// Import the action types we are going to respond to with this reducer
import {
  GET_LIVEASSOC_RULETYPES_REQUEST_SUCCESS,
  GET_LIVEASSOC_RULETYPES_REQUEST_ERROR,
  RESET_STATE
} from './constants';

// Initial state expresses the data type of this key in the store (in this case
// store.groupsList will be an Array). This object is exported separately to
// make unit testing easier
export const INITIAL_STATE = [];

// This is the actual "reducer". It is a function that will run every time an
// action is dispatched by the store, but only return updated state values if
// it matches the action's type in the switch statement.
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_LIVEASSOC_RULETYPES_REQUEST_SUCCESS:
      // In our success case our action's payload will be the new array to cach
      // and use in the application
      return {records: [...action.payload],  selectedDetailsRecordId: action.id};
    case GET_LIVEASSOC_RULETYPES_REQUEST_ERROR:
      // If an error occured you can set a fallback state here
      return { error : [action.error], selectedDetailsRecordId: action.id};        
    case RESET_STATE:
      return [];        
    default:
      // Most of the actions in our app will be ignored by this reducer, so the
      // default case is to return the existing state
      return state;
  }
};
