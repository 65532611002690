import styled from 'styled-components';
import { Input, DetailHeader } from 'ui-core-ssgr';

export const SubDetailHeader = styled(DetailHeader)`
  h1 {
    font-size: 20px;
  }
  padding-bottom: 12px;
`;

export const RuleTypesDetailRow = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const RuleTypesFilter = styled(Input)`
  margin-left: auto;
  input {
    padding-right: 1.25em;
    min-width: 300px;
  }
  & > button {
    height: 32px;
    top: 0;
  }
`;

export const SearchButtonImage = styled.img`
  width: 1.25em;
  height: 1.25em;
`;

export const DefaultModalHeight = styled.div`
  .active-content {
    height: 35em;
  }
`;

export const ButtonContainer = styled.div`
  margin-left: 10px;
`;
