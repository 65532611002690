import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Checkbox, Tooltip } from 'ui-core-ssgr';
import {
  DefaultLengthWordTag,
  TruncatedColumnMainGrids,
} from '../../../../shared/styles/styledComponents';

import { floatToDollarString } from '../../../../shared/helpers/money';
import { isA11yClick } from '../../../../shared/helpers/domEvents';
import { mapIterable } from '../../../../shared/helpers/iterables';

const onKeyPress = (e, callback) => {
  if (isA11yClick(e)) {
    e.preventDefault();
    callback(e);
  }
};

export const ClickableRow = ({
  onClickThrough = () => false,
  onRowSelect = () => false,
  supportSelection = false,
  isSelected = false,
  isFocused = false,
  selectionKey = '',
  selectedItemIds,
  disabledColumns = new Set([]),
  columns = new Set([]),
  record,
}) => {
  const classes = [];
  if (record.emboldened) classes.push('emboldened');
  if (isFocused) classes.push('focused');

  return (
    <tr
      onClick={e => {
        onClickThrough(e, record);
      }}
      onKeyPress={e => onKeyPress(e, onClickThrough)}
      role="button"
      tabIndex="0"
      className={classes.join(' ')}
    >
      {supportSelection ? (
        <td className="select-checkbox" key="select column">
          <Checkbox
            name={`${record[selectionKey].replace(
              / /g,
              '-',
            )}-row-select-checkbox`}
            className="table-row-checkbox"
            onChange={() => onRowSelect(record, isSelected)}
            value={selectedItemIds.has(record.id)}
            stopClickPropagation
          />
        </td>
      ) : null}
      {mapIterable(columns.values(), c => {
        if (disabledColumns.has(c.dataName)) return null;

        if (c.dataName === 'name') {
          return (
            <Tooltip tooltipContent={`Group ID: ${record.id}`} tooltipPosition="bottom-center" domID="tooltip-group-id">
              <TruncatedColumnMainGrids key={c.dataName} className={c.dataName}>
                {record.name}
              </TruncatedColumnMainGrids>
            </Tooltip>
          );
        }
        if (c.dataName === 'status') {
          let wordTagType = '';
          let wordTagLabel = '';
          if (record.status === 'Active') {
            wordTagType = 'positive';
            wordTagLabel = 'ACTIVE';
          }
          if (record.status === 'Inactive') {
            wordTagType = 'neutral';
            wordTagLabel = 'INACTIVE';
          }
          if (record.status === 'Termed') {
            wordTagType = 'negative';
            wordTagLabel = 'TERMED';
          }

          return (
            <td key={c.dataName} className={c.dataName}>
              <DefaultLengthWordTag type={wordTagType} label={wordTagLabel} />
            </td>
          );
        }
        if (c.dataName === 'desc') {
          return (
            <TruncatedColumnMainGrids key={c.dataName} className={c.dataName}>
              {record.desc}
            </TruncatedColumnMainGrids>
          );
        }
        if (c.dataName === 'hierarchy') {
          const joinedStrings = record.hierarchy
            .filter(Boolean)
            .toString()
            .replace(/,/g, ', ');
          return (
            <TruncatedColumnMainGrids key={c.dataName} className={c.dataName}>
              {joinedStrings}
            </TruncatedColumnMainGrids>
          );
        }
        if (c.dataName === 'arrangement') {
          return (
            <TruncatedColumnMainGrids key={c.dataName} className={c.dataName}>
              {record.arrangement}
            </TruncatedColumnMainGrids>
          );
        }
        if (c.dataName === 'returnAddr') {
          return (
            <TruncatedColumnMainGrids key={c.dataName} className={c.dataName}>
              {record.returnAddr}
            </TruncatedColumnMainGrids>
          );
        }
        if (c.dataName === 'dateModified') {
          return (
            <td key={c.dataName} className={c.dataName}>
              {moment(record.dateModified)
                .format('M/DD/YYYY h:mma')
                .toUpperCase()}
            </td>
          );
        }
        return (
          <td key={c.dataName} className={c.dataName}>
            {c.isCurrency
              ? floatToDollarString(record[c.dataName])
              : record[c.dataName]}
          </td>
        );
      })}
      <td />
    </tr>
  );
};

ClickableRow.propTypes = {
  columns: PropTypes.shape({
    dataName: PropTypes.string,
    values: PropTypes.func,
  }).isRequired,
  disabledColumns: PropTypes.shape({
    dataName: PropTypes.string,
    has: PropTypes.func,
  }).isRequired,
  record: PropTypes.any.isRequired,
  onClickThrough: PropTypes.func,
  onRowSelect: PropTypes.func,
  supportSelection: PropTypes.bool,
  isSelected: PropTypes.bool,
  selectedItemIds: PropTypes.object,
  selectionKey: PropTypes.string,
  isFocused: PropTypes.bool,
};

export default ClickableRow;
