import styled from 'styled-components';
import { TextArea } from 'ui-core-ssgr';

// Folder Name Corrections
export const ContainerBox = styled.div`
  display: flex;
  padding-bottom: 16px;
  margin-top: 20px
  position: relative;
  & > div > div > span {
    width: 670px;
  }
`;

export const PDFTaggingTextArea = styled(TextArea)`
  width: 670px;
`;

export const SectionInfoHeader = styled.div`
    font-size: 14px;
    color: #6D767E;
`;
