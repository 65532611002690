import { useAxios } from '../hooks'

const useClientSettings = (group) => {
  const { data, ...rest } = useAxios({
    url: `/clientAdministration/clientSettings?grouping=${group}`,
  })

  return { 
    data,
    ...rest
  }
}

export default useClientSettings