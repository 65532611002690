export const gridCommitConfig = {
  columns: new Set([
    {
      dataName: 'name',
      text: 'Rule',
      sortable: true,
    },
    {
      dataName: 'status',
      text: 'Status',
      sortable: true,
    },
    {
      dataName: 'activeGroupLabel',
      text: 'Active Group Live Association',
      sortable: true,
    },
    {
      dataName: 'description',
      text: 'Description',
      sortable: true,
    },
    {
      dataName: 'tested',
      text: 'Trialed',
      sortable: true,
    },
  ]),
  selectionKey: 'id',
  domID: 'commit-grid',
  onSortTableColumn: () => {},
  supportSelection: false,
  initialSortingKey: 'dateModified|SORT_DESCENDING',
};

export const gridCommitConfigForImageRuleTypes = {
  columns: new Set([
    {
      dataName: 'name',
      text: 'Rule',
      sortable: true,
    },
    {
      dataName: 'status',
      text: 'Status',
      sortable: true,
    },
    {
      dataName: 'activeGroupLabel',
      text: 'Active Group Live Association',
      sortable: true,
    },
    {
      dataName: 'testImageCount',
      text: 'Images In Test',
      sortable: true,
    },
    {
      dataName: 'description',
      text: 'Description',
      sortable: true,
    },
    {
      dataName: 'tested',
      text: 'Trialed',
      sortable: true,
    },
  ]),
  selectionKey: 'id',
  domID: 'commit-grid-for-imagert',
  onSortTableColumn: () => {},
  supportSelection: false,
  initialSortingKey: 'dateModified|SORT_DESCENDING',
};
