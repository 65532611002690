export const trapClick = e => {
  e.stopPropagation();
};
export const trapScroll = e => {
  e.stopPropagation();
};

export const actionWithoutPropagation = (e, action, paramsArray = []) => {
  e.stopPropagation();
  if (action) action(...paramsArray);
};

export const isA11yClick = event => {
  if (event.type === 'click') {
    return true;
  } if (event.type === 'keypress') {
    const code = event.charCode || event.keyCode;
    if (code === 32 || code === 13) return true;
  }
  return false;
};

export default {
  trapClick,
  trapScroll,
  actionWithoutPropagation,
  isA11yClick,
};
