import { SET_CONTEXT, SET_PERMISSIONS, TOGGLE_MODAL } from './constants'

export const setContext = payload => ({
  type: SET_CONTEXT,
  payload,
})

export const setPermissions = payload => ({
  type: SET_PERMISSIONS,
  payload,
})

export const toggleCtxModal = payload => ({
  type: TOGGLE_MODAL,
  payload,
})