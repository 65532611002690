import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import {
  SelectDropdown,
  EntityTitle,
  CircleCheckFill,
  LoadingIndicator,
  Button,
} from 'ui-core-ssgr';

import {
  DropOne,
  DropTwo,
  DropThree,
  ContainerBox,
  ReturnAddDropDown,
  AddressDivContainer,
  AddressDivContainerReadOnly,
  ReturnAddressWrapper,
  ReturnAddressWrapperReadOnly,
  CheckIcon,
  NameAndAddress,
  AltAddressDiv,
  ReturnAddressTitle,
  Title,
  AltTitle,
  ButtonWrap,
  ButtonContainerWrap,
  ReturnAddressImage,
  ReturnAddressImageReadOnly,
  ReturnAddressLogo
} from './styles';
import { 
  ButtonGroupBottom,
  ReadOnlyTextTitle,
  ReadOnlyTextArea
} from '../../../shared/styles/styledComponents';
import {
  LoadingWrapper,
  ModalButton,
} from '../../RulesTabView/components/RuleDetails/RuleDetailsGridWrapper/styles';
import AddNewAddressModal from '../AddNewAddressModal/index';
import ImageControl from '../ModalControls/ImageControl';
import { ImageNotFound200x100Other } from '../ModalControls/UploadAndViewImageControl/constants';
import CommitImageModal from '../CommitImageModal';

class ReturnAddressModal extends Component {
  state = {
    rowClicked: null,
    isButtonDisabled: true,
    claimType: 'default',
    recipientType: 'default',
    documentType: 'default',
    returnAddress: '00000',
    firstCode: '00000',
    rowNewlyAdded: null,
    selectedDataToCommit: null,
  };

  componentDidMount() {
    const { isPostingRuleDetail } = this.props;
    this.getInitialValues();
    this.setState({
      isButtonDisabled: !isPostingRuleDetail,
      rowClicked: null,
    });
  }

  componentDidUpdate(prevProps) {
    let rowNewlyAddedCustom = null;
    let returnAddressCustom = '00000';
    let isButtonDisabledCustom = true;
    let claimTypeCustom = 'default';
    let recipientTypeCustom = 'default';
    let documentTypeCustom = 'default';
    const { claimType, recipientType, documentType, selectedDataToCommit } = this.state;
    const {
      detailElements,
      isPostingRuleDetail,
      isCloningRuleDetail,
      isShowingReturnAddressModal,
      selectedRuleDetail,
      postImageCommitSuccessId
    } = this.props;
    if (
      prevProps.detailElements !== detailElements ||
      prevProps.isShowingReturnAddressModal !== isShowingReturnAddressModal ||
      prevProps.isPostingRuleDetail !== isPostingRuleDetail
    ) {
      // code to set newly added return address pre-selected
      if (
        prevProps.detailElements.returnAddressList !== undefined &&
        detailElements.returnAddressList !== undefined
      ) {
        const missingElement = this.findMissingElement(
          prevProps.detailElements.returnAddressList,
          detailElements.returnAddressList,
        );
        if (missingElement !== undefined && missingElement.length > 0) {
          this.populateReturnAddress('returnAddress', missingElement[0].value);
          rowNewlyAddedCustom = missingElement[0].value;
          returnAddressCustom = missingElement[0].code;
          if (isCloningRuleDetail === false) isButtonDisabledCustom = false;

          claimTypeCustom = claimType;
          recipientTypeCustom = recipientType;
          documentTypeCustom = documentType;
        }
      }
      // do not call when user made changes to claim,recipnt,doctype. keep changes
      if (rowNewlyAddedCustom === null) {
        this.getInitialValues();
      } else {
        isButtonDisabledCustom = false;
      }
      if (isPostingRuleDetail === true || isButtonDisabledCustom === false) {
        isButtonDisabledCustom = false;
        this.setState({
          claimType: claimTypeCustom,
          recipientType: recipientTypeCustom,
          documentType: documentTypeCustom,
        });
      }
      // case when add new return address and clone it after
      if (
        isCloningRuleDetail === true &&
        rowNewlyAddedCustom === null &&
        !isEmpty(selectedRuleDetail)
      ) {
        if (selectedRuleDetail.returnAddress !== undefined) {
          const isReturnAddressCustomExists = detailElements.returnAddressList.find(
            o => o.code.includes(selectedRuleDetail.returnAddressCode),
          );
          returnAddressCustom = !isEmpty(isReturnAddressCustomExists) ? isReturnAddressCustomExists.code : '00000';
        }
      }
      // case when add new return address and edit it after
      if (
        isPostingRuleDetail === false &&
        isCloningRuleDetail === false &&
        rowNewlyAddedCustom === null &&
        !isEmpty(selectedRuleDetail)
      ) {
        if (selectedRuleDetail.returnAddress !== undefined) {
          const isReturnAddressCustomExists = detailElements.returnAddressList.find(
            o => o.code.includes(selectedRuleDetail.returnAddressCode),
          );
          returnAddressCustom = !isEmpty(isReturnAddressCustomExists) ? isReturnAddressCustomExists.code : '00000';
        }
      }
      // case when commit button is clicked and it should keep changes for claimType, recipientType and documentType
      if(postImageCommitSuccessId &&
        postImageCommitSuccessId.imageId && postImageCommitSuccessId.isTested !== false ) {
        if(!isEmpty(selectedDataToCommit)){
          const {returnAddress} = selectedDataToCommit;
          returnAddressCustom = returnAddress;
        }
        isButtonDisabledCustom = false;
        this.setState({
          claimType,
          recipientType,
          documentType,
        });
      }
      this.setState({
        isButtonDisabled: isButtonDisabledCustom,
        rowNewlyAdded: rowNewlyAddedCustom,
        returnAddress: returnAddressCustom,
      });
    }
  }

  findMissingElement(prevArray, currentArray) {
    const result = [prevArray, currentArray]
      .sort((a, b) => b.length - a.length)
      .reduce((a, b) => a.filter(o => !b.some(v => v.code === o.code)));
    return result;
  }

  getInitialValues = () => {
    const { 
      detailElements,
      isPostingRuleDetail,
      selectedRuleDetail,
      actionType
    } = this.props;
    let claimType = 'default';
    let recipientType = 'default';
    let documentType = 'default';
    let returnAddress = '00000';
    if (
      !isEmpty(detailElements) && 
      !isEmpty(selectedRuleDetail) &&
      actionType !== 'Add a') {
      if (actionType === 'Edit a' || actionType === 'Clone a') {
        if (detailElements.claimTypesList) {
          claimType = detailElements.claimTypesList.find(
            o => o.value === selectedRuleDetail.claimType,
          ).code;
        }
        if (detailElements.recipientTypesList) {
          recipientType = detailElements.recipientTypesList.find(
            o => o.value === selectedRuleDetail.recipientType,
          ).code;
        }
        if (detailElements.documentTypesList) {
          documentType = detailElements.documentTypesList.find(
            o => o.value === selectedRuleDetail.documentType,
          ).code;
        }
        if (detailElements.returnAddressList) {
          if (selectedRuleDetail.returnAddress) {
            returnAddress = detailElements.returnAddressList.find(
              o => o.code === selectedRuleDetail.returnAddressCode,
            ).code;
          } else {
            returnAddress = '00000';
          }
        }
      }

      if (actionType === 'Read only') {
        if (detailElements.claimTypesList) {
          claimType = detailElements.claimTypesList.find(
            o => o.value === selectedRuleDetail.claimType,
          ).value;
        }
        if (detailElements.recipientTypesList) {
          recipientType = detailElements.recipientTypesList.find(
            o => o.value === selectedRuleDetail.recipientType,
          ).value;
        }
        if (detailElements.documentTypesList) {
          documentType = detailElements.documentTypesList.find(
            o => o.value === selectedRuleDetail.documentType,
          ).value;
        }
      }
    }
    if (isPostingRuleDetail) {
      claimType = 'default';
      recipientType = 'default';
      documentType = 'default';
      returnAddress = '00000';
    }
    this.setState({
      claimType,
      recipientType,
      documentType,
      returnAddress,
    });
  };

  getRuleVersion = record => {
    try {
      const { status, version } = record;
      const testVersion = version[status.indexOf('T')];
      if (testVersion) return testVersion;
      const liveVersion = version[status.indexOf('L')];
      return liveVersion;
    } catch (e) {
      console.log(e);
    }
    return null;
  };

  populateDataDropDown = (name, initialValue) => {
    const { detailElements, selectedRuleDetail } = this.props;
    if (!isEmpty(detailElements)) {
      if (name === 'claimType' && detailElements) {
        let options = [];
        let selectedClaimType = '';
        const claimTypeValue = selectedRuleDetail.claimType;
        const firstValue = detailElements.claimTypesList.find(
          x => x.value === claimTypeValue,
        );
        const arr = firstValue
          ? [...new Set([firstValue, ...detailElements.claimTypesList])]
          : detailElements.claimTypesList;
        options = arr.map(r => {
          if (r.code === initialValue) {
            selectedClaimType = r.code;
          }
          return {
            label: r.value,
            value: r.code,
          };
        });
        return [options, selectedClaimType];
      }
      if (name === 'recipient' && detailElements.recipientTypesList) {
        let options = [];
        let selectedRecipientType = '';
        const recipientValue = selectedRuleDetail.recipientType;
        const firstValue = detailElements.recipientTypesList.find(
          x => x.value === recipientValue,
        );
        const arr = firstValue
          ? [...new Set([firstValue, ...detailElements.recipientTypesList])]
          : detailElements.recipientTypesList;
        options = arr.map(r => {
          if (r.code === initialValue) {
            selectedRecipientType = r.code;
          }
          return {
            label: r.value,
            value: r.code,
          };
        });
        return [options, selectedRecipientType];
      }
      if (name === 'documentType' && detailElements.documentTypesList) {
        let options = [];
        let selectedDocumentType = '';
        const documentTypeValue = selectedRuleDetail.documentType;
        const firstValue = detailElements.documentTypesList.find(x =>
          x.value.includes(documentTypeValue),
        );
        const arr = firstValue
          ? [...new Set([firstValue, ...detailElements.documentTypesList])]
          : detailElements.documentTypesList;
        options = arr.map(r => {
          if (r.code === initialValue) {
            selectedDocumentType = r.code;
          }
          return {
            label: r.value,
            value: r.code,
          };
        });
        return [options, selectedDocumentType];
      }
    }
    return [];
  };

  onCancel = () => {
    const {
      setIsCloningRuleDetail,
      setIsPostingRuleDetail,
      setIsShowingReturnAddressModal,
      setIsReadOnlyRuleDetail
    } = this.props;
    setIsShowingReturnAddressModal(false);
    setIsCloningRuleDetail(false);
    setIsPostingRuleDetail(false);
    setIsReadOnlyRuleDetail(false);
    this.setState({ isButtonDisabled: true, rowClicked: null });
  };

  divClicked = (e, index, returnAddress) => {
    if(e.target.tagName !== 'BUTTON'){
      const { claimType, recipientType, documentType } = this.state;
      this.checkUnsavedChanges(
        claimType,
        recipientType,
        documentType,
        returnAddress,
      );
      this.setState({
        rowClicked: index,
        returnAddress,
        rowNewlyAdded: null,
      });
      return <CircleCheckFill fillColor="#32CD32" className="test" />;
    }
    return '';
  };

  onSave = () => {
    const {
      isCloningRuleDetail,
      isPostingRuleDetail,
      postRuleDetails,
      putRuleDetails,
      selectedRecord,
      selectedRuleDetail,
      selectedRuleRecord,
      setIsCloningRuleDetail,
      setIsPostingRuleDetail,
      selectedRuleTypeId,
    } = this.props;
    const {
      claimType,
      recipientType,
      documentType,
      returnAddress,
    } = this.state;
    const record = selectedRuleRecord || selectedRecord;
    const ruleDetailId = selectedRuleDetail.id;
    const ruleId = record.id;
    const ruleVersion = this.getRuleVersion(record);
    const ruleType = selectedRuleTypeId;
    let ruleDetail = {
      claimType,
      recipientType,
      documentType,
      returnAddress,
    };
    ruleDetail = isCloningRuleDetail
      ? { ...ruleDetail, ...{ ruleDetailIdFrom: ruleDetailId } }
      : ruleDetail;

    if (isPostingRuleDetail || isCloningRuleDetail) {
      postRuleDetails({ ruleDetail, ruleType, ruleId, ruleVersion });
      setIsCloningRuleDetail(false);
      setIsPostingRuleDetail(false);
    } else {
      putRuleDetails({
        ruleDetail,
        ruleType,
        ruleId,
        ruleVersion,
        ruleDetailId,
      });
    }
  };

  handleCommitAction = (e, imageId, imageName, index, returnAddress) => {
    const {setIsShowingCommitImageModal} = this.props;
    this.setState ({
      selectedDataToCommit: {imageName, imageId, returnAddress, rowClicked: index}
    });
    setIsShowingCommitImageModal({status:true, workflow:'ruledetail'});
  };

  // used only for read only purpose to show live rule details
  populateReturnAddressReadOnly = () => {
    const {
      detailElements,
      selectedRuleDetail,
      loading,
      selectedRuleTypeId
    } = this.props;
    let rCode = '00000';
    if (selectedRuleDetail.returnAddress) {
      rCode = selectedRuleDetail.returnAddressCode;
    }
    if(!isEmpty(detailElements) && loading.has(selectedRuleTypeId) === false){
      const isFirstValueExists = detailElements.returnAddressList.find(
        o => o.code.includes(rCode),
      );
      const firstValue = !isEmpty(isFirstValueExists) ? isFirstValueExists : null;
      const arr = firstValue
        ? [...new Set([firstValue, ...detailElements.returnAddressList])]
        : detailElements.returnAddressList;
    
      const currentRecord = arr.filter(o => o.code === rCode);
      if(currentRecord !== undefined){
        const altAddress =
      currentRecord[0].altAddressCode && currentRecord[0].altAddressCode !== undefined
        ? `Alt Address: ${currentRecord[0].altAddressCode} ${currentRecord[0].altAddressValue}`
        : `Alt Address: None`;
        const returnLogoStyle = currentRecord[0].returnStyle || null;
        const returnAddressImageCaption = currentRecord[0].logoName || null;
        const returnAddressImageSrc =
        currentRecord[0].logoImage || ImageNotFound200x100Other;
        const returnAddressImageId = currentRecord[0].logoImageId || null;

        return (
          <ReturnAddressWrapperReadOnly
            key={rCode}
          >
            <ReturnAddressImageReadOnly className="ReturnImgWrap">
              {!isEmpty(returnAddressImageId) ? (
                <>
                  <ImageControl
                    key={`key-${returnAddressImageId}`}
                    imageKey={`imgkey-${returnAddressImageId}`}
                    imageDomID={`domid-${returnAddressImageId}`}
                    imageClassName={`class-${returnAddressImageId}`}
                    imageSrc={returnAddressImageSrc}
                    imageAltLabel={returnAddressImageCaption}
                    imageStyles={{ display: 'inline-block', fontSize: '13px' }}
                    imageOnClickHandle={() => {}}
                    imageOnLoad={() => {}}
                    imageOnError={() => {}}
                    imageSelection={false}
                    ruleType="RAD"
                  />
                </>
              ) : (
                <div className="image-library-none">None</div>
              )}
            </ReturnAddressImageReadOnly>
            {!isEmpty(returnAddressImageId) ? (
              <ReturnAddressLogo className="ReturnStyleTitleWrap">
                <Title>
                  <EntityTitle
                    title="Return Style:"
                    description={returnLogoStyle || ''}
                    removeUnderline={Boolean('true')}
                    color="black"
                    className="name"
                  />
                </Title>
              </ReturnAddressLogo>
            ) : (
              <ReturnAddressLogo className="ReturnStyleTitleWrapEmptyImg">
                <Title />
              </ReturnAddressLogo>
            )}
            <NameAndAddress className="NameAndAddressWrap">
              <Title>
                <EntityTitle
                  title={currentRecord[0].code}
                  description={currentRecord[0].value}
                  removeUnderline={Boolean('true')}
                  color="black"
                  className="name"
                />
              </Title>
            </NameAndAddress>
            <AltAddressDiv className="AltAddressDivWrap">
              <AltTitle>
                <EntityTitle
                  title={altAddress}
                  removeUnderline={Boolean('true')}
                  description=" "
                />
              </AltTitle>
            </AltAddressDiv>
          </ReturnAddressWrapperReadOnly>
        );
      }
      return '';
    }
    return '';
  }

  populateReturnAddress = () => {
    const {
      rowNewlyAdded,
      returnAddress,
      rowClicked,
      selectedDataToCommit,
      firstCode
    } = this.state;
    const {
      detailElements,
      selectedRuleDetail,
      isPostingRuleDetail,
      postImageCommitSuccessId
    } = this.props;
    if (!isEmpty(detailElements)) {
      let rCode = '00000';
      let newCode = '00000';
      if (selectedRuleDetail.returnAddress && isPostingRuleDetail === false) {
        rCode = selectedRuleDetail.returnAddressCode;
      }

      // case when newly added row exists
      if (rowNewlyAdded !== null) {
        newCode = returnAddress;
      }
      if (rowNewlyAdded !== null && newCode !== firstCode) {
        this.setState({firstCode: newCode})
      }
      
      // case when commit button is clicked and it should keep changes for claimType, recipientType and documentType
      if(postImageCommitSuccessId &&
        postImageCommitSuccessId.imageId && postImageCommitSuccessId.isTested !== false 
        && !isEmpty(selectedDataToCommit) ) {
        rCode = selectedDataToCommit.returnAddress;
      }

      const firstValue = (firstCode !== '00000') ? (
        detailElements.returnAddressList.find(x => x.code === firstCode) 
      ) : (
        detailElements.returnAddressList.find(x => x.code === rCode)
      ); 

      const arr = firstValue
        ? [...new Set([firstValue, ...detailElements.returnAddressList])]
        : detailElements.returnAddressList;      

      return uniqBy(arr, 'code').map((r, index) => {
        let selectedReturnAddress = null;
        if (rowNewlyAdded !== null && rCode === r.code) { // case when new return address is added
          selectedReturnAddress = firstValue;
        }
        else if (rowClicked !== null && rowNewlyAdded === null) { // case of user clicking on a row
          selectedReturnAddress = rowClicked;
        }
        else {
          selectedReturnAddress = 0;
        }

        const altAddress =
          r.altAddressCode && r.altAddressCode !== undefined
            ? `Alt Address: ${r.altAddressCode} ${r.altAddressValue}`
            : `Alt Address: None`;
        const returnLogoStyle = r.returnStyle || null;
        const returnAddressImageCaption = r.logoName || null;
        const returnAddressImageSrc =
          r.logoImage || ImageNotFound200x100Other;
        const returnAddressImageId = r.logoImageId || null;

        const isTestedCustom = r.isTestedLogo === undefined ? false : r.isTestedLogo;
        
        if (r.code !== 'default') {
          return (
            <ReturnAddressWrapper
              onClick={(e) => this.divClicked(e, index, r.code)}
              style={{
                border: index === selectedReturnAddress ? '3px solid #358600' : '1px solid #737d85',
              }}
              key={r.code}
            >
              <CheckIcon>
                {index === selectedReturnAddress ? (
                  <CircleCheckFill
                    fillColor="#358600"
                    width="20px"
                  />
                ) : null}
              </CheckIcon>
              <ReturnAddressImage className="ReturnImgWrap">
                {!isEmpty(returnAddressImageId) ? (
                  <>
                    <ImageControl
                      key={`key-${returnAddressImageId}`}
                      imageKey={`imgkey-${returnAddressImageId}`}
                      imageDomID={`domid-${returnAddressImageId}`}
                      imageClassName={`class-${returnAddressImageId}`}
                      imageSrc={returnAddressImageSrc}
                      imageAltLabel={returnAddressImageCaption}
                      imageStyles={{ display: 'inline-block', fontSize: '13px' }}
                      imageOnClickHandle={() => {}}
                      imageOnLoad={() => {}}
                      imageOnError={() => {}}
                      imageSelection={false}
                      ruleType="RAD"
                    />
                    {/* show commit button if image is not tested so that user can make it to production from test */}
                    {isTestedCustom === false ? <Button
                      domID="cmt-btn-RAD"
                      name="COMMIT"
                      buttonType="diminished"
                      size="medium"
                      onClick={e => this.handleCommitAction(e, returnAddressImageId, returnAddressImageCaption, index, r.code)}
                      isDropdown={false}
                      type="button"
                    /> : null }
                  </>
                ) : (
                  <div className="image-library-none">None</div>
                )}
              </ReturnAddressImage>
              {!isEmpty(returnAddressImageId) ? (
                <ReturnAddressLogo className="ReturnStyleTitleWrap">
                  <Title>
                    <EntityTitle
                      title="Return Style:"
                      description={returnLogoStyle || ''}
                      removeUnderline={Boolean('true')}
                      color="black"
                      className="name"
                    />
                  </Title>
                </ReturnAddressLogo>
              ) : (
                <ReturnAddressLogo className="ReturnStyleTitleWrapEmptyImg">
                  <Title />
                </ReturnAddressLogo>
              )}
              <NameAndAddress className="NameAndAddressWrap">
                <Title>
                  <EntityTitle
                    title={r.code}
                    description={r.value}
                    removeUnderline={Boolean('true')}
                    color="black"
                    className="name"
                  />
                </Title>
              </NameAndAddress>
              <AltAddressDiv className="AltAddressDivWrap">
                <AltTitle>
                  <EntityTitle
                    title={altAddress}
                    removeUnderline={Boolean('true')}
                    description=" "
                  />
                </AltTitle>
              </AltAddressDiv>
            </ReturnAddressWrapper>
          );
        }
        return '';
      });
    }
    return '';
  };

  checkUnsavedChanges = (event, code) => {
    if (event.target) {
      const { name } = event.target;
      const { value } = event.target;
      this.setState({
        isButtonDisabled: false,
        [name]: value,
      });
    } else {
      this.setState({
        isButtonDisabled: false,
        returnAddress: code,
      });
    }
  };

  onAddNewReturnAddress = () => {
    const { setIsShowingAddNewAddressModal } = this.props;
    setIsShowingAddNewAddressModal(true);
  };

  render() {
    const { checkUnsavedChanges } = this;
    const {
      isButtonDisabled,
      claimType,
      recipientType,
      documentType,
      selectedDataToCommit
    } = this.state;
    const {
      loading,
      selectedRuleDetail,
      selectedRecord,
      selectedRuleRecord,
      setIsShowingAddNewAddressModal,
      isShowingAddNewAddressModal,
      selectedRuleTypeId,
      returnAddressElements,
      postAddNewAddressElements,
      setIsShowingImageLibraryModal,
      isShowingImageLibraryModal,
      getRuleImages,
      ruleImages,
      getRuleImagesCount,
      ruleImagesCount,
      resetRuleImagesData,
      setIsShowingImageUploadModal,
      isShowingImageUploadModal,
      convertImageUpload,
      convertedImageUploadData,
      convertedImageUploadError,
      postImageUpload,
      postImageUploadSuccessId,
      isShowingCommitImageModal,
      setIsShowingCommitImageModal,
      postCommitImage,
      postImageCommitSuccessId,
      isShowingImageRuleModal,
      postCommitImageTriggerCancel,
      actionType
    } = this.props;
    const ruleVersion = this.getRuleVersion(selectedRuleRecord);
    const recordId = selectedRuleRecord
      ? selectedRuleRecord.id
      : selectedRecord.id;
    const optionsClaimType = this.populateDataDropDown(
      'claimType',
      selectedRuleDetail.claimType,
    );
    const optionsRecipientType = this.populateDataDropDown(
      'recipient',
      selectedRuleDetail.recipientType,
    );
    const optionsDocumentType = this.populateDataDropDown(
      'documentType',
      selectedRuleDetail.documentType,
    );
    let claimTypeOptions = [];
    let recipientTypeOptions = [];
    let documentTypeOptions = [];
    if (optionsClaimType) {
      claimTypeOptions = optionsClaimType[0] || [];
    }
    if (optionsRecipientType) {
      recipientTypeOptions = optionsRecipientType[0] || [];
    }
    if (optionsDocumentType) {
      documentTypeOptions = optionsDocumentType[0] || [];
    }

    // FOR READ ONLY CONDITIONAL RENDERING (Live Rule)
    let claimTypeReadOnly;
    let recipientTypeReadOnly;
    let documentTypeReadOnly;
    if (actionType === 'Read only') {
      claimTypeReadOnly = (claimType !== 'default') ? claimType : 'Any';
      recipientTypeReadOnly = (recipientType !== 'default') ? recipientType : 'Any';
      documentTypeReadOnly = (documentType !== 'default') ? documentType : 'Any';
    }

    return (
      <>
        <ContainerBox>
          {isShowingCommitImageModal !== undefined && isShowingCommitImageModal.status === true ? (
            <CommitImageModal
              {...this.props}
              workflow={isShowingCommitImageModal.workflow}
              selectedDataToCommit={selectedDataToCommit}
              ruleId={recordId}
              ruleVersion={ruleVersion}
            />
          ) : null}
          {actionType !== 'Read only' ? (
            <ReturnAddDropDown>
              <DropOne>
                <SelectDropdown
                  domID="test-id"
                  label="claim type"
                  name="claimType"
                  options={claimTypeOptions}
                  initialValue={claimType}
                  startWithBlankValue={false}
                  onChange={e => checkUnsavedChanges(e)}
                />
              </DropOne>
            </ReturnAddDropDown>
          ) : <ReadOnlyTextTitle>Claim Type<ReadOnlyTextArea>{claimTypeReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
          {actionType !== 'Read only' ? (
            <ReturnAddDropDown>
              <DropTwo>
                <SelectDropdown
                  domID="test-id"
                  label="recipient"
                  name="recipientType"
                  options={recipientTypeOptions}
                  initialValue={recipientType}
                  startWithBlankValue={false}
                  onChange={e => checkUnsavedChanges(e)}
                />
              </DropTwo>
            </ReturnAddDropDown>
          ) : <ReadOnlyTextTitle>Recipient Type<ReadOnlyTextArea>{recipientTypeReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
          {actionType !== 'Read only' ? (
            <ReturnAddDropDown>
              <DropThree>
                <SelectDropdown
                  domID="test-id"
                  label="document type"
                  name="documentType"
                  options={documentTypeOptions}
                  initialValue={documentType}
                  startWithBlankValue={false}
                  onChange={e => checkUnsavedChanges(e)}
                />
              </DropThree>
            </ReturnAddDropDown>
          ) : <ReadOnlyTextTitle>Document Type<ReadOnlyTextArea>{documentTypeReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
        </ContainerBox>
        <ReturnAddressTitle>
          <EntityTitle
            title={actionType !== 'Read only' ? 'Return Address' : 'RETURN ADDRESS'}
            description=" "
            removeUnderline={Boolean('true')}
          />
          {actionType !== 'Read only' ? (
            <ButtonWrap>
              {loading.has(selectedRuleTypeId) && (
                <LoadingWrapper>
                  <LoadingIndicator
                    domID="basic-details-form-loading-indicator"
                    length="25px"
                  />
                </LoadingWrapper>
              )}
              <Button
                onClick={() => this.onAddNewReturnAddress()}
                domID="automation-id"
                name="Add a New Address"
                buttonType="deEmphasized"
                size="small"
                isDropdown={false}
                type="button"
                disabled={
                  !(
                    returnAddressElements && returnAddressElements !== undefined
                  ) || loading.has(selectedRuleTypeId)
                }
              />
            </ButtonWrap>
          ): null}
        </ReturnAddressTitle>
        {actionType !== 'Read only' ? (
          <AddressDivContainer>
            <ReturnAddressWrapper />
            {this.populateReturnAddress(
              'returnAddress',
              selectedRuleDetail.returnAddress,
            )}
          </AddressDivContainer> 
        ) : 
          <AddressDivContainerReadOnly>
            {this.populateReturnAddressReadOnly()}
          </AddressDivContainerReadOnly>
        }
        <ButtonContainerWrap>
          <ButtonGroupBottom>
            <ModalButton>
              {loading.has(recordId) && (
                <LoadingWrapper>
                  <LoadingIndicator
                    domID="basic-details-form-loading-indicator"
                    length="30px"
                  />
                </LoadingWrapper>
              )}
              {actionType !== 'Read only' ? (
                <Button
                  onClick={() => this.onSave()}
                  domID="automation-id"
                  name="Save"
                  buttonType="emphasized"
                  size="medium"
                  isDropdown={false}
                  type="button"
                  disabled={isButtonDisabled || loading.has(recordId)}
                />) : null }
              <Button
                domID="automation-id"
                name={actionType !== 'Read only' ? 'Cancel' : 'Close'}
                buttonType="diminished"
                disabled={loading.has(recordId)}
                size="medium"
                onClick={() => this.onCancel()}
                isDropdown={false}
                type="button"
              />
            </ModalButton>
          </ButtonGroupBottom>
        </ButtonContainerWrap>
        {isShowingAddNewAddressModal ? (
          <AddNewAddressModal
            setIsShowingAddNewAddressModal={setIsShowingAddNewAddressModal}
            isShowingAddNewAddressModal={isShowingAddNewAddressModal}
            loading={loading}
            selectedRecord={selectedRecord}
            selectedRuleRecord={selectedRuleRecord}
            selectedRuleTypeId={selectedRuleTypeId}
            selectedRuleDetail={selectedRuleDetail}
            returnAddressElements={returnAddressElements}
            postAddNewAddressElements={postAddNewAddressElements}
            setIsShowingImageLibraryModal={setIsShowingImageLibraryModal}
            isShowingImageLibraryModal={isShowingImageLibraryModal}
            getRuleImages={getRuleImages}
            ruleImages={ruleImages}
            getRuleImagesCount={getRuleImagesCount}
            ruleImagesCount={ruleImagesCount}
            resetRuleImagesData={resetRuleImagesData}
            setIsShowingImageUploadModal={setIsShowingImageUploadModal}
            isShowingImageUploadModal={isShowingImageUploadModal}
            convertImageUpload={convertImageUpload}
            convertedImageUploadData={convertedImageUploadData}
            convertedImageUploadError={convertedImageUploadError}
            postImageUpload={postImageUpload}
            postImageUploadSuccessId={postImageUploadSuccessId}
            isShowingCommitImageModal={isShowingCommitImageModal}
            setIsShowingCommitImageModal={setIsShowingCommitImageModal}
            postCommitImage={postCommitImage}
            postImageCommitSuccessId={postImageCommitSuccessId}
            isShowingImageRuleModal={isShowingImageRuleModal}
            postCommitImageTriggerCancel={postCommitImageTriggerCancel}
          />
        ) : (
          ''
        )}
      </>
    );
  }
}

ReturnAddressModal.propTypes = {
  detailElements: PropTypes.object,
  loading: PropTypes.object,
  selectedRuleDetail: PropTypes.object,
  selectedRuleRecord: PropTypes.object,
  setIsCloningRuleDetail: PropTypes.func,
  setIsPostingRuleDetail: PropTypes.func,
  setIsShowingReturnAddressModal: PropTypes.func,
  isShowingReturnAddressModal: PropTypes.bool,
  isPostingRuleDetail: PropTypes.bool,
  selectedRuleTypeId: PropTypes.string,
  setIsShowingAddNewAddressModal: PropTypes.func,
  selectedRecord: PropTypes.object,
  isShowingAddNewAddressModal: PropTypes.bool,
  returnAddressElements: PropTypes.object,
  postAddNewAddressElements: PropTypes.func,
  isCloningRuleDetail: PropTypes.bool,
  postRuleDetails: PropTypes.func,
  putRuleDetails: PropTypes.func,
  ruleImagesCount: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  setIsShowingImageLibraryModal: PropTypes.func,
  isShowingImageLibraryModal: PropTypes.bool,
  getRuleImages: PropTypes.func,
  ruleImages: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  getRuleImagesCount: PropTypes.func,
  resetRuleImagesData: PropTypes.func,
  setIsShowingImageUploadModal: PropTypes.func,
  isShowingImageUploadModal: PropTypes.bool,
  convertImageUpload: PropTypes.func,
  convertedImageUploadData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  convertedImageUploadError: PropTypes.func,
  postImageUpload: PropTypes.func,
  postImageUploadSuccessId: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  isShowingCommitImageModal: PropTypes.object,
  setIsShowingCommitImageModal: PropTypes.func,
  postCommitImage: PropTypes.func,
  postImageCommitSuccessId: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
  isShowingImageRuleModal: PropTypes.bool,
  postCommitImageTriggerCancel: PropTypes.func,
  setIsReadOnlyRuleDetail: PropTypes.func
};
export default ReturnAddressModal;