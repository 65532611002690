/**
 * Create the store with dynamic reducers
 */

import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';

import invariant from 'invariant';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'
import createReducer from './reducers';
import checkStore from './utils/checkStore';

import sagaList from './redux/rootSaga';

const sagaMiddleware = createSagaMiddleware();

export default function configureStore(initialState = {}, history) {
  // Create the store with two middlewares
  // 1. thunk: for async/await support in action creators
  // 2. routerMiddleware: Syncs the location/URL path to the state
  const middlewares = [thunk, sagaMiddleware, routerMiddleware(history)];

  const enhancers = [applyMiddleware(...middlewares)];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  const composeEnhancers =
    process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;

  const persistConfig = {
    key: 'root:reducer',
    storage,
    // whitelist: ['app', 'nav', 'context', 'session', 'serverConfig']
    blacklist: [
      'groups',
      'groupsList',
      'ruleTypesList',
      'rulesForRuleTypes',
      'rulesForRuleTypesList',
      'ruleDetails',
      'commitRuleTypesList',
      'liveAssocRuleTypesList',
      'loadingState',
      'notificationState',
      'detailsPaneState'
    ]
  }
      
  const persistedReducer = persistReducer(persistConfig, createReducer({}, history))

  const store = createStore(
    persistedReducer,
    initialState,
    composeEnhancers(...enhancers),
  );

  const persistor = persistStore(store)

  store.injectedReducers = {};
  store.injectReducer = (key, reducer) => {
    checkStore(store);

    invariant(
      isString(key) && !isEmpty(key) && isFunction(reducer),
      '(app/utils...) injectReducer: Expected `reducer` to be a reducer function',
    );

    // Check `store.injectedReducers[key] === reducer` for hot reloading
    // when a key is the same but a reducer is different
    if (
      Reflect.has(store.injectedReducers, key) &&
      store.injectedReducers[key] === reducer
    )
      return;

    store.injectedReducers[key] = reducer;
    store.replaceReducer(createReducer(store.injectedReducers, history));
  };

    // We have to tell the store to run each saga individually, which is why we
  // collect them into an array in rootSaga.js
  sagaList.forEach(sagaMiddleware.run);

  // Make reducers hot reloadable
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(createReducer(store.injectedReducers, history));
    });
  }

  return { store, persistor };
}
