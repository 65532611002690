export const RULES_FOR_RULETYPES_PUT_REQUEST_TRIGGER =
  'RULES_FOR_RULETYPES_PUT_REQUEST_TRIGGER';
export const RULES_FOR_RULETYPES_PUT_SUCCESS =
  'RULES_FOR_RULETYPES_PUT_SUCCESS';
export const RULES_FOR_RULETYPES_PUT_ERROR = 'RULES_FOR_RULETYPES_PUT_ERROR';
export const SET_IS_POSTING_RULES_FOR_RULETYPES =
  'SET_IS_POSTING_RULES_FOR_RULETYPES';
export const RULES_FOR_RULETYPES_POST_REQUEST_TRIGGER =
  'RULES_FOR_RULETYPES_POST_REQUEST_TRIGGER';
export const RULES_FOR_RULETYPES_POST_SUCCESS =
  'RULES_FOR_RULETYPES_POST_SUCCESS';
export const RULES_FOR_RULETYPES_POST_ERROR = 'RULES_FOR_RULETYPES_POST_ERROR';
export const SET_IS_CLONING_RULE_STATE = 'SET_IS_CLONING_RULE_STATE';
export const SET_IS_SHOWING_CLONE_RULE_MODAL =
  'SET_IS_SHOWING_CLONE_RULE_MODAL';
export const SET_RECORD_FOR_DETAIL_PANE = 'SET_RECORD_FOR_DETAIL_PANE';
export const SET_RULE_TYPE_COMMIT_MODAL_OPEN =
  'SET_RULE_TYPE_COMMIT_MODAL_OPEN';
export const TRIGGER_COMMIT_RULE_FOR_RULE_TYPE =
  'TRIGGER_COMMIT_RULE_FOR_RULE_TYPE';
export const COMMIT_RULE_FOR_RULE_TYPE_SUCCESS =
  'COMMIT_RULE_FOR_RULE_TYPE_SUCCESS';
export const COMMIT_RULE_FOR_RULETYPE_ERROR = 'COMMIT_RULE_FOR_RULETYPE_ERROR';
export const SET_IS_COMMITTING_RULE = 'SET_IS_COMMITTING_RULE';
export const SET_IS_SHOWING_ACTIVE_GROUPS_LISTING_MODAL =
  'SET_IS_SHOWING_ACTIVE_GROUPS_LISTING_MODAL';
export const SET_IS_SHOWING_REVERT_RULE_MODAL = 'SET_IS_SHOWING_REVERT_RULE_MODAL';
export const GET_REVERT_RULE_VERSION_TRIGGER = 'GET_REVERT_RULE_VERSION_TRIGGER';
export const GET_REVERT_RULE_VERSION_SUCCESS = 'GET_REVERT_RULE_VERSION_SUCCESS';
export const GET_REVERT_RULE_VERSION_ERROR = 'GET_REVERT_RULE_VERSION_ERROR';
export const POST_REVERT_RULE_VERSION_TRIGGER = 'POST_REVERT_RULE_VERSION_TRIGGER';
export const POST_REVERT_RULE_VERSION_SUCCESS = 'POST_REVERT_RULE_VERSION_SUCCESS';
export const POST_REVERT_RULE_VERSION_ERROR = 'POST_REVERT_RULE_VERSION_ERROR';
export const RESET_STATE =
  'RESET_STATE';