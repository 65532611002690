/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { 
  useLayout,
} from 'ui-core/dist';
import {detailPaneAnimStates} from '../../commonResources/constants/animStates';
import './Modules/styles.scss';
import DetailPaneHeader from '../../components/ErrorDisplaySection/DetailPaneHeaderWrapper';
import ErrorManagerDetail from './ErrorManagerDetail';

const ErrorManagerDetailPane = () => {
  const {
    expandCollapseDetailPaneButtonConfig, 
    closeDetailPaneButtonConfig,
    detailAnimationState,
    detailProps
  } = useLayout()
  

  return (
    <>
      {detailAnimationState>=detailPaneAnimStates.EXPANDED?(
        <>
          <DetailPaneHeader
            title="Error Details"
            layoutControls={[expandCollapseDetailPaneButtonConfig, closeDetailPaneButtonConfig]}
          />
          <ErrorManagerDetail />
        </>
      ):null}
    </>
  )
}

export default ErrorManagerDetailPane
