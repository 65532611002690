import styled from 'styled-components';

export const ScrollableGridWrapper = styled.div`
  max-height: 45vh;
  margin-bottom: 10vh;
  overflow: auto;
`;

export const ModalButton = styled.div`
  margin-top: 95px;
  margin-bottom: 40px;
  &.button:last-child {
    margin-left: 30px;
`;
