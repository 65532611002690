import {
  ON_AVATAR_MENU_SELECT,
  USER_SET,
  TOGGLE_AUTH_MODAL_OPEN,
  SET_SUPPORT_ITEMS
} from './constants';

export const onAvatarMenuSelect = state => ({
  type: ON_AVATAR_MENU_SELECT,
  state,
});

export const setUser = state => ({
  type: USER_SET,
  state,
});

export const toggleAuthModalOpen = state => ({
  type: TOGGLE_AUTH_MODAL_OPEN,
  state,
});

export const setSupportItems = state => ({
  type: SET_SUPPORT_ITEMS,
  state,
});