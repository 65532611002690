import React, { useState } from 'react'
import { 
  Grid, 
  useLayout,
  ListPaneHeader,
} from 'ui-core/dist'
import { GridPaginationWrapper, Loading, SelectDropdown } from '../../components'
import ErrorPage from '../ErrorPage';
import ListPaneHeaderWrapper from '../../components/ErrorDisplaySection/ListPaneHeaderWrapper';
import useDataSources from '../../api/useDataSources'
import withAxiosCancellation from '../../components/withAxiosCancellation'
import './modules/styles.scss'

const FirstFilter = ({ onFirstFilter, initialValue }) => (
  <div style={{
    width: '150px',
    marginLeft: '5px',
    marginRight: '5px'
  }}>
    <SelectDropdown
      domID="data-source-manager__first-filter"
      isClearable={false}
      label=""
      options={[
        {
          label: 'Active',
          value: false
        },
        {
          label: 'Inactive',
          value: true
        }
      ]}
      size="small"
      initialValue={initialValue}
      onChange={onFirstFilter}
    />
  </div>
)


const DataSourceManagerListPane = ({getCancelSourceWithName}) => {
  const [ rowsPerPage, setRowsPerPage ] = useState(10);
  const [ pageIndex, setPageIndex ] = useState(0);
  const [ sortField, setSortField] = useState('id')
  const [ sortDescending, setSortDecending ] = useState(false)
  // const [ isModalOpen, setIsModalOpen ] = useState(false)
  const [ viewInactive, setViewInactive ] = useState({
    label: 'Active',
    value: false
  })
  const [ payload, setPayload ] = useState({
    maxRecords: rowsPerPage,
    rowPosition: pageIndex,
    sortField,
    sortDescending,
    viewInactive: viewInactive.value,
  })
  // const canView = useSelector(({ context }) => 
  //   context?.permissions?.filter(p => p.name === 'CAN_PERFORM_SECURITY_DATASOURCE_VIEW').length > 0)
  const {
    expandCollapseListPaneButtonConfig,
  } = useLayout()
  const { data, isLoading, total, isError, error } = useDataSources({ payload, getCancelSource:getCancelSourceWithName('dataSources') })

  const onSortGridColumn = (e, { sortingKey }) => {
    const splitKey = sortingKey.split('|')
    setSortField(splitKey[0])
    if (splitKey[1] ==='SORT_DESCENDING') setSortDecending(true)
    else setSortDecending(false)
    setPayload({
      ...payload,
      sortField: splitKey[0],
      sortDescending: splitKey[1] === 'SORT_DESCENDING'
    })
  }

  const sortingComparator = (sortDataName, sortDirectionString, recordsToSort) =>  recordsToSort

  const handleShowButton = ({ pi }) => {
    if (viewInactive.value !== payload.viewInactive) {
      setPayload({
        maxRecords: 10,
        rowPosition: 0,
        sortField: 'id',
        sortDescending: false,
        viewInactive: viewInactive.value,
      })
      setRowsPerPage(10)
      setPageIndex(0)
      setSortField('id')
      setSortDecending(false)
    } else {
      setPageIndex(pi)
      setPayload({
        maxRecords: rowsPerPage,
        rowPosition: pi,
        sortField,
        sortDescending,
        viewInactive: viewInactive.value,
      })
    }
  }

  const onFirstFilter  = (state) => setViewInactive(state)

  // const onClickThrough = (e, item) => setIsModalOpen(item)

  // const onClose = () => setIsModalOpen(false)

  return (
    <>
      <ListPaneHeaderWrapper
        title="Data Source Manager" 
        layoutControls={[expandCollapseListPaneButtonConfig]}
      />
      <GridPaginationWrapper
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={setRowsPerPage}
        pageIndex={pageIndex}
        onChangePageIndex={setPageIndex}
        onShowButtonCLick={handleShowButton}
        totalRecords={total}
        defaultRowsPerPage={10}
        FirstFilter={() => (
          <FirstFilter onFirstFilter={onFirstFilter} initialValue={viewInactive}/>
        )}
      />
      {isLoading ? <Loading /> : 
      isError ? (
      // replace with a better error component
      <ErrorPage
        // this prop isn't real but is an example
        error={error}
      />
    ) : (
        <Grid
          columns={new Set([
            {
              dataName: 'id',
              text: 'Data Sourse Id',
              sortable: true,
              isSorted: 0,
              cellType: 'text',
            },
            {
              dataName: 'description',
              text: 'Description',
              sortable: true,
              isSorted: 0,
              cellType: 'text',
            },
            {
              dataName: 'definition',
              text: 'Definition',
              sortable: true,
              isSorted: 0,
              cellType: 'text',
            },
          ])}
          records={data || []}
          dataTestId="test-UserManagerGrid"
          domID="UserManagerGrid"
          onSortGridColumn={onSortGridColumn}
          selectAllCheckboxDomID="UserManagerGridSelectAll"
          initialSortingKey={`${sortField}|${sortDescending ? 'SORT_DESCENDING' : 'SORT_ASCENDING'}`}
          sortingComparator={sortingComparator}
          // onClickThrough={canView && onClickThrough}
        />
      )}
      {/* <Modal isOpen={isModalOpen} className="data-source-modal">
        <ModalHeader 
          title="Data Source Details"
          description={`Details for data source ${isModalOpen?.id}`}
          onClose={onClose}
        />
        <ModalBody className="data-source-modal__contents">
          <div className="data-source-modal__row">
            <span className="data-source-modal__label">Data Source Definition:</span>
            <span className="data-source-modal__info">{isModalOpen?.definition}</span>
          </div>
          <div className="data-source-modal__row">
            <span className="data-source-modal__label">Data Source Description:</span>
            <span className="data-source-modal__info">{isModalOpen?.description}</span>
          </div>
          <div className="data-source-modal__row">
            <span className="data-source-modal__label">Is Active:</span>
            <span className="data-source-modal__info">{isModalOpen?.isActive === 'true' ? 'Active' : 'Inactive'}</span>
          </div>
        </ModalBody>
      </Modal> */}
    </>
  )
}

export default withAxiosCancellation(DataSourceManagerListPane)