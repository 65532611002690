import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Portal } from 'react-portal';
import { Button, Icons, NotificationBanner, LoadingIndicator } from 'ui-core-ssgr';
import has from 'lodash/has';
import TestAssocDetailsGrid from '../TestAssocDetailsGrid';
import { PaddingWrapper } from '../../../../shared/styles/spacing';
import { GridLoadingWrapper, LoadingContainer } from '../../../../shared/styles/styledComponents';
import AddRuleTypeModal from '../AddRuleTypeModal/Modal';
import GroupAssociationsModal from '../TestAssocDetailsGrid/components/GroupAssociationsModal';
import {
  RuleTypesFilter,
  RuleTypesDetailRow,
  SubDetailHeader,
  DefaultModalHeight,
  ButtonContainer,
} from './styles';

class TestAssocContent extends Component {
  state = {
    errorMessage: '',
    inputError: false,
    queryValue: '',
    isButtonDisabled: false,
    selectedItemIds: new Set([]),
    ruleIds: {},
  };

  componentDidMount = () => {
    const {
      setRuleTypeRequestTrigger,
      deleteAllNotifications,
      selectedRecord,
      triggerRuleTypesRequest,
      resetRuleTypes,
    } = this.props;
    const id = get(selectedRecord, 'id', '');

    resetRuleTypes();
    deleteAllNotifications();
    triggerRuleTypesRequest({ groupId: id });
    setRuleTypeRequestTrigger();
  };

  componentDidUpdate = prevProps => {
    const {
      selectedRuleTypeId,
      resetRuleTypesListReceived,
    } = this.props;
    const { queryValue } = this.state;
    if (
      prevProps.selectedRuleTypeId &&
      selectedRuleTypeId &&
      prevProps.selectedRuleTypeId !== selectedRuleTypeId
    ) {
      let errorMessage = '';
      if (
        !selectedRuleTypeId &&
        queryValue.length < 3 &&
        queryValue.length > 0
      ) {
        errorMessage = 'Please expand one rule type to search';
      } else if (selectedRuleTypeId && queryValue.length > 0) {
        errorMessage =
          'Please enter at least three valid characters - alphanumeric, space, - or _ is allowed.';
      }
      this.setState({
        inputError:
          !selectedRuleTypeId && queryValue.length < 3 && queryValue.length > 0,
        errorMessage,
      });
      resetRuleTypesListReceived();
    }
  };

  componentWillUnmount = () => {
    // reset rule type ID for search when component unmounts
    const { setSelectedRuleTypeId, resetRuleTypesListReceived } = this.props;
    setSelectedRuleTypeId(null);
    resetRuleTypesListReceived();
    this.setState({
      queryValue: '',
    });
  };

  onInputBlur = () => {
    const { selectedRuleTypeId, setRuleTypesSearchQuery } = this.props;
    const { queryValue } = this.state;
    if (!queryValue.length && !selectedRuleTypeId) {
      this.setState({
        inputError: false,
        errorMessage: '',
      });
      setRuleTypesSearchQuery(queryValue);
    }
  };

  activeSSEnabledRuleTypes = () => {
    const { ruleTypesList } = this.props;
    const ruleTypeRecords = get(ruleTypesList, 'ruleTypesAccumRecords', []);
    return ruleTypeRecords.filter(
      type =>
        type.id === 'PAR' ||
        type.id === 'RAD' ||
        type.id === 'HLD' ||
        type.id === 'CHT' ||
        type.id === 'CSM' ||
        type.id === 'DPX' ||
        type.id === 'ERI' ||
        type.id === 'GEN' ||
        type.id === 'HDR' ||
        type.id === 'IMG' ||
        type.id === 'PIO' ||
        type.id === 'SSN' ||
        type.id === 'SIG' ||
        type.id === 'TAX',
    );
  };

  onInputChange = e => {
    const {
      selectedRecord,
      setRuleTypesSearchQuery,
      triggerRuleTypesRequest,
    } = this.props;
    const { value } = e.target;

    if (value.length === 0) {
      triggerRuleTypesRequest({
        groupId: selectedRecord.id,
        ruleType: null,
        queryValue: '',
      });
    }
    this.setState({
      queryValue: value,
    });
    setRuleTypesSearchQuery(value);
    this.validateInput(value);
  };

  onInputFocus = e => {
    const { value } = e.target;
    this.validateInput(value);
  };

  onNotificationDismiss = id => {
    const { deleteNotification } = this.props;
    deleteNotification(id);
  };

  onSearch = () => {
    const {
      selectedRecord,
      selectedRuleTypeId,
      setRuleTypesSearchQuery,
      triggerRuleTypesRequest,
    } = this.props;
    const { inputError, queryValue } = this.state;
    if (!inputError && queryValue.length >= 3) {
      triggerRuleTypesRequest({
        groupId: selectedRecord.id,
        ruleType: selectedRuleTypeId,
        queryValue,
      });
      setRuleTypesSearchQuery(queryValue);
    }
  };

  validateInput = value => {
    const { selectedRuleTypeId } = this.props;
    const checkValue = /^[a-zA-Z0-9-_ ]*$/;
    const inputError = !(
      selectedRuleTypeId &&
      checkValue.test(value) &&
      value.length >= 3
    );
    const errorMessage = !selectedRuleTypeId
      ? 'Please expand one rule type to search'
      : 'Please enter at least three valid characters - alphanumeric, space, - or _ is allowed.';
    this.setState({
      inputError,
      errorMessage,
    });
  };

  toggleRuleTypeSelectionModal = records => {
    const { setRuleTypeModalOpen } = this.props;
    if (records.length > 0) {
      setRuleTypeModalOpen(true);
    } else {
      setRuleTypeModalOpen(false);
    }
  };

  updateSelectedRuleType = (ruleName, ruleTypeIds, method) => {
    const { ruleIds } = this.state;
    if (method === 'delete') {
      delete ruleIds[ruleName];
    } else {
      ruleIds[ruleName] = ruleTypeIds;
    }

    const rIds = Object.values(ruleIds);
    this.setState({
      ruleIds,
      selectedItemIds: new Set(rIds),
    });
  };

  associateHandle = e => {
    e.preventDefault();
    e.stopPropagation();

    const { setIsViewingGroupAssociationsModal } = this.props;
    setIsViewingGroupAssociationsModal({
      showing: true,
      associatedRuleType: null,
    });
  };

  render() {
    const { onNotificationDismiss, activeSSEnabledRuleTypes } = this;
    const {
      selectedRecord,
      setRuleTypeModalOpen,
      ruleTypeModalIsOpen,
      ruleTypeGridRecords,
      setIsViewingGroupAssociationsModal,
      postGroupAssociationsForRuleTypeTrigger,
      notifications,
      isShowingGroupAssociationModal,
      triggerRuleTypesRequest,
      loading,
      selectedRuleType,
      associatedRuleType,
      associating,
      isExpanded,
      userRole,
      userPermissions
    } = this.props;
    const {
      inputError,
      errorMessage,
      isButtonDisabled,
      ruleIds,
      queryValue,
    } = this.state;
    const clonedSelectedRecord = JSON.parse(JSON.stringify(selectedRecord));
    const rIds = Object.values(ruleIds);
    const rNames = Object.keys(ruleIds);
    let selectedRuleTypes = [];

    if (clonedSelectedRecord && clonedSelectedRecord.ruleTypes) {
      clonedSelectedRecord.ruleTypes
        .filter(
          ruleType =>
            ruleType.id !== 'PAR' &&
            ruleType.id !== 'RAD' &&
            ruleType.rules &&
            ruleType.rules.find(rule => rule.associated === 'Yes'),
        )
        .forEach(({ rules }) =>
          rules.push({
            id: 'None',
            name: 'None',
            associated: '',
            status: [],
            version: [],
          }),
        );

      selectedRuleTypes = clonedSelectedRecord.ruleTypes
        .filter(ruleType => rNames.includes(ruleType.name))
        .filter(({ rules }) =>
          rules.map(rule => rIds.includes(rule.id) && rule.associated === 'No'),
        );
    }

    const filterSelectedRuleTypes = associatedRuleType
      ? selectedRuleTypes.filter(type => type.id === associatedRuleType)
      : selectedRuleTypes;

    const hasSelectedNewRules =
      selectedRuleTypes
        .map(({ rules }) => rules.find(rule => rIds.includes(rule.id)))
        .filter(rule => rule && rule.associated === 'No').length > 1;

    const generatesubRecordsCount = record =>
      has(record, 'ruleTypes') && Array.isArray(record.ruleTypes)
        ? `${record.ruleTypes.length}`
        : `0`;

    return (
      <div>
        <PaddingWrapper>
          {notifications.size > 0 && (
            <Portal key="accordion-panel">
              {[...notifications].map(([key, value]) => (
                <NotificationBanner
                  key={[key]}
                  domID={`${key}-notification`}
                  type={value.type}
                  text={value.msg}
                  onDismiss={() => onNotificationDismiss(key)}
                  autoDismiss
                  timer={1800000}
                  icon={value.type === 'neutral' ? 'Info' : null}
                />
              ))}
            </Portal>
          )}
          <SubDetailHeader
            domID="rule-type-header"
            title="Rule Types"
            count={generatesubRecordsCount(selectedRecord)}
          />
          <RuleTypesDetailRow>
            <Button
              name="Add Rule Type"
              buttonType="deEmphasized"
              onClick={() => this.toggleRuleTypeSelectionModal(ruleTypeGridRecords)}
              size="small"
              type="button"
              className="add-rule-type"
              // disabling the add rule type button hard coded for now with the 14 rule types available. once we have an endpoint that gives us all the rule types available, we can make this dynamic.
              disabled={
                activeSSEnabledRuleTypes().length > 13 ||
                selectedRecord.status.toLowerCase() === 'termed'
              }
            />
            {hasSelectedNewRules && (
              selectedRecord.status.toLowerCase() !== 'termed' && 
              <ButtonContainer>
                <Button
                  name="Associate"
                  buttonType="deEmphasized"
                  onClick={this.associateHandle}
                  size="small"
                  type="button"
                />
              </ButtonContainer>
            )}
            <RuleTypesFilter
              domID="rule-type-filter"
              onEnterPress={this.onSearch}
              onButtonClick={this.onSearch}
              onChange={this.onInputChange}
              onBlur={this.onInputBlur}
              onFocus={this.onInputFocus}
              placeholder="Search by name or description"
              errorMessage={errorMessage}
              hasError={inputError}
              initialValue={queryValue}
              buttonContent={
                <Icons.Search
                  fillColor="#666"
                  className="search-icon"
                  title="search"
                />
              }
            />
          </RuleTypesDetailRow>
        </PaddingWrapper>
        {loading.has('ruleTypesGridLoading') ? (
          <LoadingContainer>
            <GridLoadingWrapper>
              <LoadingIndicator
                domID="group-grid-loading-indicator"
                length="45px"
              />
            </GridLoadingWrapper>
          </LoadingContainer>
        ) : (
          <TestAssocDetailsGrid
            {...this.props}
            isExpanded={isExpanded}
            updateSelectedRuleType={this.updateSelectedRuleType}
            activeSSEnabledRuleTypes={activeSSEnabledRuleTypes()}
            userRole={userRole}
            userPermissions={userPermissions}
          />
        )}
        <DefaultModalHeight>
          <AddRuleTypeModal
            isOpen={ruleTypeModalIsOpen}
            onModalToggle={() => setRuleTypeModalOpen(false)}
            records={ruleTypeGridRecords}
            selectedRecord={selectedRecord}
            triggerRuleTypesRequest={triggerRuleTypesRequest}
          />
        </DefaultModalHeight>
        <GroupAssociationsModal
          ruleType={filterSelectedRuleTypes[0]}
          selectedRuleTypes={filterSelectedRuleTypes}
          selectedRecord={selectedRecord}
          selectedRuleIds={ruleIds}
          loading={loading}
          setIsViewingGroupAssociationsModal={
            setIsViewingGroupAssociationsModal
          }
          postGroupAssociationsForRuleTypeTrigger={
            postGroupAssociationsForRuleTypeTrigger
          }
          isShowingGroupAssociationModal={isShowingGroupAssociationModal}
          isButtonDisabled={isButtonDisabled}
          selectedRuleType={selectedRuleType}
          associating={associating}
        />
      </div>
    );
  }
}

TestAssocContent.propTypes = {
  setRuleTypeModalOpen: PropTypes.func,
  ruleTypeModalIsOpen: PropTypes.bool,
  selectedRecord: PropTypes.object,
  setIsViewingRuleDetails: PropTypes.func,
  setRuleTypeRequestTrigger: PropTypes.func,
  ruleTypeGridRecords: PropTypes.array,
  setIsViewingGroupAssociationsModal: PropTypes.func,
  postGroupAssociationsForRuleTypeTrigger: PropTypes.func,
  rulesForRuleTypesList: PropTypes.object,
  notifications: PropTypes.object,
  deleteNotification: PropTypes.func,
  triggerRuleTypesRequest: PropTypes.func,
  resetRuleTypesListReceived: PropTypes.func,
  deleteAllNotifications: PropTypes.func,
  selectedRuleTypeId: PropTypes.string,
  setSelectedRuleTypeId: PropTypes.func,
  isShowingGroupAssociationModal: PropTypes.bool,
  setRuleTypesSearchQuery: PropTypes.func,
  loading: PropTypes.object,
  selectedRuleType: PropTypes.object,
  associatedRuleType: PropTypes.string,
  associating: PropTypes.bool,
  ruleTypesList: PropTypes.object,
  resetRuleTypes: PropTypes.func,
  userRole: PropTypes.string,
  userPermissions: PropTypes.string
};

export default TestAssocContent;
