import React from 'react';
import moment from 'moment';
import './index.scss';

const TimeoutNotification = () => {
    const expiryDate = '08/15/2022';

    return (
        <>
            {moment().isBefore(moment(expiryDate)) && <h1 id='TimeoutNotification'>Welcome to the newly designed CAS! Please&nbsp;
            <a href='https://player.vimeo.com/progressive_redirect/playback/696143585/rendition/720p?loc=external&signature=e4fd2ed92fc6dd807e81ae205942d0108449653c8d98d0946640b605c315c44e' target='_blank'>watch this video</a> to see what's new.
        </h1>}
        </>
    );
};

export default TimeoutNotification;
