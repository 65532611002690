import React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import { Page } from '../components';
import RouteWrapper from '../components/RouteWrapper';
import UserReport from '../containers/ReportManager/UserReport';
import BillingReport from '../containers/ReportManager/BillingReport'
import VendorLayoutReport from '../containers/ReportManager/VendorLayoutReport';
import DeliveryReport from '../containers/ReportManager/DeliveryReport';
import ClientImageReport from '../containers/ReportManager/ClientImageReport';
import ACSReport from '../containers/ReportManager/ACSReport';

import ClientInformationReport from '../containers/ReportManager/ClientInformationReport';
import DataScriptReport from '../containers/ReportManager/DataScriptReport';
import ClientSpecificReports from '../containers/ReportManager/ClientSpecificReports'
import OffHoursActivityReport from '../containers/ReportManager/OffHoursActivityReport';
import MailDeliveryReport from '../containers/ReportManager/MailDeliveryReport';
import EftHistoryReport from '../containers/ReportManager/EftHistoryReport';
import EftProviderReport from '../containers/ReportManager/EftProviderReport';
import OnHoldReport from '../containers/ReportManager/OnHoldReport';
import UnauthorizedAccessReport from '../containers/ReportManager/UnauthorizedAccessReport';


const UserReportContainerPage = () => (
<RouteWrapper>
  <Page
    ListPaneContent={UserReport}
  />
</RouteWrapper>
);
const BillingReportContainerPage = () => (
<RouteWrapper>
  <Page
    ListPaneContent={BillingReport}
  />
</RouteWrapper>
);
const ACSReportContainerPage = () => (
<RouteWrapper>
  <Page
    ListPaneContent={ACSReport}
  />
</RouteWrapper>
);

const VendorLayoutReportContainerPage = () => (
<RouteWrapper>
  <Page
    ListPaneContent={VendorLayoutReport}
  />
</RouteWrapper>
);
const DataDictionaryReportContainerPage = () => (
<RouteWrapper>
  <Page
    ListPaneContent={DataScriptReport}
  />
</RouteWrapper>
);

const ClientInformationReportContainerPage = () => (
<RouteWrapper>
  <Page
    ListPaneContent={ClientInformationReport}
  />
</RouteWrapper>
);
const MailDeliveryReportContainerPage = () => (
<RouteWrapper>
  <Page
    ListPaneContent={MailDeliveryReport}
  />
</RouteWrapper>
);

const ClientSpecificReportsContainerPage = () => (
<RouteWrapper>
  <Page
    ListPaneContent={ClientSpecificReports}
  />
</RouteWrapper>
);

const OffHoursActivityReportContainerPage = () => (
<RouteWrapper>
  <Page
    ListPaneContent={OffHoursActivityReport}
  />
</RouteWrapper>
);

const EftProviderReportContainerPage = () => (
<RouteWrapper>
  <Page
    ListPaneContent={EftProviderReport}
  />
</RouteWrapper>
);

const ClientImageReportContainerPage = () => (
<RouteWrapper>
  <Page
    ListPaneContent={ClientImageReport}
  />
</RouteWrapper>
);

const OnHoldReportPage=()=>(
<RouteWrapper>
  <Page
    ListPaneContent={OnHoldReport}
  />
</RouteWrapper>
)

const UnAuthorizedAccessReportPage=()=>(
<RouteWrapper>
  <Page
    ListPaneContent={UnauthorizedAccessReport}
  />
</RouteWrapper>
)

const EftHistoryReportPage=()=>(
<RouteWrapper>
  <Page
    ListPaneContent={EftHistoryReport}
  />
</RouteWrapper>
)

const ReportManagerRoutes = () => (
  <Switch>
    <Route path="/ReportManager/ACSReport" component={ACSReportContainerPage} />
    <Route path="/ReportManager/BillingReport" component={BillingReportContainerPage} />
    <Route path="/ReportManager/ChecksClearedReport" component={Page} />
    <Route path="/ReportManager/ClientImageReport" component={ClientImageReportContainerPage} />
    <Route path="/ReportManager/ClientInfo" component={ClientInformationReportContainerPage} />
    <Route path="/ReportManager/GenericReports" component={ClientSpecificReportsContainerPage} />
    <Route path="/ReportManager/DataScript" component={DataDictionaryReportContainerPage} />
    <Route path="/ReportManager/EFTHistory" component={EftHistoryReportPage} />
    <Route path="/ReportManager/GenericReports" component={Page} />
    <Route path="/ReportManager/DataScript" component={DataDictionaryReportContainerPage} />
    <Route path="/ReportManager/EftProvider" component={EftProviderReportContainerPage} />
    <Route path="/ReportManager/Reports" component={Page} />
    <Route path="/ReportManager/MailDeliveryReport" component={MailDeliveryReportContainerPage} />
    <Route path="/ReportManager/OffHoursActivity" component={OffHoursActivityReportContainerPage} />
    <Route path="/ReportManager/OnHoldReport" component={OnHoldReportPage} />
    <Route path="/ReportManager/UnauthorizedAccess" component={UnAuthorizedAccessReportPage} />
    <Route path="/ReportManager/USDelivery" component={DeliveryReport} />
    <Route path="/ReportManager/UserReport" component={UserReportContainerPage} />
    <Route path="/ReportManager/VendorLayout" component={VendorLayoutReportContainerPage} />
    <Route exact path="/ReportManager">
      <Redirect to="/ReportManager/ACSReport" />
    </Route>
  </Switch>
)

export default ReportManagerRoutes;