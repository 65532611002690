import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Grid, Button, LoadingIndicator, NotificationBanner } from 'ui-core-ssgr';
import { Portal } from 'react-portal';
import ClickableRow from '../ClickableRow';
import GroupGridHeader from '../GridHeader';
import { gridConfig } from './constants';
import { IS_EDIT_ROLE } from '../../../GroupDetailView/constants';
import {
  LoadMoreButton,
  InputLabelWrapper,
  LoadingWrapper,
  LoadingContainer,
  GridLoadingWrapper,
} from './styles';

class GroupGridView extends Component {
  state = {
    selectedItemIds: new Set([]),
    queryValue: '',
    sortOrder: 'desc',
    name: 'dateModified',
    status: '',
    disableButton: false,
  };

  componentDidMount = () => {
    const { getFreshGroupsList } = this;
    const status = queryString.parse(window.location.search).group || 'all';

    this.setState({ status }, () => {
      getFreshGroupsList();
    });
  };

  componentDidUpdate = prevProps => {
    const {
      isCloningGroup,
      isActivatingGroup,
      isDeactivatingGroup,
      recordCountByStatusAndKeywordTriggerDB,
    } = this.props;
    const { queryValue, status, sortOrder, name } = this.state;
    if (
      (prevProps.isCloningGroup && !isCloningGroup) ||
      (prevProps.isActivatingGroup && !isActivatingGroup) ||
      (prevProps.isDeactivatingGroup && !isDeactivatingGroup)
    ) {
      recordCountByStatusAndKeywordTriggerDB({
        status,
        name,
        sortOrder,
        queryValue,
      });
      this.setState({ selectedItemIds: new Set([]) });
    }
  };

  componentWillUpdate(nextProps, nextState) {
    const {
      groupGridQuery,
      recordCountByStatusAndKeywordTriggerDB,
    } = this.props;
    const { queryValue } = this.state;
    if (nextProps.status.length) {
      if (nextProps.status !== nextState.status) {
        if (nextProps.status === 'all') {
          recordCountByStatusAndKeywordTriggerDB({
            status: nextProps.status,
            name: nextState.name,
            sortOrder: nextState.sortOrder,
            queryValue: '',
          });
          this.setState({ queryValue: '', status: 'all' });
        }
      }

      if (
        queryValue &&
        groupGridQuery &&
        queryValue.length &&
        groupGridQuery.length
      ) {
        if (nextProps.groupGridQuery.length === 0) {
          recordCountByStatusAndKeywordTriggerDB({
            status: nextProps.status,
            name: nextState.name,
            sortOrder: nextState.sortOrder,
            queryValue: '',
          });
          this.setState({ queryValue: '' });
        }
      }
    }
  }

  getFreshGroupsList = (_, sortObj) => {
    const {
      triggerAllGroupsRequest,
      recordCountByStatusAndKeywordTriggerDB,
      resetGroupsRecords,
    } = this.props;
    const { sortingKey } = sortObj || { sortingKey: '' };
    const [columnName, direction] = sortingKey.split('|');
    const { queryValue, status } = this.state;
    let { sortOrder, name } = this.state;

    if (columnName) name = columnName;
    if (direction) sortOrder = direction === 'SORT_ASCENDING' ? 'asc' : 'desc';
    //'desc' : 'asc';
    resetGroupsRecords();
    recordCountByStatusAndKeywordTriggerDB({
      status,
      name,
      sortOrder,
      queryValue,
    });
    triggerAllGroupsRequest({ status, name, sortOrder, queryValue });
    this.setState({ name, sortOrder, queryValue });
  };

  generateGridRecordsFromProps(records) {
    return records.map(record => {
      const newRecord = {
        ...record,
        completed: { bad: record.completed, nonCompliant: record.completed },
      };
      return newRecord;
    });
  }

  onGridSelectAll = () => {
    const { selectedItemIds } = this.state;
    const { records } = this.props;
    const alreadyFull = selectedItemIds.size === records.length;
    const ids = alreadyFull ? [] : records.map(({ id }) => id);

    this.setState({ selectedItemIds: new Set(ids) });
  };

  onRowClickThrough = (e, { id }) => {
    const {
      toggleDetailsView,
      setSelectedDetailsRecordId,
      resetDetailsPane,
      notifications,
      setIsCloningGroupState,
      captureCurrentPage
    } = this.props;
    notifications.clear();
    setIsCloningGroupState(false);
    setSelectedDetailsRecordId({ id });
    toggleDetailsView(true);
    resetDetailsPane();
    // following action is called to capture new relic data, render basic as it lands on basic tab as user click on any row
    const pageTrackerPrefix = 'basic';
    captureCurrentPage({pageId: `${pageTrackerPrefix}`});
  };

  onRowSelect = ({ id }) => {
    const { setSelectedDetailsRecordId, triggerRuleTypesRequest, notifications } = this.props;
    const { selectedItemIds } = this.state;
    const isBeingSelected = !selectedItemIds.has(id);
    const method = isBeingSelected ? 'add' : 'delete';
    selectedItemIds[method](id);
    const newSelectedId = [...selectedItemIds][0];
    if (selectedItemIds.size === 1) {
      triggerRuleTypesRequest({ groupId: newSelectedId });
      setSelectedDetailsRecordId({ id: newSelectedId });
      notifications.clear();
    }
    this.setState({ selectedItemIds });
  };

  getHeaderPropsForRoute = count => {
    const {groupsList} = this.props;
    if (groupsList.status.length) {
      if (groupsList.status === 'all')
        return { headerText: 'All Groups', headerCount: count };
      if (groupsList.status === 'active')
        return { headerText: 'Active Groups', headerCount: count };
      if (groupsList.status === 'inactive')
        return { headerText: 'Inactive Groups', headerCount: count };
      if (groupsList.status === 'termed')
        return { headerText: 'Termed Groups', headerCount: count };
    }
    return { headerText: '', headerCount: 0 };
  };

  loadMore = () => {
    const { status, name, sortOrder, queryValue } = this.state;
    const { triggerAllGroupsRequest, records } = this.props;
    this.setState({ disableButton: true });

    triggerAllGroupsRequest({
      offset: records.length,
      sortOrder,
      name,
      status,
      queryValue,
    });

    this.setState({ disableButton: false });
  };

  handleQueryUpdate = queryValue => {
    const { records } = this.props;
    const { getFreshGroupsList } = this;
    this.setState({ queryValue }, () => {
      getFreshGroupsList();
      if (records.length === 0) {
        this.setState({ queryValue: '' });
      }
    });
  };

  render() {
    const {
      addNotification,
      deleteNotification,
      records,
      loadMore,
      isCloningGroup,
      isExpanded,
      isShowingActivateGroupModal,
      isShowingDeactivateGroupModal,
      loading,
      setIsShowingCloneGroupModal,
      notifications,
      count,
      recordCountByStatusAndKeywordTriggerDB,
      resetDetailsPane,
      selectedRecord,
      setIsCloningGroupState,
      setIsShowingActivateGroupModal,
      setIsShowingDeactivateGroupModal,
      isMulti,
      setSelectedDetailsRecordId,
      showCloneGroupModal,
      toggleDetailsView,
      triggerActivateGroupRequest,
      triggerDeactivateGroupRequest,
      triggerRuleTypesRequest,
      resetRuleTypesListReceived,
      setDefaultGroupDetailsTab,
      ruleTypesList,
      userRole,
    } = this.props;
    const { selectedItemIds, disableButton } = this.state;
    const {
      onGridSelectAll,
      onRowClickThrough,
      onRowSelect,
      getHeaderPropsForRoute,
      handleQueryUpdate,
    } = this;

    const { numActive, numInactive } = records
      .filter(({ id }) => selectedItemIds.has(id))
      .reduce(
        (carry, { status }) => ({
          numActive:
            status === 'Active' && selectedRecord
              ? carry.numActive + 1
              : carry.numActive,
          numInactive:
            status === 'Inactive' && selectedRecord
              ? carry.numInactive + 1
              : carry.numInactive,
        }),
        { numActive: 0, numInactive: 0 },
      );

    const counterLabel = !loading.has('groupsListGridLoading')
      ? `${records.length}/${getHeaderPropsForRoute(count).headerCount}`
      : '';
    return (
      <>
        {notifications.size > 0 && (
          <Portal key="rules-grid-wrapper">
            {notifications
              ? [...notifications].map(([key, value]) => (
                <NotificationBanner
                  key={[key]}
                  domID={`${key}-notification`}
                  type={value.type}
                  text={value.msg}
                  onDismiss={() => deleteNotification(key)}
                  autoDismiss
                  timer={1800000}
                  icon={value.type === 'neutral' ? 'Info' : null}
                />
              ))
              : null}
          </Portal>
        )}
        <GroupGridHeader
          addNotification={addNotification}
          setDefaultGroupDetailsTab={setDefaultGroupDetailsTab}
          deleteNotification={deleteNotification}
          groupGridQueryUpdate={handleQueryUpdate}
          triggerCountUpdate={recordCountByStatusAndKeywordTriggerDB}
          toggleDetailsView={toggleDetailsView}
          headerName={getHeaderPropsForRoute(count).headerText}
          isCloningGroup={isCloningGroup}
          isExpanded={isExpanded}
          isShowingActivateGroupModal={isShowingActivateGroupModal}
          isShowingDeactivateGroupModal={isShowingDeactivateGroupModal}
          loading={loading}
          onCancel={onRowClickThrough}
          setIsCloningGroupState={setIsCloningGroupState}
          setIsShowingActivateGroupModal={setIsShowingActivateGroupModal}
          setIsShowingDeactivateGroupModal={setIsShowingDeactivateGroupModal}
          isMulti={isMulti}
          setIsShowingCloneGroupModal={setIsShowingCloneGroupModal}
          setSelectedDetailsRecordId={setSelectedDetailsRecordId}
          notifications={notifications}
          records={this.generateGridRecordsFromProps(records)}
          resetDetailsPane={resetDetailsPane}
          selectedItemIds={selectedItemIds}
          selectedRecord={selectedRecord}
          showCloneGroupModal={showCloneGroupModal}
          triggerActivateGroupRequest={triggerActivateGroupRequest}
          triggerDeactivateGroupRequest={triggerDeactivateGroupRequest}
          triggerRuleTypesRequest={triggerRuleTypesRequest}
          numActive={numActive}
          numInactive={numInactive}
          counterLabel={counterLabel}
          resetRuleTypesListReceived={resetRuleTypesListReceived}
          ruleTypesList={ruleTypesList}
          isEditRole={IS_EDIT_ROLE(userRole).value}
        />
        {loading.has('groupsListGridLoading') && !loadMore && (
          <LoadingContainer>
            <GridLoadingWrapper>
              <LoadingIndicator
                domID="group-grid-loading-indicator"
                length="45px"
              />
            </GridLoadingWrapper>
          </LoadingContainer>
        )}
        <Grid
          {...gridConfig}
          key={count}
          records={this.generateGridRecordsFromProps(records)}
          selectedItemIds={selectedItemIds}
          rowComponent={ClickableRow}
          onClickThrough={onRowClickThrough}
          onRowSelect={onRowSelect}
          onSelectAll={onGridSelectAll}
          onSortGridColumn={this.getFreshGroupsList}
          focusedItem={selectedRecord}
        />
        <LoadMoreButton>
          <InputLabelWrapper>
            {loading.size === 0 ? `${counterLabel} groups loaded` : null}
          </InputLabelWrapper>

          {(loading.has('groupsListGridLoading') && loadMore) ||
          disableButton ? (
              <LoadingWrapper>
                <LoadingIndicator
                  domID="group-load-more-loading-indicator"
                  length="45px"
                />
              </LoadingWrapper>
            ) : (
              loadMore && (
                <Button
                  name={`Load ${
                    count - records.length > 100 ? 100 : count - records.length
                  } More`}
                  buttonType="deEmphasized"
                  onClick={this.loadMore}
                  size="medium"
                  type="button"
                />
              )
            )}
        </LoadMoreButton>
      </>
    );
  }
}

GroupGridView.defaultProps = {
  groupGridQueryUpdate: () => false,
};

GroupGridView.propTypes = {
  groupsList: PropTypes.object,
  addNotification: PropTypes.func.isRequired,
  deleteNotification: PropTypes.func.isRequired,
  triggerActivateGroupRequest: PropTypes.func.isRequired,
  triggerDeactivateGroupRequest: PropTypes.func.isRequired,
  triggerAllGroupsRequest: PropTypes.func.isRequired,
  toggleDetailsView: PropTypes.func.isRequired,
  triggerRuleTypesRequest: PropTypes.func.isRequired,
  groupGridQueryUpdate: PropTypes.func,
  resetDetailsPane: PropTypes.func.isRequired,
  leftStripConfig: PropTypes.object,
  columns: PropTypes.object,
  records: PropTypes.array,
  headerText: PropTypes.string,
  headerCount: PropTypes.number,
  isActivatingGroup: PropTypes.bool,
  isDeactivatingGroup: PropTypes.bool,
  isMulti: PropTypes.bool,
  isCloningGroup: PropTypes.bool,
  isExpanded: PropTypes.bool,
  loading: PropTypes.object,
  loadMore: PropTypes.bool,
  notifications: PropTypes.object,
  isShowingActivateGroupModal: PropTypes.bool,
  isShowingDeactivateGroupModal: PropTypes.bool,
  selectedRecord: PropTypes.object,
  resetRuleDetailElements: PropTypes.func,
  setIsCloningGroupState: PropTypes.func,
  setIsShowingActivateGroupModal: PropTypes.func,
  setIsShowingDeactivateGroupModal: PropTypes.func,
  setSelectedDetailsRecordId: PropTypes.func,
  showCloneGroupModal: PropTypes.bool,
  status: PropTypes.string,
  recordCountFromDBTrigger: PropTypes.func,
  setIsShowingCloneGroupModal: PropTypes.func,
  count: PropTypes.number,
  recordCountByStatusAndKeywordTriggerDB: PropTypes.func,
  resetRuleTypesListReceived: PropTypes.func,
  groupGridQuery: PropTypes.string,
  setDefaultGroupDetailsTab: PropTypes.func,
  ruleTypesList: PropTypes.object,
  resetGroupsRecords: PropTypes.func,
  captureCurrentPage: PropTypes.func,
};

export default GroupGridView;
