import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { LoadingIndicator, Button, DetailHeader, Modal} from 'ui-core-ssgr';
import DropDownControl from '../ModalControls/DropDownControl/index';
import { 
  DropOne,
  DropTwo,
  DropThree,
  ContainerBox,
  ImageRuleDropDown,
  ImageRuleImageWrapper,
  HeaderTitle,
  ImageFilter,
  SearchButtonImage,
  RuleTypesDetailRow,
} from './styles';
import { 
  ButtonGroupBottom,
  ReadOnlyTextTitle,
  ReadOnlyTextArea
 } from '../../../shared/styles/styledComponents';
import {
  LoadingWrapper,
  ModalButton,
} from '../../RulesTabView/components/RuleDetails/RuleDetailsGridWrapper/styles';
import UploadAndViewImageControl from '../ModalControls/UploadAndViewImageControl';
import ImageControl from '../ModalControls/ImageControl';
import ImageLibraryModal from '../ImageLibraryModal';
import FileUpload from '../../../shared/components/FileUpload';
import CommitImageModal from '../CommitImageModal';
import searchIcon from '../../../shared/images/search-icon.png';
class ImageRuleModal extends React.Component {

  state = {
    claimType: 'default',
    recipientType: 'default',
    documentType: 'default',
    image1: null,
    image2: null,
    image3: null,
    imageDefinitionId1: null,
    imageDefinitionId2: null,
    imageDefinitionId3: null,
    imageDefinition1: null,
    imageDefinition2: null,
    imageDefinition3: null,
    isButtonDisabled: true,
    selectedRecordIdImageLibrary: '0',
    selectedUploadViewConrolId : '',
    mode:'',
    selectedDataToCommit: null,
    isTestedImage1: false,
    isTestedImage2: false,
    isTestedImage3: false,
    searchHasError:'',
    searchErrorMessage:'',
    searchToggle:false,
    keyword: ''
  };

  componentDidMount() {
    this.getInitialValues();
  }

  componentDidUpdate(prevProps) {
    const {
      detailElements,
      isShowingImageRuleModal,
      postImageUploadSuccessId,
      postImageCommitSuccessId
    } = this.props;

    const { mode } = this.state;
    if (
      prevProps.detailElements !== detailElements ||
      prevProps.isShowingImageRuleModal !== isShowingImageRuleModal
    ) {
      this.getInitialValues();
    }
    if(postImageUploadSuccessId &&
      postImageUploadSuccessId.imageId && 
      ( !prevProps.postImageUploadSuccessId ||
        (prevProps.postImageUploadSuccessId.imageId.imageId !== postImageUploadSuccessId.imageId.imageId)) &&
       mode === 'upload'){
      this.updateImageData();
    } 
    if(postImageCommitSuccessId &&
      postImageCommitSuccessId.imageId && 
      ( !prevProps.postImageCommitSuccessId ||
        (prevProps.postImageCommitSuccessId.isTested !== postImageCommitSuccessId.isTested) ||
        (prevProps.postImageCommitSuccessId.imageId !== postImageCommitSuccessId.imageId) ) ) {
      this.commitImageData();
    }
  }

  updateImageData = () => {
    const {postImageUploadSuccessId} = this.props;
    const {selectedUploadViewConrolId} = this.state;
    if(
      postImageUploadSuccessId && 
      postImageUploadSuccessId.convertedImageUploadData !== undefined && 
      postImageUploadSuccessId.convertedImageUploadData !== null ){
      if(selectedUploadViewConrolId === 'image1'){
        this.setState({image1:postImageUploadSuccessId.imageName, 
          imageDefinitionId1: String(postImageUploadSuccessId.imageId.imageId),
          imageDefinition1: postImageUploadSuccessId.convertedImageUploadData.convertedImage,
        });
      }
      if(selectedUploadViewConrolId === 'image2'){
        this.setState({image2:postImageUploadSuccessId.imageName, 
          imageDefinitionId2: String(postImageUploadSuccessId.imageId.imageId),
          imageDefinition2: postImageUploadSuccessId.convertedImageUploadData.convertedImage,
        });
      }
      if(selectedUploadViewConrolId === 'image3'){
        this.setState({image3:postImageUploadSuccessId.imageName, 
          imageDefinitionId3: String(postImageUploadSuccessId.imageId.imageId),
          imageDefinition3: postImageUploadSuccessId.convertedImageUploadData.convertedImage,
        });
      }
    }
    const { actionType } = this.props;
    const {image1} = this.state;
    if (actionType === 'Edit a' || actionType === 'Add a') {
      const image1Temp = image1 || (selectedUploadViewConrolId === 'image1' && postImageUploadSuccessId);
      this.setState ({isButtonDisabled : !image1Temp });
    }
  }

  // called when commit button is clicked
  commitImageData = () => {
    const {postImageCommitSuccessId} = this.props;
    const {selectedUploadViewConrolId, imageDefinitionId1, imageDefinitionId2, imageDefinitionId3} = this.state;
    if(postImageCommitSuccessId){
      if(selectedUploadViewConrolId === 'image1'){
        if(postImageCommitSuccessId.imageId === imageDefinitionId2){
          this.setState({
            isTestedImage2: postImageCommitSuccessId.isTested,
          });
        }
        if(postImageCommitSuccessId.imageId === imageDefinitionId3){
          this.setState({
            isTestedImage3: postImageCommitSuccessId.isTested,
          });
        }
        this.setState({
          isTestedImage1: postImageCommitSuccessId.isTested,
        });
      }
      if(selectedUploadViewConrolId === 'image2'){
        if(postImageCommitSuccessId.imageId === imageDefinitionId1){
          this.setState({
            isTestedImage1: postImageCommitSuccessId.isTested,
          });
        }
        if(postImageCommitSuccessId.imageId === imageDefinitionId3){
          this.setState({
            isTestedImage3: postImageCommitSuccessId.isTested,
          });
        }
        this.setState({
          isTestedImage2: postImageCommitSuccessId.isTested,
        });
      }
      if(selectedUploadViewConrolId === 'image3'){
        if(postImageCommitSuccessId.imageId === imageDefinitionId1){
          this.setState({
            isTestedImage1: postImageCommitSuccessId.isTested,
          });
        }
        if(postImageCommitSuccessId.imageId === imageDefinitionId2){
          this.setState({
            isTestedImage2: postImageCommitSuccessId.isTested,
          });
        }
        this.setState({
          isTestedImage3: postImageCommitSuccessId.isTested,
        });
      }
    }
  }

  populateCodeFromValue = name => {
    const { detailElements, selectedRuleDetail } = this.props;
    if (!_.isEmpty(detailElements)) {
      if (name && detailElements.claimTypesList) {
        let selectedCode = '';
        let selectedList = '';
        let selectedName = name.replace('List', '');
        if (selectedName.indexOf('Types')) {
          selectedName = selectedName.replace('Types', 'Type');
        }
        const selectedValue = selectedRuleDetail[selectedName];
        selectedList = name;

        const firstValue = detailElements[selectedList].find(x =>
          x.value.includes(selectedValue),
        );

        const arr = firstValue
          ? [...new Set([firstValue, ...detailElements[selectedList]])]
          : detailElements[selectedList];

        arr.map(r => {
          if (
            name === 'image1List' ||
              name === 'image2List' ||
              name === 'image33List'
          ) {
            if (r.value.includes(selectedValue)) {
              selectedCode = r.code;
            }
          } else if (r.value === selectedValue) {
            selectedCode = r.code;
          }
          return {
            label: r.value,
            value: r.code,
          };
        });
        return selectedCode;
      }
    }
    return '';
  };

  getInitialValues = () => {
    const { detailElements, selectedRuleDetail, actionType } = this.props;
    let claimType = 'default';
    let recipientType = 'default';
    let documentType = 'default';
    if (
      !_.isEmpty(detailElements) &&
      !_.isEmpty(selectedRuleDetail) &&
      actionType !== 'Add a'
    ) {
      if (actionType === 'Edit a' || actionType === 'Clone a') {
        if (detailElements.claimTypesList) {
          claimType = detailElements.claimTypesList.find(
            o => o.value === selectedRuleDetail.claimType,
          ).code;
        }
        if (detailElements.recipientTypesList) {
          recipientType = detailElements.recipientTypesList.find(
            o => o.value === selectedRuleDetail.recipientType,
          ).code;
        }
        if (
          detailElements.documentTypesList &&
          selectedRuleDetail.documentType !== 'Any'
        ) {
          documentType = detailElements.documentTypesList.find(
            o => o.value === selectedRuleDetail.documentType,
          ).code;
        } else {
          documentType = 'default';
        }
      }
      
      if (actionType === 'Read only') {
        if (detailElements.claimTypesList) {
          claimType = detailElements.claimTypesList.find(
            o => o.value === selectedRuleDetail.claimType,
          ).value;
        }
        if (detailElements.recipientTypesList) {
          recipientType = detailElements.recipientTypesList.find(
            o => o.value === selectedRuleDetail.recipientType,
          ).value;
        }
        if (detailElements.documentTypesList) {
          documentType = detailElements.documentTypesList.find(
            o => o.value === selectedRuleDetail.documentType,
          ).value;
        }
      }

      const {image1, image2, image3,
        imageDefinitionId1, imageDefinitionId2, imageDefinitionId3,
        imageDefinition1, imageDefinition2, imageDefinition3 } = selectedRuleDetail;

      const {isTestedImage1, isTestedImage2, isTestedImage3} = selectedRuleDetail;
      
      this.setState({
        claimType,
        recipientType,
        documentType,
        image1,
        image2,
        image3,
        imageDefinition1,
        imageDefinition2,
        imageDefinition3,
        imageDefinitionId1,
        imageDefinitionId2,
        imageDefinitionId3,
        isButtonDisabled: true,
        isTestedImage1,
        isTestedImage2,
        isTestedImage3
      });
    } else {
      const {isTestedImage1, isTestedImage2, isTestedImage3} = selectedRuleDetail;
      this.setState({
        claimType,
        recipientType,
        documentType,
        isButtonDisabled: true,
        isTestedImage1, 
        isTestedImage2, 
        isTestedImage3
      });
    }
  };

  getRuleVersion = record => {
    try {
      const { status, version } = record;
      const testVersion = version[status.indexOf('T')];
      if (testVersion) return testVersion;
      const liveVersion = version[status.indexOf('L')];
      return liveVersion;
    } catch (e) {
      console.log(e);
    }
    return null;
  };

  populateDataDropDown = name => {
    const { detailElements, selectedRuleDetail } = this.props;
    if (!_.isEmpty(detailElements)) {
      if (detailElements) {
        let options = [];
        
        const selectedValue = selectedRuleDetail[name];

        const selectedList = name;

        const firstValue = detailElements[selectedList].find(x =>
          x.value.includes(selectedValue),
        );
        const arr = firstValue
          ? [...new Set([firstValue, ...detailElements[selectedList]])]
          : detailElements[selectedList];
        options = arr
          .map(r => ({
            label: r.value,
            value: r.code,
          }))
          .filter(v => v.value !== '');
        return options;
      }
    }
    return '';
  };

  
  setButtonDisableOnDefault(dbCodes) {
    const { actionType } = this.props;
    const {
      claimType,
      recipientType,
      documentType,
      imageDefinitionId1,
      imageDefinitionId2,
      imageDefinitionId3,
    } = this.state;
    let newState = false;
    if (
      (actionType === 'Edit a' &&
        (claimType === dbCodes.claimTypeDbCode &&
          recipientType === dbCodes.recipientTypeDbCode &&
          imageDefinitionId1 === dbCodes.imageDefinitionId1DbCode &&
          imageDefinitionId2 === dbCodes.imageDefinitionId2DbCode &&
          imageDefinitionId3 === dbCodes.imageDefinitionId3DbCode &&
          documentType === dbCodes.documentTypeDbCode)) ||
      (actionType === 'Clone a' &&
        (claimType === dbCodes.claimTypeDbCode &&
          recipientType === dbCodes.recipientTypeDbCode &&
          documentType === dbCodes.documentTypeDbCode))
    ) {
      newState = true;
    }
    
    this.setState({ isButtonDisabled: newState });
  }

  onCancel = () => {
    const {
      setIsCloningRuleDetail,
      setIsPostingRuleDetail,
      setIsShowingImageRuleModal,
      setIsReadOnlyRuleDetail
    } = this.props;
    setIsCloningRuleDetail(false);
    setIsPostingRuleDetail(false);
    setIsShowingImageRuleModal(false);
    setIsReadOnlyRuleDetail(false);
  };

  onSave = () => {
    const {
      isCloningRuleDetail,
      isPostingRuleDetail,
      postRuleDetails,
      putRuleDetails,
      selectedRuleDetail,
      selectedRuleRecord,
      selectedRuleTypeId,
      setIsCloningRuleDetail,
      setIsPostingRuleDetail,
    } = this.props;
    const {
      claimType,
      recipientType,
      documentType,
      image1,
      image2,
      image3,
    } = this.state;
    const ruleDetailId = selectedRuleDetail.id;
    const ruleId = selectedRuleRecord.id;
    const ruleVersion = this.getRuleVersion(selectedRuleRecord);
    const ruleType = selectedRuleTypeId;
    let ruleDetail = {
      claimType,
      recipientType,
      documentType,
      image1,
      image2,
      image3,
    };
    ruleDetail = isCloningRuleDetail
      ? { ...ruleDetail, ...{ ruleDetailIdFrom: ruleDetailId } }
      : ruleDetail;
    const {imageDefinitionId1, imageDefinitionId2, imageDefinitionId3}  = this.state;
    ruleDetail.image1 = imageDefinitionId1 || 'default';
    ruleDetail.image2 = imageDefinitionId2 || 'default';
    ruleDetail.image3 = imageDefinitionId3 || 'default';
    if (isPostingRuleDetail || isCloningRuleDetail) {
      postRuleDetails({ ruleDetail, ruleType, ruleId, ruleVersion });
      setIsCloningRuleDetail(false);
      setIsPostingRuleDetail(false);
    } else {
      putRuleDetails({
        ruleDetail,
        ruleType,
        ruleId,
        ruleVersion,
        ruleDetailId,
      });
    }
  };

  checkUnsavedChanges = event => {
    const { selectedRuleDetail } = this.props;
    const { populateCodeFromValue} = this;
    const claimTypeDbCode = populateCodeFromValue('claimTypesList');
    const recipientTypeDbCode = populateCodeFromValue('recipientTypesList');
    const documentTypeDbCode = populateCodeFromValue('documentTypesList');
    const imageDefinitionId1DbCode = selectedRuleDetail.imageDefinitionId1;
    const imageDefinitionId2DbCode = selectedRuleDetail.imageDefinitionId2;
    const imageDefinitionId3DbCode = selectedRuleDetail.imageDefinitionId3;
    const dbCodes = {
      claimTypeDbCode,
      recipientTypeDbCode,
      documentTypeDbCode,
      imageDefinitionId1DbCode,
      imageDefinitionId2DbCode,
      imageDefinitionId3DbCode
    };
    if (event.target) {
      const { name, value } = event.target;
      this.setState(
        {
          [name]: value
        },
        () => {
          this.setButtonDisableOnDefault(dbCodes);
        },
      );
    }
  };

  // called when image is selected from image library
  selectedImageData = (e, imageKey, imageSrc, imageAltLabel, imageId, isTestedStatus) => {
    if (imageKey !== null && imageSrc !== null && imageAltLabel !== null) {
      const {
        selectedUploadViewConrolId
      } = this.state;
    
      if (selectedUploadViewConrolId === 'image1') {
        this.setState({
          image1: imageAltLabel,
          imageDefinition1: imageSrc,
          imageDefinitionId1: imageId,
          isTestedImage1: isTestedStatus
        });
      } else if (selectedUploadViewConrolId === 'image2') {
        this.setState({
          image2: imageAltLabel,
          imageDefinition2: imageSrc,
          imageDefinitionId2: imageId,
          isTestedImage2: isTestedStatus
        });
      } else {
        this.setState({
          image3: imageAltLabel,
          imageDefinition3: imageSrc,
          imageDefinitionId3: imageId,
          isTestedImage3: isTestedStatus
        });
      }
      const { actionType } = this.props;
      const {image1} = this.state;
      if (actionType === 'Edit a' || actionType === 'Add a') {
        const image1Temp = image1 || (selectedUploadViewConrolId === 'image1' && imageAltLabel);
        this.setState ({isButtonDisabled : !image1Temp });
      }
      this.checkUnsavedChanges(e);
    }
  };

  // called when image library cancel button is clicked to close image library modal
  onCancelImageLibrary = () => {
    const { setIsShowingImageLibraryModal, resetRuleImagesData, selectedRuleTypeId} = this.props;
    resetRuleImagesData({selectedRuleTypeId});
    setIsShowingImageLibraryModal(false);
    this.setState({searchHasError:false, searchErrorMessage:''})
  };
  
  handleImageAction = (event, actionType, uniqueId, imageId, imageName) => {
    const {
      setIsShowingImageLibraryModal,
      setIsShowingImageUploadModal,
      selectedRuleDetail,
      selectedRuleTypeId,
      getRuleImages,
      getRuleImagesCount,
      setIsShowingCommitImageModal
    } = this.props;
    const selectedRecordIdImageLibrary =
      selectedRuleDetail[uniqueId] !== undefined
        ? selectedRuleDetail[uniqueId]
        : '0';
    if (actionType === 'library') {
      const offset = null;
      getRuleImages({selectedRuleTypeId, offset}); // to pull records
      getRuleImagesCount({selectedRuleTypeId}); // to pull count
      setIsShowingImageLibraryModal(
        selectedRuleTypeId,
        selectedRecordIdImageLibrary,
        true,
      );
      this.setState({keyword:''});
    } else if (actionType === 'remove') {
      if (uniqueId === 'image1') {
        this.setState({image1: '', imageDefinition1: '', imageDefinitionId1: ''});
      } else if (uniqueId === 'image2') {
        this.setState({image2: '', imageDefinition2: '', imageDefinitionId2: ''});
      } else {
        this.setState({image3: '', imageDefinition3: '', imageDefinitionId3: ''});
      }  
      this.checkUnsavedChanges(event);
    } else if (actionType === 'commit') {
      this.setState ({selectedDataToCommit: {imageName, imageId}});
      setIsShowingCommitImageModal({status:true, workflow:'ruledetail'});
    } else {
      setIsShowingImageUploadModal(
        selectedRuleTypeId,
        selectedRecordIdImageLibrary,
        true,
      );
    }
    this.setState({ selectedRecordIdImageLibrary });
  };

  // called when image upload cancel button is clicked to close image upload modal
  onCancelImageUpload = () => {
    const { setIsShowingImageUploadModal } = this.props;
    setIsShowingImageUploadModal(false);
  };

  // called when image is selected from image library
  selectedImageControl = (e, actionType, uniqueId, imageId, imageName) => {
    const {handleImageAction} = this;
    handleImageAction(e, actionType, uniqueId, imageId, imageName);
    this.setState({selectedUploadViewConrolId: uniqueId, mode: actionType });
  };

  getKeyword = () => {
    return document.getElementById("image-filter").value;
  }
 
  userSearchWithinImages = () => {
    let {keyword} = this.state;
    const {searchToggle} = this.state;
    const checkValue = /^[a-zA-Z0-9-_ ]*$/;
    keyword = this.getKeyword();
    this.setState({searchToggle:!searchToggle});
    if (checkValue.test(keyword) && (keyword.length === 0 || keyword.length >= 3)) {
      this.setState({keyword})
      this.setState({searchHasError:false})
    } else {
      this.setState({searchHasError:true})
      this.setState({searchErrorMessage:'Please enter at least three valid characters - alphanumeric, space, - or _ is allowed.'})
    } 
    this.updateImageLibraryGrid();
  };

  updateImageLibraryGrid = () => {
    const {
      selectedRuleTypeId,
      getRuleImages,
      getRuleImagesCount,
      resetRuleImagesData
    } = this.props;
    let {keyword} = this.state;
    keyword = this.getKeyword();
    const checkValue = /^[a-zA-Z0-9-_ ]*$/;
    // only make call if valid search
    if(checkValue.test(keyword) && (keyword.length === 0 || keyword.length >= 3)) {
      resetRuleImagesData({selectedRuleTypeId});
      getRuleImages({selectedRuleTypeId, offset:null, keyword}); // to pull records
      getRuleImagesCount({selectedRuleTypeId, keyword}); // to pull count
    }
  }

  loadMoreRecords = (offset) => {
    const { selectedRuleTypeId, getRuleImages } = this.props
    const checkValue = /^[a-zA-Z0-9-_ ]*$/;
    const {keyword} = this.state;
    if (checkValue.test(keyword) && keyword.length >= 3) {
      getRuleImages({selectedRuleTypeId, offset, keyword}); // pull records w/keyword after valid search
    } else {
      getRuleImages({selectedRuleTypeId, offset}); // pull records  wo/keyword after invalid search
    }
  }
  
  handleAcceptImageUpload = (e) => {
    const {selectedUploadViewConrolId, image1} = this.state;
    const newImage1State = image1 || (selectedUploadViewConrolId === 'image1');
    this.setState ({isButtonDisabled : !newImage1State });
    this.checkUnsavedChanges(e);
  }

  render() {
    const {
      checkUnsavedChanges,
      selectedImageData,
      onCancelImageLibrary,
      selectedImageControl,
      loadMoreRecords,
      handleAcceptImageUpload,
      userSearchWithinImages,
      getKeyword
    } = this;
    const {
      claimType,
      recipientType,
      documentType,
      isButtonDisabled,
      selectedRecordIdImageLibrary,
      image1,
      image2,
      image3,
      imageDefinition1,
      imageDefinition2,
      imageDefinition3,
      imageDefinitionId1,
      imageDefinitionId2,
      imageDefinitionId3,
      selectedDataToCommit,
      isTestedImage1,
      isTestedImage2,
      isTestedImage3,
      searchHasError,
      searchErrorMessage,
      searchToggle
    } = this.state;
    const {
      loading,
      selectedRuleRecord,
      selectedRuleDetail,
      selectedRecord,
      isShowingImageLibraryModal,
      setIsShowingImageLibraryModal,
      selectedRuleTypeId,
      ruleImages,
      ruleImagesCount,
      convertImageUpload,
      convertedImageUploadData,
      convertedImageUploadError,
      postImageUpload,
      isShowingImageUploadModal,
      setIsShowingImageUploadModal,
      isShowingCommitImageModal,
      actionType
    } = this.props;
    const ruleVersion = this.getRuleVersion(selectedRuleRecord);
    const recordId = selectedRuleRecord
      ? selectedRuleRecord.id
      : selectedRecord.id;
    
    const claimTypeOptions = this.populateDataDropDown('claimTypesList');
    const recipientTypeOptions = this.populateDataDropDown('recipientTypesList');
    const documentTypeOptions = this.populateDataDropDown('documentTypesList');

    const image1Key = imageDefinitionId1 || null;
    const image1Src = imageDefinition1 || null;
    const image1AltLabel = image1 || null;

    const image2Key = imageDefinitionId2 || null;
    const image2Src = imageDefinition2 || null;
    const image2AltLabel = image2 || null;

    const image3Key = imageDefinitionId3 || null;
    const image3Src = imageDefinition3 || null;
    const image3AltLabel = image3 || null;

    // FOR READ ONLY CONDITIONAL RENDERING (Live Rule)
    let claimTypeReadOnly;
    let recipientTypeReadOnly;
    let documentTypeReadOnly;
    if (actionType === 'Read only') {
      claimTypeReadOnly = (claimType !== 'default') ? claimType : 'Any';
      recipientTypeReadOnly = (recipientType !== 'default') ? recipientType : 'Any';
      documentTypeReadOnly = (documentType !== 'default') ? documentType : 'Any';
    }
    
    return (
      <>
        {isShowingImageLibraryModal ? (
          <Modal
            domID="image-library-modal"
            isOpen={isShowingImageLibraryModal}
            onModalToggle={onCancelImageLibrary}
          >
            <RuleTypesDetailRow>
              <DetailHeader
                domID="header-title-image-library"
                title="Image Definition Library"
                marginBottom={12}
              >
              </DetailHeader>
            </RuleTypesDetailRow>   
            <ImageFilter
              domID="image-filter"
              onEnterPress={userSearchWithinImages}
              onButtonClick={userSearchWithinImages}
              placeholder="Search by Image Name"
              hasError={searchHasError}
              errorMessage={searchHasError ? searchErrorMessage : null}
              buttonContent={<SearchButtonImage alt="search" src={searchIcon} />}
            />
            <ImageLibraryModal
              loading={loading}
              ruleImages={ruleImages}
              ruleImagesCount={ruleImagesCount}
              searchToggle={searchToggle}
              getKeyword={getKeyword}
              setIsShowingImageLibraryModal={setIsShowingImageLibraryModal}
              selectedRuleDetail={selectedRuleDetail}
              selectedRecordIdImageLibrary={selectedRecordIdImageLibrary}
              selectedRuleTypeId={selectedRuleTypeId}
              selectedImageData={(e, imageKey, imageSrc, imageAltLabel, imageId, isTestedStatus) =>
                selectedImageData(e, imageKey, imageSrc, imageAltLabel, imageId, isTestedStatus)
              }
              loadMoreRecords={(offset) => loadMoreRecords(offset)}
              onCancelImageLibrary={onCancelImageLibrary}
            />
          </Modal>
        ) : null}
        {isShowingImageUploadModal ? (
          <Modal
            domID="image-upload-modal"
            isOpen={isShowingImageUploadModal}
            onModalToggle={this.onCancelImageUpload}
          >
            <HeaderTitle><DetailHeader
              domID="header-title-image-upload"
              title="Logo Upload"
              marginBottom={0}
            /></HeaderTitle>
            <center><span className="choose-caption">
              Choose your own logo and upload it. Your logo will be
              added to the library.
            </span></center>
            <FileUpload
              loading={loading}
              postImageUpload={postImageUpload}
              setIsShowingImageUploadModal={setIsShowingImageUploadModal}
              selectedRuleTypeId={selectedRuleTypeId}
              fileUploadMessage="Drag a jpg, png, jpeg, tiff, tif, gif, bmp file here. Max 2MB. Max dimensions 1280x720."
              acceptedFileTypes="jpg,png,jpeg,tiff,tif,gif,bmp"
              fileSizeAllowed={2106286} // bytes == 2.00 Mb
              allowMultiple={false}
              convertImageUpload={convertImageUpload}
              convertedImageUploadData={convertedImageUploadData}
              convertedImageUploadError={convertedImageUploadError}
              handleAcceptImageUpload={(e) =>
                handleAcceptImageUpload(e)
              }
            />
          </Modal>
        ) : null}
        {isShowingCommitImageModal !== undefined && isShowingCommitImageModal.status === true ? (
          <CommitImageModal
            {...this.props}
            workflow={isShowingCommitImageModal.workflow}
            selectedDataToCommit={selectedDataToCommit}
            ruleId={recordId}
            ruleVersion={ruleVersion}
          />
        ) : null}
        <ContainerBox>
          {actionType !== 'Read only' ? (
            <ImageRuleDropDown>
              <DropOne>
                <DropDownControl
                  dropDownDomID="image-arr-claim-type"
                  dropDownLabel="claim type"
                  dropDownName="claimType"
                  dropDownOptions={claimTypeOptions}
                  dropDownInitialValue={claimType}
                  dropDownOnChangeHandle={checkUnsavedChanges}
                />
              </DropOne>
            </ImageRuleDropDown>
          ) : <ReadOnlyTextTitle>Claim Type<ReadOnlyTextArea>{claimTypeReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
          {actionType !== 'Read only' ? (
            <ImageRuleDropDown>
              <DropTwo>
                <DropDownControl
                  dropDownDomID="image-arr-recipient-type"
                  dropDownLabel="recipient"
                  dropDownName="recipientType"
                  dropDownOptions={recipientTypeOptions}
                  dropDownInitialValue={recipientType}
                  dropDownOnChangeHandle={checkUnsavedChanges}
                />
              </DropTwo>
            </ImageRuleDropDown> ) : <ReadOnlyTextTitle>Recipient Type<ReadOnlyTextArea>{recipientTypeReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
          {actionType !== 'Read only' ? (
            <ImageRuleDropDown>
              <DropThree>
                <DropDownControl
                  dropDownDomID="image-arr-document-type"
                  dropDownLabel="document type"
                  dropDownName="documentType"
                  dropDownOptions={documentTypeOptions}
                  dropDownInitialValue={documentType}
                  dropDownOnChangeHandle={checkUnsavedChanges}
                />
              </DropThree>
            </ImageRuleDropDown> ) : <ReadOnlyTextTitle>Document Type<ReadOnlyTextArea>{documentTypeReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
        </ContainerBox>

        <ContainerBox>
          {actionType !== 'Read only' ? (
            <ImageRuleImageWrapper className={!image1Key ? "first-rule-required" : ''}>
              <UploadAndViewImageControl
                uniqueId="image1"
                headerText="Image 1"
                libraryButtonClassName="library-link"
                libraryButtonText="Library"
                uploadButtonClassName="upload-link"
                uploadButtonText="Upload"
                onClickHandleLibrary={e =>
                  selectedImageControl(e, 'library', 'image1',image1Key,image1AltLabel)
                }
                onClickHandleUpload={e =>
                  selectedImageControl(e, 'upload', 'image1',image1Key,image1AltLabel)
                }
                onClickHandleRemove={e =>
                  selectedImageControl(e, 'remove', 'image1',image1Key,image1AltLabel)
                }
                onClickHandleCommit={e =>
                  selectedImageControl(e, 'commit', 'image1',image1Key,image1AltLabel)
                }
                imageKey={image1Key}
                imageSrc={image1Src}
                imageAltLabel={image1AltLabel}
                isTested={isTestedImage1}
              />
              {!image1Key ? <span className="s1-required-err">Image 1 is required</span> : null}
            </ImageRuleImageWrapper>
          ) : <ReadOnlyTextTitle>
          Image 1
            {image1 !== undefined ? (
              <ImageControl
                uniqueId="image1"
                imageKey={image1Key}
                imageSrc={image1Src}
                imageAltLabel={image1AltLabel}
                isTested={isTestedImage1}
                imageStyles={{ marginRight: '2em', fontSize: '13px', fontWeight: 'normal', textTransform: 'none' }}
                imageOnClickHandle={() => {}}
                imageOnLoad={() => {}}
                imageOnError={() => {}}
                imageSelection={false}
              />
            ) : <ReadOnlyTextArea>-</ReadOnlyTextArea>}
          </ReadOnlyTextTitle>}

          {actionType !== 'Read only' ? (
            <ImageRuleImageWrapper>
              <UploadAndViewImageControl
                uniqueId="image2"
                headerText="Image 2"
                libraryButtonClassName="library-link"
                libraryButtonText="Library"
                uploadButtonClassName="upload-link"
                uploadButtonText="Upload"
                onClickHandleLibrary={e =>
                  selectedImageControl(e, 'library', 'image2',image2Key,image2AltLabel)
                }
                onClickHandleUpload={e =>
                  selectedImageControl(e, 'upload', 'image2',image2Key,image2AltLabel)
                }
                onClickHandleRemove={e =>
                  selectedImageControl(e, 'remove', 'image2',image2Key,image2AltLabel)
                }
                onClickHandleCommit={e =>
                  selectedImageControl(e, 'commit', 'image2',image2Key,image2AltLabel)
                }
                imageKey={image2Key}
                imageSrc={image2Src}
                imageAltLabel={image2AltLabel}
                isTested={isTestedImage2}
              />
            </ImageRuleImageWrapper>
          ) : <ReadOnlyTextTitle>
          Image 2
            {image2 !== undefined ? (
              <ImageControl
                uniqueId="image2"
                imageKey={image2Key}
                imageSrc={image2Src}
                imageAltLabel={image2AltLabel}
                isTested={isTestedImage2}
                imageStyles={{ marginRight: '2em', fontSize: '13px', fontWeight: 'normal', textTransform: 'none' }}
                imageOnClickHandle={() => {}}
                imageOnLoad={() => {}}
                imageOnError={() => {}}
                imageSelection={false}
              />
            ) : <ReadOnlyTextArea>-</ReadOnlyTextArea>}
          </ReadOnlyTextTitle>}

          {actionType !== 'Read only' ? (
            <ImageRuleImageWrapper>
              <UploadAndViewImageControl
                uniqueId="image3"
                headerText="Image 3"
                libraryButtonClassName="library-link"
                libraryButtonText="Library"
                uploadButtonClassName="upload-link"
                uploadButtonText="Upload"
                onClickHandleLibrary={e =>
                  selectedImageControl(e, 'library', 'image3',image3Key,image3AltLabel)
                }
                onClickHandleUpload={e =>
                  selectedImageControl(e, 'upload', 'image3',image3Key,image3AltLabel)
                }
                onClickHandleRemove={e =>
                  selectedImageControl(e, 'remove', 'image3',image3Key,image3AltLabel)
                }
                onClickHandleCommit={e =>
                  selectedImageControl(e, 'commit', 'image3',image3Key,image3AltLabel)
                }
                imageKey={image3Key}
                imageSrc={image3Src}
                imageAltLabel={image3AltLabel}
                isTested={isTestedImage3}
              />
            </ImageRuleImageWrapper>) : <ReadOnlyTextTitle>
          Image 3
            {image3 !== undefined ? (
              <ImageControl
                uniqueId="image3"
                imageKey={image3Key}
                imageSrc={image3Src}
                imageAltLabel={image3AltLabel}
                isTested={isTestedImage3}
                imageStyles={{ marginRight: '2em', fontSize: '13px', fontWeight: 'normal', textTransform: 'none' }}
                imageOnClickHandle={() => {}}
                imageOnLoad={() => {}}
                imageOnError={() => {}}
                imageSelection={false}
              />
            ) : <ReadOnlyTextArea>-</ReadOnlyTextArea>}
          </ReadOnlyTextTitle>}
        </ContainerBox>

        <ButtonGroupBottom>
          <ModalButton>
            {loading.has(recordId) && (
              <LoadingWrapper>
                <LoadingIndicator
                  domID="basic-details-form-loading-indicator"
                  length="30px"
                />
              </LoadingWrapper>
            )}
            {actionType !== 'Read only' ? (
              <Button
                onClick={() => this.onSave()}
                domID="automation-id"
                name="Save"
                buttonType="emphasized"
                size="medium"
                isDropdown={false}
                type="button"
                disabled={
                  loading.has(recordId) || isButtonDisabled || !image1
                }
              />
            ) : null }
            <Button
              domID="automation-id"
              name={actionType !== 'Read only' ? 'Cancel' : 'Close'}
              buttonType="diminished"
              disabled={loading.has(recordId)}
              size="medium"
              onClick={() => this.onCancel()}
              isDropdown={false}
              type="button"
            />
          </ModalButton>
        </ButtonGroupBottom>
      </>
    );
  }
}

ImageRuleModal.propTypes = {
  detailElements: PropTypes.object,
  loading: PropTypes.object,
  selectedRuleRecord: PropTypes.object,
  selectedRuleDetail: PropTypes.object,
  setIsCloningRuleDetail: PropTypes.func,
  setIsPostingRuleDetail: PropTypes.func,
  setIsShowingImageRuleModal: PropTypes.func,
  isShowingImageRuleModal: PropTypes.bool,
  isCloningRuleDetail: PropTypes.bool,
  isPostingRuleDetail: PropTypes.bool,
  postRuleDetails: PropTypes.func,
  putRuleDetails: PropTypes.func,
  setIsShowingImageLibraryModal: PropTypes.func,
  isShowingImageLibraryModal: PropTypes.bool,
  isShowingImageUploadModal: PropTypes.bool,
  setIsShowingImageUploadModal: PropTypes.func,
  postImageUpload: PropTypes.func,
  selectedRuleTypeId: PropTypes.string,
  selectedRecord: PropTypes.object,
  getRuleImages: PropTypes.func,
  ruleImages: PropTypes.object,
  getRuleImagesCount: PropTypes.func,
  ruleImagesCount: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  actionType: PropTypes.string,
  resetRuleImagesData: PropTypes.func,
  convertImageUpload: PropTypes.func,
  convertedImageUploadData: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  convertedImageUploadError: PropTypes.string,
  postImageUploadSuccessId: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  isShowingCommitImageModal: PropTypes.object,
  postImageCommitSuccessId: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
};
export default ImageRuleModal;