/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Datetime from 'react-datetime';
import moment from 'moment';
import styled from 'styled-components/macro';
import {
  useLayout,
  Input, DatePickerV2,
  Button, Modal, ModalHeader, ModalBody
} from 'ui-core/dist'
import {
  LoadingPage, GridPaginationWrapper, AnchorInput, SelectDropdown
} from '../../../components';
import {
  useFilePurgeHistory, useFileRouteHistory, useFileReleaseHistory, useFileHoldHistory,
  useGroupPurgeHistory, useGroupRouteHistory, useGroupReleaseHistory, useGroupHoldHistory,
  useAutoHoldHistory, useCustomReleaseHistory, useDocumentPurgeHistory, useDocumentRouteHistory,
  useDocumentReleaseHistory, useDocumentHoldHistory
} from '../../../api/useDocumentManager';
import ShowMoreNestedGridComponent from '../../../components/ShowMoreNestedGridComponent';

import ErrorPage from '../../ErrorPage';
import withAxiosCancellation from '../../../components/withAxiosCancellation'
import {
  SELECT_ACTION_OPTION, SELECT_LEVEL_OPTION, COLLAPSE_CLAIM_NUMBER,
  DOCUMENT_HISTORY_GRID_COLUMN, FILE_GROUP_HISTORY_GRID_COLUMN, AUTO_HISTORY_GRID_COLUMN
} from '../Modules/constants'
import ListPaneHeaderWrapper from '../../../components/ErrorDisplaySection/ListPaneHeaderWrapper';
import '../Modules/styles.scss';


const StyledTableContainer = styled.div`
 &>table>tr>td{
  padding:2px;
}`;
const StyledGridWrapper = styled.div`
&.GridWrapper div:first-child div:first-child {
  & table thead tr td.documentKey > div.displayFlex {
    width: 200px !important;
  }
  & table thead tr td.claimNumbers > div.displayFlex {
    width: 80px !important;
  }
  & table thead tr td.recipientTypeDescription > div.displayFlex {
    width: 150px !important;
  }
  & table thead tr td.checkNumber > div.displayFlex {
    width: 100px !important;
  }
  & table thead tr td.checkAmount > div.displayFlex {
    width: 120px !important;
  }
}
`
const HistoryMain = ({getCancelSourceWithName}) => {
  const {
    expandCollapseListPaneButtonConfig
  } = useLayout()
  // const dispatch = useDispatch();
  // const history = useHistory();
  const { allData: allDataDocHold, totalRecordsResp: totalRecDocHold, isError: isErrorDocHold, error: errorDocHold, isLoading: isLoadingDocHold, searchDocumentHoldHistory } = useDocumentHoldHistory({getCancelSource:getCancelSourceWithName('documentHoldHistory')});
  const { allData: allDataDocRelease, totalRecordsResp: totalRecDocRelease, isError: isErrorDocRelease, error: errorDocRelease, isLoading: isLoadingDocRelease, searchDocumentReleaseHistory } = useDocumentReleaseHistory({getCancelSource:getCancelSourceWithName('documentReleaseHistory')});
  const { allData: allDataDocPurge, totalRecordsResp: totalRecDocPurge, isError: isErrorDocPurge, error: errorDocPurge, isLoading: isLoadingDocPurge, searchDocumentPurgeHistory } = useDocumentPurgeHistory({getCancelSource:getCancelSourceWithName('documentPergeHistory')});
  const { allData: allDataDocRoute, totalRecordsResp: totalRecDocRoute, isError: isErrorDocRoute, error: errorDocRoute, isLoading: isLoadingDocRoute, searchDocumentRouteHistory } = useDocumentRouteHistory({getCancelSource:getCancelSourceWithName('documentRouteHistory')});
  const { allData: allDataFileHold, totalRecordsResp: totalRecFileHold, isError: isErrorFileHold, error: errorFileHold, isLoading: isLoadingFileHold, searchFileHoldHistory } = useFileHoldHistory({getCancelSource:getCancelSourceWithName('fileHoldHistory')});
  const { allData: allDataFilePurge, totalRecordsResp: totalRecFilePurge, isError: isErrorFilePurge, error: errorFilePurge, isLoading: isLoadingFilePurge, searchFilePurgeHistory } = useFilePurgeHistory({getCancelSource:getCancelSourceWithName('filePurgeHistory')});
  const { allData: allDataFileRelease, totalRecordsResp: totalRecFileRelease, isError: isErrorFileRelease, error: errorFileRelease, isLoading: isLoadingFileRelease, searchFileReleaseHistory } = useFileReleaseHistory({getCancelSource:getCancelSourceWithName('fileReleaseHistory')});
  const { allData: allDataFileRoute, totalRecordsResp: totalRecFileRoute, isError: isErrorFileRoute, error: errorFileRoute, isLoading: isLoadingFileRoute, searchFileRouteHistory } = useFileRouteHistory({getCancelSource:getCancelSourceWithName('fileRouteHistory')});
  const { allData: allDataGroupHold, totalRecordsResp: totalRecGroupHold, isError: isErrorGroupHold, error: errorGroupHold, isLoading: isLoadingGrouphold, searchGroupHoldHistory } = useGroupHoldHistory({getCancelSource:getCancelSourceWithName('groupHoldHitory')});
  const { allData: allDataGroupPurge, totalRecordsResp: totalRecGroupPurge, isError: isErrorGroupRelease, error: errorRelease, isLoading: isLoadingGroupRelease, searchGroupPurgeHistory } = useGroupPurgeHistory({getCancelSource:getCancelSourceWithName('groupPurgeHistory')});
  const { allData: allDataGroupRelease, totalRecordsResp: totalRecGroupRelease, isError: isErrorGroupPurge, error: errorGroupPurge, isLoading: isLoadingGroupPurge, searchGroupReleaseHistory } = useGroupReleaseHistory({getCancelSource:getCancelSourceWithName('groupReleaseHistory')});
  const { allData: allDataGroupRoute, totalRecordsResp: totalRecGroupRoute, isError: isErrorGroupRoute, error: errorGroupRoute, isLoading: isLoadingGroupRoute, searchGroupRouteHistory } = useGroupRouteHistory({getCancelSource:getCancelSourceWithName('GroupRouteHistory')});
  const { allData: allDataAutoHold, totalRecordsResp: totalRecAutoHold, isError: isErrorAutoHold, error: errorAutoHold, isLoading: isLoadingAutoHold, searchAutoHoldHistory } = useAutoHoldHistory({getCancelSource:getCancelSourceWithName('AutoHoldHistory')});
  const { allData: allDataCustomRelease, totalRecordsResp: totalRecCustomRelease, isError: isErrorCutomRelease, error: errorCustomRelease, isLoading: isLoadingCustomRelease, searchCustomReleaseHistory } = useCustomReleaseHistory({getCancelSource:getCancelSourceWithName('CustomReleaseHistory')});

  const [selectAction, setSelectAction] = useState({ value: '', label: "None" });
  const [selectLevel, setSelectLevel] = useState({ value: '', label: "None" });
  const [fileDocKey, onChangeFileDocKey] = useState('')
  const [claimNoValue, onChangeCollapseClaimNumber] = useState({ value: 'No', label: "No" });
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [pageIndex, setPageIndex] = useState(0)
  const [changeItemPerPage, setchangeItemsPerPage] = useState(5)
  const [columns, setColumns] = useState('');
  const [list, setList] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [count, setCount] = useState(0);
  const [error, setError] = useState('');
  const [isOpenDetailsModal, setOpenDetailsModal] = useState(false)
  const [currentData, setCurrentData] = useState(0)

  const [datesError, setDatesError] = useState(false)
  const [errMsg, setErrMsg] = useState(false)

  useEffect(() => {
    if (selectLevel.value === "Document") {
      if (selectAction.value === "Hold") {
        setList(allDataDocHold)
        setTotalRecords(totalRecDocHold);
      } else if (selectAction.value === "Release") {
        setList(allDataDocRelease);
        setTotalRecords(totalRecDocRelease);
      } else if (selectAction.value === "Purge") {
        setList(allDataDocPurge);
        setTotalRecords(totalRecDocPurge);
      } else if (selectAction.value === "Route") {
        setList(allDataDocRoute);
        setTotalRecords(totalRecDocRoute);
      } else {
        setList(allDataDocHold)
        setTotalRecords(totalRecDocHold);
      }
    } else if (selectLevel.value === "File") {
      if (selectAction.value === "Hold") {
        setList(allDataFileHold)
        setTotalRecords(totalRecFileHold);
      } else if (selectAction.value === "Release") {
        setList(allDataFileRelease);
        setTotalRecords(totalRecFileRelease);
      } else if (selectAction.value === "Purge") {
        setList(allDataFilePurge);
        setTotalRecords(totalRecFilePurge);
      } else if (selectAction.value === "Route") {
        setList(allDataFileRoute);
        setTotalRecords(totalRecFileRoute);
      } else {
        setList(allDataFileHold)
        setTotalRecords(totalRecFileHold);
      }
    } else if (selectLevel.value === "Group") {
      if (selectAction.value === "Hold") {
        setList(allDataGroupHold)
        setTotalRecords(totalRecGroupHold);
      } else if (selectAction.value === "Release") {
        setList(allDataGroupRelease);
        setTotalRecords(totalRecGroupRelease);
      } else if (selectAction.value === "Purge") {
        setList(allDataGroupPurge);
        setTotalRecords(totalRecGroupPurge);
      } else if (selectAction.value === "Route") {
        setList(allDataGroupRoute);
        setTotalRecords(totalRecGroupRoute);
      } else {
        setList(allDataGroupHold)
        setTotalRecords(totalRecGroupHold);
      }
    } else if (selectLevel.value === 'Auto') {
      if (selectAction.value === "Hold") {
        setList(allDataAutoHold);
        setTotalRecords(totalRecAutoHold);
      } else if (selectAction.value === "Release") {
        setList(allDataCustomRelease);
        setTotalRecords(totalRecCustomRelease);
      }
    }
  }, [allDataDocHold, allDataDocRelease, allDataDocPurge, allDataDocRoute,
    allDataFileRoute, allDataFileRelease, allDataFileHold, allDataFilePurge,
    allDataGroupHold, allDataGroupPurge, allDataGroupRelease, allDataGroupRoute,
    totalRecFileRoute, totalRecFileRelease, totalRecFilePurge.totalRecFileHold,
    totalRecGroupHold, totalRecGroupRelease, totalRecGroupPurge, totalRecGroupRoute,
    totalRecDocHold, totalRecDocRelease, totalRecDocPurge, totalRecDocRoute,
    allDataAutoHold, totalRecAutoHold, allDataCustomRelease, totalRecCustomRelease
  ])
  const onCloseDetailsModal = () => {
    setOpenDetailsModal(false)
  }
  const nextClick = useCallback((args, freshSearch) => {
    setDatesError(false);
    setList([])
    setPageIndex(args?.pi || 0)
    setchangeItemsPerPage(freshSearch ? 5 : changeItemPerPage)
    setTotalRecords(0)
    let pi;
    if (args) {
      ({ pi } = args);
    }
    const payload = JSON.parse(JSON.stringify({
      "maxRecords": freshSearch ? 5 : changeItemPerPage,
      "rowPosition": args?.pi || 0,
      "sortField": 'fileKey',
      "sortDescending": true,
      "viewInactive": false,
      "parameters": `${startDate ? startDate : ''}|${endDate ? endDate : ''}|${fileDocKey}|${claimNoValue.value}`,
      count
    }));
    setError('');
    if (selectLevel.value === "Document") {
      setColumns(DOCUMENT_HISTORY_GRID_COLUMN);
      if (selectAction.value === "Hold") {
        searchDocumentHoldHistory(payload)
      } else if (selectAction.value === "Release") {
        searchDocumentReleaseHistory(payload)
      } else if (selectAction.value === "Purge") {
        searchDocumentPurgeHistory(payload)
      } else if (selectAction.value === "Route") {
        searchDocumentRouteHistory(payload)
      } else {
        searchDocumentHoldHistory(payload)
      }
    } else if (selectLevel.value === "File") {
      setColumns(FILE_GROUP_HISTORY_GRID_COLUMN);
      if (selectAction.value === "Hold") {
        searchFileHoldHistory(payload)
      } else if (selectAction.value === "Release") {
        searchFileReleaseHistory(payload)
      } else if (selectAction.value === "Purge") {
        searchFilePurgeHistory(payload)
      } else if (selectAction.value === "Route") {
        searchFileRouteHistory(payload)
      } else {
        searchFileHoldHistory(payload)
      }
    } else if (selectLevel.value === "Group") {
      setColumns(FILE_GROUP_HISTORY_GRID_COLUMN);
      if (selectAction.value === "Hold") {
        searchGroupHoldHistory(payload)
      } else if (selectAction.value === "Release") {
        searchGroupReleaseHistory(payload)
      } else if (selectAction.value === "Purge") {
        searchGroupPurgeHistory(payload)
      } else if (selectAction.value === "Route") {
        searchGroupRouteHistory(payload)
      } else {
        searchGroupHoldHistory(payload)
      }
    } else if (selectLevel.value === 'Auto') {
      setColumns(AUTO_HISTORY_GRID_COLUMN);
      if (selectAction.value === "Hold") {
        searchAutoHoldHistory(payload)
      } else if (selectAction.value === "Release") {
        searchCustomReleaseHistory(payload)
      } else {
        setError('This type is not supported for Auto.');
      }
    }

    setCount(count + 1);
  }, [selectAction, selectLevel, count, pageIndex, changeItemPerPage, startDate, endDate, claimNoValue, fileDocKey])

  const startDateValidation = (currentDate) => {
    let err = false;
    if (moment(currentDate).isAfter(moment(endDate).startOf('day'), 'day')) {
      err = true;
    } else if (moment(currentDate).isAfter(moment().startOf('day'), 'day')) {
      err = true;
    }

    return !err;
  };

  const validation = (currentDate) => {
    let err = false;
    if (moment(currentDate).isAfter(moment().startOf('day'), 'day')) {
      err = true;
    } else if (moment(currentDate).isBefore(moment(startDate).startOf('day'), 'day')) {
      err = true;
    }

    return !err;
  };

  const onStartDateChange = (val) => {
    let err = false;
    let errMessage = '';

    if (val === '') {
      setDatesError(false);
      setStartDate('');
      return;
    }

    if (!moment(val, 'MM/DD/YYYY', true).isValid()) {
      errMessage = 'Start date is invalid(mm/dd/yyyy).';
      err = true;
    } else if (moment(val).isAfter(moment(endDate))) {
      errMessage = 'Start date should be earlier than the end date.';
      err = true;
    } else if (moment(val).isAfter(moment().startOf('day'), 'day')) {
      errMessage = 'Start date is invalid(mm/dd/yyyy, max date is today).';
      err = true;
    }

    if (err) {
      setDatesError(true);
      setErrMsg(errMessage);
      setStartDate(val);
    } else {
      setDatesError(false);
      setStartDate(val.format("MM/DD/YYYY"));
    }
  }

  const onEndDateChange = (val) => {
    let err = false;
    let errMessage = '';

    if (val === '') {
      setDatesError(false);
      setEndDate('');
      return;
    }

    if (!moment(val, 'MM/DD/YYYY', true).isValid()) {
      err = true;
      errMessage = 'End date is invalid(mm/dd/yyyy).'
    } else if (moment(val).isBefore(moment(startDate))) {
      errMessage = 'End date should be greater than the start date.';
      err = true;
    } else if (moment(val).isAfter(moment().startOf('day'), 'day')) {
      errMessage = 'End date is invalid(mm/dd/yyyy, max date is today).';
      err = true;
    }

    if (err) {
      setDatesError(true);
      setErrMsg(errMessage);
      setEndDate(val);
    } else {
      setDatesError(false);
      setEndDate(val.format("MM/DD/YYYY"));
    }
  }


  const transformGroupedData = (data, isGrouped) => {
    const temp = JSON.parse(JSON.stringify(data))
    let t;
    if (isGrouped) {
      t = temp.map((d) => ({
        ...d,
        subRecords: d.subRecords.map((s) => {
          let groupName = ''
          if (s.action === "Hold") {
            groupName += `Held`
          } else if (s.action === "Release") {
            groupName += s.relType
          } else if (s.action === "Purge") {
            groupName += 'Purged'
          } else if (s.action === "Route") {
            groupName += 'Routed'
          } else {
            groupName += `Unknown Action ${s.action}`
          }
          groupName += ` at ${s.dateTime} by user ${s.user} in transaction ${s.id}`
          let claimNumbers = '';
          if (s.claimNumbers?.claimNumber?.constructor.name === 'Array'
            && s.claimNumbers?.claimNumber?.length > 1 && claimNoValue.value === 'Yes') {
            claimNumbers = 'multiple'
          } else if (s.claimNumbers?.claimNumber?.constructor.name === 'Array'
            && s.claimNumbers?.claimNumber?.length > 1 && claimNoValue.value === 'No') {
            claimNumbers = <div dangerouslySetInnerHTML={{ __html: s.claimNumbers?.claimNumber?.join('<br/>') }}></div>
          } else {
            claimNumbers = s.claimNumbers?.claimNumber
          }
          let details = '';
          if (s.displayShowLink) {
            if (s.action === "Route") {
              details =
                <AnchorInput
                  id='fileKey'
                  type='button'
                  className='fileKeyLink'
                  onClick={() => {
                    setOpenDetailsModal(true)
                    setCurrentData(s)
                  }
                  }
                >Show Delivery Info</AnchorInput>
            } else if (s.action === "Release") {
              details =
                <AnchorInput
                  id='fileKey'
                  type='button'
                  className='fileKeyLink'
                  onClick={() => {
                    setOpenDetailsModal(true)
                    setCurrentData(s)
                  }
                  }
                >Show Hold Info</AnchorInput>
            }
          }
          return {
            ...s,
            'groupname_display': groupName,
            checkAmount: s.checkAmount ? parseFloat(s.checkAmount, 10)?.toFixed(2) : 0.00,
            claimNumbers,
            details
          }
        })
      }))
    }
    return t;
  }

  const renderGridContent = useMemo(() => {
    if (list.length) {
      return (
        <ShowMoreNestedGridComponent
          mainGridConfig={{
            transformationFunction: transformGroupedData,
            disabledColumns: new Set([]),
            columns,
            isGrouped: true,
            defaultGroupBy: 'id',
            defaultGroupingType: 'table',
            wantGroupingControls: false,
            width: '100%',
          }}
          data={list}
        />
      );
    } else {
      return (
        <div className="displayFlex">
          <label style={{ color: 'red' }}>No records found. Change search criteria.</label>
        </div>
      )
    }
  }, [list, columns, claimNoValue]);

  const renderDetailsModalContent = useMemo(() => {
    let isPrevDelvry = currentData;
    if (selectLevel.value === "Document" && selectAction.value === "Route") {
      isPrevDelvry = currentData && currentData.previousDeliveryInformation && currentData.previousDeliveryInformation ? currentData.previousDeliveryInformation : '';
    }

    return (
      <div style={{ display: "flex", justifyContent: 'center', paddingBottom: '20px' }}>
        <div className="modalDetailsDiv">
          <div>
            {(selectLevel.value === "Document" && selectAction.value === "Route") ?
              'Previous Delivery Information'
              : (selectLevel.value !== "Document" && selectAction.value === "Route") ?
                'Delivery Information for Routed Documents'
                : 'Previous Hold Information'
            }
          </div>
          <table>
            <tbody>
              {selectAction.value !== 'Release' ?
                (
                  <>
                    <tr>
                      <td className="name">Name:</td>
                      <td className="value">{isPrevDelvry ? isPrevDelvry.deliveryAddress?.name : ''}</td>
                    </tr>
                    <tr>
                      <td className="name">Line 1:</td>
                      <td className="value">{isPrevDelvry ? isPrevDelvry.deliveryAddress?.line1 : ''}</td>
                    </tr>
                    <tr>
                      <td className="name">Line 2:</td>
                      <td className="value">{isPrevDelvry ? isPrevDelvry.deliveryAddress?.line2 : ''}</td>
                    </tr>
                    <tr>
                      <td className="name">Attention:</td>
                      <td className="value">{isPrevDelvry ? isPrevDelvry.deliveryAddress?.attn : ''}</td>
                    </tr>
                    <tr>
                      <td className="name">City:</td>
                      <td className="value">{isPrevDelvry ? isPrevDelvry.deliveryAddress?.city : ''}</td>
                    </tr>
                    <tr>
                      <td className="name">State:</td>
                      <td className="value">{isPrevDelvry ? isPrevDelvry.deliveryAddress?.state : ''}</td>
                    </tr>
                    <tr>
                      <td className="name">ZIP:</td>
                      <td className="value">{isPrevDelvry ? isPrevDelvry.deliveryAddress?.postalCode : ''}</td>
                    </tr>
                    <tr>
                      <td className="name">Delivery Type:</td>
                      <td className="value">{isPrevDelvry ?
                        (isPrevDelvry.deliveryType?.description ? isPrevDelvry.deliveryType?.description[0] : '') : ''}</td>
                    </tr>
                  </>
                ) : (
                  <>
                    <tr>
                      <td className="name">Date/Time Held:</td>
                      <td className="value">{currentData?.holdInformation?.dateTime || ''}</td>
                    </tr>
                    <tr>
                      <td className="name">Held By User:</td>
                      <td className="value">{currentData?.holdInformation?.user || ''}</td>
                    </tr>
                    <tr>
                      <td className="name">Transaction Id:</td>
                      <td className="value">{currentData?.holdInformation?.transaction || ''}</td>
                    </tr>
                  </>
                )
              }
            </tbody>
          </table>
        </div>
        {(selectLevel.value === "Document" && selectAction.value === "Route") ? (
          <div className="modalDetailsDiv">
            <div>New Delivery Information</div>
            <table>
              <tbody>
                <tr>
                  <td className="name">Name:</td>
                  <td className="value">{currentData?.deliveryAddress?.name || ''}</td>
                </tr>
                <tr>
                  <td className="name">Line 1:</td>
                  <td className="value">{currentData?.deliveryAddress?.line1 || ''}</td>
                </tr>
                <tr>
                  <td className="name">Line 2:</td>
                  <td className="value">{currentData?.deliveryAddress?.line2 || ''}</td>
                </tr>
                <tr>
                  <td className="name">Attention:</td>
                  <td className="value">{currentData?.deliveryAddress?.attn || ''}</td>
                </tr>
                <tr>
                  <td className="name">City:</td>
                  <td className="value">{currentData?.deliveryAddress?.city || ''}</td>
                </tr>
                <tr>
                  <td className="name">State:</td>
                  <td className="value">{currentData?.deliveryAddress?.state || ''}</td>
                </tr>
                <tr>
                  <td className="name">ZIP:</td>
                  <td className="value">{currentData?.deliveryAddress?.postalCode || ''}</td>
                </tr>
                <tr>
                  <td className="name">Delivery Type:</td>
                  <td className="value">{(currentData?.deliveryType?.description ? currentData?.deliveryType?.description[0] : '') || ''}</td>
                </tr>

              </tbody>
            </table>
          </div>
        ) : ''}
      </div>
    )
  }, [currentData, isOpenDetailsModal])

  const checkLoader = () => (
    isLoadingCustomRelease || isLoadingAutoHold || isLoadingDocHold || isLoadingDocRelease || isLoadingDocPurge
    || isLoadingDocRoute || isLoadingFileHold || isLoadingFilePurge || isLoadingFileRelease || isLoadingFileRoute
    || isLoadingGrouphold || isLoadingGroupRelease || isLoadingGroupPurge || isLoadingGroupRoute
  );

  const displayError = () => (
    errorDocHold || errorDocRelease || errorDocPurge || errorDocRoute || errorFileHold
    || errorFilePurge || errorFileRelease || errorFileRoute || errorGroupHold || errorRelease
    || errorGroupPurge || errorGroupRoute || errorAutoHold || errorCustomRelease
  )

  const checkError = () => (
    isErrorDocHold || isErrorDocRelease || isErrorDocRoute || isErrorFileHold || isErrorFilePurge
    || isErrorFileRelease || isErrorFileRoute || isErrorGroupHold || isErrorGroupRelease || isErrorDocPurge
    || isErrorGroupPurge || isErrorGroupRoute || isErrorAutoHold || isErrorCutomRelease
  )

  return (
    <>
      <ListPaneHeaderWrapper
        title="Document Management Transaction History"
        layoutControls={[expandCollapseListPaneButtonConfig]}

      />
      {checkLoader() ? (
        // replace with a better loading component
        <LoadingPage />
      ) : checkError() ? (
        // replace with a better error component
        <ErrorPage
          // this prop isn't real but is an example
          error={displayError()}
        />
      ) : (
            <div className="mainWrapper">
              <div className="marginLeft">
                <div style={{ color: 'red', fontSize: 'small', height: '25px' }}>{error}</div>
                {datesError && <p style={{ fontSize: '13px', color: 'red', margin: 0 }}>{errMsg}</p>}
                <StyledTableContainer style={{ paddingBottom: '17px' }}>
                  <table style={{ width: "705px" }}>
                    <tr></tr>
                    <tr>
                      <td>
                        <SelectDropdown
                          width="200px"
                          label='Select Action'
                          domID="selectAction"
                          onChange={(e) => {
                            setSelectAction(e)
                            if (selectLevel?.value === 'Auto' && (e?.value !== 'Hold' && e?.value !== 'Release')) {
                              setError('This type is not supported for Auto.');
                            } else {
                              setError('');
                            }
                          }}
                          initialValue={selectAction}
                          options={SELECT_ACTION_OPTION}
                          size="small"
                        />
                      </td>
                      <td>
                        <SelectDropdown
                          width="200px"
                          label='Select Level'
                          domID="selectlevel"
                          onChange={(e) => {
                            setSelectLevel(e)
                            if (e?.value === 'Auto' && (selectAction?.value !== 'Hold' && selectAction?.value !== 'Release')) {
                              setError('This type is not supported for Auto.');
                            } else {
                              setError('');
                            }
                          }}
                          initialValue={selectLevel}
                          options={SELECT_LEVEL_OPTION}
                          size="small"
                        />
                      </td>
                      <td>
                        {/* <div style={{ width: "200px" }}>
                          <DatePickerV2
                            label='Start Date'
                            domID="datePicker"
                            initialSelection={startDate}
                            onDateChange={(e) => {
                              setStartDate(e ? e : '')
                            }}
                            size="small"
                          />
                        </div> */}
                        <div className="msgDatePicker">
                          <label style={{ color: '#626D8A', fontSize: '11px' }}>Start Date</label>
                          <Datetime
                            onChange={onStartDateChange}
                            closeOnSelect={true}
                            isValidDate={startDateValidation}
                            dateFormat="MM/DD/YYYY"
                            value={startDate}
                            initialValue={startDate}
                            initialViewDate={startDate}
                            timeFormat={false}
                          />
                        </div>
                      </td>
                      <td>
                        {/* <div style={{ width: "200px" }}>
                          <DatePickerV2
                            label='End Date'
                            domID="datePicker"
                            initialSelection={endDate}
                            onDateChange={(e) => {
                              setEndDate(e ? e : '')
                            }}
                            size="small"
                            errorMessage="Start Date can't be later than End Date"
                            hasError={startDate > endDate ? true : false}
                          />
                        </div> */}
                        <div className="msgDatePicker">
                          <label style={{ color: '#626D8A', fontSize: '11px' }}>End Date</label>
                          <Datetime
                            onChange={onEndDateChange}
                            closeOnSelect={true}
                            isValidDate={validation}
                            dateFormat="MM/DD/YYYY"
                            value={endDate}
                            initialValue={endDate}
                            initialViewDate={endDate}
                            timeFormat={false}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div style={{ width: "200px" }}>
                          <Input
                            label='File Or Document Key'
                            domID="fileDoc"
                            size="small"
                            initialValue={fileDocKey}
                            onChange={(e) => onChangeFileDocKey(e.target.value)}
                          />
                        </div>
                      </td>
                      <td>
                        <SelectDropdown
                          width="200px"
                          label='Collapse Claim Numbers'
                          domID="claimN"
                          onChange={(e) => onChangeCollapseClaimNumber(e)}
                          initialValue={claimNoValue}
                          options={COLLAPSE_CLAIM_NUMBER}
                          size="small"
                        />
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                  </table>
                </StyledTableContainer>
              </div>

              <div className="displayFlex marginLeft">
                <Button
                  buttonType="standard"
                  size="small"
                  style={{ marginLeft: '10px' }}
                  name="SEARCH"
                  disabled={selectLevel.label === "None" || datesError}
                  onClick={() => {
                    nextClick(null, true)
                  }}
                />
              </div>

              <GridPaginationWrapper
                rowsPerPage={changeItemPerPage}
                onChangeRowsPerPage={(v) => {
                  setPageIndex(0)
                  setchangeItemsPerPage(v)
                }}
                pageIndex={pageIndex}
                onChangePageIndex={(v) => setPageIndex(v)}
                onShowButtonCLick={(args) => nextClick(args)}
                totalRecords={totalRecords}
                defaultRowsPerPage={changeItemPerPage}
                enableTextbox
                disableShowButton={datesError}
              />
              <StyledGridWrapper className='GridWrapper'>
                {renderGridContent}
              </StyledGridWrapper>
            </div>
          )}

      <Modal isOpen={isOpenDetailsModal}>
        <ModalHeader
          title="Details"
          onClose={() => onCloseDetailsModal()}
        />
        <ModalBody>
          {renderDetailsModalContent}
        </ModalBody>
      </Modal>
    </>
  )
}

export default withAxiosCancellation(HistoryMain)
