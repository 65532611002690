export default {
    cardPadding: '0.625rem',
    NavigationPane: '36px',
    navigationPaneNumber: 36,
    // First tab needs extra left padding to align itself properly with other
    // items like DetailPaneHeader
    tabsFirstItemLeftPadding: '48px',
    input: {
      small: '6px 0 5px 10px',
      medium: '8px 0 9px 12px',
      large: '6px 0 5px 10px',
    },
    detailPaneHeader: {
      left: 48,
      top: 40,
      bottom: 16,
    },
    listPaneHeader: {
      left: 44,
      right: 40,
      top: 29,
    },
    navigationPaneHeader: {
      left: 36,
      top: 40,
    },
    modalLeft: '40px 20px',
  };
  