/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import {
  Grid,
  useLayout,
} from 'ui-core/dist'
import { useSelector, useDispatch } from 'react-redux';
import { detailPaneAnimStates } from '../../commonResources/constants/animStates';
import { LoadingPage } from '../../components';
import { ERROR_MANAGER_DETAIL_NOTE_GRID_COLUMN, ERROR_MANAGER_DETAIL_PAGE_GRID_COLUMN } from '../ErrorManager/Modules/constants';
import './Modules/styles.scss';
import GridPaginationWrapper from '../../components/GridPaginationWrapper';
import { useErrorDetails, useErrorListByKey } from '../../api/useErrorManager';
import { setDetailsInfoModal, setCuid } from '../ErrorManager/Modules/Action'
import DetailsInfoModal from '../ErrorManager/DetailsInfoModal';
import useSortableGrid from '../../hooks/useSortableGrid';

const ErrorDetail = () => {
  const {
    detailAnimationState,
  } = useLayout()
  const dispatch = useDispatch()
  const DEFAULT_ROWS_PER_PAGE = 10;
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const [pageIndex, setPageIndex] = useState(0);
  const [resp, setResp] = useState([]);
  const [totalRecordsResp, settotalRecordsResp] = useState(0);

  const {
    data,
    totalRecords,
    isLoading,
    getErrorDetails,
    refetch: refetchErrorDetails
  } = useErrorDetails();

  const {
    isLoadingForKey,
    errorRespforKey,
    totalRecordsRespForKey,
    getErrorListByFileKey
  } = useErrorListByKey();

  useEffect(() => {
    if (data || errorRespforKey) {
      if (id.length === 17 || id.length === 23) {
        setResp(errorRespforKey);
        settotalRecordsResp(totalRecordsRespForKey);
      } else {
        setResp(data);
        settotalRecordsResp(totalRecords);
      }
    }
  }, [data, errorRespforKey])

  const fetchData = (args) => {
    let sortcolumn; let sd; let pi;
    if (args) {
      ({ sortcolumn, sd, pi } = args);
    }
    const param = JSON.parse(JSON.stringify({
      "maxRecords": rowsPerPage,
      "rowPosition": pi === undefined || pi === null ? pageIndex : pi,
      "sortField": sortcolumn === undefined || sortcolumn === null ? sortConfig.sortDataName : sortcolumn,
      "viewInactive": false,
      "sortDescending": sd === undefined || sd === null ? sortConfig.sortDescending : sd,
      "parameters": "",
      "pstrErrorId": id
    }))
    if (id.length === 17 || id.length === 23)
      getErrorListByFileKey({ ...param, pstrKey: id })
    else
      getErrorDetails(param);
  }

  const [sortConfig, getArgumentsAndSortGrid] = useSortableGrid(fetchData, "errorCuid", "true");
  const { id } = useSelector(store => store.errorManager)

  useEffect(() => {
    if (detailPaneAnimStates && detailAnimationState >= detailPaneAnimStates.EXPANDED) {
      const param = {
        "maxRecords": "10",
        "rowPosition": 0,
        "sortField": sortConfig.sortDataName,
        "viewInactive": false,
        "sortDescending": sortConfig.sortDescending,
        "parameters": "",
        "pstrErrorId": id
      }
      if (id.length === 17 || id.length === 23)
        getErrorListByFileKey({ ...param, pstrKey: id })
      else
        getErrorDetails(param)
    }
  }, [id, sortConfig.sortDataName, sortConfig.sortDecending, detailAnimationState]);

  const renderSection = (errorResp) => {
    if (errorResp)
      return (
        <div>
          <div className="displayFlex">
            <label> Error ID : </label>
            <div className="marginLeft">
              {errorResp.id}
            </div>
          </div>
          <div className="displayFlex">
            <label> System Type : </label>
            <div className="marginLeft">
              {errorResp.systemType}
            </div>
          </div>
          <div className="displayFlex">
            <label> Current Status : </label>
            <div className="marginLeft">
              {errorResp.status} {errorResp.status === 'Resolved' ?
                `(resolved at ${errorResp?.resolution?.dateTime || ''} by user ${errorResp?.resolution?.userId || ''})` : ''}
            </div>
          </div>
          <div className="displayFlex">
            <label> Severity : </label>
            <div className="marginLeft">
              {errorResp['highest-severity']}
            </div>
          </div>
          <div className="displayFlex">
            <label> Error Description : </label>
            <div className="marginLeft">
              {errorResp.description}
            </div>
          </div>
          <br />
          <br />
        </div>
      )
  }

  return (
    <>
      {isLoading || isLoadingForKey ? (
        // replace with a better loading component
        <LoadingPage />
      ) : (
          <div>
            <div className="mainWrapper">
              {id.length === 17 || id.length === 23 ? resp.map((r) => renderSection(r)) : renderSection(resp)}
            </div>
          </div>
        )}
      <DetailsInfoModal />
    </>
  )
}

export default ErrorDetail
