import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import map from 'lodash/map';
import { Portal } from 'react-portal';
import { DetailHeader, Button, NotificationBanner, Icons, Tooltip } from 'ui-core-ssgr';
import ButtonGroup from '../../../../shared/components/ButtonGroup';
import searchIcon from '../../../../shared/images/search-icon.png';
import MultipleCommitRulesModal from '../MultipleRulesCommitModal';
import { PaddingWrapper } from '../../../../shared/styles/spacing';
import {
  headerProps,
  CLONE_BUTTON,
  COMMIT_BUTTON_SET,
  CLONE_COMMIT_BUTTON_SET
} from './constants';
import { RulesFilter, SearchButtonImage, GroupDetailRow, TooltipWrap } from './styles';
import {userPermissionConstants} from '../../../../shared/modules/constants';
const RulesGridHeader = props => {
  const {
    records,
    setRecordForDetailPane,
    setIsViewingRuleDetails,
    setIsShowingCloneRuleModal,
    selectedItemIds,
    counterLabel,
    headerName,
    rulesGridQueryUpdate,
    triggerMultipleCommitPutRequest,
    multipleCommitPutSuccess,
    multipleCommitPutError,
    handleCellClick,
    addNotification,
    toggleDetailsView,
    setIsPostingRulesForRuleTypes,
    updateCurrentTab,
    currentRuleTypeData,
    isCloningRule,
    isPostingRulesForRuleTypes,
    deleteNotification,
    showMultipleCommitRulesModal,
    setIsShowingMultipleCommitRulesModal,
    triggerImagesTestCountRequest,
    multipleTestImageCountRecords,
    userRole,
    ruleGridQuery
  } = props;
  const [queryValue, setQueryValue] = useState('');
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const {userRoleEdit} = userPermissionConstants;

  useEffect(() => {
    if (!isPostingRulesForRuleTypes) {
      deleteNotification('postRule');
    }
  }, [isPostingRulesForRuleTypes]);

  useEffect(() => {
    if (!isCloningRule) {
      deleteNotification('cloneRule');
    }
  }, [isCloningRule]);

  const cloneRule = () => {
    const record = find(records, 
      element => element.id === Array.from(selectedItemIds)[0],
    );
    // this.setState(record);
    if (selectedItemIds.has(record.id)) {
      setRecordForDetailPane(record);
    }
    setIsViewingRuleDetails({
      isAdvancedViewShown: true,
      selectedRuleRecord: record,
      selectedRuleRecordId: record.id,
      selectedRuleTypeId: window.location.href.substring(
        window.location.href.lastIndexOf('=') + 1,
      ),
    });
    setIsShowingCloneRuleModal(true);
  };

  const onButtonClick = name => () => {
    switch(name) {
      case 'add':
        addRule();
        break;
      case 'clone':
        cloneRule();
        break;
      case 'commit':
        commitMultipleRules();
        break;
      default:
        break;
    };
  };

  const addRule = () => {
    setIsPostingRulesForRuleTypes(true);
    toggleDetailsView(true);
    updateCurrentTab({ currentTab: 0 });
    setIsViewingRuleDetails({
      isAdvancedViewShown: true,
      selectedRuleRecord: {
        id: '0',
        name: '',
        description: '',
        status: ['T'],
        version: ['0'],
        userId: '',
        dateModified: 0,
        hasDetails: false,
        trialed: false,
        activeGroup: [],
      },
      selectedRuleRecordId: records && records[0] ? records[0].id : '0',
      selectedRuleTypeId: window.location.href.substring(
        window.location.href.lastIndexOf('=') + 1,
      ),
    });
    addNotification({
      id: 'postRule',
      icon: 'Info',
      type: 'neutral',
      msg: 'Fill out a unique rule name.',
    });
  };

  const commitMultipleRules = () => setIsShowingMultipleCommitRulesModal(true);

  const generateGridRecordsFromProps = gridRecords => map(gridRecords, record => ({
    ...record,
    completed: { bad: record.completed, nonCompliant: record.completed },
  }));

  const renderHeaderTitle = () => {
    const { name, description } = currentRuleTypeData || '';
    const infoIcon = (
      <Tooltip
        caretToAnchor={8}
        dataTestId="test-tooltip"
        domID="test-id"
        tooltipContent={description}
        tooltipPosition="bottom-center"
        tooltipWidth={500}
      >
          <Icons.Info
            fillColor="#37474F"
            className={`icon-class-${name}`}
            title={description}
          />
      </Tooltip>
    );
    return [name, infoIcon];
  };

  const { TestLive, LiveOnly } = records
    .filter(({ id }) => selectedItemIds.has(id))
    .reduce(
      (carry, { status }) => ({
        TestLive:
          (status.includes('T') && status.includes('L')) ||
          status.includes('T')
            ? carry.TestLive + 1
            : carry.TestLive,
        LiveOnly:
          status.length === 1 && status[0] === 'L'
            ? carry.LiveOnly + 1
            : carry.LiveOnly,
        TestOnly:
          status.length === 1 && status[0] === 'T'
            ? carry.TestOnly + 1
            : carry.TestOnly,
      }),
      { TestLive: 0, LiveOnly: 0, TestOnly: 0 },
    );

  let additionalButtons = [];

  if (selectedItemIds.size === 1 && TestLive === 1 && LiveOnly === 0) {
    additionalButtons = CLONE_COMMIT_BUTTON_SET;
    // remove commit button when userRole is set to 'Full Client SS GR | Edit' as per MCDS-19182
    if(userRole === userRoleEdit){
      const indexCommit = additionalButtons.indexOf('commit');
      additionalButtons.splice(indexCommit, 1);
    }
  }
  if (TestLive > 1 && LiveOnly === 0) {
    // add commit button only when userRole is NOT set to 'Full Client SS GR | Edit' as per MCDS-19182 
    if(userRole !== userRoleEdit){
      additionalButtons = COMMIT_BUTTON_SET;
    }
  }
  if (selectedItemIds.size === 1 && TestLive === 0) {
    additionalButtons = CLONE_BUTTON;
  }

  const userSearchWithinRules = () => {
    const checkValue = /^[a-zA-Z0-9-_ ]*$/;
    if (queryValue.length === 0) {
      rulesGridQueryUpdate();
    }
    if (checkValue.test(queryValue) && queryValue.length >= 3) {
      setHasError(false);
      rulesGridQueryUpdate(queryValue);
    } else {
      setHasError(true);
      setErrorMessage('Please enter at least three valid characters - alphanumeric, space, - or _ is allowed. ');
    }
  };

  
  return (
    <PaddingWrapper>
      {multipleCommitPutSuccess && (
        <Portal>
          <NotificationBanner
            icon="Info"
            type="positive"
            text="The selected rules have been committed to live successfully."
          />
        </Portal>
      )}
      {multipleCommitPutError && (
        <Portal>
          <NotificationBanner
            type="negative"
            text={`There is an error in committing the selected rules. Error Code:  
          ${multipleCommitPutError.errorCommit.code}`}
          />
        </Portal>
      )}
      <DetailHeader
        {...headerProps}
        title={renderHeaderTitle() || ''}
        amountKey="amount"
        domID="header"
        count={counterLabel}
        marginBottom={12}
      />
      {showMultipleCommitRulesModal && <MultipleCommitRulesModal
        {...props}
        records={generateGridRecordsFromProps(
          records.filter(({ id }) => selectedItemIds.has(id)),
        )}
        title={headerName}
        isShowingMultipleCommitRulesModal={showMultipleCommitRulesModal}
        setIsShowingMultipleCommitRulesModal={setIsShowingMultipleCommitRulesModal}
        triggerMultipleCommitPutRequest={triggerMultipleCommitPutRequest}
        triggerImagesTestCountRequest={triggerImagesTestCountRequest}
        handleCellClick={handleCellClick}
        multipleTestImageCountRecords={multipleTestImageCountRecords}
      />}
      <GroupDetailRow>
        <ButtonGroup>
          <Button
            name="add rule"
            buttonType="emphasized"
            onClick={onButtonClick('add')}
            size="medium"
            type="button"
          />
          {map(additionalButtons, name => (
            <Button
              name={name}
              key={name}
              buttonType="standard"
              onClick={onButtonClick(name)}
              size="medium"
              type="button"
            />
          ))}
        </ButtonGroup>
        <RulesFilter
          domID="rule-filter"
          onEnterPress={userSearchWithinRules}
          onButtonClick={userSearchWithinRules}
          onChange={(event, { value }) => setQueryValue(value) }
          placeholder="Search by name or description"
          initialValue={ruleGridQuery}
          hasError={hasError}
          errorMessage={hasError ? errorMessage : null}
          buttonContent={<SearchButtonImage alt="search" src={searchIcon} />}
        />
      </GroupDetailRow>
    </PaddingWrapper>
  );
};

RulesGridHeader.defaultProps = {
  recordCount: 0,
};

RulesGridHeader.propTypes = {
  selectedItemIds: PropTypes.object.isRequired,
  records: PropTypes.array,
  addNotification: PropTypes.func,
  deleteNotification: PropTypes.func,
  toggleDetailsView: PropTypes.func,
  setIsPostingRulesForRuleTypes: PropTypes.func,
  setIsViewingRuleDetails: PropTypes.func,
  updateCurrentTab: PropTypes.func,
  recordCount: PropTypes.number,
  headerName: PropTypes.string,
  rulesGridQueryUpdate: PropTypes.func,
  showCloneRuleModal: PropTypes.bool,
  setIsShowingCloneRuleModal: PropTypes.func,
  triggerMultipleCommitPutRequest: PropTypes.func.isRequired,
  currentRuleTypeData: PropTypes.object,
  multipleCommitPutSuccess: PropTypes.bool,
  multipleCommitPutError: PropTypes.bool,
  handleCellClick: PropTypes.func,
  setRecordForDetailPane: PropTypes.func,
  isCloningRule: PropTypes.bool,
  isPostingRulesForRuleTypes: PropTypes.bool,
  gridConfig: PropTypes.object,
  counterLabel: PropTypes.string,
  setIsShowingMultipleCommitRulesModal: PropTypes.func,
  showMultipleCommitRulesModal: PropTypes.bool,
  isShowingMultipleCommitRulesModal: PropTypes.bool,
  triggerImagesTestCountRequest: PropTypes.func,
  multipleTestImageCountRecords: PropTypes.array,
};

export default RulesGridHeader;
