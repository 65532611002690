/* eslint-disable no-param-reassign */
import { useCallback, useEffect, useRef, useState } from "react";

export default function useSelectableGrid({uniqueKey, selectionKey, list, setlist, isUicl=false, 
  selectionCondition=()=>true, disabledCondition=(x)=>x===null}){


  const setlistRef=useRef(setlist);
  const selectionConditionRef=useRef(selectionCondition);
  const disabledConditionRef=useRef(disabledCondition);
  const internalChangeDetectionOfList=useRef(false);
  const [selectedItemIds, setselectedItemIds]=useState(new Set([]))
  const [selectedRecords, setselectedRecords]=useState(new Set([]))
  const [selectedItemIdsCurrentPage, setselectedItemIdsCurrentPage]=useState(new Set([]))
  const [disabledItemIds, setdisabledItemIds]=useState(new Set([]))

  useEffect(()=>{
    if (selectionCondition) {
      selectionConditionRef.current=selectionCondition
    }
  },[selectionCondition])
  useEffect(()=>{
    if (disabledCondition) {
      disabledConditionRef.current=disabledCondition
    }
  },[disabledCondition])

  useEffect(()=>{
    const sidsc=new Set(Array.from(selectedItemIdsCurrentPage));
    sidsc.clear();
    const sids=new Set(Array.from(selectedItemIds));
    Array.from(sids).forEach((item)=>{
      const l=list.find(x=>x[uniqueKey]===item);
      if (l && !sidsc.has(item)) {

        // check for selection condition and disabled condition
        const s=[{...l}].find(selectionConditionRef.current)
        const d=[{...l}].find(disabledConditionRef.current)
        if (s && !d) {
          sidsc.add(item);    
        }
      }
    })
    setselectedItemIdsCurrentPage(new Set(Array.from(sidsc)))
  },[selectedItemIds])

  const evaluateDisabledItemIds=useCallback(()=>{
    if (list && list.length>0) {
      const disc=new Set(Array.from(disabledItemIds));
      disc.clear();
      list.forEach((l)=>{
        const s=[{...l}].find(selectionConditionRef.current)
        const d=[{...l}].find(disabledConditionRef.current)
        if (s && d) {
          if(!disc.has(l[uniqueKey])) disc.add(l[uniqueKey])
        }
      })
      setdisabledItemIds(new Set(Array.from(disc)))
    }
  },[list, disabledItemIds])

  useEffect(()=>{
    if (internalChangeDetectionOfList.current) {
      internalChangeDetectionOfList.current=false;
      return;
    }
    const sidsc=new Set(Array.from(selectedItemIdsCurrentPage));
    sidsc.clear();
    
    if (list && list.length>0) {
      if (selectedItemIds && Array.from(selectedItemIds).length>0) {
        Array.from(selectedItemIds).forEach((item)=>{
          const l=list.find(x=>x[uniqueKey]===item);
          if (l && !sidsc.has(item)) {
            // check for selection condition and disabled condition
            const s=[{...l}].find(selectionConditionRef.current)
            const d=[{...l}].find(disabledConditionRef.current)
            if (s && !d) {
              sidsc.add(item);  
            }
          }
        })
        setselectedItemIdsCurrentPage(new Set(Array.from(sidsc)))
        if (!isUicl) {
          Array.from(selectedItemIds).forEach((item)=>{
            const l=list.find(x=>x[uniqueKey]===item);
            if (l) {
              // check for selection condition and disabled condition
              const s=[{...l}].find(selectionConditionRef.current)
              const d=[{...l}].find(disabledConditionRef.current)
              if (s && !d) {
                l[selectionKey]=true;
              }
            }
          })
          if(setlistRef && setlistRef.current) {
            internalChangeDetectionOfList.current=true;
            setlistRef.current(JSON.parse(JSON.stringify(list)));
          }
        }    
      }
      evaluateDisabledItemIds()
    }
  },[list, internalChangeDetectionOfList.current])

  const onSetSelectedItemIdsExplicitly=useCallback((ids)=>{
    const sids=new Set(Array.from(selectedItemIds));
    const srecs=new Set(Array.from(selectedRecords));
    sids.clear()
    srecs.clear()
    if (!isUicl) {
      list.forEach((item)=>{
        item[selectionKey]=false;
      })  
    }
    ids.forEach((id)=>{
      const item=list.find(x=>x[uniqueKey]===id)
      if (item) {
        if (!sids.has(item[uniqueKey])) {
        
          // check for selection condition and disabled condition
          const s=[{...item}].find(selectionConditionRef.current)
          const d=[{...item}].find(disabledConditionRef.current)
          if (s && !d) {
            // if(disc.has(item[uniqueKey])) disc.delete(item[uniqueKey])
            if(!isUicl) item[selectionKey]=true;
            sids.add(item[uniqueKey]);
            if (!Array.from(srecs).find(x=>x[uniqueKey]===item[uniqueKey])) {
              srecs.add(item);
            }
          }
        }
      }
    })
    setselectedItemIds(new Set(Array.from(sids)))
    setselectedRecords(new Set(Array.from(srecs)))
    evaluateDisabledItemIds()
    if(!isUicl && setlistRef && setlistRef.current) {
      internalChangeDetectionOfList.current=true;
      setlistRef.current(JSON.parse(JSON.stringify(list)));
    }
  },[selectedItemIds, selectedRecords, disabledItemIds, list])

  const onSelectAllCurrentPage=()=>{
    const sids=new Set(Array.from(selectedItemIds));
    const srecs=new Set(Array.from(selectedRecords));
    list.forEach((item)=>{

      // check for selection condition and disabled condition
      const s=[{...item}].find(selectionConditionRef.current)
      const d=[{...item}].find(disabledConditionRef.current)
      if (s && !d) {

        if (!sids.has(item[uniqueKey])) {
          if(!isUicl) item[selectionKey]=true;
          sids.add(item[uniqueKey]);
          if (!Array.from(srecs).find(x=>x[uniqueKey]===item[uniqueKey])) {
            srecs.add(item);
          }
        }
      }
    })
    setselectedItemIds(new Set(Array.from(sids)))
    setselectedRecords(new Set(Array.from(srecs)))
    evaluateDisabledItemIds()
    if(!isUicl && setlistRef && setlistRef.current) {
      internalChangeDetectionOfList.current=true;
      setlistRef.current(JSON.parse(JSON.stringify(list)));
    }
  }
  const onClearSelectionCurrentPage=()=>{
    const sids=new Set(Array.from(selectedItemIds));
    let srecs=new Set(Array.from(selectedRecords));
    const tsids=JSON.parse(JSON.stringify(Array.from(sids)))
    list.forEach((item)=>{
      if (tsids.find(x=>x===item[uniqueKey])) {
        if(!isUicl) item[selectionKey]=false;
        sids.delete(item[uniqueKey]);
      }
    })
    setselectedItemIds(new Set(Array.from(sids)))
    srecs=new Set(Array.from(srecs).filter(x=>Array.from(sids).find(y=>y===x[uniqueKey])))
    setselectedRecords(srecs);
    evaluateDisabledItemIds()
    if(!isUicl && setlistRef && setlistRef.current) {
      internalChangeDetectionOfList.current=true;
      setlistRef.current(JSON.parse(JSON.stringify(list)));
    }
  }
  const onClearAllSelection=()=>{
    if (!isUicl) {
      Array.from(selectedItemIds).forEach((item)=>{
        const l=list.find(x=>x[uniqueKey]===item);
        if (l) {
          l[selectionKey]=false;
        }
      })
      setselectedItemIds(new Set([]))
      setselectedRecords(new Set([]));
      if(setlistRef && setlistRef.current) {
        internalChangeDetectionOfList.current=true;
        setlistRef.current(JSON.parse(JSON.stringify(list)));
      }
    } else{
      setselectedItemIds(new Set([]))
      setselectedRecords(new Set([]));
    }
    evaluateDisabledItemIds()
  }

  const onRowSelect=(state, record)=>{
    const sids=new Set(Array.from(selectedItemIds));
    let srecs=new Set(Array.from(selectedRecords));
    if (!isUicl) {
      if (record) {
        if (record.constructor.name==="Array") {
          record.forEach((r)=>{
            const l=list.find(x=>x[uniqueKey]===r[uniqueKey]);
            if (l) {
              l[selectionKey]=state;
              if (state) sids.add(l[uniqueKey]) 
              else sids.delete(l[uniqueKey]);
              setselectedItemIds(new Set(Array.from(sids)))
              if (state) srecs.add(JSON.parse(JSON.stringify(l))) 
              else srecs=new Set(Array.from(srecs).filter(x=>x[uniqueKey]!==l[uniqueKey]));
              setselectedRecords(new Set(Array.from(srecs)))
            }
          })
        } else if(record.constructor.name==="Object"){
          const l=list.find(x=>x[uniqueKey]===record[uniqueKey]);
          if (l) {
            l[selectionKey]=state;
            if (state) sids.add(l[uniqueKey]) 
            else sids.delete(l[uniqueKey]);
            setselectedItemIds(new Set(Array.from(sids)))
            if (state)  srecs.add(JSON.parse(JSON.stringify(l))) 
            else srecs=new Set(Array.from(srecs).filter(x=>x[uniqueKey]!==l[uniqueKey]))
            setselectedRecords(new Set(Array.from(srecs)))
          }
        }
        if(setlistRef && setlistRef.current) {
          internalChangeDetectionOfList.current=true;
          setlistRef.current(JSON.parse(JSON.stringify(list)));
        }
      }
    } else{
      const id=state.currentTarget.getAttribute('id').replace('-row-select-checkbox','');
      const checked=state.currentTarget.getAttribute('class').indexOf('checked')===-1;
      if (record && record.selectedItemIds) {
        Array.from(record.selectedItemIds).forEach((item)=>{
          if (!sids.has(item)) {
            sids.add(item)
            setselectedItemIds(new Set(Array.from(sids)))
          }
          const l=list.find(x=>x[uniqueKey]===item);
          if (l && !Array.from(srecs).find(x=>x[uniqueKey]===item)) {
            srecs.add(l);
            setselectedRecords(new Set(Array.from(srecs)))
          }
        })
        if (!checked){
          if (id==="select-all-test-id"||id==="select-all-checkbox") {
            const tsids=JSON.parse(JSON.stringify(Array.from(sids)))
            tsids.forEach((item)=>{
              const l=list.find(x=>x[uniqueKey]===item);
              if (l) {
                sids.delete(item);
              }
            })
            setselectedItemIds(new Set(Array.from(sids)))
            srecs=new Set(Array.from(srecs).filter(x=>Array.from(sids).find(y=>y===x[uniqueKey])))
            setselectedRecords(srecs);
          } else if(sids.has(id)) {
            sids.delete(id)
            setselectedItemIds(new Set(Array.from(sids)))
            srecs=new Set(Array.from(srecs).filter(x=>x[uniqueKey]!==id))
            setselectedRecords(srecs)
          }
        }
      }
    }
  }

  return {onRowSelect, onClearAllSelection,onSelectAllCurrentPage, 
    onClearSelectionCurrentPage,
    selectedItemIds, selectedItemIdsCurrentPage, selectedRecords, disabledItemIds, onSetSelectedItemIdsExplicitly};
}