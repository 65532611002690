import { useAxios } from '../hooks'

const useAssignableRolesAndSources = () => {
  const { data, ...rest } = useAxios({
    url: `/members/assignableContextRoles`,
    method: 'GET',
  })

  return { data, roles: data?.data?.dataRoles, dataSources: data?.data?.dataSources, ... rest }
}
export default useAssignableRolesAndSources