import styled from 'styled-components';
import { UnstyledButton } from './buttons';


export default styled(UnstyledButton)`
  outline: none;

  .layout-control {
    fill: #737D85;
  }
  &:hover .layout-control {
    fill: #4CC6FF;
  }
`;
