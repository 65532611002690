import React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import ReserveForm from '../containers/FormImage835Manager';
import DeployForm from '../containers/FormImage835Manager/DeployFormIndex';
import CommitForm from '../containers/FormImage835Manager/CommitFormIndex';

const ImageManagerRoutes = () => (
  <Switch>
    <Route path="/ImageManager/ReserveImage" component={ReserveForm} />
    <Route path="/ImageManager/DeployImage" component={DeployForm} />
    <Route path="/ImageManager/CommitImage" component={CommitForm} />
    <Route exact path="/ImageManager">
      <Redirect to="/ImageManager/ReserveImage?param=1" />
    </Route>
  </Switch>
)

export default ImageManagerRoutes;