import Api from '..';
import get from 'lodash/get';
import { API_BASE } from '../constants';

// -------------- Edit Group----------------
export const putGroups = async (
  { clientLayoutId, userSecurityTokenKey, username, roleId },
  {
    groupId = '',
    name = '',
    desc = '',
    hierarchy = [],
    selectedRecord,
    triggerAllGroupsRequestParams,
  },
) => {
  try {
    const baseUrl = `${API_BASE}/groups/${clientLayoutId}/${groupId}`;
    const group = JSON.stringify({
      name: name.trim(),
      description: desc.trim(),
      hierarchy,
      cloneAssociation: false,
    });
    const api = new Api(userSecurityTokenKey, username, null, roleId);
    const putGroupsResponse = await api.put(baseUrl, group);

    if (putGroupsResponse.error) {
      throw putGroupsResponse.error;
    }
    putGroupsResponse.selectedRecord = selectedRecord;
    putGroupsResponse.triggerAllGroupsRequestParams = triggerAllGroupsRequestParams;
    return putGroupsResponse;
  } catch (error) {
    throw error;
  }
};

// -------------- Post Group----------------
export const postGroups = async (
  { clientLayoutId, userSecurityTokenKey, username, roleId },
  {
    name = '',
    desc = '',
    hierarchy = [],
    groupId,
    isCloningGroup,
    triggerAllGroupsRequestParams,
  },
) => {
  try {
    const baseUrl = `${API_BASE}/groups/${clientLayoutId}`;
    const body = isCloningGroup ? {
      name: name.trim(),
      description: desc.trim(),
      hierarchy,
      cloneAssociation: isCloningGroup,
      groupIdFrom: groupId,
    } : {
      name: name.trim(),
      description: desc.trim(),
      hierarchy,
      cloneAssociation: isCloningGroup,
    };
    const api = new Api(userSecurityTokenKey, username, null, roleId);
    const postGroupsResponse = await api.post(baseUrl, JSON.stringify(body));

    if (postGroupsResponse.error) {
      throw postGroupsResponse.error;
    }
    postGroupsResponse.triggerAllGroupsRequestParams = triggerAllGroupsRequestParams;
    return postGroupsResponse;
  } catch (error) {
    throw error;
  }
};

export const activateGroup = async (
  { clientLayoutId, userSecurityTokenKey, username, roleId },
  { groupId, isActive = 'true' },
) => {
  try {
    const baseUrl = `${API_BASE}/groups/${clientLayoutId}/${groupId}?isActive=${isActive}`;
    const api = new Api(userSecurityTokenKey, username, null, roleId);
    const activateGroupResponse = await api.put(baseUrl);

    if (activateGroupResponse.error) {
      throw activateGroupResponse.error;
    }
    return activateGroupResponse;
  } catch (error) {
    throw error;
  }
};

export const deactivateGroup = async (
  { clientLayoutId, userSecurityTokenKey, username, roleId },
  { groupId, isActive = 'false' },
) => {
  try {
    const baseUrl = `${API_BASE}/groups/${clientLayoutId}/${groupId}?isActive=${isActive}`;
    const api = new Api(userSecurityTokenKey, username, null, roleId);
    const deactivateGroupsResponse = await api.put(baseUrl);

    if (deactivateGroupsResponse.error) {
      throw deactivateGroupsResponse.error;
    }
    // This is the actual resolved data from the API. Extracting the rule types array
    // from the response and translating to fit to UX's needs
    const deactivateGroupsResponseUpdate = deactivateGroupsResponse;
    deactivateGroupsResponseUpdate.status = !deactivateGroupsResponse.error;
    deactivateGroupsResponseUpdate.message = deactivateGroupsResponse.error
      ? get(deactivateGroupsResponse, 'error.message', '')
      : get(deactivateGroupsResponse, 'success', '');

    return deactivateGroupsResponseUpdate;
  } catch (error) {
    const deactivateGroupsResponseError = { status: false, message: { message: error.message } };
    throw deactivateGroupsResponseError;
  }
};

export const deactivateMultipleGroups = async (
  { clientLayoutId, userSecurityTokenKey, username, roleId },
  { groupId },
) => {
  try {
    const baseUrl = `${API_BASE}/groups/${clientLayoutId}:deactivate`;
    const api = new Api(userSecurityTokenKey, username, null, roleId);
    const groupsToDeactivate = JSON.stringify({
      groupIds: groupId,
    });
    const deactivateGroupsResponse = await api.put(baseUrl, groupsToDeactivate);

    if (deactivateGroupsResponse.error) {
      throw deactivateGroupsResponse.error;
    }

    // This is the actual resolved data from the API. Extracting the rule types array
    // from the response and translating to fit to UX's needs
    const deactivateGroupsResponseUpdate = deactivateGroupsResponse;
    deactivateGroupsResponseUpdate.status = !deactivateGroupsResponse.error;
    deactivateGroupsResponseUpdate.message = deactivateGroupsResponse.error
      ? get(deactivateGroupsResponse, 'error.message', '')
      : get(deactivateGroupsResponse, 'success', '');

    return deactivateGroupsResponseUpdate;
  } catch (error) {
    const deactivateGroupsResponseError = { status: false, message: error };
    throw deactivateGroupsResponseError;
  }
};
