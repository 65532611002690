import styled from 'styled-components';
import { DetailHeader } from 'ui-core-ssgr';

import { ButtonGroup } from '../../../../shared/components/ButtonGroup';

export const SubDetailHeader = styled(DetailHeader)`
  h1 {
    font-size: 20px;
    margin: 0.8em 0 0.4em;
  }
  padding-left: 2em;
`;

export const ButtonStatus = styled(ButtonGroup)`
    right: 1em;
    position: absolute;
    margin-bottom:50px;
    & button:last-child {
        margin-right: .5rem;
    `;
