/* eslint-disable no-plusplus */
/* eslint-disable no-loop-func */
/* eslint-disable radix */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-useless-escape */
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import styled from 'styled-components/dist/styled-components'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import {
  Grid,
  Modal,
  ModalHeader,
  useLayout,
  ListPaneHeader,
  Button, Input, Breadcrumb
} from 'ui-core/dist'
import { useDeactivateEftAccount, useGetConsTypeDesc, useGetDrawDownDesc, useCheckGroupRule } from '../../api/usePayerEftAccount';
import { SelectDropdown } from '../../components'
import LoadingComponent from '../../components/LoadingComponent';
import ErrorPage from '../ErrorPage/components/ErrorPage';
import ListPaneHeaderWrapper from '../../components/ErrorDisplaySection/ListPaneHeaderWrapper';
import useNotificationBannerHook from '../../useNotificationBannerHook';
import GridPaginationWrapper from '../../components/GridPaginationWrapper';
import * as constants from './Modules/constants';
import useSortableGrid from '../../hooks/useSortableGrid';
import {resetEditPayerEftAccount} from './Modules/action';
import './Modules/styles.scss'

const StyledContainer=styled.table`
width: 60%;
& tr td:first-child {
    width: 300px !important;
}
& tr td input {
    width: 370px !important;
}
`

const PayerEftAccountDeactivateConfirmation = () => {
  const {
    expandCollapseListPaneButtonConfig
  } = useLayout()
  const { notify } = useNotificationBannerHook();
  const history = useHistory();
  const dispatch=useDispatch()
    
  const { editPayerEftAccount } 
    = useSelector(({ payerEftAccount }) => payerEftAccount);
  
  const { isLoading: isLoadinggetConsTypeDesc, isError: isErrorgetConsTypeDesc, error: errorgetConsTypeDesc, 
    postData: getConsTypeDesc, data: ConsTypeDesc } = useGetConsTypeDesc()
  
  const { isLoading: isLoadinggetDrawDownDesc, isError: isErrorgetDrawDownDesc, error: errorgetDrawDownDesc, 
    postData: getDrawDownDesc, data: DrawDownDesc } = useGetDrawDownDesc()
      
  const { isLoading: isLoadingdeactivateEftAccount, isError: isErrordeactivateEftAccount, error: errordeactivateEftAccount, postData: deactivateEftAccount, 
    metaData: metadatadeactivateEftAccount, data: deactivateEftAccountResp } = useDeactivateEftAccount()

  const { isLoading: isLoadingcheckGroupRule, isError: isErrorcheckGroupRule, error: errorcheckGroupRule, postData: checkGroupRule,
    metaData: metadatacheckGroupRule, data: checkGroupRuleResp } = useCheckGroupRule()
    
  const [consolidationTypeId, setConsolidationTypeId]=useState()
  const [consolidationTypeDesc, setConsolidationTypeDesc]=useState()
  const [drawDownTypeId, setDrawDownTypeId]=useState()
  const [drawDownTypeDesc, setDrawDownTypeDesc]=useState()
  const [eftAcctId, setEftAcctId]=useState()
  const [accountId, setAccountId]=useState()
  const [routingCode, setRoutingCode]=useState()
  const [companyName, setCompanyName]=useState()
  const [descretionaryData, setDescretionaryData]=useState()
  const [odfiId, setOdfiId]=useState()
  const [effDateDiff, setEffDateDiff]=useState()
  const [companyId, setCompanyId]=useState()
  const [companyEntryDesc, setCompanyEntryDesc]=useState()
  const [origFinInst, setOrigFinInst]=useState()
  const [accountVerified, setAccountVerified]=useState()
  const [bankingModel, setBankingModel]=useState()
  const [versionNumber, setVersionNumber]=useState()
  const [statusCode, setStatusCode]=useState()
  const [tUpdated, setTUpdated]=useState()
  const [acctType, setAcctType]=useState()
  const [holdDays, setHoldDays]=useState()
  const [count, setcount] = useState(0);

  useEffect(()=>{
    if (editPayerEftAccount && editPayerEftAccount?.eftAcctId) {
      setConsolidationTypeId(editPayerEftAccount?.consolidationTypeId||'')
      setConsolidationTypeDesc(editPayerEftAccount?.consolidationTypeDesc||'')
      setDrawDownTypeId(editPayerEftAccount?.drawDownTypeId||'')
      setDrawDownTypeDesc(editPayerEftAccount?.drawDownTypeDesc||'')
      setEftAcctId(editPayerEftAccount?.eftAcctId||'')
      setAccountId(editPayerEftAccount?.accountId||'')
      setRoutingCode(editPayerEftAccount?.routingCode||'')
      setCompanyName(editPayerEftAccount?.companyName||'')
      setDescretionaryData(editPayerEftAccount?.descretionaryData||'')
      setOdfiId(editPayerEftAccount?.odfiId||'')
      setEffDateDiff(editPayerEftAccount?.effDateDiff||'')
      setCompanyId(editPayerEftAccount?.companyId||'')
      setCompanyEntryDesc(editPayerEftAccount?.companyEntryDesc||'')
      setOrigFinInst(editPayerEftAccount?.origFinInst||'')
      setAccountVerified(editPayerEftAccount?.accountVerified||'')
      setBankingModel(editPayerEftAccount?.bankingModel||'')
      setVersionNumber(editPayerEftAccount?.versionNumber||'')
      setStatusCode(editPayerEftAccount?.statusCode||'')
      setTUpdated(editPayerEftAccount?.tUpdated||'')
      setAcctType(editPayerEftAccount?.acctType||'')
      setHoldDays(editPayerEftAccount?.holdDays||'')
    }
  },[editPayerEftAccount])

  useEffect(() => {
    if (metadatadeactivateEftAccount) {
      const { status, statusText } = metadatadeactivateEftAccount;
      if (status === 200 || statusText === "OK") {
        if (deactivateEftAccountResp && deactivateEftAccountResp.fs) {
          const { fs } = deactivateEftAccountResp;
          if (fs) {
            notify(fs, 'negative')
            return;
          }
        }
        let message={};
        if (deactivateEftAccountResp.resp==="NO LIVE") {
          message={msg: constants.status_client_eft_deactivate_not_live.replace('{0}', eftAcctId), type: 'positive'}
        } else if(statusCode==="T"){
          message={msg: constants.status_client_eft_deactivate_test_successful.replace('{0}', eftAcctId), type: 'positive'}
        } else{
          message={msg: constants.status_client_eft_deactivate_successful.replace('{0}', eftAcctId), type: 'positive'}
        }
        history.push({pathname: '/ClientRulesManager/PayerEftAccount/index', state:JSON.parse(JSON.stringify(message))})
      } else {
        notify(constants.error_client_eft_deactivate_failure.replace('{0}', eftAcctId), 'negative')
      }
    }
  }, [metadatadeactivateEftAccount, deactivateEftAccountResp])

  useEffect(() => {
    if (metadatacheckGroupRule) {
      const { status, statusText } = metadatacheckGroupRule;
      if (status === 200 || statusText === "OK") {
        if (checkGroupRuleResp && checkGroupRuleResp.fs) {
          const { fs } = checkGroupRuleResp;
          if (fs) {
            notify(fs, 'negative')
          }
        }
      } else {
        notify(constants.error_client_eft_check_group_rule_failure.replace('{0}', eftAcctId), 'negative')
      }
    }
  }, [metadatacheckGroupRule, checkGroupRuleResp])

  useEffect(()=>{
    if (consolidationTypeId) getConsTypeDesc({consType: consolidationTypeId})
    if (drawDownTypeId) getDrawDownDesc({drawDownType: drawDownTypeId});
    if (eftAcctId) checkGroupRule({acctId: eftAcctId})
    return ()=>{
      dispatch(resetEditPayerEftAccount())
    }
  },[])
  useEffect(()=>{
    if (checkGroupRuleResp && checkGroupRuleResp.resp) {
      const check=checkGroupRuleResp.resp;
      if (check==="LIVE FOUND") {
        notify(constants.status_client_eft_deactivate_warning, 'caution')
      }
    }
  },[checkGroupRuleResp])
  useEffect(()=>{
    if (ConsTypeDesc && ConsTypeDesc.resp) {
      setConsolidationTypeDesc(ConsTypeDesc.resp)
    }
  },[ConsTypeDesc])
  useEffect(()=>{
    if (DrawDownDesc && DrawDownDesc.resp) {
      setDrawDownTypeDesc(DrawDownDesc.resp)
    }
  },[DrawDownDesc])
  const onDeactivateConfirmationClick=()=>{
    const abfPeo=populateEftObj();
    deactivateEftAccount({...abfPeo, count})
    setcount(count+1)
  }
  const populateEftObj=()=> {
    const abfPeo={};
    abfPeo.consType = consolidationTypeId||'';
    abfPeo.consTypeDesc = consolidationTypeDesc||'';
    abfPeo.drawDown = drawDownTypeId||'';
    abfPeo.drawDownDesc = drawDownTypeDesc||'';
    abfPeo.acctId = eftAcctId||'';
    abfPeo.onus = accountId||'';
    abfPeo.routeId = routingCode||'';
    abfPeo.compName = companyName||'';
    abfPeo.compData = descretionaryData||'';
    abfPeo.odfiId = odfiId||'';
    abfPeo.dateDiff = effDateDiff||'';
    abfPeo.compId = companyId||'';
    abfPeo.compEntDesc = companyEntryDesc||'';
    abfPeo.originId = origFinInst||'';
    abfPeo.verified = accountVerified||'';
    abfPeo.payerBank = bankingModel||'';
    abfPeo.version = versionNumber||'';
    abfPeo.status = statusCode||'';
    abfPeo.updated = tUpdated||'';
    abfPeo.acctType = acctType||'';
    abfPeo.holdDays = holdDays||'';
    return abfPeo;
  }
  return (
    <>
      <ListPaneHeaderWrapper
        title="Confirm Payer EFT Account Deactivation"
        layoutControls={[expandCollapseListPaneButtonConfig]}
      />
      <>
        {isLoadingdeactivateEftAccount || isLoadinggetConsTypeDesc || isLoadinggetDrawDownDesc
        || isLoadingcheckGroupRule ? (
        // replace with a better loading component            
            <div style={{ marginLeft: '50%' }}><LoadingComponent /></div>
          ) : isErrordeactivateEftAccount || isErrorgetConsTypeDesc || isErrorgetDrawDownDesc
        || isErrorcheckGroupRule ? (
          // replace with a better error component
              <ErrorPage
                // this prop isn't real but is an example
                error={errordeactivateEftAccount || errorgetConsTypeDesc || errorgetDrawDownDesc
            || errorcheckGroupRule}
              />
            ) : (
              <div className="marginLeft">
                <table>
                  <tr>
                    <td>
                      <Breadcrumb
                        breadcrumbItems={[{ title: "Client Payer Eft Manager" }, 
                          { title: "Deactivate Client Payer Eft Manager" }]}
                        onBreadcrumbItemClick={()=>history.goBack()}
                        domID="breadcrumb"
                      />
                      <br/>
                    </td>
                  </tr>
                  <tr>
                    <td><span className="required-glyph">
                      <label>Click Submit to deactivate this EFT Account.</label></span></td>
                  </tr>
                  <tr><p></p></tr>
                  <tr>
                    <StyledContainer border="0">
                      {bankingModel==="1"?(
                        <><tr>
                          <td className="name"><label>Banking Model:</label></td>
                          <td className="value"><label>Payer Bank</label></td>
                        </tr><tr><p></p></tr></>
                      ):null}
                      {bankingModel==="0"?(
                        <><tr>
                          <td className="name"><label>Banking Model:</label></td>
                          <td className="value"><label>Emdeon</label></td>
                        </tr><tr><p></p></tr></>
                      ):null}
                      <tr>
                        <td className="name"><label>Payer Eft Account Number:</label></td>
                        <td className="value"><label>{accountId}</label></td>
                      </tr>
                      <tr><p></p></tr>
                      <tr>
                        <td className="name"><label>Payer EFT Routing Code:</label></td>
                        <td className="value"><label>{routingCode}</label></td>
                      </tr>
                      <tr><p></p></tr>
                      <tr>
                        <td className="name"><label>Company Name:</label></td>
                        <td className="value"><label>{companyName}</label></td>
                      </tr>
                      <tr><p></p></tr>
                      {bankingModel==="1"?(
                        <><tr>
                          <td className="name"><label>ODFI ID:</label></td>
                          <td className="value"><label>{odfiId}</label></td>
                        </tr><tr><p></p></tr></>
                      ):null}
                      <tr>
                        <td className="name"><label>Effective Date Difference:</label></td>
                        <td className="value"><label>{effDateDiff}</label></td>
                      </tr>
                      <tr><p></p></tr>
                      {bankingModel==="0"?(
                        <><tr>
                          <td className="name"><label>Hold Days:</label></td>
                          <td className="value"><label>{holdDays}</label></td>
                        </tr><tr><p></p></tr></>
                      ):null}
                      {bankingModel==="1"?(
                        <><tr>
                          <td className="name"><label>Discretionary Data:</label></td>
                          <td className="value"><label>{descretionaryData}</label></td>
                        </tr><tr><p></p></tr></>
                      ):null}
                      {bankingModel==="1"?(
                        <><tr>
                          <td className="name"><label>Company Identification (Assigned by ODFI):</label></td>
                          <td className="value"><label>{companyId}</label></td>
                        </tr><tr><p></p></tr></>
                      ):null}
                      {bankingModel==="1"?(
                        <><tr>
                          <td className="name"><label>Company Entry Description:</label></td>
                          <td className="value"><label>{companyEntryDesc}</label></td>
                        </tr><tr><p></p></tr></>
                      ):null}
                      {bankingModel==="1"?(
                        <><tr>
                          <td className="name"><label>Originating Financial Institution:</label></td>
                          <td className="value"><label>{origFinInst}</label></td>
                        </tr><tr><p></p></tr></>
                      ):null}
                      {bankingModel==="0"?(
                        <><tr>
                          <td className="name"><label>Consolidation Type:</label></td>
                          <td className="value"><label>{consolidationTypeDesc}</label></td>
                        </tr><tr><p></p></tr></>
                      ):null}
                      {bankingModel==="0"?(
                        <><tr>
                          <td className="name"><label>Draw Down Type:</label></td>
                          <td className="value"><label>{drawDownTypeDesc}</label></td>
                        </tr><tr><p></p></tr></>
                      ):null}
                      {bankingModel==="0"?(
                        <><tr>
                          <td className="name"><label>Account Type:</label></td>
                          {acctType === "C" ? (
                            <td className="value"><label>Checkings</label></td>
                          ) : null}
                          {acctType === "S" ? (
                            <td className="value"><label>Savings</label></td>
                          ) : null}
                        </tr><tr><p></p></tr></>
                      ):null}
                      <tr>
                        <td className="name"><label>Account Verified:</label></td>
                        {accountVerified==="0"?(
                          <td className="value"><label>NO</label></td>
                        ):null}
                        {accountVerified==="1"?(
                          <td className="value"><label>YES</label></td>
                        ):null}
                      </tr>
                    </StyledContainer>
                    <table>
                      <tr><p></p></tr>
                      <tr>
                        <td className="value" colspan="2">
                          <div className='displayFlex'>
                            <Button
                              id='ContactEditorAdd'
                              size="small"
                              name="Cancel"
                              onClick={()=>history.goBack()}
                            />
                            <div className='marginLeft'>
                              <Button
                                id='ContactEditorAdd'
                                size="small"
                                name="Submit"
                                onClick={()=>onDeactivateConfirmationClick()}
                              />
                            </div>
                          </div>
                          {/* <input type="button" value="Back" onClick={()=>history.goBack()} />&nbsp; */}
                        </td>
                      </tr>
                    </table>
                  </tr>
                </table>
              </div>
            )
        }
      </>
    </>
  )
}

export default PayerEftAccountDeactivateConfirmation;