import React,{useEffect} from 'react';
import PropTypes from 'prop-types';
import { NotificationBanner } from 'ui-core-ssgr';
import AssociateGridHeader from './AssociateGridHeader';
import AssociateGridWrapper from './AssociateGridWrapper';

export const AssociateTab = props => {
  const {
    selectedRecord,
    notifications,
    deleteNotification,
    loading,
    deleteAllNotifications,
    selectedRuleTypeId,
    captureCurrentPage
  } = props;
  
  useEffect(() => {
    const pageTrackerPrefix = 'GFA-';
    // following action is called to capture new relic data
    captureCurrentPage({pageId: `${pageTrackerPrefix}${selectedRuleTypeId}`});
  }, []);
  
  return (
    <>
      {notifications.has('ruleDetailsError') && (
        <NotificationBanner
          domID="api-error-notification"
          type={notifications.get('ruleDetailsError').type}
          text={notifications.get('ruleDetailsError').msg}
          onDismiss={() => deleteNotification('ruleDetailsError')}
          autoDismiss
          timer={1800000}
        />
      )}
      <AssociateGridHeader />
      <AssociateGridWrapper
        selectedRecord={selectedRecord}
        loading={loading}
        deleteAllNotifications={deleteAllNotifications}
      />
    </>
  );
};

AssociateTab.propTypes = {
  selectedRecord: PropTypes.shape({
    id: PropTypes.string,
  }),
  groupAssociationsForRuleIdTrigger: PropTypes.func,
  associatedGroupRecordsNoTermedRecords: PropTypes.array,
  captureCurrentPage: PropTypes.func,
};

export default AssociateTab;
