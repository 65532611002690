import React, { useState, useEffect } from 'react'
import {
  Grid,
  useLayout,
  ListPaneHeader,
  Button, ButtonGroup, Accordion, CollapsePanel, CollapsePanelTitle, CollapsePanelDetails, Checkbox
} from 'ui-core/dist'
import { SelectDropdown } from '../../components'
import axios from '../../axios';
import { useReportClientLayoutsRequest } from '../../api/useReportManager';
import { LoadingPage } from '../../components';
import useNotificationBannerHook from '../../useNotificationBannerHook';
import ErrorPage from '../ErrorPage';
import withAxiosCancellation from '../../components/withAxiosCancellation';
import './modules/style.scss'

const ClientInformationReport = ({getCancelSourceWithName}) => {
  const {
    expandCollapseListPaneButtonConfig
  } = useLayout()

  const { notify } = useNotificationBannerHook()

  const { clientLayoutList: clientLayoutListOption, isLoading: pIsLoading, error, isError } = useReportClientLayoutsRequest({getCancelSource:getCancelSourceWithName('reportClientLayouts')});
  const [clientLayoutValue, setClientLayoutValue] = useState({ label: "", value: "" });
  const [clientInfoValue, setClientInfoValue] = useState();
  const [clientValue, setClientValue] = useState();
  const [layoutValue, setLayoutValue] = useState();
  const [isReportFetched, setIsReportFetched] = useState(false);
  const [isAllReportLoading, setisAllReportLoading] = useState(false);
  const [nonCommingleGroups, setNonCommingleGroups] = useState([]);
  const [pricingCols, setPricingCols] = useState([]);
  const [pricingData, setPricingData] = useState([]);

  const handleClientLayoutOptionChange = (e) => {
    setClientLayoutValue({ label: e?.label, value: e?.value });
    setClientValue(e?.value?.substring(0, 4));
    setLayoutValue(e?.value?.substring(4));
  }
  useEffect(() => {
    if (clientInfoValue) {
      setIsReportFetched(true);
    }
  }, [clientInfoValue]);


  const getClientInfoReport = () => {
    setisAllReportLoading(true);
    let client = clientValue;
    let layout = layoutValue;
    if (!clientValue && !layoutValue) {
      client = clientLayoutListOption?.[0]?.value?.substring(0, 4);
      layout = (clientLayoutListOption?.[0]?.value?.substring(4));
      setClientLayoutValue({ label: clientLayoutListOption?.[0]?.label, value: clientLayoutListOption?.[0]?.value });
    }
    axios.get(`/reports-manager/client-information-report/${client}/${layout}`).then(res => {
      setClientInfoValue(res.data.report);
      const emailRecip = res?.data?.report?.["email-recipients"]?.["email-recipient"] || null;
      const newEmailRecip = emailRecip ? Array.isArray(emailRecip) ? emailRecip : [emailRecip] : []
      let emailrRcipientsTemp = newEmailRecip.map(obj => ({ ...obj, email: obj["email-type"].description }));
      setEmailRcipientsData(emailrRcipientsTemp || []);

      const grpList = res?.data?.report?.group || null;
      const newGrpList = grpList ? Array.isArray(grpList) ? grpList : [grpList] : []

      setExcludedGroupData(newGrpList)

      const pricing = res.data.report?.ClientBillingDetails?.[0]?.ProductItem || null;
      const newPricing = pricing ? Array.isArray(pricing) ? pricing : [pricing] : []
      const productItemPrices = [];

      const pricingColumns = [{
        dataName: 'isActive',
        text: 'Is Active',
        cellType: 'text',
        width: '5%'
      },
      {
        dataName: 'description',
        text: 'Product Item Description',
        cellType: 'text',
        width: '9%'
      }];

      newPricing.forEach((price, parentIdx) => {
        const pricingObj = {
          description: price.description,
          backSideCount: <Checkbox
            ariaLabelledBy=""
            checked={price.ProductItemPrices?.include_backside_counts === '1'}
            dataTestId="test-checkboxDefault"
            label=""
            disabled
          />,
          isActive: <Checkbox
            ariaLabelledBy=""
            checked={price.ProductItemPrices?.is_active === '1'}
            dataTestId="test-checkboxDefault"
            label=""
            disabled
          />
        };
        const tierPricing = price.ProductItemPrices?.TierPricing;
        const newTierPricing = tierPricing ? Array.isArray(tierPricing) ? tierPricing : [tierPricing] : []

        newTierPricing.forEach((tierPrice, idx) => {
          pricingObj['tier' + (idx + 1) + 'price'] = tierPrice.price;
          pricingObj['tier' + (idx + 1) + 'BackToZero'] = <Checkbox
            ariaLabelledBy=""
            checked={tierPrice.back_to_zero === '1'}
            dataTestId="test-checkboxDefault"
            label=""
            disabled
          />
          pricingObj['tier' + (idx + 1) + 'MinCounts'] = tierPrice.minimum_count;
          if (parentIdx === 0) {
            if (idx !== 0) {
              pricingColumns.push({
                dataName: 'tier' + (idx + 1) + 'MinCounts',
                text: 'Minimum Counts',
                cellType: 'text',
              })
              pricingColumns.push({
                dataName: 'tier' + (idx + 1) + 'price',
                text: 'Price',
                cellType: 'text',
              })
              pricingColumns.push({
                dataName: 'tier' + (idx + 1) + 'BackToZero',
                text: 'Back To Zero',
                cellType: 'custom',
              })
            } else {
              pricingColumns.push({
                dataName: 'tier' + (idx + 1) + 'price',
                text: 'Price',
                cellType: 'text',
              })
              if (price.ProductItemPrices?.include_backside_counts === '1') {
                pricingColumns.push({
                  dataName: 'backSideCount',
                  text: 'Include BackSide Counts',
                  cellType: 'text',
                })
              }
            }
          }
        });

        productItemPrices.push(pricingObj);
      });
      setPricingData(productItemPrices);
      setPricingCols(pricingColumns);

      // let tPricingEOBDataTemp = newPricing.map(obj => ({
      //   ...obj,
      //   is_active: obj?.ProductItemPrices.is_active,
      //   price: obj?.ProductItemPrices.TierPricing.price,
      // }
      // ));
      // setPricingEOBData(tPricingEOBDataTemp);
      // let tempselected = tPricingEOBDataTemp.filter((y) => { return y.is_active == "1" })
      // setPricingEOBselectedItems(new Set(Array.from(tempselected)))

      const nonCommingleGrp = res.data.report?.grpNonComingle?.group || null;
      const newNonCommingleGrp = nonCommingleGrp ? Array.isArray(nonCommingleGrp) ? nonCommingleGrp : [nonCommingleGrp] : []
      setNonCommingleGroups(newNonCommingleGrp);

      //setPricingEOBselectedItems(tempselected);
      setisAllReportLoading(false);
    }).catch(err => {
      setisAllReportLoading(false);
      notify(err.displayMessage, "negative")
    })
  }
  const printClientInfoReport = () => {

    window.print(); return false;

  }
  const [emailRcipientsData, setEmailRcipientsData] = useState();
  const emailRcipientsColumns = new Set([
    {
      dataName: 'name',
      text: 'Name',
      cellType: 'text',
    },
    {
      dataName: 'phone',
      text: 'Phone',
      cellType: 'text',
    },
    {
      dataName: 'email-address',
      text: 'Email',
      cellType: 'text',
    },
    {
      dataName: 'email',
      text: 'Email Type',
      cellType: 'text',
    }
  ]);

  const [excludedGroupData, setExcludedGroupData] = useState();
  const excludedGroupColumns = new Set([
    {
      dataName: 'id',
      text: 'GROUP ID',
      cellType: 'text',
    },
    {
      dataName: 'description',
      text: 'GROUP DESCRIPTION',
      cellType: 'text',
    },
  ]);

  return (
    <>
      <ListPaneHeader
        title={`Client Information Report`}
        layoutControls={[expandCollapseListPaneButtonConfig]}
      />
      {isError ? (
                  // replace with a better error component
                  <ErrorPage
                    // this prop isn't real but is an example
                    error={error}
                  />
      ) : pIsLoading ? (
        <LoadingPage height='40px'></LoadingPage>
      ) : (
      <div className="marginLeftUserReport">
        <table id="clientInfoReportSearch">
          <tr>
            <td className='clientInfocriteriaWidth'>
              <SelectDropdown
                  width="400px"
                  isClearable={false}
                  domID="clientType"
                  options={clientLayoutListOption}
                  size="small"
                  initialValue={clientLayoutValue ? clientLayoutValue.value ? clientLayoutValue : clientLayoutListOption[0] : clientLayoutListOption[0]}
                  onChange={(e) => handleClientLayoutOptionChange(e)}
                  label="Client / Layout"
                />
              
            </td>
          </tr>
          {/* <tr><td><Button
            buttonType="standard"
            size="small"
            name="Submit"
            onClick={() => { getClientInfoReport() }}
          />

          </td><td> <Button
            buttonType="standard"
            size="small"
            name="Print"
            onClick={() => { printClientInfoReport() }}

          /></td>


          </tr> */}
        </table>
        <div style={{ margin: '10px 0 0 40px' }}>
          {pIsLoading ? <></> : <ButtonGroup>
            <Button
              buttonType="standard"
              size="small"
              name="Submit"
              onClick={() => { getClientInfoReport() }}
            />
            {isReportFetched && !isAllReportLoading && <Button
              buttonType="standard"
              size="small"
              name="Print"
              onClick={() => { printClientInfoReport() }}
            />}
          </ButtonGroup>
          }
        </div>
      </div>
      )}
      <div >{
        isReportFetched ? (
          <div>
            {
              isAllReportLoading ? (
                <LoadingPage height='40px'></LoadingPage>
              ) : (
                  <div className='clientInfoReport' id="clientInfoReportToPrint">
                    <table id="clientInfoReportTable" >
                      <tr><td></td><td>{clientInfoValue["client-name"]} ( {clientLayoutValue.value} )</td></tr>
                      <tr>
                        <td><label>Client Address:</label></td>
                        <td >
                          {clientInfoValue?.address["name"]}<br></br>
                          {clientInfoValue?.address["line-1"]}<br></br>
                          {clientInfoValue?.address["line-2"]}<br></br>
                          {clientInfoValue?.address["city"]},&nbsp;
                        {clientInfoValue?.address["state"]},&nbsp;
                        {clientInfoValue?.address["zip"]}
                        </td>
                      </tr>
                      <tr><td>Phone Number:</td><td>{clientInfoValue.phone}</td> </tr>
                      <tr><td>Fax Number:</td><td>{clientInfoValue.fax}</td> </tr>
                      <tr><td>FTP User ID:</td><td> {clientInfoValue.ftp["user-id"]}</td> </tr>
                      <tr><td>FTP Password:</td><td> {clientInfoValue.ftp.password}</td> </tr>
                      <tr>
                        <td><label>Return Address:</label></td>
                        <td >
                          {clientInfoValue?.["return-address"]["name"]}<br></br>
                          {clientInfoValue?.["return-address"]["line-1"]}<br></br>
                          {clientInfoValue?.["return-address"]["line-2"] ? `${clientInfoValue?.["return-address"]["line-2"]}<br><br>` : ''}
                          {clientInfoValue?.["return-address"]["city"]},&nbsp;
                        {clientInfoValue?.["return-address"]["state"]},&nbsp;
                        {clientInfoValue?.["return-address"]["zip"]}
                        </td>
                      </tr>
                      <tr><td>Server Name:</td><td>{clientInfoValue["client-server"]}</td> </tr>
                      <tr><td>Return Logo:</td><td>	{clientInfoValue["return-logo"]}</td> </tr>
                      <tr><td>Return Style:</td><td>	{clientInfoValue["return-style"]?.["description"]}</td> </tr>
                      <tr><td>Return Delivery Type:</td><td>{clientInfoValue["return-delivery-type"]?.["id"]}&nbsp;	({clientInfoValue["return-delivery-type"]["description"]})</td> </tr>
                      <tr><td>Express Delivery Type:</td><td>  	{clientInfoValue["express-delivery-type"]?.["id"]}&nbsp;	({clientInfoValue["express-delivery-type"]["description"]})</td> </tr>
                      <tr><td>Alternate Mail Delivery Type:</td><td>	{clientInfoValue["alternate-mail-delivery-type"]?.["id"]}&nbsp;	({clientInfoValue["alternate-mail-delivery-type"]["description"]})</td> </tr>
                      <tr><td>PO Destination Flag:</td><td>{clientInfoValue["po-destination-flag"]?.["id"]}&nbsp;	({clientInfoValue["po-destination-flag"]["description"]})</td> </tr>
                      <tr><td>Alternate Shipping Address : </td> <td>
                        {clientInfoValue?.["alternate-shipping-address"]["name"]}<br></br>
                        {clientInfoValue?.["alternate-shipping-address"]["line-1"]}<br></br>
                        {clientInfoValue?.["alternate-shipping-address"]["line-2"] ? `${clientInfoValue?.["alternate-shipping-address"]["line-2"]}<br><br>` : ''}
                        {clientInfoValue?.["alternate-shipping-address"]["city"]},&nbsp;
                        {clientInfoValue?.["alternate-shipping-address"]["state"]},&nbsp;
                        {clientInfoValue?.["alternate-shipping-address"]["zip"]}
                      </td> </tr>
                      <tr><td>Zero Image Override:</td><td>{clientInfoValue["zero-override"]} </td> </tr>
                      <tr><td>Register Format:</td><td>N/A </td> </tr>
                      <tr><td>Release Cutoff:</td><td>{clientInfoValue["release-cutoff"]}</td> </tr>
                      <tr><td>Address Service Endorsement:</td><td> {clientInfoValue["address-service-endorsement"]?.["description"]} 3 - Forward Service Requested </td> </tr>
                      <tr><td>Eligible for File Receipt:</td><td>{clientInfoValue["file-receipt"]}</td> </tr>
                      <tr><td>Eligible for File Release:</td><td>{clientInfoValue["release"]}</td> </tr>
                      <tr><td>Commingle with Other Clients:</td><td> {clientInfoValue["comingle"]}</td> </tr>
                      <tr><td>Non-commingled Groups:</td><td> {clientInfoValue["grpNonComingle"] ? 'Yes' : 'No'}</td> </tr>
                      {nonCommingleGroups && nonCommingleGroups.length > 0 && <tr>
                        <td colSpan="2">
                          <Accordion dataTestId="test-accordion-noncomminglegroup" >
                            {
                              <CollapsePanel domID="accordion-section-noncommingle">
                                <CollapsePanelTitle domID="accordian-section-noncommingle-title">
                                  Non-Commingle Groups
                                </CollapsePanelTitle>
                                <CollapsePanelDetails>
                                  <Grid
                                    columns={new Set([
                                      {
                                        dataName: 'consolidationCode',
                                        text: 'CONSOLIDATION CODE',
                                        cellType: 'text',
                                      },
                                      {
                                        dataName: 'grpId',
                                        text: 'GROUP ID',
                                        cellType: 'text',
                                      },
                                      {
                                        dataName: 'grpDescription',
                                        text: 'GROUP DESCRIPTION',
                                        cellType: 'text',
                                      }])}
                                    dataTestId="test-clientInfoReport-NonCommingle"
                                    records={nonCommingleGroups || []}
                                  />
                                </CollapsePanelDetails>
                              </CollapsePanel>
                            }
                          </Accordion>
                        </td>
                      </tr>}
                      <tr><td>USPS Confirm Service Participation:</td><td> {clientInfoValue["uspscs"]}</td> </tr>
                      <tr><td>Address Change Service (ACS):</td><td>{clientInfoValue["acs"]}</td> </tr>
                      <tr><td>Terrorist Watch Participation:</td><td>{clientInfoValue["tw"]}</td> </tr>
                      <tr><td>HPUSA Participation:</td><td> {clientInfoValue["cpc"]}</td></tr>
                      <tr><td>Emdeon's % of HPUSA Savings:</td><td> {clientInfoValue?.["ClientBillingDetails"]?.[0]?.["cpc_maximum_percentage"] || '%'}  </td> </tr>
                      <tr><td>HPUSA Rate:</td><td>   {clientInfoValue?.["ClientBillingDetails"]?.[0]?.["cpc_50_percent"] == 1 ? "% of HPUSA Savings" : "Lesser of CPC Processing and % of HPUSA Savings"

                      }</td> </tr>
                      <tr><td>Groups Excluded from HPUSA:</td><td> {clientInfoValue["excluded"]} </td> </tr>



                    </table>
                    <br></br><br></br><br></br>
                    <div className="cIAccordion">
                      <Accordion dataTestId="test-accordion" >
                        {
                          clientInfoValue["excluded"] == "Yes" && excludedGroupData.length > 0 ?
                            (<CollapsePanel domID="accordion-section-1">
                              <CollapsePanelTitle domID="accordian-section-1-title">
                                Excluded Groups
                              </CollapsePanelTitle>
                              <CollapsePanelDetails>
                                <Grid
                                  columns={excludedGroupColumns}
                                  dataTestId="test-userReport"
                                  records={excludedGroupData || []}
                                />
                              </CollapsePanelDetails>
                            </CollapsePanel>) : (null)

                        }

                        {
                          emailRcipientsData.length > 0 ? (<CollapsePanel domID="accordion-section-1">
                            <CollapsePanelTitle domID="accordian-section-1-title">
                              Email Recipients
                            </CollapsePanelTitle>
                            <CollapsePanelDetails>
                              <Grid
                                columns={emailRcipientsColumns}
                                dataTestId="test-userReport"
                                records={emailRcipientsData || []}
                              />
                            </CollapsePanelDetails>
                          </CollapsePanel>) : (null)
                        }
                        {
                          pricingData && pricingData.length > 0 &&
                          ( <CollapsePanel domID="accordion-section-2">
                            <CollapsePanelTitle domID="accordian-section-2-title">
                              Pricing for the EOB Check Printing Product
                            </CollapsePanelTitle>
                            <CollapsePanelDetails>
                              <div style={{ width: '100%', overflow: 'scroll' }}>
                                <table class="feature-table report-grid" id="PricingTable" border="0">
                                  <tbody>
                                    <tr align="left">
                                      <th colspan="2">
                                        &nbsp;
                                  </th>
                                      {pricingData && pricingData[0] && pricingData[0].tier1price && <th style={{ textAlign: 'center' }} colSpan="2">Tier1</th>}
                                      {pricingData && pricingData[0] && pricingData[0].tier2price && <th style={{ textAlign: 'center' }} colSpan="3">Tier2</th>}
                                      {pricingData && pricingData[0] && pricingData[0].tier3price && <th style={{ textAlign: 'center' }} colSpan="3">Tier3</th>}
                                      {pricingData && pricingData[0] && pricingData[0].tier4price && <th style={{ textAlign: 'center' }} colSpan="3">Tier4</th>}
                                      {pricingData && pricingData[0] && pricingData[0].tier5price && <th style={{ textAlign: 'center' }} colSpan="4">Tier5</th>}
                                    </tr>
                                    <tr align="right">
                                      {
                                        pricingCols.map(col => {
                                          return (
                                            <th style={{ width: col.width }}>{col.text}</th>
                                          )
                                        })
                                      }
                                    </tr>
                                    {
                                      pricingData.map(pricingD => {
                                        return (
                                          <tr>
                                            {
                                              pricingCols.map(col => {
                                                return (
                                                  <td>
                                                    {pricingD[col.dataName]}
                                                  </td>
                                                )
                                              })
                                            }
                                          </tr>
                                        )
                                      })
                                    }
                                    <tr>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </CollapsePanelDetails>
                          </CollapsePanel> )
                        }
                      </Accordion>
                    </div>
                  </div>

                )
            }
          </div>
        ) : (<div>
          {
            isAllReportLoading ? (
              <LoadingPage height='40px'></LoadingPage>
            ) : (
                null
              )
          }
        </div>)
      }
      </div>
    </>
  )
}

export default withAxiosCancellation(ClientInformationReport);