
const initialState = {
  recipientTypeTranslationRecord:null,
  recipientTypeTranslationMode:'',
  refetchRecipientTypes:()=>false,

  addressExclusionSelectedRecord:null,
  addressExclusionMode:'',
  refetchAddressExclusionList:()=>false,

  clientContactEditorRecord:null,
  clientContactEditorMode:'',
  refetchClientContactEditorRecord:()=>false
}

export default (state = initialState, { type, payload }) => {
  switch(type) {
    case 'SET_RECIPIENT_TYPE_TRANSLATION_RECORD':
      return {
        ...state,
        recipientTypeTranslationRecord:JSON.parse(JSON.stringify(payload.recipientTypeTranslationRecord)),
        recipientTypeTranslationMode:payload.mode,
        refetchRecipientTypes:payload.refetchRecipientTypes
      };
    case 'RESET_RECIPIENT_TYPE_TRANSLATION_RECORD':
      return {
        ...state,
        recipientTypeTranslationRecord:null,
        recipientTypeTranslationMode:'',
        refetchRecipientTypes:()=>false
      };

    case 'SET_ADDRESS_EXCLUSION_SELECTED_RECORD':
      return {
        ...state,
        addressExclusionSelectedRecord:JSON.parse(JSON.stringify(payload.addressExclusionSelectedRecord)),
        addressExclusionMode:payload.mode,
        refetchAddressExclusionList:payload.refetchAddressExclusionList
      }
    case 'RESET_ADDRESS_EXCLUSION_SELECTED_RECORD':
      return {
        ...state,
        addressExclusionSelectedRecord:null,
        addressExclusionMode:'',
        refetchAddressExclusionList:()=>false
      }
    case 'SET_CLIENT_CONTACT_EDITOR_RECORD':{
      return {
        ...state,
        clientContactEditorRecord:JSON.parse(JSON.stringify(payload.clientContactEditorRecord)),
        clientContactEditorMode:payload.mode,
        refetchClientContactEditorRecord:payload.refetchClientContactEditorRecord
      }
    }
    case 'RESET_CLIENT_CONTACT_EDITOR_RECORD':{
      return {
        ...state,
        clientContactEditorRecord:null,
        clientContactEditorMode:'',
        refetchClientContactEditorRecord:()=>false
      }
    }
    case 'SET_PAF_EDITOR_RECORD':{
      return {
        ...state,
        PAFEditorRecord: JSON.parse(JSON.stringify(payload.PAFEditorRecord)),
        PAFEditorMode: payload.mode,
        refetchPAFEditorRecord: payload.refetchPAFEditorRecord
      }
    }
    case 'RESET_PAF_EDITOR_RECORD':{
      return {
        ...state,
        PAFEditorRecord:null,
        PAFEditorMode:'',
        refetchPAFEditorRecord:()=>false
      }
    }
    case 'SET_TERRORIST_EXCLUSION_SELECTED_RECORD':{
      return {
        ...state,
        terroristExclusionSelectedRecord:JSON.parse(JSON.stringify(payload.terroristExclusionSelectedRecord)),
        terroristExclusionEditorMode:payload.mode,
        refetchterroristExclusionRecord:payload.refetchterroristExclusionRecord
      }
    }
    case 'RESET_TERRORIST_CONTACT_EDITOR_RECORD':{
      return {
        ...state,
        terroristExclusionSelectedRecord:null,
        terroristExclusionEditorMode:'',
        refetchterroristExclusionRecord:()=>false
      }
    }
    default:
      return state;
  }
}