/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import styled from 'styled-components/dist/styled-components';
import { useDispatch } from 'react-redux';
import {
  Grid,
  useLayout,
  Button
} from 'ui-core/dist'
import { LoadingPage } from '../../components';
import ErrorPage from '../ErrorPage';
import ListPaneHeaderWrapper from '../../components/ErrorDisplaySection/ListPaneHeaderWrapper';
import GridPaginationWrapper from '../../components/GridPaginationWrapper';
import { EEOB_FORM_MANAGER_GRID } from './Modules/constants';
import useSortableGrid from '../../hooks/useSortableGrid';
import { setEEOBFormManagerRecord, resetEEOBFormManagerRecord } from './Modules/action';
import { useGetAvailableEEOBForms } from '../../api/useEEOBFormManager';

const StyledGridWrapper = styled.div`
&.GridWrapper #grid-wrapper-eeobFormManagerGrid{
  overflow:initial !important;
 }
`
const StyledGridWrapper1 = styled.div`
&.GridWrapper #grid-wrapper-eeobFormManagerGrid{
  overflow:initial !important;
  & table#eeobFormManagerGrid thead tr th:first-child {
    display: none;
  }
}
`
const EEOBFormManagerMain = () => {
  const {
    expandCollapseListPaneButtonConfig,
    openDetailPane,
    closeDetailPane
  } = useLayout()
  const dispatch = useDispatch()
  const DEFAULT_ROWS_PER_PAGE = 10;

  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE)
  const [pageIndex, setPageIndex] = useState(0);
  const [count, setcount] = useState(0);
  const [selectedRecord,setSelectedRecord]=useState('')

  const refetchEEOBFormManager = () => {
    setRowsPerPage(DEFAULT_ROWS_PER_PAGE)
    setPageIndex(0)
    postData(JSON.parse(JSON.stringify({
      "maxRecords": DEFAULT_ROWS_PER_PAGE,
      "rowPosition": 0,
      "sortField": sortConfig.sortDataName,
      "sortDescending": sortConfig.sortDescending,
      count
    })))
    setcount(count + 1)
  }

  useEffect(() => {
    if (postData) {
      closeDetailPane();
      setSelectedRecord({})
      dispatch(resetEEOBFormManagerRecord());
      refetchEEOBFormManager()
    }
  }, [])

  const fetchData = useCallback((args) => {
    let sortcolumn; let sd; let pi; let type;
    if (args) {
      ({ sortcolumn, sd, pi, type } = args);
    }
    closeDetailPane();
    dispatch(resetEEOBFormManagerRecord());
    postData(JSON.parse(JSON.stringify({
      "maxRecords": rowsPerPage,
      "rowPosition": pi === undefined || pi === null ? pageIndex : pi,
      "sortField": sortcolumn === undefined || sortcolumn === null ? sortConfig.sortDataName : sortcolumn,
      "sortDescending": sd === undefined || sd === null ? sortConfig.sortDescending : sd,
      type,
      count
    })))
    setcount(count + 1)

  }, [rowsPerPage, pageIndex, sortConfig]);
  const [sortConfig, getArgumentsAndSortGrid] = useSortableGrid(fetchData, "description", "true");
  const { totalRecords, records, postData, isLoading, isError, error } = useGetAvailableEEOBForms()

  const onShowMoreClick = useCallback((record) => {
    dispatch(setEEOBFormManagerRecord({ eeobFormManagerRecord: JSON.parse(JSON.stringify(record)), mode: 'Edit', refetchEEOBFormManager }));
    setSelectedRecord(record)
    openDetailPane();
  }, [records]);

  const onAddEEOBFormManager = () => {
    setSelectedRecord({})
    dispatch(setEEOBFormManagerRecord({ eeobFormManagerRecord: JSON.parse(JSON.stringify({})), mode: 'Add', refetchEEOBFormManager }));
    openDetailPane();
  }
  const renderMainGrid = useMemo(() => {

    if (records) {
      const data = JSON.parse(JSON.stringify(records));
      return (
        <>
          <div style={{ marginLeft: '22px' }}>
            <GridPaginationWrapper
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={setRowsPerPage}
              pageIndex={pageIndex}
              onChangePageIndex={setPageIndex}
              onShowButtonCLick={fetchData}
              totalRecords={totalRecords}
              defaultRowsPerPage={DEFAULT_ROWS_PER_PAGE}
            />
          </div>
          {data.length === 0 ? (
            <StyledGridWrapper1 className='GridWrapper'>
              <Grid
                {...EEOB_FORM_MANAGER_GRID}
                records={data || []}
                selectionKey="id"
                onClickThrough={(e, rec) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (rec) {
                    const record = JSON.parse(JSON.stringify(rec))
                    if (onShowMoreClick) {
                      dispatch(resetEEOBFormManagerRecord())
                      return onShowMoreClick(record);
                    }
                    return false;
                  }
                  return false;
                }}
                initialSortingKey={`${sortConfig.sortDataName}|${sortConfig.sortDescending === "true" ? 'SORT_DESCENDING' : 'SORT_ASCENDING'}`}
              />
            </StyledGridWrapper1>
          ) : (
              <StyledGridWrapper className='GridWrapper'>
                <Grid
                  {...EEOB_FORM_MANAGER_GRID}
                  records={data || []}
                  selectionKey="id"
                  onClickThrough={(e, rec) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (rec) {
                      const record = JSON.parse(JSON.stringify(rec))
                      if (onShowMoreClick) {
                        dispatch(resetEEOBFormManagerRecord())
                        return onShowMoreClick(record);
                      }
                      return false;
                    }
                    return false;
                  }}
                  focusedItem={selectedRecord}
                  initialSortingKey={`${sortConfig.sortDataName}|${sortConfig.sortDescending === "true" ? 'SORT_DESCENDING' : 'SORT_ASCENDING'}`}
                  onSortGridColumn={(e, state) => getArgumentsAndSortGrid(state)}
                />
              </StyledGridWrapper>
            )}
        </>
      )
    }
    return null;
  }, [records, rowsPerPage, pageIndex, selectedRecord]);

  return (
    <>
      <ListPaneHeaderWrapper
        title="EEOB Form Manager"
        layoutControls={[expandCollapseListPaneButtonConfig]}
      />
       <div style={{ marginLeft: '20px' }} className="marginLeft">
                <div style={{ marginTop: '20px' }} className="displayFlex">
                  <Button
                    id='EEOBFormManagerAdd'
                    size="small"
                    name="Add EEOB Form Manager"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onAddEEOBFormManager()
                    }}
                  />
                </div>
              </div>
      {isLoading ? (
        // replace with a better loading component
        <LoadingPage />
      ) : isError ? (
        // replace with a better error component
        <ErrorPage
          // this prop isn't real but is an example
          error={error}
        />
      ) : (
            <>
              {renderMainGrid}
            </>
          )}
    </>
  )
}

export default EEOBFormManagerMain
