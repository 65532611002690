import {
  SET_LOADING_STATE,
  SET_LOADING_STATE_FOR_DEACTIVATE_GROUP_MODAL,
  CLEAR_LOADING_STATE
} from './constants';
export const INITIAL_STATE = {
  loading: new Map(),
  loadingForDeactivateGroupModal: new Map(),
  lastUpdate: 0,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LOADING_STATE:
      action.payload.isLoading
        ? state.loading.set(action.payload.id, true)
        : state.loading.delete(action.payload.id);
      return {
        ...state,
        lastUpdate: Date.now(),
      };
    case SET_LOADING_STATE_FOR_DEACTIVATE_GROUP_MODAL:
      action.payload.isLoading
        ? state.loadingForDeactivateGroupModal.set(action.payload.id, true)
        : state.loadingForDeactivateGroupModal.delete(action.payload.id);
      return {
        ...state,
        lastUpdate: Date.now(),
      };
    case CLEAR_LOADING_STATE:
      return {
        loading: new Map(),
        loadingForDeactivateGroupModal: new Map(),
        lastUpdate: 0,
      };
    default:
      return state;
  }
};
