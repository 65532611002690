import React, { useState, useEffect, useCallback, useMemo } from 'react'
import useLayout from 'ui-core/dist/useLayout'
import ListPaneHeader from 'ui-core/dist/ListPaneHeader'
import ButtonGroup from 'ui-core/dist/ButtonGroup'
import Button from 'ui-core/dist/Button'
import { useSelector, useDispatch } from 'react-redux'
import { Grid, LoadingIndicator } from 'ui-core/dist'
import useTestFiles from '../../api/useTestFiles'
import Authorizer from '../Authorizer'
import GridPaginationWrapper from '../../components/GridPaginationWrapper'
import { AnchorInput } from '../../components'
import ErrorPage from '../ErrorPage';
import axios from '../../axios'
import ColorCell from '../../components/ColorCell'
import './modules/styles.scss'
import { setIsUploadDetail, getTestFileListRefetch } from './modules/actions'
import Loading from '../../components/LoadingComponent'
import useNotificationBannerHook from '../../useNotificationBannerHook';
import useSortableGrid from '../../hooks/useSortableGrid';
import withAxiosCancellation from '../../components/withAxiosCancellation'

const TestFileManagerListPane = ({getCancelSourceWithName}) => {
  const dispatch = useDispatch()
  const { notify } = useNotificationBannerHook()
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [sortField, setSortField] = useState('dateSubmitted')
  const [sortDescending, setSortDecending] = useState(true)
  const [selected, setSelected] = useState([])
  const [count, setcount] = useState(0)
  const [resubmitLoading, setResubmitLoading] = useState(false)
  
  const canPerform = useSelector(({ context }) =>
    context?.permissions?.filter(p => p.name === 'CAN_PERFORM_TESTFILE_NEW_JOB').length > 0)
  const canView = useSelector(({ context }) =>
    context?.permissions?.filter(p => p.name === 'CAN_PERFORM_TESTFILE_VIEW_JOB').length > 0)
  const {
    expandCollapseListPaneButtonConfig,
    openDetailPane,
    closeDetailPane
  } = useLayout()
  const { data, isLoading, total, isError, error, getAllTestFiles } = useTestFiles()
  const userType = useSelector(store => store.session.type)
  const GRID_COLUMN = new Set([
    {
      dataName: 'testFileIdProp',
      text: 'TEST FILE ID',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'dateSubmitted',
      text: 'DATE SUBMITTED',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'dateCompleted',
      text: 'DATE COMPLETED',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'submittedBy',
      text: 'SUBMITTER',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'originalFileName',
      text: 'ORIG. NAME',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'status',
      text: 'STATUS',
      cellType: 'custom',
      customComponent: ColorCell
    },
    {
      dataName: 'bhide',
      text: 'ACCESS',
      cellType: 'text',
    },
  ])
  const [sortConfig, getArgumentsAndSortGrid]=useSortableGrid(fetchData,"dateSubmitted","true");
  useEffect(()=>{
    getAllTestFiles({
      maxRecords: 10,
      rowPosition: 0,
      sortField:'dateSubmitted',
      sortDescending:true,
      count: 0
    })
  },[])

  useEffect(() => {
    if (isLoading) {
      setSelected([])
    }
  }, [isLoading])

  const onRowSelect = (e, state) => {
    const ids = Array.from(state.selectedItemIds);
    setSelected(ids);
  }

  const onSelectAll = (e, state) => {
    const ids = Array.from(state.selectedItemIds);
    setSelected(ids);
  }

  const onSortGridColumn = useCallback( (e, { sortingKey }) => {
    const splitKey = sortingKey.split('|')
    if (splitKey[0] === 'testFileIdProp') setSortField('testFileId')
    else setSortField(splitKey[0])
    if (splitKey[1] === 'SORT_DESCENDING') setSortDecending(true)
    else setSortDecending(false)
    setcount(count + 1)
    getAllTestFiles({
      maxRecords: rowsPerPage,
      rowPosition: pageIndex,
      sortField: splitKey[0] === 'testFileIdProp' ? 'testFileId' : splitKey[0],
      sortDescending: splitKey[1] === 'SORT_DESCENDING',
      count
    })
  },[count])
  
  const refreshMe = useCallback(() => {
    setcount(count + 1)
    getAllTestFiles({
      maxRecords: rowsPerPage,
      rowPosition: pageIndex,
      sortField,
      sortDescending,
      count:count+1
    })
    
   
  }, [count, sortField, sortDescending,rowsPerPage, pageIndex])

  const onSubmitTestFile = () => {
    dispatch(setIsUploadDetail(true))
    dispatch(getTestFileListRefetch({fetchFunc:refreshMe}))
    openDetailPane()
  }
  const openDetailsPage = (testFileId) => {
      dispatch(setIsUploadDetail(false))
      dispatch(getTestFileListRefetch({fetchFunc:refreshMe}))
      openDetailPane({
        id: testFileId
      })
  }
  const fetchData = useCallback((args) => {
    let sortcolumn; let sd; let pi;
    if (args) {
      ({sortcolumn,sd,pi}=args);
    }
    
    setcount(count + 1)
    getAllTestFiles({
      maxRecords: rowsPerPage,
      rowPosition: pi===undefined || pi===null?pageIndex: pi,
      sortField:sortcolumn===undefined || sortcolumn===null?sortConfig.sortDataName: sortcolumn,
      sortDescending: sd===undefined || sd===null?sortConfig.sortDescending: sd,
      count: count+1
    })
  }, [pageIndex, rowsPerPage, sortDescending, sortField,sortConfig])

  const handleResubmit = async () => {
    try {
      setResubmitLoading(true)
      const res = await axios.post(`/testFiles/resubmit/${selected[0]}`)
      refreshMe()
      setResubmitLoading(false)
      notify(`You have successfully resubmitted a test file ID # "${res?.data?.resp}".`, 'positive')
    } catch (err) {
      setResubmitLoading(false)
      notify('Failed to resubmit the file.', 'negative')
    }
    closeDetailPane()
  }
const loadPage = useMemo(()=>{
return (
  isLoading ? <Loading /> : isError  ? (
    // replace with a better error component
      <ErrorPage
        // this prop isn't real but is an example
        error={error }
      />
        ) : 
        <>
        <div style={{ marginLeft: '30px' }}>
        <GridPaginationWrapper
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={setRowsPerPage}
          pageIndex={pageIndex}
          onChangePageIndex={setPageIndex}
          onShowButtonCLick={fetchData}
          totalRecords={total}
          defaultRowsPerPage={10}
        />
      </div>
      
        <Grid
          columns={GRID_COLUMN}
          records={data?.map(record => {
            if ((
              canView
              && userType === "I"
              && !(['Queued', 'In Progress'].includes(record.status))
            ) || (
                canView
                && !(['Queued', 'In Progress'].includes(record.status))
                && (userType === "E" && record.bhide === "0")
              )) {
              return {
                ...record,
                testFileIdProp: (
                  <AnchorInput
                    id='fileKey'
                    type='button'
                    className='fileKeyLink'
                    onClick={()=>openDetailsPage(record.testFileId)}
                  >
                    {record.testFileId}
                  </AnchorInput>
                ),
                bhide: record.bhide === "0" ? 'All' : 'Internal Only',
                status: { status: record.status }
              }
            }
            return ({
              ...record,
              testFileIdProp: <AnchorInput disabled style={{ textDecoration: 'none', cursor: 'default' }}>{record.testFileId}</AnchorInput>,
              bhide: record.bhide === "0" ? 'All' : 'Internal Only',
              status: { status: record.status }
            })
          }) || []}
          dataTestId="test-TestFileGrid"
          domID="TestFileGrid"
          selectionKey="testFileId"
          selectAllCheckboxDomID="TestFileGridSelectAll"
          onRowSelect={onRowSelect}
          onSelectAll={onSelectAll}
           onSortGridColumn={onSortGridColumn}
          supportSelection={canPerform}
          // initialSortingKey={`${sortConfig.sortDataName}|${sortConfig.sortDescending==="true"?'SORT_DESCENDING':'SORT_ASCENDING'}`}
           // onSortGridColumn={(e,state)=>getArgumentsAndSortGrid(state)}
          initialSortingKey={`${sortField === 'testFileId' ? 'testFileIdProp' : sortField}|${sortDescending ? 'SORT_DESCENDING' : 'SORT_ASCENDING'}`}
          sortingComparator={(sortDataName, sortDirectionString, recordsToSort) => recordsToSort}
        />
        </>
)
},[data,total,sortField,sortDescending,isLoading, rowsPerPage, pageIndex, selected, resubmitLoading])

  // const sortingComparator = (sortDataName, sortDirectionString, recordsToSort) => recordsToSort

  return (
    <>
      <ListPaneHeader
        title="Test File Manager"
        layoutControls={[expandCollapseListPaneButtonConfig]}
      />
      <Authorizer permission="CAN_PERFORM_TESTFILE_NEW_JOB" >
        <ButtonGroup className="test-file__button-group">
          <Button
            domID="SubmitTestFileButton"
            className="test-file__button"
            buttonType="standard"
            size="small"
            name="Submit Test File"
            onClick={onSubmitTestFile}
          />
          <Button
            domID="ResubmitTestFilesButton"
            className="test-file__button"
            buttonType="standard"
            size="small"
            name="Resubmit Selected"
            onClick={handleResubmit}
            disabled={(
              resubmitLoading
             || (userType === 'E' && data?.filter(d => d.testFileId === selected[0])[0]?.bhide === '1')
              || (['Queued', 'In Progress'].includes(data?.filter(i => i.testFileId === selected[0])[0]?.status))
              || !(selected.length === 1)
            )} 
          />
          {resubmitLoading? <LoadingIndicator/> : null}
        </ButtonGroup>
      </Authorizer>
      {
            loadPage
      }
    </>
  )
}

export default withAxiosCancellation(TestFileManagerListPane)