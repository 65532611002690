// import { useState, useEffect } from 'react'
import { useAxios } from '../hooks'

const useFileTrackerFiles = ({ fileKey, payload }) => {
  // const [ adjPayload, setAdjPayload ] = useState(payload)
  // const [ options, setOptions ] = useState({})

  // useEffect(() => { 
  //   if(data) {
  //     setAdjPayload({ ...payload, includeCatalogs: false })
  //     const { data: records, ...rest } = data
  //     setOptions({...rest})
  //   }
  //   else setAdjPayload({ ...payload, includeCatalogs: true })
  // }, [data])

  const { data, ...rest } = useAxios({
    url: `/fileTracker/${fileKey}/documents`,
    method: 'POST',
    payload: { ...payload, includeCatalogs: payload?.firstTime ? true : false },
    negativeMessage: true
  })

  

  return { 
    data,
    total: data?.totalRecords || undefined, 
    cacheName: data?.cacheName,
    ...rest
  }
}

export default useFileTrackerFiles