import React from 'react';
import PropTypes from 'prop-types';
import { SelectDropdown } from 'ui-core-ssgr';
import { DropDown } from '../styles';

const DropDownControl = ({
  dropDownDomID,
  dropDownLabel,
  dropDownName,
  dropDownOptions,
  dropDownInitialValue,
  dropDownStartWithBlankValue,
  dropDownOnChangeHandle,
  dropDownStyles,
  dropDownErrorMessage,
  dropDownHasError,
  dropDownDisabled
}) => (
  
  <DropDown style={dropDownStyles}>
    <SelectDropdown
      domID={dropDownDomID}
      label={dropDownLabel}
      name={dropDownName}
      options={dropDownOptions || []}
      initialValue={dropDownInitialValue}
      startWithBlankValue={dropDownStartWithBlankValue}
      onChange={e => dropDownOnChangeHandle(e)}
      errorMessage={dropDownErrorMessage}
      hasError={dropDownHasError}
      disabled={dropDownDisabled}
    />
  </DropDown>
);

DropDownControl.propTypes = {
  dropDownDomID: PropTypes.string,
  dropDownLabel: PropTypes.string,
  dropDownName: PropTypes.string,
  dropDownOptions: PropTypes.array,
  dropDownInitialValue: PropTypes.string,
  dropDownStartWithBlankValue: PropTypes.bool,
  dropDownOnChangeHandle: PropTypes.func,
  dropDownStyles: PropTypes.object,
  dropDownErrorMessage: PropTypes.string,
  dropDownHasError: PropTypes.bool,
  dropDownDisabled: PropTypes.bool,
};
export default DropDownControl;
