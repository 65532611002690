export const selectProductionArrangementModalState = state =>
  state.ruleDetails.isShowingProductionArrangementModal;

export const selectCustomerServiceMessageModalState = state =>
  state.ruleDetails.isShowingCustomerServiceMessageModal;

export const selectReturnAddressModalState = state =>
  state.ruleDetails.isShowingReturnAddressModal;

export const selectDuplexRuleModalState = state =>
  state.ruleDetails.isShowingDuplexRuleModal;

export const selectSignatureRuleModalState = state =>
  state.ruleDetails.isShowingSignatureRuleModal;

export const selectCheckTextRuleModalState = state =>
  state.ruleDetails.isShowingCheckTextRuleModal;

export const selectImageRuleModalState = state =>
  state.ruleDetails.isShowingImageRuleModal;

export const selectAutoHoldModalState = state =>
  state.ruleDetails.isShowingAutoHoldModal;

export const selectMemberIdModalState = state =>
  state.ruleDetails.isShowingMemberIdModal;

export const selectTaxIdArrangementState = state =>
  state.ruleDetails.isShowingTaxIdArrangementRuleModal;

export const selectErisaMessageModalState = state =>
  state.ruleDetails.isShowingErisaMessageModal;

export const selectHighDollarReviewModalState = state =>
  state.ruleDetails.isShowingHighDollarReviewModal;

export const selectProviderInsuredOptionMessageModalState = state =>
  state.ruleDetails.isShowingProviderInsuredOptionMessageModal;

export const selectGeneralMessageModalState = state =>
  state.ruleDetails.isShowingGeneralMessageModal;

export const selectPDFTaggingModalState = state =>
  state.ruleDetails.isShowingPDFTaggingModal;

export const selectLoadingState = state => state.loadingState.loading;

export const selectNotificationState = state =>
  state.notificationState.notifications;

export const selectNotificationStateErrors = state =>
  state.ruleDetails.errorNotifications;

export const selectCurrentDetailsRecord = state => {
  const { selectedRuleRecordId } = state.rulesForRuleTypesList;
  const finalResp =
    selectedRuleRecordId &&
    state.rulesForRuleTypesList.records.find(
      element => element.id === selectedRuleRecordId,
    );
  return finalResp;
};

export const selectCurrentDetailsRecordStatus = state =>
  selectCurrentDetailsRecord(state) && selectCurrentDetailsRecord(state).status;

export const selectIsAdvancedViewShown = state =>
  state.rulesForRuleTypesList.isAdvancedViewShown;

export const selectCurrentTab = state => state.rulesForRuleTypesList.currentTab;

export const selectIsCloningRuleDetail = state =>
  state.ruleDetails.isCloningRuleDetail;

export const selectIsEditingRuleDetail = state =>
  state.ruleDetails.isEditingRuleDetail;

export const selectIsReadOnlyRuleDetail = state =>
  state.ruleDetails.isReadOnlyRuleDetail;

export const selectIsPostingRuleDetail = state =>
  state.ruleDetails.isPostingRuleDetail;

export const selectIsPostingRulesForRulesTypes = state =>
  state.rulesForRuleTypes.isPostingRulesForRuleTypes;

export const selectRuleCloningState = state =>
  state.rulesForRuleTypes.isCloningRule;

export const selectAssociatedGroupRecordsNoTermedRecords = state => state.rulesForRuleTypesList &&
    state.rulesForRuleTypesList.groupAssociationsForRuleId
  ? state.rulesForRuleTypesList.groupAssociationsForRuleId.filter(record => record.groupStatus !== 'termed')
  : [];

export const selectReturnAddressElements = state => state.ruleDetails.returnAddressElements;
export const selectRuleImages = state => state.ruleDetails.ruleImages;
export const selectRuleImagesCount = state => state.ruleDetails.ruleImagesCount;
export const selectConvertedImageUploadData = state => state.ruleDetails.convertedImageUploadData;
export const selectConvertedImageUploadError = state => state.ruleDetails.convertedImageUploadError;
export const selectPostImageUploadSuccessId = state => state.ruleDetails.postImageUploadSuccessId;
export const selectIsShowingCommitImageModal = state => state.ruleDetails.isShowingCommitImageModal;
export const selectPostImageCommitSuccessId = state => state.ruleDetails.postImageCommitSuccessId;
export const selectUserRole = (state) => state.serverConfig.userRole;
export const selectUserPermissions = (state) => state.serverConfig.userPermissions;
export const selectIsShowingRevertRuleModal = state => state.rulesForRuleTypes.isShowingRevertRuleModal;
export const selectRevertRuleVersionsList = state => state.rulesForRuleTypes.revertRuleVersionsList;
export const selectUserType = (state) => state.serverConfig.userType;
export const selectUserRoleId = (state) => state.serverConfig.userRoleId;