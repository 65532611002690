
export const setErrorIds = payload => ({
  type: "SET_ERROR_IDS",
  payload
})
export const resetErrorIds = payload => ({
  type: "RESET_ERROR_IDS",
  payload
})

export const setResolutionModal=payload=>({
  type:'SET_RESOLUTION_MODAL',
  payload
})
export const setIsAll=payload=>({
  type:'SET_IS_ALL',
  payload
})
export const setDetailsId=payload=>({
  type:'SET_DETAILS_ID',
  payload
})
export const setCuid=payload=>({
  type:'SET_DETAILS_CUID',
  payload
})

export const setNotesModal=payload=>({
  type:'SET_NOTES_MODAL',
  payload
})

export const setDetailsInfoModal=payload=>({
  type:'SET_DETAILS_MODAL',
  payload
})

export const getErrorListRefetch=(payload)=>({
  type:'SET_ERROR_LIST_REFETCH',
  payload
})
