import React from 'react';
import PropTypes from 'prop-types';
import { Button, EntityTitle } from 'ui-core-ssgr';
import ImageControl from '../ImageControl';
import {ImageNotFound200x100} from './constants'

const UploadAndViewImageControl = ({
  uniqueId,
  headerText,
  libraryButtonText,
  uploadButtonText,
  onClickHandleLibrary,
  onClickHandleUpload,
  imageKey,
  imageSrc,
  imageAltLabel,
  onClickHandleRemove,
  onClickHandleCommit,
  isTested
}) => {
  const onClickHandle = (e, action, callback) => {
    callback(e, action, uniqueId);
  };

  const  getImageSrc = () => {
    if (imageSrc) {
      return imageSrc; 
    } 
    return ImageNotFound200x100;
  }

  const isTestedCustom = isTested === undefined ? false : isTested;

  return (
    <div className="upload-library-cntrl">
      <EntityTitle removeUnderline title={headerText} description="" />
      <Button
        domID="library-btn"
        name={libraryButtonText}
        buttonType="diminished"
        size="medium"
        onClick={e => onClickHandle(e, 'library', onClickHandleLibrary)}
        isDropdown={false}
        type="button"
      />{' '}
      |
      <Button
        domID="upload-btn"
        name={uploadButtonText}
        buttonType="diminished"
        size="medium"
        onClick={e => onClickHandle(e, 'upload', onClickHandleUpload)}
        isDropdown={false}
        type="button"
      />

      {imageKey && imageKey.trim() ? (
        <div>
          <ImageControl
            imageKey={String(imageKey)}
            imageDomID={String(imageKey)}
            imageClassName="upld-cntrl"
            imageSrc={getImageSrc()}
            imageAltLabel={imageAltLabel}
            imageStyles={null}
            imageOnClickHandle={() => {}}
            imageOnLoad={() => {}}
            imageOnError={() => {}}
            imageSelection={false}
          />
          <Button
            domID="remove-btn"
            name="REMOVE"
            buttonType="diminished"
            size="medium"
            onClick={e => onClickHandle(e, 'remove', onClickHandleRemove)}
            isDropdown={false}
            type="button"
          />
          {/* show commit button if image is not tested so that user can make it to production from test */}
          {isTestedCustom === false ? <>| <Button
            domID="cmt-btn"
            name="COMMIT"
            buttonType="diminished"
            size="medium"
            onClick={e => onClickHandle(e, 'commit', onClickHandleCommit)}
            isDropdown={false}
            type="button"
          /></> : null }
        </div>
      ) : (
        <div className="image-library-none">None</div>
      )}
    </div>
  );
};

UploadAndViewImageControl.propTypes = {
  uniqueId: PropTypes.string,
  headerText: PropTypes.string,
  libraryButtonText: PropTypes.string,
  uploadButtonText: PropTypes.string,
  onClickHandleLibrary: PropTypes.func,
  onClickHandleUpload: PropTypes.func,
  onClickHandleRemove: PropTypes.func,
  onClickHandleCommit: PropTypes.func,
  imageKey: PropTypes.string,
  imageSrc: PropTypes.string,
  imageAltLabel: PropTypes.string,
  isTested: PropTypes.bool
};
export default UploadAndViewImageControl;