import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import find from 'lodash/find';
import { Button, LoadingIndicator, SelectDropdown, TextArea } from 'ui-core-ssgr';
import {
  ContainerBox,
  ContainerBoxTextArea,
  DropDownOne,
  DropDownTwo,
  DropDownThree,
  DropSmallMargin,
  LoadingWrapper,
} from './styles';
import { 
  ButtonGroupBottom,
  ModalButton,
  ReadOnlyTextTitle,
  ReadOnlyTextArea
 } from '../../../shared/styles/styledComponents';
import { DivWithPaddingTop } from '../../../shared/styles/spacing';
import { getRuleVersion, validMessage, populateDataDropDown } from '../utils';

const CustomerServiceMessageModal = ({
  isPostingRuleDetail,
  setIsShowingCustomerServiceMessageModal,
  isCloningRuleDetail,
  postRuleDetails,
  putRuleDetails,
  detailElements,
  selectedRecord,
  selectedRuleDetail,
  selectedRuleRecord,
  selectedRuleTypeId,
  loading,
  actionType,
  setIsCloningRuleDetail,
  setIsPostingRuleDetail,
  setIsReadOnlyRuleDetail
}) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [ruleDetail, setRuleDetail] = useState({
    claimType: 'default',
    recipientType: 'default',
    documentType: '01',
    message: '',
  });
  const [messageFormError, setMessageFormError] = useState(false);
  const [messageFormErrorMessage, setMessageFormErrorMessage] = useState('');

  useEffect(() => {
    if (isPostingRuleDetail) {
      setIsButtonDisabled(true);
      setMessageFormError(true);
      setMessageFormErrorMessage('Message required with minimum of 1 character');
    }
    getInitialDropdownValues();
  }, []);

  useEffect(() => {
    const claimTypeCode = populateCodeFromValue('claimType');
    const recipientTypeCode = populateCodeFromValue('recipientType');
    const documentTypeCode = populateCodeFromValue('documentType');
    const originalMessage = get(selectedRuleDetail, 'message', '');

    setButtonDisableOnDefault(claimTypeCode, recipientTypeCode, documentTypeCode, originalMessage); 
  }, [ruleDetail.claimType, ruleDetail.recipientType, ruleDetail.documentType, ruleDetail.message]);

  const populateCodeFromValue = name => {
    if (!isEmpty(detailElements)) {
      const detailElementsClaimTypesList = get(detailElements, 'claimTypesList', []);
      const detailElementsRecipentTypesList = get(detailElements, 'recipientTypesList', []);

      if (name === 'claimType' && detailElementsClaimTypesList) {
        let selectedClaimType = '';
        const claimTypeValue = get(selectedRuleDetail, 'claimType', '');
        const firstValue = find(detailElementsClaimTypesList, x => x.value === claimTypeValue);
        const arr = firstValue
          ? [...new Set([firstValue, ...detailElementsClaimTypesList])]
          : detailElementsClaimTypesList;
        arr.map(r => {
          if (r.value === claimTypeValue) {
            selectedClaimType = r.code;
          }
          return {
            label: r.value,
            value: r.code,
          };
        });
        return selectedClaimType;

      }
      if (
        name === 'recipientType' &&
        detailElementsRecipentTypesList
      ) {
        let selectedRecipientType = '';
        const recipientValue = get(selectedRuleDetail, 'recipientType', '');
        const firstValue = detailElements.recipientTypesList.find(
          x => x.value === recipientValue,
        );
        const arr = firstValue
          ? [...new Set([firstValue, ...detailElements.recipientTypesList])]
          : detailElements.recipientTypesList;
        arr.map(r => {
          if (r.value === recipientValue) {
            selectedRecipientType = r.code;
          }
          return {
            label: r.value,
            value: r.code,
          };
        });
        return selectedRecipientType;
      }
      if (name === 'documentType' && detailElements.documentTypesList) {
        let selectedDocumentType = '';
        const documentTypeValue = get(selectedRuleDetail, 'documentType', '');
        const firstValue = detailElements.documentTypesList.find(x =>
          x.value.includes(documentTypeValue),
        );
        const arr = firstValue
          ? [...new Set([firstValue, ...detailElements.documentTypesList])]
          : detailElements.documentTypesList;
        arr.map(r => {
          if (r.value === documentTypeValue) {
            selectedDocumentType = r.code;
          }
          return {
            label: r.value,
            value: r.code,
          };
        });
        return selectedDocumentType;
      }
    }
  };

  const { claimType, documentType, recipientType, message } = ruleDetail;
  const recordId = get(selectedRuleRecord, 'id', '') || get(selectedRecord, 'id', '');

  const getInitialDropdownValues = () => {
    const defaultMessage = !isPostingRuleDetail ? get(selectedRuleDetail, 'message', '').replace(/\r?\\n|\r/g, '\n') : '';
    let defaultClaimType = 'default';
    let defaultRecipientType = 'default';
    let defaultDocumentType = 'default';
    let readOnlyClaimType = 'default';
    let readOnlyRecipientType = 'default';
    let readOnlyDocumentType = 'default';

    if (!isPostingRuleDetail) {
      /**
       * For this we are doing a check on the claimTypesList, recipientTypesList, and documentTypesList
       * Using the get from lodash to make sure the code does not break the page and return a blank page
       */
      const detailElementsClaimTypesList = get(detailElements, 'claimTypesList', []);
      const detailElementsRecipientTypesList = get(detailElements, 'recipientTypesList', []);
      const detailElementsDocumentTypesList = get(detailElements, 'documentTypesList', []);

      // For here we are getting the claimType, recipientType, and documentType from the selectedRuleDetail to populate the values
      const selectedClaimType = get(selectedRuleDetail, 'claimType', '');
      const selectedRecipientType = get(selectedRuleDetail, 'recipientType', '');
      const selectedDocumentType = get(selectedRuleDetail, 'documentType', '');

      /**
       * Down here for these checks, once again we are using the get method of lodash just in case if something comes back blank
       * We are wrapping the find method inside of a get method for additional checking to make sure that the code doesn't come back blank
       */
      defaultClaimType = get(find(detailElementsClaimTypesList, o => o.value === selectedClaimType), 'code', '');
      readOnlyClaimType = get(find(detailElementsClaimTypesList, o => o.value === selectedClaimType), 'value', '');
      defaultRecipientType = get(find(detailElementsRecipientTypesList, o => o.value === selectedRecipientType), 'code', '');
      readOnlyRecipientType = get(find(detailElementsRecipientTypesList, o => o.value === selectedRecipientType), 'value', '');

      if (
        detailElements.documentTypesList &&
        selectedRuleDetail.documentType !== 'Any'
      ) {
        defaultDocumentType = get(find(detailElementsDocumentTypesList, o => o.value === selectedDocumentType), 'code', '');
        readOnlyDocumentType = get(find(detailElementsDocumentTypesList, o => o.value === selectedDocumentType), 'value', '');
      }

      if (actionType !== 'Read only') {
        setRuleDetail({
          ...ruleDetail,
          claimType: defaultClaimType,
          recipientType: defaultRecipientType,
          documentType: defaultDocumentType,
          message: defaultMessage,
        });
      }
      if (actionType === 'Read only') {
        setRuleDetail({
          ...ruleDetail,
          claimType: readOnlyClaimType,
          recipientType: readOnlyRecipientType,
          documentType: readOnlyDocumentType,
          message: defaultMessage,
        });
      }
    } else {
      setIsButtonDisabled(true);
      setRuleDetail({
        ...ruleDetail,
        claimType: defaultClaimType,
        recipientType: defaultRecipientType,
        documentType: defaultDocumentType,
        message: defaultMessage,
      });
    }
  };

  const setButtonDisableOnDefault = (
    claimTypeCode,
    recipientTypeCode,
    documentTypeCode,
    originalMessage,
  ) => {
    let buttonDisabled = false;

    if (
      actionType === 'Edit a' &&
      claimType === claimTypeCode &&
      recipientType === recipientTypeCode &&
      documentType === documentTypeCode &&
      message === originalMessage
    ) {
      buttonDisabled = true;
    }
    if (
      actionType === 'Clone a' &&
      claimType === claimTypeCode &&
      recipientType === recipientTypeCode &&
      documentType === documentTypeCode
    ) {
      buttonDisabled = true;
    }
    if (
      (actionType === 'Add a' && !claimType === 'default') ||
      !recipientType === 'default' ||
      !documentType === 'default' ||
      !validMessage(message)
    ) {
      buttonDisabled = true;
    }

    setIsButtonDisabled(buttonDisabled);
  }

  const onCancel = () => {
    setIsShowingCustomerServiceMessageModal(false);
    setIsCloningRuleDetail(false);
    setIsPostingRuleDetail(false);
    setIsReadOnlyRuleDetail(false);
  };

  const onSave = () => {
    const record = selectedRecord || selectedRuleRecord;
    const ruleDetailId = get(selectedRuleDetail, 'id', '');
    const ruleId = get(record, 'id', '');
    const ruleVersion = getRuleVersion(record);
    const ruleType = selectedRuleTypeId;
    const checkRuleDetail = isCloningRuleDetail
      ? { ...ruleDetail, ...{ ruleDetailIdFrom: ruleDetailId } }
      : ruleDetail;

    if (ruleType === 'CSM') {
      if (isPostingRuleDetail || isCloningRuleDetail) {
        postRuleDetails({ ruleDetail: checkRuleDetail, ruleType, ruleId, ruleVersion });
      } else {
        putRuleDetails({
          ruleDetail: checkRuleDetail,
          ruleType,
          ruleId,
          ruleVersion,
          ruleDetailId,
        });
      }
    }
  };

  const checkUnsavedChanges = event => {
    if (event.target) {
      const targetName = event.target.name;
      const targetValue = event.target.value;
      let errorMessage = '';

      if (!targetValue.length || (/^\s*$/).test(targetValue)) {
        errorMessage = 'Message required with minimum of 1 character';
      } else if (targetValue.length > 4000) {
        errorMessage = 'Maximum characters allowed is 4000';
      }

      if (targetName === 'message') {
        setRuleDetail({
          ...ruleDetail,
          message: targetValue,
        });
        setMessageFormError(!validMessage(targetValue));
        setMessageFormErrorMessage(errorMessage);
      } else {
        setRuleDetail({
          ...ruleDetail,
          [targetName]: targetValue,
        });
      }
    }
  };

  // FOR READ ONLY CONDITIONAL RENDERING (Live Rule)
  let claimTypeReadOnly;
  let recipientTypeReadOnly;
  let documentTypeReadOnly;
  let messageReadOnly;
  if (actionType === 'Read only') {
    claimTypeReadOnly = (claimType !== 'default') ? claimType : 'Any';
    recipientTypeReadOnly = (recipientType !== 'default') ? recipientType : 'Any';
    documentTypeReadOnly = (documentType !== 'default') ? documentType : 'Any';
    messageReadOnly = (message !== 'default') ? message : '-';
  }


  return (
    <div>
      {actionType !== 'Read only' ? (
        <DivWithPaddingTop></DivWithPaddingTop>
      ) : null}
      <ContainerBox>
        {actionType !== 'Read only' ? (
          <DropDownOne>
            <DropSmallMargin>
              <SelectDropdown
                domID="test-id"
                label="claim type"
                name="claimType"
                options={populateDataDropDown({
                  listName: 'claimTypesList',
                  name: 'claimType',
                  detailElements,
                  selectedRuleTypeId,
                  selectedRuleDetail
                })}
                initialValue={claimType || '1'}
                onChange={checkUnsavedChanges}
                startWithBlankValue={false}
              />
            </DropSmallMargin>
          </DropDownOne>
        ) : <ReadOnlyTextTitle>Claim Type<ReadOnlyTextArea>{claimTypeReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
        {actionType !== 'Read only' ? (
          <DropDownTwo>
            <DropSmallMargin>
              <SelectDropdown
                domID="test-id"
                label="recipient"
                name="recipientType"
                options={populateDataDropDown({
                  listName: 'recipientTypesList',
                  name: 'recipientType',
                  detailElements,
                  selectedRuleTypeId,
                  selectedRuleDetail
                })}
                initialValue={recipientType || '1'}
                onChange={checkUnsavedChanges}
                startWithBlankValue={false}
                s
              />
            </DropSmallMargin>
          </DropDownTwo>
        ) : <ReadOnlyTextTitle>Recipient<ReadOnlyTextArea>{recipientTypeReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
        {actionType !== 'Read only' ? (
          <DropDownThree>
            <DropSmallMargin>
              <SelectDropdown
                domID="test-id"
                label="document type"
                name="documentType"
                options={populateDataDropDown({
                  listName: 'documentTypesList',
                  name: 'documentType',
                  detailElements,
                  selectedRuleTypeId,
                  selectedRuleDetail
                })}
                initialValue={documentType || '1'}
                onChange={checkUnsavedChanges}
                startWithBlankValue={false}
              />
            </DropSmallMargin>
          </DropDownThree>
        ) : <ReadOnlyTextTitle>Document Type<ReadOnlyTextArea>{documentTypeReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
      </ContainerBox>
      <ContainerBox>
        {actionType !== 'Read only' ? (
          <ContainerBoxTextArea>
            <TextArea
              data-test-id="message-textarea"
              domID="message"
              hasCounter
              name="message"
              label="Message"
              initialValue={message}
              placeholder="Fill out your message here"
              disabled={false}
              readOnly={false}
              onChange={checkUnsavedChanges}
              maxLength={4001}
              hasError={messageFormError}
              errorMessage={messageFormErrorMessage}
            />
          </ContainerBoxTextArea>
        ) : <ReadOnlyTextTitle>Message<ReadOnlyTextArea>{messageReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
      </ContainerBox>
      <ButtonGroupBottom>
        <ModalButton>
          {loading.has(recordId) && (
            <LoadingWrapper>
              <LoadingIndicator
                domID="basic-details-form-loading-indicator"
                length="30px"
              />
            </LoadingWrapper>
          )}
          {actionType !== 'Read only' ? (
            <Button
              domID="automation-id"
              name="Save"
              buttonType="emphasized"
              size="medium"
              disabled={isButtonDisabled || loading.has(recordId)}
              onClick={onSave}
              isDropdown={false}
              type="button"
            />
          ) : null }
          <Button
            domID="automation-id"
            name={actionType !== 'Read only' ? 'Cancel' : 'Close'}
            buttonType="diminished"
            size="medium"
            disabled={selectedRuleRecord && loading.has(recordId)}
            onClick={onCancel}
            isDropdown={false}
            type="button"
          />
        </ModalButton>
      </ButtonGroupBottom>
    </div>
  );
}

CustomerServiceMessageModal.propTypes = {
  selectedRuleDetail: PropTypes.object,
  isPostingRuleDetail: PropTypes.bool,
  detailElements: PropTypes.object,
  setIsShowingCustomerServiceMessageModal: PropTypes.func,
  isCloningRuleDetail: PropTypes.bool,
  postRuleDetails: PropTypes.func,
  putRuleDetails: PropTypes.func,
  selectedRecord: PropTypes.object,
  selectedRuleRecord: PropTypes.object,
  selectedRuleTypeId: PropTypes.string,
  loading: PropTypes.object,
  actionType: PropTypes.string,
  setIsCloningRuleDetail: PropTypes.func,
  setIsPostingRuleDetail: PropTypes.func,
};

export default CustomerServiceMessageModal;
