import React from 'react'
import { Page } from '../../components'
import RouteWrapper from '../../components/RouteWrapper';
import ListPaneContent from './ReportBuilder'

const ReportBuilder = () => (
<RouteWrapper>
    <Page 
        ListPaneContent={ListPaneContent} 
    />
</RouteWrapper>
)

export default ReportBuilder