import { useEffect, useState } from 'react'
import { useAxiosPost } from '../hooks'

const useIDDocuments = () => {
  const [ docs, setDocs ] = useState([]);
  const { data, isLoading, postData, ...rest } = useAxiosPost({
    url: 'welcomeKit/documents',
    method: 'POST',
  });
  useEffect(() => {
    if(data){
      setDocs(data);
    }
  },[data])
  return { docs: docs && docs.data, total: docs && docs.totalRecords, isLoading, getDocuments: postData, ...rest}
}

export default useIDDocuments