import { SORTING_COMPARATOR } from '../../../../shared/helpers/sorting';
export const gridConfig = isSelectable => ({
  columns: new Set([
    {
      dataName: 'ruleTypeName',
      text: 'Rule Types',
      sortable: true,
    },
    {
      dataName: 'associatedTestRule',
      text: 'Associated Test Rule',
      sortable: true,
    },
    {
      dataName: 'activeGroupLabel',
      text: 'Active Group/s With Live Association To Associated Test Rule',
      sortable: true,
    },
    {
      dataName: 'associatedLiveRule',
      text: 'Associated Live Rule',
      sortable: true,
    },
  ]),
  selectionKey: 'ruleTypeName',
  domID: 'commit-tab-grid',
  onSortTableColumn: () => {},
  supportSelection: isSelectable,
  initialSortingKey: 'ruleTypeName|SORT_ASCENDING',
  sortingComparator: SORTING_COMPARATOR,
});

export const gridActiveGroupConfig = {
  columns: new Set([
    {
      dataName: 'name',
      text: 'Active Group',
      sortable: true,
    },
    {
      dataName: 'description',
      text: 'Description',
      sortable: true,
    },
  ]),
  selectionKey: 'id',
  domID: 'active-rule-grid',
  onSortTableColumn: () => {},
  supportSelection: false,
  initialSortingKey: 'dateModified|SORT_DESCENDING',
  isFixedHeader: true,
};
