import React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import { Page } from '../components';
import DirectPrintJobTrackerMain from '../containers/DirectPrintJobTracker/index'
import DirectPrintFilesForJob from '../containers/DirectPrintJobTracker/DirectPrintFilesForJob'

const DirectPrintJobTrackerPage = () => (
  <Page
    ListPaneContent={DirectPrintJobTrackerMain}
    DetailPaneContent={DirectPrintFilesForJob}
  />
);
// const DirectPrintFilesForJobPage = () => (
//   <Page
//     ListPaneContent={DirectPrintFilesForJob}
//   />
// );

const DirectPrintJobTrackerRoutes = () => (
  <Switch>
    <Route path="/DirectPrintJobTracker/index" component={DirectPrintJobTrackerPage} />
    {/* <Route path="/DirectPrintJobTracker/Files" component={DirectPrintFilesForJobPage} /> */}
    <Route exact path="/DirectPrintJobTracker">
      <Redirect to="/DirectPrintJobTracker/index" />
    </Route>
  </Switch>
)

export default DirectPrintJobTrackerRoutes;