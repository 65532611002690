import React, { useState, useEffect } from 'react';
import moment from 'moment';
const CountDownTimer = (props) => {
  const { startDate,endDate } = props;
  const eventTime= moment(startDate);
  const currentTime = moment(endDate);
  let distance = eventTime.diff(currentTime);

  const [counter, setCounter] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  useEffect(() => {
    const timer = setInterval(() => {
      setTime();
    }, 1000);
    return () => clearInterval(timer);
  }, [startDate, endDate]);

  const setTime = () =>{
    if (distance > 0){
      distance -= 1000;
      let s = Math.floor(distance / 1000);
      let m = Math.floor(s / 60);
      s %= 60;
      let h = Math.floor(m / 60);
      m %= 60;
      let d = Math.floor(h / 24);
      h %= 24;
      const pad = (n) => n < 10 ? `0${  n}` : n;
      if (m === 60) {
        h += 1;
        m = 0;
      }
      if (h === 24) {
        d += 1;
        h = 0;
      }
      h = pad(h);
      m = pad(m);

      setCounter({
        days: d,
        hours: h,
        minutes: m,
        seconds: s,
      });
    } else {
      clearInterval();
    }
  }

  const countDownString = () => {
    let str = `${counter.days} day${counter.days !==1 ? 's' : ''}, `
    str += `${counter.hours} hour${counter.hours !==1 ? 's' : ''}, `
    str += `${counter.minutes} minute${counter.minutes !== 1 ? 's' : ''}, `
    str += `${counter.seconds} second${counter.seconds !== 1 ? 's' : ''}`
    return str;
  }

  const renderTimer = (
    <div>
      <div style={{
        marginBottom: '10px',
        fontSize: '13px',    
        marginLeft: '10px'}}
      >
        {endDate < startDate ?
          (<b>Countdown to release: {countDownString()}</b>)
          :
          (<b>The release has started. <i><br/>Only document management that was completed by the start of the release was included.</i></b>)
        }
      </div>
    </div>
  );

  return (
    <div>
      {endDate !== startDate ? renderTimer : ''}
    </div>
  );

}
export default CountDownTimer;
