import { push } from 'connected-react-router'
import { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLogout } from '..'
import { DEFAULT_USER_SESSION_TIME, TOAST_SHOW_TIME, SESSION_TIMEOUT, SESSION_INTERVAL} from '../../containers/InactivityTracker/modules/constants'
import axios from '../../axios';

const useInactivityTracker = () => {
  const dispatch = useDispatch()
  const logout = useLogout()
  const [time, setTime] = useState(DEFAULT_USER_SESSION_TIME)
  const [lastAccess, setLastAccess] = useState(new Date())
  const [showtoast, setshowtoast] = useState(false)

  useEffect(() => {
    function checkUserData() {
      const item = localStorage.getItem('user:token')

      if (!item) {
        dispatch(push('/login'))
      }
    }

    window.addEventListener('storage', checkUserData)

    return () => {
      window.removeEventListener('storage', checkUserData)
    }
  }, [])

  const showToastMessage = (args) => {
    if (args) {
      setshowtoast(true)
    } else {
      setshowtoast(false)
    }
  }

  const resetTimer = async() => {
    if (Number(Math.abs(lastAccess?.getTime() - new Date().getTime()) / (60 * 1000))
      > (Number(SESSION_TIMEOUT) - Number(SESSION_INTERVAL)) && !showtoast && window?.location?.pathname!=="/login") {
        await axios.post('/users/continueSession')
        setLastAccess(new Date())
        setTime(DEFAULT_USER_SESSION_TIME)
    }
  }
  document.onmousedown = resetTimer;
  document.onkeydown = resetTimer;
  document.onscroll = resetTimer;

  function handleLogout() {
    document.removeEventListener('mousedown', resetTimer)
    document.removeEventListener('keydown', resetTimer)
    document.removeEventListener('scroll', resetTimer)
    logout('timeout')
  }

  useEffect(() => {
    let interval = null
    if (time === DEFAULT_USER_SESSION_TIME) {
      showToastMessage(false)
    }
    if (time === TOAST_SHOW_TIME) {
      showToastMessage(true)
    }
    if (time > 0 && !interval) {
      interval = setInterval(() => setTime(t=>t-1), 1000)
    } else if (time <= 0) {
      showToastMessage(false)
      clearInterval(interval)
      handleLogout()
    }
    return () => {
      clearInterval(interval)
      document.removeEventListener('mousedown', resetTimer)
      document.removeEventListener('keydown', resetTimer)
      document.removeEventListener('scroll', resetTimer)
    }
  }, [handleLogout, time])

  return [showtoast, setTime, setLastAccess]
}

export default useInactivityTracker