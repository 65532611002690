import React from 'react';
import PropTypes from 'prop-types';
import { Portal } from 'react-portal';
import { NotificationBanner } from 'ui-core-ssgr';
import DefaultView from '../DefaultView';
import AdvancedView from '../AdvancedView';
import { DetailsPane } from './styles';
import { IS_EDIT_ROLE } from '../../constants';

const DetailsWrapper = props => {
  const {
    isExpanded,
    toggleDetailsView,
    selectedRecord,
    isCloningGroup,
    isEditingRuleDetail,
    isReadOnlyRuleDetail,
    isAdvancedViewShown,
    setIsViewingRuleDetails,
    selectedRuleRecord,
    selectedRuleRecordStatus,
    setIsCloningGroupState,
    detailsPaneState,
    ruleTypesList,
    rulesForRuleTypesList,
    selectedRuleTypeId,
    setDefaultGroupDetailsTab,
    setAdvancedGroupDetailsTab,
    setIsShowingActiveGroupsListingModal,
    setIsShowingDeactivateGroupModal,
    isDeactivateGroupModalOpen,
    triggerDeactivateGroupRequest,
    setSelectedRuleDetail,
    notifications,
    errorNotifications,
    deleteNotification,
    deleteAllNotifications,
    overwriteTestRuleRequestTrigger,
    setIsShowingOverwriteModal,
    isShowingOverwriteModal,
    loading,
    setRuleTypeCommitModalOpen,
    ruleTypeCommitRuleModalOpen,
    triggerCommitRuleForRuleType,
    setIsShowingCloneGroupModal,
    setSelectedDetailsRecordId,
    setIsShowingTestFileManager,
    isShowingTestFileManager,
    addNotification,
    gridConfig,
    triggerRuleDetailsRequest,
    resetRuleTypesListReceived,
    activeGroupsModalLocation,
    selectedActiveRecordsList,
    selectedRecordName,
    setIsShowingActivateGroupModal,
    triggerRuleTypesRequest,
    setIsReadOnlyRuleDetail,
    setIsShowingCustomerServiceMessageModal,
    setIsShowingCheckTextRuleModal,
    setIsShowingErisaMessageModal,
    setIsShowingSignatureRuleModal,
    setIsShowingImageRuleModal,
    setIsShowingReturnAddressModal,
    setIsShowingProductionArrangementModal,
    setIsShowingTaxIdArrangementModal,
    setShowingHighDollarReviewModal,
    setIsShowingMemberIdModal,
    setIsShowingPDFTaggingModal,
    captureCurrentPage,
    getRevertRuleVersions,
    revertRuleVersionsList,
    setIsShowingRevertRuleModal,
    isShowingRevertRuleModal,
    postRevertRuleVersion,
    userRole,
    setIsShowingGeneralMessageModal,
    setIsShowingProviderInsuredOptionMessageModal,
    setIsShowingAutoHoldModal,
    setIsShowingDuplexRuleModal,
    userType,
    rulesForRuleTypes
  } = props;

  const visibility = isAdvancedViewShown ? 'none' : 'block';
  const onNotificationDismiss = id => {
    if (
      selectedRecord &&
      notifications.has(selectedRecord.id) &&
      notifications.get(selectedRecord.id).type === 'negative'
    ) {
      deleteNotification(id);
    }
  };

  return (
    <DetailsPane isExpanded={isExpanded}>
      <DefaultView
        style={{
          display: visibility,
        }}
        isExpanded={isExpanded}
        isCloningGroup={isCloningGroup}
        setIsCloningGroupState={setIsCloningGroupState}
        toggleDetailsView={toggleDetailsView}
        selectedRecord={selectedRecord}
        defaultGroupsDetailsTabIndex={
          detailsPaneState.defaultGroupsDetailsTabIndex
        }
        updateCurrentTab={setDefaultGroupDetailsTab}
        setIsShowingActiveGroupsListingModal={setIsShowingActiveGroupsListingModal}
        setIsShowingDeactivateGroupModal={setIsShowingDeactivateGroupModal}
        isDeactivateGroupModalOpen={isDeactivateGroupModalOpen}
        triggerDeactivateGroupRequest={triggerDeactivateGroupRequest}
        setDefaultGroupDetailsTab={setDefaultGroupDetailsTab}
        deleteNotification={deleteNotification}
        loading={loading}
        setIsShowingCloneGroupModal={setIsShowingCloneGroupModal}
        setSelectedDetailsRecordId={setSelectedDetailsRecordId}
        setIsShowingTestFileManager={setIsShowingTestFileManager}
        isShowingTestFileManager={isShowingTestFileManager}
        resetRuleTypesListReceived={resetRuleTypesListReceived}
        setIsShowingActivateGroupModal={setIsShowingActivateGroupModal}
        triggerRuleTypesRequest={triggerRuleTypesRequest}
        captureCurrentPage={captureCurrentPage}
        isEditRole={IS_EDIT_ROLE(userRole).value}
      />
      {isAdvancedViewShown && (
        <AdvancedView
          {...props}
          isEditingRuleDetail={isEditingRuleDetail}
          isReadOnlyRuleDetail={isReadOnlyRuleDetail}
          ruleTypesList={ruleTypesList}
          gridConfig={gridConfig}
          rulesForRuleTypesList={rulesForRuleTypesList}
          notifications={notifications}
          errorNotifications={errorNotifications}
          setSelectedRuleDetail={setSelectedRuleDetail}
          selectedRuleRecordStatus={selectedRuleRecordStatus}
          toggleDetailsView={toggleDetailsView}
          selectedRecord={selectedRecord}
          triggerRuleDetailsRequest={triggerRuleDetailsRequest}
          selectedRuleRecord={selectedRuleRecord}
          setIsViewingRuleDetails={setIsViewingRuleDetails}
          selectedRuleTypeId={selectedRuleTypeId}
          advancedGroupsDetailsTabIndex={
            detailsPaneState.advancedGroupsDetailsTabIndex
          }
          updateCurrentTab={setAdvancedGroupDetailsTab}
          deleteNotification={deleteNotification}
          deleteAllNotifications={deleteAllNotifications}
          loading={loading}
          overwriteTestRuleRequestTrigger={overwriteTestRuleRequestTrigger}
          setIsShowingOverwriteModal={setIsShowingOverwriteModal}
          isShowingOverwriteModal={isShowingOverwriteModal}
          setRuleTypeCommitModalOpen={setRuleTypeCommitModalOpen}
          ruleTypeCommitRuleModalOpen={ruleTypeCommitRuleModalOpen}
          triggerCommitRuleForRuleType={triggerCommitRuleForRuleType}
          addNotification={addNotification}
          setDefaultGroupDetailsTab={setDefaultGroupDetailsTab}
          activeGroupsModalLocation={activeGroupsModalLocation}
          selectedActiveRecordsList={selectedActiveRecordsList}
          selectedRecordName={selectedRecordName}
          setIsShowingActiveGroupsListingModal={setIsShowingActiveGroupsListingModal}
          setIsShowingActivateGroupModal={setIsShowingActivateGroupModal}
          setIsReadOnlyRuleDetail={setIsReadOnlyRuleDetail}
          setIsShowingCustomerServiceMessageModal={setIsShowingCustomerServiceMessageModal}
          setIsShowingCheckTextRuleModal={setIsShowingCheckTextRuleModal}
          setIsShowingErisaMessageModal={setIsShowingErisaMessageModal}
          setIsShowingSignatureRuleModal={setIsShowingSignatureRuleModal}
          setIsShowingPDFTaggingModal={setIsShowingPDFTaggingModal}
          captureCurrentPage={captureCurrentPage}
          setIsShowingImageRuleModal={setIsShowingImageRuleModal}
          setIsShowingReturnAddressModal={setIsShowingReturnAddressModal}
          setIsShowingProductionArrangementModal={setIsShowingProductionArrangementModal}
          setIsShowingTaxIdArrangementModal={setIsShowingTaxIdArrangementModal}
          setShowingHighDollarReviewModal={setShowingHighDollarReviewModal}
          setIsShowingMemberIdModal={setIsShowingMemberIdModal}
          getRevertRuleVersions={getRevertRuleVersions}
          revertRuleVersionsList={revertRuleVersionsList}
          setIsShowingRevertRuleModal={setIsShowingRevertRuleModal}
          isShowingRevertRuleModal={isShowingRevertRuleModal}
          postRevertRuleVersion={postRevertRuleVersion}
          setIsShowingGeneralMessageModal={setIsShowingGeneralMessageModal}
          setIsShowingProviderInsuredOptionMessageModal={setIsShowingProviderInsuredOptionMessageModal}
          setIsShowingAutoHoldModal={setIsShowingAutoHoldModal}
          setIsShowingDuplexRuleModal={setIsShowingDuplexRuleModal}
          userType={userType}
          rulesForRuleTypes={rulesForRuleTypes}
        />
      )}
      {notifications.size > 0 && (
        <Portal key="basic-details-form">
          {notifications
            ? [...notifications].map(([key, value]) => (
              <NotificationBanner
                key={[key]}
                domID={`${key}-notification`}
                type={value.type}
                text={value.msg}
                onDismiss={() => onNotificationDismiss(key)}
                autoDismiss
                timer={1800000}
                icon={value.type === 'neutral' ? 'Info' : null}
              />
            ))
            : null}
        </Portal>
      )}
    </DetailsPane>
  );
};

DetailsWrapper.defaultProps = {
  selectedRecord: null,
  isExpanded: false,
};

DetailsWrapper.propTypes = {
  setIsViewingRuleDetails: PropTypes.func,
  toggleDetailsView: PropTypes.func.isRequired,
  setDefaultGroupDetailsTab: PropTypes.func.isRequired,
  setAdvancedGroupDetailsTab: PropTypes.func.isRequired,
  setIsShowingActiveGroupsListingModal: PropTypes.func,
  isExpanded: PropTypes.bool,
  isAdvancedViewShown: PropTypes.bool.isRequired,
  selectedRecord: PropTypes.shape({
    id: PropTypes.string,
  }),
  selectedRuleRecord: PropTypes.shape({
    id: PropTypes.string,
  }),
  detailsPaneState: PropTypes.shape({
    defaultGroupsDetailsTabIndex: PropTypes.number,
    advancedGroupsDetailsTabIndex: PropTypes.number,
  }).isRequired,
  selectedRuleRecordStatus: PropTypes.array,
  setIsShowingDeactivateGroupModal: PropTypes.func,
  isDeactivateGroupModalOpen: PropTypes.bool,
  triggerDeactivateGroupRequest: PropTypes.func,
  setSelectedRuleDetail: PropTypes.func,
  notifications: PropTypes.object,
  errorNotifications: PropTypes.object,
  deleteNotification: PropTypes.func,
  deleteAllNotifications: PropTypes.func,
  overwriteTestRuleRequestTrigger: PropTypes.func,
  setIsShowingOverwriteModal: PropTypes.func,
  isShowingOverwriteModal: PropTypes.bool,
  loading: PropTypes.object,
  setRuleTypeCommitModalOpen: PropTypes.func,
  ruleTypeCommitRuleModalOpen: PropTypes.bool,
  triggerCommitRuleForRuleType: PropTypes.func,
  setIsShowingCloneGroupModal: PropTypes.func,
  setSelectedDetailsRecordId: PropTypes.func,
  setIsShowingTestFileManager: PropTypes.func,
  isShowingTestFileManager: PropTypes.bool,
  addNotification: PropTypes.func,
  gridConfig: PropTypes.object,
  triggerRuleDetailsRequest: PropTypes.func,
  resetRuleTypesListReceived: PropTypes.func,
  setIsCloningGroupState: PropTypes.func,
  ruleTypesList: PropTypes.array,
  rulesForRuleTypesList: PropTypes.array,
  selectedRuleTypeId: PropTypes.string,
  isCloningGroup: PropTypes.bool,
  isEditingRuleDetail: PropTypes.bool,
  activeGroupsModalLocation: PropTypes.string,
  selectedActiveRecordsList: PropTypes.object,
  selectedRecordName: PropTypes.string,
  setIsShowingActivateGroupModal: PropTypes.func,
  captureCurrentPage: PropTypes.func,
  userType: PropTypes.string
};

export default DetailsWrapper;
