/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import styled from 'styled-components/dist/styled-components';
import { useDispatch } from 'react-redux';
import {
  Grid,
  Button,
  useLayout,
  Input,
  Checkbox,
} from 'ui-core/dist';
import { LoadingPage, SelectDropdown } from '../../components';
import ErrorPage from '../ErrorPage';
import ListPaneHeaderWrapper from '../../components/ErrorDisplaySection/ListPaneHeaderWrapper';
import GridPaginationWrapper from '../../components/GridPaginationWrapper';
import { DELIVERY_ADDRESS_GRID } from './Modules/constants';
import { setManageAddressRecord, resetManageAddressRecord } from './Modules/action';
import { useGetAddresses, useGetAlternateAddresses, useGetReturnLogos, useGetReturnStyles } from "../../api/useAddressManager";
import useSortableGrid from '../../hooks/useSortableGrid';
import Authorizer from '../../containers/Authorizer';
const StyledGridWrapper = styled.div`
&.GridWrapper #grid-wrapper-test-id{
  overflow:initial !important;
}
`
const ReturnAddressesMain = () => {
  const {
    expandCollapseListPaneButtonConfig,
    openDetailPane,
    closeDetailPane
  } = useLayout()
  const dispatch = useDispatch()
  const [count, setcount] = useState(0)
  const [selectedRecord,setSelectedRecord]=useState('')
  const [totalRecords, setTotalRecords] = useState(0);
  const [alternateAddress, setAlernateAddress] = useState();
  const [returnLogo, setReturnLogo] = useState();
  const [returnStyle, setReturnStyle] = useState();
  const [records, setRecords] =useState([])
  const [rowsPerPage,setRowsPerPage]=useState(10);
  const [pageIndex,setPageIndex]=useState(0);
  const [status,setStatus] = useState({label: 'Active', value: 'true'})
  const [searchValue, setSearchValue] = useState('');
  const [exactMatch, setExactMatch] = useState(false);
  const [resetControls, setResetControls] = useState(false);

  const {data: returnAddresses, postData: getReturnAddresses, isError, isLoading, error}
    =useGetAddresses()
  const { postData: getAlternateAddressList, data: AlternateAddressData }=useGetAlternateAddresses();
  const { postData: getReturnLogoLists, data: ReturnLogosData }=useGetReturnLogos();
  const { postData: getReturnStyleLists, data: ReturnStylesData }=useGetReturnStyles();
  const SELECT_RECORD_OPTIONS = [{label:'Active',value: true},{label: 'Inactive',value: false}]

  const getParameters = useMemo(() =>(
    `<![CDATA[
      <criteria>
        <itemType value="R">
            <filter>
                <item></item>
            </filter>
        </itemType>
        <addressToSearch value="${exactMatch ? searchValue?.trim() : searchValue}"/>
        <findExactMatch value="${exactMatch}"/>
      </criteria>
      ]]>`
  ),[searchValue, exactMatch])

  useEffect(() => {
    dispatch(resetManageAddressRecord())
    getAlternateAddressList({maxRecords:10, rowPosition:0,sortField:'',viewInactive:false,sortDescending:false,parameters:'',tagname:'AlternateAddress'});
    getReturnLogoLists({maxRecords:10, rowPosition:0,sortField:'',viewInactive:false,sortDescending:false,parameters:'',tagname:'ReturnLogos'});
    getReturnStyleLists({maxRecords:10, rowPosition:0,sortField:'',viewInactive:false,sortDescending:false,parameters:'',tagname:'ReturnStyles'});

    getReturnAddresses({
      maxRecords: 10,
      rowPosition: 0,
      sortField: '',
      sortDescending: false,
      viewInactive: false,
      searchValue: '',
      exactMatch: false,
      parameters:getParameters,
      count
    })
    setcount(count + 1)
    closeDetailPane();
  },[]);

  const fetchData = useCallback((args) => {
    let sortcolumn; let sd; let pi;
    if (args) {
      ({sortcolumn,sd,pi}=args);
    }
    getReturnAddresses({
      maxRecords: rowsPerPage,
      rowPosition: pi===undefined || pi===null?pageIndex: pi,
      sortField:sortcolumn===undefined || sortcolumn===null?sortConfig.sortDataName: sortcolumn,
      sortDescending: sd===undefined || sd===null?sortConfig.sortDescending: sd,
      viewInactive: !status.value,
      parameters: getParameters,
      count
    })
    setcount(count + 1)
    closeDetailPane();
  }, [rowsPerPage, pageIndex, status, searchValue, exactMatch, sortConfig, count])

  const [sortConfig, getArgumentsAndSortGrid]=useSortableGrid(fetchData,"addressId","false");
  useEffect(() => {
    if (AlternateAddressData && AlternateAddressData.AlternateAddresses  && AlternateAddressData.AlternateAddresses.AlternateAddress) {
      const retAddr = AlternateAddressData.AlternateAddresses.AlternateAddress || null;
      const newRetAddr = retAddr ? Array.isArray(retAddr) ? retAddr : [retAddr] : [];
      const so=newRetAddr.map((x)=>({label:x.name,value:x.id}))
      setAlernateAddress([{label:'--None--',value:''},...JSON.parse(JSON.stringify(so))])
    }
    if (ReturnLogosData && ReturnLogosData.ReturnLogos  && ReturnLogosData.ReturnLogos.ReturnLogo) {
      const retLogo = ReturnLogosData.ReturnLogos.ReturnLogo || null;
      const newRetLogo = retLogo ? Array.isArray(retLogo) ? retLogo : [retLogo] : [];
      const so=newRetLogo.map((x)=>({label:x.name,value:x.id}))
      setReturnLogo([{label:'--None--',value:''},...JSON.parse(JSON.stringify(so))])
    }
    if (ReturnStylesData && ReturnStylesData.ReturnStyles  && ReturnStylesData.ReturnStyles.ReturnStyle) {
      const retStyle = ReturnStylesData.ReturnStyles.ReturnStyle || null;
      const newRetStyle = retStyle ? Array.isArray(retStyle) ? retStyle : [retStyle] : [];
      const so=newRetStyle.map((x)=>({label:x.name,value:x.id}))
      setReturnStyle([...JSON.parse(JSON.stringify(so))])
    }
    if(returnAddresses){
      const {collection}=returnAddresses;
      if (collection) {
        setTotalRecords(collection.$?.totalRecords)
        const {Address}=collection;
        if (Address) {
          const newAddr = Array.isArray(Address) ? Address : [Address]
          setRecords(newAddr)
        } else {
          setRecords([])
        }
      } else {
        setTotalRecords(0)
        setRecords([])
      }
    }
  }, [returnAddresses,AlternateAddressData,ReturnStylesData,ReturnLogosData])

  const refetchReturnRecord = () => {
    fetchData()
    setcount(count + 1)
    closeDetailPane();
    setResetControls(false)
  }

  useEffect(()=>{
    if(resetControls){
      refetchReturnRecord();
    }
  }, [resetControls]);

  const resetControlsFunc = () => {
    setSearchValue('')
    setExactMatch(false)
    setPageIndex(0)
    setRowsPerPage(10)
    setStatus({label:'Active',value: true})
    setSelectedRecord({})
    setResetControls(true)
  }

  const onShowMoreClick = useCallback((record) => {
    dispatch(setManageAddressRecord({ ManageAddressRecord: JSON.parse(JSON.stringify(record)), alternateAddress
      ,returnStyle,returnLogo, mode: 'Edit', refetchManageAddressRecord: refetchReturnRecord, resetControls: resetControlsFunc }));
    setSelectedRecord(JSON.parse(JSON.stringify(record)))
    openDetailPane({
      isAdd: false
    });
  },[alternateAddress,returnStyle,returnLogo, count]);

  const onAddReturnAddress = () => {
    setSelectedRecord({})
    dispatch(setManageAddressRecord({ ManageAddressRecord: JSON.parse(JSON.stringify({})), alternateAddress
      ,returnStyle,returnLogo, mode: 'Add', refetchManageAddressRecord: refetchReturnRecord,  resetControls: resetControlsFunc }));
    openDetailPane({
      isAdd: true
    });
  }
  const renderFirstFilter=()=>(
    <div style={{
      width: '200px',
      marginLeft: '5px',
      marginRight: '5px'
    }}>
      <SelectDropdown
        width='200px'
        domID="AddressStatusActive"
        label=""
        options={SELECT_RECORD_OPTIONS}
        size="small"
        initialValue={status}
        onChange={(e)=>setStatus(e)}
      />
    </div>
  );
  const renderGrid = useMemo(() => {
    if (records) {
      let data = []
      if(records.constructor.name === 'Array')
        data = JSON.parse(JSON.stringify(records))
      else
        data = JSON.parse(JSON.stringify([records]))
      return (
        <>
          <StyledGridWrapper className='GridWrapper'>
            <div className="displayFlex"  style={{paddingLeft: '23px'}}>

              <span>Return Address Search: </span>
              <Input
                style={{ width: '200px',marginLeft:'10px'}}
                className="test-classname"
                domID="txt_renderaddressId"
                size="small"
                maxLength={50}
                initialValue={searchValue}
                onChange={(e) => {
                  if(e)
                    setSearchValue(e.target.value)
                }}
                onEnterPress={fetchData}
              />

              <span style={{ marginLeft:'10px',marginRight:"10px"}}>Exact Match: </span>
              <Checkbox
                domID="exactMatch"
                size="large"
                checked={exactMatch}
                onChange={() => {
                  setExactMatch(!exactMatch)
                }}
              />

            </div>

            <GridPaginationWrapper
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={setRowsPerPage}
              pageIndex={pageIndex}
              onChangePageIndex={setPageIndex}
              onShowButtonCLick={fetchData}
              totalRecords={totalRecords}
              defaultRowsPerPage={10}
              FirstFilter={renderFirstFilter}
            />
            <Grid
              {...DELIVERY_ADDRESS_GRID}
              records={data || []}
              selectionKey= "addressId"
              onClickThrough={(e, rec) => {
                e.preventDefault();
                e.stopPropagation();
                if (rec) {
                  const record = (data || []).find(x => x.addressId === rec.addressId)
                  if (onShowMoreClick) {
                    dispatch(resetManageAddressRecord())
                    return onShowMoreClick({...record, data});
                  }
                  return false;
                }
                return false;
              }}
              focusedItem={selectedRecord}
              initialSortingKey={`${sortConfig.sortDataName}|${sortConfig.sortDescending==="true"?'SORT_DESCENDING':'SORT_ASCENDING'}`}
              onSortGridColumn={(e,state)=>getArgumentsAndSortGrid(state)}
            />
          </StyledGridWrapper>
        </>
      )
    }
    return null;
  }, [
    records,
    selectedRecord,
    alternateAddress,
    sortConfig,
    searchValue,
    exactMatch,
    rowsPerPage,
    pageIndex,
    totalRecords,
    status
  ])

  return (
    <>
      <ListPaneHeaderWrapper
        title="Return Address Manager"
        layoutControls={[expandCollapseListPaneButtonConfig]}
      />
      {isLoading ? (
        // replace with a better loading component
        <LoadingPage />
      ) : isError ? (
        // replace with a better error component
        <ErrorPage
          // this prop isn't real but is an example
          error={error}
        />
      ) : (
        <>
          <div className="marginLeft">
            <table border="0" cellSpacing="1" cellPadding="3" width="100%">
              <tr>
                <td>
                  <Authorizer permission='CAN_PERFORM_ADDRESS_DELIVERY_ADD'>
                  <div className="displayFlex"  style={{paddingLeft: '23px'}}>
                    <Button
                      id='ContactEditorAdd'
                      size="small"
                      name="Add Return Address"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onAddReturnAddress()
                      }}
                    />
                  </div>
                  </Authorizer>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  {renderGrid}
                </td>
              </tr>
            </table>
          </div>
          {/* {renderMainGrid} */}
        </>
      )}
    </>
  )
};

export default ReturnAddressesMain
