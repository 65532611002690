import { useAxios } from '../hooks'

const useFileTrackerFiles = ({ payload, getCancelSource }) => {
  const { data, ...rest } = useAxios({
    getCancelSource,
    url: '/fileTracker/files',
    method: 'POST',
    payload,
    // negativeMessage: true
  })

  return { 
    data: data?.data, 
    cacheName: data?.cacheName,
    lastUpdated: data?.lastUpdated,
    total: data?.totalRecords || undefined, ...rest}
}

export default useFileTrackerFiles