
const initialState = {
  eeobFormManagerRecord:null,
  eeobFormManagerMode:'',
  refetchEEOBFormManager:()=>false,
}
  
export default (state = initialState, { type, payload }) => {
  switch(type) {
    case 'SET_EEOB_FORM_MANAGER_RECORD':
      return {
        ...state,
        eeobFormManagerRecord:JSON.parse(JSON.stringify(payload.eeobFormManagerRecord)),
        eeobFormManagerMode:payload.mode,
        refetchEEOBFormManager:payload.refetchEEOBFormManager
      };
    case 'RESET_EEOB_FORM_MANAGER_RECORD':
      return {
        ...state,
        eeobFormManagerRecord:null,
        eeobFormManagerMode:'',
        refetchEEOBFormManager:()=>false
      };
    default:
      return state;
  }
}