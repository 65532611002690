/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useEffect, useCallback } from 'react';
import styled from 'styled-components/dist/styled-components'
import { useLayout } from 'ui-core/dist/useLayout';
import DetailPaneHeader from 'ui-core/dist/DetailPaneHeader';
import { useSelector } from 'react-redux';
import {
  Modal, Button, ModalHeader, Input, TextArea, ModalBody
} from 'ui-core/dist';
import { SelectDropdown } from '../../components';
import Authorizer from '../Authorizer';
import { detailPaneAnimStates } from '../../commonResources/constants/animStates';
import ErrorPage from '../ErrorPage';
import LoadingComponent from '../../components/LoadingComponent';
import {SELECT_YES_NO_OPTIONS} from '../ClientAdministration/Modules/constants';
import { 
  useAddEmail,
  useActivateEmail,
  useDeactivateEmail,
  useModifyEmail,
  useGetSelected
} from "../../api/useClientEmail";

import {
  useGetCheckAccountInfo, useGetAvailableImages, 
  useAddCheckAccount, useModifyCheckAccount,
  useActivateCheckAccount, useDectivateCheckAccount
} from '../../api/useClientRuleManager'

import ModalWrapper from '../../components/Modal/components/ModalWrapper';
import useNotificationBannerHook from '../../useNotificationBannerHook';

const DEFAULT_SELECTION = {label: 'None',value: ''};

const StyledModalWrapperWidth=styled(ModalWrapper)`
& .active-content {
  width: 30% !important;
}
`

const CheckAccountInfoDetails = () => {
  const {
    expandCollapseDetailPaneButtonConfig,
    closeDetailPaneButtonConfig,
    detailAnimationState,
    closeDetailPane
  } = useLayout()

  const { notify } = useNotificationBannerHook();
  const [checkAccountId, setCheckAccountId] = useState('')
  const [eobMessage, setEobMessage] = useState('')
  const [checkLogo, setCheckLogo] = useState(DEFAULT_SELECTION)
  const [checkBankLogo, setCheckBankLogo] = useState(DEFAULT_SELECTION)
  const [isActive, setIsActive] = useState(true)
  const [ voidMessage, setVoidMessage] = useState('')
  const [ checkNumberAddOn, setCheckNumberAddOn] = useState('')
  const [ micrAcctNoStartPos, setMicrAcctNoStartPos] = useState('')
  const [ signature1, setSignature1] = useState(DEFAULT_SELECTION)
  const [ signature2, setSignature2] = useState(DEFAULT_SELECTION)
  const [ secondSignatureThreshold, setSecondSignatureThreshold] = useState('')
  const [editorMode, setEditorMode ] = useState('Add')
  const [isOpenConfirmationModal, setisOpenConfirmationModal] = useState(false)
  const [confirmationModalMode, setconfirmationModalMode] = useState('')
  const [count, setcount] = useState(0)

  const { selectedRecord: records, mode: editorModep, refetchRecord }
    = useSelector(({ clientRule }) => clientRule);
  
  const { data: infoCheckAcctInfo, postData: getCheckAccountInfo, isLoading: isLoadingGetSelected,
    isError: isErrorGetSelected, error: errorGetSelected } = useGetCheckAccountInfo()
  
  const { data: infoAddCheckAccount, postData: addCheckAccount, metaData: infoAddCheckAccountInfo,
    isLoading: isLoadingAddCheckAccount, isError: isErrorAddCheckAccount, error: errorAddCheckAccount 
  } = useAddCheckAccount()
  
  const { data: infoModifyCheckAccount, postData: modifyCheckAccount, metaData: infoModifyCheckAccountInfo,
    isLoading: isLoadingModifyCheckAccount, isError: isErrorModifyCheckAccount, error: errorModifyCheckAccount 
  } = useModifyCheckAccount()
  
  const { data: infoActivateCheckAccount,postData: activateCheckAccount, metaData: infoActivateCheckAccountInfo, isLoading: isLoadingActivateCheckAccountInfo,
    isError: isErrorActivateCheckAccountInfo, error: errorActivateCheckAccountInfo, } = useActivateCheckAccount()
    
  const { data: infoDeactivateCheckAccount, postData: deactivateCheckAccount, metaData: infoDeactivateCheckAccountInfo, isLoading: isLoadingDeactivateCheckAccountInfo,
    isError: isErrorDeactivateCheckAccountInfo, error: errorDeactivateCheckAccountInfo, } = useDectivateCheckAccount()
  
  const {availableSignatures, availableLogos, postData: getAvailableImages, isLoading: isLoadingAvailableImages,
    isError: isErrorAvailableImages, error: errorAvailableImages } = useGetAvailableImages()

  useEffect(()=>{
    getAvailableImages({strImageType: 'S'});
    getAvailableImages({strImageType: 'L'});
  },[])

  useEffect(() => {
    if (records) {
      if (records['check-account-id']) {
        getCheckAccountInfo({strCheckAccountId: records['check-account-id'], count})
        setcount(count+1)
      } else {
        onClearCurrentRecordValues()
      }
    }
  }, [records]);

  const onReset = useCallback(() => {
    if (infoCheckAcctInfo && infoCheckAcctInfo['check-account-information']
    && infoCheckAcctInfo['check-account-information']['check-account-information']) {
      const checkAcctInfo = infoCheckAcctInfo['check-account-information']['check-account-information']
      const { 'check-account-id': checkAccountIdp, 'eob-message': eobMessagep, 'check-bank-logo': checkBankLogop,
        'check-logo': checkLogop, isActive: activep,
        'void-message': voidMessagep, 'number-add-on': checkNumberAddOnp,
        'micr-account-number-start-pos': micrAcctNoStartPosp, 'signature-1': signature1p, 'signature-2': signature2p,
        'signature-2-threshold': secondSignatureThresholdp} = checkAcctInfo
      setCheckAccountId(checkAccountIdp)
      setEobMessage(eobMessagep)
      setCheckLogo(checkLogop ? {label: checkLogop, value: checkLogop} : {label: 'None', value: checkLogop})
      setCheckBankLogo(checkBankLogop ? {label: checkBankLogop, value: checkBankLogop} : {label: 'None', value: checkBankLogo})
      setIsActive(activep === 'false' ? 0 : 1)
      setVoidMessage(voidMessagep)
      setCheckNumberAddOn(checkNumberAddOnp)
      setMicrAcctNoStartPos(micrAcctNoStartPosp !== "0" ? micrAcctNoStartPosp : "")
      setSignature1(signature1p ? {label: signature1p, value: signature1p} : {label: 'None', value: signature1p})
      setSignature2(signature2p ? {label: signature2p, value: signature2p} : {label: 'None', value: signature2p})
      setSecondSignatureThreshold(secondSignatureThresholdp !== "0" ? secondSignatureThresholdp : "")
      setEditorMode(editorModep)
    }
  },[infoCheckAcctInfo])

  useEffect(() => {    
    onReset()
  },[infoCheckAcctInfo])

  useEffect(() => {
    if (infoAddCheckAccountInfo) {
      const { status: respStatus, statusText } = infoAddCheckAccountInfo;
      if (respStatus === 200 || statusText === "OK") {
        if (infoAddCheckAccount && infoAddCheckAccount.fs) {
          const { fs } = infoAddCheckAccount;
          if (fs) {
            notify(fs, 'negative')
          }
          return;
        }        
        notify(`You have successfully added Check Account ${checkAccountId}`, 'positive')
        closeDetailPane()
        if (refetchRecord) refetchRecord()
      }
    }
  }, [infoAddCheckAccountInfo, infoAddCheckAccount])

  useEffect(() => {
    if (infoModifyCheckAccountInfo) {
      const { status: respStatus, statusText } = infoModifyCheckAccountInfo;
      if (respStatus === 200 || statusText === "OK") {
        if (infoModifyCheckAccount && infoModifyCheckAccount.fs) {
          const { fs } = infoModifyCheckAccount;
          if (fs) {
            notify(fs, 'negative')
          }
          return;
        }        
        notify(`You have successfully update Check Account ${checkAccountId}`, 'positive')
        closeDetailPane()
        if (refetchRecord) refetchRecord()
      }
    }
  }, [infoModifyCheckAccountInfo, infoModifyCheckAccount])

  useEffect(() => {
    if (infoActivateCheckAccountInfo) {
      const { status: respStatus, statusText } = infoActivateCheckAccountInfo;
      if (respStatus === 200 || statusText === "OK") {
        if (infoActivateCheckAccount && infoActivateCheckAccount.fs) {
          const { fs } = infoActivateCheckAccount;
          if (fs) {
            notify(fs, 'negative')
          }
          return;
        }        
        notify(`You have successfully reactivated Check Account ${checkAccountId}`, 'positive')
        closeDetailPane()
        if (refetchRecord) refetchRecord()
      }
    }
  }, [infoActivateCheckAccountInfo, infoActivateCheckAccount])

  useEffect(() => {
    if (infoDeactivateCheckAccountInfo) {
      const { status: respStatus, statusText } = infoDeactivateCheckAccountInfo;
      if (respStatus === 200 || statusText === "OK") {
        if (infoDeactivateCheckAccount && infoDeactivateCheckAccount.fs) {
          const { fs } = infoDeactivateCheckAccount;
          if (fs) {
            notify(fs, 'negative')
          }
          return;
        }        
        notify(`You have successfully deactivated Check Account ${checkAccountId}`, 'positive')
        closeDetailPane()
        if (refetchRecord) refetchRecord()
      }
    }
  }, [infoDeactivateCheckAccountInfo, infoDeactivateCheckAccount])

  const onDeactivateClick = () => {
    if (editorMode === "Edit") {
      if(isActive)
        setconfirmationModalMode("Deactivate")
      else
        setconfirmationModalMode("Reactivate")
      setisOpenConfirmationModal(true)
    }
  }
  const onSubmitClick = () => {
    if (editorMode === "Add") {
      setconfirmationModalMode("Add")
    } else if (editorMode === "Edit") {
      setconfirmationModalMode("Modify")
    }
    setisOpenConfirmationModal(true)
  }

  const onConfirmationYesClick = () => {
    if (confirmationModalMode === "Deactivate") {
      deactivateCheckAccount({ 
        checkAccountInfoXml: `
      <![CDATA[<check-account-information>
        <check-account-id>${checkAccountId}</check-account-id>
        <eob-message>${eobMessage}</eob-message>
        <check-logo>${checkLogo.value}</check-logo>
        <check-bank-logo>${checkLogo.value}</check-bank-logo>
        <void-message>${voidMessage}</void-message>
        <number-add-on>${checkNumberAddOn}</number-add-on>
        <micr-account-number-start-pos>${micrAcctNoStartPos}</micr-account-number-start-pos>
        <signature-1>${signature1.value}</signature-1>
        <signature-2>${signature2.value}</signature-2>
        <signature-2-threshold>${secondSignatureThreshold}</signature-2-threshold>
        <isActive>0</isActive>          
      </check-account-information>]]>`, count })
    } else if(confirmationModalMode === "Reactivate") {
      activateCheckAccount({ 
        checkAccountInfoXml: `
      <![CDATA[<check-account-information>
        <check-account-id>${checkAccountId}</check-account-id>
        <eob-message>${eobMessage}</eob-message>
        <check-logo>${checkLogo.value}</check-logo>
        <check-bank-logo>${checkLogo.value}</check-bank-logo>
        <void-message>${voidMessage}</void-message>
        <number-add-on>${checkNumberAddOn}</number-add-on>
        <micr-account-number-start-pos>${micrAcctNoStartPos}</micr-account-number-start-pos>
        <signature-1>${signature1.value}</signature-1>
        <signature-2>${signature2.value}</signature-2>
        <signature-2-threshold>${secondSignatureThreshold}</signature-2-threshold>
        <isActive>1</isActive>          
      </check-account-information>]]>`, count })
    } else if (confirmationModalMode === "Add") {
      addCheckAccount({
        checkAccountInfoXml: `
        <![CDATA[<check-account-information>
          <check-account-id>${checkAccountId}</check-account-id>
          <eob-message>${eobMessage}</eob-message>
          <check-logo>${checkLogo.value}</check-logo>
          <check-bank-logo>${checkLogo.value}</check-bank-logo>
          <void-message>${voidMessage}</void-message>
          <number-add-on>${checkNumberAddOn}</number-add-on>
          <micr-account-number-start-pos>${micrAcctNoStartPos}</micr-account-number-start-pos>
          <signature-1>${signature1.value}</signature-1>
          <signature-2>${signature2.value}</signature-2>
          <signature-2-threshold>${secondSignatureThreshold}</signature-2-threshold>
          <isActive>${isActive ? 1 : 0}</isActive>          
        </check-account-information>]]>`,
        count
      })
    } else if (confirmationModalMode === "Modify") {
      modifyCheckAccount({
        checkAccountInfoXml: `
        <![CDATA[<check-account-information>
          <check-account-id>${checkAccountId}</check-account-id>
          <eob-message>${eobMessage}</eob-message>
          <check-logo>${checkLogo.value}</check-logo>
          <check-bank-logo>${checkLogo.value}</check-bank-logo>
          <void-message>${voidMessage}</void-message>
          <number-add-on>${checkNumberAddOn}</number-add-on>
          <micr-account-number-start-pos>${micrAcctNoStartPos}</micr-account-number-start-pos>
          <signature-1>${signature1.value}</signature-1>
          <signature-2>${signature2.value}</signature-2>
          <signature-2-threshold>${secondSignatureThreshold}</signature-2-threshold>
          <isActive>${isActive ? 1 : 0}</isActive>          
        </check-account-information>]]>`,
        count
      })
    }
    setconfirmationModalMode('')
    setisOpenConfirmationModal(false)
    setcount(count + 1)
  }

  const onClearCurrentRecordValues = () => {
    setCheckAccountId('')
    setEobMessage('')
    setCheckLogo(DEFAULT_SELECTION)
    setCheckBankLogo({label:'None',value: ''})
    setVoidMessage('')
    setCheckNumberAddOn('')
    setMicrAcctNoStartPos('')
    setSignature1(DEFAULT_SELECTION)
    setSignature2(DEFAULT_SELECTION)
    setSecondSignatureThreshold('')
    setEditorMode('Add')
  }

  const onCopyCurrentRecordValues = () => {
    setCheckAccountId('')
    setEditorMode('Add')
  }

  const onResetClick = (mode) => {
    onReset()
    if(mode === 'Add'){      
      onCopyCurrentRecordValues()
    }
  }
  
  const renderCheckAccountId = useMemo(() => (
    editorMode === 'Add' ?
      <Input
        style={{ width: '200px' }}
        className="test-classname"
        domID="txt_renderCheckAccountId"
        placeholder=""
        size="small"
        maxLength={8}
        initialValue={checkAccountId}
        onChange={(e) => {
          setCheckAccountId(e.target.value || "")
        }}
      /> : checkAccountId
  ), [checkAccountId])

  const renderEobMessage = useMemo(() => (
    <TextArea
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_renderEobMessage"
      size="small"
      maxLength={40}
      initialValue={eobMessage}
      onChange={(e) => {
        setEobMessage(e.target.value || "")
      }}
      placeholder="You can resize me"
      resize="vertical"
    />
    
  ), [eobMessage])

  const renderCheckLogo = useMemo(() => (
    <div>
      <SelectDropdown
        width="300px"
        domID="defer_state_input"
        label=""
        options={availableLogos}
        size="small"
        initialValue={checkLogo}
        onChange={(e)=>{
          setCheckLogo(e)
        }}
      />
    </div>
  ), [checkLogo, availableLogos])

  const renderCheckBankLogo = useMemo(() => (
    <SelectDropdown
      width="300px"
      domID="defer_state_input"
      label=""
      options={availableLogos}
      size="small"
      initialValue={checkBankLogo}
      onChange={(e)=>{
        setCheckBankLogo(e)
      }}
    />
  ), [checkBankLogo, availableLogos])

  const renderVoidMessage = useMemo(() => (
    <div style={{width:"200px"}}>
      <Input
        style={{ width: '200px' }}
        className="test-classname"
        domID="txt_rendervoidMessage"
        placeholder=""
        size="small"
        maxLength={35}
        initialValue={voidMessage}
        onChange={(e) => {
          setVoidMessage(e.target.value || "")
        }}
      />
    </div>
  ), [voidMessage])  
  
  const renderCheckNumberAddOn = useMemo(() => (
    <div style={{width:"200px"}}>
      <Input
        style={{ width: '200px' }}
        className="test-classname"
        domID="txt_renderCheckNumberAddOn"
        placeholder=""
        size="small"
        maxLength={10}
        initialValue={checkNumberAddOn}
        onChange={(e) => {
          setCheckNumberAddOn(e.target.value || "")
        }}
      />
    </div>
  ), [checkNumberAddOn])  
  
  const validateMicrAcctNo = (value) => {
    if(!value || (/^\d{2}$/.test(value) && (parseInt(value,10) > 12) && (parseInt(value,10) < 33))){
      return true
    }
    return false
  }

  const validateSecondSignatureThreshold = (value) => {
    if(!value || (/^\d*(\.\d+)?$/.test(value)
      && /^\d*(\.\d+)?$/.test(parseFloat(value)) >= 0.00
      && /^\d*(\.\d+)?$/.test(parseFloat(value)) <= 1000000000000.00)) {
      return true
    }
    return false
  }

  const renderMicrAcctNoStartPos = useMemo(() => (
    <div style={{width:"200px"}}>
      <Input
        style={{ width: '200px' }}
        className="test-classname"
        domID="txt_renderMicrAcctNoStartPos"
        placeholder=""
        size="small"
        maxLength={5}
        initialValue={micrAcctNoStartPos}        
        hasError={!validateMicrAcctNo(micrAcctNoStartPos)}
        errorMessage={'Enter valid whole number between 13 and 32, inclusive.'}
        onChange={(e) => {
          setMicrAcctNoStartPos(e.target.value || "")
        }}
      />
    </div>
  ), [micrAcctNoStartPos])
  
  const renderSignature1 = useMemo(() => (
    <div>
      <SelectDropdown
        width="300px"
        domID="defer_state_input"
        label=""
        options={availableSignatures}
        size="small"
        initialValue={signature1}
        onChange={(e)=>{
          setSignature1(e)
        }}
      />
    </div>
  ), [signature1, availableSignatures])
  
  const renderSignature2 = useMemo(() => (
    <div>
      <SelectDropdown
        width="300px"
        domID="defer_state_input"
        label=""
        options={availableSignatures}
        size="small"
        initialValue={signature2}
        onChange={(e)=>{
          setSignature2(e)
        }}
      />
    </div>
  ), [signature2, availableSignatures])
  
  const renderSecondSignatureThreshold = useMemo(() => (
    <div style={{width:"200px"}}>
      <Input
        style={{ width: '200px' }}
        className="test-classname"
        domID="txt_rendersecondSignatureThreshold"
        placeholder=""
        size="small"
        maxLength={16}
        initialValue={secondSignatureThreshold}
        hasError={!validateSecondSignatureThreshold(secondSignatureThreshold)}
        errorMessage={'Second Signature Threshold must be a valid monetary value between 0.00 and 1,000,000,000,000.00, inclusive.'}
        onChange={(e) => {
          setSecondSignatureThreshold(e.target.value || "")
        }}
      />
    </div>
  ), [secondSignatureThreshold])

  const validateForm = useMemo(() => {
    if (!checkAccountId || !validateMicrAcctNo(micrAcctNoStartPos) || !validateSecondSignatureThreshold(secondSignatureThreshold)){
      return false;
    }
    return true
  }, [checkAccountId,micrAcctNoStartPos,secondSignatureThreshold])

  const disabledDiactivateButton = useMemo(() => {
    if (editorMode === "Edit") {
      if (records && !records['check-account-id']) {
        return true;
      }
      return false;
    }
    return true;
  }, [editorMode, records])
  const renderDeliveryAddressForm = useMemo(() => {
    if (detailAnimationState > detailPaneAnimStates.COLLAPSED) {
      return (
        <div style={{ marginLeft: '40px' }} className="marginLeft">
          <div>
            <p>
              <div className="displayFlex">Complete the information below and then click Submit to continue.</div>
            </p>
            <p>
              <div className="displayFlex"><span style={{ color: 'red' }}>* indicates required information</span></div>
            </p>
          </div>
          <br />
          <div className="displayFlex">
            <table className="common-table">
              <tr>
                <td>
                  <span> Check Account ID: <span style={{ color: 'red' }}>*</span></span>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderCheckAccountId}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <span> EOB Message: </span>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderEobMessage}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <span> Check Logo: </span>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderCheckLogo}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <span> Check Bank Logo: </span>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderCheckBankLogo}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <span> Void Message: </span>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderVoidMessage}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <span> Check Number Add On: </span>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderCheckNumberAddOn}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <span> MICR Account Number Start Position: </span>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderMicrAcctNoStartPos}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <span> Signature: </span>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '360px' }}>
                    {renderSignature1}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <span> Second Signature: </span>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '400px' }}>
                    {renderSignature2}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <span> Second Signature Threshold: </span>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '400px' }}>
                    {renderSecondSignatureThreshold}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td colSpan="2">
                  <div className="displayFlex">
                    <div className="marginLeft">
                    <Authorizer permission="CAN_PERFORM_CLIENTRULES_CAI_ADD" > 
                      <Button
                        domID="btn_copy"
                        buttonType="standard"
                        size="small"
                        name="COPY"
                        disabled={editorMode === "Add"}
                        onClick={() => {
                          onCopyCurrentRecordValues()
                          setEditorMode('Add')
                        }}
                      />
                      </Authorizer>
                    </div>
                    <div className="marginLeft">
                      <Button
                        domID="btn_reset"
                        buttonType="standard"
                        size="small"
                        name="RESET"
                        onClick={() => {
                          onResetClick(editorMode)
                        }}
                      />
                    </div>
                    <div className="marginLeft">
                    <Authorizer permission="CAN_PERFORM_CLIENTRULES_CAI_ADD" > 
                      <Button
                        domID="btn_diactivate"
                        buttonType="standard"
                        size="small"
                        name={isActive ? "DEACTIVATE" : 'REACTIVATE'}
                        disabled={disabledDiactivateButton}
                        onClick={() => onDeactivateClick()}
                      />
                      </Authorizer>
                    </div>
                    <div className="marginLeft">
                    <Authorizer permission="CAN_PERFORM_CLIENTRULES_CAI_MODIFY" > 
                      <Button
                        domID="btn_search"
                        buttonType="standard"
                        size="small"
                        name="SUBMIT"
                        disabled={!validateForm}
                        onClick={() => onSubmitClick()}
                      />
                      </Authorizer>
                    </div>
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
            </table>
          </div>

          <Modal isOpen={isOpenConfirmationModal} wrapperComponent={StyledModalWrapperWidth}>
            <ModalHeader
              title={`Do you want to ${confirmationModalMode} ?`}
              onClose={() => setisOpenConfirmationModal(false)}
            />
            <ModalBody>
              {isOpenConfirmationModal ? (
                <div style={{ marginLeft: '34px', marginBottom: '20px' }} className="marginLeft">
                  <div className="displayFlex">
                    <div className="marginLeft">
                      <Button
                        domID="btn_search"
                        buttonType="standard"
                        size="medium"
                        name="Yes"
                        onClick={() => onConfirmationYesClick()}
                      />
                    </div>
                    <div className="marginLeft">
                      <Button
                        domID="btn_search"
                        buttonType="standard"
                        size="medium"
                        name="No"
                        onClick={() => setisOpenConfirmationModal(false)}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
            </ModalBody>
          </Modal>
        </div>
      )
    }
    return null;
  }, [detailAnimationState,validateForm, editorMode, records,
    isOpenConfirmationModal, confirmationModalMode,checkAccountId, eobMessage, checkBankLogo,
    checkLogo, voidMessage, checkNumberAddOn, micrAcctNoStartPos,
    signature1, signature2, secondSignatureThreshold, infoCheckAcctInfo])

  return (
    <>
      <DetailPaneHeader
        title={editorMode === "Edit" ? "Modify Check Account Info" : "Add Check Account Info"}
        layoutControls={[
          expandCollapseDetailPaneButtonConfig,
          closeDetailPaneButtonConfig
        ]}
      />
      <>
        {isLoadingAddCheckAccount || isLoadingGetSelected || isLoadingDeactivateCheckAccountInfo
          || isLoadingAvailableImages || isLoadingActivateCheckAccountInfo || isLoadingModifyCheckAccount ? (
            // replace with a better loading component
            <div style={{ marginLeft: '50%' }}><LoadingComponent /></div>
          ) : isErrorAddCheckAccount || isErrorGetSelected || isErrorDeactivateCheckAccountInfo
            || isErrorAvailableImages || isErrorActivateCheckAccountInfo || isErrorModifyCheckAccount ? (
              // replace with a better error component
              <ErrorPage
                // this prop isn't real but is an example
                error={errorAddCheckAccount || errorGetSelected || errorDeactivateCheckAccountInfo
                  || errorAvailableImages || errorActivateCheckAccountInfo || errorModifyCheckAccount}
              />
            ) : (
              <>
                {renderDeliveryAddressForm}
              </>
            )
        } 
      </>
    </>
  )
};

export default CheckAccountInfoDetails