import { useAxios } from '../hooks'

const useMessagesList = ({ viewInactive, maxRecords, rowPosition }) => {
  const { data, ...rest } = useAxios({
    url: `/message-manager/messages?viewInactive=${viewInactive}&maxRecords=${maxRecords}&rowPosition=${rowPosition}`,
  })

  return { 
    data: data?.data,
    total: data?.totalRecords,
    ...rest
  }
}

export default useMessagesList