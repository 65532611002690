import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, LoadingIndicator, Modal, Warning } from 'ui-core-ssgr';
import get from 'lodash/get';
import ButtonGroup from '../../../../shared/components/ButtonGroup';
import Colors from '../../../../shared/styles/colors';
import { 
  ModalPaddingWrapper, 
  BoldTextAfterWarningIcon, 
  IconWrapper, ModalTitlePaddingWrapper, 
  StyledHeader,
} from '../../../../shared/styles/styledComponents';
import { LoadingWrapper, ModalSubheadingPaddingWrapper } from './styles';
import { DivWithPaddingTop } from '../../../../shared/styles/spacing';

const ActivateGroupModal = ({
  setIsShowingActivateGroupModal,
  triggerActivateGroupRequest,
  onCancel,
  setDefaultGroupDetailsTab,
  ruleTypesList,
  loading,
  isShowingActivateGroupModal,
  selectedRecord,
}) => {
  const selectedRecordName = get(selectedRecord, 'name', '');
  const [commitButtonDisabled, setCommitButtonDisabled] = useState(false);
  const [activateMessage, setActivateMessage] = useState(`You are about to activate the group  ${selectedRecordName}.`);
  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    const ruleTypeRecords = get(ruleTypesList, 'ruleTypesAccumRecords', []);
    const hasTestAssociations = ruleTypeRecords.filter(type => type.id === 'PAR' || type.id === 'RAD').length === 2;   
    const arrangement = get(selectedRecord, 'arrangement', '');
    const returnAddr = get(selectedRecord, 'returnAddr', '');
  
    if (arrangement.length > 0 && returnAddr.length > 0) {
      setActivateMessage(`You are about to activate the group  ${selectedRecordName}.`);
      setCommitButtonDisabled(false);
      setCurrentTab(0);
    }
    if (hasTestAssociations && (arrangement.length === 0 || returnAddr.length === 0)) {
      setActivateMessage('The following rule associations must be committed before activating the group: Template and Delivery Assignments, and Return Address.');
      setCurrentTab(2);
      setCommitButtonDisabled(true);
    }
    if (!hasTestAssociations && (arrangement.length === 0 || returnAddr.length === 0)) {
      setActivateMessage('The following rule associations must be added and committed before activating the group: Template and Delivery Assignments, and Return Address.');
      setCurrentTab(1);
      setCommitButtonDisabled(true);
    }
  }, [selectedRecord, ruleTypesList]);

  const onButtonClick = name => e => {
    const groupId = get(selectedRecord, 'id', '');

    if (name === 'cancel') {
      onCancel(e, selectedRecord);
      setDefaultGroupDetailsTab(currentTab);
      setIsShowingActivateGroupModal(false);
    }
    if (name === 'confirm') {
      triggerActivateGroupRequest({
        groupId,
        isActive: 'true',
      });
      setIsShowingActivateGroupModal(false);
    }
  };

  const onModalToggle = () => setIsShowingActivateGroupModal(false);

  return (
    <Modal
      domID="modal-id"
      onModalToggle={onModalToggle}
      isOpen={
        isShowingActivateGroupModal ||
        (loading && loading.has('activateGroup'))
      }
      showDefaultClose
      deferBodyControl={false}
    >
      <ModalPaddingWrapper>
        <ModalTitlePaddingWrapper>
          <StyledHeader>
            {`Activating: Group ${selectedRecordName}`}
          </StyledHeader>
        </ModalTitlePaddingWrapper>
        <ModalSubheadingPaddingWrapper>
          {activateMessage}
        </ModalSubheadingPaddingWrapper>
        <IconWrapper>
          <Warning fillColor={Colors.negativeDark} />
        </IconWrapper>
        <BoldTextAfterWarningIcon>
          {`Activating a group will allow future documents to be processed in production.`}
        </BoldTextAfterWarningIcon>
        <DivWithPaddingTop>
          <ButtonGroup>
            {loading && loading.has('activateGroup') && (
              <LoadingWrapper>
                <LoadingIndicator
                  domID="basic-details-form-loading-indicator"
                  length="30px"
                />
              </LoadingWrapper>
            )}
            <Button
              name="confirm"
              buttonType="emphasized"
              onClick={onButtonClick('confirm')}
              disabled={(loading && loading.has('activateGroup')) || commitButtonDisabled}
              size="medium"
              type="button"
            />
            <Button
              name="cancel"
              buttonType="diminished"
              onClick={onButtonClick('cancel')}
              size="medium"
              type="button"
            />
          </ButtonGroup>
        </DivWithPaddingTop>
      </ModalPaddingWrapper>
    </Modal>
  );
};

ActivateGroupModal.propTypes = {
  loading: PropTypes.object,
  setIsShowingActivateGroupModal: PropTypes.func,
  triggerActivateGroupRequest: PropTypes.func,
  isShowingActivateGroupModal: PropTypes.bool,
  onCancel: PropTypes.func,
  setDefaultGroupDetailsTab: PropTypes.func,
  ruleTypesList: PropTypes.object,
  selectedRecord: PropTypes.object,
};

export default ActivateGroupModal;
