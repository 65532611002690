/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import styled from 'styled-components/dist/styled-components'
import {
  Grid,
  useLayout,
  ListPaneHeader,
  Button, Input, DatePickerV2
} from 'ui-core/dist'
import { useSubmitUnauthorizedAccess } from '../../api/useUnauthorizedAccessReport';
import LoadingComponent from '../../components/LoadingComponent';
import ErrorPage from '../ErrorPage/components/ErrorPage';
import useNotificationBannerHook from '../../useNotificationBannerHook';



const ON_HOLD_REPORT_SUBMITTED = 'Unauthorized Access Report Web Transaction {0} has been submitted.  If you have not received this report within six (6) hours, please contact client services via customer care hub (https://customercare.changehealthcare.com/public/home.html). If you do not have access to customer care hub, please contact your internal administrator.'

const UnauthorizedAccessReport = () => {
  const {
    expandCollapseListPaneButtonConfig
  } = useLayout()
  const DEFAULT_DATE = '';
  const DEFAULT_EMAIL_ADDRESS = '';
  const { notify } = useNotificationBannerHook();
  const { isLoading, isError, error, postData, data: infoSubmitResponse, metaData: metadataSubmitResponse } = useSubmitUnauthorizedAccess()
  //   const [noOfDays, setnoOfDays]=useState(DEFAULT_DATE)
  const [fromDate, setfromDate] = useState(DEFAULT_DATE)
  const [toDate, settoDate] = useState(DEFAULT_DATE)
  const [email, setEmail] = useState(DEFAULT_EMAIL_ADDRESS);

  useEffect(() => {
    if (metadataSubmitResponse) {
      const { status, statusText } = metadataSubmitResponse;
      if (status === 200 || statusText === "OK") {
        if (infoSubmitResponse && infoSubmitResponse.fs) {
          const { fs } = infoSubmitResponse;
          if (fs) {
            notify(fs, 'negative')
            return;
          }
        }
        const { reportId } = infoSubmitResponse;
        const msg = ON_HOLD_REPORT_SUBMITTED.replace('{0}', reportId || '');
        onResetClick()
        notify(msg, 'positive')
      }
    }
  }, [metadataSubmitResponse, infoSubmitResponse])

  const onResetClick = useCallback(() => {
    setfromDate(DEFAULT_DATE)
    settoDate(DEFAULT_DATE)
    setEmail(DEFAULT_EMAIL_ADDRESS)
  }, [email, fromDate, toDate])

  const onSubmitClick = useCallback(() => {
    postData({
      pstrStartDate: fromDate ? fromDate.format("MM/DD/YYYY") : '',
      pstrEndDate: toDate ? toDate.format("MM/DD/YYYY") : '',
      pstrEmail: email
    })
  }, [email, fromDate, toDate])

  const renderSDate = useMemo(() => (
    <div style={{ width: "200px" }}>
      <DatePickerV2
        // label='Start Date'
        domID="unauthorizedDatePicker1"
        initialSelection={fromDate}
        onDateChange={(e) => {
          if (e) {
            setfromDate(e)
          }
        }}
        size="small"
      />
    </div>
  ), [fromDate]);

  const renderEDate = useMemo(() => {
    return (
      <div style={{ width: "200px" }}>
        <DatePickerV2
          // label='Start Date'
          domID="unauthorizedDatePicker2"
          initialSelection={toDate}
          onDateChange={(e) => {
            if (e) {
              settoDate(e)
            }
          }}
          size="small"
        />
      </div>
    )
  }, [toDate])
  //   ), [noOfDays]);
  const renderEmailAdres = useMemo(() => (
    <Input
      dataTestId="onholdreport-email"
      domID="onholdreport-email"
      size="medium"
      maxLength={255}
      errorMessage="Valid Email Required"
      // hasError={validateEmail()}
      initialValue={email}
      onChange={(e) => {
        setEmail(e.target.value || "");
      }}
    />
  ), [email]);
  const validateForm = useMemo(() => {
    // const regex = new RegExp('[a-z0-9]+@[a-z]+.[a-z]');
    const reEmail = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (!email || !(email.match(reEmail)) || !fromDate || !toDate) {
      return false;
    }
    return true;
  }, [email, fromDate, toDate])

  return (
    <>
      <ListPaneHeader
        title="Unauthorized Access Report Request"
        layoutControls={[expandCollapseListPaneButtonConfig]}
      />
      <>
        {isLoading ? (
          // replace with a better loading component            
          <div style={{ marginLeft: '50%' }}><LoadingComponent /></div>
        ) : (<>
          {
            isError && <div> <ErrorPage
              // this prop isn't real but is an example
              error={error}
            />

            </div>
          }

          <div className="marginLeft" style={{ wordBreak: "keep-all", 'margin-top': '25px' }}>
            <table style={{ width: '70%' }}>
              <tr>
                <td className=''><label>From Date:</label></td>
                <td className=''>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderSDate}
                  </div>
                </td>
                <td className=''><label style={{ width: '50px' }}>To Date:</label></td>
                <td className=''>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderEDate}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td className=''><label style={{ width: '201px' }}>Email Address for Delivery of Report:</label></td>
                <td className=''>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderEmailAdres}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                </td>
                <td>
                  <div className='displayFlex'>
                    <Button
                      buttonType="standard"
                      size="small"
                      name="Reset"
                      onClick={onResetClick}
                    />
                    <div className='marginLeft'>
                      <Button
                        buttonType="standard"
                        size="small"
                        name="Submit"
                        disabled={!validateForm}
                        onClick={onSubmitClick}
                      />
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </>
          )
        }
      </>
    </>
  )
}

export default UnauthorizedAccessReport;