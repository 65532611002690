import styled from 'styled-components';

export const ScrollableGridWrapper = styled.div`
  max-height: 45vh;
  margin-bottom: 10vh;
  overflow: auto;
`;

export const ModalButton = styled.div`
  margin-top: 3em;
  margin-bottom: 1em;
  &.button:last-child {
    margin-left: 30px;
`;

export const LoadingWrapper = styled.div`
  float: left;
  margin-left: -20px;
  margin-top: 3px;
`;

export const LoadingWrapperImageControl = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10%;
`;

export const LoadingWrapperSmall = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

export const FileZoneWrapper = styled.div`
  display: block;
`;

export const ImageNameWrap = styled.div`
  display: block;
  margin: 4% 25% 0% 25%;
`;

