import React from 'react'
import { useSelector } from 'react-redux'
import { Page } from '../../components'
import MessageList from './MessageList';
import ModifyMessage from './ModifyMessage';
import AddMessage from './AddMessage';

const MessageManager = () => {
    const isAddMsg = useSelector(store => store.sysAdmin.isAddMsg)
    return (<Page
        ListPaneContent={MessageList}
        DetailPaneContent={isAddMsg ? AddMessage : ModifyMessage}
    />)
}
export default MessageManager