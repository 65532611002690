import styled from 'styled-components';
import { DetailHeader } from 'ui-core-ssgr';
import { ButtonGroup } from '../../../../shared/components/ButtonGroup';

export const ButtonStatus = styled(ButtonGroup)`
    right: 1em;
    top: 4.9em;
    position: absolute;
    & button:last-child {
        margin-right: .5rem;
    `;

export const StyledDetailHeader = styled(DetailHeader)`
    & > div > div > div > h1 {
        overflow: visible;
        font-size: 26px;
    }
    & #tooltip-group-id > span > div.tooltip-wrapper > div {
        font-size: 26px;
    }
`;