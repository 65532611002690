import {
  RULE_TYPES_REQUEST_TRIGGER,
  RULE_TYPES_REQUEST_SUCCESS,
  RULE_TYPES_REQUEST_ERROR,
  UPDATE_RULETYPES_REQUEST_TRIGGER,
  SET_IS_VIEWING_RULE_DETAILS,
  RULES_GRID_QUERY_UPDATE,
  RULE_DETAILS_REQUEST_TRIGGER,
  RULE_DETAILS_REQUEST_SUCCESS,
  RULE_DETAILS_REQUEST_ERROR,
  SET_RULE_TYPE_MODAL_OPEN,
  SET_RULE_TYPE_REQUEST_TRIGGER,
  SET_RULE_TYPE_REQUEST_SUCCESS,
  SET_RULE_TYPE_REQUEST_ERROR,
  SET_RULE_TYPES_SEARCH_QUERY,
  SET_RULE_TYPES_SEARCH_RESULTS,
  SET_SELECTED_DETAILS_RECORD_ID,
  SET_SELECTED_RULE_TYPE_ID,
  SET_IS_SHOWING_DELETE_RULE_DETAIL_MODAL,
  SET_IS_SHOWING_OVERWRITE_MODAL,
  DELETE_RULE_DETAIL_TRIGGER,
  DELETE_RULE_DETAIL_SUCCESS,
  DELETE_RULE_DETAIL_ERROR,
  SET_IS_SHOWING_DETAIL_CLONE_RULE_MODAL,
  OVERWRITE_TEST_RULE_SUCCESS,
  OVERWRITE_TEST_RULE_ERROR,
  OVERWRITE_TEST_RULE_REQUEST_TRIGGER,
  SET_RULE_TYPES_REQUEST_SUCCESS,
  RESET_RULE_TYPES,
  IMAGES_TEST_COUNT_REQUEST_TRIGGER,
  IMAGES_TEST_COUNT_REQUEST_SUCCESS,
  IMAGES_TEST_COUNT_REQUEST_ERROR,
  RESET_STATE
} from './constants';

export const setRuleTypesSearchQuery = payload => ({
  type: SET_RULE_TYPES_SEARCH_QUERY,
  payload,
});

export const setRuleTypesSearchResults = payload => ({
  type: SET_RULE_TYPES_SEARCH_RESULTS,
  payload,
});

// This is the action that the UI will dispatch to trigger our request loop
export const triggerRuleTypesRequest = payload => ({
  type: RULE_TYPES_REQUEST_TRIGGER,
  payload,
});

export const resetRuleTypes = () => ({
  type: RESET_RULE_TYPES
});

// This action is dispatched from the saga if our request succeeds. It is the
// action the reducer will respond in order to cache the response data
export const ruleTypesListReceived = (payload = [], id = null) => ({
  type: RULE_TYPES_REQUEST_SUCCESS,
  payload,
  id,
  error: null,
});

// This action is used to signal a request error
export const ruleTypesListRequestError = (error, id = null) => ({
  type: RULE_TYPES_REQUEST_ERROR,
  payload: id,
  error,
});

export const rulesGridQueryUpdate = payload => ({
  type: RULES_GRID_QUERY_UPDATE,
  payload,
});

// This action is dispatched from the saga to update the ruletypes in the groupsList modal.
export const triggerUpdateRuleTypes = (payload = [], id = null) => ({
  type: UPDATE_RULETYPES_REQUEST_TRIGGER,
  payload,
  id,
  error: null,
});

export const setIsViewingRuleDetails = (payload = []) => ({
  type: SET_IS_VIEWING_RULE_DETAILS,
  payload,
});

export const setSelectedDetailsRecordId = payload => ({
  type: SET_SELECTED_DETAILS_RECORD_ID,
  payload,
});

// This is the action that the UI will dispatch to trigger our request loop
export const triggerRuleDetailsRequest = (payload = {}) => ({
  type: RULE_DETAILS_REQUEST_TRIGGER,
  payload,
});

// This action is dispatched from the saga if our request succeeds. It is the
// action the reducer will respond in order to cache the response data
export const ruleDetailsListReceived = (payload = []) => ({
  type: RULE_DETAILS_REQUEST_SUCCESS,
  payload,
  error: null,
});

// This action is used to signal a request error
export const ruleDetailsRequestError = error => ({
  type: RULE_DETAILS_REQUEST_ERROR,
  payload: null,
  error,
});

export const setRuleTypeModalOpen = (payload = false) => ({
  type: SET_RULE_TYPE_MODAL_OPEN,
  payload,
});

export const setRuleTypesListReceived = (payload = []) => ({
  type: SET_RULE_TYPE_REQUEST_SUCCESS,
  payload,
});

// This action is used to signal a request error
export const setRuleTypesListRequestError = error => ({
  type: SET_RULE_TYPE_REQUEST_ERROR,
  error,
});

export const setRuleTypeRequestTrigger = (payload = false) => ({
  type: SET_RULE_TYPE_REQUEST_TRIGGER,
  payload,
});

// This action sets if the clone group modal is shown or not
export const setIsShowingDeleteRuleDetailModal = payload => ({
  type: SET_IS_SHOWING_DELETE_RULE_DETAIL_MODAL,
  payload,
});

export const setSelectedRuleTypeId = payload => ({
  type: SET_SELECTED_RULE_TYPE_ID,
  payload,
});

// delete rule detail actions
export const deleteRuleDetailTrigger = (payload = {}) => ({
  type: DELETE_RULE_DETAIL_TRIGGER,
  payload,
});

export const deleteRuleDetailSuccess = (payload = {}) => ({
  type: DELETE_RULE_DETAIL_SUCCESS,
  payload,
});

export const deleteRuleDetailError = error => ({
  type: DELETE_RULE_DETAIL_ERROR,
  error,
});

export const setIsShowingDetailCloneRuleModal = (payload = false) => ({
  type: SET_IS_SHOWING_DETAIL_CLONE_RULE_MODAL,
  payload,
});

export const overwriteTestRuleRequestTrigger = (payload = {}) => ({
  type: OVERWRITE_TEST_RULE_REQUEST_TRIGGER,
  payload,
});

export const overwriteTestRuleReceived = (payload = {}) => ({
  type: OVERWRITE_TEST_RULE_SUCCESS,
  payload,
});

export const overwriteTestRuleError = (payload = {}) => ({
  type: OVERWRITE_TEST_RULE_ERROR,
  payload,
});

export const setIsShowingOverwriteModal = (payload = {}) => ({
  type: SET_IS_SHOWING_OVERWRITE_MODAL,
  payload,
});

export const resetRuleTypesListReceived = (payload = []) => ({
  type: SET_RULE_TYPES_REQUEST_SUCCESS,
  payload,
});

// This is the action that the UI will dispatch to trigger our request loop
export const triggerImagesTestCountRequest = (payload = {}) => ({
  type: IMAGES_TEST_COUNT_REQUEST_TRIGGER,
  payload,
});

// This action is dispatched from the saga if our request succeeds. It is the
// action the reducer will respond in order to cache the response data
export const imagesTestCountRecieved = (payload = []) => ({
  type: IMAGES_TEST_COUNT_REQUEST_SUCCESS,
  payload,
  error: null,
});

// This action is used to signal a request error
export const imagesTestCountError = error => ({
  type: IMAGES_TEST_COUNT_REQUEST_ERROR,
  payload: null,
  error,
});

export const resetRuleTypesList = () => ({
  type: RESET_STATE,
  payload: null,
});