/* eslint-disable react/destructuring-assignment */
import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components/dist/styled-components';
import moment from 'moment';
import { useLayout } from 'ui-core/dist/useLayout';
import DetailPaneHeader from 'ui-core/dist/DetailPaneHeader';
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
import { useSelector, useDispatch } from 'react-redux';
// import moment, { calendarFormat } from 'moment';
import {
  Modal, ModalHeader, TabContainer, LoadingIndicator, ModalBody
} from 'ui-core/dist';
import withAxiosCancellation from '../../components/withAxiosCancellation'
import { detailPaneAnimStates } from '../../commonResources/constants/animStates';
import { AnchorInput } from '../../components';
import ErrorPage from '../ErrorPage';
import LoadingComponent from '../../components/LoadingComponent';
import { resetRecord } from './Modules/action';
import FileReceiptManagerDetailComponent from '../FileReceiptManager/FileReceiptManagerDetailComponent';
import { resetFileKey, setFileKey } from '../FileReceiptManager/Modules/Action';
import { useDocumentDetailSource, useGetPdfFile, useGetEEOBEmail, useDownloadFile } from '../../api/useDocumentManager';
import useNotificationBannerHook from '../../useNotificationBannerHook';
import ACHTransmissionHistoryFileDetails from './ACHTransmissionHistoryFileDetails';

const StyledLoadingContainer = styled.div`
& > div {
  width: 35px !important;
  height: 35px !important;
  & > svg {
    width: 35px !important;
    height: 35px !important;
  }
}
`
const StyledModalWrapper = styled.div`
height: 80vh;
`
const StyledModalHeaderWrapper = styled.div`
& div {
  padding:1px 9px !important;
  padding-bottom: 1px !important;
}
`
const StyledList = styled.ul`
list-style-type:none;
display:block;
font-size:10px;
margin-top:-9px;
`
const StyledListItem = styled.li`
display:inline-block;
border:1px solid black;
margin-right:10px;
margin-top:5px;
padding-left:10px;
padding-right:10px;
text-align:center;
width:${props => props.width || 'auto'}
`

const StyledTable = styled.table`
font-size:13px;
border:1px solid #c3c5cd;
margin-left:5px;
width:99%;
margin-bottom:5px;
& tbody tr{
    height:50px;
}
& tbody tr td, thead tr td{
  padding-left: 6px;
  padding-right: 6px;
  word-break: keep-all;
  min-width: 50px;
  vertical-align: top;
  padding-top: 6px;
}
`
const StyledTable2 = styled.table`
height:95%;
margin-top:5px;
margin-bottom:5px;
border:1px solid #c3c5cd;
& tbody tr {
    height:60px;
    & td {
      vertical-align: top;
      padding-top: 6px;
    }
}
`

const StyledContainerTable = styled.table`
min-height:200px;
font-size:13px;
border:1px solid #c3c5cd;
margin-left:5px;
margin-bottom:5px;
& tbody tr td, thead tr td{
  padding-left: 6px;
  padding-right: 6px;
  word-break: keep-all;
  min-width: 50px;
}
`

// https://prod.storybooks.awsprod.healthcareit.net/?path=/story/layouts-page-layout--getting-started
const DocumentSearchResultsDetailPage = ({getCancelSourceWithName}) => {
  const {
    expandCollapseDetailPaneButtonConfig,
    closeDetailPaneButtonConfig,
    detailAnimationState
  } = useLayout()
  const dispatch = useDispatch()
  const { notify } = useNotificationBannerHook();
  const { filekey }
    = useSelector(({ fileReceipt }) => fileReceipt);
  const { record, index, documentSearchMetaData, currentShowMoreTarget, trackingInfoAvailable, trackingInfoText }
    = useSelector(({ documentSearch }) => documentSearch);
  const DEFAULT_DOWNLOAD_ERR_MESSAGE = "Some error occurred while downloading file";
  const DEFAULT_EEOB_ERR_MESSAGE = "Some error occurred while fetching EEOB";

  const fetchFileDetailsFunc = useRef(null);

  const [isOpenFileReceiptModal, setisOpenFileReceiptModal] = useState(false)
  const [isOpenEEOBModal, setisOpenEEOBModal] = useState(false);
  const [downloadTrackConfig, setDownloadTrackConfig] = useState(null);
  const [getEobConfig, setgetEobConfig] = useState(null);

  const { file: copyNumberFile2, getPdfFile, refetchGetPdfFile, isError: isErrorPdfFile, error: errorPdfFile, isLoading: isLoadingPdfFile } = useGetPdfFile(setDownloadTrackConfig);
  const { data: EEOBEmail, error: errorEEOBEmail, isError: isErrorEEOBEmail, isLoading: isLoadingEEOBEmail, refetcGetEEOBEmail, getEEOBEmail } = useGetEEOBEmail(setgetEobConfig)
  const { file: eeobpdf, error: erroreeobpdf, isError: iserroreeobpdf, isLoading: isloadingeeobpdf, refetcheeobpdf, geteeobpdf } = useDownloadFile(setgetEobConfig)

  useEffect(() => {
    if (isErrorPdfFile) {
      notify(errorPdfFile || DEFAULT_DOWNLOAD_ERR_MESSAGE, 'negative')
    }
    if (isErrorEEOBEmail) {
      notify(errorEEOBEmail || DEFAULT_EEOB_ERR_MESSAGE, 'negative')
    }
    if (iserroreeobpdf) {
      notify(erroreeobpdf || DEFAULT_DOWNLOAD_ERR_MESSAGE, 'negative')
    }

  }, [isErrorPdfFile, isErrorEEOBEmail, iserroreeobpdf])

  const onCloseEEOBModal = () => {
    setisOpenEEOBModal(false);
  }
  useEffect(() => {
    if (EEOBEmail && EEOBEmail.resp) {
      const { resp } = EEOBEmail;
      if (resp.indexOf('http') === -1) {
        setisOpenEEOBModal(true);
      } else {
        const config = {          
          fileurl: resp,
          filename: 'e_eob.pdf',
          documentKey: record.documentKey
        };
        if (JSON.stringify(config) !== JSON.stringify(getEobConfig)) {
          geteeobpdf({ payload: { fileurl: resp }, config: JSON.parse(JSON.stringify(config)) });
        } else {
          refetcheeobpdf()
        }
      }
    }
  }, [EEOBEmail])

  useEffect(() => {
    if (copyNumberFile2 && downloadTrackConfig.filename) {
      downloadFile(copyNumberFile2, "downloadtrack")
    }
  }, [copyNumberFile2])

  useEffect(() => {
    if (eeobpdf && getEobConfig.filename) {
      downloadFile(eeobpdf, "eeobpdf")
    }
  }, [eeobpdf])

  const downloadFile = (f, target) => {
    let filename;
    if (target === "downloadtrack") {
      ({ filename } = downloadTrackConfig)
    } else if (target === "eeobpdf") {
      ({ filename } = getEobConfig)
    }

    if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
      window.navigator.msSaveOrOpenBlob(f, filename);
    } else {
      const FILE = window.URL.createObjectURL(f);
      const docUrl = document.createElement('a');
      docUrl.href = FILE;

      docUrl.setAttribute('download', filename);
      document.body.appendChild(docUrl);
      docUrl.click();
      document.body.removeChild(docUrl);
    }
  }

  useEffect(() => {
    if (record) {
      getShowMoreData(record.documentKey, record.source);
    }
  }, [record]);
  useEffect(() => {
    if (detailAnimationState === detailPaneAnimStates.COLLAPSED && showMoreInfo) {
      dispatch(resetRecord())
    }
  }, [detailAnimationState])

  const onCloseFileReceiptModal = () => {
    setisOpenFileReceiptModal(false)
    dispatch(resetFileKey())
    return true;
  }
  const getShowMoreData = (documentKey, source) => {
    postDataShowMoreInfo(JSON.parse(JSON.stringify({ pstrDocKey: documentKey, pstrSourceTable: source })))
  }

  const { showMoreInfo, postData: postDataShowMoreInfo, isLoading: isLoadingShowMoreInfo,
    isError: isErrorShowMoreInfo, error: errorShowMoreINfo } = useDocumentDetailSource({getCancelSource:getCancelSourceWithName('searchDocumentDetailSource')})

  const renderClaimNumbersList = (x) => {
    if(x && x.length>0){
        let first=[]
        let rest=[]
        if(x.length>4){
          first=x.slice(0,4)
          rest=[...x].splice(4,x.length-4)
        } else if(x.length===4){
          first=x.slice(0,4)
        } else if(x.length<4){
          first=x.slice(0,x.length)
        }
        let _x=null;
        if(first && first.length>0){
          let width = '80px';
          if (first[0].length > 6 && first[0].length <= 8) {
            width = '100px'
          }
          if (first[0].length > 8 && first[0].length <= 10) {
            width = '120px'
          }
          if (first[0].length > 10) {
            width = '163px'
          }
            _x= <StyledList>
            {first[0] ? (<StyledListItem width={width}>{first[0]}</StyledListItem>) : null}
            {first[1] ? (<StyledListItem width={width}>{first[1]}</StyledListItem>) : null}
            {first[2] ? (<StyledListItem width={width}>{first[2]}</StyledListItem>) : null}
            {first[3] ? (<StyledListItem width={width}>{first[3]}</StyledListItem>) : null}
          </StyledList>
        }
        if(rest && rest.length>0){
            return React.createElement(React.Fragment, null, [
                _x,
                renderClaimNumbersList(rest)
            ])
        }
        return _x;
    }
    return null;
  }

  const renderShowMoreGrid = (args) => {
    if (showMoreInfo) {
      let hashValue;
      if (documentSearchMetaData) {
        ({ hashValue } = documentSearchMetaData)
      }
      const { releaseInformation, Transactions, importedDate, receivedDate } = showMoreInfo;
      const { dateTimeReleased } = releaseInformation;

      const { documentKey, noaccess, trackingInfo } = record;
      const { trackingURL } = trackingInfo;
      let items = [];
      if (releaseInformation
        && releaseInformation.item
        && releaseInformation.item.constructor.name === "Object") {
        items.push(JSON.parse(JSON.stringify(releaseInformation.item)))
      } else if (releaseInformation
        && releaseInformation.item
        && releaseInformation.item.constructor.name === "Array") {
        items = [...releaseInformation.item]
      }
      const deliveryTypeCondition = (item) => {
        if (item && item.job && item.job.envelope
          && item.job.envelope.deliveryInfo
          && item.job.envelope.deliveryInfo.type === 'ACH') {
          return (
            <tr style={{ background: 'white' }}>
              <td>ACH</td>
              <td>MCDS</td>
              <td>
                <StyledLoadingContainer>
                  <>
                    {/* {isLoadingachFileDetailsData && achDetailsConfig?.documentKey===documentKey?<LoadingIndicator/>:

                    } */}
                    <AnchorInput
                      id={`ACH_${dateTimeReleased}_${documentKey}`}
                      type='button'
                      className='fileKeyLink'
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        const config = {
                          documentKey,
                          // fileId: '370',
                          // batchId: '00000321'
                        };
                        if (fetchFileDetailsFunc.current) fetchFileDetailsFunc.current(config)
                      }}
                    >{documentKey}</AnchorInput>
                  </>
                </StyledLoadingContainer>
              </td>
              <td>Epayment</td>
              <td>{dateTimeReleased ? moment(dateTimeReleased).format('MM/DD/yyyy hh:mm A') : ''}</td>
            </tr>
          )
        }
        return null;
      }
      const ePaymentCopyCondition = (item) => {
        if (item && item.job && item.job.envelope
          && item.job.envelope.deliveryInfo
          && item.job.envelope.deliveryInfo.trackingNumbers
          && item.job.envelope.deliveryInfo.trackingNumbers.ePaymentCopy === 'true') {
          if (item.job.envelope.deliveryInfo.trackingNumbers.ePaymentCopy === 'true') {
            if (item.job.envelope.deliveryInfo.trackingNumbers.estDates === 'true') {
              return (
                <tr style={{ background: 'white' }}>
                  <td colSpan="8">
                    <table>
                      <tr style={{ background: 'white' }}>
                        <td colSpan="3">Estimated Settlement Dates:</td>
                      </tr>
                      <tr style={{ background: 'white' }}>
                        <td>Payer Funding:</td>
                        <td colSpan="2">
                          <span>{item.job.envelope.deliveryInfo?.trackingNumbers?.PaymentStatus?.EstStatus?.ddDate || ""}</span>
                        </td>
                      </tr>
                      <tr style={{ background: 'white' }}>
                        <td>Payee Receipt:</td>
                        <td colSpan="2">
                          <span>{item.job.envelope.deliveryInfo?.trackingNumbers?.PaymentStatus?.EstStatus?.distDate || ""}</span>
                        </td>
                      </tr>
                      <tr style={{ background: 'white' }}>
                        <td>Date/Time</td>
                        <td>Payment ID</td>
                        <td>Payment Status</td>
                      </tr>
                      {item.job.envelope.deliveryInfo.trackingNumbers?.PaymentStatus?.EpayStatus?.map((eps) => (
                        <tr style={{ background: 'white' }}>
                          <td>{eps.date ? moment(eps.date).format('MM/DD/yyyy hh:mm A') : ''}</td>
                          <td>{eps.id}</td>
                          <td>{eps.status}</td>
                        </tr>
                      ))}
                    </table>
                  </td>
                </tr>
              )
            }
            return item.job.envelope.deliveryInfo?.trackingNumbers?.PaymentStatus?.EpayStatus?.map((eps) => (
              <tr style={{ background: 'white' }}>
                <td>{item.job.envelope.deliveryInfo.type}</td>
                <td>MCDS</td>
                <td>{eps.id}</td>
                <td>{eps.status}</td>
                <td>{eps.date ? moment(eps.date).format('MM/DD/yyyy hh:mm A') : ''}</td>
              </tr>
            ))
          }
          return null;
        }
        return null;
      }
      const trackingNumberCondition = (item) => {
        if (item && item.job && item.job.envelope && item.job.envelope.deliveryInfo
          && item?.job?.envelope?.deliveryInfo?.trackingNumbers
          && item?.job?.envelope?.deliveryInfo?.trackingNumbers?.constructor.name === "Array"
          && item?.job?.envelope?.deliveryInfo.trackingNumbers.length > 0) {
          item.job.envelope.deliveryInfo.trackingNumbers.map((trackingNumber) => {
            if (trackingNumber.bDataNotAvailable === 'true') {
              return (
                <tr className="row" style={{ background: 'white' }}>
                  <td>USPS</td>
                  <td>No Data</td>
                  <td>No Data</td>
                  <td>No Data</td>
                  <td>No Data</td>
                </tr>
              )
            }
            return null;
          })
        }
        if (item && item.job && item.job.envelope && item.job.envelope.deliveryInfo
          && item?.job?.envelope?.deliveryInfo?.trackingNumbers
          && item?.job?.envelope?.deliveryInfo?.trackingNumbers?.constructor.name === "Object") {
          if (item?.job?.envelope?.deliveryInfo?.trackingNumbers?.bDataNotAvailable === 'true') {
            return (
              <tr className="row" style={{ background: 'white' }}>
                <td>USPS</td>
                <td>No Data</td>
                <td>No Data</td>
                <td>No Data</td>
                <td>No Data</td>
              </tr>
            )
          }
          return null;
        }
        return null;
      }
      const OneCodeTrackingRecordCondition = (item) => {
        if (item && item.job && item.job.envelope && item.job.envelope.deliveryInfo
          && item.job.envelope.deliveryInfo.trackingNumbers) {
          let trackingNumbers = [];
          if (item.job.envelope.deliveryInfo.trackingNumbers.constructor.name === "Object") {
            trackingNumbers.push({ ...item.job.envelope.deliveryInfo.trackingNumbers })
          } else if (item.job.envelope.deliveryInfo.trackingNumbers.constructor.name === "Array") {
            trackingNumbers = item.job.envelope.deliveryInfo.trackingNumbers;
          }
          if (trackingNumbers[0].OneCodeTrackingRecord) {
            let OneCodeTrackingRecord = [];
            if (trackingNumbers[0].OneCodeTrackingRecord.constructor.name === "Object") {
              OneCodeTrackingRecord.push({ ...trackingNumbers[0].OneCodeTrackingRecord })
            } else if (trackingNumbers[0].OneCodeTrackingRecord.constructor.name === "Array") {
              OneCodeTrackingRecord = trackingNumbers[0].OneCodeTrackingRecord;
            }
            return OneCodeTrackingRecord.map((tr) => (
              <tr style={{ background: 'white' }}>
                {tr.FacilityName === 'DPAY-IXT' ? (
                  <td>Direct Pay</td>
                ) : (
                    <td>USPS</td>
                  )}
                <td>{tr.FacilityName}</td>
                <td>{tr.FacilityID}</td>
                <td>{tr.ScanType}</td>
                <td>{tr.ScanDateTime ? moment(tr.ScanDateTime).format('MM/DD/yyyy hh:mm A') : ''}</td>
              </tr>
            ))
          }
        }
        return null;
      }
      const InserterTrackingRecordCondition = (item) => {
        if (item && item.job && item.job.envelope && item.job.envelope.deliveryInfo
          && item.job.envelope.deliveryInfo.trackingNumbers) {
          let trackingNumbers = [];
          if (item.job.envelope.deliveryInfo.trackingNumbers.constructor.name === "Object") {
            trackingNumbers.push({ ...item.job.envelope.deliveryInfo.trackingNumbers })
          } else if (item.job.envelope.deliveryInfo.trackingNumbers.constructor.name === "Array") {
            trackingNumbers = item.job.envelope.deliveryInfo.trackingNumbers;
          }
          if (trackingNumbers[0].InserterTrackingRecord) {
            let InserterTrackingRecord = [];
            if (trackingNumbers[0].InserterTrackingRecord.constructor.name === "Object") {
              InserterTrackingRecord.push({ ...trackingNumbers[0].InserterTrackingRecord })
            } else if (trackingNumbers[0].InserterTrackingRecord.constructor.name === "Array") {
              InserterTrackingRecord = trackingNumbers[0].InserterTrackingRecord;
            }
            return InserterTrackingRecord.map((itr, itrindex) => {
              return (
                <tr style={{ background: 'white' }}>
                  <td>Insertion</td>
                  <td>{itr.InserterFacilityName}</td>
                  <td>{itr.InserterName}</td>
                  <td>{itr.InsScanType}</td>
                  <td>{itr.ScanDateTime ? moment(itr.ScanDateTime).format('MM/DD/yyyy hh:mm A') : ''}</td>
                </tr>
              )
              return null;
            })
          }
        }
        return null;
      }
      // (
      //   <>
      //     {[{ ...record }].map((document, dindex) => {
      //       if (dindex === index) {
      //         if (items[dindex] && items[dindex].job
      //           && items[dindex].job.envelope && items[dindex].job.envelope.deliveryInfo
      //           && items[dindex].job.envelope.deliveryInfo.trackingNumbers){
      //             let trackingNumbers=[];
      //             if () {

      //             }
      //           }
      //           && items[dindex].job.envelope.deliveryInfo.trackingNumbers.InserterTrackingRecord
      //           && items[dindex].job.envelope.deliveryInfo.trackingNumbers.InserterTrackingRecord.length > 0) {
      //           items[dindex].job.envelope.deliveryInfo.trackingNumbers.InserterTrackingRecord.map((itr, itrindex) => {
      //             if (itrindex === 0) {
      //               return (
      //                 <tr style={{ background: 'white' }}>
      //                   <td>Insertion</td>
      //                   <td>{itr.InserterFacilityName}</td>
      //                   <td>{itr.InserterName}</td>
      //                   <td>{itr.InsScanType}</td>
      //                   <td>{itr.ScanDateTime}</td>
      //                 </tr>
      //               )
      //             }
      //             return null;
      //           })
      //         }
      //         return null;
      //       }
      //       return null;
      //     })}
      //   </>
      // )
      const trackingURLCondition = (item) => {
        //CODEQL issues - incomplete url substring sanitization lines 528 533 537 541 554 558 562
        const protectedUrls = ['http://www.fedex.com', 'ups.com', 'usps.com']
        if (trackingURL) {
          return (
            <tr style={{ background: 'white' }}>
              <td>
                {trackingURL.indexOf(protectedUrls[0]) !== -1 ? (
                  <span>FedEx</span>
                ) : (
                    <>
                      {trackingURL.indexOf(protectedUrls[1]) !== -1 ? (
                        <span>USPS</span>
                      ) : (
                          <>
                            {trackingURL.indexOf(protectedUrls[2]) !== -1 ? (
                              <span>USPS</span>
                            ) : null}
                          </>
                        )}
                    </>
                  )}
              </td>
              <td>STL</td>
              <td>
                <a href={trackingURL.replace(/&gt;/g, ">").replace(/&lt;/g, "<").replace(/&amp;/g, "&").split("='")[1].split("'~]")[0]} target='_blank'>{trackingURL.split("='")[1].split("'~]")[1].split("[~")[0]}</a>
              </td>
              <td>
                {trackingURL.indexOf(protectedUrls[0]) !== -1 ? (
                  <span>FedEx</span>
                ) : (
                    <>
                      {trackingURL.indexOf(protectedUrls[1]) !== -1 ? (
                        <span>USPS</span>
                      ) : (
                          <>
                            {trackingURL.indexOf(protectedUrls[2]) !== -1 ? (
                              <span>USPS</span>
                            ) : null}
                          </>
                        )}
                    </>
                  )}
              </td>
              <td>
                {record.trackingInfo.shippingDate ? (
                  <span>{record.trackingInfo.shippingDate ? moment(record.trackingInfo.shippingDate).format('MM/DD/yyyy hh:mm A') : ''}</span>
                ) : (
                    <span>{item.job.manifest.maildate ? moment(item.job.manifest.maildate).format('MM/DD/yyyy hh:mm A') : ''}</span>
                  )}
              </td>
            </tr>
          )
        }
        return null;
      }
      const TransactionsCondition = () => {
        if (Transactions && Transactions.Transaction) {
          let ts=[];
          if(Transactions.Transaction.constructor.name === "Array"){
            ts=[...Transactions.Transaction]
          } else if(Transactions.Transaction.constructor.name === "Object"){
            ts.push({...Transactions.Transaction})
          }
          if(ts.length>0){
            return ts.map((tran) => (
              <tr style={{ background: 'white' }}>
                <td>Document Management</td>
                <td>Client Access System</td>
                <td>{tran.$.CUSERID}--{tran.$.CFULLNAME}</td>
                <td>
                  <>
                    {tran.$.CACTION.substr(0, 8) === 'RelImmed' ? (
                      <span>Released Immediately</span>
                    ) : (
                        <span>{tran.$.CACTION}</span>
                      )}
                    <span>{tran.$.CLEVEL}</span>
                  </>
                </td>
                <td>{tran.$.TENTERTIME ? moment(tran.$.TENTERTIME).format('MM/DD/yyyy hh:mm A') : ''}</td>
              </tr>
            ))
          }
        }
        return null;
      }
      const importedDateCondition = () => {
        if (importedDate) {
          return (
            <tr style={{ background: 'white' }}>
              <td>Data Processing</td>
              <td>MCDS</td>
              <td>
                <AnchorInput
                  id={`importedDate_${moment(importedDate).format('MM-DD-yyyy')}_${documentKey.substr(0, 17)}`}
                  type='button'
                  className='fileKeyLink'
                  onClick={() => {
                    dispatch(resetFileKey())
                    dispatch(setFileKey(documentKey.substr(0, 17)))
                    setisOpenFileReceiptModal(true)
                  }}
                >{documentKey.substr(0, 17)}</AnchorInput>
              </td>
              <td>File Import</td>
              <td>{importedDate ? moment(importedDate).format('MM/DD/yyyy hh:mm A') : ''}</td>
            </tr>
          )
        }
        return null;
      }
      const receivedDateCondition = () => {
        if (receivedDate) {
          return (
            <tr style={{ background: 'white' }}>
              <td>Data Processing</td>
              <td>MCDS</td>
              <td>
                <AnchorInput
                  id={`receivedDate_${moment(receivedDate).format('MM-DD-yyyy')}_${documentKey.substr(0, 17)}`}
                  type='button'
                  className='fileKeyLink'
                  onClick={() => {
                    dispatch(resetFileKey())
                    dispatch(setFileKey(documentKey.substr(0, 17)))
                    setisOpenFileReceiptModal(true)
                  }}
                >{documentKey.substr(0, 17)}</AnchorInput>
              </td>
              <td>File Received</td>
              <td>{receivedDate ? moment(receivedDate).format('MM/DD/yyyy hh:mm A') : ''}</td>
            </tr>
          )
        }
        return null;
      }
      const renderInnerTable = (item) => (
        <>
          {deliveryTypeCondition(item)}
          {ePaymentCopyCondition(item)}
          {trackingNumberCondition(item)}
          {OneCodeTrackingRecordCondition(item)}
          {InserterTrackingRecordCondition(item)}
          {trackingURLCondition(item)}
          {TransactionsCondition()}
          {renderInnerTable2()}
        </>
      )
      const renderInnerTable2 = () => (
        <>
          {importedDateCondition()}
          {receivedDateCondition()}
        </>
      )
      const renderInnerTableHeader = (arg) => (
        <>
          {arg === "showMore" ? (
            <tr style={{ background: 'white', borderBottom: '1px solid rgb(195, 197, 205)' }}>
              <td>Type</td>
              <td>Facility/Type</td>
              <td>ID</td>
              <td>Description</td>
              <td>Date/Time</td>
            </tr>
          ) : (
              <tr style={{ background: 'rgb(15, 15, 89)', color: 'white', fontWeight: 'bold', borderBottom: '1px solid rgb(195, 197, 205)' }}>
                <td>Type</td>
                <td>Facility/Type</td>
                <td>ID</td>
                <td>Description</td>
                <td>Date/Time</td>
              </tr>
            )}
        </>
      )
      const renderTrackingCondition = () => {
        if (items && items.length) {
          const trackingItem = items.find(e => (e?.job?.envelope?.copyNumber ?? "01") === record.trackingInfo.copyNumber);
          if (trackingItem) {
            return (
              <>
                {trackingNumberCondition(trackingItem)}
                {OneCodeTrackingRecordCondition(trackingItem)}
                {InserterTrackingRecordCondition(trackingItem)}
                {trackingURLCondition(trackingItem)}
              </>
            )
          }
        }
        return null;
      }
      const renderTrackingInfoContainer = () => {
        if (trackingInfoAvailable) {
          return (
            <StyledTable className="Expand expandable-table">
              <thead>
                {renderInnerTableHeader("trackPackage")}
              </thead>
              <tbody>
                {renderTrackingCondition()}
                {TransactionsCondition()}
                {renderInnerTable2()}
              </tbody>
            </StyledTable>
          )
        }
        return (
          <div className="marginLeft" style={{ fontSize: '13px' }}>
            <p>{trackingInfoText}</p>
          </div>
        )
      }
      const renderfunc = () => {
        if (!releaseInformation.item) {
          return (
            <tr>
              <td colSpan="8"></td>
              <td>
                <StyledTable2 className="Expand expandable-table">
                  <thead>
                    {renderInnerTableHeader("showMore")}
                  </thead>
                  <tbody>
                    {TransactionsCondition()}
                    {renderInnerTable2()}
                  </tbody>
                </StyledTable2>
              </td>
            </tr>
          )
        }
        if (items && items.length > 0) {
          return items.map((item, currIndex) => {
            const currCopyNumber = item?.job?.envelope?.copyNumber || "";
            return (
              <tr style={{ background: 'white', verticalAlign: 'top' }}>
                <td>
                  {item?.job?.$?.hasPdf === 'true' ? (
                    <>
                      {noaccess !== "*" ? (
                        <StyledLoadingContainer>
                          <>
                            {isLoadingPdfFile ? <LoadingIndicator /> :
                              <AnchorInput
                                id={`detailpane_copyNumber_${item.job.envelope.copyNumber}_${documentKey}`}
                                type='button'
                                className='fileKeyLink'
                                onClick={(e) => {
                                  const jobNumber = item.job.number;
                                  const envelopeNumber = item.job.envelope.number;
                                  const { startPage } = item.job.envelope;

                                  e.preventDefault();
                                  e.stopPropagation();
                                  const config = {
                                    jobNumber,
                                    envelopeNumber,
                                    startPage,
                                    hashValue,
                                    filename: 'claimSearch.pdf'
                                  };
                                  if (JSON.stringify(config) !== JSON.stringify(downloadTrackConfig)) {
                                    getPdfFile({ payload: { jn: jobNumber, en: envelopeNumber, sp: startPage, ex: hashValue }, config: JSON.parse(JSON.stringify(config)) });
                                  } else {
                                    refetchGetPdfFile()
                                  }
                                  // call this url
                                }}
                              >{item.job.envelope.copyNumber}</AnchorInput>
                            }
                          </>
                        </StyledLoadingContainer>
                      ) : (
                          <span>{item.job.envelope.copyNumber}</span>
                        )}
                    </>
                  ) : (
                      <span>{item.job.envelope.copyNumber}</span>
                    )}
                </td>
                <td>{item.paperStock || ""}</td>
                <td>{item.job.number}</td>
                <td>{item.job.envelope.number}</td>
                <td>{item.job.envelope.startPage}</td>
                <td>
                  {item && item.job && item.job.manifest ? (
                    <>
                      {item.job.manifest.printFacility === '' ? (
                        <span>STL</span>
                      ) : (
                          <span>{item.job.manifest.printFacility}</span>
                        )}
                    </>
                  ) : null}
                </td>
                <td>{item?.job?.manifest?.maildate || ""}</td>
                <td>{item?.job?.envelope?.deliveryInfo?.type || ""}</td>
                {((currIndex === 0) || (!items.slice(0, currIndex).find(i => (i?.job?.envelope?.copyNumber || "") === currCopyNumber))) ? (
                  // use currCopyNumber for rowspan of td
                  <td rowSpan={items.filter(i => (i?.job?.envelope?.copyNumber || "") === currCopyNumber).length}>
                    <StyledTable2 className="Expand expandable-table">
                      <thead>
                        {renderInnerTableHeader("showMore")}
                      </thead>
                      <tbody>
                        {renderInnerTable(item)}
                      </tbody>
                    </StyledTable2>
                  </td>
                ) : null}
                <td>
                  {(item?.job?.envelope?.eeobemail || "") !== '' ? (
                    <>
                      {(item?.job?.envelope?.eeobemail || "") !== 'none' ? (
                        <StyledLoadingContainer>
                          <>
                            {isLoadingEEOBEmail || isloadingeeobpdf ? <LoadingIndicator /> :
                              <AnchorInput
                                id={`viewlink_${item?.job?.envelope?.eeobemail}_${documentKey}`}
                                type='button'
                                className='fileKeyLink'
                                onClick={(e) => {

                                  e.preventDefault();
                                  e.stopPropagation();
                                  const config = {
                                    id: item?.job?.envelope?.eeobemail,
                                    documentKey
                                  };
                                  if(getEobConfig && getEobConfig.filename && config.documentKey == getEobConfig.documentKey)
                                    refetcheeobpdf();
                                  else if (JSON.stringify(config) !== JSON.stringify(getEobConfig)) {
                                    getEEOBEmail({ payload: { pstrEEOBID: item?.job?.envelope?.eeobemail }, config: JSON.parse(JSON.stringify(config)) });
                                  } else {
                                    refetcGetEEOBEmail()
                                  }
                                }}
                              >View</AnchorInput>
                            }
                          </>
                        </StyledLoadingContainer>
                      ) : null}
                    </>
                  ) : null}
                </td>
              </tr>
            )
          })
        }
        return null;
      }
      if (args === 'showMore')
        return (
          <StyledContainerTable className="Expand expandable-table">
            <thead>
              <tr style={{ background: '#0f0f59', color: 'white', fontWeight: 'bold' }}>
                <td className="althead">Copy</td>
                <td className="althead">Stock</td>
                <td className="althead">Job</td>
                <td className="althead">Envelope</td>
                <td className="althead">Start Page</td>
                <td className="althead">Facility</td>
                <td className="althead">Mail Date</td>
                <td className="althead">Delivery Type</td>
                <td className="althead">Tracking Information</td>
                <td className="althead">Email</td>
              </tr>
            </thead>
            <tbody>
              {renderfunc()}
            </tbody>
          </StyledContainerTable>
        )
      return (
        <>
          {renderTrackingInfoContainer()}
        </>
      )
    }
    return null;
  }
  const renderFooterPanel = (noaccess, suppressPDF) => {
    if (showMoreInfo) {
      const { displayCaretMsg, releaseInformation } = showMoreInfo;
      let scheduled;
      if (releaseInformation && releaseInformation.item) {
        let item;
        if (releaseInformation.item.constructor.name === "Object") {
          item = { ...releaseInformation.item };
        } else if (releaseInformation.item.constructor.name === "Array") {
          item = releaseInformation.item[0];
        }
        if (item && item.job) {
          const { manifest } = item.job;
          if (manifest) {
            ({ scheduled } = manifest);
          }
        }
      }
      const supressPDFCondition = () => {
        if (suppressPDF === '1') {
          return (
            <table style={{ fontSize: '13px' }}>
              <tr>
                <td colSpan="8" className="althead" align="center">Display of PDF is suppressed for external users</td>
              </tr>
            </table>
          )
        }
        return null;
      }
      const noaccessCondition = () => {
        if (noaccess === '*') {
          return (
            <table style={{ fontSize: '13px' }}>
              <tr><td className="tinytext">*  Your Webgroup does not have access to the PDF for this document.</td></tr>
            </table>
          )
        }
        return null;
      }
      const scheduledCondition = () => {
        if (scheduled === '*') {
          return (
            <table style={{ fontSize: '13px' }}>
              <tr><td colSpan="6" className="tinytext">**  This represents a scheduled mail date.  Once the document has been mailed, the actual mail date will display.</td></tr>
            </table>
          )
        }
        return null;
      }
      const displayCaretMsgCondition = () => {
        if (displayCaretMsg) {
          return (
            <table style={{ fontSize: '13px' }}>
              <tr><td colSpan="6" className="tinytext">^ This represents the anticipated print facility. Once the document has been printed, the actual print facility will display.</td></tr>
            </table>
          )
        }
        return null;
      }

      return (
        <>
          {supressPDFCondition()}
          {noaccessCondition()}
          {scheduledCondition()}
          {displayCaretMsgCondition()}
        </>
      )
    }
    return null;
  }
  const renderGenType835 = useMemo(() => {
    if (showMoreInfo && showMoreInfo.GENTYP835) {
      if (showMoreInfo.GENTYP835.constructor.name === "String") {
        return showMoreInfo.GENTYP835
      } else if (showMoreInfo.GENTYP835.constructor.name === "Array")
        return showMoreInfo.GENTYP835[0]
    }
    return null;
  }, [showMoreInfo])
  const renderShowMoreSection = useCallback((args) => {
    let claimNumbers = [];
    if (showMoreInfo && showMoreInfo.claimNumbers && showMoreInfo.claimNumbers.claimNumber) {
      if (showMoreInfo.claimNumbers.claimNumber.constructor.name === "String") {
        claimNumbers.push(showMoreInfo.claimNumbers.claimNumber)
      } else if (showMoreInfo.claimNumbers.claimNumber.constructor.name === "Array") {
        claimNumbers = JSON.parse(JSON.stringify(showMoreInfo.claimNumbers.claimNumber))
      }
    }
    let noaccess;
    let suppressPDF;
    if (record) {
      const { $ } = record;
      if ($) {
        suppressPDF = $.suppressPDF;
        noaccess = record.noaccess;
      }
      return (
        <>
          {args === "showMore" ?
            //
            <div className="marginLeft" style={{ minWidth: detailAnimationState === detailPaneAnimStates.FULL_EXPAND ? '60%' : '120%' }}>
              <div style={{ marginBottom: '5px', border: '1px solid rgb(218, 216, 215)' }}>
                {suppressPDF === '1' ? (
                  <table>
                    <tr className="row">
                      <td colSpan="8" className="value"><font className="color-black">
                        Display of PDF is suppressed for external users for document key:
                        <span>{record.documentKey}</span></font></td>
                    </tr>
                  </table>
                ) : null}
                <div className="marginLeft">
                  <div className="displayFlex" style={{ background: 'lightgray', marginLeft: '-10px' }}>
                    <p style={{ marginLeft: '10px', fontWeight: 'bold' }}>Details for Document <span style={{ marginLeft: '5px' }}>{record.documentKey}</span></p>
                  </div>
                  <div className="displayFlex">
                    <label style={{ fontWeight: 'bold' }}>Document Type: </label>
                    <div className="marginLeft">
                      {showMoreInfo ? showMoreInfo.relationCodeDescription : null}
                    </div>
                  </div>
                  <div className="displayFlex">
                    <label style={{ fontWeight: 'bold' }}>Recipient Type: </label>
                    <div className="marginLeft">
                      {showMoreInfo ? showMoreInfo.recipientTypeDescription : null}
                    </div>
                  </div>
                  <div className="displayFlex">
                    <label style={{ fontWeight: 'bold' }}>Payment Identifier: </label>
                    <div className="marginLeft">
                      {record.CardTokenID}
                    </div>
                  </div>
                  {showMoreInfo?.bcheckclr === "1" ? 
                  (<div>
                  <div className="displayFlex">
                    <label style={{ fontWeight: 'bold' }}>Payment Status: </label>
                    <div className="marginLeft">
                      {showMoreInfo?.paymentStatus||null}
                    </div>
                  </div>
                  <div className="displayFlex">
                    <label style={{ fontWeight: 'bold' }}>Payment Status Date: </label>
                    <div className="marginLeft">
                      {showMoreInfo?.payStatusDate||null}
                    </div>
                  </div>
                  </div>): null}
                  <div className="displayFlex">
                    <label style={{ fontWeight: 'bold' }}>Check Number: </label>
                    <div className="marginLeft">
                      {showMoreInfo ? showMoreInfo.checkNumber : null}
                    </div>
                  </div>
                  <div className="displayFlex">
                    <label style={{ fontWeight: 'bold' }}>Payment Amount: </label>
                    <div className="marginLeft">
                      {showMoreInfo ? showMoreInfo.checkAmount : null}
                    </div>
                  </div>
                  {/* {bRelatedKeys==="True"?(
                            <div className="displayFlex">
                              <label style={{fontWeight:'bold'}}>Associated Keys:</label>
                              <div className="marginLeft">
                                {relKeys}
                              </div>
                            </div>
                          ):null} */}
                  <div className="displayFlex">
                    <label style={{ fontWeight: 'bold' }}>ERA/835 Generation:</label>
                    <div className="marginLeft">
                      {renderGenType835}
                    </div>
                  </div>

                  <div className="displayFlex">
                    <label style={{ fontWeight: 'bold' }}>Claim Number: </label>
                    {claimNumbers && claimNumbers.length > 0 ? (
                      <div style={{ display: 'block' }}>
                        {claimNumbers.length>1000?<p><b>The result displays 1000 claims of total {claimNumbers.length} claims.</b></p>:null}
                        <div style={{overflow:'auto', maxHeight:'400px'}}>
                          <div style={{marginTop:'5px', marginBottom:'5px'}}>{renderClaimNumbersList(claimNumbers.length>1000?claimNumbers.slice(0,1000):claimNumbers)}</div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="displayFlex">
                    <label> Copy Information : </label>
                  </div>
                </div>
                {renderShowMoreGrid(args)}
                {renderFooterPanel(noaccess, suppressPDF)}
              </div>
            </div>
            : (
              <>
                {args === "trackPackage" ? (
                  <>
                    <div style={{
                      width: detailAnimationState >= detailPaneAnimStates.FULL_EXPAND ?
                        '60%' : '95%', border: '1px solid rgb(218, 216, 215)'
                    }}>
                      <div className="marginLeft">
                        <div className="displayFlex" style={{ background: 'lightgray', marginLeft: '-10px' }}>
                          <p style={{ marginLeft: '10px', fontWeight: 'bold' }}>Tracking Info for Document <span style={{ marginLeft: '5px' }}>{record.documentKey}</span></p>
                        </div>
                      </div>
                      {renderShowMoreGrid(args)}
                    </div>
                  </>
                ) : null}
              </>
            )}
        </>
      )
    }
    return null;
  }, [record, index, documentSearchMetaData, showMoreInfo, currentShowMoreTarget,
    detailAnimationState, trackingInfoAvailable, trackingInfoText, downloadTrackConfig, getEobConfig,
    isLoadingPdfFile, isLoadingEEOBEmail, isloadingeeobpdf]);

  const renderTabContainer = useMemo(() => {
    if (detailAnimationState > detailPaneAnimStates.COLLAPSED) {
      return <div style={{ overflow: 'auto' }}><TabContainer
        className="tab_container_classname"
        contentClass="tab_content_classname"
        dataTestId="test-tabInitial"
        domID="tab-container-initial"
        initialTab={currentShowMoreTarget === "showMore" ? 0 : (currentShowMoreTarget === "trackPackage" ? 1 : null)}
        onTabChange={() => false}
        tabs={[
          {
            domID: 'id-1',
            label: 'Details',
            tabContent: <>{renderShowMoreSection("showMore")}</>
          },
          {
            domID: 'id-2',
            label: 'Tracking Info',
            tabContent: <>{renderShowMoreSection("trackPackage")}</>
          },
        ]}
      /></div>
    }
    return null;
  }, [record, index, documentSearchMetaData, showMoreInfo, currentShowMoreTarget,
    detailAnimationState, trackingInfoAvailable, trackingInfoText, downloadTrackConfig, getEobConfig
    , isLoadingPdfFile, isLoadingEEOBEmail, isloadingeeobpdf])

  const renderFileRecipietModal = useMemo(() => (
    <Modal isOpen={isOpenFileReceiptModal}>
      <StyledModalWrapper>
        <StyledModalHeaderWrapper>
          <ModalHeader
            title="File Receipt"
            onClose={() => onCloseFileReceiptModal()}
          />
        </StyledModalHeaderWrapper>
        <ModalBody>
          {isOpenFileReceiptModal ? (
            <FileReceiptManagerDetailComponent
              filekey={filekey}
              height='58vh'
              overflow='auto'
            />
          ) : null}
        </ModalBody>
      </StyledModalWrapper>
    </Modal>
  ), [isOpenFileReceiptModal, filekey])

  const renderEEOBModal = useMemo(() => (
    <Modal isOpen={isOpenEEOBModal}>
      <StyledModalWrapper>
        <StyledModalHeaderWrapper>
          <ModalHeader
            title="EEOB MAIL"
            onClose={() => onCloseEEOBModal()}
          />
        </StyledModalHeaderWrapper>
        <ModalBody>
          {isOpenEEOBModal ? (
            <div className="marginLeft">
              <div style={{ marginRight: '10px' }}
                dangerouslySetInnerHTML={{
                  __html: EEOBEmail.resp.indexOf('border=5') !== -1 ?
                    EEOBEmail.resp.replace('border=5', 'border=1') : EEOBEmail.resp
                }}></div>
              {/* <p>{EEOBEmail.resp}</p> */}
            </div>
          ) : null}
        </ModalBody>
      </StyledModalWrapper>
    </Modal>
  ), [isOpenEEOBModal, EEOBEmail])

  return (
    <>
      <DetailPaneHeader
        title="Document Manager: Search Details"
        layoutControls={[
          expandCollapseDetailPaneButtonConfig,
          closeDetailPaneButtonConfig
        ]}
      />
      <>
        {isLoadingShowMoreInfo ? (
          // replace with a better loading component
          <div style={{ marginLeft: '50%' }}><LoadingComponent /></div>
        ) : isErrorShowMoreInfo ? (
          // replace with a better error component
          <ErrorPage
            // this prop isn't real but is an example
            error={errorShowMoreINfo}
          />
        ) : (
              <>
                {renderTabContainer}
              </>
            )
        }
        {renderFileRecipietModal}
        {renderEEOBModal}
        <ACHTransmissionHistoryFileDetails
          mode="batchList" // fileDetails // batchList
          getFetchDetailsFunc={(funcRef) => {
            if (funcRef) fetchFileDetailsFunc.current = funcRef
          }}
        />
      </>
    </>
  )
}

export default withAxiosCancellation(DocumentSearchResultsDetailPage)
