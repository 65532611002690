export const gridConfig = {
  columns: new Set([
    {
      dataName: 'ruleTypeName',
      text: 'Rule Types',
      sortable: true,
    },
    {
      dataName: 'associatedTestRule',
      text: 'Associated Test Rule',
      sortable: true,
    },
    {
      dataName: 'associatedLiveRule',
      text: 'Associated Live Rule',
      sortable: true,
    },
    {
      dataName: 'trialed',
      text: 'Trialed',
      sortable: true,
    },
  ]),
  selectionKey: 'ruleTypeName',
  domId: 'commit-tab-grid',
  onSortTableColumn: () => {},
  supportSelection: true,
  initialSortingKey: 'ruleTypeName|SORT_ASCENDING',
};
