import styled from 'styled-components';
import { UnstyledButton } from './UnstyledButton';
import {
  neutral10,
  neutral15,
} from 'ui-core/dist/BasePalette';
import TextStyles from 'ui-core/dist/TextStyles';
import padding from '../helpers/padding';

const primary100 = '#4CC6FF';
const primary30 = '#CAF0FF';

export default styled(UnstyledButton)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 !important; /* stylelint-disable-line declaration-no-important */
  padding: 8px ${padding.navigationPaneHeader.left}px 8px
    ${(props) => props.paddingLeft}px;
  width: 100%;
  ${TextStyles.small};
  color: ${TextStyles.textColors.secondary};
  height: ${(props) => (props.isTextWrapping ? 'auto' : '36px')};
  min-height: 36px;
  cursor: pointer;

  > span {
    cursor: pointer;
    align-items: center;
  }

  &:hover {
    cursor: ${(props) => (props.isLink ? 'pointer' : 'text')};
    background: ${neutral10};
  }

  &:active {
    background: ${neutral15};
  }

  &:focus-visible {
    outline: none;
    box-shadow: inset 0 0 0 1px ${primary100};
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }

  &.selected {
    background: ${primary30};
  }

  .arrow {
    transform-style: preserve-3d;
    transition: transform 0.3s ease 0s, -webkit-transform 0.3s ease 0s,
      transform 300ms;
    transform: rotateX(0deg) rotateY(0deg) rotateZ(-90deg);
    margin-right: 8px;
    flex-shrink: 0;
    cursor: pointer;
  }

  .arrow.expanded {
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  }
`;
