/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import styled from 'styled-components/dist/styled-components';
import { useDispatch } from 'react-redux';
import { 
  Grid,
  Button,
  useLayout,
  Checkbox,
  Input,
  SelectDropdown
} from 'ui-core/dist';
import { LoadingPage } from '../../../components';
import GridPaginationWrapper from '../../../components/GridPaginationWrapper';
import ErrorPage from '../../ErrorPage';
import ListPaneHeaderWrapper from '../../../components/ErrorDisplaySection/ListPaneHeaderWrapper';
import { CLIENT_EMAIL_GRID } from '../Modules/constants';
import { setManageAddressRecord, resetManageAddressRecord } from '../../AddressManager/Modules/action';
import { useGetAll } from "../../../api/useClientEmail";
import useSortableGrid from '../../../hooks/useSortableGrid';

const StyledGridWrapper = styled.div`
&.GridWrapper #grid-wrapper-test-id{
  overflow:initial !important;
}
`
const SELECT_RECORD_OPTIONS = [{label:'Active',value: true},{label: 'Inactive',value: false}]

const ClientEmailMain = () => {
  const {
    expandCollapseListPaneButtonConfig,
    openDetailPane,
    closeDetailPane
  } = useLayout()
  const dispatch = useDispatch()
  const [count, setcount] = useState(0)
  const [selectedRecord,setSelectedRecord]=useState('')
  const [totalRecords, setTotalRecords] = useState(0);
  const [records, setRecords] =useState([])
  const [rowsPerPage,setRowsPerPage]=useState(10);
  const [pageIndex,setPageIndex]=useState(0);
  const [status,setStatus] = useState({label: 'Active', value: true})
  const [searchValue, setSearchValue] = useState('');
  const [exactMatch, setExactMatch] = useState(false);
  const [resetControls, setResetControls] = useState(false);
  
  const {data: clientEmailRecords, postData: getAll, isError, isLoading, error} = useGetAll()
  
  const getParameters = useCallback((args) =>{
    let sortcolumn; let sd; let pi;
    if (args) {
      ({sortcolumn,sd,pi}=args);
    }
    return `
    <![CDATA[
        <filter>
          <client-table>ClientEmailAddresses</client-table>
          <starting-record>${pi===undefined || pi===null?pageIndex: pi}</starting-record>
          <maximum-records>${rowsPerPage}</maximum-records>
          <status-filter>${!status.value ? 1 : 0}</status-filter>
          <sort-field>${sortcolumn===undefined || sortcolumn===null?sortConfig.sortDataName: sortcolumn}</sort-field>
          <sort-descending>${(sd===undefined || sd===null?sortConfig.sortDescending: sd) === "false" ? 0 : 1}</sort-descending>
        </filter>
      ]]>`
  },[pageIndex, rowsPerPage, status, sortConfig])

  useEffect(() => {
    const param = getParameters();
    getAll({ param, count })
    setcount(count + 1)
    closeDetailPane();
  },[]);

  const fetchData = useCallback((args) => {
    getAll({ 
      param: getParameters(args),
      count 
    })
    setcount(count + 1)
    closeDetailPane();
  }, [rowsPerPage, pageIndex, status, searchValue, exactMatch, sortConfig, count])
  
  const [sortConfig, getArgumentsAndSortGrid]=useSortableGrid(fetchData,"CEEOBRULE","false");

  useEffect(() => {  

    if(clientEmailRecords){
      const {collection}=clientEmailRecords;
      if (collection) {
        setTotalRecords(collection.$?.totalRecords)
        const clientEmailAddress = collection['client-email-address'];
        if (clientEmailAddress) {
          setRecords(clientEmailAddress)
        } else {
          setRecords([])
        }
      } else {
        setTotalRecords(0)
        setRecords([])
      }
    }
  }, [clientEmailRecords])
  
  const refetchDeliveryRecord = () => {
    fetchData()
    setcount(count + 1)
    closeDetailPane();
    setResetControls(false)
  }

  useEffect(()=>{
    if(resetControls){
      refetchDeliveryRecord();
    }
  }, [resetControls]);

  const resetControlsFunc = () => {
    setSearchValue('')
    setExactMatch(false)
    setPageIndex(0)
    setRowsPerPage(10)
    setStatus({label:'Active',value: true})    
    setSelectedRecord({})
    setResetControls(true)
  }

  const onShowMoreClick = useCallback((record) => {
    dispatch(setManageAddressRecord({ ManageAddressRecord: JSON.parse(JSON.stringify(record)), sortOrder: sortConfig.sortDescending
      , mode: 'Edit', refetchManageAddressRecord: refetchDeliveryRecord, resetControls: resetControlsFunc }));
    setSelectedRecord(JSON.parse(JSON.stringify(record)))
    openDetailPane();
  },[sortConfig]);

  const onAddDeliveryAddress = () => {
    setSelectedRecord({})
    dispatch(setManageAddressRecord({ ManageAddressRecord: JSON.parse(JSON.stringify({})), sortOrder: sortConfig.sortDescending
      , mode: 'Add', refetchManageAddressRecord: refetchDeliveryRecord, resetControls: resetControlsFunc }));
    openDetailPane();
  }
  const renderFirstFilter=()=>(
    <div style={{
      width: '200px',
      marginLeft: '5px',
      marginRight: '5px'
    }}>
      <SelectDropdown
        domID="defer_state_input"
        label=""
        options={SELECT_RECORD_OPTIONS}
        size="small"
        initialValue={status}
        onChange={(e)=>setStatus(e)}
      />
    </div>
  );
  const renderGrid = useMemo(() => {
    if (records) {
      let data = []
      if(records.constructor.name === 'Array')
        data = JSON.parse(JSON.stringify(records))
      else 
        data = JSON.parse(JSON.stringify([records]))
      if(data){
        data = data.map(x=>({
          ...x,
          BSUPPRESS: x.BSUPPRESS === '1' ? 'Yes' : 'No',
          BBADEMAIL: x.BBADEMAIL === '1' ? 'Yes' : 'No'
        }))
      }
      return (
        <>
          <StyledGridWrapper className='GridWrapper'>
            <GridPaginationWrapper
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={setRowsPerPage}
              pageIndex={pageIndex}
              onChangePageIndex={setPageIndex}
              onShowButtonCLick={fetchData}
              totalRecords={totalRecords}
              defaultRowsPerPage={10}
              FirstFilter={renderFirstFilter}
            />
            <Grid
              {...CLIENT_EMAIL_GRID}
              records={data || []}
              selectionKey= "CEEOBRULE"
              onClickThrough={(e, rec) => {
                e.preventDefault();
                e.stopPropagation();
                if (rec) {
                  const record = (data || []).find(x => x.CEEOBRULE === rec.CEEOBRULE)
                  if (onShowMoreClick) {
                    dispatch(resetManageAddressRecord())
                    return onShowMoreClick({...record, data});
                  }
                  return false;
                }
                return false;
              }}
              focusedItem={selectedRecord}            
              initialSortingKey={`${sortConfig.sortDataName}|${sortConfig.sortDescending==="true"?'SORT_DESCENDING':'SORT_ASCENDING'}`}
              onSortGridColumn={(e,state)=>getArgumentsAndSortGrid(state)}
            />
          </StyledGridWrapper>
        </>
      )
    }
    return null;
  }, [
    records,
    selectedRecord,
    sortConfig,
    searchValue,
    exactMatch,
    rowsPerPage,
    pageIndex,
    totalRecords,
    status
  ])

  return (
    <>
      <ListPaneHeaderWrapper
        title="Client Email Editor"
        layoutControls={[expandCollapseListPaneButtonConfig]}
      />
      {isLoading ? (
        // replace with a better loading component
        <LoadingPage />
      ) : isError ? (
        // replace with a better error component
        <ErrorPage
          // this prop isn't real but is an example
          error={error}
        />
      ) : (
        <>
          <div className="marginLeft">
            <table border="0" cellSpacing="1" cellPadding="3" width="100%">
              <tr>
                <td>
                  <div className="displayFlex" style={{ paddingLeft: '23px'}}>
                    <Button
                      id='ContactEditorAdd'
                      size="small"
                      name="Add Client Email"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onAddDeliveryAddress()
                      }}
                    />
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  {renderGrid}
                </td>
              </tr>
            </table>
          </div>
          {/* {renderMainGrid} */}
        </>
      )}
    </>
  )
};

export default ClientEmailMain