import React, { useState } from 'react'
import {
  Modal,
  ModalHeader,
  Button,
  ButtonGroup,
  ModalBody,
  ModalFooter
} from 'ui-core/dist'

const DeliveryReportModal = ({
  data,
  isAccessOpen,
  onAccessClose,
}) => {

  const onClose = () => {
    onAccessClose(false)
  }

  return (
    <Modal isOpen={isAccessOpen} className="delivery-report-modal">
      <ModalHeader
        title="Five Digit ZIP Codes"
        onClose={onClose}
      />
      <ModalBody>
        <div className="delivery-report-modal__contents">
          <>
            {(
              <>
                <p>{data && data.toString() === '0' ? 'No Records found' : data}</p>
              </>
            )}
          </>
        </div>
      </ModalBody>
      <ModalFooter>
        <ButtonGroup className="delivery-report-modal__button-group">
          <>
            <Button
              domID="DeliveryReportModalBack"
              name='Close'
              onClick={onClose}
              size="small"
              buttonType="standard"
            />
          </>
        </ButtonGroup>
      </ModalFooter>
    </Modal >
  )
}

export default DeliveryReportModal