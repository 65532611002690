export const MODAL_ANIMATION = 300;
export const LEFT_STRIP_EXPAND_COLLAPSE = 300;
export const LEFT_STRIP_FADE = 200;
export const DETAIL_PANE_EXPAND_COLLAPSE = 500;
export const DETAIL_PANE_FADE = 200;
export const GRID_ITEM_MENU_FADE = 200;

export default {
  MODAL_ANIMATION,
  LEFT_STRIP_EXPAND_COLLAPSE,
  LEFT_STRIP_FADE,
  DETAIL_PANE_EXPAND_COLLAPSE,
  DETAIL_PANE_FADE,
  GRID_ITEM_MENU_FADE,
};
