export const setRecipientTypeTranslationRecord=(payload)=>({
  type:'SET_RECIPIENT_TYPE_TRANSLATION_RECORD',
  payload
})

export const resetRecipientTypeTranslationRecord=()=>({
  type:'RESET_RECIPIENT_TYPE_TRANSLATION_RECORD'
})

export const setAddressExclusionSelectedRecord=(payload)=>({
  type:'SET_ADDRESS_EXCLUSION_SELECTED_RECORD',
  payload
})

export const resetAddressExclusionSelectedRecord=()=>({
  type:'RESET_ADDRESS_EXCLUSION_SELECTED_RECORD'
})

export const setClientContactEditorRecord=(payload)=>({
  type:'SET_CLIENT_CONTACT_EDITOR_RECORD',
  payload
})

export const resetClientContactEditorRecord=()=>({
  type:'RESET_CLIENT_CONTACT_EDITOR_RECORD'
})

export const setPAFEditorRecord=(payload)=>({
  type:'SET_PAF_EDITOR_RECORD',
  payload
})

export const resetPAFEditorRecord=()=>({
  type:'RESET_PAF_EDITOR_RECORD'
})

export const setTerroristExclusionRecord=(payload)=>({
  type:'SET_TERRORIST_EXCLUSION_SELECTED_RECORD',
  payload
})

export const resetTerroristExclusionSelectedRecord=()=>({
  type:'RESET_TERRORIST_EXCLUSION_SELECTED_RECORD'
})

export const getXmlDocumentTypeSaveUpdate=({currentdocumentType, currentnormalizedDocumentType, currentwebDocumentTypeDesc, isActive})=>{
  let xml=`<![CDATA[<document-type-translation>`;
  xml=`${xml}<documentTypeTrans>${currentdocumentType}</documentTypeTrans>`;
  xml=`${xml}<documentType>${currentnormalizedDocumentType}</documentType>`
  xml=`${xml}<documentTypeDesc>${currentwebDocumentTypeDesc}</documentTypeDesc>`
  xml=`${xml}<isActive>${isActive||'False'}</isActive>`
  xml=`${xml}</document-type-translation>]]>`
  return xml;
}

export const getXmlClaimTypeSaveUpdate=({currentclaimType, currentnormalizedRecipientType, currentwebRecipientTypeDesc, isActive})=>{
  let xml=`<![CDATA[<claim-type-translation>`;
  xml=`${xml}<claimTypeTrans>${currentclaimType}</claimTypeTrans>`;
  xml=`${xml}<claimType>${currentnormalizedRecipientType}</claimType>`
  xml=`${xml}<claimTypeDesc>${currentwebRecipientTypeDesc}</claimTypeDesc>`
  xml=`${xml}<isActive>${isActive||'False'}</isActive>`
  xml=`${xml}</claim-type-translation>]]>`
  return xml;
}