/**
 * The rule details modal props mapper maps the needed props for the specific modal itself
 * You will need to make sure that the key value is the same as the selectedRuleTypeId being used for that modal
 * The props are just the names in string format put into the array to grab from all of the props in the modal itself
 */
export const RULE_DETAILS_MODAL_PROPS_MAPPER = {
  CSM: [
    'isPostingRuleDetail',
    'setIsShowingCustomerServiceMessageModal',
    'isCloningRuleDetail',
    'postRuleDetails',
    'putRuleDetails',
    'detailElements',
    'selectedRecord',
    'selectedRuleDetail',
    'selectedRuleRecord',
    'selectedRuleTypeId',
    'loading',
    'actionType',
    'setIsCloningRuleDetail',
    'setIsPostingRuleDetail',
    'setIsReadOnlyRuleDetail',
    'isReadOnlyRuleDetail',
  ],
  PAR: [
    'onCancel',
    'setIsReadOnlyRuleDetail',
    'isReadOnlyRuleDetail',
    'actionType',
    'selectedRuleDetail',
    'detailElements',
    'isPostingRuleDetail',
    'isCloningRuleDetail',
    'setIsShowingProductionArrangementModal',
    'postRuleDetails',
    'putRuleDetails',
    'selectedRecord',
    'selectedRuleRecord',
    'selectedRuleTypeId',
    'loading',
    'setIsCloningRuleDetail',
    'setIsPostingRuleDetail',
  ],
  DPX: [
    'detailElements',
    'loading',
    'selectedRuleRecord',
    'selectedRuleDetail',
    'setIsCloningRuleDetail',
    'setIsPostingRuleDetail',
    'setIsShowingDuplexRuleModal',
    'isShowingDuplexRuleModal',
    'isCloningRuleDetail',
    'isPostingRuleDetail',
    'postRuleDetails',
    'putRuleDetails',
    'actionType',
    'selectedRecord',
    'selectedRuleTypeId',
    'setIsReadOnlyRuleDetail',
    'isReadOnlyRuleDetail',
  ],
  SSN: [
    'detailElements',
    'loading',
    'selectedRuleRecord',
    'selectedRuleDetail',
    'setIsCloningRuleDetail',
    'setIsPostingRuleDetail',
    'setIsShowingMemberIdModal',
    'isShowingMemberIdModal',
    'isCloningRuleDetail',
    'isPostingRuleDetail',
    'postRuleDetails',
    'putRuleDetails',
    'actionType',
    'selectedRecord',
    'selectedRuleTypeId',
    'setIsReadOnlyRuleDetail',
    'isReadOnlyRuleDetail',
  ],
  GEN: [
    'setIsCloningRuleDetail',
    'setIsPostingRuleDetail',
    'setIsShowingGeneralMessageModal',
    'isCloningRuleDetail',
    'isPostingRuleDetail',
    'postRuleDetails',
    'putRuleDetails',
    'selectedRuleDetail',
    'selectedRuleRecord',
    'selectedRuleTypeId',
    'selectedRecord',
    'loading',
    'detailElements',
    'isShowingGeneralMessageModal',
    'actionType',
    'setIsReadOnlyRuleDetail',
    'isReadOnlyRuleDetail',
  ],
  ERI: [
    'loading',
    'selectedRuleRecord',
    'selectedRecord',
    'detailElements',
    'selectedRuleDetail',
    'actionType',
    'setIsCloningRuleDetail',
    'setIsPostingRuleDetail',
    'setIsShowingErisaMessageModal',
    'isCloningRuleDetail',
    'isShowingErisaMessageModal',
    'isPostingRuleDetail',
    'postRuleDetails',
    'putRuleDetails',
    'selectedRuleTypeId',
    'setIsReadOnlyRuleDetail',
    'isReadOnlyRuleDetail',
  ],
  CHT: [
    'detailElements',
    'loading',
    'onCancel',
    'selectedRuleRecord',
    'selectedRuleDetail',
    'setIsCloningRuleDetail',
    'setIsPostingRuleDetail',
    'setIsReadOnlyRuleDetail',
    'setIsShowingCheckTextRuleModal',
    'isShowingCheckTextRuleModal',
    'isCloningRuleDetail',
    'isPostingRuleDetail',
    'isReadOnlyRuleDetail',
    'postRuleDetails',
    'putRuleDetails',
    'actionType',
    'selectedRecord',
    'selectedRuleTypeId',
    'setIsShowingImageLibraryModal',
    'isShowingImageLibraryModal',
    'setIsShowingImageUploadModal',
    'isShowingImageUploadModal',
    'postImageUpload',
    'getRuleImages',
    'ruleImages',
    'getRuleImagesCount',
    'ruleImagesCount',
    'resetRuleImagesData',
    'convertImageUpload',
    'convertedImageUploadData',
    'convertedImageUploadError',
    'postImageUploadSuccessId',
    'postCommitImage',
    'setIsShowingCommitImageModal',
    'isShowingCommitImageModal',
    'postImageCommitSuccessId',
    'postCommitImageTriggerCancel'
  ],
  PIO: [
    'setIsCloningRuleDetail',
    'setIsPostingRuleDetail',
    'isShowingProviderInsuredOptionMessageModal',
    'isCloningRuleDetail',
    'isPostingRuleDetail',
    'postRuleDetails',
    'putRuleDetails',
    'selectedRuleDetail',
    'selectedRuleRecord',
    'selectedRuleTypeId',
    'selectedRecord',
    'loading',
    'detailElements',
    'setIsShowingProviderInsuredOptionMessageModal',
    'actionType',
    'setIsReadOnlyRuleDetail',
    'isReadOnlyRuleDetail',
  ],
  SIG: [
    'detailElements',
    'loading',
    'selectedRuleRecord',
    'selectedRuleDetail',
    'setIsCloningRuleDetail',
    'setIsPostingRuleDetail',
    'setIsShowingSignatureRuleModal',
    'isShowingSignatureRuleModal',
    'isCloningRuleDetail',
    'isPostingRuleDetail',
    'postRuleDetails',
    'putRuleDetails',
    'actionType',
    'selectedRecord',
    'selectedRuleTypeId',
    'setIsShowingImageLibraryModal',
    'isShowingImageLibraryModal',
    'setIsShowingImageUploadModal',
    'isShowingImageUploadModal',
    'postImageUpload',
    'getRuleImages',
    'ruleImages',
    'getRuleImagesCount',
    'ruleImagesCount',
    'resetRuleImagesData',
    'convertImageUpload',
    'convertedImageUploadData',
    'convertedImageUploadError',
    'postImageUploadSuccessId',
    'postCommitImage',
    'setIsShowingCommitImageModal',
    'isShowingCommitImageModal',
    'postImageCommitSuccessId',
    'postCommitImageTriggerCancel',
    'setIsReadOnlyRuleDetail',
    'isReadOnlyRuleDetail',
  ],
  TAX: [
    'detailElements',
    'loading',
    'selectedRuleRecord',
    'selectedRuleDetail',
    'setIsCloningRuleDetail',
    'setIsPostingRuleDetail',
    'setIsShowingTaxIdArrangementRuleModal',
    'isShowingTaxIdArrangementRuleModal',
    'isCloningRuleDetail',
    'isPostingRuleDetail',
    'postRuleDetails',
    'putRuleDetails',
    'actionType',
    'selectedRuleTypeId',
    'selectedRecord',
    'setIsReadOnlyRuleDetail',
    'isReadOnlyRuleDetail',
  ],
  IMG: [
    'detailElements',
    'loading',
    'selectedRuleRecord',
    'selectedRuleDetail',
    'setIsCloningRuleDetail',
    'setIsPostingRuleDetail',
    'setIsShowingImageRuleModal',
    'isShowingImageRuleModal',
    'isCloningRuleDetail',
    'isPostingRuleDetail',
    'postRuleDetails',
    'putRuleDetails',
    'actionType',
    'selectedRuleTypeId',
    'selectedRecord',
    'setIsShowingImageLibraryModal',
    'isShowingImageLibraryModal',
    'setIsShowingImageUploadModal',
    'isShowingImageUploadModal',
    'postImageUpload',
    'getRuleImages',
    'ruleImages',
    'getRuleImagesCount',
    'ruleImagesCount',
    'resetRuleImagesData',
    'convertImageUpload',
    'convertedImageUploadData',
    'convertedImageUploadError',
    'postImageUploadSuccessId',
    'postCommitImage',
    'setIsShowingCommitImageModal',
    'isShowingCommitImageModal',
    'postImageCommitSuccessId',
    'postCommitImageTriggerCancel',
    'setIsReadOnlyRuleDetail',
    'isReadOnlyRuleDetail',
  ],
  RAD: [
    'detailElements',
    'loading',
    'selectedRuleDetail',
    'selectedRuleRecord',
    'setIsCloningRuleDetail',
    'setIsPostingRuleDetail',
    'setIsShowingReturnAddressModal',
    'isShowingReturnAddressModal',
    'isPostingRuleDetail',
    'setIsShowingAddNewAddressModal',
    'selectedRecord',
    'isShowingAddNewAddressModal',
    'selectedRuleTypeId',
    'returnAddressElements',
    'postAddNewAddressElements',
    'isCloningRuleDetail',
    'postRuleDetails',
    'putRuleDetails',
    'setIsShowingImageLibraryModal',
    'isShowingImageLibraryModal',
    'setIsShowingImageUploadModal',
    'isShowingImageUploadModal',
    'postImageUpload',
    'getRuleImages',
    'ruleImages',
    'getRuleImagesCount',
    'ruleImagesCount',
    'resetRuleImagesData',
    'convertImageUpload',
    'convertedImageUploadData',
    'convertedImageUploadError',
    'postImageUploadSuccessId',
    'postCommitImage',
    'setIsShowingCommitImageModal',
    'isShowingCommitImageModal',
    'postImageCommitSuccessId',
    'postCommitImageTriggerCancel',
    'setIsReadOnlyRuleDetail',
    'isReadOnlyRuleDetail',
    'actionType'
  ],
  HLD: [
    'detailElements',
    'loading',
    'selectedRuleRecord',
    'selectedRuleDetail',
    'setIsCloningRuleDetail',
    'setIsPostingRuleDetail',
    'setIsShowingAutoHoldModal',
    'isShowingAutoHoldModal',
    'isCloningRuleDetail',
    'isPostingRuleDetail',
    'postRuleDetails',
    'putRuleDetails',
    'actionType',
    'selectedRecord',
    'selectedRuleTypeId',
    'setIsReadOnlyRuleDetail',
    'isReadOnlyRuleDetail',
  ],
  HDR: [
    'detailElements',
    'loading',
    'selectedRuleRecord',
    'selectedRuleDetail',
    'setIsCloningRuleDetail',
    'setIsPostingRuleDetail',
    'setShowingHighDollarReviewModal',
    'isShowingHighDollarReviewModal',
    'isCloningRuleDetail',
    'isPostingRuleDetail',
    'postRuleDetails',
    'putRuleDetails',
    'actionType',
    'selectedRuleTypeId',
    'selectedRecord',
    'setIsReadOnlyRuleDetail',
    'isReadOnlyRuleDetail',
  ],
  PDT: [
    'loading',
    'selectedRuleRecord',
    'selectedRecord',
    'detailElements',
    'selectedRuleDetail',
    'actionType',
    'setIsCloningRuleDetail',
    'setIsPostingRuleDetail',
    'setIsShowingPDFTaggingModal',
    'isCloningRuleDetail',
    'isShowingPDFTaggingModal',
    'isPostingRuleDetail',
    'postRuleDetails',
    'putRuleDetails',
    'selectedRuleTypeId',
    'setIsReadOnlyRuleDetail',
    'isReadOnlyRuleDetail',
  ]
};