/* eslint-disable radix */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-useless-escape */
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import styled from 'styled-components/dist/styled-components'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import {
  Grid,
  Modal,
  ModalHeader,
  useLayout,
  ListPaneHeader,
  Button, Input, DatePickerV2
} from 'ui-core/dist'
import {detailPaneAnimStates} from '../../commonResources/constants/animStates';
import { useGetEftAccounts, useGetEftAccount, useCopyEftAccount, 
  useOverWriteEftAccount, useDeactivateEftAccount } from '../../api/usePayerEftAccount';
import {AnchorInput} from '../../components'
import LoadingComponent from '../../components/LoadingComponent';
import ErrorPage from '../ErrorPage/components/ErrorPage';
import ListPaneHeaderWrapper from '../../components/ErrorDisplaySection/ListPaneHeaderWrapper';
import useNotificationBannerHook from '../../useNotificationBannerHook';
import GridPaginationWrapper from '../../components/GridPaginationWrapper';
import * as constants from './Modules/constants';
import useSortableGrid from '../../hooks/useSortableGrid';
import {setEditPayerEftAccount, resetEditPayerEftAccount, 
  resetOriginalPayerEftAccount, setOriginalPayerEftAccount} from './Modules/action'

const DEFAULT_ERROR_MSG_EFT_ACCOUNT='Could not retireve account information for Client EFT Account: {0}';
const DEFAULT_ROWS_PER_PAGE=10;

const StyledGridWrapper = styled.div`
&.GridWrapper #grid-wrapper-payerEftMainGrid{
  overflow:initial !important;
 }
`
const PayerEftAccount = () => {
  const {
    expandCollapseListPaneButtonConfig,
    openDetailPane,
    detailAnimationState
  } = useLayout()
  const { notify } = useNotificationBannerHook();
  const history = useHistory();
  const dispatch=useDispatch();
  const { isLoading, isError, error, postData: getEftAccounts, metaData: metadataEftAccounts, data: eftAccounts } = useGetEftAccounts()
  
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE)
  const [pageIndex, setPageIndex] = useState(0);
  const [count, setcount] = useState(0);
  const [selectedRecord, setselectedRecord]=useState()
  
  useEffect(()=>{
    if (history?.location && history?.location?.state) {
      notify(history?.location?.state?.msg, history?.location?.state?.type);
      history.replace({ ...history?.location, state: undefined });
    }
  },[history?.location])

  const refetchEftAccounts = () => {
    setRowsPerPage(DEFAULT_ROWS_PER_PAGE)
    setPageIndex(0)
    getEftAccounts(JSON.parse(JSON.stringify({
      "maxRecords": DEFAULT_ROWS_PER_PAGE,
      "rowPosition": 0,
      "sortField": sortConfig.sortDataName,
      "sortDescending": sortConfig.sortDescending,
      "viewInactive": false,
      count
    })))
    setcount(count + 1)
  }
  useEffect(() => {
    refetchEftAccounts()
  }, [])
  const fetchData = useCallback((args) => {
    let sortcolumn; let sd; let pi; let type;
    if (args) {
      ({ sortcolumn, sd, pi, type } = args);
    }
    getEftAccounts(JSON.parse(JSON.stringify({
      "maxRecords": rowsPerPage,
      "rowPosition": pi === undefined || pi === null ? pageIndex : pi,
      "sortField": sortcolumn === undefined || sortcolumn === null ? sortConfig.sortDataName : sortcolumn,
      "sortDescending": sd === undefined || sd === null ? sortConfig.sortDescending : sd,
      "viewInactive": false,
      type,
      count
    })))
    setcount(count + 1)

  }, [rowsPerPage, pageIndex, sortConfig]);
  const [sortConfig, getArgumentsAndSortGrid] = useSortableGrid(fetchData, "AcctId", "false");
  
  const onAddButtonClick=()=>{
    dispatch(resetOriginalPayerEftAccount())
    dispatch(resetEditPayerEftAccount())
    dispatch(setEditPayerEftAccount({mode: 'add', editPayerEftAccount: {}}));
    setselectedRecord(null)
    history.push('/ClientRulesManager/PayerEftAccount/edit')
  }
  useEffect(() => {
    if (metadataEftAccounts) {
      const { status, statusText } = metadataEftAccounts;
      if (status === 200 || statusText === "OK") {
        if (eftAccounts && eftAccounts.fs) {
          const { fs } = eftAccounts;
          if (fs) {
            notify(fs, 'negative')
          }
        }
      }
    }
  }, [metadataEftAccounts, eftAccounts])
  const renderPayerBank=(PayerBank)=>{
    if (PayerBank==="0" || PayerBank==="" || PayerBank===" ") {
      return <td>Emdeon</td>
    }
    if(PayerBank==="1"){
      return <td>Payer</td>
    }
    return null;
  }
  useEffect(()=>{
    if (detailAnimationState <= detailPaneAnimStates.COLLAPSED){
      if (selectedRecord) setselectedRecord(null)
    }
  },[detailAnimationState])
  const renderStatus=(Status, AcctId)=>{
    if (Status==="T") {
      return (
        <span>No</span>
      )
    }
    if(Status==="L"){
      return (
        <span>Yes</span>
      )
    }
    return null;
  }
  const renderEftAccountsTable=useMemo(()=>{
    if (eftAccounts && eftAccounts.collection && eftAccounts.collection.eftAccount) {
      const data = JSON.parse(JSON.stringify(eftAccounts.collection.eftAccount));
      const {totalRecords} = eftAccounts.collection.$;
      const temp=data.map((d)=>({
        ...d,
        payerbank_display: renderPayerBank(d?.PayerBank),
        status_display: renderStatus(d?.Status, d?.AcctId)
      }))
      return (
        <>
          <div style={{ marginLeft: '22px' }}>
            <p><span className="required-glyph"><label>Click on a record to see the View Live and Edit Test options for the Payer EFT account :</label></span></p>
            <GridPaginationWrapper
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={setRowsPerPage}
              pageIndex={pageIndex}
              onChangePageIndex={setPageIndex}
              onShowButtonCLick={fetchData}
              totalRecords={totalRecords}
              defaultRowsPerPage={DEFAULT_ROWS_PER_PAGE}
            />
          </div>
          <StyledGridWrapper className='GridWrapper'>
            <Grid
              {...constants.EFT_ACCT_MAIN_GRID}
              records={temp || []}
              selectionKey="AcctId" 
              onClickThrough={(e, rec) => {
                e.preventDefault();
                e.stopPropagation();
                const f=data.find(x=>x.AcctId===rec.AcctId && x.Status===rec.Status);
                if (f) {
                  if (f?.Status && f?.AcctId) {
                    try {
                      dispatch(resetOriginalPayerEftAccount())
                      dispatch(resetEditPayerEftAccount())
                      dispatch(setOriginalPayerEftAccount({
                        refetchEftAccounts,
                        originalEftAcctId: rec.AcctId,
                        originalVersionNumber: rec.Version,
                        originalStatusCode: rec.Status,
                      }))
                      openDetailPane()
                      setselectedRecord(f)
                    } catch (e) {
                      const msg=DEFAULT_ERROR_MSG_EFT_ACCOUNT.replace('{0}', f?.AcctId)
                      notify(msg, 'negative')
                    }
                  }
                }
                return false;
              }}
              focusedItem={selectedRecord}
              initialSortingKey={`${sortConfig.sortDataName}|${sortConfig.sortDescending === "true" ? 'SORT_DESCENDING' : 'SORT_ASCENDING'}`}
              onSortGridColumn={(e, state) => getArgumentsAndSortGrid(state)}
            />
          </StyledGridWrapper>
        </>
      )
    }
    return (
      <div className="marginLeft">
        <p><span className="required-glyph"><label>No Records available</label></span></p>
      </div>
    );
  },[eftAccounts, rowsPerPage, pageIndex, sortConfig, selectedRecord])
  
  return (
    <>
      <ListPaneHeaderWrapper
        title="Client Payer Eft Manager"
        layoutControls={[expandCollapseListPaneButtonConfig]}
      />
      <>
        {isLoading? (
        // replace with a better loading component            
          <div style={{ marginLeft: '50%' }}><LoadingComponent /></div>
        ) : isError ? (
          // replace with a better error component
          <ErrorPage
            // this prop isn't real but is an example
            error={error}
          />
        ) : (
          <div className="marginLeft">
            <table border="0" cellPadding="0" cellSpacing="0">
              <tr>
                <td>
                  <div className='marginLeft'>
                    <div className='displayFlex' style={{marginLeft:'10px'}}>
                      <Button
                        id='eftEditorAddButton'
                        size="small"
                        name="Add EFT Account"
                        onClick={()=>onAddButtonClick()}
                      />
                      <div className='marginLeft'>
                        <Button
                          id='ContactEditorAdd'
                          size="small"
                          name="Commit EFT Account"
                          onClick={()=>{history.push('/ClientRulesManager/PayerEftAccount/commit')}}
                        />
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                {renderEftAccountsTable}
              </tr>
            </table>
          </div>
        )
        }
      </>
    </>
  )
}

export default PayerEftAccount;