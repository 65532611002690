import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Button, Modal, Grid, Count, LoadingIndicator } from 'ui-core-ssgr';
import { ModalTitlePaddingWrapper, StyledHeader } from '../../../../shared/styles/styledComponents';
import {
  ModalWrapper,
  ActiveModalPaddingWrapper,
  LoadMoreButton,
  InputLabelWrapper,
  LoadingWrapper,
} from './styles';

const ActiveGroupsListingModal = props => {
  const {
    setIsShowingActiveGroupsListingModal,
    selectedActiveRecordsList,
    gridActiveGroupConfig,
    selectedRecordName,
    showActiveGroupsListingModal,
  } = props;

  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [itemsCount, setItemsCount] = useState(50);

  useEffect(() => {
    if (loadingState) {
      const newCount = itemsCount + 50;
      setItemsCount(newCount);
      setLoadingState(false);
      setButtonDisabled(false);
    }
  }, [loadingState]);

  const loadMoreItems = () => {
    setLoadingState(true);
    setButtonDisabled(true);
  };

  const displayItems = items => {
    const counter = items.length > itemsCount ? itemsCount : items.length;
    const itemsArray = items.slice(0, counter);
    return itemsArray;
  };

  const onModalToggle = () =>
    setIsShowingActiveGroupsListingModal({
      showActiveGroupsListingModal: false,
      activeGroupsModalLocation: '',
      selectedActiveRecordsList: {},
      selectedRecordName: '',
    });

  const records = get(selectedActiveRecordsList, 'activeGroup', []);
  const recordCount = records.length;
  const counter = recordCount > itemsCount ? itemsCount : recordCount;
  const loadMore = !!(recordCount > itemsCount);
  const totalCounterLabel = recordCount;
  const difference =
    totalCounterLabel - counter < 50 ? totalCounterLabel - counter : 50;
  const loadMoreLabel = `Load ${difference} More`;

  return (
    <ModalWrapper>
      <Modal
        domID="modal-id"
        onModalToggle={onModalToggle}
        isOpen={showActiveGroupsListingModal}
        showDefaultClose
        deferBodyControl={false}
      >
        <ActiveModalPaddingWrapper>
          <ModalTitlePaddingWrapper>
            <StyledHeader>
              {`Active Groups with Live Associations: ${selectedRecordName}`}{' '}
              <Count
                count={`${counter}/${totalCounterLabel}`}
                domID="active-group-count"
                size="large"
              />
            </StyledHeader>
          </ModalTitlePaddingWrapper>
          <Grid
            {...gridActiveGroupConfig}
            key={recordCount}
            records={displayItems(records)}
            onClickThrough={() => {}}
            onRowSelect={() => {}}
            onSelectAll={() => {}}
          />
          <LoadMoreButton>
            {loadingState ? (
              <LoadingWrapper>
                <LoadingIndicator
                  domID="associations-load-more-loading-indicator"
                  length="45px"
                />
                <Button
                  name={loadMoreLabel}
                  buttonType="deEmphasized"
                  onClick={loadMoreItems}
                  size="medium"
                  type="button"
                  disabled={buttonDisabled}
                />
              </LoadingWrapper>
            ) : (
              <>
                <InputLabelWrapper>
                  {`${counter}/${totalCounterLabel} rules loaded`}
                </InputLabelWrapper>
                {loadMore && (
                  <Button
                    name={loadMoreLabel}
                    buttonType="deEmphasized"
                    onClick={loadMoreItems}
                    size="medium"
                    type="button"
                  />
                )}
              </>
            )}
          </LoadMoreButton>
        </ActiveModalPaddingWrapper>
      </Modal>
    </ModalWrapper>
  );
};

ActiveGroupsListingModal.propTypes = {
  setIsShowingActiveGroupsListingModal: PropTypes.func.isRequired,
  selectedActiveRecordsList: PropTypes.object,
  gridActiveGroupConfig: PropTypes.object,
  selectedRecordName: PropTypes.string,
  showActiveGroupsListingModal: PropTypes.bool,
};

export default ActiveGroupsListingModal;
