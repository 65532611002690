
const initialState = {
  recipientTypeTranslationRecord:null,
  recipientTypeTranslationMode:'',
  refetchRecipientTypes:()=>false,
}
  
export default (state = initialState, { type, payload }) => {
  switch(type) {
    case 'SET_CLIENT_RULE_DRAFT_CODE_DATA':{
      return {
        ...state,
        terroristExclusionSelectedRecord:JSON.parse(JSON.stringify(payload.terroristExclusionSelectedRecord)),
        terroristExclusionEditorMode:payload.mode,
        refetchterroristExclusionRecord:payload.refetchterroristExclusionRecord
      }
    }
    case 'RESET_CLIENT_RULE_DRAFT_CODE_DATA':{
      return {
        ...state,
        terroristExclusionSelectedRecord:null,
        terroristExclusionEditorMode:'',
        refetchterroristExclusionRecord:()=>false
      }
    } 
    case 'SET_CHECK_ACCOUNT_INFO':{
      return {
        ...state,
        selectedRecord:JSON.parse(JSON.stringify(payload.selectedRecord)),
        mode:payload.mode,
        refetchRecord:payload.refetchRecord
      }
    }
    case 'RESET_CHECK_ACCOUNT_INFO':{
      return {
        ...state,
        selectedRecord:null,
        mode:'',
        refetchRecord:()=>false
      }
    }  
    default:
      return state;
  }
}