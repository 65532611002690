/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Grid,
  RadioGroup,
  Input,
  Checkbox,
  useLayout,
  ListPaneHeader,
  ButtonGroup,
  Button,
  Modal,
  ModalHeader,
  ModalFooter
} from 'ui-core/dist'
import { isAddUser, setCopyId } from './modules/actions'
import axios from '../../axios'
import useUserManager from '../../api/useUserManager'
import Authorizer from '../Authorizer'
import { AnchorInput, GridPaginationWrapper, Loading, SelectDropdown } from '../../components'
import ErrorPage from '../ErrorPage';
import useNotificationBannerHook from '../../useNotificationBannerHook';
import withAxiosCancellation from '../../components/withAxiosCancellation'
import './modules/styles.scss'

const FirstFilter = ({ onFirstFilter, initialValue }) => (
  <SelectDropdown
    domID="user-manager__first-filter"
    isClearable={false}
    label=""
    options={[
      {
        label: 'Active',
        value: false
      },
      {
        label: 'Inactive',
        value: true
      }
    ]}
    size="small"
    initialValue={initialValue}
    onChange={onFirstFilter}
  />
)

const RadioGroupSource=[
  {
    id: 'userId',
    isChecked: false,
    label: 'User ID'
  },
  {
    id: 'userName',
    isChecked: true,
    label: 'User Name'
  },
]

const UserManagerListPane = ({getCancelSourceWithName}) => {
  const dispatch = useDispatch()
  const { notify } = useNotificationBannerHook()
  const [count, setCount] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const [inFlight, setInFlight] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [sortField, setSortField] = useState('userId')
  const [sortDescending, setSortDecending] = useState(false)
  const [searchType, setSearchType] = useState('userId')
  const [searchValue, setSearchValue] = useState('')
  const [exactMatch, setExactMatch] = useState(false)
  const [viewInactive, setViewInactive] = useState({
    label: 'Active',
    value: false
  })
  const [selected, setSelected] = useState([])
  const [payload, setPayload] = useState({
    maxRecords: rowsPerPage,
    rowPosition: pageIndex,
    sortField,
    sortDescending,
    viewInactive: viewInactive.value,
    searchType,
    searchValue,
    exactMatch,
  })
  const [selectedRecord,setSelectedRecord]=useState('')
  const canDeactivate = useSelector(({ context }) =>
    context?.permissions?.filter(p => p.name === 'CAN_PERFORM_SECURITY_USER_DEACTIVATE').length > 0)
  const canReactivate = useSelector(({ context }) =>
    context?.permissions?.filter(p => p.name === 'CAN_PERFORM_SECURITY_USER_REACTIVATE').length > 0)
  const canView = useSelector(({ context }) =>
    context?.permissions?.filter(p => p.name === 'CAN_PERFORM_SECURITY_USER_VIEW').length > 0)
  const {
    expandCollapseListPaneButtonConfig,
    openDetailPane
  } = useLayout()
  const { data, isLoading, total, refetch, isError, error } = useUserManager({ payload, getCancelSource:getCancelSourceWithName('usermanager') })

  useEffect(() => {
    if (isLoading) {
      setSelected([])
    }
  }, [isLoading])

  const onSortGridColumn = (e, { sortingKey }) => {
    const splitKey = sortingKey.split('|')
    if (splitKey[0] === 'userIdProp') setSortField('userId')
    else setSortField(splitKey[0])
    if (splitKey[1] === 'SORT_DESCENDING') setSortDecending(true)
    else setSortDecending(false)
    setPayload({
      ...payload,
      sortField: splitKey[0] === 'userIdProp' ? 'userId' : splitKey[0],
      sortDescending: splitKey[1] === 'SORT_DESCENDING'
    })
  }

  const sortingComparator = (sortDataName, sortDirectionString, recordsToSort) => recordsToSort

  const onRowSelect = (e, state) => {
    const ids = Array.from(state.selectedItemIds);
    setSelected(ids);
  }

  const onSelectAll = (e, state) => {
    const ids = Array.from(state.selectedItemIds);
    setSelected(ids);
  }

  const refreshMe = useCallback(() => {
    // if (
    //   payload.maxRecords === 10 &&
    //   payload.rowPosition === 0 &&
    //   payload.sortField === 'userId' &&
    //   payload.sortDescending === false &&
    //   viewInactive.value === false &&
    //   searchType === 'userId' &&
    //   searchValue === '' &&
    //   exactMatch === false
    // ) {
    //   refetch()
    // } else {
      
    // }
    setPayload(JSON.parse(JSON.stringify({
      maxRecords: 10,
      rowPosition: 0,
      sortField: 'userId',
      sortDescending: false,
      viewInactive: false,
      searchType: 'userId',
      searchValue: '',
      exactMatch: false,
      count:count+1
    })))
    setRowsPerPage(10)
    setPageIndex(0)
    setSortField('userId')
    setSortDecending(false)
    setViewInactive({
      label: 'Active',
      value: false
    })
    setSearchType('userId')
    setSearchValue('')
    setExactMatch(false)
    setCount(count + 1);
  }, [count])

  const handleShowButton = ({ pi }) => {
    if (viewInactive.value !== payload.viewInactive) {
      setPayload({
        maxRecords: 10,
        rowPosition: 0,
        sortField: 'userId',
        sortDescending: false,
        viewInactive: viewInactive.value,
        searchType,
        searchValue,
        exactMatch
      })
      setRowsPerPage(10)
      setPageIndex(0)
      setSortField('userId')
      setSortDecending(false)
      // setSearchType('userId')
      // setSearchValue(searchValue)
      // setExactMatch(false)
    } else {
      setPageIndex(pi)
      setPayload({
        maxRecords: rowsPerPage,
        rowPosition: pi,
        sortField,
        sortDescending,
        viewInactive: viewInactive.value,
        searchType,
        searchValue,
        exactMatch,
      })
    }
  }

  const onFirstFilter = (state) => setViewInactive(state)

  const onAddUser = () => {
    dispatch(isAddUser(true))
    dispatch(setCopyId(undefined))
    setSelectedRecord({})
    openDetailPane({
      detailPaneOpen: true,
      isAddUser: true,
      refreshMe
    })
  }

  const onCopyUser = () => {
    dispatch(isAddUser(true))
    dispatch(setCopyId(selected))
    setSelectedRecord({})
    openDetailPane({
      detailPaneOpen: true
    })
  }

  const onDeactivateUser = () => setIsOpen('deactivate')

  const onReactivateUser = () => setIsOpen('activate')

  const onRadioSelection = (state) => setSearchType(state.id)

  const onSearchValueChange = (e, state) => setSearchValue(state.value)

  const onExactMatchToggle = () => setExactMatch(!exactMatch)

  const onSubmitActivate = async () => {
    setInFlight(true)
    try {
      await axios.put(`/members/${selected}/reactivate`)
      setIsOpen(false)
      notify(`You have successfully activated user ${selected}`, 'positive')
      refreshMe()
    } catch (err) {
      notify(err.displayMessage, 'negative')
    } finally {
      setInFlight(false)
    }
  }

  const onSubmitDeactivate = async () => {
    setInFlight(true)
    try {
      await axios.put(`/members/${selected}/deactivate`)
      setIsOpen(false)
      notify(`You have successfully deactivated user ${selected}`, 'positive')
      refreshMe()
    } catch (err) {
      notify(err.displayMessage, 'negative')
    } finally {
      setInFlight(false)
    }
  }

  const onClose = () => setIsOpen(false)
  const onCancel = () => setIsOpen(false)
  const onConfirm = () => {
    switch (isOpen) {
      case 'activate':
        onSubmitActivate()
        break;
      case 'deactivate':
        onSubmitDeactivate()
        break;
      default:
        break;
    }
  }

  return (
    <>
      <ListPaneHeader
        title="User Manager"
        layoutControls={[expandCollapseListPaneButtonConfig]}
      />
      {isLoading ? <Loading /> :
        isError ? (
          // replace with a better error component
          <ErrorPage
            // this prop isn't real but is an example
            error={error}
          />
        ) : (
            <>
              <ButtonGroup className="user-manager__button-group">
                <Authorizer permission="CAN_PERFORM_SECURITY_USER_ADD" >
                  <Button
                    domID="AddUserButton"
                    className="user-manager__button"
                    buttonType="standard"
                    size="small"
                    name="Add a User"
                    onClick={onAddUser}
                  />
                </Authorizer>
              </ButtonGroup>
              <div className="user-manager__search-row" style={{ marginBottom: '12px' }}>
                <span className="user-manager__label">Search By:</span>
                <RadioGroup
                  dataTestId="test-radioBasic"
                  items={RadioGroupSource}
                  onChange={onRadioSelection}
                  orientation="horizontal"
                  checkedItem={{ id: RadioGroupSource?.find(x=>x?.id===searchType)?.id }}
                  size="small"
                />
              </div>
              <div className="user-manager__search-row">
                <span className="user-manager__label">Search User ID:</span>
                <div style={{ width: '200px' }}>
                  <Input
                    domID="SearchValueInput"
                    onChange={onSearchValueChange}
                    value={searchValue}
                    size="small"
                    onEnterPress={handleShowButton}
                  />
                </div>
                <span className="user-manager__label">Exact Match:</span>
                <Checkbox
                  domID="ExactMatchCheckbox"
                  checked={exactMatch}
                  onChange={onExactMatchToggle}
                />
              </div>
              <div style={{ marginLeft: '30px' }}>
                <GridPaginationWrapper
                  rowsPerPage={rowsPerPage}
                  onChangeRowsPerPage={setRowsPerPage}
                  pageIndex={pageIndex}
                  onChangePageIndex={setPageIndex}
                  onShowButtonCLick={handleShowButton}
                  totalRecords={total}
                  defaultRowsPerPage={10}
                  FirstFilter={() => (
                    <FirstFilter onFirstFilter={onFirstFilter} initialValue={viewInactive} />
                  )}
                />
              </div>
              <Grid
                columns={new Set([
                  {
                    dataName: 'userIdProp',
                    text: 'User Id',
                    sortable: true,
                    isSorted: 0,
                    cellType: 'custom',
                  },
                  {
                    dataName: 'fullName',
                    text: 'User Name',
                    sortable: true,
                    isSorted: 0,
                    cellType: 'text',
                  },
                  {
                    dataName: 'jobTitle',
                    text: 'Job Title',
                    sortable: true,
                    isSorted: 0,
                    cellType: 'text',
                  },
                  {
                    dataName: 'emailAddr',
                    text: 'Email Address',
                    sortable: true,
                    isSorted: 0,
                    cellType: 'custom',
                    customComponent: ({ emailAddr }) => <a href={`mailto:${emailAddr}`}>{emailAddr}</a>
                  },
                ])}
                records={data?.map(record => {
                  if (canView) {
                    return {
                      ...record,
                      emailAddr: { emailAddr: record.emailAddr },
                      userIdProp: (
                        <AnchorInput
                          id='fileKey'
                          type='button'
                          className='fileKeyLink'
                          onClick={() => {
                            dispatch(isAddUser(false))
                            dispatch(setCopyId(undefined))
                            openDetailPane({
                              refreshMe,
                              userId: record.userId
                            })
                          }}
                        >
                          {record.userId}
                        </AnchorInput>
                      ),
                    }
                  }
                  return ({
                    ...record,
                    emailAddr: { emailAddr: record.emailAddr },
                    userIdProp: <AnchorInput disabled style={{ textDecoration: 'none', cursor: 'default' }}>{record.userId}</AnchorInput>,
                  })
                }) || []}
                dataTestId="test-UserManagerGrid"
                domID="UserManagerGrid"
                onSortGridColumn={onSortGridColumn}
                // selectionKey="userId"
                // selectAllCheckboxDomID="UserManagerGridSelectAll"
                onRowSelect={onRowSelect}
                // onSelectAll={onSelectAll}
                // supportSelection={canDeactivate || canReactivate}
                initialSortingKey={`${sortField === 'userId' ? 'userIdProp' : sortField}|${sortDescending ? 'SORT_DESCENDING' : 'SORT_ASCENDING'}`}
                sortingComparator={sortingComparator}
              />
            </>
          )}
          {isOpen?(
          <Modal isOpen={isOpen}>
            <ModalHeader
              title={
                isOpen === 'activate' ? "Confirm User Activation"
                  : isOpen === 'deactivate' ? "Confirm User Deactivation"
                    : ''
              }
              description={
                isOpen === 'activate' ? `Activate user ${selected}`
                  : isOpen === 'deactivate' ? `Deactivate user ${selected}`
                    : ''
              }
              onClose={onClose}
            />
            <ModalFooter>
              <ButtonGroup>
                <Button
                  domID="UserManagerDetailsModalCancelButton"
                  size="small"
                  buttonType="standard"
                  name="Cancel"
                  onClick={onCancel}
                />
                <Button
                  domID="UserManagerDetailsModalConfirmButton"
                  size="small"
                  buttonType="standard"
                  name="Confirm"
                  onClick={onConfirm}
                  disabled={inFlight}
                />
              </ButtonGroup>
            </ModalFooter>
          </Modal>
          ):null}
    </>
  )
}

export default withAxiosCancellation(UserManagerListPane)