import styled from 'styled-components';

export const ScrollableGridWrapper = styled.div`
  max-height: 45vh;
  width: 100%;
  overflow: auto;
  margin-bottom: 10vh;
  .emptyGridMessage {
    width: 400px;
  }
  }
`;
