import styled from 'styled-components';

export default styled.div`
  position: absolute;
  bottom: -6px;
  width: 6px;
  height: 6px;
  margin-left: -3px;
  border-top: 6px solid rgba(255, 255, 255, 1);
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  box-sizing: border-box;

  &.top {
    left: 50%
  }

  &.right {
    left: -6px;
    transform: rotate(90deg);
    top: 50%;
  }

  &.bottom {
    transform: rotate(180deg);
    top: -6px;
    left: 50%;
  }

  &.left {
    left: 100%;
    transform: rotate(-90deg);
    top: 50%;
  }
`;
