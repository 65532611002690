import {
  UPDATE_SERVER_CONFIG,
} from './constants';

export const updateServerConfig = payload => ({
  type: UPDATE_SERVER_CONFIG,
  payload,
});

export default {
  updateServerConfig,
};
