import styled from 'styled-components';

export const ModalWrapper = styled.div`
  display: block;
`;
export const ActiveModalPaddingWrapper = styled.div`
  padding: 0 10px 10px 10px;
  & div#grid-wrapper-active-rule-grid {
    height: 350px;
    overflow: scroll;
    overflow-x: hidden;
  }
`;

export const LoadMoreButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  & button {
    display: inline-block;
    padding: 4px;
    margin: 10px;
  }
`;

export const InputLabelWrapper = styled.div`
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.5px;
  display: inline-block;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  float: left;
`;
