/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Input, Button, Count } from 'ui-core/dist'
import { SelectDropdown } from '../components'
import next from '../assets/icons/next.gif';
import prev from '../assets/icons/previous.gif';
import { useStateCallback } from '../hooks';
import { SELECT_RECORD_OPTIONS } from './constants';

const GridPaginationWrapper = ({
  label,
  rowsPerPage,
  onChangeRowsPerPage,
  pageIndex,
  onChangePageIndex,
  onShowButtonCLick,
  totalRecords,
  defaultRowsPerPage,
  FirstFilter = null,
  enableDropdown = true,
  enableTextbox = true,
  specialIdx = false,
  disableShowButton = false,
  selectRecordOptions = false,
}) => {

  const [arrowVisibleConfig, setarrowVisibleConfig] = useStateCallback({
    prevArrowVisible: false,
    nextArrowVisible: false
  })
  // const [prevArrowVisible,setPrevArrowVisible]=useStateCallback(false);
  // const [nextArrowVisible,setNextArrowVisible]=useStateCallback(false);
  const onChangeRowsPerPageRef = useRef(null);
  const onChangePageIndexRef = useRef(null);
  const onShowButtonCLickRef = useRef(null);
  onChangeRowsPerPageRef.current = onChangeRowsPerPage;
  onChangePageIndexRef.current = onChangePageIndex;
  onShowButtonCLickRef.current = onShowButtonCLick;
  const [validationMessage, setvalidationMessage] = useState(null)

  const onPrevArrowClick = useCallback(() => {
    const rpp = parseInt(rowsPerPage, 10);
    const pi = parseInt(pageIndex, 10);
    let newpi;
    if (pi <= rpp) {
      newpi = 0;
    } else {
      newpi = pi - rpp;
    }
    if (specialIdx) {
      if (pi <= rpp) {
        newpi = 1;
      } else {
        newpi = pi - rpp;
      }
    }
    if (onChangePageIndexRef.current) onChangePageIndexRef.current(newpi);
    if (onShowButtonCLickRef.current) onShowButtonCLickRef.current({ pi: newpi });

  }, [arrowVisibleConfig, rowsPerPage, pageIndex]);

  const onNextArrowClick = useCallback(() => {
    const rpp = parseInt(rowsPerPage, 10);
    const pi = parseInt(pageIndex, 10);
    const newpi = pi + rpp;

    if (onChangePageIndexRef.current) onChangePageIndexRef.current(newpi);
    if (onShowButtonCLickRef.current) onShowButtonCLickRef.current({ pi: newpi });

  }, [arrowVisibleConfig, rowsPerPage, pageIndex]);

  useEffect(() => {

    const rpp = parseInt(rowsPerPage, 10);
    const pi = parseInt(pageIndex, 10);
    const tr = parseInt(totalRecords, 10);
    let pArrowVisible;
    let nArrowVisible;

    if (pi === 0) {
      pArrowVisible = false;
    } else if ((pi <= tr) || (pi > tr)) {
      pArrowVisible = true;
    }
    if ((pi === tr - 1) || (pi + rpp) > tr - 1) {
      nArrowVisible = false;
    } else {
      nArrowVisible = true;
    }

    if (specialIdx) {
      if (pi === 1 || pi === 0) {
        pArrowVisible = false;
      } else if ((pi <= tr) || (pi > tr)) {
        pArrowVisible = true;
      }
      if ((pi === tr) || (pi + rpp) > tr) {
        nArrowVisible = false;
      } else {
        nArrowVisible = true;
      }
    }

    // if (tr>rpp) {

    // } else{
    //   pArrowVisible=false;
    //   nArrowVisible=false;
    // }
    setarrowVisibleConfig(JSON.parse(JSON.stringify({
      prevArrowVisible: pArrowVisible,
      nextArrowVisible: nArrowVisible
    })))
  }, [totalRecords])

  const onShowButtonCLickEventHandler = useCallback(() => {

    if (onShowButtonCLickRef.current) onShowButtonCLickRef.current({ pi: pageIndex })

  }, [rowsPerPage, pageIndex, totalRecords]);

  const renderPreviousButton = useMemo(() => (
    <div style={{ display: 'flex' }}>
      <input type="image" src={prev} className="pagination-arrow" name="_prevResultPage" value="_prev" alt="Prev Result Page"
        onClick={arrowVisibleConfig.prevArrowVisible ? onPrevArrowClick : () => { }}
        style={{
          marginRight: '10px',
          opacity: arrowVisibleConfig.prevArrowVisible ? 1 : 0.4,
          cursor: arrowVisibleConfig.prevArrowVisible ? 'pointer' : 'unset'
        }} />
    </div>
  ), [arrowVisibleConfig]);

  const renderNextButton = useMemo(() => (
    <div style={{ display: 'flex' }}>
      <input type="image" src={next} className="pagination-arrow" name="_nextResultPage" value="_next" alt="Next Result Page"
        onClick={arrowVisibleConfig.nextArrowVisible ? onNextArrowClick : () => { }}
        style={{
          opacity: arrowVisibleConfig.nextArrowVisible ? 1 : 0.4,
          cursor: arrowVisibleConfig.nextArrowVisible ? 'pointer' : 'unset'
        }} />
    </div>
  ), [arrowVisibleConfig])

  const validatePageIndex = (value) => {
    if (!/^\d+$/.test(value)) {
      setvalidationMessage('Invalid Row Index')
      return false;
    }
    return true;
  }
  const renderPageIndex = useMemo(() => (
    <Input
      domID="GridPageIndex"
      label=""
      maxLength="8"
      size="small"
      initialValue={pageIndex}
      onChange={(e) => {
        setvalidationMessage('')
        if (validatePageIndex(e?.target?.value || 0)) {
          const v = e?.target?.value || 0;
          if (v === 0 && parseInt(pageIndex) === 0) {
            if (onChangePageIndexRef.current) onChangePageIndexRef.current('')
            setTimeout(() => {
              if (onChangePageIndexRef.current) onChangePageIndexRef.current(e.target.value || 0)
            });
          } else {
            if (onChangePageIndexRef.current) onChangePageIndexRef.current(e.target.value || 0)
          }
        }
      }}
    />
  ), [pageIndex, validationMessage])
  return (
    <>
      {validationMessage ? (
        <>
          <table>
            <tr>
              <td>
                <span className="required-glyph" style={{ fontSize: '13px', fontWeight: 'bold' }}><label style={{ color: 'red' }}>{validationMessage}</label></span>
              </td>
            </tr>
          </table>
        </>
      ) : null}
      <div className="PaginationWrapper">
        <div className="paginationDisplayFlex">
          <p style={{
            marginRight: '7px',
          }}>{label ? label : `Found  `}<span style={{ padding: '0 4px' }}>
              <Count
                count={totalRecords}
                domID="GridCount"
                size="medium"
              />
            </span></p>
          <div>
            {renderPreviousButton}
          </div>
          {FirstFilter ? <FirstFilter /> : null}
          {/* <p>&nbsp;&nbsp;{label ? 'showing' : 'items'}:</p> */}
          <div style={{
            marginLeft: '7px',
            marginRight: '7px',
            width: '80px',
            inline: 'block'
          }}>
            {enableDropdown ?
              <SelectDropdown
                width="80px"
                domID="defer_state_input"
                isClearable={false}
                label=""
                options={selectRecordOptions || SELECT_RECORD_OPTIONS}
                size="small"
                initialValue={{ label: rowsPerPage?.toString(), value: rowsPerPage?.toString() }}
                onChange={(e) => {
                  if (e) {
                    if (onChangeRowsPerPageRef.current) onChangeRowsPerPageRef.current(e.value)
                  } else if (onChangeRowsPerPageRef.current) onChangeRowsPerPageRef.current(defaultRowsPerPage)
                }}
              /> : <span> {rowsPerPage}</span>}
          </div>
          <p>items per page starting at</p>
          <span style={{
            marginRight: '7px',
            marginLeft: '7px'
          }}>
            {enableTextbox ?
              <>
                {renderPageIndex}
              </> : <span style={{ paddingRight: '10px' }}>{pageIndex}</span>}
          </span>
          <Button
            domID="gridPaginationWrapperShowButton"
            buttonType="deEmphasized"
            size="small"
            name="SHOW"
            disabled={validationMessage || disableShowButton}
            onClick={onShowButtonCLickEventHandler}
          />
        </div>
        <div>
          {renderNextButton}
        </div>
      </div>
    </>
  );
}

export default React.memo(GridPaginationWrapper)
