import React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import GroupRuleManager from '../containers/SelfServiceGroupRules/Home';

const RuleRoutes = () => (
  <Switch>
    <Route path="/GroupRuleManager" component={GroupRuleManager} />
  </Switch>
)

export default RuleRoutes;

