import { useEffect, useState } from 'react'
import { useAxios } from '../hooks'

const useDashbordPaymentAndSavings = (clientId, getCancelSource ) => {
    const { data, error, status, postData,isError, isLoading,...rest } = useAxios({
      // getCancelSource,
      url: `homepage-manager/payment-and-savings-metrics/${clientId}`,
      method: 'GET'
    });
    return { data, error, status, postData,isError, isLoading,...rest }
  };

  const useDashbordProductMetrics = (clientId, getCancelSource ) => {
    const { data, error, status, postData,isError, isLoading,...rest } = useAxios({
      // getCancelSource,
      url: `homepage-manager/product-output-metrics/${clientId}`,
      method: 'GET'
    });
    return { data, error, status, postData,isError, isLoading,...rest }
  };
  
  const useDashbordAnnouncements = (props={}) => {
    const { data, error, status, postData,isError, isLoading,...rest } = useAxios({
      ...props,
      url: `message-manager/display-messages`,
      method: 'GET'
    });
    return { data, error, status, postData,isError, isLoading,...rest }
  };

  const useContextError = (showAll, getCancelSource) => {
    const { data, error, status, postData,isError, isLoading,...rest } = useAxios({
      // getCancelSource,
      url: `homepage-manager/errors?showAllContexts=${showAll}`,
      method: 'GET'
    });
    return { data, error, status, postData,isError, isLoading,...rest }
  };

  const formatPhoneNumber= (phoneNumberString)=> {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ')' + match[2] + '-' + match[3];
    }
    return null;
  }
  const useDashbordContact = (clientId, getCancelSource ) => {
    const [ contactList, setContactList ] = useState([]);
    const { data, error, status, postData,isError, isLoading,...rest } = useAxios({
      // getCancelSource,
      url: `homepage-manager/contacts/${clientId}`,
      method: 'GET'
    });
    useEffect(() => {
      if (data) {
      let modifiedResult=  data.map(obj=> ({ ...obj, formattedPhone: formatPhoneNumber(obj.phone)}));
        setContactList(modifiedResult); 
      }
    }, [data]);
    return { contactList, data, error, status, postData,isError, isLoading,...rest }
  };

  const useDashbordServiceRequest = (clientId, getCancelSource ) => {
    const { data, error, status, postData,isError, isLoading,...rest } = useAxios({
      // getCancelSource,
      url: `homepage-manager/active-siebel-tickets/${clientId}`,
      method: 'GET'
    });
    return { data, error, status, postData,isError, isLoading,...rest }
  };
  
  export  {
    useDashbordPaymentAndSavings,
    useDashbordContact,
    useDashbordServiceRequest,
    useDashbordProductMetrics,
    useDashbordAnnouncements,
    useContextError
  };