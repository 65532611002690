import {
  UPDATE_SERVER_CONFIG,
} from './constants';

export const INITIAL_STATE = {
  clientLayoutId: null,
  userSecurityTokenKey: null,
  username: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_SERVER_CONFIG:
      return {
        ...state,
        clientLayoutId: action.payload.clientLayout,
        userSecurityTokenKey: action.payload.token,
        username: action.payload.userName,
        dataSourceDefinition: action.payload.dataSourceDefinition,
        dataSourceId: action.payload.dataSourceId,
        roleId: action.payload.roleId,
        userRoleId: action.payload.roleId,
        userRole: action.payload.userRole,
        userPermissions: action.payload.userPermissions,
      };
    default:
      return state;
  }
};