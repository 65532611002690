import { connect } from 'react-redux';
import CommitDetailsForm from './components/CommitDetailsForm';
import {
  triggerGetCommitRuleTypesRequest,
  triggerCommitforMultipleTestAssocRules,
  isShowingCommitMultipleTestAssocRuleModalOpen,
  commitMultipleTestAssocRulesSuccess,
  commitMultipleTestAssocRulesError,
} from '../../redux/api/commitRuleTypesList/actions';
import { selectCommitRuleTypesList, selectLoadingState } from './selectors';
import { selectNotificationState } from '../BasicTabDetailsView/selectors';
import { selectCurrentDetailsRecordStatus } from '../RulesTabView/components/RuleDetails/RuleDetailsGridWrapper/selectors';

import {
  addNotification,
  deleteNotification,
  deleteAllNotifications,
} from '../../redux/app/notificationState/actions';
import { setIsShowingActiveGroupsListingModal } from '../../redux/api/rulesForRuleTypes/actions';

const mapStateToProps = state => ({
  ...state,
  records: selectCommitRuleTypesList(state),
  commitMultipleTestAssocRuleModalOpen:
    state.commitRuleTypesList.commitMultipleTestAssocRuleModalOpen,
  showErrorBanner: state.commitRuleTypesList.showErrorBanner,
  showSuccessBanner: state.commitRuleTypesList.showSuccessBanner,
  notifications: selectNotificationState(state),
  selectedRecordStatus: selectCurrentDetailsRecordStatus(state),
  loading: selectLoadingState(state),
  showActiveGroupsListingModal:
    state.rulesForRuleTypes.showActiveGroupsListingModal,
  selectedActiveRecordsList: state.rulesForRuleTypes.selectedActiveRecordsList,
  selectedRecordName: state.rulesForRuleTypes.selectedRecordName,
  activeGroupsModalLocation: state.rulesForRuleTypes.activeGroupsModalLocation,
});

const mapDispatchToProps = {
  triggerGetCommitRuleTypesRequest,
  addNotification,
  deleteNotification,
  deleteAllNotifications,
  triggerCommitforMultipleTestAssocRules,
  isShowingCommitMultipleTestAssocRuleModalOpen,
  commitMultipleTestAssocRulesSuccess,
  commitMultipleTestAssocRulesError,
  setIsShowingActiveGroupsListingModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CommitDetailsForm);
