import styled from 'styled-components/macro';

const SelectInputWrapper = styled.div`
  width: ${props=>props.width||'40%'};
  margin-bottom: 10px;
  display: flex;
`;
const SelectInput = styled.select`
  display: block;
  width: ${props=>props.selectInputWidth||'100%'};
  border: 1px solid #DEE4EC;
  border-radius: 6px;
  box-shadow: inset 0px 2px 6px #8c94a829;
  box-sizing: border-box;
  font-family: Core Sans C;
  font-style: normal;
  font-weight: normal;
  color: #0F0F59;
  font-size: 13px;
  line-height: 150%;
  background-color: #fff;
  outline: none;
  height: 100px;
  padding: 8px 0 9px 12px;
`;

const SelectInputLabel = styled.label`
  width: ${props=>props.selectInputLabelWidth||'30%'};
`;

export{SelectInput, SelectInputWrapper, SelectInputLabel};
