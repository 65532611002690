import styled from 'styled-components';

export const RuleTypesDetailRow = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const ScrollableGridWrapper = styled.div`
  max-height: 45vh;
  margin-bottom: 10vh;
  overflow: auto;
`;