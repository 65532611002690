import { useEffect } from 'react'
import { useAxiosPost } from '../hooks'

const useGetProdItemPricesWithTierPrices = (callback) => {  
    const { data, status, error, isError, isLoading, isSuccess, refetch, postData, metaData } = useAxiosPost({
      url: 'emdeonFinanceManager/getProdItemPricesWithTierPrices',
      method: 'POST',
      dispatchError: false
    });
      
    useEffect(() => {
      if(data)
      // const {collection} = data
      // if(collection){
      //   const {productItemPrice} = collection
      //   productItemPrice.forEach(productItemPriceData => {
      //     if(productItemPriceData.productItemDesc !== "NCOA Envelope" || productItemPriceData.productItemDesc !== "NCOA Hit"){

      //     }
      //   });
        
      // }
        if(callback) callback(false);
    },[data])
    
    useEffect(()=>{
      if(isError){
        if(callback) callback(error);
      }
    },[isError])
    return { data, status, error, isError, isLoading, isSuccess, refetch, metaData, postData}
  }

const useUpdateTierPrices=()=>{
    const {postData, isLoading,isError,error, metaData, data }=useAxiosPost({
      url:'emdeonFinanceManager/saveOrUpdateProductItemPricesWithTierPrices',
      method:'POST',
      onError: (e) => 
      // what happens on error? if you need something extra
      // check api and see that the notifications are already handled there
        e
      ,
      // this will override the onSuccess and onError so just pick to use this
      // or to use the other 2
      onSettled: (d, e) => {
        if(e) {
          // do a thing
          return e
        }
        // some logic here
        return d
      },
    })  
    return { isLoading, isError, error, postData, metaData, data};
  }

  export {useGetProdItemPricesWithTierPrices, useUpdateTierPrices}