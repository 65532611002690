/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components/dist/styled-components'
import {
  useLayout,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  DatePickerV2
} from 'ui-core/dist';
import { LoadingPage, SelectDropdown } from '../../components';
import ErrorPage from '../ErrorPage';
import ListPaneHeaderWrapper from '../../components/ErrorDisplaySection/ListPaneHeaderWrapper';
import { useAvailableGenericReports, useGenericReportWithDate } from "../../api/useReportManager";
import ModalWrapper from '../../components/Modal/components/ModalWrapper';
import useNotificationBannerHook from '../../useNotificationBannerHook';
import withAxiosCancellation from '../../components/withAxiosCancellation';

const StyledModalWrapperWidth = styled(ModalWrapper)`
& .active-content {
  width: 30% !important;
}
`

const ClientSpecificReports = ({getCancelSourceWithName}) => {
  const {
    expandCollapseListPaneButtonConfig,
  } = useLayout()
  const { notify } = useNotificationBannerHook();
  const [showDate, setshowDate]=useState(false);
  const [fromDate, setFromDate]=useState('')
  const [toDate, setToDate]=useState('')
  const [reportType, setReportType] = useState([]);
  const [reportTypes, setReportTypes] = useState([]);
  const [email, setEmail] = useState('');
  const [count, setCount] = useState(0)
  const [isOpenConfirmationModal, setisOpenConfirmationModal] = useState(false)

  const { reportTypeList, postData: getAvailableGenericReports, isError: isErrorGet, isLoading: isLoadingGet, error: errorGet } = useAvailableGenericReports({getCancelSource:getCancelSourceWithName('availableGenericReports')})
  const { result, postData: addGenericReportWithDate, isError: isErrorAdd, isLoading: isLoadingAdd, error: errorAdd } = useGenericReportWithDate({getCancelSource:getCancelSourceWithName('genericReportWithDate')})

  useEffect(()=>{
    if(reportType && reportType?.originalLabel
      && reportType?.originalLabel.indexOf('|')!==-1){
        const s=reportType?.originalLabel?.split('|');
        if(s && s.length>1){
          setshowDate(s[1]==='1')
          return;
        }
      }
      setshowDate(false)
  },[reportType])

  useEffect(() => {
    getAvailableGenericReports({ count })
    setCount(count + 1)
  }, []);

  useEffect(() => {
    if (reportTypeList) {
      setReportTypes(reportTypeList)
      setReportType(reportTypeList.length ? reportTypeList[0] : {})
    }
  }, [reportTypeList])

  useEffect(() => {
    if (result && result.transactionID) {
      notify(`Your Web Report Transaction ${result.transactionID} has been submitted.  If you have not received this report within six (6) hours, please contact client services via customer care hub (https://customercare.changehealthcare.com/public/home.html). If you do not have access to customer care hub, please contact your internal administrator.`, 'positive')
      getAvailableGenericReports({ count })
      setCount(count + 1)
      setReportType(reportTypeList.length ? reportTypeList[0] : {})
      setEmail('')
    }
  }, [result])

  const validateEmail = () =>
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)

  const onConfirmationYesClick = () => {
    addGenericReportWithDate({
      pstrReportType: reportType?.value,
      pstrEmail: email,
      pstrFromDate: showDate?fromDate:'',
      pstrToDate: showDate?toDate:'',
      count
    })
    setCount(count + 1)
    setisOpenConfirmationModal(false)
  }
  const ValidatestartAndDate = () => {
    let startDate = new Date(fromDate);
    let endDate = new Date(toDate);
    return startDate > endDate ? false : true;
}
const ValidateFutureDate = () => {
    let startDate = new Date(fromDate);
    let endDate = new Date(toDate);
    let today = new Date();
    if ((startDate > today) || (endDate > today)) {
        return false;
    }
    else {
        return true;
    }
}
  const validateForm = useMemo(() => {
    if (showDate)
    {
      if (!ValidatestartAndDate()) {
        notify(`From Date should be less than To Date.`, 'caution')
      }
      else if (!ValidateFutureDate()) {
          notify(`Future Date is not allowed.`, 'caution')
      }
    }
    if (!validateEmail() || !reportType?.value?.trim()?.length) {
      return false;
    }
    return true
  }, [email, reportType, showDate, fromDate, toDate])

  const renderReportType = useMemo(() => (
    <SelectDropdown
      width="300px"
      domID="defer_state_input"
      label=""
      options={reportTypes}
      size="small"
      initialValue={reportType}
      onChange={(e) => setReportType(e)} 
    />
  ), [reportType, reportTypes]);

  const renderFromDate=useMemo(()=>{
    if(showDate){
      return ( 
        <div>
          <div>
            From Date:
          </div>
          <DatePickerV2
            domID="renderstartDatePicker"
            errorMessage="Please enter a valid Date"
            hasError={fromDate ? false : true}
            onDateChange={(e) => {
                setFromDate(e ? e.format('MM/DD/YYYY') : '')
            }}
            size="small"

        />
        </div>
      )
    }
    return null;
  },[fromDate, showDate])

  const renderToDate=useMemo(()=>{
    if(showDate){
      return ( 
        <div>
          <div>
            To Date:
          </div>
        <DatePickerV2
            domID="renderstartDatePicker"
            errorMessage="Please enter a valid Date"
            hasError={toDate ? false : true}
            onDateChange={(e) => {
                setToDate(e ? e.format('MM/DD/YYYY') : '')
            }}
            size="small"
        />
        </div>
      )
    }
    return null;
  },[toDate, showDate])

  const renderSection = useMemo(() => (
    <div>
      <table border="0" cellSpacing="1" cellPadding="3" width="100%">
        <tr>
          <td style={{ width: "130px" }}>
            Report Type:
          </td>
          <td style={{ width: "300px" }}>
            {renderReportType}
          </td>
        </tr>
        <tr>
          <td>{renderFromDate}</td>
          <td>{renderToDate}</td>
        </tr>
        <tr>
          <td style={{ width: "130px" }}>
            Email Address for Delivery of Report:
          </td>
          <td style={{ width: "300px" }}>
            <div style={{ width: "300px" }}>
              <Input
                style={{ width: '300px' }}
                className="test-classname"
                domID="txt_renderheirarchy5"
                placeholder=""
                size="small"
                maxLength={50}
                initialValue={email}
                hasError={!email ? false : !validateEmail()}
                errorMessage='Invalid email address'
                onChange={(e) => {
                  setEmail(e.target.value || "")
                }}
              />
            </div>
          </td>
        </tr>
      </table>
      <div className="marginLeft">
        <Button
          domID="btn_search"
          buttonType="standard"
          size="small"
          name="SUBMIT"
          disabled={!validateForm}
          onClick={() => setisOpenConfirmationModal(true)}
        />
      </div>

      <Modal isOpen={isOpenConfirmationModal} wrapperComponent={StyledModalWrapperWidth}>
        <ModalHeader
          title='Do you want to submit the changes?'
          onClose={() => setisOpenConfirmationModal(false)}
        />
        <ModalBody>
          {isOpenConfirmationModal ? (
            <div style={{ marginLeft: '34px', marginBottom: '20px' }} className="marginLeft">
              <div className="displayFlex">
                <div className="marginLeft">
                  <Button
                    domID="btn_search"
                    buttonType="standard"
                    size="medium"
                    name="Yes"
                    onClick={() => onConfirmationYesClick()}
                  />
                </div>
                <div className="marginLeft">
                  <Button
                    domID="btn_search"
                    buttonType="standard"
                    size="medium"
                    name="No"
                    onClick={() => setisOpenConfirmationModal(false)}
                  />
                </div>
              </div>
            </div>
          ) : null}
        </ModalBody>
      </Modal>
    </div>
  ), [email, reportType, reportTypes, isOpenConfirmationModal, showDate, fromDate, toDate])
  return (
    <>
      <ListPaneHeaderWrapper
        title="Client Specific Reports"
        layoutControls={[expandCollapseListPaneButtonConfig]}
      />
      {isLoadingGet || isLoadingAdd ? (
        // replace with a better loading component
        <LoadingPage />
      ) : isErrorGet || isErrorAdd ? (
        // replace with a better error component
        <ErrorPage
          // this prop isn't real but is an example
          error={errorGet || errorAdd}
        />
      ) : (
            <>
              {result && result.error ?
                <div style={{ color: 'red' }} className="marginLeft"><h3>There were errors processing your request:</h3>
                  <li style={{ fontSize: '12px' }}>Internal Communications Error (Number 8AB/F0001) {result?.error}</li>
                </div> : ''}<br /><br />
              <div className="marginLeft">
                {reportTypes.length ? renderSection : `There are no client specific reports available for the current client/layout`}
              </div>
            </>
          )}
    </>
  )
};

export default withAxiosCancellation(ClientSpecificReports)