import React from 'react';
import PropTypes from 'prop-types';

export const Close = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.length} height={props.length} viewBox="0 0 64 64" version="1.1" className={props.className}>
    <title>lg-close</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="lg-close" fillRule="nonzero" fill="#393939" className="layout-control">
        <g id="close" transform="translate(16.000000, 16.000000)">
          <path d="M16,14.5857864 L1.70710678,0.292893219 C1.31658249,-0.0976310729 0.683417511,-0.0976310729 0.292893219,0.292893219 C-0.0976310729,0.683417511 -0.0976310729,1.31658249 0.292893219,1.70710678 L14.5857864,16 L0.292893219,30.2928932 C-0.0976310729,30.6834175 -0.0976310729,31.3165825 0.292893219,31.7071068 C0.683417511,32.0976311 1.31658249,32.0976311 1.70710678,31.7071068 L16,17.4142136 L30.2928932,31.7071068 C30.6834175,32.0976311 31.3165825,32.0976311 31.7071068,31.7071068 C32.0976311,31.3165825 32.0976311,30.6834175 31.7071068,30.2928932 L17.4142136,16 L31.7071068,1.70710678 C32.0976311,1.31658249 32.0976311,0.683417511 31.7071068,0.292893219 C31.3165825,-0.0976310729 30.6834175,-0.0976310729 30.2928932,0.292893219 L16,14.5857864 Z" id="Combined-Shape" />
        </g>
      </g>
    </g>
  </svg>
);

Close.defaultProps = {
  className: undefined,
  length: '36px',
};

Close.propTypes = {
  className: PropTypes.string,
  length: PropTypes.string,
};

export default Close;
