import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'ui-core-ssgr';
import AddRuleTypesModalGridWrapper from '../ModalGridWrapper';

const AddRuleTypeModal = ({
  isOpen,
  records,
  selectedRecord,
  onModalToggle,
  triggerRuleTypesRequest,
}) => (
  <Modal
    domID="add-rule-type-modal"
    isOpen={isOpen}
    key={isOpen}
    showDefaultClose
    onModalToggle={onModalToggle}
  >
    <AddRuleTypesModalGridWrapper
      records={records}
      selectedRecord={selectedRecord}
      onModalToggle={onModalToggle}
      triggerRuleTypesRequest={triggerRuleTypesRequest}
    />
  </Modal>
);

AddRuleTypeModal.propTypes = {
  isOpen: PropTypes.bool,
  onModalToggle: PropTypes.func,
  records: PropTypes.array,
  selectedRecord: PropTypes.object,
  triggerRuleTypesRequest: PropTypes.func,
};

export default AddRuleTypeModal;
