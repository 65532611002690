import styled from 'styled-components';

export const PaddingWrapper = styled.div`
  padding: 1.5rem 1.5rem 0.4rem 2rem;
`;

export const DetailHeaderWrapper = styled.div`
  width: 40%;
`;

export const DivWithPaddingTop = styled.div`
  padding-top: 1.5em;
`;

export const PaddingWrapperEditTab = styled.div`
  padding: 0.5rem 2rem;
  position: fixed;
  bottom: 0px;
  width: 100%;
  border-top: 1px solid #ccc;
  background: #fff;
  z-index: 1;
`; 

export const TooltipWrapper = styled.div`
  display: inline-block;
`; 

