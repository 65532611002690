/* eslint-disable import/no-cycle */
import React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import { Page } from '../components';
import PayerEftAccount from '../containers/ClientRulesManager/PayerEftAccount'
import PayerEftAccountEdit from '../containers/ClientRulesManager/PayerEftAccountEdit'
import PayerEftAccountDeactivateConfirmation from '../containers/ClientRulesManager/PayerEftAccountDeactivateConfirmation'
import PayerEftAccountCommit from '../containers/ClientRulesManager/PayerEftAccountCommit'
import PayerEftAccountDetailPane from '../containers/ClientRulesManager/PayerEftAccountDetailPane'
import DraftCode from '../containers/ClientRuleManager/DraftCodeMain'
import DraftCodeDetail from '../containers/ClientRuleManager/DraftCodeDetails'
import CheckAccountInfoMain from '../containers/ClientRuleManager/CheckAccountInfoMain'
import CheckAccountInfoDetails from '../containers/ClientRuleManager/CheckAccountInfoDetails'
import StateCopy from '../containers/ClientRuleManager/StateCopyMain'
import StateCopyDetails from '../containers/ClientRuleManager/StateCopyDetails'
import FromTranslationMain from '../containers/ClientRuleManager/FormTranslation/FormTranslationMain'
import FormTranslationDetail from '../containers/ClientRuleManager/FormTranslation/FormTranslationDetail'
import ImageTranslationMain from '../containers/ClientRuleManager/ImageTranslation/ImageTranslationMain'
import ImageTranslationDetail from '../containers/ClientRuleManager/ImageTranslation/ImageTranslationDetail'
import IneligibleCodesMain from '../containers/ClientRuleManager/IneligibleCodesMain'
import IneligibleCodesDetails from '../containers/ClientRuleManager/IneligibleCodesDetails'

const PayerEftAccountPage=()=>(
  <Page
    ListPaneContent={PayerEftAccount}
    DetailPaneContent={PayerEftAccountDetailPane}
  />
)
const PayerEftAccountEditPage=()=>(
  <Page
    ListPaneContent={PayerEftAccountEdit}
  />
)
const PayerEftAccountDeactivateConfirmationPage=()=>(
  <Page
    ListPaneContent={PayerEftAccountDeactivateConfirmation}
  />
)
const PayerEftAccountCommitPage=()=>(
  <Page
    ListPaneContent={PayerEftAccountCommit}
  />
)

const DraftCodeMenu=()=>(
  <Page
    ListPaneContent={DraftCode}
    DetailPaneContent={DraftCodeDetail}
  />
)

const FormTranslationMenu=()=>(
  <Page
    ListPaneContent={FromTranslationMain}
    DetailPaneContent={FormTranslationDetail}
  />
)

const CheckAccountInfoMenu=()=>(
  <Page
    ListPaneContent={CheckAccountInfoMain}
    DetailPaneContent={CheckAccountInfoDetails}
  />
)

const StateCopyMenu=()=>(
  <Page
    ListPaneContent={StateCopy}
    DetailPaneContent={StateCopyDetails}
  />
)

const ImageTranslationMenu=()=>(
  <Page
    ListPaneContent={ImageTranslationMain}
    DetailPaneContent={ImageTranslationDetail}
  />
)
const IneligibleCodesMenu=()=>(
  <Page
    ListPaneContent={IneligibleCodesMain}
    DetailPaneContent={IneligibleCodesDetails}
  />
)

const ClientRulesRoutes = () => (
  <Switch>
    <Route path="/ClientRulesManager/StateCopy1099" component={StateCopyMenu} />
    <Route path="/ClientRulesManager/CheckAcctInfo" component={CheckAccountInfoMenu} />
    <Route path="/ClientRulesManager/DraftCode" component={DraftCodeMenu} />
    <Route path="/ClientRulesManager/PayerEftAccount/index" component={PayerEftAccountPage} />
    <Route path="/ClientRulesManager/PayerEftAccount/edit" component={PayerEftAccountEditPage} />
    <Route path="/ClientRulesManager/PayerEftAccount/deactiveConfirmation" component={PayerEftAccountDeactivateConfirmationPage} />
    <Route path="/ClientRulesManager/PayerEftAccount/commit" component={PayerEftAccountCommitPage} />
    <Route path="/ClientRulesManager/FormTranslation" component={FormTranslationMenu} />
    <Route path="/ClientRulesManager/ImageTranslation" component={ImageTranslationMenu} />
    <Route path="/ClientRulesManager/IneligibleCodes" component={IneligibleCodesMenu} />
    <Route exact path="/ClientRulesManager/PayerEftAccount">
      <Redirect to="/ClientRulesManager/PayerEftAccount/index" />
    </Route>
    <Route exact path="/ClientRulesManager">
      <Redirect to="/ClientRulesManager/StateCopy1099" />
    </Route>
  </Switch>
)

export default ClientRulesRoutes;