import { useAxios } from '../hooks'

const useIDList = ({ payload }) => {
  const { data, ...rest } = useAxios({
    url: '/welcomeKit/list',
    method: 'POST',
    payload,
    negativeMessage: true
  })

  return { 
    data: data?.data, 
    total: data?.totalRecords || undefined, ...rest}
}

export default useIDList