/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
import React, {
  useState,
  useEffect,
  useRef
} from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import {
  Input,
} from 'ui-core/dist'
import {
  CLAIM_TYPE,
  CLAIM_TYPE_NOT_AVAILABLE,
  DOCUMENT_TYPE,
  DOCUMENT_TYPE_LIST_NOT_AVAILABLE,
  FILEKEY_LIST_NOT_AVAILABLE,
  RECIPIENT_TYPE,
  RECIPIENT_TYPE_LIST_NOT_AVAILABLE,
  SELECT_RECORD_OPTIONS,
} from "./Modules/constants";
import { SelectDropdown } from '../../components'
import MultipleSelectDropdown from '../../components/MultipleSelectDropdown';
import './Modules/styles.scss';
import SortingWrapper from '../../components/SortingWrapper';
import RangeTextboxControl from '../../components/RangeTextboxControl';
import EmployerGroup from '../../components/EmployerGroup';
import useHandleEnterKeyPressEvent from './Modules/useHandleEnterKeyPressEvent';

const StyledTableContainer = styled.div`
 &>table>tr>td:first-child{
  width:130px;
  padding-bottom: 10px
}`

const InputWrapper = styled.div`
  display: flex;
  
  margin-bottom: 10px;
  justify-content: space-between;
  & div:first-child{
    width:200px !important;
  }`;

const ItemsDropdownWrapper = styled.div`
  width: ${props => props.width || '65%'};
`;
const DocumentManagerDocumentSearchForm = ({
  isRelease=false,
  isRangeControlEnabled,
  isResetField,
  setIsResetFlag,
  changeTinSsn = () => { },
  changeFileKey = () => { },
  changeEmpGroup = () => { },
  changeRecipientTypes = () => { },
  changeDocumentTypes = () => { },
  changeClaimType = () => { },
  changeSortField = () => { },
  changeSortOrder = () => { },
  changeItemsPerPage = () => { },
  changeCheckNo1 = () => { },
  changeClaimNo1 = () => { },
  changeCheckNo2 = () => { },
  changeClaimNo2 = () => { },
  changeClaimNoMode = () => { },
  changeCheckNoMode = () => { },
  nextClick = () => false,
  getCancelSourceWithName=()=>false
}) => {
  const selectFileKeys = useRef();
  selectFileKeys.current = changeFileKey
  const selectEmpGroup = useRef();
  selectEmpGroup.current = changeEmpGroup;
  const selectDocumentTypes = useRef();
  selectDocumentTypes.current = changeDocumentTypes;
  const selectClaimType = useRef();
  selectClaimType.current = changeClaimType;
  const selectRecipientTypes = useRef();
  selectRecipientTypes.current = changeRecipientTypes;
  const selectSortField = useRef();
  selectSortField.current = changeSortField;
  const selectSortOrder = useRef();
  selectSortOrder.current = changeSortOrder;
  const onChangeTinSsn = useRef();
  onChangeTinSsn.current = changeTinSsn;
  const selectItemsPerPage = useRef();
  selectItemsPerPage.current = changeItemsPerPage;
  const selectClaimNo1 = useRef();
  selectClaimNo1.current = changeClaimNo1;
  const selectClaimNo2 = useRef();
  selectClaimNo2.current = changeClaimNo2;
  const selectCheckNo1 = useRef();
  selectCheckNo1.current = changeCheckNo1;
  const selectCheckNo2 = useRef();
  selectCheckNo2.current = changeCheckNo2
  const selectCheckNoMode = useRef();
  selectCheckNoMode.current = changeCheckNoMode;
  const selectClaimNoMode = useRef();
  selectClaimNoMode.current = changeClaimNoMode;

  const config = {
    fields: [{
      fieldName: 'claimNumber',
      validator: (value) => value ? value.length <= 30 : true
    }, {
      fieldName: 'checkNumber',
      validator: (value) => value ? value.length <= 30 : true
    }, {
      fieldName: 'tinSsn',
      validator: (value) => value ? value.length <= 30 : true
    }, {
      fieldName: 'checknumbertextbox1',
      validator: () => true,
    }, {
      fieldName: 'checknumbertextbox2',
      validator: () => true,
    }, {
      fieldName: 'claimnumbertextbox1',
      validator: () => true,
    }, {
      fieldName: 'claimnumbertextbox2',
      validator: () => true,
    }],
    onEnterKeyPressHandler: nextClick
  }
  const { handleEnterKeyPressEvent } = useHandleEnterKeyPressEvent(config)

  const [claimNumber1, setClaimNumber1] = useState('');
  const [claimNumber2, setClaimNumber2] = useState('');
  const [claimNumberMode, setClaimNumberMode] = useState('');

  const [checkNumber1, setCheckNumber1] = useState('');
  const [checkNumber2, setCheckNumber2] = useState('');
  const [checkNumberMode, setCheckNumberMode] = useState('');

  const [empGroup, setEmpGroup] = useState('');
  const [recipientTypes, setRecipientTypes] = useState('');
  const [documentTypes, setDocumentTypes] = useState('');
  const [fileKeys, setFileKeys] = useState('');
  const [claimTypes, setClaimTypes] = useState('');

  const [tinSSN, setTinSSN] = useState('');
  const [itemsPerPage, setItemsPerPage] = useState({ label: 10, value: 10 });

  const [claimNumber1MaxLength, setClaimNumber1MaxLength] = useState(3000)
  const [claimNumber2MaxLength, setClaimNumber2MaxLength] = useState(30)
  const [checkNumber1MaxLength, setCheckNumber1MaxLength] = useState(3000)
  const [checkNumber2MaxLength, setCheckNumber2MaxLength] = useState(21)
  const [hasLimitExceededstate, sethasLimitExceededstate] = useState(false)

  // selectClick.current=onSelectFileKey;
  const renderClaimNumberLabel = (elem) => (
    <td>{elem}</td>
  )
  useEffect(() => {
    if (claimNumberMode === "Exact" || claimNumberMode === "Partial") {
      setClaimNumber1MaxLength('3000')
    }
    else if (claimNumberMode === "Range") {
      setClaimNumber2MaxLength('30')
    }
  }, [claimNumberMode])

  useEffect(() => {
    if (checkNumberMode === "Exact" || checkNumberMode === "Partial") {
      setCheckNumber1MaxLength('3000')
    }
    else if (checkNumberMode === "Range") {
      setCheckNumber2MaxLength('30')
    }
  }, [checkNumberMode])


  const constructEmpGroups = (items) => {
    let egroup = '';
    if (hasLimitExceededstate) {
      egroup = items.value || ""
    } else if (items && items.length > 0 && items.indexOf('') === -1) {
      egroup = '<![CDATA[<EmpGroupIds>'
      items.forEach(item => {
        egroup += `<EmpGroupId>${item}</EmpGroupId>`
      })
      egroup += '</EmpGroupIds>]]>'
    }
    setEmpGroup(JSON.parse(JSON.stringify(items)));
    selectEmpGroup.current(egroup);
  }

  const constructRecipientTypes = (items) => {
    let xml = '';
    if (items && items.length > 0 && items.indexOf('') === -1) {
      xml = '<![CDATA[<recipientTypes>'
      items.forEach(item => {
        if (item !== '') xml += `<recipientType>${item}</recipientType>`
      })
      xml += '</recipientTypes>]]>'
    }
    setRecipientTypes(JSON.parse(JSON.stringify(items)))
    selectRecipientTypes.current(xml);
  }

  const constructDocumentTypesXml = (items) => {
    let xml = '';
    if (items && items.length > 0 && items.indexOf('') === -1) {
      xml = `<![CDATA[<documentTypes>`
      items.forEach(item => {
        if (item !== '') xml += `<documentType>${item}</documentType>`
      })
      xml += '</documentTypes>]]>'
    }
    setDocumentTypes(JSON.parse(JSON.stringify(items)))
    selectDocumentTypes.current(xml);
  }

  const constructFileKeys = (items) => {
    let xml = ''
    if (items && items.length > 0 && items.indexOf('') === -1) {
      xml = '<![CDATA[<filekeys>'
      items.forEach(item => {
        if (item !== '') xml += `<filekey>${item}</filekey>`
      })
      xml += '</filekeys>]]>'
    }
    setFileKeys(JSON.parse(JSON.stringify(items)));
    selectFileKeys.current(xml);
  }

  const constructClaimType = (items) => {
    let xml = ''
    if (items && items.length > 0 && items.indexOf('') === -1) {
      // xml = `${items.join('|')}`;
      xml = '<![CDATA[<claimTypes>'
      items.forEach(item => {
        if (item !== '') xml += `<claimType>${item}</claimType>`
      })
      xml += '</claimTypes>]]>'
    }
    setClaimTypes(JSON.parse(JSON.stringify(items)));
    selectClaimType.current(xml);
  }

  const constructClaimNumber = (textbox1value, textbox2value, mode) => {
    setClaimNumber1(textbox1value)
    setClaimNumber2(textbox2value)
    setClaimNumberMode(mode)
    selectClaimNo1.current(textbox1value)
    selectClaimNo2.current(textbox2value)
    selectClaimNoMode.current(mode);
  }

  const constructCheckNumber = (textbox1value, textbox2value, mode) => {
    setCheckNumber1(textbox1value)
    setCheckNumber2(textbox2value)
    setCheckNumberMode(mode)
    selectCheckNo1.current(textbox1value)
    selectCheckNo2.current(textbox2value)
    selectCheckNoMode.current(mode)
  }

  const changeTinSSNs = (value) => {
    if (onChangeTinSsn.current) onChangeTinSsn.current(value)
    setTinSSN(value)
  }

  const onChangeClaimNumber = (value) => {
    if (selectClaimNo1.current) selectClaimNo1.current(value)
    setClaimNumber1(value)
  }

  const onChangeCheckNumber = (value) => {
    if (selectCheckNo1.current) selectCheckNo1.current(value)
    setCheckNumber1(value)
  }


  useEffect(() => {
    if (isResetField) {
      setClaimNumber1('')
      setClaimNumber2('')
      setClaimNumberMode('')
      setCheckNumber1('')
      setCheckNumber2('')
      setCheckNumberMode('')
      setTinSSN('');
      setRecipientTypes([])
      setDocumentTypes([])
      setClaimTypes([])
      setFileKeys([])
      setEmpGroup(null);
      setItemsPerPage({ label: 10, value: 10 })
      selectSortOrder.current('')
      selectSortField.current('')
      selectClaimNo1.current('');
      selectClaimNo2.current('');
      selectCheckNo1.current('');
      selectCheckNo2.current('');
      selectCheckNoMode.current('');
      selectClaimNoMode.current('')

      setIsResetFlag(false)
    }
  }, [isResetField])

  return (
    <>
      <div>
        <div className="marginLeft">
          <StyledTableContainer style={{ paddingBottom: '17px' }}>
            <table>

              {isRangeControlEnabled ? (
                <>
                  <tr>
                    <RangeTextboxControl
                      labelContainer={renderClaimNumberLabel}
                      inputContainer={renderClaimNumberLabel}
                      label="Claim Number "
                      uniqueId="claimnumber"
                      defaultMode="Exact"
                      textbox1maxlength={claimNumber1MaxLength}
                      textbox2maxlength={claimNumber2MaxLength}
                      onSetValue={({ textbox1value, textbox2value, mode }) => {
                        constructClaimNumber(textbox1value, textbox2value, mode)
                      }}
                      initialValueTextBox1={claimNumber1}
                      initialValueTextBox2={claimNumber2}
                      isResetField={isResetField}
                      setIsResetFlag={setIsResetFlag}
                      handleEnterKeyPressEvent={handleEnterKeyPressEvent}
                    />
                  </tr>
                  <tr><p></p></tr>
                  <tr>
                    <RangeTextboxControl
                      labelContainer={renderClaimNumberLabel}
                      inputContainer={renderClaimNumberLabel}
                      label="Check Number "
                      defaultMode="Exact"
                      initialValue=""
                      uniqueId="checknumber"
                      textbox1maxlength={checkNumber1MaxLength}
                      textbox2maxlength={checkNumber2MaxLength}
                      onSetValue={({ textbox1value, textbox2value, mode }) => {
                        constructCheckNumber(textbox1value, textbox2value, mode)
                      }}
                      initialValueTextBox1={checkNumber1}
                      initialValueTextBox2={checkNumber2}
                      isResetField={isResetField}
                      setIsResetFlag={setIsResetFlag}
                      handleEnterKeyPressEvent={handleEnterKeyPressEvent}
                    />
                  </tr>
                </>
              ) : (
                  <>
                    <tr>
                      <td>
                        <label>Claim Number: </label>
                      </td>
                      <td>
                        <InputWrapper>
                          <Input
                            domID="claimNumber"
                            size="small"
                            maxLength={3000}
                            initialValue={claimNumber1}
                            onChange={(e) => onChangeClaimNumber(e.target.value)}
                            onKeyUp={(e) => {
                              handleEnterKeyPressEvent(e, e?.currentTarget?.id, claimNumber1)
                            }}
                          />
                        </InputWrapper>
                      </td>
                    </tr>
                    <tr><p></p></tr>
                    <tr>
                      <td>
                        <label>Check Number: </label>
                      </td>
                      <td>
                        <InputWrapper>
                          <Input
                            domID="checkNumber"
                            size="small"
                            maxLength={3000}
                            initialValue={checkNumber1}
                            onChange={(e) => onChangeCheckNumber(e.target.value)}
                            onKeyUp={(e) => {
                              handleEnterKeyPressEvent(e, e?.currentTarget?.id, checkNumber1)
                            }}
                          />
                        </InputWrapper>
                      </td>
                    </tr>
                  </>
                )
              }
              <tr><p></p></tr>
              <tr>
                <td>
                  <label>TIN/SSN: </label>
                </td>
                <td>
                  <InputWrapper>
                    <Input
                      domID="tinSsn"
                      size="small"
                      maxLength={30}
                      initialValue={tinSSN}
                      onChange={(e) => changeTinSSNs(e.target.value)}
                      onKeyUp={(e) => {
                        handleEnterKeyPressEvent(e, e?.currentTarget?.id, tinSSN)
                      }}
                    />
                  </InputWrapper>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <EmployerGroup
                getCancelSourceWithName={getCancelSourceWithName}
                  selectInputLabelWidth="100%"
                  selectInputWidth="350px"
                  labelContainer={renderClaimNumberLabel}
                  inputContainer={renderClaimNumberLabel}
                  onSelect={constructEmpGroups}
                  onSelectRow={constructEmpGroups}
                  hasLimitExceeded={sethasLimitExceededstate}
                  initialValue={empGroup}
                />
              </tr>
              <tr><td></td><td></td></tr>
              <tr>
                <MultipleSelectDropdown
                getCancelSource={getCancelSourceWithName('filekey')}
                  id='fileKey'
                  methodType='POST'
                  fetchDataURL={isRelease ? 'documentManager/getFileKeysAvailableForRelease' : 'documentManager/getEligibleFileKeys'}
                  label='File Key(s):'
                  errorMessage={FILEKEY_LIST_NOT_AVAILABLE}
                  effect={(value) => {
                    constructFileKeys(value)
                  }}
                  displayField='label'
                  valueField='value'
                  displayDefault
                  initialValue={fileKeys}
                  labelContainer={renderClaimNumberLabel}
                  inputContainer={renderClaimNumberLabel}
                  selectInputLabelWidth='100%'
                  selectInputWrapperWidth="30%"
                />
              </tr>
              <tr><td></td><td></td></tr>
              <tr>
                <MultipleSelectDropdown
                getCancelSource={getCancelSourceWithName('claimType')}
                  id='claimType'
                  methodType='POST'
                  fetchDataURL='discriminationTypes/buildXmlByType'
                  label='Claim Type:'
                  errorMessage={CLAIM_TYPE_NOT_AVAILABLE}
                  effect={(value) => {
                    constructClaimType(value)
                  }}
                  tagName={CLAIM_TYPE}
                  displayField='label'
                  valueField='value'
                  displayDefault
                  initialValue={claimTypes}
                  labelContainer={renderClaimNumberLabel}
                  inputContainer={renderClaimNumberLabel}
                  selectInputLabelWidth='100%'
                  selectInputWrapperWidth="30%"
                />
              </tr>
              <tr><td></td><td></td></tr>
              <tr>
                <MultipleSelectDropdown
                getCancelSource={getCancelSourceWithName('recipentType')}
                  id='recipentType'
                  methodType='POST'
                  fetchDataURL='discriminationTypes/buildXmlByType'
                  label='Recipent Type:'
                  errorMessage={RECIPIENT_TYPE_LIST_NOT_AVAILABLE}
                  effect={(value) => {
                    constructRecipientTypes(value)
                  }}
                  tagName={RECIPIENT_TYPE}
                  displayField='label'
                  valueField='value'
                  displayDefault
                  initialValue={recipientTypes}
                  labelContainer={renderClaimNumberLabel}
                  inputContainer={renderClaimNumberLabel}
                  selectInputLabelWidth='100%'
                  selectInputWrapperWidth="30%"
                />
              </tr>
              <tr><td></td><td></td></tr>
              <tr>
                <MultipleSelectDropdown
                getCancelSource={getCancelSourceWithName('documentType')}
                  id='documentType'
                  methodType='POST'
                  fetchDataURL='discriminationTypes/buildXmlByType'
                  label='Document Type:'
                  errorMessage={DOCUMENT_TYPE_LIST_NOT_AVAILABLE}
                  effect={(value) => {
                    constructDocumentTypesXml(value)
                  }}
                  tagName={DOCUMENT_TYPE}
                  displayField='label'
                  valueField='value'
                  displayDefault
                  initialValue={documentTypes}
                  labelContainer={renderClaimNumberLabel}
                  inputContainer={renderClaimNumberLabel}
                  selectInputLabelWidth='100%'
                  selectInputWrapperWidth="30%"
                />
              </tr>
              <tr><td></td><td></td></tr>
              <SortingWrapper
                width="20%"
                onChangeSortField={selectSortField.current}
                onChangeSortOrder={selectSortOrder.current}
                isResetFlag={isResetField}
                setIsResetFlag={setIsResetFlag}
                labelContainer={renderClaimNumberLabel}
                inputContainer={renderClaimNumberLabel}
              />
              <tr><td></td><td></td></tr>
              <tr>
                <td>
                  <label>Items Per Page:</label>
                </td>
                <td>
                  <ItemsDropdownWrapper width="20%">
                    <SelectDropdown
                      domID="itemPerPage"
                      onChange={(e) => { setItemsPerPage(e); selectItemsPerPage.current(e) }}
                      options={SELECT_RECORD_OPTIONS}
                      initialValue={{ label: itemsPerPage?.label?.toString(), value: itemsPerPage?.value?.toString() }}
                      size="small"
                    />
                  </ItemsDropdownWrapper>
                </td>
              </tr>
            </table>
          </StyledTableContainer>
        </div>
      </div>


    </>
  )
}

DocumentManagerDocumentSearchForm.propTypes = {
  isRangeControlEnabled: PropTypes.bool,
  isResetField: PropTypes.bool,
  setIsResetFlag: PropTypes.func,
  changeTinSsn: PropTypes.func,
  changeFileKey: PropTypes.func,
  changeEmpGroup: PropTypes.func,
  changeRecipientTypes: PropTypes.func,
  changeDocumentTypes: PropTypes.func,
  changeClaimType: PropTypes.func,
  changeSortField: PropTypes.func,
  changeSortOrder: PropTypes.func,
}
export default DocumentManagerDocumentSearchForm
