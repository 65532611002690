export const setTrackingInfoAvailable=(payload)=>({
  type:'SET_TRACKING_INFO_AVAILABLE',
  payload
})

export const setCurrentShowMoreTarget=(payload)=>({
  type:'SET_CURRENT_SHOW_MORE_TARGET',
  payload
})

export const setDocumentSearchMetadata=(payload)=>({
  type:'SET_DOCUMENT_SEARCH_METADATA',
  payload
})

export const resetDocumentSearchMetadata=(payload)=>({
  type:'RESET_DOCUMENT_SEARCH_METADATA',
  payload
})

export const setRecord=(payload)=>({
  type:'SET_RECORD',
  payload
})

export const resetRecord=()=>({
  type:'RESET_RECORD'
})

export const setQuickSearchParams=(payload)=>({
  type: 'SET_QUICK_SEARCH_PARAMS',
  payload
})
export const setDocumentSearchParams=(payload)=>({
  type:'SET_DOCUMENT_SEARCH_PARAMS',
  payload
})

export const resetDocumentSearchParams=()=>({
  type:'RESET_DOCUMENT_SEARCH_PARAMS'
})

export const setFileSearchParam = payload => ({
  type: "FILE_SEARCH_PARAM",
  payload
})

export const setFileSearchParamNull = payload => ({
  type: "FILE_SEARCH_PARAM_NULL",
  payload
})
export const setFileIdDetailsACHManager=(payload)=>({
  type:'SET_ACH_FILE_ID_DETAILS',
  payload
})

export function getCkeyXML(strTokenID) {
  return `
  <![CDATA[
    <executeAction>
      <strModuleName>ABFVCCManager</strModuleName>
      <strSubModuleName>ABFVCCMgr</strSubModuleName>
      <strAction>GetCKey</strAction>
      <strParameters>TokenID|${strTokenID}</strParameters>
    </executeAction>
    ]]>
  `
}