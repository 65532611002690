/* eslint-disable no-param-reassign */
import { useState, useEffect, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { resetErrors, setErrors } from '../../components/ErrorDisplaySection/modules/action';
import axios from '../../axios';
import envConfig from '../../config/env-config';
import useNotificationBannerHook from '../../useNotificationBannerHook';

const { APP_ENV = 'development' } = window;
const API_ROOT_SERVER = envConfig[APP_ENV].API_ROOT_SERVER;
const DIRECT_PRINT_SERVER = envConfig[APP_ENV].DIRECT_PRINT;
const { REACT_APP_API_ROOT_SERVER } = process.env
function useAxiosPost({ 
  url, 
  method='POST',
  useDirectPrint=false,
  showNotificationBanner=false,
  notificationSuccessMessage=null,
  notificationErrorMessage=null,
  config = {},
  onError,
  onSuccess,
  onSettled,
  dispatchError = true,
  resetError = true,
  responseType,
  getCancelSource=()=>false
}) {
  // Objects are always unequal in hooks becasue they are stored in different
  // memory locaitons in react, so we ref the objs and functions that are
  // passed into the function so that they .current prop can be used instead
  const configRef = useRef()
  configRef.current = config
  const onErrorRef = useRef()
  onErrorRef.current = onError
  const onSuccessRef = useRef()
  onSuccessRef.current = onSuccess
  const onSettledRef = useRef()
  onSettledRef.current = onSettled
  const getCancelSourceRef=useRef()
  getCancelSourceRef.current=getCancelSource

  const cancelTokenSource = axios?.CancelToken?.source();
 
  const dispatch = useDispatch();
  const { notify } = useNotificationBannerHook()
  const [data, setData] = useState(null)
  const [metaData, setMetaData] = useState(null)
  const [status, setStatus] = useState(null)
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const activeContext = useSelector(({ context }) => `${context?.context?.client}_${context?.context?.layout}`)
  const activeUserId = useSelector(({ session }) => session.userId)
  if (getCancelSourceRef.current) {
    getCancelSourceRef.current(cancelTokenSource)
  }

  const currentPayload=useRef(null)
 
  const directPostCall=async(payload)=>{
    payload=payload||{};
    const {tagname}=payload;
    let returntagname='resp';
    if (tagname) {
      returntagname=tagname;
    }
    let statusp='';
    let issuccess=false;
    let err;
    let retvalue;
    let restValue;
    try {
      ({ data:retvalue, ...restValue} = await axios({
        url: `${REACT_APP_API_ROOT_SERVER ? REACT_APP_API_ROOT_SERVER : API_ROOT_SERVER}${url}`,
        method:'POST',
        data:JSON.stringify(payload),
        headers:{
          context: activeContext,
          ...configRef.current
        },
        responseType: responseType || 'json',
      }));
      statusp="success";
      issuccess=true;
    } catch (e) {
      statusp="error";
      issuccess=false;
      err=e;
    }
    return {data: retvalue?retvalue[returntagname]||"":'',status:statusp,issuccess,err, metaData:{...restValue}}
  }
  const getUrl=(url)=>{
    
    if (useDirectPrint) {
      return `${DIRECT_PRINT_SERVER}${url}`;
    }
    return `${REACT_APP_API_ROOT_SERVER ? REACT_APP_API_ROOT_SERVER : API_ROOT_SERVER}${url}`
  }
  const postData = useCallback(async (payload) => {
    try {
      
      if (JSON.stringify(payload)!==JSON.stringify(currentPayload.current)) {
        currentPayload.current=JSON.parse(JSON.stringify(payload))
        setStatus('loading')
        setIsLoading(true)
        let config={
          url: getUrl(url),
          method,
          headers:{
            context: activeContext,
            username: activeUserId,
            ...configRef.current
          },
          responseType: responseType || 'json',
          cancelToken: cancelTokenSource?.token,
        }
        
        if (method==="POST" || method==="PUT") {
          config={...config, data:JSON.stringify(payload),}
        }
        
        const { data: info, ...rest} = await axios({...config});
        // if (info.fs) throw info
        if (resetError) dispatch(resetErrors())
        setStatus('success')
        setIsSuccess(true)
        setIsError(false)
        setData(info)
        setMetaData(rest)
        if (onSuccessRef.current) onSuccessRef.current(info)
      }
      
    } catch (err) {
      setStatus('error')
      setIsError(true)
      setIsSuccess(false)
      setError(JSON.parse(JSON.stringify(err)))
      if (onErrorRef.current) onErrorRef.current(err)
    } finally {
      setIsLoading(false)
      // You can only have onSettled OR both onError & on Sucess, but not all 3.
      if (onSettledRef.current) onSettledRef.current(data, error)
    }
  }, [url, configRef, onErrorRef, onSuccessRef, onSettledRef,currentPayload.current, activeContext])
 
  const reset = () => {
    setData(null)
    setMetaData(null)
    setError(null)
    setStatus(null)
    setIsLoading(false)
    setIsError(false)
    setIsSuccess(false)
  }

  const refetch = async() => {
    reset()
    try {
      setStatus('loading')
      setIsLoading(true)
      const { data: info, ...rest} = await axios({
        url: `${REACT_APP_API_ROOT_SERVER ? REACT_APP_API_ROOT_SERVER : API_ROOT_SERVER}${url}`,
        method:'POST',
        data:JSON.stringify(currentPayload.current),
        headers:{...configRef.current},
        responseType: responseType || 'json',
      });
      if (info.fs) throw info
      if (resetError) dispatch(resetErrors())
      setStatus('success')
      setIsSuccess(true)
      setData(info)
      setMetaData(rest)
      if (onSuccessRef.current) onSuccessRef.current(info)
      
    } catch (err) {
      setStatus('error')
      setIsError(true)
      setError(JSON.parse(JSON.stringify(err)))
      if (onErrorRef.current) onErrorRef.current(err)
    } finally {
      setIsLoading(false)
      // You can only have onSettled OR both onError & on Sucess, but not all 3.
      if (onSettledRef.current) onSettledRef.current(data, error)
    }
  }  
 
  // making this an extenable hook would create issues with hook order based
  // on the design of the useAxios hook so it's not worth it.
  useEffect(() => {
    if (showNotificationBanner) {
      switch(status) {
        case 'error':
          notify(notificationErrorMessage || error?.displayMessage, 'negative')
          break;
        case 'success':
          notify(notificationSuccessMessage || 'success', 'positive')
          break;
        default:
          break;
      }  
    }
  }, [status, error])

  useEffect(()=>{
    if (resetError) dispatch(resetErrors())
    if(dispatchError) dispatch(setErrors(data));
  },[data]);

  return { data, status, error, isError, isLoading, isSuccess, refetch,postData, metaData, directPostCall }
}
 
export default useAxiosPost