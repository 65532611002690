import React from 'react';
import { Page } from '../../components';
import ErrorManagerMain from './ErrorManagerMain';
import ErrorManagerDetailPane from './ErrorManagerDetailPane'
import RouteWrapper from '../../components/RouteWrapper';

const ErrorManager = () => (
  <RouteWrapper>
    <Page
      ListPaneContent={ErrorManagerMain}
      DetailPaneContent={ErrorManagerDetailPane}
    />
  </RouteWrapper>
);

export default ErrorManager;
