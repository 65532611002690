import { useAxios } from '../hooks'

const useActiveUserList = ({ payload, getCancelSource }) => {
  const { data, ...rest } = useAxios({
    getCancelSource,
    url: '/roles',
    method: 'POST',
    payload,
    // negativeMessage: true
  })

  return { data: data?.data, total: data?.totalRecords || undefined, ...rest}
}

export default useActiveUserList