import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { NotificationBanner } from 'ui-core-ssgr';
import RulesTabForm from '../RulesTabForm';
import RuleDetailsGridWrapper from '../RuleDetails/RuleDetailsGridWrapper';

const EditTestTab = props => {
  const {
    ruleDetails,
    selectedRecord,
    addNotification,
    isPostingRulesForRuleTypes,
    isCloningRule,
    notifications,
    detailElements,
    getRuleDetails,
    getRuleDetailElements,
    gridConfig,
    isCloningRuleDetail,
    isEditingRuleDetail,
    isPostingRuleDetail,
    isShowingProductionArrangementModal,
    isShowingCustomerServiceMessageModal,
    isShowingReturnAddressModal,
    isShowingDuplexRuleModal,
    isShowingSignatureRuleModal,
    isShowingCheckTextRuleModal,
    isShowingImageRuleModal,
    isShowingAutoHoldModal,
    isShowingMemberIdModal,
    isShowingTaxIdArrangementRuleModal,
    isShowingErisaMessageModal,
    isShowingHighDollarReviewModal,
    isShowingProviderInsuredOptionMessageModal,
    isShowingGeneralMessageModal,
    isShowingPDFTaggingModal,
    postRuleDetails,
    putRuleDetails,
    selectedRuleDetail,
    selectedRuleRecord,
    selectedRuleTypeId,
    setIsCloningRuleDetail,
    setIsEditingRuleDetail,
    setIsPostingRuleDetail,
    setIsReadOnlyRuleDetail,
    setIsShowingProductionArrangementModal,
    setIsShowingCustomerServiceMessageModal,
    setIsShowingReturnAddressModal,
    setIsShowingDuplexRuleModal,
    setIsShowingSignatureRuleModal,
    setIsShowingCheckTextRuleModal,
    setIsShowingImageRuleModal,
    setIsShowingAutoHoldModal,
    setIsShowingMemberIdModal,
    setIsShowingTaxIdArrangementRuleModal,
    setIsShowingErisaMessageModal,
    setShowingHighDollarReviewModal,
    setIsShowingProviderInsuredOptionMessageModal,
    setIsShowingGeneralMessageModal,
    setIsShowingPDFTaggingModal,
    setSelectedRuleDetail,
    deleteNotification,
    isExpanded,
    loading,
    errorNotifications,
    toggleDetailsView,
    selectedRecordStatus,
    triggerRulesForRuleTypesPutRequest,
    records,
    testImageCount,
    goBackFromAdvancedToDefaultView,
    resetDetailsPane,
    rulesForRuleTypesList,
    setIsPostingRulesForRuleTypes,
    triggerAllRulesForRuleTypesRequest,
    triggerRulesForRuleTypesPostRequest,
    setIsShowingDeleteRuleDetailModal,
    setSelectedDetailsRecordId,
    isShowingDeleteRuleDetailModal,
    deleteRuleDetailTrigger,
    setIsShowingDetailCloneRuleModal,
    setIsCloningRuleState,
    showDetailCloneRuleModal,
    setRuleTypeCommitModalOpen,
    ruleTypeCommitRuleModalOpen,
    triggerCommitRuleForRuleType,
    setIsShowingAddNewAddressModal,
    isShowingAddNewAddressModal,
    getAddNewAddressElements,
    returnAddressElements,
    postAddNewAddressElements,
    setIsShowingImageLibraryModal,
    isShowingImageLibraryModal,
    setIsShowingImageUploadModal,
    isShowingImageUploadModal,
    postImageUpload,
    setIsShowingActiveGroupsListingModal,
    showActiveGroupsListingModal,
    selectedActiveRecordsList,
    selectedRecordName,
    activeGroupsModalLocation,
    getRuleImages,
    ruleImages,
    getRuleImagesCount,
    ruleImagesCount,
    resetRuleImagesData,
    convertImageUpload,
    convertedImageUploadData,
    convertedImageUploadError,
    postImageUploadSuccessId,
    postCommitImage,
    setIsShowingCommitImageModal,
    isShowingCommitImageModal,
    postImageCommitSuccessId,
    postCommitImageTriggerCancel,
    captureCurrentPage,
    userRole,
    userPermissions,
    rulesForRuleTypes
  } = props;
  
  useEffect(() => {
    const { status } = selectedRecord;
    const [firstIndex] = status;
    if (isEmpty(ruleDetails.detailElements)) {
      getRuleDetailElements({ selectedRuleTypeId });
    }
    if (!notifications.has('postRule') && isPostingRulesForRuleTypes) {
      addNotification({
        id: 'postRule',
        icon: 'Info',
        type: 'neutral',
        msg: 'Fill out a unique rule name.',
      });
    }
    if (!notifications.has('cloneRule') && isCloningRule) {
      addNotification({
        id: 'cloneRule',
        icon: 'Info',
        type: 'neutral',
        msg: 'Fill out a unique rule name.',
      });
      if (
        firstIndex === 'L' &&
        status.length === 1
      ) {
        addNotification({
          icon: 'Info',
          id: selectedRecord.id,
          type: 'neutral',
          msg:
            'This is a mirror of the live rule. Feel free to edit this test rule.',
        });
      }
    }
    // following action is called to capture new relic data
    captureCurrentPage({pageId: `${gridConfig.pageTrackerPrefix}${selectedRuleTypeId}`});
  }, []);

  return (
    <>
      {notifications.has('ruleDetailsGridError') && (
        <NotificationBanner
          domID="api-error-notification"
          type={notifications.get('ruleDetailsGridError').type}
          text={notifications.get('ruleDetailsGridError').msg}
          onDismiss={() => deleteNotification('ruleDetailsGridError')}
          autoDismiss
          timer={1800000}
        />
      )}
      <RulesTabForm
        count={records.length}
        deleteNotification={deleteNotification}
        goBackFromAdvancedToDefaultView={goBackFromAdvancedToDefaultView}
        isExpanded={isExpanded}
        isPostingRulesForRuleTypes={isPostingRulesForRuleTypes}
        loading={loading}
        notifications={notifications}
        resetDetailsPane={resetDetailsPane}
        rulesForRuleTypesList={rulesForRuleTypesList}
        selectedRuleRecord={selectedRecord}
        selectedRuleRecordStatus={selectedRecordStatus}
        setIsPostingRulesForRuleTypes={setIsPostingRulesForRuleTypes}
        toggleDetailsView={toggleDetailsView}
        triggerAllRulesForRuleTypesRequest={
          triggerAllRulesForRuleTypesRequest
        }
        triggerRulesForRuleTypesPutRequest={
          triggerRulesForRuleTypesPutRequest
        }
        triggerRulesForRuleTypesPostRequest={
          triggerRulesForRuleTypesPostRequest
        }
        isCloningRule={isCloningRule}
        setIsCloningRuleState={setIsCloningRuleState}
        ruleTypeCommitRuleModalOpen={ruleTypeCommitRuleModalOpen}
        setRuleTypeCommitModalOpen={setRuleTypeCommitModalOpen}
        triggerCommitRuleForRuleType={triggerCommitRuleForRuleType}
        getRuleDetailElements={getRuleDetailElements}
        selectedRuleTypeId={selectedRuleTypeId}
        setIsShowingActiveGroupsListingModal={setIsShowingActiveGroupsListingModal}
        showActiveGroupsListingModal={showActiveGroupsListingModal}
        selectedActiveRecordsList={selectedActiveRecordsList}
        selectedRecordName={selectedRecordName}
        activeGroupsModalLocation={activeGroupsModalLocation}
        testImageCount={testImageCount}
        userRole={userRole}
        userPermissions={userPermissions}
      />
      <RuleDetailsGridWrapper
        key={selectedRecord.id}
        detailElements={detailElements}
        getRuleDetails={getRuleDetails}
        getRuleDetailElements={getRuleDetailElements}
        goBackFromAdvancedToDefaultView={goBackFromAdvancedToDefaultView}
        gridConfig={gridConfig}
        isCloningRuleDetail={isCloningRuleDetail}
        isEditingRuleDetail={isEditingRuleDetail}
        isPostingRuleDetail={isPostingRuleDetail}
        isShowingProductionArrangementModal={
          isShowingProductionArrangementModal
        }
        isShowingCustomerServiceMessageModal={
          isShowingCustomerServiceMessageModal
        }
        isShowingReturnAddressModal={isShowingReturnAddressModal}
        isShowingDuplexRuleModal={isShowingDuplexRuleModal}
        isShowingSignatureRuleModal={isShowingSignatureRuleModal}
        isShowingCheckTextRuleModal={isShowingCheckTextRuleModal}
        isShowingImageRuleModal={isShowingImageRuleModal}
        isShowingAutoHoldModal={isShowingAutoHoldModal}
        isShowingMemberIdModal={isShowingMemberIdModal}
        isShowingTaxIdArrangementRuleModal={
          isShowingTaxIdArrangementRuleModal
        }
        isShowingErisaMessageModal={isShowingErisaMessageModal}
        isShowingHighDollarReviewModal={isShowingHighDollarReviewModal}
        isShowingProviderInsuredOptionMessageModal={
          isShowingProviderInsuredOptionMessageModal
        }
        isShowingGeneralMessageModal={isShowingGeneralMessageModal}
        isShowingPDFTaggingModal={isShowingPDFTaggingModal}
        postRuleDetails={postRuleDetails}
        putRuleDetails={putRuleDetails}
        records={records}
        selectedRuleDetail={selectedRuleDetail}
        selectedRuleRecord={selectedRuleRecord}
        selectedRuleTypeId={selectedRuleTypeId}
        setIsCloningRuleDetail={setIsCloningRuleDetail}
        setIsEditingRuleDetail={setIsEditingRuleDetail}
        setIsPostingRuleDetail={setIsPostingRuleDetail}
        setIsReadOnlyRuleDetail={setIsReadOnlyRuleDetail}
        setIsShowingProductionArrangementModal={
          setIsShowingProductionArrangementModal
        }
        setIsShowingCustomerServiceMessageModal={
          setIsShowingCustomerServiceMessageModal
        }
        setIsShowingReturnAddressModal={setIsShowingReturnAddressModal}
        setIsShowingDuplexRuleModal={setIsShowingDuplexRuleModal}
        setIsShowingSignatureRuleModal={setIsShowingSignatureRuleModal}
        setIsShowingCheckTextRuleModal={setIsShowingCheckTextRuleModal}
        setIsShowingImageRuleModal={setIsShowingImageRuleModal}
        setIsShowingAutoHoldModal={setIsShowingAutoHoldModal}
        setIsShowingMemberIdModal={setIsShowingMemberIdModal}
        setIsShowingTaxIdArrangementRuleModal={
          setIsShowingTaxIdArrangementRuleModal
        }
        setIsShowingErisaMessageModal={setIsShowingErisaMessageModal}
        setShowingHighDollarReviewModal={setShowingHighDollarReviewModal}
        setIsShowingProviderInsuredOptionMessageModal={
          setIsShowingProviderInsuredOptionMessageModal
        }
        setIsShowingGeneralMessageModal={setIsShowingGeneralMessageModal}
        setIsShowingPDFTaggingModal={setIsShowingPDFTaggingModal}
        setSelectedRuleDetail={setSelectedRuleDetail}
        toggleDetailsView={toggleDetailsView}
        isPostingRulesForRuleTypes={isPostingRulesForRuleTypes}
        setIsShowingDeleteRuleDetailModal={setIsShowingDeleteRuleDetailModal}
        setSelectedDetailsRecordId={setSelectedDetailsRecordId}
        selectedRecord={selectedRecord}
        isShowingDeleteRuleDetailModal={isShowingDeleteRuleDetailModal}
        deleteRuleDetailTrigger={deleteRuleDetailTrigger}
        loading={loading}
        setIsShowingDetailCloneRuleModal={setIsShowingDetailCloneRuleModal}
        setIsCloningRuleState={setIsCloningRuleState}
        showDetailCloneRuleModal={showDetailCloneRuleModal}
        isCloningRule={isCloningRule}
        triggerAllRulesForRuleTypesRequest={
          triggerAllRulesForRuleTypesRequest
        }
        rulesForRuleTypesList={rulesForRuleTypesList}
        setRuleTypeCommitModalOpen={setRuleTypeCommitModalOpen}
        ruleTypeCommitRuleModalOpen={ruleTypeCommitRuleModalOpen}
        triggerCommitRuleForRuleType={triggerCommitRuleForRuleType}
        setIsShowingAddNewAddressModal={setIsShowingAddNewAddressModal}
        isShowingAddNewAddressModal={isShowingAddNewAddressModal}
        getAddNewAddressElements={getAddNewAddressElements}
        returnAddressElements={returnAddressElements}
        postAddNewAddressElements={postAddNewAddressElements}
        addNotification={addNotification}
        notifications={notifications}
        errorNotifications={errorNotifications}
        setIsShowingImageLibraryModal={setIsShowingImageLibraryModal}
        isShowingImageLibraryModal={isShowingImageLibraryModal}
        setIsShowingImageUploadModal={setIsShowingImageUploadModal}
        isShowingImageUploadModal={isShowingImageUploadModal}
        postImageUpload={postImageUpload}
        getRuleImages={getRuleImages}
        ruleImages={ruleImages}
        getRuleImagesCount={getRuleImagesCount}
        ruleImagesCount={ruleImagesCount}
        resetRuleImagesData={resetRuleImagesData}
        convertImageUpload={convertImageUpload}
        convertedImageUploadData={convertedImageUploadData}
        convertedImageUploadError={convertedImageUploadError}
        postImageUploadSuccessId={postImageUploadSuccessId}
        postCommitImage={postCommitImage}
        setIsShowingCommitImageModal={setIsShowingCommitImageModal}
        isShowingCommitImageModal={isShowingCommitImageModal}
        postImageCommitSuccessId={postImageCommitSuccessId}
        postCommitImageTriggerCancel={postCommitImageTriggerCancel}
        captureCurrentPage={captureCurrentPage}
        rulesForRuleTypes={rulesForRuleTypes}
      />
    </>
  );
};

EditTestTab.propTypes = {
  deleteNotification: PropTypes.func,
  loading: PropTypes.object,
  notifications: PropTypes.object,
  records: PropTypes.array,
  goBackFromAdvancedToDefaultView: PropTypes.func,
  isPostingRulesForRuleTypes: PropTypes.bool,
  rulesForRuleTypesList: PropTypes.object,
  triggerAllRulesForRuleTypesRequest: PropTypes.func,
  toggleDetailsView: PropTypes.func.isRequired,
  selectedRecord: PropTypes.shape({
    id: PropTypes.string,
  }),
  addNotification: PropTypes.func,
  setIsShowingDeleteRuleDetailModal: PropTypes.func,
  deleteRuleDetailTrigger: PropTypes.func,
  setRuleTypeCommitModalOpen: PropTypes.func,
  ruleTypeCommitRuleModalOpen: PropTypes.bool,
  triggerCommitRuleForRuleType: PropTypes.func,
  detailElements: PropTypes.object,
  getRuleDetails: PropTypes.func,
  getRuleDetailElements: PropTypes.func,
  gridConfig: PropTypes.object,
  isCloningRuleDetail: PropTypes.bool,
  isEditingRuleDetail: PropTypes.bool,
  isPostingRuleDetail: PropTypes.bool,
  isShowingProductionArrangementModal: PropTypes.bool,
  isShowingCustomerServiceMessageModal: PropTypes.bool,
  isShowingReturnAddressModal: PropTypes.bool,
  isShowingDuplexRuleModal: PropTypes.bool,
  isShowingSignatureRuleModal: PropTypes.bool,
  isShowingCheckTextRuleModal: PropTypes.bool,
  isShowingImageRuleModal: PropTypes.bool,
  isShowingAutoHoldModal: PropTypes.bool,
  isShowingMemberIdModal: PropTypes.bool,
  isShowingTaxIdArrangementRuleModal: PropTypes.bool,
  isShowingErisaMessageModal: PropTypes.bool,
  isShowingHighDollarReviewModal: PropTypes.bool,
  isShowingProviderInsuredOptionMessageModal: PropTypes.bool,
  isShowingGeneralMessageModal: PropTypes.bool,
  isShowingPDFTaggingModal: PropTypes.bool,
  postRuleDetails: PropTypes.func,
  putRuleDetails: PropTypes.func,
  selectedRuleDetail: PropTypes.object,
  selectedRuleRecord: PropTypes.object,
  selectedRuleTypeId: PropTypes.string,
  setIsCloningRuleDetail: PropTypes.func,
  setIsEditingRuleDetail: PropTypes.func,
  setIsPostingRuleDetail: PropTypes.func,
  setIsShowingProductionArrangementModal: PropTypes.func,
  setIsShowingCustomerServiceMessageModal: PropTypes.func,
  setIsShowingReturnAddressModal: PropTypes.func,
  setIsShowingDuplexRuleModal: PropTypes.func,
  setIsShowingSignatureRuleModal: PropTypes.func,
  setIsShowingCheckTextRuleModal: PropTypes.func,
  setIsShowingImageRuleModal: PropTypes.func,
  setIsShowingAutoHoldModal: PropTypes.func,
  setIsShowingMemberIdModal: PropTypes.func,
  setIsShowingTaxIdArrangementRuleModal: PropTypes.func,
  setIsShowingErisaMessageModal: PropTypes.func,
  setShowingHighDollarReviewModal: PropTypes.func,
  setIsShowingProviderInsuredOptionMessageModal: PropTypes.func,
  setIsShowingGeneralMessageModal: PropTypes.func,
  setIsShowingPDFTaggingModal: PropTypes.func,
  setSelectedRuleDetail: PropTypes.func,
  isCloningRule: PropTypes.bool,
  setIsShowingAddNewAddressModal: PropTypes.func,
  isShowingAddNewAddressModal: PropTypes.bool,
  getAddNewAddressElements: PropTypes.func,
  returnAddressElements: PropTypes.object,
  postAddNewAddressElements: PropTypes.func,
  setIsShowingImageLibraryModal: PropTypes.func,
  isShowingImageLibraryModal: PropTypes.bool,
  setIsShowingImageUploadModal: PropTypes.func,
  isShowingImageUploadModal: PropTypes.bool,
  postImageUpload: PropTypes.func,
  errorNotifications: PropTypes.object,
  setSelectedDetailsRecordId: PropTypes.func,
  isShowingDeleteRuleDetailModal: PropTypes.bool,
  setIsShowingDetailCloneRuleModal: PropTypes.func,
  setIsCloningRuleState: PropTypes.func,
  showDetailCloneRuleModal: PropTypes.bool,
  setIsShowingActiveGroupsListingModal: PropTypes.func,
  showActiveGroupsListingModal: PropTypes.bool,
  isExpanded: PropTypes.bool,
  selectedRecordStatus: PropTypes.array,
  triggerRulesForRuleTypesPutRequest: PropTypes.func,
  resetDetailsPane: PropTypes.func,
  setIsPostingRulesForRuleTypes: PropTypes.func,
  triggerRulesForRuleTypesPostRequest: PropTypes.func,
  convertedImageUploadData: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  convertedImageUploadError: PropTypes.string,
  postImageUploadSuccessId: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  postCommitImage: PropTypes.func,
  setIsShowingCommitImageModal: PropTypes.func,
  isShowingCommitImageModal: PropTypes.object,
  postImageCommitSuccessId: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  postCommitImageTriggerCancel: PropTypes.func,
  captureCurrentPage: PropTypes.func,
};

export default EditTestTab;