import { SET_CONTEXT, SET_PERMISSIONS, TOGGLE_MODAL } from './constants';

const initialState = {
  context: undefined,
  isModalOpen: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CONTEXT:
      return {
        ...state,
        context: action.payload
      }
    case SET_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload
      }
    case TOGGLE_MODAL:
      return {
        ...state,
        isModalOpen: !state.isModalOpen
      }
    default:
      return state
  }
}