import uniqBy from 'lodash/uniqBy';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import get from 'lodash/get';

/**
 * Function for validating the message being used to add a rule
 * @param {String} message being used for adding the rule
 * Message must not be empty
 * Message must be at least 1 character
 * Message must be less than 4000 characters
 */
export const validMessage = message => message.length >= 1 && message.length <= 4000 && !(/^\s*$/).test(message);

/**
 * Function used in order to get the selected records rule version
 * @param {Object} record is the record we will check to get the rule version 
 */
export const getRuleVersion = record => {
  try {
    const { status, version } = record;
    const testVersion = version[status.indexOf('T')];
    const liveVersion = version[status.indexOf('L')];

    return testVersion || liveVersion;
  } catch (e) {
    console.log(e);
  }
  return null;
};

/**
 * Function for populating the data dropdown values
 * @param {String} listName is the name of the list elements
 * @param {String} name is the name of the elements
 * @param {Object} detailElements are the elements that are used to populdate the dropdowns for claimTypes, recipientTypes, documentTypes
 * @param {String} selectedRuleTypeId is the ID of the rule type that is selected
 * @param {Object} selectedRuleDetail is the detail object of the rule that is currently selected
 */
export const populateDataDropDown = ({
  listName,
  name,
  detailElements,
  selectedRuleTypeId,
  selectedRuleDetail
}) => {
  if (!isEmpty(detailElements) && selectedRuleTypeId === 'CSM') {
    const findDetailElement = detailElements[listName];
    const matchingRule = find(findDetailElement, x => x.value === selectedRuleDetail[name]);
    const arr = selectedRuleDetail[name] && matchingRule
      ? [...new Set([matchingRule, ...findDetailElement])]
      : detailElements[listName];
    return uniqBy(arr, 'value')
      .map(r => ({
        label: r.value,
        value: r.code,
      })
      ).filter(d => d.value);
  }
  return [];
};

/**
 * Function for setting the original values of the dropdowns for the message modals
 * @param {Object} Object the original object parameter that is being supplied to this function with parameters as listed below
 * @param {Boolean} isPostingRuleDetail is the boolean that determines whether or not someone is adding a rule detail
 * @param {Object} selectedRuleDetail is the object of details of the currently selected rule
 * @param {Object} detailElements are the elements that are used to populdate the dropdowns for claimTypes, recipientTypes, documentTypes
 */
export const getInitialMessageModalValues = ({ isPostingRuleDetail, selectedRuleDetail, detailElements }) => {
  const messageValue = !isPostingRuleDetail ? get(selectedRuleDetail, 'message', '') : '';
  const buttonDisabled = true;
  let claimTypeValue = 'default';
  let recipientTypeValue = 'default';
  let documentTypeValue = 'default';

  if (!isPostingRuleDetail) {
    /**
     * For this we are doing a check on the claimTypesList, recipientTypesList, and documentTypesList
     * Using the get from lodash to make sure the code does not break the page and return a blank page
     */
    const detailElementsClaimTypesList = get(detailElements, 'claimTypesList', []);
    const detailElementsRecipientTypesList = get(detailElements, 'recipientTypesList', []);
    const detailElementsDocumentTypesList = get(detailElements, 'documentTypesList', []);

    // For here we are getting the claimType, recipientType, and documentType from the selectedRuleDetail to populate the values
    const selectedClaimType = get(selectedRuleDetail, 'claimType', '');
    const selectedRecipientType = get(selectedRuleDetail, 'recipientType', '');
    const selectedDocumentType = get(selectedRuleDetail, 'documentType', '');

    /**
     * Down here for these checks, once again we are using the get method of lodash just in case if something comes back blank
     * We are wrapping the find method inside of a get method for additional checking to make sure that the code doesn't come back blank
     */
    claimTypeValue = get(find(detailElementsClaimTypesList, o => o.value === selectedClaimType), 'code', 'default');
    recipientTypeValue = get(find(detailElementsRecipientTypesList, o => o.value === selectedRecipientType), 'code', 'default');
    documentTypeValue = get(find(detailElementsDocumentTypesList, o => o.value === selectedDocumentType), 'code', 'default');
  }

  return {
    claimTypeValue,
    recipientTypeValue,
    documentTypeValue,
    messageValue,
    buttonDisabled
  }
};