
const initialState = {
    financeManagerTierData:[],
    refetchTierData:null,
    ncoaDropdownData:[]
}
export default (state=initialState,{type,payload})=>{
    switch(type){
    case 'SET_FINANCE_MANAGER_TIER_DATA':{
        return {
          ...state,
          financeManagerTierData:JSON.parse(JSON.stringify(payload.financeManagerTierData)),
          refetchTierData:payload.refetchTierData
        }
      }
    case 'SET_NCOA_DROPDOWN_DATA':{
            return {
              ...state,
              ncoaDropdownData:JSON.parse(JSON.stringify(payload.ncoaDropdownData)),
            }
          }
      
      default:
      return state;
    }
}