
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Grid,
  Toggle,
  Tooltip
} from 'ui-core/dist'
import { useContextError } from '../../api/useDashboard';
import {
  LoadingPage,
} from '../../components';
import withAxiosCancellation from '../../components/withAxiosCancellation'

const ContextError = ({getCancelSourceWithName}) => {
  const { accessEntryId } = useSelector(({ context }) => context?.context || {});
  const [payload, setPayload] = useState({
    ds: accessEntryId,
    showAllContextErr: false,
  });
  const [contextErrData, setContextErrData] = useState([]);
  const { data: contextErrList, isLoading: cIsLoading } = useContextError(payload.showAllContextErr && payload.ds === accessEntryId,getCancelSourceWithName('contextError'));

  useEffect(() => {
    const newList = contextErrList && contextErrList.length && contextErrList.map(ctx => {
      return {
        ...ctx,
        name: <Tooltip
          caretToAnchor={8}
          dataTestId="test-DataSourceToolTip"
          domID="DataSourceToolTip"
          tooltipContent={ctx.name}
          tooltipPosition="top-center"
          tooltipWidth={300}
        >
          {ctx.name}
        </Tooltip>
      }
    })
    setContextErrData(newList);
  }, [contextErrList]);

  useEffect(() => {
    setPayload({
      ds: accessEntryId,
      showAllContextErr: false
    });
  }, [accessEntryId]);

  return (
    <>
      <div className='contextErrSet'>
        <h3 className="contactHeader">Context Errors</h3>
        <div className='contextErrToggle'>
          <Toggle
            initialValue={payload.showAllContextErr && payload.ds === accessEntryId}
            altTextForCenterToggle="View Active vs All Context Errors"
            dataTestId="ContextErrSwitch"
            leftLabel="ACTIVE"
            onToggle={(e, state) => {
              if (state.isToggled) setPayload({
                ds: payload.ds,
                showAllContextErr: true
              })
              else setPayload({
                ds: payload.ds,
                showAllContextErr: false
              })
            }}
            rightLabel="ALL"
          />
        </div>
      </div>
      {
        cIsLoading ? (<LoadingPage height='20vh' />) : (
          <Grid
            columns={new Set([
              {
                dataName: 'name',
                text: 'Data Source',
                cellType: 'text',
              },
              {
                dataName: 'liveErrors',
                text: 'Live Errors',
                cellType: 'custom',
              },
              {
                dataName: 'testErrors',
                text: 'Test Errors',
                cellType: 'custom',
              }]
            )}
            dataTestId="ContextErrGrid"
            records={contextErrData && contextErrData.map(data => {
              return {
                ...data,
                testErrors: (
                  <span
                    style={{ color: 'red' }}
                  >
                    {data.testErrors}
                  </span>
                ),
                liveErrors: (
                  <span
                    style={{ color: 'red' }}
                  >
                    {data.liveErrors}
                  </span>
                )
              }
            }) || []}
          />
        )
      }
    </>
  )
}

export default withAxiosCancellation(ContextError);