import React, { useState, useMemo,useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Grid, TextArea, Button, ButtonGroup, ModalBody } from 'ui-core/dist'
import ModalHeaderWrapper from '../../components/ErrorDisplaySection/ModalHeaderWrapper';
import { LoadingPage } from '../../components';
import { ERROR_MANAGER_MAIN_GRID_COLUMN } from './Modules/constants';
import './Modules/styles.scss';
import {useUpdateResolveStatus,useUpdateResolveAllStatus} from '../../api/useErrorManager';
import { setResolutionModal } from './Modules/Action';

const ResolutionConfirmationModal = ({modalClose,selectedRecords}) => {
  const { resolutionModal, isAll } = useSelector(({ errorManager }) => errorManager);
  const [pstrNoteText, setNoteText]=useState('');
  const dispatch = useDispatch();
  
  const { postData: updateResolveStatus, isLoading }= useUpdateResolveStatus({
    onSuccess: () => modalClose(true)
  });
  const { postData: updateResolveAll,isLoadingD }= useUpdateResolveAllStatus({
    onSuccess: () => modalClose(true)
  });
  

  const handleResolve = () => {
    const pstrErrorIds = selectedRecords.map(x =>  x.id);
    const payload = {
      pstrNewStatusId: "03",
      pstrErrorIds,
      pstrNoteText,
      bPurge: false
    };
    updateResolveStatus(payload)
    setNoteText('')
  }
  
  const handleResolvePurge = () => {
    const pstrErrorIds = selectedRecords.map(x =>  x.id);
    const payload = {
      pstrNewStatusId: "03",
      pstrErrorIds,
      pstrNoteText,
      bPurge: true
    };
    updateResolveStatus(payload)
    setNoteText('')
  }

  const handleResolveAll = () => {
    const payload = {
      pstrNewStatusId: "03",
      pstrNoteText,
      bPurge: false
    };
    updateResolveAll(payload)
    setNoteText('')
  }

  const handleResolvePurgeAll = () => {
    const payload = {
      pstrNewStatusId: "03",
      pstrNoteText,
      bPurge: true
    };
    updateResolveAll(payload)
    setNoteText('')
  }

  const renderGridContent=useMemo(()=>{
    const data= selectedRecords.map((record)=>({
      ...record,
      error_id:record.id
    }))

    return (
      <Grid
        {...ERROR_MANAGER_MAIN_GRID_COLUMN}
        dataTestId="test-gridDefault"
        domID="test-id"
        records={data || []}
        supportSelection={false}
      />
    );
  },[selectedRecords]);

  return (
    <Modal 
      isOpen={resolutionModal}
      className="resolution-modal"
      domID="ResolutionModal"
      contentClassName="resolution-modal__content"
    >
      <ModalHeaderWrapper
        title="Error Manager"
        onClose={() => {
          
          dispatch(setResolutionModal(false))
          setNoteText('')
        }}
      />
      <ModalBody>
        {isLoading || isLoadingD ? (
        // replace with a better loading component
          <LoadingPage />
        ) : (
          <div className="resolution-modal__body">
            <p className="resolution-modal__text">
              { 
                `Select to resolve ${isAll ? 'all' : 'chosen'} errors, or to resolve ${isAll ? 'all errors' : 'errors'} and purge documents, for this client and layout. Note: If documents have already processed through MCDS production, the documents will not be purged.`
              }
            </p>
            <div className="resolution-modal__section">
              <TextArea
                label="Note Text :"
                className="test-classname"
                domID="test_id"
                placeholder="Add a note!"
                resize="both"
                initialValue= {pstrNoteText}
                width="400px"
                onChange={(e)=> setNoteText(e.target.value)}
                draggable={false}
              />
            </div>
            {isAll ? undefined : (
              <div className='resolution-modal__section' style={{maxHeight:'110px',overflow:'auto',width:'710px'}}>
                {renderGridContent}
              </div>
            )}
            <ButtonGroup>
              {isAll ? (
                <>
                  <Button
                    domID="ResolveButton"
                    buttonType="emphasized"
                    size="medium"
                    name="Resolve"
                    onClick={handleResolveAll}
                  />
                  <Button
                    domID="ResolvePurgeButton"
                    buttonType="standard"
                    size="medium"
                    name="Resolve/Purge"
                    onClick={handleResolvePurgeAll}
                  />
                </>
              ) : (
                <>
                  <Button
                    domID="ResolveButton"
                    buttonType="emphasized"
                    size="medium"
                    name="Resolve"
                    onClick={handleResolve}
                  />
                  <Button
                    domID="ResolvePurgeButton"
                    buttonType="standard"
                    size="medium"
                    name="Resolve/Purge"
                    onClick={handleResolvePurge}
                  />
                </>
              )}
            </ButtonGroup>
          </div>
        )}
      </ModalBody>
    </Modal>
  )
}

export default ResolutionConfirmationModal
