import { connect } from 'react-redux';
import LiveAssocDetailsForm from './components/LiveAssocDetailsForm';
import { deleteAllNotifications } from '../../redux/app/notificationState/actions';
import { triggerGetLiveAssocRuleTypesRequest } from '../../redux/api/liveAssocRuleTypesList/actions';
import { selectLiveAssocRuleTypesList, selectLoadingState } from './selectors';

const mapStateToProps = state => ({
  records: selectLiveAssocRuleTypesList(state),
  loading: selectLoadingState(state),
});

const mapDispatchToProps = {
  triggerGetLiveAssocRuleTypesRequest,
  deleteAllNotifications,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LiveAssocDetailsForm);
