import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { useSelector, useDispatch } from 'react-redux';
import PageLayout from 'ui-core/dist/PageLayout';
import axios from '../../axios';
import useNotificationBannerHook from '../../useNotificationBannerHook';
import integrations from '../../integrations';
import { APP_ID } from './modules/constants';
import SelectContextModal from '../SelectContextModal';
import {
  onAvatarMenuSelect,
} from './modules/actions';
import { useLogout } from '../../hooks';
import { toggleCtxModal } from '../ChooseContext/modules/actions';
import InactivityTracker from '../InactivityTracker';
import './style.scss';
import MaintenanceConfigModal from '../MaintenanceConfigModal';

const PageWrapper = ({
  navigationPaneContent = null,
  listPaneContent = <></>,
  detailPaneContent = null,
}) => {
  const {
    userFirstName,
    userEmail,
    supportMenuConfig,
    avatarMenuItems,
    roleName,
    dataSourceDesc
  } = useSelector(store => ({
    userFirstName: store.session.name,
    userEmail: store.session.email,
    avatarMenuItems: store.app.avatarMenuItems,
    avatarImage: store.app.user.photo,
    supportMenuConfig: store.app.supportMenuItems,
    roleName: store.context?.context?.roleName,
    dataSourceDesc: store.context?.context?.dataSourceDesc,
  }));
  const canPerformMaintenanceConfig = useSelector(store => store.context?.permissions?.filter(p => p.name === 'CAN_PERFORM_MAINTENANCE_CONFIG').length > 0);
  const userType = useSelector(state => state?.session?.type);

  const { notify } = useNotificationBannerHook();
  const dispatch = useDispatch();
  const logout = useLogout();

  const getFAQ = async () => {
    try {
      const res = await axios.get(`/faq`, { responseType: 'blob' });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
        window.navigator.msSaveOrOpenBlob(res.data, 'FQA.pdf');
      } else {
        const url = URL.createObjectURL(res.data);
        const tab = window.open();
        if (tab)
          tab.location.href = url;
        else
          notify('Please enable allow window pop-up in your browser.', 'caution');
      }
    } catch (err) {
      notify('Error in displaying FAQ', "negative");
    }
  };

  const handleSupportMenuSelect = (event, { activeItem }) => {
    event.stopPropagation();
    if (activeItem.label === 'Change Context') dispatch(toggleCtxModal());
    if (activeItem.label === 'FAQ') getFAQ();
  };

  const handleAvatarMenuSelect = (event, { activeItem }, state) => {
    dispatch(onAvatarMenuSelect(state));
    if (activeItem.path) {
      dispatch(push(activeItem.path));
    }
  };

  return (
    <>
      <InactivityTracker />
      <PageLayout
        mastheadProps={{
          domID: "Masthead",
          dataTestId: "test-Masthead",
          productNameOneLine: true,
          onSupportMenuSelect: handleSupportMenuSelect,
          onLogOutSelect: logout,
          onAvatarMenuSelect: handleAvatarMenuSelect,
          userFirstName,
          userLastName: '',
          userEmail,
          integrations,
          appId: APP_ID,
          supportMenuConfig,
          avatarMenuItems,
          customUserMenuItems: [{
            sectionItems: [{
              id: 'asdfa',
              label: `${dataSourceDesc}: ${roleName}`,
            }]
          }],
          currentProductName: `Client Access System`,
        }}
        navigationPaneContent={navigationPaneContent}
        listPaneContent={listPaneContent}
        detailPaneContent={detailPaneContent}
      />
      <SelectContextModal />
      {/* {(canPerformMaintenanceConfig && userType === 'I') && <MaintenanceConfigModal />} */}
    </>
  );
};

PageWrapper.propTypes = {
  navigationPaneContent: PropTypes.any,
  listPaneContent: PropTypes.any,
  detailPaneContent: PropTypes.any,
};

PageWrapper.defaultProps = {
  navigationPaneContent: null,
  listPaneContent: null,
  detailPaneContent: null,
};

export default PageWrapper;
