import React, { useState, useEffect } from 'react'
import {
  Grid,
  Input,
  useLayout,
  ListPaneHeader,
  Button
} from 'ui-core/dist'
import axios from '../../axios';
import useNotificationBannerHook from '../../useNotificationBannerHook';
import { useStatesRequest, usePayerIdsRequest } from '../../api/useReportManager';
import GridPaginationWrapper from '../../components/GridPaginationWrapper';
import { LoadingPage, SelectDropdown } from '../../components';
import ErrorPage from '../ErrorPage';
import withAxiosCancellation from '../../components/withAxiosCancellation';
import './modules/style.scss'

const EftProviderReport = ({getCancelSourceWithName}) => {
  const {
    expandCollapseListPaneButtonConfig
  } = useLayout()
  const { notify } = useNotificationBannerHook();

  const gridColumns = new Set([{
    dataName: 'FinAcctAgn',
    text: 'Financial Acct',
    cellType: 'text',
  }, {
    dataName: 'TaxId',
    text: 'TIN',
    cellType: 'text',
  }, {
    dataName: 'PrvName',
    text: 'Name',
    cellType: 'text',
  }, {
    dataName: 'Npi',
    text: 'NPI',
    cellType: 'text',
  }, {
    dataName: 'PyrAssgndId',
    text: 'Payer Assigned ID',
    cellType: 'text',
  }, {
    dataName: 'SupPrn835',
    text: 'Supp Print 835',
    cellType: 'text',
  }, {
    dataName: 'SupPrnCnv',
    text: 'Supp Print Conv',
    cellType: 'text',
  }, {
    dataName: 'SupPrnRmtImg',
    text: 'Supp Print Remit Img',
    cellType: 'text',
  }, {
    dataName: 'OrgDt',
    text: 'Original Date',
    cellType: 'text',
  }, {
    dataName: 'AcctStatus',
    text: 'Status',
    cellType: 'text',
  }, {
    dataName: 'Chk21',
    text: 'Check 21',
    cellType: 'text',
  }, {
    dataName: 'PreNtVld',
    text: 'Prenote Validated',
    cellType: 'text',
  }, {
    dataName: 'SigRcvd',
    text: 'Signature Rcvd',
    cellType: 'text',
  }, {
    dataName: 'AcctNum',
    text: 'Acct Number',
    cellType: 'text',
  }, {
    dataName: 'RouteNum',
    text: 'Route Number',
    cellType: 'text',
  }, {
    dataName: 'Addr1',
    text: 'Addr1',
    cellType: 'text',
  }, {
    dataName: 'Addr2',
    text: 'Addr2',
    cellType: 'text',
  }, {
    dataName: 'Addr3',
    text: 'Addr3',
    cellType: 'text',
  }, {
    dataName: 'City',
    text: 'City',
    cellType: 'text',
  }, {
    dataName: 'State',
    text: 'State',
    cellType: 'text',
  }, {
    dataName: 'Zip',
    text: 'Zip',
    cellType: 'text',
  }
  ]);

  const { statesList: statesList, isLoading: isStatesLoading, error, isError } = useStatesRequest({getCancelSource:getCancelSourceWithName('stateRequest')});
  const { payersList: payersList, isLoading: isPayersLoading } = usePayerIdsRequest({getCancelSource:getCancelSourceWithName('payerIdsRequest')});
  const [eftRecordsList, setEftRecordsList] = useState();
  const [isReportLoading, setIsReportLoading] = useState(false);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [stateValue, setStateValue] = useState('');
  const [payerValue, setPayerValue] = useState('');
  const [startRow, setStartRow] = useState(1);
  const [rowCount, setRowCount] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [providerTIN, setProviderTIN] = useState('');
  const [providerName, setProviderName] = useState('');
  const [npi, setNPI] = useState('');
  const [generic, setGeneric] = useState('');

  useEffect(() => {
    setStateValue(statesList && statesList[0] && statesList[0].value)
  }, [statesList])

  useEffect(() => {
    setPayerValue(payersList && payersList[0] && payersList[0].value)
  }, [payersList])

  const handlePayerOptionChange = (e) => {
    setPayerValue(e?.value);
  }

  const handleStateOptionChange = (e) => {
    setStateValue(e?.value);
  }

  const getReport = () => {
    setIsSubmitClicked(true);
    setIsReportLoading(true);
    axios.post(`reports-manager/provider-eft-report/`,
      {
        rptStartRow: startRow,
        rptRowCount: rowCount,
        rptProviderTin: providerTIN,
        rptProviderName: providerName,
        rptState: stateValue,
        rptNpi: npi,
        rptPayerId: payerValue,
        rptGeneric: generic
      })
      .then(res => {
        setEftRecordsList(res?.data?.data);
        setTotalRecords(res?.data?.totalRecords);
        setIsReportLoading(false);
      }).catch(err => {
        notify(`${err.displayMessage}`, 'negative');
        setIsSubmitClicked(false)
        setIsReportLoading(false);
      })
  }

  return (
    <>
      <ListPaneHeader
        title={`EFT Provider Report`}
        layoutControls={[expandCollapseListPaneButtonConfig]}
      />
      <div style={{ paddingLeft: '50px' }}>
        <h4>Data Script Report</h4>
        {
          isPayersLoading || isStatesLoading ? <LoadingPage height='20px'/> : 
          isError ? (
            // replace with a better error component
            <ErrorPage
              // this prop isn't real but is an example
              error={error}
            />
          ) :
          <table id="eftProviderReport">
            <tr>
              <td  >
                {
                  (<SelectDropdown
                    width="250px"
                    isClearable={false}
                    size="small"
                    options={payersList}
                    initialValue={(payersList && payersList.find(payer => payer.value === payerValue)) || payersList[0]}
                    onChange={(e) => handlePayerOptionChange(e)}
                    label="Payer ID"
                  />)
                }
              </td>
            </tr>
            <tr>
              <td  >
                {
                  (<SelectDropdown
                    width="250px"
                    isClearable={false}
                    size="small"
                    options={statesList}
                    initialValue={(statesList && statesList.find(state => state.value === stateValue)) || statesList[0]}
                    onChange={(e) => handleStateOptionChange(e)}
                    label="State"
                  />)
                }
              </td>
            </tr>
            <tr >
              <td ><label> Provider TIN </label>
                <Input
                  style={{ width: '250px' }}
                  domID="providerTIN"
                  size="small"
                  onChange={(e) => {
                    setProviderTIN(e.target.value || "")
                  }}
                /></td>
            </tr>
            <tr>
              <td ><label> Provider Name </label>
                <Input
                  style={{ width: '250px' }}
                  domID="providerName"
                  size="small"
                  onChange={(e) => {
                    setProviderName(e.target.value || "")
                  }}
                /></td>
            </tr>
            <tr>
              <td ><label> NPI	</label>
                <Input
                  style={{ width: '250px' }}
                  domID="NPI"
                  size="small"
                  onChange={(e) => {
                    setNPI(e.target.value || "")
                  }}
                /></td>
            </tr>
            <tr style={{ marginTop: '20px' }}>
              <td style={{ marginTop: '20px' }}>
                <Button
                  buttonType="standard"
                  size="small"
                  name="Submit"
                  onClick={() => { getReport() }}
                /></td></tr>
          </table>
        }
        <div style={{ paddingTop: '10px' }}>
          {
            isSubmitClicked ? (isReportLoading ? (<LoadingPage height="20px"></LoadingPage>)
              : (<div>
                <GridPaginationWrapper
                  rowsPerPage={rowCount}
                  onChangeRowsPerPage={setRowCount}
                  pageIndex={startRow}
                  onChangePageIndex={setStartRow}
                  onShowButtonCLick={getReport}
                  totalRecords={totalRecords}
                  defaultRowsPerPage={10}
                />
                <Grid
                  columns={gridColumns}
                  dataTestId="test-eftProviderReport"
                  records={eftRecordsList}
                /></div>))
              : (null)
          }
        </div>
      </div>
    </>
  )
}

export default withAxiosCancellation(EftProviderReport);