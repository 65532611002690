/* eslint-disable no-nested-ternary */
import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom'
import { 
  Grid, 
  useLayout, 
  Modal, ModalHeader,ButtonGroup, ModalFooter,  ModalBody,
  Button } from 'ui-core/dist'
import { LoadingPage, AnchorInput } from '../../../components';
import useNotificationBannerHook from '../../../useNotificationBannerHook';
import ErrorPage from '../../ErrorPage';
import {
  HOLD_DOCUMENT_SEARCH_RESULT_COLUMN,
  SELECTED_HOLD_PURGE_ROUTE_DOCUMENT_SEARCH_RESULT_COLUMN,
  GENERIC_TEXT_HOLD,
  GENERIC_TEXT_SEARCH_HOLD,
} from "../Modules/constants";
import withAxiosCancellation from '../../../components/withAxiosCancellation'
import { useSearchHoldDocument, useSubmitHoldDocument, usePreviewFile } from '../../../api/useDocumentManager';
import ListPaneHeaderWrapper from '../../../components/ErrorDisplaySection/ListPaneHeaderWrapper';
import {
  resetRecord,
  setRecord,
  setFileSearchParam, setFileSearchParamNull
} from '../Modules/action';
import GridPaginationWrapper from '../../../components/GridPaginationWrapper';
import { setDetailsId } from '../../ErrorManager/Modules/Action';

import useSelectableGrid from '../../../hooks/useSelectableGrid';
import '../Modules/styles.scss';

const DSearchResultHoldMain = ({getCancelSourceWithName}) => {
  const {
    expandCollapseListPaneButtonConfig,
    openDetailPane,
    closeDetailPane
  } = useLayout()
  const dispatch = useDispatch()
  const history = useHistory();
  const { notify } = useNotificationBannerHook();
  const DEFAULT_DOWNLOAD_ERR_MESSAGE = "Could not download PDF at the moment. There is no PDF File for your request";
  const { allData, totalRecordsResp, isLoading, isError, error, searchDocument } = useSearchHoldDocument({getCancelSource:getCancelSourceWithName('searchHoldDocumentResult')});
  const { transId, submitDocuments, isLoading: isTxnLoading } = useSubmitHoldDocument();
  const [selectedRecord,setSelectedRecord]=useState({})
  const [claimNumberDownloadTrack, setclaimNumberDownloadTrack] = useState({
    documentKey: null,
    filename: null
  })
  const { file, previewFile, refetchPreviewFile, isError: isErrorPreviewFile, error: errorPreviewFile, isLoading: isLoadingPreviewFile } = usePreviewFile(setclaimNumberDownloadTrack);

  const [count, setCount] = useState(0);
  const { fileSearchParam } = useSelector(({ documentSearch }) => documentSearch);
  const [changeItemPerPage, setchangeItemsPerPage] = useState(fileSearchParam.maxRecords)
  const [pageIndex, setPageIndex] = useState(0)
  const [disabledItemIds, setDisableItemIds] = useState([])
  // const [selectedItemIds, setSelectedItemIds] = useState([])
  const [isOpenConfirmedModal, setConfirmedModal] = useState(false)

  const { onRowSelect, onClearAllSelection, selectedItemIdsCurrentPage, selectedItemIds, selectedRecords } = useSelectableGrid({ uniqueKey: 'documentKey', list: allData, isUicl: true })
  const canView = useSelector(({ context }) =>
    context?.permissions?.filter(p => p.name === 'CAN_PERFORM_DOC_PDF_VIEW').length > 0)
  useEffect(() => {
    searchDocument(fileSearchParam)
  }, [])

  useEffect(() => {
    if (transId) {
      // searchDocument(JSON.parse(JSON.stringify({ ...fileSearchParam, count })));
      // setCount(count + 1);
      setConfirmedModal(false);
      onClearAllSelection()
      history.push({pathname: '/DocumentManager/Hold/Document', state: `You have successfully held the documents.  The transaction ID is ${transId}`})
    }
  }, [transId])

  useEffect(() => {
    if (allData && allData.length) {
      const disableItems = allData.filter(x => (x.status.indexOf("Held") !== -1 || x.canManage === 'false'))
        .map(y => y.documentKey)
      setDisableItemIds(disableItems)
    }
    // onClearAllSelection()
  }, [allData])

  useEffect(() => {
    if (isErrorPreviewFile) {
      notify(errorPreviewFile || DEFAULT_DOWNLOAD_ERR_MESSAGE, 'negative');
    }
  }, [isErrorPreviewFile])

  useEffect(() => {
    if (file && claimNumberDownloadTrack.filename) {
      downloadFile(file)
    }
  }, [file])

  const downloadFile = (f) => {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
      window.navigator.msSaveOrOpenBlob(f, claimNumberDownloadTrack.filename);
    } else {
      const FILE = window.URL.createObjectURL(f);
      const docUrl = document.createElement('a');
      docUrl.href = FILE;
      docUrl.setAttribute('download', claimNumberDownloadTrack.filename);
      document.body.appendChild(docUrl);
      docUrl.click();
      document.body.removeChild(docUrl);
    }
  }

  const onChangeItemsPerPage = (v) => {
    setchangeItemsPerPage(v)
  }

  const onChangePageIndex = (v) => {
    setPageIndex(v);
  }

  const fetchData = useCallback((args) => {
    let pi;
    if (args) {
      ({ pi } = args);
    }
    closeDetailPane();
    const payload = JSON.parse(JSON.stringify({
      ...fileSearchParam,
      "maxRecords": changeItemPerPage || fileSearchParam.maxRecords,
      "rowPosition": pi === undefined || pi === null ? pageIndex : pi,
      }))
    // onClearAllSelection()
    dispatch(setFileSearchParamNull(payload))
    dispatch(setFileSearchParam(payload))
    searchDocument(payload)

  }, [changeItemPerPage, pageIndex]);

  const onCloseConfirmedModal = () => {
    setConfirmedModal(false)
    return true;
  }
  const renderStatus = (status, documentKey) => {
    let className;
    switch (status) {
      case 'Imported':
        className = 'status-ok';
        break;
      case 'Released':
        className = 'status-ok';
        break;
      case 'Ready to print':
        className = 'status-ok';
        break;
      case 'Held':
        className = 'status-warn';
        break;
      case 'Held and Errored':
        className = 'status-error';
        break;
      case 'Errored':
        className = 'status-error';
        break;
      case 'Purged':
        className = 'status-error';
        break;
      default:
        break;
    }
    if (status === 'Errored' && documentKey) {
      return (<AnchorInput
        id='fileKey'
        type='button'
        style={{ color: 'red' }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (onShowMoreClick) {
            const record = allData.find(x => x.documentKey === documentKey);
            const i = allData.map(x => x.documentKey).indexOf(documentKey);
            dispatch(resetRecord())
            dispatch(setDetailsId(documentKey))
            setSelectedRecord(record)
            return onShowMoreClick(record, i, 'errorDetails');
          }
        }}
      >{status}</AnchorInput>)
    }
    return (
      <div className={className}>
        <span>{status}</span>
      </div>
    )
  }

  // const selectRow = (e,records) =>{
  //   setSelectedItemIds(Array.from(records.selectedItemIds))
  // }

  const onShowMoreClick = useCallback((record, index, currentShowMoreTargetProps) => {
    dispatch(setRecord({ record, index, currentShowMoreTarget: currentShowMoreTargetProps }));
    setSelectedRecord(record)
    openDetailPane();
  }, [allData]);

  const onConfirm = () => {
    const selectedDocumentIds = Array.from(selectedItemIds);
    submitDocuments({ pstrDocumentKeys: selectedDocumentIds, count });
    setCount(count + 1);
  }

  const renderCheckedContent = useMemo(() => {
    const selectedRecordsArray = Array.from(selectedRecords);
    if (selectedRecordsArray.length) {
      const data = selectedRecordsArray.map((results) => {
        let claimNumber = '';
        if (results.claimNumbers && results.claimNumbers.claimNumber
          && results.claimNumbers.claimNumber.constructor.name === 'String') {
          claimNumber = results.claimNumbers.claimNumber
        } else if (results.claimNumbers && results.claimNumbers.claimNumber
          && results.claimNumbers.claimNumber.constructor.name === 'Array') {
          claimNumber = 'multiple'
        }
        return {
          ...results,
          statusComp: renderStatus(results.status),
          claimNumber
        }
      })

      return (
        <Grid
          {...SELECTED_HOLD_PURGE_ROUTE_DOCUMENT_SEARCH_RESULT_COLUMN}
          dataTestId="test-gridDefault"
          domID="DocumentSearchHoldConfirm"
          // isFixedHeader
          maxHeight="350px"
          records={data || []}
        />
      );
    }
    return '';
  }, [selectedRecords, isOpenConfirmedModal])

  const renderClaimNumberDisplay = (bPreviewLink, bRelatedKeys, claimNumber, documentKey, relKeys) => {
    if (claimNumber) {
      if (claimNumber.constructor.name === "Array" && claimNumber.length > 1) {
        if (bPreviewLink === 'True') {
          if (bRelatedKeys === 'True') {
            return (
              <AnchorInput
                id='fileKey'
                type='button'
                className='fileKeyLink'
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if(canView) {
                  if (documentKey !== claimNumberDownloadTrack.documentKey) {
                    previewFile({
                      payload: { documentKey, relKeys }, config: JSON.parse(JSON.stringify({
                        documentKey,
                        filename: 'printPreview.pdf'
                      }))
                    });
                  } else {
                    refetchPreviewFile()
                  }} else {
                    notify('You do not have required permission to perform this function','negative')
                  }
                }}
              >multiple</AnchorInput>
            )
          }
          return (
            <AnchorInput
              id='fileKey'
              type='button'
              className='fileKeyLink'
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (canView) {
                if (documentKey !== claimNumberDownloadTrack.documentKey) {
                  previewFile({
                    payload: { documentKey, relKeys: "none" }, config: JSON.parse(JSON.stringify({
                      documentKey,
                      filename: 'printPreview.pdf'
                    }))
                  });
                } else {
                  refetchPreviewFile()
                }} else {
                  notify('You do not have required permission to perform this function','negative')
                }
              }}
            >multiple</AnchorInput>
          )
        }
        return <span>multiple</span>
      }
      if (bPreviewLink === 'True') {
        if (bRelatedKeys === 'True') {
          return (
            <AnchorInput
              id='fileKey'
              type='button'
              className='fileKeyLink'
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if(canView) {
                if (documentKey !== claimNumberDownloadTrack.documentKey) {
                  previewFile({
                    payload: { documentKey, relKeys }, config: JSON.parse(JSON.stringify({
                      documentKey,
                      filename: 'printPreview.pdf'
                    }))
                  });
                } else {
                  refetchPreviewFile()
                }} else {
                  notify('You do not have required permission to perform this function','negative')
                }
              }}
            >{claimNumber.claimNumber}</AnchorInput>
          )
        }
        return (
          <AnchorInput
            id='fileKey'
            type='button'
            className='fileKeyLink'
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (canView) {
              if (documentKey !== claimNumberDownloadTrack.documentKey) {
                previewFile({
                  payload: { documentKey, relKeys: "none" }, config: JSON.parse(JSON.stringify({
                    documentKey,
                    filename: 'printPreview.pdf'
                  }))
                });
              } else {
                refetchPreviewFile()
              }} else {
                notify('You do not have required permission to perform this function','negative')
              }
            }}
          >{claimNumber.claimNumber}</AnchorInput>
        )
      }
      return <span>{claimNumber.claimNumber}</span>
    }
    return null;
  }

  const renderGridContent = useMemo(() => {
    if (allData.length) {
      // const temp=JSON.parse(JSON.stringify(errorResp));
      const data = allData.map(f => ({
        ...f,
        statusComp: renderStatus(f.status, f.documentKey),
        claimNumber: renderClaimNumberDisplay(
          f.$.bPreviewLink,
          f.$.bRelatedKeys,
          f.claimNumbers.claimNumber.constructor.name === 'Array' ? f.claimNumbers.claimNumber : f.claimNumbers,
          f.documentKey, f.$relKeys)
      }))

      return (
        <Grid
          {...HOLD_DOCUMENT_SEARCH_RESULT_COLUMN}
          dataTestId="test-gridDefault"
          domID="DocumentSearchHold"
          records={data || []}
          selectionKey="documentKey"
          initialSelectedItemIds={new Set(selectedItemIdsCurrentPage)}
          initialDisabledItemIds={new Set(disabledItemIds)}
          onRowSelect={onRowSelect}
          onSelectAll={onRowSelect}
          supportSelection={data.length !== disabledItemIds.length}
          // focusedItem={selectedRecord}
          onClickThrough={(e, rec) => {
            e.preventDefault();
            e.stopPropagation();
            if (rec) {
              const record = data.find(x => x.documentKey === rec.documentKey);
              const i = data.map(x => x.documentKey).indexOf(rec.documentKey);
              if (onShowMoreClick) {
                dispatch(resetRecord())
                dispatch(setDetailsId(rec.documentKey))
                const source = record.source.constructor.name === 'Array' ? record.source[0] : record.source
                return onShowMoreClick({ ...record, source }, i, 'showMore');
              }
              return false;
            }
            return false;
          }}
        />
      );
    } else {
      return (
        <div className="displayFlex">
          <label style={{ color: 'red' }}>No records found. Change search criteria.</label>
        </div>
      )
    }
  }, [allData, disabledItemIds, selectedItemIds, selectedItemIdsCurrentPage, claimNumberDownloadTrack, selectedRecord]);

  return (
    <>
      <ListPaneHeaderWrapper
        title="Document Manager: Hold Documents"
        layoutControls={[expandCollapseListPaneButtonConfig]}

      />
      {isLoading || isLoadingPreviewFile ? (
        // replace with a better loading component
        <LoadingPage />
      ) : isError ? (
        // replace with a better error component
        <ErrorPage
          // this prop isn't real but is an example
          error={error}
        />
      ) : (
        <div className="mainWrapper">
          <div style={{ fontSize: "small", padding: "12px" }}>
            <p>
              {GENERIC_TEXT_SEARCH_HOLD}
            </p>
            <p style={{ color: "#bf1616" }}>
              {GENERIC_TEXT_HOLD}
            </p>
            <p style={{ color: "#bf1616" }}>
                  If the value in the claim number column shows up as a link, the document is ready for pre-release preview. Any updates to group rules, forms or images can affect the final output.
            </p>
          </div>
          <div>
            <GridPaginationWrapper
              rowsPerPage={changeItemPerPage}
              onChangeRowsPerPage={(v) => onChangeItemsPerPage(v)}
              pageIndex={pageIndex}
              onChangePageIndex={(v) => onChangePageIndex(v)}
              onShowButtonCLick={(args) => fetchData(args)}
              totalRecords={totalRecordsResp}
              defaultRowsPerPage={changeItemPerPage}
              enableTextbox={false}
            />
            <div className='displayFlex'>
              <Button
                buttonType="standard"
                size="small"
                name="New Search"
                onClick={() => { history.push(`/DocumentManager/Hold/Document`) }}
              />&nbsp;
              <Button
                buttonType="standard"
                size="small"
                name="Clear all"
                disabled={(selectedItemIds.size === 0)}
                onClick={() => { onClearAllSelection() }}
              />&nbsp;
              <Button
                buttonType="standard"
                size="small"
                name="CONFIRM SELECTED ITEMS"
                disabled={(selectedItemIds.size === 0)}
                onClick={() => { setConfirmedModal(true) }}
              />
            </div>
            <div className="displayFlex"></div>
            <div className='GridWrapper'>
              {renderGridContent}
            </div>
          </div>
        </div>
      )}
      <Modal isOpen={isOpenConfirmedModal}>
        <ModalHeader
          title="Selected Documents"
          onClose={() => onCloseConfirmedModal()}
        />
        <ModalBody>
          <div className="displayFlex" style={{ paddingLeft: '30px' }}>
            <span>Confirm your selections, and then click Confirm to place the documents on hold.</span>
          </div>
          {isOpenConfirmedModal ? (
            <div>
              <div className='GridWrapper'>
                <div className="modalOuterDiv" style={{
                  paddingLeft: '25px',
                  paddingRight: '25px',
                  marginTop: '20px',
                  marginBottom: '-10px',
                  maxHeight: '40vh',
                  overflow: 'scroll',
                  maxWidth: '800px'
                }}>
                  {renderCheckedContent}
                </div>
              </div>
            </div>
          ) : null}
        </ModalBody>
        <ModalFooter>
                <ButtonGroup>
                  <Button
                    buttonType="standard"
                    domID="close"
                    dataTestId="test-close"
                    name="CLOSE"
                    onClick={() => setConfirmedModal(false)}
                    size="medium"
                  />
                  <Button
                    buttonType="standard"
                    domID="confirm"
                    dataTestId="test-confirm"
                    name="CONFIRM"
                    onClick={() => onConfirm()}
                    size="medium"
                    disabled={isTxnLoading}
                  />
                  {
                    isTxnLoading && <LoadingPage height="1vh"/>
                  }
                </ButtonGroup>
              </ModalFooter>
      </Modal>
    </>
  )
}

export default withAxiosCancellation(DSearchResultHoldMain)
