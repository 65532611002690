import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Datetime from 'react-datetime';
import moment from 'moment';
import {
  Input,
  useLayout,
  ListPaneHeader,
  Button,
} from 'ui-core/dist'
import useIDList from '../../api/useIDList'
import { GridPaginationWrapper, Loading, SelectDropdown } from '../../components'
import ErrorPage from '../ErrorPage';
import CustomGrid from './CustomeGrid';
import './modules/styles.scss'

const WelcomeKitContent = () => {
  const { client } = useSelector(({ context }) => context?.context || {});
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [pageIndex, setPageIndex] = useState(0);
  const [idx, setIdx] = useState(0)

  const [fileStatus, setFileStatus] = useState("")
  const [fileName, setFileName] = useState("")
  const [importStartDate, setImportStartDate] = useState('')
  const [importEndDate, setImportEndDate] = useState('')
  const [mailStartDate, setMailStartDate] = useState('')
  const [mailEndDate, setMailEndDate] = useState('')
  const [sortField, setSortField] = useState('')
  const [sortBy, setSortBy] = useState('')
  const [datesError, setDatesError] = useState(false)
  const [errMsg, setErrMsg] = useState(false)
  const [payload, setPayload] = useState({
    maxRecords: rowsPerPage,
    rowPosition: pageIndex,
    fileStatus,
    fileName,
    importStartDate: importStartDate,
    importEndDate: importEndDate,
    mailStartDate: mailStartDate,
    mailEndDate: mailEndDate,
    sortBy,
    sortField
  })
  const { expandCollapseListPaneButtonConfig } = useLayout()
  const { data, isLoading, total , isError, error} = useIDList({ payload })

  const onFileStatusChange = (state) => setFileStatus(state)
  const onFileNameChange = (e, state) => setFileName(state.value)

  const searchClick = () => {
    setRowsPerPage(50)
    setPageIndex(0)
    setIdx(idx + 1);
    setPayload({
      maxRecords: 50,
      rowPosition: 0,
      fileStatus: fileStatus ? fileStatus.value : '',
      fileName,
      importStartDate: importStartDate,
      importEndDate: importEndDate,
      mailStartDate: mailStartDate,
      mailEndDate: mailEndDate,
      sortBy: '',
      sortField: ''
    })
  }

  const handleShowButton = ({ pi }) => {
    setPageIndex(pi)
    setPayload({
      maxRecords: rowsPerPage,
      rowPosition: pi,
      fileStatus: fileStatus ? fileStatus.value : '',
      fileName,
      importStartDate: importStartDate,
      importEndDate: importEndDate,
      mailStartDate: mailStartDate,
      mailEndDate: mailEndDate,
      sortBy,
      sortField
    })
  }

  const importStartDateValidation = (currentDate) => {
    let err = false;
    if (moment(currentDate).isAfter(moment(importEndDate).startOf('day'), 'day')) {
      err = true;
    } else if (moment(currentDate).isAfter(moment().startOf('day'), 'day')) {
      err = true;
    }

    return !err;
  };

  const importEndDateValidation = (currentDate) => {
    let err = false;
    if (moment(currentDate).isAfter(moment().startOf('day'), 'day')) {
      err = true;
    } else if (moment(currentDate).isBefore(moment(importStartDate).startOf('day'), 'day')) {
      err = true;
    }

    return !err;
  };

  const mailedStartDateValidation = (currentDate) => {
    let err = false;
    if (moment(currentDate).isAfter(moment(mailEndDate).startOf('day'), 'day')) {
      err = true;
    } else if (moment(currentDate).isAfter(moment().startOf('day'), 'day')) {
      err = true;
    }

    return !err;
  };

  const mailedEndDateValidation = (currentDate) => {
    let err = false;
    if (moment(currentDate).isAfter(moment().startOf('day'), 'day')) {
      err = true;
    } else if (moment(currentDate).isBefore(moment(mailStartDate).startOf('day'), 'day')) {
      err = true;
    }

    return !err;
  };

  const onImportStartDateChange = (val) => {
    let err = false;
    let errMessage = '';

    if (val === '') {
      setDatesError(false);
      setImportStartDate('');
      return;
    }

    if (!moment(val, 'MM/DD/YYYY', true).isValid()) {
      errMessage = 'Import start date is invalid(mm/dd/yyyy).';
      err = true;
    } else if (moment(val).isAfter(moment(importEndDate))) {
      errMessage = 'Import start date should be earlier than the end date.';
      err = true;
    } else if (moment(val).isAfter(moment().startOf('day'), 'day')) {
      errMessage = 'Import start date is invalid(mm/dd/yyyy, max date is today).';
      err = true;
    }

    if (err) {
      setDatesError(true);
      setErrMsg(errMessage);
      setImportStartDate(val);
    } else {
      setDatesError(false);
      setImportStartDate(val.format("MM/DD/YYYY"));
    }
  }

  const onImportEndDateChange = (val) => {
    let err = false;
    let errMessage = '';

    if (val === '') {
      setDatesError(false);
      setImportEndDate('');
      return;
    }

    if (!moment(val, 'MM/DD/YYYY', true).isValid()) {
      err = true;
      errMessage = 'Import end date is invalid(mm/dd/yyyy).'
    } else if (moment(val).isBefore(moment(importStartDate))) {
      errMessage = 'Import end date should be greater than the start date.';
      err = true;
    } else if (moment(val).isAfter(moment().startOf('day'), 'day')) {
      errMessage = 'Import end date is invalid(mm/dd/yyyy, max date is today).';
      err = true;
    }

    if (err) {
      setDatesError(true);
      setErrMsg(errMessage);
      setImportEndDate(val);
    } else {
      setDatesError(false);
      setImportEndDate(val.format("MM/DD/YYYY"));
    }
  }

  const onMailedStartDateChange = (val) => {
    let err = false;
    let errMessage = '';

    if (val === '') {
      setDatesError(false);
      setMailStartDate('');
      return;
    }

    if (!moment(val, 'MM/DD/YYYY', true).isValid()) {
      errMessage = 'Mailed start date is invalid(mm/dd/yyyy).';
      err = true;
    } else if (moment(val).isAfter(moment(mailEndDate))) {
      errMessage = 'Mailed start date should be earlier than the end date.';
      err = true;
    } else if (moment(val).isAfter(moment().startOf('day'), 'day')) {
      errMessage = 'Mailed start date is invalid(mm/dd/yyyy, max date is today).';
      err = true;
    }

    if (err) {
      setDatesError(true);
      setErrMsg(errMessage);
      setMailStartDate(val);
    } else {
      setDatesError(false);
      setMailStartDate(val.format("MM/DD/YYYY"));
    }
  }

  const onMailedEndDateChange = (val) => {
    let err = false;
    let errMessage = '';

    if (val === '') {
      setDatesError(false);
      setMailEndDate('');
      return;
    }

    if (!moment(val, 'MM/DD/YYYY', true).isValid()) {
      err = true;
      errMessage = 'Mailed end date is invalid(mm/dd/yyyy).'
    } else if (moment(val).isBefore(moment(mailStartDate))) {
      errMessage = 'Mailed end date should be greater than the start date.';
      err = true;
    } else if (moment(val).isAfter(moment().startOf('day'), 'day')) {
      errMessage = 'Mailed end date is invalid(mm/dd/yyyy, max date is today).';
      err = true;
    }

    if (err) {
      setDatesError(true);
      setErrMsg(errMessage);
      setMailEndDate(val);
    } else {
      setDatesError(false);
      setMailEndDate(val.format("MM/DD/YYYY"));
    }
  }

  const onDateClose = () => {
    setIdx(idx + 1);
  }

  return (
    <>
      <ListPaneHeader
        title={client === '2470' ? `EB Document` : 'ID Card/Welcome Kit Manager'}
        layoutControls={[expandCollapseListPaneButtonConfig]}
      />
      <div className="idkit-home">
        {datesError && <p style={{ fontSize: '13px', color: 'red', marginLeft: '30px' }}>{errMsg}</p>}
        <div className="idkit-home__search-row">
          <div className="msgDatePicker">
            <label style={{ color: '#626D8A', fontSize: '11px' }}>IMPORT START DATE</label>
            <Datetime
              onChange={onImportStartDateChange}
              // inputProps={{ readOnly: true }}
              closeOnSelect={true}
              isValidDate={importStartDateValidation}
              dateFormat="MM/DD/YYYY"
              value={importStartDate}
              initialValue={importStartDate}
              initialViewDate={importStartDate}
              timeFormat={false}
              onClose={onDateClose}
            />
          </div>
          <div className="msgDatePicker">
            <label style={{ color: '#626D8A', fontSize: '11px' }}>IMPORT END DATE</label>
            <Datetime
              onChange={onImportEndDateChange}
              // inputProps={{ readOnly: true }}
              closeOnSelect={true}
              isValidDate={importEndDateValidation}
              dateFormat="MM/DD/YYYY"
              value={importEndDate}
              initialValue={importEndDate}
              initialViewDate={importEndDate}
              timeFormat={false}
              onClose={onDateClose}
            />
          </div>
          <div className="msgDatePicker">
            <label style={{ color: '#626D8A', fontSize: '11px' }}>MAILED START DATE</label>
            <Datetime
              onChange={onMailedStartDateChange}
              // inputProps={{ readOnly: true }}
              closeOnSelect={true}
              isValidDate={mailedStartDateValidation}
              dateFormat="MM/DD/YYYY"
              value={mailStartDate}
              initialValue={mailStartDate}
              initialViewDate={mailStartDate}
              timeFormat={false}
              onClose={onDateClose}
            />
          </div>
          <div className="msgDatePicker">
            <label style={{ color: '#626D8A', fontSize: '11px' }}>MAILED END DATE</label>
            <Datetime
              onChange={onMailedEndDateChange}
              // inputProps={{ readOnly: true }}
              closeOnSelect={true}
              isValidDate={mailedEndDateValidation}
              dateFormat="MM/DD/YYYY"
              value={mailEndDate}
              initialValue={mailEndDate}
              initialViewDate={mailEndDate}
              timeFormat={false}
              onClose={onDateClose}
            />
          </div>
          <Input
            domID="IDKitFileNameInput"
            onChange={onFileNameChange}
            size="small"
            label="FILE NAME"
          />
          <SelectDropdown
            domID="IDKitFileStatusDropdown"
            onChange={onFileStatusChange}
            size="small"
            label="FILE STATUS"
            options={[{ label: 'Errored', value: 1 }, { label: 'Finished', value: 3 }, { label: 'In Progress', value: 4 }, { label: 'Received', value: 2 }]}
            initialValue={fileStatus}
          />
          <Button
            domID="searchButton"
            name="Search"
            onClick={searchClick}
            disabled={datesError}
            size="small"
            buttonType="standard"
            className="idkit-home__search-button"
          />
        </div>
        <div className="idkit-home__pagination-row">
          <GridPaginationWrapper
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={setRowsPerPage}
            pageIndex={pageIndex}
            onChangePageIndex={setPageIndex}
            onShowButtonCLick={handleShowButton}
            totalRecords={total}
            defaultRowsPerPage={10}
            disableShowButton={datesError}
          />
        </div>
        <div style={{ marginTop: '-30px' }}>
          <p style={{ color: '#0F0F59', position: 'relative', fontSize: '13px', float: 'right', margin: '0 20px 16px 0' }}>ALL TIMES CT</p>
          <div style={{ clear: 'both' }} className="idkit-home__grid-wrapper">
            {isLoading ? <Loading /> : 
            isError ? (
            // replace with a better error component
            <ErrorPage
              // this prop isn't real but is an example
              error={error}
            />
          ) : (
              <CustomGrid
                columns={[
                  {
                    dataName: 'filename',
                    text: 'File Name',
                    cellType: 'text',
                  },
                  {
                    dataName: 'fileReceived',
                    text: 'File Received',
                    cellType: 'text',
                  },
                  {
                    dataName: 'recSuccessCount',
                    text: "Record Imported Success",
                    cellType: 'text',
                  },
                  {
                    dataName: 'recRejectedCount',
                    text: 'Record Imported Reject',
                    cellType: 'text',
                  },
                  {
                    dataName: 'recordsSent',
                    text: 'Records Sent',
                    cellType: 'text',
                    // sortable: true,
                  },
                  {
                    dataName: 'validatedSuccess',
                    text: "Record Verified Success",
                    cellType: 'text',
                  },
                  {
                    dataName: 'validatedRejected',
                    text: "Record Verified Reject",
                    cellType: 'text',
                  },
                  {
                    dataName: 'pdfsReceived',
                    text: "PDFs",
                    cellType: 'text',
                  },
                  {
                    dataName: 'printedMailedSuccess',
                    text: "Printed & Mailed Success",
                    cellType: 'text',
                  },
                  {
                    dataName: 'printedMailedPending',
                    text: "Printed & Mailed Pending",
                    cellType: 'text',
                  },
                  {
                    dataName: 'lastUpdated',
                    text: 'Mailed Date',
                    cellType: 'text',
                  },
                  {
                    dataName: 'overallStatus',
                    text: "Status",
                    cellType: 'text',
                  }
                ]}
                gridData={data}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default WelcomeKitContent