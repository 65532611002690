import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import { LoadingIndicator, Button } from 'ui-core-ssgr';
import DropDownControl from '../ModalControls/DropDownControl/index';
import { 
  ContainerBox, 
  ErisaTextArea, 
  SectionInfoHeader 
} from './styles';
import { 
  ButtonGroupBottom,
  ModalButton,
  ReadOnlyTextArea,
  ReadOnlyTextTitle 
} from '../../../shared/styles/styledComponents';
import { LoadingWrapper } from '../../RulesTabView/components/RuleDetails/RuleDetailsGridWrapper/styles';
import { getRuleVersion } from '../utils';

const ErisaMessageModal = ({
  loading,
  selectedRuleRecord,
  selectedRecord,
  detailElements,
  selectedRuleDetail,
  actionType,
  setIsCloningRuleDetail,
  setIsPostingRuleDetail,
  setIsShowingErisaMessageModal,
  isCloningRuleDetail,
  isShowingErisaMessageModal,
  isPostingRuleDetail,
  postRuleDetails,
  putRuleDetails,
  selectedRuleTypeId,
  setIsReadOnlyRuleDetail
}) => {
  const [claimType, setClaimType] = useState('default');
  const [recipientType, setRecipientType] = useState('default');
  const [documentType, setDocumentType] = useState('default');
  const [state, setState] = useState('default');
  const [message, setMessage] = useState('');
  const [erisaMessageError, setErisaMessageError] = useState(false);
  const [erisaMessageErrorMessage, setErisaMessageErrorMessage] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const recordId = get(selectedRuleRecord, 'id', '')|| get(selectedRecord, 'id', '');

  const validateMessage = userMessage => {
    let erisaMessageErrorValue = false;
    let erisaMessageErrorMessageValue = '';
    if (!userMessage || /^\s*$/.test(userMessage)) {
      erisaMessageErrorValue = true;
      erisaMessageErrorMessageValue = 'Message required with minimum of 1 character';
    } else if (userMessage.length > 4000) {
      erisaMessageErrorValue = true;
      erisaMessageErrorMessageValue = 'Max 4000 characters';
    }
    setErisaMessageError(erisaMessageErrorValue);
    setErisaMessageErrorMessage(erisaMessageErrorMessageValue);
  };

  const getInitialValues = () => {
    const messageValue = !isPostingRuleDetail ? get(selectedRuleDetail, 'message', '').replace(/\\n/g, '\n') : '';
    let claimTypeValue = 'default';
    let recipientTypeValue = 'default';
    let documentTypeValue = 'default';
    let stateValue = 'default';
    let readOnlyClaimType = 'default';
    let readOnlyRecipientType = 'default';
    let readOnlyDocumentType = 'default';
    let readOnlyStateValue = 'default';


    if (!isPostingRuleDetail) {
      /**
       * For this we are doing a check on the claimTypesList, recipientTypesList, and documentTypesList
       * Using the get from lodash to make sure the code does not break the page and return a blank page
       */
      const detailElementsClaimTypesList = get(detailElements, 'claimTypesList', []);
      const detailElementsRecipientTypesList = get(detailElements, 'recipientTypesList', []);

      // For here we are getting the claimType, recipientType, and documentType from the selectedRuleDetail to populate the values
      const ruleDetailClaimType = get(selectedRuleDetail, 'claimType', '');
      const ruleDetailRecipientType = get(selectedRuleDetail, 'recipientType', '');

      /**
       * Down here for these checks, once again we are using the get method of lodash just in case if something comes back blank
       * We are wrapping the find method inside of a get method for additional checking to make sure that the code doesn't come back blank
       */
      claimTypeValue = get(find(detailElementsClaimTypesList, o => o.value === ruleDetailClaimType), 'code', 'default');
      readOnlyClaimType = get(find(detailElementsClaimTypesList, o => o.value === ruleDetailClaimType), 'value', '');
      recipientTypeValue = get(find(detailElementsRecipientTypesList, o => o.value === ruleDetailRecipientType), 'code', 'default');
      readOnlyRecipientType = get(find(detailElementsRecipientTypesList, o => o.value === ruleDetailRecipientType), 'value', '');

      if (
        detailElements.documentTypesList &&
        selectedRuleDetail.state &&
        selectedRuleDetail.documentType !== ''
      ) {
        const detailElementsDocumentTypesList = get(detailElements, 'documentTypesList', []);
        const ruleDetailDocumentType = get(selectedRuleDetail, 'documentType', '');
        documentTypeValue = get(find(detailElementsDocumentTypesList, o => o.value === ruleDetailDocumentType), 'code', 'default');
        readOnlyDocumentType = get(find(detailElementsDocumentTypesList, o => o.value === ruleDetailDocumentType), 'value', '');
      }
      if (
        detailElements.stateList &&
        selectedRuleDetail.state &&
        selectedRuleDetail.state !== '' &&
        selectedRuleDetail.state !== 'Any'
      ) {
        const detailElementsStatesList = get(detailElements, 'stateList', []);
        const ruleDetailState = get(selectedRuleDetail, 'state', '');
        stateValue = get(find(detailElementsStatesList, o => o.value === ruleDetailState), 'code', 'default');
        readOnlyStateValue = get(find(detailElementsStatesList, o => o.value === ruleDetailState), 'value', '');
      } else {
        stateValue = 'default';
      }
    }
    if(actionType !== 'Read only') {
      setClaimType(claimTypeValue);
      setRecipientType(recipientTypeValue);
      setDocumentType(documentTypeValue);
      setState(stateValue);
      setMessage(messageValue);
      setIsButtonDisabled(true);
    }
    if(actionType === 'Read only') {
      setClaimType(readOnlyClaimType);
      setRecipientType(readOnlyRecipientType);
      setDocumentType(readOnlyDocumentType);
      setState(readOnlyStateValue);
      setMessage(messageValue);
      setIsButtonDisabled(true);
    }
  };

  const setButtonDisableOnDefault = getDbCodes => {
    let buttonDisabled = false;
    if (
      (actionType === 'Edit a' &&
          (claimType === getDbCodes.claimTypeDbCode &&
            recipientType === getDbCodes.recipientTypeDbCode &&
            documentType === getDbCodes.documentTypeDbCode &&
            message === getDbCodes.messageDbCode &&
            state === getDbCodes.stateDbCode)) ||
        (actionType === 'Clone a' &&
          (claimType === getDbCodes.claimTypeDbCode &&
            recipientType === getDbCodes.recipientTypeDbCode &&
            documentType === getDbCodes.documentTypeDbCode &&
            state === getDbCodes.stateDbCode))
    ) {
      buttonDisabled = true;
    }
  
    setIsButtonDisabled(buttonDisabled);
  };

  // This use effect is for the componentDidMount functionality
  useEffect(() => {
    if (isPostingRuleDetail) {
      setErisaMessageError(true);
      setErisaMessageErrorMessage('Message required with minimum of 1 character');
    }
    getInitialValues();
  }, []);

  // This use effect is for the componentDidUpdate functionality
  useEffect(() => {
    getInitialValues();
  }, [detailElements, isShowingErisaMessageModal]);

  useEffect(() => {
    const claimTypeDbCode = populateCodeFromValue('claimTypesList');
    const recipientTypeDbCode = populateCodeFromValue('recipientTypesList');
    const documentTypeDbCode = populateCodeFromValue('documentTypesList');
    const stateDbCode = populateCodeFromValue('stateList');
    const messageDbCode = get(selectedRuleDetail, 'message', '');
    const getDbCodes = {
      claimTypeDbCode,
      recipientTypeDbCode,
      documentTypeDbCode,
      stateDbCode,
      messageDbCode,
    };
    setButtonDisableOnDefault(getDbCodes); 
  }, [claimType, documentType, recipientType, state, message]);

  const populateCodeFromValue = name => {
    let selectedCode = '';

    if (!isEmpty(detailElements)) {
      if (name) {
        const selectedName = name.replace('List', '');
        const replaceSelectedName = selectedName.indexOf('Types') ? selectedName.replace('Types', 'Type') : selectedName;
        const selectedValue = selectedRuleDetail[replaceSelectedName];
        let selectedList = '';
        if (name === 'claimTypesList') selectedList = 'claimTypesList';
        if (name === 'recipientTypesList') selectedList = 'recipientTypesList';
        if (name === 'documentTypesList') selectedList = 'documentTypesList';
        if (name === 'stateList') selectedList = 'stateList';
        const detailListItem = detailElements[selectedList];
        const firstValue = find(detailListItem, x =>
          x.value.includes(selectedValue),
        ) || {};
        const arr = firstValue
          ? [...new Set([firstValue, ...detailListItem])]
          : detailListItem;
        arr.map(r => {
          if (r.value === selectedValue || (r.code === 'default' && selectedValue === 'Any')) {
            selectedCode = r.code;
          }
          return {
            label: r.value,
            value: r.code,
          };
        });
      }
    }
    return selectedCode;
  };

  const checkUnsavedChanges = event => {
    if (event.target) {
      const { name, value } = event.target;
      switch(name) {
        case 'message':
          validateMessage(value);
          setMessage(value);
          break;
        case 'claimType':
          setClaimType(value);
          break;
        case 'recipientType':
          setRecipientType(value);
          break;
        case 'documentType':
          setDocumentType(value);
          break;
        case 'state':
          setState(value);
          break;
        default:
          break;
      }
    }
  };

  const populateDataDropDown = name => {
    let options = [];

    if (!isEmpty(detailElements)) {

      let selectedList = '';
      const selectedValue = selectedRuleDetail[name];
      if (name === 'claimTypesList') selectedList = 'claimTypesList';
      if (name === 'recipientTypesList') selectedList = 'recipientTypesList';
      if (name === 'documentTypesList') selectedList = 'documentTypesList';
      if (name === 'stateList') selectedList = 'stateList';

      const detailElementList = detailElements[selectedList];
      const firstValue = find(detailElementList, x =>
        x.value.includes(selectedValue),
      ) || {};
      const arr = firstValue
        ? [...new Set([firstValue, ...detailElementList])]
        : detailElementList;
      options = arr
        .map(r => ({
          label: r.value,
          value: r.code,
        }))
        .filter(v => v.value);
    }
    return options;
  };

  const onCancel = () => {
    setIsShowingErisaMessageModal(false);
    setIsCloningRuleDetail(false);
    setIsPostingRuleDetail(false);
    setIsReadOnlyRuleDetail(false);
  };

  const onSave = () => {
    const record = selectedRuleRecord || selectedRecord;
    const ruleDetailId = get(selectedRuleDetail, 'id', '');
    const ruleId = get(record, 'id', '');
    const ruleVersion = getRuleVersion(record);
    const ruleType = selectedRuleTypeId;
    let ruleDetail = {
      claimType,
      recipientType,
      documentType,
      state,
      message,
    };
    ruleDetail = isCloningRuleDetail
      ? { ...ruleDetail, ...{ ruleDetailIdFrom: ruleDetailId } }
      : ruleDetail;
    if (isPostingRuleDetail || isCloningRuleDetail) {
      postRuleDetails({ ruleDetail, ruleType, ruleId, ruleVersion });
      setIsCloningRuleDetail(false);
      setIsPostingRuleDetail(false);
    } else {
      putRuleDetails({
        ruleDetail,
        ruleType,
        ruleId,
        ruleVersion,
        ruleDetailId,
      });
    }
  };

  // FOR READ ONLY CONDITIONAL RENDERING (Live Rule)
  let claimTypeReadOnly;
  let recipientTypeReadOnly;
  let documentTypeReadOnly;
  let stateValueReadOnly;
  let messageReadOnly;
  if(actionType === 'Read only') {
    claimTypeReadOnly = (claimType !== 'default') ? claimType : 'Any';
    recipientTypeReadOnly = (recipientType !== 'default') ? recipientType : 'Any';
    documentTypeReadOnly = (documentType !== 'default') ? documentType : 'Any';
    stateValueReadOnly = (state !== 'default') ? state : 'Any';
    messageReadOnly = (message !== 'default') ? message : '-';
  }
  
  return (
    <>
    {actionType !== 'Read only' ? (
      <br />
    ) : null}
    {actionType !== 'Read only' ? (
      <SectionInfoHeader>Please enter a unique combination of Claim Type, Recipient, Document Type and State.</SectionInfoHeader>
    ) : null}
      <ContainerBox>
        {actionType !== 'Read only' ? (
          <DropDownControl
            dropDownDomID="erisa-message-claim-type"
            dropDownLabel="claim type"
            dropDownName="claimType"
            dropDownOptions={populateDataDropDown('claimTypesList')}
            dropDownInitialValue={claimType}
            dropDownOnChangeHandle={checkUnsavedChanges}
          />
        ) : <ReadOnlyTextTitle>Claim Type<ReadOnlyTextArea>{claimTypeReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
        {actionType !== 'Read only' ? (
          <DropDownControl
            dropDownDomID="erisa-message-recipient-type"
            dropDownLabel="recipient"
            dropDownName="recipientType"
            dropDownOptions={populateDataDropDown('recipientTypesList')}
            dropDownInitialValue={recipientType}
            dropDownOnChangeHandle={checkUnsavedChanges}
          />
        ) : <ReadOnlyTextTitle>Reicipient<ReadOnlyTextArea>{recipientTypeReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
        {actionType !== 'Read only' ? (
          <DropDownControl
            dropDownDomID="erisa-message-document-type"
            dropDownLabel="document type"
            dropDownName="documentType"
            dropDownOptions={populateDataDropDown('documentTypesList')}
            dropDownInitialValue={documentType}
            dropDownOnChangeHandle={checkUnsavedChanges}
          />
        ) : <ReadOnlyTextTitle>Document Type<ReadOnlyTextArea>{documentTypeReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
      </ContainerBox>
      <ContainerBox>
        {actionType !== 'Read only' ? (
          <DropDownControl
            dropDownDomID="erisa-message-state"
            dropDownLabel="state"
            dropDownName="state"
            dropDownOptions={populateDataDropDown('stateList')}
            dropDownInitialValue={state}
            dropDownOnChangeHandle={checkUnsavedChanges}
          />
        ) : <ReadOnlyTextTitle>State<ReadOnlyTextArea>{stateValueReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
      </ContainerBox>
      <ContainerBox>
        {actionType !== 'Read only' ? (
          <ErisaTextArea
            domID="erisa-message-message"
            label="message"
            name="message"
            placeholder="Fill out your message here"
            initialValue={message}
            onChange={checkUnsavedChanges}
            errorMessage={erisaMessageErrorMessage}
            hasError={erisaMessageError}
            hasCounter
            maxLength={4001}
          />
        ) : <ReadOnlyTextTitle>Message<ReadOnlyTextArea>{messageReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
      </ContainerBox>
      <ButtonGroupBottom>
        <ModalButton>
          {loading.has(recordId) && (
            <LoadingWrapper>
              <LoadingIndicator
                domID="basic-details-form-loading-indicator"
                length="30px"
              />
            </LoadingWrapper>
          )}
          {actionType !== 'Read only' ? (
            <Button
              onClick={onSave}
              domID="automation-id"
              name="Save"
              buttonType="emphasized"
              size="medium"
              type="button"
              disabled={
                isButtonDisabled ||
                loading.has(recordId) ||
                erisaMessageError ||
                !message
              }
            />
          ) : null }
          <Button
            domID="automation-id"
            name={actionType !== 'Read only' ? 'Cancel' : 'Close'}
            buttonType="diminished"
            disabled={loading.has(recordId)}
            size="medium"
            onClick={onCancel}
            type="button"
          />
        </ModalButton>
      </ButtonGroupBottom>
    </>
  );
}

ErisaMessageModal.propTypes = {
  detailElements: PropTypes.object,
  loading: PropTypes.object,
  selectedRuleRecord: PropTypes.object,
  selectedRuleDetail: PropTypes.object,
  selectedRecord: PropTypes.object,
  setIsCloningRuleDetail: PropTypes.func,
  setIsPostingRuleDetail: PropTypes.func,
  setIsShowingErisaMessageModal: PropTypes.func,
  isCloningRuleDetail: PropTypes.bool,
  isShowingErisaMessageModal: PropTypes.bool,
  isPostingRuleDetail: PropTypes.bool,
  postRuleDetails: PropTypes.func,
  putRuleDetails: PropTypes.func,
  actionType: PropTypes.string,
  selectedRuleTypeId: PropTypes.string,
};

export default ErisaMessageModal;
