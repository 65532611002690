import { connect } from 'react-redux';
import GridWrapper from './components/GridWrapper';
import {
  triggerAllRulesForRuleTypesRequest,
  triggerAllRulesForRuleTypesAssociationsRequest,
  rulesCountFromDBTrigger,
  resetRuleDetailsPane,
  updateCurrentTab,
  setSelectedRuleRecord,
  resetRuleTypeRecords,
} from '../../redux/api/rulesForRuleTypesList/actions';
import { resetDetailsPane } from '../../redux/app/detailsPaneState/actions';
import {
  setIsShowingMultipleCommitRulesModal,
  setIsMultipleRulesCommitingState,
  triggerMultipleCommitPutRequest,
} from '../../redux/api/commitRuleTypesList/actions';

import {
  setIsViewingRuleDetails,
  setRuleTypeRequestTrigger,
  triggerImagesTestCountRequest,
} from '../../redux/api/ruleTypesList/actions';
import {
  ruleDetailElementsTrigger,
  setIsShowingProductionArrangementModal,
  getAddNewAddressElementsTrigger,
  setIsShowingCustomerServiceMessageModal,
  setIsShowingPDFTaggingModal,
  getRuleImagesTrigger,
  resetRuleImagesDataTrigger
} from '../../redux/api/ruleDetails/actions';
import {
  setIsPostingRulesForRuleTypes,
  setIsShowingCloneRuleModal,
  setIsCloningRuleState,
  setRecordForDetailPane,
  setIsCommittingRule,
  setIsShowingActiveGroupsListingModal,
} from '../../redux/api/rulesForRuleTypes/actions';
import {
  selectCurrentDetailsRecord,
  selectIsPostingRulesForRulesTypes,
  selectRuleCloningState,
  selectUserRole,
  selectUserPermissions,
  selectUserRoleId
} from '../RulesTabView/selectors';
import {
  selectCurrentRuleRecord,
  selectCurrentRuleRecordId,
  selectMultipleRuleCommitingState,
  selectMultipleCommitPutSuccess,
  selectMultipleCommitPutError,
  selectCurrentSelectedRecord,
} from './selectors';
import { selectNotificationState } from '../BasicTabDetailsView/selectors';
import {
  addNotification,
  deleteNotification,
} from '../../redux/app/notificationState/actions';
import {
  captureCurrentPage,
} from '../../redux/api/pageTracker/actions';

const mapStateToProps = state => ({
  ...state,
  ...state.rulesForRuleTypesList,
  notifications: selectNotificationState(state),
  selectedRecord: selectCurrentDetailsRecord(state),
  isCommittingRule: state.rulesForRuleTypes.isCommittingRule,
  isMultipleCommiting: selectMultipleRuleCommitingState(state),
  isPostingRulesForRuleTypes: selectIsPostingRulesForRulesTypes(state),
  showCloneRuleModal: state.rulesForRuleTypes.showCloneRuleModal,
  isCloningRule: selectRuleCloningState(state),
  selectedRuleRecord: selectCurrentRuleRecord(state),
  selectedCurrentRecord: selectCurrentSelectedRecord(state),
  selectedRuleTypeId: selectCurrentRuleRecordId(state),
  multipleCommitPutSuccess: selectMultipleCommitPutSuccess(state),
  multipleCommitPutError: selectMultipleCommitPutError(state),
  loading: state.loadingState.loading,
  showMultipleCommitRulesModal:
    state.commitRuleTypesList.showMultipleCommitRulesModal,
  showActiveGroupsListingModal:
    state.rulesForRuleTypes.showActiveGroupsListingModal,
  allRuleTypes: state.ruleTypesList.setRuleTypesListRecords,
  multipleTestImageCountRecords: state.ruleTypesList.multipleTestImageCountRecords,
  ruleGridQuery: state.rulesForRuleTypesList.ruleGridQuery,
  selectedActiveRecordsList: state.rulesForRuleTypes.selectedActiveRecordsList,
  selectedRecordName: state.rulesForRuleTypes.selectedRecordName,
  activeGroupsModalLocation: state.rulesForRuleTypes.activeGroupsModalLocation,
  userRole: selectUserRole(state),
  userPermissions: selectUserPermissions(state),
  userRoleId: selectUserRoleId(state)
});

const mapDispatchToProps = {
  addNotification,
  deleteNotification,
  ruleDetailElementsTrigger,
  triggerAllRulesForRuleTypesRequest,
  triggerAllRulesForRuleTypesAssociationsRequest,
  updateCurrentTab,
  setIsMultipleRulesCommitingState,
  setIsShowingMultipleCommitRulesModal,
  rulesCountFromDBTrigger,
  resetRuleDetailsPane,
  setIsCommittingRule,
  setIsPostingRulesForRuleTypes,
  setIsShowingProductionArrangementModal,
  setIsShowingCustomerServiceMessageModal,
  setIsShowingPDFTaggingModal,
  setIsViewingRuleDetails,
  setIsShowingCloneRuleModal,
  setIsCloningRuleState,
  setSelectedRuleRecord,
  resetDetailsPane,
  setRecordForDetailPane,
  triggerMultipleCommitPutRequest,
  getAddNewAddressElementsTrigger,
  setIsShowingActiveGroupsListingModal,
  setRuleTypeRequestTrigger,
  triggerImagesTestCountRequest,
  resetRuleTypeRecords,
  getRuleImagesTrigger,
  resetRuleImagesDataTrigger,
  captureCurrentPage
};

export default connect(mapStateToProps, mapDispatchToProps)(GridWrapper);
