import styled from 'styled-components';

export const DropDownOne = styled.div`
  margin-right: 35px;
  margin-bottom: 15px;
  width: 240px;
`;
export const DropSmallMargin = styled.div`
  margin-bottom: 30px;
`;
export const DropLargeMargin = styled.div`
  margin-bottom: 50px;
`;
export const DropDownTwo = styled.div`
  width: 200px;
  margin-right: 35px;
`;
export const DropDownThree = styled.div``;
export const ContainerBox = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  & button:last-child {
    position: absolute;
    margin-top: 60px;
`;
export const LoadingWrapper = styled.div`
  float: left;
  margin-left: -22px;
`;
export const ContainerBoxTextArea = styled.div`
  width: 100%;
`;
