/* eslint-disable camelcase */

import { useEffect, useState } from 'react'
import { useAxiosPost } from '../hooks'

const useGetNCOAAssosiatePAFBrokersTable=()=>{
  
  const [NCOA_PAF, setNCOA_PAF]=useState(null)
  const [NCOA_PAFCount, setNCOA_PAFCount]=useState(0)
  const {postData, isLoading,isError,error, data }=useAxiosPost({
    url:'clientAdministration/clientNCOAPAFManager/getNCOAPAFsWithBrokersList',
    method:'POST',
    onError: (e) => 
    // what happens on error? if you need something extra
    // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })

  useEffect(()=>{
    if (data){
      const {NCOA_PAFS_BROKERS}=data;
      if (NCOA_PAFS_BROKERS) {
        const {NCOA_PAF: NCOA_PAFp}=NCOA_PAFS_BROKERS
        if (NCOA_PAFp) {
          let NCOA_PAF_Result=[]
          
          if (NCOA_PAFp.constructor.name==="Object") {
            NCOA_PAF_Result.push({...NCOA_PAFp})
          } else if(NCOA_PAFp.constructor.name==="Array"){
            NCOA_PAF_Result=[...NCOA_PAFp]
          }
          setNCOA_PAF(JSON.parse(JSON.stringify(NCOA_PAF_Result.map((t)=>({...t, cPAFID: t.$.cPAFID})))))
          setNCOA_PAFCount(NCOA_PAF_Result.length)
          
        } else{
          setNCOA_PAF(JSON.parse(JSON.stringify([])))
          setNCOA_PAFCount(0)
        } 
        
      } else{
        setNCOA_PAF(JSON.parse(JSON.stringify([])))
        setNCOA_PAFCount(0)
      }
      
    } else{
      setNCOA_PAF(JSON.parse(JSON.stringify([])))
      setNCOA_PAFCount(0)
    }
  },[data])
  
  return { isLoading, isError, error, postData, NCOA_PAF, NCOA_PAFCount, setNCOA_PAF};
}
const useGetNCOAPAFBrokersTable=()=>{
  
  const [NCOA_PAF_BROKER, setNCOA_PAF_BROKER]=useState(null)
  const [NCOA_PAF_BROKERCount, setNCOA_PAF_BROKERCount]=useState(0)
  const {postData, isLoading,isError,error, data }=useAxiosPost({
    url:'clientAdministration/clientNCOAPAFManager/getNCOAPAFBrokersList',
    method:'POST',
    onError: (e) => 
      // what happens on error? if you need something extra
      // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })
  useEffect(()=>{
    if(data){
      const {NCOA_PAF_BROKERS}=data;
      if (NCOA_PAF_BROKERS) {
        const {NCOA_PAF_BROKER: NCOA_PAF_BROKERp}=NCOA_PAF_BROKERS
        if (NCOA_PAF_BROKERp) {
          let NCOA_PAF_BROKER_Result=[];
          if (NCOA_PAF_BROKERp.constructor.name==="Object") {
            NCOA_PAF_BROKER_Result.push({...NCOA_PAF_BROKERp})
          } else if(NCOA_PAF_BROKERp.constructor.name==="Array"){
            NCOA_PAF_BROKER_Result=[...NCOA_PAF_BROKERp]
          }
          
          setNCOA_PAF_BROKER(JSON.parse(JSON.stringify(NCOA_PAF_BROKER_Result.map((t)=>({...t, cBrokerID: t.$.cBrokerID})))))
          setNCOA_PAF_BROKERCount(NCOA_PAF_BROKER_Result.length)

        } else{
          setNCOA_PAF_BROKER(JSON.parse(JSON.stringify([])))
          setNCOA_PAF_BROKERCount(0)
        }
        
      } else{
        setNCOA_PAF_BROKER(JSON.parse(JSON.stringify([])))
        setNCOA_PAF_BROKERCount(0)
      }
      
    } else{
      setNCOA_PAF_BROKER(JSON.parse(JSON.stringify([])))
      setNCOA_PAF_BROKERCount(0)
    }
  },[data])
    
  return { isLoading, isError, error, postData, NCOA_PAF_BROKER, NCOA_PAF_BROKERCount, setNCOA_PAF_BROKER};
}

const useUpdatePAFBrokersAssociations=()=>{
  const {postData, isLoading,isError,error, data }=useAxiosPost({
    url:'clientAdministration/clientNCOAPAFManager/updatePAFBrokersAssociations',
    method:'POST',
    onError: (e) => 
      // what happens on error? if you need something extra
      // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })
  return {postData, isLoading,isError,error, data}
}

export {
  useGetNCOAAssosiatePAFBrokersTable,
  useGetNCOAPAFBrokersTable,
  useUpdatePAFBrokersAssociations
}