import React from 'react';
import PropTypes from 'prop-types';
import { DetailHeader, Button } from 'ui-core-ssgr';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import filter from 'lodash/filter';
import {
  PaddingWrapper,
  DetailHeaderWrapper,
} from '../../../../../shared/styles/spacing';
import { RightSideButtons } from '../../../../../shared/styles/styledComponents';
import DeleteRuleDetailModal from '../../../../RuleDetailModals/DeleteRuleDetailModal';

import {
  headerProps,
  ACTION_BUTTONS
} from './constants';


const RuleDetailsGridHeader = props => {
  const {
    recordCount,
    headerName,
    isPostingRulesForRuleTypes,
    selectedItemIds,
    setIsShowingDeleteRuleDetailModal,
    isShowingDeleteRuleDetailModal,
    deleteRuleDetailTrigger,
    selectedRecord,
    records,
    loading,
    selectedRuleTypeId,
    detailElements,
    setIsCloningRuleDetail,
    setIsShowingProductionArrangementModal,
    setIsShowingCustomerServiceMessageModal,
    setIsShowingReturnAddressModal,
    setIsShowingDuplexRuleModal,
    setIsShowingSignatureRuleModal,
    setIsShowingCheckTextRuleModal,
    setIsShowingImageRuleModal,
    setIsPostingRuleDetail,
    setIsShowingAutoHoldModal,
    setIsShowingMemberIdModal,
    setIsShowingTaxIdArrangementRuleModal,
    setIsShowingErisaMessageModal,
    setShowingHighDollarReviewModal,
    setIsShowingProviderInsuredOptionMessageModal,
    setIsShowingGeneralMessageModal,
    setIsShowingPDFTaggingModal,
    addNotification,
    errorNotifications,
    getAddNewAddressElements,
    isCloningRule
  } = props;

  const onButtonClick = name => {
    if (name === 'delete') {
      setIsShowingDeleteRuleDetailModal(true);
    } else if (!isEmpty(detailElements)) {
      if (name === 'edit' || name === 'add' || name === 'clone') {
        if (
          (name === 'add' || name === 'clone') &&
            selectedRuleTypeId === 'PAR'
        ) {
          setIsShowingProductionArrangementModal(true);
          if (name === 'add') {
            setIsPostingRuleDetail(true);
          } else {
            setIsCloningRuleDetail(true);
          }
        }
        if (
          (name === 'add' || name === 'clone') &&
            selectedRuleTypeId === 'CSM'
        ) {
          setIsShowingCustomerServiceMessageModal(true);
          if (name === 'add') {
            setIsPostingRuleDetail(true);
          } else {
            setIsCloningRuleDetail(true);
          }
        }
        if (
          selectedRuleTypeId === 'RAD' &&
            (name === 'edit' || name === 'add' || name === 'clone')
        ) {
          if (name === 'add') {
            setIsPostingRuleDetail(true);
          } else {
            setIsCloningRuleDetail(true);
          }
          setIsShowingReturnAddressModal(true);
          getAddNewAddressElements({selectedRuleTypeId});
        }
        if (
          selectedRuleTypeId === 'DPX' &&
            (name === 'edit' || name === 'add' || name === 'clone')
        ) {
          if (name === 'add') {
            setIsPostingRuleDetail(true);
          } else {
            setIsCloningRuleDetail(true);
          }
          setIsShowingDuplexRuleModal(true);
        }
        if (
          selectedRuleTypeId === 'SIG' &&
            (name === 'edit' || name === 'add' || name === 'clone')
        ) {
          if (name === 'add') {
            setIsPostingRuleDetail(true);
          } else {
            setIsCloningRuleDetail(true);
          }
          setIsShowingSignatureRuleModal(true);
        }

        if (
          selectedRuleTypeId === 'CHT' &&
            (name === 'edit' || name === 'add' || name === 'clone')
        ) {
          if (name === 'add') {
            setIsPostingRuleDetail(true);
          } else {
            setIsCloningRuleDetail(true);
          }
          setIsShowingCheckTextRuleModal(true);
        }

        if (
          selectedRuleTypeId === 'IMG' &&
            (name === 'edit' || name === 'add' || name === 'clone')
        ) {
          if (name === 'add') {
            setIsPostingRuleDetail(true);
          } else {
            setIsCloningRuleDetail(true);
          }
          setIsShowingImageRuleModal(true);
        }

        if (
          selectedRuleTypeId === 'HLD' &&
            (name === 'edit' || name === 'add' || name === 'clone')
        ) {
          if (name === 'add') {
            setIsPostingRuleDetail(true);
          } else {
            setIsCloningRuleDetail(true);
          }
          setIsShowingAutoHoldModal(true);
        }
        if (
          selectedRuleTypeId === 'SSN' &&
            (name === 'edit' || name === 'add' || name === 'clone')
        ) {
          if (name === 'add') {
            setIsPostingRuleDetail(true);
          } else {
            setIsCloningRuleDetail(true);
          }
          setIsShowingMemberIdModal(true);
        }
        if (
          selectedRuleTypeId === 'TAX' &&
            (name === 'edit' || name === 'add' || name === 'clone')
        ) {
          if (name === 'add') {
            setIsPostingRuleDetail(true);
          } else {
            setIsCloningRuleDetail(true);
          }
          setIsShowingTaxIdArrangementRuleModal(true);
        }
        if (
          selectedRuleTypeId === 'ERI' &&
            (name === 'edit' || name === 'add' || name === 'clone')
        ) {
          if (name === 'add') {
            setIsPostingRuleDetail(true);
          } else {
            setIsCloningRuleDetail(true);
          }
          setIsShowingErisaMessageModal(true);
        }
        if (
          selectedRuleTypeId === 'HDR' &&
            (name === 'edit' || name === 'add' || name === 'clone')
        ) {
          if (name === 'add') {
            setIsPostingRuleDetail(true);
          } else {
            setIsCloningRuleDetail(true);
          }
          setShowingHighDollarReviewModal(true);
        }
        if (
          selectedRuleTypeId === 'PIO' &&
            (name === 'edit' || name === 'add' || name === 'clone')
        ) {
          if (name === 'add') {
            setIsPostingRuleDetail(true);
          } else {
            setIsCloningRuleDetail(true);
          }
          setIsShowingProviderInsuredOptionMessageModal(true);
        }
        if (
          selectedRuleTypeId === 'GEN' &&
            (name === 'edit' || name === 'add' || name === 'clone')
        ) {
          if (name === 'add') {
            setIsPostingRuleDetail(true);
          } else {
            setIsCloningRuleDetail(true);
          }
          setIsShowingGeneralMessageModal(true);
        }
        if (
          selectedRuleTypeId === 'PDT' &&
            (name === 'edit' || name === 'add' || name === 'clone')
        ) {
          if (name === 'add') {
            setIsPostingRuleDetail(true);
          } else {
            setIsCloningRuleDetail(true);
          }
          setIsShowingPDFTaggingModal(true);
        }
      }
    } else if (errorNotifications) {
      errorNotifications.forEach(element => {
        if (element.type === 'ruleDetailElements') {
          addNotification({
            id: selectedRuleTypeId,
            type: 'negative',
            msg: element.msg
          });
        }

      });
    }
  };

  /**
   * Function for showing the required action buttons when there is user activity on the grid or ruleDetailsPage
   * The action buttons come from the constants file in an array of objects form with the properties of the buttons
   */
  const getEnabledActionButtons = () => {
    let buttons = ACTION_BUTTONS({ isPostingRulesForRuleTypes, detailElements });

    if (selectedItemIds.size === 1 && recordCount === 1) {
      buttons = filter(buttons, button => button.value !== 'delete');
    } else if (selectedItemIds.size === 1 && recordCount > 1) {
      buttons = ACTION_BUTTONS({ isPostingRulesForRuleTypes, detailElements });
    } else if (selectedItemIds.size > 1 && selectedItemIds.size !== recordCount) {
      buttons = filter(buttons, button => button.value !== 'clone');
    } else {
      buttons = filter(buttons, button => button.value !== 'clone' && button.value !== 'delete');
    };

    return map(buttons, button => (
      <Button
        key={button.value}
        name={button.name}
        buttonType="deEmphasized"
        onClick={() => onButtonClick(button.value)}
        disabled={button.disabled}
        size="medium"
        type="button"
      />
    ))
  };

  return (
    <PaddingWrapper>
      {isShowingDeleteRuleDetailModal && (
        <DeleteRuleDetailModal
          selectedItemIds={selectedItemIds}
          setIsShowingDeleteRuleDetailModal={setIsShowingDeleteRuleDetailModal}
          deleteRuleDetailTrigger={deleteRuleDetailTrigger}
          selectedRecord={selectedRecord}
          records={records}
          loading={loading.size !== 0}
          selectedRuleTypeId={selectedRuleTypeId}
        />
      )}
      <DetailHeaderWrapper>
        <DetailHeader
          {...headerProps}
          title={headerName}
          amountKey="amount"
          domID="header"
          count={!loading.has('ruleDetailsGridLoading') && !isPostingRulesForRuleTypes ? recordCount : null}
          marginBottom={12}
        />
      </DetailHeaderWrapper>
      <RightSideButtons>
        {!isCloningRule ? getEnabledActionButtons() : null}
      </RightSideButtons>
    </PaddingWrapper>
  );
};

RuleDetailsGridHeader.defaultProps = {
  recordCount: 0,
};

RuleDetailsGridHeader.propTypes = {
  loading: PropTypes.object.isRequired,
  isPostingRulesForRuleTypes: PropTypes.bool,
  selectedItemIds: PropTypes.object,
  recordCount: PropTypes.number,
  headerName: PropTypes.string,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  setIsCloningRuleDetail: PropTypes.func,
  setIsShowingProductionArrangementModal: PropTypes.func,
  setIsShowingCustomerServiceMessageModal: PropTypes.func,
  setIsShowingReturnAddressModal: PropTypes.func,
  setIsShowingDuplexRuleModal: PropTypes.func,
  setIsShowingSignatureRuleModal: PropTypes.func,
  setIsShowingCheckTextRuleModal: PropTypes.func,
  setIsShowingImageRuleModal: PropTypes.func,
  setIsPostingRuleDetail: PropTypes.func,
  setIsShowingAutoHoldModal: PropTypes.func,
  setIsShowingMemberIdModal: PropTypes.func,
  setIsShowingTaxIdArrangementRuleModal: PropTypes.func,
  setIsShowingErisaMessageModal: PropTypes.func,
  setShowingHighDollarReviewModal: PropTypes.func,
  setIsShowingProviderInsuredOptionMessageModal: PropTypes.func,
  setIsShowingGeneralMessageModal: PropTypes.func,
  setIsShowingPDFTaggingModal: PropTypes.func,
  addNotification: PropTypes.func,
  errorNotifications: PropTypes.object,
  getAddNewAddressElements: PropTypes.func,
  setIsShowingDeleteRuleDetailModal: PropTypes.func,
  isShowingDeleteRuleDetailModal: PropTypes.bool,
  deleteRuleDetailTrigger: PropTypes.func,
  selectedRecord: PropTypes.object,
  records: PropTypes.array,
  selectedRuleTypeId: PropTypes.string,
  detailElements: PropTypes.object,
  isCloningRule: PropTypes.bool
};

export default RuleDetailsGridHeader;
