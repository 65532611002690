/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import styled from 'styled-components/dist/styled-components'
import { useLayout } from 'ui-core/dist/useLayout';
import DetailPaneHeader from 'ui-core/dist/DetailPaneHeader';
import { useSelector, useDispatch } from 'react-redux';
import {
  Modal, Button, ModalHeader, Input, TextArea, ModalBody
} from 'ui-core/dist';
import { detailPaneAnimStates } from '../../../commonResources/constants/animStates';
import ErrorPage from '../../ErrorPage';
import Authorizer from '../../Authorizer';
import LoadingComponent from '../../../components/LoadingComponent';
import useNotificationBannerHook from '../../../useNotificationBannerHook';
import { SelectDropdown } from '../../../components'
import {
  setDraftCodeData, resetDraftCodeData
} from '../Modules/action'
import {
  useGetSelectedFormData,
  useAddFormTranslation,
  useGetForms,
  useUpdateFormTranslation,
  useDeleteFormTranslation
} from '../../../api/useClientRuleManager'

const FormTranslationDetail = () => {
  const {
    expandCollapseDetailPaneButtonConfig,
    closeDetailPaneButtonConfig,
    detailAnimationState,
    closeDetailPane
  } = useLayout()
  const dispatch = useDispatch();
  const { notify } = useNotificationBannerHook();
  // const [draftCode, setDraftCode] = useState('')
  // const [message, setMessage] = useState('')
  // const [logo, setLogo] = useState('')
  const [cFormTranslationId, setFormTranslationId] = useState('')
  const [cClientForm, setClientForm] = useState('')
  const [formList, setformList] = useState([]);
  const [formListData, setFormListData] = useState()
  const [confirmationModalMode, setconfirmationModalMode] = useState('')
  const [canClear, setCanClear] = useState(true);
  const [isOpenConfirmationModal, setisOpenConfirmationModal] = useState(false)
  const [editorMode, setEditorMode] = useState('Add')

  const [count, setcount] = useState(0)

  const { terroristExclusionSelectedRecord, terroristExclusionEditorMode: editorModep, refetchterroristExclusionRecord }
    = useSelector(({ clientRule }) => clientRule);
  // const [form, setForm] = useState({label:terroristExclusionSelectedRecord.FormDesc,value:terroristExclusionSelectedRecord.FormName});
  const {FormData, totalRecords,getAllForms} = useGetForms();  
  useEffect(()=>{
    getAllForms(JSON.parse(JSON.stringify({      
      "maxRecords":"5000",
      "rowPosition": "10",
      "sortField":"",
      "sortDescending":true,
      count
    })))
    setcount(count+1)
  },[])
  useEffect(() => {
    
    if (terroristExclusionSelectedRecord && terroristExclusionSelectedRecord.FormTranslationId) {
      getShowMoreData(terroristExclusionSelectedRecord.FormTranslationId);
        
    } else 
    {
      onClearCurrentRecordValues()
    }
    
    
  }, [terroristExclusionSelectedRecord]);

 

  const getShowMoreData = (key) => {
    postDataShowMoreInfo({ formTranslationId:key,count})
    setcount(count + 1)
  }
  const onCopyCurrentRecordValues = ()=>{
    setFormTranslationId('')
    setEditorMode('Edit')
  }

  const { showMoreInfo, postData: postDataShowMoreInfo, isLoading: isLoadingShowMoreInfo,
    isError: isErrorShowMoreInfo, error: errorShowMoreINfo } = useGetSelectedFormData()

  const { data: infoAddAddressExclusion, postData: addFormTranslation, metaData: addTerroristExclusionResponse, isLoading: isLoadingaddTerroristExclusion,
    isError: isErroraddTerroristExclusion, error: erroraddTerroristExclusion } = useAddFormTranslation()

  const { data: infoModifyAddressExclusion, postData: modifyDraftCode, metaData: modifyTerroristExclusionResponse, isLoading: isLoadingmodifyTerroristExclusion,
    isError: isErrormodifyTerroristExclusion, error: errormodifyTerroristExclusion } = useUpdateFormTranslation()

  const { data: infoDeleteRecipType, postData: DeleteDraftCode, metaData: deleteClaimTypeTranslationResponse, isLoading: isLoadingdeleteRecipientTypeTranslation,
    isError: isErrordeleteRecipientTypeTranslation, error: errordeleteRecipientTypeTranslation } = useDeleteFormTranslation()
  const onResetCurrentRecordValues = () => {
    if (showMoreInfo) {
      setFormTranslationId(showMoreInfo?.formTranslationId)
      setClientForm(showMoreInfo?.clientForm)
      setFormListData(terroristExclusionSelectedRecord?.AbfForm)
      setEditorMode(editorModep)
    }
  }

  useEffect(()=>{
    if (FormData) {
      const FormL = FormData || null;
      const newFormData = FormL ? Array.isArray(FormL) ? FormL : [FormL] : [];
      const so=newFormData.map((x)=>({label: x.FormDesc,value:x. FormName}))
      setformList([...JSON.parse(JSON.stringify(so))])
    }},[FormData])

  useEffect(() => {
    if (addTerroristExclusionResponse) {
      const { status, statusText } = addTerroristExclusionResponse;
      if (status === 200 || statusText === "OK") {
        if (infoAddAddressExclusion && infoAddAddressExclusion.fs) {
          const { fs } = infoAddAddressExclusion;
          if (fs) {
            notify(`You must provide a unique value for the Client Form Code. This value has already been used.`, 'negative')
            return;
          }
        }
        if (infoAddAddressExclusion && infoAddAddressExclusion.resp) {
          const { resp } = infoAddAddressExclusion;
          if (resp) {
            notify(`You have successfully added Form Translation ${resp}.`, 'positive')
            closeDetailPane()
            if (refetchterroristExclusionRecord) refetchterroristExclusionRecord()
            return
          }
          return;
        }
        // notify(`You have successfully modified Form Translation ${cFormTranslationId}.`, 'positive')
        closeDetailPane()
        if (refetchterroristExclusionRecord) refetchterroristExclusionRecord()
      }
    }
  }, [addTerroristExclusionResponse])

  useEffect(() => {
    if (modifyTerroristExclusionResponse) {
      const { status, statusText } = modifyTerroristExclusionResponse;
      if (status === 200 || statusText === "OK") {
        if (infoModifyAddressExclusion && infoModifyAddressExclusion.fs) {
          const { fs } = infoModifyAddressExclusion;
          if (fs) {
            notify(fs, 'negative')
            return;
          }
        }
        notify(`You have successfully modified Form Translation ${cFormTranslationId}.`, 'positive')
        closeDetailPane()
        if (refetchterroristExclusionRecord) refetchterroristExclusionRecord()
      }
    }
  }, [modifyTerroristExclusionResponse])

  useEffect(() => {
    if (deleteClaimTypeTranslationResponse) {
      const { status, statusText } = deleteClaimTypeTranslationResponse;
      if (status === 200 || statusText === "OK") {
        if (infoDeleteRecipType && infoDeleteRecipType.fs) {
          const { fs } = infoDeleteRecipType;
          if (fs) {
            notify(fs, 'negative')
            return;
          }
        }
        notify(`You have successfully deleted Form Translation ${cFormTranslationId}.`, 'positive')
        closeDetailPane()
        if (refetchterroristExclusionRecord) refetchterroristExclusionRecord()
      }
    }
  }, [deleteClaimTypeTranslationResponse])

  const onSubmitClick = () => {
    if (editorMode === "Add") {
      setconfirmationModalMode("Add")
    } else if (editorMode === "Edit") {
      setconfirmationModalMode("Modify")
    }
    setisOpenConfirmationModal(true)
  }
  const onConfirmationYesClick = () => {
    if (confirmationModalMode === "Add") {
      addFormTranslation({
        count,
        formTranslationId: "",
        clientForm: cClientForm,
        abfForm: formListData
      })
      setcount(count + 1)

    } else if (confirmationModalMode === "Modify") {
      modifyDraftCode({
        count,
        formTranslationId: cFormTranslationId,
        clientForm: cClientForm,
        abfForm: formListData
      })
      setcount(count + 1)
    } else if (confirmationModalMode === "Delete") {
      DeleteDraftCode({
        count,
        formTranslationId: cFormTranslationId,
        clientForm: cClientForm,
        abfForm: formListData,
        isActive: 0
      })
      setcount(count + 1)
    }
    setconfirmationModalMode('')
    setisOpenConfirmationModal(false)
  }
  useEffect(() => {
    onResetCurrentRecordValues()
  }, [showMoreInfo])

  const onClearCurrentRecordValues = () => {
    
    setFormTranslationId('')
    setClientForm('')
    setFormListData('')
    setEditorMode('Add')
  }
  const renderFormTranslationID = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_name"
      placeholder=""
      size="small"
      initialValue={cFormTranslationId}
      disabled
      // errorMessage="Incorrect data do not use '&', '|' or '>' characters"
      // hasError={/[\\&>\\|]/.test(name)}
      onChange={(e) => {
        setFormTranslationId(e.target.value || "")
      }}
    />
  ), [cFormTranslationId])
  const renderClientForm = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_address"
      placeholder=""
      size="small"
      maxLength={5}
      initialValue={cClientForm}
      // errorMessage="Incorrect data do not use '&', '|' or '>' characters"
      // hasError={/[\\&>\\|]/.test(address)}
      onChange={(e) => {
        setClientForm(e.target.value || "")
      }}
    />
  ), [cClientForm])
  const renderLogo = useMemo(() => (
    <SelectDropdown
      width="350px"
      domID="defer_state_input"
      label=""
      options={formList}
      size="small"
      initialValue={{ label: formList.find(x=>x.value===formListData)?.label, value: formListData }}
      onChange={(e)=>{
        if (e) {
          if (setFormListData) setFormListData(e.value)
        } else if (setFormListData) setFormListData(null)
      }}
    />
  ), [formList, formListData, terroristExclusionSelectedRecord])
  
  const validateForm = useMemo(() => {
    if(!cClientForm || !formListData){
      return true
    }
    return false

  }, [cClientForm, formListData])

  const renderAddressEntryForm = useMemo(() => {
    if (detailAnimationState > detailPaneAnimStates.COLLAPSED) {
      return ( 
        <div style={{ marginLeft: '40px' }} className="marginLeft">
          <div>
            <p>
              <div className="displayFlex">Complete the information below, and then click Submit to continue.</div>
            </p>
            <p>
              <div className="displayFlex"><span style={{ color: 'red' }}>* indicates required information</span></div>
            </p>
          </div>
          <br />
          <div className="displayFlex">
            <table className="common-table">
              {editorMode !== "Add"? <tr>
                <td>
                  <label> Form Translation ID : </label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderFormTranslationID}
                  </div>
                </td>
              </tr>: null}
              <tr><p></p></tr>
              <tr>
                <td>
                  <label> Client Form Code : <span style={{ color: 'red' }}>*</span></label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderClientForm}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td style={{width: "198px"}}>
                  <label>Form Name & Form Description :  <span style={{ color: 'red' }}>*</span></label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderLogo}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              
              <tr>
                <td colSpan="2">
                  <div className="displayFlex">
                    <div>
                      <Button
                        domID="btn_reset"
                        buttonType="standard"
                        size="small"
                        name="RESET"
                        // disabled={editorMode !== "Edit"}
                        onClick={() => {
                          if(editorMode==='Add')
                          onClearCurrentRecordValues()
                          else
                          onResetCurrentRecordValues()
                        }}
                      />
                    </div>
                    <div className="marginLeft">
                      <Authorizer permission= "CAN_PERFORM_CLIENTRULES_FRT_ADD">
                      <Button
                        domID="btn_copy"
                        buttonType="standard"
                        size="small"
                        name="COPY"
                        disabled={editorMode === "Add"}
                        onClick={() => {
                          onCopyCurrentRecordValues()
                          setEditorMode('Add')
                        }}
                      />
                      </Authorizer>
                    </div>
                    <div className="marginLeft">
                    <Authorizer permission= "CAN_PERFORM_CLIENTRULES_FRT_DELETE">
                      <Button
                        domID="btn_delete"
                        buttonType="standard"
                        size="small"
                        name="DELETE"
                        disabled={editorMode !== "Edit"}
                        onClick={() => {setconfirmationModalMode("Delete")
                          setisOpenConfirmationModal(true)}}
                      />
                      </Authorizer>
                    </div>
                    <div className="marginLeft">
                      <Authorizer permission= "CAN_PERFORM_CLIENTRULES_FRT_MODIFY">
                      <Button
                        domID="btn_search"
                        buttonType="standard"
                        size="small"
                        name="SUBMIT"
                        disabled={validateForm}
                        onClick={() => onSubmitClick()}
                      />
                      </Authorizer>
                    </div>
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
            </table>
          </div>
          <Modal isOpen={isOpenConfirmationModal}>
            <ModalHeader
              title={`Do you want to ${confirmationModalMode} ?`}
              onClose={() => setisOpenConfirmationModal(false)}
            />
            <ModalBody>
              {isOpenConfirmationModal ? (
                <div style={{ marginLeft: '34px', marginBottom: '20px' }} className="marginLeft">
                  <div className="displayFlex">
                    <div className="marginLeft">
                      <Button
                        domID="btn_search"
                        buttonType="standard"
                        size="small"
                        name="Yes"
                        onClick={() => onConfirmationYesClick()}
                      />
                    </div>
                    <div className="marginLeft">
                      <Button
                        domID="btn_search"
                        buttonType="standard"
                        size="small"
                        name="No"
                        onClick={() => setisOpenConfirmationModal(false)}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
            </ModalBody>
          </Modal>
        </div>
      )
    }
    return null;
  }, [terroristExclusionSelectedRecord, showMoreInfo, detailAnimationState, cFormTranslationId, cClientForm, formList, formListData,
    validateForm, editorMode, confirmationModalMode, isOpenConfirmationModal])

  return (
    <>
      <DetailPaneHeader
        title={editorMode === "Edit" ? "Modify Form Translation" : "Add Form Translation"}
        layoutControls={[
          expandCollapseDetailPaneButtonConfig,
          closeDetailPaneButtonConfig
        ]}
      />
      <>
        {isLoadingShowMoreInfo || isLoadingaddTerroristExclusion
          || isLoadingmodifyTerroristExclusion || isLoadingdeleteRecipientTypeTranslation ? (
            // replace with a better loading component            
            <div style={{ marginLeft: '50%' }}><LoadingComponent /></div>
          ) : isErrorShowMoreInfo || isErroraddTerroristExclusion
            || isErrormodifyTerroristExclusion  || isErrordeleteRecipientTypeTranslation? (
              // replace with a better error component
              <ErrorPage
                // this prop isn't real but is an example
                error={errorShowMoreINfo || erroraddTerroristExclusion
                  || errormodifyTerroristExclusion || errordeleteRecipientTypeTranslation}
              />
            ) : (
              <>
                {renderAddressEntryForm}
              </>
            )
        }
      </>
    </>
  )
}

export default FormTranslationDetail