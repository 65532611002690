import React, {useState, useEffect} from 'react';
import styled from 'styled-components/macro';

const AnchorInputStyled = styled.button`  
  text-decoration: underline;
  border: none;
  box-shadow: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  outline: none;
  color: ${props=>props.color||'inherit'};
`;

const AnchorInput=(props)=>{
  const [color,setcolor]=useState(null)
  const onMouseDown=()=>{
    setcolor('purple')
  }
  return (
    <AnchorInputStyled {...props} color={color} onMouseDown={onMouseDown} />
  )
}
export default AnchorInput;