
const MULTIPLE_SEPARATOR = ",";
const ASTERISK = "*";
const RANGE_SEPARATOR = "@~@"; 
const BACKQUOTE = "`";

export const udpateFirstNumber = (number01, number2, mode) => {
  let number1 = number01;
  number1 = number1.replace(/\*/g, "").replace(/^\s+|\s+$/g, "");
  if (mode === 'Partial' && number1 && !number2) {
    number1 += "*";
  }
  return number1
}

const isAlphanumeric = (strdata) => {
  // const alphanumericRegex =/^((([A-Za-z0-9]*)([-\\_\\.]*)([A-Za-z0-9]*))+)$/ ;
  const alphanumericRegex = /^[a-z0-9]+$/i ;
  const regex = new RegExp(alphanumericRegex);

  if (!regex.test(strdata)) {
    return false;
  }
  return true;
}
export const adjustNumber = (number1, number2) => number1 && number2 ? number1 + RANGE_SEPARATOR + number2 : adjustNumber1(number1)
const adjustNumber1 = (number) => {
  if (!number) {
    return '';
  }
  const splitted = number.split(MULTIPLE_SEPARATOR).map(x=>x.trim()).filter(x=>x.length > 0)
  return splitted.length <2 ? adjustSingleOrEmptyNumber(splitted) : number;
}
  
const adjustSingleOrEmptyNumber = (number) => {
  if(number.length){
    return adjustSingleNumber(number[0]);
  }
  return '';
}
  
const adjustSingleNumber = (number) => {
  if (!number) {
    return '';
  } if (number.endsWith(ASTERISK)) {
    return number.substring(0,number.length-1);
  } 
  return number + BACKQUOTE;
    
}

export const validateNumber2 = (number) =>  !number || !isAlphanumeric(number) ? '' : number.trim();

export const validateNumber = number => {
  if (!number) {
    return '';
  }
  const splitted =number.split(MULTIPLE_SEPARATOR).map(x=>x.trim()).filter(x=>x.length>0);
  if (number.length < 2) {
    return validateSingleOrEmptyNumber(splitted);
  }
  const filteredNumbers = getFilteredNumbers(splitted);
  if (filteredNumbers.length < 2) {
    return validateSingleOrEmptyNumber(filteredNumbers);
  }
  return filteredNumbers.join(MULTIPLE_SEPARATOR)
}
  
const getFilteredNumbers = (number) => {
  const set = [];
  for(let i = 0; i < number.length; i +=1){
    set.push(number[i].replace(/\s/g, ''));
    if(set.length === 24)
      break;
  }
  return set;
}
  
const validateSingleOrEmptyNumber = (number) => {
  if(number.length){
    return validateSingleNumber(number[0]);
  }
  return '';
}
      
const validateSingleNumber = (number) => {
  if (!number) {
    return '';
  } 
  if (number.endsWith(ASTERISK)) {
    const numberWithoutAsterisk = number.substring(0, number.length - 1);
    return isAlphanumeric(numberWithoutAsterisk) ? number : '';
  } 
  return isAlphanumeric(number) ? number : '';
    
}