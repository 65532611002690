import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import { Grid } from 'ui-core-ssgr';
import { gridConfig } from './constants';
const LiveAssocDetailsGridWrapper = ({ records }) => {

  const generateGridRecordsFromProps = gridRecords => map(gridRecords, record => (
    {
      ...record,
      completed: { bad: record.completed, nonCompliant: record.completed },
    }
  ));
  return (
    <Grid
      {...gridConfig}
      records={generateGridRecordsFromProps(records)}
      selectedItemIds={new Set([])}
    />
  );
};

LiveAssocDetailsGridWrapper.propTypes = {
  records: PropTypes.array,
  liveAssocRuleTypesList: PropTypes.object,
};

export default LiveAssocDetailsGridWrapper;