export const selectRuleTypesList = state => state.rulesForRuleTypesList.records;

export const selectCurrentRuleRecord = state => {
  const { selectedRuleRecordId } = state.rulesForRuleTypesList;
  return (
    selectedRuleRecordId &&
    state.rulesForRuleTypesList.records.find(
      element => element.id === selectedRuleRecordId,
    )
  ); // TODO: create action to reset the selected rule record with newly created rule id
};

export const selectRuleRecords = state =>
  state.ruleTypesList.setRuleTypesListRecords;
export const selectCurrentRuleRecordId = state =>
  state.ruleTypesList.selectedRuleTypeId;
export const selectNotificationState = state =>
  state.notificationState.notifications;
export const selectMultipleRuleCommitingState = state =>
  state.commitRuleTypesList.isMultipleCommiting;
export const selectMultipleCommitPutSuccess = state =>
  state.commitRuleTypesList.resultCommit;
export const selectMultipleCommitPutError = state =>
  state.commitRuleTypesList.errorCommit;
export const selectLoadingState = state => state.loadingState.loading;
// May need to update this at a later time, I wanted to update the selectCurrentRuleRecord but don't want to break the app
export const selectCurrentSelectedRecord = state => state.ruleTypesList.selectedRuleRecord;
