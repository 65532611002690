import { useAxios } from '../hooks'

const useSysClientLayouts = () => {
  const { data, ...rest } = useAxios({
    url: `/sysAdmin/clients`,
  })

  return { 
    data,
    ...rest
  }
}

export default useSysClientLayouts