import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tabs, DropdownButton, Button, Tooltip } from 'ui-core-ssgr';
import { PaddingWrapper, TooltipWrapper } from '../../../../shared/styles/spacing';
import {
  WordTagWidthSmall,
  GrayedOutWordTagSmall,
  ButtonStatus,
  StyledDetailHeader
} from './styles';
import { TabsWrap } from '../../../../shared/styles/styledComponents';
import {IS_ALLOWED_TO_REVERT} from '../../../GroupDetailView/constants';

class RulesTabHeader extends Component {
  componentDidMount() {
    this.updateNotifications();
  }

  componentDidUpdate(prevProps) {
    const {
      currentTab,
      deleteNotification,
      notifications,
      selectedRuleRecord,
    } = this.props;
    const { id } = selectedRuleRecord;

    if (prevProps.currentTab !== currentTab && notifications.has(id)) {
      deleteNotification(id);
    }
    if (
      !prevProps.notifications.has(id) &&
      (prevProps.currentTab !== currentTab ||
        prevProps.selectedRuleRecord.id !== id)
    ) {
      this.updateNotifications();
    }
  }

  updateNotifications = () => {
    const {
      addNotification,
      currentTab,
      isPostingRulesForRuleTypes,
      selectedRuleRecord,
      isExpanded,
    } = this.props;
    const { status } = selectedRuleRecord;
    if (status && status.length === 1) {
      let msg = '';
      if (
        status[0] === 'L' &&
        currentTab === 0 &&
        !isPostingRulesForRuleTypes &&
        isExpanded
      ) {
        msg =
          'This is a mirror of the live rule. Feel free to edit this test rule.';
      }
      if (
        status[0] === 'T' &&
        currentTab === 1 &&
        !isPostingRulesForRuleTypes
      ) {
        msg =
          'There is no live rule at this time. Committing test rule will create a live rule';
      }
      if (msg.length) {
        addNotification({
          id: selectedRuleRecord.id,
          icon: 'Info',
          type: 'neutral',
          msg,
        });
      }
    }
  };

  getWordTags = status => {
    if (status.includes('L') && status.includes('T')) {
      return (
        <>
          <WordTagWidthSmall type="neutral" label="test" />
          <WordTagWidthSmall type="positive" label="live" />
        </>
      );
    }
    if (status[0] === 'T') {
      return (
        <>
          <WordTagWidthSmall type="neutral" label="test" />
        </>
      );
    }
    if (status[0] === 'L') {
      return (
        <>
          <GrayedOutWordTagSmall label="test" />
          <WordTagWidthSmall type="positive" label="live" />
        </>
      );
    }
    return null;
  };

  dismissDetailPane = () => {
    const {
      deleteNotification,
      isPostingRulesForRuleTypes,
      selectedRuleRecord,
      setIsPostingRulesForRuleTypes,
      setIsCloningRuleState,
      setIsViewingRuleDetails,
    } = this.props;
    if (isPostingRulesForRuleTypes) {
      deleteNotification('postRule');
      setIsPostingRulesForRuleTypes(false);
    }

    setIsViewingRuleDetails({
      isAdvancedViewShown: false,
      selectedRuleRecordId: null,
    });
    deleteNotification(selectedRuleRecord.id);
    deleteNotification('postRule');
    setIsCloningRuleState(false);
  };

  selectTab = selectedTabIndex => {
    const {
      deleteNotification,
      isPostingRulesForRuleTypes,
      isCloningRule,
      updateCurrentTab,
    } = this.props;
    // If in 'Add a Rule' mode and tab switches reset header and notifications
    if (isPostingRulesForRuleTypes && selectedTabIndex !== 0) {
      deleteNotification('postRule');
    }
    if (isCloningRule && selectedTabIndex !== 0) {
      deleteNotification('cloneRule');
      deleteNotification('postRule');
    }
    updateCurrentTab({ currentTab: selectedTabIndex });
  };

  cloneRule = () => {
    const { setIsShowingDetailCloneRuleModal } = this.props;
    setIsShowingDetailCloneRuleModal(true);
  };

  onMenuClick = e => {
    if (e.target.innerHTML === 'Clone') {
      this.cloneRule();
    }
  };

  ruleDetailHeaderText = () => {
    const {
      selectedRuleRecord,
      isPostingRulesForRuleTypes,
      isCloningRule,
    } = this.props;
    let title = selectedRuleRecord.name;
    if (isPostingRulesForRuleTypes) {
      title = 'Adding a New Rule';
    } else if (isCloningRule) {
      title = `Cloning Rule ${selectedRuleRecord.name}`;
    }
    return title;
  };

  renderHeaderTitle = () => {
    const { 
      selectedRuleRecord,
      isPostingRulesForRuleTypes,
      isCloningRule
    } = this.props;
    if (!isPostingRulesForRuleTypes && !isCloningRule) {
      return (
        <Tooltip tooltipContent={`Rule ID: ${selectedRuleRecord.id}`} tooltipPosition="bottom-center" domID="tooltip-rule-id">
          {this.ruleDetailHeaderText()}
        </Tooltip>
      )
    } return this.ruleDetailHeaderText();
  }


  onClickTrialHandle = () => {
    window.open(
      '/TestFileManager',
      '_blank'
    );
  };

  onClickRevertHandle = () => {
    const {
      setIsShowingRevertRuleModal,
      getRevertRuleVersions,
      selectedRuleRecord,
      selectedRuleTypeId,
      userRoleId,
      addNotification
    } = this.props;
    if(IS_ALLOWED_TO_REVERT(userRoleId).status){
      const {id} = selectedRuleRecord;
      getRevertRuleVersions({selectedRuleTypeId, ruleId: id});
      setIsShowingRevertRuleModal(true);
    }else{
      addNotification({
        id: selectedRuleTypeId,
        type: 'negative',
        msg: IS_ALLOWED_TO_REVERT(userRoleId).error,
      });
    }
  }

  render() {
    const {onClickTrialHandle, onClickRevertHandle} = this;
    const {
      selectedRuleRecord,
      toggleDetailsView,
      currentTab,
      isPostingRulesForRuleTypes,
      setIsShowingDetailCloneRuleModal,
      setIsCloningRuleState,
      showDetailCloneRuleModal,
      isCloningRule,
      resetDetailsPane,
      userRoleId
    } = this.props;
    
    return (
      <>
        <PaddingWrapper className="rule-detail-pane-header">
          <StyledDetailHeader
            title={this.renderHeaderTitle()}
            inlineLabel={
              !isPostingRulesForRuleTypes && !isCloningRule
                ? this.getWordTags(selectedRuleRecord.status)
                : this.getWordTags(['T'])
            }
            layoutControls={[
              {
                buttonType: 'close',
                onButtonClick: () =>
                  setTimeout(() => this.dismissDetailPane(), 500) &&
                  toggleDetailsView(false),
                buttonTooltipPosition: 'top-center',
              },
            ]}
          />
          {!isCloningRule && (
            <ButtonStatus>
              {IS_ALLOWED_TO_REVERT(userRoleId).status && 
                <Button
                  name="Revert"
                  buttonType="deEmphasized"
                  size="small"
                  type="button"
                  onClick={() => onClickRevertHandle()}
                /> 
              }
              <TooltipWrapper>
                <Tooltip
                  domID="tooltip-trial"
                  tooltipContent="Run a test file to preview your changes in a new tab. Come back to this tab when you finish the test."
                  tooltipPosition="top-left"
                  tooltipWidth={310}>
                  <Button
                    name="Trial"
                    buttonType="deEmphasized"
                    size="small"
                    type="button"
                    onClick={() => onClickTrialHandle()}
                  />
                </Tooltip>
              </TooltipWrapper>
              <DropdownButton
                buttonID="Dropdown_Button"
                buttonClass="dropdown-button"
                buttonType="deEmphasized"
                size="small"
                name="more"
                onClick={() => false}
                disabled={false}
                listID="Dropdown_List"
                listClass="dropdown-list"
                menuItems={[
                  { label: 'Clone', id: 1 },
                  // Commenting Trial and Term out for now. Will implement these post-beta
                  // { label: 'Trial', id: 2 },
                  // { label: 'Term', id: 3 },
                ]}
                onMenuClick={this.onMenuClick}
                setIsShowingDetailCloneRuleModal={
                  setIsShowingDetailCloneRuleModal
                }
                setIsCloningRuleState={setIsCloningRuleState}
                showDetailCloneRuleModal={showDetailCloneRuleModal}
                isCloningRule={isCloningRule}
                toggleDetailsView={toggleDetailsView}
                resetDetailsPane={resetDetailsPane}
              />
            </ButtonStatus>)}
        </PaddingWrapper>
        <TabsWrap>
          <Tabs
            domID="tabsWrap"
            key={selectedRuleRecord.ruleId}
            tabs={[
              { label: 'edit test', domID: 'edit-test-tab' },
              { label: 'live rule', domID: 'live-rule-tab' },
              {
                label: 'associations',
                domID: 'associate-tab',
              },
            ]}
            initialTab={currentTab}
            onTabSelect={(e, { selectedTabIndex }) =>
              this.selectTab(selectedTabIndex)
            }
          />
        </TabsWrap>
      </>
    );
  }
}

RulesTabHeader.propTypes = {
  isExpanded: PropTypes.bool,
  addNotification: PropTypes.func.isRequired,
  deleteNotification: PropTypes.func.isRequired,
  isPostingRulesForRuleTypes: PropTypes.bool,
  notifications: PropTypes.object,
  setIsPostingRulesForRuleTypes: PropTypes.func.isRequired,
  selectedRuleRecord: PropTypes.object,
  setIsViewingRuleDetails: PropTypes.func,
  currentTab: PropTypes.number,
  updateCurrentTab: PropTypes.func,
  toggleDetailsView: PropTypes.func,
  setIsShowingDetailCloneRuleModal: PropTypes.func,
  setIsCloningRuleState: PropTypes.func,
  showDetailCloneRuleModal: PropTypes.bool,
  isCloningRule: PropTypes.bool,
  resetDetailsPane: PropTypes.func,
  userRoleId: PropTypes.string,
  setIsShowingRevertRuleModal: PropTypes.func,
  getRevertRuleVersions: PropTypes.func,
  selectedRuleTypeId: PropTypes.object
};

export default RulesTabHeader;
