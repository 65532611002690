import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { Checkbox, Tooltip } from 'ui-core-ssgr';
import { TruncatedColumn } from '../../../../../../shared/styles/styledComponents';
import { floatToDollarString } from '../../../../../../shared/helpers/money';
import { isA11yClick } from '../../../../../../shared/helpers/domEvents';
import { mapIterable } from '../../../../../../shared/helpers/iterables';

const onKeyPress = (e, callback) => {
  if (isA11yClick(e)) {
    e.preventDefault();
    callback(e);
  }
};

export const AdvancedClickableRow = ({
  onClickThrough = () => false,
  onRowSelect = () => false,
  supportSelection = false,
  isSelected = false,
  isFocused = false,
  selectedItemIds,
  selectionKey = '',
  disabledColumns = new Set([]),
  columns = new Set([]),
  record,
}) => {
  const classes = [];
  if (record.emboldened) classes.push('emboldened');
  if (isFocused) classes.push('focused');
  return (
    <tr
      onClick={e => {
        onClickThrough(e, record);
      }}
      onKeyPress={e => onKeyPress(e, onClickThrough)}
      role="button"
      tabIndex="0"
      className={classes.join(' ')}
    >
      {supportSelection ? (
        <td className="select-checkbox" key="select column">
          <Checkbox
            name={`${record[selectionKey].replace(
              / /g,
              '-',
            )}-row-select-checkbox`}
            className="table-row-checkbox"
            onChange={() => onRowSelect(record, isSelected)}
            value={selectedItemIds.has(record.id)}
            stopClickPropagation
          />
        </td>
      ) : null}
      {mapIterable(columns.values(), c => {
        if (disabledColumns.has(c.dataName)) return null;

        if (c.dataName === 'claimType') {
          return (
            <TruncatedColumn key={c.dataName} className={c.dataName}>
              {record.claimType ? (
                <Tooltip tooltipContent={record.claimType}>
                  {record.claimType}
                </Tooltip>
              ) : (
                <br />
              )}
            </TruncatedColumn>
          );
        }
        if (c.dataName === 'recipientType') {
          return (
            <TruncatedColumn key={c.dataName} className={c.dataName}>
              {record.recipientType ? (
                <Tooltip tooltipContent={record.recipientType}>
                  {record.recipientType}
                </Tooltip>
              ) : (
                <br />
              )}
            </TruncatedColumn>
          );
        }
        if (c.dataName === 'recipient') {
          return (
            <TruncatedColumn key={c.dataName} className={c.dataName}>
              {record.recipientType ? (
                <Tooltip tooltipContent={record.recipientType}>
                  {record.recipientType}
                </Tooltip>
              ) : (
                <br />
              )}
            </TruncatedColumn>
          );
        }
        if (c.dataName === 'documentType') {
          return (
            <TruncatedColumn key={c.dataName} className={c.dataName}>
              {record.documentType ? (
                <Tooltip tooltipContent={record.documentType}>
                  {record.documentType}
                </Tooltip>
              ) : (
                <br />
              )}
            </TruncatedColumn>
          );
        }

        if (c.dataName === 'returnAddress') {
          return (
            <TruncatedColumn key={c.dataName} className={c.dataName}>
              {record.returnAddress ? (
                <Tooltip tooltipContent={record.returnAddress}>
                  {record.returnAddress}
                </Tooltip>
              ) : (
                <br />
              )}
            </TruncatedColumn>
          );
        }
        if (c.dataName === 'deliveryAddress') {
          return (
            <TruncatedColumn key={c.dataName} className={c.dataName}>
              {record.deliveryAddress ? (
                <Tooltip tooltipContent={record.deliveryAddress}>
                  {record.deliveryAddress}
                </Tooltip>
              ) : (
                <br />
              )}
            </TruncatedColumn>
          );
        }
        if (c.dataName === 'copyNumber') {
          return (
            <TruncatedColumn key={c.dataName} className={c.dataName}>
              {record.copyNumber ? (
                <Tooltip tooltipContent={record.copyNumber}>
                  {record.copyNumber}
                </Tooltip>
              ) : (
                <br />
              )}
            </TruncatedColumn>
          );
        }
        if (c.dataName === 'deliveryMethod') {
          return (
            <TruncatedColumn key={c.dataName} className={c.dataName}>
              {record.deliveryMethod ? (
                <Tooltip tooltipContent={record.deliveryMethod}>
                  {record.deliveryMethod}
                </Tooltip>
              ) : (
                <Tooltip tooltipContent='Originally Specified Delivery Type'>
                  Originally Specified Delivery Type
                </Tooltip>
              )}
            </TruncatedColumn>
          );
        }
        if (c.dataName === 'checkForm') {
          return (
            <TruncatedColumn key={c.dataName} className={c.dataName}>
              {record.checkForm ? (
                <Tooltip tooltipContent={record.checkForm}>
                  {record.checkForm}
                </Tooltip>
              ) : (
                <br />
              )}
            </TruncatedColumn>
          );
        }
        if (c.dataName === 'eobForm') {
          return (
            <TruncatedColumn key={c.dataName} className={c.dataName}>
              {record.eobForm ? (
                <Tooltip tooltipContent={record.eobForm}>
                  {record.eobForm}
                </Tooltip>
              ) : (
                <br />
              )}
            </TruncatedColumn>
          );
        }
        if (c.dataName === 'customCoverSheetForm') {
          return (
            <TruncatedColumn key={c.dataName} className={c.dataName}>
              {record.customCoverSheetForm ? (
                <Tooltip tooltipContent={record.customCoverSheetForm}>
                  {record.customCoverSheetForm}
                </Tooltip>
              ) : (
                <br />
              )}
            </TruncatedColumn>
          );
        }
        if (c.dataName === 'customCoverSheetLevel') {
          return (
            <TruncatedColumn key={c.dataName} className={c.dataName}>
              {record.customCoverSheetLevel ? (
                <Tooltip tooltipContent={record.customCoverSheetLevel}>
                  {record.customCoverSheetLevel}
                </Tooltip>
              ) : (
                <br />
              )}
            </TruncatedColumn>
          );
        }
        if (c.dataName === 'taxIdNumber') {
          return (
            <TruncatedColumn key={c.dataName} className={c.dataName}>
              {record.taxIdNumber ? (
                <Tooltip tooltipContent={record.taxIdNumber}>
                  {record.taxIdNumber}
                </Tooltip>
              ) : (
                <br />
              )}
            </TruncatedColumn>
          );
        }
        if (c.dataName === 'SSN') {
          return (
            <TruncatedColumn key={c.dataName} className={c.dataName}>
              {record.SSN ? (
                <Tooltip tooltipContent={record.SSN}>{record.SSN}</Tooltip>
              ) : (
                <br />
              )}
            </TruncatedColumn>
          );
        }
        if (c.dataName === 'checkLeftText') {
          const newlineMessage = record.checkLeftText
            ? record.checkLeftText.includes('\n')
            : null;
          const newRecordMessage = newlineMessage
            ? record.checkLeftText.split('\n').map(checkLeftText => (
              <span>
                {checkLeftText}
                <br />
              </span>
            ))
            : get(record, 'checkLeftText', null);

          return (
            <TruncatedColumn key={c.dataName} className={c.dataName}>
              {record.checkLeftText ? (
                <Tooltip tooltipContent={record.checkLeftText}>
                  {newRecordMessage}
                </Tooltip>
              ) : (
                <br />
              )}
            </TruncatedColumn>
          );
        }
        if (c.dataName === 'fractionalCodeLeft') {
          return (
            <TruncatedColumn key={c.dataName} className={c.dataName}>
              {record.fractionalCodeLeft ? (
                <Tooltip tooltipContent={record.fractionalCodeLeft}>
                  {record.fractionalCodeLeft}
                </Tooltip>
              ) : (
                <br />
              )}
            </TruncatedColumn>
          );
        }
        if (c.dataName === 'fractionalCodeTop') {
          return (
            <TruncatedColumn key={c.dataName} className={c.dataName}>
              {record.fractionalCodeTop ? (
                <Tooltip tooltipContent={record.fractionalCodeTop}>
                  {record.fractionalCodeTop}
                </Tooltip>
              ) : (
                <br />
              )}
            </TruncatedColumn>
          );
        }
        if (c.dataName === 'fractionalCodeBottom') {
          return (
            <TruncatedColumn key={c.dataName} className={c.dataName}>
              {record.fractionalCodeBottom ? (
                <Tooltip tooltipContent={record.fractionalCodeBottom}>
                  {record.fractionalCodeBottom}
                </Tooltip>
              ) : (
                <br />
              )}
            </TruncatedColumn>
          );
        }
        if (c.dataName === 'signatureThresholdLabel') {
          return (
            <TruncatedColumn key={c.dataName} className={c.dataName}>
              {record.signatureThresholdLabel ? (
                <Tooltip tooltipContent={record.signatureThresholdLabel}>
                  {record.signatureThresholdLabel}
                </Tooltip>
              ) : (
                <br />
              )}
            </TruncatedColumn>
          );
        }
        if (c.dataName === 'signature1') {
          return (
            <TruncatedColumn key={c.dataName} className={c.dataName}>
              {record.signature1 ? (
                <Tooltip tooltipContent={record.signature1}>
                  {record.signature1}
                </Tooltip>
              ) : (
                <br />
              )}
            </TruncatedColumn>
          );
        }
        if (c.dataName === 'highDollarThresholdLabel') {
          return (
            <TruncatedColumn key={c.dataName} className={c.dataName}>
              {record.highDollarThresholdLabel ? (
                <Tooltip tooltipContent={record.highDollarThresholdLabel}>
                  {record.highDollarThresholdLabel}
                </Tooltip>
              ) : (
                <br />
              )}
            </TruncatedColumn>
          );
        }
        if (c.dataName === 'state') {
          return (
            <TruncatedColumn key={c.dataName} className={c.dataName}>
              {record.state ? (
                <Tooltip tooltipContent={record.state}>{record.state}</Tooltip>
              ) : (
                <br />
              )}
            </TruncatedColumn>
          );
        }
        if (c.dataName === 'message') {
          const newlineMessage = record.message
            ? record.message.includes('\n')
            : null;
          const newRecordMessage = newlineMessage
            ? record.message.split('\n').map(message => (
              <span>
                {message}
                <br />
              </span>
            ))
            : get(record, 'message', null);

          return (
            <TruncatedColumn key={c.dataName} className={c.dataName}>
              {record.message ? (
                <Tooltip tooltipContent={record.message}>
                  {newRecordMessage}
                </Tooltip>
              ) : (
                <br />
              )}
            </TruncatedColumn>
          );
        }
        if (c.dataName === 'image1') {
          return (
            <TruncatedColumn key={c.dataName} className={c.dataName}>
              {record.image1 ? (
                <Tooltip tooltipContent={record.image1}>
                  {record.image1}
                </Tooltip>
              ) : (
                <br />
              )}
            </TruncatedColumn>
          );
        }

        return (
          <td key={c.dataName} className={c.dataName}>
            {c.isCurrency
              ? floatToDollarString(record[c.dataName])
              : record[c.dataName]}
          </td>
        );
      })}
      <td />
    </tr>
  );
};

AdvancedClickableRow.propTypes = {
  columns: PropTypes.shape({
    dataName: PropTypes.string,
    values: PropTypes.func,
  }).isRequired,
  disabledColumns: PropTypes.shape({
    dataName: PropTypes.string,
    has: PropTypes.func,
  }).isRequired,
  /* eslint-disable react/forbid-prop-types */
  record: PropTypes.any.isRequired,
  /* eslint-disable react/forbid-prop-types */
  onClickThrough: PropTypes.func,
  onRowSelect: PropTypes.func,
  supportSelection: PropTypes.bool,
  isSelected: PropTypes.bool,
  emboldened: PropTypes.bool,
  selectionKey: PropTypes.string,
  isFocused: PropTypes.bool,
};

export default AdvancedClickableRow;
