import React from 'react';
import PropTypes from 'prop-types';
import { SideNav, PageWrapper } from '../../containers';

const Page = ({
  ListPaneContent = null,
  DetailPaneContent = null
}) => (
  <PageWrapper 
    navigationPaneContent={<SideNav />}
    listPaneContent={ListPaneContent && <ListPaneContent />}
    detailPaneContent={DetailPaneContent && <DetailPaneContent />}
  />
);

Page.propTypes = {
  ListPaneContent: PropTypes.any,
  DetailPaneContent: PropTypes.any,
}

Page.defaultProps = {
  ListPaneContent: null,
  DetailPaneContent: null,
}

export default Page