/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components/dist/styled-components'
import { useLayout } from 'ui-core/dist/useLayout';
import DetailPaneHeader from 'ui-core/dist/DetailPaneHeader';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  Modal, Button, Checkbox, ModalHeader, Input, DatePickerV2, ModalBody
} from 'ui-core/dist';
import { detailPaneAnimStates } from '../../../commonResources/constants/animStates';
import ErrorPage from '../../ErrorPage';
import LoadingComponent from '../../../components/LoadingComponent';
import ModalWrapper from '../../../components/Modal/components/ModalWrapper';
import useNotificationBannerHook from '../../../useNotificationBannerHook';
import {
  useInsertPAF,
  useDeletePAF,
  useUpdatePAF
} from '../../../api/useClientNCOAPAFEditor'


const StyledModalWrapperWidth=styled(ModalWrapper)`
& .active-content {
  width: 30% !important;
}
`

const PAFEditorDetail = () => {
  const {
    expandCollapseDetailPaneButtonConfig,
    closeDetailPaneButtonConfig,
    detailAnimationState,
    closeDetailPane
  } = useLayout()

  const { notify } = useNotificationBannerHook();
  const [cPAFID, setcPAFID] = useState('')
  const [cListName, setcListName] = useState('')
  const [cListOwnerNAICS, setcListOwnerNAICS] = useState('')
  const [dABFSignDate, setdABFSignDate] = useState('')
  const [cListOwnerName, setcListOwnerName] = useState('')
  const [isActivePAF, setIsActivePAF] = useState(false)
  const [cListOwnerAddress, setcListOwnerAddress] = useState('')
  const [cListOwnerCity, setcListOwnerCity] = useState('')
  const [cListOwnerState, setcListOwnerState] = useState('')
  const [cListOwnerPhone, setcListOwnerPhone] = useState('')
  const [cListOwnerZip, setcListOwnerZip] = useState('')
  const [cListOwnerZip4, setcListOwnerZip4] = useState('')
  const [cListOwnerAltName, setcListOwnerAltName] = useState('')
  const [cListOwnerParentCompany, setcListOwnerParentCompany] = useState('')
  const [cSignerTitle, setcSignerTitle] = useState('')
  const [cSignerName, setcSignerName] = useState('')
  const [dSignDate, setdSignDate] = useState('')
  const [dPAFExpiration, setdPAFExpiration] = useState('');
  const [editorMode, setEditorMode ] = useState('Add');
  const [bCanEdit, setbCanEdit ] = useState(true);
  const [isOpenConfirmationModal, setisOpenConfirmationModal] = useState(false)
  const [confirmationModalMode, setconfirmationModalMode] = useState('')

  const [count, setcount] = useState(0)

  const { PAFEditorRecord: records, PAFEditorMode: editorModep, refetchPAFEditorRecord }
    = useSelector(({ clientAdministration }) => clientAdministration);

  useEffect(() => {
    if (records) {
      if (records.cPAFID) {
        onResetCurrentRecordValues()
      } else {
        onClearCurrentRecordValues()
      }
    }
  }, [records]);

  const { data: infoInsertPAF, postData: insertPAF, metaData: insertPAFResponse, isLoading: isLoadingInsertPAF,
    isError: isErrorInsertPAF, error: errorInsertPAF } = useInsertPAF()

  const { data: infoUpdatePAF, postData: updatePAF, metaData: updatePAFResponse, isLoading: isLoadingupdatePAF,
    isError: isErrorupdatePAF, error: errorupdatePAF } = useUpdatePAF()

  const { data: infoDeletePAF, postData: deletePAF, metaData: deletePAFResponse, isLoading: isLoadingdeletePAF,
    isError: isErrordeletePAF, error: errordeletePAF } = useDeletePAF()

  const onResetCurrentRecordValues = () => {
    if (records && records.cPAFID) {
      const { cPAFID: cPAFIDp, cListName: cListNamep, cListOwnerNAICS: cListOwnerNAICSp,
        dABFSignDate: dABFSignDatep, cListOwnerName: cListOwnerNamep, bActive,
        cListOwnerAddress: cListOwnerAddressp, cListOwnerState: cListOwnerStatep, cListOwnerZip4: cListOwnerZip4p,
        cListOwnerPhone: cListOwnerPhonep, cListOwnerZip: cListOwnerZipp, cListOwnerCity: cListOwnerCityp,
        cListOwnerAltName: cListOwnerAltNamep, cListOwnerParentCompany: cListOwnerParentCompanyp,
        cSignerTitle: cSignerTitlep, cSignerName: cSignerNamep, dSignDate: dSignDatep, bCanEdit: bCanEditp, dPAF_Expiration: dPAFExpirationp } = records
      setcPAFID(cPAFIDp)
      setcListName(cListNamep)
      setdABFSignDate(dABFSignDatep ? moment(dABFSignDatep) : '')
      setcListOwnerNAICS(cListOwnerNAICSp)
      setIsActivePAF(bActive === "1")
      setcListOwnerName(cListOwnerNamep)
      setcListOwnerAddress(cListOwnerAddressp)
      setcListOwnerCity(cListOwnerCityp)
      setcListOwnerState(cListOwnerStatep)
      setcListOwnerPhone(`(${cListOwnerPhonep?.substr(0,3)}) ${cListOwnerPhonep?.substr(3,3)}-${cListOwnerPhonep?.substr(6,4)}`)
      setcListOwnerZip(cListOwnerZipp)
      setcListOwnerZip4(cListOwnerZip4p)
      setcListOwnerAltName(cListOwnerAltNamep)
      setcListOwnerParentCompany(cListOwnerParentCompanyp)
      setcSignerTitle(cSignerTitlep)
      setcSignerName(cSignerNamep)
      setdSignDate(dSignDatep ? moment(dSignDatep) : '')
      setEditorMode(editorModep)
      setbCanEdit(bCanEditp === "1")
      setdPAFExpiration(dPAFExpirationp)
    }
  }

  useEffect(() => {
    if (insertPAFResponse) {
      const { status, statusText } = insertPAFResponse;
      if (status === 200 || statusText === "OK") {
        if (infoInsertPAF && infoInsertPAF.XMLResponse) {
          const { XMLResponse } = infoInsertPAF;
          if (XMLResponse) {
            notify(`PAF ${XMLResponse} has been successfully added.`, 'positive')
            closeDetailPane()
            if (refetchPAFEditorRecord) refetchPAFEditorRecord()
            return
          }
          return;
        }
        closeDetailPane()
        if (refetchPAFEditorRecord) refetchPAFEditorRecord()
      }
    }
  }, [insertPAFResponse])

  useEffect(() => {
    if (updatePAFResponse) {
      const { status, statusText } = updatePAFResponse;
      if (status === 200 || statusText === "OK") {
        if (infoUpdatePAF && infoUpdatePAF.XMLResponse) {
          const { XMLResponse } = infoUpdatePAF;
          if (XMLResponse) {
            notify(`PAF ${XMLResponse} has been successfully updated.`, 'positive')
            closeDetailPane()
            if (refetchPAFEditorRecord) refetchPAFEditorRecord()
            return
          }
          return;
        }
        closeDetailPane()
        if (refetchPAFEditorRecord) refetchPAFEditorRecord()
      }
    }
  }, [updatePAFResponse])


  useEffect(() => {
    if (deletePAFResponse) {
      const { status, statusText } = deletePAFResponse;
      if (status === 200 || statusText === "OK") {
        if (infoDeletePAF && infoDeletePAF.XMLResponse) {
          const { XMLResponse } = infoDeletePAF;
          if (XMLResponse) {
            notify(`PAF ${XMLResponse} has been successfully deleted.`, 'positive')
            closeDetailPane()
            if (refetchPAFEditorRecord) refetchPAFEditorRecord()
            return
          }
          return;
        }
        closeDetailPane()
        if (refetchPAFEditorRecord) refetchPAFEditorRecord()
      }
    }
  }, [deletePAFResponse])


  const onDeleteClick = () => {
    if (editorMode === "Edit") {
      setconfirmationModalMode("Delete")
      setisOpenConfirmationModal(true)
    }
  }
  const onSubmitClick = () => {
    if (editorMode === "Add") {
      setconfirmationModalMode("Add")
    } else if (editorMode === "Edit") {
      setconfirmationModalMode("Modify")
    }
    setisOpenConfirmationModal(true)
  }

  const onConfirmationYesClick = () => {
    const dSignDateTemp=dSignDate?moment(dSignDate.format('MM/DD/YYYY')):'';
    if (confirmationModalMode === "Delete") {
      deletePAF({ cPAFID: records?.cPAFID, count })
      setcount(count + 1)

    } else if (confirmationModalMode === "Add") {
      let newNAICSVal = cListOwnerNAICS;
      if (newNAICSVal.length !== 6) {
        const zerosToPrepend = 6 - Number(newNAICSVal.length);
        newNAICSVal = "0".repeat(zerosToPrepend) + newNAICSVal;
      }
      insertPAF({
        ...records,
        cPAFID,cListName, cListOwnerNAICS : newNAICSVal,
        dABFSignDate: dABFSignDate ? dABFSignDate.format("MM/DD/YYYY") : '',
        cListOwnerName, cListOwnerAddress, cListOwnerState, cListOwnerZip4,
        cListOwnerPhone, cListOwnerZip, cListOwnerCity,
        cListOwnerAltName, cListOwnerParentCompany,
        cSignerTitle, cSignerName,
        dSignDate: dSignDate ? dSignDate.format("MM/DD/YYYY") : '',
        ASSOCIATED_CLIENTS: [],
        bActive: isActivePAF,
        dPAF_Expiration: dSignDate ? dSignDateTemp.add(1,'year').format("MM/DD/YYYY") : moment().add(1,'year').format("MM/DD/YYYY"),
        bCanEdit: true,
        bAlternatePAF: false,
        cPAF_Type: 'INITIAL',
        count })
      setcount(count + 1)
    } else if (confirmationModalMode === "Modify") {
      let PAFType = records?.cPAF_Type;
      if(dSignDateTemp.add(1,'year').diff(dPAFExpiration,'minutes') !== 0 && !bCanEdit)
        PAFType = 'RENEWAL'
      else if(!bCanEdit)
        PAFType = 'MODIFIED';
      updatePAF({
        ...records,
        cPAFID,cListName, cListOwnerNAICS,
        dABFSignDate: dABFSignDate ? dABFSignDate.format("MM/DD/YYYY") : '',
        cListOwnerName, cListOwnerAddress, cListOwnerState, cListOwnerZip4,
        cListOwnerPhone, cListOwnerZip, cListOwnerCity,
        cListOwnerAltName, cListOwnerParentCompany,
        cSignerTitle, cSignerName,
        dSignDate: dSignDate ? dSignDate.format("MM/DD/YYYY") : '',
        dPAF_Expiration: dSignDate ? dSignDateTemp.add(1,'year').format("MM/DD/YYYY") : moment().add(1,'year').format("MM/DD/YYYY"),
        cPAF_Type: PAFType,
        bActive: isActivePAF,
        bCanEdit,
        count })
      setcount(count + 1)
    }
    setconfirmationModalMode('')
    setisOpenConfirmationModal(false)
  }

  const onClearCurrentRecordValues = () => {
    setcPAFID('')
    setcListName('')
    setdABFSignDate('')
    setcListOwnerNAICS('')
    setIsActivePAF(false);
    setcListOwnerName('')
    setcListOwnerAddress('')
    setcListOwnerCity('');
    setcListOwnerState('')
    setcListOwnerPhone('')
    setcListOwnerZip('')
    setcListOwnerZip4('')
    setcListOwnerAltName('')
    setcListOwnerParentCompany('')
    setcSignerTitle('')
    setcSignerName('')
    setdSignDate('')
    setbCanEdit(true)
    setEditorMode('Add')
  }

  const onCopyCurrentRecordValues = () => {
    setcPAFID('')
    setbCanEdit(true)
  }
  const inFuture = (date) => date > new Date();
  const validateDate=(date)=>{
    if (!date) {
      return false;
    }
    if (inFuture(moment(date))) {
      return false;
    }
    return true;
  }
  const rendercPAFID = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_rendercPAFID"
      placeholder=""
      size="small"
      disabled
      maxLength={30}
      initialValue={cPAFID}
      onChange={(e) => {
        setcPAFID(e.target.value || "")
      }}
    />
  ), [cPAFID])

  const rendercListName = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_rendercListName"
      placeholder=""
      size="small"
      maxLength={25}
      initialValue={cListName}
      errorMessage="Incorrect data do not use '&', '|' or '>' characters"
      hasError={/[\\&>\\|]/.test(cListName)}
      disabled={!bCanEdit}
      onChange={(e) => {
        setcListName(e.target.value || "")
      }}
    />
  ), [cListName, bCanEdit])

  const renderdABFSignDate = useMemo(() => (
    <div style={{width:"200px"}}>
      <DatePickerV2
        domID="renderdABFSignDatePicker"
        initialSelection={dABFSignDate ? moment(dABFSignDate) : ''}
        errorMessage="Future Date is not Allowed"
        hasError={dABFSignDate ? !validateDate(dABFSignDate) : false}
        onDateChange={(e)=>{
          if (e) setdABFSignDate(e)
          else setdABFSignDate('')
        }}
        size="small"
      />
    </div>
  ), [dABFSignDate])

  const rendercListOwnerNAICS = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_cListOwnerNAICS"
      placeholder=""
      size="small"
      maxLength={6}
      disabled={editorMode === "Edit"}
      initialValue={cListOwnerNAICS}
      errorMessage="Only numeric values are allowed and must be between 2 and 6 digits"
      hasError={cListOwnerNAICS ? !/^\d{2,6}$/.test(cListOwnerNAICS) : false}
      onChange={(e) => {
        setcListOwnerNAICS(e.target.value || "")
      }}
    />
  ), [cListOwnerNAICS, editorMode])

  const rendercListOwnerName = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_cListOwnerName"
      placeholder=""
      size="small"
      maxLength={50}
      initialValue={cListOwnerName}
      errorMessage="Incorrect data do not use '&', '|' or '>' characters"
      hasError={/[\\&>\\|]/.test(cListOwnerName)}
      onChange={(e) => {
        setcListOwnerName(e.target.value || "")
      }}
    />
  ), [cListOwnerName])

  const renderActivePAF = useMemo(() => (
    <Checkbox
      domID='chk_activePaf'
      labelTooltipWidth={100}
      name="DropdownListItem"
      checked={isActivePAF}
      onChange={(e,state)=>{
        setIsActivePAF(state)
      }}
    />
  ), [isActivePAF])

  const rendercListOwnerAddress = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_cListOwnerName"
      placeholder=""
      size="small"
      maxLength={30}
      initialValue={cListOwnerAddress}
      errorMessage="Incorrect data do not use '&', '|' or '>' characters"
      hasError={/[\\&>\\|]/.test(cListOwnerAddress)}
      onChange={(e) => {
        setcListOwnerAddress(e.target.value || "")
      }}
    />
  ), [cListOwnerAddress])

  const rendercListOwnerCity = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_cListOwnerCity"
      placeholder=""
      size="small"
      maxLength={50}
      initialValue={cListOwnerCity}
      errorMessage="Incorrect data do not use '&', '|' or '>' characters"
      hasError={/[\\&>\\|]/.test(cListOwnerCity)}
      onChange={(e) => {
        setcListOwnerCity(e.target.value || "")
      }}
    />
  ), [cListOwnerCity])

  const rendercListOwnerState = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_cListOwnerState"
      placeholder=""
      size="small"
      maxLength={2}
      initialValue={cListOwnerState}
      errorMessage="Incorrect data do not use '&', '|' or '>' characters"
      hasError={/[\\&>\\|]/.test(cListOwnerState)}
      onChange={(e) => {
        setcListOwnerState(e.target.value || "")
      }}
    />
  ), [cListOwnerState])

  const rendercListOwnerPhone = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_cListOwnerPhone"
      placeholder=""
      size="small"
      maxLength={14}
      initialValue={cListOwnerPhone}
      errorMessage="Must be of proper format '(111) 111-1111'"
      hasError={cListOwnerPhone ? !/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(cListOwnerPhone) : false}
      onChange={(e) => {
        let x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        x = !x[2] ? x[1] : `(${  x[1]  }) ${  x[2]  }${x[3] ? `-${  x[3]}` : ''}`
        setcListOwnerPhone(x || "")
      }}
    />
  ), [cListOwnerPhone])

  const rendercListOwnerAltName = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_cListOwnerAltName"
      placeholder=""
      size="small"
      maxLength={50}
      initialValue={cListOwnerAltName}
      errorMessage="Incorrect data do not use '&', '|' or '>' characters"
      hasError={/[\\&>\\|]/.test(cListOwnerAltName)}
      onChange={(e) => {
        setcListOwnerAltName(e.target.value || "")
      }}
    />
  ), [cListOwnerAltName])

  const rendercListOwnerParentCompany = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_cListOwnerPhone"
      placeholder=""
      size="small"
      maxLength={50}
      initialValue={cListOwnerParentCompany}
      errorMessage="Incorrect data do not use '&', '|' or '>' characters"
      hasError={/[\\&>\\|]/.test(cListOwnerParentCompany)}
      onChange={(e) => {
        setcListOwnerParentCompany(e.target.value || "")
      }}
    />
  ), [cListOwnerParentCompany])

  const rendercSignerTitle = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_cSignerTitle"
      placeholder=""
      size="small"
      maxLength={20}
      initialValue={cSignerTitle}
      errorMessage="Incorrect data do not use '&', '|' or '>' characters"
      hasError={/[\\&>\\|]/.test(cSignerTitle)}
      onChange={(e) => {
        setcSignerTitle(e.target.value || "")
      }}
    />
  ), [cSignerTitle])

  const rendercSignerName = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_cSignerName"
      placeholder=""
      size="small"
      maxLength={50}
      disabled={!bCanEdit}
      initialValue={cSignerName}
      errorMessage="Incorrect data do not use '&', '|' or '>' characters"
      hasError={/[\\&>\\|]/.test(cSignerName)}
      onChange={(e) => {
        setcSignerName(e.target.value || "")
      }}
    />
  ), [cSignerName, bCanEdit])

  const renderdSignDate = useMemo(() => (
    <div style={{width:"200px"}}>
      <DatePickerV2
        domID="renderdABFSignDatePicker"
        initialSelection={dSignDate ? moment(dSignDate) : ''}
        errorMessage="Future Date is not Allowed"
        hasError={dSignDate ? !validateDate(dSignDate) : false}
        openDirection="up"
        onDateChange={(e)=>{
          if (e) setdSignDate(e)
          else setdSignDate('')
        }}
        size="small"
      />
    </div>
  ), [dSignDate])

  const rendercOwnerZip = useMemo(() => (
    <div className="displayFlex">
      <Input
        style={{ width: '70px' }}
        className="test-classname"
        domID="txt_cListOwnerZip"
        placeholder=""
        size="small"
        maxLength={5}
        initialValue={cListOwnerZip}
        onChange={(e) => {
          setcListOwnerZip(e.target.value || "")
        }}
      />
      <span style={{marginLeft: "5px", marginRight: "5px"}}>-</span>
      <Input
        style={{ width: '70px' }}
        className="test-classname"
        domID="txt_cListOwnerZip4"
        placeholder=""
        size="small"
        maxLength={4}
        initialValue={cListOwnerZip4}
        onChange={(e) => {
          setcListOwnerZip4(e.target.value || "")
        }}
      />
    </div>
  ), [cListOwnerZip, cListOwnerZip4])

  const ownerZipErrorMsg = useMemo(() => {
    if(cListOwnerZip){
      if(!/^\d{5}$/.test(cListOwnerZip)){
        return <span style={{color: 'red'}}>Only numeric values are allowed and must be 5 digit<br/></span>
      }
    }
    return ''
  },  [cListOwnerZip])

  const ownerZip4ErrorMsg = useMemo(() => {
    if(cListOwnerZip4){
      if(!/^\d{4}$/.test(cListOwnerZip4)){
        return <span style={{color: 'red'}}>Only numeric values are allowed and must be 4 digit</span>
      }
    }
    return ''
  },  [cListOwnerZip4])
  
  const validateForm = useMemo(() => {
    if ((cListName ? /[\\&>\\|]/.test(cListName) : true)
    || !(/^\d{2,6}$/.test(cListOwnerNAICS))
    || !validateDate(dABFSignDate) || (cListOwnerName ? /[\\&>\\|]/.test(cListOwnerName) : true)
    || (cListOwnerAddress ? /[\\&>\\|]/.test(cListOwnerAddress) : true)
    || (cListOwnerState ? /[\\&>\\|]/.test(cListOwnerState) : true) || !/^\d{4}$/.test(cListOwnerZip4)
    || !/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(cListOwnerPhone) || !/^\d{5}$/.test(cListOwnerZip)
    || (cListOwnerCity ? /[\\&>\\|]/.test(cListOwnerCity) : true)
    || (cListOwnerAltName ? /[\\&>\\|]/.test(cListOwnerAltName) : true)
    || (cListOwnerParentCompany ? /[\\&>\\|]/.test(cListOwnerParentCompany) : true)
    || (cSignerTitle ? /[\\&>\\|]/.test(cSignerTitle) : true) || (cSignerName ? /[\\&>\\|]/.test(cSignerName) : true)
    || !validateDate(dSignDate)){
      return false;
    }
    return true
  }, [cPAFID, cListName, cListOwnerNAICS,
    dABFSignDate, cListOwnerName, cListOwnerAddress, cListOwnerState, cListOwnerZip4,
    cListOwnerPhone, cListOwnerZip, cListOwnerCity,
    cListOwnerAltName, cListOwnerParentCompany,
    cSignerTitle, cSignerName, dSignDate])

  const disabledDeleteButton = useMemo(() => {
    if (editorMode === "Edit") {
      if (!records?.cPAFID || !bCanEdit) {
        return true;
      }
      return false;
    }
    return true;
  }, [editorMode, records, bCanEdit])
  const renderPAFEditorForm = useMemo(() => {
    if (detailAnimationState > detailPaneAnimStates.COLLAPSED) {
      return (
        <div style={{ marginLeft: '40px' }} className="marginLeft">
          <div>
            <p>
              <div className="displayFlex">Complete the information below, and then click Submit to continue.</div>
            </p>
            <p>
              <div className="displayFlex"><span style={{ color: 'red' }}>* indicates required information</span></div>
            </p>
          </div>
          <br />
          <div className="displayFlex">
            <table className="common-table">
              <tr>
                <td>
                  <span> PAF ID : </span>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {rendercPAFID}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <span> List Name: <span style={{ color: 'red' }}>*</span></span>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {rendercListName}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <span> ABF Sign Date: <span style={{ color: 'red' }}>*</span></span>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderdABFSignDate}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <span> Owner NAICS: <span style={{ color: 'red' }}>*</span></span>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {rendercListOwnerNAICS}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <span> Owner Name: <span style={{ color: 'red' }}>*</span></span>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {rendercListOwnerName}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <span> Active PAF: </span>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderActivePAF}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <span> Owner Address: <span style={{ color: 'red' }}>*</span></span>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {rendercListOwnerAddress}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <span> Owner City: <span style={{ color: 'red' }}>*</span></span>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {rendercListOwnerCity}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <span> Owner State: <span style={{ color: 'red' }}>*</span></span>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {rendercListOwnerState}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <span> Owner Phone #: <span style={{ color: 'red' }}>*</span></span>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {rendercListOwnerPhone}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <span> Owner Zip+4: <span style={{ color: 'red' }}>*</span></span>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {rendercOwnerZip}
                    {ownerZipErrorMsg}
                    {ownerZip4ErrorMsg}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <span> Owner Alt. Name: <span style={{ color: 'red' }}>*</span></span>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {rendercListOwnerAltName}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <span> Owner Parent Company: <span style={{ color: 'red' }}>*</span></span>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {rendercListOwnerParentCompany}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <span> Signer Title: <span style={{ color: 'red' }}>*</span></span>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {rendercSignerTitle}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <span> Signer Name: <span style={{ color: 'red' }}>*</span></span>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {rendercSignerName}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <span> Sign Date: <span style={{ color: 'red' }}>*</span></span>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderdSignDate}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td colSpan="2">
                  <div className="displayFlex">
                    <div className="marginLeft">
                      <Button
                        domID="btn_clear"
                        buttonType="standard"
                        size="small"
                        name="CLEAR"
                        onClick={() => {
                          onClearCurrentRecordValues()
                          setEditorMode('Add')
                        }}
                      />
                    </div>
                    <div className="marginLeft">
                      <Button
                        domID="btn_copy"
                        buttonType="standard"
                        size="small"
                        name="COPY"
                        disabled={editorMode === "Add"}
                        onClick={() => {
                          onCopyCurrentRecordValues()
                          setEditorMode('Add')
                        }}
                      />
                    </div>
                    <div className="marginLeft">
                      <Button
                        domID="btn_delete"
                        buttonType="standard"
                        size="small"
                        name="DELETE"
                        disabled={disabledDeleteButton}
                        onClick={() => onDeleteClick()}
                      />
                    </div>
                    <div className="marginLeft">
                      <Button
                        domID="btn_search"
                        buttonType="standard"
                        size="small"
                        name="SUBMIT"
                        disabled={!validateForm}
                        onClick={() => onSubmitClick()}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
            </table>
          </div>

          <Modal isOpen={isOpenConfirmationModal} wrapperComponent={StyledModalWrapperWidth}>
            <ModalHeader
              title={`Do you want to ${confirmationModalMode} ?`}
              onClose={() => setisOpenConfirmationModal(false)}
            />
            <ModalBody>
              {isOpenConfirmationModal ? (
                <div style={{ marginLeft: '34px', marginBottom: '20px' }} className="marginLeft">
                  <div className="displayFlex">
                    <div className="marginLeft">
                      <Button
                        domID="btn_search"
                        buttonType="standard"
                        size="medium"
                        name="Yes"
                        onClick={() => onConfirmationYesClick()}
                      />
                    </div>
                    <div className="marginLeft">
                      <Button
                        domID="btn_search"
                        buttonType="standard"
                        size="medium"
                        name="No"
                        onClick={() => setisOpenConfirmationModal(false)}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
            </ModalBody>
          </Modal>
        </div>
      )
    }
    return null;
  }, [detailAnimationState,validateForm, editorMode, records,
    isOpenConfirmationModal, confirmationModalMode,cPAFID, cListName, cListOwnerNAICS,
    dABFSignDate, cListOwnerName, cListOwnerAddress, cListOwnerState, cListOwnerZip4,
    cListOwnerPhone, cListOwnerZip, cListOwnerCity,
    cListOwnerAltName, cListOwnerParentCompany,
    cSignerTitle, cSignerName, dSignDate])

  return (
    <>
      <DetailPaneHeader
        title={editorMode === "Edit" ? "Modify PAF" : "Add PAF"}
        layoutControls={[
          expandCollapseDetailPaneButtonConfig,
          closeDetailPaneButtonConfig
        ]}
      />
      <>
        {isLoadingInsertPAF
          || isLoadingdeletePAF || isLoadingupdatePAF ? (
            // replace with a better loading component
            <div style={{ marginLeft: '50%' }}><LoadingComponent /></div>
          ) : isErrorInsertPAF
            || isErrordeletePAF || isErrorupdatePAF ? (
              // replace with a better error component
              <ErrorPage
                // this prop isn't real but is an example
                error={errorInsertPAF
                  || errordeletePAF || errorupdatePAF}
              />
            ) : (
              <>
                {renderPAFEditorForm}
              </>
            )
        }
      </>
    </>
  )
};

export default PAFEditorDetail
