import React, { useState, useEffect } from 'react'
import { useLocation } from "react-router-dom";
import {
    useLayout,
    ListPaneHeader,
    Button,
} from 'ui-core/dist'
import ErrorPage from '../../ErrorPage';
import ListPaneHeaderWrapper from '../../../components/ErrorDisplaySection/ListPaneHeaderWrapper';
import useClientSettings from '../../../api/useClientSettings'
import ClientSettingsConfirmModal from './ClientSettingsConfirm';
import axios from '../../../axios'
import { Loading } from '../../../components'
import './index.scss'

const ClientSettings = () => {
    const [modalContent, setModalContent] = useState({
        isAccessOpen: false,
        confirmData: '',
        showInstructions: false,
    })

    const search = useLocation().search;
    const grouping = new URLSearchParams(search).get('grouping');

    const { expandCollapseListPaneButtonConfig } = useLayout()
    const { data, isLoading, isError, error, refetch } = useClientSettings(grouping)

    useEffect(() => {
        const page = document.getElementsByClassName('clientSettings-home-data');
        const grid = page && page[0];
        if (grid) {
            window.showModalDialog = async (url) => {
                const rule = url && url.split('cruleid=');
                const ruleId = rule && rule[1];
                try {
                    const res = await axios.get(`/clientAdministration/clientSettings/${ruleId}`);
                    setModalContent({
                        ...modalContent,
                        isAccessOpen: true,
                        confirmData: res?.data?.resp,
                        showInstructions: true,
                    })
                } catch (err) {
                    // Catch error
                }
            }
            // const anchors = grid.getElementsByTagName('a');
            // anchors && anchors.length > 1 && Array.from(anchors).forEach((ele) => {
            //     const eleFunc = ele.onclick && ele.onclick.toString();
            //     const splitArr1 = eleFunc && eleFunc.split('cruleid=');
            //     const splitEle1 = splitArr1 && splitArr1[1];
            //     const splitArr2 = splitEle1 && splitEle1.split("','");
            //     const ruleId = splitArr2 && splitArr2[0];
            //     ele.onclick = (e) => { showInstructions(ruleId); e.preventDefault(); e.stopPropagation() }
            // })
        }
    }, [data, isLoading]);

    const showInstructions = async (ruleId) => {
        try {
            const res = await axios.get(`/clientAdministration/clientSettings/${ruleId}`);
            setModalContent({
                ...modalContent,
                isAccessOpen: true,
                confirmData: res?.data?.resp,
                showInstructions: true,
            })
        } catch (err) {
            // Catch error
        }
    }

    const getConfirmationRecords = async (payload) => {
        try {
            const res = await axios.post(`/clientAdministration/clientSettings`, { payload });
            return res?.data?.resp;
        } catch (err) {
            // Catch error
        }
    }

    const onClientSettingsSubmit = async () => {
        const form = document.forms['ClientSettingsEditorForm'];
        const formData = new FormData(form);
        const formObj = Array.from(formData.keys()).reduce((result, key) => {
            result[key] = formData.get(key);
            return result;
        }, {});
        let formDataString = '';
        for (const prop in formObj) {
            if (formObj.hasOwnProperty(prop)) {
                formDataString += prop + '*$!$*' + formObj[prop] + '~!*!~';
            }
        }
        formDataString = formDataString.length > 1 ? formDataString.substring(0, formDataString.length - 5) : '';
        const confirmRecords = await getConfirmationRecords(formDataString);
        setModalContent({
            ...modalContent,
            isAccessOpen: true,
            confirmData: confirmRecords
        })
    }

    const getTitle = (grouping) => {
        if (grouping === '0') return 'Data Handling';
        if (grouping === '1') return 'Client Information Editor';
        if (grouping === '4') return 'Client Settings';
        if (grouping === '3') return 'Print Handling';
        if (grouping === '5') return 'Group Rules';
        return 'Client Settings Editor'
    }


    const onAccessClose = () => {
        setModalContent({
            isAccessOpen: false,
            confirmData: '',
            showInstructions: false,
        })
    }

    return (
        <>
            <ListPaneHeaderWrapper
                title={getTitle(grouping)}
                layoutControls={[expandCollapseListPaneButtonConfig]}
            />
            <div className="clientSettings-home">
                {isLoading ? <Loading /> :  isError ? (
        // replace with a better error component
        <ErrorPage
          // this prop isn't real but is an example
          error={error}
        />
      ) : (
                    <>
                        <form name="ClientSettingsEditorForm">
                            <table>
                                <tbody className="clientSettings-home-data" dangerouslySetInnerHTML={{ __html: data?.resp?.split('</h2>')[1] || data?.resp }} />
                            </table>
                        </form>
                        <div>
                            {data?.resp ? <Button
                                domID="clientSettingsSubmit"
                                name="Next"
                                onClick={() => { onClientSettingsSubmit() }}
                                size="small"
                                buttonType="standard"
                            /> : <></>}
                        </div>
                    </>
                )}
                {modalContent.isAccessOpen && <ClientSettingsConfirmModal
                    isAccessOpen={modalContent.isAccessOpen}
                    onAccessClose={onAccessClose}
                    data={modalContent.confirmData}
                    confirmationCallback={refetch}
                    instructions={modalContent.showInstructions}
                />}
            </div>
        </>
    )
}

export default ClientSettings