/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch,useSelector} from 'react-redux';
import { Button,ButtonGroup,Modal, TextArea, ModalBody} from 'ui-core/dist'
import {setNotesModal} from './Modules/Action';
import ModalHeaderWrapper from '../../components/ErrorDisplaySection/ModalHeaderWrapper';
import './Modules/styles.scss';
import {useAddNote} from '../../api/useErrorManager';

const ErrorManagerAddNotes = ({ refreshMe }) => {
  const [notetext,setNotetext]=useState('');
  const dispatch = useDispatch();
  const { id, notesModal } = useSelector(store => store.errorManager)
  const { postData } = useAddNote({
    onSuccess: () => {
      if (refreshMe) refreshMe.forEach(func => func())
      dispatch(setNotesModal())
    },
  })

  useEffect(()=>{
    setNotetext('')
  },[notesModal])

  const resetClick = () => setNotetext('')

  const handleConfirmation=()=> {
    postData({
      pstrErrorId: id,
      pstrNoteText: notetext, 
    })
  }

  return (
    <Modal isOpen={notesModal}>
      <ModalHeaderWrapper
        title="Add Note To Error"
        onClose={() => {
          setNotetext('')
          dispatch(setNotesModal(false))
        }}
      />
      <ModalBody>
        <div className="notes-modal__body">
          <div className="notes-modal__section">
            <TextArea
              className="notes-modal"
              domID="NotesModalTextarea"
              placeholder="Add a note!"
              label="Note Text :"
              resize="both"
              width="400px"
              initialValue={notetext}
              onChange={(e)=>setNotetext(e.target.value)}
            />
          </div>
          <ButtonGroup>
            <Button
              style={{marginLeft:'10px'}}
              buttonType="deEmphasized"
              size="medium"
              name="Reset"
              className="marginLeft"
              onClick={resetClick}
            />
            <Button
              style={{marginLeft:'10px'}}
              buttonType="emphasized"
              size="medium"
              name="Confirm"
              className="marginLeft"
              onClick={handleConfirmation}
            />
          </ButtonGroup>
        </div>
      </ModalBody>
    </Modal>
  )
}

ErrorManagerAddNotes.propTypes = {
  refreshMe: PropTypes.arrayOf(PropTypes.func)
}

ErrorManagerAddNotes.defaultProps = {
  refresMe: undefined
}
export default ErrorManagerAddNotes
