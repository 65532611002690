import React from 'react'
import { Page } from '../../components'
import RouteWrapper from '../../components/RouteWrapper';
import ListPaneContent from './DataSourceListPane'


const DataSourceManager = () => (
<RouteWrapper>
  <Page 
    ListPaneContent={ListPaneContent}
  />
</RouteWrapper>
)
export default DataSourceManager