import { useAxios } from '../hooks'

const useClientLayouts = () => {
  const { data, ...rest } = useAxios({
    url: `/message-manager/layouts`,
  })

  return { 
    data,
    ...rest
  }
}

export default useClientLayouts