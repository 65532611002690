/* eslint-disable radix */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-useless-escape */
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import styled from 'styled-components/dist/styled-components'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import DetailPaneHeader from 'ui-core/dist/DetailPaneHeader';
import {
  useLayout,
  Button, Modal, ModalHeader, TabContainer, ModalBody
} from 'ui-core/dist'
import { useGetEftAccounts, useGetEftAccount, useCopyEftAccount, 
  useOverWriteEftAccount, useDeactivateEftAccount } from '../../api/usePayerEftAccount';
import {detailPaneAnimStates} from '../../commonResources/constants/animStates';
import {AnchorInput} from '../../components'
import LoadingComponent from '../../components/LoadingComponent';
import ErrorPage from '../ErrorPage/components/ErrorPage';
import useNotificationBannerHook from '../../useNotificationBannerHook';
import GridPaginationWrapper from '../../components/GridPaginationWrapper';
import * as constants from './Modules/constants';
import {setEditPayerEftAccount, resetEditPayerEftAccount, setModePayerEftAccount} from './Modules/action'

const DEFAULT_ERROR_MSG_EFT_ACCOUNT='Could not retireve account information for Client EFT Account: {0}';

const StyledTable=styled.table`
& > tr > td:first-child {
  width: 350px;
}
`
const StyledLoadingContainer=styled.div`
& > div {
  position: absolute !important;
  left: 36% !important;
  top: 36% !important;
}
`
const PayerEftAccountDetailPane = () => {
  const {
    expandCollapseDetailPaneButtonConfig,
    closeDetailPaneButtonConfig,
    detailAnimationState,
    closeDetailPane
  } = useLayout()
  const { notify } = useNotificationBannerHook();
  const history = useHistory();
  const dispatch=useDispatch()

  const { originalRecord, refetchEftAccounts } 
    = useSelector(({ payerEftAccount }) => payerEftAccount);

  const [tabIndex, settabIndex]=useState(0)
  const [consolidationTypeId, setConsolidationTypeId]=useState('')
  const [consolidationTypeDesc, setConsolidationTypeDesc]=useState('')
  const [drawDownTypeId, setDrawDownTypeId]=useState('')
  const [drawDownTypeDesc, setDrawDownTypeDesc]=useState('')
  const [eftAcctId, setEftAcctId]=useState('')
  const [accountId, setAccountId]=useState('')
  const [routingCode, setRoutingCode]=useState('')
  const [companyName, setCompanyName]=useState('')
  const [descretionaryData, setDescretionaryData]=useState('')
  const [odfiId, setOdfiId]=useState('')
  const [effDateDiff, setEffDateDiff]=useState('')
  const [companyId, setCompanyId]=useState('')
  const [companyEntryDesc, setCompanyEntryDesc]=useState('')
  const [origFinInst, setOrigFinInst]=useState('')
  const [accountVerified, setAccountVerified]=useState('')
  const [bankingModel, setBankingModel]=useState('')
  const [versionNumber, setVersionNumber]=useState('')
  const [statusCode, setStatusCode]=useState('')
  const [tUpdated, setTUpdated]=useState('')
  const [acctType, setAcctType]=useState('')
  const [holdDays, setHoldDays]=useState('')
  const [count, setcount] = useState(0);
  const [isOpenCopyConfirmationModal, setisOpenCopyConfirmationModal]=useState(false)
  const [isOpenOverwriteConfirmationModal, setisOpenOverwriteConfirmationModal]=useState(false)

  useEffect(() => {
    if (metadataEftAccount) {
      const { status, statusText } = metadataEftAccount;
      if (status === 200 || statusText === "OK") {
        if (eftAccount && eftAccount.fs) {
          const { fs } = eftAccount;
          if (fs) {
            notify(fs, 'negative')
          }
        }
      } else{
        notify(DEFAULT_ERROR_MSG_EFT_ACCOUNT.replace('{0}', originalRecord?.originalEftAcctId), 'negative')
      }
    }
  }, [metadataEftAccount, eftAccount])

  useEffect(()=>{
    if (originalRecord) {
      const {originalEftAcctId, originalStatusCode}=originalRecord;
      if (originalStatusCode==="L") {
        settabIndex(0)
        dispatch(resetEditPayerEftAccount())
        resetForm()
        getEftAccount({acctId: originalEftAcctId, status: "L",count: count+1})
        setcount(count+2)
      } else if(originalStatusCode==="T"){
        settabIndex(1)
        dispatch(resetEditPayerEftAccount())
        resetForm()
        getEftAccount({acctId: originalEftAcctId, status: 'T',count: count+2})  
        setcount(count+3)   
      }
    }
  }, [originalRecord])

  const { isLoading: isLoadingEftAccount, isError: isErrorEftAccount, error: errorEftAccount, postData: getEftAccount, 
    metaData: metadataEftAccount, data: eftAccount } = useGetEftAccount()
  const { isLoading: isLoadingcopyEftAccount, isError: isErrorcopyEftAccount, error: errorcopyEftAccount, postData: copyEftAccount, 
    metaData: metadatacopyEftAccount, data: copyEftAccountResp } = useCopyEftAccount()
  const { isLoading: isLoadingoverWriteEftAccount, isError: isErroroverWriteEftAccount, error: erroroverWriteEftAccount, postData: overWriteEftAccount, 
    metaData: metadataoverWriteEftAccount, data: overWriteEftAccountResp } = useOverWriteEftAccount()

  useEffect(() => {
    if (metadatacopyEftAccount) {
      const { status, statusText } = metadatacopyEftAccount;
      if (status === 200 || statusText === "OK") {
        if (copyEftAccountResp && copyEftAccountResp.fs) {
          const { fs } = copyEftAccountResp;
          if (fs) {
            notify(fs, 'negative')
            return;
          }
        }
        notify(`${constants.status_client_eft_copy_successful.replace('{0}', eftAcctId)} to ${copyEftAccountResp.resp}`, 'positive')
        closeDetailPane()
        if(refetchEftAccounts) refetchEftAccounts()
      } else {
        notify(constants.error_client_eft_copy_failure.replace('{0}', eftAcctId), 'negative')
      }
      setisOpenCopyConfirmationModal(false)
    }
  }, [metadatacopyEftAccount, copyEftAccountResp])
  useEffect(() => {
    if (metadataoverWriteEftAccount) {
      const { status, statusText } = metadataoverWriteEftAccount;
      if (status === 200 || statusText === "OK") {
        if (overWriteEftAccountResp && overWriteEftAccountResp.fs) {
          const { fs } = overWriteEftAccountResp;
          if (fs) {
            notify(fs, 'negative')
            return;
          }
        }
        notify(constants.status_client_eft_overwrite_successful.replace('{0}', eftAcctId), 'positive')
        closeDetailPane()
        if(refetchEftAccounts) refetchEftAccounts()
      } else {
        notify(constants.error_client_eft_overwrite_failure.replace('{0}', eftAcctId), 'negative')
      }
      setisOpenOverwriteConfirmationModal(false)
    }
  }, [metadataoverWriteEftAccount, overWriteEftAccountResp])

  const populateEftObj=()=> {
    const abfPeo={};
    abfPeo.consType = consolidationTypeId||'';
    abfPeo.consTypeDesc = consolidationTypeDesc||'';
    abfPeo.drawDown = drawDownTypeId||'';
    abfPeo.drawDownDesc = drawDownTypeDesc||'';
    abfPeo.acctId = eftAcctId||'';
    abfPeo.onus = accountId||'';
    abfPeo.routeId = routingCode||'';
    abfPeo.compName = companyName||'';
    abfPeo.compData = descretionaryData||'';
    abfPeo.odfiId = odfiId||'';
    abfPeo.dateDiff = effDateDiff||'';
    abfPeo.compId = companyId||'';
    abfPeo.compEntDesc = companyEntryDesc||'';
    abfPeo.originId = origFinInst||'';
    abfPeo.verified = accountVerified||'';
    abfPeo.payerBank = bankingModel||'';
    abfPeo.version = versionNumber||'';
    abfPeo.status = statusCode||'';
    abfPeo.updated = tUpdated||'';
    abfPeo.acctType = acctType||'';
    abfPeo.holdDays = holdDays||'';
    return abfPeo;
  }
  const onCopyClick=()=>{
    const abfPeo=populateEftObj();
    copyEftAccount({...abfPeo, count})
    setcount(count+1)
  }
  const onOverwriteClick=()=>{
    const abfPeo=populateEftObj();
    overWriteEftAccount({...abfPeo, count})
    setcount(count+1)
  }
  const onDeactivateClick=()=>{
    history.push('/ClientRulesManager/PayerEftAccount/deactiveConfirmation')
  }
  const onModifyClick=()=>{
    dispatch(setModePayerEftAccount({mode: 'modify'}))
    history.push('/ClientRulesManager/PayerEftAccount/edit')
  }
  const renderViewModal=useCallback((args)=>{
    if (eftAcctId) {
      return (
        <div style={{ marginLeft: '34px', marginBottom: '20px' }} className="marginLeft">
          <div className="displayFlex">
            <div className="marginLeft">
              {args==="viewedit"?(
                <table>
                  <tr>
                    <table border="0">
                      <tr>
                        <td className="name" nowrap="nowrap">
                          <div className='displayFlex'>
                            <Button
                              id='eftPayerCopy'
                              size="small"
                              name="Copy EFT Account"
                              onClick={()=>setisOpenCopyConfirmationModal(true)}
                            />
                            <div className='marginLeft'>
                              <Button
                                id='eftPayerDeactivate'
                                size="small"
                                name="Deactivate EFT Account"
                                onClick={()=>onDeactivateClick()}
                              />
                            </div>
                            <div className='marginLeft'>
                              <Button
                                id='eftPayerModify'
                                size="small"
                                name="Modify"
                                onClick={()=>onModifyClick()}
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr><td><p></p></td></tr>
                      <tr>
                        <td className="name">Client Rule Id:&nbsp;{eftAcctId}&nbsp;(Test)</td>
                      </tr>
                      <tr><td><p></p></td></tr>
                    </table>
                    <StyledTable border="0">
                      <>
                        {bankingModel==="1"?(
                          <tr>
                            <td className="name">Banking Model:</td>
                            <td className="value">Payer Bank</td>
                          </tr>
                        ):null}
                      </>
                      <>
                        {bankingModel==="0"?(
                          <tr>
                            <td className="name">Banking Model:</td>
                            <td className="value">Emdeon</td>
                          </tr>
                        ):null}
                      </>
                      <tr>
                        <td className="name">Payer Eft Account Number:</td>
                        <td className="value">{accountId}</td>
                      </tr>
                      <tr>
                        <td className="name">Payer EFT Routing Code:</td>
                        <td className="value">{routingCode}</td>
                      </tr>
                      <tr>
                        <td className="name">Company Name:</td>
                        <td className="value">{companyName}</td>
                      </tr>
                      <>
                        {bankingModel==="1"?(
                          <tr>
                            <td className="name">ODFI ID:</td>
                            <td className="value">{odfiId}</td>
                          </tr>
                        ):null}
                      </>
                      <tr>
                        <td className="name">Effective Date Difference:</td>
                        <td className="value">{effDateDiff}</td>
                      </tr>
                      <>
                        {bankingModel==="0"?(
                          <tr>
                            <td className="name">Hold Days:</td>
                            <td className="value">{holdDays}</td>
                          </tr>
                        ):null}
                      </>
                      <>
                        {bankingModel==="1"?(
                          <tr>
                            <td className="name">Discretionary Data:</td>
                            <td className="value">{descretionaryData}</td>
                          </tr>
                        ):null}
                      </>
                      <>
                        {bankingModel==="1"?(
                          <tr>
                            <td className="name">Company Identification (Assigned by ODFI):</td>
                            <td className="value">{companyId}</td>
                          </tr>
                        ):null}
                      </>
                      <>
                        {bankingModel==="1"?(
                          <tr>
                            <td className="name">Company Entry Description:</td>
                            <td className="value">{companyEntryDesc}</td>
                          </tr>
                        ):null}
                      </>
                      <>
                        {bankingModel==="1"?(
                          <tr>
                            <td className="name">Originating Financial Institution:</td>
                            <td className="value">{origFinInst}</td>
                          </tr>
                        ):null}
                      </>
                      <>
                        {bankingModel==="0"?(
                          <tr>
                            <td className="name">Consolidation Type:</td>
                            <td className="value">{consolidationTypeDesc}</td>
                          </tr>
                        ):null}
                      </>
                      <>
                        {bankingModel==="0"?(
                          <tr>
                            <td className="name">Draw Down Type:</td>
                            <td className="value">{drawDownTypeDesc}</td>
                          </tr>
                        ):null}
                      </>
                      <>
                        {bankingModel==="0"?(
                          <tr>
                            <td className="name">Account Type:</td>
                            {acctType==="C"?(
                              <td className="value">Checkings</td>
                            ):null}
                            {acctType==="S"?(
                              <td className="value">Savings</td>
                            ):null}
                          </tr>
                        ):null}
                      </>
                      <tr>
                        <td className="name">Account Verified:</td>
                        {accountVerified==="0"?(
                          <td className="value">NO</td>
                        ):null}
                        {accountVerified==="1"?(
                          <td className="value">YES</td>
                        ):null}
                      </tr>
                    </StyledTable> 
                  </tr>
                </table>
              ):(
                <table>
                  <tr>
                    <table border="0">
                      <tr>
                        <td className="name" nowrap="nowrap">
                          <div className='displayFlex'>
                            <Button
                              id='ContactEditorAdd'
                              size="small"
                              name="Copy EFT Account"
                              onClick={()=>setisOpenCopyConfirmationModal(true)}
                            />
                            <div className='marginLeft'>
                              <Button
                                id='ContactEditorAdd'
                                size="small"
                                name="Deactivate EFT Account"
                                onClick={()=>onDeactivateClick()}
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr><td><p></p></td></tr>
                      <tr>
                        <td className="name">
                          <div className='displayFlex'>
                              Client Rule Id:&nbsp;{eftAcctId}&nbsp;(Live)&nbsp;
                            <div className='marginLeft'>
                              <Button
                                id='ContactEditorAdd'
                                size="small"
                                name="Overwrite Test version with copy of this Payer EFT Account"
                                onClick={()=>setisOpenOverwriteConfirmationModal(true)}
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr><td><p></p></td></tr>
                    </table>
                    <StyledTable border="0">
                      {bankingModel==="1"?(
                        <tr>
                          <td className="name">Banking Model:</td>
                          <td className="value">Payer Bank</td>
                        </tr>
                      ):null}
                      {bankingModel==="0"?(
                        <tr>
                          <td className="name">Banking Model:</td>
                          <td className="value">Emdeon</td>
                        </tr>
                      ):null}
                      <tr>
                        <td className="name">Payer Eft Account Number:</td>
                        <td className="value">{accountId}</td>
                      </tr>
                      <tr>
                        <td className="name">Payer EFT Routing Code:</td>
                        <td className="value">{routingCode}</td>
                      </tr>
                      <tr>
                        <td className="name">Company Name:</td>
                        <td className="value">{companyName}</td>
                      </tr>
                      {bankingModel==="1"?(
                        <tr>
                          <td className="name">ODFI ID:</td>
                          <td className="value">{odfiId}</td>
                        </tr>
                      ):null}
                      <tr>
                        <td className="name">Effective Date Difference:</td>
                        <td className="value">{effDateDiff}</td>
                      </tr>
                      {bankingModel==="0"?(
                        <tr>
                          <td className="name">Hold Days:</td>
                          <td className="value">{holdDays}</td>
                        </tr>
                      ):null}
                      {bankingModel==="1"?(
                        <tr>
                          <td className="name">Discretionary Data:</td>
                          <td className="value">{descretionaryData}</td>
                        </tr>
                      ):null}
                      {bankingModel==="1"?(
                        <tr>
                          <td className="name">Company Identification (Assigned by ODFI):</td>
                          <td className="value">{companyId}</td>
                        </tr>
                      ):null}
                      {bankingModel==="1"?(
                        <tr>
                          <td className="name">Company Entry Description:</td>
                          <td className="value">{companyEntryDesc}</td>
                        </tr>
                      ):null}
                      {bankingModel==="1"?(
                        <tr>
                          <td className="name">Originating Financial Institution:</td>
                          <td className="value">{origFinInst}</td>
                        </tr>
                      ):null}
                      <>
                        {bankingModel==="0"?(
                          <tr>
                            <td className="name">Consolidation Type:</td>
                            <td className="value">{consolidationTypeDesc}</td>
                          </tr>
                        ):null}
                      </>
                      <>
                        {bankingModel==="0"?(
                          <tr>
                            <td className="name">Draw Down Type:</td>
                            <td className="value">{drawDownTypeDesc}</td>
                          </tr>
                        ):null}
                      </>
                      {bankingModel==="0"?(
                        <tr>
                          <td className="name">Account Type:</td>
                          {acctType==="C"?(
                            <td className="value">Checkings</td>
                          ):null}
                          {acctType==="S"?(
                            <td className="value">Savings</td>
                          ):null}
                        </tr>
                      ):null}
                      <tr>
                        <td className="name">Account Verified:</td>
                        {accountVerified==="0"?(
                          <td className="value">NO</td>
                        ):null}
                        {accountVerified==="1"?(
                          <td className="value">YES</td>
                        ):null}
                      </tr>
                    </StyledTable>  
                  </tr>
                </table>
              )}
            </div>
          </div>
        </div>
      );
    }
    return null;
  },[
    consolidationTypeDesc,drawDownTypeDesc,eftAcctId,accountId,
    routingCode,companyName,descretionaryData,odfiId,effDateDiff,companyId,
    companyEntryDesc,origFinInst,accountVerified,bankingModel,acctType,holdDays,
    consolidationTypeId,drawDownTypeId,versionNumber,statusCode,tUpdated
  ])
  const populateForm=(abfPeo)=>{
    setConsolidationTypeId(abfPeo.consType);
    setConsolidationTypeDesc(abfPeo.consTypeDesc);
    setDrawDownTypeId(abfPeo.drawDown);
    setDrawDownTypeDesc(abfPeo.drawDownDesc);
    setEftAcctId(abfPeo.acctId);
    setAccountId(abfPeo.onus);
    setRoutingCode(abfPeo.routeId);
    setCompanyName(abfPeo.compName);
    setDescretionaryData(abfPeo.compData);
    setOdfiId(abfPeo.odfiId);
    setEffDateDiff(abfPeo.dateDiff);
    setCompanyId(abfPeo.compId);
    setCompanyEntryDesc(abfPeo.compEntDesc);
    setOrigFinInst(abfPeo.originId);
    setAccountVerified(abfPeo.verified);
    setBankingModel(abfPeo.payerBank);
    setVersionNumber(abfPeo.version);
    setStatusCode(abfPeo.status);
    setTUpdated(abfPeo.updated);
    setAcctType(abfPeo.acctType);
    setHoldDays(abfPeo.holdDays);
  }
  const resetForm=()=>{
    setConsolidationTypeId("");
    setConsolidationTypeDesc("");
    setDrawDownTypeId("");
    setDrawDownTypeDesc("");
    setEftAcctId("");
    setAccountId("");
    setRoutingCode("");
    setCompanyName("");
    setDescretionaryData("");
    setOdfiId("");
    setEffDateDiff("");
    setCompanyId("");
    setCompanyEntryDesc("");
    setOrigFinInst("");
    setAccountVerified("");
    setBankingModel("");
    setVersionNumber("");
    setStatusCode("");
    setTUpdated("");
    setAcctType("");
    setHoldDays("");
  }
  useEffect(()=>{
    if (eftAcctId) {
      dispatch(setEditPayerEftAccount({editPayerEftAccount: {
        consolidationTypeId,
        consolidationTypeDesc,
        drawDownTypeId,
        drawDownTypeDesc,
        eftAcctId,
        accountId,
        routingCode,
        companyName,
        descretionaryData,
        odfiId,
        effDateDiff,
        companyId,
        companyEntryDesc,
        origFinInst,
        accountVerified,
        bankingModel,
        versionNumber,
        statusCode,
        tUpdated,
        acctType,
        holdDays
      }}))
    }
  },[eftAcctId])
  useEffect(()=>{
    if (eftAccount) {
      if (eftAccount && eftAccount.resp) {
        const {resp: abfPeo}=eftAccount;
        populateForm(abfPeo);
      }
    }
  }, [eftAccount])
  const renderTabContainer = useMemo(() => {
    let originalEftAcctId;
    let originalStatusCode;
    if (originalRecord) ({originalEftAcctId, originalStatusCode}=originalRecord);
    if (detailAnimationState > detailPaneAnimStates.COLLAPSED && originalEftAcctId) {
      return <div style={{ overflow: 'auto' }}><TabContainer
        className="tab_container_classname"
        contentClass="tab_content_classname"
        dataTestId="test-tabInitial"
        domID="tab-container-initial"
        initialTab={tabIndex}
        onTabChange={(...rest) => {
          if (rest.length===2) {
            const {tabIndex: tabIndexP}=rest[1];
            settabIndex(tabIndexP)
            if (tabIndexP===0 && originalStatusCode!=="T") {
              dispatch(resetEditPayerEftAccount())
              resetForm()
              getEftAccount({acctId: originalEftAcctId, status: "L",count})
              setcount(count+1)
            } else if(tabIndexP===1 && (originalStatusCode==="L" || originalStatusCode==="T")){
              dispatch(resetEditPayerEftAccount())
              resetForm()
              getEftAccount({acctId: originalEftAcctId, status: 'T',count})  
              setcount(count+1)   
            }
          }
        }}
        tabs={[
          {
            domID: 'id-1',
            label: 'View Live',
            tabContent: <>
              {isLoadingEftAccount?<div className='marginLeft'><StyledLoadingContainer>
                <LoadingComponent/></StyledLoadingContainer></div>:(
                <>{originalStatusCode!=="T"?(
                  <>
                    {renderViewModal("view")}
                  </>
                ):(
                  <>
                    <div>
                      <p><span><lable>Live details are not available for this Record</lable></span></p>
                    </div>
                  </>
                )}
                </>
              )}
            </>
          },
          {
            domID: 'id-2',
            label: 'Edit Test',
            tabContent: <>
              {isLoadingEftAccount?<div className='marginLeft'><StyledLoadingContainer>
                <LoadingComponent/></StyledLoadingContainer></div>:(
                <>{renderViewModal("viewedit")}</>
              )}
            </>
          },
        ]}
      /></div>
    }
    return null;
  },[
    detailAnimationState, tabIndex, originalRecord, isLoadingEftAccount,
    consolidationTypeDesc,drawDownTypeDesc,eftAcctId,accountId,
    routingCode,companyName,descretionaryData,odfiId,effDateDiff,companyId,
    companyEntryDesc,origFinInst,accountVerified,bankingModel,acctType,holdDays,
    consolidationTypeId,drawDownTypeId,versionNumber,statusCode,tUpdated
  ])
  const renderCopyConfirmationModal=useMemo(()=>(
    <Modal isOpen={isOpenCopyConfirmationModal}>
      <ModalHeader
        title="Do you want to Copy ?"
        onClose={() => setisOpenCopyConfirmationModal(false)}
      />
      <ModalBody>
        {isOpenCopyConfirmationModal ? (
          <div style={{ marginLeft: '34px', marginBottom: '20px' }} className="marginLeft">
            <div className="displayFlex">
              <div className="marginLeft">
                <Button
                  domID="btn_exclYes"
                  buttonType="standard"
                  size="small"
                  name="Yes"
                  onClick={() => onCopyClick()}
                />
              </div>
              <div className="marginLeft">
                <Button
                  domID="btn_exclNo"
                  buttonType="standard"
                  size="small"
                  name="No"
                  onClick={() => setisOpenCopyConfirmationModal(false)}
                />
              </div>
            </div>
          </div>
        ) : null}
      </ModalBody>
    </Modal>
  ),[isOpenCopyConfirmationModal])
  const renderOverwriteConfirmationModal=useMemo(()=>(
    <Modal isOpen={isOpenOverwriteConfirmationModal}>
      <ModalHeader
        title="Do you want to Overwrite ?"
        onClose={() => setisOpenOverwriteConfirmationModal(false)}
      />
      <ModalBody>
        {isOpenOverwriteConfirmationModal ? (
          <div style={{ marginLeft: '34px', marginBottom: '20px' }} className="marginLeft">
            <div className="displayFlex">
              <div className="marginLeft">
                <Button
                  domID="btn_exclYes"
                  buttonType="standard"
                  size="small"
                  name="Yes"
                  onClick={() => onOverwriteClick()}
                />
              </div>
              <div className="marginLeft">
                <Button
                  domID="btn_exclNo"
                  buttonType="standard"
                  size="small"
                  name="No"
                  onClick={() => setisOpenOverwriteConfirmationModal(false)}
                />
              </div>
            </div>
          </div>
        ) : null}
      </ModalBody>
    </Modal>
  ),[isOpenOverwriteConfirmationModal])
  return (
    <>
      <DetailPaneHeader
        title="Client Payer Eft Manager: Details"
        layoutControls={[
          expandCollapseDetailPaneButtonConfig,
          closeDetailPaneButtonConfig
        ]}
      />
      <>
        {isLoadingcopyEftAccount || isLoadingoverWriteEftAccount ? (
        // replace with a better loading component
          <div style={{ marginLeft: '50%' }}><LoadingComponent /></div>
        ) : isErrorcopyEftAccount || isErroroverWriteEftAccount || isErrorEftAccount ? (
        // replace with a better error component
          <ErrorPage
            // this prop isn't real but is an example
            error={errorcopyEftAccount || erroroverWriteEftAccount || errorEftAccount}
          />
        ) : (
          <>
            {renderTabContainer}
            {renderCopyConfirmationModal}
            {renderOverwriteConfirmationModal}
          </>
        )           
        }
      </>
    </>
  )
}
  
export default PayerEftAccountDetailPane