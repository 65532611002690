import {useContext, useRef} from 'react'
import NotificationContext from './notificationContext'

const useNotificationBannerHook=()=>{

  const notificationContext = useContext(NotificationContext);

  const notificationRef=useRef(notificationContext)

  return notificationRef.current;
}

export default useNotificationBannerHook