import React, { useState, useEffect } from 'react'
import {
  Grid,
  Checkbox,
  Input,
  useLayout,
  ListPaneHeader,
  Button,
} from 'ui-core/dist'
import axios from '../../axios'
import useIDDocuments from '../../api/useIDDocuments'
import { AnchorInput, GridPaginationWrapper, Loading } from '../../components'
import ErrorPage from '../ErrorPage';
import useNotificationBannerHook from '../../useNotificationBannerHook';
import './modules/styles.scss'

const WelcomeKitDocuments = () => {
  const { notify } = useNotificationBannerHook()
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [pageIndex, setPageIndex] = useState(0);
  const [idx, setIdx] = useState(0)
  const [docKey, setDocKey] = useState("")
  const [memberName, setMemberName] = useState("")
  const [memberId, setMemberId] = useState("")
  const [exactMatch, setExactMatch] = useState(false)

  const [resp, setResp] = useState([])
  const [totalRecords, settotalRecordsResp] = useState(0)

  const { expandCollapseListPaneButtonConfig } = useLayout()
  const { docs: data, isLoading, getDocuments, total, isError, error } = useIDDocuments()

  const onDocKeyChange = (e, state) => setDocKey(state.value)
  const onMemberIdChange = (e, state) => setMemberId(state.value)
  const onMemberNameChange = (e, state) => setMemberName(state.value)
  const onExactMatchToggle = () => setExactMatch(!exactMatch)

  useEffect(() => {
    if (data) {
      setResp(data);
      settotalRecordsResp(total);
    }
  }, [data])

  const searchClick = () => {
    setRowsPerPage(50)
    setPageIndex(0)
    setIdx(idx + 1);
    settotalRecordsResp(0);
    getDocuments({
      maxRecords: 50,
      rowPosition: 0,
      docKey,
      memberId,
      memberName,
      exactMatch,
      idx,
    })
  }

  const handleShowButton = ({ pi }) => {
    setIdx(idx + 1)
    setPageIndex(pi)
    settotalRecordsResp(0);
    getDocuments({
      maxRecords: rowsPerPage,
      rowPosition: pi,
      docKey,
      memberName,
      memberId,
      exactMatch,
    })
  }

  const onPDFDownload = async (docKey, group) => {
    try {
      const res = await axios.get(`/welcomeKit/pdf?docKey=${docKey}&group=${group}`, { responseType: 'blob' })
      if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
        window.navigator.msSaveOrOpenBlob(res.data, `${docKey}.pdf`);
      } else {
        const url = URL.createObjectURL(res.data);
        const tab = window.open();
        if(tab)
          tab.location.href = url;
        else
          notify('Please enable allow window pop-up in your browser.','caution')
      }
    } catch (err) {
      notify('PDF does not exists', "negative")
    }
  }

  return (
    <>
      <ListPaneHeader
        title={`Document Manager Search`}
        layoutControls={[expandCollapseListPaneButtonConfig]}
      />
      <div className="idDocument-search">
        <div className="idDocument__search-row">
          <Input
            domID="IDDocumentKey"
            onChange={onDocKeyChange}
            size="small"
            label={"Document Key"}
          />
          <div style={{ position: 'relative' }}>
            <Input
              domID="IDMemberName"
              onChange={onMemberNameChange}
              size="small"
              label={"Member Name"}
            />
            <div className='idDocument-exactMatch'>
              <span className="idDocument__label">Exact Match:</span>
              <Checkbox
                domID="ExactMatchCheckbox"
                checked={exactMatch}
                onChange={onExactMatchToggle}
              />
            </div>
          </div>
          <Input
            domID="IDMemberId"
            onChange={onMemberIdChange}
            size="small"
            label={"Member Id"}
          />
          <Button
            domID="searchButton"
            name="Search"
            disabled={!docKey && !memberId && !memberName}
            onClick={searchClick}
            size="small"
            buttonType="standard"
            className="idDocument__search-button"
          />
        </div>
        {idx !== 0 ? <div className="idDocument__pagination-row">
          <GridPaginationWrapper
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={setRowsPerPage}
            pageIndex={pageIndex}
            onChangePageIndex={setPageIndex}
            onShowButtonCLick={handleShowButton}
            totalRecords={Number(totalRecords) || 0}
            defaultRowsPerPage={50}
          />
        </div> : <></>}
        <div className="idDocument__grid-wrapper">
          {isLoading && idx !== 0 ? <Loading /> :
            isError ? (
              // replace with a better error component
              <ErrorPage
                // this prop isn't real but is an example
                error={error}
              />
            ) :
              idx !== 0 ? (
                <Grid
                  columns={new Set([
                    {
                      dataName: 'cKey',
                      text: 'Doc Key',
                      cellType: 'text',
                    },
                    {
                      dataName: 'businessUnit',
                      text: 'Business Unit',
                      cellType: 'text',
                    },
                    {
                      dataName: 'memberNumber',
                      text: "Member Id",
                      cellType: 'text',
                    },
                    {
                      dataName: 'memberName',
                      text: 'Member Name',
                      cellType: 'text',
                    },
                    {
                      dataName: 'insertedHere',
                      text: 'File Received',
                      cellType: 'text',
                    },
                    {
                      dataName: 'lastUpdated',
                      text: "Last Updated",
                      cellType: 'text',
                    },
                    {
                      dataName: 'overallStatus',
                      text: "Status",
                      cellType: 'text',
                    },
                    {
                      dataName: 'download',
                      text: "Download",
                      cellType: 'text',
                    }
                  ])}
                  records={resp?.map((record, i) => ({
                    ...record,
                    download: (
                      <AnchorInput
                        id={`docKey-${record.docKey}`}
                        type='button'
                        className='docKeyLink'
                        onClick={() => onPDFDownload(record.cKey, record.envGroup)}
                      >
                        pdf
                      </AnchorInput>
                    )
                  })) || []}
                  dataTestId="test-IDDocumentGrid"
                  domID="IDDocumentGrid"
                />
              ) : <></>}
        </div>
      </div>
    </>
  )
}

export default WelcomeKitDocuments