export const Colors = {
  // Each grouping has the lightest value first, and the darkest value last
  // Always use variables, even for pure white and pure black
  white: '#FFF',
  black: '#000',
  highlight: '#fff0c1', // Replaces #F8F3DE
  lightBlue: '#4CC6FF',
  turquoise: '#286B8B',
  lightTurquoise: '#2B77A4',
  darkTurquoise: '#205778',
  babyBlue: '#40B9FF',
  lightBabyBlue: '#e4f6ff',

  defaultHighlight: '',
  defaultLight: '#C3C5CD', // Replaces #ccd1d8,
  default: '#37474F',
  defaultDark: '#000',
  defaultTransparent: 'rgba(51, 51, 51, .9)', // Replaces rgba(77, 77, 77, .2), rgba(68, 71, 75, .45)

  primaryHighlight: '#edf9ff',
  primaryLight: '#CAF0FF',
  primary: '#82D8FF',
  primaryTransparent: 'rgba(130, 216, 255, .30)',

  secondaryHighlight: '#F0EAFD',
  secondaryLight: '#D8B6F5', // Replaces rgba(94, 138, 180, .7)
  secondary: '#A84EE5',
  secondaryDark: '#8400D9',
  secondaryTransparent: 'rgba(168, 78, 229, .30)',

  tertiaryHighlight: '#F1FCF8',
  tertiaryLight: '#CBF8E6',
  tertiary: '#7FF0C2',
  tertiaryDark: '#46EAA7',
  tertiaryTransparent: 'rgba(127, 240, 194, .30)',

  positiveHighlight: '#C9E5B6',
  positiveLight: '#7DC54E',
  positive: '#44AD00', // Replaces #51a08f
  positiveDark: '#3D9901',
  positiveDarkest: '#358600',
  positiveTransparent: 'rgba(61, 153, 2, .30)',

  caution: '#BB8D00',
  darkYellow: '#DAC893',
  darkRed: '#B62727',

  warningHighlight: '#FFEBB6',
  warningLight: '#FFD352',
  warning: '#FFC107',
  warningDark: '#E9AF01',
  warningTransparent: 'rgba(232, 175, 1, .30)',

  negativeHighlight: '#F4C5C5',
  negativeLight: '#E47979',
  negative: '#D84040', // Replaces #d5361c
  negativeDark: '#B72A2A',
  negativeTransparent: 'rgba(183, 42, 42, .30)', // Replaces rgba(224, 78, 57, .25)

  destroyHighlight: '#F4C5C5',
  destroyLight: '#f14327',
  destroy: '#D84040', // Replaces #db6350, #d65846, #d65846
  destroyDark: '#AD240E',
  destroyTransparent: 'rgba(224, 78, 57, .6)',

  // Normal greys have no hue
  fadedGrey: '#86AFC5',
  veryFaintGrey: '#DADBE0',
  faintestGrey: '#F8F8F8',
  fainterGrey: '#E1E2E6',
  faintGrey: 'rgba(34,34,34,0.4)',
  lightestGrey: '#f7f5fd',
  lighterGrey: '#DDD',
  lightGrey: '#CCC',
  middleGrey: '#B3B3B3',
  middlerGrey: '#ACACAC',
  middlestGrey: '#838383',
  darkGrey: '#737373',
  darkerGrey: '#333',
  darkestGrey: '#222',

  // Secondary greys have blue in the hue
  faintestSecondaryGrey: '#F1F2F3', // Replaces #f5f7fa, #f0ebeb
  fainterSecondaryGrey: '#EBE9F1', // Replaces #e6e7e9, #edf1f5, #e8eaec, #e6e8eb
  faintSecondaryGrey: '#D6DADF', // Replaces #d7dadd, #d9e0e6, #d0d1d3, #d9dde0
  lightestSecondaryGrey: '#c4d0da ', // Replaces #cacdcf, #cdcfd1, #c5cfd8, #cfd1d5, #c2c8cf, #c7ccd1
  lighterSecondaryGrey: '#BEC4C6', // Replaces #bcbec0, #b8bec4, #b8bfc5
  lightSecondaryGrey: '#AFB6BD', // Replaces #aeb9c2, #afb7bd, #a0a6ac, #a2aaad
  middleSecondaryGrey: '#9BA1A9', // Replaces #868e94, #83878a, #818486, #858a8f, #989ea5, #8596a3
  secondary30: '#737D85', // Replaces #7b828a, #757c83
  middlestSecondaryGrey: '#6F7479', // Replaces #676d72, #62666b, #575b5F
  darkSecondaryGrey: '#35618b',
  darkerSecondaryGrey: '#37474F',
  darkestSecondaryGrey: '#0F0F59', // Actually dark blue currently

  // blue-gray background used in dropdown links
  blueGrey: '#e7eef4',

  // Transparent greys are used when you need to overlay and darken the background color
  transparentWhite: 'rgba(231, 238, 244, .8)',
  transparentDarkGrey: 'rgba(55, 71, 79, .8)',
  transparentGrey: 'rgba(0, 0, 0, 0)',
  transparentLightestGrey: 'rgba(0,0,0,0.12)', // Replaces rgba(56, 65, 73, .1), hsla(0, 0%, 100%, 0) 10%, hsla(0, 0%, 100%, 0) 94%, rgba(0, 0, 0, .05), hsla(0, 0%, 100%, .3)
  transparentLighterGrey: 'rgba(0,0,0,0.2)', // Replaces rgba(93, 92, 92, .15)
  transparentLightGrey: 'rgba(0,0,0,0.3)',
};

export default Colors;
