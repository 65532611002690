import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Portal } from 'react-portal';
import {
  Button,
  Input,
  Icons,
  LoadingIndicator,
  NotificationBanner, Tooltip,
} from 'ui-core-ssgr';
import has from 'lodash/has';
import { generateUUID } from '../../../../shared/helpers/uuid';
import {
  PaddingWrapper,
  DivWithPaddingTop,
} from '../../../../shared/styles/spacing';
import {
  ButtonGroupBottom,
  LoadingContainer,
  GridLoadingWrapper,
  IconWrapper,
} from '../../../../shared/styles/styledComponents';
import {
  GroupNameInput,
  GroupDescriptionInput,
  InputLabelWrapper,
  InputLabelWrapperWithIcon,
  HierarchyInput,
  HierarchyInputNumber,
  HierarchyInputInnerWrapper,
  LoadingWrapper,
  StyledInput,
} from './styles';

export class BasicDetailsForm extends Component {
  state = {
    desc: '',
    error: new Map(),
    hierarchyError: new Map(),
    hierarchy: [],
    isButtonDisabled: true,
    name: '',
    regexp: [/^[a-zA-Z0-9-_ ]*$/, /^[a-zA-Z0-9]+$/],
    resetForm: false,
  };

  componentWillMount() {
    const { isCloningGroup, selectedRecord, addNotification } = this.props;

    this.setState({
      hierarchy: !isCloningGroup
        ? selectedRecord.hierarchy.filter(i => i).map(h => h)
        : [''],
      name: selectedRecord.name,
    });
    if (isCloningGroup) {
      addNotification({
        id: selectedRecord.id,
        type: 'neutral',
        msg: `Fill out a unique group name and combination of hierarchy.`,
      });
    }

    this.resetComponentState();
  }

  componentDidUpdate(prevProps) {
    const {
      isCloningGroup,
      isExpanded,
      selectedRecord,
      setIsCloningGroupState,
    } = this.props;

    if (
      prevProps.selectedRecord.id !== selectedRecord.id ||
      prevProps.isCloningGroup !== isCloningGroup ||
      prevProps.isExpanded !== isExpanded
    ) {
      this.resetComponentState();
      if (isCloningGroup && prevProps.selectedRecord.id !== selectedRecord.id) {
        setIsCloningGroupState(false);
      }
      if (!prevProps.isCloningGroup && isCloningGroup) {
        this.setState({
          hierarchy: selectedRecord.hierarchy.filter(i => i),
          hierarchyError: new Map(),
        });
      }
    }
  }

  componentWillUnmount() {
    const { deleteAllNotifications } = this.props;
    deleteAllNotifications();
  }

  is028Layout() {
    const { serverConfig } = this.props;
    return (
      serverConfig.clientLayoutId.substr(
        serverConfig.clientLayoutId.length - 3,
      ) === String('028')
    );
  }

  addHierarchy = () => {
    const hiState = this.state;
    const hierarchy = [...hiState.hierarchy, ''];
    const { hierarchyError } = this.state;
    const errorMsg = 'Must enter a minimum of 1 character.';
    hierarchyError.set(hierarchy.lastIndexOf(''), errorMsg);

    this.setState({
      hierarchyError,
      hierarchy,
    });
  };

  getIsButtonDisabledState = (
    input,
    val,
    selectedRecord,
    name,
    desc,
    hierarchy,
  ) => {
    let isButtonDisabled = true;
    const value = val && val.toUpperCase();
    const recordName = selectedRecord.name.toUpperCase();
    const recordDesc = selectedRecord.desc.toUpperCase();
    const nameUpper = name ? name.toUpperCase() : '';
    if (input === 'name')
      isButtonDisabled = value === recordName && recordDesc === desc;
    if (input === 'desc' && value.length === 0) isButtonDisabled = false;
    if (input === 'desc')
      isButtonDisabled = value === recordDesc && recordName === nameUpper;

    if (hierarchy !== null) {
      isButtonDisabled = !hierarchy.length >= 1;
    }
    if (selectedRecord.status.toLowerCase() === 'termed') {
      isButtonDisabled = true;
    }
    return isButtonDisabled;
  };

  generateRemoveHierarchyButton = (index, entry) => {
    const { isCloningGroup, selectedRecord } = this.props;
    const button = (
      <Button
        onClick={() => this.removeHierarchy(index, entry)}
        type="button"
        size="small"
        buttonType="diminished"
        disabled={selectedRecord.status.toLowerCase() === 'termed'}
        name="Remove"
      />
    );
    if (index !== 0 && selectedRecord.status !== 'Active') {
      return button;
    }
    if (index !== 0 && isCloningGroup) {
      return button;
    }
    return null;
  };

  onCancel = () => {
    const {
      deleteNotification,
      selectedRecord,
      setIsCloningGroupState,
      toggleDetailsView,
    } = this.props;
    toggleDetailsView();
    deleteNotification(selectedRecord.id);
    setIsCloningGroupState(false);
    this.resetComponentState();
  };

  onInputChange = (e, input, selectedRecord) => {
    const { name, desc } = this.state;
    const value = e.target.value.trim();
    const isButtonDisabled = this.getIsButtonDisabledState(
      input,
      value,
      selectedRecord,
      name,
      desc,
      null,
    );
    this.setState({
      [input]: value,
      isButtonDisabled,
    });
    this.validateFormInputs(input, value, selectedRecord);
  };

  onHierarchyInputChange = (e, index, selectedRecord) => {
    const value = e.target.value.trim();
    const { hierarchy } = this.state;
    hierarchy[index] = value;
    const isButtonDisabled = this.getIsButtonDisabledState(
      null,
      null,
      selectedRecord,
      null,
      null,
      hierarchy,
    );
    this.setState({
      hierarchy,
      isButtonDisabled,
    });
    this.validateFormInputs(index, value, selectedRecord);
  };

  onNotificationDismiss = id => {
    const { deleteNotification, notifications, selectedRecord } = this.props;
    if (
      notifications.has(selectedRecord.id) &&
      notifications.get(selectedRecord.id).type === 'negative'
    ) {
      this.resetComponentState();
    }
    deleteNotification(id);
  };

  onSave = () => {
    const {
      selectedRecord,
      triggerGroupsPutRequest,
      triggerGroupsPostRequest,
      groupsList,
      isCloningGroup,
    } = this.props;
    const { status } = groupsList;
    const { name, desc } = this.state;
    let { hierarchy } = this.state;
    const groupId = selectedRecord.id;
    hierarchy = hierarchy.filter(h => h);
    const triggerAllGroupsRequestParams = {
      offset: 0,
      sortOrder: 'desc',
      sortColumn: 'dateModified',
      status: !isCloningGroup ? status : 'all',
      queryValue: '',
    };
    if (!isCloningGroup) {
      triggerGroupsPutRequest({
        groupId,
        name,
        desc,
        hierarchy,
        selectedRecord,
        triggerAllGroupsRequestParams,
      });
    } else {
      triggerGroupsPostRequest({
        name,
        desc,
        hierarchy,
        groupId,
        isCloningGroup,
        triggerAllGroupsRequestParams,
      });
    }
    this.setState({ isButtonDisabled: true });
  };

  removeHierarchy = index => {
    const { hierarchyError } = this.state;
    let { hierarchy } = this.state;
    hierarchy = hierarchy.filter((h, i) => i !== index);
    const { stateHierarchy } = this.state;
    if (!hierarchy.length) hierarchy = stateHierarchy.slice(0, 1);
    if (hierarchyError.has(index)) hierarchyError.delete(index);
    this.setState({
      hierarchy,
      hierarchyError,
      isButtonDisabled: false,
    });
    this.validateHierarchyInputs(hierarchy);
  };

  resetComponentState = () => {
    const { isCloningGroup, selectedRecord } = this.props;
    const error = new Map();
    this.setState({
      error: !isCloningGroup
        ? error
        : error.set('name', 'Group name is required'),
      hierarchyError: new Map(),

      desc: !isCloningGroup ? selectedRecord.desc : '',
      hierarchy: selectedRecord.hierarchy.filter(i => i).map(h => h),

      isButtonDisabled: true,
      name: !isCloningGroup ? selectedRecord.name : '',
    });
  };

  validateFormInputs = (input, value) => {
    const { error, hierarchy, regexp } = this.state;
    const { length } = value;
    if ((input === 'name' || input === 'desc') && length < 3)
      error.set(input, 'Must enter a minimum of 3 characters.');
    else error.delete(input);
    if (input === 'name' || input === 'desc') {
      if (input === 'name' && length > 50) {
        if (length > 50) error.set(input, 'Must be 50 characters or less.');
        else error.delete(input);
      }
      if (input === 'desc') {
        if (length > 100) error.set(input, 'Must be 100 characters or less.');
      }
    }
    if (input !== 'desc' && input !== 'name')
      this.validateHierarchyInputs(hierarchy, regexp);
    if (length < 1 && (input === 'name' || input === 'desc')) {
      error.set(input, 'Must enter a minimum of 1 character.');
    }
    if (input === 'name' && length === 0)
      error.set(input, 'Group name is required.');
    if (input === 'desc' && length === 0) error.delete(input);
    if (regexp[0].test(value)) {
      if (!regexp[1].test(value.replace(/[_\-\s]/g, '')) && value.length >= 3) {
        error.set(input, 'Must contain at least one alphanumeric character.');
      }
    } else {
      error.set(
        input,
        'Must contain only alphanumerics, underscores, dashes and spaces.',
      );
    }
    this.setState({ error });
  };

  validateHierarchyInputs = hierarchy => {
    const { regexp } = this.state;
    const hierarchyError = new Map();
    const getDuplicates = arr => {
      const duplicates = {};
      for (let i = 0; i < arr.length; i += 1) {
        if (has(duplicates, arr[i])) {
          duplicates[arr[i]].push(i);
        } else if (arr.lastIndexOf(arr[i]) !== i) {
          duplicates[arr[i]] = [i];
        }
      }
      return duplicates;
    };
    const dupIndexes = [].concat(
      ...Object.values(getDuplicates(hierarchy.map(i => i.toLowerCase()))),
    );
    dupIndexes.forEach(index => {
      if (hierarchy[index].length >= 1) {
        hierarchyError.set(index, 'Must have a unique name for hierarchy');
      } else {
        hierarchyError.set(index, 'Must enter a minimum of 1 character.');
      }
    });
    hierarchy.forEach((h, index) => {
      if (h.length < 1) {
        hierarchyError.set(index, 'Must enter a minimum of 1 character.');
      }
      if (h.length > 20)
        hierarchyError.set(index, 'Must be 20 characters or less.');
      if (regexp[0].test(h)) {
        if (!regexp[1].test(h.replace(/[_\-\s]/g, '')) && h.length >= 3) {
          hierarchyError.set(
            index,
            'Must contain at least one alphanumeric character.',
          );
        }
      } else {
        hierarchyError.set(
          index,
          'Must contain only alphanumerics, underscores, dashes and spaces.',
        );
      }
    });

    this.setState({ hierarchyError });
  };

  render() {
    const {
      loading,
      isCloningGroup,
      notifications,
      selectedRecord,
    } = this.props;
    const {
      desc,
      error,
      hierarchy,
      hierarchyError,
      isButtonDisabled,
      name,
      resetForm,
    } = this.state;
    const is028Layout = this.is028Layout();

    return loading.size > 0 ? (
      <LoadingContainer>
        <GridLoadingWrapper>
          <LoadingIndicator
            domID="group-grid-loading-indicator"
            length="45px"
          />
        </GridLoadingWrapper>
      </LoadingContainer>
    ) : (
        <PaddingWrapper>
          {notifications.size > 0 && (
            <Portal key="basic-details-form">
              {notifications
                ? [...notifications].map(([key, value]) => (
                  <NotificationBanner
                    key={[key]}
                    domID={`${key}-notification`}
                    type={value.type}
                    text={value.msg}
                    onDismiss={() => this.onNotificationDismiss(key)}
                    autoDismiss
                    timer={1800000}
                    icon={value.type === 'neutral' ? 'Info' : null}
                  />
                ))
                : null}
            </Portal>
          )}
          <GroupNameInput>
            <InputLabelWrapperWithIcon>
              Group Name
            <Tooltip
                caretToAnchor={8}
                dataTestId="test-tooltip-groupName"
                domID="test-id"
                tooltipContent="A group contains an unique set of business requirements"
                tooltipPosition="top-center"
              >
                <div>
                  <IconWrapper>
                    <Icons.Info
                      domID="icon-id-Group-Name"
                      fillColor="#37474F"
                      title="A group contains an unique set of business requirements"
                    />
                  </IconWrapper>
                </div>
              </Tooltip>
            </InputLabelWrapperWithIcon>
            <Input
              key={resetForm}
              domID="group-name-id"
              disabled={selectedRecord.status.toLowerCase() === 'termed'}
              isHovered={false}
              errorMessage={error.has('name') ? error.get('name') : ''}
              hasError={error.has('name')}
              initialValue={name}
              maxLength={51}
              onChange={e => this.onInputChange(e, 'name', selectedRecord)}
            />
          </GroupNameInput>
          <DivWithPaddingTop>
            <GroupDescriptionInput>
              <InputLabelWrapper>Group Description</InputLabelWrapper>
              <Input
                domID="group-description-id"
                disabled={selectedRecord.status.toLowerCase() === 'termed'}
                isHovered={false}
                errorMessage={error.has('desc') ? error.get('desc') : ''}
                hasError={error.has('desc')}
                initialValue={desc}
                maxLength={101}
                onChange={e => this.onInputChange(e, 'desc', selectedRecord)}
              />
            </GroupDescriptionInput>
          </DivWithPaddingTop>
          <DivWithPaddingTop>
            <InputLabelWrapperWithIcon>
              Hierarchy
            <Tooltip
                caretToAnchor={8}
                dataTestId="test-tooltip-hierarchy"
                domID="HierarchyToolTip"
                tooltipContent="Group level indicators from your file."
                tooltipPosition="top-center"
              >
                <div>
                  <IconWrapper>
                    <Icons.Info
                      domID="icon-id-Hierarchy"
                      fillColor="#37474F"
                      title="Group level indicators from your file."
                    />
                  </IconWrapper>
                </div>
              </Tooltip>
            </InputLabelWrapperWithIcon>
            <HierarchyInput>
              {hierarchy.map((entry, index) => (
                <HierarchyInputInnerWrapper key={index + 1}>
                  <HierarchyInputNumber key={generateUUID()}>
                    {`${index + 1}.`}
                  </HierarchyInputNumber>
                  <StyledInput
                    key={index}
                    domID={`hierarchy-input-id${index}`}
                    disabled={
                      (selectedRecord.status === 'Active' && !isCloningGroup) ||
                      (index === 0 &&
                        is028Layout &&
                        hierarchy[0] === '_ABF_ALL_') ||
                      selectedRecord.status.toLowerCase() === 'termed'
                    }
                    isHovered={false}
                    errorMessage={
                      hierarchyError.has(index) ? hierarchyError.get(index) : ''
                    }
                    hasError={hierarchyError.has(index)}
                    initialValue={entry || ''}
                    onChange={e =>
                      this.onHierarchyInputChange(e, index, selectedRecord)
                    }
                  />
                  {index === 0 && (
                    <Button
                      onClick={() => this.addHierarchy()}
                      type="button"
                      size="small"
                      buttonType="diminished"
                      disabled={
                        hierarchy.length >= 6 ||
                        (selectedRecord.status === 'Active' && !isCloningGroup) ||
                        selectedRecord.status.toLowerCase() === 'termed'
                      }
                      name="+ Add Hierarchy"
                    />
                  )}
                  {this.generateRemoveHierarchyButton(index, entry)}
                </HierarchyInputInnerWrapper>
              ))}
            </HierarchyInput>
          </DivWithPaddingTop>
          <DivWithPaddingTop>
            <ButtonGroupBottom>
              <LoadingWrapper>
                {loading.has(selectedRecord.id) && (
                  <LoadingIndicator
                    domID="basic-details-form-loading-indicator"
                    length="30px"
                  />
                )}
              </LoadingWrapper>
              <Button
                onClick={() => this.onSave()}
                type="button"
                size="large"
                buttonType="emphasized"
                disabled={
                  isButtonDisabled ||
                  loading.has(selectedRecord.id) ||
                  error.size > 0 ||
                  hierarchyError.size > 0
                }
                name="Save"
              />
              <Button
                onClick={() => this.onCancel()}
                type="button"
                size="large"
                buttonType="diminished"
                disabled={loading.has(selectedRecord.id)}
                name="Cancel"
              />
            </ButtonGroupBottom>
          </DivWithPaddingTop>
        </PaddingWrapper>
      );
  }
}

BasicDetailsForm.propTypes = {
  groupsList: PropTypes.object.isRequired,
  isCloningGroup: PropTypes.bool.isRequired,
  loading: PropTypes.object.isRequired,
  notifications: PropTypes.object.isRequired,
  selectedRecord: PropTypes.object.isRequired,
  triggerGroupsPutRequest: PropTypes.func.isRequired,
  triggerGroupsPostRequest: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool,
  deleteAllNotifications: PropTypes.func,
  setIsCloningGroupState: PropTypes.func,
  toggleDetailsView: PropTypes.func,
  deleteNotification: PropTypes.func,
};

export default BasicDetailsForm;
