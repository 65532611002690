import styled from 'styled-components';
import { WordTag, DetailHeader } from 'ui-core-ssgr';
import { ButtonGroup } from '../../../../shared/components/ButtonGroup';

export const WordTagWidthSmall = styled(WordTag)`
  width: 40px;
  text-align: center;
  margin-right: 1px;
`;

export const GrayedOutWordTagSmall = styled(WordTagWidthSmall)`
  color: #fff;
  background-color: #e1e2e6;
`;

export const StyledBreadCrumb = styled.div`
  .hYooFO {
    padding-left: 0;
  }
  & a {
    padding-left: 0;
  }
`;
export const ButtonStatus = styled(ButtonGroup)`
  right: 1em;
  top: 6.5em;
  position: absolute;
  & button:last-child {
      margin-right: .5rem;
  `;

export const AdvancedDetailHeader = styled(DetailHeader)`
  h1 {
    font-size: 26px;
  }
  `;