
export const MANIFEST_ENTRY_MAIN_GRID={
  columns: new Set([
    {
      dataName: 'JobNumber',
      text: "Job Number",
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'MailDate',
      text: "Mail Date",
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'PkgCount',
      text: "Pkg Count",
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'Postage',
      text: "Postage",
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'Weight',
      text: "Weight",
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'USPSApprover',
      text: "USPS Approver",
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'ABFApprover',
      text: "ABF Approver",
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    }
  ]),
  initialSelectedItemIds: new Set([]),
  bookmarkedItemIds: new Set([]),
  domID: 'manifestEntryGrid',
  dataTestId: 'manifestEntryGrid',
  isConfigurable: true,
  supportSelection: true
};

export const SELECT_YES_NO_OPTIONS = [{label:'Yes',value: 1},{label: 'No',value: 0}]

export const USPSApprover_OPTIONS = [
  {label: '-- None --', value: ""},
  {label: 'Glenn Biermann', value: "Glenn Biermann"},
  {label: 'Mike Osborne', value: "Mike Osborne"},
  {label: 'Adonica Williams', value: "Adonica Williams"},
  {label: 'Patrice Smith', value: "Patrice Smith"},
  {label: 'Sonya Bequette', value: "Sonya Bequette"}
]

export const ABFApprover_OPTIONS = [
  {label: "-- None --", value:""},
  {label: "RDE", value: "RDE"},
  {label: "LVC", value: "LVC"},
  {label: "BCE", value: "BCE"},
  {label: "DEC", value: "DEC"},
  {label: "WJC", value: "WJC"},
  {label: "BES", value: "BES"},
  {label: "TWM", value: "TWM"},
  {label: "CMJ", value: "CMJ"},
  {label: "JPU", value: "JPU"},
  {label: "AMH", value: "AMH"},
  {label: "LAC", value: "LAC"},
]