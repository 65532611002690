const ASSOCIATE = 'associate';
const DISASSOCIATE = 'disassociate';

export const NO_BUTTONS = [];
export const ASSOCIATE_BUTTON = [ASSOCIATE];
export const DISASSOCIATE_BUTTON = [DISASSOCIATE];
export const detailProps = {
  columns: new Set([
    {
      dataName: 'name',
      text: 'Rule',
      sortable: true,
    },
    {
      dataName: 'status',
      text: 'Status',
      sortable: true,
    },
    { dataName: 'associated', text: 'Associated', sortable: true },
    {
      dataName: 'description',
      text: 'Description',
      sortable: true,
    },
    {
      dataName: 'userId',
      text: 'User Id',
      sortable: true,
    },
    {
      dataName: 'dateModified',
      text: 'Date Modified',
      sortable: true,
    },
  ]),
  isDragOver: '',
  selectedItemIds: new Set([]),
  selectionKey: 'id',
  bookmarkedItemIds: new Set([]),
  domID: 'detail-pane',
  supportSelection: true,
};
