import React from 'react';
import { Page } from '../../components';
import DashboardMain from './DashboardMain';
import DashboardDetail from './DashboardDetail';
import RouteWrapper from '../../components/RouteWrapper';

const Dashboard = () => (
  <RouteWrapper>
  <Page
    ListPaneContent={DashboardMain}
    DetailPaneContent={DashboardDetail}
  />
  </RouteWrapper>
);

export default Dashboard;
