/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useEffect, useCallback } from 'react';
import styled from 'styled-components/dist/styled-components'
import { useLayout } from 'ui-core/dist/useLayout';
import DetailPaneHeader from 'ui-core/dist/DetailPaneHeader';
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal, Button, ModalHeader, Input, ModalBody
} from 'ui-core/dist';
import { detailPaneAnimStates } from '../../commonResources/constants/animStates';
import ErrorPage from '../ErrorPage';
import LoadingComponent from '../../components/LoadingComponent';
import { CANNOT_DEACTIVATE_ADDRESS_ID, SELECT_RECORD_OPTIONS } from './Modules/constants';
import {
  useAddAddress,
  useReactivateAddress,
  useDeactivateAddress,
  useUpdateAddress,
  useGetAddress
} from "../../api/useAddressManager";
import { SelectDropdown } from '../../components'
import {resetManageAddressRecord } from './Modules/action';
import ModalWrapper from '../../components/Modal/components/ModalWrapper';
import useNotificationBannerHook from '../../useNotificationBannerHook';
import Authorizer from '../../containers/Authorizer';


const StyledModalWrapperWidth = styled(ModalWrapper)`
& .active-content {
  width: 30% !important;
}
`

const DeliveryAddressesDetail = () => {
  const {
    expandCollapseDetailPaneButtonConfig,
    closeDetailPaneButtonConfig,
    detailAnimationState,
    closeDetailPane,
    detailProps
  } = useLayout()

  const dispatch=useDispatch();
  const { notify } = useNotificationBannerHook();
  const [addressId, setaddressId] = useState('')
  const [name, setName] = useState('')
  const [line1, setLine1] = useState('')
  const [attention, setAttention] = useState('')
  const [line2, setLine2] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [zip, setZip] = useState('')
  const [isActive, setIsActive] = useState(false);
  const [status, setStatus] = useState({ label: 'Active', value: 'true' })
  const [sortOrder, setSortOrder] = useState({ label: '--none--', value: '' })
  const [description, setDescription] = useState('')
  const [editorMode, setEditorMode] = useState('Add')
  const [isOpenConfirmationModal, setisOpenConfirmationModal] = useState(false)
  const [confirmationModalMode, setconfirmationModalMode] = useState('')
  const [count, setcount] = useState(0)
  const [errorMsg, setErrorMsg] = useState([])

  const { ManageAddressRecord: records, sortOrders, ManageAddressMode: editorModep, resetControls }
    = useSelector(({ manageAddress }) => manageAddress);

  const { data: infoAddress, postData: getAddress, isLoading: isLoadingGetAddress,
    isError: isErrorGetAddress, error: errorGetAddress } = useGetAddress()

  const { data: infoInsertAddress, postData: insertAddress, metaData: insertAddressResponse, isLoading: isLoadingInsertAddress,
    isError: isErrorInsertAddress, error: errorInsertAddress } = useAddAddress()

  const { data: infoUpdateAddress, postData: updateAddress, metaData: updateAddressResponse, isLoading: isLoadingupdateAddress,
    isError: isErrorupdateAddress, error: errorupdateAddress } = useUpdateAddress()

  const { postData: reactivateAddress, metaData: reactivateddressResponse, isLoading: isLoadingReactivateAddress,
    isError: isErrorReactivateAddress, error: errorReactivateAddress } = useReactivateAddress()

  const { postData: deactivateAddress, metaData: deactivateddressResponse, isLoading: isLoadingDeactivateAddress,
    isError: isErrorDeactivateAddress, error: errorDeactivateAddress } = useDeactivateAddress()

  useEffect(() => {
    if (records) {
      setErrorMsg([])
      if (records.addressId && detailAnimationState!=detailPaneAnimStates.COLLAPSED) {
        getAddress({ pstrAddressId: records.addressId, pstrAddresstype: "D", count })
        setcount(count + 1)
      } else {
        onClearCurrentRecordValues()
      }
    }
  }, [records]);

  const onReset = useCallback(() => {
    if (infoAddress && infoAddress.resp && !detailProps?.isAdd) {
      const { addressId: addressIdp, name: namep, line1: line1p,
        attention: attentionp, line2: line2p, city: cityp,
        state: statep, isActive: isActivep, description: descriptionp,
        sortOrderId: sortOrderp, zip: zipp } = infoAddress.resp
      setaddressId(addressIdp)
      setDescription(descriptionp)
      setName(namep)
      setAttention(attentionp)
      setLine1(line1p)
      setCity(cityp)
      setLine2(line2p)
      setState(statep)
      setZip(zipp)
      setStatus({ label: isActivep === 'true' ? 'Active' : 'Inactive', value: isActivep === 'true' })
      setIsActive(isActivep === 'true')

      const filterdData = sortOrders.find(a => a.value === sortOrderp)
      if (filterdData?.value)
        setSortOrder({ label: filterdData.label, value: filterdData.value })
      else
        setSortOrder({ label: '--none--', value: '' })
    } else {
      onClearCurrentRecordValues();
    }
    setEditorMode(editorModep)
  }, [infoAddress, detailProps])

  useEffect(() => {
    onReset()
  }, [infoAddress])

  useEffect(() => {
    if (insertAddressResponse) {
      const { status: respStatus, statusText } = insertAddressResponse;
      if (respStatus === 200 || statusText === "OK") {
        if (infoInsertAddress && infoInsertAddress.fs) {
          const { fs } = infoInsertAddress;
          setErrorMsg([fs])
          return;
        }
        notify(`You have successfully added Delivery Address ${description}`, 'positive')
        dispatch(resetManageAddressRecord())
        closeDetailPane()
        if (resetControls) resetControls()
      }
    }
  }, [insertAddressResponse])

  useEffect(() => {
    if (updateAddressResponse) {
      const { status: respStatus, statusText } = updateAddressResponse;
      if (respStatus === 200 || statusText === "OK") {
        if (infoUpdateAddress && infoUpdateAddress.fs) {
          const { fs } = infoUpdateAddress;
          setErrorMsg([fs]);
          return;
        }
        notify(`You have successfully modified Delivery Address ${description}`, 'positive')
        dispatch(resetManageAddressRecord())
        closeDetailPane()
        if (resetControls) resetControls()
      }
    }
  }, [updateAddressResponse])

  useEffect(() => {
    if (deactivateddressResponse) {
      const { status: respStatus, statusText } = deactivateddressResponse;
      if (respStatus === 200 || statusText === "OK") {
        if (deactivateAddress && deactivateAddress.fs) {
          const { fs } = deactivateAddress;
          notify(fs, 'negative');
          return;
        }
        notify(`You have successfully deactivated Delivery Address ${description}`, 'positive')
        dispatch(resetManageAddressRecord())
        closeDetailPane()
        if (resetControls) resetControls()
      }
    }
  }, [deactivateddressResponse, deactivateAddress])

  useEffect(() => {
    if (reactivateddressResponse) {
      const { status: respStatus, statusText } = reactivateddressResponse;
      if (respStatus === 200 || statusText === "OK") {
        if (reactivateAddress && reactivateAddress.fs) {
          const { fs } = reactivateAddress;
          notify(fs, 'negative');
          return;
        }
        notify(`You have successfully reactivated Delivery Address ${description}`, 'positive')
        dispatch(resetManageAddressRecord())
        closeDetailPane()
        if (resetControls) resetControls()
      }
    }
  }, [reactivateddressResponse, reactivateAddress])

  const onDeactivateClick = () => {
    if (editorMode === "Edit") {
      if (isActive)
        setconfirmationModalMode("Deactivate")
      else
        setconfirmationModalMode("Reactivate")
      setisOpenConfirmationModal(true)
    }
  }

  const isFormChanged = () => {
    const { name: namep, line1: line1p,
      attention: attentionp, line2: line2p, city: cityp,
      state: statep, isActive: isActivep, description: descriptionp,
      sortOrder: sortOrderp, zip: zipp } = infoAddress.resp

    if (
      description === descriptionp &&
      name === namep &&
      attention === attentionp &&
      line1 === line1p &&
      line2 === line2p &&
      city === cityp &&
      state === statep &&
      zip === zipp &&
      status?.value?.toString() === isActivep?.toString() &&
      (sortOrder?.value?.toString() === sortOrderp?.toString() || (sortOrder?.value === '' && !sortOrderp))
    ) {
      return false;
    }
    return true;
  };

  const onSubmitClick = () => {
    const detailPage = document.getElementById('DeliveryAddressDetail');
    const parentNode = detailPage.parentNode;
    const parentRoot = parentNode.parentNode;
    const errors = [];

    if (editorMode === "Edit" && !isFormChanged()) {
      errors.push("You have not changed any field, you cannot continue until you make a change.")
    }
    if (errors && errors.length) {
      parentRoot.scrollTo(0, 0)
      setErrorMsg(errors);
      return false;
    }

    if (editorMode === "Add") {
      setconfirmationModalMode("Add")
    } else if (editorMode === "Edit") {
      setconfirmationModalMode("Modify")
    }
    setisOpenConfirmationModal(true)
    setErrorMsg([])
  }

  const onConfirmationYesClick = () => {
    // if (editorMode === 'Edit') {
      
    // }
    let recordsRequest={};
    Object.keys(records).forEach((key)=>{
      if (['$','data'].indexOf(key)==-1) {
        recordsRequest[key]=JSON.parse(JSON.stringify(records[key]));
      }
    });
    if (confirmationModalMode !== "Add" && Object.keys(recordsRequest).length==0) {
      return false;
    }
    if (confirmationModalMode === "Deactivate") {
      deactivateAddress({ addressId: records?.addressId, addressType: 'D', isActive, canModify: infoAddress?.resp?.canModify, count })
    } else if (confirmationModalMode === "Reactivate") {
      reactivateAddress({ addressId: records?.addressId, addressType: 'D', isActive, canModify: infoAddress?.resp?.canModify, count })
    } else if (confirmationModalMode === "Add") {
      insertAddress({
        ...recordsRequest,
        addressId: '',
        addressType: 'D',
        description,
        name,
        attention,
        line1,
        city,
        line2,
        state,
        zip,
        zip4: '',
        isActive: status.value,
        sortOrderId: sortOrder.value || '',
        coverPageSequence: '',
        imageName: '',
        returnStyle: '',
        altAddressId: '',
        intFlag: '',
        intAddr3: '',
        intCity: '',
        intState: '',
        intZip: '',
        intCountr: '',
        intPhone: '',
        intEmail: '',
        intRetPho: '',
        canModify: true,
        count
      })
    } else if (confirmationModalMode === "Modify") {
      updateAddress({
        ...recordsRequest,
        addressType: 'D',
        description,
        name,
        attention,
        line1,
        city,
        line2,
        state,
        zip,
        zip4: '',
        isActive: status.value,
        sortOrderId: sortOrder.value || '',
        imageName: '',
        returnStyle: '',
        altAddressId: '',
        intFlag: '',
        intAddr3: '',
        intCity: '',
        intState: '',
        intZip: '',
        intCountr: '',
        intPhone: '',
        intEmail: '',
        intRetPho: '',
        canModify: infoAddress?.resp?.canModify,
        count
      })
    }
    setconfirmationModalMode('')
    setisOpenConfirmationModal(false)
    setcount(count + 1)
  }

  const onClearCurrentRecordValues = () => {
    setaddressId('')
    setDescription('')
    setName('')
    setAttention('')
    setLine1('')
    setCity('')
    setLine2('')
    setState('')
    setZip('')
    setStatus({ label: 'Active', value: 'true' })
    setSortOrder({ label: '--none--', value: '' })
    setEditorMode('Add')
  }

  const onCopyCurrentRecordValues = () => {
    setaddressId('')
    setDescription('')
  }

  const renderDescription = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_renderaddressDescription"
      placeholder=""
      size="small"
      maxLength={50}
      initialValue={description}
      onChange={(e) => {
        setDescription(e.target.value || "")
      }}
    />
  ), [description])

  const renderName = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_renderName"
      placeholder=""
      size="small"
      maxLength={40}
      initialValue={name}
      onChange={(e) => {
        setName(e.target.value || "")
      }}
    />
  ), [name])

  const renderAttention = useMemo(() => (
    <div style={{ width: "200px" }}>
      <Input
        style={{ width: '200px' }}
        className="test-classname"
        domID="txt_renderAttention"
        placeholder=""
        size="small"
        maxLength={35}
        initialValue={attention}
        onChange={(e) => {
          setAttention(e.target.value || "")
        }}
      />
    </div>
  ), [attention])

  const renderLine1 = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_line1"
      placeholder=""
      size="small"
      maxLength={35}
      initialValue={line1}
      onChange={(e) => {
        setLine1(e.target.value || "")
      }}
    />
  ), [line1])

  const renderLine2 = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_line2"
      placeholder=""
      size="small"
      maxLength={35}
      initialValue={line2}
      onChange={(e) => {
        setLine2(e.target.value || "")
      }}
    />
  ), [line2])

  const renderCity = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_city"
      placeholder=""
      size="small"
      maxLength={20}
      initialValue={city}
      onChange={(e) => {
        setCity(e.target.value || "")
      }}
    />
  ), [city])

  const renderState = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_state"
      placeholder=""
      size="small"
      maxLength={2}
      initialValue={state}
      hasError={state ? !/^[a-zA-Z]{2}$/.test(state) : false}
      errorMessage='State must be exactly two letters long'
      onChange={(e) => {
        setState(e.target.value || "")
      }}
    />
  ), [state])

  const renderZip = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_zip"
      placeholder=""
      size="small"
      maxLength={5}
      hasError={zip ? !/^[0-9]{5}$/.test(zip) : false}
      errorMessage='ZIP must be exactly five digits long and may contain only numbers'
      initialValue={zip}
      onChange={(e) => {
        setZip(e.target.value || "")
      }}
    />
  ), [zip])

  const renderStatus = useMemo(() => (
    addressId === CANNOT_DEACTIVATE_ADDRESS_ID ? 'Active (Default Delivery Address cannot be deactivated)' :
      <SelectDropdown
        width="200px"
        domID="AddressStatus"
        label=""
        options={SELECT_RECORD_OPTIONS}
        size="small"
        initialValue={status}
        isClearable={false}
        disabled={true} // temp disabled till we find a perm solution
        onChange={(e) => {
          setStatus(e)
        }}
      />
  ), [status])

  const renderSortOrder = useMemo(() => (
    <SelectDropdown
      width="200px"
      domID="AddressSortOrder"
      label=""
      options={sortOrders}
      size="small"
      initialValue={sortOrder}
      isClearable={false}
      onChange={(e) => {
        setSortOrder(e)
      }}
    />
  ), [sortOrder, sortOrders])

  const validateForm = useMemo(() => {
    if (!name || !line1 || !city || !description
      || !/^[a-zA-Z]{2}$/.test(state) || !/^[0-9]{5}$/.test(zip)) {
      return false;
    }
    return true
  }, [description, name, line1,
    city, state, zip])

  const disabledDiactivateButton = useMemo(() => {
    if (editorMode === "Edit") {
      if (!records?.addressId) {
        return true;
      }
      return false;
    }
    return true;
  }, [editorMode, records])
  const renderDeliveryAddressForm = useMemo(() => {
    if (detailAnimationState > detailPaneAnimStates.COLLAPSED) {
      return (
        <div id='DeliveryAddressDetail' style={{ marginLeft: '40px' }} className="marginLeft">
          <div>
            {errorMsg && errorMsg.length ?
              <p>
                <div className="displayFlex" style={{ color: 'red' }}>There were errors processing your request:</div>
                {
                  errorMsg.map(err => {
                    return (
                      <div className="displayFlex"><span style={{ color: 'red', margin: '0 20px' }} dangerouslySetInnerHTML={{ __html: err }}></span></div>
                    )
                  })
                }
              </p>
              : ''
            }
            <p>
              <div className="displayFlex">Make any necessary changes to the Delivery Address, and then click Submit to continue.</div>
            </p>
            <p>
              <ul style={{ fontSize: '13px' }}>
                <li>
                  Only the first 28 characters of the Name will be printed.
                </li>
                <li>
                  The Description must be unique; it cannot match that of another Delivery Address, even if the address is inactive.
                </li>
              </ul>
            </p>
            <p>
              <div className="displayFlex"><span style={{ color: 'red' }}>* indicates required information</span></div>
            </p>
          </div>
          <br />
          <div className="displayFlex">
            <table className="common-table">
              <tr>
                <td>
                  <span> Address ID : </span>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {addressId}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <span> Description: <span style={{ color: 'red' }}>*</span> </span>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderDescription}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <span> Name: <span style={{ color: 'red' }}>*</span></span>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderName}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <span> Attention: </span>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderAttention}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <span> Address 1: <span style={{ color: 'red' }}>*</span></span>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderLine1}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <span> Address 2: </span>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderLine2}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <span> City: <span style={{ color: 'red' }}>*</span></span>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderCity}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <span> State: <span style={{ color: 'red' }}>*</span></span>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderState}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <span> ZIP Code: <span style={{ color: 'red' }}>*</span></span>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderZip}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <span> Status: </span>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '360px' }}>
                    {renderStatus}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <span> Sort Order: </span>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '400px' }}>
                    {renderSortOrder}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td colSpan="2">
                  <div className="displayFlex">
                  <Authorizer permission='CAN_PERFORM_ADDRESS_DELIVERY_ADD'>
                    <div className="marginLeft">
                      <Button
                        domID="btn_copy"
                        buttonType="standard"
                        size="small"
                        name="COPY"
                        disabled={editorMode === "Add"}
                        onClick={() => {
                          onCopyCurrentRecordValues()
                          setEditorMode('Add')
                        }}
                      />
                    </div>
                    </Authorizer>
                    <div className="marginLeft">
                      <Button
                        domID="btn_reset"
                        buttonType="standard"
                        size="small"
                        name="RESET"
                        // disabled={editorMode === "Add"}
                        onClick={() => {
                          onReset()
                        }}
                      />
                    </div>
                    <Authorizer permission='CAN_PERFORM_ADDRESS_DELIVERY_MODIFY'>
                    <div className="marginLeft">
                      <Button
                        domID="btn_diactivate"
                        buttonType="standard"
                        size="small"
                        name={isActive ? "DEACTIVATE" : 'REACTIVATE'}
                        disabled={disabledDiactivateButton}
                        onClick={() => onDeactivateClick()}
                      />
                    </div>
                    <div className="marginLeft">
                      <Button
                        domID="btn_search"
                        buttonType="standard"
                        size="small"
                        name="SUBMIT"
                        disabled={!validateForm}
                        onClick={() => onSubmitClick()}
                      />
                    </div>
                    </Authorizer>
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
            </table>
          </div>

          <Modal isOpen={isOpenConfirmationModal}>
            <ModalHeader
              title={`Do you want to ${confirmationModalMode} ?`}
              onClose={() => setisOpenConfirmationModal(false)}
            />
            <ModalBody>
              {isOpenConfirmationModal ? (
                <div style={{ marginLeft: '34px', marginBottom: '20px' }} className="marginLeft">
                  <div className="displayFlex">
                    <div className="marginLeft">
                      <Button
                        domID="btn_search"
                        buttonType="standard"
                        size="medium"
                        name="Yes"
                        onClick={() => onConfirmationYesClick()}
                      />
                    </div>
                    <div className="marginLeft">
                      <Button
                        domID="btn_search"
                        buttonType="standard"
                        size="medium"
                        name="No"
                        onClick={() => setisOpenConfirmationModal(false)}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
            </ModalBody>
          </Modal>
        </div>
      )
    }
    return null;
  }, [detailAnimationState, validateForm, editorMode, records,
    isOpenConfirmationModal, confirmationModalMode, addressId, name, line1, description,
    attention, line2, state, status, city,
    sortOrder, zip, infoAddress, errorMsg])

  return (
    <>
      <DetailPaneHeader
        title={editorMode === "Edit" ? "Modify Delivery Address" : "Add Delivery Address"}
        layoutControls={[
          expandCollapseDetailPaneButtonConfig,
          closeDetailPaneButtonConfig
        ]}
      />
      <>
        {isLoadingInsertAddress || isLoadingGetAddress
          || isLoadingDeactivateAddress || isLoadingReactivateAddress || isLoadingupdateAddress ? (
            // replace with a better loading component
            <div style={{ marginLeft: '50%' }}><LoadingComponent /></div>
          ) : isErrorInsertAddress || isErrorGetAddress
            || isErrorDeactivateAddress || isErrorReactivateAddress || isErrorupdateAddress ? (
              // replace with a better error component
              <ErrorPage
                // this prop isn't real but is an example
                error={errorInsertAddress || errorGetAddress
                  || errorDeactivateAddress || errorReactivateAddress || errorupdateAddress}
              />
            ) : (
              <>
                {renderDeliveryAddressForm}
              </>
            )
        }
      </>
    </>
  )
};

export default DeliveryAddressesDetail
