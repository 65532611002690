import React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import EmployerGroupManager from '../containers/SelfServiceGroups';

const EmployerRoutes = () => (
  <Switch>
    <Route path="/EmployerGroupManager" component={EmployerGroupManager} />
  </Switch>
)

export default EmployerRoutes;

