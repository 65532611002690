/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import styled from 'styled-components/dist/styled-components'
import { useLayout } from 'ui-core/dist/useLayout';
import DetailPaneHeader from 'ui-core/dist/DetailPaneHeader';
import { useSelector, useDispatch } from 'react-redux';
import {
  Modal, Button, ModalHeader, Input, ModalBody
} from 'ui-core/dist';
import { detailPaneAnimStates } from '../../../commonResources/constants/animStates';
import { SelectDropdown } from '../../../components'
import ErrorPage from '../../ErrorPage';
import LoadingComponent from '../../../components/LoadingComponent';
import { getXmlClaimTypeSaveUpdate } from '../Modules/action';
import useNotificationBannerHook from '../../../useNotificationBannerHook';
import { useSetRecipTypeStatus } from '../../../api/useClientAdministrationRecipientType'
import {
  useClaimTypeTranslationDetails,
  useGetSysClaimTypes,
  useAddClaimTypeTranslation,
  useUpdateClaimTypeTranslation,
  useDeleteClaimTypeTranslation
} from '../../../api/useClientAdministrationClaimType'
import Authorizer from '../../../containers/Authorizer';
const StyledDropDownContainer = styled.div`
width: 370px !important;
& #drpDeliveryAddress, & #drpDeliveryTypes, & #drpReleaseItems {
    width: 370px !important;
}
`
const ClaimTypeTranslationDetail = () => {
  const {
    expandCollapseDetailPaneButtonConfig,
    closeDetailPaneButtonConfig,
    detailAnimationState,
    closeDetailPane
  } = useLayout()

  const { notify } = useNotificationBannerHook();
  const [currentclaimType, setcurrentClaimType] = useState(null);
  const [currentnormalizedRecipientType, setcurrentnormalizedRecipientType] = useState(null);
  const [currentwebRecipientTypeDesc, setcurrentwebRecipientTypeDesc] = useState(null);

  const [normalizedRecipientTypeSource, setnormalizedRecipientTypeSource] = useState([])
  const [clientClaimTypeSource, setclientClaimTypeSource] = useState([])

  const [confirmationModalMode, setconfirmationModalMode] = useState('')
  const [isOpenConfirmationModal, setisOpenConfirmationModal] = useState(false)
  const [count, setcount] = useState(0)

  const { recipientTypeTranslationRecord, recipientTypeTranslationMode, refetchRecipientTypes }
    = useSelector(({ clientAdministration }) => clientAdministration);

  const currentLayout = useSelector(({ context }) => context?.context?.layout)
  useEffect(() => {
    if (recipientTypeTranslationRecord) {
      if (recipientTypeTranslationRecord.claimTypeTrans) {
        getShowMoreData(recipientTypeTranslationRecord.claimTypeTrans);
      } else {
        onClearCurrentRecordValues()
      }
      getRecipientTypes()
    }
  }, [recipientTypeTranslationRecord]);


  const getShowMoreData = (claimTypeTrans) => {
    postDataShowMoreInfo({ strClaimType: claimTypeTrans, count })
    setcount(count + 1)
  }

  const { showMoreInfo, postData: postDataShowMoreInfo, isLoading: isLoadingShowMoreInfo,
    isError: isErrorShowMoreInfo, error: errorShowMoreINfo } = useClaimTypeTranslationDetails()

  const { directPostCall: getsysRecipientTypes, isLoading: isLoadingsysRecipientTypes,
    isError: isErrorsysRecipientTypes, error: errorsysRecipientTypes } = useGetSysClaimTypes()

  const { data: infoAddRecipType, postData: addRecipientTypeTranslation, metaData: addClaimTypeTranslationResponse, isLoading: isLoadingaddRecipientTypeTranslation,
    isError: isErroraddRecipientTypeTranslation, error: erroraddRecipientTypeTranslation } = useAddClaimTypeTranslation()

  const { data: infoUpdateRecipType, postData: updateRecipientTypeTranslation, metaData: updateClaimTypeTranslationResponse, isLoading: isLoadingupdateRecipientTypeTranslation,
    isError: isErrorupdateRecipientTypeTranslation, error: errorupdateRecipientTypeTranslation } = useUpdateClaimTypeTranslation()

  const { data: infoDeleteRecipType, postData: deleteRecipientTypeTranslation, metaData: deleteClaimTypeTranslationResponse, isLoading: isLoadingdeleteRecipientTypeTranslation,
    isError: isErrordeleteRecipientTypeTranslation, error: errordeleteRecipientTypeTranslation } = useDeleteClaimTypeTranslation()

  const onResetCurrentRecordValues = () => {
    if (showMoreInfo) {
      const { claimType, claimTypeDesc, claimTypeTrans } = showMoreInfo;
      setcurrentClaimType(claimTypeTrans)
      setcurrentnormalizedRecipientType(claimType)
      setcurrentwebRecipientTypeDesc(claimTypeDesc)
    }
  }
  const getRecipientTypes = async () => {

    const { data: data1 } = await getsysRecipientTypes({ tagname: 'ClaimTypes' });
    if (data1) {
      const { ClaimType } = data1;
      if (ClaimType) {
        let claimTypes = [];
        if (ClaimType.constructor.name === "Object") {
          claimTypes.push({ ...ClaimType })
        } else if (ClaimType.constructor.name === "Array") {
          claimTypes = [...ClaimType]
        }
        if (claimTypes && claimTypes.length > 0) {
          let r = [];
          if (currentLayout !== "028") {
            r = claimTypes.map((x) => ({ label: `${x.name}`, value: x.id }))
          } else {
            r = claimTypes.map((x) => ({ label: `${x.id} - ${x.description}`, value: x.id }))
          }
          setnormalizedRecipientTypeSource(JSON.parse(JSON.stringify(r)))
        }
      } else {
        setnormalizedRecipientTypeSource([])
      }
    } else {
      setnormalizedRecipientTypeSource([])
    }
    if (currentLayout !== "028") {
      const { data } = await getsysRecipientTypes({ parameters: "cvalue", tagname: 'ClientClaimTypes' })
      if (data) {
        const { ClientClaimType } = data;
        if (ClientClaimType) {
          let clientClaimTypes = [];
          if (ClientClaimType.constructor.name === "Object") {
            clientClaimTypes.push({ ...ClientClaimType })
          } else if (ClientClaimType.constructor.name === "Array") {
            clientClaimTypes = [...ClientClaimType]
          }
          if (clientClaimTypes && clientClaimTypes.length > 0) {
            const r = clientClaimTypes.map((x) => ({ label: `${x.description}`, value: x.id }))
            setclientClaimTypeSource(JSON.parse(JSON.stringify(r)))
          }
        } else {
          setclientClaimTypeSource([])
        }
      } else {
        setclientClaimTypeSource([])
      }
    }
  }

  useEffect(() => {
    if (addClaimTypeTranslationResponse) {
      const { status, statusText } = addClaimTypeTranslationResponse;
      if (status === 200 || statusText === "OK") {
        if (infoAddRecipType && infoAddRecipType.fs) {
          const { fs } = infoAddRecipType;
          if (fs) {
            notify(fs, 'negative')
            return;
          }
        }
        notify('Claim Type Added successfully', 'positive')
        setconfirmationModalMode('')
        closeDetailPane()
        if (refetchRecipientTypes) refetchRecipientTypes()
      }
    }
  }, [addClaimTypeTranslationResponse])

  useEffect(() => {
    if (updateClaimTypeTranslationResponse) {
      const { status, statusText } = updateClaimTypeTranslationResponse;
      if (status === 200 || statusText === "OK") {
        if (infoUpdateRecipType && infoUpdateRecipType.fs) {
          const { fs } = infoUpdateRecipType;
          if (fs) {
            notify(fs, 'negative')
            return;
          }
        }
        if (confirmationModalMode === "Deactivate") {
          notify(`Claim Type ${showMoreInfo?.isActive === "False" ? "Reactivated" : "Deactivated"} successfully`, 'positive')
        } else if (confirmationModalMode === "Modify") {
          notify('Claim Type Updated successfully', 'positive')
        }
        setconfirmationModalMode('')
        closeDetailPane()
        if (refetchRecipientTypes) refetchRecipientTypes()
      }
    }
  }, [updateClaimTypeTranslationResponse])

  useEffect(() => {
    if (deleteClaimTypeTranslationResponse) {
      const { status, statusText } = deleteClaimTypeTranslationResponse;
      if (status === 200 || statusText === "OK") {
        if (infoDeleteRecipType && infoDeleteRecipType.fs) {
          const { fs } = infoDeleteRecipType;
          if (fs) {
            notify(fs, 'negative')
            return;
          }
        }
        notify('Claim Type Deleted successfully', 'positive')
        setconfirmationModalMode('')
        closeDetailPane()
        if (refetchRecipientTypes) refetchRecipientTypes()
      }
    }
  }, [deleteClaimTypeTranslationResponse])

  const onDeactiveClick = () => {
    if (recipientTypeTranslationMode === "Edit") {

      setconfirmationModalMode("Deactivate")
      setisOpenConfirmationModal(true)
    }
  }
  const onDeleteClick = () => {
    if (recipientTypeTranslationMode === "Edit") {

      setconfirmationModalMode("Delete")
      setisOpenConfirmationModal(true)
    }
  }
  const onSubmitClick = () => {
    if (recipientTypeTranslationMode === "Add") {

      setconfirmationModalMode("Add")

    } else if (recipientTypeTranslationMode === "Edit") {

      setconfirmationModalMode("Modify")
    }
    setisOpenConfirmationModal(true)
  }
  const onConfirmationYesClick = () => {
    if (confirmationModalMode === "Delete") {
      deleteRecipientTypeTranslation({ strClaimType: showMoreInfo?.claimType, strRecipientTypeTrans: showMoreInfo?.claimTypeTrans, strIsActive: 'false', count })
      setcount(count + 1)

    } else if (confirmationModalMode === "Deactivate") {

      updateRecipientTypeTranslation({
        strNewClaimTypeInfoXml: getXmlClaimTypeSaveUpdate({
          currentclaimType: showMoreInfo?.claimTypeTrans,
          currentnormalizedRecipientType: showMoreInfo?.claimType,
          currentwebRecipientTypeDesc: showMoreInfo?.claimTypeDesc,
          isActive: showMoreInfo?.isActive === "False" ? 1 : 0
        }), strOldClaimTypeTrans: showMoreInfo?.claimTypeTrans, count
      })
      setcount(count + 1)

    } else if (confirmationModalMode === "Add") {
      addRecipientTypeTranslation({
        strClaimTypeInfoXml: getXmlClaimTypeSaveUpdate({
          currentclaimType, currentnormalizedRecipientType, currentwebRecipientTypeDesc, isActive: showMoreInfo?.isActive
        }), count
      })
      setcount(count + 1)

    } else if (confirmationModalMode === "Modify") {
      updateRecipientTypeTranslation({
        strNewClaimTypeInfoXml: getXmlClaimTypeSaveUpdate({
          currentclaimType, currentnormalizedRecipientType, currentwebRecipientTypeDesc, isActive: showMoreInfo?.isActive
        }), strOldClaimTypeTrans: showMoreInfo?.claimTypeTrans, count
      })
      setcount(count + 1)
    }
    setisOpenConfirmationModal(false)
  }
  useEffect(() => {
    if (showMoreInfo) {
      onResetCurrentRecordValues()
    }
  }, [showMoreInfo])


  const onClearCurrentRecordValues = () => {
    setcurrentClaimType('')
    setcurrentnormalizedRecipientType('')
    setcurrentwebRecipientTypeDesc('')
  }
  const renderCurrentRecipientType = useMemo(() => {
    if (currentLayout !== "028") {
      return (
        <SelectDropdown
          width="200px"
          domID="drp_clientClaimType"
          label=""
          options={clientClaimTypeSource}
          size="medium"
          initialValue={{ label: clientClaimTypeSource.find(x => x.value === currentclaimType)?.label, value: currentclaimType }}
          onChange={(e) => {
            if (e) {
              if (setcurrentClaimType) setcurrentClaimType(e.value)
            } else if (setcurrentClaimType) setcurrentClaimType(null)
            if (currentLayout !== "028") {
              if (e) {
                const label = normalizedRecipientTypeSource.find(x => x.value === e.value)?.label || '';
                if (label && label.indexOf('-') !== -1) {
                  const value = label.split('-')[0]
                  if (setcurrentnormalizedRecipientType) setcurrentnormalizedRecipientType(value)
                }

              } else if (setcurrentnormalizedRecipientType) setcurrentnormalizedRecipientType(null)
            }
          }}
        />
      )
    }
    return (
      <Input
        style={{ width: '200px' }}
        className="test-classname"
        domID="txt_clientClaimType"
        placeholder=""
        size="small"
        maxLength={30}
        initialValue={currentclaimType}
        onChange={(e) => {
          setcurrentClaimType(e.target.value || "")
        }}
      />
    )
  }, [currentclaimType, currentLayout, clientClaimTypeSource, normalizedRecipientTypeSource])

  const renderCurrentNormalizedRecipientType = useMemo(() => {
    if (currentLayout !== "028") {
      return (
        <SelectDropdown
          width="200px"
          domID="drpClaimTypes"
          disabled
          label=""
          options={normalizedRecipientTypeSource}
          size="medium"
          initialValue={{ label: normalizedRecipientTypeSource.find(x => x.value === currentclaimType)?.label, value: currentclaimType }}
          onChange={(e) => {
            if (e) {
              if (setcurrentClaimType) setcurrentClaimType(e.value)
            } else if (setcurrentClaimType) setcurrentClaimType(null)
            if (e) {
              if (setcurrentnormalizedRecipientType) setcurrentnormalizedRecipientType(e.value)
            } else if (setcurrentnormalizedRecipientType) setcurrentnormalizedRecipientType(null)
          }}
        />
      )
    }
    return (
      <SelectDropdown
        width="200px"
        domID="drpClaimTypes"
        label=""
        options={normalizedRecipientTypeSource}
        size="medium"
        initialValue={{ label: normalizedRecipientTypeSource.find(x => x.value === currentnormalizedRecipientType)?.label, value: currentnormalizedRecipientType }}
        onChange={(e) => {
          if (e) {
            if (setcurrentnormalizedRecipientType) setcurrentnormalizedRecipientType(e.value)
          } else if (setcurrentnormalizedRecipientType) setcurrentnormalizedRecipientType(null)
        }}
      />
    )
  }, [normalizedRecipientTypeSource, currentnormalizedRecipientType, currentclaimType, currentLayout])

  const renderCurrentWebRecipientTypeDesc = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_webRecipientTypeDesc"
      placeholder=""
      size="small"
      maxLength={30}
      initialValue={currentwebRecipientTypeDesc}
      onChange={(e) => {
        setcurrentwebRecipientTypeDesc(e.target.value || "")
      }}
    />
  ), [currentwebRecipientTypeDesc])
  const validateForm = useMemo(() => {
    if (currentLayout !== "028") {
      if (!currentclaimType || !clientClaimTypeSource.find(x => x.value) === currentclaimType) {
        return false;
      }
      if (!currentnormalizedRecipientType || !normalizedRecipientTypeSource.find(x => x.value) === currentnormalizedRecipientType) {
        return false;
      }
    } else {
      if (!currentclaimType) {
        return false;
      }
      if (!currentnormalizedRecipientType || !normalizedRecipientTypeSource.find(x => x.value) === currentnormalizedRecipientType) {
        return false;
      }
    }
    if (!currentwebRecipientTypeDesc) {
      return false;
    }
    return true;
  }, [currentclaimType, normalizedRecipientTypeSource, currentnormalizedRecipientType,
    currentwebRecipientTypeDesc, clientClaimTypeSource, currentLayout])

  const renderConfirmationMarkup = useMemo(() => {
    if (confirmationModalMode === "Deactivate") {
      return showMoreInfo?.isActive === "False" ? "Reactivate" : "Deactivate"
    }
    return confirmationModalMode;
  }, [isOpenConfirmationModal, confirmationModalMode]);

  const renderRecipientTypeEntryForm = useMemo(() => {
    if (detailAnimationState > detailPaneAnimStates.COLLAPSED) {
      return (
        <div style={{ marginLeft: '40px' }} className="marginLeft">
          <div>
            <p>
              <div className="displayFlex">Complete the information below, and then click Submit to continue.</div>
            </p>
            <p>
              <div className="displayFlex">Note: Claim type translation values are case-sensitive.</div>
            </p>
            <p>
              <div className="displayFlex"><span style={{ color: 'red' }}>Note: Other client claim types having the same normalized value will be updated with this web description.</span></div>
            </p>
            <p>
              <div className="displayFlex"><span style={{ color: 'red' }}>* indicates required information</span></div>
            </p>
          </div>
          <br />
          <div className="displayFlex">
            <table className="common-table">
              <tr>
                <td>
                  <label> Client Claim Type : <span style={{ color: 'red' }}>*</span></label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderCurrentRecipientType}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <label> Normalized Claim Type (Value-Description): <span style={{ color: 'red' }}>*</span></label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderCurrentNormalizedRecipientType}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <label> Web Claim Type Description: <span style={{ color: 'red' }}>*</span></label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderCurrentWebRecipientTypeDesc}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td colSpan="2">
                  <div className="displayFlex">
                    <div>
                      <Button
                        domID="btn_reset"
                        buttonType="standard"
                        size="small"
                        name="RESET"
                        disabled={recipientTypeTranslationMode !== "Edit"}
                        onClick={() => onResetCurrentRecordValues()}
                      />
                    </div>
                    <div className="marginLeft">
                      <Button
                        domID="btn_cancel"
                        buttonType="standard"
                        size="small"
                        name="CLEAR"
                        onClick={() => onClearCurrentRecordValues()}
                      />
                    </div>
                    <Authorizer permission ='CAN_PERFORM_CLIENTRULES_CTT_MODIFY'>
                      <>
                    <div className="marginLeft">
                      <Button
                        domID="btn_deactivate"
                        buttonType="standard"
                        size="small"
                        name={showMoreInfo?.isActive === "False" ? "REACTIVATE" : "DEACTIVATE"}
                        disabled={recipientTypeTranslationMode !== "Edit"}
                        onClick={() => onDeactiveClick()}
                      />
                    </div>
                    <div className="marginLeft">
                      <Button
                        domID="btn_delete"
                        buttonType="standard"
                        size="small"
                        name="DELETE"
                        disabled={recipientTypeTranslationMode !== "Edit"}
                        onClick={() => onDeleteClick()}
                      />
                    </div>
                    <div className="marginLeft">
                      <Button
                        domID="btn_search"
                        buttonType="standard"
                        size="small"
                        name="SUBMIT"
                        disabled={!validateForm}
                        onClick={() => onSubmitClick()}
                      />
                    </div>
                    </>
                    </Authorizer>
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
            </table>
          </div>
          <Modal isOpen={isOpenConfirmationModal}>
            <ModalHeader
              title={`Do you want to ${renderConfirmationMarkup} ?`}
              onClose={() => setisOpenConfirmationModal(false)}
            />
            <ModalBody>
              {isOpenConfirmationModal ? (
                <div style={{ marginLeft: '34px', marginBottom: '20px' }} className="marginLeft">
                  <div className="displayFlex">
                    <div className="marginLeft">
                      <Button
                        domID="btn_search"
                        buttonType="standard"
                        size="small"
                        name="Yes"
                        onClick={() => onConfirmationYesClick()}
                      />
                    </div>
                    <div className="marginLeft">
                      <Button
                        domID="btn_search"
                        buttonType="standard"
                        size="small"
                        name="No"
                        onClick={() => setisOpenConfirmationModal(false)}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
            </ModalBody>
          </Modal>
        </div>
      )
    }
    return null;
  }, [recipientTypeTranslationRecord, showMoreInfo, detailAnimationState, clientClaimTypeSource,
    currentclaimType, normalizedRecipientTypeSource, currentnormalizedRecipientType,
    currentwebRecipientTypeDesc, validateForm, recipientTypeTranslationMode, confirmationModalMode, isOpenConfirmationModal])

  return (
    <>
      <DetailPaneHeader
        title={recipientTypeTranslationMode === "Edit" ? "Modify Claim Type Translation" : "Add Claim Type Translation"}
        layoutControls={[
          expandCollapseDetailPaneButtonConfig,
          closeDetailPaneButtonConfig
        ]}
      />
      <>
        {isLoadingShowMoreInfo || isLoadingsysRecipientTypes || isLoadingaddRecipientTypeTranslation
          || isLoadingdeleteRecipientTypeTranslation || isLoadingupdateRecipientTypeTranslation ? (
            // replace with a better loading component
            <div style={{ marginLeft: '50%' }}><LoadingComponent /></div>
          ) : isErrorShowMoreInfo || isErrorsysRecipientTypes || isErroraddRecipientTypeTranslation
            || isErrordeleteRecipientTypeTranslation || isErrorupdateRecipientTypeTranslation ? (
              // replace with a better error component
              <ErrorPage
                // this prop isn't real but is an example
                error={errorShowMoreINfo || errorsysRecipientTypes || erroraddRecipientTypeTranslation
                  || errordeleteRecipientTypeTranslation || errorupdateRecipientTypeTranslation}
              />
            ) : (
              <>
                {renderRecipientTypeEntryForm}
              </>
            )
        }
      </>
    </>
  )
};

export default ClaimTypeTranslationDetail