import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Warning, LoadingIndicator } from 'ui-core-ssgr';
import ButtonGroup from '../../../../../shared/components/ButtonGroup';
import Colors from '../../../../../shared/styles/colors';
import { DivWithPaddingTop } from '../../../../../shared/styles/spacing';
import { 
  ModalPaddingWrapper, 
  BoldTextAfterWarningIcon, 
  IconWrapper, 
  ModalTitlePaddingWrapper, 
  StyledHeader,
} from '../../../../../shared/styles/styledComponents'; 
import { LoadingWrapper } from './styles';

class OverwriteRuleModal extends Component {
  confirmOverwriteRule = () => {
    const {
      selectedRecord,
      selectedRuleTypeId,
      overwriteTestRuleRequestTrigger,
    } = this.props;

    overwriteTestRuleRequestTrigger({
      ruleName: selectedRecord.name,
      ruleType: selectedRuleTypeId,
      ruleId: selectedRecord.id,
    });
  };

  cancelOverwriteRule = () => {
    const { setIsShowingOverwriteModal } = this.props;
    setIsShowingOverwriteModal(false);
  };

  onModalToggle = () => {
    const { setIsShowingOverwriteModal } = this.props;
    setIsShowingOverwriteModal(false);
  };

  render() {
    const { selectedRecord, loading } = this.props;

    return (
      <Modal
        domID="modal-id"
        onModalToggle={this.onModalToggle}
        isOpen
        showDefaultClose
        deferBodyControl={false}
      >
        <ModalPaddingWrapper>
          <ModalTitlePaddingWrapper>
            <StyledHeader>{`Overwrite Test Version with Live Rule: ${selectedRecord &&
              selectedRecord.name}`}</StyledHeader>
          </ModalTitlePaddingWrapper>
          <IconWrapper>
            <Warning fillColor={Colors.negativeDark} />
          </IconWrapper>
          <BoldTextAfterWarningIcon>
            Are you sure that you want to overwrite the Test version?
          </BoldTextAfterWarningIcon>
          <DivWithPaddingTop>
            <ButtonGroup>
              <LoadingWrapper>
                {loading.has('overwriteTestRuleLoading') && (
                  <LoadingIndicator
                    domID="overwrite-test-rule-modal-loading-indicator"
                    length="30px"
                  />
                )}
              </LoadingWrapper>
              <Button
                name="confirm"
                buttonType="emphasized"
                onClick={this.confirmOverwriteRule}
                size="medium"
                type="button"
                disabled={loading.size > 0}
              />
              <Button
                name="cancel"
                buttonType="diminished"
                onClick={this.cancelOverwriteRule}
                size="medium"
                type="button"
                disabled={loading.size > 0}
              />
            </ButtonGroup>
          </DivWithPaddingTop>  
        </ModalPaddingWrapper>
      </Modal>
    );
  }
}

OverwriteRuleModal.propTypes = {
  overwriteTestRuleRequestTrigger: PropTypes.func,
  setIsShowingOverwriteModal: PropTypes.func,
  selectedRecord: PropTypes.object,
  records: PropTypes.array,
};

export default OverwriteRuleModal;
