import { useEffect, useState } from 'react'
import { useAxios,useAxiosPost } from '../hooks'

const useTWExclusions = (props) => {
  const [totalRecordsResp, settotalRecordsResp]=useState(0)
  const [twExclusionData, setTwExclusionData]=useState([])

  const { data,postData, error, status, isLoading,isError, ...rest } = useAxiosPost({
    url: `twExclusionManager/getTWExclusions`,
    method:'POST',
    config:{
      'Content-Type':'application/json',
      'Accept':'*'
    },
    dispatchError:false
  })

  useEffect(()=>{
    if (data) {
      const {collection}=data;
      if (collection && collection.$) {
        const {totalRecords: totalRecordsp, type}=collection.$
        if (type) {
          let finalData;
          if (collection[type]) {
            finalData=collection[type]
          } else if (collection['tw-exclusion']){
            finalData=collection['tw-exclusion']
          }
          if (finalData) {
            settotalRecordsResp(totalRecordsp)  
            const sr=JSON.parse(JSON.stringify(finalData))  
            let searchResult=[]
            if (sr.constructor.name==="Array") {
              searchResult=[...sr]
            } else if(sr.constructor.name==="Object"){
              searchResult.push({...sr})
            }
            if (searchResult && searchResult.length>0) {
              setTwExclusionData(JSON.parse(JSON.stringify(searchResult)))  
            } else{
              setTwExclusionData(JSON.parse(JSON.stringify([])))  
            }
          } else{
            settotalRecordsResp(0);
            setTwExclusionData(JSON.parse(JSON.stringify([])))
          }
        } else{
          settotalRecordsResp(0);
          setTwExclusionData(JSON.parse(JSON.stringify([])))
        }
      }
    }
  },[data])

  return { twExclusionData, totalRecordsResp, getAll:postData, error, isLoading,isError, status, ...rest }
}
const useGetSelectedTerroristExclusionExclusion=()=>{
  const [showMoreInfo, setshowMoreInfo]=useState(null)
  const {postData, isLoading,isError,error, data }=useAxiosPost({
    url:'twExclusionManager/getTWExclusion',
    method:'POST',
    onError: (e) => 
      // what happens on error? if you need something extra
      // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  
  useEffect(()=>{
    if (data && data.resp) {
      setshowMoreInfo(JSON.parse(JSON.stringify(data.resp)))
    } else{
      setshowMoreInfo(null);
    }
  },[data])

  return {showMoreInfo, isLoading, isError, error, postData};
}

const useAddTerroristExclusion=()=>{
  const {postData, isLoading,isError,error, metaData, data }=useAxiosPost({
    url:'twExclusionManager/insertTWExclusion',
    method:'POST',
    onError: (e) => 
      // what happens on error? if you need something extra
      // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  

  return { isLoading, isError, error, postData, metaData, data};
}
const useModifyTerroristExclusion=()=>{
  const {postData, isLoading,isError,error,metaData, data }=useAxiosPost({
    url:'twExclusionManager/updateTWExclusion',
    method:'POST',
    onError: (e) => 
      // what happens on error? if you need something extra
      // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  

  return { isLoading, isError, error, postData, metaData, data};
}
export{useTWExclusions, useGetSelectedTerroristExclusionExclusion, useModifyTerroristExclusion, useAddTerroristExclusion}