import { useEffect, useState } from 'react'
import { useAxios, useAxiosPost } from '../hooks'

const useDatasourceRequest = (props={}) => {
  const [ dataSourceList, setDataSourceList ] = useState([]);
  const { data, error, status, postData,isError, isLoading,...rest } = useAxios({
    ...props,
    url: `reports-manager/datasources`,
    method: 'GET'
  });
  useEffect(() => {
    if (data) {
      const modifiedResult=  data.data.map(obj=> ({    label: obj.desc,value: obj.id}));
      modifiedResult.splice(0,0,{ label: "ALL",value: "ALL"})
      setDataSourceList(modifiedResult); 
    }
  }, [data]);
  return { dataSourceList, data, error, status, postData,isError, isLoading,...rest }
};

const useReportClientLayoutsRequest = (props={}) => {
  const [ clientLayoutList, setClientLayoutList ] = useState([]);
  const { data, error, status, postData,isError, isLoading,...rest } = useAxios({
    ...props,
    url: `reports-manager/report-client-layouts`,
    method: 'GET'
  });
  useEffect(() => {
    if (data) {
      const modifiedResult=  data.data.map(obj=> ({label: obj.name,value: obj.id}));
      //  let modifiedResult=  [{
      //   label: "0007 - (028) SHERRY HESEK'S TEST CLIENT",
      //   value: "0007028"
      // },
      // {
      //   label: "0099 - (028) CHANGE HEALTHCARE PE TEST CLIENT",
      //   value: "0099028"
      // }
      // ];
    
      setClientLayoutList(modifiedResult); 
    }
  }, [data]);

  return { clientLayoutList, data, error, status, postData,isError, isLoading,...rest }
};


const useBillingReportAccessRequest = (props={}) => {
  const { data, error, status, postData,isError, isLoading,...rest } = useAxios({
    ...props,
    url: `reports-manager/billing-report-access`,
    method: 'GET'
  });
    
  return {  data, error, status, postData,isError, isLoading,...rest }
};


const useDownloadReportFile = () => {
  const [ file, setFile ] = useState('');
  const { data, status, error, isError, isLoading, isSuccess, refetch, postData, metaData } = useAxiosPost({
    url: `fileReceiptManager/downloadFile`,
    method: 'POST',
    responseType: 'blob',
    dispatchError: false
  });
  
  useEffect(() => {
    if (data) {
      setFile(data);
    }
  }, [data]);
  
  return { file, status, error, isError, isLoading, isSuccess, refetch, getDownloadFile: postData, metaData }
}
  
const useLayoutsRequest = (props={}) => {
  const [ layoutsList, setLayoutsList ] = useState([]);
  const { data, error, status, postData,isError, isLoading,...rest } = useAxios({
    ...props,
    url: `reports-manager/vendor-layouts`,
    method: 'GET'
  });
  useEffect(() => {
    if (data) {
      const modifiedResult = data.data.map(obj=> ({ label: obj.code, value: obj.layout}));
      setLayoutsList(modifiedResult); 
    }
  }, [data]);
  return { layoutsList, data, error, status, postData,isError, isLoading,...rest }
};  

const usePayerIdsRequest = (props={}) => {
  const [ payersList, setPayersList ] = useState([]);
  const { data, error, status, postData,isError, isLoading,...rest } = useAxios({
    ...props,
    url: `reports-manager/payerids`,
    method: 'GET'
  });
  useEffect(() => {
    if (data) {
      const modifiedResult = data.data.map(obj=> ({ label: obj.name, value: obj.id}));
      setPayersList(modifiedResult); 
    }
  }, [data]);
  return { payersList, data, error, status, postData,isError, isLoading,...rest }
}; 

const useStatesRequest = (props={}) => {
  const [ statesList, setStatesList ] = useState([]);
  const { data, error, status, postData,isError, isLoading,...rest } = useAxios({
    ...props,
    url: `reports-manager/states`,
    method: 'GET'
  });
  useEffect(() => {
    if (data) {
      const modifiedResult = data.data.map(obj=> ({ label: obj.name, value: obj.code}));
      modifiedResult.unshift({label: 'ALL', value: ''})
      setStatesList(modifiedResult); 
    }
  }, [data]);
  return { statesList, data, error, status, postData,isError, isLoading,...rest }
}; 
  
  
const useAvailableGenericReports = (props={}) => {
  const [ reportTypeList, setReportTypeList ] = useState([]);
  const { data, error, status, postData,isError, isLoading,...rest } = useAxiosPost({
    ...props,
    url: `reports-manager/getAvailableGenericReports`,
    method: 'POST'
  });
  useEffect(() => {
    if (data) {
      const modifiedResult = data.reportTypes.map(obj=>{ 
        if(obj && obj.name){
          let label = obj.name;
          if(label.indexOf("|") > -1){
            const ar = label.split('|');
            label = ar[0]
          }
          return { label, originalLabel:obj.name, value: obj.id}
        }
      });
      setReportTypeList(modifiedResult); 
    }
  }, [data]);
  return { reportTypeList, data, error, status, postData,isError, isLoading,...rest }
}; 

const useGenericReportWithDate = (props={}) => {
  const [ result, setResult ] = useState([]);
  const { data, error, status, metaData, postData,isError, isLoading,...rest } = useAxiosPost({
    ...props,
    url: `reports-manager/addGenericReportWithDate`,
  });
  useEffect(() => {
    if (data) {
      setResult(data); 
    }
  }, [data]);
  return { result, data, error, status,metaData, postData,isError, isLoading, ...rest }
};

export  {
  useDatasourceRequest, useStatesRequest, usePayerIdsRequest, useGenericReportWithDate, useAvailableGenericReports,
  useReportClientLayoutsRequest, useLayoutsRequest, useBillingReportAccessRequest, useDownloadReportFile
};
