/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { useLayout, Button } from 'ui-core/dist'
import { LoadingPage, CountDownTimer } from '../../../components';
import ErrorPage from '../../ErrorPage';
import {
  GENERIC_TEXT1,
  GENERIC_TEXT2,
  GENERIC_TEXT_ROUTE,
  GENERIC_WARNING_TEXT_ROUTE,
} from "../Modules/constants";
import withAxiosCancellation from '../../../components/withAxiosCancellation'
import ListPaneHeaderWrapper from '../../../components/ErrorDisplaySection/ListPaneHeaderWrapper';
import DocManagerFileGroupSearchForm from '../DocManagerFileGroupSearchForm';


import { useCurrentTime, useReleaseTime, useSearchFiles } from '../../../api/useDocumentManager';
import { setFileSearchParam, setFileSearchParamNull } from "../Modules/action";
import '../Modules/styles.scss';

const FileSearchRouteMain = ({getCancelSourceWithName}) => {
  const {
    expandCollapseListPaneButtonConfig
  } = useLayout()

  const dispatch = useDispatch();
  const history = useHistory();

  const [isResetFlag, setIsResetFlag] = useState(false);
  const [empGroupId, setEmpGroupId] = useState('')
  const [fileKey, setFileKeys] = useState('')
  const [sortOrder, setSortOrder] = useState({ label: 'Descending', value: true })
  const [sortColumn, setSortColumn] = useState({ label: 'File Key', value: 'filekey' })
  const [perPage, setchangeItemsPerPage] = useState({ label: 10, value: 10 })
  const [hasLimitExceededstate, sethasLimitExceededstate] = useState(false)
  const { currentTime, isError: isErrorCurrentTime, error: errorCurrentTime, isLoading: isLoadingCurrentTime } = useCurrentTime({getCancelSource:getCancelSourceWithName('searchRouteFilePageCurrentTime')});
  const { releaseTime, isError: isErrorReleaseTime, error: errorReleaseTime, isLoading: isLoadingReleaseTime } = useReleaseTime({getCancelSource:getCancelSourceWithName('searchRouteFilePageReleaseTime')});

  // const isLoading = false;
  // const isError = false;
  // const error = '';
  const [transactionMessage, settransactionMessage]=useState('')

  useEffect(()=>{
    if (history?.location && history?.location?.state) {
      settransactionMessage(history?.location?.state);
      history.replace({ ...history?.location, state: undefined });
    }
  },[history?.location])
  const onSelectEmpGroup = (items) => {
    let egroup = '';
    if (hasLimitExceededstate) {
      egroup = items && items.value ? items.value : ""
    } else if (items && items.length > 0 && items.indexOf('') === -1) {
      egroup = '<![CDATA[<EmpGroupIds>'
      items.forEach(item => {
        egroup += `<EmpGroupId>${item}</EmpGroupId>`
      })
      egroup += '</EmpGroupIds>]]>'
    }
    setEmpGroupId(egroup);
  }

  const nextClick = () => {
    const payload = {
      "maxRecords": perPage ? perPage.value : 10,
      "rowPosition": 0,
      "sortField": sortColumn ? sortColumn.value : '',
      "sortDescending": sortOrder ? sortOrder.value : true,
      "checkNumber": '',
      "claimNumber": '',
      "groupId": empGroupId ? empGroupId.value ? empGroupId.value : empGroupId : '',
      "idNumber": '',
      "filekey": fileKey,
      "showDuplicateChecks": true,
      "showProcessedDocuments": false
    }
    dispatch(setFileSearchParamNull(payload))
    dispatch(setFileSearchParam(payload))
    history.push(`/DocumentManager/Route/File/searchResults`)
  }

  return (
    <>
      <ListPaneHeaderWrapper
        title="Document Manager: Route Files"
        layoutControls={[expandCollapseListPaneButtonConfig]}

      />
      {isLoadingCurrentTime || isLoadingReleaseTime ? (
        // replace with a better loading component
        <LoadingPage />
      ) : isErrorCurrentTime || isErrorReleaseTime ? (
        // replace with a better error component
        <ErrorPage
          // this prop isn't real but is an example
          error={errorCurrentTime || errorReleaseTime}
        />
      ) : (
            <div className="mainWrapper">
              {releaseTime && currentTime ?
                <CountDownTimer
                  startDate={releaseTime}
                  endDate={currentTime}
                /> : ''}
              <div style={{ fontSize: "small", padding: "12px" }}>
                <i>{GENERIC_TEXT1}</i>
                <p>Document search finds documents that match the search options you complete, whether or not the documents have been processed. 
                  Complete a field on the basic search form or click Advanced Options to display additional search fields that you can complete to further refine your document search. 
                  Click Next to begin the search.</p>
                <div style={{ color: "#bf1616" }}>
                  <p>
                    {GENERIC_TEXT_ROUTE}
                  </p>
                  <p>
                    {GENERIC_WARNING_TEXT_ROUTE}
                  </p>
                  {transactionMessage && <p style={{ color: "black", fontWeight:'bold' }}>{transactionMessage}</p>}
                </div>
              </div>
              <DocManagerFileGroupSearchForm
              getCancelSourceWithName={getCancelSourceWithName}
                isCheckNumberExist={false}
                isClaimNumberExist={false}
                isSSNExist={false}
                onSelectEmpGroup={(value) => onSelectEmpGroup(value)}
                isSortingExist
                hasLimitExceeded={(value) => sethasLimitExceededstate(value)}
                isResetFlag={isResetFlag}
                setIsResetFlag={setIsResetFlag}
                changeFileKey={(value) => setFileKeys(value)}
                changeSortGridColumn={(v) => setSortColumn(v)}
                changeItemsPerPage={(v) => setchangeItemsPerPage(v)}
                perPage={perPage}
                changeSortGridOrder={(v) => setSortOrder(v)}
                nextClick={nextClick}
              />
              <div className="displayFlex marginLeft">
                <Button
                  buttonType="standard"
                  size="small"
                  name="RESET"
                  onClick={() => {
                    setIsResetFlag(true);
                  }}
                />
                <Button
                  buttonType="standard"
                  size="small"
                  style={{ marginLeft: '10px' }}
                  name="NEXT->"
                  onClick={() => nextClick()}
                />
              </div>
            </div>
          )}
    </>
  )
}

export default withAxiosCancellation(FileSearchRouteMain)
