import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Warning } from 'ui-core-ssgr';
import ButtonGroup from '../../../../shared/components/ButtonGroup';
import Colors from '../../../../shared/styles/colors';
import { DivWithPaddingTop } from '../../../../shared/styles/spacing';
import { 
  ModalPaddingWrapper, 
  BoldTextAfterWarningIcon, 
  IconWrapper, 
  ModalTitlePaddingWrapper, 
  StyledHeader,
} from '../../../../shared/styles/styledComponents';

class DetailCloneRuleModal extends React.Component {
  onButtonClick = name => {
    const {
      addNotification,
      isExpanded,
      resetDetailsPane,
      setIsCloningRuleState,
      setIsShowingDetailCloneRuleModal,
      toggleDetailsView,
      triggerRuleDetailsRequest,
      selectedRecord,
      selectedRuleTypeId,
      updateCurrentTab
    } = this.props;

    if (name === 'confirm') {
      if (!isExpanded) toggleDetailsView();
      resetDetailsPane();
      addNotification({
        id: 'cloneRule',
        icon: 'Info',
        type: 'neutral',
        msg: 'Fill out a unique rule name.',
      });
      // switch tab only when user in on rule side flow
      if(window.location.href.includes('ruletype')) updateCurrentTab({ currentTab: 0 });
      setIsCloningRuleState(true);
      const ruleVersion = this.getRuleVersionStatus(selectedRecord);
      triggerRuleDetailsRequest({
        ruleType: selectedRuleTypeId,
        ruleId: selectedRecord.id,
        ruleVersion,
      });
    }
    if (name === 'cancel') setIsCloningRuleState(false);
    setIsShowingDetailCloneRuleModal(false);
  };

  onModalToggle = () => {
    const {
      setIsShowingDetailCloneRuleModal,
      setIsCloningRuleState,
    } = this.props;
    setIsCloningRuleState(false);
    setIsShowingDetailCloneRuleModal(false);
  };

  // want live (Live Rule Tab)
  getRuleVersionStatus = record => {
    try {
      const { status, version } = record;
      const testVersion = version[status.indexOf('T')];
      const liveVersion = version[status.indexOf('L')];
      if (liveVersion) {
        return liveVersion;
      }
      if (testVersion) {
        return testVersion;
      }
    } catch (e) {}
    // if no test/live version or any error return null
    return null;
  };

  // if live version is different than test version, we want to let the user know it is copying the live details
  getRuleVersion = record => {
    try {
      const testVersion = record.status.length === 1;
      const liveVersion =
        record.status.length > 1 && record.status.includes('L');
      if (liveVersion) return 'live';
      if (testVersion) return '';
    } catch (e) {}
    return null;
  };

  render() {
    const { onButtonClick } = this;
    const { selectedRecord } = this.props;

    return (
      <Modal
        domID="modal-id"
        onModalToggle={() => this.onModalToggle()}
        isOpen
        showDefaultClose
        deferBodyControl={false}
      >
        <ModalPaddingWrapper>
          <ModalTitlePaddingWrapper>
            <StyledHeader>
              {`Clone Rule: ${selectedRecord && selectedRecord.name}`}
            </StyledHeader>
          </ModalTitlePaddingWrapper>
          <IconWrapper>
            <Warning fillColor={Colors.negativeDark} />
          </IconWrapper>
          <BoldTextAfterWarningIcon>
            {`This will copy all the rule details from the original ${this.getRuleVersion(
              selectedRecord,
            )} rule ${selectedRecord && selectedRecord.name}`}
          </BoldTextAfterWarningIcon>
          <DivWithPaddingTop>
            <ButtonGroup>
              <Button
                name="confirm"
                buttonType="emphasized"
                onClick={() => onButtonClick('confirm')}
                size="medium"
                type="button"
              />
              <Button
                name="cancel"
                buttonType="diminished"
                onClick={() => onButtonClick('cancel')}
                size="medium"
                type="button"
              />
            </ButtonGroup>
            </DivWithPaddingTop>
        </ModalPaddingWrapper>
      </Modal>
    );
  }
}

DetailCloneRuleModal.propTypes = {
  addNotification: PropTypes.func.isRequired,
  selectedRecord: PropTypes.object,
  setIsCloningRuleState: PropTypes.func.isRequired,
  setIsShowingDetailCloneRuleModal: PropTypes.func.isRequired,
  toggleDetailsView: PropTypes.func.isRequired,
};

export default DetailCloneRuleModal;
