import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { 
  LoadingIndicator,
  Button,
  Input,
  TextArea,
  Modal,
  DetailHeader
} from 'ui-core-ssgr';
import DropDownControl from '../ModalControls/DropDownControl/index';
import {
  ContainerBoxTextArea,
  CheckTextRuleInput,
  CheckTextImageWrapper,
  HeaderTitle,
  ImageFilter,
  SearchButtonImage,
  RuleTypesDetailRow
} from './styles';
import {
  DropOne,
  DropTwo,
  DropThree,
  ContainerBox,
  SignatureRuleDropDown,
} from '../SignatureRuleModal/styles';
import { 
  ButtonGroupBottom, 
  ReadOnlyTextTitle, 
  ReadOnlyTextArea 
} from '../../../shared/styles/styledComponents';
import {
  LoadingWrapper,
  ModalButton,
} from '../../RulesTabView/components/RuleDetails/RuleDetailsGridWrapper/styles';
import UploadAndViewImageControl from '../ModalControls/UploadAndViewImageControl';
import ImageControl from '../ModalControls/ImageControl';
import ImageLibraryModal from '../ImageLibraryModal';
import FileUpload from '../../../shared/components/FileUpload';
import CommitImageModal from '../CommitImageModal';
import searchIcon from '../../../shared/images/search-icon.png';

class CheckTextRuleModal extends React.Component {
  checkText = {
    claimType: 'default',
    recipientType: 'default',
    documentType: 'default',
    bankName: '',
    checkLeftText: '',
    bankLogo: '',
    bankLogoImageId: '',
    bankLogoImage: '',
    checkLogo: '',
    checkLogoImageId: '',
    checkLogoImage: '',
    voidMessage1: '',
    voidMessage2: '',
    checkHeader: '',
    signatureLabel1: '',
    signatureLabel2: '',
    signatureLabel3: '',
    fractionalCodeLeft: '',
    fractionalCodeTop: '',
    fractionalCodeBottom: '',
    signature1: '',
    signature2: '',
    signature3: '',
    error: { formError: true },
    selectedRecordIdImageLibrary: '0',
    selectedUploadViewConrolId: '',
    selectedUploadViewConrolData: [],
    mode:'',
    selectedDataToCommit: null,
    isTestedBankLogo: false,
    isTestedCheckLogo: false,
    searchHasError: '',
    searchErrorMessage: '',
    searchToggle:false,
    keyword: ''
  };

  state = this.checkText;

  componentDidMount() {
    this.getInitialValues();
  }

  componentDidUpdate(prevProps) {
    const {
      detailElements,
      isPostingRuleDetail,
      isShowingCheckTextRuleModal,
      postImageUploadSuccessId,
      postImageCommitSuccessId
    } = this.props;
    const {mode} = this.state;
    if (
      prevProps.detailElements !== detailElements ||
      prevProps.isShowingCheckTextRuleModal !== isShowingCheckTextRuleModal
    ) {
      this.getInitialValues();
      if (!isPostingRuleDetail) {
        this.setState({ error: { formError: true } });
      }
    }
    if(postImageUploadSuccessId &&
      postImageUploadSuccessId.imageId && 
      ( !prevProps.postImageUploadSuccessId ||
        (prevProps.postImageUploadSuccessId.imageId.imageId !== postImageUploadSuccessId.imageId.imageId)) &&
       mode === 'upload'){
      this.updateImageData();
    } 

    if(postImageCommitSuccessId &&
      postImageCommitSuccessId.imageId && 
      ( !prevProps.postImageCommitSuccessId ||
        (prevProps.postImageCommitSuccessId.isTested !== postImageCommitSuccessId.isTested) ||
        (prevProps.postImageCommitSuccessId.imageId !== postImageCommitSuccessId.imageId) ) ) {
      this.commitImageData();
    }
  }

  updateImageData = () => {
    const {postImageUploadSuccessId } = this.props;
    const {selectedUploadViewConrolId} = this.state;
    if(
      postImageUploadSuccessId && 
      postImageUploadSuccessId.convertedImageUploadData !== undefined && 
      postImageUploadSuccessId.convertedImageUploadData !== null ){
      if(selectedUploadViewConrolId === 'bankLogo'){
        this.setState({bankLogo:postImageUploadSuccessId.imageName, 
          bankLogoImageId: String(postImageUploadSuccessId.imageId.imageId),
          bankLogoImage: postImageUploadSuccessId.convertedImageUploadData.convertedImage
        });
      }
      if(selectedUploadViewConrolId === 'checkLogo'){
        this.setState({checkLogo:postImageUploadSuccessId.imageName, 
          checkLogoImageId: String(postImageUploadSuccessId.imageId.imageId),
          checkLogoImage: postImageUploadSuccessId.convertedImageUploadData.convertedImage
        });
      }
    }
  }

  commitImageData = () => {
    const {postImageCommitSuccessId } = this.props;
    const {selectedUploadViewConrolId, bankLogoImageId, checkLogoImageId} = this.state;
    if(postImageCommitSuccessId){
      if(selectedUploadViewConrolId === 'bankLogo'){
        if(postImageCommitSuccessId.imageId === checkLogoImageId){
          this.setState({
            isTestedCheckLogo: postImageCommitSuccessId.isTested,
          });
        }
        this.setState({
          isTestedBankLogo: postImageCommitSuccessId.isTested,
        });
      }
      if(selectedUploadViewConrolId === 'checkLogo'){
        if(postImageCommitSuccessId.imageId === bankLogoImageId){
          this.setState({
            isTestedBankLogo: postImageCommitSuccessId.isTested,
          });
        }
        this.setState({
          isTestedCheckLogo: postImageCommitSuccessId.isTested,
        });
      }
    }
  }

  getInitialValues = () => {
    const { detailElements, selectedRuleDetail, actionType } = this.props;
    let claimType = 'default';
    let recipientType = 'default';
    let documentType = 'default';
    this.setState({ ...this.checkText });
    if (!_.isEmpty(detailElements) &&
    !_.isEmpty(selectedRuleDetail) && actionType === 'Add a') {
      this.setState({...this.checkText})
    }
    if (
      !_.isEmpty(detailElements) &&
      !_.isEmpty(selectedRuleDetail) &&
      actionType !== 'Add a'
    ) { 
      if (actionType === 'Edit a' || actionType === 'Clone a') {
        if (detailElements.claimTypesList) {
          claimType = detailElements.claimTypesList.find(
            o => o.value === selectedRuleDetail.claimType,
          ).code;
        }
        if (detailElements.recipientTypesList) {
          recipientType = detailElements.recipientTypesList.find(
            o => o.value === selectedRuleDetail.recipientType,
          ).code;
        }
        if (detailElements.documentTypesList) {
          documentType = detailElements.documentTypesList.find(
            o => o.value === selectedRuleDetail.documentType,
          ).code;
        }
      }
      if (actionType === 'Read only') {
        if (detailElements.claimTypesList) {
          claimType = detailElements.claimTypesList.find(
            o => o.value === selectedRuleDetail.claimType,
          ).value;
        }
        if (detailElements.recipientTypesList) {
          recipientType = detailElements.recipientTypesList.find(
            o => o.value === selectedRuleDetail.recipientType,
          ).value;
        }
        if (detailElements.documentTypesList) {
          documentType = detailElements.documentTypesList.find(
            o => o.value === selectedRuleDetail.documentType,
          ).value;
        }
      }

      const {bankLogo, bankLogoImage, bankLogoImageId,
        checkLogo, checkLogoImage, checkLogoImageId} = selectedRuleDetail;
      
      const {isTestedBankLogo, isTestedCheckLogo} = selectedRuleDetail;

      this.setState({
        ...selectedRuleDetail,
        claimType,
        recipientType,
        documentType,
        checkLogo,
        checkLogoImage,
        checkLogoImageId,
        bankLogo,
        bankLogoImage, 
        bankLogoImageId,
        error: { formError: true },
        isTestedBankLogo,
        isTestedCheckLogo
      });
    } else {
      const {isTestedBankLogo, isTestedCheckLogo} = selectedRuleDetail;
      this.setState({
        ...this.checkText,
        error: {},
        isTestedBankLogo,
        isTestedCheckLogo
      });
    }
  };

  getRuleVersion = record => {
    try {
      const { status, version } = record;
      const testVersion = version[status.indexOf('T')];
      if (testVersion) return testVersion;
      const liveVersion = version[status.indexOf('L')];
      return liveVersion;
    } catch (e) {
      console.log(e);
    }
    return null;
  };

  populateDataDropDown = name => {
    const { detailElements, selectedRuleDetail } = this.props;
    if (!_.isEmpty(detailElements)) {
      if (detailElements) {
        let options = [];
        
        const selectedValue = selectedRuleDetail[name];

        const selectedList = name;

        const firstValue = detailElements[selectedList].find(x =>
          x.value.includes(selectedValue),
        );
        const arr = firstValue
          ? [...new Set([firstValue, ...detailElements[selectedList]])]
          : detailElements[selectedList];
        options = arr
          .map(r => ({
            label: r.value,
            value: r.code,
          }))
          .filter(v => v.value !== '');
        return options;
      }
    }
    return '';
  };

  cancelPressed = () => {
    const {
      setIsCloningRuleDetail,
      setIsPostingRuleDetail,
      setIsShowingCheckTextRuleModal,
      setIsReadOnlyRuleDetail
    } = this.props;
    setIsCloningRuleDetail(false);
    setIsPostingRuleDetail(false);
    setIsShowingCheckTextRuleModal(false);
    setIsReadOnlyRuleDetail(false);
    this.setState({ ...this.checkText });
  };

 
  onSave = () => {
    const {
      isCloningRuleDetail,
      isPostingRuleDetail,
      postRuleDetails,
      putRuleDetails,
      selectedRuleDetail,
      selectedRuleTypeId,
      selectedRecord,
      selectedRuleRecord,
      setIsCloningRuleDetail,
      setIsPostingRuleDetail,
    } = this.props;
    const {
      claimType,
      recipientType,
      documentType,
      bankName,
      checkLeftText,
      bankLogo,
      checkLogo,
      voidMessage1,
      voidMessage2,
      checkHeader,
      signatureLabel1,
      signatureLabel2,
      signatureLabel3,
      fractionalCodeLeft,
      fractionalCodeTop,
      fractionalCodeBottom,
    } = this.state;

    const ruleDetailId = selectedRuleDetail.id;
    const record = selectedRecord || selectedRuleRecord;
    const ruleId = record.id;
    const ruleVersion = this.getRuleVersion(record);
    const ruleType = selectedRuleTypeId;
    let ruleDetail = {
      claimType,
      recipientType,
      documentType,
      bankName,
      checkLeftText,
      bankLogo,
      checkLogo,
      voidMessage1,
      voidMessage2,
      checkHeader,
      signatureLabel1,
      signatureLabel2,
      signatureLabel3,
      fractionalCodeLeft,
      fractionalCodeTop,
      fractionalCodeBottom,
    };

    ruleDetail = isCloningRuleDetail
      ? { ...ruleDetail, ...{ ruleDetailIdFrom: ruleDetailId } }
      : ruleDetail;
    const {bankLogoImageId, checkLogoImageId}  = this.state;
    ruleDetail.bankLogo = bankLogoImageId || 'default';
    ruleDetail.checkLogo = checkLogoImageId || 'default';
    if (isPostingRuleDetail || isCloningRuleDetail) {
      postRuleDetails({ ruleDetail, ruleType, ruleId, ruleVersion });
      setIsCloningRuleDetail(false);
      setIsPostingRuleDetail(false);
    } else {
      putRuleDetails({
        ruleDetail,
        ruleType,
        ruleId,
        ruleVersion,
        ruleDetailId,
      });
    }
  };

  getTrimmedValue  = (val) => {
    if (val) return val.trim();
    return '';
  }
  
  checkUnsavedChanges = (event, type) => {
    const { error } = this.state;
    error[event.target.name] = false;
    if (type === 'alphaNumeric' && event.target.value) {
      const alphaNumeric = /^[a-z\d\-_\s]+$/i;
      if (!alphaNumeric.test(event.target.value)) {
        error[event.target.name] = `Please enter alphanumeric characters.`;
        this.setState({
          error,
        });
        return;
      }
    }
    if (
      event.target.maxLength &&
      event.target.value.length > event.target.maxLength - 1
    ) {
      error[event.target.name] = `Maximum characters allowed is ${event.target
        .maxLength - 1}.`;
      this.setState({
        error,
      });
      return;
    }

    const {
      isPostingRuleDetail,
      selectedRuleDetail,
      detailElements,
    } = this.props;
    error.formError = true;
    const stateValues = {};
    const typeDropDowns = {
      claimType: 'claimTypesList',
      recipientType: 'recipientTypesList',
      documentType: 'documentTypesList',
    };

    /** Please add the properties from the state list that doesn't rquire value change listening */
    const blacklist = [
      'error',
      'selectedRecordIdImageLibrary',
      'selectedUploadViewConrolId',
      'selectedUploadViewConrolData',
      'mode',
      'bankLogo',
      'bankLogoImage',
      'checkLogo',
      'checkLogoImage',
      'selectedDataToCommit',
      'isTestedBankLogo',
      'isTestedCheckLogo'
    ];

    const validationObject = _.cloneDeep(_.omit(this.checkText, blacklist));


    if (!isPostingRuleDetail) {
      _.forEach(validationObject, (value, input) => {
        let stateValue = this.state[input];
        if (input === event.target.name) {
          stateValue = event.target.value;
        }

        const selectedRecordValue = selectedRuleDetail[input] || '';
        const inputList = typeDropDowns[input];
        if (inputList && detailElements[inputList]) {
          const stateValueSelected = detailElements[inputList].find(
            o => o.code === stateValue,
          );
          if (stateValueSelected) {
            stateValue = stateValueSelected.value;
          }
          stateValues[input] = stateValue;
        }
        if (this.getTrimmedValue(stateValue) !== this.getTrimmedValue(selectedRecordValue)) {
          error[event.target.name] = false;
          error.formError = false;
        }
      });
    } else {
      error[event.target.name] = false;
      error.formError = false;
    }

    _.forEach(error, (value, name) => {
      if (!value) {
        delete error[name];
      }
    });
    const { isCloningRuleDetail } = this.props;

    if (isCloningRuleDetail) {
      if (
        selectedRuleDetail.claimType === stateValues.claimType &&
        selectedRuleDetail.documentType === stateValues.documentType &&
        selectedRuleDetail.recipientType === stateValues.recipientType
      ) {
        error.formError = true;
      }
    }
    if (event.target) {
      if (event.target.name) {
        this.setState({
          [event.target.name]: event.target.value,
          error,
        });
      }
    }
  };

  // called when image is selected from image library
  selectedImageControl = (e, actionType, uniqueId, imageId, imageName) => {
    const {handleImageAction} = this;
    handleImageAction(e, actionType, uniqueId, imageId, imageName);
    this.setState({selectedUploadViewConrolId: uniqueId, mode: actionType});
  };
  
  handleImageAction = (event, actionType, uniqueId, imageId, imageName) => {
    const {
      setIsShowingImageLibraryModal,
      setIsShowingImageUploadModal,
      selectedRuleDetail,
      selectedRuleTypeId,
      getRuleImages,
      getRuleImagesCount,
      setIsShowingCommitImageModal
    } = this.props;
    const selectedRecordIdImageLibrary =
      selectedRuleDetail[uniqueId] !== undefined
        ? selectedRuleDetail[uniqueId]
        : '0';
    if (actionType === 'library') {
      const offset = null;
      getRuleImages({selectedRuleTypeId, offset}); // to pull records
      getRuleImagesCount({selectedRuleTypeId}); // to pull count
      setIsShowingImageLibraryModal(
        selectedRuleTypeId,
        selectedRecordIdImageLibrary,
        true,
      );
      this.setState({keyword:''});
    } else if (actionType === 'remove') {
      if (uniqueId === 'bankLogo') {
        this.setState({bankLogo: '', bankLogoImage: '', bankLogoImageId: ''});
      } else if (uniqueId === 'checkLogo') {
        this.setState({checkLogo: '', checkLogoImage: '', checkLogoImageId: ''});
      }
      const actionTypeRuleModal = this.props.actionType;
      if (actionTypeRuleModal !== 'Clone a') {
        this.setState( {error: {}});
      }    
    }  else if (actionType === 'commit') {
      this.setState ({selectedDataToCommit: {imageName, imageId}});
      setIsShowingCommitImageModal({status:true, workflow:'ruledetail'});
    } else {
      setIsShowingImageUploadModal(
        selectedRuleTypeId,
        selectedRecordIdImageLibrary,
        true,
      );
    }
    this.setState({ selectedRecordIdImageLibrary });
  };

  // called when image is selected from image library
  selectedImageData = (e, imageKey, imageSrc, imageAltLabel, imageId, isTestedStatus) => {
    if (imageKey !== null && imageSrc !== null && imageAltLabel !== null) {
      const {
        selectedUploadViewConrolId,
      } = this.state;
      if (selectedUploadViewConrolId === 'bankLogo') {
        this.setState({
          bankLogo: imageAltLabel,
          bankLogoImage: imageSrc,
          bankLogoImageId: imageId,
          isTestedBankLogo: isTestedStatus
        });
      } else if (selectedUploadViewConrolId === 'checkLogo') {
        this.setState({
          checkLogo: imageAltLabel,
          checkLogoImage: imageSrc,
          checkLogoImageId: imageId,
          isTestedCheckLogo: isTestedStatus
        });
      } 
      const { actionType } = this.props;
      const {error} = this.state;
      if (actionType === 'Edit a' || actionType === 'Add a') {
        const {formError,...rest} = error;
        if (!_.size(rest)) this.setState( {error: {}});
      }
    }
  };

  // called when image library cancel button is clicked to close image library modal
  onCancelImageLibrary = () => {
    const { setIsShowingImageLibraryModal, resetRuleImagesData, selectedRuleTypeId} = this.props;
    resetRuleImagesData({selectedRuleTypeId});
    setIsShowingImageLibraryModal(false);
    this.setState({searchHasError:false, searchErrorMessage:''})
  };

  // called when image upload cancel button is clicked to close image upload modal
  onCancelImageUpload = () => {
    const { setIsShowingImageUploadModal } = this.props;
    setIsShowingImageUploadModal(false);
  };

  getKeyword = () => {
    return document.getElementById("image-filter").value;
  }
 
  userSearchWithinImages = () => {
    let {keyword} = this.state;
    const {searchToggle} = this.state;
    const checkValue = /^[a-zA-Z0-9-_ ]*$/;
    keyword = this.getKeyword();
    this.setState({searchToggle:!searchToggle});
    if (checkValue.test(keyword) && (keyword.length === 0 || keyword.length >= 3)) {
      this.setState({keyword})
      this.setState({searchHasError:false})
    } else {
      this.setState({searchHasError:true})
      this.setState({searchErrorMessage:'Please enter at least three valid characters - alphanumeric, space, - or _ is allowed.'})
    } 
    this.updateImageLibraryGrid();
  };

  updateImageLibraryGrid = () => {
    const {
      selectedRuleTypeId,
      getRuleImages,
      getRuleImagesCount,
      resetRuleImagesData
    } = this.props;
    let {keyword} = this.state;
    keyword = this.getKeyword();
    const checkValue = /^[a-zA-Z0-9-_ ]*$/;
    // only make call if valid search
    if(checkValue.test(keyword) && (keyword.length === 0 || keyword.length >= 3)) {
      resetRuleImagesData({selectedRuleTypeId});
      getRuleImages({selectedRuleTypeId, offset:null, keyword}); // to pull records
      getRuleImagesCount({selectedRuleTypeId, keyword}); // to pull count
    }
  }

  loadMoreRecords = (offset) => {
    const { selectedRuleTypeId, getRuleImages } = this.props
    const checkValue = /^[a-zA-Z0-9-_ ]*$/;
    const {keyword} = this.state;
    if (checkValue.test(keyword) && keyword.length >= 3) {
      getRuleImages({selectedRuleTypeId, offset, keyword}); // pull records w/keyword after valid search
    } else {
      getRuleImages({selectedRuleTypeId, offset}); // pull records  wo/keyword after invalid search
    }
  }

  handleAcceptImageUpload = (e) => {
    const { actionType } = this.props;
    const {error} = this.state;
    if (actionType === 'Edit a' || actionType === 'Add a') {
      const {formError,...rest} = error;
      if (!_.size(rest)) this.setState( {error: {}});
    }
  }

  render() {
    const {
      checkUnsavedChanges,
      selectedImageControl,
      selectedImageData,
      loadMoreRecords,
      onCancelImageLibrary,
      handleAcceptImageUpload,
      userSearchWithinImages,
      getKeyword
    } = this;
    const {
      claimType,
      recipientType,
      documentType,
      bankName,
      checkLeftText,
      voidMessage1,
      voidMessage2,
      checkHeader,
      signatureLabel1,
      signatureLabel2,
      signatureLabel3,
      fractionalCodeLeft,
      fractionalCodeTop,
      fractionalCodeBottom,
      error,
      selectedRecordIdImageLibrary,
      bankLogo,
      bankLogoImage,
      bankLogoImageId,
      checkLogo,
      checkLogoImage,
      checkLogoImageId,
      selectedDataToCommit,
      isTestedBankLogo,
      isTestedCheckLogo,
      searchHasError,
      searchErrorMessage,
      searchToggle
    } = this.state;
    const {
      actionType,
      loading,
      selectedRuleRecord,
      selectedRecord,
      isShowingImageLibraryModal,
      setIsShowingImageLibraryModal,
      selectedRuleTypeId,
      ruleImages,
      ruleImagesCount,
      convertImageUpload,
      convertedImageUploadData,
      convertedImageUploadError,
      postImageUpload,
      isShowingImageUploadModal,
      setIsShowingImageUploadModal,
      selectedRuleDetail,
      isShowingCommitImageModal,
    } = this.props;
    const ruleVersion = this.getRuleVersion(selectedRuleRecord);
    const recordId = selectedRuleRecord
      ? selectedRuleRecord.id
      : selectedRecord.id;

    const claimTypeOptions = this.populateDataDropDown('claimTypesList');
    const recipientTypeOptions = this.populateDataDropDown('recipientTypesList');
    const documentTypeOptions = this.populateDataDropDown('documentTypesList');

    const bankLogoImageKey = bankLogoImageId || null;
    const bankLogoImageSrc = bankLogoImage || null;
    const bankLogoImageAltLabel = bankLogo || ' ';
  
    const checkLogoImageKey = checkLogoImageId || null;
    const checkLogoImageSrc = checkLogoImage || null;
    const checkLogoImageAltLabel = checkLogo ||  ' ';

    // FOR READ ONLY CONDITIONAL RENDERING (Live Rule)
    let claimTypeReadOnly;
    let recipientTypeReadOnly;
    let documentTypeReadOnly;
    let bankInfoReadOnly;
    let checkLeftTextReadOnly;
    let voidMessage1ReadOnly;
    let voidMessage2ReadOnly;
    let checkHeaderReadOnly;
    let signatureLabel1ReadOnly;
    let signatureLabel2ReadOnly;
    let signatureLabel3ReadOnly;
    let fractionalCodeLeftReadOnly;
    let fractionalCodeTopReadOnly;
    let fractionalCodeBottomReadOnly;
    if (actionType === 'Read only') {
      claimTypeReadOnly = (claimType !== 'default') ? claimType : 'Any';
      recipientTypeReadOnly = (recipientType !== 'default') ? recipientType : 'Any';
      documentTypeReadOnly = (documentType !== 'default') ? documentType : 'Any';
      bankInfoReadOnly = (bankName !== '') ? bankName : '-';
      checkLeftTextReadOnly = (checkLeftText !== '') ? checkLeftText : '-';
      voidMessage1ReadOnly = (voidMessage1 !== '') ? voidMessage1 : '-';
      voidMessage2ReadOnly = (voidMessage2 !== '') ? voidMessage2 : '-';
      checkHeaderReadOnly = (checkHeader !== '') ? checkHeader : '-';
      signatureLabel1ReadOnly = (signatureLabel1 !== '') ? signatureLabel1 : '-';
      signatureLabel2ReadOnly = (signatureLabel2 !== '') ? signatureLabel2 : '-';
      signatureLabel3ReadOnly = (signatureLabel3 !== '') ? signatureLabel3 : '-';
      fractionalCodeLeftReadOnly = (fractionalCodeLeft !== '') ? fractionalCodeLeft : '-';
      fractionalCodeTopReadOnly = (fractionalCodeTop !== '') ? fractionalCodeTop : '-';
      fractionalCodeBottomReadOnly = (fractionalCodeBottom !== '') ? fractionalCodeBottom : '-';
    }

    return (
      <>
        {isShowingImageLibraryModal ? (
          <Modal
            domID="image-library-modal"
            isOpen={isShowingImageLibraryModal}
            onModalToggle={onCancelImageLibrary}
          >
            <RuleTypesDetailRow>
              <DetailHeader
                domID="header-title-image-library"
                title="Check Text Information Library"
                marginBottom={0}
              />
            </RuleTypesDetailRow>
            <ImageFilter
              domID="image-filter"
              onEnterPress={userSearchWithinImages}
              onButtonClick={userSearchWithinImages}
              placeholder="Search by Image Name"
              hasError={searchHasError}
              errorMessage={searchHasError ? searchErrorMessage : null}
              buttonContent={<SearchButtonImage alt="search" src={searchIcon} />}
            />
            <ImageLibraryModal
              loading={loading}
              ruleImages={ruleImages}
              ruleImagesCount={ruleImagesCount}
              searchToggle={searchToggle}
              getKeyword={getKeyword}
              setIsShowingImageLibraryModal={setIsShowingImageLibraryModal}
              selectedRuleDetail={selectedRuleDetail}
              selectedRecordIdImageLibrary={selectedRecordIdImageLibrary}
              selectedRuleTypeId={selectedRuleTypeId}
              selectedImageData={(e, imageKey, imageSrc, imageAltLabel, imageId, isTestedStatus) =>
                selectedImageData(e, imageKey, imageSrc, imageAltLabel, imageId, isTestedStatus)
              }
              loadMoreRecords={(offset) => loadMoreRecords(offset)}
              onCancelImageLibrary={onCancelImageLibrary}
            />
          </Modal>
        ) : null}
        {isShowingImageUploadModal ? (
          <Modal
            domID="image-upload-modal"
            isOpen={isShowingImageUploadModal}
            onModalToggle={this.onCancelImageUpload}
          >
            <HeaderTitle><DetailHeader
              domID="header-title-image-upload"
              title="Logo Upload"
              marginBottom={0}
            /></HeaderTitle>
            <center><span className="choose-caption">
              Choose your own logo and upload it. Your logo will be
              added to the library.
            </span></center>
            <FileUpload
              loading={loading}
              postImageUpload={postImageUpload}
              setIsShowingImageUploadModal={setIsShowingImageUploadModal}
              selectedRuleTypeId={selectedRuleTypeId}
              fileUploadMessage="Drag a jpg, png, jpeg, tiff, tif, gif, bmp file here. Max 2MB. Max dimensions 1280x720."
              acceptedFileTypes="jpg,png,jpeg,tiff,tif,gif,bmp"
              fileSizeAllowed={2106286} // bytes == 2.00 Mb
              allowMultiple={false}
              convertImageUpload={convertImageUpload}
              convertedImageUploadData={convertedImageUploadData}
              convertedImageUploadError={convertedImageUploadError}
              handleAcceptImageUpload={(e) =>
                handleAcceptImageUpload(e)
              }
            />
          </Modal>
        ) : null}
        {isShowingCommitImageModal !== undefined && isShowingCommitImageModal.status === true ? (
          <CommitImageModal
            {...this.props}
            workflow={isShowingCommitImageModal.workflow}
            selectedDataToCommit={selectedDataToCommit}
            ruleId={recordId}
            ruleVersion={ruleVersion}
          />
        ) : null}
        <ContainerBox>
          {actionType !== 'Read only' ? (
            <SignatureRuleDropDown>
              <DropOne>
                <DropDownControl
                  dropDownDomID="checktext-arr-claim-type"
                  dropDownLabel="claim type"
                  dropDownName="claimType"
                  dropDownOptions={claimTypeOptions}
                  dropDownInitialValue={claimType}
                  dropDownOnChangeHandle={checkUnsavedChanges}
                />
              </DropOne>
            </SignatureRuleDropDown>
          ) : <ReadOnlyTextTitle>Claim Type<ReadOnlyTextArea>{claimTypeReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
          {actionType !== 'Read only' ? (
            <SignatureRuleDropDown>
              <DropTwo>
                <DropDownControl
                  dropDownDomID="checktext-arr-recipient-type"
                  dropDownLabel="recipient"
                  dropDownName="recipientType"
                  dropDownOptions={recipientTypeOptions}
                  dropDownInitialValue={recipientType}
                  dropDownOnChangeHandle={checkUnsavedChanges}
                />
              </DropTwo>
            </SignatureRuleDropDown>
          ) : <ReadOnlyTextTitle>Recipient<ReadOnlyTextArea>{recipientTypeReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
          {actionType !== 'Read only' ? (
            <SignatureRuleDropDown>
              <DropThree>
                <DropDownControl
                  dropDownDomID="checktext-arr-document-type"
                  dropDownLabel="document type"
                  dropDownName="documentType"
                  dropDownOptions={documentTypeOptions}
                  dropDownInitialValue={documentType}
                  dropDownOnChangeHandle={checkUnsavedChanges}
                />
              </DropThree>
            </SignatureRuleDropDown>
          ) : <ReadOnlyTextTitle>Document Type<ReadOnlyTextArea>{documentTypeReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
        </ContainerBox>
        <ContainerBox>
          {actionType !== 'Read only' ? (
            <ContainerBoxTextArea>
              <TextArea
                domID="bankName"
                name="bankName"
                label="Bank Info"
                initialValue={bankName}
                placeholder="Example Bank
                123 Street
                City, State, 90000"
                disabled={false}
                readOnly={false}
                hasCounter
                onChange={e => checkUnsavedChanges(e)}
                maxLength={4001}
                hasError={!!error.bankName}
                errorMessage={error.bankName}
              />
            </ContainerBoxTextArea>
          ) : <ReadOnlyTextTitle>Bank Info<ReadOnlyTextArea>{bankInfoReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
        </ContainerBox>
        <ContainerBox>
          {actionType !== 'Read only' ? (
            <ContainerBoxTextArea>
              <TextArea
                domID="checkLeftText"
                name="checkLeftText"
                label="Check left text (Company payers address)"
                initialValue={checkLeftText}
                placeholder="Example Company/payer
                123 Street
                City, State, 90000"
                maxLength={4001}
                disabled={false}
                readOnly={false}
                onChange={e => checkUnsavedChanges(e)}
                hasError={!!error.checkLeftText}
                errorMessage={error.checkLeftText}
              />
            </ContainerBoxTextArea>
          ) : <ReadOnlyTextTitle>Check Left Text (Company Payers Address)<ReadOnlyTextArea>{checkLeftTextReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
        </ContainerBox>
        <ContainerBox>
          {actionType !== 'Read only' ? (
            <CheckTextImageWrapper>
              <UploadAndViewImageControl
                uniqueId="bankLogo"
                headerText="Bank Logo"
                libraryButtonClassName="library-link"
                libraryButtonText="Library"
                uploadButtonClassName="upload-link"
                uploadButtonText="Upload"
                onClickHandleLibrary={e =>
                  selectedImageControl(e, 'library', 'bankLogo',bankLogoImageKey,bankLogoImageAltLabel)
                }
                onClickHandleUpload={e =>
                  selectedImageControl(e, 'upload', 'bankLogo',bankLogoImageKey,bankLogoImageAltLabel)
                }
                onClickHandleRemove={e =>
                  selectedImageControl(e, 'remove', 'bankLogo',bankLogoImageKey,bankLogoImageAltLabel)
                }
                onClickHandleCommit={e =>
                  selectedImageControl(e, 'commit', 'bankLogo',bankLogoImageKey,bankLogoImageAltLabel)
                }
                imageKey={bankLogoImageKey}
                imageSrc={bankLogoImageSrc}
                imageAltLabel={bankLogoImageAltLabel}
                isTested={isTestedBankLogo}
              />
            </CheckTextImageWrapper>
          ) : <ReadOnlyTextTitle>
            Bank Logo
            {bankLogo !== undefined ? (
              <ImageControl
                uniqueId="bankLogo"
                imageKey={bankLogoImageKey}
                imageSrc={bankLogoImageSrc}
                imageAltLabel={bankLogoImageAltLabel}
                isTested={isTestedBankLogo}
                imageStyles={{ marginRight: '2em', fontSize: '13px', fontWeight: 'normal', textTransform: 'none' }}
                imageOnClickHandle={() => {}}
                imageOnLoad={() => {}}
                imageOnError={() => {}}
                imageSelection={false}
              />
            ) : <ReadOnlyTextArea>-</ReadOnlyTextArea>}
          </ReadOnlyTextTitle>}
          {actionType !== 'Read only' ? (
            <CheckTextImageWrapper>
              <UploadAndViewImageControl
                uniqueId="checkLogo"
                headerText="Check Logo"
                libraryButtonClassName="library-link"
                libraryButtonText="Library"
                uploadButtonClassName="upload-link"
                uploadButtonText="Upload"
                onClickHandleLibrary={e =>
                  selectedImageControl(e, 'library', 'checkLogo',checkLogoImageKey,checkLogoImageAltLabel)
                }
                onClickHandleUpload={e =>
                  selectedImageControl(e, 'upload', 'checkLogo',checkLogoImageKey,checkLogoImageAltLabel)
                }
                onClickHandleRemove={e =>
                  selectedImageControl(e, 'remove', 'checkLogo',checkLogoImageKey,checkLogoImageAltLabel)
                }
                onClickHandleCommit={e =>
                  selectedImageControl(e, 'commit', 'checkLogo',checkLogoImageKey,checkLogoImageAltLabel)
                }
                imageKey={checkLogoImageKey}
                imageSrc={checkLogoImageSrc}
                imageAltLabel={checkLogoImageAltLabel}
                isTested={isTestedCheckLogo}
              />
            </CheckTextImageWrapper>
          ) : <ReadOnlyTextTitle>
            Check Logo
            {checkLogo !== undefined ? (
              <ImageControl
                uniqueId="checkLogo"
                imageKey={checkLogoImageKey}
                imageSrc={checkLogoImageSrc}
                imageAltLabel={checkLogoImageAltLabel}
                isTested={isTestedCheckLogo}
                imageStyles={{ marginRight: '2em', fontSize: '13px', fontWeight: 'normal', textTransform: 'none' }}
                imageOnClickHandle={() => {}}
                imageOnLoad={() => {}}
                imageOnError={() => {}}
                imageSelection={false}
              />
            ) : <ReadOnlyTextArea>-</ReadOnlyTextArea>}
          </ReadOnlyTextTitle>}
        </ContainerBox>
        <ContainerBox>
          {actionType !== 'Read only' ? (
            <CheckTextRuleInput>
              <DropOne>
                <Input
                  label="void message 1"
                  name="voidMessage1"
                  domID="voidMessage1"
                  disabled={false}
                  isHovered={false}
                  maxLength={51}
                  errorMessage={error.voidMessage1}
                  hasError={!!error.voidMessage1}
                  initialValue={String(voidMessage1)}
                  onChange={e => checkUnsavedChanges(e, 'alphaNumeric')}
                />
              </DropOne>
            </CheckTextRuleInput>
          ) : <ReadOnlyTextTitle>Void Message 1<ReadOnlyTextArea>{voidMessage1ReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
          {actionType !== 'Read only' ? (
            <CheckTextRuleInput>
              <DropOne>
                <Input
                  label="void message 2"
                  name="voidMessage2"
                  domID="voidMessage2"
                  disabled={false}
                  isHovered={false}
                  initialValue={String(voidMessage2)}
                  onChange={e => checkUnsavedChanges(e, 'alphaNumeric')}
                  maxLength={51}
                  errorMessage={error.voidMessage2}
                  hasError={!!error.voidMessage2}
                />
              </DropOne>
            </CheckTextRuleInput>
          ) : <ReadOnlyTextTitle>Void Message 2<ReadOnlyTextArea>{voidMessage2ReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
        </ContainerBox>

        <ContainerBox>
          {actionType !== 'Read only' ? (
            <CheckTextRuleInput>
              <DropOne>
                <Input
                  label="check header"
                  name="checkHeader"
                  domID="checkHeader"
                  disabled={false}
                  isHovered={false}
                  onChange={e => checkUnsavedChanges(e, 'alphaNumeric')}
                  maxLength={81}
                  errorMessage={error.checkHeader}
                  hasError={!!error.checkHeader}
                  initialValue={String(checkHeader)}
                />
              </DropOne>
            </CheckTextRuleInput>
          ) : <ReadOnlyTextTitle>Check Header<ReadOnlyTextArea>{checkHeaderReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
        </ContainerBox>

        <ContainerBox>
          {actionType !== 'Read only' ? (
            <CheckTextRuleInput>
              <DropOne>
                <Input
                  label="signature label 1"
                  name="signatureLabel1"
                  domID="signatureLabel1"
                  disabled={false}
                  isHovered={false}
                  initialValue={String(signatureLabel1)}
                  onChange={e => checkUnsavedChanges(e, 'alphaNumeric')}
                  maxLength={71}
                  errorMessage={error.signatureLabel1}
                  hasError={!!error.signatureLabel1}
                />
              </DropOne>
            </CheckTextRuleInput>
          ) : <ReadOnlyTextTitle>Signature Label 1<ReadOnlyTextArea>{signatureLabel1ReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
          {actionType !== 'Read only' ? (
            <CheckTextRuleInput>
              <DropOne>
                <Input
                  label="signature label 2"
                  name="signatureLabel2"
                  domID="signatureLabel2"
                  disabled={false}
                  isHovered={false}
                  initialValue={String(signatureLabel2)}
                  onChange={e => checkUnsavedChanges(e, 'alphaNumeric')}
                  maxLength={71}
                  errorMessage={error.signatureLabel2}
                  hasError={!!error.signatureLabel2}
                />
              </DropOne>
            </CheckTextRuleInput>
          ) : <ReadOnlyTextTitle>Signature Label 2<ReadOnlyTextArea>{signatureLabel2ReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
          {actionType !== 'Read only' ? (
            <CheckTextRuleInput>
              <DropOne>
                <Input
                  label="signature label 3"
                  name="signatureLabel3"
                  domID="signatureLabel3"
                  disabled={false}
                  isHovered={false}
                  initialValue={String(signatureLabel3)}
                  onChange={e => checkUnsavedChanges(e, 'alphaNumeric')}
                  maxLength={71}
                  errorMessage={error.signatureLabel3}
                  hasError={!!error.signatureLabel3}
                />
              </DropOne>
            </CheckTextRuleInput>
          ) : <ReadOnlyTextTitle>Signature Label 3<ReadOnlyTextArea>{signatureLabel3ReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
        </ContainerBox>
        <ContainerBox>
          {actionType !== 'Read only' ? (
            <CheckTextRuleInput>
              <DropOne>
                <Input
                  label="fractional Code left"
                  name="fractionalCodeLeft"
                  domID="fractionalCodeLeft"
                  disabled={false}
                  isHovered={false}
                  initialValue={String(fractionalCodeLeft)}
                  onChange={e => checkUnsavedChanges(e, 'alphaNumeric')}
                  maxLength={11}
                  errorMessage={error.fractionalCodeLeft}
                  hasError={!!error.fractionalCodeLeft}
                />
              </DropOne>
            </CheckTextRuleInput>
          ) : <ReadOnlyTextTitle>Fractional Code Left<ReadOnlyTextArea>{fractionalCodeLeftReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
          {actionType !== 'Read only' ? (
            <CheckTextRuleInput>
              <DropOne>
                <Input
                  label="fractional Code top"
                  name="fractionalCodeTop"
                  domID="fractionalCodeTop"
                  disabled={false}
                  isHovered={false}
                  initialValue={String(fractionalCodeTop)}
                  onChange={e => checkUnsavedChanges(e, 'alphaNumeric')}
                  maxLength={11}
                  errorMessage={error.fractionalCodeTop}
                  hasError={!!error.fractionalCodeTop}
                />
              </DropOne>
            </CheckTextRuleInput>
          ) : <ReadOnlyTextTitle>Fractional Code Top<ReadOnlyTextArea>{fractionalCodeTopReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
          {actionType !== 'Read only' ? (
            <CheckTextRuleInput>
              <DropOne>
                <Input
                  label="Fractional Code bottom"
                  name="fractionalCodeBottom"
                  domID="fractionalCodeBottom"
                  disabled={false}
                  isHovered={false}
                  initialValue={String(fractionalCodeBottom)}
                  onChange={e => checkUnsavedChanges(e, 'alphaNumeric')}
                  maxLength={11}
                  errorMessage={error.fractionalCodeBottom}
                  hasError={!!error.fractionalCodeBottom}
                />
              </DropOne>
            </CheckTextRuleInput>
          ) : <ReadOnlyTextTitle>Fractional Code Bottom<ReadOnlyTextArea>{fractionalCodeBottomReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
        </ContainerBox>

        <ButtonGroupBottom>
          <ModalButton>
            {loading.has(recordId) && (
              <LoadingWrapper>
                <LoadingIndicator
                  domID="basic-details-form-loading-indicator"
                  length="30px"
                />
              </LoadingWrapper>
            )}
            {actionType !== 'Read only' ? (
              <Button
                onClick={() => this.onSave()}
                domID="automation-id"
                name="Save"
                buttonType="emphasized"
                size="medium"
                isDropdown={false}
                type="button"
                disabled={loading.has(recordId) || !!_.size(error)}
              />
            ) : null }
            <Button
              domID="automation-id"
              name={actionType !== 'Read only' ? 'Cancel' : 'Close'}
              buttonType="diminished"
              disabled={loading.has(recordId)}
              size="medium"
              onClick={() => this.cancelPressed()}
              isDropdown={false}
              type="button"
            />
          </ModalButton>
        </ButtonGroupBottom>
      </>
    );
  }
}

CheckTextRuleModal.propTypes = {
  detailElements: PropTypes.object,
  loading: PropTypes.object,
  selectedRuleRecord: PropTypes.object,
  selectedRuleDetail: PropTypes.object,
  setIsCloningRuleDetail: PropTypes.func,
  setIsPostingRuleDetail: PropTypes.func,
  setIsShowingCheckTextRuleModal: PropTypes.func,
  isShowingCheckTextRuleModal: PropTypes.bool,
  isCloningRuleDetail: PropTypes.bool,
  isPostingRuleDetail: PropTypes.bool,
  postRuleDetails: PropTypes.func,
  putRuleDetails: PropTypes.func,
  isShowingImageLibraryModal: PropTypes.bool,
  setIsShowingImageLibraryModal: PropTypes.func,
  isShowingImageUploadModal: PropTypes.bool,
  setIsShowingImageUploadModal: PropTypes.func,
  postImageUpload: PropTypes.func,
  selectedRuleTypeId: PropTypes.string,
  selectedRecord: PropTypes.object,
  getRuleImages: PropTypes.func,
  ruleImages: PropTypes.object,
  getRuleImagesCount: PropTypes.func,
  ruleImagesCount: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  resetRuleImagesData: PropTypes.func,
  convertImageUpload: PropTypes.func,
  convertedImageUploadData: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  convertedImageUploadError: PropTypes.string,
  actionType: PropTypes.string,
  isShowingCommitImageModal: PropTypes.object,
  postImageUploadSuccessId: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  postImageCommitSuccessId: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
};
export default CheckTextRuleModal;