import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Title from './title';
import { Down } from 'ui-core/dist/icons';
import { Tooltip} from 'ui-core/dist';
import ClickableWrapper from './clickableWrapper';
import padding from '../helpers/padding';

export const SingleSelectGroupOpenClose = ({
  onClick = () => false,
  domID = null,
  paddingLeft = padding.navigationPaneHeader.left,
  isExpanded = false,
  title = '',
  wrapText = false,
  overflowTooltipPosition = 'right-center',
  overflowTooltipWidth = 200,
  dataTestId = '',
  menuItems = null,
  onMenuClick = () => false,
  isCreateGroupSubFolder = false,
  isEditing = false,
  onHoverReorder = () => false,
  keyForHoverReorder = '',
  className = '',
  selectItem = () => false,
  isLink = false,
  icon: Icon = null,
}) => {
  const titleRef = useRef(null);
  const [isAllTextVisible, setIsAllTextVisible] = useState(true);
  const [isTextWrapping, setIsTextWrapping] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const isEllipsisActive = (element) => {
    // scrollWidth and offsetWidth can be slightly off in IE, so I'm
    // giving this 1px of wiggle room. Otherwise it almost always returns true.
    return element.offsetWidth < element.scrollWidth - 1;
  };

  // check whether the text is truncated and enable the tooltip if it is
  useEffect(() => {
    if (titleRef.current) {
      setIsAllTextVisible(!isEllipsisActive(titleRef.current));
    } else {
      setIsAllTextVisible(true);
    }
  }, [title, wrapText]);

  // check whether the text is actually wrapping and adjust line-height
  useEffect(() => {
    if (titleRef.current && wrapText) {
      setIsTextWrapping(titleRef.current.offsetHeight > 30);
    } else {
      setIsTextWrapping(false);
    }
  }, [title, wrapText]);

  const testIDs = {
    wrapper: dataTestId ? `${dataTestId}-wrapper-single-select-item` : '',
    title: dataTestId ? `${dataTestId}-title` : '',
    overflowTooltip: dataTestId ? `${dataTestId}-overflow-tooltip` : '',
    downArrow: dataTestId ? `${dataTestId}-arrow` : '',
  };

  const withoutOverflowTooltip = () => (
    <ClickableWrapper
      id={domID}
      role="menuitem"
      data-testid={testIDs.wrapper}
      paddingLeft={paddingLeft}
      isTextWrapping={isTextWrapping}
      onMouseEnter={() => isEditing && onHoverReorder(keyForHoverReorder)}
      onMouseLeave={() => isEditing && onHoverReorder('')}
      isEditing={isEditing}
      className={className}
      isLink={isLink}
      onClick={(e) => onClick(e)}
    >
      <span style={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
        <Down
          className={isExpanded ? 'arrow expanded' : 'arrow'}
          size="small"
          dataTestId={testIDs.downArrow}
        />
        {Icon && (
          <div style={{ margin: '1.5px 10px 0px 0px' }}>
            <Icon size="small" />
          </div>
        )}
        <Title
          ref={titleRef}
          wrapText={wrapText}
          isAllTextVisible={isAllTextVisible}
          isTextWrapping={isTextWrapping}
          data-testid={testIDs.title}
        >
          {title}
        </Title>
      </span>
    </ClickableWrapper>
  );

  if (isAllTextVisible) return withoutOverflowTooltip();

  return (
    <Tooltip
      tooltipPosition={overflowTooltipPosition}
      tooltipWidth={overflowTooltipWidth}
      text={title}
      domID={testIDs.overflowTooltip}
      isButtonRoleDisabled
      style={{ overflow: 'hidden', width: '100%' }}
    >
      {withoutOverflowTooltip()}
    </Tooltip>
  );
};

SingleSelectGroupOpenClose.propTypes = {
  title: PropTypes.string.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  /** takes the click event as a parameter */
  onClick: PropTypes.func,
  domID: PropTypes.string,
  dataTestId: PropTypes.string,
  paddingLeft: PropTypes.number,
  /** if true, the title wraps instead of truncating if too long */
  wrapText: PropTypes.bool,
  overflowTooltipWidth: PropTypes.number,
  overflowTooltipPosition: PropTypes.oneOf([
    'top-center',
    'top-left',
    'top-right',
    'bottom-center',
    'bottom-left',
    'bottom-right',
    'left-up',
    'left-center',
    'left-down',
    'right-up',
    'right-center',
    'right-down',
  ]),
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string.isRequired,
      path: PropTypes.string,
      labelDecoration: PropTypes.string,
      isSecondary: PropTypes.bool,
    }),
  ),
  onMenuClick: PropTypes.func,
  isCreateGroupSubFolder: PropTypes.bool,
  isEditing: PropTypes.bool,
  onHoverReorder: PropTypes.func,
  selectItem: PropTypes.func,
  keyForHoverReorder: PropTypes.string,
  className: PropTypes.string,
  /**
   * Indicates whether the group label is clickable and should fire selectItem callback
   */
  isLink: PropTypes.bool,
  /** Valid React element type. eg icon={CircleCheck}. Should be a ui-core icon component */
  icon: PropTypes.elementType,
};

export default SingleSelectGroupOpenClose;
