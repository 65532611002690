import { createSelector } from 'reselect';
import {
  DEFAULT_RECORD_VALUES_BY_RECORD_TYPE,
  GRID_CONFIGS_BY_RECORD_TYPE,
} from './constants';

const selectruleTypesList = ({ ruleTypesList }) => ruleTypesList;

export const selectRenderSafeRecords = createSelector(
  selectruleTypesList,
  ({ records, selectedRuleTypeId }) => {
    const defaultValues =
      DEFAULT_RECORD_VALUES_BY_RECORD_TYPE[selectedRuleTypeId];

    records.forEach(record =>
      Object.keys(record).forEach(key => !record[key] && delete record[key]),
    );

    return records.map(record => ({
      ...defaultValues,
      ...record,
    }));
  },
);

export const selectGridConfig = createSelector(
  selectruleTypesList,
  ({ selectedRuleTypeId }) => GRID_CONFIGS_BY_RECORD_TYPE[selectedRuleTypeId],
);

export const selectCurrentRuleRecord = state => {
  const { selectedRuleRecordId } = state.rulesForRuleTypesList;
  return (
    selectedRuleRecordId &&
    state.rulesForRuleTypesList.records.find(
      element => element.id === selectedRuleRecordId,
    )
  );
};

export const selectCurrentRuleRecordId = state =>
  state.ruleTypesList.selectedRuleTypeId;
