import isEmpty from 'lodash/isEmpty';

// Header props
export const headerProps = {
  amountKey: 'amount',
  domID: 'header',
};

export const ACTION_BUTTONS = ({ isPostingRulesForRuleTypes, detailElements }) => [
  {
    name: 'add a detail',
    value: 'add',
    action: 'isPostingRuleDetail',
    header: 'add a',
    disabled: isPostingRulesForRuleTypes || isEmpty(detailElements)
  },
  {
    name: 'clone',
    value: 'clone',
    action: 'isCloningRuleDetail',
    header: 'clone a',
    disabled: isEmpty(detailElements)
  },
  {
    name: 'delete',
    value: 'delete',
    actionType: 'isDeletingRuleDetail',
    header: 'deleting',
    disabled: isEmpty(detailElements)
  }
];
