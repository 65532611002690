/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useEffect, useCallback } from 'react';
import styled from 'styled-components/dist/styled-components'
import { useLayout } from 'ui-core/dist/useLayout';
import DetailPaneHeader from 'ui-core/dist/DetailPaneHeader';
import { useSelector } from 'react-redux';
import {
  Modal, Button, ModalHeader, Input, ModalBody
} from 'ui-core/dist';
import { detailPaneAnimStates } from '../../commonResources/constants/animStates';
import ErrorPage from '../ErrorPage';
import LoadingComponent from '../../components/LoadingComponent';
import {
  useAddIneligibleCode,
  useUpdateIneligibleCode
} from "../../api/useClientRuleManager";
import ModalWrapper from '../../components/Modal/components/ModalWrapper';
import useNotificationBannerHook from '../../useNotificationBannerHook';

const StyledModalWrapperWidth = styled(ModalWrapper)`
& .active-content {
  width: 30% !important;
}
`

const IneligibleCodesDetails = () => {
  const {
    expandCollapseDetailPaneButtonConfig,
    closeDetailPaneButtonConfig,
    detailAnimationState,
    closeDetailPane
  } = useLayout()

  const { notify } = useNotificationBannerHook();
  const [ineligibleCode, setIneligibleCode] = useState('')
  const [editorMode, setEditorMode] = useState('Add')
  const [isOpenConfirmationModal, setisOpenConfirmationModal] = useState(false)
  const [confirmationModalMode, setconfirmationModalMode] = useState('')
  const [count, setcount] = useState(0)
  const [errorMsg, setErrorMsg] = useState([])

  const { selectedRecord: records, mode: editorModep, refetchRecord }
    = useSelector(({ clientRule }) => clientRule);

  const { data: infoInsertIneligibleCode, postData: insertIneligibleCode, metaData: insertIneligibleCodeResponse, isLoading: isLoadingInsertIneligibleCode,
    isError: isErrorInsertIneligibleCode, error: errorInsertIneligibleCode } = useAddIneligibleCode()

  const { data: infoUpdateIneligibleCode, postData: updateIneligibleCode, metaData: updateIneligibleCodeResponse, isLoading: isLoadingUpdateIneligibleCode,
    isError: isErrorUpdateIneligibleCode, error: errorUpdateIneligibleCode } = useUpdateIneligibleCode()

  useEffect(() => {
    if (records) {
      setErrorMsg([])
      if (records['inelgible-code']) {
        setIneligibleCode(records['inelgible-code'])
        setcount(count + 1)
      } else {
        onClearCurrentRecordValues()
      }
    }
    setEditorMode(editorModep)
  }, [records]);

  const onReset = useCallback(() => {
    if (records) {
      setIneligibleCode(records['inelgible-code'])
    } else {
      onClearCurrentRecordValues();
    }
    setEditorMode(editorModep)
  }, [records])

  useEffect(() => {
    if (insertIneligibleCodeResponse) {
      const { status: respStatus, statusText } = insertIneligibleCodeResponse;
      if (respStatus === 200 || statusText === "OK") {
        if (infoInsertIneligibleCode && infoInsertIneligibleCode.fs) {
          const { fs } = infoInsertIneligibleCode;
          if (fs.indexOf('Duplicate') !== -1) {
            setErrorMsg(['The Ineligible Code already exists'])
          } else {
            setErrorMsg([fs])
          } 
          return;
        }
        notify(`You have successfully added ineligible Code`, 'positive')
        closeDetailPane()
        if (refetchRecord) refetchRecord()
      }
    }
  }, [insertIneligibleCodeResponse])

  useEffect(() => {
    if (updateIneligibleCodeResponse) {
      const { status: respStatus, statusText } = updateIneligibleCodeResponse;
      if (respStatus === 200 || statusText === "OK") {
        if (infoUpdateIneligibleCode && infoUpdateIneligibleCode.fs) {
          const { fs } = infoUpdateIneligibleCode;
          if (fs.indexOf('Duplicate') !== -1) {
            setErrorMsg(['The Ineligible Code already exists'])
          } else {
            setErrorMsg([fs])
          } 
          return;
        }
        notify(`You have successfully modified ineligible Code`, 'positive')
        closeDetailPane()
        if (refetchRecord) refetchRecord()
      }
    }
  }, [updateIneligibleCodeResponse])

  const onSubmitClick = () => {
    if (editorMode === "Add") {
      setconfirmationModalMode("Add")
    } else if (editorMode === "Edit") {
      setconfirmationModalMode("Modify")
    }
    setisOpenConfirmationModal(true)
    setErrorMsg([])
    return '';
  }

  const onConfirmationYesClick = () => {   
    if (confirmationModalMode === "Add") {
      insertIneligibleCode({
        strIneligibleCode: ineligibleCode,
        count
      })
    } else if (confirmationModalMode === "Modify") {
      updateIneligibleCode({
        strOldIneligibleCode: records['inelgible-code'],
        strNewIneligibleCode: ineligibleCode,
        count
      })
    }
    setconfirmationModalMode('')
    setisOpenConfirmationModal(false)
    setcount(count + 1)
  }

  const onClearCurrentRecordValues = () => {
    setIneligibleCode('')
    setEditorMode('Add')
  }

  const renderIneligibleCode = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_renderName"
      placeholder=""
      size="small"
      maxLength={2}
      initialValue={ineligibleCode}
      onChange={(e) => {
        setIneligibleCode(e.target.value || "")
      }}
    />
  ), [ineligibleCode])

  const validateForm = useMemo(() => {
    if (!ineligibleCode) {
      return false;
    }
    return true
  }, [ineligibleCode])

  const renderIneligibleCodeForm = useMemo(() => {
    if (detailAnimationState > detailPaneAnimStates.COLLAPSED) {
      return (
        <div id='DeliveryAddressDetail' style={{ marginLeft: '40px' }} className="marginLeft">
          <div>
            {errorMsg && errorMsg.length ?
              <p>
                <div className="displayFlex" style={{ color: 'red', fontSize: '13px' }}>There were errors processing your request:</div>
                {
                  errorMsg.map(err => {
                    return (
                      <div className="displayFlex"><span style={{ color: 'red', margin: '0 20px' }} dangerouslySetInnerHTML={{ __html: err }}></span></div>
                    )
                  })
                }
              </p>
              : ''
            }
            <p>
              <div className="displayFlex">Complete the information below, and then click Submit to continue.</div>
            </p>
            <p>
              <div className="displayFlex"><span style={{ color: 'red' }}>* indicates required information</span></div>
            </p>
          </div>
          <br />
          <div className="displayFlex">
            <table className="common-table">
              <tr>
                <td>
                  <span> Ineligible Code: <span style={{ color: 'red' }}>*</span></span>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderIneligibleCode}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td colSpan="2">
                  <div className="displayFlex">
                    <div className="marginLeft">
                      <Button
                        domID="btn_reset"
                        buttonType="standard"
                        size="small"
                        name="RESET"
                        onClick={() => {
                          onReset()
                        }}
                      />
                    </div>
                    <div className="marginLeft">
                      <Button
                        domID="btn_search"
                        buttonType="standard"
                        size="small"
                        name="SUBMIT"
                        disabled={!validateForm}
                        onClick={() => onSubmitClick()}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
            </table>
          </div>

          <Modal isOpen={isOpenConfirmationModal} wrapperComponent={StyledModalWrapperWidth}>
            <ModalHeader
              title={`Do you want to ${confirmationModalMode} ?`}
              onClose={() => setisOpenConfirmationModal(false)}
            />
            <ModalBody>
              {isOpenConfirmationModal ? (
                <div style={{ marginLeft: '34px', marginBottom: '20px' }} className="marginLeft">
                  <div className="displayFlex">
                    <div className="marginLeft">
                      <Button
                        domID="btn_search"
                        buttonType="standard"
                        size="medium"
                        name="Yes"
                        onClick={() => onConfirmationYesClick()}
                      />
                    </div>
                    <div className="marginLeft">
                      <Button
                        domID="btn_search"
                        buttonType="standard"
                        size="medium"
                        name="No"
                        onClick={() => setisOpenConfirmationModal(false)}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
            </ModalBody>
          </Modal>
        </div>
      )
    }
    return null;
  }, [detailAnimationState, validateForm, editorMode, records,
    isOpenConfirmationModal, confirmationModalMode, ineligibleCode, errorMsg])

  return (
    <>
      <DetailPaneHeader
        title={editorMode === "Edit" ? "Modify Ineligible Code" : "Add Ineligible Code"}
        layoutControls={[
          expandCollapseDetailPaneButtonConfig,
          closeDetailPaneButtonConfig
        ]}
      />
      <>
        {isLoadingInsertIneligibleCode || isLoadingUpdateIneligibleCode ? (
        // replace with a better loading component
          <div style={{ marginLeft: '50%' }}><LoadingComponent /></div>
        ) : isErrorInsertIneligibleCode || isErrorUpdateIneligibleCode ? (
        // replace with a better error component
          <ErrorPage
            // this prop isn't real but is an example
            error={errorInsertIneligibleCode || errorUpdateIneligibleCode}
          />
        ) : (
          <>
            {renderIneligibleCodeForm}
          </>
        )
        }
      </>
    </>
  )
};

export default IneligibleCodesDetails
