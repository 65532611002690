import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Grid,
  useLayout,
  ListPaneHeader,
  Button,
} from 'ui-core/dist'
import { isAddMsg } from './modules/actions'
import useMessagesList from '../../api/useMessagesList'
import { Loading, SelectDropdown, GridPaginationWrapper } from '../../components'
import ErrorPage from '../ErrorPage';
import './modules/index.scss';

const FirstFilter = ({ onFirstFilter, initialValue }) => (
  <SelectDropdown
    domID="msg-manager__first-filter"
    isClearable={false}
    label=""
    options={[
      {
        label: 'Active',
        value: false
      },
      {
        label: 'Inactive',
        value: true
      }
    ]}
    size="small"
    initialValue={initialValue}
    onChange={onFirstFilter}
  />
)

const MessageListPane = () => {
  const dispatch = useDispatch()

  const [selectedRecord, setSelectedRecord] = useState('')
  const [idx, setIdx] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [viewInactive, setViewInactive] = useState({
    label: 'Active',
    value: false
  })
  const [payload, setPayload] = useState({
    maxRecords: rowsPerPage,
    rowPosition: pageIndex,
    viewInactive: viewInactive.value,
  })

  const { expandCollapseListPaneButtonConfig, openDetailPane } = useLayout()
  const { data, isLoading, isError, error, refetch, total } = useMessagesList(payload)

  const onClickThrough = (e, item) => {
    setSelectedRecord(item);
    dispatch(isAddMsg(false))
    openDetailPane({ id: item.cid, showDetailPane: true, isAddMsg: false, idx: idx + 1, refresh });
    setIdx(idx + 1)
  }

  const refresh = () => {
    if (
      payload.maxRecords === 10 &&
      payload.rowPosition === 0 &&
      viewInactive.value === false
    ) {
      refetch()
    } else {
      setPayload({
        maxRecords: 10,
        rowPosition: 0,
        viewInactive: false,
      })
      setRowsPerPage(10)
      setPageIndex(0)
      setViewInactive({
        label: 'Active',
        value: false
      })
    }
  };

  const onAddMessage = () => {
    dispatch(isAddMsg(true))
    openDetailPane({
      showDetailPane: true,
      isAddMsg: true,
      refresh,
      idx: idx + 1
    })
    setIdx(idx + 1)
  }

  const handleShowButton = ({ pi }) => {
    if (viewInactive.value !== payload.viewInactive) {
      setPayload({
        maxRecords: 10,
        rowPosition: 0,
        viewInactive: viewInactive.value,
      })
      setRowsPerPage(10)
      setPageIndex(0)
    } else {
      setPageIndex(pi)
      setPayload({
        maxRecords: rowsPerPage,
        rowPosition: pi,
        viewInactive: viewInactive.value,
      })
    }
  }

  const onFirstFilter = (state) => setViewInactive(state)

  return (
    <>
      <ListPaneHeader
        title="Message Manager"
        layoutControls={[expandCollapseListPaneButtonConfig]}
      />
      <div className="addModifyMessage">
        <Button
          domID="AddMessage"
          name="Add Website Message Info"
          onClick={onAddMessage}
          size="small"
          buttonType="standard"
          className="reserveModifyForm__add-button"
        />
        <div style={{ marginLeft: '30px' }}>
          <GridPaginationWrapper
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={setRowsPerPage}
            pageIndex={pageIndex}
            onChangePageIndex={setPageIndex}
            onShowButtonCLick={handleShowButton}
            totalRecords={total}
            defaultRowsPerPage={10}
            FirstFilter={() => (
              <FirstFilter onFirstFilter={onFirstFilter} initialValue={viewInactive} />
            )}
          />
        </div>
        <div className="addModifyMessage__grid-wrapper">
        {isLoading ? <Loading /> : isError  ? (
        // replace with a better error component
          <ErrorPage
            // this prop isn't real but is an example
            error={error }
          />
        ) : (
              data && data.length > 0 && data[0] !== null ? <Grid
              columns={new Set([
                {
                  dataName: 'Description',
                  text: 'Message Description',
                  cellType: 'text',
                },
                {
                  dataName: 'clientLayout',
                  text: 'Client/Layout',
                  cellType: 'text',
                },
                {
                  dataName: 'priority',
                  text: 'Priority',
                  cellType: 'text',
                }
              ])}
              records={data || []}
              dataTestId="test-AddModifyMsgGrid"
              domID="AddModifyMsgGrid"
              onClickThrough={onClickThrough}
              selectionKey="cid"
              focusedItem={selectedRecord}
              isFixedHeader
              maxHeight="400px"
            /> : <p style={{ marginLeft: '40px', fontSize: '13px' }}><i>No records to display at this time</i></p>
          )}
        </div>
      </div>
    </>
  )
}

export default MessageListPane