// Header props
export const headerProps = {
  amountKey: 'amount',
  domID: 'header',
};

const CLONE = 'clone';
const ACTIVATE = 'activate';
const DEACTIVATE = 'deactivate';
/* Enable POST BETA const TERM = 'term';

export const ACTIVE_BUTTON_SET = [CLONE, DEACTIVATE, TERM];
export const INACTIVE_BUTTON_SET = [CLONE, ACTIVATE, TERM];
export const MULTIPLE_ACTIVE_BUTTON_SET = [DEACTIVATE, TERM];
export const MULTIPLE_INACTIVE_BUTTON_SET = [TERM];
export const MULTIPLE_MIXED_STATUS_BUTTON_SET = [TERM]; */

export const ACTIVE_BUTTON_SET = [CLONE,DEACTIVATE];
export const INACTIVE_BUTTON_SET = [CLONE,ACTIVATE];
export const CLONE_BUTTON_SET = [CLONE];
export const MULTIPLE_ACTIVE_BUTTON_SET = [DEACTIVATE];
export const MULTIPLE_INACTIVE_BUTTON_SET = [];
export const MULTIPLE_MIXED_STATUS_BUTTON_SET = [];