/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import Datetime from 'react-datetime';
import moment from 'moment';
import {
  Input,
  TextArea,
  Button,
  ButtonGroup,
  ListPaneHeader,
  useLayout,
  StickyLayout,
  Modal,
  ModalHeader,
  ModalFooter
} from 'ui-core/dist'
import { Loading, SelectDropdown } from '../../components'
import axios from '../../axios'
import useClients from '../../api/useClients'
import useClientLayouts from '../../api/useClientLayouts'
import useNotificationBannerHook from '../../useNotificationBannerHook';
import './modules/index.scss';
import "react-datetime/css/react-datetime.css";

const ModifyMessageDetail = () => {
  const {
    expandCollapseDetailPaneButtonConfig,
    closeDetailPaneButtonConfig,
    closeDetailPane,
    detailProps
  } = useLayout()

  const { notify } = useNotificationBannerHook()

  const [layouts, setLayouts] = useState([
    { label: 'ALL', value: 'ALL' }
  ]);
  const [clients, setClients] = useState([
    { label: 'ALL', value: 'ALL' }
  ]);


  const [loading, setLoading] = useState(false);
  const [msgDetail, setMsgDetail] = useState({});
  const [idx, setIdx] = useState(0);
  const [selectedLayout, setSelectedLayout] = useState('ALL');
  const [selectedClient, setSelectedClient] = useState('');
  const [userType, setUserType] = useState('ALL');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [msgDesc, setMsgDesc] = useState('');
  const [msg, setMsg] = useState('');
  const [priority, setPriority] = useState('');

  const [isDeleteModal, setIsDeleteModal] = useState(false)
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)

  const clearState = () => {
    setIdx(idx + 1);
    setSelectedLayout(msgDetail?.layout);
    setSelectedClient(msgDetail?.clientLayout);
    setUserType(msgDetail?.userTypeId);
    setStartDate(msgDetail?.startDate ? moment(msgDetail?.startDate) : '');
    setEndDate(msgDetail?.endDate ? moment(msgDetail?.endDate) : '');
    setMsgDesc(msgDetail?.msgDesc);
    setMsg(msgDetail?.message);
    setPriority(msgDetail?.priority);
  }

  const { data: clientsList, isLoading: clientsLoading } = useClients()
  const { data: layoutsList, isLoading: layoutsLoading } = useClientLayouts()

  useEffect(() => {
    if (clientsList && clientsList.length)
      setClients(clientsList)
  }, [clientsList])

  useEffect(() => {
    if (layoutsList && layoutsList.length)
      setLayouts([...layouts, ...layoutsList])
  }, [layoutsList])

  const getMessageDetail = async (id) => {
    try {
      setLoading(true);
      const res = await axios.get(`/message-manager/messages/${id}/details`);
      const data = res?.data?.data;
      setMsgDetail(data);
      setSelectedLayout(data?.layout);
      setSelectedClient(data?.clientLayout);
      setUserType(data?.userTypeId);
      setStartDate(data?.startDate ? moment(data?.startDate) : '');
      setEndDate(data?.endDate ? moment(data?.endDate) : '');
      setMsgDesc(data?.msgDesc);
      setMsg(data?.message);
      setPriority(data?.priority);
    } catch (err) {
      notify(err.displayMessage, "negative")
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (detailProps?.id) getMessageDetail(detailProps?.id)
  }, [detailProps?.id])

  const getSelectionValue = (id, data) => {
    return data && data.filter(ele => {
      return ele.value === id;
    })[0];
  }

  const validation = (currentDate) => {
    var yesterday = moment().subtract(1, 'day');
    return currentDate.isAfter(yesterday);
  };

  const onLayoutChange = (state) => {
    setSelectedLayout(state.value)
    setSelectedClient('')
  }

  const onClientChange = (state) => {
    setSelectedClient(state.value)
    setSelectedLayout('')
  }

  const getAddMessageInputs = () => {
    return <div style={{ marginTop: '20px', fontSize: '13px', color: '#0F0F59' }}>
      <p style={{ marginTop: '0px' }}>* indicates required information</p>
      <fieldset>
        <legend style={{ fontSize: '13px', color: '#0F0F59' }}>You must choose one of the following:</legend>
        <SelectDropdown
          width="200px"
          dataTestId="test-layout"
          domID="LayoutsDropdown"
          label='Layout'
          onChange={onLayoutChange}
          initialValue={getSelectionValue(selectedLayout, layouts)}
          isClearable={false}
          options={layouts}
          size="small"
        />
        <SelectDropdown
          width="200px"
          dataTestId="test-clientLayout"
          domID="ClientLayout Dropdown"
          label='Client Layout'
          onChange={onClientChange}
          initialValue={getSelectionValue(selectedClient, clients)}
          isClearable={false}
          options={clients}
          size="small"
        />
      </fieldset>

      <fieldset style={{ marginTop: '12px' }}>
        <legend style={{ fontSize: '13px', color: '#0F0F59' }}>Choose one of the following to allow users to view the message:</legend>
        <SelectDropdown
          width="200px"
          dataTestId="test-usertype"
          domID="UserTypeDropdown"
          label='User Type'
          onChange={(state) => setUserType(state.value)}
          initialValue={getSelectionValue(userType, [{
            label: 'ALL',
            value: 'ALL'
          }, {
            label: 'External',
            value: 'E'
          }, {
            label: 'Internal',
            value: 'I'
          }])}
          isClearable={false}
          options={[{
            label: 'ALL',
            value: 'ALL'
          }, {
            label: 'External',
            value: 'E'
          }, {
            label: 'Internal',
            value: 'I'
          }]}
          size="small"
        />
        <div className="msgDatePicker">
          <label style={{ color: '#626D8A', fontSize: '11px' }}>Start Date</label>
          <Datetime
            key={idx}
            inputProps={{ readOnly: true }}
            onChange={setStartDate}
            closeOnSelect={true}
            isValidDate={validation}
            timeFormat="HH:mm"
            dateFormat="MM:DD:YYYY"
            timeConstraints={{
              hours: { min: 0, max: 23 },
              minutes: { min: 0, max: 59 },
            }}
            value={startDate}
            initialValue={startDate}
            initialViewDate={startDate || new Date()}
          />
        </div>
        <div className="msgDatePicker">
          <label style={{ color: '#626D8A', fontSize: '11px' }}>End Date</label>
          <Datetime
            key={idx}
            inputProps={{ readOnly: true }}
            onChange={setEndDate}
            closeOnSelect={true}
            isValidDate={validation}
            timeFormat="HH:mm"
            dateFormat="MM:DD:YYYY"
            timeConstraints={{
              hours: { min: 0, max: 23 },
              minutes: { min: 0, max: 59 },
            }}
            value={endDate}
            initialValue={endDate}
            initialViewDate={endDate || new Date()}
          />
        </div>
        <Input
          domID="MsgDescription"
          onChange={(e, { value }) => setMsgDesc(value)}
          size="small"
          initialValue={msgDesc}
          label="Message Description *"
          maxLength="40"
          errorMessage="Message Description is Mandatory"
          hasError={msgDesc.trim() === ''}
        />
        <TextArea
          label="Message *"
          dataTestId="test-msg"
          domID="MsgTextArea"
          initialValue={msg}
          onChange={(e, { value }) => setMsg(value)}
          resize="both"
          size="small"
          errorMessage="Message is Mandatory"
          hasError={msg.trim() === ''}
        />
        <Input
          domID="PriorityInput"
          onChange={(e, { value }) => setPriority(value)}
          size="small"
          initialValue={priority}
          label="Priority *"
          maxLength="1"
          errorMessage="Priority is Mandatory and accepts only integers"
          hasError={priority.trim() === '' || !/^[0-9]*$/.test(priority)}
        />
      </fieldset>
    </div>
  };

  const closeConfirmationModal = () => setIsConfirmationOpen(false)

  const saveForm = async () => {
    try {
      const res = await axios.put(`/message-manager/messages/${detailProps?.id}`, {
        layout: selectedLayout,
        client: selectedClient,
        userId: userType,
        startDate: startDate ? moment(startDate).format('MM/DD/YYYY HH:mm') : '',
        endDate: endDate ? moment(endDate).format('MM/DD/YYYY HH:mm') : '',
        newMessageDesc: msgDesc,
        newMessage: msg,
        iPriority: priority
      });
      notify(`You have successfully updated the message: ${msgDesc}`, 'positive')
      clearState();
      detailProps && detailProps.refresh()
    } catch (err) {
      notify(err.displayMessage, "negative")
    } finally {
      setIsConfirmationOpen(false)
      closeDetailPane()
    }
  }

  const deleteMsg = async () => {
    try {
      const res = await axios.delete(`/message-manager/messages/${detailProps?.id}`);
      notify(`You have successfully deleted the message: ${msgDesc}`, 'positive')
      clearState();
      detailProps && detailProps.refresh()
    } catch (err) {
      notify(err.displayMessage, "negative")
    } finally {
      setIsConfirmationOpen(false)
      closeDetailPane()
    }
  }

  return (
    <>
      {detailProps?.showDetailPane && <StickyLayout
        headerContent={<ListPaneHeader
          sticky
          title="Message Details"
          layoutControls={[expandCollapseDetailPaneButtonConfig, closeDetailPaneButtonConfig]}
        />}
        height="calc(100vh - 80px)"
      >
        {
          loading ? <Loading /> : <div className='addMsgDetail'>
            <Button
              buttonType="standard"
              domID="DeleteAction"
              size="small"
              name="Delete"
              onClick={() => {
                setIsDeleteModal(true)
                setIsConfirmationOpen(true)
              }}
            ></Button>
            {getAddMessageInputs()}
            <ButtonGroup>
              <Button
                buttonType="standard"
                domID="ResetAction"
                size="small"
                name="Reset"
                onClick={clearState}
              ></Button>
              <Button
                buttonType="standard"
                domID="ConfirmAction"
                size="small"
                name="Save"
                disabled={msgDesc.trim() === '' || msg.trim() === '' || priority.trim() === '' || !/^[0-9]*$/.test(priority)}
                onClick={() => {
                  setIsDeleteModal(false)
                  setIsConfirmationOpen(true)
                }}
              ></Button>
            </ButtonGroup>
          </div>
        }
      </StickyLayout>}
      { (detailProps && detailProps.showDetailPane) ? <Modal isOpen={isConfirmationOpen} className="add-msg-modal">
        <ModalHeader
          title={isDeleteModal ? 'Are you sure you wish to delete this message?' : 'Do you accept these changes?'}
          onClose={closeConfirmationModal}
        />
        <ModalFooter>
          <ButtonGroup className="add-msg-modal__button-group">
            <Button
              domID="cancelModalButton"
              onClick={closeConfirmationModal}
              buttonType="standard"
              size="small"
              name="Cancel"
            />
            <Button
              domID="confirmAddButton"
              onClick={isDeleteModal ? deleteMsg : saveForm}
              buttonType="standard"
              size="small"
              name="Confirm"
            />
          </ButtonGroup>
        </ModalFooter>
      </Modal> : <></>}
    </>
  )
}

export default ModifyMessageDetail