/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import styled from 'styled-components/dist/styled-components';
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import {
  Grid,
  LoadingIndicator,
  useLayout,
} from 'ui-core/dist'
import useNotificationBannerHook from '../../useNotificationBannerHook';
import withAxiosCancellation from '../../components/withAxiosCancellation'
import { LoadingPage, AnchorInput } from '../../components';
import ErrorPage from '../ErrorPage/components/ErrorPage';
import ListPaneHeaderWrapper from '../../components/ErrorDisplaySection/ListPaneHeaderWrapper';
import { DOCUMENT_SEARCH_RESULTS_PAGE_QUICK_GRID_COLUMN } from './Modules/constants';
import useSortableGrid from '../../hooks/useSortableGrid';
import { resetDocumentSearchParams, setDocumentSearchParams } from './Modules/action';
import { usePreviewFile, useSearchDocumentsQuickFull, useGetPdfFileQuickSearch } from '../../api/useDocumentManager';
import { documentSearch as documentSearchAction } from '../SideNav/modules/actions';

const StyledGridWrapper = styled.div`
&.GridWrapper #grid-wrapper-test-id{
  overflow:initial !important;
}
`
const StyledLoadingContainer = styled.div`
& > div {
  width: 35px !important;
  height: 35px !important;
  & > svg {
    width: 35px !important;
    height: 35px !important;
  }
}
`

const DocumentSearchResultsPageQuick = ({getCancelSourceWithName}) => {
  const {
    expandCollapseListPaneButtonConfig,
  } = useLayout()
  const { notify } = useNotificationBannerHook()
  const history = useHistory();
  const dispatch = useDispatch()
  const DEFAULT_ROWS_PER_PAGE = 10;
  const DEFAULT_DOWNLOAD_ERR_MESSAGE = "Could not download PDF at the moment. There is no PDF File for your request";

  const [downloadTrackConfig, setDownloadTrackConfig] = useState(null);

  const [claimNumberDownloadTrack, setclaimNumberDownloadTrack] = useState({
    documentKey: null,
    filename: null
  })

  const { file, previewFile, refetchPreviewFile, isError: isErrorPreviewFile, isLoading: isLoadingPreviewFile } = usePreviewFile(setclaimNumberDownloadTrack);
  const { file: copyNumberFile2, getPdfFile, refetchGetPdfFile, isError: isErrorPdfFile, isLoading: isLoadingPdfFile } = useGetPdfFileQuickSearch(setDownloadTrackConfig);

  const { quickSearchParams }
    = useSelector(({ documentSearch }) => documentSearch);
  const canView = useSelector(({ context }) =>
    context?.permissions?.filter(p => p.name === 'CAN_PERFORM_DOC_PDF_VIEW').length > 0)

  useEffect(() => {
    if (postData) {
      postData(JSON.parse(JSON.stringify({
        ...quickSearchParams,
        "maxRecords": DEFAULT_ROWS_PER_PAGE,
        "rowPosition": 0,
        "sortField": sortConfig.sortDataName,
        "sortDescending": sortConfig.sortDescending,
      })))
    }
  }, [quickSearchParams])

  useEffect(() => {
    if (isErrorPreviewFile) {
      notify(DEFAULT_DOWNLOAD_ERR_MESSAGE, 'negative');
    }
    if (isErrorPdfFile) {
      notify(DEFAULT_DOWNLOAD_ERR_MESSAGE, 'negative')
    }
  }, [isErrorPreviewFile, isErrorPdfFile])

  const fetchData = useCallback((args) => {
    let sortcolumn; let sd; let pi;
    if (args) {
      ({ sortcolumn, sd, pi } = args);
    }
    postData(JSON.parse(JSON.stringify({
      ...quickSearchParams,
      "maxRecords": DEFAULT_ROWS_PER_PAGE,
      "rowPosition": 0,
      "sortField": sortcolumn === undefined || sortcolumn === null ? sortConfig.sortDataName : sortcolumn,
      "sortDescending": sd === undefined || sd === null ? sortConfig.sortDescending : sd,
    })))

  }, [sortConfig]);
  const [sortConfig, getArgumentsAndSortGrid] = useSortableGrid(fetchData, "documentKey", "true");
  const { suppressdata, errorDesc, totalRecords, records, postData, isLoading, isError, error } = useSearchDocumentsQuickFull(
    (errordescription) => {
      if (errordescription) notify(errordescription, 'negative');
    }, getCancelSourceWithName('searchDocumentsQuickFull'))

  useEffect(() => {
    if (file && claimNumberDownloadTrack.filename) {
      downloadFile(file, 'claimNumber')
    }
  }, [file])

  useEffect(() => {
    if (copyNumberFile2 && downloadTrackConfig.filename) {
      downloadFile(copyNumberFile2, 'pdfLink')
    }
  }, [copyNumberFile2])

  const downloadFile = (f, target) => {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
      window.navigator.msSaveOrOpenBlob(f, target === "claimNumber" ? claimNumberDownloadTrack.filename : downloadTrackConfig.filename);
    } else {
      const FILE = window.URL.createObjectURL(f);
      const docUrl = document.createElement('a');
      docUrl.href = FILE;
      docUrl.setAttribute('download', target === "claimNumber" ? claimNumberDownloadTrack.filename : downloadTrackConfig.filename);
      document.body.appendChild(docUrl);
      docUrl.click();
      document.body.removeChild(docUrl);
    }
  }
  const renderDocumentKeyDisplay = (documentKey) => {
    if (documentKey) {
      return (
        <AnchorInput
          id={`documentKey_${documentKey}`}
          type='button'
          className='fileKeyLink'
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            const payload = {
              groupId: "",
              recipientType: "",
              idNumber: "",
              tableName: '',
              insuredPolicyNumber: "",
              insuredState: "",
              patientId: "",
              processorId: "",
              providerStateCode: "",
              documentType: "",
              filekey: '',
              documentKey,
              voucherNumber: "",
              formNumber: '',
              claimTypeId: '',
              checkNumber: "",
              claimNumber: "",
              domXml: "",
              benefitYear: "",
              showDuplicateChecks: true,
              showProcessedDocuments: true,
            };
            dispatch(setDocumentSearchParams(payload))
            history.push('/DocumentManager/SearchResults')
          }}
        >{documentKey}</AnchorInput>
      )
    }
    return null;
  }
  const renderClaimNumberDisplay = (claimCount, bPreviewLink, claimNumber, documentKey) => {
    if (claimCount > 1) {
      if (bPreviewLink === '0') {
        return (
          <>
            <i>multiple</i>
          </>
        )
      }
      return (
        <StyledLoadingContainer>
          <>
            {isLoadingPreviewFile && claimNumberDownloadTrack?.documentKey === documentKey ? <LoadingIndicator /> :
              <AnchorInput
                id={`claimNumber_${claimNumber}_${documentKey}`}
                type='button'
                className='fileKeyLink'
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if(canView) {
                  if (documentKey !== claimNumberDownloadTrack?.documentKey) {
                    previewFile({
                      payload: { documentKey, relKeys: "none" }, config: JSON.parse(JSON.stringify({
                        documentKey,
                        filename: 'printPreview.pdf'
                      }))
                    });
                  } else {
                    refetchPreviewFile()
                  }} else {
                    notify('You do not have required permission to perform this function','negative')
                  }
                }}
              ><i>multiple</i></AnchorInput>
            }
          </>
        </StyledLoadingContainer>
      )
    }
    if (bPreviewLink === '0') {
      return (
        <>
          <span>{claimNumber}</span>
        </>
      )
    }
    return (
      <StyledLoadingContainer>
        <>
          {isLoadingPreviewFile && claimNumberDownloadTrack?.documentKey === documentKey ? <LoadingIndicator /> :
            <AnchorInput
              id={`claimNumber_${claimNumber}_${documentKey}`}
              type='button'
              className='fileKeyLink'
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if(canView) {
                if (documentKey !== claimNumberDownloadTrack?.documentKey) {
                  previewFile({
                    payload: { documentKey, relKeys: "none" }, config: JSON.parse(JSON.stringify({
                      documentKey,
                      filename: 'printPreview.pdf'
                    }))
                  });
                } else {
                  refetchPreviewFile()
                }} else {
                  notify('You do not have required permission to perform this function','negative')
                }
              }}
            ><i>{claimNumber}</i></AnchorInput>
          }
        </>
      </StyledLoadingContainer>
    )
  }
  const renderPdfLinkDisplay = (documentKey, bPreviewLink) => {
    return (
      <StyledLoadingContainer>
        <>
          {isLoadingPdfFile && downloadTrackConfig?.documentKey === documentKey ? <LoadingIndicator /> :
            <AnchorInput
              id={`pdfLink_${documentKey}`}
              type='button'
              className='fileKeyLink'
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if(canView) {
                if (documentKey !== downloadTrackConfig?.documentKey) {
                  getPdfFile({
                    payload: { documentKey, bPreview: bPreviewLink }, config: JSON.parse(JSON.stringify({
                      documentKey,
                      filename: 'printPreview.pdf'
                    }))
                  });
                } else {
                  refetchGetPdfFile()
                }} else {
                  notify('You do not have required permission to perform this function','negative')
                }
              }}
            >Download PDF</AnchorInput>
          }
        </>
      </StyledLoadingContainer>
    )
  }
  const renderMainGrid = useMemo(() => {

    if (records && records.length > 0) {
      const temp = JSON.parse(JSON.stringify(records));
      const data = temp.map((d) => {
        const { bPreview: bPreviewLink, documentKey } = d;
        const claimCount = d.claims.claim.length;


        const claimNumber = d.claims && d.claims.claim && d.claims.claim.length > 0 ?
          d.claims.claim[0].claimNumber : '';
        return {
          documentKey,
          documentKeyDisplay: renderDocumentKeyDisplay(documentKey),

          checkNumber: d.checknumber,
          checkAmount: d.checkamount,

          claimNumber,
          claimNumberDisplay: renderClaimNumberDisplay(claimCount, bPreviewLink, claimNumber, documentKey),

          claimType: d.claimtype.name,
          documentType: d.documenttype.name,
          recipientType: d.recipienttype.name,

          pdfLinkDisplay: renderPdfLinkDisplay(documentKey, bPreviewLink),

          status: `Released on ${moment(d.releasedate).format('MM/DD/yyyy hh:mm a')}`// renderStatus(d?.status,documentKey, releaseInformation?.dateTimeReleased),
        }
      })
      return (
        <>
          {suppressdata === "T" ? (
            <p>
              <font color='red'>The PDF you are trying to review may not be viewable due to client request to suppress this document.</font>
            </p>
          ) : null}
          <StyledGridWrapper className='GridWrapper'>
            <Grid
              {...DOCUMENT_SEARCH_RESULTS_PAGE_QUICK_GRID_COLUMN}
              dataTestId="DocumentManagerSearchGrid"
              records={data || []}
              onClickThrough={(e) => {
                e.preventDefault();
                e.stopPropagation();
                return false;
              }}
              initialSortingKey={`${sortConfig.sortDataName}|${sortConfig.sortDescending === "true" ? 'SORT_DESCENDING' : 'SORT_ASCENDING'}`}
              onSortGridColumn={(e, state) => getArgumentsAndSortGrid(state)}
            />
          </StyledGridWrapper>
        </>
      )
    }
    return null;
  }, [records, claimNumberDownloadTrack, downloadTrackConfig,
    isLoadingPreviewFile, isLoadingPdfFile, suppressdata]);

  return (
    <>
      <ListPaneHeaderWrapper
        title="Document Manager: Search Documents"
        layoutControls={[expandCollapseListPaneButtonConfig]}
      />
      {isLoading ? (
        // replace with a better loading component
        <LoadingPage />
      ) : isError ? (
        // replace with a better error component
        <ErrorPage
          // this prop isn't real but is an example
          error={error}
        />
      ) : (
        <>
          <div className="marginLeft">
            <div className="displayFlex">
              <label>The following documents matched your search criteria. Click the record to view additional document details. If PDF output and tracking information are available, links to this information are displayed in the document details.</label>
            </div>
            <div className="displayFlex">
              <label style={{ color: 'red' }}>If the value in the claim number column shows up as a link, the document is ready for pre-release preview. Any updates to group rules, forms or images can affect the final output.</label>
            </div>
            <div className="displayFlex">
              <AnchorInput
                id='documentSearchNavigation'
                type='button'
                className='fileKeyLink'
                onClick={() => {
                  // history.goBack();
                  // dispatch(resetDocumentSearchParams())
                  dispatch(documentSearchAction(true))
                }}
              >[New Search]</AnchorInput>
            </div>
          </div>
          {!errorDesc?renderMainGrid: 
          <div className="displayFlex" style={{marginLeft: "36px", fontSize: "14px"}}>
          <label style={{ color: 'red' }}>{errorDesc}</label>
        </div>
          }
        </>
      )}
    </>
  )
}

export default withAxiosCancellation(DocumentSearchResultsPageQuick)