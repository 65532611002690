import Api from '..';
import { API_BASE } from '../constants';

export const getCommitRuleTypesList = async (
  clientLayoutId,
  userSecurityTokenKey,
  username,
  groupId,
  roleId
) => {
  try {
    const baseUrl = `${API_BASE}/associations_group/${clientLayoutId}/${groupId}`;
    const api = new Api(userSecurityTokenKey, username, null, roleId);
    const getCommitRuleTypesListResponse = await api.get(baseUrl);

    if (getCommitRuleTypesListResponse.error) {
      throw getCommitRuleTypesListResponse.error;
    }
    return getCommitRuleTypesListResponse.associations;
  } catch (error) {
    throw error;
  }
}

// -------------- Commit multiple rules----------------
export const putMultipleCommitRules = async (
  ruleIdArray,
  clientLayoutId,
  userSecurityTokenKey,
  username,
  roleId
) => {
  try {
    const ruleType = window.location.href.substring(
      window.location.href.lastIndexOf('=') + 1,
    );
    const baseUrl = `${API_BASE}/rules/${clientLayoutId}/${ruleType}:commit`;
    const idArray = JSON.stringify({ ruleIdArray, });
    const api = new Api(userSecurityTokenKey, username, null, roleId);
    const putMultipleCommitRulesResponse = await api.put(baseUrl, idArray);

    if (putMultipleCommitRulesResponse.error) {
      throw putMultipleCommitRulesResponse.error;
    }
    return putMultipleCommitRulesResponse;
  } catch (error) {
    throw error;
  }
};
// ------------ Commit test rule associations ----------- /

export const putCommitMultipleRuleAssociations = async (
  { clientLayoutId, userSecurityTokenKey, username, roleId },
  { groupId, ruleTypes },
) => {
  try {
    const baseUrl = `${API_BASE}/associations/${clientLayoutId}:commit`;
    const associationArray = JSON.stringify({
      associationsArray: [
        {
          groupId,
          ruleTypes,
        },
      ],
    });
    const api = new Api(userSecurityTokenKey, username, null, roleId);
    const putCommitMultipleRuleAssociationsResponse = api.put(baseUrl, associationArray);

    if (putCommitMultipleRuleAssociationsResponse.error) {
      throw putCommitMultipleRuleAssociationsResponse.error;
    }
    return putCommitMultipleRuleAssociationsResponse;
  } catch (error) {
    throw error;
  }
};

export default {
  getCommitRuleTypesList,
  putMultipleCommitRules,
  putCommitMultipleRuleAssociations,
};
