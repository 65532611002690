/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import styled from 'styled-components/dist/styled-components';
import { useDispatch } from 'react-redux';
import {
  Grid,
  useLayout,
  Button
} from 'ui-core/dist'
import { LoadingPage, SelectDropdown } from '../../../components';
import ErrorPage from '../../ErrorPage';
import ListPaneHeaderWrapper from '../../../components/ErrorDisplaySection/ListPaneHeaderWrapper';
import GridPaginationWrapper from '../../../components/GridPaginationWrapper';
import { CLIENT_ADMINISTRATION_RECIPIENT_TYPE_GRID } from '../Modules/constants';
import useSortableGrid from '../../../hooks/useSortableGrid';
import { resetRecipientTypeTranslationRecord, setRecipientTypeTranslationRecord } from '../Modules/action';
import { useClientAdministrationRecipientTypeData } from '../../../api/useClientAdministrationRecipientType';
import '../index.scss';

const StyledGridWrapper = styled.div`
&.GridWrapper #grid-wrapper-clientAdministrationRecipientTypeGrid {
  & table#clientAdministrationRecipientTypeGrid thead tr th[data-testid="header-recipientTypeTrans"], & table#clientAdministrationRecipientTypeGrid thead tr th[data-testid="header-recipientTypeTrans"] > button:first-child {
    width: 165px !important;
    overflow: hidden !important;
  }
  & table#clientAdministrationRecipientTypeGrid thead tr th[data-testid="header-recipientType"], & table#clientAdministrationRecipientTypeGrid thead tr th[data-testid="header-recipientType"] > button:first-child {
    width: 195px !important;
    overflow: hidden !important;
  }
  & table#clientAdministrationRecipientTypeGrid thead tr th[data-testid="header-recipientTypeDesc"], & table#clientAdministrationRecipientTypeGrid thead tr th[data-testid="header-recipientTypeDesc"] > button:first-child {
    width: 250px !important;
    overflow: hidden !important;
  }
}
`
const StyledDropDownContainer = styled.div`
width: 370px !important;
& #drpDeliveryAddress, & #drpDeliveryTypes, & #drpReleaseItems {
    width: 370px !important;
}
`
const RecipientTypeTranslationMain = () => {
  const {
    expandCollapseListPaneButtonConfig,
    openDetailPane,
    closeDetailPane
  } = useLayout()
  const dispatch = useDispatch()
  const DEFAULT_ROWS_PER_PAGE = 10;

  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE)
  const [pageIndex, setPageIndex] = useState(0);
  const [recipientTypeDropdownSource, setrecipientTypeDropdownSource] = useState([])
  const [searchParams, setSearchParams] = useState('Active')
  const [count, setcount] = useState(0);
  const [selectedRecord, setSelectedRecord] = useState('')

  const refetchRecipientTypes = () => {
    postData(JSON.parse(JSON.stringify({
      "parameters": "",
      "viewInactive": searchParams !== "Active",
      "maxRecords": rowsPerPage,
      "rowPosition": pageIndex,
      "sortField": sortConfig.sortDataName,
      "sortDescending": sortConfig.sortDescending,
      count
    })))
    setcount(count + 1)
  }

  useEffect(() => {
    setrecipientTypeDropdownSource(JSON.parse(JSON.stringify([{
      label: 'Inactive',
      value: 'Inactive'
    }, {
      label: 'Active',
      value: 'Active'
    }])))
    if (postData) {
      closeDetailPane();
      dispatch(resetRecipientTypeTranslationRecord());
      refetchRecipientTypes()
    }
  }, [])

  const fetchData = useCallback((args) => {
    let sortcolumn; let sd; let pi;
    if (args) {
      ({ sortcolumn, sd, pi } = args);
    }
    closeDetailPane();
    dispatch(resetRecipientTypeTranslationRecord());
    postData(JSON.parse(JSON.stringify({
      "parameters": "",
      "viewInactive": searchParams !== "Active",
      "maxRecords": rowsPerPage,
      "rowPosition": pi === undefined || pi === null ? pageIndex : pi,
      "sortField": sortcolumn === undefined || sortcolumn === null ? sortConfig.sortDataName : sortcolumn,
      "sortDescending": sd === undefined || sd === null ? sortConfig.sortDescending : sd,
      count
    })))
    setcount(count + 1)

  }, [rowsPerPage, pageIndex, sortConfig, searchParams]);
  const [sortConfig, getArgumentsAndSortGrid] = useSortableGrid(fetchData, "recipientTypeTrans", "true");
  const { totalRecords, records, postData, isLoading, isError, error } = useClientAdministrationRecipientTypeData()

  const onShowMoreClick = useCallback((record) => {
    dispatch(setRecipientTypeTranslationRecord({ recipientTypeTranslationRecord: JSON.parse(JSON.stringify(record)), mode: 'Edit', refetchRecipientTypes }));
    setSelectedRecord(JSON.parse(JSON.stringify(record)))
    openDetailPane();
  }, [records]);

  const onAddRecipientTypeTranslation = () => {
    setSelectedRecord({})
    dispatch(setRecipientTypeTranslationRecord({ recipientTypeTranslationRecord: JSON.parse(JSON.stringify({})), mode: 'Add', refetchRecipientTypes }));
    openDetailPane();
  }
  const renderMainGrid = useMemo(() => {

    if (records) {
      const data = JSON.parse(JSON.stringify(records));
      return (
        <>
          <div style={{ marginLeft: '22px' }}>
            <GridPaginationWrapper
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={setRowsPerPage}
              pageIndex={pageIndex}
              onChangePageIndex={setPageIndex}
              onShowButtonCLick={fetchData}
              totalRecords={totalRecords}
              FirstFilter={() => (
                <SelectDropdown
                  width="150px"
                  domID="drpReleaseTypeOptions"
                  label=""
                  options={recipientTypeDropdownSource}
                  size="medium"
                  initialValue={{ label: recipientTypeDropdownSource.find(x => x.value === searchParams)?.label, value: searchParams }}
                  onChange={(e) => {
                    if (e) {
                      if (setSearchParams) setSearchParams(e.value)
                    } else if (setSearchParams) setSearchParams(null)
                  }}
                />
              )}
              defaultRowsPerPage={DEFAULT_ROWS_PER_PAGE}
            />
          </div>
          <StyledGridWrapper className='GridWrapper'>
            <Grid
              {...CLIENT_ADMINISTRATION_RECIPIENT_TYPE_GRID}
              records={data || []}
              selectionKey="recipientTypeTrans"
              onClickThrough={(e, rec) => {
                e.preventDefault();
                e.stopPropagation();
                if (rec) {
                  const record = JSON.parse(JSON.stringify(rec))
                  if (onShowMoreClick) {
                    dispatch(resetRecipientTypeTranslationRecord())
                    return onShowMoreClick(record);
                  }
                  return false;
                }
                return false;
              }}
              focusedItem={selectedRecord}
              initialSortingKey={`${sortConfig.sortDataName}|${sortConfig.sortDescending === "true" ? 'SORT_DESCENDING' : 'SORT_ASCENDING'}`}
              onSortGridColumn={(e, state) => getArgumentsAndSortGrid(state)}
            />
          </StyledGridWrapper>
        </>
      )
    }
    return null;
  }, [records, rowsPerPage, pageIndex, recipientTypeDropdownSource, searchParams, selectedRecord]);

  return (
    <>
      <ListPaneHeaderWrapper
        title="Recipient Type Translation Manager"
        layoutControls={[expandCollapseListPaneButtonConfig]}
      />
      {isLoading ? (
        // replace with a better loading component
        <LoadingPage />
      ) : isError ? (
        // replace with a better error component
        <ErrorPage
          // this prop isn't real but is an example
          error={error}
        />
      ) : (
            <>
              <div style={{ marginLeft: '20px' }} className="marginLeft">
                <div style={{ marginTop: '20px' }} className="displayFlex">
                  <Button
                    id='RecipientTypeAdd'
                    size="small"
                    name="Add Recipient Type Translation"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onAddRecipientTypeTranslation()
                    }}
                  />
                </div>
              </div>
              {renderMainGrid}
            </>
          )}
    </>
  )
}

export default RecipientTypeTranslationMain
