import { connect } from 'react-redux';
import RulesTabWrapper from './components/RulesTabWrapper';
import {
  selectIsEditingRuleDetail,
  selectIsReadOnlyRuleDetail,
  selectIsPostingRuleDetail,
  selectCurrentDetailsRecordStatus,
  selectCurrentTab,
  selectLoadingState,
  selectNotificationState,
  selectIsPostingRulesForRulesTypes,
  selectAssociatedGroupRecordsNoTermedRecords,
  selectRuleCloningState,
  selectProductionArrangementModalState,
  selectCustomerServiceMessageModalState,
  selectIsCloningRuleDetail,
  selectReturnAddressModalState,
  selectDuplexRuleModalState,
  selectSignatureRuleModalState,
  selectAutoHoldModalState,
  selectMemberIdModalState,
  selectCheckTextRuleModalState,
  selectImageRuleModalState,
  selectTaxIdArrangementState,
  selectErisaMessageModalState,
  selectHighDollarReviewModalState,
  selectReturnAddressElements,
  selectProviderInsuredOptionMessageModalState,
  selectGeneralMessageModalState,
  selectPDFTaggingModalState,
  selectNotificationStateErrors,
  selectRuleImages,
  selectRuleImagesCount,
  selectConvertedImageUploadData,
  selectConvertedImageUploadError,
  selectPostImageUploadSuccessId,
  selectIsShowingCommitImageModal,
  selectPostImageCommitSuccessId,
  selectUserRole,
  selectUserPermissions,
  selectIsShowingRevertRuleModal,
  selectRevertRuleVersionsList,
  selectUserType,
  selectUserRoleId
} from './selectors';
import {
  updateCurrentTab,
  triggerAllRulesForRuleTypesRequest,
  groupAssociationsForRuleIdTrigger,
  setSelectedRuleRecord,
} from '../../redux/api/rulesForRuleTypesList/actions';
import {
  setIsPostingRulesForRuleTypes,
  triggerRulesForRuleTypesPutRequest,
  triggerRulesForRuleTypesPostRequest,
  setIsCloningRuleState,
  setRuleTypeCommitModalOpen,
  triggerCommitRuleForRuleType,
  setIsShowingActiveGroupsListingModal,
  setIsShowingRevertRuleModal,
  getRevertRuleVersions,
  postRevertRuleVersion
} from '../../redux/api/rulesForRuleTypes/actions';
import {
  setIsViewingRuleDetails,
  setIsShowingDeleteRuleDetailModal,
  deleteRuleDetailTrigger,
  triggerRuleDetailsRequest,
  setIsShowingDetailCloneRuleModal,
  overwriteTestRuleRequestTrigger,
  setIsShowingOverwriteModal,
} from '../../redux/api/ruleTypesList/actions';
import {
  addNotification,
  deleteNotification,
} from '../../redux/app/notificationState/actions';
import { resetDetailsPane } from '../../redux/app/detailsPaneState/actions';
import {
  selectGridConfig,
  selectRenderSafeRecords,
} from './components/RuleDetails/RuleDetailsGridWrapper/selectors';
import {
  ruleDetailElementsTrigger,
  setSelectedRuleDetail,
  setIsEditingRuleDetail,
  setIsReadOnlyRuleDetail,
  setIsPostingRuleDetail,
  setIsShowingProductionArrangementModal,
  setIsShowingCustomerServiceMessageModal,
  ruleDetailPostRequestTrigger,
  ruleDetailPutRequestTrigger,
  setIsCloningRuleDetail,
  setIsShowingReturnAddressModal,
  setIsShowingDuplexRuleModal,
  setIsShowingSignatureRuleModal,
  setIsShowingCheckTextRuleModal,
  setIsShowingImageRuleModal,
  setIsShowingAutoHoldModal,
  setIsShowingMemberIdModal,
  setIsShowingTaxIdArrangementRuleModal,
  setIsShowingErisaMessageModal,
  setShowingHighDollarReviewModal,
  setIsShowingAddNewAddressModal,
  getAddNewAddressElementsTrigger,
  postAddNewAddressElementsTrigger,
  setIsShowingProviderInsuredOptionMessageModal,
  setIsShowingGeneralMessageModal,
  setIsShowingPDFTaggingModal,
  setIsShowingImageLibraryModal,
  setIsShowingImageUploadModal,
  postImageUploadTrigger,
  getRuleImagesTrigger,
  getRuleImagesCountTrigger,
  resetRuleImagesDataTrigger,
  convertImageUploadTrigger,
  postCommitImageTrigger,
  setIsShowingCommitImageModal,
  postCommitImageTriggerCancel
} from '../../redux/api/ruleDetails/actions';
import {
  captureCurrentPage,
} from '../../redux/api/pageTracker/actions';
import {
  selectDetailElements,
  selectSelectedRuleDetail,
} from '../GroupDetailView/components/AdvancedTabs/EditTestTab/AdvancedGridWrapper/selectors';

const mapStateToProps = state => ({
  ...state,
  ...state.rulesForRuleTypesList,
  ...state.rulesForRuleTypes,
  ...state.ruleDetails,
  isCloningRuleDetail: selectIsCloningRuleDetail(state),
  isEditingRuleDetail: selectIsEditingRuleDetail(state),
  isReadOnlyRuleDetail: selectIsReadOnlyRuleDetail(state),
  isPostingRuleDetail: selectIsPostingRuleDetail(state),
  isShowingProductionArrangementModal: selectProductionArrangementModalState(
    state,
  ),
  isShowingCustomerServiceMessageModal: selectCustomerServiceMessageModalState(
    state,
  ),
  isShowingReturnAddressModal: selectReturnAddressModalState(state),
  isShowingDuplexRuleModal: selectDuplexRuleModalState(state),
  isShowingSignatureRuleModal: selectSignatureRuleModalState(state),
  isShowingCheckTextRuleModal: selectCheckTextRuleModalState(state),
  isShowingImageRuleModal: selectImageRuleModalState(state),
  isShowingTaxIdArrangementRuleModal: selectTaxIdArrangementState(state),
  isShowingErisaMessageModal: selectErisaMessageModalState(state),
  isShowingHighDollarReviewModal: selectHighDollarReviewModalState(state),
  isShowingProviderInsuredOptionMessageModal: selectProviderInsuredOptionMessageModalState(
    state,
  ),
  isShowingGeneralMessageModal: selectGeneralMessageModalState(state),
  isShowingPDFTaggingModal: selectPDFTaggingModalState(state),
  isShowingAutoHoldModal: selectAutoHoldModalState(state),
  isShowingMemberIdModal: selectMemberIdModalState(state),
  detailElements: selectDetailElements(state),
  gridConfig: selectGridConfig(state),
  selectedRuleDetail: selectSelectedRuleDetail(state),
  ...state.ruleDetails,
  isShowingDeleteRuleDetailModal:
    state.ruleTypesList.isShowingDeleteRuleDetailModal,
  ruleTypeCommitRuleModalOpen:
    state.rulesForRuleTypes.ruleTypeCommitRuleModalOpen,
  records: selectRenderSafeRecords(state),
  isPostingRulesForRuleTypes: selectIsPostingRulesForRulesTypes(state),
  loading: selectLoadingState(state),
  notifications: selectNotificationState(state),
  errorNotifications: selectNotificationStateErrors(state),
  selectedRecord: state.ruleTypesList.selectedRuleRecord,
  selectedRecordStatus: selectCurrentDetailsRecordStatus(state),
  selectedRuleRecord: state.ruleTypesList.selectedRuleRecord,
  selectedRuleTypeId: state.ruleTypesList.selectedRuleTypeId,
  testImageCount: state.ruleTypesList.testImageCount !== null ? state.ruleTypesList.testImageCount : 0, 
  currentTab: selectCurrentTab(state),
  groupAssociationsForRuleId:
    state.rulesForRuleTypesList.groupAssociationsForRuleId,
  associatedGroupRecordsNoTermedRecords: selectAssociatedGroupRecordsNoTermedRecords(
    state || [],
  ),
  showDetailCloneRuleModal: state.ruleTypesList.showDetailCloneRuleModal,
  isCloningRule: selectRuleCloningState(state),
  recordForDetailPane: state.rulesForRuleTypes.recordForDetailPane,
  overwriteTestRuleError: state.ruleTypesList.overwriteTestRuleError,
  overwriteTestRuleSuccess: state.ruleTypesList.overwriteTestRuleSuccess,
  returnAddressElements: selectReturnAddressElements(state),
  isShowingOverwriteModal: state.ruleTypesList.isShowingOverwriteModal,
  isShowingImageLibraryModal: state.ruleDetails.isShowingImageLibraryModal,
  isShowingImageUploadModal: state.ruleDetails.isShowingImageUploadModal,
  showActiveGroupsListingModal: state.rulesForRuleTypes.showActiveGroupsListingModal,
  selectedActiveRecordsList: state.rulesForRuleTypes.selectedActiveRecordsList,
  selectedRecordName: state.rulesForRuleTypes.selectedRecordName,
  activeGroupsModalLocation: state.rulesForRuleTypes.activeGroupsModalLocation,
  ruleImages: selectRuleImages(state),
  ruleImagesCount: selectRuleImagesCount(state),
  convertedImageUploadData: selectConvertedImageUploadData(state),
  convertedImageUploadError: selectConvertedImageUploadError(state),
  postImageUploadSuccessId: selectPostImageUploadSuccessId(state),
  isShowingCommitImageModal: selectIsShowingCommitImageModal(state),
  postImageCommitSuccessId: selectPostImageCommitSuccessId(state),
  userRole: selectUserRole(state),
  userPermissions: selectUserPermissions(state),
  ruleDetails: state.ruleDetails,
  revertRuleVersionsList: selectRevertRuleVersionsList(state),
  isShowingRevertRuleModal: selectIsShowingRevertRuleModal(state),
  userType: selectUserType(state),
  userRoleId: selectUserRoleId(state)
});

const mapDispatchToProps = dispatch => ({
  postRuleDetails: (...args) => dispatch(ruleDetailPostRequestTrigger(...args)),
  putRuleDetails: (...args) => dispatch(ruleDetailPutRequestTrigger(...args)),
  triggerCommitRuleForRuleType: ruleIdArray =>
    dispatch(triggerCommitRuleForRuleType(ruleIdArray)),
  setRuleTypeCommitModalOpen: bool =>
    dispatch(setRuleTypeCommitModalOpen(bool)),
  addNotification: id => dispatch(addNotification(id)),
  deleteNotification: id => dispatch(deleteNotification(id)),
  getRuleDetails: (...args) => dispatch(triggerRuleDetailsRequest(...args)),
  getRuleDetailElements: ({ selectedRuleTypeId }) =>
    dispatch(ruleDetailElementsTrigger({ selectedRuleTypeId })),
  setSelectedRuleDetail: record => dispatch(setSelectedRuleDetail(record)),
  setIsPostingRulesForRuleTypes: bool =>
    dispatch(setIsPostingRulesForRuleTypes(bool)),
  resetDetailsPane: () => dispatch(resetDetailsPane()),
  setIsViewingRuleDetails,
  setIsCloningRuleDetail: isCloning =>
    dispatch(setIsCloningRuleDetail(isCloning)),
  setIsEditingRuleDetail: isEditing =>
    dispatch(setIsEditingRuleDetail(isEditing)),
  setIsReadOnlyRuleDetail: isReadOnly =>
    dispatch(setIsReadOnlyRuleDetail(isReadOnly)),
  setIsPostingRuleDetail: isPosting =>
    dispatch(setIsPostingRuleDetail(isPosting)),
  setIsShowingProductionArrangementModal: bool =>
    dispatch(setIsShowingProductionArrangementModal(bool)),
  setIsShowingCustomerServiceMessageModal: bool =>
    dispatch(setIsShowingCustomerServiceMessageModal(bool)),
  setIsShowingReturnAddressModal: bool =>
    dispatch(setIsShowingReturnAddressModal(bool)),
  setIsShowingDuplexRuleModal: bool =>
    dispatch(setIsShowingDuplexRuleModal(bool)),
  setIsShowingSignatureRuleModal: bool =>
    dispatch(setIsShowingSignatureRuleModal(bool)),
  setIsShowingCheckTextRuleModal: bool =>
    dispatch(setIsShowingCheckTextRuleModal(bool)),
  setIsShowingImageRuleModal: bool =>
    dispatch(setIsShowingImageRuleModal(bool)),
  setIsShowingAutoHoldModal: bool => dispatch(setIsShowingAutoHoldModal(bool)),
  setIsShowingMemberIdModal: bool => dispatch(setIsShowingMemberIdModal(bool)),
  setIsShowingTaxIdArrangementRuleModal: bool =>
    dispatch(setIsShowingTaxIdArrangementRuleModal(bool)),
  setIsShowingErisaMessageModal: bool =>
    dispatch(setIsShowingErisaMessageModal(bool)),
  setShowingHighDollarReviewModal: bool =>
    dispatch(setShowingHighDollarReviewModal(bool)),
  setIsShowingProviderInsuredOptionMessageModal: bool =>
    dispatch(setIsShowingProviderInsuredOptionMessageModal(bool)),
  setIsShowingGeneralMessageModal: bool =>
    dispatch(setIsShowingGeneralMessageModal(bool)),
  setIsShowingPDFTaggingModal: bool =>
    dispatch(setIsShowingPDFTaggingModal(bool)),
  setSelectedRuleRecord,
  updateCurrentTab: tab => dispatch(updateCurrentTab(tab)),
  triggerAllRulesForRuleTypesRequest: (...args) =>
    dispatch(triggerAllRulesForRuleTypesRequest(...args)),
  triggerRulesForRuleTypesPostRequest: (...args) =>
    dispatch(triggerRulesForRuleTypesPostRequest(...args)),
  triggerRulesForRuleTypesPutRequest: (...args) =>
    dispatch(triggerRulesForRuleTypesPutRequest(...args)),
  goBackFromAdvancedToDefaultView: (...args) =>
    dispatch(setIsViewingRuleDetails(...args)),
  groupAssociationsForRuleIdTrigger: id =>
    dispatch(groupAssociationsForRuleIdTrigger({ ruleId: id })),
  setIsShowingDeleteRuleDetailModal: payload =>
    dispatch(setIsShowingDeleteRuleDetailModal(payload)),
  deleteRuleDetailTrigger: (...args) =>
    dispatch(deleteRuleDetailTrigger(...args)),
  setIsShowingDetailCloneRuleModal: payload =>
    dispatch(setIsShowingDetailCloneRuleModal(payload)),
  setIsCloningRuleState: payload => dispatch(setIsCloningRuleState(payload)),
  overwriteTestRuleRequestTrigger: payload =>
    dispatch(overwriteTestRuleRequestTrigger(payload)),
  getAddNewAddressElements: payload =>
    dispatch(getAddNewAddressElementsTrigger(payload)),
  postAddNewAddressElements: payload =>
    dispatch(postAddNewAddressElementsTrigger(payload)),
  setIsShowingAddNewAddressModal: bool =>
    dispatch(setIsShowingAddNewAddressModal(bool)),
  setIsShowingOverwriteModal: bool =>
    dispatch(setIsShowingOverwriteModal(bool)),
  setIsShowingImageLibraryModal: (ruleType, selectedRecordId, bool) =>
    dispatch(setIsShowingImageLibraryModal(ruleType, selectedRecordId, bool)),
  setIsShowingImageUploadModal: (ruleType, selectedRecordId, bool) =>
    dispatch(setIsShowingImageUploadModal(ruleType, selectedRecordId, bool)),
  postImageUpload: payload =>
    dispatch(postImageUploadTrigger(payload)),
  setIsShowingActiveGroupsListingModal: payload =>
    dispatch(setIsShowingActiveGroupsListingModal(payload)),
  getRuleImages: payload =>
    dispatch(getRuleImagesTrigger(payload)),
  getRuleImagesCount: payload =>
    dispatch(getRuleImagesCountTrigger(payload)),
  resetRuleImagesData: payload => 
    dispatch(resetRuleImagesDataTrigger(payload)),
  convertImageUpload: payload =>
    dispatch(convertImageUploadTrigger(payload)),
  postCommitImage: payload =>
    dispatch(postCommitImageTrigger(payload)),
  setIsShowingCommitImageModal: payload =>
    dispatch(setIsShowingCommitImageModal(payload)),
  postCommitImageTriggerCancel: payload =>
    dispatch(postCommitImageTriggerCancel(payload)),
  captureCurrentPage: payload => 
    dispatch(captureCurrentPage(payload)),
  setIsShowingRevertRuleModal: payload => 
    dispatch(setIsShowingRevertRuleModal(payload)),
  getRevertRuleVersions: payload => 
    dispatch(getRevertRuleVersions(payload)),
  postRevertRuleVersion: payload => 
    dispatch(postRevertRuleVersion(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RulesTabWrapper);