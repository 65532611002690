/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { useDispatch, } from 'react-redux';
import { useLayout, Button } from 'ui-core/dist'
import { LoadingPage, CountDownTimer } from '../../../components';
import ErrorPage from '../../ErrorPage';
import {
  GENERIC_TEXT1,
  GENERIC_TEXT2,
  GENERIC_TEXT_ROUTE,
  GENERIC_WARNING_TEXT_ROUTE,
} from "../Modules/constants";
import withAxiosCancellation from '../../../components/withAxiosCancellation'
import ListPaneHeaderWrapper from '../../../components/ErrorDisplaySection/ListPaneHeaderWrapper';
import DocManagerFileGroupSearchForm from '../DocManagerFileGroupSearchForm';
import { useCurrentTime, useReleaseTime } from '../../../api/useDocumentManager';
import { setFileSearchParam, setFileSearchParamNull } from "../Modules/action";

import '../Modules/styles.scss';

const GroupSearchRouteMain = ({getCancelSourceWithName}) => {
  const {
    expandCollapseListPaneButtonConfig
  } = useLayout()

  const history = useHistory();
  const dispatch = useDispatch();

  const [isResetFlag, setIsResetFlag] = useState(false);
  const [fileKeys, setFileKeys] = useState('');
  const [empGroup, setEmpGroup] = useState('');
  const [itemsPerPage, setItemsPerPage] = useState({ label: 10, value: 10 });
  const [hasLimitExceededstate, sethasLimitExceededstate] = useState(false)
  const { currentTime, isError: isErrorCurrentTime, error: errorCurrentTime, isLoading: isLoadingCurrentTime } = useCurrentTime({getCancelSource:getCancelSourceWithName('searchRouteGroupPageCurrentTime')});
  const { releaseTime, isError: isErrorReleaseTime, error: errorReleaseTime, isLoading: isLoadingReleaseTime } = useReleaseTime({getCancelSource:getCancelSourceWithName('searchRouteGroupPageReleaseTime')});
  // const isLoading = false;
  // const isError = false;
  // const error = '';
  const [transactionMessage, settransactionMessage]=useState('')

  useEffect(()=>{
    if (history?.location && history?.location?.state) {
      settransactionMessage(history?.location?.state);
      history.replace({ ...history?.location, state: undefined });
    }
  },[history?.location])
  const onSelectEmpGroup = (items) => {
    let egroup = '';
    if (hasLimitExceededstate) {
      egroup = items.value || ""
    } else if (items && items.length > 0 && items.indexOf('') === -1) {
      egroup = '<![CDATA[<EmpGroupIds>'
      items.forEach(item => {
        egroup += `<EmpGroupId>${item}</EmpGroupId>`
      })
      egroup += '</EmpGroupIds>]]>'
    }
    setEmpGroup(egroup);
  }

  const nextClick = () => {
    const payload = {
      "maxRecords": itemsPerPage ? itemsPerPage.value : 10,
      "rowPosition": 0,
      "sortField": 'filekey',
      "sortDescending": true,
      "checkNumber": '',
      "claimNumber": '',
      "groupId": empGroup,
      "idNumber": '',
      "filekey": fileKeys,
      "showDuplicateChecks": true,
      "showProcessedDocuments": false
    }
    dispatch(setFileSearchParamNull(payload))
    dispatch(setFileSearchParam(payload))
    history.push(`/DocumentManager/Route/Group/searchResults`)
  }

  return (
    <>
      <ListPaneHeaderWrapper
        title="Document Manager: Route Groups"
        layoutControls={[expandCollapseListPaneButtonConfig]}

      />
      {isLoadingCurrentTime || isLoadingReleaseTime ? (
        // replace with a better loading component
        <LoadingPage />
      ) : isErrorCurrentTime || isErrorReleaseTime ? (
        // replace with a better error component
        <ErrorPage
          // this prop isn't real but is an example
          error={errorCurrentTime || errorReleaseTime}
        />
      ) : (
            <div className="mainWrapper">
              {releaseTime && currentTime ?
                <CountDownTimer
                  startDate={releaseTime}
                  endDate={currentTime}
                /> : ''}
              <div style={{ fontSize: "small", padding: "12px" }}>
                <i>{GENERIC_TEXT1}</i>
                <p>Type a complete or a partial claim number, check number, TIN, or SSN to search for specific documents or a range of documents. 
                  You can also select a group or file to display all documents within the group or file. 
                  Click Next to display the search results page where you can update the routing information for the documents you select.</p>
                <div style={{ color: "#bf1616" }}>
                  <p>
                    {GENERIC_TEXT_ROUTE}
                  </p>
                  <p>
                    {GENERIC_WARNING_TEXT_ROUTE}
                  </p>
                  {transactionMessage && <p style={{ color: "black", fontWeight:'bold' }}>{transactionMessage}</p>}
                </div>
              </div>
              <DocManagerFileGroupSearchForm
              getCancelSourceWithName={getCancelSourceWithName}
                isCheckNumberExist={false}
                isClaimNumberExist={false}
                isSSNExist={false}
                changeItemsPerPage={(value) => setItemsPerPage(value)}
                onSelectEmpGroup={(value) => onSelectEmpGroup(value)}
                changeFileKey={(value) => setFileKeys(value)}
                hasLimitExceeded={(value) => sethasLimitExceededstate(value)}
                isSortingExist={false}
                isResetFlag={isResetFlag}
                setIsResetFlag={setIsResetFlag}
                perPage={itemsPerPage}
                nextClick={nextClick}
              />
              <div className="displayFlex marginLeft">
                <Button
                  buttonType="standard"
                  size="small"
                  name="RESET"
                  onClick={() => {
                    setIsResetFlag(true);
                  }}
                />
                <Button
                  buttonType="standard"
                  size="small"
                  style={{ marginLeft: '10px' }}
                  name="NEXT->"
                  onClick={() => nextClick()}
                />
              </div>
            </div>
          )}
    </>
  )
}

export default withAxiosCancellation(GroupSearchRouteMain)
