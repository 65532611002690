import styled from 'styled-components';
import { Input, Button } from 'ui-core-ssgr';

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 1em;
`;

export const RuleNameInputWrapper = styled.div`
  flex: 1 0 0;
  padding: 2.5em 2em 3.5em 1em;
`;

export const RuleDescriptionInputWrapper = styled.div`
  flex: 2 0 0;
  padding: 2.5em 1em 3.5em 1em;
`;

export const RuleNameInput = styled(Input)`
  width: 100%;
`;

export const RuleDescriptionInput = styled(Input)`
  width: 100%;
`;

export const InputLabelWrapper = styled.div`
  text-transform: uppercase;
  font-size: 11px;
  margin-bottom: 0.3rem;
  line-height: 1.4em;
  font-weight: 700;
  letter-spacing: 0.5px;
`;

export const RuleInputLabel = styled.div`
  font-size: 13px;
  margin-bottom: 0.3rem;
  line-height: 1.4em;
  letter-spacing: 0.5px;
`;

export const OverwriteRuleButton = styled(Button)`
  position: absolute;
  right: 2.5em;
`;
