/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
import React, { useState, useRef, useEffect, useMemo } from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import {
  Input,
} from 'ui-core/dist';
import { SelectDropdown } from '../../components'
import { FILEKEY_LIST_NOT_AVAILABLE, SELECT_RECORD_OPTIONS } from "./Modules/constants";

import MultipleSelectDropdown from '../../components/MultipleSelectDropdown';
import './Modules/styles.scss';
import SortingWrapper from '../../components/SortingWrapper';
import EmployerGroup from '../../components/EmployerGroup';
import useHandleEnterKeyPressEvent from './Modules/useHandleEnterKeyPressEvent';

const StyledTableContainer = styled.div`
 &>table>tr>td:first-child{
  width:130px;
}`;

const InputWrapper = styled.div`
  display: flex;
  
  margin-bottom: 10px;
  justify-content: space-between;
  & div:first-child{
    width:200px !important;
  }`;


const ItemsDropdownWrapper = styled.div`
  width: 52%;
`;

const DocManagerFileGroupSearchForm = ({
  isCheckNumberExist,
  isRelease=false,
  isClaimNumberExist,
  isSSNExist,
  onSelectEmpGroup,
  // onSelectFileKey,
  isSortingExist,
  isResetFlag,
  setIsResetFlag,
  changeClaimNo = () => { },
  changeCheckNo = () => { },
  changeFileKey = () => { },
  changeTinSsn = () => { },
  changeSortGridColumn = () => { },
  changeItemsPerPage = () => { },
  perPage = () => { },
  changeSortGridOrder = () => { },
  hasLimitExceeded,
  nextClick = () => false,
  getCancelSourceWithName=()=>false
}) => {
  const selectClick = useRef();
  selectClick.current = onSelectEmpGroup;
  const changeClaimNos = useRef();
  changeClaimNos.current = changeClaimNo
  const changeCheckNos = useRef();
  changeCheckNos.current = changeCheckNo
  const selectFileKeys = useRef();
  selectFileKeys.current = changeFileKey
  const changeTinSsns = useRef();
  changeTinSsns.current = changeTinSsn
  const changeSortGridColumns = useRef();
  changeSortGridColumns.current = changeSortGridColumn;
  const changeItemsPerPages = useRef();
  changeItemsPerPages.current = changeItemsPerPage
  const changeSortGridOrders = useRef();
  changeSortGridOrders.current = changeSortGridOrder;
  const hasLimitExceededClick = useRef();
  hasLimitExceededClick.current = hasLimitExceeded;
  const setIsResetFlagClick = useRef();
  setIsResetFlagClick.current = setIsResetFlag;
  // const [itemsPerPage, setItemsPerPage]=useState({label:10, value:10});

  const config = {
    fields: [{
      fieldName: 'claimNumber',
      validator: (value) => value ? value.length <= 30 : true
    }, {
      fieldName: 'checkNumber',
      validator: (value) => value ? value.length <= 30 : true
    }, {
      fieldName: 'tinSsn',
      validator: (value) => value ? value.length <= 30 : true
    }],
    onEnterKeyPressHandler: nextClick
  }
  const { handleEnterKeyPressEvent } = useHandleEnterKeyPressEvent(config)

  const [initialValueEmpGroup, setInitialValueEmpGroup] = useState(null)
  const [hasLimitExceededState, sethasLimitExceededState] = useState(null);
  const [selectedFileKeys, setselectedFileKeys] = useState([]);
  const [tinSSN, setTinSSN] = useState('');
  const [checkNumber, setCheckNumber] = useState('');
  const [claimNumber, setClaimNumber] = useState('');


  const constructFileKeys = (fileKeys) => {
    let xml = ''
    if (fileKeys && fileKeys.length > 0 && fileKeys.indexOf('') === -1) {
      xml = '<![CDATA[<filekeys>'
      fileKeys.forEach((item) => {
        xml += `<filekey>${item}</filekey>`
      })
      xml += '</filekeys>]]>'
    }
    setselectedFileKeys(JSON.parse(JSON.stringify(fileKeys)));
    selectFileKeys.current(xml)
  }

  const renderClaimNumberLabel = (elem) => (
    <td>{elem}</td>
  )

  const onChangeTinSSN = (value) => {
    if (changeTinSsns.current) changeTinSsns.current(value)
    setTinSSN(value)
  }

  const onChangeClaimNumber = (value) => {
    if (changeClaimNos.current) changeClaimNos.current(value)
    setClaimNumber(value)
  }

  const onChangeCheckNumber = (value) => {
    if (changeCheckNos.current) changeCheckNos.current(value)
    setCheckNumber(value)
  }

  useEffect(() => {
    if (isResetFlag) {
      changeClaimNos.current('')
      changeCheckNos.current('')
      changeTinSsns.current('');
      selectFileKeys.current('')
      changeItemsPerPages.current({ label: 10, value: 10 })
      setTinSSN('');
      setClaimNumber('');
      setCheckNumber('');

      setInitialValueEmpGroup(hasLimitExceededState ? '' : [])
      setselectedFileKeys(JSON.parse(JSON.stringify([])));
      changeSortGridOrders.current('')
      changeSortGridColumns.current('')

      setIsResetFlagClick.current(false)
    }
  }, [isResetFlag])

  const empGroup = useMemo(() => (
    <EmployerGroup
    getCancelSourceWithName={getCancelSourceWithName}
      selectInputLabelWidth="100%"
      selectInputWidth="350px"
      labelContainer={renderClaimNumberLabel}
      inputContainer={renderClaimNumberLabel}
      onSelect={(value) => {
        setInitialValueEmpGroup(value)
        if (selectClick.current) selectClick.current(value)
      }}
      onSelectRow={(value) => {
        setInitialValueEmpGroup(value)
        if (selectClick.current) selectClick.current(value)
      }}
      hasLimitExceeded={(value) => {
        sethasLimitExceededState(value);
        if (hasLimitExceededClick.current) hasLimitExceededClick.current(value)
      }}
      initialValue={initialValueEmpGroup}
    />
  ), [initialValueEmpGroup, hasLimitExceededState])
  return (
    <>
      <div>
        <div className="marginLeft">
          <StyledTableContainer style={{ paddingBottom: '17px' }}>
            <table>
              {isClaimNumberExist ? (
                <>
                  <tr>
                    <td>
                      <label>Claim Number:</label>
                    </td>
                    <td>
                      <InputWrapper>
                        <Input
                          domID="claimNumber"
                          size="small"
                          maxLength={3000}
                          initialValue={claimNumber}
                          onChange={(e) => onChangeClaimNumber(e.target.value)}
                          onKeyUp={(e) => {
                            handleEnterKeyPressEvent(e, e?.currentTarget?.id, claimNumber)
                          }}
                        />
                      </InputWrapper>
                    </td>
                  </tr>
                  <tr><td><p></p></td></tr>
                </>
              ) : null}
              {isCheckNumberExist ? (
                <>
                  <tr>
                    <td>
                      <label>Check Number:</label>
                    </td>
                    <td>
                      <InputWrapper>
                        <Input
                          domID="checkNumber"
                          size="small"
                          maxLength={3000}
                          initialValue={checkNumber}
                          onChange={(e) => onChangeCheckNumber(e.target.value)}
                          onKeyUp={(e) => {
                            handleEnterKeyPressEvent(e, e?.currentTarget?.id, checkNumber)
                          }}
                        />
                      </InputWrapper>
                    </td>
                  </tr>
                  <tr><td><p></p></td></tr>
                </>
              ) : null}
              {isSSNExist ? (
                <>
                  <tr>
                    <td>
                      <label>TIN/SSN:</label>
                    </td>
                    <td>
                      <InputWrapper>
                        <Input
                          domID="tinSsn"
                          size="small"
                          maxLength={30}
                          initialValue={tinSSN}
                          onChange={(e) => onChangeTinSSN(e.target.value)}
                          onKeyUp={(e) => {
                            handleEnterKeyPressEvent(e, e?.currentTarget?.id, tinSSN)
                          }}
                        />
                      </InputWrapper>
                    </td>
                  </tr>
                  <tr><td><p></p></td></tr>
                </>
              ) : null}
              <tr>
                {empGroup}
              </tr>
              <tr><td><p></p></td></tr>
              <tr>
                <MultipleSelectDropdown
                getCancelSource={getCancelSourceWithName('filekey')}
                  id='fileKey'
                  methodType='POST'
                  fetchDataURL={isRelease ? 'documentManager/getFileKeysAvailableForRelease' : 'documentManager/getEligibleFileKeys'}
                  label='File Key(s):'
                  errorMessage={FILEKEY_LIST_NOT_AVAILABLE}
                  initialValue={selectedFileKeys}
                  effect={(value) => {
                    constructFileKeys(value)
                  }}
                  displayField='label'
                  valueField='value'
                  displayDefault
                  defaultText
                  labelContainer={renderClaimNumberLabel}
                  inputContainer={renderClaimNumberLabel}
                  selectInputLabelWidth='100%'
                />
              </tr>
              <tr><td><p></p></td></tr>
              {isSortingExist ? (
                <>
                  <SortingWrapper
                    onChangeSortField={(value) => changeSortGridColumns.current ? changeSortGridColumns.current(value) : () => { }}
                    isResetFlag={isResetFlag}
                    setIsResetFlag={setIsResetFlag}
                    onChangeSortOrder={(value) => changeSortGridOrders.current ? changeSortGridOrders.current(value) : () => { }}
                    labelContainer={renderClaimNumberLabel}
                    inputContainer={renderClaimNumberLabel}
                  />
                  <tr><td><p></p></td></tr>
                </>
              ) : null}
              <tr>
                <td>
                  <label>Items Per Page:</label>
                </td>
                <td>
                  <ItemsDropdownWrapper>
                    <SelectDropdown
                      domID="itemPerPage"
                      onChange={(e) => changeItemsPerPages.current ? changeItemsPerPages.current(e) : () => { }}
                      initialValue={{label: perPage?.label?.toString(), value: perPage?.value?.toString()}}
                      options={SELECT_RECORD_OPTIONS}
                      size="small"

                    />
                  </ItemsDropdownWrapper>
                </td>
              </tr>
              <tr><td><p></p></td></tr>
              {/* </ItemsWrapper> */}
            </table>
          </StyledTableContainer>
        </div>
      </div>
    </>
  )
}

DocManagerFileGroupSearchForm.propTypes = {
  isCheckNumberExist: PropTypes.string,
  isClaimNumberExist: PropTypes.string,
  isSSNExist: PropTypes.string,
  onSelectEmpGroup: PropTypes.func,
  isSortingExist: PropTypes.string,
  setIsResetFlag: PropTypes.func
}

export default DocManagerFileGroupSearchForm
