import { createSelector } from 'reselect';
import find from 'lodash/find';
import get from 'lodash/get';

const selectRuleTypesListState = state => state.ruleTypesList;

export const selectRuleTypesSearchResults = state =>
  state.ruleTypesList.ruleTypesSearchResults;

const setRuleTypesListRecordsState = state =>
  state.ruleTypesList.setRuleTypesListRecords;

export const selectIsViewingRuleDetails = createSelector(
  selectRuleTypesListState,
  ruleTypesListState => ruleTypesListState.isAdvancedViewShown,
);

export const selectRuleTypesSearchQuery = state =>
  state.ruleTypesList.ruleTypesSearchQuery;

export const selectSelectedRuleTypeId = state =>
  state.ruleTypesList.selectedRuleTypeId;

export const selectSessionTimeout = state =>
  state.serverConfig.sessionTimeout;

export const selectSelectedRuleRecord = createSelector(
  selectRuleTypesListState,
  selectedRule => selectedRule.selectedRuleRecord,
);

export const ruleTypeModelIsOpen = createSelector(
  setRuleTypesListRecordsState,
  setRuleTypesListState => setRuleTypesListState.length > 0,
);

export const selectNotificationState = state =>
  state.notificationState.notifications;

export const selectLoadingState = state => state.loadingState.loading;

export const selectRuleTypesAccumRecords = state => state.ruleTypesList.ruleTypesAccumRecords;

export const setRulesWithAssociations = state => {
  const { selectedRuleTypeId } = state.ruleTypesList;
  const { records } = state.rulesForRuleTypesList;
  const selectedRuleType = find(records, record => record.id === selectedRuleTypeId);

  return get(selectedRuleType, 'rules', []);
}

export const selectClientInfo = state => {
  return {
    groupsList: state.groupsList,
    serverConfig: state.serverConfig,
    ruleTypesList: state.ruleTypesList,
    pageId: state.pageTracker.pageId || ''
  };
}