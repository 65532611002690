/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import styled from 'styled-components/dist/styled-components'
import { useLayout } from 'ui-core/dist/useLayout';
import DetailPaneHeader from 'ui-core/dist/DetailPaneHeader';
import { useSelector, useDispatch } from 'react-redux';
import {
  Modal, Button, ModalHeader, Input, TextArea, ModalBody
} from 'ui-core/dist';
import { SelectDropdown } from '../../components';
import { detailPaneAnimStates } from '../../commonResources/constants/animStates';
import ErrorPage from '../ErrorPage';
import LoadingComponent from '../../components/LoadingComponent';
import useNotificationBannerHook from '../../useNotificationBannerHook';
import {
  useGetSelectedStateCopyData,
  useAddStateCopyRule,
  useUpdateStateCopy
} from '../../api/useClientRuleManager'
import {setDraftCodeData} from './Modules/action'
const DraftCodeDetails = () => {
  const {
    expandCollapseDetailPaneButtonConfig,
    closeDetailPaneButtonConfig,
    detailAnimationState,
    closeDetailPane
  } = useLayout()
  const dispatch = useDispatch();
  const { notify } = useNotificationBannerHook();
  const [state, onChangeState] = useState({label:'None',value:''})
  const [info, onChangeInfo] = useState({label:'No Copies',value:''})
  const [activeFlag, setActiveFlag] = useState('true')
  const [editorMode, setEditorMode] = useState('Add')
  const [confirmationModalMode, setconfirmationModalMode] = useState('')
  const [isOpenConfirmationModal, setisOpenConfirmationModal] = useState(false)

  const [count, setcount] = useState(0)

  const { terroristExclusionSelectedRecord, terroristExclusionEditorMode, refetchterroristExclusionRecord }
    = useSelector(({ clientRule }) => clientRule);

  useEffect(() => {
    if (terroristExclusionSelectedRecord) {
      if (terroristExclusionSelectedRecord.state) {
        getShowMoreData(terroristExclusionSelectedRecord.state);
      } else {
        setEditorMode("Add")
        onChangeState({label:'None',value:''})
        onClearCurrentRecordValues()
        
      }
    }
  }, [terroristExclusionSelectedRecord]);

  const getShowMoreData = (key) => {
    postDataShowMoreInfo({ strState:key,count})
    setcount(count + 1)
  }
  
  const { showMoreInfo, postData: postDataShowMoreInfo, isLoading: isLoadingShowMoreInfo,
    isError: isErrorShowMoreInfo, error: errorShowMoreINfo } = useGetSelectedStateCopyData()

  const { data: infoAddAddressExclusion, postData: addStateCopyRule, metaData: addTerroristExclusionResponse, isLoading: isLoadingaddTerroristExclusion,
    isError: isErroraddTerroristExclusion, error: erroraddTerroristExclusion } = useAddStateCopyRule()

  const { data: infoModifyAddressExclusion, postData: modifyStateCopy, metaData: modifyTerroristExclusionResponse, isLoading: isLoadingmodifyTerroristExclusion,
    isError: isErrormodifyTerroristExclusion, error: errormodifyTerroristExclusion } = useUpdateStateCopy()

  const onResetCurrentRecordValues = () => {
    if (showMoreInfo) {
      const statea = showMoreInfo?.state
      const infoData = showMoreInfo?showMoreInfo['state-routing-instruction']:''
      let labelInfo;
      onChangeState({label:statea==='MI'?'Michigan': 'OK' ? 'Oklahoma':'None',value:statea})
      if(infoData === '1'){
        labelInfo = 'Route Copy to Master Delivery Address'
      }else if(infoData === '2'){
        labelInfo = 'Route Copy to 1099 Address'
      }else{
        labelInfo = 'No Copies'
      }
      onChangeInfo({label:labelInfo,value:infoData})
      setActiveFlag(showMoreInfo?.active)
      setEditorMode(terroristExclusionEditorMode)
    }
  }

  useEffect(() => {
    if (addTerroristExclusionResponse) {
      const { status, statusText } = addTerroristExclusionResponse;
      if (status === 200 || statusText === "OK") {
        if (infoAddAddressExclusion && infoAddAddressExclusion.fs) {
          const { fs } = infoAddAddressExclusion;
          if (fs) {
            notify(fs, 'negative')
            return;
          }
        }
        notify('1099 State Copy Rule Added successfully', 'positive')
        closeDetailPane()
        if (refetchterroristExclusionRecord) refetchterroristExclusionRecord()
      }
    }
  }, [addTerroristExclusionResponse])

  useEffect(() => {
    if (modifyTerroristExclusionResponse) {
      const { status, statusText } = modifyTerroristExclusionResponse;
      if (status === 200 || statusText === "OK") {
        if (infoModifyAddressExclusion && infoModifyAddressExclusion.fs) {
          const { fs } = infoModifyAddressExclusion;
          if (fs) {
            notify(fs, 'negative')
            return;
          }
        }
        notify('1099 State Copy Rule Updated successfully', 'positive')
        closeDetailPane()
        if (refetchterroristExclusionRecord) refetchterroristExclusionRecord()
      }
    }
  }, [modifyTerroristExclusionResponse])

  const onSubmitClick = () => {
    if (editorMode === "Add") {
      setconfirmationModalMode("Add")
    } else if (editorMode === "Edit") {
      setconfirmationModalMode("Modify")
    }
    setisOpenConfirmationModal(true)
  }
  const constructXMl = useCallback(() => {
    let xml = '';
    xml = '<![CDATA[<state-copy-1099>'
    xml += `<state>${state.value}</state>`
    xml += `<state-routing-instruction>${info.value}</state-routing-instruction>`
    xml += `<active>${activeFlag}</active>`
    xml += '</state-copy-1099>]]>'
    return xml
  },[activeFlag,state,info])
  const onConfirmationYesClick = () => {
    if (confirmationModalMode === "Add") {
      addStateCopyRule({
        count,
        stateCopy1099Xml:constructXMl()
      })
      setcount(count + 1)

    }else if(confirmationModalMode === "Modify"){
      modifyStateCopy({
        count,
        stateCopy1099Xml:constructXMl()
      })
      setcount(count + 1)
    }
    else if (confirmationModalMode === "Change Mode") {
      let xml = '';
      let activate
      if(activeFlag==='true'){
        activate = false
      }else{
        activate = true
      }
      
      xml = '<![CDATA[<state-copy-1099>'
      xml += `<state>${state.value}</state>`
      xml += `<state-routing-instruction>${info.value}</state-routing-instruction>`
      xml += `<active>${activate}</active>`
      xml += '</state-copy-1099>]]>'
      
      modifyStateCopy({
        count,
        stateCopy1099Xml:xml

      })
      setcount(count + 1)
    }
    setconfirmationModalMode('')
    setisOpenConfirmationModal(false)
  }
  // const onActivateRecord = ()=>{
  //   addStateCopyRule({
  //       count,
  //       stateCopy1099Xml:constructXMl()
  //     })
  //     setcount(count + 1)
  // }
  useEffect(() => {
    onResetCurrentRecordValues()
  }, [showMoreInfo])

  const onClearCurrentRecordValues = useCallback(() => {
    if(editorMode==="Add")
      onChangeState({label:'None',value:''})
    onChangeInfo({label:'No Copies',value:''})
  },[editorMode])
  const renderState = useMemo(() => (
    <SelectDropdown
      width="230px"
      domID="defer_state_input"
      disabled={editorMode==="Edit"}
      // onChange={(e, state) => this.props.handleDateRangeSelect(e, state)}
      options={[{label:'None',value:''},{label:'Michigan',value:'MI'},{label:'Oklahoma',value:'OK'}]}
      size="small"
      initialValue={state}
      onChange={(e)=>onChangeState(e)}
    />
  ), [state,editorMode])
  const renderInfo = useMemo(() => (
    <SelectDropdown
      width="330px"
      domID="defer_state_input"
      // onChange={(e, state) => this.props.handleDateRangeSelect(e, state)}
      options={[{label:'No Copies',value:''},{label:'Route Copy to Master Delivery Address',value:'1'},{label:'Route Copy to 1099 Address',value:'2'}]}
      size="small"
      initialValue={info}
      onChange={(e)=>onChangeInfo(e)}
    />
  ), [info])
  
  
  const validateForm = useMemo(() => {
    let errorCount = 1
    if(state && state.label!=='None'){
      errorCount=0
      //   if ((draftCode && draftCode.length > 5)
      //   ){
      //     errorCount=1
      //   }
      
    }
    

    if(!errorCount) {
      return true
    }

  }, [state, info])

  const renderAddressEntryForm = useMemo(() => {
    if (detailAnimationState > detailPaneAnimStates.COLLAPSED) {
      return (
        <div style={{ marginLeft: '40px' }} className="marginLeft">
          <div>
            <p>
              <div className="displayFlex">Complete the information below, and then click Submit to continue.</div>
            </p>
            <p>
              <div className="displayFlex"><span style={{ color: 'red' }}>* indicates required information</span></div>
            </p>
          </div>
          <br />
          <div className="displayFlex">
            <table className="common-table">
              <tr>
                <td>
                  <label> State : <span style={{ color: 'red' }}>*</span></label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderState}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <label> Routing Instructions: :</label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderInfo}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td colSpan="2">
                  <div className="displayFlex">
                    <div className="marginLeft">
                      <Button
                        domID="btn_reset"
                        buttonType="standard"
                        size="small"
                        name="RESET"
                        disabled={editorMode !== "Edit"}
                        onClick={() => onResetCurrentRecordValues()}
                      />
                    </div>
                    <div className="marginLeft">
                      <Button
                        domID="btn_copy"
                        buttonType="standard"
                        size="small"
                        name="COPY"
                        disabled={editorMode === "Add"}
                        onClick={() => {
                          setEditorMode("Add")
                        }}
                      />
                    </div>
                    <div className="marginLeft">
                      <Button
                        domID="btn_cancel"
                        buttonType="standard"
                        size="small"
                        name="CLEAR"
                        onClick={() => onClearCurrentRecordValues()}
                      />
                    </div>
                    <div className="marginLeft">
                      <Button
                        domID="btn_cancel"
                        buttonType="standard"
                        size="small"
                        name={activeFlag==='true'?'DEACTIVATE':'REACTIVATE'}
                        disabled={editorMode === "Add"}
                        onClick={() => {
                          setconfirmationModalMode('Change Mode')
                          setisOpenConfirmationModal(true)
                        }}
                      />
                    </div>
                    <div className="marginLeft">
                      <Button
                        domID="btn_search"
                        buttonType="standard"
                        size="small"
                        name="SUBMIT"
                        disabled={!validateForm}
                        onClick={() => onSubmitClick()}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
            </table>
          </div>
          <Modal isOpen={isOpenConfirmationModal}>
            <ModalHeader
              title={`Do you want to ${confirmationModalMode} ?`}
              onClose={() => setisOpenConfirmationModal(false)}
            />
            <ModalBody>
              {isOpenConfirmationModal ? (
                <div style={{ marginLeft: '34px', marginBottom: '20px' }} className="marginLeft">
                  <div className="displayFlex">
                    <div className="marginLeft">
                      <Button
                        domID="btn_search"
                        buttonType="standard"
                        size="small"
                        name="Yes"
                        onClick={() => onConfirmationYesClick()}
                      />
                    </div>
                    <div className="marginLeft">
                      <Button
                        domID="btn_search"
                        buttonType="standard"
                        size="small"
                        name="No"
                        onClick={() => setisOpenConfirmationModal(false)}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
            </ModalBody>
          </Modal>
        </div>
      )
    }
    return null;
  }, [terroristExclusionSelectedRecord, showMoreInfo, detailAnimationState, activeFlag, editorMode,
    state, info, validateForm, terroristExclusionEditorMode, confirmationModalMode, isOpenConfirmationModal])

  return (
    <>
      <DetailPaneHeader
        title={editorMode === "Edit" ? "Modify 1099 State Copy Rule" : "Add 1099 State Copy Rule"}
        layoutControls={[
          expandCollapseDetailPaneButtonConfig,
          closeDetailPaneButtonConfig
        ]}
      />
      <>
        {isLoadingShowMoreInfo || isLoadingaddTerroristExclusion
          || isLoadingmodifyTerroristExclusion ? (
            // replace with a better loading component            
            <div style={{ marginLeft: '50%' }}><LoadingComponent /></div>
          ) : isErrorShowMoreInfo || isErroraddTerroristExclusion
            || isErrormodifyTerroristExclusion ? (
              // replace with a better error component
              <ErrorPage
                // this prop isn't real but is an example
                error={errorShowMoreINfo || erroraddTerroristExclusion
                  || errormodifyTerroristExclusion}
              />
            ) : (
              <>
                {renderAddressEntryForm}
              </>
            )
        }
      </>
    </>
  )
}

export default DraftCodeDetails