import { useAxios } from '../hooks'

const useDeliveryReport = (props={}) => {
  const { data, ...rest } = useAxios({
    ...props,
    url: `/reports-manager/delivery-times-with-states`,
  })

  return { 
    data: data?.report || '',
    ...rest
  }
}

export default useDeliveryReport