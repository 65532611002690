const Statuses = Object.freeze({
  TEST: Symbol(0),
  TEST_LIVE: Symbol(1),
  EDITED_TEST_LIVE: Symbol(2),
  NO_VALID: Symbol(3)
});

const isTestOnlyNoLiveVersionCommitted = element => element === 'T';
const isTestHasNotBeenEditedTestIsTheSameAsLive = element => element === 'L';
const isTestIsBeingEditedAndIsDifferentThanLiveVersion =
  set => set.has('L') && set.has('T');

const evaluateUniqueElement = input =>
  // eslint-disable-next-line no-nested-ternary
  isTestOnlyNoLiveVersionCommitted(input[0]) ? Statuses.TEST :
    isTestHasNotBeenEditedTestIsTheSameAsLive(input[0]) ? Statuses.TEST_LIVE :
      Statuses.NO_VALID;

const evaluateTwoElements = input =>
  isTestIsBeingEditedAndIsDifferentThanLiveVersion(new Set(input))?
    Statuses.EDITED_TEST_LIVE:Statuses.NO_VALID;

const getStatus = input =>
  // eslint-disable-next-line no-nested-ternary
  input.length === 1 ? evaluateUniqueElement(input) :
    input.length === 2 ? evaluateTwoElements(input):
      Statuses.NO_VALID;

module.exports.Statuses = Statuses;
module.exports.getStatus = getStatus;
