/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useMemo } from 'react';
import { Grid, ListPaneHeader, useLayout, Card } from 'ui-core/dist'
import { useDispatch, useSelector } from 'react-redux';
import { LoadingPage } from '../../components';
import ErrorPage from '../ErrorPage';
import { useAxios } from '../../hooks';
import PaymentAndSaving from './PaymentAndSaving';
import ProductMetrics from './ProductMetrics';
import Announcements from './Announcements';
import Contacts from './Contacts';
import USDeliveryTime from './USDeliveryTime';
import ServiceRequest from './ServiceRequest';
import ContextErrors from './ContextErrors';
import TimeoutNotification from '../../components/TimeoutNotification';
import {
  setSelected,
} from '../SideNav/modules/actions';
import './Modules/styles.scss';
import { useDashbordPaymentAndSavings } from '../../api/useDashboard';

// EXAMPLE
// TODO: REPLACE ME WITH REAL CODE LATER
// https://prod.storybooks.awsprod.healthcareit.net/?path=/story/layouts-page-layout--getting-started
const DashboardMain = () => {
  const {
    expandCollapseListPaneButtonConfig,
    openDetailPane,
  } = useLayout();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setSelected('/'));
  }, [])

  const { dataSourceDesc } = useSelector(({ context }) => context?.context || {});
  const clientId = dataSourceDesc.split('-')[0].trim().replace('_', '');
  const { data: payMentAndSavingData, isLoading: pIsLoading } = useDashbordPaymentAndSavings(clientId, 'payMentAndSavingData');

  const [paySave, setPaySave] = useState(false);
  React.useEffect(() => {
    if (!pIsLoading) {
      if (payMentAndSavingData) {
        // if (payMentAndSavingData.hidePayments == null  ) {
          if ((payMentAndSavingData?.hidePayments  || 0) == 0  ) {
          setPaySave(true);
        } else if (payMentAndSavingData?.hidePayments == 1) {
          setPaySave(false);
        }
      }
    }
  }, [pIsLoading, payMentAndSavingData]);
  // const paySaveCard = useMemo(() => {
  //   if (paySave) {
  //     return (<div className="cardSpacing">
  //       <Card
  //         domID="PaymentAndSavingCard"
  //         dataTestId="PaymentAndSavingCard"
  //         cardBody={PaymentAndSaving}
  //       />
  //     </div>)
  //   }
  // }, [paySave])

  return (
    <>
      <h1 className="dashboardHeader">Dashboard</h1>
      <TimeoutNotification />
      <div className="alignDashboardItems">
        <div className="cardSpacing">
          <Card
            domID="AnnouncementsCard"
            dataTestId="AnnouncementsCard"
            cardBody={Announcements}
          />
        </div>

        <div className="cardSpacing">
          <Card
            domID="ContactsCard"
            dataTestId="ContactsCard"
            cardBody={Contacts}
          />
        </div>

        <div className="cardSpacing">
          <Card
            domID="ProductMetricsCard"
            dataTestId="ProductMetricsCard"
            cardBody={ProductMetrics}
          />
        </div>

        <div className="cardSpacing">
          <Card
            domID="ContextErrorsCard"
            dataTestId="ContextErrorsCard"
            cardBody={ContextErrors}
          />
        </div>
  

        <div className="cardSpacing">
          <Card
            domID="USDeliveryCard"
            dataTestId="USDeliveryCard"
            cardBody={USDeliveryTime}
          />
        </div>

        <div className="cardSpacing">
          <Card
            domID="ServiceRequestCard"
            dataTestId="ServiceRequestCard"
            cardBody={ServiceRequest}
          />
        </div>
        {pIsLoading ? (<LoadingPage height='10vh' />) : paySave && <div className="cardSpacing">
        <Card
          domID="PaymentAndSavingCard"
          dataTestId="PaymentAndSavingCard"
          cardBody={PaymentAndSaving}
        />
      </div>}

      </div>
    </>
  );
}

export default DashboardMain
