import React from 'react';
import { ModalHeader } from 'ui-core';
import ErrorDisplaySection from './ErrorDisplaySection';

const ModalHeaderWrapper=({title, onClose})=>(
  <>
    <ModalHeader
      title={title}
      onClose={onClose}
    />
    <ErrorDisplaySection/>
  </>
)

export default ModalHeaderWrapper