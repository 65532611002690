/**
 * Produces console error about deprecated property
 * @param {string} deprecatedProperty - The name of the property which is no longer supported.
 * @param {string} componentName - The name of the current component.
 * @param {string} newProperty - The name of the property to be used instead of the deprecated property.
 */
export const errorAbout = (deprecatedProperty, componentName, newProperty) =>
  console.error(
    // eslint-disable-next-line max-len
    `'${deprecatedProperty}' is no longer supported in the ${componentName} component. Please use '${newProperty}' instead.`,
  );