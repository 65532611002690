/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import {
  Input,
  Button,
  ListPaneHeader,
  useLayout,
  StickyLayout,
} from 'ui-core/dist'
import { SelectDropdown } from '../../components'
import axios from '../../axios'
import useFormValues from '../../api/useFormValues'
import useFormDetail from '../../api/useFormDetail'
import LoadingComponent from '../../components/Loading';
import useNotificationBannerHook from '../../useNotificationBannerHook';
import './modules/index.scss';

const ReserveModifyDetail = () => {
  const {
    expandCollapseDetailPaneButtonConfig,
    closeDetailPaneButtonConfig,
    closeDetailPane,
    detailProps
  } = useLayout()

  const [response, setResponse] = useState();

  const [payload, setPayload] = useState({
    clientNum: '',
    sendData: '',
    formType: '',
    formDescription: '',
    formName: '',
    formName1: '',
    quadaddrSuppression: '',
    addrSuppression: '',
    billing: '',
    bdplxCheck: '',
    stockMod: '',
    imageType: '',
    imageDescription: '',
    imageName: '',
    imageActive: '',
    bnoChkOvly: '',
    bcntchkwht: '',
    formOrientation: '',
    mapName: '',
    mapDescription: '',
    bisActive: '',
    bdedupe: '',
    bdedupe: '',
    bcntchkwht: '',
    idx: 0
  });

  const clearPayload = () => {
    setPayload({
      clientNum: '',
      sendData: '',
      formType: '',
      formDescription: '',
      formName: '',
      formName1: '',
      quadaddrSuppression: '',
      addrSuppression: '',
      billing: '',
      bdplxCheck: '',
      stockMod: '',
      imageType: '',
      imageDescription: '',
      imageName: '',
      imageActive: '',
      bnoChkOvly: '',
      bcntchkwht: '',
      formOrientation: '',
      mapName: '',
      mapDescription: '',
      bisActive: '',
      bdedupe: '',
      bdedupe: '',
      bcntchkwht: '',
      idx: payload.idx + 1
    });
  }

  const { notify } = useNotificationBannerHook()

  const [formInputValues, setFormInputValues] = useState({
    imageType: [{
      label: 'LOGO',
      value: 'L'
    }, {
      label: 'SIGNATURE',
      value: 'S'
    }],
    imageActive: [{
      label: 'ACTIVE',
      value: '1'
    }, {
      label: 'INACTIVE',
      value: '0'
    }],
    bisActive: [{
      label: 'No',
      value: '0'
    }, {
      label: 'Yes',
      value: '1'
    }],
    formOrientation: [{
      label: 'Portrait',
      value: 'P'
    }, {
      label: 'Landscape',
      value: 'L'
    }],
    addrSuppression: [{
      label: 'No',
      value: '0'
    }, {
      label: 'Yes',
      value: '1'
    }],
    billing: [{
      label: 'No',
      value: 'N'
    }, {
      label: 'Yes',
      value: 'Y'
    }],
    bdplxCheck: [{
      label: 'No',
      value: '0'
    }, {
      label: 'Yes',
      value: '1'
    }],
    bnoChkOvly: [{
      label: 'No',
      value: '0'
    }, {
      label: 'Yes',
      value: '1'
    }],
    bdedupe: [{
      label: 'No',
      value: '0'
    }, {
      label: 'Yes',
      value: '1'
    }],
    bcntchkwht: [{
      label: 'No',
      value: '0'
    }, {
      label: 'Yes',
      value: '1'
    }],
  });

  const search = useLocation().search;
  const param = new URLSearchParams(search).get('param');

  const onSendDataChange = (state) => setPayload({ ...payload, sendData: state.value })
  const onFormTypeChange = (state) => setPayload({ ...payload, formType: state.value })
  const onStockModifierChange = (state) => setPayload({ ...payload, stockMod: state.value })
  const onFormOrientationChange = (state) => {
    setPayload({ ...payload, formOrientation: state.value });
  }
  const onFormDescChange = (e, { value }) => setPayload({ ...payload, formDescription: value })
  const onQuadaddressSuppressionChange = (state) => setPayload({ ...payload, quadaddrSuppression: state.value })
  const onAddressSuppressionChange = (state) => setPayload({ ...payload, addrSuppression: state.value })
  const onBillingChange = (state) => setPayload({ ...payload, billing: state.value })
  const onDuplexCheckChange = (state) => setPayload({ ...payload, bdplxCheck: state.value })
  const onNoCheckOverlayDropdownChange = (state) => setPayload({ ...payload, bnoChkOvly: state.value })
  const onCheckPrintsChange = (state) => setPayload({ ...payload, bcntchkwht: state.value })
  const onOneFormChange = (state) => setPayload({ ...payload, bdedupe: state.value })
  const onImageTypeChange = (state) => setPayload({ ...payload, imageType: state.value })
  const onImageActiveChange = (state) => setPayload({ ...payload, imageActive: state.value })
  const onImageDescChange = (e, { value }) => setPayload({ ...payload, imageDescription: value })
  const onMapDescChange = (e, { value }) => setPayload({ ...payload, mapDescription: value })
  const onMapActiveChange = (state) => setPayload({ ...payload, bisActive: state.value })

  const { data, isLoading } = useFormValues(param)
  const { data: formDetail, isLoading: formDetailLoading, refetch } = useFormDetail(detailProps?.id, param)

  useEffect(() => {
    setPayload({
      clientNum: '',
      sendData: '',
      formType: '',
      formDescription: '',
      formName: '',
      formName1: '',
      quadaddrSuppression: '',
      addrSuppression: '',
      billing: '',
      bdplxCheck: '',
      stockMod: '',
      imageType: '',
      imageDescription: '',
      imageName: '',
      imageActive: '',
      bnoChkOvly: '',
      bcntchkwht: '',
      formOrientation: '',
      mapName: '',
      mapDescription: '',
      bisActive: '',
      bdedupe: '',
      bdedupe: '',
      bcntchkwht: '',
      ...formDetail
    });
  }, [formDetail, detailProps?.id])

  useEffect(() => {
    setFormInputValues({
      ...data,
      ...formInputValues,
    });
  }, [data])

  useEffect(() => {
    refetch();
    setResponse();
  }, [detailProps?.idx])

  const getSelectionValue = (id, formdata) => {
    return formdata && formdata.filter(form => {
      return form.value === id;
    })[0];
  }

  const getFormInputs = () => {
    return <>
      <SelectDropdown
        width="200px"
        dataTestId="test-sendData"
        domID="SendDataDropdown"
        label='Send Data for Composition'
        onChange={onSendDataChange}
        initialValue={getSelectionValue(payload?.sendData, formInputValues && formInputValues['sendDatas'])}
        isClearable={false}
        options={data?.sendDatas || []}
        size="small"
      />
      <SelectDropdown
        width="200px"
        dataTestId="test-formType"
        domID="FormTypeDropdown"
        label='Form Type'
        onChange={onFormTypeChange}
        initialValue={getSelectionValue(payload?.formType, formInputValues && formInputValues['formTypes'])}
        isClearable={false}
        options={data?.formTypes || []}
        size="small"
      />
      <SelectDropdown
        width="200px"
        dataTestId="test-stockModifier"
        domID="StockModifierDropdown"
        label='Stock Modifier'
        onChange={onStockModifierChange}
        initialValue={getSelectionValue(payload?.stockMod, formInputValues && formInputValues['stocks'])}
        isClearable={false}
        options={data?.stocks || []}
        size="small"
      />
      <SelectDropdown
        width="200px"
        dataTestId="test-formOrientation"
        domID="FormOrientationDropdown"
        label='Form Orientation'
        onChange={onFormOrientationChange}
        initialValue={getSelectionValue(payload?.formOrientation, formInputValues.formOrientation) || formInputValues.formOrientation[0]}
        isClearable={false}
        options={formInputValues.formOrientation}
        size="small"
      />
      <Input
        domID="FormDescription"
        onChange={onFormDescChange}
        size="small"
        initialValue={payload?.formDescription}
        label="Form Description"
      />
      <Input
        domID="FormName"
        size="small"
        initialValue={payload?.formName}
        disabled
        label="Form Name"
      />
      <SelectDropdown
        width="200px"
        dataTestId="test-quadaddressSuppression"
        domID="QuadaddressSuppressionDropdown"
        label='Quadient Address Suppression selection'
        onChange={onQuadaddressSuppressionChange}
        initialValue={getSelectionValue(payload?.quadaddrSuppression, formInputValues && formInputValues['quadaddrSuppressions'])}
        isClearable={false}
        options={data?.quadaddrSuppressions || []}
        size="small"
      />
      <SelectDropdown
        width="200px"
        dataTestId="test-addressSuppression"
        domID="AddressSuppressionDropdown"
        label='AddressSuppression'
        onChange={onAddressSuppressionChange}
        initialValue={getSelectionValue(payload?.addrSuppression, formInputValues.addrSuppression) || formInputValues.addrSuppression[0]}
        isClearable={false}
        options={formInputValues.addrSuppression}
        size="small"
      />
      <SelectDropdown
        width="200px"
        dataTestId="test-billing"
        domID="BillingDropdown"
        label='Billing'
        onChange={onBillingChange}
        initialValue={getSelectionValue(payload?.billing, formInputValues.billing) || formInputValues.billing[0]}
        isClearable={false}
        options={formInputValues.billing}
        size="small"
      />
      <SelectDropdown
        width="200px"
        dataTestId="test-duplexCheck"
        domID="DuplexCheckDropdown"
        label='Duplex Check'
        onChange={onDuplexCheckChange}
        initialValue={getSelectionValue(payload?.bdplxCheck, formInputValues.bdplxCheck) || formInputValues.bdplxCheck[0]}
        isClearable={false}
        options={formInputValues.bdplxCheck}
        size="small"
      />
      <SelectDropdown
        width="200px"
        dataTestId="test-noCheckOverlay"
        domID="NoCheckOverlayDropdown"
        label='No Check Overlay'
        onChange={onNoCheckOverlayDropdownChange}
        initialValue={getSelectionValue(payload?.bnoChkOvly, formInputValues.bnoChkOvly) || formInputValues.bnoChkOvly[0]}
        isClearable={false}
        options={formInputValues.bnoChkOvly}
        size="small"
      />
      <SelectDropdown
        width="200px"
        dataTestId="test-checkPrints"
        domID="CheckPrintsDropdown"
        label='Check Prints on Page 1 of EOB'
        onChange={onCheckPrintsChange}
        initialValue={getSelectionValue(payload?.bcntchkwht, formInputValues.bcntchkwht) || formInputValues.bcntchkwht[0]}
        isClearable={false}
        options={formInputValues.bcntchkwht}
        size="small"
        disabled={!((payload?.formType === '01' || payload?.formType === '03' || payload?.formType === '04') && payload?.formOrientation === 'P')}
      />
      <SelectDropdown
        width="200px"
        dataTestId="test-oneForm"
        domID="OneFormDropdown"
        label='One Form Per Deliverable'
        onChange={onOneFormChange}
        initialValue={getSelectionValue(payload?.bdedupe, formInputValues.bdedupe) || formInputValues.bdedupe[0]}
        isClearable={false}
        options={formInputValues.bdedupe}
        size="small"
        disabled={!(payload?.formType === '07' || payload?.formType === '09')}
      />
    </>
  };

  const getImageInputs = () => {
    return <>
      <SelectDropdown
        width="200px"
        dataTestId="test-imageType"
        domID="ImageTypeDropdown"
        label='Image Type'
        onChange={onImageTypeChange}
        initialValue={getSelectionValue(payload?.imageType, formInputValues.imageType) || formInputValues.imageType[0]}
        isClearable={false}
        options={formInputValues.imageType || []}
        size="small"
      />
      <Input
        domID="ImageDesc"
        onChange={onImageDescChange}
        size="small"
        initialValue={payload?.imageDescription}
        label="Image Description"
      />
      <Input
        domID="ImageName"
        size="small"
        initialValue={payload?.imageName}
        label="Image Name"
        disabled
      />
      <SelectDropdown
        width="200px"
        dataTestId="test-imageActive"
        domID="ImageActive"
        label='Image Active'
        onChange={onImageActiveChange}
        initialValue={getSelectionValue(payload?.imageActive, formInputValues.imageActive) || formInputValues.imageActive[0]}
        isClearable={false}
        options={formInputValues.imageActive}
        size="small"
      />
    </>
  };

  const getMapInputs = () => {
    return <>
      <Input
        domID="MapDesc"
        onChange={onMapDescChange}
        size="small"
        initialValue={payload?.mapDescription}
        label="Map Description"
      />
      <Input
        domID="MapName"
        size="small"
        initialValue={payload?.mapName}
        label="Map Name"
        disabled
      />
      <SelectDropdown
        width="200px"
        dataTestId="test-mapActive"
        domID="MapActive"
        label='Is Active'
        onChange={onMapActiveChange}
        initialValue={getSelectionValue(payload?.bisActive, formInputValues.bisActive) || formInputValues.bisActive[0]}
        isClearable={false}
        options={formInputValues.bisActive}
        size="small"
      />
    </>
  };

  const getTitle = () => {
    if (param === '0') return 'Modify Form Record';
    if (param === '1') return 'Modify Image Record';
    if (param === 'M') return 'Modify 835 Map Record';
  }

  const getFormName = () => {
    if (param === '0') return payload.formName;
    if (param === '1') return payload.imageName;
    if (param === 'M') return payload.mapName;
  }

  const getSuccessMsg = (res) => {
    if (param === '0') return `You've successfully modified a form ${res.formName}`;
    if (param === '1') return `You've successfully modified an image ${res.imageName}`;
    if (param === 'M') return `You've successfully modified a map ${res.mapName}`;
  }

  const saveForm = async () => {
    try {
      const res = await axios.put(`/forms/${getFormName()}?param=${param}`, payload);
      // closeDetailPane();
      notify(`${getSuccessMsg(res && res.data)}`, 'positive')
      setResponse(res.data);
      clearPayload()
      // detailProps && detailProps.refresh()
    } catch (err) {
      notify(err.displayMessage, "negative")
    }
  }

  const getConfirmFormInputs = () => {
    return <>
      <div> Form Modified </div>
      <div className="reserve-form__row">
        <span className="reserve-form__label">Client Number: </span>
        <span className="reserve-form__value">{response.clientNum}</span>
      </div>
      <div className="reserve-form__row">
        <span className="reserve-form__label">Send Data for Composition: </span>
        <span className="reserve-form__value">{getSelectionValue(response?.sendData, formInputValues && formInputValues['sendDatas'])?.label}</span>
      </div>
      <div className="reserve-form__row">
        <span className="reserve-form__label">Form Type: </span>
        <span className="reserve-form__value">{getSelectionValue(response?.formType, formInputValues && formInputValues['formTypes'])?.label}</span>
      </div>
      <div className="reserve-form__row">
        <span className="reserve-form__label">Stock Modifier: </span>
        <span className="reserve-form__value">{getSelectionValue(response?.stockMod, formInputValues && formInputValues['stocks'])?.label}</span>
      </div>
      <div className="reserve-form__row">
        <span className="reserve-form__label">Form Orientation: </span>
        <span className="reserve-form__value">{getSelectionValue(response?.formOrientation, formInputValues && formInputValues['formOrientation'])?.label}</span>
      </div>
      <div className="reserve-form__row">
        <span className="reserve-form__label">Form Description: </span>
        <span className="reserve-form__value">{response.formDescription}</span>
      </div>
      <div className="reserve-form__row">
        <span className="reserve-form__label">Form Name: </span>
        <span className="reserve-form__value">{response.formName}</span>
      </div>
      <div className="reserve-form__row">
        <span className="reserve-form__label">Quadient Address Suppression selection: </span>
        <span className="reserve-form__value">{getSelectionValue(response?.quadaddrSuppression, formInputValues && formInputValues['quadaddrSuppressions'])?.label}</span>
      </div>
      <div className="reserve-form__row">
        <span className="reserve-form__label">Address Suppression: </span>
        <span className="reserve-form__value">{response.addrSuppression === '0' ? 'No' : 'Yes'}</span>
      </div>
      <div className="reserve-form__row">
        <span className="reserve-form__label">Billing: </span>
        <span className="reserve-form__value">{response.billing === 'N' ? 'No' : 'Yes'}</span>
      </div>
      <div className="reserve-form__row">
        <span className="reserve-form__label">Duplex Check: </span>
        <span className="reserve-form__value">{response.bdplxCheck === '0' ? 'No' : 'Yes'}</span>
      </div>
      <div className="reserve-form__row">
        <span className="reserve-form__label">No Check Overlay: </span>
        <span className="reserve-form__value">{response.bnoChkOvly === '0' ? 'No' : 'Yes'}</span>
      </div>
      <div className="reserve-form__row">
        <span className="reserve-form__label">Check Prints on Page 1 of EOB: </span>
        <span className="reserve-form__value">{response.bcntchkwht === '0' ? 'No' : 'Yes'}</span>
      </div>
      <div className="reserve-form__row">
        <span className="reserve-form__label">One Form Per Deliverable: </span>
        <span className="reserve-form__value">{response.bdedupe === '0' ? 'No' : 'Yes'}</span>
      </div>
    </>
  }

  const getConfirmImageInputs = () => {
    return <>
      <div> Image Modified </div>
      <div className="reserve-form__row">
        <span className="reserve-form__label">Client Number: </span>
        <span className="reserve-form__value">{response.clientNum}</span>
      </div>
      <div className="reserve-form__row">
        <span className="reserve-form__label">Image Type: </span>
        <span className="reserve-form__value">{getSelectionValue(response?.imageType, formInputValues && formInputValues['imageType'])?.label}</span>
      </div>
      <div className="reserve-form__row">
        <span className="reserve-form__label">Image Description: </span>
        <span className="reserve-form__value">{response.imageDescription}</span>
      </div>
      <div className="reserve-form__row">
        <span className="reserve-form__label">Image Name: </span>
        <span className="reserve-form__value">{response.imageName}</span>
      </div>
      <div className="reserve-form__row">
        <span className="reserve-form__label">Image Active: </span>
        <span className="reserve-form__value">{response.imageActive === '0' ? 'No' : 'Yes'}</span>
      </div>
    </>
  }

  const getConfirmMapInputs = () => {
    return <>
      <div> Map Modified </div>
      <div className="reserve-form__row">
        <span className="reserve-form__label">Client Number: </span>
        <span className="reserve-form__value">{response.clientNum}</span>
      </div>
      <div className="reserve-form__row">
        <span className="reserve-form__label">Map Description: </span>
        <span className="reserve-form__value">{response.mapDescription}</span>
      </div>
      <div className="reserve-form__row">
        <span className="reserve-form__label">Map Name: </span>
        <span className="reserve-form__value">{response.mapName}</span>
      </div>
      <div className="reserve-form__row">
        <span className="reserve-form__label">Is Active: </span>
        <span className="reserve-form__value">{response.bisActive === '0' ? 'No' : 'Yes'}</span>
      </div>
    </>
  }

  return (
    <>
      {detailProps?.showDetailPane && <StickyLayout
        headerContent={<ListPaneHeader
          sticky
          title={getTitle()}
          layoutControls={[expandCollapseDetailPaneButtonConfig, closeDetailPaneButtonConfig]}
        />}
        height="calc(100vh - 80px)"
      >
        {
          formDetailLoading ? <LoadingComponent /> :
            <div className='addFormDetail'>
              {!response ? <>
                {param === '0' ? getFormInputs() : param === '1' ? getImageInputs() : param === 'M' ? getMapInputs() : <></>}
                <Button
                  buttonType="standard"
                  domID="ConfirmAction"
                  size="small"
                  name="Save"
                  onClick={saveForm}
                ></Button>
              </> : param === '0' ? getConfirmFormInputs() : param === '1' ? getConfirmImageInputs() : param === 'M' ? getConfirmMapInputs() : <></>}
            </div>
        }
      </StickyLayout>}
    </>
  )
}

export default ReserveModifyDetail