
import React, { useState } from 'react'
import {
  Grid,
  SelectDropdown,
  useLayout,
  ListPaneHeader,
  Button,
} from 'ui-core/dist'
import axios from '../../axios';
import exportFromJSON from 'export-from-json'
import useNotificationBannerHook from '../../useNotificationBannerHook';

import { useDatasourceRequest } from '../../api/useReportManager';
import { LoadingPage } from '../../components';

import './modules/style.scss'

const ClientImageReport = () => {
  const {
    expandCollapseListPaneButtonConfig
  } = useLayout()
  const [isReportLoading, setIsReportLoading] = useState(false);
  const { notify } = useNotificationBannerHook();

  const SELECT_REPORT_ReportType_OPTIONS = [{
    label: "Production",
    value: "prod"
  },
  {
    label: "Test",
    value: "test"
  }
  ];
  const [initialReportTypeValue, setInitialReportTypeValue] = useState({ label: "Production", value: "prod" });
  const [reportType, setReportType] = useState("prod");
  const handleReportSortOrderOptionChange = (e) => {
    setInitialReportTypeValue({ label: e?.label, value: e?.value });
    setReportType(e?.value);
  }



  const getReport = () => {
    setIsReportLoading(true);
    axios.get(`/reports-manager/client-image-report/${reportType}`, { responseType: 'blob' }).then(res => {
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(res.data, 'clientImageReport.pdf');
      } else {
        const url = URL.createObjectURL(res.data);
        const tab = window.open();
        if(tab)
          tab.location.href = url;
        else
          notify('Please enable allow window pop-up in your browser.','caution')
      }
      setIsReportLoading(false)
    }).catch(err => {
      setIsReportLoading(false);
      notify(`${err.displayMessage}`, 'negative');
    })
  }

  return (
    <>
      <ListPaneHeader
        title={`Client Image Report`}
        layoutControls={[expandCollapseListPaneButtonConfig]}
      />
      <div className="marginLeftUserReport">
        <table id="userReportSearch">
          <tr>
            <td className='userreport-labels'><label>Select Report Type:</label></td>
            <td className='userReportcriteriaWidth'>
              <SelectDropdown
                isClearable={false}
                domID="reportType"
                options={SELECT_REPORT_ReportType_OPTIONS}
                size="small"
                initialValue={initialReportTypeValue}
                onChange={(e) => handleReportSortOrderOptionChange(e)}
              />
            </td>
          </tr>

          <tr><td>
            {
              isReportLoading ? (
                // replace with a better loading component
                <LoadingPage height="20vh" />
              ) :
                (<Button
                  buttonType="standard"
                  size="small"
                  name="Submit"
                  onClick={() => { getReport() }}
                />)
            }
          </td><td></td></tr>
        </table>

      </div>
    </>
  )
}

export default ClientImageReport;