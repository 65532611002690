
import { useEffect, useState } from 'react'
import { useAxiosPost } from '../hooks'

const getXml=({
  body,
  description,
  formId,
  fromAddress,
  fromName,
  subject,})=> `
  <![CDATA[
  <eeob-form>
      <formDesc>${description}</formDesc>
      <formName>${formId}</formName>
      <fromEmailAddr>${fromAddress}</fromEmailAddr>
      <fromName>${fromName}</fromName>
      <subject>${subject}</subject>
      <emailBody><![CDATA[${body}]]]]><![CDATA[></emailBody>
  </eeob-form>]]>`

const useAddEEOBForm=()=>{
  const addEEOBForm=({
    count,
    body,
    description,
    formId,
    fromAddress,
    fromName,
    subject,
  })=>{
    postData({
      count,
      sEEOBFormXml: getXml({
        body,
        description,
        formId,
        fromAddress,
        fromName,
        subject,})
    })
  }
  const {postData, isLoading,isError,error, metaData, data }=useAxiosPost({
    url:'eeobFormManager/addEEOBForm',
    method:'POST',
    onError: (e) => 
      // what happens on error? if you need something extra
      // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  

  return { isLoading, isError, error, addEEOBForm, metaData, data};
}
const useUpdateEEOBForm=()=>{
  const updateEEOBForm=({
    count,
    body,
    description,
    formId,
    fromAddress,
    fromName,
    subject,
  })=>{
    postData({
      count,
      sEEOBFormXml: getXml({
        body,
        description,
        formId,
        fromAddress,
        fromName,
        subject,})
    })
  }
  const {postData, isLoading,isError,error,metaData, data }=useAxiosPost({
    url:'eeobFormManager/updateEEOBForm',
    method:'POST',
    onError: (e) => 
      // what happens on error? if you need something extra
      // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  

  return { isLoading, isError, error, updateEEOBForm, metaData, data};
}

const useGetNextEEOBFormId=()=>{
  const [nextEEOBFormId, setnextFormId]=useState(null)
  const {postData, isLoading,isError,error, data }=useAxiosPost({
    url:'eeobFormManager/getNextEEOBFormId',
    method:'POST',
    onError: (e) => 
      // what happens on error? if you need something extra
      // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  
  useEffect(()=>{
    if (data) {
      setnextFormId(JSON.parse(JSON.stringify(data)))
    } else{
      setnextFormId(null);
    }
  },[data])

  return {nextEEOBFormId, isLoading, isError, error, postData};
}
const useGetAvailableEEOBForms=()=>{
  const [totalRecords,setTotalRecords]=useState(0)
  const [records, setRecords]=useState([]);
  const {postData, isLoading,isError,error, data }=useAxiosPost({
    url:'eeobFormManager/getAvailableEEOBForms',
    method:'POST',
    onError: (e) => 
      // what happens on error? if you need something extra
      // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  
  useEffect(()=>{
    if (data) {
      const {EEOBForm, totalRecords: totalRecordsp}=data;
      if (EEOBForm) {
        setTotalRecords(totalRecordsp)  
        const sr=JSON.parse(JSON.stringify(EEOBForm))  
        let searchResult=[]
        if (sr.constructor.name==="Array") {
          searchResult=[...sr]
        } else if(sr.constructor.name==="Object"){
          searchResult.push({...sr})
        }
        if (searchResult && searchResult.length>0) {
          setRecords(JSON.parse(JSON.stringify(searchResult)))  
        } else{
          setRecords(JSON.parse(JSON.stringify([])))  
        }
      } else{
        setTotalRecords(0);
        setRecords(JSON.parse(JSON.stringify([])))
      }
    }
  },[data])
  return {totalRecords,records,postData, isLoading, isError, error};
}
const useGetEEOBFormByName=()=>{
  const [showMoreInfo, setshowMoreInfo]=useState(null)
  const {postData, isLoading,isError,error, data }=useAxiosPost({
    url:'eeobFormManager/getEEOBFormByName',
    method:'POST',
    onError: (e) => 
    // what happens on error? if you need something extra
    // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  
  useEffect(()=>{
    if (data && data.collection) {
      const {EEOBForm}=data.collection;
      if (EEOBForm) {
        setshowMoreInfo(JSON.parse(JSON.stringify(EEOBForm)))    
      } else{
        setshowMoreInfo(null);    
      }
    } else{
      setshowMoreInfo(null);
    }
  },[data])
  
  return {showMoreInfo, isLoading, isError, error, postData};
}

export {
  useAddEEOBForm,
  useUpdateEEOBForm,
  useGetNextEEOBFormId,
  useGetAvailableEEOBForms,
  useGetEEOBFormByName
}