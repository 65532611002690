import React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import {FileReceiptManager} from '../containers';

const FileReceiptManagerRoutes = () => (
  <Switch>
    <Route path="/FileReceiptManager/all" component={FileReceiptManager} />
    <Route exact path="/FileReceiptManager">
      <Redirect to="/FileReceiptManager/all" />
    </Route>
  </Switch>
)

export default FileReceiptManagerRoutes;
