export const documentTypesOptions = [
    {"label":"-- Output All --", "value":"" },
    {"label":"1099 MISC","value":"21"},
    {"label":"1099-R","value":"22"},
    {"label":"AFF Genelco Billing Batch Documents","value":"39"},
    {"label":"AFF Genelco Billing Documents","value":"38"},
    {"label":"AFF II Image","value":"36"},
    {"label":"Administration Check","value":"12"},
    {"label":"Batch 835 Check","value":"37"},
    {"label":"Batch Check","value":"05"},
    {"label":"Batch EOB","value":"06"},
    {"label":"Capitation Check","value":"13"},
    {"label":"Check Only","value":"16"},
    {"label":"Check Register","value":"34"},
    {"label":"Dental Batch Check","value":"30"},
    {"label":"Dental Batch EOB","value":"31"},
    {"label":"Dental Check","value":"28"},
    {"label":"Dental EOB","value":"29"},
    {"label":"Disability Check","value":"09"},
    {"label":"Disability EOB","value":"10"},
    {"label":"EOB","value":"02"},
    {"label":"EOB Check","value":"01"},
    {"label":"Estimate","value":"35"},
    {"label":"Flex Check","value":"07"},
    {"label":"Flex EOB","value":"08"},
    {"label":"Form Letter","value":"04"},
    {"label":"Form Letter Batch EOB","value":"20"},
    {"label":"Form Letter Batch EOB Check","value":"19"},
    {"label":"Form Letter EOB","value":"18"},
    {"label":"Form Letter EOB Check","value":"17"},
    {"label":"Generic Form","value":"11"},
    {"label":"HCFA","value":"41"},
    {"label":"Long term Disability Check","value":"32"},
    {"label":"Long term Disability EOB","value":"33"},
    {"label":"Manual Check","value":"03"},
    {"label":"Other","value":"24"},
    {"label":"Other Check","value":"23"},
    {"label":"Pre-Authorization","value":"14"},
    {"label":"PreTreatment Estimate","value":"25"},
    {"label":"Provider Assessment","value":"15"},
    {"label":"UB92","value":"40"},
    {"label":"Voucher","value":"27"},
    {"label":"Voucher Check","value":"26"}
]