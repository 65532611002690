import styled from 'styled-components';

export const LoadingWrapper = styled.div`
  float: left;
  margin-left: -16px;
  margin-top: 1px;
  margin-right: 6px;
`;

export const ScrollableGridWrapper = styled.div`
  max-height: 45vh;
  overflow: auto;
  & > div > table > tbody > tr > td {
    vertical-align: middle;
  }
`;

export const InputLabel = styled.div`
  font-size: 14px !important;
  margin: 0 0 1em .3em;
  line-height: 1.4em;
  letter-spacing: 0.5px;
  text-align: left;
`;

export const ImageContentPaddingWrapper = styled.div`
  padding: 0.5rem 0.5rem 1.4rem 1.5rem;
  font-size: 14px;
`;

