export const ALL_RULES_FOR_RULETYPES_REQUEST_TRIGGER =
  'ALL_RULES_FOR_RULETYPES_REQUEST_TRIGGER';
export const ALL_RULES_FOR_RULETYPES_REQUEST_SUCCESS =
  'ALL_RULES_FOR_RULETYPES_REQUEST_SUCCESS';
export const ALL_RULES_FOR_RULETYPES_REQUEST_ERROR =
  'ALL_RULES_FOR_RULETYPES_REQUEST_ERROR';

export const ALL_RULES_FOR_RULETYPES_ASSOCIATIONS_REQUEST_TRIGGER =
  'ALL_RULES_FOR_RULETYPES_ASSOCIATIONS_REQUEST_TRIGGER';
export const ALL_RULES_FOR_RULETYPES_ASSOCIATIONS_REQUEST_SUCCESS =
  'ALL_RULES_FOR_RULETYPES_ASSOCIATIONS_REQUEST_SUCCESS';
export const ALL_RULES_FOR_RULETYPES_ASSOCIATIONS_REQUEST_ERROR =
  'ALL_RULES_FOR_RULETYPES_ASSOCIATIONS_REQUEST_ERROR';

export const RULES_COUNT_FROM_DB_TRIGGER = 'RULES_COUNT_FROM_DB_TRIGGER';
export const RULES_COUNT_FROM_DB_SUCCESS = 'RULES_COUNT_FROM_DB_SUCCESS';
export const RULES_COUNT_FROM_DB_ERROR = 'RULES_COUNT_FROM_DB_ERROR';

export const RESET_RULE_DETAILS_PANE = 'RESET_RULE_DETAILS_PANE';

export const SET_IS_VIEWING_RULE_DETAILS = 'SET_IS_VIEWING_RULE_DETAILS';

export const UPDATE_CURRENT_TAB = 'UPDATE_CURRENT_TAB';

export const GROUP_ASSOCIATIONS_FOR_RULE_ID_TRIGGER =
  'GROUP_ASSOCIATIONS_FOR_RULE_ID_TRIGGER';
export const GROUP_ASSOCIATIONS_FOR_RULE_ID_SUCCESS =
  'GROUP_ASSOCIATIONS_FOR_RULE_ID_SUCCESS';
export const GROUP_ASSOCIATIONS_FOR_RULE_ID_ERROR =
  'GROUP_ASSOCIATIONS_FOR_RULE_ID_ERROR';

export const POST_GROUP_ASSOCIATIONS_FOR_RULE_TYPE_TRIGGER =
  'POST_GROUP_ASSOCIATIONS_FOR_RULE_TYPE_TRIGGER';

export const GROUP_ASSOCIATIONS_FOR_RULE_TYPE_SUCCESS =
  'GROUP_ASSOCIATIONS_FOR_RULE_TYPE_SUCCESS';

export const GROUP_ASSOCIATIONS_FOR_RULE_TYPE_ERROR =
  'GROUP_ASSOCIATIONS_FOR_RULE_TYPE_ERROR';

export const SET_IS_VIEWING_GROUP_ASSOCIATIONS_TYPE_MODAL =
  'SET_IS_VIEWING_GROUP_ASSOCIATIONS_TYPE_MODAL';

export const SET_SELECTED_RULE_TYPE = 'SET_SELECTED_RULE_TYPE';

export const RESET_RULE_TYPE_RECORDS = 'RESET_RULE_TYPE_RECORDS';
export const RESET_STATE = 'RESET_STATE';
