
const initialState = {
    jobNumber: null,
  }
      
  export default (state = initialState, { type, payload }) => {
    switch(type) {
      case 'SET_DIRECTPRINT_JOBNUMBER':
        return {
          ...state,
          jobNumber: payload.jobNumber
        }
      case 'RESET_DIRECTPRINT_JOBNUMBER':
        return {
          ...state,
          jobNumber: null
        }
      default:
        return state;
    }
  }