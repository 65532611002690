/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import styled from 'styled-components/dist/styled-components';
import ReactTooltip from 'react-tooltip';
import { useSelector, useDispatch } from 'react-redux';
import { Chart, Circle100 ,
  Grid,
  Button,
  useLayout,
} from 'ui-core/dist';

import { LoadingPage } from '../../../components';
import ErrorPage from '../../ErrorPage';
import ListPaneHeaderWrapper from '../../../components/ErrorDisplaySection/ListPaneHeaderWrapper';
import { CLIENT_NCOA_PAF_EDITOR_GRID } from '../Modules/constants';
import { setPAFEditorRecord, resetPAFEditorRecord } from '../Modules/action';
import { useGetNCOAPAFsList } from '../../../api/useClientNCOAPAFEditor';

const StyledGridWrapper = styled.div`
&.GridWrapper #grid-wrapper-test-id{
  overflow:initial !important;
}
`
const StyleButton = styled(Button)`
height: 18px !important;
width: 53px !important;
background: transparent;
box-shadow: none;
&:hover {
  background: transparent;
box-shadow: none;
}
`
const PAFEditorMain = () => {
  const {
    expandCollapseListPaneButtonConfig,
    openDetailPane,
    closeDetailPane
  } = useLayout()
  const { faultstring } = useSelector(({ errormessages }) => errormessages);
  const dispatch = useDispatch()
  const [count, setcount] = useState(0)
  const [selectedRecord,setSelectedRecord]=useState('')
  const { isLoading,
    isError,
    error,
    postData: getNCOAPAFList,
    NCOA_PAF,
    NCOA_PAFCount,
    ASSOCIATED_CLIENTS,
    ASSOCIATED_CLIENTSCount
  } = useGetNCOAPAFsList();

  //   const [sortConfigContacts, _c, gridSortingContacts] = useSortableGrid(() => false, "cclientnum", "true", setPAFsInfo);

  useEffect(() => {
    refetchPAFEditorRecord()
  },[]);

  const refetchPAFEditorRecord = () => {
    getNCOAPAFList({ count })
    setcount(count + 1)
    closeDetailPane();
  }

  const onShowMoreClick = useCallback((record) => {
    dispatch(setPAFEditorRecord({ PAFEditorRecord: JSON.parse(JSON.stringify(record)), mode: 'Edit', refetchPAFEditorRecord }));
    setSelectedRecord(JSON.parse(JSON.stringify(record)))
    openDetailPane();
  },[count]);

  const onAddPAFRecord = () => {
    setSelectedRecord({})
    dispatch(setPAFEditorRecord({ PAFEditorRecord: JSON.parse(JSON.stringify({})), mode: 'Add', refetchPAFEditorRecord }));
    openDetailPane();
  }
  const renderGrid = useMemo(() => {
    if (NCOA_PAF) {
      const data = JSON.parse(JSON.stringify(NCOA_PAF))
      const temp = data.map((d, index) => {
        const { cPAFID, bCanEdit, bActive, cListOwnerPhone } = d;
        const bCanEditImg = bCanEdit === '0' ? (
          <>
            <StyleButton data-tip data-for={`paftooltip_${index}_${cPAFID}`}
              onClick={() => false}>
              <Chart
                className="settings"
                fillColor="#37474F"
                title="Settings"
              />
            </StyleButton>
            <ReactTooltip id={`paftooltip_${index}_${cPAFID}`} type='dark' effect='solid'>
              <span>This paf has been accessed and can no longer be deleted.</span>
            </ReactTooltip>
          </>
        ): ''
        const bActiveImg = bActive === '1' ? (
          <>
            <StyleButton data-tip data-for={`paftooltipedit_${index}_${cPAFID}`}
              onClick={() => false}>
              <Circle100
                className="settings"
                fillColor="#00D100"
                title="Settings"
              />
            </StyleButton>
            <ReactTooltip id={`paftooltipedit_${index}_${cPAFID}`} type='dark' effect='solid'>
              <span>Active PAF</span>
            </ReactTooltip>
          </>
        ) : ''
        return {
          ...d,
          bCanEditImg,
          bActiveImg,
          cListOwnerPhoneDisplay: (<span>({cListOwnerPhone.substr(0,3)}) {cListOwnerPhone.substr(3,3)}-{cListOwnerPhone.substr(6,4)}</span>),
        };
      })
      return (
        <>
          <StyledGridWrapper className='GridWrapper'>
            <Grid
              {...CLIENT_NCOA_PAF_EDITOR_GRID}
              records={temp || []}
              selectionKey= "cPAFID"
              onClickThrough={(e, rec) => {
                e.preventDefault();
                e.stopPropagation();
                if (rec) {
                  const record = (NCOA_PAF || []).find(x => x.cPAFID === rec.cPAFID)
                  if (onShowMoreClick) {
                    dispatch(resetPAFEditorRecord())
                    return onShowMoreClick({...record, ASSOCIATED_CLIENTS});
                  }
                  return false;
                }
                return false;
              }}
              focusedItem={selectedRecord}
              //   initialSortingKey={`${sortConfigContacts.sortDataName}|${sortConfigContacts.sortDescending === "true" ? 'SORT_DESCENDING' : 'SORT_ASCENDING'}`}
            //   onSortGridColumn={(e, state) => {
            //     const { sortingKey } = state;
            //     const sortingKey$split = sortingKey.split('|');
            //     const dataName = sortingKey$split[0];
            //     const f = Array.from(CLIENT_INFORMATION_CONTACTS_EDITOR_GRID.columns).find(x => x.dataName === dataName);
            //     if (f) {
            //       const { cellType } = f;
            //       if (gridSortingContacts) gridSortingContacts({ sortingKey, type: cellType }, data)
            //     }
            //   }}
            />
          </StyledGridWrapper>
        </>
      )
    }
    return null;
  }, [
    NCOA_PAF,
    selectedRecord
  ])

  return (
    <>
      <ListPaneHeaderWrapper
        title="Client NCOA PAF Manager - PAF Editor"
        layoutControls={[expandCollapseListPaneButtonConfig]}
      />
      {isLoading ? (
        // replace with a better loading component
        <LoadingPage />
      ) : isError ? (
        // replace with a better error component
        <ErrorPage
          // this prop isn't real but is an example
          error={error}
        />
      ) : (
        <>
          <div className="marginLeft">
            <table border="0" cellSpacing="1" cellPadding="3" width="100%">
              <tr>
                <td>
                  <div className="displayFlex">
                    <Button
                      id='ContactEditorAdd'
                      size="small"
                      name="Add PAF"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onAddPAFRecord()
                      }}
                    />
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  {renderGrid}
                </td>
              </tr>
            </table>
          </div>
          {/* {renderMainGrid} */}
        </>
      )}
    </>
  )
};

export default PAFEditorMain
