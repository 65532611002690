
const initialState = {
  filekey:null
}
      
export default (state = initialState, { type, payload }) => {
  switch(type) {
    case 'SET_FILE_KEY':
      return {
        ...state,
        filekey:payload
      }
    case 'RESET_FILE_KEY':
      return {
        filekey:null
      }
    default:
      return state;
  }
}