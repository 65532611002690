import React from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalHeader, ModalBody, Grid } from 'ui-core/dist'
import useRoleInfo from '../../api/useRoleInfo'
import { Loading } from '../../components'

const RoleModal = ({ isRoleOpen, onRoleClose, roleId }) => {
  const { data, isLoading } = useRoleInfo({ roleId })
  return (
    <Modal isOpen={isRoleOpen} className="role-modal">
      <ModalHeader 
        title="Role Details"
        description={`Details for role ${roleId}`}
        onClose={onRoleClose}
      />
      {isLoading ? <Loading /> : (
        <ModalBody className="role-modal__contents">
          <div className="role-modal__row">
            <span className="role-modal__label">Name:</span>
            <span className="role-modal__info">{data?.name}</span>
          </div>
          <div className="role-modal__row">
            <span className="role-modal__label">Permission Description:</span>
            <span className="role-modal__info">{data?.description}</span>
          </div>
          <div className="role-modal__row">
            <span className="role-modal__label">Is Active:</span>
            <span className="role-modal__info">{data?.isActive === "true" ? 'Active' : 'Inactive'}</span>
          </div>
          <div className="role-modal__row">
            <span className="role-modal__label">Permissions for this Role:</span>
          </div>
          <Grid 
            columns={new Set([
              {
                dataName: 'permName',
                text: 'Permission Name',
                cellType: 'text',
              },
              {
                dataName: 'description',
                text: 'Permission Description',
                cellType: 'text',
              },
            ])}
            records={data?.permissions || []}
            dataTestId="test-RoleModalGrid"
            domID="RoleModalGrid"
          />
        </ModalBody>
      )}
    </Modal>
  )
}

RoleModal.propTypes = {
  isRoleOpen: PropTypes.bool.isRequired, 
  onRoleClose: PropTypes.func.isRequired, 
  roleId: PropTypes.string.isRequired
}

export default RoleModal