import styled from 'styled-components';

export const CommitImageModalContentPaddingWrapper = styled.div`
  padding: 0 0 20px 0;
  font-size: 14px;
  & > span {
    font-weight: bold;
  }
`;

export const LoadingWrapper = styled.div`
  float: left;
  margin-left: -20px;
  margin-top: 3px;
  padding-right: 5px;
`;
