import React from 'react'
import ReactSelect, { createFilter } from 'react-select'
import { InputLabel } from 'ui-core/dist/chunk-4203e5d9'
import CustomOption from './CustomOption'
import CustomMenuList from './MiniVirtualMenuList'

const getMaxWidth = (options, initialWidth = '150px') => {
  let width = 0;
  const initWidth = initialWidth.split('px')[0];
  options && options.forEach(option => {
    const currWidth = option?.label ? option.label.trim().length * 7 + 40 : 0;
    if (currWidth > width) {
      width = currWidth;
    }
  });
  return width > Number(initWidth) ? width + 'px' : initWidth + 'px';
}

const getStyles = (width, options) => ({
  control: (css) => ({
    ...css,
    width: width || '150px'
  }),
  menu: ({ width: defaultWidth, ...css }) => ({
    ...css,
    width: `${getMaxWidth(options, width)}`,
    // minWidth: width,
    position: 'absolute'
  }),
  // Add padding to account for width of Indicators Container plus padding
  // option: (css) => ({ ...css, width: '100%' })
});

const SelectDropdown = ({ label, domID, initialValue, width, options, disabled, ...rest }) => {
  const getRandomInt = (max) => Math.floor(Math.random() * Math.floor(max));

  return (
    <div className="SelectDropdown">
      {label && <InputLabel>{label}</InputLabel>}
      <ReactSelect
        isSearchable
        key={getRandomInt(99999999999).toString()}
        // menuPlacement="auto"
        // menuPosition="fixed"
        styles={getStyles(width, options)}
        filterOption={createFilter({ ignoreAccents: false, matchFrom: 'any', stringify: option => `${option.label}` })}
        captureMenuScroll={false}
        classNamePrefix="custom-select"
        // closeMenuOnSelect={false} 
        components={{ Option: CustomOption, MenuList: CustomMenuList }}
        id={domID}
        isDisabled={disabled}
        className="react-select__dropdown"
        defaultValue={initialValue}
        options={options}
        {...rest}
      />
    </div>
  );
}

export default SelectDropdown