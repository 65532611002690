/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components/dist/styled-components';
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import {
  useLayout,
  Modal,
  ModalHeader,
  Button,
  ButtonGroup, ModalFooter, ModalBody
} from 'ui-core/dist'
import { LoadingPage, AnchorInput, SelectDropdown } from '../../../components';
import ErrorPage from '../../ErrorPage';
import {
  GENERIC_TEXT1,
  GENERIC_TEXT_HOLD,
  GENERIC_TEXT_SEARCH_HOLD,
} from "../Modules/constants";
import withAxiosCancellation from '../../../components/withAxiosCancellation'
import ListPaneHeaderWrapper from '../../../components/ErrorDisplaySection/ListPaneHeaderWrapper';
import FileReceiptManagerDetailComponent from '../../FileReceiptManager/FileReceiptManagerDetailComponent';
import ShowMoreNestedGridComponent from '../../../components/ShowMoreNestedGridComponent';
import { useSearchReleaseFiles, useSubmitReleaseFiles } from '../../../api/useDocumentManager';
import { resetFileKey, setFileKey } from '../../FileReceiptManager/Modules/Action';
import GridPaginationWrapper from '../../../components/GridPaginationWrapper';
import { setFileSearchParam, setFileSearchParamNull } from "../Modules/action";
import PopoverBox from "../../../components/PopoverBox";
import '../Modules/styles.scss';
import useSelectableGrid from '../../../hooks/useSelectableGrid';

const StyleDivParent = styled.div`
  &.styled-parent-div {
    margin-top: 45px;
    border: 1px solid #cacccd;
    /* border-width: 1px; */
    /* border-color: #dbdbdb; */
    overflow-y: auto;
    height: 470px;
    border-radius: 5px;
    margin-left: 30px;
    text-align: left;
    /* -webkit-box-shadow: -1px 9px 18px 0px rgb(0 0 0 / 75%); */
    /* -moz-box-shadow: -1px 9px 18px 0px rgba(0,0,0,0.75); */
    /* box-shadow: 0px 0px 6px -1px rgb(0 0 0 / 75%); */
  }
  &.popBoxDiv {
    display: inline-block;

    & span {
      position:relative !important;

      & div.popover.bottom {
        border-radius: 5px;
        margin-top: 42px !important;
        margin-left: 27px !important;
      }
    }
  }
`;
const StyledModalHeaderWrapper = styled.div`
& div {
  padding:1px 9px !important;
  padding-bottom: 1px !important;
}
`

const FileSearchResultReleaseMain = ({getCancelSourceWithName}) => {
  const {
    expandCollapseListPaneButtonConfig
  } = useLayout()

  const dispatch = useDispatch()
  const history = useHistory();
  const { allData, totalRecordsResp, searchFiles, isLoading, isError, error } = useSearchReleaseFiles({getCancelSource:getCancelSourceWithName('searchReleaseFileResult')});
  const { transId, submitFiles, isLoading: isTxnLoading } = useSubmitReleaseFiles();
  const { fileSearchParam } = useSelector(({ documentSearch }) => documentSearch);
  const [count, setCount] = useState(0);
  const [changeItemPerPage, setchangeItemsPerPage] = useState(fileSearchParam.maxRecords)
  const [pageIndex, setPageIndex] = useState(0)
  const [list, setlist] = useState([]);

  const [isOpenFileReceiptModal, setisOpenFileReceiptModal] = useState(false)
  const [isOpenConfirmModal, setisOpenConfirmModal] = useState(false)
  const [releaseType, setReleaseType] = useState({ value: 'S', label: 'Scheduled Release Rule' })
  const { filekey }
    = useSelector(({ fileReceipt }) => fileReceipt);

  const { onRowSelect, selectedRecords, onClearSelectionCurrentPage, onClearAllSelection } = useSelectableGrid({ uniqueKey: 'filekey', selectionKey: 'isSelected', list, setlist })

  useEffect(() => {
    searchFiles(fileSearchParam)
  }, [])

  useEffect(() => {
    if (allData) {
      setlist(JSON.parse(JSON.stringify(allData)))
    }
  }, [allData, totalRecordsResp])



  useEffect(() => {
    if (releaseType.value === "S") {
      const l = list.find(x => x.user === "Scheduled"); // Scheduled
      if (l) {
        l.isSelected = false;
      }
      setlist(JSON.parse(JSON.stringify(list)));
    }
  }, [releaseType])

  useEffect(() => {
    if (transId) {
      setisOpenConfirmModal(false);
      // searchFiles({ ...fileSearchParam, count })
      // setCount(count + 1);
      onClearAllSelection();
      history.push({ pathname: '/DocumentManager/Release/File', state: `You have successfully released the files.  The transaction ID is ${transId}` })
    }
  }, [transId]);


  const onCloseFileReceiptModal = () => {
    setisOpenFileReceiptModal(false)
    dispatch(resetFileKey())
    return true;
  }


  const onCloseConfirmModal = () => {
    setisOpenConfirmModal(false)
    return true;
  }



  const onConfirm = () => {
    // const fileIds = [...new Map(list.filter(y => y.isSelected).map(item => [item.groupid, item])).values()].map(x=>x.groupid);
    const selectedItemids = [releaseType.value]
    if(selectedRecords && selectedRecords.size){
      Array.from(selectedRecords).forEach(l => {
        selectedItemids.push(l.filekey);
      });
      submitFiles({ pstrFileKeys: selectedItemids, count });
      setCount(count + 1);
    }
  }

  const transformGroupedData = (data, isGrouped) => {
    const temp = JSON.parse(JSON.stringify(data))
    let t;
    if (isGrouped) {
      t = temp.map((d) => ({
        ...d,
        subRecords: d.subRecords.map((s) => {
          let statusComp = '';
          let className;
          switch (s.status) {
            case 'Imported':
              className = 'status-ok';
              break;
            case 'Released':
              className = 'status-ok';
              break;
            case 'Ready to print':
              className = 'status-ok';
              break;
            case 'Held':
              className = 'status-warn';
              break;
            case 'Held and Errored':
              className = 'status-error';
              break;
            case 'Errored':
              className = 'status-error';
              break;
            case 'Purged':
              className = 'status-error';
              break;
            default:
              break;
          }
          statusComp = (
            <div className={className}>
              <span>{s.status}</span>
            </div>
          )
          return {
            ...s,
            'filekey_display': <AnchorInput
              id='fileKey'
              type='button'
              className='fileKeyLink'
              onClick={() => {
                dispatch(resetFileKey())
                dispatch(setFileKey(s.filekey))
                setisOpenFileReceiptModal(true)
              }}
            >{s.filekey}</AnchorInput>,
            'display_id': `Held at ${s.dateTime} by user ${s.user} in transaction ${s.id}`,
            status_display: statusComp
          }
        })
      }))
    }
    return t;
  }

  const onSelectAll = () => {
    const data = list.filter(l => l.canRelease === 'true');
    onRowSelect(true, data);
  }

  const fetchData = (args) => {
    let pi;
    if (args) {
      ({ pi } = args);
    }
    const payload = JSON.parse(JSON.stringify({
      "maxRecords": changeItemPerPage,
      "rowPosition": pi === undefined || pi === null ? pageIndex : pi,
      "sortField": fileSearchParam.sortField,
      "sortDescending": fileSearchParam.sortDescending,
      "checkNumber": fileSearchParam ? fileSearchParam.checkNumber : '',
      "claimNumber": fileSearchParam ? fileSearchParam.claimNumber : '',
      "groupId": fileSearchParam ? fileSearchParam.groupId : '',
      "idNumber": fileSearchParam ? fileSearchParam.idNumber : '',
      "filekey": fileSearchParam ? fileSearchParam.filekey : '',
      "showDuplicateChecks": true,
      "showProcessedDocuments": false,
    }))
    dispatch(setFileSearchParamNull(payload))
    dispatch(setFileSearchParam(payload))
    searchFiles(payload)
  }
  const renderGrid = (fromModal) => {
    let grid = <></>;
    if (fromModal)
      grid = <ShowMoreNestedGridComponent
        mainGridConfig={{
          transformationFunction: transformGroupedData,
          disabledColumns: new Set([]),
          columns: new Set([
            {
              dataName: 'display_id',
              groupingColumnName: 'id',
              text: 'Group Name',
              sortable: true,
              isSorted: 0,
              cellType: 'text',
            },
            {
              dataName: 'filekey',
              text: 'File Key',
              sortable: true,
              isSorted: 0,
              cellType: 'text',
            },
            {
              dataName: 'originalFileName',
              text: 'File Name',
              sortable: true,
              isSorted: 0,
              cellType: 'text',
            },
            {
              dataName: 'importedDate',
              text: 'Imported Date',
              sortable: true,
              isSorted: 0,
              cellType: 'text',
            }, {
              dataName: 'status_display',
              text: 'Status',
              sortable: true,
              isSorted: 0,
              cellType: 'custom',
            }, {
              dataName: 'errors',
              text: '',
              sortable: true,
              isSorted: 0,
              cellType: 'text',
            },]),
          isGrouped: true,
          defaultGroupBy: 'id',
          defaultGroupingType: 'table',
          wantGroupingControls: false,
          width: '100%',
        }}
        // data={list.filter(l => l.isSelected)}
        data={Array.from(selectedRecords)}
      />
    else {
      if (list && list.length > 0) {
        grid = <ShowMoreNestedGridComponent
          mainGridConfig={{
            transformationFunction: transformGroupedData,
            disabledColumns: new Set([]),
            columns: new Set([
              {
                dataName: 'display_id',
                groupingColumnName: 'id',
                text: 'Group Name',
                sortable: true,
                isSorted: 0,
                cellType: 'text',
              },
              {
                dataName: 'filekey_display',
                text: 'File Key',
                sortable: true,
                isSorted: 0,
                cellType: 'text',
              },
              {
                dataName: 'originalFileName',
                text: 'File Name',
                sortable: true,
                isSorted: 0,
                cellType: 'text',
              },
              {
                dataName: 'importedDate',
                text: 'Imported Date',
                sortable: true,
                isSorted: 0,
                cellType: 'text',
              }, {
                dataName: 'status_display',
                text: 'Status',
                sortable: true,
                isSorted: 0,
                cellType: 'custom',
              }, {
                dataName: 'errors',
                text: '',
                sortable: true,
                isSorted: 0,
                cellType: 'text',
              },]),
            isGrouped: true,
            defaultGroupBy: 'id',
            defaultGroupingType: 'table',
            wantGroupingControls: false,
            width: '85%',
            supportSelection: true,
            selectionKey: 'isSelected',
            selectionCondition: (x) => x.canRelease === 'true',
            disabledCondition: (x) => ["Scheduled"].indexOf(x.user) !== -1 && releaseType.value === "S",
            // disabledTooltip:()=>`This item is already scheduled to print. Please select release type First Available in order to override Scheduled Date`,
            onRowSelect
          }}
          data={list}
        />
      } else {
        grid = (
          <div className="displayFlex">
            <label style={{ color: 'red' }}>No records found. Change search criteria.</label>
          </div>
        )
      }
    }
    return grid;
  }

  return (
    <>
      <ListPaneHeaderWrapper
        title="Document Manager: Release Files"
        layoutControls={[expandCollapseListPaneButtonConfig]}

      />
      {isLoading ? (
        // replace with a better loading component
        <LoadingPage />
      ) : isError ? (
        // replace with a better error component
        <ErrorPage
          // this prop isn't real but is an example
          error={error}
        />
      ) : (
            <div className="mainWrapper">
              <div style={{ fontSize: "small", padding: "12px" }}>
                <i>{GENERIC_TEXT1}</i>
                <p>{GENERIC_TEXT_SEARCH_HOLD}</p>
                <p style={{ color: "#bf1616" }}>
                  {GENERIC_TEXT_HOLD}
                </p>
              </div>
              <div>

                <div className="">
                  <StyleDivParent className="popBoxDiv">
                    <PopoverBox
                      trigger="hover"
                      orientation="bottom"
                      buttonName="File Cutoff Time Applies"
                      buttonType="diminished"
                      domID="format_popup"
                      width="350px"

                    >
                      <div style={{
                        padding: "14px",
                        color: "#202083",
                        fontSize: "13px",
                        fontWeight: "bold",
                      }}>
                        File cutoff time is defined as the time in which a file must be fully imported, in order to be included in the 10:00AM release.

                  </div>
                    </PopoverBox>
                  </StyleDivParent>
                </div>

                <GridPaginationWrapper
                  rowsPerPage={changeItemPerPage}
                  onChangeRowsPerPage={(v) => setchangeItemsPerPage(v)}
                  pageIndex={pageIndex}
                  onChangePageIndex={(v) => setPageIndex(v)}
                  onShowButtonCLick={(args) => fetchData(args)}
                  totalRecords={totalRecordsResp}
                  defaultRowsPerPage={changeItemPerPage}
                  enableTextbox={false}
                />
                <div style={{ marginBottom: "10px" }} className="displayFlex">
                  <span>Release Type:</span>
                  <SelectDropdown
                    domID="relType"
                    onChange={(e) => {
                      setReleaseType(e)
                      setlist(JSON.parse(JSON.stringify(list)))
                    }}
                    initialValue={releaseType}
                    options={[
                      { value: 'S', label: 'Scheduled Release Rule' },
                      { value: 'I', label: 'First Available' }
                    ]}
                    size="small"
                    isClearable={false}
                    width="240px"
                  />
                </div>
                <div className='displayFlex'>
                  <Button
                    buttonType="standard"
                    size="small"
                    name="Select all on this page"
                    disabled={list && list.length === 0}
                    onClick={() => { onSelectAll() }}
                  />&nbsp;
              <Button
                    buttonType="standard"
                    size="small"
                    name="Clear all on this page"
                    disabled={list.filter(x => x.isSelected === true).length === 0}
                    onClick={() => { onClearSelectionCurrentPage() }}
                  />&nbsp;
              <Button
                    buttonType="standard"
                    size="small"
                    name="Clear all"
                    disabled={!selectedRecords.size}
                    onClick={() => { onClearAllSelection() }}
                  />&nbsp;
              <Button
                    buttonType="standard"
                    size="small"
                    name="New Search"
                    onClick={() => { history.push(`/DocumentManager/Release/File`) }}
                  />&nbsp;
              <Button
                    buttonType="standard"
                    size="small"
                    name="CONFIRM SELECTED ITEMS"
                    disabled={!selectedRecords.size}
                    onClick={() => { setisOpenConfirmModal(true) }}
                  />
                </div>
                <div className="displayFlex"></div>
                {renderGrid(false)}

                <div className="displayFlex"></div>

                <Modal isOpen={isOpenConfirmModal}>
                  <ModalHeader
                    title="Selected Files"
                    onClose={() => onCloseConfirmModal()}
                  />
                  <ModalBody>
                    <div className="displayFlex" style={{ paddingLeft: '30px' }}>
                      <span>Confirm your selections, and then click Confirm to remove the hold and release the files to printing.</span>
                    </div>
                    <div style={{ marginBottom: "10px", marginLeft: "30px" }} className="displayFlex">
                  <span>Release Type:</span>
                  <SelectDropdown
                    domID="relType"
                    disabled="true"
                    initialValue={releaseType}
                    options={[
                      { value: 'S', label: 'Scheduled Release Rule' },
                      { value: 'I', label: 'First Available' }
                    ]}
                    size="small"
                    width="240px"
                  />
                </div>
                    <div>
                      {isOpenConfirmModal ? (
                        <div className="modalOuterDiv" style={{
                          paddingLeft: '25px',
                          paddingRight: '25px',
                          marginTop: '20px',
                          marginBottom: '-10px',
                          maxHeight: '40vh',
                          overflow: 'scroll',
                          maxWidth: '800px'
                        }}>
                          {renderGrid(true)}
                        </div>
                      ) : null}

                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <ButtonGroup>
                      <Button
                        buttonType="standard"
                        domID="close"
                        dataTestId="test-close"
                        name="CLOSE"
                        onClick={() => setisOpenConfirmModal(false)}
                        size="medium"
                      />
                      <Button
                        buttonType="standard"
                        domID="confirm"
                        dataTestId="test-confirm"
                        name="CONFIRM"
                        onClick={() => onConfirm()}
                        size="medium"
                        disabled={!selectedRecords.size||isTxnLoading}
                      />
                      {
                        isTxnLoading && <LoadingPage height="1vh" />
                      }
                    </ButtonGroup>
                  </ModalFooter>
                </Modal>

                <Modal isOpen={isOpenFileReceiptModal}>
                  <StyledModalHeaderWrapper>
                    <ModalHeader
                      title="File Receipt"
                      onClose={() => onCloseFileReceiptModal()}
                    />
                  </StyledModalHeaderWrapper>
                  <ModalBody>
                    {isOpenFileReceiptModal ? (
                      <FileReceiptManagerDetailComponent
                        filekey={filekey}
                        height='58vh'
                        overflow='auto'
                      />
                    ) : null}
                  </ModalBody>
                </Modal>
              </div>
            </div>
          )}
    </>
  )
}

export default withAxiosCancellation(FileSearchResultReleaseMain)
