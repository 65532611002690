import styled from 'styled-components';
import { Input } from 'ui-core-ssgr';

export const GroupFilter = styled(Input)`
  margin-left: auto;
  input {
    padding-right: 1.25em;
    min-width: 240px;
  }
  & > button {
    height: 32px;
    top: 0;
  }
`;

export const SearchButtonImage = styled.img`
  height: 1.25em;
`;

export const GroupDetailRow = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-right: 2em;
`;
