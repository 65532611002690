const initialState = {
  isUpload: true,
  refetchAllTestFile:null
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'test_file_is_upload':
      return {
        ...state,
        isUpload: payload
      };
      case 'GET_TEST_FILE_LIST_GRID_DATA':
        return{
          ...state,
          refetchAllTestFile: payload.fetchFunc
        };
    default:
      return state
  }
}