import React, { useState, useMemo, useRef, useCallback } from 'react';
import styled from 'styled-components/dist/styled-components';
import { ErrorBoundary } from 'react-error-boundary'
import { NotificationBanner, Button, Modal, ModalHeader, ModalBody, Card } from 'ui-core/dist';
import ErrorContext from './ErrorContext'
import { useEffect } from 'react';
import ModalWrapper from './components/Modal/components/ModalWrapper';
import useInsertErrorDetails from './api/useErhErrorDetails'
import LoadingComponent from './components/LoadingComponent';

const StyledParentContainer = styled.div`
cursor: default;
    line-height: 1.5;
    tab-size: 4;
    -webkit-tap-highlight-color: transparent /* 4 */;
    -webkit-text-size-adjust: 100%;
    word-break: break-word;
    box-sizing: border-box;
    font-family: 'Core Sans C';
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    background-color: #F4F6F9;
    background-repeat: no-repeat;
    background-size: cover;
`
const StyledContainer = styled.div`
cursor: default;
line-height: 1.5;
tab-size: 4;
-webkit-tap-highlight-color: transparent /* 4 */;
-webkit-text-size-adjust: 100%;
word-break: break-word;
box-sizing: border-box;
font-family: 'Core Sans C';
text-align: center;
display: flex;
-webkit-box-pack: center;
justify-content: center;
-webkit-box-align: center;
align-items: center;
width: 511px;
`
const StyledCardWrapper = styled.div`
cursor: default;
line-height: 1.5;
tab-size: 4;
-webkit-tap-highlight-color: transparent /* 4 */;
-webkit-text-size-adjust: 100%;
word-break: break-word;
box-sizing: border-box;
font-family: 'Core Sans C';
display: flex;
flex-direction: column;
-webkit-box-pack: justify;
justify-content: space-between;
width: 100%;
height: 100%;
background: #fff;
box-shadow: 0px 1px 2px rgba(17,80,244,0.1),0px 2px 8px rgba(166,175,197,0.3);
border-radius: 8px;
text-align: center;
padding: 48px;
`
const StyledHeader = styled.h1`
cursor: default;
tab-size: 4;
-webkit-tap-highlight-color: transparent /* 4 */;
-webkit-text-size-adjust: 100%;
word-break: break-word;
box-sizing: border-box;
text-align: center;
font-family: Core Sans C;
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 140%;
color: #0F0F59;
margin: 0;
`
const StyledBodyContainer = styled.div`
cursor: default;
line-height: 1.5;
tab-size: 4;
-webkit-tap-highlight-color: transparent /* 4 */;
-webkit-text-size-adjust: 100%;
word-break: break-word;
box-sizing: border-box;
font-family: 'Core Sans C';
-webkit-box-flex: 1;
flex-grow: 1;
text-align: center;
max-height: 300px;
overflow: scroll;
`
const StyledBodyText = styled.div`
cursor: default;
tab-size: 4;
-webkit-tap-highlight-color: transparent /* 4 */;
-webkit-text-size-adjust: 100%;
word-break: break-word;
box-sizing: border-box;
text-align: center;
font-family: Core Sans C;
font-style: normal;
font-weight: normal;
font-size: 13px;
line-height: 150%;
color: #626D8A;
margin-top: 12px;
`
const StyledButtonContainer = styled.div`
cursor: default;
line-height: 1.5;
tab-size: 4;
-webkit-tap-highlight-color: transparent;
-webkit-text-size-adjust: 100%;
word-break: break-word;
font-size: 13px;
box-sizing: border-box;
font-family: 'Core Sans C';
text-align: center;
left: 38%;
position: relative;
`
const StyledModalWrapperWidth = styled(ModalWrapper)`
& .active-content {
  width: 60% !important;
  height: 80vh !important;
}
`
const StyledCardContainer = styled.div`
& > div[data-testid="undefined-wrapper"] {
  height: ${props => !props.showStack ? "30px" : "100%"};
  width: ${props => !props.showStack ? "96%" : "96%"};
  & > h4 {
    margin-bottom: ${props => props.showStack ? "0px !important" : "0px !important"};
    margin-top: ${props => props.showStack ? "0px !important" : "0px !important"};
  }
  & > div:nth-child(2) {
    height: ${props => props.showStack ? "250px !important" : "auto"};
    overflow: ${props => props.showStack ? "auto" : "initial"};
  }
}
`
const externalRedirectModules = ['DashboardMain', 'DashboardDetail', 'ChooseContext', 'SideNav']
export const FallbackComponent = ({ error, resetErrorBoundary, componentStack }) => {
  return <></>
}

const ErrorBoundaryWrapper = ({ children }) => {
  const [messageConfig, setMessageConfig] = useState({ message: '', info: null });
  const [explode, setExplode] = React.useState(false)
  const historyRef = useRef(null)
  const errorRef = useRef(null)
  const [isOpenErrorMessageModal, setisOpenErrorMessageModal] = useState(false)
  const [showStack, setshowStack] = useState(false)
  const [externalModuleCheckName, setexternalModuleCheckName] = useState('')
  const [hasError, setHasError] = useState(false)

  const { errorIdResp, isError, error, insertErrorDetails, isLoading } = useInsertErrorDetails()

  const handleCustomError = (error, errorInfo, componentName) => {
    if (myErrorHandler) {
      myErrorHandler(error, errorInfo, componentName)
      // handleError(error, {...errorInfo, componentName})
    }
  }
  const getHistoryRef = useCallback((ref) => {
    if (ref) historyRef.current = ref;
    return historyRef?.current?.listen(routingCallabck)
  }, [messageConfig])

  const routingCallabck = useCallback((location) => {
    if (location.pathname && (historyRef.current.action === "POP" || historyRef.current.action === "PUSH")
      && messageConfig?.message && hasError) {
      setMessageConfig(JSON.parse(JSON.stringify({ message: '', info: null })))
      setisOpenErrorMessageModal(false)
      setshowStack(false)
      setHasError(false)
      setexternalModuleCheckName('')
    }
  }, [messageConfig])

  const myErrorHandler = (error, info, componentName) => {
    let componentStack=info?.componentStack?.trim().replaceAll(/'/g, '"');
    if(componentName){
      componentStack=componentStack+' inside component ' + componentName || '';
    }
    insertErrorDetails({
      message: (error?.message || "").replaceAll(/'/g, '"'),
      info: { ...info, componentStack }
    })
    const s = componentName || info?.componentStack;
    setexternalModuleCheckName(s)
    setHasError(true)
    if (!externalRedirectModules.find(x => s.indexOf(x) !== -1)) {
      setMessageConfig(JSON.parse(JSON.stringify({ message: error?.message, info })))
      setisOpenErrorMessageModal(true)
      setshowStack(false)
      if (errorRef.current && errorRef.current.resetErrorBoundary) errorRef?.current?.resetErrorBoundary()
    }
  }

  const renderErrorDetails = useMemo(() => (
    <>
      {isLoading ? (
        <div style={{ marginLeft: '50%' }}><LoadingComponent /></div>
      ) : (
        <div style={{ marginLeft: '34px', marginBottom: '10px', maxHeight: '400px', overflow: 'scroll' }} className="marginLeft">
          <div style={{ fontSize: '13px', color: 'red' }}>There were errros processing your request:</div>
          {isError && error ? (
            <p><div style={{ fontSize: '13px', color: '#0F0F59' }}>Some error occurred while inserting error details.</div></p>
          ) : null}
          {errorIdResp ? (
            <p><div style={{ fontSize: '13px', color: '#0F0F59' }}><span style={{ fontWeight: 'bold' }}>Error ID: {errorIdResp}</span>. Please pass the error ID to Change Healthcare Client Support</div></p>
          ) : null}
        </div>
      )}
    </>
  ), [errorIdResp, isError, error, isLoading])

  const resetErrorBoundary = () => { if (errorRef.current && errorRef.current.resetErrorBoundary) errorRef?.current?.resetErrorBoundary(); setexternalModuleCheckName(''); setHasError(false) }
  const renderFallbackComponent = useMemo(() => {
    if (externalModuleCheckName
      && externalRedirectModules.find(x => externalModuleCheckName?.indexOf(x) !== -1) && hasError) {

      return (
        <>
          <StyledParentContainer>
            <StyledContainer>
              <StyledCardWrapper>
                <StyledHeader>Application Error</StyledHeader>
                <StyledBodyContainer>
                  <StyledBodyText>
                    {renderErrorDetails}
                  </StyledBodyText>
                  <br />
                  <div className="displayFlex">
                    <StyledButtonContainer>
                      <Button
                        id='tryagain'
                        size="small"
                        name="Try Again"
                        onClick={() => resetErrorBoundary()}
                      />
                    </StyledButtonContainer>
                  </div>
                </StyledBodyContainer>
              </StyledCardWrapper>
            </StyledContainer>
          </StyledParentContainer>
        </>
      )
    }
    return (
      <ErrorBoundary
        ref={errorRef}
        // FallbackComponent={FallbackComponent}
        // fallbackRender={renderFallbackComponent}
        onError={myErrorHandler}
        onReset={() => {
          setExplode(!explode)
          if (historyRef.current) historyRef.current.replace('/')
        }}
        resetKeys={[explode]}
      >
        {explode ? <>{children}</> : <>{children}</>}
      </ErrorBoundary>
    )
  }, [externalModuleCheckName, errorIdResp, isError, error, isLoading, hasError, explode])

  const renderError = useMemo(() => {
    if (messageConfig && messageConfig?.message && messageConfig?.info && hasError && externalModuleCheckName) {
      if (!externalRedirectModules.find(x => externalModuleCheckName.indexOf(x) !== -1) && hasError) {
        return (
          <Modal isOpen={isOpenErrorMessageModal}>
            <ModalHeader
              title="Application Error"
              onClose={() => {
                setMessageConfig(JSON.parse(JSON.stringify({ message: '', info: null})))
                setisOpenErrorMessageModal(false)
              }}
            />
            {isOpenErrorMessageModal ? (
              <ModalBody>
                {isLoading ? (
                  <div style={{ marginLeft: '50%' }}><LoadingComponent /></div>
                ) : (
                  <div style={{ marginLeft: '34px', marginBottom: '20px', maxHeight: '400px', overflow: 'scroll' }} className="marginLeft">
                    <div style={{ fontSize: '13px', color: 'red' }}>There were errros processing your request:</div>
                    {isError && error ? (
                      <p><div style={{ fontSize: '13px', color: '#0F0F59' }}>Some error occurred while inserting error details.</div></p>
                    ) : null}
                    {errorIdResp ? (
                      <p><div style={{ fontSize: '13px', color: '#0F0F59' }}><span style={{ fontWeight: 'bold' }}>Error ID: {errorIdResp}</span>. Please pass the error ID to Change Healthcare Client Support</div></p>
                    ) : null}

                  </div>
                )}
              </ModalBody>
            ) : null}
          </Modal>
        )
      }
    }
    return null;
  }, [messageConfig, isOpenErrorMessageModal, showStack, isLoading, errorIdResp, isError, error, externalModuleCheckName, hasError])

  return (
    <>
      <ErrorContext.Provider value={{ getHistoryRef, handleCustomError }}>
        {renderFallbackComponent}
        {renderError}

      </ErrorContext.Provider>
    </>
  )
}

export default ErrorBoundaryWrapper;
