import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'ui-core';
import CloseLayoutControl from './CloseLayoutControl/CloseLayoutControl';
import ModalWrapper from './components/ModalWrapper';
import ModalContent from './components/ModalContent';


const INITIAL_STATE = {
  isOpen: false,
  interactiveEls: [],
  currentElIndex: null,
};

// list of focusable element types, to be used for auto-focusing in modal when modal renders
// https://bitsofco.de/accessible-modal-dialog/
const focusableQueryArray = [
  'a[href]',
  'area[href]',
  'input:not([disabled])',
  'select:not([disabled])',
  'textarea:not([disabled])',
  'button:not([disabled])',
  '[tabindex="0"]',
];

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  componentDidMount() {
    if (this.props.deferBodyControl) {
      return;
    }

    if (this.props.isOpen) {
      document.body.style.overflow = 'hidden';
    }
  }

  componentWillUnmount() {
    if (this.props.deferBodyControl) {
      return;
    }
    document.body.style.overflow = 'initial';
  }

  onClickOut(e) {
    if (typeof this.props.onClickOut === 'function') {
      this.props.onClickOut(e);
      return;
    }
    if (this.state.isOpen && e.target.id !== this.props.buttonConfig.domID) {
      this.toggleModal(e, false);
    }
  }

  // handle Tab navigation
  onKeyPress(e) {
    const TAB_KEY = 9;
    const {keyCode} = e;
    const {shiftKey} = e;
    
    if (keyCode === TAB_KEY) {
      e.preventDefault();
      const formTag = e.target.closest(".tabIndexForm");
      const inputs = formTag.querySelectorAll(".tabIndexClass");
      const controlTypes = ['text', 'radio', 'select-one', 'button','submit'];
      let currentFocusIndex = -1;
      if (e.which === 9 && !e.shiftKey) {
        for (let i = 0; i < inputs.length; i++) {
          if (inputs[i] === e.target) {
            currentFocusIndex = i;
            break;
          }
          if (inputs[i].firstElementChild != null) {
            if (inputs[i].firstElementChild.firstElementChild != null) {
              if (inputs[i].firstElementChild.firstElementChild === e.target) {
                currentFocusIndex = i;
                break;
              }
            } else if (inputs[i].firstElementChild === e.target) {
              currentFocusIndex = i;
              break;
            }
          }
        }
        for (let i = currentFocusIndex; i < inputs.length; i++) {          
          if (currentFocusIndex >= -1) {
            currentFocusIndex = i + 1;
            if (currentFocusIndex <= inputs.length - 1) {
              if (controlTypes.indexOf(inputs[currentFocusIndex].type) >= 0) {
                if (inputs[currentFocusIndex].disabled === false) {
                  inputs[currentFocusIndex].focus();
                  break;
                }
              } else if (inputs[currentFocusIndex].firstElementChild != null &&
                controlTypes.indexOf(inputs[currentFocusIndex].firstElementChild.type) >= 0) {
                if (inputs[currentFocusIndex].firstElementChild.disabled === false) {
                  inputs[currentFocusIndex].firstElementChild.focus();
                  break;
                }
              }              
              else if (inputs[currentFocusIndex].firstElementChild.firstElementChild != null &&
                controlTypes.indexOf(inputs[currentFocusIndex].firstElementChild.firstElementChild.type) >= 0) {
                if (inputs[currentFocusIndex].firstElementChild.firstElementChild.disabled === false) {
                  inputs[currentFocusIndex].firstElementChild.firstElementChild.focus();
                  break;
                }
              }
            }
          }
        }
      } else if (e.which === 9 && e.shiftKey) {
        for (let i = 0; i < inputs.length; i++) {
          if (inputs[i] === e.target) {
            currentFocusIndex = i;
            break;
          }
          if (inputs[i].firstElementChild != null) {
            if (inputs[i].firstElementChild.firstElementChild != null) {
              if (inputs[i].firstElementChild.firstElementChild === e.target) {
                currentFocusIndex = i;
                break;
              }
            } else if (inputs[i].firstElementChild === e.target) {
              currentFocusIndex = i;
              break;
            }
          }
        }
        for (let i = currentFocusIndex; i >= 0; i--) {
          if (currentFocusIndex >= 0) {
            currentFocusIndex = i - 1;
            if (currentFocusIndex <= inputs.length - 1) {
              if (controlTypes.indexOf(inputs[currentFocusIndex].type) >= 0) {
                if (inputs[currentFocusIndex].disabled === false) {
                  inputs[currentFocusIndex].focus();
                  break;
                }
              } else if (inputs[currentFocusIndex].firstElementChild != null &&
                controlTypes.indexOf(inputs[currentFocusIndex].firstElementChild.type) >= 0) {
                if (inputs[currentFocusIndex].firstElementChild.disabled === false) {
                  inputs[currentFocusIndex].firstElementChild.focus();
                  break;
                }
              }              
              else if (inputs[currentFocusIndex].firstElementChild.firstElementChild != null &&
                controlTypes.indexOf(inputs[currentFocusIndex].firstElementChild.firstElementChild.type) >= 0) {
                if (inputs[currentFocusIndex].firstElementChild.firstElementChild.disabled === false) {
                  inputs[currentFocusIndex].firstElementChild.firstElementChild.focus();
                  break;
                }
              }
            }
          }
        } // for        
      }
    }
  }

  toggleModal(e, forceState) {
    if (typeof e.persist === 'function') e.persist();
    if (forceState === true && !this.props.deferBodyControl) {
      document.body.style.overflow = 'hidden';
    } else if (forceState === false && !this.props.deferBodyControl) {
      document.body.style.overflow = 'initial';
    }
    this.setState({ isOpen: forceState }, () => {
      this.props.onModalToggle(e, this.state);
    });
  }

  render() {
    const {buttonConfig} = this.props;
    const {wrapperStyle} = this.props;
    const isOpen = this.props.isOpen !== undefined ? this.props.isOpen : this.state.isOpen;
    return (
      <span id={this.props.domID}>
        {this.props.buttonConfig
          ? <Button
            buttonType="standard"
            size={buttonConfig.size}
            domID={buttonConfig.domID}
            onClick={e => this.toggleModal(e, true)}
            name={buttonConfig.label}
          />
          : null
        }
        <ModalWrapper
          wrapperStyle={wrapperStyle}
          className={isOpen ? 'active' : ''}
          ref={(c) => { this.modalWrapper = c; }}
          onKeyDown={e => this.onKeyPress(e)}
        >
          <ModalContent
            className={isOpen ? 'active-content' : ''}
            onClickOut={e => this.onClickOut(e)}
            style={{ width: this.props.width }}
          >
            {this.props.children}
            {this.props.showCloseIcon
              ? <CloseLayoutControl
                buttonType="close"
                hideTooltip
                className="modal-close"
                onClick={e => this.toggleModal(e, false)}
              />
              : null
            }
          </ModalContent>
        </ModalWrapper>
      </span>
    );
  }
}

Modal.defaultProps = {
  children: [],
  onModalToggle: () => false,
  domID: null,
  buttonConfig: null,
  isOpen: undefined,
  onClickOut: undefined,
  deferBodyControl: false,
  width: '80%',
  showCloseIcon: true,
};

Modal.propTypes = {
  buttonConfig: PropTypes.shape({
    label: PropTypes.string.isRequired,
    domID: PropTypes.string,
    size: PropTypes.oneOf(['small', 'medium', 'large'])
  }),
  children: PropTypes.node.isRequired,
  onModalToggle: PropTypes.func,
  domID: PropTypes.string,
  isOpen: PropTypes.bool,
  onClickOut: PropTypes.func,
  deferBodyControl: PropTypes.bool,
  width: PropTypes.string,
  showCloseIcon: PropTypes.bool,
};

export default Modal;
