import React, { Component } from 'react';
import { Page } from '../../components'
import GlobalStyles from '../../shared/styles/globalStyles';
import GroupWrapper from './GroupWrapper'

class App extends Component {
  render() {
    return (
      <div id="App" className="App">
        <GlobalStyles />
        <Page ListPaneContent={() => <GroupWrapper />} />
      </div>
    );
  }
}

export default App;
