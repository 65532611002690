import React from 'react'
import { useSelector } from 'react-redux'
import { Page } from '../../components'
import RouteWrapper from '../../components/RouteWrapper';
import ListPaneContent from './UserManagerListPane'
import UserManagerDetailPane from './UserManagerDetailPane'
import AddAUser from './AddAUser'


const UserManager = () => {
  const isAddUser = useSelector(store => store.userManager.isAddUser)
  return (
  <RouteWrapper>
    <Page 
      ListPaneContent={ListPaneContent}
      DetailPaneContent={isAddUser ? AddAUser: UserManagerDetailPane}
    />
  </RouteWrapper>
  )
}

export default UserManager