import { createSelector } from "reselect";

export const selectLoadingState = state => state.loadingState.loadingForDeactivateGroupModal.size
export const selectGroupsList = state => state.groupsList;
export const selectedGroupIds = state => state.groups.selectedGroupIds;


export const selectCurrentSelectedGroupNames = createSelector(
  selectGroupsList,
  selectedGroupIds,
  ({records},groupIds) => {
    const groupNamesList = [];  
    if (groupIds) {
      groupIds.forEach(groupId => {
        const selectedGroup = records.find(record => record.id === groupId)
        if (selectedGroup) groupNamesList.push(selectedGroup.name);
      });
    }
    return groupNamesList;
  }
);
