/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types'
// import { ListOfValue } from  '..';
import MultipleSelectDropdown from '../MultipleSelectDropdown';
import { useEmployerGroup, useEmployerGroupsLite, useWebGroupList } from '../../api/useDocumentManager'
import { EMPLOYER_GROUP_LIST_NOT_AVAILABLE } from "../../containers/DocumentManager/Modules/constants";
import LoadingComponent from '../LoadingComponent';
import { SelectDropdown, Loading } from ".."


EmployerGroup.propTypes = {
  onSelect: PropTypes.func,
  setEmpGroup: PropTypes.func,
  setGroupId: PropTypes.func,
  setWebGroup: PropTypes.func,
  setLimitToSpecifixGroup: PropTypes.func,
  onSelectRow: PropTypes.func,
  selectInputLabelWidth: PropTypes.string,
  selectInputWidth: PropTypes.string,
  labelContainer: PropTypes.func,
  inputContainer: PropTypes.func,
  hasLimitExceeded: PropTypes.func,
};

function EmployerGroup(props) {
  const { onSelect, setEmpGroup, setGroupId, setWebGroup, setLimitToSpecifixGroup, onSelectRow,
    selectInputLabelWidth, selectInputWidth, labelContainer, inputContainer, hasLimitExceeded, 
    resetEmpGroupValue, initialValue, skipCheck = false, getCancelSourceWithName=()=>false } = props;

  const [newEmpGroup, setNewEmpGroup] = useState([]);

  const [initialValueDropdown, setInitialValueDropdown] = useState(null);

  const { empData, isLoading, getEmployerGroup } = useEmployerGroup({getCancelSource:getCancelSourceWithName('getEmployerGroup')});

  const { empGroupData, getEmployerGroupsLite } = useEmployerGroupsLite({getCancelSource:getCancelSourceWithName('getEmployerGroupsLite')});

  const { webGroupData, getWebGroupList } = useWebGroupList({getCancelSource:getCancelSourceWithName('getWebGroupList')});

  const listOfDataPayload = {
    "maxRecords": "2500",
    "rowPosition": "0",
    "sortField": 'Desc',
    "viewInactive": false,
    "sortDescending": 'true',
    "parameters": "",
  }

  useEffect(() => {
    if (!initialValue && empData.hasLimitExceeded) {
      setInitialValueDropdown(JSON.parse(JSON.stringify({ label: '', value: '' })))
    }
  }, [initialValue])

  useEffect(() => {
    getEmployerGroup({ param: 1 });
  }, []);

  useEffect(() => {
    if (empData) {
      if (setEmpGroup && !empData.hasLimitExceeded) setEmpGroup(empData.empGroup);
      if (setGroupId) setGroupId(empData.groupId);
      if (setWebGroup) setWebGroup(empData.webGroup);
      if (setLimitToSpecifixGroup) setLimitToSpecifixGroup(empData.limitToSpecificGroup)
      if (hasLimitExceeded) hasLimitExceeded(empData.hasLimitExceeded)
    }
    if (empData && empData.hasLimitExceeded) {
      getEmployerGroupsLite(listOfDataPayload);
    }
  }, [empData])

  useEffect(() => {
    if (empGroupData && empGroupData.length) {
      setNewEmpGroup(empGroupData);
      if (empData.webGroup) {
        getWebGroupList({ ...listOfDataPayload, webGroupID: empData.wGrp });
      }
    }
  }, [empGroupData]);

  useEffect(() => {
    if (webGroupData && webGroupData.length) {
      const arr = empGroupData.filter(item => webGroupData.includes(item.value));
      setNewEmpGroup(arr);
    }
  }, [webGroupData])

  const renderSection = useMemo(() => {
    if (empData) {
      return (
        skipCheck ? <>
          {labelContainer ? labelContainer(
            <label style={{ width: selectInputLabelWidth }}>Employer Group</label>) : <label>Employer Group</label>}
          {inputContainer ? inputContainer(
            <div style={{ width: selectInputWidth, fontSize: 'small' }}>
              {/* <SelectDropdown
              domID="employerGroup"
              options={empGroupData}
              size="small"
              initialValue={initialValueDropdown}
              onChange={(e)=>{onSelectRow(e)}}
              isClearable={false}
            /> */}
              <SelectDropdown
                width="300px"
                placeholder="Select Employer Group"
                domID="AccessDataSourceDropown"
                onChange={(e) => { onSelectRow(e) }}
                initialValue={initialValue}
                options={
                  empData.hasLimitExceeded ? empGroupData?.map(ds => ({ label: ds.label, value: ds.value })) : empData.empGroup
                }
              />
            </div>) :
            <div style={{ fontSize: 'small' }}><SelectDropdown
              width="300px"
              placeholder="Select Employer Group"
              domID="AccessDataSourceDropown"
              onChange={(e) => { onSelectRow(e) }}
              initialValue={initialValue}
              options={
                empData.hasLimitExceeded ? empGroupData?.map(ds => ({ label: ds.label, value: ds.value })) : empData.empGroup
              }
            />
            </div>
          }
        </> :
          empData.hasLimitExceeded ?
            // <ListOfValue
            //   includeInput
            //   labelContainer={labelContainer}
            //   inputContainer={inputContainer}
            //   inputLable="Employer Group"
            //   anchorLabel="Select a Employer Group"
            //   fetchDataURL='empGroupManager/getEmployerGroupsLite'
            //   payload={JSON.parse(JSON.stringify(listOfDataPayload))}
            //   transformationFunction={(d)=>d?.collection?.error||[]}
            //   column={SELECT_EMPLOYER_GROUP}
            //   onSelectValue={(value)=>onSelectRow(value)}
            //   modalTitle="Select Employer Group"
            //   modalDesc="Click an Employer Group Name to select it and close this window."
            //   selectColumn="id"
            // />
            <>
              {labelContainer ? labelContainer(
                <label style={{ width: selectInputLabelWidth }}>Employer Group</label>) : <label>Employer Group</label>}
              {inputContainer ? inputContainer(
                <div style={{ width: selectInputWidth, fontSize: 'small' }}>
                  {/* <SelectDropdown
                  domID="employerGroup"
                  options={empGroupData}
                  size="small"
                  initialValue={initialValueDropdown}
                  onChange={(e)=>{onSelectRow(e)}}
                  isClearable={false}
                /> */}
                  <SelectDropdown
                    width="300px"
                    placeholder="Select Employer Group"
                    domID="AccessDataSourceDropown"
                    onChange={(e) => { onSelectRow(e) }}
                    initialValue={initialValue}
                    options={empGroupData?.map(ds => ({ label: ds.label, value: ds.value }))}
                  />
                </div>) :
                <div style={{ fontSize: 'small' }}><SelectDropdown
                  width="300px"
                  placeholder="Select Employer Group"
                  domID="AccessDataSourceDropown"
                  onChange={(e) => { onSelectRow(e) }}
                  initialValue={initialValue}
                  options={empGroupData?.map(ds => ({ label: ds.label, value: ds.value }))}
                />
                </div>
              }
            </>
            :
            <MultipleSelectDropdown
              id='employerGroup'
              selectInputLabelWidth={selectInputLabelWidth}
              selectInputWidth={selectInputWidth}
              labelContainer={labelContainer}
              inputContainer={inputContainer}
              methodType='POST'
              fetchDataURL=''
              label='Employer Group(s):'
              errorMessage={EMPLOYER_GROUP_LIST_NOT_AVAILABLE}
              effect={(value) => onSelect(value)}
              pRecords={empData.empGroup}
              displayField='label'
              valueField='value'
              displayDefault
              initialValue={initialValue}
            />)
    }
    return null;
  }, [empData, empGroupData, resetEmpGroupValue, initialValue, initialValueDropdown]);

  return (
    <>
      {isLoading ? (
        <LoadingComponent />
      ) : (
          <>
            {renderSection}
          </>
        )}
    </>
  );
}

export default EmployerGroup;
