import {
  IS_CLONING_RULE_DETAIL,
  IS_EDITING_RULE_DETAIL,
  IS_READ_ONLY_RULE_DETAIL,
  IS_POSTING_RULE_DETAIL,
  IS_SHOWING_PRODUCTION_ARRANGEMENT_MODAL,
  RULE_DETAIL_ELEMENTS_SUCCESS,
  RULES_DETAIL_ELEMENT_ERROR,
  RULE_DETAIL_POST_REQUEST_SUCCESS,
  RULE_DETAIL_POST_REQUEST_ERROR,
  SET_SELECTED_RULE_DETAIL,
  IS_SHOWING_RETURN_ADDRESS_MODAL,
  IS_SHOWING_DUPLEX_RULE_MODAL,
  IS_SHOWING_SIGNATURE_RULE_MODAL,
  IS_SHOWING_AUTO_HOLD_MODAL,
  IS_SHOWING_CHECK_TEXT_RULE_MODAL,
  IS_SHOWING_MEMBER_ID_MODAL,
  IS_SHOWING_TAX_ID_ARRANGEMENT_MODAL,
  IS_SHOWING_IMAGE_RULE_MODAL,
  IS_SHOWING_ERISA_MESSAGE_MODAL,
  IS_SHOWING_HIGH_DOLLAR_REVIEW_MODAL,
  IS_SHOWING_GENERAL_MESSAGE_MODAL,
  IS_SHOWING_CUSTOMER_SERVICE_MESSAGE_MODAL,
  IS_SHOWING_PDF_TAGGING_MODAL,
  IS_SHOWING_PROVIDER_INSURED_OPTION_MESSAGE_MODAL,
  RESET_RULE_DETAIL_ELEMENTS,
  IS_SHOWING_ADD_NEW_ADDRESS_MODAL,
  ADD_NEW_ADDRESS_GET_ELEMENTS_TRIGGER_SUCCESS,
  ADD_NEW_ADDRESS_GET_ELEMENTS_TRIGGER_ERROR,
  IS_SHOWING_IMAGE_LIBRARY_MODAL,
  IS_SHOWING_IMAGE_UPLOAD_MODAL,
  GET_RULE_IMAGE_TRIGGER_SUCCESS,
  GET_RULE_IMAGE_TRIGGER_ERROR,
  GET_RULE_IMAGE_COUNT_TRIGGER_SUCCESS,
  GET_RULE_IMAGE_COUNT_TRIGGER_ERROR,
  CONVERT_IMAGE_UPLOAD_TRIGGER_SUCCESS,
  CONVERT_IMAGE_UPLOAD_TRIGGER_ERROR,
  POST_IMAGE_UPLOAD_SUCCESS,
  POST_IMAGE_UPLOAD_ERROR,
  IS_SHOWING_COMMIT_IMAGE_MODAL,
  POST_COMMIT_IMAGE_TRIGGER,
  POST_COMMIT_IMAGE_TRIGGER_SUCCESS,
  POST_COMMIT_IMAGE_TRIGGER_CANCEL,
  RESET_STATE
} from './constants';

export const INITIAL_STATE = {
  detailElements: {},
  selectedRuleDetail: {},
  isCloningRuleDetail: false,
  isEditingRuleDetail: false,
  isReadOnlyRuleDetail: false,
  isPostingRuleDetail: false,
  isShowingProductionArrangementModal: false,
  isShowingCustomerServiceMessageModal: false,
  isShowingReturnAddressModal: false,
  isShowingDuplexRuleModal: false,
  isShowingSignatureRuleModal: false,
  isShowingCheckTextRuleModal: false,
  isShowingImageRuleModal: false,
  isShowingAutoHoldModal: false,
  isShowingMemberIdModal: false,
  isShowingTaxIdArrangementRuleModal: false,
  isShowingErisaMessageModal: false,
  isShowingHighDollarReviewModal: false,
  isShowingAddNewAddressModal: false,
  isShowingProviderInsuredOptionMessageModal: false,
  isShowingGeneralMessageModal: false,
  isShowingPDFTaggingModal: false,
  errorNotifications: new Map(),
  isShowingImageLibraryModal: false,
  isShowingImageUploadModal: false,
  convertedImageUploadError: null,
  convertedImageUploadData: null,
  postImageUploadSuccessId: null,
  postImageCommitSuccessId: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RULE_DETAIL_ELEMENTS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        errorNotifications: new Map()
      };
    case RULES_DETAIL_ELEMENT_ERROR:
      return {
        ...state,
        errorNotifications: state.errorNotifications.set(action.payload.id, {
          msg: `${action.payload.msg}`,
          type: `${action.payload.type}`,
          code: `${action.payload.code}`,
        })
      };
    case SET_SELECTED_RULE_DETAIL:
      return {
        ...state,
        selectedRuleDetail: { ...action.payload },
      };
    case IS_SHOWING_PRODUCTION_ARRANGEMENT_MODAL:
      return {
        ...state,
        isShowingProductionArrangementModal: action.payload,
      };
    case IS_SHOWING_CUSTOMER_SERVICE_MESSAGE_MODAL:
      return {
        ...state,
        isShowingCustomerServiceMessageModal: action.payload,
      };
    case IS_SHOWING_RETURN_ADDRESS_MODAL:
      return {
        ...state,
        isShowingReturnAddressModal: action.payload,
      };
    case IS_SHOWING_DUPLEX_RULE_MODAL:
      return {
        ...state,
        isShowingDuplexRuleModal: action.payload,
      };
    case IS_SHOWING_SIGNATURE_RULE_MODAL:
      return {
        ...state,
        isShowingSignatureRuleModal: action.payload,
      };
    case IS_SHOWING_CHECK_TEXT_RULE_MODAL:
      return {
        ...state,
        isShowingCheckTextRuleModal: action.payload,
      };
    case IS_SHOWING_IMAGE_RULE_MODAL:
      return {
        ...state,
        isShowingImageRuleModal: action.payload,
      };
    case IS_SHOWING_AUTO_HOLD_MODAL:
      return {
        ...state,
        isShowingAutoHoldModal: action.payload,
      };
    case IS_SHOWING_MEMBER_ID_MODAL:
      return {
        ...state,
        isShowingMemberIdModal: action.payload,
      };
    case IS_SHOWING_TAX_ID_ARRANGEMENT_MODAL:
      return {
        ...state,
        isShowingTaxIdArrangementRuleModal: action.payload,
      };
    case IS_SHOWING_ERISA_MESSAGE_MODAL:
      return {
        ...state,
        isShowingErisaMessageModal: action.payload,
      };
    case IS_SHOWING_HIGH_DOLLAR_REVIEW_MODAL:
      return {
        ...state,
        isShowingHighDollarReviewModal: action.payload,
      };
    case IS_SHOWING_PROVIDER_INSURED_OPTION_MESSAGE_MODAL:
      return {
        ...state,
        isShowingProviderInsuredOptionMessageModal: action.payload,
      };
    case IS_SHOWING_GENERAL_MESSAGE_MODAL:
      return {
        ...state,
        isShowingGeneralMessageModal: action.payload,
      };
    case IS_SHOWING_PDF_TAGGING_MODAL:
      return {
        ...state,
        isShowingPDFTaggingModal: action.payload,
      };
    case IS_CLONING_RULE_DETAIL:
      return {
        ...state,
        isCloningRuleDetail: action.payload,
      };
    case IS_EDITING_RULE_DETAIL:
      return {
        ...state,
        isEditingRuleDetail: action.payload,
      };
    case IS_READ_ONLY_RULE_DETAIL:
      return {
        ...state,
        isReadOnlyRuleDetail: action.payload,
      };
    case IS_POSTING_RULE_DETAIL:
      return {
        ...state,
        isPostingRuleDetail: action.payload,
      };
    case RULE_DETAIL_POST_REQUEST_SUCCESS:
      return {
        ...state,
      };
    case RULE_DETAIL_POST_REQUEST_ERROR:
      return {
        ...state,
      };
    case RESET_RULE_DETAIL_ELEMENTS:
      return {
        ...state,
        detailElements: {},
      };
    case IS_SHOWING_ADD_NEW_ADDRESS_MODAL:
      return {
        ...state,
        isShowingAddNewAddressModal: action.payload,
      };
    case ADD_NEW_ADDRESS_GET_ELEMENTS_TRIGGER_SUCCESS:
      return {
        ...state,
        ...action.payload.detailElements,
      };
    case ADD_NEW_ADDRESS_GET_ELEMENTS_TRIGGER_ERROR:
      return {
        ...state,
      };
    case IS_SHOWING_IMAGE_LIBRARY_MODAL:
      return {
        ...state,
        isShowingImageLibraryModal: action.payload,
        postImageUploadSuccessId : null,
      };
    case IS_SHOWING_IMAGE_UPLOAD_MODAL: 
      return {
        ...state,
        isShowingImageUploadModal: action.payload,
        convertedImageUploadData: null,
        convertedImageUploadError: null
      };
    case GET_RULE_IMAGE_TRIGGER_SUCCESS:
      return {
        ...state,
        ruleImages : action.payload,
      };
    case GET_RULE_IMAGE_TRIGGER_ERROR:
      return {
        ...state,
      };
    case GET_RULE_IMAGE_COUNT_TRIGGER_SUCCESS:
      return {
        ...state,
        ruleImagesCount : action.payload.detailElements,
      };
    case GET_RULE_IMAGE_COUNT_TRIGGER_ERROR:
      return {
        ...state,
      };
    case POST_IMAGE_UPLOAD_SUCCESS:
      return {
        ...state,
        postImageUploadSuccessId:
        {imageId:action.payload.imageId, 
          imageName: action.payload.imageName,
          convertedImageUploadData: state.convertedImageUploadData,
        },
        convertedImageUploadError: null
      };
    case POST_IMAGE_UPLOAD_ERROR:
      return {
        ...state,
        postImageUploadSuccessId:null
      };
    case CONVERT_IMAGE_UPLOAD_TRIGGER_SUCCESS:
      return {
        ...state,
        convertedImageUploadData: action.payload.response,
        convertedImageUploadError: null
      };
    case CONVERT_IMAGE_UPLOAD_TRIGGER_ERROR:
      return {
        ...state,
        convertedImageUploadData: null,
        convertedImageUploadError : action.error.message
      };
    case IS_SHOWING_COMMIT_IMAGE_MODAL:
      return {
        ...state,
        isShowingCommitImageModal: action.payload,
      };
    case POST_COMMIT_IMAGE_TRIGGER:
      return {
        ...state,
        postImageCommitSuccessId:
          {
            imageId:action.payload.imageId, 
            isTested: false,
            isTestedSuccess: false
          },
      };
    case POST_COMMIT_IMAGE_TRIGGER_CANCEL:
      return {
        ...state,
        postImageCommitSuccessId:
          {
            imageId:action.payload.imageId, 
            isTested: false,
            isCancelledToCommit: true
          },
      };
    case POST_COMMIT_IMAGE_TRIGGER_SUCCESS:
      return {
        ...state,
        postImageCommitSuccessId:
          {
            imageId:action.payload.imageId, 
            isTested: true,
            isTestedSuccess: true
          },
      };
    case RESET_STATE:
      return {
        detailElements: {},
        isReadOnlyRuleDetail: false,
        selectedRuleDetail: {},
        isCloningRuleDetail: false,
        isEditingRuleDetail: false,
        isPostingRuleDetail: false,
        isShowingProductionArrangementModal: false,
        isShowingCustomerServiceMessageModal: false,
        isShowingReturnAddressModal: false,
        isShowingDuplexRuleModal: false,
        isShowingSignatureRuleModal: false,
        isShowingCheckTextRuleModal: false,
        isShowingImageRuleModal: false,
        isShowingAutoHoldModal: false,
        isShowingMemberIdModal: false,
        isShowingTaxIdArrangementRuleModal: false,
        isShowingErisaMessageModal: false,
        isShowingHighDollarReviewModal: false,
        isShowingAddNewAddressModal: false,
        isShowingProviderInsuredOptionMessageModal: false,
        isShowingGeneralMessageModal: false,
        isShowingPDFTaggingModal: false,
        errorNotifications: new Map(),
        isShowingImageLibraryModal: false,
        isShowingImageUploadModal: false,
        convertedImageUploadError: null,
        convertedImageUploadData: null,
        postImageUploadSuccessId: null,
        postImageCommitSuccessId: null
      }
    default:
      return state;
  }
};