import React from 'react';
import PropTypes from 'prop-types';
import { LoadingIndicator } from 'ui-core-ssgr';
import { CenteredLoadingContainer } from './styles';
import { GridLoadingWrapper } from '../../../../shared/styles/styledComponents';
import DefaultHeader from '../DefaultHeader';
import BasicTabDetailsView from '../../../BasicTabDetailsView';
import TestAssocTabDetailsView from '../../../TestAssocTabDetailsView';
import CommitTabDetailsView from '../../../CommitTabDetailsView';
import LiveAssocTabDetailsView from '../../../LiveAssocTabDetailsView';

const DetailsWrapper = (
  {
    isCloningGroup,
    isExpanded,
    toggleDetailsView,
    selectedRecord,
    setIsCloningGroupState,
    style,
    defaultGroupsDetailsTabIndex,
    updateCurrentTab,
    setIsShowingActivateGroupModal,
    setIsShowingDeactivateGroupModal,
    setDefaultGroupDetailsTab,
    deleteNotification,
    setIsShowingCloneGroupModal,
    setSelectedDetailsRecordId,
    setIsShowingTestFileManager,
    isShowingTestFileManager,
    loading,
    resetRuleTypesListReceived,
    triggerRuleTypesRequest,
    captureCurrentPage,
    isEditRole
  }
) => {
  let defaultView = null;

  if (!selectedRecord && loading.has('ruleTypesGridLoading')) {
    defaultView = (
      <CenteredLoadingContainer>
        <GridLoadingWrapper>
          <LoadingIndicator
            domID="group-detail-loading-indicator"
            length="45px"
          />
        </GridLoadingWrapper>
      </CenteredLoadingContainer>
    );
  } else if (selectedRecord) {
    defaultView = (
      <div style={{ ...style }}>
        <DefaultHeader
          isCloningGroup={isCloningGroup}
          selectedRecord={selectedRecord}
          toggleDetailsView={toggleDetailsView}
          currentTab={defaultGroupsDetailsTabIndex}
          updateCurrentTab={updateCurrentTab}
          setIsCloningGroupState={setIsCloningGroupState}
          setIsShowingActivateGroupModal={setIsShowingActivateGroupModal}
          setIsShowingDeactivateGroupModal={setIsShowingDeactivateGroupModal}
          deleteNotification={deleteNotification}
          setIsShowingCloneGroupModal={setIsShowingCloneGroupModal}
          setSelectedDetailsRecordId={setSelectedDetailsRecordId}
          setIsShowingTestFileManager={setIsShowingTestFileManager}
          isShowingTestFileManager={isShowingTestFileManager}
          resetRuleTypesListReceived={resetRuleTypesListReceived}
          loading={loading}
          triggerRuleTypesRequest={triggerRuleTypesRequest}
          captureCurrentPage={captureCurrentPage}
          isEditRole={isEditRole}
        />
        <div>
          {
            [
              <BasicTabDetailsView
                loading={loading}
                isExpanded={isExpanded}
                selectedRecord={selectedRecord}
                toggleDetailsView={toggleDetailsView}
              />,
              <TestAssocTabDetailsView loading={loading} selectedRecord={selectedRecord} isExpanded={isExpanded} />,
              <CommitTabDetailsView
                loading={loading}
                selectedRecord={selectedRecord}
                setDefaultGroupDetailsTab={setDefaultGroupDetailsTab}
                isEditRole={isEditRole}
              />,
              <LiveAssocTabDetailsView loading={loading} selectedRecord={selectedRecord} />,
            ][defaultGroupsDetailsTabIndex]
          }
        </div>
      </div>
    );
  }

  return defaultView;
};

DetailsWrapper.defaultProps = {
  selectedRecord: null,
  defaultGroupsDetailsTabIndex: 0,
};

DetailsWrapper.propTypes = {
  setIsShowingActivateGroupModal: PropTypes.func,
  setIsShowingDeactivateGroupModal: PropTypes.func,
  toggleDetailsView: PropTypes.func.isRequired,
  updateCurrentTab: PropTypes.func.isRequired,
  defaultGroupsDetailsTabIndex: PropTypes.number,
  setIsCloningGroupState: PropTypes.func,
  isCloningGroup: PropTypes.bool,
  isExpanded: PropTypes.bool,
  setDefaultGroupDetailsTab: PropTypes.func,
  deleteNotification: PropTypes.func,
  setIsShowingCloneGroupModal: PropTypes.func,
  setSelectedDetailsRecordId: PropTypes.func,
  setIsShowingTestFileManager: PropTypes.func,
  isShowingTestFileManager: PropTypes.bool,
  loading: PropTypes.object,
  resetRuleTypesListReceived: PropTypes.func,
  selectedRecord: PropTypes.shape({
    id: PropTypes.string,
  }),
  style: PropTypes.object,
};

export default DetailsWrapper;
