import styled from 'styled-components';

export const Title = styled.div`
  margin: 1em 0;
  & > span.infoHeader {
    line-height: 1em;
    font-size: 14px;
  }
`;

export const DropDownOne = styled.div`
  height: 280px;
  margin-right: 35px;
  margin-bottom: 15px;
  width: 240px;
`;
export const DropSmallMargin = styled.div`
  margin-bottom: 30px;
`;
export const DropLargeMargin = styled.div`
  margin-bottom: 50px;
`;
export const DropDownTwo = styled.div`
  height: 280px;
  width: 200px;
  margin-right: 35px;
`;
export const DropDownThree = styled.div`
  height: 280px;
`;
export const ContainerBox = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  & button:last-child {
    position: absolute;
    margin-top: 60px;
`;

export const LoadingWrapper = styled.div`
  float: left;
  margin-left: -22px;
`;
export const DocumentMessage = styled.span`
  font-size: 11px;
  line-height: 1.5;
  display: inline-block;
`;
