import { useAxios } from '../hooks'

const useProdForm = (param) => {
  const { data, ...rest } = useAxios({
    url: `/forms/prod?param=${param}`,
    showErrNotification: true
  })

  return { 
    data,
    ...rest
  }
}

export default useProdForm