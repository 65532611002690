
export const DRAFT_CODE_GRID={
  columns: new Set([
    {
      dataName: 'code',
      text: 'Draft Code',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'message',
      text: 'Message',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'logo',
      text: 'Logo',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    }
  ]),
  selectedItemIds: new Set([]),
  bookmarkedItemIds: new Set([]),
  domID: 'clientRuleDraftCodeGrid',
  dataTestId: 'clientRuleDraftCodeGrid',
  isConfigurable: true,
  supportSelection: false
};

export const FORM_TRANSLATION_GRID={
  columns: new Set([
    {
      dataName: 'FormTranslationId',
      text: 'Form Translation ID',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'ClientForm',
      text: 'Client Form Code',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'AbfForm',
      text: 'Form Name',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'desc',
      text: 'Form Description',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    }
  ]),
  selectedItemIds: new Set([]),
  bookmarkedItemIds: new Set([]),
  domID: 'clientRuleFormTranslationGrid',
  dataTestId: 'clientRuleFormTranslationGrids',
  isConfigurable: true,
  supportSelection: false
};

export const STATE_COPY_CODE_GRID={
  columns: new Set([
    {
      dataName: 'state',
      text: 'State',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'routing_instruction',
      text: 'Routing Instruction',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    }
  ]),
  selectedItemIds: new Set([]),
  bookmarkedItemIds: new Set([]),
  domID: 'state1099copyGrid',
  dataTestId: 'state1099copyGrid',
  isConfigurable: true,
  supportSelection: false
};

export const SELECT_STATUS_RECORD = [
  { label: 'Active', value: "true" },
  { label: 'Inactive', value: "false" }
];

export const CHECK_ACCOUNT_INFO_GRID={
  columns: new Set([
    {
      dataName: 'check-account-id',
      text: 'ID',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'check-logo',
      text: 'Check Logo',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'check-bank-logo',
      text: 'Bank Logo',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'number-add-on',
      text: 'Number Add On',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'micr-account-number-start-pos',
      text: 'MICR Start Position',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'signature-1',
      text: 'Signature',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'signature-2',
      text: 'Second Signature',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'signature-2-threshold',
      text: 'Second Signature Threshold',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'void-message',
      text: 'VOID Message',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'eob-message',
      text: 'EOB Message',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    }
  ]),
  selectedItemIds: new Set([]),
  bookmarkedItemIds: new Set([]),
  domID: 'clientRuleCheckAccountInfoGrid',
  dataTestId: 'clientRuleCheckAccountInfoGrid',
  isConfigurable: true,
  supportSelection: false
};

export const INELIGIBLE_CODE_INFO_GRID={
  columns: new Set([
    {
      dataName: 'inelgible-code',
      text: 'Ineligible Code',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
  ]),
  selectedItemIds: new Set([]),
  bookmarkedItemIds: new Set([]),
  domID: 'ineligibleCodeInfoGrid',
  dataTestId: 'ineligibleCodeInfoGrid',
  isConfigurable: true,
  supportSelection: false
};

export const IMAGE_TRANSLATION_GRID={
  columns: new Set([
    {
      dataName: 'imageTran',
      text: 'Image Translation ID',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'ClientImage',
      text: 'Client Image Code',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'AbfImage',
      text: 'Image Name',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'desc',
      text: 'Image Description',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    }
  ]),
  selectedItemIds: new Set([]),
  bookmarkedItemIds: new Set([]),
  domID: 'clientRuleImageTranslationGrid',
  dataTestId: 'clientRuleImageTranslationGrid',
  isConfigurable: true,
  supportSelection: false
};