/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react';
import styled from 'styled-components/dist/styled-components'
import { useLayout } from 'ui-core/dist/useLayout';
import DetailPaneHeader from 'ui-core/dist/DetailPaneHeader';
import { useSelector } from 'react-redux';
import {
  Modal, Button, ModalHeader, Input, Grid
} from 'ui-core/dist';
import { AnchorInput } from '../../components';
import { GridPaginationWrapper } from '../../components'
import useNotificationBannerHook from '../../useNotificationBannerHook';
import { ACH_TRANS_DETAIL_HIST } from './Modules/constants';
import { detailPaneAnimStates } from '../../commonResources/constants/animStates';
import ErrorPage from '../ErrorPage';
import LoadingComponent from '../../components/LoadingComponent';
import {
  useFileBatchDetailsByFileID
} from "../../api/useAchManager";
//import {CANNOT_DEACTIVATE_ADDRESS_ID, SELECT_RECORD_OPTIONS} from './Modules/constants';
import {
  DOCUMENT_SEARCH_RESULTS_PAGE_ACH_BATCH_DETAILS, DOCUMENT_SEARCH_RESULTS_PAGE_ACH_FILE_DETAILS,
  DOCUMENT_SEARCH_RESULTS_PAGE_ACH_TRANSACTION_DETAILS
} from '../DocumentManager/Modules/constants';
import useSortableGrid from '../../hooks/useSortableGrid';
import ACHTransmissionHistoryFileDetails from '../DocumentManager/ACHTransmissionHistoryFileDetails';
import './index.scss';

const AchTransHistoryDetail = () => {
  const {
    expandCollapseDetailPaneButtonConfig,
    closeDetailPaneButtonConfig,
    detailAnimationState,
    closeDetailPane
  } = useLayout()
  const fetchFileDetailsFunc = useRef(null);
  const { notify } = useNotificationBannerHook();
  const DEFAULT_ACH_ERR_MESSAGE = "Some error occurred while fetching ACH File Details";
  const DEFAULT_ROWS_PER_PAGE = 10;
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE)
  const [pageIndex, setPageIndex] = useState(0);

  const [count, setcount] = useState(0)

  const { selectedAchRecord }
    = useSelector(({ documentSearch }) => documentSearch);

  const { data: achFdData, totalRecordsResp, error: errorachFdData, isError: isErrorachFdData, isLoading: isLoadingachFdData,
    refetchACHFileDetailsByFileID, getACHFileDetailsByFileID } = useFileBatchDetailsByFileID()


  useEffect(() => {
    if (selectedAchRecord && selectedAchRecord.id) {
      getACHFileDetailsByFileID({
        payload: {
          "maxRecords": DEFAULT_ROWS_PER_PAGE,
          "rowPosition": 0,
          //"sortField":sortConfig.sortDataName,
          //"sortDescending":sortConfig.sortDescending,
          parameters: selectedAchRecord.id,
          // batchId: config.batchId
        }
      })
    }
  }, [selectedAchRecord])
  // const [sortConfig, getArgumentsAndSortGrid]=useSortableGrid(fetchData,"id","true");  

  const fetchData = useCallback((args) => {
    let sortcolumn; let sd; let pi; let type;
    if (args) {
      ({ sortcolumn, sd, pi, type } = args);
    }

    getACHFileDetailsByFileID({
      payload: {
        "maxRecords": rowsPerPage,
        "rowPosition": pi === undefined || pi === null ? pageIndex : pi,
        "sortField": sortcolumn === undefined || sortcolumn === null ? sortConfig.sortDataName : sortcolumn,
        "sortDescending": sd === undefined || sd === null ? sortConfig.sortDescending : sd,
        type,
        parameters: selectedAchRecord.id
      }
    })
  }, [rowsPerPage, pageIndex, sortConfig, selectedAchRecord]);
  const [sortConfig, getArgumentsAndSortGrid] = useSortableGrid(fetchData, "documentKey", "true");

  const achDetailsData = useMemo(() => {
    const data = JSON.parse(JSON.stringify(achFdData))
    if (data) {
      const { batchData, totalRecords } = data;
      const { file } = batchData;
      const { batch } = file;

      let temp = []
      if (batch) {
        if (batch.constructor.name === 'Array') {
          temp = [...batch]
        } else if (batch.constructor.name === "Object") {
          temp.push({ ...batch })
        }
      }
      const fdata = temp.map((b, index) => ({
        ...b,
        companyname: <AnchorInput
          id={`ACH_batch${index}_${b.companyname}`}
          type='button'
          className='fileKeyLink'
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            const config = {
              // documentKey,
              fileId: file.id.constructor.name === 'Array' ? file.id[0] : file.id,
              batchId: b.id
            };
            if (fetchFileDetailsFunc.current) fetchFileDetailsFunc.current(config)
          }}
        >{b.companyname}</AnchorInput>
      }))
      return (
        <>
          <table className="labelWrapper">
            <tr>
              <td>
                <div className="displayFlex">
                  <label>File ID: </label>
                  <label style={{ marginLeft: '10px' }}>{file.id}</label>
                </div>
              </td>
              <td>
                <div className="displayFlex">
                  <label>Transmission Status: </label>
                  <label style={{ marginLeft: '10px' }}>{file.status}</label>
                </div>
              </td>
            </tr>
          </table>
          <br />
          <div className="displayFlex">
            <label>File Information</label>
          </div>
          <Grid
            {...DOCUMENT_SEARCH_RESULTS_PAGE_ACH_FILE_DETAILS}
            records={file ? [file] || [] : []}
            onClickThrough={() => false}
          />
          <br />
          {!isLoadingachFdData ?
            <>
              <GridPaginationWrapper
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={setRowsPerPage}
                pageIndex={pageIndex}
                onChangePageIndex={setPageIndex}
                onShowButtonCLick={fetchData}
                totalRecords={totalRecordsResp}
                defaultRowsPerPage={DEFAULT_ROWS_PER_PAGE}
              />
              <div className="displayFlex">
                <label>Batch Information</label>
              </div>

              <Grid
                {...DOCUMENT_SEARCH_RESULTS_PAGE_ACH_BATCH_DETAILS}
                records={fdata || []}
                onClickThrough={() => false}
              />
            </>
            : null}
        </>
      )
    }
  }, [achFdData, rowsPerPage, pageIndex, isLoadingachFdData, totalRecordsResp])
  return (
    <>
      <DetailPaneHeader
        title="ACH File Transmission History - Batch Selection"
        layoutControls={[
          expandCollapseDetailPaneButtonConfig,
          closeDetailPaneButtonConfig
        ]}
      />


      {isLoadingachFdData ? (
        // replace with a better loading component            
        <div style={{ marginLeft: '50%' }}><LoadingComponent /></div>
      )
        :
        detailAnimationState > detailPaneAnimStates.COLLAPSED ?
          <>
            <div style={{ marginLeft: '40px' }} className="marginLeft">
              {achDetailsData}
            </div>
            <ACHTransmissionHistoryFileDetails
              mode="fileDetails"
              getFetchDetailsFunc={(funcRef) => {
                if (funcRef) fetchFileDetailsFunc.current = funcRef
              }}

            />
          </>
          : null}
    </>
  )
};

export default AchTransHistoryDetail