import React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import { Page } from '../components';
import ActiveUserList from '../containers/ActiveUserList';
import Authorizer from '../containers/Authorizer';
import DataSourceManagerListPane from '../containers/DataSourceManager';
import RoleManager from '../containers/RoleManager';
import UserManager from '../containers/UserManager'

const SecurityRoutes = () => (
  <Switch>
    <Route path="/SecurityManager/ActiveUsers" component={ActiveUserList} />
    <Route path="/SecurityManager/DataSourceManager" component={DataSourceManagerListPane} />
    <Route path="/SecurityManager/RoleManager" component={RoleManager} />
    <Authorizer permission="CAN_ACCESS_SECURITY_USER">
      <Route path="/SecurityManager/UserManager" component={UserManager} />
    </Authorizer>
    <Route exact path="/SecurityManager">
      <Redirect to="/SecurityManager/ActiveUsers" />
    </Route>
  </Switch>
)
export default SecurityRoutes;