import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { NotificationBanner } from 'ui-core-ssgr';
import AdvancedForm from './AdvancedForm';
import AdvancedGridWrapper from './AdvancedGridWrapper/index';
import { selectRenderSafeRecords } from './AdvancedGridWrapper/selectors';

const EditTestTab = props => {
  const {
    isCloningRule,
    isPostingRulesForRuleTypes,
    notifications,
    errorNotifications,
    selectedRuleRecord,
    selectedRuleRecordStatus,
    setSelectedRuleDetail,
    triggerRulesForRuleTypesPutRequest,
    triggerRulesForRuleTypesPostRequest,
    setRuleTypeCommitModalOpen,
    ruleTypeCommitRuleModalOpen,
    triggerCommitRuleForRuleType,
    selectedRuleTypeId,
    records,
    selectedRecord,
    loading,
    deleteNotification,
    addNotification,
    setIsCloningRuleState,
    setIsShowingDetailCloneRuleModal,
    showActiveGroupsListingModal,
    isExpanded,
    activeGroupsModalLocation,
    selectedActiveRecordsList,
    selectedRecordName,
    setIsShowingActiveGroupsListingModal,
    testImageCount,
    setIsReadOnlyRuleDetail,
    gridConfig,
    captureCurrentPage,
    serverConfig
  } = props;
  
  const [editTestTabInput, setEditTestTabInput] = useState({
    name: '',
    desc: ''
  });
  const [error, setError] = useState(new Map());
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const resetComponentState = () => {
    const newError = new Map();

    setEditTestTabInput({
      name: isPostingRulesForRuleTypes || isCloningRule
        ? ''
        : selectedRuleRecord.name,
      desc: isPostingRulesForRuleTypes || isCloningRule
        ? ''
        : selectedRuleRecord.description
    });
    setError(isPostingRulesForRuleTypes || isCloningRule
      ? newError.set('name', 'Rule name is required.')
      : newError);
    setIsButtonDisabled(true);
  };

  // useEffect for the componentDidMount
  useEffect(() => {
    const newError = new Map();
    deleteNotification();
    setEditTestTabInput({
      name: isPostingRulesForRuleTypes || isCloningRule
        ? ''
        : selectedRuleRecord.name,
      desc: isPostingRulesForRuleTypes || isCloningRule
        ? ''
        : selectedRuleRecord.description
    });
    setError(isPostingRulesForRuleTypes || isCloningRule
      ? newError.set('name', 'Rule name is required.')
      : newError);
    // following action is called to capture new relic data
    captureCurrentPage({pageId: `${gridConfig.pageTrackerPrefix}${selectedRuleTypeId}`});
  }, []);

  // useEffect for the componentDidUpdate
  useEffect(() => {
    resetComponentState();
    if (notifications.has(selectedRuleRecord.id))
      deleteNotification(selectedRuleRecord.id);
  }, [selectedRuleRecord.id, isExpanded, isPostingRulesForRuleTypes, isCloningRule]);

  // Similar to the componentWillUnmount function
  useEffect(() => () => {
    if (notifications.has(selectedRuleRecord.id))
      deleteNotification(selectedRuleRecord.id);
  }, [selectedRuleRecord.id]);

  // want test unless cloning (Edit Test Tab)
  const getRuleVersion = record => {
    try {
      const { status, version } = record;
      const testVersion = version[status.indexOf('T')];
      const liveVersion = version[status.indexOf('L')];
      if (!isCloningRule && testVersion) {
        return testVersion;
      }
      if (isCloningRule && liveVersion) {
        return liveVersion;
      }
      if (!isCloningRule && liveVersion) {
        return liveVersion;
      }
      if (isCloningRule && testVersion) {
        return testVersion;
      }
    } catch (e) {}
    // if no test/live version or any error return null
    return null;
  };
  
  const onSave = () => {
    const id = get(selectedRuleRecord, 'id', '');
    const ruleVersionFrom = getRuleVersion(selectedRuleRecord);
    const { name, desc } = editTestTabInput;

    if (isPostingRulesForRuleTypes) {
      triggerRulesForRuleTypesPostRequest({
        name,
        desc,
        id: 'postRule',
        ruleType: selectedRuleTypeId,
      });
    } else if (isCloningRule) {
      triggerRulesForRuleTypesPostRequest({
        name,
        desc,
        isCloningRule,
        ruleIdFrom: id,
        ruleVersionFrom,
        id: 'postRule',
        ruleType: selectedRuleTypeId,
      });
    } else {
      triggerRulesForRuleTypesPutRequest({
        id,
        name,
        desc,
        selectedRuleRecord,
        ruleType: selectedRuleTypeId,
      });
    }
    setIsButtonDisabled(true);
  };
  
  const onInputChange = (e, input, selected) => {
    const { value: eventValue } = e.target;
    const { name, desc } = editTestTabInput;
    const value = eventValue.trim();

    validateFormInputs(input, value);

    const buttonDisabled = getIsButtonDisabledState({
      input,
      value,
      selectedRuleRecord: selected,
      name,
      desc,
    });
    setEditTestTabInput({
      ...editTestTabInput,
      [input]: value
    });
    setIsButtonDisabled(buttonDisabled);
  };
  
  const validateFormInputs = (input, value) => {
    const { length } = value;
    const regexp = /^[a-zA-Z0-9-_ ]*$/;
    const regexp2 = /^[a-zA-Z0-9]+$/;

    if (length < 1) error.set(input, 'Must enter a minimum of 3 characters.');
    else error.delete(input);
    if (input === 'name' || input === 'desc') {
      if (input === 'name') {
        if (length > 50) error.set(input, 'Must be 50 characters or less.');
        else error.delete(input);
      }
      if (input === 'desc') {
        if (length > 100) error.set(input, 'Must be 100 characters or less.');
      }
    }
    if (regexp.test(value)) {
      if (!regexp2.test(value.replace(/[_\-\s]/g, '')) && value.length >= 3) {
        error.set(input, 'Must contain at least one alphanumeric character.');
      }
    } else {
      error.set(
        input,
        'Must contain only alphanumerics, underscores, dashes and spaces.',
      );
    }
    if (length < 3) error.set(input, 'Must enter a minimum of 3 characters.');
    if (input === 'name' && length === 0)
      error.set(input, 'Rule name is required.');
    if (input === 'desc' && length === 0) error.delete(input);
    setError(error);
  };
  
  const getIsButtonDisabledState = ({
    input,
    value: settingValue,
    selectedRuleRecord: selected,
    name: settingName,
    desc: settingDesc
  }) => {
    const value = settingValue.toUpperCase();
    let settingIsButtonDisabled = true;

    if ((input === 'name' && !isPostingRulesForRuleTypes) || !isCloningRule)
      settingIsButtonDisabled =
          value === selected.name.toUpperCase() &&
          selected.description.toUpperCase() === settingDesc;
    if ((input === 'desc' && !isPostingRulesForRuleTypes) || !isCloningRule)
      settingIsButtonDisabled =
          value === selected.description.toUpperCase() &&
          selected.name.toUpperCase() === settingName;
    if (isPostingRulesForRuleTypes && value.length >= 3)
      settingIsButtonDisabled = false;
    if (isPostingRulesForRuleTypes && input === 'name' && value.length >= 3)
      settingIsButtonDisabled = false;
    if (input === 'desc' && value.length === 0) settingIsButtonDisabled = false;
  
    if (error.size > 0) settingIsButtonDisabled = true;
  
    let nameCur = settingName;
    let descCur = value;

    if (input === 'name') {
      descCur = settingDesc;
      nameCur = value;
    }
    if (
      selected.name.toLowerCase() === nameCur.toLowerCase() &&
        descCur.toLowerCase() === selected.description.toLowerCase()
    )
      settingIsButtonDisabled = true;
  
    return settingIsButtonDisabled;
  };

  const { name, desc } = editTestTabInput;

  return (
    <>
      {notifications.has('fetchGroupError') && (
        <NotificationBanner
          domID="api-error-notification"
          type={notifications.get('fetchGroupError').type}
          text={notifications.get('fetchGroupError').msg}
          onDismiss={() => deleteNotification('fetchGroupError')}
          autoDismiss
          timer={1800000}
        />
      )}
      <AdvancedForm
        notifications={notifications}
        selectedRuleRecord={selectedRuleRecord}
        selectedRuleRecordStatus={selectedRuleRecordStatus}
        onInputChange={onInputChange}
        setRuleTypeCommitModalOpen={setRuleTypeCommitModalOpen}
        ruleTypeCommitRuleModalOpen={ruleTypeCommitRuleModalOpen}
        triggerCommitRuleForRuleType={triggerCommitRuleForRuleType}
        selectedRuleTypeId={selectedRuleTypeId}
        showActiveGroupsListingModal={showActiveGroupsListingModal}
        error={error}
        records={records}
        testImageCount={testImageCount}
        selectedRecord={selectedRecord}
        desc={desc}
        name={name}
        isCloningRule={isCloningRule}
        setIsShowingActiveGroupsListingModal={setIsShowingActiveGroupsListingModal}
        activeGroupsModalLocation={activeGroupsModalLocation}
        selectedActiveRecordsList={selectedActiveRecordsList}
        selectedRecordName={selectedRecordName}
        userRole={serverConfig.userRole}
        userPermissions={serverConfig.userPermissions}
      />
      <AdvancedGridWrapper
        desc={desc}
        name={name}
        isPostingRulesForRuleTypes={isPostingRulesForRuleTypes}
        isCloningRule={isCloningRule}
        selectedRuleRecord={selectedRuleRecord}
        selectedRuleRecordStatus={selectedRuleRecordStatus}
        triggerRulesForRuleTypesPutRequest={
          triggerRulesForRuleTypesPutRequest
        }
        triggerRulesForRuleTypesPostRequest={
          triggerRulesForRuleTypesPostRequest
        }
        setSelectedRuleDetail={setSelectedRuleDetail}
        notifications={notifications}
        loading={loading}
        isButtonDisabled={isButtonDisabled}
        onSave={onSave}
        deleteNotification={deleteNotification}
        addNotification={addNotification}
        errorNotifications={errorNotifications}
        setIsCloningRuleState={setIsCloningRuleState}
        setIsShowingDetailCloneRuleModal={setIsShowingDetailCloneRuleModal}
        setIsReadOnlyRuleDetail={setIsReadOnlyRuleDetail}
      />
    </>
  );
};

EditTestTab.propTypes = {
  selectedRuleRecord: PropTypes.shape({
    id: PropTypes.string,
  }),
  selectedRuleRecordStatus: PropTypes.array,
  setSelectedRuleDetail: PropTypes.func.isRequired,
  isCloningRule: PropTypes.bool,
  isPostingRulesForRuleTypes: PropTypes.bool,
  notifications: PropTypes.object,
  errorNotifications: PropTypes.object,
  triggerRulesForRuleTypesPutRequest: PropTypes.func,
  triggerRulesForRuleTypesPostRequest: PropTypes.func,
  setRuleTypeCommitModalOpen: PropTypes.func,
  ruleTypeCommitRuleModalOpen: PropTypes.bool,
  triggerCommitRuleForRuleType: PropTypes.func,
  selectedRuleTypeId: PropTypes.string,
  records: PropTypes.array,
  selectedRecord: PropTypes.object,
  loading: PropTypes.object,
  deleteNotification: PropTypes.func,
  addNotification: PropTypes.func,
  setIsCloningRuleState: PropTypes.func,
  setIsShowingDetailCloneRuleModal: PropTypes.func,
  showActiveGroupsListingModal: PropTypes.bool,
  isExpanded: PropTypes.bool,
  activeGroupsModalLocation: PropTypes.string,
  selectedActiveRecordsList: PropTypes.object,
  selectedRecordName: PropTypes.string,
  setIsShowingActiveGroupsListingModal: PropTypes.func,
  captureCurrentPage: PropTypes.func,
};

const mapStateToProps = state => ({
  records: selectRenderSafeRecords(state),
  testImageCount: state.ruleTypesList.testImageCount, 
});

export default connect(mapStateToProps)(EditTestTab);
