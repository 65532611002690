// Import the action types we are going to respond to with this reducer
import {
  RESET_DETAIL_PANE_TO_INITIAL_STATE,
  SET_DEFAULT_GROUP_DETAILS_TAB,
  SET_ADVANCED_GROUP_DETAILS_TAB,
  SET_DETAIL_PANE_TO_INITIAL_STATE_FOR_CLONE
} from './constants';

import { SET_IS_VIEWING_RULE_DETAILS } from '../../api/ruleTypesList/constants';

export const INITIAL_STATE = {
  defaultGroupsDetailsTabIndex: 0,
  advancedGroupsDetailsTabIndex: 0,
  isAdvancedViewShown: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET_DETAIL_PANE_TO_INITIAL_STATE:
      return {
        ...state,
        defaultGroupsDetailsTabIndex: 0,
        advancedGroupsDetailsTabIndex: 0,
        isAdvancedViewShown: false,
      };
    case SET_IS_VIEWING_RULE_DETAILS:
      return {
        ...state,
        isAdvancedViewShown: action.payload.isAdvancedViewShown,
      };
    case SET_DEFAULT_GROUP_DETAILS_TAB:
      return {
        ...state,
        defaultGroupsDetailsTabIndex: action.payload,
      };
    case SET_ADVANCED_GROUP_DETAILS_TAB:
      return {
        ...state,
        advancedGroupsDetailsTabIndex: action.payload,
      };
    case SET_DETAIL_PANE_TO_INITIAL_STATE_FOR_CLONE:
      return {
        ...state,
        advancedGroupsDetailsTabIndex: 0, // makes first tab selected
        isAdvancedViewShown: true, // makes advanced view open
      };
    default:
      return state;
  }
};
