import { createGlobalStyle } from 'styled-components';
import { generateFontFace } from 'ui-core-ssgr';

export const GlobalStyle = createGlobalStyle`
  ${generateFontFace()}
  html,
  body,
  #root {
    height: 100%;
    width: 100%;
  }

  *, * > * {
    box-sizing: border-box;
    font-family: coresansc, 'coresansc', 'Core Sans C', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  // fonts - were not taking in QA environment, these are classes generated by the UICL for buttons, grid headers, etc. //
  body, .ewyROV, .gbvsIg, .gbvsIg, .gbvsIg, .leykei, .gEbEKi, .hcSWfc, .XdkTz, .byhYrf, .kFWYkr, .fRKqmC, .hsDfOU {
    font-family: coresansc, 'coresansc', 'Core Sans C', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  #App {
    display: block;
    position: relative;
    bottom: 0;
    background-color: #fafafa;
    height: 100%;
    width: 100%;
    overflow-x: auto;
  }

  p,
  label {
    line-height: 1.5em;
  }

  // tooltip overflow //
  .caROCT,
  .sc-fepxGN.eHODcU,
  .live-rule-rule-name,
  .live-rule-rule-description {
    word-break: break-all;
    word-wrap: break-word;
  }

// start MCDS-16974 //
// tooltip display line-breaks //
div.tooltip {
  white-space: pre-line !important;
}
td.message > div, td.checkLeftText > div {
  text-overflow: ellipsis;
}
div.tooltip-wrapper > div {
  max-height: 34px;
  vertical-align: middle;
}
// end MCDS-16974 //

// checkbox defect 14570 //
  label.checkbox-input-label {
    font-size: 0;
    line-height: 0;
    padding: 0;
    margin: 0;
}


  #App table tbody tr > td,
  .eCxQX table tbody tr > td {
    vertical-align: middle;
  }
  
  .dfljZI button.modal-close {
    top: -20px;
    right: -10px;
  }

  #test-file-manager-modal-wrap div.active-content{width:1200px;height:630px;}

  // chrome checkbox issue //
  input[type=checkbox]:active:before,
  input[type=checkbox]:before {
    content: normal;
  }
 
  input[type=checkbox]:active:checked:after,
  input[type=checkbox]:checked:after {
    background: transparent;
    border: none;
  }
  input[type=checkbox], input[type=checkbox]:active {
    cursor: auto;
  }

  /* styles for filezone*/
  .DargStyles {
    background-color: #e5f8ff;
    border-radius: 5px;
    width: 100%;
    height: 88%;
    border: 2px #4cc6ff;
    border-style: dashed;
    font-family: Core Sans;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
  }
  .DargStyles div{
    height: 55%;
    width: 100%;
  }
  .messageDrag{
    display: inline;
    font-family: core-sans, sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: #37474F;
  }
  .box__dragndrop{
    display: none;
  }
  .uploadResult{
    width: 100%;
    margin-right: 10%;
    margin-left: 10%;
    align-content: center;
    -ms-flex-align: center;
    align-items: center;
    display: contents;
  }
  .uploadFilezone{
    width: 70%;
    height: 30%;
    margin:auto;
  }
  
  #dragid-filezone{
    padding: 20px;
    background-color: #e5f8ff;
    border-radius: 5px;
    width: 100%;
    border: 2px #4cc6ff;
    border-style: dashed;
    text-align: center;
    margin-top: 25px;
  }

  .fileuploadMessage{
    font-size: 4mm;
    text-align: center;
  }

  .fileuploadMessageError{
    font-size: 4mm;
    text-align: center;
    color: #ff0000;
  }

  .fileuploadMessageInfo{
    font-size: 3mm;
    text-align: center;
  }
  
  .fileuploadMessageSmall{
    font-size: 3mm;
    text-align: center;
  }

  .image-library-list{
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow-y: scroll;
    max-height:400px
  }

  .image-library-list div{ 
    display: inline-block;
    margin:5px;
    // position:relative;
  }

  .image-library-list div img{
    // border: 2px solid #ddd;
    padding: 20px;
    // border-radius: 10px;
  }

  .image-library-list > div > span {
    max-width: 120px;
  }

  .image-library-list span div span{
  //   display: block;
    color: #000000;
    text-decoration: none;
    text-align: center;
  //   padding-bottom: 15px;
  }

  .notselected .image-check-tick{
    display:none;
  }
  
  .upload-library-cntrl .notselected{
    background:none !important;
  }

  .upload-library-cntrl > button {padding-left: 6px;}
  .image-library-list .selected .image-check-tick{
    position:absolute;
    display:block;
  }

  .button-wrap-image-library{
    margin-top: 50px!important;
    margin-bottom: 20px!important;
    text-align: center;
  }

  #header-title-image-library, #header-title-image-upload{
    text-align: center;
    padding-left: 32%;
    margin-bottom: 15px;
  }

  #header-title-image-library > div, #header-title-image-upload > div{
    text-align: center;
    width: 100%;
    min-width: 600px;
  }
  
  #header-title-image-library > div > span, #header-title-image-upload > div > span{
    text-align: center;
  }

  #remove-btn{padding-left: 0px;}
  .choose-caption{margin-bottom: 15px;padding-left:5%;}
  #image-library-modal > div {width:1200px}
  #image-upload-modal > div {width:970px}
  .image-library-none{font-size: 14px;padding: 0px 0px 0px 8px;}
  .remove-underline .entity-title{font-size: 14px;color:black;}
  .upld-cntrl{border: 1px solid #ccc;padding: 3px;}
  .first-rule-required .entity-title, .first-rule-required .image-library-none, .first-rule-required .s1-required-err {
    color: #c62f2f;
    font-size: 14px;
  }
  .first-rule-required .s1-required-err {
    padding: 0px 0px 0px 8px;
  }
  .first-rule-required .r1-required-err {
    font-size:11px;
    color: #c62f2f;
  }
  #SIG-FILEUPLOAD-notification, #IMG-FILEUPLOAD-notification, #RAD-FILEUPLOAD-notification, #CHT-FILEUPLOAD-notification {
    z-index: 902;
  }
  #RAD-FILEUPLOAD-notification, #RAD-top-notification{
    z-index: 901;
  }
  #COMMIT-CART-notification{
    z-index: 901;
  }
  #active-group-count{display:inline-block;}
}

.ReturnImgWrap #cmt-btn-RAD{padding: 0;}
#header-title-revert-description{
  word-break:break-word;
  white-space: inherit;
  overflow: auto;
  word-break: break-all;
}

@media only screen and (max-width: 1350px) {
  #basic-tab > label, #test-assoc-tab > label, #commit-tab > label, #live-assoc-tab > label{
    font-size: 10px;
  }
  #basic-tab, #test-assoc-tab, #commit-tab, #live-assoc-tab{
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
  }
}
@media only screen and (max-width: 1065px) {
  #basic-tab, #test-assoc-tab, #commit-tab, #live-assoc-tab{
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 10px;
  }
}
`;

export default GlobalStyle;
