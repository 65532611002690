/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import styled from 'styled-components/dist/styled-components'
import { useLayout } from 'ui-core/dist/useLayout';
import DetailPaneHeader from 'ui-core/dist/DetailPaneHeader';
import { useSelector, useDispatch } from 'react-redux';
import {
  Modal, Button, ModalHeader, Input, TextArea, ModalBody
} from 'ui-core/dist';
import { detailPaneAnimStates } from '../../commonResources/constants/animStates';
import ErrorPage from '../ErrorPage';
import Authorizer from '../Authorizer';
import LoadingComponent from '../../components/LoadingComponent';
import useNotificationBannerHook from '../../useNotificationBannerHook';
import {
  useGetSelectedDraftCodeData,
  useAddDraftCode,
  useUpdateDraftCode
} from '../../api/useClientRuleManager'
import {setDraftCodeData} from './Modules/action'
const DraftCodeDetails = () => {
  const {
    expandCollapseDetailPaneButtonConfig,
    closeDetailPaneButtonConfig,
    detailAnimationState,
    closeDetailPane
  } = useLayout()
  const dispatch = useDispatch();
  const { notify } = useNotificationBannerHook();
  const [draftCode, setDraftCode] = useState('')
  const [message, setMessage] = useState('')
  const [logo, setLogo] = useState('')
  const [confirmationModalMode, setconfirmationModalMode] = useState('')
  const [isOpenConfirmationModal, setisOpenConfirmationModal] = useState(false)

  const [count, setcount] = useState(0)
  const [editorMode, setEditorMode] = useState('Add')
  const { terroristExclusionSelectedRecord, terroristExclusionEditorMode, refetchterroristExclusionRecord }
    = useSelector(({ clientRule }) => clientRule);

  useEffect(() => {
    if (terroristExclusionSelectedRecord) {
      if (terroristExclusionSelectedRecord.code) {
        getShowMoreData(terroristExclusionSelectedRecord.code);
      } else {
        setEditorMode("Add")
        setDraftCode('')
        onClearCurrentRecordValues()
      }
    }
  }, [terroristExclusionSelectedRecord]);

  const getShowMoreData = (key) => {
    postDataShowMoreInfo({ strDraftCode:key,count})
    setcount(count + 1)
  }

  const { showMoreInfo, postData: postDataShowMoreInfo, isLoading: isLoadingShowMoreInfo,
    isError: isErrorShowMoreInfo, error: errorShowMoreINfo } = useGetSelectedDraftCodeData()

  const { data: infoAddAddressExclusion, postData: addDraftCode, metaData: addTerroristExclusionResponse, isLoading: isLoadingaddTerroristExclusion,
    isError: isErroraddTerroristExclusion, error: erroraddTerroristExclusion } = useAddDraftCode()

  const { data: infoModifyAddressExclusion, postData: modifyDraftCode, metaData: modifyTerroristExclusionResponse, isLoading: isLoadingmodifyTerroristExclusion,
    isError: isErrormodifyTerroristExclusion, error: errormodifyTerroristExclusion } = useUpdateDraftCode()

  const onResetCurrentRecordValues = () => {
    if (showMoreInfo) {
      setDraftCode(showMoreInfo?showMoreInfo['draft-code']:'')
      setMessage(showMoreInfo?.message)
      setLogo(showMoreInfo?.logo)
      setEditorMode(terroristExclusionEditorMode)
    }
  }

  useEffect(() => {
    if (addTerroristExclusionResponse) {
      const { status, statusText } = addTerroristExclusionResponse;
      if (status === 200 || statusText === "OK") {
        if (infoAddAddressExclusion && infoAddAddressExclusion.fs) {
          const { fs } = infoAddAddressExclusion;
          if (fs) {
            notify(fs, 'negative')
            return;
          }
        }
        notify('Draft Code Added successfully', 'positive')
        closeDetailPane()
        if (refetchterroristExclusionRecord) refetchterroristExclusionRecord()
      }
    }
  }, [addTerroristExclusionResponse])

  useEffect(() => {
    if (modifyTerroristExclusionResponse) {
      const { status, statusText } = modifyTerroristExclusionResponse;
      if (status === 200 || statusText === "OK") {
        if (infoModifyAddressExclusion && infoModifyAddressExclusion.fs) {
          const { fs } = infoModifyAddressExclusion;
          if (fs) {
            notify(fs, 'negative')
            return;
          }
        }
        notify('Draft Code Updated successfully', 'positive')
        closeDetailPane()
        if (refetchterroristExclusionRecord) refetchterroristExclusionRecord()
      }
    }
  }, [modifyTerroristExclusionResponse])

  const onSubmitClick = () => {
    if (editorMode === "Add") {
      setconfirmationModalMode("Add")
    } else if (editorMode === "Edit") {
      setconfirmationModalMode("Modify")
    }
    setisOpenConfirmationModal(true)
  }
  const onConfirmationYesClick = () => {
    if (confirmationModalMode === "Add") {
      addDraftCode({
        count,
        strDraftCode:draftCode,
        strMessage: message,
        strLogo: logo
      })
      setcount(count + 1)

    } else if (confirmationModalMode === "Modify") {
      modifyDraftCode({
        count,
        strDraftCode:draftCode,
        strMessage: message,
        strLogo: logo
      })
      setcount(count + 1)
    }
    setconfirmationModalMode('')
    setisOpenConfirmationModal(false)
  }
  useEffect(() => {
    onResetCurrentRecordValues()
  }, [showMoreInfo])

  const onClearCurrentRecordValues = () => {
    if(editorMode==="Add")
      setDraftCode('')
    setMessage('')
    setLogo('')
  }
  const renderDraftCode = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_name"
      placeholder=""
      size="small"
      maxLength={5}
      initialValue={draftCode}
      disabled={editorMode==="Edit"}
      // errorMessage="Incorrect data do not use '&', '|' or '>' characters"
      // hasError={/[\\&>\\|]/.test(name)}
      onChange={(e) => {
        setDraftCode(e.target.value || "")
      }}
    />
  ), [draftCode,editorMode])
  const renderMessage = useMemo(() => (
    <TextArea
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_address"
      placeholder=""
      size="small"
      initialValue={message}
      // errorMessage="Incorrect data do not use '&', '|' or '>' characters"
      // hasError={/[\\&>\\|]/.test(address)}
      onChange={(e) => {
        setMessage(e.target.value || "")
      }}
    />
  ), [message])
  const renderLogo = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_description"
      placeholder=""
      size="small"
      maxLength={16}
      initialValue={logo}
      // errorMessage="Incorrect data do not use '&', '|' or '>' characters"
      // hasError={/[\\&>\\|]/.test(description)}
      onChange={(e) => {
        setLogo(e.target.value || "")
      }}
    />
  ), [logo])
  
  const validateForm = useMemo(() => {
    let errorCount = 1
    if(draftCode){
      errorCount=0
      //   if ((draftCode && draftCode.length > 5)
      //   ){
      //     errorCount=1
      //   }
      
    }
    

    if(!errorCount) {
      return true
    }

  }, [draftCode, logo, message])

  const renderAddressEntryForm = useMemo(() => {
    if (detailAnimationState > detailPaneAnimStates.COLLAPSED) {
      return (
        <div style={{ marginLeft: '40px' }} className="marginLeft">
          <div>
            <p>
              <div className="displayFlex">Complete the information below, and then click Submit to continue.</div>
            </p>
            <p>
              <div className="displayFlex"><span style={{ color: 'red' }}>* indicates required information</span></div>
            </p>
          </div>
          <br />
          <div className="displayFlex">
            <table className="common-table">
              <tr>
                <td>
                  <label> Draft Code : <span style={{ color: 'red' }}>*</span></label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderDraftCode}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <label> Message :</label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderMessage}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <label>Logo : </label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderLogo}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              
              <tr>
                <td colSpan="2">
                  <div className="displayFlex">
                    <div className="marginLeft">
                      <Button
                        domID="btn_reset"
                        buttonType="standard"
                        size="small"
                        name="RESET"
                        disabled={editorMode !== "Edit"}
                        onClick={() => onResetCurrentRecordValues()}
                      />
                    </div>
                    <div className="marginLeft">
                    <Authorizer permission="CAN_PERFORM_CLIENTRULES_DFT_ADD" >
                      <Button
                        domID="btn_copy"
                        buttonType="standard"
                        size="small"
                        name="COPY"
                        disabled={editorMode === "Add"}
                        onClick={() => {
                          setEditorMode("Add")
                          setDraftCode('')
                          setMessage('')
                          //onResetCurrentRecordValues()
                        }}
                      />
                      </Authorizer>
                    </div>
                    <div className="marginLeft">
                    <Authorizer permission="CAN_PERFORM_CLIENTRULES_DFT_ADD" >
                      <Button
                        domID="btn_cancel"
                        buttonType="standard"
                        size="small"
                        name="CLEAR"
                        onClick={() => onClearCurrentRecordValues()}
                      />
                      </Authorizer>
                    </div>
                    <div className="marginLeft">
                      <Authorizer permission='CAN_PERFORM_CLIENTRULES_DFT_MODIFY'>
                      <Button
                        domID="btn_search"
                        buttonType="standard"
                        size="small"
                        name="SUBMIT"
                        disabled={!validateForm}
                        onClick={() => onSubmitClick()}
                      />
                      </Authorizer>
                    </div>
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
            </table>
          </div>
          <Modal isOpen={isOpenConfirmationModal}>
            <ModalHeader
              title={`Do you want to ${confirmationModalMode} ?`}
              onClose={() => setisOpenConfirmationModal(false)}
            />
            <ModalBody>
              {isOpenConfirmationModal ? (
                <div style={{ marginLeft: '34px', marginBottom: '20px' }} className="marginLeft">
                  <div className="displayFlex">
                    <div className="marginLeft">
                      <Button
                        domID="btn_search"
                        buttonType="standard"
                        size="small"
                        name="Yes"
                        onClick={() => onConfirmationYesClick()}
                      />
                    </div>
                    <div className="marginLeft">
                      <Button
                        domID="btn_search"
                        buttonType="standard"
                        size="small"
                        name="No"
                        onClick={() => setisOpenConfirmationModal(false)}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
            </ModalBody>
          </Modal>
        </div>
      )
    }
    return null;
  }, [terroristExclusionSelectedRecord, showMoreInfo, detailAnimationState, editorMode, 
    draftCode, logo, message, validateForm, terroristExclusionEditorMode, confirmationModalMode, isOpenConfirmationModal])

  return (
    <>
      <DetailPaneHeader
        title={editorMode === "Edit" ? "Modify Draft Code" : "Add Draft Code"}
        layoutControls={[
          expandCollapseDetailPaneButtonConfig,
          closeDetailPaneButtonConfig
        ]}
      />
      <>
        {isLoadingShowMoreInfo || isLoadingaddTerroristExclusion
          || isLoadingmodifyTerroristExclusion ? (
            // replace with a better loading component            
            <div style={{ marginLeft: '50%' }}><LoadingComponent /></div>
          ) : isErrorShowMoreInfo || isErroraddTerroristExclusion
            || isErrormodifyTerroristExclusion ? (
              // replace with a better error component
              <ErrorPage
                // this prop isn't real but is an example
                error={errorShowMoreINfo || erroraddTerroristExclusion
                  || errormodifyTerroristExclusion}
              />
            ) : (
              <>
                {renderAddressEntryForm}
              </>
            )
        }
      </>
    </>
  )
}

export default DraftCodeDetails