import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RulesTabLiveForm from './RulesTabLiveForm';
import LiveGridWrapper from './LiveGridWrapper';

class LiveRuleTab extends Component {
  componentDidMount = () => {
    const {
      selectedRuleTypeId,
      captureCurrentPage,
      rulesForRuleTypes
    } = this.props;
    const pageTrackerPrefix = 'RDLA-';
    // following action is called to capture new relic data
    captureCurrentPage({pageId: `${pageTrackerPrefix}${selectedRuleTypeId}`});
    rulesForRuleTypes.ruleVersionRequest = null; // made this null so that it wont affect versions after cloning any rule, as it should be set in case of revert rule only
    rulesForRuleTypes.ruleVersion = null; // // made this null so that it wont affect versions after cloning any rule, as it should be set in case of revert rule only
  };

  render() {
    const {
      isExpanded,
      loading,
      notifications,
      addNotification,
      deleteNotification,
      isPostingRulesForRuleTypes,
      isCloningRule,
      toggleDetailsView,
      selectedRecord,
      selectedRecordStatus,
      triggerRulesForRuleTypesPutRequest,
      currentTab,
      overwriteTestRuleRequestTrigger,
      setIsShowingOverwriteModal,
      isShowingOverwriteModal,
      selectedRuleRecord,
      selectedRuleTypeId,
      records,
      gridConfig,
      setSelectedRuleDetail,
      setIsShowingCustomerServiceMessageModal,
      isShowingCustomerServiceMessageModal,
      setIsShowingCheckTextRuleModal,
      isShowingCheckTextRuleModal,
      isShowingErisaMessageModal,
      setIsShowingErisaMessageModal,
      setIsShowingProductionArrangementModal,
      isShowingProductionArrangementModal,
      setIsShowingSignatureRuleModal,
      isShowingSignatureRuleModal,
      isShowingPDFTaggingModal,
      setIsShowingPDFTaggingModal,
      setIsEditingRuleDetail,
      setIsReadOnlyRuleDetail,
      setIsCloningRuleDetail,
      setIsPostingRuleDetail,
      getRuleDetailElements,
      selectedRuleDetail,
      detailElements,
      isEditingRuleDetail,
      setIsShowingImageRuleModal,
      setIsShowingReturnAddressModal,
      isShowingImageRuleModal,
      isShowingReturnAddressModal,
      isShowingTaxIdArrangementRuleModal,
      setIsShowingTaxIdArrangementRuleModal,
      isShowingHighDollarReviewModal,
      setShowingHighDollarReviewModal,
      ruleDetails,
      getRuleDetails,
      setIsShowingGeneralMessageModal,
      isShowingGeneralMessageModal,
      setIsShowingMemberIdModal,
      isShowingMemberIdModal,
      setIsShowingProviderInsuredOptionMessageModal,
      isShowingProviderInsuredOptionMessageModal,
      setIsShowingAutoHoldModal,
      isShowingAutoHoldModal,
      setIsShowingDuplexRuleModal,
      isShowingDuplexRuleModal
    } = this.props;
    const { status } = selectedRecord;
    const statusDisabled =
      !!(status.length === 1 && status[0] === 'L');

    return (
      <>
        <RulesTabLiveForm
          deleteNotification={deleteNotification}
          isExpanded={isExpanded}
          isPostingRulesForRuleTypes={isPostingRulesForRuleTypes}
          isCloningRule={isCloningRule}
          loading={loading}
          notifications={notifications}
          selectedRuleRecord={selectedRecord}
          selectedRuleRecordStatus={selectedRecordStatus}
          toggleDetailsView={toggleDetailsView}
          triggerRulesForRuleTypesPutRequest={triggerRulesForRuleTypesPutRequest}
          setIsShowingOverwriteModal={setIsShowingOverwriteModal}
          statusDisabled={statusDisabled}
          getRuleDetailElements={getRuleDetailElements}
          selectedRuleTypeId={selectedRuleTypeId}
          ruleDetails={ruleDetails}
        />
        <LiveGridWrapper
          getRuleDetails={getRuleDetails}
          key={selectedRecord.id}
          addNotification={addNotification}
          deleteNotification={deleteNotification}
          notifications={notifications}
          toggleDetailsView={toggleDetailsView}
          currentTab={currentTab}
          loading={loading}
          selectedRecord={selectedRecord}
          isCloningRule={isCloningRule}
          gridConfig={gridConfig}
          selectedRuleTypeId={selectedRuleTypeId}
          overwriteTestRuleRequestTrigger={overwriteTestRuleRequestTrigger}
          setIsShowingOverwriteModal={setIsShowingOverwriteModal}
          isShowingOverwriteModal={isShowingOverwriteModal}
          selectedRuleRecord={selectedRuleRecord}
          records={records}
          isPostingRulesForRuleTypes={isPostingRulesForRuleTypes}
          setSelectedRuleDetail={setSelectedRuleDetail}
          setIsShowingCustomerServiceMessageModal={setIsShowingCustomerServiceMessageModal}
          isShowingCustomerServiceMessageModal={isShowingCustomerServiceMessageModal}
          setIsShowingCheckTextRuleModal={setIsShowingCheckTextRuleModal}
          isShowingCheckTextRuleModal={isShowingCheckTextRuleModal}
          isShowingErisaMessageModal={isShowingErisaMessageModal}
          setIsShowingErisaMessageModal={setIsShowingErisaMessageModal}
          setIsShowingProductionArrangementModal={setIsShowingProductionArrangementModal}
          isShowingProductionArrangementModal={isShowingProductionArrangementModal}
          setIsShowingSignatureRuleModal={setIsShowingSignatureRuleModal}
          isShowingSignatureRuleModal={isShowingSignatureRuleModal}
          isShowingPDFTaggingModal={isShowingPDFTaggingModal}
          setIsShowingPDFTaggingModal={setIsShowingPDFTaggingModal}
          setIsEditingRuleDetail={setIsEditingRuleDetail}
          setIsReadOnlyRuleDetail={setIsReadOnlyRuleDetail}
          setIsCloningRuleDetail={setIsCloningRuleDetail}
          setIsPostingRuleDetail={setIsPostingRuleDetail}
          selectedRuleDetail={selectedRuleDetail}
          detailElements={detailElements}
          isEditingRuleDetail={isEditingRuleDetail}
          setIsShowingImageRuleModal={setIsShowingImageRuleModal}
          setIsShowingReturnAddressModal={setIsShowingReturnAddressModal}
          isShowingImageRuleModal={isShowingImageRuleModal}
          isShowingReturnAddressModal={isShowingReturnAddressModal}
          isShowingTaxIdArrangementRuleModal={isShowingTaxIdArrangementRuleModal}
          setIsShowingTaxIdArrangementRuleModal={setIsShowingTaxIdArrangementRuleModal}
          isShowingHighDollarReviewModal={isShowingHighDollarReviewModal}
          setShowingHighDollarReviewModal={setShowingHighDollarReviewModal}
          setIsShowingGeneralMessageModal={setIsShowingGeneralMessageModal}
          isShowingGeneralMessageModal={isShowingGeneralMessageModal}
          setIsShowingMemberIdModal={setIsShowingMemberIdModal}
          isShowingMemberIdModal={isShowingMemberIdModal}
          setIsShowingProviderInsuredOptionMessageModal={setIsShowingProviderInsuredOptionMessageModal}
          isShowingProviderInsuredOptionMessageModal={isShowingProviderInsuredOptionMessageModal}
          isShowingAutoHoldModal={isShowingAutoHoldModal}
          setIsShowingAutoHoldModal={setIsShowingAutoHoldModal}
          isShowingDuplexRuleModal={isShowingDuplexRuleModal}
          setIsShowingDuplexRuleModal={setIsShowingDuplexRuleModal}
        />
      </>
    );
  }
};

LiveRuleTab.propTypes = {
  loading: PropTypes.object,
  notifications: PropTypes.object,
  deleteNotification: PropTypes.func,
  currentTab: PropTypes.number,
  toggleDetailsView: PropTypes.func.isRequired,
  isCloningRule: PropTypes.bool,
  isExpanded: PropTypes.bool,
  isPostingRulesForRuleTypes: PropTypes.bool,
  selectedRecord: PropTypes.shape({
    id: PropTypes.string,
  }),
  selectedRecordStatus: PropTypes.array,
  triggerRulesForRuleTypesPutRequest: PropTypes.func.isRequired,
  captureCurrentPage: PropTypes.func,
};

export default LiveRuleTab;
