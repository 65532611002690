import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import findIndex from 'lodash/findIndex';
import filter from 'lodash/filter';
import map from 'lodash/map';
import _ from 'lodash';
import {
  Button,
  DetailpageTitle,
  Modal,
  Grid,
  LoadingIndicator,
  GridCell,
  Warning,
} from 'ui-core-ssgr';
import { mapIterable } from '../../../../shared/helpers/iterables';
import constants from '../ClickableRow/constants';
import {
  WordTagWidthSmall,
  GrayedOutWordTagSmall,
  ModalPaddingWrapper,
  IconWrapper,
  ModalTitlePaddingWrapper,
  BoldTextAfterWarningIcon,
} from '../../../../shared/styles/styledComponents';
import ButtonGroup from '../../../../shared/components/ButtonGroup';
import Colors from '../../../../shared/styles/colors';
import {
  IconWrapperNoDetails,
  NoDetailsWrapper,
  ScrollableGridWrapper,
  LoadingWrapper,
  InputLabel,
  WarningWrapper,
  CustomTableData,
  GridLoadingContainer,
  GridLoadingWrapper,
  ImageContentPaddingWrapper
} from './styles';
import { DivWithPaddingTop,  } from '../../../../shared/styles/spacing';
import { selectLoadingState } from '../../selectors';
import { GridCellWrap } from '../../../CommitTabDetailsView/components/CommitDetailsGridWrapper/styles';
import { gridCommitConfig, gridCommitConfigForImageRuleTypes } from './constants';

const MultipleCommitModal = ({
  setIsMultipleRulesCommitingState,
  setIsShowingMultipleCommitRulesModal,
  triggerMultipleCommitPutRequest,
  triggerImagesTestCountRequest,
  multipleTestImageCountRecords,
  records,
  handleCellClick,
  isShowingMultipleCommitRulesModal,
  title,
  loading,
}) => {
  const recordsWithDetails = filter(records, record => record.hasDetails);    
  const recordsWithNoDetails = filter(records, record => !record.hasDetails);
  useEffect(() => {
    const ruleType = window.location.href.includes('ruletype')
      ? window.location.href
        .substring(window.location.href.lastIndexOf('=') + 1)
        .trim()
      : '';
    const imageUploadRuleTypes = ['RAD', 'IMG', 'SIG', 'CHT'];
    if (_.includes(imageUploadRuleTypes, ruleType)) {
      const testCountRequest = [];
      records.forEach(record => {
        testCountRequest.push({
          ruleType,
          ruleId: record.id,
          ruleVersion: getRuleVersion(record),
        });
      });
      triggerImagesTestCountRequest(testCountRequest);
    }
  }, []);

  const getRuleVersion = record => {
    try {
      const { status, version } = record;
      const testVersion = version[status.indexOf('T')];
      return testVersion;
    } catch (e) {
      console.log(e);
    }
    return null;
  };

  const getGridConfig = () => {
    const ruleType = window.location.href.includes('ruletype')
      ? window.location.href
        .substring(window.location.href.lastIndexOf('=') + 1)
        .trim()
      : '';
    const imageUploadRuleTypes = ['RAD', 'IMG', 'SIG', 'CHT'];
    if (_.includes(imageUploadRuleTypes, ruleType))
      return gridCommitConfigForImageRuleTypes;
    return gridCommitConfig;
  };


  const onButtonClick = action => () => {
    const ids = filter(records, record => record.hasDetails).map(
      ({ id }) => id,
    );
    const selectedItemIds = new Set(ids);

    if (action === 'confirm') {
      triggerMultipleCommitPutRequest({ selectedItemIds });
      setIsMultipleRulesCommitingState(true);
    }
    if (action === 'cancel') {
      setIsMultipleRulesCommitingState(false);
      setIsShowingMultipleCommitRulesModal(false);
    }
  };

  const onModalToggle = () => {
    setIsMultipleRulesCommitingState(false);
    setIsShowingMultipleCommitRulesModal(false);
  };

  const generateGridRecordsFromProps = gridRecords =>
    map(gridRecords, record => {
      const matchingRecord =
        !_.isEmpty(multipleTestImageCountRecords) &&
        multipleTestImageCountRecords.find(
          multiRecord => multiRecord.ruleId === record.id,
        );
      const imagesInTest = matchingRecord ? matchingRecord.testImageCount : 0;
      const activeGroupLabel =
        record.activeGroup !== 'None' &&
        typeof record.activeGroup !== 'string' &&
        record.activeGroup.length >= 1 ? (
            <GridCellWrap onClick={e => handleCellClick(e, record)}>
              <GridCell
                cellType="link"
                data={{
                  domID: record.id,
                  text: `${record.activeGroup.length}`,
                  href: '',
                }}
              />
            </GridCellWrap>
          ) : (
            0
          );
      return {
        ...record,
        activeGroupLength: record.activeGroup.length,
        imagesInTest,
        activeGroupLabel,
      };
    });

  const renderCustomRow = ({ columns, record }) => {
    return (
      <tr>
        {mapIterable(columns.values(), c => {
          const dataKey = c.dataName;
          const dataValue = record[dataKey];
          if(dataKey === 'trialed') {
            return (
              <CustomTableData key={dataKey} className="trialed">
                {dataValue ? 'Yes' : 'No'}
              </CustomTableData>
            )
          }
          if (dataKey === 'status') {
            switch (constants.getStatus(record.status)) {
              case constants.Statuses.TEST:
                return (
                  <td key={dataKey} className={dataKey}>
                    <WordTagWidthSmall type="neutral" label="test" />
                  </td>
                );
              case constants.Statuses.EDITED_TEST_LIVE:
                return (
                  <td key={dataKey} className={dataKey}>
                    <WordTagWidthSmall type="neutral" label="test" />
                    <WordTagWidthSmall type="positive" label="live" />
                  </td>
                );
              case constants.Statuses.TEST_LIVE:
                return (
                  <td key={dataKey} className={dataKey}>
                    <GrayedOutWordTagSmall label="test" />
                    <WordTagWidthSmall type="positive" label="live" />
                  </td>
                );
              default:
                return <td key={dataKey} className={dataKey}>{dataValue}</td>;
            }
          }
          else {
            return (
              <td key={dataKey} className={dataKey}>{dataValue}</td>
            );
          }
        })}
      </tr>
    );
  };

  const noRecordsWithDetailsMessage = recordsWithNoDetails.length && !recordsWithDetails.length
    ? <NoDetailsWrapper>
      <IconWrapperNoDetails>
        <Warning fillColor={Colors.negativeDark} />
      </IconWrapperNoDetails>
      <BoldTextAfterWarningIcon>
      Please select “CANCEL” to go back and add rule detail(s) before you commit. 
      </BoldTextAfterWarningIcon>
    </NoDetailsWrapper>
    : null;

  const recordsWithNoDetailsMessage = recordsWithNoDetails.length
    ? `
    These selected rule(s) that have no 
    detail will not show or be committed:
    ${recordsWithNoDetails
    .map(
      (record, index) =>
        `${record.name} ${
          index !== recordsWithNoDetails.length - 1 ? '| ' : ''
        }`,
    )
    .join('')}
  `
    : null;
  const multipleCommitRecords = generateGridRecordsFromProps(recordsWithDetails);
  const nonTrialedRecords =
    findIndex(multipleCommitRecords, record => !record.trialed) !== -1;

  const shouldShowMultipleImageComment =
        !_.isEmpty(multipleTestImageCountRecords) &&
        multipleTestImageCountRecords.find(
          record => record.testImageCount);

  return (
    <>
      <Modal
        domID="modal-id"
        onModalToggle={onModalToggle}
        isOpen={isShowingMultipleCommitRulesModal}
        showDefaultClose
        deferBodyControl={false}
      >
        <ModalPaddingWrapper>
          <ModalTitlePaddingWrapper>
            <DetailpageTitle title={`Commit These ${title || ''} Rule(s)`} />
            <InputLabel>{recordsWithNoDetailsMessage}</InputLabel>
            <InputLabel>{noRecordsWithDetailsMessage}</InputLabel>
          </ModalTitlePaddingWrapper>
          {nonTrialedRecords && (
            <>
              {recordsWithDetails && recordsWithDetails.length ? (
                <IconWrapper>
                  <Warning fillColor={Colors.negativeDark} />
                </IconWrapper>
              ) : null }
                {recordsWithDetails && recordsWithDetails.length ? (
                  <BoldTextAfterWarningIcon>
                    {`You are about to commit one or more <rule/rule association> that has not yet been tested. Please select "CANCEL" to go back and test this change before you commit.`}
                  </BoldTextAfterWarningIcon>
                ) : null }
              {shouldShowMultipleImageComment && <WarningWrapper>
                <ImageContentPaddingWrapper>
                  {`Please note: This action will commit the rule(s) to production. This will NOT commit any associated images to production.
                   If you have image(s) that need to be committed with these rules please navigate to the image(s) on the rule details screen
                   after committing the rule(s) to production.`}
                </ImageContentPaddingWrapper>
              </WarningWrapper>}
              {recordsWithDetails && recordsWithDetails.length ? (
                <InputLabel>
                  {
                    'If you select "Confirm", it will commit the following selected rule(s) to live.'
                  }
                </InputLabel>
              ) : null }
              <br/>
            </>
          )}
          {loading.size > 0 &&
          loading.has('multipleCommitDialogGridLoading') ? (
              <GridLoadingContainer>
                <GridLoadingWrapper>
                  <LoadingIndicator
                    domID="commit-grid-loading-indicator"
                    length="45px"
                  />
                </GridLoadingWrapper>
              </GridLoadingContainer>
            ) : (
              <ScrollableGridWrapper>
                <Grid
                  {...getGridConfig()}
                  records={multipleCommitRecords}
                  rowComponent={renderCustomRow}
                />
              </ScrollableGridWrapper>
            )}
          <DivWithPaddingTop>
            <LoadingWrapper>
              {loading.size > 0 &&
                !loading.has('multipleCommitDialogGridLoading') && (
                <LoadingIndicator
                  domID="basic-details-form-loading-indicator"
                  length="30px"
                />
              )}
            </LoadingWrapper>

            <ButtonGroup>
              {recordsWithDetails.length === 0 || loading.size > 0 ? (
                <Button
                  name="confirm"
                  buttonType="emphasized"
                  onClick={onButtonClick('confirm')}
                  size="medium"
                  type="button"
                  disabled
                />
              ) : (
                <Button
                  name="confirm"
                  buttonType="emphasized"
                  onClick={onButtonClick('confirm')}
                  size="medium"
                  type="button"
                />
              )}
              <Button
                name="cancel"
                buttonType="diminished"
                onClick={onButtonClick('cancel')}
                size="medium"
                type="button"
              />
            </ButtonGroup>
          </DivWithPaddingTop>
        </ModalPaddingWrapper>
      </Modal>
    </>
  );
};

const mapStateToProps = state => ({
  loading: selectLoadingState(state),
});

MultipleCommitModal.propTypes = {
  records: PropTypes.array.isRequired,
  setIsMultipleRulesCommitingState: PropTypes.func.isRequired,
  setIsShowingMultipleCommitRulesModal: PropTypes.func.isRequired,
  isShowingMultipleCommitRulesModal: PropTypes.bool.isRequired,
  toggleDetailsView: PropTypes.func.isRequired,
  title: PropTypes.string,
  triggerMultipleCommitPutRequest: PropTypes.func.isRequired,
  handleCellClick: PropTypes.func,
  loading: PropTypes.object,
  columns: PropTypes.object,
  record: PropTypes.object,
  triggerImagesTestCountRequest: PropTypes.func,
  multipleTestImageCountRecords: PropTypes.array,
};

export default connect(mapStateToProps)(MultipleCommitModal);
