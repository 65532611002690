import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalBody } from 'ui-core/dist';
import { useResendFileReceipt } from '../../api/useFileReceiptManager';
import ModalHeaderWrapper from '../../components/ErrorDisplaySection/ModalHeaderWrapper';
import {LoadingPage} from '../../components';
import ErrorPage from '../ErrorPage';

const FileReceiptManagerResend = ({filekey,isOpen, closeModal,sendResponseMessage}) => {
  const [ called, setCalled ] = useState(1);
  const { data, isLoading, isError, error, resendFileReceipt } = useResendFileReceipt();  

  useEffect(() => {
    if (data) {
      closeModal()
      sendResponseMessage(data)
    }
  }, [data, called]);

  return (
    <>
      <Modal isOpen={isOpen}>
        <ModalHeaderWrapper
          title="Resend File Receipt"
          onClose={() => closeModal()}
        />
        <ModalBody>
        {isLoading ? (
        // replace with a better loading component
          <LoadingPage/>
        ) :  isError ? (
          // replace with a better error component
          <ErrorPage
            // this prop isn't real but is an example
            error={error}
          />
        ) : (
          <>
            {filekey?(
              <div className='marginLeft42px fontSize14px'>
        Click Next to resend the receipt for the file shown below. The file receipt will be sent to the e-mail and ftp addresses currently on file for this client.
                <div style={{ width: 'max-content', padding: '10px 0 20px 0', marginLeft: '36%'}}>
                  <div style={{ border: '1px solid #c3c5cd',width:'156px'}}>
                    <div className='boxHeader padding4px'>{filekey}</div>
                    <div className='resendButtonWrapper'>
                      <Button
                        buttonType="standard"
                        dataTestId="test-defaultButton"
                        domID="StopLight"
                        name="<-BACK"
                        size="small"
                        type="button"
                        onClick={()=>{closeModal()}}
                      />
                      <Button
                        buttonType="standard"
                        dataTestId="test-defaultButton"
                        domID="StopLight"
                        name="NEXT->"
                        size="small"
                        type="button"
                        onClick={ () => { resendFileReceipt({pstrFileKey: filekey, called}); setCalled(called+1)}}/>
                    </div>
                  </div>
                </div>
              </div>
            ):null}
          </>
        )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default FileReceiptManagerResend;
