/* eslint-disable no-nested-ternary */
import React, { useMemo, useState,useRef } from 'react';
import PropTypes from 'prop-types';
import { SelectInput, SelectInputWrapper, SelectInputLabel } from './SelectInput';
import { useAxios } from '../hooks';
import LoadingComponent from './LoadingComponent';
import useNotificationBannerHook from '../useNotificationBannerHook';

const MultipleSelectDropdown=({
  fetchDataURL, methodType, label, initialValue,
  effect, displayField, valueField,
  labelContainer, inputContainer,selectInputLabelWidth,
  selectInputWidth, errorMessage, tagName, id,
  defaultText = '',displayDefault = true, pRecords = [], selectInputWrapperWidth, getCancelSource=()=>false})=>{
  const selectClick = useRef();
  selectClick.current = effect;
  const [records, setRecords]=useState(JSON.parse(JSON.stringify(pRecords)));
  const { notify } = useNotificationBannerHook()

  const {isLoading} = useAxios({
    getCancelSource,
    url: fetchDataURL,
    method: methodType,
    payload: {type: tagName},
    dispatchError: false,
    onSuccess: (data) => {
      if (data) {
        setRecords(JSON.parse(JSON.stringify(data ||[])));
      }
    },
    onError: (err) => {
      // notify(`${err?.displayMessage}`, 'negative')
    }
  });

  const getSelectedOptions = (sel) => {
    const opts = []; let opt;
    const selOptions = Array.from(sel.target.options);
    const len = selOptions.length;
    for (let i = 0; i < len; i += 1) {
      opt = selOptions[i];
      if (opt.selected && opt.id.length) {
        opts.push(opt.value);
      }
    }
    selectClick.current(opts);
  }

  const renderSelect = (data) => (
    <>
      {initialValue?(
        <SelectInput id={id} selectInputWidth={selectInputWidth} onChange={(value)=>getSelectedOptions(value)} multiple value={initialValue}>
          {displayDefault ? <option id='all' value=''>{defaultText.length ? defaultText : ('--Select All--')}</option> : null}
          {data}
        </SelectInput>
      ):(
        <SelectInput id={id} selectInputWidth={selectInputWidth} onChange={(value)=>getSelectedOptions(value)} multiple>
          {displayDefault ? <option id='all' value=''>{defaultText.length ? defaultText : ('--Select All--')}</option> : null}
          {data}
        </SelectInput>
      )}
    </>
    
  );

  const renderSelectContent=useMemo(()=>{
    let data = [];
    if (pRecords && pRecords.length>0 ) {
      const pRec = JSON.parse(JSON.stringify(pRecords));
      data = pRec.map((record) => (
        <option id={record[valueField]} value={record[valueField]}>{record[displayField]}</option>
      ));
    } else if(records && records.length) {
      const rec = JSON.parse(JSON.stringify(records));
      data = rec.map((record) => (
        <option id={record[valueField]} value={record[valueField]}>{record[displayField]}</option>
      ));
    }
    if (labelContainer && inputContainer) {
      return (
        <>
          {labelContainer(<SelectInputLabel selectInputLabelWidth={selectInputLabelWidth}>{label}</SelectInputLabel>)}
          {inputContainer(
            <>
              {isLoading ? (
                <LoadingComponent />
              ) : 
                (pRecords.length || records.length ? 
                  <SelectInputWrapper width={selectInputWrapperWidth}> {renderSelect(data)}</SelectInputWrapper> : <span style={{fontSize: '13px'}}>{errorMessage}</span>
                )} 
            </>)}
        </>
      )
    }
    return (
      <div>
        <SelectInputWrapper width={selectInputWrapperWidth}>
          <SelectInputLabel selectInputLabelWidth={selectInputLabelWidth}>{label}</SelectInputLabel>
          {isLoading ? (
            <LoadingComponent />
          ) :(pRecords.length || records.length ? 
            renderSelect(data) : <span style={{fontSize: '13px'}}>{errorMessage}</span>
          )}
          
        </SelectInputWrapper>
      </div>
    )
  },[records, pRecords, initialValue]);
  return (
    <>
      {renderSelectContent}
    </>
  );
}

MultipleSelectDropdown.propTypes = {
  fetchDataURL: PropTypes.string, 
  methodType: PropTypes.string, 
  label: PropTypes.string,
  effect: PropTypes.func, 
  displayField: PropTypes.string, 
  valueField: PropTypes.string,
  labelContainer: PropTypes.func, 
  inputContainer: PropTypes.func,
  selectInputLabelWidth: PropTypes.string,
  selectInputWidth: PropTypes.string,
  errorMessage: PropTypes.string,
  defaultText: PropTypes.string,
  displayDefault: PropTypes.string,
  pRecords: [],
  tagName: PropTypes.string,
  id: PropTypes.string
}

export default MultipleSelectDropdown
