import {
  ALL_GROUPS_REQUEST_TRIGGER,
  ALL_GROUPS_REQUEST_SUCCESS,
  ALL_GROUPS_REQUEST_ERROR,
  RECORD_COUNT_FROM_DB_SUCCESS,
  RECORD_COUNT_FROM_DB_ERROR,
  RECORD_COUNT_FROM_DB_TRIGGER,
  RECORD_COUNT_WITH_STATUS_KEYWORD_FROM_DB_TRIGGER,
  RECORD_COUNT_WITH_STATUS_KEYWORD_FROM_DB_SUCCESS,
  RECORD_COUNT_WITH_STATUS_KEYWORD_FROM_DB_ERROR,
  RESET_GROUPS_RECORDS,
  RESET_GROUPS_LIST
} from './constants';

// These actions will dispatch to make the request loop
// to the backend to collect the total count from the DB of records
export const recordCountByStatusAndKeywordTriggerDB = (payload = {}) => ({
  type: RECORD_COUNT_WITH_STATUS_KEYWORD_FROM_DB_TRIGGER,
  payload,
});

export const recordCountByStatusAndKeywordRecievedDB = payload => ({
  type: RECORD_COUNT_WITH_STATUS_KEYWORD_FROM_DB_SUCCESS,
  payload,
  error: null,
});

export const recordCountByStatusAndKeywordErrorDB = error => ({
  type: RECORD_COUNT_WITH_STATUS_KEYWORD_FROM_DB_ERROR,
  payload: null,
  error,
});

export const recordCountFromDBTrigger = (payload = {}) => ({
  type: RECORD_COUNT_FROM_DB_TRIGGER,
  payload,
});

export const recordCountFromDBRecieved = payload => ({
  type: RECORD_COUNT_FROM_DB_SUCCESS,
  payload,
  error: null,
});

export const recordCountFromDBError = error => ({
  type: RECORD_COUNT_FROM_DB_ERROR,
  payload: null,
  error,
});

// This is the action that the UI will dispatch to trigger our request loop
// These actions will work for all groups, search query

export const triggerAllGroupsRequest = (payload = {}) => ({
  type: ALL_GROUPS_REQUEST_TRIGGER,
  payload,
});

// This action is dispatched from the saga if our request succeeds. It is the
// action the reducer will respond in order to cache the response data
export const allGroupsListReceived = (payload = []) => ({
  type: ALL_GROUPS_REQUEST_SUCCESS,
  payload,
  error: null,
});

// This action is used to signal a request error
export const allGroupsRequestError = error => ({
  type: ALL_GROUPS_REQUEST_ERROR,
  payload: null,
  error,
});

// Reset group records (for search)
export const resetGroupsRecords = () => ({
  type: RESET_GROUPS_RECORDS,
  payload: null,
})

export const resetGroupsList = (payload = {}) => ({
  type: RESET_GROUPS_LIST,
  payload,
});
