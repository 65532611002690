import { useState, useEffect, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import axios from '../../axios'
import { resetErrors, setErrors } from '../../components/ErrorDisplaySection/modules/action';
import useNotificationBannerHook from '../../useNotificationBannerHook';

function useAxios({ 
  url, 
  method='GET',
  payload=null,
  showNotificationBanner=false,
  showErrNotification=false,
  notificationSuccessMessage=null,
  notificationErrorMessage=null,
  config = {},
  onError,
  onSuccess,
  onSettled,
  positiveMessage,
  negativeMessage,
  dispatchError = true,
  getCancelSource=()=>false
}) {
  const configRef = useRef()
  configRef.current = config
  const onErrorRef = useRef()
  onErrorRef.current = onError
  const onSuccessRef = useRef()
  onSuccessRef.current = onSuccess
  const onSettledRef = useRef()
  onSettledRef.current = onSettled
  const getCancelSourceRef=useRef()
  getCancelSourceRef.current=getCancelSource

  const cancelTokenSource = axios?.CancelToken?.source();
 
  const [data, setData] = useState(null)
  const [metaData, setMetaData] = useState(null)
  const [status, setStatus] = useState(null)
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const { notify } = useNotificationBannerHook()
  const dispatch = useDispatch();
  const activeContext = useSelector(({ context }) => `${context?.context?.client}_${context?.context?.layout}`)
  const activeUserId = useSelector(({ session }) => session.userId)
  if (getCancelSourceRef.current) {
    getCancelSourceRef.current(cancelTokenSource)
  }
 
  const getData = useCallback(async () => {
    const m = method||'GET';
    if (url && m) {
      try {
        setStatus('loading')
        setIsLoading(true)
        setData()
        const { data: info, ...rest} = await axios({
          url,
          method:m,
          data:JSON.stringify(payload),
          headers:{
            context: activeContext,
            username: activeUserId,
            ...configRef.current
          },
          cancelToken: cancelTokenSource?.token,
        });
        setStatus('success')
        setIsSuccess(true)
        setData(info)
        setMetaData(rest)
        if (positiveMessage) notify(positiveMessage, 'positive')
        if (onSuccessRef.current) onSuccessRef.current(info)
      } catch (err) {
        setStatus('error')
        setIsError(true)
        setError(err)
        if (negativeMessage) notify(negativeMessage === true ? err.displayMessage || 'Internal server error' : negativeMessage, 'negative')
        if (onErrorRef.current) onErrorRef.current(err)
      } finally {
        setIsLoading(false)
        // You can only have onSettled OR both onError & on Sucess, but not all 3.
        if (onSettledRef.current) onSettledRef.current(data, error)
      }
    }
  }, [url,method,JSON.stringify(payload), configRef, onErrorRef, onSuccessRef, onSettledRef, activeContext])
 
  const reset = () => {
    setData(null);
    setMetaData(null);
    setStatus(null);
    setError(null);
    setIsLoading(false);
    setIsError(false);
    setIsSuccess(false);
  }
 
  const refetch = () => {
    reset()
    getData()
  }
 
  useEffect(() => {
    getData()
    return () => reset()
  }, [getData])
 
  // making this an extenable hook would create issues with hook order based
  // on the design of the useAxios hook so it's not worth it.
  useEffect(() => {
    if (showNotificationBanner) {
      switch(status) {
        case 'error':
          notify(notificationErrorMessage || error?.message, 'negative')
          break;
        case 'success':
          notify(notificationSuccessMessage || 'success', 'positive')
          break;
        default:
          break;
      }  
    }
  }, [status, error])

  useEffect(() => {
    if (showErrNotification) {
      switch(status) {
        case 'error':
          notify(notificationErrorMessage || error?.message || error?.displayMessage, 'negative')
          break;
        default:
          break;
      }  
    }
  }, [status, error])

  useEffect(()=>{
    dispatch(resetErrors())
    if(dispatchError)dispatch(setErrors(data));
  },[data]);
 
  return { data, status, error, isError, isLoading, isSuccess, refetch, metaData }
}
 
export default useAxios;