import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { CircleCheckFill, Tooltip } from 'ui-core-ssgr';
import {
  ImageControlWrap,
  TruncatedColumn,
  ImageWrap,
  CanvasWrap,
  TickIconWrap
} from '../styles';
import { ImageNotFound200x100 } from '../UploadAndViewImageControl/constants'

const ImageControl = ({
  imageKey,
  imageDomID,
  imageClassName,
  imageSrc,
  imageAltLabel,
  imageStyles,
  imageOnLoad,
  imageOnError,
  imageOnClickHandle,
  imageSelection,
  ruleType
}) => {

  const handleClick = e => {
    imageOnClickHandle(e, imageKey, imageSrc, imageAltLabel);
  };

  let { imageRowSelectedClass, imageRowSelectedStyles } = '';
  if (imageSelection === Number(imageKey)) {
    imageRowSelectedClass = 'selected';
    imageRowSelectedStyles ={ border: '3px solid #358600', background: '#F1F2F4' };
  } else {
    imageRowSelectedClass = 'notselected';
    imageRowSelectedStyles = { background: '#F1F2F4' };
  }
  const canvasRef = useRef();
  const imgRef = useRef();
  const hiddenImgRef = useRef();

  useEffect(() => {
    const img = imgRef.current;
    const canvas = canvasRef.current;
    const hiddenImg = hiddenImgRef.current;
    img.onload = () => {
      if (ruleType) {
        canvas.width = 100;
        if (img.clientHeight <= 50) {
          canvas.height = 50;
        } else {
          canvas.height = img.clientHeight;
        }
      } else {
        canvas.width = 200;
        canvas.height = 100;
      }
      const ctx = canvas.getContext('2d');
      const xCord = canvas.width / 2 - img.clientWidth / 2;
      const yCord = canvas.height / 2 - img.clientHeight / 2;
      ctx.drawImage(img, xCord, yCord, img.clientWidth, img.clientHeight);
      /* The below block of code is for debugging purposes to demonstrate the aspect ratio is working properly
        canvas.title = `Original Image Size: ${hiddenImg.width}x${
          hiddenImg.height
        }, Resized Image size: ${img.width}x${
          img.height
        }, Aspect Ratio applied: ${(hiddenImg.width / hiddenImg.height).toFixed(
          1,
        )}:1`;
        ctx.strokeStyle = 'gray';
        ctx.lineWidth = 1;
        ctx.strokeRect(xCord, yCord, img.clientWidth, img.clientHeight); */
      img.style.display = 'none';
      hiddenImg.style.display = 'none';
    }

    img.onerror = () => {
      if (ruleType) {
        canvas.width = 100;
        canvas.height = 50;
      } else {
        canvas.width = 200;
        canvas.height = 100;
      }
      img.style.display = 'none';
      hiddenImg.style.display = 'none';
    }
  });
  
  return (
    <ImageControlWrap
      key={`wrap-${imageKey}`}
      className={imageRowSelectedClass}
      style={imageStyles || imageRowSelectedStyles}
      onClick={e => handleClick(e, imageKey, imageSrc, imageAltLabel)}
    >
      {imageRowSelectedStyles && imageSelection >= 0 ? (
        <TickIconWrap><CircleCheckFill
          key={`chk-${imageKey}`}
          fillColor="#358600"
          width="20px"
          className="image-check-tick"
        /></TickIconWrap>
      ) : null}
      <CanvasWrap ref={canvasRef}/>
      <ImageWrap
        key={`img1-${imageKey}`}
        ref={imgRef}
        id={imageDomID}
        className={imageClassName}
        src={imageSrc || ImageNotFound200x100}
        alt={imageAltLabel}
        ruleType={ruleType}
        onError={e => imageOnError(e) || null}
        onLoad={e => imageOnLoad(e) || null}
      />
      <img
        key={`hidden-img1-${imageKey}`}
        ref={hiddenImgRef}
        id={`hidden-${imageDomID}`}
        className={imageClassName}
        src={imageSrc || ImageNotFound200x100}
        alt={imageAltLabel}
        style={{ visibility: 'hidden' }}
        onError={e => imageOnError(e) || null}
        onLoad={e => imageOnLoad(e) || null}
      />
      <TruncatedColumn
        key={`tooltip1-${imageAltLabel}`}
        className={`tooltip1-${imageAltLabel}`}
      >
        {imageAltLabel ? (
          <Tooltip
            domID={`tool-tip-${imageAltLabel}`}
            tooltipContent={imageAltLabel}
          >
            {imageAltLabel}
          </Tooltip>
        ) : null}
      </TruncatedColumn>
    </ImageControlWrap>
  );
};

ImageControl.propTypes = {
  imageKey: PropTypes.string,
  imageDomID: PropTypes.string,
  imageClassName: PropTypes.string,
  imageSrc: PropTypes.string,
  imageAltLabel: PropTypes.string,
  imageStyles: PropTypes.object,
  imageOnClickHandle: PropTypes.func,
  imageOnLoad: PropTypes.func,
  imageOnError: PropTypes.func,
  imageSelection: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number
  ]),
  ruleType: PropTypes.string
};
export default ImageControl;