import {useState, useEffect} from 'react'
import { useAxiosPost } from '../hooks'
import { setLoadingState } from '../redux/app/loadingState/actions'

const useContextsList = () => {

    const { postData, isLoading, isError, error, data } = useAxiosPost({
        url: 'users/contexts',
        method: 'GET',
        dispatchError: false,
        onError: (e) =>
            // what happens on error? if you need something extra
            // check api and see that the notifications are already handled there
            e
        ,
        // this will override the onSuccess and onError so just pick to use this
        // or to use the other 2
        onSettled: (d, e) => {
            if (e) {
                // do a thing
                return e
            }
            // some logic here
            return d
        },
    })

    return { isLoading, isError, error, postData, data };
}

const useUpdateContext = () => {

    const [url,seturl]=useState('')
    const [payload, setpayload]=useState(null)
    const updateContext=(urli,payloadi)=>{
        seturl(urli)
        setpayload(payloadi)
    }
    useEffect(()=>{
        if (url && payload && postData) {
            postData(payload)
        }
    },[url, payload])
    const { postData, isLoading, isError, error, data } = useAxiosPost({
        url,
        method: 'PUT',
        onError: (e) =>
            // what happens on error? if you need something extra
            // check api and see that the notifications are already handled there
            e
        ,
        // this will override the onSuccess and onError so just pick to use this
        // or to use the other 2
        onSettled: (d, e) => {
            if (e) {
                // do a thing
                return e
            }
            // some logic here
            return d
        },
    })

    return { isLoading, isError, error, updateContext, data };
}

export {useContextsList, useUpdateContext}