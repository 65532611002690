import React, { useState } from 'react'
import {
  Modal,
  ModalHeader,
  ButtonGroup,
  Button,
  ModalBody
} from 'ui-core/dist'
import axios from '../../../axios'
import useNotificationBannerHook from '../../../useNotificationBannerHook';
import './index.scss'

const ClientSettingsConfirmModal = ({
  confirmationCallback,
  data,
  isAccessOpen,
  onAccessClose,
  instructions,
}) => {
  const { notify } = useNotificationBannerHook()
  const [inFlight, setInFlight] = useState(false)

  const onClose = () => {
    onAccessClose(false)
  }

  const onConfirm = async () => {
    setInFlight(true)
    let success = true;
    try {
      const form = document.forms['ClientSettingsEditorConfirmForm'];
      const formData = new FormData(form);
      const formObj = Array.from(formData.keys()).reduce((result, key) => {
        result[key] = formData.get(key);
        return result;
      }, {});
      let formDataString = '';
      for (const prop in formObj) {
        if (formObj.hasOwnProperty(prop)) {
          formDataString += prop + '*$!$*' + formObj[prop] + '~!*!~';
        }
      }
      formDataString = formDataString.length > 1 ? formDataString.substring(0, formDataString.length - 5) : '';
      const resp = await axios.put(`/clientAdministration/clientSettings`, { payload: formDataString });
      onClose()
      if (resp?.data?.fs)  {
        success = false;
        notify(resp?.data?.fs, 'negative')
      }
      else notify('Your client setting changes were successfully saved', 'positive')
    } catch (err) {
      notify(err.displayMessage, 'negative')
    } finally {
      setInFlight(false)
      if (success) confirmationCallback()
    }
  }

  return (
    <Modal isOpen={isAccessOpen} className="client-settings-modal">
      <ModalHeader
        title={`${instructions ? 'Instructions' : 'Confirm Changes?'}`}
        onClose={onClose}
      />
      <ModalBody>
        <div className="client-settings-modal__contents">
          {(
            <>
              {(
                <>
                  <form name="ClientSettingsEditorConfirmForm">
                    <table>
                      <tbody className="clientSettings-confirm-data" dangerouslySetInnerHTML={{ __html: data }} />
                    </table>
                  </form>
                </>
              )}
            </>
          )}
          <ButtonGroup className="client-settings-modal__button-group">
            {(
              <>
                <Button
                  domID="ClientSettingsConfirmModalBack"
                  name={`${instructions ? 'Close' : 'Cancel'}`}
                  onClick={onClose}
                  size="small"
                  buttonType="standard"
                />
                {!instructions && <Button
                  domID="ClientSettingsConfirmModalConfirm"
                  name="Confirm"
                  onClick={onConfirm}
                  size="small"
                  buttonType="standard"
                  disabled={inFlight || data === 'No changes were found'}
                />}
              </>
            )}
          </ButtonGroup>
        </div>
      </ModalBody>
    </Modal >
  )
}

export default ClientSettingsConfirmModal