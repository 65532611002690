import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { SingleSelectItem } from 'ui-core/dist';
import { errorAbout } from './helpers/deprecationWarning';
import { mapIterable } from './helpers/iterables';
import padding from './helpers/padding';
import Wrapper from './components/NavigationPaneGroupWrapper'
import SingleSelectGroupOpenClose from './components/SingleSelectGroupOpenClose';

export const GROUP_FIRST_INDENTATION = 40;
export const ITEM_FIRST_INDENTATION = padding.navigationPaneHeader.left;
export const FILTER_INDENTATION = 24;

export const SingleSelectGroup = ({
  domID = null,
  dataTestId = '',
  items,
  initialSelectedIndex = null,
  initialOpenGroupIndices = null,
  onOpen = () => false,
  onSelect = () => false,
  wrapText = false,
  overflowTooltipPosition = 'bottom-center',
  overflowTooltipWidth = 200,
  title = null,
  isEditing = false,
  onMoveItems = () => false,
  dragIndex = '',
  allowCrossLevel = false,
  onHomeChange = () => false,
  initialHomeIndex = null,
  isDragging = false,
  onDragStart = () => false,
  onDragEnd = () => false,
  disableDrop = false,
  singleSelectSubMenuItems = null,
  singleSelectGroupMenuItems = null,
  onMenuClick = () => false,
  showSaveDropdownIndex = null,
  setShowSaveDropdownIndex = () => false,
  onSaveSearchRename = () => false,
  onPopoutClick = () => false,
  borderLeft,
  selectedBackground,
}) => {
  /* upcoming deprecation warning */
  useEffect(() => {
    if (title !== null)
      errorAbout(
        'title',
        SingleSelectGroup.name,
        'the nested `items` data structure',
      );
  }, [title]);

  const [openGroupIndices, setOpenGroupIndices] = useState(
    initialOpenGroupIndices || new Set([]),
  );

  useEffect(() => {
    // if (initialOpenGroupIndices !== null) {
    setOpenGroupIndices(initialOpenGroupIndices || new Set([]));
    // }
  }, [initialOpenGroupIndices]);

  const [selectedIndex, setSelectedIndex] = useState(initialSelectedIndex);
  useEffect(() => {
    setSelectedIndex(initialSelectedIndex);
  }, [initialSelectedIndex]);

  const [isAboutToDragGroup, setIsAboutToDragGroup] = useState('');

  const moveItem = (from, to) => {
    onMoveItems(from, to);
  };

  /**
   * @description selectItem - callback fired whenever a SingleSelectItem is selected
   * @param {object} event - a React synthetic event
   * @param {string} indexKey - index key in format of "0|0..."
   */
  const selectItem = (event, indexKey) => {
    // iterate over the items prop and return the item that corresponds to the indexKey
    const getSelectedItem = (key) => {
      const indexArr = key.split('|');
      return indexArr.reduce((memo, itemIndex) => {
        return memo[itemIndex].items ? memo[itemIndex].items : memo[itemIndex];
      }, items);
    };

    const selectedItem = getSelectedItem(indexKey);
    onSelect(event, { selectedIndex: indexKey, selectedItem });
    setSelectedIndex(indexKey);
  };

  /**
   * @description handleOpen - callback fired whenever a SingleSelectGroup is toggled
   * @param {object} event - a React synthetic event
   * @param {string} indexKey - index key in format of "0|0..."
   */
  const handleOpen = (event, indexKey) => {
    const newOpenGroupIndices = new Set(openGroupIndices);
    // check if openGroupIndices contains indexKey or children of indexKey. If so, delete it/them, else add it
    newOpenGroupIndices.has(indexKey)
      ? newOpenGroupIndices.forEach((value) => {
          if (value.startsWith(indexKey)) newOpenGroupIndices.delete(value);
        })
      : newOpenGroupIndices.add(indexKey);
    onOpen(event, { openGroupIndices: newOpenGroupIndices });
    setOpenGroupIndices(newOpenGroupIndices);
  };

  /**
   * @description generateSingleSelectItem - given an item, return the appropriate SingleSelectItem component
   * @param {string || object} item
   * @param {string} indexKey - format "0|0|0"
   * @param {string} currentSelectedIndex - format "0|0|0"
   * @param isEditingItem Indicates whether the Navigation Pane is currently in edit mode or not
   * @param disableReOrder This can be passed to not allow items to be dragged
   * @returns {object} - SingleSelectItem component
   */
  const generateSingleSelectItem = (
    item,
    indexKey,
    currentSelectedIndex,
    isEditingItem,
    disableReOrder,
  ) => {
    const currentLevel = indexKey.split('|')[0] || '';
    // levelCount for current index
    const levelCount = (`${indexKey}`.match(/\|/g) || []).length;
    let paddingLeft =
      ITEM_FIRST_INDENTATION + (levelCount + 1) * FILTER_INDENTATION;
    let deepestOpenParent = 0;

    // if this item is selected, determine which of its parents are open
    // and set its paddingLeft appropriately
    if (indexKey === currentSelectedIndex) {
      mapIterable(openGroupIndices.values(), (value) => {
        if (
          indexKey.startsWith(value) &&
          `${value}`.match(/\|/g) &&
          `${value}`.match(/\|/g).length > deepestOpenParent
        ) {
          deepestOpenParent = `${value}`.match(/\|/g).length;
        }
      });
    }
    if (
      indexKey === currentSelectedIndex &&
      deepestOpenParent < levelCount - 1
    ) {
      paddingLeft =
        ITEM_FIRST_INDENTATION + deepestOpenParent * FILTER_INDENTATION;
    }

    const SingleSelectItemWrapper =
      !isEditingItem || disableReOrder ? Fragment : <></>;

    const extraProps =
      !isEditingItem || disableReOrder
        ? {}
        : {
            index: `${dragIndex}_${indexKey}`,
            moveItem: (dragItemIndex, hoverIndex) =>
              moveItem(dragItemIndex, hoverIndex),
            type: `drag-drop-${dragIndex}-${
              allowCrossLevel ? '' : currentLevel
            }`,
            onDragStart: () => onDragStart(dragIndex),
            onDragEnd,
          };

    const handleHomeChange = () => onHomeChange(`${dragIndex}_${indexKey}`);

    const menuItems = item.singleSelectSubMenuItems || singleSelectSubMenuItems;

    return (
      <SingleSelectItemWrapper key={indexKey} {...extraProps}>
        {!item.text ? (
          <SingleSelectItem
            icon={item.icon}
            domID={`${domID}-${indexKey}`}
            dataTestId={`${dataTestId}-${indexKey}`}
            text={item}
            isSelected={indexKey === currentSelectedIndex}
            onClick={(event) => {
              selectItem(event, `${indexKey}`);
            }}
            paddingLeft={paddingLeft}
            wrapText={wrapText}
            overflowTooltipWidth={overflowTooltipWidth}
            overflowTooltipPosition={overflowTooltipPosition}
            isEditing={isEditing}
            onHomeChange={handleHomeChange}
            isCurrentHome={indexKey === initialHomeIndex}
            disableReOrder={disableReOrder}
            menuItems={menuItems}
            onMenuClick={onMenuClick}
            showSaveDropdown={showSaveDropdownIndex === indexKey.split('|')[1]}
            onSave={onSaveSearchRename}
            onCancel={() => setShowSaveDropdownIndex(null)}
            saveSearchTitleInitialValue={item}
            borderLeft={borderLeft}
            selectedBackground={selectedBackground}
            // style={{ margin: '0 0 0 -21px' }}
          />
        ) : (
          <SingleSelectItem
            domID={`${domID}-${indexKey}`}
            dataTestId={`${dataTestId}-${indexKey}`}
            text={item.text}
            isPopout={item.isPopout}
            onPopoutClick={() => {
              onPopoutClick(item);
            }}
            count={item.count}
            tooltipText={item.tooltipText}
            tooltipWidth={item.tooltipWidth}
            overflowTooltipWidth={
              item.overflowTooltipWidth || overflowTooltipWidth
            }
            overflowTooltipPosition={
              item.overflowTooltipPosition || overflowTooltipPosition
            }
            isSelected={indexKey === currentSelectedIndex}
            onClick={(event) => {
              selectItem(event, indexKey);
            }}
            paddingLeft={paddingLeft}
            wrapText={item.wrapText || wrapText}
            isEditing={isEditing}
            onHomeChange={handleHomeChange}
            isCurrentHome={indexKey === initialHomeIndex}
            disableReOrder={disableReOrder}
            menuItems={menuItems}
            onMenuClick={onMenuClick}
            showSaveDropdown={showSaveDropdownIndex === indexKey}
            onSave={onSaveSearchRename}
            onCancel={() => setShowSaveDropdownIndex(null)}
            saveSearchTitleInitialValue={item.text}
            icon={item.icon}
            borderLeft={borderLeft}
            selectedBackground={selectedBackground}
            // style={{ margin: '0 0 0 -21px' }}
          />
        )}
      </SingleSelectItemWrapper>
    );
  };

  /**
   *@description generateSingleSelectGroup - recursively iterate through items and render group
   *@returns {object} - SingleSelectGroup component
   */
  const generateSingleSelectGroup = () => {
    // for iterating through items
    const recursiveFunction = (
      itemsToRecurseOn,
      level,
      isUnderSavedSearches,
    ) => {
      if (!itemsToRecurseOn) return null;

      return itemsToRecurseOn.map((item, index) => {
        // if the item has no items within it, it should be a SingleSelectItem
        if (item && !item.items) {
          // if the level it's within is not open and the item is not selected, don't render it
          if (
            !openGroupIndices.has(level) &&
            `${level}|${index}` !== selectedIndex
          )
            return null;
          return generateSingleSelectItem(
            item,
            `${level}|${index}`,
            selectedIndex,
            isEditing,
            itemsToRecurseOn.length === 1 && isUnderSavedSearches !== true,
          );
        }

        // otherwise, if an item has an items object, it should be a SingleSelectGroupOpenClose
        const prefix = level.length > 0 ? `${level}|` : '';
        const key = `${prefix}${index}`;
        const levelCount = (key.match(/\|/g) || []).length;
        const paddingLeft =
          GROUP_FIRST_INDENTATION + levelCount * FILTER_INDENTATION;

        const SingleSelectItemWrapper = isEditing ? <></> : Fragment;

        const dndProps = isEditing
          ? {
              index: `${dragIndex}_${level}|${index}`,
              moveItem: (h, t) => {
                moveItem(h, t);
              },
              type: `drag-drop-${levelCount ? dragIndex : ''}-${level}`,
            }
          : {};

        const menuItems =
          item.singleSelectGroupMenuItems || singleSelectGroupMenuItems;

        return levelCount === 0 || openGroupIndices.has(level) ? (
          <Wrapper
            key={key}
            role="menu"
            className={
              // eslint-disable-next-line no-nested-ternary
              disableDrop
                ? 'non-drop-zone'
                : isAboutToDragGroup === key && !isDragging
                ? 'drag-initial'
                : ''
            }
          >
            <SingleSelectItemWrapper {...dndProps}>
              <SingleSelectGroupOpenClose
                domID={
                  key ? `${domID}-open-close-${key}` : `${domID}-open-close`
                }
                dataTestId={`${dataTestId}-open-close-${key}`}
                title={item.text}
                isLink={item.isLink}
                onClick={(e) => handleOpen(e, key)}
                selectItem={(e) => selectItem(e, key)}
                isExpanded={openGroupIndices.has(key)}
                paddingLeft={paddingLeft}
                wrapText={item.wrapText || wrapText}
                overflowTooltipWidth={
                  item.overflowTooltipWidth || overflowTooltipWidth
                }
                overflowTooltipPosition={
                  item.overflowTooltipPosition || overflowTooltipPosition
                }
                className={key === selectedIndex ? 'selected' : ''}
                isEditing={isEditing}
                onHoverReorder={setIsAboutToDragGroup}
                keyForHoverReorder={key}
                menuItems={menuItems}
                onMenuClick={onMenuClick}
                isCreateGroupSubFolder={!!menuItems?.length && isEditing}
                icon={item.icon}
              />
            </SingleSelectItemWrapper>
            {recursiveFunction(item.items, key, item.isCreateGroupSubFolder)}
          </Wrapper>
        ) : (
          <>{recursiveFunction(item.items, key, isUnderSavedSearches)}</>
        );
      });
    };
    // eslint-disable-next-line react/destructuring-assignment
    return recursiveFunction(items, '');
  };

  return (
    <div id={domID} data-testid={dataTestId}>
      {generateSingleSelectGroup()}
    </div>
  );
};

SingleSelectGroup.propTypes = {
  dataTestId: PropTypes.string,
  domID: PropTypes.string,
  /** an array containing either strings or `item` objects:
   * @prop {String} text item label
   * @prop {String | Number} count number shown to right of label
   * @prop {String} tooltipText shown when hovering over `count`
   * @prop {Number} tooltipWidth width of tooltip shown when hovering over `count`
   * @prop {[item]} items sub-items or children of this item
   */
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        text: PropTypes.string,
        isPopout: PropTypes.bool,
        count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        tooltipText: PropTypes.string,
        tooltipWidth: PropTypes.number,
        wrapText: PropTypes.bool,
        overflowTooltipWidth: PropTypes.number,
        overflowTooltipPosition: PropTypes.oneOf([
          'top-center',
          'top-left',
          'top-right',
          'bottom-center',
          'bottom-left',
          'bottom-right',
          'left-up',
          'left-center',
          'left-down',
          'right-up',
          'right-center',
          'right-down',
        ]),
        items: PropTypes.array,
        isCreateGroupSubFolder: PropTypes.bool,
        /**
         * In edit mode there is an ellipsis that opens menu with list of menu items defined through `singleSelectSubMenuItems` prop.
         * This prop is used to define menu items for specific SingleSelectItem in the edit mode.
         */
        singleSelectSubMenuItems: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            label: PropTypes.string.isRequired,
            path: PropTypes.string,
            labelDecoration: PropTypes.string,
            isSecondary: PropTypes.bool,
          }),
        ),
        /**
         * In edit mode there is an ellipsis that opens menu with list of menu items defined through `singleSelectGroupMenuItems` prop.
         * This prop is used to define menu items for specific SingleSelectGroup in the edit mode.
         */
        singleSelectGroupMenuItems: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            label: PropTypes.string.isRequired,
            path: PropTypes.string,
            labelDecoration: PropTypes.string,
            isSecondary: PropTypes.bool,
          }),
        ),
      }),
    ]),
  ).isRequired,
  /** fires when an item is clicked
   * @param {SyntheticEvent} e
   * @param {Object} state ('state' name only for JSDoc object structure)
   * @prop {String} state.selectedIndex - indexKey - format "0|0|0"
   * @prop {String} state.selectedItem - item text
   */
  onSelect: PropTypes.func,
  /** fires when a group is opened or closed
   * @param {SyntheticEvent} e
   * @param {Object} state ('state' name only for JSDoc object structure)
   * @prop {Set} state.openGroupIndices - each key formatted "0|0..."
   */
  onOpen: PropTypes.func,
  /** string representing the SingleSelectItem's location in the data structure, e.g. '0|0|1' where first integer is the index of the first level, etc. */
  initialSelectedIndex: PropTypes.string,
  /** set of strings representing the SingleSelectGroupOpenClose's location in the data structure, e.g. '0|1' where the first integer if the index of the top level element and the second is the index of the indicated element */
  initialOpenGroupIndices: PropTypes.instanceOf(Set),
  /** title prop is no longer supported. Please use items prop text property instead. */
  title: PropTypes.string, // for legacy
  /** if true, the text in the items wraps instead of truncating if too long */
  wrapText: PropTypes.bool,
  overflowTooltipWidth: PropTypes.number,
  overflowTooltipPosition: PropTypes.oneOf([
    'top-center',
    'top-left',
    'top-right',
    'bottom-center',
    'bottom-left',
    'bottom-right',
    'left-up',
    'left-center',
    'left-down',
    'right-up',
    'right-center',
    'right-down',
  ]),
  /** Indicates whether the Navigation Pane is in edit mode or not */
  isEditing: PropTypes.bool,
  /** Indicates whether an item from one group can be dragged to another group */
  allowCrossLevel: PropTypes.bool,
  onMoveItems: PropTypes.func,
  dragIndex: PropTypes.number,
  onHomeChange: PropTypes.func,
  isDragging: PropTypes.bool,
  onDragStart: PropTypes.func,
  initialHomeIndex: PropTypes.string,
  onDragEnd: PropTypes.func,
  disableDrop: PropTypes.bool,
  /**
   * In edit mode there is an ellipsis that opens menu with list of menu items defined through `singleSelectSubMenuItems` prop.
   * This prop is used to define menu items for all SingleSelectItem in the edit mode.
   */
  singleSelectSubMenuItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string.isRequired,
      path: PropTypes.string,
      labelDecoration: PropTypes.string,
      isSecondary: PropTypes.bool,
    }),
  ),
  /**
   * In edit mode there is an ellipsis that opens menu with list of menu items defined through `singleSelectGroupMenuItems` prop.
   * This prop is used to define menu items for all SingleSelectGroup in the edit mode.
   */
  singleSelectGroupMenuItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string.isRequired,
      path: PropTypes.string,
      labelDecoration: PropTypes.string,
      isSecondary: PropTypes.bool,
    }),
  ),
  /**
   * This function is fired when sub menu item is selected
   */
  onMenuClick: PropTypes.func,
  /** OPT-IN prop for save-search pattern only - use this prop to open a rename search dialog */
  showSaveDropdownIndex: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  /** OPT-IN prop for save-search pattern only - setter prop for showSaveDropdownIndex */
  setShowSaveDropdownIndex: PropTypes.func,
  /** OPT-IN prop for save-search pattern only - callback to respond to user completing rename search form */
  onSaveSearchRename: PropTypes.func,
  /** if any item has isPopout true, then clicking of that item will call this function. item will be passed as an argument */
  onPopoutClick: PropTypes.func,
  /**
   * Represent background color for selected item default is primary30
   */
  selectedBackground: PropTypes.string,
  /**
   * Represent border left color for selected item default is primary100
   */
  borderLeft: PropTypes.string,
};

export default SingleSelectGroup;
