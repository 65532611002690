
import { useEffect, useState } from 'react'
import { useAxiosPost } from '../hooks'

const useGetSysRecipientTypes=()=>{
  const {directPostCall, isLoading,isError,error }=useAxiosPost({
    url:'clientAdministration/getSysRecipientTypes',
    method:'POST',
    onError: (e) => 
      // what happens on error? if you need something extra
      // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  

  return { isLoading, isError, error, directPostCall};
}
const useAddRecipientTypeTranslation=()=>{
  const {postData, isLoading,isError,error, metaData, data }=useAxiosPost({
    url:'clientAdministration/addRecipientTypeTranslation',
    method:'POST',
    onError: (e) => 
      // what happens on error? if you need something extra
      // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  

  return { isLoading, isError, error, postData, metaData, data};
}
const useUpdateRecipientTypeTranslation=()=>{
  const {postData, isLoading,isError,error,metaData, data }=useAxiosPost({
    url:'clientAdministration/updateRecipientTypeTranslation',
    method:'POST',
    onError: (e) => 
      // what happens on error? if you need something extra
      // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  

  return { isLoading, isError, error, postData, metaData, data};
}
const useSetRecipTypeStatus=()=>{
  const {postData, isLoading,isError,error, metaData, data }=useAxiosPost({
    url:'clientAdministration/setRecipTypeStatus',
    method:'POST',
    onError: (e) => 
      // what happens on error? if you need something extra
      // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  

  return { isLoading, isError, error, postData, metaData, data};
}
const useDeleteRecipientTypeTranslation=()=>{
  const {postData, metaData, isLoading,isError,error, data }=useAxiosPost({
    url:'clientAdministration/deleteRecipientTypeTranslation',
    method:'POST',
    onError: (e) => 
      // what happens on error? if you need something extra
      // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  

  return { isLoading, isError, error, postData, metaData, data};
}

const useRecipientTypeTranslationDetails=()=>{
  const [showMoreInfo, setshowMoreInfo]=useState(null)
  const {postData, isLoading,isError,error, data }=useAxiosPost({
    url:'clientAdministration/getRecipientTypeTranslation',
    method:'POST',
    onError: (e) => 
      // what happens on error? if you need something extra
      // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  
  useEffect(()=>{
    if (data && data['recipient-type-translation'] && data['recipient-type-translation']['recipient-type-translation']) {
      
      setshowMoreInfo(JSON.parse(JSON.stringify(data['recipient-type-translation']['recipient-type-translation'])))
    } else{
      setshowMoreInfo(null);
    }
  },[data])

  return {showMoreInfo, isLoading, isError, error, postData};
}
const useClientAdministrationRecipientTypeData=()=>{
  const [totalRecords,setTotalRecords]=useState(0)
  const [records, setRecords]=useState([]);
  const {postData, isLoading,isError,error, data }=useAxiosPost({
    url:'clientAdministration/getRecipientTypeTranslations',
    method:'POST',
    onError: (e) => 
      // what happens on error? if you need something extra
      // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  
  useEffect(()=>{
    if (data) {
      const {collection}=data;
      if (collection && collection.$) {
        const {totalRecords: totalRecordsp, type}=collection.$
        if (type && collection[type]) {
          setTotalRecords(totalRecordsp)  
          const sr=JSON.parse(JSON.stringify(collection[type]))  
          let searchResult=[]
          if (sr.constructor.name==="Array") {
            searchResult=[...sr]
          } else if(sr.constructor.name==="Object"){
            searchResult.push({...sr})
          }
          if (searchResult && searchResult.length>0) {
            setRecords(JSON.parse(JSON.stringify(searchResult)))  
          } else{
            setTotalRecords(0);
            setRecords(JSON.parse(JSON.stringify([])))  
          }
        } else{
          setTotalRecords(0);
          setRecords(JSON.parse(JSON.stringify([])))
        }
      }
    }
  },[data])
  return {totalRecords,records,postData, isLoading, isError, error};
}

export {
  useClientAdministrationRecipientTypeData,
  useRecipientTypeTranslationDetails,
  useGetSysRecipientTypes,
  useAddRecipientTypeTranslation,
  useUpdateRecipientTypeTranslation,
  useSetRecipTypeStatus,
  useDeleteRecipientTypeTranslation
}