/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import styled from 'styled-components/dist/styled-components'
import { useLayout } from 'ui-core/dist/useLayout';
import DetailPaneHeader from 'ui-core/dist/DetailPaneHeader';
import { useSelector, useDispatch } from 'react-redux';
import {
  Modal, Button, ModalHeader, Input, ModalBody
} from 'ui-core/dist';
import { detailPaneAnimStates } from '../../../commonResources/constants/animStates';
import ErrorPage from '../../ErrorPage';
import LoadingComponent from '../../../components/LoadingComponent';
import useNotificationBannerHook from '../../../useNotificationBannerHook';
import {
  useGetSelectedTerroristExclusionExclusion,
  useAddTerroristExclusion,
  useModifyTerroristExclusion
} from '../../../api/useTWExclusion'

const AddressCorrectionExclusionDetail = () => {
  const {
    expandCollapseDetailPaneButtonConfig,
    closeDetailPaneButtonConfig,
    detailAnimationState,
    closeDetailPane
  } = useLayout()

  const { notify } = useNotificationBannerHook();
  const [name, setname] = useState('')
  const [address, setaddress] = useState('')
  const [description, setdescription] = useState('')
  const [city, setcity] = useState('')
  const [state, setstate] = useState('')
  const [zipCode, setzipCode] = useState('')
  const [zip4, setZip4] = useState('')
  const [country, setcountry] = useState('')
  const [confirmationModalMode, setconfirmationModalMode] = useState('')
  const [isOpenConfirmationModal, setisOpenConfirmationModal] = useState(false)

  const [count, setcount] = useState(0)

  const { terroristExclusionSelectedRecord, terroristExclusionEditorMode, refetchterroristExclusionRecord }
    = useSelector(({ clientAdministration }) => clientAdministration);

  useEffect(() => {
    if (terroristExclusionSelectedRecord) {
      if (terroristExclusionSelectedRecord.key) {
        getShowMoreData(terroristExclusionSelectedRecord.key);
      } else {
        onClearCurrentRecordValues()
      }
    }
  }, [terroristExclusionSelectedRecord]);

  const getShowMoreData = (key) => {
    postDataShowMoreInfo({ key,count})
    setcount(count + 1)
  }

  const { showMoreInfo, postData: postDataShowMoreInfo, isLoading: isLoadingShowMoreInfo,
    isError: isErrorShowMoreInfo, error: errorShowMoreINfo } = useGetSelectedTerroristExclusionExclusion()

  const { data: infoAddAddressExclusion, postData: addTerroristExclusion, metaData: addTerroristExclusionResponse, isLoading: isLoadingaddTerroristExclusion,
    isError: isErroraddTerroristExclusion, error: erroraddTerroristExclusion } = useAddTerroristExclusion()

  const { data: infoModifyAddressExclusion, postData: modifyTerroristExclusion, metaData: modifyTerroristExclusionResponse, isLoading: isLoadingmodifyTerroristExclusion,
    isError: isErrormodifyTerroristExclusion, error: errormodifyTerroristExclusion } = useModifyTerroristExclusion()

  const onResetCurrentRecordValues = () => {
    if (showMoreInfo) {
      setname(showMoreInfo?.name)
      setaddress(showMoreInfo?.address)
      setdescription(showMoreInfo?.description)
      setcity(showMoreInfo?.city)
      setstate(showMoreInfo?.state)
      setzipCode(showMoreInfo?.zipCode)
      setZip4(showMoreInfo?.zip4)
      setcountry(showMoreInfo?.country)
    }
  }

  useEffect(() => {
    if (addTerroristExclusionResponse) {
      const { status, statusText } = addTerroristExclusionResponse;
      if (status === 200 || statusText === "OK") {
        if (infoAddAddressExclusion && infoAddAddressExclusion.fs) {
          const { fs } = infoAddAddressExclusion;
          if (fs) {
            notify(fs, 'negative')
            return;
          }
        }
        notify('Terrorist Watch Exclusion Added successfully', 'positive')
        closeDetailPane()
        if (refetchterroristExclusionRecord) refetchterroristExclusionRecord()
      }
    }
  }, [addTerroristExclusionResponse])

  useEffect(() => {
    if (modifyTerroristExclusionResponse) {
      const { status, statusText } = modifyTerroristExclusionResponse;
      if (status === 200 || statusText === "OK") {
        if (infoModifyAddressExclusion && infoModifyAddressExclusion.fs) {
          const { fs } = infoModifyAddressExclusion;
          if (fs) {
            notify(fs, 'negative')
            return;
          }
        }
        notify('Terrorist Watch Exclusion Updated successfully', 'positive')
        closeDetailPane()
        if (refetchterroristExclusionRecord) refetchterroristExclusionRecord()
      }
    }
  }, [modifyTerroristExclusionResponse])

  const onSubmitClick = () => {
    if (terroristExclusionEditorMode === "Add") {
      setconfirmationModalMode("Add")
    } else if (terroristExclusionEditorMode === "Edit") {
      setconfirmationModalMode("Modify")
    }
    setisOpenConfirmationModal(true)
  }
  const onConfirmationYesClick = () => {
    if (confirmationModalMode === "Add") {
      addTerroristExclusion({
        count,
        name,
        description,
        address,
        city,
        state,
        country,
        zipCode,
        zip4,
        id: ''
      })
      setcount(count + 1)

    } else if (confirmationModalMode === "Modify") {
      modifyTerroristExclusion({
        count, id: showMoreInfo?.id,
        name,
        description,
        address,
        city,
        state,
        country,
        zipCode,
        zip4
      })
      setcount(count + 1)
    }
    setconfirmationModalMode('')
    setisOpenConfirmationModal(false)
  }
  useEffect(() => {
    onResetCurrentRecordValues()
  }, [showMoreInfo])

  const onClearCurrentRecordValues = () => {
    setname('')
    setaddress('')
    setdescription('')
    setcity('')
    setstate('')
    setzipCode('')
    setcountry('')
    setZip4('')
  }
  const renderName = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_name"
      placeholder=""
      size="small"
      maxLength={50}
      initialValue={name}
      errorMessage="Incorrect data do not use '&', '|' or '>' characters"
      hasError={/[\\&>\\|]/.test(name)}
      onChange={(e) => {
        setname(e.target.value || "")
      }}
    />
  ), [name])
  const renderAddress = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_address"
      placeholder=""
      size="small"
      maxLength={50}
      initialValue={address}
      errorMessage="Incorrect data do not use '&', '|' or '>' characters"
      hasError={/[\\&>\\|]/.test(address)}
      onChange={(e) => {
        setaddress(e.target.value || "")
      }}
    />
  ), [address])
  const renderDescription = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_description"
      placeholder=""
      size="small"
      maxLength={64}
      initialValue={description}
      errorMessage="Incorrect data do not use '&', '|' or '>' characters"
      hasError={/[\\&>\\|]/.test(description)}
      onChange={(e) => {
        setdescription(e.target.value || "")
      }}
    />
  ), [description])
  const renderCity = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_city"
      placeholder=""
      size="small"
      maxLength={35}
      initialValue={city}
      errorMessage="Incorrect data do not use '&', '|' or '>' characters"
      hasError={/[\\&>\\|]/.test(city)}
      onChange={(e) => {
        setcity(e.target.value || "")
      }}
    />
  ), [city])
  const renderState = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_state"
      placeholder=""
      size="small"
      maxLength={2}
      initialValue={state}
      onChange={(e) => {
        setstate(e.target.value || "")
      }}
    />
  ), [state])
  const renderZipCode = useMemo(() => (
    <div className="displayFlex">
      <Input
        style={{ width: '100px' }}
        className="test-classname"
        domID="txt_zipCode"
        placeholder=""
        size="small"
        maxLength={6}
        initialValue={zipCode}
        errorMessage="Only numeric values are allowed and must be 5 digit"
        hasError={zipCode ? !/^\d{5}$/.test(zipCode) : false}
        onChange={(e) => {
          setzipCode(e.target.value || "")
        }}
      />
      <Input
        style={{ width: '90px',marginLeft:'10px' }}
        className="test-classname"
        domID="txt_zip4"
        placeholder=""
        size="small"
        maxLength={4}
        initialValue={zip4}
        errorMessage="Only numeric values are allowed and must be 4 digit"
        hasError={zip4 ? !/^\d{4}$/.test(zip4) : false}
        onChange={(e) => {
          setZip4(e.target.value || "")
        }}
      />
    </div>
  ), [zipCode,zip4])
  const renderCountry = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_country"
      placeholder=""
      size="small"
      maxLength={64}
      initialValue={country}
      errorMessage="Incorrect data do not use '&', '|' or '>' characters"
      hasError={/[\\&>\\|]/.test(country)}
      onChange={(e) => {
        setcountry(e.target.value || "")
      }}
    />
  ), [country])
  const validateForm = useMemo(() => {
    let errorCount = 1
    if((name && !/[\\&>\\|]/.test(name)) && (description && !/[\\&>\\|]/.test(description))){
      errorCount=0
      if ((address && /[\\&>\\|]/.test(address))
       || (city && /[\\&>\\|]/.test(city)) 
       || (country && /[\\&>\\|]/.test(country)) 
       || zipCode && !/^\d{5}$/.test(zipCode)
       || zip4 && !/^\d{4}$/.test(zip4)
       || ((state && /[\\&>\\|]/.test(state)) || (state && state.length < 2))
      ){
        errorCount=1
      }
      
    }
    

    if(!errorCount) {
      return true
    }

  }, [name, address, description, city, state, zipCode, zip4, country])

  const renderAddressEntryForm = useMemo(() => {
    if (detailAnimationState > detailPaneAnimStates.COLLAPSED) {
      return (
        <div style={{ marginLeft: '40px' }} className="marginLeft">
          <div>
            <p>
              <div className="displayFlex">Complete the information below, and then click Submit to continue.</div>
            </p>
            <p>
              <div className="displayFlex"><span style={{ color: 'red' }}>* indicates required information</span></div>
            </p>
          </div>
          <br />
          <div className="displayFlex">
            <table className="common-table">
              <tr>
                <td>
                  <label> Description : <span style={{ color: 'red' }}>*</span></label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderDescription}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <label> Name (Exactly as passed in data file): <span style={{ color: 'red' }}>*</span></label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderName}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <label>Corrected Address 1 From Decission Data: </label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderAddress}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <label>Corrected City From Decission Data: </label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderCity}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <label> Corrected State From Decission Data:</label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderState}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <label> Corrected Zip Code From Decission Data: </label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderZipCode}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <label> Corrected Country From Decission Data: </label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderCountry}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td colSpan="2">
                  <div className="displayFlex">
                    <div>
                      <Button
                        domID="btn_reset"
                        buttonType="standard"
                        size="small"
                        name="RESET"
                        disabled={terroristExclusionEditorMode !== "Edit"}
                        onClick={() => onResetCurrentRecordValues()}
                      />
                    </div>
                    <div className="marginLeft">
                      <Button
                        domID="btn_cancel"
                        buttonType="standard"
                        size="small"
                        name="CLEAR"
                        onClick={() => onClearCurrentRecordValues()}
                      />
                    </div>
                    <div className="marginLeft">
                      <Button
                        domID="btn_search"
                        buttonType="standard"
                        size="small"
                        name="SUBMIT"
                        disabled={!validateForm}
                        onClick={() => onSubmitClick()}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
            </table>
          </div>
          <Modal isOpen={isOpenConfirmationModal}>
            <ModalHeader
              title={`Do you want to ${confirmationModalMode} ?`}
              onClose={() => setisOpenConfirmationModal(false)}
            />
            <ModalBody>
              {isOpenConfirmationModal ? (
                <div style={{ marginLeft: '34px', marginBottom: '20px' }} className="marginLeft">
                  <div className="displayFlex">
                    <div className="marginLeft">
                      <Button
                        domID="btn_search"
                        buttonType="standard"
                        size="small"
                        name="Yes"
                        onClick={() => onConfirmationYesClick()}
                      />
                    </div>
                    <div className="marginLeft">
                      <Button
                        domID="btn_search"
                        buttonType="standard"
                        size="small"
                        name="No"
                        onClick={() => setisOpenConfirmationModal(false)}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
            </ModalBody>
          </Modal>
        </div>
      )
    }
    return null;
  }, [terroristExclusionSelectedRecord, showMoreInfo, detailAnimationState,
    name, address, description, city, state, zipCode, country, validateForm, terroristExclusionEditorMode, confirmationModalMode, isOpenConfirmationModal])

  return (
    <>
      <DetailPaneHeader
        title={terroristExclusionEditorMode === "Edit" ? "Modify Terrorist Watch Exclusion" : "Add Terrorist Watch Exclusion"}
        layoutControls={[
          expandCollapseDetailPaneButtonConfig,
          closeDetailPaneButtonConfig
        ]}
      />
      <>
        {isLoadingShowMoreInfo || isLoadingaddTerroristExclusion
          || isLoadingmodifyTerroristExclusion ? (
            // replace with a better loading component            
            <div style={{ marginLeft: '50%' }}><LoadingComponent /></div>
          ) : isErrorShowMoreInfo || isErroraddTerroristExclusion
            || isErrormodifyTerroristExclusion ? (
              // replace with a better error component
              <ErrorPage
                // this prop isn't real but is an example
                error={errorShowMoreINfo || erroraddTerroristExclusion
                  || errormodifyTerroristExclusion}
              />
            ) : (
              <>
                {renderAddressEntryForm}
              </>
            )
        }
      </>
    </>
  )
}

export default AddressCorrectionExclusionDetail