import { useAxiosPost } from '../hooks'

const useExecute=()=>{
  const {postData, isLoading,isError,error, metaData, data }=useAxiosPost({
    url:'manifestInfoManager/execute',
    method:'POST',
    onError: (e) => 
      // what happens on error? if you need something extra
      // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  
  return { isLoading, isError, error, postData, metaData, data};
}
const useGetManifestRecords=()=>{
  const {postData, isLoading,isError,error, metaData, data }=useAxiosPost({
    url:'manifestInfoManager/getManifestRecords',
    method:'POST',
    onError: (e) => 
    // what happens on error? if you need something extra
    // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  
  return { isLoading, isError, error, postData, metaData, data};
}
const useSearchManifest=()=>{
    const {postData, isLoading,isError,error, metaData, data }=useAxiosPost({
      url:'manifestInfoManager/searchManifest',
      method:'POST',
      onError: (e) => 
      // what happens on error? if you need something extra
      // check api and see that the notifications are already handled there
        e
      ,
      // this will override the onSuccess and onError so just pick to use this
      // or to use the other 2
      onSettled: (d, e) => {
        if(e) {
          // do a thing
          return e
        }
        // some logic here
        return d
      },
    })  
    return { isLoading, isError, error, postData, metaData, data};
  }
  const useSetManifestRecords=()=>{
    const {postData, isLoading,isError,error, metaData, data }=useAxiosPost({
      url:'manifestInfoManager/setManifestRecords',
      method:'POST',
      onError: (e) => 
      // what happens on error? if you need something extra
      // check api and see that the notifications are already handled there
        e
      ,
      // this will override the onSuccess and onError so just pick to use this
      // or to use the other 2
      onSettled: (d, e) => {
        if(e) {
          // do a thing
          return e
        }
        // some logic here
        return d
      },
    })  
    return { isLoading, isError, error, postData, metaData, data};
  }
  const useSetManifestWireDates=()=>{
    const {postData, isLoading,isError,error, metaData, data }=useAxiosPost({
      url:'manifestInfoManager/setManifestWireDates',
      method:'POST',
      onError: (e) => 
      // what happens on error? if you need something extra
      // check api and see that the notifications are already handled there
        e
      ,
      // this will override the onSuccess and onError so just pick to use this
      // or to use the other 2
      onSettled: (d, e) => {
        if(e) {
          // do a thing
          return e
        }
        // some logic here
        return d
      },
    })  
    return { isLoading, isError, error, postData, metaData, data};
  }
export {
    useExecute,
    useGetManifestRecords,
    useSearchManifest,
    useSetManifestRecords,
    useSetManifestWireDates
}