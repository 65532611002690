import { useAxiosPost } from '../hooks'
import {useEffect, useState} from 'react'

const useTestFiles = () => {
  
  const [datas, setData] = useState([])
  const [totalData, setTotaldata] = useState(0)
  const { data, isLoading, postData, isError, error } = useAxiosPost({
  url: `testFiles`,
  method: 'POST',
})
useEffect(()=>{
  if(data && data?.data){
    setData(data?.data)
    setTotaldata(data?.totalRecords)
  }
},[data])
  

  return { data: datas, total: totalData || undefined, getAllTestFiles: postData, isLoading, isError, error }
}

export default useTestFiles