/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/dist/styled-components';
import Warning from 'ui-core/dist/Warning';
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import {
  useLayout,
  Modal,
  ModalHeader,
  Button,
  ModalFooter,
  ButtonGroup,
  ModalBody
} from 'ui-core/dist'
import ModalWrapper from '../../../components/Modal/components/ModalWrapper';
import { LoadingPage, AnchorInput } from '../../../components';
import ErrorPage from '../../ErrorPage';
import {

  GENERIC_TEXT_PURGE,
  GENERIC_WARNING_TEXT_PURGE,
  GENERIC_TEXT_SEARCH_PURGE,
} from "../Modules/constants";
import withAxiosCancellation from '../../../components/withAxiosCancellation'
import ListPaneHeaderWrapper from '../../../components/ErrorDisplaySection/ListPaneHeaderWrapper';
import FileReceiptManagerDetailComponent from '../../FileReceiptManager/FileReceiptManagerDetailComponent';
import ShowMoreNestedGridComponent from '../../../components/ShowMoreNestedGridComponent';
import { useSearchHoldGroups, useSubmitPurgeGroups } from '../../../api/useDocumentManager';
import { resetFileKey, setFileKey } from '../../FileReceiptManager/Modules/Action';
import GridPaginationWrapper from '../../../components/GridPaginationWrapper';
import { setFileSearchParam, setFileSearchParamNull } from "../Modules/action";
import useSelectableGrid from '../../../hooks/useSelectableGrid';
import '../Modules/styles.scss';

const StyledModalWrapper = styled.div`
height: 80vh;
`
const StyledModalHeaderWrapper = styled.div`
& div {
  padding:1px 9px !important;
  padding-bottom: 1px !important;
}
`
const StyledModalWrapperWidth = styled(ModalWrapper)`
& .active-content {
  
  max-height: 600px !important;
 
  & span {
    font-size: 11px !important;
  }
}
`
const GSearchResultPurgeMain = ({getCancelSourceWithName}) => {
  const {
    expandCollapseListPaneButtonConfig
  } = useLayout()

  const dispatch = useDispatch()
  const history = useHistory();
  const { allData, totalRecordsResp, searchGroups, isLoading, isError, error } = useSearchHoldGroups({getCancelSource:getCancelSourceWithName('groupSearchResultPagePurge')});
  const { transId, submitGroups, isLoading: isTxnLoading } = useSubmitPurgeGroups();
  const { fileSearchParam } = useSelector(({ documentSearch }) => documentSearch);
  const [count, setCount] = useState(0);
  const [changeItemPerPage, setchangeItemsPerPage] = useState(fileSearchParam.maxRecords)
  const [pageIndex, setPageIndex] = useState(0)
  const [list, setlist] = useState([]);

  const [isOpenFileReceiptModal, setisOpenFileReceiptModal] = useState(false)
  const [isOpenConfirmModal, setisOpenConfirmModal] = useState(false)
  const { onRowSelect, onClearAllSelection,
    onClearSelectionCurrentPage, selectedRecords } = useSelectableGrid({ uniqueKey: 'uniqueId', selectionKey: 'isSelected', list, setlist })

  const { filekey }
    = useSelector(({ fileReceipt }) => fileReceipt);

  useEffect(() => {
    searchGroups(fileSearchParam)
  }, [])

  useEffect(() => {
    if (allData) {
      setlist(JSON.parse(JSON.stringify(allData)))
    }
  }, [allData, totalRecordsResp])



  useEffect(() => {
    if (transId) {
      setisOpenConfirmModal(false);
      // searchGroups({ ...fileSearchParam, count })
      // setCount(count + 1);
      onClearAllSelection()
      history.push({ pathname: '/DocumentManager/Purge/Group', state: `You have successfully purged the groups.  The transaction ID is ${transId}` })
    }
  }, [transId]);

  const onCloseFileReceiptModal = () => {
    setisOpenFileReceiptModal(false)
    dispatch(resetFileKey())
    return true;
  }


  const onCloseConfirmModal = () => {
    setisOpenConfirmModal(false)
    return true;
  }

  const onConfirm = () => {
    if(selectedRecords && selectedRecords.size){
      const groupIds = [...new Map(Array.from(selectedRecords).filter(y => y.isSelected).map(item => [item.groupid, item])).values()].map(x => x.groupid);
      let payLoad = `<![CDATA[<GroupManagement>`
      groupIds.forEach(g => {
        payLoad += `<group>`
        payLoad += `<id>${g}</id>`
        payLoad += `<filekeys>`
        Array.from(selectedRecords).forEach(l => {
          if (g === l.groupid)
            payLoad += `<filekey>${l.filekey}</filekey>`;
        });
        payLoad += `</filekeys>`;
        payLoad += `</group>`
      });
      payLoad += `</GroupManagement>]]>`
      submitGroups({ pstrGroupKeys: payLoad, count });
      setCount(count + 1);
    }
  }

  const transformGroupedData = (data, isGrouped) => {
    const temp = JSON.parse(JSON.stringify(data))
    let t;
    if (isGrouped) {
      t = temp.map((d) => ({
        ...d,
        subRecords: d.subRecords.map((s) => {
          let statusComp = '';
          let className;
          switch (s.status) {
            case 'Imported':
              className = 'status-ok';
              break;
            case 'Released':
              className = 'status-ok';
              break;
            case 'Ready to print':
              className = 'status-ok';
              break;
            case 'Held':
              className = 'status-warn';
              break;
            case 'Held and Errored':
              className = 'status-error';
              break;
            case 'Errored':
              className = 'status-error';
              break;
            case 'Purged':
              className = 'status-error';
              break;
            default:
              break;
          }
          statusComp = (
            <div className={className}>
              <span>{s.status}</span>
            </div>
          )
          return {
            ...s,
            'filekey_display': <AnchorInput
              id='fileKey'
              type='button'
              className='fileKeyLink'
              onClick={() => {
                dispatch(resetFileKey())
                dispatch(setFileKey(s.filekey))
                setisOpenFileReceiptModal(true)
              }}
            >{s.filekey}</AnchorInput>,
            'groupname_display': <AnchorInput
              id='documentSearchNavigation'
              type='button'
              className='fileKeyLink'
              onClick={() => { alert(s.groupname) }}
            >{s.groupname}</AnchorInput>,
            status_display: statusComp
          }
        })
      }))
    }
    return t;
  }

  const onSelectAll = () => {
    const data = list.filter(x => x.status.indexOf("Held") === -1 && x.canManage === 'true')
    onRowSelect(true, data)
  }

  const fetchData = (args) => {
    let pi;
    if (args) {
      ({ pi } = args);
    }
    const payload = JSON.parse(JSON.stringify({
      "maxRecords": changeItemPerPage,
      "rowPosition": pi === undefined || pi === null ? pageIndex : pi,
      "sortField": fileSearchParam.sortField,
      "sortDescending": fileSearchParam.sortDescending,
      "checkNumber": fileSearchParam ? fileSearchParam.checkNumber : '',
      "claimNumber": fileSearchParam ? fileSearchParam.claimNumber : '',
      "groupId": fileSearchParam ? fileSearchParam.groupId : '',
      "idNumber": fileSearchParam ? fileSearchParam.idNumber : '',
      "filekey": fileSearchParam ? fileSearchParam.filekey : '',
      "showDuplicateChecks": true,
      "showProcessedDocuments": false,
    }))
    dispatch(setFileSearchParamNull(payload))
    dispatch(setFileSearchParam(payload))
    searchGroups(payload)
  }

  const renderGrid = (fromModal) => {
    let grid = <></>;
    if (fromModal)
      grid = <ShowMoreNestedGridComponent
        mainGridConfig={{
          transformationFunction: transformGroupedData,
          disabledColumns: new Set([]),
          columns: new Set([
            {
              dataName: 'groupname_display',
              groupingColumnName: 'groupname',
              text: 'Group Name',
              sortable: true,
              isSorted: 0,
              cellType: 'text',
            },
            {
              dataName: 'filekey',
              text: 'File Key',
              sortable: true,
              isSorted: 0,
              cellType: 'text',
            },
            {
              dataName: 'originalFileName',
              text: 'File Name',
              sortable: true,
              isSorted: 0,
              cellType: 'text',
            },
            {
              dataName: 'importedDate',
              text: 'Imported Date',
              sortable: true,
              isSorted: 0,
              cellType: 'text',
            }, {
              dataName: 'status_display',
              text: 'Status',
              sortable: true,
              isSorted: 0,
              cellType: 'custom',
            }, {
              dataName: 'errors',
              text: '',
              sortable: true,
              isSorted: 0,
              cellType: 'text',
            },]),
          isGrouped: true,
          defaultGroupBy: 'groupname',
          defaultGroupingType: 'table',
          wantGroupingControls: false,
          width: '100%',
        }}
        data={Array.from(selectedRecords)}
      />
    else {
      if (list && list.length > 0) {
        grid = <ShowMoreNestedGridComponent
          mainGridConfig={{
            transformationFunction: transformGroupedData,
            disabledColumns: new Set([]),
            columns: new Set([
              {
                dataName: 'groupname_display',
                groupingColumnName: 'groupname',
                text: 'Group Name',
                sortable: true,
                isSorted: 0,
                cellType: 'text',
              },
              {
                dataName: 'filekey_display',
                text: 'File Key',
                sortable: true,
                isSorted: 0,
                cellType: 'text',
              },
              {
                dataName: 'originalFileName',
                text: 'File Name',
                sortable: true,
                isSorted: 0,
                cellType: 'text',
              },
              {
                dataName: 'importedDate',
                text: 'Imported Date',
                sortable: true,
                isSorted: 0,
                cellType: 'text',
              }, {
                dataName: 'status_display',
                text: 'Status',
                sortable: true,
                isSorted: 0,
                cellType: 'custom',
              }, {
                dataName: 'errors',
                text: '',
                sortable: true,
                isSorted: 0,
                cellType: 'text',
              },]),
            isGrouped: true,
            defaultGroupBy: 'groupname',
            defaultGroupingType: 'table',
            wantGroupingControls: false,
            width: '85%',
            supportSelection: true,
            selectionKey: 'isSelected',
            selectionCondition: (x) => x.canManage === 'true',
            onRowSelect
            // onRowSelect:(state, record)=>{
            //   if (record) {
            //     if (record.constructor.name==="Array") {
            //       record.forEach((r)=>{
            //         const l=list.find(x=>x.filekey===r.filekey);
            //         if (l) {
            //           l.isSelected=state;
            //         }
            //       })
            //     } else if(record.constructor.name==="Object"){
            //       const l=list.find(x=>x.filekey===record.filekey);
            //       if (l) {
            //         l.isSelected=state;
            //       }
            //     }
            //     setlist(JSON.parse(JSON.stringify(list)));
            //   }
            // }
          }}
          data={list}
        />
      } else {
        grid = (
          <div className="displayFlex">
            <label style={{ color: 'red' }}>No records found. Change search criteria.</label>
          </div>
        )
      }
    }
    return grid;
  }

  return (
    <>
      <ListPaneHeaderWrapper
        title="Document Manager: Purge Groups"
        layoutControls={[expandCollapseListPaneButtonConfig]}

      />
      {isLoading ? (
        // replace with a better loading component
        <LoadingPage />
      ) : isError ? (
        // replace with a better error component
        <ErrorPage
          // this prop isn't real but is an example
          error={error}
        />
      ) : (
            <div className="mainWrapper">
              <div style={{ fontSize: "small", padding: "12px" }}>
                <i>{GENERIC_TEXT_SEARCH_PURGE}</i>
                <div style={{ color: "#bf1616" }}>
                  <p>
                    {GENERIC_TEXT_PURGE}
                  </p>
                  <p>
                    <Warning
                      className="test"
                      fillColor="#bf1616"
                      title="hover for title"
                      width={75}
                    />
                    <span style={{ paddingLeft: "5px" }}>
                      {GENERIC_WARNING_TEXT_PURGE}
                    </span>
                  </p>
                </div>
              </div>
              <div>
                <GridPaginationWrapper
                  rowsPerPage={changeItemPerPage}
                  onChangeRowsPerPage={(v) => setchangeItemsPerPage(v)}
                  pageIndex={pageIndex}
                  onChangePageIndex={(v) => setPageIndex(v)}
                  onShowButtonCLick={(args) => fetchData(args)}
                  totalRecords={totalRecordsResp}
                  defaultRowsPerPage={changeItemPerPage}
                  enableTextbox={false}
                />
                <div className='displayFlex'>
                  <Button
                    buttonType="standard"
                    size="small"
                    name="Select all on this page"
                    disabled={list && list.length === 0}
                    onClick={() => { onSelectAll() }}
                  />&nbsp;
              <Button
                    buttonType="standard"
                    disabled={list.filter(x => x.isSelected === true).length === 0}
                    size="small"
                    name="Clear all on this page"
                    onClick={() => { onClearSelectionCurrentPage() }}
                  />&nbsp;
              <Button
                    buttonType="standard"
                    disabled={!selectedRecords.size}
                    size="small"
                    name="Clear all"
                    onClick={() => { onClearAllSelection() }}
                  />&nbsp;
              <Button
                    buttonType="standard"
                    size="small"
                    name="New Search"
                    onClick={() => { history.push(`/DocumentManager/Purge/Group`) }}
                  />&nbsp;
              <Button
                    buttonType="standard"
                    size="small"
                    name="CONFIRM SELECTED ITEMS"
                    disabled={!selectedRecords.size}
                    onClick={() => { setisOpenConfirmModal(true) }}
                  />
                </div>
                <div className="displayFlex"></div>
                {renderGrid(false)}

                <div className="displayFlex"></div>

                <Modal isOpen={isOpenConfirmModal} wrapperComponent={StyledModalWrapperWidth}>
                  <ModalHeader
                    title="Selected Groups"
                    onClose={() => onCloseConfirmModal()}
                  />
                  <ModalBody>
                    <div className="displayFlex" style={{ paddingLeft: '30px', fontSize: '11px' }}>
                      <span>Confirm your selections, and then click Purge to purge all documents in the selected files. </span>

                    </div>

                    <div className="displayFlex" style={{ color: "#bf1616", paddingLeft: '30px' }}>
                      <p>
                        {GENERIC_TEXT_PURGE}
                      </p>

                    </div>
                    <div>
                      {isOpenConfirmModal ? (
                        <div className="modalOuterDiv" style={{
                          paddingLeft: '25px',
                          paddingRight: '25px',
                          marginTop: '20px',
                          marginBottom: '-10px',
                          maxHeight: '150px',
                          overflow: 'scroll',
                          maxWidth: '800px'
                        }}>
                          {renderGrid(true)}
                        </div>
                      ) : null}

                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <div style={{ color: "#bf1616" }}>

                      <p>
                        <Warning
                          className="test"
                          fillColor="#bf1616"
                          title="hover for title"
                          width={75}
                        />
                        <span style={{ paddingRight: "5px" }}>
                          {GENERIC_WARNING_TEXT_PURGE}
                        </span>
                      </p>
                    </div>
                    <ButtonGroup>
                      <Button
                        buttonType="standard"
                        domID="close"
                        dataTestId="test-close"
                        name="CLOSE"
                        onClick={() => setisOpenConfirmModal(false)}
                        size="medium"
                      />
                      <Button
                        buttonType="standard"
                        domID="confirm"
                        dataTestId="test-confirm"
                        name="CONFIRM"
                        onClick={() => onConfirm()}
                        size="medium"
                        disabled={!selectedRecords.size||isTxnLoading}
                      />
                      {
                        isTxnLoading && <LoadingPage height="1vh" />
                      }
                    </ButtonGroup>
                  </ModalFooter>
                </Modal>

                <Modal isOpen={isOpenFileReceiptModal}>
                  <StyledModalWrapper>
                    <StyledModalHeaderWrapper>
                      <ModalHeader
                        title="File Receipt"
                        onClose={() => onCloseFileReceiptModal()}
                      />
                    </StyledModalHeaderWrapper>
                    <ModalBody>
                      {isOpenFileReceiptModal ? (
                        <FileReceiptManagerDetailComponent
                          filekey={filekey}
                          height='58vh'
                          overflow='auto'
                        />
                      ) : null}
                    </ModalBody>
                  </StyledModalWrapper>
                </Modal>
              </div>
            </div>
          )}
    </>
  )
}

export default withAxiosCancellation(GSearchResultPurgeMain)
