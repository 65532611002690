/* eslint-disable react/prefer-stateless-function */
/* eslint-disable dot-notation */
import React from 'react';
import styled from 'styled-components';

const VanillaStyleWrapper=styled.div`
position: fixed;
top: 0;
bottom: 0;
left: 0;
right: 0;
background-color: rgba(55, 71, 79, .8);
z-index:900;
opacity: 0;
visibility: hidden;
overflow: auto;
transition: opacity 300ms, visibility 300ms;

&.active {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
}
`;

const StyledWrapper=styled.div`
position: ${props=>(props && props.position?props.position:'fixed')};
top: ${props=>(props && props.top?props.top:'0')};
bottom: ${props=>(props && props.bottom?props.bottom:'0')};
left: ${props=>(props && props.left?props.left:'0')};
right: ${props=>(props && props.right?props.right:'0')};
background-color: ${props=>(props && props.backgroundColor?props.backgroundColor: 'rgba(55, 71, 79, .8)')};
z-index:${props=>(props && props.zIndex?props.zIndex:'900')}
opacity: ${props=>(props && props.opacity?props.opacity:'0')};
visibility: ${props=>(props && props.visibility?props.visibility:'hidden')};
overflow: ${props=>(props && props.overflow?props.overflow:'auto')};
transition: ${props=>(props && props.transition?props.transition:`opacity 300ms, visibility 300ms`)};

&.active {
  opacity: ${props=>(props &&  props.activeOpacity?props.activeOpacity:'1')};
  visibility: ${props=>(props &&  props.activeVisibility?props.activeVisibility:'visible')};
  transition-delay: ${props=>(props && props.activeTransitionDelay?props.activeTransitionDelay:'0s')};
  z-index:${props=>(props && props.activeZIndex?props.activeZIndex:'900')};
}
`;


export default class ModalWrapper extends React.Component{
  render(){
    const {wrapperStyle,className,ref,onKeyDown}=this.props;
    return (
      <div>{wrapperStyle?(
        <StyledWrapper
          className={className}
          ref={ref}
          onKeyDown={onKeyDown}
          position={wrapperStyle.position}
          top={wrapperStyle.top}
          bottom={wrapperStyle.bottom}
          left={wrapperStyle.left}
          right={wrapperStyle.right}
          backgroundColor={wrapperStyle.backgroundColor}
          zIndex={wrapperStyle.zIndex}
          opacity={wrapperStyle.opacity}
          visibility={wrapperStyle.visibility}
          overflow={wrapperStyle.overflow}
          transition={wrapperStyle.transition}
          activeOpacity={wrapperStyle.active && wrapperStyle.active.opacity?wrapperStyle.active.opacity:null}
          activeVisibility={wrapperStyle.active && wrapperStyle.active.visibility?wrapperStyle.active.visibility:null}
          activeTransitionDelay={wrapperStyle.active && wrapperStyle.active.transitionDelay?wrapperStyle.active.transitionDelay:null}
          activeZIndex={wrapperStyle.active && wrapperStyle.active.zIndex?wrapperStyle.active.zIndex:null}>
          {React.Children.toArray(this.props.children)}
        </StyledWrapper>
      ):(
        <VanillaStyleWrapper
          className={className}
          ref={ref}
          onKeyDown={onKeyDown}>
          {React.Children.toArray(this.props.children)}
        </VanillaStyleWrapper>
      )}
      </div>
    );
  }
}
