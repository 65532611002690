import styled from 'styled-components';

export const InputLabel = styled.div`
  font-size: 14px;
  margin-bottom: 0.3rem;
  line-height: 1.4em;
  letter-spacing: 0.5px;
  text-align: left;
`;

export const CustomTableData = styled.td`
  &.trialed {
    color: red;
  }
`;
