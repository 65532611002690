import styled from 'styled-components';

export default styled.div`
  align-self: center;
  margin-right: 0.2rem;
  white-space: ${(props) => (props.wrapText ? `normal` : 'nowrap')};
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;
