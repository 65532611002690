import styled from 'styled-components';

export const DetailsPane = styled.div`
  position: fixed;
  top: 48px;
  right: ${props => (props.isExpanded ? '0' : '-65%')};
  width: 65%;
  height: 93%;
  background-color: #fff;
  border-left: 1px solid #afb6bd;
  box-shadow: -1px 0 3px 0 rgba(0, 0, 0, 0.1);
  transition: right 500ms ease-in-out 0s;
  overflow-x: hidden;
`;
