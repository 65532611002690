/* eslint-disable no-plusplus */
/* eslint-disable no-loop-func */
/* eslint-disable radix */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-useless-escape */
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import styled from 'styled-components/dist/styled-components';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Grid,
  Modal,
  ModalHeader,
  useLayout,
  ListPaneHeader,
  Input, Button, LoadingIndicator, Checkbox, ModalBody
} from 'ui-core/dist';
import { SelectDropdown, AnchorInput } from '../../components';
import LoadingComponent from '../../components/LoadingComponent';
import ErrorPage from '../ErrorPage/components/ErrorPage';
import useNotificationBannerHook from '../../useNotificationBannerHook';
import ModalWrapper from '../../components/Modal/components/ModalWrapper';
import { useGetClientAndLayoutInfo, useSaveOrUpdateClientAndLayoutInfo } from '../../api/useEMDFinManagerClientInfo';

const StyledHeader = styled.h1`
cursor: default;
tab-size: 4;
-webkit-tap-highlight-color: transparent /* 4 */;
-webkit-text-size-adjust: 100%;
word-break: break-word;
box-sizing: border-box;
text-overflow: ellipsis;
overflow: hidden;
white-space: nowrap;
font-family: Core Sans C;
font-style: normal;
color: #0F0F59;
font-weight: 600;
font-size: 20px;
line-height: 140%;
margin: 20px 12px 20px 0;
`;
const StyledLayoutContainer = styled.table`
& tr td input[type="text"] {
  width: 200px !important;
}
`;
const StyledContainer = styled.table`
width: 74% !important;
& tr td:first-child {
    width: 300px !important;
    & > label {
      width: 300px !important;
    }
}
& tr td input {
    width: 250px !important;
}
& tr td.checkbox {
  width: 50px !important;
  & > span:first-child {
    width: 16px !important;
  }
}
`;
const ClientInfo = ({ onCloseClientInfo, onResetClick, client, layout, showClientInfo, clearState }) => {
  const {
    expandCollapseListPaneButtonConfig
  } = useLayout();
  const { notify } = useNotificationBannerHook();
  const activeUserId = useSelector(({ session }) => session.userId);
  const { isLoading: isLoadinggetClientAndLayoutInfo, isError: isErrorgetClientAndLayoutInfo, error: errorgetClientAndLayoutInfo,
    postData: getClientAndLayoutInfo, data: getClientAndLayoutInfoResp, metaData: getClientAndLayoutInfoMetadata } = useGetClientAndLayoutInfo();

  const { isLoading: isLoadingsaveOrUpdateClientAndLayoutInfo, isError: isErrorsaveOrUpdateClientAndLayoutInfo, error: errorsaveOrUpdateClientAndLayoutInfo,
    postData: saveOrUpdateClientAndLayoutInfo, data: saveOrUpdateClientAndLayoutInfoResp, metaData: saveOrUpdateClientAndLayoutInfoMetadata } = useSaveOrUpdateClientAndLayoutInfo();

  const availableClaimTypes = [{
    label: 'Default', value: '0'
  }, {
    label: 'Billing For Doc Process', value: '1'
  }, {
    label: 'HCFA/(UB + EOB)', value: '2'
  }];
  const [isLoading, setisLoading] = useState(true);
  const [id, setid] = useState('');
  const [number, setnumber] = useState('');
  const [name, setname] = useState('');
  const [parentClientId, setparentClientId] = useState('');
  const [sortByCopyNumber, setsortByCopyNumber] = useState(false);
  const [sortByRecipientType, setsortByRecipientType] = useState(false);
  const [sortByDocumentType, setsortByDocumentType] = useState(false);
  const [cpc50Percent, setcpc50Percent] = useState(false);
  const [cpcMaximumPercentage, setcpcMaximumPercentage] = useState('');
  const [sortByExtraInserts, setsortByExtraInserts] = useState(false);
  const [maximumClaimsPerPayemnt, setmaximumClaimsPerPayemnt] = useState('');
  const [isDeactive, setisDeactive] = useState(false);
  const [claimTypes, setclaimTypes] = useState("0");
  const [claimDesc1, setclaimDesc1] = useState('');
  const [claimDesc2, setclaimDesc2] = useState('');
  const [postage, setpostage] = useState(false);
  const [suppressBilling, setsuppressBilling] = useState(false);
  const [clientLayouts, setclientLayouts] = useState([]);
  const [siblingClients, setsiblingClients] = useState('');
  const [isOpenConfirmationModal, setisOpenConfirmationModal] = useState(false);
  const [defaultLayoutId, setdefaultLayoutId] = useState("0");
  const [count, setcount] = useState(0);

  const onCloseConfirmationModal = () => {
    setisOpenConfirmationModal(false);
  };
  const onResetForm = () => {
    if (client) {
      setid('');
      setnumber('');
      setname('');
      setparentClientId('');
      setsortByCopyNumber(false);
      setsortByRecipientType(false);
      setsortByDocumentType(false);
      setcpc50Percent(false);
      setcpcMaximumPercentage('');
      setsortByExtraInserts(false);
      setmaximumClaimsPerPayemnt('');
      setisDeactive(false);
      setclaimTypes("0");
      setclaimDesc1('');
      setclaimDesc2('');
      setpostage(false);
      setsuppressBilling(false);
      setclientLayouts(JSON.parse(JSON.stringify([])));
      setsiblingClients('');
      setisOpenConfirmationModal(false);
      setdefaultLayoutId("0");
    }
  };
  const onClearForm = () => {
    if (client && layout) {
      setsortByCopyNumber(false);
      setsortByRecipientType(false);
      setsortByDocumentType(false);
      setcpc50Percent(false);
      setsortByExtraInserts(false);
      setisDeactive(false);
      setpostage(false);
      setsuppressBilling(false);
    }
  };
  useEffect(() => {
    if (client && showClientInfo) {
      onResetForm();
      const paramXML = `<![CDATA[<params><client>${client}</client><layout>${layout || ''}</layout></params>]]>`;
      getClientAndLayoutInfo({ paramXML, count });
      setcount(count + 1);
    }
  }, [client, layout, showClientInfo]);
  useEffect(() => {
    if (isErrorgetClientAndLayoutInfo && errorgetClientAndLayoutInfo) {
      setisLoading(false);
      onResetForm();
      setclientLayouts(JSON.parse(JSON.stringify([])));
      if (client) setnumber(client);
      setdefaultLayoutId("0");
      clearState();
    }
  }, [isErrorgetClientAndLayoutInfo, errorgetClientAndLayoutInfo]);
  useEffect(() => {
    if (getClientAndLayoutInfoMetadata) {
      const { status, statusText } = getClientAndLayoutInfoMetadata;
      if (status === 200 || statusText === "OK") {
        if (getClientAndLayoutInfoResp) {
          if (getClientAndLayoutInfoResp.fs) {
            const { fs } = getClientAndLayoutInfoResp;
            if (fs) {
              notify(fs, 'negative');
              onResetForm();
              setclientLayouts(JSON.parse(JSON.stringify([])));
              return;
            }
          }
          const { data } = getClientAndLayoutInfoResp;
          if (data && data?.client) {
            const { layouts } = data?.client;
            const layoutp = layouts?.layout || null;
            setclaimDesc1(data?.client.claimDesc1);
            setclaimDesc2(data?.client.claimDesc2);
            setclaimTypes(data?.client.claimTypes);
            setcpc50Percent(data?.client.cpc50Percent && data?.client.cpc50Percent === "1" ? true : false);
            setcpcMaximumPercentage(data?.client.cpcMaxPercent);
            setisDeactive(data?.client.isDeactivate ? true : false);
            setmaximumClaimsPerPayemnt(data?.client.maxClaimsPerPayment);
            setid(data?.client.id);
            setname(data?.client.name);
            setnumber(data?.client.number);
            setparentClientId(data?.client.parentClientId);
            setpostage(data?.client.postage && data?.client.postage === "1" ? true : false);
            setsiblingClients(data?.client.siblingClients);
            setsortByCopyNumber(data?.client.sortByCopyNumber && data?.client.sortByCopyNumber === "1" ? true : false);
            setsortByDocumentType(data?.client.sortByDocumentType && data?.client.sortByDocumentType === "1" ? true : false);
            setsortByExtraInserts(data?.client.sortByExtraInserts && data?.client.sortByExtraInserts === "1" ? true : false);
            setsortByRecipientType(data?.client.sortByRecipientType && data?.client.sortByRecipientType === "1" ? true : false);
            setsuppressBilling(data?.client.suppressBilling && data?.client.suppressBilling === "1" ? true : false);
            if (layoutp && layoutp.length > 0) {
              const temp = layoutp.map((item) => ({
                id: item?.id || "",
                layout: item?.layoutNum || "",
                ebillHierarchyLevel: item?.ebillHierLevel || ""
              }));
              setclientLayouts(JSON.parse(JSON.stringify(temp)));
              const f = layoutp.find(x => (x.layoutNum || "") === "");
              if (f) {
                setdefaultLayoutId(f?.id || "");
              }
            }
            setisLoading(false);
            clearState();
          }
        }
      } else {
        notify('Error occurred while fetching Client Info', 'negative');
        onResetForm();
        setclientLayouts(JSON.parse(JSON.stringify([])));
        clearState();
      }
    }
  }, [getClientAndLayoutInfoMetadata, getClientAndLayoutInfoResp]);
  useEffect(() => {
    if (isErrorsaveOrUpdateClientAndLayoutInfo && errorsaveOrUpdateClientAndLayoutInfo) {
      const { displayMessage } = errorsaveOrUpdateClientAndLayoutInfo;
      if (displayMessage) {
        notify(displayMessage, 'negative');
        onCloseConfirmationModal();
        return;
      }
    }
  }, [isErrorsaveOrUpdateClientAndLayoutInfo, errorsaveOrUpdateClientAndLayoutInfo]);
  useEffect(() => {
    if (saveOrUpdateClientAndLayoutInfoMetadata) {
      const { status, statusText } = saveOrUpdateClientAndLayoutInfoMetadata;
      if (status === 200 || statusText === "OK") {
        if (saveOrUpdateClientAndLayoutInfoResp && saveOrUpdateClientAndLayoutInfoResp.fs) {
          const { fs } = saveOrUpdateClientAndLayoutInfoResp;
          if (fs) {
            notify(fs, 'negative');
            onCloseConfirmationModal();
            return;
          }
        }
        notify("Client Info updated successfully", "positive");
        onCloseConfirmationModal();
        // onResetClick()
        onCloseClientInfo();
      } else {
        notify("There were errors processing you request", 'negative');
        onCloseConfirmationModal();
      }
    }
  }, [saveOrUpdateClientAndLayoutInfoMetadata, saveOrUpdateClientAndLayoutInfoResp]);
  const validateNumber = useCallback(() => {
    if (!number) {
      return true;
    }
    return (/^\d+$/.test(number) ? false : true);
  }, [number]);
  const numberErrorMessage = useCallback(() => {
    if (!number) {
      return "Client Number is required";
    }
    return (/^\d+$/.test(number) ? '' : "Client Number must be numeric");
  }, [number]);

  const validateParentClientId = useCallback(() => {
    if (!parentClientId) {
      return false;
    }
    return (/^\d+$/.test(parentClientId) ? false : true);
  }, [parentClientId]);

  const parentClientIdErrorMessage = useCallback(() => {
    if (!parentClientId) {
      return "";
    }
    return (/^\d+$/.test(parentClientId) ? false : "Parent Client Number must be numeric");
  }, [parentClientId]);

  const validatecpcMaximumPercentage = useCallback(() => {
    if (!cpcMaximumPercentage) {
      return true;
    }
    return (/^\d+$/.test(cpcMaximumPercentage) ? false : true);
  }, [cpcMaximumPercentage]);

  const cpcMaximumPercentageErrorMessage = useCallback(() => {
    if (!cpcMaximumPercentage) {
      return "CPC Maximum Percentage is required";
    }
    return (/^\d+$/.test(cpcMaximumPercentage) ? false : "CPC Maximum Percentage must be numeric");
  }, [cpcMaximumPercentage]);

  const validateForm = useMemo(() => {
    if (validateNumber() || validateParentClientId() || validatecpcMaximumPercentage()) {
      return false;
    }
    return true;
  }, [number, parentClientId, cpcMaximumPercentage]);

  const onSubmitClick = () => {
    setisOpenConfirmationModal(true);
  };
  const onConfirmationYesClick = () => {
    let hasEmptyLayout = false;
    const layForms = [];
    let valid = true;
    const temp = JSON.parse(JSON.stringify(clientLayouts));
    for (const obj of temp) {
      if ((obj?.layout || "") === "") {
        if (hasEmptyLayout && (obj?.ebillHierarchyLevel || "") !== "") {
          notify("Can not add/modify a default layout because it already exists.", 'negative');
          valid = false;
          break;
        } else
          hasEmptyLayout = true;
      } else if ((obj?.layout || "") !== "") {
        if (layForms.find(x => x?.layout === obj?.layout)) {
          notify("Can not modify a layout to another existing layout.", "negative");
          valid = false;
          break;
        } else {
          layForms.push({ ...obj });
        }
      }
    }
    if (valid) {
      const tempName = name?.replaceAll('&', '&amp;');
      let dataXML = `<![CDATA[<data><client><id>${id || ""}</id><number>
      ${number || ""}</number><name>${tempName || ""}</name><parentClientId>
      ${parentClientId || ""}</parentClientId><sortByCopyNumber>
      ${sortByCopyNumber ? "1" : "0"}</sortByCopyNumber><sortByRecipientType>
      ${sortByRecipientType ? "1" : "0"}</sortByRecipientType><sortByDocumentType>
      ${sortByDocumentType ? "1" : "0"}</sortByDocumentType><cpc50Percent>
      ${cpc50Percent ? "1" : "0"}</cpc50Percent><cpcMaxPercent>
      ${cpcMaximumPercentage || ""}</cpcMaxPercent><sortByExtraInserts>
      ${sortByExtraInserts ? "1" : "0"}</sortByExtraInserts><maxClaimsPerPayment>
      ${maximumClaimsPerPayemnt || ""}</maxClaimsPerPayment><isDeactivate>
      ${isDeactive ? "1" : "0"}</isDeactivate><userId>${activeUserId}</userId><claimTypes>
      ${claimTypes || ""}</claimTypes><claimDesc1>${claimDesc1 || ""}</claimDesc1>
      <claimDesc2>${claimDesc2 || ""}</claimDesc2><suppressBilling>
      ${suppressBilling ? "1" : "0"}</suppressBilling><postage>
      ${postage ? "1" : "0"}</postage><layouts>`;
      for (const layForm of layForms) {
        dataXML = dataXML + `<layout><id>${layForm?.id || ""}</id><layoutNum>
        ${layForm?.layout || ""}</layoutNum><ebillHierLevel>
        ${layForm?.ebillHierarchyLevel || ""}</ebillHierLevel></layout>`;
      }
      dataXML = dataXML + "</layouts></client></data>]]>";
      saveOrUpdateClientAndLayoutInfo({ dataXML, count });
      setcount(count + 1);
      return;
    }
    setisOpenConfirmationModal(false);
  };
  const renderNumber = useMemo(() => (
    <Input
      dataTestId="number"
      domID="number"
      size="medium"
      maxLength="4"
      label=""
      disabled
      errorMessage={numberErrorMessage()}
      hasError={validateNumber()}
      initialValue={number}
      onChange={(e) => {
        if (setnumber) setnumber(e.target.value);
      }}
    />
  ), [number]);
  const renderName = useMemo(() => (
    <Input
      domID="name"
      label=""
      maxLength="50"
      size="small"
      initialValue={name}
      onChange={(e) => {
        if (setname) setname(e.target.value);
      }}
    />
  ), [name]);
  const renderParentClientId = useMemo(() => (
    <Input
      dataTestId="parentClientId"
      domID="parentClientId"
      size="medium"
      maxLength="4"
      label=""
      errorMessage={parentClientIdErrorMessage()}
      hasError={validateParentClientId()}
      initialValue={parentClientId}
      onChange={(e) => {
        if (setparentClientId) setparentClientId(e.target.value);
      }}
    />
  ), [parentClientId]);
  const renderSiblingClients = useMemo(() => (
    <Input
      domID="siblingClients"
      label=""
      maxLength="50"
      disabled
      size="small"
      initialValue={siblingClients}
      onChange={(e) => {
        if (setsiblingClients) setsiblingClients(e.target.value);
      }}
    />
  ), [siblingClients]);
  const renderSortByCopyNumber = useMemo(() => (
    <Checkbox
      domID="sortByCopyNumber"
      size="large"
      checked={sortByCopyNumber}
      onChange={() => {
        setsortByCopyNumber(!sortByCopyNumber);
      }}
    />
  ), [sortByCopyNumber]);
  const renderSortByRecipientType = useMemo(() => (
    <Checkbox
      domID="sortByRecipientType"
      size="large"
      checked={sortByRecipientType}
      onChange={() => {
        setsortByRecipientType(!sortByRecipientType);
      }}
    />
  ), [sortByRecipientType]);
  const renderSortByDocumentType = useMemo(() => (
    <Checkbox
      domID="sortByDocumentType"
      size="large"
      checked={sortByDocumentType}
      onChange={() => {
        setsortByDocumentType(!sortByDocumentType);
      }}
    />
  ), [sortByDocumentType]);
  const renderSortByExtraInserts = useMemo(() => (
    <Checkbox
      domID="sortByExtraInserts"
      size="large"
      checked={sortByExtraInserts}
      onChange={() => {
        setsortByExtraInserts(!sortByExtraInserts);
      }}
    />
  ), [sortByExtraInserts]);
  const renderCpc50percent = useMemo(() => (
    <Checkbox
      domID="cpc50Percent"
      size="large"
      checked={cpc50Percent}
      onChange={() => {
        setcpc50Percent(!cpc50Percent);
      }}
    />
  ), [cpc50Percent]);
  const renderCpcMaximumPercentage = useMemo(() => (
    <Input
      domID="cpcMaximumPercentage"
      label=""
      maxLength="15"
      size="small"
      errorMessage={cpcMaximumPercentageErrorMessage()}
      hasError={validatecpcMaximumPercentage()}
      initialValue={cpcMaximumPercentage}
      onChange={(e) => {
        if (setcpcMaximumPercentage) setcpcMaximumPercentage(e.target.value);
      }}
    />
  ), [cpcMaximumPercentage]);
  const rendermaximumClaimsPerPayment = useMemo(() => (
    <Input
      domID="maximumClaimsPerPayemnt"
      label=""
      maxLength="4"
      size="small"
      initialValue={maximumClaimsPerPayemnt}
      onChange={(e) => {
        if (setmaximumClaimsPerPayemnt) setmaximumClaimsPerPayemnt(e.target.value);
      }}
    />
  ), [maximumClaimsPerPayemnt]);
  const renderIsDeactive = useMemo(() => (
    <Checkbox
      domID="isDeactive"
      size="large"
      checked={isDeactive}
      onChange={() => {
        setisDeactive(!isDeactive);
      }}
    />
  ), [isDeactive]);
  useEffect(() => {
    if (claimTypes) {
      if (claimTypes === "0") {
        setclaimDesc1('');
        setclaimDesc2('');
      } else if (claimTypes === "1") {
        setclaimDesc2('');
      }
    }
  }, [claimTypes]);
  const renderClaimTypes = useMemo(() => (
    <SelectDropdown
      width="250px"
      domID="defer_state_input"
      isClearable={false}
      label=""
      options={availableClaimTypes}
      size="small"
      initialValue={{ label: availableClaimTypes.find(x => x.value === claimTypes)?.label, value: claimTypes }}
      onChange={(e) => {
        if (e) {
          if (setclaimTypes) setclaimTypes(e.value);
        } else if (setclaimTypes) setclaimTypes(null);
      }}
    />
  ), [claimTypes]);
  const renderClaimDesc1 = useMemo(() => (
    <Input
      domID="claimDesc1"
      label=""
      disabled={claimTypes === "0"}
      size="small"
      initialValue={claimDesc1}
      onChange={(e) => {
        if (setclaimDesc1) setclaimDesc1(e.target.value);
      }}
    />
  ), [claimDesc1, claimTypes]);
  const renderClaimDesc2 = useMemo(() => (
    <Input
      domID="claimDesc2"
      label=""
      disabled={claimTypes === "0" || claimTypes === "1"}
      size="small"
      initialValue={claimDesc2}
      onChange={(e) => {
        if (setclaimDesc2) setclaimDesc2(e.target.value);
      }}
    />
  ), [claimDesc2, claimTypes]);
  const renderSupressBilling = useMemo(() => (
    <Checkbox
      domID="suppressBilling"
      size="large"
      checked={suppressBilling}
      onChange={() => {
        setsuppressBilling(!suppressBilling);
      }}
    />
  ), [suppressBilling]);
  const renderPostage = useMemo(() => (
    <Checkbox
      domID="postage"
      size="large"
      checked={postage}
      onChange={() => {
        setpostage(!postage);
      }}
    />
  ), [postage]);
  const onChangeHeirarchyLevel = (value, index) => {
    if (clientLayouts && clientLayouts.length > 0) {
      const temp = JSON.parse(JSON.stringify(clientLayouts));
      const f = temp[index];
      if (f) {
        f.ebillHierarchyLevel = value;
        setclientLayouts(JSON.parse(JSON.stringify(temp)));
      }
    }
  };
  const onChangeClientLayout = (value, index) => {
    if (clientLayouts && clientLayouts.length > 0) {
      const temp = JSON.parse(JSON.stringify(clientLayouts));
      const f = temp[index];
      if (f) {
        f.layout = value;
        setclientLayouts(JSON.parse(JSON.stringify(temp)));
      }
    }
  };
  const onAddLayout = () => {
    const temp = JSON.parse(JSON.stringify(clientLayouts)) || [];
    for (let i = 0; i < 5; i++) {
      temp.push({ id: null, layout: null, ebillHierarchyLevel: null });
    }
    setclientLayouts(JSON.parse(JSON.stringify(temp)));
  };
  const renderSingleLayout = (cl, index) => {
    if (!Object.prototype.hasOwnProperty.call(cl, 'layout')) {
      return (
        <Input
          domID={`cl_layout_${index}`}
          label=""
          size="small"
          initialValue={cl?.layout || ""}
          onChange={(e) => {
            if (onChangeClientLayout) onChangeClientLayout(e.target.value, index);
          }}
        />
      );
    }
    if ((cl?.layout || "") === "") {
      if ((cl?.id || "")) {
        if ((cl?.id || "") === (defaultLayoutId || "")) {
          return (
            <Input
              domID={`cl_layout_${index}`}
              label=""
              size="small"
              initialValue={cl?.layout || ""}
              disabled={true}
              onChange={(e) => {
                if (onChangeClientLayout) onChangeClientLayout(e.target.value, index);
              }}
            />
          );
        }
        return (
          <Input
            domID={`cl_layout_${index}`}
            label=""
            size="small"
            initialValue={cl?.layout || ""}
            onChange={(e) => {
              if (onChangeClientLayout) onChangeClientLayout(e.target.value, index);
            }}
          />
        );
      }
      return (
        <Input
          domID={`cl_layout_${index}`}
          label=""
          size="small"
          initialValue={cl?.layout || ""}
          onChange={(e) => {
            if (onChangeClientLayout) onChangeClientLayout(e.target.value, index);
          }}
        />
      );
    }
    return (
      <Input
        domID={`cl_layout_${index}`}
        label=""
        size="small"
        initialValue={cl?.layout || ""}
        onChange={(e) => {
          if (onChangeClientLayout) onChangeClientLayout(e.target.value, index);
        }}
      />
    );
  };
  const renderClientLayouts = useMemo(() => (
    <StyledLayoutContainer border="0">
      <th><label>Layout</label></th><th><label>Ebill Hierarchy Level</label></th>
      <th><div className='marginLeft'>
        <Button
          id='addLayouts'
          size="small"
          name="Add Layouts"
          onClick={() => onAddLayout()}
        />
      </div></th>
      {clientLayouts && clientLayouts.length > 0 ? (
        <>
          {clientLayouts.map((cl, index) => (
            <tr>
              <td>
                {renderSingleLayout(cl, index)}
              </td>
              <td><Input
                domID={`cl_hierarchy_${index}`}
                label=""
                size="small"
                initialValue={cl?.ebillHierarchyLevel || ""}
                onChange={(e) => {
                  if (onChangeHeirarchyLevel) onChangeHeirarchyLevel(e.target.value, index);
                }}
              /></td>
            </tr>
          ))}
        </>
      ) : null}
    </StyledLayoutContainer>
  ), [clientLayouts, defaultLayoutId]);
  const renderConfirmationModal = useMemo(() => (
    <Modal isOpen={isOpenConfirmationModal}>
      <ModalHeader
        title={`Do you want to Save ?`}
        onClose={() => setisOpenConfirmationModal(false)}
      />
      <ModalBody>
        {isOpenConfirmationModal ? (
          <div style={{ marginLeft: '34px', marginBottom: '20px' }} className="marginLeft">
            <div className="displayFlex">
              <div className="marginLeft">
                <Button
                  domID="btn_exclYes"
                  buttonType="standard"
                  size="small"
                  name="Yes"
                  onClick={() => onConfirmationYesClick()}
                />
              </div>
              <div className="marginLeft">
                <Button
                  domID="btn_exclNo"
                  buttonType="standard"
                  size="small"
                  name="No"
                  onClick={() => setisOpenConfirmationModal(false)}
                />
              </div>
            </div>
          </div>
        ) : null}
      </ModalBody>
    </Modal>
  ), [isOpenConfirmationModal]);
  return (
    <>
      <div className="marginLeft">
        <StyledHeader>Finance Manager: Client Info</StyledHeader>
      </div>
      <>
        {isLoadinggetClientAndLayoutInfo || isLoadingsaveOrUpdateClientAndLayoutInfo ? (
          // replace with a better loading component            
          <div style={{ marginLeft: '50%' }}><LoadingComponent /></div>
        ) : (
          <>
            {client && !isLoading ? (
              <div className="marginLeft">
                <table>
                  <tr>
                    <td><span className="required-glyph">
                      <label>Modify the information as desired, and then click Save to update Client Info.</label></span></td>
                  </tr>
                  <tr>
                    <td><span className="required-glyph"><label>* Indicates a required field.</label></span></td>
                  </tr>
                  <tr><p></p></tr>
                  <tr>
                    <StyledContainer border="0">
                      <tr>
                        <td className="name"><label>Client Number:&#160;<span className="required-glyph">*</span></label></td>
                        <td className="value">
                          {renderNumber}
                        </td>
                      </tr>
                      <tr><p></p></tr>
                      <tr>
                        <td className="name"><label>Name:</label></td>
                        <td className="value">
                          {renderName}
                        </td>
                      </tr>
                      <tr><p></p></tr>
                      <tr>
                        <td className="name"><label>Parent Client Number: </label></td>
                        <td className="value">
                          {renderParentClientId}
                        </td>
                      </tr>
                      <tr><p></p></tr>
                      <tr>
                        <td className="name"><label>Clients with Same Parent: </label></td>
                        <td className="value">
                          {renderSiblingClients}
                        </td>
                      </tr>
                      <tr><p></p></tr>
                      <tr>
                        <td className="name"><label>Sort By Copy Number: </label></td>
                        <td className="checkbox">
                          {renderSortByCopyNumber}
                        </td>
                      </tr>
                      <tr><p></p></tr>
                      <tr>
                        <td className="name"><label>Sort By Recipient Type: </label></td>
                        <td className="checkbox">
                          {renderSortByRecipientType}
                        </td>
                      </tr>
                      <tr><p></p></tr>
                      <tr>
                        <td className="name"><label>Sort By Document Type: </label></td>
                        <td className="checkbox">
                          {renderSortByDocumentType}
                        </td>
                      </tr>
                      <tr><p></p></tr>
                      <tr>
                        <td className="name"><label>Sort By Extra Inserts: </label></td>
                        <td className="checkbox">
                          {renderSortByExtraInserts}
                        </td>
                      </tr>
                      <tr><p></p></tr>
                      <tr>
                        <td className="name"><label>CPC 50%: </label></td>
                        <td className="checkbox">
                          {renderCpc50percent}
                        </td>
                      </tr>
                      <tr><p></p></tr>
                      <tr>
                        <td className="name"><label>CPC Maximum Percentage:&#160;<span className="required-glyph">*</span></label></td>
                        <td className="value">
                          {renderCpcMaximumPercentage}
                        </td>
                      </tr>
                      <tr><p></p></tr>
                      <tr>
                        <td className="name"><label>Maximum Claims Per Payment: </label></td>
                        <td className="value">
                          {rendermaximumClaimsPerPayment}
                        </td>
                      </tr>
                      <tr><p></p></tr>
                      <tr>
                        <td className="name"><label>Deactivate: </label></td>
                        <td className="checkbox">
                          {renderIsDeactive}
                        </td>
                      </tr>
                      <tr><p></p></tr>
                      <tr>
                        <td className="name"><label>Billing Types:</label></td>
                        <td className="value">
                          {renderClaimTypes}
                        </td>
                      </tr>
                      <tr><p></p></tr>
                      <tr>
                        <td className="name"><label>Billing Type Item 1 Description: </label></td>
                        <td className="value">
                          {renderClaimDesc1}
                        </td>
                      </tr>
                      <tr><p></p></tr>
                      <tr>
                        <td className="name"><label>Billing Type Item 2 Description: </label></td>
                        <td className="value">
                          {renderClaimDesc2}
                        </td>
                      </tr>
                      <tr><p></p></tr>
                      <tr>
                        <td className="name"><label>Suppress Oracle Billing: </label></td>
                        <td className="checkbox">
                          {renderSupressBilling}
                        </td>
                      </tr>
                      <tr><p></p></tr>
                      <tr>
                        <td className="name"><label>Include Postage: </label></td>
                        <td className="checkbox">
                          {renderPostage}
                        </td>
                      </tr>
                      <tr><p></p></tr>
                      <tr>
                        <td className="name"><label>Layout Information: </label></td>
                        <td className="value">
                          {renderClientLayouts}
                        </td>
                      </tr>
                    </StyledContainer>
                    <table>
                      <tr><p></p></tr>
                      <tr>
                        <td className="value" colspan="2">
                          <div className='displayFlex'>
                            <Button
                              id='save'
                              size="small"
                              name="Save"
                              disabled={!validateForm}
                              onClick={() => onSubmitClick()}
                            />
                            {/* <div className='marginLeft'>
                              <Button
                                id='clear'
                                size="small"
                                name="Clear"
                                onClick={()=>onClearForm()}
                              />
                            </div> */}
                            <div className='marginLeft'>
                              <Button
                                id='cancel'
                                size="small"
                                name="Cancel"
                                onClick={() => onCloseClientInfo()}
                              />
                            </div>
                          </div>
                          &nbsp;</td>
                      </tr>
                    </table>
                  </tr>
                </table>
                {renderConfirmationModal}
              </div>
            ) : null}
          </>
        )
        }
      </>
    </>
  );
};

export default ClientInfo;