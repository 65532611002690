export const pathMapOptions = [
  [ "Reserve/Modify Map", "/EtfMapManager/ReserveMap?param=M" ],
  [ "Deploy Test Map", "/EtfMapManager/DeployMap?param=M" ],
  [ "Commit Production Maps", "/EtfMapManager/CommitMap?param=M" ],
  // [ "Accounts In Error", "/AchManager/AccountsInError",],
  // [ "Payee Maintenance", "/AchManager/PayeeMaint",],
  [ "Transmission History", "/AchManager/TransHist",],
  [ "Delivery Addresses", "/AddressManager/Delivery", ],
  [ "Return Addresses", "/AddressManager/Return", ],
  [ "835 Map Manager", "/CCFManager/835map_manager", ],
  [ "Form Manager", "/CCFManager/form_manager", ],
  [ "Image Manager", "/CCFManager/image_manager", ],
  [ "Address Correction Exclusion", "/ClientAdministration/AddressCorrectionExclusion", ],
  [ "Claim Type Translation", "/ClientAdministration/ClaimTypeTranslation", ],
  [ "Client Email Editor", "/ClientAdministration/ClientEmail", ],
  [ "Associate Reports Editor", "/ClientAdministration/ClientInformationManager/associate_reports", ],
  [ "Client Information Editor", "/ClientAdministration/ClientEditorSettings?grouping=1", ],
  [ "Contact Editor", "/ClientAdministration/ClientInformationManager/contact_editor", ],
  [ "Client Internal Rules", "/ClientAdministration/InternalRules", ],
  [ "Associate Brokers Editor", "/ClientAdministration/ClientNCOAPAFManager/associate_brokers_editor", ],
  [ "Broker Editor", "/ClientAdministration/ClientNCOAPAFManager/broker_editor", ],
  [ "PAF Editor", "/ClientAdministration/ClientNCOAPAFManager/paf_editor", ],
  [ "Data Handling", "/ClientAdministration/ClientEditorSettings?grouping=0", ],
  [ "Client Settings", "/ClientAdministration/ClientEditorSettings?grouping=4", ],
  [ "Print Handling", "/ClientAdministration/ClientEditorSettings?grouping=3", ],
  [ "Group Rules", "/ClientAdministration/ClientEditorSettings?grouping=5", ],
  [ "Document Type Translation", "/ClientAdministration/DocumentTypeTranslation", ],
  [ "Recipient Type Translation", "/ClientAdministration/RecipientTypeTranslation", ],
  [ "Remark Code Translation", "/ClientAdministration/RemarkCode", ],
  [ "Terrorist Watch Exclusion", "/ClientAdministration/TerroristWatchExclusion", ],
  [ "1099 State Copy", "/ClientRulesManager/StateCopy1099", ],
  [ "Check Account Info", "/ClientRulesManager/CheckAcctInfo", ],
  [ "Client Payer Eft Manager", "/ClientRulesManager/PayerEftAccount", ],
  [ "Draft Code", "/ClientRulesManager/DraftCode", ],
  [ "Form Translation", "/ClientRulesManager/FormTranslation", ],
  [ "Image Translation", "/ClientRulesManager/ImageTranslation", ],
  [ "Ineligible Codes", "/ClientRulesManager/IneligibleCodes", ],
  [ "Override File Cutoff", "/DocumentManager/FileOverride", ],
  [ "Search", "/DocumentManager/Search", ],
  [ "History", "/DocumentManager/History", ],
  [ "Add EEOB Form", "/EEOBFormManager/add", ],
  [ "Modify EEOB Form", "/EEOBFormManager/modify", ],
  ["Auto Hold", "/GroupRuleManager?ruletype=HLD"],
  ["Check Text Information", "/GroupRuleManager?ruletype=CHT"],
  ["Customer Service Messages", "/GroupRuleManager?ruletype=CSM"],
  ["Duplex Definition", "/GroupRuleManager?ruletype=DPX"],
  ["Erisa Messages", "/GroupRuleManager?ruletype=ERI"],
  ["General Messages", "/GroupRuleManager?ruletype=GEN"],
  ["PDF Tagging", "/GroupRuleManager?ruletype=PDT"],
  ["High Dollar Payment Routing", "/GroupRuleManager?ruletype=HDR"],
  ["Image Definition", "/GroupRuleManager?ruletype=IMG"],
  ["Member ID Routing", "/GroupRuleManager?ruletype=SSN"],
  ["Provider Insured Optional Messages", "/GroupRuleManager?ruletype=PIO"],
  ["Return Address", "/GroupRuleManager?ruletype=RAD"],
  ["Signatures", "/GroupRuleManager?ruletype=SIG"],
  ["Tax ID Routing", "/GroupRuleManager?ruletype=TAX"],
  ["Template and Delivery Assignments", "/GroupRuleManager?ruletype=PAR"],
  [ "All Groups", "/EmployerGroupManager?group=all", ],
  [ "Active Groups", "/EmployerGroupManager?group=active", ],
  [ "InActive Groups", "/EmployerGroupManager?group=inactive", ],
  [ "Termed Groups", "/EmployerGroupManager?group=termed", ],
  [ "Reserve/Modify Form", "/FormManager/ReserveForm?param=0", ],
  [ "Deploy Test Forms", "/FormManager/DeployForm?param=0", ],
  [ "Commit Production Forms", "/FormManager/CommitForm?param=0", ],
  [ "Reserve/Modify Image", "/ImageManager/ReserveImage?param=1", ],
  [ "Deploy Test Images", "/ImageManager/DeployImage?param=1", ],
  [ "Commit Production Images", "/ImageManager/CommitImage?param=1", ],
  [ "Manifest Entry", "/ManifestInformationManager/ManifestInformationEntry", ],
  [ "Manifest Wire Date", "/ManifestInformationManager/ManifestWireDate", ],
  [ "Manual Insertion Data Manager", "/ManifestInformationManager/ManualJobInsertionData", ],
  [ "Home", "/IDCardWelcomeKitManager/home", ],
  [ "Document Manager Search", "/IDCardWelcomeKitManager/DocumentManager", ],
  [ "ACS Report", "/ReportManager/ACSReport", ],
  [ "Billing Report", "/ReportManager/BillingReport", ],
  [ "Checks Cleared Report", "/ReportManager/ChecksClearedReport", ],
  [ "Client Image Report", "/ReportManager/ClientImageReport", ],
  [ "Client Information Report", "/ReportManager/ClientInfo", ],
  [ "Client Specific Reports", "/ReportManager/GenericReports", ],
  [ "Data Script Report", "/ReportManager/DataScript", ],
  [ "EFT History Report", "/ReportManager/EFTHistory", ],
  [ "EFT Provider Report", "/ReportManager/EftProvider", ],
  [ "Group Rules History Report", "/ReportManager/Reports", ],
  [ "Mail Delivery Report", "/ReportManager/MailDeliveryReport", ],
  [ "Off Hours Activity Report", "/ReportManager/OffHoursActivity", ],
  [ "On Hold Report", "/ReportManager/OnHoldReport", ],
  [ "Unauthorized Access Report", "/ReportManager/UnauthorizedAccess", ],
  [ "US Delivery Report", "/ReportManager/USDelivery", ],
  [ "User Report", "/ReportManager/UserReport", ],
  [ "Vendor Layout Report", "/ReportManager/VendorLayout", ],
  [ "Active User List", "/SecurityManager/ActiveUsers", ],
  [ "Data Source Manager", "/SecurityManager/DataSourceManager", ],
  [ "Role Manager", "/SecurityManager/RoleManager", ],
  [ "User Manager", "/SecurityManager/UserManager", ],
  [ "Message Manager", "/SystemAdministration/MessageManager", ],
  [ "Server Process Detail", "/SystemAdministration/serverDetails", ],
  [ "Update Document Status", "/SystemAdministration/MassUpdate", ],
]

export const etfOptions = [
  "Reserve/Modify Map",
  "Deploy Test Map",
  "Commit Production Maps",
]

export const ccfOptions = [
  "835 Map Manager",
  "Form Manager",
  "Image Manager",
]

export const documentOptions = [
  "Override File Cutoff",
  "Document",
  "Group",
  "File",
  "Search",
  "History",
]

export const groupOptions = [
  "All Groups",
  "Active Groups",
  "InActive Groups",
  "Termed Groups",
]

export const ruleTypesOptions = [
  "Auto Hold",
  "Check Text Information",
  "Customer Service Messages",
  "Duplex Definition",
  "Erisa Messages",
  "General Messages",
  "PDF Tagging",
  "High Dollar Payment Routing",
  "Image Definition",
  "Member ID Routing",
  "Provider Insured Optional Messages",
  "Return Address",
  "Signatures",
  "Tax ID Routing",
  "Template and Delivery Assignments",
]

export const formOptions = [
  "Reserve/Modify Form",
  "Deploy Test Forms",
  "Commit Production Forms",
]

export const imageOptions = [
  "Reserve/Modify Image",
  "Deploy Test Images",
  "Commit Production Images",
]

export const welcomeKitOptions = [
  "Home",
  "Document Manager Search",
]

export const clientSettingsOptions = [
  "Client Settings",
  "Data Handling",
  "Group Rules",
  "Print Handling",
]

export const navPermissions = [
  "CAN_ACCESS_835MAP_MANAGER",
  "CAN_ACCESS_ACH",
  "CAN_ACCESS_ACH_ERRORS",
  "CAN_ACCESS_ACH_PAYEEMAINT",
  "CAN_ACCESS_ACH_PAYERMAINT",
  "CAN_ACCESS_ACH_TRANSHIST",
  "CAN_ACCESS_ADDRESS",
  "CAN_ACCESS_ADDRESS_DELIVERY",
  "CAN_ACCESS_ADDRESS_RETURN",
  "CAN_ACCESS_CCF_MANAGER",
  "CAN_ACCESS_CLIENT_ADMINISTRATION",
  "CAN_ACCESS_ADDRESS_CORRECTION_EXCLUSION",
  "CAN_ACCESS_CLIENTRULES_CTT",
  "CAN_ACCESS_CLIENT_EMAIL",
  "CAN_PERFORM_CLIENT_OBJECTS_MODIFY",
  "CAN_PERFORM_INTERNAL_RULE_MODIFY",
  "CAN_PERFORM_CLIENT_OBJECTS_MODIFY",
  "CAN_ACCESS_CLIENTRULES_DTT",
  "CAN_PERFORM_GRP_RULE_AVAIL",
  "CAN_ACCESS_CLIENTRULES_RTT",
  "CAN_ACCESS_REMARK_CODES",
  "CAN_ACCESS_TERRORIST_WATCH_EXCLUSION",
  "CAN_ACCESS_CLIENTRULES",
  "CAN_ACCESS_CLIENTRULES_1099SC",
  "CAN_ACCESS_CLIENTRULES_CAI",
  "CAN_ACCESS_CLIENTRULES_PAYER_EFT_MANAGER",
  "CAN_ACCESS_CLIENTRULES_DATATRANS_EDITOR",
  "CAN_ACCESS_CLIENTRULES_DFT",
  "CAN_ACCESS_CLIENTRULES_FRT",
  "CAN_ACCESS_CLIENTRULES_IMT",
  "CAN_ACCESS_CLIENTRULES_INC",
  "CAN_ACCESS_CLIENTRULES_RC",
  "CAN_ACCESS_CLIENTRULES_DATATRANS_EDITOR",
  "CAN_ACCESS_DOC",
  "CAN_ACCESS_FILE_CUTOFF",
  "CAN_ACCESS_DOC_HOLD",
  "CAN_PERFORM_DOC_HOLD_DOCUMENT",
  "CAN_PERFORM_DOC_HOLD_GROUP",
  "CAN_PERFORM_DOC_HOLD_FILE",
  "CAN_ACCESS_DOC_RELEASE",
  "CAN_PERFORM_DOC_RELEASE_DOCUMENT",
  "CAN_PERFORM_DOC_RELEASE_GROUP",
  "CAN_PERFORM_DOC_RELEASE_FILE",
  "CAN_ACCESS_DOC_PURGE",
  "CAN_PERFORM_DOC_PURGE_DOCUMENT",
  "CAN_PERFORM_DOC_PURGE_GROUP",
  "CAN_PERFORM_DOC_PURGE_FILE",
  "CAN_ACCESS_DOC_ROUTE",
  "CAN_PERFORM_DOC_ROUTE_DOCUMENT",
  "CAN_PERFORM_DOC_ROUTE_GROUP",
  "CAN_PERFORM_DOC_ROUTE_FILE",
  "CAN_ACCESS_DOC_SEARCH",
  "CAN_ACCESS_DOC_HISTORY",
  "CAN_ACCESS_DOFR_MANAGER",
  "CAN_ACCESS_EEOBFORM_MANAGER",
  "CAN_PERFORM_EEOBFORM_ADD",
  "CAN_PERFORM_EEOBFORM_MODIFY",
  "CAN_ACCESS_EMPGROUP",
  "CAN_ACCESS_SELFSERVICE_GROUPS",
  "CAN_ACCESS_ERROR",
  "CAN_ACCESS_FILERECEIPT",
  "CAN_ACCESS_FINANCE_MANAGER",
  "CAN_ACCESS_FORM_MANAGER",
  "CAN_ACCESS_SELFSERVICE_RULETYPES",
  "CAN_ACCESS_GROUPRULES_8GN",
  "CAN_ACCESS_GROUPRULES_8IN",
  "CAN_ACCESS_GROUPRULES_ACC",
  "CAN_ACCESS_GROUPRULES_ADJ",
  "CAN_ACCESS_GROUPRULES_HLD",
  "CAN_ACCESS_GROUPRULES_BDR",
  "CAN_ACCESS_GROUPRULES_CCM",
  "CAN_ACCESS_GROUPRULES_CDM",
  "CAN_ACCESS_GROUPRULES_CHT",
  "CAN_ACCESS_GROUPRULES_COB",
  "CAN_PERFORM_EMPGROUP_COMMIT_SINGLE",
  "CAN_ACCESS_GROUPRULES_CEB",
  "CAN_ACCESS_GROUPRULES_CCI",
  "CAN_ACCESS_GROUPRULES_CCR",
  "CAN_ACCESS_GROUPRULES_CSM",
  "CAN_ACCESS_GROUPRULES_DPX",
  "CAN_ACCESS_GROUPRULES_EEB",
  "CAN_ACCESS_GROUPRULES_ERI",
  "CAN_ACCESS_GROUPRULES_XIN",
  "CAN_ACCESS_GROUPRULES_FVT",
  "CAN_ACCESS_GROUPRULES_GEN",
  "CAN_ACCESS_GROUPRULES_HCR",
  "CAN_ACCESS_GROUPRULES_HPP",
  "CAN_ACCESS_GROUPRULES_CXR",
  "CAN_ACCESS_GROUPRULES_HDR",
  "CAN_ACCESS_GROUPRULES_IMG",
  "CAN_ACCESS_GROUPRULES_INV",
  "CAN_ACCESS_GROUPRULES_MSS",
  "CAN_ACCESS_GROUPRULES_MIC",
  "CAN_ACCESS_GROUPRULES_NCO",
  "CAN_ACCESS_GROUPRULES_OVR",
  "CAN_ACCESS_GROUPRULES_OVT",
  "CAN_ACCESS_GROUPRULES_PSD",
  "CAN_ACCESS_GROUPRULES_PRS",
  "CAN_ACCESS_GROUPRULES_EPA",
  "CAN_ACCESS_GROUPRULES_PPO",
  "CAN_ACCESS_GROUPRULES_HCS",
  "CAN_ACCESS_GROUPRULES_SSN",
  "CAN_ACCESS_GROUPRULES_PIO",
  "CAN_ACCESS_GROUPRULES_RAD",
  "CAN_ACCESS_GROUPRULES_SIG",
  "CAN_ACCESS_GROUPRULES_TAX",
  "CAN_ACCESS_GROUPRULES_PAR",
  "CAN_ACCESS_GROUPRULES_SCH",
  "CAN_ACCESS_GROUPRULES_UND",
  "CAN_ACCESS_GROUPRULES_PDT",
  "CAN_ACCESS_FORM_MANAGER",
  "CAN_ACCESS_MANIFEST_INFO",
  "CAN_ACCESS_MANIFEST_ENTRY",
  "CAN_ACCESS_MANIFEST_WIRE_DATE",
  "CAN_ACCESS_MANIFEST_ENTRY",
  "CAN_ACCESS_IDCARDS",
  "CAN_ACCESS_FILETRACKER",
  "CAN_ACCESS_REPORTBUILDER",
  "CAN_ACCESS_ABF_REPORTS",
  "CAN_PERFORM_ACS_REPORT",
  "CAN_PERFORM_ABF_BILLING_REPORT",
  "CAN_PERFORM_CHECKS_CLEARED_REPORT",
  "CAN_PERFORM_CLIENT_IMAGE_REPORT",
  "CAN_PERFORM_ABF_CLIENT_INFO_REPORT",
  "CAN_PERFORM_GENERIC_REPORT",
  "CAN_PERFORM_DATA_DICTIONARY_REPORT",
  "CAN_PERFORM_EFT_HISTORY",
  "CAN_PERFORM_EFT_PROVIDER_REPORT",
  "CAN_PERFORM_MAILDELIVERY_REPORT",
  "CAN_PERFORM_OFFHOURS_REPORT",
  "CAN_PERFORM_ONHOLD_REPORT",
  "CAN_PERFORM_OUTSTANDING_CHECKS",
  "CAN_PERFORM_UNAUTHORIZED_REPORT",
  "CAN_PERFORM_US_DELIVERY_REPORT",
  "CAN_PERFORM_ABF_USER_REPORT",
  "CAN_PERFORM_USER_SECURITY_REPORT",
  "CAN_PERFORM_DATA_DICTIONARY_REPORT",
  "CAN_ACCESS_SECURITY",
  "CAN_PERFORM_SECURITY_VIEW_ACTIVE_USERS",
  "CAN_ACCESS_SECURITY_DATASOURCE",
  "CAN_ACCESS_SECURITY_ROLE",
  "CAN_ACCESS_SECURITY_USER",
  "CAN_ACCESS_SHARED_DOCUMENTS",
  "CAN_ACCESS_SYSTEM_ADMINISTRATION",
  "CAN_ACCESS_COMMINGLE_MANAGER",
  "CAN_ACCESS_MESSAGE_MANAGER",
  "CAN_PERFORM_PACK_REINDEX",
  "CAN_PERFORM_PURGE_CHECK_HISTORY",
  "CAN_PERFORM_RAIC_MONITORING",
  "CAN_PERFORM_SPECIAL_RELEASE",
  "CAN_PERFORM_MASS_UPDATE",
  "CAN_ACCESS_TESTFILE",
]