
import React from 'react';
import PropTypes from 'prop-types'
import VirtualList from 'react-tiny-virtual-list';
import './styles.scss'

const DefaultItemHeight = 40;

class CustomMenuList extends React.Component {

  renderItem = (props) => {
    const { children } = this.props;
    if (Array.isArray(children)) {
      return (
        <li className="select-dropdown-list-items" style={props.style} key={props.index}>
          {children[props.index]}
        </li>
      );
    }
    return (
      <li
        key={props.index}
        className="react-virtualized-menu-placeholder select-dropdown-list-items"
      >
        {children.props.children}
      </li>
    );
  }

  render() {
    const { options, children, maxHeight, getValue } = this.props;

    const [value]         = getValue();
    const initialOffset   = options.indexOf(value) * DefaultItemHeight;
    const idx = options.indexOf(value);
    const childrenOptions = React.Children.toArray(children);
    const wrapperHeight   = maxHeight < childrenOptions.length * DefaultItemHeight ?
      maxHeight : childrenOptions.length * DefaultItemHeight;

    return (
      <span className="react-virtualized-list-wrapper">
        <VirtualList
          width="100%"
          height={wrapperHeight + 6}
          // scrollOffset={initialOffset - 60}
          scrollToIndex={idx}
          itemCount={childrenOptions.length}
          itemSize={DefaultItemHeight}
          renderItem={this.renderItem}
        />
      </span>
    );
  }
}

CustomMenuList.propTypes = {
  options   : PropTypes.array.isRequired,
  children  : PropTypes.node.isRequired,
  maxHeight : PropTypes.number.isRequired,
  getValue  : PropTypes.func.isRequired
}

export default CustomMenuList