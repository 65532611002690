// Header props
export const headerProps = {
  amountKey: 'amount',
  domID: 'header',
};

const CLONE = 'clone';
const DELETE = 'delete';

export const CLONE_BUTTONS = [CLONE];
export const CLONE_DELETE_BUTTONS = [CLONE, DELETE];
export const DELETE_BUTTON = [DELETE];
