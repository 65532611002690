import React from 'react';
import PropTypes from 'prop-types';
import DefaultView from '../DefaultView';
import { DetailsPane } from './styles';

const RulesTabWrapper = ({
  associatedGroupRecordsNoTermedRecords,
  currentTab,
  addNotification,
  deleteNotification,
  detailElements,
  getRuleDetails,
  getRuleDetailElements,
  goBackFromAdvancedToDefaultView,
  gridConfig,
  groupAssociationsForRuleIdTrigger,
  groupAssociationsForRuleId,
  isCloningRuleDetail,
  isEditingRuleDetail,
  isReadOnlyRuleDetail,
  isExpanded,
  isPostingRuleDetail,
  isPostingRulesForRuleTypes,
  isShowingProductionArrangementModal,
  isShowingCustomerServiceMessageModal,
  isShowingReturnAddressModal,
  isShowingDuplexRuleModal,
  isShowingSignatureRuleModal,
  isShowingCheckTextRuleModal,
  isShowingImageRuleModal,
  isShowingAutoHoldModal,
  isShowingMemberIdModal,
  isShowingTaxIdArrangementRuleModal,
  isShowingErisaMessageModal,
  isShowingHighDollarReviewModal,
  isShowingProviderInsuredOptionMessageModal,
  isShowingGeneralMessageModal,
  isShowingPDFTaggingModal,
  loading,
  notifications,
  errorNotifications,
  postRuleDetails,
  putRuleDetails,
  resetDetailsPane,
  rulesForRuleTypesList,
  selectedRecord,
  selectedRecordStatus,
  selectedRuleDetail,
  selectedRuleRecord,
  selectedRuleTypeId,
  setIsViewingRuleDetails,
  setIsPostingRulesForRuleTypes,
  setIsCloningRuleDetail,
  setIsEditingRuleDetail,
  setIsReadOnlyRuleDetail,
  setIsPostingRuleDetail,
  setIsShowingProductionArrangementModal,
  setIsShowingCustomerServiceMessageModal,
  setIsShowingReturnAddressModal,
  setIsShowingDuplexRuleModal,
  setIsShowingSignatureRuleModal,
  setIsShowingCheckTextRuleModal,
  setIsShowingImageRuleModal,
  setIsShowingAutoHoldModal,
  setIsShowingMemberIdModal,
  setIsShowingTaxIdArrangementRuleModal,
  setIsShowingErisaMessageModal,
  setShowingHighDollarReviewModal,
  setIsShowingPDFTaggingModal,
  setIsShowingProviderInsuredOptionMessageModal,
  setIsShowingGeneralMessageModal,
  setSelectedRuleDetail,
  toggleDetailsView,
  triggerRulesForRuleTypesPutRequest,
  triggerAllRulesForRuleTypesRequest,
  triggerRulesForRuleTypesPostRequest,
  updateCurrentTab,
  records,
  testImageCount,
  isShowingDeleteRuleDetailModal,
  setIsShowingDeleteRuleDetailModal,
  deleteRuleDetailTrigger,
  setIsShowingDetailCloneRuleModal,
  setIsCloningRuleState,
  isCloningRule,
  showDetailCloneRuleModal,
  setRuleTypeCommitModalOpen,
  ruleTypeCommitRuleModalOpen,
  triggerCommitRuleForRuleType,
  overwriteTestRuleRequestTrigger,
  overwriteTestRuleSuccess,
  overwriteTestRuleError,
  setIsShowingAddNewAddressModal,
  isShowingAddNewAddressModal,
  getAddNewAddressElements,
  returnAddressElements,
  postAddNewAddressElements,
  setIsShowingOverwriteModal,
  isShowingOverwriteModal,
  setIsShowingImageLibraryModal,
  isShowingImageLibraryModal,
  setIsShowingImageUploadModal,
  isShowingImageUploadModal,
  postImageUpload,
  setIsShowingActiveGroupsListingModal,
  showActiveGroupsListingModal,
  selectedActiveRecordsList,
  selectedRecordName,
  activeGroupsModalLocation,
  getRuleImages,
  ruleImages,
  getRuleImagesCount,
  ruleImagesCount,
  resetRuleImagesData,
  convertImageUpload,
  convertedImageUploadData,
  convertedImageUploadError,
  postImageUploadSuccessId,
  postCommitImage,
  isShowingCommitImageModal,
  setIsShowingCommitImageModal,
  postImageCommitSuccessId,
  postCommitImageTriggerCancel,
  captureCurrentPage,
  userRole,
  userPermissions,
  ruleDetails,
  getRevertRuleVersions,
  revertRuleVersionsList,
  setIsShowingRevertRuleModal,
  isShowingRevertRuleModal,
  postRevertRuleVersion,
  userType,
  rulesForRuleTypes,
  userRoleId
}) => (
  <DetailsPane isExpanded={isExpanded}>
    {selectedRecord && (
      <DefaultView
        detailElements={detailElements}
        getRuleDetails={getRuleDetails}
        getRuleDetailElements={getRuleDetailElements}
        gridConfig={gridConfig}
        isCloningRuleDetail={isCloningRuleDetail}
        isEditingRuleDetail={isEditingRuleDetail}
        isReadOnlyRuleDetail={isReadOnlyRuleDetail}
        isPostingRuleDetail={isPostingRuleDetail}
        addNotification={addNotification}
        isPostingRulesForRuleTypes={isPostingRulesForRuleTypes}
        isShowingProductionArrangementModal={
          isShowingProductionArrangementModal
        }
        isShowingCustomerServiceMessageModal={
          isShowingCustomerServiceMessageModal
        }
        isShowingReturnAddressModal={isShowingReturnAddressModal}
        isShowingDuplexRuleModal={isShowingDuplexRuleModal}
        isShowingSignatureRuleModal={isShowingSignatureRuleModal}
        isShowingCheckTextRuleModal={isShowingCheckTextRuleModal}
        isShowingImageRuleModal={isShowingImageRuleModal}
        isShowingAutoHoldModal={isShowingAutoHoldModal}
        isShowingMemberIdModal={isShowingMemberIdModal}
        isShowingTaxIdArrangementRuleModal={
          isShowingTaxIdArrangementRuleModal
        }
        isShowingErisaMessageModal={isShowingErisaMessageModal}
        isShowingHighDollarReviewModal={isShowingHighDollarReviewModal}
        isShowingProviderInsuredOptionMessageModal={
          isShowingProviderInsuredOptionMessageModal
        }
        isShowingGeneralMessageModal={isShowingGeneralMessageModal}
        isShowingPDFTaggingModal={isShowingPDFTaggingModal}
        postRuleDetails={postRuleDetails}
        putRuleDetails={putRuleDetails}
        selectedRecord={selectedRecord}
        selectedRecordStatus={selectedRecordStatus}
        setSelectedRuleDetail={setSelectedRuleDetail}
        selectedRuleDetail={selectedRuleDetail}
        selectedRuleRecord={selectedRuleRecord}
        selectedRuleTypeId={selectedRuleTypeId}
        setIsCloningRuleDetail={setIsCloningRuleDetail}
        setIsEditingRuleDetail={setIsEditingRuleDetail}
        setIsReadOnlyRuleDetail={setIsReadOnlyRuleDetail}
        setIsPostingRuleDetail={setIsPostingRuleDetail}
        setIsShowingProductionArrangementModal={
          setIsShowingProductionArrangementModal
        }
        setIsShowingCustomerServiceMessageModal={
          setIsShowingCustomerServiceMessageModal
        }
        setIsShowingReturnAddressModal={setIsShowingReturnAddressModal}
        setIsShowingDuplexRuleModal={setIsShowingDuplexRuleModal}
        setIsShowingSignatureRuleModal={setIsShowingSignatureRuleModal}
        setIsShowingCheckTextRuleModal={setIsShowingCheckTextRuleModal}
        setIsShowingImageRuleModal={setIsShowingImageRuleModal}
        setIsShowingAutoHoldModal={setIsShowingAutoHoldModal}
        setIsShowingMemberIdModal={setIsShowingMemberIdModal}
        setIsShowingTaxIdArrangementRuleModal={
          setIsShowingTaxIdArrangementRuleModal
        }
        setIsShowingErisaMessageModal={setIsShowingErisaMessageModal}
        setShowingHighDollarReviewModal={setShowingHighDollarReviewModal}
        setIsShowingProviderInsuredOptionMessageModal={
          setIsShowingProviderInsuredOptionMessageModal
        }
        setIsShowingGeneralMessageModal={setIsShowingGeneralMessageModal}
        setIsShowingPDFTaggingModal={setIsShowingPDFTaggingModal}
        toggleDetailsView={toggleDetailsView}
        deleteNotification={deleteNotification}
        loading={loading}
        notifications={notifications}
        errorNotifications={errorNotifications}
        triggerRulesForRuleTypesPutRequest={
          triggerRulesForRuleTypesPutRequest
        }
        triggerAllRulesForRuleTypesRequest={
          triggerAllRulesForRuleTypesRequest
        }
        triggerRulesForRuleTypesPostRequest={
          triggerRulesForRuleTypesPostRequest
        }
        setIsViewingRuleDetails={setIsViewingRuleDetails}
        setIsPostingRulesForRuleTypes={setIsPostingRulesForRuleTypes}
        goBackFromAdvancedToDefaultView={goBackFromAdvancedToDefaultView}
        currentTab={currentTab}
        resetDetailsPane={resetDetailsPane}
        isExpanded={isExpanded}
        rulesForRuleTypesList={rulesForRuleTypesList}
        updateCurrentTab={updateCurrentTab}
        records={records}
        testImageCount={testImageCount}
        groupAssociationsForRuleIdTrigger={
          groupAssociationsForRuleIdTrigger
        }
        groupAssociationsForRuleId={groupAssociationsForRuleId}
        associatedGroupRecordsNoTermedRecords={
          associatedGroupRecordsNoTermedRecords
        }
        isShowingDeleteRuleDetailModal={isShowingDeleteRuleDetailModal}
        setIsShowingDeleteRuleDetailModal={
          setIsShowingDeleteRuleDetailModal
        }
        deleteRuleDetailTrigger={deleteRuleDetailTrigger}
        setIsShowingDetailCloneRuleModal={setIsShowingDetailCloneRuleModal}
        setIsCloningRuleState={setIsCloningRuleState}
        isCloningRule={isCloningRule}
        showDetailCloneRuleModal={showDetailCloneRuleModal}
        setRuleTypeCommitModalOpen={setRuleTypeCommitModalOpen}
        ruleTypeCommitRuleModalOpen={ruleTypeCommitRuleModalOpen}
        triggerCommitRuleForRuleType={triggerCommitRuleForRuleType}
        overwriteTestRuleRequestTrigger={overwriteTestRuleRequestTrigger}
        overwriteTestRuleSuccess={overwriteTestRuleSuccess}
        overwriteTestRuleError={overwriteTestRuleError}
        setIsShowingAddNewAddressModal={setIsShowingAddNewAddressModal}
        isShowingAddNewAddressModal={isShowingAddNewAddressModal}
        getAddNewAddressElements={getAddNewAddressElements}
        returnAddressElements={returnAddressElements}
        postAddNewAddressElements={postAddNewAddressElements}
        setIsShowingOverwriteModal={setIsShowingOverwriteModal}
        isShowingOverwriteModal={isShowingOverwriteModal}
        setIsShowingImageLibraryModal={setIsShowingImageLibraryModal}
        isShowingImageLibraryModal={isShowingImageLibraryModal}
        setIsShowingImageUploadModal={setIsShowingImageUploadModal}
        isShowingImageUploadModal={isShowingImageUploadModal}
        postImageUpload={postImageUpload}
        setIsShowingActiveGroupsListingModal={setIsShowingActiveGroupsListingModal}
        showActiveGroupsListingModal={showActiveGroupsListingModal}
        selectedActiveRecordsList={selectedActiveRecordsList}
        selectedRecordName={selectedRecordName}
        activeGroupsModalLocation={activeGroupsModalLocation}
        getRuleImages={getRuleImages}
        ruleImages={ruleImages}
        getRuleImagesCount={getRuleImagesCount}
        ruleImagesCount={ruleImagesCount}
        resetRuleImagesData={resetRuleImagesData}
        convertImageUpload={convertImageUpload}
        convertedImageUploadData={convertedImageUploadData}
        convertedImageUploadError={convertedImageUploadError}
        postImageUploadSuccessId={postImageUploadSuccessId}
        postCommitImage={postCommitImage}
        setIsShowingCommitImageModal={setIsShowingCommitImageModal}
        isShowingCommitImageModal={isShowingCommitImageModal}
        postImageCommitSuccessId={postImageCommitSuccessId}
        postCommitImageTriggerCancel={postCommitImageTriggerCancel}
        captureCurrentPage={captureCurrentPage}
        userRole={userRole}
        userPermissions={userPermissions}
        ruleDetails={ruleDetails}
        getRevertRuleVersions={getRevertRuleVersions}
        revertRuleVersionsList={revertRuleVersionsList}
        setIsShowingRevertRuleModal={setIsShowingRevertRuleModal}
        isShowingRevertRuleModal={isShowingRevertRuleModal}
        postRevertRuleVersion={postRevertRuleVersion}
        userType={userType}
        rulesForRuleTypes={rulesForRuleTypes}
        userRoleId={userRoleId}
      />
    )}
  </DetailsPane>
);

RulesTabWrapper.defaultProps = {
  selectedRecord: null,
  isExpanded: false,
};

RulesTabWrapper.propTypes = {
  deleteNotification: PropTypes.func,
  isPostingRulesForRuleTypes: PropTypes.bool,
  loading: PropTypes.object,
  notifications: PropTypes.object,
  setIsPostingRulesForRuleTypes: PropTypes.func,
  goBackFromAdvancedToDefaultView: PropTypes.func,
  resetDetailsPane: PropTypes.func,
  rulesForRuleTypesList: PropTypes.object,
  triggerAllRulesForRuleTypesRequest: PropTypes.func,
  triggerRulesForRuleTypesPostRequest: PropTypes.func,
  records: PropTypes.array,
  groupAssociationsForRuleIdTrigger: PropTypes.func,
  groupAssociationsForRuleId: PropTypes.array,
  associatedGroupRecordsNoTermedRecords: PropTypes.array,
  toggleDetailsView: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool,
  selectedRecord: PropTypes.shape({
    id: PropTypes.string,
  }),
  selectedRecordStatus: PropTypes.array,
  setIsViewingRuleDetails: PropTypes.func.isRequired,
  triggerRulesForRuleTypesPutRequest: PropTypes.func.isRequired,
  currentTab: PropTypes.number,
  updateCurrentTab: PropTypes.func.isRequired,
  setIsCloningRuleDetail: PropTypes.func,
  setIsReadOnlyRuleDetail: PropTypes.func,
  setIsEditingRuleDetail: PropTypes.func,
  setIsPostingRuleDetail: PropTypes.func,
  setIsShowingProductionArrangementModal: PropTypes.func,
  setIsShowingCustomerServiceMessageModal: PropTypes.func,
  setIsShowingReturnAddressModal: PropTypes.func,
  setIsShowingDuplexRuleModal: PropTypes.func,
  setIsShowingSignatureRuleModal: PropTypes.func,
  setIsShowingCheckTextRuleModal: PropTypes.func,
  setIsShowingImageRuleModal: PropTypes.func,
  setIsShowingAutoHoldModal: PropTypes.func,
  setIsShowingMemberIdModal: PropTypes.func,
  setIsShowingTaxIdArrangementRuleModal: PropTypes.func,
  setIsShowingErisaMessageModal: PropTypes.func,
  setShowingHighDollarReviewModal: PropTypes.func,
  setIsShowingProviderInsuredOptionMessageModal: PropTypes.func,
  setIsShowingGeneralMessageModal: PropTypes.func,
  setIsShowingPDFTaggingModal: PropTypes.func,
  setSelectedRuleDetail: PropTypes.func,
  isShowingProductionArrangementModal: PropTypes.bool,
  isShowingCustomerServiceMessageModal: PropTypes.bool,
  isShowingReturnAddressModal: PropTypes.bool,
  isShowingDuplexRuleModal: PropTypes.bool,
  isShowingSignatureRuleModal: PropTypes.bool,
  isShowingCheckTextRuleModal: PropTypes.bool,
  isShowingImageRuleModal: PropTypes.bool,
  isShowingAutoHoldModal: PropTypes.bool,
  isShowingMemberIdModal: PropTypes.bool,
  isShowingTaxIdArrangementRuleModal: PropTypes.bool,
  isShowingErisaMessageModal: PropTypes.bool,
  isShowingHighDollarReviewModal: PropTypes.bool,
  isShowingProviderInsuredOptionMessageModal: PropTypes.bool,
  isShowingGeneralMessageModal: PropTypes.bool,
  isShowingPDFTaggingModal: PropTypes.bool,
  addNotification: PropTypes.func,
  detailElements: PropTypes.object,
  getRuleDetails: PropTypes.func,
  getRuleDetailElements: PropTypes.func,
  gridConfig: PropTypes.object,
  isCloningRuleDetail: PropTypes.bool,
  isEditingRuleDetail: PropTypes.bool,
  isReadOnlyRuleDetail: PropTypes.bool,
  isPostingRuleDetail: PropTypes.bool,
  resetRuleImagesData: PropTypes.func,
  getRuleImages: PropTypes.func,
  getRuleImagesCount: PropTypes.func,
  ruleImages: PropTypes.object,
  ruleImagesCount: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  setIsShowingImageUploadModal: PropTypes.func,
  isShowingImageUploadModal: PropTypes.bool,
  isShowingImageLibraryModal: PropTypes.bool,
  convertedImageUploadData: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  convertedImageUploadError: PropTypes.string,
  postImageUploadSuccessId: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  postCommitImage: PropTypes.func,
  setIsShowingCommitImageModal: PropTypes.func,
  isShowingCommitImageModal: PropTypes.object,
  postImageCommitSuccessId: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  postCommitImageTriggerCancel: PropTypes.func,
  captureCurrentPage: PropTypes.func,
  userPermissions: PropTypes.string,
  userRole: PropTypes.string,
  userRoleId: PropTypes.string
};

export default RulesTabWrapper;