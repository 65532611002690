import React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import { Page } from '../components';
import DocumentPage from '../containers/DocumentManager/DocumentSearchPage';
import FilePageHold from '../containers/DocumentManager/Hold/FileSearchPageHold';
import FilePageRelease from '../containers/DocumentManager/Release/FileSearchPageRelease';
import FilePagePurge from '../containers/DocumentManager/Purge/FileSearchPagePurge';
import FilePageRoute from '../containers/DocumentManager/Route/FileSearchPageRoute';
import DocumentPageHold from '../containers/DocumentManager/Hold/DocumentSearchPageHold';
import DocumentPagePurge from '../containers/DocumentManager/Purge/DocumentSearchPagePurge';
import DocumentPageRelease from '../containers/DocumentManager/Release/DocumentSearchPageRelease';
import DocumentPageRoute from '../containers/DocumentManager/Route/DocumentSearchPageRoute';
import GroupPageHold from '../containers/DocumentManager/Hold/GroupSearchPageHold';
import GroupPagePurge from '../containers/DocumentManager/Purge/GroupSearchPagePurge';
import GroupPageRelease from '../containers/DocumentManager/Release/GroupSearchPageRelease';
import GroupPageRoute from '../containers/DocumentManager/Route/GroupSearchPageRoute';
import GroupSearchResultHold from '../containers/DocumentManager/Hold/GroupSearchResultHold';
import DocumentSearchResultsPage from '../containers/DocumentManager/DocumentSearchResultsPage';
import GroupSearchResultPurge from '../containers/DocumentManager/Purge/GroupSearchResultPurge';
import GroupSearchResultRelease from '../containers/DocumentManager/Release/GroupSearchResultRelease';
import GroupSearchResultRoute from '../containers/DocumentManager/Route/GroupSearchResultRoute';
import FileSearchResultHold from '../containers/DocumentManager/Hold/FileSearchResultHold';
import FileSearchResultRelease from '../containers/DocumentManager/Release/FileSearchResultRelease';
import FileSearchResultPurge from '../containers/DocumentManager/Purge/FileSearchResultPurge';
import FileSearchResultRoute from '../containers/DocumentManager/Route/FileSearchResultRoute';
import DocumentSearchResultHold from '../containers/DocumentManager/Hold/DocumentSearchResultHold';
import DocumentSearchResultRelease from '../containers/DocumentManager/Release/DocumentSearchResultRelease';
import DocumentSearchResultPurge from '../containers/DocumentManager/Purge/DocumentSearchResultPurge';
import DocumentSearchResultRoute from '../containers/DocumentManager/Route/DocumentSearchResultRoute';
import DocumentSearchResultsDetailPage from '../containers/DocumentManager/DocumentSearchResultsDetailPage';
// import DocumentSearchResultDetailsPageHold from '../containers/DocumentManager/Hold/DocumentSearchResultDetailsPageHold';
// import DocumentSearchResultReleaseDetailsPage from '../containers/DocumentManager/Release/DocumentSearchResultReleaseDetailsPage';
import DocumentSearchResultsPageQuick from '../containers/DocumentManager/DocumentSearchResultsPageQuick'
import DocumentManagerDocDetailsPane from '../containers/DocumentManager/DocumentManagerDocDetailsPane';
import DocumentHistoryPage from '../containers/DocumentManager/History/HistoryMainPage';
import OverrideFileCutoff from '../containers/DocumentManager/OverrideFileCutoff';
import RouteWrapper from '../components/RouteWrapper';

const OverrideFileCutoffPage = () => (
  <RouteWrapper>
  <Page
    ListPaneContent={OverrideFileCutoff}
  />
  </RouteWrapper>
);

const DocumentSearchPage = () => (
  <RouteWrapper>
  <Page
    ListPaneContent={DocumentPage}
  />
  </RouteWrapper>
);
const FileSearchHoldMain = () => (
  <RouteWrapper>
  <Page
    ListPaneContent={FilePageHold}
  />
  </RouteWrapper>
);
const FileSearchReleaseMain = () => (
  <RouteWrapper>
  <Page
    ListPaneContent={FilePageRelease}
  />
  </RouteWrapper>
);
const FileSearchPurgeMain = () => (
  <RouteWrapper>
  <Page
    ListPaneContent={FilePagePurge}
  />
  </RouteWrapper>
);
const FileSearchRouteMain = () => (
  <RouteWrapper>
  <Page
    ListPaneContent={FilePageRoute}
  />
  </RouteWrapper>
);
const DocumentSearchHoldMain = () => (
  <RouteWrapper>
  <Page
    ListPaneContent={DocumentPageHold}
  />
  </RouteWrapper>
);
const DocumentSearchPurgeMain = () => (
  <RouteWrapper>
  <Page
    ListPaneContent={DocumentPagePurge}
  />
  </RouteWrapper>
);
const DocumentSearchReleaseMain = () => (
  <RouteWrapper>
  <Page
    ListPaneContent={DocumentPageRelease}
  />
  </RouteWrapper>
);
const DocumentSearchRouteMain = () => (
  <RouteWrapper>
  <Page
    ListPaneContent={DocumentPageRoute}
  />
  </RouteWrapper>
);
const GroupSearchHoldMain = () => (
  <RouteWrapper>
  <Page
    ListPaneContent={GroupPageHold}
  />
  </RouteWrapper>
);
const GroupSearchPurgeMain = () => (
  <RouteWrapper>
  <Page
    ListPaneContent={GroupPagePurge}
  />
  </RouteWrapper>
);
const GroupSearchReleaseMain = () => (
  <RouteWrapper>
  <Page
    ListPaneContent={GroupPageRelease}
  />
  </RouteWrapper>
);
const GroupSearchRouteMain = () => (
  <RouteWrapper>
  <Page
    ListPaneContent={GroupPageRoute}
  />
  </RouteWrapper>
);
const GSearchResultHoldMain = () => (
  <RouteWrapper>
  <Page
    ListPaneContent={GroupSearchResultHold}
  />
  </RouteWrapper>
);

const DocumentSearchResults = () => (
  <RouteWrapper>
  <Page
    ListPaneContent={DocumentSearchResultsPage}
    DetailPaneContent={DocumentSearchResultsDetailPage}
  />
  </RouteWrapper>
);

const DocumentSearchResultsQuick = () => (
  <RouteWrapper>
  <Page
    ListPaneContent={DocumentSearchResultsPageQuick}
  />
  </RouteWrapper>
);

const GSearchResultPurgeMain = () => (
  <RouteWrapper>
  <Page
    ListPaneContent={GroupSearchResultPurge}
  />
  </RouteWrapper>
);

const GSearchResultReleaseMain = () => (
  <RouteWrapper>
  <Page
    ListPaneContent={GroupSearchResultRelease}
  />
  </RouteWrapper>
);

const GSearchResultRouteMain = () => (
  <RouteWrapper>
  <Page
    ListPaneContent={GroupSearchResultRoute}
  />
  </RouteWrapper>
);

const FSearchResultHoldMain = () => (
  <RouteWrapper>
  <Page
    ListPaneContent={FileSearchResultHold}
  />
  </RouteWrapper>
);

const FSearchResultReleaseMain = () => (
  <RouteWrapper>
  <Page
    ListPaneContent={FileSearchResultRelease}
  />
  </RouteWrapper>
);

const FSearchResultPurgeMain = () => (
  <RouteWrapper>
  <Page
    ListPaneContent={FileSearchResultPurge}
  />
  </RouteWrapper>
);

const FSearchResultRouteMain = () => (
  <RouteWrapper>
  <Page
    ListPaneContent={FileSearchResultRoute}
  />
  </RouteWrapper>
);

const DSearchResultHoldMain = () => (
  <RouteWrapper>
  <Page
    ListPaneContent={DocumentSearchResultHold}    
    DetailPaneContent={DocumentManagerDocDetailsPane}
  />
  </RouteWrapper>
);

const DSearchResultReleaseMain = () => (
  <RouteWrapper>
  <Page
    ListPaneContent={DocumentSearchResultRelease}
    DetailPaneContent={DocumentManagerDocDetailsPane}
  />
  </RouteWrapper>
);

const DSearchResultPurgeMain = () => (
  <RouteWrapper>
  <Page
    ListPaneContent={DocumentSearchResultPurge}
    DetailPaneContent={DocumentManagerDocDetailsPane}
  />
  </RouteWrapper>
);

const DSearchResultRouteMain = () => (
  <RouteWrapper>
  <Page
    ListPaneContent={DocumentSearchResultRoute}
    DetailPaneContent={DocumentManagerDocDetailsPane}
  />
  </RouteWrapper>
);
const DocumentHistoryMainPage = () => (
  <RouteWrapper>
  <Page
    ListPaneContent={DocumentHistoryPage}
    //  DetailPaneContent={DocumentManagerDocDetailsPane}
  />
  </RouteWrapper>
);

const DocManagerRotues = () => (
  <Switch>
    <Route path="/DocumentManager/FileOverride" component={OverrideFileCutoffPage} exact/>
    <Route path="/DocumentManager/Hold/Document/searchResults" component={DSearchResultHoldMain} exact/>
    <Route path="/DocumentManager/Hold/Document" component={DocumentSearchHoldMain} />
    <Route path="/DocumentManager/Hold/Group/searchResults" component={GSearchResultHoldMain} exact/>
    <Route path="/DocumentManager/Hold/Group" component={GroupSearchHoldMain} />
    <Route path="/DocumentManager/Hold/File/searchResults" component={FSearchResultHoldMain} exact/>
    <Route path="/DocumentManager/Hold/File" component={FileSearchHoldMain} />
    <Route path="/DocumentManager/Release/Document/searchResults" component={DSearchResultReleaseMain} exact/>
    <Route path="/DocumentManager/Release/Document" component={DocumentSearchReleaseMain} />
    <Route path="/DocumentManager/Release/Group/searchResults" component={GSearchResultReleaseMain} exact/>
    <Route path="/DocumentManager/Release/Group" component={GroupSearchReleaseMain} />
    <Route path="/DocumentManager/Release/File/searchResults" component={FSearchResultReleaseMain} exact/>
    <Route path="/DocumentManager/Release/File" component={FileSearchReleaseMain} />
    <Route path="/DocumentManager/Purge/Document/searchResults" component={DSearchResultPurgeMain} exact/>
    <Route path="/DocumentManager/Purge/Document" component={DocumentSearchPurgeMain} />
    <Route path="/DocumentManager/Purge/Group/searchResults" component={GSearchResultPurgeMain} exact/>
    <Route path="/DocumentManager/Purge/Group" component={GroupSearchPurgeMain} />
    <Route path="/DocumentManager/Purge/File/searchResults" component={FSearchResultPurgeMain} exact/>
    <Route path="/DocumentManager/Purge/File" component={FileSearchPurgeMain} />
    <Route path="/DocumentManager/Route/Document/searchResults" component={DSearchResultRouteMain} exact/>
    <Route path="/DocumentManager/Route/Document" component={DocumentSearchRouteMain} />
    <Route path="/DocumentManager/Route/Group/searchResults" component={GSearchResultRouteMain} exact/>
    <Route path="/DocumentManager/Route/Group" component={GroupSearchRouteMain} />
    <Route path="/DocumentManager/Route/File/searchResults" component={FSearchResultRouteMain} exact/>
    <Route path="/DocumentManager/Route/File" component={FileSearchRouteMain} />
    <Route path="/DocumentManager/Search" component={DocumentSearchPage} />
    <Route path="/DocumentManager/SearchResults" component={DocumentSearchResults} />
    <Route path="/DocumentManager/SearchResultsQuick" component={DocumentSearchResultsQuick} />
    <Route path="/DocumentManager/History" component={DocumentHistoryMainPage} />
    <Route exact path="/DocumentManager">
      <Redirect to="/DocumentManager/FileOverride" />
    </Route>
  </Switch>
)

export default DocManagerRotues;