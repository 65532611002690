import styled from 'styled-components';
import { ButtonGroup } from '../../../../../../shared/components/ButtonGroup';

export const RightSideButtons = styled(ButtonGroup)`
  float: right;
  margin-right: -1em;
  margin-top: -3em;
`;

export const DetailHeaderWrapper = styled.div`
  width: 40%;
`;