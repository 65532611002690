import React, { useState, useEffect } from 'react'
import
 { useSelector } 
from
 'react-redux';
import {
  Grid,
  Input,
  useLayout,
  Button,
  Breadcrumb
} from 'ui-core/dist'
import axios from '../../axios'
import useFileDocuments from '../../api/useFileDocuments'
import { AnchorInput, SelectDropdown, GridPaginationWrapper, Loading } from '../../components'
import useNotificationBannerHook from '../../useNotificationBannerHook';
import './modules/styles.scss'




const EmailCell = ({ isEnote, index, onClick }) => {
  if (isEnote) return (
    <AnchorInput
      id={`email-${index}`}
      type='button'
      className='file-documents__email'
      onClick={onClick}
    >
      EMAIL
    </AnchorInput>
  )
  return <></>
}

const SmsCell = ({ isSMS, index, onClick }) => {
  if (isSMS) return (
    <AnchorInput
      id={`sms-${index}`}
      type='button'
      className='file-documents__sms'
      onClick={onClick}
    >
      SMS
    </AnchorInput>
  )
  return <></>
}

const FileTrackerChildListPane = ({ record, onClose, labels, claimNumber }) => {
  const { notify } = useNotificationBannerHook()
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [pageIndex, setPageIndex] = useState(1);
  const [selected, setSelected] = useState([])
  const [sortField, setSortField] = useState('mailDt')
  const [sortOrder, setSortOrder] = useState('DESC')
  const [caseNumber, setCaseNumber] = useState(claimNumber)
  const [fileStatus, setFileStatus] = useState("")
  const [billName, setBillName] = useState("")
  const [recipientType, setRecipientType] = useState("")
  const [deliveryType, setDeliveryType] = useState("")
  const [issuedState, setIssuedState] = useState("")
  const [carrier, setCarrier] = useState("")
  const [owningCarrier, setOwningCarrier] = useState("")
  const [language, setLanguage] = useState("")
  const [productType, setProductType] = useState("")
  const [idx, setIdx] = useState(0)

  const [dropdowns, setDropdowns] = useState();
  const [isReportLoading, setIsReportLoading] = useState(false)
  const [isPDFDownloading, setIsPDFDownloading] = useState(false)
  const [isDocLoading, setIsDocLoading] = useState(false)
  
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");
  const { lastLogin, attempts, passwordExpired, token: sessionToken, userId, userloginuniqueid } = useSelector(({ session }) => session)

  const [payload, setPayload] = useState({
    maxRecords: rowsPerPage,
    rowPosition: pageIndex,
    sortBy: sortField,
    sortOrder,
    claimNum: caseNumber,
    docStatus: fileStatus,
    claimType: billName,
    recptType: recipientType,
    delvType: deliveryType,
    recptState: issuedState,
    hierarchy3: language,
    hierarchy4: carrier,
    hierarchy5: owningCarrier,
    procesrId: productType,
    firstTime: true
  })
  const { expandCollapseListPaneButtonConfig } = useLayout()
  const { data, isLoading, total, refetch, cacheName } = useFileDocuments({ fileKey: record.fileKey, payload })

  useEffect(() => {
    if (!dropdowns) {
      // if (data && data.hierarchy5 && data.hierarchy5.length) {
      //   data.hierarchy5 = [{ label: 'Select...', value: '' }, ...data.hierarchy5]
      // }
      // setOwningCarrier(data.hierarchy5[0])
      setDropdowns(data);
    }
  }, [data]);

  const [isSMSFeatureEnabled, setisSMSFeatureEnabled]=useState('0')
  const fetchSMSEnableState = () => {
    axios.get('/fileTracker/getFeatureFlag',
      {
        headers: {
          'username': userId, userloginuniqueid
        }
      }).then(res => {
        console.log(res?.data?.data[0]);
        setisSMSFeatureEnabled(res?.data?.data); 
      }).catch(err => {
        setIsError(true);
        setError("There were errros processing your request");
      })
  }



  useEffect(() => {
    fetchSMSEnableState();
  }, [])


  const breadcrumbClick = () => onClose()
  const refreshMe = () => {
    if (
      payload.maxRecords === 10 &&
      payload.rowPosition === 0 &&
      payload.sortBy === 'mailDt' &&
      payload.sortOrder === 'DESC'
    ) {
      refetch()
    } else {
      setPayload({
        maxRecords: 10,
        rowPosition: 1,
        sortBy: 'mailDt',
        sortOrder: "DESC",
        claimNum: "",
        docStatus: "",
        claimType: "",
        recptType: "",
        delvType: "",
        recptState: "",
        hierarchy3: "",
        hierarchy4: "",
        hierarchy5: "",
        procesrId: "",
      })
      setRowsPerPage(50)
      setPageIndex(1)
      setSortField("mailDt")
      setSortOrder('DESC')
      setCaseNumber("")
      setFileStatus("")
      setBillName("")
      setRecipientType("")
      setDeliveryType("")
      setIssuedState("")
      setCarrier("")
      setOwningCarrier("")
      setLanguage("")
      setProductType("")
    }
  }
  const onFileStatusChange = (state) => setFileStatus(state)
  const onBillNameChange = (state) => setBillName(state)
  const onRecipientTypeChange = (state) => setRecipientType(state)
  const onDeliveryTypeChange = (state) => setDeliveryType(state)
  const onIssuedStateChange = (state) => setIssuedState(state)
  const onCarrierChange = (state) => setCarrier(state)
  const onOwningCarrierChange = (state) => setOwningCarrier(state)
  const onLanguageChange = (state) => setLanguage(state)
  const onCaseNumberChange = (e, state) => setCaseNumber(state.value)
  const onProductTypeChange = (e, state) => setProductType(state.value)
  const searchClick = () => {
    setIsPDFDownloading(false);
    setIsReportLoading(false);
    setIsDocLoading(false);
    
    setIsReportLoading(false);
    setIsPDFDownloading(false);
    setRowsPerPage(50)
    setPageIndex(1)
    setSelected([])
    setIdx(idx + 1);
    setPayload({
      idx,
      maxRecords: 50,
      rowPosition: pageIndex,
      sortBy: sortField,
      sortOrder,
      claimNum: caseNumber,
      docStatus: fileStatus ? fileStatus.value : '',
      claimType: billName ? billName.value : '',
      recptType: recipientType ? recipientType.value : '',
      delvType: deliveryType ? deliveryType.value : '',
      recptState: issuedState ? issuedState.value : '',
      hierarchy3: language ? language.value : '',
      hierarchy4: carrier ? carrier.value : '',
      hierarchy5: owningCarrier ? owningCarrier.value : '',
      procesrId: productType,
    })
  }
  const clearClick = () => {
    setIdx(idx + 10);
    setCaseNumber("")
    setFileStatus({})
    setBillName({})
    setRecipientType({})
    setDeliveryType({})
    setIssuedState({})
    setCarrier({})
    setLanguage({})
    setProductType("")
    setOwningCarrier({})
  }
  const viewSelectedClick = async () => {
    try {
      setIsPDFDownloading(true);
      const res = await axios.get(`/fileTracker/pdfUrl?docKey=${selected.reduce((a, c) => `${a},${c}`)}`, { responseType: 'blob' })
      if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
        window.navigator.msSaveOrOpenBlob(res.data, 'FileTrackerDocument.pdf');
      } else {
        const url = URL.createObjectURL(res.data);
        const tab = window.open();
        if(tab)
          tab.location.href = url;
        else
          notify('Please enable allow window pop-up in your browser.','caution')
      }
      setIsPDFDownloading(false);
    } catch (err) {
      setIsPDFDownloading(false);
      notify('Failed to export PDF at the moment. Please try again', "negative")
    }
  }
  const exportClick = async () => {
    try {
      setIsReportLoading(true);
      const res = await axios.get(`/fileTracker/exportDocUrl?cacheName=${cacheName}`, { responseType: 'blob' })
      if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
        window.navigator.msSaveOrOpenBlob(res.data, 'FileTrackerFilesReport.csv');
      } else {
        const url = URL.createObjectURL(res.data);
        const element = document.createElement('a');
        element.setAttribute('href', url);
        element.setAttribute('download', `FileTrackerFilesReport.csv`);
        element.click();
      }
      setIsReportLoading(false);
    } catch (err) {
      setIsReportLoading(false);
      notify('Failed to export document at the moment. Please try again', "negative")
    }
  }
  const handleShowButton = ({ pi }) => {
    setPageIndex(pi)
    setPayload({
      maxRecords: rowsPerPage,
      rowPosition: pi,
      sortBy: sortField,
      sortOrder,
      claimNum: caseNumber,
      docStatus: fileStatus ? fileStatus.value : '',
      claimType: billName ? billName.value : '',
      recptType: recipientType ? recipientType.value : '',
      delvType: deliveryType ? deliveryType.value : '',
      recptState: issuedState ? issuedState.value : '',
      hierarchy3: language ? language.value : '',
      hierarchy4: carrier ? carrier.value : '',
      hierarchy5: owningCarrier ? owningCarrier.value : '',
      procesrId: productType,
      cacheName,
    })
  }
  const onEmailClick = async (docKey) => {
    try {
      setIsDocLoading(true);
      const res = await axios.get(`/fileTracker/enoteUrl?docKey=${docKey}`, { responseType: 'blob' })
      if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
        window.navigator.msSaveOrOpenBlob(res.data, 'FileTrackerEmail.pdf');
      } else {
        const url = URL.createObjectURL(res.data);
        const tab = window.open();
        if(tab)
          tab.location.href = url;
        else
          notify('Please enable allow window pop-up in your browser.','caution')
      }
      setIsDocLoading(false);
    } catch (err) {
      setIsDocLoading(false);
      notify('Failed to export PDF at the moment. Please try again', "negative")
    }
  }

  const onSmsClick = async (docKey) => {
    try {
      setIsDocLoading(true);
      const res = await axios.get(`/fileTracker/smsUrl?docKey=${docKey}`, { responseType: 'blob' })
      if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
        window.navigator.msSaveOrOpenBlob(res.data, 'FileTrackerSms.pdf');
      } else {
        const url = URL.createObjectURL(res.data);
        const tab = window.open();
        if(tab)
          tab.location.href = url;
        else
          notify('Please enable allow window pop-up in your browser.','caution')
      }
      setIsDocLoading(false);
    } catch (err) {
      setIsDocLoading(false);
      notify('Failed to export PDF at the moment. Please try again', "negative")
    }
  }
  const onDocKeyAnchorClick = async (docKey) => {
    try {
      setIsDocLoading(true);
      const res = await axios.get(`/fileTracker/pdfUrl?docKey=${docKey}`, { responseType: 'blob' })
      if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
        window.navigator.msSaveOrOpenBlob(res.data, 'FileTrackerDocument.pdf');
      } else {
        if(res.data){
          const url = URL.createObjectURL(res.data);
          const tab = window.open();
          if(tab)
            tab.location.href = url;
          else
            notify('Please enable allow window pop-up in your browser.','caution');
        }
        else{
          notify('PDF does not exist.','caution'); 
        }
        
      }
      setIsDocLoading(false);
    } catch (err) {
      setIsDocLoading(false);
      notify('PDF does not exist.', "caution");
      console.log(err);
    }
  }
  const onRowSelect = (e, state) => {
    const ids = Array.from(state.selectedItemIds);
    setSelected(ids);
  }
  const onSelectAll = (e, state) => {
    const ids = Array.from(state.selectedItemIds);
    setSelected(ids);
  }
  const onSortGridColumn = (e, { sortingKey }) => {
    const splitKey = sortingKey.split('|')
    if (splitKey[0] === 'docKeyProp') setSortField('docKey')
    else setSortField(splitKey[0])
    if (splitKey[1] === 'SORT_DESCENDING') setSortOrder('DESC')
    else setSortOrder('ASC')
    setPayload({
      ...payload,
      sortBy: splitKey[0] === 'docKeyProp' ? 'docKey' : splitKey[0],
      sortOrder: splitKey[1] === 'SORT_DESCENDING' ? 'DESC' : 'ASC',
      cacheName
    })
  }
  const sortingComparator = (sortDataName, sortDirectionString, recordsToSort) => recordsToSort

  return (
    <>
      {/* <ListPaneHeader
        title={`File Name:${record.fileName}`}
        layoutControls={[expandCollapseListPaneButtonConfig]}
      /> */}
      <div>
        <Breadcrumb
          breadcrumbItems={[{ title: "File Tracker" }, { title: record.fileName }]}
          onBreadcrumbItemClick={breadcrumbClick}
          domID="breadcrumb"
        />
        <div className="file-documents">
          <p className="file-documents__info">File Name: {record.fileName}, File Key: {record.fileKey}, Total Imported: {record.imported}, Ready for Print: {record.readyPrnt}, Released: {record.released}, Completed: {record.mailed}, Error: {record.error}</p>
          <div className="file-documents__search-row" style={{ marginTop: '12px', marginBottom: '12px' }}>
            <SelectDropdown
              domID="FileTrackerFileStatusDropdown"
              onChange={onFileStatusChange}
              size="small"
              label={(labels?.docStatus || "Document Status").toUpperCase()}
              options={dropdowns?.documentStatus}
              initialValue={fileStatus}
            // isClearable={fileStatus?.label ? true : false}
            />
            <Input
              domID="FileTrackerCaseNumberInput"
              onChange={onCaseNumberChange}
              size="small"
              label={(labels?.claimNum || "Claim Number").toUpperCase()}
              initialValue={caseNumber}
            />
            <SelectDropdown
              domID="FileTrackerBillNameDropdown"
              onChange={onBillNameChange}
              size="small"
              label={(labels?.claimType || "Claim Type").toUpperCase()}
              options={dropdowns?.claimTypes}
              initialValue={billName}
            // isClearable={billName?.label ? true : false}
            />
            <SelectDropdown
              domID="FileTrackerRecipientTypeDropdown"
              onChange={onRecipientTypeChange}
              size="small"
              label="RECIPIENT TYPE"
              options={dropdowns?.recipientType}
              initialValue={recipientType}
            // isClearable={recipientType?.label ? true : false}
            />
            <SelectDropdown
              // width="120px"
              domID="FileTrackerDeliveryTypeDropdown"
              onChange={onDeliveryTypeChange}
              size="small"
              label="DELIVERY TYPE"
              options={dropdowns?.deliveryTypes}
              initialValue={deliveryType}
            // isClearable={deliveryType?.label ? true : false}
            />
            <SelectDropdown
              width="120px"
              domID="FileTrackerIssuedStateDropdown"
              onChange={onIssuedStateChange}
              size="small"
              label={(labels?.recptState || "Recipient State").toUpperCase()}
              options={dropdowns?.recipientState}
              initialValue={issuedState}
            // isClearable={issuedState?.label ? true : false}
            />
          </div><div className="file-documents__search-row" style={{ marginTop: '12px', marginBottom: '12px' }}>
            <SelectDropdown
              domID="FileTrackerCarrierDropdown"
              onChange={onCarrierChange}
              size="small"
              label={(labels?.hierarchy4 || "Hierarchy 4").toUpperCase()}
              options={dropdowns?.hierarchy4}
              initialValue={carrier}
            // isClearable={carrier?.label ? true : false}
            />
            {/* <SelectDropdown 
            domID="FileTrackerOwningCarrierDropdown"
            onChange={onOwningCarrierChange}
            size="small"
            label={labels?.hierarchy5||"Hierarchy 5"}
            options={dropdowns?.hierarchy5}
            initialValue={owningCarrier}
            isClearable={owningCarrier?.label ? true : false}
          /> */}
            <SelectDropdown
              domID="FileTrackerOwningCarrierDropdown"
              onChange={onOwningCarrierChange}
              label={(labels?.hierarchy5 || "Hierarchy 5").toUpperCase()}
              options={dropdowns?.hierarchy5}
              initialValue={owningCarrier}
            />
            <Input
              domID="FileTrackerProductTypeInput"
              onChange={onProductTypeChange}
              size="small"
              label={(labels?.procesrId || "Processor ID").toUpperCase()}
              initialValue={productType}
            />
            <SelectDropdown
              domID="FileTrackerLanguageDropdown"
              onChange={onLanguageChange}
              size="small"
              label={(labels?.hierarchy3 || "Hierarchy 3").toUpperCase()}
              options={dropdowns?.hierarchy3}
              initialValue={language}
            // isClearable={language?.label ? true : false}
            />
            <Button
              domID="searchButton"
              name={isLoading ? "Searching..." : "Search"}
              onClick={searchClick}
              size="small"
              buttonType="standard"
              className="file-documents__search-button"
              disabled={isLoading}
            />
            <Button
              domID="clearButton"
              name="Clear"
              onClick={clearClick}
              size="small"
              buttonType="diminished"
              className="file-documents__search-button"
            />
          </div>
          <div className="file-documents__pagination-row" style={{ display: 'flex', alignItems: 'baseline' }}>
            <Button
              domID="viewSelectedButton"
              name={isPDFDownloading ? "Loading PDF..." : "View Selected"}
              onClick={viewSelectedClick}
              size="small"
              buttonType="standard"
              disabled={!(selected && selected.length > 0) || isPDFDownloading}
            />
            {
              isPDFDownloading && <Loading length="30px" width="auto" />
            }
            <Button
              domID="exportButton"
              name={isReportLoading ? "Exporting data..." : "Export"}
              onClick={exportClick}
              size="small"
              buttonType="standard"
              disabled={!data?.data?.length > 0 || isReportLoading}
            />
            {
              isReportLoading && <Loading length="30px" width="auto" />
            }
            <GridPaginationWrapper
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={setRowsPerPage}
              pageIndex={pageIndex}
              onChangePageIndex={setPageIndex}
              onShowButtonCLick={handleShowButton}
              totalRecords={total}
              defaultRowsPerPage={50}
              specialIdx={true}
            />
          </div>
          {
            isDocLoading && <div style={{ display: 'flex', alignItems: 'baseline' }}>
              <p style={{ fontSize: '13px', color: '#0F0F59', margin: '0 0 20px 20px' }}>Loading PDF. Please wait...</p>
              <Loading length="30px" width="auto" /> 
            </div>
          }
          <div className="file-documents__grid-wrapper">
            {isLoading ? <Loading /> : 
              <>{
              data?.message ?
              <span style={{fontSize: "14px", marginLeft: "42px"}}>{data.message}</span>
              :<Grid
                isFixedHeader
                maxHeight="400px"
                isConfigurable
                columns={ isSMSFeatureEnabled === '1' ? new Set([
                  {
                    dataName: 'docKeyProp',
                    text: 'DOCUMENT KEY',
                    sortable: true,
                    isSorted: 0,
                    cellType: 'custom',
                  },
                  {
                    dataName: 'emailProp',
                    text: 'EMAIL',
                    cellType: 'custom',
                  },
                  {
                    dataName: 'smsProp',
                    text: 'SMS',
                    cellType: 'custom',
                  },
                  {
                    dataName: 'claimNum',
                    text: (labels?.claimNum || 'Claim Number').toUpperCase(),
                    sortable: true,
                    isSorted: 0,
                    cellType: 'text',
                  },
                  {
                    dataName: 'claimType',
                    text: (labels?.claimType || "Claim Type").toUpperCase(),
                    sortable: true,
                    isSorted: 0,
                    cellType: 'text',
                  },
                  {
                    dataName: 'recptType',
                    text: 'RECIPIENT TYPE',
                    sortable: true,
                    isSorted: 0,
                    cellType: 'text',
                  },
                  {
                    dataName: 'delvType',
                    text: 'DELIVERY TYPE',
                    sortable: true,
                    isSorted: 0,
                    cellType: 'text',
                  },
                  {
                    dataName: 'recptState',
                    text: (labels?.recptState || "Recipient State").toUpperCase(),
                    sortable: true,
                    isSorted: 0,
                    cellType: 'text',
                  },
                  {
                    dataName: 'mailDt',
                    text: 'MAILED / COMPLETED DATE',
                    sortable: true,
                    isSorted: 0,
                    cellType: 'text',
                  },
                  {
                    dataName: 'hierarchy4',
                    text: (labels?.hierarchy4 || "Hierarchy 4").toUpperCase(),
                    sortable: true,
                    isSorted: 0,
                    cellType: 'text',
                  },
                  {
                    dataName: 'hierarchy5',
                    text: (labels?.hierarchy5 || "Hierarchy 5").toUpperCase(),
                    sortable: true,
                    isSorted: 0,
                    cellType: 'text',
                  },
                  {
                    dataName: 'docStatus',
                    text: (labels?.docStatus || "Document Status").toUpperCase(),
                    sortable: true,
                    isSorted: 0,
                    cellType: 'text',
                  },
                  {
                    dataName: 'enoteDt',
                    text: 'E-NOTE DELIVERY DATE',
                    sortable: true,
                    isSorted: 0,
                    cellType: 'text',
                  },
                  {
                    dataName: 'procesrId',
                    text: (labels?.procesrId || "Processor ID").toUpperCase(),
                    sortable: true,
                    isSorted: 0,
                    cellType: 'text',
                  },
                  {
                    dataName: 'hierarchy3',
                    text: (labels?.hierarchy3 || "Hierarchy 3").toUpperCase(),
                    sortable: true,
                    isSorted: 0,
                    cellType: 'text',
                  },
                ]) : new Set([
                  {
                    dataName: 'docKeyProp',
                    text: 'DOCUMENT KEY',
                    sortable: true,
                    isSorted: 0,
                    cellType: 'custom',
                  },
                  {
                    dataName: 'emailProp',
                    text: 'EMAIL',
                    cellType: 'custom',
                  },
                  {
                    dataName: 'claimNum',
                    text: (labels?.claimNum || 'Claim Number').toUpperCase(),
                    sortable: true,
                    isSorted: 0,
                    cellType: 'text',
                  },
                  {
                    dataName: 'claimType',
                    text: (labels?.claimType || "Claim Type").toUpperCase(),
                    sortable: true,
                    isSorted: 0,
                    cellType: 'text',
                  },
                  {
                    dataName: 'recptType',
                    text: 'RECIPIENT TYPE',
                    sortable: true,
                    isSorted: 0,
                    cellType: 'text',
                  },
                  {
                    dataName: 'delvType',
                    text: 'DELIVERY TYPE',
                    sortable: true,
                    isSorted: 0,
                    cellType: 'text',
                  },
                  {
                    dataName: 'recptState',
                    text: (labels?.recptState || "Recipient State").toUpperCase(),
                    sortable: true,
                    isSorted: 0,
                    cellType: 'text',
                  },
                  {
                    dataName: 'mailDt',
                    text: 'MAILED / COMPLETED DATE',
                    sortable: true,
                    isSorted: 0,
                    cellType: 'text',
                  },
                  {
                    dataName: 'hierarchy4',
                    text: (labels?.hierarchy4 || "Hierarchy 4").toUpperCase(),
                    sortable: true,
                    isSorted: 0,
                    cellType: 'text',
                  },
                  {
                    dataName: 'hierarchy5',
                    text: (labels?.hierarchy5 || "Hierarchy 5").toUpperCase(),
                    sortable: true,
                    isSorted: 0,
                    cellType: 'text',
                  },
                  {
                    dataName: 'docStatus',
                    text: (labels?.docStatus || "Document Status").toUpperCase(),
                    sortable: true,
                    isSorted: 0,
                    cellType: 'text',
                  },
                  {
                    dataName: 'enoteDt',
                    text: 'E-NOTE DELIVERY DATE',
                    sortable: true,
                    isSorted: 0,
                    cellType: 'text',
                  },
                  {
                    dataName: 'procesrId',
                    text: (labels?.procesrId || "Processor ID").toUpperCase(),
                    sortable: true,
                    isSorted: 0,
                    cellType: 'text',
                  },
                  {
                    dataName: 'hierarchy3',
                    text: (labels?.hierarchy3 || "Hierarchy 3").toUpperCase(),
                    sortable: true,
                    isSorted: 0,
                    cellType: 'text',
                  },
                ])}
                records={data?.data?.map((record, i) => ({
                  ...record,
                  emailProp: <EmailCell
                    isEnote={record.isEnote}
                    onClick={() => onEmailClick(record.docKey)}
                    index={i}
                  />,
                  smsProp: <SmsCell
                    isSMS={record.isSMS}
                    onClick={() => onSmsClick(record.docKey)}
                    index={i}
                  />,
                  docKeyProp: (record.docStatus === "Error" || record.docStatus === "Imported") ? (
                    <AnchorInput
                      id={`docKey-${record.docKey}`}
                      type='button'
                      disabled
                      style={{ textDecoration: 'none', cursor: 'default' }}
                      className='docKeyLink'
                    >
                      {record.docKey}
                    </AnchorInput>
                  ) : (
                      <AnchorInput
                        id={`docKey-${record.docKey}`}
                        type='button'
                        className='docKeyLink'
                        onClick={() => onDocKeyAnchorClick(record.docKey)}
                      >
                        {record.docKey}
                      </AnchorInput>
                    ),
                })) || []}
                dataTestId="test-FileDocumentGrid"
                domID="FileDocumentGrid"
                initialSortingKey="mailDt|SORT_DESCENDING"
                selectionKey="docKey"
                supportSelection
                selectAllCheckboxDomID="FileDocumentGridSelectAll"
                onRowSelect={onRowSelect}
                onSelectAll={onSelectAll}
                onSortGridColumn={onSortGridColumn}
                sortingComparator={sortingComparator}
                initialSelectedItemIds={new Set(selected)}
                initialSortingKey={`${sortField === 'docKey' ? 'docKeyProp' : sortField}|${sortOrder === 'DESC' ? 'SORT_DESCENDING' : 'SORT_ASCENDING'}`}
                initialDisabledItemIds={new Set(data?.data?.filter(d => d.docStatus === "Error" || d.docStatus === "Imported").map(filterData => filterData.docKey))}
              />}
              </>
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default FileTrackerChildListPane