export const mapIterable = (iterable, iterationMethod, optionalProps) => {
    const results = [];
    let stepNumber = 0;
  
    const stepFunc = (y) => {
      if (y.done) return results;
      results.push(iterationMethod(y.value, (stepNumber += 1), optionalProps));
      return stepFunc(iterable.next());
    };
  
    return stepFunc(iterable.next());
  };
  
  export default {
    mapIterable,
  };
  