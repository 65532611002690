/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import {
    ListPaneHeader,
    useLayout,
    Grid,
} from 'ui-core/dist'
import { SelectDropdown, Loading } from '../../components'
import ErrorPage from '../ErrorPage';
import axios from '../../axios'
import useServers from '../../api/useServers'
import useNotificationBannerHook from '../../useNotificationBannerHook';
import './modules/index.scss';

const ServerProcessDetail = () => {
    const { expandCollapseListPaneButtonConfig, openDetailPane } = useLayout()


    const { notify } = useNotificationBannerHook()

    const [loading, setLoading] = useState(false);

    const [servers, setServers] = useState([]);
    const [selectedServer, setSelectedServer] = useState([]);

    const [dataSet, setDataSet] = useState([]);

    const { data: serversList, isLoading: serversLoading, isError, error } = useServers()

    useEffect(() => {
        if (serversList && serversList.data && serversList.data.length)
            setServers(serversList.data)
    }, [serversList])

    const getServerProcessDetails = async (serverName) => {
        try {
            setLoading(true);
            const res = await axios.get(`/sysAdmin/serverProcessDetails/${serverName}`);
            const resData = res?.data?.data;
            setLoading(false);
            setDataSet(resData);
        } catch (err) {
            setLoading(false);
            notify(err.displayMessage, "negative")
        }
    }

    const onServerChange = (state) => {
        setSelectedServer(state.value);
        getServerProcessDetails(state.value)
    }

    const getSelectionValue = (id, data) => {
        return data && data.filter(ele => {
            return ele.value === id;
        })[0];
    }

    const getDOMElements = () => {
        return <>
            <div className="serverProcessDetail">
                {serversLoading ? <Loading /> : 
                    <>
                        <SelectDropdown
                            width="600px"
                            dataTestId="test-servers"
                            domID="ServersDropdown"
                            label='RAIC Server'
                            onChange={onServerChange}
                            initialValue={getSelectionValue(selectedServer, servers)}
                            isClearable={false}
                            options={servers}
                            size="small"
                        />
                        {loading ? <Loading /> :
                            dataSet && dataSet.map((record, idx) => {
                                return <div className="serverProcessDetail_grid">
                                    <div className="serverProcessDetailStatusTitle">
                                        <div>{record.name}</div>
                                        <div>Process Count: {record['process-count']}</div>
                                    </div>
                                    <Grid
                                        columns={new Set([
                                            {
                                                dataName: 'task-type',
                                                text: 'Task Type',
                                                cellType: 'text',
                                            },
                                            {
                                                dataName: 'parameters',
                                                text: 'Parameters',
                                                cellType: 'text',
                                            },
                                            {
                                                dataName: 'first-attempt',
                                                text: 'First Attempt',
                                                cellType: 'text',
                                            },
                                            {
                                                dataName: 'scheduled',
                                                text: 'Scheduled',
                                                cellType: 'text',
                                            },
                                            {
                                                dataName: 'retries',
                                                text: 'Retries',
                                                cellType: 'text',
                                            },
                                            {
                                                dataName: 'priority',
                                                text: 'Priority',
                                                cellType: 'text',
                                            },
                                            {
                                                dataName: 'priority-timeout',
                                                text: 'Priority Timeout',
                                                cellType: 'text',
                                            },
                                        ])}
                                        records={record.process || []}
                                        dataTestId={`test-ServerProcessGrid${idx}`}
                                        domID={`ServerProcessGrid${idx}`}
                                    />
                                </div>
                            })
                        }
                    </>
                }
            </div>
        </>
    };

    return (
        <>
            <ListPaneHeader
                title="RAICen Monitor - Server Process Detail"
                layoutControls={[expandCollapseListPaneButtonConfig]}
            />
            { isError  ? (
        // replace with a better error component
          <ErrorPage
            // this prop isn't real but is an example
            error={error }
          />
            ) : (
                <>
                {getDOMElements()}
                </>
            )}
        </>
    )
}

export default ServerProcessDetail