import axios from 'axios';
import Error from './errorHandler';
import { push } from 'connected-react-router'
// eslint-disable-next-line import/no-cycle
import { store } from '../index'
import { clearLogonDetails } from '../containers/Login/modules/actions';
import envConfig from '../config/env-config';

const { APP_ENV = 'development' } = window;
const API_ROOT_SERVER = envConfig[APP_ENV].API_ROOT_SERVER;
const { REACT_APP_API_ROOT_SERVER } = process.env
const axiosInstance = axios.create({
  baseURL: REACT_APP_API_ROOT_SERVER ? REACT_APP_API_ROOT_SERVER : API_ROOT_SERVER,
  withCredentials: true,
  timeout: 3000000,
  headers: {
    'Content-Type': 'application/json',
    'isfromCasnt': true
  },
  validateStatus: (status) => status >= 200 && status < 400,
})
axiosInstance.CancelToken=axios.CancelToken;
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('user:token')
    const parsedToken = token && token !== 'undefined' ? JSON.parse(token) : ''
    /* eslint-disable no-param-reassign */
    config.headers.Authorization = parsedToken
    if (!config.headers.username) {
      const r=localStorage.getItem('persist:root:reducer');
      if(r){
        const jr=JSON.parse(r);
        if(jr.session){
          const js=JSON.parse(jr.session);
          if(js.userId) {
            config.headers.username=js.userId;
          }
        }
      }
      // if (localStorage[] && JSON.parse(localStorage['persist:root:reducer']).session
      // && JSON.parse(JSON.parse(localStorage['persist:root:reducer']).session).userId) {
      //   const userId=JSON.parse(JSON.parse(localStorage['persist:root:reducer']).session).userId
        
      // }
    } 
    if (!config.headers.userloginuniqueid){
      const r=localStorage.getItem('persist:root:reducer');
      if(r){
        const jr=JSON.parse(r);
        if(jr.session){
          const js=JSON.parse(jr.session);
          if(js.userloginuniqueid) {
            config.headers.userloginuniqueid = js.userloginuniqueid;
          }
        }
      }
      // if (localStorage['persist:root:reducer'] && JSON.parse(localStorage['persist:root:reducer']).session
      // && JSON.parse(JSON.parse(localStorage['persist:root:reducer']).session).userloginuniqueid) {
      //   const userLoginUniqueId = JSON.parse(JSON.parse(localStorage['persist:root:reducer']).session).userloginuniqueid
        
      // }
    }
    return config
  }
  ,
  (error) => Promise.reject(Error(error.request)
  )
)

axiosInstance.interceptors.response.use(
  undefined,
  (error) => {
    if (error?.response?.data?.error === "Token Invalid") {
      localStorage.removeItem('user:token')
      store.dispatch(clearLogonDetails())
      store.dispatch(push({pathname:'/login', state:'timeout'}))
      return;
    }
    if (
      error.request.responseType === 'blob' &&
      error.response.data instanceof Blob &&
      error.response.data.type &&
      error.response.data.type.toLowerCase().indexOf('json') != -1
    )
    {
      return new Promise((resolve, reject) => {
         let reader = new FileReader();
         reader.onload = () => {
             error.response.data = JSON.parse(reader.result);
             reject(Error(error.response));
         };

         reader.onerror = () => {
             reject(Error(error));
         };
         reader.readAsText(error.response.data);
      });
    };
    return Promise.reject(Error(error.response)
    )
  }
)
export default axiosInstance
