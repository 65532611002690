export const ACH_TRANS_HIST={
    columns: new Set([
      {
        dataName: 'id',
        text: 'File ID',
        sortable: true,
        isSorted: 0,
        cellType: 'text',
      },
      {
        dataName: 'date',
        text: 'Transmitted',
        sortable: true,
        isSorted: 0,
        cellType: 'text',
      },
      {
        dataName: 'status',
        text: 'Status',
        sortable: true,
        isSorted: 0,
        cellType: 'text',
      },
      {
        dataName: 'debit',
        text: 'Total Debit',
        sortable: true,
        isSorted: 0,
        cellType: 'text',
      },
      {
        dataName: 'credit',
        text: 'Total Credit',
        sortable: true,
        isSorted: 0,
        cellType: 'text',
      },
      {
        dataName: 'count',
        text: 'Total Items',
        sortable: true,
        isSorted: 0,
        cellType: 'text',
      },
      {
        dataName: 'confirm',
        text: 'Confirmation',
        sortable: true,
        isSorted: 0,
        cellType: 'text',
      }
    ]),
    selectedItemIds: new Set([]),
    bookmarkedItemIds: new Set([]),
    domID: 'AchTransactionHist',
    dataTestId: 'AchTransactionHist',  
    isConfigurable: true,
    supportSelection: false
  };


  export const ACH_TRANS_DETAIL_HIST={
    columns: new Set([
      {
        dataName: 'name',
        text: 'Bank Name',
        sortable: true,
        isSorted: 0,
        cellType: 'text',
      },
      {
        dataName: 'date',
        text: 'Created',
        sortable: true,
        isSorted: 0,
        cellType: 'text',
      },
      {
        dataName: 'debit',
        text: 'Total Debit',
        sortable: true,
        isSorted: 0,
        cellType: 'text',
      },
      {
        dataName: 'credit',
        text: 'Total Credit',
        sortable: true,
        isSorted: 0,
        cellType: 'text',
      },
      {
        dataName: 'fileName',
        text: 'File Name',
        sortable: true,
        isSorted: 0,
        cellType: 'text',
      },
      {
        dataName: 'status',
        text: 'Status',
        sortable: true,
        isSorted: 0,
        cellType: 'text',
      }
    ]),
    selectedItemIds: new Set([]),
    bookmarkedItemIds: new Set([]),
    domID: 'AchTransactionDetailsHist',
    dataTestId: 'AchTransactionDetailsHist',  
    isConfigurable: true,
    supportSelection: false
  };
  