export const associatiedGroupsGridConfig = {
  columns: new Set([
    {
      dataName: 'groupName',
      text: 'associated groups',
      sortable: true,
    },
    {
      dataName: 'groupStatus',
      text: 'group status',
      sortable: true,
    },
    {
      dataName: 'status',
      text: 'association',
      sortable: true,
    },
    {
      dataName: 'userId',
      text: 'user id',
      sortable: true,
    },
    {
      dataName: 'dateModified',
      text: 'date modified',
      sortable: true,
    },
  ]),
  selectionKey: 'groupId',
  domID: 'associated-groups-grid',
  onSortTableColumn: () => {},
  supportSelection: true,
  initialSortingKey: 'dateModified|SORT_DESCENDING',
};
