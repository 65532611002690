export const gridActiveGroupConfig = {
  columns: new Set([
    {
      dataName: 'name',
      text: 'Active Group',
      sortable: true,
    },
    {
      dataName: 'description',
      text: 'Description',
      sortable: true,
    },
  ]),
  selectionKey: 'id',
  domID: 'active-rule-grid',
  onSortTableColumn: () => {},
  supportSelection: false,
  initialSortingKey: 'dateModified|SORT_DESCENDING',
  isFixedHeader: true
};