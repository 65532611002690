import { useEffect, useState } from 'react'
import { useAxios,useAxiosPost } from '../hooks'

const useErrorList = (props={}) => {
  const [totalRecordsResp, settotalRecordsResp]=useState(0)
  const [errorResp, seterrorResp]=useState([])

  const { data,postData, error, status, isLoading,isError, ...rest } = useAxiosPost({
    ...props,
    url: `errorManager/getErrorList`,
    method:'POST',
    config:{
      'Content-Type':'application/json',
      'Accept':'*'
    },
    dispatchError:false
  })

  useEffect(() => {
    if (data) {
      if (data?.resp === '' || data?.dnf) {
        seterrorResp(JSON.parse(JSON.stringify([])));
        settotalRecordsResp(0);
        return;
      }

      const { collection, fa, fs, fc, rc, dnf } = data;

      if (collection) {
        seterrorResp(JSON.parse(JSON.stringify(collection.error)));
        if (collection && collection.$) {
          const { totalRecords } = collection.$;
          settotalRecordsResp(totalRecords);
        }
      } else if (fa && fs && fc && rc) {
        seterrorResp(JSON.parse(JSON.stringify([])));
        settotalRecordsResp(0);
      }
    }
  }, [data]);

  return { errorResp, totalRecordsResp, postData, error, isLoading,isError, status, ...rest }
}

const useErrorDetails = (props={})=>{
  const [totalRecordsResp,settotalRecordsResp]=useState(0)
  const [errorResp, seterrorResp]=useState([])

  const { data, error, status, isLoading,isError,refetch,postData, ...rest } = useAxiosPost({
    ...props,
    url: `errorManager/getErrorDetail`,
    method:'POST',
    config:{
      'Content-Type':'application/json',
      'Accept':'*'
    },
    onSuccess: (d) => {
      if (d) {
        const {collection}=d;
        if (collection && collection.error) {
          seterrorResp(JSON.parse(JSON.stringify(collection.error)))
          if (collection && collection.error && collection.error.details && collection.error.details.$) {
            const {totalRecords}=collection.error.details.$;
            settotalRecordsResp(totalRecords)
          }
        }
      }
    },
  })
  
  return { data: errorResp, totalRecords: totalRecordsResp, error, isLoading,isError, refetch,status, getErrorDetails:postData, ...rest }
}


const useErrorListByKey = (props={})=>{
  const [errorResp, seterrorResp]=useState([])

  const { data, error, status, isLoading,isError,refetch,postData, ...rest } = useAxiosPost({
    ...props,
    url: `errorManager/getErrorListByKey`,
    method:'POST',
    config:{
      'Content-Type':'application/json',
      'Accept':'*'
    },
    onSuccess: (d) => {
      if (d) {
        const {collection}=d;
        if (collection && collection.error  && collection.error.constructor.name === 'Array') {
          seterrorResp(JSON.parse(JSON.stringify(collection.error.map((err)=>({
            ...err,
            systemType: err['system-type'],
          })))))
        } else { 
          seterrorResp(JSON.parse(JSON.stringify({
            ...collection.error,
            systemType: collection.error['system-type'],
          })))
        }
      }
    },
  })
  
  return {errorRespforKey: errorResp, totalRecordsRespForKey: 0, error, 
    isLoadingForKey: isLoading, isError, refetch, status, getErrorListByFileKey: postData, ...rest }
}

const useErrorStatusValues = () =>{
  const [errorStatusValues, seterrorStatusValues]=useState([])
  const { data,postData, ...rest } = useAxiosPost({
    url: `errorManager/getErrorStatusValues`,
    method:'POST',
    config:{
      'Content-Type':'application/json',
      'Accept':'*'
    }
  })

  useEffect(()=>{
    let errorStatus=[{label:'--None--', value:""}];
    if (data && data.ErrorStatusValues && data.ErrorStatusValues.ErrorStatusValue) {
      const {ErrorStatusValues}=data;
      if (ErrorStatusValues) {
        const {ErrorStatusValue}= ErrorStatusValues;
      
        if (ErrorStatusValue && ErrorStatusValue.length>=0) {
          errorStatus=[...errorStatus,...ErrorStatusValue.map((e)=>({
            label:e.name,
            value:e.id
          }))];
        }
      }
    }
    seterrorStatusValues(JSON.parse(JSON.stringify(errorStatus)))
  },[data])
 
  return { errorStatusValues,getErrorStatusValues:postData, ...rest }
}

const useUpdateStatus = (payload)=>{
  const { postData, data } = useAxiosPost({
    url: `errorManager/updateErrorStatus`,
    method:'POST',
    config:{
      'Content-Type':'application/json',
      'Accept':'*'
    },
    ...payload
  })
  
  return { postData, data}
}

const useAddNote = payload => useAxiosPost({
  url: `errorManager/addNote`,
  method:'POST',
  config:{
    'Content-Type':'application/json',
    'Accept':'*'
  },
  showNotificationBanner: true,
  // notificationSuccessMessage: 'Note added successfully',
  notificationErrorMessage: 'Failed to add note. Please try again.',
  ...payload,
})

const useGetErrorDetail = (payLoadToErrorDetail)=>{
  const [severityrespD, setseverityrespD]=useState('')
  const [descriptionrespD, setdescriptionrespD]=useState('')
  const [errorCodeRespD, seterrorCodeRespD]=useState('')

  const { data,postData,isLoading, isError, error  } = useAxiosPost({
    url: `errorManager/getErrorDetail`,
    method:'POST',
    payload: payLoadToErrorDetail.payload,
    config:{
      'Content-Type':'application/json',
      'Accept':'*'
    }
  })

  useEffect(()=>{
    if (data && data.collection) {
      const {error:errorresp}=data.collection;
      if (errorresp) {
        const {severity:severityresp, description:descriptionresp, details}=errorresp;
        const {detail}=details;
        if (detail) {
          let detailobj=[];
          detailobj=[...detail];
          const f=detailobj.find(x=>x.errorCuid===payLoadToErrorDetail.detailcuid);
          if (f) {
            const {errorCode:errorCodeResp}=f;
            setseverityrespD(severityresp)
            setdescriptionrespD(descriptionresp)
            seterrorCodeRespD(errorCodeResp)
          }
        }
      }
    }
  },[data])
  
  return {data,getErrorDetail:postData, isLoading, isError, error,errorCodeRespD,descriptionrespD,severityrespD}
}

const useGetErrorDetailInfo =() =>{
  const [resolutionInforesp, setresolutionresp]=useState('')
  const [informationInforesp, setinformationresp]=useState('')
  const { data,postData, ...rest } = useAxiosPost({
    url: `errorManager/getErrorDetailInfo`,
    method:'POST',
    config:{
      'Content-Type':'application/json',
      'Accept':'*'
    }
  })
  useEffect(()=>{
    if (data && data.errorInformation) {
      const {errorInformation: errorInformationresp} = data;
      const {information: informationresp, resolution: resolutionresp}=errorInformationresp;
      setinformationresp(informationresp)
      setresolutionresp(resolutionresp)
     }
  },[data])
  return { getErrorDetailInfo:postData,resolutionInforesp, informationInforesp}
}

const useGetErrorResolution =() =>{
  const [resolutionresp, setresolutionresp]=useState('')
  const { data,postData, ...rest } = useAxiosPost({
    url: `errorManager/getErrorResolution`,
    method:'POST',
    config:{
      'Content-Type':'application/json',
      'Accept':'*'
    }
  })
  useEffect(()=>{
    if (data && data.errorResolution) {
      const {errorResolution}=data;
      const {resolution}=errorResolution;
      setresolutionresp(resolution)
    }
  },[data])

  return {getErrorResolution:postData,resolutionresp, ...rest}
}

const useUpdateResolveStatus =(payload)=>{
  const { data, ...rest } = useAxiosPost({
    url:`errorManager/updateMultiErrorStatus`,
    method:'POST',
    config:{
      'Content-Type':'application/json',
      'Accept':'*'
    },
    showNotificationBanner: true,
    notificationSuccessMessage: 'Updated successfully',
    notificationErrorMessage: 'Update unsuccessful',
    ...payload,
  })
  
  return { data: data?.isUpdated, ...rest }
  
}
const useUpdateResolveAllStatus =(payload)=>{
  const { data,isLoading, ...rest } = useAxiosPost({
    url:`errorManager/updateAllErrorStatus`,
    method:'POST',
    config:{
      'Content-Type':'application/json',
      'Accept':'*'
    },
    showNotificationBanner: true,
    notificationSuccessMessage: 'Updated successfully',
    notificationErrorMessage: 'Update unsuccessful',
    ...payload,
  })
  
  return { data: data?.isUpdated, isLoadingD:isLoading, ...rest }
}
export{
  useErrorList,useErrorDetails, useErrorStatusValues,
  useUpdateStatus, useAddNote, useGetErrorDetail, useGetErrorDetailInfo, 
  useGetErrorResolution, useUpdateResolveStatus,useUpdateResolveAllStatus,useErrorListByKey
}