import styled from 'styled-components';
import { TextArea } from 'ui-core-ssgr';

export const ContainerBox = styled.div`
  display: flex;
  padding-bottom: 16px;
  margin-top: 20px
  position: relative;
  & > div > div > span {
    width: 670px;
  }
`;

export const GeneralTextArea = styled(TextArea)`
  width: 670px;
  & > div > label {
    margin-right: 590px;
  }
`;
