/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import styled from 'styled-components/dist/styled-components';
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import moment from 'moment'
import {
  Grid,
  useLayout,
  Input,
  Button
} from 'ui-core/dist'
import Datetime from 'react-datetime';
import { LoadingPage, SelectDropdown } from '../../components';
import ErrorPage from '../ErrorPage';
import ListPaneHeaderWrapper from '../../components/ErrorDisplaySection/ListPaneHeaderWrapper';
import GridPaginationWrapper from '../../components/GridPaginationWrapper';
import useSortableGrid from '../../hooks/useSortableGrid';
import useSelectableGrid from '../../hooks/useSelectableGrid'
import { useGetJobs, useGetJobNumbers } from '../../api/useDirectPrintJobTracker';
import useNotificationBannerHook from '../../useNotificationBannerHook';
import { DIRECT_PRINT_JOB_TRACKER_GRID } from './Module/constants'
import {setDirectPrintJob} from './Module/action'
import './Module/index.scss'

const StyledGridWrapper = styled.div`
&.GridWrapper #grid-wrapper-directPrintJobTrackerGrid{
  & table tbody tr td:nth-child(6) > div.cell-component-wrapper {
      max-width: 250px !important;
      overflow: hidden !important;
  }
  & table tbody tr td:nth-child(8) > div.cell-component-wrapper {
    margin-left: 25px !important;
  }
  & table tbody tr td:nth-child(10) > div.cell-component-wrapper {
    max-width: 100px !important;
    overflow: hidden !important;
}
 }
`
const DirectPrintJobTracker = () => {
  const {
    expandCollapseListPaneButtonConfig,
    openDetailPane,
    closeDetailPane
  } = useLayout()
  const history = useHistory();
  const { notify } = useNotificationBannerHook();
  const clientLayout = useSelector(({ context }) => context?.context?.client + context?.context?.layout)
  const dispatch = useDispatch()
  const DEFAULT_ROWS_PER_PAGE = 25;

  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE)
  const [pageIndex, setPageIndex] = useState(0);
  const [count, setcount] = useState(0)
  const [validationMessage2, setvalidationMessage2] = useState('')
  const [importStartDate, setImportStartDate] = useState(moment().subtract(30, "days").format("MM/DD/YYYY"))
  const [importEndDate, setImportEndDate] = useState(moment().format("MM/DD/YYYY"))
  const [jobNumber, setjobNumber] = useState('')
  const [jobStatus, setjobStatus] = useState('')
  const [responseStatus, setresponseStatus] = useState('')
  const [isCleared, setisCleared] = useState(false)
  const [datesError, setDatesError] = useState(false)
  const [errMsg, setErrMsg] = useState(false)
  const [selectedRecord,setSelectedRecord]=useState({})

  const JOBSTATUS_OPTIONS = [{
    label: 'Print Received',
    value: 400
  }, {
    label: 'Print Accepted',
    value: 401
  }, {
    label: 'Rejected',
    value: 402
  }, {
    label: 'Print In Process',
    value: 410
  }, {
    label: 'Print In Late Process',
    value: 411
  }, {
    label: 'Print Hold',
    value: 420
  }, {
    label: 'Print Hold in Late Process',
    value: 421
  }, {
    label: 'Print Cancelled',
    value: 440
  }, {
    label: 'Print Void',
    value: 441
  }, {
    label: 'Print Complete',
    value: 450
  }, {
    label: 'Partial Hold',
    value: 419
  }, {
    label: 'Print Undergoing Manual Changes',
    value: 430
  }, {
    label: 'Partial Complete',
    value: 449
  }, {
    label: 'Print Job Does Not Exist',
    value: 480
  }, {
    label: 'Print status response contains error(s).',
    value: 490
  }]
  const RESPONSESTATUS_OPTIONS = [{
    label: 'Interrupt Request Acknowledged',
    value: 700
  }, {
    label: 'Interrupt Request Accepted',
    value: 701
  }, {
    label: 'Interrupt Request Rejected',
    value: 702
  }, {
    label: 'Interrupt Request Complete',
    value: 750
  }]

  const getInitialValueJobStatus = () => {
    if (jobStatus) {
      return { label: JOBSTATUS_OPTIONS.find(x => x.value === jobStatus)?.label, value: jobStatus }
    }
    return null;
  }
  const getInitialValueResponseStatus = () => {
    if (responseStatus) {
      return { label: RESPONSESTATUS_OPTIONS.find(x => x.value === responseStatus)?.label, value: responseStatus }
    }
    return null;
  }
  const onClearClick = () => {
    setImportStartDate(moment().subtract(30, "days").format("MM/DD/YYYY"))
    setImportEndDate(moment().format("MM/DD/YYYY"))
    setjobNumber('')
    setjobStatus('')
    setresponseStatus('')
    setDatesError(false);
    setisCleared(true)
  }
  useEffect(() => {
    setvalidationMessage2('')
    if (jobNumber) {
      if (!/^[a-zA-Z0-9._-]+$/i.test(jobNumber)) {
        setvalidationMessage2('Job Number can only be alphanumeric')
      }
    }
  }, [jobNumber])
  // useEffect(() => {
  //   setvalidationMessage('')
  //   if (importStartDate && importEndDate) {
  //     if (moment(importStartDate) > moment(importEndDate)) {
  //       setvalidationMessage('Import Start date cannot be greater that Import End date')
  //     }
  //   }
  // }, [importStartDate, importEndDate])
  // useEffect(() => {
  //   if (isCleared) {
  //     refetchDirectPrintJobs()
  //     setisCleared(false)
  //   }
  // }, [isCleared])
  const refetchDirectPrintJobs = () => {
    closeDetailPane()
    setRowsPerPage(DEFAULT_ROWS_PER_PAGE)
    setPageIndex(0)
    let config = {
      clientLayout,
      "rowCount": DEFAULT_ROWS_PER_PAGE,
      "offset": 0,
      "sortColumn": sortConfig.sortDataName,
      "sortOrder": sortConfig.sortDescending,
      withparams: false,
      count
    }
    if (importStartDate || importEndDate || jobNumber || jobStatus || responseStatus) {
      config = {
        ...config, withparams: true, importStartDate,
        importEndDate,
        jobNumber,
        jobStatus,
        responseStatus, count
      }
    }
    getJobs(JSON.parse(JSON.stringify(config)))
    setcount(count + 1)
  }

  useEffect(() => {
    if (getJobs) {
      closeDetailPane();
      //   dispatch(resetDirectPrintJobRecord());
      refetchDirectPrintJobs()
    }
  }, [])

  const fetchData = useCallback((args) => {
    let sortcolumn; let sd; let pi; let type;
    if (args) {
      ({ sortcolumn, sd, pi, type } = args);
    }
    closeDetailPane();
    let config = {
      clientLayout,
      "rowCount": rowsPerPage,
      "offset": pi === undefined || pi === null ? pageIndex : pi,
      "sortColumn": sortcolumn === undefined || sortcolumn === null ? sortConfig.sortDataName : sortcolumn,
      "sortOrder": sd === undefined || sd === null ? sortConfig.sortDescending : sd,
      withparams: false,
      count
    }
    if (importStartDate || importEndDate || jobNumber || jobStatus || responseStatus) {
      config = {
        ...config, withparams: true, importStartDate,
        importEndDate,
        jobNumber,
        jobStatus,
        responseStatus, count
      }
    }
    getJobs(JSON.parse(JSON.stringify(config)))
    setcount(count + 1)

  }, [rowsPerPage, pageIndex, sortConfig, clientLayout, importStartDate, importEndDate, jobNumber, jobStatus, responseStatus, count]);

  const { isLoading, isError, error, getJobs, data: directPrintJobs } = useGetJobs()
  const [sortConfig, getArgumentsAndSortGrid] = useSortableGrid(fetchData, "IMPORTDATETIME", "true");


  const onShowMoreClick = useCallback((record) => {
    dispatch(setDirectPrintJob({ jobNumber: record.JOBNUMBER }));
    openDetailPane()
    setSelectedRecord(record)
    // history.push('/DirectPrintJobTracker/Files')
  }, [directPrintJobs]);

  const renderJobStatus = (JOBSTATUS, index) => {
    if (JOBSTATUS) {
      if (JOBSTATUS.length >= 50) {
        return (
          <>
            <span data-tip data-for={`statustooltip_${index}`}>${JOBSTATUS.slice(0, 50)}...</span>
            <ReactTooltip id={`statustooltip_${index}`} type='dark' effect='solid'>
              <span>{JOBSTATUS}</span>
            </ReactTooltip>
          </>
        )
      }
      return JOBSTATUS;
    }
    return '';
  }
  const renderResponse = (RESPONSE, FILECOUNT, index) => {
    if (FILECOUNT && parseInt(FILECOUNT || 0) > 1) {
      return 'Mixed'
    }
    if (RESPONSE) {
      if (RESPONSE.length >= 30) {
        return (
          <>
            <span data-tip data-for={`responsetooltip_${index}`}>${RESPONSE.slice(0, 30)}...</span>
            <ReactTooltip id={`responsetooltip_${index}`} type='dark' effect='solid'>
              <span>{RESPONSE}</span>
            </ReactTooltip>
          </>
        )
      }
      return RESPONSE;
    }
    return '-';
  }
  const renderMainGrid = useMemo(() => {

    if (directPrintJobs) {
      const { jobs, totalRecords } = directPrintJobs;
      const data = JSON.parse(JSON.stringify(jobs)).map((d, index) => ({
        ...d,
        RESPONSE: renderResponse(d.RESPONSE, d.FILECOUNT, index),
        RESPONSESTATUS: ((d.RESPONSESTATUS || '').replace('{Resume} ', '')) || '-',
        JOBSTATUS_DISPLAY: renderJobStatus(d.JOBSTATUS, index) // renderJobStatus(d.JOBSTATUS, index)
      }));

      return (
        <>
          <div style={{ marginLeft: '30px' }}>
            <GridPaginationWrapper
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={setRowsPerPage}
              pageIndex={pageIndex}
              onChangePageIndex={setPageIndex}
              onShowButtonCLick={fetchData}
              totalRecords={totalRecords || 0}
              defaultRowsPerPage={DEFAULT_ROWS_PER_PAGE}
              disableShowButton={datesError}
            />
          </div>
          <StyledGridWrapper>
            <Grid
              {...DIRECT_PRINT_JOB_TRACKER_GRID}
              selectionKey= "CLIENTID"
              // isFixedHeader
              maxHeight="600px"
              records={data || []}
              onClickThrough={(e, rec) => {
                e.preventDefault();
                e.stopPropagation();
                if (onShowMoreClick) onShowMoreClick(rec)
              }}
              initialSortingKey={`${sortConfig.sortDataName}|${sortConfig.sortDescending === "true" ? 'SORT_DESCENDING' : 'SORT_ASCENDING'}`}
              onSortGridColumn={(e, state) => getArgumentsAndSortGrid(state)}
              focusedItem={selectedRecord}
              sortingComparator={(sortDataName, sortDirectionString, recordsToSort) => recordsToSort}
            />
          </StyledGridWrapper>
        </>
      )
    }
    return (
      <div className="marginLeft">
        <p><span className="required-glyph"><label>No jobs exist for the selected context</label></span></p>
      </div>
    );
  }, [directPrintJobs, rowsPerPage, pageIndex, sortConfig, datesError, selectedRecord]);

  const startDateValidation = (currentDate) => {
    const momentStartDuration = moment().subtract(10, 'years');
    let err = false;
    if (moment(currentDate).isBefore(momentStartDuration)) {
      err = true;
    } else if (moment(currentDate).isAfter(moment(importEndDate).startOf('day'), 'day')) {
      err = true;
    }

    return !err;
  };

  const validation = (currentDate) => {
    const momentStartDuration = moment().subtract(10, 'years');
    let err = false;
    if (moment(currentDate).isBefore(momentStartDuration)) {
      err = true;
    } else if (moment(currentDate).isAfter(moment().startOf('day'), 'day')) {
      err = true;
    } else if (moment(currentDate).isBefore(moment(importStartDate).startOf('day'), 'day')) {
      err = true;
    }

    return !err;
  };

  const onStartDateChange = (val) => {
    let err = false;
    let errMessage = '';

    const momentStartDuration = moment().subtract(10, 'years');

    if (!moment(val, 'MM/DD/YYYY', true).isValid() || val === '' || moment(val).isBefore(momentStartDuration)) {
      errMessage = 'Import start date is invalid(mm/dd/yyyy, within 10 years).';
      err = true;
    } else if (moment(val).isAfter(moment(importEndDate))) {
      errMessage = 'Import start date should be earlier than the end date.';
      err = true;
    } else if (moment(val).isAfter(moment().startOf('day'), 'day')) {
      errMessage = 'Import start date is invalid(mm/dd/yyyy, max date is today).';
      err = true;
    }

    if (err) {
      setDatesError(true);
      setErrMsg(errMessage);
      setImportStartDate(val);
    } else {
      setDatesError(false);
      setImportStartDate(val.format("MM/DD/YYYY"));
    }
  }

  const onEndDateChange = (val) => {
    let err = false;
    let errMessage = '';

    const momentStartDuration = moment().subtract(10, 'years');

    if (!moment(val, 'MM/DD/YYYY', true).isValid() || val === '' || moment(val).isBefore(momentStartDuration)) {
      err = true;
      errMessage = 'Import end date is invalid(mm/dd/yyyy, within 10 years).'
    } else if (moment(val).isBefore(moment(importStartDate))) {
      errMessage = 'Import end date should be greater than the start date.';
      err = true;
    } else if (moment(val).isAfter(moment().startOf('day'), 'day')) {
      errMessage = 'Import end date is invalid(mm/dd/yyyy, max date is today).';
      err = true;
    }

    if (err) {
      setDatesError(true);
      setErrMsg(errMessage);
      setImportEndDate(val);
    } else {
      setDatesError(false);
      setImportEndDate(val.format("MM/DD/YYYY"));
    }
  }

  return (
    <>
      <ListPaneHeaderWrapper
        title="Direct Print Job Tracker"
        layoutControls={[expandCollapseListPaneButtonConfig]}
      />
      <div style={{ marginLeft: '20px' }} className="marginLeft">
        {datesError && <p style={{ fontSize: '13px', color: 'red', marginLeft: '10px' }}>{errMsg}</p>}
        {validationMessage2 ? (
          <><div className='marginLeft' style={{ marginLeft: '29px' }}><table><tr>
            <td>
              <span className="required-glyph"><label style={{ color: 'red' }}>{validationMessage2}</label></span>
            </td>
          </tr><tr><p></p></tr></table></div></>
        ) : null}
        <div className="displayFlex" style={{ marginTop: '15px', alignItems: 'flex-end' }}>
          <div className="marginLeft" style={{ display: 'flex', alignItems: 'flex-end' }}>
            <div className="msgDatePicker">
              <label style={{ color: '#626D8A', fontSize: '11px' }}>IMPORT START DATE</label>
              <Datetime
                onChange={onStartDateChange}
                // inputProps={{ readOnly: true }}
                closeOnSelect={true}
                isValidDate={startDateValidation}
                dateFormat="MM/DD/YYYY"
                value={importStartDate}
                initialValue={importStartDate}
                initialViewDate={importStartDate}
                timeFormat={false}
              />
            </div>
            <div className="msgDatePicker" style={{ marginLeft: '16px' }}>
              <label style={{ color: '#626D8A', fontSize: '11px' }}>IMPORT END DATE</label>
              <Datetime
                onChange={onEndDateChange}
                // inputProps={{ readOnly: true }}
                closeOnSelect={true}
                isValidDate={validation}
                dateFormat="MM/DD/YYYY"
                value={importEndDate}
                initialValue={importEndDate}
                initialViewDate={importEndDate}
                timeFormat={false}
              />
            </div>
          </div>

          <div className="marginLeft" style={{ marginLeft: '16px' }}>
            <div>
              <label> JOB NUMBER </label>
              <Input
                style={{ width: '200px' }}
                className="test-classname"
                domID="jobnumber"
                placeholder="Enter Job number"
                size="small"
                maxLength={30}
                initialValue={jobNumber}
                onChange={(e) => {
                  setjobNumber(e.target.value || "")
                }}
              />
            </div>
          </div>
          <div className="marginLeft" style={{ marginLeft: '16px' }}>
            <div>
              <label> JOB STATUS </label>
              <SelectDropdown
                placeholder="Select Here..."
                width='300px'
                domID="jobstatusDropdown"
                label=""
                options={JOBSTATUS_OPTIONS}
                size="small"
                initialValue={getInitialValueJobStatus()}
                isClearable={false}
                onChange={(e) => {
                  setjobStatus(e?.value || '')
                }}
              />
            </div>
          </div>
        </div>
        <div className="displayFlex" style={{ marginTop: '15px', alignItems: 'flex-end' }}>
          <div className="marginLeft">
            <div>
              <label> RESPONSE STATUS </label>
              <SelectDropdown
                placeholder="Select Here..."
                width='300px'
                domID="responsestatusDropdown"
                label=""
                options={RESPONSESTATUS_OPTIONS}
                size="small"
                initialValue={getInitialValueResponseStatus()}
                isClearable={false}
                onChange={(e) => {
                  setresponseStatus(e?.value || '')
                }}
              />
            </div>
          </div>
          <div className="marginLeft">
            <div>
              <p></p>
              <Button
                id='search'
                size="small"
                name="SEARCH"
                disabled={validationMessage2 || datesError}
                onClick={() => refetchDirectPrintJobs()}
              />
            </div>
          </div>
          <div className="marginLeft">
            <div>
              <p></p>
              <Button
                buttonType="deEmphasized"
                id='clear'
                size="small"
                name="CLEAR"
                onClick={() => onClearClick()}
              />
            </div>
          </div>
        </div>
      </div>
      {isLoading ? (
        // replace with a better loading component
        <LoadingPage />
      ) : isError ? (
        // replace with a better error component
        <ErrorPage
          // this prop isn't real but is an example
          error={error}
        />
      ) : (
            <>
              {renderMainGrid}
            </>
          )}
    </>
  )
}

export default DirectPrintJobTracker
