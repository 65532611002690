/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
import React,{
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Grid, useLayout, Button } from 'ui-core/dist'
import { LoadingPage} from '../../../components';
import Authorizer from '../../Authorizer';
import LoadingComponent from '../../../components/LoadingComponent';
import ErrorPage from '../../ErrorPage/components/ErrorPage';
import {IMAGE_TRANSLATION_GRID} from '../Modules/constants';
import GridPaginationWrapper from '../../../components/GridPaginationWrapper';
import ListPaneHeaderWrapper from '../../../components/ErrorDisplaySection/ListPaneHeaderWrapper';
import useSortableGrid from '../../../hooks/useSortableGrid';
import { useGetImageTranslationDetails } from "../../../api/useClientRuleManager";
import {
  setDraftCodeData, resetDraftCodeData
} from '../Modules/action'
    
const ImageTranslationMain = () => {
  const {
    expandCollapseListPaneButtonConfig,
    openDetailPane,
    closeDetailPane
  } = useLayout()
  const dispatch = useDispatch();
  const [rowsPerPage,setRowsPerPage]=useState(10);
  const [pageIndex,setPageIndex]=useState(0);
  const [count, setcount]=useState(0);
  const [selectedRecord,setSelectedRecord]=useState('')
      
  const fetchData=useCallback((args)=>{
    let sortcolumn; let sd; let pi;let type;
    if (args) {
      ({sortcolumn,sd,pi,type}=args);
    }
    closeDetailPane();
    dispatch(resetDraftCodeData());
    getAll(JSON.parse(JSON.stringify({
      "maxRecords":rowsPerPage,
      "rowPosition":pi===undefined || pi===null?pageIndex: pi,
      "sortField":sortcolumn===undefined || sortcolumn===null?sortConfig.sortDataName: sortcolumn,
      "sortDescending":sd===undefined || sd===null?sortConfig.sortDescending: sd,
      // type,
      count
    })))
    setcount(count+1)
          
  },[rowsPerPage,pageIndex,sortConfig, count]);
  const [sortConfig, getArgumentsAndSortGrid]=useSortableGrid(fetchData,"imageTran","false");
  const {ImageGridData,ImageData, totalRecordsResp,getAll,isLoading,isError,error} = useGetImageTranslationDetails();
        
       
  useEffect(()=>{
    getAll(JSON.parse(JSON.stringify({      
      "maxRecords":rowsPerPage,
      "rowPosition":pageIndex,
      "sortField":sortConfig.sortDataName,
      "sortDescending":sortConfig.sortDescending,
      count
    })))
    setcount(count+1)
  },[])
    
 
  
  const refetchterroristExclusionRecord=useCallback(()=>{
    getAll(JSON.parse(JSON.stringify({      
      "maxRecords":rowsPerPage,
      "rowPosition":pageIndex,
      "sortField":sortConfig.sortDataName,
      "sortDescending":sortConfig.sortDescending,
      count
    })))
    setcount(count+1)
  }, [rowsPerPage,pageIndex,sortConfig, count])
  const onAddDraftCode = () =>{
    setSelectedRecord({})
    dispatch(setDraftCodeData({terroristExclusionSelectedRecord:JSON.parse(JSON.stringify({})), mode:'Add', refetchterroristExclusionRecord}));
    openDetailPane();
  }
        
  const onShowMoreClick=useCallback((record)=>{
    record.count=count
    dispatch(setDraftCodeData({terroristExclusionSelectedRecord:JSON.parse(JSON.stringify(record)), mode:'Edit', refetchterroristExclusionRecord}));
    setSelectedRecord(JSON.parse(JSON.stringify(record)))
    openDetailPane();
    setcount(count+1)
  },[ImageGridData]);
      
  const renderGridContent = useMemo(()=>{
    const temp=ImageGridData.length? JSON.parse(JSON.stringify(ImageGridData)):[]
    const data=temp.map((ImageGridDataResults)=>({
      imageTran: ImageGridDataResults.imageTran||'',
      ClientImage: ImageGridDataResults.clientImageName||'',
      AbfImage:ImageGridDataResults.abfImageName||'',
      desc: ImageGridDataResults.description||'',
      // FormDesc: FormData && FormData.length ? FormData.find(a=>a.FormName===ImageGridDataResults.AbfForm).FormDesc :''
  
    }))
    return(
      <>
        <div className="mainWrapper">
          <GridPaginationWrapper
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={setRowsPerPage}
            pageIndex={pageIndex}
            onChangePageIndex={setPageIndex}
            onShowButtonCLick={fetchData}
            totalRecords={totalRecordsResp}
            defaultRowsPerPage={10}
          />
          <div className="displayFlex">
            <Authorizer permission='CAN_PERFORM_CLIENTRULES_IMT_ADD'>
            <Button 
              domID="add_image"
              name=" Add Image Translation"
              buttonType="standard"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onAddDraftCode();
              }}
              style={{ marginLeft: "20px" }}
              size="small"
            />
            </Authorizer>
          </div>
        </div>
        <div className='GridWrapper'>
          <Grid
            {...IMAGE_TRANSLATION_GRID}
            dataTestId="test-gridDefault"
            domID="ImageTranslation_Id"
            records={data||[]}
            selectionKey="imageTran"
            initialSortingKey={`${sortConfig.sortDataName}|${sortConfig.sortDescending==="true"?'SORT_DESCENDING':'SORT_ASCENDING'}`}
            onSortGridColumn={(e,state)=>getArgumentsAndSortGrid(state)}
            onClickThrough={(e,rec) => {
              e.preventDefault();
              e.stopPropagation();
              if (data) {
                // const record=JSON.parse(JSON.stringify(data))
                if (onShowMoreClick) {
                  // dispatch(resetAddressExclusionSelectedRecord())
                  return onShowMoreClick(rec);
                }
                return false;
              }
              return false;
            }}
            focusedItem={selectedRecord}
          />
        </div>
      </>
    )},[ImageGridData,selectedRecord, FormData, sortConfig, rowsPerPage, pageIndex, totalRecordsResp])
        
      
  return (
    <>
      <ListPaneHeaderWrapper
        title="Image Translation Manager"
        layoutControls={[expandCollapseListPaneButtonConfig]}
      />
      <>
        {isLoading ? (
          // replace with a better loading component            
          <div style={{ marginLeft: '50%' }}><LoadingComponent /></div>
        ) : isError  ? (
        // replace with a better error component
          <ErrorPage
            // this prop isn't real but is an example
            error={error }
          />
        ) : (
          <>
            {renderGridContent}
          </>
        )}
      </>
    </>
  )
}
        
export default ImageTranslationMain
        