/* eslint-disable no-else-return */
/* eslint-disable import/named */
/* eslint-disable import/no-cycle */
/* eslint-disable no-restricted-imports */
/* eslint-disable no-plusplus */
/* eslint-disable no-lonely-if */
import React, { useEffect, useRef, useState } from "react";
import styled from 'styled-components/dist/styled-components';
import { useGetAddresses, useGetSortOrdersDirect } from "../../../api/useAddressManager";
import {
  useGetDeliveryTypesEx, useGetProdRuleForRoute, useCheckDelTypeForRoute,
  useGetDelTypeDescForRoute, useChkClientCpyAddrForPoBx,
  useCheckAddressForPoBox, useGetDocumentDetailSourceDirect, useGetCountryListDirect, useGetAddress
} from "../../../api/useDocumentManager";
import {
  DOC_MANAGER_SPECIFY_TEMP_DELIVERY_ADDRESS, DOC_MANAGER_SPECIFY_TEMP_DELIVERY_ADDRESS_LABEL,
  DOC_MANAGER_ROUTE_ORIGINAL_ADDRESS, DOC_MANAGER_ROUTE_ORIGINAL_ADDRESS_LABEL,
  DOC_MANAGER_ROUTE_DELIVERY_ADDRESS_EMPTY_VALIDATION_MSG,
  ABF_DELIVERY_ADDRESS_TYPE,
  DOC_MANAGER_ROUTE_DELIVERY_ADDRESS_INT_NORECORDS_SELECTED,
  DOC_MANAGER_ROUTE_DELIVERY_TYPE_CANACCEPTPOBOX_VALIDATION_MSG,
  DOC_MANAGER_ROUTE_PO_BOX_WARNING,
  DOC_MANAGER_ROUTE_PO_BOX_WARNING_SHORT,
  DOC_MANAGER_ROUTE_DELIVERY_TYPE_NO_CHANGE_VALUE
} from '../Modules/constants';

const DEFAULT_SELECTED_VALUE_DELIVERY_ADDRESS = 'Not Selected'
const DEFAULT_SELECTED_VALUE_DELIVERY_TYPE = ''
const DEFAULT_SELECTED_VALUE_RELEASE_TYPE = 'N'

const StyledConfirmPanelContainer = styled.div`
margin-left: 20px;
& table {
  width: 50% !important;
  & tr {
    border-spacing: 5em;
    & > td {
      padding-bottom: 1em;
    }
  }
}
`

const useDocumentRouteDeliveryFilter = (selectedRecords, notify, dropdownConfigs = { deliveryTypeConfig: {}, deliveryAddressConfig: {} }) => {

  const { directPostCall: getAddress } = useGetAddress();
  const [deliveryAddressSource, setdeliveryAddressSource] = useState([]);
  const [deliveryTypeSource, setdeliveryTypeSource] = useState([])
  const [releaseItemsSource, setreleaseItemsSource] = useState([])

  const [selectedDeliveryAddress, setselectedDeliveryAddress] = useState(null)
  const [selectedDeliveryType, setselectedDeliveryType] = useState(null)
  const [selectedReleaseItemsOption, setselectedReleaseItemsOption] = useState(null)
  const [docManagerOneOffRouteAddressObject, setdocManagerOneOffRouteAddressObject] = useState(null);
  const [hasdocManagerOneOffRouteAddressObject, sethasdocManagerOneOffRouteAddressObject] = useState(false);

  const [validationMessage, setvalidationMessage] = useState(null);
  const [checkWarningAttribute, setcheckWarningAttribute] = useState(null);
  const [isLoadingValidation, setisLoadingValidation] = useState(false);

  /**
   * BEGIN variables for the once-off address modal
   */
  const [isOpenOnOffAddressModal, setisOpenOnOffAddressModal] = useState(false);
  const [availableSortOrders, setAvailableSortOrders] = useState('')
  const [description, setDescription] = useState('')
  const [name, setName] = useState('')
  const [attention, setattention] = useState('')
  const [line1, setLine1] = useState('')
  const [line2, setLine2] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [zip, setZip] = useState('')
  const [intFlag, setIntFlag] = useState('')
  const [intCountryList, setIntCountryList] = useState([])
  const [intAddr3, setIntAddr3] = useState('')
  const [intCity, setIntCity] = useState('')
  const [intState, setIntState] = useState('')
  const [intZip, setIntZip] = useState('')
  const [intCountr, setIntCountr] = useState('')
  const [intPhone, setIntPhone] = useState('')
  const [intEmail, setIntEmail] = useState('')
  const [sortOrderId, setsortOrderId] = useState('')
  const [intRetPho, setIntRetPho] = useState('')
  const [intDecision, setIntDecision] = useState('')
  const [intCtStZp, setIntCtStZp] = useState('')
  const [intCntry, setIntCntry] = useState('')
  const [warningMessage, setwarningMessage] = useState('')
  /**
   * END variables for the once-off address modal
   */

  const setisOpenOnOffAddressModalRef = useRef(setisOpenOnOffAddressModal)
  const setattentionRef = useRef(setattention)
  const setNameRef = useRef(setName)
  const setLine1Ref = useRef(setLine1)
  const setLine2Ref = useRef(setLine2)
  const setCityRef = useRef(setCity)
  const setStateRef = useRef(setState)
  const setZipRef = useRef(setZip)
  const setIntAddr3Ref = useRef(setIntAddr3)
  const setIntCityRef = useRef(setIntCity)
  const setIntStateRef = useRef(setIntState)
  const setIntZipRef = useRef(setIntZip)
  const setIntCountrRef = useRef(setIntCountr)
  const setIntPhoneRef = useRef(setIntPhone)
  const setIntEmailRef = useRef(setIntEmail)
  const setIntRetPhoRef = useRef(setIntRetPho)
  const setIntDecisionRef = useRef(setIntDecision)
  const setIntCtStZpRef = useRef(setIntCtStZp)
  const setIntCntryRef = useRef(setIntCntry)
  const setsortOrderIdRef = useRef(setsortOrderId)

  const [confirmationModalMarkup, setconfirmationModalMarkup] = useState(null);

  const setselectedDeliveryAddressRef = useRef(null);
  const setselectedDeliveryTypeRef = useRef(null);
  const setselectedReleaseItemsOptionRef = useRef(null);
  const setdocManagerOneOffRouteAddressObjectRef = useRef(setdocManagerOneOffRouteAddressObject)
  const sethasdocManagerOneOffRouteAddressObjectRef = useRef(sethasdocManagerOneOffRouteAddressObject)

  const { data: deliveryAddresses, postData: getDeliveryAddresses, isError: isErrorDeliveryAddress, isLoading: isLoadingDeliveryAddress }
    = useGetAddresses((error) => { if (error && notify) notify(error, 'negative') })
  const { data: deliveryTypes, postData: getDeliveryTypes, isError: isErrorDeliveryType, isLoading: isLoadingDeliveryType }
    = useGetDeliveryTypesEx((error) => { if (error && notify) notify(error, 'negative') })

  const { directPostCall: getProdRuleForRoute } = useGetProdRuleForRoute();
  const { directPostCall: checkDelTypeForRoute } = useCheckDelTypeForRoute();
  const { directPostCall: getDelTypeDescForRoute } = useGetDelTypeDescForRoute();
  const { directPostCall: chkClientCpyAddrForPoBx } = useChkClientCpyAddrForPoBx();
  const { directPostCall: checkAddressForPoBox } = useCheckAddressForPoBox();
  const { directPostCall: getSortOrders } = useGetSortOrdersDirect();
  const { directPostCall: getDocumentDetailSource } = useGetDocumentDetailSourceDirect();
  const { Country, getCountryList, isLoading: isLoadingCountryList } = useGetCountryListDirect();

  const countRef = useRef(0);

  const renderConfirmationModalUpperPanel = async () => {
    try {
      const addressId = selectedDeliveryAddress;
      let deliveryAddressAsHtml;
      const deliveryTypeDesc = deliveryTypeSource.find(x => x.value === selectedDeliveryType)?.label || "";
      const releaseType = selectedReleaseItemsOption;
      if (addressId != null && addressId !== "" && addressId !== "Not Selected"
        && addressId !== DOC_MANAGER_SPECIFY_TEMP_DELIVERY_ADDRESS
        && addressId !== DOC_MANAGER_ROUTE_ORIGINAL_ADDRESS) {
        // gussy up the selected address for the confirmation page

        const { data: delAddr } = await getAddress({ pstrAddressId: addressId, pstrAddresstype: "D" });
        if (delAddr) {
          let buff = "";
          if (delAddr != null) {
            buff += `(${delAddr.description}) <br/>`;
            buff += `${delAddr.name}<br />`;
            buff += `${delAddr.attention}<br />`;
            buff += `${delAddr.line1}<br />`;
            buff += `${delAddr.line2}<br />`;
            buff += `${delAddr.city}, `;
            buff += `${delAddr.state} `;
            buff += delAddr.zip;
          }
          deliveryAddressAsHtml = buff.toString();
        }

      } // original address
      else if (addressId != null && addressId === DOC_MANAGER_ROUTE_ORIGINAL_ADDRESS) {
        deliveryAddressAsHtml = "<font color=red><i>Original Delivery Address</i></font>";
      } // temp address
      else if (addressId != null && addressId === DOC_MANAGER_SPECIFY_TEMP_DELIVERY_ADDRESS) {
        // if (hasdocManagerOneOffRouteAddressObject) {
        let buff = "";
        const tempAddress = docManagerOneOffRouteAddressObject;

        if (tempAddress != null) {
          if (tempAddress.intFlag === "1") {
            buff += "<font color=red>(<i>One-Time Address</i>) <br/>";
            buff += `${tempAddress.name}<br/>`;
            buff += `${tempAddress.attention}<br/>`;
            buff += `${tempAddress.line1}<br/>`;
            buff += `${tempAddress.line2}<br/>`;
            buff += `${tempAddress.intAddr3}<br/>`;
            buff += `${tempAddress.intCity}<br/>`;
            buff += `${tempAddress.intState}<br/> `;
            buff += `${tempAddress.intZip}<br/>`;
            buff += `${tempAddress.intCountr}<br/>`;
            buff += `${tempAddress.intPhone}<br/>`;
            buff += `${tempAddress.intEmail}<br/>`;
            buff += `${tempAddress.intRetPho}</font>`;
          } else {
            buff += "<font color=red>(<i>One-Time Address</i>) <br/>";
            buff += `${tempAddress.name}<br />`;
            buff += `${tempAddress.attention}<br />`;
            buff += `${tempAddress.line1}<br />`;
            buff += `${tempAddress.line2}<br />`;
            buff += `${tempAddress.city}, `;
            buff += `${tempAddress.state} `;
            buff += `${tempAddress.zip} </font>`;
          }
        }

        deliveryAddressAsHtml = buff;
        // }
      }
      const renderReleaseType = () => {
        if (releaseType === "S") {
          return <span>Scheduled Release</span>
        }
        if (releaseType === "I") {
          return <span>Release Immediately</span>
        }
        if (releaseType !== "S" && releaseType !== "I") {
          return <span>Do Not Release</span>
        }
        return null;
      }
      const s =
        (
          <div className="marginLeft">
            <StyledConfirmPanelContainer className="displayFlex">
              <table className="common-table collapse-border" style={{ width: '50% !important;' }}>
                <tr className="row">
                  <td className="name" style={{ verticalAlign: 'top' }}>
                    <span>Delivery Address: </span>
                  </td>
                  <td className="value">
                    <div dangerouslySetInnerHTML={{ __html: deliveryAddressAsHtml }}></div>
                  </td>
                </tr>
                <tr className="row2">
                  <td className="name" style={{ verticalAlign: 'top' }}>
                    <span>Delivery Type: </span>
                  </td>
                  <td className="value">
                    {deliveryTypeDesc}
                  </td>
                </tr>
                <tr className="row">
                  <td className="name" style={{ verticalAlign: 'top' }}>
                    <span>Release Items on Hold: </span>
                  </td>
                  <td className="value">
                    {renderReleaseType()}
                  </td>
                </tr>
              </table>
            </StyledConfirmPanelContainer>
          </div>
        )
      setconfirmationModalMarkup(s);
      return;
    } catch (error) {
      console.log(error)
      setconfirmationModalMarkup(null)

    }
  }

  const ClearFormFields = () => {
    setAvailableSortOrders(JSON.parse(JSON.stringify(availableSortOrders)))
    setDescription('')
    // setIntFlag('')
    setName('')
    setattention('')
    setLine1('')
    setLine2('')
    setCity('')
    setState('')
    setZip('')
    setIntCountryList(JSON.parse(JSON.stringify(intCountryList)))
    setIntAddr3('')
    setIntCity('')
    setIntState('')
    setIntZip('')
    setIntCountr('')
    setIntPhone('')
    setIntEmail('')
    setsortOrderId('')
    setIntRetPho('')
    setIntDecision('')
    setIntCtStZp('')
    setIntCntry('')
    resetDocManagerOnOffAddress()
    if(warningMessage) setwarningMessage('')
    if(validationMessage && validationMessage.indexOf(DOC_MANAGER_ROUTE_DELIVERY_ADDRESS_EMPTY_VALIDATION_MSG)==-1){
      setvalidationMessage(DOC_MANAGER_ROUTE_DELIVERY_ADDRESS_EMPTY_VALIDATION_MSG)
    }
  }
  const resetDocManagerOnOffAddress = () => {
    sethasdocManagerOneOffRouteAddressObject(false)
    setdocManagerOneOffRouteAddressObject(null)
  }
  const persistDocManagerOnOffAddress = () => {
    setisOpenOnOffAddressModal(true)
  }
  const saveDocManagerOneOffAddress = async () => {
    const tempAddress = {};
    tempAddress.addressId = null;
    tempAddress.description = "Temporary Route Address";
    tempAddress.addressType = ABF_DELIVERY_ADDRESS_TYPE;
    tempAddress.sortOrderId = sortOrderId;
    tempAddress.isActive = true;
    tempAddress.coverPageSequence = null;
    tempAddress.name = name;
    tempAddress.line1 = line1;
    tempAddress.line2 = line2;
    tempAddress.attention = attention;
    tempAddress.city = city;
    tempAddress.state = state;
    tempAddress.zip = zip;

    // international
    tempAddress.intFlag = intFlag;
    tempAddress.intAddr3 = intAddr3;
    tempAddress.intCity = intCity;
    tempAddress.intState = intState;
    tempAddress.intZip = intZip;
    tempAddress.intCountr = intCountr;
    tempAddress.intPhone = intPhone;
    tempAddress.intEmail = intEmail;
    tempAddress.intRetPho = intRetPho;

    let valid = true;
    let warningmsg='';
    const delTypeId = selectedDeliveryType || "";
    const { data: delTypeRt } = await getProdRuleForRoute();
    if (delTypeId.trim() === "" || delTypeId.trim() === DOC_MANAGER_ROUTE_DELIVERY_TYPE_NO_CHANGE_VALUE) {
      if (delTypeRt.trim().toUpperCase() === "RETURNTOCLIENT") {
        const { data: c1 } = await checkAddressForPoBox({ Addr1: tempAddress.line1, Addr2: tempAddress.line2 })
        if (c1 === "true") {
          valid = true;
          warningmsg= "*WARNING - If your original delivery type cannot be delivered to a PO Box, the routed document(s) will be delivered back to your default delivery address per your client setup. Click on ⚠ button to view the current address of the document(s) you are routing.";
          // const { data: c9 } = await checkDelTypeForRoute({ delType: delTypeId })
          // if (c9 === "false") {
          //   valid = false;
          //   setvalidationMessage("You are routing to a PO Box and the delivery type you have chosen does not allow this type of package delivery. Please choose another delivery type or modify the address so that the new address does not contain a PO Box.")
          //   setcheckWarningAttribute("DISABLE")
          //   setisOpenOnOffAddressModal(false);
          // } else {
          //   valid = true;
          //   warningmsg= "*WARNING - If your original delivery type cannot be delivered to a PO Box, the routed document(s) will be delivered back to your default delivery address per your client setup. Click on ⚠ button to view the current address of the document(s) you are routing.";
          // }
        }
      } else {
        const { data: c2 } = await checkAddressForPoBox({ Addr1: tempAddress.line1, Addr2: tempAddress.line2 })
        if (c2 === "true") {
          const { data: msgFragment1 } = await getDelTypeDescForRoute({ delType: delTypeRt })
          valid = true;
          warningmsg=`${DOC_MANAGER_ROUTE_PO_BOX_WARNING}. Click on ⚠ button to view the current address of the document(s) you are routing.`
        }
      }
    } else {
      const { data: c3 } = await checkAddressForPoBox({ Addr1: tempAddress.line1, Addr2: tempAddress.line2 })
      if (c3 === "true") {
        const { data: c4 } = await checkDelTypeForRoute({ delType: delTypeId })
        if (c4 === "false") {
          valid = false;
          setvalidationMessage("You are routing to a PO Box and the delivery type you have chosen does not allow this type of package delivery. Please choose another delivery type or modify the address so that the new address does not contain a PO Box.")
          setcheckWarningAttribute("DISABLE")
          setisOpenOnOffAddressModal(false);
        }
      }
    }
    if (valid) {
      setwarningMessage(warningmsg)
      setdocManagerOneOffRouteAddressObject(JSON.parse(JSON.stringify(tempAddress)))
      setisOpenOnOffAddressModal(false);
      sethasdocManagerOneOffRouteAddressObject(true)
    }
  }

  useEffect(() => {
    if (validationMessage && typeof(validationMessage) === 'string' && !(validationMessage && validationMessage.toString() && validationMessage.toString().includes(DOC_MANAGER_ROUTE_DELIVERY_ADDRESS_EMPTY_VALIDATION_MSG))) {
      // const deliveryTypeId = selectedDeliveryType || "";
      // const canAcceptPoBox = deliveryTypes?.DeliveryTypes?.DeliveryType?.find(x => x.id === deliveryTypeId)?.canAcceptPoBox || '';
      // if (canAcceptPoBox === "false" && validationMessage === DOC_MANAGER_ROUTE_DELIVERY_ADDRESS_EMPTY_VALIDATION_MSG) {
      //   setvalidationMessage(
      //     <>
      //       <>{DOC_MANAGER_ROUTE_DELIVERY_ADDRESS_EMPTY_VALIDATION_MSG}</>
      //       <br />
      //       <>{DOC_MANAGER_ROUTE_DELIVERY_TYPE_CANACCEPTPOBOX_VALIDATION_MSG}</>
      //     </>
      //   )
      // }
      // resetDocManagerOnOffAddress();
      // setisOpenOnOffAddressModal(false);
    }
  }, [validationMessage])

  useEffect(() => {
    setwarningMessage('');
  }, [selectedDeliveryType, selectedDeliveryAddress])

  useEffect(() => {
    validateFields()
  }, [selectedDeliveryAddress, selectedDeliveryType, selectedRecords, docManagerOneOffRouteAddressObject, hasdocManagerOneOffRouteAddressObject])

  useEffect(() => {
    const sRecords = JSON.parse(JSON.stringify(Array.from(selectedRecords)));
    const deliveryTypeId = selectedDeliveryType || "";
    const deliveryAddressId = selectedDeliveryAddress || "";
    const canAcceptPoBox = deliveryTypes?.DeliveryTypes?.DeliveryType?.find(x => x.id === deliveryTypeId)?.canAcceptPoBox || '';
    let deliveryTypeInt = deliveryTypes?.DeliveryTypes?.DeliveryType?.find(x => x.id === deliveryTypeId)?.international || '';
    // if (deliveryTypeInt.trim() !== "1" && canAcceptPoBox === "false" && deliveryAddressId === DOC_MANAGER_SPECIFY_TEMP_DELIVERY_ADDRESS) {
    //   const { deliveryAddressConfig } = dropdownConfigs;
    //   const { defaultSelectedValue } = deliveryAddressConfig;
    //   resetDocManagerOnOffAddress();
    //   setselectedDeliveryAddress(defaultSelectedValue || DEFAULT_SELECTED_VALUE_DELIVERY_ADDRESS)
    //   return;
    // }
    if (deliveryTypeId.trim() === "") {
      if (sRecords != null) {
        for (let k = 0; k < sRecords.length; k++) {
          const delType = sRecords[k]?.deliveryType || "";
          deliveryTypeInt = deliveryTypes?.DeliveryTypes?.DeliveryType?.find(x => x.id === delType)?.international || '';
          if (deliveryTypeInt.trim() === "1")
            break;
        }
      }
    }
    if (docManagerOneOffRouteAddressObject) {
      if ((docManagerOneOffRouteAddressObject?.intFlag || "").trim() !== (deliveryTypeInt || "").trim()) {
        resetDocManagerOnOffAddress();
      }
    }
  }, [selectedDeliveryType, selectedRecords, docManagerOneOffRouteAddressObject])

  const getArrayEntry = (arr) => {
    if (arr && arr.length > 0) {
      return arr[1]
    }
    return '';
  }
  const getArrayEntryPh = (arr) => {
    if (arr && arr.length > 0) {
      return arr.join("")
    }
    return '';
  }
  const validateFields = async () => {
    setvalidationMessage('')
    setcheckWarningAttribute('');
    try {
      setisLoadingValidation(true);
      const sRecords = JSON.parse(JSON.stringify(Array.from(selectedRecords)));
      let isInternationalOk = false;
      const deliveryAddressId = selectedDeliveryAddress || "";
      const deliveryTypeId = selectedDeliveryType || "";
      const canAcceptPoBox = deliveryTypes?.DeliveryTypes?.DeliveryType?.find(x => x.id === deliveryTypeId)?.canAcceptPoBox || '';
      let deliveryTypeInt = deliveryTypes?.DeliveryTypes?.DeliveryType?.find(x => x.id === deliveryTypeId)?.international || '';
      if (deliveryAddressId !== "Not Selected" && deliveryAddressId !== "") {
        if (deliveryTypeId.trim() === "") {
          if (sRecords != null) {
            for (let k = 0; k < sRecords.length; k++) {
              const delType = sRecords[k]?.deliveryType || "";
              deliveryTypeInt = deliveryTypes?.DeliveryTypes?.DeliveryType?.find(x => x.id === delType)?.international || '';
              if (deliveryTypeInt.trim() === "1")
                break;
            }
          }
        }
        // if delivery type is international then cannot route more than one document per time if delivery type is international then MUST use delivery address Specify A One-Time Address
        if (deliveryTypeInt.trim() === "1") {
          // no more than one document per time
          if (sRecords != null) {
            if (sRecords.length > 1) {
              setvalidationMessage("*ERROR - Cannot route more than one international document per time")
            } else {
              // must be one time address
              if (deliveryAddressId !== DOC_MANAGER_SPECIFY_TEMP_DELIVERY_ADDRESS) {
                setvalidationMessage('*ERROR - Delivery address MUST be -Specify A One-Time Address- for international documents')
              } else {
                isInternationalOk = true;
              }
            }
          }
        } else {
          isInternationalOk = true;
        }
        if (isInternationalOk) {
          // ClearFormFields();
          setIntFlag(deliveryTypeInt)
          // if they chose to specify an address, forward to the 'address' mapping
          if (deliveryAddressId === DOC_MANAGER_SPECIFY_TEMP_DELIVERY_ADDRESS
            && !hasdocManagerOneOffRouteAddressObject && !docManagerOneOffRouteAddressObject) {

            // if (deliveryTypeInt.trim() !== "1" && canAcceptPoBox === "false") {
            //   const { deliveryAddressConfig } = dropdownConfigs;
            //   const { defaultSelectedValue } = deliveryAddressConfig;
            //   resetDocManagerOnOffAddress();
            //   setselectedDeliveryAddress(defaultSelectedValue || DEFAULT_SELECTED_VALUE_DELIVERY_ADDRESS)
            //   return;
            // }

            const { data: sortOrder } = await getSortOrders({ maxRecords: 10, rowPosition: 0, sortField: '', viewInactive: false, sortDescending: false, parameters: '', tagname: 'SortOrders' });

            if (sortOrder && sortOrder.SortOrder) {
              const so = sortOrder.SortOrder.map((x) => ({ label: x.name, value: x.id }))
              setAvailableSortOrders([{ label: '', value: '' }, ...JSON.parse(JSON.stringify(so))])
              setDescription("Temporary Route Address");

              if (deliveryTypeInt === "1") {
                if (sRecords != null && sRecords.length === 1) {
                  const { documentKey } = sRecords[0];
                  const { data: documentDetailSource } = await getDocumentDetailSource({ pstrSourceTable: '', pstrDocKey: documentKey, tagname: 'Document' });
                  if (documentDetailSource) {
                    const { lastRoutedAddress } = documentDetailSource;
                    if (lastRoutedAddress) {
                      let strTemp = "";
                      const {
                        intAddr3: intAddr3p,
                        intCity: intCityp,
                        intCntry: intCntryp,
                        intCountr: intCountrp,
                        intCtStZp: intCtStZpp,
                        intDecision: intDecisionp,
                        intEmail: intEmailp,
                        intPhone: intPhonep,
                        intRetPho: intRetPhop,
                        intState: intStatep,
                        intZip: intZipp,
                        recipAdd1, recipAdd2, recipCty, recipName, recipStat, recipZip } = lastRoutedAddress;
                      setName(getArrayEntry(recipName))
                      setLine1(getArrayEntry(recipAdd1))
                      setLine2(getArrayEntry(recipAdd2))
                      strTemp = getArrayEntry(recipCty);
                      if (strTemp.charAt(strTemp.length - 1) === ',')
                        strTemp = strTemp.substr(0, strTemp.length - 1);
                      setCity(strTemp)
                      strTemp = getArrayEntry(recipStat).substr(0, 2);
                      setState(strTemp)
                      setZip(getArrayEntry(recipZip))
                      if (deliveryTypeInt === "1") {

                        setIntAddr3(getArrayEntry(intAddr3p) || "")
                        setIntCity(getArrayEntry(intCityp) || "")
                        setIntState(getArrayEntry(intStatep) || "")
                        setIntZip(getArrayEntry(intZipp) || "")
                        setIntCountr(intCountrp || "")
                        setIntPhone(getArrayEntry(intPhonep) || "")
                        setIntEmail(getArrayEntry(intEmailp) || "")
                        setIntRetPho(getArrayEntryPh(intRetPhop) || "")
                        setIntDecision(intDecisionp || "")
                        setIntCtStZp(intCtStZpp || "")
                        setIntCntry(intCntryp || "")
                        if (intCountrp === "") {
                          setIntCountr(intCntryp || "")
                        }
                      }
                    }
                  }
                  if (!hasdocManagerOneOffRouteAddressObject && !docManagerOneOffRouteAddressObject) setisOpenOnOffAddressModal(true)
                } else {
                  setvalidationMessage(
                    <>
                      <>{DOC_MANAGER_ROUTE_DELIVERY_ADDRESS_EMPTY_VALIDATION_MSG}</>
                      <br />
                      <>{DOC_MANAGER_ROUTE_DELIVERY_ADDRESS_INT_NORECORDS_SELECTED}</>
                    </>
                  )
                  setisLoadingValidation(false);
                  return;
                }
              } else {
                if (!hasdocManagerOneOffRouteAddressObject && !docManagerOneOffRouteAddressObject) setisOpenOnOffAddressModal(true)
              }
            }
            setvalidationMessage(DOC_MANAGER_ROUTE_DELIVERY_ADDRESS_EMPTY_VALIDATION_MSG)
            setisLoadingValidation(false);
            return;
          } else {
            // INSERT NEW DELIVER TYPE AND ADDRESS VALIDATION CALLS HERE
            if (deliveryAddressId === DOC_MANAGER_ROUTE_ORIGINAL_ADDRESS) {
              const { data: delTypeRt1 } = await getProdRuleForRoute();
              if (delTypeRt1.trim().toUpperCase() === "RETURNTOCLIENT") {
                if (deliveryTypeId.trim().toLowerCase() !== "") {
                  const { data: c1 } = await checkDelTypeForRoute({ delType: deliveryTypeId })
                  if (c1 === "false") {
                    setwarningMessage("*WARNING - If your original delivery type cannot be delivered to a PO Box, the routed document(s) will be delivered back to your default delivery address per your client setup.")
                  }
                }
              } else {
                if (deliveryTypeId.trim().toLowerCase() !== "") {
                  const { data: c2 } = await checkDelTypeForRoute({ delType: deliveryTypeId })
                  if (c2 === "false") {
                    const { data: msgFragment1 } = await getDelTypeDescForRoute({ delType: deliveryTypeId });
                    if (msgFragment1==="Federal Express Overnight") setwarningMessage(DOC_MANAGER_ROUTE_PO_BOX_WARNING_SHORT)
                    else setwarningMessage(`${DOC_MANAGER_ROUTE_PO_BOX_WARNING}  ${msgFragment1}.`)
                  }
                }
              }
            } else if (deliveryAddressId !== DOC_MANAGER_SPECIFY_TEMP_DELIVERY_ADDRESS) {
              if (deliveryTypeId.trim() === "" || deliveryTypeId.trim()===DOC_MANAGER_ROUTE_DELIVERY_TYPE_NO_CHANGE_VALUE) {
              const { data: delTypeRt2 } = await getProdRuleForRoute();
              if (delTypeRt2.trim().toUpperCase() === "RETURNTOCLIENT") {
                const { data: c3 } = await chkClientCpyAddrForPoBx({ addrId: deliveryAddressId })
                if (c3 === "true") {
                  setwarningMessage("*WARNING - If your original delivery type cannot be delivered to a PO Box, the routed document(s) will be delivered back to your default delivery address per your client setup.")
                  // const { data: c4 } = await checkDelTypeForRoute({ delType: deliveryTypeId })
                  // if (c4 === "false") {
                  //   setvalidationMessage("You are routing to a PO Box and the delivery type you have chosen does not allow this type of package delivery. Please choose another delivery type or modify the address so that the new address does not contain a PO Box.")
                  //   setcheckWarningAttribute("DISABLE")
                  // } else {
                  //   setwarningMessage("*WARNING - If your original delivery type cannot be delivered to a PO Box, the routed document(s) will be delivered back to your default delivery address per your client setup. Click on ⚠ button to view the current address of the document(s) you are routing.")
                  // }
                }
              } else {
                  const { data: c5 } = await chkClientCpyAddrForPoBx({ addrId: deliveryAddressId })
                  if (c5 === "true") {
                    const { data: msgFragment2 } = await getDelTypeDescForRoute({ delType: delTypeRt2 })
                    setwarningMessage(`${DOC_MANAGER_ROUTE_PO_BOX_WARNING}  ${msgFragment2}.`)
                  }
                }
               } else {
                  const { data: c6 } = await chkClientCpyAddrForPoBx({ addrId: deliveryAddressId })
                  if (c6 === "true") {
                    const { data: c7 } = await checkDelTypeForRoute({ delType: deliveryTypeId })
                    if (c7 === "false") {
                      setvalidationMessage("You are routing to a PO Box and the delivery type you have chosen does not allow this type of package delivery. Please choose another delivery type or modify the address so that the new address does not contain a PO Box.")
                      setcheckWarningAttribute("DISABLE")
                    }
                  }
                }
            } else if (hasdocManagerOneOffRouteAddressObject && docManagerOneOffRouteAddressObject) {
              const delAddress = docManagerOneOffRouteAddressObject;
              if (deliveryTypeId.trim() === "" || deliveryTypeId.trim()===DOC_MANAGER_ROUTE_DELIVERY_TYPE_NO_CHANGE_VALUE) {
              const { data: delTypeRt3 } = await getProdRuleForRoute();
              if (delTypeRt3.trim().toUpperCase() === "RETURNTOCLIENT") {
                const { data: c8 } = await checkAddressForPoBox({ Addr1: delAddress.line1, Addr2: delAddress.line2 })
                if (c8 === "true") {
                  setwarningMessage("*WARNING - If your original delivery type cannot be delivered to a PO Box, the routed document(s) will be delivered back to your default delivery address per your client setup. Click on ⚠ button to view the current address of the document(s) you are routing.")
                  // const { data: c9 } = await checkDelTypeForRoute({ delType: deliveryTypeId })
                  // if (c9 === "false") {
                  //   setvalidationMessage("You are routing to a PO Box and the delivery type you have chosen does not allow this type of package delivery. Please choose another delivery type or modify the address so that the new address does not contain a PO Box.")
                  //   setcheckWarningAttribute("DISABLE")
                  // } else {
                  //   setwarningMessage("*WARNING - If your original delivery type cannot be delivered to a PO Box, the routed document(s) will be delivered back to your default delivery address per your client setup. Click on ⚠ button to view the current address of the document(s) you are routing.")
                  // }
                }
              } else {
                  const { data: c10 } = await checkAddressForPoBox({ Addr1: delAddress.line1, Addr2: delAddress.line2 })
                  if (c10 === "true") {
                    const { data: msgFragment3 } = await getDelTypeDescForRoute({ delType: delTypeRt3 })
                    setwarningMessage(`${DOC_MANAGER_ROUTE_PO_BOX_WARNING}. Click on ⚠ button to view the current address of the document(s) you are routing.`)
                  }
                }
               } else {
                  const { data: c11 } = await checkAddressForPoBox({ Addr1: delAddress.line1, Addr2: delAddress.line2 })
                  if (c11 === "true") {
                    const { data: c12 } = await checkDelTypeForRoute({ delType: deliveryTypeId })
                    if (c12 === "false") {
                      setvalidationMessage("You are routing to a PO Box and the delivery type you have chosen does not allow this type of package delivery. Please choose another delivery type or modify the address so that the new address does not contain a PO Box.")
                      setcheckWarningAttribute("DISABLE")
                    }
                  }
                }
            }
            // return (mapping.findForward("confirm"));
          }
        }
      } else {
        setvalidationMessage(DOC_MANAGER_ROUTE_DELIVERY_ADDRESS_EMPTY_VALIDATION_MSG)
      }
      setisLoadingValidation(false);
    } catch (error) {
      setisLoadingValidation(false);
      console.log(error);
      if (error && notify) notify(JSON.stringify(error), "negative")
    }
  };
  setselectedDeliveryAddressRef.current = (value) => {
    setselectedDeliveryAddress(value)
  };
  setselectedDeliveryTypeRef.current = (value) => {
    setselectedDeliveryType(value)
  }
  setselectedReleaseItemsOptionRef.current = (value) => {
    setselectedReleaseItemsOption(value)
  }
  const setFieldValue = (obj) => {
    if (obj) {
      const keys = Object.keys(obj);
      if (keys && keys.length > 0) {
        const fieldname = keys[0];
        const value = keys[1];
        switch (fieldname) {
          case 'deliveryAddress':
            if (setselectedDeliveryAddressRef.current) setselectedDeliveryAddressRef.current(value);
            break;
          case 'deliveryType':
            if (setselectedDeliveryTypeRef.current) setselectedDeliveryTypeRef.current(value);
            break;
          case 'releaseItemsOption':
            if (setselectedReleaseItemsOptionRef.current) setselectedReleaseItemsOptionRef.current(value);
            break;
          default:
            break;
        }
      }
    }
  }
  const clearFields = () => {
    if (setselectedDeliveryAddressRef.current) setselectedDeliveryAddressRef.current(null);
    if (setselectedDeliveryTypeRef.current) setselectedDeliveryTypeRef.current(null);
    if (setselectedReleaseItemsOptionRef.current) setselectedReleaseItemsOptionRef.current(null);
  }
  const resetFields = () => {
    const { deliveryTypeConfig, deliveryAddressConfig } = dropdownConfigs;
    const { defaultSelectedValue: defaultDeliveryType } = deliveryTypeConfig;
    const { defaultSelectedValue: defaultDeliveryAddress } = deliveryAddressConfig;
    if (setselectedDeliveryAddressRef.current) setselectedDeliveryAddressRef.current(defaultDeliveryAddress || DEFAULT_SELECTED_VALUE_DELIVERY_ADDRESS);
    if (setselectedDeliveryTypeRef.current) setselectedDeliveryTypeRef.current(defaultDeliveryType || DEFAULT_SELECTED_VALUE_DELIVERY_TYPE);
    if (setselectedReleaseItemsOptionRef.current) setselectedReleaseItemsOptionRef.current(DEFAULT_SELECTED_VALUE_RELEASE_TYPE);
    setIntFlag('')
  }
  const getFilterCriteria = () => {
    const criteria = {
      parameters: `
        <![CDATA[
          <criteria>
            <itemType value="D">
                <filter>
                    <item></item>
                </filter>
            </itemType>
          </criteria>
          ]]>
        `
    }
    return criteria;
  }
  useEffect(() => {
    getCountryList({count: countRef.current, tagname: 'Countries'})
    getDeliveryAddresses(getFilterCriteria())
    getDeliveryTypes({ count: countRef.current })
    setreleaseItemsSource(JSON.parse(JSON.stringify([{
      label: 'Do Not Release',
      value: 'N'
    }, {
      label: 'First Available',
      value: 'I'
    }, {
      label: 'Scheduled Release Rule',
      value: 'S'
    }])))
    countRef.current += 1;
  }, [])

  useEffect(()=>{
    if(Country){
      const tc=Country.filter(c => c.name !== "USA").map((c) => ({ label: (c.id==='BLM'? "SAINT BARTHý0LEMY":c.name), value: (c.id==='BLM'? "SAINT BARTHý0LEMY":c.name) }))
      setIntCountryList([{ label: '', value: '' }, ...JSON.parse(JSON.stringify(tc))])
    }
  },[Country])

  useEffect(() => {
    const { deliveryAddressConfig } = dropdownConfigs;
    if (deliveryAddresses) {
      const { collection } = deliveryAddresses;
      if (collection) {
        let { Address } = collection;
        Address = Address ? Array.isArray(Address) ? Address : [Address] : null;
        if (Address) {
          const { values } = deliveryAddressConfig;
          let ad = [];
          if (values && values.length > 0) {
            ad = [...values, ...Address.map((a) => ({ label: `${a.addressId} - ${a.name} - ${a.description}`, value: a.addressId }))]
          } else {
            ad = Address.map((a) => ({ label: `${a.addressId} - ${a.name} - ${a.description}`, value: a.addressId }))
          }
          setdeliveryAddressSource(JSON.parse(JSON.stringify([
            { label: 'Not Selected', value: 'Not Selected' },
            { label: DOC_MANAGER_ROUTE_ORIGINAL_ADDRESS_LABEL, value: DOC_MANAGER_ROUTE_ORIGINAL_ADDRESS },
            { label: DOC_MANAGER_SPECIFY_TEMP_DELIVERY_ADDRESS_LABEL, value: DOC_MANAGER_SPECIFY_TEMP_DELIVERY_ADDRESS }, ...ad])));
        }
      }
    }
  }, [deliveryAddresses])
  useEffect(() => {
    const { deliveryAddressConfig } = dropdownConfigs;
    const { defaultSelectedValue } = deliveryAddressConfig
    let f;
    if (defaultSelectedValue) {
      f = deliveryAddressSource.find(x => x.value === defaultSelectedValue);
    } else {
      f = deliveryAddressSource.find(x => x.value === DEFAULT_SELECTED_VALUE_DELIVERY_ADDRESS);
    }
    if (f)
      setselectedDeliveryAddress(f.value);
  }, [deliveryAddressSource])

  useEffect(() => {
    const { deliveryTypeConfig } = dropdownConfigs;
    if (deliveryTypes) {
      const { DeliveryTypes } = deliveryTypes;
      if (DeliveryTypes) {
        const { DeliveryType } = DeliveryTypes
        if (DeliveryType) {
          const { values } = deliveryTypeConfig;
          let dt = [];
          if (values && values.length > 0) {
            dt = [...values, ...DeliveryType.map((d) => ({ label: d.description.trim(), value: d.id.trim() }))]
          } else {
            dt = DeliveryType.map((d) => ({ label: d.description.trim(), value: d.id.trim() }));
          }
          setdeliveryTypeSource(JSON.parse(JSON.stringify(dt)))
        }
      }
    }
  }, [deliveryTypes])
  useEffect(() => {
    let f;
    const { deliveryTypeConfig } = dropdownConfigs;
    const { defaultSelectedValue } = deliveryTypeConfig;
    if (defaultSelectedValue) {
      f = deliveryTypeSource.find(x => x.value === defaultSelectedValue);
    } else {
      f = deliveryTypeSource.find(x => x.value === DEFAULT_SELECTED_VALUE_DELIVERY_TYPE);
    }
    if (f) {
      const { value } = f;
      setselectedDeliveryType(value)
    }

  }, [deliveryTypeSource])
  useEffect(() => {
    setselectedReleaseItemsOption(DEFAULT_SELECTED_VALUE_RELEASE_TYPE)
  }, [releaseItemsSource])

  return {

    /** dropdown sources */
    deliveryAddressSource,
    deliveryTypeSource,
    releaseItemsSource,

    /** selected values like delivery address, delivery type, release type, onoff delivery address if entered from separate modal */
    selectedDeliveryAddress,
    selectedDeliveryType,
    selectedReleaseItemsOption,
    docManagerOneOffRouteAddressObject,
    hasdocManagerOneOffRouteAddressObject,

    /** all the setters for public properties */
    setselectedDeliveryAddressRef,
    setselectedDeliveryTypeRef,
    setselectedReleaseItemsOptionRef,
    setdocManagerOneOffRouteAddressObjectRef,
    sethasdocManagerOneOffRouteAddressObjectRef,

    /** properties and setters for the PO-BOX form in the modal window */
    isOpenOnOffAddressModal,
    availableSortOrders,
    description,
    name,
    attention,
    line1,
    line2,
    city,
    state,
    zip,
    intFlag,
    intCountryList,
    intAddr3,
    intCity,
    intState,
    intZip,
    intCountr,
    intPhone,
    intEmail,
    intRetPho,
    intDecision,
    intCtStZp,
    intCntry,
    sortOrderId,

    setisOpenOnOffAddressModalRef,
    setattentionRef,
    setNameRef,
    setLine1Ref,
    setLine2Ref,
    setCityRef,
    setStateRef,
    setZipRef,
    setIntAddr3Ref,
    setIntCityRef,
    setIntStateRef,
    setIntZipRef,
    setIntCountrRef,
    setIntPhoneRef,
    setIntEmailRef,
    setIntRetPhoRef,
    setIntDecisionRef,
    setIntCtStZpRef,
    setIntCntryRef,
    setsortOrderIdRef,

    /** validation messages and warning attributes and the validation loader */
    validationMessage,
    warningMessage,
    checkWarningAttribute,
    isLoadingValidation,

    /** public methods to select a field value and clear all fields */
    setFieldValue,
    clearFields,
    resetFields,
    resetDocManagerOnOffAddress,
    persistDocManagerOnOffAddress,
    saveDocManagerOneOffAddress,
    ClearFormFields,

    /** other attributes like isError and isLoading for the dropdowns */
    isErrorDeliveryAddress,
    isLoadingDeliveryAddress,
    isErrorDeliveryType,
    isLoadingDeliveryType,
    isLoadingCountryList,

    /** confirmation modal upper panel for delivery address */
    confirmationModalMarkup,
    renderConfirmationModalUpperPanel

  }
}

export default useDocumentRouteDeliveryFilter;