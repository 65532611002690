import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDashbordServiceRequest } from '../../api/useDashboard';
import { LoadingPage } from '../../components';
import withAxiosCancellation from '../../components/withAxiosCancellation'


const ServiceRequest = ({getCancelSourceWithName}) => {
  const { dataSourceDesc } = useSelector(({ context }) => context?.context || {});
  const clientId = dataSourceDesc.split('-')[0].trim().replace('_', '');
  const { data: serviceActiveTicketData, isLoading: sIsLoading } = useDashbordServiceRequest(clientId, getCancelSourceWithName('serviceActiveTicketData'));


  return (
    <>
      <div>
        <h3 className="serviceRequestHeader">Service Requests</h3>
        <div className="ServiceRequestBody">
          <p>
            To submit a service request, please access customer care hub at &nbsp;
            <a href="https://customercare.changehealthcare.com/public/home.html" target="_blank" >https://customercare.changehealthcare.com/public/home.html</a>
          </p>


        </div>
      </div>
    </>
  )
}

export default withAxiosCancellation(ServiceRequest)