import { createSelector } from 'reselect';
import { GRID_CONFIGS_BY_RECORD_TYPE } from './constants';

export const selectGroupsList = state => state.groupsList;
export const selectRuleTypesList = state => state.ruleTypesList;
export const selectDetailsPaneState = state => state.detailsPaneState;
export const selectGroupsState = state => state.groups;
export const selectRulesForRuleTypesList = state => state;
const selectruleTypesList = ({ ruleTypesList }) => ruleTypesList;


export const selectCurrentDetailsRecord = createSelector(
  selectGroupsList,
  selectRuleTypesList,
  (groupsList, { selectedDetailsRecordId }) => groupsList.records.find(({ id }) => id === selectedDetailsRecordId),
);

export const selectIsViewingRuleDetails = createSelector(
  selectDetailsPaneState,
  detailsPaneState => detailsPaneState.isAdvancedViewShown,
);

export const selectSelectedRuleRecord = createSelector(
  selectRuleTypesList,
  selectedRule => selectedRule.selectedRuleRecord,
);

export const selectSelectedRuleRecordStatus = createSelector(
  selectRulesForRuleTypesList,
  selectedRule => {
    let recordsResponse = [];
    const {
      selectedRuleRecordId,
      records,
    } = selectedRule.rulesForRuleTypesList;
    if (records.length > 0) {
      const recordsResponse1 =
        selectedRuleRecordId && records
          ? records.find(element => element.id === selectedRuleRecordId)
          : [];
      recordsResponse = recordsResponse1 ? recordsResponse1.status : undefined;
    } else {
      recordsResponse =
        selectedRule && selectedRule.ruleTypesList.selectedRuleRecord
          ? selectedRule.ruleTypesList.selectedRuleRecord.status
          : [];
    }
    return recordsResponse;
  },
);

export const selectGridConfig = createSelector(
  selectruleTypesList,
  ({ selectedRuleTypeId }) => GRID_CONFIGS_BY_RECORD_TYPE[selectedRuleTypeId],
);

export const selectLoadingState = state => state.loadingState.loading;

export const selectRuleRecords = state => state.rulesForRuleTypesList.records;

export const selectRuleDetailsList = state => state.ruleDetails;

export const selectCurrentTab = state => state.ruleTypesList.currentTab;

export const selectNotificationState = state =>
  state.notificationState.notifications;

export const selectNotificationStateErrors = state =>
  state.ruleDetails.errorNotifications;
