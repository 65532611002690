import React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import { Page } from '../components';

import DeliveryAddressesMain from '../containers/AddressManager/DeliveryAddressesMain'
import DeliveryAddressesDetail from '../containers/AddressManager/DeliveryAddressesDetail'
import ReturnAddressesMain from '../containers/AddressManager/ReturnAddressesMain'
import ReturnAddressesDetail from '../containers/AddressManager/ReturnAddressesDetail'

const DeliveryAddressesPage=()=>(
  <Page
    ListPaneContent={DeliveryAddressesMain}
    DetailPaneContent={DeliveryAddressesDetail}
  />
)
const ReturnAddressesPage=()=>(
  <Page
    ListPaneContent={ReturnAddressesMain}
    DetailPaneContent={ReturnAddressesDetail}
  />
)

const AddressRoutes = () => (
  <Switch>
    <Route path="/AddressManager/Delivery" component={DeliveryAddressesPage} />
    <Route path="/AddressManager/Return" component={ReturnAddressesPage} />
    <Route exact path="/AddressManager">
      <Redirect to="/AddressManager/Delivery" />
    </Route>
  </Switch>
)

export default AddressRoutes;
