import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Checkbox, Tooltip } from 'ui-core-ssgr';
import {
  TruncatedColumnMainGrids,
  WordTagWidthSmall,
  GrayedOutWordTagSmall,
  CustomInactiveColumn,
  CustomActiveColumn,
} from '../../../../shared/styles/styledComponents';

import { floatToDollarString } from '../../../../shared/helpers/money';
import { isA11yClick } from '../../../../shared/helpers/domEvents';
import { mapIterable } from '../../../../shared/helpers/iterables';
import constants from './constants';

const onKeyPress = (e, callback) => {
  if (isA11yClick(e)) {
    e.preventDefault();
    callback(e);
  }
};

export const ClickableRow = ({
  onClickThrough = () => false,
  onRowSelect = () => false,
  supportSelection = false,
  isSelected = false,
  isFocused = false,
  selectionKey = '',
  selectedItemIds,
  disabledColumns = new Set([]),
  columns = new Set([]),
  record,
}) => {
  const classes = [];
  if (record.emboldened) classes.push('emboldened');
  if (isFocused) classes.push('focused');

  return (
    <tr
      onClick={e => {
        onClickThrough(e, record);
      }}
      onKeyPress={e => onKeyPress(e, onClickThrough)}
      role="button"
      tabIndex="0"
      className={classes.join(' ')}
    >
      {supportSelection ? (
        <td className="select-checkbox" key="select column">
          <Checkbox
            name={`${record[selectionKey].replace(
              / /g,
              '-',
            )}-row-select-checkbox`}
            className="table-row-checkbox"
            onChange={() => onRowSelect(record, isSelected)}
            value={selectedItemIds.has(record.id)}
            stopClickPropagation
          />
        </td>
      ) : null}
      {mapIterable(columns.values(), c => {
        if (disabledColumns.has(c.dataName)) return null;

        if (c.dataName === 'name') {
          return (
            <TruncatedColumnMainGrids key={c.dataName} className={c.dataName}>
              {record.name}
            </TruncatedColumnMainGrids>
          );
        }
        if (c.dataName === 'status') {
          switch (constants.getStatus(record.status)) {
            case constants.Statuses.TEST:
              return (
                <td key={c.dataName} className={c.dataName}>
                  <WordTagWidthSmall type="neutral" label="test" />
                </td>
              );
            case constants.Statuses.EDITED_TEST_LIVE:
              return (
                <td key={c.dataName} className={c.dataName}>
                  <WordTagWidthSmall type="neutral" label="test" />
                  <WordTagWidthSmall type="positive" label="live" />
                </td>
              );
            case constants.Statuses.TEST_LIVE:
              return (
                <td key={c.dataName} className={c.dataName}>
                  <GrayedOutWordTagSmall label="test" />
                  <WordTagWidthSmall type="positive" label="live" />
                </td>
              );
            default:
              return <td key={c.dataName} className={c.dataName} />;
          }
        }
        if (c.dataName === 'activeGroup') {
          return (
            <TruncatedColumnMainGrids key={c.dataName} className={c.dataName}>
              {Array.prototype.map
                .call(record.activeGroup, s => ` ${s}`)
                .toString()}
            </TruncatedColumnMainGrids>
          );
        }

        if (c.dataName === 'description') {
          return (
            <TruncatedColumnMainGrids key={c.dataName} className={c.dataName}>
              {record.description}
            </TruncatedColumnMainGrids>
          );
        }
        if (c.dataName === 'userId') {
          return (
            <TruncatedColumnMainGrids key={c.dataName} className={c.dataName}>
              {record.userId}
            </TruncatedColumnMainGrids>
          );
        }

        if (c.dataName === 'dateModified') {
          return (
            <td key={c.dataName} className={c.dataName}>
              {moment(record.dateModified)
                .format('M/DD/YYYY h:mma')
                .toUpperCase()}
            </td>
          );
        }
        if (c.dataName === 'tested') {
          switch (record.trialed) {
            case true:
              return (
                <CustomActiveColumn key={c.dataName} className={c.dataName}>
                  {' '}
                  Yes
                </CustomActiveColumn>
              );
            case false:
              return (
                <CustomInactiveColumn key={c.dataName} className={c.dataName}>
                  No
                </CustomInactiveColumn>
              );
            default:
              return <td key={c.dataName} className={c.dataName} />;
          }
        }

        return (
          <td key={c.dataName} className={c.dataName}>
            {c.isCurrency
              ? floatToDollarString(record[c.dataName])
              : record[c.dataName]}
          </td>
        );
      })}
      <td />
    </tr>
  );
};

ClickableRow.propTypes = {
  columns: PropTypes.shape({
    dataName: PropTypes.string,
    values: PropTypes.func,
  }).isRequired,
  disabledColumns: PropTypes.shape({
    dataName: PropTypes.string,
    has: PropTypes.func,
  }).isRequired,
  record: PropTypes.any.isRequired,
  onClickThrough: PropTypes.func,
  onRowSelect: PropTypes.func,
  supportSelection: PropTypes.bool,
  isSelected: PropTypes.bool,
  selectionKey: PropTypes.string,
  selectedItemIds: PropTypes.object,
  isFocused: PropTypes.bool,
};

export default ClickableRow;
