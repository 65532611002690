import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Checkbox, Tooltip } from 'ui-core-ssgr';
import {
  TruncatedColumn,
  DefaultLengthWordTag,
  WordTagWidthSmall,
  GrayedOutWordTagSmall,
} from '../../../../../../shared/styles/styledComponents';

import { floatToDollarString } from '../../../../../../shared/helpers/money';
import { isA11yClick } from '../../../../../../shared/helpers/domEvents';
import { mapIterable } from '../../../../../../shared/helpers/iterables';

const onKeyPress = (e, callback) => {
  if (isA11yClick(e)) {
    e.preventDefault();
    callback(e);
  }
};

export const ClickableRow = ({
  onClickThrough = () => false,
  onRowSelect = () => false,
  supportSelection = false,
  isSelected = false,
  isFocused = false,
  selectionKey = '',
  disabledColumns = new Set([]),
  columns = new Set([]),
  record,
}) => {
  const classes = [];
  if (isFocused) classes.push('focused');

  return (
    <tr
      onClick={e => {
        onClickThrough(e, record);
      }}
      onKeyPress={e => onKeyPress(e, onClickThrough)}
      role="button"
      tabIndex="0"
      className={classes.join(' ')}
    >
      {supportSelection ? (
        <td className="select-checkbox" key="select column">
          <Checkbox
            name={`${record[selectionKey].replace(
              / /g,
              '-',
            )}-row-select-checkbox`}
            className="table-row-checkbox"
            onChange={() => onRowSelect(record, isSelected)}
            value={isSelected}
            stopClickPropagation
          />
        </td>
      ) : null}
      {mapIterable(columns.values(), c => {
        if (disabledColumns.has(c.dataName)) return null;
        if (c.dataName === 'groupName') {
          return (
            <TruncatedColumn key={c.dataName} className={c.dataName}>
              {record.groupId ? (
                <Tooltip
                  tooltipWidth={120}
                  tooltipContent={`Group ID: ${record.groupId}`}
                >
                  {record.groupName}
                </Tooltip>
              ) : (
                <br />
              )}
            </TruncatedColumn>
          );
        }
        if (c.dataName === 'groupStatus') {
          let wordTagType = '';
          let wordTagLabel = '';
          if (record.groupStatus === 'active') {
            wordTagType = 'positive';
            wordTagLabel = 'ACTIVE';
          }
          if (record.groupStatus === 'inactive') {
            wordTagType = 'neutral';
            wordTagLabel = 'INACTIVE';
          }
          if (record.groupStatus === 'termed') {
            wordTagType = 'negative';
            wordTagLabel = 'TERMED';
          }
          return (
            <td key={c.dataName} className={c.dataName}>
              <DefaultLengthWordTag type={wordTagType} label={wordTagLabel} />
            </td>
          );
        }

        if (c.dataName === 'status') {
          if (record.status.includes('L') && record.status.includes('T')) {
            return (
              <td>
                <WordTagWidthSmall type="neutral" label="test" />
                <WordTagWidthSmall type="positive" label="live" />
              </td>
            );
          }
          if (record.status[0] === 'L') {
            return (
              <td key={c.dataName} className={c.dataName}>
                <GrayedOutWordTagSmall label="test" />
                <WordTagWidthSmall type="positive" label="live" />
              </td>
            );
          }
          if (record.status[0] === 'T') {
            return (
              <td key={c.dataName} className={c.dataName}>
                <WordTagWidthSmall type="neutral" label="test" />
              </td>
            );
          }
        }

        if (c.dataName === 'userId') {
          return (
            <TruncatedColumn key={c.dataName} className={c.dataName}>
              {record.userId}
            </TruncatedColumn>
          );
        }
        if (c.dataName === 'dateModified') {
          return (
            <td key={c.dataName} className={c.dataName}>
              {moment(record.dateModified)
                .format('M/DD/YYYY h:mma')
                .toUpperCase()}
            </td>
          );
        }

        return (
          <td key={c.dataName} className={c.dataName}>
            {c.isCurrency
              ? floatToDollarString(record[c.dataName])
              : record[c.dataName]}
          </td>
        );
      })}
      <td />
    </tr>
  );
};

ClickableRow.propTypes = {
  columns: PropTypes.shape({
    dataName: PropTypes.string,
    values: PropTypes.func,
  }).isRequired,
  disabledColumns: PropTypes.shape({
    dataName: PropTypes.string,
    has: PropTypes.func,
  }).isRequired,
  /* eslint-disable react/forbid-prop-types */
  record: PropTypes.any.isRequired,
  /* eslint-disable react/forbid-prop-types */
  onClickThrough: PropTypes.func,
  onRowSelect: PropTypes.func,
  supportSelection: PropTypes.bool,
  isSelected: PropTypes.bool,
  selectionKey: PropTypes.string,
  isFocused: PropTypes.bool,
};

export default ClickableRow;
