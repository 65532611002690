import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LoadingIndicator } from 'ui-core-ssgr';
import { SubDetailHeader } from './styles';
import { PaddingWrapper } from '../../../../shared/styles/spacing';
import LiveAssocDetailsGridWrapper from '../LiveAssocDetailsGridWrapper';
import {
  LoadingContainer,
  GridLoadingWrapper,
} from '../../../../shared/styles/styledComponents';

export default class LiveAssocDetailsForm extends Component {
  componentDidMount() {
    const {
      selectedRecord,
      triggerGetLiveAssocRuleTypesRequest,
      deleteAllNotifications,
    } = this.props;
    const groupId = selectedRecord.id;
    deleteAllNotifications();
    triggerGetLiveAssocRuleTypesRequest({ groupId });
  }

  /**
   * Have to do this because it's not landing on the basic tab EVERY TIME the  user clicks on a group
   * from the groups list (Acceptance criteria -  MCDS-10293), It only works the first time and sticks
   * to the same tab for any subsequent groups.  The LiveAssoc tab is not unmounting from the previous
   * selection, therefore triggerGetLiveAssocRuleTypesRequest is not getting dispatched because it's in
   * componentDidMount life cycle method. This method will be removed once that gets fixed.
   *
   * @param {*} nextProps
   * @param {*} nextState
   */
  shouldComponentUpdate(nextProps) {
    const { selectedRecord, triggerGetLiveAssocRuleTypesRequest } = this.props;
    const nextGroupId = nextProps.selectedRecord.id;
    if (selectedRecord.id !== nextGroupId) {
      triggerGetLiveAssocRuleTypesRequest({ groupId: nextGroupId });
    }
    return true;
  }

  render() {
    const { records, loading } = this.props;

    return (
      <div>
        <PaddingWrapper />
        <SubDetailHeader
          domID="liveassoc-tab-header"
          title="Live Associated Rules"
        />
        {loading.has('liveAssocRuleTypesGridLoading') ? (
          <LoadingContainer>
            <GridLoadingWrapper>
              <LoadingIndicator
                domID="group-grid-loading-indicator"
                length="45px"
              />
            </GridLoadingWrapper>
          </LoadingContainer>
        ) : (
          <LiveAssocDetailsGridWrapper records={records} />
        )}
      </div>
    );
  }
}

LiveAssocDetailsForm.propTypes = {
  selectedRecord: PropTypes.object,
  triggerGetLiveAssocRuleTypesRequest: PropTypes.func.isRequired,
  records: PropTypes.array,
};
