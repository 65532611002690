/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import styled from 'styled-components/dist/styled-components'
import { useLayout } from 'ui-core/dist/useLayout';
import DetailPaneHeader from 'ui-core/dist/DetailPaneHeader';
import { useSelector, useDispatch } from 'react-redux';
import {
  Modal, Button, ModalHeader, Input, ModalBody
} from 'ui-core/dist';
import { SelectDropdown } from '../../../components'
import { detailPaneAnimStates } from '../../../commonResources/constants/animStates';
import ErrorPage from '../../ErrorPage';
import LoadingComponent from '../../../components/LoadingComponent';
import {
  useGetSelectedAddressCorrectionExclusion, useAddAddressCorrectionExclusion,
  useModifyAddressCorrectionExclusion
} from '../../../api/useClientAdministrationAddressExclusion'
import useNotificationBannerHook from '../../../useNotificationBannerHook';
import Authorizer from '../../../containers/Authorizer';
const AddressCorrectionExclusionDetail = () => {
  const {
    expandCollapseDetailPaneButtonConfig,
    closeDetailPaneButtonConfig,
    detailAnimationState,
    closeDetailPane
  } = useLayout()

  const { notify } = useNotificationBannerHook();
  const [name, setname] = useState('')
  const [address, setaddress] = useState('')
  const [description, setdescription] = useState('')
  const [city, setcity] = useState('')
  const [state, setstate] = useState('')
  const [zipCode, setzipCode] = useState('')
  const [country, setcountry] = useState('')
  const [confirmationModalMode, setconfirmationModalMode] = useState('')
  const [isOpenConfirmationModal, setisOpenConfirmationModal] = useState(false)

  const [count, setcount] = useState(0)

  const { addressExclusionSelectedRecord, addressExclusionMode, refetchAddressExclusionList }
    = useSelector(({ clientAdministration }) => clientAdministration);

  useEffect(() => {
    if (addressExclusionSelectedRecord) {
      if (addressExclusionSelectedRecord.key) {
        getShowMoreData(addressExclusionSelectedRecord.key);
      } else {
        onClearCurrentRecordValues()
      }
    }
  }, [addressExclusionSelectedRecord]);

  const getShowMoreData = (key) => {
    postDataShowMoreInfo({ key, count })
    setcount(count + 1)
  }

  const { showMoreInfo, postData: postDataShowMoreInfo, isLoading: isLoadingShowMoreInfo,
    isError: isErrorShowMoreInfo, error: errorShowMoreINfo } = useGetSelectedAddressCorrectionExclusion()

  const { data: infoAddAddressExclusion, postData: addAddressCorrectionExclusion, metaData: addAddressCorrectionExclusionResponse, isLoading: isLoadingaddAddressCorrectionExclusion,
    isError: isErroraddAddressCorrectionExclusion, error: erroraddAddressCorrectionExclusion } = useAddAddressCorrectionExclusion()

  const { data: infoModifyAddressExclusion, postData: modifyAddressCorrectionExclusion, metaData: modifyAddressCorrectionExclusionResponse, isLoading: isLoadingmodifyAddressCorrectionExclusion,
    isError: isErrormodifyAddressCorrectionExclusion, error: errormodifyAddressCorrectionExclusion } = useModifyAddressCorrectionExclusion()

  const onResetCurrentRecordValues = () => {
    if (showMoreInfo) {
      setname(showMoreInfo?.name)
      setaddress(showMoreInfo?.address)
      setdescription(showMoreInfo?.description)
      setcity(showMoreInfo?.city)
      setstate(showMoreInfo?.state)
      setzipCode(showMoreInfo?.zipCode)
      setcountry(showMoreInfo?.country)
    }
  }

  useEffect(() => {
    if (addAddressCorrectionExclusionResponse) {
      const { status, statusText } = addAddressCorrectionExclusionResponse;
      if (status === 200 || statusText === "OK") {
        if (infoAddAddressExclusion && infoAddAddressExclusion.fs) {
          const { fs } = infoAddAddressExclusion;
          if (fs) {
            notify(fs, 'negative')
            return;
          }
        }
        notify('Address Conversion Exclusion Added successfully', 'positive')
        closeDetailPane()
        if (refetchAddressExclusionList) refetchAddressExclusionList()
      }
    }
  }, [addAddressCorrectionExclusionResponse])

  useEffect(() => {
    if (modifyAddressCorrectionExclusionResponse) {
      const { status, statusText } = modifyAddressCorrectionExclusionResponse;
      if (status === 200 || statusText === "OK") {
        if (infoModifyAddressExclusion && infoModifyAddressExclusion.fs) {
          const { fs } = infoModifyAddressExclusion;
          if (fs) {
            notify(fs, 'negative')
            return;
          }
        }
        notify('Address Conversion Exclusion Updated successfully', 'positive')
        closeDetailPane()
        if (refetchAddressExclusionList) refetchAddressExclusionList()
      }
    }
  }, [modifyAddressCorrectionExclusionResponse])

  const onSubmitClick = () => {
    if (addressExclusionMode === "Add") {
      setconfirmationModalMode("Add")
    } else if (addressExclusionMode === "Edit") {
      setconfirmationModalMode("Modify")
    }
    setisOpenConfirmationModal(true)
  }
  const onConfirmationYesClick = () => {
    if (confirmationModalMode === "Add") {
      addAddressCorrectionExclusion({
        count,
        name,
        description,
        address,
        city,
        state,
        country,
        zipCode,
        zip4: '',
        id: ''
      })
      setcount(count + 1)

    } else if (confirmationModalMode === "Modify") {
      modifyAddressCorrectionExclusion({
        count, id: showMoreInfo?.id,
        name,
        description,
        address,
        city,
        state,
        country,
        zipCode,
        zip4: ''
      })
      setcount(count + 1)
    }
    setconfirmationModalMode('')
    setisOpenConfirmationModal(false)
  }
  useEffect(() => {
    onResetCurrentRecordValues()
  }, [showMoreInfo])

  const onClearCurrentRecordValues = () => {
    setname('')
    setaddress('')
    setdescription('')
    setcity('')
    setstate('')
    setzipCode('')
    setcountry('')
  }
  const renderName = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_name"
      placeholder=""
      size="small"
      maxLength={64}
      errorMessage="Please enter a valid value for Address 1."
      hasError={name?false:true}
      initialValue={name}
      onChange={(e) => {
        setname(e.target.value || "")
      }}
    />
  ), [name])
  const renderAddress = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_exclAddress"
      placeholder=""
      size="small"
      maxLength={64}
      initialValue={address}
      onChange={(e) => {
        setaddress(e.target.value || "")
      }}
    />
  ), [address])
  const renderDescription = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_exclDescription"
      placeholder=""
      size="small"
      maxLength={50}
      errorMessage="Please enter a valid value for Description."
      hasError={description?false:true}
      initialValue={description}
      onChange={(e) => {
        setdescription(e.target.value || "")
      }}
    />
  ), [description])
  const renderCity = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_exclCity"
      placeholder=""
      size="small"
      maxLength={35}
      errorMessage="Please enter a valid value for City."
      hasError={city?false:true}
      initialValue={city}
      onChange={(e) => {
        setcity(e.target.value || "")
      }}
    />
  ), [city])
  const renderState = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_exclState"
      placeholder=""
      size="small"
      maxLength={2}
      errorMessage="Please enter a valid value for State."
      hasError={state?false:true}
      initialValue={state}
      onChange={(e) => {
        setstate(e.target.value || "")
      }}
    />
  ), [state])
  const renderZipCode = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_exclZipCode"
      placeholder=""
      size="small"
      maxLength={6}
      errorMessage="Please enter a valid value for ZIP."
      hasError={zipCode?false:true}
      initialValue={zipCode}
      onChange={(e) => {
        setzipCode(e.target.value || "")
      }}
    />
  ), [zipCode])
  const renderCountry = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_exclCountry"
      placeholder=""
      size="small"
      maxLength={24}
      initialValue={country}
      onChange={(e) => {
        setcountry(e.target.value || "")
      }}
    />
  ), [country])
  const validateForm = useMemo(() => {
    if (!name || !description || !city || !state || !zipCode) {
      return false;
    }
    return true;
  }, [name, description, city, state, zipCode])

  const renderAddressEntryForm = useMemo(() => {
    if (detailAnimationState > detailPaneAnimStates.COLLAPSED) {
      return (
        <div style={{ marginLeft: '40px' }} className="marginLeft">
          <div>
            <p>
              <div className="displayFlex">Complete the information below, and then click Submit to continue.</div>
            </p>
            <p>
              <div className="displayFlex"><span style={{ color: 'red' }}>* indicates required information</span></div>
            </p>
          </div>
          <br />
          <div className="displayFlex">
            <table className="common-table">
              <tr>
                <td>
                  <label> Description : <span style={{ color: 'red' }}>*</span></label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderDescription}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <label> Address 1 from the Client's Data: <span style={{ color: 'red' }}>*</span></label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderName}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <label> Address 2 from the Client's Data: </label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderAddress}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <label> City from the Client's Data: <span style={{ color: 'red' }}>*</span></label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderCity}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <label> State from the Client's Data: <span style={{ color: 'red' }}>*</span></label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderState}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <label> ZIP Code from the Client's Data: <span style={{ color: 'red' }}>*</span></label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderZipCode}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <label> Country from the Client's Data: </label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderCountry}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td colSpan="2">
                  <div className="displayFlex">
                    <div>
                      <Button
                        domID="btn_exclReset"
                        buttonType="standard"
                        size="small"
                        name="RESET"
                        disabled={addressExclusionMode !== "Edit"}
                        onClick={() => onResetCurrentRecordValues()}
                      />
                    </div>
                    <Authorizer permission='CAN_PERFORM_ADDRESS_CORRECTION_EXCLUSION_MODIFY'>
                      <>
                    <div className="marginLeft">
                      <Button
                        domID="btn_exclCancel"
                        buttonType="standard"
                        size="small"
                        name="CLEAR"
                        onClick={() => onClearCurrentRecordValues()}
                      />
                    </div>
                    <div className="marginLeft">
                      
                      <Button
                        domID="btn_exclSearch"
                        buttonType="standard"
                        size="small"
                        name="SUBMIT"
                        disabled={!validateForm}
                        onClick={() => onSubmitClick()}
                      />
                      
                    </div>
                    </>
                    </Authorizer>
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
            </table>
          </div>
          <Modal isOpen={isOpenConfirmationModal}>
            <ModalHeader
              title={`Do you want to ${confirmationModalMode} ?`}
              onClose={() => setisOpenConfirmationModal(false)}
            />
            <ModalBody>
              {isOpenConfirmationModal ? (
                <div style={{ marginLeft: '34px', marginBottom: '20px' }} className="marginLeft">
                  <div className="displayFlex">
                    <div className="marginLeft">
                      <Button
                        domID="btn_exclYes"
                        buttonType="standard"
                        size="small"
                        name="Yes"
                        onClick={() => onConfirmationYesClick()}
                      />
                    </div>
                    <div className="marginLeft">
                      <Button
                        domID="btn_exclNo"
                        buttonType="standard"
                        size="small"
                        name="No"
                        onClick={() => setisOpenConfirmationModal(false)}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
            </ModalBody>
          </Modal>
        </div>
      )
    }
    return null;
  }, [addressExclusionSelectedRecord, showMoreInfo, detailAnimationState,
    name, address, description, city, state, zipCode, country, validateForm, addressExclusionMode, confirmationModalMode, isOpenConfirmationModal])

  return (
    <>
      <DetailPaneHeader
        title={addressExclusionMode === "Edit" ? "Modify Address Correction Exclusion" : "Add Address Correction Exclusion"}
        layoutControls={[
          expandCollapseDetailPaneButtonConfig,
          closeDetailPaneButtonConfig
        ]}
      />
      <>
        {isLoadingShowMoreInfo || isLoadingaddAddressCorrectionExclusion
          || isLoadingmodifyAddressCorrectionExclusion ? (
            // replace with a better loading component            
            <div style={{ marginLeft: '50%' }}><LoadingComponent /></div>
          ) : isErrorShowMoreInfo || isErroraddAddressCorrectionExclusion
            || isErrormodifyAddressCorrectionExclusion ? (
              // replace with a better error component
              <ErrorPage
                // this prop isn't real but is an example
                error={errorShowMoreINfo || erroraddAddressCorrectionExclusion
                  || errormodifyAddressCorrectionExclusion}
              />
            ) : (
              <>
                {renderAddressEntryForm}
              </>
            )
        }
      </>
    </>
  )
}

export default AddressCorrectionExclusionDetail