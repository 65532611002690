import styled from 'styled-components';
import Collapse from 'rc-collapse';
import { Borders } from '../../../../../../shared/styles/borders';
import { Colors } from '../../../../../../shared/styles/colors';

export const DetailRulesGrid = styled.div`
  background-color: ${Colors.faintestSecondaryGrey};
  padding-top: 0.4rem;
  padding-bottom: 1.5rem;
  padding-left: 2rem;
  padding-right: 1.5rem;

  table {
    background-color: ${Colors.white};
    border-radius: 6px;
    ${Borders.lightGrey};
    box-shadow: 0px 0px 1px 1px ${Colors.defaultLight};
  }
`;

export const DetailPanel = styled(Collapse.Panel)`
  .detail-panel-header {
    ${Borders.lightGrey};
    ${Borders.onlyBottom};
  }
`;

export const LoadMoreButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  & button {
    display: inline-block;
    padding: 4px;
    margin: 10px;
  }
`;

export const InputLabelWrapper = styled.div`
  font-size: 14px;
  line-height: 1.4em;
  font-weight: 700;
  letter-spacing: 0.5px;
  display: inline-block;
`;

export const LoadingWrapper = styled.div`
  display: inline-block;
`;