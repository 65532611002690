/* eslint-disable no-useless-return */
import { useEffect, useRef, useState } from "react";

const useHandleEnterKeyPressEvent=({fields, onEnterKeyPressHandler})=>{

  const fieldsRef=useRef(fields);
  // const onEnterKeyPressHandlerRef=useRef(onEnterKeyPressHandler)
    
  const handleEnterKeyPressEvent=(event, id, value)=>{
    if(event.key === 'Enter'){
      if (id && fieldsRef.current && fieldsRef.current.length>0) {
        const field=fieldsRef.current.find(f=>f.fieldName===id);
        if (field && field.validator) {
          if (field.validator(value)) {
            // if(onEnterKeyPressHandlerRef.current) onEnterKeyPressHandlerRef.current()
            if(onEnterKeyPressHandler) onEnterKeyPressHandler()
            return;
          }
        }
      }
    }
  }

  return {handleEnterKeyPressEvent};
}

export default useHandleEnterKeyPressEvent;