/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import {
  Masthead,
  ListPaneHeader,
  Button,
  NotificationBanner,
  ButtonGroup,
  LoadingIndicator
} from 'ui-core/dist';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Redirect } from 'react-router';
import { setContext, setPermissions, toggleCtxModal } from './modules/actions';
import { SelectDropdown } from '../../components'
import axios from '../../axios';
import './index.css'
import useLocalStorage from '../../hooks/useLocalStorage'
import { useLogout } from '../../hooks';
import { onAvatarMenuSelect, setSupportItems } from '../PageWrapper/modules/actions';
import InactivityTracker from '../InactivityTracker';
import { setSelected as navSelected } from '../SideNav/modules/actions';
import { updateServerConfig } from '../../redux/app/serverConfigState/actions';
import useNotificationBannerHook from '../../useNotificationBannerHook';
import { useMemo } from 'react';
import withErrorBoundary from '../../hooks/withErrorBoundary';

const ChooseContext = () => {
  const { notify } = useNotificationBannerHook()
  const dispatch = useDispatch()
  const [token] = useLocalStorage('user:token')
  const [error, setError] = useState({ key: 1, type: 'negative' })
  const [state, setState] = useState([])
  const [loadingBtnScreen, setLodingBtn] = useState(false)
  const [initialValue, setInitialValue] = useState()
  const [options, setOptions] = useState()
  const [selected, setSelected] = useState()
  const { lastLogin, attempts, passwordExpired, token: sessionToken, userId, userloginuniqueid } = useSelector(({ session }) => session)
  const { accessEntryId, dataSourceDesc, roleName } = useSelector(({ context }) => context?.context || {})
  const {
    userFirstName,
    userEmail,
  } = useSelector(store => ({
    userFirstName: store.session.name,
    userEmail: store.session.email,
    avatarMenuItems: store.app.avatarMenuItems,
    avatarImage: store.app.user.photo,
  }))
  const logout = useLogout();
  useEffect(() => {
    axios.get('/users/contexts',
    {
      headers: {
      'username': userId, userloginuniqueid 
    }
  }).then(res => {
      setState(res.data)
      // if (res.data.length === 1) {
      //   axios.put(`/users/updateContext/${res.data[0].accessEntryId}`,{
      // })
      //     .then(response => {
      //       dispatch(setContext(res.data[0]))
      //       dispatch(setSupportItems('singleContext'))
      //       const permissions = response.data
      //       dispatch(setPermissions(permissions))
      //       if (passwordExpired) {
      //         dispatch(push('/change-password'))
      //       } else {
      //         if (res.data[0].container) {
      //           dispatch(push('/fileTracker'))
      //           dispatch(navSelected('/fileTracker'))
      //         } else {
      //           dispatch(push('/'))
      //           dispatch(navSelected('/'))
      //         }
      //       }
      //     }).catch(err => {
      //       setError({
      //         key: error.key + 1,
      //         text: err.displayMessage,
      //       })
      //     })

      // }

      setOptions(res.data.map(ctx => ({
        label: `${ctx.dataSourceDesc}: ${ctx.roleName}`,
        value: ctx.accessEntryId
      })))

      if (accessEntryId) {
        setInitialValue({
          label: `${dataSourceDesc}: ${roleName}`,
          value: accessEntryId
        })
        setSelected(accessEntryId)
      } else {
        setInitialValue({
          label: `${res.data[0].dataSourceDesc}: ${res.data[0].roleName}`,
          value: res.data[0].accessEntryId
        })
        setSelected(res.data[0].accessEntryId)
      }
    }).catch(err => {
      setError({
        key: error.key + 1,
        text: err.displayMessage,
      })
    })
  }, [])

  const handleChange = (state) => {
    setInitialValue(state)
    setSelected(state.value)
  }

  const handleSubmit = () => {
    setLodingBtn(true)
    axios.put(`/users/updateContext/${selected}`,{
    }).then(res => {
        const ctx = state.filter(c => c.accessEntryId === selected)[0]
        dispatch(setContext(ctx))
        const permissions = res.data
        dispatch(setPermissions(permissions))
        dispatch(updateServerConfig({
          clientLayout: `${ctx.client}${ctx.layout}`,
          userName: userId,
          dataSourceDefinition: ctx.dataSourceDefinition,
          dataSourceId: ctx.dataSourceId,
          token: sessionToken,
          roleId: ctx.roleId,
          userRole: ctx.roleName,
          userPermissions: permissions.map(perm => perm.name)
        }))
        if (passwordExpired) {
          dispatch(push('/change-password'))
        } else {
          if (ctx.container && ctx.container.toString() === '1') {
            dispatch(push('/fileTracker'))
            dispatch(navSelected('/fileTracker'))
          } else {
            dispatch(push('/'))
            dispatch(navSelected('/'))
          }
        }
        setLodingBtn(false)
      }).catch(err => {
        setError({
          key: error.key + 1,
          text: err.displayMessage,
        })
        setLodingBtn(false)
      })
  }

  const handleReset = () => {
    if (accessEntryId) {
      setInitialValue({
        label: `${dataSourceDesc}: ${roleName}`,
        value: accessEntryId
      })
      setSelected(accessEntryId)
    } else {
      setInitialValue({
        label: `${state[0].dataSourceDesc}: ${state[0].roleName}`,
        value: state[0].accessEntryId
      })
      setSelected(state[0].accessEntryId)
    }
  }

  const getFAQ = async () => {
    try {
      const res = await axios.get(`/faq`, { responseType: 'blob' })
      if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
        window.navigator.msSaveOrOpenBlob(res.data, 'FQA.pdf');
      } else {
        const url = URL.createObjectURL(res.data);
        const tab = window.open();
        if(tab)
          tab.location.href = url;
        else
          notify('Please enable allow window pop-up in your browser.','caution')
      }
    } catch (err) {
      notify('Error in displaying FAQ', "negative")
    }
  }

  const handleSupportMenuSelect = (event, { activeItem }) => {
    event.stopPropagation()
    if (activeItem.label === 'Change Context') dispatch(toggleCtxModal())
    if (activeItem.label === 'FAQ') getFAQ()
  }

  const handleAvatarMenuSelect = (event, { activeItem }, payload) => {
    dispatch(onAvatarMenuSelect({ state: payload }));
    if (activeItem.path) {
      dispatch(push(activeItem.path));
    }
  }

  const renderContextDropdown=useMemo(()=>(
<SelectDropdown
                  width="500px"
                  dataTestId="test-ContextDropdown"
                  domID="ContextDropdown"
                  onChange={handleChange}
                  options={options}
                  initialValue={initialValue}
                  label="Choose a context:"
                  className="choose-context__dropdown"
                />
  ),[options, initialValue])
  
  return (
    <>
      <InactivityTracker />
      {!token ? <Redirect to="/login" /> :
         (
            <>
              <Masthead
                currentProductName="Client Access System"
                onLogOutSelect={logout}
                onAvatarMenuSelect={handleAvatarMenuSelect}
                userFirstName={userFirstName}
                userLastName=''
                onProfileSelect={() => false}
                customUserMenuItems={[{
                  sectionItems: [{
                    id: 'sectionItemsItemSection',
                    label: (accessEntryId && initialValue) ?
                      `${initialValue.label}` :
                      `Active Context: Not Set`,
                  }]
                }]}
                userEmail={userEmail}
                onSupportMenuSelect={handleSupportMenuSelect}
                supportMenuConfig={{
                  shouldShow: true,
                  showBottomAction: false,
                  linkData: {
                    staticContent: [
                      {
                        label: 'FAQ',
                      },
                    ]
                  }
                }}
              />
              <NotificationBanner
                key={error.key}
                dataTestId="test-NotificationBanner"
                domID="NotificationBanner"
                text={error.text}
                type={error.type}
              />
              <div className="choose-context">
                <ListPaneHeader
                  title="Select Active Context"
                />
                <p>Last Successful login was on {lastLogin} <br></br>
        There were {attempts} number of failed attempts since the last successful login.
              </p>
                <p>
                  Select the security context that you want to make active. Each security context
                  in the Select Context list defines the tasks that you can perform on a client&apos;s
                  data for a specific layout.
              </p>
                <p>
                  Note:	You can change contexts at any time by clicking the Change Active
                  Context link in the upper right corner of each Client Access System Web
                  page, and then selecting a different context.
              </p>
                {renderContextDropdown}
                <ButtonGroup>
                {loadingBtnScreen ? <LoadingIndicator/> : ''}
                  <Button
                    buttonType="standard"
                    domID="SubmitButton"
                    dataTestId="test-submit"
                    name="Submit"
                    onClick={handleSubmit}
                    size="medium"
                    disabled={loadingBtnScreen ||  state.length <= 1}
                  />
                  <Button
                    buttonType="standard"
                    domID="ResetButton"
                    dataTestId="test-reset"
                    name="Reset"
                    onClick={handleReset}
                    size="medium"
                    disabled={state.length <= 1}
                  />
                </ButtonGroup>
                {passwordExpired && <p>PASSWORD EXPIRED. Please change your password
                once you choose a context.
              </p>}
              </div>
            </>
          )}
    </>
  )
}

export default withErrorBoundary(ChooseContext);
