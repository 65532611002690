/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable prefer-destructuring */
import React, { useEffect, useState, useMemo } from 'react';
import { SelectDropdown } from '../components'
import styled from 'styled-components/macro';
import { useHistory } from 'react-router'

import {
  SELECT_SORT_FIELD_OPTIONS,
  SORT_ORDER,
  SELECT_SORT_FIELD_RELEASE_DOCUMENT,
  SELECT_SORT_FIELD_RELEASE_GROUP
} from '../containers/DocumentManager/Modules/constants';

const SortFieldDropdownWrapper = styled.div`
  width: ${props=>props.width||'65%'};
`;
const SortOrderDropdownWrapper = styled.div`
  width: ${props=>props.width||'65%'};
`;

const SortingWrapper = ({onChangeSortField=null,onChangeSortOrder=null,isResetFlag,setIsResetFlag,labelContainer,inputContainer,width}) => {
  const history = useHistory();

  // let intialDropdownValue = "";
  const [initialValue,setInitialValue] = useState(SELECT_SORT_FIELD_OPTIONS [0]);
  const [dropdownOption,setDropdownOption] = useState(SELECT_SORT_FIELD_OPTIONS);
  const [sortOrder, setSortOrder] = useState(JSON.parse(JSON.stringify(SORT_ORDER[0])));
  useEffect(()=>{
    if(isResetFlag){
      setInitialValue(JSON.parse(JSON.stringify(dropdownOption[0])))
      setSortOrder(JSON.parse(JSON.stringify(SORT_ORDER[0])))
      setIsResetFlag(false)
    }
  },[isResetFlag])

  useEffect(()=>{
    mapSortFiled()
  },[])
  const mapSortFiled = () => {
    if (history.location.pathname === '/DocumentManager/Release/Document')
    { 
      // intialDropdownValue = SELECT_SORT_FIELD_RELEASE_DOCUMENT [0]
      setDropdownOption(JSON.parse(JSON.stringify(SELECT_SORT_FIELD_RELEASE_DOCUMENT)))
      setInitialValue(JSON.parse(JSON.stringify(SELECT_SORT_FIELD_RELEASE_DOCUMENT [0])))
      // return SELECT_SORT_FIELD_RELEASE_DOCUMENT 
    }
    else if ((history.location.pathname === '/DocumentManager/Release/Group') ||
        (history.location.pathname === '/DocumentManager/Hold/File') ||
        (history.location.pathname === '/DocumentManager/Release/File') ||
        (history.location.pathname === '/DocumentManager/Purge/File') ||
        (history.location.pathname === '/DocumentManager/Route/File'))
    {
      setDropdownOption(SELECT_SORT_FIELD_RELEASE_GROUP)
      setInitialValue(SELECT_SORT_FIELD_RELEASE_GROUP [0])
      // intialDropdownValue = SELECT_SORT_FIELD_RELEASE_GROUP [0]
      // return SELECT_SORT_FIELD_RELEASE_GROUP
    }else{
      setDropdownOption(SELECT_SORT_FIELD_OPTIONS)
      setInitialValue(SELECT_SORT_FIELD_OPTIONS [0])
    }
    
     
    // intialDropdownValue = SELECT_SORT_FIELD_OPTIONS [0]
    // return SELECT_SORT_FIELD_OPTIONS 
  }
  
  const renderContent = useMemo(()=>(
    <>
      {/* <SortFieldWrapper> */}
      <tr>
        {labelContainer ? labelContainer(
          <label>Sort Field:</label>
        ):<label>Sort Field:</label>}
        {inputContainer?inputContainer(
          <SortFieldDropdownWrapper width={width}>
            <SelectDropdown
              width="230px"
              domID="defer_state_input"
              // onChange={(e, state) => this.props.handleDateRangeSelect(e, state)}
              options={dropdownOption}
              size="small"
              initialValue={initialValue}
              onChange={(e)=>onChangeSortField(e)}
            />
          </SortFieldDropdownWrapper>):
          <SortFieldDropdownWrapper width={width}>
            <SelectDropdown
              width="230px"
              domID="defer_state_input"
              options={dropdownOption}
              size="small"
              initialValue={initialValue}
              onChange={(e)=>onChangeSortField(e)}
            />
          </SortFieldDropdownWrapper>}
      </tr>
      <tr><td><p></p></td></tr>
      <tr>
        {labelContainer ? labelContainer(
          <label>Sort Order:</label>
        ):<label>Sort Order:</label>}
        {inputContainer?inputContainer(
          <SortOrderDropdownWrapper width={width}>
            <SelectDropdown
              width="230px"
              domID="defer_state_input"
              onChange={(e) => onChangeSortOrder(e)}
              options={SORT_ORDER}
              initialValue={sortOrder}
              size="small"
            />
          </SortOrderDropdownWrapper>
        ):<SortOrderDropdownWrapper width={width}>
          <SelectDropdown
            width="230px"
            domID="defer_state_input"
            onChange={(e) => onChangeSortOrder(e)}
            options={SORT_ORDER}
            initialValue={sortOrder}
            size="small"
          />
        </SortOrderDropdownWrapper>}
        {/* </SortOrderWrapper> */}
      </tr> 
    </>
  ),[dropdownOption,initialValue,isResetFlag])

  return (
    <>
      {renderContent}
    </>
  )}

export default SortingWrapper;
