import { useAxios } from '../hooks'

const useReportBuilderFields = (props={}) => {
  const { data, ...rest } = useAxios({
    ...props,
    url: `/reportBuilder/fields`,
    method: 'GET',
  })
  return {
    fields: data,
    ...rest
  }
}
export default useReportBuilderFields