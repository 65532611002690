import styled from 'styled-components';
import { Input } from 'ui-core-ssgr';

export const DropOne = styled.div`
  margin-bottom: 30px;
`;
export const DropTwo = styled.div`
  margin-bottom: 30px;
`;
export const DropThree = styled.div`
  margin-bottom: 30px;
`;

export const ContainerBox = styled.div`
  display: flex;
  margin-top: 30px;
  margin-bottom: 30px;
  position: relative;
`;
export const SignatureRuleDropDown = styled.div`
  height: 50px;
  width: 200px;
  margin-right: 35px;
  margin-top: 10px;
`;
export const DollarLabel = styled.div`
  position: absolute;
  top: 25px;
  left: -11px;
`;
export const SignatureImageWrapper = styled.div`
  width: 200px;
  margin-right: 35px;
  margin-top: 10px;
  & > button {
    padding-left: 5px;
  }
`;

export const ImageFilter = styled(Input)`
  margin-left: 870px;
  input {
    padding-right: 1.25em;
    min-width: 240px;
  }
  & > button {
    height: 32px;
    top: 0;
  }
`;

export const SearchButtonImage = styled.img`
  height: 1.25em;
`;

export const RuleTypesDetailRow = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-left:8%;
`;

export const DollarLabelReadOnly = styled.div`
  top: 25px;
  left: -11px;
`;

export const PaddingWrapper = styled.div`
  padding: 0rem 0rem 3rem 0rem;
`;