import { select, call, put, takeLatest } from 'redux-saga/effects';
import _ from'lodash';
import {
  putGroups,
  postGroups,
  deactivateGroup,
  deactivateMultipleGroups,
  activateGroup,
} from './network';
import {
  groupsPutSuccess,
  groupsPutError,
  groupsPostSuccess,
  groupsCloneError,
  setIsActivatingGroup,
  setIsDeactivatingGroup,
  setIsCloningGroupState,
  setIsShowingDeactivateGroupModal,
  setIsShowingActivateGroupModal,
} from './actions';
import { setDefaultGroupDetailsTab } from '../../app/detailsPaneState/actions';
import {
  triggerRuleTypesRequest,
  setSelectedDetailsRecordId,
} from '../ruleTypesList/actions';
import { triggerAllGroupsRequest } from '../groupsList/actions';
import { addNotification } from '../../app/notificationState/actions';
import {
  setLoadingState,
  setLoadingStateForDeactivateGroupModal,
} from '../../app/loadingState/actions';
import {
  ACTIVATE_GROUP_REQUEST_TRIGGER,
  GROUPS_PUT_REQUEST_TRIGGER,
  GROUPS_POST_REQUEST_TRIGGER,
  DEACTIVATE_GROUP_REQUEST_TRIGGER,
} from './constants';


// This generator is the control flow manager that will step through the request
// lifecycle and dispatch actions to update the application at the end
function* groupsPutGenerator(action) {
  // Before we make our network request we can dispatch actions to modify app
  // state, for example to show a spinner:
  // yield put(AppActions.startWaiting());\
  yield put(
    setLoadingState({
      id: action.payload.groupId,
      isLoading: true,
    }),
  );

  try {
    // get values sent via server-side config to use with this call
    const userConfig = yield select(({ serverConfig }) => serverConfig);

    const response = yield call(putGroups, userConfig, action.payload);
    if (response.groupId) {
      // If we got what we expect, dispatch our success action
      if (action.payload) {
        yield put(
          groupsPutSuccess({
            group: response,
          }),
        );
        yield put(
          setLoadingState({
            id: action.payload.groupId,
            isLoading: false,
          }),
        );
        yield put(
          addNotification({
            id: action.payload.groupId,
            type: 'positive',
            msg: response.success,
          }),
        );
      }
      yield put(triggerRuleTypesRequest({ groupId: response.groupId }));
      yield put(setSelectedDetailsRecordId({ id: response.groupId }));
      yield put(
        triggerAllGroupsRequest(action.payload.triggerAllGroupsRequestParams),
      );
    } else {
      // We got a 200 response that was valid JSON, but the expected data type
      // was not returned from the server so we pass a custom error out with our
      // failure action
      yield put(
        setLoadingState({
          id: action.payload.groupId,
          isLoading: false,
        }),
      );
      yield put(
        addNotification({
          id: action.payload.groupId,
          type: 'negative',
          msg: response.error.message,
        }),
      );
      yield put(
        groupsPutError({
          msg: new Error(response),
        }),
      );
    }
  } catch (thrownError) {
    yield put(
      setLoadingState({
        id: action.payload.groupId,
        isLoading: false,
      }),
    );
    yield put(
      addNotification({
        id: thrownError.errorId,
        type: 'negative',
        msg: thrownError.message,
      }),
    );
    yield put(groupsPutError(thrownError));
  }
}

// POST generator for creating or cloning a group
function* groupsPostGenerator(action) {
  // TODO: extend this for POST new group
  yield put(
    setLoadingState({
      id: action.payload.groupId,
      isLoading: true,
    }),
  );

  try {
    // get values sent via server-side config to use with this call
    const userConfig = yield select(({ serverConfig }) => serverConfig);
    const response = yield call(postGroups, userConfig, action.payload);
    if (response.groupId) {
      if (action.payload) {
        yield put(
          groupsPostSuccess({
            groupId: action.payload.groupId,
          }),
        );
        yield put(
          setLoadingState({
            id: action.payload.groupId,
            isLoading: false,
          }),
        );
        yield put(setSelectedDetailsRecordId({ id: response.groupId }));
        yield put(
          triggerAllGroupsRequest(action.payload.triggerAllGroupsRequestParams),
        );
        yield put(triggerRuleTypesRequest({ groupId: response.groupId }));
        yield put(setIsCloningGroupState(false));
        yield put(
          addNotification({
            id: response.groupId,
            type: 'positive',
            msg: `The group has been ${
              action.payload.isCloningGroup ? 'cloned' : 'created'
            } successfully.`,
          }),
        );
      }
    } else {
      // We got a 200 response that was valid JSON, but the expected data type
      // was not returned from the server so we pass a custom error out with our
      // failure action
      yield put(
        setLoadingState({
          id: action.payload.groupId,
          isLoading: false,
        }),
      );
      yield put(
        addNotification({
          id: action.payload.groupId,
          type: 'negative',
          msg: response.error.message,
        }),
      );
      yield put(
        groupsCloneError({
          msg: new Error(response),
        }),
      );
    }
  } catch (thrownError) {
    yield put(
      setLoadingState({
        id: action.payload.groupId,
        isLoading: false,
      }),
    );
    yield put(
      addNotification({
        id: thrownError.errorId,
        type: 'negative',
        msg: thrownError.message,
      }),
    );
    yield put(groupsPutError(thrownError));
  }
}

// This generator is the control flow manager that will step through the request
// lifecycle and dispatch actions to update the application at the end
function* deactivateGroupGenerator(action) {
  // Before we make our network request we can dispatch actions to modify app
  // state, for example to show a spinner:
  // yield put(AppActions.startWaiting());\
  yield put(setIsDeactivatingGroup(true));
  yield put(
    setLoadingStateForDeactivateGroupModal({
      id: 'deactivateGroup',
      isLoading: true,
    }),
  );
  
  try {
    // get values sent via server-side config to use with this call
    const userConfig = yield select(({ serverConfig }) => serverConfig);

    const networkMethod = action.payload.isMulti ? deactivateMultipleGroups : deactivateGroup

    const response = yield call(networkMethod, userConfig, action.payload);

    yield put(
      setLoadingStateForDeactivateGroupModal({
        id: 'deactivateGroup',
        isLoading: false,
      }),
    );

    let responseMessage = '';

    if (response && response.status) {
      if (action.payload.isMulti) {
        responseMessage = `The selected group(s) ${action.payload.groupName} has been deactivated successfully`;
      } else {
        responseMessage = response.message;
      }
      yield put(
        addNotification({
          id: action.payload.groupId,
          type: 'positive',
          msg: responseMessage,
        }),
      );
      const status = 'all';
      const name = 'dateModified';
      const sortOrder = 'desc';
      const queryValue = '';
      yield put(
        triggerAllGroupsRequest({ status, name, sortOrder, queryValue }),
      );
      if (!window.location.href.includes('ruletype')) {
        for (const groupId of action.payload.groupId) {
          yield put(triggerRuleTypesRequest({ groupId }));
        }
      }
      yield put(setIsShowingDeactivateGroupModal(false));
      yield put(setIsDeactivatingGroup(false));
      yield put(setDefaultGroupDetailsTab(0));
    } else {
      yield put(setIsShowingDeactivateGroupModal(false));
      yield put(
        addNotification({
          id: action.payload.groupId,
          type: 'negative',
          msg: response.error.message,
        }),
      );
    }
    yield put(setIsShowingDeactivateGroupModal(false));
    yield put(setIsDeactivatingGroup(false));
  } catch (thrownError) {
    yield put(
      setLoadingStateForDeactivateGroupModal({
        id: 'deactivateGroup',
        isLoading: false,
      }),
    );
    yield put(
      addNotification({
        id: thrownError.errorId,
        type: 'negative',
        msg: !_.isEmpty(thrownError.message) ? thrownError.message.message : ''
      }),
    );
    yield put(setIsShowingDeactivateGroupModal(false));
    yield put(groupsPutError(thrownError));
  }
}

function* activateGroupGenerator(action) {
  yield put(setIsActivatingGroup(true));
  yield put(
    setLoadingState({
      id: 'activateGroup',
      isLoading: true,
    }),
  );
  try {
    const userConfig = yield select(({ serverConfig }) => serverConfig);
    const response = yield call(activateGroup, userConfig, action.payload);
    if (response && !response.error) {
      const status = 'all';
      const name = 'dateModified';
      const sortOrder = 'desc';
      const queryValue = '';
      yield put(
        setLoadingState({
          id: 'activateGroup',
          isLoading: false,
        }),
      );
      yield put(
        addNotification({
          id: action.payload.groupId,
          type: 'positive',
          msg: response.success,
        }),
      );
      yield put(
        triggerAllGroupsRequest({ status, name, sortOrder, queryValue }),
      );
      if (!window.location.href.includes('ruletype')) {
        yield put(triggerRuleTypesRequest({ groupId: action.payload.groupId }));
      }
      yield put(setIsShowingActivateGroupModal(false));
      yield put(setIsActivatingGroup(false));
      yield put(setDefaultGroupDetailsTab(0));
    } else {
      yield put(
        setLoadingState({
          id: 'activateGroup',
          isLoading: false,
        }),
      );
      yield put(
        addNotification({
          id: action.payload.ruleDetailId,
          type: 'negative',
          msg: response.error.message,
        }),
      );
    }
  } catch (thrownError) {
    yield put(
      setLoadingState({
        id: 'activateGroup',
        isLoading: false,
      }),
    );
    yield put(
      addNotification({
        id: thrownError.errorId,
        type: 'negative',
        msg: thrownError.message,
      }),
    );
    yield put(setIsShowingActivateGroupModal(false));
    yield put(groupsPutError(thrownError));
  }
}

// This saga gets attached to the redux store, and listens for action types
// similar to the reducers. When it matches an action type it will run the
// generator indicated and pass it the action as an argument.
export function* groupsPutSaga() {
  yield takeLatest(ACTIVATE_GROUP_REQUEST_TRIGGER, activateGroupGenerator);
  yield takeLatest(GROUPS_PUT_REQUEST_TRIGGER, groupsPutGenerator);
  yield takeLatest(DEACTIVATE_GROUP_REQUEST_TRIGGER, deactivateGroupGenerator);
}

export function* groupsPostSaga() {
  yield takeLatest(GROUPS_POST_REQUEST_TRIGGER, groupsPostGenerator);
}
