import { select, call, put, takeLatest } from 'redux-saga/effects';
import get from 'lodash/get';
import {
  getRuleDetailElements,
  putRuleDetails,
  getAddNewAddressElements,
  postAddNewAddressElements,
  postImageUpload,
  postRuleDetails,
  getRuleImages,
  getRuleImagesCount,
  convertImageUpload,
  putCommitImage
} from './network';

import {
  ruleDetailElementsRecieved,
  ruleDetailElementsError,
  ruleDetailPostRequestSuccess,
  ruleDetailPostRequestError,
  ruleDetailPutRequestSuccess,
  ruleDetailPutRequestError,
  setIsShowingProductionArrangementModal,
  setIsShowingCustomerServiceMessageModal,
  setIsShowingReturnAddressModal,
  setIsShowingDuplexRuleModal,
  setIsShowingSignatureRuleModal,
  setIsShowingCheckTextRuleModal,
  setIsShowingImageRuleModal,
  setIsShowingAutoHoldModal,
  setIsShowingMemberIdModal,
  setIsShowingTaxIdArrangementRuleModal,
  setIsShowingErisaMessageModal,
  setShowingHighDollarReviewModal,
  setIsShowingProviderInsuredOptionMessageModal,
  setIsShowingGeneralMessageModal,
  setIsShowingPDFTaggingModal,
  setIsCloningRuleDetail,
  setIsPostingRuleDetail,
  setIsEditingRuleDetail,
  setIsReadOnlyRuleDetail,
  setIsShowingAddNewAddressModal,
  getAddNewAddressElementsRecieved,
  getAddNewAddressElementsError,
  postImageUploadRecieved,
  postImageUploadError,
  setIsShowingImageUploadModal,
  getRuleImagesRecieved,
  getRuleImagesError,
  getRuleImagesCountRecieved,
  getRuleImagesCountError,
  convertImageUploadRecieved,
  convertImageUploadError,
  postCommitImageRecieved,
  postCommitImageError,
  setIsShowingCommitImageModal,
  setIsShowingImageLibraryModal,
  setSelectedRuleDetail
} from './actions';
import {
  setIsViewingRuleDetails,
  triggerRuleDetailsRequest,
  triggerRuleTypesRequest,
  ruleDetailsListReceived
} from '../ruleTypesList/actions';
import {
  RULE_DETAIL_ELEMENTS_TRIGGER,
  RULE_DETAIL_POST_REQUEST_TRIGGER,
  RULE_DETAIL_PUT_REQUEST_TRIGGER,
  ADD_NEW_ADDRESS_GET_ELEMENTS_TRIGGER,
  ADD_NEW_ADDRESS_ELEMENTS_POST_REQUEST_TRIGGER,
  POST_IMAGE_UPLOAD_TRIGGER,
  GET_RULE_IMAGE_TRIGGER,
  GET_RULE_IMAGE_COUNT_TRIGGER,
  RESET_RULE_IMAGE_DATA_TRIGGER,
  CONVERT_IMAGE_UPLOAD_TRIGGER,
  POST_COMMIT_IMAGE_TRIGGER
} from './constants';
import { addNotification } from '../../app/notificationState/actions';
import { setLoadingState } from '../../app/loadingState/actions';
import { getRulesForRuleTypesList } from '../rulesForRuleTypesList/network';
import { getRuleDetails } from '../ruleTypesList/network';
import {
  setSelectedRuleRecord,
  triggerAllRulesForRuleTypesRequest,
  rulesCountFromDBTrigger,
} from '../rulesForRuleTypesList/actions';
import { selectCurrentDetailsRecord } from '../../../containers/GroupDetailView/selectors';

function* ruleDetailElementsGenerator(action) {
  try {
    const userConfig = yield select(({ serverConfig }) => serverConfig);
    const response = yield call(
      getRuleDetailElements,
      userConfig,
      action.payload,
    );
    const detailElements = response;

    yield put(
      ruleDetailElementsRecieved({
        detailElements,
      }),
    );

  } catch (thrownError) {
    yield put(
      addNotification({
        id: thrownError.errorId,
        type: 'negative',
        msg: thrownError.message,
      }),
    );
    yield put(
      ruleDetailElementsError(
        {
          id: action.payload.selectedRuleTypeId,
          msg: thrownError.message,
          code: thrownError.code,
          type: 'ruleDetailElements'
        },
        new Error(
          '[ ruleDetailElementsGenetator ] returned detail elements was not an object',
        ),
      ),
    );
    yield put(ruleDetailElementsError(thrownError));
  }
}

function* postRuleDetailGenerator(action) {
  yield put(
    setLoadingState({
      id: action.payload.ruleId,
      isLoading: true,
    }),
  );
  try {
    const userConfig = yield select(({ serverConfig }) => serverConfig);
    const response = yield call(postRuleDetails, userConfig, action.payload);
    const ruleType = action.payload.ruleType
      ? action.payload.ruleType
      : window.location.href.substring(
        window.location.href.lastIndexOf('=') + 1,
      );

    yield put(
      setLoadingState({
        id: action.payload.ruleId,
        isLoading: false,
      }),
    );
    yield put(
      addNotification({
        id: response.ruleDetailId,
        type: 'positive',
        msg: response.success,
      }),
    );
    if (!window.location.href.includes('ruletype')) {
      const group = yield select(selectCurrentDetailsRecord);
      yield put(triggerRuleTypesRequest({ groupId: group.id }));
    }
    const { ruleId } = action.payload;
    const { rules } = yield call(
      getRulesForRuleTypesList,
      userConfig,
      {
        sortOrder: 'desc',
        sortColumn: 'dateModified',
        offset: 0,
        ruleTypeId: ruleType,
        queryValue: '',
      },
      ruleType,
    );
    // Grabbing the state from the store
    const getState = yield select();
    // Getting the Current Selected Record Active Groups since it does not come back from API
    const currentSelectedRuleRecordActiveGroups = get(getState, 'ruleTypesList.selectedRuleRecord.activeGroup', []);
    // Finding the current rule from the list of updated rules after saving the detail
    const findCurrentRule = rules.find(r => r.id === ruleId);
    const isAdvancedViewShown = !window.location.href.includes('ruletype');
    // Setting the rule with the updates details and also the active groups associated with it
    const rule = {
      ...findCurrentRule,
      activeGroup: currentSelectedRuleRecordActiveGroups
    };
    yield put(
      setIsViewingRuleDetails({
        isAdvancedViewShown,
        selectedRuleRecord: rule,
        selectedRuleTypeId: ruleType,
      }),
    );
    yield put(
      setLoadingState({
        id: action.payload.ruleId,
        isLoading: false,
      }),
    );
    yield put(
      triggerAllRulesForRuleTypesRequest({
        sortOrder: 'desc',
        sortColumn: 'dateModified',
        offset: 0,
        ruleTypeId: ruleType,
        queryValue: '',
      }),
    );
    yield put(
      rulesCountFromDBTrigger({
        sortOrder: 'desc',
        sortColumn: 'dateModified',
        offset: 0,
        ruleTypeId: ruleType,
        queryValue: '',
      }),
    );
    yield put(setSelectedRuleRecord({ id: ruleId }));
    const { ruleVersion } = response;
    yield put(
      triggerRuleDetailsRequest({
        ruleType,
        ruleId,
        ruleVersion,
      }),
    );
    if (ruleType === 'PAR')
      yield put(setIsShowingProductionArrangementModal(false));
    if (ruleType === 'CSM')
      yield put(setIsShowingCustomerServiceMessageModal(false));
    if (ruleType === 'RAD') yield put(setIsShowingReturnAddressModal(false));
    if (ruleType === 'CHT') yield put(setIsShowingCheckTextRuleModal(false));
    if (ruleType === 'DPX') yield put(setIsShowingDuplexRuleModal(false));
    if (ruleType === 'SIG') yield put(setIsShowingSignatureRuleModal(false));
    if (ruleType === 'SSN') yield put(setIsShowingMemberIdModal(false));
    if (ruleType === 'IMG') yield put(setIsShowingImageRuleModal(false));
    if (ruleType === 'TAX')
      yield put(setIsShowingTaxIdArrangementRuleModal(false));
    if (ruleType === 'ERI') yield put(setIsShowingErisaMessageModal(false));
    if (ruleType === 'HLD') yield put(setIsShowingAutoHoldModal(false));
    if (ruleType === 'PIO')
      yield put(setIsShowingProviderInsuredOptionMessageModal(false));
    if (ruleType === 'GEN') yield put(setIsShowingGeneralMessageModal(false));
    if (ruleType === 'SSN') yield put(setIsShowingMemberIdModal(false));
    if (ruleType === 'HDR') yield put(setShowingHighDollarReviewModal(false));
    if (ruleType === 'PDT') yield put(setIsShowingPDFTaggingModal(false));
    yield put(
      ruleDetailPostRequestSuccess({
        response,
      }),
    );
    yield put(setIsCloningRuleDetail(false));
    yield put(setIsPostingRuleDetail(false));
    yield put(setIsEditingRuleDetail(false));
    yield put(setIsReadOnlyRuleDetail(false));

  } catch (thrownError) {
    yield put(
      setLoadingState({
        id: action.payload.ruleId,
        isLoading: false,
      }),
    );
    yield put(
      addNotification({
        id: thrownError.errorId,
        type: 'negative',
        msg: thrownError.message,
      }),
    );
    yield put(setIsShowingProductionArrangementModal(false));
    yield put(setIsShowingCustomerServiceMessageModal(false));
    yield put(setIsShowingReturnAddressModal(false));
    yield put(setIsShowingDuplexRuleModal(false));
    yield put(setIsShowingSignatureRuleModal(false));
    yield put(setIsShowingCheckTextRuleModal(false));
    yield put(setIsShowingImageRuleModal(false));
    yield put(setIsShowingAutoHoldModal(false));
    yield put(setIsShowingMemberIdModal(false));
    yield put(setIsShowingTaxIdArrangementRuleModal(false));
    yield put(setIsShowingErisaMessageModal(false));
    yield put(setShowingHighDollarReviewModal(false));
    yield put(setIsShowingProviderInsuredOptionMessageModal(false));
    yield put(setIsShowingGeneralMessageModal(false));
    yield put(setIsShowingPDFTaggingModal(false));
    yield put(setIsCloningRuleDetail(false));
    yield put(setIsPostingRuleDetail(false));
    yield put(setIsEditingRuleDetail(false));
    yield put(setIsReadOnlyRuleDetail(false));
    yield put(ruleDetailPostRequestError(thrownError));
    yield put(
      ruleDetailPostRequestError(
        new Error('[ postRuleDetailGenerator ] failed operation'),
      ),
    );
  }
}

function* putRuleDetailGenerator(action) {
  yield put(
    setLoadingState({
      id: action.payload.ruleId,
      isLoading: true,
    }),
  );
  try {
    const userConfig = yield select(({ serverConfig }) => serverConfig);
    const response = yield call(putRuleDetails, userConfig, action.payload);
    const ruleType = action.payload.ruleType
      ? action.payload.ruleType
      : window.location.href.substring(
        window.location.href.lastIndexOf('=') + 1,
      );

    if (!window.location.href.includes('ruletype')) {
      const group = yield select(selectCurrentDetailsRecord);
      yield put(triggerRuleTypesRequest({ groupId: group.id }));
    }
    const { ruleId } = action.payload;
    const { rules } = yield call(
      getRulesForRuleTypesList,
      userConfig,
      {
        sortOrder: 'desc',
        sortColumn: 'dateModified',
        offset: 0,
        ruleTypeId: ruleType,
        queryValue: '',
      },
      ruleType,
    );
    // Grabbing the state from the store
    const getState = yield select();
    // Getting the Current Selected Record Active Groups since it does not come back from API
    const currentSelectedRuleRecordActiveGroups = get(getState, 'ruleTypesList.selectedRuleRecord.activeGroup', []);
    // Finding the current rule from the list of updated rules after saving the detail
    const findCurrentRule = rules.find(r => r.id === ruleId);
    const isAdvancedViewShown = !window.location.href.includes('ruletype');
    // Setting the rule with the updates details and also the active groups associated with it
    const rule = {
      ...findCurrentRule,
      activeGroup: currentSelectedRuleRecordActiveGroups
    };
    yield put(
      setIsViewingRuleDetails({
        isAdvancedViewShown,
        selectedRuleRecord: rule,
        selectedRuleTypeId: ruleType,
      }),
    );
    yield put(
      triggerAllRulesForRuleTypesRequest({
        sortOrder: 'desc',
        sortColumn: 'dateModified',
        offset: 0,
        ruleTypeId: ruleType,
        queryValue: '',
      }),
    );
    yield put(
      rulesCountFromDBTrigger({
        sortOrder: 'desc',
        sortColumn: 'dateModified',
        offset: 0,
        ruleTypeId: ruleType,
        queryValue: '',
      }),
    );
    const { ruleVersion } = response;
    yield put(setSelectedRuleRecord({ id: ruleId }));
    yield put(
      setLoadingState({
        id: ruleId,
        isLoading: false,
      }),
    );
    yield put(
      triggerRuleDetailsRequest({
        ruleType,
        ruleId,
        ruleVersion,
      }),
    );
    if (!window.location.href.includes('ruletype')) {
      const group = yield select(selectCurrentDetailsRecord);
      yield put(triggerRuleTypesRequest({ groupId: group.id }));
    }
    if (ruleType === 'PAR') {
      yield put(setIsShowingProductionArrangementModal(false));
    }
    if (ruleType === 'CSM') {
      yield put(setIsShowingCustomerServiceMessageModal(false));
    }
    if (ruleType === 'RAD') {
      yield put(setIsShowingReturnAddressModal(false));
    }
    if (ruleType === 'DPX') {
      yield put(setIsShowingDuplexRuleModal(false));
    }
    if (ruleType === 'TAX') {
      yield put(setIsShowingTaxIdArrangementRuleModal(false));
    }
    if (ruleType === 'IMG') {
      yield put(setIsShowingImageRuleModal(false));
    }
    if (ruleType === 'SIG') {
      yield put(setIsShowingSignatureRuleModal(false));
    }
    if (ruleType === 'CHT') {
      yield put(setIsShowingCheckTextRuleModal(false));
    }
    if (ruleType === 'HLD') {
      yield put(setIsShowingAutoHoldModal(false));
    }
    if (ruleType === 'SSN') {
      yield put(setIsShowingMemberIdModal(false));
    }
    if (ruleType === 'ERI') {
      yield put(setIsShowingErisaMessageModal(false));
    }
    if (ruleType === 'HDR') {
      yield put(setShowingHighDollarReviewModal(false));
    }
    if (ruleType === 'PIO') {
      yield put(setIsShowingProviderInsuredOptionMessageModal(false));
    }
    if (ruleType === 'GEN') {
      yield put(setIsShowingGeneralMessageModal(false));
    }
    if (ruleType === 'PDT') {
      yield put(setIsShowingPDFTaggingModal(false));
    }
    yield put(
      ruleDetailPutRequestSuccess({
        response,
      }),
    );
    yield put(
      addNotification({
        id: ruleId,
        type: 'positive',
        msg: response.success,
      }),
    );
    yield put(setIsCloningRuleDetail(false));
    yield put(setIsPostingRuleDetail(false));
    yield put(setIsEditingRuleDetail(false));
    yield put(setIsReadOnlyRuleDetail(false));

  } catch (thrownError) {
    yield put(
      setLoadingState({
        id: action.payload.ruleId,
        isLoading: false,
      }),
    );
    yield put(
      addNotification({
        id: thrownError.errorId,
        type: 'negative',
        msg: thrownError.message,
      }),
    );
    yield put(setIsShowingProductionArrangementModal(false));
    yield put(setIsShowingCustomerServiceMessageModal(false));
    yield put(setIsShowingReturnAddressModal(false));
    yield put(setIsShowingDuplexRuleModal(false));
    yield put(setIsShowingSignatureRuleModal(false));
    yield put(setIsShowingCheckTextRuleModal(false));
    yield put(setIsShowingImageRuleModal(false));
    yield put(setIsShowingAutoHoldModal(false));
    yield put(setIsShowingMemberIdModal(false));
    yield put(setIsShowingTaxIdArrangementRuleModal(false));
    yield put(setIsShowingErisaMessageModal(false));
    yield put(setShowingHighDollarReviewModal(false));
    yield put(setIsShowingProviderInsuredOptionMessageModal(false));
    yield put(setIsShowingGeneralMessageModal(false));
    yield put(setIsShowingPDFTaggingModal(false));
    yield put(setIsCloningRuleDetail(false));
    yield put(setIsPostingRuleDetail(false));
    yield put(setIsEditingRuleDetail(false));
    yield put(setIsReadOnlyRuleDetail(false));
    yield put(ruleDetailPutRequestError(thrownError));
    yield put(
      ruleDetailPutRequestError(
        new Error('[ putRuleDetailGenerator ] failed operation'),
      ),
    );
  }
}

function* getAddNewAddressElementsGenerator(action) {
  // enable loader when api request sent
  yield put(
    setLoadingState({
      id: action.payload.selectedRuleTypeId,
      isLoading: true
    }),
  );
  try {
    const userConfig = yield select(({ serverConfig }) => serverConfig);
    const response = yield call(
      getAddNewAddressElements,
      userConfig,
      action.payload,
    );
    const detailElements = response;

    yield put(
      getAddNewAddressElementsRecieved({
        detailElements,
      }),
    );
    // disable loader when api request is success
    yield put(
      setLoadingState({
        id: action.payload.selectedRuleTypeId,
        isLoading: false,
      }),
    );
  } catch (thrownError) {
    // disable loader when api request failed
    yield put(
      setLoadingState({
        id: action.payload.selectedRuleTypeId,
        isLoading: false,
      }),
    );
    // show notification when api fails to pull data
    yield put(
      addNotification({
        id: thrownError.errorId,
        type: 'negative',
        msg: thrownError.message,
      }),
    );
    yield put(getAddNewAddressElementsError(thrownError));
  }
}

function* postAddNewAddressElementsGenerator(action) {
  yield put(
    setLoadingState({
      id: action.payload.ruleId,
      isLoading: true,
    }),
  );
  try {
    const userConfig = yield select(({ serverConfig }) => serverConfig);
    const response = yield call(
      postAddNewAddressElements,
      userConfig,
      action.payload,
    );
    const ruleType = action.payload.ruleType
      ? action.payload.ruleType
      : window.location.href.substring(
        window.location.href.lastIndexOf('=') + 1,
      );

    // starts here : method to pull latest 'return address' and render to return address list on 'Add a Detail for Rule'(parent modal)
    const detailElements = yield call(getRuleDetailElements, userConfig, {
      selectedRuleTypeId: ruleType,
    });
    if (response) {
      yield put(
        ruleDetailElementsRecieved({
          detailElements,
        }),
      );
    }
    // ends here
    yield put(
      addNotification({
        id: `${action.payload.ruleType}-top`,
        type: 'positive',
        msg: response.success,
      }),
    );
    if (!window.location.href.includes('ruletype')) {
      const group = yield select(selectCurrentDetailsRecord);
      yield put(triggerRuleTypesRequest({ groupId: group.id }));
    }
    const { ruleId } = action.payload;
    yield put(
      setLoadingState({
        id: action.payload.ruleId,
        isLoading: false,
      }),
    );
    yield put(
      triggerAllRulesForRuleTypesRequest({
        sortOrder: 'desc',
        sortColumn: 'dateModified',
        offset: 0,
        ruleTypeId: ruleType,
        queryValue: '',
      }),
    );
    yield put(setSelectedRuleRecord({ id: ruleId }));
    const { ruleVersion } = action.payload;
    yield put(
      triggerRuleDetailsRequest({
        ruleType,
        ruleId,
        ruleVersion,
      }),
    );
    yield put(
      ruleDetailPostRequestSuccess({
        response,
      }),
    );
    yield put(setIsShowingAddNewAddressModal(false));
  } catch (thrownError) {
    const { selectedRuleTypeId, selectedRecordId } = action.payload;
    yield put(postImageUploadError(thrownError));
    yield put(
      setLoadingState({
        id: action.payload.ruleId,
        isLoading: false,
      }),
    );
    yield put(
      addNotification({
        id: `${action.payload.ruleType}-top`,
        type: 'negative',
        msg: thrownError.message,
      }),
    );
    yield put(setIsShowingImageUploadModal(selectedRuleTypeId, selectedRecordId, false));
    yield put(setIsShowingAddNewAddressModal(false));
    yield put(
      ruleDetailPostRequestError(
        new Error(thrownError),
      ),
    );
  }
}

function* postImageUploadTriggerGenerator(action) {
  yield put(
    setLoadingState({
      id: action.payload.selectedRecordId,
      isLoading: true,
    }),
  );
  try {
    const userConfig = yield select(({ serverConfig }) => serverConfig);
    const response = yield call(
      postImageUpload,
      userConfig,
      action.payload,
    );
    const { selectedRuleTypeId, selectedRecordId } = action.payload;
    if (response && !response.error) {
      
      yield put(postImageUploadRecieved({imageId:response, imageName:action.payload.imageName }));
      yield put(setIsShowingCommitImageModal({status:true, workflow:'upload'}));
    } else {
      yield put(
        addNotification({
          id: selectedRuleTypeId,
          type: 'negative',
          msg: response.error.message,
        }),
      );
      yield put(setIsShowingImageUploadModal(selectedRuleTypeId, selectedRecordId, false));
      yield put(postImageUploadError(new Error('[ postImageUploadTriggerGenerator ] failed operation')));
    }
    yield put(
      setLoadingState({
        id: selectedRecordId,
        isLoading: false,
      }),
    );
    yield put(setIsShowingImageUploadModal(selectedRuleTypeId, selectedRecordId, false));
  } catch (thrownError) {
    const { selectedRuleTypeId, selectedRecordId } = action.payload;
    yield put(postImageUploadError(thrownError));
    yield put(
      addNotification({
        id: selectedRuleTypeId,
        type: 'negative',
        msg: thrownError.message,
      }),
    );
    yield put(setIsShowingImageUploadModal(selectedRuleTypeId, selectedRecordId, false));
    yield put(
      setLoadingState({
        id: action.payload.selectedRuleTypeId,
        isLoading: false,
      }),
    );
  }
}

function* getRuleImagesGenerator(action) {
  yield put(
    setLoadingState({
      id: action.payload.offset !== null ? `${action.payload.selectedRuleTypeId}-offset` : action.payload.selectedRuleTypeId,
      isLoading: true,
    }),
  );
  try {
    const userConfig = yield select(({ serverConfig }) => serverConfig);
    const response = yield call(
      getRuleImages,
      userConfig,
      action.payload,
    );
    const detailElements = response;
    if (response) {
      yield put(
        getRuleImagesRecieved({
          detailElements,
        }),
      );
    } else {
      // show error banner notification when request is failed to pull data
      yield put(
        addNotification({
          id: action.payload.selectedRuleTypeId,
          type: 'negative',
          msg: response.error.message,
        }),
      );
      yield put(
        getRuleImagesError(
          new Error(
            '[ getRuleImagesGenerator ] returned images was not an object',
          ),
        ),
      );
      yield put(setIsShowingImageLibraryModal(false));
    }
    // disable loader when request is processed, either failed or successs
    yield put(
      setLoadingState({
        id: action.payload.offset !== null ? `${action.payload.selectedRuleTypeId}-offset` : action.payload.selectedRuleTypeId,
        isLoading: false,
      }),
    );
  } catch (thrownError) {
    yield put(setIsShowingImageLibraryModal(false));
    yield put(getRuleImagesError(thrownError));
    yield put(
      addNotification({
        id: thrownError.errorId,
        type: 'negative',
        msg: thrownError.message,
      }),
    );
    yield put(
      setLoadingState({
        id: action.payload.offset !== null ? `${action.payload.selectedRuleTypeId}-offset` : action.payload.selectedRuleTypeId,
        isLoading: false,
      }),
    );
  }
}

function* getRuleImagesCountGenerator(action) {
  try {
    const userConfig = yield select(({ serverConfig }) => serverConfig);
    const response = yield call(
      getRuleImagesCount,
      userConfig,
      action.payload,
    );
    const detailElements = response;
    if (response) {
      yield put(
        getRuleImagesCountRecieved({
          detailElements,
        }),
      );
    } else {
      yield put(
        getRuleImagesCountError(
          new Error(
            '[ getRuleImagesCountGenerator ] returned images count was not an object',
          ),
        ),
      );
    }
  } catch (thrownError) {
    yield put(getRuleImagesCountError(thrownError));
  }
}

function* resetRuleImagesDataGenerator(action) {
  try {
    if(action.payload.offset !== null){
      yield put(
        setLoadingState({
          id: `${action.payload.selectedRuleTypeId}-offset`,
          isLoading: false,
        }),
      );
    }
    const detailElements = [];
    yield put(
      setLoadingState({
        id: action.payload.selectedRuleTypeId,
        isLoading: false,
      }),
    );
    yield put(
      getRuleImagesCountRecieved({
        detailElements
      }),
    );
  } catch (thrownError) {
    yield put(getRuleImagesCountError(thrownError));
  }
}

// post image to api to get it converted to bmp image
function* convertImageUploadTriggerGenerator(action) {
  yield put(
    setLoadingState({
      id: action.payload.selectedRuleTypeId,
      isLoading: true,
    }),
  );
  try {
    const userConfig = yield select(({ serverConfig }) => serverConfig);
    const response = yield call(
      convertImageUpload,
      userConfig,
      action.payload,
    );
    if (response && !response.error) {
      yield put(convertImageUploadRecieved({response}));
    } else {
      
      yield put(convertImageUploadError(new Error(response.error.message)));
    }
    yield put(
      setLoadingState({
        id: action.payload.selectedRuleTypeId,
        isLoading: false,
      }),
    );
  } catch (thrownError) {
    yield put(convertImageUploadError(thrownError));
    yield put(
      setLoadingState({
        id: action.payload.selectedRuleTypeId,
        isLoading: false,
      }),
    );
  }
}

// put image to API once the commit or confirm button from commit image modal is clicked
function* postCommitImageTriggerGenerator(action) {
  const {imageName, ruleDetailId, imageId, selectedRuleTypeId} = action.payload;
  yield put(
    setLoadingState({
      id: action.payload.selectedRuleTypeId,
      isLoading: true,
    }),
  );
  try {
    const userConfig = yield select(({ serverConfig }) => serverConfig);
    const response = yield call(
      putCommitImage,
      userConfig,
      action.payload,
    );
    if (response && !response.error) {
      const position = 9;
      const message = [ response.success.slice(0, position), ` ${imageName}`, response.success.slice(position)].join('');
      yield put(postCommitImageRecieved({imageId, imageName, ruleDetailId, response}));
      yield put(
        addNotification({
          id: `${action.payload.selectedRuleTypeId}-FILEUPLOAD`,
          type: 'positive',
          msg: message,
        }),
      );
    } else {
      yield put(postCommitImageError(new Error(response.error.message)));
      yield put(
        addNotification({
          // id: action.payload.selectedRuleTypeId,
          id: `${action.payload.selectedRuleTypeId}-FILEUPLOAD`,
          type: 'negative',
          msg: response.error.message,
        }),
      );
    }
    // condition to call ruleDetailElements for return address only 
    if(selectedRuleTypeId === 'RAD'){
      const responseRuleDetailElements = yield call(
        getRuleDetailElements,
        userConfig,
        action.payload,
      );
      const detailElements = responseRuleDetailElements;
      yield put(
        ruleDetailElementsRecieved({
          detailElements,
        }),
      );
    }

    // start: pull latest ruledetails data for both: commit failed and success operations
    const { clientLayoutId, userSecurityTokenKey, username, roleId } = userConfig;
    const { ruleId, ruleVersion } = action.payload;
    const responseRuleDetails = yield call(getRuleDetails, {
      clientLayoutId,
      userSecurityTokenKey,
      username,
      ruleType:selectedRuleTypeId,
      ruleId,
      ruleVersion,
      roleId
    });
    const ruleDetailsResults = responseRuleDetails.ruleDetails;
    const {testImageCount} = responseRuleDetails;
    yield put(
      ruleDetailsListReceived({
        ruleDetails: ruleDetailsResults,
        testImageCount
      }),
    );
    const selectedRuleDetailFiltered = ruleDetailsResults.filter( (element) => element.id === ruleDetailId);
    
    if(selectedRuleDetailFiltered && selectedRuleDetailFiltered.length > 0){
      yield put(setSelectedRuleDetail(selectedRuleDetailFiltered[0]));
    }
    // end: pull latest ruledetails data for both: commit failed and success operations
      
    yield put(setIsShowingCommitImageModal({status:false, workflow:'upload'}));
    yield put(
      setLoadingState({
        id: action.payload.selectedRuleTypeId,
        isLoading: false,
      }),
    );
  } catch (thrownError) {
    yield put(
      addNotification({
        id: thrownError.errorId,
        type: 'negative',
        msg: thrownError.message,
      }),
    );
    yield put(postCommitImageError(thrownError));
    yield put(
      setLoadingState({
        id: action.payload.selectedRuleTypeId,
        isLoading: false,
      }),
    );
  }
}

function* ruleDetailElementsSaga() {
  yield takeLatest(RULE_DETAIL_ELEMENTS_TRIGGER, ruleDetailElementsGenerator);
  yield takeLatest(RULE_DETAIL_POST_REQUEST_TRIGGER, postRuleDetailGenerator);
  yield takeLatest(RULE_DETAIL_PUT_REQUEST_TRIGGER, putRuleDetailGenerator);

  yield takeLatest(
    ADD_NEW_ADDRESS_GET_ELEMENTS_TRIGGER,
    getAddNewAddressElementsGenerator,
  );
  yield takeLatest(
    ADD_NEW_ADDRESS_ELEMENTS_POST_REQUEST_TRIGGER,
    postAddNewAddressElementsGenerator,
  );
  yield takeLatest(
    POST_IMAGE_UPLOAD_TRIGGER,
    postImageUploadTriggerGenerator,
  );
  yield takeLatest(
    GET_RULE_IMAGE_TRIGGER,
    getRuleImagesGenerator,
  );
  yield takeLatest(
    GET_RULE_IMAGE_COUNT_TRIGGER,
    getRuleImagesCountGenerator,
  );
  yield takeLatest(
    RESET_RULE_IMAGE_DATA_TRIGGER,
    resetRuleImagesDataGenerator,
  );
  yield takeLatest(
    CONVERT_IMAGE_UPLOAD_TRIGGER,
    convertImageUploadTriggerGenerator,
  );
  yield takeLatest(
    POST_COMMIT_IMAGE_TRIGGER,
    postCommitImageTriggerGenerator,
  );
}

export default ruleDetailElementsSaga;