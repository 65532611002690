import styled from 'styled-components';
import { Borders } from '../../../../shared/styles/borders';

export const SingleSelectGroupWrapper = styled.div`
  ${Borders.lightGrey};
  ${Borders.onlyTopAndBottom};
`;

export const SingleSelectGroupInnerWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  button.add-rule {
    margin-left: auto;
  }
`;

export const SingleSelectGroupNoBorder = styled.div`
  width: 100%;
  div {
    border: none;

    button {
      width: auto;
    }
  }
`;


