import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import {
  DetailPaneHeader,
  useLayout,
  Input,
  MultiSelectDropdown,
  Checkbox,
  Button,
  Modal,
  ButtonGroup,
  ModalHeader,
  Dropzone,
  ModalBody,
  LoadingIndicator
} from 'ui-core/dist'
import axios from '../../axios'
import useNotificationBannerHook from '../../useNotificationBannerHook';
import {useUploadFile, useGetMaxFileSize} from '../../api/useTestFile'
import  {documentTypesOptions} from './modules/constants'
import './modules/styles.scss'

const TestFileManagerDetailPane = () => {
  const {
    expandCollapseDetailPaneButtonConfig,
    closeDetailPaneButtonConfig,
    closeDetailPane,
    detailProps
  } = useLayout()
  const { notify } = useNotificationBannerHook()
  const userType = useSelector(store => store.session.type)
  const { refetchAllTestFile } = useSelector(store => store.testFileManager)
  // const { refetchAllTestFile } = useSelector(({ testFileManager }) => testFileManager);
  // const [ docTypeOptions, setDocTypeOptions ] = useState()
  const [ groupOptions, setGroupOptions ] = useState()
  const [ uploadModal, setUploadModal ] = useState(false)
  const [ confirmationModal, setConfirmationModal ] = useState(false)
  const [ docTypesError, setDocTypesError ] = useState()
  const [ groupsError, setGroupsError ] = useState()
  const [ dropzoneError, setDropzoneError ] = useState(false)
  const [ uploadedFile, setUploadedFile ] = useState()
  const [ documentKeys, setDocumentKeys ] = useState()
  const [ releaseOnly, setReleaseOnly ] = useState(false)
  const [ pdfOutput, setPDFOutput ] = useState(false)
  const [ emailNotification, setEmailNotification ] = useState()
  const [ documentTypes, setDocumentTypes ] = useState()
  const [ documentNumber, setDocumentNumber ] = useState()
  const [ groups, setGroups ] = useState()
  const [loadingBtn, setLoadingBtn] = useState(false)
  const {maxSize}=useGetMaxFileSize(userType)
  const [ MaxDocsPerPayer, setMaxDocumentsPerPayer ] = useState('')
  const layout = useSelector(({ context }) => `${context?.context?.layout}`)
  const [ visibleMDPP ] = useState(layout == '007')


 // const {  testFileId, isLoading, uploadTestFile } = useUploadFile()

  useEffect(() => {
    // const loadDocumentOptions = async () => {
    //   try {
    //     const res = await axios.post('/testFilesDocumentType/getDocumentTypeTestFiles')
    //     setDocTypeOptions(res.data)
    //   } catch(err) {
    //     setDocTypesError('Failed to Load the options. Please refresh.')
    //   }
    // }
    const loadGroupOptions = async () => {
      try {
        const res = await axios.post('/empGroupManager/getEmployerGroupsLite')
        if(res.data && res.data.constructor.name==="Array"){
          setGroupOptions([{label:'-- Output All --', value:''}, ...res.data])  
        }
      } catch(err) {
        setGroupsError('Failed to Load the options. Please refresh.')
      }
    }
    // loadDocumentOptions()
    loadGroupOptions()
  }, [])

  const removeFile = () => setUploadedFile()

  const onDeleteFile = () => setUploadedFile()

  const onDropFiles = (files) => {
    setDropzoneError(false)
    if(files.length === 0) setDropzoneError(true)
    else {
      setUploadedFile(files[0])
      setUploadModal(false)
    }
  }

  const handleFileKeysChange = (e, { value }) => setDocumentKeys(value)

  const toggleReleaseOnly = () => setReleaseOnly(!releaseOnly)

  const togglePDFOutput = () => setPDFOutput(!pdfOutput)

  const handleEmailInput = (e, { value }) => setEmailNotification(value)

  const handleDocumentTypesSelect = (values) => setDocumentTypes(values)

  const handleNumberOfDocuments = (e, { value }) => setDocumentNumber(value)

  const handleGroupsSelect = (values) =>setGroups(values)

  const handleMaxDocumentsPerPayer = (e, { value }) => setMaxDocumentsPerPayer(value)

  const clearFields = () => {
    setUploadedFile()
    setDocumentKeys()
    setReleaseOnly(false)
    setPDFOutput(false)
    setEmailNotification()
    setDocumentTypes()
    setDocumentNumber()
    setGroups()
    setMaxDocumentsPerPayer()
  }

  const next = () => setConfirmationModal(true)

  const confirmationModalClose = () => setConfirmationModal(false)

  const handleSubmitCancelation = () => setConfirmationModal(false)

  const openUploadModal = () => {
    setUploadModal(true)
  }

  const uploadModalClose = () => {
    setUploadedFile()
    setUploadModal(false)
  }

  const handleBackButton = () => {
    setUploadedFile()
    setUploadModal(false)
  }

  const handleConfirmation = async () => {
    let payload
    setLoadingBtn(true)
    if (uploadedFile && uploadedFile.file) {
      const {file}=uploadedFile;
      let _f=file;
      if(/\s+/g.test(file.name)){
        const blob = new Blob([new Uint8Array(await file.arrayBuffer())], {type: file.type });
        _f=new File([blob], file.name.replace(/\s+/g, ''), {
          lastModified: file.lastModified,
          type: file.type
        });
      }
      payload = new FormData();
      payload.append('test', _f)
      payload.append('internalReview', releaseOnly)
      payload.append('pdfOutput', !pdfOutput)
      payload.append('email',  MaxDocsPerPayer + emailNotification)
      payload.append('documentTypes', documentTypes?.map(i => i.value))
      payload.append('maxDocuments', documentNumber)
      // payload.append('payerLimit', )
      payload.append('groups', groups?.map(i => i.value))
      
    } else {
      payload = {
        fileKey: documentKeys,
        internalReview: releaseOnly,
        pdfOutput: !pdfOutput,
        email: MaxDocsPerPayer + emailNotification,
        documentTypes: documentTypes?.map(i => i.value),
        maxDocuments: documentNumber,
        // payerLimit: ,
        groups: groups?.map(i => i.value),
      }
    }
    try {
      // uploadTestFile(payload)
      const res = await axios.post('/testFiles/upload', payload, {
        headers: {
          'Content-Type': uploadedFile ? 'multipart/form-data' : 'application/json'
        }
      })
      setLoadingBtn(false)
      setConfirmationModal(false)
      clearFields()
      closeDetailPane()
      if(refetchAllTestFile) {
        refetchAllTestFile()
      }
      // detailProps.refreshMe()
      notify(`You have successfully scheduled test file ID # "${res?.data?.testFileId}"`, 'positive')
    } catch(err) {
      notify(err.displayMessage, 'negative')
      setLoadingBtn(false)
    }
  }

  const validateEmail = () => {
    if(documentNumber) {
      if(!/^[0-9]*$/.test(documentNumber)) {
      notify("Limit to # of Documents only accepts number", 'negative')
      return false;
      }
    }
    if (!emailNotification) {
      return true;
    }
    let emailArr = []
    let errCount = 0
    const emailData = emailNotification.replace(/\s+/g, '')
    if(emailData.indexOf(',') !== -1) {
      emailArr = emailData.split(',')
    } else {
        emailArr.push(emailData)
    }
    for(let i=0;i<emailArr.length;i++){
      if(!(/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(emailArr[i]))){
        errCount++
      }
    }
    if(errCount > 0){
      notify("The e-mail address you have entered is not a valid Internet e-mail address", 'negative')
    } else {
      setEmailNotification(emailData)

      var vPayerLimit
      if(MaxDocsPerPayer.length === 3)
        vPayerLimit = MaxDocsPerPayer.toUpperCase()
      else if(MaxDocsPerPayer.length === 2)
        vPayerLimit = '0' + MaxDocsPerPayer
      else if(MaxDocsPerPayer.length === 1)
        vPayerLimit = '00' + MaxDocsPerPayer
      else if(MaxDocsPerPayer.length === 0 && layout === '007')
        vPayerLimit = '010'
      else
        vPayerLimit= MaxDocsPerPayer
      setMaxDocumentsPerPayer(vPayerLimit)

      next()
    }
    //return (/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(emailNotification) ? false : true);
  }
  const ValidateDocKey = useCallback(() => {
    if (documentKeys?.length !== 17 && documentKeys?.length !== 23) {
      return true
    } else 
     return false
  },[documentKeys])
  const ValidatePayerLimit = useCallback(() => {
    if(MaxDocsPerPayer?.length !==0){
      if (MaxDocsPerPayer?.toUpperCase() !== 'ALL'){
        if (isNaN(MaxDocsPerPayer))
          return true
        else
          return false
      }
      else
        return false
    }
    else  
     return false
  },[MaxDocsPerPayer])
  return (
    <>
      <DetailPaneHeader
        title="Submit a Test File"
        layoutControls={[expandCollapseDetailPaneButtonConfig, closeDetailPaneButtonConfig]}
      />
      <div className="test-file-submission">
        <p>Click the Upload File button to select and upload your test file. You will be returned to this page after your file has been uploaded.</p>
        <p>Files requiring Emdeon preprocessing that are larger than 30mb cannot be uploaded directly to the website. If you have any questions, please contact your client analyst.</p>
        <div className="test-file-submission__row">
          <span className="test-file-submission__label">Uploaded File Name:	</span>
          {uploadedFile ? (
            <Input
              domID="UploadFileId"
              autoComplete="off"
              dataTestId="test-UploadFileId"
              size="small"
              disabled
              initialValue={uploadedFile.file.name}
            />
          ):(
            <Input
              domID="UploadFileId"
              autoComplete="off"
              dataTestId="test-UploadFileId"
              size="small"
              disabled
            />
          )}
          {!uploadedFile ? (
          <Button
            domID="UploadFileButton"
            className="test-file-submission__upload-file"
            buttonType="standard"
            size="small"
            name="Upload File"
            onClick={openUploadModal}
            disabled={!!documentKeys}
            style={{ marginLeft: '12px' }}
          />
          ) : (
            <Button
            domID="UploadFileButton"
            className="test-file-submission__upload-file"
            buttonType="standard"
            size="small"
            name="Remove File"
            onClick={removeFile}
            disabled={!!documentKeys}
            style={{ marginLeft: '12px' }}
          />
          )}
        </div>
        <div className="test-file-submission__row">
          <span className="test-file-submission__label">-or- Enter a File Key or Document Key(s):<span className="--red">*</span></span>
          <Input
            domID="FileKeysInput"
            autoComplete="off"
            dataTestId="test-FileKeysInput"
            onChange={handleFileKeysChange}
            size="small"
            hasError={documentKeys? ValidateDocKey(): false}
            errorMessage='Document keys must have a length of 23 and File keys must have a length of 17'
            disabled={uploadedFile}
            initialValue={documentKeys}
          />
        </div>
        <p>If multiple documents are requested,<br/> the keys must be separated by commas.</p>
        {userType === "I" && (
            <div className="test-file-submission__row">
              <span className="test-file-submission__label">Internal View Only</span>
              <Checkbox
                domID="ReleaseCheckbox"
                checked={releaseOnly}
                dataTestId="test-ReleaseCheckbox"
                onChange={toggleReleaseOnly}
              />
            </div>
          )}
          <div className="test-file-submission__row">
            <span className="test-file-submission__label">Release Only (No PDF Generation)</span>
            <Checkbox
              domID="ReleaseCheckbox"
              checked={pdfOutput}
              dataTestId="test-ReleaseCheckbox"
              onChange={togglePDFOutput}
            />
          </div>
        <div className="test-file-submission__row">
          <span className="test-file-submission__label">E-Mail Notification:<span className="--red">*</span></span>
          <Input
            domID="EmailInput"
            autoComplete="off"
            dataTestId="test-EmailInput"
            onChange={handleEmailInput}
            maxLength={50}
            // errorMessage="Please enter a valid Email"
            // hasError={emailNotification ? validateEmail() : false}
            size="small"
            initialValue={emailNotification}
          />
        </div>
        <div className="test-file-submission__row">
          <span className="test-file-submission__label">Limit to Document Types:</span>
          <MultiSelectDropdown
            className="test-file-submission__dropdwon"
            dataTestId="test-DocumentTypesDropdown"
            domID="DocumentTypesDropdown"
            options={documentTypesOptions}
            hasError={!!docTypesError}
            errorMessage={docTypesError}
            isSearchable
            isClearable
            name="DocumentTypesDropdown"
            onChange={handleDocumentTypesSelect}
            size="small"
            initialValue={documentTypes}
          />
        </div>
        <div className="test-file-submission__row">
          <span className="test-file-submission__label">Limit to # of Documents:	</span>
          <Input
            domID="NumberOfDocuments"
            autoComplete="off"
            dataTestId="test-NumberOfDocuments"
            onChange={handleNumberOfDocuments}
            maxLength={20}
            size="small"
            initialValue={documentNumber}
          />
        </div>
        <div className="test-file-submission__row">
          <span className="test-file-submission__label">Limit to Groups:	</span>
          <MultiSelectDropdown
            className="test-file-submission__dropdwon"
            dataTestId="test-GroupsDropdown"
            domID="GroupsDropdown"
            isSearchable
            isClearable
            options={groupOptions}
            hasError={!!groupsError}
            errorMessage={groupsError}
            name="GroupsDropdown"
            onChange={handleGroupsSelect}
            size="small"
            initialValue={groups}
          />
        </div>
        {visibleMDPP && <div className="test-file-submission__row">
          <span className="test-file-submission__label">Max Documents Per Payer (1 - 999 or ALL):	</span>
          <Input
            domID="MaxDocumentsPerPayer"
            autoComplete="off"
            dataTestId="test-MaxDocumentsPerPayer"
            onChange={handleMaxDocumentsPerPayer}
            maxLength={3}
            size="small"
            hasError={MaxDocsPerPayer? ValidatePayerLimit(): false}
            errorMessage='Please enter value between 1 - 999 or ALL'           
            initialValue={MaxDocsPerPayer}
          />
        </div>}
        <p className="--red">* Indicates required information</p>
        <ButtonGroup className="button-group">
          <Button
            domID="ClearFieldsButton"
            className="test-file-submission__button"
            buttonType="standard"
            size="small"
            name="Clear Fields"
            onClick={clearFields}
          />
          <Button
            domID="SubmitButton"
            className="test-file-submission__button"
            buttonType="standard"
            size="small"
            name="Next ->"
            onClick={validateEmail}
            // disabled={((!documentKeys || !uploadedFile) && (!emailNotification))}
            disabled={((ValidateDocKey() && !uploadedFile) || (!emailNotification) || ValidatePayerLimit())}
          />
        </ButtonGroup>
      </div>
      <Modal isOpen={confirmationModal}>
        <ModalHeader
          title="Confirm Test File Submission"
          onClose={confirmationModalClose}
        />
        <ModalBody>
        <div className="test-file-modal">
          <div className="test-file-modal__row">
            <span className="test-file-modal__label">Uploaded File Name:</span>
            <span>{uploadedFile?.file?.name || documentKeys}</span>
          </div>
          <div className="test-file-modal__row">
            <span className="test-file-modal__label">E-Mail Notification:</span>
            <span>{emailNotification}</span>
          </div>
          {userType === "I" && <div className="test-file-modal__row">
            <span className="test-file-modal__label">Internal View Only:</span>
            <span>{`${releaseOnly ? 'Yes' : 'No'}`}</span>
          </div>}
          <div className="test-file-modal__row">
            <span className="test-file-modal__label">PDF Output:</span>
            <span>{`${pdfOutput ? "No" : "Yes"}`}</span>
          </div>
          <div className="test-file-modal__row">
            <span className="test-file-modal__label">Limit to Document Types:</span>
            <span>{documentTypes?.reduce((a, c) => `${a}${`, ${c.label}`}`, documentTypes[0]?.label)||""}</span>
          </div>
          <div className="test-file-modal__row">
            <span className="test-file-modal__label">Limit to # of Documents:</span>
            <span>{documentNumber}</span>
          </div>
          <div className="test-file-modal__row">
            <span className="test-file-modal__label">Limit to Groups:</span>
            <span>{groups?.reduce((a, c) => `${a}${`, ${c.label}`}`, groups[0]?.label)||""}</span>
          </div>
          {visibleMDPP && <div className="test-file-modal__row">
            <span className="test-file-modal__label">Max Documents Per Payer:	</span>
            <span>{MaxDocsPerPayer}</span>
          </div>}
          <ButtonGroup className="button-group">
            <Button
              domID="CancelButton"
              className="test-file-modal__Button"
              buttonType="standard"
              size="small"
              name="Cancel"
              onClick={handleSubmitCancelation}
            />
            <Button
              domID="ConfirmButton"
              className="test-file-modal__Button"
              buttonType="standard"
              size="small"
              name="Confirm"
              onClick={handleConfirmation}
              disabled={loadingBtn}
            />
            {loadingBtn ? <LoadingIndicator/>:''}
          </ButtonGroup>
        </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={uploadModal}>
        <ModalHeader
          title="Upload a Test File"
          onClose={uploadModalClose}
        />
        <ModalBody>
        <div className="upload-file-modal">
          <Dropzone
            domID="dropzone"
            acceptedFileTypes=".zip, .gzip, .pgp, .gz"
            dataTestId="test-dropzoneDefault"
            onDeleteFile={onDeleteFile}
            onDropFiles={onDropFiles}
            maxFileSize={maxSize}
            hasError={dropzoneError}
            errorMessage={`The file size exceeds the limit for an ${userType === 'E' ? 'External' : 'Internal'} user which is ${maxSize/1024/1024} MB.`}
            label="Click Browse to open a Choose File dialog box where you can locate and select your test file. The file must be a valid client data file in either ZIP, GZIP or PGP format. Only the first uploaded file will be accepted."
          />
          <ButtonGroup className="button-group">
            <Button
              domID="BackButton"
              className="test-file-modal__Button"
              buttonType="standard"
              size="small"
              name="Cancel"
              onClick={handleBackButton}
            />
          </ButtonGroup>
        </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default TestFileManagerDetailPane
