import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, useLayout, Input, Button } from 'ui-core/dist';
import Datetime from 'react-datetime';
import moment from 'moment';
import { LoadingPage } from '../../components';
import ErrorPage from '../ErrorPage';
import {FILE_RECEIPT_MANAGER_MAIN_GRID_COLUMN } from './Modules/constants';
import withAxiosCancellation from '../../components/withAxiosCancellation'
import GridPaginationWrapper from '../../components/GridPaginationWrapper';
import ListPaneHeaderWrapper from '../../components/ErrorDisplaySection/ListPaneHeaderWrapper';
import { useGetFileReceipts } from '../../api/useFileReceiptManager';
import useSortableGrid from '../../hooks/useSortableGrid';
import { resetFileKey, setFileKey } from './Modules/Action';
import useNotificationBannerHook from '../../useNotificationBannerHook';
import './Modules/styles.scss';

const FileReceiptManagerMain = ({getCancelSourceWithName}) => {
  const {
    expandCollapseListPaneButtonConfig,
    openDetailPane,
    closeDetailPane
  } = useLayout();
  const dispatch = useDispatch()
  const { notify } = useNotificationBannerHook();

  const DEFAULT_ROWS_PER_PAGE = 50;
  const [startDate, setStartDate] = useState(moment().add(-30, 'days').format("MM/DD/YYYY"));
  const [endDate, setEndDate] = useState(moment().format("MM/DD/YYYY"));
  const [pageStarting, setPageStarting] = useState(0);
  const [releaseDateStartError, setReleaseDateStartError] = useState(false)
  const [releaseDateEndError, setReleaseDateEndError] = useState(false)

  const [recordsPerPage, setRecordsPerPage] = useState(50);
  const [fileKeys, setFilekeys] = useState('');
  const [datesError, setDatesError] = useState(false)
  const [errMsg, setErrMsg] = useState(false)
  const [selectedRecord,setSelectedRecord]=useState('')
  const { records, totalRecords, receiveDateEnd, receiveDateStart, isLoading, isError, error, getFileReceipts } = useGetFileReceipts({getCancelSource:getCancelSourceWithName('getFileReceipts')});

  useEffect(() => {
    setStartDate(receiveDateStart ? moment(receiveDateStart) : null);
    setEndDate(receiveDateEnd ? moment(receiveDateEnd) : null);
    setReleaseDateStartError(false);
    setReleaseDateEndError(false);
  }, [records, receiveDateEnd, receiveDateStart]);

  const fetchData = useCallback((args) => {
    let sortcolumn; let sd; let pi;
    if (args) {
      ({ sortcolumn, sd, pi } = args);
    }
    setSelectedRecord({})
    const parameters = createSearchParams();
    closeDetailPane();
    dispatch(resetFileKey());
    getFileReceipts(JSON.parse(JSON.stringify({
      "maxRecords": recordsPerPage,
      "rowPosition": pi === undefined || pi === null ? pageStarting : pi,
      "sortField": sortcolumn === undefined || sortcolumn === null ? sortConfig.sortDataName : sortcolumn,
      "viewInactive": false,
      "sortDescending": sd === undefined || sd === null ? sortConfig.sortDescending : sd,
      parameters
    })))
  }, [sortConfig, recordsPerPage, pageStarting, startDate, endDate, fileKeys])

  const [sortConfig, getArgumentsAndSortGrid] = useSortableGrid(fetchData, "receivedDate", "true");
  const payload =
  {
    "maxRecords": 50,
    "rowPosition": 0,
    "sortField": sortConfig.sortDataName,
    "viewInactive": false,
    "sortDescending": sortConfig.sortDescending,
    "parameters": ""
  };

  const validateFileKeys = useCallback(() => {
    const fKeys = fileKeys;
    let keys = fKeys;
    const splittedKeys = fKeys.split(',');
    let newKeys = [];
    splittedKeys.forEach(v => {
      const value = v.trim().replace(/[^\w\s]/gi, '');
      if (value.length === 17) {
        newKeys.push(value)
      }
    });
    if (newKeys.length > 0) {
      if (newKeys.length > 100) {
        newKeys = newKeys.slice(0, 100);
      }
      keys = `${newKeys.join(',')},`;
    } else {
      keys = '';
    }
    setFilekeys(newKeys.join(','));
    return keys;
  }, [startDate, endDate, fileKeys]);

  const createSearchParams = useCallback(() =>
    `${startDate ? moment(startDate).format('L') : ''}|${endDate ? moment(endDate).format('L') : ''}|${validateFileKeys()}|`,
    [startDate, endDate, fileKeys])

  useEffect(() => {
    closeDetailPane();
    dispatch(resetFileKey());
    setSelectedRecord({})
    getFileReceipts(payload);
  }, []);

  const validateDate = (dtValue) => {
    if (dtValue === "") {
      return true;
    }
    const dtRegex = new RegExp(/\b\d{1,2}[/-]\d{1,2}[/-]\d{4}\b/);
    if (dtRegex.test(dtValue)) {
      const splitted = dtValue.split("/");
      if (splitted[0] < 0 || splitted[0] > 12) {
        return false;
      }
      if (splitted[1] < 0 || splitted[1] > 31) {
        return false;
      }
    }
    return true;

  }
  const handleEnterKeyPressEvent = (event, id) => {
    if (event.key === 'Enter') {
      if (id === "FileKeySearch" && (fileKeys.length === 17 || fileKeys.length === 0)) {
        fetchData();
      }
    }
  }

  const startDateValidation = (currentDate) => {
    let err = false;
    if (moment(currentDate).isAfter(moment(endDate).startOf('day'), 'day')) {
      err = true;
    } else if (moment(currentDate).isAfter(moment().startOf('day'), 'day')) {
      err = true;
    }

    return !err;
  };

  const endDateValidation = (currentDate) => {
    let err = false;
    if (moment(currentDate).isAfter(moment().startOf('day'), 'day')) {
      err = true;
    } else if (moment(currentDate).isBefore(moment(startDate).startOf('day'), 'day')) {
      err = true;
    }

    return !err;
  };

  const onStartDateChange = (val) => {
    let err = false;
    let errMessage = '';

    if (val === '') {
      setDatesError(false);
      setStartDate('');
      return;
    }

    if (!moment(val, 'MM/DD/YYYY', true).isValid()) {
      errMessage = 'Start date is invalid(mm/dd/yyyy).';
      err = true;
    } else if (moment(val).isAfter(moment(endDate))) {
      errMessage = 'Start date should be earlier than the end date.';
      err = true;
    } else if (moment(val).isAfter(moment().startOf('day'), 'day')) {
      errMessage = 'Start date is invalid(mm/dd/yyyy, max date is today).';
      err = true;
    }

    if (err) {
      setDatesError(true);
      setErrMsg(errMessage);
      setStartDate(val);
    } else {
      setDatesError(false);
      setStartDate(val.format("MM/DD/YYYY"));
    }
  }

  const onEndDateChange = (val) => {
    let err = false;
    let errMessage = '';

    if (val === '') {
      setDatesError(false);
      setEndDate('');
      return;
    }

    if (!moment(val, 'MM/DD/YYYY', true).isValid()) {
      err = true;
      errMessage = 'End date is invalid(mm/dd/yyyy).'
    } else if (moment(val).isBefore(moment(startDate))) {
      errMessage = 'End date should be greater than the start date.';
      err = true;
    } else if (moment(val).isAfter(moment().startOf('day'), 'day')) {
      errMessage = 'End date is invalid(mm/dd/yyyy, max date is today).';
      err = true;
    }

    if (err) {
      setDatesError(true);
      setErrMsg(errMessage);
      setEndDate(val);
    } else {
      setDatesError(false);
      setEndDate(val.format("MM/DD/YYYY"));
    }
  }

  const renderSearchContent = useMemo(() => (
    <>
      <div className="SearchWrapper">
        <div className="Margin5px">Date Range:</div>
        <div className="Margin5px msgDatePicker" style={{ width: "250px" }} >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Datetime
              onChange={onStartDateChange}
              // inputProps={{ readOnly: true }}
              closeOnSelect={true}
              isValidDate={startDateValidation}
              dateFormat="MM/DD/YYYY"
              value={startDate}
              initialValue={startDate}
              initialViewDate={startDate}
              timeFormat={false}
            /> <span style={{ padding: '0 3px' }}>-</span>
            <Datetime
              onChange={onEndDateChange}
              // inputProps={{ readOnly: true }}
              closeOnSelect={true}
              isValidDate={endDateValidation}
              dateFormat="MM/DD/YYYY"
              value={endDate}
              initialValue={endDate}
              initialViewDate={endDate}
              timeFormat={false}
            />
          </div>
        </div>
        <div className="Margin5px">File Key(s):</div>
        <div className="ItemsSelect">
          <Input
            domID="FileKeySearch"
            label=""
            size="small"
            initialValue={fileKeys}
            maxLength={17}
            onChange={(e) => {
              setFilekeys(e.target.value);
            }}
            onKeyUp={(e) => {
              handleEnterKeyPressEvent(e, e?.currentTarget?.id)
            }}
          />
        </div>
        <Button
          domID="searchButton"
          buttonType="standard"
          size="small"
          name="SEARCH"
          disabled={datesError}
          onClick={fetchData}
        />
      </div>

      <div className="Margin5px">
        {releaseDateStartError ? (
          <span id="releaseDateStartErrorid" style={{ fontSize: '10pt', color: 'red' }}>Initial date is invalid(mm/dd/yyyy).&nbsp;</span>
        ) : null}
        {releaseDateEndError ? (
          <span id="releaseDateEndErrorid" style={{ fontSize: '10pt', color: 'red' }}>Final date is invalid(mm/dd/yyyy).&nbsp;</span>
        ) : null}
      </div>
      <GridPaginationWrapper
        rowsPerPage={recordsPerPage}
        onChangeRowsPerPage={setRecordsPerPage}
        pageIndex={pageStarting}
        onChangePageIndex={setPageStarting}
        onShowButtonCLick={fetchData}
        totalRecords={totalRecords}
        defaultRowsPerPage={DEFAULT_ROWS_PER_PAGE}
        disableShowButton={datesError}
      />
    </>
  ), [recordsPerPage, pageStarting, startDate, endDate, fileKeys, totalRecords, releaseDateStartError, releaseDateEndError])

  const renderGridContent = () => {
    const results = JSON.parse(JSON.stringify(records));

    return (
      <Grid
        {...FILE_RECEIPT_MANAGER_MAIN_GRID_COLUMN}
        dataTestId="test-fileReceiptGridDefault"
        records={results || []}
        selectionKey='filekey'
        initialSortingKey={`${sortConfig.sortDataName}|${sortConfig.sortDescending === "true" ? 'SORT_DESCENDING' : 'SORT_ASCENDING'}`}
        onSortGridColumn={(e, state) => getArgumentsAndSortGrid(state)}
        sortingComparator={(sortDataName, sortDirectionString, recordsToSort) => recordsToSort}
        focusedItem={selectedRecord}
        onClickThrough={(e, rec) => {
          e.preventDefault();
          e.stopPropagation();
          if (rec && rec.filekey && rec.status === 'Imported') {
            openDetailPane()
            setSelectedRecord(rec)
            dispatch(resetFileKey());
            dispatch(setFileKey(rec.filekey))
          } else {
            notify(`File ${rec?.filekey} is in ${rec?.status} status. File Receipt details are not available for this file`, 'neutral')
          }
          return false;
        }}
      />
    );
  };

  return (
    <>
      <ListPaneHeaderWrapper
        title="File Receipt Manager"
        layoutControls={[expandCollapseListPaneButtonConfig]}
      />
      {isLoading ? (
        <LoadingPage />
      ) :  isError ? (
        // replace with a better error component
        <ErrorPage
          // this prop isn't real but is an example
          error={error}
        />
      ) : (
          <div>
            {datesError && <p style={{ fontSize: '13px', color: 'red', marginLeft: '40px' }}>{errMsg}</p>}
            <div className='mainWrapper'>
              {renderSearchContent}
            </div>
            <div className='GridWrapper'>
              {renderGridContent()}
            </div>
          </div>
        )}
    </>
  )
}

export default withAxiosCancellation(FileReceiptManagerMain)
