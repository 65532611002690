export const SET_RULE_TYPES_SEARCH_RESULTS = 'SET_RULE_TYPES_SEARCH_RESULTS';
export const RULE_TYPES_REQUEST_TRIGGER = 'RULE_TYPES_REQUEST_TRIGGER';
export const RULE_TYPES_REQUEST_SUCCESS = 'RULE_TYPES_REQUEST_SUCCESS';
export const RULE_TYPES_REQUEST_ERROR = 'RULE_TYPES_REQUEST_ERROR';
export const UPDATE_RULETYPES_REQUEST_TRIGGER =
  'UPDATE_RULETYPES_REQUEST_TRIGGER';
export const RULES_GRID_QUERY_UPDATE = 'RULES_GRID_QUERY_UPDATE';
export const RULE_DETAILS_REQUEST_TRIGGER = 'RULE_DETAILS_REQUEST_TRIGGER';
export const RULE_DETAILS_REQUEST_SUCCESS = 'RULE_DETAILS_REQUEST_SUCCESS';
export const RULE_DETAILS_REQUEST_ERROR = 'RULE_DETAILS_REQUEST_ERROR';
export const SET_IS_VIEWING_RULE_DETAILS = 'SET_IS_VIEWING_RULE_DETAILS';
export const SET_RULE_TYPE_MODAL_OPEN = 'SET_RULE_TYPE_MODAL_OPEN';
export const SET_RULE_TYPE_REQUEST_TRIGGER = 'SET_RULE_TYPE_REQUEST_TRIGGER';
export const SET_RULE_TYPE_REQUEST_SUCCESS = 'SET_RULE_TYPE_REQUEST_SUCCESS';
export const SET_RULE_TYPE_REQUEST_ERROR = 'SET_RULE_TYPE_REQUEST_ERROR';
export const SET_RULE_TYPES_SEARCH_QUERY = 'SET_RULE_TYPES_SEARCH_QUERY';
export const SET_SELECTED_RULE_RECORD = 'SET_SELECTED_RULE_RECORD';
export const SET_SELECTED_DETAILS_RECORD_ID = 'SET_SELECTED_DETAILS_RECORD_ID';
export const SET_IS_SHOWING_DELETE_RULE_DETAIL_MODAL =
  'SET_IS_SHOWING_DELETE_RULE_DETAIL_MODAL';
export const DELETE_RULE_DETAIL_TRIGGER = 'DELETE_RULE_DETAIL_TRIGGER';
export const DELETE_RULE_DETAIL_SUCCESS = 'DELETE_RULE_DETAIL_SUCCESS';
export const DELETE_RULE_DETAIL_ERROR = 'DELETE_RULE_DETAIL_ERROR';
export const SET_IS_SHOWING_DETAIL_CLONE_RULE_MODAL =
  'SET_IS_SHOWING_DETAIL_CLONE_RULE_MODAL';
export const OVERWRITE_TEST_RULE_REQUEST_TRIGGER =
  'OVERWRITE_TEST_RULE_REQUEST_TRIGGER';
export const OVERWRITE_TEST_RULE_SUCCESS = 'OVERWRITE_TEST_RULE_SUCCESS';
export const OVERWRITE_TEST_RULE_ERROR = 'OVERWRITE_TEST_RULE_ERROR';
export const SET_SELECTED_RULE_TYPE_ID = 'SET_SELECTED_RULE_TYPE_ID';
export const SET_IS_SHOWING_OVERWRITE_MODAL = 'SET_IS_SHOWING_OVERWRITE_MODAL';
export const SET_RULE_TYPES_REQUEST_SUCCESS = 'SET_RULE_TYPES_REQUEST_SUCCESS';
export const RESET_RULE_TYPES = 'RESET_RULE_TYPES';
export const IMAGES_TEST_COUNT_REQUEST_TRIGGER = 'IMAGES_TEST_COUNT_REQUEST_TRIGGER';
export const IMAGES_TEST_COUNT_REQUEST_SUCCESS = 'IMAGES_TEST_COUNT_REQUEST_SUCCESS';
export const IMAGES_TEST_COUNT_REQUEST_ERROR = 'IMAGES_TEST_COUNT_REQUEST_ERROR';
export const RESET_STATE = 'RESET_STATE';
