import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Modal,
  ModalHeader,
  ButtonGroup,
  Button,
  ModalBody
} from 'ui-core/dist'
import axios from '../../axios'
import useAssignableRolesAndSources from '../../api/useAssignableRolesAndSources'
import { SelectDropdown, Loading } from '../../components'
import "./modules/styles.scss"
import { InputLabel } from 'ui-core/dist/chunk-4203e5d9'
import useNotificationBannerHook from '../../useNotificationBannerHook';

const AccessEntryModal = ({
  confirmationCallback,
  userId,
  isAccessOpen,
  onAccessClose,
}) => {
  const [ inFlight, setInFlight ] = useState(false)
  const [ role, setRole ] = useState()
  const [ dataSource, setDataSource ] = useState()
  const [ stage, setStage ] = useState(1)
  const { notify } = useNotificationBannerHook()
  const { 
    roles, 
    dataSources, 
    isLoading,
  } = useAssignableRolesAndSources()
  
  const onClose = () => {
    onAccessClose(false)
    setStage(1)
  }
  
  const onCancel = () => {
    onAccessClose(false)
    setStage(1)
  }

  const onNext = () => setStage(2)

  const onBack = () => setStage(1)

  const onRoleDropdownChange = (state) => setRole(state)

  const onDataSourceDropdownChange = (state) => setDataSource(state)

  const onConfirm = async () => {
    setInFlight(true)
    try {
      await axios.post(`/members/${userId}/access/${dataSource.value}/roles/${role.value}`)
      notify(`You have successfully added an access entry for the user ${userId}`, 'positive')
      onClose()
    } catch(err) {
      notify(err.displayMessage, 'negative')
    } finally {
      setInFlight(false)
      confirmationCallback()
    }
  }

  return (
    <Modal isOpen={isAccessOpen} className="access-entry-modal">
      <ModalHeader 
        title={stage === 1 ? "Grant An Access List Entry" : "Confirm Access List Entry Grant"}
        onClose={onClose}
      />
      <ModalBody>
        <div className="access-entry-modal__contents">
          {stage === 1 ? (
            <>
              {isLoading ? <Loading /> : (
                <>
                  <p>Select the Data Source and Role that will make up the access list entry you&rsquo;re granting to {userId}</p>
                  <SelectDropdown
                    width="300px"
                    domID="AccessRoleDropown"
                    label="Role"
                    name="Role"
                    size="medium"
                    onChange={onRoleDropdownChange}
                    initialValue={role}
                    options={roles?.map(r => ({ label: r.name, value: r.id }))}
                  />
                  <InputLabel>Data Source</InputLabel>
                  <SelectDropdown
                    width="300px"
                    placeholder="Select..."
                    domID="AccessDataSourceDropown"
                    onChange={onDataSourceDropdownChange}
                    initialValue={dataSource}
                    options={dataSources?.map(ds => ({ label: ds.description, value: ds.id }))}
                  />
                </>
              )}
            </>
          ) : (
            <>
              <div className="access-modal__row">
                <span className="access-modal__label">Data Source: </span>
                <span>{dataSource.label}</span>
              </div>
              <div className="access-modal__row">
                <span className="access-modal__label">Role: </span>
                <span>{role.label}</span>
              </div>
            </>
          )}
          <ButtonGroup className="access-entry-modal__button-group">
            {stage === 1 ? (
              <>
                <Button
                  domID="AccessEntryModalCancel"
                  name="Cancel"
                  onClick={onCancel}
                  size="small"
                  buttonType="standard"
                />
                <Button
                  domID="AccessEntryModalNext"
                  name="Next"
                  onClick={onNext}
                  size="small"
                  buttonType="standard"
                  disabled={!role || !dataSource}
                /> 
              </>
            ) : (
              <>
                <Button
                  domID="AccessEntryModalBack"
                  name="Back"
                  onClick={onBack}
                  size="small"
                  buttonType="standard"
                />
                <Button
                  domID="AccessEntryModalConfirm"
                  name="Confirm"
                  onClick={onConfirm}
                  size="small"
                  buttonType="standard"
                  disabled={inFlight}
                />
              </>
            )}
          </ButtonGroup>
        </div>
      </ModalBody>
    </Modal>
  )
}

AccessEntryModal.propTypes = {
  confirmationCallback: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  isAccessOpen: PropTypes.bool.isRequired,
  onAccessClose: PropTypes.func.isRequired,
}

export default AccessEntryModal