import React, { useState, useEffect, useMemo } from 'react';
// import {
//   Modal, ModalHeader, ButtonGroup, Button, SelectDropdown, ModalBody, ModalFooter
// } from 'ui-core/dist'
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Modal, ModalBody, ModalFooter, ModalHeader,LoadingIndicator } from 'ui-core/dist';
import Button from 'ui-core/dist/Button';
import ButtonGroup from 'ui-core/dist/ButtonGroup';
import { SelectDropdown } from '../../components'
import axios from '../../axios';
import { setContext, setPermissions, toggleCtxModal } from '../ChooseContext/modules/actions';
import { setSelected as navSelected } from '../SideNav/modules/actions';
import { updateServerConfig } from '../../redux/app/serverConfigState/actions';
import './index.scss';

const SelectContextModal = () => {
  const dispatch = useDispatch()
  const [error, setError] = useState()
  const [state, setState] = useState()
  const [loadingBtnScreen, setLodingBtn] = useState(false)
  const [initialValue, setInitialValue] = useState()
  const [options, setOptions] = useState()
  const [selected, setSelected] = useState()
  const { isModalOpen } = useSelector(({ context }) => context)
  const { token, userId } = useSelector(({ session }) => session)
  const { accessEntryId, dataSourceDesc, roleName } = useSelector(({ context }) => context?.context || {})

  useEffect(() => {
    if (isModalOpen) axios.get('/users/contexts',
    {
      headers: {
        'username': userId
      }
    }).then(res => {
      if (res.data.length === 1) {
        dispatch(setContext(res.data[0]))
        if (res.data[0].container) {
          dispatch(push('/fileTracker'))
          dispatch(navSelected('/fileTracker'))
        } else {
          dispatch(push('/'))
          dispatch(navSelected('/'))
        }
      }
      if(res && res.data) {
      setState(res.data)
      }
      setOptions(res.data.map(ctx => ({
        label: `${ctx.dataSourceDesc}: ${ctx.roleName}`,
        value: ctx.accessEntryId
      })))

      if (accessEntryId) {
        setInitialValue({
          label: `${dataSourceDesc}: ${roleName}`,
          value: accessEntryId
        })
        setSelected(accessEntryId)
      } else {
        setInitialValue({
          label: `${res.data[0].dataSourceDesc}: ${res.data[0].roleName}`,
          value: res.data[0].accessEntryId
        })
        setSelected(res.data[0].accessEntryId)
      }
    }).catch(err => {
      setError(err)
    })
  }, [isModalOpen])

  const handleChange = (state) => {
    setInitialValue(state);
    setSelected(state.value)
  }

  const handleSubmit = () => {
    setLodingBtn(true)
    axios.put(`/users/updateContext/${selected}`,
      {
        headers: {
          'username': userId
        }
      }).then(res => {
        const ctx = state.filter(c => c.accessEntryId === selected)[0]
        dispatch(setContext(ctx))
        const permissions = res.data
        dispatch(setPermissions(permissions))
        dispatch(updateServerConfig({
          clientLayout: `${ctx.client}${ctx.layout}`,
          userName: userId,
          dataSourceDefinition: ctx.dataSourceDefinition,
          dataSourceId: ctx.dataSourceId,
          token,
          roleId: ctx.roleId,
          userRole: ctx.roleName,
          userPermissions: permissions.map(perm => perm.name)
        }))
        dispatch(toggleCtxModal())
        if (ctx.container && ctx.container.toString() === '1') {
          dispatch(push('/fileTracker'))
          dispatch(navSelected('/fileTracker'))
        } else {
          dispatch(push('/'))
          dispatch(navSelected('/'))
        }
        setLodingBtn(false)
      }).catch(err => {
        setError(err)
        setLodingBtn(false)
      })
      
  }

  const handleReset = () => {
    if (accessEntryId) {
      setInitialValue({
        label: `${dataSourceDesc}: ${roleName}`,
        value: accessEntryId
      })
      setSelected(accessEntryId)
    } else {
      setInitialValue({
        label: `${state[0].dataSourceDesc}: ${state[0].roleName}`,
        value: state[0].accessEntryId
      })
      setSelected(state[0].accessEntryId)
    }
  }

  const handleModalClose = () => dispatch(toggleCtxModal())
const renderDropdownContent=useMemo(()=>(
  <SelectDropdown
                    width="600px"
                    dataTestId="test-ContextDropdown"
                    domID="ContextDropdown"
                    onChange={handleChange}
                    options={options}
                    initialValue={initialValue}
                    label="Choose a context:"
                    size="medium"
                    className="choose-context__dropdown"
                  />
), [options, initialValue])
  return (
    <>
      {isModalOpen ?
        <div>
          <Modal isOpen={isModalOpen}>
            <ModalHeader
              title="Select Active Context"
              onClose={handleModalClose}
            />
            <div id='SelectContextModal'>
              <ModalBody>
                {error && <p className="error" style={{ color: 'red' }}>{error.displayMessage}</p>}
                <div className="select-context-modal" style={{ padding: '0 24px' }}>
                  <p>
                    Select the security context that you want to make active. Each security context
                    in the Select Context list defines the tasks that you can perform on a client&apos;s
                    data for a specific layout.
              </p>
              {renderDropdownContent}
                </div>
              </ModalBody>
            </div>
            <ModalFooter>
              <ButtonGroup>
                {loadingBtnScreen ? <LoadingIndicator/> : ''}
                <Button
                  buttonType="standard"
                  domID="SubmitButton"
                  dataTestId="test-submit"
                  name="Submit"
                  onClick={handleSubmit}
                  size="medium"
                  disabled={loadingBtnScreen ||  !state}
                />
                <Button
                  buttonType="standard"
                  domID="ResetButton"
                  dataTestId="test-reset"
                  name="Reset"
                  onClick={handleReset}
                  size="medium"
                  disabled={!state}
                />
              </ButtonGroup>
            </ModalFooter>
          </Modal> </div> : ''}
    </>
  )
}

export default SelectContextModal
