import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from "react-router-dom";
import {
  Grid,
  useLayout,
  ListPaneHeader,
  Button,
} from 'ui-core/dist'
import { isAddForm } from './modules/actions'
import useFormList from '../../api/useFormList'
import { Loading } from '../../components'
import ListPaneHeaderWrapper from '../../components/ErrorDisplaySection/ListPaneHeaderWrapper';
import ErrorPage from '../ErrorPage';
import './modules/index.scss';

const ReserveForm = () => {
  const dispatch = useDispatch()

  const search = useLocation().search;
  const param = new URLSearchParams(search).get('param');

  const [selectedRecord, setSelectedRecord] = useState('')
  const [idx, setIdx] = useState(0)

  const { expandCollapseListPaneButtonConfig, openDetailPane } = useLayout()
  const { data, isLoading, isError, error, refetch } = useFormList(param)

  const getTitle = () => {
    if (param === '0') return 'Reserve/Modify Form';
    if (param === '1') return 'Reserve/Modify Image';
    if (param === 'M') return 'Reserve/Modify 835 Map';
  }

  const getButtonName = () => {
    if (param === '0') return 'Reserve Form';
    if (param === '1') return 'Reserve Image';
    if (param === 'M') return 'Reserve 835 Map';
  }

  const getColumnName = () => {
    if (param === '0') return 'Available Forms';
    if (param === '1') return 'Available Images';
    if (param === 'M') return 'Available 835 Maps';
  }

  const onClickThrough = (e, item) => {
    setSelectedRecord(item);
    dispatch(isAddForm(false))
    openDetailPane({ id: item.value, showDetailPane: true, isAddForm: false, idx: idx + 1 });
    setIdx(idx + 1)
  }

  const refresh = () => {
    refetch();
  };

  const onAddForm = () => {
    dispatch(isAddForm(true))
    openDetailPane({
      showDetailPane: true,
      isAddForm: true,
      refresh,
      idx: idx + 1
    })
    setIdx(idx + 1)
  }

  const getTableName = () => {
    if (param === '0') return 'sys_forms';
    if (param === '1') return 'sys_images';
    if (param === 'M') return 'sys_835map';
  }

  const getName = () => {
    if (param === '0') return 'form';
    if (param === '1') return 'image';
    if (param === 'M') return 'map';
  }

  return (
    <>
      <ListPaneHeaderWrapper
        title={getTitle()}
        layoutControls={[expandCollapseListPaneButtonConfig]}
      />
      <p style={{fontSize: '13px', padding: '0 40px'}}>This function allows the user to reserve/modify a {getName()} in the {getTableName()} table. Make all the neccessary entries and click Save. The record will be entered into the {getTableName()} table. In case of Reserve {getName()}, the user must immediately rename the {getName()} they are working with to the name returned by this function and check it into VSS. In case of modify {getName()}, when the user clicks on Save, the record in the {getTableName()} table for that {getName()} will be updated and results are displayed </p>
      <div className="reserveModifyForm">
        <Button
          domID="ReserveForm"
          name={getButtonName()}
          onClick={onAddForm}
          size="small"
          buttonType="standard"
          className="reserveModifyForm__add-button"
        />
        <div className="reserveModifyForm__grid-wrapper">
          {isLoading ? <Loading /> :
            isError ? (
            // replace with a better error component
            <ErrorPage
              // this prop isn't real but is an example
              error={error}
            />
          ) : (
            data && data.length > 0 ? <Grid
              columns={new Set([
                {
                  dataName: 'label',
                  text: getColumnName(),
                  cellType: 'text',
                }
              ])}
              records={data || []}
              dataTestId="test-ReserveModifyFormGrid"
              domID="ReserveModifyFormGrid"
              onClickThrough={onClickThrough}
              focusedItem={selectedRecord}
              selectionKey="value"
              isFixedHeader
              maxHeight="400px"
            /> : <p style={{marginLeft: '40px', fontSize: '13px'}}><i>No records to display at this time</i></p>
          )}
        </div>
      </div>
    </>
  )
}

export default ReserveForm