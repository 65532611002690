import { useAxios } from '../hooks'

const useServers = () => {
  const { data, ...rest } = useAxios({
    url: `/sysAdmin/servers`,
  })

  return { 
    data,
    ...rest
  }
}

export default useServers