import styled from 'styled-components';

export const RulesGridWrapperStyle = styled.div`
  & td.status {
    display: inline-flex;
  }
  & button:not(.settings-trigger) {
    text-align: left;
  }
  & svg {
    right: auto !important;
  }
`;

export const LoadMoreButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  & button {
    display: inline-block;
    padding: 4px;
    margin: 10px;
  }
`;

export const InputLabelWrapper = styled.div`
  font-size: 14px;
  line-height: 1.4em;
  font-weight: 700;
  letter-spacing: 0.5px;
  display: inline-block;
`;

export const LoadingWrapper = styled.div`
  display: inline-block;
`;

export const GridCellWrap = styled.div`
  text-decoration: underline;
`;

export const LoadingContainer = styled.div`
  margin-bottom: 100px;
  position: absolute;
  width: 100%;
  z-index: 100;
`;

export const GridLoadingWrapper = styled.div`
  left: 48%;
  position: absolute;
`;
