/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { useLayout, Button } from 'ui-core/dist'
import { useDispatch, } from 'react-redux';
import withAxiosCancellation from '../../../components/withAxiosCancellation'
import { LoadingPage, CountDownTimer } from '../../../components';
import useNotificationBannerHook from '../../../useNotificationBannerHook';
import ErrorPage from '../../ErrorPage';
import {
  GENERIC_TEXT1,
  GENERIC_TEXT2,
  GENERIC_TEXT_RELEASE
} from "../Modules/constants";
import ListPaneHeaderWrapper from '../../../components/ErrorDisplaySection/ListPaneHeaderWrapper';
import { useCurrentTime, useReleaseTime } from '../../../api/useDocumentManager';
import DocumentManagerDocumentSearchForm from '../DocumentManagerDocumentSearchForm';
import { setFileSearchParam, setFileSearchParamNull } from "../Modules/action";
import { adjustNumber, validateNumber, validateNumber2, udpateFirstNumber } from '../../../commonResources/helpers/DocumentHelper'


import '../Modules/styles.scss';

const DocumentSearchReleaseMain = ({getCancelSourceWithName}) => {
  const {
    expandCollapseListPaneButtonConfig
  } = useLayout()

  const history = useHistory();
  const dispatch = useDispatch();
  const { notify } = useNotificationBannerHook();
  const [isResetFlag, setIsResetFlag] = useState(false);
  const [fileKeys, setFileKeys] = useState('');
  const [empGroup, setEmpGroup] = useState('');
  const [recipientType, setRecipientType] = useState('');
  const [documentType, setDocumentType] = useState('');
  const [tinSSN, setTinSSN] = useState('');
  const [checkNo1, setCheckNo1] = useState('');
  const [claimNo1, setClaimNo1] = useState('');
  const [checkNo2, setCheckNo2] = useState('');
  const [claimNo2, setClaimNo2] = useState('');
  const [checkMode, setCheckMode] = useState('');
  const [claimMode, setClaimMode] = useState('');
  const [claimType, setClaimType] = useState('');
  const [sortField, setSortField] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [itemsPerPage, setItemsPerPage] = useState({ label: 10, value: 10 });

  const { currentTime, isError: isErrorCurrentTime, error: errorCurrentTime, isLoading: isLoadingCurrentTime } = useCurrentTime({getCancelSource:getCancelSourceWithName('searchReleaseDocumentPageCurrentTime')});
 const { releaseTime, isError: isErrorReleaseTime, error: errorReleaseTime, isLoading: isLoadingReleaseTime } = useReleaseTime({getCancelSource:getCancelSourceWithName('searchReleaseDocumentPageReleaseTime')});
  const isLoading = false;
  const isError = false;
  const error = '';
  const [transactionMessage, settransactionMessage]=useState('')

  useEffect(()=>{
    if (history?.location && history?.location?.state) {
      settransactionMessage(history?.location?.state);
      history.replace({ ...history?.location, state: undefined });
    }
  },[history?.location])


  const nextClick = () => {
    const payload = {
      "maxRecords": itemsPerPage ? itemsPerPage.value : 10,
      "rowPosition": 0,
      "sortField": sortField ? sortField.value : 'transid',
      "sortDescending": sortOrder ? sortOrder.value : 'true',
      "checkNumber": adjustNumber(validateNumber(udpateFirstNumber(checkNo1, checkNo2, checkMode)), validateNumber2(checkNo2)),
      "claimNumber": adjustNumber(validateNumber(udpateFirstNumber(claimNo1, claimNo2, claimMode)), validateNumber2(claimNo2)).replace(/['"]/g, ''),
      "groupId": empGroup,
      "idNumber": tinSSN,
      "filekey": fileKeys,
      "showDuplicateChecks": true,
      "showProcessedDocuments": false,
      "claimTypeId": claimType,
      "recipientType": recipientType,
      "documentType": documentType,
    }
    dispatch(setFileSearchParamNull(payload))
    dispatch(setFileSearchParam(payload))
    history.push(`/DocumentManager/Release/Document/searchResults`)
  }

  return (
    <>
      <ListPaneHeaderWrapper
        title="Document Manager: Release Documents"
        layoutControls={[expandCollapseListPaneButtonConfig]}

      />
      {isLoadingCurrentTime || isLoadingReleaseTime ? (
        // replace with a better loading component
        <LoadingPage />
      ) : isErrorCurrentTime || isErrorReleaseTime ? (
        // replace with a better error component
        <ErrorPage
          // this prop isn't real but is an example
          error={errorCurrentTime || errorReleaseTime}
        />
      ) : (
            <div className="mainWrapper">
              {releaseTime && currentTime ?
                <CountDownTimer
                  startDate={releaseTime}
                  endDate={currentTime}
                /> : ''}
              <div style={{ fontSize: "small", padding: "12px" }}>
                <i>{GENERIC_TEXT1}</i>
                <p>Type a complete or a partial claim number, check number, TIN, or SSN to search for a specific document or a range of documents. 
                  You can also select a group or file to display all documents within the group or file. 
                  Click Next to display the search results page where you can select the documents you want to release.</p>
                <p style={{ color: "#bf1616" }}>
                  {GENERIC_TEXT_RELEASE}
                </p>
                {transactionMessage && <p style={{ color: "black", fontWeight:'bold' }}>{transactionMessage}</p>}
              </div>
              <DocumentManagerDocumentSearchForm
              getCancelSourceWithName={getCancelSourceWithName}
                isRelease={true}
                isCheckNumberExist
                isClaimNumberExist
                isSSNExist
                isSortingExist={false}
                perPage={itemsPerPage}
                isRangeControlEnabled
                isResetField={isResetFlag}
                setIsResetFlag={setIsResetFlag}
                changeTinSsn={setTinSSN}
                changeFileKey={setFileKeys}
                changeEmpGroup={setEmpGroup}
                changeRecipientTypes={setRecipientType}
                changeDocumentTypes={setDocumentType}
                changeClaimType={setClaimType}
                changeSortField={setSortField}
                changeSortOrder={setSortOrder}
                changeItemsPerPage={setItemsPerPage}
                changeCheckNo1={setCheckNo1}
                changeCheckNo2={setCheckNo2}
                changeClaimNo1={setClaimNo1}
                changeClaimNo2={setClaimNo2}
                changeClaimNoMode={setClaimMode}
                changeCheckNoMode={setCheckMode}
                nextClick={nextClick}
              />
              <div className="displayFlex marginLeft">
                <Button
                  buttonType="standard"
                  size="small"
                  name="RESET"
                  onClick={() => {
                    setIsResetFlag(true);
                  }}
                />
                <Button
                  buttonType="standard"
                  size="small"
                  style={{ marginLeft: '10px' }}
                  name="NEXT->"
                  onClick={() => { nextClick() }}
                />
              </div>
            </div>
          )}
    </>
  )
}

export default withAxiosCancellation(DocumentSearchReleaseMain)
