/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
/* eslint-disable camelcase */
import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { push } from 'connected-react-router';
import { useHistory } from 'react-router-dom'
import SingleSelectItem from 'ui-core/dist/SingleSelectItem';
import SingleSelectGroup from '../../components/SingleSelectGroup/SingleSelectGroup';
import Search from 'ui-core/dist/Search';
import { Modal, ModalHeader, Input, Button, LoadingIndicator, ModalBody } from 'ui-core/dist';
import {
  documentSearch,
  setSelected,
} from './modules/actions';
import Authorizer from '../Authorizer';
import { AnchorInput, SelectDropdown } from '../../components';
import { useDiscriminationTypes } from '../../api/useDocumentManager'
import { setQuickSearchParams } from '../DocumentManager/Modules/action';
import { resetDetailsPane } from '../../redux/app/detailsPaneState/actions';
import { clearLoadingState } from '../../redux/app/loadingState/actions'
import { resetGroupsState } from '../../redux/api/groups/actions'
import { resetGroupsList } from '../../redux/api/groupsList/actions'
import { resetCommitState } from '../../redux/api/commitRuleTypesList/actions'
import { resetLiveAssocState } from '../../redux/api/liveAssocRuleTypesList/actions'
import { resetRuleDetails } from '../../redux/api/ruleDetails/actions'
import { resetRulesForRuleTypes } from '../../redux/api/rulesForRuleTypes/actions'
import { deleteAllNotifications } from '../../redux/app/notificationState/actions'
import { resetRuleTypesList } from '../../redux/api/ruleTypesList/actions'
import { resetRuleTypeRecordsState } from '../../redux/api/rulesForRuleTypesList/actions'
import { CLAIM_TYPE, CLAIM_TYPE_NOT_AVAILABLE, DOCUMENT_TYPE, DOCUMENT_TYPE_LIST_NOT_AVAILABLE, RECIPIENT_TYPE, RECIPIENT_TYPE_LIST_NOT_AVAILABLE } from '../DocumentManager/Modules/constants';
import useHandleEnterKeyPressEvent from '../DocumentManager/Modules/useHandleEnterKeyPressEvent';
import useNotificationBannerHook from '../../useNotificationBannerHook';
import withErrorBoundary from '../../hooks/withErrorBoundary';


const SideNav = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { notify } = useNotificationBannerHook();

  const [claimNumber, setClaimNumber] = useState('');
  const [checkNumber, setCheckNumber] = useState('');
  const [tinssn, setTinssn] = useState('');
  const [recipientType, setRecipientType] = useState('none');
  const [documentType, setDocumentType] = useState('none');
  const [claimType, setClaimType] = useState('none');
  const [documentKey, setDocumentKey] = useState('');
  const [voucherNumber, setVoucherNumber] = useState('');
  const [providerState, setProviderState] = useState('');
  const [insuredPolicy, setInsuredPolicy] = useState('');
  const [patientId, setPatientId] = useState('');
  const [insuredState, setInsuredState] = useState('');
  const [benefitYear, setBenefitYear] = useState('');
  const [documentKeyValidationError, setdocumentKeyValidationError] = useState('')
  const [formValid, setFormValid] = useState(false)

  const { records: recipientTypeSource, postData: getRecipientTypes, isLoading: isLoadingRecipientTypes, isError: isErrorRecipientTypes,
    error: errorRecipientTypes } = useDiscriminationTypes();
  const { records: documentTypeSource, postData: getDocumentTypes, isLoading: isLoadingDocumentTypes, isError: isErrorDocumentTypes,
    error: errorDocumentTypes } = useDiscriminationTypes();
  const { records: claimTypeSource, postData: getClaimTypes, isLoading: isLoadingClaimTypes, isError: isErrorClaimTypes,
    error: errorClaimTypes } = useDiscriminationTypes();
  const selected = useSelector(store => store.nav.selectedNavItem);
  const { container } = useSelector(({ context }) => context?.context || {})
  const {
    etfOptions, achOptions, addressOptions, ccfOptions, clientAdminOptions,
    clientAdminItems, clientRulesOptions, documentOptions, documentItems, eeobOptions,
    groupOptions, formOptions, imageOptions, manifestOptions, welcomeKitOptions,
    reportOptions, securityOptions, sysAdminOptions, pathMapOptions,
    isDocumentSearchActive, ruleTypesOptions
  } = useSelector(store => store.nav)
  const pathMap = useMemo(() => new Map(pathMapOptions), [pathMapOptions]);

  const [isAdvancedSearchActive, setisAdvancedSearchActive] = useState(false);

  useEffect(() => {
    return () => {
      if (history.action === "POP" || history.action === "PUSH") {
        const { pathname, search } = history.location
        if(pathname !== '/temp')
          onBackButtonEvent(pathname, search)
      }
    }
  }, [history, history.location, history.location.search])

  function findInMap(map, searchValue) {
    for (let [key, value] of map.entries()) {
      if (value === searchValue)
        return true;
    }
    return false;
  }
  function getByValue(map, searchValue) {
    for (let [key, value] of map.entries()) {
      if (value === searchValue)
        return key;
    }
  }

  const firstLevelOptions = (options, selectedItem) => {
    let valid = false;
    let selectedIndex;
    const ef = options.reduce((a, b, c) => {
      if (typeof (b) === "string" && b === selectedItem) {
        return a + "|" + c
      } else return a
    }, '0');
    if (ef !== '0') {
      valid = true;
      selectedIndex = ef;
    }
    return { valid, selectedIndex };
  }
  const resolveDocumentPaths = (path) => {
    if (path === "/DocumentManager/Hold/Document/searchResults") {
      path = "/DocumentManager/Hold/Document"
    } else if (path === "/DocumentManager/Hold/Group/searchResults")
      path = "/DocumentManager/Hold/Group"
    else if (path === "/DocumentManager/Hold/File/searchResults")
      path = "/DocumentManager/Hold/File"
    else if (path === "/DocumentManager/Release/Document/searchResults")
      path = "/DocumentManager/Release/Document"
    else if (path === "/DocumentManager/Release/Group/searchResults")
      path = "/DocumentManager/Release/Group"
    else if (path === "/DocumentManager/Release/File/searchResults")
      path = "/DocumentManager/Release/File"
    else if (path === "/DocumentManager/Purge/Document/searchResults")
      path = "/DocumentManager/Purge/Document"
    else if (path === "/DocumentManager/Purge/Group/searchResults")
      path = "/DocumentManager/Purge/Group"
    else if (path === "/DocumentManager/Purge/File/searchResults")
      path = "/DocumentManager/Purge/File"
    else if (path === "/DocumentManager/Route/Document/searchResults")
      path = "/DocumentManager/Route/Document"
    else if (path === "/DocumentManager/Route/Group/searchResults")
      path = "/DocumentManager/Route/Group"
    else if (path === "/DocumentManager/Route/File/searchResults")
      path = "/DocumentManager/Route/File"
    else if (path==="/DocumentManager/SearchResults")
      path="/DocumentManager/Search"
    else path = null;
    return path;
  }
  const onBackButtonEvent = (pathname, search) => {
    let path = `${pathname}${search}`
    let selectedItem;
    let selectedIndex;
    path = path.replace('/all', '').replace('/index', '').replace('/list', '')
    if (path) {
      let exists = false;
      if (findInMap(pathMap, path)) {
        exists = true;
      } else if (resolveDocumentPaths(path)) {
        path = resolveDocumentPaths(path)
        if (findInMap(pathMap, path)) {
          exists = true;
        }
      }
      if (exists) {
        const pathKey = getByValue(pathMap, path);
        if (['Document', 'Group', 'File'].find(x => (pathKey || "").indexOf(x) !== -1)
          && (path || "").indexOf('DocumentManager/') !== -1 && pathKey !== "Override File Cutoff") {
          // const pat = /([0-9]+[|]*)*(([A-Za-z])+)/gm;
          const pat = /(([A-Za-z])+)/gm
          const lastSeg = pathKey.split("|")[2];
          const res = pat.exec(lastSeg)
          if (res && res.length >= 1) {
            selectedItem = res[1];
            if (documentItems && documentItems[0] && documentItems[0].items) {
              documentItems[0].items.forEach((item, index)=>{
                if(item && typeof item === 'object' && path.split('/').indexOf(item.text) !== -1){
                  selectedIndex = `0|${index}|${item.items.indexOf(selectedItem)}`;
                  dispatch(setSelected([selectedIndex, selectedItem]));
                }
              })  
            }
          }
        } else {
          selectedItem = pathKey;
          let valid = false;
          if (selectedItem === "Search" || selectedItem === "Override File Cutoff" || selectedItem === "History") {
            valid = true;
            if(documentItems && documentItems[0] && documentItems[0].items
               && documentItems[0].items.indexOf(selectedItem)!==-1){
              const currIndex = documentItems[0].items.indexOf(selectedItem)
              selectedIndex = `0|${currIndex}`
            }
          }
          if (!valid) {
            if (clientAdminItems && clientAdminItems.constructor.name==="Array"
            && clientAdminItems.length>0 && clientAdminItems[0]
             && clientAdminItems[0].items
            && clientAdminItems[0].items.constructor.name==="Array"
            && clientAdminItems[0].items.length>0) {
              const cs = clientAdminItems[0]?.items?.reduce((a, b, c) => {
                if (typeof (b) === "string" && b === selectedItem) {
                  return a + "|" + c
                } else if (typeof (b) === "object" && b?.items.constructor.name === 'Array' && b?.items.find(t => t === selectedItem)) {
                  return b?.items.reduce((x, y, z) => { if (y === selectedItem) { return x + "|" + z } else return x }, a + "|" + c);
                } else return a
              }, '0');
              if (cs !== '0') {
                valid = true;
                selectedIndex = cs;
              }
            }
          }
          if (!valid) ({ valid, selectedIndex } = firstLevelOptions(etfOptions, selectedItem));
          if (!valid) ({ valid, selectedIndex } = firstLevelOptions(achOptions, selectedItem));
          if (!valid) ({ valid, selectedIndex } = firstLevelOptions(addressOptions, selectedItem));
          if (!valid) ({ valid, selectedIndex } = firstLevelOptions(clientRulesOptions, selectedItem));
          if (!valid) ({ valid, selectedIndex } = firstLevelOptions(formOptions, selectedItem));
          if (!valid) ({ valid, selectedIndex } = firstLevelOptions(groupOptions, selectedItem));
          if (!valid) ({ valid, selectedIndex } = firstLevelOptions(imageOptions, selectedItem));
          if (!valid) ({ valid, selectedIndex } = firstLevelOptions(welcomeKitOptions, selectedItem));
          if (!valid) ({ valid, selectedIndex } = firstLevelOptions(reportOptions, selectedItem));
          if (!valid) ({ valid, selectedIndex } = firstLevelOptions(ruleTypesOptions, selectedItem));
          if (!valid) ({ valid, selectedIndex } = firstLevelOptions(securityOptions, selectedItem));
          if (!valid) ({ valid, selectedIndex } = firstLevelOptions(sysAdminOptions, selectedItem));
          if (valid) {
            dispatch(setSelected([selectedIndex, selectedItem]));
          }
        }
      } else {
        dispatch(setSelected(path));
      }
    }
  }

  useEffect(() => {
    onClearFields();
  }, [isDocumentSearchActive])
  useEffect(() => {
    if (isDocumentSearchActive) {
      if (!isAdvancedSearchActive) {
        if (!claimNumber && !checkNumber && !tinssn && !documentKey && !voucherNumber) {
          setFormValid(false);
          setdocumentKeyValidationError(null);
          return;
        }
      }
      if (!claimNumber && !checkNumber && !tinssn && recipientType === "none" && documentType === "none"
        && claimType === "none" && !documentKey && !voucherNumber && !providerState && !insuredPolicy
        && !patientId && !insuredState && !benefitYear) {
        setFormValid(false);
        setdocumentKeyValidationError(null);
        return;
      }
      // Validate any "new" required fields here before submitting form.
      // Document Key
      if (!((documentKey.length === 0) || (documentKey.length >= 17 && documentKey.length <= 23))) {
        setFormValid(false);
        setdocumentKeyValidationError("Document key length must be in the range of 17 to 23 characters");
        return;
      }
    }
    setFormValid(true);
    setdocumentKeyValidationError(null);
  }, [claimNumber, checkNumber, tinssn, recipientType, documentType, claimType, documentKey, voucherNumber,
    providerState, insuredPolicy, patientId, insuredState, benefitYear, isAdvancedSearchActive])

  const handleDocumentSearch = () => {
    // dispatch(setSelected('documentSearch'));
    dispatch(documentSearch(true));
  }

  const handleSingleSelect = path => {
    if (`${window.location.pathname}${window.location.search}` === path) {
      history.push('/temp');
      history.goBack();
      // window.location.reload(true);
    } else {
      dispatch(setSelected(path));
      dispatch(push(path));
    }

  }

  useEffect(() => {
    onClearAdvancedSearchFields();
    if (isAdvancedSearchActive) {
      getRecipientTypes({ type: RECIPIENT_TYPE })
      getDocumentTypes({ type: DOCUMENT_TYPE })
      getClaimTypes({ type: CLAIM_TYPE })
    }
  }, [isAdvancedSearchActive])

  useEffect(() => {
    if (isErrorRecipientTypes) {
      notify(errorRecipientTypes || RECIPIENT_TYPE_LIST_NOT_AVAILABLE)
    }
    if (isErrorDocumentTypes) {
      notify(errorDocumentTypes || DOCUMENT_TYPE_LIST_NOT_AVAILABLE)
    }
    if (isErrorClaimTypes) {
      notify(errorClaimTypes || CLAIM_TYPE_NOT_AVAILABLE)
    }
  }, [isErrorRecipientTypes, isErrorDocumentTypes, isErrorClaimTypes])

  const onClearAdvancedSearchFields = () => {
    setRecipientType('none')
    setDocumentType('none')
    setClaimType('none')

    setProviderState('')
    setInsuredPolicy('')
    setPatientId('')
    setInsuredState('')
    setBenefitYear('')
  }

  const onClearFields = () => {
    setClaimNumber('')
    setCheckNumber('')
    setTinssn('')

    setRecipientType('none')
    setDocumentType('none')
    setClaimType('none')

    setDocumentKey('')
    setVoucherNumber('')

    setProviderState('')
    setInsuredPolicy('')
    setPatientId('')
    setInsuredState('')
    setBenefitYear('')
  }

  function fixSearchParams(callback) {
    const id_number = tinssn.replace(/\*/g, "").replace(/^\s+|\s+$/g, "")
    if (id_number && id_number.length !== 30) {
      setTinssn(id_number)
      if (callback) callback(id_number)
    }
  }
  const getRecipientsType = (key) => {
    let value = "";
    if (key === "00") {
      value = "I";
    } else if (key === "01") {
      value = "P";
    } else if (key === "02") {
      value = "G";
    } else if (key === "03") {
      value = "T";
    } else if (key === "04") {
      value = "O";
    } else {
      value = "";
    }
    return value;
  }
  const onSearchClick = () => {
    let id_number = tinssn.replace(/\*/g, "").replace(/^\s+|\s+$/g, "")
    fixSearchParams((param) => {
      id_number = param
    })
    const payload = {
      sKey: documentKey,
      sClaimNumber: claimNumber,
      sCheckNumber: checkNumber,
      sRecipientType: recipientType !== 'none' ? getRecipientsType(recipientType.toString()) : '',
      sRecipientID: id_number,
      sDocumentType: documentType !== 'none' ? documentType : '',
      sVoucherNumber: voucherNumber,
      sInsuredState: insuredState,
      sProviderState: providerState,
      sPolicyNumber: insuredPolicy,
      sPatientID: patientId,
      sBenefitYear: benefitYear,
    };
    let srchClaimType = "";
    const claimTypes = claimType !== 'none' ? [claimType] : [];
    if (claimTypes && claimTypes.length > 0) {
      for (let j = 0; j < claimTypes.length - 1; j++)
        srchClaimType = `${srchClaimType + claimTypes[j]}|`;
      srchClaimType += claimTypes[claimTypes.length - 1];
    }
    payload.sClaimType = srchClaimType;
    dispatch(documentSearch(false))
    setisAdvancedSearchActive(false);
    dispatch(setQuickSearchParams(payload))
    history.push('/DocumentManager/SearchResultsQuick')
  }

  const config = {
    fields: [{
      fieldName: 'txt_claimNumber',
      validator: (value) => value.length > 0 && value.length <= 30
    }, {
      fieldName: 'txt_checkNumber',
      validator: (value) => value.length > 0 && value.length <= 21
    }, {
      fieldName: 'txt_voucherNumber',
      validator: (value) => value.length > 0 && value.length <= 16
    }, {
      fieldName: 'txt_documentKey',
      validator: (value) => value.length === 17 || value.length === 23
    }, {
      fieldName: 'txt_tinssn',
      validator: (value) => value.length > 0 && value.length <= 30
    }, {
      fieldName: 'txt_providerState',
      validator: (value) => value.length > 0 && value.length <= 2
    }, {
      fieldName: 'txt_insuredPolicy',
      validator: (value) => value.length > 0 && value.length <= 20
    }, {
      fieldName: 'txt_patientId',
      validator: (value) => value.length > 0 && value.length <= 15
    }, {
      fieldName: 'txt_insuredState',
      validator: (value) => value.length > 0 && value.length <= 2
    }, {
      fieldName: 'txt_benefitYear',
      validator: (value) => value.length > 0 && value.length <= 4
    }],
    onEnterKeyPressHandler: onSearchClick
  }
  const { handleEnterKeyPressEvent } = useHandleEnterKeyPressEvent(config)

  const handleGroupMenuSelect = (e, { selectedItem, selectedIndex }) => {
    dispatch(setSelected([selectedIndex, selectedItem]));
    let pathKey = selectedItem;
    if (['Document', 'Group', 'File'].includes(selectedItem)) {
      pathKey = `${selectedIndex}${selectedItem}`
    }
    const path = pathMap.get(pathKey);
    if (`${window.location.pathname}${window.location.search}` === path) {
      history.push('/temp');
      history.goBack();
      // window.location.reload(true);
    }
    else {
      dispatch(push(path));
    }

  }

  const handleGroupMenuReload = (e, { selectedItem, selectedIndex }) => {
    dispatch(resetDetailsPane())
    dispatch(clearLoadingState())
    dispatch(resetGroupsState())
    dispatch(resetGroupsList())
    dispatch(resetLiveAssocState())
    dispatch(resetCommitState())
    dispatch(resetRuleDetails())
    dispatch(resetRulesForRuleTypes())
    dispatch(resetRuleTypesList())
    dispatch(resetRuleTypeRecordsState())
    dispatch(deleteAllNotifications())
    dispatch(setSelected([selectedIndex, selectedItem]));
    let pathKey = selectedItem;
    if (['Document', 'Group', 'File'].includes(selectedItem)) {
      pathKey = `${selectedIndex}${selectedItem}`
    }
    const path = pathMap.get(pathKey);
    dispatch(push(path));
  }

  const chosenGroupOption = arr =>
    ((Array.isArray(selected) && arr?.includes(selected[1])) && selected[0]) || ''

  const renderRouteLinks = () => (
    <>
      {!(container && container.toString() === '1') &&
        <>
          <Authorizer permission="CAN_ACCESS_DOC_SEARCH">
            <SingleSelectItem
              dataTestId="test-DocumentSearch"
              domID="DocumentSearch"
              text={<>Document Search <Search size="medium" /></>}
              // isSelected={selected === "documentSearch"}
              onClick={handleDocumentSearch}
            />
          </Authorizer>
          <SingleSelectItem
            dataTestId="test-DashboardMenuSelect"
            domID="DashboardMenuSelect"
            text="Dashboard"
            isSelected={selected === "/"}
            onClick={() => handleSingleSelect("/")}
          />
        </>
      }

      <Authorizer permission="CAN_ACCESS_835MAP_MANAGER" >
        <SingleSelectGroup
          dataTestId="test-835MapManagerMenuSelect"
          domID="835MapManagerMenuSelect"
          initialSelectedIndex={chosenGroupOption(etfOptions)}
          initialOpenGroupIndices={new Set(chosenGroupOption(etfOptions)?.split('|'))}
          items={[{ text: "835 Map Manager", items: etfOptions }]}
          onSelect={handleGroupMenuSelect}
        />
      </Authorizer>
      <Authorizer permission="CAN_ACCESS_ACH" >
        <SingleSelectGroup
          dataTestId="test-ACHManagerMenuSelect"
          domID="ACHManagerMenuSelect"
          initialSelectedIndex={chosenGroupOption(achOptions)}
          initialOpenGroupIndices={new Set(chosenGroupOption(achOptions)?.split('|'))}
          items={[{ text: "ACH Manager", items: achOptions }]}
          onSelect={handleGroupMenuSelect}
        />
      </Authorizer>
      <Authorizer permission="CAN_ACCESS_FINANCE_MANAGER" >
        <SingleSelectItem
          dataTestId="test-Financemanger"
          domID="Financemanger"
          text="Finance Manager"
          isSelected={selected === "/EMDFinanceManager"}
          onClick={() => handleSingleSelect("/EMDFinanceManager")}
        />
      </Authorizer>
      <Authorizer permission="CAN_ACCESS_ADDRESS" >
        <SingleSelectGroup
          dataTestId="test-AddressManagerMenuSelect"
          domID="AddressManagerMenuSelect"
          initialSelectedIndex={chosenGroupOption(addressOptions)}
          initialOpenGroupIndices={new Set(chosenGroupOption(addressOptions)?.split('|'))}
          items={[{ text: "Address Manager", items: addressOptions }]}
          onSelect={handleGroupMenuSelect}
        />
      </Authorizer>
      {/* <Authorizer permission="CAN_ACCESS_CCF_MANAGER" >
        <SingleSelectGroup
          dataTestId="test-CCFManagerMenuSelect"
          domID="CCFManagerMenuSelect"
          initialSelectedIndex={chosenGroupOption(ccfOptions)}
          items={[{ text: "CCF Manager", items: ccfOptions }]}
          onSelect={handleGroupMenuSelect}
        />
      </Authorizer> */}
      <Authorizer permission="CAN_ACCESS_CLIENT_ADMINISTRATION" >
        <SingleSelectGroup
          dataTestId="test-ClientAdministrationMenuSelect"
          domID="ClientAdministrationMenuSelect"
          initialSelectedIndex={chosenGroupOption(clientAdminOptions)}
          initialOpenGroupIndices={chosenGroupOption(clientAdminOptions) ? new Set([chosenGroupOption(clientAdminOptions)[0], chosenGroupOption(clientAdminOptions)?.slice(0, 3), chosenGroupOption(clientAdminOptions)]) : new Set()}
          items={clientAdminItems}
          onSelect={handleGroupMenuSelect}
        />
      </Authorizer>
      <Authorizer permission="CAN_ACCESS_CLIENTRULES" >
        <SingleSelectGroup
          dataTestId="test-ClientRulesManagerMenuSelect"
          domID="ClientRulesManagerMenuSelect"
          initialSelectedIndex={chosenGroupOption(clientRulesOptions)}
          initialOpenGroupIndices={chosenGroupOption(clientRulesOptions) ? new Set([chosenGroupOption(clientRulesOptions)[0], chosenGroupOption(clientRulesOptions)?.slice(0, 3), chosenGroupOption(clientRulesOptions)]) : new Set()}
          items={[{ text: "Client Rules Manager", items: clientRulesOptions }]}
          onSelect={handleGroupMenuSelect}
        />
      </Authorizer>
      <Authorizer permission="CAN_ACCESS_DIRECT_PRINT_JOB_TRACKER">
        <SingleSelectItem
          dataTestId="test-DirectPrintJobTracker"
          domID="DirectPrintJobTrackerSelect"
          text="Direct Print Job Tracker"
          isSelected={selected === "/DirectPrintJobTracker"}
          onClick={() => handleSingleSelect("/DirectPrintJobTracker")}
        />
      </Authorizer>
      <Authorizer permission="CAN_ACCESS_DOC" >
        <SingleSelectGroup
          dataTestId="test-DocumentManagerMenuSelect"
          domID="DocumentManagerMenuSelect"
          initialSelectedIndex={chosenGroupOption(documentOptions)}
          initialOpenGroupIndices={chosenGroupOption(documentOptions) ? new Set([chosenGroupOption(documentOptions)[0], chosenGroupOption(documentOptions)?.slice(0, 3), chosenGroupOption(documentOptions)]) : new Set()}
          items={documentItems}
          onSelect={handleGroupMenuSelect}
        />
      </Authorizer>
      <Authorizer permission="CAN_ACCESS_EEOBFORM_MANAGER" >
        <SingleSelectItem
          dataTestId="test-EEOBFormManager"
          domID="EEOBFormManagerMenuSelect"
          text="EEOB Form Manager"
          isSelected={selected === "/EEOBFormManager"}
          onClick={() => handleSingleSelect("/EEOBFormManager")}
        />
        {/* <SingleSelectGroup
          dataTestId="test-EEOBFormManagerMenuSelect"
          domID="EEOBFormManagerMenuSelect"
          initialSelectedIndex={chosenGroupOption(eeobOptions)}
          items={[{ text: "EEOB Form Manager", items: eeobOptions }]}
          onSelect={handleGroupMenuSelect}
        /> */}
      </Authorizer>
      <Authorizer permission="CAN_ACCESS_ERROR" >
        <SingleSelectItem
          dataTestId="test-ErrorManagerMenuSelect"
          domID="ErrorManagerMenuSelect"
          text="Error Manager"
          isSelected={selected === "/ErrorManager"}
          onClick={() => handleSingleSelect("/ErrorManager")}
        />
      </Authorizer>
	  
	  <Authorizer permission="CAN_ACCESS_ERROR" >
        <SingleSelectItem
          dataTestId="test-ErrorManagerTransMenuSelect"
          domID="ErrorManagerTransMenuSelect"
          text="Error Translation Manager"
          isSelected={selected === "/ErrorManagerTranslation"}
          onClick={() => handleSingleSelect("/ErrorManagerTranslation")}
        />
      </Authorizer>
  
      <Authorizer permission="CAN_ACCESS_FILERECEIPT" >
        <SingleSelectItem
          dataTestId="test-FileReceiptManagerMenuSelect"
          domID="FileReceiptManagerMenuSelect"
          text="File Receipt Manager"
          isSelected={selected === "/FileReceiptManager"}
          onClick={() => handleSingleSelect("/FileReceiptManager")}
        />
      </Authorizer>
      <Authorizer permission="CAN_ACCESS_FILETRACKER" >
        <SingleSelectItem
          dataTestId="test-FileTrackerMenuSelect"
          domID="FileTrackerMenuSelect"
          text="File Tracker"
          isSelected={selected === "/fileTracker"}
          onClick={() => handleSingleSelect("/fileTracker")}
        />
      </Authorizer>
      <Authorizer permission="CAN_ACCESS_FORM_MANAGER" >
        <SingleSelectGroup
          dataTestId="test-FormManagerMenuSelect"
          domID="FormManagerMenuSelect"
          initialSelectedIndex={chosenGroupOption(formOptions)}
          initialOpenGroupIndices={new Set(chosenGroupOption(formOptions)?.split('|'))}
          items={[{ text: "Form Manager", items: formOptions }]}
          onSelect={handleGroupMenuSelect}
        />
      </Authorizer>
      <Authorizer permission="CAN_ACCESS_SELFSERVICE_GROUPS" >
        <SingleSelectGroup
          dataTestId="test-GroupsMenuSelect"
          domID="GroupsMenuSelect"
          initialSelectedIndex={chosenGroupOption(groupOptions)}
          initialOpenGroupIndices={new Set(chosenGroupOption(groupOptions)?.split('|'))}
          items={[{ text: "Groups", items: groupOptions }]}
          onSelect={handleGroupMenuReload}
        />
      </Authorizer>
      <Authorizer permission="CAN_ACCESS_FORM_MANAGER" >
        <SingleSelectGroup
          dataTestId="test-ImageManagerMenuSelect"
          domID="ImageManagerMenuSelect"
          initialSelectedIndex={chosenGroupOption(imageOptions)}
          initialOpenGroupIndices={new Set(chosenGroupOption(imageOptions)?.split('|'))}
          items={[{ text: "Image Manager", items: imageOptions }]}
          onSelect={handleGroupMenuSelect}
        />
      </Authorizer>
      <Authorizer permission="CAN_ACCESS_IDCARDS" >
        <SingleSelectGroup
          dataTestId="test-IDCardMenuSelect"
          domID="IDCardMenuSelect"
          initialSelectedIndex={chosenGroupOption(welcomeKitOptions)}
          initialOpenGroupIndices={new Set(chosenGroupOption(welcomeKitOptions)?.split('|'))}
          items={[{ text: "ID Card/Welcome Kit Manager", items: welcomeKitOptions }]}
          onSelect={handleGroupMenuSelect}
        />
      </Authorizer>
      <Authorizer permission="CAN_ACCESS_MANIFEST_INFO" >
        <SingleSelectItem
          dataTestId="test-ManifestInformationMenuSelect"
          domID="ManifestInformationMenuSelect"
          text="Manifest Information"
          isSelected={selected === "/ManifestInformationManager"}
          onClick={() => handleSingleSelect("/ManifestInformationManager")}
        />
        {/* <SingleSelectGroup
          dataTestId="test-ManifestInformationMenuSelect"
          domID="ManifestInformationMenuSelect"
          initialSelectedIndex={chosenGroupOption(manifestOptions)}
          items={[{ text: "Manifest Information", items: manifestOptions }]}
          onSelect={handleGroupMenuSelect}
        /> */}
      </Authorizer>
      <Authorizer permission="CAN_ACCESS_REPORTBUILDER" >
        <SingleSelectItem
          dataTestId="test-ReportBuilderMenuSelect"
          domID="ReportBuilderMenuSelect"
          text="Report Builder"
          isSelected={selected === "/reportBuilder"}
          onClick={() => handleSingleSelect("/reportBuilder")}
        />
      </Authorizer>
      <Authorizer permission="CAN_ACCESS_ABF_REPORTS" >
        <SingleSelectGroup
          dataTestId="test-ReportManagerMenuSelect"
          domID="ReportManagerMenuSelect"
          initialSelectedIndex={chosenGroupOption(reportOptions)}
          initialOpenGroupIndices={new Set(chosenGroupOption(reportOptions)?.split('|'))}
          items={[{ text: "Report Manager", items: reportOptions }]}
          onSelect={handleGroupMenuSelect}
        />
      </Authorizer>
      <Authorizer permission="CAN_ACCESS_SELFSERVICE_RULETYPES" >
        <SingleSelectGroup
          dataTestId="test-RuleTypesMenuSelect"
          domID="RuleTypesMenuSelect"
          initialSelectedIndex={chosenGroupOption(ruleTypesOptions)}
          initialOpenGroupIndices={new Set(chosenGroupOption(ruleTypesOptions)?.split('|'))}
          items={[{ text: "Rule Types", items: ruleTypesOptions }]}
          onSelect={handleGroupMenuReload}
        />
      </Authorizer>

      <Authorizer permission="CAN_ACCESS_SECURITY" >
        <SingleSelectGroup
          dataTestId="test-SecurityManagerMenuSelect"
          domID="SecurityManagerMenuSelect"
          initialSelectedIndex={chosenGroupOption(securityOptions)}
          initialOpenGroupIndices={new Set(chosenGroupOption(securityOptions)?.split('|'))}
          items={[{ text: "Security Manager", items: securityOptions }]}
          onSelect={handleGroupMenuSelect}
        />
      </Authorizer>
      <Authorizer permission="CAN_ACCESS_SYSTEM_ADMINISTRATION" >
        <SingleSelectGroup
          dataTestId="test-SystemAdministrationMenuSelect"
          domID="SystemAdministrationMenuSelect"
          initialSelectedIndex={chosenGroupOption(sysAdminOptions)}
          initialOpenGroupIndices={new Set(chosenGroupOption(sysAdminOptions)?.split('|'))}
          items={[{ text: "System Administration", items: sysAdminOptions }]}
          onSelect={handleGroupMenuSelect}
        />
      </Authorizer>
      <Authorizer permission="CAN_ACCESS_TESTFILE" >
        <SingleSelectItem
          dataTestId="test-TestFileManagerMenuSelect"
          domID="TestFileManagerMenuSelect"
          text="Test File Manager"
          isSelected={selected === "/TestFileManager"}
          onClick={() => handleSingleSelect("/TestFileManager")}
        />
      </Authorizer>
    </>
  )
  const renderSimpleQuickSearchForm = () => (
    <table className="common-table">
      <tr>
        <td>
          <label> Claim Number : </label>
        </td>
        <td>
          <div className="marginLeft" style={{ width: '250px' }}>
            <Input
              style={{ width: '200px' }}
              className="test-classname"
              domID="txt_claimNumber"
              placeholder=""
              size="small"
              maxLength={30}
              initialValue={claimNumber}
              onChange={(e) => {
                setClaimNumber(e.target.value || "")
              }}
              onKeyUp={(e) => {
                handleEnterKeyPressEvent(e, e?.currentTarget?.id, claimNumber)
              }}
            />
          </div>
        </td>
      </tr>
      <tr><p></p></tr>
      <tr>
        <td>
          <div>
            <label data-tip data-for="lblCheckNumber">
              Check Number:&nbsp;<strong><font color='#bf1616'>*</font></strong>
              <br />
              <strong>
                <i style={{ color: '#bf1616' }}><font color='#bf1616'>*</font>
                  &nbsp;Exclude preceding zeros</i><br />
              </strong>
            </label>
            <ReactTooltip id="lblCheckNumber" type='dark' effect='solid'>
              <span>Payer generated check number</span>
            </ReactTooltip>
          </div>
        </td>
        <td>
          <div className="marginLeft" style={{ width: '250px' }}>
            <Input
              style={{ width: '200px' }}
              className="test-classname"
              domID="txt_checkNumber"
              placeholder=""
              size="small"
              maxLength={21}
              initialValue={checkNumber}
              onChange={(e) => {
                setCheckNumber(e.target.value || "")
              }}
              onKeyUp={(e) => {
                handleEnterKeyPressEvent(e, e?.currentTarget?.id, checkNumber)
              }}
            />
          </div>
        </td>
      </tr>
      <tr><p></p></tr>
      <tr>
        <td>
          <label> Voucher Number : </label>
        </td>
        <td>
          <div className="marginLeft" style={{ width: '250px' }}>
            <Input
              style={{ width: '200px' }}
              className="test-classname"
              domID="txt_voucherNumber"
              placeholder=""
              size="small"
              maxLength={16}
              initialValue={voucherNumber}
              onChange={(e) => {
                setVoucherNumber(e.target.value || "")
              }}
              onKeyUp={(e) => {
                handleEnterKeyPressEvent(e, e?.currentTarget?.id, voucherNumber)
              }}
            />
          </div>
        </td>
      </tr>
      <tr><p></p></tr>
      <tr>
        <td>
          <label> SSN/TIN : </label>
        </td>
        <td>
          <div className="marginLeft" style={{ width: '250px' }}>
            <Input
              style={{ width: '200px' }}
              className="test-classname"
              domID="txt_tinssn"
              placeholder=""
              size="small"
              maxLength={30}
              initialValue={tinssn}
              onChange={(e) => {
                setTinssn(e.target.value || "")
              }}
              onKeyUp={(e) => {
                handleEnterKeyPressEvent(e, e?.currentTarget?.id, tinssn)
              }}
            />
          </div>
        </td>
      </tr>
      <tr><p></p></tr>
      <tr>
        <td>
          <label> Document Key : </label>
        </td>
        <td>
          <div className="marginLeft" style={{ width: '250px' }}>
            <Input
              style={{ width: '200px' }}
              className="test-classname"
              domID="txt_documentKey"
              placeholder=""
              size="small"
              maxLength={23}
              initialValue={documentKey}
              onChange={(e) => {
                setDocumentKey(e.target.value || "")
              }}
              onKeyUp={(e) => {
                handleEnterKeyPressEvent(e, e?.currentTarget?.id, documentKey)
              }}
            />
          </div>
          <div className="marginLeft">
            {documentKeyValidationError ? (
              <span id="lblDocumentKeyError" style={{ fontSize: '10pt', color: 'red' }}>{documentKeyValidationError}&nbsp;</span>
            ) : null}
          </div>
        </td>
      </tr>
      <tr><p></p></tr>
      <tr>
        <td colSpan="2">
          <label><b>Note: </b><i>Document Key searches less than 23 characters may result in timeout.</i></label>
        </td>
      </tr>
      <tr><p></p></tr>
      <tr>
        <td colSpan="2">
          <div className="displayFlex">
            <div className="marginLeft">
              <AnchorInput
                id="btn_advanced_search"
                type='button'
                className='fileKeyLink'
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setisAdvancedSearchActive(true);
                }}
              >Advanced Search</AnchorInput>
            </div>
            <div className="marginLeft">
              <Button
                domID="btn_cancel"
                buttonType="standard"
                size="small"
                name="CLEAR FIELDS"
                onClick={() => onClearFields()}
              />
            </div>
            <div className="marginLeft">
              <Button
                domID="btn_search"
                buttonType="standard"
                size="small"
                name="SEARCH"
                disabled={!formValid}
                onClick={() => onSearchClick()}
              />
            </div>
          </div>
        </td>
      </tr>
      <tr><p></p></tr>
    </table>
  )
  const renderAdvancedQuickSearchForm = () => (
    <div style={{ maxHeight: '63vh', overflow: 'auto' }}>
      <div className="displayFlex">
        <label>Document search finds documents that match the search options you complete, whether or not the documents have been processed. Complete one or a combination of fields on the search form. Click Next to begin the search.</label>
      </div>
      <table className="common-table">
        <tr>
          <td>
            <label> Claim Number : </label>
          </td>
          <td>
            <div className="marginLeft" style={{ width: '250px' }}>
              <Input
                style={{ width: '200px' }}
                className="test-classname"
                domID="txt_claimNumber"
                placeholder=""
                size="small"
                maxLength={30}
                initialValue={claimNumber}
                onChange={(e) => {
                  setClaimNumber(e.target.value || "")
                }}
                onKeyUp={(e) => {
                  handleEnterKeyPressEvent(e, e?.currentTarget?.id, claimNumber)
                }}
              />
            </div>
          </td>
        </tr>
        <tr><p></p></tr>
        <tr>
          <td>
            <div>
              <label data-tip data-for="lblCheckNumber">
                Check Number:&nbsp;<strong><font color='#bf1616'>*</font></strong>
                <br />
                <strong>
                  <i style={{ color: '#bf1616' }}><font color='#bf1616'>*</font>
                    &nbsp;Exclude preceding zeros</i><br />
                </strong>
              </label>
              <ReactTooltip id="lblCheckNumber" type='dark' effect='solid'>
                <span>Payer generated check number</span>
              </ReactTooltip>
            </div>
          </td>
          <td>
            <div className="marginLeft" style={{ width: '250px' }}>
              <Input
                style={{ width: '200px' }}
                className="test-classname"
                domID="txt_checkNumber"
                placeholder=""
                size="small"
                maxLength={21}
                initialValue={checkNumber}
                onChange={(e) => {
                  setCheckNumber(e.target.value || "")
                }}
                onKeyUp={(e) => {
                  handleEnterKeyPressEvent(e, e?.currentTarget?.id, checkNumber)
                }}
              />
            </div>
          </td>
        </tr>
        <tr><p></p></tr>
        <tr>
          <td>
            <label> SSN/TIN : </label>
          </td>
          <td>
            <div className="marginLeft" style={{ width: '250px' }}>
              <Input
                style={{ width: '200px' }}
                className="test-classname"
                domID="txt_tinssn"
                placeholder=""
                size="small"
                maxLength={30}
                initialValue={tinssn}
                onChange={(e) => {
                  setTinssn(e.target.value || "")
                }}
                onKeyUp={(e) => {
                  handleEnterKeyPressEvent(e, e?.currentTarget?.id, tinssn)
                }}
              />
            </div>
          </td>
        </tr>
        <tr><p></p></tr>
        <tr id="recipientTypeControl">
          <td>
            <label>  Recipent Type: </label>
          </td>
          <td>
            <div className="marginLeft" style={{ width: '250px' }}>
              {isLoadingRecipientTypes ? <LoadingIndicator /> : (
                <>
                  {isErrorRecipientTypes ? <span>{RECIPIENT_TYPE_LIST_NOT_AVAILABLE}</span> : <SelectDropdown
                    domID="sl_recipient_type"
                    label=""
                    options={recipientTypeSource}
                    size="medium"
                    initialValue={recipientTypeSource.find(g => g.value === recipientType)}
                    onChange={(e) => {
                      if (e) {
                        setRecipientType(e.value)
                      }
                    }}
                  />}
                </>
              )}
            </div>
          </td>

        </tr>
        <tr><p></p></tr>
        <tr id="documentTypeControl">
          <td>
            <label>  Document Type: </label>
          </td>
          <td>
            <div className="marginLeft" style={{ width: '250px' }}>
              {isLoadingDocumentTypes ? <LoadingIndicator /> : (
                <>
                  {isErrorDocumentTypes ? <span>{DOCUMENT_TYPE_LIST_NOT_AVAILABLE}</span> : <SelectDropdown
                    domID="sl_document_type"
                    label=""
                    options={documentTypeSource}
                    size="medium"
                    initialValue={documentTypeSource.find(g => g.value === documentType)}
                    onChange={(e) => {
                      if (e) {
                        setDocumentType(e.value)
                      }
                    }}
                  />}
                </>
              )}
            </div>
          </td>
        </tr>
        <tr><p></p></tr>
        <tr id="claimNumberControl">
          <td>
            <label>  Claim Type: </label>
          </td>
          <td>
            <div className="marginLeft" style={{ width: '250px' }}>
              {isLoadingClaimTypes ? <LoadingIndicator /> : (
                <>
                  {isErrorClaimTypes ? <span>{CLAIM_TYPE_NOT_AVAILABLE}</span> : <SelectDropdown
                    domID="sl_claim_type"
                    label=""
                    options={claimTypeSource}
                    size="medium"
                    initialValue={claimTypeSource.find(g => g.value === claimType)}
                    onChange={(e) => {
                      if (e) {
                        setClaimType(e.value)
                      }
                    }}
                  />}
                </>
              )}
            </div>
          </td>
        </tr>
        <tr><p></p></tr>
        <tr>
          <td>
            <label> Document Key : </label>
          </td>
          <td>
            <div className="marginLeft" style={{ width: '250px' }}>
              <Input
                style={{ width: '200px' }}
                className="test-classname"
                domID="txt_documentKey"
                placeholder=""
                size="small"
                maxLength={23}
                initialValue={documentKey}
                onChange={(e) => {
                  setDocumentKey(e.target.value || "")
                }}
                onKeyUp={(e) => {
                  handleEnterKeyPressEvent(e, e?.currentTarget?.id, documentKey)
                }}
              />
            </div>
            <div className="marginLeft">
              {documentKeyValidationError ? (
                <span id="lblDocumentKeyError" style={{ fontSize: '10pt', color: 'red' }}>{documentKeyValidationError}&nbsp;</span>
              ) : null}
            </div>
          </td>
        </tr>
        <tr><p></p></tr>
        <tr>
          <td>
            <label> Voucher Number : </label>
          </td>
          <td>
            <div className="marginLeft" style={{ width: '250px' }}>
              <Input
                style={{ width: '200px' }}
                className="test-classname"
                domID="txt_voucherNumber"
                placeholder=""
                size="small"
                maxLength={16}
                initialValue={voucherNumber}
                onChange={(e) => {
                  setVoucherNumber(e.target.value || "")
                }}
                onKeyUp={(e) => {
                  handleEnterKeyPressEvent(e, e?.currentTarget?.id, voucherNumber)
                }}
              />
            </div>
          </td>
        </tr>
        <tr><p></p></tr>
        <tr>
          <td>
            <label> Provider State : </label>
          </td>
          <td>
            <div className="marginLeft" style={{ width: '250px' }}>
              <Input
                style={{ width: '200px' }}
                className="test-classname"
                domID="txt_providerState"
                placeholder=""
                size="small"
                maxLength={2}
                initialValue={providerState}
                onChange={(e) => {
                  setProviderState(e.target.value || "")
                }}
                onKeyUp={(e) => {
                  handleEnterKeyPressEvent(e, e?.currentTarget?.id, providerState)
                }}
              />
            </div>
          </td>
        </tr>
        <tr><p></p></tr>
        <tr>
          <td>
            <label> Insured Policy # : </label>
          </td>
          <td>
            <div className="marginLeft" style={{ width: '250px' }}>
              <Input
                style={{ width: '200px' }}
                className="test-classname"
                domID="txt_insuredPolicy"
                placeholder=""
                size="small"
                maxLength={20}
                initialValue={insuredPolicy}
                onChange={(e) => {
                  setInsuredPolicy(e.target.value || "")
                }}
                onKeyUp={(e) => {
                  handleEnterKeyPressEvent(e, e?.currentTarget?.id, insuredPolicy)
                }}
              />
            </div>
          </td>
        </tr>
        <tr><p></p></tr>
        <tr>
          <td>
            <label> Patient ID : </label>
          </td>
          <td>
            <div className="marginLeft" style={{ width: '250px' }}>
              <Input
                style={{ width: '200px' }}
                className="test-classname"
                domID="txt_patientId"
                placeholder=""
                size="small"
                maxLength={15}
                initialValue={patientId}
                onChange={(e) => {
                  setPatientId(e.target.value || "")
                }}
                onKeyUp={(e) => {
                  handleEnterKeyPressEvent(e, e?.currentTarget?.id, patientId)
                }}
              />
            </div>
          </td>
        </tr>
        <tr><p></p></tr>
        <tr>
          <td>
            <label> Insured State : </label>
          </td>
          <td>
            <div className="marginLeft" style={{ width: '250px' }}>
              <Input
                style={{ width: '200px' }}
                className="test-classname"
                domID="txt_insuredState"
                placeholder=""
                size="small"
                maxLength={2}
                initialValue={insuredState}
                onChange={(e) => {
                  setInsuredState(e.target.value || "")
                }}
                onKeyUp={(e) => {
                  handleEnterKeyPressEvent(e, e?.currentTarget?.id, insuredState)
                }}
              />
            </div>
          </td>
        </tr>
        <tr><p></p></tr>
        <tr>
          <td>
            <label> Benefit Year : </label>
          </td>
          <td>
            <div className="marginLeft" style={{ width: '250px' }}>
              <Input
                style={{ width: '200px' }}
                className="test-classname"
                domID="txt_benefitYear"
                placeholder=""
                size="small"
                maxLength={4}
                initialValue={benefitYear}
                onChange={(e) => {
                  setBenefitYear(e.target.value || "")
                }}
                onKeyUp={(e) => {
                  handleEnterKeyPressEvent(e, e?.currentTarget?.id, benefitYear)
                }}
              />
            </div>
          </td>
        </tr>
        <tr><p></p></tr>
        <tr>
          <td colSpan="2">
            <div className="displayFlex">
              <div className="marginLeft">
                <Button
                  domID="btn_clear"
                  buttonType="standard"
                  size="small"
                  name="CLEAR FIELDS"
                  onClick={() => onClearFields()}
                />
              </div>
              <div className="marginLeft">
                <Button
                  domID="btn_cancel"
                  buttonType="standard"
                  size="small"
                  name="CANCEL"
                  onClick={() => setisAdvancedSearchActive(false)}
                />
              </div>
              <div className="marginLeft">
                <Button
                  domID="btn_search"
                  buttonType="standard"
                  size="small"
                  name="SEARCH"
                  disabled={!formValid}
                  onClick={() => onSearchClick()}
                />
              </div>
            </div>
          </td>
        </tr>
        <tr><p></p></tr>
      </table>
    </div>
  )

  return (
    <>
      {renderRouteLinks()}
      {isDocumentSearchActive ?
        <Modal isOpen={isDocumentSearchActive}>
          <ModalHeader
            title="Document Search"
            onClose={() => {
              // dispatch(resetDocumentSearchParams())
              dispatch(documentSearch(false))
            }}
          />
          <ModalBody>
            {isDocumentSearchActive ? (
              <div className="marginLeft" style={{ marginLeft: '45px' }}>
                {!isAdvancedSearchActive ? renderSimpleQuickSearchForm() : renderAdvancedQuickSearchForm()}
              </div>
            ) : null}
          </ModalBody>
        </Modal> : null}
    </>
  )
}

export default withErrorBoundary(SideNav);
