/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import appReducer from './containers/PageWrapper/modules/reducer';
import navReducer from './containers/SideNav/modules/reducer';
import contextReducer from './containers/ChooseContext/modules/reducer';
import sessionReducer from './containers/Login/modules/reducer'
import errorManagerReducers from './containers/ErrorManager/Modules/reducer'
import errormessagesReducers from './components/ErrorDisplaySection/modules/reducer'
import documentSearchReducers from './containers/DocumentManager/Modules/reducer'
import testFileReducer from './containers/TestFileManager/modules/reducer'
import fileReceiptReducers from './containers/FileReceiptManager/Modules/reducer'
import userManagerReducer from './containers/UserManager/modules/reducer'
import formManagerReducer from './containers/FormImage835Manager/modules/reducer';
import sysAdminReducer from './containers/SystemAdmin/modules/reducer'
import clientAdministrationReducer from './containers/ClientAdministration/Modules/reducer'
import manageAddressReducer from './containers/AddressManager/Modules/reducer'
import eeobFormManagerReducer from './containers/EEOBFormManager/Modules/reducer'
import payerEftAccountReducer from './containers/ClientRulesManager/Modules/reducer';
import financeManagerReducer from './containers/EMDFinanceManager/Modules/reducer';

// Self service group rules reducer
import groupsList from './redux/api/groupsList/reducer';
import groups from './redux/api/groups/reducer';
import ruleTypesList from './redux/api/ruleTypesList/reducer';
import rulesForRuleTypesList from './redux/api/rulesForRuleTypesList/reducer';
import rulesForRuleTypes from './redux/api/rulesForRuleTypes/reducer';
import serverConfig from './redux/app/serverConfigState/reducer';
import commitRuleTypesList from './redux/api/commitRuleTypesList/reducer';
import liveAssocRuleTypesList from './redux/api/liveAssocRuleTypesList/reducer';
import detailsPaneState from './redux/app/detailsPaneState/reducer';
import notificationState from './redux/app/notificationState/reducer';
import loadingState from './redux/app/loadingState/reducer';
import ruleDetails from './redux/api/ruleDetails/reducer';
import clientRuleReducer from './containers/ClientRuleManager/Modules/reducer'
import ManifestEntryReducer from './containers/MailManifestManager/Modules/reducer'
import directPrintReducer from './containers/DirectPrintJobTracker/Module/reducer'
/**
 * Creates the main reducer with the dynamically injected ones
 */
export default function createReducer(injectedReducers = {}, history = null) {
  return (state, action) => {
    let newState = state;
    if (action.type === 'USER_LOGGED_OUT') {
      newState = undefined;
    }

    return combineReducers({
      router: connectRouter(history),
      app: appReducer,
      nav: navReducer,
      context: contextReducer,
      session: sessionReducer,
      errorManager: errorManagerReducers,
      errormessages: errormessagesReducers,
      documentSearch: documentSearchReducers,
      testFileManager: testFileReducer,
      fileReceipt: fileReceiptReducers,
      userManager: userManagerReducer,
      formManager: formManagerReducer,
      sysAdmin: sysAdminReducer,
      clientAdministration: clientAdministrationReducer,
      manageAddress: manageAddressReducer,
      eeobFormManager: eeobFormManagerReducer,
      payerEftAccount: payerEftAccountReducer,
      clientRule: clientRuleReducer,
      financeManager : financeManagerReducer,
      manifestEntry: ManifestEntryReducer,
      directPrint: directPrintReducer,

      groups,
      groupsList,
      ruleTypesList,
      rulesForRuleTypes,
      rulesForRuleTypesList,
      ruleDetails,
      commitRuleTypesList,
      liveAssocRuleTypesList,
      loadingState,
      notificationState,
      serverConfig,
      detailsPaneState,
      ...injectedReducers,
    })(newState, action);
  };
}