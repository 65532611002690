/* eslint-disable no-nested-ternary */
/* eslint-disable no-useless-escape */
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import styled from 'styled-components/dist/styled-components'
import {
    Grid,
    useLayout,
    ListPaneHeader,
    Button, Input, DatePickerV2
} from 'ui-core/dist'
import LoadingComponent from '../../components/LoadingComponent';
import ErrorPage from '../ErrorPage/components/ErrorPage';
import ListPaneHeaderWrapper from '../../components/ErrorDisplaySection/ListPaneHeaderWrapper';
import useNotificationBannerHook from '../../useNotificationBannerHook';
import Authorizer from '../Authorizer';
import ClientInfo from './ClientInfo';
import FinanceManagerTierPrice from './FinanceManagerTierPrice';

const StyledContainer = styled.table`
width: 100%;

& tr td input[type="text"] {
    width: 250px !important;
    height: 32px !important;
}
`

const EMDFinanceManager = () => {
    const {
        expandCollapseListPaneButtonConfig,
        closeDetailPane
    } = useLayout()
    const { notify } = useNotificationBannerHook();
    const [client, setclient] = useState('')
    const [layout, setlayout] = useState('');
    const [showTierPrices, setshowTierPrices] = useState(false)
    const [showTierData, setShowTierData] = useState(false)
    const [showClientInfo, setshowClientInfo] = useState(false)
    const [showClientData, setshowClientData] = useState(false)

    const validateClient = useCallback(() => {
        if (!client) {
            return true;
        }
        return (/^\d+$/.test(client) ? false : true);
    }, [client]);

    const validateLayout = useCallback(() => {
        if (!layout) {
            return true;
        }
        return (/^\d+$/.test(layout) ? false : true);
    }, [layout]);
    const validateForm = useMemo(() => {
        if (validateClient()) {
            return false;
        }
        return true;
    }, [client, layout])
    const onResetClick = useCallback(() => {
        setclient('')
        setlayout('')
    }, [client, layout])

    const onViewTierPrices = () => {
        onCloseAll()
        setshowTierPrices(true)
        setShowTierData(true)
    }
    const onViewClientInfo = () => {
        onCloseAll()
        setshowClientInfo(true)
    }
    const onCloseTierPrices = () => {
        closeDetailPane()
        setshowTierPrices(false)
    }
    const onCloseClientInfo = () => {
        closeDetailPane()
        setshowClientInfo(false)
        setshowClientData(true)
    }
    const onCloseAll = () => {
        closeDetailPane()
        onCloseTierPrices()
        onCloseClientInfo()
    }

    const renderClientControl = useMemo(() => (
        <Input
            dataTestId="finmananger-client"
            domID="finmananger-client"
            size="medium"
            initialValue={client}
            onChange={(e) => {
                setclient(e.target.value || "");
            }}
        />
    ), [client]);
    const renderLayoutControl = useMemo(() => (
        <Input
            dataTestId="finmananger-layout"
            domID="finmananger-layout"
            size="medium"
            initialValue={layout}
            onChange={(e) => {
                setlayout(e.target.value || "");
            }}
        />
    ), [layout]);

    const isLoading = false;
    const isError = false;
    const error = null;
    return (
        <>
            <ListPaneHeaderWrapper
                title="Finance Manager"
                layoutControls={[expandCollapseListPaneButtonConfig]}
            />
            <>
                {isLoading ? (
                    // replace with a better loading component            
                    <div style={{ marginLeft: '50%' }}><LoadingComponent /></div>
                ) : isError ? (
                    // replace with a better error component
                    <ErrorPage
                        // this prop isn't real but is an example
                        error={error}
                    />
                ) : (
                            <div className="marginLeft">
                                <StyledContainer>
                                    <tr>
                                        <td className=''>
                                            <div className="displayFlex">
                                                <div className="marginLeft">
                                                    <label style={{ wordBreak: "keep-all" }}>Client:</label>
                                                </div>
                                                <div className="marginLeft">
                                                    {renderClientControl}
                                                </div>
                                                <div className="marginLeft">
                                                    <label style={{ wordBreak: "keep-all" }}>Layout:</label>
                                                </div>
                                                <div className="marginLeft">
                                                    {renderLayoutControl}
                                                </div>
                                                <div className='marginLeft'>
                                                    <Authorizer permission="CAN_ACCESS_PRODUCT_PRICING" >
                                                        <Button
                                                            buttonType="standard"
                                                            size="small"
                                                            name="View Tier Prices"
                                                            disabled={!validateForm}
                                                            onClick={onViewTierPrices}
                                                        />
                                                    </Authorizer>
                                                </div>
                                                <div className='marginLeft'>
                                                    <Button
                                                        buttonType="standard"
                                                        size="small"
                                                        name="View Client Info"
                                                        disabled={!validateForm}
                                                        onClick={onViewClientInfo}
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </StyledContainer>
                                {showTierPrices ? (
                                    <>
                                        <FinanceManagerTierPrice
                                            onCloseClientInfo={onCloseClientInfo}
                                            onResetClick={onResetClick}
                                            client={client}
                                            layout={layout}
                                            showTierPrices={showTierData}
                                            refetch={showTierPrices}
                                            clearState={() => setShowTierData(false)}
                                        />
                                    </>
                                ) : null}
                                {showClientInfo ? (
                                    <>
                                        <ClientInfo
                                            onCloseClientInfo={onCloseClientInfo}
                                            onResetClick={onResetClick}
                                            client={client}
                                            layout={layout}
                                            showClientInfo={showClientData}
                                            clearState={() => setshowClientData(false)}
                                        />
                                    </>
                                ) : null}
                            </div>
                        )
                }
            </>
        </>
    )
}

export default EMDFinanceManager;