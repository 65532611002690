import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Button, Count, SectionDescription } from 'ui-core-ssgr';
import { PanelTitle, RuleTypeButtons } from './styles';

import {
  NO_BUTTONS,
  ASSOCIATE_BUTTON,
  DISASSOCIATE_BUTTON,
} from '../../constants';

const PanelHeaderContent = ({
  ruleType,
  selectedItemIds,
  selectedRecord,
  setIsViewingGroupAssociationsModal,
  setSelectedRuleType,
  isExpanded
}) => {

  const associateHandle = e => {
    e.preventDefault();
    e.stopPropagation();

    setIsViewingGroupAssociationsModal({
      showing: true,
      associatedRuleType: ruleType.id,
      associating: true
    });
    setSelectedRuleType(ruleType);
  };

  const disasssociatePressed = e => {
    e.preventDefault();
    e.stopPropagation();

    setIsViewingGroupAssociationsModal({
      showing: true,
      associatedRuleType: ruleType.id,
      associating: false
    });
    setSelectedRuleType(ruleType);
  };

  const getRules = get(ruleType, 'rules', []);

  const generateSubRecordsCount = () => `${getRules.length}`;

  const buttonsToRender = () => {
    let buttonSet = [...DISASSOCIATE_BUTTON];
    if (ruleType.id === 'PAR' || ruleType.id === 'RAD' || ruleType.id === 'NCO' || ruleType.associatedRuleName === '' || selectedItemIds.size > 1) {
      buttonSet = [...NO_BUTTONS];
    }
    if (selectedItemIds.size === 1) {
      buttonSet = [...ASSOCIATE_BUTTON];

      const { hasLive, hasNotAssociated } = getRules
        .filter(({ id }) => selectedItemIds.has(id))
        .map(({ status, associated }) => ({
          hasLive: status.reduce(
            (hasL, elem) => hasL || elem.toUpperCase() === 'L',
            false,
          ),
          hasNotAssociated: associated.toUpperCase() === 'NO',
        }))[0];

      if (hasLive) {
        buttonSet = [...NO_BUTTONS];
      }
      if (hasNotAssociated) {
        buttonSet = [...ASSOCIATE_BUTTON];
      }
    }
    return buttonSet;
  };

  const ruleTypeName = get(ruleType, 'name', '');
  const hasEditPermission = get(ruleType, 'hasEditPermission', '');
  const ruleTypeAssociatedRuleName = get(ruleType, 'associatedRuleName', '');
  const selectedRecordStatus = get(selectedRecord, 'status', '');
  
  return (
    <>
      {isExpanded && (
        <PanelTitle>{ruleTypeName}</PanelTitle>
      )}
      {isExpanded && (
        <Count
          count={hasEditPermission !== null && hasEditPermission === true ? generateSubRecordsCount() : 0}
          domID="prod-arrangement-select-count"
        />
      )}
      {ruleTypeAssociatedRuleName && isExpanded && (
        <SectionDescription
          description={ruleTypeAssociatedRuleName}
          domID={ruleTypeAssociatedRuleName}
        />
      )}
      {selectedRecordStatus.toLowerCase() !== 'termed' ? (
        <RuleTypeButtons>
          {buttonsToRender().map(name =>
            name === 'associate' ?
              <Button
                name="Associate"
                key="associate"
                buttonType="deEmphasized"
                onClick={associateHandle}
                size="small"
                type="button"
              />
              :
              <>
              {/* show this button when permissions array is empty from CAS or permission is set to true for rule type */}
                {hasEditPermission !== null && hasEditPermission === true ?  
                  <Button
                    name="Disassociate"
                    key="disassociate"
                    buttonType="deEmphasized"
                    onClick={disasssociatePressed}
                    size="small"
                    type="button"
                  />
                  : null}
              </>
          )}
        </RuleTypeButtons>
      ) : null}
    </>
  );
}

PanelHeaderContent.propTypes = {
  ruleType: PropTypes.object,
  selectedItemIds: PropTypes.object,
  selectedRecord: PropTypes.object,
  setIsViewingGroupAssociationsModal: PropTypes.func,
  setSelectedRuleType: PropTypes.func,
  isExpanded: PropTypes.bool
};

export default PanelHeaderContent;
