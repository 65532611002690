/* eslint-disable no-nested-ternary */
import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import styled from 'styled-components/dist/styled-components'
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import ErrorPage from '../ErrorPage';
import { Grid, useLayout, Button } from 'ui-core/dist'
import { LoadingPage, SelectDropdown } from '../../components';
import LoadingComponent from '../../components/LoadingComponent';
import GridPaginationWrapper from '../../components/GridPaginationWrapper';
import ListPaneHeaderWrapper from '../../components/ErrorDisplaySection/ListPaneHeaderWrapper';
import useSortableGrid from '../../hooks/useSortableGrid';
import { useGetProdItemPricesWithTierPrices } from '../../api/useEmdFinanceManagerTierPrice'
import { setFinanceManagerTierData, setFinanceManagerNcoaDropdownData } from './Modules/action'
import './Modules/index.scss';

const StyledTable = styled.table`
  margin-top: 20px;
  cursor: default;
  tab-size: 4;
  -webkit-tap-highlight-color: transparent /* 4 */;
  -webkit-text-size-adjust: 100%;
  word-break: keep-all;
  box-sizing: border-box;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  font-family: Core Sans C;
  font-style: normal;
  font-weight: normal;
  color: #0F0F59;
  font-size: 13px;
  line-height: 150%;
  & tbody tr:nth-child(even) {
    background-color: #F4F6F9;
  }
  & thead {
    cursor: default;
    tab-size: 4;
    -webkit-tap-highlight-color: transparent /* 4 */;
    -webkit-text-size-adjust: 100%;
    word-break: keep-all;
    // border-collapse: collapse;
    border-spacing: 0;
    font-style: normal;
    font-weight: normal;
    color: #0F0F59;
    font-size: 13px;
    line-height: 150%;
    box-sizing: border-box;
    font-family: 'Core Sans C';
    background: #fff;
    box-shadow: 0px 1px 0px #EAEEF4;
    & tr th {
      cursor: default;
      tab-size: 4;
      -webkit-tap-highlight-color: transparent /* 4 */;
      -webkit-text-size-adjust: 100%;
      word-break: keep-all;
      border-collapse: collapse;
      border-spacing: 0;
      font-style: normal;
      color: #0F0F59;
      font-size: 13px;
      line-height: 150%;
      box-sizing: border-box;
      font-family: 'Core Sans C';
      // background: #fff;
      padding: 0;
      width: 40px;
    }
    & tr th.tier1 {
      background: aliceblue;
    }
    & tr th.tier2 {
      background: antiquewhite;
    }
    & tr th.tier3 {
      background: beige;
    }
    & tr th.tier4 {
      background: lightcyan;
    }
    & tr th.tier5 {
      background: lightyellow;
    }
  }
  & tbody {
    cursor: default;
    tab-size: 4;
    -webkit-tap-highlight-color: transparent /* 4 */;
    -webkit-text-size-adjust: 100%;
    word-break: keep-all;
    border-collapse: collapse;
    border-spacing: 0;
    font-style: normal;
    font-weight: normal;
    color: #0F0F59;
    font-size: 13px;
    line-height: 150%;
    box-sizing: border-box;
    font-family: 'Core Sans C';
    & tr td {
      tab-size: 4;
      -webkit-tap-highlight-color: transparent /* 4 */;
      -webkit-text-size-adjust: 100%;
      word-break: keep-all;
      border-collapse: collapse;
      border-spacing: 0;
      font-style: normal;
      font-weight: normal;
      color: #0F0F59;
      font-size: 13px;
      line-height: 150%;
      cursor: pointer;
      box-sizing: border-box;
      font-family: 'Core Sans C';
      height: 36px;
      white-space: initial;
      vertical-align: top;
      padding: 0px 8px;
      padding-left: 24px;
      width: 40px;

      & div.cell-component-wrapper {
        tab-size: 4;
        -webkit-tap-highlight-color: transparent /* 4 */;
        -webkit-text-size-adjust: 100%;
        word-break: keep-all;
        border-collapse: collapse;
        border-spacing: 0;
        font-style: normal;
        font-weight: normal;
        color: #0F0F59;
        font-size: 13px;
        line-height: 150%;
        cursor: pointer;
        white-space: initial;
        box-sizing: border-box;
        font-family: 'Core Sans C';
        min-height: 36px;
        display: flex;
        flex-direction: column;
        -webkit-box-pack: center;
        justify-content: center;
        align-items: flex-start;

        & div.no-margin {
          tab-size: 4;
          -webkit-tap-highlight-color: transparent /* 4 */;
          -webkit-text-size-adjust: 100%;
          word-break: keep-all;
          border-collapse: collapse;
          border-spacing: 0;
          cursor: pointer;
          white-space: initial;
          box-sizing: border-box;
          padding: 7px 0 !important;
          font-family: Core Sans C;
          font-style: normal;
          font-weight: normal;
          color: #0F0F59;
          font-size: 13px;
          line-height: 150%;
          text-align: left;
          margin: 0;

          & span {
            tab-size: 4;
            -webkit-tap-highlight-color: transparent /* 4 */;
            -webkit-text-size-adjust: 100%;
            word-break: keep-all;
            border-collapse: collapse;
            border-spacing: 0;
            cursor: pointer;
            white-space: initial;
            font-style: normal;
            font-weight: normal;
            color: #0F0F59;
            font-size: 13px;
            line-height: 150%;
            text-align: left;
            box-sizing: border-box;
            font-family: 'Core Sans C';
          }
        }
      }
    }
  }
  `
const StyledHeader = styled.h1`
  cursor: default;
  tab-size: 4;
  -webkit-tap-highlight-color: transparent /* 4 */;
  -webkit-text-size-adjust: 100%;
  word-break: break-word;
  box-sizing: border-box;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-family: Core Sans C;
  font-style: normal;
  color: #0F0F59;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  margin: 20px 12px 20px 0;
  `
const FinanceManagerTierPrice = ({ onCloseClientInfo, onResetClick, client, layout, showTierPrices, refetch, clearState }) => {
  const {
    expandCollapseListPaneButtonConfig,
    openDetailPane,
    closeDetailPane
  } = useLayout()
  const dispatch = useDispatch()
  const [show, onChangeShowFilter] = useState({ label: 'All', value: 'all' })
  const [count, setcount] = useState(0)
  const [records, setRecords] = useState([])
  const [tierData, setTierData] = useState([])
  const [totalRecords, setTotalRecords] = useState(0)
  const [ncoaData, setNcoaData] = useState([])
  const [selectedRecord, setSelectedRecord] = useState('')

  const { isLoading: isLoadinggetTierPriceInfo, isError: isErrorgetTierPriceInfo, error: errorgetTierPriceInfo,
    postData: getTierPriceInfo, data: getTierPriceInfoResp, metaData: getClientAndLayoutInfoMetadata } = useGetProdItemPricesWithTierPrices()

  useEffect(() => {
    if (client && showTierPrices) {
      onChangeShowFilter({ label: 'All', value: 'all' })
      const paramXML = `<![CDATA[
          <params><client>${client}</client><layout>${layout || ''}</layout><show>all</show></params>
          ]]>`;
      getTierPriceInfo({ paramXML, count })
      setcount(count + 1)
      clearState();
    }
  }, [client, layout, showTierPrices])

  useEffect(() => {
    if (ncoaData && ncoaData.length > 1)
      dispatch(setFinanceManagerNcoaDropdownData({ ncoaDropdownData: JSON.parse(JSON.stringify(ncoaData)) }))
  }, [ncoaData])

  const clickedSelectedItem = (TierPriceData) => {
    dispatch(setFinanceManagerTierData({ financeManagerTierData: JSON.parse(JSON.stringify(TierPriceData)), refetchTierData }));
    setSelectedRecord(TierPriceData);
    openDetailPane()
  }
  const refetchTierData = useCallback(() => {
    if (client && refetch) {
      const paramXML = `<![CDATA[
          <params><client>${client}</client><layout>${layout || ''}</layout><show>${show.value}</show></params>
          ]]>`;
      setcount(count + 1)
      getTierPriceInfo({ paramXML, count })

    }
  }, [show, client, count, layout, refetch])
  useEffect(() => {
    if (getTierPriceInfoResp) {
      const { collection } = getTierPriceInfoResp;
      if (collection) {
        setTotalRecords(collection.$?.totalRecords)
        const { productItemPrice } = collection;
        if (productItemPrice) {
          setRecords(productItemPrice)
        }
      } else {
        setTotalRecords(0)
        setRecords([])
      }
    }
  }, [getTierPriceInfoResp])

  useEffect(() => {
    if (records) {
      let data = []
      if (records.constructor.name === 'Array') {
        let ncoa = []
        records.forEach((val, index) => {
          if (val.productItemDesc === 'NCOA Envelope' || val.productItemDesc === 'NCOA Hit') {
            // const a = {label:val.productItemDesc, value:val.$.id}
            ncoa.push(val)
          }
          
        })
        setNcoaData(ncoa)
        if (ncoa && ncoa.length > 1) {
          let i = 0
          ncoa.forEach(v => {
            if (v.tierPrices === "" && i === 0) {
              const ind = records.indexOf(v)
              records.splice(ind, 1)
              i++
            }
          })
        }
        data = JSON.parse(JSON.stringify(records))
      }
      else
        data = JSON.parse(JSON.stringify([records]))

      data = data && data.map((TierPriceData) => {
        TierPriceData.active = TierPriceData.isActive === '1' ? 'Yes' : 'No';
        TierPriceData.id = TierPriceData.$.id;
               
        let innerTierPrice
        if (TierPriceData && TierPriceData.tierPrices && TierPriceData.tierPrices.tierPrice.constructor.name === 'Array')
          innerTierPrice = JSON.parse(JSON.stringify(TierPriceData.tierPrices.tierPrice))
        else
          innerTierPrice = JSON.parse(JSON.stringify([TierPriceData.tierPrices.tierPrice]))

        innerTierPrice = innerTierPrice.sort((a, b) => Number(a.minimumCounts) > Number(b.minimumCounts) ? 1 : -1);

        const tier1Data = innerTierPrice && innerTierPrice[0];
        const tier2Data = innerTierPrice && innerTierPrice[1];
        const tier3Data = innerTierPrice && innerTierPrice[2];
        const tier4Data = innerTierPrice && innerTierPrice[3];
        const tier5Data = innerTierPrice && innerTierPrice[4];
       

        TierPriceData.tier1Price = tier1Data ? tier1Data.price : '';
        TierPriceData.tier1BacksideCounts = TierPriceData.includeBacksideCounts === '1' ? 'Yes' : '';

        TierPriceData.tier2MinCount = tier2Data ? tier2Data.minimumCounts : '';
        TierPriceData.tier2Price = tier2Data ? tier2Data.price : '';
        TierPriceData.tier2BackToZero = tier2Data ? tier2Data.backToZero === '1' ? 'Yes' : '' : '';

        TierPriceData.tier3MinCount = tier3Data ? tier3Data.minimumCounts : '';
        TierPriceData.tier3Price = tier3Data ? tier3Data.price : '';
        TierPriceData.tier3BackToZero = tier3Data ? tier3Data.backToZero === '1' ? 'Yes' : '' : '';

        TierPriceData.tier4MinCount = tier4Data ? tier4Data.minimumCounts : '';
        TierPriceData.tier4Price = tier4Data ? tier4Data.price : '';
        TierPriceData.tier4BackToZero = tier4Data ? tier4Data.backToZero === '1' ? 'Yes' : '' : '';

        TierPriceData.tier5MinCount = tier5Data ? tier5Data.minimumCounts : '';
        TierPriceData.tier5Price = tier5Data ? tier5Data.price : '';
        TierPriceData.tier5BackToZero = tier5Data ? tier5Data.backToZero === '1' ? 'Yes' : '' : '';
        
        return TierPriceData;
      })

      setTierData(data);
    }
  }, [records])

  return (
    <>
      <div>
        <div className="marginLeft displayFlex">
          <StyledHeader>Finance Manager: Tier Pricing Info</StyledHeader>
          <div className="mainWrapper">
            <div className="displayFlex">
              <div style={{ marginRight: '10px' }}>
                <SelectDropdown
                  domID="defer_state_input"
                  options={[{ label: 'Active', value: 'active' }, { label: 'Inactive', value: 'inactive' }, { label: 'All', value: 'all' }]}
                  size="small"
                  initialValue={show}
                  onChange={(e) => onChangeShowFilter(e)}
                />
              </div>
              <Button
                domID="btn_reset"
                buttonType="standard"
                size="small"
                name="SHOW"
                onClick={() => refetchTierData()}
              />
            </div>
          </div>
        </div>
        {isLoadinggetTierPriceInfo ? (
          // replace with a better loading component            
          <div style={{ marginLeft: '50%' }}><LoadingComponent /></div>
        ) : isErrorgetTierPriceInfo ? (
          // replace with a better error component
          <ErrorPage
            // this prop isn't real but is an example
            error={errorgetTierPriceInfo}
          />
        ) : (
              <>
                <div className='GridWrapper'>
                  <Grid
                    columns={new Set([
                      {
                        dataName: 'active',
                        text: 'Is Active',
                        cellType: 'text',
                      },
                      {
                        dataName: 'billedClientNumber',
                        text: 'Billed Client Id',
                        cellType: 'text',
                      },
                      {
                        dataName: 'productItemDesc',
                        text: 'Product Item Description',
                        cellType: 'text',
                      },
                      {
                        dataName: 'tier1Price',
                        text: 'Tier 1 Price',
                        cellType: 'text',
                      },
                      {
                        dataName: 'tier1BacksideCounts',
                        text: 'Tier 1 Include Backside counts',
                        cellType: 'text',
                      },
                      {
                        dataName: 'tier2MinCount',
                        text: 'Tier 2 Minimum Counts',
                        cellType: 'text',
                      },
                      {
                        dataName: 'tier2Price',
                        text: 'Tier 2 Price',
                        cellType: 'text',
                      },
                      {
                        dataName: 'tier2BackToZero',
                        text: 'Tier 2 BackToZero',
                        cellType: 'text',
                      },
                      {
                        dataName: 'tier3MinCount',
                        text: 'Tier 3 Minimum Counts',
                        cellType: 'text',
                      },
                      {
                        dataName: 'tier3Price',
                        text: 'Tier 3 Price',
                        cellType: 'text',
                      },
                      {
                        dataName: 'tier3BackToZero',
                        text: 'Tier 3 BackToZero',
                        cellType: 'text',
                      },
                      {
                        dataName: 'tier4MinCount',
                        text: 'Tier 4 Minimum Counts',
                        cellType: 'text',
                      },
                      {
                        dataName: 'tier4Price',
                        text: 'Tier 4 Price',
                        cellType: 'text',
                      },
                      {
                        dataName: 'tier4BackToZero',
                        text: 'Tier 4 BackToZero',
                        cellType: 'text',
                      },
                      {
                        dataName: 'tier5MinCount',
                        text: 'Tier 5 Minimum Counts',
                        cellType: 'text',
                      },
                      {
                        dataName: 'tier5Price',
                        text: 'Tier 5 Price',
                        cellType: 'text',
                      },
                      {
                        dataName: 'tier5BackToZero',
                        text: 'Tier 5 BackToZero',
                        cellType: 'text',
                      },
                      {
                        dataName: 'SSPBillings',
                        text: 'Legacy/SSP Billing',
                        cellType: 'text',
                      },
                    ])}
                    records={tierData || []}
                    dataTestId="test-FinanceManagerGrid"
                    domID="FinanceManagerGrid"
                    isFixedHeader
                    maxHeight="450px"
                    onClickThrough={(e, item) => clickedSelectedItem(item)}
                    focusedItem={selectedRecord}
                    selectionKey="id"
                  />
                  <br />
                  <br />
                </div>
              </>
            )}
      </div>
    </>
  )
}

export default FinanceManagerTierPrice