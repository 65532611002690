import {
  SUPPORT_MENU_ITEMS,
  AVATAR_MENU_ITEMS,
  DEMO_USER,
  ON_AVATAR_MENU_SELECT,
  USER_SET,
  SET_SUPPORT_ITEMS,
  TOGGLE_AUTH_MODAL_OPEN,
} from './constants';

// The initial state of the App
const initialState = {
  // navigation
  currentNavMenuIndex: 0,
  supportMenuItems: SUPPORT_MENU_ITEMS,
  avatarMenuItems: AVATAR_MENU_ITEMS,
  user: DEMO_USER,
  authModalOpen: false,
  notifications: [],
  testSagaTimestamp: 0,
};

function AppReducer(state = initialState, action) {
  switch (action.type) {
    case ON_AVATAR_MENU_SELECT:
      return {
        ...state,
      };
    case USER_SET:
      return {
        ...state,
        user: action.state,
      };
    case TOGGLE_AUTH_MODAL_OPEN:
      return {
        ...state,
        authModalOpen: action.state,
      };
    case SET_SUPPORT_ITEMS:
      return {
        ...state,
        supportMenuItems: {
          shouldShow: true,
          showBottomAction: false,
          linkData: {
            staticContent: [
              {
                label: 'FAQ',
              },
            ]
          }
        }
      }
    default:
      return state;
  }
}

export default AppReducer;
