import { useEffect, useRef, useState } from 'react';
import { FIELDINDB} from '../../components/constants';

export default function useSortableGrid(fetchData,defaultSorDataName,defaultSortDescending, setRecords){

  const [sortConfig,setSortConfig]=useState({
    sortDataName:defaultSorDataName,
    sortDescending:defaultSortDescending
  })
  
  const getArgumentsAndSortGrid=(state) => {
    const { sortingKey,type } = state;
    const sortingKey$split = sortingKey.split('|');
    const dataName = sortingKey$split[0];
    const direction = sortingKey$split[1];
    
    if (dataName) {
      const sortDescendingFlag = `${FIELDINDB[direction]}`;
      setSortConfig(JSON.parse(JSON.stringify({
        sortDataName:dataName,
        sortDescending:sortDescendingFlag
      })))  
      fetchData({sortcolumn:dataName,sd:sortDescendingFlag, type}); 
    }
  }
  
  const makeComparator = (sortDirectionString) => {
    const sortDirection = (sortDirectionString === 'SORT_ASCENDING') ? 1 : -1;
    return (a, b) => {
      if (a > b) {
        return sortDirection;
      }
      if (a === b) {
        return 0;
      }
      return (sortDirection * -1);
    };
  };
  
  const comparisonSafeString1 = (object = '') => {
    let value = object;
    if (value) {
      value = new Date(value).getTime();
    }
    return value;
  };
  
  const comparisonSafeString2 = (object = '') => {
    let value = object;
    if (parseInt(value, 10) >= 0) {
      value = parseInt(value, 10);
    }
    return value;
  };
  
  const comparisonSafeString = (object = '') => {
    let value = object;
    if (value && value.props && value.props.text) {
      value = value.props.text;
    }
    else if (value && value.name && value.name.props && value.name.props.text) {
      value = value.name.props.text;
    }
    else if (value && value.name && value.name.props && value.name.props.tooltipContent) {
      value = value.name.props.tooltipContent;
    }
    else if (value && value.name) {
      value = value.name;
    }
    else if (typeof value === 'string') {
      return value ? value.trim().toLowerCase() : '';
    }
    else if(typeof value === 'number'){
      return value;
    }
    else if (value && value.props && value.props.tooltipContent) {
      value = value.props.tooltipContent;
    }
    else if (value && value.props && value.props.domID) {
      value = value.props.domID;
    }
    else if (value && value.props && value.props.label) {
      value = value.props.label;
    }
    else if (value && value.props && value.props.children) {
      if (value.props.children && value.props.children.props
        && Object.prototype.hasOwnProperty.call(value.props.children.props,'name')) {
        value = value.props.children.props.name;
      } else if (value.props.children
        && value.props.children[1]
        && value.props.children[1].props
        && value.props.children[1].props.id) {
        value = value.props.children[1].props.id;
        if(value && (typeof value)==='string' && value.split('@')[0]==='audit'){
          const valueAudit = value.split('@');
          [,,value] = valueAudit;
        }
      } else if(value.props.children.props.children
        && value.props.children.props.children.constructor.name==='Array'
        && value.props.children.props.children[2]){
        value=value.props.children.props.children[2];
        if(parseInt(value,10)){
          value=parseInt(value,10);
        }
      } else {
        value = value.props.children;
      }
  
    }
    else if (value && value.props && value.props.name) {
      value = value.props.name;
    }
    // else {
    //   value = '';
    // }
    if (typeof value === 'string') {
      return value.trim().toLowerCase();
    } if(typeof value === 'number'){
      return value;
    }
    return '';
  };
  
  const gridSorting = (state, records) => {
    const { sortingKey, type } = state;
    const sortingKey$split = sortingKey.split('|');
    const sortDataName = sortingKey$split[0];
    const sortDirectionString = sortingKey$split[1];

    if (!sortDataName || !sortDirectionString || !records) return records;

    const sortDescendingFlag = `${FIELDINDB[sortDirectionString]}`;
    setSortConfig(JSON.parse(JSON.stringify({
      sortDataName,
      sortDescending:sortDescendingFlag
    })))  
    if (type==="date")
    {
      const comparator = makeComparator(sortDirectionString);
      if(setRecords) setRecords(JSON.parse(JSON.stringify(records.sort((a, b) =>
        comparator(
          comparisonSafeString1(a[sortDataName]),
          comparisonSafeString1(b[sortDataName]),
        ),
      ))))
      return false;
    }
  
    const comparator = makeComparator(sortDirectionString);
    if (type==="text") {
      if(setRecords) setRecords(JSON.parse(JSON.stringify(records.sort((a, b) => 
        comparator(comparisonSafeString(a[sortDataName]), comparisonSafeString(b[sortDataName]))))))
      return false;  
    }
    if (type==="number") {
      if(setRecords) setRecords(JSON.parse(JSON.stringify(records.sort((a, b) => comparator(comparisonSafeString2(a[sortDataName])
        ,comparisonSafeString2(b[sortDataName]))))))
      return false;  
    }
    return null;
  };

  return [sortConfig, getArgumentsAndSortGrid, gridSorting];
}