// Import the action types we are going to respond to with this reducer
import {
  ALL_RULES_FOR_RULETYPES_REQUEST_SUCCESS,
  ALL_RULES_FOR_RULETYPES_REQUEST_ERROR,
  ALL_RULES_FOR_RULETYPES_ASSOCIATIONS_REQUEST_SUCCESS,
  ALL_RULES_FOR_RULETYPES_ASSOCIATIONS_REQUEST_ERROR,
  RULES_COUNT_FROM_DB_SUCCESS,
  RULES_COUNT_FROM_DB_ERROR,
  RESET_RULE_DETAILS_PANE,
  SET_IS_VIEWING_RULE_DETAILS,
  UPDATE_CURRENT_TAB,
  GROUP_ASSOCIATIONS_FOR_RULE_ID_SUCCESS,
  GROUP_ASSOCIATIONS_FOR_RULE_ID_ERROR,
  GROUP_ASSOCIATIONS_FOR_RULE_TYPE_SUCCESS,
  GROUP_ASSOCIATIONS_FOR_RULE_TYPE_ERROR,
  SET_IS_VIEWING_GROUP_ASSOCIATIONS_TYPE_MODAL,
  SET_SELECTED_RULE_TYPE,
  RESET_RULE_TYPE_RECORDS,
  RESET_STATE
} from './constants';

import gridStub from '../../../stubs/rulesArray';
import { SET_SELECTED_RULE_RECORD } from '../ruleTypesList/constants';
// Initial state expresses the data type of this key in the store (in this case
// store.rulesForRuleTypesList will be an Array). This object is exported separately to
// make unit testing easier
export const INITIAL_STATE = {
  records: [],
  ruleGridQuery: '',
  loadMore: false,
  offset: 0,
  count: 0,
  ruleTypeId: '',
  currentTab: 0,
  selectedRuleRecordId: null,
  isAdvancedViewShown: false,
  groupAssociationsForRuleId: [],
  isShowingGroupAssociationModal: false,
  associatedRuleType: null,
  selectedRuleType: null,
  associating: false,
};

// This is the actual "reducer". It is a function that will run every time an
// action is dispatched by the store, but only return updated state values if
// it matches the action's type in the switch statement.
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    
    case RULES_COUNT_FROM_DB_SUCCESS:
      return {
        ...state,
        count: action.payload.count,
      };
    case RULES_COUNT_FROM_DB_ERROR:
      return {
        ...state,
        count: 0,
      };
    case ALL_RULES_FOR_RULETYPES_REQUEST_SUCCESS:
      if (action.payload.append) {
        return {
          ...state,
          records: [...state.records, ...action.payload.rulesForRuleTypesList],
          loadMore: action.payload.loadMore,
          offset: action.payload.offset,
          ruleGridQuery: action.payload.queryValue,
          ruleTypeId: action.payload.ruleTypeId,
        };
      }
      return {
        ...state,
        records: [...action.payload.rulesForRuleTypesList],
        loadMore: action.payload.loadMore,
        offset: action.payload.offset,
        ruleGridQuery: action.payload.queryValue,
        ruleTypeId: action.payload.ruleTypeId,
      };
    case ALL_RULES_FOR_RULETYPES_REQUEST_ERROR:
      // If an error occured you can set a fallback state here (empty grid unless on localhost)
      if (window.location.origin === 'http://localhost:3000') {
        return { ...state, records: [...gridStub] };
      }
      return { ...state, records: [] };
    // 2nd API call must be merged into the firs call's result
    case ALL_RULES_FOR_RULETYPES_ASSOCIATIONS_REQUEST_SUCCESS: {
      return action.payload.groupFlow ? state : { ...state, records: [...action.payload] };
    }
    case ALL_RULES_FOR_RULETYPES_ASSOCIATIONS_REQUEST_ERROR:
      if (window.location.origin === 'http://localhost:3000') {
        return { ...state, records: [...gridStub] };
      }
      return { ...state };
    case RESET_RULE_DETAILS_PANE: {
      let { currentTab } = { ...state };
      if (state.selectedRuleRecordId !== action.payload.ruleId) currentTab = 0;
      return {
        ...state,
        selectedRuleRecordId: action.payload.ruleId,
        isAdvancedViewShown: true,
        currentTab,
      };
    }
    case SET_IS_VIEWING_RULE_DETAILS:
      return {
        ...state,
        selectedRuleRecordId: action.payload.selectedRuleRecordId,
        isAdvancedViewShown: action.payload.isAdvancedViewShown,
      };
    case SET_SELECTED_RULE_RECORD:
      return {
        ...state,
        selectedRuleRecordId: action.payload.id,
      };
    case UPDATE_CURRENT_TAB:
      return {
        ...state,
        currentTab: action.payload.currentTab,
      };
    case GROUP_ASSOCIATIONS_FOR_RULE_ID_SUCCESS:
      return {
        ...state,
        groupAssociationsForRuleId: action.payload.associations,
      };
    case GROUP_ASSOCIATIONS_FOR_RULE_ID_ERROR:
      return {
        ...state,
      };
    case GROUP_ASSOCIATIONS_FOR_RULE_TYPE_SUCCESS:
      return {
        ...state,
        // groupAssociationsForRuleId: action.payload.associations,
      };
    case GROUP_ASSOCIATIONS_FOR_RULE_TYPE_ERROR:
      return {
        ...state,
      };
    case SET_IS_VIEWING_GROUP_ASSOCIATIONS_TYPE_MODAL:
      return {
        ...state,
        isShowingGroupAssociationModal: action.payload.showing,
        associatedRuleType: action.payload.associatedRuleType,
        associating: action.payload.associating
      };
    case SET_SELECTED_RULE_TYPE:
      return {
        ...state,
        selectedRuleType: action.payload,
      };
    case RESET_RULE_TYPE_RECORDS:
      return {
        ...state,
        records: [],
      }
    case RESET_STATE:
      return {
        records: [],
        ruleGridQuery: '',
        loadMore: false,
        offset: 0,
        count: 0,
        ruleTypeId: '',
        currentTab: 0,
        selectedRuleRecordId: null,
        isAdvancedViewShown: false,
        groupAssociationsForRuleId: [],
        isShowingGroupAssociationModal: false,
        associatedRuleType: null,
        selectedRuleType: null,
        associating: false,
      }
    default:
      // Most of the actions in our app will be ignored by this reducer, so the
      // default case is to return the existing state
      return state;
  }
};
