/* eslint-disable no-else-return */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-expressions */
import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'ui-core-ssgr';
import { DropDown } from '../styles';

// eslint-disable-next-line react/prefer-stateless-function
class InputControl extends React.Component {
  render() {
    const {
      inputDomID,
      inputLabel,
      inputName,
      inputInitialValue,
      inputStartWithBlankValue,
      inputOnChangeHandle,
      inputDisabled,
      inputIsHovered,
      inputErrorMessage,
      inputHasError,
      inputStyles,
      inputOnBlurHandle,
      inputOnKeyDown,
      inputMask,
      inputMaxLength,
    } = this.props;
    return (
      <DropDown style={inputStyles}>
        <Input
          mask={inputMask || null}
          domID={inputDomID} 
          label={inputLabel}
          name={inputName}
          initialValue={inputInitialValue}
          startWithBlankValue={inputStartWithBlankValue}
          onChange={e => inputOnChangeHandle(e)}
          onBlur={e => inputOnBlurHandle(e)}
          onKeyDown={e => inputOnKeyDown(e)}
          disabled={inputDisabled}
          isHovered={inputIsHovered}
          errorMessage={inputErrorMessage}
          hasError={inputHasError}
          maxLength={inputMaxLength || null}
        />
      </DropDown>
    );
  }
}

InputControl.propTypes = {
  inputDomID: PropTypes.string,
  inputLabel: PropTypes.string,
  inputName: PropTypes.string,
  inputInitialValue: PropTypes.string,
  inputStartWithBlankValue: PropTypes.bool,
  inputOnChangeHandle: PropTypes.func,
  inputDisabled: PropTypes.bool,
  inputIsHovered: PropTypes.bool,
  inputErrorMessage: PropTypes.string,
  inputHasError: PropTypes.bool,
  inputStyles: PropTypes.object,
  inputOnBlurHandle: PropTypes.func,
  inputOnKeyDown: PropTypes.func,
  inputMask: PropTypes.string,
  inputMaxLength: PropTypes.number
};
export default InputControl;
