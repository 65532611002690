/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { useSelector, useDispatch} from 'react-redux';
import { useLayout, DetailPaneHeader } from 'ui-core/dist';
import { detailPaneAnimStates } from '../../commonResources/constants/animStates';
import { resetFileKey } from './Modules/Action';
import FileReceiptManagerDetailComponent from './FileReceiptManagerDetailComponent';

const FileReceiptManagerDetail = () => {
  const {
    expandCollapseDetailPaneButtonConfig,
    closeDetailPaneButtonConfig,
    detailAnimationState,
  } = useLayout();
  const dispatch=useDispatch()

  const { filekey } 
  = useSelector(({ fileReceipt }) => fileReceipt);
  
  useEffect(()=>{
    if (detailAnimationState===detailPaneAnimStates.COLLAPSED) {
      dispatch(resetFileKey())
    }
  },[detailAnimationState])

  return (
    <>
      <DetailPaneHeader 
        title="File Receipt Details"
        layoutControls={[
          expandCollapseDetailPaneButtonConfig,
          closeDetailPaneButtonConfig
        ]}
      />
      <FileReceiptManagerDetailComponent
        filekey={filekey}
        overflow="scroll"
      />
    </>
  );
};

FileReceiptManagerDetail.propTypes = {

};

export default FileReceiptManagerDetail;
