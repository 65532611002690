/* eslint-disable no-else-return */
/* eslint-disable no-lonely-if */
import React,{useState,useMemo, useCallback, useEffect} from 'react';
import styled from 'styled-components/dist/styled-components';
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
import { useHistory } from 'react-router-dom'
import { useDispatch} from 'react-redux';
import { push } from 'connected-react-router';
// import moment, { calendarFormat } from 'moment';
import { Modal, ModalHeader, Grid, ModalBody
} from 'ui-core/dist';
import { AnchorInput, GridPaginationWrapper} from '../../components';
import ErrorPage from '../ErrorPage';
import LoadingComponent from '../../components/LoadingComponent';
import { useGetACHFileDetailsByKey,
  useGetACHFileDetailsByFileID } from '../../api/useDocumentManager';
import {setDocumentSearchParams } from './Modules/action';
import {DOCUMENT_SEARCH_RESULTS_PAGE_ACH_BATCH_DETAILS, DOCUMENT_SEARCH_RESULTS_PAGE_ACH_FILE_DETAILS,
  DOCUMENT_SEARCH_RESULTS_PAGE_ACH_TRANSACTION_DETAILS} from './Modules/constants';
import ModalWrapper from '../../components/Modal/components/ModalWrapper';
import useNotificationBannerHook from '../../useNotificationBannerHook';
import useSortableGrid from '../../hooks/useSortableGrid';
import {
  setSelected,
} from '../SideNav/modules/actions';

const StyledModalWrapperWidth=styled(ModalWrapper)`
& .active-content {
  width: 90% !important;
  overflow:auto;
  & span {
    font-size: 11px !important;
  }
}
`
const StyledModalWrapper=styled.div`
height: 80vh;
`
const StyledModalHeaderWrapper=styled.div`
& div {
  padding:1px 9px !important;
  padding-bottom: 1px !important;
}
`
const StyledLableWrapperACHModal=styled.div`
margin-right: 10px;
max-height: 68vh;
overflow: auto;
& table.labelWrapper {
  width: 100% !important;
  & tr {
    background: rgb(15, 15, 89);
    & td {
      width: 50% !important;
      & div.displayFlex {
        padding-top: 3px;
        padding-left: 10px;
        & label {
          color: white !important;
        }
      }
    }
  }
}
`
const ACHTransmissionHistoryFileDetails=({mode, getFetchDetailsFunc})=>{
  
  const history = useHistory();
  const dispatch = useDispatch()

  const DEFAULT_ACH_ERR_MESSAGE="Some error occurred while fetching ACH File Details";
  const DEFAULT_ROWS_PER_PAGE=10;
  const [rowsPerPage, setRowsPerPage]=useState(DEFAULT_ROWS_PER_PAGE)
  const [pageIndex, setPageIndex]=useState(0);
  const [achDetailsConfig, setAchDetailsConfig]=useState(null);
  const [isOpenACHModal, setisOpenACHModal]=useState(false);

  const {notify}=useNotificationBannerHook();

  const {data: achFileDetailsData, error: errorachFileDetailsData, isError: isErrorachFileDetailsData, 
    isLoading: isLoadingachFileDetailsData, refetchACHFileDetailsByKey, getACHFileDetailsByKey}=useGetACHFileDetailsByKey(setAchDetailsConfig)
  const {data: achFdData, error: errorachFdData, isError: isErrorachFdData, isLoading: isLoadingachFdData, 
    refetchACHFileDetailsByFileID, getACHFileDetailsByFileID}=useGetACHFileDetailsByFileID(setAchDetailsConfig)

  const checkConfigDetailsOnCall=(config)=>{
    if (mode==="batchList") {
      return config.documentKey && config.documentKey.constructor.name==="String"?true:false
    } else if(mode==="fileDetails"){
      return config.fileId && config.fileId.constructor.name==="String" 
      && config.batchId && config.batchId.constructor.name==="String"?true:false
    }
    return null;
  }

  const fetchDetails=(config)=>{
    setRowsPerPage(DEFAULT_ROWS_PER_PAGE)
    setPageIndex(0)
    let valid=true;
    if (JSON.stringify(config)!==JSON.stringify(achDetailsConfig)) {
      if (checkConfigDetailsOnCall(config)) {
        if (mode==="batchList") {
          getACHFileDetailsByKey({payload:{
            "maxRecords":DEFAULT_ROWS_PER_PAGE,
            "rowPosition":0,
            "sortField":sortConfig.sortDataName,
            "sortDescending":sortConfig.sortDescending,
            parameters: config.documentKey
          },config:JSON.parse(JSON.stringify(config))});
        } else if(mode==="fileDetails"){
          getACHFileDetailsByFileID({payload:{
            "maxRecords":DEFAULT_ROWS_PER_PAGE,
            "rowPosition":0,
            "sortField":sortConfig.sortDataName,
            "sortDescending":sortConfig.sortDescending,
            parameters: config.fileId,
            batchId: config.batchId
          },config:JSON.parse(JSON.stringify(config))})
        } 
      } else{
        valid=false;
        notify('Invalid arguments', 'negative');
      }
    } else{
      if (mode==="batchList") {
        refetchACHFileDetailsByKey()      
      } else if(mode==="fileDetails"){
        refetchACHFileDetailsByFileID()
      }
    }
    if(!isOpenACHModal && valid) setisOpenACHModal(true);
  }
  useEffect(()=>{
    if (getFetchDetailsFunc) {
      getFetchDetailsFunc(fetchDetails)
    }
  },[])
  const fetchData=useCallback((args)=>{
    let sortcolumn; let sd; let pi; let type;
    if (args) {
      ({sortcolumn,sd,pi, type}=args);
    }
    if (mode==="batchList") {
      getACHFileDetailsByKey({payload:{
        "maxRecords":rowsPerPage,
        "rowPosition":pi===undefined || pi===null?pageIndex: pi,
        "sortField":sortcolumn===undefined || sortcolumn===null?sortConfig.sortDataName: sortcolumn,
        "sortDescending":sd===undefined || sd===null?sortConfig.sortDescending: sd,
        type,
        parameters: achDetailsConfig.documentKey
      }})    
    } else if(mode==="fileDetails"){
      getACHFileDetailsByFileID({payload:{
        "maxRecords":rowsPerPage,
        "rowPosition":pi===undefined || pi===null?pageIndex: pi,
        "sortField":sortcolumn===undefined || sortcolumn===null?sortConfig.sortDataName: sortcolumn,
        "sortDescending":sd===undefined || sd===null?sortConfig.sortDescending: sd,
        type,
        parameters: achDetailsConfig.fileId,
        batchId: achDetailsConfig.batchId
      }})
    }
  },[rowsPerPage,pageIndex,sortConfig, achDetailsConfig, mode]);
  const [sortConfig, getArgumentsAndSortGrid]=useSortableGrid(fetchData,"key","true");

  useEffect(()=>{
    if (isErrorachFileDetailsData) {
      notify(errorachFileDetailsData||DEFAULT_ACH_ERR_MESSAGE,'negative')
    }
    if (errorachFdData) {
      notify(errorachFdData||DEFAULT_ACH_ERR_MESSAGE,'negative')
    }
  },[errorachFileDetailsData, errorachFdData])

  const onCloseACHModal=()=>{
    setisOpenACHModal(false);
  }
  const renderACHModal=useMemo(()=>{
    let data;
    if (mode==="batchList") {
      data=achFileDetailsData
    } else if(mode==="fileDetails"){
      data=achFdData;
    }
    if (data && isOpenACHModal) {
      const {batchData, totalRecords}=data;
      const {file}=batchData;
      const {batch}=file;
      const {transactions}=batch;
      let tx=transactions;
      const TRANSACTION_GRID_PROPS=DOCUMENT_SEARCH_RESULTS_PAGE_ACH_TRANSACTION_DETAILS;
      if (mode==="fileDetails" && transactions && transactions.length>0) {
        tx=transactions.map((t, index)=>({
          ...t,
          documentKey_display: <AnchorInput
            id={`ACH_transaction${index}_${t.key}`}
            type='button'
            className='fileKeyLink'
            onClick={(e)=>{
              e.preventDefault();
              e.stopPropagation();
              const payload = {
                groupId: "",
                recipientType: "",
                idNumber: "",
                tableName: '',
                insuredPolicyNumber: "",
                insuredState: "",
                patientId: "",
                processorId: "",
                providerStateCode: "",
                documentType: "",
                filekey: '',
                documentKey: t.key,
                voucherNumber: "",
                formNumber: '',
                claimTypeId: '',
                checkNumber: "",
                claimNumber: "",
                domXml: "",
                benefitYear: "",
                showDuplicateChecks: true,
                showProcessedDocuments: true,
              };
              onCloseACHModal()
              dispatch(setDocumentSearchParams(payload))
              // dispatch(push('/DocumentManager/SearchResults'))
              dispatch(setSelected(['0|5','Search']))
              history.push('/DocumentManager/SearchResults')
            }}
          >{t.key}</AnchorInput>
        }));
        const col=Array.from(TRANSACTION_GRID_PROPS.columns).find(x=>x.text==="DOCUMENT KEY");
        if (col) {
          col.dataName="documentKey_display"
        }
      } else if(mode==='batchList'){
        const col=Array.from(TRANSACTION_GRID_PROPS.columns).find(x=>x.text==="DOCUMENT KEY");
        if (col) {
          col.dataName="key"
        }
      }
      return (
        <Modal isOpen={isOpenACHModal} wrapperComponent={StyledModalWrapperWidth}>
          <StyledModalWrapper>
            <StyledModalHeaderWrapper>
              <ModalHeader
                title="ACH File Transmission History - File Details"
                onClose={() => onCloseACHModal()}
              />
            </StyledModalHeaderWrapper>
            <ModalBody>
              {isOpenACHModal?(
                <div className="marginLeft">
                  <StyledLableWrapperACHModal>
                    {isLoadingachFdData || isLoadingachFileDetailsData?<div style={{marginLeft: '50%'}}><LoadingComponent /></div>:
                      <>
                        {isErrorachFdData || isErrorachFileDetailsData ? (
                        // replace with a better error component
                          <ErrorPage
                            // this prop isn't real but is an example
                            error={errorachFdData || errorachFileDetailsData}
                          />
                        ): (
                          <>
                            <table className="labelWrapper">
                              <tr>
                                <td>
                                  <div className="displayFlex">
                                    <label>File ID: </label>
                                    <label style={{marginLeft:'10px'}}>{file.id}</label>
                                  </div>
                                </td>
                                <td>
                                  <div className="displayFlex">
                                    <label>Transmission Status: </label>
                                    <label style={{marginLeft:'10px'}}>{file.status}</label>
                                  </div>
                                </td>
                              </tr>
                            </table>
                            <br/>
                            <div className="displayFlex">
                              <label>File Information</label>
                            </div>
                            <Grid
                              {...DOCUMENT_SEARCH_RESULTS_PAGE_ACH_FILE_DETAILS}
                              records={file?[file] || []:[]}
                              onClickThrough={()=>false}
                            />
                            <br/>
                            <div className="displayFlex">
                              <label>Batch Information</label>
                            </div>
                            <Grid
                              {...DOCUMENT_SEARCH_RESULTS_PAGE_ACH_BATCH_DETAILS}
                              records={batch?[batch] || []:[]}
                              onClickThrough={()=>false}
                            />
                            <br/>
                            <GridPaginationWrapper
                              rowsPerPage={rowsPerPage}
                              onChangeRowsPerPage={setRowsPerPage}
                              pageIndex={pageIndex}
                              onChangePageIndex={setPageIndex}
                              onShowButtonCLick={fetchData}
                              totalRecords={totalRecords}
                              defaultRowsPerPage={DEFAULT_ROWS_PER_PAGE}
                            />
                            <div className="displayFlex">
                              <label>Transaction Information</label>
                            </div>
                            <div>
                            <Grid
                              {...TRANSACTION_GRID_PROPS}
                              records={tx?tx || []:[]}
                              onClickThrough={()=>false}
                              initialSortingKey={`${sortConfig.sortDataName}|${sortConfig.sortDescending==="true"?'SORT_DESCENDING':'SORT_ASCENDING'}`}
                              onSortGridColumn={(e,state)=>getArgumentsAndSortGrid(state)}
                            />
                            </div>
                          </>
                        )}
                      </>
                    }
                  </StyledLableWrapperACHModal>
                </div>
              ):null}
            </ModalBody>
          </StyledModalWrapper>
          
        </Modal>
      )
    }
    return null;
  },[mode, rowsPerPage,pageIndex, achDetailsConfig,isOpenACHModal,
    achFileDetailsData, errorachFileDetailsData, isErrorachFileDetailsData,
    isLoadingachFileDetailsData,
    achFdData,  errorachFdData,isErrorachFdData, 
    isLoadingachFdData])

  return (
    <>
      {renderACHModal}
    </>
  )
}

export default ACHTransmissionHistoryFileDetails;