export const SELECT_RECORD_OPTIONS = [
  {label:'1', value:'1'},
  {label:'3', value:'3'},
  {label:'5', value:'5'},
  {label:'10', value:'10'},
  {label:'25', value:'25'},
  {label:'50', value:'50'},
];


export const FIELDINDB = {
  'SORT_DESCENDING': true,
  'SORT_ASCENDING': false
};