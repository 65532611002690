/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-lonely-if */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/dist/styled-components'
import moment from 'moment';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { Grid, Input, 
  DatePickerV2,
  Button,
  useLayout, 
  ListPaneHeader,
  CircleCheck,
  Circle100,
  Chart,
  Modal, ModalHeader, LoadingIndicator, ModalBody
} from 'ui-core/dist'
import { LoadingPage } from '../../../components';
import ErrorPage from '../../ErrorPage';
import ListPaneHeaderWrapper from '../../../components/ErrorDisplaySection/ListPaneHeaderWrapper';
import {useGetNCOAAssosiatePAFBrokersTable, useGetNCOAPAFBrokersTable, useUpdatePAFBrokersAssociations} from '../../../api/useClientNCOAPAFAssosiateBrokers'
import {CLIENT_NCOA_PAF_GRID, CLIENT_NCOA_BROKERS_GRID} from '../Modules/constants'
import useSortableGrid from '../../../hooks/useSortableGrid'
import useSelectableGrid from '../../../hooks/useSelectableGrid'
import { floatToDollarString } from '../../../commonResources/helpers/money';
import { isA11yClick } from '../../../commonResources/helpers/domEvents';
import { mapIterable } from '../../../commonResources/helpers/iterables';
import useNotificationBannerHook from '../../../useNotificationBannerHook';

const StyledGridWrapper=styled.div`

// margin-top: -22px !important;

&.GridWrapper #grid-wrapper-clientNCOAPAFBrokersGrid {
  overflow:auto !important;
  min-height: 150px !important;
  max-height: 350px !important;

  & thead tr > th:first-child {
    display: none !important;
  }  
  & thead tr > th:nth-child(2) {
    width: 40px !important;
  }  
}
&.GridWrapper #grid-wrapper-clientNCOABrokersGrid{
  overflow:auto !important;
  height: 500px !important;

  & tbody {
    tr:nth-child(even) {
      background-color: #F4F6F9;
    }
    tr td.cSignerTitleControl, tr td.cSignerNameControl, tr td.cSignerDateControl {
      & div.cell-component-wrapper div.no-margin {
        padding: 2px 0 !important;
      }
    }
  }
}
`
const StyleButton=styled(Button)`
height: 18px !important;
width: 53px !important;
background: transparent;
box-shadow: none;
&:hover {
  background: transparent;
box-shadow: none;
}
`
const StyledLoadingContainer=styled.div`
& > div {
  width: 30px !important;
  height: 30px !important;
  & > svg {
    width: 30px !important;
    height: 30px !important;
  }
}
`
const onKeyPress = (e, callback) => {
  if (isA11yClick(e)) {
    e.preventDefault();
    callback(e);
  }
};
export const ClickableRow = (props) => {
  const classes = [];
  if (props.record.emboldened) classes.push('emboldened');
  if (props.isFocused) classes.push('focused');
  return (
    <tr
      onClick={(e) => { props.onClickThrough(e, props.record); }}
      onKeyPress={e => onKeyPress(e, props.onClickThrough)}
      role="button"
      tabIndex="0"
      className={classes.join(' ')}
      style={{background:props.record.selected ? 'orange': ''}}
    >
      {
        mapIterable(props.columns.values(), (c) => (!props.disabledColumns.has(c.dataName)
          ? (
            <td
              style={{padding: '10px 8px'}}
              key={c.dataName}
              className={c.dataName}
            >
              {c.isCurrency
                ? floatToDollarString(props.record[c.dataName])
                : props.record[c.dataName]}
            </td>
          )
          : null))
      }
    </tr>
  );
};

const AssosiateBrokers=()=>{
  const {
    expandCollapseListPaneButtonConfig,
  } = useLayout()
  const { faultstring } = useSelector(({ errormessages }) => errormessages);
  const {notify}=useNotificationBannerHook();
  const [currentlyAssosiatedPAFs, setcurrentlyAssosiatedPAFs]=useState([]);
  const [count, setcount]=useState(0)
  const [validationMessage, setvalidationMessage]=useState('')
  const [validationloading, setvalidationloading]=useState(false);
  const [isOpenConfirmationModal, setisOpenConfirmationModal]=useState(false)
  const VALIDATION_PREFIX="&nbsp;&nbsp;&nbsp;&nbsp;"

  const {
    isLoading: isLoadinggetAssosicateBrokersTable, 
    isError:isErrorgetAssosicateBrokersTable, 
    error:errorgetAssosicateBrokersTable, 
    postData: getAssosicateBrokersTable, 
    NCOA_PAF, NCOA_PAFCount, setNCOA_PAF}=useGetNCOAAssosiatePAFBrokersTable();

  const [NCOA_BROKER, setNCOA_BROKER]=useState([])
  const [NCOA_BROKERCount, setNCOA_BROKERCount]=useState(0)
  const {
    isLoading: isLoadinggetBrokers, 
    isError: isErrorgetBrokers, 
    error: errorgetBrokers, 
    postData: getBrokersTable,
    NCOA_PAF_BROKER, NCOA_PAF_BROKERCount}=useGetNCOAPAFBrokersTable();

  const [NCOA_PAF_BROKER_LOCAL, setNCOA_PAF_BROKER_LOCAL]=useState([])

  const {postData: updatePAFBrokersAssociations, 
    isLoading: isLoadingupdatePAFBrokersAssociations,
    isError: isErrorupdatePAFBrokersAssociations,
    error: errorupdatePAFBrokersAssociations, 
    data: updatePAFBrokersAssociationsResp}=useUpdatePAFBrokersAssociations();

  const [sortConfigAssosiateBrokers,_r, gridSortingAssosiateBrokers]=useSortableGrid(()=>false,"cPAFID","false", setNCOA_PAF);
  
  const {onRowSelect: onRowSelectBrokers,selectedItemIdsCurrentPage: selectedItemIdsCurrentPageBrokers, onSetSelectedItemIdsExplicitly, disabledItemIds}=
    useSelectableGrid({uniqueKey:'cBrokerID',list:NCOA_PAF_BROKER_LOCAL,isUicl:true})
  const [sortConfigBrokers,_c, gridSortingBrokers]=useSortableGrid(()=>false,"cBrokerID","false", setNCOA_PAF_BROKER_LOCAL);

  const getData=()=>{
    getAssosicateBrokersTable({count})
    getBrokersTable({count})  
    setcount(count+1)
  }
  useEffect(()=>{
    getData()
  },[])

  useEffect(()=>{
    if (NCOA_PAF_BROKER && NCOA_PAF_BROKERCount>0) {
      const t=JSON.parse(JSON.stringify(NCOA_PAF_BROKER))
      setNCOA_PAF_BROKER_LOCAL(t)      
    }
  },[NCOA_PAF_BROKER, NCOA_PAF_BROKERCount])

  useEffect(()=>{
    if (NCOA_BROKER && NCOA_BROKER.length>0) {
      const temp=JSON.parse(JSON.stringify(NCOA_PAF_BROKER_LOCAL));
      NCOA_BROKER.forEach((item)=>{
        const {cBrokerID, iSequence}=item.$;
        const {cSignerTitle, cSignerName, dSignDate}=item;
        const f=temp.find(x=>x.$.cBrokerID===cBrokerID);
        if (f) {
          f.isSelected=true;
          f.sequence=iSequence;
          f.isDisabled=true;
          f.cSignerTitle=cSignerTitle;
          f.cSignerName=cSignerName;
          f.cSignerDate=dSignDate? moment(dSignDate).format('MM/DD/YYYY'):'';
        }
      })
      setNCOA_PAF_BROKER_LOCAL(JSON.parse(JSON.stringify(temp)))
      if(onSetSelectedItemIdsExplicitly) onSetSelectedItemIdsExplicitly(JSON.parse(JSON.stringify(NCOA_BROKER.map((item)=>item.$.cBrokerID))))
    }
  },[NCOA_BROKER])
  const inFuture = (date) => date > new Date();
  const validateSubmit=useMemo(()=>{
    setvalidationloading(true);
    if (currentlyAssosiatedPAFs.length===0) {
      setvalidationMessage(`<label>You have not selected a PAF for modification.</label>${VALIDATION_PREFIX}`)
      setvalidationloading(false);
      return false;
    }
    setvalidationMessage('')
    try {
      const existingBrokers=(NCOA_BROKER||[]).map((item)=>{
        const {cBrokerID, iSequence}=item.$;
        const {cSignerTitle, cSignerName, dSignDate}=item;
        return {
          cBrokerID,
          sequence: (iSequence||"").toString(),
          cSignerTitle,
          cSignerName,
          cSignerDate: dSignDate? moment(dSignDate).format('MM/DD/YYYY'):''
        }
      })
      const currentBrokerIds=JSON.parse(JSON.stringify(Array.from(selectedItemIdsCurrentPageBrokers)))
      const currentBrokers=[];
      currentBrokerIds.forEach((cBrokerID)=>{
        const f=(NCOA_PAF_BROKER_LOCAL||[]).find(x=>x.$.cBrokerID===cBrokerID);
        if (f) {
          currentBrokers.push({
            cBrokerID,
            sequence:(f.sequence||'').toString(),
            cSignerTitle:f.cSignerTitle,
            cSignerName: f.cSignerName,
            cSignerDate: f.cSignerDate? moment(f.cSignerDate).format('MM/DD/YYYY'):''
          })
        }
      })
      if (JSON.stringify(existingBrokers)!==JSON.stringify(currentBrokers)) {
        const re2excludeChars = /[\\&>\\|]/;
        let bValid;
        if (currentBrokers.length===0) {
          bValid=true;
        }
        for (const item of currentBrokers) {
          const {cSignerTitle, cSignerName, cSignerDate, cBrokerID}=item;
          const cSignerTitlep=cSignerTitle||'';
          const cSignerNamep=cSignerName||'';
          const cSignerDatep=cSignerDate||'';
  
          if (cSignerTitlep!=="" && !re2excludeChars.test(cSignerTitlep)) {
            bValid=true;
          } else{
            // notify with error message
            bValid=false;
            setvalidationMessage(`<label>You have incorrect or missing data in Signer Title field.${VALIDATION_PREFIX}BrokerID: ${cBrokerID}</label>${VALIDATION_PREFIX}`)
            break;
          }
          if (cSignerNamep!=="" && !re2excludeChars.test(cSignerNamep)) {
            bValid=true;
          } else{
            bValid=false;
            setvalidationMessage(`<label>You have incorrect or missing data in Signer Name field.${VALIDATION_PREFIX}BrokerID: ${cBrokerID}</label>${VALIDATION_PREFIX}`)
            break;
          }
          if (cSignerDatep!=="" && !re2excludeChars.test(cSignerDatep)) {
            if (!inFuture(moment(cSignerDatep))) {
              bValid=true;
            } else{
              bValid=false;
              setvalidationMessage(`<label>You cannot have future date in Signer Date field.${VALIDATION_PREFIX}BrokerID: ${cBrokerID}</label>${VALIDATION_PREFIX}`)
              break;
            }
          } else{
            bValid=false;
            setvalidationMessage(`<label>You have incorrect or missing data in Signer Date field.${VALIDATION_PREFIX}BrokerID: ${cBrokerID}</label>${VALIDATION_PREFIX}`)
            break;
          }
        }
        if (bValid) {
          setvalidationMessage('')  
        }
        setvalidationloading(false);
        return bValid;
      }
    } catch (error) {
      setvalidationMessage(`<label>You have incorrect or missing data in Signer Date field.${VALIDATION_PREFIX}</label>${VALIDATION_PREFIX}`)
      setvalidationloading(false)
      return false;
    }
    setvalidationMessage(`<label>You have not made any changes.</label>${VALIDATION_PREFIX}`);
    setvalidationloading(false)
    return false;
    
  },[NCOA_BROKER, NCOA_PAF_BROKER_LOCAL, selectedItemIdsCurrentPageBrokers, currentlyAssosiatedPAFs])

  const onClearcurrentlyAssosiatedPAFs=()=>{
    setNCOA_PAF_BROKER_LOCAL(JSON.parse(JSON.stringify(NCOA_PAF_BROKER)))
    if(onSetSelectedItemIdsExplicitly) onSetSelectedItemIdsExplicitly(JSON.parse(JSON.stringify([])))
  }
  const onNewcurrentlyAssosiatedPAFs=()=>{
    setcurrentlyAssosiatedPAFs(JSON.parse(JSON.stringify([])))
    setNCOA_BROKER(JSON.parse(JSON.stringify([])))
    setNCOA_BROKERCount(0)
    onClearcurrentlyAssosiatedPAFs()
  }
  useEffect(()=>{
    if (updatePAFBrokersAssociationsResp) {
     
      const {fs}=updatePAFBrokersAssociationsResp;
      if (!fs) {
        notify('PAF Brokers Assosiation updated successfully', 'positive');
        onNewcurrentlyAssosiatedPAFs();
        getData();
      } else{
        notify('Error occurred during updation of PAF Brokers assosiation', 'negative')
      }
    }
  },[updatePAFBrokersAssociationsResp])

  const onSavecurrentlyAssosiatedPAFs=useCallback(()=>{
    if (currentlyAssosiatedPAFs.length>0) {
      setisOpenConfirmationModal(true);
    }
  },[isOpenConfirmationModal, currentlyAssosiatedPAFs])

  const onConfirmationYesClick=useCallback(()=>{
    if (currentlyAssosiatedPAFs.length>0) {
      const existingBrokers=(NCOA_BROKER||[]).map((item)=>{
        const {cBrokerID, iSequence}=item.$;
        const {cSignerTitle, cSignerName, dSignDate}=item;
        return {
          cBrokerID,
          sequence: (iSequence||"").toString(),
          cSignerTitle,
          cSignerName,
          cSignerDate: dSignDate? moment(dSignDate).format('MM/DD/YYYY'):''
        }
      })
      const currentBrokerIds=JSON.parse(JSON.stringify(Array.from(selectedItemIdsCurrentPageBrokers)))
      const currentBrokers=[];
      currentBrokerIds.forEach((cBrokerID)=>{
        const f=(NCOA_PAF_BROKER_LOCAL||[]).find(x=>x.$.cBrokerID===cBrokerID);
        if (f) {
          currentBrokers.push({
            cBrokerID,
            sequence:(f.sequence||"").toString(),
            cSignerTitle:f.cSignerTitle,
            cSignerName: f.cSignerName,
            cSignerDate: f.cSignerDate? moment(f.cSignerDate).format('MM/DD/YYYY'):''
          })
        }
      })
      if (JSON.stringify(existingBrokers)!==JSON.stringify(currentBrokers)) {
        const strPAFID=currentlyAssosiatedPAFs[0];
        const strBrokerIDsOrig=existingBrokers.map((item)=>`${item.cBrokerID}~~${item.sequence}~~${item.cSignerTitle}~~${item.cSignerName}~~${item.cSignerDate}|`)
        const strBrokerIDsMods=currentBrokers.map((item)=>`${item.cBrokerID}~~${item.sequence}~~${item.cSignerTitle}~~${item.cSignerName}~~${item.cSignerDate}|`)
        updatePAFBrokersAssociations({strPAFID, strBrokerIDsOrig, strBrokerIDsMods})
        setcount(count+1)
        setisOpenConfirmationModal(false)
      }
    }
    
  },[currentlyAssosiatedPAFs, NCOA_BROKER, NCOA_PAF_BROKER_LOCAL, selectedItemIdsCurrentPageBrokers,
    isOpenConfirmationModal])

  const renderPAFHTMLTable=useMemo(()=>{
    if (NCOA_PAF && NCOA_PAFCount>0 && NCOA_BROKER) {
      const temp=JSON.parse(JSON.stringify(NCOA_PAF))
      const data=temp.map((t, index)=>{
        const {cPAFID, bCanEdit, bActive} = t.$;
        const {NCOA_BROKER: NCOA_BROKERp}=t;
        const {cListOwnerPhone}=t;        
        const f1=currentlyAssosiatedPAFs.filter(x=>x===cPAFID).length
        let ifBrokerExists=null
        if (NCOA_BROKERp) {
          ifBrokerExists=(
            <>
              <StyleButton data-tip data-for={`reporttooltip_${index}_${cPAFID}_ifBrokerExists`}
                onClick={()=>false}>
                <CircleCheck
                  className="settings"
                  fillColor="#37474F"
                />
              </StyleButton>
              <ReactTooltip id={`reporttooltip_${index}_${cPAFID}`} type='dark' effect='solid'>
                <span>Has Associated Broker(s)</span>
              </ReactTooltip>
            </>
          )
        }
        return {
          ...t,
          ifBrokerExists,
          bCanEdit,
          bActive,
          bCanEditDisplay: bCanEdit && bCanEdit==="0" ?(
            <>
              <StyleButton data-tip data-for={`reporttooltip_${index}_${cPAFID}_bCanEdit`}
                onClick={()=>false}>
                <Chart
                  className="settings"
                  fillColor="#37474F"
                />
              </StyleButton>
              <ReactTooltip id={`reporttooltip_${index}_${cPAFID}_bCanEdit`} type='dark' effect='solid'>
                <span>This PAF has been accessed and can no longer be deleted.</span>
              </ReactTooltip>
            </>
          ):null,
          bActiveDisplay: bActive && bActive==="1" ?(
            <>
              <StyleButton data-tip data-for={`reporttooltip_${index}_${cPAFID}_bisActive`}
                onClick={()=>false}>
                <Circle100
                  className="settings"
                  fillColor="#00FF00"
                />
              </StyleButton>
              <ReactTooltip id={`reporttooltip_${index}_${cPAFID}_bisActive`} type='dark' effect='solid'>
                <span>Active PAF</span>
              </ReactTooltip>
            </>
          ):null,
          cPAFID,
          cListOwnerPhoneDisplay: (<span>({cListOwnerPhone.substr(0,3)}) {cListOwnerPhone.substr(3,3)}-{cListOwnerPhone.substr(6,4)}</span>),
          selected:!!f1
        }
      })
      return (
        <>
          <StyledGridWrapper className='GridWrapper'>
            <Grid
              {...CLIENT_NCOA_PAF_GRID}
              records={data || []}
              rowComponent={ClickableRow}
              onClickThrough={(e,rec)=>{
                e.preventDefault();
                e.stopPropagation();
                if (rec) {                  
                  // ...currentlyAssosiatedPAFs
                  setcurrentlyAssosiatedPAFs(JSON.parse(JSON.stringify([rec.cPAFID||''])))
                  onClearcurrentlyAssosiatedPAFs()
                  const {NCOA_BROKER: NCOA_BROKERp}=rec;
                  if (NCOA_BROKERp) {
                    let NCOA_BROKER_Result=[]
          
                    if (NCOA_BROKERp.constructor.name==="Object") {
                      NCOA_BROKER_Result.push({...NCOA_BROKERp})
                    } else if(NCOA_BROKERp.constructor.name==="Array"){
                      NCOA_BROKER_Result=[...NCOA_BROKERp]
                    }
                    setNCOA_BROKER(JSON.parse(JSON.stringify(NCOA_BROKER_Result)))
                    setNCOA_BROKERCount(NCOA_BROKER_Result.length)
                  } else{
                    setNCOA_BROKER(JSON.parse(JSON.stringify([])))
                    setNCOA_BROKERCount(0)
                  }
                  return;
                }
                setNCOA_BROKER(JSON.parse(JSON.stringify([])))
                setNCOA_BROKERCount(0)                
              }}
              initialSortingKey={`${sortConfigAssosiateBrokers.sortDataName}|${sortConfigAssosiateBrokers.sortDescending==="true"?'SORT_DESCENDING':'SORT_ASCENDING'}`}
              onSortGridColumn={(e,state)=>{
                const { sortingKey } = state;
                const sortingKey$split = sortingKey.split('|');
                const dataName = sortingKey$split[0];
                const f=Array.from(CLIENT_NCOA_PAF_GRID.columns).find(x=>x.dataName===dataName);
                if (f) {
                  const {cellType}=f;
                  if(gridSortingAssosiateBrokers) gridSortingAssosiateBrokers({sortingKey, type:cellType}, temp)
                }
              }}
            />
          </StyledGridWrapper>
        </>
      )
    }
    return null;
  },[
    NCOA_PAF,
    NCOA_BROKER,
    NCOA_PAFCount,
    NCOA_BROKERCount,
    NCOA_PAF_BROKER_LOCAL,
    sortConfigAssosiateBrokers,
    currentlyAssosiatedPAFs
  ])

  const onChangeRowSelection=useCallback((state, record)=>{
    if (record && record.selectedItemIds) {
      const temp=JSON.parse(JSON.stringify(NCOA_PAF_BROKER_LOCAL));
      temp.forEach((item)=>{
        const f=Array.from(record.selectedItemIds).find(x=>x===item.$.cBrokerID)
        if (f) {
          item.isSelected=true;
          item.sequence=Array.from(record.selectedItemIds).indexOf(f)+1;
        } else{
          item.isSelected=false;
          item.sequence='';
        }
      });
      setNCOA_PAF_BROKER_LOCAL(JSON.parse(JSON.stringify(temp)))
    }
  },[NCOA_PAF_BROKER_LOCAL])

  const onChangeSignerFields=(cBrokerID, fieldname, value)=>{
    if (cBrokerID && fieldname) {
      const temp=JSON.parse(JSON.stringify(NCOA_PAF_BROKER_LOCAL))
      const f=temp.find(x=>x.$.cBrokerID===cBrokerID)
      if (f) {
        f[fieldname]=value;
      }
      setNCOA_PAF_BROKER_LOCAL(JSON.parse(JSON.stringify(temp)))
    }
  }
  const renderBrokersHTMLTable=useMemo(()=>{
    if (NCOA_PAF_BROKER_LOCAL && NCOA_PAF_BROKERCount>0) {
      const temp=JSON.parse(JSON.stringify(NCOA_PAF_BROKER_LOCAL))

      const data=temp.map((t,index)=>{
        const cBrokerID=t?.$?.cBrokerID;
        const isSelected=t.isSelected||false;
        const isDisabled=t.isDisabled||false;
        return {
          ...t,
          sequence: t.sequence||'',
          cBrokerID,
          cSignerTitle: t.cSignerTitle||'',
          cSignerName: t.cSignerName||'',
          cSignerDate: t.cSignerDate||'',

          cSignerTitleControl: isSelected?(
            <Input
              style={{width:'200px'}}
              className="test-classname"
              domID={`signerTitle_${cBrokerID}_${index}`}
              placeholder=""
              size="small"
              maxLength="30"
              initialValue={t.cSignerTitle}
              disabled={isDisabled}
              onClick={(e)=>{
                e.preventDefault();
                e.stopPropagation();
              }}
              onChange={(e)=>{
                onChangeSignerFields(cBrokerID,'cSignerTitle', e.currentTarget.value)
              }}
            />
          ):'',
          cSignerNameControl: isSelected?(
            <Input
              style={{width:'200px'}}
              className="test-classname"
              domID={`signerName_${cBrokerID}_${index}`}
              placeholder=""
              size="small"
              maxLength="30"
              initialValue={t.cSignerName}
              disabled={isDisabled}
              onClick={(e)=>{
                e.preventDefault();
                e.stopPropagation();
              }}
              onChange={(e)=>{
                onChangeSignerFields(cBrokerID,'cSignerName', e.currentTarget.value)
              }}
            />
          ):'',
          cSignerDateControl: isSelected?(
            <DatePickerV2
              disabled={isDisabled}
              dataTestId={`signerDate_${cBrokerID}_${index}`}
              domID={`signerDate_${cBrokerID}_${index}`}
              errorMessage="error"
              initialSelection={t.cSignerDate?moment(t.cSignerDate):''}
              onDateChange={(e)=>{
                if (e) onChangeSignerFields(cBrokerID,'cSignerDate',e.format('MM/DD/YYYY'))
                else onChangeSignerFields(cBrokerID,'cSignerDate','')
              }}
              onFocusChange={()=>false}
              openDirection="down"
              showCalendarIcon
            />
          ):''
        }
      })
      return (
        <>
          <StyledGridWrapper className='GridWrapper'>
            <Grid
              {...CLIENT_NCOA_BROKERS_GRID}
              records={data || []}
              selectionKey="cBrokerID"
              initialSelectedItemIds={selectedItemIdsCurrentPageBrokers}
              initialDisabledItemIds={disabledItemIds}
              onRowSelect={(state,record)=>{
                onRowSelectBrokers(state,record)
                onChangeRowSelection(state,record)
              }}
              onSelectAll = {(state,record)=>{
                onRowSelectBrokers(state,record)
                onChangeRowSelection(state,record)
              }}
              supportSelection={currentlyAssosiatedPAFs.length>0}
              onClickThrough={(e)=>false}
              // onClickThrough={(e,record)=>{
              //   const s=Array.from(selectedItemIdsCurrentPageBrokers);
              //   const f=s.find(x=>x===record.cBrokerID);
              //   let newids=[]
              //   if (f) {
              //     newids=s.filter(x=>x!==record.cBrokerID);
              //   } else{
              //     newids=[...s, record.cBrokerID];
              //   }
              //   if(onSetSelectedItemIdsExplicitly) onSetSelectedItemIdsExplicitly(newids)
              //   onChangeRowSelection(!(record.isSelected||false),{selectedItemIds: newids}, true) 
              // }}
              initialSortingKey={`${sortConfigBrokers.sortDataName}|${sortConfigBrokers.sortDescending==="true"?'SORT_DESCENDING':'SORT_ASCENDING'}`}
              onSortGridColumn={(e,state)=>{
                const { sortingKey } = state;
                const sortingKey$split = sortingKey.split('|');
                const dataName = sortingKey$split[0];
                const f=Array.from(CLIENT_NCOA_BROKERS_GRID.columns).find(x=>x.dataName===dataName);
                if (f) {
                  const {cellType}=f;
                  if(gridSortingBrokers) gridSortingBrokers({sortingKey, type:cellType}, temp)
                }
              }}
            />
          </StyledGridWrapper>
        </>
      )
    }
    return null;
  },[
    NCOA_PAF_BROKER,
    NCOA_PAF_BROKERCount,
    NCOA_PAF_BROKER_LOCAL,
    selectedItemIdsCurrentPageBrokers,
    sortConfigBrokers,
    currentlyAssosiatedPAFs
  ])

  return (
    <>
      <ListPaneHeaderWrapper
        title="Client NCOA PAF Manager - Associate Brokers Editor"
        layoutControls={[expandCollapseListPaneButtonConfig]}
      />
      {isLoadinggetAssosicateBrokersTable || isLoadinggetBrokers || isLoadingupdatePAFBrokersAssociations ? (
      // replace with a better loading component
        <LoadingPage />
      ) : isErrorgetAssosicateBrokersTable || isErrorgetBrokers || isErrorupdatePAFBrokersAssociations ? (
      // replace with a better error component
        <ErrorPage
          // this prop isn't real but is an example
          error={errorgetAssosicateBrokersTable || errorgetBrokers || errorupdatePAFBrokersAssociations}
        />
      ) : (
        <>
          <div style={{marginLeft: '20px'}} className="marginLeft">
            {validationMessage?(
              <>
                <div className="displayFlex" style={{height:'20px !important'}}>
                  {validationloading?(
                    <StyledLoadingContainer>
                      <LoadingIndicator/>
                    </StyledLoadingContainer>
                  ):<div style={{color:'red'}} dangerouslySetInnerHTML={{ __html: validationMessage||'' }}></div>}
                </div>
              </>
            ): null}
            
            <table border="0" cellSpacing="1" cellPadding="3" width="100%">
              <tr>
                <td>
                  <div className="displayFlex" style={{marginBottom:'0px !important'}}>
                    <strong><label style={{fontSize: '14px'}}>&nbsp;&nbsp;PAFs:</label></strong>
                  </div>
                  {renderPAFHTMLTable}
                </td>
              </tr>
              <tr style={{position: 'relative', top: '20px'}}>
                <td>
                  <div className="displayFlex">
                    <strong><label style={{fontSize: '14px'}}>&nbsp;&nbsp;Brokers:</label></strong>
                  </div>
                  {renderBrokersHTMLTable}
                </td>
              </tr>
              <tr style={{position: 'relative', top: '40px'}}>
                <td className="setLocalStyleCol3">
                  <div className="displayFlex">
                    <div className="marginLeft">
                      <Button
                        domID="btn_clear"
                        buttonType="standard"
                        size="small"
                        name="CLEAR"
                        onClick={()=>onClearcurrentlyAssosiatedPAFs()}
                      />
                    </div>
                    <div className="marginLeft">
                      <Button
                        domID="btn_submit"
                        buttonType="standard"
                        size="small"
                        name="SAVE"
                        disabled={!validateSubmit}
                        onClick={()=>onSavecurrentlyAssosiatedPAFs()}
                      />
                    </div>
                  </div>
			
                </td>
              </tr>
            </table>

            <Modal isOpen={isOpenConfirmationModal}>
              <ModalHeader
                title="Do you want to save changes ?"
                onClose={() => setisOpenConfirmationModal(false)}
              />
              <ModalBody>
                {isOpenConfirmationModal ? (
                  <div style={{ marginLeft: '34px', marginBottom: '20px' }} className="marginLeft">
                    <div className="displayFlex">
                      <div className="marginLeft">
                        <Button
                          domID="btn_search"
                          buttonType="standard"
                          size="small"
                          name="Yes"
                          onClick={() => onConfirmationYesClick()}
                        />
                      </div>
                      <div className="marginLeft">
                        <Button
                          domID="btn_search"
                          buttonType="standard"
                          size="small"
                          name="No"
                          onClick={() => setisOpenConfirmationModal(false)}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
              </ModalBody>
            </Modal>
          </div>
        </>
      )}
    </>
  )

}

export default AssosiateBrokers;
