import React, { useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import map from 'lodash/map';
import findIndex from 'lodash/findIndex';
import {
  Modal,
  DetailpageTitle,
  Grid,
  LoadingIndicator,
  Button,
  Warning,
  // ModalBody
} from 'ui-core-ssgr';
import { ModalBody } from 'ui-core'
import ButtonGroup from '../../../../shared/components/ButtonGroup';
import { DivWithPaddingTop } from '../../../../shared/styles/spacing';
import Colors from '../../../../shared/styles/colors';
import {
  ModalPaddingWrapper,
  BoldTextAfterWarningIcon,
  IconWrapper,
  ModalTitlePaddingWrapper,
} from '../../../../shared/styles/styledComponents';
import { LoadingWrapper } from '../../../RulesTabView/components/CommitRuleModal/styles';
import {
  InputLabel,
  CustomTableData,
} from './styles';
import { gridConfig } from './constants';

const CommitTestAssociationsModal = ({
  isShowingCommitMultipleTestAssocRuleModalOpen,
  triggerCommitforMultipleTestAssocRules,
  selectedItemIds,
  selectedRecord,
  records,
  commitMultipleTestAssocRuleModalOpen,
  selectedRuleOnModal,
}) => {
  const [disableButton, setDisabledButton] = useState(false);

  const onButtonClick = name => () => {
    const groupId = selectedRecord.id;
    const ruleTypes = Array.from(selectedItemIds);
    const selectedRecordStatus = selectedRecord.status;
    if (name === 'confirm') {
      triggerCommitforMultipleTestAssocRules({
        groupId,
        ruleTypes,
        selectedRecordStatus,
      });
      setDisabledButton(true);
    }
    if (name === 'cancel') {
      isShowingCommitMultipleTestAssocRuleModalOpen(false);
      setDisabledButton(false);
    }
  };

  const selectedRecordName = get(selectedRecord, 'name', '');
  const nonTrialedRecords = findIndex(records, record => !record.trialed) !== -1;

  const displayWarningMessage = () => nonTrialedRecords && (
    <div>
      <IconWrapper>
        <Warning fillColor={Colors.negativeDark} />
      </IconWrapper>
      <BoldTextAfterWarningIcon>
        {`You are about to commit one or more <rule/rule association> that 
        has not yet been tested. Please select "Cancel" to go back and test this change before you commit.`}
      </BoldTextAfterWarningIcon>
    </div>
  );

  const renderCustomRow = ({ columns, record }) => {
    const setColumns = [...columns];

    return (
      <tr>
        {map(setColumns, c => {
          const dataKey = c.dataName;
          const dataValue = record[dataKey];

          return c.dataName === 'trialed' ? (
            <CustomTableData key={dataKey} className="trialed">
              {dataValue ? 'Yes' : 'No'}
            </CustomTableData>
          ) : (
              <td key={dataKey}>{dataValue}</td>
            )
        })}
      </tr>
    );
  };

  return (
    <>
      <Modal
        domID="commit-multiple-id"
        onModalToggle={() =>
          isShowingCommitMultipleTestAssocRuleModalOpen(false)
        }
        isOpen={commitMultipleTestAssocRuleModalOpen}
        showDefaultClose
        deferBodyControl={false}
        width="max-content"
      >
        <ModalBody>
          <ModalPaddingWrapper>
            <ModalTitlePaddingWrapper>
              <DetailpageTitle title="Commit Test Rules & Associations" />
              {displayWarningMessage()}
              <InputLabel>
                {`If you select "Confirm", this action will promote the association and the rule 
              from test to live for group ${selectedRecordName}. The Group will remain in the status it is.`}
              </InputLabel>
            </ModalTitlePaddingWrapper>
            <Grid
              {...gridConfig}
              records={records}
              supportSelection={false}
              triggerCommitforMultipleTestAssocRules={triggerCommitforMultipleTestAssocRules}
              commitMultipleTestAssocRuleModalOpen={commitMultipleTestAssocRuleModalOpen}
              selectedRuleOnModal={selectedRuleOnModal}
              selectedItemIds={selectedItemIds}
              rowComponent={renderCustomRow}
            />
            <DivWithPaddingTop>
              <LoadingWrapper>
                {disableButton && (
                  <LoadingIndicator
                    domID="basic-details-form-loading-indicator"
                    length="30px"
                  />
                )}
              </LoadingWrapper>
              <ButtonGroup>
                <Button
                  name="confirm"
                  buttonType="emphasized"
                  onClick={onButtonClick('confirm')}
                  size="medium"
                  type="button"
                  disabled={disableButton}
                />
                <Button
                  name="cancel"
                  buttonType="diminished"
                  onClick={onButtonClick('cancel')}
                  size="medium"
                  type="button"
                  disabled={disableButton}
                />
              </ButtonGroup>
            </DivWithPaddingTop>
          </ModalPaddingWrapper>
        </ModalBody>
      </Modal>
    </>
  );
};

CommitTestAssociationsModal.propTypes = {
  records: PropTypes.array,
  commitMultipleTestAssocRuleModalOpen: PropTypes.bool,
  triggerCommitforMultipleTestAssocRules: PropTypes.func,
  commitMultipleTestAssocRulesSuccess: PropTypes.func,
  commitMultipleTestAssocRulesError: PropTypes.func,
  isShowingCommitMultipleTestAssocRuleModalOpen: PropTypes.func,
  selectedItemIds: PropTypes.object,
  selectedRecord: PropTypes.object,
  selectedRuleOnModal: PropTypes.object,
  columns: PropTypes.object,
  record: PropTypes.object,
};

export default CommitTestAssociationsModal;
