import React from 'react';
import PropTypes from 'prop-types';
import { DetailHeader } from 'ui-core-ssgr';
import { PaddingWrapper } from '../../../../../../shared/styles/spacing';

import { headerProps } from './constants';

class LiveGridHeader extends React.Component {
  onButtonClick = name => {};

  render() {
    const { recordCount, headerName, isPostingRulesForRuleTypes } = this.props;

    return (
      <PaddingWrapper>
        <DetailHeader
          {...headerProps}
          title={headerName}
          amountKey="amount"
          domID="header"
          count={!isPostingRulesForRuleTypes ? recordCount : '0'}
          marginBottom={12}
        />
        {/* <RightSideButtons>
          <Button
            name="add a detail"
            buttonType="deEmphasized"
            onClick={() => onButtonClick('add')}
            disabled={isPostingRulesForRuleTypes}
            size="medium"
            type="button"
          />
          {additionalButtons.map(name => (
            <Button
              name={name}
              key={name}
              buttonType="deEmphasized"
              onClick={() => onButtonClick(name)}
              size="medium"
              type="button"
            />
          ))}
        </RightSideButtons> */}
      </PaddingWrapper>
    );
  }
}

LiveGridHeader.defaultProps = {
  recordCount: 0,
};

LiveGridHeader.propTypes = {
  selectedItemIds: PropTypes.object,
  recordCount: PropTypes.number,
  headerName: PropTypes.string,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
};

export default LiveGridHeader;
