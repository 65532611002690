import styled from 'styled-components';
import Typography from './typography';
import { Colors } from './colorVariables';

export const UnstyledButton = styled.button`
  padding: 0;
  margin: 0;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
  white-space: nowrap;
  ${Typography.small};
  ${Typography.bold};
  border-radius: 0;

  &.small {
    ${Typography.small}
  }

  &.medium {
    ${Typography.mediumLarge}
  }

  &.large {
    ${Typography.extraLarge}
  }

  &:disabled {
    cursor: default;
    pointer-events: none;
  }

  &.button,
  &.dropdown-button {
    outline: none;
  }

  &.unstyled,
  &.link {
    ${Typography.extraLargeLineHeight};
    padding: 0px 1em;

    &:focus:not(:active) {
      box-shadow: 0 0 0 2px ${Colors.grey30};
      border-radius: 2px;
    }

    &:focus:not(:focus-visible) {
      box-shadow: none;
    }

    &:focus-visible:not(:active) {
      box-shadow: 0 0 0 2px ${Colors.grey30};
      border-radius: 2px;
    }
  }

  &.standard,
  &.primary,
  &.destroy {
    .dropdown-caret path {
      fill: ${Colors.white};
    }
  }

  &.secondary,
  &.emphasized {
    .dropdown-caret path {
      fill: ${Colors.chBlue100};
    }
  }

  &.deEmphasized {
    .dropdown-caret path {
      fill: ${Colors.grey100};
    }
  }

  &.diminished {
    .dropdown-caret path {
      fill: ${Colors.brightBlue130};
    }
  }
`;

export const ButtonWithLinkStyling = styled(UnstyledButton)`
  font-size: inherit;
  text-transform: none;
  text-decoration: underline;
  text-decoration-color: ${Colors.grey30};
  color: ${Colors.grey100};
  ${Typography.defaultFontWeight};
  ${Typography.fontFamily};

  &:hover {
    text-decoration-color: ${Colors.grey70};
  }
`;

export const ButtonWithDefaultStyling = styled(UnstyledButton)`
  border-radius: 3px;
  padding: 0px 1em;
  max-width: 100%;
  display: inline-block;
  background-color: ${Colors.grey100};
  color: ${Colors.white};
  ${Typography.uppercase};
  ${Typography.LetterSpacing};
  ${Typography.fontFamily};
  outline: none;
  border: 1px solid transparent;

  &.small {
    height: 26px;
  }

  &.medium {
    height: 30px;
  }

  &.large {
    height: 36px;
  }

  &.load-type {
    position: relative;
    overflow: hidden;
  }

  &.loading:not(.finished-loading) {
    background-color: ${Colors.grey15};
    cursor: wait;
  }

  &:hover:not(.loading) {
    background-color: ${Colors.grey70};
  }

  &:active,
  &.open {
    background-color: ${Colors.black};
  }

  &:focus:not(:active):not(.loading) {
    box-shadow: 0 0 0 1px ${Colors.white}, 0 0 0 3px ${Colors.grey100};
  }

  &:focus:not(:focus-visible) {
    box-shadow: none;
  }

  &:focus-visible:not(:active):not(.loading) {
    box-shadow: 0 0 0 1px ${Colors.white}, 0 0 0 3px ${Colors.grey100};
  }

  &.open {
    &:focus {
      box-shadow: 0 0 0 1px ${Colors.white}, 0 0 0 3px ${Colors.black};
    }

    &:focus:not(:focus-visible) {
      box-shadow: none;
    }

    &:focus-visible {
      box-shadow: 0 0 0 1px ${Colors.white}, 0 0 0 3px ${Colors.black};
    }
  }

  &:disabled {
    background-color: ${Colors.grey15};
  }
`;

export const EmphasizedButton = styled(ButtonWithDefaultStyling)`
  background-color: ${Colors.brightBlue70};
  color: ${Colors.chBlue100};

  &:hover:not(.loading) {
    background-color: ${Colors.brightBlue50};
  }

  &:active,
  &.open {
    background-color: ${Colors.brightBlue100};
  }

  &:focus:not(:active):not(.loading) {
    box-shadow: 0 0 0 1px ${Colors.white}, 0 0 0 3px ${Colors.brightBlue70};
  }

  &:focus:not(:focus-visible) {
    box-shadow: none;
  }

  &:focus-visible:not(:active):not(.loading) {
    box-shadow: 0 0 0 1px ${Colors.white}, 0 0 0 3px ${Colors.brightBlue70};
  }

  &.open {
    &:focus {
      box-shadow: 0 0 0 1px ${Colors.white}, 0 0 0 3px ${Colors.brightBlue100};
    }

    &:focus:not(:focus-visible) {
      box-shadow: none;
    }

    &:focus-visible {
      box-shadow: 0 0 0 1px ${Colors.white}, 0 0 0 3px ${Colors.brightBlue100};
    }
  }

  &:disabled,
  &.loading:not(.finished-loading) {
    background-color: ${Colors.brightBlue15};
    color: ${Colors.digitalBlue40};

    .dropdown-caret path {
      fill: ${Colors.digitalBlue40};
    }
  }
`;

export const DeEmphasizedButton = styled(ButtonWithDefaultStyling)`
  background-color: transparent;
  color: ${Colors.grey100};
  border: 1px solid ${Colors.grey100};

  &:hover:not(.loading) {
    background-color: transparent;
    border-color: ${Colors.grey70};
    color: ${Colors.grey75};

    .dropdown-caret path {
      fill: ${Colors.grey70};
    }
  }

  &:active,
  &.open {
    background-color: transparent;
    border-color: ${Colors.black};
    color: ${Colors.black};

    .dropdown-caret path {
      fill: ${Colors.black};
    }
  }

  &:focus:not(:active):not(.loading) {
    border-color: ${Colors.grey100};
    color: ${Colors.grey100};
    box-shadow: 0 0 0 1px ${Colors.white}, 0 0 0 3px ${Colors.grey100};

    .dropdown-caret path {
      fill: ${Colors.grey100};
    }
  }

  &:focus:not(:focus-visible) {
    box-shadow: none;
  }

  &:focus-visible:not(:active):not(.loading) {
    border-color: ${Colors.grey100};
    color: ${Colors.grey100};
    box-shadow: 0 0 0 1px ${Colors.white}, 0 0 0 3px ${Colors.grey100};

    .dropdown-caret path {
      fill: ${Colors.grey100};
    }
  }

  &.open {
    &:focus {
      background-color: transparent;
      border-color: ${Colors.black};
      color: ${Colors.black};
      box-shadow: 0 0 0 1px ${Colors.white}, 0 0 0 3px ${Colors.black};

      .dropdown-caret path {
        fill: ${Colors.black};
      }
    }

    &:focus:not(:focus-visible) {
      box-shadow: none;
    }

    &:focus-visible {
      background-color: transparent;
      border-color: ${Colors.black};
      color: ${Colors.black};
      box-shadow: 0 0 0 1px ${Colors.white}, 0 0 0 3px ${Colors.black};

      .dropdown-caret path {
        fill: ${Colors.black};
      }
    }
  }

  &:disabled,
  &.loading:not(.finished-loading) {
    background-color: transparent;
    border-color: ${Colors.grey15};
    color: ${Colors.grey15};

    .dropdown-caret path {
      fill: ${Colors.grey15};
    }
  }
`;

export const DeEmphasizedReversedButton = styled(ButtonWithDefaultStyling)`
  background-color: transparent;
  color: ${Colors.white};
  border: 1px solid ${Colors.white};
  .dropdown-caret path {
    fill: ${Colors.white};
  }

  &:hover:not(.loading) {
    background-color: transparent;
    border-color: ${Colors.brightBlue50};
    color: ${Colors.brightBlue50};

    .dropdown-caret path {
      fill: ${Colors.brightBlue50};
    }
  }

  &:active,
  &.open {
    background-color: transparent;
    border: 1px solid ${Colors.brightBlue100};
    color: ${Colors.brightBlue100};

    .dropdown-caret path {
      fill: ${Colors.brightBlue100};
    }
  }

  &:focus:not(:active):not(.loading) {
    border-color: transparent;
    color: ${Colors.white};
    box-shadow: inset 0 0 0 1px ${Colors.white}, 0 0 0 2px ${Colors.white};
  }

  &:focus:not(:focus-visible):not(:active) {
    border: 1px solid ${Colors.white};
    box-shadow: none;
  }

  &:focus-visible:not(:active) {
    border-color: transparent;
    color: ${Colors.white};
    box-shadow: inset 0 0 0 1px ${Colors.white}, 0 0 0 2px ${Colors.white};
  }

  &.open {
    &:focus {
      color: ${Colors.brightBlue100};
      border-color: transparent;
      box-shadow: inset 0 0 0 1px ${Colors.brightBlue100},
        0 0 0 2px ${Colors.brightBlue100};
    }

    &:focus:not(:focus-visible) {
      border: 1px solid ${Colors.brightBlue100};
      box-shadow: none;
    }

    &:focus-visible {
      color: ${Colors.brightBlue100};
      border-color: transparent;
      box-shadow: inset 0 0 0 1px ${Colors.brightBlue100},
        0 0 0 2px ${Colors.brightBlue100};
    }
  }

  &:disabled,
  &.loading:not(.finished-loading) {
    background-color: transparent;
    border-color: ${Colors.blue100};
    color: ${Colors.blue100};

    .dropdown-caret path {
      fill: ${Colors.blue100};
    }
  }
`;

export const DiminishedButton = styled(ButtonWithDefaultStyling)`
  background-color: transparent;
  color: ${Colors.brightBlue130};
  border: 2px solid transparent;

  &:hover:not(.loading) {
    background-color: transparent;
    color: ${Colors.brightBlue100};

    .dropdown-caret path {
      fill: ${Colors.brightBlue100};
    }
  }

  &:active,
  &.open {
    background-color: transparent;
    color: ${Colors.brightBlue150};
    border: 2px solid transparent;

    .dropdown-caret path {
      fill: ${Colors.brightBlue150};
    }
  }

  &:focus:not(:active):not(.loading) {
    color: ${Colors.brightBlue130};
    border: 2px solid ${Colors.brightBlue130};
    box-shadow: 0 0 0;

    .dropdown-caret path {
      fill: ${Colors.brightBlue130};
    }
  }

  &:focus:not(:focus-visible) {
    border: 2px solid transparent;
    box-shadow: none;
  }

  &:focus-visible:not(:active) {
    color: ${Colors.brightBlue130};
    border: 2px solid ${Colors.brightBlue130};
    box-shadow: 0 0 0;

    .dropdown-caret path {
      fill: ${Colors.brightBlue130};
    }
  }

  &:disabled,
  &.loading:not(.finished-loading) {
    background-color: transparent;
    color: ${Colors.grey15};

    .dropdown-caret path {
      fill: ${Colors.grey15};
    }
  }

  &.open {
    &:focus {
      background-color: transparent;
      color: ${Colors.brightBlue150};
      border: 2px solid ${Colors.brightBlue150};

      .dropdown-caret path {
        fill: ${Colors.brightBlue150};
      }

      box-shadow: 0 0 0;
    }

    &:focus:not(.focus-visible) {
      box-shadow: none;
    }

    &:focus-visible {
      background-color: transparent;
      color: ${Colors.brightBlue150};
      border: 2px solid ${Colors.brightBlue150};

      .dropdown-caret path {
        fill: ${Colors.brightBlue150};
      }
      box-shadow: 0 0 0;
    }

    &:disabled {
      background-color: transparent;
      color: ${Colors.grey15};

      .dropdown-caret path {
        fill: ${Colors.grey15};
      }
    }
  }
`;

export const DestroyButton = styled(ButtonWithDefaultStyling)`
  background-color: ${Colors.digitalRed130};
  color: ${Colors.white};

  &:hover:not(.loading) {
    background-color: ${Colors.digitalRed70};
  }

  &:active,
  &.open {
    background-color: ${Colors.digitalRed150};
  }

  &:focus:not(:active):not(.loading) {
    box-shadow: 0 0 0 1px ${Colors.white}, 0 0 0 3px ${Colors.digitalRed130};
  }

  &:focus:not(:focus-visible) {
    box-shadow: none;
  }

  &:focus-visible:not(:active) {
    box-shadow: 0 0 0 1px ${Colors.white}, 0 0 0 3px ${Colors.digitalRed130};
  }

  &.open {
    &:focus {
      box-shadow: 0 0 0 1px ${Colors.white}, 0 0 0 3px ${Colors.digitalRed150};
    }

    &:focus:not(:focus-visible) {
      box-shadow: none;
    }

    &:focus-visible {
      box-shadow: 0 0 0 1px ${Colors.white}, 0 0 0 3px ${Colors.digitalRed150};
    }
  }

  &:disabled,
  &.loading:not(.finished-loading) {
    background-color: ${Colors.digitalRed30};
  }
`;

export default {
  UnstyledButton,
  ButtonWithLinkStyling,
  ButtonWithDefaultStyling,
  EmphasizedButton,
  DeEmphasizedButton,
  DeEmphasizedReversedButton,
  DestroyButton,
  DiminishedButton,
};
