import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import AssociateGridHeader from './AssociateGridHeader';
import AssociateGridWrapper from './AssociateGridWrapper';
import { associatiedGroupsGridConfig } from './AssociateGridWrapper/constants';

export const AssociateTab = props => {
  const {
    groupAssociationsForRuleIdTrigger,
    selectedRecord,
    associatedGroupRecordsNoTermedRecords,
    isPostingRulesForRuleTypes,
    isCloningRule,
    groupAssociationsForRuleId,
    loading,
    notifications,
    deleteNotification,
    selectedRuleTypeId,
    captureCurrentPage
  } = props;

  useEffect(() => {
    // following action is called to capture new relic data
    captureCurrentPage({pageId: `${associatiedGroupsGridConfig.pageTrackerPrefix}${selectedRuleTypeId}`});
  }, []);

  return (
    <>
      <AssociateGridHeader
        isPostingRulesForRuleTypes={isPostingRulesForRuleTypes}
        isCloningRule={isCloningRule}
      />
      <AssociateGridWrapper
        groupAssociationsForRuleIdTrigger={groupAssociationsForRuleIdTrigger}
        isPostingRulesForRuleTypes={isPostingRulesForRuleTypes}
        isCloningRule={isCloningRule}
        loading={loading}
        notifications={notifications}
        deleteNotification={deleteNotification}
        selectedRecord={selectedRecord}
        associatedGroupRecordsNoTermedRecords={
          associatedGroupRecordsNoTermedRecords
        }
        groupAssociationsForRuleId={groupAssociationsForRuleId}
      />
    </>
  );
};

AssociateTab.propTypes = {
  selectedRecord: PropTypes.shape({
    id: PropTypes.string,
  }),
  groupAssociationsForRuleIdTrigger: PropTypes.func,
  associatedGroupRecordsNoTermedRecords: PropTypes.array,
  captureCurrentPage: PropTypes.func,
};

export default AssociateTab;
