/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import styled from 'styled-components/dist/styled-components'
import { useLayout } from 'ui-core/dist/useLayout';
import DetailPaneHeader from 'ui-core/dist/DetailPaneHeader';
import { useSelector, useDispatch } from 'react-redux';
import {
  Modal, Button, ModalHeader, Input, TextArea, ModalBody
} from 'ui-core/dist';
import { detailPaneAnimStates } from '../../../commonResources/constants/animStates';
import ErrorPage from '../../ErrorPage';
import Authorizer from '../../Authorizer';
import LoadingComponent from '../../../components/LoadingComponent';
import useNotificationBannerHook from '../../../useNotificationBannerHook';
import { SelectDropdown } from '../../../components'
import {
  setDraftCodeData, resetDraftCodeData
} from '../Modules/action'
import {
  useGetSelectedImageData,
  useAddImageTranslation,
  useGetImageTranslationDetails,
  useUpdateImageTranslation,
  useDeleteImageTranslation
} from '../../../api/useClientRuleManager'

const ImageTranslationDetail = () => {
  const {
    expandCollapseDetailPaneButtonConfig,
    closeDetailPaneButtonConfig,
    detailAnimationState,
    closeDetailPane
  } = useLayout()
  const dispatch = useDispatch();
  const { notify } = useNotificationBannerHook();
  // const [draftCode, setDraftCode] = useState('')
  // const [message, setMessage] = useState('')
  // const [logo, setLogo] = useState('')
  const [imageTranp, setImageTran] = useState('')
  const [imageCode, setImageCode] = useState('')
  const [imageName, setImageName] = useState('')
  const [imageListData, setImageListData] = useState()
  const [confirmationModalMode, setconfirmationModalMode] = useState('')
  const [isOpenConfirmationModal, setisOpenConfirmationModal] = useState(false)
  const [editorMode, setEditorMode] = useState('Add')

  const [count, setcount] = useState(0)

  const { terroristExclusionSelectedRecord, terroristExclusionEditorMode: editorModep, refetchterroristExclusionRecord }
    = useSelector(({ clientRule }) => clientRule);
  // const [form, setForm] = useState({label:terroristExclusionSelectedRecord.FormDesc,value:terroristExclusionSelectedRecord.FormName});
  const {ImageData, totalRecords,getAll} = useGetImageTranslationDetails();  
  useEffect(()=>{
    getAll(JSON.parse(JSON.stringify({      
      "maxRecords":"5000",
      "rowPosition": "10",
      "sortField":"",
      "sortDescending":true,
      count
    })))
    setcount(count+1)
  },[])
  useEffect(() => {
    
    if (terroristExclusionSelectedRecord && terroristExclusionSelectedRecord.imageTran) {
      getShowMoreData(terroristExclusionSelectedRecord.imageTran);
        
    } else 
    {
      onClearCurrentRecordValues()
    }
    
    
  }, [terroristExclusionSelectedRecord]);

 

  const getShowMoreData = (key) => {
    postDataShowMoreInfo({ strImageID:key,count})
    setcount(count + 1)
  }
  const onCopyCurrentRecordValues = ()=>{
    setImageTran('')
    setEditorMode('Edit')
  }

  const { showMoreInfo, postData: postDataShowMoreInfo, isLoading: isLoadingShowMoreInfo,
    isError: isErrorShowMoreInfo, error: errorShowMoreINfo } = useGetSelectedImageData()

  const { data: infoAddAddressExclusion, postData: addImageTranslation, metaData: addTerroristExclusionResponse, isLoading: isLoadingaddTerroristExclusion,
    isError: isErroraddTerroristExclusion, error: erroraddTerroristExclusion } = useAddImageTranslation()

  const { data: infoModifyAddressExclusion, postData: modifyImageTranslation, metaData: modifyTerroristExclusionResponse, isLoading: isLoadingmodifyTerroristExclusion,
    isError: isErrormodifyTerroristExclusion, error: errormodifyTerroristExclusion } = useUpdateImageTranslation()
    
  const { data: infoDeleteRecipType, postData: deleteImageTranslation, metaData: deleteClaimTypeTranslationResponse, isLoading: isLoadingdeleteRecipientTypeTranslation,
    isError: isErrordeleteRecipientTypeTranslation, error: errordeleteRecipientTypeTranslation } = useDeleteImageTranslation()
  const onResetCurrentRecordValues = () => {
    if (showMoreInfo) {
      setImageTran(showMoreInfo?.imageTran)
      setImageCode(showMoreInfo?.clientImageName)
      setImageName(showMoreInfo?.abfImageName)
      setImageListData(showMoreInfo?.abfImageName)
      setEditorMode(editorModep)
    }
  }


  useEffect(() => {
    if (addTerroristExclusionResponse) {
      const { status, statusText } = addTerroristExclusionResponse;
      if (status === 200 || statusText === "OK") {
        if (infoAddAddressExclusion && infoAddAddressExclusion.fs) {
          const { fs } = infoAddAddressExclusion;
          if (fs) {
            notify(`You must provide a unique value for the Client Image Code. This value has already been used.`, 'negative')
            return;
          }
        }
        
        notify(`You have successfully added Image Translation "${imageCode}"`, 'positive')
        closeDetailPane()
        if (refetchterroristExclusionRecord) refetchterroristExclusionRecord()
      }
    }
  }, [addTerroristExclusionResponse])

  useEffect(() => {
    if (modifyTerroristExclusionResponse) {
      const { status, statusText } = modifyTerroristExclusionResponse;
      if (status === 200 || statusText === "OK") {
        if (infoModifyAddressExclusion && infoModifyAddressExclusion.fs) {
          const { fs } = infoModifyAddressExclusion;
          if (fs) {
            notify(fs, 'negative')
            return;
          }
        }
        notify(`You have successfully modified Image Translation "${imageCode}"`, 'positive')
        closeDetailPane()
        if (refetchterroristExclusionRecord) refetchterroristExclusionRecord()
      }
    }
  }, [modifyTerroristExclusionResponse])

  useEffect(() => {
    if (deleteClaimTypeTranslationResponse) {
      const { status, statusText } = deleteClaimTypeTranslationResponse;
      if (status === 200 || statusText === "OK") {
        if (infoDeleteRecipType && infoDeleteRecipType.fs) {
          const { fs } = infoDeleteRecipType;
          if (fs) {
            notify(fs, 'negative')
            return;
          }
        }
        
        notify(`You have successfully deleted Image Translation "${imageCode}"`, 'positive')
        closeDetailPane()
        if (refetchterroristExclusionRecord) refetchterroristExclusionRecord()
      }
    }
  }, [deleteClaimTypeTranslationResponse])

  const onSubmitClick = () => {
    if (editorMode === "Add") {
      setconfirmationModalMode("Add")
    } else if (editorMode === "Edit") {
      setconfirmationModalMode("Modify")
    }
    setisOpenConfirmationModal(true)
  }
  const onConfirmationYesClick = () => {
    if (confirmationModalMode === "Add") {
      addImageTranslation({
        count,
        // strImageID: "",
        clientImageName: imageCode,
        abfImageName: imageListData
      })
      setcount(count + 1)

    } else if (confirmationModalMode === "Modify") {
      modifyImageTranslation({
        count,
        imageTran: imageTranp,
        clientImageName: imageCode,
        abfImageName: imageListData
      })
      setcount(count + 1)
    } else if (confirmationModalMode === "Delete") {
      deleteImageTranslation({
        count,
        imageTran: imageTranp,
      })
      setcount(count + 1)
    }

    setconfirmationModalMode('')
    setisOpenConfirmationModal(false)
  }
  useEffect(() => {
    onResetCurrentRecordValues()
  }, [showMoreInfo])

  const onClearCurrentRecordValues = () => {
    
    setImageTran('')
    setImageCode('')
    setImageListData('')
    setEditorMode('Add')
  }
  const renderFormTranslationID = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_name"
      placeholder=""
      size="small"
      initialValue={imageTranp}
      disabled
      // errorMessage="Incorrect data do not use '&', '|' or '>' characters"
      // hasError={/[\\&>\\|]/.test(name)}
      onChange={(e) => {
        setImageTran(e.target.value || "")
      }}
    />
  ), [imageTranp])
  const renderClientForm = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_address"
      placeholder=""
      size="small"
      maxLength={5}
      initialValue={imageCode}
      // errorMessage="Incorrect data do not use '&', '|' or '>' characters"
      // hasError={/[\\&>\\|]/.test(address)}
      onChange={(e) => {
        setImageCode(e.target.value || "")
      }}
    />
  ), [imageCode])
  const renderLogo = useMemo(() => (
    <SelectDropdown
      width="350px"
      domID="defer_state_input"
      label=""
      options={ImageData}
      size="small"
      initialValue={{ label: ImageData.find(x=>x.value===imageListData)?.label, value: imageListData}}
      onChange={(e)=>{
        if (e) {
          if (setImageListData) setImageListData(e.value)
        } else if (setImageListData) setImageListData(null)
      }}
    />
  ), [ImageData, terroristExclusionSelectedRecord, imageListData])
  
  const validateForm = useMemo(() => {
    if(!imageCode || !imageListData){
      return true
    }
    return false

  }, [imageCode, imageListData])

  const renderAddressEntryForm = useMemo(() => {
    if (detailAnimationState > detailPaneAnimStates.COLLAPSED) {
      return ( 
        <div style={{ marginLeft: '40px' }} className="marginLeft">
          <div>
            <p>
              <div className="displayFlex">Complete the information below, and then click Submit to continue.</div>
            </p>
            <p>
              <div className="displayFlex"><span style={{ color: 'red' }}>* indicates required information</span></div>
            </p>
          </div>
          <br />
          <div className="displayFlex">
            <table className="common-table">
            {editorMode !== "Add"? <tr>
                <td>
                  <label> Image Translation ID : </label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderFormTranslationID}
                  </div>
                </td>
              </tr>: null}
              <tr><p></p></tr>
              <tr>
                <td>
                  <label> Client Image Code : <span style={{ color: 'red' }}>*</span></label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderClientForm}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td style={{width: "204px"}}>
                  <label>Image Name & Image Description :  <span style={{ color: 'red' }}>*</span></label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderLogo}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              
              <tr>
                <td colSpan="2">
                  <div className="displayFlex">
                    <div>
                      <Button
                        domID="btn_reset"
                        buttonType="standard"
                        size="small"
                        name="RESET"
                        // disabled={editorMode !== "Edit"}
                        onClick={() => {
                          if(editorMode==="Add")
                          onClearCurrentRecordValues()
                          else
                          onResetCurrentRecordValues()
                        }}
                      />
                    </div>
                    <div className="marginLeft">
                      <Authorizer permission= "CAN_PERFORM_CLIENTRULES_IMT_ADD">
                      <Button
                        domID="btn_copy"
                        buttonType="standard"
                        size="small"
                        name="COPY"
                        disabled={editorMode === "Add"}
                        onClick={() => {
                          onCopyCurrentRecordValues()
                          setEditorMode('Add')
                        }}
                      />
                      </Authorizer>
                    </div>
                    <div className="marginLeft">
                      <Authorizer permission= "CAN_PERFORM_CLIENTRULES_IMT_DEACTIVATE">
                      <Button
                        domID="btn_delete"
                        buttonType="standard"
                        size="small"
                        name="DELETE"
                        disabled={editorMode !== "Edit"}
                        onClick={() => {setconfirmationModalMode("Delete")
                          setisOpenConfirmationModal(true)}}
                      />
                    </Authorizer>
                    </div>
                    <div className="marginLeft">
                      <Authorizer permission= "CAN_PERFORM_CLIENTRULES_IMT_MODIFY">
                      <Button
                        domID="btn_search"
                        buttonType="standard"
                        size="small"
                        name="SUBMIT"
                        disabled={validateForm}
                        onClick={() => onSubmitClick()}
                      />
                      </Authorizer>
                    </div>
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
            </table>
          </div>
          <Modal isOpen={isOpenConfirmationModal}>
            <ModalHeader
              title={`Do you want to ${confirmationModalMode} ?`}
              onClose={() => setisOpenConfirmationModal(false)}
            />
            <ModalBody>
              {isOpenConfirmationModal ? (
                <div style={{ marginLeft: '34px', marginBottom: '20px' }} className="marginLeft">
                  <div className="displayFlex">
                    <div className="marginLeft">
                      <Button
                        domID="btn_search"
                        buttonType="standard"
                        size="small"
                        name="Yes"
                        onClick={() => onConfirmationYesClick()}
                      />
                    </div>
                    <div className="marginLeft">
                      <Button
                        domID="btn_search"
                        buttonType="standard"
                        size="small"
                        name="No"
                        onClick={() => setisOpenConfirmationModal(false)}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
            </ModalBody>
          </Modal>
        </div>
      )
    }
    return null;
  }, [terroristExclusionSelectedRecord, showMoreInfo, detailAnimationState, imageTranp, imageCode, ImageData, imageListData,
    validateForm, editorMode, confirmationModalMode, isOpenConfirmationModal])

  return (
    <>
      <DetailPaneHeader
        title={editorMode === "Edit" ? "Modify Image Translation" : "Add Image Translation"}
        layoutControls={[
          expandCollapseDetailPaneButtonConfig,
          closeDetailPaneButtonConfig
        ]}
      />
      <>
        {isLoadingShowMoreInfo || isLoadingaddTerroristExclusion
          || isLoadingmodifyTerroristExclusion || isLoadingdeleteRecipientTypeTranslation? (
            // replace with a better loading component            
            <div style={{ marginLeft: '50%' }}><LoadingComponent /></div>
          ) : isErrorShowMoreInfo || isErroraddTerroristExclusion
            || isErrormodifyTerroristExclusion || isErrordeleteRecipientTypeTranslation ? (
              // replace with a better error component
              <ErrorPage
                // this prop isn't real but is an example
                error={errorShowMoreINfo || erroraddTerroristExclusion
                  || errormodifyTerroristExclusion || errordeleteRecipientTypeTranslation}
              />
            ) : (
              <>
                {renderAddressEntryForm}
              </>
            )
        }
      </>
    </>
  )
}

export default ImageTranslationDetail