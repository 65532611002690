import React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import { Page } from '../components';
import EEOBFormManagerMain from '../containers/EEOBFormManager/EEOBFormManagerMain'
import EEOBFormManagerDetail from '../containers/EEOBFormManager/EEOBFormManagerDetail'

const EEOBFormManagerPage = () => (
  <Page
    ListPaneContent={EEOBFormManagerMain}
    DetailPaneContent={EEOBFormManagerDetail}
  />
);

const EEOBRoutes = () => (
  <Switch>
    <Route path="/EEOBFormManager/list" component={EEOBFormManagerPage} />
    <Route exact path="/EEOBFormManager">
      <Redirect to="/EEOBFormManager/list" />
    </Route>
  </Switch>
)

export default EEOBRoutes;