import styled from 'styled-components';
import Collapse from 'rc-collapse';

export const OuterPanel = styled(Collapse)`
  .rc-collapse-item.rc-collapse-item-disabled > .rc-collapse-header {
    pointer-events: none;
    background-color: inherit;
    & > div {
      pointer-events: auto;
    }
  }
`;
