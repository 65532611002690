import { useEffect, useState } from 'react'
import { useAxiosPost } from '../hooks'

const useGetDraftCodes = () => {
  const [totalRecordsResp, settotalRecordsResp]=useState(0)
  const [draftCodesData, setTwExclusionData]=useState([])

  const { data,postData, error, status, isLoading,isError, ...rest } = useAxiosPost({
    url: `draftCode/getDraftCodes`,
    method:'POST',
    config:{
      'Content-Type':'application/json',
      'Accept':'*'
    },
    dispatchError:false
  })

  useEffect(()=>{
    if (data) {
      const {collection}=data;
      if (collection && collection.$) {
        const {totalRecords: totalRecordsp, type}=collection.$
        settotalRecordsResp(totalRecordsp)  
        if (type) {
          let finalData;
          if (collection[type]) {
            finalData=collection[type]
          } else if (collection['draft-translation']){
            finalData=collection['draft-translation']
          }
          if (finalData) {
            const sr=JSON.parse(JSON.stringify(finalData))  
            let searchResult=[]
            if (sr.constructor.name==="Array") {
              searchResult=[...sr]
            } else if(sr.constructor.name==="Object"){
              searchResult.push({...sr})
            }
            if (searchResult && searchResult.length>0) {
              setTwExclusionData(JSON.parse(JSON.stringify(searchResult)))  
            } else{
              setTwExclusionData(JSON.parse(JSON.stringify([])))  
            }
          } else{
            setTwExclusionData(JSON.parse(JSON.stringify([])))
          }
        } else{
          settotalRecordsResp(0);
          setTwExclusionData(JSON.parse(JSON.stringify([])))
        }
      }
    }
  },[data])

  return { draftCodesData, totalRecordsResp, getAll:postData, error, isLoading,isError, status, ...rest }
}
const useGetSelectedDraftCodeData=()=>{
  const [showMoreInfo, setshowMoreInfo]=useState(null)
  const {postData, isLoading,isError,error, data }=useAxiosPost({
    url:'draftCode/getDraftCode',
    method:'POST',
    onError: (e) => 
    // what happens on error? if you need something extra
    // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  
  useEffect(()=>{
    if (data && data["draft-translation"]) {
      setshowMoreInfo(JSON.parse(JSON.stringify(data["draft-translation"])))
    } else{
      setshowMoreInfo(null);
    }
  },[data])
  
  return {showMoreInfo, isLoading, isError, error, postData};
}
  
const useAddDraftCode=()=>{
  const {postData, isLoading,isError,error, metaData, data }=useAxiosPost({
    url:'draftCode/addDraftCode',
    method:'POST',
    onError: (e) => 
    // what happens on error? if you need something extra
    // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  
  
  return { isLoading, isError, error, postData, metaData, data};
}
const useUpdateDraftCode=()=>{
  const {postData, isLoading,isError,error,metaData, data }=useAxiosPost({
    url:'draftCode/updateDraftCode',
    method:'POST',
    onError: (e) => 
    // what happens on error? if you need something extra
    // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  
  
  return { isLoading, isError, error, postData, metaData, data};
}

const useGetFormTranslations = () => {
  const [totalRecordsResp, settotalRecordsResp]=useState(0)
  const [FormTranslationsData, setTwExclusionData]=useState([])

  const { data,postData, error, status, isLoading,isError, ...rest } = useAxiosPost({
    url: `formTranslation/getFormTranslations`,
    method:'POST',
    config:{
      'Content-Type':'application/json',
      'Accept':'*'
    },
    dispatchError:false
  })

  useEffect(()=>{
    if (data) {
      const {collection}=data;
      if (collection && collection.$) {
        const {totalRecords: totalRecordsp, type}=collection.$
        settotalRecordsResp(totalRecordsp)
        if (type) {
          let finalData;
          if (collection[type]) {
            finalData=collection[type]
          } else if (collection.FormTranslation){
            finalData=collection.FormTranslation
          }
          if (finalData) {  
            const sr=JSON.parse(JSON.stringify(finalData))  
            let searchResult=[]
            if (sr.constructor.name==="Array") {
              searchResult=[...sr]
            } else if(sr.constructor.name==="Object"){
              searchResult.push({...sr})
            }
            if (searchResult && searchResult.length>0) {
              setTwExclusionData(JSON.parse(JSON.stringify(searchResult)))  
            } else{
              setTwExclusionData(JSON.parse(JSON.stringify([])))  
            }
          } else{
            setTwExclusionData(JSON.parse(JSON.stringify([])))
          }
        } else{
          settotalRecordsResp(0);
          setTwExclusionData(JSON.parse(JSON.stringify([])))
        }
      }
    }
  },[data])

  return { FormTranslationsData, totalRecordsResp, getAll:postData, error, isLoading,isError, status, ...rest }
}

const useGetForms = () => {
  const [totalRecordsResp, settotalRecordsResp]=useState(0)
  const [FormData, setTwExclusionData]=useState([])

  const { data,postData, error, status, isLoading,isError, ...rest } = useAxiosPost({
    url: `formTranslation/getForms`,
    method:'POST',
    config:{
      'Content-Type':'application/json',
      'Accept':'*'
    },
    dispatchError:false
  })

  useEffect(()=>{
    if (data) {
      const {collection}=data;
      if (collection && collection.$) {
        const {totalRecords: totalRecordsp, type}=collection.$
        settotalRecordsResp(totalRecordsp) 
        if (type) {
          let finalData;
          if (collection[type]) {
            finalData=collection[type]
          } else if (collection.Form){
            finalData=collection.Form
          }
          if (finalData) { 
            const sr=JSON.parse(JSON.stringify(finalData))  
            let searchResult=[]
            if (sr.constructor.name==="Array") {
              searchResult=[...sr]
            } else if(sr.constructor.name==="Object"){
              searchResult.push({...sr})
            }
            if (searchResult && searchResult.length>0) {
              setTwExclusionData(JSON.parse(JSON.stringify(searchResult)))  
            } else{
              setTwExclusionData(JSON.parse(JSON.stringify([])))  
            }
          } else{
            setTwExclusionData(JSON.parse(JSON.stringify([])))
          }
        } else{
          settotalRecordsResp(0);
          setTwExclusionData(JSON.parse(JSON.stringify([])))
        }
      }
    }
  },[data])

  return { FormData, totalRecordsResp, getAllForms:postData, error, isLoading,isError, status, ...rest }
}

const useGetSelectedFormData=()=>{
  const [showMoreInfo, setshowMoreInfo]=useState(null)
  const {postData, isLoading,isError,error, data }=useAxiosPost({
    url:'formTranslation/getFormTranslation',
    method:'POST',
    onError: (e) => 
    // what happens on error? if you need something extra
    // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  
  useEffect(()=>{
    if (data && data.resp) {
      const {resp}=data;
      setshowMoreInfo(JSON.parse(JSON.stringify(resp)))
    } else{
      setshowMoreInfo(null);
    }
  },[data])
  
  return {showMoreInfo , isLoading, isError, error, postData};
}
const useAddFormTranslation=()=>{
  const {postData, isLoading,isError,error,metaData, data }=useAxiosPost({
    url:'formTranslation/addFormTranslation',
    method:'POST',
    onError: (e) => 
    // what happens on error? if you need something extra
    // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  
  
  return { isLoading, isError, error, postData, metaData, data};
}
const useUpdateFormTranslation=()=>{
  const {postData, isLoading,isError,error,metaData, data }=useAxiosPost({
    url:'formTranslation/updateFormTranslation',
    method:'POST',
    onError: (e) => 
    // what happens on error? if you need something extra
    // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  
  
  return { isLoading, isError, error, postData, metaData, data};
}

const useGetStateCopy = () => {
  const [totalRecordsResp, settotalRecordsResp]=useState(0)
  const [draftCodesData, setTwExclusionData]=useState([])
  const { data,postData, error, status, isLoading,isError, ...rest } = useAxiosPost({
    url: `stateCopy/get1099StateCopyRecs`,
    method:'POST',
    config:{
      'Content-Type':'application/json',
      'Accept':'*'
    },
    dispatchError:false
  })

  useEffect(()=>{
    if (data) {
      const {collection}=data;
      if (collection && collection.$) {
        const {totalRecords: totalRecordsp, type}=collection.$
        settotalRecordsResp(totalRecordsp)  
        if (type) {
          let finalData;
          if (collection[type]) {
            finalData=collection[type]
          } else if (collection['state-copy-1099']){
            finalData=collection['state-copy-1099']
          }
          if (finalData) {
            const sr=JSON.parse(JSON.stringify(finalData))  
            let searchResult=[]
            if (sr.constructor.name==="Array") {
              searchResult=[...sr]
            } else if(sr.constructor.name==="Object"){
              searchResult.push({...sr})
            }
            if (searchResult && searchResult.length>0) {
              setTwExclusionData(JSON.parse(JSON.stringify(searchResult)))  
            } else{
              setTwExclusionData(JSON.parse(JSON.stringify([])))  
            }
          } else{
            setTwExclusionData(JSON.parse(JSON.stringify([])))
          }
        } else{
          settotalRecordsResp(0);
          setTwExclusionData(JSON.parse(JSON.stringify([])))
        }
      }
    }
  },[data])

  return { draftCodesData, totalRecordsResp, getAll:postData, error, isLoading,isError, status, ...rest }
}

const useGetSelectedStateCopyData=()=>{
  const [showMoreInfo, setshowMoreInfo]=useState(null)
  const {postData, isLoading,isError,error, data }=useAxiosPost({
    url:'stateCopy/get1099StateCopy',
    method:'POST',
    onError: (e) => 
    // what happens on error? if you need something extra
    // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  
  useEffect(()=>{
    if (data && data["state-copy-1099"]) {
      setshowMoreInfo(JSON.parse(JSON.stringify(data["state-copy-1099"]["state-copy-1099"])))
    } else{
      setshowMoreInfo(null);
    }
  },[data])
  
  return {showMoreInfo, isLoading, isError, error, postData};
}

const useAddStateCopyRule=()=>{
  const {postData, isLoading,isError,error, metaData, data }=useAxiosPost({
    url:'stateCopy/add1099StateCopy',
    method:'POST',
    onError: (e) => 
    // what happens on error? if you need something extra
    // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  
  
  return { isLoading, isError, error, postData, metaData, data};
}
const  useUpdateStateCopy=()=>{
  const {postData, isLoading,isError,error,metaData, data }=useAxiosPost({
    url:'stateCopy/update1099StateCopy',
    method:'POST',
    onError: (e) => 
    // what happens on error? if you need something extra
    // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  
    
  return { isLoading, isError, error, postData, metaData, data};
}
const useGetCheckAccountInfoRecs = () => {  
  const { data, status, error, isError, isLoading, isSuccess, refetch, postData, metaData } = useAxiosPost({
    url: 'checkAcctInfo/getCheckAccountInfoRecs',
    method: 'POST',
    dispatchError: false
  });
  
  return { data, status, error, isError, isLoading, isSuccess, refetch, metaData, postData}
}

const useGetCheckAccountInfo = () => {  
  const { data, status, error, isError, isLoading, isSuccess, refetch, postData, metaData } = useAxiosPost({
    url: 'checkAcctInfo/getCheckAccountInfo',
    method: 'POST',
    dispatchError: false
  });
  
  return { data, status, error, isError, isLoading, isSuccess, refetch, metaData, postData}
}

const useGetAvailableImages = () => {  
  const [ availableSignatures, setAvailableSignatures ] = useState([]);
  const [ availableLogos, setAvailableLogos ] = useState([]);
  const { data, status, error, isError, isLoading, isSuccess, refetch, postData, metaData } = useAxiosPost({
    url: 'checkAcctInfo/getAvailableImages',
    method: 'POST',
    dispatchError: false
  });

  useEffect(()=>{
    if(data){
      if(data.strImageType === 'L')
      {
        if(data && data.Icollection && data.Icollection.Images){
          if(data.Icollection.Images.constructor.name === 'Array'){
            const tempList = data.Icollection.Images.map(x=> ({label: x.abfImageName, value: x.abfImageName}))
            setAvailableLogos([{label: 'None', value:''},...tempList])
          } else if(data.Icollection.Images.constructor.name === 'Object'){
            const d = data.Icollection.Images.abfImageName
            setAvailableLogos([{label: 'None', value:''},{label: d, value: d}])
          }
        }
      } else if(data.strImageType === 'S')
      {
        if(data && data.Icollection && data.Icollection.Images){
          if(data.Icollection.Images.constructor.name === 'Array'){
            const tempList = data.Icollection.Images.map(x=> ({label: x.abfImageName, value: x.abfImageName}))
            setAvailableSignatures([{label: 'None', value:''},...tempList])
          } else if(data.Icollection.Images.constructor.name === 'Object'){
            const d = data.Icollection.Images.abfImageName
            setAvailableSignatures([{label: 'None', value:''},{label: d, value: d}])
          }
        }
      }
    }
  }, [data])
  
  return { availableSignatures, availableLogos, status, error, isError, isLoading, isSuccess, refetch, metaData, postData}
}

const useAddCheckAccount = () => {  
  const { data, status, error, isError, isLoading, isSuccess, refetch, postData, metaData } = useAxiosPost({
    url: 'checkAcctInfo/addCheckAccountInfo',
    method: 'POST',
    dispatchError: false
  });
  
  return { data, status, error, isError, isLoading, isSuccess, refetch, metaData, postData}
}

const useModifyCheckAccount = () => {  
  const { data, status, error, isError, isLoading, isSuccess, refetch, postData, metaData } = useAxiosPost({
    url: 'checkAcctInfo/updateCheckAccountInfo',
    method: 'POST',
    dispatchError: false
  });
  
  return { data, status, error, isError, isLoading, isSuccess, refetch, metaData, postData}
}

const useActivateCheckAccount = () => {  
  const { data, status, error, isError, isLoading, isSuccess, refetch, postData, metaData } = useAxiosPost({
    url: 'checkAcctInfo/updateCheckAccountInfo',
    method: 'POST',
    dispatchError: false
  });
  
  return { data, status, error, isError, isLoading, isSuccess, refetch, metaData, postData}
}

const useDectivateCheckAccount = () => {  
  const { data, status, error, isError, isLoading, isSuccess, refetch, postData, metaData } = useAxiosPost({
    url: 'checkAcctInfo/updateCheckAccountInfo',
    method: 'POST',
    dispatchError: false
  });
  
  return { data, status, error, isError, isLoading, isSuccess, refetch, metaData, postData}
}

const useGetIneligibleCodes = () => {  
  const { data, status, error, isError, isLoading, isSuccess, refetch, postData, metaData } = useAxiosPost({
    url: 'ineligibleCodes/getIneligibleCodes',
    method: 'POST',
    dispatchError: false
  });
  
  return { data, status, error, isError, isLoading, isSuccess, refetch, metaData, postData}
}

const useAddIneligibleCode = () => {  
  const { data, status, error, isError, isLoading, isSuccess, refetch, postData, metaData } = useAxiosPost({
    url: 'ineligibleCodes/addIneligibleCode',
    method: 'POST',
    dispatchError: false
  });
  
  return { data, status, error, isError, isLoading, isSuccess, refetch, metaData, postData}
}

const useUpdateIneligibleCode = () => {  
  const { data, status, error, isError, isLoading, isSuccess, refetch, postData, metaData } = useAxiosPost({
    url: 'ineligibleCodes/updateIneligibleCode',
    method: 'POST',
    dispatchError: false
  });
  
  return { data, status, error, isError, isLoading, isSuccess, refetch, metaData, postData}
}

const useGetImageTranslationDetails = (props) => {
  const [totalRecordsResp, settotalRecordsResp]=useState(0)
  const [ImageData, setImageData]=useState([])
  const [ImageGridData, setImageGridData]=useState([])
  const { data,postData, error, status, isLoading,isError, ...rest } = useAxiosPost({
    url: `imageTranslation/getImageTranslationDetails`,
    method:'POST',
    config:{
      'Content-Type':'application/json',
      'Accept':'*'
    },
    dispatchError:false
  })

  useEffect(()=>{
    if (data) {
      const {ImageCollectionWrapper}=data;
      if (ImageCollectionWrapper.collection && ImageCollectionWrapper.collection.$) {
        const {totalRecords}=ImageCollectionWrapper.collection.$
        const {ImageTranslation} = ImageCollectionWrapper.collection
        settotalRecordsResp(totalRecords)
        if(ImageTranslation) {
          if(ImageTranslation.constructor.name === "Array"){
            setImageGridData(ImageTranslation)
          } else {
            setImageGridData([ImageTranslation])
          }
        } else {
          setImageGridData([])          
        }
      }
      if (ImageCollectionWrapper.collection && ImageCollectionWrapper.Icollection.$) {
        const {Images} = ImageCollectionWrapper.Icollection
        if(Images) {
          if(Images.constructor.name === "Array"){
            const ImgArray = Images.map(x=>({label: x.abfImageName, value: x.abfImageName?x.abfImageName.split(' ')[0] : null}))
            setImageData(ImgArray)
          } else {
            const Img = {label: Images.abfImageName, value: Images.abfImageName? Images.abfImageName.split(' ')[0] : null}
            setImageData([Img])
          }
        }
      }
    }
  },[data])

  return { ImageData, ImageGridData, totalRecordsResp, getAll:postData, error, isLoading,isError, status, ...rest }
}
const useGetSelectedImageData=()=>{
  const [showMoreInfo, setshowMoreInfo]=useState(null)
  const {postData, isLoading,isError,error, data }=useAxiosPost({
    url:'imageTranslation/getSingleImage',
    method:'POST',
    onError: (e) => 
    // what happens on error? if you need something extra
    // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  
  useEffect(()=>{
    if (data && data['image-trans-info']) {
      const imageTransInfo = data['image-trans-info'];
      if(imageTransInfo && imageTransInfo.ImageTranslation) {
        const {ImageTranslation}=imageTransInfo;
        setshowMoreInfo(JSON.parse(JSON.stringify(ImageTranslation)))
      }
    } 
  },[data])
  
  return {showMoreInfo , isLoading, isError, error, postData};
}

const useAddImageTranslation=()=>{
  const {postData, isLoading,isError,error,metaData, data }=useAxiosPost({
    url:'imageTranslation/addImgTransEntry',
    method:'POST',
    onError: (e) => 
    // what happens on error? if you need something extra
    // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  
  
  return { isLoading, isError, error, postData, metaData, data};
}

const useUpdateImageTranslation=()=>{
  const {postData, isLoading,isError,error,metaData, data }=useAxiosPost({
    url:'imageTranslation/updateImageTranslation',
    method:'POST',
    onError: (e) => 
    // what happens on error? if you need something extra
    // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  
  
  return { isLoading, isError, error, postData, metaData, data};
}

const useDeleteImageTranslation=()=>{
  const {postData, metaData, isLoading,isError,error, data }=useAxiosPost({
    url:'imageTranslation/deleteImageTranslation',
    method:'POST',
    onError: (e) => 
    // what happens on error? if you need something extra
    // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  
  
  return { isLoading, isError, error, postData, metaData, data};
}

const useDeleteFormTranslation=()=>{
  const {postData, metaData, isLoading,isError,error, data }=useAxiosPost({
    url:'formTranslation/deleteFormTranslation',
    method:'POST',
    onError: (e) => 
    // what happens on error? if you need something extra
    // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  
  
  return { isLoading, isError, error, postData, metaData, data};
}


export{ useGetCheckAccountInfoRecs, useGetCheckAccountInfo, useGetAvailableImages,
  useAddCheckAccount, useModifyCheckAccount, useActivateCheckAccount, useDectivateCheckAccount,
  useGetStateCopy, useGetSelectedStateCopyData,useAddStateCopyRule, useUpdateStateCopy,
  useGetFormTranslations, useGetSelectedFormData, useGetForms, useAddFormTranslation, useUpdateFormTranslation,
  useGetDraftCodes, useGetSelectedDraftCodeData, useAddDraftCode, useUpdateDraftCode,
  useGetIneligibleCodes, useAddIneligibleCode, useUpdateIneligibleCode,
  useGetImageTranslationDetails, useGetSelectedImageData, useAddImageTranslation, useUpdateImageTranslation,
  useDeleteImageTranslation, useDeleteFormTranslation
}
