import React from 'react';
import PropTypes from 'prop-types';
import {
  Tabs,
  WordTag,
  DropdownButton,
  Button,
  Tooltip,
} from 'ui-core-ssgr';
import {
  PaddingWrapper,
  TooltipWrapper,
} from '../../../../shared/styles/spacing';
import { 
  ButtonStatus, 
  StyledDetailHeader
} from './styles';
import { TabsWrap } from '../../../../shared/styles/styledComponents';

export const DetailsHeader = ({
  isCloningGroup,
  selectedRecord,
  setIsCloningGroupState,
  toggleDetailsView,
  currentTab,
  updateCurrentTab,
  loading,
  setIsShowingActivateGroupModal,
  setIsShowingDeactivateGroupModal,
  setIsShowingCloneGroupModal,
  setSelectedDetailsRecordId,
  resetRuleTypesListReceived,
  triggerRuleTypesRequest,
  captureCurrentPage,
  isEditRole
}) => {
  const formatStatus = record => {
    const wordTagType =
      record.status === 'Active' && !isCloningGroup ? 'positive' : 'neutral';
    const wordTagLabel =
      record.status === 'Active' && !isCloningGroup ? 'ACTIVE' : 'INACTIVE';
    return <WordTag type={wordTagType} label={wordTagLabel} />;
  };

  const closeDetailsView = () => {
    if (isCloningGroup) setIsCloningGroupState(false);
  };

  const deactivateGroup = () => {
    setIsShowingDeactivateGroupModal({
      isShowingDeactivateGroupModal: true,
      isMulti: false,
      selectedGroupIds: '',
    });
  };

  const activateGroup = () => {
    const { id } = selectedRecord;
    triggerRuleTypesRequest({ groupId: id });
    setIsShowingActivateGroupModal(true);
  };

  const onDropdownListClick = event => {
    if (event.activeItem.label.toLowerCase() === 'clone') {
      setSelectedDetailsRecordId({ id: selectedRecord.id });
      setIsShowingCloneGroupModal(true);
      resetRuleTypesListReceived();
    }
  };
  const onClickTrialHandle = () =>
    window.open('/TestFileManager', '_blank');

  const renderButtonGroup = () => {
    if (selectedRecord.status === 'Active' && !isCloningGroup && isEditRole === false) {
      return (
        <Button
          name="Deactivate"
          buttonType="deEmphasized"
          size="small"
          type="button"
          onClick={() => deactivateGroup()}
          disabled={(loading && loading.size > 0) || isCloningGroup}
        />
      );
    }
    if (selectedRecord.status.toLowerCase() !== 'termed' && isEditRole === false) {
      return (
        <Button
          name="Activate"
          buttonType="emphasized"
          size="small"
          type="button"
          onClick={() => activateGroup()}
          disabled={(loading && loading.size > 0) || isCloningGroup}
        />
      );
    }

    return null;
  };

  const groupDetailHeaderText = () => {
    let title = selectedRecord.name;
    if (isCloningGroup) {
      title = `Cloning Group ${selectedRecord.name}`;
    }
    return title;
  };

  const renderHeaderTitle = () => {
    if (!isCloningGroup) {
      return (
        <Tooltip tooltipContent={`Group ID: ${selectedRecord.id}`} tooltipPosition="bottom-center" domID="tooltip-group-id">
          {groupDetailHeaderText()}
        </Tooltip>
      )
    } return groupDetailHeaderText()
  }
    
  return (
    <>
      <PaddingWrapper className="detail-pane-header">
        <StyledDetailHeader
          title={renderHeaderTitle()}
          amountKey="amount"
          inlineLabel={formatStatus(selectedRecord)}
          layoutControls={[
            {
              buttonType: 'close',
              onButtonClick: () =>
                setTimeout(() => closeDetailsView(), 500) &&
              toggleDetailsView(false),
              buttonTooltipPosition: 'top-center',
            },
          ]}
        />
      </PaddingWrapper>
      <ButtonStatus>
        {selectedRecord.status.toLowerCase() !== 'termed' && (
          <TooltipWrapper>
            <Tooltip
              domID="tooltip-trial"
              tooltipContent="Run a test file to preview your changes in a new tab. Come back to this tab when you finish the test."
              tooltipPosition="top-left"
              tooltipWidth={310}
            >
              <Button
                name="Trial"
                buttonType="deEmphasized"
                size="small"
                type="button"
                onClick={onClickTrialHandle}
                disabled={(loading && loading.size > 0) || isCloningGroup}
              />
            </Tooltip>
          </TooltipWrapper>
        )}
        {renderButtonGroup()}
        {selectedRecord.status.toLowerCase() !== 'termed' ? (
          <DropdownButton
            buttonID="Dropdown_Button"
            buttonClass="dropdown-button"
            buttonType="deEmphasized"
            size="small"
            name="more"
            onClick={() => false}
            disabled={(loading && loading.size > 0) || isCloningGroup}
            listID="Dropdown_List"
            listClass="dropdown-list"
            menuItems={[{ label: 'Clone', id: 1 }]}
            onMenuClick={(e, target) => onDropdownListClick(target)}
          />
        ) : null}
      </ButtonStatus>
      <TabsWrap>
        <Tabs
          domID="tabsWrap"
          tabs={[
            { label: 'basic', domID: 'basic-tab' },
            { label: 'test associations', domID: 'test-assoc-tab' },
            {
              label: 'commit cart',
              domID: 'commit-tab',
            },
            { label: 'live associations', domID: 'live-assoc-tab' },
          ]}
          initialTab={currentTab}
          key={currentTab}
          onTabSelect={(e, { selectedTabIndex }) => {
            let pageTrackerPrefix = e.target.innerHTML;
            pageTrackerPrefix = pageTrackerPrefix.replace(/(<([^>]+)>)/ig,"");
            // following action is called to capture new relic data
            captureCurrentPage({pageId: `${pageTrackerPrefix}`});
            updateCurrentTab(selectedTabIndex);
          }}
        />
      </TabsWrap>
    </>
  );
};

DetailsHeader.propTypes = {
  isCloningGroup: PropTypes.bool.isRequired,
  selectedRecord: PropTypes.object,
  setIsCloningGroupState: PropTypes.func.isRequired,
  setIsShowingActivateGroupModal: PropTypes.func,
  setIsShowingDeactivateGroupModal: PropTypes.func,
  currentTab: PropTypes.number,
  updateCurrentTab: PropTypes.func,
  toggleDetailsView: PropTypes.func,
  setIsShowingCloneGroupModal: PropTypes.func,
  setSelectedDetailsRecordId: PropTypes.string,
  resetRuleTypesListReceived: PropTypes.func,
  loading: PropTypes.object,
  captureCurrentPage: PropTypes.func,
  triggerRuleTypesRequest: PropTypes.func,
};

export default DetailsHeader;
