import React from 'react';
import styled from 'styled-components/macro';
import LoadingIndicator from 'ui-core/dist/LoadingIndicator';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${props => props.height}
`;

const LoadingPage = ({ height }) => (
  <Container height={height || '50vh'}>
    <LoadingIndicator />
  </Container>
);

export default LoadingPage;
