import React from 'react';
import PropTypes from 'prop-types';
import { Grid, LoadingIndicator, NotificationBanner } from 'ui-core-ssgr';
import { associatiedGroupsGridConfig } from './constants';
import ClickableRow from '../ClickableRow';
import AssociateGridHeader from '../AssociateGridHeader';
import {
  LoadingContainer,
  GridLoadingWrapper
} from '../../../../../shared/styles/styledComponents';
import './index.scss';

class AssociateGridWrapper extends React.Component {
  state = {
    selectedItemIds: new Set([]),
  };

  componentDidMount() {
    const { groupAssociationsForRuleIdTrigger, selectedRecord } = this.props;
    groupAssociationsForRuleIdTrigger(selectedRecord.id);
  }

  onGridSelectAll = e => {
    const { selectedItemIds } = this.state;
    const { groupAssociationsForRuleId } = this.props;
    const alreadyFull =
      selectedItemIds.size === groupAssociationsForRuleId.length;
    const ids = alreadyFull
      ? []
      : groupAssociationsForRuleId.map(({ id }) => id);

    this.setState({ selectedItemIds: new Set(ids) });
  };

  onRowClickThrough = e => {};

  onRowSelect = ({ id }) => {
    const { selectedItemIds } = this.state;
    const isBeingSelected = !selectedItemIds.has(id);
    const method = isBeingSelected ? 'add' : 'delete';

    selectedItemIds[method](id);

    this.setState({ selectedItemIds });
  };

  render() {
    const { selectedItemIds } = this.state;
    const { onGridSelectAll, onRowClickThrough, onRowSelect } = this;
    const {
      associatedGroupRecordsNoTermedRecords,
      isPostingRulesForRuleTypes,
      isCloningRule,
      loading,
      notifications,
      deleteNotification
    } = this.props;
    const recordCount = associatedGroupRecordsNoTermedRecords.length;

    return (
      <>
        {notifications.has('ruleDetailsError') && (
          <NotificationBanner
            domID="api-error-notification"
            type={notifications.get('ruleDetailsError').type}
            text={notifications.get('ruleDetailsError').msg}
            onDismiss={() => deleteNotification('ruleDetailsError')}
            autoDismiss
            timer={1800000}
          />
        )}
        <AssociateGridHeader
          headerName="Associated Groups"
          recordCount={recordCount}
          selectedItemIds={selectedItemIds}
          isPostingRulesForRuleTypes={isPostingRulesForRuleTypes}
          isCloningRule={isCloningRule}
        />
        {loading.has('groupAssociationsGridLoading') ? (
          <LoadingContainer>
            <GridLoadingWrapper>
              <LoadingIndicator
                domID="group-grid-loading-indicator"
                length="45px"
              />
            </GridLoadingWrapper>
          </LoadingContainer>
        ) : (
          <Grid
            {...associatiedGroupsGridConfig}
            rowComponent={ClickableRow}
            records={
              !isPostingRulesForRuleTypes && !isCloningRule
                ? associatedGroupRecordsNoTermedRecords
                : []
            }
            selectedItemIds={new Set([])}
            onClickThrough={onRowClickThrough}
            onRowSelect={onRowSelect}
            onSelectAll={onGridSelectAll}
          />
        )}
      </>
    );
  }
}

AssociateGridWrapper.propTypes = {
  associatedGroupRecordsNoTermedRecords: PropTypes.array,
  groupAssociationsForRuleIdTrigger: PropTypes.func,
  selectedRecord: PropTypes.object,
  groupAssociationsForRuleId: PropTypes.array,
};

export default AssociateGridWrapper;
