/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import styled from 'styled-components/dist/styled-components';
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import {
  Grid,
  LoadingIndicator,
  useLayout,
  Modal, ModalHeader, ModalBody
} from 'ui-core/dist'
import { LoadingPage, AnchorInput } from '../../components';
import withAxiosCancellation from '../../components/withAxiosCancellation'
import useNotificationBannerHook from '../../useNotificationBannerHook';
import ErrorPage from '../ErrorPage';
import ListPaneHeaderWrapper from '../../components/ErrorDisplaySection/ListPaneHeaderWrapper';
import GridPaginationWrapper from '../../components/GridPaginationWrapper';
import { DOCUMENT_SEARCH_RESULTS_PAGE_GRID_COLUMN } from './Modules/constants';
import useSortableGrid from '../../hooks/useSortableGrid';
import { resetRecord, setCurrentShowMoreTarget, setDocumentSearchMetadata, setRecord, setTrackingInfoAvailable } from './Modules/action';
import { usePreviewFile, useSearchDocumentsWithDetail, useGetPdfMerged, useGetPdfFile, useErrorResolution } from '../../api/useDocumentManager';
import './index.scss';

const StyledGridWrapper = styled.div`
&.GridWrapper #grid-wrapper-test-id{
  overflow:initial !important;
}
`
const StyledGrid = styled.div`
    display: grid;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 10px;
    font-size: 13px;
    & table {
      line-height: 4vh;
    }
}
`
const StyledInlineDiv = styled.div`
display: inline-block !important;
& > div {
  display: inline-block !important;
}
`
const StyledLoadingContainer = styled.div`
& > div {
  width: 35px !important;
  height: 35px !important;
  & > svg {
    width: 35px !important;
    height: 35px !important;
  }
}
`

const DocumentSearchResultsPage = ({getCancelSourceWithName}) => {
  const {
    expandCollapseListPaneButtonConfig,
    openDetailPane,
    closeDetailPane
  } = useLayout()
  const { notify } = useNotificationBannerHook()
  const history = useHistory();
  const dispatch = useDispatch()
  const DEFAULT_ROWS_PER_PAGE = 10;
  const DEFAULT_DOWNLOAD_ERR_MESSAGE = "Could not download PDF at the moment. There is no PDF File for your request";

  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE)
  const [pageIndex, setPageIndex] = useState(0);
  const [isOpenResolutionModal, setisOpenResolutionModal] = useState(false);
  const [selectedRecord,setSelectedRecord]=useState('')

  const [downloadTrackConfig, setDownloadTrackConfig] = useState(null);

  const [claimNumberDownloadTrack, setclaimNumberDownloadTrack] = useState({
    documentKey: null,
    filename: null
  })

  const [errorResolutionConfig, setErrorResolutionConfig] = useState(null);

  const { file, previewFile, refetchPreviewFile, isError: isErrorPreviewFile, error: errorPreviewFile, isLoading: isLoadingPreviewFile } = usePreviewFile(setclaimNumberDownloadTrack);
  const { file: copyNumberFile, getPdfMerged, refetchGetPdfMerged, isError: isErrorPdfMerged, error: errorPdfMerged, isLoading: isLoadingPdfMerged } = useGetPdfMerged(setDownloadTrackConfig);
  const { file: copyNumberFile2, getPdfFile, refetchGetPdfFile, isError: isErrorPdfFile, error: errorPdfFile, isLoading: isLoadingPdfFile } = useGetPdfFile(setDownloadTrackConfig);

  const { data: errorResolutionData, refetchErrorResolution, getErrorResolution, isLoading: isLoadingErrorResolution } = useErrorResolution(setErrorResolutionConfig);

  const { searchParams, documentSearchMetaData, currentShowMoreTarget }
    = useSelector(({ documentSearch }) => documentSearch);
  const canView = useSelector(({ context }) =>
    context?.permissions?.filter(p => p.name === 'CAN_PERFORM_DOC_PDF_VIEW').length > 0)

  useEffect(() => {
    if (postData) {
      closeDetailPane();
      dispatch(resetRecord());
      postData(JSON.parse(JSON.stringify({
        ...searchParams,
        "maxRecords": rowsPerPage,
        "rowPosition": pageIndex,
        "sortField": sortConfig.sortDataName,
        "sortDescending": sortConfig.sortDescending,
      })))
    }
  }, [])

  useEffect(() => {
    if (errorResolutionData) {
      setisOpenResolutionModal(true);
    }
  }, [errorResolutionData])

  useEffect(() => {
    if (isErrorPreviewFile) {
      notify(errorPreviewFile?.displayMessage || DEFAULT_DOWNLOAD_ERR_MESSAGE, 'negative');
    }
    if (isErrorPdfMerged) {
      notify(errorPdfMerged?.displayMessage || DEFAULT_DOWNLOAD_ERR_MESSAGE, 'negative')
    }
    if (isErrorPdfFile) {
      notify(errorPdfFile?.displayMessage || DEFAULT_DOWNLOAD_ERR_MESSAGE, 'negative')
    }
  }, [isErrorPreviewFile, isErrorPdfMerged, isErrorPdfFile, errorPreviewFile, errorPdfMerged, errorPdfFile])

  const fetchData = useCallback((args) => {
    let sortcolumn; let sd; let pi;
    if (args) {
      ({ sortcolumn, sd, pi } = args);
    }
    closeDetailPane();
    dispatch(resetRecord());
    setSelectedRecord({})
    postData(JSON.parse(JSON.stringify({
      ...searchParams,
      "maxRecords": rowsPerPage,
      "rowPosition": pi === undefined || pi === null ? pageIndex : pi,
      "sortField": sortcolumn === undefined || sortcolumn === null ? sortConfig.sortDataName : sortcolumn,
      "sortDescending": sd === undefined || sd === null ? sortConfig.sortDescending : sd,
    })))

  }, [rowsPerPage, pageIndex, sortConfig]);
  const [sortConfig, getArgumentsAndSortGrid] = useSortableGrid(fetchData, "importDate", "true");
  const { totalRecords, records, postData, isLoading, isError, error } = useSearchDocumentsWithDetail((dmetadata) => {
    dispatch(setDocumentSearchMetadata(JSON.parse(JSON.stringify(dmetadata))))
  }, getCancelSourceWithName('searchDocumentsWithDetail'))

  const onShowMoreClick = useCallback((record, index, currentShowMoreTargetProps) => {
    dispatch(setRecord({ record: JSON.parse(JSON.stringify(record)), index, currentShowMoreTarget: currentShowMoreTargetProps }));
    setSelectedRecord(record)
    openDetailPane();
  }, [records]);

  useEffect(() => {
    if (file && claimNumberDownloadTrack.filename) {
      downloadFile(file, 'claimNumber')
    }
  }, [file])

  useEffect(() => {
    if (copyNumberFile && downloadTrackConfig.filename) {
      downloadFile(copyNumberFile, 'copyNumber')
    }
  }, [copyNumberFile])

  useEffect(() => {
    if (copyNumberFile2 && downloadTrackConfig.filename) {
      downloadFile(copyNumberFile2, 'copyNumber')
    }
  }, [copyNumberFile2])

  const downloadFile = (f, target) => {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
      window.navigator.msSaveOrOpenBlob(f, target === "claimNumber" ? claimNumberDownloadTrack.filename : downloadTrackConfig.filename);
    } else {
      const FILE = window.URL.createObjectURL(f);
      const docUrl = document.createElement('a');
      docUrl.href = FILE;
      docUrl.setAttribute('download', target === "claimNumber" ? claimNumberDownloadTrack.filename : downloadTrackConfig.filename);
      document.body.appendChild(docUrl);
      docUrl.click();
      document.body.removeChild(docUrl);
    }
  }

  const renderClaimNumberDisplay = (bPreviewLink, bRelatedKeys, claimNumber, documentKey, relKeys) => {
    if (claimNumber) {
      if (claimNumber.constructor.name === "Array" && claimNumber.length > 1) {
        if (bPreviewLink === 'True') {
          if (bRelatedKeys === 'True') {
            return (
              <StyledLoadingContainer>
                <>
                  {isLoadingPreviewFile && claimNumberDownloadTrack?.documentKey === documentKey ? <LoadingIndicator /> :
                    <AnchorInput
                      id={`claimNumber_multiple_${documentKey}`}
                      type='button'
                      className='fileKeyLink'
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if(canView)
                        {
                          if (documentKey !== claimNumberDownloadTrack.documentKey) {
                            previewFile({
                              payload: { documentKey, relKeys }, config: JSON.parse(JSON.stringify({
                                documentKey,
                                filename: 'printPreview.pdf'
                              }))
                            });
                          } else {
                            refetchPreviewFile()
                          }
                        }else{
                          notify('You do not have required permission to perform this function','negative')
                        }
                        
                      }}
                    >multiple</AnchorInput>
                  }
                </>
              </StyledLoadingContainer>
            )
          }
          return (
            <StyledLoadingContainer>
              <>
                {isLoadingPreviewFile && claimNumberDownloadTrack?.documentKey === documentKey ? <LoadingIndicator /> :
                  <AnchorInput
                    id={`claimNumber_multiple_${documentKey}`}
                    type='button'
                    className='fileKeyLink'
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if(canView) {
                        if (documentKey !== claimNumberDownloadTrack.documentKey) {
                          previewFile({
                            payload: { documentKey, relKeys: "none" }, config: JSON.parse(JSON.stringify({
                              documentKey,
                              filename: 'printPreview.pdf'
                            }))
                          });
                        } else {
                          refetchPreviewFile()
                        }
                      }
                      else {
                        notify('You do not have required permission to perform this function','negative')
                      }
                    }}
                  >multiple</AnchorInput>
                }
              </>
            </StyledLoadingContainer>
          )
        }
        return <span>multiple</span>
      }
      if (bPreviewLink === 'True') {
        if (bRelatedKeys === 'True') {
          return (
            <StyledLoadingContainer>
              <>
                {isLoadingPreviewFile && claimNumberDownloadTrack?.documentKey === documentKey ? <LoadingIndicator /> :
                  <AnchorInput
                    id={`claimNumber_${claimNumber}_${documentKey}`}
                    type='button'
                    className='fileKeyLink'
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if(canView) {
                        if (documentKey !== claimNumberDownloadTrack.documentKey) {
                          previewFile({
                            payload: { documentKey, relKeys }, config: JSON.parse(JSON.stringify({
                              documentKey,
                              filename: 'printPreview.pdf'
                            }))
                          });
                        } else {
                          refetchPreviewFile()
                        }
                      } else {
                        notify('You do not have required permission to perform this function','negative')
                      }
                      
                    }}
                  >{claimNumber}</AnchorInput>
                }
              </>
            </StyledLoadingContainer>
          )
        }
        return (
          <StyledLoadingContainer>
            <>
              {isLoadingPreviewFile && claimNumberDownloadTrack?.documentKey === documentKey ? <LoadingIndicator /> :
                <AnchorInput
                  id={`claimNumber_${claimNumber}_${documentKey}`}
                  type='button'
                  className='fileKeyLink'
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if(canView)
                     {
                      if (documentKey !== claimNumberDownloadTrack.documentKey) {
                        previewFile({
                          payload: { documentKey, relKeys: "none" }, config: JSON.parse(JSON.stringify({
                            documentKey,
                            filename: 'printPreview.pdf'
                          }))
                        });
                      } else {
                        refetchPreviewFile()
                      }
                    }
                    else {
                      notify('You do not have required permission to perform this function','negative')
                    }
                  }}
                >{claimNumber}</AnchorInput>
              }
            </>
          </StyledLoadingContainer>
        )
      }
      return <span>{claimNumber}</span>
    }
    return null;
  }
  const renderCopyNumbers = (hasPdf, copyNumber, bRelatedKeys, noaccess, relKeys, documentKey,
    jobNumber, envelopeNumber, startPage, hashValue, multiplePDF) => {
    let mergeCopyArray = [];
    let mergePDF = '';
    let mergeCopy = '';
    if (multiplePDF) {
      if (multiplePDF.constructor.name === "Array") {
        multiplePDF.forEach((mpdf) => {
          ({ mergePDF, mergeCopy } = mpdf)
          if ((mergePDF === 'true' || mergePDF === 'false') && mergeCopy) {
            if (mergeCopy.constructor.name === "Array") {
              mergeCopyArray = [...mergeCopy]
            } else if (mergeCopy.constructor.name === "String") {
              mergeCopyArray.push(mergeCopy);
            }
          }
        })
      } else {
        ({ mergePDF, mergeCopy } = multiplePDF)
        if ((mergePDF === 'true' || mergePDF === 'false') && mergeCopy) {
          if (mergeCopy.constructor.name === "Array") {
            mergeCopyArray = [...mergeCopy]
          } else if (mergeCopy.constructor.name === "String") {
            mergeCopyArray.push(mergeCopy);
          }
        }
      }
    }
    const renderMergePdfs = (merge) => {
      if (mergePDF === 'true' && bRelatedKeys === "False") {
        return (
          <StyledLoadingContainer>
            <>
              {isLoadingPdfMerged && (downloadTrackConfig?.documentKey === documentKey && downloadTrackConfig?.relKeys === "none"
                && downloadTrackConfig?.copyNumber === merge) ? <LoadingIndicator /> :
                <>
                  <AnchorInput
                    id={`copyNumber_${merge}_${documentKey}`}
                    type='button'
                    className='fileKeyLink'
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      const config = {
                        documentKey,
                        relKeys: 'none',
                        copyNumber: merge,
                        filename: 'claimSearch.pdf'
                      };
                      if (JSON.stringify(config) !== JSON.stringify(downloadTrackConfig)) {
                        getPdfMerged({ payload: { dk: documentKey, rk: "none", cp: merge }, config: JSON.parse(JSON.stringify(config)) });
                      } else {
                        refetchGetPdfMerged()
                      }
                    }}
                  >{merge}</AnchorInput>&nbsp;&nbsp;
                </>
              }
            </>
          </StyledLoadingContainer>
        )
      }
      if (mergePDF === 'true' && bRelatedKeys === "True") {
        return (
          <StyledLoadingContainer>
            <>
              {isLoadingPdfMerged && (downloadTrackConfig?.documentKey === documentKey && downloadTrackConfig?.relKeys === relKeys
                && downloadTrackConfig?.copyNumber === merge) ? <LoadingIndicator /> :
                <>
                  <AnchorInput
                    id={`copyNumber_${merge}_${documentKey}`}
                    type='button'
                    className='fileKeyLink'
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      const config = {
                        documentKey,
                        relKeys,
                        copyNumber: merge,
                        filename: 'claimSearch.pdf'
                      };
                      if (JSON.stringify(config) !== JSON.stringify(downloadTrackConfig)) {
                        getPdfMerged({ payload: { dk: documentKey, rk: relKeys, cp: merge }, config: JSON.parse(JSON.stringify(config)) });
                      } else {
                        refetchGetPdfMerged()
                      }
                    }}
                  >{merge}</AnchorInput>&nbsp;&nbsp;
                  <p>** This link will display a consolidated PDF document for
      the super document and its related keys.</p>&nbsp;&nbsp;
                </>
              }
            </>
          </StyledLoadingContainer>
        )
      }
      if (mergePDF !== 'true') {
        return (
          <><span>{merge}</span>&nbsp;&nbsp;</>
        )
      }
      return null;
    }

    if (mergeCopyArray.constructor.name === "Array" && mergeCopyArray.length > 0) {
      return (
        <>
          {mergeCopyArray.map((merge) => (
            <>
              <StyledInlineDiv>
                {renderMergePdfs(merge)}
              </StyledInlineDiv>
            </>
          ))}
        </>
      )
    }
    // if (copyNumber.constructor.name==="Array" && copyNumber.length>1) {

    // }
    if (bRelatedKeys === "True" && hasPdf === "true") {
      return (
        <StyledLoadingContainer>
          <>
            {isLoadingPdfMerged && (downloadTrackConfig?.documentKey === documentKey && downloadTrackConfig?.relKeys === relKeys
              && downloadTrackConfig?.copyNumber === copyNumber) ? <LoadingIndicator /> :
              <>
                <AnchorInput
                  id={`copyNumber_${copyNumber}_${documentKey}`}
                  type='button'
                  className='fileKeyLink'
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    const config = {
                      documentKey,
                      relKeys,
                      copyNumber,
                      filename: 'claimSearch.pdf'
                    };
                    if (JSON.stringify(config) !== JSON.stringify(downloadTrackConfig)) {
                      getPdfMerged({ payload: { dk: documentKey, rk: relKeys, cp: copyNumber }, config: JSON.parse(JSON.stringify(config)) });
                    } else {
                      refetchGetPdfMerged()
                    }
                  }}
                >{copyNumber}</AnchorInput>
                <p>** This link will display a consolidated PDF document for the super document and its related keys.
                </p>
              </>
            }
          </>
        </StyledLoadingContainer>
      )
    }
    if (bRelatedKeys === "False" && hasPdf === 'true') {
      if (noaccess !== "*") {
        return (
          <StyledLoadingContainer>
            <>
              {isLoadingPdfFile && (downloadTrackConfig?.jobNumber === jobNumber && downloadTrackConfig?.envelopeNumber === envelopeNumber
                && downloadTrackConfig?.startPage === startPage && downloadTrackConfig?.hashValue === hashValue) ? <LoadingIndicator /> :
                <AnchorInput
                  id={`copyNumber_${copyNumber}_${documentKey}`}
                  type='button'
                  className='fileKeyLink'
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    const config = {
                      jobNumber,
                      envelopeNumber,
                      startPage,
                      hashValue,
                      filename: 'claimSearch.pdf'
                    };
                    if (JSON.stringify(config) !== JSON.stringify(downloadTrackConfig)) {
                      getPdfFile({ payload: { jn: jobNumber, en: envelopeNumber, sp: startPage, ex: hashValue }, config: JSON.parse(JSON.stringify(config)) });
                    } else {
                      refetchGetPdfFile()
                    }
                  }}
                >{copyNumber}</AnchorInput>
              }
            </>
          </StyledLoadingContainer>
        )
      }
      if (noaccess === "*") {
        return (
          <span>{copyNumber} No Access</span>
        )
      }
    }
    if (hasPdf !== 'true') {
      return (
        <span>{copyNumber}</span>
      )
    }
    return null;
  }
  const renderWheresMyPackage = (record, trackingInfo, documentKey, status, index) => {
    let field = '';
    if (trackingInfo && status && (!status.includes('Ready to') && !status.includes('Scheduled to')
      && !status.includes('Held') && !status.includes('Errored') && !status.includes('Purged'))) {
      if (trackingInfo.trackingNumber
        && trackingInfo.trackingNumber === 'Show USPS Confirm Tracking') {
        field = <AnchorInput
          id={`trackPackage_${documentKey}`}
          type='button'
          className='fileKeyLink'
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            if (currentShowMoreTarget === "showMore") {
              dispatch(setTrackingInfoAvailable({ trackingInfoAvailable: true, trackingInfoText: null }))
              return dispatch(setCurrentShowMoreTarget('trackPackage'))
            }
            if (onShowMoreClick) {
              dispatch(resetRecord())
              dispatch(setTrackingInfoAvailable({ trackingInfoAvailable: true, trackingInfoText: null }))
              return onShowMoreClick(record, index, 'trackPackage');
            }
            return false;
          }}
        >{renderShowMoreText(record, "trackPackage")}</AnchorInput>
      } else if (trackingInfo.bDataNotAvailable) {
        field = 'Tracking Details (Data Not Available)'
      } else if (trackingInfo.bNotSubscribed) {
        field = 'Tracking Details (Not Subscribed)'
      } else if (trackingInfo.trackingNumber === 'multiple' || trackingInfo.trackingNumber === 'Multiples') {
        field = 'multiple';
      } else if (trackingInfo.trackingNumber === 'Not Subscribed') {
        field = 'Tracking Details (Not Subscribed)';
      } else if (trackingInfo.trackingNumber === 'No Data Found') {
        field = 'Tracking Details (Data Not Available)';
      } else if (trackingInfo.deliveryType === 'non-confirm') {
        field = trackingInfo.nonconfirm
      } else if (trackingInfo.trackingURL) {
        field = <AnchorInput
          id={`trackPackage_${documentKey}`}
          type='button'
          className='fileKeyLink'
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            if (currentShowMoreTarget === "showMore") {
              dispatch(setTrackingInfoAvailable({ trackingInfoAvailable: true, trackingInfoText: null }))
              return dispatch(setCurrentShowMoreTarget('trackPackage'))
            }
            if (onShowMoreClick) {
              dispatch(resetRecord())
              dispatch(setTrackingInfoAvailable({ trackingInfoAvailable: true, trackingInfoText: null }))
              return onShowMoreClick(record, index, 'trackPackage');
            }
            return false;
          }}
        >{renderShowMoreText(record, "trackPackage")}</AnchorInput>
      }
    }
    return field;
  }
  const renderStatus = (status, documentKey, dateTimeReleased) => {
    let className;
    switch (status) {
      case 'Imported':
        className = 'status-ok';
        break;
      case 'Released':
        className = 'status-ok';
        break;
      case 'Ready to print':
        className = 'status-ok';
        break;
      case 'Held':
        className = 'status-warn';
        break;
      case 'Held and Errored':
        className = 'status-error';
        break;
      case 'Errored':
        className = 'status-error';
        break;
      case 'Purged':
        className = 'status-error';
        break;
      default:
        break;
    }
    if (status.trim() === "Errored") {
      return (
        <div className={className}>
          <StyledLoadingContainer>
            {isLoadingErrorResolution && errorResolutionConfig?.documentKey === documentKey ? <LoadingIndicator /> :
              <AnchorInput
                id={`status_${status}_${documentKey}`}
                type='button'
                className='fileKeyLink'
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  const config = {
                    documentKey
                  };
                  if (JSON.stringify(config) !== JSON.stringify(errorResolutionConfig)) {
                    getErrorResolution({ payload: { pstrDocKey: documentKey }, config: JSON.parse(JSON.stringify(config)) });
                  } else {
                    refetchErrorResolution()
                  }
                }}
              >{status}</AnchorInput>
            }
          </StyledLoadingContainer>
        </div>
      )
    }
    if (status.indexOf("Archive") !== -1) {
      return (
        <div className={className}>
          <span>(Archived) Released on {dateTimeReleased}</span>
        </div>
      )
    }
    if (status.indexOf("Released on") !== -1) {
      return (
        <div className={className}>
          <span>Released on {dateTimeReleased}</span>
        </div>
      )
    }
    return (
      <div className={className}>
        <span>{status}</span>
      </div>
    )
  }
  const renderShowMoreText = (record, target) => {
    const { showMoreColumnIdentifier } = DOCUMENT_SEARCH_RESULTS_PAGE_GRID_COLUMN;
    if (target === "showMore") {
      if (currentShowMoreTarget === target) {
        if (record[showMoreColumnIdentifier]) {
          return <span>Hide</span>
        }
        return <span>Show</span>
      }
      return <span>Show</span>
    }
    if (target === "trackPackage") {
      if (currentShowMoreTarget === target) {
        if (record[showMoreColumnIdentifier]) {
          return <span>Hide Tracking Details</span>
        }
        return <span>Tracking Details</span>
      }
      return <span>Tracking Details</span>
    }
    return null;
  }
  const renderMainGrid = useMemo(() => {

    if (records && records.length > 0) {
      const temp = JSON.parse(JSON.stringify(records));
      const data = temp.map((d, index) => {
        let bPreviewLink;
        let bRelatedKeys;
        let relKeys;
        let documentKey;
        let noaccess;
        let releaseInformation;
        let trackingInfo;
        let jobNumber;
        let envelopeNumber;
        let startPage;
        let copyNumber;
        let hasPdf;
        const { $ } = d;
        if ($) {
          bPreviewLink = $.bPreviewLink;
          bRelatedKeys = $.bRelatedKeys;
          relKeys = $.relKeys;

          documentKey = d.documentKey;
          noaccess = d.noaccess;
          releaseInformation = d.releaseInformation;
          trackingInfo = d.trackingInfo;

          if (releaseInformation && releaseInformation.item) {
            let item;
            if (releaseInformation.item.constructor.name === "Array") {
              item = releaseInformation.item[0];
            } else if (releaseInformation.item.constructor.name === "Object") {
              item = { ...releaseInformation.item };
            }
            if (item) {
              ({ number: jobNumber } = item.job);
              hasPdf = item?.job?.$?.hasPdf;
              if (item.job.envelope) {
                ({ number: envelopeNumber, startPage, copyNumber } = item.job.envelope);
              }
            }
          }
        }
        let colDomId = "";
        const getRandomInt = (max) => Math.floor(Math.random() * Math.floor(max));
        const uniqueNo = getRandomInt(99999999999).toString();
        colDomId = `${uniqueNo}`;
        const claimNumber = d.claimNumbers && d.claimNumbers.length > 0 ?
          d.claimNumbers[0].claimNumber : '';
        const wheresMyPackage = renderWheresMyPackage(d, d.trackingInfo, documentKey, d?.status, index);
        return {
          documentKey: d.documentKey,
          importDate: d.ImportDate,
          paymentIdentifier: d.CardTokenID,
          checkNumber: d.checkNumber,
          checkAmount: d.checkAmount,

          claimNumber,
          claimNumberDisplay: renderClaimNumberDisplay(bPreviewLink, bRelatedKeys, claimNumber
            , documentKey, relKeys),

          claimType: d.claimTypeDescription,
          documentType: d.relationCodeDescription,
          recipientType: d.recipientTypeDescription,

          copyNumbers: copyNumber || "",
          copyNumbersDisplay: renderCopyNumbers(hasPdf || "",
            copyNumber || "",
            bRelatedKeys, noaccess, relKeys, documentKey,
            jobNumber, envelopeNumber, startPage, documentSearchMetaData?.hashValue || "",
            d?.multiplePDFs?.multiplePDF || ""),

          wheresMyPackage,
          status: renderStatus(d?.status, documentKey, releaseInformation?.dateTimeReleased),
        }
      })
      return (
        <>
          <div className='marginLeft' style={{marginLeft:'40px'}}>
          <GridPaginationWrapper
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={setRowsPerPage}
            pageIndex={pageIndex}
            onChangePageIndex={setPageIndex}
            onShowButtonCLick={fetchData}
            totalRecords={totalRecords}
            defaultRowsPerPage={DEFAULT_ROWS_PER_PAGE}
          />
          </div>
          <StyledGridWrapper className='GridWrapper'>
            <Grid
              {...DOCUMENT_SEARCH_RESULTS_PAGE_GRID_COLUMN}
              dataTestId="DocumentManagerSearchGrid"
              selectionKey="documentKey"
              records={data?.map(record => {
                if(record?.documentKey) {
                  return {
                    ...record,
                    documentKey: (
                      <AnchorInput
                        id='fileKey'
                        type='button'
                        className='fileKeyLink'
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          if (record) {
                            const info = records.find(x => x.documentKey === record.documentKey);
                            const i = records.map(x => x.documentKey).indexOf(record.documentKey);
                            const { wheresMyPackage } = record;
                            let trackingInfoAvailable;
                            let trackingInfoText;
                            if (wheresMyPackage.constructor.name === "String") {
                              trackingInfoAvailable = false;
                              trackingInfoText = wheresMyPackage
                            } else if (wheresMyPackage.constructor.name === "Object") {
                              trackingInfoAvailable = true;
                              trackingInfoText = null
                            }
                            if (onShowMoreClick) {
                              dispatch(resetRecord())
                              dispatch(setTrackingInfoAvailable({ trackingInfoAvailable, trackingInfoText }))
                              return onShowMoreClick(info, i, 'showMore');
                            }
                            return false;
                          }
                          return false;
                        }}
                  >
                    {record.documentKey}
                  </AnchorInput>
                    )
                  }
                }
              }) || []}
              // onClickThrough={(e, rec) => {
              //   e.preventDefault();
              //   e.stopPropagation();
              //   if (rec) {
              //     const record = records.find(x => x.documentKey === rec.documentKey);
              //     const i = records.map(x => x.documentKey).indexOf(rec.documentKey);
              //     const { wheresMyPackage } = rec;
              //     let trackingInfoAvailable;
              //     let trackingInfoText;
              //     if (wheresMyPackage.constructor.name === "String") {
              //       trackingInfoAvailable = false;
              //       trackingInfoText = wheresMyPackage
              //     } else if (wheresMyPackage.constructor.name === "Object") {
              //       trackingInfoAvailable = true;
              //       trackingInfoText = null
              //     }
              //     if (onShowMoreClick) {
              //       dispatch(resetRecord())
              //       dispatch(setTrackingInfoAvailable({ trackingInfoAvailable, trackingInfoText }))
              //       return onShowMoreClick(record, i, 'showMore');
              //     }
              //     return false;
              //   }
              //   return false;
              // }}
              // focusedItem={selectedRecord}
              initialSortingKey={`${sortConfig.sortDataName}|${sortConfig.sortDescending === "true" ? 'SORT_DESCENDING' : 'SORT_ASCENDING'}`}
              onSortGridColumn={(e, state) => getArgumentsAndSortGrid(state)}
              sortingComparator={(sortDataName, sortDirectionString, recordsToSort) => recordsToSort}
            />
          </StyledGridWrapper>
        </>
      )
    }
    return null;
  }, [records, rowsPerPage, pageIndex, currentShowMoreTarget, claimNumberDownloadTrack, downloadTrackConfig, errorResolutionConfig,
    isLoadingErrorResolution, isLoadingPreviewFile, isLoadingPdfMerged, isLoadingPdfFile, selectedRecord]);

  return (
    <>
      <ListPaneHeaderWrapper
        title="Document Manager: Search Documents"
        layoutControls={[expandCollapseListPaneButtonConfig]}
      />
      {isLoading ? (
        // replace with a better loading component
        <LoadingPage />
      ) : isError ? (
        // replace with a better error component
        <ErrorPage
          // this prop isn't real but is an example
          error={error}
        />
      ) : (
        <>
          <div className="marginLeft" style={{ marginLeft: '40px' }}>
            <div className="displayFlex">
              <label>The following documents matched your search criteria. Click the record to view additional document details. If PDF output and tracking information are available, links to this information are displayed in the document details.</label>
            </div>
            <div className="displayFlex">
              <label style={{ color: 'red' }}>If the value in the claim number column shows up as a link, the document is ready for pre-release preview. Any updates to group rules, forms or images can affect the final output.</label>
            </div>
            <div className="displayFlex">
              <AnchorInput
                id='documentSearchNavigation'
                type='button'
                className='fileKeyLink'
                onClick={() => { history.push('/DocumentManager/Search') }}
              >[New Search]</AnchorInput>
            </div>
            {records && records.length === 0 && totalRecords === 0 ? (
              <div className="displayFlex">
                <label style={{ color: 'red' }}>No records found. Change search criteria.</label>
              </div>
            ) : null}
          </div>
          {renderMainGrid}
          <Modal isOpen={isOpenResolutionModal}>
            <ModalHeader
              title="Error Resolution"
              onClose={() => setisOpenResolutionModal(false)}
            />
            <ModalBody>
              {isOpenResolutionModal ? (
                <div className="marginLeft">
                  <StyledGrid>
                    {errorResolutionData && errorResolutionData.errorResolution ? (
                      <>
                        {errorResolutionData?.errorResolution?.error ? (
                          <table className="common-table">
                            {errorResolutionData.errorResolution.error.errorID ? (
                              <tr >
                                <td className="name"><label>Error Id:</label></td>
                                <td>{errorResolutionData.errorResolution.error.errorID}</td>
                              </tr>
                            ) : null}
                            <tr >
                              <td className="name"><label>Error Code:</label></td>
                              <td>{errorResolutionData.errorResolution.error.errorCode}</td>
                            </tr>
                            <tr >
                              <td className="name"><label>Severity:</label></td>
                              <td>{errorResolutionData.errorResolution.error.severity}</td>
                            </tr>
                            <tr >
                              <td className="name"><label>Error Description:</label></td>
                              <td>{errorResolutionData.errorResolution.error.description}</td>
                            </tr>
                            <tr >
                              <td className="name"><label>Resolution:</label></td>
                              <td>{errorResolutionData.errorResolution.error.resolution}</td>
                            </tr>
                          </table>
                        ) : null}
                        {errorResolutionData?.errorResolution?.error?.errorInfo ? (
                          <table className="common-table">
                            <tr >
                              <td className="name" style={{ width: '143px' }}><label>Error Information:</label></td>
                              <td className="value">{errorResolutionData?.errorResolution?.error?.errorInfo}</td>
                            </tr>
                          </table>
                        ) : (
                          <table className="common-table">
                            <tr >
                              <td>ERROR: Cannot retrieve error detail information</td>
                            </tr>
                          </table>
                        )}
                      </>
                    ) : (
                      <table className="common-table">
                        <tr >
                          <td>ERROR: Cannot retrieve error resolution information.</td>
                        </tr>
                      </table>
                    )}
                  </StyledGrid>
                </div>
              ) : null}
            </ModalBody>
          </Modal>
        </>
      )}
    </>
  )
}

export default withAxiosCancellation(DocumentSearchResultsPage)