/**
 * This is the entry file for the application.
 */

// Import all the third party stuff
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'focus-visible';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'sanitize.css/sanitize.css';
import 'intersection-observer';
import DragProvider from 'ui-core/dist/DragProvider';
import { PersistGate } from 'redux-persist/integration/react';
import registerServiceWorker from './registerServiceWorker';

import './chc-new-logo.png';
import './.htaccess'; // eslint-disable-line import/extensions

// Import views
import ErrorBoundary from './ErrorBoundary';

import configureStore from './configureStore';
import GlobalStyle from './global-styles';
import { fonts } from './commonResources/constants/fonts';

// Import all application routes
import { history } from './applicationRoutes';
import BootstrapComponent from './boostrapComponent'

import './assets/style.scss';
// if (integrations.ciam) {
//   initializeConfig(process.env.NODE_ENV, integrations.ciam);
// }

// if (integrations.notifications) {
//   initializeNotifications(integrations.notifications);
// }

// Add promise support to the global window object if not found
if (!window.Promise) window.Promise = Promise;

// Create redux store with history
const initialState = {};
export const {store, persistor} = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById('app');

const loadFonts = () => {
  const head = document.getElementsByTagName('head')[0];
  const style = document.createElement('style');
  style.innerHTML = fonts;
  head.appendChild(style);
};

const render = () => {
  ReactDOM.render(
    <ErrorBoundary>
      <GlobalStyle />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <DragProvider>
            <BootstrapComponent/>
          </DragProvider>
        </PersistGate>
      </Provider>
    </ErrorBoundary>,
    MOUNT_NODE,
    loadFonts,
  );
};

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./containers/PageWrapper'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render();
  });
}
render();

registerServiceWorker();
