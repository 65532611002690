export const detailProps = {
  columns: new Set([
    {
      dataName: 'ruleTypeName',
      text: 'Rule Type',
    },
    {
      dataName: 'name',
      text: 'Rule Name',
    },
    {
      dataName: 'associatedRuleName',
      text: 'Current Test Associated Rule',
    },
  ]),
  isDragOver: '',
  selectedItemIds: new Set([]),
  selectionKey: 'id',
  domID: 'associate-multiple-rule-grid',
};
  