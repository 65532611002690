import React from 'react';
import {
  Checkbox,
} from 'ui-core';
import { isA11yClick } from '../commonResources/helpers/domEvents';
import { floatToDollarString } from '../commonResources/helpers/money';
import { mapIterable } from '../commonResources/helpers/iterables';

export const onKeyPress = (e, callback) => {
  if (isA11yClick(e)) {
    e.preventDefault();
    callback(e);
  }
};

export const onlyUnique=(value, index, self)=> {
  return self.indexOf(value) === index;
}
  
export const renderShowMorePanel=(props,record)=>{
  const {showMoreColumnComponentIdentifier}=props;
  if (!showMoreColumnComponentIdentifier) {
    return (
      <div style={{paddingTop:'10px',paddingBottom:'10px'}}>
        {mapIterable(props.showMoreColumns.values(), (c) => {
          const {dataName,text}=c;
          const disabled=props.disabledColumns.has(dataName);
          if (!disabled) {
            let entry=record[dataName];
            if (c.isCurrency) {
              entry=floatToDollarString(entry);
            }
            return (
              <>
                {text}: {entry}
                <br/>
              </>
            )
          }
          return null;
        })}
      </div>
    );  
  }
  const entry=record[showMoreColumnComponentIdentifier];
  return (
    <div style={{paddingTop:'10px',paddingBottom:'10px'}}>
      {entry}
    </div>
  )
};

export const renderSelectionHeaderCol=(record,index,props,length,showmorelength)=>{
  if (index===0) {
    if (props.supportSelection) {
      const l=(length||0)+(showmorelength||0);
      return (
        <th rowSpan={l} scope="rowgroup" 
          className="select-checkbox" key="select column">
  
          {!record.is_template_indicator ?
            (
              <Checkbox
                name='disableCheckBox'
                disabled
                className="table-row-checkbox"
                onClick={e => {
                  if ('preventDefault' in e) e.preventDefault();
                  if ('stopPropagation' in e) e.stopPropagation();
                  return false;
                }}
                stopClickPropagation
              />
            ) : (
              <Checkbox
                name='enableCheckBox'
                className="table-row-checkbox"
                onChange={e => props.onRowSelect(e, record)}
                onClick={e => {
                  if ('preventDefault' in e) e.preventDefault();
                  if ('stopPropagation' in e) e.stopPropagation();
                  return false;
                }}
                value={props.isSelected}
                stopClickPropagation
              />
            )}
        </th>
      );
    }
    return null;
  }
  return <th style={{display:"none"}}/>;
}
  
export const renderHeaderCol=(length,index,key,value,showmorelength)=>{
  if (index===0) {
    const l=(length||0)+(showmorelength||0);
    return (<th key={key} className={key} style={{background:'#13136c', color:'white'}}
      rowSpan={l} scope="rowgroup">{value}</th>);
  }
  return <th style={{display:"none"}}/>;
}

export function getRandomColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}