import styled from 'styled-components';
export const ContainerBox = styled.div`
  display: flex;
  margin-top: 30px;
  margin-bottom: 30px;
  position: relative;
  & > div > div > span {
    width: 470px;
  }
`;
export const CopyNumberCaption = styled.div`
  display: flex;
  margin-top: 28px;
  position: relative;
  margin-left: -30px;
  font-size: 11px;
`;
