import { useState, useEffect } from 'react';
import { useAxiosPost } from '../hooks'

const trimURL = (url) => {
  let Uri = '';
  let fName = '';
  if(url) {
    const ar = url.split('?');
    if (ar.length > 1) {
      Uri = ar[0];
      fName = ar[1].split('=')[1];
    }
  }
  return { Uri, fName };
};

const useResendFileReceipt = () => {
  const [dataResp, setDataResp] = useState(false);
  const { data, status, error, isError, isLoading, isSuccess, refetch, postData, metaData } = useAxiosPost({
    url: 'fileReceiptManager/resendFileReceipt',
    method: 'POST',
    config:{
      'Content-Type':'application/json',
      'Accept':'*'
    },
  });
  
  useEffect(() => {
    if (data && (data.resp || data.resp === '')){
      setDataResp(`You have successfully re-sent the file receipt to "${data.resp}"`);
    } else if(data) {
      setDataResp(true)
    }
  }, [data])

  return { data: dataResp, status, error, isError, isLoading, isSuccess, refetch, resendFileReceipt: postData, metaData }
}

const useGetFileReceipts = (props={}) => {
  const [ records, setRecords ] = useState([]);
  const [ totalRecords, setTotalRecords ] = useState(0);
  const [ receiveDateStart, setStartDate ] = useState(null);
  const [ receiveDateEnd, setEndDate ] = useState(null);
  const { data, status, error, isError, isLoading, isSuccess, refetch, postData, metaData } = useAxiosPost({
    ...props,
    url: `fileReceiptManager/getFileReceipts`,
    method:'POST',
    config:{
      'Content-Type':'application/json',
      'Accept':'*'
    },
  });
  
  useEffect(()=> {
    if (data) {
      const {collection}=data;
      if (collection) {
        setRecords(JSON.parse(JSON.stringify(collection.FileReceipt ||[])));
        setTotalRecords(JSON.parse(JSON.stringify(collection.$.totalRecords)));
        setStartDate(JSON.parse(JSON.stringify(collection.$.receiveDateStart)));
        setEndDate(JSON.parse(JSON.stringify(collection.$.receiveDateEnd)));
      }
    }
  }, [data]);

  return { records, totalRecords, receiveDateEnd, receiveDateStart, status, error, isError, 
    isLoading, isSuccess, refetch, getFileReceipts: postData, metaData }
}

const useGetFileDetails = () => {
  const [ details, setDetails ] = useState([]);
  const { data, status, error, isError, isLoading, isSuccess, refetch, postData, metaData } = useAxiosPost({
    url: `fileReceiptManager/getFileDetails`,
    method:'POST',
    config:{
      'Content-Type':'application/json',
      'Accept':'*'
    },
  });
  
  useEffect(() => {
    if (data && data.resp) {
      setDetails(data.resp);
    }
  }, [data]);

  return { details, status, error, isError, isLoading, isSuccess, refetch, getFileDetails: postData, metaData }
}

const useGetOriginalFile = () => {
  const [ ABFFile, setABFFile ] = useState({redirectUri:'',fileName:''});
  const { data, status, error, isError, isLoading, isSuccess, refetch, postData, metaData } = useAxiosPost({
    url: `fileReceiptManager/getOriginalFile`,
    method:'POST',
    config:{
      'Content-Type':'application/json',
      'Accept':'*'
    },
    dispatchError: false,
  });
  useEffect(() => {
    if (data) {
      const { Uri, fName } = trimURL(data.resp)
      setABFFile({redirectUri: Uri, fileName: fName});
    }
  },[data])

  return { ABFFile, status, error, isError, isLoading, isSuccess, refetch, getOriginalFile: postData, metaData }
}

const useGetCheckRegister = () => {
  const [ checkRegister, setCheckRegister ] = useState({redirectUri:'',fileName:''});
  const { data, status, error, isError, isLoading, isSuccess, refetch, postData, metaData } = useAxiosPost({
    url: `fileReceiptManager/getCheckRegister`,
    method:'POST',
    config:{
      'Content-Type':'application/json',
      'Accept':'*'
    },
    dispatchError: false
  });
  useEffect(() => {
    if (data) { 
      const { Uri, fName } = trimURL(data.resp)
      setCheckRegister({redirectUri: Uri, fileName: fName});
    }
  },[data]);

  return { checkRegister, status, error, isError, isLoading, isSuccess, refetch, getCheckRegister: postData, metaData }
}
const useCheckIfFileNameAFF = () => {
  const [ AffFileName, setAffFileName ] = useState('');
  const { data, status, error, isError, isLoading, isSuccess, refetch, postData, metaData } = useAxiosPost({
    url: `fileReceiptManager/checkIfFileNameAFF`,
    method:'POST',
    config:{
      'Content-Type':'application/json',
      'Accept':'*'
    },
    dispatchError: false
  });

  useEffect(() => {
    if (data && data.resp) {
      setAffFileName(data.resp);
    }else{
      setAffFileName('')
    }
  }, [data]);

  return { AffFileName, status, error, isError, isLoading, isSuccess, refetch, getCheckIfFileNameAFF: postData, metaData }
}

const useGetAFFFiles = () => {
  const [ originalFile, setOriginalFile ] = useState({redirectUri: '' });
  const { data, status, error, isError, isLoading, isSuccess, refetch, postData, metaData } = useAxiosPost({
    url: `fileReceiptManager/getAFFFiles`,
    method:'POST',
    dispatchError: false
  });

  useEffect(() => {
    let newFieldId = 'item';
    
    // if(data && data.resp) {
    //   const dataProps = Object.keys(data.resp);
    //   dataProps.forEach(prop => {
    //     if (prop.includes('AbfAFFFileObj')) {
    //       newFieldId = prop;
    //     }
    //   });
    // }
    if (data && data.resp && data.resp[newFieldId]) {
      if(data.resp[newFieldId].constructor.name==="Array" )
      {
      const affFileCount = data.resp[newFieldId].length;
      if (affFileCount > 0) {
        setOriginalFile(data.resp[newFieldId]);
      }
    } else if(data.resp[newFieldId].constructor.name==="Object"){
      setOriginalFile([data.resp[newFieldId]])
    }
  }

  }, [data]);

  return { originalFile, status, error, isError, isLoading, isSuccess, refetch, getAFFFiles: postData, metaData }
}

const useDownloadFile = () => {
  const [ file, setFile ] = useState('');
  const { data, status, error, isError, isLoading, isSuccess, refetch, postData, metaData } = useAxiosPost({
    url: `fileReceiptManager/downloadFile`,
    method: 'POST',
    responseType: 'blob',
    dispatchError: false
  });

  useEffect(() => {
    if (data) {
      setFile(data);
    }
  }, [data]);

  return { file, status, error, isError, isLoading, isSuccess, refetch, getDownloadFile: postData, metaData }
}
export { 
  useResendFileReceipt, 
  useGetFileReceipts, 
  useGetFileDetails, 
  useGetOriginalFile, 
  useGetCheckRegister, 
  useCheckIfFileNameAFF,
  useGetAFFFiles,
  useDownloadFile
}
