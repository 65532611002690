/* eslint-disable no-nested-ternary */
import React, { useCallback,useEffect,useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import Info from 'ui-core/dist/Info'
import Warning from 'ui-core/dist/Warning';
import { useHistory,useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { Grid, ListPaneHeader, useLayout,  TextArea, Button, Input, Checkbox } from 'ui-core/dist'
import PopoverBox from "./PopoverBox";

const StyleDivParent = styled.div`
  &.styled-parent-div {
    margin-top: 45px;
    border: 1px solid #cacccd;
    /* border-width: 1px; */
    /* border-color: #dbdbdb; */
    overflow-y: auto;
    height: 470px;
    border-radius: 5px;
    margin-left: 30px;
    text-align: left;
    /* -webkit-box-shadow: -1px 9px 18px 0px rgb(0 0 0 / 75%); */
    /* -moz-box-shadow: -1px 9px 18px 0px rgba(0,0,0,0.75); */
    /* box-shadow: 0px 0px 6px -1px rgb(0 0 0 / 75%); */
  }
  &.popBoxDiv {
    display: inline-block;

    & span {
      position:relative !important;

      & div.popover.bottom {
        border-radius: 5px;
        margin-top: 42px !important;
        margin-left: 27px !important;
      }
    }
  }
`;

const StyledLi = styled.li`
  text-size: 14px;
`;

const RangeTextboxControl=({label,labelContainer,
  inputContainer, initialValueTextBox1,initialValueTextBox2,
  textbox1maxlength, textbox2maxlength, uniqueId,
  initialMode, defaultMode, onSetValue, onEnterValue, isResetField, setIsResetFlag, handleEnterKeyPressEvent=()=>false})=>{
  const [textbox1value,settextbox1value]=useState('');
  const [textbox2value,settextbox2value]=useState('');
  const [mode,setmode]=useState(defaultMode||"Exact");
  const [checkbox1checked,setcheckbox1checked]=useState(false);
  const [checkbox2checked,setcheckbox2checked]=useState(false);
  const onSetValueRef=useRef();
  const onEnterValueRef=useRef();
  onSetValueRef.current=onSetValue
  onEnterValueRef.current=onEnterValue

  useEffect(()=>{
    if(isResetField){
      settextbox1value("")
      settextbox2value("")
      setIsResetFlag(false)
    }
  },[isResetField])

  useEffect(()=>{
    if (initialValueTextBox1 && initialValueTextBox2 && initialMode) {
      setmode(initialMode)
      settextbox1value(initialValueTextBox1 ||"")
      settextbox2value(initialValueTextBox2||"");
    }
  },[initialValueTextBox1,initialValueTextBox2,initialMode])

  useEffect(()=>{
    if (mode==="Exact") {
      setcheckbox1checked(false);
      setcheckbox2checked(true);
    } else if(mode==="Range"){
      setcheckbox1checked(true);
      setcheckbox2checked(false);
    } else if(mode==="Partial") {
      setcheckbox1checked(false);
      setcheckbox2checked(false);
    }
  },[mode]);

  const onChangeMode=(value)=>{
    if (value && value!==mode) {
      settextbox1value('');
      settextbox2value('');
      setmode(value);
    }
  }
  const onChangeTextboxValue=(value,target)=>{
    if (target==="textbox1") {
      settextbox1value(value);
    } else if(target==="textbox2"){
      settextbox2value(value);
    }
    if (mode) {
      if (mode==="Exact" || mode==="Partial") {
        if (onSetValueRef.current) onSetValueRef.current({textbox1value:value,textbox2value:'',mode})    
            
      } else if(mode==="Range"){
        if (target==="textbox1") {
          if (onSetValueRef.current) onSetValueRef.current({textbox1value:value,textbox2value,mode});      
        } else if(target==="textbox2"){
          if (onSetValueRef.current) onSetValueRef.current({textbox1value, textbox2value:value,mode});
        }
      }
    }
  }
  const onKeyPress=(e)=>{
    if (e && e.keyCode===13) {
      if (mode) {
        if (mode==="Exact" || mode==="Partial") {
          if (textbox1value) {
            if(onEnterValueRef.current) onEnterValueRef.current();
          }
        } else if(mode==="Range"){
          if (textbox1value && textbox2value) {
            if(onEnterValueRef.current) onEnterValueRef.current();
          }
        }
      }    
    }
  }

  const renderInput=()=>(
    <>
      <Input
        style={{width:'200px'}}
        className="test-classname"
        domID={`${uniqueId}textbox1`}
        placeholder=""
        maxLength={textbox1maxlength}
        initialValue={textbox1value}
        onChange={(e)=>onChangeTextboxValue(e.target.value||"","textbox1")}
        onKeyDown={onKeyPress}
        size="small"
        onKeyUp={(e)=>{
          handleEnterKeyPressEvent(e, e?.currentTarget?.id, textbox1value)
        }}
      />
      {mode==="Range"?(
        <>
          <label style={{marginLeft:'10px'}}> To </label>
          <div className="marginLeft">
            <Input
              style={{marginLeft:'10px',width:'200px'}}
              className="test-classname"
              domID={`${uniqueId}textbox2`}
              placeholder=""
              maxLength={textbox2maxlength}
              initialValue={textbox2value}
              onChange={(e)=>onChangeTextboxValue(e.target.value||"","textbox2")}
              onKeyDown={onKeyPress}
              size="small"
              onKeyUp={(e)=>{
                handleEnterKeyPressEvent(e, e?.currentTarget?.id, textbox2value)
              }}
            />
          </div>
        </>
      ):null}
      <div className="">
        <StyleDivParent className="popBoxDiv">
          <PopoverBox
            trigger="click"
            orientation="bottom"
            buttonName={<Info />}
            buttonType="diminished"
            domID="format_popup"
            width="630px"
          
          >
            <div style={{
              padding: "14px",
              color: "#202083",
              fontSize: "13px",
              fontWeight: "bold",
            }}>
              <span>Search Tips</span>
              <ul>
                <StyledLi>Search considers all fields entered or selected as criteria for the query.</StyledLi>
                <StyledLi>Separate multiple values by commas.</StyledLi>
                <StyledLi>For a range search, select range checkbox and enter starting and ending numbers of the range.</StyledLi>
                <StyledLi>For an exact search, select Exact Match checkbox and enter the value in the field textbox.</StyledLi>
                <StyledLi>Remove all leading and trailing spaces.</StyledLi>
                <StyledLi>Remove all leading zeros (0).</StyledLi>
              </ul>
            </div>
          </PopoverBox>
        </StyleDivParent>
      </div>
      <div className="marginLeft">
        <div className="displayFlex">
          <Checkbox
            style={{marginLeft:'10px'}}
            ariaLabelledBy="Range"
            className=""
            domID="range-checkboxLabel"
            dataTestId="range-checkboxLabel"
            labelAfter="Range"
            labelBefore="Range"
            labelTooltipText=""
            labelTooltipWidth={100}
            name="Range"
            value={mode}
            checked={checkbox1checked}
            onChange={(e,state)=>{
              const name=e.currentTarget.firstChild.getAttribute('aria-labelledby');
              if (state) {
                onChangeMode(name);
              } else onChangeMode("Partial")
            }}
            wrapperClass=""
          />
          <label style={{marginLeft:'10px'}}>Range</label>
        </div>
      </div>
      <div className="marginLeft">
        <div className="displayFlex">
          <Checkbox
            style={{marginLeft:'10px'}}
            ariaLabelledBy="Exact"
            className=""
            domID="exact-checkboxLabel"
            dataTestId="exact-checkboxLabel"
            labelAfter="Exact"
            labelBefore="Exact"
            labelTooltipText=""
            labelTooltipWidth={100}
            name="Exact"
            checked={checkbox2checked}
            onChange={(e,state)=>{
              const name=e.currentTarget.firstChild.getAttribute('aria-labelledby');
              if (state) {
                onChangeMode(name);
              } else onChangeMode("Partial")
            }}
            wrapperClass=""
          />
          <label style={{marginLeft:'10px'}}>Exact Match</label>
        </div>
      </div>
    </>
  )

  return (
    <>
      {labelContainer?labelContainer(
        <div className="displayFlex">
          <label> {label}</label></div>):(
        <div className="displayFlex">
          <label> {label}: </label>
        </div>
      )}
      
      {inputContainer?inputContainer(
        <div className="displayFlex">
          {renderInput()}</div>):
        <div className="displayFlex">{renderInput()}</div>}
    </>
  )
}

export default RangeTextboxControl