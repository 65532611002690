/* 
INTEGRATIONS
––––––––––––––––––––––––––––––––––––––
This file is the source of truth for what integrations are 
enabled and how they are configured. 

*/

export const integrations = {
  // Remove from integrations if not using auth-management
  ciam: {
    // REMOVE THIS LINE AND REPLACE WITH CLIENT_ID
    URL: `${window.location.origin}/sso`,
    // 'CLIENT_ID': 'goes here'
  },
  // Uncomment to use notifications
  // notifications: {
  // },
};

export default integrations;
