import React, {useRef, useEffect} from 'react';

export default function withAxiosCancellation(Component) {

    const WrappedComponent = (props = {}) => {
        const cancelTokenSourceRef = useRef([])
        const getCancelSourceWithNameRef=useRef(null)
        const getCancelSourceWithName=(servicename)=>{
            if (servicename) {
                return (source) => {
                    if (source && cancelTokenSourceRef && cancelTokenSourceRef.current 
                        && !cancelTokenSourceRef.current.find(x=>x.servicename===servicename)) {
                        cancelTokenSourceRef.current.push({servicename, source})
                    }
                }
            }
        }
        getCancelSourceWithNameRef.current=getCancelSourceWithName
        useEffect(()=>{
            return ()=>{
                if(cancelTokenSourceRef && cancelTokenSourceRef.current && cancelTokenSourceRef.current.length>0) {
                  for (const item of cancelTokenSourceRef.current) {
                    item?.source?.cancel("Operation canceled by the user.");
                  }
                }
              }
        })
        return (
            <Component {...props} getCancelSourceWithName={getCancelSourceWithNameRef.current} />
        )
    }
    WrappedComponent.componentDisplayName=Component.displayName || Component.name || 'Component'
    return WrappedComponent;
}


