import { useAxios } from '../hooks'

const useClients = () => {
  const { data, ...rest } = useAxios({
    url: `/message-manager/client-layouts`,
  })

  return { 
    data,
    ...rest
  }
}

export default useClients