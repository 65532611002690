import styled from 'styled-components';

export const DropOne = styled.div`
  margin-bottom: 30px;
`;
export const DropTwo = styled.div`
  margin-bottom: 30px;
`;
export const DropThree = styled.div`
  margin-bottom: 30px;
`;

export const ContainerBox = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 10px;
  margin-bottom: 10px;
`;
export const DuplexRuleDropDown = styled.div`
  height: 50px;
  flex-grow: 1;
  margin-right: 35px;
  margin-top: 10px;
  width: 33%;
`;
