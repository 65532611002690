import React from 'react';
import PropTypes from 'prop-types';

import ButtonWrapper from './components/ButtonWrapper';

const tooltipTextMap = {
  close: 'Close',
};

const CloseLayoutControl = (props) => {
  const tooltipContent = tooltipTextMap[props.buttonType];
  const shouldHideTooltip = props.buttonType === 'close' && props.hideTooltip;
  return (
    <span id={props.domID} className={props.className}>
      <ButtonWrapper
        {...props}
        tooltipContent={tooltipContent}
        tooltipWidth={80}
        tooltipPosition={props.tooltipPosition}
        hideTooltip={shouldHideTooltip}
      />
    </span>
  );
};

CloseLayoutControl.defaultProps = {
  buttonType: 'popout',
  tooltipPosition: 'top-center',
  hideTooltip: false,
  className: null,
  domID: null,
};

CloseLayoutControl.propTypes = {
  buttonType: PropTypes.oneOf(['close']),
  tooltipPosition: PropTypes.oneOf([
    'top-center',
    'top-left',
    'top-right',
    'bottom-center',
    'bottom-left',
    'bottom-right',
    'left-up',
    'left-center',
    'left-down',
    'right-up',
    'right-center',
    'right-down',
  ]),
  hideTooltip: PropTypes.bool,
  className: PropTypes.string,
  domID: PropTypes.string,
};

export default CloseLayoutControl;
