import React, { useState, useEffect } from 'react';
import Button from 'ui-core/dist/Button';
import ButtonGroup from 'ui-core/dist/ButtonGroup';
import ListPaneHeader from 'ui-core/dist/ListPaneHeader';
import Input from 'ui-core/dist/Input';
import './index.scss'
import { useDispatch, useSelector } from 'react-redux';
import axios from '../../axios'
import { changePassword } from '../Login/modules/actions';
import useNotificationBannerHook from '../../useNotificationBannerHook';

const ChangePassword = () => {
  const [state, setState] = useState({})
  const [error, setError] = useState()
  const [successful, setSuccessful] = useState(false)
  const [userTyped, setUserTyped] = useState(false)
  const { notify } = useNotificationBannerHook()
  const userId = useSelector(store => store.session.userId)
  const dispatch = useDispatch()

  const validateNewPassword=(npassword)=>{
    if((npassword||'').length<8){
      setError('Password must be at least 8 characters')
      return false;
    }
    if(!/^(.*[a-z].*)$/gm.test(npassword)){
      setError('Must include at least 1 lower case character')
      return false;
    }
    if(!/^(.*[A-Z].*)$/gm.test(npassword)){
      setError('Must include at least 1 upper case character')
      return false;
    }
    if(!/^(.*\d.*)$/gm.test(npassword)){
      setError('Must include at lease 1 digit')
      return false;
    }
    if(!/^(.*\W.*)$/gm.test(npassword)){
      if(!/^(.*_.*)$/gm.test(npassword)){
        setError('Must include at lease 1 allowed special character')
        return false;
      }
    }
    const pat=`.*${userId}.*`
    const r=new RegExp(pat, 'gm');
    if(r.test(npassword)){
      setError('Cannot contain you user ID')
      return false;
    }
    if(npassword===state.oldPassword){
      setError('Password cannot be the same as old password')
      return false;
    }
    return true;
  }
  useEffect(() => {
    if (state.verifyPassword !== state.newPassword && userTyped === true) {
      setError("Passwords do not match.")
    } else if (state.verifyPassword === state.newPassword) {
      if(validateNewPassword(state.newPassword)){
        setError()  
      }  
    }
  }, [state.verifyPassword, state.newPassword, userTyped, state.oldPassword])

  const handleInputChange = (e, { value }) => {
    if(e.target.name === 'verifyPassword') {
      setUserTyped(true)
    }

    setState({
      ...state,
      [e.target.name]: value,
    })
  }

  const handleFormSubmit = async () => {
    try {
      await axios.post('users/changePassword', state)
      setSuccessful(true)
      notify(`You've successfully updated your password.`, 'positive')
      dispatch(changePassword())
    } catch (err){
      notify(err.displayMessage, 'negative')
    }
  }

  return (
    <>
      <ListPaneHeader 
        title="Change Your Password"
      />
      {successful ? (
        <p style={{ fontSize: '12px', marginLeft: '24px'}}>You have successfully changed your password.</p>
      ): (
        <>
          <div className="change-password__info">
            <p>Your password has expired. Please create a new password based on the following rules:</p>
            <ul>
              <li>Must be at least 8 characters</li>
              <li>Include at least 1 lower case character</li>
              <li>Include at least 1 upper case character</li>
              <li>Include at least 1 digit</li>
              <li>Include at least 1 allowed special character (@-$#=+*&?)</li>
              <li>Cannot contain your user ID</li>
              <li>Cannot be the same as your last 6 passwords</li>
            </ul>
            <p style={{ color: `#bf1616` }}>* indicates required information</p>
          </div>
          <div className="change-password__form">
            <form>
              <Input 
                label="User ID"
                size="small"
                initialValue={userId}
                disabled
              />
              <div className="change-password__form__row">
                <Input 
                  label="Old Password: "
                  autoComplete="off"
                  dataTestId="test-oldPassword"
                  domID="oldPassword"
                  onChange={handleInputChange}
                  size="small"
                  name="oldPassword"
                  type="password"
                />
              </div>
              <div className="change-password__form__row">
                <Input 
                  label="New Password: "
                  autoComplete="off"
                  dataTestId="test-newPassword"
                  domID="newPassword"
                  onChange={handleInputChange}
                  size="small"
                  name="newPassword"
                  hasError={!!error}
                  errorMessage={error}
                  type="password"
                />
                <div style={{marginLeft:'20px'}}>
                  <Input 
                    label="Verify Password: "
                    autoComplete="off"
                    dataTestId="test-verifyPassword"
                    domID="verifyPassword"
                    onChange={handleInputChange}
                    size="small"
                    name="verifyPassword"
                    hasError={!!error}
                    errorMessage={error}
                    type="password"
                  />
                </div>
              </div>
              <Input 
                label="Password Hint:"
                autoComplete="off"
                dataTestId="test-hint"
                domID="hint"
                onChange={handleInputChange}
                size="small"
                name="hint"
              />
            </form>
            <ButtonGroup>
              <Button 
                buttonType="standard"
                dataTestId="test-submit"
                domID="submit"
                name="Submit"
                onClick={handleFormSubmit}
                size="small"
                type="button"
                disabled={error || !userTyped}
              />
            </ButtonGroup>
          </div>
        </>
      )}
    </>
  )
}
export default ChangePassword;