/* eslint-disable camelcase */
import { useEffect, useState } from 'react'
import { useAxiosPost } from '../hooks'

const useGetNCOAPAFsList=()=>{
  const [ASSOCIATED_CLIENTS, setASSOCIATED_CLIENTS]=useState(null)
  const [ASSOCIATED_CLIENTSCount, setASSOCIATED_CLIENTSCount]=useState(0)
  const [NCOA_PAF, setNCOA_PAF]=useState(null)
  const [NCOA_PAFCount, setNCOA_PAFCount]=useState(0)
  const {postData, isLoading,isError,error, data }=useAxiosPost({
    url:'clientAdministration/clientNCOAPAFManager/getNCOAPAFsList',
    method:'POST',
    onError: (e) => 
      e
    ,
    onSettled: (d, e) => {
      if(e) {
        return e
      }
      return d
    },
  })

  useEffect(()=>{
    if (data){
      const {NCOA_PAFS}=data;
      if (NCOA_PAFS) {
        const {NCOA_PAF: NCOA_PAFp, ASSOCIATED_CLIENTS: ASSOCIATED_CLIENTSp}=NCOA_PAFS
        if (NCOA_PAFp) {
          let NCOA_PAF_Result=[]
            
          if (NCOA_PAFp.constructor.name==="Object") {
            NCOA_PAF_Result.push({
              ...NCOA_PAFp,
              ...NCOA_PAFp.$
            })
          } else if(NCOA_PAFp.constructor.name==="Array"){
            NCOA_PAF_Result=[...NCOA_PAFp.map(v=>({
              ...v, 
              ...v.$
            }))]
          }
          setNCOA_PAF(JSON.parse(JSON.stringify(NCOA_PAF_Result)))
          setNCOA_PAFCount(NCOA_PAF_Result.length)
            
        } else{
          setNCOA_PAF(JSON.parse(JSON.stringify([])))
          setNCOA_PAFCount(0)
        } 
        if (ASSOCIATED_CLIENTSp) {
          const { CLIENT } = ASSOCIATED_CLIENTSp
          if(CLIENT){
            let ASSOCIATED_CLIENTSp_Result=[]
    
            if (CLIENT.constructor.name==="Object") {
              ASSOCIATED_CLIENTSp_Result.push({...CLIENT?.$})
            } else if(CLIENT.constructor.name==="Array"){
              ASSOCIATED_CLIENTSp_Result=[...CLIENT.map(v=>({
                ...v.$
              }))]
            }
            setASSOCIATED_CLIENTS(JSON.parse(JSON.stringify(ASSOCIATED_CLIENTSp_Result)))
            setASSOCIATED_CLIENTSCount(ASSOCIATED_CLIENTSp_Result.length)
          }
        } else{
          setASSOCIATED_CLIENTS(JSON.parse(JSON.stringify([])))
          setASSOCIATED_CLIENTSCount(0)
        }
      } else{
        setNCOA_PAF(JSON.parse(JSON.stringify([])))
        setNCOA_PAFCount(0)
        setASSOCIATED_CLIENTS(JSON.parse(JSON.stringify([])))
        setASSOCIATED_CLIENTSCount(0)
      }
        
    } else{
      setNCOA_PAF(JSON.parse(JSON.stringify([])))
      setNCOA_PAFCount(0)
      setASSOCIATED_CLIENTS(JSON.parse(JSON.stringify([])))
      setASSOCIATED_CLIENTSCount(0)
    }
  },[data])
  
  return { data, isLoading, isError, error, NCOA_PAF, NCOA_PAFCount, ASSOCIATED_CLIENTS, ASSOCIATED_CLIENTSCount, postData};
}

const useInsertPAF=()=>{
  const {postData, isLoading,isError,error,metaData, data }=useAxiosPost({
    url:'clientAdministration/clientNCOAPAFManager/insertNCOAPAF',
    method:'POST',
    onError: (e) => 
      // what happens on error? if you need something extra
      // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })
  return {postData, isLoading,isError,error,metaData, data}
}
const useUpdatePAF=()=>{
  const {postData, isLoading,isError,error, metaData, data }=useAxiosPost({
    url:'clientAdministration/clientNCOAPAFManager/updateNCOAPAF',
    method:'POST',
    onError: (e) => 
      // what happens on error? if you need something extra
      // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })
  return {postData, isLoading,isError,error,metaData, data}
}
const useDeletePAF=()=>{
  const {postData, isLoading,isError,error,metaData, data }=useAxiosPost({
    url:'clientAdministration/clientNCOAPAFManager/deleteNCOAPAF',
    method:'POST',
    onError: (e) => 
      // what happens on error? if you need something extra
      // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })
  return {postData, isLoading,isError,error,metaData, data}
}
export {
  useGetNCOAPAFsList,
  useInsertPAF,
  useUpdatePAF,
  useDeletePAF
}