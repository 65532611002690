import React, {useState,useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Group } from 'ui-core/dist';
import { useDashbordAnnouncements } from '../../api/useDashboard';
import {  LoadingPage} from '../../components';
import withAxiosCancellation from '../../components/withAxiosCancellation'

const Announcements = () => {
  const { data:announcementsData, isLoading:pIsLoading } = useDashbordAnnouncements();
  return (
    <>
      <div>
        <h3 className="paymentHeader">Announcements</h3>
        <div className="paymentBody" style={{marginLeft: '-30px', paddingBottom: '20px'}}>
          {
              pIsLoading ? (<LoadingPage height='20vh'/>) :  ( 
              <>
                {
                  announcementsData && announcementsData.data && announcementsData.data.length ? (
                    announcementsData.data.map(announcement => {
                      return <Group 
                        title={announcement.msgDesc?.toUpperCase()}
                        isAccordion
                        isCollapsed
                        initialIsCollapsed
                      >
                        <p style={{marginLeft: '60px'}} dangerouslySetInnerHTML={{__html: announcement.msgData}} />
                      </Group>
                    })
                  ) : <></>
                }
              </>
              )
          }
      </div>
      </div>
    </>
  )
}

export default withAxiosCancellation(Announcements)