import envConfig from './env-config.js';


const { APP_ENV = 'development' } = window;
const API_CLIENT_ID = envConfig[APP_ENV].API_CLIENT_ID;
const { REACT_APP_API_CLIENT_ID } = process.env;
const Clientid = REACT_APP_API_CLIENT_ID ? REACT_APP_API_CLIENT_ID : API_CLIENT_ID;
const { REACT_APP_SENTINEL_URI } = process.env;
const sentinel_URL = REACT_APP_SENTINEL_URI ? REACT_APP_SENTINEL_URI : envConfig[APP_ENV].SENTINEL_URI;

export const OICoreConfig = {
  sentinel: {
    authority: `${sentinel_URL}auth/realms/developer-platform`,
    userinfo: `${sentinel_URL}auth/realms/developer-platform/protocol/openid-connect/userinfo`,
    introspectToken: `${sentinel_URL}auth/realms/developer-platform/protocol/openid-connect/token/introspect`,
    token_endpoint: `${sentinel_URL}auth/realms/developer-platform/protocol/openid-connect/token`,
    client_id: Clientid,
    redirect_uri: window.location.origin + "/login",
    post_logout_redirect_uri: window.location.origin + "/login",
    automaticSilentRenew: false,
  }
}