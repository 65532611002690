import React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router';
import { useLocalStorage } from '../../hooks'
import { clearNotificationBanner, resetClearNotificationBanner } from '../Login/modules/actions'

const SecureRotue = ({ component: Component, ...rest }) => {
  const { clearNotification } = useSelector(({ session }) => session)
  const [token] = useLocalStorage('user:token')
  const dispatch = useDispatch()

  if (!token) {
    dispatch(clearNotificationBanner());
  } else{
    if (clearNotification) dispatch(resetClearNotificationBanner())
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        token ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              // eslint-disable-next-line react/prop-types
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}

SecureRotue.propTypes = {
  component: PropTypes.node.isRequired
}

export default SecureRotue;