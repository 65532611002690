import React from 'react';
import {Button, useLayout} from 'ui-core/dist';
import { useHistory } from 'react-router-dom'
import styled from 'styled-components/dist/styled-components'
import ListPaneHeaderWrapper from '../../../components/ErrorDisplaySection/ListPaneHeaderWrapper';

const StyledGridWrapper=styled.div`
& table {
    & tr {
        line-height: 4.3vh;
        & td {
            padding-left: 8vh;
        }
    }
}
`

const ClientInformationMenuPage=()=>{
  const {
    expandCollapseListPaneButtonConfig,
  } = useLayout()
  const history=useHistory();
  return (
    <>
      <ListPaneHeaderWrapper
        title="Client Information Manager"
        layoutControls={[expandCollapseListPaneButtonConfig]}
      />
      <div className="marginLeft">
        <div className="displayFlex">
          <StyledGridWrapper>
            <table border="0" cellSpacing="1" cellPadding="3">
              <tr>
                <th>Editor</th>
                <th>Description</th>
              </tr>
      
              <tr className="row">
                <td className="value">
                  <div className="marginLeft">
                    <Button
                      domID="btn_search"
                      buttonType="standard"
                      size="small"
                      name="Associate Reports Editor"
                      onClick={(e)=>{
                        e.preventDefault();
                        e.stopPropagation();
                        history.push(`/ClientAdministration/ClientInformationManager/associate_reports`)
                      }}
                    />
                  </div>
                </td>
                <td className="value">
                      Associate Client Reports to Client Contacts
                </td>
              </tr>
      
              <tr className="row">
                <td className="value">
                  <div className="marginLeft">
                    <Button
                      domID="btn_search"
                      buttonType="standard"
                      size="small"
                      name="Client Information Editor"
                      onClick={(e)=>{
                        e.preventDefault();
                        e.stopPropagation();
                        // history.push(`/ClientAdministration/ClientInformationManager/clientInformationEditor`)
                      }}
                    />
                  </div>
                </td>
                <td className="value">
                      Manage Client Information
                </td>
              </tr>
      
              <tr className="row">
                <td className="value">
                  <div className="marginLeft">
                    <Button
                      domID="btn_search"
                      buttonType="standard"
                      size="small"
                      name="Contact Editor"
                      onClick={(e)=>{
                        e.preventDefault();
                        e.stopPropagation();
                        history.push(`/ClientAdministration/ClientInformationManager/contact_editor`)
                      }}
                    />
                  </div>
                </td>
                <td className="value">
                      Manage Client Contact Information
                </td>
              </tr>
      
            </table>
          </StyledGridWrapper>
        </div>
      </div>
    </>
  )
}

export default ClientInformationMenuPage;