  /* eslint-disable react/prop-types */
  import { Route, Switch } from 'react-router';
  import { ConnectedRouter } from 'connected-react-router';
  import React, { useEffect, useMemo } from 'react';
  import { createBrowserHistory } from 'history';
  import { Page } from '../components';
  import EtfRoutes from './EtfRoutes';
  import AchRoutes from './AchRoutes';
  import AddressRoutes from './AddressRoutes';
  import CCFRoutes from './CCFRoutes';
  import ClientAdminRoutes from './ClientAdminRoutes';
  import ClientRulesRoutes from './ClientRulesRoutes';
  import DocManagerRoutes from './DocManagerRoutes';
  import EEOBRoutes from './EEOBRoutes';
  import EmployerRoutes from './EmployerRotues';
  import GroupRuleRoute from './GroupRuleRoute';
  import FormManagerRoutes from './FormManagerRoutes';
  import ImageManagerRoutes from './ImageManagerRoutes';
  import ManifestRoutes from './ManifestRoutes';
  import ReportManagerRoutes from './ReportManagerRoutes';
  import SecurityRoutes from './SecurityRoutes';
  import WelcomeKitRoutes from './WelcomeKitRoutes';
  import SysAdminRoutes from './SysAdminRoutes';
  import EMDFinanceManagerRoutes from './EMDFinanceManagerRoutes'
  import DirectPrintJobTrackerRoutes from './DirectPrintJobTrackerRoutes'
  import { 
    ChoseContext, Dashboard, ForgotPassword, Login, HyperSecureRoute, SecureRoute, 
    ErrorManager
  } from '../containers';

  import Internalogin from '../containers/Login/internalLogin';
  import ErrorManagerTranslation from '../containers/ErrorManagerTranslation';
  import NotFound from '../containers/NotFoundPage';
  import { AccessDeniedPage } from '../containers/AccessDeniedPage';
  import FileReceiptManagerRoutes from './FileReceiptManagerRoutes';
  import Authorizer from '../containers/Authorizer';
  import ChangePassword from '../containers/ChangePassword';
  import TestFileManager from '../containers/TestFileManager';
  import FileTracker from '../containers/FileTracker';
  import ReportBuilder from '../containers/ReportBuilder';

  export const history = createBrowserHistory();

  const ApplicationRoutes = ({ getHistoryRef }) => {
    useEffect(() => {
      if (getHistoryRef) getHistoryRef(history)
    }, [])

    const renderRoutes = useMemo(() => (
      <>
        <ConnectedRouter history={history}>
          <Switch>
            <HyperSecureRoute exact path="/" component={Dashboard} />
            <HyperSecureRoute path="/EtfMapManager"
              component={() => (
                <Authorizer permission="CAN_ACCESS_835MAP_MANAGER">
                  <EtfRoutes />
                </Authorizer>
              )}
            />
            <HyperSecureRoute path="/AchManager"
              component={() => (
                <Authorizer permission="CAN_ACCESS_ACH">
                  <AchRoutes />
                </Authorizer>
              )}
            />
            <HyperSecureRoute path="/AddressManager"
              component={() => (
                <Authorizer permission="CAN_ACCESS_ADDRESS">
                  <AddressRoutes />
                </Authorizer>
              )}
            />
            <HyperSecureRoute path="/CCFManager"
              component={() => (
                <Authorizer permission="CAN_ACCESS_CCF_MANAGER">
                  <CCFRoutes />
                </Authorizer>
              )}
            />
            <HyperSecureRoute path="/ClientAdministration"
              component={() => (
                <Authorizer permission="CAN_ACCESS_CLIENT_ADMINISTRATION">
                  <ClientAdminRoutes />
                </Authorizer>
              )
              } />
            <HyperSecureRoute path="/ClientRulesManager"
              component={() => (
                <Authorizer permission="CAN_ACCESS_CLIENTRULES">
                  <ClientRulesRoutes />
                </Authorizer>
              )}
            />
            <HyperSecureRoute path="/DocumentManager"
              component={() => (
                <Authorizer permission="CAN_ACCESS_DOC">
                  <DocManagerRoutes />
                </Authorizer>
              )}
            />
            <HyperSecureRoute path="/EEOBFormManager"
              component={() => (
                <Authorizer permission="CAN_ACCESS_EEOBFORM_MANAGER">
                  <EEOBRoutes />
                </Authorizer>
              )}
            />
            <HyperSecureRoute path="/EmployerGroupManager"
              component={() => (
                <Authorizer permission="CAN_ACCESS_SELFSERVICE_GROUPS">
                  <EmployerRoutes />
                </Authorizer>
              )}
            />
            <HyperSecureRoute path="/ErrorManager"
              component={() => (
                <Authorizer permission="CAN_ACCESS_ERROR">
                  <ErrorManager />
                </Authorizer>
              )}
            />

            <HyperSecureRoute path="/ErrorManagerTranslation"
              component={() => (
                <Authorizer permission="CAN_ACCESS_ERROR">
                  <ErrorManagerTranslation />
                </Authorizer>
              )}
            />

            <HyperSecureRoute path="/FileReceiptManager"
              component={() => (
                <Authorizer permission="CAN_ACCESS_FILERECEIPT">
                  <FileReceiptManagerRoutes />
                </Authorizer>
              )}
            />
            <HyperSecureRoute path="/FormManager"
              component={() => (
                <Authorizer permission="CAN_ACCESS_FORM_MANAGER">
                  <FormManagerRoutes />
                </Authorizer>
              )}
            />
            <HyperSecureRoute path="/GroupRuleManager"
              component={() => (
                <Authorizer permission="CAN_ACCESS_SELFSERVICE_RULETYPES">
                  <GroupRuleRoute />
                </Authorizer>
              )}
            />
            <HyperSecureRoute path="/HPPManager"
              component={() => (
                <Authorizer permission="CAN_ACCESS_SELFSERVICE_RULETYPES">
                  <Page />
                </Authorizer>
              )}
            />
            <HyperSecureRoute path="/ImageManager"
              component={() => (
                <Authorizer permission="CAN_ACCESS_FORM_MANAGER">
                  <ImageManagerRoutes />
                </Authorizer>
              )}
            />
            <HyperSecureRoute path="/ManifestInformationManager"
              component={() => (
                <Authorizer permission="CAN_ACCESS_MANIFEST_INFO">
                  <ManifestRoutes />
                </Authorizer>
              )}
            />
            <HyperSecureRoute path="/IDCardWelcomeKitManager"
              component={() => (
                <Authorizer permission="CAN_ACCESS_IDCARDS">
                  <WelcomeKitRoutes />
                </Authorizer>
              )}
            />
            <HyperSecureRoute path="/fileTracker"
              component={() => (
                <Authorizer permission="CAN_ACCESS_FILETRACKER">
                  <FileTracker />
                </Authorizer>
              )}
            />
            <HyperSecureRoute path="/reportBuilder"
              component={() => (
                <Authorizer permission="CAN_ACCESS_REPORTBUILDER">
                  <ReportBuilder />
                </Authorizer>
              )}
            />
            <HyperSecureRoute path="/ReportManager"
              component={() => (
                <Authorizer permission="CAN_ACCESS_ABF_REPORTS">
                  <ReportManagerRoutes />
                </Authorizer>
              )}
            />
            <HyperSecureRoute path="/SecurityManager"
              component={() => (
                <Authorizer permission="CAN_ACCESS_SECURITY">
                  <SecurityRoutes />
                </Authorizer>
              )}
            />
            <HyperSecureRoute path="/IDCardWelcomeKitManager"
              component={() => (
                <Authorizer permission="CAN_ACCESS_IDCARDS">
                  <SecurityRoutes />
                </Authorizer>
              )}
            />
            <HyperSecureRoute path="/SystemAdministration"
              component={() => (
                <Authorizer permission="CAN_ACCESS_SYSTEM_ADMINISTRATION">
                  <SysAdminRoutes />
                </Authorizer>
              )}
            />
            <HyperSecureRoute path="/TestFileManager"
              component={() => (
                <Authorizer permission="CAN_ACCESS_TESTFILE">
                  <TestFileManager />
                </Authorizer>
              )}
            />
            <HyperSecureRoute path="/EMDFinanceManager" 
              component={() => (
                <Authorizer permission="CAN_ACCESS_FINANCE_MANAGER">
                  <EMDFinanceManagerRoutes />
                </Authorizer>
              )}
            />
            <HyperSecureRoute path="/DirectPrintJobTracker" 
              component={() => (
                <Authorizer permission="CAN_ACCESS_DIRECT_PRINT_JOB_TRACKER">
                  <DirectPrintJobTrackerRoutes />
                </Authorizer>
              )}
            />
            <HyperSecureRoute path="/temp"
              component={() => (
                <></>
              )}
            />
            <SecureRoute path="/change-password" exact component={ChangePassword} />
            <SecureRoute path="/choose-context" exact component={ChoseContext} />
            <Route path="/login" exact component={Login} />
            <Route path="/internallogin" exact component={Internalogin} />
            <Route path="/logon.jsp" exact component={Login} />
            <Route path="/forgot" exact component={ForgotPassword} />
            <Route path="/unauthorized" component={AccessDeniedPage} />
            <Route
              path="*"
              render={(props) => props.location.pathname !== '/sso' && <NotFound />}
            />
          </Switch>
        </ConnectedRouter>
      </>
    ), []);

    return (
      <>
        {renderRoutes}
      </>
    );
  }

  // Make some routes private if using auth-management
  // const PrivateRouteIfAuth = integrations.ciam ? PrivateRoute : Route;

  // const CIAMRoute = ({ component: Component, ...props }) => (
  //   <Route
  //     {...props}
  //     render={(innerProps) =>
  //       integrations.ciam ? <Component {...innerProps} /> : <Redirect to="/404" />
  //     }
  //   />
  // );

  export default React.memo(ApplicationRoutes);