import {
  RULES_FOR_RULETYPES_PUT_REQUEST_TRIGGER,
  RULES_FOR_RULETYPES_PUT_SUCCESS,
  RULES_FOR_RULETYPES_PUT_ERROR,
  SET_IS_POSTING_RULES_FOR_RULETYPES,
  RULES_FOR_RULETYPES_POST_REQUEST_TRIGGER,
  RULES_FOR_RULETYPES_POST_SUCCESS,
  RULES_FOR_RULETYPES_POST_ERROR,
  SET_IS_CLONING_RULE_STATE,
  SET_IS_SHOWING_CLONE_RULE_MODAL,
  SET_RECORD_FOR_DETAIL_PANE,
  SET_RULE_TYPE_COMMIT_MODAL_OPEN,
  TRIGGER_COMMIT_RULE_FOR_RULE_TYPE,
  COMMIT_RULE_FOR_RULE_TYPE_SUCCESS,
  COMMIT_RULE_FOR_RULETYPE_ERROR,
  SET_IS_COMMITTING_RULE,
  SET_IS_SHOWING_ACTIVE_GROUPS_LISTING_MODAL,
  SET_IS_SHOWING_REVERT_RULE_MODAL,
  GET_REVERT_RULE_VERSION_TRIGGER,
  GET_REVERT_RULE_VERSION_SUCCESS,
  GET_REVERT_RULE_VERSION_ERROR,
  POST_REVERT_RULE_VERSION_TRIGGER,
  POST_REVERT_RULE_VERSION_SUCCESS,
  POST_REVERT_RULE_VERSION_ERROR,
  RESET_STATE
} from './constants';

// ---------- PUT RULE FOR RULE TYPE---------------------
export const triggerRulesForRuleTypesPutRequest = (payload = {}) => ({
  type: RULES_FOR_RULETYPES_PUT_REQUEST_TRIGGER,
  payload,
});

export const rulesForRuleTypesPutSuccess = (payload = {}) => ({
  type: RULES_FOR_RULETYPES_PUT_SUCCESS,
  payload,
  error: null,
});

export const rulesForRuleTypesPutError = (error = {}) => ({
  type: RULES_FOR_RULETYPES_PUT_ERROR,
  error,
});

// ---------- POST RULE FOR RULE TYPE---------------------
export const triggerRulesForRuleTypesPostRequest = (payload = {}) => ({
  type: RULES_FOR_RULETYPES_POST_REQUEST_TRIGGER,
  payload,
});

export const rulesForRuleTypesPostSuccess = (payload = {}) => ({
  type: RULES_FOR_RULETYPES_POST_SUCCESS,
  payload,
  error: null,
});

export const rulesForRuleTypesPostError = (error = {}) => ({
  type: RULES_FOR_RULETYPES_POST_ERROR,
  error,
});

export const setIsPostingRulesForRuleTypes = payload => ({
  type: SET_IS_POSTING_RULES_FOR_RULETYPES,
  payload,
});

// clone rule

export const setIsCloningRuleState = payload => ({
  type: SET_IS_CLONING_RULE_STATE,
  payload,
});

export const setIsShowingCloneRuleModal = payload => ({
  type: SET_IS_SHOWING_CLONE_RULE_MODAL,
  payload,
});

export const setRecordForDetailPane = payload => ({
  type: SET_RECORD_FOR_DETAIL_PANE,
  payload,
});

// ------- PUT COMMIT RULE FOR RULE TYPE -----

export const setRuleTypeCommitModalOpen = payload => ({
  type: SET_RULE_TYPE_COMMIT_MODAL_OPEN,
  payload,
});

export const triggerCommitRuleForRuleType = (payload = {}) => ({
  type: TRIGGER_COMMIT_RULE_FOR_RULE_TYPE,
  payload,
});

export const commitRuleForRuleTypeSuccess = (payload = {}) => ({
  type: COMMIT_RULE_FOR_RULE_TYPE_SUCCESS,
  payload,
  error: null,
});

export const commitRuleForRuleTypeError = (error = {}) => ({
  type: COMMIT_RULE_FOR_RULETYPE_ERROR,
  error,
});

export const setIsCommittingRule = payload => ({
  type: SET_IS_COMMITTING_RULE,
  payload,
});

export const setIsShowingActiveGroupsListingModal = payload => ({
  type: SET_IS_SHOWING_ACTIVE_GROUPS_LISTING_MODAL,
  payload,
});

export const setIsShowingRevertRuleModal = payload => ({
  type: SET_IS_SHOWING_REVERT_RULE_MODAL,
  payload,
});


export const getRevertRuleVersions = payload => ({
  type: GET_REVERT_RULE_VERSION_TRIGGER,
  payload,
});

export const getRevertRuleVersionsSuccess = payload => ({
  type: GET_REVERT_RULE_VERSION_SUCCESS,
  payload,
});

export const getRevertRuleVersionsError = payload => ({
  type: GET_REVERT_RULE_VERSION_ERROR,
  payload,
});

export const postRevertRuleVersion = payload => ({
  type: POST_REVERT_RULE_VERSION_TRIGGER,
  payload,
});

export const postRevertRuleVersionSuccess = (payload, ruleVersion) => ({
  type: POST_REVERT_RULE_VERSION_SUCCESS,
  payload,
  ruleVersion
});

export const postRevertRuleVersionError = payload => ({
  type: POST_REVERT_RULE_VERSION_ERROR,
  payload,
});

export const resetRulesForRuleTypes = payload => ({
  type: RESET_STATE,
  payload,
});