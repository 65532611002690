import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components/dist/styled-components';
import { useSelector } from 'react-redux';
import { LoadingPage } from '../../components';
import ErrorPage from '../ErrorPage';
import axios from '../../axios';
import {
  Grid,

} from 'ui-core/dist';
import ListPaneHeaderWrapper from '../../components/ErrorDisplaySection/ListPaneHeaderWrapper';
import GridPaginationWrapper from '../../components/GridPaginationWrapper';

const StyledGridWrapper = styled.div`
&.GridWrapper #grid-wrapper-clientAdministrationAddressExclusionGrid{
  overflow:initial !important;
 }
`

const ErrorManagerTranslation = () => {
  const { lastLogin, attempts, passwordExpired, token: sessionToken, userId, userloginuniqueid } = useSelector(({ session }) => session)
  const [count, setcount] = useState(0);
  const [errorList, setErrorList] = useState([]);
  const [errorBackList, setErrorBackList] = useState([]);
  const DEFAULT_ROWS_PER_PAGE = 200;

  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE)
  const [pageIndex, setPageIndex] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");
  const [filterText, setFilterText] = useState("");
  const [filterTextObj, setFilterTextObj] = useState({C_ERROR_NUMBER:null,C_ERROR_PHRASE:null,C_ERROR_RESOLUTION:null,C_ERROR_TRANSLATION:null });

  const columns = new Set([
    {
      dataName: 'C_ERROR_NUMBER',
      text: 'ERROR NUMBER',
      sortable: true
    },
    {
      dataName: 'C_ERROR_PHRASE',
      text: 'ERROR PHRASE',
    },

    
    {
      dataName: 'C_ERROR_TRANSLATION',
      text: 'ERROR TRANSLATION',
    },
    {
      dataName: 'C_ERROR_RESOLUTION',
      text: 'ERROR RESOLUTION',
    }
  ]);


  useEffect(() => {
    fetchData();
  }, [])


  const fetchData = () => {
    setIsLoading(true);
    axios.get('/ErrorManagerTranslation',
      {
        headers: {
          'username': userId, userloginuniqueid
        }
      }).then(res => {
        console.log(res?.data?.data);
        setTotalRecords(res?.data?.data.length);
        setIsLoading(false);
        setErrorList(res?.data?.data.slice(0, rowsPerPage));
        setErrorBackList([...res?.data?.data]);
      }).catch(err => {
        setIsLoading(false);
        setIsError(true);
        setError("There were errors processing your request");
      })
  }

   const multiFilter = (arr, filters) => {

    let filterKeys = Object.keys(filters);

    return arr.filter(eachObj => {

      return filterKeys.every(eachKey => {

        if (  (!filters[eachKey]?.length)  ||   (!filters[eachKey]?.trim()?.length) ) {

          return true; // passing an empty filter means that filter is ignored.

        }

        return eachObj[eachKey].trim().toLocaleLowerCase().includes(filters[eachKey].trim().toLocaleLowerCase())  ;

      });

    });

  };

 

  const filterData = (text) => {
    let tempData=[ ...errorBackList];
    let flteredList=multiFilter(tempData,filterTextObj)
    setErrorList(flteredList);
  }

 

  useEffect(() => {
    filterData();
  }, [filterTextObj])
  const filterTextChange = (e) => {
    let { name, value}= e.target;
    let extFilterTextObj = {...filterTextObj};
    extFilterTextObj[name]= value;

    setFilterTextObj(extFilterTextObj);
  }

  const renderMainGrid = useMemo(() => {

    if (totalRecords>0) {
      return (
        <div style={{ marginLeft: '30px' }}> 
         
         <input type="text" size = "15" name="C_ERROR_NUMBER" placeholder="Search By Error" onChange={(e) => { filterTextChange(e) }} value={filterTextObj.C_ERROR_NUMBER}  />
         <p></p>
         <input type="text" size = "60" name="C_ERROR_PHRASE" placeholder="Search By Phrase" onChange={(e) => { filterTextChange(e) }} value={filterTextObj.C_ERROR_PHRASE}  />
         <p></p>
         <input type="text" size = "60" name="C_ERROR_TRANSLATION" placeholder="Search By Translation" onChange={(e) => { filterTextChange(e) }} value={filterTextObj.C_ERROR_TRANSLATION}  />
         <p></p>
         <input type="text" size = "60" name="C_ERROR_RESOLUTION" placeholder="Search By Resolution" onChange={(e) => { filterTextChange(e) }} value={filterTextObj.C_ERROR_RESOLUTION}  />
         <p></p>
          
          <div className='GridWrapper'>
          <Grid
              columns={columns}
              dataTestId="test-contact"
              records={errorList || []}
            />   
          </div>
        </div>
      )
    }
    return null;
  }, [totalRecords, rowsPerPage, pageIndex, errorList, filterTextObj]);


  return (<>
    <ListPaneHeaderWrapper
      title="Error Translation Manager"
    />
    {isLoading ? (
      <LoadingPage />
    ) : isError ? (
      <ErrorPage
        error={error}
      />
    ) : (
      <>
        {renderMainGrid}
      </>
    )}

  </>);
}


export default ErrorManagerTranslation;