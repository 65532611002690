import React, { Component } from 'react';
import { connect } from 'react-redux';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import { Grid, LoadingIndicator, Modal } from 'ui-core-ssgr';
import { ModalBody } from 'ui-core'
import { ScrollableGridWrapper } from './styles';
import LiveGridHeader from '../LiveGridHeader';
import AdvancedClickableRow from '../../EditTestTab/AdvancedClickableRow';
import OverwriteRuleModal from '../../../../../RulesTabView/components/LiveRuleTab/OverwriteRuleModal';
import { LoadingContainer, GridLoadingWrapper, ModalTitlePaddingWrapper, StyledHeader } from '../../../../../../shared/styles/styledComponents';
import RuleDetailsModal from '../../../../../RuleDetailModals';
import {
  selectDetailElements,
  selectSelectedRuleDetail,
  selectRenderSafeRecords,
  selectGridConfig,
} from './selectors';
import {
  triggerRuleDetailsRequest,
  setIsViewingRuleDetails,
} from '../../../../../../redux/api/ruleTypesList/actions';
import {
  ruleDetailElementsTrigger,
  ruleDetailPostRequestTrigger,
  ruleDetailPutRequestTrigger,
  setIsCloningRuleDetail,
  setIsEditingRuleDetail,
  setIsPostingRuleDetail
} from '../../../../../../redux/api/ruleDetails/actions';
import {
  selectIsEditingRuleDetail,
  selectLoadingState,
  selectCheckTextRuleModalState,
  selectImageRuleModalState,
  selectReturnAddressModalState,
  selectProductionArrangementModalState,
  selectPDFTaggingModalState,
  selectTaxIdArrangementState  
} from '../../../../../RulesTabView/selectors';

class RuleDetailsGridWrapper extends Component {
  state = {
    selectedItemIds: new Set([]),
  };

  componentDidMount() {
    const {getRuleDetailElements, selectedRuleTypeId} = this.props;
    getRuleDetailElements({ selectedRuleTypeId });
  }

  generateGridRecordsFromProps = gridRecords => map(gridRecords, record => record.message ? {
    ...record,
    message: record.message.replace(/\r?\\n|\r/g, '\n'),
    completed: { bad: record.completed, nonCompliant: record.completed },
  } : {
    ...record,
    completed: { bad: record.completed, nonCompliant: record.completed },
  });

  toFixedTrunc(x, n) {
    const v = (typeof x === 'string' ? x : x.toString()).split('.');
    if (n <= 0) return v[0];
    let f = v[1] || '';
    if (f.length > n) return `${v[0]}.${f.substr(0, n)}`;
    while (f.length < n) f += '0';
    let inputValue = `${v[0]}.${f}`;
    inputValue = inputValue
      .replace(/,/g, '')
      .split('')
      .reverse()
      .join('')
      .replace(/(\d{3})/g, '$1,')
      .replace(/,$/, '')
      .split('')
      .reverse()
      .join('');
    return inputValue;
  }

  onCancel = () => {
    this.handleClickAction('cancel');
  }

  onRowClickThrough = (e, record) => {
    const {setSelectedRuleDetail} = this.props;
    setSelectedRuleDetail(record);
    this.handleClickAction('clickThrough');
  }

  renderModalContent = () => this.handleClickAction('renderModalContent')

  handleClickAction = (action) => {
    const {
      selectedRuleTypeId,
      isShowingCustomerServiceMessageModal,
      isShowingCheckTextRuleModal,
      isShowingErisaMessageModal,
      isShowingImageRuleModal,
      isShowingReturnAddressModal,
      isShowingSignatureRuleModal,
      isShowingProductionArrangementModal,
      isShowingTaxIdArrangementModal,
      isShowingHighDollarReviewModal,
      isShowingMemberIdModal,
      isShowingPDFTaggingModal,
      isReadOnlyRuleDetail,
      setIsShowingCustomerServiceMessageModal,
      setIsShowingCheckTextRuleModal,
      setIsShowingErisaMessageModal,
      setIsShowingImageRuleModal,
      setIsShowingReturnAddressModal,
      setIsShowingSignatureRuleModal,
      setIsShowingProductionArrangementModal,
      setIsShowingTaxIdArrangementModal,
      setShowingHighDollarReviewModal,
      setIsShowingPDFTaggingModal,
      setIsShowingMemberIdModal,
      setIsReadOnlyRuleDetail,
      setIsShowingGeneralMessageModal,
      isShowingGeneralMessageModal,
      setIsShowingProviderInsuredOptionMessageModal,
      isShowingProviderInsuredOptionMessageModal,
      setIsShowingAutoHoldModal,
      isShowingAutoHoldModal,
      setIsShowingDuplexRuleModal,
      isShowingDuplexRuleModal,

    } = this.props;
    switch ( selectedRuleTypeId ) {
      case 'CSM':
        if(action === 'clickThrough' || action === 'cancel'){
          const statusAction = action === 'clickThrough';
          setIsShowingCustomerServiceMessageModal(statusAction);
          setIsReadOnlyRuleDetail(statusAction);
        }
        if(action === 'renderModalContent' && isReadOnlyRuleDetail){
          return isShowingCustomerServiceMessageModal
        }
        break;
      case 'CHT':
        if(action === 'clickThrough' || action === 'cancel'){
          const statusAction = action === 'clickThrough';
          setIsShowingCheckTextRuleModal(statusAction);
          setIsReadOnlyRuleDetail(statusAction);
        }
        if(action === 'renderModalContent' && isReadOnlyRuleDetail){
          return isShowingCheckTextRuleModal
        }
        break;
      case 'ERI':
        if(action === 'clickThrough' || action === 'cancel'){
          const statusAction = action === 'clickThrough';
          setIsShowingErisaMessageModal(statusAction);
          setIsReadOnlyRuleDetail(statusAction);
        }
        if(action === 'renderModalContent' && isReadOnlyRuleDetail){
          return isShowingErisaMessageModal
        }
        break;
      case 'IMG':
        if(action === 'clickThrough' || action === 'cancel'){
          const statusAction = action === 'clickThrough';
          setIsShowingImageRuleModal(statusAction);
          setIsReadOnlyRuleDetail(statusAction);
        }
        if(action === 'renderModalContent' && isReadOnlyRuleDetail){
          return isShowingImageRuleModal
        }
        break;
      case 'RAD':
        if(action === 'clickThrough' || action === 'cancel'){
          const statusAction = action === 'clickThrough';
          setIsShowingReturnAddressModal(statusAction);
          setIsReadOnlyRuleDetail(statusAction);
        }
        if(action === 'renderModalContent' && isReadOnlyRuleDetail){
          return isShowingReturnAddressModal
        }
        break;
      case 'SIG':
        if(action === 'clickThrough' || action === 'cancel'){
          const statusAction = action === 'clickThrough';
          setIsShowingSignatureRuleModal(statusAction);
          setIsReadOnlyRuleDetail(statusAction);
        }
        if(action === 'renderModalContent' && isReadOnlyRuleDetail){
          return isShowingSignatureRuleModal;
        }
        break;
      case 'PAR':
        if(action === 'clickThrough' || action === 'cancel'){
          const statusAction = action === 'clickThrough';
          setIsShowingProductionArrangementModal(statusAction);
          setIsReadOnlyRuleDetail(statusAction);
        }
        if(action === 'renderModalContent' && isReadOnlyRuleDetail){
          return isShowingProductionArrangementModal;
        }
        break;
      case 'TAX':
        if(action === 'clickThrough' || action === 'cancel'){
          const statusAction = action === 'clickThrough';
          setIsShowingTaxIdArrangementModal(statusAction);
          setIsReadOnlyRuleDetail(statusAction);
        }
        if(action === 'renderModalContent' && isReadOnlyRuleDetail){
          return isShowingTaxIdArrangementModal;
        }
        break;
      case 'HDR':
        if(action === 'clickThrough' || action === 'cancel'){
          const statusAction = action === 'clickThrough';
          setShowingHighDollarReviewModal(statusAction);
          setIsReadOnlyRuleDetail(statusAction);
        }
        if(action === 'renderModalContent' && isReadOnlyRuleDetail){
          return isShowingHighDollarReviewModal;
        }
        break;
      case 'GEN':
        if(action === 'clickThrough' || action === 'cancel'){
          const statusAction = action === 'clickThrough';
          setIsShowingGeneralMessageModal(statusAction);
          setIsReadOnlyRuleDetail(statusAction);
        }
        if(action === 'renderModalContent'){
          return isShowingGeneralMessageModal;
        }
        break;
      case 'PIO':
        if(action === 'clickThrough' || action === 'cancel'){
          const statusAction = action === 'clickThrough';
          setIsShowingProviderInsuredOptionMessageModal(statusAction);
          setIsReadOnlyRuleDetail(statusAction);
        }
        if(action === 'renderModalContent'){
          return isShowingProviderInsuredOptionMessageModal;
        }
        break;
      case 'SSN':
        if(action === 'clickThrough' || action === 'cancel'){
          const statusAction = action === 'clickThrough';
          setIsShowingMemberIdModal(statusAction);
          setIsReadOnlyRuleDetail(statusAction);
        }
        if(action === 'renderModalContent'){
          return isShowingMemberIdModal;
        }
        break;
      case 'HLD':
        if(action === 'clickThrough' || action === 'cancel'){
          const statusAction = action === 'clickThrough';
          setIsShowingAutoHoldModal(statusAction);
          setIsReadOnlyRuleDetail(statusAction);
        }
        if(action === 'renderModalContent'){
          return isShowingAutoHoldModal;
        }
        break;
      case 'DPX':
        if(action === 'clickThrough' || action === 'cancel'){
          const statusAction = action === 'clickThrough';
          setIsShowingDuplexRuleModal(statusAction);
          setIsReadOnlyRuleDetail(statusAction);
        }
        if(action === 'renderModalContent'){
          return isShowingDuplexRuleModal;
        }
        break;
        case 'PDT':
          if(action === 'clickThrough' || action === 'cancel'){
            const statusAction = action === 'clickThrough';
            setIsShowingPDFTaggingModal(statusAction);
            setIsReadOnlyRuleDetail(statusAction);
          }
          if(action === 'renderModalContent' && isReadOnlyRuleDetail){
            return isShowingPDFTaggingModal
          }
          break;
      default: return null;
    }
    return null;
  }

  constructPageList = record => {
    const {selectedRuleTypeId} = this.props;
    for (let j = 0; j < record.length; j += 1) {
      const column = record[j];
      let selectedColumnLabel = '';
      let toNumber = null;
      if (column.signatureThreshold !== null && column.signatureThreshold !== undefined && selectedRuleTypeId === 'SIG') {
        selectedColumnLabel = 'signatureThresholdLabel';
        toNumber = typeof column.signatureThreshold === 'string' ? column.signatureThreshold : String(column.signatureThreshold);
        toNumber = this.toFixedTrunc( toNumber , 2);
      }
      if (column.highDollarThreshold !== null && column.highDollarThreshold !== undefined && selectedRuleTypeId === 'HDR') {
        selectedColumnLabel = 'highDollarThresholdLabel';
        toNumber = this.toFixedTrunc( String(column.highDollarThreshold) , 2);
      }
      column[selectedColumnLabel] = `$${toNumber}`;
    }
    return record;
  };

  displayLiveGrid = () => {
    const { gridConfig, loading, selectedRuleRecord, records } = this.props;
    let liveGrid = (
      <Grid
        {...gridConfig}
        rowComponent={AdvancedClickableRow}
        records={[]}
        emptyGridMessage="There are no rule details and no live rules at this time."
        supportSelection={false}
      />
    );

    if (loading.has('ruleDetailsGridLoading')) {
      liveGrid = (
        <LoadingContainer>
          <GridLoadingWrapper>
            <LoadingIndicator
              domID="group-grid-loading-indicator"
              length="45px"
            />
          </GridLoadingWrapper>
        </LoadingContainer>
      );
    } else if (selectedRuleRecord.status.length >= 1 &&
      selectedRuleRecord.status.includes('L')) {
      liveGrid = (
        <Grid
          {...gridConfig}
          rowComponent={AdvancedClickableRow}
          supportSelection={false}
          records={this.generateGridRecordsFromProps(
            this.constructPageList(records),
          )}
          onClickThrough={(e, record) => this.onRowClickThrough(e, record)}
        />
      );
    }

    return liveGrid;
  }

  render() {
    const {
      records,
      isPostingRulesForRuleTypes,
      selectedRuleRecord,
      selectedRecord,
      selectedRuleDetail,
      loading,
      selectedRuleTypeId,
      overwriteTestRuleRequestTrigger,
      isShowingOverwriteModal,
      setIsShowingOverwriteModal,
      isShowingCheckTextRuleModal,
      isShowingCustomerServiceMessageModal,
      setIsShowingCheckTextRuleModal,
      setIsShowingCustomerServiceMessageModal,
      isShowingErisaMessageModal,
      setIsShowingErisaMessageModal,
      isShowingSignatureRuleModal,
      setIsShowingSignatureRuleModal,
      setSelectedRuleDetail,
      notifications,
      setIsReadOnlyRuleDetail,
      isEditingRuleDetail,
      isReadOnlyRuleDetail,
      detailElements,
      setIsShowingImageRuleModal,
      setIsShowingReturnAddressModal,
      isShowingImageRuleModal,
      isShowingReturnAddressModal,
      isShowingProductionArrangementModal,
      setIsShowingProductionArrangementModal,
      setIsShowingTaxIdArrangementModal,
      isShowingTaxIdArrangementModal,
      isShowingHighDollarReviewModal,
      setShowingHighDollarReviewModal,
      isShowingMemberIdModal,
      setIsShowingMemberIdModal,
      setIsShowingGeneralMessageModal,
      setIsShowingProviderInsuredOptionMessageModal,
      setIsShowingAutoHoldModal,
      isShowingAutoHoldModal,
      setIsShowingDuplexRuleModal,
      isShowingDuplexRuleModal,
      isShowingPDFTaggingModal,
      setIsShowingPDFTaggingModal,
    } = this.props;
    const { selectedItemIds } = this.state;

    return (
      <>
        {isShowingOverwriteModal && (
          <OverwriteRuleModal
            selectedItemIds={selectedItemIds}
            selectedRecord={selectedRuleRecord}
            selectedRuleTypeId={selectedRuleTypeId}
            overwriteTestRuleRequestTrigger={overwriteTestRuleRequestTrigger}
            loading={loading}
            setIsShowingOverwriteModal={setIsShowingOverwriteModal}
          />
        )}
        {selectedRuleRecord.status.length >= 1 &&
        selectedRuleRecord.status.includes('L') ? (
            <LiveGridHeader
              headerName="Details"
              recordCount={!loading.has('ruleDetailsGridLoading') ? records.length : 0}
              selectedItemIds={selectedItemIds}
              isPostingRulesForRuleTypes={isPostingRulesForRuleTypes}
            />
          ) : (
            <LiveGridHeader
              headerName="Details"
              recordCount={0}
              selectedItemIds={selectedItemIds}
              isPostingRulesForRuleTypes={isPostingRulesForRuleTypes}
            />
          )}
        <ScrollableGridWrapper>
          {this.displayLiveGrid()}
        </ScrollableGridWrapper>
        {this.renderModalContent() && (
          <Modal
            isOpen={this.renderModalContent()}
            onModalToggle={this.onCancel}
          >
            <ModalBody>
              <ModalTitlePaddingWrapper>
                <StyledHeader>
                  {`Live Rule Detail: ${
                    selectedRuleRecord
                      ? selectedRuleRecord.name
                      : selectedRecord.name
                  }`}
                </StyledHeader>
              </ModalTitlePaddingWrapper>
              <RuleDetailsModal
                actionType='Read only'
                selectedRuleRecord={selectedRuleRecord}
                selectedRuleTypeId={selectedRuleTypeId}
                selectedRuleDetail={selectedRuleDetail}
                setSelectedRuleDetail={setSelectedRuleDetail}
                setIsShowingCustomerServiceMessageModal={setIsShowingCustomerServiceMessageModal}
                isShowingCustomerServiceMessageModal={isShowingCustomerServiceMessageModal}
                setIsShowingCheckTextRuleModal={setIsShowingCheckTextRuleModal}
                isShowingCheckTextRuleModal={isShowingCheckTextRuleModal}
                isShowingErisaMessageModal={isShowingErisaMessageModal}
                setIsShowingErisaMessageModal={setIsShowingErisaMessageModal}
                setIsShowingSignatureRuleModal={setIsShowingSignatureRuleModal}
                isShowingSignatureRuleModal={isShowingSignatureRuleModal}
                isShowingProductionArrangementModal={isShowingProductionArrangementModal}
                setIsShowingProductionArrangementModal={setIsShowingProductionArrangementModal}
                isShowingPDFTaggingModal={isShowingPDFTaggingModal}
                setIsShowingPDFTaggingModal={setIsShowingPDFTaggingModal}
                loading={loading}
                notifications={notifications}
                setIsEditingRuleDetail={setIsEditingRuleDetail}
                setIsCloningRuleDetail={setIsCloningRuleDetail}
                setIsPostingRuleDetail={setIsPostingRuleDetail}
                setIsReadOnlyRuleDetail={setIsReadOnlyRuleDetail}
                isEditingRuleDetail={isEditingRuleDetail}
                isReadOnlyRuleDetail={isReadOnlyRuleDetail}
                detailElements={detailElements}
                setIsShowingImageRuleModal={setIsShowingImageRuleModal}
                setIsShowingReturnAddressModal={setIsShowingReturnAddressModal}
                isShowingImageRuleModal={isShowingImageRuleModal}
                isShowingReturnAddressModal={isShowingReturnAddressModal}
                isShowingTaxIdArrangementRuleModal={isShowingTaxIdArrangementModal}
                setIsShowingTaxIdArrangementRuleModal={setIsShowingTaxIdArrangementModal}
                isShowingHighDollarReviewModal={isShowingHighDollarReviewModal}
                setShowingHighDollarReviewModal={setShowingHighDollarReviewModal}
                isShowingMemberIdModal={isShowingMemberIdModal}
                setIsShowingMemberIdModal={setIsShowingMemberIdModal}
                setIsShowingGeneralMessageModal={setIsShowingGeneralMessageModal}
                setIsShowingProviderInsuredOptionMessageModal={setIsShowingProviderInsuredOptionMessageModal}
                isShowingAutoHoldModal={isShowingAutoHoldModal}
                setIsShowingAutoHoldModal={setIsShowingAutoHoldModal}
                isShowingDuplexRuleModal={isShowingDuplexRuleModal}
                setIsShowingDuplexRuleModal={setIsShowingDuplexRuleModal}
              />
            </ModalBody>
          </Modal>
        )}
          
      </>
    );
  }
}

RuleDetailsGridWrapper.defaultProps = {
  records: [],
  selectedRuleRecord: {},
  selectedRuleTypeId: null
};

RuleDetailsGridWrapper.propTypes = {
  isPostingRulesForRuleTypes: PropTypes.bool,
  isCloningRule: PropTypes.bool,
  records: PropTypes.array,
  gridConfig: PropTypes.shape({ columns: PropTypes.shape({}) }).isRequired,
  setIsViewingRuleDetails: PropTypes.func,
  selectedRuleRecord: PropTypes.object,
  selectedRuleTypeId: PropTypes.string,
  columns: PropTypes.object,
  headerText: PropTypes.string,
  headerCount: PropTypes.number,
  toggleDetailsView: PropTypes.func.isRequired,
  loading: PropTypes.object,
  overwriteTestRuleRequestTrigger: PropTypes.func,
  isShowingOverwriteModal: PropTypes.bool,
  setIsShowingOverwriteModal: PropTypes.func,
};

const mapStateToProps = state => ({
  ...state,
  ...state.ruleDetails,
  detailElements: selectDetailElements(state),
  loading: selectLoadingState(state),
  selectedRuleDetail: selectSelectedRuleDetail(state),
  records: selectRenderSafeRecords(state),
  gridConfig: selectGridConfig(state),
  isCloningRuleDetail: state.ruleDetails.isCloningRuleDetail,
  isEditingRuleDetail: selectIsEditingRuleDetail(state),
  isPostingRuleDetail: state.ruleDetails.isPostingRuleDetail,
  isShowingCheckTextRuleModal: selectCheckTextRuleModalState(state),
  isShowingImageRuleModal: selectImageRuleModalState(state),
  isShowingReturnAddressModal: selectReturnAddressModalState(state),
  isShowingProductionArrangementModal: selectProductionArrangementModalState(state),
  isShowingPDFTaggingModal: selectPDFTaggingModalState(state),
  isShowingTaxIdArrangementModal: selectTaxIdArrangementState(state)
});

const mapDispatchToProps = dispatch => ({
  getRuleDetailElements: ({ selectedRuleTypeId }) =>
    dispatch(ruleDetailElementsTrigger({ selectedRuleTypeId })),
  getRuleDetails: (...args) => dispatch(triggerRuleDetailsRequest(...args)),
  goBackFromAdvancedToDefaultView: () =>
    dispatch(
      setIsViewingRuleDetails({
        isAdvancedViewShown: false,
        selectedRuleRecord: {},
      }),
    ),
  postRuleDetails: (...args) => dispatch(ruleDetailPostRequestTrigger(...args)),
  putRuleDetails: (...args) => dispatch(ruleDetailPutRequestTrigger(...args)),
  setIsCloningRuleDetail: bool => dispatch(setIsCloningRuleDetail(bool)),
  setIsEditingRuleDetail: bool => dispatch(setIsEditingRuleDetail(bool)),
  setIsPostingRuleDetail: bool => dispatch(setIsPostingRuleDetail(bool)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RuleDetailsGridWrapper);
