import FileTrackerListPane from "../../FileTracker/ListPane";

export const CLIENT_ADMINISTRATION_RECIPIENT_TYPE_GRID={
  columns: new Set([
    {
      dataName: 'recipientTypeTrans',
      text: 'Client Recipient Type',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'recipientType',
      text: 'Normalized Recipient Type',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'recipientTypeDesc',
      text: 'Web Recipient Type Description',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    }
  ]),
  selectedItemIds: new Set([]),
  bookmarkedItemIds: new Set([]),
  domID: 'clientAdministrationRecipientTypeGrid',
  dataTestId: 'clientAdministrationRecipientTypeGrid',
  isConfigurable: true,
  supportSelection: false
};

export const CLIENT_ADMINISTRATION_CLAIM_TYPE_GRID={
  columns: new Set([
    {
      dataName: 'claimTypeTrans',
      text: 'Client Claim Type',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'claimType',
      text: 'Normalized claim Type',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'claimTypeDesc',
      text: 'Web Claim Type Description',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    }
  ]),
  selectedItemIds: new Set([]),
  bookmarkedItemIds: new Set([]),
  domID: 'clientAdministrationClaimTypeGrid',
  dataTestId: 'clientAdministrationClaimTypeGrid',
  isConfigurable: true,
  supportSelection: false
};

export const CLIENT_ADMINISTRATION_DOCUMENT_TYPE_GRID={
  columns: new Set([
    {
      dataName: 'documentTypeTrans',
      text: 'Client Document Type',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'documentType',
      text: 'Normalized Document Type',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'documentTypeDesc',
      text: 'Web Document Type Description',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    }
  ]),
  selectedItemIds: new Set([]),
  bookmarkedItemIds: new Set([]),
  domID: 'clientAdministrationDocumentTypeGrid',
  dataTestId: 'clientAdministrationDocumentTypeGrid',
  isConfigurable: true,
  supportSelection: false
};

export const CLIENT_ADMINISTRATION_ADDRESS_EXCLUSION_GRID={
  columns: new Set([
    {
      dataName: 'acExclusionDesc',
      text: 'Description',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
  ]),
  selectedItemIds: new Set([]),
  initialSelectedItemIds: new Set([]),
  bookmarkedItemIds: new Set([]),
  domID: 'clientAdministrationAddressExclusionGrid',
  dataTestId: 'clientAdministrationAddressExclusionGrid',
  isConfigurable: true,
  supportSelection: false
};

export const CLIENT_INFORMATION_ASSOSISIATE_REPORTS_RECIPIENT_GRID={
  columns: new Set([
    {
      dataName: 'image',
      text: 'Has Assosiated Contact(s)',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'emailcode',
      text: "Email Code",
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'rpttype',
      text: "Report Type",
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'rptformat',
      text: "Format Code",
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'description',
      text: "Description",
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    }
  ]),
  // selectedItemIds: new Set([]),
  // bookmarkedItemIds: new Set([]),
  domID: 'clientInformationAssosiateReportsRecipientGrid',
  dataTestId: 'clientInformationAssosiateReportsRecipientGrid',
  isConfigurable: true,
  supportSelection: false
};

export const CLIENT_INFORMATION_ASSOSISIATE_REPORTS_CONTACTS_GRID={
  columns: new Set([
    {
      dataName: 'ccontactid',
      text: "Contact ID",
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'cfirstnm',
      text: "First Name",
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'clastnm',
      text: "Last Name",
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    }
  ]),
  bookmarkedItemIds: new Set([]),
  domID: 'clientInformationAssosiateReportsContactsGrid',
  dataTestId: 'clientInformationAssosiateReportsContactsGrid',
  isConfigurable: true,
  supportSelection: true
};

export const CLIENT_INFORMATION_CONTACTS_EDITOR_GRID={
  columns: new Set([
    {
      dataName: 'image',
      text: 'Has Assosiated Report(s)',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'ccontactid',
      text: "Contact ID",
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'cfirstnm',
      text: "First Name",
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'clastnm',
      text: "Last Name",
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'cemailaddr',
      text: "Email Address",
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'cccemail',
      text: "CC Email Address",
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    }
  ]),
  initialSelectedItemIds: new Set([]),
  bookmarkedItemIds: new Set([]),
  domID: 'clientInformationContactsEditorGrid',
  dataTestId: 'clientInformationContactsEditorGrid',
  isConfigurable: true,
  supportSelection: false
};

export const CLIENT_NCOA_PAF_GRID={
  columns: new Set([
    {
      dataName: 'ifBrokerExists',
      text: 'Has Assosiated Broker(s)',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'bCanEditDisplay',
      text: '',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'bActiveDisplay',
      text: '',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'cPAFID',
      text: "PAF ID",
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'cListOwnerNAICS',
      text: "Owner NAICS",
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'cListOwnerName',
      text: "Owner Name",
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'cListOwnerPhoneDisplay',
      text: "Owner Phone #",
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'cSignerName',
      text: "Signer Name",
      
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'dSignDate',
      text: "Sign Date",
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    }
  ]),
  selectedItemIds: new Set([]),
  bookmarkedItemIds: new Set([]),
  domID: 'clientNCOAPAFBrokersGrid',
  dataTestId: 'clientNCOAPAFBrokersGrid',
  isConfigurable: true,
  supportSelection: false
};

export const CLIENT_NCOA_BROKERS_GRID={
  columns: new Set([
    {
      dataName: 'sequence',
      text: "Seq.",
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'cBrokerID',
      text: "Broker ID",
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'cBrokerNAICS',
      text: "Broker NAICS",
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'cBrokerName',
      text: "Broker Name",
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'cSignerTitleControl',
      text: "Signer Title",
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'cSignerNameControl',
      text: "Signer Name",
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'cSignerDateControl',
      text: "Sign Date",
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    }
  ]),
  initialSelectedItemIds: new Set([]),
  bookmarkedItemIds: new Set([]),
  domID: 'clientNCOABrokersGrid',
  dataTestId: 'clientNCOABrokersGrid',
  isConfigurable: true,
  supportSelection: true
};

export const CLIENT_NCOA_BROKERS_EDITOR_GRID={
  columns: new Set([
    {
      dataName: 'bCanEditDisplay',
      text: '',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'cBrokerID',
      text: "Broker ID",
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'cBrokerNAICS',
      text: "Broker NAICS",
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'cBrokerName',
      text: "Broker Name",
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'cListOwnerPhoneDisplay',
      text: "Broker Phone #",
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
  ]),
  initialSelectedItemIds: new Set([]),
  bookmarkedItemIds: new Set([]),
  domID: 'clientNCOABrokersEditorGrid',
  dataTestId: 'clientNCOABrokersEditorGrid',
  isConfigurable: true,
  supportSelection: false
};
export const CLIENT_NCOA_PAF_EDITOR_GRID={
  columns: new Set([
    {
      dataName: 'bCanEditImg',
      text: '',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'bActiveImg',
      text: '',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'cPAFID',
      text: "PAF ID",
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'dPAF_Expiration',
      text: "PAF Expiration",
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'cListOwnerName',
      text: "Owner Name",
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'cListOwnerPhoneDisplay',
      text: "Owner Phone #",
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'cSignerName',
      text: "Signer Name",
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'cPAF_Type',
      text: "PAF Type",
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    }
  ]),
  // selectedItemIds: new Set([]),
  // bookmarkedItemIds: new Set([]),
  domID: 'clientNCOAPAFBrokersGrid',
  dataTestId: 'clientNCOAPAFBrokersGrid',
  isConfigurable: true,
  supportSelection: false
};

export const TERRORIST_WATCH_EXCLUSION_GRID={
  columns: new Set([
    {
      dataName: 'twExclusionDesc',
      text: 'Description',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    // {
    //   dataName: 'show',
    //   text: 'More...',
    //   sortable: true,
    //   isSorted: 0,
    //   cellType: 'text',
    // }
  ]),
  domID: 'terroristWatchExclutionGridId',
  dataTestId: 'terroristWatchExclutionGridId',
  isConfigurable: true,
  supportSelection: false
};


export const CLIENT_EMAIL_GRID={
  columns: new Set([
    {
      dataName: 'CEEOBRULE',
      text: 'EEOB Rule',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'CNAME',
      text: 'Name',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'CIDNUMBER',
      text: 'ID Number',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'CEMAILADDR',
      text: 'Client Email Address',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'BSUPPRESS',
      text: 'Suppressed',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'BBADEMAIL',
      text: 'Bad Email',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
  ]),
  domID: 'clientEmail',
  dataTestId: 'clientEmail',
  isConfigurable: true,
  supportSelection: false,  
};

export const SELECT_YES_NO_OPTIONS = [{label:'Yes',value: 1},{label: 'No',value: 0}]
