/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import styled from 'styled-components/dist/styled-components';
import { useDispatch } from 'react-redux';
import moment from 'moment'
import {
  Grid,
  Modal,
  ModalHeader,
  ModalBody,
  useLayout,
  Button,
  ModalFooter
  // SelectDropdown, Input, Button, DateRangePickerV2, Checkbox 
} from 'ui-core/dist'
import { LoadingPage } from '../../components';
import ErrorPage from '../ErrorPage';
import ListPaneHeaderWrapper from '../../components/ErrorDisplaySection/ListPaneHeaderWrapper';
import GridPaginationWrapper from '../../components/GridPaginationWrapper';
import { MANIFEST_ENTRY_MAIN_GRID, USPSApprover_OPTIONS } from './Modules/constants';
import useSortableGrid from '../../hooks/useSortableGrid';
import useSelectableGrid from '../../hooks/useSelectableGrid'
import { resetManifestEntryRecord, setManifestEntryRecord } from './Modules/action';
import { useGetManifestRecords, useSetManifestRecords } from '../../api/useManifestEntry';
import useNotificationBannerHook from '../../useNotificationBannerHook';

const StyledGridWrapper = styled.div`
&.GridWrapper #grid-wrapper-manifestEntryConfirmationGrid{
  height: 250px !important;
  overflow:auto !important;
 }
`
const ManifestEntryMain = () => {
  const {
    expandCollapseListPaneButtonConfig,
    openDetailPane,
    closeDetailPane
  } = useLayout()
  const { notify } = useNotificationBannerHook();
  const dispatch = useDispatch()
  const DEFAULT_ROWS_PER_PAGE = 200;

  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE)
  const [pageIndex, setPageIndex] = useState(0);
  const [count, setcount] = useState(0);
  const [isOpenCloseJobsModal, setisOpenCloseJobsModal] = useState(false)
  const [validationMessage, setvalidationMessage] = useState(null)

  const { data: setRecordsResp, postData: setManifestRecords, metaData: setManifestRecordsMetadata,
    isLoading: isLoadingsetManifestRecords, isError: isErrorsetManifestRecords, error: errorsetManifestRecords } = useSetManifestRecords()

  const onConfirmationYesClick = () => {

    if (selectedRecords && selectedRecords.size > 0) {
      const sr = Array.from(selectedRecords);
      let pstrXml = `
      <![CDATA[<collection type="manifest-information">`;
      for (const item of sr) {
        const { ABFApprover, JobNumber, MailDate, PkgCount, Postage, USPSApprover, USPSJob, Weight } = item;
        let approver = (USPSApprover || '').trim();
        if (USPSJob === 'TRUE' && !((USPSApprover || '').trim())) {
          approver = USPSApprover_OPTIONS[1]
        }
        pstrXml = pstrXml + `<Manifest 
        JobNumber="${JobNumber}"
        MailDate="${MailDate ? moment(MailDate).format('MM/DD/YYYY') : ''}"
        PkgCount="${PkgCount}"
        Postage="${Postage}"
        Weight="${Weight}" 
        USPSApprover="${approver.trim()}"
        ABFApprover="${(ABFApprover || '').trim()}"
        />`
      }
      pstrXml = pstrXml + "</collection>]]>"
      setManifestRecords({ pstrXml, count })
      setcount(count + 1)
    }

  };

  useEffect(() => {
    if (setManifestRecordsMetadata) {
      const { status: respStatus, statusText } = setManifestRecordsMetadata;
      if (respStatus === 200 || statusText === "OK") {
        if (setRecordsResp && setRecordsResp.fs) {
          const { fs } = setRecordsResp;
          if (fs) {
            notify(fs)
          }
          return;
        }
        notify(`Data Saved`, 'positive')
        closeDetailPane()
        setisOpenCloseJobsModal(false)
        onClearSelectionCurrentPage()
        if (refetchManifestEntryList) refetchManifestEntryList()
      }
    }
  }, [setManifestRecordsMetadata, setRecordsResp])

  const refetchManifestEntryList = () => {

    setRowsPerPage(DEFAULT_ROWS_PER_PAGE)
    setPageIndex(0)
    postData(JSON.parse(JSON.stringify({
      "maxRecords": DEFAULT_ROWS_PER_PAGE,
      "rowPosition": 0,
      "sortField": sortConfig.sortDataName,
      "sortDescending": sortConfig.sortDescending,
      count,
      firstLoad: true
    })))
    setcount(count + 1)
  }

  useEffect(() => {
    if (postData) {
      closeDetailPane();
      dispatch(resetManifestEntryRecord());
      refetchManifestEntryList()
    }
  }, [])

  const fetchData = useCallback((args) => {
    let sortcolumn; let sd; let pi; let type;
    if (args) {
      ({ sortcolumn, sd, pi, type } = args);
    }
    closeDetailPane();
    dispatch(resetManifestEntryRecord());
    postData(JSON.parse(JSON.stringify({
      "maxRecords": rowsPerPage,
      "rowPosition": pi === undefined || pi === null ? pageIndex : pi,
      "sortField": sortcolumn === undefined || sortcolumn === null ? sortConfig.sortDataName : sortcolumn,
      "sortDescending": sd === undefined || sd === null ? sortConfig.sortDescending : sd,
      type,
      count,
      firstLoad: false
    })))
    setcount(count + 1)

  }, [rowsPerPage, pageIndex, sortConfig]);

  const { metaData: metaDataManifestRecords, data: manifestRecords, postData, isLoading, isError, error } = useGetManifestRecords()
  const disabledCondition = useCallback(() => selectedItemIdsCurrentPage.size > 1, [selectedItemIdsCurrentPage])
  const { onRowSelect: onRowSelectJobs, selectedItemIdsCurrentPage, selectedRecords, disabledItemIds, onClearSelectionCurrentPage } =
    useSelectableGrid({ uniqueKey: 'JobNumber', list: manifestRecords?.Manifest, isUicl: true, disabledCondition })
  const [sortConfig, getArgumentsAndSortGrid] = useSortableGrid(fetchData, "JobNumber", "true");


  const onShowMoreClick = useCallback((record) => {
    dispatch(setManifestEntryRecord({ manifestEntrySelectedRecord: JSON.parse(JSON.stringify(record)), refetchManifestEntryList }));
    openDetailPane();
  }, [manifestRecords]);

  const onCloseJobsModal = () => setisOpenCloseJobsModal(false)
  const validatePkgCount = (pkgCount) => {
    if (pkgCount && !isNaN(pkgCount)) {
      if (parseInt(pkgCount) < 0) {
        return true
      }
      return false
    }
    return true
  }
  const validatePostage = (postage) => {
    if (postage && !isNaN(postage)) {
      if (parseFloat(postage) < 0.0) {
        return true
      }
      return false
    }
    return true
  }
  const validateWeight = (weight) => {
    if (weight && !isNaN(weight)) {
      if (parseFloat(weight) <= 0.0) {
        return true
      }
      return false
    }
    return true
  }
  const renderCloseJobsButton = useMemo(() => {

    let disabled = true;
    setvalidationMessage(null)
    if (selectedRecords && selectedRecords.size > 0) {
      disabled = false;
      const sr = Array.from(selectedRecords);
      if (sr && sr.length > 0) {
        for (let index = 0; index < sr.length; index++) {
          const e = sr[index];
          if (validatePkgCount(e?.PkgCount) || validatePostage(e?.Postage) || validateWeight(e?.Weight)) {
            setvalidationMessage('Records with invalid Package count, Postage or Weight selected. Please correct them to proceed.')
            disabled = true;
            break;
          }
        }
      }
    }
    return (
      <Button
        id='closeJobs'
        size="small"
        name="Close Jobs"
        disabled={disabled}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setisOpenCloseJobsModal(true)
        }}
      />
    )
  }, [isOpenCloseJobsModal, selectedItemIdsCurrentPage, manifestRecords, selectedRecords, disabledItemIds])
  const renderCloseJobsModal = useMemo(() => {

    if (selectedItemIdsCurrentPage && selectedItemIdsCurrentPage.size > 0
      && selectedRecords && selectedRecords.size > 0 && manifestRecords?.Manifest?.length > 0) {
      const sIds = Array.from(selectedItemIdsCurrentPage);
      const data = JSON.parse(JSON.stringify(manifestRecords?.Manifest.filter(x => sIds.find(y => y === x?.JobNumber))));

      return (
        <Modal isOpen={isOpenCloseJobsModal}>
          <ModalHeader
            title="Close Selected Jobs"
            onClose={() => onCloseJobsModal()}
          />
          <ModalBody>
            {isOpenCloseJobsModal ? (
              <div style={{ marginLeft: '34px', marginBottom: '20px' }} className="marginLeft">
                <div className="marginLeft">
                  <table border="0">
                    <tr>
                      <td><label>Click Submit to Close all the Jobs shown below. </label></td>
                    </tr>
                  </table>
                  <StyledGridWrapper className='GridWrapper'>
                    <Grid
                      {...MANIFEST_ENTRY_MAIN_GRID}
                      domID='manifestEntryConfirmationGrid'
                      dataTestId='manifestEntryConfirmationGrid'
                      supportSelection={false}
                      records={data || []}
                      onClickThrough={(e, rec) => {
                        e.preventDefault();
                        e.stopPropagation();
                        return false;
                      }}
                    />
                  </StyledGridWrapper>

                </div>
              </div>
            ) : null}
          </ModalBody>
          <ModalFooter>
            <div>
              <Button
                id='closeJobsSubmit'
                size="small"
                name="Submit"
                onClick={() => onConfirmationYesClick()}
              />
            </div>
          </ModalFooter>
        </Modal>
      )
    }
    return null;
  }, [isOpenCloseJobsModal, selectedItemIdsCurrentPage, manifestRecords, selectedRecords, disabledItemIds
  ])
  const renderMainGrid = useMemo(() => {

    if (manifestRecords) {
      const { Manifest, totalRecords } = manifestRecords;
      const data = JSON.parse(JSON.stringify(Manifest));

      return (
        <>
          <div style={{ marginLeft: '22px' }}>
            <GridPaginationWrapper
              selectRecordOptions={[
                { label: '100', value: '100' },
                { label: '200', value: '200' },
                { label: '300', value: '300' },
                { label: '500', value: '500' },
                { label: '1000', value: '1000' },
              ]}
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={setRowsPerPage}
              pageIndex={pageIndex}
              onChangePageIndex={setPageIndex}
              onShowButtonCLick={fetchData}
              totalRecords={totalRecords}
              defaultRowsPerPage={DEFAULT_ROWS_PER_PAGE}
            />
          </div>

          {data.length === 0 ? (
            <Grid
              {...MANIFEST_ENTRY_MAIN_GRID}
              records={data || []}
              onClickThrough={(e, rec) => {
                e.preventDefault();
                e.stopPropagation();
                if (rec) {
                  const record = JSON.parse(JSON.stringify(rec))
                  if (onShowMoreClick) {
                    dispatch(resetManifestEntryRecord())
                    return onShowMoreClick(record);
                  }
                  return false;
                }
                return false;
              }}
              initialSortingKey={`${sortConfig.sortDataName}|${sortConfig.sortDescending === "true" ? 'SORT_DESCENDING' : 'SORT_ASCENDING'}`}
            // onSortGridColumn={(e,state)=>getArgumentsAndSortGrid(state)}
            />
          ) : (
              <Grid
                {...MANIFEST_ENTRY_MAIN_GRID}
                records={data || []}
                selectionKey="JobNumber"
                initialSelectedItemIds={selectedItemIdsCurrentPage}
                initialDisabledItemIds={new Set([])}
                onRowSelect={onRowSelectJobs}
                onSelectAll={onRowSelectJobs}
                onClickThrough={(e, rec) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (rec) {
                    const record = JSON.parse(JSON.stringify(rec))
                    if (onShowMoreClick) {
                      dispatch(resetManifestEntryRecord())
                      return onShowMoreClick(record);
                    }
                    return false;
                  }
                  return false;
                }}
                initialSortingKey={`${sortConfig.sortDataName}|${sortConfig.sortDescending === "true" ? 'SORT_DESCENDING' : 'SORT_ASCENDING'}`}
                onSortGridColumn={(e, state) => getArgumentsAndSortGrid(state)}
              />
            )}
        </>
      )
    }
    return null;
  }, [manifestRecords, rowsPerPage, pageIndex, selectedItemIdsCurrentPage, selectedRecords, isOpenCloseJobsModal, disabledItemIds]);

  return (
    <>
      <ListPaneHeaderWrapper
        title="Manifest Entry"
        layoutControls={[expandCollapseListPaneButtonConfig]}
      />
      {isLoading || isLoadingsetManifestRecords ? (
        // replace with a better loading component
        <LoadingPage />
      ) : isError || isErrorsetManifestRecords ? (
        // replace with a better error component
        <ErrorPage
          // this prop isn't real but is an example
          error={error || errorsetManifestRecords}
        />
      ) : (
            <>
              <div style={{ marginLeft: '20px' }} className="marginLeft">
                {validationMessage ? (
                  <><table><tr>
                    <td>
                      <span className="required-glyph"><label style={{ color: 'red' }}>{validationMessage}</label></span>
                    </td>
                  </tr><tr><p></p></tr></table></>
                ) : null}
                <div style={{ marginTop: '20px' }} className="displayFlex">
                  {renderCloseJobsButton}
                </div>
                {renderMainGrid}
                {renderCloseJobsModal}
              </div>
            </>
          )}
    </>
  )
}

export default ManifestEntryMain
