/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-lonely-if */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/dist/styled-components'
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { Grid, 
  LoadingIndicator, 
  Button,
  useLayout, 
  ListPaneHeader
} from 'ui-core/dist'
import { CircleCheck} from 'ui-core/dist/icons';
import { LoadingPage ,AnchorInput,SelectDropdown} from '../../../components';
import ErrorPage from '../../ErrorPage';
import ListPaneHeaderWrapper from '../../../components/ErrorDisplaySection/ListPaneHeaderWrapper';
import {useGetAssosicateReportsTable, useGetAssosicateReportsClientContactTable, useUpdateClientEmailRecipientData} from '../../../api/useClientInformationAssosicateReports'
import {CLIENT_INFORMATION_ASSOSISIATE_REPORTS_RECIPIENT_GRID,
  CLIENT_INFORMATION_ASSOSISIATE_REPORTS_CONTACTS_GRID} from '../Modules/constants'
import useSortableGrid from '../../../hooks/useSortableGrid'
import useSelectableGrid from '../../../hooks/useSelectableGrid'
import { floatToDollarString } from '../../../commonResources/helpers/money';
import { isA11yClick } from '../../../commonResources/helpers/domEvents';
import { mapIterable } from '../../../commonResources/helpers/iterables';
import useNotificationBannerHook from '../../../useNotificationBannerHook';

const BLOCKING_ERROR='cannot insert NULL into ("ABFRAICADMIN"."SYS_CLIENT_EMAIL_RECIPIENTS"."C_EMAIL_CODE"'
const StyledGridWrapper=styled.div`
&.GridWrapper #grid-wrapper-clientInformationAssosiateReportsContactsGrid {
  overflow:auto !important;
  height: 240px !important;
}
&.GridWrapper #grid-wrapper-clientInformationAssosiateReportsRecipientGrid{
  overflow:auto !important;
  height: 390px !important;
  & thead tr > th:first-child {
    display: none !important;
  }
  & tbody tr > td:first-child {
    width: 60px !important;
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  & tbody {
    tr:nth-child(even) {
      background-color: #F4F6F9;
    }
  }
}
`
const StyleButton=styled(Button)`
height: 18px !important;
width: 53px !important;
background: transparent;
box-shadow: none;
&:hover {
  background: transparent;
box-shadow: none;
}
`
const onKeyPress = (e, callback) => {
  if (isA11yClick(e)) {
    e.preventDefault();
    callback(e);
  }
};
export const ClickableRow = (props) => {
  const classes = [];
  if (props.record.emboldened) classes.push('emboldened');
  if (props.isFocused) classes.push('focused');
  return (
    <tr
      onClick={(e) => { props.onClickThrough(e, props.record); }}
      onKeyPress={e => onKeyPress(e, props.onClickThrough)}
      role="button"
      tabIndex="0"
      className={classes.join(' ')}
      style={{background:props.record.selected ? 'orange': ''}}
    >
      {
        mapIterable(props.columns.values(), (c) => (!props.disabledColumns.has(c.dataName)
          ? (
            <td
              style={{padding: '10px 8px'}}
              key={c.dataName}
              className={c.dataName}
            >
              {c.isCurrency
                ? floatToDollarString(props.record[c.dataName])
                : props.record[c.dataName]}
            </td>
          )
          : null))
      }
    </tr>
  );
};

const AssosicateReports=()=>{
  const {
    expandCollapseListPaneButtonConfig,
  } = useLayout()
  const {notify}=useNotificationBannerHook();
  const [currentlyAssosiatedReports, setcurrentlyAssosiatedReports]=useState([]);
  const [count, setcount]=useState(0)

  const {isLoading: isLoadinggetAssosicateReportsTable, 
    isError:isErrorgetAssosicateReportsTable, 
    error:errorgetAssosicateReportsTable, 
    postData: getAssosicateReportsTable, 
    setReports,
    EmailRecipients, EmailRecipientsCount, Reports, ReportsCount}=useGetAssosicateReportsTable();

  const {isLoading: isLoadinggetAssosicateReportsClientContactTable, 
    isError: isErrorgetAssosicateReportsClientContactTable, 
    error: errorgetAssosicateReportsClientContactTable, 
    postData: getAssosicateReportsClientContactTable, 
    setClientContactsInfo,
    ClientContactsCanDeleteInfo, ClientContactsCanDeleteInfoCount, ClientContactsInfo, ClientContactsInfoCount}=useGetAssosicateReportsClientContactTable();

  const {postData: updateClientEmailRecipientsData, 
    isLoading: isLoadingupdateClientEmailRecipientsData,
    isError: isErrorupdateClientEmailRecipientsData,
    error: errorupdateClientEmailRecipientsData, 
    data: updateClientEmailRecipientsDataResp}=useUpdateClientEmailRecipientData();

  const [sortConfigRecipient,_r, gridSortingRecipient]=useSortableGrid(()=>false,"emailcode","true", setReports);
  const disabledCondition=useCallback(()=>currentlyAssosiatedReports.length===0,[currentlyAssosiatedReports])

  const {onRowSelect: onRowSelectContacts,selectedItemIdsCurrentPage: selectedItemIdsCurrentPageContacts, onSetSelectedItemIdsExplicitly, disabledItemIds}=
    useSelectableGrid({uniqueKey:'ccontactid',list:ClientContactsInfo,isUicl:true, disabledCondition})
  const [sortConfigContacts,_c, gridSortingContacts]=useSortableGrid(()=>false,"cclientnum","true", setClientContactsInfo);

  const getData=()=>{
    getAssosicateReportsTable({count})
    getAssosicateReportsClientContactTable({count})  
    setcount(count+1)
  }
  useEffect(()=>{
    getData()
  },[])

  useEffect(()=>{
    if (currentlyAssosiatedReports && currentlyAssosiatedReports.length>0) {
      // let contactids=JSON.parse(JSON.stringify(Array.from(selectedItemIdsCurrentPageContacts)))
      let contactids=[];
      currentlyAssosiatedReports.forEach((emailcode)=>{
        const Recipients=EmailRecipients.filter(x=>x.emailcode===emailcode);
        if (Recipients && Recipients.length>0) {
          const cids=Recipients.map((x)=>x.contactid);
          contactids=[...contactids, ...cids]
        }
      })
      const selectionids=new Set(contactids);
      if(onSetSelectedItemIdsExplicitly) onSetSelectedItemIdsExplicitly(JSON.parse(JSON.stringify(Array.from(selectionids))))
    } else {
      if(onSetSelectedItemIdsExplicitly) onSetSelectedItemIdsExplicitly(JSON.parse(JSON.stringify([])))
    }
  },[currentlyAssosiatedReports])

  const validateSubmit=useMemo(()=>{
    if (currentlyAssosiatedReports && currentlyAssosiatedReports.length>0) {
      const contactids=JSON.parse(JSON.stringify(Array.from(selectedItemIdsCurrentPageContacts)))
      let defcontactids=[];
      currentlyAssosiatedReports.forEach((emailcode)=>{
        const Recipients=EmailRecipients.filter(x=>x.emailcode===emailcode);
        if (Recipients && Recipients.length>0) {
          const cids=Recipients.map((x)=>x.contactid);
          defcontactids=[...defcontactids, ...cids]
        }
      })
      if (JSON.stringify(Array.from(new Set(contactids)))!==JSON.stringify(Array.from(new Set(defcontactids)))) {
        return true;
      }
      return false;
    }
    return false;
  },[currentlyAssosiatedReports, selectedItemIdsCurrentPageContacts, EmailRecipients])

  const onClearCurrentlyAssosiatedReports=()=>{
    if(onSetSelectedItemIdsExplicitly) onSetSelectedItemIdsExplicitly(JSON.parse(JSON.stringify([])))
  }
  const onNewCurrentlyAssosiatedReports=()=>{
    setcurrentlyAssosiatedReports(JSON.parse(JSON.stringify([])))
  }
  useEffect(()=>{
    if (updateClientEmailRecipientsDataResp) {
     
      const {fs}=updateClientEmailRecipientsDataResp;
      if (!fs || (fs||"").indexOf(BLOCKING_ERROR)===-1) {
        notify('Client Email Recipient Assosiation updated successfully', 'positive');
        onClearCurrentlyAssosiatedReports();
        onNewCurrentlyAssosiatedReports();
        getData();
      } else{
        notify('Error occurred during updation of Reports assosiation', 'negative')
      }
    }
  },[updateClientEmailRecipientsDataResp])
  const onSaveCurrentlyAssosiatedReports=()=>{

    const strEmailCode=currentlyAssosiatedReports[0];
    const contactids=JSON.parse(JSON.stringify(Array.from(selectedItemIdsCurrentPageContacts)))
    let defcontactids=[];
    currentlyAssosiatedReports.forEach((emailcode)=>{
      const Recipients=EmailRecipients.filter(x=>x.emailcode===emailcode);
      if (Recipients && Recipients.length>0) {
        const cids=Recipients.map((x)=>x.contactid);
        defcontactids=[...defcontactids, ...cids]
      }
    })
    const strContactIDsOrig=defcontactids.join(',')
    const strContactIDsMods=contactids.join(',')
    updateClientEmailRecipientsData({strEmailCode, strContactIDsOrig, strContactIDsMods, count});
    setcount(count+1)
  }

  const renderstrReportsHTMLTable=useMemo(()=>{
    if (Reports && EmailRecipients) {
      const temp=JSON.parse(JSON.stringify(Reports))
      const data=temp.map((t, index)=>{
        const currentEmailCode=t.emailcode;
        const f=EmailRecipients.find(x=>x.emailcode===currentEmailCode);
        const f1=currentlyAssosiatedReports.filter(x=>x===currentEmailCode).length
        let image=null
        if (f) {
          image=(
            <>
              <StyleButton data-tip data-for={`reporttooltip_${index}_${currentEmailCode}`}
                onClick={()=>false}>
                <CircleCheck
                  className="settings"
                  fillColor="#37474F"
                  title="Settings"
                />
              </StyleButton>
              <ReactTooltip id={`reporttooltip_${index}_${currentEmailCode}`} type='dark' effect='solid'>
                <span>Has Associated Contact(s)</span>
              </ReactTooltip>
            </>
          )
        }
        return {
          ...t,
          image,
          selected:f1?true:false
        }
      })
      return (
        <>
          <StyledGridWrapper className='GridWrapper'>
            <Grid
              {...CLIENT_INFORMATION_ASSOSISIATE_REPORTS_RECIPIENT_GRID}
              records={data || []}
              rowComponent={ClickableRow}
              onClickThrough={(e,rec)=>{
                e.preventDefault();
                e.stopPropagation();
                if (rec) {                  
                  // ...currentlyAssosiatedReports
                  setcurrentlyAssosiatedReports(JSON.parse(JSON.stringify([rec.emailcode||''])))
                }
                return false;
              }}
              initialSortingKey={`${sortConfigRecipient.sortDataName}|${sortConfigRecipient.sortDescending==="true"?'SORT_DESCENDING':'SORT_ASCENDING'}`}
              onSortGridColumn={(e,state)=>{
                const { sortingKey } = state;
                const sortingKey$split = sortingKey.split('|');
                const dataName = sortingKey$split[0];
                const f=Array.from(CLIENT_INFORMATION_ASSOSISIATE_REPORTS_RECIPIENT_GRID.columns).find(x=>x.dataName===dataName);
                if (f) {
                  const {cellType}=f;
                  if(gridSortingRecipient) gridSortingRecipient({sortingKey, type:cellType}, temp)
                }
              }}
            />
          </StyledGridWrapper>
        </>
      )
    }
    return null;
  },[
    EmailRecipients,
    EmailRecipientsCount,
    Reports,
    ReportsCount,
    sortConfigRecipient,
    currentlyAssosiatedReports
  ])

  const renderstrClientContactsHTMLTable=useMemo(()=>{
    if (ClientContactsInfo) {
      const data=JSON.parse(JSON.stringify(ClientContactsInfo))
      return (
        <>
          <StyledGridWrapper className='GridWrapper'>
            <Grid
              {...CLIENT_INFORMATION_ASSOSISIATE_REPORTS_CONTACTS_GRID}
              records={data || []}
              selectionKey="ccontactid"
              initialSelectedItemIds={selectedItemIdsCurrentPageContacts}
              initialDisabledItemIds={disabledItemIds}
              onRowSelect={onRowSelectContacts}
              onSelectAll = {onRowSelectContacts}
              supportSelection={data.length !== disabledItemIds.size}
              onClickThrough={(e,rec)=>false}
              initialSortingKey={`${sortConfigContacts.sortDataName}|${sortConfigContacts.sortDescending==="true"?'SORT_DESCENDING':'SORT_ASCENDING'}`}
              onSortGridColumn={(e,state)=>{
                const { sortingKey } = state;
                const sortingKey$split = sortingKey.split('|');
                const dataName = sortingKey$split[0];
                const f=Array.from(CLIENT_INFORMATION_ASSOSISIATE_REPORTS_CONTACTS_GRID.columns).find(x=>x.dataName===dataName);
                if (f) {
                  const {cellType}=f;
                  if(gridSortingContacts) gridSortingContacts({sortingKey, type:cellType}, data)
                }
              }}
            />
          </StyledGridWrapper>
        </>
      )
    }
    return null;
  },[
    ClientContactsCanDeleteInfo,
    ClientContactsCanDeleteInfoCount,
    ClientContactsInfo,
    ClientContactsInfoCount,
    selectedItemIdsCurrentPageContacts,
    sortConfigContacts,
    disabledItemIds
  ])
  const renderListPaneheader=useMemo(()=>{
    if (updateClientEmailRecipientsDataResp) {
      const {fs}=updateClientEmailRecipientsDataResp;
      if (fs && fs.indexOf(BLOCKING_ERROR)!==-1) {
        return <ListPaneHeaderWrapper
        title="Client Information Manager - Associate Reports Editor"
        layoutControls={[expandCollapseListPaneButtonConfig]}
      />
      }
      return <ListPaneHeader
      title="Client Information Manager - Associate Reports Editor"
      layoutControls={[expandCollapseListPaneButtonConfig]}
    />
    }
    return <ListPaneHeaderWrapper
    title="Client Information Manager - Associate Reports Editor"
    layoutControls={[expandCollapseListPaneButtonConfig]}
  />
  },[updateClientEmailRecipientsDataResp])
  return (
    <>
      {renderListPaneheader}
      {isLoadinggetAssosicateReportsTable || isLoadinggetAssosicateReportsClientContactTable || isLoadingupdateClientEmailRecipientsData ? (
      // replace with a better loading component
        <LoadingPage />
      ) : isErrorgetAssosicateReportsTable || isErrorgetAssosicateReportsClientContactTable || isErrorupdateClientEmailRecipientsData ? (
      // replace with a better error component
        <ErrorPage
          // this prop isn't real but is an example
          error={errorgetAssosicateReportsTable || errorgetAssosicateReportsClientContactTable || errorupdateClientEmailRecipientsData}
        />
      ) : (
        <>
          <div style={{marginLeft: '20px'}} className="marginLeft">
            <table border="0" cellspacing="1" cellpadding="3" width="100%">
              <tr>
                <td>
                  <div className="displayFlex">
                    <strong><label style={{fontSize: '14px'}}>&nbsp;&nbsp;Reports:</label></strong>
                  </div>
                  {renderstrReportsHTMLTable}
                </td>
              </tr>
              <tr style={{position: 'relative', top: '20px'}}>
                <td>
                  <div className="displayFlex">
                    <strong><label style={{fontSize: '14px'}}>&nbsp;&nbsp;Client Contacts:</label></strong>
                  </div>
                  {renderstrClientContactsHTMLTable}
                </td>
              </tr>
              <tr style={{position: 'relative', top: '40px'}}>
                <td className="setLocalStyleCol3">
                  <div className="displayFlex">
                    <div className="marginLeft">
                      <Button
                        domID="btn_clear"
                        buttonType="standard"
                        size="small"
                        name="CLEAR"
                        onClick={()=>onClearCurrentlyAssosiatedReports()}
                      />
                    </div>
                    <div className="marginLeft">
                      <Button
                        domID="btn_new"
                        buttonType="standard"
                        size="small"
                        name="NEW"
                        onClick={()=>onNewCurrentlyAssosiatedReports()}
                      />
                    </div>
                    <div className="marginLeft">
                      <Button
                        domID="btn_submit"
                        buttonType="standard"
                        size="small"
                        name="SAVE"
                        disabled={!validateSubmit}
                        onClick={()=>onSaveCurrentlyAssosiatedReports()}
                      />
                    </div>
                  </div>
			
                </td>
              </tr>
            </table>
          </div>
          {/* {renderMainGrid} */}
        </>
      )}
    </>
  )

}

export default AssosicateReports;
