import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader, DatePickerV2, TimePicker, Checkbox, Text, Tooltip } from 'ui-core/dist';
import Button from 'ui-core/dist/Button';
import ButtonGroup from 'ui-core/dist/ButtonGroup';
import styled from 'styled-components';
import axios from '../../axios';
import moment from 'moment';
import useNotificationBannerHook from '../../useNotificationBannerHook';
import {
  Icons,
} from 'ui-core-ssgr';

import './index.css';

const ModalContentWrapper = styled.div`
    padding-left: 20px;
    height: 357px;
    padding-right: 20px;
    & > div {
      height: 100%;
      & label {
        font-weight:normal;
      }
    }
`;
const ModalHeaderWrapper = styled.div`
    & > div {
      padding: 15px 14px;
    }
`;
const ModalFooterWrapper = styled.div`
  & div.modal-footer {
    padding: 0px 20px 10px;
  }
`;
const cogContainer = {
  position: 'fixed',
  top: 4,
  right: 140,
  zIndex: 888,
};

const MaintenanceConfigModal = () => {
  const createdBy = useSelector(({ session }) => session.userId);
  const { notify } = useNotificationBannerHook();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState(null);
  const [isIntermittently, setIsIntermittently] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [initialStartDate, setInitialStartDate] = useState(null);
  const [startTime, setStartTime] = useState('');
  const [endDate, setEndDate] = useState(null);
  const [initialEndDate, setInitialEndDate] = useState(null);
  const [endTime, setEndTime] = useState('');
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(true);
  const [isRemoveButtonDisabled, setIsRemoveButtonDisabled] = useState(true);
  const todayCT = new Date().toLocaleString('en-US', { timeZone: 'America/Chicago' });

  useEffect(() => {
    if (isModalOpen) {
      axios.get('/maintenanceConfig')
        .then(response => {
          if (response.data && response.data?.response.length >= 1) {
            const data = response.data?.response[0];
            setId(data.ID);
            setInitialStartDate(data.T_START_DATE || '');
            setInitialEndDate(data.T_END_DATE || '');
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  }, [isModalOpen]);

  const validationFunction = useCallback(() => {
    if (startDate && startTime && endDate && endTime) {
      const sstartDate = startDate.format("YYYY-MM-DD");
      const sstartTime = `${startTime.hh}:${startTime.mm} ${startTime.part}`;
      const startDateTime = `${sstartDate}, ${sstartTime}`;
      const sendDate = endDate.format("YYYY-MM-DD");
      const sendTime = `${endTime.hh}:${endTime.mm} ${endTime.part}`;
      const endDateTime = `${sendDate}, ${sendTime}`;
      const isStartDateAfterEndDate = moment(startDateTime).isSameOrAfter(endDateTime);
      return isStartDateAfterEndDate;
    }
    return false;
  }, [startDate, startTime, endDate, endTime]);

  const isStartDateEarlierThanTodaysDate = useCallback(() => {
    if (startDate && startTime) {
      const sstartDate = startDate.format("YYYY-MM-DD");
      const sstartTime = `${startTime.hh}:${startTime.mm} ${startTime.part}`;
      const startDateTime = `${sstartDate}, ${sstartTime}`;
      const today = moment(todayCT);
      const isStartDateEarlierThanTodaysDate = today.isSameOrAfter(startDateTime);
      return isStartDateEarlierThanTodaysDate;
    }
    return false;
  }, [startDate, startTime]);

  useEffect(() => {
    if (startDate?.format() && endDate?.format() && moment(startTime)?.format() && moment(endTime)?.format()) {
      setIsAddButtonDisabled(false);
    } else {
      setIsAddButtonDisabled(true);
    }
  }, [startDate, startTime, endDate, endTime]);

  useEffect(() => {
    if (initialStartDate && initialEndDate) {
      setIsRemoveButtonDisabled(false);
    } else {
      setIsRemoveButtonDisabled(true);
    }
  }, [id, isRemoveButtonDisabled, initialStartDate, initialEndDate]);

  const onSubmitMaintenanceModal = useCallback(async (param) => {
    const isStartDateEarlierThanToday = isStartDateEarlierThanTodaysDate();
    const isStartDateAfterEndDate = validationFunction();

    if (isStartDateEarlierThanToday && param === 'add') {
      notify('Start date cannot be earlier than today\'s date (CT)', 'negative');
      return;
    } else if (isStartDateAfterEndDate && param === 'add') {
      notify('Start date cannot be after end date', 'negative');
      return;
    }
    let maintenanceConfig = {};
    if (param === 'remove') {
      maintenanceConfig = {
        id,
        startDate: null,
        startTime: null,
        endDate: null,
        endTime: null,
        createdBy,
        isIntermittently: 0,
      };
    } else if (param === 'add') {
      maintenanceConfig = {
        id,
        startDate: startDate?.format("YYYY-MM-DD"),
        startTime: `${startTime?.hh}:${startTime?.mm} ${startTime?.part}`,
        endDate: endDate?.format("YYYY-MM-DD"),
        endTime: `${endTime?.hh}:${endTime?.mm} ${endTime?.part}`,
        createdBy,
        isIntermittently: isIntermittently ? 1 : 0,
      };
    }

    try {
      const result = await axios.put('/maintenanceConfig', maintenanceConfig);
      if ((result?.data?.message || '') === 'Maintenance Configuration Added') {
        onToggleMaintenanceModal();
        setStartDate(null);
        setStartTime({ hh: '', mm: '', part: '' });
        setEndDate(null);
        setEndTime({ hh: '', mm: '', part: '' });
        setIsIntermittently(true);
        notify('The maintenance date was set successfully', 'positive');
        setIsRemoveButtonDisabled(true)
      }
    } catch (error) {
      notify('Error occurred while setting the maintenance banner', 'negative');
    }

  }, [id, startDate, startTime, endDate, endTime, isIntermittently]);


  const onChangeStartDate = (event) => {
    setStartDate(event);
  };
  const onChangeStartTime = (event) => {
    setStartTime(event);
  };
  const onClearStartTime = () => {
    setStartTime({ hh: '', mm: '', part: '' });
  };
  const onChangeEndDate = (event) => {
    setEndDate(event);
  };
  const onChangeEndTime = (event) => {
    setEndTime(event);
  };
  const onChangeIsIntermittently = (_, state) => {
    setIsIntermittently(state);
  };
  const onClearEndTime = () => {
    setEndTime({ hh: '', mm: '', part: '' });
  };

  const onToggleMaintenanceModal = useCallback(() => {
    setIsModalOpen(!isModalOpen);
    setStartDate(null);
    setEndDate(null);
    setStartTime('');
    setEndTime('');
  }, [isModalOpen]);

  const renderMaintenanceModal = useMemo(() => (
    <>
      {isModalOpen ? (
        <Modal
          domID="maintenance-modal"
          isOpen={isModalOpen}
          onModalToggle={onToggleMaintenanceModal}
        >
          <ModalHeaderWrapper>
            <ModalHeader
              className="modal-header"
              title="Maintenance Banner Settings"
              onClose={onToggleMaintenanceModal}
            />
          </ModalHeaderWrapper>
          <ModalContentWrapper className="modal-content-wrapper">
            <ModalBody>
              <div style={{ zIndex: 888, position: 'relative', display: 'flex', justifyContent: 'space-between' }}>
                <DatePickerV2
                  dataTestId="test-datePicker-v2"
                  domID="startDate"
                  errorMessage="select another date"
                  label="Maintenance Start Date"
                  onDateChange={onChangeStartDate}
                  size="small"
                />
                <TimePicker
                  domID="startTime"
                  label="Maintenance Start Time"
                  size="small"
                  onChange={onChangeStartTime}
                  onClear={onClearStartTime}
                  value={startTime}
                />
                <DatePickerV2
                  dataTestId="test-datePicker-v2"
                  domID="endDate"
                  errorMessage="select another date"
                  label="Maintenance End Date"
                  onDateChange={onChangeEndDate}
                  size="small"
                />
                <TimePicker
                  domID="endTIme"
                  label="Maintenance End Time"
                  size="small"
                  onChange={onChangeEndTime}
                  onClear={onClearEndTime}
                  value={endTime}
                />
              </div>
              <div style={{ marginTop: 10 }}>
                <Checkbox
                  label={"Intermittently"}
                  name="Intermittently"
                  className="intermittently-checkbox"
                  onChange={onChangeIsIntermittently}
                  size='large'
                  checked={isIntermittently}
                />
              </div>
            </ModalBody>
          </ModalContentWrapper>
          <ModalFooterWrapper>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  size="medium"
                  buttonType="diminished"
                  name="Cancel"
                  onClick={onToggleMaintenanceModal}
                />
                <Button
                  size="medium"
                  buttonType="destroy"
                  name="Remove Banner"
                  disabled={isRemoveButtonDisabled}
                  onClick={() => onSubmitMaintenanceModal('remove')}
                />
                <Button
                  size="medium"
                  buttonType="standard"
                  name="Add Banner"
                  disabled={isAddButtonDisabled}
                  onClick={() => onSubmitMaintenanceModal('add')}
                />
              </ButtonGroup>
            </ModalFooter>
          </ModalFooterWrapper>
        </Modal>
      ) : null}
    </>
  ), [isModalOpen, id, startDate, startTime, endDate, endTime, isIntermittently, isRemoveButtonDisabled]);
  return (
    <>
      {renderMaintenanceModal}
      <div style={cogContainer}>
        <Tooltip domID='maintenance-tooltip' tooltipContent={'Maintenance Banner'}>
          <Button
            dataTestId="maintenance-button"
            buttonType="unstyled"
            onClick={onToggleMaintenanceModal}
          >
            <Icons.Cog
              domID="icon-id-cog"
              fillColor="white"
              title="Maintenance Config"
            />
          </Button>
        </Tooltip>
      </div>
    </>
  );
};

export default MaintenanceConfigModal;