/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import styled from 'styled-components/dist/styled-components';
import ReactTooltip from 'react-tooltip';
import { useSelector, useDispatch } from 'react-redux';
import { CircleCheck } from 'ui-core/dist/icons';
import {
  Grid,
  Button,
  useLayout,
  ListPaneHeader,
} from 'ui-core/dist'
import { LoadingPage } from '../../../components';
import ErrorPage from '../../ErrorPage';
import ListPaneHeaderWrapper from '../../../components/ErrorDisplaySection/ListPaneHeaderWrapper';
import { CLIENT_INFORMATION_CONTACTS_EDITOR_GRID } from '../Modules/constants';
import useSortableGrid from '../../../hooks/useSortableGrid';
import { setClientContactEditorRecord, resetClientContactEditorRecord } from '../Modules/action';
import { useGetAssosicateReportsClientContactTable, useGetAssosicateReportsTable } from '../../../api/useClientInformationAssosicateReports';

const BLOCKING_ERROR = 'cannot insert NULL into ("ABFRAICADMIN"."SYS_CLIENT_EMAIL_RECIPIENTS"."C_EMAIL_CODE"'
const StyledGridWrapper = styled.div`
&.GridWrapper #grid-wrapper-test-id{
  overflow:initial !important;
}
`
const StyleButton = styled(Button)`
height: 18px !important;
width: 53px !important;
background: transparent;
box-shadow: none;
&:hover {
  background: transparent;
box-shadow: none;
}
`
const ContactEditorMain = () => {
  const {
    expandCollapseListPaneButtonConfig,
    openDetailPane,
    closeDetailPane
  } = useLayout()
  const { faultstring } = useSelector(({ errormessages }) => errormessages);
  const dispatch = useDispatch()
  const [count, setcount] = useState(0)
  const [selectedRecord,setSelectedRecord]=useState('')

  const { isLoading: isLoadinggetAssosicateReportsTable,
    isError: isErrorgetAssosicateReportsTable,
    error: errorgetAssosicateReportsTable,
    postData: getAssosicateReportsTable,
    EmailRecipients, EmailRecipientsCount, Reports, ReportsCount } = useGetAssosicateReportsTable();

  const { isLoading: isLoadinggetAssosicateReportsClientContactTable,
    isError: isErrorgetAssosicateReportsClientContactTable,
    error: errorgetAssosicateReportsClientContactTable,
    postData: getAssosicateReportsClientContactTable,
    setClientContactsInfo,
    ClientContactsCanDeleteInfo, ClientContactsCanDeleteInfoCount, ClientContactsInfo, ClientContactsInfoCount } = useGetAssosicateReportsClientContactTable();

  const [sortConfigContacts, _c, gridSortingContacts] = useSortableGrid(() => false, "cclientnum", "true", setClientContactsInfo);

  const refetchClientContactEditorRecord = () => {
    getAssosicateReportsTable({ count })
    getAssosicateReportsClientContactTable({ count })
    setcount(count + 1)
  }

  useEffect(() => {
    if (getAssosicateReportsClientContactTable) {
      closeDetailPane();
      dispatch(resetClientContactEditorRecord());
      refetchClientContactEditorRecord()
    }
  }, [])

  const onShowMoreClick = useCallback((record) => {
    dispatch(setClientContactEditorRecord({ clientContactEditorRecord: JSON.parse(JSON.stringify(record)), mode: 'Edit', refetchClientContactEditorRecord }));
    setSelectedRecord(JSON.parse(JSON.stringify(record)))
    openDetailPane();
  }, [ClientContactsCanDeleteInfo, ClientContactsCanDeleteInfoCount, ClientContactsInfo, ClientContactsInfoCount]);

  const onAddClientContactRecord = () => {
    setSelectedRecord({})
    dispatch(setClientContactEditorRecord({ clientContactEditorRecord: JSON.parse(JSON.stringify({})), mode: 'Add', refetchClientContactEditorRecord }));
    openDetailPane();
  }
  const renderstrClientContactsHTMLTable = useMemo(() => {
    if (ClientContactsInfo) {
      const data = JSON.parse(JSON.stringify(ClientContactsInfo))
      const newData = data ? Array.isArray(data) ? data : [data] : [];
      const temp = newData.map((d, index) => {
        const { ccontactid } = d;
        let image = null
        const Recipients = (EmailRecipients || []).find(x => x.contactid === ccontactid)
        if (Recipients) {
          const reps = (Reports || []).find(x => x.emailcode === Recipients.emailcode)
          if (reps) {
            image = (
              <>
                <StyleButton data-tip data-for={`contacttooltip_${index}_${ccontactid}`}
                  onClick={() => false}>
                  <CircleCheck
                    className="settings"
                    fillColor="#37474F"
                    title="Settings"
                  />
                </StyleButton>
                <ReactTooltip id={`contacttooltip_${index}_${ccontactid}`} type='dark' effect='solid'>
                  <span>Has Associated Report(s)</span>
                </ReactTooltip>
              </>
            )
          }
        }
        return {
          ...d,
          image,
          hasReportMapping: image ? true : false
        }
      })
      return (
        <>
          <StyledGridWrapper className='GridWrapper'>
            <Grid
              {...CLIENT_INFORMATION_CONTACTS_EDITOR_GRID}
              records={temp || []}
              selectionKey="ccontactid"
              onClickThrough={(e, rec) => {
                e.preventDefault();
                e.stopPropagation();
                if (rec) {
                  const rec1 = (newData || []).find(x => x.ccontactid === rec.ccontactid)
                  if (rec1) {
                    const record = JSON.parse(JSON.stringify({
                      ...rec1,
                      hasReportMapping: rec.image ? true : false
                    }))
                    if (onShowMoreClick) {
                      dispatch(resetClientContactEditorRecord())
                      return onShowMoreClick(record);
                    }
                    return false;
                  }
                  return false;
                }
                return false;
              }}
              focusedItem={selectedRecord}
              initialSortingKey={`${sortConfigContacts.sortDataName}|${sortConfigContacts.sortDescending === "true" ? 'SORT_DESCENDING' : 'SORT_ASCENDING'}`}
              onSortGridColumn={(e, state) => {
                const { sortingKey } = state;
                const sortingKey$split = sortingKey.split('|');
                const dataName = sortingKey$split[0];
                const f = Array.from(CLIENT_INFORMATION_CONTACTS_EDITOR_GRID.columns).find(x => x.dataName === dataName);
                if (f) {
                  const { cellType } = f;
                  if (gridSortingContacts) gridSortingContacts({ sortingKey, type: cellType }, newData)
                }
              }}
            />
          </StyledGridWrapper>
        </>
      )
    }
    return null;
  }, [
    ClientContactsCanDeleteInfo,
    ClientContactsCanDeleteInfoCount,
    ClientContactsInfo,
    ClientContactsInfoCount,
    sortConfigContacts,
    selectedRecord,
    EmailRecipients, EmailRecipientsCount, Reports, ReportsCount
  ])

  return (
    <>
      {faultstring.indexOf(BLOCKING_ERROR) !== -1 ? (
        <ListPaneHeaderWrapper
          title="Client Information Manager - Contact Editor"
          layoutControls={[expandCollapseListPaneButtonConfig]}
        />
      ) : (
        <ListPaneHeader
          title="Client Information Manager - Contact Editor"
          layoutControls={[expandCollapseListPaneButtonConfig]}
        />
      )}
      {isLoadinggetAssosicateReportsClientContactTable || isLoadinggetAssosicateReportsTable ? (
        // replace with a better loading component
        <LoadingPage />
      ) : isErrorgetAssosicateReportsClientContactTable || isErrorgetAssosicateReportsTable ? (
        // replace with a better error component
        <ErrorPage
          // this prop isn't real but is an example
          error={errorgetAssosicateReportsClientContactTable || errorgetAssosicateReportsTable}
        />
      ) : (
        <>
          <div className="marginLeft">
            <table border="0" cellspacing="1" cellpadding="3" width="100%">
              <tr>
                <td>
                  <div className="displayFlex">
                    <Button
                      id='ContactEditorAdd'
                      size="small"
                      name="Add Client Contact"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onAddClientContactRecord()
                      }}
                    />
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  {renderstrClientContactsHTMLTable}
                </td>
              </tr>
            </table>
          </div>
          {/* {renderMainGrid} */}
        </>
      )}
    </>
  )
};

export default ContactEditorMain