import React, { Component} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, LoadingIndicator } from 'ui-core';
import { associatiedGroupsGridConfig } from './constants';
import ClickableRow from '../ClickableRow';
import AssociateGridHeader from '../AssociateGridHeader';
import { groupAssociationsForRuleIdTrigger } from '../../../../../../redux/api/rulesForRuleTypesList/actions';
import { selectAssociatedGroupRecordsNoTermedRecords } from './selectors';
import {
  LoadingContainer,
  GridLoadingWrapper,
} from '../../../../../../shared/styles/styledComponents';
import './index.scss';

class AssociateGridWrapper extends Component {
  state = {
    selectedItemIds: new Set([]),
  };

  generateGridRecordsFromProps(records) {
    return records.map(record => {
      const newRecord = {
        ...record,
        completed: { bad: record.completed, nonCompliant: record.completed },
      };
      return newRecord;
    });
  }

  componentDidMount() {
    const {
      groupAssociationsForRuleIdTrigger,
      selectedRecord,
      selectedRuleTypeId,
      deleteAllNotifications,
    } = this.props;
    deleteAllNotifications();
    groupAssociationsForRuleIdTrigger({
      ruleId: selectedRecord.id,
      ruleType: selectedRuleTypeId,
    });
  }

  onGridSelectAll = e => {
    const { selectedItemIds } = this.state;
    const { groupAssociationsForRuleId } = this.props;
    const alreadyFull =
      selectedItemIds.size === groupAssociationsForRuleId.length;
    const ids = alreadyFull
      ? []
      : groupAssociationsForRuleId.map(({ id }) => id);

    this.setState({ selectedItemIds: new Set(ids) });
  };

  onRowClickThrough = e => {};

  onRowSelect = ({ groupId }) => {
    const { selectedItemIds } = this.state;
    const isBeingSelected = !selectedItemIds.has(groupId);
    const method = isBeingSelected ? 'add' : 'delete';
    selectedItemIds[method](groupId);
    this.setState({ selectedItemIds });
  };

  render() {
    const { selectedItemIds } = this.state;
    const { onGridSelectAll, onRowClickThrough, onRowSelect } = this;
    const { associatedGroupRecordsNoTermedRecords, loading } = this.props;
    const recordCount = associatedGroupRecordsNoTermedRecords.length;

    return (
      <>
        <AssociateGridHeader
          headerName="Associated Groups"
          recordCount={recordCount}
          selectedItemIds={selectedItemIds}
        />
        {loading.has('groupAssociationsGridLoading') ? (
          <LoadingContainer>
            <GridLoadingWrapper>
              <LoadingIndicator
                domID="rules-grid-loading-indicator"
                length="45px"
              />
            </GridLoadingWrapper>
          </LoadingContainer>
        ) : (
          <Grid
            {...associatiedGroupsGridConfig}
            records={this.generateGridRecordsFromProps(
              associatedGroupRecordsNoTermedRecords,
            )}
            selectedItemIds={new Set([])}
            rowComponent={ClickableRow}
            onClickThrough={onRowClickThrough}
            onRowSelect={onRowSelect}
            onSelectAll={onGridSelectAll}
          />
        )}
      </>
    );
  }
}

AssociateGridWrapper.propTypes = {
  associatedGroupRecordsNoTermedRecords: PropTypes.array,
  groupAssociationsForRuleId: PropTypes.array,
  selectedRecord: PropTypes.object,
  selectedRuleTypeId: PropTypes.string,
  groupAssociationsForRuleIdTrigger: PropTypes.func,
};

const mapStateToProps = state => ({
  groupAssociationsForRuleId:
    state.rulesForRuleTypesList.groupAssociationsForRuleId,
  associatedGroupRecordsNoTermedRecords: selectAssociatedGroupRecordsNoTermedRecords(
    state || [],
  ),
  selectedRuleTypeId: state.ruleTypesList.selectedRuleTypeId,
});

const mapDispatchToProps = dispatch => ({
  groupAssociationsForRuleIdTrigger: id =>
    dispatch(groupAssociationsForRuleIdTrigger(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AssociateGridWrapper);
