import React from 'react';

export const notificationDefaultValue = {
  toggleShowNotification:()=>false,
  notify:()=>false
};
  
const NotificationContext = React.createContext(
  notificationDefaultValue // default value
);
export default NotificationContext;