// import React from 'react';
// import { Route, Switch, Redirect } from 'react-router';
// import { Page } from '../components';

// const ManifestRoutes = () => (
//   <Switch>
//     <Route path="/ManifestInformationManager/ManifestInformationEntry" component={Page} />
//     <Route path="/ManifestInformationManager/ManifestWireDate" component={Page} />
//     <Route path="/ManifestInformationManager/ManualJobInsertionData" component={Page} />
//     <Route exact path="/ManifestInformationManager">
//       <Redirect to="/ManifestInformationManager/ManifestInformationEntry" />
//     </Route>
//   </Switch>
// )

// export default ManifestRoutes;



import React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import { Page } from '../components';
import ManifestEntryDetails from '../containers/MailManifestManager/ManifestEntryDetails';
import ManifestEntryMain from '../containers/MailManifestManager/ManifestEntryMain'
// import EEOBFormManagerDetail from '../containers/EEOBFormManager/EEOBFormManagerDetail'

const ManifestEntryMainPage = () => (
  <Page
    ListPaneContent={ManifestEntryMain}
    DetailPaneContent={ManifestEntryDetails}
  />
);

const ManifestRoutes = () => (
  <Switch>
    <Route path="/ManifestInformationManager/index" component={ManifestEntryMainPage} />
    <Route exact path="/ManifestInformationManager">
      <Redirect to="/ManifestInformationManager/index" />
    </Route>
  </Switch>
)

export default ManifestRoutes;