import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { 
  Input, 
  ButtonGroup, 
  Button, 
  Modal,
  ModalHeader,
  ModalBody,
  LoadingIndicator
} from 'ui-core/dist'
import moment from 'moment';
import { Loading, SelectDropdown } from '../../components'
import './modules/styles.scss'
import axios from '../../axios'
import Authorizer from '../Authorizer';
import useNotificationBannerHook from '../../useNotificationBannerHook';

const BasicTab = ({ data, isLoading, refetch }) => {
  const { notify } = useNotificationBannerHook()
  const [isLoadingConfirm, setisLoadingConfirm]=useState(false)
  const [ userId, setUserId ] = useState(data?.userId)
  const [ userName, setUserName ] = useState(data?.fullName)
  const [ company, setCompany ] = useState(data?.company)
  const [ jobTitle, setJobTitle ] = useState(data?.jobTitle)
  const [ email, setEmail ] = useState(data?.emailAddr)
  const [ birthday, setBirthday ] = useState(data?.birthDay)
  const [ workPh, setWorkPh ] = useState(data?.workPhoneNumber)
  const [ workExtension, setWorkExtension ] = useState(data?.workPhoneNumberExt)
  const [ faxNum, setFaxNum ] = useState(data?.faxNumber)
  const [ passwordHint, setPasswordHint ] = useState(data?.passwordReminder)
  const [ passwordExpiration, setPasswordExpiration ] = useState(data?.passwordExpires)
  const [ allowedIps, setAllowedIps ] = useState(data?.userAllowedIp)
  const [ status, setStatus ] = useState(data?.isActive === "true" ? {
    label: 'Active', value: true
  } : { label: 'Inactive', value: false })
  const [ userType, setUserType ] = useState(data?.userType === "E" ? {
    label: "External", value: "E"
  } : { label: "Internal", value: "I" })
  const [ isConfirmationOpen, setIsConfirmationOpen ] = useState(false)

  const onUserIdChange = (e, { value }) => setUserId(value)
  const onUserChange = (e, { value }) => setUserName(value)
  const onCompanyChange = (e, { value }) => setCompany(value)
  const onJobChange = (e, { value }) => setJobTitle(value)
  const onEmailChange = (e, { value }) => setEmail(value)
  const onBirthdayChange = (e, { value }) => setBirthday(value)
  const onWorkChange = (e, { value }) => setWorkPh(value)
  const onWorkExtensionChange = (e, { value }) => setWorkExtension(value)
  const onFaxChange = (e, { value }) => setFaxNum(value)
  const onPasswordHintChange = (e, { value }) => setPasswordHint(value)
  const onPasswordExpirationChange = (e, { value }) => setPasswordExpiration(value)
  const onAllowedChange = (e, { value }) => setAllowedIps(value)
  const onStatusChange = (state) => setStatus(state)
  const onUserTypeChange = (state) => setUserType(state)

  const onReset = () => {
    setUserId(data?.userId)
    setUserName(data?.fullName)
    setCompany(data?.company)
    setJobTitle(data?.jobTitle)
    setEmail(data?.emailAddr)
    setBirthday(data?.birthDay)
    setWorkPh(data?.workPhoneNumber)
    setWorkExtension(data?.workPhoneNumberExt)
    setFaxNum(data?.faxNumber)
    setPasswordHint(data?.passwordReminder)
    setPasswordExpiration(data?.passwordExpires)
    setAllowedIps(data?.userAllowedIp)
    setStatus(data?.isActive ? {
      label: 'Active', value: true
    } : { label: 'Inactive', value: false })
    setUserType(data?.userType === "E" ? {
      label: "External", value: "E"
    } : { label: "Internal", value: "I" })
  }

  const onSubmit = () => {
    if(workPh) {
      if(!(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(workPh))) {
        notify('The Work Phone Number is improperly formatted.  It should follow the format ###-###-####','negative')
        return false;
      } 
    }
    if(faxNum) {
      if(!(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(faxNum))) {
        notify('The Work Fax Number is improperly formatted.  It should follow the format ###-###-####','negative')
        return false;
      } 
    }
    setIsConfirmationOpen(true)
  }

  const closeConfirmationModal = () => setIsConfirmationOpen(false)

  const onConfirmUpdate = async () => {
    try {
      setisLoadingConfirm(true)
      await axios.put(`/members/${userId}`, {
        fullName: userName,
        birthDay: birthday,
        company,
        emailAddr: email,
        faxNumber: faxNum,
        jobTitle,
        isActive: status.value,
        passwordExpires: passwordExpiration,
        passwordReminder: passwordHint,
        workPhoneNumber: workPh,
        workPhoneNumberExt: workExtension,
        userType: userType.value,
        userAllowedIp: allowedIps,
      })
      refetch()
      notify("Successfully updated the user.", 'positive')
    } catch (err) {
      notify("Failed to update the user", "negative")
    } finally {
      setisLoadingConfirm(false)
    }
  }

  return (
    <>
      {isLoading ? <Loading /> : (
        <>
          <div className="basic-tab__row">
            <div className="basic-tab__set">
              <span className="basic-tab__label">User ID:</span>
              <Input 
                domID="BasicTabUserIdInput"
                onChange={onUserIdChange}
                size="small"
                initialValue={userId}
                disabled
              />
            </div>
            <div className="basic-tab__set">
              <span className="basic-tab__label">User Name:</span>
              <Input 
                domID="BasicTabUserInput"
                onChange={onUserChange}
                size="small"
                initialValue={userName}
              />
            </div>
          </div>
          <div className="basic-tab__row">
            <div className="basic-tab__set">
              <span className="basic-tab__label">Company:</span>
              <Input 
                domID="BasicTabCompanyInput"
                onChange={onCompanyChange}
                size="small"
                initialValue={company}
              />
            </div>
            <div className="basic-tab__set">
              <span className="basic-tab__label">Job Title:</span>
              <Input 
                domID="BasicTabJobInput"
                onChange={onJobChange}
                size="small"
                initialValue={jobTitle}
              />
            </div>
          </div>
          <div className="basic-tab__set">
            <span className="basic-tab__label">E-mail Address:</span>
            <Input 
              domID="BasicTabEmailInput"
              onChange={onEmailChange}
              size="small"
              initialValue={email}
            />
          </div>
          {/* <div className="basic-tab__set">
            <span className="basic-tab__label">Birthday</span>
            <Input 
              domID="BasicTabBirthdayInput"
              onChange={onBirthdayChange}
              size="small"
              initialValue={birthday}
            />
            <span className="basic-tab__info">format 10/31</span>
          </div> */}
          <div className="basic-tab__set">
            <span className="basic-tab__label">Work Phone:</span>
            <Input 
              domID="BasicTabWorkInput"
              onChange={onWorkChange}
              size="small"
              maxLength={12}
              initialValue={workPh}
            />
            <span className="basic-tab__info">format 314-555-1212 Ext:</span>
            <div style={{ width: '75px' }}>
              <Input 
                domID="BasicTabWorkExtenstionInput"
                onChange={onWorkExtensionChange}
                size="small"
                initialValue={workExtension}
              />
            </div>
          </div>
          <div className="basic-tab__set">
            <span className="basic-tab__label">Fax Number:</span>
            <Input 
              domID="BasicTabFaxInput"
              onChange={onFaxChange}
              size="small"
              maxLength={12}
              initialValue={faxNum}
            />
            <span className="basic-tab__info">format 314-555-1212</span>
          </div>
          <div className="basic-tab__row">
            <div className="basic-tab__set">
              <span className="basic-tab__label">Password Hint:</span>
              <Input 
                domID="BasicTabPasswordHintInput"
                onChange={onPasswordHintChange}
                size="small"
                initialValue={passwordHint ? passwordHint === 'undefined' ? '' : passwordHint : ''}
              />
            </div>
            <div className="basic-tab__set">
              <span className="basic-tab__label">Password Expiration:</span>
              <Input 
                domID="BasicTabPasswordExpirationInput"
                onChange={onPasswordExpirationChange}
                size="small"
                initialValue={passwordExpiration ? moment(passwordExpiration).format('MM/DD/YYYY') : ''}
                disabled
              />
            </div>
          </div>
          <div className="basic-tab__set">
            <span className="basic-tab__label">Allowed IPs:</span>
            <Input 
              domID="BasicTabAllowedInput"
              onChange={onAllowedChange}
              size="small"
              initialValue={allowedIps}
            />
          </div>
          <div className="basic-tab__set">
            <span className="basic-tab__label">Status:</span>
            <SelectDropdown 
              dataTestId="test-selectUserType"
              domID="UserTypeDropdown"
              onChange={onStatusChange}
              initialValue={status}
              options={[
                {
                  label: 'Active',
                  value: true
                },
                {
                  label: 'Inactive',
                  value: false
                },
              ]}
              size="small"
            />
          </div>
          <div className="basic-tab__set">
            <span className="basic-tab__label">User Type:</span>
            <SelectDropdown 
              dataTestId="test-selectUserType"
              domID="UserTypeDropdown"
              onChange={onUserTypeChange}
              initialValue={userType}
              options={data?.userType === "E" ? [{
                label: 'External',
                value: 'E'
              }] : [{
                label: 'Internal',
                value: 'I'
              }]}
              size="small"
            />
          </div>
          <Authorizer permission="CAN_PERFORM_SECURITY_USER_MODIFY">
          <ButtonGroup className="user-manager-details__button-group--right">
            <Button 
              domID="Button"
              name="Reset"
              onClick={onReset}
              buttonType="standard"
              size="small"
            />
            <Button 
              domID="Button"
              name="Submit"
              onClick={onSubmit}
              buttonType="standard"
              size="small"
            />
          </ButtonGroup>
          </Authorizer>
        </>
      )}
      {isConfirmationOpen?(
        <Modal isOpen={isConfirmationOpen} >
          <ModalHeader 
            title="Are You Sure You Want to Update this User?"
            onClose={closeConfirmationModal}
          />
          <ModalBody>
            <div className='displayFlex'>
              <ButtonGroup className="modify-user-modal">
                {isLoadingConfirm ? <LoadingIndicator /> :null}
                <Button 
                  domID="confirmUpdateButton"
                  disabled={isLoadingConfirm}
                  onClick={onConfirmUpdate}
                  buttonType="standard"
                  size="medium"
                  name="Confirm"
                />
              </ButtonGroup>
            </div>
          </ModalBody>
        </Modal>
      ): null}
    </>
  )
}

BasicTab.propTypes ={
  data: PropTypes.shape({
    userId: PropTypes.string,
    fullName: PropTypes.string,
    company: PropTypes.string,
    jobTitle: PropTypes.string,
    emailAddr: PropTypes.string,
    birthDay: PropTypes.string,
    workPhoneNumber: PropTypes.string,
    workPhoneNumberExt: PropTypes.string,
    faxNumber: PropTypes.string,
    passwordReminder: PropTypes.string,
    passwordExpires: PropTypes.string,
    userAllowedIp: PropTypes.string,
    isActive: PropTypes.string,
    userType: PropTypes.string,
  }),
  isLoading: PropTypes.bool.isRequired,
  refetch: PropTypes.func
}

BasicTab.defaultProps ={
  data: undefined,
  refetch: () => false 
}
export default BasicTab