import React,{createRef} from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { Button, Input} from 'ui-core-ssgr';
import ImageControl from '../../../../containers/RuleDetailModals/ModalControls/ImageControl';

const dropStyle = {
  position: 'relative',
  width: '99%',
  height: '100%',
  align: 'center',
};

const renderFileName = (isFileUploaded, fileUploadMessage, isFileError) => {
  const statusClass = !isFileError ? 'fileuploadMessage' : 'fileuploadMessageError';
  if (isFileUploaded) {
    return (
      <div>
        <p className="fileuploadMessage">Great, we support that file type</p>
      </div>
    );
  }
  return (
    <div className="fileZone">
      <br />
      <p className={statusClass}>{fileUploadMessage}</p>
      <p className="fileuploadMessageSmall">OR</p>
    </div>
  );
};

const dropzoneRef = createRef();
const openDialog = () => {
  // Note that the ref is set async,
  // so it might be null at some point 
  if (dropzoneRef.current) {
    dropzoneRef.current.open()
  }
};

const Filezone = (props) => {
  const {
    isFileUploaded,
    fileUploadMessage,
    acceptedFileTypes,
    uploadFile,
    isFileError,
    fileOnLoad,
    fileOnError,
    allowMultiple,
    convertedImageUploadData
  } = props;
  const {imageId, convertedImage} = convertedImageUploadData || '';
  return (
    <div id="dragid-filezone">
      <Dropzone
        multiple={allowMultiple}
        ref={dropzoneRef}
        noClick
        noKeyboard
        style={dropStyle}
        accept={acceptedFileTypes}
        onDrop={(e) => uploadFile(e)}
      >
        {({ getRootProps, getInputProps, acceptedFiles}) => {
          const fileName = acceptedFiles && acceptedFiles.length>0 ? acceptedFiles[0].name : imageId;
          return (
            <div className="container">
              <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <div className="messageDrag">
                  {renderFileName(
                    isFileUploaded,
                    fileUploadMessage,
                    isFileError,
                  )}
                </div>
                {imageId && imageId !== '' ? (
                  <ImageControl
                    key={String(imageId)}
                    imageKey={String(imageId)}
                    imageDomID={String(imageId)}
                    imageClassName={String(imageId)}
                    imageSrc={convertedImage}
                    imageAltLabel={String(fileName)}
                    imageStyles={
                      isFileError ? { display: 'none' } : { display: 'block' }
                    }
                    imageOnClickHandle={() => {}}
                    imageOnLoad={e => fileOnLoad(e)}
                    imageOnError={e => fileOnError(e)}
                    imageSelection={false}
                  />
                ) : null}
                <Button
                  name="BROWSE"
                  type="button"
                  onClick={openDialog}
                  buttonType="emphasized"
                  size="medium"
                />
                <Input
                  type="file"
                  id="secondfile"
                  onChange={e => {
                    uploadFile(e.target.files);
                  }}
                  onClick={event => {
                    const e = event;
                    e.target.value = null;
                  }}
                  accept={acceptedFileTypes}
                  className="box__dragndrop"
                />
              </div>
            </div>
          );
        }}
      </Dropzone>
    </div>
  );
};

Filezone.propTypes = {
  fileUploadMessage: PropTypes.string,
  acceptedFiles: PropTypes.string,
  isFileUploaded: PropTypes.bool,
  uploadFile: PropTypes.func,
  acceptedFileTypes: PropTypes.string,
  isFileError: PropTypes.bool,
  fileOnLoad: PropTypes.func,
  fileOnError: PropTypes.func,
  allowMultiple: PropTypes.bool,
  convertedImageUploadData: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ])
};

export default Filezone;