import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, DetailHeader } from 'ui-core-ssgr';
import { ButtonGroupBottom } from '../../../../../shared/styles/styledComponents';
import { gridConfig } from './constants';
import {
  ScrollableGridWrapper,
  RequiredDiv,
} from './styles';
import { DivWithPaddingTop } from '../../../../../shared/styles/spacing';
import { ModalPaddingWrapper, ModalTitlePaddingWrapper } from '../../../../../shared/styles/styledComponents';

const AddRuleTypesModalGridWrapper = ({
  records,
  onModalToggle,
  selectedRecord,
  triggerRuleTypesRequest,
}) => {
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [selectedItemIds, setSelectedItemIds] = useState(new Set([]));
  const [initialCheckedRecordsSet, setInitialCheckedRecordsSet] = useState(new Set([]));

  useEffect(() => {
    if (
      Array.isArray(selectedRecord.ruleTypes) && selectedRecord.ruleTypes.length
    ) {
      const checkedRecords = selectedRecord.ruleTypes.map(record => record.id);
      const addRecords = new Set([]);
      checkedRecords.forEach(record => addRecords.add(record));
      setInitialCheckedRecordsSet(addRecords);
    }
  }, []);

  const generateGridRecordsFromProps = gridRecords => gridRecords.map(record => ({
    ...record,
    name: record.required && !record.name.endsWith('*') ? `${record.name}*` : record.name,
    completed: { bad: record.completed, nonCompliant: record.completed }
  }));

  const onCancel = () => {
    onModalToggle();
    setButtonDisabled(true);
  };

  const onGridSelectAll = () => {
    const alreadySelected = initialCheckedRecordsSet;
    const alreadyFull =
      selectedItemIds.size + alreadySelected.size === records.length;

    // filtering out already checked/disabled rule types
    const ids = alreadyFull
      ? []
      : records
        .map(({ id }) => id)
        .filter(id => ![...alreadySelected].includes(id));

    selectedItemIds.clear();
    ids.forEach(element => selectedItemIds.add(element));

    setButtonDisabled(selectedItemIds.size <= 0);
    setSelectedItemIds(selectedItemIds);
  };

  const onRowSelect = id => {
    const ruleTypeId = id.target.id.slice(0, 3);
    const isBeingSelected = !selectedItemIds.has(ruleTypeId);
    const method = isBeingSelected ? 'add' : 'delete';
    selectedItemIds[method](ruleTypeId);

    setButtonDisabled(selectedItemIds.size <= 0);
    setSelectedItemIds(selectedItemIds);
  };

  const onSave = () => {
    const newRuleTypes = [...selectedItemIds];

    if (newRuleTypes.length > 0) {
      const ruleTypesQuery = `?ruleType=${newRuleTypes.join(`&ruleType=`)}`;
      triggerRuleTypesRequest({
        groupId: selectedRecord.id,
        ruleType: ruleTypesQuery,
      });
    }
    onModalToggle();
  };

  return (
    <ModalPaddingWrapper>
      <ModalTitlePaddingWrapper>
        <DetailHeader title="Select Rule Types" count={records.length} />
      </ModalTitlePaddingWrapper>
      <ScrollableGridWrapper>
        <Grid
          {...gridConfig}
          records={generateGridRecordsFromProps(records)}
          onRowSelect={onRowSelect}
          onSelectAll={onGridSelectAll}
          initialSelectedItemIds={initialCheckedRecordsSet}
          initialDisabledItemIds={initialCheckedRecordsSet}
          selectedItemIds={selectedItemIds}
        />
      </ScrollableGridWrapper>
      <RequiredDiv> * Required </RequiredDiv>
      <DivWithPaddingTop>
        <ButtonGroupBottom>
          <Button
            name="Save"
            buttonType="emphasized"
            onClick={onSave}
            size="large"
            type="button"
            disabled={buttonDisabled}
          />
          <Button
            name="Cancel"
            buttonType="diminished"
            onClick={onCancel}
            size="large"
            type="button"
          />
        </ButtonGroupBottom>
      </DivWithPaddingTop>
    </ModalPaddingWrapper>
    
  );
}

AddRuleTypesModalGridWrapper.propTypes = {
  records: PropTypes.array,
  onModalToggle: PropTypes.func,
  selectedRecord: PropTypes.object,
  triggerRuleTypesRequest: PropTypes.func,
};

export default AddRuleTypesModalGridWrapper;
