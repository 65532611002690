import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import get from 'lodash/get';
import { Grid, LoadingIndicator, NotificationBanner, Modal } from 'ui-core-ssgr';
import { ScrollableGridWrapper } from './styles';
import LiveGridHeader from '../LiveGridHeader';
import ClickableRow from '../../RuleDetails/ClickableRow';
import OverwriteRuleModal from '../OverwriteRuleModal';
import {
  LoadingContainer,
  GridLoadingWrapper,
  ModalTitlePaddingWrapper, 
  StyledHeader
} from '../../../../../shared/styles/styledComponents';
import RuleDetailsModal from '../../../../RuleDetailModals';

const RuleDetailsGridWrapper = ({
  getRuleDetails,
  records,
  gridConfig,
  isPostingRulesForRuleTypes,
  isCloningRule,
  selectedRuleRecord,
  selectedRecord,
  selectedRuleTypeId,
  overwriteTestRuleRequestTrigger,
  loading,
  notifications,
  deleteNotification,
  isShowingOverwriteModal,
  setIsShowingOverwriteModal,
  setSelectedRuleDetail,
  setIsShowingCustomerServiceMessageModal,
  isShowingCustomerServiceMessageModal,
  setIsShowingCheckTextRuleModal,
  isShowingCheckTextRuleModal,
  isShowingErisaMessageModal,
  setIsShowingErisaMessageModal,
  setIsShowingProductionArrangementModal,
  isShowingProductionArrangementModal,
  setIsShowingSignatureRuleModal,
  isShowingSignatureRuleModal,
  isShowingPDFTaggingModal,
  setIsShowingPDFTaggingModal,
  setIsReadOnlyRuleDetail, 
  setIsEditingRuleDetail,
  setIsCloningRuleDetail,
  setIsPostingRuleDetail,
  selectedRuleDetail,
  detailElements,
  isEditingRuleDetail,
  setIsShowingImageRuleModal,
  setIsShowingReturnAddressModal,
  isShowingImageRuleModal,
  isShowingReturnAddressModal,
  isShowingTaxIdArrangementRuleModal,
  setIsShowingTaxIdArrangementRuleModal,
  isShowingHighDollarReviewModal,
  setShowingHighDollarReviewModal,
  setIsShowingMemberIdModal,
  isShowingMemberIdModal,
  setIsShowingGeneralMessageModal,
  isShowingGeneralMessageModal,
  setIsShowingProviderInsuredOptionMessageModal,
  isShowingProviderInsuredOptionMessageModal,
  setIsShowingAutoHoldModal,
  isShowingAutoHoldModal,
  setIsShowingDuplexRuleModal,
  isShowingDuplexRuleModal
}) => {
  // live first (Live Rule Tab)
  const getRuleVersion = record => {
    try {
      const { status, version } = record;
      const testVersion = version[status.indexOf('T')];
      const liveVersion = version[status.indexOf('L')];
      if (liveVersion) {
        return liveVersion;
      }
      if (testVersion) {
        return testVersion;
      }
    } catch (e) {}
    return null;
  };
  // useEffect for the componentDidMount
  useEffect(() => {
    if (
      selectedRuleRecord.status.length >= 1 &&
      selectedRuleRecord.status.includes('L')
    ) {
      const ruleVersion = getRuleVersion(selectedRuleRecord);
      getRuleDetails({
        ruleType: selectedRuleTypeId,
        ruleId: selectedRuleRecord.id,
        ruleVersion,
      });
    }
  }, []);

  const generateGridRecordsFromProps = gridRecords => map(gridRecords, record => record.message ? {
    ...record,
    message: record.message.replace(/\r?\\n|\r/g, '\n'),
    completed: { bad: record.completed, nonCompliant: record.completed }
  } : {
    ...record,
    completed: { bad: record.completed, nonCompliant: record.completed }
  });

  const toFixedTrunc = (x, n) => {
    const v = (typeof x === 'string' ? x : x.toString()).split('.');
    if (n <= 0) return v[0];
    let f = v[1] || '';
    if (f.length > n) return `${v[0]}.${f.substr(0, n)}`;
    while (f.length < n) f += '0';
    let inputValue = `${v[0]}.${f}`;
    inputValue = inputValue
      .replace(/,/g, '')
      .split('')
      .reverse()
      .join('')
      .replace(/(\d{3})/g, '$1,')
      .replace(/,$/, '')
      .split('')
      .reverse()
      .join('');
    return inputValue;
  }

  const constructPageList = record => {
    for (let j = 0; j < record.length; j += 1) {
      const column = record[j];
      let selectedColumnLabel = '';
      let toNumber = null;
      if (column.signatureThreshold !== null && column.signatureThreshold !== undefined && selectedRuleTypeId === 'SIG') {
        selectedColumnLabel = 'signatureThresholdLabel';
        toNumber = typeof column.signatureThreshold === 'string' ? column.signatureThreshold : String(column.signatureThreshold);
        toNumber = toFixedTrunc( toNumber , 2);
      }
      if (column.highDollarThreshold !== null && column.highDollarThreshold !== undefined && selectedRuleTypeId === 'HDR') {
        selectedColumnLabel = 'highDollarThresholdLabel';
        toNumber = toFixedTrunc( String(column.highDollarThreshold) , 2);
      }
      column[selectedColumnLabel] = `$${toNumber}`;
    }
    return record;
  };

  const onRowClickThrough = (e, record) => {
    setSelectedRuleDetail(record);
    handleClickAction('clickThrough');
  }

  const renderModalContent = () => handleClickAction('renderModalContent');
  
  const handleClickAction = (action) => {
    switch ( selectedRuleTypeId ) {
      case 'CSM':
        if(action === 'clickThrough' || action === 'cancel'){
          const statusAction = action === 'clickThrough';
          setIsShowingCustomerServiceMessageModal(statusAction);
          setIsReadOnlyRuleDetail(statusAction);
        }
        if(action === 'renderModalContent'){
          return isShowingCustomerServiceMessageModal
        }
        break;
      case 'CHT':
        if(action === 'clickThrough' || action === 'cancel'){
          const statusAction = action === 'clickThrough';
          setIsShowingCheckTextRuleModal(statusAction);
          setIsReadOnlyRuleDetail(statusAction);
        }
        if(action === 'renderModalContent'){
          return isShowingCheckTextRuleModal
        }
        break;
      case 'ERI':
        if(action === 'clickThrough' || action === 'cancel'){
          const statusAction = action === 'clickThrough';
          setIsShowingErisaMessageModal(statusAction);
          setIsReadOnlyRuleDetail(statusAction);
        }
        if(action === 'renderModalContent'){
          return isShowingErisaMessageModal
        }
        break;
      case 'IMG':
        if(action === 'clickThrough' || action === 'cancel'){
          const statusAction = action === 'clickThrough';
          setIsShowingImageRuleModal(statusAction);
          setIsReadOnlyRuleDetail(statusAction);
        }
        if(action === 'renderModalContent'){
          return isShowingImageRuleModal
        }
        break;
      case 'RAD':
        if(action === 'clickThrough' || action === 'cancel'){
          const statusAction = action === 'clickThrough';
          setIsShowingReturnAddressModal(statusAction);
          setIsReadOnlyRuleDetail(statusAction);
        }
        if(action === 'renderModalContent'){
          return isShowingReturnAddressModal
        }
        break;
      case 'SIG':
        if(action === 'clickThrough' || action === 'cancel'){
          const statusAction = action === 'clickThrough';
          setIsShowingSignatureRuleModal(statusAction);
          setIsReadOnlyRuleDetail(statusAction);
        }
        if(action === 'renderModalContent'){
          return isShowingSignatureRuleModal;
        }
        break;
      case 'PAR':
        if(action === 'clickThrough' || action === 'cancel'){
          const statusAction = action === 'clickThrough';
          setIsShowingProductionArrangementModal(statusAction);
          setIsReadOnlyRuleDetail(statusAction);
        }
        if(action === 'renderModalContent'){
          return isShowingProductionArrangementModal;
        }
        break;
      case 'TAX':
        if(action === 'clickThrough' || action === 'cancel'){
          const statusAction = action === 'clickThrough';
          setIsShowingTaxIdArrangementRuleModal(statusAction);
          setIsReadOnlyRuleDetail(statusAction);
        }
        if(action === 'renderModalContent'){
          return isShowingTaxIdArrangementRuleModal;
        }
        break;  
      case 'HDR':
        if(action === 'clickThrough' || action === 'cancel'){
          const statusAction = action === 'clickThrough';
          setShowingHighDollarReviewModal(statusAction);
          setIsReadOnlyRuleDetail(statusAction);
        }
        if(action === 'renderModalContent'){
          return isShowingHighDollarReviewModal;
        }
        break;
      case 'GEN':
        if(action === 'clickThrough' || action === 'cancel'){
          const statusAction = action === 'clickThrough';
          setIsShowingGeneralMessageModal(statusAction);
          setIsReadOnlyRuleDetail(statusAction);
        }
        if(action === 'renderModalContent'){
          return isShowingGeneralMessageModal;
        }
        break; 
      case 'PIO':
        if(action === 'clickThrough' || action === 'cancel'){
          const statusAction = action === 'clickThrough';
          setIsShowingProviderInsuredOptionMessageModal(statusAction);
          setIsReadOnlyRuleDetail(statusAction);
        }
        if(action === 'renderModalContent'){
          return isShowingProviderInsuredOptionMessageModal;
        }
        break;
      case 'HLD':
        if(action === 'clickThrough' || action === 'cancel'){
          const statusAction = action === 'clickThrough';
          setIsShowingAutoHoldModal(statusAction);
          setIsReadOnlyRuleDetail(statusAction);
        }
        if(action === 'renderModalContent'){
          return isShowingAutoHoldModal;
        }
        break;  
      case 'SSN':
        if(action === 'clickThrough' || action === 'cancel'){
          const statusAction = action === 'clickThrough';
          setIsShowingMemberIdModal(statusAction);
          setIsReadOnlyRuleDetail(statusAction);
        }
        if(action === 'renderModalContent'){
          return isShowingMemberIdModal;
        }
        break;
      case 'DPX':
        if(action === 'clickThrough' || action === 'cancel'){
          const statusAction = action === 'clickThrough';
          setIsShowingDuplexRuleModal(statusAction);
          setIsReadOnlyRuleDetail(statusAction);
        }
        if(action === 'renderModalContent'){
          return isShowingDuplexRuleModal;
        }
        break;
        case 'PDT':
          if(action === 'clickThrough' || action === 'cancel'){
            const statusAction = action === 'clickThrough';
            setIsShowingPDFTaggingModal(statusAction);
            setIsReadOnlyRuleDetail(statusAction);
          }
          if(action === 'renderModalContent'){
            return isShowingPDFTaggingModal
          }
          break;   
      default: return null;
    }
    return null;
  }

  const onCancel = () => {
    handleClickAction('cancel');
  }

  const selectedStatus = get(selectedRuleRecord, 'status', []);
  const gridRecords = !loading.has('ruleDetailsGridLoading') && 
  selectedStatus.includes('L') && 
  !isCloningRule ? generateGridRecordsFromProps(constructPageList(records)) : [];

  return (
    <>
      {notifications.has('ruleDetailsError') && (
        <NotificationBanner
          domID="api-error-notification"
          type={notifications.get('ruleDetailsError').type}
          text={notifications.get('ruleDetailsError').msg}
          onDismiss={() => deleteNotification('ruleDetailsError')}
          autoDismiss
          timer={1800000}
        />
      )}
      {isShowingOverwriteModal && (
        <OverwriteRuleModal
          selectedRecord={selectedRecord}
          selectedRuleTypeId={selectedRuleTypeId}
          overwriteTestRuleRequestTrigger={overwriteTestRuleRequestTrigger}
          loading={loading}
          setIsShowingOverwriteModal={setIsShowingOverwriteModal}
        />
      )}
      <LiveGridHeader
        headerName="Details"
        recordCount={!loading.has('ruleDetailsGridLoading') && records && !isCloningRule ? records.length : null}
        selectedItemIds={new Set([])}
        isPostingRulesForRuleTypes={isPostingRulesForRuleTypes}
        isCloningRule={isCloningRule}
        selectedRuleRecord={selectedRuleRecord}
      />
      <ScrollableGridWrapper>
        {loading.has('ruleDetailsGridLoading') ? (
          <LoadingContainer>
            <GridLoadingWrapper>
              <LoadingIndicator
                domID="live-grid-loading-indicator"
                length="45px"
              />
            </GridLoadingWrapper>
          </LoadingContainer>
        ) : (
          <Grid
            {...gridConfig}
            rowComponent={ClickableRow}
            records={gridRecords}
            emptyGridMessage="There are no rule details and no live rules at this time."
            supportSelection={false}
            onClickThrough={(e, record) => onRowClickThrough(e, record)}
          />
        )}
      </ScrollableGridWrapper>
      {renderModalContent() && (
        <Modal
          isOpen={renderModalContent()}
          onModalToggle={onCancel}
        >
          <ModalTitlePaddingWrapper>
            <StyledHeader>
              {`Live Rule Detail: ${
                selectedRuleRecord
                  ? selectedRuleRecord.name
                  : selectedRecord.name
              }`}
            </StyledHeader>
          </ModalTitlePaddingWrapper>

          <RuleDetailsModal
            actionType='Read only'
            selectedRuleRecord={selectedRuleRecord}
            selectedRuleTypeId={selectedRuleTypeId}
            selectedRuleDetail={selectedRuleDetail}
            setSelectedRuleDetail={setSelectedRuleDetail}
            setIsShowingCustomerServiceMessageModal={setIsShowingCustomerServiceMessageModal}
            isShowingCustomerServiceMessageModal={isShowingCustomerServiceMessageModal}
            setIsShowingCheckTextRuleModal={setIsShowingCheckTextRuleModal}
            isShowingCheckTextRuleModal={isShowingCheckTextRuleModal}
            isShowingErisaMessageModal={isShowingErisaMessageModal}
            setIsShowingErisaMessageModal={setIsShowingErisaMessageModal}
            setIsShowingProductionArrangementModal={setIsShowingProductionArrangementModal}
            isShowingProductionArrangementModal={isShowingProductionArrangementModal}
            setIsShowingSignatureRuleModal={setIsShowingSignatureRuleModal}
            isShowingSignatureRuleModal={isShowingSignatureRuleModal}
            isShowingPDFTaggingModal={isShowingPDFTaggingModal}
            setIsShowingPDFTaggingModal={setIsShowingPDFTaggingModal}
            loading={loading}
            notifications={notifications}
            setIsEditingRuleDetail={setIsEditingRuleDetail}
            setIsCloningRuleDetail={setIsCloningRuleDetail}
            setIsPostingRuleDetail={setIsPostingRuleDetail}
            setIsReadOnlyRuleDetail={setIsReadOnlyRuleDetail}
            isEditingRuleDetail={isEditingRuleDetail}
            detailElements={detailElements}
            onCancel={onCancel}
            setIsShowingImageRuleModal={setIsShowingImageRuleModal}
            setIsShowingReturnAddressModal={setIsShowingReturnAddressModal}
            isShowingImageRuleModal={isShowingImageRuleModal}
            isShowingReturnAddressModal={isShowingReturnAddressModal}
            isShowingTaxIdArrangementRuleModal={isShowingTaxIdArrangementRuleModal}
            setIsShowingTaxIdArrangementRuleModal={setIsShowingTaxIdArrangementRuleModal}
            isShowingHighDollarReviewModal={isShowingHighDollarReviewModal}
            setShowingHighDollarReviewModal={setShowingHighDollarReviewModal}
            isShowingMemberIdModal={isShowingMemberIdModal}
            setIsShowingMemberIdModal={setIsShowingMemberIdModal}
            setIsShowingGeneralMessageModal={setIsShowingGeneralMessageModal}
            setIsShowingProviderInsuredOptionMessageModal={setIsShowingProviderInsuredOptionMessageModal}
            isShowingAutoHoldModal={isShowingAutoHoldModal}
            setIsShowingAutoHoldModal={setIsShowingAutoHoldModal}
            isShowingDuplexRuleModal={isShowingDuplexRuleModal}
            setIsShowingDuplexRuleModal={setIsShowingDuplexRuleModal}
          />
        </Modal>
      )}
    </>
  );
};

RuleDetailsGridWrapper.defaultProps = {
  records: [],
  selectedRuleRecord: {},
  selectedRuleTypeId: null
};

RuleDetailsGridWrapper.propTypes = {
  isPostingRulesForRuleTypes: PropTypes.bool,
  isCloningRule: PropTypes.bool,
  records: PropTypes.array,
  gridConfig: PropTypes.shape({ columns: PropTypes.shape({}) }).isRequired,
  setIsViewingRuleDetails: PropTypes.func,
  selectedRuleRecord: PropTypes.object,
  selectedRuleTypeId: PropTypes.string,
  columns: PropTypes.object,
  headerText: PropTypes.string,
  headerCount: PropTypes.number,
  toggleDetailsView: PropTypes.func.isRequired,
  selectedRecord: PropTypes.object,
  overwriteTestRuleRequestTrigger: PropTypes.func,
  loading: PropTypes.object,
  notifications: PropTypes.object,
  deleteNotification: PropTypes.func,
  isShowingOverwriteModal: PropTypes.bool,
  setIsShowingOverwriteModal: PropTypes.func
};

export default RuleDetailsGridWrapper;
