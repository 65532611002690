export const ACTIVATE_GROUP_REQUEST_TRIGGER = 'ACTIVATE_GROUP_REQUEST_TRIGGER';
export const GROUPS_POST_REQUEST_TRIGGER = 'GROUPS_POST_REQUEST_TRIGGER';
export const GROUPS_POST_REQUEST_SUCCESS = 'GROUPS_POST_REQUEST_SUCCESS';
export const GROUPS_POST_REQUEST_ERROR = 'GROUPS_POST_REQUEST_ERROR';
export const GROUPS_PUT_REQUEST_TRIGGER = 'GROUPS_PUT_REQUEST_TRIGGER';
export const GROUPS_PUT_REQUEST_SUCCESS = 'GROUPS_PUT_REQUEST_SUCCESS';
export const GROUPS_PUT_REQUEST_ERROR = 'GROUPS_PUT_REQUEST_ERROR';
export const SET_IS_ACTIVATING_GROUP = 'SET_IS_ACTIVATING_GROUP';
export const SET_IS_DEACTIVATING_GROUP = 'SET_IS_DEACTIVATING_GROUP';
export const SET_IS_CLONING_GROUP_STATE = 'SET_IS_CLONING_GROUP_STATE';
export const SET_IS_SHOWING_CLONE_GROUP_MODAL =
  'SET_IS_SHOWING_CLONE_GROUP_MODAL';
export const SET_IS_SHOWING_DEACTIVATE_GROUP_MODAL =
  'SET_IS_SHOWING_DEACTIVATE_GROUP_MODAL';
export const SET_IS_SHOWING_ACTIVATE_GROUP_MODAL =
  'SET_IS_SHOWING_ACTIVATE_GROUP_MODAL';
export const DEACTIVATE_GROUP_REQUEST_TRIGGER =
  'DEACTIVATE_GROUP_REQUEST_TRIGGER';
export const RESET_GROUPS_STATE =
  'RESET_GROUPS_STATE';
