
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  Tooltip
} from 'ui-core/dist'
import { useDashbordContact } from '../../api/useDashboard';
import {
  LoadingPage,
} from '../../components';
import withAxiosCancellation from '../../components/withAxiosCancellation'

const Contacts = ({getCancelSourceWithName}) => {
  const [contactData, setContactData] = useState([]);
  const [columns, setColumns] = useState(new Set([]));
  const { dataSourceDesc } = useSelector(({ context }) => context?.context || {});
  const clientId = dataSourceDesc.split('-')[0].trim().replace('_', '');
  const { contactList: contactList, isLoading: cIsLoading } = useDashbordContact(clientId, getCancelSourceWithName('contactList'));

  useEffect(() => {
    if (contactList.length) {
      setColumns(new Set([
        {
          dataName: 'id',
          text: 'Number',
          cellType: 'text',
        },
        {
          dataName: 'title',
          text: 'Title',
          cellType: 'text',
        },
        {
          dataName: 'name',
          text: 'Name',
          cellType: 'text',
        },
        {
          dataName: 'email',
          text: 'Email',
          cellType: 'text',
        },
        {
          dataName: 'formattedPhone',
          text: 'Phone',
          cellType: 'text',
        }
      ]));
      const newList = contactList && contactList.length && contactList.map(contact => {
        return {
          ...contact,
          email: <Tooltip
            caretToAnchor={8}
            dataTestId="test-EmailToolTip"
            domID="EmailToolTip"
            tooltipContent={contact.email}
            tooltipPosition="top-center"
            tooltipWidth={300}
          >
            <a className='contactListEmail' href={`mailto:${contact.email}`}>{contact.email}</a>
          </Tooltip>
          // <>

          //   <ReactTooltip id={`reporttooltip_${contact.email}`} type='dark' effect='solid'>
          //     <span>{contact.email}</span>
          //   </ReactTooltip>
          // </>
        }
      })
      setContactData(newList);
    } else if (contactList.length === 0) {
      setColumns(new Set([
        {
          dataName: 'title',
          text: 'Title',
          cellType: 'text',
        },
        {
          dataName: 'name',
          text: 'Name',
          cellType: 'text',
        },
        {
          dataName: 'email',
          text: 'Email',
          cellType: 'text',
        },
        {
          dataName: 'formattedPhone',
          text: 'Phone',
          cellType: 'text',
        }
      ]))
      setContactData([{
        title: 'Client Analyst',
        name: 'Customer Support Rep',
        email: <Tooltip
          caretToAnchor={8}
          dataTestId="test-EmailToolTip"
          domID="EmailToolTip"
          tooltipContent="ABFIncoming@changehealthcare.com"
          tooltipPosition="top-center"
          tooltipWidth={300}
        >
          <a className='contactListEmail' href={`mailto:ABFIncoming@changehealthcare.com`}>ABFIncoming@changehealthcare.com</a>
        </Tooltip>,
        formattedPhone: '(800)804-7430'
      }]);
    }
  }, [contactList]);

  return (
    <>
      <h3 className="contactHeader">Contacts</h3>
      {
        cIsLoading ? (<LoadingPage height='20vh' />) : (
          <Grid
            columns={columns}
            dataTestId="test-contact"
            records={contactData || []}
          />
        )
      }
    </>
  )
}

export default withAxiosCancellation(Contacts);