export const DELIVERY_ADDRESS_GRID={
  columns: new Set([
    {
      dataName: 'addressId',
      text: 'Address ID',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'description',
      text: 'Description',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    }
  ]),
  selectedItemIds: new Set([]),
  bookmarkedItemIds: new Set([]),
  domID: 'deliveryAddress',
  dataTestId: 'deliveryAddress',  
  isConfigurable: true,
  supportSelection: false
};

export const CANNOT_DEACTIVATE_ADDRESS_ID = "00000";

export const SELECT_RECORD_OPTIONS = [{label:'Active',value: true},{label: 'Inactive',value: false}]