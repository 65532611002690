import {
  SET_LOGON,
  LOGOUT_ERROR,
  USER_LOGGED_OUT,
  CHANGE_PASSWORD,
  CLEAR_NOTIFICATION_BANNER
} from './constants';

export const setLogonDetails = (payload) => ({
  type: SET_LOGON,
  payload
})

export const onLogoutError = (payload) => ({
  type: LOGOUT_ERROR,
  payload
})

export const clearLogonDetails = (payload) => ({
  type: USER_LOGGED_OUT,
  payload
})

export const clearNotificationBanner = () => ({
  type: CLEAR_NOTIFICATION_BANNER,
})

export const resetClearNotificationBanner=()=>({
  type: 'RESET_CLEAR_NOTIFICATION_BANNER'
})

export const changePassword = (payload) => ({
  type: CHANGE_PASSWORD,
  payload
})