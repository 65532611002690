/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import { useEffect, useRef, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux';
import { useAxios, useAxiosPost } from '../hooks'
import { setErrors } from '../components/ErrorDisplaySection/modules/action';
import { DOC_MANAGER_ROUTE_DELIVERY_TYPE_NO_CHANGE_VALUE } from '../containers/DocumentManager/Modules/constants'
import useNotificationBannerHook from '../useNotificationBannerHook';

// Extend the use axios hookk so that we can handle notifications
// props is used ot extend onSuccess, on Error, and onSettled in case
// extra business logic is needed in the end containter
// useNotification banner can ONLY  be used insice the <PageLayout /> tree
// so if you need to, for some unknown reason use this outside of that tree
// you cannot use this hook.
const useCurrentTime = (props = {}) => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const dispatch = useDispatch();
  const { data, status, ...rest } = useAxios({
    url: 'clockLibrary/getCurrentTimeAsString',
    method: 'POST',
    payload: {}
  })

  useEffect(() => {
    if (data && data.resp) {
      setCurrentTime(data.resp);
    }
    dispatch(setErrors(data));
  }, [data]);

  return { currentTime, status, ...rest }
}

const useReleaseTime = (props = {}) => {
  const dispatch = useDispatch();
  const [releaseTime, setReleaseTime] = useState(new Date());
  const { data, status, ...rest } = useAxios({
    url: 'clockLibrary/getNextReleaseAsString',
    method: 'POST',
    payload: {}
  })

  useEffect(() => {
    if (data && data.resp) {
      setReleaseTime(data.resp);
    }
    dispatch(setErrors(data));
  }, [data]);

  return { releaseTime, status, ...rest }
}

const useFormNumbers = () => useAxios({
  url: 'documentManager/getFormNumbers',
  method: 'POST',
  payload: {}
})

const useEmployerGroup = (props={}) => {
  const [empData, setEmpData] = useState([]);
  const { data, isLoading, postData } = useAxiosPost({
    ...props,
    url: 'discriminationTypes/getEmployerGroup',
    method: 'POST',
    dispatchError: false
  });
  useEffect(() => {
    if (data) {
      setEmpData(data);
    }
  }, [data])
  return { empData, isLoading, getEmployerGroup: postData }
}

const useEmployerGroupsLite = (props={}) => {
  const [empGroupData, setEmpGroupData] = useState([]);
  const { data, postData } = useAxiosPost({
    ...props,
    url: 'empGroupManager/getEmployerGroupsLite',
    method: 'POST',
    dispatchError: false
  });
  useEffect(() => {
    if (data && data.length > 0) {
      setEmpGroupData(data && data.length > 0 && data.sort((a, b) => {
        const nameA = a.label.toUpperCase(); // ignore upper and lowercase
        const nameB = b.label.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      }));
    } else {
      setEmpGroupData([])
    }
  }, [data])
  return { empGroupData, getEmployerGroupsLite: postData }
}

const useWebGroupList = (props={}) => {
  const [webGroupData, setWebroupData] = useState([]);
  const { data, postData } = useAxiosPost({
    ...props,
    url: 'webGroupManager/getWebGroupList',
    method: 'POST',
    dispatchError: false
  });

  useEffect(() => {
    setWebroupData(data);
  }, [data])
  return { webGroupData, getWebGroupList: postData }
}

const useSearchFiles = (props = {}) => {
  const [totalRecordsResp, settotalRecordsResp] = useState(0)
  const [allData, setAllData] = useState([])
  const { data, error, status, postData, isError, isLoading, ...rest } = useAxiosPost({
    ...props,
    url: 'documentManager/searchFiles',
    method: 'POST',
    payload: {}
  })

  useEffect(() => {
    if (data) {
      const { collection } = data;
      if (collection) {
        if (collection.File && collection.File) {
          if (collection.File.constructor.name === 'Array')
            setAllData(JSON.parse(JSON.stringify(collection.File.map(f => ({ ...f, filekeyUnique: f.filekey._ })))))
          else
            setAllData(JSON.parse(JSON.stringify([
              {
                ...collection.File,
                filekeyUnique: collection.File.filekey._
              }])));
        }
        if (collection && collection.$) {
          const { totalRecords } = collection.$;
          settotalRecordsResp(totalRecords)
        }
      }

    }
  }, [data, totalRecordsResp])
  return { allData, totalRecordsResp, isError, error, status, searchFiles: postData, isLoading, ...rest }
}

const useSearchDocumentsWithDetail = (callback, getCancelSource) => {
  const [totalRecords, setTotalRecords] = useState(0)
  const [records, setRecords] = useState([]);
  const { postData, isLoading, isError, error } = useAxiosPost({
    getCancelSource,
    url: 'documentManager/searchDocumentsWithDetail',
    method: 'POST',
    onSuccess: (d) => {
      if (d) {
        const { domXml } = d;
        if (domXml && domXml.collection) {
          const { $, Document } = domXml.collection
          if ($) {
            const dmetadata = { ...$ }
            if (Document) {
              if (dmetadata && dmetadata.totalRecords) {
                setTotalRecords(dmetadata.totalRecords)
              }
              const documentSearchResults = JSON.parse(JSON.stringify(Document))
              if (documentSearchResults && documentSearchResults.length > 0) {
                setRecords(JSON.parse(JSON.stringify(documentSearchResults)))
              } else {
                setTotalRecords(0);
                setRecords(JSON.parse(JSON.stringify([])))
              }
            } else {
              setTotalRecords(0);
              setRecords(JSON.parse(JSON.stringify([])))
            }
            if (callback) {
              callback(dmetadata)
            }
          }
        }
      }
    },
    onError: (e) =>
      // what happens on error? if you need something extra
      // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if (e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })
  return { totalRecords, records, postData, isLoading, isError, error };
}
const useSearchDocumentsQuickFull = (errorcallback, getCancelSource) => {
  const [suppressdata, setsuppressdata] = useState('F')
  const [errorDesc, setErrorDesc] = useState('');
  const [totalRecords, setTotalRecords] = useState(0);
  const [records, setRecords] = useState([]);
  const { postData, isLoading, isError, error } = useAxiosPost({
    getCancelSource,
    url: 'documentManager/quickSearchFull',
    method: 'POST',
    onSuccess: (d) => {
      if (d) {
        const { superdocuments } = d;
        const { error: errorresp1 } = d;
        const { errors: errorresp } = d;
        const { resp } = d;
        if (errorresp1) {
          const { errordescription } = errorresp1;
          if (errordescription) {
            if (errorcallback) {
              setTotalRecords(0);
              setRecords(JSON.parse(JSON.stringify([])))
              setErrorDesc('');
              errorcallback(errordescription);
            }
            return;
          }
        }
        if (errorresp) {
          const  errordescription  = errorresp?.error?.errordescription;
          if (errordescription) {
            if (errorcallback) {
              setTotalRecords(0);
              setRecords(JSON.parse(JSON.stringify([])))
              setErrorDesc(errordescription);
              // errorcallback(errordescription);
            }
            return;
          }
        }
        if (resp) {
          if (errorcallback) {
            setTotalRecords(0);
            setRecords(JSON.parse(JSON.stringify([])))
            setErrorDesc('')
            errorcallback(resp);
          }
          return;
        }
        if (superdocuments && superdocuments.superdocument && superdocuments.suppressdata) {
          const { superdocument, suppressdata: suppressdataresp } = superdocuments
          if (suppressdataresp !== 'F') {
            setsuppressdata(suppressdataresp);
          }
          if (superdocument && superdocument.length > 0) {
            const retsds = [];
            superdocument.forEach((sd) => {
              if (sd.documents && sd.documents.document && sd.documents.document.length > 0) {
                sd.documents.document.forEach((doc) => {
                  retsds.push({
                    bPreview: sd.bPreview,
                    key: sd.key,
                    pdf: sd.pdf,
                    documentKey: doc.key,
                    checknumber: doc.checknumber,
                    checkamount: doc.checkamount,
                    groupID: doc.groupID,
                    pdfdocument: doc.pdfdocument,
                    releasedate: doc.releasedate,
                    recipienttype: doc.recipienttype,
                    documenttype: doc.documenttype,
                    claimtype: doc.claimtype,
                    claims: doc.claims
                  })
                })
              }
            })
            setTotalRecords(retsds.length);
            setRecords(JSON.parse(JSON.stringify(retsds)))
            setErrorDesc('')
          } else {
            setTotalRecords(0);
            setRecords(JSON.parse(JSON.stringify([])))
            setErrorDesc('')
          }
        }
      }
    },
    onError: (e) =>
      // what happens on error? if you need something extra
      // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if (e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })
  return { suppressdata, errorDesc, totalRecords, records, postData, isLoading, isError, error };
}
const useDocumentDetailSource = (props={}) => {
  const [showMoreInfo, setShowMoreInfo] = useState(null);
  const { postData, isLoading,
    isError, error } = useAxiosPost({
      ...props,
      url: 'documentManager/getDocumentDetailSource',
      method: 'POST',
      onSuccess: (d) => {
        if (d && d.Document) {
          setShowMoreInfo(JSON.parse(JSON.stringify(d.Document)))
        }
      },
      onError: (e) =>
        // what happens on error? if you need something extra
        // check api and see that the notifications are already handled there
        e
      ,
      // this will override the onSuccess and onError so just pick to use this
      // or to use the other 2
      onSettled: (d, e) => {
        if (e) {
          // do a thing
          return e
        }
        // some logic here
        return d
      },
    })
  return { showMoreInfo, postData, isLoading, isError, error };
}
const useGetDocumentDetailSourceDirect = () => {
  const { directPostCall } = useAxiosPost({
    url: 'documentManager/getDocumentDetailSource',
    method: 'POST',
    dispatchError: false
  });
  return { directPostCall }
}
const useGetCountryListDirect = () => {
  // const { directPostCall } = useAxiosPost({
  //   url: 'documentManager/getCountryList',
  //   method: 'POST',
  //   dispatchError: false
  // });
  // return { directPostCall }
  const [Country, setCountry] = useState([]);
  const { data, postData, ...rest } = useAxiosPost({
    url: 'documentManager/getCountryList',
    method: 'POST',
    dispatchError: true
  });

  useEffect(() => {
    if (data && data?.Countries && data?.Countries?.Country)
      setCountry(JSON.parse(JSON.stringify(data?.Countries?.Country)));
  }, [data])
  return { Country, getCountryList: postData, ...rest }
}

const useHoldFiles = () => {
  const [transactionId, setresultConfirmed] = useState([]);
  const { data, postData, ...rest } = useAxiosPost({
    url: 'documentManager/holdFiles',
    method: 'POST',
    dispatchError: false
  });

  useEffect(() => {
    if (data)
      setresultConfirmed(data);
  }, [data])
  return { transactionId, nextClick: postData, ...rest }
}

const useSearchHoldGroups = (props = {}) => {
  const [totalRecordsResp, settotalRecordsResp] = useState(0)
  const [allData, setAllData] = useState([])
  const { data, error, status, postData, isError, isLoading, ...rest } = useAxiosPost({
    ...props,
    url: 'documentManager/searchGroups',
    method: 'POST',
    payload: {}
  })

  useEffect(() => {
    if (data) {
      const { collection } = data;
      if (collection) {
        if (collection.Group) {
          if (collection.Group.constructor.name === 'Array')
            setAllData(JSON.parse(JSON.stringify(collection.Group.map(x => ({
              ...x,
              uniqueId: `${x.filekey}_${x.groupid}`
            })))))
          else
            setAllData(JSON.parse(JSON.stringify([{
              ...collection.Group,
              uniqueId: `${collection.Group.filekey}_${collection.Group.groupid}`
            }])));
        }
        if (collection && collection.$) {
          const { totalRecords } = collection.$;
          settotalRecordsResp(totalRecords)
        }
      }

    }
  }, [data, totalRecordsResp])
  return { allData, totalRecordsResp, isError, error, status, searchGroups: postData, isLoading, ...rest }
}

const usePreviewFile = (setConfig) => {
  const [file, setFile] = useState('');
  const configRef = useRef(null);
  const previewFile = ({ payload, config }) => {
    configRef.current = config;
    postData(payload);
    if (configRef && configRef.current && setConfig) {
      setConfig(JSON.parse(JSON.stringify(configRef.current)))
    }
  }
  const { data, status, error, isError, isLoading, isSuccess, refetch, postData, metaData } = useAxiosPost({
    url: `documentManager/printPreview`,
    method: 'POST',
    responseType: 'blob',
    dispatchError: false
  });

  useEffect(() => {
    if (data) {
      setFile(data);
    }
  }, [data]);

  return { file, status, error, isError, isLoading, isSuccess, refetchPreviewFile: refetch, previewFile, metaData }
}

const useGetPdfMerged = (setConfig) => {
  const [file, setFile] = useState('');
  const configRef = useRef(null);
  const getPdfMerged = ({ payload, config }) => {
    configRef.current = config;
    postData(payload);
    if (configRef && configRef.current && setConfig) {
      setConfig(JSON.parse(JSON.stringify(configRef.current)))
    }
  }
  const { data, status, error, isError, isLoading, isSuccess, refetch, postData, metaData } = useAxiosPost({
    url: `documentManager/getPdfMerged`,
    method: 'POST',
    responseType: 'blob',
    dispatchError: false
  });

  useEffect(() => {
    if (data) {
      setFile(data);
    }
  }, [data]);

  return { file, status, error, isError, isLoading, isSuccess, refetchGetPdfMerged: refetch, getPdfMerged, metaData }
}

const useGetPdfFile = (setConfig) => {
  const [file, setFile] = useState('');
  const configRef = useRef(null);
  const getPdfFile = ({ payload, config }) => {
    configRef.current = config;
    postData(payload);
    if (configRef && configRef.current && setConfig) {
      setConfig(JSON.parse(JSON.stringify(configRef.current)))
    }
  }
  const { data, status, error, isError, isLoading, isSuccess, refetch, postData, metaData } = useAxiosPost({
    url: `documentManager/getPdfFile`,
    method: 'POST',
    responseType: 'blob',
    dispatchError: false
  });

  useEffect(() => {
    if (data) {
      setFile(data);
    }
  }, [data]);

  return { file, status, error, isError, isLoading, isSuccess, refetchGetPdfFile: refetch, getPdfFile, metaData }
}

const useDownloadFile = (setConfig) => {
  const [file, setFile] = useState('');
  const configRef = useRef(null);
  const geteeobpdf = ({ payload, config }) => {
    configRef.current = config;
    postData(payload);
    if (configRef && configRef.current && setConfig) {
      setConfig(JSON.parse(JSON.stringify(configRef.current)))
    }
  }
  const { data, status, error, isError, isLoading, isSuccess, refetch, postData, metaData } = useAxiosPost({
    url: `documentManager/downloadFile`,
    method: 'POST',
    responseType: 'blob',
    dispatchError: false
  });

  useEffect(() => {
    if (data) {
      setFile(data);
    }
  }, [data]);

  return { file, status, error, isError, isLoading, isSuccess, refetcheeobpdf: refetch, geteeobpdf, metaData }
}

const useGetPdfFileQuickSearch = (setConfig) => {
  const [file, setFile] = useState('');
  const configRef = useRef(null);
  const getPdfFile = ({ payload, config }) => {
    configRef.current = config;
    postData(payload);
    if (configRef && configRef.current && setConfig) {
      setConfig(JSON.parse(JSON.stringify(configRef.current)))
    }
  }
  const { data, status, error, isError, isLoading, isSuccess, refetch, postData, metaData } = useAxiosPost({
    url: `documentManager/getPdfFileQuickSearch`,
    method: 'POST',
    responseType: 'blob',
    dispatchError: false
  });

  useEffect(() => {
    if (data) {
      setFile(data);
    }
  }, [data]);

  return { file, status, error, isError, isLoading, isSuccess, refetchGetPdfFile: refetch, getPdfFile, metaData }
}

const useErrorResolution = (setConfig) => {
  const configRef = useRef(null);
  const getErrorResolution = ({ payload, config }) => {
    configRef.current = config;
    postData(payload);
    if (configRef && configRef.current && setConfig) {
      setConfig(JSON.parse(JSON.stringify(configRef.current)))
    }
  }
  const { data, status, error, isError, isLoading, isSuccess, refetch, postData, metaData } = useAxiosPost({
    url: `errorManager/getErrorDetailByDocKey`,
    method: 'POST',
    dispatchError: false
  });

  useEffect(() => {
    if (data) {
      if (configRef && configRef.current && setConfig) {
        setConfig(JSON.parse(JSON.stringify(configRef.current)))
      }
    }
  }, [data])

  return { data, status, error, isError, isLoading, isSuccess, refetchErrorResolution: refetch, getErrorResolution, metaData }
}

const useGetACHFileDetailsByKey = (setConfig) => {
  const configRef = useRef(null);
  const getACHFileDetailsByKey = ({ payload, config }) => {
    configRef.current = config;
    postData(payload);
    if (configRef && configRef.current && setConfig) {
      setConfig(JSON.parse(JSON.stringify(configRef.current)))
    }
  }
  const { data, status, error, isError, isLoading, isSuccess, refetch, postData, metaData } = useAxiosPost({
    url: `documentManager/getACHFileDetailsByKey`,
    method: 'POST',
    dispatchError: false
  });

  useEffect(() => {
    if (data) {
      if (configRef && configRef.current && setConfig) {
        setConfig(JSON.parse(JSON.stringify(configRef.current)))
      }
    }
  }, [data])

  return { data, status, error, isError, isLoading, isSuccess, refetchACHFileDetailsByKey: refetch, getACHFileDetailsByKey, metaData }
}
const useGetACHFileDetailsByFileID = (setConfig) => {
  const configRef = useRef(null);
  const getACHFileDetailsByFileID = ({ payload, config }) => {
    configRef.current = config;
    postData(payload);
    if (configRef && configRef.current && setConfig) {
      setConfig(JSON.parse(JSON.stringify(configRef.current)))
    }
  }
  const { data, status, error, isError, isLoading, isSuccess, refetch, postData, metaData } = useAxiosPost({
    url: `documentManager/getACHFileDetailsByFileID`,
    method: 'POST',
    dispatchError: false
  });

  useEffect(() => {
    if (data) {
      if (configRef && configRef.current && setConfig) {
        setConfig(JSON.parse(JSON.stringify(configRef.current)))
      }
    }
  }, [data])

  return { data, status, error, isError, isLoading, isSuccess, refetchACHFileDetailsByFileID: refetch, getACHFileDetailsByFileID, metaData }
}

const useGetEEOBEmail = (setConfig) => {
  const configRef = useRef(null);
  const getEEOBEmail = ({ payload, config }) => {
    configRef.current = config;
    postData(payload);
  }
  const { data, status, error, isError, isLoading, isSuccess, refetch, postData, metaData } = useAxiosPost({
    url: `documentManager/getEEOBEmail`,
    method: 'POST',
    dispatchError: false
  });

  useEffect(() => {
    if (data) {
      if (configRef && configRef.current && setConfig) {
        setConfig(JSON.parse(JSON.stringify(configRef.current)))
      }
    }
  }, [data])

  useEffect(() => {
    if (error) {
      if (configRef && configRef.current && setConfig) {
        setConfig(JSON.parse(JSON.stringify(configRef.current)))
      }
    }
  }, [error])

  return { data, status, error, isError, isLoading, isSuccess, refetcGetEEOBEmail: refetch, getEEOBEmail, metaData }
}

const useSearchReleaseFiles = (props = {}) => {
  const [totalRecordsResp, settotalRecordsResp] = useState(0)
  const [allData, setAllData] = useState([])
  const { data, error, status, postData, isError, isLoading, ...rest } = useAxiosPost({
    ...props,
    url: 'documentManager/getHeldFileList',
    method: 'POST',
    payload: {}
  })

  useEffect(() => {
    if (data) {
      const { collection } = data;
      if (collection) {
        if (collection.transaction) {
          if (collection.transaction.constructor.name === 'Array')
            setAllData(JSON.parse(JSON.stringify(collection.transaction)))
          else
            setAllData(JSON.parse(JSON.stringify([collection.transaction])))
        }
        if (collection && collection.$) {
          const { totalRecords } = collection.$;
          settotalRecordsResp(totalRecords)
        }
      }

    }
  }, [data, totalRecordsResp])
  return { allData, totalRecordsResp, isError, error, status, searchFiles: postData, isLoading, ...rest }
}

const useSubmitHoldGroups = (props = {}) => {
  const [transId, setTransId] = useState('')
  const { data, error, status, postData, isError, isLoading, ...rest } = useAxiosPost({
    ...props,
    url: 'documentManager/holdGroups',
    method: 'POST',
    payload: {}
  })

  const { notify } = useNotificationBannerHook()

  useEffect(() => {
    if (data && data.resp) {
      setTransId(data.resp);
      notify(`You have successfully held the files.  The transaction ID is ${data.resp}`, 'positive')
    }
    if (error) {
      notify(`There were errors processing your request: ${error?.fs}`, 'negative')
    }
  }, [data, error])
  return { transId, isError, error, status, submitGroups: postData, isLoading, ...rest }
}

const useSubmitReleaseFiles = (props = {}) => {
  const [transId, setTransId] = useState('')
  const { data, error, status, postData, isError, isLoading, ...rest } = useAxiosPost({
    ...props,
    url: 'documentManager/releaseFiles',
    method: 'POST',
    payload: {}
  })

  const { notify } = useNotificationBannerHook()

  useEffect(() => {
    if (data && data.resp) {
      setTransId(data.resp);
      notify(`You have successfully released the files.  The transaction ID is ${data.resp}`, 'positive')
    }
    if (error) {
      notify(`There were errors processing your request: ${error?.fs}`, 'negative')
    }
  }, [data, error])
  return { transId, isError, error, status, submitFiles: postData, isLoading, ...rest }
}

const useSearchHoldDocument = (props = {}) => {
  const [totalRecordsResp, settotalRecordsResp] = useState(0)
  const [allData, setAllData] = useState([])
  const { data, error, status, postData, isError, isLoading, ...rest } = useAxiosPost({
    ...props,
    url: 'documentManager/searchDocumentsNoDetail',
    method: 'POST',
    payload: {}
  })

  useEffect(() => {
    if (data) {
      const { collection } = data;
      if (collection) {
        if (collection && collection.Document) {
          let documents = []
          if (collection.Document.constructor.name === 'Array')
            documents = JSON.parse(JSON.stringify(collection.Document));
          else
            documents = JSON.parse(JSON.stringify([collection.Document]));
          const temp = documents.map((d) => {
            let claimNumber;
            if (d.claimNumbers.claimNumber.constructor.name === "Array") {
              claimNumber = d?.claimNumbers?.claimNumber[0] || '';
            } else {
              claimNumber = d?.claimNumbers?.claimNumber || '';
            }
            return {
              ...d,
              documentKey_claimNumber: `${d.documentKey}_${claimNumber}`
            }
          })
          setAllData(temp);
        }
        if (collection && collection.$) {
          const { totalRecords } = collection.$;
          settotalRecordsResp(totalRecords)
        }
      }

    }
  }, [data, totalRecordsResp])
  return { allData, totalRecordsResp, isError, error, status, searchDocument: postData, isLoading, ...rest }
}

const useSubmitHoldDocument = (props = {}) => {
  const [transId, setTransId] = useState('')
  const { data, error, status, postData, isError, isLoading, ...rest } = useAxiosPost({
    ...props,
    url: 'documentManager/holdDocuments',
    method: 'POST',
    payload: {}
  })

  const { notify } = useNotificationBannerHook()

  useEffect(() => {
    if (data && data.resp) {
      setTransId(data.resp);
      notify(`You have successfully held the documents.  The transaction ID is ${data.resp}`, 'positive')
    }
    if (error) {
      notify(`There were errors processing your request: ${error?.fs}`, 'negative')
    }
  }, [data, error])
  return { transId, isError, error, status, submitDocuments: postData, isLoading, ...rest }
}

const useSearchReleaseDocument = (props = {}) => {
  const [totalRecordsResp, settotalRecordsResp] = useState(0)
  const [allData, setAllData] = useState([])
  const { data, error, status, postData, isError, isLoading, ...rest } = useAxiosPost({
    ...props,
    url: 'documentManager/getHeldDocumentList',
    method: 'POST',
    payload: {}
  })

  useEffect(() => {
    if (data) {
      const { collection } = data;
      if (collection) {
        if (collection && collection.transaction) {
          if (collection.transaction.constructor.name === 'Array')
            setAllData(JSON.parse(JSON.stringify(collection.transaction.map(x => ({
              ...x,
              uniqueId: `${x.documentKey}_${x.id}`
            })))))
          else
            setAllData(JSON.parse(JSON.stringify([
              {
                ...collection.transaction,
                uniqueId: `${collection.transaction.documentKey}_${collection.transaction.id}`
              }
            ])))
        }
        if (collection && collection.$) {
          const { totalRecords } = collection.$;
          settotalRecordsResp(totalRecords)
        }
      }

    }
  }, [data, totalRecordsResp])
  return { allData, totalRecordsResp, isError, error, status, searchDocument: postData, isLoading, ...rest }
}

const useSubmitReleaseDocument = (props = {}) => {
  const [transId, setTransId] = useState('')
  const { data, error, status, postData, isError, isLoading, ...rest } = useAxiosPost({
    ...props,
    url: 'documentManager/releaseDocuments',
    method: 'POST',
    payload: {}
  })

  const { notify } = useNotificationBannerHook()

  useEffect(() => {
    if (data && data.resp) {
      setTransId(data.resp);
      notify(`You have successfully released the documents.  The transaction ID is ${data.resp}`, 'positive')
    }
    if (error) {
      notify(`There were errors processing your request: ${error?.fs}`, 'negative')
    }
  }, [data, error])
  return { transId, isError, error, status, submitDocument: postData, isLoading, ...rest }
}

const useSearchReleaseGroup = (props = {}) => {
  const [totalRecordsResp, settotalRecordsResp] = useState(0)
  const [allData, setAllData] = useState([])
  const { data, error, status, postData, isError, isLoading, ...rest } = useAxiosPost({
    ...props,
    url: 'documentManager/getAutoHoldList',
    method: 'POST',
    payload: {}
  })

  useEffect(() => {
    if (data) {
      const { collection } = data;
      if (collection) {
        if (collection && collection.transaction) {
          if (collection.transaction.constructor.name === 'Array')
            setAllData(JSON.parse(JSON.stringify(collection.transaction)))
          else
            setAllData(JSON.parse(JSON.stringify([collection.transaction])))
        }
        if (collection && collection.$) {
          const { totalRecords } = collection.$;
          settotalRecordsResp(totalRecords)
        }
      }

    }
  }, [data, totalRecordsResp])
  return { allData, totalRecordsResp, isError, error, status, searchReleaseGroups: postData, isLoading, ...rest }
}
const useSubmitReleaseGroups = () => {
  const [transactionId, setresultConfirmed] = useState('');
  const { data, postData, ...rest } = useAxiosPost({
    url: 'documentManager/releaseAutoHold',
    method: 'POST',
    dispatchError: false
  });

  useEffect(() => {
    if (data && data.resp) {

      setresultConfirmed(data.resp);
    }
  }, [data])
  return { transactionId, nextClick: postData, ...rest }
}

const useDiscriminationTypes = () => {
  const [records, setRecords] = useState([]);
  const { data, postData, isLoading, isError, error } = useAxiosPost({
    url: 'discriminationTypes/buildXmlByType',
    method: 'POST',
    dispatchError: false,
  });

  useEffect(() => {
    if (data) {
      const d = [{ label: ' -- Search All --', value: 'none' }, ...data]
      setRecords(JSON.parse(JSON.stringify(d || [])));
    }
  }, [data])

  return { records, postData, isLoading, isError, error };
}
const useSubmitPurgeDocument = (props = {}) => {
  const [transId, setTransId] = useState('')
  const { data, error, status, postData, isError, isLoading, ...rest } = useAxiosPost({
    ...props,
    url: 'documentManager/purgeDocuments',
    method: 'POST',
    payload: {}
  })

  const { notify } = useNotificationBannerHook()

  useEffect(() => {
    if (data && data.resp) {
      setTransId(data.resp);
      notify(`You have successfully purged the documents.  The transaction ID is ${data.resp}`, 'positive')
    }
    if (error) {
      notify(`There were errors processing your request: ${error?.fs}`, 'negative')
    }
  }, [data, error])
  return { transId, isError, error, status, submitDocument: postData, isLoading, ...rest }
}
const useGetDeliveryTypesEx = (callback) => {
  const { data, status, error, isError, isLoading, isSuccess, refetch, postData, metaData } = useAxiosPost({
    url: 'documentManager/getDeliveryTypesEx',
    method: 'POST',
    dispatchError: false
  });

  useEffect(() => {
    if (data)
      if (callback) callback(false);
  }, [data])

  useEffect(() => {
    if (isError) {
      if (callback) callback(error);
    }
  }, [isError])
  return { data, status, error, isError, isLoading, isSuccess, refetch, metaData, postData }
}
const useGetProdRuleForRoute = () => {
  const { directPostCall } = useAxiosPost({
    url: 'documentManager/getProdRuleForRoute',
    method: 'POST',
    dispatchError: false
  });
  return { directPostCall }
}
const useCheckAddressForPoBox = () => {
  const { directPostCall } = useAxiosPost({
    url: 'documentManager/checkAddressForPoBox',
    method: 'POST',
    dispatchError: false
  });
  return { directPostCall }
}
const useCheckDelTypeForRoute = () => {
  const { directPostCall } = useAxiosPost({
    url: 'documentManager/checkDelTypeForRoute',
    method: 'POST',
    dispatchError: false
  });
  return { directPostCall }
}
const useGetDelTypeDescForRoute = () => {
  const { directPostCall } = useAxiosPost({
    url: 'documentManager/getDelTypeDescForRoute',
    method: 'POST',
    dispatchError: false
  });
  return { directPostCall }
}
const useChkClientCpyAddrForPoBx = () => {
  const { directPostCall } = useAxiosPost({
    url: 'documentManager/chkClientCpyAddrForPoBx',
    method: 'POST',
    dispatchError: false
  });
  return { directPostCall }
}
const useGetAddress = () => {
  const { directPostCall } = useAxiosPost({
    url: 'addressManager/getAddress',
    method: 'POST',
    dispatchError: false
  });
  return { directPostCall }
}

const usePurgeFiles = () => {
  const [transactionId, setresultConfirmed] = useState([]);
  const { data, postData, ...rest } = useAxiosPost({
    url: 'documentManager/purgeFiles',
    method: 'POST',
    dispatchError: false
  });

  useEffect(() => {
    if (data)
      setresultConfirmed(data);
  }, [data])
  return { transactionId, nextClick: postData, ...rest }
}

const useSubmitRouteDocument = (form, page) => {
  const [transId, setTransId] = useState('')
  const [url, seturl] = useState();

  useEffect(() => {
    if (page === 'documents') {
      if (form?.hasdocManagerOneOffRouteAddressObject) {
        seturl('documentManager/routeDocumentsTempAddress')
      } else {
        seturl('documentManager/routeDocuments')
      }
    } else if (page === 'files') {
      if (form?.hasdocManagerOneOffRouteAddressObject) {
        seturl('documentManager/routeFilesTempAddress')
      } else {
        seturl('documentManager/routeFiles')
      }
    } else if (page === 'groups') {
      if (form?.hasdocManagerOneOffRouteAddressObject) {
        seturl('documentManager/routeGroupsTempAddress')
      } else {
        seturl('documentManager/routeGroups')
      }
    }
  }, [form?.hasdocManagerOneOffRouteAddressObject])

  const { data, error, status, postData, isError, isLoading, ...rest } = useAxiosPost({
    url,
    method: 'POST',
    payload: {}
  })

  const submitDocuments = useCallback((selectedItemIds, count) => {
    const {
      selectedDeliveryAddress: addressId,
      selectedDeliveryType: deliveryTypeId,
      selectedReleaseItemsOption,
      docManagerOneOffRouteAddressObject,
      hasdocManagerOneOffRouteAddressObject
    } = form;
    let releasePreviousHolds = false;
    let keys;
    let releaseType = selectedReleaseItemsOption;
    if (!releaseType || releaseType.length !== 1) {
      releaseType = "X";
    }
    if (releaseType === "I" || releaseType === "S") {
      releasePreviousHolds = true;
    }
    if (page !== 'groups') {
      selectedItemIds = Array.from(selectedItemIds);
      keys = []
      keys[0] = releaseType;
      for (let k = 0; k < selectedItemIds.length; k++) {
        keys[k + 1] = selectedItemIds[k];
      }
    } else {
      keys = `<![CDATA[${releaseType}${selectedItemIds}]]>`
    }
    let keyName = 'pstrDocumentKeys'
    if (page === 'files') {
      keyName = 'pstrFileKeys'
    } else if (page === 'groups') {
      keyName = 'pstrGroupKeys'
    }
    let delType = deliveryTypeId;
    if (delType === DOC_MANAGER_ROUTE_DELIVERY_TYPE_NO_CHANGE_VALUE) {
      delType = '';
    }
    if (hasdocManagerOneOffRouteAddressObject) {
      const tempAddress = docManagerOneOffRouteAddressObject;
      tempAddress.addressId = null; // transient object; there's no ID

      // blank non-international fields
      if (tempAddress.intFlag === "1") {
        tempAddress.city = null;
        tempAddress.state = null;
        tempAddress.zip = null;
      }
      postData({
        [keyName]: keys, ...tempAddress, pstrDeliveryTypeId: delType,
        pblnReleasePrevHolds: releasePreviousHolds, count
      })
    } else {
      postData({ [keyName]: keys, pstrAddressId: addressId, pstrDeliveryTypeId: delType, pblnReleasePrevHolds: releasePreviousHolds, count })
    }
  }, [form?.selectedDeliveryAddress, form?.selectedDeliveryType, form?.selectedReleaseItemsOption,
  form?.docManagerOneOffRouteAddressObject, form?.hasdocManagerOneOffRouteAddressObject, url])

  const { notify } = useNotificationBannerHook()

  useEffect(() => {
    if (data && data.resp) {
      setTransId(data.resp);
      notify(`You have successfully routed the ${page}.  The transaction ID is ${data.resp}.`, 'positive')
    }
    if (error) {
      notify(`There were errors processing your request: ${error?.fs}`, 'negative')
    }
  }, [data, error])
  return { transId, isError, error, status, submitDocuments, isLoading, ...rest }
}


const useSubmitPurgeGroups = (props = {}) => {
  const [transId, setTransId] = useState('')
  const { data, error, status, postData, isError, isLoading, ...rest } = useAxiosPost({
    ...props,
    url: 'documentManager/purgeGroups',
    method: 'POST',
    payload: {}
  })

  const { notify } = useNotificationBannerHook()

  useEffect(() => {
    if (data && data.resp) {
      setTransId(data.resp);
      notify(`You have successfully purged the groups.  The transaction ID is ${data.resp}`, 'positive')
    }
    if (error) {
      notify(`There were errors processing your request: ${error?.fs}`, 'negative')
    }
  }, [data, error])
  return { transId, isError, error, status, submitGroups: postData, isLoading, ...rest }
}

const useFilePurgeHistory = (props = {}) => {
  const [totalRecordsResp, settotalRecordsResp] = useState(0)
  const [allData, setAllData] = useState([])
  const { data, error, status, postData, isError, isLoading, ...rest } = useAxiosPost({
    ...props,
    url: 'documentManager/getFilePurgeHistory',
    method: 'POST',
    payload: {}
  })

  useEffect(() => {
    if (data) {
      const { collection } = data;
      if (collection) {
        if (collection && collection.transaction) {
          const list = constructHistoryDocumentData(collection, 'File');
          setAllData(JSON.parse(JSON.stringify(list)))
        } else {
          setAllData([])
        }
        if (collection && collection.$) {
          const { totalRecords } = collection.$;
          settotalRecordsResp(totalRecords)
        }
      }

    }
  }, [data, totalRecordsResp])
  return { allData, totalRecordsResp, isError, error, status, searchFilePurgeHistory: postData, isLoading, ...rest }
}

const useFileRouteHistory = (props = {}) => {
  const [totalRecordsResp, settotalRecordsResp] = useState(0)
  const [allData, setAllData] = useState([])
  const { data, error, status, postData, isError, isLoading, ...rest } = useAxiosPost({
    ...props,
    url: 'documentManager/getFileRouteHistory',
    method: 'POST',
    payload: {}
  })

  useEffect(() => {
    if (data) {
      const { collection } = data;
      if (collection) {
        if (collection && collection.transaction) {
          const list = constructHistoryDocumentData(collection, 'File');
          setAllData(JSON.parse(JSON.stringify(list)))
        } else {
          setAllData([])
        }
        if (collection && collection.$) {
          const { totalRecords } = collection.$;
          settotalRecordsResp(totalRecords)
        }
      }

    }
  }, [data, totalRecordsResp])
  return { allData, totalRecordsResp, isError, error, status, searchFileRouteHistory: postData, isLoading, ...rest }
}

const useFileReleaseHistory = (props = {}) => {
  const [totalRecordsResp, settotalRecordsResp] = useState(0)
  const [allData, setAllData] = useState([])
  const { data, error, status, postData, isError, isLoading, ...rest } = useAxiosPost({
    ...props,
    url: 'documentManager/getFileReleaseHistory',
    method: 'POST',
    payload: {}
  })

  useEffect(() => {
    if (data) {
      const { collection } = data;
      if (collection) {
        if (collection && collection.transaction) {
          const list = constructHistoryDocumentData(collection, 'File');
          setAllData(JSON.parse(JSON.stringify(list)))
        } else {
          setAllData([])
        }
        if (collection && collection.$) {
          const { totalRecords } = collection.$;
          settotalRecordsResp(totalRecords)
        }
      }

    }
  }, [data, totalRecordsResp])
  return { allData, totalRecordsResp, isError, error, status, searchFileReleaseHistory: postData, isLoading, ...rest }
}

const useFileHoldHistory = (props = {}) => {
  const [totalRecordsResp, settotalRecordsResp] = useState(0)
  const [allData, setAllData] = useState([])
  const { data, error, status, postData, isError, isLoading, ...rest } = useAxiosPost({
    ...props,
    url: 'documentManager/getFileHoldHistory',
    method: 'POST',
    payload: {}
  })

  useEffect(() => {
    if (data) {
      const { collection } = data;
      if (collection) {
        if (collection && collection.transaction) {
          const list = constructHistoryDocumentData(collection, 'File');
          setAllData(JSON.parse(JSON.stringify(list)))
        } else {
          setAllData([])
        }
        if (collection && collection.$) {
          const { totalRecords } = collection.$;
          settotalRecordsResp(totalRecords)
        }
      }

    }
  }, [data, totalRecordsResp])
  return { allData, totalRecordsResp, isError, error, status, searchFileHoldHistory: postData, isLoading, ...rest }
}

const useGroupPurgeHistory = (props = {}) => {
  const [totalRecordsResp, settotalRecordsResp] = useState(0)
  const [allData, setAllData] = useState([])
  const { data, error, status, postData, isError, isLoading, ...rest } = useAxiosPost({
    ...props,
    url: 'documentManager/getGroupPurgeHistory',
    method: 'POST',
    payload: {}
  })

  useEffect(() => {
    if (data) {
      const { collection } = data;
      if (collection) {
        if (collection && collection.transaction) {
          const list = constructHistoryGroupData(collection);
          setAllData(JSON.parse(JSON.stringify(list)))
        } else {
          setAllData([])
        }
        if (collection && collection.$) {
          const { totalRecords } = collection.$;
          settotalRecordsResp(totalRecords)
        }
      }

    }
  }, [data, totalRecordsResp])
  return { allData, totalRecordsResp, isError, error, status, searchGroupPurgeHistory: postData, isLoading, ...rest }
}

const useGroupRouteHistory = (props = {}) => {
  const [totalRecordsResp, settotalRecordsResp] = useState(0)
  const [allData, setAllData] = useState([])
  const { data, error, status, postData, isError, isLoading, ...rest } = useAxiosPost({
    ...props,
    url: 'documentManager/getGroupRouteHistory',
    method: 'POST',
    payload: {}
  })

  useEffect(() => {
    if (data) {
      const { collection } = data;
      if (collection) {
        if (collection && collection.transaction) {
          const list = constructHistoryGroupData(collection);
          setAllData(JSON.parse(JSON.stringify(list)))
        } else {
          setAllData([])
        }
        if (collection && collection.$) {
          const { totalRecords } = collection.$;
          settotalRecordsResp(totalRecords)
        }
      }

    }
  }, [data, totalRecordsResp])
  return { allData, totalRecordsResp, isError, error, status, searchGroupRouteHistory: postData, isLoading, ...rest }
}

const useGroupReleaseHistory = (props = {}) => {
  const [totalRecordsResp, settotalRecordsResp] = useState(0)
  const [allData, setAllData] = useState([])
  const { data, error, status, postData, isError, isLoading, ...rest } = useAxiosPost({
    ...props,
    url: 'documentManager/getGroupReleaseHistory',
    method: 'POST',
    payload: {}
  })

  useEffect(() => {
    if (data) {
      const { collection } = data;
      if (collection) {
        if (collection && collection.transaction) {
          const list = constructHistoryGroupData(collection);
          setAllData(JSON.parse(JSON.stringify(list)))
        } else {
          setAllData([])
        }
        if (collection && collection.$) {
          const { totalRecords } = collection.$;
          settotalRecordsResp(totalRecords)
        }
      }

    }
  }, [data, totalRecordsResp])
  return { allData, totalRecordsResp, isError, error, status, searchGroupReleaseHistory: postData, isLoading, ...rest }
}

const useGroupHoldHistory = (props = {}) => {
  const [totalRecordsResp, settotalRecordsResp] = useState(0)
  const [allData, setAllData] = useState([])
  const { data, error, status, postData, isError, isLoading, ...rest } = useAxiosPost({
    ...props,
    url: 'documentManager/getGroupHoldHistory',
    method: 'POST',
    payload: {}
  })

  useEffect(() => {
    if (data) {
      const { collection } = data;
      if (collection) {
        if (collection && collection.transaction) {
          const list = constructHistoryGroupData(collection);
          setAllData(JSON.parse(JSON.stringify(list)))
        } else {
          setAllData([])
        }
        if (collection && collection.$) {
          const { totalRecords } = collection.$;
          settotalRecordsResp(totalRecords)
        }
      }

    }
  }, [data, totalRecordsResp])
  return { allData, totalRecordsResp, isError, error, status, searchGroupHoldHistory: postData, isLoading, ...rest }
}

const useAutoHoldHistory = (props = {}) => {
  const [totalRecordsResp, settotalRecordsResp] = useState(0)
  const [allData, setAllData] = useState([])
  const { data, error, status, postData, isError, isLoading, ...rest } = useAxiosPost({
    ...props,
    url: 'documentManager/getAutoHoldHistory',
    method: 'POST',
    payload: {}
  })

  useEffect(() => {
    if (data) {
      const { collection } = data;
      if (collection) {
        if (collection && collection.transaction) {
          const list = constructAutoHistory(collection);
          setAllData(JSON.parse(JSON.stringify(list)))
        } else {
          setAllData([])
        }
        if (collection && collection.$) {
          const { totalRecords } = collection.$;
          settotalRecordsResp(totalRecords)
        }
      }

    }
  }, [data, totalRecordsResp])
  return { allData, totalRecordsResp, isError, error, status, searchAutoHoldHistory: postData, isLoading, ...rest }
}

const useCustomReleaseHistory = (props = {}) => {
  const [totalRecordsResp, settotalRecordsResp] = useState(0)
  const [allData, setAllData] = useState([])
  const { data, error, status, postData, isError, isLoading, ...rest } = useAxiosPost({
    ...props,
    url: 'documentManager/getCustomReleaseHistory',
    method: 'POST',
    payload: {}
  })

  useEffect(() => {
    if (data) {
      const { collection } = data;
      if (collection) {
        if (collection && collection.transaction) {
          const list = constructAutoHistory(collection)
          setAllData(JSON.parse(JSON.stringify(list)))
        } else {
          setAllData([])
        }
        if (collection && collection.$) {
          const { totalRecords } = collection.$;
          settotalRecordsResp(totalRecords)
        }
      }

    }
  }, [data, totalRecordsResp])
  return { allData, totalRecordsResp, isError, error, status, searchCustomReleaseHistory: postData, isLoading, ...rest }
}

const useDocumentPurgeHistory = (props = {}) => {
  const [totalRecordsResp, settotalRecordsResp] = useState(0)
  const [allData, setAllData] = useState([])
  const { data, error, status, postData, isError, isLoading, ...rest } = useAxiosPost({
    ...props,
    url: 'documentManager/getDocumentPurgeHistory',
    method: 'POST',
    payload: {}
  })

  useEffect(() => {
    if (data) {
      const { collection } = data;
      if (collection) {
        if (collection && collection.transaction) {
          const list = constructHistoryDocumentData(collection, 'Document');
          setAllData(JSON.parse(JSON.stringify(list)))
        } else {
          setAllData([])
        }
        if (collection && collection.$) {
          const { totalRecords } = collection.$;
          settotalRecordsResp(totalRecords)
        }
      }

    }
  }, [data, totalRecordsResp])
  return { allData, totalRecordsResp, isError, error, status, searchDocumentPurgeHistory: postData, isLoading, ...rest }
}

const useDocumentRouteHistory = (props = {}) => {
  const [totalRecordsResp, settotalRecordsResp] = useState(0)
  const [allData, setAllData] = useState([])
  const { data, error, status, postData, isError, isLoading, ...rest } = useAxiosPost({
    ...props,
    url: 'documentManager/getDocumentRouteHistory',
    method: 'POST',
    payload: {}
  })

  useEffect(() => {
    if (data) {
      const { collection } = data;
      if (collection) {
        if (collection && collection.transaction) {
          const list = constructHistoryDocumentData(collection, 'Document');
          setAllData(JSON.parse(JSON.stringify(list)))
        } else {
          setAllData([])
        }
        if (collection && collection.$) {
          const { totalRecords } = collection.$;
          settotalRecordsResp(totalRecords)
        }
      }

    }
  }, [data, totalRecordsResp])
  return { allData, totalRecordsResp, isError, error, status, searchDocumentRouteHistory: postData, isLoading, ...rest }
}

const useDocumentReleaseHistory = (props = {}) => {
  const [totalRecordsResp, settotalRecordsResp] = useState(0)
  const [allData, setAllData] = useState([])
  const { data, error, status, postData, isError, isLoading, ...rest } = useAxiosPost({
    ...props,
    url: 'documentManager/getDocumentReleaseHistory',
    method: 'POST',
    payload: {}
  })

  useEffect(() => {
    if (data) {
      const { collection } = data;
      if (collection) {
        if (collection && collection.transaction) {
          const list = constructHistoryDocumentData(collection, 'Document');
          setAllData(JSON.parse(JSON.stringify(list)))
        } else {
          setAllData([])
        }
        if (collection && collection.$) {
          const { totalRecords } = collection.$;
          settotalRecordsResp(totalRecords)
        }
      }

    }
  }, [data, totalRecordsResp])
  return { allData, totalRecordsResp, isError, error, status, searchDocumentReleaseHistory: postData, isLoading, ...rest }
}

const useDocumentHoldHistory = (props = {}) => {
  const [totalRecordsResp, settotalRecordsResp] = useState(0)
  const [allData, setAllData] = useState([])
  const { data, error, status, postData, isError, isLoading, ...rest } = useAxiosPost({
    ...props,
    url: 'documentManager/getDocumentHoldHistory',
    method: 'POST',
    payload: {}
  })

  useEffect(() => {
    if (data) {
      const { collection } = data;
      if (collection) {
        if (collection && collection.transaction) {
          const list = constructHistoryDocumentData(collection, 'Document');
          setAllData(JSON.parse(JSON.stringify(list)))
        } else {
          setAllData([])
        }
        if (collection && collection.$) {
          const { totalRecords } = collection.$;
          settotalRecordsResp(totalRecords)
        }
      }

    }
  }, [data, totalRecordsResp])
  return { allData, totalRecordsResp, isError, error, status, searchDocumentHoldHistory: postData, isLoading, ...rest }
}

const constructHistoryDocumentData = (collection, type) => {
  const list = []
  if (collection.transaction.constructor.name === 'Array') {
    collection.transaction.forEach(val => {
      if (val[type]) {
        if (val[type].constructor.name === 'Array') {
          val[type].forEach(doc => {
            list.push({
              ...collection.$,
              ...val.$,
              ...doc,
              deliveryAddress: val.deliveryAddress,
              deliveryType: val.deliveryType,
              displayShowLink: true
            })
          });
        } else {
          list.push({
            ...collection.$,
            ...val.$,
            ...val[type],
            deliveryAddress: val.deliveryAddress,
            deliveryType: val.deliveryType,
            displayShowLink: true
          })
        }
      }
    })
  } else if (collection.transaction.constructor.name === 'Object') {
    if (collection.transaction[type]) {
      if (collection.transaction[type].constructor.name === 'Array') {
        collection.transaction[type].forEach(val => {
          list.push({
            ...collection.$,
            ...collection.transaction.$,
            ...val,
            deliveryAddress: collection.transaction.deliveryAddress,
            deliveryType: collection.transaction.deliveryType,
            displayShowLink: true
          })
        })
      } else {
        list.push({
          ...collection.$,
          ...collection.transaction.$,
          ...collection.transaction[type],
          deliveryAddress: collection.transaction.deliveryAddress,
          deliveryType: collection.transaction.deliveryType,
          displayShowLink: true
        })
      }
    }
  }
  return list;
}

const constructHistoryGroupData = (collection) => {
  const list = []
  let arr = [];
  if (collection.transaction.constructor.name === 'Array') {
    arr = collection.transaction;
  } else if (collection.transaction.constructor.name === 'Object') {
    arr = [collection.transaction];
  }
  arr.forEach(val => {
    if (val.Group) {
      if (val.Group.constructor.name === 'Array') {
        val.Group.forEach(group => {
          if (group.name) {
            list.push({
              ...collection.$,
              ...val.$,
              deliveryAddress: val.deliveryAddress,
              deliveryType: val.deliveryType,
              filekey: group.name,
              status: ''
            });
          }
          if (group.files?.file.constructor.name === 'Array') {
            group.files.file.forEach((file) => {
              list.push({
                ...collection.$,
                ...val.$,
                deliveryAddress: val.deliveryAddress,
                deliveryType: val.deliveryType,
                ...file,
                displayShowLink: true
              })
            })
          } else {
            list.push({
              ...collection.$,
              ...val.$,
              deliveryAddress: val.deliveryAddress,
              deliveryType: val.deliveryType,
              ...group.files?.file,
              displayShowLink: true
            })
          }
        });
      } else {
        if (val?.Group?.name)
          list.push({
            ...collection.$,
            ...val?.$,
            deliveryAddress: val.deliveryAddress,
            deliveryType: val.deliveryType,
            filekey: val?.Group?.name,
            status: ''
          });
        if (val.Group?.files?.file.constructor.name === 'Array') {
          val.Group.files.file.forEach((file) => {
            list.push({
              ...collection.$,
              ...val.$,
              deliveryAddress: val.deliveryAddress,
              deliveryType: val.deliveryType,
              ...file,
              displayShowLink: true
            })
          })
        } else {
          list.push({
            ...collection.$,
            ...val.$,
            deliveryAddress: val.deliveryAddress,
            deliveryType: val.deliveryType,
            ...val.Group?.files?.file,
            displayShowLink: true
          })
        }
      }
    }
  })
  return list;
}

const constructAutoHistory = (collection) => {
  const list = [];
  let arr = [];
  if (collection.transaction.constructor.name === 'Array') {
    arr = collection.transaction;
  } else if (collection.transaction.constructor.name === 'Object') {
    arr = [collection.transaction];
  }
  arr.forEach(val => {
    if (val.Group) {
      if (val.Group.constructor.name === 'Array') {
        val.Group.forEach(group => {
          if (group.name)
            list.push({
              ...collection.$,
              ...val.$,
              filekey: group.name,
              status: ''
            });
          if (group.files?.file.constructor.name === 'Array') {
            group.files.file.forEach((file) => {
              list.push({
                ...collection.$,
                ...val.$,
                claimtype: file?.claimtype?.$?.id,
                doctypeid: file?.doctype?.$?.id,
                doctype: file?.doctype?._,
                recip: file?.recip?._,
                recipid: file?.recip?.$?.id,
                filekey: file?.filekey,
                holdInformation: group.holdInformation,
                displayShowLink: true
              })
            })
          } else {
            const { file } = group?.files;
            list.push({
              ...collection.$,
              ...val.$,
              claimtype: file?.claimtype?.$?.id,
              doctypeid: file?.doctype?.$?.id,
              doctype: file?.doctype?._,
              recip: file?.recip?._,
              recipid: file?.recip?.$?.id,
              filekey: file?.filekey,
              holdInformation: group.holdInformation,
              displayShowLink: true
            })
          }
        });
      } else {
        if (val?.Group?.name)
          list.push({
            ...collection.$,
            ...val?.$,
            filekey: val?.Group?.name,
            status: ''
          });
        if (val.Group?.files?.file.constructor.name === 'Array') {
          val.Group.files.file.forEach((file) => {
            list.push({
              ...collection.$,
              ...val.$,
              claimtype: file?.claimtype?.$?.id,
              doctypeid: file?.doctype?.$?.id,
              doctype: file?.doctype?._,
              recip: file?.recip?._,
              recipid: file?.recip?.$?.id,
              filekey: file?.filekey,
              holdInformation: val?.Group?.holdInformation,
              displayShowLink: true
            })
          })
        } else {
          const { file } = val.Group?.files
          list.push({
            ...collection.$,
            ...val.$,
            claimtype: file?.claimtype?.$?.id,
            doctypeid: file?.doctype?.$?.id,
            doctype: file?.doctype?._,
            recip: file?.recip?._,
            recipid: file?.recip?.$?.id,
            filekey: file?.filekey,
            holdInformation: val?.Group?.holdInformation,
            displayShowLink: true
          })
        }
      }
    }
  })
  return list;
}

const useOverrideFileCutoff = (props = {}) => {
  const [result, setResult] = useState(false);
  const { data, error, status, postData, isError, isLoading, ...rest } = useAxiosPost({
    ...props,
    url: 'fileCutoffManager/setOverride',
    method: 'POST',
    payload: {}
  })

  const { notify } = useNotificationBannerHook()

  useEffect(() => {
    if (data) {
      notify(`You have successfully overridden the cutoff time for the file(s)`, 'positive')
      setResult(true)
    }
  }, [data])

  return { result, isError, error, status, setOverride: postData, isLoading, ...rest }
}


const useGetEligibleFileKeysFileCutoff = (props = {}) => {
  const { data, error, status, postData, isError, isLoading, ...rest } = useAxiosPost({
    ...props,
    url: 'fileCutoffManager/getEligibleFileKeys',
    method: 'POST',
    payload: {}
  })

  return { data, isError, error, status, getEligibleFileKeys: postData, isLoading, ...rest }
}

export {
  useCurrentTime, useReleaseTime, useFormNumbers, useEmployerGroup,
  useEmployerGroupsLite, useWebGroupList, useSearchFiles,
  useSearchDocumentsWithDetail, useDocumentDetailSource, useSearchHoldGroups, useSubmitHoldGroups, useHoldFiles,
  useSearchReleaseFiles, useSearchHoldDocument, useSubmitHoldDocument, useSubmitReleaseFiles, usePreviewFile,
  useGetPdfMerged, useGetPdfFile, useSearchReleaseDocument, useSubmitReleaseDocument, useSearchReleaseGroup,
  useSubmitReleaseGroups, useErrorResolution, useGetEEOBEmail, useDiscriminationTypes,
  useSearchDocumentsQuickFull, useGetPdfFileQuickSearch, useSubmitPurgeDocument, useDownloadFile, useGetACHFileDetailsByKey, useGetDeliveryTypesEx,
  useGetProdRuleForRoute, useCheckDelTypeForRoute, useGetDelTypeDescForRoute, useChkClientCpyAddrForPoBx, usePurgeFiles, useSubmitRouteDocument,
  useGetAddress, useCheckAddressForPoBox, useGetDocumentDetailSourceDirect, useGetCountryListDirect,
  useSubmitPurgeGroups, useFilePurgeHistory, useFileRouteHistory, useFileReleaseHistory, useFileHoldHistory,
  useGroupPurgeHistory, useGroupRouteHistory, useGroupReleaseHistory, useGroupHoldHistory,
  useAutoHoldHistory, useCustomReleaseHistory, useDocumentPurgeHistory, useDocumentRouteHistory,
  useDocumentReleaseHistory, useDocumentHoldHistory, useOverrideFileCutoff, useGetEligibleFileKeysFileCutoff,
  useGetACHFileDetailsByFileID
}
