import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import find from 'lodash/find';
import { Portal } from 'react-portal';
import { Grid, NotificationBanner, LoadingIndicator, Button } from 'ui-core-ssgr';
import PanelHeaderContent from '../PanelHeaderContent';
import { detailProps } from '../../constants';
import { userPermissionConstants } from '../../../../../../shared/modules/constants';
import {
  DetailRulesGrid,
  DetailPanel,
  LoadMoreButton,
  InputLabelWrapper,
  LoadingWrapper,
} from './styles';
import CustomRow from '../../../CustomRow';
import {
  LoadingContainer,
  GridLoadingWrapper,
} from '../../../../../../shared/styles/styledComponents';

const AccordionPanel = props => {
  const {
    deleteNotification,
    ruleType,
    selectedRecord,
    setIsViewingGroupAssociationsModal,
    notifications,
    loading,
    updateSelectedRuleType,
    setSelectedRuleType,
    rcCollapsePanelProps,
    setIsViewingRuleDetails,
    setAdvancedGroupDetailsTab,
    selectedRuleTypeId,
    isExpanded,
    userRole
  } = props;
  const [selectedItemIds, setSelectedItemIds] = useState(new Set([]));
  const [initSelected, setInitSelected] = useState(new Set([]));
  const [disableButton] = useState(false);
  const [recordPerPageLabel] = useState(50);
  const [loadingState, setLoadingState] = useState(false);
  const [itemsCount, setItemsCount] = useState(50);

  /**
   * Here we are using this useEffect as the componentDidUpdate function and checking to see if an association or disassociation has been made
   * If the associateRuleName of any ruleType has changed then we are going to uncheck all of the boxes to clear the state
   * This is being done for the disassociate button to show or not show depending on which ruleType has had a rule associated to it
   */
  useEffect(() => {
    setSelectedItemIds(new Set([]));
    setInitSelected(new Set([]));
  }, [ruleType.associatedRuleName]);

  const loadMoreItems = () => {
    setLoadingState(true);
    const newCount = itemsCount + recordPerPageLabel;
    setItemsCount(newCount);
    setLoadingState(false);
  };

  const displayItems = items => {
    const itemsArray = [];
    const counter = items.length > itemsCount ? itemsCount : items.length;
    for (let i = 0; i < counter; i += 1) {
      itemsArray.push(items[i]);
    }
    return itemsArray;
  };

  const rules = get(ruleType, 'rules', []);

  const updateSelectedItemIds = ({ id }) => {
    let initSelectedCustom = new Set();
    const selectedItemIdsc = selectedItemIds;
    const selectedItemIds1 = selectedItemIds
      ? selectedItemIdsc
      : initSelectedCustom;
    let method = 'add';
    if (!selectedItemIds1 || !selectedItemIds1.has(id)) {
      initSelectedCustom[method](id);
    } else {
      method = 'delete';
      selectedItemIds1[method](id);
      initSelectedCustom = selectedItemIds1;
    }
    const { name } = ruleType;
    setInitSelected(initSelectedCustom);
    setSelectedItemIds(initSelectedCustom);
    updateSelectedRuleType(name, id, method);
  };

  const onSelectAllDetailItems = () => {
    const alreadyFull = selectedItemIds.size === rules.length;
    const ids = alreadyFull ? [] : rules.map(({ id }) => id);
    setSelectedItemIds(new Set(ids));
    const { name } = ruleType;
    let method = 'add';
    if (!ids.length) {
      method = 'delete';
      updateSelectedRuleType(name, null, method);
    } else {
      updateSelectedRuleType(name, ids[0], method);
    }
  };

  const selectRuleToView = (_, record) => {
    const { id } = record;
    const getRecordWithAssociations = find(ruleType.rules, rule => rule.id === id);

    if (getRecordWithAssociations && selectedRuleTypeId !== 'None') {
      setIsViewingRuleDetails({
        isAdvancedViewShown: true,
        selectedRuleRecord: getRecordWithAssociations,
        selectedRuleTypeId: ruleType.id,
      });
      setAdvancedGroupDetailsTab(1);
    }
  };

  const {
    userRoleEdit
  } = userPermissionConstants;

  const counter =
    rules && rules.length > itemsCount ? itemsCount : rules.length;
  const loadMore = !!(rules && rules.length > itemsCount);
  const totalCounterLabel = rules && rules.length;
  const difference =
    totalCounterLabel - counter < recordPerPageLabel
      ? totalCounterLabel - counter
      : recordPerPageLabel;
  const loadMoreLabel = `Load ${difference} More`;

  let permissionDisabled;
  if (userRole === userRoleEdit) {
    // for only edit user permission
    const hasEditPermission = get(ruleType, 'hasEditPermission', '');
    permissionDisabled = hasEditPermission === true ? rcCollapsePanelProps : { disabled: true, showArrow: false };
  } else {
    // for all other permissions
    permissionDisabled = rcCollapsePanelProps;
  }

  return (
    <DetailPanel
      {...props}
      {...permissionDisabled}
      header={
        <PanelHeaderContent
          ruleType={ruleType}
          selectedItemIds={selectedItemIds}
          selectedRecord={selectedRecord}
          associateHandleParent={setIsViewingGroupAssociationsModal}
          setIsViewingGroupAssociationsModal={setIsViewingGroupAssociationsModal}
          setSelectedRuleType={setSelectedRuleType}
          isExpanded={isExpanded}
        />
      }
      headerClass="detail-panel-header"
    >{
        !rcCollapsePanelProps.disabled && <>
          {notifications.size > 0 && (
            <Portal key="accordion-panel">
              {[...notifications].map(([key, value]) => (
                <NotificationBanner
                  key={[key]}
                  domID={`${key}-notification`}
                  type={value.type}
                  text={value.msg}
                  onDismiss={() => deleteNotification(key)}
                  autoDismiss
                  timer={1800000}
                  icon={value.type === 'neutral' ? 'Info' : null}
                />
              ))}
            </Portal>
          )}
          {isExpanded && (
            <DetailRulesGrid>
              {loading.has('ruleTypesGridLoading') ? (
                <LoadingContainer>
                  <GridLoadingWrapper>
                    <LoadingIndicator
                      domID="rule-types-grid-loading-indicator"
                      length="45px"
                    />
                  </GridLoadingWrapper>
                </LoadingContainer>
              ) : (
                  <>
                    <Grid
                      domID="detail-rule-grid-1"
                      {...detailProps}
                      records={displayItems(rules)}
                      rowComponent={CustomRow}
                      onClickThrough={selectRuleToView}
                      isConfigurable={false}
                      onSelectAll={onSelectAllDetailItems}
                      onRowSelect={updateSelectedItemIds}
                      initialSelectedItemIds={initSelected}
                      initialSortingKey="associated|SORT_DESCENDING"
                      selectedItemIds={selectedItemIds}
                    />
                    <LoadMoreButton>
                      <InputLabelWrapper>
                        {loadingState === false
                          ? `${counter}/${totalCounterLabel} rules loaded`
                          : null}
                      </InputLabelWrapper>

                      {(loadingState && loadMore) || disableButton ? (
                        <LoadingWrapper>
                          <LoadingIndicator
                            domID="group-load-more-loading-indicator"
                            length="45px"
                          />
                        </LoadingWrapper>
                      ) : (
                          loadMore && (
                            <Button
                              name={loadMoreLabel}
                              buttonType="deEmphasized"
                              onClick={loadMoreItems}
                              size="medium"
                              type="button"
                            />
                          )
                        )}
                    </LoadMoreButton>
                  </>
                )}
            </DetailRulesGrid>
          )}
        </>
      }

    </DetailPanel>

  );
};

AccordionPanel.propTypes = {
  ruleType: PropTypes.object,
  selectedRecord: PropTypes.object,
  setIsViewingRuleDetails: PropTypes.func,
  updateModalState: PropTypes.func,
  rulesForRuleTypesList: PropTypes.object,
  setIsViewingGroupAssociationsModal: PropTypes.func,
  postGroupAssociationsForRuleTypeTrigger: PropTypes.func,
  notifications: PropTypes.object,
  deleteNotification: PropTypes.func,
  setAdvancedGroupDetailsTab: PropTypes.func,
  isShowingGroupAssociationModal: PropTypes.bool,
  loading: PropTypes.object,
  updateSelectedRuleType: PropTypes.func,
  setSelectedRuleType: PropTypes.func,
  rcCollapsePanelProps: PropTypes.object,
  rulesWithAssociations: PropTypes.array,
  selectedRuleTypeId: PropTypes.string,
  userRole: PropTypes.string,
  isExpanded: PropTypes.bool
};

export default AccordionPanel;
