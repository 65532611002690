import { useDispatch } from "react-redux";
import { push, replace } from 'connected-react-router';
import axios from '../../axios';
import { onLogoutError, clearLogonDetails } from "../../containers/Login/modules/actions";
import useLocalStorage from "../useLocalStorage";
import envConfig from '../../config/env-config';

const { APP_ENV = 'development' } = window;


const useLogout = () => {
  const dispatch = useDispatch();
  const [token, setItem, removeItem] = useLocalStorage('user:token');

  const logout = async (args) => {
    localStorage.setItem("isHideResetPassword", false);
    const oidcKey = `oidc.user:${envConfig[APP_ENV].SENTINEL_URI}auth/realms/developer-platform:${envConfig[APP_ENV].API_CLIENT_ID}`;
    const oidcUser = JSON.parse(sessionStorage.getItem(oidcKey));
    const logoutUri = `${envConfig[APP_ENV].SENTINEL_LOGOUT}?id_token_hint=${oidcUser?.id_token}&post_logout_redirect_uri=${envConfig[APP_ENV].REDIRECT_URI}`;
    await axios.post('/auth/terminate').then(() => {
      removeItem('user:token');
      if (args === "timeout") {
        window.location.href = logoutUri;
        dispatch(replace({ state: args }));
      } else {
        window.location.href = logoutUri;
      }
      dispatch(clearLogonDetails());
    }).catch(err => {
      dispatch(onLogoutError(err));
    });
  };

  return logout;
};

export default useLogout;