import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const Authorizer = ({ permission, children }) => {
  const access = useSelector(store => store.context?.permissions?.filter(p => p.name === permission).length > 0)
  return access ? children : <></>
}

Authorizer.propTypes = {
  permission: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}

export default Authorizer;