import { useEffect, useRef, useState, useCallback } from 'react';
import { useAxios, useAxiosPost } from '../hooks';


const useAddClientContact=()=>{
  const {postData, isLoading,isError,error, metaData, data }=useAxiosPost({
    url:'clientAdministration/clientInformation/insertClientContactData',
    method:'POST',
    onError: (e) => 
      // what happens on error? if you need something extra
      // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  
    
  return { isLoading, isError, error, postData, metaData, data};
}
  
const useUpdateClientContact=()=>{
  const {postData, isLoading,isError,error,metaData, data }=useAxiosPost({
    url:'clientAdministration/clientInformation/updateClientContactData',
    method:'POST',
    onError: (e) => 
      // what happens on error? if you need something extra
      // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  
    
  return { isLoading, isError, error, postData, metaData, data};
}
  
const useDeleteClientContact=()=>{
  const {postData, metaData, isLoading,isError,error, data }=useAxiosPost({
    url:'clientAdministration/clientInformation/deleteClientContactData',
    method:'POST',
    onError: (e) => 
      // what happens on error? if you need something extra
      // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  
    
  return { isLoading, isError, error, postData, metaData, data};
}
  
export {
  useAddClientContact,
  useUpdateClientContact,
  useDeleteClientContact
}