/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
import React, { useMemo, useEffect, useState, useCallback } from 'react';
import styled from 'styled-components/dist/styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom'
import {
  Grid,
  useLayout,
  Modal, ModalHeader, ButtonGroup, ModalFooter, ModalBody,
  Button, LoadingIndicator
} from 'ui-core/dist'
import { LoadingPage, AnchorInput } from '../../../components';

import ErrorPage from '../../ErrorPage';
import {
  HOLD_DOCUMENT_SEARCH_RESULT_COLUMN,
  SELECTED_HOLD_PURGE_ROUTE_DOCUMENT_SEARCH_RESULT_COLUMN,
  GENERIC_TEXT_DOCUMENT_SEARCH_ROUTE1,
  GENERIC_TEXT_ROUTE,
  GENERIC_WARNING_TEXT_ROUTE,
  GENERIC_TEXT_DOCUMENT_SEARCH_ROUTE2,
  DOC_MANAGER_ROUTE_ORIGINAL_ADDRESS,
  DOC_MANAGER_ROUTE_INTENDED_ADDRESS_WARNING,
} from "../Modules/constants";
import withAxiosCancellation from '../../../components/withAxiosCancellation'
import { useSearchHoldDocument, useSubmitRouteDocument, usePreviewFile } from '../../../api/useDocumentManager';
import ListPaneHeaderWrapper from '../../../components/ErrorDisplaySection/ListPaneHeaderWrapper';
import {
  resetRecord,
  setRecord,
  setFileSearchParam, setFileSearchParamNull
} from '../Modules/action';
import GridPaginationWrapper from '../../../components/GridPaginationWrapper';
import { setDetailsId } from '../../ErrorManager/Modules/Action';

import useSelectableGrid from '../../../hooks/useSelectableGrid';
import '../Modules/styles.scss';
import useDocumentRouteDeliveryFilter from '../DocumentRouteDeliveryFilter/useDocumentRouteDeliveryFilter';
import DocumentRouteDeliveryFilterComp from '../DocumentRouteDeliveryFilter/DocumentRouteDeliveryFilterComp';
import ModalWrapper from '../../../components/Modal/components/ModalWrapper';
import PopoverBox from "../../../components/PopoverBox";
import useNotificationBannerHook from '../../../useNotificationBannerHook';

const StyledLoadingContainer = styled.div`
& > div {
  width: 35px !important;
  height: 35px !important;
  & > svg {
    width: 35px !important;
    height: 35px !important;
  }
}
`
const StyledModalWrapperWidth = styled(ModalWrapper)`
& .active-content {
  width: 65% !important;
  max-height: 600px !important;
  overflow: auto;
  & span {
    font-size: 11px !important;
  }
}
`

const StyleDivParent = styled.div`
  &.styled-parent-div {
    margin-top: 45px;
    border: 1px solid #cacccd;
    /* border-width: 1px; */
    /* border-color: #dbdbdb; */
    overflow-y: auto;
    height: 470px;
    border-radius: 5px;
    margin-left: 30px;
    text-align: left;
    /* -webkit-box-shadow: -1px 9px 18px 0px rgb(0 0 0 / 75%); */
    /* -moz-box-shadow: -1px 9px 18px 0px rgba(0,0,0,0.75); */
    /* box-shadow: 0px 0px 6px -1px rgb(0 0 0 / 75%); */
  }
  &.popBoxDiv {
    display: inline-block;

    & span {
      position:relative !important;

      & div.popover.bottom {
        border-radius: 5px;
        margin-top: 42px !important;
        margin-left: 27px !important;
      }
    }
  }
`;
const DSearchResultRouteMain = ({getCancelSourceWithName}) => {
  const {
    expandCollapseListPaneButtonConfig,
    openDetailPane,
    closeDetailPane
  } = useLayout()
  const dispatch = useDispatch()
  const history = useHistory();
  const { notify } = useNotificationBannerHook();
  const DEFAULT_DOWNLOAD_ERR_MESSAGE = "Could not download PDF at the moment. There is no PDF File for your request";
  const { allData, totalRecordsResp, isLoading, isError, error, searchDocument } = useSearchHoldDocument({getCancelSource:getCancelSourceWithName('searchRouteDocumentResult')});
  const [claimNumberDownloadTrack, setclaimNumberDownloadTrack] = useState({
    documentKey_claimNumber: null,
    filename: null
  })
  const { file, previewFile, refetchPreviewFile, isError: isErrorPreviewFile, error: errorPreviewFile, isLoading: isLoadingPreviewFile } = usePreviewFile(setclaimNumberDownloadTrack);

  const [count, setCount] = useState(0);
  const { fileSearchParam } = useSelector(({ documentSearch }) => documentSearch);
  const [changeItemPerPage, setchangeItemsPerPage] = useState(fileSearchParam.maxRecords)
  const [pageIndex, setPageIndex] = useState(0)
  const [disabledItemIds, setDisableItemIds] = useState([])
  // const [selectedItemIds, setSelectedItemIds] = useState([])
  const [isOpenConfirmedModal, setConfirmedModal] = useState(false)
  const [selectedRecord, setSelectedRecord] = useState('')

  const { onRowSelect, onClearAllSelection, selectedItemIdsCurrentPage, selectedItemIds, selectedRecords } = useSelectableGrid({ uniqueKey: 'documentKey_claimNumber', list: allData, isUicl: true })
  const form = useDocumentRouteDeliveryFilter(selectedRecords, notify);
  const canView = useSelector(({ context }) =>
    context?.permissions?.filter(p => p.name === 'CAN_PERFORM_DOC_PDF_VIEW').length > 0)
  const { transId, submitDocuments, isLoading: isTxnLoading } = useSubmitRouteDocument(form, 'documents');

  useEffect(() => {
    searchDocument(fileSearchParam)
  }, [])

  useEffect(() => {
    if (isOpenConfirmedModal) {
      if (form?.renderConfirmationModalUpperPanel) form.renderConfirmationModalUpperPanel();
    }
  }, [isOpenConfirmedModal])

  useEffect(() => {
    if (transId) {
      // searchDocument(JSON.parse(JSON.stringify({ ...fileSearchParam, count })));
      setConfirmedModal(false);
      onClearAllSelection()
      if (form && form.resetFields) form.resetFields()
      if (form && form.resetDocManagerOnOffAddress) form.resetDocManagerOnOffAddress()
      setCount(count + 1);
      history.push({ pathname: '/DocumentManager/Route/Document', state: `You have successfully routed the documents.  The transaction ID is ${transId}` })
    }
  }, [transId])

  useEffect(() => {
    if (allData && allData.length) {
      const disableItems = allData.filter(x => (x.canManage === 'false'))
        .map(y => y.documentKey_claimNumber)
      setDisableItemIds(disableItems)
    }
    // onClearAllSelection()
  }, [allData])

  useEffect(() => {
    if (isErrorPreviewFile) {
      notify(errorPreviewFile || DEFAULT_DOWNLOAD_ERR_MESSAGE, 'negative');
    }
  }, [isErrorPreviewFile])

  useEffect(() => {
    if (file && claimNumberDownloadTrack.filename) {
      downloadFile(file)
    }
  }, [file])

  const downloadFile = (f) => {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
      window.navigator.msSaveOrOpenBlob(f, claimNumberDownloadTrack.filename);
    } else {
      const FILE = window.URL.createObjectURL(f);
      const docUrl = document.createElement('a');
      docUrl.href = FILE;
      docUrl.setAttribute('download', claimNumberDownloadTrack.filename);
      document.body.appendChild(docUrl);
      docUrl.click();
      document.body.removeChild(docUrl);
    }
  }

  const onChangeItemsPerPage = (v) => {
    setchangeItemsPerPage(v)
  }

  const onChangePageIndex = (v) => {
    setPageIndex(v);
  }

  const fetchData = useCallback((args) => {
    let pi;
    if (args) {
      ({ pi } = args);
    }
    closeDetailPane();
    const payload = JSON.parse(JSON.stringify({
      ...fileSearchParam,
      "maxRecords": changeItemPerPage || fileSearchParam.maxRecords,
      "rowPosition": pi === undefined || pi === null ? pageIndex : pi,
     }))
    // onClearAllSelection()
    dispatch(setFileSearchParamNull(payload))
    dispatch(setFileSearchParam(payload))
    setSelectedRecord({})
    searchDocument(payload)

  }, [changeItemPerPage, pageIndex]);

  const onCloseConfirmedModal = () => {
    setConfirmedModal(false)
    return true;
  }
  const renderStatus = (status, documentKey_claimNumber) => {
    let className;
    switch (status) {
      case 'Imported':
        className = 'status-ok';
        break;
      case 'Released':
        className = 'status-ok';
        break;
      case 'Ready to print':
        className = 'status-ok';
        break;
      case 'Held':
        className = 'status-warn';
        break;
      case 'Held and Errored':
        className = 'status-error';
        break;
      case 'Errored':
        className = 'status-error';
        break;
      case 'Purged':
        className = 'status-error';
        break;
      default:
        break;
    }
    if (status === 'Errored' && documentKey_claimNumber) {
      return (<AnchorInput
        id='fileKey'
        type='button'
        style={{ color: 'red' }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (onShowMoreClick) {
            const record = allData.find(x => x.documentKey_claimNumber === documentKey_claimNumber);
            const i = allData.map(x => x.documentKey_claimNumber).indexOf(documentKey_claimNumber);
            dispatch(resetRecord())
            dispatch(setDetailsId(documentKey_claimNumber))
            return onShowMoreClick(record, i, 'errorDetails');
          }
        }}
      >{status}</AnchorInput>)
    }
    return (
      <div className={className}>
        <span>{status}</span>
      </div>
    )
  }

  const onShowMoreClick = useCallback((record, index, currentShowMoreTargetProps) => {
    dispatch(setRecord({ record, index, currentShowMoreTarget: currentShowMoreTargetProps }));
    setSelectedRecord(record)
    openDetailPane();
  }, [allData]);

  const onConfirm = () => {
    const docIds = Array.from(selectedItemIds).map(id => {
      return id.split('_')[0];
    });
    submitDocuments(docIds, count);
    setCount(count + 1);
  }

  const renderCheckedContent = useMemo(() => {
    const selectedRecordsArray = Array.from(selectedRecords);
    if (selectedRecordsArray.length) {
      const data = selectedRecordsArray.map((results) => {
        let claimNumber = '';
        if (results.claimNumbers && results.claimNumbers.claimNumber
          && results.claimNumbers.claimNumber.constructor.name === 'String') {
          claimNumber = results.claimNumbers.claimNumber
        } else if (results.claimNumbers && results.claimNumbers.claimNumber
          && results.claimNumbers.claimNumber.constructor.name === 'Array') {
          claimNumber = 'multiple'
        }
        return {
          ...results,
          statusComp: renderStatus(results.status),
          claimNumber
        }
      })

      return (
        <Grid
          {...SELECTED_HOLD_PURGE_ROUTE_DOCUMENT_SEARCH_RESULT_COLUMN}
          domID="DocumentSearchHoldConfirm"
          dataTestId="test-gridDefault"
          // isFixedHeader
          maxHeight="350px"
          records={data || []}
        />
      );
    }
    return '';
  }, [selectedRecords, isOpenConfirmedModal])

  /**
 * [form?.selectedDeliveryAddress,
  form?.selectedDeliveryType, form?.selectedReleaseItemsOption, form?.docManagerOneOffRouteAddressObject,
form?.hasdocManagerOneOffRouteAddressObject, form?.deliveryTypeSource, isOpenConfirmedModal]
 */

  const renderClaimNumberDisplay = (bPreviewLink, bRelatedKeys, claimNumber, documentKey_claimNumber, relKeys) => {
    if (claimNumber) {
      if (claimNumber.constructor.name === "Array" && claimNumber.length > 1) {
        if (bPreviewLink === 'True') {
          if (bRelatedKeys === 'True') {
            return (
              <StyledLoadingContainer>
                <>
                  {isLoadingPreviewFile && claimNumberDownloadTrack?.documentKey_claimNumber === documentKey_claimNumber ? <LoadingIndicator /> :
                    <AnchorInput
                      id='fileKey'
                      type='button'
                      className='fileKeyLink'
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (canView) {
                        if (documentKey_claimNumber !== claimNumberDownloadTrack.documentKey_claimNumber) {
                          previewFile({
                            payload: { documentKey_claimNumber, relKeys }, config: JSON.parse(JSON.stringify({
                              documentKey_claimNumber,
                              filename: 'printPreview.pdf'
                            }))
                          });
                        } else {
                          refetchPreviewFile()
                        }} else {
                          notify('You do not have required permission to perform this function','negative')
                        }
                      }}
                    >multiple</AnchorInput>
                  }
                </>
              </StyledLoadingContainer>
            )
          }
          return (
            <StyledLoadingContainer>
              <>
                {isLoadingPreviewFile && claimNumberDownloadTrack?.documentKey_claimNumber === documentKey_claimNumber ? <LoadingIndicator /> :
                  <AnchorInput
                    id='fileKey'
                    type='button'
                    className='fileKeyLink'
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (canView) {
                      if (documentKey_claimNumber !== claimNumberDownloadTrack.documentKey_claimNumber) {
                        previewFile({
                          payload: { documentKey_claimNumber, relKeys: "none" }, config: JSON.parse(JSON.stringify({
                            documentKey_claimNumber,
                            filename: 'printPreview.pdf'
                          }))
                        });
                      } else {
                        refetchPreviewFile()
                      }} else {
                        notify('You do not have required permission to perform this function','negative')
                      }
                    }}
                  >multiple</AnchorInput>
                }
              </>
            </StyledLoadingContainer>
          )
        }
        return <span>multiple</span>
      }
      if (bPreviewLink === 'True') {
        if (bRelatedKeys === 'True') {
          return (
            <StyledLoadingContainer>
              <>
                {isLoadingPreviewFile && claimNumberDownloadTrack?.documentKey_claimNumber === documentKey_claimNumber ? <LoadingIndicator /> :
                  <AnchorInput
                    id='fileKey'
                    type='button'
                    className='fileKeyLink'
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (canView) {
                      if (documentKey_claimNumber !== claimNumberDownloadTrack.documentKey_claimNumber) {
                        previewFile({
                          payload: { documentKey_claimNumber, relKeys }, config: JSON.parse(JSON.stringify({
                            documentKey_claimNumber,
                            filename: 'printPreview.pdf'
                          }))
                        });
                      } else {
                        refetchPreviewFile()
                      }} else {
                        notify('You do not have required permission to perform this function','negative')
                      }
                    }}
                  >{claimNumber.claimNumber}</AnchorInput>
                }
              </>
            </StyledLoadingContainer>
          )
        }
        return (
          <StyledLoadingContainer>
            <>
              {isLoadingPreviewFile && claimNumberDownloadTrack?.documentKey_claimNumber === documentKey_claimNumber ? <LoadingIndicator /> :
                <AnchorInput
                  id='fileKey'
                  type='button'
                  className='fileKeyLink'
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (canView) {
                    if (documentKey_claimNumber !== claimNumberDownloadTrack.documentKey_claimNumber) {
                      previewFile({
                        payload: { documentKey_claimNumber, relKeys: "none" }, config: JSON.parse(JSON.stringify({
                          documentKey_claimNumber,
                          filename: 'printPreview.pdf'
                        }))
                      });
                    } else {
                      refetchPreviewFile()
                    }} else {
                      notify('You do not have required permission to perform this function','negative')
                    }
                  }}
                >{claimNumber.claimNumber}</AnchorInput>
              }
            </>
          </StyledLoadingContainer>
        )
      }
      return <span>{claimNumber.claimNumber}</span>
    }
    return null;
  }

  const renderGridContent = useMemo(() => {
    if (allData.length) {
      // const temp=JSON.parse(JSON.stringify(errorResp));
      const data = allData.map(f => ({
        ...f,
        statusComp: renderStatus(f.status, f.documentKey_claimNumber),
        claimNumber: renderClaimNumberDisplay(
          f.$.bPreviewLink,
          f.$.bRelatedKeys,
          f.claimNumbers.claimNumber.constructor.name === 'Array' ? f.claimNumbers.claimNumber : f.claimNumbers,
          f.documentKey_claimNumber, f.$relKeys)
      }))

      return (
        <Grid
          {...HOLD_DOCUMENT_SEARCH_RESULT_COLUMN}
          domID="DocumentSearchHoldConfirm"
          dataTestId="test-gridDefault"
          records={data || []}
          selectionKey="documentKey_claimNumber"
          initialSelectedItemIds={new Set(selectedItemIdsCurrentPage)}
          initialDisabledItemIds={new Set(disabledItemIds)}
          onRowSelect={onRowSelect}
          onSelectAll={onRowSelect}
          supportSelection={data.length !== disabledItemIds.length}
          // focusedItem={selectedRecord}
          onClickThrough={(e, rec) => {
            e.preventDefault();
            e.stopPropagation();
            if (rec) {
              const record = data.find(x => x.documentKey_claimNumber === rec.documentKey_claimNumber);
              const i = data.map(x => x.documentKey_claimNumber).indexOf(rec.documentKey_claimNumber);
              if (onShowMoreClick) {
                dispatch(resetRecord())
                dispatch(setDetailsId(rec.documentKey_claimNumber))
                const source = record.source.constructor.name === 'Array' ? record.source[0] : record.source
                return onShowMoreClick({ ...record, source }, i, 'showMore');
              }
              return false;
            }
            return false;
          }}
        />
      );
    } else {
      return (
        <div className="displayFlex">
          <label style={{ color: 'red' }}>No records found. Change search criteria.</label>
        </div>
      )
    }
  }, [allData, disabledItemIds, selectedItemIds, selectedItemIdsCurrentPage, claimNumberDownloadTrack, selectedRecord]);

  const renderConfirmButton = useMemo(() => {
    let disabled = form && (form?.isLoadingValidation || (form?.validationMessage && form.validationMessage.toString() && !form.validationMessage.toString().toLowerCase().includes('warning')));
    if (selectedItemIds.size === 0) {
      disabled = true;
    }
    return (
      <Button
        buttonType="standard"
        size="small"
        name="CONFIRM SELECTED ITEMS"
        disabled={disabled}
        onClick={() => { setConfirmedModal(true) }}
      />
    );
  }, [selectedItemIds, form?.validationMessage, form?.isLoadingValidation])

  return (
    <>
      <ListPaneHeaderWrapper
        title="Document Manager: Route Documents"
        layoutControls={[expandCollapseListPaneButtonConfig]}

      />
      {isLoading ? (
        // replace with a better loading component
        <LoadingPage />
      ) : isError ? (
        // replace with a better error component
        <ErrorPage
          // this prop isn't real but is an example
          error={error}
        />
      ) : (
            <div className="mainWrapper">
              <div style={{ fontSize: "small", padding: "12px" }}>
                <p>{GENERIC_TEXT_DOCUMENT_SEARCH_ROUTE1}</p>
                <div style={{ color: "#bf1616" }}>
                  <p>
                    {GENERIC_TEXT_ROUTE}
                  </p>
                  <p>
                    {GENERIC_TEXT_DOCUMENT_SEARCH_ROUTE2}
                  </p>
                  <p>
                    {GENERIC_WARNING_TEXT_ROUTE}
                  </p>
                </div>
              </div>
              <div className="">
                <StyleDivParent className="popBoxDiv">
                  <PopoverBox
                    trigger="hover"
                    orientation="bottom"
                    buttonName="File Cutoff Time Applies"
                    buttonType="diminished"
                    domID="format_popup"
                    width="350px"

                  >
                    <div style={{
                      padding: "14px",
                      color: "#202083",
                      fontSize: "13px",
                      fontWeight: "bold",
                    }}>
                      File cutoff time is defined as the time in which a file must be fully imported, in order to be included in the 10:00AM release.

                </div>
                  </PopoverBox>
                </StyleDivParent>
              </div>

              {/* Delivery Address Filter Component */}
              <DocumentRouteDeliveryFilterComp form={form} />

              <div>
                <GridPaginationWrapper
                  rowsPerPage={changeItemPerPage}
                  onChangeRowsPerPage={(v) => onChangeItemsPerPage(v)}
                  pageIndex={pageIndex}
                  onChangePageIndex={(v) => onChangePageIndex(v)}
                  onShowButtonCLick={(args) => fetchData(args)}
                  totalRecords={totalRecordsResp}
                  defaultRowsPerPage={changeItemPerPage}
                  enableTextbox={false}
                />
                <div className='displayFlex'>
                  <Button
                    buttonType="standard"
                    size="small"
                    name="New Search"
                    onClick={() => { history.push(`/DocumentManager/Route/Document`) }}
                  />&nbsp;
              <Button
                    buttonType="standard"
                    size="small"
                    name="Clear all"
                    disabled={(selectedItemIds.size === 0)}
                    onClick={() => { onClearAllSelection() }}
                  />&nbsp;
              {renderConfirmButton}
                </div>
                <div className="displayFlex"></div>
                <div className='GridWrapper'>
                  {renderGridContent}
                </div>
              </div>
            </div>
          )}
      <Modal isOpen={isOpenConfirmedModal}>
        <ModalHeader
          title="Selected Documents"
          onClose={() => onCloseConfirmedModal()}
        />
        <ModalBody>
          <div style={{ paddingLeft: '30px', fontSize: '11px' }}>
            <span>Confirm your selections, and then click Confirm to Route the documents.</span>
            {form?.selectedDeliveryAddress === DOC_MANAGER_ROUTE_ORIGINAL_ADDRESS? <p style={{color:"red"}}>
                {DOC_MANAGER_ROUTE_INTENDED_ADDRESS_WARNING}
              </p>: null}
          </div>
          {isOpenConfirmedModal ? (
            <div>
              {form?.confirmationModalMarkup}
              <div className='GridWrapper'>
                <div className="modalOuterDiv" style={{
                  paddingLeft: '25px',
                  paddingRight: '25px',
                  marginTop: '20px',
                  marginBottom: '-10px',
                  overflow: 'scroll',
                  maxWidth: '800px',
                  maxHeight: '40vh'
                }}>
                  {renderCheckedContent}
                </div>
              </div>

            </div>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button
              buttonType="standard"
              domID="close"
              dataTestId="test-close"
              name="CLOSE"
              onClick={() => setConfirmedModal(false)}
              size="medium"
            />
            <Button
              buttonType="standard"
              domID="confirm"
              dataTestId="test-confirm"
              name="CONFIRM"
              disabled={(form && (form?.isLoadingValidation || (form?.validationMessage && form.validationMessage.toString() && !form.validationMessage.toString().toLowerCase().includes('warning')))) || isTxnLoading}
              onClick={() => onConfirm()}
              size="medium"
            />
            {
              isTxnLoading && <LoadingPage height="1vh" />
            }
          </ButtonGroup>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default withAxiosCancellation(DSearchResultRouteMain)
