import { useAxios } from '../hooks'

const useFormValues = (param) => {
  const { data, ...rest } = useAxios({
    url: `/forms/fields?param=${param}`,
  })

  return { 
    data,
    ...rest
  }
}

export default useFormValues