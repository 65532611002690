import React from 'react'
import { LoadingIndicator } from 'ui-core'
import './index.scss'

function Loading({ length, width }) {
  return (
    <div className="loading" style={{ width: width || '100%'}}>
      <LoadingIndicator
        className="loading__icon"
        domID="LoadingIndicator"
        length={length ? length : '50px'}
      />
    </div>
  )
}

export default Loading