import {
  GROUPS_POST_REQUEST_TRIGGER,
  GROUPS_POST_REQUEST_SUCCESS,
  GROUPS_POST_REQUEST_ERROR,
  GROUPS_PUT_REQUEST_TRIGGER,
  GROUPS_PUT_REQUEST_SUCCESS,
  GROUPS_PUT_REQUEST_ERROR,
  SET_IS_ACTIVATING_GROUP,
  SET_IS_DEACTIVATING_GROUP,
  SET_IS_CLONING_GROUP_STATE,
  SET_IS_SHOWING_CLONE_GROUP_MODAL,
  SET_IS_SHOWING_DEACTIVATE_GROUP_MODAL,
  SET_IS_SHOWING_ACTIVATE_GROUP_MODAL,
  RESET_GROUPS_STATE,
} from './constants';

// Initial state expresses the data type of this key in the store. This object
// is exported separately to make unit testing easier
export const INITIAL_STATE = {
  group: {},
  isCloningGroup: false,
  isShowingActivateGroupModal: false,
  isActivatingGroup: false,
  isDeactivatingGroup: false,
  showCloneGroupModal: false,
  isShowingDeactivateGroupModal: false,
  isMulti: false
};

// This is the actual "reducer". It is a function that will run every time an
// action is dispatched by the store, but only return updated state values if
// it matches the action's type in the switch statement.
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GROUPS_POST_REQUEST_TRIGGER:
      return {
        ...state,
      };
    case GROUPS_POST_REQUEST_SUCCESS:
      return {
        ...state,
        selectedDetailsRecordId: action.payload.groupId,
      };
    case GROUPS_POST_REQUEST_ERROR:
      // If an error occurred you can set a fallback state here
      if (window.location.origin === 'http://localhost:3000') {
        return {
          ...state,
        };
      }
      return {
        ...state,
      };
    case GROUPS_PUT_REQUEST_TRIGGER:
      return {
        ...state,
      };
    case GROUPS_PUT_REQUEST_SUCCESS:
      return {
        ...state,
        group: { ...action.payload.group },
      };
    case GROUPS_PUT_REQUEST_ERROR:
      // If an error occurred you can set a fallback state here
      if (window.location.origin === 'http://localhost:3000') {
        return {
          ...state,
        };
      }
      return {
        ...state,
      };
    case SET_IS_CLONING_GROUP_STATE:
      return {
        ...state,
        isCloningGroup: action.payload,
      };
    case SET_IS_SHOWING_CLONE_GROUP_MODAL:
      return {
        ...state,
        showCloneGroupModal: action.payload,
      };
    case SET_IS_SHOWING_DEACTIVATE_GROUP_MODAL:
      return {
        ...state,
        isShowingDeactivateGroupModal: action.payload.isShowingDeactivateGroupModal,
        isMulti: action.payload.isMulti,
        selectedGroupIds: action.payload.selectedGroupIds
      };
    case SET_IS_SHOWING_ACTIVATE_GROUP_MODAL:
      return {
        ...state,
        isShowingActivateGroupModal: action.payload,
      };
    case SET_IS_ACTIVATING_GROUP:
      return {
        ...state,
        isActivatingGroup: action.payload,
      };
    case SET_IS_DEACTIVATING_GROUP:
      return {
        ...state,
        isDeactivatingGroup: action.payload,
      };
    case RESET_GROUPS_STATE:
      return {
        group: {},
        isCloningGroup: false,
        isShowingActivateGroupModal: false,
        isActivatingGroup: false,
        isDeactivatingGroup: false,
        showCloneGroupModal: false,
        isShowingDeactivateGroupModal: false,
        isMulti: false
      }
    default:
      // default case is to return the existing state
      return state;
  }
};
