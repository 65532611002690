import {
  GROUPS_POST_REQUEST_TRIGGER,
  GROUPS_POST_REQUEST_SUCCESS,
  GROUPS_POST_REQUEST_ERROR,
  GROUPS_PUT_REQUEST_TRIGGER,
  GROUPS_PUT_REQUEST_SUCCESS,
  GROUPS_PUT_REQUEST_ERROR,
  SET_IS_ACTIVATING_GROUP,
  SET_IS_DEACTIVATING_GROUP,
  SET_IS_CLONING_GROUP_STATE,
  SET_IS_SHOWING_CLONE_GROUP_MODAL,
  SET_IS_SHOWING_DEACTIVATE_GROUP_MODAL,
  SET_IS_SHOWING_ACTIVATE_GROUP_MODAL,
  DEACTIVATE_GROUP_REQUEST_TRIGGER,
  ACTIVATE_GROUP_REQUEST_TRIGGER,
  RESET_GROUPS_STATE,
} from './constants';

// This is the action that the UI will dispatch to trigger our request loop
export const triggerGroupsPutRequest = (payload = {}) => ({
  type: GROUPS_PUT_REQUEST_TRIGGER,
  payload,
});

// This action is dispatched from the saga if our request succeeds. It is the
// action the reducer will respond in order to cache the response data
export const groupsPutSuccess = (payload = {}) => ({
  type: GROUPS_PUT_REQUEST_SUCCESS,
  payload,
  error: null,
});

// This action is used to signal a request error
export const groupsPutError = (error = {}) => ({
  type: GROUPS_PUT_REQUEST_ERROR,
  error,
});

export const setIsCloningGroupState = payload => ({
  type: SET_IS_CLONING_GROUP_STATE,
  payload,
});

// This action sets if the clone group modal is shown or not
export const setIsShowingCloneGroupModal = payload => ({
  type: SET_IS_SHOWING_CLONE_GROUP_MODAL,
  payload,
});

export const setIsActivatingGroup = payload => ({
  type: SET_IS_ACTIVATING_GROUP,
  payload,
});
export const setIsDeactivatingGroup = payload => ({
  type: SET_IS_DEACTIVATING_GROUP,
  payload,
});

export const triggerGroupsPostRequest = (payload = {}) => ({
  type: GROUPS_POST_REQUEST_TRIGGER,
  payload,
});

export const groupsPostSuccess = (payload = {}) => ({
  type: GROUPS_POST_REQUEST_SUCCESS,
  payload,
  error: null,
});

export const groupsCloneError = (error = {}) => ({
  type: GROUPS_POST_REQUEST_ERROR,
  error,
});

export const setIsShowingDeactivateGroupModal = payload => ({
  type: SET_IS_SHOWING_DEACTIVATE_GROUP_MODAL,
  payload,
});

export const setIsShowingActivateGroupModal = payload => ({
  type: SET_IS_SHOWING_ACTIVATE_GROUP_MODAL,
  payload,
});

export const triggerActivateGroupRequest = (payload = {}) => ({
  type: ACTIVATE_GROUP_REQUEST_TRIGGER,
  payload,
});

export const triggerDeactivateGroupRequest = (payload = {}) => ({
  type: DEACTIVATE_GROUP_REQUEST_TRIGGER,
  payload,
});

export const resetGroupsState = (payload = {}) => ({
  type: RESET_GROUPS_STATE,
  payload,
});
