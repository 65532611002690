import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Portal } from 'react-portal';
import { Button, LoadingIndicator, NotificationBanner } from 'ui-core-ssgr';
import {
  FormWrapper,
  RuleNameInputWrapper,
  RuleDescriptionInputWrapper,
  RuleNameInput,
  RuleDescriptionInput,
  InputLabelWrapper,
  CommitRuleButton,
  RuleTabFormButtonGroup,
  LoadingWrapper,
} from './styles';
import { gridActiveGroupConfig } from './constants';
import CommitRuleModal from '../CommitRuleModal';
import ActiveGroupsListingModal from '../../../RulesGridView/components/ActiveGroupsListingModal';
import {userPermissionConstants} from '../../../../shared/modules/constants';

const RulesTabForm = ({
  isPostingRulesForRuleTypes,
  selectedRuleRecord,
  isCloningRule,
  deleteNotification,
  isExpanded,
  notifications,
  goBackFromAdvancedToDefaultView,
  setIsPostingRulesForRuleTypes,
  toggleDetailsView,
  setIsCloningRuleState,
  triggerRulesForRuleTypesPutRequest,
  triggerRulesForRuleTypesPostRequest,
  triggerAllRulesForRuleTypesRequest,
  getRuleDetailElements,
  selectedRuleTypeId,
  count,
  loading,
  triggerCommitRuleForRuleType,
  selectedRecord,
  setIsShowingActiveGroupsListingModal,
  showActiveGroupsListingModal,
  selectedActiveRecordsList,
  selectedRecordName,
  activeGroupsModalLocation,
  testImageCount,
  userRole,
  userPermissions
}) => {
  const [inputState, setInputState] = useState({
    name: '',
    desc: ''
  });
  const [error, setError] = useState(new Map());
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isShowingCommitRuleModal, setIsShowingCommitRuleModal] = useState(false);

  useEffect(() => {
    const { id } = selectedRuleRecord;
    const newError = new Map();

    setInputState({
      desc: isPostingRulesForRuleTypes || isCloningRule
        ? ''
        : selectedRuleRecord.description,
      name: isPostingRulesForRuleTypes || isCloningRule
        ? ''
        : selectedRuleRecord.name
    });

    setError(isPostingRulesForRuleTypes || isCloningRule
      ? newError.set('name', 'Rule name is required.')
      : newError);

    if (!notifications.has(id))
      deleteNotification(id);

  }, []);

  useEffect(() => {
    resetComponentState();
  }, [selectedRuleRecord, isExpanded, isPostingRulesForRuleTypes, isCloningRule]);

  useEffect(() => {
    const { id } = selectedRuleRecord;

    if (!notifications.has(id))
      deleteNotification(id);

  }, [selectedRuleRecord.id]);

  const getIsButtonDisabledState = (input, value, selected, paramName, paramDesc) => {
    const newValue = value.toUpperCase();
    const { name, desc } = inputState;
    let buttonDisabled = true;

    if ((input === 'name' && !isPostingRulesForRuleTypes) || !isCloningRule)
      buttonDisabled =
        newValue === selected.name.toUpperCase() &&
        selected.description.toUpperCase() === paramDesc;
    if ((input === 'desc' && !isPostingRulesForRuleTypes) || !isCloningRule)
      buttonDisabled =
        newValue === selected.description.toUpperCase() &&
        selected.name.toUpperCase() === paramName;
    if (isPostingRulesForRuleTypes && newValue.length >= 3)
      buttonDisabled = false;
    if (isPostingRulesForRuleTypes && input === 'name' && newValue.length >= 3)
      buttonDisabled = false;
    if (input === 'desc' && newValue.length === 0) buttonDisabled = false;

    let nameCur = name;
    let descCur = value;
    if (input === 'name') {
      descCur = desc;
      nameCur = value;
    }
    if (
      selectedRuleRecord.name.toLowerCase() === nameCur.toLowerCase() &&
      descCur.toLowerCase() === selectedRuleRecord.description.toLowerCase()
    )
      buttonDisabled = true;

    return buttonDisabled;
  };

  // want test unless cloning (Edit Test Tab)
  const getRuleVersion = record => {
    try {
      const { status, version } = record;
      const testVersion = version[status.indexOf('T')];
      const liveVersion = version[status.indexOf('L')];
      if (!isCloningRule && testVersion) {
        return testVersion;
      }
      if (isCloningRule && liveVersion) {
        return liveVersion;
      }
      if (!isCloningRule && liveVersion) {
        return liveVersion;
      }
      if (isCloningRule && testVersion) {
        return testVersion;
      }
    } catch (e) {
      console.error('No Test/Live Version: ', e);
      return null;
    }
  };
  
  const onCancel = () => {
    const { id } = selectedRuleRecord;
  
    if (notifications.has(id)) deleteNotification(id);
    if (isPostingRulesForRuleTypes) setIsPostingRulesForRuleTypes(false);
    deleteNotification('postRule');
    setIsCloningRuleState(false);
    if (notifications.has(id)) deleteNotification(id);
    goBackFromAdvancedToDefaultView();
    toggleDetailsView(false);
  };
  
  const onInputChange = (e, input, selected) => {
    const { value } = e.target;
    const { name, desc } = inputState;
    const newValue = value.trim();
    const buttonDisabled = getIsButtonDisabledState(
      input,
      newValue,
      selected,
      name,
      desc,
    );
    setInputState(prevState => ({
      ...prevState,
      [input]: value
    }));

    setIsButtonDisabled(buttonDisabled);
    validateFormInputs(input, value);
  };
  
  const handleCellClick = (event, record) => {
    event.preventDefault();
    event.stopPropagation();
    setIsShowingActiveGroupsListingModal({
      showActiveGroupsListingModal: true,
      activeGroupsModalLocation: 'RulesTabForm',
      selectedActiveRecordsList: record,
      selectedRecordName: record.name,
    });
  };

  const onNotificationDismiss = id => () => {
    if (
      notifications.has(selectedRuleRecord.id) &&
        notifications.get(selectedRuleRecord.id).type === 'negative'
    ) {
      resetComponentState();
    }
    deleteNotification(id);
  };
  
  const onSave = () => {
    const { id } = selectedRuleRecord;
    const { name, desc } = inputState;
    const ruleVersionFrom = getRuleVersion(selectedRuleRecord);
    if (isCloningRule) {
      triggerRulesForRuleTypesPostRequest({
        name,
        desc,
        isCloningRule,
        ruleIdFrom: id,
        ruleVersionFrom,
        id: 'postRule',
      });
    } else if (isPostingRulesForRuleTypes) {
      triggerRulesForRuleTypesPostRequest({
        name,
        desc,
        id: 'postRule',
      });
      getRuleDetailElements({ selectedRuleTypeId });
    } else {
      triggerRulesForRuleTypesPutRequest({
        id,
        name,
        desc,
        selectedRuleRecord,
      });
    }
    setIsButtonDisabled(true);
  };
  
  const resetComponentState = () => {
    const newError = new Map();

    setInputState({
      desc: isPostingRulesForRuleTypes || isCloningRule
        ? ''
        : selectedRuleRecord.description,
      name: isPostingRulesForRuleTypes || isCloningRule
        ? ''
        : selectedRuleRecord.name
    });
    setError(isPostingRulesForRuleTypes || isCloningRule
      ? newError.set('name', 'Rule name is required.')
      : newError);
    setIsButtonDisabled(true);
  };
  
  const validateFormInputs = (input, value) => {
    const { length } = value;
    const regexp = /^[a-zA-Z0-9-_ ]*$/;
    const regexp2 = /^[a-zA-Z0-9]+$/;

    if (length < 3) error.set(input, 'Must enter a minimum of 3 characters.');
    else error.delete(input);
    if (input === 'name' || input === 'desc') {
      if (input === 'name') {
        if (length > 50) error.set(input, 'Must be 50 characters or less.');
        else error.delete(input);
      }
      if (input === 'desc') {
        if (length > 100) error.set(input, 'Must be 100 characters or less.');
      }
    }
    if (regexp.test(value)) {
      if (!regexp2.test(value.replace(/[_\-\s]/g, '')) && value.length >= 3) {
        error.set(input, 'Must contain at least one alphanumeric character.');
      }
    } else {
      error.set(
        input,
        'Must contain only alphanumerics, underscores, dashes and spaces.',
      );
    }
    if (length < 3) error.set(input, 'Must enter a minimum of 3 characters.');
    if (input === 'name' && length === 0)
      error.set(input, 'Rule name is required.');
    if (input === 'desc' && length === 0) error.delete(input);

    setError(error);
  };

  const commitRule = () => setIsShowingCommitRuleModal(true);

  const disabledCommitRuleButton = (selectedRuleRecord &&
    selectedRuleRecord.status.length !== 2 &&
    selectedRuleRecord.status[0] === 'L') ||
    isPostingRulesForRuleTypes ||
    isCloningRule ||
    count <= 0;

  const { name, desc } = inputState;
  const {userRoleEdit, CAN_PERFORM_GROUPRULES_RULETYPE_KEY_COMMIT_RULES} = userPermissionConstants;
  const CAN_PERFORM_GROUPRULES_RULETYPE_COMMIT_RULES = CAN_PERFORM_GROUPRULES_RULETYPE_KEY_COMMIT_RULES.replace('RULETYPE_KEY', selectedRuleTypeId);
  
  return (
    <FormWrapper>
      {notifications.size > 0 && (
        <Portal key="rule-tab-form">
          {[...notifications].map(([key, value]) => (
            <NotificationBanner
              key={[key]}
              domID={`${key}-notification`}
              type={value.type}
              text={value.msg}
              onDismiss={onNotificationDismiss(key)}
              autoDismiss
              timer={1800000}
              icon={value.type === 'neutral' ? 'Info' : null}
            />
          ))}
        </Portal>
      )}
      {userRole !== userRoleEdit && userPermissions.includes(CAN_PERFORM_GROUPRULES_RULETYPE_COMMIT_RULES) ?
        <CommitRuleButton
          name="Commit Rule"
          buttonType="emphasized"
          size="medium"
          onClick={commitRule}
          disabled={disabledCommitRuleButton}
        />
        : null}
      {isShowingCommitRuleModal && (
        <CommitRuleModal
          loading={loading}
          testImageCount={testImageCount}
          selectedRuleRecord={selectedRuleRecord}
          selectedRecord={selectedRecord}
          setIsShowingCommitRuleModal={setIsShowingCommitRuleModal}
          isShowingCommitRuleModal={isShowingCommitRuleModal}
          triggerCommitRuleForRuleType={triggerCommitRuleForRuleType}
          handleCellClick={handleCellClick}
        />
      )}
      {showActiveGroupsListingModal && activeGroupsModalLocation === 'RulesTabForm' && (
        <ActiveGroupsListingModal
          setIsShowingActiveGroupsListingModal={
            setIsShowingActiveGroupsListingModal
          }
          showActiveGroupsListingModal={showActiveGroupsListingModal}
          gridActiveGroupConfig={gridActiveGroupConfig}
          selectedActiveRecordsList={selectedActiveRecordsList}
          selectedRecordName={selectedRecordName}
        />
      )}
      <RuleNameInputWrapper>
        <InputLabelWrapper>RULE *</InputLabelWrapper>
        <RuleNameInput
          className="rule-name-input"
          disabled={false}
          isHovered={false}
          errorMessage={error.has('name') ? error.get('name') : ''}
          hasError={error.has('name')}
          initialValue={name}
          maxLength={51}
          onChange={e => onInputChange(e, 'name', selectedRuleRecord)}
        />
      </RuleNameInputWrapper>
      <RuleDescriptionInputWrapper>
        <InputLabelWrapper>RULE DESCRIPTION</InputLabelWrapper>
        <RuleDescriptionInput
          className="rule-description-input"
          disabled={false}
          isHovered={false}
          errorMessage={error.has('desc') ? error.get('desc') : ''}
          hasError={error.has('desc')}
          initialValue={desc}
          onChange={e => onInputChange(e, 'desc', selectedRuleRecord)}
        />
      </RuleDescriptionInputWrapper>
      <RuleTabFormButtonGroup>
        <span className="border-top"></span>
        <LoadingWrapper>
          {(loading.has(selectedRuleRecord.id) ||
              loading.has('postRule')) && (
            <LoadingIndicator
              domID="rules-tab-form-loading-indicator"
              length="30px"
            />
          )}
        </LoadingWrapper>
        <Button
          onClick={onSave}
          type="button"
          size="large"
          buttonType="emphasized"
          disabled={
            isButtonDisabled ||
                loading.has(selectedRuleRecord.id) ||
                error.size > 0 ||
                loading.has('postRule')
          }
          name="Save"
        />
        <Button
          onClick={onCancel}
          type="button"
          size="large"
          buttonType="diminished"
          disabled={
            loading.has(selectedRuleRecord.id) || loading.has('postRule')
          }
          name="Cancel"
        />
      </RuleTabFormButtonGroup>
    </FormWrapper>
  );
};

RulesTabForm.propTypes = {
  isExpanded: PropTypes.bool,
  triggerAllRulesForRuleTypesRequest: PropTypes.func,
  goBackFromAdvancedToDefaultView: PropTypes.func,
  setIsPostingRulesForRuleTypes: PropTypes.func,
  toggleDetailsView: PropTypes.func,
  count: PropTypes.number,
  deleteNotification: PropTypes.func.isRequired,
  isPostingRulesForRuleTypes: PropTypes.bool.isRequired,
  isCloningRule: PropTypes.bool.isRequired,
  selectedRuleRecord: PropTypes.shape({ id: PropTypes.string }),
  notifications: PropTypes.object.isRequired,
  loading: PropTypes.object.isRequired,
  triggerRulesForRuleTypesPutRequest: PropTypes.func.isRequired,
  triggerRulesForRuleTypesPostRequest: PropTypes.func.isRequired,
  triggerCommitRuleForRuleType: PropTypes.func,
  selectedRecord: PropTypes.object,
  getRuleDetailElements: PropTypes.func,
  selectedRuleTypeId: PropTypes.string,
  setIsCloningRuleState: PropTypes.func,
  setIsShowingActiveGroupsListingModal: PropTypes.func
};

export default RulesTabForm;
