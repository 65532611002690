import styled from 'styled-components';
import { Input } from 'ui-core-ssgr';

export const GroupNameInput = styled.div`
  width: 30%;
`;

export const GroupDescriptionInput = styled.div`
  width: 60%;
`;

export const StyledInput = styled(Input)`
  width: 11.2em;
`;

export const InputLabelWrapperWithIcon = styled.div`
  text-transform: uppercase;
  font-size: 11px;
  margin-bottom: 0;
  line-height: 1.4em;
  font-weight: 700;
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
  & svg {
    margin-left: 1em;
  }
`;

export const InputLabelWrapper = styled.div`
  text-transform: uppercase;
  font-size: 11px;
  margin-bottom: 0.3rem;
  line-height: 1.4em;
  font-weight: 700;
  letter-spacing: 0.5px;
  & svg {
    margin-left: 1em;
  }
`;

export const HierarchyInput = styled.div`
  width: 22em;
  display: inline-block;
  & div[class*='Wrapper-'] {
    margin: 0 1em 1em 0;
  }
`;

export const HierarchyInputInnerWrapper = styled.div`
  display: flex;
  margin-top: 1em;
`;

export const HierarchyInputNumber = styled.div`
  margin: 6px 6px 6px 0;
`;

export const LoadingWrapper = styled.div`
  float: left;
  margin-left: -30px;
  margin-top: 3px;
`;
