import React, { useState } from 'react';
import map from 'lodash/map';
import get from 'lodash/get';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
  Modal,
  Button,
  LoadingIndicator,
  Warning,
  Grid,
  GridCell,
} from 'ui-core-ssgr';
import ButtonGroup from '../../../../shared/components/ButtonGroup';
import { PaddingWrapper, DivWithPaddingTop } from '../../../../shared/styles/spacing';
import { gridCommitConfig, gridCommitConfigForImageRuleTypes } from './constants';
import Colors from '../../../../shared/styles/colors';
import { 
  ModalPaddingWrapper, 
  IconWrapper, 
  ModalTitlePaddingWrapper, 
  BoldTextAfterWarningIcon,
  StyledHeader,
} from '../../../../shared/styles/styledComponents';
import ClickableRow from '../../../RulesGridView/components/ClickableRow';
import { GridCellWrap } from '../../../CommitTabDetailsView/components/CommitDetailsGridWrapper/styles';
import {
  LoadingWrapper,
  ScrollableGridWrapper,
  InputLabel,
  ImageContentPaddingWrapper
} from './styles';

const CommitRuleModal = ({
  setIsShowingCommitRuleModal,
  selectedRuleRecord,
  triggerCommitRuleForRuleType,
  selectedRuleTypeId,
  isShowingCommitRuleModal,
  handleCellClick,
  testImageCount,
}) => {
  const [disableButton, setDisableButton] = useState(false);

  const selectedRuleRecordName = get(selectedRuleRecord, 'name', '');

  const getGridConfig = () => {
    const ruleType =
      selectedRuleTypeId ||
      (window.location.href.includes('ruletype')
        ? window.location.href
          .substring(window.location.href.lastIndexOf('=') + 1)
          .trim()
        : '');
    const imageUploadRuleTypes = ['RAD', 'IMG', 'SIG', 'CHT'];
    if (_.includes(imageUploadRuleTypes, ruleType))
      return gridCommitConfigForImageRuleTypes;
    return gridCommitConfig;
  };

  
  const onButtonClick = name => () => {
    switch (name) {
      case 'cancel':
        setIsShowingCommitRuleModal(false);
        setDisableButton(false);
        break;
      case 'confirm':
        triggerCommitRuleForRuleType({
          selectedRuleRecord,
          selectedRuleTypeId,
        });
        setDisableButton(true);
        break;
      default:
        break;
    }
  };

  const getRuleVersion = record => {
    try {
      const { status, version } = record;
      const liveVersion = version[status.indexOf('L')];
      return liveVersion;
    } catch (e) {
      // if no test/live version or any error, return null
    }
    return null;
  };

  const modalViewByStatus = () => {
    const theRuleVersion = getRuleVersion(selectedRuleRecord);
    let selectedRecordStatus = '';

    if (!selectedRuleRecord.trialed) {
      selectedRecordStatus = (
        <>
          <IconWrapper>
            <Warning fillColor={Colors.negativeDark} />
          </IconWrapper>
          <BoldTextAfterWarningIcon>
            {`You are about to commit one or more <rule/rule association> that has not yet been tested. Please select "CANCEL" to go back and test this change before you commit.`}
          </BoldTextAfterWarningIcon>
          {testImageCount ? ( 
            <ImageContentPaddingWrapper>
              {`Please note: This action will commit the rule(s) to production. This will NOT commit any associated images to production.
                   If you have image(s) that need to be committed with these rules please navigate to the image(s) on the rule details screen
                   after committing the rule(s) to production.`}
            </ImageContentPaddingWrapper>) : ''
          }
          <InputLabel>
            {
              'If you select "Confirm", it will commit the following selected rule to live.'
            }
          </InputLabel>
          <br/>
        </>
      );
    } else if (
      selectedRuleRecord.status.includes('T') &&
      selectedRuleRecord.status.includes('L')
    ) {
      selectedRecordStatus = (
        <InputLabel>
          <IconWrapper>
            <Warning
              fillColor="#B73239"
              className="test"
              title="hover for title"
            />
          </IconWrapper>
          {`A new live version of ${
            selectedRuleRecord.name
          } will replace the previous live version ${theRuleVersion}.`}
        </InputLabel>
      );
    } else {
      selectedRecordStatus = (
        <PaddingWrapper>
          <InputLabel>
            {`This will move ${selectedRuleRecord &&
              selectedRuleRecord.name} from test to live. ${selectedRuleRecord &&
              selectedRuleRecord.name} live version will be created.`}
          </InputLabel>
        </PaddingWrapper>
      );
    }

    return selectedRecordStatus;
  };

  const onModalToggle = () => {
    setIsShowingCommitRuleModal(false);
    setDisableButton(false);
  };

  const generateGridRecordsFromProps = gridRecords =>
    map(gridRecords, record => {
      const { id } = record;
      const activeGroup = get(record, 'activeGroup', []);
      return {
        ...record,
        activeGroupLength: activeGroup.length,
        testImageCount,
        activeGroupLabel:
          activeGroup !== 'None' &&
          typeof activeGroup !== 'string' &&
          activeGroup.length >= 1 ? (
              <GridCellWrap onClick={e => handleCellClick(e, record)}>
                <GridCell
                  cellType="link"
                  data={{
                    domID: id,
                    text: `${activeGroup.length}`,
                    href: '',
                  }}
                />
              </GridCellWrap>
            ) : (
              0
            ),
      };
    });

  return (
    <Modal
      domID="modal-id"
      onModalToggle={onModalToggle}
      isOpen={isShowingCommitRuleModal}
      showDefaultClose
      deferBodyControl={false}
      width="max-content"
    >
      <ModalPaddingWrapper>
        <ModalTitlePaddingWrapper>
          <StyledHeader>
            Commiting Rule: {selectedRuleRecordName}
          </StyledHeader>
        </ModalTitlePaddingWrapper>
        {modalViewByStatus()}
        <ScrollableGridWrapper>
          <Grid
            {...getGridConfig()}
            records={generateGridRecordsFromProps([selectedRuleRecord])}
            rowComponent={ClickableRow}
          />
        </ScrollableGridWrapper>
        <DivWithPaddingTop>
          <LoadingWrapper>
            {disableButton && (
              <LoadingIndicator
                domID="commit-rule-loading-indicator"
                length="30px"
              />
            )}
          </LoadingWrapper>
          <ButtonGroup>
            <Button
              name="confirm"
              buttonType="emphasized"
              onClick={onButtonClick('confirm')}
              size="medium"
              type="button"
              disabled={disableButton}
            />
            <Button
              name="cancel"
              buttonType="diminished"
              onClick={onButtonClick('cancel')}
              size="medium"
              type="button"
              disabled={disableButton}
            />
          </ButtonGroup>
        </DivWithPaddingTop>
      </ModalPaddingWrapper>
    </Modal>
  );
};

CommitRuleModal.propTypes = {
  selectedRuleRecord: PropTypes.object,
  isShowingCommitRuleModal: PropTypes.bool,
  setIsShowingCommitRuleModal: PropTypes.func,
  triggerCommitRuleForRuleType: PropTypes.func,
  selectedRuleTypeId: PropTypes.string,
  selectedRecord: PropTypes.object,
  handleCellClick: PropTypes.func,
  testImageCount: PropTypes.number,
};

export default CommitRuleModal;
