import React from 'react';

export const ErrorPage = (err) => {
    const renderErrors=()=>{
        if (err?.error?.displayMessage) {
            let message;
            if (err?.error?.displayMessage.constructor.name==="String") {
                message=err?.error?.displayMessage
            } else if(err?.error?.displayMessage.constructor.name==="Object" && err?.error?.displayMessage?.message){
                ({message}=err?.error?.displayMessage)
            }
            if (message?.indexOf('connect ECONNREFUSED')!==-1
        || message?.indexOf('ENOTFOUND')!==-1){
            return <div><br/>
                You may be experiencing intermittent access to the Client Access System. The website should be fully accessible shortly.
                <br />If it is before 10:00 am (cst) and you are attempting to manage documents, please contact a Client Analyst at (800) 804-7430 and they can assist
                <br/> with this transaction(s).
            </div>
        }
        if (message?.indexOf('connect ETIMEDOUT')!==-1){
            return <div><br/>
                You may be experiencing intermittent access to the Client Access System. The website should be fully accessible shortly.
            </div>
        }
        }
        return <ul>
        <li>
            {err?.error?.displayMessage || err?.error?.fs || err?.error?.error}
        </li>
    </ul>
    }
    return <>
        <div style={{ fontSize: '13px', color: 'red', marginLeft: '45px', marginTop: '-15px' }}>
            <h3>There were errors processing your request:</h3></div>
        {
            err && <div style={{ fontSize: '13px', color: 'red', marginLeft: '50px' }}>
                {renderErrors()}
            </div>}
    </>
}

export default ErrorPage;
