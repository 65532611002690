import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import { DetailHeader, Button } from 'ui-core-ssgr';
import { PaddingWrapper } from '../../../../../../shared/styles/spacing';
import { RightSideButtons, DetailHeaderWrapper } from './styles';
import DeleteRuleDetailModal from '../../../../../RuleDetailModals/DeleteRuleDetailModal';

import {
  CLONE_BUTTONS,
  CLONE_DELETE_BUTTONS,
  DELETE_BUTTON,
  headerProps,
} from './constants';

const AdvancedGridHeader = ({
  setIsCloningRuleDetail,
  setIsShowingProductionArrangementModal,
  setIsShowingCustomerServiceMessageModal,
  setIsShowingReturnAddressModal,
  setIsShowingDuplexRuleModal,
  setIsShowingSignatureRuleModal,
  setIsShowingCheckTextRuleModal,
  setIsShowingImageRuleModal,
  setIsShowingAutoHoldModal,
  setIsShowingMemberIdModal,
  setIsPostingRuleDetail,
  selectedRuleTypeId,
  setIsShowingDeleteRuleDetailModal,
  setIsShowingTaxIdArrangementRuleModal,
  setIsShowingErisaMessageModal,
  setShowingHighDollarReviewModal,
  setIsShowingProviderInsuredOptionMessageModal,
  setIsShowingGeneralMessageModal,
  setIsShowingPDFTaggingModal,
  detailElements,
  addNotification,
  errorNotifications,
  getAddNewAddressElements,
  recordCount,
  headerName,
  selectedItemIds,
  isShowingDeleteRuleDetailModal,
  selectedRecord,
  deleteRuleDetailTrigger,
  records,
  loading,
  isCloningRule
}) => {
  const onButtonClick = name => {
    if (name === 'delete') {
      setIsShowingDeleteRuleDetailModal(true);
    } else if (!isEmpty(detailElements)) {
      if (name === 'edit' || name === 'add' || name === 'clone') {
        if (
          (name === 'add' || name === 'clone') &&
            selectedRuleTypeId === 'PAR'
        ) {
          setIsShowingProductionArrangementModal(true);
          if (name === 'add') {
            setIsPostingRuleDetail(true);
          } else {
            setIsCloningRuleDetail(true);
          }
        }
        if (
          (name === 'add' || name === 'clone') &&
            selectedRuleTypeId === 'CSM'
        ) {
          setIsShowingCustomerServiceMessageModal(true);
          if (name === 'add') {
            setIsPostingRuleDetail(true);
          } else {
            setIsCloningRuleDetail(true);
          }
        }
        if (
          selectedRuleTypeId === 'RAD' &&
            (name === 'edit' || name === 'add' || name === 'clone')
        ) {
          if (name === 'add') {
            setIsPostingRuleDetail(true);
          } else {
            setIsCloningRuleDetail(true);
          }
          getAddNewAddressElements({selectedRuleTypeId});
          setIsShowingReturnAddressModal(true);
        }
        if (
          selectedRuleTypeId === 'DPX' &&
            (name === 'edit' || name === 'add' || name === 'clone')
        ) {
          if (name === 'add') {
            setIsPostingRuleDetail(true);
          } else {
            setIsCloningRuleDetail(true);
          }
          setIsShowingDuplexRuleModal(true);
        }
        if (
          selectedRuleTypeId === 'SIG' &&
            (name === 'edit' || name === 'add' || name === 'clone')
        ) {
          if (name === 'add') {
            setIsPostingRuleDetail(true);
          } else {
            setIsCloningRuleDetail(true);
          }
          setIsShowingSignatureRuleModal(true);
        }
        if (
          selectedRuleTypeId === 'CHT' &&
            (name === 'edit' || name === 'add' || name === 'clone')
        ) {
          if (name === 'add') {
            setIsPostingRuleDetail(true);
          } else {
            setIsCloningRuleDetail(true);
          }
          setIsShowingCheckTextRuleModal(true);
        }

        if (
          selectedRuleTypeId === 'IMG' &&
            (name === 'edit' || name === 'add' || name === 'clone')
        ) {
          if (name === 'add') {
            setIsPostingRuleDetail(true);
          } else {
            setIsCloningRuleDetail(true);
          }
          setIsShowingImageRuleModal(true);
        }

        if (
          selectedRuleTypeId === 'HLD' &&
            (name === 'edit' || name === 'add' || name === 'clone')
        ) {
          if (name === 'add') {
            setIsPostingRuleDetail(true);
          } else {
            setIsCloningRuleDetail(true);
          }
          setIsShowingAutoHoldModal(true);
        }
        if (
          selectedRuleTypeId === 'SSN' &&
            (name === 'edit' || name === 'add' || name === 'clone')
        ) {
          if (name === 'add') {
            setIsPostingRuleDetail(true);
          } else {
            setIsCloningRuleDetail(true);
          }
          setIsShowingMemberIdModal(true);
        }
        if (
          selectedRuleTypeId === 'TAX' &&
            (name === 'edit' || name === 'add' || name === 'clone')
        ) {
          if (name === 'add') {
            setIsPostingRuleDetail(true);
          } else {
            setIsCloningRuleDetail(true);
          }
          setIsShowingTaxIdArrangementRuleModal(true);
        }
        if (
          selectedRuleTypeId === 'ERI' &&
            (name === 'edit' || name === 'add' || name === 'clone')
        ) {
          if (name === 'add') {
            setIsPostingRuleDetail(true);
          } else {
            setIsCloningRuleDetail(true);
          }
          setIsShowingErisaMessageModal(true);
        }
        if (
          selectedRuleTypeId === 'PDT' &&
            (name === 'edit' || name === 'add' || name === 'clone')
        ) {
          if (name === 'add') {
            setIsPostingRuleDetail(true);
          } else {
            setIsCloningRuleDetail(true);
          }
          setIsShowingPDFTaggingModal(true);
        }
        if (
          selectedRuleTypeId === 'HDR' &&
            (name === 'edit' || name === 'add' || name === 'clone')
        ) {
          if (name === 'add') {
            setIsPostingRuleDetail(true);
          } else {
            setIsCloningRuleDetail(true);
          }
          setShowingHighDollarReviewModal(true);
        }
        if (
          selectedRuleTypeId === 'PIO' &&
            (name === 'edit' || name === 'add' || name === 'clone')
        ) {
          if (name === 'add') {
            setIsPostingRuleDetail(true);
          } else {
            setIsCloningRuleDetail(true);
          }
          setIsShowingProviderInsuredOptionMessageModal(true);
        }
        if (
          selectedRuleTypeId === 'GEN' &&
            (name === 'edit' || name === 'add' || name === 'clone')
        ) {
          if (name === 'add') {
            setIsPostingRuleDetail(true);
          } else {
            setIsCloningRuleDetail(true);
          }
          setIsShowingGeneralMessageModal(true);
        }
      }
    } else if (errorNotifications) {
      errorNotifications.forEach(element => {
        if (element.type === 'ruleDetailElements') {
          addNotification({
            id: selectedRuleTypeId,
            type: 'negative',
            msg: element.msg
          });
        }
      });
    }
  };

  let additionalButtons = [];

  if (selectedItemIds.size === 1 && recordCount === 1) {
    additionalButtons = CLONE_BUTTONS;
  }
  if (selectedItemIds.size === 1 && recordCount > 1) {
    additionalButtons = CLONE_DELETE_BUTTONS;
  } else if (
    selectedItemIds.size > 1 &&
    selectedItemIds.size !== recordCount
  ) {
    additionalButtons = DELETE_BUTTON;
  }

  return (
    <PaddingWrapper>
      {isShowingDeleteRuleDetailModal && (
        <DeleteRuleDetailModal
          selectedItemIds={selectedItemIds}
          setIsShowingDeleteRuleDetailModal={
            setIsShowingDeleteRuleDetailModal
          }
          deleteRuleDetailTrigger={deleteRuleDetailTrigger}
          selectedRecord={selectedRecord}
          records={records}
          loading={loading.size !== 0}
          selectedRuleTypeId={selectedRuleTypeId}
        />
      )}
      <DetailHeaderWrapper>
        <DetailHeader
          {...headerProps}
          title={headerName}
          amountKey="amount"
          domID="header"
          count={!loading.has('ruleDetailsGridLoading') ? recordCount : 0}
          marginBottom={12}
        />
      </DetailHeaderWrapper>
      {!isCloningRule && (
        <RightSideButtons>
        
          <Button
            name="add a detail"
            buttonType="deEmphasized"
            onClick={() => onButtonClick('add')}
            disabled={isEmpty(detailElements)}
            size="medium"
            type="button"
          />
          {map(additionalButtons, name => (
            <Button
              name={name}
              key={name}
              buttonType="deEmphasized"
              onClick={() => onButtonClick(name)}
              size="medium"
              type="button"
            />
          ))}
      
        </RightSideButtons>
      )}
    </PaddingWrapper>
  );
};

AdvancedGridHeader.defaultProps = {
  recordCount: 0,
};

AdvancedGridHeader.propTypes = {
  selectedItemIds: PropTypes.object,
  recordCount: PropTypes.number,
  headerName: PropTypes.string,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  setIsCloningRuleDetail: PropTypes.func,
  setIsShowingProductionArrangementModal: PropTypes.func,
  setIsShowingCustomerServiceMessageModal: PropTypes.func,
  setIsShowingReturnAddressModal: PropTypes.func,
  setIsShowingDuplexRuleModal: PropTypes.func,
  setIsShowingSignatureRuleModal: PropTypes.func,
  setIsShowingCheckTextRuleModal: PropTypes.func,
  setIsShowingImageRuleModal: PropTypes.func,
  setIsShowingAutoHoldModal: PropTypes.func,
  setIsShowingMemberIdModal: PropTypes.func,
  setIsPostingRuleDetail: PropTypes.func,
  selectedRuleTypeId: PropTypes.string,
  setIsShowingDeleteRuleDetailModal: PropTypes.func,
  setIsShowingTaxIdArrangementRuleModal: PropTypes.func,
  setIsShowingErisaMessageModal: PropTypes.func,
  setShowingHighDollarReviewModal: PropTypes.func,
  setIsShowingProviderInsuredOptionMessageModal: PropTypes.func,
  setIsShowingGeneralMessageModal: PropTypes.func,
  setIsShowingPDFTaggingModal: PropTypes.func,
  detailElements: PropTypes.object,
  addNotification: PropTypes.func,
  errorNotifications: PropTypes.object,
  getAddNewAddressElements: PropTypes.func,
  isShowingDeleteRuleDetailModal: PropTypes.bool,
  selectedRecord: PropTypes.object,
  deleteRuleDetailTrigger: PropTypes.func,
  records: PropTypes.array,
  loading: PropTypes.object,
  isCloningRule: PropTypes.bool
};

export default AdvancedGridHeader;
