import styled from 'styled-components';
import { DetailHeader } from 'ui-core-ssgr';
import { Input } from 'ui-core-ssgr';

export const ContainerBox = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
`;
export const AddReturnAddressHeader = styled(DetailHeader)`
  & h1 {
    margin-bottom: 4px;
  }
  & span#test-id-description {
    color: #37474f;
  }
`;

export const Title = styled.div`
  & > span.infoHeader1,
  & > span.infoHeader2,
  & > span.infoHeader3,
  & > span.infoHeader4,
  & > span.infoHeader5 {
    margin-top: 1em;
    line-height: 1em;
    font-size: 14px;
  }
`;
export const ButtonContainerWrap = styled.div`
  & > span > div {
    margin-top: 20px;
  }
`;
export const ReturnAddressLogoImageWrapper = styled.div`
  width: 200px;
  margin-right: 285px;
  margin-top: 10px;
  & > button {
    padding-left: 5px;
  }
  & > div > div.entity-title-wrapper {
    padding: 2px 0px 0px 0px;
  }
  & > div > button#library-btn, & > div > button#upload-btn {
    font-size:11px;
  }
  & > div > button#library-btn {
    padding-left:0px;
  }
  & > div > div.image-library-none {
    padding:2px;
    font-size:11px;
  }
  & > div > div.entity-title-wrapper > span.entity-title {
    text-transform: uppercase;
    font-size: 11px;
  }
`;
export const HeaderTitle = styled.div`
text-align:center;
margin: 0 auto;
width: 65%;
`;

export const ImageFilter = styled(Input)`
  margin-left: 870px;
  input {
    padding-right: 1.25em;
    min-width: 240px;
  }
  & > button {
    height: 32px;
    top: 0;
  }
`;

export const SearchButtonImage = styled.img`
  height: 1.25em;
`;

export const RuleTypesDetailRow = styled.div`
  display: flex;
  justify-content: flex-start;
`;
