import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import map from 'lodash/map';
import { Grid, GridCell } from 'ui-core-ssgr';
import ClickableRow from '../ClickableRow';
import { gridConfig, gridActiveGroupConfig } from './constants';
import CommitTestAssociationsModal from '../CommitTestAssociationsModal';
import { GridCellWrap } from './styles';
import ActiveGroupsListingModal from '../../../RulesGridView/components/ActiveGroupsListingModal';

const CommitDetailsGridWrapper = props => {
  const {
    records,
    isShowingCommitMultipleTestAssocRuleModalOpen,
    triggerCommitforMultipleTestAssocRules,
    commitMultipleTestAssocRuleModalOpen,
    commitMultipleTestAssocRulesError,
    commitMultipleTestAssocRulesSuccess,
    loading,
    setDefaultGroupDetailsTab,
    selectedRecord,
    showActiveGroupsListingModal,
    setIsShowingActiveGroupsListingModal,
    toggleDetailsView,
    shouldDisableButton,
    activeGroupsModalLocation,
    selectedActiveRecordsList,
    selectedRecordName,
    isEditRole
  } = props;

  // This is both the seed of the total selectedItemIds as well as an important discreet value for disabling these rows in the grid
  const initialSelectedItemIds = new Set(
    records
      .filter(({ autoCommit }) => autoCommit)
      .map(({ ruleType }) => ruleType),
  );
  const [selectedItemIds, setSelectedItemIds] = useState(
    initialSelectedItemIds,
  );

  // using a map below because the values will be used as a list but should be unique by type (keys)
  const [selectedRuleOnModal, setSelectedRuleOnModal] = useState(new Map());
  useEffect(() => shouldDisableButton(selectedItemIds.size <= 0), []);

  const handleCellClick = (event, record) => {
    const id = get(event, 'target.id', '');
    if (id) {
      event.preventDefault();
      event.stopPropagation();
      setIsShowingActiveGroupsListingModal({
        showActiveGroupsListingModal: true,
        activeGroupsModalLocation: 'CommitDetailsGridWrapper',
        selectedActiveRecordsList: record,
        selectedRecordName: record.associatedTestRule,
      });
    }
  };

  const generateGridRecordsFromProps = gridRecords =>
    map(gridRecords, record => {
      const activeGroupLength = record.activeGroup.length;
      const completed = {
        bad: record.completed,
        nonCompliant: record.completed,
      };
      const hasManyActiveGroups =
        Array.isArray(record.activeGroup) && activeGroupLength >= 1;
      const activeGroupLabel = hasManyActiveGroups ? (
        <GridCellWrap onClick={e => handleCellClick(e, record)}>
          <GridCell
            cellType="link"
            data={{
              domID: record.ruleTypeName,
              text: `${activeGroupLength}`,
              href: '',
            }}
          />
        </GridCellWrap>
      ) : (
        0
      );

      return {
        ...record,
        completed,
        activeGroupLength,
        activeGroupLabel,
      };
    });

  const onGridSelectAll = () => {
    const rules = new Map();
    const alreadyFull = selectedItemIds.size === records.length;
    records.forEach(r => {
      if (!alreadyFull) {
        rules.set(r.ruleType, r);
        selectedItemIds.add(r.ruleType);
      } else if (!initialSelectedItemIds.has(r.ruleType)) {
        rules.delete(r.ruleType);
        selectedItemIds.delete(r.ruleType);
      }
    });
    shouldDisableButton(selectedItemIds.size === 0);
    setSelectedRuleOnModal(rules);
    setSelectedItemIds(selectedItemIds);
  };

  const onRowSelect = record => {
    if (!selectedRuleOnModal.has(record.ruleType)) {
      selectedRuleOnModal.set(record.ruleType, record);
    } else {
      selectedRuleOnModal.delete(record.ruleType);
    }
    const isSelectedItemIds = !selectedItemIds.has(record.ruleType);
    const methodForRule = isSelectedItemIds ? 'add' : 'delete';
    selectedItemIds[methodForRule](record.ruleType);
    setSelectedItemIds(selectedItemIds);
    setSelectedRuleOnModal(selectedRuleOnModal);
    shouldDisableButton(selectedItemIds.size <= 0);
  };

  return (
    <div>
      {commitMultipleTestAssocRuleModalOpen && (
        <CommitTestAssociationsModal
          {...props}
          selectedRuleOnModal={selectedRuleOnModal}
          selectedItemIds={selectedItemIds}
          records={generateGridRecordsFromProps(
            records.filter(({ ruleType }) => selectedItemIds.has(ruleType)),
          )}
          isShowingCommitMultipleTestAssocRuleModalOpen={
            isShowingCommitMultipleTestAssocRuleModalOpen
          }
          triggerCommitforMultipleTestAssocRules={
            triggerCommitforMultipleTestAssocRules
          }
          commitMultipleTestAssocRuleModalOpen={
            commitMultipleTestAssocRuleModalOpen
          }
          commitMultipleTestAssocRulesSuccess={
            commitMultipleTestAssocRulesSuccess
          }
          commitMultipleTestAssocRulesError={commitMultipleTestAssocRulesError}
          loading={loading}
          setDefaultGroupDetailsTab={setDefaultGroupDetailsTab}
          selectedRecord={selectedRecord}
        />
      )}
      {showActiveGroupsListingModal && activeGroupsModalLocation === 'CommitDetailsGridWrapper' && (
        <ActiveGroupsListingModal
          setIsShowingActiveGroupsListingModal={
            setIsShowingActiveGroupsListingModal
          }
          showActiveGroupsListingModal={showActiveGroupsListingModal}
          gridActiveGroupConfig={gridActiveGroupConfig}
          selectedActiveRecordsList={selectedActiveRecordsList}
          selectedRecordName={selectedRecordName}
        />
      )}
      <Grid
        {...gridConfig(isEditRole === false)}
        onGridSelectAll={onGridSelectAll}
        selectedItemIds={selectedItemIds}
        records={generateGridRecordsFromProps(records)}
        initialDisabledItemIds={initialSelectedItemIds}
        initialSelectedItemIds={initialSelectedItemIds}
        rowComponent={ClickableRow}
        onClickThrough={() => toggleDetailsView(true)}
        onRowSelect={onRowSelect}
        onSelectAll={onGridSelectAll}
      />
    </div>
  );
};
CommitDetailsGridWrapper.propTypes = {
  records: PropTypes.array,
  commitRuleTypesList: PropTypes.object,
  shouldDisableButton: PropTypes.func.isRequired,
  triggerCommitforMultipleTestAssocRules: PropTypes.func,
  commitMultipleTestAssocRuleModalOpen: PropTypes.bool,
  toggleDetailsView: PropTypes.bool,
  isShowingCommitMultipleTestAssocRuleModalOpen: PropTypes.func,
  commitMultipleTestAssocRulesError: PropTypes.func,
  commitMultipleTestAssocRulesSuccess: PropTypes.func,
  loading: PropTypes.object,
  setDefaultGroupDetailsTab: PropTypes.func,
  selectedRecord: PropTypes.object,
  showActiveGroupsListingModal: PropTypes.bool,
  setIsShowingActiveGroupsListingModal: PropTypes.func,
  activeGroupsModalLocation: PropTypes.string,
  selectedActiveRecordsList: PropTypes.object,
  selectedRecordName: PropTypes.string,
};
export default CommitDetailsGridWrapper;
