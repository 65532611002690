// Import the action types we are going to respond to with this reducer
import {
  ALL_GROUPS_REQUEST_SUCCESS,
  ALL_GROUPS_REQUEST_ERROR,
  UPDATE_RULETYPES_REQUEST_TRIGGER,
  RECORD_COUNT_FROM_DB_SUCCESS,
  RECORD_COUNT_FROM_DB_ERROR,
  RECORD_COUNT_WITH_STATUS_KEYWORD_FROM_DB_ERROR,
  RECORD_COUNT_WITH_STATUS_KEYWORD_FROM_DB_SUCCESS,
  RESET_GROUPS_RECORDS,
  RESET_GROUPS_LIST,
} from './constants';

import gridStub from '../../../stubs/gridRecords';
// Initial state expresses the data type of this key in the store (in this case
// store.groupsList will be an Array). This object is exported separately to
// make unit testing easier
export const INITIAL_STATE = {
  records: [],
  groupGridQuery: '',
  loadMore: false,
  offset: 0,
  status: '',
  count: 0,
};

// This is the actual "reducer". It is a function that will run every time an
// action is dispatched by the store, but only return updated state values if
// it matches the action's type in the switch statement.
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RECORD_COUNT_WITH_STATUS_KEYWORD_FROM_DB_SUCCESS:
      return {
        ...state,
        count: action.payload.count,
      };
    case RECORD_COUNT_WITH_STATUS_KEYWORD_FROM_DB_ERROR:
      return {
        ...state,
        count: 0,
      };
    // If the action payload was a success, it'll have the total amount of records
    case RECORD_COUNT_FROM_DB_SUCCESS:
      return {
        ...state,
        count: action.payload.count,
      };
    case RECORD_COUNT_FROM_DB_ERROR: // If error occurs, it'll just return default count
      return {
        ...state,
        count: 0,
      };
    case ALL_GROUPS_REQUEST_SUCCESS:
      // In our success case our action's payload will be the new array to cach
      // and use in the application
      if (action.payload.append) {
        return {
          ...state,
          records: [...state.records, ...action.payload.groupsList],
          loadMore: action.payload.loadMore,
          offset: action.payload.offset,
          status: action.payload.status,
          groupGridQuery: action.payload.queryValue,
        };
      }
      return {
        ...state,
        records: [...action.payload.groupsList],
        loadMore: action.payload.loadMore,
        offset: action.payload.offset,
        status: action.payload.status,
        groupGridQuery: action.payload.queryValue,
      };

    case ALL_GROUPS_REQUEST_ERROR:
      // If an error occured you can set a fallback state here (empty grid unless on localhost)
      if (window.location.origin === 'http://localhost:3000') {
        return { ...state, records: [...gridStub] };
      }
      return { ...state, records: [] };

    case UPDATE_RULETYPES_REQUEST_TRIGGER: {
      // Taking a deep copy to not to change the redux state directly.
      // const [...newState] = JSON.parse(JSON.stringify(state));
      const newRecords = JSON.parse(JSON.stringify(state.records));
      if (Array.isArray(newRecords)) {
        const selectedGroup = newRecords.find(group => group.id === action.id);
        if (selectedGroup) {
          selectedGroup.ruleTypes = action.payload;
        }
      }
      return {
        ...state,
        records: newRecords,
      };
    }
    case RESET_GROUPS_RECORDS:
      return {
        ...state,
        records: [],
      };
    case RESET_GROUPS_LIST:
      return {
        records: [],
        groupGridQuery: '',
        loadMore: false,
        offset: 0,
        status: '',
        count: 0,
      };

    default:
      // Most of the actions in our app will be ignored by this reducer, so the
      // default case is to return the existing state
      return state;
  }
};
