import { connect } from 'react-redux';
import TestAssocContent from './components/TestAssocContent';
import {
  setIsViewingRuleDetails,
  setRuleTypeRequestTrigger,
  setRuleTypeModalOpen,
  triggerRuleTypesRequest,
  setSelectedRuleTypeId,
  setRuleTypesSearchQuery,
  resetRuleTypesListReceived,
  resetRuleTypes,
} from '../../redux/api/ruleTypesList/actions';
import {
  setIsViewingGroupAssociationsModal,
  postGroupAssociationsForRuleTypeTrigger,
  triggerAllRulesForRuleTypesRequest,
  rulesCountFromDBTrigger,
  setSelectedRuleType,
  triggerAllRulesForRuleTypesAssociationsRequest,
} from '../../redux/api/rulesForRuleTypesList/actions';
import {
  selectNotificationState,
  selectRuleTypesSearchQuery,
  selectRuleTypesSearchResults,
  selectSelectedRuleTypeId,
  selectLoadingState,
  setRulesWithAssociations
} from './selectors';
import { 
  selectUserRole,
  selectUserPermissions
} from '../RulesTabView/selectors';
import {
  deleteNotification,
  deleteAllNotifications,
} from '../../redux/app/notificationState/actions';
import { setAdvancedGroupDetailsTab } from '../../redux/app/detailsPaneState/actions';
import {
  captureCurrentPage,
} from '../../redux/api/pageTracker/actions';

const mapStateToProps = state => ({
  ...state,
  notifications: selectNotificationState(state),
  ruleTypeModalIsOpen: state.ruleTypesList.ruleTypeModalIsOpen,
  ruleTypeGridRecords: state.ruleTypesList.setRuleTypesListRecords,
  ruleTypesSearchResults: selectRuleTypesSearchResults(state),
  rulesForRuleTypesList: state.rulesForRuleTypesList,
  selectedRuleTypeId: selectSelectedRuleTypeId(state),
  ruleTypesSearchQuery: selectRuleTypesSearchQuery(state),
  loading: selectLoadingState(state),
  isShowingGroupAssociationModal: state.rulesForRuleTypesList.isShowingGroupAssociationModal,
  associatedRuleType: state.rulesForRuleTypesList.associatedRuleType,
  associating: state.rulesForRuleTypesList.associating,
  selectedRuleType: state.rulesForRuleTypesList.selectedRuleType,
  rulesWithAssociations: setRulesWithAssociations(state),
  ruleTypesAccumRecords: state.ruleTypesList.ruleTypesAccumRecords,
  userRole: selectUserRole(state),
  userPermissions: selectUserPermissions(state),
});

const mapDispatchToProps = {
  deleteNotification,
  deleteAllNotifications,
  rulesCountFromDBTrigger,
  setAdvancedGroupDetailsTab,
  setIsViewingRuleDetails,
  setRuleTypeRequestTrigger,
  setRuleTypeModalOpen,
  setRuleTypesSearchQuery,
  setSelectedRuleTypeId,
  setIsViewingGroupAssociationsModal,
  postGroupAssociationsForRuleTypeTrigger,
  triggerAllRulesForRuleTypesRequest,
  triggerRuleTypesRequest,
  resetRuleTypesListReceived,
  setSelectedRuleType,
  triggerAllRulesForRuleTypesAssociationsRequest,
  resetRuleTypes,
  captureCurrentPage
};

export default connect(mapStateToProps, mapDispatchToProps)(TestAssocContent);
