/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import styled from 'styled-components/dist/styled-components';
import { useDispatch } from 'react-redux';
import { 
  Grid,
  Button,
  useLayout,
  SelectDropdown
} from 'ui-core/dist';
import useSortableGrid from '../../hooks/useSortableGrid';
import { LoadingPage } from '../../components';
import GridPaginationWrapper from '../../components/GridPaginationWrapper';
import ErrorPage from '../ErrorPage';
import Authorizer from '../Authorizer';
import ListPaneHeaderWrapper from '../../components/ErrorDisplaySection/ListPaneHeaderWrapper';
import { CHECK_ACCOUNT_INFO_GRID } from './Modules/constants';
import { useGetCheckAccountInfoRecs } from "../../api/useClientRuleManager";
import { resetCheckAccountInfo, setCheckAccountInfo } from './Modules/action';
import './index.scss';

const StyledGridWrapper = styled.div`
&.GridWrapper #grid-wrapper-test-id{
  overflow:initial !important;
}
`
const SELECT_RECORD_OPTIONS = [{label:'Active',value: true},{label: 'Inactive',value: false}]

const CheckAccountInfoMain = () => {
  const {
    expandCollapseListPaneButtonConfig,
    openDetailPane,
    closeDetailPane
  } = useLayout()
  const dispatch = useDispatch()
  const [count, setcount] = useState(0)
  const [selectedRecord,setSelectedRecord]=useState('')
  const [totalRecords, setTotalRecords] = useState(0);
  const [records, setRecords] =useState([])
  const [rowsPerPage,setRowsPerPage]=useState(10);
  const [pageIndex,setPageIndex]=useState(0);
  const [status,setStatus] = useState({label: 'Active', value: true})
  
  const {data: checkAccountInfoRecs, postData: getCheckAccountInfoRecs, isError, isLoading, error} = useGetCheckAccountInfoRecs()
  const [sortConfig, getArgumentsAndSortGrid]=useSortableGrid(fetchData,"check-account-id","false");
  const getParameters = useCallback((args) =>{
    let sortcolumn; let sd; let pi;let type;
    if (args) {
      ({sortcolumn,sd,pi,type}=args);
    }
    return {
      "maxRecords": rowsPerPage,
      "rowPosition": pi === undefined || pi === null ? pageIndex : pi,
      "sortField":sortcolumn===undefined || sortcolumn===null?sortConfig.sortDataName: sortcolumn,
      "sortDescending":sd===undefined || sd===null?sortConfig.sortDescending: sd,
      "viewInactive": !status.value,
    }
  },[pageIndex, rowsPerPage, status])

  useEffect(() => {
    const param = getParameters();
    getCheckAccountInfoRecs({ ...param, count })
    setcount(count + 1)
    closeDetailPane();
  },[]);

  const fetchData = useCallback((args) => {    
    const param = getParameters(args);
    getCheckAccountInfoRecs({ 
      ...param,
      count 
    })
    setcount(count + 1)
    closeDetailPane();
  }, [rowsPerPage, pageIndex, status, count])
  

  useEffect(() => {  
    if(checkAccountInfoRecs){
      const {collection}=checkAccountInfoRecs;
      if (collection) {
        setTotalRecords(collection.$?.totalRecords)
        const checkAccountInfo = collection['check-account-information'];
        if (checkAccountInfo) {
          setRecords(checkAccountInfo)
        } else {
          setRecords([])
        }
      } else {
        setTotalRecords(0)
        setRecords([])
      }
    }
  }, [checkAccountInfoRecs])
  
  const refetchCheckAccountInfo = () => {
    fetchData()
    setcount(count + 1)
    closeDetailPane();
    setSelectedRecord({})
  }

  const onShowMoreClick = useCallback((record) => {
    dispatch(setCheckAccountInfo({ selectedRecord: JSON.parse(JSON.stringify(record))
      , mode: 'Edit', refetchRecord: refetchCheckAccountInfo}));
    setSelectedRecord(JSON.parse(JSON.stringify(record)))
    openDetailPane();
  },[count]);

  const onAddDeliveryAddress = () => {
    setSelectedRecord({})
    dispatch(setCheckAccountInfo({ selectedRecord: JSON.parse(JSON.stringify({}))
      , mode: 'Add', refetchRecord: refetchCheckAccountInfo}));
    openDetailPane();
  }
  const renderFirstFilter=()=>(
    <div style={{
      width: '200px',
      marginLeft: '5px',
      marginRight: '5px'
    }}>
      <SelectDropdown
        domID="defer_state_input"
        label=""
        options={SELECT_RECORD_OPTIONS}
        size="small"
        initialValue={status}
        onChange={(e)=>setStatus(e)}
      />
    </div>
  );
  const renderGrid = useMemo(() => {
    if (records) {
      let data = []
      if(records.constructor.name === 'Array')
        data = JSON.parse(JSON.stringify(records))
      else 
        data = JSON.parse(JSON.stringify([records]))

      data = data.map(x=>{
        const val = x;
        if(val && val['micr-account-number-start-pos'] === "0")
          val['micr-account-number-start-pos'] = ''
        if(val && val['signature-2-threshold'] !== "0")
          val['signature-2-threshold'] = parseFloat(val['signature-2-threshold']).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
        else 
          val['signature-2-threshold'] = ''

        return val
      })
      return (
        <>
          <StyledGridWrapper className='GridWrapper'>
            <GridPaginationWrapper
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={setRowsPerPage}
              pageIndex={pageIndex}
              onChangePageIndex={setPageIndex}
              onShowButtonCLick={fetchData}
              totalRecords={totalRecords}
              defaultRowsPerPage={10}
              FirstFilter={renderFirstFilter}
            />
            <Grid
              {...CHECK_ACCOUNT_INFO_GRID}
              records={data || []}
              selectionKey= "check-account-id"
              onClickThrough={(e, rec) => {
                e.preventDefault();
                e.stopPropagation();
                if (rec) {
                  const record = (data || []).find(x => x['check-account-id'] === rec['check-account-id'])
                  if (onShowMoreClick) {
                    dispatch(resetCheckAccountInfo())
                    return onShowMoreClick({...record, data});
                  }
                  return false;
                }
                return false;
              }}
              onSortGridColumn={(e,state)=>getArgumentsAndSortGrid(state)}
              focusedItem={selectedRecord}            
            />
          </StyledGridWrapper>
        </>
      )
    }
    return null;
  }, [
    records,
    selectedRecord,
    rowsPerPage,
    pageIndex,
    totalRecords,
    status
  ])

  return (
    <>
      <ListPaneHeaderWrapper
        title="Check Account Info Manager"
        layoutControls={[expandCollapseListPaneButtonConfig]}
      />
      {isLoading ? (
        // replace with a better loading component
        <LoadingPage />
      ) : isError ? (
        // replace with a better error component
        <ErrorPage
          // this prop isn't real but is an example
          error={error}
        />
      ) : (
        <>
          <div className="marginLeft">
            <table border="0" cellSpacing="1" cellPadding="3" width="100%">
              <tr>
                <td>
                  <div className="displayFlex" style={{ paddingLeft: '23px'}}>
                  <Authorizer permission="CAN_PERFORM_CLIENTRULES_CAI_ADD" >
                    <Button
                      id='ContactEditorAdd'
                      size="small"
                      name="Add Check Account Info"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onAddDeliveryAddress()
                      }}
                    />
                    </Authorizer>
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  {renderGrid}
                </td>
              </tr>
            </table>
          </div>
          {/* {renderMainGrid} */}
        </>
      )}
    </>
  )
};

export default CheckAccountInfoMain