import React, { useState, useMemo, useCallback, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components/dist/styled-components'
import { Modal, ModalBody, ModalHeader, Button, Checkbox, Triangle0, Triangle100 } from 'ui-core/dist'
import { SelectDropdown } from '../components'
import Caret from 'ui-core/dist/Caret'
import { Cog } from 'ui-core/dist/icons';
import { mapIterable } from '../commonResources/helpers/iterables';
import { floatToDollarString } from '../commonResources/helpers/money';
import { renderHeaderCol, renderShowMorePanel, onlyUnique } from './gridUtils'

const StyledTable = styled.table`
border: ${props => props.isGroupedParent ? 'none' : '1px solid rgb(218, 216, 215)'};
        margin-left: 4px;
        margin-right: 4px;
        margin-bottom: 2px;
        font-size: 11px;
        width: ${props => props.width || '61%'};
        & thead tr td, thead tr th, tbody tr td, tbody tr th {
          padding-top: 5px;
          padding-left: 2px;
          padding-right: 2px;
          padding-bottom: 2px;
        }
        & tbody tr td {
          padding-left: 5px;
        }
        & > tbody > tr:hover {
          cursor: pointer;
          // border: 1px solid #DEE4EC;
          // box-shadow: 0px 2px 6px #cfcfcf;
        }
`
const StyledFlexDiv = styled.div`
display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    /* align-items: center; */
    margin-bottom: 10px;
    font-size: 13px;
`;
const StyleButton = styled(Button)`
height: 18px !important;
width: 53px !important;
background: transparent;
box-shadow: none;
&:hover {
  background: transparent;
box-shadow: none;
}
`
const StyleButton2 = styled(Button)`
color:${props => props.color} !important;
margin-left: 25px !important;
height: 18px !important;
width: 53px !important;
background: transparent;
box-shadow: none;
&:hover {
  background: transparent;
box-shadow: none;
}
`
const StyledCaret = styled(Caret)`
transform: ${props => props.isExpanded ? 'rotate(0deg)' : 'rotate(180deg)'};
-webkit-transition: -webkit-transform 0.5s ease-out;
-moz-transition:    -moz-transform 0.5s ease-out;
-o-transition:      -o-transform 0.5s ease-out;
-ms-transition:     -ms-transform 0.5s ease-out;
transition:         transform 0.5s ease-out;
`

const StyledTriangle = styled(Triangle100)`
transform: ${props => props.direction === 'SORT_ASCENDING' ? 'rotate(0deg)' : 'rotate(180deg)'};
-webkit-transition: -webkit-transform 0.5s ease-out;
-moz-transition:    -moz-transform 0.5s ease-out;
-o-transition:      -o-transform 0.5s ease-out;
-ms-transition:     -ms-transform 0.5s ease-out;
transition:         transform 0.5s ease-out;
`

const ShowMoreNestedGridComponent = ({ mainGridConfig, nestedGridConfig, data }) => {

  nestedGridConfig = nestedGridConfig || {};

  const { isGrouped: isGroupedParent, defaultGroupBy, defaultGroupingType, transformationFunction: transformationFunctionParent
    , wantGroupingControls, width
    , supportSelection, selectionKey, onRowSelect, onRowClick
    , configurable
    , initialSortingKey, onSortGridColumn, sortingComparator, disabledTooltip } = mainGridConfig

  let { disabledCondition, selectionCondition } = mainGridConfig;
  const defDisabledCondition = (x) => x === null;
  const defSelectionCondition = () => true;
  disabledCondition = disabledCondition || defDisabledCondition
  selectionCondition = selectionCondition || defSelectionCondition

  const { isGrouped: isGroupedNested, groupingType: groupingTypeNested } = nestedGridConfig

  const [groupdedData, setGroupdedData] = useState([]);
  const [groupingColumnNameList, setGroupingColumnNameList] = useState([]);
  const [groupgingTypeList, setGroupingTypeList] = useState([]);

  const [configurationWindow, setConfigurationWindow] = useState(false)

  const [groupingColumnName, setGroupingColumnName] = useState(defaultGroupBy)
  const [groupingTypeParent, setGroupingTypeParent] = useState(defaultGroupingType)
  const [groupingOrderReverseParent, setGroupingOrderReverseParent] = useState(false);
  const [currentSelectedRowIndex, setcurrentSelectedRowIndex] = useState(null)

  useEffect(() => {
    const columnnames = Array.from(mainGridConfig.columns.values()).filter(x => x.text).map((c) => ({
      label: c.text,
      value: c.groupingColumnName || c.dataName,
      isSelected: true
    }))
    const columntypes = [
      { label: 'Table', value: 'table' },
      { label: 'Row', value: 'row' }
    ];
    setGroupingColumnNameList(columnnames)
    setGroupingTypeList(columntypes)
  }, [])

  const setColumnSelectedForDisplay = (state, grpname) => {
    if (grpname) {
      const g = groupingColumnNameList.find(x => x.label === grpname.label);
      if (g) {
        g.isSelected = state;
        setGroupingColumnNameList(JSON.parse(JSON.stringify(groupingColumnNameList)))
      }
    }
  }
  useEffect(() => {
    assignGroupedData();
    const columnnames = Array.from(mainGridConfig.columns.values()).filter(x => x.text).map((c) => ({
      label: c.text,
      value: c.groupingColumnName || c.dataName,
      isSelected: true
    }))
    setGroupingColumnNameList(columnnames)
  }, [data, isGroupedParent, groupingColumnName]);

  const assignGroupedData = () => {
    let result = [];
    if (isGroupedParent) {
      const uniquefirstnames = data.map(t => t[groupingColumnName]).filter(onlyUnique);
      for (const firstname of uniquefirstnames) {
        const tarray = data.filter(t => t[groupingColumnName] == firstname);
        result.push({
          key: groupingColumnName,
          value: firstname,
          isExpanded: true,
          subRecords: [...tarray]
        })
      }
    } else {
      result = [...data];
    }
    setGroupdedData(JSON.parse(JSON.stringify(result)));
  }
  const renderGroupedHeader = () => {
    if (isGroupedParent && groupdedData && groupdedData.length > 0) {
      const { key } = groupdedData[0]
      const remain = Array.from(mainGridConfig.columns.values()).filter(x => (x.groupingColumnName || x.dataName) !== key);
      const t = Array.from(mainGridConfig.columns.values()).find(x => (x.groupingColumnName || x.dataName) === key);
      let text = '';
      if (t) {
        text = t.text;
      }
      const disabled = mainGridConfig.disabledColumns.has(key);
      return (
        <>
          {groupingTypeParent === "row" ? (
            <>
              {groupingOrderReverseParent ? (
                <>
                  {renderHeader(new Set(remain))}
                  {!disabled ? (
                    <td
                      key={key}
                      className={key}
                      style={{ textAlign: 'center' }}
                    >
                      <p></p>
                    </td>
                  ) : null}
                </>
              ) : (
                  <>
                    {!disabled ? (
                      <td
                        key={key}
                        className={key}
                        style={{ textAlign: 'center' }}
                      >
                        <p></p>
                      </td>
                    ) : null}
                    {renderHeader(new Set(remain))}
                  </>
                )}
            </>
          ) : (
              <>
                {supportSelection ? (
                  <td><p></p></td>
                ) : null}
                {renderHeader(new Set(remain))}
              </>
            )}

        </>
      )
    }
    return (
      <>
        {supportSelection && groupdedData && groupdedData.length > 0 ? (
          <>
            {groupdedData.find(selectionCondition) ? (
              <td style={{ paddingLeft: '5px' }}>
                <div data-tip data-for="headerTooltip">
                  <Checkbox
                    name='enableCheckBox'
                    className="table-row-checkbox"
                    onChange={(e, state) => {
                      if ('preventDefault' in e) e.preventDefault();
                      if ('stopPropagation' in e) e.stopPropagation();
                      onRowSelect(state, groupdedData.filter(selectionCondition))
                    }}
                    onClick={e => {
                      if ('preventDefault' in e) e.preventDefault();
                      if ('stopPropagation' in e) e.stopPropagation();
                      return false;
                    }}
                    checked={groupdedData.filter(x => x[selectionKey]).length === groupdedData.filter(selectionCondition).length}
                    disabled={groupdedData.filter(selectionCondition).filter(disabledCondition).length === groupdedData.filter(selectionCondition).length}
                    stopClickPropagation
                  />
                </div>
                {disabledTooltip && (groupdedData.filter(selectionCondition).filter(disabledCondition).length === groupdedData.filter(selectionCondition).length) ? (
                  <ReactTooltip id="headerTooltip" type='dark' effect='solid'>
                    {disabledTooltip({ target: "header", isGroupedParent })}
                  </ReactTooltip>
                ) : null}
              </td>) : <td><p></p></td>}
          </>
        ) : null}
        {renderHeader(mainGridConfig.columns)}
      </>
    )
  }

  const getSelectedStatusOfCol = (text) => {
    if (groupingColumnNameList && groupingColumnNameList.length > 0) {
      const f = groupingColumnNameList.find(x => x.label === text)
      if (f) {
        return f.isSelected
      }
      return true;
    }
    return false;
  }
  const getIndexOfCol = (cindex, text, key) => {
    if (groupingColumnNameList && groupingColumnNameList.length > 0) {
      if (key) {
        const f = groupingColumnNameList.filter(x => x.isSelected).filter(x => x.value !== key).find(x => x.label === text);
        if (f) {
          return groupingColumnNameList.filter(x => x.isSelected).filter(x => x.value !== key).map(x => x.label).indexOf(text);
        }
        return cindex;
      }
      const f = groupingColumnNameList.filter(x => x.isSelected).find(x => x.label === text);
      if (f) {
        return groupingColumnNameList.filter(x => x.isSelected).map(x => x.label).indexOf(text);
      }
      return cindex;
    }
    return cindex;
  }
  const renderHeader = (columnSet) => {
    let dataname = "";
    let direction = "";
    if (initialSortingKey && initialSortingKey.indexOf('|')) {
      const s = initialSortingKey.split('|');
      if (s && s.length > 0) {
        dataname = s[0];
        direction = s[1];
      }
    }
    return (
      <>
        {
          mapIterable(columnSet.values(), (c) => {
            const colname = c.groupingColumnName || c.dataName;
            const { sortable, cellType } = c;
            const disabled = mainGridConfig.disabledColumns.has(c.dataName);
            const selected = getSelectedStatusOfCol(c.text);
            if (!disabled && selected) {
              return (
                <td
                  style={{ paddingLeft: groupingTypeParent === "row" ? '20px' : '5px', whiteSpace: 'nowrap' }}
                  key={c.dataName}
                  className={c.dataName}
                >
                  <div style={{ fontSize: '13px', color: 'black', fontWeight: 'bold' }}>
                    {/* <StyleButton2
                      color={isGroupedParent ? 'black' : 'white'}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (sortingComparator) {
                          return sortingComparator(e, {
                            sortingKey: `${colname}|${direction === "SORT_DESCENDING" ? "SORT_ASCENDING" : "SORT_DESCENDING"}`,
                            type: cellType
                          })
                        }
                        if (onSortGridColumn) {
                          return onSortGridColumn(e, {
                            sortingKey: `${colname}|${direction === "SORT_DESCENDING" ? "SORT_ASCENDING" : "SORT_DESCENDING"}`,
                            type: cellType
                          })
                        }
                        return false;
                      }}> */}
                    {c.text}
                    {/* <div>
                        {(sortable && dataname === colname) ? (
                          <StyleButton>
                            <StyledTriangle
                              direction={direction}
                              className="expand"
                              fillColor={isGroupedParent ? 'black' : 'white'}
                              title="expand"
                            />
                          </StyleButton>
                        ) : null}
                      </div>
                    </StyleButton2> */}
                  </div>
                </td>
              )
            }
            return null;
          })
        }
      </>
    )
  }
  const renderRowBackground = useCallback((index) => {
    if (currentSelectedRowIndex !== null) {
      if (currentSelectedRowIndex === index) {
        return '#CAF0FF';
      }
    }
    if (index === 0) {
      return '#f1f2f3';
    }
    if (index === 1) {
      return 'white';
    }
    return index % 2 === 0 ? '#f1f2f3' : 'white';
  }, [currentSelectedRowIndex]);
  const renderTbody = useCallback((temp) => (
    <tbody className="GroupGrid">
      {temp.map((record, sindex) => {
        if (!isGroupedParent) {
          return (
            <>
              <tr style={{ background: renderRowBackground(sindex), cursor: !onRowClick ? 'default' : 'pointer' }} onClick={(e) => { if (onRowClick) { /*setcurrentSelectedRowIndex(sindex);*/ onRowClick(e, record); } }}>
                {
                  Array.from(mainGridConfig.columns.values()).map((c, cindex) => {
                    const disabled = mainGridConfig.disabledColumns.has(c.dataName);
                    const selected = getSelectedStatusOfCol(c.text);
                    if (!disabled && selected) {
                      let entry = record[c.dataName];
                      if (c.isCurrency) {
                        entry = floatToDollarString(entry);
                      }
                      if (!c.hasSubRecords) {
                        return (
                          <>
                            {supportSelection ? (
                              <>
                                {getIndexOfCol(cindex, c.text) === 0 ? (
                                  <>
                                    {[{ ...record }].find(selectionCondition) ? (
                                      <td>
                                        <div data-tip data-for={`recordTooltip_${sindex}`}>
                                          <Checkbox
                                            name='enableCheckBox'
                                            className="table-row-checkbox"
                                            onChange={(e, state) => {
                                              if ('preventDefault' in e) e.preventDefault();
                                              if ('stopPropagation' in e) e.stopPropagation();
                                              onRowSelect(state, record)
                                            }}
                                            onClick={e => {
                                              if ('preventDefault' in e) e.preventDefault();
                                              if ('stopPropagation' in e) e.stopPropagation();
                                              return false;
                                            }}
                                            checked={record[selectionKey]}
                                            disabled={[{ ...record }].find(disabledCondition)}
                                            stopClickPropagation
                                          />
                                        </div>
                                        {disabledTooltip && ([{ ...record }].find(disabledCondition)) ? (
                                          <ReactTooltip id={`recordTooltip_${sindex}`} type='dark' effect='solid'>
                                            {disabledTooltip({ target: "record", isGroupedParent, data: record })}
                                          </ReactTooltip>
                                        ) : null}
                                      </td>) : <td><p></p></td>}
                                  </>
                                ) : null}
                              </>
                            ) : null}
                            <td
                              key={c.dataName}
                              className={c.dataName}
                            >
                              <div className="displayFlex">
                                {entry}
                              </div>
                            </td>
                          </>
                        )
                      }
                      return (
                        <>
                          {supportSelection ? (
                            <>
                              {getIndexOfCol(cindex, c.text) === 0 ? (
                                <>
                                  {[{ ...record }].find(selectionCondition) ? (
                                    <td>
                                      <div data-tip data-for={`recordTooltip_${sindex}`}>
                                        <Checkbox
                                          name='enableCheckBox'
                                          className="table-row-checkbox"
                                          onChange={(e, state) => {
                                            if ('preventDefault' in e) e.preventDefault();
                                            if ('stopPropagation' in e) e.stopPropagation();
                                            onRowSelect(state, record)
                                          }}
                                          onClick={e => {
                                            if ('preventDefault' in e) e.preventDefault();
                                            if ('stopPropagation' in e) e.stopPropagation();
                                            return false;
                                          }}
                                          checked={record[selectionKey]}
                                          disabled={[{ ...record }].find(disabledCondition)}
                                          stopClickPropagation
                                        />
                                      </div>
                                      {disabledTooltip && ([{ ...record }].find(disabledCondition)) ? (
                                        <ReactTooltip id={`recordTooltip_${sindex}`} type='dark' effect='solid'>
                                          {disabledTooltip({ target: "record", isGroupedParent, data: record })}
                                        </ReactTooltip>
                                      ) : null}
                                    </td>) : <td><p></p></td>}
                                </>
                              ) : null}
                            </>
                          ) : null}
                          <td
                            key={c.dataName}
                            className={c.dataName}
                          >
                            <table style={{
                              border: '1px solid rgb(218, 216, 215)',
                              marginTop: '4px',
                              marginBottom: '4px'
                            }}>
                              <thead>
                                <tr>
                                  {
                                    mapIterable(nestedGridConfig.columns.values(), (c1) => {
                                      const disabled1 = nestedGridConfig.disabledColumns.has(c1.dataName);
                                      if (!disabled1) {
                                        return (
                                          <td
                                            key={c1.dataName}
                                            className={c1.dataName}
                                          >
                                            <div className="displayFlex">
                                              {c1.text}
                                            </div>
                                          </td>
                                        )
                                      }
                                      return null;
                                    })
                                  }
                                </tr>
                              </thead>
                              <tbody>
                                {record.subRecords.map((subrecord) => {
                                  if (!isGroupedNested) {
                                    return (
                                      <>
                                        <tr>
                                          {
                                            mapIterable(nestedGridConfig.columns.values(), (c1) => {
                                              const disabled1 = nestedGridConfig.disabledColumns.has(c1.dataName);
                                              if (!disabled1) {
                                                let entry1 = subrecord[c1.dataName];
                                                if (c1.isCurrency) {
                                                  entry1 = floatToDollarString(entry1);
                                                }
                                                return (
                                                  <td
                                                    key={c1.dataName}
                                                    className={c1.dataName}
                                                  >
                                                    <div className="displayFlex">
                                                      {entry1}
                                                    </div>
                                                  </td>
                                                )
                                              }
                                              return null;
                                            })
                                          }
                                        </tr>
                                        {nestedGridConfig.supportShowMore && subrecord[nestedGridConfig.showMoreColumnIdentifier] ? (
                                          <tr style={{ paddingTop: "10px" }}>
                                            <td colSpan={Array.from(nestedGridConfig.columns.values()).length}>
                                              {renderShowMorePanel(nestedGridConfig, subrecord)}
                                            </td>
                                          </tr>
                                        ) : null}
                                      </>
                                    )
                                  }
                                  if (groupingTypeNested === "table") {
                                    return null;
                                  }
                                  return null;
                                })}
                              </tbody>
                            </table>
                          </td>
                        </>
                      )
                    }
                    return null;
                  })
                }
              </tr>
              {mainGridConfig.supportShowMore && record[mainGridConfig.showMoreColumnIdentifier] ? (
                <tr style={{ paddingTop: "10px", cursor: 'default' }}>
                  <td colSpan={Array.from(mainGridConfig.columns.values()).length}>
                    {renderShowMorePanel(mainGridConfig, record)}
                  </td>
                </tr>
              ) : null}
            </>
          );
        }
        const { key, value, isExpanded, subRecords } = record;
        if (groupingTypeParent === "table") {
          return (
            <>
              {subRecords.length === 1 ? (
                <>

                  <tr style={{
                    background: '#13136c', color: 'white', marginTop: '30px', cursor: 'default'
                  }} colSpan={Array.from(mainGridConfig.columns.values()).length} scope="colgroup">
                    {supportSelection ? (
                      <>
                        {subRecords.find(selectionCondition) ? (
                          <td style={{ width: '15px' }}>
                            <div data-tip data-for={`recordHeaderTooltip_${sindex}`}>
                              <Checkbox
                                name='enableCheckBox'
                                className="table-row-checkbox"
                                onChange={(e, state) => {
                                  if ('preventDefault' in e) e.preventDefault();
                                  if ('stopPropagation' in e) e.stopPropagation();
                                  onRowSelect(state, subRecords)
                                }}
                                onClick={e => {
                                  if ('preventDefault' in e) e.preventDefault();
                                  if ('stopPropagation' in e) e.stopPropagation();
                                  return false;
                                }}
                                checked={subRecords[0][selectionKey]}
                                disabled={[{ ...subRecords[0] }].find(disabledCondition)}
                                stopClickPropagation
                              />
                            </div>
                            {disabledTooltip && ([{ ...subRecords[0] }].find(disabledCondition)) ? (
                              <ReactTooltip id={`recordHeaderTooltip_${sindex}`} type='dark' effect='solid'>
                                {disabledTooltip({ target: "recordHeader", isGroupedParent, data: subRecords[0] })}
                              </ReactTooltip>
                            ) : null}
                          </td>) : <td><p></p></td>}
                      </>
                    ) : null}
                    <th colSpan={Array.from(mainGridConfig.columns.values()).length}
                      style={{ textAlign: "left" }}
                      scope="colgroup">
                      <div className="displayFlex" style={{ display: 'block' }}>
                        <span style={{ marginLeft: '5px', float: 'left' }}>{subRecords[0][Array.from(mainGridConfig.columns.values()).find(c => (c.groupingColumnName || c.dataName) === key).dataName]}</span>
                        <div className="floatRight" style={{ float: 'right' }}>
                          <StyleButton
                            onClick={(e) => {
                              const f = groupdedData.find(x => x.key === key && x.value === value);
                              f.isExpanded = !isExpanded;
                              setGroupdedData(JSON.parse(JSON.stringify(groupdedData)));
                            }}>
                            <StyledCaret
                              isExpanded={isExpanded}
                              className="expand"
                              fillColor="white"
                              title="expand"
                            />
                          </StyleButton>
                        </div>
                      </div>
                    </th>
                  </tr>
                  <tr style={{ background: renderRowBackground(0), height: isExpanded ? 'auto' : '0px', display: isExpanded ? 'table-row' : 'none', transition: 'all 0.5s ease-out', cursor: !onRowClick ? 'default' : 'pointer' }} onClick={(e) => { if (onRowClick) { /*setcurrentSelectedRowIndex(0);*/ onRowClick(e, subRecords[0]) } }}>
                    {supportSelection ? (
                      <td><p></p></td>
                    ) : null}
                    {
                      Array.from(mainGridConfig.columns.values()).filter(c => (c.groupingColumnName || c.dataName) !== key).map((c) => {
                        const disabled = mainGridConfig.disabledColumns.has(c.dataName);
                        const selected = getSelectedStatusOfCol(c.text);;
                        if (!disabled && selected) {
                          let entry = subRecords[0][c.dataName];
                          if (c.isCurrency) {
                            entry = floatToDollarString(entry);
                          }
                          return (
                            <td style={{ paddingLeft: '0px !important' }}
                              key={c.dataName}
                              className={c.dataName}
                            >
                              <div className="displayFlex">
                                {entry}
                              </div>
                            </td>
                          )
                        }
                        return null;
                      })
                    }
                  </tr>
                  {mainGridConfig.supportShowMore && subRecords[0][mainGridConfig.showMoreColumnIdentifier] ? (
                    <tr style={{ paddingTop: "10px", cursor: 'default' }}>
                      <th colSpan={Array.from(mainGridConfig.columns.values()).length} scope="colgroup">
                        {renderShowMorePanel(mainGridConfig, subRecords[0])}
                      </th>
                    </tr>
                  ) : null}
                </>
              ) : (
                  <>
                    {subRecords.map((srecord, index) => (
                      <>
                        {index === 0 ? (
                          <>

                            <tr style={{
                              background: '#13136c', color: 'white', marginTop: '30px', cursor: 'default'
                            }} colSpan={Array.from(mainGridConfig.columns.values()).length} scope="colgroup">
                              {supportSelection ? (
                                <>
                                  {subRecords.find(selectionCondition) ? (
                                    <td style={{ width: '15px' }}>
                                      <div data-tip data-for={`recordHeaderTooltip_${sindex}`}>
                                        <Checkbox
                                          name='enableCheckBox'
                                          className="table-row-checkbox"
                                          onChange={(e, state) => {
                                            if ('preventDefault' in e) e.preventDefault();
                                            if ('stopPropagation' in e) e.stopPropagation();
                                            onRowSelect(state, subRecords.filter(selectionCondition))
                                          }}
                                          onClick={e => {
                                            if ('preventDefault' in e) e.preventDefault();
                                            if ('stopPropagation' in e) e.stopPropagation();
                                            return false;
                                          }}
                                          checked={subRecords.filter(x => x[selectionKey]).length === subRecords.filter(selectionCondition).length}
                                          disabled={subRecords.filter(selectionCondition).filter(disabledCondition).length === subRecords.filter(selectionCondition).length}
                                          stopClickPropagation
                                        />
                                      </div>
                                      {disabledTooltip && (subRecords.filter(selectionCondition).filter(disabledCondition).length === subRecords.filter(selectionCondition).length) ? (
                                        <ReactTooltip id={`recordHeaderTooltip_${sindex}`} type='dark' effect='solid'>
                                          {disabledTooltip({ target: "recordHeader", isGroupedParent, data: subRecords })}
                                        </ReactTooltip>
                                      ) : null}
                                    </td>) : <td><p></p></td>}
                                </>
                              ) : null}
                              <th colSpan={Array.from(mainGridConfig.columns.values()).length}
                                style={{ textAlign: "left" }}
                                scope="colgroup">
                                <div className="displayFlex" style={{ display: 'block' }}>
                                  <span style={{ marginLeft: '5px', float: 'left' }}>{srecord[Array.from(mainGridConfig.columns.values()).find(c => (c.groupingColumnName || c.dataName) === key).dataName]}</span>
                                  <div className="floatRight" style={{ float: 'right' }}>
                                    <StyleButton
                                      onClick={(e) => {
                                        const f = groupdedData.find(x => x.key === key && x.value === value);
                                        f.isExpanded = !isExpanded;
                                        setGroupdedData(JSON.parse(JSON.stringify(groupdedData)));
                                      }}>
                                      <StyledCaret
                                        isExpanded={isExpanded}
                                        className="expand"
                                        fillColor="white"
                                        title="expand"
                                      />
                                    </StyleButton>
                                  </div>
                                </div>
                              </th>
                            </tr>
                          </>
                        ) : null}
                        <>
                          <tr style={{ background: renderRowBackground(index), height: isExpanded ? 'auto' : '0px', display: isExpanded ? 'table-row' : 'none', transition: 'all 0.5s ease-out', cursor: !onRowClick ? 'default' : 'pointer' }} onClick={(e) => { if (onRowClick) { /*setcurrentSelectedRowIndex(index);*/ onRowClick(e, srecord) } }}>
                            {
                              Array.from(mainGridConfig.columns.values()).filter(c => (c.groupingColumnName || c.dataName) !== key).map((c, cindex) => {
                                const disabled = mainGridConfig.disabledColumns.has(c.dataName);
                                const selected = getSelectedStatusOfCol(c.text);;
                                if (!disabled && selected) {
                                  let entry = srecord[c.dataName];
                                  if (c.isCurrency) {
                                    entry = floatToDollarString(entry);
                                  }
                                  return (
                                    <>
                                      {supportSelection ? (
                                        <>
                                          {getIndexOfCol(cindex, c.text, key) === 0 ? (
                                            <>
                                              {[{ ...srecord }].find(selectionCondition) ? (
                                                <td>
                                                  <div data-tip data-for={`recordBodyTooltip_${sindex}_${index}`}>
                                                    <Checkbox
                                                      name='enableCheckBox'
                                                      className="table-row-checkbox"
                                                      onChange={(e, state) => {
                                                        if ('preventDefault' in e) e.preventDefault();
                                                        if ('stopPropagation' in e) e.stopPropagation();
                                                        onRowSelect(state, srecord)
                                                      }}
                                                      onClick={e => {
                                                        if ('preventDefault' in e) e.preventDefault();
                                                        if ('stopPropagation' in e) e.stopPropagation();
                                                        return false;
                                                      }}
                                                      checked={srecord[selectionKey]}
                                                      disabled={[{ ...srecord }].find(disabledCondition)}
                                                      stopClickPropagation
                                                    />
                                                  </div>
                                                  {disabledTooltip && ([{ ...srecord }].find(disabledCondition)) ? (
                                                    <ReactTooltip id={`recordBodyTooltip_${sindex}_${index}`} type='dark' effect='solid'>
                                                      {disabledTooltip({ target: "recordBody", isGroupedParent, data: srecord })}
                                                    </ReactTooltip>
                                                  ) : null}
                                                </td>) : <td><p></p></td>}
                                            </>
                                          ) : null}
                                        </>
                                      ) : null}
                                      <td style={{ paddingLeft: '0px !important' }}
                                        key={c.dataName}
                                        className={c.dataName}
                                      >
                                        <div className="displayFlex">
                                          {entry}
                                        </div>
                                      </td>
                                    </>
                                  )
                                }
                                return null;
                              })
                            }
                          </tr>
                          {mainGridConfig.supportShowMore && srecord[mainGridConfig.showMoreColumnIdentifier] ? (
                            <tr style={{ paddingTop: "10px", cursor: 'default' }}>
                              <th colSpan={Array.from(mainGridConfig.columns.values()).length} scope="colgroup">
                                {renderShowMorePanel(mainGridConfig, srecord)}
                              </th>
                            </tr>
                          ) : null}
                        </>
                      </>
                    )
                    )}
                  </>
                )}
            </>
          );
        }
        return (
          <>
            {subRecords.map((srecord, index) => (
              <>
                {index === 0 ? (
                  <>
                    <tr><td style={{ display: 'block' }}><p></p></td></tr>
                  </>
                ) : null}
                <tr style={{ background: '#f1f2f3', cursor: !onRowClick ? 'default' : 'pointer' }} onClick={(e) => { if (onRowClick) onRowClick(e, srecord) }}>
                  {groupingOrderReverseParent ? (
                    <>
                      {
                        Array.from(mainGridConfig.columns.values()).filter(c => (c.groupingColumnName || c.dataName) !== key).map((c) => {
                          const disabled = mainGridConfig.disabledColumns.has(c.dataName);
                          const selected = getSelectedStatusOfCol(c.text);;
                          if (!disabled && selected) {
                            let entry = srecord[c.dataName];
                            if (c.isCurrency) {
                              entry = floatToDollarString(entry);
                            }
                            return (
                              <td style={{ paddingLeft: '20px' }}
                                key={c.dataName}
                                className={c.dataName}
                              >
                                <div className="displayFlex">
                                  {entry}
                                </div>
                              </td>
                            )
                          }
                          return null;
                        })
                      }
                      {renderHeaderCol(subRecords.length, index, key, srecord[Array.from(mainGridConfig.columns.values()).find(c => (c.groupingColumnName || c.dataName) === key).dataName],
                        subRecords.filter(s => s[mainGridConfig.showMoreColumnIdentifier] || false).length)}
                    </>
                  ) : (
                      <>
                        {renderHeaderCol(subRecords.length, index, key, srecord[Array.from(mainGridConfig.columns.values()).find(c => (c.groupingColumnName || c.dataName) === key).dataName],
                          subRecords.filter(s => s[mainGridConfig.showMoreColumnIdentifier] || false).length)}
                        {
                          Array.from(mainGridConfig.columns.values()).filter(c => (c.groupingColumnName || c.dataName) !== key).map((c) => {
                            const disabled = mainGridConfig.disabledColumns.has(c.dataName);
                            const selected = getSelectedStatusOfCol(c.text);;
                            if (!disabled && selected) {
                              let entry = srecord[c.dataName];
                              if (c.isCurrency) {
                                entry = floatToDollarString(entry);
                              }
                              return (
                                <td style={{ paddingLeft: '20px' }}
                                  key={c.dataName}
                                  className={c.dataName}
                                >
                                  <div className="displayFlex">
                                    {entry}
                                  </div>
                                </td>
                              )
                            }
                            return null;
                          })
                        }
                      </>
                    )}

                </tr>
                {mainGridConfig.supportShowMore && srecord[mainGridConfig.showMoreColumnIdentifier] ? (
                  <tr style={{ paddingTop: "10px", cursor: 'default' }}>
                    <th colSpan={Array.from(mainGridConfig.columns.values()).length} scope="colgroup">
                      {renderShowMorePanel(mainGridConfig, srecord)}
                    </th>
                  </tr>
                ) : null}
              </>
            ))}
          </>
        );
      })}
    </tbody>
  ), [groupdedData, groupingTypeParent, groupingOrderReverseParent, isGroupedParent, groupingColumnName, groupingColumnNameList, currentSelectedRowIndex])

  const renderFunction = useMemo(() => {
    let temp = JSON.parse(JSON.stringify(groupdedData));
    if (transformationFunctionParent && typeof transformationFunctionParent === "function") {
      temp = transformationFunctionParent(groupdedData, isGroupedParent);
    }
    return renderTbody(temp)
  }, [groupdedData, groupingTypeParent, groupingOrderReverseParent, isGroupedParent, groupingColumnName, groupingColumnNameList, currentSelectedRowIndex])

  const onChangeIsSelectedColumnForDisplay = (e, state, grpname) => {
    if (isGroupedParent) {
      if (grpname.value === groupingColumnName && !state) {
        e.preventDefault();
        e.stopPropagation();
        return false;
      }
      if (groupingColumnNameList.filter(x => x.isSelected).length === 2 && !state) {
        e.preventDefault();
        e.stopPropagation();
        return false;
      }
      return setColumnSelectedForDisplay(state, grpname)
    }
    if (groupingColumnNameList.filter(x => x.isSelected).length === 1 && !state) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
    return setColumnSelectedForDisplay(state, grpname)
  }

  const renderConfigurationCheckbox = (grpname) => {
    if (isGroupedParent) {
      if (grpname.value === groupingColumnName) {
        return true;
      }
      if (groupingColumnNameList.filter(x => x.value !== groupingColumnName).filter(x => x.isSelected).find(x => x.value === grpname.value)
        && groupingColumnNameList.filter(x => x.value !== groupingColumnName).filter(x => x.isSelected).length === 1) {
        return true;
      }
      return false;
    }
    if (groupingColumnNameList.filter(x => x.isSelected).find(x => x.value === grpname.value)
      && groupingColumnNameList.filter(x => x.isSelected).length === 1) {
      return true;
    }
    return false;
  }

  return (
    <>
      <div style={{ paddingTop: '10px' }}>
        {isGroupedParent && wantGroupingControls ? (
          <div className="displayFlex">
            <div className="marginLeft" style={{ width: '250px' }}>
              <SelectDropdown
                width="250px"
                domID="defer_state_input"
                label=""
                options={groupingColumnNameList}
                size="medium"
                initialValue={groupingColumnNameList.find(g => g.value === groupingColumnName)}
                onChange={(e) => {
                  if (e) {
                    setGroupingColumnName(e.value)
                  }
                }}
              />
            </div>
            <div className="marginLeft" style={{ width: '250px' }}>
              <SelectDropdown
                width="250px"
                domID="defer_state_input"
                label=""
                options={groupgingTypeList}
                size="medium"
                initialValue={groupgingTypeList.find(g => g.value === groupingTypeParent)}
                onChange={(e) => {
                  if (e) {
                    setGroupingTypeParent(e.value)
                  }
                }}
              />
            </div>
            <div className="marginLeft">
              <Checkbox
                ariaLabelledBy=""
                className=""
                dataTestId="chk-reversegroup"
                labelAfter=""
                labelBefore=""
                labelTooltipText="Reverse"
                labelTooltipWidth={100}
                name=""
                checked={groupingOrderReverseParent}
                onChange={(e, state) => setGroupingOrderReverseParent(state)}
                wrapperClass=""
              />
            </div>
          </div>
        ) : null}
        <StyledFlexDiv>
          <StyledTable isGroupedParent={isGroupedParent} width={width}>
            <thead style={{
              cursor: 'default', background: isGroupedParent ? 'white' : 'rgb(19, 19, 108)',
              color: isGroupedParent ? 'black' : 'white'
            }}>
              <tr>
                {
                  renderGroupedHeader()
                }
              </tr>
            </thead>
            {renderFunction}
          </StyledTable>
          {configurable ? (
            <>
              <StyleButton
                onClick={(e) => setConfigurationWindow(true)}>
                <Cog
                  className="settings"
                  fillColor="#37474F"
                  title="Settings"
                />
              </StyleButton>
              <Modal isOpen={configurationWindow}>
                <ModalHeader
                  title="Configure"
                  onClose={() => setConfigurationWindow(false)}
                />
                <ModalBody>
                  {configurationWindow ? (
                    <>
                      {groupingColumnNameList && groupingColumnNameList.length > 0 ? (
                        <>
                          {groupingColumnNameList.filter(x => x.label && x.value).map(grpname => (
                            <div style={{ marginLeft: '15px' }}>
                              <div className="displayFlex">
                                <div className="marginLeft">
                                  <Checkbox
                                    disabled={renderConfigurationCheckbox(grpname)}
                                    ariaLabelledBy=""
                                    className=""
                                    dataTestId="chk-reversegroup"
                                    labelAfter=""
                                    labelBefore=""
                                    labelTooltipText="Reverse"
                                    labelTooltipWidth={100}
                                    name=""
                                    checked={grpname.isSelected}
                                    onChange={(e, state) => onChangeIsSelectedColumnForDisplay(e, state, grpname)}
                                    wrapperClass=""
                                  />

                                </div>
                                <div className="marginLeft">
                                  <label>{grpname.label}</label>
                                </div>
                              </div>
                            </div>
                          ))}
                        </>
                      ) : null}
                    </>
                  ) : null}
                </ModalBody>
              </Modal>
            </>
          ) : null}
        </StyledFlexDiv>
      </div>
    </>
  )
}

export default ShowMoreNestedGridComponent
