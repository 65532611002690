import React from 'react';
import PropTypes from 'prop-types'
import { Redirect } from 'react-router';
import { useSelector } from 'react-redux';
import SecureRotue from '../SecureRoute';

const HyperSecureRotue = ({ component }) => {
  const context = useSelector(store => store?.context?.context?.accessEntryId)
  const { passwordExpired } = useSelector(({ session }) => session)
  
  if (!context) return <Redirect to="/choose-context"/>
  if (passwordExpired && context) return <Redirect to="/change-password"/>
  if (context && !passwordExpired ) return <SecureRotue component={component} />
}

HyperSecureRotue.propTypes = {
  component: PropTypes.node.isRequired
}

export default HyperSecureRotue;