/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable prefer-template */
/* eslint-disable operator-assignment */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
import React, {
  useState,
  useEffect,
  useRef
} from 'react';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom'
import { useDispatch, } from 'react-redux';
import Datetime from 'react-datetime';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import {
  useLayout,
  Input, Button, Checkbox
} from 'ui-core/dist'
import axios from '../../axios';
import { LoadingPage } from '../../components';
import ErrorPage from '../ErrorPage';
import EmployerGroup from '../../components/EmployerGroup';
import ListPaneHeaderWrapper from '../../components/ErrorDisplaySection/ListPaneHeaderWrapper';
import RangeTextboxControl from '../../components/RangeTextboxControl';

import './Modules/styles.scss';
import MultipleSelectDropdown from '../../components/MultipleSelectDropdown';
import { setErrors } from '../../components/ErrorDisplaySection/modules/action';
import withAxiosCancellation from '../../components/withAxiosCancellation'
import { useFormNumbers } from '../../api/useDocumentManager';
import { setDocumentSearchParams, resetDocumentSearchParams, getCkeyXML, resetRecord, resetDocumentSearchMetadata } from './Modules/action'
import { CLAIM_TYPE, CLAIM_TYPE_NOT_AVAILABLE, DOCUMENT_TYPE, DOCUMENT_TYPE_LIST_NOT_AVAILABLE, FORM_NUMBER_LIST_NOT_AVAILABLE, RECIPIENT_TYPE, RECIPIENT_TYPE_LIST_NOT_AVAILABLE } from './Modules/constants';
import useHandleEnterKeyPressEvent from './Modules/useHandleEnterKeyPressEvent';

// EXAMPLE
// TODO: REPLACE ME WITH REAL CODE LATER
// https://prod.storybooks.awsprod.healthcareit.net/?path=/story/layouts-page-layout--getting-started

const StyledTableContainer = styled.div`
 &>table>tr>td:first-child{
  width:150px;
}
`

const DocumentSearchMain = ({getCancelSourceWithName}) => {
  const {
    expandCollapseListPaneButtonConfig
  } = useLayout()
  const history = useHistory();
  const dispatch = useDispatch()

  const { data, isLoading, isError, error } = useFormNumbers();
  const originalStartDate = useRef();
  const originalEndDate = useRef();
  useEffect(() => {
    dispatch(resetDocumentSearchParams())
    dispatch(resetDocumentSearchMetadata())
    dispatch(resetRecord())
  }, [])

  useEffect(() => {
    if (data) {
      let { dateItems } = data;
      const { formnames } = data;
      dateItems = dateItems || {};
      if (originalStartDate && originalEndDate) {
        originalStartDate.current = dateItems.startImportDate || "";
        originalEndDate.current = dateItems.endImportDate || "";
      }
      setStartDate(moment(dateItems.startImportDate || ""))
      setEndDate(moment(dateItems.endImportDate || ""))
      setSearchDescription(dateItems.searchDescription || "")
      setFormNames(JSON.parse(JSON.stringify(formnames)))
    }
  }, [data])

  const [documentKeyLableColor, setDocumentKeyLabelColor] = useState('#626D8A')
  const [paymentIdentifierLableColor, setPaymentIdentifierLabelColor] = useState('#626D8A')
  const [documentKeyDisabled, setDocumentKeyDisabled] = useState(false);
  const [paymentIdentifierDisabled, setPaymentIdentifierDisabled] = useState(false);

  const [claimNumber1MaxLength, setClaimNumber1MaxLength] = useState(3000)
  const [claimNumber2MaxLength, setClaimNumber2MaxLength] = useState(30)
  const [checkNumber1MaxLength, setCheckNumber1MaxLength] = useState(3000)
  const [checkNumber2MaxLength, setCheckNumber2MaxLength] = useState(21)

  const [claimNumber1, setClaimNumber1] = useState('');
  const [claimNumber2, setClaimNumber2] = useState('');
  const [claimNumberMode, setClaimNumberMode] = useState('');

  const [checkNumber1, setCheckNumber1] = useState('');
  const [checkNumber2, setCheckNumber2] = useState('');
  const [checkNumberMode, setCheckNumberMode] = useState('');

  // const [employerGroupLOV,setEmployerGroupLOV]=useState(true);

  const [searchDescription, setSearchDescription] = useState('');
  const [searchDescriptionShow, setSearchDescriptionShow] = useState(false)
  const [startImportDateError, setStartImportDateError] = useState(false)
  const [endImportDateError, setEndImportDateError] = useState(false)
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [formNames, setFormNames] = useState([]);

  const [employerGroup, setEmployerGroup] = useState('');
  const [recipientType, setRecipientType] = useState([]);
  const [documentType, setDocumentType] = useState([]);
  const [claimType, setClaimType] = useState([]);
  const [formNumber, setFormNumber] = useState([]);
  const [tinSSN, setTinSSN] = useState('');
  const [documentKey, setDocumentKey] = useState('');
  const [paymentIdentifier, setPaymentIdentifier] = useState('');
  const [routingCode, setRoutingCode] = useState('');
  const [voucherNumber, setVoucherNumber] = useState('');
  const [processorID, setProcessorID] = useState('');
  const [providerState, setProviderState] = useState('');
  const [insuredPolicy, setInsuredPolicy] = useState('');
  const [patientID, setPatientID] = useState('');
  const [insuredState, setInsuredState] = useState('');
  const [benefitYear, setBenefitYear] = useState('');

  const [releaseDateStartError, setReleaseDateStartError] = useState(false)
  const [releaseDateEndError, setReleaseDateEndError] = useState(false)
  const [startReleasedDate, setReleasedStartDate] = useState('');
  const [endReleasedDate, setReleasedEndDate] = useState('');

  const [tinssnExactMatch, setTinSSNExactmatch] = useState(false);
  const [voucherNumberExactMatch, setVoucherNumberExactmatch] = useState(false);
  const [processorIdExactMatch, setProcessorIdExactmatch] = useState(false);
  const [insuredPolicyExactMatch, setInsuredPolicyExactmatch] = useState(false);
  const [patientIdExactMatch, setPatientIdExactmatch] = useState(false);
  const [hasLimitExceededstate, sethasLimitExceededstate] = useState(false)

  const [isResetFlag, setIsResetFlag] = useState(false);

  const [datesError, setDatesError] = useState(false)
  const [errMsg, setErrMsg] = useState(false)

  const [releaseDatesError, setReleaseDatesError] = useState(false)
  const [releaseErrMsg, setReleaseErrMsg] = useState(false)

  const [patientFirstName, setPatientFirstName] = useState('');
  const [patientLastName, setPatientLastName] = useState('');
  const [recipientName, setRecipientName] = useState('');
  const [checkAmount, setCheckAmount] = useState('');

  const TIN_SSN_MAX_LENGTH = 30;
  const VOUCHER_NUMBER_MAX_LENGTH = 16;
  const PROCESSOR_ID_MAX_LENGTH = 5;
  const INSURED_POLICY_NUMBER_MAX_LENGTH = 20;
  const PATIENT_ID_MAX_LENGTH = 15;

  const listOfDataPayload = {
    "userId": process.env.REACT_APP_USER_ID, "client": "1848", "layout": "028",
    "accessEntryId": process.env.REACT_APP_ACCESS_ENTRY_ID, "description": "1848_028 - HealthPlan Services a Wipro Company",
    "ticket": process.env.REACT_APP_TICKET, "dataSourceDefinition": "/1848/028/*",
    "roleId": process.env.REACT_APP_ROLE_ID,
    "maxRecords": "10",
    "rowPosition": "0",
    "sortField": 'id',
    "viewInactive": false,
    "sortDescending": 'true',
    "parameters": ""
  }

  function validateDate(dtValue) {
    if (dtValue === "") {
      return true;
    }
    const dtRegex = new RegExp(/\b\d{1,2}[/-]\d{1,2}[/-]\d{4}\b/);
    if (dtRegex.test(dtValue)) {
      const splitted = dtValue.split("/");
      if (splitted[0] < 0 || splitted[0] > 12) {
        return false;
      }
      if (splitted[1] < 0 || splitted[1] > 31) {
        return false;
      }
    }
    return true;

  }

  function fixSearchParams(callback) {
    let check_number1 = checkNumber1.replace(/\*/g, "").replace(/^\s+|\s+$/g, "")
    const check_number2 = checkNumber2.replace(/\*/g, "").replace(/^\s+|\s+$/g, "")

    let claim_number1 = claimNumber1.replace(/\*/g, "").replace(/^\s+|\s+$/g, "")
    const claim_number2 = claimNumber2.replace(/\*/g, "").replace(/^\s+|\s+$/g, "")

    let id_number = tinSSN.replace(/\*/g, "").replace(/^\s+|\s+$/g, "")
    let voucher_number = voucherNumber.replace(/\*/g, "").replace(/^\s+|\s+$/g, "")
    let processor_id = processorID.replace(/\*/g, "").replace(/^\s+|\s+$/g, "")
    let insured_policy_number = insuredPolicy.replace(/\*/g, "").replace(/^\s+|\s+$/g, "")
    let patient_id = patientID.replace(/\*/g, "").replace(/^\s+|\s+$/g, "")

    if ((checkNumberMode === 'Partial') && (check_number1 !== '')
      && (check_number2 === '')) {
      check_number1 = check_number1 + '*'
      setCheckNumber1(check_number1)
      setCheckNumber2(check_number2)
    }

    if ((claimNumberMode === 'Partial') && (claim_number1 !== '')
      && (claim_number2 === '')) {
      claim_number1 = claim_number1 + '*'
      setClaimNumber1(claim_number1)
      setClaimNumber2(claim_number2)
    }

    if (tinssnExactMatch && (id_number.length !== TIN_SSN_MAX_LENGTH)
      && (id_number !== '')) {
      setTinSSN(id_number + String.fromCharCode(9));
      id_number = id_number + String.fromCharCode(9);
    }
    if (voucherNumberExactMatch && (voucher_number.length !== VOUCHER_NUMBER_MAX_LENGTH)
      && voucher_number !== '') {
      setVoucherNumber(voucher_number)
      voucher_number = voucher_number;
    }
    if (processorIdExactMatch && (processor_id.length !== PROCESSOR_ID_MAX_LENGTH)
      && processor_id !== '') {
      setProcessorID(processor_id)
      processor_id = processor_id;
    }
    if (insuredPolicyExactMatch && (insured_policy_number.length !== INSURED_POLICY_NUMBER_MAX_LENGTH)
      && insured_policy_number !== '') {
      setInsuredPolicy(insured_policy_number)
      insured_policy_number = insured_policy_number;
    }
    if (patientIdExactMatch && (patient_id.length !== PATIENT_ID_MAX_LENGTH)
      && patient_id !== '') {
      setPatientID(patient_id)
      patient_id = patient_id;
    }
    if (callback) callback({ id_number, voucher_number, processor_id, insured_policy_number, patient_id })
    return validateFields();
  }

  function validateFields() {
    let bValid = true;
    const errors = [];
    // Validate any "new" required fields here before submitting form.
    // Document Key
    // if (!((documentKey.length === 0) || (documentKey.length >= 17 && documentKey.length <= 23))) {
    //   errors.push("Document key length must be in the range of 17 to 23 characters");
    //   bValid = false;
    // }
    // Card Token ID
    if (paymentIdentifier.length > 30) {
      errors.push("Payment Identifier length cannot exceed 30 characters");
      bValid = false;
    }

    dispatch(setErrors({ errors }))
    return bValid;
  }

  function setAllowedFields(event, currentTarget) {
    const { id } = currentTarget
    let value;
    switch (id) {
      case "inputDocumentKey":
        if (documentKey !== "") {
          setPaymentIdentifierLabelColor("#c3c5cd")
          setPaymentIdentifierDisabled(true);
        }
        else {
          setPaymentIdentifierLabelColor("#626D8A")
          setPaymentIdentifierDisabled(false);
        }
        value = documentKey;
        break;
      case "inputPaymentIdentifier":
        if (paymentIdentifier !== "") {
          setDocumentKeyLabelColor("#c3c5cd")
          setDocumentKeyDisabled(true);
        }
        else {
          setDocumentKeyLabelColor("#626D8A")
          setDocumentKeyDisabled(false);
        }
        value = paymentIdentifier;
        break;
      default:
        break;
    }
    handleEnterKeyPressEvent(event, id, value);
  }

  useEffect(() => {
    setDocumentKeyLabelColor("#626D8A")
    setDocumentKeyDisabled(false);
    if (claimNumberMode === "Exact" || claimNumberMode === "Partial") {
      setClaimNumber1MaxLength('3000')
    }
    else if (claimNumberMode === "Range") {
      setClaimNumber2MaxLength('30')
      setDocumentKeyLabelColor("#c3c5cd")
      setDocumentKeyDisabled(true);
    }
  }, [claimNumberMode])

  useEffect(() => {
    setDocumentKeyLabelColor("#626D8A")
    setDocumentKeyDisabled(false);
    if (checkNumberMode === "Exact" || checkNumberMode === "Partial") {
      setCheckNumber1MaxLength('3000');
    }
    else if (checkNumberMode === "Range") {
      setCheckNumber2MaxLength('21');
      setDocumentKeyLabelColor("#c3c5cd")
      setDocumentKeyDisabled(true);
    }
  }, [checkNumberMode])

  function clearTableName() {
    // document.forms[0].tableName = "";
  }

  const onClearFields = () => {
    setIsResetFlag(true)
    setPaymentIdentifierLabelColor("#626D8A")
    setDocumentKeyLabelColor("#626D8A")
    setDocumentKeyDisabled(false);
    setPaymentIdentifierDisabled(false);

    setClaimNumber1('');
    setClaimNumber2('');
    setCheckNumber1('');
    setCheckNumber2('');
    setClaimNumberMode('Exact');
    setCheckNumberMode('Exact');

    setSearchDescriptionShow(true)
    setStartImportDateError(false)
    setEndImportDateError(false)

    if (originalStartDate) setStartDate(moment(originalStartDate.current));
    if (originalEndDate) setEndDate(moment(originalEndDate.current));

    setEmployerGroup(hasLimitExceeded ? '' : []);
    setRecipientType([]);
    setDocumentType([]);
    setClaimType([]);
    setFormNumber([]);
    setTinSSN('');
    setDocumentKey('');
    setPaymentIdentifier('');
    setRoutingCode('');
    setVoucherNumber('');
    setProcessorID('');
    setProviderState('');
    setInsuredPolicy('');
    setPatientID('');
    setInsuredState('');
    setBenefitYear('');

    setReleaseDateStartError(false)
    setReleaseDateEndError(false)
    setReleasedStartDate('');
    setReleasedEndDate('');

    setTinSSNExactmatch(false);
    setVoucherNumberExactmatch(false);
    setProcessorIdExactmatch(false);
    setInsuredPolicyExactmatch(false);
    setPatientIdExactmatch(false);

    setDatesError(false);
    setReleaseDatesError(false);
  }

  function updateCheckNumber(rc, checkNumber) {
    if (rc.length === 2) {
      return rc + checkNumber;
    }
    if (rc.length === 1) {
      // Pad a space if routing code length is 1
      return rc + " " + checkNumber;
    }
    // Should never be greater than 2, but if it is, then treat it as blank
    return "  " + checkNumber;
  }

  const getRecipientTypesXml = () => {
    if (recipientType && recipientType.length > 0 && recipientType.indexOf('') === -1) {
      let xml = '<![CDATA[<recipientTypes>'
      for (const item of recipientType) {
        xml = xml + `<recipientType>${item}</recipientType>`
      }
      xml = xml + '</recipientTypes>]]>'
      return xml;
    }
    return "";
  }
  const getDocumentTypesXml = () => {
    if (documentType && documentType.length > 0 && documentType.indexOf('') === -1) {
      let xml = `<![CDATA[<documentTypes>`
      for (const item of documentType) {
        xml = xml + `<documentType>${item}</documentType>`
      }
      xml = xml + '</documentTypes>]]>'
      return xml;
    }
    return "";
  }
  const hasLimitExceeded = (value) => {
    sethasLimitExceededstate(value)
  }
  const getCKey = async (strTokenID) => {
    // We initialize to x's to result in a non-existant documentkey if tokenID is invalid.
    let strResponse = "XXXXXXXXXXXXXXXX";
    const sActionXML = getCkeyXML(strTokenID);
    const strResponseXML = await axios.post('genericInterface/execute', { sActionXML })
    if (strResponseXML && strResponseXML.status === 200 && strResponseXML.data
      && strResponseXML.data.CCI && strResponseXML.data.CCI.CCI_Payment
      && strResponseXML.data.CCI.CCI_Payment.$) {
      const { ckey } = strResponseXML.data.CCI.CCI_Payment.$;
      strResponse = ckey;
    }
    return strResponse;
  }
  const onNextClick = async () => {
    let idNumberP; let voucherNumberP; let processorIdP; let insuredPolicyNumberP; let patientIdP;
    if (fixSearchParams(({ id_number, voucher_number, processor_id, insured_policy_number, patient_id }) => {
      idNumberP = id_number;
      voucherNumberP = voucher_number;
      processorIdP = processor_id;
      insuredPolicyNumberP = insured_policy_number;
      patientIdP = patient_id
    })) {
      let newchecknumber = checkNumber1;
      if (!checkNumber2) {
        newchecknumber = updateCheckNumber(routingCode, checkNumber1)
      }
      let fDocumentKey = documentKey;
      if (paymentIdentifier) {
        fDocumentKey = await getCKey(paymentIdentifier)
      }
      let egroup;
      // if (hasLimitExceededstate) {
      egroup = employerGroup.value || ""
      // } else {
      //   egroup = employerGroup && employerGroup.length > 0 ? employerGroup.map((e) => e.value || "") : ""
      // }
      const newbenefityear = benefitYear;
      let newClaimType = '';
      let newFormNumber = '';
      if (claimType && claimType.length > 0 && claimType.indexOf('') === -1) {
        newClaimType = `${claimType.join('|')}|`;
      }

      if (formNumber && formNumber.length > 0 && formNumber.indexOf('') === -1) {
        newFormNumber = `${formNumber.join('|')}|`;
      }

      // const filekey='';
      const payload = {
        groupId: egroup || "",
        recipientType: getRecipientTypesXml(),
        idNumber: idNumberP,
        tableName: '',
        insuredPolicyNumber: insuredPolicyNumberP,
        insuredState,
        patientId: patientIdP,
        processorId: processorIdP,
        providerStateCode: providerState,
        documentType: getDocumentTypesXml(),
        filekey: '',
        documentKey: fDocumentKey,
        voucherNumber: voucherNumberP,
        formNumber: newFormNumber,
        claimTypeId: newClaimType,
        checkNumber: newchecknumber.trim() || "",
        claimNumber: claimNumber1.replace(/['"]/g, ''),
        domXml: ""
      };
      if (newchecknumber && checkNumber2 && checkNumberMode === "Range") {
        payload.checkNumber = payload.checkNumber.trim() + "@~@" + checkNumber2.trim();
      }
      if (checkNumberMode === "Partial" && payload.checkNumber.trim() !== "") {
        payload.checkNumber = `${payload.checkNumber}*`;
      }
      // Remove quote from claimNumber2 as well
      if (claimNumber1 && claimNumber2 && claimNumberMode === "Range") {
        payload.claimNumber = payload.claimNumber.trim() + "@~@" + claimNumber2.trim().replace(/['"]/g, '');
      }
      if (claimNumberMode === "Partial" && payload.claimNumber.trim() !== "") {
        payload.claimNumber = `${payload.claimNumber}*`;
      }
      payload.benefitYear = `${startDate ? moment(startDate).format("MM/DD/YYYY") : ''}|${endDate ? moment(endDate).format("MM/DD/YYYY") : ''}|${newbenefityear}|`
      // 09/23/2011|09/23/2021||
      // (new StringBuilder(startImportDate)).append("|").append(endImportDate).append("|").append(benefitYear).append("|").toString();
      if (startReleasedDate) {
        payload.releaseDateStart = `${moment(startReleasedDate).format('YYYY-MM-DD')}T06:00:00Z`
      }
      if (endReleasedDate) {
        payload.releaseDateEnd = `${moment(endReleasedDate).format('YYYY-MM-DD')}T06:00:00Z`
      }
      payload.showDuplicateChecks = true;
      payload.showProcessedDocuments = true;   
      

      if (patientFirstName) {
        payload.patientFirstName = patientFirstName.trim();
      }
      if (patientLastName) {
        payload.patientLastName = patientLastName.trim();
      }
      if (recipientName) {
        payload.recipientName = recipientName.trim();
      }
      if (checkAmount) {
        payload.checkAmount = checkAmount.trim();
      }

      dispatch(setDocumentSearchParams(payload))
      history.push('/DocumentManager/SearchResults')
    }
  }

  const config = {
    fields: [{
      fieldName: 'inputDocumentKey',
      validator: (value) => value.length === 17 || value.length === 23
    }, {
      fieldName: 'inputPaymentIdentifier',
      validator: (value) => value.length === 30
    }, {
      fieldName: 'tinSSN',
      validator: (value) => tinssnExactMatch ? (value.length === TIN_SSN_MAX_LENGTH) : (value.length > 0 && value.length <= TIN_SSN_MAX_LENGTH)
    }, {
      fieldName: 'routingCode',
      validator: (value) => value.length === 2
    }, {
      fieldName: 'voucherNumber',
      validator: (value) => voucherNumberExactMatch ? (value.length === VOUCHER_NUMBER_MAX_LENGTH) : (value.length > 0 && value.length <= VOUCHER_NUMBER_MAX_LENGTH)
    }, {
      fieldName: 'processorID',
      validator: (value) => processorIdExactMatch ? (value.length === PROCESSOR_ID_MAX_LENGTH) : (value.length > 0 && value.length <= PROCESSOR_ID_MAX_LENGTH)
    }, {
      fieldName: 'providerState',
      validator: (value) => value.length === 2
    }, {
      fieldName: 'insuredPolicy',
      validator: (value) => insuredPolicyExactMatch ? (value.length === INSURED_POLICY_NUMBER_MAX_LENGTH) : (value.length > 0 && value.length <= INSURED_POLICY_NUMBER_MAX_LENGTH)
    }, {
      fieldName: 'patientID',
      validator: (value) => patientIdExactMatch ? (value.length === PATIENT_ID_MAX_LENGTH) : (value.length > 0 && value.length <= PATIENT_ID_MAX_LENGTH)
    }, {
      fieldName: 'insuredState',
      validator: (value) => value.length === 2
    }, {
      fieldName: 'benefitYear',
      validator: (value) => value.length === 4
    }],
    onEnterKeyPressHandler: onNextClick
  }
  const { handleEnterKeyPressEvent } = useHandleEnterKeyPressEvent(config)

  const renderchecknumberlabel = () => (
    <div>
      <label data-tip data-for="lblCheckNumber">
        Check Number:&nbsp;<strong><font color='#bf1616'>*</font></strong>
        <br />
        <strong>
          <i style={{ color: '#bf1616' }}><font color='#bf1616'>*</font>
      &nbsp;Exclude preceding zeros</i><br />
        </strong>
      </label>
      <ReactTooltip id="lblCheckNumber" type='dark' effect='solid'>
        <span>Payer generated check number</span>
      </ReactTooltip>
    </div>
  )
  const renderClaimNumberLabel = (elem) => (
    <td>{elem}</td>
  )

  const importStartDateValidation = (currentDate) => {
    let err = false;
    if (moment(currentDate).isAfter(moment(endDate).startOf('day'), 'day')) {
      err = true;
    } else if (moment(currentDate).isAfter(moment().startOf('day'), 'day')) {
      err = true;
    }

    return !err;
  };

  const importEndDateValidation = (currentDate) => {
    let err = false;
    if (moment(currentDate).isAfter(moment().startOf('day'), 'day')) {
      err = true;
    } else if (moment(currentDate).isBefore(moment(startDate).startOf('day'), 'day')) {
      err = true;
    }

    return !err;
  };

  const releaseStartDateValidation = (currentDate) => {
    let err = false;
    if (moment(currentDate).isAfter(moment(endReleasedDate).startOf('day'), 'day')) {
      err = true;
    } else if (moment(currentDate).isAfter(moment().startOf('day'), 'day')) {
      err = true;
    }

    return !err;
  };

  const releaseEndDateValidation = (currentDate) => {
    let err = false;
    if (moment(currentDate).isAfter(moment().startOf('day'), 'day')) {
      err = true;
    } else if (moment(currentDate).isBefore(moment(startReleasedDate).startOf('day'), 'day')) {
      err = true;
    }

    return !err;
  };

  const onImportStartDateChange = (val) => {
    let err = false;
    let errMessage = '';

    if (val === '') {
      setDatesError(false);
      setStartDate('');
      return;
    }

    if (!moment(val, 'MM/DD/YYYY', true).isValid()) {
      errMessage = 'Start date is invalid(mm/dd/yyyy).';
      err = true;
    } else if (moment(val).isAfter(moment(endDate))) {
      errMessage = 'Start date should be earlier than the end date.';
      err = true;
    } else if (moment(val).isAfter(moment().startOf('day'), 'day')) {
      errMessage = 'Start date is invalid(mm/dd/yyyy, max date is today).';
      err = true;
    }

    if (err) {
      setDatesError(true);
      setErrMsg(errMessage);
      setStartDate(val);
    } else {
      setDatesError(false);
      setStartDate(val.format("MM/DD/YYYY"));
    }
  }

  const onImportEndDateChange = (val) => {
    let err = false;
    let errMessage = '';

    if (val === '') {
      setDatesError(false);
      setEndDate('');
      return;
    }

    if (!moment(val, 'MM/DD/YYYY', true).isValid()) {
      err = true;
      errMessage = 'End date is invalid(mm/dd/yyyy).'
    } else if (moment(val).isBefore(moment(startDate))) {
      errMessage = 'End date should be greater than the start date.';
      err = true;
    } else if (moment(val).isAfter(moment().startOf('day'), 'day')) {
      errMessage = 'End date is invalid(mm/dd/yyyy, max date is today).';
      err = true;
    }

    if (err) {
      setDatesError(true);
      setErrMsg(errMessage);
      setEndDate(val);
    } else {
      setDatesError(false);
      setEndDate(val.format("MM/DD/YYYY"));
    }
  }

  const onReleaseStartDateChange = (val) => {
    let err = false;
    let errMessage = '';

    if (val === '') {
      setReleaseDatesError(false);
      setReleasedStartDate('');
      return;
    }

    if (!moment(val, 'MM/DD/YYYY', true).isValid()) {
      errMessage = 'Start date is invalid(mm/dd/yyyy).';
      err = true;
    } else if (moment(val).isAfter(moment(endReleasedDate))) {
      errMessage = 'Start date should be earlier than the end date.';
      err = true;
    } else if (moment(val).isAfter(moment().startOf('day'), 'day')) {
      errMessage = 'Start date is invalid(mm/dd/yyyy, max date is today).';
      err = true;
    }

    if (err) {
      setReleaseDatesError(true);
      setReleaseErrMsg(errMessage);
      setReleasedStartDate(val);
    } else {
      setReleaseDatesError(false);
      setReleasedStartDate(val.format("MM/DD/YYYY"));
    }
  }

  const onReleaseEndDateChange = (val) => {
    let err = false;
    let errMessage = '';

    if (val === '') {
      setReleaseDatesError(false);
      setReleasedEndDate('');
      return;
    }

    if (!moment(val, 'MM/DD/YYYY', true).isValid()) {
      err = true;
      errMessage = 'End date is invalid(mm/dd/yyyy).'
    } else if (moment(val).isBefore(moment(startReleasedDate))) {
      errMessage = 'End date should be greater than the start date.';
      err = true;
    } else if (moment(val).isAfter(moment().startOf('day'), 'day')) {
      errMessage = 'End date is invalid(mm/dd/yyyy, max date is today).';
      err = true;
    }

    if (err) {
      setReleaseDatesError(true);
      setReleaseErrMsg(errMessage);
      setReleasedEndDate(val);
    } else {
      setReleaseDatesError(false);
      setReleasedEndDate(val.format("MM/DD/YYYY"));
    }
  }

  // const isLoading = false;
  // const isError = false;
  // const error = '';
  return (
    <>
      <ListPaneHeaderWrapper
        title="Document Manager: Search Documents"
        layoutControls={[expandCollapseListPaneButtonConfig]}

      />
      {isLoading ? (
        // replace with a better loading component
        <LoadingPage />
      ) : isError ? (
        // replace with a better error component
        <ErrorPage
          // this prop isn't real but is an example
          error={error}
        />
      ) : (
            <div className="marginLeft" style={{ marginLeft: '45px' }}>
              {/* {releaseTime && currentTime ?
            <CountDownTimer
              startDate={releaseTime}
              endDate={currentTime}
            /> : ''} */}
              <div className="displayFlex">
                <label>Document search finds documents that match the search options you complete, whether or not the documents have been processed. Complete one or a combination of fields on the search form. Click Next to begin the search.</label>
              </div>
              <div className="displayFlex">
                <label> Import Date Range: </label>
                <div className="marginLeft msgDatePicker" style={{ marginLeft: '44px' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Datetime
                      onChange={onImportStartDateChange}
                      // inputProps={{ readOnly: true }}
                      closeOnSelect={true}
                      isValidDate={importStartDateValidation}
                      dateFormat="MM/DD/YYYY"
                      value={startDate}
                      initialValue={startDate}
                      initialViewDate={startDate}
                      timeFormat={false}
                    /> <span style={{ padding: '0 3px' }}>-</span>
                    <Datetime
                      onChange={onImportEndDateChange}
                      // inputProps={{ readOnly: true }}
                      closeOnSelect={true}
                      isValidDate={importEndDateValidation}
                      dateFormat="MM/DD/YYYY"
                      value={endDate}
                      initialValue={endDate}
                      initialViewDate={endDate}
                      timeFormat={false}
                    />
                    {datesError && <span style={{ fontSize: '13px', color: 'red', marginLeft: '20px' }}>{errMsg}</span>}
                  </div>
                  <div className="marginLeft">
                    {searchDescriptionShow ? (
                      <span id="searchDescription">&nbsp;&nbsp;&nbsp;{searchDescription}</span>
                    ) : null}

                    <br />
                    {startImportDateError ? (
                      <span id="startImportDateError" style={{ fontSize: '10pt', color: 'red' }}>Initial date is invalid(mm/dd/yyyy).&nbsp;</span>
                    ) : null}
                    {endImportDateError ? (
                      <span id="endImportDateError" style={{ fontSize: '10pt', color: 'red' }}>Final date is invalid(mm/dd/yyyy).&nbsp;</span>
                    ) : null}

                  </div>
                </div>
              </div>

              <StyledTableContainer>
                <table>
                  <tr id="claimNumberRange">
                    <RangeTextboxControl
                      uniqueId="first"
                      label="Claim Number"
                      defaultMode="Exact"
                      labelContainer={renderClaimNumberLabel}
                      inputContainer={renderClaimNumberLabel}
                      textbox1maxlength={claimNumber1MaxLength}
                      textbox2maxlength={claimNumber2MaxLength}
                      initialValueTextBox1={claimNumber1}
                      initialValueTextBox2={claimNumber2}
                      onSetValue={({ textbox1value, textbox2value, mode }) => {
                        clearTableName()
                        setClaimNumber1(textbox1value)
                        setClaimNumber2(textbox2value)
                        setClaimNumberMode(mode)
                      }}
                      onEnterValue={onNextClick}
                      isResetField={isResetFlag}
                      setIsResetFlag={setIsResetFlag}
                    />
                  </tr>
                  <tr id="checkNumberRange">
                    <RangeTextboxControl
                      uniqueId="second"
                      label={
                        renderchecknumberlabel()
                      }
                      defaultMode="Exact"
                      labelContainer={renderClaimNumberLabel}
                      inputContainer={renderClaimNumberLabel}
                      textbox1maxlength={checkNumber1MaxLength}
                      textbox2maxlength={checkNumber2MaxLength}
                      initialValueTextBox1={checkNumber1}
                      initialValueTextBox2={checkNumber2}
                      onSetValue={({ textbox1value, textbox2value, mode }) => {
                        clearTableName()
                        setCheckNumber1(textbox1value)
                        setCheckNumber2(textbox2value)
                        setCheckNumberMode(mode)
                      }}
                      onEnterValue={onNextClick}
                      isResetField={isResetFlag}
                      setIsResetFlag={setIsResetFlag}
                    />
                  </tr>
                  <tr id="employerGroup">
                    <EmployerGroup
                      getCancelSourceWithName={getCancelSourceWithName}
                      skipCheck
                      selectInputLabelWidth="100%"
                      selectInputWidth="350px"
                      labelContainer={renderClaimNumberLabel}
                      inputContainer={renderClaimNumberLabel}
                      hasLimitExceeded={hasLimitExceeded}
                      initialValue={employerGroup}
                      onSelect={(e) => {
                        setEmployerGroup(e)
                      }}
                      onSelectRow={(e) => {
                        setEmployerGroup(e)
                      }}
                    />
                  </tr>
                  <tr><p></p></tr>
                  <tr>

                    <td>
                      <label> TIN/SSN : </label>
                    </td>

                    <td>
                      <div className="displayFlex" style={{ marginTop: '10px' }}>
                        <Input
                          style={{ width: '200px' }}
                          className="test-classname"
                          domID="tinSSN"
                          placeholder=""
                          size="small"
                          maxLength={TIN_SSN_MAX_LENGTH}
                          initialValue={tinSSN}
                          onChange={(e) => {
                            clearTableName()
                            setTinSSN(e.target.value || "", "textbox1")
                          }}
                          onKeyUp={(e) => {
                            handleEnterKeyPressEvent(e, e?.currentTarget?.id, tinSSN)
                          }}
                        />
                        <div className="marginLeft">
                          <Checkbox
                            ariaLabelledBy=""
                            className=""
                            domID="chk-tinssn"
                            dataTestId="chk-tinssn"
                            labelAfter=""
                            labelBefore=""
                            labelTooltipText="TIN/SSN"
                            labelTooltipWidth={100}
                            name=""
                            checked={tinssnExactMatch}
                            onChange={(e, state) => setTinSSNExactmatch(state)}
                            wrapperClass=""
                          />
                        </div>
                      </div>
                    </td>


                  </tr>

                  <tr id="recipientTypeControl">
                    <MultipleSelectDropdown
                      getCancelSource={getCancelSourceWithName("recipentType")}
                      selectInputLabelWidth="100%"
                      selectInputWidth="250px"
                      labelContainer={renderClaimNumberLabel}
                      inputContainer={renderClaimNumberLabel}
                      methodType='POST'
                      id="recipientTypeDropdown"
                      fetchDataURL='discriminationTypes/buildXmlByType'
                      label='Recipent Type:'
                      errorMessage={RECIPIENT_TYPE_LIST_NOT_AVAILABLE}
                      listOfDataPayload={JSON.parse(JSON.stringify(listOfDataPayload))}
                      initialValue={recipientType}
                      effect={(value) => {
                        clearTableName()
                        setRecipientType(value)
                      }}
                      displayField='label'
                      valueField='value'
                      tagName={RECIPIENT_TYPE}
                      displayDefault
                    />
                  </tr>

                  <tr><p></p></tr>
                  <tr id="documentTypeControl">
                    <MultipleSelectDropdown
                      getCancelSource={getCancelSourceWithName('documentType')}
                      selectInputLabelWidth="100%"
                      selectInputWidth="250px"
                      labelContainer={renderClaimNumberLabel}
                      inputContainer={renderClaimNumberLabel}
                      methodType='POST'
                      fetchDataURL='discriminationTypes/buildXmlByType'
                      label='Document Type:'
                      id="documentTypeDropdown"
                      errorMessage={DOCUMENT_TYPE_LIST_NOT_AVAILABLE}
                      listOfDataPayload={JSON.parse(JSON.stringify(listOfDataPayload))}
                      initialValue={documentType}
                      effect={(value) => {
                        clearTableName()
                        setDocumentType(value)
                      }}
                      tagName={DOCUMENT_TYPE}
                      displayField='label'
                      valueField='value'
                      displayDefault
                    />
                  </tr>

                  <tr><p></p></tr>
                  <tr id="claimNumberControl">
                    <MultipleSelectDropdown
                      getCancelSource={getCancelSourceWithName('claimType')}
                      selectInputLabelWidth="100%"
                      selectInputWidth="250px"
                      labelContainer={renderClaimNumberLabel}
                      inputContainer={renderClaimNumberLabel}
                      id="claimTypeDropdown"
                      methodType='POST'
                      fetchDataURL='discriminationTypes/buildXmlByType'
                      label='Claim Type:'
                      errorMessage={CLAIM_TYPE_NOT_AVAILABLE}
                      listOfDataPayload={JSON.parse(JSON.stringify(listOfDataPayload))}
                      initialValue={claimType}
                      effect={(value) => {
                        clearTableName()
                        setClaimType(value)
                      }}
                      tagName={CLAIM_TYPE}
                      displayField='label'
                      valueField='value'
                      displayDefault
                    />
                  </tr>

                  <tr><p></p></tr>
                  <tr id="documentKeyControl">

                    <td>
                      <label style={{ color: documentKeyLableColor }}> Document Key : </label>
                    </td>

                    <td>
                      <div className="displayFlex">
                        <Input
                          style={{ width: '200px' }}
                          className="test-classname"
                          domID="inputDocumentKey"
                          placeholder=""
                          size="small"
                          maxLength={23}
                          disabled={documentKeyDisabled}
                          initialValue={documentKey}
                          hasError={!((documentKey.length === 0) || (documentKey.length >= 17 && documentKey.length <= 23))}
                          errorMessage="Document key length must be in the range of 17 to 23 characters"
                          onChange={(e) => {
                            const { value } = e.target;
                            clearTableName()
                            setDocumentKey(value || "")
                          }}
                          onKeyUp={(e) => {
                            setAllowedFields(e, e.currentTarget)
                          }}
                        />
                      </div>
                    </td>

                  </tr>

                  <tr>

                    <td>
                      <label data-tip data-for="lblTokenID" style={{ color: paymentIdentifierLableColor }}> Payment Identifier : </label>
                      <ReactTooltip id="lblTokenID" type='dark' effect='solid'>
                        <span>Search by VCC Card Token or Direct Payment Transaction Reference Number (TRN)</span>
                      </ReactTooltip>
                    </td>

                    <td>
                      <Input
                        style={{ width: '200px' }}
                        className="test-classname"
                        domID="inputPaymentIdentifier"
                        size="small"
                        maxLength={30}
                        placeholder=""
                        disabled={paymentIdentifierDisabled}
                        initialValue={paymentIdentifier}
                        onChange={(e) => {
                          clearTableName()
                          setPaymentIdentifier(e.target.value || "")
                        }}
                        onKeyUp={(e) => {
                          setAllowedFields(e, e.currentTarget)
                        }}
                      />
                    </td>

                  </tr>

                  <tr><p></p></tr>
                  <tr id="formNumberControl">
                    <MultipleSelectDropdown
                      getCancelSource={getCancelSourceWithName('FormNumber')}
                      selectInputLabelWidth="100%"
                      selectInputWidth="250px"
                      id="FormNumberDropdown"
                      labelContainer={renderClaimNumberLabel}
                      inputContainer={renderClaimNumberLabel}
                      methodType=''
                      fetchDataURL=''
                      label='Form Number:'
                      errorMessage={FORM_NUMBER_LIST_NOT_AVAILABLE}
                      pRecords={formNames}
                      initialValue={formNumber}
                      effect={(value) => {
                        clearTableName()
                        setFormNumber(JSON.parse(JSON.stringify(value)))
                      }}
                      displayField='label'
                      valueField='value'
                      displayDefault
                    />
                  </tr>

                  <tr><p></p></tr>
                  <tr>
                    <td>
                      <label> Routing Code : </label>
                    </td>
                    <td>
                      <Input
                        style={{ width: '200px' }}
                        className="test-classname"
                        domID="routingCode"
                        size="small"
                        maxLength={2}
                        placeholder=""
                        initialValue={routingCode}
                        onChange={(e) => {
                          clearTableName()
                          setRoutingCode(e.target.value || "")
                        }}
                        onKeyUp={(e) => {
                          handleEnterKeyPressEvent(e, e?.currentTarget?.id, routingCode)
                        }}
                      />
                    </td>
                  </tr>

                  <tr><p></p></tr>
                  <tr>
                    <td>
                      <label> Voucher Number : </label>
                    </td>
                    <td>
                      <div className="displayFlex">
                        <Input
                          style={{ width: '200px' }}
                          className="test-classname"
                          domID="voucherNumber"
                          size="small"
                          placeholder=""
                          maxLength={VOUCHER_NUMBER_MAX_LENGTH}
                          initialValue={voucherNumber}
                          onChange={(e) => {
                            clearTableName()
                            setVoucherNumber(e.target.value || "")
                          }}
                          onKeyUp={(e) => {
                            handleEnterKeyPressEvent(e, e?.currentTarget?.id, voucherNumber)
                          }}
                        />
                        <div className="marginLeft">
                          <Checkbox
                            ariaLabelledBy=""
                            className=""
                            domID="chk-voucherNumber"
                            dataTestId="chk-voucherNumber"
                            labelAfter=""
                            labelBefore=""
                            labelTooltipText="TIN/SSN"
                            labelTooltipWidth={100}
                            name=""
                            checked={voucherNumberExactMatch}
                            onChange={(e, state) => setVoucherNumberExactmatch(state)}
                            wrapperClass=""
                          />
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <label> Processor ID : </label>
                    </td>
                    <td>
                      <div className="displayFlex">
                        <Input
                          style={{ width: '200px' }}
                          className="test-classname"
                          domID="processorID"
                          size="small"
                          placeholder=""
                          maxLength={PROCESSOR_ID_MAX_LENGTH}
                          initialValue={processorID}
                          onChange={(e) => {
                            clearTableName()
                            setProcessorID(e.target.value || "", "textbox1")
                          }}
                          onKeyUp={(e) => {
                            handleEnterKeyPressEvent(e, e?.currentTarget?.id, processorID)
                          }}
                        />
                        <div className="marginLeft">
                          <Checkbox
                            ariaLabelledBy=""
                            className=""
                            domID="chk-processorID"
                            dataTestId="chk-processorID"
                            labelAfter=""
                            labelBefore=""
                            labelTooltipText="TIN/SSN"
                            labelTooltipWidth={100}
                            name=""
                            checked={processorIdExactMatch}
                            onChange={(e, state) => setProcessorIdExactmatch(state)}
                            wrapperClass=""
                          />
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <label> Provider State : </label>
                    </td>
                    <td>
                      <Input
                        style={{ width: '200px' }}
                        className="test-classname"
                        domID="providerState"
                        size="small"
                        maxLength={2}
                        placeholder=""
                        initialValue={providerState}
                        onChange={(e) => {
                          clearTableName()
                          setProviderState(e.target.value || "", "textbox1")
                        }}
                        onKeyUp={(e) => {
                          handleEnterKeyPressEvent(e, e?.currentTarget?.id, providerState)
                        }}
                      />
                    </td>
                  </tr>

                  <tr><p></p></tr>
                  <tr>
                    <td>
                      <label> Insured Policy # : </label>
                    </td>
                    <td>
                      <div className="displayFlex">
                        <Input
                          style={{ width: '200px' }}
                          className="test-classname"
                          domID="insuredPolicy"
                          size="small"
                          maxLength={INSURED_POLICY_NUMBER_MAX_LENGTH}
                          placeholder=""
                          initialValue={insuredPolicy}
                          onChange={(e) => {
                            clearTableName()
                            setInsuredPolicy(e.target.value || "", "textbox1")
                          }}
                          onKeyUp={(e) => {
                            handleEnterKeyPressEvent(e, e?.currentTarget?.id, insuredPolicy)
                          }}
                        />
                        <div className="marginLeft">
                          <Checkbox
                            ariaLabelledBy=""
                            className=""
                            domID="chk-insuredPolicy"
                            dataTestId="chk-insuredPolicy"
                            labelAfter=""
                            labelBefore=""
                            labelTooltipText="TIN/SSN"
                            labelTooltipWidth={100}
                            name=""
                            checked={insuredPolicyExactMatch}
                            onChange={(e, state) => setInsuredPolicyExactmatch(state)}
                            wrapperClass=""
                          />
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <label> Patient ID # : </label>
                    </td>
                    <td>
                      <div className="displayFlex">
                        <Input
                          style={{ width: '200px' }}
                          className="test-classname"
                          domID="patientID"
                          size="small"
                          placeholder=""
                          maxLength={PATIENT_ID_MAX_LENGTH}
                          initialValue={patientID}
                          onChange={(e) => {
                            clearTableName()
                            setPatientID(e.target.value || "", "textbox1")
                          }}
                          onKeyUp={(e) => {
                            handleEnterKeyPressEvent(e, e?.currentTarget?.id, patientID)
                          }}
                        />
                        <div className="marginLeft">
                          <Checkbox
                            domID="chk-patientIDCheckbox"
                            ariaLabelledBy=""
                            className=""
                            dataTestId="chk-patientID"
                            labelAfter=""
                            labelBefore=""
                            labelTooltipText="TIN/SSN"
                            labelTooltipWidth={100}
                            name=""
                            checked={patientIdExactMatch}
                            onChange={(e, state) => setPatientIdExactmatch(state)}
                            wrapperClass=""
                          />
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <label> Insured State # : </label>
                    </td>
                    <td>
                      <Input
                        style={{ width: '200px' }}
                        className="test-classname"
                        domID="insuredState"
                        size="small"
                        maxLength={2}
                        placeholder=""
                        initialValue={insuredState}
                        onChange={(e) => {
                          clearTableName()
                          setInsuredState(e.target.value || "", "textbox1")
                        }}
                        onKeyUp={(e) => {
                          handleEnterKeyPressEvent(e, e?.currentTarget?.id, insuredState)
                        }}
                      />
                    </td>
                  </tr>

                  <tr><p></p></tr>
                  <tr>
                    <td>
                      <label> Benefit Year : </label>
                    </td>
                    <td>
                      <Input
                        style={{ width: '200px' }}
                        className="test-classname"
                        domID="benefitYear"
                        size="small"
                        maxLength={4}
                        placeholder=""
                        initialValue={benefitYear}
                        onChange={(e) => {
                          clearTableName()
                          setBenefitYear(e.target.value || "", "textbox1")
                        }}
                        onKeyUp={(e) => {
                          handleEnterKeyPressEvent(e, e?.currentTarget?.id, benefitYear)
                        }}
                      />
                    </td>
                  </tr>

                  <tr><p></p></tr>
                  <tr>
                    <td>
                      <label> Patient First Name : </label>
                    </td>
                    <td>
                      <Input
                        style={{ width: '200px' }}
                        className="test-classname"
                        domID="patientFirstName"
                        size="small"
                        maxLength={40}
                        placeholder=""
                        initialValue={patientFirstName}
                        onChange={(e) => {
                          clearTableName()
                          setPatientFirstName(e.target.value || "", "textbox1")
                        }}
                        onKeyUp={(e) => {
                          handleEnterKeyPressEvent(e, e?.currentTarget?.id, patientFirstName)
                        }}
                      />
                    </td>
                  </tr>

                  <tr><p></p></tr>
                  <tr>
                    <td>
                      <label> Patient Last Name : </label>
                    </td>
                    <td>
                      <Input
                        style={{ width: '200px' }}
                        className="test-classname"
                        domID="patientLastName"
                        size="small"
                        maxLength={40}
                        placeholder=""
                        initialValue={patientLastName}
                        onChange={(e) => {
                          clearTableName()
                          setPatientLastName(e.target.value || "", "textbox1")
                        }}
                        onKeyUp={(e) => {
                          handleEnterKeyPressEvent(e, e?.currentTarget?.id, patientLastName)
                        }}
                      />
                    </td>
                  </tr>
                  <tr><p></p></tr>
                  <tr>
                    <td>
                      <label> Recipient Name : </label>
                    </td>
                    <td>
                      <Input
                        style={{ width: '200px' }}
                        className="test-classname"
                        domID="recipientName"
                        size="small"
                        maxLength={40}
                        placeholder=""
                        initialValue={recipientName}
                        onChange={(e) => {
                          clearTableName()
                          setRecipientName(e.target.value || "", "textbox1")
                        }}
                        onKeyUp={(e) => {
                          handleEnterKeyPressEvent(e, e?.currentTarget?.id, recipientName)
                        }}
                      />
                    </td>
                  </tr>

                  <tr><p></p></tr>
                  <tr>
                    <td>
                      <label> Check Amount : </label>
                    </td>
                    <td>
                      <Input
                        style={{ width: '200px' }}
                        className="test-classname"
                        domID="checkAmount"
                        size="small"
                        maxLength={23}
                        placeholder=""
                        initialValue={checkAmount}
                        onChange={(e) => {
                          clearTableName()
                          setCheckAmount(e.target.value || "", "textbox1")
                        }}
                        onKeyUp={(e) => {
                          handleEnterKeyPressEvent(e, e?.currentTarget?.id, checkAmount)
                        }}
                      />
                    </td>
                  </tr>

                </table>
              </StyledTableContainer>
              <div className="displayFlex releasedDatePicker" style={{ marginTop: '15px' }}>
                <label> Released Between : </label>
                <div className="marginLeft msgDatePicker" style={{ marginLeft: '43px' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Datetime
                      onChange={onReleaseStartDateChange}
                      // inputProps={{ readOnly: true }}
                      closeOnSelect={true}
                      isValidDate={releaseStartDateValidation}
                      dateFormat="MM/DD/YYYY"
                      value={startReleasedDate}
                      initialValue={startReleasedDate}
                      initialViewDate={startReleasedDate}
                      timeFormat={false}
                    /> <span style={{ padding: '0 3px' }}>-</span>
                    <Datetime
                      onChange={onReleaseEndDateChange}
                      // inputProps={{ readOnly: true }}
                      closeOnSelect={true}
                      isValidDate={releaseEndDateValidation}
                      dateFormat="MM/DD/YYYY"
                      value={endReleasedDate}
                      initialValue={endReleasedDate}
                      initialViewDate={endReleasedDate}
                      timeFormat={false}
                    />
                    {releaseDatesError && <span style={{ fontSize: '13px', color: 'red', marginLeft: '20px' }}>{releaseErrMsg}</span>}
                  </div>
                  <div className="marginLeft">
                    {releaseDateStartError ? (
                      <span id="releaseDateStartError" style={{ fontSize: '10pt', color: 'red' }}>Initial date is invalid(mm/dd/yyyy).&nbsp;</span>
                    ) : null}
                    {releaseDateEndError ? (
                      <span id="releaseDateEndError" style={{ fontSize: '10pt', color: 'red' }}>Final date is invalid(mm/dd/yyyy).&nbsp;</span>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="displayFlex">
                <table>
                  <tr>
                    <td style={{ width: '138px' }}>
                      <Button
                        domID="clearFieldsButton"
                        buttonType="standard"
                        size="small"
                        name="CLEAR FIELDS"
                        onClick={() => {
                          onClearFields();
                        }}
                      />
                    </td>
                    <td>
                      <Button
                        domID="nextButton"
                        buttonType="standard"
                        size="small"
                        style={{ marginLeft: '10px' }}
                        name="NEXT->"
                        disabled={datesError || releaseDatesError || !((documentKey.length === 0) || (documentKey.length >= 17 && documentKey.length <= 23))}
                        onClick={() => {
                          onNextClick();
                        }}
                      />
                    </td>
                  </tr>
                </table>

              </div>
            </div>
          )}
    </>
  )
}

export default withAxiosCancellation(DocumentSearchMain)
