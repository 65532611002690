import { useAxios } from '../hooks'

const useUserManagerAccess = ({ userId }) => {
  const { data, ...rest } = useAxios({
    url: `/members/${userId}/access`,
    method: 'GET',
  })

  return { data: data?.data, total: data?.totalRecords || undefined, ...rest}
}

export default useUserManagerAccess