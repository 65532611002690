
import styled from 'styled-components';

export const LoadingWrapper = styled.div`
float: left;
margin-left: -20px;
margin-top: 3px;
`;

export const WarningWrapper = styled.div `
display:inline-block;
margin-top: 30px;
`;

export const AssociationTextWrapper = styled.div `
margin-top: 30px;
`;