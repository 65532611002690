import { select, call, put, takeLatest } from 'redux-saga/effects';
import map from 'lodash/map';
import filter from 'lodash/filter';
import find from 'lodash/find';
import get from 'lodash/get';
import {
  getRulesForRuleTypesList,
  getGroupAssociationsForRuleIds,
  getRulesCountFromDB,
  getGroupAssociationsForRule,
  postGroupAssociationsForRuleTypeTrigger,
} from './network';
import { selectCurrentRuleRecordId } from '../../../containers/GroupDetailView/components/AdvancedTabs/LiveRuleTab/LiveGridWrapper/selectors';
import { getRuleTypesList } from '../ruleTypesList/network';
import { selectRuleTypesList } from '../../../containers/RulesGridView/selectors';
import { selectRuleTypesAccumRecords } from '../../../containers/TestAssocTabDetailsView/selectors';
import {
  allRulesForRuleTypesListReceived,
  allRulesForRuleTypesRequestError,
  allRulesForRuleTypesAssociationsListReceived,
  allRulesForRuleTypesAssociationsRequestError,
  triggerAllRulesForRuleTypesAssociationsRequest,
  rulesCountFromDBRecieved,
  rulesCountFromDBError,
  groupAssociationsForRuleIdSuccess,
  groupAssociationsForRuleIdError,
  groupAssociationsForRuleTypeSuccess,
  groupAssociationsForRuleTypeError,
  setIsViewingGroupAssociationsModal,
} from './actions';
import {
  ruleTypesListReceived,
  triggerUpdateRuleTypes,
} from '../ruleTypesList/actions';
import { addNotification } from '../../app/notificationState/actions';
import { setLoadingState } from '../../app/loadingState/actions';
import {
  ALL_RULES_FOR_RULETYPES_ASSOCIATIONS_REQUEST_TRIGGER,
  ALL_RULES_FOR_RULETYPES_REQUEST_TRIGGER,
  RULES_COUNT_FROM_DB_TRIGGER,
  GROUP_ASSOCIATIONS_FOR_RULE_ID_TRIGGER,
  POST_GROUP_ASSOCIATIONS_FOR_RULE_TYPE_TRIGGER,
} from './constants';

// This generator is the control flow manager that will step through the request
// lifecycle and dispatch actions to update the application at the end

function* rulesCountGenerator(action) {
  try {
    const userConfig = yield select(({ serverConfig }) => serverConfig);

    const response = yield call(
      getRulesCountFromDB,
      userConfig,
      action.payload,
    );

    const count = response.rulesCount || 0;
    yield put(
      rulesCountFromDBRecieved({
        count
      }),
    );
  } catch (thrownError) {
    yield put(
      addNotification({
        id: thrownError.errorId,
        type: 'negative',
        msg: thrownError.message,
      }),
    );
    yield put(rulesCountFromDBError(thrownError));
  }
}

function* rulesForRuleTypesListGenerator(action) {
  yield put(
    setLoadingState({
      id: 'rulesForRuleTypesGridLoading',
      isLoading: true,
    }),
  );
  // Before we make our network request we can dispatch actions to modify app
  // state, for example to show a spinner:
  // yield put(AppActions.startWaiting());
  // const ruleType = action.payload.ruleType
  //   ? action.payload.ruleType
  //   : window.location.href.substring(window.location.href.lastIndexOf('=') + 1);
  try {
    const userConfig = yield select(({ serverConfig }) => serverConfig);
    const { queryValue } = action.payload;
    const selectedRuleTypeId = yield select(selectCurrentRuleRecordId);
    // get values sent via server-side config to use with this call
    // We get the rules list in scope by yielding the result of calling the API
    const response = yield call(
      getRulesForRuleTypesList,
      userConfig,
      action.payload,
      selectedRuleTypeId,
    );
    const ruleType = action.payload.ruleTypeId
      ? action.payload.ruleTypeId
      : window.location.href.substring(
        window.location.href.lastIndexOf('=') + 1,
      );

    const newRulesList = response.rules;
    // If no error was thrown we can assume we got our rules list successfully,
    // but we can also do additional validation here
    if (Array.isArray(newRulesList)) {
      // If we got what we expect, dispatch our success action
      if (action.payload && action.payload.offset > 0) {
        yield put(
          setLoadingState({
            id: 'rulesForRuleTypesGridLoading',
            isLoading: false,
          }),
        );
        yield put(
          allRulesForRuleTypesListReceived({
            rulesForRuleTypesList: newRulesList,
            loadMore: response.loadMore,
            append: true,
            queryValue,
            ruleTypeId: ruleType,
            offset: action.payload.offset,
          }),
        );
      } else {
        yield put(
          setLoadingState({
            id: 'rulesForRuleTypesGridLoading',
            isLoading: false,
          }),
        );
        yield put(
          allRulesForRuleTypesListReceived({
            rulesForRuleTypesList: newRulesList,
            loadMore: response.loadMore,
            offset: action.payload.offset,
            queryValue,
          }),
        );
      }
      yield put(
        triggerAllRulesForRuleTypesAssociationsRequest({
          ruleType,
          groupFlow: false
        }),
      );
    } else {
      yield put(
        setLoadingState({
          id: 'rulesForRuleTypesGridLoading',
          isLoading: false,
        }),
      );
      // We got a 200 response that was valid JSON, but the expected data type
      // was not returned from the server so we pass a custom error out with our
      // failure action
      yield put(
        allRulesForRuleTypesRequestError(
          new Error(
            '[ rulesForRuleTypesGenerator ] returned rules list was not an Array',
          ),
        ),
      );
    }
  } catch (thrownError) {
    yield put(
      setLoadingState({
        id: 'rulesForRuleTypesGridLoading',
        isLoading: false,
      }),
    );
    yield put(
      addNotification({
        id: thrownError.errorId,
        type: 'negative',
        msg: thrownError.message,
      }),
    );
    yield put(allRulesForRuleTypesRequestError(thrownError));
  }
}

// eslint-disable-next-line no-unused-vars
function* activeGroupAssociationsListGenerator(action) {
  yield put(
    setLoadingState({
      id: 'activeGroupAssociationsGridLoading',
      isLoading: true,
    }),
  );
  try {
    const { clientLayoutId, userSecurityTokenKey, username, roleId } = yield select(
      ({ serverConfig }) => serverConfig,
    );
  
    /**
     * Here is the payload which contains the ruleType of the group associations we are getting
     * This also includes the boolean value of groupFlow determining which records we are getting associations for
     */
    const { ruleType: activeGroupRuleType, groupFlow } = action.payload;
  
    /**
     * If we are triggering this through the group flow then we are going to use the group flow rule Types list
     * If we are trigger from the rule flow we are going to use the rules flow rule types list as the records
     */
    const ruleTypeGroupFlowRecords = yield select(selectRuleTypesAccumRecords);
    const ruleTypeRuleFlowRecords = yield select(selectRuleTypesList);
    const records = groupFlow ? get(find(ruleTypeGroupFlowRecords, record => record.id === activeGroupRuleType), 'rules', []) : ruleTypeRuleFlowRecords;
    const ruleIds = {
      ruleId: map(records, record => record.id)
    };
    const ruleType = activeGroupRuleType || window.location.href.substring(window.location.href.lastIndexOf('=') + 1);
  
    // 2nd call for active group live associations
    const associatedRulesForRuleTypes = yield call(
      getGroupAssociationsForRuleIds,
      ruleIds,
      clientLayoutId,
      userSecurityTokenKey,
      username,
      ruleType,
      roleId
    );
  
    // match agla.ruleId to existing record with same ruleId
    const newRecords = map(records, record => {
      const matchedRecord = find(associatedRulesForRuleTypes, 
        type => type.ruleId === record.id,
      );
      if (matchedRecord && Array.isArray(matchedRecord.activeGroup)) {
        return { ...record, activeGroup: matchedRecord.activeGroup };
      }
      return { ...record, activeGroup: [] };
    });
    yield put(
      setLoadingState({
        id: 'activeGroupAssociationsGridLoading',
        isLoading: false,
      }),
    );
    /**
       * If we are going through groupFlow then we need to format the records back into the specified ruleTypes depending on
       * The accordion that was clicked
       * If we are going through ruleFlow then the records stay as is
       */
    const associatedRecords = !groupFlow ? newRecords :
      map(ruleTypeGroupFlowRecords, record => record.id === activeGroupRuleType ? {
        ...record,
        rules: newRecords
      } : record);
  
    yield put(allRulesForRuleTypesAssociationsListReceived(associatedRecords));
  } catch (thrownError) {
    yield put(
      setLoadingState({
        id: 'activeGroupAssociationsGridLoading',
        isLoading: false,
      }),
    );
    yield put(
      addNotification({
        id: thrownError.errorId,
        type: 'negative',
        msg: thrownError.message,
      }),
    );
    yield put(allRulesForRuleTypesAssociationsRequestError(thrownError));
  }
}

// Group Associations For Rule Id Generator

function* groupAssociationsForRuleIdGenerator(action) {
  yield put(
    setLoadingState({
      id: 'groupAssociationsGridLoading',
      isLoading: true,
    }),
  );
  const { clientLayoutId, userSecurityTokenKey, username, roleId } = yield select(
    ({ serverConfig }) => serverConfig,
  );
  const ruleType = yield select(selectCurrentRuleRecordId);
  try {
    const response = yield call(
      getGroupAssociationsForRule,
      clientLayoutId,
      userSecurityTokenKey,
      username,
      ruleType,
      roleId,
      action.payload,
    );
    if (response && !response.error) {
      if (action.payload) {
        yield put(
          setLoadingState({
            id: 'groupAssociationsGridLoading',
            isLoading: false,
          }),
        );
        yield put(groupAssociationsForRuleIdSuccess(response));
      }
    } else {
      yield put(
        setLoadingState({
          id: 'groupAssociationsGridLoading',
          isLoading: false,
        }),
      );
      yield put(groupAssociationsForRuleIdError(response.error));
    }
  } catch (thrownError) {
    yield put(
      setLoadingState({
        id: 'groupAssociationsGridLoading',
        isLoading: false,
      }),
    );
    yield put(
      addNotification({
        id: action.payload.ruleType,
        type: 'negative',
        msg: thrownError.message,
      }),
    );
    yield put(groupAssociationsForRuleIdError(thrownError));
  }
}

// Group Associations For Rule Type Generator
function* postGroupAssociationsForRuleTypeGenerator(action) {
  yield put(
    setLoadingState({
      id: 'postingGroupAssociationsLoading',
      isLoading: true,
    }),
  );
  const userConfig = yield select(({ serverConfig }) => serverConfig);
  try {
    const response = yield call(
      postGroupAssociationsForRuleTypeTrigger,
      userConfig,
      action.payload,
    );
    // case of success
    if (action.payload) {
      yield put(setIsViewingGroupAssociationsModal({
        showing: false,
        associatedRuleType: null
      }));
      yield put(
        setLoadingState({
          id: 'postingGroupAssociationsLoading',
          isLoading: false,
        }),
      );
      yield put(
        addNotification({
          id: action.payload.ruleType,
          type: 'positive',
          msg: response.success,
        }),
      );
      yield put(groupAssociationsForRuleTypeSuccess(response));
      const { groupId } = action.payload;
      const { clientLayoutId, userSecurityTokenKey, username, roleId, dataSourceId } = yield select(
        ({ serverConfig }) => serverConfig,
      );
      // Here we need to find out whether or not they are associating a rule type or disassociating a rule type
      // We are also getting the selected rule/s from the payload. Specifically to use if they are disassociating a rule from rule type
      const { associating, selectedRules } = action.payload;
      const ruleTypeToDisassociate = selectedRules[0].ruleType;
      // Grabbing the state object from the redux store
      const prevState = yield select();
      // Here we need to get the current rule types associated with a specific group
      const currentAccumRecords = get(prevState, 'ruleTypesList.ruleTypesAccumRecords', []);
      // If the user is associating a rule type then we need to use the previous accumRecords in our filter below
      // If not, then we need to filter out the specific ruleType that is being disassociated so we can remove it from the UI
      const currentRuleTypesArray = associating ? currentAccumRecords : 
        filter(currentAccumRecords, accumRecord => accumRecord.id !== ruleTypeToDisassociate);
      // We need to get the new rule type array from associating a new rule to a rule type
      const queryValue = '';
      const ruleTypesArray = yield call(
        getRuleTypesList,
        clientLayoutId,
        userSecurityTokenKey,
        username,
        groupId,
        '', // kept ruleType empty as it is causing issue for query insert for GET call
        queryValue,
        dataSourceId,
        roleId,
      );
      // We are going to grab the ids of the newly updated rule types array
      const ruleTypesIdArray = map(ruleTypesArray, type => type.id);
      // Here we need to filter out the updated rule types from the old rule types
      const filteredRuleTypesArray = filter(currentRuleTypesArray, current => ruleTypesIdArray.indexOf(current.id) === -1);
      // We then need to add back in the new rule types and their associations to the old ones that have not been updated
      const updatedRuleTypesArray = [
        ...ruleTypesArray,
        ...filteredRuleTypesArray
      ];

      yield put(triggerUpdateRuleTypes(updatedRuleTypesArray, groupId));
      yield put(ruleTypesListReceived(updatedRuleTypesArray, groupId));
    }
  } catch (thrownError) {
    yield put(
      setLoadingState({
        id: 'postingGroupAssociationsLoading',
        isLoading: false,
      }),
    );
    yield put(
      addNotification({
        id: action.payload.ruleType,
        type: 'negative',
        msg: thrownError.message,
      }),
    );
    yield put(setIsViewingGroupAssociationsModal({
      showing: false,
      associatedRuleType: null
    }));
    yield put(groupAssociationsForRuleTypeError(thrownError));
  }
}

// This saga gets attached to the redux store, and listens for action types
// similar to the reducers. When it matches an action type it will run the
// generator indicated and pass it the action as an argument.
function* rulesForRuleTypesListSaga() {
  yield takeLatest(
    ALL_RULES_FOR_RULETYPES_ASSOCIATIONS_REQUEST_TRIGGER,
    activeGroupAssociationsListGenerator,
  );
  yield takeLatest(
    ALL_RULES_FOR_RULETYPES_REQUEST_TRIGGER,
    rulesForRuleTypesListGenerator,
  );
  yield takeLatest(RULES_COUNT_FROM_DB_TRIGGER, rulesCountGenerator);
  yield takeLatest(
    GROUP_ASSOCIATIONS_FOR_RULE_ID_TRIGGER,
    groupAssociationsForRuleIdGenerator,
  );
  yield takeLatest(
    POST_GROUP_ASSOCIATIONS_FOR_RULE_TYPE_TRIGGER,
    postGroupAssociationsForRuleTypeGenerator,
  );
}

export default rulesForRuleTypesListSaga;
