/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components/dist/styled-components';
import { Input, Button, LoadingIndicator, Modal, ModalHeader, ModalFooter, ModalBody, ButtonGroup } from 'ui-core/dist'
import { Warning, CircleCheck } from 'ui-core/dist/icons';
import ReactTooltip from 'react-tooltip';
import { SelectDropdown } from '../../../components'
import ModalWrapper from '../../../components/Modal/components/ModalWrapper';
import { DOC_MANAGER_SPECIFY_TEMP_DELIVERY_ADDRESS } from '../Modules/constants';

const StyleButton = styled(Button)`
height: 18px !important;
width: 53px !important;
background: transparent;
box-shadow: none;
&:hover {
  background: transparent;
box-shadow: none;
}
`
const StyledModalWrapperWidth = styled(ModalWrapper)`
& .active-content {
  width: 50% !important;
  max-height: 600px !important;
  overflow: auto;
  & span {
    font-size: 11px !important;
  }
}
`
const StyledFormContainer = styled.div`
margin-left: 35px;
`
const StyledModalFooter = styled(ModalFooter)`
padding: 26px 0px !important;
justify-content: flex-start !important;
`
const StyledLoadingContainer = styled.div`
& > div {
  width: 35px !important;
  height: 35px !important;
  & > svg {
    width: 35px !important;
    height: 35px !important;
  }
}
`
const StyledDropDownContainer = styled.div`
width: 370px !important;
& #drpDeliveryAddress, & #drpDeliveryTypes, & #drpReleaseItems {
    width: 370px !important;
}
`

const DocumentRouteDeliveryFilterComp = ({ form }) => {

  const history = useHistory();
  const renderValidattionMessage = useMemo(() => {
    const msg=form?.warningMessage||form?.validationMessage;
    if (msg) {
      // {form?.checkWarningAttribute || ""}
      return (
        <div className="displayFlex">
          <p style={{ color: 'red' }}>{msg || ""}&nbsp;&nbsp;</p>
        </div>
      )
    }
    return null;
  }, [form?.validationMessage, form?.checkWarningAttribute, form?.warningMessage])
  const renderDeliveryAddresses = useMemo(() => (
    <div className="displayFlex">
      <StyledDropDownContainer>
        <SelectDropdown
          width="370px"
          domID="drpDeliveryAddress"
          label=""
          options={form?.deliveryAddressSource}
          size="medium"
          initialValue={{ label: form?.deliveryAddressSource.find(x => x.value === form?.selectedDeliveryAddress)?.label, value: form?.selectedDeliveryAddress }}
          onChange={(e) => {
            if (e) {
              if (form?.setselectedDeliveryAddressRef.current) form?.setselectedDeliveryAddressRef.current(e.value)
            } else if (form?.setselectedDeliveryAddressRef.current) form?.setselectedDeliveryAddressRef.current(null)
          }}
        />
      </StyledDropDownContainer>
      <div className="marginLeft">
        {form?.selectedDeliveryAddress === DOC_MANAGER_SPECIFY_TEMP_DELIVERY_ADDRESS ? (
          <>
            {form?.hasdocManagerOneOffRouteAddressObject && form?.docManagerOneOffRouteAddressObject ? (
              <>
              {form?.warningMessage || form?.validationMessage?(
                <StyleButton data-tip data-for='WarnDelFil'
                  onClick={(e) => { if (form?.setisOpenOnOffAddressModalRef?.current) form?.setisOpenOnOffAddressModalRef?.current(true) }}>
                  <Warning
                    className="settings"
                    fillColor="red"
                    title="Settings"
                  />
                  <ReactTooltip id='WarnDelFil' type='dark' effect='solid' backgroundColor='#0F0F59'>
                    <span>{(form?.warningMessage || form?.validationMessage).indexOf('⚠')!==-1?'Address accepted, with warning. Click here to change.':'Address not accepted.'}</span>
                  </ReactTooltip>
                </StyleButton>
              ):(
              <StyleButton data-tip data-for='CheckDelFil'
                onClick={(e) => form?.persistDocManagerOnOffAddress()}>
                <CircleCheck
                  className="settings"
                  fillColor="#00FF00"
                  title="Settings"
                />
                <ReactTooltip id='CheckDelFil' type='dark' effect='solid' backgroundColor='#0F0F59'>
                  <span>Address accepted. Click here to change.</span>
                </ReactTooltip>
              </StyleButton>
              )}
              </>
            ) : (
                <StyleButton data-tip data-for='WarnDelFil'
                  onClick={(e) => { if (form?.setisOpenOnOffAddressModalRef?.current) form?.setisOpenOnOffAddressModalRef?.current(true) }}>
                  <Warning
                    className="settings"
                    fillColor="red"
                    title="Settings"
                  />
                  <ReactTooltip id='WarnDelFil' type='dark' effect='solid' backgroundColor='#0F0F59'>
                <span>Click here to specify a one-time address.</span>
              </ReactTooltip>
                </StyleButton>
              )}
          </>
        ) : null}
      </div>
    </div>
  ), [form?.validationMessage, form?.warningMessage,form?.deliveryAddressSource, form?.selectedDeliveryAddress, form?.docManagerOneOffRouteAddressObject, form?.hasdocManagerOneOffRouteAddressObject])
  
  function compare(a,b) {
    if (a.label < b.label )
      return -1;
 }
 
  const renderDeliveryTypes = useMemo(() => (
    <StyledDropDownContainer>
      <SelectDropdown
        width="370px"
        domID="drpDeliveryTypes"
        label=""
        options={(history.location.pathname === '/DocumentManager/Route/Group/searchResults'||
                  history.location.pathname === '/DocumentManager/Route/File/searchResults')?
                  form?.deliveryTypeSource.sort(compare).filter(item => item.label !== "Federal Express International"):
                  form?.deliveryTypeSource.sort(compare)}
        size="medium"
        initialValue={{ label: form?.deliveryTypeSource.find(x => x.value === form?.selectedDeliveryType)?.label, value: form?.selectedDeliveryType }}
        onChange={(e) => {
          if (e) {
            if (form?.setselectedDeliveryTypeRef.current) form?.setselectedDeliveryTypeRef.current(e.value)
          } else if (form?.setselectedDeliveryTypeRef.current) form?.setselectedDeliveryTypeRef.current(null)
        }}
      />
    </StyledDropDownContainer>
  ), [form?.deliveryTypeSource, form?.selectedDeliveryType])

  const renderReleaseItems = useMemo(() => (
    <StyledDropDownContainer>
      <SelectDropdown
        width="370px"
        domID="drpReleaseItems"
        label=""
        options={form?.releaseItemsSource}
        size="medium"
        initialValue={{ label: form?.releaseItemsSource.find(x => x.value === form?.selectedReleaseItemsOption)?.label, value: form?.selectedReleaseItemsOption }}
        onChange={(e) => {
          if (e) {
            if (form?.setselectedReleaseItemsOptionRef.current) form?.setselectedReleaseItemsOptionRef.current(e.value)
          } else if (form?.setselectedReleaseItemsOptionRef.current) form?.setselectedReleaseItemsOptionRef.current(null)
        }}
      />
    </StyledDropDownContainer>
  ), [form?.releaseItemsSource, form?.selectedReleaseItemsOption])

  const validateForm = useMemo(() => {
    if (form?.isOpenOnOffAddressModal) {
      if (!form?.name || !form?.line1 || !/^[0-9]*$/.test(form?.zip)) {
        return false;
      }
      if (form?.intFlag !== "1") {
        if (!form?.city || !form?.state || !form?.zip) {
          return false;
        }
        return true;
      }
      if (!form?.intCity
        || !form?.intCountr
        || !form?.intCountryList.find(x => x.value === form?.intCountr)
        ) {
        return false;
      }
      if(form?.intZip || form?.intPhone || form?.intRetPho){
        if((!/^[A-Z0-9]*$/.test(form?.intZip)||(!/^[0-9]*$/.test(form?.intPhone))||(!/^[0-9]*$/.test(form?.intRetPho)))) {
          return false;
        }
        return true;
      }
      return true;
    }
    return false;
  }, [
    form?.isOpenOnOffAddressModal,
    form?.intFlag,
    form?.name,
    form?.line1,
    form?.city,
    form?.state,
    form?.zip,
    form?.intCity,
    form?.intCountryList,
    form?.intCountr,
    form?.availableSortOrders,
    form?.sortOrderId,
    form?.intZip,
    form?.intPhone,
    form?.intRetPho,
  ])

  const renderPOBoxModalWindow = useMemo(() => {
    return (
      <Modal isOpen={form?.isOpenOnOffAddressModal} wrapperComponent={StyledModalWrapperWidth}>
        <ModalHeader
          title="One Time Address"
          onClose={() => form?.setisOpenOnOffAddressModalRef.current(false)}
        />
        {form?.isOpenOnOffAddressModal ? (
          <StyledFormContainer>
            <ModalBody>
              <div className="marginLeft">
                <div className="displayFlex">
                  <span>The documents you've selected will be delivered to the address you specify below.</span>
                </div>
                {form?.intFlag === "1" ? (
                  <div className="displayFlex">
                    <span>Please refer to
                     <a style={{marginLeft: '3px'}} href="http://www.fedex.com/us/service-guide/after-you-ship/terms/express-ground.html" target="_blank">http://www.fedex.com/us/service-guide/after-you-ship/terms/express-ground.html</a> for FedEx Express International Terms and Conditions on international delivery.</span>
                  </div>
                ) : null}
                <div className="displayFlex">
                  <table className="common-table">
                    <tr>
                      <td colspan="2" className="required-glyph-msg">
                        <label><span style={{color: "red"}}>* indicates required information</span></label>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label>Name: <span class="required-glyph" style={{color: "red"}}>*</span></label>
                      </td>
                      <td>
                        <Input
                          style={{ width: '200px' }}
                          className="test-classname"
                          domID="name"
                          placeholder=""
                          size="small"
                          maxLength={30}
                          initialValue={form?.name}
                          onChange={(e) => {
                            form?.setNameRef.current(e.target.value || "")
                          }}
                        />
                      </td>
                    </tr>
                    <tr><td><p></p></td></tr>
                    <tr>
                      <td>
                        <label>Attention: </label>
                      </td>
                      <td>
                        <Input
                          style={{ width: '200px' }}
                          className="test-classname"
                          domID="attention"
                          placeholder=""
                          size="small"
                          maxLength={30}
                          initialValue={form?.attention}
                          onChange={(e) => {
                            form?.setattentionRef.current(e.target.value || "")
                          }}
                        />
                      </td>
                    </tr>
                    <tr><td><p></p></td></tr>
                    <tr>
                      <td>
                        <label>Address 1: <span class="required-glyph" style={{color: "red"}}>*</span></label>
                      </td>
                      <td>
                        <Input
                          style={{ width: '200px' }}
                          className="test-classname"
                          domID="line1"
                          placeholder=""
                          size="small"
                          maxLength={30}
                          initialValue={form?.line1}
                          onChange={(e) => {
                            form?.setLine1Ref.current(e.target.value || "")
                          }}
                        />
                      </td>
                    </tr>
                    <tr><td><p></p></td></tr>
                    <tr>
                      <td>
                        <label>Address 2:</label>
                      </td>
                      <td>
                        <Input
                          style={{ width: '200px' }}
                          className="test-classname"
                          domID="line2"
                          placeholder=""
                          size="small"
                          maxLength={30}
                          initialValue={form?.line2}
                          onChange={(e) => {
                            form?.setLine2Ref.current(e.target.value || "")
                          }}
                        />
                      </td>
                    </tr>
                    <tr><td><p></p></td></tr>
                    {form?.intFlag !== "1" ? (
                      <>
                        <tr>
                          <td>
                            <label>City: <span class="required-glyph" style={{color: "red"}}>*</span></label>
                          </td>
                          <td>
                            <Input
                              style={{ width: '200px' }}
                              className="test-classname"
                              domID="city"
                              placeholder=""
                              size="small"
                              maxLength={20}
                              initialValue={form?.city}
                              onChange={(e) => {
                                form?.setCityRef.current(e.target.value || "")
                              }}
                            />
                          </td>
                        </tr>
                        <tr><td><p></p></td></tr>
                        <tr>
                          <td>
                            <label>State: <span class="required-glyph" style={{color: "red"}}>*</span></label>
                          </td>
                          <td>
                            <Input
                              style={{ width: '200px' }}
                              className="test-classname"
                              domID="state"
                              placeholder=""
                              size="small"
                              maxLength={2}
                              initialValue={form?.state}
                              onChange={(e) => {
                                form?.setStateRef.current(e.target.value || "")
                              }}
                            />
                          </td>
                        </tr>
                        <tr><td><p></p></td></tr>
                        <tr>
                          <td>
                            <label>Zip Code: <span class="required-glyph" style={{color: "red"}}>*</span></label>
                          </td>
                          <td>
                            <Input
                              style={{ width: '200px' }}
                              className="test-classname"
                              domID="zip"
                              placeholder=""
                              size="small"
                              maxLength={5}
                              hasError={form?.zip ? !/^[0-9]*$/.test(form?.zip) : false}
                              errorMessage="Zip code accepts only digits"
                              initialValue={form?.zip}
                              onChange={(e) => {
                                form?.setZipRef.current(e.target.value || "")
                              }}
                            />
                          </td>
                        </tr>
                        <tr><td><p></p></td></tr>
                      </>
                    ) : (
                        <>
                          <tr>
                            <td>
                              <label>Address 3: </label>
                            </td>
                            <td>
                              <Input
                                style={{ width: '200px' }}
                                className="test-classname"
                                domID="intAddr3"
                                placeholder=""
                                size="small"
                                maxLength={30}
                                initialValue={form?.intAddr3}
                                onChange={(e) => {
                                  form?.setIntAddr3Ref.current(e.target.value || "")
                                }}
                              />
                            </td>
                          </tr>
                          <tr><td><p></p></td></tr>
                          {(form?.intDecision !== "1" && form?.intFlag !== "1") ? (
                            <>
                              <tr>
                                <td>
                                  <label>Reference: </label>
                                </td>
                                <td>
                                  <Input
                                    disabled={true}
                                    style={{ width: '200px' }}
                                    className="test-classname"
                                    domID="intCtStZp"
                                    placeholder=""
                                    size="small"
                                    maxLength={30}
                                    initialValue={form?.intCtStZp}
                                    onChange={(e) => {
                                      form?.setIntCtStZpRef.current(e.target.value || "")
                                    }}
                                  />
                                </td>
                              </tr>
                              <tr><td><p></p></td></tr>
                              <tr>
                                <td>
                                  <label>Reference: </label>
                                </td>
                                <td>
                                  <Input
                                    disabled={true}
                                    style={{ width: '200px' }}
                                    className="test-classname"
                                    domID="intCntry"
                                    placeholder=""
                                    size="small"
                                    maxLength={30}
                                    initialValue={form?.intCntry}
                                    onChange={(e) => {
                                      form?.setIntCntryRef.current(e.target.value || "")
                                    }}
                                  />
                                </td>
                              </tr>
                              <tr><td><p></p></td></tr>
                            </>
                          ) : null}
                          <tr>
                            <td>
                              <label>City: <span class="required-glyph" style={{color: "red"}}>*</span></label>
                            </td>
                            <td>
                              <Input
                                style={{ width: '200px' }}
                                className="test-classname"
                                domID="intCity"
                                placeholder=""
                                size="small"
                                maxLength={30}
                                initialValue={form?.intCity}
                                onChange={(e) => {
                                  form?.setIntCityRef.current(e.target.value || "")
                                }}
                              />
                            </td>
                          </tr>
                          <tr><td><p></p></td></tr>
                          <tr>
                            <td>
                              <label>State/Province: </label>
                            </td>
                            <td>
                              <Input
                                style={{ width: '200px' }}
                                className="test-classname"
                                domID="intState"
                                placeholder=""
                                size="small"
                                maxLength={30}
                                initialValue={form?.intState}
                                onChange={(e) => {
                                  form?.setIntStateRef.current(e.target.value || "")
                                }}
                              />
                            </td>
                          </tr>
                          <tr><td><p></p></td></tr>
                          <tr>
                            <td>
                              <label>Postal Code: </label>
                            </td>
                            <td>
                              <Input
                                style={{ width: '200px' }}
                                className="test-classname"
                                domID="intZip"
                                placeholder=""
                                size="small"
                                maxLength={15}
                                hasError={form?.intZip ? !/^[A-Z0-9]*$/.test(form?.intZip) : false}
                                errorMessage="Postal code accepts only capital alphabet and digits"
                                initialValue={form?.intZip}
                                onChange={(e) => {
                                  form?.setIntZipRef.current(e.target.value || "")
                                }}
                              />
                            </td>
                          </tr>
                          <tr><td><p></p></td></tr>
                          <tr>
                            <td>
                              <label>Country: <span class="required-glyph"  style={{color: "red"}}>*</span></label>
                            </td>
                            <td>
                              <StyledDropDownContainer>
                                <SelectDropdown
                                  width="370px"
                                  domID="intCountryList"
                                  label=""
                                  options={form?.intCountryList}
                                  size="medium"
                                  initialValue={{ label: form?.intCountryList.find(x => x.value === form?.intCountr)?.label, value: form?.intCountr }}
                                  onChange={(e) => {
                                    if (e) {
                                      if (form?.setIntCountrRef.current) form?.setIntCountrRef.current(e.value)
                                    } else if (form?.setIntCountrRef.current) form?.setIntCountrRef.current(null)
                                  }}
                                />
                              </StyledDropDownContainer>
                            </td>
                          </tr>
                          <tr><td><p></p></td></tr>
                          <tr>
                            <td>
                              <label>Phone Number: </label>
                            </td>
                            <td>
                              <Input
                                style={{ width: '200px' }}
                                className="test-classname"
                                domID="intPhone"
                                placeholder=""
                                size="small"
                                maxLength={25}
                                hasError={form?.intPhone ? !/^[0-9]*$/.test(form?.intPhone) : false}
                                errorMessage="Phone number accepts only digits"
                                initialValue={form?.intPhone}
                                onChange={(e) => {
                                  form?.setIntPhoneRef.current(e.target.value || "")
                                }}
                              />
                            </td>
                          </tr>
                          <tr><td><p></p></td></tr>
                          <tr>
                            <td>
                              <label>Email Address: </label>
                            </td>
                            <td>
                              <Input
                                style={{ width: '200px' }}
                                className="test-classname"
                                domID="intEmail"
                                placeholder=""
                                size="small"
                                maxLength={60}
                                initialValue={form?.intEmail}
                                onChange={(e) => {
                                  form?.setIntEmailRef.current(e.target.value || "")
                                }}
                              />
                            </td>
                          </tr>
                          <tr><td><p></p></td></tr>
                          <tr>
                            <td>
                              <label>Return Phone Number: </label>
                            </td>
                            <td>
                              <Input
                                style={{ width: '200px' }}
                                className="test-classname"
                                domID="intRetPho"
                                placeholder=""
                                size="small"
                                maxLength={60}
                                hasError={form?.intRetPho ? !/^[0-9]*$/.test(form?.intRetPho) : false}
                                errorMessage="Phone number accepts only digits"
                                initialValue={form?.intRetPho}
                                onChange={(e) => {
                                  form?.setIntRetPhoRef.current(e.target.value || "")
                                }}
                              />
                            </td>
                          </tr>
                          <tr><td><p></p></td></tr>
                        </>
                      )}
                    <tr>
                      <td>
                        <label>Sort Order: </label>
                      </td>
                      <td>
                        <StyledDropDownContainer>
                          <SelectDropdown
                            width="370px"
                            domID="availableSortOrders"
                            label=""
                            options={form?.availableSortOrders}
                            size="medium"
                            initialValue={{ label: form?.availableSortOrders.find(x => x.value === form?.sortOrderId)?.label, value: form?.sortOrderId }}
                            onChange={(e) => {
                              if (e) {
                                if (form?.setsortOrderIdRef.current) form?.setsortOrderIdRef.current(e.value)
                              } else if (form?.setsortOrderIdRef.current) form?.setsortOrderIdRef.current(null)
                            }}
                          />
                        </StyledDropDownContainer>
                      </td>
                    </tr>
                    <tr>
                      <td><p></p></td>
                    </tr>
                  </table>
                </div>

              </div>
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  buttonType="standard"
                  domID="confirm"
                  dataTestId="test-confirm"
                  name="CONFIRM"
                  disabled={!validateForm}
                  onClick={() => form?.saveDocManagerOneOffAddress()}
                  size="medium"
                />
                <Button
                  buttonType="standard"
                  domID="resetOneTime"
                  dataTestId="test-confirm"
                  name="RESET"
                  // disabled={!validateForm}
                  onClick={() => form?.ClearFormFields()}
                  size="medium"
                />
              </ButtonGroup>
            </ModalFooter>
          </StyledFormContainer>
        ) : null}
      </Modal>
    )
  }, [
    form?.isOpenOnOffAddressModal,
    form?.availableSortOrders,
    form?.description,
    form?.name,
    form?.attention,
    form?.line1,
    form?.line2,
    form?.city,
    form?.state,
    form?.zip,
    form?.intFlag,
    form?.intCountryList,
    form?.intAddr3,
    form?.intCity,
    form?.intState,
    form?.intZip,
    form?.intCountr,
    form?.intPhone,
    form?.intEmail,
    form?.intRetPho,
    form?.intDecision,
    form?.intCtStZp,
    form?.intCntry,
    form?.sortOrderId
  ])

  return (
    <div className="marginLeft">
      <>
        {form?.isLoadingValidation ? <div style={{ height: '142px' }}><LoadingIndicator /></div> : <>
          {renderValidattionMessage}
          <table>
            <tr>
              <td><div className="displayFlex"><span>Delivery Address: </span></div></td>
              <td>
                <div className="displayFlex">
                  <StyledLoadingContainer>
                    <>
                      {form?.isLoadingDeliveryAddress ? <LoadingIndicator /> : (
                        <>
                          {form?.isErrorDeliveryAddress ? <span>Couldn't load Delivery Addresses</span> : (
                            <>
                              {renderDeliveryAddresses}
                            </>
                          )}
                        </>
                      )}
                    </>
                  </StyledLoadingContainer>
                </div>
              </td>
            </tr>
            <tr>
              <td><div className="displayFlex"><span>Delivery Type: </span></div></td>
              <td>
                <div className="displayFlex">
                  <StyledLoadingContainer>
                    <>
                      {form?.isLoadingDeliveryType ? <LoadingIndicator /> : (
                        <>
                          {form?.isErrorDeliveryType ? <span>Couldn't load Delivery Type</span> : (
                            <>
                              {renderDeliveryTypes}
                            </>
                          )}
                        </>
                      )}
                    </>
                  </StyledLoadingContainer>
                </div>
              </td>
            </tr>
            <tr>
              <td><div className="displayFlex"><span>Release Items on Hold: </span></div></td>
              <td>
                <div className="displayFlex">
                  <>
                    {renderReleaseItems}
                  </>
                </div>
              </td>
            </tr>
          </table>
        </>}
        {renderPOBoxModalWindow}
      </>
    </div>
  );
}

export default DocumentRouteDeliveryFilterComp;