import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Warning } from 'ui-core-ssgr';
import ButtonGroup from '../../../../shared/components/ButtonGroup';
import Colors from '../../../../shared/styles/colors';
import  { 
  ModalPaddingWrapper, 
  BoldTextAfterWarningIcon, 
  IconWrapper, 
  ModalTitlePaddingWrapper, 
  StyledHeader,
} from '../../../../shared/styles/styledComponents';
import { DivWithPaddingTop } from '../../../../shared/styles/spacing';

class CloneRuleModal extends Component {
  state = {
    record: null,
  };

  componentDidMount() {
    const { records, selectedItemIds } = this.props;

    const record = records.find(
      element => element.id === Array.from(selectedItemIds)[0],
    );

    this.setState({ record });
  }

  onButtonClick = name => {
    const {
      addNotification,
      isExpanded,
      setIsCloningRuleState,
      setIsShowingCloneRuleModal,
      toggleDetailsView,
      selectedItemIds,
      updateCurrentTab,
    } = this.props;
    if (name === 'confirm' && selectedItemIds.size === 1) {
      if (!isExpanded) toggleDetailsView();
      updateCurrentTab({ currentTab: 0 });
      addNotification({
        id: 'cloneRule',
        icon: 'Info',
        type: 'neutral',
        msg: 'Fill out a unique rule name.',
      });
      setIsCloningRuleState(true);
    }
    if (name === 'cancel') setIsCloningRuleState(false);
    setIsShowingCloneRuleModal(false);
  };

  onModalToggle = () => {
    const { setIsShowingCloneRuleModal, setIsCloningRuleState } = this.props;
    setIsCloningRuleState(false);
    setIsShowingCloneRuleModal(false);
  };

  // if live version is different than test version, we want to let the user know it is copying the live details
  getRuleVersion = record => {
    try {
      const testVersion = record.status.length === 1;
      const liveVersion =
        record.status.length > 1 && record.status.includes('L');
      if (liveVersion) return 'live';
      if (testVersion) return '';
    } catch (e) {}
    return null;
  };

  render() {
    const { onButtonClick } = this;
    const { record } = this.state;

    return (
      <Modal
        domID="modal-id"
        onModalToggle={() => this.onModalToggle()}
        isOpen
        showDefaultClose
        deferBodyControl={false}
      >
        <ModalPaddingWrapper>
          <ModalTitlePaddingWrapper>
            <StyledHeader>
              {`Clone Rule: ${record && record.name}`}
            </StyledHeader>
          </ModalTitlePaddingWrapper>
          <IconWrapper>
            <Warning fillColor={Colors.negativeDark} />
          </IconWrapper>
          <BoldTextAfterWarningIcon>
            {`This will copy all the rule details from the original ${this.getRuleVersion(
              record,
            )} rule ${record && record.name}`}
          </BoldTextAfterWarningIcon>
          <DivWithPaddingTop>
            <ButtonGroup>
              <Button
                name="confirm"
                buttonType="emphasized"
                onClick={() => onButtonClick('confirm')}
                size="medium"
                type="button"
              />
              <Button
                name="cancel"
                buttonType="diminished"
                onClick={() => onButtonClick('cancel')}
                size="medium"
                type="button"
              />
            </ButtonGroup>
          </DivWithPaddingTop>
        </ModalPaddingWrapper>
      </Modal>
    );
  }
}

CloneRuleModal.propTypes = {
  addNotification: PropTypes.func.isRequired,
  selectedItemIds: PropTypes.object.isRequired,
  setIsCloningRuleState: PropTypes.func.isRequired,
  setIsShowingCloneRuleModal: PropTypes.func.isRequired,
  toggleDetailsView: PropTypes.func.isRequired,
  updateCurrentTab: PropTypes.func,
  isExpanded: PropTypes.bool,
  records: PropTypes.array
};

export default CloneRuleModal;
