import styled from 'styled-components';
import { neutral10 } from 'ui-core/dist/BasePalette';
import TextStyles from 'ui-core/dist/TextStyles';

export default styled.div`
  &.drag-initial {
    background-color: ${neutral10};
  }

  &.non-drop-zone {
    opacity: 0.4;
  }

  .selected {
    color: ${TextStyles.textColors.primary};

    .arrow path {
      fill: ${TextStyles.textColors.primary};
    }
  }
`;
