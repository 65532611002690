import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { LoadingIndicator, Button, Modal, DetailHeader } from 'ui-core-ssgr';
import { ButtonGroup } from '../../../shared/components/ButtonGroup';
import {
  ContainerBox,
  AddReturnAddressHeader,
  Title,
  ButtonContainerWrap,
  ReturnAddressLogoImageWrapper,
  HeaderTitle,
  ImageFilter,
  SearchButtonImage,
  RuleTypesDetailRow
} from './styles';
import DropDownControl from '../ModalControls/DropDownControl/index';
import InputControl from '../ModalControls/InputControl';
import {
  LoadingWrapper,
  ModalButton,
} from '../../RulesTabView/components/RuleDetails/RuleDetailsGridWrapper/styles';
import UploadAndViewImageControl from '../ModalControls/UploadAndViewImageControl';
import ImageLibraryModal from '../ImageLibraryModal';
import FileUpload from '../../../shared/components/FileUpload';
import CommitImageModal from '../CommitImageModal';
import searchIcon from '../../../shared/images/search-icon.png';

class AddNewAddressModal extends Component {
  state = {
    isButtonDisabled: true,
    description: '',
    name: '',
    state: '',
    city: '',
    line2: '',
    line1: '',
    attention: '',
    zip: '',
    status: 'active',
    altAddressId: 'default',
    logoStyle: 'default',
    errorArray: [],
    labelRadLogo: 'Return Address Logo*',
    labelRadLogoStyle: 'Return Logo Style*',
    labelName: 'Name*',
    labelAddress1: 'Address 1*',
    selectedRecordIdImageLibrary: '0',
    returnAddressLogo : null,
    returnAddressLogoImageId: null,
    returnAddressLogoImage: null,
    mode: '',
    selectedDataToCommit: null,
    isTested: false,
    searchHasError: '',
    searchErrorMessage: '',
    searchToggle:false,
    keyword: ''
  };

  componentDidMount() {
    this.validateFormInputs();
  }

  componentDidUpdate(prevProps) {
    const { postImageUploadSuccessId, postImageCommitSuccessId} = this.props;
    const {mode} = this.state;

    if(postImageUploadSuccessId &&
      postImageUploadSuccessId.imageId && 
      ( !prevProps.postImageUploadSuccessId ||
        (prevProps.postImageUploadSuccessId.imageId.imageId !== postImageUploadSuccessId.imageId.imageId)) &&
       mode === 'upload'){
      this.updateImageData();
    } 

    if(postImageCommitSuccessId &&
      postImageCommitSuccessId.imageId && 
      ( !prevProps.postImageCommitSuccessId ||
        (prevProps.postImageCommitSuccessId.isTested !== postImageCommitSuccessId.isTested) ||
        (prevProps.postImageCommitSuccessId.imageId !== postImageCommitSuccessId.imageId) ) ) {
      this.commitImageData();
    }
  }

  updateImageData = () => {
    const {postImageUploadSuccessId } = this.props;
    const {selectedUploadViewConrolId} = this.state;
  
    if(selectedUploadViewConrolId === 'returnAddressLogo'){
      this.setState({returnAddressLogo:postImageUploadSuccessId.imageName, 
        returnAddressLogoImageId: String(postImageUploadSuccessId.imageId.imageId),
        returnAddressLogoImage: postImageUploadSuccessId.convertedImageUploadData.convertedImage,
        isTested: postImageUploadSuccessId.imageId.isTested,
      });
      this.checkUnsavedChangesForImage("setdirty","");
    }
  }

  commitImageData = () => {
    const {postImageCommitSuccessId } = this.props;
    if(postImageCommitSuccessId){
      this.setState({
        isTested: postImageCommitSuccessId.isTested,
      });
    }
  }

  validateInputFields = inputType => {
    const { name, description, line1, city, zip } = this.state;
    let status = false;
    if (inputType === 'zip' && (zip.length === 6 || zip.length === 10)) {
      status = false;
    } else if (inputType === 'zip') {
      status = true;
    }
    if (inputType === 'city' && /^.{1,25}$/.test(city) === false) {
      status = true;
    }
    if (inputType === 'line1' && /^.{1,64}$/.test(line1) === false) {
      status = true;
    }
    if (inputType === 'name' && /^.{1,40}$/.test(name) === false) {
      status = true;
    }
    if (
      inputType === 'description' &&
      /^.{1,50}$/.test(description) === false
    ) {
      status = true;
    }
    return status;
  };

  validateFormInputs = () => {
    const {
      name,
      description,
      returnAddressLogo,
      line1,
      line2,
      attention,
      altAddressId,
      city,
      zip,
      state,
      errorArray
    } = this.state;
    let { logoStyle } = this.state;
    let isButtonDisabled = true;
    let labelRadLogoCust = '';
    let labelRadLogoStyleCust = '';
    let labelNameCust = '';
    let labelAddress1Cust = '';
    let infoHeader1Style;
    let infoHeader2Style;
    let infoHeader3Style;
    let infoHeader4Style;
    let infoHeader5Style;

    const emptyLogo = _.isEmpty(returnAddressLogo);
    const logoOnly = logoStyle === '05';

    const logoVal =
      (!emptyLogo  &&
        logoStyle !== 'default') ||
      (emptyLogo  &&
        !errorArray.nameError &&
        !errorArray.line1Error);

    const nameValidation = this.validateInputFields('name');
    errorArray.nameError =
      name.trim() === '' ||
      name === undefined ||
      (!logoOnly && nameValidation) ||
      nameValidation;
    errorArray.nameErrorMessage =
      errorArray.nameError && name.length > 39 ? 'Max allowed char is 40' : '';

    const descriptionValidation = this.validateInputFields('description');
    errorArray.descriptionError =
      description.trim() === '' ||
      description === undefined ||
      descriptionValidation;
    errorArray.descriptionErrorMessage =
      errorArray.descriptionError && description.length > 49
        ? 'Max allowed char is 50.'
        : '';

    const line1Validation = this.validateInputFields('line1');
    errorArray.line1Error =
      line1.trim() === '' || 
      line1 === undefined || 
      (!logoOnly && line1Validation) || 
      line1Validation;
    errorArray.line1ErrorMessage =
      errorArray.line1Error && line1.length > 63
        ? 'Max allowed char is 64'
        : '';
    const regexPoBox = /p\.?\s?o\.?\s{0,3}b[o][x]/i;
    if (
      regexPoBox.test(line1) === true &&
      (altAddressId === 'default')
    ) {
      errorArray.altAddressIdError = true;
    } else {
      errorArray.altAddressIdError = false;
    }
    if (
      line2 !== '' &&
      line2 !== undefined &&
      attention !== '' &&
      attention !== undefined
    ) {
      errorArray.line2Error = true;
      errorArray.attentionError = true;
    } else {
      errorArray.line2Error = false;
      errorArray.attentionError = false;
    }
    
    if (!logoVal) {
      errorArray.logoNameError = true;
      errorArray.logoStyleError = true;
    }
    if (emptyLogo) {
      logoStyle = 'default';
      errorArray.logoNameError = false;
      errorArray.logoStyleError = false;
    }
    if (logoVal && !errorArray.nameError && logoOnly) {
      errorArray.line1Error = false;
      labelAddress1Cust = 'Address 1';
    } else {
      labelAddress1Cust = 'Address 1*';
    }
    if (logoVal && !errorArray.line1Error && logoOnly) {
      errorArray.nameError = false;
      labelNameCust = 'Name';
    } else {
      labelNameCust = 'Name*';
    }
    if (logoVal && !errorArray.nameError && !errorArray.line1Error) {
      errorArray.logoNameError = false;
      errorArray.logoStyleError = false;
    }
    if (!errorArray.nameError && !errorArray.line1Error) {
      labelRadLogoCust = 'Return Address Logo';
      labelRadLogoStyleCust = 'Return Logo Style';
    } else {
      labelRadLogoCust = 'Return Address Logo*';
      labelRadLogoStyleCust = 'Return Logo Style*';
    }

    const cityValidation = this.validateInputFields('city');
    errorArray.cityError =
      city.trim() === '' || city === undefined || cityValidation;
    errorArray.cityErrorMessage =
      errorArray.cityError && city.length > 24 ? 'Max allowed char is 25' : '';

    const zipValidation = this.validateInputFields('zip');
    errorArray.zipError =
      zip.trim() === '' || zip === undefined || zipValidation;
    errorArray.zipErrorMessage = errorArray.zipError
      ? 'Zipcode must be 5 or 9 char'
      : '';

    errorArray.stateError =
      state === 'none' || state === '' || state === undefined;

    // INFO HEADER ERROR LOGIC
    // line 1 (2 out of 3 required fields)
    if (
      (!emptyLogo && logoVal && !errorArray.line1Error) ||
      (!emptyLogo && logoVal && !errorArray.nameError) ||
      (!errorArray.line1Error && !errorArray.nameError)
    ) {
      infoHeader1Style = { color: '#6d767e', fontWeight: '100' };
    } else {
      infoHeader1Style = { color: '#bf1616', fontWeight: '600' };
    }
    // line 2 (logo name & logo style)
    if ( 
      (emptyLogo && logoStyle !== 'default') ||
      (!emptyLogo  && logoStyle === 'default')
    ) {
      infoHeader2Style = { color: '#bf1616', fontWeight: '600' };
    } else {
      infoHeader2Style = { color: '#6d767e', fontWeight: '100' };
    }
    // line 3 (P.O. Box)
    if (
      regexPoBox.test(line1) === true &&
      (altAddressId === 'default')
    ) {
      infoHeader3Style = { color: '#bf1616', fontWeight: '600' };
    } else {
      infoHeader3Style = { color: '#6d767e', fontWeight: '100' };
    }
    // line 4 (Attention or Address 2 but not both)
    if (
      line2 !== '' &&
      line2 !== undefined &&
      attention !== '' &&
      attention !== undefined
    ) {
      infoHeader4Style = { color: '#bf1616', fontWeight: '600' };
    } else {
      infoHeader4Style = { color: '#6d767e', fontWeight: '100' };
    }
    // line 5 (Name and Address 1)
    if (
      (!logoOnly && nameValidation) ||
      ((!logoOnly && line1Validation) ||
        (emptyLogo && nameValidation) ||
        (emptyLogo && line1Validation))
    ) {
      infoHeader5Style = { color: '#bf1616', fontWeight: '600' };
    } else {
      infoHeader5Style = { color: '#6d767e', fontWeight: '100' };
    }

    if (
      errorArray.nameError !== true &&
      errorArray.descriptionError !== true &&
      errorArray.logoNameError !== true &&
      errorArray.line1Error !== true &&
      errorArray.cityError !== true &&
      errorArray.zipError !== true &&
      errorArray.stateError !== true &&
      errorArray.line2Error !== true &&
      errorArray.altAddressIdError !== true &&
      errorArray.attentionError !== true
    ) {
      isButtonDisabled = false;
    }
    if ((line1 === '' || name === '') && (returnAddressLogo === '' || returnAddressLogo === undefined)) {
      isButtonDisabled = true;
    }
    this.setState({
      name,
      description,
      line1,
      line2,
      attention,
      altAddressId,
      city,
      zip,
      state,
      logoStyle,
      returnAddressLogo,
      errorArray,
      isButtonDisabled,
      labelRadLogo: labelRadLogoCust,
      labelRadLogoStyle: labelRadLogoStyleCust,
      labelName: labelNameCust,
      labelAddress1: labelAddress1Cust,
      infoHeader1Style,
      infoHeader2Style,
      infoHeader3Style,
      infoHeader4Style,
      infoHeader5Style,
    });
  };

  checkUnsavedChanges = event => {
    if (event.target) {
      const { name, value } = event.target;
      this.setState(
        {
          [name]: value,
        },
        () => {
          this.validateFormInputs(name);
        },
      );
    }
  };

  checkUnsavedChangesForImage = (key, data) => {
    const name = key;
    const value = data;
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.validateFormInputs();
      },
    );
  };

  getRuleVersion = record => {
    try {
      const { status, version } = record;
      const testVersion = version[status.indexOf('T')];
      if (testVersion) return testVersion;
      const liveVersion = version[status.indexOf('L')];
      return liveVersion;
    } catch (e) {
      console.log(e);
    }
    return null;
  };

  onCancel = () => {
    const { setIsShowingAddNewAddressModal } = this.props;
    setIsShowingAddNewAddressModal(false);
    this.setState({ isButtonDisabled: true });
  };

  onSave = () => {
    const {
      postAddNewAddressElements,
      selectedRuleRecord,
      selectedRecord,
      selectedRuleDetail,
      selectedRuleTypeId,
    } = this.props;
    const {
      description,
      name,
      state,
      city,
      line2,
      line1,
      attention,
      zip,
      logoStyle,
      altAddressId,
      status,
    } = this.state;

    const record = selectedRuleRecord || selectedRecord;
    const ruleDetailId = selectedRuleDetail.id;
    const ruleId = record.id;
    const {returnAddressLogoImageId} = this.state;
    const logoName = returnAddressLogoImageId || 'default';

    const ruleVersion = this.getRuleVersion(record);
    const ruleType = selectedRuleTypeId;
    const zipCode = zip.replace(/-/g, '');
    const ruleDetail = {
      description,
      name,
      state,
      city,
      line2,
      line1,
      attention,
      zip: zipCode,
      logoName,
      logoStyle,
      altAddressId,
      status,
    };

    postAddNewAddressElements({
      ruleDetail,
      ruleType,
      ruleId,
      ruleVersion,
      ruleDetailId,
    });
  };

  populateDataDropDown = name => {
    const { returnAddressElements } = this.props;
    if (!_.isEmpty(returnAddressElements)) {
      if (returnAddressElements) {
        let options = [];
        let selectedList = '';

        if (name === 'stateList') selectedList = 'stateList';
        if (name === 'addressLogoList') selectedList = 'addressLogoList';
        if (name === 'statusList') selectedList = 'statusList';
        if (name === 'altAddressList') selectedList = 'altAddressList';
        if (name === 'logoStyleList') selectedList = 'logoStyleList';

        const arr = returnAddressElements[selectedList];
        options = arr.map(r => ({
          label: r.value,
          value: r.code,
        }));
        return options;
      }
    }
    return [];
  };

  // called when image is selected from image library
  selectedImageData = (e, imageKey, imageSrc, imageAltLabel, imageId, isTestedStatus) => {
    if (imageKey !== null && imageSrc !== null && imageAltLabel !== null) {
      this.setState({
        returnAddressLogo: imageAltLabel, 
        returnAddressLogoImage: imageSrc,
        returnAddressLogoImageId: imageId, 
        isTested: isTestedStatus 
      });
      this.checkUnsavedChangesForImage('setDirty','');
    }
  };

  // called when image library cancel button is clicked to close image library modal
  onCancelImageLibrary = () => {
    const { setIsShowingImageLibraryModal, resetRuleImagesData, selectedRuleTypeId} = this.props;
    resetRuleImagesData({selectedRuleTypeId});
    setIsShowingImageLibraryModal(false);
    this.setState({searchHasError:false, searchErrorMessage:''})
  };

  // called when image is selected from image library
  selectedImageControl = (e, actionType, uniqueId) => {
    const {handleImageAction} = this;
    handleImageAction(e, actionType, uniqueId);
    this.setState({selectedUploadViewConrolId: uniqueId, mode: actionType });
  };

  // called by selectedImageControl to enable action
  handleImageAction = (event, actionType, uniqueId) => {
    const {
      setIsShowingImageLibraryModal,
      setIsShowingImageUploadModal,
      selectedRuleDetail,
      selectedRuleTypeId,
      getRuleImages,
      getRuleImagesCount,
      setIsShowingCommitImageModal
    } = this.props;
    const selectedRecordIdImageLibrary =
      selectedRuleDetail[uniqueId] !== undefined
        ? selectedRuleDetail[uniqueId]
        : '0';
    if (actionType === 'library') {
      const offset = null;
      getRuleImages({selectedRuleTypeId, offset}); // to pull records
      getRuleImagesCount({selectedRuleTypeId}); // to pull count
      setIsShowingImageLibraryModal(
        selectedRuleTypeId,
        selectedRecordIdImageLibrary,
        true,
      );
      this.setState({keyword:''});
    } else if (actionType === 'remove') {
      const {name, line1} = this.state;
      if (name === '' || line1 === '') {
        this.setState({returnAddressLogo: '', returnAddressLogoImage: '',
          returnAddressLogoImageId: '', isButtonDisabled : true });
      } else {
        this.setState({returnAddressLogo: '', returnAddressLogoImage: '',
          returnAddressLogoImageId: ''});
      }
      this.checkUnsavedChangesForImage('setDirty','');
    } else if (actionType === 'commit') {
      const {returnAddressLogo, returnAddressLogoImageId} = this.state;
      this.setState ({selectedDataToCommit: {imageName: returnAddressLogo, imageId: returnAddressLogoImageId}}, () => {
        setIsShowingCommitImageModal({status:true, workflow:'ruledetail'});
      });
    } else {
      setIsShowingImageUploadModal(
        selectedRuleTypeId,
        selectedRecordIdImageLibrary,
        true,
      );
    }
    this.setState({ selectedRecordIdImageLibrary });
  };

  getKeyword = () => {
    return document.getElementById("image-filter").value;
  }
 
  userSearchWithinImages = () => {
    let {keyword} = this.state;
    const {searchToggle} = this.state;
    const checkValue = /^[a-zA-Z0-9-_ ]*$/;
    keyword = this.getKeyword();
    this.setState({searchToggle:!searchToggle});
    if (checkValue.test(keyword) && (keyword.length === 0 || keyword.length >= 3)) {
      this.setState({keyword})
      this.setState({searchHasError:false})
    } else {
      this.setState({searchHasError:true})
      this.setState({searchErrorMessage:'Please enter at least three valid characters - alphanumeric, space, - or _ is allowed.'})
    } 
    this.updateImageLibraryGrid();
  };

  updateImageLibraryGrid = () => {
    const {
      selectedRuleTypeId,
      getRuleImages,
      getRuleImagesCount,
      resetRuleImagesData
    } = this.props;
    let {keyword} = this.state;
    keyword = this.getKeyword();
    const checkValue = /^[a-zA-Z0-9-_ ]*$/;
    // only make call if valid search
    if(checkValue.test(keyword) && (keyword.length === 0 || keyword.length >= 3)) {
      resetRuleImagesData({selectedRuleTypeId});
      getRuleImages({selectedRuleTypeId, offset:null, keyword}); // to pull records
      getRuleImagesCount({selectedRuleTypeId, keyword}); // to pull count
    }
  }

  loadMoreRecords = (offset) => {
    const { selectedRuleTypeId, getRuleImages } = this.props
    const checkValue = /^[a-zA-Z0-9-_ ]*$/;
    const {keyword} = this.state;
    if (checkValue.test(keyword) && keyword.length >= 3) {
      getRuleImages({selectedRuleTypeId, offset, keyword}); // pull records w/keyword after valid search
    } else {
      getRuleImages({selectedRuleTypeId, offset}); // pull records  wo/keyword after invalid search
    }
  }

  handleAcceptImageUpload = (e) => {
    this.setState({isButtonDisabled:false });
  }

  // called when image upload cancel button is clicked to close image upload modal
  onCancelImageUpload = () => {
    const { setIsShowingImageUploadModal } = this.props;
    setIsShowingImageUploadModal(false);
  };

  render() {
    const {
      checkUnsavedChanges,
      selectedImageControl,
      onCancelImageLibrary,
      selectedImageData,
      loadMoreRecords,
      handleAcceptImageUpload,
      userSearchWithinImages,
      getKeyword
    } = this;
    const {
      loading,
      selectedRecord,
      selectedRuleRecord,
      isShowingAddNewAddressModal,
      isShowingImageLibraryModal,
      setIsShowingImageLibraryModal,
      selectedRuleDetail,
      selectedRuleTypeId,
      ruleImages,
      ruleImagesCount,
      convertImageUpload,
      convertedImageUploadData,
      convertedImageUploadError,
      isShowingImageUploadModal,
      setIsShowingImageUploadModal,
      postImageUpload,
      isShowingCommitImageModal
    } = this.props;
    const {
      isButtonDisabled,
      description,
      name,
      state,
      city,
      line2,
      line1,
      attention,
      zip,
      logoStyle,
      altAddressId,
      status,
      labelRadLogo,
      labelRadLogoStyle,
      labelName,
      labelAddress1,
      errorArray,
      infoHeader1Style,
      infoHeader2Style,
      infoHeader3Style,
      infoHeader4Style,
      infoHeader5Style,
      selectedRecordIdImageLibrary,
      returnAddressLogo,
      returnAddressLogoImage,
      selectedDataToCommit,
      isTested,
      searchHasError,
      searchErrorMessage,
      searchToggle
    } = this.state;

    const ruleVersion = this.getRuleVersion(selectedRuleRecord);
    const recordId = selectedRuleRecord
      ? selectedRuleRecord.id
      : selectedRecord.id;

    const selectOption = { label: 'Select', value: 'none' };
    const stateListOptions = this.populateDataDropDown('stateList');
    const statusListOptions = this.populateDataDropDown('statusList');
    const altAddressIdListOptions = this.populateDataDropDown('altAddressList');
    const logoStyleListOptions = this.populateDataDropDown('logoStyleList');

    stateListOptions.unshift(selectOption);


    const returnAddressLogoImageKey = returnAddressLogo || null;
    const returnAddressLogoImageSrc = returnAddressLogoImage || null;
    const returnAddressLogoImageAltLabel = returnAddressLogo || null;

    const isFirstRuleRequired =
      (infoHeader1Style && infoHeader1Style.color === '#bf1616') ||
      errorArray.logoStyleError;

    return (
    <>
      {isShowingImageLibraryModal ? (
        <Modal
          domID="image-library-modal"
          isOpen={isShowingImageLibraryModal}
          onModalToggle={onCancelImageLibrary}
        >
          <RuleTypesDetailRow>
            <DetailHeader
              domID="header-title-image-library"
              title="Return Address Library"
              marginBottom={0}
            />
          </RuleTypesDetailRow>
          <ImageFilter
            domID="image-filter"
            onEnterPress={userSearchWithinImages}
            onButtonClick={userSearchWithinImages}
            placeholder="Search by Image Name"
            hasError={searchHasError}
            errorMessage={searchHasError ? searchErrorMessage : null}
            buttonContent={<SearchButtonImage alt="search" src={searchIcon} />}
          />
          <ImageLibraryModal
            loading={loading}
            ruleImages={ruleImages}
            ruleImagesCount={ruleImagesCount}
            searchToggle={searchToggle}
            getKeyword={getKeyword}
            setIsShowingImageLibraryModal={setIsShowingImageLibraryModal}
            selectedRuleDetail={selectedRuleDetail}
            selectedRecordIdImageLibrary={selectedRecordIdImageLibrary}
            selectedRuleTypeId={selectedRuleTypeId}
            selectedImageData={(e, imageKey, imageSrc, imageAltLabel, imageId, isTestedStatus) =>
              selectedImageData(e, imageKey, imageSrc, imageAltLabel, imageId, isTestedStatus)
            }
            loadMoreRecords={(offset) => loadMoreRecords(offset)}
            onCancelImageLibrary={onCancelImageLibrary}
          />
        </Modal>
      ) : null}
      {isShowingImageUploadModal ? (
        <Modal 
          domID="image-upload-modal"
          isOpen={isShowingImageUploadModal}
          onModalToggle={this.onCancelImageUpload}
        >
          <HeaderTitle><DetailHeader
            domID="header-title-image-upload"
            title="Logo Upload"
            marginBottom={0}
          /></HeaderTitle>
          <center><span className="choose-caption">
            Choose your own logo and upload it. Your logo will be
            added to the library.
          </span></center>
          <FileUpload
            loading={loading}
            postImageUpload={postImageUpload}
            setIsShowingImageUploadModal={setIsShowingImageUploadModal}
            selectedRuleTypeId={selectedRuleTypeId}
            fileUploadMessage="Drag a jpg, png, jpeg, tiff, tif, gif, bmp file here. Max 2MB. Max dimensions 1280x720."
            acceptedFileTypes="jpg,png,jpeg,tiff,tif,gif,bmp"
            fileSizeAllowed={2106286} // bytes == 2.00 Mb
            allowMultiple={false}
            convertImageUpload={convertImageUpload}
            convertedImageUploadData={convertedImageUploadData}
            convertedImageUploadError={convertedImageUploadError}
            handleAcceptImageUpload={(e) =>
              handleAcceptImageUpload(e)
            }
          />
        </Modal>
      ) : null}
      {isShowingCommitImageModal !== undefined && isShowingCommitImageModal.status === true ? (
        <CommitImageModal
          {...this.props}
          workflow={isShowingCommitImageModal ? isShowingCommitImageModal.workflow : null}
          selectedDataToCommit={selectedDataToCommit}
          ruleId={recordId}
          ruleVersion={ruleVersion}
        />
      ) : null}
	      <Modal
	        isOpen={isShowingAddNewAddressModal}
	        onModalToggle={() => this.onCancel()}
	      >
	        <AddReturnAddressHeader
	          domID="test-id"
	          title="Add a Return Address"
	          description="Complete the information below, then hit Save to continue."
	        />
	        <Title>
	          <span className="infoHeader1" style={{ ...infoHeader1Style }}>
	            * At least 2 of the 3 fields are required: Name, Address 1, Return Address Logo (and Return Logo Style).
	          </span>
	          <br />
	          <span className="infoHeader2" style={{ ...infoHeader2Style }}>
	            * Return Address Logo and Return Logo Style must be selected
	            together.
	          </span>
	          <br />
	          <span className="infoHeader3" style={{ ...infoHeader3Style }}>
	            * Alternate Address is required if address contains a P.O. Box.
	          </span>
	          <br />
	          <span className="infoHeader4" style={{ ...infoHeader4Style }}>
	            * You can complete either Attention or Address 2 but not both.
	          </span>
	          <br />
	          <span className="infoHeader5" style={{ ...infoHeader5Style }}>
	            * The Name field and Address 1 field are required unless Return Logo Style is Logo Only.
	          </span>
	        </Title>

	        <ContainerBox>
	          <InputControl
	            inputDomID="add-new-address-description"
	            inputLabel="description*"
	            inputName="description"
	            inputInitialValue={description}
	            inputOnChangeHandle={checkUnsavedChanges}
	            inputErrorMessage={errorArray.descriptionErrorMessage || ''}
	            inputHasError={errorArray.descriptionError || false}
	            inputStyles={{
	              width: '450px',
	              marginBottom: '12px',
	              marginTop: '12px',
	            }}
	            inputOnBlurHandle={() => null}
	            inputOnKeyDown={() => null}
	            inputMaxLength={51}
	          />
	        </ContainerBox>
	        <ContainerBox>
	          <InputControl
	            inputDomID="add-new-address-name"
	            inputLabel={labelName}
	            inputName="name"
	            inputInitialValue={name}
	            inputOnChangeHandle={checkUnsavedChanges}
	            inputErrorMessage={errorArray.nameErrorMessage || ''}
	            inputHasError={errorArray.nameError || false}
	            inputStyles={{
	              width: '450px',
	              marginBottom: '12px',
	              marginTop: '12px',
	            }}
	            inputOnBlurHandle={() => null}
	            inputOnKeyDown={() => null}
	            inputMaxLength={41}
	          />
	          <InputControl
	            inputDomID="add-new-address-attention"
	            inputLabel="attention"
	            inputName="attention"
	            inputInitialValue={attention}
	            inputOnChangeHandle={checkUnsavedChanges}
	            inputErrorMessage={errorArray.attentionErrorMessage || ''}
	            inputHasError={errorArray.attentionError || false}
	            inputStyles={{
	              width: '230px',
	              marginBottom: '12px',
	              marginTop: '12px',
	            }}
	            inputOnBlurHandle={() => null}
	            inputOnKeyDown={() => null}
	          />
	        </ContainerBox>
	        <ContainerBox>
	          <InputControl
	            inputDomID="add-new-address-line1"
	            inputLabel={labelAddress1}
	            inputName="line1"
	            inputInitialValue={line1}
	            inputOnChangeHandle={checkUnsavedChanges}
	            inputErrorMessage={errorArray.line1ErrorMessage || ''}
	            inputHasError={errorArray.line1Error || false}
	            inputStyles={{
	              width: '450px',
	              marginBottom: '12px',
	              marginTop: '12px',
	            }}
	            inputOnBlurHandle={() => null}
	            inputOnKeyDown={() => null}
	            inputMaxLength={65}
	          />
	          <InputControl
	            inputDomID="add-new-address-line2"
	            inputLabel="address 2"
	            inputName="line2"
	            inputInitialValue={line2}
	            inputOnChangeHandle={checkUnsavedChanges}
	            inputErrorMessage={errorArray.line2ErrorMessage || ''}
	            inputHasError={errorArray.line2Error || false}
	            inputStyles={{
	              width: '230px',
	              marginBottom: '12px',
	              marginTop: '12px',
	            }}
	            inputOnBlurHandle={() => null}
	            inputOnKeyDown={() => null}
	            inputMaxLength={null}
	          />
	        </ContainerBox>
	        <ContainerBox>
	          <InputControl
	            inputDomID="add-new-address-city"
	            inputLabel="city*"
	            inputName="city"
	            inputInitialValue={city}
	            inputOnChangeHandle={checkUnsavedChanges}
	            inputErrorMessage={errorArray.cityErrorMessage || ''}
	            inputHasError={errorArray.cityError || false}
	            inputStyles={{
	              width: '450px',
	              marginBottom: '12px',
	              marginTop: '12px',
	            }}
	            inputOnBlurHandle={() => null}
	            inputOnKeyDown={() => null}
	            inputMaxLength={26}
	          />
	          <DropDownControl
	            dropDownDomID="add-new-address-state"
	            dropDownLabel="state*"
	            dropDownName="state"
	            dropDownOptions={stateListOptions}
	            dropDownInitialValue={state}
	            dropDownOnChangeHandle={checkUnsavedChanges}
	            dropDownStyles={{
	              width: '350px',
	              marginBottom: '12px',
	              marginTop: '12px',
	            }}
	            dropDownHasError={errorArray.stateError || false}
	            dropDownErrorMessage={errorArray.stateErrorMessage || ''}
	          />
	          <InputControl
	            inputDomID="add-new-address-zip"
	            inputLabel="zip code*"
	            inputName="zip"
	            inputInitialValue={zip}
	            inputOnChangeHandle={checkUnsavedChanges}
	            inputErrorMessage={errorArray.zipErrorMessage || ''}
	            inputHasError={errorArray.zipError || false}
	            inputStyles={{
	              width: '450px',
	              marginBottom: '12px',
	              marginTop: '12px',
	            }}
	            inputOnBlurHandle={() => null}
	            inputOnKeyDown={() => null}
	            inputMask="99999-99999"
	          />
	        </ContainerBox>
	        <ContainerBox>
	          <ReturnAddressLogoImageWrapper className={isFirstRuleRequired ? "first-rule-required" : ''}>
	            <UploadAndViewImageControl
	              uniqueId="returnAddressLogo"
	              headerText={labelRadLogo}
	              libraryButtonClassName="library-link"
	              libraryButtonText="Library"
	              uploadButtonClassName="upload-link"
	              uploadButtonText="Upload"
	              onClickHandleLibrary={e =>
	                selectedImageControl(e, 'library', 'returnAddressLogo')
	              }
	              onClickHandleUpload={e =>
	                selectedImageControl(e, 'upload', 'returnAddressLogo')
	              }
	              onClickHandleRemove={e =>
	                selectedImageControl(e, 'remove', 'returnAddressLogo')
	              }
	              onClickHandleCommit={e =>
	                selectedImageControl(e, 'commit', 'returnAddressLogo')
	              }
	              imageKey={returnAddressLogoImageKey}
	              imageSrc={returnAddressLogoImageSrc}
	              imageAltLabel={returnAddressLogoImageAltLabel}
	              isTested={isTested}
	            />
	           {/*  {(!returnAddressLogoImageKey && _.isEmpty(line1)) ? <span className="r1-required-err">{labelLogoName} is required</span> : null} */}
	          </ReturnAddressLogoImageWrapper>
	          <DropDownControl
	            dropDownDomID="add-new-address-return-logo-style"
	            dropDownLabel={labelRadLogoStyle}
	            dropDownName="logoStyle"
	            dropDownOptions={logoStyleListOptions}
	            dropDownInitialValue={logoStyle}
	            dropDownOnChangeHandle={checkUnsavedChanges}
	            dropDownStyles={{
	              width: '230px',
	              marginBottom: '12px',
	              marginTop: '12px',
	            }}
	            dropDownHasError={errorArray.logoStyleError || false}
	            dropDownErrorMessage={errorArray.logoStyleErrorMessage || ''}
	            dropDownDisabled={returnAddressLogo === null || returnAddressLogo === ''}
	          />
	        </ContainerBox>
	        <ContainerBox>
	          <DropDownControl
	            dropDownDomID="add-new-address-alternative-address"
	            dropDownLabel="alternative address"
	            dropDownName="altAddressId"
	            dropDownOptions={altAddressIdListOptions}
	            dropDownInitialValue={altAddressId}
	            dropDownOnChangeHandle={checkUnsavedChanges}
	            dropDownStyles={{
	              width: '720px',
	              marginBottom: '12px',
	              marginTop: '12px',
	            }}
	            dropDownHasError={errorArray.altAddressIdError || false}
	            dropDownErrorMessage=""
	          />
	        </ContainerBox>
	        <ContainerBox>
	          <DropDownControl
	            dropDownDomID="add-new-address-address-status"
	            dropDownLabel="address status"
	            dropDownName="status"
	            dropDownOptions={statusListOptions}
	            dropDownInitialValue={status}
	            dropDownOnChangeHandle={checkUnsavedChanges}
	            dropDownStyles={{
	              width: '180px',
	              marginBottom: '12px',
	              marginTop: '12px',
	            }}
	            dropDownHasError={false}
	            dropDownErrorMessage=""
	          />
	        </ContainerBox>
	        <ButtonContainerWrap>
	          <ButtonGroup>
	            <ModalButton>
	              {loading.has(recordId) && (
	                <LoadingWrapper>
	                  <LoadingIndicator
	                    domID="basic-details-form-loading-indicator"
	                    length="30px"
	                  />
	                </LoadingWrapper>
	              )}
	              <Button
	                onClick={() => this.onSave()}
	                domID="automation-id"
	                name="Save"
	                buttonType="emphasized"
	                size="medium"
	                isDropdown={false}
	                type="button"
	                disabled={isButtonDisabled || loading.has(recordId)}
	              />
	              <Button
	                domID="automation-id"
	                name="Cancel"
	                buttonType="diminished"
	                disabled={loading.has(recordId)}
	                size="medium"
	                onClick={() => this.onCancel()}
	                isDropdown={false}
	                type="button"
	              />
	            </ModalButton>
	          </ButtonGroup>
	        </ButtonContainerWrap>
	      </Modal>
      </>
    );
  }
}
AddNewAddressModal.propTypes = {
  setIsShowingAddNewAddressModal: PropTypes.func,
  postAddNewAddressElements: PropTypes.func,
  selectedRuleRecord: PropTypes.object,
  selectedRecord: PropTypes.object,
  selectedRuleDetail: PropTypes.object,
  setIsShowingImageLibraryModal: PropTypes.func,
  isShowingImageLibraryModal: PropTypes.bool,
  selectedRuleTypeId: PropTypes.string,
  returnAddressElements: PropTypes.object,
  getRuleImages: PropTypes.func,
  ruleImages: PropTypes.object,
  getRuleImagesCount: PropTypes.func,
  ruleImagesCount: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  resetRuleImagesData: PropTypes.func,
  postImageUploadSuccessId: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  isShowingCommitImageModal: PropTypes.object,
  convertImageUpload: PropTypes.func,
  convertedImageUploadData: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  convertedImageUploadError: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  isShowingImageUploadModal: PropTypes.bool,
  setIsShowingImageUploadModal: PropTypes.func,
  postImageUpload: PropTypes.func,
  isShowingAddNewAddressModal: PropTypes.bool,
  loading: PropTypes.object,
  postImageCommitSuccessId: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
};

export default AddNewAddressModal;