import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { 
  ButtonGroup, 
  Button, 
  Grid, 
  Modal, 
  ModalHeader, 
  ModalBody
} from 'ui-core/dist'
import axios from '../../axios'
import { AnchorInput, Loading } from '../../components'
import useUserManagerAccess from '../../api/useUserManagerAccess'
import Authorizer from '../Authorizer'
import RoleModal from './RoleModal'
import DataSourceModal from './DataSourceModal'
import GrantAccessModal from './AccessEntryModal'
import useNotificationBannerHook from '../../useNotificationBannerHook';
import './modules/styles.scss'

const AccessTab = ({ userId }) => {
  const [ selected, setSelected ] = useState([])
  const [ isRoleOpen, setIsRoleOpen ] = useState(false)
  const [ isAccessOpen, setIsAccessOpen ] = useState(false)
  const [ isDataSourceOpen, setIsDataSourceOpen ] = useState(false)
  const [ roleId, setRoleId ] = useState()
  const [ dataSourceId, setDataSourceId ] = useState()
  const [ revokeModal, setRevokeModal ] = useState(false)
  const [ inFlight, setInFlight ] = useState(false)
  const { data, isLoading, refetch } = useUserManagerAccess({ userId })

  const canViewRole = useSelector(({ context }) => 
    context?.permissions?.filter(p => p.name === 'CAN_PERFORM_SECURITY_ROLE_VIEW').length > 0)
  const canViewDataSource = useSelector(({ context }) => 
    context?.permissions?.filter(p => p.name === 'CAN_PERFORM_SECURITY_DATASOURCE_VIEW').length > 0)
  const canDelete = useSelector(({ context }) => 
    context?.permissions?.filter(p => p.name === 'CAN_PERFORM_SECURITY_USER_ACCESS_DELETE').length > 0)
  const { notify } = useNotificationBannerHook()
    
  const onRowSelect = (e, state) => {
    // e.stopPropagation()
    const ids = Array.from(state.selectedItemIds);
    setSelected(ids);
  }

  const onSelectAll = (e, state) => {
    // e.stopPropagation()
    const ids = Array.from(state.selectedItemIds);
    setSelected(ids);
  }  
  
  const onRevoke = () => setRevokeModal(true)
  
  const onCloseRevoke = () => setRevokeModal(false)
  
  const onCancelRevoke = () => setRevokeModal(false)
  
  const onConfirmRevoke = async () => {
    setInFlight(true)
    try {
      await axios.delete(`/members/${userId}/access/${selected}`)
      notify(`You have successfully revoked an access entry for the user ${userId}`, 'positive')
      refetch()
    } catch(err) {
      notify(err.displayMessage, 'negative')
    } finally {
      setInFlight(false)
      setRevokeModal(false)
      setSelected([])
    }
  }

  const onDataSource = (id) => {
    setIsDataSourceOpen(true)
    setDataSourceId(id)
  }

  const onGrantAccess = () => {
    setIsAccessOpen(true)
  }

  const onRole = (id) => {
    setIsRoleOpen(true)
    setRoleId(id)
  }

  const onRoleClose = () => {
    setIsRoleOpen(false)
    setRoleId()
  }

  const onDataSourceClose = () => {
    setIsDataSourceOpen(false)
    setDataSourceId()
  }

  const onAccessClose = () => {
    setIsAccessOpen(false)
  }

  return (
    <>
      <ButtonGroup style={{ marginBottom: '12px' }}>
        <Authorizer permission = "CAN_PERFORM_SECURITY_USER_ACCESS_ADD">
        <Button 
          domID="GrantAccessButton"
          onClick={onGrantAccess}
          name="Grant Access Entry"
          buttonType="standard"
          size="small"
        />
        </Authorizer>
        <Authorizer permission="CAN_PERFORM_SECURITY_USER_ACCESS_DELETE">
          <Button 
            domID="RevokeButton"
            onClick={onRevoke}
            name="RevokeEntry"
            buttonType="standard"
            size="small"
            disabled={!(selected.length === 1)}
          />
        </Authorizer>
      </ButtonGroup>
      {isLoading ? <Loading /> : data && data.length ? (
        <>
          <Grid 
            columns={new Set([
              {
                dataName: 'role',
                text: 'Role',
                cellType: 'custom',
                customComponent: props => canViewRole ? (
                  <AnchorInput
                    id='fileKey'
                    type='button'
                    className='fileKeyLink'
                    onClick={() => onRole(props.id)}
                  >{props.name}</AnchorInput>
                ) : (<>{props.name}</>)
              },
              {
                dataName: 'dataSource',
                text: 'Data Source',
                cellType: 'custom',
                customComponent: props => canViewDataSource ? (
                  <AnchorInput
                    id='fileKey'
                    type='button'
                    className='fileKeyLink'
                    onClick={() => onDataSource(props.id)}
                  >{props.description}</AnchorInput>
                ) : (<>{props.description}</>)
              },
            ])}
            records={data || []}
            dataTestId="test-accessTabGrid"
            domID="accessTabGrid"
            selectionKey="accessEntryId"
            selectAllCheckboxDomID="accessTabGridSelectAll"
            onRowSelect={onRowSelect}
            onSelectAll={onSelectAll}
            supportSelection={canDelete}
          />
        </>
      ) : <></>}
      {revokeModal && (
      <Modal isOpen={revokeModal} className="access-entry-modal">
        <ModalHeader 
          title="Confirm Revoke an Access List Entry"
          description={`Click Confirm to revoke this access list entry from ${userId}`}
          onClose={onCloseRevoke}
        />
        <ModalBody className="access-entry-modal__contents">
          <p>Details for Access List Entry {selected}</p>
          <div className="access-entry-modal__row">
            <span className="access-entry-modal__label">Role:</span>
            <span className="access-entry-modal__info">{data?.filter(d => d.accessEntryId === `${selected}`)[0]?.role.name}</span>
          </div>
          <div className="access-entry-modal__row">
            <span className="access-entry-modal__label">Data Source:</span>
            <span className="access-entry-modal__info">{data?.filter(d => d.accessEntryId === `${selected}`)[0]?.dataSource.description}</span>
          </div>
          <ButtonGroup className="access-entry-modal__button-group">
            <Button 
              domID="accessEntryCancelButton"
              name="Cancel"
              onClick={onCancelRevoke}
              size="small"
              buttonType="standard"
              disabled={inFlight}
            />
            <Button 
              domID="accessEntryConfirmButton"
              name="Confirm"
              onClick={onConfirmRevoke}
              size="small"
              buttonType="standard"
              disabled={inFlight}
            />
          </ButtonGroup>
        </ModalBody>
      </Modal>
      )}
      {isAccessOpen && <GrantAccessModal 
        isAccessOpen={isAccessOpen}
        onAccessClose={onAccessClose}
        userId={userId}
        confirmationCallback={refetch}
      />}
      {isRoleOpen && <RoleModal 
        isRoleOpen={isRoleOpen} 
        onRoleClose={onRoleClose}
        roleId={roleId}
      />}
      {isDataSourceOpen && <DataSourceModal 
        isDataSourceOpen={isDataSourceOpen}
        onDataSourceClose={onDataSourceClose}
        dataSourceId={dataSourceId}
      />}
    </>
  )
}

AccessTab.propTypes = {
  userId: PropTypes.string
}

AccessTab.defaultProps = {
  userId: undefined
}

export default AccessTab