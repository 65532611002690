import React from 'react';
import PropTypes from 'prop-types';
import pick from 'lodash/pick';

import DuplexRuleModal from './DuplexRuleModal/index';
import AutoHoldModal from './AutoHoldModal/index';
import MemberIdModal from './MemberIdModal/index';
import ReturnAddressModal from './ReturnAddressModal/index';
import ProductionArrangementModal from './ProductionArrangementModal';
import SignatureRuleModal from './SignatureRuleModal/index';
import CheckTextRuleModal from './CheckTextRuleModal';
import TaxIdArrangementRuleModal from './TaxIdArrangementRuleModal/index';
import ImageRuleModal from './ImageRuleModal';
import ErisaMessageModal from './ErisaMessageModal/index';
import HighDollarReviewModal from './HighDollarReviewModal/index';
import ProviderInsuredOptionMessageModal from './ProviderInsuredOptionMessageModal/index';
import GeneralMessageModal from './GeneralMessageModal/index';
import CustomerServiceMessageModal from './CustomerServiceMessage';
import PDFTaggingModal from './PDFTaggingModal/index';

import { RULE_DETAILS_MODAL_PROPS_MAPPER } from './constants';

const RuleDetailsModal = props => {
  const { selectedRuleTypeId } = props;
  /**
   * Here we are setting the modal components themselves inside of the object
   * The KEY property of the modal MUST be the selectedRuleTypeId that is used for that specific modal
   * The VALUE property must be the actual modal component being used for the RuleDetailsModal component
   */
  const modals = {
    CSM: CustomerServiceMessageModal,
    PAR: ProductionArrangementModal,
    DPX: DuplexRuleModal,
    SSN: MemberIdModal,
    GEN: GeneralMessageModal,
    ERI: ErisaMessageModal,
    CHT: CheckTextRuleModal,
    PIO: ProviderInsuredOptionMessageModal,
    SIG: SignatureRuleModal,
    TAX: TaxIdArrangementRuleModal,
    IMG: ImageRuleModal,
    RAD: ReturnAddressModal,
    HLD: AutoHoldModal,
    HDR: HighDollarReviewModal,
    PDT: PDFTaggingModal
  };
  // Turning the specific modal into a component based off of the selectedRuleTypeId being passed in
  const Modal = modals[selectedRuleTypeId];
  // Getting the needed props from the RULE_DETAILS_MODAL_PROPS_MAPPER depending on the selectedRuleTypeId
  const getPropsFromMapper = RULE_DETAILS_MODAL_PROPS_MAPPER[selectedRuleTypeId];
  /**
   * Here we are taking the props needed for a specific modal and picking them off of ALL the props being passed to the modal component
   * As a failsafe, if there is not any props specified inside of the constants file, we will just give it ALL props (NOT IDEAL)
   */
  const modalPropsFromMapper = getPropsFromMapper ? pick(props, getPropsFromMapper) : props;

  return <Modal {...modalPropsFromMapper} />;
};

RuleDetailsModal.propTypes = {
  selectedRuleTypeId: PropTypes.string.isRequired
};

export default RuleDetailsModal;