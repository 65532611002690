const initialState = {
  isAddUser: true,
  copyId: undefined
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'user_manager_is_add_user':
      return {
        ...state,
        isAddUser: payload
      }
    case 'user_manager_set_id':
      return {
        ...state,
        copyId: payload
      }
    default:
      return state
  }
}