import styled from 'styled-components';

export const DropOne = styled.div`
  margin-bottom: 30px;
`;
export const DropTwo = styled.div`
  margin-bottom: 30px;
`;
export const DropThree = styled.div`
  margin-bottom: 30px;
`;

export const ContainerBox = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
`;
export const AutoHoldDropDown = styled.div`
  height: 50px;
  width: 200px;
  margin-right: 35px;
  margin-top: 10px;
`;
