import React, { useState } from 'react'
import {
  NestedGrid,
  Section,
  useLayout,
  ListPaneHeader,
  Button,
  Radio,
} from 'ui-core/dist'
import { SelectDropdown } from '../../components'
import axios from '../../axios';
import useNotificationBannerHook from '../../useNotificationBannerHook';

import { useLayoutsRequest } from '../../api/useReportManager';
import { LoadingPage } from '../../components';
import ErrorPage from '../ErrorPage';
import withAxiosCancellation from '../../components/withAxiosCancellation';
import './modules/style.scss'

const DataScriptReport = ({getCancelSourceWithName}) => {
  const {
    expandCollapseListPaneButtonConfig
  } = useLayout()
  const { notify } = useNotificationBannerHook()

  const gridColumns = new Set([
    {
      dataName: 'Sequence',
      text: 'SEQUENCE',
      cellType: 'text',
    },
    {
      dataName: 'DataType',
      text: 'DATA TYPE',
      cellType: 'text',
    },
    {
      dataName: 'FieldName',
      text: 'FIELD NAME',
      cellType: 'text',
    },
    {
      dataName: 'Length',
      text: 'LENGTH',
      cellType: 'text',
    },
    {
      dataName: 'Description',
      text: 'DESCRIPTION',
      cellType: 'text',
    }
  ]);

  const { layoutsList: layoutsList, isLoading: pIsLoading,error, isError } = useLayoutsRequest({getCancelSource:getCancelSourceWithName('layoutRequest')});
  const [dataScriptList, setDataScriptList] = useState();
  const [isReportLoading, setIsReportLoading] = useState(false);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [initialLayout, setInitialLayout] = useState({ label: '003', value: '003' });
  const [layoutsValue, setLayoutsValue] = useState('003');
  const handleLayoutOptionChange = (e) => {
    setInitialLayout({ label: e?.label, value: e?.value });
    setLayoutsValue(e?.value);
  }

  const getReport = () => {
    setIsSubmitClicked(true);
    setIsReportLoading(true);
    axios.get(`reports-manager/datascript-report/${layoutsValue}`)
      .then(res => {
        setDataScriptList(res.data.report.TableName);
        setIsReportLoading(false);
      }).catch(err => {
        notify(err.displayMessage, 'negative')
        setIsReportLoading(false);
      })
  }

  return (
    <>
      <ListPaneHeader
        title={`Data Dictionary Reports`}
        layoutControls={[expandCollapseListPaneButtonConfig]}
      />
      {
        isError ? (
          // replace with a better error component
          <ErrorPage
            // this prop isn't real but is an example
            error={error}
          />
        ) :
        pIsLoading ? (
          <LoadingPage height='20px'></LoadingPage>
        ) :
      (
      <div style={{ paddingLeft: '50px', fontSize: '13px' }}>
        <h4>Data Script Report</h4>
        <table id="dataScriptReport">
          <tr>
            {/* <td><div className='dataDictreport__labels'><label>Vendor Layout:</label></div></td> */}
            <td className='layoutsWidth'>
              <SelectDropdown
                    isClearable={false}
                    size="small"
                    placeholderText="Select..."
                    initialValue={initialLayout}
                    options={layoutsList}
                    onChange={(e) => handleLayoutOptionChange(e)}
                    label="Vendor Layout"
                  />
              
            </td>
          </tr>
          <tr style={{ marginTop: '20px' }}><td style={{ marginTop: '20px' }}><Button
            buttonType="standard"
            size="small"
            name="Submit"
            onClick={() => { getReport() }}
          /></td></tr>
        </table>
        <div style={{ paddingTop: '10px' }}>
          {
            isSubmitClicked ? (isReportLoading ? (<LoadingPage></LoadingPage>)
              : (dataScriptList && dataScriptList.map((table, index) =>
                <Section title={'Data Script Report for Layout: ' + table.Layout}
                  description={'Table Name:   ' + table.TableNme}
                  count={table.Details.length}
                  isAccordion={true}
                  dataTestId={'test-section-dataScriptReport-' + index}
                  key={'section-dataScriptReport-' + index}
                  headerChildren={<div className='dataDictreport__labels'>
                    <td><tr>
                      <label style={{ paddingLeft: '8px' }}>Record Type: </label>
                      <label className='dataDictreport-header__labels'>{table.RecordType}</label>
                      <label style={{ paddingLeft: '8px' }}>Layout: </label>
                      <label className='dataDictreport-header__labels'>{table.Layout}</label>
                    </tr></td>
                  </div>}
                ><NestedGrid
                    className='vendorLayoutGrid'
                    key={'dataScriptReport-' + index}
                    gridProps={{
                      selectionKey: 'Sequence',
                      dataTestId: 'test-dataScriptReport-' + index,
                      columns: gridColumns,
                      records: table.Details,
                      className: 'vendorLayoutGrid'
                    }}
                  /></Section>)))
              : (null)
          }
        </div>
      </div>
      )}
    </>
  )
}

export default withAxiosCancellation(DataScriptReport);