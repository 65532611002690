import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Grid,
    useLayout,
    ListPaneHeader, CheckboxGroup,
    Button, Input, DatePickerV2, TimePicker, RadioGroup, Modal, ModalHeader, ButtonGroup, ModalFooter
} from 'ui-core/dist';
import axios from '../../axios';
import exportFromJSON from 'export-from-json'
import { useNotificationBanner } from 'ui-core/dist';
import useNotificationBannerHook from '../../useNotificationBannerHook';
import { useStatesRequest } from '../../api/useReportManager';
import { LoadingPage, SelectDropdown } from '../../components';
import withAxiosCancellation from '../../components/withAxiosCancellation';
import './modules/style.scss';
import ErrorPage from '../ErrorPage';

const MailDeliveryReport = ({getCancelSourceWithName}) => {
    const {
        expandCollapseListPaneButtonConfig
    } = useLayout();
    const { notify } = useNotificationBannerHook();
    const { statesList: stateOption, isLoading: sIsLoading, error, isError } = useStatesRequest({getCancelSource:getCancelSourceWithName('layoutRequestMailDelivery')});
    const { dataSourceDesc } = useSelector(({ context }) => context?.context || {});
    const cLInfo = dataSourceDesc.split('-')[0].trim();
    const clientId = cLInfo.split('_')[0].trim();
    const LayoutId = cLInfo.split('_')[1].trim();
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const [email, setEmail] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [resolutionModal, setResolutionModal] = useState(false);
    const [radiocount, setradioCount] = useState(0);
    const [radioMailcount, setRadioMailcount] = useState(0);


    const ValidatestartAndDate = () => {
        let startDate = new Date(fromDate);
        let endDate = new Date(toDate);
        return startDate > endDate ? false : true;
    }

    const ValidateFutureDate = () => {
        let startDate = new Date(fromDate);
        let endDate = new Date(toDate);
        return startDate > new Date() || endDate > new Date() ? false : true;
    }

    const showModal = async () => {
        let isinvalidState = true;

        let validZipCodeLength = ["3", "5"]
        if (state == "") {
            if (zipCode == undefined || isNaN(zipCode)) {
                isinvalidState = true;
            }
            else {
                let zipcodeLength = zipCode?.length;
                let filteredResult = validZipCodeLength.filter(e => e?.includes(zipcodeLength));
                if (filteredResult.length > 0) {
                    isinvalidState = false;
                }
            }
        }
        else {
            isinvalidState = false;
        }
        if (!ValidatestartAndDate()) {
            notify(`From Date should be less than To Date.`, 'caution')
        } else if (!ValidateFutureDate()) {
            notify(`Future date not accepted`, 'caution')
        }
        else if (isinvalidState) {
            notify(`Please enter 3 or 5 digits for zip code.`, 'caution')
        }
        else {
            setResolutionModal(true)
        }
    }
    const submitReport = async () => {
        setIsLoading(true);
        let objTosave = {
            client: clientType == "This Client" ? clientId : "ALL",
            state: state,
            zipcode: zipCode ? zipCode : "",
            "fromDate": fromDate,
            "toDate": toDate,
            "mailOption": intialcheckedMailItem.label,
            preSort: presort,
            allEnvelopeTypes: Envelope,
            "emailAddress": email
        }
        let xml = `<![CDATA[<mail-delivery-report>`;
        xml = `${xml}<Client>${objTosave.client}</Client>`;
        xml = `${xml}<State>${objTosave.state}</State>`
        xml = `${xml}<Zipcode>${objTosave.zipcode}</Zipcode>`
        xml = `${xml}<FromDate>${objTosave.fromDate}</FromDate>`
        xml = `${xml}<ToDate>${objTosave.toDate}</ToDate>`
        xml = `${xml}<MailOption>${objTosave.mailOption}</MailOption>`
        xml = `${xml}<PreSort>${objTosave.preSort}</PreSort>`
        xml = `${xml}<AllEnvelopeTypes>${objTosave.allEnvelopeTypes}</AllEnvelopeTypes>`
        xml = `${xml}<EmailAddress>${objTosave.emailAddress}</EmailAddress>`
        xml = `${xml}</mail-delivery-report>]]>`;
        try {
            setIsLoading(true);
            const res = await axios.post(
                `reports-manager/mailDeliveryReport`, {
                strReportParmsXml: xml
            });
            setIsLoading(false);
            setResolutionModal(false);
            notify(`Mail Delivery Report Web Transaction ${res.data.reportId} has been submitted. If you have not received this report within six(6)hours, please contact client service  via customer care hub (https://customercare.changehealthcare.com/public/home.html). If you don't have access to customer care hub, please contact your internal administrator.`, 'positive')
            resetForm();
        } catch (err) {
            setIsLoading(false);
            notify(`${err.displayMessage}`, 'negative');
            setResolutionModal(false);
        }
    }

    const [intialcheckedMailItem, setIntialcheckedMailItem] = useState({
        id: 'id3',
        isChecked: true,
        label: 'All UPSC Mail'
    });
    const rendemailOptionRadio = useMemo(() => (
        <RadioGroup
            key={radioMailcount}
            dataTestId="test-radioBasic-EmailOption"
            items={[
                {
                    id: 'id1',
                    isChecked: false,
                    label: 'Same Day USPS Mail'
                },
                {
                    id: 'id2',
                    isChecked: false,
                    label: 'Next Day USPS Mail'
                },
                {
                    id: 'id3',
                    isChecked: false,
                    label: 'All UPSC Mail'
                }


            ]}
            checkedItem={intialcheckedMailItem}
            label="Mail Option"
            onChange={(e) => {
                setIntialcheckedMailItem({
                    id: e?.item?.id,
                    isChecked: true,
                    label: e?.item?.label
                })
            }}
            orientation="horizontal"
            size="small"
        />
    ), [intialcheckedMailItem, radioMailcount]);

    const [clientType, setClientType] = useState("This Client");
    const [intialCheckedclientType, setIntialCheckedclientType] = useState({
        id: 'id1',
        isChecked: true,
        label: 'This Client'
    });

    const renderClientTypeRadio = useMemo(() => (
        <RadioGroup
            dataTestId="test-radioBasic-clientType"
            items={[
                {
                    id: 'id1',
                    isChecked: false,
                    label: 'This Client'
                },
                {
                    id: 'id2',
                    isChecked: false,
                    label: 'All Clients'
                }

            ]}
            checkedItem={intialCheckedclientType}
            label="Select Client"
            onChange={(e) => {
                setClientType(e?.item?.label);
                setIntialCheckedclientType({
                    id: e?.item?.id,
                    isChecked: true,
                    label: e?.item?.label
                })
            }
            }
            orientation="horizontal"
            size="small"
            key={radiocount}
        />
    ), [intialCheckedclientType, radiocount]);

    const [intialState, setIntialState] = useState({
        label: 'ALL', value: ''
    });
    const [state, setState] = useState("");

    const renderStateDropDown = useMemo(() => (
        <SelectDropdown
            isClearable={false}
            domID="reportType1"
            options={stateOption}
            initialValue={intialState}
            size="small"
            onChange={function handelStateChange(e) {
                setIntialState({
                    label: e?.label,
                    value: e?.value
                });
                setState(e?.value);

            }}
        />
    ), [state, stateOption, intialState]);

    const [zipCode, setZipCode] = useState();

    const renderZipcodeTextBox = useMemo(() => (
        <Input
            aria-label="ARIA Label"
            autoComplete="off"
            dataTestId="test-inputBasic"
            domID="basic-test-id"
            size="small"
            name="zipCode"
            initialValue={zipCode}
            maxLength={5}
            onChange={(e) => {
                setZipCode(e?.target?.value || "");
            }}
        />
    ), [zipCode]);

    const [count, setCount] = useState(0);
    const rendeStartDate = useMemo(() => (
        <DatePickerV2
            key={count}
            domID="renderstartDatePicker"
            errorMessage="Please enter a valid Date"
            initialValue={fromDate}
            hasError={fromDate ? false : true}
            onDateChange={(e) => {
                setFromDate(e ? e.format('MM/DD/YYYY') : '')
            }}
            size="small"

        />
    ), [fromDate, count]);
    const rendeEndDate = useMemo(() => (
        <DatePickerV2
            key={count}
            domID="renderEndDatePicker"
            errorMessage="Please enter a valid Date"
            hasError={toDate ? false : true}
            onDateChange={(e) => {
                setToDate(e ? e.format('MM/DD/YYYY') : '')
            }}
            initialValue={toDate}
            size="small"

        />
    ), [toDate, count]);

    const [presort, setPresort] = useState('No');
    const [Envelope, setEnvelope] = useState('No');

    const [displayOption, setDisplayOption] = useState([
        {
            id: 'id1',
            isChecked: false,
            label: 'Display Detail For All Presort rates'
        },
        {
            id: 'id2',
            isChecked: false,
            label: 'Display Detail For All Envelope Types'
        },

    ]);

    const [displayOptioncount, setDisplayOptioncount] = useState(0);
    const rendeDisplayOption = useMemo(() => (
        <CheckboxGroup
            dataTestId="test-checkboxBasic"
            items={displayOption}
            label="Report Detail Display Options:"
            onChange={function handelDisplayOptionChange(e) {
                if (e?.id == "id1") {
                    if (e?.checked) {
                        setPresort("Yes")
                    }
                    else {
                        setPresort("No")
                    }
                }
                if (e?.id == "id2") {
                    if (e?.checked) {
                        setEnvelope("Yes")
                    }
                    else {
                        setEnvelope("No")
                    }
                }

            }}
            orientation="horizontal"
            size="small"
            key={displayOptioncount}
        />
    ), [displayOption, displayOptioncount]);

    const ValidateEmail = () => {
        return (/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(email) ? false : true);
    }

    const renderEmailAdres = useMemo(() => (
        <Input
            aria-label="ARIA Label"
            autoComplete="off"
            dataTestId="test-inputBasic"
            domID="basic-test-id"
            size="small"
            errorMessage="Please enter a valid Email"
            hasError={ValidateEmail()}
            initialValue={email}
            name="emailAddress"
            maxLength={50}
            onChange={(e) => {
                setEmail(e.target.value || "");
            }}
        />
    ), [email]);
    const validateForm = useMemo(() => {
        if (!email || !fromDate || !toDate || ValidateEmail()) {
            return false;
        }
        return true;
    }, [email, fromDate, toDate]);

    const resetForm = () => {
        setCount(count + 1);
        setradioCount(radiocount + 1)
        setFromDate('')
        setToDate('');
        setEmail("");
        setIntialState({ label: 'ALL', value: '' });
        setPresort("No");
        setEnvelope("No")
        setZipCode("");
        setIntialCheckedclientType({
            id: 'id1',
            isChecked: true,
            label: 'This Client'
        });
        setClientType("This Client");
        setRadioMailcount(radioMailcount + 1);
        setIntialcheckedMailItem({
            id: 'id3',
            isChecked: true,
            label: 'All UPSC Mail'
        });
        setDisplayOptioncount(displayOptioncount + 1);
        setDisplayOption([
            {
                id: 'id1',
                isChecked: false,
                label: 'Display Detail For All Presort rates'
            },
            {
                id: 'id2',
                isChecked: false,
                label: 'Display Detail For All Envelope Types'
            },
        ]);
    }

    return (
        <>
            <ListPaneHeader
                title={`Mail Delivery Report Request`}
                layoutControls={[expandCollapseListPaneButtonConfig]}
            />
            {isError ? (
                // replace with a better error component
                <ErrorPage
                    // this prop isn't real but is an example
                    error={error}
                />
            ) :
                (<div className="marginLeftUserReport" style={{ fontSize: '13px' }}>
                    <table id="userReportSearch">
                        <tr>
                            <td className='userreport-labels'><label>{renderClientTypeRadio}</label></td>
                            <td className='userReportcriteriaWidth'>
                            </td>
                        </tr>
                        <tr>
                            <td >
                                <div className='maildeliveryColumn'>
                                    <label style={{ paddingRight: '6px' }}>From:  </label>
                                    <div className='maildeliveryColumnDropdownWidth'>
                                        {rendeStartDate}
                                    </div>
                                </div>
                            </td>
                            <td >
                                <div className='maildeliveryColumn'>
                                    <label style={{ paddingRight: '6px' }}>To Date: </label>
                                    <div className='maildeliveryColumnDropdownWidth' style={{ "margin-left": "27px" }}>
                                        {rendeEndDate}
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td >
                                <div className='maildeliveryColumn'>
                                    <label style={{ paddingRight: '6px' }}>State:  </label>
                                    <div className='maildeliveryColumnDropdownWidth'>
                                        {
                                            sIsLoading ? (
                                                <LoadingPage height='10vh' />
                                            ) : (
                                                    renderStateDropDown
                                                )
                                        }
                                    </div>
                                </div>
                            </td>
                            <td >
                                <div className='maildeliveryColumn'>
                                    <label style={{ paddingRight: '6px' }}> Or Zipcode: </label>
                                    <div className='maildeliveryColumnDropdownWidth'>
                                        {renderZipcodeTextBox}
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">{rendemailOptionRadio}</td>
                        </tr>
                        <tr>
                            <td colspan="2">{rendeDisplayOption}
                            </td>

                        </tr>
                        <tr>
                            <td className='userreport-labels'><label>Email Address for Delivery of Report:</label></td>
                            <td className='userReportcriteriaWidth'>
                                {renderEmailAdres}
                            </td>
                        </tr>
                        <tr><td colspan="2">
                            <div className='maildeliveryColumn'>
                                <ButtonGroup>
                                    <Button
                                        buttonType="standard"
                                        size="small"
                                        name="Reset"
                                        onClick={() => { resetForm() }}
                                    />
                                    <Button
                                        buttonType="standard"
                                        size="small"
                                        name="Submit"
                                        disabled={!validateForm}
                                        onClick={() => { showModal() }}
                                    />
                                </ButtonGroup>
                            </div>
                        </td></tr>
                    </table>
                    <Modal isOpen={resolutionModal}>
                        <ModalHeader
                            title="Do you want to proceed?"
                            onClose={() => setResolutionModal(false)}
                        />
                        <ModalFooter>
                            <div className="end-session-modal">
                                {
                                    isLoading ? (
                                        <LoadingPage height='10vh' />
                                    ) :
                                        (<ButtonGroup>
                                            <Button
                                                domID="endUserSessionCancelButton"
                                                name="Cancel"
                                                size="small"
                                                buttonType="standard"
                                                onClick={() => { setResolutionModal(false) }}
                                            />
                                            <Button
                                                domID="endUserSessionConfirmButton"
                                                name="Confirm"
                                                size="small"
                                                buttonType="standard"
                                                onClick={() => { submitReport() }}
                                            />
                                        </ButtonGroup>)
                                }
                            </div>
                        </ModalFooter>
                    </Modal>
                </div>
                )}

        </>
    )
}

export default withAxiosCancellation(MailDeliveryReport);
