
export const setDraftCodeData=(payload)=>({
  type:'SET_CLIENT_RULE_DRAFT_CODE_DATA',
  payload
})

export const resetDraftCodeData=(payload)=>({
  type:'RESET_CLIENT_RULE_DRAFT_CODE_DATA',
  payload
})

export const setCheckAccountInfo=(payload)=>({
  type:'SET_CHECK_ACCOUNT_INFO',
  payload
})

export const resetCheckAccountInfo=(payload)=>({
  type:'RESET_CHECK_ACCOUNT_INFO',
  payload
})