export const isA11yClick = (event) => {
  if (event.type === 'click') {
    return true;
  }
  if (event.type === 'keypress') {
    const code = event.charCode || event.keyCode;
    if (code === 32 || code === 13) return true;
  }
  return false;
};

export default {
  isA11yClick,
};
