import Api from '..';
import { API_BASE } from '../constants';

export const getRuleTypesList = async (
  clientLayoutId,
  userSecurityTokenKey,
  username,
  groupId,
  ruleType,
  queryValue,
  dataSource,
  roleId,
) => {
  try {
    const safeSearchQuery =
      queryValue && queryValue.length
        ? `/${ruleType}?keyword=${queryValue}`
        : `${ruleType || ''}`;
    const baseUrl = `${API_BASE}/rules/${clientLayoutId}/${groupId}${safeSearchQuery}`;
    const api = new Api(userSecurityTokenKey, username, dataSource, roleId);
    const getRuleTypesListResponse = await api.get(baseUrl);

    if (getRuleTypesListResponse.error) {
      throw getRuleTypesListResponse.error;
    }
    return getRuleTypesListResponse.ruleTypes;
  } catch (error) {
    throw error;
  }
};

// For overwriting a test rule with a live rule
export const overwriteTestRule = async ({
  clientLayoutId,
  userSecurityTokenKey,
  username,
  ruleType,
  ruleId,
  roleId,
}) => {
  try {
    const baseUrl = `${API_BASE}/rules/${clientLayoutId}/${ruleType}/${ruleId}:overwrite`;
    const api = new Api(userSecurityTokenKey, username, null, roleId);
    const overwriteTestRuleResponse = await api.put(baseUrl);

    if (overwriteTestRuleResponse.error) {
      throw overwriteTestRuleResponse.error;
    }
    return overwriteTestRuleResponse;
  } catch (error) {
    throw error;
  }
};

// For rule details
export const getRuleDetails = async ({
  clientLayoutId,
  userSecurityTokenKey,
  username,
  ruleType,
  ruleId,
  ruleVersion,
  roleId,
}) => {
  try {
    const baseUrl = `${API_BASE}/ruleDetails/${clientLayoutId}/${ruleType}/${ruleId}/${ruleVersion}`;
    const api = new Api(userSecurityTokenKey, username, null, roleId);
    const ruleDetailsResults = await api.get(baseUrl);
    const { error } = ruleDetailsResults;

    if (error) {
      throw error;
    } else {
      const ruleDetails = ruleDetailsResults.ruleDetails.map(rule =>
        JSON.parse(JSON.stringify(rule, replacer)),
      );
      return {
        ...ruleDetailsResults,
        ruleDetails,
        ruleId
      };
    }
  } catch (error) {
    throw error;
  }
};

export const getAddRuleTypesList = async (
  clientLayoutId,
  userSecurityTokenKey,
  username,
  roleId,
) => {
  try {
    const baseUrl = `${API_BASE}/ruleTypes/${clientLayoutId}`;
    const api = new Api(userSecurityTokenKey, username, '', roleId);
    const getAddRuleTypesListResponse = await api.get(baseUrl);

    if (getAddRuleTypesListResponse.error) {
      throw getAddRuleTypesListResponse.error;
    }
    return getAddRuleTypesListResponse.ruleTypes;
  } catch (error) {
    throw error;
  }
};

// Delete rule detail/s
export const deleteRuleDetail = async (
  { clientLayoutId, userSecurityTokenKey, username, roleId },
  { ruleType, ruleId, ruleVersion, ruleDetailIdArray },
) => {
  try {
    const baseUrl = `${API_BASE}/ruleDetails/${clientLayoutId}/${ruleType}/${ruleId}/${ruleVersion}`;
    const api = new Api(userSecurityTokenKey, username, '', roleId);
    const ruleDetailToDelete = JSON.stringify({ ruleDetailIdArray: [...ruleDetailIdArray] });
    const getAddRuleTypesListResponse = await api.delete(baseUrl, ruleDetailToDelete);

    if (getAddRuleTypesListResponse.error) {
      throw getAddRuleTypesListResponse.error
    }
    getAddRuleTypesListResponse.ruleType = ruleType;
    getAddRuleTypesListResponse.ruleId = ruleId;

    return getAddRuleTypesListResponse;
  } catch (error) {
    throw error;
  }
};

const replacer = (name, val) => {
  if (!val) {
    return '';
  }
  if (Array.isArray(val)) {
    return val.filter(valE => valE !== null);
  }
  if (name === 'status') {
    return `${val.charAt(0).toUpperCase()}${val.slice(1)}`;
  }
  return val;
};

export default {
  getRuleTypesList,
};
