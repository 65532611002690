export const GET_COMMIT_RULETYPES_REQUEST_TRIGGER =
  'GET_COMMIT_RULETYPES_REQUEST_TRIGGER';
export const GET_COMMIT_RULETYPES_REQUEST_SUCCESS =
  'GET_COMMIT_RULETYPES_REQUEST_SUCCESS';
export const GET_COMMIT_RULETYPES_REQUEST_ERROR =
  'GET_COMMIT_RULETYPES_REQUEST_ERROR';
export const SET_IS_SHOWING_MULTIPLE_RULES_COMMIT_MODAL =
  'SET_IS_SHOWING_MULTIPLE_RULES_COMMIT_MODAL';
export const MULTIPLE_RULES_COMMIT_PUT_REQUEST_TRIGGER =
  'MULTIPLE_RULES_COMMIT_PUT_REQUEST_TRIGGER';
export const MULTIPLE_RULES_COMMIT_PUT_REQUEST_SUCCESS =
  'MULTIPLE_RULES_COMMIT_PUT_REQUEST_SUCCESS';
export const MULTIPLE_RULES_COMMIT_PUT_REQUEST_ERROR =
  'MULTIPLE_RULES_COMMIT_PUT_REQUEST_ERROR';
export const SET_IS_MULTIPLE_RULES_COMMIT_STATE =
  'SET_IS_MULTIPLE_RULES_COMMIT_STATE';
export const TRIGGER_COMMIT_FOR_MULTIPLE_TEST_ASSOCIATION_RULES =
  'TRIGGER_COMMIT_FOR_MULTIPLE_TEST_ASSOCIATION_RULES';
export const COMMIT_MULTIPLE_TEST_ASSOCIATION_RULES_SUCCESS =
  'COMMIT_MULTIPLE_TEST_ASSOCIATION_RULES_SUCCESS';
export const COMMIT_MULTIPLE_TEST_ASSOCIATION_RULES_ERROR =
  'COMMIT_MULTIPLE_TEST_ASSOCIATION_RULES_ERROR';
export const COMMIT_MULTIPLE_TEST_ASSOCIATION_RULE_MODAL_OPEN =
  'COMMIT_MULTIPLE_TEST_ASSOCIATION_RULE_MODAL_OPEN';
export const RESET_COMMIT_STATE =
  'RESET_COMMIT_STATE';
