import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { Page } from '../components';

import AchTransactionHistoryMain from '../containers/AchManager/AchTransactionHistoryMain'
import AchTransactionHistoryDetails from '../containers/AchManager/AchTransactionHistoryDetails'

const AchManagerTransHistory=()=>(
  <Page
    ListPaneContent={AchTransactionHistoryMain}
    DetailPaneContent={AchTransactionHistoryDetails}
  />
)
const AchRoutes = () => (
  <Switch>
    <Route path="/AchManager/AccountsInError" component={Page} />
    <Route path="/AchManager/PayeeMaint" component={Page} />
    <Route path="/AchManager/TransHist" component={AchManagerTransHistory} />
    <Route exact path="/AchManager">
      <Redirect to="/AchManager/AccountsInError" />
    </Route>
  </Switch>
)

export default AchRoutes;