import styled from 'styled-components';

export const DropOne = styled.div`
  margin-bottom: 30px;
`;
export const DropTwo = styled.div`
  margin-bottom: 30px;
`;
export const DropThree = styled.div`
  margin-bottom: 30px;
`;

export const ContainerBox = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
`;
export const ReturnAddDropDown = styled.div`
  height: 50px;
  width: 200px;
  margin-right: 35px;
  margin-top: 10px;
`;

export const ReturnAddressTitle = styled.div`
  & > div {
    display: inline-block;
    margin-top: 40px;
    margin-bottom: 5px;
    margin-right: 14px;
  }
`;
export const AddressDivContainer = styled.div`
  width: 740px;
  height: 300px;
  margin-bottom: 5px;
  overflow-y: auto;
  border: 1px solid #737d85;
`;

export const AddressDivContainerReadOnly = styled.div`
  width: 740px;
  margin-bottom: 5px;
  overflow-y: auto;
`;

export const ReturnAddressWrapper = styled.div`
  width: 720px;
  margin-top: 1px;
  flex-direction: row;
  display: flex;
  border: 1px solid #737d85;
  border-radius: 4px 4px 4px 4px;
  @media all and (-ms-high-contrast: none) {
    & > .ReturnStyleTitleWrap .entity-subtitle{
      width: 110px;
    }
    & > .NameAndAddressWrap .entity-subtitle{
      width: 180px;
    }
    & > .AltAddressDivWrap .entity-title{
      margin-left:35px;
    }
  }
  & >.AltAddressDivWrap .entity-title {
    word-break: break-word;
    white-space: break-spaces;
  }
`;

export const ReturnAddressWrapperReadOnly = styled.div`
  width: 720px;
  margin-top: 1px;
  flex-direction: row;
  display: flex;
  @media all and (-ms-high-contrast: none) {
    & > .ReturnStyleTitleWrap .entity-subtitle{
      width: 110px;
    }
    & > .NameAndAddressWrap .entity-subtitle{
      width: 180px;
    }
    & > .AltAddressDivWrap .entity-title{
      margin-left:35px;
    }
  }
  & > .AltAddressDivWrap .entity-title {
    word-break: break-word;
    white-space: break-spaces;
  }
`;

export const CheckIcon = styled.div`
  margin: 10px 0px 0px 5px;
  width:0px;
`;

export const NameAndAddress = styled.div`
  width: 300px;
  margin-top: 6px;
  margin-left: 45px;
  overflow-wrap: break-word;
`;
export const AltAddressDiv = styled.div`
  margin-top: 6px;
  width: 500px;
`;
export const EditButtonDiv = styled.div`
  height: 70px;
  margin-top: 3px;
`;
export const Title = styled.div`
  width: 300px;
  margin-top: 6px;
  & > div > div.entity-subtitle {
    word-break: break-word;
    word-wrap: break-word;
  }
`;
export const AltTitle = styled.div`
  margin-top: 6px;
`;
export const ButtonWrap = styled.div`
  float: right;
`;
export const ButtonContainerWrap = styled.div`
  & > span > div {
    margin-top: 20px;
  }
`;
export const ReturnAddressImage = styled.div`
  padding:10px 0px 10px 20px;
  min-width:123px;
  & > div > img {
    border: 2px solid #6D767E;
    margin-bottom: 12px;
  }
  & > div > span {
    color:#6D767E
  }
`;

export const ReturnAddressImageReadOnly = styled.div`
padding:10px 0px 0px 10px;
min-width:123px;
& > div > img {
  border: 2px solid #6D767E;
  margin-bottom: 12px;
}
& > div > span {
  color:#6D767E
}
`;

export const ReturnAddressLogo = styled.div`
  width: 100px;
  margin-top: 6px;
`;