import React, { useState } from 'react';
import { Input, Masthead, ListPaneHeader, Button, NotificationBanner } from 'ui-core/dist';
import axios from '../../axios';
import LoggedOutFooter from '../../components/LoggedOutFooter';
import "./index.css"

const ForgotPassword = () => {
  const [userId, setUserId] = useState()
  const [notification, setNotification] = useState({})
  const [successful, setSuccessful] = useState(false)
  const [key, setKey] = useState(1)

  const handleChange = (e, state) => {
    setUserId(state.value)
  }

  const forgotPassword = async () => {
    setKey(s => s+1)
    try {
      await axios.post('/users/forgotPassword', {
        userId,
      })
      setNotification({
        text: `Your new password has been sent to the registered email address for the user: ${userId}`,
        type: 'positive',
        key
      })
      setSuccessful(true)
    } catch (err) {
      setNotification({
        text: err.displayMessage,
        type: 'negative',
        key 
      })
    }
  }

  return (
    <>
      <NotificationBanner 
        text={notification.text}
        type={notification.type}
        autoDismiss
        key={notification.key}
      />
      <Masthead currentProductName="Client Access System" />
      <div className="forgot-password">
        {successful ? (
          <a href="/login">Return to the logon page</a>
        ) : (
          <>
            <ListPaneHeader 
              domID="Header"
              title="Retrieve Password"
            />
            <Input
              autoComplete="off"
              dataTestId="test-UserIdInput"
              domID="UserIdInput"
              onChange={handleChange}
              label="USER ID"
              size="medium"
              className="forgot-password__input"
            />
            <Button 
              buttonType="deEmphasized"
              dataTestId="test-SubmitButton"
              domID="SubmitButton"
              name="Submit"
              onClick={forgotPassword}
              size="medium"
              disabled={!userId}
            />
          </>
        )}
      </div>
      <LoggedOutFooter />
    </>
  )
}

export default ForgotPassword;