/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import styled from 'styled-components/dist/styled-components'
import { useLayout } from 'ui-core/dist/useLayout';
import DetailPaneHeader from 'ui-core/dist/DetailPaneHeader';
import { useSelector, useDispatch } from 'react-redux';
import {
  Modal, Button, ModalHeader, Input, ModalBody
} from 'ui-core/dist';
import { detailPaneAnimStates } from '../../../commonResources/constants/animStates';
import ErrorPage from '../../ErrorPage';
import LoadingComponent from '../../../components/LoadingComponent';
import { SelectDropdown } from '../../../components'
import { getXmlDocumentTypeSaveUpdate } from '../Modules/action';
import useNotificationBannerHook from '../../../useNotificationBannerHook';
import { useSetRecipTypeStatus } from '../../../api/useClientAdministrationRecipientType'
import {

  useDocumentTypeTranslationDetails,
  useGetSysDocumentTypes,
  useAddDocumentTypeTranslation,
  useUpdateDocumentTypeTranslation,
  useDeleteDocumentTypeTranslation
} from '../../../api/useClientAdministrationDocumentType'

const StyledDropDownContainer = styled.div`
width: 370px !important;
& #drpCurrentNormalizedDocumentType, & #drpCurrentDocumentType {
    width: 370px !important;
}
`
const DocumentTypeTranslationDetail = () => {
  const {
    expandCollapseDetailPaneButtonConfig,
    closeDetailPaneButtonConfig,
    detailAnimationState,
    closeDetailPane
  } = useLayout()

  const { notify } = useNotificationBannerHook();
  const [currentdocumentType, setcurrentdocumentType] = useState(null);
  const [currentnormalizedDocumentType, setcurrentnormalizedDocumentType] = useState(null);
  const [currentwebDocumentTypeDesc, setcurrentwebDocumentTypeDesc] = useState(null);
  const [normalizedDocumentTypeSource, setnormalizedDocumentTypeSource] = useState([])
  const [currentDocumentTypeSource, setcurrentDocumentTypeSource] = useState([])
  const [confirmationModalMode, setconfirmationModalMode] = useState('')
  const [isOpenConfirmationModal, setisOpenConfirmationModal] = useState(false);
  const [count, setcount] = useState(0)

  const { recipientTypeTranslationRecord, recipientTypeTranslationMode, refetchRecipientTypes }
    = useSelector(({ clientAdministration }) => clientAdministration);

  useEffect(() => {
    if (recipientTypeTranslationRecord) {
      if (recipientTypeTranslationRecord.documentTypeTrans) {
        getShowMoreData(recipientTypeTranslationRecord.documentTypeTrans);
      } else {
        onClearCurrentRecordValues()
      }
    }
  }, [recipientTypeTranslationRecord]);


  const getShowMoreData = (documentTypeTrans) => {
    postDataShowMoreInfo({ strDocumentType: documentTypeTrans, count })
    setcount(count + 1)
  }

  const { showMoreInfo, postData: postDataShowMoreInfo, isLoading: isLoadingShowMoreInfo,
    isError: isErrorShowMoreInfo, error: errorShowMoreINfo } = useDocumentTypeTranslationDetails()

  const { directPostCall: getsysDocumentTypes, isLoading: isLoadingsysDocumentTypes,
    isError: isErrorsysDocumentTypes, error: errorsysDocumentTypes } = useGetSysDocumentTypes()

  const { data: infoAddRecipType, postData: addDocumentTypeTranslation, metaData: addDocumentTypeTranslationResponse, isLoading: isLoadingaddDocumentTypeTranslation,
    isError: isErroraddDocumentTypeTranslation, error: erroraddDocumentTypeTranslation } = useAddDocumentTypeTranslation()

  const { data: infoUpdateRecipType, postData: updateDocumentTypeTranslation, metaData: updateDocumentTypeTranslationResponse, isLoading: isLoadingupdateDocumentTypeTranslation,
    isError: isErrorupdateDocumentTypeTranslation, error: errorupdateDocumentTypeTranslation } = useUpdateDocumentTypeTranslation()

  const { data: infoDeleteRecipType, postData: deleteDocumentTypeTranslation, metaData: deleteDocumentTypeTranslationResponse, isLoading: isLoadingdeleteDocumentTypeTranslation,
    isError: isErrordeleteDocumentTypeTranslation, error: errordeleteDocumentTypeTranslation } = useDeleteDocumentTypeTranslation()

  const onResetCurrentRecordValues = () => {
    if (showMoreInfo) {
      const { documentType, documentTypeDesc, documentTypeTrans } = showMoreInfo;
      setcurrentdocumentType(documentTypeTrans)
      setcurrentnormalizedDocumentType(documentType)
      setcurrentwebDocumentTypeDesc(documentTypeDesc)
    }
  }
  const getNormalizedOptionsValue = (name) => {
    if (name) {
      const s = name.split('-');
      if (s && s.length > 0) {
        return s[0].trim()
      }
    }
    return ""
  }
  const getDocumentTypes = async () => {
    const { data } = await getsysDocumentTypes({ parameters: "cvalue", tagname: 'ClientDocumentTypes' })
    if (data) {
      const { DocumentType } = data;
      if (DocumentType && DocumentType.length > 0) {
        const r = DocumentType.map((x) => ({ label: x.name, value: getNormalizedOptionsValue(x.name), data: x.id }))
        const r1 = DocumentType.map((x) => ({ label: x.id, value: x.id }))
        setnormalizedDocumentTypeSource(JSON.parse(JSON.stringify(r)))
        setcurrentDocumentTypeSource(JSON.parse(JSON.stringify(r1)))
        return;
      }
    }
    setnormalizedDocumentTypeSource([])
    setcurrentDocumentTypeSource([])
  }

  useEffect(() => {
    if (addDocumentTypeTranslationResponse) {
      const { status, statusText } = addDocumentTypeTranslationResponse;
      if (status === 200 || statusText === "OK") {
        if (infoAddRecipType && infoAddRecipType.fs) {
          const { fs } = infoAddRecipType;
          if (fs) {
            notify(fs, 'negative')
            return;
          }
        }
        notify('Document Type Added successfully', 'positive')
        setconfirmationModalMode('')
        closeDetailPane()
        if (refetchRecipientTypes) refetchRecipientTypes()
      }
    }
  }, [addDocumentTypeTranslationResponse])

  useEffect(() => {
    if (updateDocumentTypeTranslationResponse) {
      const { status, statusText } = updateDocumentTypeTranslationResponse;
      if (status === 200 || statusText === "OK") {
        if (infoUpdateRecipType && infoUpdateRecipType.fs) {
          const { fs } = infoUpdateRecipType;
          if (fs) {
            notify(fs, 'negative')
            return;
          }
        }
        if (confirmationModalMode === "Deactivate") {
          notify(`Document Type ${showMoreInfo?.isActive === "False" ? "Reactivated" : "Deactivated"} successfully`, 'positive')
        } else if (confirmationModalMode === "Modify") {
          notify('Document Type Updated successfully', 'positive')
        }
        setconfirmationModalMode('')
        closeDetailPane()
        if (refetchRecipientTypes) refetchRecipientTypes()
      }
    }
  }, [updateDocumentTypeTranslationResponse])

  useEffect(() => {
    if (deleteDocumentTypeTranslationResponse) {
      const { status, statusText } = deleteDocumentTypeTranslationResponse;
      if (status === 200 || statusText === "OK") {
        if (infoDeleteRecipType && infoDeleteRecipType.fs) {
          const { fs } = infoDeleteRecipType;
          if (fs) {
            notify(fs, 'negative')
            return;
          }
        }
        notify('Document Type Deleted successfully', 'positive')
        setconfirmationModalMode('')
        closeDetailPane()
        if (refetchRecipientTypes) refetchRecipientTypes()
      }
    }
  }, [deleteDocumentTypeTranslationResponse])

  const onDeactiveClick = () => {
    if (recipientTypeTranslationMode === "Edit") {

      setconfirmationModalMode("Deactivate")
      setisOpenConfirmationModal(true)
    }
  }
  const onDeleteClick = () => {
    if (recipientTypeTranslationMode === "Edit") {

      setconfirmationModalMode("Delete")
      setisOpenConfirmationModal(true)
    }
  }
  const onSubmitClick = () => {
    if (recipientTypeTranslationMode === "Add") {

      setconfirmationModalMode("Add")
    } else if (recipientTypeTranslationMode === "Edit") {

      setconfirmationModalMode("Modify")
    }

    setisOpenConfirmationModal(true)
  }
  const onConfirmationYesClick = () => {
    if (confirmationModalMode === "Delete") {
      deleteDocumentTypeTranslation({ strDocumentType: showMoreInfo?.documentType, strDocumentTypeTrans: showMoreInfo?.documentTypeTrans, strIsActive: 'false', count })
      setcount(count + 1)

    } else if (confirmationModalMode === "Deactivate") {

      updateDocumentTypeTranslation({
        strNewDocumentTypeInfoXml: getXmlDocumentTypeSaveUpdate({
          currentdocumentType: showMoreInfo?.documentTypeTrans,
          currentnormalizedDocumentType: showMoreInfo?.documentType,
          currentwebDocumentTypeDesc: showMoreInfo?.documentTypeDesc,
          isActive: showMoreInfo?.isActive === "False" ? 1 : 0
        }), strOldDocumentTypeTrans: showMoreInfo?.documentTypeTrans, count
      })
      setcount(count + 1)

    } else if (confirmationModalMode === "Add") {
      addDocumentTypeTranslation({
        strDocumentTypeInfoXml: getXmlDocumentTypeSaveUpdate({
          currentdocumentType, currentnormalizedDocumentType, currentwebDocumentTypeDesc, isActive: showMoreInfo?.isActive
        }), count
      })
      setcount(count + 1)

    } else if (confirmationModalMode === "Modify") {
      updateDocumentTypeTranslation({
        strNewDocumentTypeInfoXml: getXmlDocumentTypeSaveUpdate({
          currentdocumentType, currentnormalizedDocumentType, currentwebDocumentTypeDesc, isActive: showMoreInfo?.isActive
        }), strOldDocumentTypeTrans: showMoreInfo?.documentTypeTrans, count
      })
      setcount(count + 1)
    }
    setisOpenConfirmationModal(false)
  }
  useEffect(() => {
    onResetCurrentRecordValues()
    getDocumentTypes()
  }, [showMoreInfo])


  const onClearCurrentRecordValues = () => {
    setcurrentdocumentType('')
    setcurrentnormalizedDocumentType('')
    setcurrentwebDocumentTypeDesc('')
  }
  const renderCurrentDocumentType = useMemo(() => (
    <SelectDropdown
      width="200px"
      domID="drpCurrentDocumentType"
      label=""
      options={currentDocumentTypeSource}
      size="medium"
      initialValue={{ label: currentDocumentTypeSource.find(x => x.value === currentdocumentType)?.label, value: currentdocumentType }}
      onChange={(e) => {
        if (e) {
          if (setcurrentdocumentType) {
            setcurrentdocumentType(e.value)
            const f = normalizedDocumentTypeSource.find(x => x.data === e.value);
            if (f) {
              const { value } = f;
              if (setcurrentnormalizedDocumentType) setcurrentnormalizedDocumentType(value)
            }
          }
        } else if (setcurrentdocumentType) {
          setcurrentdocumentType(null)
          if (setcurrentnormalizedDocumentType) setcurrentnormalizedDocumentType(null)
        }
      }}
    />
  ), [currentdocumentType, currentDocumentTypeSource, normalizedDocumentTypeSource, currentnormalizedDocumentType])
  const renderCurrentNormalizedDocumentType = useMemo(() => (
    <SelectDropdown
      width="200px"
      domID="drpCurrentNormalizedDocumentType"
      disabled
      label=""
      options={normalizedDocumentTypeSource}
      size="medium"
      initialValue={{ label: normalizedDocumentTypeSource.find(x => x.data === currentdocumentType)?.label, value: currentnormalizedDocumentType }}
    />
  ), [currentdocumentType, currentDocumentTypeSource, normalizedDocumentTypeSource, currentnormalizedDocumentType])
  const renderCurrentWebDocumentTypeDesc = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_webDocumentTypeDesc"
      placeholder=""
      size="small"
      maxLength={30}
      initialValue={currentwebDocumentTypeDesc}
      onChange={(e) => {
        setcurrentwebDocumentTypeDesc(e.target.value || "")
      }}
    />
  ), [currentwebDocumentTypeDesc])
  const validateForm = useMemo(() => {
    if (!currentdocumentType) {
      return false;
    }
    if (!currentnormalizedDocumentType || !normalizedDocumentTypeSource.find(x => x.value) === currentnormalizedDocumentType) {
      return false;
    }
    if (!currentwebDocumentTypeDesc) {
      return false;
    }
    return true;
  }, [currentdocumentType, currentDocumentTypeSource, normalizedDocumentTypeSource, currentnormalizedDocumentType,
    currentwebDocumentTypeDesc])

  const renderConfirmationMarkup = useMemo(() => {
    if (confirmationModalMode === "Deactivate") {
      return showMoreInfo?.isActive === "False" ? "Reactivate" : "Deactivate"
    }
    return confirmationModalMode;
  }, [isOpenConfirmationModal, confirmationModalMode]);

  const renderDocumentTypeEntryForm = useMemo(() => {
    if (detailAnimationState > detailPaneAnimStates.COLLAPSED) {
      return (
        <div style={{ marginLeft: '40px' }} className="marginLeft">
          <div>
            <p>
              <div className="displayFlex">Complete the information below, and then click Submit to continue.</div>
            </p>
            <p>
              <div className="displayFlex">Note: Claim type translation values are case-sensitive.</div>
            </p>
            <p>
              <div className="displayFlex"><span style={{ color: 'red' }}>Note: Other client document types having the same normalized value will be updated with this web description.</span></div>
            </p>
            <p>
              <div className="displayFlex"><span style={{ color: 'red' }}>* indicates required information</span></div>
            </p>
          </div>
          <br />
          <div className="displayFlex">
            <table className="common-table">
              <tr>
                <td>
                  <label> Client Document Type : <span style={{ color: 'red' }}>*</span></label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderCurrentDocumentType}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <label> Normalized Document Type (Value-Description): <span style={{ color: 'red' }}>*</span></label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderCurrentNormalizedDocumentType}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <label> Web Document Type Description: <span style={{ color: 'red' }}>*</span></label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderCurrentWebDocumentTypeDesc}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td colSpan="2">
                  <div className="displayFlex">
                    <div>
                      <Button
                        domID="btn_reset"
                        buttonType="standard"
                        size="small"
                        name="RESET"
                        disabled={recipientTypeTranslationMode !== "Edit"}
                        onClick={() => onResetCurrentRecordValues()}
                      />
                    </div>
                    <div className="marginLeft">
                      <Button
                        domID="btn_cancel"
                        buttonType="standard"
                        size="small"
                        name="CLEAR"
                        onClick={() => onClearCurrentRecordValues()}
                      />
                    </div>
                    <div className="marginLeft">
                      <Button
                        domID="btn_deactivate"
                        buttonType="standard"
                        size="small"
                        name={showMoreInfo?.isActive === "False" ? "REACTIVATE" : "DEACTIVATE"}
                        disabled={recipientTypeTranslationMode !== "Edit"}
                        onClick={() => onDeactiveClick()}
                      />
                    </div>
                    {/* <div className="marginLeft">
                      <Button
                        domID="btn_delete"
                        buttonType="standard"
                        size="small"
                        name="DELETE"
                        disabled={recipientTypeTranslationMode !== "Edit"}
                        onClick={() => onDeleteClick()}
                      />
                    </div> */}
                    <div className="marginLeft">
                      <Button
                        domID="btn_search"
                        buttonType="standard"
                        size="small"
                        name="SUBMIT"
                        disabled={!validateForm}
                        onClick={() => onSubmitClick()}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
            </table>
          </div>
          <Modal isOpen={isOpenConfirmationModal}>
            <ModalHeader
              title={`Do you want to ${renderConfirmationMarkup} ?`}
              onClose={() => setisOpenConfirmationModal(false)}
            />
            <ModalBody>
              {isOpenConfirmationModal ? (
                <div style={{ marginLeft: '34px', marginBottom: '20px' }} className="marginLeft">
                  <div className="displayFlex">
                    <div className="marginLeft">
                      <Button
                        domID="btn_search"
                        buttonType="standard"
                        size="small"
                        name="Yes"
                        onClick={() => onConfirmationYesClick()}
                      />
                    </div>
                    <div className="marginLeft">
                      <Button
                        domID="btn_search"
                        buttonType="standard"
                        size="small"
                        name="No"
                        onClick={() => setisOpenConfirmationModal(false)}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
            </ModalBody>
          </Modal>
        </div>
      )
    }
    return null;
  }, [recipientTypeTranslationRecord, showMoreInfo, detailAnimationState,
    currentdocumentType, currentDocumentTypeSource, normalizedDocumentTypeSource, currentnormalizedDocumentType,
    currentwebDocumentTypeDesc, validateForm, recipientTypeTranslationMode, isOpenConfirmationModal, confirmationModalMode])

  return (
    <>
      <DetailPaneHeader
        title={recipientTypeTranslationMode === "Edit" ? "Modify Document Type Translation" : "Add Document Type Translation"}
        layoutControls={[
          expandCollapseDetailPaneButtonConfig,
          closeDetailPaneButtonConfig
        ]}
      />
      <>
        {isLoadingShowMoreInfo || isLoadingsysDocumentTypes || isLoadingaddDocumentTypeTranslation
          || isLoadingdeleteDocumentTypeTranslation || isLoadingupdateDocumentTypeTranslation ? (
            // replace with a better loading component
            <div style={{ marginLeft: '50%' }}><LoadingComponent /></div>
          ) : isErrorShowMoreInfo || isErrorsysDocumentTypes || isErroraddDocumentTypeTranslation
            || isErrordeleteDocumentTypeTranslation || isErrorupdateDocumentTypeTranslation ? (
              // replace with a better error component
              <ErrorPage
                // this prop isn't real but is an example
                error={errorShowMoreINfo || errorsysDocumentTypes || erroraddDocumentTypeTranslation
                  || errordeleteDocumentTypeTranslation || errorupdateDocumentTypeTranslation}
              />
            ) : (
              <>
                {renderDocumentTypeEntryForm}
              </>
            )
        }
      </>
    </>
  )
};

export default DocumentTypeTranslationDetail