import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { NotificationBanner } from 'ui-core-ssgr';
import { Portal } from 'react-portal';
import {
  FormWrapper,
  RuleNameInputWrapper,
  RuleDescriptionInputWrapper,
  RuleInputLabel,
  InputLabelWrapper,
  OverwriteRuleButton,
} from './styles';

export class RulesTabLiveForm extends Component {
  state = {
    desc: '',
    name: '',
  };

  componentDidMount = () => {
    const { 
      ruleDetails, 
      getRuleDetailElements, 
      selectedRuleTypeId, 
      selectedRuleRecord, 
      prevProps, 
      deleteNotification 
    } = this.props;
    if (isEmpty(ruleDetails.detailElements)) {
      getRuleDetailElements({ selectedRuleTypeId });
    }
    this.setState({
      desc: selectedRuleRecord.description || '-',
      name: selectedRuleRecord.name || '-',
    });
    deleteNotification(prevProps);
  }

  componentDidUpdate(prevProps) {
    const {
      deleteNotification,
      isExpanded,
      isPostingRulesForRuleTypes,
      isCloningRule,
      notifications,
      selectedRuleRecord,
      getRuleDetailElements,
      selectedRuleTypeId
    } = this.props;
    const id = selectedRuleRecord.id;
    if (
      prevProps.selectedRuleRecord.id !== id ||
      prevProps.isExpanded !== isExpanded ||
      prevProps.isPostingRulesForRuleTypes !== isPostingRulesForRuleTypes ||
      prevProps.isCloningRule !== isCloningRule
    ) {
      this.resetComponentState();
      getRuleDetailElements({ selectedRuleTypeId });
      if (notifications.has(prevProps.selectedRuleRecord.id))
        deleteNotification(prevProps.selectedRuleRecord.id);
    }
  }

  onNotificationDismiss = id => {
    const {
      deleteNotification,
      notifications,
      selectedRuleRecord,
    } = this.props;
    if (
      notifications.has(selectedRuleRecord.id) &&
      notifications.get(selectedRuleRecord.id).type === 'negative'
    ) {
      this.resetComponentState();
    }
    deleteNotification(id);
  };

  resetComponentState = () => {
    const { selectedRuleRecord } = this.props;
    this.setState({
      desc: selectedRuleRecord.description,
      name: selectedRuleRecord.name,
    });
  };

  disableOverwriteButton = status => {
    if (status.includes('L') && status.includes('T')) {
      return false;
    }
    if (status[0] === 'T') {
      return true;
    }
    if (status[0] === 'L') {
      return false;
    }
    return true;
  };

  render() {
    const {
      notifications,
      selectedRuleRecord,
      isPostingRulesForRuleTypes,
      isCloningRule,
      setIsShowingOverwriteModal,
      statusDisabled,
    } = this.props;

    const { desc, name } = this.state;
    const { status } = selectedRuleRecord;
    const disabledLiveOnlyStatus = status.length === 1 && status[0];

    return (
      <FormWrapper>
        {notifications.size > 0 && (
          <Portal key="rules-tab-live-form">
            {[...notifications].map(([key, value]) => (
              <NotificationBanner
                key={[key]}
                domID={`${key}-notification`}
                type={value.type}
                text={value.msg}
                onDismiss={() => this.onNotificationDismiss(key)}
                autoDismiss
                timer={1800000}
                icon={value.type === 'neutral' ? 'Info' : null}
              />
            ))}
          </Portal>
        )}
        <OverwriteRuleButton
          name="OVERWRITE TEST VERSION WITH LIVE RULE"
          buttonType="emphasized"
          size="medium"
          onClick={() => setIsShowingOverwriteModal(true)}
          disabled={
            isPostingRulesForRuleTypes ||
            isCloningRule ||
            disabledLiveOnlyStatus ||
            statusDisabled
              ? true
              : status && status.length >= 1
                ? this.disableOverwriteButton(status)
                : true
          }
        />
        <RuleNameInputWrapper>
          <InputLabelWrapper>RULE *</InputLabelWrapper>
          <RuleInputLabel className="live-rule-rule-name">
           {isPostingRulesForRuleTypes || isCloningRule
              ? '-'
              : status && status.length >= 1 && status.includes('L')
                ? name
                : '-'}
          </RuleInputLabel>
        </RuleNameInputWrapper>
        <RuleDescriptionInputWrapper>
          <InputLabelWrapper>RULE DESCRIPTION</InputLabelWrapper>
          <RuleInputLabel className="live-rule-rule-description">
            {isPostingRulesForRuleTypes || isCloningRule
              ? '-'
              : status && status.length >= 1 && status.includes('L')
                ? desc
                : '-'}
          </RuleInputLabel>
        </RuleDescriptionInputWrapper>
      </FormWrapper>
    );
  }
}

RulesTabLiveForm.propTypes = {
  isCloningRule: PropTypes.bool,
  isPostingRulesForRuleTypes: PropTypes.bool,
  isExpanded: PropTypes.bool,
  deleteNotification: PropTypes.func.isRequired,
  selectedRuleRecord: PropTypes.shape({ id: PropTypes.string }),
  notifications: PropTypes.object.isRequired,
};

export default RulesTabLiveForm;
