import React, { useState, useMemo, useEffect, useCallback } from 'react';
import styled from 'styled-components/dist/styled-components'
import { useLayout } from 'ui-core/dist/useLayout';
import DetailPaneHeader from 'ui-core/dist/DetailPaneHeader';
import moment from 'moment'
import { useSelector } from 'react-redux';
import {
  Modal, Button, ModalHeader, Input, ModalBody, DatePickerV2, SelectDropdown
} from 'ui-core/dist';
import { detailPaneAnimStates } from '../../commonResources/constants/animStates';
import { USPSApprover_OPTIONS, ABFApprover_OPTIONS } from './Modules/constants';
import {
  useSetManifestRecords
} from "../../api/useManifestEntry";
import ModalWrapper from '../../components/Modal/components/ModalWrapper';
import useNotificationBannerHook from '../../useNotificationBannerHook';



const StyledModalWrapperWidth = styled(ModalWrapper)`
& .active-content {
  width: 30% !important;
}
`

const ManifestEntryDetails = () => {
  const {
    expandCollapseDetailPaneButtonConfig,
    closeDetailPaneButtonConfig,
    detailAnimationState,
    closeDetailPane,
    detailProps
  } = useLayout()

  const { notify } = useNotificationBannerHook();
  const [ jobNumber, setJobNumber ] = useState('')
  const [ mailDate, setMailDate ] = useState('')
  const [ USPSJob, setUSPSJob ] = useState(false)
  const [ pkgCount, setPkgCount ] = useState('')
  const [ postage, setPostage ] = useState('')
  const [ weight, setWeight ] = useState('')
  const [ USPSApprover, setUSPSApprover ] = useState('')
  const [ ABFApprover, setABFApprover ] = useState('')
  const [invalidDate,setInvalidDate] = useState('')
  const [isOpenConfirmationModal, setisOpenConfirmationModal] = useState(false)

  const { manifestEntrySelectedRecord, refetchManifestEntryList }
    = useSelector(({ manifestEntry }) => manifestEntry);

  const { data, postData, metaData, isLoading,  isError, error } = useSetManifestRecords()

  const createUSPSApprover = (USPSJobp, USPSApproverp) => {
    if(USPSJobp === 'TRUE'){
        if(USPSApproverp.trim()){
            return USPSApprover_OPTIONS.find(x=>x.value === USPSApproverp.trim())
        } 
        return USPSApprover_OPTIONS[1]
    } 
    return USPSApprover_OPTIONS[0]
  }

  const createABFApprover = (ABFApproverp) => {
      if(ABFApproverp.trim()){
          return ABFApprover_OPTIONS.find(x=>x.value === ABFApproverp.trim())
      }
      return ABFApprover_OPTIONS[0]
  }
  
  useEffect(() => {
    if (manifestEntrySelectedRecord) {
      console.log(manifestEntrySelectedRecord)
      const { ABFApprover: ABFApproverp, JobNumber: JobNumberp, MailDate: MailDatep ,
        PkgCount: PkgCountp, Postage: Postagep, USPSApprover: USPSApproverp, USPSJob: USPSJobp,
        Weight: Weightp} = manifestEntrySelectedRecord
    
        setJobNumber(JobNumberp)
        setMailDate(MailDatep ? moment(MailDatep) : '')
        setPkgCount(PkgCountp)
        setPostage(Postagep)
        setUSPSJob(USPSJobp)
        setWeight(Weightp)
        setUSPSApprover(createUSPSApprover(USPSJobp, USPSApproverp))
        setABFApprover(createABFApprover(ABFApproverp))
    }
  }, [manifestEntrySelectedRecord]);

  const onConfirmationYesClick = useCallback(() => {
      const pstrXml = `
        <![CDATA[<collection type="manifest-information">
            <Manifest 
                JobNumber="${jobNumber}"
                MailDate="${mailDate.format('MM/DD/YYYY')}"
                PkgCount="${pkgCount}"
                Postage="${postage}"
                Weight="${weight}" 
                USPSApprover="${USPSApprover.value}"
                ABFApprover="${ABFApprover.value}"
            />
        </collection>]]>
      `

    postData({pstrXml})
    setisOpenConfirmationModal(false)
  },[jobNumber, mailDate, pkgCount, postage, weight, USPSApprover, ABFApprover]);

  useEffect(() => {
    if (metaData) {
      const { status: respStatus, statusText } = metaData;
      if (respStatus === 200 || statusText === "OK") {
        if (data && data.fs) {
          const { fs } = data;
          if (fs) {
              notify(fs)
          }
          return;
        }
        notify(`Data Saved`, 'positive')
        closeDetailPane()
        setisOpenConfirmationModal(false)
        if (refetchManifestEntryList) refetchManifestEntryList()
      }
    }
  }, [metaData])

  useEffect(()=>{
    if(mailDate){
      const forCheck = moment(mailDate).format('YYYYMMDD')
      const jobNumberFormat = jobNumber.substring(0,8)
      if(jobNumberFormat > forCheck){
        setInvalidDate('The mail date must not be before the job date!')
      }else{
        setInvalidDate('')
      }
    }else{
      setInvalidDate('Mail date can not be blank!')
    }
  },[mailDate,jobNumber])

  const renderMailDate = useMemo(() => (
    <>
    <DatePickerV2
        domID="renderMailDatePicker"
        initialSelection={mailDate}
        openDirection="down"
        onDateChange={(e)=>{
          if (e) setMailDate(e)
          else setMailDate('')
        }}
        size="small"
      />
      <span className='errorUnderText'>{invalidDate}</span>
      </>
  ), [mailDate,invalidDate])

    const validatePkgCount = useMemo(() => {
        if(pkgCount && !isNaN(pkgCount)){
            if(parseInt(pkgCount) < 0){
                return true
            }
            return false
        }
        return true
    }, [pkgCount])

  const renderPkgCount = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_renderPkgCount"
      placeholder=""
      size="small"
      initialValue={pkgCount}
      hasError={validatePkgCount}
      errorMessage='The package count must be greater than zero (0).'
      onChange={(e) => {
        setPkgCount(e.target.value || "")
      }}
    />
  ), [pkgCount])

    const validatePostage = useMemo(() => {
        if(postage && !isNaN(postage)){
            if(parseFloat(postage) < 0.0){
                return true
            }
            return false
        }
        return true
    }, [postage])

  const renderPostage = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_line1"
      placeholder=""
      size="small"
      initialValue={postage}
      hasError={validatePostage}
      errorMessage='The postage must be greater than zero (0).'
      onChange={(e) => {
        setPostage(e.target.value || "")
      }}
    />
  ), [postage])

  
  const validateWeight = useMemo(() => {
    if(weight && !isNaN(weight)){
        if(parseFloat(weight) <= 0.0){
            return true
        }
        return false
    }
    return true
}, [weight])

  const renderWeight = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_line2"
      placeholder=""
      size="small"
      initialValue={weight}
      hasError={validateWeight}
      errorMessage='The weight must be greater than zero (0).'
      onChange={(e) => {
        setWeight(e.target.value || "")
      }}
    />
  ), [weight])

  const renderUSPSApprover = useMemo(() => (
    <SelectDropdown
        domID="renderUSPSApprover"
        onChange={(e) => {
            setUSPSApprover(e)
        }}
        size="small"
        options={USPSApprover_OPTIONS}
        initialValue={USPSApprover}
    />
  ), [USPSApprover])

  const renderABFApprover = useMemo(() => (
    <SelectDropdown
        domID="renderABFApprover"
        onChange={(e) => {
            setABFApprover(e)
        }}
        size="small"
        options={ABFApprover_OPTIONS}
        initialValue={ABFApprover}
    />
  ), [ABFApprover])

  const renderManifestForm = useMemo(() => {
    if (detailAnimationState > detailPaneAnimStates.COLLAPSED) {
      return (
        <div id='DeliveryAddressDetail' style={{ marginLeft: '40px' }} className="marginLeft">
          <div className="displayFlex">
            <table className="common-table">
              <tr>
                <td>
                  <span> Job Number: </span>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {jobNumber}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <span> Mail Date: </span>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderMailDate}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <span> Pkg Count: </span>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderPkgCount}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <span> Postage: </span>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderPostage}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <span> Weight: </span>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderWeight}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <span> USPS Approver: </span>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderUSPSApprover}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <span> ABF Approver: </span>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderABFApprover}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td colSpan="2">
                  <div className="displayFlex">
                    <div className="marginLeft">
                      <Button
                        domID="btn_search"
                        buttonType="standard"
                        size="small"
                        disabled={validatePkgCount||validatePostage || validateWeight || invalidDate!==''}
                        name="SUBMIT"
                        onClick={() => setisOpenConfirmationModal(true)}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
            </table>
          </div>

          <Modal isOpen={isOpenConfirmationModal}>
            <ModalHeader
              title={`Do you want to save changes and close the job?`}
              onClose={() => setisOpenConfirmationModal(false)}
            />
            <ModalBody>
              {isOpenConfirmationModal ? (
                <div style={{ marginLeft: '34px', marginBottom: '20px' }} className="marginLeft">
                  <div className="displayFlex">
                    <div className="marginLeft">
                      <Button
                        domID="btn_search"
                        buttonType="standard"
                        size="medium"
                        name="Yes"
                        onClick={() => onConfirmationYesClick()}
                      />
                    </div>
                    <div className="marginLeft">
                      <Button
                        domID="btn_search"
                        buttonType="standard"
                        size="medium"
                        name="No"
                        onClick={() => setisOpenConfirmationModal(false)}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
            </ModalBody>
          </Modal>
        </div>
      )
    }
    return null;
  }, [detailAnimationState, jobNumber, mailDate, pkgCount, postage, weight, USPSApprover, ABFApprover, isOpenConfirmationModal, invalidDate])

  return (
    <>
      <DetailPaneHeader
        title={"Modify Manifest Entry Details"}
        layoutControls={[
          expandCollapseDetailPaneButtonConfig,
          closeDetailPaneButtonConfig
        ]}
      />
        {renderManifestForm}
    </>
  )
};

export default ManifestEntryDetails
