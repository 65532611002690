import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { LoadingIndicator, Button, DetailHeader, Modal } from 'ui-core-ssgr';
import DropDownControl from '../ModalControls/DropDownControl/index';
import InputControl from '../ModalControls/InputControl';
import {
  DropOne, 
  DropTwo,
  DropThree,
  ContainerBox,
  DollarLabel,
  DollarLabelReadOnly,
  SignatureRuleDropDown,
  SignatureImageWrapper,
  ImageFilter,
  SearchButtonImage,
  RuleTypesDetailRow,
  PaddingWrapper
} from './styles';
import { 
  ButtonGroupBottom,
  ReadOnlyTextTitle,
  ReadOnlyTextArea
} from '../../../shared/styles/styledComponents';
import {
  LoadingWrapper,
  ModalButton,
} from '../../RulesTabView/components/RuleDetails/RuleDetailsGridWrapper/styles';
import FileUpload from '../../../shared/components/FileUpload';
import ImageLibraryModal from '../ImageLibraryModal';
import UploadAndViewImageControl from '../ModalControls/UploadAndViewImageControl';
import CommitImageModal from '../CommitImageModal';
import searchIcon from '../../../shared/images/search-icon.png';
import ImageControl from '../ModalControls/ImageControl';

class SignatureRuleModal extends React.Component {
  state = {
    claimType: 'default',
    recipientType: 'default',
    documentType: 'default',
    signatureThreshold: '0',
    signatureThresholdError: false,
    signatureThresholdErrorMessage: '',
    isButtonDisabled: true,
    selectedRecordIdImageLibrary: '0',
    selectedUploadViewConrolId: '',
    signature1: '',
    signature2: '',
    signature3: '',
    signatureImageId1: '',
    signatureImageId2: '',
    signatureImageId3: '',
    signatureImage1: '',
    signatureImage2: '', 
    signatureImage3: '',
    mode:'',
    selectedDataToCommit: null,
    isTestedSignature1: false,
    isTestedSignature2: false,
    isTestedSignature3: false,
    searchHasError: '',
    searchErrorMessage: '',
    searchToggle:false,
    keyword: ''
  };

  componentDidMount() {
    this.getInitialValues();
  }

  componentDidUpdate(prevProps) {
    const {
      detailElements,
      isShowingSignatureRuleModal,
      postImageUploadSuccessId,
      postImageCommitSuccessId
    } = this.props;
    const {mode} = this.state;
    if (
      prevProps.detailElements !== detailElements ||
      prevProps.isShowingSignatureRuleModal !== isShowingSignatureRuleModal
    ) {
      this.getInitialValues();
    }

    if(postImageUploadSuccessId &&
      postImageUploadSuccessId.imageId &&
      !postImageUploadSuccessId.isTestedSuccess && 
      ( !prevProps.postImageUploadSuccessId ||
        (prevProps.postImageUploadSuccessId.imageId.imageId !== postImageUploadSuccessId.imageId.imageId)) &&
       mode === 'upload'){
      this.updateImageData();
    }

    if(postImageCommitSuccessId &&
      postImageCommitSuccessId.imageId && 
      ( !prevProps.postImageCommitSuccessId ||
        (prevProps.postImageCommitSuccessId.isTested !== postImageCommitSuccessId.isTested) ||
        (prevProps.postImageCommitSuccessId.imageId !== postImageCommitSuccessId.imageId) ) ) {
      this.commitImageData();
    }
  }

  updateImageData = () => {
    const {postImageUploadSuccessId, actionType} = this.props;
    const {selectedUploadViewConrolId, signature1} = this.state;
    if(
      postImageUploadSuccessId && 
      postImageUploadSuccessId.convertedImageUploadData !== undefined && 
      postImageUploadSuccessId.convertedImageUploadData !== null ){
      if(selectedUploadViewConrolId === 'signature1'){
        this.setState({signature1:postImageUploadSuccessId.imageName, 
          signatureImageId1: String(postImageUploadSuccessId.imageId.imageId),
          signatureImage1: postImageUploadSuccessId.convertedImageUploadData.convertedImage
        });
      }
      if(selectedUploadViewConrolId === 'signature2'){
        this.setState({signature2:postImageUploadSuccessId.imageName, 
          signatureImageId2: String(postImageUploadSuccessId.imageId.imageId),
          signatureImage2: postImageUploadSuccessId.convertedImageUploadData.convertedImage
        });
      }
      if(selectedUploadViewConrolId === 'signature3'){
        this.setState({signature3:postImageUploadSuccessId.imageName, 
          signatureImageId3: String(postImageUploadSuccessId.imageId.imageId),
          signatureImage3: postImageUploadSuccessId.convertedImageUploadData.convertedImage
        });
      }
    }
    if (actionType === 'Edit a' || actionType === 'Add a') {
      const signatureImage1Temp = signature1 || (selectedUploadViewConrolId === 'signature1' && postImageUploadSuccessId);
      this.setState ({isButtonDisabled : !signatureImage1Temp });

    }
  }

  commitImageData = () => {
    const {postImageCommitSuccessId } = this.props;
    const {selectedUploadViewConrolId, signatureImageId1, signatureImageId2, signatureImageId3} = this.state;
    if(postImageCommitSuccessId){
      if(selectedUploadViewConrolId === 'signature1'){
        if(postImageCommitSuccessId.imageId === signatureImageId2){
          this.setState({
            isTestedSignature2: postImageCommitSuccessId.isTested,
          });
        }
        if(postImageCommitSuccessId.imageId === signatureImageId3){
          this.setState({
            isTestedSignature3: postImageCommitSuccessId.isTested,
          });
        }
        this.setState({
          isTestedSignature1: postImageCommitSuccessId.isTested,
        });
      }
      if(selectedUploadViewConrolId === 'signature2'){
        if(postImageCommitSuccessId.imageId === signatureImageId1){
          this.setState({
            isTestedSignature1: postImageCommitSuccessId.isTested,
          });
        }
        if(postImageCommitSuccessId.imageId === signatureImageId3){
          this.setState({
            isTestedSignature3: postImageCommitSuccessId.isTested,
          });
        }
        this.setState({
          isTestedSignature2: postImageCommitSuccessId.isTested,
        });
      }
      if(selectedUploadViewConrolId === 'signature3'){
        if(postImageCommitSuccessId.imageId === signatureImageId1){
          this.setState({
            isTestedSignature1: postImageCommitSuccessId.isTested,
          });
        }
        if(postImageCommitSuccessId.imageId === signatureImageId2){
          this.setState({
            isTestedSignature2: postImageCommitSuccessId.isTested,
          });
        }
        this.setState({
          isTestedSignature3: postImageCommitSuccessId.isTested,
        });
      }
    }
  }

  populateCodeFromValue = name => {
    const { detailElements, selectedRuleDetail } = this.props;
    if (!_.isEmpty(detailElements)) {
      if (name && detailElements.claimTypesList) {
        let selectedCode = '';
        let selectedList = '';
        let selectedName = name.replace('List', '');
        if (selectedName.indexOf('Types')) {
          selectedName = selectedName.replace('Types', 'Type');
        }
        const selectedValue = selectedRuleDetail[selectedName];
        selectedList = name;

        const firstValue = detailElements[selectedList].find(x =>
          x.value.includes(selectedValue),
        );

        const arr = firstValue
          ? [...new Set([firstValue, ...detailElements[selectedList]])]
          : detailElements[selectedList];

        arr.map(r => {
          if (r.value === selectedValue) {
            selectedCode = r.code;
          }
          return {
            label: r.value,
            value: r.code,
          };
        });
        return selectedCode;
      }
    }
    return '';
  };

  getInitialValues = () => {
    const { detailElements, selectedRuleDetail, actionType } = this.props;
    let claimType = 'default';
    let recipientType = 'default';
    let documentType = 'default';
    let signatureThreshold = '';
    if (
      !_.isEmpty(detailElements) &&
      !_.isEmpty(selectedRuleDetail) &&
      actionType !== 'Add a'
    ) {
      if (actionType === 'Edit a' || actionType === 'Clone a') {
        if (detailElements.claimTypesList) {
          claimType = detailElements.claimTypesList.find(
            o => o.value === selectedRuleDetail.claimType,
          ).code;
        }
        if (detailElements.recipientTypesList) {
          recipientType = detailElements.recipientTypesList.find(
            o => o.value === selectedRuleDetail.recipientType,
          ).code;
        }
        if (
          detailElements.documentTypesList &&
          selectedRuleDetail.documentType !== 'Any'
        ) {
          documentType = detailElements.documentTypesList.find(
            o => o.value === selectedRuleDetail.documentType,
          ).code;
        } else {
          documentType = 'default';
        }
      }

      if (actionType === 'Read only') {
        if (detailElements.claimTypesList) {
          claimType = detailElements.claimTypesList.find(
            o => o.value === selectedRuleDetail.claimType,
          ).value;
        }
        if (detailElements.recipientTypesList) {
          recipientType = detailElements.recipientTypesList.find(
            o => o.value === selectedRuleDetail.recipientType,
          ).value;
        }
        if (detailElements.documentTypesList) {
          documentType = detailElements.documentTypesList.find(
            o => o.value === selectedRuleDetail.documentType,
          ).value;
        }
      }


      if (
        selectedRuleDetail.signatureThreshold !== null &&
        selectedRuleDetail.signatureThreshold !== undefined
      ) {
        let toNumber = Number(selectedRuleDetail.signatureThreshold);
        toNumber = toNumber.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        signatureThreshold = String(toNumber);
      }

      const {
        signature1,
        signature2,
        signature3,
        signatureImageId1,
        signatureImageId2,
        signatureImageId3,
        signatureImage1,
        signatureImage2,
        signatureImage3,
      } = selectedRuleDetail;

      const {
        isTestedSignature1,
        isTestedSignature2,
        isTestedSignature3,
      } = selectedRuleDetail;

      this.setState(
        {
          claimType,
          recipientType,
          documentType,
          signatureThreshold,
          isButtonDisabled: true,
          signature1,
          signature2,
          signature3,
          signatureImageId1,
          signatureImageId2,
          signatureImageId3,
          signatureImage1,
          signatureImage2,
          signatureImage3,
          isTestedSignature1,
          isTestedSignature2,
          isTestedSignature3,
        },
        () => {
          // to validate existing value from db
          this.validateFormInputs('signatureThreshold');
        },
      );
    } else {
      const {
        isTestedSignature1,
        isTestedSignature2,
        isTestedSignature3,
      } = selectedRuleDetail;
      this.setState(
        {
          claimType,
          recipientType,
          documentType,
          signatureThreshold,
          isButtonDisabled: true,
          isTestedSignature1,
          isTestedSignature2,
          isTestedSignature3,
        },
        () => {
          // to validate existing value from db
          this.validateFormInputs('signatureThreshold');
        },
      );
    }
  };

  getRuleVersion = record => {
    try {
      const { status, version } = record;
      const testVersion = version[status.indexOf('T')];
      if (testVersion) return testVersion;
      const liveVersion = version[status.indexOf('L')];
      return liveVersion;
    } catch (e) {
      console.log(e);
    }
    return null;
  };

  populateDataDropDown = name => {
    const { detailElements, selectedRuleDetail } = this.props;
    if (!_.isEmpty(detailElements)) {
      if (detailElements) {
        let options = [];

        const selectedValue = selectedRuleDetail[name];

        const selectedList = name;

        const firstValue = detailElements[selectedList].find(x =>
          x.value.includes(selectedValue),
        );
        const arr = firstValue
          ? [...new Set([firstValue, ...detailElements[selectedList]])]
          : detailElements[selectedList];
        options = arr
          .map(r => ({
            label: r.value,
            value: r.code,
          }))
          .filter(v => v.value !== '');
        return options;
      }
    }
    return '';
  };

  onCancel = () => {
    const {
      setIsCloningRuleDetail,
      setIsPostingRuleDetail,
      setIsShowingSignatureRuleModal,
      setIsReadOnlyRuleDetail
    } = this.props;
    setIsCloningRuleDetail(false);
    setIsPostingRuleDetail(false);
    setIsShowingSignatureRuleModal(false);
    setIsReadOnlyRuleDetail(false);
  };

  onSave = () => {
    const {
      isCloningRuleDetail,
      isPostingRuleDetail,
      postRuleDetails,
      putRuleDetails,
      selectedRuleDetail,
      selectedRuleRecord,
      selectedRuleTypeId,
      setIsCloningRuleDetail,
      setIsPostingRuleDetail,
    } = this.props;
    const {
      claimType,
      recipientType,
      documentType,
      signatureThreshold,
    } = this.state;
   
    const ruleDetailId = selectedRuleDetail.id;
    const ruleId = selectedRuleRecord.id;
    const ruleVersion = this.getRuleVersion(selectedRuleRecord);
    const ruleType = selectedRuleTypeId;
    let ruleDetail = {
      claimType,
      recipientType,
      documentType,
      signatureThreshold:
        signatureThreshold === ''
          ? '0'
          : String(parseFloat(signatureThreshold.replace(/,/g, ''))),
    };

    const {signatureImageId1, signatureImageId2, signatureImageId3} = this.state;
    ruleDetail.signature1 = signatureImageId1 || 'default';
    ruleDetail.signature2 = signatureImageId2 || 'default';
    ruleDetail.signature3 = signatureImageId3 || 'default';

    ruleDetail = isCloningRuleDetail
      ? { ...ruleDetail, ...{ ruleDetailIdFrom: ruleDetailId } }
      : ruleDetail;
 
    if (isPostingRuleDetail || isCloningRuleDetail) {
      postRuleDetails({ ruleDetail, ruleType, ruleId, ruleVersion });
      setIsCloningRuleDetail(false);
      setIsPostingRuleDetail(false);
    } else {
      putRuleDetails({
        ruleDetail,
        ruleType,
        ruleId,
        ruleVersion,
        ruleDetailId,
      });
    }  
  };

  setButtonDisableOnDefault(dbCodes) {
    const { actionType } = this.props;
    const {
      claimType,
      recipientType,
      documentType,
      signatureThreshold,
      signatureImageId1,
      signatureImageId2,
      signatureImageId3,
    } = this.state;
    const signatureThresholdParsed = parseFloat(signatureThreshold.replace(/,/g, ''))
    const signatureThresholdDbCodeParsed = Math.floor(Number(dbCodes.signatureThresholdDbCode)*100)/100;
    let newState = false;
    if (
      (actionType === 'Edit a' &&
        (claimType === dbCodes.claimTypeDbCode &&
          recipientType === dbCodes.recipientTypeDbCode &&
          documentType === dbCodes.documentTypeDbCode &&
          signatureImageId1 === dbCodes.signatureImageId1DbCode &&
          signatureImageId2 === dbCodes.signatureImageId2DbCode &&
          signatureImageId3 === dbCodes.signatureImageId3DbCode &&
          signatureThresholdParsed ===
          signatureThresholdDbCodeParsed)) ||
      (actionType === 'Clone a' &&
        (claimType === dbCodes.claimTypeDbCode &&
          recipientType === dbCodes.recipientTypeDbCode &&
          documentType === dbCodes.documentTypeDbCode &&
          signatureThresholdParsed ===
          signatureThresholdDbCodeParsed))
    ) {
      newState = true;
    }
    
    this.setState({ isButtonDisabled: newState });
  }

  checkUnsavedChanges = event => {
    const { selectedRuleDetail } = this.props;
    const { populateCodeFromValue, validateFormInputs } = this;
    const claimTypeDbCode = populateCodeFromValue('claimTypesList');
    const recipientTypeDbCode = populateCodeFromValue('recipientTypesList');
    const documentTypeDbCode = populateCodeFromValue('documentTypesList');
    const signatureThresholdDbCode = selectedRuleDetail.signatureThreshold;
    const signatureImageId1DbCode = selectedRuleDetail.signatureImageId1;
    const signatureImageId2DbCode = selectedRuleDetail.signatureImageId2;
    const signatureImageId3DbCode = selectedRuleDetail.signatureImageId3;

    const dbCodes = {
      claimTypeDbCode,
      recipientTypeDbCode,
      documentTypeDbCode,
      signatureThresholdDbCode,
      signatureImageId1DbCode,
      signatureImageId2DbCode,
      signatureImageId3DbCode
    };
    if (event.target) {
      const { name, value } = event.target;
      this.setState(
        {
          [name]: value,
        },
        () => {
          validateFormInputs(name);
          this.setButtonDisableOnDefault(dbCodes);
        },
      );
    }
  };

  validateFormInputs = input => {
    const { signatureThreshold } = this.state;

    if (input === 'signatureThreshold') {
      let { signatureThresholdError } = this.state;
      const regexNumeric = /^[+-]?((\d+(\.\d*)?)|(\.\d+))$/;
      if (!signatureThreshold) {
        signatureThresholdError = false;
      } else if (
        signatureThreshold !== undefined &&
        signatureThreshold !== null &&
        regexNumeric.test(signatureThreshold.replace(/,/g, '')) &&
        (signatureThreshold.substr(signatureThreshold.indexOf('.') + 1)
          .length <= 2 ||
          signatureThreshold.indexOf('.') < 1)
      ) {
        const regExpDecimal = /^\d?[0-9]{0,14}(\.\d?[0-9]{1,2})?$/;
        const toNumber = Number(
          parseFloat(signatureThreshold.replace(/,/g, '')),
        );
        signatureThresholdError =
          input === 'signatureThreshold' && input !== undefined
            ? !regExpDecimal.test(toNumber)
            : signatureThresholdError;
      } else {
        signatureThresholdError = true;
      }
      this.setState({
        signatureThresholdError,
        signatureThresholdErrorMessage: signatureThresholdError
          ? 'Please enter valid numeric value only. Up to 15 digits max and 2 numeric digit allowed after decimal.'
          : '',
      });
    }
  };

  formatCurrency = event => {
    const { validateFormInputs } = this;
    const inputValueOrignal = event.target.value.replace(/ /g, '');
    if (
      inputValueOrignal !== null &&
      inputValueOrignal !== undefined &&
      inputValueOrignal !== ''
    ) {
      let inputValue = inputValueOrignal.replace(/,|^0+/g, '');
      inputValue =
        inputValueOrignal.includes('.') &&
        inputValue.split('.')[1].length === 0 &&
        inputValue.split('.')[0].length !== 0
          ? `${inputValue}0`
          : inputValueOrignal; // to add 0 in back
      inputValue =
        inputValueOrignal.includes('.') && inputValue.split('.')[0].length === 0
          ? `0${inputValue}`
          : inputValue; // to add 0 in front
      inputValue =
        inputValue.split('.')[0].length === 0
          ? this.toFixedTrunc(inputValue, 2)
          : inputValue;
      if (inputValue.split('.')[0].length > 1)
        inputValue = inputValue.replace(/^0+/, '');
      inputValue = this.toFixedTrunc(inputValue, 2);
      const toNumber = inputValue
        .replace(/,/g, '')
        .split('')
        .reverse()
        .join('')
        .replace(/(\d{3})/g, '$1,')
        .replace(/,$/, '')
        .split('')
        .reverse()
        .join('');
      this.setState(
        {
          signatureThreshold: toNumber,
        },
        () => {
          validateFormInputs('signatureThreshold');
        },
      );
    }
  };

  toFixedTrunc(x, n) {
    const v = (typeof x === 'string' ? x : x.toString()).split('.');
    if (n <= 0) return v[0];
    let f = v[1] || '';
    if (f.length > n) return `${v[0]}.${f.substr(0, n)}`;
    while (f.length < n) f += '0';
    return `${v[0]}.${f}`;
  }

  handleImageAction = (event, actionType, signatureType, imageId, imageName) => {
    const {
      setIsShowingImageLibraryModal,
      setIsShowingImageUploadModal,
      selectedRuleDetail,
      selectedRuleTypeId,
      getRuleImages,
      getRuleImagesCount,
      setIsShowingCommitImageModal
    } = this.props;
    const selectedRecordIdImageLibrary =
      selectedRuleDetail[signatureType] !== undefined
        ? selectedRuleDetail[signatureType]
        : '0';
    if (actionType === 'library') {
      const offset = null;
      getRuleImages({selectedRuleTypeId, offset}); // to pull records
      getRuleImagesCount({selectedRuleTypeId}); // to pull count
      setIsShowingImageLibraryModal(
        selectedRuleTypeId,
        selectedRecordIdImageLibrary,
        true,
      );
      this.setState({keyword:''});
    }  else if (actionType === 'remove') {
      if (signatureType === 'signature1') {
        this.setState({signature1: '', signatureImage1: '', signatureImageId1: ''});
      } else if (signatureType === 'signature2') {
        this.setState({signature2: '', signatureImage2: '', signatureImageId2: ''});
      } else {
        this.setState({signature3: '', signatureImage3: '', signatureImageId3: ''});
      }
      this.checkUnsavedChanges(event);
    }  else if (actionType === 'commit') {
      this.setState ({selectedDataToCommit: {imageName, imageId, uniqueId: signatureType}});
      setIsShowingCommitImageModal({status:true, workflow:'ruledetail'});
    } else {
      setIsShowingImageUploadModal(
        selectedRuleTypeId,
        selectedRecordIdImageLibrary,
        true,
      );
    }
    this.setState({ selectedRecordIdImageLibrary });
  };

  // called when image library cancel button is clicked to close image library modal
  onCancelImageLibrary = () => {
    const { setIsShowingImageLibraryModal, resetRuleImagesData, selectedRuleTypeId} = this.props;
    resetRuleImagesData({selectedRuleTypeId});
    setIsShowingImageLibraryModal(false);
    this.setState({searchHasError:false, searchErrorMessage:''})
  };

  // called when image upload cancel button is clicked to close image upload modal
  onCancelImageUpload = () => {
    const { setIsShowingImageUploadModal } = this.props;
    setIsShowingImageUploadModal(false);
  };

  // called when image is selected from image library
  selectedImageData = (e,imageKey, imageSrc, imageAltLabel, imageId, isTestedStatus) => {
    if (imageKey !== null && imageSrc !== null && imageAltLabel !== null) {
      const {
        selectedUploadViewConrolId
      } = this.state;
      if (selectedUploadViewConrolId === 'signature1') {
        this.setState({
          signature1: imageAltLabel,
          signatureImage1: imageSrc,
          signatureImageId1: imageId,
          isTestedSignature1: isTestedStatus
        });
      } else if (selectedUploadViewConrolId === 'signature2') {
        this.setState({
          signature2: imageAltLabel,
          signatureImage2: imageSrc,
          signatureImageId2: imageId,
          isTestedSignature2: isTestedStatus
        });
      } else {
        this.setState({
          signature3: imageAltLabel,
          signatureImage3: imageSrc,
          signatureImageId3: imageId,
          isTestedSignature3: isTestedStatus
        });
      }
      const { actionType } = this.props;
      const {signature1} = this.state;
      if (actionType === 'Edit a' || actionType === 'Add a') {
        const signature1Temp = signature1 || (selectedUploadViewConrolId === 'signature1' && imageAltLabel);
        this.setState ({isButtonDisabled : !signature1Temp });
      }
      this.checkUnsavedChanges(e);
    }
  };

  // called when image is selected from image library
  selectedImageControl = (e, actionType, uniqueId, imageId, imageName) => {
    const {handleImageAction} = this;
    handleImageAction(e, actionType, uniqueId, imageId, imageName);
    this.setState({selectedUploadViewConrolId: uniqueId, mode: actionType });
  };

  getKeyword = () => {
    return document.getElementById("image-filter").value;
  }
 
  userSearchWithinImages = () => {
    let {keyword} = this.state;
    const {searchToggle} = this.state;
    const checkValue = /^[a-zA-Z0-9-_ ]*$/;
    keyword = this.getKeyword();
    this.setState({searchToggle:!searchToggle});
    if (checkValue.test(keyword) && (keyword.length === 0 || keyword.length >= 3)) {
      this.setState({keyword})
      this.setState({searchHasError:false})
    } else {
      this.setState({searchHasError:true})
      this.setState({searchErrorMessage:'Please enter at least three valid characters - alphanumeric, space, - or _ is allowed.'})
    } 
    this.updateImageLibraryGrid();
  };

  updateImageLibraryGrid = () => {
    const {
      selectedRuleTypeId,
      getRuleImages,
      getRuleImagesCount,
      resetRuleImagesData
    } = this.props;
    let {keyword} = this.state;
    keyword = this.getKeyword();
    const checkValue = /^[a-zA-Z0-9-_ ]*$/;
    // only make call if valid search
    if(checkValue.test(keyword) && (keyword.length === 0 || keyword.length >= 3)) {
      resetRuleImagesData({selectedRuleTypeId});
      getRuleImages({selectedRuleTypeId, offset:null, keyword}); // to pull records
      getRuleImagesCount({selectedRuleTypeId, keyword}); // to pull count
    }
  }

  loadMoreRecords = (offset) => {
    const { selectedRuleTypeId, getRuleImages } = this.props
    const checkValue = /^[a-zA-Z0-9-_ ]*$/;
    const {keyword} = this.state;
    if (checkValue.test(keyword) && keyword.length >= 3) {
      getRuleImages({selectedRuleTypeId, offset, keyword}); // pull records w/keyword after valid search
    } else {
      getRuleImages({selectedRuleTypeId, offset}); // pull records  wo/keyword after invalid search
    }
  }

  handleAcceptImageUpload = (e) => {
    const {selectedUploadViewConrolId, signature1} = this.state;
    const newSignature1State = signature1 || (selectedUploadViewConrolId === 'signature1');
    this.setState ({isButtonDisabled : !newSignature1State });
    this.checkUnsavedChanges(e);
  }

  render() {
    const {
      checkUnsavedChanges,
      formatCurrency,
      selectedImageData,
      selectedImageControl,
      loadMoreRecords,
      onCancelImageLibrary,
      handleAcceptImageUpload,
      userSearchWithinImages,
      getKeyword
    } = this;
    const {
      claimType,
      recipientType,
      documentType,
      signatureThreshold,
      signatureThresholdError,
      signatureThresholdErrorMessage,
      isButtonDisabled,
      selectedRecordIdImageLibrary,
      signature1,
      signature2,
      signature3,
      signatureImage1,
      signatureImage2,
      signatureImage3,
      selectedDataToCommit,
      signatureImageId1,
      signatureImageId2,
      signatureImageId3,
      isTestedSignature1,
      isTestedSignature2,
      isTestedSignature3,
      searchHasError,
      searchErrorMessage,
      searchToggle
    } = this.state;
    const {
      loading,
      selectedRuleRecord,
      selectedRecord,
      isShowingImageLibraryModal,
      setIsShowingImageLibraryModal,
      selectedRuleDetail,
      selectedRuleTypeId,
      postImageUpload,
      isShowingImageUploadModal,
      setIsShowingImageUploadModal,
      ruleImages,
      ruleImagesCount,
      convertImageUpload,
      convertedImageUploadData,
      convertedImageUploadError,
      isShowingCommitImageModal,
      actionType
    } = this.props;
    const ruleDetailId = selectedRuleDetail.id;
    const ruleVersion = this.getRuleVersion(selectedRuleRecord);
    const recordId = selectedRuleRecord
      ? selectedRuleRecord.id
      : selectedRecord.id;

    const claimTypeOptions = this.populateDataDropDown('claimTypesList');
    const recipientTypeOptions = this.populateDataDropDown(
      'recipientTypesList',
    );
    const documentTypeOptions = this.populateDataDropDown('documentTypesList');

    const signature1ImageKey = signatureImageId1 || null;
    const signature1ImageSrc = signatureImage1 || null;
    const signature1ImageAltLabel = signature1 || null;

    const signature2ImageKey = signatureImageId2 || null;
    const signature2ImageSrc = signatureImage2 || null;
    const signature2ImageAltLabel = signature2 || null;

    const signature3ImageKey = signatureImageId3 || null;
    const signature3ImageSrc = signatureImage3 || null;
    const signature3ImageAltLabel = signature3 || null;

    // FOR READ ONLY CONDITIONAL RENDERING (Live Rule)
    let claimTypeReadOnly;
    let recipientTypeReadOnly;
    let documentTypeReadOnly;
    if (actionType === 'Read only') {
      claimTypeReadOnly = (claimType !== 'default') ? claimType : 'Any';
      recipientTypeReadOnly = (recipientType !== 'default') ? recipientType : 'Any';
      documentTypeReadOnly = (documentType !== 'default') ? documentType : 'Any';
    }


    return (
      <>
        {isShowingImageLibraryModal ? (
          <Modal
            domID="image-library-modal"
            isOpen={isShowingImageLibraryModal}
            onModalToggle={onCancelImageLibrary}
          >
            <RuleTypesDetailRow>
              <DetailHeader
                domID="header-title-image-library"
                title="Signature Library"
                marginBottom={0}
              />
            </RuleTypesDetailRow>
            <ImageFilter
              domID="image-filter"
              onEnterPress={userSearchWithinImages}
              onButtonClick={userSearchWithinImages}
              placeholder="Search by Image Name"
              hasError={searchHasError}
              errorMessage={searchHasError ? searchErrorMessage : null}
              buttonContent={<SearchButtonImage alt="search" src={searchIcon} />}
            />
            <ImageLibraryModal
              loading={loading}
              ruleImages={ruleImages}
              ruleImagesCount={ruleImagesCount}
              searchToggle={searchToggle}
              getKeyword={getKeyword}
              setIsShowingImageLibraryModal={setIsShowingImageLibraryModal}
              selectedRuleDetail={selectedRuleDetail}
              selectedRecordIdImageLibrary={selectedRecordIdImageLibrary}
              selectedRuleTypeId={selectedRuleTypeId}
              selectedImageData={(e,imageKey, imageSrc, imageAltLabel, imageId, isTestedStatus) =>
                selectedImageData(e,imageKey, imageSrc, imageAltLabel, imageId, isTestedStatus)
              }
              loadMoreRecords={(offset) => loadMoreRecords(offset)}
              onCancelImageLibrary={onCancelImageLibrary}
            />
          </Modal>
        ) : null}
        
        {isShowingImageUploadModal ? (
          <Modal
            domID="image-upload-modal"
            isOpen={isShowingImageUploadModal}
            onModalToggle={this.onCancelImageUpload}
          >
            <DetailHeader
              domID="header-title-image-upload"
              title="Signature Upload"
              marginBottom={0}
            />
            <center><span className="choose-caption">
            Choose your own signature and upload it. Your signature will be added to the library.
            </span></center>
            <FileUpload
              loading={loading}
              postImageUpload={postImageUpload}
              setIsShowingImageUploadModal={setIsShowingImageUploadModal}
              selectedRuleTypeId={selectedRuleTypeId}
              fileUploadMessage="Drag a jpg, png, jpeg, tiff, tif, gif, bmp file here. Max 2MB. Max dimensions 1280x720."
              acceptedFileTypes="jpg,png,jpeg,tiff,tif,gif,bmp"
              fileSizeAllowed={2106286} // bytes == 2.00 Mb
              allowMultiple={false}
              convertImageUpload={convertImageUpload}
              convertedImageUploadData={convertedImageUploadData}
              convertedImageUploadError={convertedImageUploadError}
              handleAcceptImageUpload={(e) =>
                handleAcceptImageUpload(e)
              }
            />
          </Modal>
        ) : null}
        {isShowingCommitImageModal !== undefined && isShowingCommitImageModal.status === true ? (
          <CommitImageModal
            {...this.props}
            workflow={isShowingCommitImageModal.workflow}
            selectedDataToCommit={selectedDataToCommit}
            ruleId={recordId}
            ruleVersion={ruleVersion}
            ruleDetailId={ruleDetailId}
          />
        ) : null}
        <ContainerBox>
          {actionType !== 'Read only' ? (
            <SignatureRuleDropDown>
              <DropOne>
                <DropDownControl
                  dropDownDomID="signature-arr-claim-type"
                  dropDownLabel="claim type"
                  dropDownName="claimType"
                  dropDownOptions={claimTypeOptions}
                  dropDownInitialValue={claimType}
                  dropDownOnChangeHandle={checkUnsavedChanges}
                />
              </DropOne>
            </SignatureRuleDropDown>
          ) : <ReadOnlyTextTitle>Claim Type<ReadOnlyTextArea>{claimTypeReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
          {actionType !== 'Read only' ? (
            <SignatureRuleDropDown>
              <DropTwo>
                <DropDownControl
                  dropDownDomID="signature-arr-recipient-type"
                  dropDownLabel="recipient"
                  dropDownName="recipientType"
                  dropDownOptions={recipientTypeOptions}
                  dropDownInitialValue={recipientType}
                  dropDownOnChangeHandle={checkUnsavedChanges}
                />
              </DropTwo>
            </SignatureRuleDropDown>
          ) :  <ReadOnlyTextTitle>Recipient Type<ReadOnlyTextArea>{recipientTypeReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
          {actionType !== 'Read only' ? (
            <SignatureRuleDropDown>
              <DropThree>
                <DropDownControl
                  dropDownDomID="signature-arr-document-type"
                  dropDownLabel="document type"
                  dropDownName="documentType"
                  dropDownOptions={documentTypeOptions}
                  dropDownInitialValue={documentType}
                  dropDownOnChangeHandle={checkUnsavedChanges}
                />
              </DropThree>
            </SignatureRuleDropDown>
          ) : <ReadOnlyTextTitle>Document Type<ReadOnlyTextArea>{documentTypeReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
        </ContainerBox>
        <ContainerBox>
          {actionType !== 'Read only' ? (
            <SignatureImageWrapper className={!signature1ImageKey ? "first-rule-required" : ''}>
              <UploadAndViewImageControl
                uniqueId="signature1"
                headerText="SIGNATURE 1"
                libraryButtonClassName="library-link"
                libraryButtonText="Library"
                uploadButtonClassName="upload-link"
                uploadButtonText="Upload"
                onClickHandleLibrary={e =>
                  selectedImageControl(e, 'library', 'signature1',signature1ImageKey, signature1ImageAltLabel)
                }
                onClickHandleUpload={e =>
                  selectedImageControl(e, 'upload', 'signature1',signature1ImageKey, signature1ImageAltLabel)
                }
                onClickHandleRemove={e =>
                  selectedImageControl(e, 'remove', 'signature1',signature1ImageKey, signature1ImageAltLabel)
                }
                onClickHandleCommit={e =>
                  selectedImageControl(e, 'commit', 'signature1',signature1ImageKey, signature1ImageAltLabel)
                }
                imageKey={signature1ImageKey}
                imageSrc={signature1ImageSrc}
                imageAltLabel={signature1ImageAltLabel}
                isTested={isTestedSignature1}
              />
              {!signature1ImageKey ? <span className="s1-required-err">Signature 1 is required</span> : null}
            </SignatureImageWrapper>
          ) : <ReadOnlyTextTitle>
        Signature 1
            {signature1 !== undefined ? (
              <ImageControl
                uniqueId="signature1"
                imageKey={signature1ImageKey}
                imageSrc={signature1ImageSrc}
                imageAltLabel={signature1ImageAltLabel}
                isTested={isTestedSignature1}
                imageStyles={{ marginRight: '2em', fontSize: '13px', fontWeight: 'normal', textTransform: 'none' }}
                imageOnClickHandle={() => {}}
                imageOnLoad={() => {}}
                imageOnError={() => {}}
                imageSelection={false}
              />
            ) : <ReadOnlyTextArea>-</ReadOnlyTextArea>}
          </ReadOnlyTextTitle>}

          {actionType !== 'Read only' ? ( 
            <SignatureImageWrapper>
              <UploadAndViewImageControl
                uniqueId="signature2"
                headerText="SIGNATURE 2"
                libraryButtonClassName="library-link"
                libraryButtonText="Library"
                uploadButtonClassName="upload-link"
                uploadButtonText="Upload"
                onClickHandleLibrary={e =>
                  selectedImageControl(e, 'library', 'signature2',signature2ImageKey, signature2ImageAltLabel)
                }
                onClickHandleUpload={e =>
                  selectedImageControl(e, 'upload', 'signature2',signature2ImageKey, signature2ImageAltLabel)
                }
                onClickHandleRemove={e =>
                  selectedImageControl(e, 'remove', 'signature2',signature2ImageKey, signature2ImageAltLabel)
                }
                onClickHandleCommit={e =>
                  selectedImageControl(e, 'commit', 'signature2',signature2ImageKey, signature2ImageAltLabel)
                }
                imageKey={signature2ImageKey}
                imageSrc={signature2ImageSrc}
                imageAltLabel={signature2ImageAltLabel}
                isTested={isTestedSignature2}
              />
            </SignatureImageWrapper>
          ) :  <ReadOnlyTextTitle>
        Signature 2
            {signature2 !== undefined ? (
              <ImageControl
                uniqueId="signature2"
                imageKey={signature2ImageKey}
                imageSrc={signature2ImageSrc}
                imageAltLabel={signature2ImageAltLabel}
                isTested={isTestedSignature2}
                imageStyles={{ marginRight: '2em', fontSize: '13px', fontWeight: 'normal', textTransform: 'none' }}
                imageOnClickHandle={() => {}}
                imageOnLoad={() => {}}
                imageOnError={() => {}}
                imageSelection={false}
              />
            ) : <ReadOnlyTextArea>-</ReadOnlyTextArea>}
          </ReadOnlyTextTitle>}

          {actionType !== 'Read only' ? (
            <SignatureImageWrapper>
              <UploadAndViewImageControl
                uniqueId="signature3"
                headerText="SIGNATURE 3"
                libraryButtonClassName="library-link"
                libraryButtonText="Library"
                uploadButtonClassName="upload-link"
                uploadButtonText="Upload"
                onClickHandleLibrary={e =>
                  selectedImageControl(e, 'library', 'signature3',signature3ImageKey, signature3ImageAltLabel)
                }
                onClickHandleUpload={e =>
                  selectedImageControl(e, 'upload', 'signature3',signature3ImageKey, signature3ImageAltLabel)
                }
                onClickHandleRemove={e =>
                  selectedImageControl(e, 'remove', 'signature3',signature3ImageKey, signature3ImageAltLabel)
                }
                onClickHandleCommit={e =>
                  selectedImageControl(e, 'commit', 'signature3',signature3ImageKey, signature3ImageAltLabel)
                }
                imageKey={signature3ImageKey}
                imageSrc={signature3ImageSrc}
                imageAltLabel={signature3ImageAltLabel}
                isTested={isTestedSignature3}
              />
            </SignatureImageWrapper>
          ) : <ReadOnlyTextTitle>
        Signature 3
            {signature3 !== undefined ? (
              <ImageControl
                uniqueId="signature3"
                imageKey={signature3ImageKey}
                imageSrc={signature3ImageSrc}
                imageAltLabel={signature3ImageAltLabel}
                isTested={isTestedSignature3}
                imageStyles={{ marginRight: '2em', fontSize: '13px', fontWeight: 'normal', textTransform: 'none' }}
                imageOnClickHandle={() => {}}
                imageOnLoad={() => {}}
                imageOnError={() => {}}
                imageSelection={false}
              />
            ) : <ReadOnlyTextArea>-</ReadOnlyTextArea>}
          </ReadOnlyTextTitle>}
        </ContainerBox>
         <ContainerBox>
           {actionType !== 'Read only' ? (
             <PaddingWrapper>
               <DollarLabel>$</DollarLabel>
               <InputControl
                 inputDomID="signature-arr-threshold"
                 inputLabel="Signature Threshold"
                 inputName="signatureThreshold"
                 inputInitialValue={String(signatureThreshold)}
                 inputStartWithBlankValue={false}
                 inputOnChangeHandle={checkUnsavedChanges}
                 inputDisabled={false}
                 inputIsHovered={false}
                 inputErrorMessage={signatureThresholdErrorMessage}
                 inputHasError={signatureThresholdError}
                 inputOnBlurHandle={e => formatCurrency(e)}
                 inputOnKeyDown={e => (e.key === 'Enter' ? formatCurrency(e) : '')}
               /> </PaddingWrapper>) : <ReadOnlyTextTitle>Signature Threshold<ReadOnlyTextArea><DollarLabelReadOnly>$ {String(signatureThreshold)}</DollarLabelReadOnly></ReadOnlyTextArea></ReadOnlyTextTitle>}
         </ContainerBox>
        <ButtonGroupBottom>
          <ModalButton>
            {loading.has(recordId) && (
              <LoadingWrapper>
                <LoadingIndicator
                  domID="basic-details-form-loading-indicator"
                  length="30px"
                />
              </LoadingWrapper>
            )}
            {actionType !== 'Read only' ? (
              <Button
                onClick={() => this.onSave()}
                domID="automation-id"
                name="Save"
                buttonType="emphasized"
                size="medium"
                isDropdown={false}
                type="button"
                disabled={isButtonDisabled || loading.has(recordId) || !signatureImage1}
              />) : null }
            <Button
              domID="automation-id"
              name={actionType !== 'Read only' ? 'Cancel' : 'Close'}
              buttonType="diminished"
              disabled={loading.has(recordId)}
              size="medium"
              onClick={() => this.onCancel()}
              isDropdown={false}
              type="button"
            />
          </ModalButton>
        </ButtonGroupBottom>
      </>
    );
  }
}

SignatureRuleModal.propTypes = {
  detailElements: PropTypes.object,
  loading: PropTypes.object,
  selectedRuleRecord: PropTypes.object,
  selectedRuleDetail: PropTypes.object,
  setIsCloningRuleDetail: PropTypes.func,
  setIsPostingRuleDetail: PropTypes.func,
  setIsShowingSignatureRuleModal: PropTypes.func,
  isShowingSignatureRuleModal: PropTypes.bool,
  isCloningRuleDetail: PropTypes.bool,
  isPostingRuleDetail: PropTypes.bool,
  postRuleDetails: PropTypes.func,
  putRuleDetails: PropTypes.func,
  setIsShowingImageLibraryModal: PropTypes.func,
  setIsShowingImageUploadModal: PropTypes.func,
  isShowingImageLibraryModal: PropTypes.bool,
  isShowingImageUploadModal: PropTypes.bool,
  postImageUpload: PropTypes.func,
  selectedRuleTypeId: PropTypes.string,
  selectedRecord: PropTypes.object,
  getRuleImages: PropTypes.func,
  ruleImages: PropTypes.object,
  getRuleImagesCount: PropTypes.func,
  ruleImagesCount: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  actionType: PropTypes.string,
  resetRuleImagesData: PropTypes.func,
  convertImageUpload: PropTypes.func,
  convertedImageUploadData: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  convertedImageUploadError: PropTypes.string,
  postImageUploadSuccessId: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  isShowingCommitImageModal: PropTypes.object,
  setIsShowingCommitImageModal: PropTypes.func,
  postImageCommitSuccessId: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
};
export default SignatureRuleModal;