import React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import ReserveForm from '../containers/FormImage835Manager';
import DeployForm from '../containers/FormImage835Manager/DeployFormIndex';
import CommitForm from '../containers/FormImage835Manager/CommitFormIndex';

const EtfRoutes = () => (
  <Switch>
    <Route path="/EtfMapManager/ReserveMap" component={ReserveForm} />
    <Route path="/EtfMapManager/DeployMap" component={DeployForm} />
    <Route path="/EtfMapManager/CommitMap" component={CommitForm} />
    <Route exact path="/EtfMapManager">
      <Redirect to="/EtfMapManager/ReserveMap?param=M" />
    </Route>
  </Switch>
)

export default EtfRoutes;