export const gridConfig = {
  columns: new Set([
    {
      dataName: 'ruleType',
      text: 'Rule Type',
      sortable: true,
    },
    {
      dataName: 'ruleName',
      text: 'Live Rule',
      sortable: true,
    },
  ]),
  selectionKey: 'ruleType',
  domID: 'liveassoc-tab-grid',
  supportSelection: false,
  initialSortingKey: 'ruleType|SORT_ASCENDING',
};
