import React, { useState } from 'react'
import { useLocation } from "react-router-dom";
import {
    Grid,
    useLayout,
    ListPaneHeader,
    Button,
} from 'ui-core/dist'
import axios from '../../axios'
import useTestForm from '../../api/useTestForm'
import { Loading } from '../../components'
import ErrorPage from '../ErrorPage';
import ListPaneHeaderWrapper from '../../components/ErrorDisplaySection/ListPaneHeaderWrapper';
import './modules/index.scss';

const DeployForm = () => {
    const [selected, setSelected] = useState([])
    const [message, setMessage] = useState({
        state: 'positive',
        msg: ''
    });

    const search = useLocation().search;
    const param = new URLSearchParams(search).get('param');

    const { expandCollapseListPaneButtonConfig, openDetailPane } = useLayout()
    const { data, isLoading, isError, error, refetch } = useTestForm(param)

    const getSuccessMsg = () => {
        if (param === '0') return `You've successfully deployed the below form(s) to test`;
        if (param === '1') return `You've successfully deployed the below image(s) to test`;
        if (param === 'M') return `You've successfully deployed the below map(s) to test`;
    }

    const deployForm = async () => {
        try {
            const res = await axios.post(`/forms/deploy?param=${param}`, { payload: selected });
            // notify(`${getSuccessMsg()}`, 'positive', false)
            setMessage({
                state: 'positive',
                msg: res?.data?.replaceAll('deployedList', 'ul').replaceAll('itemDeployed', 'li').replaceAll('frmDeployed', 'span')
            });
            // refetch()
            setSelected([])
        } catch (err) {
            setMessage({
                state: 'negative',
                msg: err.displayMessage?.replaceAll('<br>', '')
            });
            // notify(err.displayMessage.replace(/<[^>]*>?/gm, ''), "negative", false)
            // setSelected([])
        }
    }

    const getTitle = () => {
        if (param === '0') return 'Deploy Form';
        if (param === '1') return 'Deploy Image';
        if (param === 'M') return 'Deploy 835 Map';
    }

    const getButtonName = () => {
        if (param === '0') return 'Deploy Form To Test';
        if (param === '1') return 'Deploy Image To Test';
        if (param === 'M') return 'Deploy Map To Test';
    }

    const getColumnName = () => {
        if (param === '0') return 'Available Forms';
        if (param === '1') return 'Available Images';
        if (param === 'M') return 'Available 835 Maps';
    }

    const onRowSelect = (e, state) => {
        const ids = Array.from(state.selectedItemIds);
        setSelected(ids);
    }

    const onSelectAll = (e, state) => {
        const ids = Array.from(state.selectedItemIds);
        setSelected(ids);
    }

    const getName = () => {
        if (param === '0') return 'form';
        if (param === '1') return 'image';
        if (param === 'M') return 'map';
    }

    return (
        <>
            <ListPaneHeaderWrapper
                title={getTitle()}
                layoutControls={[expandCollapseListPaneButtonConfig]}
            />
            <p style={{ fontSize: '13px', padding: '0 40px' }}>This function provides the user with a list of the client's {getName()}s available in VSS to be deployed to the Client's Print Test directory. When the user selects a {getName()} or {getName()}s from the list and clicks Deploy {getName()} to Test, the {getName()} is then copied from VSS to the Print Test directory.</p>
            <div className="deployForm">
                <Button
                    domID="DeployForm"
                    name={getButtonName()}
                    onClick={deployForm}
                    size="small"
                    buttonType="standard"
                    className="deployForm__add-button"
                    disabled={selected.length === 0}
                />
                {message.msg && message.state === 'positive' ? <div style={{ color: '#0F0F59', fontSize: '13px', padding: '0 30px' }}>{getSuccessMsg()}</div> : <></>}
                {message.msg ? <>
                    {message.state === 'negative' && <div style={{ color: `${message.state === 'negative' ? 'red' : '#0F0F59'}`, fontSize: '13px', padding: '0 0 10px 30px' }}>There were errors processing your request:</div>}
                    <div style={{ color: `${message.state === 'negative' ? 'red' : '#0F0F59'}`, fontSize: '13px', padding: '0 0 10px 50px' }} dangerouslySetInnerHTML={{ __html: message.msg }}></div></> : <></>}
                <div className="deployForm__grid-wrapper">
                    {isLoading ? <Loading /> : 
            isError ? (
            // replace with a better error component
            <ErrorPage
              // this prop isn't real but is an example
              error={error}
            />
          ) : (
                        <Grid
                            columns={new Set([
                                {
                                    dataName: 'label',
                                    text: getColumnName(),
                                    cellType: 'text',
                                }
                            ])}
                            records={data || []}
                            dataTestId="test-deployFormGrid"
                            domID="DeployFormGrid"
                            selectionKey="value"
                            selectAllCheckboxDomID="DeployFormGridGridSelectAll"
                            onRowSelect={onRowSelect}
                            onSelectAll={onSelectAll}
                            supportSelection
                            initialSelectedItemIds={new Set(selected)}
                        />
                    )}
                </div>
            </div>
        </>
    )
}

export default DeployForm