/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useEffect, useCallback } from 'react';
import styled from 'styled-components/dist/styled-components'
import { useLayout } from 'ui-core/dist/useLayout';
import DetailPaneHeader from 'ui-core/dist/DetailPaneHeader';
import { useSelector } from 'react-redux';
import {
  Modal, Button, ModalHeader, Input, ModalBody
} from 'ui-core/dist';
import { SelectDropdown } from '../../../components';
import { detailPaneAnimStates } from '../../../commonResources/constants/animStates';
import ErrorPage from '../../ErrorPage';
import LoadingComponent from '../../../components/LoadingComponent';
import {SELECT_YES_NO_OPTIONS} from '../Modules/constants';
import { 
  useAddEmail,
  useActivateEmail,
  useDeactivateEmail,
  useModifyEmail,
  useGetSelected
} from "../../../api/useClientEmail";
import ModalWrapper from '../../../components/Modal/components/ModalWrapper';
import useNotificationBannerHook from '../../../useNotificationBannerHook';



const StyledModalWrapperWidth=styled(ModalWrapper)`
& .active-content {
  width: 30% !important;
}
`

const ClientEmailDetail = () => {
  const {
    expandCollapseDetailPaneButtonConfig,
    closeDetailPaneButtonConfig,
    detailAnimationState,
    closeDetailPane
  } = useLayout()

  const { notify } = useNotificationBannerHook();
  const [eEOBID, seteEOBID] = useState('')
  const [name, setName] = useState('')
  const [suppress, setSuppress] = useState('')
  const [emailAddress, setEmailAddress] = useState('')
  const [isActive, setIsActive] = useState(true)
  const [badEmail, setBadEmail] = useState('')
  const [ hierarchy1, setHierarchy1] = useState('')
  const [ hierarchy2, setHierarchy2] = useState('')
  const [ hierarchy3, setHierarchy3] = useState('')
  const [ hierarchy4, setHierarchy4] = useState('')
  const [ hierarchy5, setHierarchy5] = useState('')
  const [ hierarchy6, setHierarchy6] = useState('')
  const [IdNumber, setIdNumber] = useState('')
  const [editorMode, setEditorMode ] = useState('Add')
  const [isOpenConfirmationModal, setisOpenConfirmationModal] = useState(false)
  const [confirmationModalMode, setconfirmationModalMode] = useState('')
  const [count, setcount] = useState(0)

  const { ManageAddressRecord: records, ManageAddressMode: editorModep, resetControls }
    = useSelector(({ manageAddress }) => manageAddress);
  
  const { data: infoSelectedEmail, postData: getSelected, isLoading: isLoadingGetSelected,
    isError: isErrorGetSelected, error: errorGetSelected } = useGetSelected()
  
  const { data: infoAddEmail, postData: addEmail, metaData: addEmailResponse, isLoading: isLoadingAddEmail,
    isError: isErrorAddEmail, error: errorAddEmail } = useAddEmail()
  
  const { data: infoModifyEmail, postData: modifyEmail, metaData: modifyEmailResponse, isLoading: isLoadingModifyEmail,
    isError: isErrorModifyEmail, error: errorModifyEmail } = useModifyEmail()
  
  const { postData: activateEmail, metaData: reactivateddressResponse, isLoading: isLoadingActivateEmail,
    isError: isErrorActivateEmail, error: errorActivateEmail } = useActivateEmail()
  
  const { postData: deactivateEmail, metaData: deactivateEmailResponse, isLoading: isLoadingDeactivateEmail,
    isError: isErrorDeactivateEmail, error: errorDeactivateEmail } = useDeactivateEmail()

  useEffect(() => {
    if (records) {
      if (records.CEEOBRULE) {
        getSelected({param: `<![CDATA[<filter><client-table>ClientEmailAddresses</client-table><eeobrule>${records.CEEOBRULE}</eeobrule></filter>]]>`, count})
        setcount(count+1)
      } else {
        onClearCurrentRecordValues()
      }
    }
  }, [records]);

  const onReset = useCallback(() => {
    if (infoSelectedEmail && infoSelectedEmail['client-email-address']) {
      const { eeobrule: eEOBIDp, name: namep, suppress: suppressp,
        emailaddr: emailAddressp, bademail: badEmailp, active: activep,
        idnumber: IdNumberp, hierarchy1: hierarchy1p, hierarchy2: hierarchy2p,
        hierarchy3: hierarchy3p, hierarchy4: hierarchy4p, hierarchy5: hierarchy5p,
        hierarchy6: hierarchy6p} = infoSelectedEmail['client-email-address']
      seteEOBID(eEOBIDp)
      setIdNumber(IdNumberp)
      setName(namep)
      setEmailAddress(emailAddressp)
      setSuppress(suppressp === '0' ? {label: 'No',value: 0} : {label:'Yes',value: 1})
      setBadEmail(badEmailp  === '0' ? {label: 'No',value: 0} : {label:'Yes',value: 1})
      setIsActive(activep === '0' ? 0 : 1)
      setHierarchy1(hierarchy1p)
      setHierarchy2(hierarchy2p)
      setHierarchy3(hierarchy3p)
      setHierarchy4(hierarchy4p)
      setHierarchy5(hierarchy5p)
      setHierarchy6(hierarchy6p)
      setEditorMode(editorModep)
    }
  },[infoSelectedEmail])

  useEffect(() => {    
    onReset()
  },[infoSelectedEmail])

  useEffect(() => {
    if (addEmailResponse) {
      const { status: respStatus, statusText } = addEmailResponse;
      if (respStatus === 200 || statusText === "OK") {
        if (infoAddEmail && infoAddEmail.fs) {
          const { fs } = infoAddEmail;
          if (fs) {
            notify(fs, 'negative')
          }
          return;
        }        
        notify(`You have successfully added Client Email ${eEOBID}`, 'positive')
        closeDetailPane()
        if (resetControls) resetControls()
      }
    }
  }, [addEmailResponse])

  useEffect(() => {
    if (modifyEmailResponse) {
      const { status: respStatus, statusText } = modifyEmailResponse;
      if (respStatus === 200 || statusText === "OK") {
        if (infoModifyEmail && infoModifyEmail.fs) {
          const { fs } = infoModifyEmail;
          if (fs) {
            notify(fs, 'negative')
          } 
          return;
        }
        notify(`You have successfully modified Client Email ${eEOBID}`, 'positive')
        closeDetailPane()
        if (resetControls) resetControls()
      }
    }
  }, [modifyEmailResponse])

  useEffect(() => {
    if (deactivateEmailResponse) {
      const { status: respStatus, statusText } = deactivateEmailResponse;
      if (respStatus === 200 || statusText === "OK") {
        if (deactivateEmail && deactivateEmail.fs) {
          const { fs } = deactivateEmail;
          if (fs) {
            notify(fs, 'negative')
          } 
          return;
        }
        notify(`You have successfully deactivated Client Email ${eEOBID}`, 'positive')
        closeDetailPane()
        if (resetControls) resetControls()
      }
    }
  }, [deactivateEmailResponse, deactivateEmail])

  useEffect(() => {
    if (reactivateddressResponse) {
      const { status: respStatus, statusText } = reactivateddressResponse;
      if (respStatus === 200 || statusText === "OK") {
        if (activateEmail && activateEmail.fs) {
          const { fs } = activateEmail;
          if (fs) {
            notify(fs, 'negative')
          } 
          return;
        }
        notify(`You have successfully activated Client Email ${eEOBID}`, 'positive')
        closeDetailPane()
        if (resetControls) resetControls()
      }
    }
  }, [reactivateddressResponse, activateEmail])

  const onDeactivateClick = () => {
    if (editorMode === "Edit") {
      if(isActive)
        setconfirmationModalMode("Deactivate")
      else
        setconfirmationModalMode("Activate")
      setisOpenConfirmationModal(true)
    }
  }
  const onSubmitClick = () => {
    if (editorMode === "Add") {
      setconfirmationModalMode("Add")
    } else if (editorMode === "Edit") {
      setconfirmationModalMode("Modify")
    }
    setisOpenConfirmationModal(true)
  }

  const onConfirmationYesClick = () => {
    if (confirmationModalMode === "Deactivate") {
      deactivateEmail({ param: `
      <![CDATA[
        <filter>
          <client-table>ClientEmailAddresses</client-table>
          <eeobrule>${eEOBID}</eeobrule>
        </filter>
      ]]>`, count })
    } else if(confirmationModalMode === "Activate") {
      activateEmail({ param: `
      <![CDATA[
        <filter>
          <client-table>ClientEmailAddresses</client-table>
          <eeobrule>${eEOBID}</eeobrule>
        </filter>
      ]]>`, count })
    } else if (confirmationModalMode === "Add") {
      addEmail({
        param: `
        <![CDATA[<client-email>
          <client-table>ClientEmailAddresses</client-table>
          <eeobrule>${eEOBID}</eeobrule>
          <idnumber>${IdNumber}</idnumber>
          <name>${name}</name>
          <emailaddr>${emailAddress}</emailaddr>
          <hierarchy1>${hierarchy1}</hierarchy1>
          <hierarchy2>${hierarchy2}</hierarchy2>
          <hierarchy3>${hierarchy3}</hierarchy3>
          <hierarchy4>${hierarchy4}</hierarchy4>
          <hierarchy5>${hierarchy5}</hierarchy5>
          <hierarchy6>${hierarchy6}</hierarchy6>
          <suppress>${suppress.value}</suppress>
          <bademail>${badEmail.value}</bademail>
          <active>${isActive ? 1 : 0}</active>          
        </client-email>]]>`,
        count
      })
    } else if (confirmationModalMode === "Modify") {
      modifyEmail({
        param: `
        <![CDATA[<client-email>
          <client-table>ClientEmailAddresses</client-table>
          <eeobrule>${eEOBID}</eeobrule>
          <idnumber>${IdNumber}</idnumber>
          <name>${name}</name>
          <emailaddr>${emailAddress}</emailaddr>
          <hierarchy1>${hierarchy1}</hierarchy1>
          <hierarchy2>${hierarchy2}</hierarchy2>
          <hierarchy3>${hierarchy3}</hierarchy3>
          <hierarchy4>${hierarchy4}</hierarchy4>
          <hierarchy5>${hierarchy5}</hierarchy5>
          <hierarchy6>${hierarchy6}</hierarchy6>
          <suppress>${suppress.value}</suppress>
          <bademail>${badEmail.value}</bademail>
          <active>${isActive ? 1 : 0}</active>          
        </client-email>]]>`,
        count
      })
    }
    setconfirmationModalMode('')
    setisOpenConfirmationModal(false)
    setcount(count + 1)
  }

  const onClearCurrentRecordValues = () => {
    seteEOBID('')
    setIdNumber('')
    setName('')
    setEmailAddress('')
    setSuppress({label:'No',value: 0})
    setBadEmail({label:'No',value: 0})
    setHierarchy1('')
    setHierarchy2('')
    setHierarchy3('')
    setHierarchy4('')
    setHierarchy5('')
    setHierarchy6('')
    setEditorMode('Add')
  }

  // const onCopyCurrentRecordValues = () => {
  //   seteEOBID('')
  //   setIdNumber('');
  // }
  
  const renderEEOBID = useMemo(() => (
    editorMode === 'Add' ?
      <Input
        style={{ width: '200px' }}
        className="test-classname"
        domID="txt_rendereEOBID"
        placeholder=""
        size="small"
        maxLength={5}
        initialValue={eEOBID}
        onChange={(e) => {
          seteEOBID(e.target.value || "")
        }}
      /> : eEOBID
  ), [eEOBID, editorMode])

  const renderIdNumber = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_renderid"
      placeholder=""
      size="small"
      maxLength={127}
      initialValue={IdNumber}
      onChange={(e) => {
        setIdNumber(e.target.value || "")
      }}
    />
  ), [IdNumber])

  const renderName = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_renderName"
      placeholder=""
      size="small"
      maxLength={64}
      initialValue={name}
      onChange={(e) => {
        setName(e.target.value || "")
      }}
    />
  ), [name])

  const validateEmail = () => 
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(emailAddress)

  const renderEmailAddress = useMemo(() => (
    <div style={{width:"200px"}}>
      <Input
        style={{ width: '200px' }}
        className="test-classname"
        domID="txt_renderName"
        placeholder=""
        size="small"
        maxLength={127}
        initialValue={emailAddress}
        hasError={!emailAddress ? false : !validateEmail()}
        errorMessage='Invalid email address'
        onChange={(e) => {
          setEmailAddress(e.target.value || "")
        }}
      />
    </div>
  ), [emailAddress])

  const renderSuppress = useMemo(() => (
    <SelectDropdown
      domID="defer_state_input"
      label=""
      options={SELECT_YES_NO_OPTIONS}
      size="small"
      initialValue={suppress}
      onChange={(e)=>{
        setSuppress(e)
      }}
    />
  ), [suppress])

  const renderBadEmail = useMemo(() => (
    <SelectDropdown
      domID="defer_state_input"
      label=""
      options={SELECT_YES_NO_OPTIONS}
      size="small"
      initialValue={badEmail}
      onChange={(e) => {
        setBadEmail(e)
      }}
    />
  ), [badEmail])
  
  const renderHierarchy1 = useMemo(() => (
    <div style={{width:"200px"}}>
      <Input
        style={{ width: '200px' }}
        className="test-classname"
        domID="txt_renderheirarchy1"
        placeholder=""
        size="small"
        maxLength={20}
        initialValue={hierarchy1}
        onChange={(e) => {
          setHierarchy1(e.target.value || "")
        }}
      />
    </div>
  ), [hierarchy1])  
  
  const renderHierarchy2 = useMemo(() => (
    <div style={{width:"200px"}}>
      <Input
        style={{ width: '200px' }}
        className="test-classname"
        domID="txt_renderheirarchy2"
        placeholder=""
        size="small"
        maxLength={20}
        initialValue={hierarchy2}
        onChange={(e) => {
          setHierarchy2(e.target.value || "")
        }}
      />
    </div>
  ), [hierarchy2])  
  
  const renderHierarchy3 = useMemo(() => (
    <div style={{width:"200px"}}>
      <Input
        style={{ width: '200px' }}
        className="test-classname"
        domID="txt_renderheirarchy3"
        placeholder=""
        size="small"
        maxLength={20}
        initialValue={hierarchy3}
        onChange={(e) => {
          setHierarchy3(e.target.value || "")
        }}
      />
    </div>
  ), [hierarchy3])
  
  const renderHierarchy4 = useMemo(() => (
    <div style={{width:"200px"}}>
      <Input
        style={{ width: '200px' }}
        className="test-classname"
        domID="txt_renderheirarchy4"
        placeholder=""
        size="small"
        maxLength={20}
        initialValue={hierarchy4}
        onChange={(e) => {
          setHierarchy4(e.target.value || "")
        }}
      />
    </div>
  ), [hierarchy4])
  
  const renderHierarchy5 = useMemo(() => (
    <div style={{width:"200px"}}>
      <Input
        style={{ width: '200px' }}
        className="test-classname"
        domID="txt_renderheirarchy5"
        placeholder=""
        size="small"
        maxLength={20}
        initialValue={hierarchy5}
        onChange={(e) => {
          setHierarchy5(e.target.value || "")
        }}
      />
    </div>
  ), [hierarchy5])
  
  const renderHierarchy6 = useMemo(() => (
    <div style={{width:"200px"}}>
      <Input
        style={{ width: '200px' }}
        className="test-classname"
        domID="txt_renderheirarchy6"
        placeholder=""
        size="small"
        maxLength={20}
        initialValue={hierarchy6}
        onChange={(e) => {
          setHierarchy6(e.target.value || "")
        }}
      />
    </div>
  ), [hierarchy6])

  const validateForm = useMemo(() => {
    if (!name || !validateEmail() || !IdNumber){
      return false;
    }
    return true
  }, [IdNumber, name, emailAddress])

  const disabledDiactivateButton = useMemo(() => {
    if (editorMode === "Edit") {
      if (!records?.CEEOBRULE) {
        return true;
      }
      return false;
    }
    return true;
  }, [editorMode, records])
  const renderDeliveryAddressForm = useMemo(() => {
    if (detailAnimationState > detailPaneAnimStates.COLLAPSED) {
      return (
        <div style={{ marginLeft: '40px' }} className="marginLeft">
          <div>
            <p>
              <div className="displayFlex">Complete the information below and then click Submit to continue.</div>
            </p>
            <p>
              <div className="displayFlex"><span style={{ color: 'red' }}>* indicates required information</span></div>
            </p>
          </div>
          <br />
          <div className="displayFlex">
            <table className="common-table">
              <tr>
                <td>
                  <span> eEOB Rule ID: <span style={{ color: 'red' }}>*</span></span>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderEEOBID}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <span> ID Number: <span style={{ color: 'red' }}>*</span></span>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderIdNumber}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <span> Name: <span style={{ color: 'red' }}>*</span></span>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderName}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <span> Email Address: <span style={{ color: 'red' }}>*</span></span>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderEmailAddress}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <span> Suppress: </span>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderSuppress}
                  </div>
                </td>
              </tr>
              {editorMode === 'Edit' ?
                <>
                  <tr><p></p></tr>
                  <tr>
                    <td>
                      <span> Bad Email: </span>
                    </td>
                    <td>
                      <div className="marginLeft" style={{ width: '250px' }}>
                        {renderBadEmail}
                      </div>
                    </td>
                  </tr>
                </>:''}
              <tr><p></p></tr>
              <tr>
                <td>
                  <span> Hierarchy 1: </span>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderHierarchy1}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <span> Hierarchy 2: </span>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderHierarchy2}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <span> Hierarchy 3: </span>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderHierarchy3}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <span> Hierarchy 4: </span>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '360px' }}>
                    {renderHierarchy4}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <span> Hierarchy 5: </span>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '400px' }}>
                    {renderHierarchy5}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <span> Hierarchy 6: </span>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '400px' }}>
                    {renderHierarchy6}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td colSpan="2">
                  <div className="displayFlex">
                    {/* <div className="marginLeft">
                      <Button
                        domID="btn_copy"
                        buttonType="standard"
                        size="small"
                        name="COPY"
                        disabled={editorMode === "Add"}
                        onClick={() => {
                          onCopyCurrentRecordValues()
                          setEditorMode('Add')
                        }}
                      />
                    </div>
                    <div className="marginLeft">
                      <Button
                        domID="btn_reset"
                        buttonType="standard"
                        size="small"
                        name="RESET"
                        disabled={editorMode === "Add"}
                        onClick={() => {
                          onReset()
                        }}
                      />
                    </div> */}
                    <div className="marginLeft">
                      <Button
                        domID="btn_diactivate"
                        buttonType="standard"
                        size="small"
                        name={isActive ? "DEACTIVATE" : 'ACTIVATE'}
                        disabled={disabledDiactivateButton}
                        onClick={() => onDeactivateClick()}
                      />
                    </div>
                    <div className="marginLeft">
                      <Button
                        domID="btn_search"
                        buttonType="standard"
                        size="small"
                        name="SUBMIT"
                        disabled={!validateForm}
                        onClick={() => onSubmitClick()}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
            </table>
          </div>

          <Modal isOpen={isOpenConfirmationModal} wrapperComponent={StyledModalWrapperWidth}>
            <ModalHeader
              title={`Do you want to ${confirmationModalMode} ?`}
              onClose={() => setisOpenConfirmationModal(false)}
            />
            <ModalBody>
              {isOpenConfirmationModal ? (
                <div style={{ marginLeft: '34px', marginBottom: '20px' }} className="marginLeft">
                  <div className="displayFlex">
                    <div className="marginLeft">
                      <Button
                        domID="btn_search"
                        buttonType="standard"
                        size="medium"
                        name="Yes"
                        onClick={() => onConfirmationYesClick()}
                      />
                    </div>
                    <div className="marginLeft">
                      <Button
                        domID="btn_search"
                        buttonType="standard"
                        size="medium"
                        name="No"
                        onClick={() => setisOpenConfirmationModal(false)}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
            </ModalBody>
          </Modal>
        </div>
      )
    }
    return null;
  }, [detailAnimationState,validateForm, editorMode, records,
    isOpenConfirmationModal, confirmationModalMode,eEOBID, name, suppress,
    emailAddress, badEmail, hierarchy1, hierarchy2, hierarchy3,
    hierarchy4, hierarchy5, hierarchy6, infoSelectedEmail])

  return (
    <>
      <DetailPaneHeader
        title={editorMode === "Edit" ? "Modify Client Email" : "Add Client Email"}
        layoutControls={[
          expandCollapseDetailPaneButtonConfig,
          closeDetailPaneButtonConfig
        ]}
      />
      <>
        {isLoadingAddEmail || isLoadingGetSelected
          || isLoadingDeactivateEmail || isLoadingActivateEmail || isLoadingModifyEmail ? (
            // replace with a better loading component
            <div style={{ marginLeft: '50%' }}><LoadingComponent /></div>
          ) : isErrorAddEmail || isErrorGetSelected
            || isErrorDeactivateEmail || isErrorActivateEmail || isErrorModifyEmail ? (
              // replace with a better error component
              <ErrorPage
                // this prop isn't real but is an example
                error={errorAddEmail || errorGetSelected 
                  || errorDeactivateEmail || errorActivateEmail || errorModifyEmail}
              />
            ) : (
              <>
                {renderDeliveryAddressForm}
              </>
            )
        } 
      </>
    </>
  )
};

export default ClientEmailDetail