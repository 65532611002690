import groupsListSaga from './api/groupsList/sagas';
import { groupsPutSaga, groupsPostSaga } from './api/groups/sagas';
import { ruleTypesListSaga, ruleDetailsSaga } from './api/ruleTypesList/sagas';
import rulesForRuleTypesList from './api/rulesForRuleTypesList/sagas';
import rulesForRuleTypesSaga from './api/rulesForRuleTypes/sagas';
import commitRuleTypesList from './api/commitRuleTypesList/sagas';
import liveAssocRuleTypesList from './api/liveAssocRuleTypesList/sagas';
import ruleDetailElementsSaga from './api/ruleDetails/sagas';

export default [
  groupsListSaga,
  groupsPutSaga,
  groupsPostSaga,
  ruleTypesListSaga,
  ruleDetailsSaga,
  rulesForRuleTypesList,
  rulesForRuleTypesSaga,
  commitRuleTypesList,
  liveAssocRuleTypesList,
  ruleDetailElementsSaga,
];
