import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { LoadingIndicator, Button } from 'ui-core-ssgr';
import {
  ContainerBox,
  DollarLabel,
  DollarLabelReadOnly,
  BlankSignature,
  BlankSignatureTitle,
  BlankSignatureWrap,
  BlankSignatureContainer,
  ReadOnlyContainerBox
} from './styles';
import { LoadingWrapper } from '../../RulesTabView/components/RuleDetails/RuleDetailsGridWrapper/styles';
import { 
  ButtonGroupBottom,
  ModalButton,
  ReadOnlyTextTitle,
  ReadOnlyTextArea
} from '../../../shared/styles/styledComponents';
import DropDownControl from '../ModalControls/DropDownControl/index';
import InputControl from '../ModalControls/InputControl';

class HighDollarReviewModal extends Component {
  state = {
    claimType: 'default',
    recipientType: 'default',
    documentType: 'default',
    deliveryMethod: 'none',
    deliveryAddress: 'default',
    isButtonDisabled: true,
    blankSignature: false,
    highDollarThreshold: undefined,
    highDollarThresholdError: false,
    highDollarThresholdErrorMessage: '',
    deliveryAddressError: false,
    deliveryAddressErrorMessage: '',
    deliveryMethodError: false,
    deliveryMethodErrorMessage: '',
  };

  componentDidMount() {
    this.getInitialValues();
  }

  componentDidUpdate(prevProps) {
    const { detailElements, isShowingHighDollarReviewModal } = this.props;
    if (
      prevProps.detailElements !== detailElements ||
      prevProps.isShowingHighDollarReviewModal !==
        isShowingHighDollarReviewModal
    ) {
      this.getInitialValues();
    }
  }

  populateCodeFromValue = name => {
    const { detailElements, selectedRuleDetail } = this.props;
    if (!_.isEmpty(detailElements)) {
      if (name && detailElements.claimTypesList) {
        let selectedCode = '';
        let selectedList = '';
        let firstValue = '';
        let selectedName = name.replace('List', '');
        if (selectedName.indexOf('Types')) {
          selectedName = selectedName.replace('Types', 'Type');
        }
        const selectedValue = selectedRuleDetail[selectedName];
        if (name === 'claimTypesList') selectedList = 'claimTypesList';
        if (name === 'recipientTypesList') selectedList = 'recipientTypesList';
        if (name === 'documentTypesList') selectedList = 'documentTypesList';
        if (name === 'deliveryMethodList') selectedList = 'deliveryMethodList';
        if (name === 'deliveryAddressList')
          selectedList = 'deliveryAddressList';

        if (
          selectedList === 'deliveryMethodList' &&
          (selectedValue === undefined || selectedValue === '')
        ) {
          firstValue = detailElements[selectedList].find(
            x => x.code === 'default',
          );
        } else {
          firstValue = detailElements[selectedList].find(x =>
            x.value.includes(selectedValue),
          );
        }

        const arr = firstValue
          ? [...new Set([firstValue, ...detailElements[selectedList]])]
          : detailElements[selectedList];
        arr.map(r => {
          if (
            selectedList === 'deliveryMethodList' &&
            (selectedValue === undefined || selectedValue === '')
          ) {
            selectedCode = 'default';
          } else if (r.value === selectedValue) {
            selectedCode = r.code;
          }

          return {
            label: r.value,
            value: r.code,
          };
        });
        return selectedCode;
      }
    }
  };

  getInitialValues = () => {
    const { detailElements, selectedRuleDetail, actionType } = this.props;
    let claimType = 'default';
    let recipientType = 'default';
    let documentType = 'default';
    let deliveryMethod = 'none';
    let deliveryAddress = 'default';
    let highDollarThreshold = '';
    let blankSignature = false;

    if (
      !_.isEmpty(detailElements) &&
      !_.isEmpty(selectedRuleDetail) &&
      (actionType !== 'Add a')
    ) {
      if (actionType === 'Edit a' || actionType === 'Clone a') {
        if (detailElements.claimTypesList && selectedRuleDetail.claimType) {
          claimType = detailElements.claimTypesList.find(
            o => o.value === selectedRuleDetail.claimType,
          ).code;
        }
        if (
          detailElements.recipientTypesList &&
          selectedRuleDetail.recipientType
        ) {
          recipientType = detailElements.recipientTypesList.find(
            o => o.value === selectedRuleDetail.recipientType,
          ).code;
        }
        if (
          detailElements.documentTypesList &&
          selectedRuleDetail.documentType !== 'Any'
        ) {
          documentType = detailElements.documentTypesList.find(
            o => o.value === selectedRuleDetail.documentType,
          ).code;
        } else {
          documentType = 'default';
        }
        if (selectedRuleDetail.highDollarThreshold !== null && selectedRuleDetail.highDollarThreshold !== undefined) {
          let toNumber = Number(selectedRuleDetail.highDollarThreshold);
          toNumber = toNumber.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          highDollarThreshold = String(toNumber);
        }
        if (selectedRuleDetail.blankSignature) {
          const { blankSignature: ruleDetailBlankSignature } = selectedRuleDetail;
          blankSignature = ruleDetailBlankSignature;
        }
        if (
          detailElements.deliveryMethodList &&
          selectedRuleDetail.deliveryMethod !== undefined
        ) {
          deliveryMethod = detailElements.deliveryMethodList.find(o =>
            o.value.includes(selectedRuleDetail.deliveryMethod),
          ).code;
        } else {
          deliveryMethod = 'default';
        }
        if (
          detailElements.deliveryAddressList &&
          selectedRuleDetail.deliveryAddress
        ) {
          deliveryAddress = detailElements.deliveryAddressList.find(o =>
            o.value.includes(selectedRuleDetail.deliveryAddress),
          ).code;
        }
      }
      if (actionType === 'Read only') {
        if (detailElements.claimTypesList) {
          claimType = detailElements.claimTypesList.find(
            o => o.value === selectedRuleDetail.claimType,
          ).value;
        }
        if (detailElements.recipientTypesList) {
          recipientType = detailElements.recipientTypesList.find(
            o => o.value === selectedRuleDetail.recipientType,
          ).value;
        }
        if (detailElements.documentTypesList) {
          documentType = detailElements.documentTypesList.find(
            o => o.value === selectedRuleDetail.documentType,
          ).value;
        }
        if (
          detailElements.deliveryMethodList &&
          selectedRuleDetail.deliveryMethod !== undefined
        ) {
          deliveryMethod = detailElements.deliveryMethodList.find(o =>
            o.value.includes(selectedRuleDetail.deliveryMethod),
          ).value;
        } else {
          deliveryMethod = 'default';
        }
        if (
          detailElements.deliveryAddressList &&
          selectedRuleDetail.deliveryAddress
        ) {
          deliveryAddress = detailElements.deliveryAddressList.find(o =>
            o.value.includes(selectedRuleDetail.deliveryAddress),
          ).value;
        }
      }
      if (selectedRuleDetail.highDollarThreshold !== null && selectedRuleDetail.highDollarThreshold !== undefined) {
        let toNumber = Number(selectedRuleDetail.highDollarThreshold);
        toNumber = toNumber.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        highDollarThreshold = String(toNumber);
      }
      if (selectedRuleDetail.blankSignature) {
        const { blankSignature: ruleDetailBlankSignature } = selectedRuleDetail;
        blankSignature = ruleDetailBlankSignature;
      }
      this.setState(
        {
          claimType,
          recipientType,
          documentType,
          blankSignature,
          highDollarThreshold,
          highDollarThresholdError: false,
          highDollarThresholdErrorMessage: '',
          deliveryMethod,
          deliveryAddress,
          isButtonDisabled: true,
        },
        () => {
          // to validate existing value from db
          this.validateFormInputs('highDollarThreshold');
          this.validateFormInputs('deliveryAddress');
          this.validateFormInputs('deliveryMethod');
        },
      );
    } else {
      this.setState(
        {
          claimType,
          recipientType,
          documentType,
          blankSignature: false,
          highDollarThreshold,
          highDollarThresholdError: false,
          highDollarThresholdErrorMessage: '',
          deliveryMethod,
          deliveryAddress,
          isButtonDisabled: true,
        },
        () => {
          // to validate existing value from db
          this.validateFormInputs('highDollarThreshold');
          this.validateFormInputs('deliveryAddress');
          this.validateFormInputs('deliveryMethod');
        },
      );
    }
  };

  getRuleVersion = record => {
    try {
      const { status, version } = record;
      const testVersion = version[status.indexOf('T')];
      if (testVersion) return testVersion;
      const liveVersion = version[status.indexOf('L')];
      return liveVersion;
    } catch (e) {
      console.log(e);
    }
    return null;
  };

  populateDataDropDown = name => {
    const { detailElements, selectedRuleDetail } = this.props;
    if (!_.isEmpty(detailElements)) {
      if (detailElements) {
        let options = [];
        let selectedList = '';
        const selectedValue = selectedRuleDetail[name];

        if (name === 'claimTypesList') selectedList = 'claimTypesList';
        if (name === 'recipientTypesList') selectedList = 'recipientTypesList';
        if (name === 'documentTypesList') selectedList = 'documentTypesList';
        if (name === 'deliveryMethodList') selectedList = 'deliveryMethodList';
        if (name === 'deliveryAddressList')
          selectedList = 'deliveryAddressList';

        const firstValue = detailElements[selectedList].find(x =>
          x.value.includes(selectedValue),
        );
        const arr = firstValue
          ? [...new Set([firstValue, ...detailElements[selectedList]])]
          : detailElements[selectedList];
        options = arr.map(r => ({
          label: r.value,
          value: r.code,
        }))
          .filter(d => d.value !== '');
        return options;
      }
    }
  };

  onCancel = () => {
    const {
      setIsCloningRuleDetail,
      setIsPostingRuleDetail,
      setShowingHighDollarReviewModal,
      setIsReadOnlyRuleDetail
    } = this.props;
    setIsCloningRuleDetail(false);
    setIsPostingRuleDetail(false);
    setShowingHighDollarReviewModal(false);
    setIsReadOnlyRuleDetail(false);
  };

  onSave = () => {
    const {
      isCloningRuleDetail,
      isPostingRuleDetail,
      postRuleDetails,
      putRuleDetails,
      selectedRuleDetail,
      selectedRuleRecord,
      selectedRuleTypeId,
      setIsCloningRuleDetail,
      setIsPostingRuleDetail,
      selectedRecord,
    } = this.props;
    const {
      claimType,
      recipientType,
      documentType,
      deliveryMethod,
      deliveryAddress,
      highDollarThreshold,
      blankSignature,
    } = this.state;

    const record = selectedRuleRecord || selectedRecord;
    const ruleDetailId = selectedRuleDetail.id;
    const ruleId = record.id;

    const ruleVersion = this.getRuleVersion(record);
    const ruleType = selectedRuleTypeId;

    let blankSignatureCust = null;

    if (typeof blankSignature === 'string' && blankSignature === 'true') {
      blankSignatureCust = true;
    }
    if (typeof blankSignature === 'string' && blankSignature === 'false') {
      blankSignatureCust = false;
    }
    if (typeof blankSignature !== 'string') {
      blankSignatureCust = blankSignature;
    }

    let ruleDetail = {
      claimType,
      recipientType,
      documentType,
      blankSignature: blankSignatureCust,
      deliveryMethod,
      deliveryAddress,
      highDollarThreshold: highDollarThreshold.replace(/,/g, ''),
    };
    ruleDetail = isCloningRuleDetail
      ? { ...ruleDetail, ...{ ruleDetailIdFrom: ruleDetailId } }
      : ruleDetail;

    if (isPostingRuleDetail || isCloningRuleDetail) {
      postRuleDetails({ ruleDetail, ruleType, ruleId, ruleVersion });
      setIsCloningRuleDetail(false);
      setIsPostingRuleDetail(false);
    } else {
      putRuleDetails({
        ruleDetail,
        ruleType,
        ruleId,
        ruleVersion,
        ruleDetailId,
      });
    }
  };

  setButtonDisableOnDefault(dbCodes) {
    const { actionType } = this.props;
    const {
      claimType,
      recipientType,
      documentType,
      deliveryMethod,
      deliveryAddress,
      highDollarThreshold,
      blankSignature,
    } = this.state;
    let newState = false;
    if (
      (actionType === 'Edit a' &&
      (claimType === dbCodes.claimTypeDbCode &&
      recipientType === dbCodes.recipientTypeDbCode &&
      documentType === dbCodes.documentTypeDbCode &&
      deliveryMethod === dbCodes.deliveryMethodDbCode &&
      deliveryAddress === dbCodes.deliveryAddressDbCode &&
      highDollarThreshold === dbCodes.highDollarThresholdDbCode &&
      String(blankSignature) === String(dbCodes.blankSignatureDbCode))) ||
      (actionType === 'Clone a' &&
        (claimType === dbCodes.claimTypeDbCode &&
          recipientType === dbCodes.recipientTypeDbCode &&
          documentType === dbCodes.documentTypeDbCode && 
          highDollarThreshold === dbCodes.highDollarThresholdDbCode))
    ) {
      newState = true;
    }
    if (
      actionType === 'Add a' &&
      (deliveryMethod === 'none' ||
        deliveryAddress === 'default' ||
        highDollarThreshold === null)
    ) {
      newState = true;
    }
    this.setState({ isButtonDisabled: newState });
  }

  formatCurrency = event => {
    const {validateFormInputs} = this;
    const inputValueOrignal = event.target.value.replace(/ /g, '');
    if(inputValueOrignal !== null && inputValueOrignal !== undefined && inputValueOrignal !== ''){
      let inputValue = inputValueOrignal.replace(/,|^0+/g, '');
      inputValue = inputValueOrignal.includes('.') && inputValue.split(".")[1].length === 0 && inputValue.split(".")[0].length !== 0? `${inputValue}0` : inputValueOrignal;  // to add 0 in back
      inputValue = inputValueOrignal.includes('.') && inputValue.split(".")[0].length === 0 ?  `0${inputValue}` : inputValue; // to add 0 in front
      inputValue = inputValue.split(".")[0].length === 0 ? this.toFixedTrunc(inputValue, 2) : inputValue;
      if(inputValue.split(".")[0].length > 1) inputValue = inputValue.replace(/^0+/, '');
      inputValue = this.toFixedTrunc(inputValue, 2);
      const toNumber = inputValue
        .replace(/,/g, '')
        .split('')
        .reverse()
        .join('')
        .replace(/(\d{3})/g, '$1,')
        .replace(/,$/, '')
        .split('')
        .reverse()
        .join('');
      this.setState({
        highDollarThreshold: toNumber
      }, () => { validateFormInputs('highDollarThreshold'); });
    }
  };

  toFixedTrunc(x, n) {
    const v = (typeof x === 'string' ? x : x.toString()).split('.');
    if (n <= 0) return v[0];
    let f = v[1] || '';
    if (f.length > n) return `${v[0]}.${f.substr(0, n)}`;
    while (f.length < n) f += '0';
    return `${v[0]}.${f}`;
  }

  checkUnsavedChanges = event => {
    const { selectedRuleDetail } = this.props;
    const { populateCodeFromValue } = this;
    const claimTypeDbCode = populateCodeFromValue('claimTypesList');
    const recipientTypeDbCode = populateCodeFromValue('recipientTypesList');
    const documentTypeDbCode = populateCodeFromValue('documentTypesList');
    const deliveryMethodDbCode = populateCodeFromValue('deliveryMethodList');
    const deliveryAddressDbCode = populateCodeFromValue('deliveryAddressList');
    let toNumber = Number(selectedRuleDetail.highDollarThreshold);
    toNumber = toNumber.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
    const highDollarThresholdDbCode = toNumber;
    const blankSignatureDbCode = selectedRuleDetail.blankSignature;

    const dbCodes = {
      claimTypeDbCode,
      recipientTypeDbCode,
      documentTypeDbCode,
      deliveryMethodDbCode,
      deliveryAddressDbCode,
      highDollarThresholdDbCode,
      blankSignatureDbCode,
    };
    if (event.target) {
      const { name, value } = event.target;
      this.setState(
        {
          [name]: value,
        },
        () => {
          this.validateFormInputs('deliveryAddress');
          this.validateFormInputs('deliveryMethod');
          this.validateFormInputs('highDollarThreshold');
          this.setButtonDisableOnDefault(dbCodes);
        },
      );
    }
  };

  validateFormInputs = input => {
    const { deliveryAddress, deliveryMethod, highDollarThreshold } = this.state;
    if (input === 'deliveryAddress') {
      const deliveryAddressError =
        deliveryAddress === 'default' || deliveryAddress === undefined;
      this.setState({
        deliveryAddressError,
        deliveryAddressErrorMessage: deliveryAddressError
          ? 'You must select a delivery address'
          : '',
      });
    }
    if (input === 'deliveryMethod') {
      const deliveryMethodError =
        deliveryMethod === 'none' || deliveryMethod === undefined;
      this.setState({
        deliveryMethodError,
        deliveryMethodErrorMessage: deliveryMethodError
          ? 'You must select a delivery method'
          : '',
      });
    }
    if (input === 'highDollarThreshold') {
      let { highDollarThresholdError } = this.state;
      const regexNumeric = /^[+-]?((\d+(\.\d*)?)|(\.\d+))$/;

      if (
        highDollarThreshold !== undefined &&
        highDollarThreshold !== null &&
        regexNumeric.test(highDollarThreshold.replace(/,/g, '')) &&
        (highDollarThreshold.substr(highDollarThreshold.indexOf('.') + 1)
          .length <= 2 ||
          highDollarThreshold.indexOf('.') < 1)
      ) {
        const regExpDecimal = /^\d?[0-9]{0,14}(\.\d?[0-9]{1,2})?$/;
        const toNumber = Number(
          parseFloat(highDollarThreshold.replace(/,/g, '')),
        );
        highDollarThresholdError =
          input === 'highDollarThreshold' && input !== undefined
            ? !regExpDecimal.test(toNumber)
            : highDollarThresholdError;
      } else {
        highDollarThresholdError = true;
      }
      this.setState({
        highDollarThresholdError,
        highDollarThresholdErrorMessage: highDollarThresholdError
          ? 'Please enter valid numeric value only. Up to 15 digits max and 2 numeric digit allowed after decimal.'
          : '',
      });
    }
  };

  render() {
    const { checkUnsavedChanges, formatCurrency } = this;
    const {
      claimType,
      recipientType,
      documentType,
      deliveryMethod,
      deliveryAddress,
      isButtonDisabled,
      deliveryAddressError,
      deliveryAddressErrorMessage,
      blankSignature,
      highDollarThreshold,
      highDollarThresholdError,
      highDollarThresholdErrorMessage,
      deliveryMethodError,
      deliveryMethodErrorMessage,
    } = this.state;
    const { 
      loading, 
      selectedRuleRecord, 
      selectedRecord,
      actionType
    } = this.props;
    const recordId = selectedRuleRecord
      ? selectedRuleRecord.id
      : selectedRecord.id;
    const claimTypeOptions = this.populateDataDropDown('claimTypesList');
    const recipientTypeOptions = this.populateDataDropDown(
      'recipientTypesList',
    );
    const documentTypeOptions = this.populateDataDropDown('documentTypesList');
    const deliveryMethodListOptions = this.populateDataDropDown(
      'deliveryMethodList',
    );
    const deliveryAddressListOptions = this.populateDataDropDown(
      'deliveryAddressList',
    );

    if (deliveryAddressListOptions !== undefined) {
      const selectOption = { label: 'Select', value: 'default' };
      deliveryAddressListOptions.unshift(selectOption);
    }
    if (deliveryMethodListOptions !== undefined) {
      const selectOption = { label: 'Select', value: 'none' };
      deliveryMethodListOptions.unshift(selectOption);
    }

    const blankSignatureCust = String(blankSignature);

    // FOR READ ONLY CONDITIONAL RENDERING (Live Rule)
    let claimTypeReadOnly;
    let recipientTypeReadOnly;
    let documentTypeReadOnly;
    let highDollarThresholdReadOnly;
    let deliveryMethodReadOnly;
    let deliveryAddressReadOnly;
    if (actionType === 'Read only') {
      claimTypeReadOnly = (claimType !== 'default') ? claimType : 'Any';
      recipientTypeReadOnly = (recipientType !== 'default') ? recipientType : 'Any';
      documentTypeReadOnly = (documentType !== 'default') ? documentType : 'Any';
      highDollarThresholdReadOnly = (highDollarThreshold !== 'default') ? highDollarThreshold : '-';
      deliveryMethodReadOnly = (deliveryMethod !== 'default') ? deliveryMethod : 'Originally Specified Delivery Type';
      deliveryAddressReadOnly = (deliveryAddress !== 'default') ? deliveryAddress : '-';
    }

    return (
      <>
        {actionType !== 'Read only' ? (
          <ContainerBox>
            <DropDownControl
              dropDownDomID="high-dollar-review-claim-type"
              dropDownLabel="claim type"
              dropDownName="claimType"
              dropDownOptions={claimTypeOptions}
              dropDownInitialValue={claimType}
              dropDownOnChangeHandle={e => checkUnsavedChanges(e)}
            />
            <DropDownControl
              dropDownDomID="high-dollar-review-recipient-type"
              dropDownLabel="recipient"
              dropDownName="recipientType"
              dropDownOptions={recipientTypeOptions}
              dropDownInitialValue={recipientType}
              dropDownOnChangeHandle={e => checkUnsavedChanges(e)}
            />
            <DropDownControl
              dropDownDomID="high-dollar-review-document-type"
              dropDownLabel="document type"
              dropDownName="documentType"
              dropDownOptions={documentTypeOptions}
              dropDownInitialValue={documentType}
              dropDownOnChangeHandle={e => checkUnsavedChanges(e)}
            />
          </ContainerBox>
        ) : (
          <ReadOnlyContainerBox>
            <ReadOnlyTextTitle>
              Claim Type
              <ReadOnlyTextArea>{claimTypeReadOnly}</ReadOnlyTextArea>
            </ReadOnlyTextTitle>
            <ReadOnlyTextTitle>
              Recipient
              <ReadOnlyTextArea>{recipientTypeReadOnly}</ReadOnlyTextArea>
            </ReadOnlyTextTitle>
            <ReadOnlyTextTitle>
              Document Type
              <ReadOnlyTextArea>{documentTypeReadOnly}</ReadOnlyTextArea>
            </ReadOnlyTextTitle>
          </ReadOnlyContainerBox>
        )}

        {actionType !== 'Read only' ? (
          <ContainerBox>
            <div>
              <DollarLabel>$</DollarLabel>
              <InputControl
                inputDomID="high-dollar-review-high-dollar-threshold"
                inputLabel="high dollar threshold"
                inputName="highDollarThreshold"
                inputInitialValue={highDollarThreshold}
                inputStartWithBlankValue={false}
                inputOnChangeHandle={e => checkUnsavedChanges(e)}
                inputDisabled={false}
                inputIsHovered={false}
                inputErrorMessage={highDollarThresholdErrorMessage}
                inputHasError={highDollarThresholdError}
                inputStyles={{ width: '250px' }}
                inputOnBlurHandle={e => formatCurrency(e)}
                inputOnKeyDown={e => (e.key === 'Enter' ? formatCurrency(e) : '')}
              />
            </div>
          </ContainerBox>
        ) : (
          <ReadOnlyContainerBox>
            <ReadOnlyTextTitle>
              High Dollar Threshold
              <ReadOnlyTextArea>
                <DollarLabelReadOnly>$ {String(highDollarThresholdReadOnly)}</DollarLabelReadOnly>
              </ReadOnlyTextArea>
            </ReadOnlyTextTitle>
          </ReadOnlyContainerBox>
        )}

        {actionType !== 'Read only' ? (
          <ContainerBox>
            <DropDownControl
              dropDownDomID="high-dollar-review-delivery-method"
              dropDownLabel="delivery method"
              dropDownName="deliveryMethod"
              dropDownOptions={deliveryMethodListOptions}
              dropDownInitialValue={deliveryMethod}
              dropDownOnChangeHandle={e => checkUnsavedChanges(e)}
              dropDownStyles={{ width: '400px' }}
              dropDownHasError={deliveryMethodError}
              dropDownErrorMessage={deliveryMethodErrorMessage}
            />
          </ContainerBox>
        ) : (
          <ReadOnlyContainerBox>
            <ReadOnlyTextTitle>
              Delivery Method
              <ReadOnlyTextArea>{deliveryMethodReadOnly}</ReadOnlyTextArea>
            </ReadOnlyTextTitle>
          </ReadOnlyContainerBox>
        )}

        {actionType !== 'Read only' ? (
          <ContainerBox>
            <DropDownControl
              dropDownDomID="high-dollar-review-delivery-address"
              dropDownLabel="delivery address"
              dropDownName="deliveryAddress"
              dropDownOptions={deliveryAddressListOptions}
              dropDownInitialValue={deliveryAddress}
              dropDownOnChangeHandle={e => checkUnsavedChanges(e)}
              dropDownStyles={{ width: '400px' }}
              dropDownHasError={deliveryAddressError}
              dropDownErrorMessage={deliveryAddressErrorMessage}
            />
          </ContainerBox>
        ) : (
          <ReadOnlyContainerBox>
            <ReadOnlyTextTitle>
              Delivery Address
              <ReadOnlyTextArea>{deliveryAddressReadOnly}</ReadOnlyTextArea>
            </ReadOnlyTextTitle>
          </ReadOnlyContainerBox>
        )}

        <BlankSignatureContainer>
          <BlankSignatureTitle>Blank Signature</BlankSignatureTitle>
          <BlankSignatureWrap>
            <BlankSignature>
              <input
                type="radio"
                id="high-dollar-review-delivery-address-false"
                name="blankSignature"
                value="false"
                checked={blankSignatureCust === 'false'}
                onClick={e => checkUnsavedChanges(e)}
                onChange={e => checkUnsavedChanges(e)}
                disabled={actionType === 'Read only'}
              />{' '}
              No
            </BlankSignature>
            <BlankSignature>
              <input
                type="radio"
                id="high-dollar-review-delivery-address-true"
                name="blankSignature"
                value="true"
                checked={blankSignatureCust === 'true'}
                onClick={e => checkUnsavedChanges(e)}
                onChange={e => checkUnsavedChanges(e)}
                disabled={actionType === 'Read only'}
              />{' '}
              Yes
            </BlankSignature>
          </BlankSignatureWrap>
        </BlankSignatureContainer>
        <ButtonGroupBottom>
          <ModalButton>
            {loading.has(recordId) && (
              <LoadingWrapper>
                <LoadingIndicator
                  domID="basic-details-form-loading-indicator"
                  length="30px"
                />
              </LoadingWrapper>
            )}
            {actionType !== 'Read only' ? (
              <Button
                onClick={() => this.onSave()}
                domID="automation-id"
                name="Save"
                buttonType="emphasized"
                size="medium"
                isDropdown={false}
                type="button"
                disabled={
                  isButtonDisabled ||
                  loading.has(recordId) ||
                  deliveryAddressError ||
                  highDollarThresholdError
                }
              />
            ) : null }
            <Button
              domID="automation-id"
              name={actionType !== 'Read only' ? 'Cancel' : 'Close'}
              buttonType="diminished"
              disabled={loading.has(recordId)}
              size="medium"
              onClick={() => this.onCancel()}
              isDropdown={false}
              type="button"
            />
          </ModalButton>
        </ButtonGroupBottom>
      </>
    );
  }
}

HighDollarReviewModal.propTypes = {
  detailElements: PropTypes.object,
  loading: PropTypes.object,
  selectedRuleRecord: PropTypes.object,
  selectedRuleDetail: PropTypes.object,
  setIsCloningRuleDetail: PropTypes.func,
  setIsPostingRuleDetail: PropTypes.func,
  setShowingHighDollarReviewModal: PropTypes.func,
  isShowingHighDollarReviewModal: PropTypes.bool,
  isCloningRuleDetail: PropTypes.bool,
  isPostingRuleDetail: PropTypes.bool,
  postRuleDetails: PropTypes.func,
  putRuleDetails: PropTypes.func,
  actionType: PropTypes.string,
  selectedRuleTypeId: PropTypes.string,
  selectedRecord: PropTypes.object,
};
export default HighDollarReviewModal;
