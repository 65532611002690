import styled from 'styled-components';

export const NoDetailsWrapper = styled.div`
  padding: 1em 0;
  & > div {
  float: left;
  }
`;

export const IconWrapperNoDetails = styled.div`
  & > svg {
    margin-left: -.4em;
  }
`;

export const ScrollableGridWrapper = styled.div`
  max-height: 45vh;
  overflow: auto;
  & > div > table > tbody > tr > td {
    vertical-align: middle;
  }
`;

export const LoadingWrapper = styled.div`
  float: left;
  margin-left: -30px;
  margin-top: 3px;
`;

export const InputLabel = styled.div`
  margin: 0 0 .5em .7em;
  line-height: 1.4em;
  letter-spacing: 0.5px;
  text-align: left;
  font-size: 14px;
`;

export const WarningWrapper = styled.div`
  align-items: center;
  display: flex;
`;

export const CustomTableData = styled.td`
  &.trialed {
    color: #bf1616;
  }
`;

export const GridLoadingWrapper = styled.div`
  left: 48%;
  position: absolute;
`;


export const GridLoadingContainer = styled.div`
  margin-bottom: 100px;
  position: relative;
`;

export const ImageContentPaddingWrapper = styled.div`
  padding: 0.5rem 0.5rem 1.4rem 1.5rem;
  font-size: 14px;
`;

