
import { useEffect, useState } from 'react'
import { useAxiosPost } from '../hooks'

const useGetAssosicateReportsTable=()=>{
  const [EmailRecipients, setEmailRecipients]=useState(null)
  const [EmailRecipientsCount, setEmailRecipientsCount]=useState(0)
  const [Reports, setReports]=useState(null)
  const [ReportsCount, setReportsCount]=useState(0)
  const {postData, isLoading,isError,error, data }=useAxiosPost({
    url:'clientAdministration/clientInformation/getAssosicateReportsTable',
    method:'POST',
    onError: (e) => 
    // what happens on error? if you need something extra
    // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })

  useEffect(()=>{
    if (data){
      const {CLIENT_REPORT_INFO_RESPONSE}=data;
      if (CLIENT_REPORT_INFO_RESPONSE) {
        const {EmailRecipients: EmailRecipientsp, Reports: Reportsp}=CLIENT_REPORT_INFO_RESPONSE
        if (EmailRecipientsp && EmailRecipientsp.$ && EmailRecipientsp.Recipient) {
          const {$, Recipient}=EmailRecipientsp;
          const {cnt: cnt1}=$;
          let r=[];
          if (Recipient.constructor.name==="Array") {
            r=[...Recipient]
          } else if(Recipient.constructor.name==="Object"){
            r.push({...Recipient})
          }
          setEmailRecipients(JSON.parse(JSON.stringify(r)))
          setEmailRecipientsCount(cnt1||0)
        } else{
          setEmailRecipients([])
          setEmailRecipientsCount(0)
        }
        if (Reportsp && Reportsp.$ && Reportsp.Report) {
          const {$, Report}=Reportsp;
          const {cnt: cnt2}=$;
          let r=[];
          if (Report.constructor.name==="Array") {
            r=[...Report]
          } else if(Report.constructor.name==="Object"){
            r.push({...Report})
          }
          setReports(JSON.parse(JSON.stringify(r)))
          setReportsCount(cnt2||0)
        } else{
          setReports([])
          setReportsCount(0)
        }  
      } else{
        setEmailRecipients([])
        setEmailRecipientsCount(0)
        setReports([])
        setReportsCount(0)
      }
      
    } else{
      setEmailRecipients([])
      setEmailRecipientsCount(0)
      setReports([])
      setReportsCount(0)
    }
  },[data])
  
  return { isLoading, isError, error, postData, EmailRecipients, EmailRecipientsCount, Reports, ReportsCount, setReports};
}
const useGetAssosicateReportsClientContactTable=()=>{
  const [ClientContactsCanDeleteInfo, setClientContactsCanDeleteInfo]=useState(null)
  const [ClientContactsCanDeleteInfoCount, setClientContactsCanDeleteInfoCount]=useState(0)
  const [ClientContactsInfo, setClientContactsInfo]=useState(null)
  const [ClientContactsInfoCount, setClientContactsInfoCount]=useState(0)
  const {postData, isLoading,isError,error, data }=useAxiosPost({
    url:'clientAdministration/clientInformation/getAssosicateReportsClientContactTable',
    method:'POST',
    dispatchError: false,
    resetError: false,
    onError: (e) => 
      // what happens on error? if you need something extra
      // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })
  useEffect(()=>{
    if(data){
      const {CLIENT_CONTACT_INFO_RESPONSE}=data;
      if (CLIENT_CONTACT_INFO_RESPONSE) {
        const {CanDelete, Contacts}=CLIENT_CONTACT_INFO_RESPONSE
        if (CanDelete && CanDelete.$ && CanDelete.Contact) {
          const {$, Contact}=CanDelete;
          const {cnt: cnt1}=$;
          let c=[];
          if (Contact.constructor.name==="Array") {
            c=[...Contact]
          } else if(Contact.constructor.name==="Object"){
            c.push({...Contact})
          }
          setClientContactsCanDeleteInfo(JSON.parse(JSON.stringify(c)))
          setClientContactsCanDeleteInfoCount(cnt1||0)
        } else{
          setClientContactsCanDeleteInfo(null)
          setClientContactsCanDeleteInfoCount(0)
        }
        if (Contacts && Contacts.$ && Contacts.Contact) {
          const {$, Contact}=Contacts;
          const {cnt: cnt2}=$;
          let c=[];
          if (Contact.constructor.name==="Array") {
            c=[...Contact]
          } else if(Contact.constructor.name==="Object"){
            c.push({...Contact})
          }
          setClientContactsInfo(JSON.parse(JSON.stringify(c)))
          setClientContactsInfoCount(cnt2||0)
        } else{
          setClientContactsInfo([])
          setClientContactsInfoCount(0)
        }
      } else{
        setClientContactsCanDeleteInfo(null)
        setClientContactsCanDeleteInfoCount(0)
        setClientContactsInfo([])
        setClientContactsInfoCount(0)
      }
      
    } else{
      setClientContactsCanDeleteInfo(null)
      setClientContactsCanDeleteInfoCount(0)
      setClientContactsInfo([])
      setClientContactsInfoCount(0)
    }
  },[data])
    
  return { isLoading, isError, error, postData, ClientContactsCanDeleteInfo, ClientContactsCanDeleteInfoCount, ClientContactsInfo, ClientContactsInfoCount, setClientContactsInfo};
}

const useUpdateClientEmailRecipientData=()=>{
  const {postData, isLoading,isError,error, data }=useAxiosPost({
    url:'clientAdministration/clientInformation/updateClientEmailRecipientsData',
    method:'POST',
    dispatchError: false,
    onError: (e) => 
      // what happens on error? if you need something extra
      // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })
  return {postData, isLoading,isError,error, data}
}

export {
  useGetAssosicateReportsTable,
  useGetAssosicateReportsClientContactTable,
  useUpdateClientEmailRecipientData
}