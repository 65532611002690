import styled from 'styled-components';
export const LoadMoreButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  & button {
    display: inline-block;
    padding: 4px;
    margin: 10px;
  }
`;
export const InputLabelWrapper = styled.div`
  font-size: 14px;
  line-height: 1.4em;
  font-weight: 700;
  letter-spacing: 0.5px;
  display: inline-block;
`;
export const LoadingWrapper = styled.div`
  & > div {
    margin: 8% 0 8% 0;
  }
`;