/* eslint-disable no-nested-ternary */
/* eslint-disable no-useless-escape */
import React, { useState, useMemo,useCallback, useEffect } from 'react';
import styled from 'styled-components/dist/styled-components'
import {
  Grid,
  useLayout,
  ListPaneHeader,
  Button, Input, DatePickerV2
} from 'ui-core/dist'
import { useSubmitOnHoldReport } from '../../api/useOnHoldReport';
import LoadingComponent from '../../components/LoadingComponent';
import ErrorPage from '../ErrorPage/components/ErrorPage';
import useNotificationBannerHook from '../../useNotificationBannerHook';

const StyledContainer=styled.table`
width: 60%;
& tr td:first-child {
    width: 300px !important;
}
& tr td input {
    width: 370px !important;
}
`

const ON_HOLD_REPORT_SUBMITTED='On Hold Report Web Transaction {0} has been submitted.  If you have not received this report within six (6) hours, please contact client services via customer care hub (https://customercare.changehealthcare.com/public/home.html). If you do not have access to customer care hub, please contact your internal administrator.'

const OnHoldReport = () => {
  const {
    expandCollapseListPaneButtonConfig
  } = useLayout()
  const DEFAULT_NO_OF_DAYS=7;
  const DEFAULT_EMAIL_ADDRESS='';
  const {notify}=useNotificationBannerHook();
  const {isLoading, isError, error, postData, data: infoSubmitResponse, metaData: metadataSubmitResponse}=useSubmitOnHoldReport()

  useEffect(() => {
    if (metadataSubmitResponse) {
      const { status, statusText } = metadataSubmitResponse;
      if (status === 200 || statusText === "OK") {
        if (infoSubmitResponse && infoSubmitResponse.fs) {
          const { fs } = infoSubmitResponse;
          if (fs) {
            notify(fs, 'negative')
            return;
          }
        }
        const {resp}=infoSubmitResponse;
        const msg=ON_HOLD_REPORT_SUBMITTED.replace('{0}', resp||'');
        notify(msg, 'positive')
      }
    }
  }, [metadataSubmitResponse, infoSubmitResponse])


  const [noOfDays, setnoOfDays]=useState(DEFAULT_NO_OF_DAYS)
  const [email, setEmail] = useState(DEFAULT_EMAIL_ADDRESS);

  const validateEmail = useCallback(() => {
    if (!email) {
      return true;
    }
    return (/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(email) ? false : true);
  }, [email]);

  const validateNoOfDays = useCallback(() => {
    if (!noOfDays) {
      return true;
    }
    return (/^\d+$/.test(noOfDays) ? false : true);
  }, [noOfDays]);

  const onResetClick=useCallback(()=>{
    setnoOfDays(DEFAULT_NO_OF_DAYS)
    setEmail(DEFAULT_EMAIL_ADDRESS)
  },[email, noOfDays])
  
  const onSubmitClick=useCallback(()=>{
    postData({strNumberDays: noOfDays, strEmailAddress: email})
  },[email, noOfDays])

  const renderNumberOfDays = useMemo(() => (
    <Input
      dataTestId="onholdreport-numberofdays"
      domID="onholdreport-numberofdays"
      size="medium"
      errorMessage="Please enter a valid No of Days"
      hasError={validateNoOfDays()}
      initialValue={noOfDays}
      onChange={(e) => {
        setnoOfDays(e.target.value || "");

      }}
    />
  ), [noOfDays]);
  const renderEmailAdres = useMemo(() => (
    <Input
      dataTestId="onholdreport-email"
      domID="onholdreport-email"
      size="medium"
      errorMessage="Please enter a valid Email"
      hasError={validateEmail()}
      initialValue={email}
      onChange={(e) => {
        setEmail(e.target.value || "");

      }}
    />
  ), [email]);
  const validateForm = useMemo(() => {
    if (!email || !noOfDays) {
      return false;
    }
    return true;
  }, [email, noOfDays])

  return (
    <>
      <ListPaneHeader
        title="OnHoldReport"
        layoutControls={[expandCollapseListPaneButtonConfig]}
      />
      <>
        {isLoading ? (
        // replace with a better loading component            
          <div style={{ marginLeft: '50%' }}><LoadingComponent /></div>
        ) : isError ? (
        // replace with a better error component
          <ErrorPage
            // this prop isn't real but is an example
            error={error}
          />
        ) : (
          <div className="marginLeft">
            <StyledContainer>
              <tr>
                <td className=''><label>Number Of Days:</label></td>
                <td className=''>
                  {renderNumberOfDays}
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td className=''><label>Email Address for Delivery of Report:</label></td>
                <td className=''>
                  {renderEmailAdres}
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                </td>
                <td>
                  <div className='displayFlex'>
                    <Button
                      buttonType="standard"
                      size="small"
                      name="Reset"
                      onClick={onResetClick}
                    />
                    <div className='marginLeft'>
                      <Button
                        buttonType="standard"
                        size="small"
                        name="Submit"
                        disabled={!validateForm}
                        onClick={onSubmitClick}
                      /> 
                    </div>
                  </div>
                </td>
              </tr>
            </StyledContainer>
          </div>
        )
        }
      </>
    </>
  )
}

export default OnHoldReport;