import Api from '..';
import { API_BASE } from '../constants';

const getLiveAssocRuleTypesList = async (
  clientLayoutId,
  userSecurityTokenKey,
  username,
  groupId,
  roleId,
) => {
  try {
    const baseUrl = `${API_BASE}/groups/${clientLayoutId}/${groupId}/associations?sortColumn=ruleType&sortOrder=desc&associationStatus=L`;
    const api = new Api(userSecurityTokenKey, username, null, roleId);
    const getLiveAssocRuleTypesListResponse = await api.get(baseUrl);

    if (getLiveAssocRuleTypesListResponse.error) {
      throw getLiveAssocRuleTypesListResponse.error;
    }
    return getLiveAssocRuleTypesListResponse.associations;
  } catch (error) {
    throw error;
  }
};

export default getLiveAssocRuleTypesList;
