/* eslint-disable no-nested-ternary */
import React, { useEffect,useState } from 'react';
import { Modal, ModalBody } from 'ui-core/dist'
import { useSelector, useDispatch } from 'react-redux';
import ModalHeaderWrapper from '../../components/ErrorDisplaySection/ModalHeaderWrapper';
import { LoadingPage } from '../../components';
import './Modules/styles.scss';
import {useGetErrorDetail,useGetErrorDetailInfo, useGetErrorResolution} from '../../api/useErrorManager';
import { setDetailsInfoModal } from './Modules/Action';
const DetailsInfoModal = ()=>{
  const dispatch = useDispatch()
  const { detailsInfoModal, id, detailcuid } = useSelector(({ errorManager }) => errorManager)
  const errorDetailsPayload={
    "maxRecords":"10",
    "rowPosition":0,
    "sortField":"errorCuid",
    "viewInactive":false,
    "sortDescending":true,
    "parameters":"",
    "pstrErrorId":id
  };
  
  const payLoadToErrorDetail = {
    payload:errorDetailsPayload,
    detailcuid
  }
  const {getErrorDetail, isLoading, errorCodeRespD, descriptionrespD, severityrespD} = useGetErrorDetail(payLoadToErrorDetail)
  const { getErrorDetailInfo, resolutionInforesp,informationInforesp } = useGetErrorDetailInfo();
  const {getErrorResolution, resolutionresp} = useGetErrorResolution();
  const [resolutiondisp, setresolutiondisp] = useState('')
  useEffect(()=>{
    if (detailsInfoModal) {
      getErrorDetail(payLoadToErrorDetail.payload)
      getErrorDetailInfo({
        "pstrDetailCuid":detailcuid
      })
      getErrorResolution({
        "pstrErrorNum":id,
        "pstrSubErrorNum":detailcuid
      })  
    }
  },[detailsInfoModal])
 
  useEffect(() => {
    if ( resolutionInforesp.length === 0) {
      setresolutiondisp(resolutionresp);
    } else {
      setresolutiondisp(resolutionInforesp);
    }
  }, [resolutionInforesp,resolutionresp]);


  return (
    <Modal isOpen={detailsInfoModal}>
      <ModalHeaderWrapper
        title="Error Details"
        onClose={() => dispatch(setDetailsInfoModal(false))}
      />
      <ModalBody>
        {isLoading ? (
        // replace with a better loading component
          <LoadingPage />
        ) : (
          <div className="mainWrapper detail-info-modal">
            <div className="displayFlex--modal">
              <b> Error Code : </b>
              {errorCodeRespD}
            </div>
            <div className="displayFlex--modal">
              <b> Severity : </b>
              {severityrespD}
            </div>
            <div className="displayFlex--modal">
              <b> Error Description : </b>
              {descriptionrespD}
            </div>
            <div className="displayFlex--modal">
              <b> Resolution : </b>
              {resolutiondisp}
            </div>
            <div className="displayFlex--modal">
              <b> Error Information : </b>
              {informationInforesp}
            </div>
          </div>
        )}
      </ModalBody>
    </Modal>
  )
}

export default DetailsInfoModal