import React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import WelcomeKitHome from '../containers/WelcomeKit';
import WelcomeKitDocPage from '../containers/WelcomeKit/WelcomeKitDocHome';
import Authorizer from '../containers/Authorizer';

const WelcomeKitRoutes = () => (
  <Switch>
    <Authorizer permission="CAN_ACCESS_IDCARDS">
      <Route path="/IDCardWelcomeKitManager/home" component={WelcomeKitHome} />
      <Route path="/IDCardWelcomeKitManager/DocumentManager" component={WelcomeKitDocPage} />
    </Authorizer>
    <Route exact path="/IDCardWelcomeKitManager">
      <Redirect to="/IDCardWelcomeKitManager/home" />
    </Route>
  </Switch>
)
export default WelcomeKitRoutes;