import styled from 'styled-components';
export const DropDown = styled.div`
  height: 40px;
  width: 200px;
  margin-right: 35px;
`;
export const ImageControlWrap = styled.div`
  padding: 1px;
  max-height: 150px;
  & > span {display: block;}
`;
export const TruncatedColumn = styled.span`
  & div.tooltip-wrapper > div > div {
    display: inline;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  & div.tooltip-wrapper > div {
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  & .tooltip {
    white-space: normal;
  }
`;

export const ImageWrap = styled.img `
  display: block;
  max-width: ${props => props.ruleType === 'RAD' ? '100px' : '200px'};
  max-height: 100px;
  width: auto;
  height: auto;
  margin: auto;
`;

export const CanvasWrap = styled.canvas `
  border:1px solid #D3D3D3;
`;

export const TickIconWrap = styled.span `
  position:relative;
`;