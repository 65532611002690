import { SET_LOADING_STATE,SET_LOADING_STATE_FOR_DEACTIVATE_GROUP_MODAL, CLEAR_LOADING_STATE } from './constants';

export const setLoadingState = payload => ({
  type: SET_LOADING_STATE,
  payload
});

export const setLoadingStateForDeactivateGroupModal = payload => ({
  type: SET_LOADING_STATE_FOR_DEACTIVATE_GROUP_MODAL,
  payload
});

export const clearLoadingState = payload => ({
  type: CLEAR_LOADING_STATE,
  payload
});


export default {
  setLoadingState,
  setLoadingStateForDeactivateGroupModal,
  clearLoadingState
};
