export const gridConfig = {
  columns: new Set([
    {
      dataName: 'name',
      text: 'Group Name',
      sortable: true,
    },
    {
      dataName: 'status',
      text: 'Status',
      sortable: true,
    },
    {
      dataName: 'desc',
      text: 'Description',
      sortable: true,
    },
    {
      dataName: 'hierarchy',
      text: 'Hierarchy',
      sortable: true,
    },
    {
      dataName: 'arrangement',
      text: 'Template and Delivery Assignments',
      sortable: true,
    },
    {
      dataName: 'returnAddr',
      text: 'Return Address',
      sortable: true,
    },
    {
      dataName: 'dateModified',
      text: 'Date Modified',
      sortable: true,
    },
  ]),
  selectionKey: 'id',
  domID: 'main-grid',
  onSortTableColumn: () => {},
  sortingComparator: (sortDataName, sortDirectionString, records) => records,
  supportSelection: true,
  initialSortingKey: 'dateModified|SORT_DESCENDING',
  knownGroupFilters: ['all', 'active', 'inactive', 'termed'],
};
