/* eslint-disable no-nested-ternary */
import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, } from 'react-redux';
import {
  useLayout,
  Button
} from 'ui-core/dist'
import {
  LoadingPage,
  CountDownTimer
} from '../../../components';
import withAxiosCancellation from '../../../components/withAxiosCancellation'
import ErrorPage from '../../ErrorPage';
import {
  GENERIC_TEXT1,
  GENERIC_TEXT2,
  GENERIC_TEXT_RELEASE
} from "../Modules/constants";
import ListPaneHeaderWrapper from '../../../components/ErrorDisplaySection/ListPaneHeaderWrapper';
import DocumentManagerDocumentSearchForm from "../DocumentManagerDocumentSearchForm"
import { setFileSearchParam, setFileSearchParamNull } from "../Modules/action";

import { useCurrentTime, useReleaseTime } from '../../../api/useDocumentManager';
import '../Modules/styles.scss';

const GroupSearchReleaseMain = ({getCancelSourceWithName}) => {
  const {
    expandCollapseListPaneButtonConfig
  } = useLayout()

  const history = useHistory();
  const dispatch = useDispatch();

  const [fileKeys, setFileKeys] = useState('');
  const [empGroup, setEmpGroup] = useState('');
  const [tinSSN, setTinSSN] = useState('');
  const [checkNo, setCheckNo] = useState('');
  const [claimNo, setClaimNo] = useState('');
  const [itemsPerPage, setItemsPerPage] = useState({ label: 10, value: 10 });
  const [recipientType, setRecipientType] = useState('');
  const [documentType, setDocumentType] = useState('');
  const [sortField, setSortField] = useState('');
  const [claimType, setClaimType] = useState('');
  const [sortOrder, setSortOrder] = useState({ label: 'Descending', value: true });
  const [hasLimitExceededstate, sethasLimitExceededstate] = useState(false);
  const [isResetFlag, setIsResetFlag] = useState(false);
  const { currentTime, isError: isErrorCurrentTime, error: errorCurrentTime, isLoading: isLoadingCurrentTime } = useCurrentTime({getCancelSource:getCancelSourceWithName('searchReleaseGroupPageCurrentTime')});
 const { releaseTime, isError: isErrorReleaseTime, error: errorReleaseTime, isLoading: isLoadingReleaseTime } = useReleaseTime({getCancelSource:getCancelSourceWithName('searchReleaseGroupPageReleaseTime')});
  const isLoading = false;
  const isError = false;
  const error = '';
  const [transactionMessage, settransactionMessage]=useState('')

  useEffect(()=>{
    if (history?.location && history?.location?.state) {
      settransactionMessage(history?.location?.state);
      history.replace({ ...history?.location, state: undefined });
    }
  },[history?.location])

  const nextClick = () => {
    const payload = {
      "maxRecords": itemsPerPage ? itemsPerPage.value : 10,
      "rowPosition": 0,
      "sortField": sortField ? sortField.value : 'documentKey',
      "sortDescending": sortOrder ? sortOrder.value : 'true',
      "checkNumber": checkNo,
      "claimNumber": claimNo.replace(/['"]/g, ''),
      "groupId": empGroup,
      "idNumber": tinSSN,
      "filekey": fileKeys,
      "showDuplicateChecks": true,
      "showProcessedDocuments": false,
      "claimTypeId": claimType,
      "recipientType": recipientType,
      "documentType": documentType,
    }
    dispatch(setFileSearchParamNull(payload))
    dispatch(setFileSearchParam(payload))
    history.push(`/DocumentManager/Release/Group/searchResults`)
  }
  return (
    <>
      <ListPaneHeaderWrapper
        title="Document Manager: Release Groups"
        layoutControls={[expandCollapseListPaneButtonConfig]}

      />
      {isLoadingCurrentTime || isLoadingReleaseTime ? (
        // replace with a better loading component
        <LoadingPage />
      ) : isErrorCurrentTime || isErrorReleaseTime ? (
        // replace with a better error component
        <ErrorPage
          // this prop isn't real but is an example
          error={errorCurrentTime || errorReleaseTime}
        />
      ) : (
            <div className="mainWrapper">
              {releaseTime && currentTime ?
                <CountDownTimer
                  startDate={releaseTime}
                  endDate={currentTime}
                /> : ''}
              <div style={{ fontSize: "small", padding: "12px" }}>
                <i>{GENERIC_TEXT1}</i>
                <p>Type a complete or a partial claim number, check number, TIN, or SSN to search for specific documents or a range of documents. 
                  You can also select a group, file, or claim type to display all documents within the group, file, or claim type. 
                  Click Next to display the search results page where you can select the documents you want to release.</p>
                <p style={{ color: "#bf1616" }}>
                  {GENERIC_TEXT_RELEASE}
                </p>
                {transactionMessage && <p style={{ color: "black", fontWeight:'bold' }}>{transactionMessage}</p>}
              </div>
              <DocumentManagerDocumentSearchForm
              getCancelSourceWithName={getCancelSourceWithName}
                isRelease={true}
                isRangeControlEnabled={false}
                isResetField={isResetFlag}
                setIsResetFlag={setIsResetFlag}
                changeTinSsn={setTinSSN}
                changeFileKey={setFileKeys}
                changeEmpGroup={setEmpGroup}
                changeRecipientTypes={setRecipientType}
                changeDocumentTypes={setDocumentType}
                changeClaimType={setClaimType}
                changeSortField={setSortField}
                changeSortOrder={setSortOrder}
                changeItemsPerPage={setItemsPerPage}
                changeCheckNo1={setCheckNo}
                changeClaimNo1={setClaimNo}
                isSortingExist={false}
                nextClick={nextClick}
              />

              <div className="displayFlex marginLeft">
                <Button
                  buttonType="standard"
                  size="small"
                  name="RESET"
                  onClick={() => {
                    setIsResetFlag(true);
                  }}
                />
                <Button
                  buttonType="standard"
                  size="small"
                  style={{ marginLeft: '10px' }}
                  name="NEXT->"
                  onClick={() => {
                    nextClick();
                  }}
                />
              </div>
            </div>
          )}
    </>
  )
}

export default withAxiosCancellation(GroupSearchReleaseMain)
