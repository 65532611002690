import React from 'react'
import { Page } from '../../components'
import RouteWrapper from '../../components/RouteWrapper';
import WelcomeKitContent from './WelcomeKitDocuments'

const WelcomeKitDocHome = () => (
<RouteWrapper>
    <Page
        ListPaneContent={WelcomeKitContent} 
    />
</RouteWrapper>
)

export default WelcomeKitDocHome