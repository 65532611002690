import styled from 'styled-components';

export const ModalSubheadingPaddingWrapper = styled.div`
  padding: 0 0 30px 0;
  font-size: 14px;
`;

export const LoadingWrapper = styled.div`
  float: left;
  margin-left: -30px;
`;