import { useAxiosPost } from '../hooks'

const useGetAll = () => {  
  const { data, status, error, isError, isLoading, isSuccess, refetch, postData, metaData } = useAxiosPost({
    url: 'clientEditor/getAll',
    method: 'POST',
    dispatchError: false
  });
  
  return { data, status, error, isError, isLoading, isSuccess, refetch, metaData, postData}
}

const useGetSelected = () => {  
  const { data, status, error, isError, isLoading, isSuccess, refetch, postData, metaData } = useAxiosPost({
    url: 'clientEditor/getSelected',
    method: 'POST',
    dispatchError: false
  });
    
  return { data, status, error, isError, isLoading, isSuccess, refetch, metaData, postData}
}

const useAddEmail = () => {  
  const { data, status, error, isError, isLoading, isSuccess, refetch, postData, metaData } = useAxiosPost({
    url: 'clientEditor/add',
    method: 'POST',
    dispatchError: false
  });
      
  return { data, status, error, isError, isLoading, isSuccess, refetch, metaData, postData}
}
  
const useModifyEmail = () => {  
  const { data, status, error, isError, isLoading, isSuccess, refetch, postData, metaData } = useAxiosPost({
    url: 'clientEditor/modify',
    method: 'POST',
    dispatchError: false
  });
      
  return { data, status, error, isError, isLoading, isSuccess, refetch, metaData, postData}
}

const useActivateEmail = () => {  
  const { data, status, error, isError, isLoading, isSuccess, refetch, postData, metaData } = useAxiosPost({
    url: 'clientEditor/activate',
    method: 'POST',
    dispatchError: false
  });
      
  return { data, status, error, isError, isLoading, isSuccess, refetch, metaData, postData}
}
  
const useDeactivateEmail = () => {  
  const { data, status, error, isError, isLoading, isSuccess, refetch, postData, metaData } = useAxiosPost({
    url: 'clientEditor/deactivate',
    method: 'POST',
    dispatchError: false
  });
      
  return { data, status, error, isError, isLoading, isSuccess, refetch, metaData, postData}
}
export { useGetAll, useGetSelected, useAddEmail, useModifyEmail, useActivateEmail, useDeactivateEmail }