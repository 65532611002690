/* eslint-disable no-nested-ternary */
import React,{
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Grid, useLayout, Button } from 'ui-core/dist'
import { LoadingPage} from '../../../components';
import ErrorPage from '../../ErrorPage';
import {TERRORIST_WATCH_EXCLUSION_GRID} from '../Modules/constants';
import GridPaginationWrapper from '../../../components/GridPaginationWrapper';
import ListPaneHeaderWrapper from '../../../components/ErrorDisplaySection/ListPaneHeaderWrapper';
import useSortableGrid from '../../../hooks/useSortableGrid';
import { useTWExclusions,useGetSelectedTerroristExclusionExclusion } from "../../../api/useTWExclusion";
import {
  setTerroristExclusionRecord,
  resetTerroristExclusionSelectedRecord,
  setAddressExclusionSelectedRecord
} from '../Modules/action'
const TWExclusion = () => {
  const {
    expandCollapseListPaneButtonConfig,
    openDetailPane,
    closeDetailPane
  } = useLayout()
  const dispatch = useDispatch();
  const [rowsPerPage,setRowsPerPage]=useState(10);
  const [pageIndex,setPageIndex]=useState(0);
  const [count, setcount]=useState(0);
  const [selectedRecord,setSelectedRecord]=useState('')

  const fetchData=useCallback((args)=>{
    let sortcolumn; let sd; let pi;let type;
    if (args) {
      ({sortcolumn,sd,pi,type}=args);
    }
    closeDetailPane();
    dispatch(resetTerroristExclusionSelectedRecord());
    getAll(JSON.parse(JSON.stringify({
      "maxRecords":rowsPerPage,
      "rowPosition":pi===undefined || pi===null?pageIndex: pi,
      "sortField":sortcolumn===undefined || sortcolumn===null?sortConfig.sortDataName: sortcolumn,
      "sortDescending":sd===undefined || sd===null?sortConfig.sortDescending: sd,
      type,
      count
    })))
    setcount(count+1)
    
  },[rowsPerPage,pageIndex,sortConfig]);
  const [sortConfig, getArgumentsAndSortGrid]=useSortableGrid(fetchData,"twExclusionDesc","true");
  const {twExclusionData, totalRecordsResp,getAll,isLoading,isError,error, refetch} = useTWExclusions();
  
  
 
  useEffect(()=>{
    getAll({})
  },[])
  
  const refetchterroristExclusionRecord=useCallback(()=>{
    
    getAll(JSON.parse(JSON.stringify({      
      "maxRecords":rowsPerPage,
      "rowPosition":pageIndex,
      "sortField":sortConfig.sortDataName,
      "sortDescending":sortConfig.sortDescending,
      count
    })))
    setcount(count+1)
  })
  const onAddTerroristExclusion = () =>{
    setSelectedRecord({})
    dispatch(setTerroristExclusionRecord({terroristExclusionSelectedRecord:JSON.parse(JSON.stringify({})), mode:'Add', refetchterroristExclusionRecord}));
    openDetailPane();
  }
  
  const onShowMoreClick=useCallback((record)=>{
    record.count=count
    dispatch(setTerroristExclusionRecord({terroristExclusionSelectedRecord:JSON.parse(JSON.stringify(record)), mode:'Edit', refetchterroristExclusionRecord}));
    setSelectedRecord(JSON.parse(JSON.stringify(record)))
    openDetailPane();
    setcount(count+1)
  },[twExclusionData]);

  const renderGridContent = useMemo(()=>{
    const data=twExclusionData.length && twExclusionData.map((twExclusionDataResults)=>({
      twExclusionDesc:twExclusionDataResults.twExclusionDesc||'',
      key:twExclusionDataResults.key||''
      // show: (
      //   <Button 
      //     domID="modify"
      //     name="Modify"
      //     buttonType="standard"
      //     onClick={(e) => {
      //       e.preventDefault();
      //       e.stopPropagation();
      //       if (data) {
      //         // const record=JSON.parse(JSON.stringify(data))
      //         if (onShowMoreClick) {
      //           // dispatch(resetAddressExclusionSelectedRecord())
      //           return onShowMoreClick(twExclusionDataResults);
      //         }
      //         return false;
      //       }
      //       return false;
      //     }}
      //     size="small"
      //   />
      // )
    }))
    return(<Grid
      {...TERRORIST_WATCH_EXCLUSION_GRID}
      dataTestId="test-gridDefault"
      domID="twExclusionId"
      records={data||[]}
      selectionKey="key"
      // initialSortingKey={`${sortConfig.sortDataName}|${sortConfig.sortDescending==="true"?'SORT_DESCENDING':'SORT_ASCENDING'}`}
      // onSortGridColumn={(e,state)=>getArgumentsAndSortGrid(state)}
      onClickThrough={(e,rec) => {
        e.preventDefault();
        e.stopPropagation();
        if (data) {
          // const record=JSON.parse(JSON.stringify(data))
          if (onShowMoreClick) {
            // dispatch(resetAddressExclusionSelectedRecord())
            return onShowMoreClick(rec);
          }
          return false;
        }
        return false;
      }}
      focusedItem={selectedRecord}
    />)},[twExclusionData,selectedRecord])
  

  return (
    <>
      <ListPaneHeaderWrapper
        title="Terrorist Watch Exclusion"
        layoutControls={[expandCollapseListPaneButtonConfig]}
      />
      {isLoading ? (
      // replace with a better loading component
        <LoadingPage />
      ) :  isError ? (
        // replace with a better error component
        <ErrorPage
          // this prop isn't real but is an example
          error={error}
        />
      ) : (
        <>
          <div>
            <div className="mainWrapper">
              <GridPaginationWrapper
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={setRowsPerPage}
                pageIndex={pageIndex}
                onChangePageIndex={setPageIndex}
                onShowButtonCLick={fetchData}
                totalRecords={totalRecordsResp}
                defaultRowsPerPage={10}
              />
              <div className="displayFlex">
                <Button 
                  domID="add"
                  name="Add Terrorist Watch Exclusion"
                  buttonType="standard"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onAddTerroristExclusion();
                  }}
                  style={{ marginLeft: "20px" }}
                  size="small"
                />
                
              </div>
            </div>
            <div className='GridWrapper'>
              {renderGridContent}
            </div>
          </div>
        </>
      )}
    </>
  )
}
  
export default TWExclusion
  