import React from 'react'
import { Page } from '../../components'
import RouteWrapper from '../../components/RouteWrapper';
import WelcomeKitContent from './WelcomeKit'

const WelcomeKit = () => (
<RouteWrapper>
    <Page 
        ListPaneContent={WelcomeKitContent}
    />
</RouteWrapper>
)

export default WelcomeKit