/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import {
  Grid,
  useLayout,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ButtonGroup,
  ModalBody
} from 'ui-core/dist'
import styled from 'styled-components/dist/styled-components';
import withAxiosCancellation from '../../../components/withAxiosCancellation'
import useNotificationBannerHook from '../../../useNotificationBannerHook';
import { LoadingPage, AnchorInput, SelectDropdown } from '../../../components';
import ErrorPage from '../../ErrorPage';
import {
  GENERIC_TEXT_SEARCH_RELEASE,
  GENERIC_TEXT_RELEASE,
  RELEASE_GROUP_SEARCH_RESULT_COLUMN,
  RELEASE_TYPE,
} from "../Modules/constants";
import GridPaginationWrapper from '../../../components/GridPaginationWrapper';
import ListPaneHeaderWrapper from '../../../components/ErrorDisplaySection/ListPaneHeaderWrapper';
import { useSearchReleaseGroup, useSubmitReleaseGroups } from '../../../api/useDocumentManager';
import useSortableGrid from '../../../hooks/useSortableGrid';
import FileReceiptManagerDetailComponent from '../../FileReceiptManager/FileReceiptManagerDetailComponent';
import { setFileSearchParam, setFileSearchParamNull } from "../Modules/action";
import PopoverBox from "../../../components/PopoverBox";
import useSelectableGrid from '../../../hooks/useSelectableGrid';
import '../Modules/styles.scss';

const StyledModalWrapper = styled.div`
height: 80vh;
`
const StyledModalHeaderWrapper = styled.div`
& div {
  padding:1px 9px !important;
  padding-bottom: 0px !important;
}
`;
const StyleDivParent = styled.div`
  &.styled-parent-div {
    margin-top: 45px;
    border: 1px solid #cacccd;
    /* border-width: 1px; */
    /* border-color: #dbdbdb; */
    overflow-y: auto;
    height: 470px;
    border-radius: 5px;
    margin-left: 30px; 
    text-align: left;
    /* -webkit-box-shadow: -1px 9px 18px 0px rgb(0 0 0 / 75%); */
    /* -moz-box-shadow: -1px 9px 18px 0px rgba(0,0,0,0.75); */
    /* box-shadow: 0px 0px 6px -1px rgb(0 0 0 / 75%); */
  }
  &.popBoxDiv {
    display: inline-block;
    & span {
      position:relative !important;

      & div.popover.bottom {
        border-radius: 5px;
        margin-top: 42px !important;
        margin-left: 27px !important;
      }
    }
  }
`;
const GSearchResultReleaseMain = ({getCancelSourceWithName}) => {
  const {
    expandCollapseListPaneButtonConfig
  } = useLayout()

  const history = useHistory();
  const { notify } = useNotificationBannerHook()
  const dispatch = useDispatch();
  const [isOpenFileReceiptModal, setisOpenFileReceiptModal] = useState(false)
  const [isOpenConfirmedModal, setConfirmedModal] = useState(false)
  const [currentFileKey, setCurrentFileKey] = useState('')
  const { fileSearchParam } = useSelector(({ documentSearch }) => documentSearch);
  const [changeItemPerPage, setchangeItemsPerPage] = useState(fileSearchParam.maxRecords)
  const [pageIndex, setPageIndex] = useState(0)
  // const [selectedItemIds, setSelectedItemIds] = useState([])
  const { transactionId, nextClick, isLoading: isTxnLoading } = useSubmitReleaseGroups()
  const { allData, totalRecordsResp, isLoading, isError, error, searchReleaseGroups } = useSearchReleaseGroup({getCancelSource:getCancelSourceWithName('searchReleaseGroupResult')});
  const [releaseType, setReleaseType] = useState({ label: 'Scheduled Release Rule', value: "S" });
  const [disabledItemIds, setDisableItemIds] = useState([])
  const [count, setCount] = useState(0);
  const { onRowSelect, onClearAllSelection, selectedItemIds, selectedItemIdsCurrentPage, selectedRecords } = useSelectableGrid({ uniqueKey: 'id', list: allData, isUicl: true })
  useEffect(() => {
    searchReleaseGroups(fileSearchParam)
  }, [])

  useEffect(() => {
    if (releaseType.value === "S") {
      if (allData.length) {
        const disableItems = allData.filter(x => (x.$.user === "Scheduled")).map(y => y.$.description)
        setDisableItemIds(disableItems)
      }
    }
  }, [releaseType, allData])
  useEffect(() => {
    if (transactionId) {
      // notify(`You have successfully released the groups.  The transaction ID is ${transactionId}`, 'positive')
      // searchReleaseGroups(JSON.parse(JSON.stringify({ ...fileSearchParam, count })));
      // setCount(count + 1);
      history.push({ pathname: '/DocumentManager/Release/Group', state: `You have successfully released the groups.  The transaction ID is ${transactionId}` })
      setConfirmedModal(false);
      onClearAllSelection()
    }
  }, [transactionId])
  const onChangeItemsPerPage = (v) => {
    setchangeItemsPerPage(v)
  }
  const onChangePageIndex = (v) => {
    setPageIndex(v);
  }
  const onCloseConfirmedModal = () => {
    setConfirmedModal(false)
    setCurrentFileKey('')
    // dispatch(resetFileKey())
    return true;
  }
  const nextConfirmed = () => {
    const pstrTransactionIds = [releaseType.value, ...selectedItemIds]
    nextClick({
      pstrTransactionIds, count
    })
    // setConfirmedModal(false)
    setCount(count + 1);
    // history.push(`/DocumentManager/Release/Group`)
  }
  const onCloseFileReceiptModal = () => {
    setisOpenFileReceiptModal(false)
    setCurrentFileKey('')
    // dispatch(resetFileKey())
    return true;
  }
  // const selectRow = (e,records) =>{
  //   setSelectedItemIds(Array.from(records.selectedItemIds))
  // }
  const fetchData = useCallback((args) => {
    let sortcolumn; let sd; let pi;
    if (args) {
      ({ sortcolumn, sd, pi } = args);
    }

    const payload = JSON.parse(JSON.stringify({
      ...fileSearchParam,
      "maxRecords": changeItemPerPage,
      "rowPosition": pi === undefined || pi === null ? pageIndex : pi,
      "sortField": sortcolumn === undefined || sortcolumn === null ? sortConfig.sortDataName : sortcolumn,
      "sortDescending": sd === undefined || sd === null ? sortConfig.sortDescending : sd,
    }))

    dispatch(setFileSearchParamNull(payload))
    dispatch(setFileSearchParam(payload))
    searchReleaseGroups(payload)

  }, [changeItemPerPage, pageIndex, sortConfig]);

  const [sortConfig, getArgumentsAndSortGrid] = useSortableGrid(fetchData, "filekey", "true");
  const renderGridContent = useMemo(() => {
    if (allData.length) {
      // const temp=JSON.parse(JSON.stringify(errorResp));
      const data = allData.map((groupSearchResults) => ({
        filekey: (
          <AnchorInput
            id='fileKey'
            type='button'
            className='fileKeyLink'
            // onClick={()=>{history.push(`/FileReceiptManager/details/${fileSearchResults.filekey._}`)}}
            onClick={() => {
              setisOpenFileReceiptModal(true)
              setCurrentFileKey(groupSearchResults.filekey)
            }}
          >{groupSearchResults.filekey}</AnchorInput>
        ),
        originalFileName: groupSearchResults.origName.description,
        description: `${groupSearchResults.$.description} ${groupSearchResults.$.description2}`,
        group: groupSearchResults.group.description,
        docType: groupSearchResults.documentType.description,
        claimType: groupSearchResults.claimType.description,
        recipType: groupSearchResults.recipientType.description,
        id: groupSearchResults.id
      }))


      return (

        <Grid
          {...RELEASE_GROUP_SEARCH_RESULT_COLUMN}
          domID="DocumentSearchHold"
          dataTestId="test-gridDefault"
          records={data || []}
          selectionKey="id"
          initialSelectedItemIds={selectedItemIdsCurrentPage}
          initialDisabledItemIds={new Set(disabledItemIds)}
          onRowSelect={onRowSelect}
          onSelectAll={onRowSelect}
          onSortGridColumn={(e, state) => getArgumentsAndSortGrid(state)}
          initialSortingKey={`${sortConfig.sortDataName}|${sortConfig.sortDescending === "true" ? 'SORT_DESCENDING' : 'SORT_ASCENDING'}`}
          supportSelection
        />
      );
    } else {
      return (
        <div style={{ marginTop: '14px' }} className="displayFlex">
          <label style={{ color: 'red' }}>No records found. Change search criteria.</label>
        </div>
      )
    }
  }, [allData, selectedItemIds, selectedItemIdsCurrentPage, selectedRecords, transactionId]);
  const renderCheckedContent = useMemo(() => {
    const selectedRecordsArray = Array.from(selectedRecords);
    if (selectedRecordsArray.length) {
      // const temp=JSON.parse(JSON.stringify(errorResp));
      const data = selectedRecordsArray.map((groupSearchResults) => ({
        filekey: groupSearchResults.filekey,
        originalFileName: groupSearchResults.origName.description,
        description: `${groupSearchResults.$.description} ${groupSearchResults.$.description2}`,
        group: groupSearchResults.group.description,
        docType: groupSearchResults.documentType.description,
        claimType: groupSearchResults.claimType.description,
        recipType: groupSearchResults.recipientType.description,
        id: groupSearchResults.id
      }))


      return (
        <Grid
          {...RELEASE_GROUP_SEARCH_RESULT_COLUMN}
          domID="DocumentSearchHoldConfirm"
          // isFixedHeader
          maxHeight="350px"
          dataTestId="test-gridDefault"
          records={data || []}
        // onSortGridColumn={(e,state)=>getArgumentsAndSortGrid(state)}
        />
      );
    }
    return '';
  }, [selectedRecords, isOpenConfirmedModal])
  return (
    <>
      <ListPaneHeaderWrapper
        title="Document Manager: Release Groups"
        layoutControls={[expandCollapseListPaneButtonConfig]}

      />
      {isLoading ? (
        // replace with a better loading component
        <LoadingPage />
      ) : isError ? (
        // replace with a better error component
        <ErrorPage
          // this prop isn't real but is an example
          error={error}
        />
      ) : (
            <div className="mainWrapper">
              <div style={{ fontSize: "small", padding: "12px" }}>
                <p>{GENERIC_TEXT_SEARCH_RELEASE}</p>
                <div style={{ color: "#bf1616" }}>
                  <p>
                    {GENERIC_TEXT_RELEASE}
                  </p>
                </div>
              </div>
              <div>
                <div className="" style={{ marginLeft: "-3px" }}>
                  <StyleDivParent className="popBoxDiv">
                    <PopoverBox
                      trigger="hover"
                      orientation="bottom"
                      buttonName="File Cutoff Time Applies"
                      buttonType="diminished"
                      domID="format_popup_Group"
                      width="350px"

                    >
                      <div style={{
                        padding: "14px",
                        color: "#202083",
                        fontSize: "13px",
                        fontWeight: "bold",
                      }}>
                        File cutoff time is defined as the time in which a file must be fully imported, in order to be included in the 10:00AM release.

                  </div>
                    </PopoverBox>
                  </StyleDivParent>
                </div>
                <div className="displayFlex"></div>
                <GridPaginationWrapper
                  rowsPerPage={changeItemPerPage}
                  onChangeRowsPerPage={(v) => onChangeItemsPerPage(v)}
                  pageIndex={pageIndex}
                  onChangePageIndex={(v) => onChangePageIndex(v)}
                  onShowButtonCLick={(args) => fetchData(args)}
                  totalRecords={totalRecordsResp}
                  defaultRowsPerPage={changeItemPerPage}
                  enableTextbox={false}
                />
                <div style={{ marginBottom: "10px" }} className="displayFlex">
                  <span>Release Type:</span>
                  <SelectDropdown
                    domID="releaseType"
                    onChange={(e) => { setReleaseType(e) }}
                    options={RELEASE_TYPE}
                    initialValue={releaseType}
                    size="small"
                    isClearable={false}
                    width="240px"
                  />
                </div>
                <ButtonGroup>
                  <Button
                    buttonType="standard"
                    size="small"
                    name="New Search"
                    onClick={() => { history.push(`/DocumentManager/Release/Group`) }}
                  />
                  <Button
                    buttonType="standard"
                    size="small"
                    name="Clear all"
                    disabled={!selectedItemIds.size}
                    onClick={() => {
                      onClearAllSelection()
                    }}
                  />
                  <Button
                    buttonType="standard"
                    size="small"
                    name="CONFIRM SELECTED ITEMS"
                    disabled={!selectedItemIds.size}
                    onClick={() => {
                      setConfirmedModal(true);
                    }}
                  />
                </ButtonGroup>
                <div className='GridWrapper'>
                  {renderGridContent}
                </div>
              </div>
            </div>
          )}
      <Modal isOpen={isOpenFileReceiptModal}>
        <StyledModalWrapper>
          <StyledModalHeaderWrapper>
            <ModalHeader
              title="File Receipt"
              onClose={() => onCloseFileReceiptModal()}
            />
          </StyledModalHeaderWrapper>
          <ModalBody>
            {isOpenFileReceiptModal ? (
              <FileReceiptManagerDetailComponent
                filekey={currentFileKey}
                height='58vh'
                overflow='auto'
              />
            ) : null}
          </ModalBody>
        </StyledModalWrapper>
      </Modal>
      <Modal isOpen={isOpenConfirmedModal}>
        <ModalHeader
          title="Selected Groups"
          onClose={() => onCloseConfirmedModal()}
        />
        <ModalBody>
          <div className="displayFlex" style={{ paddingLeft: '30px' }}>
            <span>Confirm your selections, and then click Confirm to remove the hold and release the documents to printing.</span>
          </div>
          <div style={{ marginBottom: "10px", marginLeft: "30px" }} className="displayFlex">
                  <span>Release Type:</span>
                  <SelectDropdown
                    domID="relType"
                    disabled="true"
                    initialValue={releaseType}
                    options={[
                      { value: 'S', label: 'Scheduled Release Rule' },
                      { value: 'I', label: 'First Available' }
                    ]}
                    size="small"
                    width="240px"
                  />
                </div>
          <div>
            {isOpenConfirmedModal ? (
              <div className="modalOuterDiv" style={{
                paddingLeft: '25px',
                paddingRight: '25px',
                marginTop: '20px',
                marginBottom: '-10px',
                maxHeight: '40vh',
                overflow: 'scroll',
                maxWidth: '800px'
              }}>
                {renderCheckedContent}
              </div>
            ) : null}

          </div>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button
              buttonType="standard"
              domID="close"
              dataTestId="test-close"
              name="CLOSE"
              onClick={() => setConfirmedModal(false)}
              size="medium"
            />
            <Button
              buttonType="standard"
              domID="confirm"
              dataTestId="test-confirm"
              name="CONFIRM"
              onClick={() => nextConfirmed()}
              size="medium"
              disabled={isTxnLoading}
            />
            {
              isTxnLoading && <LoadingPage height="1vh" />
            }
          </ButtonGroup>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default withAxiosCancellation(GSearchResultReleaseMain)
