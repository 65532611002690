
import { useEffect, useState } from 'react'
import { useAxiosPost } from '../hooks'


const useAddAddressCorrectionExclusion=()=>{
  const {postData, isLoading,isError,error, metaData, data }=useAxiosPost({
    url:'clientAdministration/addAddressCorrectionExclusion',
    method:'POST',
    onError: (e) => 
      // what happens on error? if you need something extra
      // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  

  return { isLoading, isError, error, postData, metaData, data};
}
const useModifyAddressCorrectionExclusion=()=>{
  const {postData, isLoading,isError,error,metaData, data }=useAxiosPost({
    url:'clientAdministration/modifyAddressCorrectionExclusion',
    method:'POST',
    onError: (e) => 
      // what happens on error? if you need something extra
      // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  

  return { isLoading, isError, error, postData, metaData, data};
}

const useGetSelectedAddressCorrectionExclusion=()=>{
  const [showMoreInfo, setshowMoreInfo]=useState(null)
  const {postData, isLoading,isError,error, data }=useAxiosPost({
    url:'clientAdministration/getSelectedAddressCorrectionExclusion',
    method:'POST',
    onError: (e) => 
      // what happens on error? if you need something extra
      // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  
  useEffect(()=>{
    if (data && data.resp) {
      setshowMoreInfo(JSON.parse(JSON.stringify(data.resp)))
    } else{
      setshowMoreInfo(null);
    }
  },[data])

  return {showMoreInfo, isLoading, isError, error, postData};
}
const useGetAllAddressCorrectionExclusion=()=>{
  const [totalRecords,setTotalRecords]=useState(0)
  const [records, setRecords]=useState([]);
  const {postData, isLoading,isError,error, data }=useAxiosPost({
    url:'clientAdministration/getAllAddressCorrectionExclusion',
    method:'POST',
    onError: (e) => 
      // what happens on error? if you need something extra
      // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  
  useEffect(()=>{
    if (data) {
      const {collection}=data;
      if (collection && collection.$) {
        const {totalRecords: totalRecordsp, type}=collection.$
        if (type) {
          let finalData;
          if (collection[type]) {
            finalData=collection[type]
          } else if (collection['ac-exclusion']){
            finalData=collection['ac-exclusion']
          }
          if (finalData) {
            setTotalRecords(totalRecordsp)  
            const sr=JSON.parse(JSON.stringify(finalData))  
            let searchResult=[]
            if (sr.constructor.name==="Array") {
              searchResult=[...sr]
            } else if(sr.constructor.name==="Object"){
              searchResult.push({...sr})
            }
            if (searchResult && searchResult.length>0) {
              setRecords(JSON.parse(JSON.stringify(searchResult)))  
            } else{
              setRecords(JSON.parse(JSON.stringify([])))  
            }
          } else{
            setTotalRecords(0);
            setRecords(JSON.parse(JSON.stringify([])))
          }
        } else{
          setTotalRecords(0);
          setRecords(JSON.parse(JSON.stringify([])))
        }
      }
    }
  },[data])
  return {totalRecords,records,postData, isLoading, isError, error};
}

export {
  useAddAddressCorrectionExclusion,
  useModifyAddressCorrectionExclusion,
  useGetSelectedAddressCorrectionExclusion,
  useGetAllAddressCorrectionExclusion,
}