/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import {
  Grid,
  useLayout,
  Button,
  useNotificationBanner,
} from 'ui-core/dist'
import { useSelector, useDispatch } from 'react-redux';
import { SelectDropdown } from '../../components'
import ErrorPage from '../ErrorPage';
import useNotificationBannerHook from '../../useNotificationBannerHook';
import { detailPaneAnimStates } from '../../commonResources/constants/animStates';
import { LoadingPage } from '../../components';
import { ERROR_MANAGER_DETAIL_NOTE_GRID_COLUMN, ERROR_MANAGER_DETAIL_PAGE_GRID_COLUMN } from './Modules/constants';
import './Modules/styles.scss';
import GridPaginationWrapper from '../../components/GridPaginationWrapper';
import { useErrorDetails, useErrorStatusValues, useUpdateStatus, useErrorListByKey } from '../../api/useErrorManager';
import { setNotesModal, setDetailsInfoModal, setCuid } from './Modules/Action'
import ErrorManagerAddNotes from './ErrorManagerAddNotes';
import DetailsInfoModal from './DetailsInfoModal';
import useSortableGrid from '../../hooks/useSortableGrid';
import Authorizer from '../../containers/Authorizer';
import withAxiosCancellation from '../../components/withAxiosCancellation'

const ErrorManagerDetail = ({getCancelSourceWithName}) => {
  const {
    detailAnimationState,
    closeDetailPane,
    detailProps
  } = useLayout()
  const dispatch = useDispatch()
  const { notify } = useNotificationBannerHook()
  const DEFAULT_ROWS_PER_PAGE = 10;
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const [pageIndex, setPageIndex] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [resp, setResp] = useState([]);
  const [totalRecordsResp, settotalRecordsResp] = useState(0);

  const { id, errorListRefetch } = useSelector(store => store.errorManager)

  const { postData: updateStatusClicked, data: updateResp } = useUpdateStatus({
    onSuccess: () => {
    },
    onError: () => {
      notify('Error occurred while updating status', 'negative');
    }
  });
  useEffect(()=>{
    if (updateResp) {
      notify(`Status of Error: ${id} has been successfully updated to ${selectedStatus?.label || "--None--"}`, 'positive')
      setSelectedStatus(null)
      // detailProps.refreshMe()
      closeDetailPane()
      if (errorListRefetch) errorListRefetch()
    }
  },[updateResp])

  const {
    data,
    totalRecords,
    isLoading,
    getErrorDetails,
    refetch: refetchErrorDetails,
    isError,
    error
  } = useErrorDetails();

  const {
    isLoadingForKey,
    errorRespforKey,
    totalRecordsRespForKey,
    getErrorListByFileKey
  } = useErrorListByKey();

  useEffect(() => {
    if (data || errorRespforKey) {
      if (id.length === 17 || id.length === 23) {
        setResp(errorRespforKey);
        settotalRecordsResp(totalRecordsRespForKey);
      } else {
        setResp(data);
        settotalRecordsResp(totalRecords);
      }
    }
  }, [data, errorRespforKey, totalRecords])

  const fetchData = (args) => {
    let sortcolumn; let sd; let pi;
    if (args) {
      ({ sortcolumn, sd, pi } = args);
    }
    const param = JSON.parse(JSON.stringify({
      "maxRecords": rowsPerPage,
      "rowPosition": pi === undefined || pi === null ? pageIndex : pi,
      "sortField": sortcolumn === undefined || sortcolumn === null ? sortConfig.sortDataName : sortcolumn,
      "viewInactive": false,
      "sortDescending": false,
      "parameters": "",
      "pstrErrorId": id
    }))
    if (id.length === 17 || id.length === 23)
      getErrorListByFileKey({ ...param, pstrKey: id })
    else
      getErrorDetails(param);
  }

  const [sortConfig, getArgumentsAndSortGrid] = useSortableGrid(fetchData, "errorCuid", "true");

  useEffect(() => {
    if (detailPaneAnimStates && detailAnimationState >= detailPaneAnimStates.EXPANDED) {
      const param = {
        "maxRecords": "10",
        "rowPosition": 0,
        "sortField": sortConfig.sortDataName,
        "viewInactive": false,
        "sortDescending": false,
        "parameters": "",
        "pstrErrorId": id
      }
      if (id.length === 17 || id.length === 23)
        getErrorListByFileKey({ ...param, pstrKey: id })
      else
        getErrorDetails(param)

      getErrorStatusValues({
        "maxRecords": "10",
        "rowPosition": 0,
        "sortField": "id",
        "viewInactive": false,
        "sortDescending": true,
        "parameters": "",
      })
    }
  }, [id, sortConfig.sortDataName, sortConfig.sortDecending, detailAnimationState]);

  const updateStatusClick = () => {
    if (updateStatusClicked) {
      updateStatusClicked({
        pstrErrorId: id,
        pstrNewStatusId: selectedStatus?.value || ""
      })
    }
  }

  const { errorStatusValues, getErrorStatusValues, refetch } = useErrorStatusValues();

  const loadInitialStatusValue = () => {
    if (selectedStatus) {
      return selectedStatus;
    }
    if (errorStatusValues && errorStatusValues.length > 0) {
      return errorStatusValues[0]
    }
    return selectedStatus;
  }

  const renderSection = (errorResp) => {
    if (errorResp)
      return (
        <div>
          <div className="displayFlex">
            <label> Error ID : </label>
            <div className="marginLeft">
              {errorResp.id}
            </div>
          </div>
          <div className="displayFlex">
            <label> System Type : </label>
            <div className="marginLeft">
              {errorResp.systemType}
            </div>
          </div>
          <div className="displayFlex">
            <label> Current Status : </label>
            <div className="marginLeft">
              {errorResp.status} {errorResp.status === 'Resolved' ?
                `(resolved at ${errorResp?.resolution?.dateTime || ''} by user ${errorResp?.resolution?.userId || ''})` : ''}
            </div>
          </div>
          <div className="displayFlex">
            <label> Severity : </label>
            <div className="marginLeft">
              {errorResp.severity}
            </div>
          </div>
          <div className="displayFlex">
            <label> Error Description : </label>
            <div className="marginLeft">
              {errorResp.description}
            </div>
          </div>
          {errorResp?.notes?.note && (
            <>
              <div className="mainWrapper">
                <div className="displayFlex">
                  <h4> Notes :</h4>
                </div>
              </div>
              <Grid
                {...ERROR_MANAGER_DETAIL_NOTE_GRID_COLUMN}
                dataTestId="test-gridDefault"
                domID="test-id"
                records={errorResp.notes.note || []}
                selectionKey="error_id"
              />
            </>
          )}
          <div className="displayFlex">
            <h4> Detail Information : </h4>
          </div>

          <GridPaginationWrapper
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={setRowsPerPage}
            pageIndex={pageIndex}
            onChangePageIndex={setPageIndex}
            onShowButtonCLick={fetchData}
            totalRecords={totalRecordsResp}
            defaultRowsPerPage={DEFAULT_ROWS_PER_PAGE}
          />
          <div className='GridWrapper' style={{ marginBottom: '20px' }}>
            <Grid
              {...ERROR_MANAGER_DETAIL_PAGE_GRID_COLUMN}
              dataTestId="test-gridDefault"
              domID="test-id"
              records={errorResp?.details?.detail ?
                errorResp.details.detail.map((d) => ({
                  ...d,
                  errorInfoDisplay: d.errorInfo
                })) : []}
              selectionKey="error_id"
              // initialSortingKey={`${sortConfig.sortDataName}|${sortConfig.sortDescending === "true" ? 'SORT_DESCENDING' : 'SORT_ASCENDING'}`}
              // onSortGridColumn={(e, state) => getArgumentsAndSortGrid(state)}
              onClickThrough={(e, rec) => {
                e.preventDefault();
                e.stopPropagation();
                if (rec) {
                  dispatch(setDetailsInfoModal(true))
                  dispatch(setCuid(rec.errorCuid))
                }
              }}
            />
          </div>
        </div>
      )
  }

  return (
    <>
      {isLoading || isLoadingForKey ? (
        // replace with a better loading component
        <LoadingPage />
      ) : isError ? (
        // replace with a better error component
        <ErrorPage
          // this prop isn't real but is an example
          error={error}
        />
      ) : (
          <div>
            <div className="mainWrapper">
              <div className="displayFlex">
                <Authorizer permission='CAN_PERFORM_ERROR_ADD_NOTE'>
                <Button
                  buttonType="emphasized"
                  domID="AddANote"
                  size="medium"
                  name="Add a note"
                  onClick={() => dispatch(setNotesModal(true))}
                />
                </Authorizer>
              </div>
              {errorStatusValues && errorStatusValues.length > 1 ? (
                <>
                <Authorizer permission='CAN_PERFORM_ERROR_UPDATE_STATUS'>
                <div className="displayFlex">
                  <label> Select Status: </label>
                  <div className="marginLeft">
                    <div className="displayFlex">
                      <SelectDropdown
                        domID="defer_state_input"
                        label=""
                        options={errorStatusValues}
                        size="small"
                        initialValue={loadInitialStatusValue()}
                        onChange={(e) => {
                          if (e) {
                            setSelectedStatus(e)
                          } else {
                            setSelectedStatus("")
                          }
                        }}
                      />
                      <div className="marginLeft">

                        <Button
                          buttonType="standard"
                          size="small"
                          name="Update Status"
                          onClick={() => {
                            updateStatusClick()
                          }}
                        />

                      </div>
                    </div>
                  </div>
                </div>
                </Authorizer>
                </>
              ) : null}
              {id.length === 17 || id.length === 23 ? resp.map((r) => renderSection(r)) : renderSection(resp)}
            </div>
          </div>
        )}
      <ErrorManagerAddNotes refreshMe={[refetchErrorDetails, refetch]} />
      <DetailsInfoModal />
    </>
  )
}

export default withAxiosCancellation(ErrorManagerDetail)
