/* eslint-disable no-nested-ternary */
import React, {
  useState,
  useEffect,
  useCallback,
  useMemo
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Grid, useLayout, Button } from 'ui-core/dist'
import { LoadingPage, AnchorInput, SelectDropdown } from '../../components';
import { ERROR_MANAGER_MAIN_GRID_COLUMN, SELECT_STATUS_RECORD } from './Modules/constants';
import { setErrorIds, resetErrorIds, setDetailsId, setResolutionModal, setIsAll, getErrorListRefetch } from './Modules/Action';
import GridPaginationWrapper from '../../components/GridPaginationWrapper';
import ListPaneHeaderWrapper from '../../components/ErrorDisplaySection/ListPaneHeaderWrapper';
import { useErrorList } from "../../api/useErrorManager";
import ResolutionConfirmationModal from './ResolutionConfirmationModal'
import useSortableGrid from '../../hooks/useSortableGrid';
import useSelectableGrid from '../../hooks/useSelectableGrid';
import Authorizer from '../../containers/Authorizer';
import ErrorPage from '../ErrorPage';
import withAxiosCancellation from '../../components/withAxiosCancellation'


const ErrorManagerMain = ({getCancelSourceWithName}) => {
  const {
    expandCollapseListPaneButtonConfig,
    openDetailPane,
    closeDetailPane
  } = useLayout()
  const dispatch = useDispatch();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [status, setStatus] = useState({ label: 'Unresolved', value: 'false' });
  const [isResolveAllPage, setIsResolveAllPage] = useState(false);
  const [selectedRecord,setSelectedRecord]=useState('')

  const getSortDataName = () => {
    const currDataName = sortConfig.sortDataName;
    let newDataName = currDataName;

    if (newDataName === 'severity') newDataName = 'highest-severity';
    else if (newDataName === 'errorId') newDataName = 'id';

    return newDataName;
  }

  const fetchData = useCallback((args) => {
    let sortcolumn; let sd; let pi;
    if (args) {
      ({ sortcolumn, sd, pi } = args);
    }
    setSelectedRecord({})
    closeDetailPane();
    setcount(count + 1)
    getErrorList({
      "maxRecords": rowsPerPage,
      "rowPosition": pi === undefined || pi === null ? pageIndex : pi,
      "sortField": sortcolumn === undefined || sortcolumn === null ? getSortDataName() : sortcolumn,
      "viewInactive": (status && status.value) ? status.value : false,
      "sortDescending": sd === undefined || sd === null ? sortConfig.sortDescending : sd,
      "parameters": "",
      count: count + 1
    });
    if (!status || status.value === "false") {
      setStatus({ label: 'Unresolved', value: 'false' });
      setIsResolveAllPage(false);
    } else {
      setIsResolveAllPage(true);
    }
  }, [rowsPerPage, pageIndex, sortConfig, status, count]);

  const [sortConfig, getArgumentsAndSortGrid] = useSortableGrid(fetchData, "errorId", "true");
  const [count, setcount] = useState(0)
  const { errorResp, totalRecordsResp, postData: getErrorList, isLoading, isError, error } = useErrorList({getCancelSource:getCancelSourceWithName('getErrorList')});

  const loadErrorManagerList = useCallback(() => {
    setcount(count + 1)
    setSelectedRecord({})
    const payload = {
      "maxRecords": rowsPerPage,
      "rowPosition": pageIndex,
      "sortField": getSortDataName(),
      "viewInactive": (status && status.value) ? status.value : false,
      "sortDescending": sortConfig.sortDescending,
      "parameters": "",
      count: count + 1
    };
    getErrorList(payload);
  }, [rowsPerPage, pageIndex, sortConfig, status, count]);

  const { onRowSelect, selectedItemIds, selectedItemIdsCurrentPage, selectedRecords, onClearAllSelection } = useSelectableGrid({ uniqueKey: 'id', list: errorResp, isUicl: true })
  useEffect(() => {
    loadErrorManagerList()
  }, [])

  const selectRow = (e, record) => {
    if (record) {
      // const selectedErrorIdsComponent = Array.from(record.selectedItemIds);
      onRowSelect(e, record);
      const selectedErrorIds = Array.from(record.selectedItemIds);
      // setSelectedErrorIds(selectedErrorIds);
      dispatch(resetErrorIds())
      const filterArray = errorResp.filter(recordData => selectedErrorIds.indexOf(recordData.id) !== -1);
      dispatch(setErrorIds(filterArray));
    }
  }

  const modalClose = (clearSelection) => {
    dispatch(setResolutionModal(false))
    dispatch(setIsAll(false))
    if (clearSelection) {
      dispatch(resetErrorIds())
      onClearAllSelection()
    }
    // setSelectedErrorIds([])
    loadErrorManagerList()
  }

  const renderFirstFilter = () => (
    <div style={{
      width: '150px',
      marginLeft: '5px',
      marginRight: '5px'
    }}>
      <SelectDropdown
        domID="defer_state_input"
        label=""
        options={SELECT_STATUS_RECORD}
        size="small"
        initialValue={status}
        onChange={(e) => setStatus(e)}
      />
    </div>
  );

  const sortingComparator = (sortDataName, sortDirectionString, recordsToSort) => recordsToSort

  const onSortGridColumn = (state) => {
    const { sortingKey } = state;
    const splitKey = sortingKey.split('|');
    let newSortKey = splitKey[0];
    if (newSortKey === 'id') newSortKey = 'errorId'
    else if (newSortKey === 'highest-severity') newSortKey = 'severity'
    getArgumentsAndSortGrid({
      ...state,
      sortingKey: `${newSortKey}|${splitKey[1]}`
    })
  }

  const renderGridContent = useMemo(() => (
    <Grid
      {...ERROR_MANAGER_MAIN_GRID_COLUMN}
      dataTestId="test-gridDefault"
      domID="errorManagerGrid"
      records={errorResp || []}
      selectionKey="id"
      initialSelectedItemIds={new Set(selectedItemIdsCurrentPage)}
      onRowSelect={(e, d) => { selectRow(e, d); }}
      onSelectAll={(e, d) => { selectRow(e, d); }}
      onClickThrough={(e, rec) => {
        e.preventDefault();
        e.stopPropagation();
        if (rec) {
          dispatch(setDetailsId(rec.id))
          if (loadErrorManagerList) dispatch(getErrorListRefetch({ fetchFunc: loadErrorManagerList }))
          setSelectedRecord(rec)
          openDetailPane()
        }
      }}
      // focusedItem={selectedRecord}
      initialSortingKey={`${getSortDataName()}|${sortConfig.sortDescending==="true"?'SORT_DESCENDING':'SORT_ASCENDING'}`}
      onSortGridColumn={(e,state)=>onSortGridColumn(state)}
      supportSelection={!isResolveAllPage}
      sortingComparator={sortingComparator}
    />
  ), [errorResp, selectedItemIdsCurrentPage, sortConfig, isResolveAllPage,selectedRecord ])

  return (
    <>
    <ListPaneHeaderWrapper
        title="Error Manager"
        layoutControls={[expandCollapseListPaneButtonConfig]}
    />
      {isLoading ? (
        // replace with a better loading component
        <LoadingPage />
      ) :  isError ? (
        // replace with a better error component
        <ErrorPage
          // this prop isn't real but is an example
          error={error}
        />
      ) : (
          <>
            <div>
              <div className="mainWrapper">
                <GridPaginationWrapper
                  rowsPerPage={rowsPerPage}
                  onChangeRowsPerPage={setRowsPerPage}
                  pageIndex={pageIndex}
                  onChangePageIndex={setPageIndex}
                  onShowButtonCLick={fetchData}
                  totalRecords={totalRecordsResp}
                  defaultRowsPerPage={10}
                  FirstFilter={renderFirstFilter}
                />
                <div className="displayFlex">
                  {!isResolveAllPage &&
                    <>
                    <Authorizer permission="CAN_PERFORM_ERROR_UPDATE_STATUS">
                      <Button
                        domID="ResolveSelectedButton"
                        name="Resolve Selected"
                        buttonType="standard"
                        onClick={() => {
                          dispatch(setResolutionModal(true))
                          dispatch(setIsAll(false))
                        }}
                        disabled={(selectedItemIds.size === 0)}
                        size="small"
                      />
                      <Button
                        domID="ResolveSelectedButton"
                        name="Clear all"
                        buttonType="standard"
                        onClick={() => {
                          onClearAllSelection()
                        }}
                        style={{ marginLeft: "20px" }}
                        disabled={(selectedItemIds.size === 0)}
                        size="small"
                      />
                      <Button
                        domID="ResolveAllButton"
                        name="Resolve All"
                        buttonType="standard"
                        onClick={() => {
                          dispatch(setResolutionModal(true))
                          dispatch(setIsAll(true))
                        }}
                        style={{ marginLeft: "20px" }}
                        size="small"
                      />
                      </Authorizer>
                    </>}
                </div>
              </div>
              <div className='GridWrapper'>
                {renderGridContent}
              </div>
            </div>
          </>
        )}
      <ResolutionConfirmationModal
        modalClose={modalClose}
        selectedRecords={Array.from(selectedRecords)}
      />
    </>
  )
}

export default withAxiosCancellation(ErrorManagerMain)
