/* eslint-disable camelcase */
export const EFT_ACCT_MAIN_GRID={
  columns: new Set([
    {
      dataName: 'AcctId',
      text: 'Payer EFT Account',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'Onus',
      text: 'ABF Account Id',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'CompName',
      text: 'Company Name',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'payerbank_display',
      text: 'Bank Model',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'status_display',
      text: 'Live',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
  ]),
  selectedItemIds: new Set([]),
  initialSelectedItemIds: new Set([]),
  bookmarkedItemIds: new Set([]),
  domID: '',
  dataTestId: 'payerEftMainGrid',
  isConfigurable: true,
  supportSelection: false
};
export const EFT_ACCT_COMMIT_GRID_SINGLE_COL={
  columns: new Set([
    {
      dataName: 'AcctId',
      text: 'Client Rule Id',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    }
  ]),
  selectedItemIds: new Set([]),
  initialSelectedItemIds: new Set([]),
  bookmarkedItemIds: new Set([]),
  domID: '',
  dataTestId: 'payerEftCommitGridSingleCol',
  isConfigurable: true,
  supportSelection: false
};
export const EFT_ACCT_COMMIT_GRID={
  columns: new Set([
    // {
    //   dataName: 'commit_single_action',
    //   text: 'Action',
    //   sortable: false,
    //   isSorted: 0,
    //   cellType: 'text',
    // },
    {
      dataName: 'AcctId',
      text: 'Client Rule Id',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'Onus',
      text: 'Payer Eft Account',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    }
  ]),
  selectedItemIds: new Set([]),
  initialSelectedItemIds: new Set([]),
  bookmarkedItemIds: new Set([]),
  domID: '',
  dataTestId: 'payerEftCommitGrid',
  isConfigurable: true,
  supportSelection: true
};
export const EFT_CHOOSE_ODFI_GRID={
  columns: new Set([
    {
      dataName: 'odfiId_display',
      text: 'ODFI ID',
      sortable: false,
      isSorted: 0,
      cellType: 'custom',
    },
    {
      dataName: 'immDest',
      text: 'Immediate Destination',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'immDestName',
      text: 'Immediate Destination Name',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'immOrigin',
      text: 'Immediate Origin',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'immOriginName',
      text: 'Immediate Origin Name',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
  ]),
  selectedItemIds: new Set([]),
  initialSelectedItemIds: new Set([]),
  bookmarkedItemIds: new Set([]),
  domID: 'payerEftChooseOdfiGrid',
  dataTestId: 'payerEftChooseOdfiGrid',
  isConfigurable: true,
  supportSelection: false
};

export const status_client_eft_deactivate_warning=`This Payer EFT Account is associated with a Live Group Rule.`

export const error_client_eft_add_failure=`<li>Could not add new Client EFT Account: {0}</li>`

export const status_client_eft_insert_successful=`You have successfully Added Payer EFT Account:{0}`

export const status_client_eft_update_successful=`You have successfully Modified Payer EFT Account:{0}. Please review corresponding Payer EFT Account Eligibility Group Rules and modify as needed.`

export const status_client_eft_copy_successful=`You have successfully copied Payer EFT Account:{0}`

export const error_client_eft_copy_failure=`<li>Could not copy Client EFT Account: {0}</li>`

export const status_client_eft_overwrite_successful=`You have successfully over written Payer EFT Test Account:{0}`

export const error_client_eft_overwrite_failure=`<li>Could not overwrite Client EFT Account: {0}</li>`

export const status_client_eft_deactivate_successful=`You have successfully deactivated Payer EFT Account:{0}`

export const status_client_eft_deactivate_test_successful=`You have successfully deactivated Payer EFT Account (TEST):{0}`

export const error_client_eft_deactivate_failure=`<li>Could not deactivate Client EFT Account: {0}</li>`

export const status_client_eft_commit_all_successful=`You have successfully committed all Payer EFT Accounts_`

export const status_client_eft_commit_single_successful=`You have successfully committed the following Payer EFT Account:{0}`

export const status_client_eft_edit_nochange=`You have not made any changes to Payer EFT Account:{0}`

export const status_client_eft_validate_routeid_failed=`The Routing Code must be numeric:{0}`

export const status_client_eft_validate_effdtdiff_failed=`The Effective Date Difference must be numeric:{0}`

export const status_client_eft_validate_companyid_failed=`The Company Identification must be numeric:{0}`

export const status_client_eft_validate_oridfinid_failed=`The Origination Financial Institution must be numeric:{0}`

export const status_client_eft_validate_holddays_failed=`Hold Days must be numberic:{0}`

export const status_client_eft_validate_fieldList=`The following fields must be populated:{0}`

export const status_client_eft_deactivate_not_live=`There is no live rule to deactivate for Payer EFT Account:{0}`

export const status_client_eft_validate_routeid_checksum_failed=`The Payer Eft Routing Code is invalid. It must be 9 digits in length and must be a valid Routing Code.`

export const error_client_eft_modify_failure=`<li>Could not update Client EFT Account: {0}</li>`

export const error_client_eft_check_group_rule_failure=`<li>Could not verify if Client EFT Account {0} is associated to a live group rule.</li>`

export const error_client_eft_commit_failure=`<li>Either the Account Rules could not be committed or the reprocessing table could not be updated.</li>`