import React, { useState } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import { DetailHeader, Button } from 'ui-core-ssgr';
import searchIcon from '../../../../shared/images/search-icon.png';
import ButtonGroup from '../../../../shared/components/ButtonGroup';
import CloneGroupModal from '../CloneGroupModal';
import { PaddingWrapper } from '../../../../shared/styles/spacing';
import { GroupFilter, SearchButtonImage, GroupDetailRow } from './styles';
import {
  ACTIVE_BUTTON_SET,
  INACTIVE_BUTTON_SET,
  CLONE_BUTTON_SET,
  MULTIPLE_ACTIVE_BUTTON_SET,
  MULTIPLE_INACTIVE_BUTTON_SET,
  MULTIPLE_MIXED_STATUS_BUTTON_SET,
  headerProps,
} from './constants';
import ActivateGroupModal from '../ActivateGroupModal';
import DeactivateGroupModal from '../DeactivateGroupModal';

const GroupGridHeader = props => {
  const {
    setIsShowingActivateGroupModal,
    setIsShowingDeactivateGroupModal,
    selectedItemIds,
    resetRuleTypesListReceived,
    setIsShowingCloneGroupModal,
    setSelectedDetailsRecordId,
    selectedRecord,
    counterLabel,
    headerName,
    numActive,
    numInactive,
    groupGridQueryUpdate,
    onCancel,
    setDefaultGroupDetailsTab,
    ruleTypesList,
    isEditRole
  } = props;
  const [queryValue, setQueryValue] = useState('');
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const activateGroup = () => {
    setIsShowingActivateGroupModal(true);
  };

  const deactivateGroup = () => {
    setIsShowingDeactivateGroupModal({
      isShowingDeactivateGroupModal: true,
      isMulti: true,
      selectedGroupIds: selectedItemIds,
    });
    resetRuleTypesListReceived();
  };


  const cloneGroup = () => {
    if (selectedRecord && !selectedItemIds.has(selectedRecord.id)) {
      const id = [...selectedItemIds][0];
      setSelectedDetailsRecordId({ id });
    }
    setIsShowingCloneGroupModal(true);
    resetRuleTypesListReceived();
  };

  const onHeaderButtonClick = name => {
    if (name === 'clone') cloneGroup();
    if (name === 'activate') activateGroup();
    if (name === 'deactivate') deactivateGroup();
  };

  const hasActiveOnly = numInactive === 0 && numActive === 1;
  const hasInactiveOnly = numInactive === 1 && numActive === 0;
  const hasActiveAndInactive = numInactive > 0 && numActive > 0;

  let additionalButtons = [];

  if (hasActiveOnly || hasInactiveOnly) {
    if(isEditRole === false){
      additionalButtons = hasActiveOnly
        ? ACTIVE_BUTTON_SET 
        : INACTIVE_BUTTON_SET;
    }else {
      additionalButtons = CLONE_BUTTON_SET;
    }
  } else if (numActive > 1 && !hasActiveAndInactive && isEditRole === false) {
    additionalButtons = MULTIPLE_ACTIVE_BUTTON_SET;
  } else if (numInactive > 1 && !hasActiveAndInactive && isEditRole === false) {
    additionalButtons = MULTIPLE_INACTIVE_BUTTON_SET;
  } else if (hasActiveAndInactive && isEditRole === false) {
    additionalButtons = MULTIPLE_MIXED_STATUS_BUTTON_SET;
  }
 
  const userSearchWithinGroups = () => {
    const checkValue = /^[a-zA-Z0-9-_ ]*$/;
    if (queryValue.length === 0) {
      groupGridQueryUpdate();
    }
    if (checkValue.test(queryValue) && queryValue.length >= 3) {
      setHasError(false);
      groupGridQueryUpdate(queryValue);
    } else {
      setHasError(true);
      setErrorMessage('Please enter at least three valid characters - alphanumeric, space, - or _ is allowed. ');
    }
  };

  return (
    <PaddingWrapper>
      <DetailHeader
        {...headerProps}
        title={headerName}
        amountKey="amount"
        domID="header"
        count={counterLabel}
        marginBottom={12}
      />
      <GroupDetailRow>
        <ButtonGroup>
          {/* Enable POST BETA <Button
            name="add a group"
            buttonType="emphasized"
            onClick={() => onHeaderButtonClick('add')}
            size="medium"
            type="button"
          /> */}
          {map(additionalButtons, name => (
            <Button
              name={name}
              key={name}
              buttonType="standard"
              onClick={() => onHeaderButtonClick(name)}
              size="medium"
              type="button"
            />
          ))}
        </ButtonGroup>
        <CloneGroupModal {...props} />
        <ActivateGroupModal
          {...props}
          onCancel={onCancel}
          selectedRecord={selectedRecord}
          setDefaultGroupDetailsTab={setDefaultGroupDetailsTab}
          ruleTypesList={ruleTypesList}
        />
        <DeactivateGroupModal {...props} />
        <GroupFilter
          domID="group-filter"
          onEnterPress={userSearchWithinGroups}
          onButtonClick={userSearchWithinGroups}
          onChange={(event, { value }) => setQueryValue(value)}
          placeholder="Search by name or description"
          hasError={hasError}
          errorMessage={hasError ? errorMessage : null}
          buttonContent={<SearchButtonImage alt="search" src={searchIcon} />}
        />
      </GroupDetailRow>
    </PaddingWrapper>
  );
};

GroupGridHeader.defaultProps = {
  recordCount: 0,
};

GroupGridHeader.propTypes = {
  numActive: PropTypes.number.isRequired,
  numInactive: PropTypes.number.isRequired,
  recordCount: PropTypes.number,
  headerName: PropTypes.string,
  groupGridQueryUpdate: PropTypes.func,
  hasError: PropTypes.bool,
  loading: PropTypes.object.isRequired,
  selectedRecord: PropTypes.object,
  selectedItemIds: PropTypes.object.isRequired,
  setIsShowingActivateGroupModal: PropTypes.func,
  setSelectedDetailsRecordId: PropTypes.func.isRequired,
  showCloneGroupModal: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  recordCountByStatusAndKeywordTriggerDB: PropTypes.func,
  setIsShowingCloneGroupModal: PropTypes.func.isRequired,
  setIsShowingDeactivateGroupModal: PropTypes.func.isRequired,
  resetRuleTypesListReceived: PropTypes.func,
  onCancel: PropTypes.func,
  setDefaultGroupDetailsTab: PropTypes.func,
  ruleTypesList: PropTypes.object,
  counterLabel: PropTypes.string,
};

export default GroupGridHeader;
