import React from 'react';
import ListPaneHeader from 'ui-core/dist/ListPaneHeader';
import ErrorDisplaySection from './ErrorDisplaySection';

const ListPaneHeaderWrapper=({title, layoutControls})=>(
  <>
    <ListPaneHeader
      title={title}
      layoutControls={layoutControls}
    />
    <ErrorDisplaySection/>
  </>
)

export default ListPaneHeaderWrapper