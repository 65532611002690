import { connect } from 'react-redux';
import DetailsWrapper from './components/DetailsWrapper';
import {
  deleteNotification,
  deleteAllNotifications,
  addNotification,
} from '../../redux/app/notificationState/actions';

import {
  selectCurrentTab,
  selectCurrentDetailsRecord,
  selectSelectedRuleRecord,
  selectSelectedRuleRecordStatus,
  selectIsViewingRuleDetails,
  selectRuleDetailsList,
  selectNotificationState,
  selectLoadingState,
  selectNotificationStateErrors,
  selectGridConfig
} from './selectors';
import {
  setIsViewingRuleDetails,
  overwriteTestRuleRequestTrigger,
  setIsShowingOverwriteModal,
  setSelectedDetailsRecordId,
  setIsShowingDetailCloneRuleModal,
  triggerRuleDetailsRequest,
  resetRuleTypesListReceived,
  triggerRuleTypesRequest
} from '../../redux/api/ruleTypesList/actions';
import {
  captureCurrentPage,
} from '../../redux/api/pageTracker/actions';
import { selectGroupCloningState } from '../BasicTabDetailsView/selectors';
import {
  triggerAllRulesForRuleTypesRequest,
  updateCurrentTab,
} from '../../redux/api/rulesForRuleTypesList/actions';
import {
  setIsCloningGroupState,
  setIsShowingActivateGroupModal,
  setIsShowingDeactivateGroupModal,
  setIsShowingCloneGroupModal,
} from '../../redux/api/groups/actions';
import {
  setDefaultGroupDetailsTab,
  setAdvancedGroupDetailsTab,
  resetDetailsPane,
  setDetailsPaneToInitialStateForClone,
} from '../../redux/app/detailsPaneState/actions';
import {
  resetRuleDetailElements,
  setSelectedRuleDetail,
  setIsReadOnlyRuleDetail,
  setIsShowingCustomerServiceMessageModal,
  setIsShowingCheckTextRuleModal,
  setIsShowingErisaMessageModal,
  setIsShowingSignatureRuleModal,
  setIsShowingImageRuleModal,
  setIsShowingReturnAddressModal,
  setIsShowingProductionArrangementModal,
  setIsShowingTaxIdArrangementRuleModal,
  setShowingHighDollarReviewModal,
  setIsShowingMemberIdModal,
  setIsShowingGeneralMessageModal,
  setIsShowingProviderInsuredOptionMessageModal,
  setIsShowingAutoHoldModal,
  setIsShowingDuplexRuleModal,
  setIsShowingPDFTaggingModal
} from '../../redux/api/ruleDetails/actions';
import { selectRenderSafeRecords } from '../RulesTabView/components/LiveRuleTab/LiveGridWrapper/selectors';
import {
  selectIsEditingRuleDetail,
  selectIsReadOnlyRuleDetail,
  selectIsShowingRevertRuleModal,
  selectRevertRuleVersionsList,
  selectUserRole,
  selectUserPermissions,
  selectUserType,
  selectUserRoleId
} from '../RulesTabView/selectors';
import {
  setRuleTypeCommitModalOpen,
  triggerRulesForRuleTypesPostRequest,
  triggerRulesForRuleTypesPutRequest,
  triggerCommitRuleForRuleType,
  setIsCloningRuleState,
  setIsShowingActiveGroupsListingModal,
  setIsShowingRevertRuleModal,
  getRevertRuleVersions,
  postRevertRuleVersion
} from '../../redux/api/rulesForRuleTypes/actions';

// state
const mapStateToProps = state => ({
  ...state,
  ...state.rulesForRuleTypesList,
  ...state.rulesForRuleTypes,
  gridConfig: selectGridConfig(state),
  loading: selectLoadingState(state),
  ruleTypesList: state.ruleTypesList.records,
  rulesForRuleTypesList: state.rulesForRuleTypesList.records,
  records: selectRenderSafeRecords(state),
  currentTab: selectCurrentTab(state),
  selectedRuleRecord: selectSelectedRuleRecord(state),
  selectedRuleRecordStatus: selectSelectedRuleRecordStatus(state),
  selectedRecord: selectCurrentDetailsRecord(state),
  selectedRuleTypeId: state.ruleTypesList.selectedRuleTypeId,
  isAdvancedViewShown: selectIsViewingRuleDetails(state),
  isCloningGroup: selectGroupCloningState(state),
  isEditingRuleDetail: selectIsEditingRuleDetail(state),
  isReadOnlyRuleDetail: selectIsReadOnlyRuleDetail(state),
  ruleDetails: selectRuleDetailsList(state),
  detailsPaneState: state.detailsPaneState,
  notifications: selectNotificationState(state),
  errorNotifications: selectNotificationStateErrors(state),
  isShowingOverwriteModal: state.ruleTypesList.isShowingOverwriteModal,
  ruleTypeCommitRuleModalOpen:
    state.rulesForRuleTypes.ruleTypeCommitRuleModalOpen,
  showDetailCloneRuleModal: state.ruleTypesList.showDetailCloneRuleModal,
  selectedActiveRecordsList: state.rulesForRuleTypes.selectedActiveRecordsList,
  selectedRecordName: state.rulesForRuleTypes.selectedRecordName,
  activeGroupsModalLocation: state.rulesForRuleTypes.activeGroupsModalLocation,
  revertRuleVersionsList: selectRevertRuleVersionsList(state),
  isShowingRevertRuleModal: selectIsShowingRevertRuleModal(state),
  userRole: selectUserRole(state),
  userPermissions: selectUserPermissions(state),
  userType: selectUserType(state),
  userRoleId: selectUserRoleId(state)
});

// actions
const mapDispatchToProps = dispatch => ({
  deleteAllNotifications: () => dispatch(deleteAllNotifications()),
  deleteNotification: id => dispatch(deleteNotification(id)),
  addNotification: id => dispatch(addNotification(id)),
  resetRuleDetailElements: () => dispatch(resetRuleDetailElements()),
  setIsViewingRuleDetails: payload =>
    dispatch(setIsViewingRuleDetails(payload)),
  overwriteTestRuleRequestTrigger: payload =>
    dispatch(overwriteTestRuleRequestTrigger(payload)),
  setDefaultGroupDetailsTab: payload =>
    dispatch(setDefaultGroupDetailsTab(payload)),
  setAdvancedGroupDetailsTab: payload =>
    dispatch(setAdvancedGroupDetailsTab(payload)),
  setIsCloningGroupState: bool => dispatch(setIsCloningGroupState(bool)),
  setIsShowingActivateGroupModal: bool =>
    dispatch(setIsShowingActivateGroupModal(bool)),
  setIsShowingDeactivateGroupModal: bool =>
    dispatch(setIsShowingDeactivateGroupModal(bool)),
  setSelectedRuleDetail: record => dispatch(setSelectedRuleDetail(record)),
  triggerAllRulesForRuleTypesRequest: (...args) =>
    dispatch(triggerAllRulesForRuleTypesRequest(...args)),
  triggerRulesForRuleTypesPostRequest: (...args) =>
    dispatch(triggerRulesForRuleTypesPostRequest(...args)),
  triggerRulesForRuleTypesPutRequest: payload =>
    dispatch(triggerRulesForRuleTypesPutRequest(payload)),
  updateCurrentTab: tab => dispatch(updateCurrentTab(tab)),
  setIsShowingOverwriteModal: bool =>
    dispatch(setIsShowingOverwriteModal(bool)),
  setRuleTypeCommitModalOpen: bool =>
    dispatch(setRuleTypeCommitModalOpen(bool)),
  triggerCommitRuleForRuleType: ruleIdArray =>
    dispatch(triggerCommitRuleForRuleType(ruleIdArray)),
  setIsShowingCloneGroupModal: bool =>
    dispatch(setIsShowingCloneGroupModal(bool)),
  setSelectedDetailsRecordId: payload =>
    dispatch(setSelectedDetailsRecordId(payload)),
  setIsShowingDetailCloneRuleModal: bool =>
    dispatch(setIsShowingDetailCloneRuleModal(bool)),
  setIsCloningRuleState: payload => dispatch(setIsCloningRuleState(payload)),
  resetDetailsPane: () => dispatch(resetDetailsPane()),
  setDetailsPaneToInitialStateForClone: payload =>
    dispatch(setDetailsPaneToInitialStateForClone(payload)),
  triggerRuleDetailsRequest: (...args) =>
    dispatch(triggerRuleDetailsRequest(...args)),
  resetRuleTypesListReceived: payload =>
    dispatch(resetRuleTypesListReceived(payload)),
  setIsShowingActiveGroupsListingModal: payload =>
    dispatch(setIsShowingActiveGroupsListingModal(payload)),
  triggerRuleTypesRequest: payload =>  
    dispatch(triggerRuleTypesRequest(payload)),
  setIsReadOnlyRuleDetail: payload =>
    dispatch(setIsReadOnlyRuleDetail(payload)),
  setIsShowingCustomerServiceMessageModal: payload =>
    dispatch(setIsShowingCustomerServiceMessageModal(payload)),
  setIsShowingCheckTextRuleModal: payload =>
    dispatch(setIsShowingCheckTextRuleModal(payload)),
  setIsShowingErisaMessageModal: payload =>
    dispatch(setIsShowingErisaMessageModal(payload)),
  setIsShowingSignatureRuleModal: payload =>
    dispatch(setIsShowingSignatureRuleModal(payload)),    
  setIsShowingImageRuleModal: payload =>
    dispatch(setIsShowingImageRuleModal(payload)),
  setIsShowingReturnAddressModal: payload =>
    dispatch(setIsShowingReturnAddressModal(payload)),
  setIsShowingTaxIdArrangementModal: payload =>
    dispatch(setIsShowingTaxIdArrangementRuleModal(payload)),
  setShowingHighDollarReviewModal: payload =>
    dispatch(setShowingHighDollarReviewModal(payload)),
  setIsShowingMemberIdModal: payload =>
    dispatch(setIsShowingMemberIdModal(payload)),
  setIsShowingProductionArrangementModal: payload =>
    dispatch(setIsShowingProductionArrangementModal(payload)),
  setIsShowingGeneralMessageModal: payload =>
    dispatch(setIsShowingGeneralMessageModal(payload)),
  setIsShowingProviderInsuredOptionMessageModal: payload =>
    dispatch(setIsShowingProviderInsuredOptionMessageModal(payload)),
  setIsShowingAutoHoldModal: payload =>
    dispatch(setIsShowingAutoHoldModal(payload)),
  setIsShowingDuplexRuleModal: payload =>
    dispatch(setIsShowingDuplexRuleModal(payload)),
  setIsShowingPDFTaggingModal: payload =>
    dispatch(setIsShowingPDFTaggingModal(payload)),
  captureCurrentPage: payload => 
    dispatch(captureCurrentPage(payload)),
  setIsShowingRevertRuleModal: payload => 
    dispatch(setIsShowingRevertRuleModal(payload)),
  getRevertRuleVersions: payload => 
    dispatch(getRevertRuleVersions(payload)),
  postRevertRuleVersion: payload => 
    dispatch(postRevertRuleVersion(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DetailsWrapper);
