import React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import { Page } from '../components';

const CCFRoutes = () => (
  <Switch>
    <Route path="/CCFManager/835map_manager" component={Page} />
    <Route path="/CCFManager/form_manager" component={Page} />
    <Route path="/CCFManager/image_manager" component={Page} />
    <Route exact path="/CCFManager">
      <Redirect to="/CCFManager/835map_manager" />
    </Route>
  </Switch>
)

export default CCFRoutes;