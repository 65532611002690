import React from 'react';
import './index.css';

const LoggedOutFooter = () => (
  <div className="login__footer">
    <div className="login__links">
      <span>© 2024 Change Healthcare LLC and/or one of its subsidiaries.</span>
      <a 
        target="_blank"
        href="https://www.changehealthcare.com/privacy-notice"
        rel="noopener noreferrer"
      >
            Privacy Policy
      </a>
      <a 
        target="_blank"
        href="https://www.changehealthcare.com/terms-of-use"
        rel="noopener noreferrer"
      >
            Terms and Conditions
      </a>
    </div>
    <span>You are not Logged In</span>
  </div>
)

export default LoggedOutFooter;