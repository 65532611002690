/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import Warning from 'ui-core/dist/Warning';
import { useLayout, Button } from 'ui-core/dist'
import { LoadingPage, CountDownTimer } from '../../../components';
import ErrorPage from '../../ErrorPage';
import {
  GENERIC_TEXT1,
  GENERIC_TEXT2,
  GENERIC_TEXT_PURGE,
  GENERIC_WARNING_TEXT_PURGE,
} from "../Modules/constants";
import withAxiosCancellation from '../../../components/withAxiosCancellation'
import ListPaneHeaderWrapper from '../../../components/ErrorDisplaySection/ListPaneHeaderWrapper';

import '../Modules/styles.scss';
import DocManagerFileGroupSearchForm from '../DocManagerFileGroupSearchForm';
import { useCurrentTime, useReleaseTime } from '../../../api/useDocumentManager';
import { setFileSearchParam, setFileSearchParamNull } from "../Modules/action";


const FileSearchPurgeMain = ({getCancelSourceWithName}) => {
  const {
    expandCollapseListPaneButtonConfig
  } = useLayout()
  const dispatch = useDispatch();
  const history = useHistory();
  const onSelectFileKey = (value) => {
  }
  const [isResetFlag, setIsResetFlag] = useState(false);
  const [empGroupId, setEmpGroupId] = useState('')
  const [claimNumber1, setClaimNo] = useState('')
  const [checkNumber1, setCheckNo] = useState('')
  const [fileKey, setFileKeys] = useState('')
  const [tinSSN, setTinSsn] = useState('')
  const [sortOrder, setSortOrder] = useState({ label: 'Descending', value: true })
  const [sortColumn, setSortColumn] = useState({ label: 'File Key', value: 'filekey' })
  const [perPage, setchangeItemsPerPage] = useState({ label: 10, value: 10 })
  const [hasLimitExceededstate, sethasLimitExceededstate] = useState(false)
  const { currentTime, isError: isErrorCurrentTime, error: errorCurrentTime, isLoading: isLoadingCurrentTime } = useCurrentTime({getCancelSource:getCancelSourceWithName('searchPurgeFilePageCurrentTime')});
  const { releaseTime, isError: isErrorReleaseTime, error: errorReleaseTime, isLoading: isLoadingReleaseTime } = useReleaseTime({getCancelSource:getCancelSourceWithName('searchPurgeFilePageReleaseTime')});
  const [transactionMessage, settransactionMessage]=useState('')

  useEffect(()=>{
    if (history?.location && history?.location?.state) {
      settransactionMessage(history?.location?.state);
      history.replace({ ...history?.location, state: undefined });
    }
  },[history?.location])
  const onSelectEmpGroup = (items) => {
    let egroup = '';
    if (hasLimitExceededstate) {
      egroup = items && items.value ? items.value : ""
    } else if (items && items.length > 0 && items.indexOf('') === -1) {
      egroup = '<![CDATA[<EmpGroupIds>'
      items.forEach(item => {
        egroup += `<EmpGroupId>${item}</EmpGroupId>`
      })
      egroup += '</EmpGroupIds>]]>'
    }
    setEmpGroupId(egroup);
  }

  const nextClick = () => {
    const payload = {
      "maxRecords": perPage ? perPage.value : 10,
      "rowPosition": 0,
      "sortField": sortColumn ? sortColumn.value : '',
      "sortDescending": sortOrder ? sortOrder.value : true,
      "checkNumber": checkNumber1,
      "claimNumber": claimNumber1.replace(/['"]/g, ''),
      "groupId": empGroupId ? empGroupId.value ? empGroupId.value : empGroupId : '',
      "idNumber": tinSSN,
      "filekey": fileKey,
      "showDuplicateChecks": true,
      "showProcessedDocuments": false
    }
    dispatch(setFileSearchParamNull(payload))
    dispatch(setFileSearchParam(payload))
    history.push(`/DocumentManager/Purge/File/searchResults`)
  }

  const commonDocManagerForm = useMemo(() => (
    <DocManagerFileGroupSearchForm
    getCancelSourceWithName={getCancelSourceWithName}
      isCheckNumberExist
      isClaimNumberExist
      isSSNExist
      onSelectEmpGroup={(value) => onSelectEmpGroup(value)}
      onSelectFileKey={(value) => onSelectFileKey(value)}
      isSortingExist
      hasLimitExceeded={(value) => sethasLimitExceededstate(value)}
      isResetFlag={isResetFlag}
      setIsResetFlag={setIsResetFlag}
      changeClaimNo={(value) => setClaimNo(value)}
      changeCheckNo={(value) => setCheckNo(value)}
      changeFileKey={(value) => setFileKeys(value)}
      changeTinSsn={(value) => setTinSsn(value)}
      changeSortGridColumn={(v) => setSortColumn(v)}
      changeItemsPerPage={(v) => setchangeItemsPerPage(v)}
      perPage={perPage}
      changeSortGridOrder={(v) => setSortOrder(v)}
      tinSSN={tinSSN}
      claimNumber1={claimNumber1}
      CheckNumber1={checkNumber1}
      nextClick={nextClick}
    />
  ), [isResetFlag, perPage, tinSSN, claimNumber1, checkNumber1, hasLimitExceededstate, fileKey, sortColumn, sortOrder, empGroupId])

  const isLoading = false;
  const isError = false;
  const error = '';

  return (
    <>
      <ListPaneHeaderWrapper
        title="Document Manager: Purge Files"
        layoutControls={[expandCollapseListPaneButtonConfig]}

      />
      {isLoadingCurrentTime || isLoadingReleaseTime ? (
        // replace with a better loading component
        <LoadingPage />
      ) : isErrorCurrentTime || isErrorReleaseTime ? (
        // replace with a better error component
        <ErrorPage
          // this prop isn't real but is an example
          error={errorCurrentTime || errorReleaseTime}
        />
      ) : (
            <div className="mainWrapper">
              {releaseTime && currentTime ?
                <CountDownTimer
                  startDate={releaseTime}
                  endDate={currentTime}
                /> : ''}
              <div style={{ fontSize: "small", padding: "12px" }}>
                <i>{GENERIC_TEXT1}</i>
                <p>Type a partial or complete claim number, check number, TIN, or SSN to find the files that contain documents matching your search option. 
                  You can also search for all files that contain a specific group or you can select one or more files. 
                  Click Next to display the search results page where you can select the files you want to purge.</p>
                <div style={{ color: "#bf1616" }}>
                  <p>
                    {GENERIC_TEXT_PURGE}
                  </p>
                  <p>
                    <Warning
                      className="test"
                      fillColor="#bf1616"
                      title="hover for title"
                      width={75}
                    />
                    <span style={{ paddingLeft: "5px" }}>
                      {GENERIC_WARNING_TEXT_PURGE}
                    </span>
                  </p>
                  {transactionMessage && <p style={{ color: "black", fontWeight:'bold' }}>{transactionMessage}</p>}
                </div>
              </div>
              {commonDocManagerForm}
              <div className="displayFlex marginLeft">
                <Button
                  buttonType="standard"
                  size="small"
                  name="RESET"
                  onClick={() => {
                    setIsResetFlag(true);
                  }}
                />
                <Button
                  buttonType="standard"
                  size="small"
                  style={{ marginLeft: '10px' }}
                  name="NEXT->"
                  onClick={() => nextClick()}
                />
              </div>
            </div>
          )}
    </>
  )
}

export default withAxiosCancellation(FileSearchPurgeMain)
