import styled from 'styled-components';
import { withClickAway } from './withClickAway';

const ModalContent = styled.div`
  position: relative;
  width: 800px;
  margin: 5em auto;
  background-color: #FFF;
  box-shadow: 0 15px 45px 15px rgba(0, 0, 0, .15);
  border-radius: 3px;
  padding: 1rem 1.5rem;
  transform-style: preserve-3d;
  transition: transform 300ms;
  transform: translateX(0px) translateY(0px) translateZ(0px);

  &.active-content {
    transform: translateX(0px) translateY(15px) translateZ(0px);
  }

  .modal-close {
    position: absolute;
    display: inline-block;
    top: 1.5rem;
    right: 1.5rem;
  }
`;

export default withClickAway(ModalContent);
