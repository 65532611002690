import Api from '..';
import { API_BASE } from '../constants';

// ---- Get groups and Get groups from Search Bar ----------------
export const getGroupsListByStatus = async (
  { clientLayoutId, userSecurityTokenKey, username, roleId },
  {
    sortOrder = 'desc',
    name = 'dateModified',
    offset = 0,
    status = 'all',
    queryValue = 0,
  },
) => {
  try {
    // By default fetch will use the GET method, but it supports all REST verbs.
    // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
    let baseUrl = `${API_BASE}/groups/${clientLayoutId}`;

    if (queryValue) {
      baseUrl = `${baseUrl}?keyword=${queryValue}`;
      if(offset > 0){
        baseUrl = `${baseUrl}&offset=${offset}`;
      }
    } else {
      baseUrl = `${baseUrl}?sortColumn=${name}&sortOrder=${sortOrder}&offset=${offset}`;
    }

    baseUrl = `${baseUrl}&status=${status}`;

    // TODO: change this for release
    // `http://localhost:4000/groups/`,
    const api = new Api(userSecurityTokenKey, username, null, roleId);
    const getGroupsListResponse = await api.get(baseUrl);

    if (getGroupsListResponse.error) {
      throw getGroupsListResponse.error;
    }
    const groups = getGroupsListResponse.groups.map(group =>
      JSON.parse(JSON.stringify(group, replacer)),
    );
    return {
      ...getGroupsListResponse,
      groups,
    };
  } catch (error) {
    throw error;
  }
};

// -------- Request for the total count from the DB --------------
export const getRecordCountFromDB = async (
  { clientLayoutId, userSecurityTokenKey, username, roleId },
  { sortOrder = 'desc', name = 'dateModified', offset = 0, status = 'all' },
) => {
  try {
    const baseUrl = `${API_BASE}/groups/${clientLayoutId}/count?sortOrder=${sortOrder}&sortColumn=${name}&offset=${offset}&status=${status}`;
    const api = new Api(userSecurityTokenKey, username, null, roleId);
    const getRecordCountFromDBResponse = await api.get(baseUrl);

    if (getRecordCountFromDBResponse.error) {
      throw getRecordCountFromDBResponse.error;
    } else {
      return getRecordCountFromDBResponse;
    }
  } catch (error) {
    throw error;
  }
}

// --------Groups Query With Count from DB ----------

export const getGroupsListByStatusAndKeywordCountFromDB = async (
  { clientLayoutId, userSecurityTokenKey, username, roleId },
  {
    sortOrder = 'desc',
    name = 'dateModified',
    offset = 0,
    queryValue = 0,
    status = 'all',
  },
) => {
  try {
    let baseUrl = `${API_BASE}/groups/${clientLayoutId}/count`;

    if (queryValue) {
      baseUrl = `${baseUrl}?keyword=${queryValue}`;
      if(offset > 0){
        baseUrl = `${baseUrl}&offset=${offset}`;
      }
    } else {
      baseUrl = `${baseUrl}?sortColumn=${name}&sortOrder=${sortOrder}&offset=${offset}`;
    }
  
    baseUrl = `${baseUrl}&status=${status}`;
  
    const api = new Api(userSecurityTokenKey, username, null, roleId);
    const getGroupsListByStatusAndKeywordCountFromDBResponse = await api.get(baseUrl);
  
    if (getGroupsListByStatusAndKeywordCountFromDBResponse.error) {
      throw getGroupsListByStatusAndKeywordCountFromDBResponse.error;
    }
    return getGroupsListByStatusAndKeywordCountFromDBResponse;
  } catch (error) {
    throw error;
  }
}

/**
 * Replacer function to handle the nulls and empty strings in the json
 *
 * */
const replacer = (name, val) => {
  if (!val) {
    return '';
  }
  if (Array.isArray(val)) {
    return val.filter(valE => valE !== null);
  }
  if (name === 'status') {
    return `${val.charAt(0).toUpperCase()}${val.slice(1)}`;
  }
  return val;
};

export default {
  getGroupsListByStatus,
  getGroupsListByStatusAndKeywordCountFromDB,
};
