import React, {useState,useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDashbordProductMetrics } from '../../api/useDashboard';
import {  LoadingPage} from '../../components';
import withAxiosCancellation from '../../components/withAxiosCancellation'

const ProductMetrics = ({getCancelSourceWithName}) => {
  const {  dataSourceDesc } = useSelector(({ context }) => context?.context || {});
  const clientId = dataSourceDesc.split('-')[0].trim().replace('_','');
  const { data:productMetricsData, isLoading:pIsLoading } = useDashbordProductMetrics(clientId, getCancelSourceWithName('productMetricsData'));
 
  return (
    <>
      <div>
        <h3 className="paymentHeader">Product Output Metrics</h3>
        <div  className="paymentBody">
          {
              pIsLoading ? (<LoadingPage height='10vh'/>) :  ( 
              <>
                <p>
                <div className="alignPaymentItems paymentItems">
                <div className="paymentleftItem">DOCUMENTS ON HOLD</div>
      
                <div className="paymentRightItem" style={{color:'#FFC107'}}>
                  {  productMetricsData?.numHolds }
                 </div>
                </div>
              </p>
              <p>
                <div className="alignPaymentItems paymentItems">
                <div className="paymentleftItem">DOCUMENTS PROCESSED LAST 30 DAYS</div>
      
                <div className="paymentRightItem">{productMetricsData?.numDocumentsProcessed}</div>
                </div>
              </p>
      
              <p>
                <div className="alignPaymentItems paymentItems">
                <div className="paymentleftItem">ACTIVE USERS ON SYSTEM</div>
      
                <div className="paymentRightItem">{productMetricsData?.activeUsers}</div>
                </div>
              </p>
                </>
              )
          }
         
      </div>
      </div>
    </>
  )
}

export default withAxiosCancellation(ProductMetrics)