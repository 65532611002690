import styled from 'styled-components';
import { WordTag, DetailHeader } from 'ui-core-ssgr';
import ButtonGroup from '../components/ButtonGroup';

export const DefaultLengthWordTag = styled(WordTag)`
  width: 60px;
  text-align: center;
`;

export const WordTagWidthSmall = styled(WordTag)`
  text-align: center;
  display: inherit;
  margin-right: 1px;
`;

export const GrayedOutWordTagSmall = styled(WordTagWidthSmall)`
  color: #fff;
  background-color: #e1e2e6;
`;

export const TruncatedColumn = styled.td`
  & div.tooltip-wrapper > div > div {
    display: inline;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  & div.tooltip-wrapper > div {
    min-width: 100px;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  & .tooltip {
    white-space: normal;
  }
`;

export const TruncatedColumnMainGrids = styled.td`
    min-width: 100px;
    max-width: 400px;
    word-break: break-all;
    word-wrap: break-word;
`;

export const StyledSearchButtonImage = styled.img`
  width: 1.25em;
  height: 1.25em;
  margin-right: 1em;
`;

export const ButtonGroupBottom = styled(ButtonGroup)`
  position: relative;
  margin: 3em 0;
`;

export const RightSideButtons = styled(ButtonGroup)`
  float: right;
  margin-right: -1em;
  margin-top: -3em;
`;

export const CustomActiveColumn = styled.td`
  & div.tooltip-wrapper > div {
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  & .tooltip {
    white-space: normal;
  }
`;

export const CustomInactiveColumn = styled.td`
  & div.tooltip-wrapper > div {
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  color: red;
  & .tooltip {
    white-space: normal;
  }
`;
export const TabsWrap = styled.div`
  & > div > span > button.sc-bdVaJa#edit-test-tab,
  & > div > span > button.sc-bdVaJa#live-rule-tab,
  & > div > span > button.sc-bdVaJa#associate-tab {
    @media only screen and (max-width: 900px) {
      width: 6.9em;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  & > div > span > button.sc-bdVaJa#basic-tab,
  & > div > span > button.sc-bdVaJa#test-assoc-tab,
  & > div > span > button.sc-bdVaJa#commit-tab,
  & > div > span > button.sc-bdVaJa#live-assoc-tab {
    @media only screen and (max-width: 1000px) {
      width: 5em;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  & > #tabsWrap > span > button > div {
    width: 3px;
    right: 0px;
  }
  @media (max-width: 1366px) {
    & > #tabsWrap > span > button > div {
      width: 3px;
      right: 0px;
    }
  }
  @media (max-width: 1280px) {
    & > #tabsWrap > span > button > div {
      width: 3px;
      right: 0px;
    }
  }
  @media (max-width: 1440px) {
    & > #tabsWrap > span > button > div {
      width: 3px;
      right: 0px;
    }
  }
  @media (max-width: 1680px) {
    & > #tabsWrap > span > button > div {
      width: 3px;
      right: 0px;
    }
  }
  @media (max-width: 1920px) {
    & > #tabsWrap > span > button > div {
      width: 3px;
      right: 0px;
    }
  }
  @media (max-width: 1600px) {
    & > #tabsWrap > span > button > div {
      width: 3px;
      right: 0px;
    }
  }
`;

export const ModalButton = styled.div`
  margin-top: 3em;
  margin-bottom: 1em;
  &.button:last-child {
    margin-left: 30px;
`;

export const LoadingContainer = styled.div`
  margin-bottom: 100px;
  position: relative;
`;

export const GridLoadingWrapper = styled.div`
  left: 48%;
  position: absolute;
`;

export const ModalPaddingWrapper = styled.div`
  padding: 0 10px 10px 10px;
`;

export const ModalContentPaddingWrapper = styled.div`
  padding: 0 0 20px 0;
  font-size: 14px;
`;

export const BoldTextAfterWarningIcon = styled(ModalContentPaddingWrapper)`
  font-weight: bold;
`;

export const IconWrapper = styled.div`
  float: left;
`;

export const ModalTitlePaddingWrapper = styled.div`
  padding: 8px 0 20px 0;
  & > h1 {
    white-space: pre-wrap; 
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word
    margin-right: 1em;
  }
`;

export const StyledHeader = styled.h1`
  display: inline-block;
  margin: 0 0.6rem 0 0;
  padding: 0 0.6rem 0 0;
  color: #37474f;
  font-size: 30px;
  line-height: 1.3em;
  font-weight: 400;
  white-space: inherit;
  overflow: auto;
  word-break: break-all;
  word-wrap: break-word;
`;

export const StyledDetailHeader = styled(DetailHeader)`
  & > div > div > div {
    display: inline-block;
  }
  & > div > div > div > h1 {
    display: inline-block;
    padding: .2em 1em 0 0;
    color: #37474f;
    font-size: 30px;
    line-height: 1.3em;
    font-weight: 400;
    white-space: inherit;
    overflow: auto;
    word-break: break-all;
    word-wrap: break-word;
  }
`;

export const ReadOnlyTextTitle = styled.div`
  display: block;
  min-width: 20em;
  margin: 0.5em 1em 0 0;
  line-height: 2em;
  color: #37474F;
  font-size: 11px;
  font-weight: 600;
  -webkit-letter-spacing: 0.05em;
  -moz-letter-spacing: 0.05em;
  -ms-letter-spacing: 0.05em;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  white-space: pre-wrap;
  text-align: left;
  word-break: break-all;
  word-wrap: break-word;
`;

export const ReadOnlyTextArea = styled(ReadOnlyTextTitle)`
  font-weight: normal;
  text-transform: none;
`;
