import React from 'react'
import { useSelector } from 'react-redux'
import { Page } from '../../components'
import Reserve from './Reserve';
import DetailPane from './ReserveModifyDetail';
import AddFormDetail from './AddFormDetail';

const ReserveForm = () => {
    const isAddForm = useSelector(store => store.formManager.isAddForm)
    return (<Page
        ListPaneContent={Reserve}
        DetailPaneContent={isAddForm ? AddFormDetail : DetailPane}
    />)
}
export default ReserveForm