import {
  DOCUMENT_SEARCH,
  SET_SELECTED_NAV_ITEM,
  SET_PERMISSIONS
} from './constants';
import {
  etfOptions, ccfOptions, documentOptions, groupOptions, formOptions,
  imageOptions, welcomeKitOptions, navPermissions, pathMapOptions, ruleTypesOptions
} from '../constants';

const initialState = {
  isDocumentSearchActive: false,
  selectedNavItem: [],
  pathMapOptions,
}

export default (state = initialState, { type, payload }) => {
  switch(type) {
    case DOCUMENT_SEARCH:
      return {
        ...state,
        isDocumentSearchActive: payload
      }
    case SET_SELECTED_NAV_ITEM:
      return {
        ...state,
        selectedNavItem: payload
      }
    case SET_PERMISSIONS: {
      // map privilages to flat array of names
      const permissions = payload.map(perm => perm.name)

      // Filter the perms down to just those that are related to the nav
      const activePermissions = permissions.filter(perm => navPermissions.includes(perm))

      // check if perm is in active perms
      const checker = perm => {
        const activePerm = activePermissions.indexOf(perm)
        if (activePerm > -1) return true
        return false
      }

      // Build the array of menu options based on permission
      // a[0] = permission a[1] = menu item text
      const optionsBuilder = (arr) => {
        const options = []
        // 1n
        arr.forEach(a => checker(a[0]) && options.push(a[1]))

        return options
      }

      // determinant is the base permission for a nav menu option
      const determinantOptionsBuilder = (determinant, arr) => {
        if (!checker(determinant)) return []

        return optionsBuilder(arr)
      }

      // since we have a text option we include this
      const optionsBuilderWithText = (determinant, text, arr) => {
        const items = determinantOptionsBuilder(determinant, arr)

        // can't be handled by <authorizer /> too nested
        if (items.length === 0) return []

        return [{ text, items }]
      }

      const oneToMany = (determinant, arr) => {
        if (!checker(determinant)) return []
        return arr
      }

      const oneToManyWithText = (determinant, text, arr) => {
        const items = oneToMany(determinant, arr)

        if (items.length === 0) return []

        return [{ text, items }]
      }

      // For adding to state at the end
      const documentPathOptions = []

      const achOptions = determinantOptionsBuilder("CAN_ACCESS_ACH", [
        // ["CAN_ACCESS_ACH_ERRORS", "Accounts In Error"],
        // ["CAN_ACCESS_ACH_PAYEEMAINT", "Payee Maintenance"],
        ["CAN_ACCESS_ACH_TRANSHIST", "Transmission History"],
      ])

      const addressOptions = determinantOptionsBuilder("CAN_ACCESS_ADDRESS", [
        ["CAN_ACCESS_ADDRESS_DELIVERY", "Delivery Addresses"],
        ["CAN_ACCESS_ADDRESS_RETURN", "Return Addresses"],
      ])

      const clientAdminOptions = determinantOptionsBuilder("CAN_ACCESS_CLIENT_ADMINISTRATION", [
        [ "CAN_ACCESS_ADDRESS_CORRECTION_EXCLUSION", "Address Correction Exclusion"  ],
        [ "CAN_ACCESS_CLIENTRULES_CTT", "Claim Type Translation"  ],
        [ "CAN_ACCESS_CLIENT_EMAIL", "Client Email Editor"  ],
        [ "CAN_ACCESS_CLIENT_ADMINISTRATION", "Associate Reports Editor"  ],
        [ "CAN_ACCESS_CLIENT_ADMINISTRATION", "Client Information Editor"  ],
        [ "CAN_ACCESS_CLIENT_ADMINISTRATION", "Contact Editor"  ],
        [ "CAN_PERFORM_INTERNAL_RULE_MODIFY", "Client Internal Rules"  ],
        [ "CAN_ACCESS_CLIENT_ADMINISTRATION", "Associate Brokers Editor"  ],
        [ "CAN_ACCESS_CLIENT_ADMINISTRATION", "Broker Editor"  ],
        [ "CAN_ACCESS_CLIENT_ADMINISTRATION", "PAF Editor"  ],
        [ "CAN_PERFORM_CLIENT_OBJECTS_MODIFY", "Client Settings"  ],
        [ "CAN_PERFORM_CLIENT_OBJECTS_MODIFY", "Data Handling"  ],
        [ "CAN_PERFORM_CLIENT_OBJECTS_MODIFY", "Group Rules"  ],
        [ "CAN_PERFORM_CLIENT_OBJECTS_MODIFY", "Print Handling"  ],
        [ "CAN_ACCESS_CLIENTRULES_DTT", "Document Type Translation"  ],
        [ "CAN_ACCESS_CLIENTRULES_RTT", "Recipient Type Translation"  ],
        // [ "CAN_ACCESS_REMARK_CODES", "Remark Code Translation"  ],
        [ "CAN_ACCESS_TERRORIST_WATCH_EXCLUSION", "Terrorist Watch Exclusion"  ],
      ])

      // Use a seperate builder for deeply nested UICL config bullshit
      const buildClientAdminItems = () => {
        // If this permission doesn't exist doing the rest is pointless
        if (!checker("CAN_ACCESS_CLIENT_ADMINISTRATION")) return []
        // If it does we check for the rest of the permissions

        const firstChunk = optionsBuilder([
          ["CAN_ACCESS_ADDRESS_CORRECTION_EXCLUSION", "Address Correction Exclusion"],
          ["CAN_ACCESS_CLIENTRULES_CTT", "Claim Type Translation"],
          ["CAN_ACCESS_CLIENT_EMAIL", "Client Email Editor"],
        ])

        const clientInfoManager = oneToManyWithText(
          "CAN_PERFORM_CLIENT_OBJECTS_MODIFY",
          "Client Information Manager", [
            "Associate Reports Editor",
            "Client Information Editor",
            "Contact Editor",
          ]
        )

        const secondChunk = optionsBuilder(["CAN_PERFORM_INTERNAL_RULE_MODIFY", "Client Internal Rules"])

        const NCOAManager = oneToManyWithText(
          "CAN_ACCESS_CLIENT_ADMINISTRATION",
          "Client NCOA PAF Manager", [
            "Associate Brokers Editor",
            "Broker Editor",
            "PAF Editor",
          ]
        )

        const thirdChunk = oneToManyWithText(
          "CAN_PERFORM_CLIENT_OBJECTS_MODIFY",
          "Client Settings Editor", [
            "Client Settings",
            "Data Handling",
            "Group Rules",
            "Print Handling",
          ]
        )

        const fourthChunk = optionsBuilder([
          ["CAN_ACCESS_CLIENTRULES_DTT","Document Type Translation"],
          ["CAN_ACCESS_CLIENTRULES_RTT","Recipient Type Translation"],
          // ["CAN_ACCESS_REMARK_CODES","Remark Code Translation"],
          ["CAN_ACCESS_TERRORIST_WATCH_EXCLUSION","Terrorist Watch Exclusion"],
        ])

        return [{
          text: "Client Administration",
          items: [
            // has to be done in order so as to preserve menu order
            ...firstChunk,
            ...clientInfoManager,
            ...secondChunk,
            ...NCOAManager,
            ...thirdChunk,
            ...fourthChunk
          ]
        }]
      }
      const clientAdminItems = buildClientAdminItems()

      const clientRulesOptions = determinantOptionsBuilder("CAN_ACCESS_CLIENTRULES",[
        ["CAN_ACCESS_CLIENTRULES_1099SC", "1099 State Copy"],
        ["CAN_ACCESS_CLIENTRULES_CAI", "Check Account Info"],
        ["CAN_ACCESS_CLIENTRULES_PAYER_EFT_MANAGER", "Client Payer Eft Manager"],
        ["CAN_ACCESS_CLIENTRULES_DFT", "Draft Code"],
        ["CAN_ACCESS_CLIENTRULES_FRT", "Form Translation"],
        ["CAN_ACCESS_CLIENTRULES_IMT", "Image Translation"],
        ["CAN_ACCESS_CLIENTRULES_INC", "Ineligible Codes"],
      ])

      const buildDocumentItems = () => {
        // If this permission doesn't exist doing the rest is pointless
        if (!checker("CAN_ACCESS_DOC")) return []
        // If it does we check for the rest of the permissions

        // track the order for the UICL config
        let fileCutOffFlag = false

        // Because of the way uicl configs work we have to dynamically set paths here
        // because each level matches in it's label name
        const pathMapSetter = (holdItems, releaseItems, purgeItems, routeItems) => {

          // we use these maps to assign the apropriate path to the right value
          // for the side nav component
          const holdPaths = new Map([
            [ "Document", "/DocumentManager/Hold/Document", ],
            [ "Group", "/DocumentManager/Hold/Group", ],
            [ "File", "/DocumentManager/Hold/File", ],
          ])
          const releasePaths = new Map([
            [ "Document", "/DocumentManager/Release/Document", ],
            [ "Group", "/DocumentManager/Release/Group", ],
            [ "File", "/DocumentManager/Release/File", ],
          ])
          const purgePaths = new Map([
            [ "Document", "/DocumentManager/Purge/Document", ],
            [ "Group", "/DocumentManager/Purge/Group", ],
            [ "File", "/DocumentManager/Purge/File", ],
          ])
          const routePaths = new Map([
            [ "Document", "/DocumentManager/Route/Document", ],
            [ "Group", "/DocumentManager/Route/Group", ],
            [ "File", "/DocumentManager/Route/File", ],
          ])

          // UICL returns a x|x|x... key for items in <SingleSelectGroup />
          // because the Doc Manager section has several items with matching
          // names "Document", "Group", and "File" we use the key to distinguish
          // which items the user selected in the nav.
          // However, if a user doesn't have all the permissions, we need to map
          // the right instnace of x|x|x... to the right path so we do that here.
          const uiclStringGenerator = (action, level, options) => {
            // These flags with either be 1 if there are active privilages or 0 for none
            const holdFlag = (holdItems.length !== 0 || holdItems[0]?.items.length > 0) ? 1 : 0
            const releaseFlag = (releaseItems.length !== 0 || releaseItems[0]?.items.length > 0) ? 1 : 0
            const purgeFlag = (purgeItems.length !== 0 || purgeItems[0]?.items.length > 0) ? 1 : 0

            // x|middleNum|x
            let middleNum = 0

            // if the user has the CAN_ACCESS_FILE_CUTOFF privilage
            if (fileCutOffFlag) middleNum = 1

            // the middle number goes up 1 for each preceeding set that had
            // ANY privilages
            if (action === 'release') middleNum += holdFlag
            if (action === 'purge') middleNum = middleNum + holdFlag + releaseFlag
            if (action === 'route') middleNum = middleNum + holdFlag + releaseFlag + purgeFlag

            // endNum is mapped to the level but dependant on avilable levels
            // for the specific action
            let endNum
            if (
              level === 'Document' ||
              (level === 'Group' && !options.includes('Document')) ||
              (level === 'File' && options.filter(o => o !== 'File').length === 0)
            ) endNum = 0
            else if (
              (level === 'Group' && options.includes('Document')) ||
              (level === 'File' && options.filter(o => o !== 'File').length === 1)
            ) endNum = 1
            else if (
              (level === 'File' && options.filter(o => o !== 'File').length === 2)
            ) endNum = 2
            return `0|${middleNum}|${endNum}${level}`
          }

          // documentPathOptions will get spread [...] into pathMapOptions
          // for use in the <SideNav /> contianer
          const setActivePaths = (config, map, action) => {
            // if not cofig[0] it means there were no routes for this action
            // and it can be skipped
            if(config[0]) {
              config[0].items.forEach(item => {
                // these maps are the ones at the start of this function
                const path = map.get(item)

                // get the x|x|xDocument/Group/File name configuration
                const key = uiclStringGenerator(action, item, config[0].items)

                documentPathOptions.push([key, path])
              })
            }
          }

          setActivePaths(holdItems, holdPaths, 'hold')
          setActivePaths(releaseItems, releasePaths, 'release')
          setActivePaths(purgeItems, purgePaths, 'purge')
          setActivePaths(routeItems, routePaths, 'route')
        }

        const firstChunk = optionsBuilder([["CAN_ACCESS_FILE_CUTOFF", "Override File Cutoff"]])
        // set the flag if this exists for the path map options builder
        if (firstChunk.length !== 0) fileCutOffFlag = true

        const holdOptions = optionsBuilderWithText("CAN_ACCESS_DOC_HOLD", "Hold", [
          [ "CAN_PERFORM_DOC_HOLD_DOCUMENT", "Document" ],
          [ "CAN_PERFORM_DOC_HOLD_GROUP", "Group" ],
          [ "CAN_PERFORM_DOC_HOLD_FILE", "File" ],
        ])
        const releaseOptions = optionsBuilderWithText("CAN_ACCESS_DOC_RELEASE", "Release", [
          [ "CAN_PERFORM_DOC_RELEASE_DOCUMENT", "Document" ],
          [ "CAN_PERFORM_DOC_RELEASE_GROUP", "Group" ],
          [ "CAN_PERFORM_DOC_RELEASE_FILE", "File" ],
        ])
        const purgeOptions = optionsBuilderWithText("CAN_ACCESS_DOC_PURGE", "Purge", [
          [ "CAN_PERFORM_DOC_PURGE_DOCUMENT", "Document" ],
          [ "CAN_PERFORM_DOC_PURGE_GROUP", "Group" ],
          [ "CAN_PERFORM_DOC_PURGE_FILE", "File" ],
        ])
        const routeOptions = optionsBuilderWithText("CAN_ACCESS_DOC_ROUTE", "Route", [
          [ "CAN_PERFORM_DOC_ROUTE_DOCUMENT", "Document" ],
          [ "CAN_PERFORM_DOC_ROUTE_GROUP", "Group" ],
          [ "CAN_PERFORM_DOC_ROUTE_FILE", "File" ],
        ])

        pathMapSetter(holdOptions, releaseOptions, purgeOptions, routeOptions)

        const secondChunk = optionsBuilder([
          [ "CAN_ACCESS_DOC_SEARCH", "Search" ],
          [ "CAN_ACCESS_DOC_HISTORY", "History" ],
        ])

        return [{
          text: "Document Manager",
          items: [
            ...firstChunk,
            ...holdOptions,
            ...releaseOptions,
            ...purgeOptions,
            ...routeOptions,
            ...secondChunk
          ]
        }]
      }
      const documentItems = buildDocumentItems()

      const eeobOptions = determinantOptionsBuilder("CAN_ACCESS_EEOBFORM_MANAGER", [
        [ "CAN_PERFORM_EEOBFORM_ADD", "Add EEOB Form" ],
        [ "CAN_PERFORM_EEOBFORM_MODIFY", "Modify EEOB Form" ],
      ])

      const manifestOptions = determinantOptionsBuilder("CAN_ACCESS_MANIFEST_INFO", [
        [ "CAN_ACCESS_MANIFEST_ENTRY", "Manifest Entry" ],
        [ "CAN_ACCESS_MANIFEST_WIRE_DATE", "Manifest Wire Date" ],
        [ "CAN_ACCESS_MANIFEST_ENTRY", "Manual Insertion Data Manager" ],
      ])

      const reportOptions = determinantOptionsBuilder("CAN_ACCESS_ABF_REPORTS", [
        [ "CAN_PERFORM_ACS_REPORT", "ACS Report" ],
        [ "CAN_PERFORM_ABF_BILLING_REPORT", "Billing Report" ],
        // [ "CAN_PERFORM_CHECKS_CLEARED_REPORT", "Checks Cleared Report" ],
        [ "CAN_PERFORM_CLIENT_IMAGE_REPORT", "Client Image Report" ],
        [ "CAN_PERFORM_ABF_CLIENT_INFO_REPORT", "Client Information Report" ],
        [ "CAN_PERFORM_GENERIC_REPORT", "Client Specific Reports" ],
        [ "CAN_PERFORM_DATA_DICTIONARY_REPORT", "Data Script Report" ],
        [ "CAN_PERFORM_EFT_HISTORY", "EFT History Report" ],
        [ "CAN_PERFORM_EFT_PROVIDER_REPORT", "EFT Provider Report" ],
        // [ "CAN_ACCESS_SELFSERVICE_RULETYPES", "Group Rules History Report" ],
        [ "CAN_PERFORM_MAILDELIVERY_REPORT", "Mail Delivery Report" ],
        [ "CAN_PERFORM_OFFHOURS_REPORT", "Off Hours Activity Report" ],
        [ "CAN_PERFORM_ONHOLD_REPORT", "On Hold Report" ],
        [ "CAN_PERFORM_UNAUTHORIZED_REPORT", "Unauthorized Access Report" ],
        [ "CAN_PERFORM_US_DELIVERY_REPORT", "US Delivery Report" ],
        [ "CAN_PERFORM_ABF_USER_REPORT", "User Report" ],
        [ "CAN_PERFORM_DATA_DICTIONARY_REPORT", "Vendor Layout Report" ],
      ])

      const securityOptions = determinantOptionsBuilder("CAN_ACCESS_SECURITY", [
        [ "CAN_PERFORM_SECURITY_VIEW_ACTIVE_USERS", "Active User List" ],
        [ "CAN_ACCESS_SECURITY_DATASOURCE", "Data Source Manager" ],
        [ "CAN_ACCESS_SECURITY_ROLE", "Role Manager" ],
        [ "CAN_ACCESS_SECURITY_USER", "User Manager" ],
      ]
      )
      const sysAdminOptions = determinantOptionsBuilder("CAN_ACCESS_SYSTEM_ADMINISTRATION", [
        [ "CAN_ACCESS_MESSAGE_MANAGER", "Message Manager" ],
        [ "CAN_PERFORM_RAIC_MONITORING", "Server Process Detail" ],
        [ "CAN_PERFORM_MASS_UPDATE", "Update Document Status" ],
      ])

      return {
        ...state,
        pathMapOptions: [...state.pathMapOptions, ...documentPathOptions],
        etfOptions: oneToMany('CAN_ACCESS_835MAP_MANAGER', etfOptions),
        achOptions,
        addressOptions,
        ccfOptions: oneToMany('CAN_ACCESS_CCF_MANAGER', ccfOptions),
        clientAdminOptions,
        clientAdminItems,
        clientRulesOptions,
        documentOptions,
        documentItems,
        eeobOptions,
        groupOptions: oneToMany('CAN_ACCESS_SELFSERVICE_GROUPS', groupOptions),
        ruleTypesOptions: oneToMany('CAN_ACCESS_SELFSERVICE_RULETYPES', ruleTypesOptions),
        formOptions: oneToMany('CAN_ACCESS_FORM_MANAGER', formOptions),
        imageOptions: oneToMany('CAN_ACCESS_FORM_MANAGER', imageOptions),
        manifestOptions,
        welcomeKitOptions: oneToMany('CAN_ACCESS_IDCARDS', welcomeKitOptions),
        reportOptions,
        securityOptions,
        sysAdminOptions,
      }
    }
    default:
      return state;
  }
}
