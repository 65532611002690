
export const gridRevertRuleVersionConfig = {
  columns: new Set([
    {
      dataName: 'version',
      text: 'Live Rule Version',
      sortable: true,
      cellType: 'custom',
    },
    {
      dataName: 'lastCommit',
      text: 'Commit Date',
      sortable: true,
    },
  ]),
  selectionKey: 'id',
  domID: 'rever-rule-version-grid',
  onSortTableColumn: () => {},
  supportSelection: false,
  initialSortingKey: 'dateModified|SORT_DESCENDING',
  isFixedHeader: true
};