/* eslint-disable radix */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-useless-escape */
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import styled from 'styled-components/dist/styled-components'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import {
  Grid,
  Modal,
  ModalHeader,
  useLayout,
  ListPaneHeader,
  Button, Input, Breadcrumb, ModalBody
} from 'ui-core/dist'
import { useGetConsTypeDesc, useGetDrawDownDesc, useGetEftAccount, useGetEftCommitList,
  useCommitEftAccount } from '../../api/usePayerEftAccount';
import {AnchorInput} from '../../components'
import LoadingComponent from '../../components/LoadingComponent';
import ErrorPage from '../ErrorPage/components/ErrorPage';
import ListPaneHeaderWrapper from '../../components/ErrorDisplaySection/ListPaneHeaderWrapper';
import useNotificationBannerHook from '../../useNotificationBannerHook';
import GridPaginationWrapper from '../../components/GridPaginationWrapper';
import * as constants from './Modules/constants';
import useSortableGrid from '../../hooks/useSortableGrid';
import useSelectableGrid from '../../hooks/useSelectableGrid'
import './Modules/styles.scss'

const DEFAULT_ERROR_MSG_EFT_ACCOUNT='Could not retireve account information for Client EFT Account: {0}';
const DEFAULT_ERROR_MSG_FETCH_EFT_ACCOUNT='Some errorgetEftCommitList occurred while fetching Eft Account';
const DEFAULT_ROWS_PER_PAGE=10;

const StyledGridWrapper = styled.div`
&.GridWrapper #grid-wrapper-payerEftMainGrid{
  overflow:initial !important;
 }
`
const PayerEftAccountCommit = () => {
  const {
    expandCollapseListPaneButtonConfig
  } = useLayout()
  const { notify } = useNotificationBannerHook();
  const history = useHistory();

  const [isOpenCommitSingleModal, setisOpenCommitSingleModal]=useState(false)
  const [isOpenCommitAllModal, setisOpenCommitAllModal]=useState(false)

  const { isLoading: isLoadinggetEftCommitList, isError: isErrorgetEftCommitList, error: errorgetEftCommitList, postData: getEftCommitList, 
    metaData: metadatagetEftCommitList, data: eftCommitList } = useGetEftCommitList()

  const { isLoading: isLoadingEftAccount, isError: isErrorEftAccount, error: errorEftAccount, 
    postData: getEftAccount, metaData: metadataEftAccount, data: eftAccount } = useGetEftAccount()

  const { isLoading: isLoadinggetConsTypeDesc, isError: isErrorgetConsTypeDesc, error: errorgetConsTypeDesc, 
    postData: getConsTypeDesc, data: ConsTypeDesc, metaData: getConsTypeDescMetadata } = useGetConsTypeDesc()
    
  const { isLoading: isLoadinggetDrawDownDesc, isError: isErrorgetDrawDownDesc, error: errorgetDrawDownDesc, 
    postData: getDrawDownDesc, data: DrawDownDesc, metaData: getDrawDownDescMetadata } = useGetDrawDownDesc()

  const { isLoading: isLoadingcommitEftAccount, isError: isErrorcommitEftAccount, error: errorcommitEftAccount, 
    postData: commitEftAccount, data: commitEftAccountResp, metaData: commitEftAccountMetadata } = useCommitEftAccount()
    
  const disabledCondition=useCallback(()=>selectedItemIdsCurrentPage.size>1,[selectedItemIdsCurrentPage])

  const {onRowSelect: onRowSelectContacts,selectedItemIdsCurrentPage, disabledItemIds}=
    useSelectableGrid({uniqueKey:'AcctId',list:eftCommitList?.collection?.commitAccounts,isUicl:true, disabledCondition})

  const [totalRecords, settotalRecords]=useState(0)
  const [mode, setMode]=useState('')
  const [consolidationTypeId, setConsolidationTypeId]=useState('')
  const [consolidationTypeDesc, setConsolidationTypeDesc]=useState('')
  const [drawDownTypeId, setDrawDownTypeId]=useState('')
  const [drawDownTypeDesc, setDrawDownTypeDesc]=useState('')
  const [eftAcctId, setEftAcctId]=useState('')
  const [accountId, setAccountId]=useState('')
  const [routingCode, setRoutingCode]=useState('')
  const [companyName, setCompanyName]=useState('')
  const [descretionaryData, setDescretionaryData]=useState('')
  const [odfiId, setOdfiId]=useState('')
  const [effDateDiff, setEffDateDiff]=useState('')
  const [companyId, setCompanyId]=useState('')
  const [companyEntryDesc, setCompanyEntryDesc]=useState('')
  const [origFinInst, setOrigFinInst]=useState('')
  const [accountVerified, setAccountVerified]=useState('')
  const [bankingModel, setBankingModel]=useState('')
  const [versionNumber, setVersionNumber]=useState('')
  const [statusCode, setStatusCode]=useState('')
  const [tUpdated, setTUpdated]=useState('')
  const [acctType, setAcctType]=useState('')
  const [holdDays, setHoldDays]=useState('')
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE)
  const [pageIndex, setPageIndex] = useState(0);
  const [count, setcount] = useState(0);

  useEffect(() => {
    if (commitEftAccountMetadata) {
      const { status, statusText } = commitEftAccountMetadata;
      if (status === 200 || statusText === "OK") {
        if (commitEftAccountResp && commitEftAccountResp.fs) {
          const { fs } = commitEftAccountResp;
          if (fs) {
            notify(fs, 'negative')
            return ;
          }
        }
        if (commitEftAccountResp?.resp) {
          if (mode==="commitsingle") {
            notify(constants.status_client_eft_commit_single_successful.replace('{0}', eftAcctId), 'positive')
            onCloseCommitSingleModal()
          } else if(mode==="commitall"){
            notify(constants.status_client_eft_commit_all_successful, 'positive')
            onCloseCommitAllModal()
          }
          refetchEftAccounts()
        }
      } else{
        notify(constants.error_client_eft_commit_failure, 'negative')
      }
    }
  }, [commitEftAccountMetadata, commitEftAccountResp])

  useEffect(() => {
    if (metadataEftAccount) {
      const { status, statusText } = metadataEftAccount;
      if (status === 200 || statusText === "OK") {
        if (eftAccount && eftAccount.fs) {
          const { fs } = eftAccount;
          if (fs) {
            notify(fs, 'negative')
          }
        }
      } else{
        notify(DEFAULT_ERROR_MSG_FETCH_EFT_ACCOUNT, 'negative')
      }
    }
  }, [metadataEftAccount, eftAccount])
  useEffect(() => {
    if (metadatagetEftCommitList) {
      const { status, statusText } = metadatagetEftCommitList;
      if (status === 200 || statusText === "OK") {
        if (eftCommitList && eftCommitList.fs) {
          const { fs } = eftCommitList;
          if (fs) {
            notify(fs, 'negative');
            return;
          }
        }
        if (eftCommitList && eftCommitList.collection && eftCommitList.collection.$) {
          const {totalRecords: totalRecordsP} = eftCommitList.collection.$;
          settotalRecords(totalRecordsP)
        }
      }
    }
  }, [metadatagetEftCommitList, eftCommitList])

  useEffect(() => {
    if (getConsTypeDescMetadata) {
      const { status, statusText } = getConsTypeDescMetadata;
      if (status === 200 || statusText === "OK") {
        if (ConsTypeDesc && ConsTypeDesc.fs) {
          const { fs } = ConsTypeDesc;
          if (fs) {
            notify(fs, 'negative')
          }
        }
      } else {
        notify(constants.error_client_eft_copy_failure.replace('{0}', eftAcctId), 'negative')
      }
    }
  }, [getConsTypeDescMetadata, ConsTypeDesc])
  useEffect(() => {
    if (getDrawDownDescMetadata) {
      const { status, statusText } = getDrawDownDescMetadata;
      if (status === 200 || statusText === "OK") {
        if (DrawDownDesc && DrawDownDesc.fs) {
          const { fs } = DrawDownDesc;
          if (fs) {
            notify(fs, 'negative')
          }
        }
      } else {
        notify(constants.error_client_eft_overwrite_failure.replace('{0}', eftAcctId), 'negative')
      }
    }
  }, [getDrawDownDescMetadata, DrawDownDesc])
  useEffect(()=>{
    if (ConsTypeDesc && ConsTypeDesc.resp) {
      setConsolidationTypeDesc(ConsTypeDesc.resp)
    }
  },[ConsTypeDesc])
  useEffect(()=>{
    if (DrawDownDesc && DrawDownDesc.resp) {
      setDrawDownTypeDesc(DrawDownDesc.resp)
    }
  },[DrawDownDesc])
  const refetchEftAccounts = () => {
    setRowsPerPage(DEFAULT_ROWS_PER_PAGE)
    setPageIndex(0)
    getEftCommitList(JSON.parse(JSON.stringify({
      "maxRecords": DEFAULT_ROWS_PER_PAGE,
      "rowPosition": 0,
      "sortField": sortConfig.sortDataName,
      "sortDescending": sortConfig.sortDescending,
      count
    })))
    setcount(count + 1)
  }
  useEffect(() => {
    refetchEftAccounts()
  }, [])
  const fetchData = useCallback((args) => {
    settotalRecords(0)
    let sortcolumn; let sd; let pi; let type;
    if (args) {
      ({ sortcolumn, sd, pi, type } = args);
    }
    getEftCommitList(JSON.parse(JSON.stringify({
      "maxRecords": rowsPerPage,
      "rowPosition": pi === undefined || pi === null ? pageIndex : pi,
      "sortField": sortcolumn === undefined || sortcolumn === null ? sortConfig.sortDataName : sortcolumn,
      "sortDescending": sd === undefined || sd === null ? sortConfig.sortDescending : sd,
      type,
      count
    })))
    setcount(count + 1)

  }, [rowsPerPage, pageIndex, sortConfig]);
  const [sortConfig, getArgumentsAndSortGrid] = useSortableGrid(fetchData, "AcctId", "true");
  useEffect(()=>{
    if (eftAccount) {
      if(mode==="commitsingle") {
        if (consolidationTypeId) getConsTypeDesc({consType: consolidationTypeId})
        if (drawDownTypeId) getDrawDownDesc({drawDownType: drawDownTypeId});
        setisOpenCommitSingleModal(true)
      }
    }
  }, [eftAccount])
  const renderEftAccountsTable=useMemo(()=>{
    if (eftCommitList && eftCommitList.collection && eftCommitList.collection.commitAccounts) {
      const data = JSON.parse(JSON.stringify(eftCommitList.collection.commitAccounts));
      return (
        <>
          <div style={{ marginLeft: '22px' }}>
            <p><span className="required-glyph"><label>Select a record to commit the Payer EFT account :</label></span></p>
            <GridPaginationWrapper
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={setRowsPerPage}
              pageIndex={pageIndex}
              onChangePageIndex={setPageIndex}
              onShowButtonCLick={fetchData}
              totalRecords={totalRecords}
              defaultRowsPerPage={DEFAULT_ROWS_PER_PAGE}
            />
          </div>
          <StyledGridWrapper className='GridWrapper'>
            <Grid
              {...constants.EFT_ACCT_COMMIT_GRID}
              records={data || []}
              selectionKey="AcctId"
              initialSelectedItemIds={selectedItemIdsCurrentPage}
              initialDisabledItemIds={disabledItemIds}
              onRowSelect={onRowSelectContacts}
              onSelectAll = {onRowSelectContacts}
              onClickThrough={(e, rec) => {
                e.preventDefault();
                e.stopPropagation();
                return false;
              }}
              initialSortingKey={`${sortConfig.sortDataName}|${sortConfig.sortDescending === "true" ? 'SORT_DESCENDING' : 'SORT_ASCENDING'}`}
              onSortGridColumn={(e, state) => getArgumentsAndSortGrid(state)}
            />
          </StyledGridWrapper>
          <table>
            <tr><p></p></tr>
            <tr>
              <td className="value" colspan="2">
                <div className='displayFlex'>
                  <Button
                    id='eftPayerCommitBack'
                    size="small"
                    name="Cancel"
                    onClick={()=>history.goBack()}
                  />
                  <div className='marginLeft'>
                    <Button
                      id='eftPayerCommitSingle'
                      size="small"
                      disabled={selectedItemIdsCurrentPage.size!==1}
                      name="Commit Selected"
                      onClick={(e)=>{
                        e.stopPropagation();
                        e.preventDefault();
                        const farray=Array.from(selectedItemIdsCurrentPage);
                        if (farray && farray.length===1) {
                          if (eftCommitList && eftCommitList.collection && eftCommitList.collection.commitAccounts) {
                            const AcctId=farray[0];
                            const d=  eftCommitList.collection.commitAccounts.find(x=>x?.AcctId===AcctId);
                            if (d) {
                              getEftAccount({acctId: d?.AcctId, version: d?.Version, status: d?.Status ,count})      
                              setcount(count+1)
                              setMode('commitsingle')      
                            }
                          }
                        }
                      }}
                    />
                  </div>
                  <div className='marginLeft'>
                    <Button
                      id='eftPayerCommitAll'
                      size="small"
                      name="Commit All"
                      onClick={(e)=>{
                        e.stopPropagation();
                        e.preventDefault();
                        setMode('commitall')
                        setisOpenCommitAllModal(true)
                      }}
                    />
                  </div>
                </div>
                {/* <input type="button" value="Back" onClick={()=>history.goBack()} />&nbsp; */}
              </td>
            </tr>
          </table>
        </>
      )
    }
    return (
      <div className="marginLeft">
        <p><span className="required-glyph"><label>No Records available</label></span></p>
      </div>
    );
  },[eftCommitList, totalRecords, rowsPerPage, pageIndex, sortConfig, selectedItemIdsCurrentPage, disabledItemIds])
  const populateForm=(abfPeo)=>{
    setConsolidationTypeId(abfPeo.consType);
    setConsolidationTypeDesc(abfPeo.consTypeDesc);
    setDrawDownTypeId(abfPeo.drawDown);
    setDrawDownTypeDesc(abfPeo.drawDownDesc);
    setEftAcctId(abfPeo.acctId);
    setAccountId(abfPeo.onus);
    setRoutingCode(abfPeo.routeId);
    setCompanyName(abfPeo.compName);
    setDescretionaryData(abfPeo.compData);
    setOdfiId(abfPeo.odfiId);
    setEffDateDiff(abfPeo.dateDiff);
    setCompanyId(abfPeo.compId);
    setCompanyEntryDesc(abfPeo.compEntDesc);
    setOrigFinInst(abfPeo.originId);
    setAccountVerified(abfPeo.verified);
    setBankingModel(abfPeo.payerBank);
    setVersionNumber(abfPeo.version);
    setStatusCode(abfPeo.status);
    setTUpdated(abfPeo.updated);
    setAcctType(abfPeo.acctType);
    setHoldDays(abfPeo.holdDays);
  }
  const resetForm=()=>{
    setConsolidationTypeId("");
    setConsolidationTypeDesc("");
    setDrawDownTypeId("");
    setDrawDownTypeDesc("");
    setEftAcctId("");
    setAccountId("");
    setRoutingCode("");
    setCompanyName("");
    setDescretionaryData("");
    setOdfiId("");
    setEffDateDiff("");
    setCompanyId("");
    setCompanyEntryDesc("");
    setOrigFinInst("");
    setAccountVerified("");
    setBankingModel("");
    setVersionNumber("");
    setStatusCode("");
    setTUpdated("");
    setAcctType("");
    setHoldDays("");
    setMode("");
  }  
  const onCloseCommitSingleModal=()=>{
    setisOpenCommitSingleModal(false)
    resetForm()
  }
  const onCloseCommitAllModal=()=>{
    setisOpenCommitAllModal(false)
    resetForm()
  }
  const populateEftObj=()=> {
    const abfPeo={};
    abfPeo.consType = consolidationTypeId||'';
    abfPeo.consTypeDesc = consolidationTypeDesc||'';
    abfPeo.drawDown = drawDownTypeId||'';
    abfPeo.drawDownDesc = drawDownTypeDesc||'';
    abfPeo.acctId = eftAcctId||'';
    abfPeo.onus = accountId||'';
    abfPeo.routeId = routingCode||'';
    abfPeo.compName = companyName||'';
    abfPeo.compData = descretionaryData||'';
    abfPeo.odfiId = odfiId||'';
    abfPeo.dateDiff = effDateDiff||'';
    abfPeo.compId = companyId||'';
    abfPeo.compEntDesc = companyEntryDesc||'';
    abfPeo.originId = origFinInst||'';
    abfPeo.verified = accountVerified||'';
    abfPeo.payerBank = bankingModel||'';
    abfPeo.version = versionNumber||'';
    abfPeo.status = statusCode||'';
    abfPeo.updated = tUpdated||'';
    abfPeo.acctType = acctType||'';
    abfPeo.holdDays = holdDays||'';
    return abfPeo;
  }
  const onCommitClick=()=>{
    if (mode==="commitsingle") {
      const abfPeo=populateEftObj();
      commitEftAccount({strAcctList: abfPeo.acctId, count})
    } else if(mode==="commitall"){
      commitEftAccount({strAcctList: "ALL", count})
    }
    setcount(count+1)
  }
  const renderCommitSingleModal=useMemo(()=>{
    if (eftAccount && eftAccount.resp) {
      const {resp: abfPeo}=eftAccount;
      populateForm(abfPeo);
      return (
        <Modal isOpen={isOpenCommitSingleModal}>
          <ModalHeader
            title="Confirm Payer EFT Account Commit (Selected EFT Account)"
            onClose={() => onCloseCommitSingleModal()}
          />
          <ModalBody>
            {isOpenCommitSingleModal ? (
              <div style={{ marginLeft: '34px', marginBottom: '20px' }} className="marginLeft">
                <div className="displayFlex">
                  <div className="marginLeft">
                    <table>
                      <tr>
                        <td><span className="required-glyph"><label>Click Submit to commit the Payer EFT account shown below:</label></span></td>
                      </tr>
                      <tr><td><p></p></td></tr>
                      <tr>
                        <table border="0">
                          {bankingModel==="1"?(
                            <tr>
                              <td className="name"><label>Banking Model:</label></td>
                              <td className="value"><label>Payer Bank</label></td>
                            </tr>
                          ):null}
                          {bankingModel==="0"?(
                            <tr>
                              <td className="name"><label>Banking Model:</label></td>
                              <td className="value"><label>Emdeon</label></td>
                            </tr>
                          ):null}
                          <tr>
                            <td className="name"><label>Payer Eft Account Number:</label></td>
                            <td className="value"><label>{accountId}</label></td>
                          </tr>
                          <tr>
                            <td className="name"><label>Payer EFT Routing Code:</label></td>
                            <td className="value"><label>{routingCode}</label></td>
                          </tr>
                          <tr>
                            <td className="name"><label>Company Name:</label></td>
                            <td className="value"><label>{companyName}</label></td>
                          </tr>
                          {bankingModel==="1"?(
                            <tr>
                              <td className="name"><label>ODFI ID:</label></td>
                              <td className="value"><label>{odfiId}</label></td>
                            </tr>
                          ):null}
                          <tr>
                            <td className="name"><label>Effective Date Difference:</label></td>
                            <td className="value"><label>{effDateDiff}</label></td>
                          </tr>
                          {bankingModel==="0"?(
                            <tr>
                              <td className="name"><label>Hold Days:</label></td>
                              <td className="value"><label>{holdDays}</label></td>
                            </tr>
                          ):null}
                          {bankingModel==="1"?(
                            <tr>
                              <td className="name"><label>Discretionary Data:</label></td>
                              <td className="value"><label>{descretionaryData}</label></td>
                            </tr>
                          ):null}
                          {bankingModel==="1"?(
                            <tr>
                              <td className="name"><label>Company Identification (Assigned by ODFI):</label></td>
                              <td className="value"><label>{companyId}</label></td>
                            </tr>
                          ):null}
                          {bankingModel==="1"?(
                            <tr>
                              <td className="name"><label>Company Entry Description:</label></td>
                              <td className="value"><label>{companyEntryDesc}</label></td>
                            </tr>
                          ):null}
                          {bankingModel==="1"?(
                            <tr>
                              <td className="name"><label>Originating Financial Institution:</label></td>
                              <td className="value"><label>{origFinInst}</label></td>
                            </tr>
                          ):null}
                          {bankingModel==="0"?(
                            <tr>
                              <td className="name"><label>Consolidation Type:</label></td>
                              <td className="value"><label>{consolidationTypeDesc}</label></td>
                            </tr>
                          ):null}
                          {bankingModel==="0"?(
                            <tr>
                              <td className="name"><label>Draw Down Type:</label></td>
                              <td className="value"><label>{drawDownTypeDesc}</label></td>
                            </tr>
                          ):null}
                          {bankingModel==="0"?(
                            <tr>
                              <td className="name"><label>Account Type:</label></td>
                              {acctType==="C"?(
                                <td className="value"><label>Checkings</label></td>
                              ):null}
                              {acctType==="S"?(
                                <td className="value"><label>Savings</label></td>
                              ):null}
                            </tr>
                          ):null}
                          <tr>
                            <td className="name"><label>Account Verified:</label></td>
                            {accountVerified==="0"?(
                              <td className="value"><label>NO</label></td>
                            ):null}
                            {accountVerified==="1"?(
                              <td className="value"><label>YES</label></td>
                            ):null}
                          </tr>
                          <tr><td><p></p></td></tr>
                          <tr>
                            <td className="value" colspan="2">&nbsp;
                              <Button
                                id='ContactEditorAdd'
                                size="small"
                                name="Submit"
                                onClick={onCommitClick}
                              />
                              {/* <input type="submit" name="submit" value="Submit" onClick={onCommitClick}/> */}
                            </td>
                          </tr>
                        </table>  
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            ) : null}
          </ModalBody>
        </Modal>
      )
    }
    return null;
  },[eftAccount, totalRecords, isOpenCommitSingleModal,mode,
    consolidationTypeDesc,drawDownTypeDesc,eftAcctId,accountId,
    routingCode,companyName,descretionaryData,odfiId,effDateDiff,companyId,
    companyEntryDesc,origFinInst,accountVerified,bankingModel,acctType,holdDays,
    consolidationTypeId,drawDownTypeId,versionNumber,statusCode,tUpdated
  ])
  const renderCommitAllModal=useMemo(()=>{
    if (eftCommitList && eftCommitList.collection && eftCommitList.collection.commitAccounts) {
      const data = JSON.parse(JSON.stringify(eftCommitList.collection.commitAccounts));
      return (
        <Modal isOpen={isOpenCommitAllModal}>
          <ModalHeader
            title="Confirm Payer EFT Account Commit (All EFT Accounts)"
            onClose={() => onCloseCommitAllModal()}
          />
          <ModalBody>
            {isOpenCommitAllModal ? (
              <div style={{ marginLeft: '34px', marginBottom: '20px' }} className="marginLeft">
                <div className="displayFlex">
                  <div className="marginLeft">
                    <table border="0">
                      <tr>
                        <td><label>Click Submit to commit all the EFT Accounts shown below. You can also commit a specific EFT account instead by clicking the Commit link shown to the left of the EFT account.</label></td>
                      </tr>
                    </table>
                    <StyledGridWrapper className='GridWrapper'>
                      <Grid
                        {...constants.EFT_ACCT_COMMIT_GRID_SINGLE_COL}
                        records={data || []}
                        onClickThrough={(e, rec) => {
                          e.preventDefault();
                          e.stopPropagation();
                          return false;
                        }}
                      />
                    </StyledGridWrapper>
                    <table border="0">
                      {totalRecords>0?(
                        <>
                          <tr><td><p></p></td></tr>
                          <tr>
                            <td className="value" colspan="2">
                              <Button
                                id='ContactEditorAdd'
                                size="small"
                                name="Submit"
                                onClick={onCommitClick}
                              />
                              {/* &nbsp;<input type="submit" name="submit" value="Submit" onClick={onCommitClick}/> */}
                            </td>
                          </tr>
                        </>
                      ):null}
                    </table>
                  </div>
                </div>
              </div>
            ) : null}
          </ModalBody>
        </Modal>
      )
    }
    return null;
  },[eftCommitList, totalRecords, isOpenCommitAllModal,mode,
    consolidationTypeDesc,drawDownTypeDesc,eftAcctId,accountId,
    routingCode,companyName,descretionaryData,odfiId,effDateDiff,companyId,
    companyEntryDesc,origFinInst,accountVerified,bankingModel,acctType,holdDays,
    consolidationTypeId,drawDownTypeId,versionNumber,statusCode,tUpdated
  ])
  return (
    <>
      <ListPaneHeaderWrapper
        title="Client Payer Eft Manager"
        layoutControls={[expandCollapseListPaneButtonConfig]}
      />
      <>
        {isLoadinggetEftCommitList || isLoadingEftAccount || isLoadinggetConsTypeDesc
        || isLoadinggetDrawDownDesc || isLoadingcommitEftAccount ? (
        // replace with a better loading component            
            <div style={{ marginLeft: '50%' }}><LoadingComponent /></div>
          ) : isErrorgetEftCommitList || isErrorEftAccount || isErrorgetConsTypeDesc
        || isErrorgetDrawDownDesc || isErrorcommitEftAccount ? (
          // replace with a better errorgetEftCommitList component
              <ErrorPage
                // this prop isn't real but is an example
                errorgetEftCommitList={errorgetEftCommitList || errorEftAccount || errorgetConsTypeDesc
            || errorgetDrawDownDesc || errorcommitEftAccount}
              />
            ) : (
              <div className="marginLeft">
                <table border="0" cellPadding="0" cellSpacing="0">
                  <tr>
                    <td>
                      <Breadcrumb
                        breadcrumbItems={[{ title: "Client Payer Eft Manager" }, 
                          { title: "Commit Client Payer Eft Manager" }]}
                        onBreadcrumbItemClick={()=>history.goBack()}
                        domID="breadcrumb"
                      />
                    </td>
                  </tr>
                  <tr>
                    {renderEftAccountsTable}
                  </tr>
                </table>
                {renderCommitSingleModal}
                {renderCommitAllModal}
              </div>
            )
        }
      </>
    </>
  )
}

export default PayerEftAccountCommit;