import React, { useState,useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, LoadingIndicator } from 'ui-core-ssgr';
import _ from 'lodash';
import { ButtonGroup } from '../../../shared/components/ButtonGroup';
import { ModalButton } from '../../RulesTabView/components/RuleDetails/RuleDetailsGridWrapper/styles';
import {
  LoadMoreButton,
  InputLabelWrapper,
  LoadingWrapper,
} from './styles';
import ImageControl from '../ModalControls/ImageControl';

const ImageLibraryModal = ({
  setIsShowingImageLibraryModal,
  selectedRecordIdImageLibrary,
  selectedRuleTypeId,
  selectedImageData,
  ruleImages,
  ruleImagesCount,
  loading,
  loadMoreRecords,
  onCancelImageLibrary,
  searchToggle,
  getKeyword
}) => {

  const [selectedImageKey, setSelectedImageKey] = useState(null);
  const [selectedImageSrc, setSelectedImageSrc] = useState('');
  const [selectedimageAltLabel, setSelectedimageAltLabel] = useState('');
  const [selectedImageId, setSelectedImageId] = useState('');
  const [itemsCount, setItemsCount] = useState(0);
  const [recordPerPageLabel] = useState(20); // for label purpose only
  const [loadedRecordsFromApi, setLoadedRecordsFromApi] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(null);
  const [isTestedStatus, setIsTestedStatus] = useState(null);
  const [isOnLoadMoreMode, setIsOnLoadMoreMode] = useState(false);
  
  useEffect(() => {
    setIsButtonDisabled(true);
    setIsOnLoadMoreMode(false);
    setLoadedRecordsFromApi(null);
    setItemsCount(0);
  }, [])

  useEffect(() => {
    const checkValue = /^[a-zA-Z0-9-_ ]*$/;
    const keyword = getKeyword();
    // only make call if valid search
    if(checkValue.test(keyword) && (keyword.length === 0 || keyword.length >= 3)) {
      setIsOnLoadMoreMode(false);
      setLoadedRecordsFromApi(null);
      setItemsCount(0);
    }
  }, [searchToggle])

  const onClickHandle = (e, imageKey, imageSrc, imageAltLabel, imageId, isTested) => {
    setSelectedImageKey(imageKey);
    setSelectedImageSrc(imageSrc);
    setSelectedimageAltLabel(imageAltLabel);
    setSelectedImageId(imageId);
    setIsButtonDisabled(false);
    setIsTestedStatus(isTested);
  };

  const onSaveImageLibrary = (e) => {
    setIsShowingImageLibraryModal(
      selectedRuleTypeId,
      selectedRecordIdImageLibrary,
      false,
    );
    selectedImageData(
      e,
      selectedImageKey,
      selectedImageSrc,
      selectedimageAltLabel,
      selectedImageId,
      isTestedStatus
    );
  };

  const renderImagesFromLibrary = () => {
    const imageLibrary = ruleImages ? ruleImages.detailElements.ruleimages : null;
    if (_.isEmpty(imageLibrary)) {
      return (
        <section className="image-library-list">
          <br/><br/><br/>
          <center><b>No images match your search</b></center>
          <br/><br/><br/>
        </section>
      );
    }
    if (itemsCount === 0 && loadedRecordsFromApi === null) {
      setLoadedRecordsFromApi(imageLibrary);
    } else if (isOnLoadMoreMode) {
      imageLibrary.forEach(x => {
        const record = loadedRecordsFromApi.find(
          rec => rec.id === x.id,
        );
        if (!record) loadedRecordsFromApi.push(x);
      });
    }
    
    // executes very first time
    if (!_.isEmpty(loadedRecordsFromApi)) {
      return (
        <section className="image-library-list">
          {loadedRecordsFromApi.map((file, index) => (
            <ImageControl
              key={file.id}
              imageKey={String(index)}
              imageDomID={String(index)}
              imageClassName={String(file.name)}
              imageSrc={file.image}
              imageAltLabel={file.name}
              imageStyles={null}
              imageOnClickHandle={e =>
                onClickHandle(e, index, file.image, file.name, file.id, file.isTested)
              }
              imageOnLoad={() => {}}
              imageOnError={() => {}}
              imageSelection  = {selectedImageKey} 
            />
          )
          )}
        </section>
      );
    }
    return '';
  }

  const loadMoreItems = () => {
    const newCount = itemsCount + recordPerPageLabel;
    setItemsCount(newCount);
    loadMoreRecords(newCount);
    setIsOnLoadMoreMode(true);
  };

  const loadedRecords = loadedRecordsFromApi ? loadedRecordsFromApi.length : null;
  const totalCounterLabel = ruleImagesCount ? ruleImagesCount.imagesCount : 0;
  const difference = totalCounterLabel - loadedRecords < recordPerPageLabel ? totalCounterLabel - loadedRecords : recordPerPageLabel;
  const loadMoreLabel = `Load ${difference} More`;
  const loadMore = loadedRecords < totalCounterLabel;
  const imageLibrary = ruleImages ? ruleImages.detailElements.ruleimages : null;
  return (
    <>
      {loading.has(selectedRuleTypeId) ?
        <center><LoadingWrapper>
          <LoadingIndicator
            domID="image-library-main-loader"
            length="60px"
          />
        </LoadingWrapper></center> : renderImagesFromLibrary()}

      {!_.isEmpty(imageLibrary) &&
      <div>
        {!loading.has(selectedRuleTypeId) ? <LoadMoreButton>
          <InputLabelWrapper>
            {`${loadedRecords}/${totalCounterLabel} images loaded`}
          </InputLabelWrapper>
          {loading.has(`${selectedRuleTypeId}-offset`) && loadMore ? (
            <LoadingIndicator
              domID="load-more-loader"
              length="30px"
            />
          ) : (
            loadMore && (
              <Button
                name={loadMoreLabel}
                buttonType="deEmphasized"
                onClick={loadMoreItems}
                size="medium"
                type="button"
              />
            )
          )}
        </LoadMoreButton> : null }
      </div>}
      {!loading.has(selectedRuleTypeId) ? <ButtonGroup>
        <ModalButton className="button-wrap-image-library">
          <Button
            onClick={(e) => onSaveImageLibrary(e)}
            domID="save-ImageLibrary"
            name="Save"
            buttonType="emphasized"
            size="medium"
            isDropdown={false}
            type="button"
            disabled = {isButtonDisabled}
          />
          <Button
            domID="cancel-ImageLibrary"
            name="Cancel"
            buttonType="diminished"
            size="medium"
            onClick={() => onCancelImageLibrary()}
            isDropdown={false}
            type="button"
          />
        </ModalButton>
      </ButtonGroup> : null }
    </>
  );
};


ImageLibraryModal.propTypes = {
  setIsShowingImageLibraryModal: PropTypes.func,
  selectedRecordIdImageLibrary: PropTypes.string,
  selectedRuleTypeId: PropTypes.string,
  selectedImageData: PropTypes.func,
  ruleImages: PropTypes.object,
  loading: PropTypes.object,
  ruleImagesCount: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  loadMoreRecords: PropTypes.func,
  searchToggle: PropTypes.bool,
  onCancelImageLibrary: PropTypes.func
};
export default ImageLibraryModal;