import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { useLayout } from 'ui-core/dist/useLayout';
import {
  TabContainer
} from 'ui-core/dist';
import DetailPaneHeader from 'ui-core/dist/DetailPaneHeader';
import styled from 'styled-components/dist/styled-components';
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
import { detailPaneAnimStates } from '../../commonResources/constants/animStates';
import { useSelector, useDispatch } from 'react-redux';
import ErrorPage from '../ErrorPage';
import LoadingComponent from '../../components/LoadingComponent';
import { resetRecord } from './Modules/action';
import { useDocumentDetailSource } from '../../api/useDocumentManager';
import ErrorDetail from './ErrorDetail';

const StyledList = styled.ul`
list-style-type:none;
display:block;
font-size:10px;
margin-top:-9px;
`
const StyledListItem = styled.li`
display:inline-block;
border:1px solid black;
margin-right:10px;
margin-top:5px;
padding-left:10px;
padding-right:10px;
text-align:center;
width:${props => props.width || 'auto'}
`
const DocumentManagerDocDetailsPane = () => {
  const {
    expandCollapseDetailPaneButtonConfig,
    closeDetailPaneButtonConfig,
    detailAnimationState
  } = useLayout()
  const dispatch = useDispatch()
  const { record, currentShowMoreTarget }
    = useSelector(({ documentSearch }) => documentSearch);

  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    if (record && detailAnimationState === detailPaneAnimStates.FADE_CONTENT) {
      getShowMoreData(record.documentKey, record.source);
      setTabIndex(currentShowMoreTarget === "showMore" ? 0 : 1)
    }
  }, [record, currentShowMoreTarget, detailPaneAnimStates]);
  useEffect(() => {
    if (detailAnimationState === detailPaneAnimStates.COLLAPSED && showMoreInfo) {
      dispatch(resetRecord())
    }
  }, [detailAnimationState, showMoreInfo])

  const getShowMoreData = (documentKey, source) => {
    postDataShowMoreInfo(JSON.parse(JSON.stringify({ pstrDocKey: documentKey, pstrSourceTable: source })))
  }

  const { showMoreInfo, postData: postDataShowMoreInfo, isLoading: isLoadingShowMoreInfo,
    isError: isErrorShowMoreInfo, error: errorShowMoreINfo } = useDocumentDetailSource()

  const renderClaimNumbersList = (x) => {
    if(x && x.length>0){
        let first=[]
        let rest=[]
        if(x.length>4){
          first=x.slice(0,4)
          rest=[...x].splice(4,x.length-4)
        } else if(x.length===4){
          first=x.slice(0,4)
        } else if(x.length<4){
          first=x.slice(0,x.length)
        }
        let _x=null;
        if(first && first.length>0){
          let width = '80px';
          if (first[0].length > 6 && first[0].length <= 8) {
            width = '100px'
          }
          if (first[0].length > 8 && first[0].length <= 10) {
            width = '120px'
          }
          if (first[0].length > 10) {
            width = '163px'
          }
            _x= <StyledList>
            {first[0] ? (<StyledListItem width={width}>{first[0]}</StyledListItem>) : null}
            {first[1] ? (<StyledListItem width={width}>{first[1]}</StyledListItem>) : null}
            {first[2] ? (<StyledListItem width={width}>{first[2]}</StyledListItem>) : null}
            {first[3] ? (<StyledListItem width={width}>{first[3]}</StyledListItem>) : null}
          </StyledList>
        }
        if(rest && rest.length>0){
            return React.createElement(React.Fragment, null, [
                _x,
                renderClaimNumbersList(rest)
            ])
        }
        return _x;
    }
    return null;
  }

  const renderDocDetails = useMemo(() => {
    if (showMoreInfo) {
      const lastRoutedAddress = [...showMoreInfo.lastRoutedAddress.recipName.filter(x => x),
      ...showMoreInfo.lastRoutedAddress.recipAdd1.filter(x => x),
      ...showMoreInfo.lastRoutedAddress.recipAdd2.filter(x => x),
      ...showMoreInfo.lastRoutedAddress.recipCty.filter(x => x),
      ...showMoreInfo.lastRoutedAddress.recipStat.filter(x => x),
      ...showMoreInfo.lastRoutedAddress.recipZip.filter(x => x)];
      const lastRoutedAddressComp = (
        <> {lastRoutedAddress.join(' ')}</>)
      const delivAdd = showMoreInfo.deliveryAddress;
      const deliveryAddressInfo = `${delivAdd.name ? delivAdd.name : ''} ${delivAdd.line1 ? ',' + delivAdd.line1 : ''} 
        ${delivAdd.line2 ? ',' + delivAdd.line2 : ''} ${delivAdd.city ? ',' + delivAdd.city : ''}
        ${delivAdd.state ? ',' + delivAdd.state : ''} ${delivAdd.postalCode ? ',' + delivAdd.postalCode : ''}`;
      const deliveryAddressInfoComp = (
        <>{deliveryAddressInfo}</>)
      return (
        <>
          <div className="marginLeft">
            <div className="displayFlex" style={{ background: 'lightgray', marginLeft: '-10px' }}>
              <p style={{ marginLeft: '10px', fontWeight: 'bold' }}>Details for Document
                <span style={{ marginLeft: '5px' }}>{showMoreInfo.documentKey}</span>
              </p>
            </div>
            <div className="displayFlex">
              <label style={{ fontWeight: 'bold' }}>Document Type: </label>
              <div className="marginLeft">
                {showMoreInfo.relationCodeDescription}
              </div>
            </div>
            <div className="displayFlex">
              <label style={{ fontWeight: 'bold' }}>Recipient Type: </label>
              <div className="marginLeft">
                {showMoreInfo.recipientTypeDescription}
              </div>
            </div>
            <div className="displayFlex">
              <label style={{ fontWeight: 'bold' }}>Check Number: </label>
              <div className="marginLeft">
                {showMoreInfo.checkNumber}
              </div>
            </div>
            <div className="displayFlex">
              <label style={{ fontWeight: 'bold' }}>Check Amount: </label>
              <div className="marginLeft">
                {showMoreInfo.checkAmount}
              </div>
            </div>
            <div className="displayFlex">
              <label style={{ fontWeight: 'bold' }}>Claim Numbers: </label>
              {record?.claimNumbers.constructor.name === 'Array' || record?.claimNumbers.claimNumber.constructor.name === 'Array' ? (
                // <div style={{ display: 'block' }}>
                //   {renderClaimNumbersList(showMoreInfo.claimNumbers.claimNumber)}
                // </div>
                <div style={{ display: 'block' }}>
                  {showMoreInfo.claimNumbers.claimNumber.length>1000?<p><b>The result displays 1000 claims of total {showMoreInfo.claimNumbers.claimNumber.length} claims.</b></p>:null}
                  <div style={{overflow:'auto', maxHeight:'400px'}}>
                    <div style={{marginTop:'5px', marginBottom:'5px'}}>{renderClaimNumbersList(showMoreInfo.claimNumbers.claimNumber.length>1000?showMoreInfo.claimNumbers.claimNumber.slice(0,1000):showMoreInfo.claimNumbers.claimNumber)}</div>
                  </div>
                </div>
              ) : <div className="marginLeft">
                  {record?.claimNumbers.claimNumber}
                </div>}
            </div>
            <div className="displayFlex">
              <label style={{ fontWeight: 'bold' }}>Delivery Address: </label>
              <div className="marginLeft">
                {deliveryAddressInfoComp}
              </div>
            </div>
            <div className="displayFlex">
              <label style={{ fontWeight: 'bold' }}>Last Routed Address: </label>
              <div className="marginLeft">
                {lastRoutedAddressComp}
              </div>
            </div>
          </div>
        </>
      )
    }
    return null;
  }, [showMoreInfo]);

  const renderShowMoreSection = useCallback((args) => {
    if (args === "showMore") {
      return (<div style={{ padding: '45px' }}>
        <div className='displayFlex'></div>
        {renderDocDetails}
      </div>)
    }
    return (<ErrorDetail />)
  }, [showMoreInfo, record, currentShowMoreTarget, detailAnimationState]);

  const renderTabContainer = useMemo(() => {
    if (showMoreInfo && showMoreInfo.status === 'Errored')
      return (<div style={{ overflow: 'auto', minHeight: "600px" }}>
        <TabContainer
          className="tab_container_classname"
          contentClass="tab_content_classname"
          dataTestId="test-tabInitial"
          domID="tab-container-initial"
          initialTab={tabIndex}
          onTabChange={() => false}
          tabs={[
            {
              domID: 'id-1',
              label: 'Details',
              tabContent: <>{renderShowMoreSection("showMore")}</>
            },
            {
              domID: 'id-2',
              label: 'Error Details',
              tabContent: <>{renderShowMoreSection("errorDetails")}</>
            },
          ]}
        /></div>
      )
    return <>{renderShowMoreSection("showMore")}</>
  }, [record, showMoreInfo, detailAnimationState, currentShowMoreTarget])

  return (
    <>
      <DetailPaneHeader
        title="Document Details"
        layoutControls={[
          expandCollapseDetailPaneButtonConfig,
          closeDetailPaneButtonConfig
        ]}
      />
      <>
        {isLoadingShowMoreInfo ? (
          // replace with a better loading component
          <div style={{ marginLeft: '50%' }}><LoadingComponent /></div>
        ) : isErrorShowMoreInfo ? (
          // replace with a better error component
          <ErrorPage
            // this prop isn't real but is an example
            error={errorShowMoreINfo}
          />
        ) : (
              <>
                {renderTabContainer}
              </>
            )
        }
      </>
    </>
  )
}

export default DocumentManagerDocDetailsPane