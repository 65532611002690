import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Grid,
  useLayout,
  ListPaneHeader,
} from 'ui-core/dist'
import useRoleManager from '../../api/useRoleManager'
import { GridPaginationWrapper, Loading, SelectDropdown } from '../../components'
import ErrorPage from '../ErrorPage';
import withAxiosCancellation from '../../components/withAxiosCancellation'
// import './modules/styles.scss'

const FirstFilter = ({ onFirstFilter, initialValue }) => (
  <SelectDropdown
    domID="role-manager__first-filter"
    isClearable={false}
    label=""
    options={[
      {
        label: 'Active',
        value: false
      },
      {
        label: 'Inactive',
        value: true
      }
    ]}
    size="small"
    initialValue={initialValue}
    onChange={onFirstFilter}
  />
)

const RoleMangerListPane = ({getCancelSourceWithName}) => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [sortField, setSortField] = useState('id')
  const [sortDescending, setSortDecending] = useState(false)
  const [viewInactive, setViewInactive] = useState({
    label: 'Active',
    value: false
  })
  const [payload, setPayload] = useState({
    maxRecords: rowsPerPage,
    rowPosition: pageIndex,
    sortField,
    sortDescending,
    viewInactive: viewInactive.value,
  })
  const [selectedRecord,setSelectedRecord]=useState('')
  const {
    expandCollapseListPaneButtonConfig, openDetailPane
  } = useLayout()
  const canView = useSelector(({ context }) =>
    context?.permissions?.filter(p => p.name === 'CAN_PERFORM_SECURITY_ROLE_VIEW').length > 0)
  const { data, isLoading, total, isError, error } = useRoleManager({ payload, getCancelSource:getCancelSourceWithName('userRolemanager') })

  const onSortGridColumn = (e, { sortingKey }) => {
    const splitKey = sortingKey.split('|')
    setSortField(splitKey[0])
    if (splitKey[1] === 'SORT_DESCENDING') setSortDecending(true)
    else setSortDecending(false)
    setPayload({
      ...payload,
      sortField: splitKey[0],
      sortDescending: splitKey[1] === 'SORT_DESCENDING'
    })
  }

  const handleShowButton = ({ pi }) => {
    if (viewInactive.value !== payload.viewInactive) {
      setPayload({
        maxRecords: 10,
        rowPosition: 0,
        sortField: 'id',
        sortDescending: false,
        viewInactive: viewInactive.value,
      })
      setRowsPerPage(10)
      setPageIndex(0)
      setSortField('id')
      setSortDecending(false)
    } else {
      setPageIndex(pi)
      setPayload({
        maxRecords: rowsPerPage,
        rowPosition: pi,
        sortField,
        sortDescending,
        viewInactive: viewInactive.value,
      })
    }
  }

  const onFirstFilter = (state) => setViewInactive(state)

  const onClickThrough = (e, item) => {
    setSelectedRecord(item)
    openDetailPane({ id: item.id })
  }

  return (
    <>
      <ListPaneHeader
        title="Role Manager"
        layoutControls={[expandCollapseListPaneButtonConfig]}
      />
      {isLoading ? <Loading />: 
            isError ? (
            // replace with a better error component
            <ErrorPage
              // this prop isn't real but is an example
              error={error}
            />
          ) : (
      <>  
      
      <div style={{ marginLeft: '40px' }}>
        <GridPaginationWrapper
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={setRowsPerPage}
          pageIndex={pageIndex}
          onChangePageIndex={setPageIndex}
          onShowButtonCLick={handleShowButton}
          totalRecords={total}
          defaultRowsPerPage={10}
          FirstFilter={() => (
            <FirstFilter onFirstFilter={onFirstFilter} initialValue={viewInactive} />
          )}
        />
      </div>

      
        <Grid
          columns={new Set([
            {
              dataName: 'id',
              text: 'Role Id',
              sortable: true,
              isSorted: 0,
              cellType: 'text',
            },
            {
              dataName: 'name',
              text: 'Role Name',
              sortable: true,
              isSorted: 0,
              cellType: 'text',
            },
            {
              dataName: 'description',
              text: 'Description',
              sortable: true,
              isSorted: 0,
              cellType: 'text',
            },
          ])}
          records={data || []}
          dataTestId="test-RoleManagerGrid"
          domID="RoleManagerGrid"
          selectionKey='id'
          onClickThrough={canView ? onClickThrough : false}
          onSortGridColumn={onSortGridColumn}
          focusedItem={selectedRecord}
          initialSortingKey={`${sortField}|${sortDescending ? 'SORT_DESCENDING' : 'SORT_ASCENDING'}`}
        />
      
    </>
  )}
  </>
  )
}

export default withAxiosCancellation(RoleMangerListPane)