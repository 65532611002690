import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Tooltip } from 'ui-core-ssgr';
import { TruncatedColumn } from '../../../../shared/styles/styledComponents';
import { floatToDollarString } from '../../../../shared/helpers/money';
import { isA11yClick } from '../../../../shared/helpers/domEvents';
import { mapIterable } from '../../../../shared/helpers/iterables';
const onKeyPress = (e, callback) => {
  if (isA11yClick(e)) {
    e.preventDefault();
    callback(e);
  }
};
export const ClickableRow = ({
  onClickThrough = () => false,
  onRowSelect = () => false,
  supportSelection = false,
  selectedItemIds = new Set([]),
  isFocused = false,
  selectionKey = '',
  disabledColumns = new Set([]),
  columns = new Set([]),
  record,
  initialDisabledItemIds,
}) => {
  const disabled = initialDisabledItemIds.has(record.ruleType);
  const isSelected = selectedItemIds.has(record.ruleType);

  const classes = [];
  if (record.emboldened) classes.push('emboldened');
  if (isFocused) classes.push('focused');
  if (disabled) classes.push('selected');
  return (
    <tr
      onClick={() => {}}
      onKeyPress={e => onKeyPress(e, onClickThrough)}
      role="button"
      tabIndex="0"
      className={classes.join(' ')}
    >
      {supportSelection ? (
        <td className="select-checkbox" key="select column">
          <Checkbox
            name={
              record &&
              `${record[selectionKey].replace(/ /g, '-')}-row-select-checkbox`
            }
            className="table-row-checkbox"
            onChange={() => onRowSelect(record, isSelected)}
            value={isSelected}
            disabled={disabled}
            stopClickPropagation
          />
        </td>
      ) : null}
      {mapIterable(columns.values(), c => {
        if (disabledColumns.has(c.dataName)) return null;
        if (c.dataName === 'ruleTypeName') {
          return (
            <TruncatedColumn key={c.dataName} className={c.dataName}>
              {record.ruleTypeName ? (
                <Tooltip
                  tooltipWidth={120}
                  tooltipContent={`${record.ruleTypeName}`}
                >
                  {record.ruleTypeName}
                </Tooltip>
              ) : (
                <br />
              )}
            </TruncatedColumn>
          );
        }
        if (c.dataName === 'associatedTestRule') {
          return (
            <TruncatedColumn key={c.dataName} className={c.dataName}>
              {record.associatedTestRule ? (
                <Tooltip tooltipContent={record.associatedTestRule}>
                  {record.associatedTestRule}
                </Tooltip>
              ) : (
                <br />
              )}
            </TruncatedColumn>
          );
        }
        if (c.dataName === 'activeGroup') {
          return (
            <TruncatedColumn key={c.dataName} className={c.dataName}>
              {record.activeGroup ? (
                <Tooltip tooltipContent={String(record.activeGroup.length)}>
                  {Array.prototype.map
                    .call(record.activeGroup, s => ` ${s}`)
                    .toString()}
                </Tooltip>
              ) : (
                <br />
              )}
            </TruncatedColumn>
          );
        }
        if (c.dataName === 'associatedLiveRule') {
          return (
            <TruncatedColumn key={c.dataName} className={c.dataName}>
              {record.associatedLiveRule ? (
                <Tooltip tooltipContent={record.associatedLiveRule}>
                  {record.associatedLiveRule}
                </Tooltip>
              ) : (
                <br />
              )}
            </TruncatedColumn>
          );
        }
        return (
          <td key={c.dataName} className={c.dataName}>
            {c.isCurrency
              ? floatToDollarString(record[c.dataName])
              : record[c.dataName]}
          </td>
        );
      })}
      <td />
    </tr>
  );
};
ClickableRow.propTypes = {
  columns: PropTypes.shape({
    dataName: PropTypes.string,
    values: PropTypes.func,
  }).isRequired,
  disabledColumns: PropTypes.shape({
    dataName: PropTypes.string,
    has: PropTypes.func,
  }).isRequired,
  record: PropTypes.any.isRequired,
  onClickThrough: PropTypes.func,
  onRowSelect: PropTypes.func,
  supportSelection: PropTypes.bool,
  selectedItemIds: PropTypes.object,
  selectionKey: PropTypes.string,
  isFocused: PropTypes.bool,
  initialDisabledItemIds: PropTypes.object,
};
export default ClickableRow;
