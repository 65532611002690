import {
  RESET_DETAIL_PANE_TO_INITIAL_STATE,
  SET_DEFAULT_GROUP_DETAILS_TAB,
  SET_ADVANCED_GROUP_DETAILS_TAB,
  SET_DETAIL_PANE_TO_INITIAL_STATE_FOR_CLONE
} from './constants';

export const resetDetailsPane = () => ({
  type: RESET_DETAIL_PANE_TO_INITIAL_STATE,
  payload: null,
});

export const setDefaultGroupDetailsTab = tabIndex => ({
  type: SET_DEFAULT_GROUP_DETAILS_TAB,
  payload: tabIndex,
});

export const setAdvancedGroupDetailsTab = tabIndex => ({
  type: SET_ADVANCED_GROUP_DETAILS_TAB,
  payload: tabIndex,
});

export const setDetailsPaneToInitialStateForClone = () => ({
  type: SET_DETAIL_PANE_TO_INITIAL_STATE_FOR_CLONE,
  payload: null,
});

export default {
  resetDetailsPane,
  setDefaultGroupDetailsTab,
  setAdvancedGroupDetailsTab,
  setDetailsPaneToInitialStateForClone
};
