const Error = (error = { status: '' }) => {
  switch (error.status) {
    case 401:
      return {
        message: 'Unauthorized to access resource. Please contact admin.',
        status: 401,
        error: 'Unauthorized',
        displayMessage: error?.data?.displayMessage,
      }
    case 404:
      return {
        message: 'Not found.',
        status: 404,
        error: 'NotFound',
        displayMessage: error?.data?.displayMessage,
      }
    case 403:
      return {
        message: 'Already exists',
        status: 403,
        error: 'Exists',
        displayMessage: error?.data?.displayMessage,
      }
    case 400:
      return {
        message: error?.data?.message,
        status: 400,
        error: error?.data?.name,
        displayMessage: error?.data?.displayMessage || error?.data?.error?.fs || error?.data?.error?.message || error?.data?.error || error?.data?.message,
        //ipAddress: error?.data?.ipAddress
      }
    case 503:
      return {
        message: 'Service Unavailable',
        status: 503,
        error: 'ConnectionError',
        displayMessage: error?.data?.displayMessage || error?.data?.message,
      }
    default:
      return {
        message: 'Internal Server Error',
        status: 500,
        error: 'Unknown',
        displayMessage: error?.data?.displayMessage || 'Service Unavailable. Please contact client service via customer care hub (https://customercare.changehealthcare.com/public/home.html)',
      }
  }
}

export default Error
