import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import {
  Button,
  LoadingIndicator,
  Modal,
  Warning,
  Grid,
} from 'ui-core-ssgr';
import { ButtonGroup } from '../../../../../../shared/components/ButtonGroup';
import Colors from '../../../../../../shared/styles/colors';
import { DivWithPaddingTop } from '../../../../../../shared/styles/spacing';
import { 
  ModalTitlePaddingWrapper,
  ModalPaddingWrapper, 
  ModalContentPaddingWrapper, 
  BoldTextAfterWarningIcon, 
  IconWrapper,
  StyledHeader, 
} from '../../../../../../shared/styles/styledComponents';
import { LoadingWrapper } from './styles';
import { detailProps } from './constants';


const GroupAssociationsModal = ({
  setIsViewingGroupAssociationsModal,
  selectedRecord,
  postGroupAssociationsForRuleTypeTrigger,
  selectedRuleType,
  associating,
  selectedRuleTypes,
  isShowingGroupAssociationModal,
  isButtonDisabled,
  loading,
  selectedRuleIds
}) => {
  
  const getSelectedRules = () => {
    const associatedRuleTypesWithRules = map(selectedRuleTypes, type => {
      const { associatedRuleName, name, id } = type;
      let { rules } = type;
      rules = map(rules, rule => ({
        ...rule,
        associatedRuleName,
        ruleType: id,
        ruleTypeName: name,
      }));
      return { ...type, rules };
    });

    const matchingRules = [];

    Object.entries(selectedRuleIds).forEach(([ruleTypeName, ruleId]) => {
      const findAssociatedRules = associatedRuleTypesWithRules.find(
        rule => rule.name === ruleTypeName,
      );

      if (findAssociatedRules && findAssociatedRules.rules) {
        const findRule = findAssociatedRules.rules.find(
          rule => rule.id === ruleId,
        );

        if (findRule) {
          matchingRules.push(findRule);
        }
      }
    });

    const filteredMatchingRules =  matchingRules.filter(
      rule => rule && rule.associated === 'No',
    );

    return filteredMatchingRules;
  };

  const cancelPressed = () => {
    setIsViewingGroupAssociationsModal({
      showing: false,
      associatedRuleType: null
    });
  };

  const onConfirm = () => {
    const selected = getSelectedRules();
    let selectedRules = [];

    if (!selected.length) {
      selectedRules = [{ ruleId: 'None', ruleType: selectedRuleType.id }];
    } else {
      selectedRules = map(selected, rule => ({
        ruleId: rule.id,
        ruleType: rule.ruleType,
      }));
    }

    postGroupAssociationsForRuleTypeTrigger({
      groupId: selectedRecord.id,
      associating,
      selectedRules,
    });
  };

  const headerValue = action => {
    const selectedRules = getSelectedRules();
    if (action === 'headerText') {
      let headerText = '';
      if (selectedRules.length && selectedRules.length === 1) {
        headerText = `Associate: ${selectedRules[0].name}`;
      }
      if (selectedRules.length && selectedRules.length > 1) {
        headerText = `Test Associate to group: ${selectedRecord.name}`;
      }
      if (!selectedRules.length) {
        headerText = `Disassociate: ${selectedRuleType.name}`;
      }
      return headerText;
    }
    if (action === 'associationText') {
      let middleText = '';
      if (selectedRules.length && selectedRules.length === 1) {
        middleText = `You are test associating ${selectedRules[0].name} to ${selectedRecord.name}`;
      }
      if (!selectedRules.length) {
        middleText = `You are disassociating the rule type ${selectedRuleType.name} from group ${selectedRecord.name}`;
      }
      return middleText;
    }
    if (action === 'associatedCheck') {
      const selectedRule = selectedRules[0];
      const footerText =
        selectedRules.length > 0
          ? `${selectedRule.name} will take place of the test association ${selectedRule.associatedRuleName}`
          : `${selectedRecord.name} will no longer use the rule type ${selectedRuleType.name}`;
      return footerText;
    }
    return '';
  };

  const renderGrid = () => {
    const selectedRules = getSelectedRules();
    let grid = '';

    if (selectedRules.length > 1) {
      grid = <Grid {...detailProps} records={selectedRules} />;
    } else if (headerValue('associatedCheck')) {
      grid = (
        <>
          <IconWrapper>
            <Warning fillColor={Colors.negativeDark} />
          </IconWrapper>
          <BoldTextAfterWarningIcon>
            {headerValue('associatedCheck')}
          </BoldTextAfterWarningIcon>
        </>
      );
    }

    return grid;
  };

  const isLoading =
  isShowingGroupAssociationModal &&
  loading.has('postingGroupAssociationsLoading');

  return (
    <>
      {isShowingGroupAssociationModal ? (
        <Modal
          isOpen={isShowingGroupAssociationModal}
          onModalToggle={cancelPressed}
        >
          <ModalPaddingWrapper>
            <ModalTitlePaddingWrapper>
              <StyledHeader>
                {headerValue('headerText')}
              </StyledHeader>
            </ModalTitlePaddingWrapper>
            <ModalContentPaddingWrapper>
              {headerValue('associationText')}
            </ModalContentPaddingWrapper>
            {renderGrid()}
            <DivWithPaddingTop>
              <ButtonGroup>
                <LoadingWrapper>
                  {isLoading && (
                    <LoadingIndicator
                      domID="basic-details-form-loading-indicator"
                      length="30px"
                    />
                  )}
                </LoadingWrapper>
                <Button
                  onClick={onConfirm}
                  domID="automation-id"
                  name="Confirm"
                  buttonType="emphasized"
                  size="medium"
                  isDropdown={false}
                  type="button"
                  disabled={isButtonDisabled || isLoading}
                />
                <Button
                  domID="automation-id"
                  name="Cancel"
                  buttonType="diminished"
                  disabled={isButtonDisabled || isLoading}
                  size="medium"
                  onClick={cancelPressed}
                  isDropdown={false}
                  type="button"
                />
              </ButtonGroup>
            </DivWithPaddingTop>
          </ModalPaddingWrapper>
        </Modal>
      ) : null}
    </>
  );
};

GroupAssociationsModal.propTypes = {
  selectedRuleTypes: PropTypes.array,
  selectedRuleIds: PropTypes.object,
  selectedRecord: PropTypes.object,
  postGroupAssociationsForRuleTypeTrigger: PropTypes.func,
  setIsViewingGroupAssociationsModal: PropTypes.func,
  isButtonDisabled: PropTypes.bool,
  isShowingGroupAssociationModal: PropTypes.bool,
  selectedRuleType: PropTypes.object,
  associating: PropTypes.bool,
  loading: PropTypes.object,
};

export default GroupAssociationsModal;
