export const NEW_RULE_ID_LIST = [
  'PAR',
  'RAD',
  'HLD',
  'CHT',
  'CSM',
  'DPX',
  'ERI',
  'GEN',
  'HDR',
  'IMG',
  'PIO',
  'SSN',
  'SIG',
  'TAX',
  'PDT'
];
