import {
  DOCUMENT_SEARCH,
  SET_SELECTED_NAV_ITEM,
} from './constants';

export const documentSearch = payload => ({
  type: DOCUMENT_SEARCH,
  payload
})

export const setSelected = payload => ({
  type: SET_SELECTED_NAV_ITEM,
  payload
})
