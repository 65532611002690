import styled from 'styled-components';
import { DetailHeader } from 'ui-core-ssgr';



export const SubDetailHeader = styled(DetailHeader)`
  h1 {
    font-size: 20px;
  }
  padding-left: 2em;
`;


  

