import styled from 'styled-components';

export default styled.div`
    opacity: 1;
    display: block;
    position: absolute;
    box-shadow: 0 2px 8px 2px rgba(0, 0, 0, .3);
    padding: 3px;
    background-color:white;

    &.top {
      bottom: 100%;
      margin-bottom: 1em;
      transform: translateX(-50%);
      left: 50%;
    }

    &.right {
      left: 100%;
      margin-left: 1em;
      transform: translateY(-50%);
      top: 50%;
    }

    &.bottom {
      top: 100%;
      margin-top: 1em;
      transform: translateX(-50%);
      left: 50%;
    }

    &.left {
      right: 100%;
      margin-right: 1em;
      transform: translateY(-50%);
      top: 50%;
    }
`;
