/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { useDispatch, } from 'react-redux';
import {
  useLayout,
  Button
} from 'ui-core/dist'
import {
  LoadingPage,
  CountDownTimer
} from '../../../components';
import ErrorPage from '../../ErrorPage';
import {
  GENERIC_TEXT1,
  GENERIC_TEXT2,
  GENERIC_TEXT_HOLD
} from "../Modules/constants";
import withAxiosCancellation from '../../../components/withAxiosCancellation'
import ListPaneHeaderWrapper from '../../../components/ErrorDisplaySection/ListPaneHeaderWrapper';
import DocManagerFileGroupSearchForm from '../DocManagerFileGroupSearchForm';

import { useCurrentTime, useReleaseTime } from '../../../api/useDocumentManager';
import '../Modules/styles.scss';
import { setFileSearchParam, setFileSearchParamNull } from "../Modules/action";

const GroupSearchHoldMain = ({getCancelSourceWithName}) => {
  const {
    expandCollapseListPaneButtonConfig
  } = useLayout()

  const history = useHistory();
  const dispatch = useDispatch();

  const [isResetFlag, setIsResetFlag] = useState(false);
  const [fileKeys, setFileKeys] = useState('');
  const [empGroup, setEmpGroup] = useState('');
  const [tinSSN, setTinSSN] = useState('');
  const [checkNo, setCheckNo] = useState('');
  const [claimNo, setClaimNo] = useState('');
  const [itemsPerPage, setItemsPerPage] = useState({ label: 10, value: 10 });
  const [hasLimitExceededstate, sethasLimitExceededstate] = useState(false)
  const { currentTime, isError: isErrorCurrentTime, error: errorCurrentTime, isLoading: isLoadingCurrentTime } = useCurrentTime({getCancelSource:getCancelSourceWithName('groupSearchHoldCurrentTime')});
 const { releaseTime, isError: isErrorReleaseTime, error: errorReleaseTime, isLoading: isLoadingReleaseTime } = useReleaseTime({getCancelSource:getCancelSourceWithName('groupSearchHoldReleaseTime')});
  // const isLoading = false;
  // const isError = false;
  // const error = '';
  const [transactionMessage, settransactionMessage]=useState('')

  useEffect(()=>{
    if (history?.location && history?.location?.state) {
      settransactionMessage(history?.location?.state);
      history.replace({ ...history?.location, state: undefined });
    }
  },[history?.location])
  const onSelectEmpGroup = (items) => {
    let egroup = '';
    if (hasLimitExceededstate) {
      egroup = items.value || ""
    } else if (items && items.length > 0 && items.indexOf('') === -1) {
      egroup = '<![CDATA[<EmpGroupIds>'
      items.forEach(item => {
        egroup += `<EmpGroupId>${item}</EmpGroupId>`
      })
      egroup += '</EmpGroupIds>]]>'
    }
    setEmpGroup(egroup);
  }

  const nextClick = () => {
    const payload = {
      "maxRecords": itemsPerPage ? itemsPerPage.value : 10,
      "rowPosition": 0,
      "sortField": 'filekey',
      "sortDescending": true,
      "checkNumber": checkNo,
      "claimNumber": claimNo.replace(/['"]/g, ''),
      "groupId": empGroup,
      "idNumber": tinSSN,
      "filekey": fileKeys,
      "showDuplicateChecks": true,
      "showProcessedDocuments": false
    }
    dispatch(setFileSearchParamNull(payload))
    dispatch(setFileSearchParam(payload))
    history.push(`/DocumentManager/Hold/Group/searchResults`)
  }

  return (
    <>
      <ListPaneHeaderWrapper
        title="Document Manager: Hold Groups"
        layoutControls={[expandCollapseListPaneButtonConfig]}

      />
      {isLoadingCurrentTime || isLoadingReleaseTime ? (
        // replace with a better loading component
        <LoadingPage />
      ) : isErrorCurrentTime || isErrorReleaseTime ? (
        // replace with a better error component
        <ErrorPage
          // this prop isn't real but is an example
          error={errorCurrentTime || errorReleaseTime}
        />
      ) : (
            <div className="mainWrapper">
              {releaseTime && currentTime ?
                <CountDownTimer
                  startDate={releaseTime}
                  endDate={currentTime}
                /> : ''}
              <div style={{ fontSize: "small", padding: "12px" }}>
                <i>{GENERIC_TEXT1}</i>
                <p>Type a partial or complete claim number, check number, TIN, or SSN to find the groups that contain documents matching your search option. 
                  You can also search for a specific group or you can search for all groups included in one or more files. 
                  Click Next to display the search results page where you can select the groups you want to place on hold.</p>
                <p style={{ color: "#bf1616" }}>
                  {GENERIC_TEXT_HOLD}
                </p>
                {transactionMessage && <p style={{ color: "black", fontWeight:'bold' }}>{transactionMessage}</p>}
              </div>
              <DocManagerFileGroupSearchForm
              getCancelSourceWithName={getCancelSourceWithName}
                isCheckNumberExist
                isClaimNumberExist
                isSSNExist
                changeItemsPerPage={(value) => setItemsPerPage(value)}
                changeTinSsn={(value) => setTinSSN(value)}
                changeClaimNo={(value) => setClaimNo(value)}
                changeCheckNo={(value) => setCheckNo(value)}
                onSelectEmpGroup={(value) => onSelectEmpGroup(value)}
                changeFileKey={(value) => setFileKeys(value)}
                hasLimitExceeded={(value) => sethasLimitExceededstate(value)}
                isSortingExist={false}
                isResetFlag={isResetFlag}
                setIsResetFlag={setIsResetFlag}
                perPage={itemsPerPage}
                nextClick={nextClick}
              />
              <div className="displayFlex marginLeft">
                <Button
                  buttonType="standard"
                  size="small"
                  name="RESET"
                  onClick={() => {
                    setIsResetFlag(true);
                  }}
                />
                <Button
                  buttonType="standard"
                  size="small"
                  style={{ marginLeft: '10px' }}
                  name="NEXT->"
                  onClick={() => { nextClick() }}
                />
              </div>
            </div>
          )}
    </>
  )
}

export default withAxiosCancellation(GroupSearchHoldMain)
