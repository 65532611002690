import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDashbordPaymentAndSavings } from '../../api/useDashboard';
import { LoadingPage } from '../../components';
import withAxiosCancellation from '../../components/withAxiosCancellation'


const PaymentAndSaving = ({ getCancelSourceWithName }) => {
  const { dataSourceDesc } = useSelector(({ context }) => context?.context || {});
  const clientId = dataSourceDesc.split('-')[0].trim().replace('_', '');
  const { data: payMentAndSavingData, isLoading: pIsLoading } = useDashbordPaymentAndSavings(clientId, getCancelSourceWithName('payMentAndSavingData'));

  const formatToCurrency = (amount) => {
    return "$" + amount?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  }


  return (
    <>
      <div>
        <h3 className="paymentHeader">Payment & savings Metrics</h3>
        <div className="paymentBody">
          {
            pIsLoading ? (<LoadingPage height='10vh' />) : (
              <>
                <p>
                  <div className="alignPaymentItems paymentItems">
                    <div className="paymentleftItem">POSTAGE CO-OP SAVING LAST 12 MONTHS</div>

                    <div className="paymentRightItem">
                      {payMentAndSavingData?.hpusaSavings ? formatToCurrency(payMentAndSavingData?.hpusaSavings) : '$0.00'}
                    </div>
                  </div>
                </p>
                <p>
                  <div className="alignPaymentItems paymentItems">
                    <div className="paymentleftItem">NUMBER OF PAYMENTS PROCESSED LAST 30 DAYS</div>

                    <div className="paymentRightItem">{payMentAndSavingData?.numPayments || 0}</div>
                  </div>
                </p>

                <p>
                  <div className="alignPaymentItems paymentItems">
                    <div className="paymentleftItem">TOTAL PAYMENT AMOUNT PROCESSED LAST 30 DAYS</div>

                    <div className="paymentRightItem">{payMentAndSavingData?.amtPayments ? formatToCurrency(payMentAndSavingData?.amtPayments) : '$0.00'}</div>
                  </div>
                </p>

              </>
            )
          }

        </div>
      </div>
    </>
  )

}

export default withAxiosCancellation(PaymentAndSaving)