import { connect } from 'react-redux';
import BasicDetailsForm from './components/BasicDetailsForm';
import {
  addNotification,
  deleteNotification,
  deleteAllNotifications,
} from '../../redux/app/notificationState/actions';
import {
  setIsCloningGroupState,
  triggerGroupsPostRequest,
  triggerGroupsPutRequest,
} from '../../redux/api/groups/actions';
import { triggerAllGroupsRequest } from '../../redux/api/groupsList/actions';
import { selectCurrentDetailsRecord } from '../GroupDetailView/selectors';
import {
  selectGroupCloningState,
  selectLoadingState,
  selectNotificationState,
  serverConfig,
} from './selectors';

const mapStateToProps = state => ({
  ...state,
  serverConfig: serverConfig(state),
  loading: selectLoadingState(state),
  isCloningGroup: selectGroupCloningState(state),
  notifications: selectNotificationState(state),
  selectedRecord: selectCurrentDetailsRecord(state),
});

const mapDispatchToProps = {
  setIsCloningGroupState,
  triggerGroupsPostRequest,
  triggerGroupsPutRequest,
  triggerAllGroupsRequest,
  addNotification,
  deleteNotification,
  deleteAllNotifications,
};

export default connect(mapStateToProps, mapDispatchToProps)(BasicDetailsForm);
