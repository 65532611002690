import React, { useState, useMemo } from 'react';
import {
    useLayout,
    ListPaneHeader,
    Button, Input, DatePickerV2
} from 'ui-core/dist'
import { SelectDropdown } from '../../components'
import axios from '../../axios';
import useNotificationBannerHook from '../../useNotificationBannerHook';
import { LoadingPage } from '../../components';
import './modules/style.scss'

const ACSReport = () => {
    const {
        expandCollapseListPaneButtonConfig
    } = useLayout();
    const { notify } = useNotificationBannerHook();
    const [isLoading, setIsLoading] = useState(false);
    const SELECT_REPORT_SORTOrder_OPTIONS = [{
        label: "Flatfile Data Report",
        value: "Flatfile"
    },
    {
        label: "Printable Report",
        value: "Printable"
    },
    ];
    const [intialReportFormat, setIntialReportFormat] = useState({
        label: "Flatfile Data Report",
        value: "Flatfile"
    });

    const [email, setEmail] = useState();
    const [reportFormat, setReportFormat] = useState("Flatfile");
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();

    const getReport = async () => {
        if (!ValidatestartAndDate()) {
            notify(`From Date should be less than To Date.`, 'caution')
        }
        else if (!ValidateFutureDate()) {
            notify(`Future Date is not allowed.`, 'caution')
        }

        else {
            let objTosave = {
                "fromDate": fromDate,
                "toDate": toDate,
                "emailId": email,
                "reportFormat": reportFormat
            };
            try {
                setIsLoading(true);
                const res = await axios.post(
                    `reports-manager/acs-report`,
                    objTosave
                )
                setIsLoading(false);
                setFromDate('')
                setToDate('');
                setEmail('')
                setIntialReportFormat({
                    label: "Flatfile Data Report",
                    value: "Flatfile"
                })
                setReportFormat('Flatfile')
                notify(`ACS ${reportFormat} Report Web Transaction ${res.data.reportId} has been submitted.If  you have not received this report within six(6)hours, please contact client service  via customer care hub (https://customercare.changehealthcare.com/public/home.html).If you do not have access to customer care hub, please contact your internal administrator.`, 'positive')
            } catch (err) {
                setIsLoading(false);
                setIsLoading(false);
                setFromDate('')
                setToDate('');
                setEmail('')
                setIntialReportFormat({
                    label: "Flatfile Data Report",
                    value: "Flatfile"
                })
                setReportFormat('Flatfile')
                notify(`${err.displayMessage}`, 'negative')
            }
        }
    }

    const ValidateEmail = (newEmail) => {
        return (/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(newEmail || email) ? false : true);
    }

    const renderEmailAdres = useMemo(() => (
        <Input
            aria-label="ARIA Label"
            autoComplete="off"
            dataTestId="test-inputBasic"
            domID="basic-test-id"
            size="small"
            maxLength={50}
            errorMessage="Please enter a valid Email"
            hasError={ValidateEmail()}
            name="emailAddress"
            onChange={(e) => {
                setEmail(e.target.value || "");
            }}
        />
    ), [email]);
    const rendeStartDate = useMemo(() => (
        <DatePickerV2
            domID="renderstartDatePicker"
            errorMessage="Please enter a valid Date"
            hasError={fromDate ? false : true}
            onDateChange={(e) => {
                setFromDate(e ? e.format('MM/DD/YYYY') : '')
            }}
            size="small"

        />
    ), [fromDate]);
    const rendeEndDate = useMemo(() => (
        <DatePickerV2
            domID="renderstartDatePicker"
            errorMessage="Please enter a valid Date"
            hasError={toDate ? false : true}
            onDateChange={(e) => {
                setToDate(e ? e.format('MM/DD/YYYY') : '')
            }}
            size="small"

        />
    ), [toDate]);
    const rendeReportFormatOption = useMemo(() => (
        <SelectDropdown
            width="260px"
            isClearable={false}
            domID="reportSortOrder"
            errorMessage="Please select report Format"
            hasError={reportFormat ? false : true}
            options={SELECT_REPORT_SORTOrder_OPTIONS}
            initialValue={intialReportFormat}
            size="small"
            onChange={function handelReportFormatChange(e) {
                setIntialReportFormat({
                    label: e?.label,
                    value: e?.value
                });
                setReportFormat(e?.value);
            }}
        />
    ), [reportFormat]);
    const ValidatestartAndDate = () => {
        let startDate = new Date(fromDate);
        let endDate = new Date(toDate);
        return startDate > endDate ? false : true;
    }
    const ValidateFutureDate = () => {
        let startDate = new Date(fromDate);
        let endDate = new Date(toDate);
        let today = new Date();
        if ((startDate > today) || (endDate > today)) {
            return false;
        }
        else {
            return true;
        }


    }
    const validateForm = useMemo(() => {
        if (ValidateEmail(email) || !fromDate || !toDate || !reportFormat) {
            return false;
        }
        return true;
    }, [email, fromDate, toDate, reportFormat])

    return (
        <>
            <ListPaneHeader
                title={`ACS Report`}
                layoutControls={[expandCollapseListPaneButtonConfig]}
            />
            { isLoading ? (
                <LoadingPage />
            ) :
                <div className="marginLeftUserReport">
                    <table id="userReportSearch">
                        <tr>
                            <td className='userreport-labels'><label>Received from USPS Between:</label></td>
                            <td className='userReportcriteriaWidth'>
                                {rendeStartDate}
                            &nbsp;<span className='acsDateLabel'>and</span>  &nbsp;
                            {rendeEndDate}

                            </td>
                        </tr>
                        <tr>
                            <td className='userreport-labels'><label>Email Address for Delivery of Report:</label></td>
                            <td className='userReportcriteriaWidth'>
                                {renderEmailAdres}
                            </td>
                        </tr>


                        <tr>
                            <td className='userreport-labels'><label>ACS Report Format:</label></td>
                            <td className='userReportcriteriaWidth'>
                                {rendeReportFormatOption}
                            </td>
                        </tr>

                        <tr><td>
                            {
                                (<Button
                                    buttonType="standard"
                                    size="small"
                                    name="Submit"
                                    disabled={!validateForm}
                                    onClick={() => { getReport() }}
                                />)
                            }
                        </td><td></td></tr>
                    </table>

                </div>
            }
        </>
    )
}

export default ACSReport;