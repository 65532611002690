export const FILE_RECEIPT_MANAGER_MAIN_GRID_COLUMN ={
  columns:new Set([
    {
      dataName: 'filekey',
      text: 'FILE KEY',
      sortable: true,
      isSorted: 0,
      cellType: 'custom',
    },
    {
      dataName: 'receivedDate',
      text: 'RECEIVED DATE',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'abfFileName',
      text: 'ABF FILE NAME',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'originalFileName',
      text: 'ORIG. NAME',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'importedDate',
      text: 'IMPORTED DATE',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'status',
      text: 'STATUS',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
  ]),
  selectedItemIds: new Set([]),
  bookmarkedItemIds: new Set([]),
  domID: 'FileReceiptGrid',
  isConfigurable: true,
  supportSelection: false
}

export const DOWNLOAD_ORIGINAL_FILE_GRID_COLUMN ={
  columns:new Set([
    {
      dataName: 'originalFile',
      text: 'ORIGINAL FILE',
      sortable: false,
      isSorted: 0,
      cellType: 'custom',
    },
    {
      dataName: 'fileType',
      text: 'FILE TYPE',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'fileSize',
      text: 'FILE SIZE',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'dateModified',
      text: 'DATE MODIFIED',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
  ]),
  selectedItemIds: new Set([]),
  bookmarkedItemIds: new Set([]),
  domID: 'DownloadOriginalFileGrid',
  isConfigurable: false,
  supportSelection: false
}


export const SELECT_RECORD_OPTIONS = [
  {label:'1', value:'1'},
  {label:'3', value:'3'},
  {label:'5', value:'5'},
  {label:'10', value:'10'},
  {label:'25', value:'25'},
  {label:'50', value:'50'},
]
