import React from 'react';
import PropTypes from 'prop-types';
import { PaddingWrapper } from '../../../../../../shared/styles/spacing';
import { AssociatedGroupsHeader } from './styles';

const AssociateGridHeader = ({
  recordCount,
  headerName
}) => (
  <PaddingWrapper>
    <AssociatedGroupsHeader
      title={headerName || ''}
      amountKey="amount"
      count={recordCount > 0 ? recordCount : null}
    />
  </PaddingWrapper>
);

AssociateGridHeader.defaultProps = {
  recordCount: 0,
};

AssociateGridHeader.propTypes = {
  recordCount: PropTypes.number,
  headerName: PropTypes.string,
};

export default AssociateGridHeader;
