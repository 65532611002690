import { select, call, put, takeLatest } from 'redux-saga/effects';
import {
  getCommitRuleTypesList,
  putMultipleCommitRules,
  putCommitMultipleRuleAssociations,
} from './network';
import {
  getCommitRuleTypesListReceived,
  getCommitRuleTypesListRequestError,
  multipleCommitPutSuccess,
  multipleCommitPutError,
  setIsShowingMultipleCommitRulesModal,
  isShowingCommitMultipleTestAssocRuleModalOpen,
  commitMultipleTestAssocRulesError,
  commitMultipleTestAssocRulesSuccess,
} from './actions';
import { triggerAllRulesForRuleTypesRequest, rulesCountFromDBTrigger } from '../rulesForRuleTypesList/actions';
import { setDefaultGroupDetailsTab } from '../../app/detailsPaneState/actions';
import { triggerGetLiveAssocRuleTypesRequest } from '../liveAssocRuleTypesList/actions';
import { addNotification } from '../../app/notificationState/actions';
import { setLoadingState } from '../../app/loadingState/actions';
import {
  GET_COMMIT_RULETYPES_REQUEST_TRIGGER,
  MULTIPLE_RULES_COMMIT_PUT_REQUEST_TRIGGER,
  TRIGGER_COMMIT_FOR_MULTIPLE_TEST_ASSOCIATION_RULES,
} from './constants';
import {
  commitRuleForRuleTypeError,
  setIsCommittingRule,
} from '../rulesForRuleTypes/actions';
import {
  triggerRuleTypesRequest,
} from '../ruleTypesList/actions';
import { triggerAllGroupsRequest } from '../groupsList/actions';

function* getCommitRuleTypesListGenerator(data) {
  yield put(
    setLoadingState({
      id: 'getCommitRuleTypesListLoading',
      isLoading: true,
    }),
  );

  const { groupId } = data.payload;
  try {
    const { clientLayoutId, userSecurityTokenKey, username, roleId } = yield select(
      ({ serverConfig }) => serverConfig,
    );

    const commitRuleTypesArray = yield call(
      getCommitRuleTypesList,
      clientLayoutId,
      userSecurityTokenKey,
      username,
      groupId,
      roleId
    );

    // If no error was thrown we can assume we got our list of rule types successfully,
    // but we can also do additional validation here
    if (Array.isArray(commitRuleTypesArray)) {
      // If we got what we expect, dispatch our success action
      yield put(
        getCommitRuleTypesListReceived({ commitRuleTypesArray, groupId }),
      );
      yield put(
        setLoadingState({
          id: 'getCommitRuleTypesListLoading',
          isLoading: false,
        }),
      );
    } else {
      yield put(
        setLoadingState({
          id: 'getCommitRuleTypesListLoading',
          isLoading: false,
        }),
      );

      // We got a 200 response that was valid JSON, but the expected data type
      // was not returned from the server so we pass a custom error out with our
      // failure action
      yield put(
        getCommitRuleTypesListRequestError(
          'Error with CommitRuleTypesListRequest',
          groupId,
        ),
      );
    }
  } catch (thrownError) {
    yield put(
      setLoadingState({
        id: 'getCommitRuleTypesListLoading',
        isLoading: false,
      }),
    );
    yield put(
      addNotification({
        id: thrownError.errorId,
        type: 'negative',
        msg: thrownError.message,
      }),
    );
    yield put(getCommitRuleTypesListRequestError(thrownError, groupId));
  }

  // After we have sent our new data off to the reducer we can still dispatch
  // additional actions to update app state, for example to resolve the loading
  // state set in the example above
  // yield put(AppActions.stopWaiting());
}

// This generator is the control flow manager that will step through the request
// lifecycle and dispatch actions to update the application at the end
function* commitMultipleRulesPutGenerator(action) {
  // Before we make our network request we can dispatch actions to modify app
  // state, for example to show a spinner:
  // yield put(AppActions.startWaiting());\
  yield put(
    setLoadingState({
      id: 'commitMultipleRulesLoading',
      isLoading: true,
    }),
  );
  yield put(setIsCommittingRule(true));
  try {
    // get values sent via server-side config to use with this call
    const { clientLayoutId, userSecurityTokenKey, username, roleId } = yield select(
      ({ serverConfig }) => serverConfig,
    );
    const ruleIdArray = Array.from(action.payload.selectedItemIds);
    yield call(
      putMultipleCommitRules,
      ruleIdArray,
      clientLayoutId,
      userSecurityTokenKey,
      username,
      roleId,
    );
    // If we got what we expect, dispatch our success action
    if (action.payload) {
      yield put(multipleCommitPutSuccess(true));
      yield put(
        setLoadingState({
          id: 'commitMultipleRulesLoading',
          isLoading: false,
        }),
      );
      yield put(setIsShowingMultipleCommitRulesModal(false));
      yield put(setIsCommittingRule(false));
      const name = 'dateModified';
      const sortOrder = 'desc';
      const queryValue = '';
      const url = window.location.href;
      const ruleTypeId = url.substr(url.lastIndexOf('=') + 1);
      yield put(
        triggerAllRulesForRuleTypesRequest({
          offset: 0,
          queryValue,
          name,
          sortOrder,
          ruleTypeId,
        }),
      );
      yield put(
        rulesCountFromDBTrigger({
          offset: 0,
          queryValue,
          name,
          sortOrder,
          ruleTypeId,
        }),
      );
      yield put(
        addNotification({
          id: ruleTypeId,
          type: 'positive',
          msg: 'The selected rules have been committed to live successfully.',
          icon: 'Info',
        }),
      );
    }
  } catch (thrownError) {
    // We got a 200 response that was valid JSON, but the expected data type
    // was not returned from the server so we pass a custom error out with our
    // failure action
    yield put(multipleCommitPutError(thrownError));
    yield put(
      setLoadingState({
        id: 'commitMultipleRulesLoading',
        isLoading: false,
      }),
    );
    yield put(
      addNotification({
        id: thrownError.errorId,
        type: 'negative',
        msg: thrownError.message,
      }),
    );
    const name = 'dateModified';
    const sortOrder = 'desc';
    const queryValue = '';
    const url = window.location.href;
    const ruleTypeId = url.substr(url.lastIndexOf('=') + 1);
    yield put(
      triggerAllRulesForRuleTypesRequest({
        offset: 0,
        queryValue,
        name,
        sortOrder,
        ruleTypeId,
      }),
    );
    yield put(
      rulesCountFromDBTrigger({
        offset: 0,
        queryValue,
        name,
        sortOrder,
        ruleTypeId,
      }),
    );
    yield put(setIsShowingMultipleCommitRulesModal(false));
  }
}

// This saga gets attached to the redux store, and listens for action types
// similar to the reducers. When it matches an action type it will run the
// generator indicated and pass it the action as an argument.

// ----------  commiting multiple test assoc rules
function* commitforMultipleTestAssocRulesPutGenerator(action) {
  try {
    const userConfig = yield select(({ serverConfig }) => serverConfig);

    const response = yield call(
      putCommitMultipleRuleAssociations,
      userConfig,
      action.payload,
    );

    if (response && !response.error) {
      if (action.payload) {
        const status = window.location.search.includes('all') ? 'all' : action.payload.selectedRecordStatus.toLowerCase();
        const name = 'dateModified';
        const sortOrder = 'desc';
        const queryValue = '';

        yield put(
          commitMultipleTestAssocRulesSuccess({
            response,
          }),
        );
        yield put(isShowingCommitMultipleTestAssocRuleModalOpen(false));
        yield put(setDefaultGroupDetailsTab(3));
        yield put(
          addNotification({
            id: action.payload.id,
            type: 'positive',
            msg: response.success,
          }),
        );
        yield put(
          triggerAllGroupsRequest({ status, name, sortOrder, queryValue }),
        );
        yield put(triggerRuleTypesRequest({ groupId: action.payload.groupId }));
      }
    } else {
      yield put(
        commitMultipleTestAssocRulesError({
          msg: new Error(response.error.message),
        }),
      );
      yield put(isShowingCommitMultipleTestAssocRuleModalOpen(false));
      const { groupId } = action.payload;
      yield put(triggerGetLiveAssocRuleTypesRequest({ groupId }));
      yield put(
        addNotification({
          id: action.payload.id,
          type: 'negative',
          msg: response.error.message,
        }),
      );
    }
  } catch (thrownError) {
    yield put(
      addNotification({
        id: thrownError.errorId,
        type: 'negative',
        msg: thrownError.message,
      }),
    );
    yield put(commitRuleForRuleTypeError(thrownError));
  }
}

function* commitRuleTypesListSaga() {
  yield takeLatest(
    GET_COMMIT_RULETYPES_REQUEST_TRIGGER,
    getCommitRuleTypesListGenerator,
  );
  yield takeLatest(
    MULTIPLE_RULES_COMMIT_PUT_REQUEST_TRIGGER,
    commitMultipleRulesPutGenerator,
  );
  yield takeLatest(
    TRIGGER_COMMIT_FOR_MULTIPLE_TEST_ASSOCIATION_RULES,
    commitforMultipleTestAssocRulesPutGenerator,
  );
}

export default commitRuleTypesListSaga;
