import React, { Component } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import {
  Grid,
  Button,
  LoadingIndicator,
  GridCell,
  NotificationBanner,
} from 'ui-core-ssgr';
import { Portal } from 'react-portal';
import get from 'lodash/get';
import {
  gridConfig,
  gridActiveGroupConfig,
} from './constants';
import {
  RulesGridWrapperStyle,
  LoadMoreButton,
  InputLabelWrapper,
  LoadingWrapper,
  GridCellWrap,
  LoadingContainer
} from './styles';

import { GridLoadingWrapper } from '../../../../shared/styles/styledComponents';

import ClickableRow from '../ClickableRow';
import RulesGridHeader from '../GridHeader';
import CloneRuleModal from '../CloneRuleModal';
import ActiveGroupsListingModal from '../ActiveGroupsListingModal';
import { setIsCommittingRule } from '../../../../redux/api/rulesForRuleTypes/actions';

class RulesGridWrapper extends Component {
  state = {
    selectedItemIds: new Set([]),
    queryValue: '',
    sortOrder: 'desc',
    name: 'dateModified',
    ruleTypeId: '',
    disableButton: false,
  };

  componentDidMount() {
    const { getFreshRulesList } = this;
    const {setRuleTypeRequestTrigger, captureCurrentPage} = this.props;
    setRuleTypeRequestTrigger();
    const url = window.location.href;
    const selectedRuleTypeId = url.substr(url.lastIndexOf('=') + 1);
    this.setState({ ruleTypeId: selectedRuleTypeId }, () => {
      getFreshRulesList();
    });
    // following action is called to capture new relic data
    captureCurrentPage({pageId: `${gridConfig.pageTrackerPrefix}${selectedRuleTypeId}`});
  }

  componentDidUpdate(prevProps) {
    const {
      selectedRecord,
      deleteNotification,
      isCommittingRule,
      isCloningRule,
      isPostingRulesForRuleTypes,

    } = this.props;
    if (
      (prevProps.isCloningRule && !isCloningRule) ||
      (prevProps.isCommittingRule && !isCommittingRule) ||
      (prevProps.isPostingRulesForRuleTypes && !isPostingRulesForRuleTypes)
    ) {
      this.setState({ selectedItemIds: new Set([]), queryValue: '' });

    }
    if (
      selectedRecord &&
      prevProps.selectedRecord &&
      prevProps.selectedRecord.id !== selectedRecord.id
    ) {
      deleteNotification(prevProps.selectedRecord.id);
    }
  }

  getFreshRulesList = (e, sortObj) => {
    const {
      triggerAllRulesForRuleTypesRequest,
      rulesCountFromDBTrigger,
      resetRuleTypeRecords,
    } = this.props;
    const {sortingKey} = sortObj || {sortingKey: ''};
    const [columnName, direction] = sortingKey.split('|');
    const { queryValue, ruleTypeId } = this.state;
    let {sortOrder, name} = this.state;

    if(columnName) name = columnName;
    if(direction) sortOrder = direction === 'SORT_ASCENDING' ? 'desc' : 'asc';
    if(columnName === 'activeGroupLabel') {
      return;
    }
    resetRuleTypeRecords();
    rulesCountFromDBTrigger({
      queryValue,
      sortOrder,
      name,
      ruleTypeId,
    });
    triggerAllRulesForRuleTypesRequest({
      offset: 0,
      queryValue,
      name,
      sortOrder,
      ruleTypeId,
    });
  };

  onGridSelectAll = () => {
    const { selectedItemIds } = this.state;
    const { records } = this.props;
    const alreadyFull = selectedItemIds.size === records.length;
    const ids = alreadyFull ? [] : records.map(({ id }) => id);

    this.setState({ selectedItemIds: new Set(ids) });
  };

  onRowClickThrough = (e, { id }) => {
    const {
      toggleDetailsView,
      records,
      isPostingRulesForRuleTypes,
      setIsViewingRuleDetails,
      setIsPostingRulesForRuleTypes,
      isCloningRule,
      setIsCloningRuleState,
      updateCurrentTab,
    } = this.props;
    updateCurrentTab({ currentTab: 1 });
    toggleDetailsView(true);
    if (isPostingRulesForRuleTypes) setIsPostingRulesForRuleTypes(false);
    if (isCloningRule) setIsCloningRuleState(false);
    const record = records.find(rule => rule.id === id);
    const url = window.location.href;
    const ruleTypeId = url.substr(url.lastIndexOf('=') + 1);
    setIsViewingRuleDetails({
      isAdvancedViewShown: true,
      selectedRuleRecord: record,
      selectedRuleRecordId: id,
      selectedRuleTypeId: ruleTypeId,
    });
    this.setState({ queryValue: '' });
  };

  onRowSelect = ({ id }) => {
    const { selectedItemIds } = this.state;
    const isBeingSelected = !selectedItemIds.has(id);
    const method = isBeingSelected ? 'add' : 'delete';
    selectedItemIds[method](id);
    this.setState({ selectedItemIds });
  };

  currentRuleTypeData = () => {
    const {allRuleTypes} = this.props;
    const ruleTypeUrl = window.location.href.includes('ruletype') ? window.location.href.substring(
      window.location.href.lastIndexOf('=') + 1,
    ) : '';
    const currentRuleType =  allRuleTypes.filter((ruleType)=> ruleType.id === ruleTypeUrl);
    return currentRuleType && currentRuleType.length > 0 ? currentRuleType[0] : null;
  };

  loadMore = () => {
    const { sortOrder, ruleTypeId, name, queryValue } = this.state;
    const { triggerAllRulesForRuleTypesRequest, records } = this.props;
    this.setState({ disableButton: true });
    triggerAllRulesForRuleTypesRequest({
      offset: records.length,
      sortOrder,
      name,
      ruleTypeId,
      queryValue,
    });
    this.setState({ disableButton: false });
  };

  handleQueryUpdate = queryValue => {
    const { records } = this.props;
    const { getFreshRulesList } = this;
    this.setState({ queryValue }, () => {
      getFreshRulesList();

      if (records.length === 0) {
        this.setState({ queryValue: '' });
      }
    });
  };

  handleCellClick = (event, record) => {
    const { setIsShowingActiveGroupsListingModal } = this.props;

    event.preventDefault();
    event.stopPropagation();

    setIsShowingActiveGroupsListingModal({
      showActiveGroupsListingModal: true,
      activeGroupsModalLocation: 'RulesGridView',
      selectedActiveRecordsList: record,
      selectedRecordName: record.name,
    });
  };

  generateGridRecordsFromProps = gridRecords => map(gridRecords, record => (
    {
      ...record,
      activeGroupLength: record.activeGroup.length,
      activeGroupLabel:
        record.activeGroup !== 'None' &&
        typeof record.activeGroup !== 'string' &&
        record.activeGroup.length >= 1 ? (
            <GridCellWrap onClick={e => this.handleCellClick(e, record)}>
              <GridCell
                cellType="link"
                data={{
                  domID: record.id,
                  text: `${record.activeGroup.length}`,
                  href: '',
                }}
              />
            </GridCellWrap>
          ) : (
            0
          ),
    }
  ));

  render() {
    const {
      addNotification,
      notifications,
      records,
      loadMore,
      count,
      deleteNotification,
      isPostingRulesForRuleTypes,
      rulesCountFromDBTrigger,
      setIsPostingRulesForRuleTypes,
      setIsViewingRuleDetails,
      toggleDetailsView,
      resetDetailsPane,
      updateCurrentTab,
      selectedRuleRecord,
      setIsShowingCloneRuleModal,
      setIsCloningRuleState,
      showCloneRuleModal,
      isCloningRule,
      isExpanded,
      loading,
      setRecordForDetailPane,
      setIsMultipleRulesCommitingState,
      setIsShowingMultipleCommitRulesModal,
      showMultipleCommitRulesModal,
      triggerMultipleCommitPutRequest,
      triggerAllRulesForRuleTypesRequest,
      multipleCommitPutSuccess,
      multipleCommitPutError,
      showActiveGroupsListingModal,
      setIsShowingActiveGroupsListingModal,
      selectedActiveRecordsList,
      selectedRecordName,
      activeGroupsModalLocation,
      triggerImagesTestCountRequest,
      multipleTestImageCountRecords,
      userRole,
      userPermissions,
      ruleGridQuery
    } = this.props;
    const {
      selectedItemIds,
      disableButton,
    } = this.state;
    const {
      onGridSelectAll,
      onRowClickThrough,
      onRowSelect,
      currentRuleTypeData,
      handleQueryUpdate,
      handleCellClick,
    } = this;
    const counterLabel = !loading.has('rulesForRuleTypesGridLoading') ? `${records.length}/${count}` : '';

    return (
      <RulesGridWrapperStyle>
        {notifications.size > 0 && (
          <Portal key="rules-grid-wrapper">
            {notifications
              ? [...notifications].map(([key, value]) => (
                <NotificationBanner
                  key={[key]}
                  domID={`${key}-notification`}
                  type={value.type}
                  text={value.msg}
                  onDismiss={() => deleteNotification(key)}
                  autoDismiss
                  timer={1800000}
                  icon={value.type === 'neutral' ? 'Info' : null}
                />
              ))
              : null}
          </Portal>
        )}
        {showCloneRuleModal && (
          <CloneRuleModal
            setRecordForDetailPane={setRecordForDetailPane}
            records={records}
            isExpanded={isExpanded}
            selectedItemIds={selectedItemIds}
            selectedRuleRecord={selectedRuleRecord}
            setIsShowingCloneRuleModal={setIsShowingCloneRuleModal}
            setIsCloningRuleState={setIsCloningRuleState}
            showCloneRuleModal={showCloneRuleModal}
            isCloningRule={isCloningRule}
            toggleDetailsView={toggleDetailsView}
            resetDetailsPane={resetDetailsPane}
            addNotification={addNotification}
            updateCurrentTab={updateCurrentTab}
          />
        )}
        {showActiveGroupsListingModal && activeGroupsModalLocation === 'RulesGridView' && (
          <ActiveGroupsListingModal
            setIsShowingActiveGroupsListingModal={
              setIsShowingActiveGroupsListingModal
            }
            showActiveGroupsListingModal={showActiveGroupsListingModal}
            gridActiveGroupConfig={gridActiveGroupConfig}
            selectedActiveRecordsList={selectedActiveRecordsList}
            selectedRecordName={selectedRecordName}
          />
        )}
        <RulesGridHeader
          headerName={get(currentRuleTypeData(), 'name', '')}
          setIsCommittingRule={setIsCommittingRule}
          setRecordForDetailPane={setRecordForDetailPane}
          addNotification={addNotification}
          deleteNotification={deleteNotification}
          isPostingRulesForRuleTypes={isPostingRulesForRuleTypes}
          rulesGridQueryUpdate={handleQueryUpdate}
          triggerCountUpdate={rulesCountFromDBTrigger}
          currentRuleTypeData={currentRuleTypeData()}
          recordCount={count}
          records={records}
          loading={loading}
          selectedItemIds={selectedItemIds}
          selectedAll={false}
          setIsMultipleRulesCommitingState={setIsMultipleRulesCommitingState}
          setIsShowingMultipleCommitRulesModal={
            setIsShowingMultipleCommitRulesModal
          }
          showMultipleCommitRulesModal={showMultipleCommitRulesModal}
          setIsPostingRulesForRuleTypes={setIsPostingRulesForRuleTypes}
          setIsViewingRuleDetails={setIsViewingRuleDetails}
          toggleDetailsView={toggleDetailsView}
          updateCurrentTab={updateCurrentTab}
          setIsShowingCloneRuleModal={setIsShowingCloneRuleModal}
          setIsCloningRuleState={setIsCloningRuleState}
          showCloneRuleModal={showCloneRuleModal}
          isCloningRule={isCloningRule}
          selectedRuleRecord={selectedRuleRecord}
          resetDetailsPane={resetDetailsPane}
          triggerMultipleCommitPutRequest={triggerMultipleCommitPutRequest}
          notifications={notifications}
          triggerAllRulesForRuleTypesRequest={
            triggerAllRulesForRuleTypesRequest
          }
          multipleCommitPutError={multipleCommitPutError}
          multipleCommitPutSuccess={multipleCommitPutSuccess}
          counterLabel={counterLabel}
          handleCellClick={handleCellClick}
          triggerImagesTestCountRequest={triggerImagesTestCountRequest}
          multipleTestImageCountRecords={multipleTestImageCountRecords}
          userRole={userRole}
          userPermissions={userPermissions}
          ruleGridQuery={ruleGridQuery}
        />
        {loading.has('rulesForRuleTypesGridLoading') && !loadMore && (
          <LoadingContainer>
            <GridLoadingWrapper>
              <LoadingIndicator
                domID="rules-grid-loading-indicator"
                length="45px"
              />
            </GridLoadingWrapper>
          </LoadingContainer>
        )}
        <Grid
          {...gridConfig}
          key={count}
          records={this.generateGridRecordsFromProps(records)}
          selectedItemIds={selectedItemIds}
          setIsPostingRulesForRuleTypes={setIsPostingRulesForRuleTypes}
          rowComponent={ClickableRow}
          onClickThrough={onRowClickThrough}
          onRowSelect={onRowSelect}
          onSelectAll={onGridSelectAll}
          multipleCommitPutError={multipleCommitPutError}
          multipleCommitPutSuccess={multipleCommitPutSuccess}
          focusedItem={selectedRuleRecord}
          onSortGridColumn={this.getFreshRulesList}
        />
        <LoadMoreButton>
          <InputLabelWrapper>{loading.size === 0 ? `${counterLabel} rules loaded` : ''}</InputLabelWrapper>

          {(loading.has('rulesForRuleTypesGridLoading') && loadMore) || disableButton ? (
            <LoadingWrapper>
              <LoadingIndicator
                domID="basic-details-form-loading-indicator"
                length="45px"
              />
            </LoadingWrapper>
          ) : (
            loadMore && (
              <Button
                name={`Load ${
                  count - records.length > 100 ? 100 : count - records.length
                } More`}
                buttonType="deEmphasized"
                onClick={this.loadMore}
                size="medium"
                type="button"
              />
            )
          )}
        </LoadMoreButton>
      </RulesGridWrapperStyle>
    );
  }
}

RulesGridWrapper.propTypes = {
  addNotification: PropTypes.func,
  deleteNotification: PropTypes.func,
  groupAssociationsForRuleIdTrigger: PropTypes.func,
  selectedRecord: PropTypes.object,
  groupAssociationsForRuleId: PropTypes.array,
  isCloningRule: PropTypes.bool,
  isCommittingRule: PropTypes.bool,
  toggleDetailsView: PropTypes.func.isRequired,
  triggerAllRulesForRuleTypesRequest: PropTypes.func.isRequired,
  rulesCountFromDBTrigger: PropTypes.func.isRequired,
  leftStripConfig: PropTypes.object,
  columns: PropTypes.object,
  records: PropTypes.array,
  count: PropTypes.number,
  loadMore: PropTypes.bool,
  ruleDetailElementsTrigger: PropTypes.func,
  ruleTypeId: PropTypes.string,
  rulesGridQueryUpdate: PropTypes.func,
  resetDetailsPane: PropTypes.func,
  setIsViewingRuleDetails: PropTypes.func.isRequired,
  showCloneRuleModal: PropTypes.bool,
  showMultipleCommitRulesModal: PropTypes.bool,
  setIsShowingMultipleRulesCommitModal: PropTypes.func,
  triggerMultipleCommitPutRequest: PropTypes.func.isRequired,
  showActiveGroupsListingModal: PropTypes.bool,
  setIsShowingActiveGroupsListingModal: PropTypes.func,
  setRuleTypeRequestTrigger: PropTypes.func,
  isPostingRulesForRuleTypes: PropTypes.bool,
  setIsPostingRulesForRuleTypes: PropTypes.func,
  setIsCloningRuleState: PropTypes.func,
  updateCurrentTab: PropTypes.func,
  selectedRuleRecord: PropTypes.object,
  setIsShowingCloneRuleModal: PropTypes.func,
  isExpanded: PropTypes.bool,
  loading: PropTypes.object,
  setRecordForDetailPane: PropTypes.func,
  setIsMultipleRulesCommitingState: PropTypes.func,
  setIsShowingMultipleCommitRulesModal: PropTypes.func,
  allRuleTypes: PropTypes.array,
  notifications: PropTypes.object,
  multipleCommitPutSuccess: PropTypes.bool,
  multipleCommitPutError: PropTypes.bool,
  selectedCurrentRecord: PropTypes.object,
  selectedActiveRecordsList: PropTypes.object,
  selectedRecordName: PropTypes.string,
  activeGroupsModalLocation: PropTypes.string,
  triggerImagesTestCountRequest: PropTypes.func,
  multipleTestImageCountRecords: PropTypes.array,
  captureCurrentPage: PropTypes.func,
  userRole: PropTypes.string,
  userPermissions: PropTypes.string
};

export default RulesGridWrapper;
