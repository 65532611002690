import React, { useState } from 'react';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { Grid, LoadingIndicator, Button, Modal, DetailHeader } from 'ui-core-ssgr';
import { ButtonGroup } from '../../../shared/components/ButtonGroup';
import { gridRevertRuleVersionConfig } from './constants';
import {
  RuleTypesDetailRow,
  ScrollableGridWrapper
} from './styles';
import {
  LoadingWrapper,
  ModalButton,
} from '../../RulesTabView/components/RuleDetails/RuleDetailsGridWrapper/styles';
import {
  LoadingContainer,
  GridLoadingWrapper,
} from '../../../shared/styles/styledComponents';

const RevertRuleModal = ({
  loading,
  setIsShowingRevertRuleModal,
  isShowingRevertRuleModal,
  revertRuleVersionsList,
  postRevertRuleVersion,
  selectedRuleRecord,
  selectedRuleTypeId
}) => {

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [selectedVersion, setSelectedVersion] = useState(true);
  const {id, name} = selectedRuleRecord;
  const ruleId = id;

  const onCancel = () => {
    setIsShowingRevertRuleModal(false);
  };

  const onButtonClick = name => {
    if (name === 'confirm'){
      const versionFrom = selectedVersion;
      postRevertRuleVersion({selectedRuleTypeId, ruleId, versionFrom});
    }else{
      setIsShowingRevertRuleModal(false);
    }
  }

  const checkVersionRadio = (e, version) => {
    setSelectedVersion(version);
    setIsButtonDisabled(false);
  }

  const renderGridData = (versions) => {
    if(versions !== null && versions !== undefined){
      for (let j = 0; j < versions.length; j += 1) {
        const column = versions[j];
        const orignalVersion = column.version;
        if(column.version && typeof column.version === 'string'){
          column.version = (<><input
            type="radio"
            id="version-radio"
            name="versionRadio"
            value={orignalVersion}
            onClick={e => checkVersionRadio(e, orignalVersion)}
          />{' Version '}{orignalVersion}</>);
        }
        if(column.lastCommit && typeof column.lastCommit === 'number'){
          const numberDate = Number(column.lastCommit);
          column.lastCommit = moment(numberDate).format('MM/DD/YYYY HH:mm A');
        }
      }
    }
    return versions;
  }

  const ruleVersions = revertRuleVersionsList !== null && revertRuleVersionsList !== undefined ? revertRuleVersionsList.ruleVersions : null;

  return (
  <>
    {isShowingRevertRuleModal ? (
      <Modal
        domID="revert-modal"
        isOpen={isShowingRevertRuleModal}
        onModalToggle={onCancel}
      >
        <RuleTypesDetailRow>
          <DetailHeader
            domID="header-title-revert"
            title="Revert"
            marginBottom={10}
            description={`Revert the current test version of rule ${name} to a previous version of the live rule.`}
          />
        </RuleTypesDetailRow>
        <ScrollableGridWrapper>
          {loading.has(selectedRuleTypeId) ? (
            <LoadingContainer>
              <GridLoadingWrapper>
                <LoadingIndicator
                  domID="edit-test-grid-loading-indicator"
                  length="45px"
                />
              </GridLoadingWrapper>
            </LoadingContainer>
          ) : (
            <Grid
              {...gridRevertRuleVersionConfig}
              records={ruleVersions !== null && ruleVersions !== undefined ? renderGridData(ruleVersions.versions) : []}
              supportSelection={false}
            />
          )}
        </ScrollableGridWrapper>
        <ModalButton>
          {loading.has(ruleId) && (
            <LoadingWrapper>
              <LoadingIndicator
                domID="basic-details-form-loading-indicator"
                length="30px"
              />
            </LoadingWrapper>
          )}
          <ButtonGroup>
            {ruleVersions !== null && !isEmpty(ruleVersions.versions) ? 
              <Button
                name="confirm"
                buttonType="emphasized"
                onClick={() => onButtonClick('confirm')}
                size="medium"
                type="button"
                disabled={isButtonDisabled || loading.has(ruleId)}
              />
              : null }
            <Button
              name="cancel"
              buttonType="diminished"
              onClick={() => onButtonClick('cancel')}
              size="medium"
              type="button"
            />
          </ButtonGroup>
        </ModalButton>
      </Modal>
    ) : null} 
    </>
  );
}
RevertRuleModal.propTypes = {
  loading: PropTypes.object,
  setIsShowingRevertRuleModal: PropTypes.func,
  isShowingRevertRuleModal: PropTypes.bool,
  revertRuleVersionsList: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  selectedRuleRecord: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  selectedRuleTypeId: PropTypes.string,
  postRevertRuleVersion: PropTypes.func
};

export default RevertRuleModal;