export default {
    development: {
        API_ROOT_SERVER: 'http://localhost:443/',
        CAS_API_SERVER: 'http://localhost:444/api/groupRules/v2',
        DIRECT_PRINT: 'https://directprint-dev.mcds.awsnonprod.healthcareit.net:8443/api/directPrint/v1',
        API_CLIENT_ID: 'CHC-CAS-MARCHFORTH-DL',
        REDIRECT_URI: 'http://localhost:3000/login',
        // REDIRECT_URI: 'http://localhost:3000/login/',
        SENTINEL_URI: 'https://idx-stage.linkhealth.com/',
        SENTINEL_LOGOUT: 'https://idx-stage.linkhealth.com/auth/realms/developer-platform/protocol/openid-connect/logout'
    },
    qa: {
        API_ROOT_SERVER: 'https://cas-qa.mcds.awsnonprod.healthcareit.net/mw/',
        CAS_API_SERVER: 'https://casapi-qa.mcds.awsnonprod.healthcareit.net:8443/api/groupRules/v2',
        DIRECT_PRINT: 'https://directprint-qa.mcds.awsnonprod.healthcareit.net:8443/api/directPrint/v1',
        API_CLIENT_ID: 'CHC-CAS-MARCHFORTH-QA',
        REDIRECT_URI: 'https://cas-qa.mcds.awsnonprod.healthcareit.net/login/',
        SENTINEL_URI: 'https://idx-stage.linkhealth.com/',
        SENTINEL_LOGOUT: 'https://idx-stage.linkhealth.com/auth/realms/developer-platform/protocol/openid-connect/logout'
    },
    cert: {
        API_ROOT_SERVER: 'https://cascert.changehealthcare.com/mw/',
        CAS_API_SERVER: 'https://cascert.changehealthcare.com/api/groupRules/v2',
        DIRECT_PRINT: 'https://cascert.changehealthcare.com/api/directPrint/v1',
        API_CLIENT_ID: 'CHC-CAS-MARCHFORTH-CERT',
        REDIRECT_URI: 'https://cascert.changehealthcare.com/login/',
        SENTINEL_URI: 'https://idx-stage.linkhealth.com/',
        SENTINEL_LOGOUT: 'https://idx-stage.linkhealth.com/auth/realms/developer-platform/protocol/openid-connect/logout'
    },
    prod: {
        API_ROOT_SERVER: 'https://cas.changehealthcare.com/mw/',
        CAS_API_SERVER: 'https://cas.changehealthcare.com/api/groupRules/v2',
        DIRECT_PRINT: 'https://cas.changehealthcare.com/api/directPrint/v1',
        API_CLIENT_ID: 'CHC-CAS-MARCHFORTH-PROD',
        REDIRECT_URI: 'https://cas.changehealthcare.com/login/',
        SENTINEL_URI: 'https://idx.linkhealth.com/',
        SENTINEL_LOGOUT: 'https://idx.linkhealth.com/auth/realms/developer-platform/protocol/openid-connect/logout'
    }
}
