import React from 'react';
import PropTypes from 'prop-types';
// import { Button } from 'ui-core';
import { PaddingWrapper } from '../../../../../shared/styles/spacing';
// import { RightSideButtons } from '../../../../../shared/styles/styledComponents';
import { AssociatedGroupsHeader } from './styles';

const AssociateGridHeader = props => {
  const {
    isPostingRulesForRuleTypes,
    isCloningRule,
    recordCount,
    headerName,
  } = props;
  return (
    <PaddingWrapper>
      <AssociatedGroupsHeader
        title={headerName || ''}
        amountKey="amount"
        count={
          !isPostingRulesForRuleTypes && !isCloningRule && recordCount !== 0
            ? recordCount
            : null
        }
      />
      {/* <RightSideButtons>
        <Button
          name="commit associations"
          buttonType="emphasized"
          onClick={() => false}
          size="medium"
          type="button"
        />
        <Button
          name="associate a group"
          buttonType="deEmphasized"
          onClick={() => false}
          size="medium"
          type="button"
        />
      </RightSideButtons> */}
    </PaddingWrapper>
  );
};

AssociateGridHeader.defaultProps = {
  recordCount: 0,
};

AssociateGridHeader.propTypes = {
  recordCount: PropTypes.number,
  headerName: PropTypes.string,
};

export default AssociateGridHeader;
