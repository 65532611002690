/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */


import React, { useState, useMemo, useCallback, useEffect } from 'react';
import styled from 'styled-components/dist/styled-components'
import { useLayout } from 'ui-core/dist/useLayout';
import DetailPaneHeader from 'ui-core/dist/DetailPaneHeader';
import { useSelector, useDispatch } from 'react-redux';
import {
  Modal, Button, ModalHeader, Input, ModalBody
} from 'ui-core/dist';
import { detailPaneAnimStates } from '../../../commonResources/constants/animStates';
import ErrorPage from '../../ErrorPage';
import { SelectDropdown } from '../../../components'
import LoadingComponent from '../../../components/LoadingComponent';
import useNotificationBannerHook from '../../../useNotificationBannerHook';
import { resetRecipientTypeTranslationRecord } from '../Modules/action';
import {
  useRecipientTypeTranslationDetails, useGetSysRecipientTypes,
  useAddRecipientTypeTranslation, useUpdateRecipientTypeTranslation, useSetRecipTypeStatus,
  useDeleteRecipientTypeTranslation
} from '../../../api/useClientAdministrationRecipientType'

const StyledDropDownContainer = styled.div`
width: 370px !important;
& #drpDeliveryAddress, & #drpDeliveryTypes, & #drpReleaseItems {
    width: 370px !important;
}
`

const RecipientTypeTranslationDetail = () => {
  const {
    expandCollapseDetailPaneButtonConfig,
    closeDetailPaneButtonConfig,
    detailAnimationState,
    closeDetailPane
  } = useLayout()

  const { notify } = useNotificationBannerHook();
  const [currentrecipientType, setcurrentrecipientType] = useState(null);
  const [currentnormalizedRecipientType, setcurrentnormalizedRecipientType] = useState(null);
  const [currentwebRecipientTypeDesc, setcurrentwebRecipientTypeDesc] = useState(null);

  const [normalizedRecipientTypeSource, setnormalizedRecipientTypeSource] = useState([])
  const [clientRecipientTypeSource, setclientRecipientTypeSource] = useState([])

  const [confirmationModalMode, setconfirmationModalMode] = useState('')
  const [isOpenConfirmationModal, setisOpenConfirmationModal] = useState(false)
  const [count, setcount] = useState(0)

  const { recipientTypeTranslationRecord, recipientTypeTranslationMode, refetchRecipientTypes }
    = useSelector(({ clientAdministration }) => clientAdministration);

  const currentLayout = useSelector(({ context }) => context?.context?.layout)
  useEffect(() => {
    if (recipientTypeTranslationRecord) {
      if (recipientTypeTranslationRecord.recipientTypeTrans) {
        getShowMoreData(recipientTypeTranslationRecord.recipientTypeTrans);
      } else {
        onClearCurrentRecordValues()
      }
      getRecipientTypes()
    }
  }, [recipientTypeTranslationRecord]);


  const getShowMoreData = (recipientTypeTrans) => {
    postDataShowMoreInfo({ strRecipientType: recipientTypeTrans, count })
    setcount(count + 1)
  }

  const { showMoreInfo, postData: postDataShowMoreInfo, isLoading: isLoadingShowMoreInfo,
    isError: isErrorShowMoreInfo, error: errorShowMoreINfo } = useRecipientTypeTranslationDetails()

  const { directPostCall: getsysRecipientTypes, isLoading: isLoadingsysRecipientTypes,
    isError: isErrorsysRecipientTypes, error: errorsysRecipientTypes } = useGetSysRecipientTypes()

  const { data: infoAddRecipType, postData: addRecipientTypeTranslation, metaData: addRecipientTypeTranslationResponse, isLoading: isLoadingaddRecipientTypeTranslation,
    isError: isErroraddRecipientTypeTranslation, error: erroraddRecipientTypeTranslation } = useAddRecipientTypeTranslation()

  const { data: infoUpdateRecipType, postData: updateRecipientTypeTranslation, metaData: updateRecipientTypeTranslationResponse, isLoading: isLoadingupdateRecipientTypeTranslation,
    isError: isErrorupdateRecipientTypeTranslation, error: errorupdateRecipientTypeTranslation } = useUpdateRecipientTypeTranslation()

  const { data: infoChangeStatusRecipType, postData: setRecipTypeStatus, metaData: setRecipTypeStatusResponse, isLoading: isLoadingsetRecipTypeStatus,
    isError: isErrorsetRecipTypeStatus, error: errorsetRecipTypeStatus } = useSetRecipTypeStatus()

  const { data: infoDeleteRecipType, postData: deleteRecipientTypeTranslation, metaData: deleteRecipientTypeTranslationResponse, isLoading: isLoadingdeleteRecipientTypeTranslation,
    isError: isErrordeleteRecipientTypeTranslation, error: errordeleteRecipientTypeTranslation } = useDeleteRecipientTypeTranslation()

  const onResetCurrentRecordValues = () => {
    if (showMoreInfo) {
      const { recipientType, recipientTypeDesc, recipientTypeTrans } = showMoreInfo;
      setcurrentrecipientType(recipientTypeTrans)
      setcurrentnormalizedRecipientType(recipientType)
      setcurrentwebRecipientTypeDesc(recipientTypeDesc)
    }
  }
  const getRecipientTypes = async () => {
    const { data: data1 } = await getsysRecipientTypes({ tagname: 'RecipientTypes' });
    if (data1) {
      const { RecipientType } = data1;
      if (RecipientType) {
        let s = [];
        if (RecipientType.constructor.name === "Object") {
          s.push({ ...RecipientType })
        } else if (RecipientType.constructor.name === "Array") {
          s = [...RecipientType]
        }
        if (s && s.length > 0) {
          let r = [];
          if (currentLayout !== "028") {
            r = s.map((x) => ({ label: `${x.name}`, value: x.id }))
          } else {
            r = s.map((x) => ({ label: `${x.id} - ${x.description}`, value: x.id }))
          }
          setnormalizedRecipientTypeSource(JSON.parse(JSON.stringify(r)))
        }
      } else {
        setnormalizedRecipientTypeSource([])
      }
    } else {
      setnormalizedRecipientTypeSource([])
    }
    if (currentLayout !== "028") {
      const { data } = await getsysRecipientTypes({ parameters: "cvalue", tagname: 'RecipientTypes' })
      if (data) {
        const { RecipientType } = data;
        if (RecipientType) {
          let ClientRecipientTypes = [];
          if (RecipientType.constructor.name === "Object") {
            ClientRecipientTypes.push({ ...RecipientType })
          } else if (RecipientType.constructor.name === "Array") {
            ClientRecipientTypes = [...RecipientType]
          }
          if (ClientRecipientTypes && ClientRecipientTypes.length > 0) {
            const r = ClientRecipientTypes.map((x) => ({ label: `${x.id}`, value: x.id }))
            setclientRecipientTypeSource(JSON.parse(JSON.stringify(r)))
          }
        } else {
          setclientRecipientTypeSource([])
        }
      } else {
        setclientRecipientTypeSource([])
      }
    }
  }

  useEffect(() => {
    if (addRecipientTypeTranslationResponse) {
      const { status, statusText } = addRecipientTypeTranslationResponse;
      if (status === 200 || statusText === "OK") {
        if (infoAddRecipType && infoAddRecipType.fs) {
          const { fs } = infoAddRecipType;
          if (fs) {
            notify(fs, 'negative')
            return;
          }
        }
        notify('Recipient Type Added successfully', 'positive')
        closeDetailPane()
        if (refetchRecipientTypes) refetchRecipientTypes()
      }
    }
  }, [addRecipientTypeTranslationResponse])

  useEffect(() => {
    if (updateRecipientTypeTranslationResponse) {
      const { status, statusText } = updateRecipientTypeTranslationResponse;
      if (status === 200 || statusText === "OK") {
        if (infoUpdateRecipType && infoUpdateRecipType.fs) {
          const { fs } = infoUpdateRecipType;
          if (fs) {
            notify(fs, 'negative')
            return;
          }
        }
        notify('Recipient Type Updated successfully', 'positive')
        closeDetailPane()
        if (refetchRecipientTypes) refetchRecipientTypes()
      }
    }
  }, [updateRecipientTypeTranslationResponse])

  useEffect(() => {
    if (setRecipTypeStatusResponse) {
      const { status, statusText } = setRecipTypeStatusResponse;
      if (status === 200 || statusText === "OK") {
        if (infoChangeStatusRecipType && infoChangeStatusRecipType.fs) {
          const { fs } = infoChangeStatusRecipType;
          if (fs) {
            notify(fs, 'negative')
            return;
          }
        }
        notify(`Recipient Type ${showMoreInfo?.isActive === "False" ? "Reactivated" : "Deactivated"} successfully`, 'positive')
        closeDetailPane()
        if (refetchRecipientTypes) refetchRecipientTypes()
      }
    }
  }, [setRecipTypeStatusResponse])

  useEffect(() => {
    if (deleteRecipientTypeTranslationResponse) {
      const { status, statusText } = deleteRecipientTypeTranslationResponse;
      if (status === 200 || statusText === "OK") {
        if (infoDeleteRecipType && infoDeleteRecipType.fs) {
          const { fs } = infoDeleteRecipType;
          if (fs) {
            notify(fs, 'negative')
            return;
          }
        }
        notify('Recipient Type Deleted successfully', 'positive')
        closeDetailPane()
        if (refetchRecipientTypes) refetchRecipientTypes()
      }
    }
  }, [deleteRecipientTypeTranslationResponse])

  const onDeactiveClick = () => {
    if (recipientTypeTranslationMode === "Edit") {
      setconfirmationModalMode("Deactivate")
      setisOpenConfirmationModal(true)
    }
  }
  const onDeleteClick = () => {
    if (recipientTypeTranslationMode === "Edit") {
      setconfirmationModalMode("Delete")
      setisOpenConfirmationModal(true)
    }
  }
  const onSubmitClick = () => {
    if (recipientTypeTranslationMode === "Add") {
      setconfirmationModalMode("Add")

    } else if (recipientTypeTranslationMode === "Edit") {
      setconfirmationModalMode("Modify")
    }
    setisOpenConfirmationModal(true)
  }
  const onConfirmationYesClick = () => {
    if (confirmationModalMode === "Delete") {
      deleteRecipientTypeTranslation({ strRecipientType: showMoreInfo?.recipientType, strRecipientTypeTrans: showMoreInfo?.recipientTypeTrans, strIsActive: 'false', count })
      setcount(count + 1)

    } else if (confirmationModalMode === "Deactivate") {

      setRecipTypeStatus({
        strRecipientType: showMoreInfo?.recipientType, strRecipientTypeTrans: showMoreInfo?.recipientTypeTrans,
        strIsActive: showMoreInfo?.isActive === "False" ? 1 : 0, count
      })
      setcount(count + 1)

    } else if (confirmationModalMode === "Add") {
      addRecipientTypeTranslation({
        strRecipientType: currentnormalizedRecipientType,
        strRecipientTypeTrans: currentrecipientType, strWebRecipTypeDesc: currentwebRecipientTypeDesc, count
      })
      setcount(count + 1)

    } else if (confirmationModalMode === "Modify") {
      updateRecipientTypeTranslation({
        strRecipientType: currentnormalizedRecipientType,
        strNewRecipientTypeTrans: currentrecipientType, strNewWebDesc: currentwebRecipientTypeDesc,
        strOldRecipientTypeTrans: showMoreInfo?.recipientTypeTrans, count
      })
      setcount(count + 1)
    }
    setconfirmationModalMode('')
    setisOpenConfirmationModal(false)
  }
  useEffect(() => {
    if (showMoreInfo) {
      onResetCurrentRecordValues()
    }
  }, [showMoreInfo])


  const onClearCurrentRecordValues = () => {
    setcurrentrecipientType('')
    setcurrentnormalizedRecipientType('')
    setcurrentwebRecipientTypeDesc('')
  }
  const renderCurrentRecipientType = useMemo(() => {
    if (currentLayout !== "028") {
      return (
        <SelectDropdown
          width="200px"
          domID="drp_clientRecipientType"
          label=""
          options={clientRecipientTypeSource}
          size="medium"
          initialValue={{ label: clientRecipientTypeSource.find(x => x.value === currentrecipientType)?.label, value: currentrecipientType }}
          onChange={(e) => {
            if (e) {
              if (setcurrentrecipientType) setcurrentrecipientType(e.value)
            } else if (setcurrentrecipientType) setcurrentrecipientType(null)
            if (currentLayout !== "028") {
              if (e) {
                const label = normalizedRecipientTypeSource.find(x => x.value === e.value)?.label || '';
                if (label && label.indexOf('-') !== -1) {
                  const value = label.split('-')[0]
                  if (setcurrentnormalizedRecipientType) setcurrentnormalizedRecipientType(value)
                }

              } else if (setcurrentnormalizedRecipientType) setcurrentnormalizedRecipientType(null)
            }
          }}
        />
      )
    }
    return (
      <Input
        style={{ width: '200px' }}
        className="test-classname"
        domID="txt_clientRecipientType"
        placeholder=""
        size="small"
        maxLength={30}
        initialValue={currentrecipientType}
        onChange={(e) => {
          setcurrentrecipientType(e.target.value || "")
        }}
      />
    )
  }, [currentrecipientType, currentLayout, clientRecipientTypeSource, normalizedRecipientTypeSource])

  const renderCurrentNormalizedRecipientType = useMemo(() => {
    if (currentLayout !== "028") {
      return (
        <SelectDropdown
          width="200px"
          domID="drpClaimTypes"
          disabled
          label=""
          options={normalizedRecipientTypeSource}
          size="medium"
          initialValue={{ label: normalizedRecipientTypeSource.find(x => x.value === currentrecipientType)?.label, value: currentrecipientType }}
          onChange={(e) => {
            if (e) {
              if (setcurrentnormalizedRecipientType) setcurrentnormalizedRecipientType(e.value)
            } else if (setcurrentnormalizedRecipientType) setcurrentnormalizedRecipientType(null)
          }}
        />
      )
    }
    return (
      <SelectDropdown
        width="200px"
        domID="drpDeliveryTypes"
        label=""
        options={normalizedRecipientTypeSource}
        size="medium"
        initialValue={{ label: normalizedRecipientTypeSource.find(x => x.value === currentnormalizedRecipientType)?.label, value: currentnormalizedRecipientType }}
        onChange={(e) => {
          if (e) {
            if (setcurrentnormalizedRecipientType) setcurrentnormalizedRecipientType(e.value)
          } else if (setcurrentnormalizedRecipientType) setcurrentnormalizedRecipientType(null)
        }}
      />
    )
  }, [normalizedRecipientTypeSource, currentnormalizedRecipientType, currentrecipientType, currentLayout])

  const renderCurrentWebRecipientTypeDesc = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_webRecipientTypeDesc"
      placeholder=""
      size="small"
      maxLength={30}
      initialValue={currentwebRecipientTypeDesc}
      onChange={(e) => {
        setcurrentwebRecipientTypeDesc(e.target.value || "")
      }}
    />
  ), [currentwebRecipientTypeDesc])
  const validateForm = useMemo(() => {
    if (currentLayout !== "028") {
      if (!currentrecipientType || !clientRecipientTypeSource.find(x => x.value) === currentrecipientType) {
        return false;
      }
      if (!currentnormalizedRecipientType || !normalizedRecipientTypeSource.find(x => x.value) === currentnormalizedRecipientType) {
        return false;
      }
    } else {
      if (!currentrecipientType) {
        return false;
      }
      if (!currentnormalizedRecipientType || !normalizedRecipientTypeSource.find(x => x.value) === currentnormalizedRecipientType) {
        return false;
      }
    }
    if (!currentwebRecipientTypeDesc) {
      return false;
    }
    return true;
  }, [currentrecipientType, normalizedRecipientTypeSource, currentnormalizedRecipientType,
    currentwebRecipientTypeDesc, clientRecipientTypeSource, currentLayout])

  const renderConfirmationMarkup = useMemo(() => {
    if (confirmationModalMode === "Deactivate") {
      return showMoreInfo?.isActive === "False" ? "Reactivate" : "Deactivate"
    }
    return confirmationModalMode;
  }, [isOpenConfirmationModal, confirmationModalMode]);

  const renderRecipientTypeEntryForm = useMemo(() => {
    if (detailAnimationState > detailPaneAnimStates.COLLAPSED) {
      return (
        <div style={{ marginLeft: '40px' }} className="marginLeft">
          <div>
            <p>
              <div className="displayFlex">Complete the information below, and then click Submit to continue.</div>
            </p>
            <p>
              <div className="displayFlex">Note: Recipient type translation values are case-sensitive.</div>
            </p>
            <p>
              <div className="displayFlex"><span style={{ color: 'red' }}>Note: Other client recipient types having the same normalized value will be updated with this web description.</span></div>
            </p>
            <p>
              <div className="displayFlex"><span style={{ color: 'red' }}>* indicates required information</span></div>
            </p>
          </div>
          <br />
          <div className="displayFlex">
            <table className="common-table">
              <tr>
                <td>
                  <label> Client Recipient Type : <span style={{ color: 'red' }}>*</span></label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderCurrentRecipientType}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <label> Normalized Recipient Type (Value-Description): <span style={{ color: 'red' }}>*</span></label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderCurrentNormalizedRecipientType}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <label> Web Recipient Type Description: <span style={{ color: 'red' }}>*</span></label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderCurrentWebRecipientTypeDesc}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td colSpan="2">
                  <div className="displayFlex">
                    <div>
                      <Button
                        domID="btn_reset"
                        buttonType="standard"
                        size="small"
                        name="RESET"
                        disabled={recipientTypeTranslationMode !== "Edit"}
                        onClick={() => onResetCurrentRecordValues()}
                      />
                    </div>
                    <div className="marginLeft">
                      <Button
                        domID="btn_cancel"
                        buttonType="standard"
                        size="small"
                        name="CLEAR"
                        onClick={() => onClearCurrentRecordValues()}
                      />
                    </div>
                    <div className="marginLeft">
                      <Button
                        domID="btn_deactivate"
                        buttonType="standard"
                        size="small"
                        name={showMoreInfo?.isActive === "False" ? "REACTIVATE" : "DEACTIVATE"}
                        disabled={recipientTypeTranslationMode !== "Edit"}
                        onClick={() => onDeactiveClick()}
                      />
                    </div>
                    <div className="marginLeft">
                      <Button
                        domID="btn_delete"
                        buttonType="standard"
                        size="small"
                        name="DELETE"
                        disabled={recipientTypeTranslationMode !== "Edit"}
                        onClick={() => onDeleteClick()}
                      />
                    </div>
                    <div className="marginLeft">
                      <Button
                        domID="btn_search"
                        buttonType="standard"
                        size="small"
                        name="SUBMIT"
                        disabled={!validateForm}
                        onClick={() => onSubmitClick()}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
            </table>
          </div>
          <Modal isOpen={isOpenConfirmationModal}>
            <ModalHeader
              title={`Do you want to ${renderConfirmationMarkup} ?`}
              onClose={() => setisOpenConfirmationModal(false)}
            />
            <ModalBody>
              {isOpenConfirmationModal ? (
                <div style={{ marginLeft: '34px', marginBottom: '20px' }} className="marginLeft">
                  <div className="displayFlex">
                    <div className="marginLeft">
                      <Button
                        domID="btn_search"
                        buttonType="standard"
                        size="small"
                        name="Yes"
                        onClick={() => onConfirmationYesClick()}
                      />
                    </div>
                    <div className="marginLeft">
                      <Button
                        domID="btn_search"
                        buttonType="standard"
                        size="small"
                        name="No"
                        onClick={() => setisOpenConfirmationModal(false)}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
            </ModalBody>
          </Modal>
        </div>
      )
    }
    return null;
  }, [recipientTypeTranslationRecord, showMoreInfo, detailAnimationState, clientRecipientTypeSource,
    currentrecipientType, normalizedRecipientTypeSource, currentnormalizedRecipientType,
    currentwebRecipientTypeDesc, validateForm, recipientTypeTranslationMode, isOpenConfirmationModal, confirmationModalMode])

  return (
    <>
      <DetailPaneHeader
        title={recipientTypeTranslationMode === "Edit" ? "Modify Recipient Type Translation" : "Add Recipient Type Translation"}
        layoutControls={[
          expandCollapseDetailPaneButtonConfig,
          closeDetailPaneButtonConfig
        ]}
      />
      <>
        {isLoadingShowMoreInfo || isLoadingsysRecipientTypes || isLoadingaddRecipientTypeTranslation
          || isLoadingdeleteRecipientTypeTranslation || isLoadingsetRecipTypeStatus || isLoadingupdateRecipientTypeTranslation ? (
            // replace with a better loading component
            <div style={{ marginLeft: '50%' }}><LoadingComponent /></div>
          ) : isErrorShowMoreInfo || isErrorsysRecipientTypes || isErroraddRecipientTypeTranslation
            || isErrordeleteRecipientTypeTranslation || isErrorsetRecipTypeStatus || isErrorupdateRecipientTypeTranslation ? (
              // replace with a better error component
              <ErrorPage
                // this prop isn't real but is an example
                error={errorShowMoreINfo || errorsysRecipientTypes || erroraddRecipientTypeTranslation
                  || errordeleteRecipientTypeTranslation || errorsetRecipTypeStatus || errorupdateRecipientTypeTranslation}
              />
            ) : (
              <>
                {renderRecipientTypeEntryForm}
              </>
            )
        }
      </>
    </>
  )
}

export default RecipientTypeTranslationDetail