import {
  ADD_NOTIFICATION,
  DELETE_NOTIFICATION,
  DELETE_ALL_NOTIFICATIONS,
} from './constants';

export const INITIAL_STATE = {
  notifications: new Map(),
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_NOTIFICATION:
      state.notifications.clear();
      return {
        ...state,
        notifications: state.notifications.set(action.payload.id, {
          msg: `${action.payload.msg}`,
          type: action.payload.type,
          icon: action.payload.icon,
        }),
      };
    case DELETE_NOTIFICATION:
      state.notifications.delete(action.id);
      return {
        ...state,
      };
    case DELETE_ALL_NOTIFICATIONS:
      state.notifications.clear();
      return {
        ...state,
      };
    default:
      return state;
  }
};
