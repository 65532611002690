import styled from 'styled-components';
import { GRID_ITEM_MENU_FADE } from './constants';

export const ButtonGroup = styled.span`
  align-self: center;
  margin-bottom: 0.6rem;

  &.grid-item-menu {
    margin-left: 7.85rem;
    display: inline-flex;
    position: absolute;
    transition: top ${GRID_ITEM_MENU_FADE}ms;
    top: 6px;

    &.entering {
      top: 6px;
      display: inline-flex;
    }

    &.entered {
      top: -30px;
    }
  }

  button {
    margin-right: 0.5rem;
    height: 31px;
    white-space: nowrap;

    &:last-child {
      margin-right: 1.5rem;
    }
  }
`;

export default ButtonGroup;
