export default [
  {
    id: '1',
    name: 'Synergized',
    desc: 'Synovectomy, hand and finger',
    status: 'Active',
    hierarchy: ['_ABF', 'ARC', 'HCFA'],
    arrangement: 'Lake View',
    returnAddr: '570 Kinsman Circle',
    dateModified: Date.now() - 1000000000,
    ruleTypes: [
      {
        id: '1',
        associatedRuleName: 'PAR_3',
        name: 'Production Arrangement',
        rules: [
          {
            id: '1',
            name: 'PAR_1',
            status: ['L', 'T'],
            active: true,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
            ruleDetails: [
              {
                id: '00286',
                version: '09',
                tested: '0',
                claimType: 'EPC_Electronic PRA',
                recipientType: 'Provider',
                documentType: 'Manual Check',
                copyNumber: '01',
                deliveryAddress: 'test, test, test, MO, 63146-1234',
                checkForm: 'Paper_check-w-ERA top',
                eobForm: 'Empty Form',
                customCoverSheetForm: 'Custom cover for Non-Emp split',
                customCoverSheetLevel: 'DOC',
              },
              {
                id: '00287',
                version: '09',
                tested: '0',
                claimType: 'EPC_Electronic PRA',
                recipientType: 'Provider',
                documentType: 'Batch EOB',
                copyNumber: '01',
                eobForm: 'PRA_with Appeals',
                customCoverSheetForm: 'Custom cover for Non-Emp split',
                customCoverSheetLevel: 'DOC',
              },
              {
                id: '00288',
                version: '09',
                tested: '0',
                claimType: 'EMP_Employee_Split_Doc',
                recipientType: 'Insured',
                documentType: 'BatchCheck',
                copyNumber: '01',
                deliveryMethod: 'United Parcel Service 2nd Day Air',
                checkForm: 'Check with Logo only',
                eobForm: 'Member_EOB',
              },
              {
                id: '00283',
                version: '09',
                tested: '1',
                recipientType: 'Insured',
                documentType: 'Batch EOB',
                copyNumber: '01',
                deliveryMethod: 'Image Only',
                eobForm: 'Member_EOB',
              },
            ],
          },
          {
            id: '2',
            name: 'PAR_2',
            status: ['T'],
            active: false,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
          {
            id: '3',
            name: 'PAR_3',
            status: ['L'],
            active: false,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
          {
            id: '4',
            name: 'PAR_4',
            status: ['L', 'T'],
            active: false,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
        ],
      },
      {
        id: 'RET_3',
        name: 'Return Address',
      },
    ],
  },
  {
    id: '2',
    name: 'Ergonomic',
    desc: 'Mastotomy',
    status: 'Inactive',
    hierarchy: ['HCFA', '_ABF', 'ARC'],
    arrangement: 'Center',
    returnAddr: '8553 Luster Hill',
    dateModified: Date.now() - 100000000,
    ruleTypes: [
      {
        id: '1',
        associatedRuleName: 'DAP_7',
        name: 'Arrangement Work 123',
        rules: [
          {
            id: '1',
            name: 'TAR_1',
            status: ['L', 'T'],
            active: true,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
          {
            id: '2',
            name: 'DAR_2',
            status: ['T'],
            active: false,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
          {
            id: '3',
            name: 'EAQ_3',
            status: ['L'],
            active: false,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
          {
            id: '4',
            name: 'ZAR_4',
            status: ['T'],
            active: false,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
          {
            id: '5',
            name: 'DAP_7',
            status: ['L', 'T'],
            active: false,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
          {
            id: '6',
            name: 'LLP_7',
            status: ['T'],
            active: false,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
        ],
      },
      {
        id: '2',
        associatedRuleName: 'CAD_1',
        name: 'Return Address',
        rules: [
          {
            id: '1',
            name: 'CAD_1',
            status: ['L', 'T'],
            active: true,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
        ],
      },
      {
        id: '3',
        associatedRuleName: 'BAR_1',
        name: 'Production Arrangement 1',
        rules: [
          {
            id: '1',
            name: 'BAR_1',
            status: ['L'],
            active: false,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
          {
            id: '2',
            name: 'CAR_2',
            status: ['T'],
            active: false,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
          {
            id: '3',
            name: 'EAR_3',
            status: ['T'],
            active: false,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
          {
            id: '4',
            name: 'LAR_4',
            status: ['L', 'T'],
            active: true,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
          {
            id: '5',
            name: 'DAT_7',
            status: ['L'],
            active: false,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
        ],
      },
    ],
  },
  {
    id: '3',
    name: 'Team-oriented',
    desc: 'Vectorcardiogram (with ECG)',
    status: 'Termed',
    hierarchy: ['ARC', 'HCFA', '_ABF'],
    arrangement: '1st',
    returnAddr: '29 Lunder Avenue',
    dateModified: Date.now() - 10000000,
    ruleTypes: [
      {
        id: '1',
        associatedRuleName: 'DAP_7',
        name: 'Arrangement Work 123',
        rules: [
          {
            id: '1',
            name: 'TAR_1',
            status: ['L', 'T'],
            active: true,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
          {
            id: '2',
            name: 'DAR_2',
            status: ['T'],
            active: false,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
          {
            id: '3',
            name: 'EAQ_3',
            status: ['T'],
            active: false,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
          {
            id: '4',
            name: 'ZAR_4',
            status: ['L'],
            active: false,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
          {
            id: '5',
            name: 'DAP_7',
            status: ['L', 'T'],
            active: false,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
          {
            id: '6',
            name: 'LLP_7',
            status: ['T'],
            active: false,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
        ],
      },
      {
        id: '2',
        associatedRuleName: 'CAD_1',
        name: 'Return Address',
        rules: [
          {
            id: '1',
            name: 'CAD_1',
            status: ['T'],
            active: true,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
        ],
      },
      {
        id: '3',
        associatedRuleName: 'BAR_1',
        name: 'Production Arrangement 1',
        rules: [
          {
            id: '1',
            name: 'BAR_1',
            status: ['L', 'T'],
            active: false,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
          {
            id: '2',
            name: 'CAR_2',
            status: ['T'],
            active: false,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
          {
            id: '3',
            name: 'EAR_3',
            status: ['T'],
            active: false,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
          {
            id: '4',
            name: 'LAR_4',
            status: ['L'],
            active: true,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
          {
            id: '5',
            name: 'DAT_7',
            status: ['L', 'T'],
            active: false,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
        ],
      },
    ],
  },
  {
    id: '4',
    name: 'real-time',
    desc: 'Control of epistaxis by other means',
    status: 'Termed',
    hierarchy: ['_ABF', 'HCFA', 'ARC'],
    arrangement: 'Portage',
    returnAddr: '13801 Darwin Plaza',
    dateModified: Date.now() - 2000000000,
    ruleTypes: [
      {
        id: '1',
        associatedRuleName: 'DAP_7',
        name: 'Arrangement Work 123',
        rules: [
          {
            id: '1',
            name: 'TAR_1',
            status: ['L', 'T'],
            active: false,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
          {
            id: '2',
            name: 'DAR_2',
            status: ['T'],
            active: true,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
          {
            id: '3',
            name: 'EAQ_3',
            status: ['T'],
            active: false,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
          {
            id: '4',
            name: 'ZAR_4',
            status: ['L'],
            active: false,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
          {
            id: '5',
            name: 'DAP_7',
            status: ['L', 'T'],
            active: false,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
          {
            id: '6',
            name: 'LLP_7',
            status: ['T'],
            active: false,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
        ],
      },
      {
        id: '2',
        associatedRuleName: 'CAD_1',
        name: 'Return Address',
        rules: [
          {
            id: '1',
            name: 'CAD_1',
            status: ['L'],
            active: true,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
        ],
      },
      {
        id: '3',
        associatedRuleName: 'BAR_1',
        name: 'Production Arrangement 1',
        rules: [
          {
            id: '1',
            name: 'BAR_1',
            status: ['L', 'T'],
            active: true,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
          {
            id: '2',
            name: 'CAR_2',
            status: ['T'],
            active: false,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
          {
            id: '3',
            name: 'EAR_3',
            status: ['L'],
            active: false,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
          {
            id: '4',
            name: 'LAR_4',
            status: ['L', 'T'],
            active: false,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
          {
            id: '5',
            name: 'DAT_7',
            status: ['T'],
            active: false,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
        ],
      },
    ],
  },
  {
    id: '5',
    name: 'flexibility',
    desc: 'Debridement of open fracture site tarsals and metatarsals',
    status: 'Inactive',
    hierarchy: ['ARC', '_ABF', 'HCFA', 'TBSDFGFR', 'GRGDFSPR', 'DSSDFGF'],
    arrangement: 'Sachtjen',
    returnAddr: '8168 Anthes Road',
    dateModified: Date.now() - 200000000,
    ruleTypes: [
      {
        id: '1',
        associatedRuleName: 'DAP_7',
        name: 'Arrangement Work 123',
        rules: [
          {
            id: '1',
            name: 'TAR_1',
            status: ['L'],
            active: false,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
          {
            id: '2',
            name: 'DAR_2',
            status: ['L', 'T'],
            active: false,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
          {
            id: '3',
            name: 'EAQ_3',
            status: ['T'],
            active: true,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
          {
            id: '4',
            name: 'ZAR_4',
            status: ['L'],
            active: false,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
          {
            id: '5',
            name: 'DAP_7',
            status: ['T'],
            active: false,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
          {
            id: '6',
            name: 'LLP_7',
            status: ['L', 'T'],
            active: false,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
        ],
      },
      {
        id: '2',
        associatedRuleName: 'CAD_1',
        name: 'Return Address',
        rules: [
          {
            id: '1',
            name: 'CAD_1',
            status: ['T'],
            active: true,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
        ],
      },
      {
        id: '3',
        associatedRuleName: 'BAR_1',
        name: 'Production Arrangement 1',
        rules: [
          {
            id: '1',
            name: 'BAR_1',
            status: ['L', 'T'],
            active: false,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
          {
            id: '2',
            name: 'CAR_2',
            status: ['T'],
            active: false,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
          {
            id: '3',
            name: 'EAR_3',
            status: ['L'],
            active: true,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
          {
            id: '4',
            name: 'LAR_4',
            status: ['L', 'T'],
            active: false,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
          {
            id: '5',
            name: 'DAT_7',
            status: ['T'],
            active: false,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
        ],
      },
    ],
  },
  {
    id: '6',
    name: 'local',
    desc:
      'Destruction of phrenic nerve for collapse of lung and lots more text to show a good tooltip if there is a really really long description',
    status: 'Active',
    hierarchy: ['_ABF', 'HFCA', 'ARC'],
    arrangement: 'Fulton',
    returnAddr: '21066 Marquette & St Anthony Junction',
    dateModified: Date.now() - 20000000000,
    ruleTypes: [
      {
        id: '1',
        associatedRuleName: 'DAP_7',
        name: 'Arrangement Work 123',
        rules: [
          {
            id: '1',
            name: 'TAR_1',
            status: ['L', 'T'],
            active: true,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
          {
            id: '2',
            name: 'DAR_2',
            status: ['T'],
            active: false,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
          {
            id: '3',
            name: 'EAQ_3',
            status: ['L'],
            active: false,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
          {
            id: '4',
            name: 'ZAR_4',
            status: ['L', 'T'],
            active: false,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
          {
            id: '5',
            name: 'DAP_7',
            status: ['T'],
            active: false,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
          {
            id: '6',
            name: 'LLP_7',
            status: ['L', 'T'],
            active: false,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
        ],
      },
      {
        id: '2',
        associatedRuleName: 'CAD_1',
        name: 'Return Address',
        rules: [
          {
            id: '1',
            name: 'CAD_1',
            status: ['T'],
            active: true,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
        ],
      },
      {
        id: '3',
        associatedRuleName: 'BAR_1',
        name: 'Production Arrangement 1',
        rules: [
          {
            id: '1',
            name: 'BAR_1',
            status: ['L'],
            active: false,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
          {
            id: '2',
            name: 'CAR_2',
            status: ['L'],
            active: false,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
          {
            id: '3',
            name: 'EAR_3',
            status: ['T'],
            active: true,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
          {
            id: '4',
            name: 'LAR_4',
            status: ['L', 'T'],
            active: false,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
          {
            id: '5',
            name: 'DAT_7',
            status: ['T'],
            active: false,
            description: 'Ibsum factum lorem Unumus',
            dateModified: Date.now() - 10000000,
          },
        ],
      },
    ],
  },
];
