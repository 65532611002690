/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  DetailPaneHeader,
  useLayout,
  Checkbox,
  Button,
  Modal,
  ModalHeader,
  ButtonGroup,
  ModalBody,
  Breadcrumb
} from 'ui-core/dist'
import { useTestFile } from '../../api/useTestFile'
import axios from '../../axios'
import { AnchorInput } from '../../components'
import Loading from '../../components/Loading'
import useNotificationBannerHook from '../../useNotificationBannerHook';
import ErrorDetail from './ErrorDetail';
import { setDetailsId } from '../ErrorManager/Modules/Action';
import withAxiosCancellation from '../../components/withAxiosCancellation'

const TestFileManagerDetailPane = ({ getCancelSourceWithName }) => {
  const {
    expandCollapseDetailPaneButtonConfig,
    closeDetailPaneButtonConfig,
    closeDetailPane,
    detailProps,
  } = useLayout()
  const dispatch = useDispatch()
  const { notify } = useNotificationBannerHook()
  const { client } = useSelector(({ context }) => context.context)
  const { refetchAllTestFile } = useSelector(store => store.testFileManager)
  const userType = useSelector(({ session }) => session.type)
  // const { data, isLoading } = useTestFile({
  //   id: detailProps?.id || undefined,
  //   getCancelSource:getCancelSourceWithName('testFile')
  // })
  const { data, isLoading, postData } = useTestFile(detailProps?.id || undefined, getCancelSourceWithName('testFile'))
  const [bhide, setBhide] = useState()
  const [isModalOpen, setModal] = useState(false)
  const [isErrorView, setIsErrorView] = useState(false)
  const [selectedErrId, setSelectedErrId] = useState(false)
  const layout = useSelector(({ context }) => `${context?.context?.layout}`)
  const [visibleMDPP] = useState(layout == '007')

  useEffect(() => {
    setBhide(data?.bhide === "1")
  }, [data])

  useEffect(() => {
    setIsErrorView(false)
  }, [detailProps?.id])

  useEffect(() => {
    setIsErrorView(false)
    if (detailProps?.id) {
      postData(detailProps?.id || undefined, getCancelSourceWithName('testFile'))
    }
  }, [detailProps?.id])

  const handleInternalView = () => setBhide(!bhide)

  const handleModalClose = () => setModal(false)

  const handleSubmit = () => setModal(true)

  const handleConfirmationClick = async (clickBtnFlags = false) => {
    if (clickBtnFlags) {
      const mappedhide = bhide ? "1" : "0"
      try {
        await axios.put(`/testFiles/${detailProps.id}?bhide=${mappedhide}`)
        notify(`You have successfully updated the test file: ${detailProps.id}`, 'positive')
        closeDetailPane()
        if (refetchAllTestFile) {
          refetchAllTestFile()
        }
        setModal(false)
      } catch (err) {
        notify("Failed to update", 'negative')
      }
    }
  }

  const handleLogoClick = async (jobId, logo) => {
    try {
      const res = await axios.get(
        `/testFiles/${detailProps.id}/jobs/${jobId}/logos/${logo}`,
        { responseType: 'blob' }
      )
      if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
        window.navigator.msSaveOrOpenBlob(res.data, `test_email_${detailProps.id}_${jobId}.pdf`);
      } else {
        const url = URL.createObjectURL(res.data);
        const element = document.createElement('a');
        element.setAttribute('href', url);
        element.setAttribute('download', `test_email_${detailProps.id}_${jobId}.pdf`);
        element.click();
      }
    } catch (err) {
      notify(err.displayMessage, 'negative')
    }
  }//SMS changes
  const handleSMSClick = async (jobId) => {
    try {
      const res = await axios.get(
        `/testFiles/${detailProps.id}/jobs/${jobId}/SMS_files`,
        { responseType: 'blob' }
      )
      if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
        window.navigator.msSaveOrOpenBlob(res.data, `test_sms_${detailProps.id}_${jobId}.pdf`);
      } else {
        const url = URL.createObjectURL(res.data);
        const element = document.createElement('a');
        element.setAttribute('href', url);
        element.setAttribute('download', `test_sms_${detailProps.id}_${jobId}.pdf`);
        element.click();
      }
    } catch (err) {
      notify(err.displayMessage, 'negative')
    }
  }

  const handlePdfClick = async (jobId, pdfId,type) => {
    try {
      const res = await axios.get(
        `/testFiles/${detailProps.id}/jobs/${jobId}/files/${pdfId}`,
        { responseType: 'blob' }
      )
      let blob = res.data
      let name = ''
      if (type === 'pdf') {
        blob = blob.slice(0, blob.size, "application/pdf")
        name = `PDF_${client}_${pdfId}.pdf`
      } else if (type === 'xml') {
        blob = blob.slice(0, blob.size, "application/zip")
        name = `XML_${client}_${pdfId}.zip`
      } else {
        blob = blob.slice(0, blob.size, "text/html")
        name = `eEOB_${client}_${pdfId}.html`
      }

      if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
        window.navigator.msSaveOrOpenBlob(blob, name);
      } else {
        const url = URL.createObjectURL(blob);
        const element = document.createElement('a');
        element.setAttribute('href', url);
        element.setAttribute('download', name);
        element.click();
      }
    } catch (err) {
      notify(err.displayMessage, 'negative')
    }
  }

  const onSelectErrorId = (id) => {
    setSelectedErrId(id)
    dispatch(setDetailsId(id))
    setIsErrorView(true);
  }

  return (
    <>
      {
        !isErrorView ?
          <>
            <DetailPaneHeader
              title="Test File Details"
              layoutControls={[expandCollapseDetailPaneButtonConfig, closeDetailPaneButtonConfig]}
            />
            {isLoading ? <Loading /> : (
              <div className="test-file-details" style={{ color: '#0F0F59', marginLeft: '26px', fontSize: '13px' }}>
                <div className='test-file-details__row'>
                  <span className="test-file-details__label">Test File Detail:</span>
                  <span >{data?.testFileId}</span>
                </div>
                <div className='test-file-details__row'>
                  <span className="test-file-details__label">Status:</span>
                  <span >{data?.status}</span>
                </div>
                <div className='test-file-details__row'>
                  <span className="test-file-details__label">Submitted:</span>
                  <span >{data?.dateTimeSubmitted} by {data?.submittedBy}</span>
                </div>
                <div className='test-file-details__row'>
                  <span className="test-file-details__label">Completed:</span>
                  <span >{data?.dateTimeCompleted}</span>
                </div>
                <div className='test-file-details__row'>
                  <span className="test-file-details__label">Notify by e-mail:</span>
                  <a href={'mailto:' + data?.contactEmailAddr}>{data?.contactEmailAddr}</a>
                </div>
                <div className='test-file-details__row'>
                  <span className="test-file-details__label">Document Limit:</span>
                  <span >{data?.maxDocuments === "0" ? 'No Limit' : data?.maxDocuments}</span>
                </div>
                <div className='test-file-details__row'>
                  <span className="test-file-details__label">Document Types:</span>
                  <span >
                    {!data?.documentTypes?.documentType ? (<></>)
                      : (data?.documentTypes && Array.isArray(data?.documentTypes?.documentType)) ? (
                        data.documentTypes.documentType.reduce(
                          (a, c) => `${a.description}${`, ${c.description}`}`
                        )
                      ) : data?.documentTypes.documentType.description}
                  </span>
                </div>
                <div className='test-file-details__row'>
                  <span className="test-file-details__label">Employer Groups</span>
                  <span >
                    {(data?.employerGroups && Array.isArray(data?.employerGroups.employerGroup)) ? (
                      data.employerGroups.employerGroup.reduce(
                        (a, c) => `${a.groupId}${`, ${c.groupId}`}`
                      )
                    ) : !data?.employerGroups?.employerGroup ? (<></>) : data?.employerGroups?.employerGroup.groupId}
                  </span>
                </div>
                {visibleMDPP && <div className='test-file-details__row'>
                  <span className="test-file-details__label">Payer Limit:</span>
                  <span >{data?.payerLimit}</span>
                </div>}
                <div className='test-file-details__row'>
                  <span className="test-file-details__label">Errors:</span>
                  <span >
                    {(data && data?.errorInformation && Array.isArray(data?.errorInformation?.errorId)) ?
                      data.errorInformation?.errorId.map((a, idx) => {
                        return <span>
                          {idx !== 0 ? ', ' : ''}
                          <a key={a} href='#' onClick={() => { onSelectErrorId(a) }}>{a}</a>
                        </span>
                      })
                      : <a href='#' onClick={() => { onSelectErrorId(data?.errorInformation?.errorId) }}>{data?.errorInformation?.errorId}</a>}
                  </span>
                </div>
                <div className='test-file-details__row'>
                  <span className="test-file-details__label">Filekeys:</span>
                  <span >
                    {(data && data?.filekeys && Array.isArray(data?.filekeys)) ? (
                      data.filekeys.filekey.reduce(
                        (a, c) => `${a}${`, ${c}`}`
                      )
                    ) : data?.filekeys?.filekey}
                  </span>
                </div>
                {userType === "I" && (
                  <div className='test-file-details__row'>
                    <span className="test-file-details__label">Internal View Only:</span>
                    <div className="test-file-details__sub-row">
                      <Checkbox
                        domID="InternalViewCheckbox"
                        dataTestId="test-checkboxDisabled"
                        onChange={handleInternalView}
                        checked={bhide}
                      />
                      <Button
                        domID="SubmitCheckboxButton"
                        className="test-file-details__button"
                        buttonType="standard"
                        size="small"
                        name="Submit"
                        onClick={handleSubmit}
                      />
                    </div>
                  </div>
                )}
                {data?.output?.hardOutput?.$?.requested === "false" ? (
                  <div className='test-file-details__row'>
                    <span className="test-file-details__label">Hard Output:</span>
                    <span>No Hard Output requested</span>
                  </div>
                ) : (
                  <>
                    <span className="test-file-details__label">Hard Output:</span>
                    <div className="test-file-details__hard-output">
                      <div>
                        <p>Your hard output was sent via the following address:</p>
                        <div className="test-file-details__hard-output-info">
                          <div className="test-file-details__row">
                            <span className="test-file-details__label">Name:</span>
                            <span>{data?.output?.hardOutput?.deliveryAddress.name}</span>
                          </div>
                          <div className="test-file-details__row">
                            <span className="test-file-details__label">Attention:</span>
                            <span>{data?.output?.hardOutput?.deliveryAddress.attn}</span>
                          </div>
                          <div className="test-file-details__row">
                            <span className="test-file-details__label">Line 1:</span>
                            <span>{data?.output?.hardOutput?.deliveryAddress.line1}</span>
                          </div>
                          <div className="test-file-details__row">
                            <span className="test-file-details__label">Line 2:</span>
                            <span>{data?.output?.hardOutput?.deliveryAddress.line2}</span>
                          </div>
                          <div className="test-file-details__row">
                            <span className="test-file-details__label">City:</span>
                            <span>{data?.output?.hardOutput?.deliveryAddress.city}</span>
                          </div>
                          <div className="test-file-details__row">
                            <span className="test-file-details__label">State:</span>
                            <span>{data?.output?.hardOutput?.deliveryAddress.state}</span>
                          </div>
                          <div className="test-file-details__row">
                            <span className="test-file-details__label">Zip:</span>
                            <span>{data?.output?.hardOutput?.deliveryAddress.postalCode}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div>
                  <>
                    <div className="test-file-details__label">Output:</div>
                    {data?.output?.pdfOutput?.$?.requested === "false" ? (
                      <p>No PDF Output requested</p>
                    ) :
                      <div className="test-file-details__jobs">
                        <div>You may view the output of your jobs here:</div>
                        {(data?.output?.pdfOutput?.jobs?.job && Array.isArray(data?.output?.pdfOutput?.jobs?.job)) ? (
                          data.output.pdfOutput.jobs.job.map(job => (
                            <div className="test-file-details__job">
                              <p className="test-file-details__job-title">{job.name}(Job #{job.id}):</p>
                              <ul>
                                {(job.files.file && Array.isArray(job?.files?.file)) ? (
                                  job.files.file.map(file => (
                                    <li key={file}>
                                      <AnchorInput
                                        onClick={() => handlePdfClick(job.id, file, 'pdf')}
                                      >
                                        PDF: {file}
                                      </AnchorInput>
                                    </li>
                                  ))
                                ) : job.files === "" ? (<></>) : (
                                  <li>
                                    <AnchorInput
                                      onClick={() => handlePdfClick(job.id, job.files.file, 'pdf')}
                                    >
                                      PDF: {job.files.file}
                                    </AnchorInput>
                                  </li>
                                )}
                                {/* Code added on 30th may */}
                                {(job.XML_files.file && Array.isArray(job?.XML_files?.file)) ? (
                                  job.XML_files.file.map(XML_files => (
                                    <li key={XML_files}>
                                      <AnchorInput
                                        onClick={() => handlePdfClick(job.id, XML_files, 'xml')}
                                      >
                                        XML: {XML_files}
                                      </AnchorInput>
                                    </li>
                                  ))
                                ) : job.XML_files === "" ? (<></>) : (
                                  <li>
                                    <AnchorInput
                                      onClick={() => handlePdfClick(job.id, job.XML_files.file, 'xml')}
                                    >
                                      XML: {job.XML_files.file}
                                    </AnchorInput>
                                  </li>
                                )}
                                {(job.EEOB_files.file && Array.isArray(job?.EEOB_files?.file)) ? (
                                  job.EEOB_files.file.map(EEOB_files => (
                                    <li key={EEOB_files}>
                                      <AnchorInput
                                        onClick={() => handlePdfClick(job.id, EEOB_files, 'eEOB')}
                                      >
                                        eEOB
                                      </AnchorInput>
                                    </li>
                                  ))
                                ) : job.EEOB_files === "" ? (<></>) : (
                                  <li>
                                    <AnchorInput
                                      onClick={() => handlePdfClick(job.id, job.EEOB_files.file, 'eEOB')}
                                    >
                                      eEOB
                                    </AnchorInput>
                                  </li>
                                )}

                                {(job.ENOTE_files.logoName && Array.isArray(job.ENOTE_files.logoName)) ?
                                  job.ENOTE_files.logoName.map((logo, i) => (
                                    <li key={logo}>
                                      <AnchorInput
                                        onClick={() => handleLogoClick(job.id, logo)}
                                      >
                                        Email: {i + 1}
                                      </AnchorInput>
                                    </li>
                                  )) : job.ENOTE_files === "" ? (<></>) : (
                                    <li>
                                      <AnchorInput
                                        onClick={() => handleLogoClick(job.id, job.ENOTE_files.logoName)}
                                      >
                                        Email: 1
                                      </AnchorInput>
                                    </li>
                                  )}



                                {
                                 ( 
                                    job?.hasOwnProperty('SMS_files')?
                                    ( <li><AnchorInput

                                      onClick={() => handleSMSClick(job.id)}
                                    >
                                      SMS: 1

                                    </AnchorInput> </li> )
                                    :
                                    ( <span></span> )
                                  
                                 )
                                }



                              </ul>
                            </div>
                          ))) : data?.output?.pdfOutput?.jobs?.job ? (
                            <div className="test-file-details__job">
                              <p className="test-file-details__job-title">{data?.output?.pdfOutput?.jobs?.job.name}(Job #{data?.output?.pdfOutput?.jobs?.job.id}):</p>
                              <ul>
                                {(data?.output?.pdfOutput?.jobs?.job.files.file && Array.isArray(data?.output?.pdfOutput?.jobs?.job?.files?.file)) ? (
                                  data?.output?.pdfOutput?.jobs?.job.files.file.map(file => (
                                    <li key={file}>
                                      <AnchorInput
                                        onClick={() => handlePdfClick(data?.output?.pdfOutput?.jobs?.job.id, file, 'pdf')}
                                      >
                                        PDF: {file}
                                      </AnchorInput>
                                    </li>
                                  ))
                                ) : data?.output?.pdfOutput?.jobs?.job.files === "" ? (<></>) : (
                                  <li>
                                    <AnchorInput
                                      onClick={() => handlePdfClick(data?.output?.pdfOutput?.jobs?.job.id, data?.output?.pdfOutput?.jobs?.job.files.file, 'pdf')}
                                    >
                                      PDF: {data?.output?.pdfOutput?.jobs?.job.files.file}
                                    </AnchorInput>
                                  </li>
                                )}

                                {(data?.output?.pdfOutput?.jobs?.job.XML_files.file && Array.isArray(data?.output?.pdfOutput?.jobs?.job?.XML_files?.file)) ? (
                                  data?.output?.pdfOutput?.jobs?.job.XML_files.file.map(file => (
                                    <li key={file}>
                                      <AnchorInput
                                        onClick={() => handlePdfClick(data?.output?.pdfOutput?.jobs?.job.id, file, 'xml')}
                                      >
                                        XML: {file}
                                      </AnchorInput>
                                    </li>
                                  ))
                                ) : data?.output?.pdfOutput?.jobs?.job.XML_files === "" ? (<></>) : (
                                  <li>
                                    <AnchorInput
                                      onClick={() => handlePdfClick(data?.output?.pdfOutput?.jobs?.job.id, data?.output?.pdfOutput?.jobs?.job.XML_files.file, 'xml')}
                                    >
                                      XML: {data?.output?.pdfOutput?.jobs?.job.XML_files.file}
                                    </AnchorInput>
                                  </li>
                                )}

                                {(data?.output?.pdfOutput?.jobs?.job.EEOB_files.file && Array.isArray(data?.output?.pdfOutput?.jobs?.job?.EEOB_files?.file)) ? (
                                  data?.output?.pdfOutput?.jobs?.job.EEOB_files.file.map(file => (
                                    <li key={file}>
                                      <AnchorInput
                                        onClick={() => handlePdfClick(data?.output?.pdfOutput?.jobs?.job.id, file, 'eEOB')}
                                      >
                                        eEOB: {file}
                                      </AnchorInput>
                                    </li>
                                  ))
                                ) : data?.output?.pdfOutput?.jobs?.job.EEOB_files === "" ? (<></>) : (
                                  <li>
                                    <AnchorInput
                                      onClick={() => handlePdfClick(data?.output?.pdfOutput?.jobs?.job.id, data?.output?.pdfOutput?.jobs?.job.EEOB_files.file, 'eEOB')}
                                    >
                                      eEOB: {data?.output?.pdfOutput?.jobs?.job.EEOB_files.file}
                                    </AnchorInput>
                                  </li>
                                )}


                                {(data?.output?.pdfOutput?.jobs?.job.ENOTE_files.logoName && Array.isArray(data?.output?.pdfOutput?.jobs?.job.ENOTE_files.logoName)) ?
                                  data?.output?.pdfOutput?.jobs?.job.ENOTE_files.logoName.map((logo, i) => (
                                    <li key={logo}>
                                      <AnchorInput
                                        onClick={() => handleLogoClick(data?.output?.pdfOutput?.jobs?.job.id, logo)}
                                      >
                                        Email: {i + 1}
                                      </AnchorInput>
                                    </li>
                                  )) : data?.output?.pdfOutput?.jobs?.job.ENOTE_files === "" ? (<></>) : (
                                    <li>
                                      <AnchorInput
                                        onClick={() => handleLogoClick(data?.output?.pdfOutput?.jobs?.job.id, data?.output?.pdfOutput?.jobs?.job.ENOTE_files.logoName)}
                                      >
                                        Email: 1
                                      </AnchorInput>
                                    </li>
                                  )}

                                {
                                  (
                                    data?.output?.pdfOutput?.jobs?.job?.hasOwnProperty('SMS_files') ?
                                      (<li><AnchorInput

                                        onClick={() => handleSMSClick(data?.output?.pdfOutput?.jobs?.job.id)}
                                      >
                                        SMS: 1

                                      </AnchorInput> </li>)
                                      :
                                      (<span></span>)

                                  )
                                }

                              </ul>
                            </div>
                          ) : (<></>)}
                      </div>
                    }
                  </>
                </div>
              </div>
            )}
            <Modal isOpen={isModalOpen} >
              <ModalHeader
                title="Confirm Selection"
                description="Please confirm your selection."
                onClose={handleModalClose}
              />
              <ModalBody>
                <div style={{ padding: '0 48px 48px 48px' }}>
                  <ButtonGroup>
                    <Button
                      domID="DetailsModalConfirmationButton"
                      onClick={() => handleConfirmationClick(true)}
                      name="Confirm"
                    />
                  </ButtonGroup>
                </div>
              </ModalBody>
            </Modal>
          </> : <>
            <Breadcrumb
              breadcrumbItems={[{ title: "Test File Details" }, { title: selectedErrId }]}
              onBreadcrumbItemClick={() => { setIsErrorView(false) }}
              domID="breadcrumb"
            />
            <DetailPaneHeader
              title="Test File Error Details"
              layoutControls={[expandCollapseDetailPaneButtonConfig, closeDetailPaneButtonConfig]}
            />
            <ErrorDetail onClose={() => { setIsErrorView(false) }} />
          </>
      }
    </>
  )
}

export default withAxiosCancellation(TestFileManagerDetailPane)