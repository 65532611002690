export const ERROR_MANAGER_MAIN_GRID_COLUMN ={
  columns: new Set([
    {
      dataName: 'id',
      text: 'ERROR ID',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'type',
      text: 'ERROR TYPE',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'system-type',
      text: 'SYSTEM TYPE',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'status',
      text: 'STATUS',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'highest-severity',
      text: 'SEVERITY',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'count',
      text: 'ERROR COUNT',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
  ]),
  selectionKey: 'id',
  selectedItemIds: new Set([]),
  bookmarkedItemIds: new Set([]),
  domID: 'datafile_grid',
  selectAllCheckboxDomID: 'select-all-test-id',
  supportSelection: true,
  isConfigurable: true,
  onSortGridColumn: () => false,
};

export const ERROR_MANAGER_DETAIL_NOTE_GRID_COLUMN = {
  columns: new Set([
    {
      dataName: 'userId',
      text: 'User ID',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'dateAdded',
      text: 'Date Added',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'text',
      text: 'Notes',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    
  ]),
  selectionKey: 'key',
  selectedItemIds: new Set([]),
  bookmarkedItemIds: new Set([]),
  domID: 'errorDetails_notes_grid',
  selectAllCheckboxDomID: 'select-all-test-id',
  supportSelection: false,
  isConfigurable: true,
};

export const ERROR_MANAGER_DETAIL_PAGE_GRID_COLUMN = {
  columns: new Set([
    {
      dataName: 'key',
      text: 'KEY',
      cellType: 'text',
    },
    {
      dataName: 'timeStamp',
      text: 'DATE/TIME RECIEVED',
      cellType: 'text',
    },
    {
      dataName: 'errorInfoDisplay',
      text: 'ERROR INFO',
      cellType: 'text',
    },
    
  ]),
  selectionKey: 'key',
  selectedItemIds: new Set([]),
  bookmarkedItemIds: new Set([]),
  domID: 'errorDetails_grid',
  selectAllCheckboxDomID: 'select-all-test-id',
  supportSelection: false,
  isConfigurable: true,
};
export const SELECT_STATUS_RECORD = [
  { label: 'Resolved', value: "true" },
  { label: 'Unresolved', value: "false" }
];

