import React, { useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    Grid,
    useLayout,
    ListPaneHeader,
    Button,
    Input,
    DatePickerV2
} from 'ui-core/dist'
import { SelectDropdown } from '../../components'
import axios from '../../axios';
import exportFromJSON from 'export-from-json';
import useNotificationBannerHook from '../../useNotificationBannerHook';
import { LoadingPage } from '../../components';
import './modules/style.scss';
import { lessTransparentNeutral100 } from 'ui-core/dist/chunk-e0ecc33c';

const EftHistoryReport = () => {
    const { dataSourceDesc } = useSelector(({ context }) => context?.context || {});
    const clientLayoutId = dataSourceDesc.split('-')[0].trim();
    const clientId = clientLayoutId.split('_')[0].trim();
    const layoutId = clientLayoutId.split('_')[1].trim();
    const {
        expandCollapseListPaneButtonConfig
    } = useLayout();
    const { notify } = useNotificationBannerHook();

    const [eftHistoryList, setEftHistoryList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [routingCode, setRoutingCode] = useState();
    const [accountNumber, setAccountNumber] = useState();
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const [sortBy, setSortBy] = useState();
    const [key, setKey] = useState(0);

    const SELECT_REPORT_OPTIONS = [{
        label: "View On Sceen",
        value: "OnScreen"
    },
    {
        label: "Download Microsoft Excel",
        value: "Excel"
    }
    ];
    const [initialREPORT_OPTIONValue, setInitialREPORT_OPTIONValue] = useState({ label: "View On Sceen", value: "OnScreen" });
    const [reportType, setReportType] = useState("OnScreen");
    const handleReportOptionChange = (e) => {
        setInitialREPORT_OPTIONValue({ label: e?.label, value: e?.value });
        setReportType(e?.value);
    }

    const reportSortOptions = [{
        label: "Payment Id",
        value: "EmdeonID"
    },
    {
        label: "Release Date",
        value: "ReleaseDate"
    },
    {
        label: "Effective Date",
        value: "EffectiveDate"
    },
    {
        label: "Routing Number",
        value: "RoutingNumber"
    },
    {
        label: "Account Number",
        value: "AccountNumber"
    },
    {
        label: "Document Key",
        value: "DocumentKey"
    },
    {
        label: "Original Check #",
        value: "OriginalCheck#"
    },
    {
        label: "Payee",
        value: "Payee"
    },
    {
        label: "Payee Tax ID",
        value: "PayeeTaxID"
    },
    {
        label: "Status",
        value: "Status"
    }
    ];

    useEffect(() => {
        getReport()
    }, [])

    const getReport = async () => {
        if (!ValidateDates()) {
            notify(`From Date should be less than To Date.`, 'caution')
        }
        else if (!ValidateFutureDate()) {
            notify(`Future Date is not allowed.`, 'caution')
        } else {
            let requestObj = {
                "payerAccountNumber": accountNumber,
                "payerRoutingNumber": routingCode,
                "clientId": clientId,
                "layoutId": layoutId,
                "startDate": fromDate,
                "endDate": toDate,
                "countReturn": "",
                "startRecord": ""
            };
            try {
                const fileName = 'EftHistoryReport';
                const exportType = 'xls';
                setIsLoading(true);
                const res = await axios.post(
                    `reports-manager/eft-history`,
                    requestObj
                )
                let resData = res?.data?.report?.EFTHistMaster;

                if (resData) {
                    resData = Array.isArray(resData) ? resData : [resData]
                } else resData = [];

                if (reportType === 'Excel') {
                    exportFromJSON({ data: resData, fileName, exportType });
                } else {
                    setEftHistoryList(resData);
                }
                setKey(key + 1);
                setIsLoading(false);
                setAccountNumber('');
                setRoutingCode('');
                setFromDate('');
                setToDate('');
            } catch (err) {
                setIsLoading(false);
                setAccountNumber('');
                setRoutingCode('');
                setFromDate('');
                setToDate('');
                notify(`${err.displayMessage}`, 'negative')
            }
        }
    }

    const rendeStartDate = useMemo(() => (
        <DatePickerV2
            domID="startDatePicker"
            onDateChange={(e) => {
                setFromDate(e ? e.format('MM/DD/YYYY') : '')
            }}
            size="small" />
    ), [fromDate]);

    const rendeEndDate = useMemo(() => (
        <DatePickerV2
            domID="endDatePicker"
            onDateChange={(e) => {
                setToDate(e ? e.format('MM/DD/YYYY') : '')
            }}
            size="small" />
    ), [toDate]);

    const ValidateDates = () => {
        let startDate = new Date(fromDate);
        let endDate = new Date(toDate);
        return startDate > endDate ? false : true;
    }

    const ValidateFutureDate = () => {
        let startDate = new Date(fromDate);
        let endDate = new Date(toDate);
        let today = new Date();
        if ((startDate > today) || (endDate > today)) {
            return false;
        }
        else {
            return true;
        }
    }

    const handleReportSortByOptionChange = (e) => {
        setSortBy(e?.value);
    }

    const handleReportTypeOptionChange = (e) => {
        setReportType(e?.value);
    }

    const config = {
        columns: new Set([
            {

                dataName: 'EmdeonID',
                text: 'Payment ID',
            },
            {
                dataName: 'ReleaseDate',
                text: 'Release Date',
            },
            {
                dataName: 'EffectiveDate',
                text: 'Effective Date',
                cellType: 'text',//done
            },
            {
                dataName: 'RoutingNumber',
                text: 'Routing Number',
                cellType: 'text',
            },
            {
                dataName: 'AcctNumber',//done
                text: 'Account Number',
                cellType: 'text',
            },
            {
                dataName: 'TotalDebit',
                text: 'Total Debit',
                cellType: 'text',//done
            },
            {
                dataName: 'TotalItems',
                text: 'Total Items',
                cellType: 'text',//done
            },
            {
                dataName: 'TraceNumber',
                text: 'Trace Number',
                cellType: 'text',//done
            },
            {
                dataName: 'TransactionType',
                text: 'Transcation Type',
                cellType: 'text',//done
            }

        ]),

        domID: 'test-id5',
    };

    return (
        <>
            <ListPaneHeader
                title={`EFT Payer Funding History`}
                layoutControls={[expandCollapseListPaneButtonConfig]}
            />
            <div>
                <div style={{ paddingLeft: '50px' }}>
                    <table style={{ width: '750px' }}>
                        <tbody>
                            <tr className="payerHistoryRow">
                                <td className='userreport-labels'>
                                    <label >Bank Routing Code:</label>
                                    <Input
                                        style={{ width: '250px' }}
                                        domID="routingCode"
                                        size="small"
                                        onChange={(e) => {
                                            setRoutingCode(e.target.value || "")
                                        }} />
                                </td>
                                <td className='userreport-labels'>
                                    <label >Bank Account Number:</label>
                                    <Input
                                        style={{ width: '250px' }}
                                        domID="accountNumber"
                                        size="small"
                                        onChange={(e) => {
                                            setAccountNumber(e.target.value || "")
                                        }} />
                                </td>
                            </tr>
                            <tr className="payerHistoryRow">
                                <td className='userreport-labels'><label>From Date:</label></td>
                                <td className='userReportcriteriaWidth'>
                                    {rendeStartDate}
                                </td>
                            </tr>
                            <tr className="payerHistoryRow">
                                <td className='userreport-labels'><label>To Date:</label></td>
                                <td className='userReportcriteriaWidth'>
                                    {rendeEndDate}
                                </td>
                            </tr>
                            <tr className="payerHistoryRow">
                                <td className='userreport-labels'><label>Sort By:</label></td>
                                <td className='userReportcriteriaWidth'>
                                    <SelectDropdown
                                        isClearable={false}
                                        domID="reportSortBy"
                                        options={reportSortOptions}
                                        size="small"
                                        width="250px"
                                        initialValue={(reportSortOptions && reportSortOptions.find(x => x.value === sortBy)) || reportSortOptions[0]}
                                        onChange={(e) => handleReportSortByOptionChange(e)} />
                                </td>
                            </tr>
                            <tr className="payerHistoryRow">
                                <td className='userreport-labels'><label>Report View Type:</label></td>
                                <td className='userReportcriteriaWidth'>
                                    <SelectDropdown
                                        width="250px"
                                        isClearable={false}
                                        domID="reportType"
                                        options={SELECT_REPORT_OPTIONS}
                                        size="small"
                                        initialValue={initialREPORT_OPTIONValue}
                                        onChange={(e) => handleReportOptionChange(e)}
                                    />
                                </td>
                            </tr>
                            <tr><td>
                                {
                                    (<Button
                                        buttonType="standard"
                                        size="small"
                                        name="Submit"
                                        disabled={isLoading}
                                        onClick={() => { getReport() }}
                                    />)
                                }
                            </td><td></td></tr>
                        </tbody>
                    </table>
                </div>
                <div style={{ paddingTop: '10px' }}>
                    {
                        isLoading ? (<LoadingPage height="10vh"></LoadingPage>)
                            : (<div>
                                <Grid
                                    key={key}
                                    records={eftHistoryList || []}
                                    {...config}
                                />
                            </div>)
                    }

                </div>
            </div>
        </>
    )
}

export default EftHistoryReport;