import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { 
  Grid, 
  Input, 
  Checkbox, 
  useLayout,
  ListPaneHeader,
  ButtonGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody
} from 'ui-core/dist'
import useActiveUserList from '../../api/useActiveUserList'
import Authorizer from '../Authorizer'
import { GridPaginationWrapper, Loading, SelectDropdown } from '../../components'
import ErrorPage from '../ErrorPage';
import axios from '../../axios'
import './modules/styles.scss'
import useNotificationBannerHook from '../../useNotificationBannerHook';
import withAxiosCancellation from '../../components/withAxiosCancellation'

const ActiveUserListPane = ({getCancelSourceWithName}) => {
  const [ rowsPerPage, setRowsPerPage ] = useState(10);
  const [ pageIndex, setPageIndex ] = useState(0);
  const [ sortField, setSortField] = useState('userId')
  const [ sortDescending, setSortDecending ] = useState(false)
  const [ searchValue, setSearchValue] = useState('')
  const [ exactMatch, setExactMatch] = useState(false)
  const [ inFlight, setInFlight ] = useState(false)
  const [ isModalOpen, setIsModalOpen ] = useState(false)
  const [ viewInactive, setViewInactive ] = useState({
    label: 'Active',
    value: false
  })
  const [ selected, setSelected ] = useState([])
  const [ payload, setPayload ] = useState({
    maxRecords: rowsPerPage,
    rowPosition: pageIndex,
    sortField,
    sortDescending,
    viewInactive: viewInactive.value,
    searchValue,
    exactMatch,
  })
  const canBoot = useSelector(({ context }) => 
    context?.permissions?.filter(p => p.name === 'CAN_PERFORM_SECURITY_USER_BOOT').length > 0)
  const activeUserId = useSelector(({ session }) => session.userId)
  const {
    expandCollapseListPaneButtonConfig,
  } = useLayout()
  const { notify } = useNotificationBannerHook()
  const { data, isLoading, total, refetch , isError, error} = useActiveUserList({ payload, getCancelSource:getCancelSourceWithName('activeUserList') })

  useEffect(() => {
    if (isLoading) {
      setSelected([])
    }
  }, [isLoading])

  const onSortGridColumn = (e, { sortingKey }) => {
    const splitKey = sortingKey.split('|')
    setSortField(splitKey[0])
    if (splitKey[1] ==='SORT_DESCENDING') setSortDecending(true)
    else setSortDecending(false)
    setPayload({
      ...payload,
      sortField: splitKey[0],
      sortDescending: splitKey[1] === 'SORT_DESCENDING'
    })
  }

  const sortingComparator = (sortDataName, sortDirectionString, recordsToSort) =>  recordsToSort

  const onRowSelect = (e, state) => {
    const ids = Array.from(state.selectedItemIds);
    setSelected(ids);
  }

  const onSelectAll = (e, state) => {
    const ids = Array.from(state.selectedItemIds);
    setSelected(ids);
  }

  const handleShowButton = ({ pi }) => {
    if (viewInactive.value !== payload.viewInactive) {
      setPayload({
        maxRecords: 10,
        rowPosition: 0,
        sortField: 'userId',
        sortDescending: false,
        viewInactive: viewInactive.value,
        searchValue: '',
        exactMatch: false
      })
      setRowsPerPage(10)
      setPageIndex(0)
      setSortField('userId')
      setSortDecending(false)
      setSearchValue('')
      setExactMatch(false)
    } else {
      setPageIndex(pi)
      setSearchValue('')
      setExactMatch(false)
      setPayload({
        maxRecords: rowsPerPage,
        rowPosition: pi,
        sortField,
        sortDescending,
        viewInactive: viewInactive.value,
        searchValue: '',
        exactMatch: 'false',
      })
    }
  }

  const onFirstFilter  = (state) => setViewInactive(state)

  const onSearch  = () => {
    setPayload({
      maxRecords: 10,
      rowPosition: 0,
      sortField: 'userId',
      sortDescending: false,
      viewInactive: viewInactive.value,
      searchValue,
      exactMatch
    })
    setRowsPerPage(10)
    setPageIndex(0)
    setSortField('userId')
    setSortDecending(false)
  }

  const onEndUserSession = () => setIsModalOpen(true)

  const onSearchValueChange = (e, state) => setSearchValue(state.value)

  const onExactMatchToggle = () => setExactMatch(!exactMatch)

  const onConfirm = async () => {
    setInFlight(true)
    try {
      localStorage.setItem("isHideResetPassword", false);
      await axios.delete(`/activeUsers/terminate/${selected}`)
      notify(`You have successfully ended the session for user ${selected}`, 'positive')
      refetch()
    } catch (err) {
      notify(err.displayMessage, 'negative')
    } finally {
      setInFlight(false)
      setIsModalOpen(false)
    }
  }

  const onCancel = () => setIsModalOpen(false)

  const onClose = () => setIsModalOpen(false)

  return (
    <>
      <ListPaneHeader 
        title="Active User List"
        layoutControls={[expandCollapseListPaneButtonConfig]}
      />

    {isLoading ? <Loading /> : 
      isError ? (
      // replace with a better error component
      <ErrorPage
        // this prop isn't real but is an example
        error={error}
      />
      ) : (
        <>
      <div className="user-manager__search-row">
        <span className="user-manager__label">Search User ID:</span>
        <div style={{ width: '200px' }}>
          <Input 
            domID="SearchValueInput"
            onChange={onSearchValueChange}
            initialValue={searchValue}
            size="small"
          />
        </div>
        <span className="user-manager__label">Exact Match:</span>
        <Checkbox 
          domID="ExactMatchCheckbox"
          checked={exactMatch}
          onChange={onExactMatchToggle}
        />
        <Button 
          domID="searchButton"
          name="Search"
          onClick={onSearch}
          buttonType="standard"
          size="small"
        />
      </div>
      <GridPaginationWrapper
        label={`Currently ${total ? total > 1 ? `${total} users;` : `${total} user;` : ''}`}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={setRowsPerPage}
        pageIndex={pageIndex}
        onChangePageIndex={setPageIndex}
        onShowButtonCLick={handleShowButton}
        totalRecords={total}
        defaultRowsPerPage={10}
      />
      <ButtonGroup className="user-manager__button-group">
        <Authorizer permission="CAN_PERFORM_SECURITY_USER_BOOT" >
          <Button 
            domID="EndUserSessionButton"
            className="user-manager__button"
            buttonType="standard"
            size="small"
            name="End User Session"
            disabled={!(selected.length === 1) || inFlight}
            onClick={onEndUserSession}
          />
        </Authorizer>
      </ButtonGroup>
      
        <Grid
          columns={new Set([
            {
              dataName: 'userId',
              text: 'User Id',
              sortable: true,
              isSorted: 0,
              cellType: 'text',
            },
            {
              dataName: 'userName',
              text: 'User Name',
              sortable: true,
              isSorted: 0,
              cellType: 'text',
            },
            {
              dataName: 'logonDateTime',
              text: 'Logon Date/Time',
              sortable: true,
              isSorted: 0,
              cellType: 'text',
            },
            {
              dataName: 'lastAccessDateTime',
              text: 'Last Activity Date/Time',
              sortable: true,
              isSorted: 0,
              cellType: 'text',
            },
          ])}
          records={data || []}
          dataTestId="test-UserManagerGrid"
          domID="UserManagerGrid"
          onSortGridColumn={onSortGridColumn}
          selectionKey="userId"
          selectAllCheckboxDomID="UserManagerGridSelectAll"
          onRowSelect={onRowSelect}
          onSelectAll={onSelectAll}
          supportSelection={canBoot && (data||[]).filter(x=>x.userId!==activeUserId).length>0}
          initialSortingKey={`${sortField}|${sortDescending ? 'SORT_DESCENDING' : 'SORT_ASCENDING'}`}
          sortingComparator={sortingComparator}
          initialDisabledItemIds={new Set([activeUserId])}
          initialSelectedItemIds={new Set([...selected])}
        />
      <Modal isOpen={isModalOpen}>
        <ModalHeader 
          title="End User Session"
          onClose={onClose}
        />
        <ModalBody>
          <div className="end-session-modal">
            <p>Click <span className="--bold">Confirm</span> to end the current Client Access System session for user &ldquo;{selected}&ldquo;</p>
            <p><span className="--bold">Warning:</span> If you end the user&rsquo;s session, the user will lose any unsaved work.</p>
            <ButtonGroup>
              <Button 
                domID="endUserSessionCancelButton"
                name="Cancel"
                onClick={onCancel}
                size="small"
                buttonType="standard"
                disabled={inFlight}
              />
              <Button 
                domID="endUserSessionConfirmButton"
                name="Confirm"
                onClick={onConfirm}
                size="small"
                buttonType="standard"
                disabled={inFlight}
              />
            </ButtonGroup>
          </div>
        </ModalBody>
      </Modal>
      </>
      )}
    </>
  )
}

export default withAxiosCancellation(ActiveUserListPane)