/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import styled from 'styled-components/dist/styled-components';
import { useDispatch } from 'react-redux';
import moment from 'moment'
import { 
  Grid,
  Button,
  useLayout,
  Input,
  Checkbox,
} from 'ui-core/dist';
import { LoadingPage, SelectDropdown } from '../../components';
import ErrorPage from '../ErrorPage';
import ListPaneHeaderWrapper from '../../components/ErrorDisplaySection/ListPaneHeaderWrapper';
import GridPaginationWrapper from '../../components/GridPaginationWrapper';
import { ACH_TRANS_HIST } from './Modules/constants';
import { setFileIdDetailsACHManager } from '../DocumentManager/Modules/action';
import { useBankList,useFileList } from "../../api/useAchManager";
import useSortableGrid from '../../hooks/useSortableGrid';

const StyledGridWrapper = styled.div`
&.GridWrapper #grid-wrapper-test-id{
  overflow:initial !important;
}
`
const AchTransHistoryMain = () => {
  const {
    expandCollapseListPaneButtonConfig,
    openDetailPane,
    closeDetailPane
  } = useLayout()
  const dispatch = useDispatch()
  const [count, setcount] = useState(0)
  const [selectedRecord,setSelectedRecord]=useState('')
  const [totalRecords, setTotalRecords] = useState(0);
  const [records, setRecords] =useState([])
  const [rowsPerPage,setRowsPerPage]=useState(10);
  const [pageIndex,setPageIndex]=useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [bankLists, setBankList] = useState([]);
  const [dateReturn, setDateReturn] = useState({label:'30 days',value: '30'});
  const [bank, setBank] = useState({label:'--None--',value:''});
  
  
  const {allData: allBankLists, postData: getBankList}
    =useBankList()
  const { postData: getAllData,totalRecordsResp: totalFileData, allData: allDatalist,isError, isLoading, error }=useFileList();
  
  const SELECT_RECORD_OPTIONS = [{label:'30 days',value: '30'},{label: '60 days',value: '60'},{label: '90 days',value: '90'}
    ,{label: '120 days',value: '120'},{label: 'All',value: '8000'}]
  


  useEffect(() => {
    getBankList({maxRecords:10, rowPosition:0,sortField:'',viewInactive:false,sortDescending:false,parameters:'',searchCriteriaTag:'sc'});
    getAllData({ 
      maxRecords: 10,
      rowPosition: 0,
      sortField: '',
      sortDescending: false,
      viewInactive: false,
      parameters:'',
      type:'date',
      count 
    })
    setcount(count + 1)
    closeDetailPane();
  },[]);

  useEffect(()=>{
    if (allBankLists) {
      const retAddr = allBankLists || null;
      const newRetAddr = retAddr ? Array.isArray(retAddr) ? retAddr : [retAddr] : [];
      const so=newRetAddr.map((x)=>({label:`${x.name} [${x.routing}]`,value:x.id}))
      setBankList([{label:'--None--',value:''},...JSON.parse(JSON.stringify(so))])
    }
  },[allBankLists])

  const fetchData = useCallback((args) => {
    let sortcolumn; let sd; let pi;let type
    if (args) {
      ({sortcolumn,sd,pi}=args);
      
    }
    if(!sortcolumn || sortcolumn==='date'){
      type='date'
    }
    const endDate = moment().format('YYYYMMDD');
    const startDate = moment().add(-dateReturn.value, 'days').format('YYYYMMDD');
    getAllData({ 
      maxRecords:rowsPerPage,
      rowPosition:pi===undefined || pi===null?pageIndex: pi,
      sortField:sortcolumn===undefined || sortcolumn===null?sortConfig.sortDataName: sortcolumn,
      sortDescending:sd===undefined || sd===null?sortConfig.sortDescending: sd,
      viewInactive: false,
      parameters:`${startDate}${endDate}${bank.value}`,
      type,
      count 
    })
    closeDetailPane();
  }, [rowsPerPage, pageIndex, sortConfig,count, bank, dateReturn])
  
  const [sortConfig, getArgumentsAndSortGrid]=useSortableGrid(fetchData,"date","true");
  
  

  

  const onShowMoreClick = useCallback((record) => {
    dispatch(setFileIdDetailsACHManager({ selectedAchRecord : {}}));
    dispatch(setFileIdDetailsACHManager({ selectedAchRecord : record}));
    setSelectedRecord(JSON.parse(JSON.stringify(record)))
    openDetailPane();
  },[]);

 
  const renderGrid = useMemo(() => {
    if (records) {
      let data = []
      if(records.constructor.name === 'Array')
        data = JSON.parse(JSON.stringify(allDatalist))
      else 
        data = JSON.parse(JSON.stringify([allDatalist]))
      return (
        <div style={{marginTop: '-20px'}}>
          <p style={{margin: '0 0 14px 23px', fontSize: '13px'}}>Review Transmission History values.</p>
          <StyledGridWrapper className='GridWrapper'>
            <div className="displayFlex"  style={{paddingLeft: '23px'}}>
              
              <span>Bank: </span>
              <div style={{
                width: '360px',
                marginLeft: '5px',
                marginRight: '5px'
              }}>
                <SelectDropdown
                  width="350px"
                  domID="defer_state_input"
                  label=""
                  options={bankLists}
                  size="small"
                  initialValue={bank}
                  onChange={(e)=>{
                    setBank(e)
                  }}
                />
              </div>
              <span>Return: </span>
              <div style={{
                width: '180px',
                marginLeft: '5px',
                marginRight: '5px'
              }}>
                <SelectDropdown
                  width="180px"
                  domID="defer_state_input1"
                  label=""
                  options={SELECT_RECORD_OPTIONS}
                  size="small"
                  initialValue={dateReturn}
                  onChange={(e)=>{
                    setDateReturn(e)
                  }}
                />
              </div>
              <Button
                id='ContactEditorAdd'
                size="small"
                name="Search"
                onClick={(e) => {
                  fetchData()
                  setcount(count + 1)
                  closeDetailPane();
                  // setResetControls(false)
                }}
              />

            </div>

            <GridPaginationWrapper
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={setRowsPerPage}
              pageIndex={pageIndex}
              onChangePageIndex={setPageIndex}
              onShowButtonCLick={fetchData}
              totalRecords={totalFileData}
              defaultRowsPerPage={10}
            />
            <Grid
              {...ACH_TRANS_HIST}
              selectionKey='date'
              records={data || []}
              onClickThrough={(e, rec) => {
                e.preventDefault();
                e.stopPropagation();
                if (rec) {
                  const record = (data || []).find(x => x.id === rec.id)
                  if (onShowMoreClick) {
                    return onShowMoreClick({...record});
                  }
                  return false;
                }
                return false;
              }}
              focusedItem={selectedRecord}          
              // initialSortingKey={`${sortConfig.sortDataName}|${sortConfig.sortDescending==="true"?'SORT_DESCENDING':'SORT_ASCENDING'}`}
              // onSortGridColumn={(e,state)=>getArgumentsAndSortGrid(state)}
            />
          </StyledGridWrapper>
        </div>
      )
    }
    return null;
  }, [
    bank,
    bankLists,
    records,
    selectedRecord,
    dateReturn,
    rowsPerPage,
    pageIndex,
    totalRecords,
    allDatalist
  ])

  return (
    <>
      <ListPaneHeaderWrapper
        title="ACH Manager - Transmission History"
        layoutControls={[expandCollapseListPaneButtonConfig]}
      />
      {isLoading ? (
        // replace with a better loading component
        <LoadingPage />
      ) : isError ? (
        // replace with a better error component
        <ErrorPage
          // this prop isn't real but is an example
          error={error}
        />
      ) : (
        <>
          <div className="marginLeft">
            <table border="0" cellSpacing="1" cellPadding="3" width="100%">
              
              <tr>
                <td>
                  {renderGrid}
                </td>
              </tr>
            </table>
          </div>
          {/* {renderMainGrid} */}
        </>
      )}
    </>
  )
};

export default AchTransHistoryMain