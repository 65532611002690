export const SORTING_COMPARATOR = (
  sortDataName,
  sortDirectionString,
  records,
) => {
  // The UICL initial sort direction defaults to descending, and with multiple grids sorted in different directions there are some issues
  // This fixes some checkbox sorting bugs when using SORT_ASCENDING.
  const sortDataNameKey =
    sortDataName === 'activeGroupLabel' ? 'activeGroupLength' : sortDataName;
  const sortDirection = sortDirectionString === 'SORT_ASCENDING' ? -1 : 1;
  const inverter = -1;
  const negator = 0;
  return records.sort((a, b) => {
    const aData = a[sortDataNameKey];
    const bData = b[sortDataNameKey];
    let result = sortDirection;
    if (aData === undefined && bData !== undefined) {
      // if we have aData but no bData then aData goes on top always
      result *= inverter;
    } else if (
      (aData === undefined && bData === undefined) ||
      aData == bData // eslint-disable-line
      // we want to match 101 and "101" in this case
    ) {
      // If they are the same or both missing return "no change"
      result *= negator;
    } else if (aData > bData) {
      // This is the natural sorting behavior
      result *= inverter;
    }
    return result;
  });
};
