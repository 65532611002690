import { css } from 'styled-components';

export const Typography = {
  fontFamily: css`
    font-family: 'Core Sans C', sans-serif;
  `,
  uppercase: css`
    text-transform: uppercase;
  `,
  LetterSpacing: css`
    letter-spacing: 0.05em;
  `,
  normal: css`
    font-weight: normal;
  `,
  italic: css`
    font-style: italic;
  `,
  defaultFontWeight: css`
    font-weight: 400;
  `,
  bold: css`
    font-weight: 600;
  `,
  smallest: css`
    font-size: 7px;
  `,
  smaller8: css`
    font-size: 8px;
  `,
  extraSmall: css`
    font-size: 9px;
  `,
  defaultSmaller: css`
    font-size: 10px;
  `,
  small: css`
    font-size: 11px;
  `,
  medium: css`
    font-size: 12px;
  `,
  mediumLarge: css`
    font-size: 13px;
  `,
  default: css`
    font-size: 14px;
  `,
  large: css`
    font-size: 15px;
  `,
  extraLarge: css`
    font-size: 16px;
  `,
  headingSmall: css`
    font-size: 20px;
  `,
  heading: css`
    font-size: 30px;
  `,
  doubleExtraLarge: css`
    font-size: 1rem;
  `,
  huge: css`
    font-size: 1.2rem;
  `,
  smallestLineHeight: css`
    line-height: 1em;
  `,
  extraSmallLineHeight: css`
    line-height: 1.3em;
  `,
  defaultLineHeight: css`
    line-height: 1.4em;
  `,
  smallLineHeight: css`
    line-height: 1.5em;
  `,
  mediumLineHeight: css`
    line-height: 1.6em;
  `,
  mediumLargeHeight: css`
    line-height: 1.7em;
  `,
  moderateLineHeight: css`
    line-height: 1.9em;
  `,
  largeLineHeight: css`
    line-height: 2em;
  `,
  extraLargeLineHeight: css`
    line-height: 2.3em;
  `,
  defaultHeight: css`
    min-height: 1.55rem;
  `,
  extraSmallHeight: css`
    min-height: 1.85rem;
  `,
  smallHeight: css`
    min-height: 2.3rem;
  `,
  mediumMinHeight: css`
    min-height: 2.25rem;
  `,
};

export default Typography;
