import React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import ReserveForm from '../containers/FormImage835Manager';
import DeployForm from '../containers/FormImage835Manager/DeployFormIndex';
import CommitForm from '../containers/FormImage835Manager/CommitFormIndex';

const FormManagerRoutes = () => (
  <Switch>
    <Route path="/FormManager/ReserveForm" component={ReserveForm} />
    <Route path="/FormManager/DeployForm" component={DeployForm} />
    <Route path="/FormManager/CommitForm" component={CommitForm} />
    <Route exact path="/FormManager">
      <Redirect to="/FormManager/ReserveForm?param=0" />
    </Route>
  </Switch>
)

export default FormManagerRoutes;