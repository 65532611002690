import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Warning } from 'ui-core-ssgr';
import ButtonGroup from '../../../../shared/components/ButtonGroup';
import Colors from '../../../../shared/styles/colors';
import { DivWithPaddingTop } from '../../../../shared/styles/spacing';
import { 
  ModalPaddingWrapper, 
  BoldTextAfterWarningIcon, 
  IconWrapper, 
  ModalTitlePaddingWrapper,
  StyledDetailHeader
} from '../../../../shared/styles/styledComponents';

class CloneGroupModal extends React.Component {
  onButtonClick = name => {
    const {
      addNotification,
      isExpanded,
      resetDetailsPane,
      setIsCloningGroupState,
      setIsShowingCloneGroupModal,
      selectedRecord,
      toggleDetailsView,
      selectedItemIds,
    } = this.props;
    if (name === 'confirm' && (selectedItemIds.size === 1|| selectedRecord)) {
      if (!isExpanded) toggleDetailsView();
      resetDetailsPane();
      addNotification({
        id: selectedRecord.id,
        type: 'neutral',
        msg: `Fill out a unique group name and combination of hierarchy.`,
      });
      setIsCloningGroupState(true);
    }
    if (name === 'cancel') setIsCloningGroupState(false);
    setIsShowingCloneGroupModal(false);
  };

  onModalToggle = () => {
    const { setIsShowingCloneGroupModal, setIsCloningGroupState } = this.props;
    setIsCloningGroupState(false);
    setIsShowingCloneGroupModal(false);
  };

  render() {
    const { onButtonClick } = this;
    const { selectedRecord, showCloneGroupModal } = this.props;
    return (
      <Modal
        domID="modal-id"
        onModalToggle={() => this.onModalToggle()}
        isOpen={showCloneGroupModal}
        showDefaultClose
        deferBodyControl={false}
      >
        <ModalPaddingWrapper>
          <ModalTitlePaddingWrapper>
            <StyledDetailHeader
              title={`Clone Group: ${
                selectedRecord ? selectedRecord.name : ''
              }`}
            />
          </ModalTitlePaddingWrapper>
          <IconWrapper>
            <Warning fillColor={Colors.negativeDark} />
          </IconWrapper>
          <BoldTextAfterWarningIcon>
            {`This action will clone all the rule associations of the original group ${
              selectedRecord ? selectedRecord.name : ''
            }.
                The cloned group will be inactive. You must change the group name and group hierarchy.`}
          </BoldTextAfterWarningIcon>
          <DivWithPaddingTop>
            <ButtonGroup>
              <Button
                name="confirm"
                buttonType="emphasized"
                onClick={() => onButtonClick('confirm')}
                size="medium"
                type="button"
              />
              <Button
                name="cancel"
                buttonType="diminished"
                onClick={() => onButtonClick('cancel')}
                size="medium"
                type="button"
              />
            </ButtonGroup>
          </DivWithPaddingTop>
        </ModalPaddingWrapper>
      </Modal>
    );
  }
}

CloneGroupModal.propTypes = {
  addNotification: PropTypes.func.isRequired,
  selectedItemIds: PropTypes.object.isRequired,
  selectedRecord: PropTypes.object,
  setIsCloningGroupState: PropTypes.func.isRequired,
  setIsShowingCloneGroupModal: PropTypes.func.isRequired,
  showCloneGroupModal: PropTypes.bool.isRequired,
  toggleDetailsView: PropTypes.func.isRequired,
};

export default CloneGroupModal;
