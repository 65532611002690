import styled from 'styled-components/dist/styled-components';

const StyledBoxBorder=styled.div`
  width: ${props=>props.width||'max-content'};
  border: 1px solid #c3c5cd;
  border-spacing: 0;
  margin-top: 20px;
  margin-bottom: 20px;
  height:${props=>props.height||'auto'};
  overflow:${props=>props.overflow||'hidden'};
  color: #0F0F59;;
`

export default StyledBoxBorder;