import React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import {Page} from '../components'
import RecipientTypeTranslationMain from '../containers/ClientAdministration/RecipientTypeTranslation/RecipientTypeTranslationMain'
import RecipientTypeTranslationDetail from '../containers/ClientAdministration/RecipientTypeTranslation/RecipientTypeTranslationDetail'
import ClaimTypeTranslationMain from '../containers/ClientAdministration/CaimTypeTranslation/ClaimTypeTranslationMain'
import ClaimTypeTranslationDetail from '../containers/ClientAdministration/CaimTypeTranslation/ClaimTypeTranslationDetail'
import AddressCorrectionExclusionMain from '../containers/ClientAdministration/AddressCorrectionExclusion/AddressCorrectionExclusionMain'
import AddressCorrectionExclusionDetail from '../containers/ClientAdministration/AddressCorrectionExclusion/AddressCorrectionExclusionDetail'
import DocumentTypeTranslationMain from '../containers/ClientAdministration/DocumentTypeTranslation/DocumentTypeTranslationMain'
import DocumentTypeTranslationDetail from '../containers/ClientAdministration/DocumentTypeTranslation/DocumentTypeTranslationDetail'
import AssosicateReports from '../containers/ClientAdministration/ClientInformationManager/AssosicateReports'
import ClientInformationMenuPage from '../containers/ClientAdministration/ClientInformationManager';
import ContactEditorMain from '../containers/ClientAdministration/ClientInformationManager/ContactEditorMain';
import ContactEditorDetail from '../containers/ClientAdministration/ClientInformationManager/ContactEditorDetail'
import ClientNCOAPAFManagerMenuPage from '../containers/ClientAdministration/ClientNCOAPAFManager/index'
import AssosiateBrokers from '../containers/ClientAdministration/ClientNCOAPAFManager/AssosiateBrokers'
import BrokerEditorMain from '../containers/ClientAdministration/ClientNCOAPAFManager/BrokerEditorMain'
import BrokerEditorDetail from '../containers/ClientAdministration/ClientNCOAPAFManager/BrokerEditorDetail'
import PAFEditorMain from '../containers/ClientAdministration/ClientNCOAPAFManager/PAFEditorMain'
import PAFEditorDetail from '../containers/ClientAdministration/ClientNCOAPAFManager/PAFEditorDetail'
import TWExclusion from '../containers/ClientAdministration/TerroristWatchExclusion'
import TWExclusionDetail from '../containers/ClientAdministration/TerroristWatchExclusion/TWExclusionDetail'
import ClientSettings from '../containers/ClientAdministration/ClientSettings';
import ClientEmailMain from '../containers/ClientAdministration/ClientEmail/ClientEmailMain';
import ClientEmailDetail from '../containers/ClientAdministration/ClientEmail/ClientEmailDetail';


const RecipientTypeTranslationPage = () => (
  <Page
    ListPaneContent={RecipientTypeTranslationMain}
    DetailPaneContent={RecipientTypeTranslationDetail}
  />
);
const AddressCorrectionExclusionPage = () => (
  <Page
    ListPaneContent={AddressCorrectionExclusionMain}
    DetailPaneContent={AddressCorrectionExclusionDetail}
  />
);
const AssosicateReportsPage = () => (
  <Page
    ListPaneContent={AssosicateReports}
  />
);

const ClaimTypeTranslationPage = () => (
  <Page
    ListPaneContent={ClaimTypeTranslationMain}
    DetailPaneContent={ClaimTypeTranslationDetail}
  />
);

const DocumentTypeTranslationPage = () => (
  <Page
    ListPaneContent={DocumentTypeTranslationMain}
    DetailPaneContent={DocumentTypeTranslationDetail}
  />
)

const ClientInformationMenu=()=>(
  <Page
    ListPaneContent={ClientInformationMenuPage}
  />
)

const ContactEditorMainPage=()=>(
  <Page
    ListPaneContent={ContactEditorMain}
    DetailPaneContent={ContactEditorDetail}
  />
)

const ClientNCOAPAFManagerMenu=()=>(
  <Page
    ListPaneContent={ClientNCOAPAFManagerMenuPage}
  />
)
const AssosiateBrokersPage=()=>(
  <Page
    ListPaneContent={AssosiateBrokers}
  />
)
const BrokerEditorPage=()=>(
  <Page
    ListPaneContent={BrokerEditorMain}
    DetailPaneContent={BrokerEditorDetail}
  />
)
const PAFEditorPage=()=>(
  <Page
    ListPaneContent={PAFEditorMain}
    DetailPaneContent={PAFEditorDetail}
  />
)

const TWExclusionMenu=()=>(
  <Page
    ListPaneContent={TWExclusion}
    DetailPaneContent={TWExclusionDetail}
  />
)


const ClientEmailPage = () => (
  <Page
    ListPaneContent={ClientEmailMain}
    DetailPaneContent={ClientEmailDetail}
  />
)

const ClientAdminRoutes = () => (
  <Switch>
    <Route path="/ClientAdministration/AddressCorrectionExclusion" component={AddressCorrectionExclusionPage} />
    <Route path="/ClientAdministration/ClaimTypeTranslation" component={ClaimTypeTranslationPage} />
    <Route path="/ClientAdministration/ClientEmail" component={ClientEmailPage} />
    <Route path="/ClientAdministration/ClientInformationManager/ClientInformationMenu" component={ClientInformationMenu} />
    <Route path="/ClientAdministration/ClientInformationManager/associate_reports" component={AssosicateReportsPage} />
    <Route path="/ClientAdministration/ClientInformationManager/contact_editor" component={ContactEditorMainPage} />
    <Route path="/ClientAdministration/InternalRules" component={Page} />

    <Route path="/ClientAdministration/ClientNCOAPAFManager/ClientNCOAPAFManagerMenu" component={ClientNCOAPAFManagerMenu} />
    <Route path="/ClientAdministration/ClientNCOAPAFManager/associate_brokers_editor" component={AssosiateBrokersPage} />
    <Route path="/ClientAdministration/ClientNCOAPAFManager/broker_editor" component={BrokerEditorPage} />
    <Route path="/ClientAdministration/ClientNCOAPAFManager/paf_editor" component={PAFEditorPage} />
    <Route path="/ClientAdministration/DocumentTypeTranslation" component={DocumentTypeTranslationPage} />
    <Route path="/ClientAdministration/RecipientTypeTranslation" component={RecipientTypeTranslationPage} />
    <Route path="/ClientAdministration/RemarkCode" component={Page} />
    <Route path="/ClientAdministration/TerroristWatchExclusion" component={TWExclusionMenu} />
    
    <Route exact path="/ClientAdministration/ClientNCOAPAFManager">
      <Redirect to="/ClientAdministration/ClientNCOAPAFManager/ClientNCOAPAFManagerMenu" />
    </Route>
    <Route path="/ClientAdministration/TerroristWatchExclusion" component={Page} />
    <Route path="/ClientAdministration/ClientEditorSettings" component={ClientSettings} />
    
    <Route exact path="/ClientAdministration/ClientInformationManager">
      <Redirect to="/ClientAdministration/ClientInformationManager/ClientInformationMenu" />
    </Route>
    <Route exact path="/ClientAdministration">
      <Redirect to="/ClientAdministration/AddressCorrectionExclusion" />
    </Route>
  </Switch>
)

export default ClientAdminRoutes;
