import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { SelectDropdown, LoadingIndicator, Button } from 'ui-core-ssgr';

import {
  DropOne,
  DropTwo,
  DropThree,
  ContainerBox,
  AutoHoldDropDown,
} from './styles';
import { 
  ButtonGroupBottom,
  ReadOnlyTextTitle,
  ReadOnlyTextArea
} from '../../../shared/styles/styledComponents';
import {
  LoadingWrapper,
  ModalButton,
} from '../../RulesTabView/components/RuleDetails/RuleDetailsGridWrapper/styles';
import { DivWithPaddingTop } from '../../../shared/styles/spacing';

class AutoHoldModal extends Component {
  state = {
    claimType: 'default',
    recipientType: 'default',
    documentType: 'default',
    isButtonDisabled: true,
  };

  componentDidMount() {
    this.getInitialValues();
    const { isPostingRuleDetail } = this.props;
    if (isPostingRuleDetail) {
      this.setState({ isButtonDisabled: false });
    }
  }

  getInitialValues = () => {
    const { detailElements, selectedRuleDetail, actionType } = this.props;
    let claimType = 'default';
    let recipientType = 'default';
    let documentType = 'default';
    if (
      !_.isEmpty(detailElements) &&
      !_.isEmpty(selectedRuleDetail) &&
      (actionType === 'Edit a' || actionType === 'Clone a' || actionType === 'Read only')
    ) {
      if (detailElements.claimTypesList) {
        claimType = detailElements.claimTypesList.find(
          o => o.value === selectedRuleDetail.claimType,
        ).code;
      }
      if (detailElements.recipientTypesList) {
        recipientType = detailElements.recipientTypesList.find(
          o => o.value === selectedRuleDetail.recipientType,
        ).code;
      }
      if (
        detailElements.documentTypesList &&
        selectedRuleDetail.documentType !== 'Any'
      ) {
        documentType = detailElements.documentTypesList.find(
          o => o.value === selectedRuleDetail.documentType,
        ).code;
      } else {
        documentType = 'default';
      }
      this.setState({
        claimType,
        recipientType,
        documentType,
      });
    } else {
      this.setState({
        claimType,
        recipientType,
        documentType,
        isButtonDisabled: true,
      });
    }
  };

  getRuleVersion = record => {
    try {
      const { status, version } = record;
      const testVersion = version[status.indexOf('T')];
      if (testVersion) return testVersion;
      const liveVersion = version[status.indexOf('L')];
      return liveVersion;
    } catch (e) {
      console.log(e);
    }
    return null;
  };

  populateDataDropDown = (name, initialValue) => {
    const { detailElements, selectedRuleDetail } = this.props;
    if (!_.isEmpty(detailElements)) {
      if (name === 'claimType' && detailElements) {
        let options = [];
        let selectedClaimType = '';
        const claimTypeValue = selectedRuleDetail.claimType;
        const firstValue = detailElements.claimTypesList.find(
          x => x.value === claimTypeValue,
        );
        const arr = firstValue
          ? [...new Set([firstValue, ...detailElements.claimTypesList])]
          : detailElements.claimTypesList;
        options = arr.map(r => {
          if (r.code === initialValue) {
            selectedClaimType = r.code;
          }
          return {
            label: r.value,
            value: r.code,
          };
        });
        return [options, selectedClaimType];
      }
      if (name === 'recipient' && detailElements.recipientTypesList) {
        let options = [];
        let selectedRecipientType = '';
        const recipientValue = selectedRuleDetail.recipientType;
        const firstValue = detailElements.recipientTypesList.find(
          x => x.value === recipientValue,
        );
        const arr = firstValue
          ? [...new Set([firstValue, ...detailElements.recipientTypesList])]
          : detailElements.recipientTypesList;
        options = arr.map(r => {
          if (r.code === initialValue) {
            selectedRecipientType = r.code;
          }
          return {
            label: r.value,
            value: r.code,
          };
        }).filter(v => v.value);
        return [options, selectedRecipientType];
      }
      if (name === 'documentType' && detailElements.documentTypesList) {
        let options = [];
        let selectedDocumentType = '';
        const documentTypeValue = selectedRuleDetail.documentType;
        const firstValue = detailElements.documentTypesList.find(x =>
          x.value.includes(documentTypeValue),
        );
        const arr = firstValue
          ? [...new Set([firstValue, ...detailElements.documentTypesList])]
          : detailElements.documentTypesList;
        options = arr.map(r => {
          if (r.code === initialValue) {
            selectedDocumentType = r.code;
          }
          return {
            label: r.value,
            value: r.code,
          };
        });
        return [options, selectedDocumentType];
      }
    }
  };

  onCancel = () => {
    const {
      setIsCloningRuleDetail,
      setIsPostingRuleDetail,
      setIsShowingAutoHoldModal,
      setIsReadOnlyRuleDetail
    } = this.props;
    setIsShowingAutoHoldModal(false);
    setIsCloningRuleDetail(false);
    setIsPostingRuleDetail(false);
    this.setState({ isButtonDisabled: true });
    setIsReadOnlyRuleDetail(false);
  };

  onSave = () => {
    const {
      isCloningRuleDetail,
      isPostingRuleDetail,
      postRuleDetails,
      putRuleDetails,
      selectedRecord,
      selectedRuleDetail,
      selectedRuleRecord,
      selectedRuleTypeId,
    } = this.props;
    const { claimType, recipientType, documentType } = this.state;
    const record = selectedRuleRecord || selectedRecord;
    const ruleId = record.id;
    const ruleDetailId = selectedRuleDetail.id;
    const ruleVersion = this.getRuleVersion(record);
    const ruleType = selectedRuleTypeId;
    let ruleDetail = {
      claimType,
      recipientType,
      documentType,
    };
    ruleDetail = isCloningRuleDetail
      ? { ...ruleDetail, ...{ ruleDetailIdFrom: ruleDetailId } }
      : ruleDetail;
    if (isPostingRuleDetail || isCloningRuleDetail) {
      postRuleDetails({ ruleDetail, ruleType, ruleId, ruleVersion });
    } else {
      putRuleDetails({
        ruleDetail,
        ruleType,
        ruleId,
        ruleVersion,
        ruleDetailId,
      });
    }
  };

  populateCodeFromValue = name => {
    const { detailElements, selectedRuleDetail } = this.props;
    if (!_.isEmpty(detailElements)) {
      if (name === 'claimType' && detailElements) {
        let selectedClaimType = '';
        const claimTypeValue = selectedRuleDetail.claimType;
        const firstValue = detailElements.claimTypesList.find(
          x => x.value === claimTypeValue,
        );

        const arr = firstValue
          ? [...new Set([firstValue, ...detailElements.claimTypesList])]
          : detailElements.claimTypesList;
        arr.map(r => {
          if (r.value === claimTypeValue) {
            selectedClaimType = r.code;
          }
          return {
            label: r.value,
            value: r.code,
          };
        });
        return selectedClaimType;
      }
      if (
        name === 'recipientType' &&
        detailElements.recipientTypesList
      ) {
        let selectedRecipientType = '';
        const recipientValue = selectedRuleDetail.recipientType;
        const firstValue = detailElements.recipientTypesList.find(
          x => x.value === recipientValue,
        );
        const arr = firstValue
          ? [...new Set([firstValue, ...detailElements.recipientTypesList])]
          : detailElements.recipientTypesList;
        arr.map(r => {
          if (r.value === recipientValue) {
            selectedRecipientType = r.code;
          }
          return {
            label: r.value,
            value: r.code,
          };
        });
        return selectedRecipientType;
      }
      if (name === 'documentType' && detailElements.documentTypesList) {
        let selectedDocumentType = '';
        const documentTypeValue = selectedRuleDetail.documentType;
        const firstValue = detailElements.documentTypesList.find(x =>
          x.value.includes(documentTypeValue),
        );
        const arr = firstValue
          ? [...new Set([firstValue, ...detailElements.documentTypesList])]
          : detailElements.documentTypesList;
        arr.map(r => {
          if (r.value === documentTypeValue) {
            selectedDocumentType = r.code;
          }
          return {
            label: r.value,
            value: r.code,
          };
        });
        return selectedDocumentType;
      }
    }
  };

  checkUnsavedChanges = event => {
    const { claimType, recipientType, documentType } = this.state;
    const { populateCodeFromValue } = this;
    const claimTypeDbCode = populateCodeFromValue('claimType');
    const recipientTypeDbCode = populateCodeFromValue('recipientType');
    const documentTypeDbCode = populateCodeFromValue('documentType');
    const {name, value} = event.target;
    if (event.target) {
      this.setState(
        {
          [name]: value,
          isButtonDisabled: false,
        },
        () => {
          this.setButtonDisableOnDefault(
            claimTypeDbCode,
            recipientTypeDbCode,
            documentTypeDbCode,
          );
        },
      );
    } else {
      this.setState(
        {
          claimType,
          recipientType,
          documentType,
        },
        () => {
          this.setButtonDisableOnDefault(
            claimTypeDbCode,
            recipientTypeDbCode,
            documentTypeDbCode,
          );
        },
      );
    }
  };

  // used for read only purpose
  pullValuefromCode = (code, selectedList) => {
    const { detailElements, selectedRuleDetail } = this.props;
    let selectedCode = '';
    if (!_.isEmpty(code)) {
      const selectedName = selectedList.replace('List', '');
      const replaceSelectedName = selectedName.indexOf('Types') ? selectedName.replace('Types', 'Type') : selectedName;
      const selectedValue = selectedRuleDetail[replaceSelectedName];
      const detailListItem = detailElements[selectedList];
      const firstValue = _.find(detailListItem, x =>
        x.value.includes(selectedValue),
      ) || {};
      const arr = firstValue
        ? [...new Set([firstValue, ...detailListItem])]
        : detailListItem;
      arr.map(r => {
        if (r.value === selectedValue) {
          selectedCode = r.value;
        }
        return {
          label: r.value,
          value: r.code,
        };
      });
    }
    return selectedCode;
  };


  setButtonDisableOnDefault(
    claimTypeCode,
    recipientTypeCode,
    documentTypeCode,
  ) {
    const { actionType } = this.props;
    const { claimType, recipientType, documentType } = this.state;
    let newState = false;
    if (
      (actionType === 'Edit a' || actionType === 'Clone a') &&
      claimType === claimTypeCode &&
      recipientType === recipientTypeCode &&
      documentType === documentTypeCode
    ) {
      newState = true;
    }
    this.setState({ isButtonDisabled: newState });
  }

  render() {
    const { checkUnsavedChanges } = this;
    const {
      claimType,
      recipientType,
      documentType,
      isButtonDisabled,
    } = this.state;
    const {
      loading,
      selectedRuleRecord,
      selectedRuleDetail,
      selectedRecord,
      actionType,
    } = this.props;
    const recordId = selectedRuleRecord
      ? selectedRuleRecord.id
      : selectedRecord.id;
    const optionsClaimType = this.populateDataDropDown(
      'claimType',
      selectedRuleDetail.claimType,
    );
    const optionsRecipientType = this.populateDataDropDown(
      'recipient',
      selectedRuleDetail.recipientType,
    );
    const optionsDocumentType = this.populateDataDropDown(
      'documentType',
      selectedRuleDetail.documentType,
    );
    let claimTypeOptions = [];
    let recipientTypeOptions = [];
    let documentTypeOptions = [];
    if (optionsClaimType) {
      const [firstIndex] = optionsClaimType;
      claimTypeOptions = firstIndex;
    }
    if (optionsRecipientType) {
      const [firstIndex] = optionsRecipientType;
      recipientTypeOptions = firstIndex;
    }
    if (optionsDocumentType) {
      const [firstIndex] = optionsDocumentType;
      documentTypeOptions = firstIndex;
    }
    // FOR READ ONLY CONDITIONAL RENDERING (Live Rule)
    let claimTypeReadOnly = '';
    let recipientTypeReadOnly = '';
    let documentTypeReadOnly = '';

    if(actionType === 'Read only'){
      claimTypeReadOnly = (claimType !== 'default') ? this.pullValuefromCode(claimType, 'claimTypesList') : 'Any';
      recipientTypeReadOnly = (recipientType !== 'default') ? this.pullValuefromCode(recipientType,'recipientTypesList') : 'Any';
      documentTypeReadOnly = (documentType !== 'default') ? this.pullValuefromCode(documentType,'documentTypesList') : 'Any';
    }
    return (
      <>
        {actionType !== 'Read only' ? (
          <DivWithPaddingTop></DivWithPaddingTop>
        ) : null}
        <ContainerBox>
          {actionType !== 'Read only' ? (
            <AutoHoldDropDown>
              <DropOne>
                <SelectDropdown
                  domID="test-id"
                  label="claim type"
                  name="claimType"
                  options={claimTypeOptions}
                  initialValue={claimType}
                  startWithBlankValue={false}
                  onChange={checkUnsavedChanges}
                />
              </DropOne>
            </AutoHoldDropDown>) : <ReadOnlyTextTitle>Claim Type<ReadOnlyTextArea>{claimTypeReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
          {actionType !== 'Read only' ? (
            <AutoHoldDropDown>
              <DropTwo>
                <SelectDropdown
                  domID="test-id"
                  label="recipient"
                  name="recipientType"
                  options={recipientTypeOptions}
                  initialValue={recipientType}
                  startWithBlankValue={false}
                  onChange={checkUnsavedChanges}
                />
              </DropTwo>
            </AutoHoldDropDown> ) : <ReadOnlyTextTitle>Recipient Type<ReadOnlyTextArea>{recipientTypeReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
          {actionType !== 'Read only' ? (
            <AutoHoldDropDown>
              <DropThree>
                <SelectDropdown
                  domID="test-id"
                  label="document type"
                  name="documentType"
                  options={documentTypeOptions}
                  initialValue={documentType}
                  startWithBlankValue={false}
                  onChange={checkUnsavedChanges}
                />
              </DropThree>
            </AutoHoldDropDown> ) : <ReadOnlyTextTitle>Document Type<ReadOnlyTextArea>{documentTypeReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
        </ContainerBox>
        <ButtonGroupBottom>
          <ModalButton>
            {loading.has(recordId) && (
              <LoadingWrapper>
                <LoadingIndicator
                  domID="basic-details-form-loading-indicator"
                  length="30px"
                />
              </LoadingWrapper>
            )}
            {actionType !== 'Read only' ? (
              <Button
                onClick={() => this.onSave()}
                domID="automation-id"
                name="Save"
                buttonType="emphasized"
                size="medium"
                isDropdown={false}
                type="button"
                disabled={isButtonDisabled || loading.has(recordId)}
              /> ): null }
            <Button
              domID="automation-id"
              name={actionType !== 'Read only' ? "Cancel" : "Close"}
              buttonType="diminished"
              disabled={loading.has(recordId)}
              size="medium"
              onClick={() => this.onCancel()}
              isDropdown={false}
              type="button"
            />
          </ModalButton>
        </ButtonGroupBottom>
      </>
    );
  }
}

AutoHoldModal.propTypes = {
  detailElements: PropTypes.object,
  loading: PropTypes.object,
  selectedRuleRecord: PropTypes.object,
  selectedRuleDetail: PropTypes.object,
  setIsCloningRuleDetail: PropTypes.func,
  setIsPostingRuleDetail: PropTypes.func,
  setIsShowingAutoHoldModal: PropTypes.func,
  isShowingAutoHoldModal: PropTypes.bool,
  isCloningRuleDetail: PropTypes.bool,
  isPostingRuleDetail: PropTypes.bool,
  postRuleDetails: PropTypes.func,
  putRuleDetails: PropTypes.func,
  actionType: PropTypes.string,
  selectedRecord: PropTypes.object,
  selectedRuleTypeId: PropTypes.string,
};
export default AutoHoldModal;
