import styled from 'styled-components';
import { Input } from 'ui-core-ssgr';
export const ContainerBoxTextArea = styled.div`
  width: 89%;
`;

export const CheckTextRuleInput = styled.div`
  height: 50px;
  width: 200px;
  margin-right: 35px;
  margin-top: 10px;
`;

export const CheckTextImageWrapper = styled.div`
  width: 200px;
  margin-right: 35px;
  margin-top: 10px;
  & > button {
    padding-left: 5px;
  }
`;
export const HeaderTitle = styled.div`
text-align:center;
width: 65%;
padding-left:20rem;
`;

export const ImageFilter = styled(Input)`
  margin-left: 870px;
  input {
    padding-right: 1.25em;
    min-width: 240px;
  }
  & > button {
    height: 32px;
    top: 0;
  }
`;

export const SearchButtonImage = styled.img`
  height: 1.25em;
`;

export const RuleTypesDetailRow = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-right:3%;
`;
