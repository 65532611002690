import React, { useState, useEffect } from 'react'
import {
  useLayout,
  ListPaneHeader,
  Button,
} from 'ui-core/dist'
import { SelectDropdown } from '../../components'
import axios from '../../axios';
import { useBillingReportAccessRequest, useDownloadReportFile } from '../../api/useReportManager';
import { LoadingPage } from '../../components';
import useNotificationBannerHook from '../../useNotificationBannerHook';
import withAxiosCancellation from '../../components/withAxiosCancellation';
import ErrorPage from '../ErrorPage';
import './modules/style.scss'


const BillingReport = ({ getCancelSourceWithName }) => {
  const {
    expandCollapseListPaneButtonConfig
  } = useLayout()
  const { notify } = useNotificationBannerHook()

  const { data: dataBillingReportAccess, isLoading, error, isError } = useBillingReportAccessRequest({ getCancelSource: getCancelSourceWithName('billingReportAccessRequest') });
  const [bAIsLoading, setbAIsLoading] = useState(true);
  const [tmpFileName, setTmpFileName] = useState('');
  const { file, getDownloadFile } = useDownloadReportFile();
  const [yIsLoading, setYIsLoading] = useState(false);
  const [rIsLoading, setRIsLoading] = useState(false);
  const [isUserHasAcces, setIsUserHasAcces] = useState({
    access: false,
    enabled: false,
    loading: true,
  });

  const [reportType, setReportType] = useState();
  const [yearMonthValue, setYearMonthValue] = useState();
  const [yearMonthList, setYearMonthList] = useState([]);
  const [reportList, setReportList] = useState();
  const [reportLinks, setreportLinks] = useState();

  useEffect(() => {
    if (dataBillingReportAccess?.access) {
      if (dataBillingReportAccess?.access === "YES") {

        axios.get(`/reports-manager/billing-tax-enabled`).then(res => {
          let enabled = res?.data?.enabled;
          if (enabled == "YES") {
            setIsUserHasAcces({
              access: true,
              enabled: true,
              loading: false
            });
          } else {
            setIsUserHasAcces({
              access: true,
              enabled: false,
              loading: false
            });
          }

          setbAIsLoading(false);
        }).catch(err => {
          setIsUserHasAcces({
            access: true,
            enabled: false,
            loading: false
          });
          setbAIsLoading(false);
        })
      }
      else {
        setIsUserHasAcces({
          access: false,
          enable: false,
          loading: false
        });
        setbAIsLoading(false);
      }
    }
  }, [dataBillingReportAccess]);


  const noAccessText = 'You do not have access to the Billing Reports.';
  const noAccesAdminText = 'Please contact your account manager for assistance.';
  const SELECT_REPORT_OPTIONS = [{
    label: "Standard Bills",
    value: "1"
  },
  {
    label: "1099 Bills",
    value: "0"
  }
  ];
  const [initialREPORT_OPTIONValue, setInitialREPORT_OPTIONValue] = useState({ label: "", value: "" });
  const [initialyearMonthValue, setInitialyearMonthValue] = useState({ label: "", value: "" });


  const [called, setCalled] = useState(1);

  const downloadPdf = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      const res = await axios.post(`fileReceiptManager/downloadFile`, { url: e.target.href, inlinePdf: true }, { responseType: 'blob' })
      if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
        window.navigator.msSaveOrOpenBlob(res.data, 'BillingReport.pdf');
      } else {
        const url = URL.createObjectURL(res.data);
        const tab = window.open();
        if (tab)
          tab.location.href = url;
        else
          notify('Please enable allow window pop-up in your browser.', 'caution')
      }
    } catch (err) {
      notify(err.displayMessage, "negative")
    }
  }

  const downloadReport = async (downloadUrl) => {
    try {
      const res = await axios.post(
        `fileReceiptManager/downloadFile`,
        { url: downloadUrl },
        { responseType: 'blob' }
      )
      const fileName = downloadUrl.split("/").pop();
      if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
        window.navigator.msSaveOrOpenBlob(res.data, fileName);
      } else {
        const url = URL.createObjectURL(res.data);
        const element = document.createElement('a');
        element.setAttribute('href', url);
        element.setAttribute('download', fileName);
        element.click();
      }
    } catch (err) {
      notify(err.displayMessage, 'negative')
    }
  }

  useEffect(() => {
    if (file) {
      if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
        window.navigator.msSaveOrOpenBlob(file, tmpFileName);
      } else {
        const url = URL.createObjectURL(file);
        const element = document.createElement('a');
        element.setAttribute('href', url);
        element.setAttribute('download', tmpFileName);
        element.click();
      }
    }
  }, [file, tmpFileName])

  const handleReportOptionChange = (e) => {
    setYearMonthValue('')
    setInitialyearMonthValue({ label: "", value: "" })
    setYIsLoading(true);
    setInitialREPORT_OPTIONValue({ label: e?.label, value: e?.value });
    setReportType(e?.value);
    axios.get(`/reports-manager/billing-report-YMList/${e?.value}`).then(res => {
      setYIsLoading(false);
      let valueTemp = res?.data?.list.split("|");
      let newValueTemp = [];
      valueTemp.forEach(val => {
        if (newValueTemp.indexOf(val) === -1 && val !== 'NO DATA FOUND') {
          newValueTemp.push(val)
        }
      });
      let modifiedResult = newValueTemp.map(obj => ({ label: obj, value: obj }));
      setYearMonthList(modifiedResult);
    }).catch(err => {
      notify(err?.displayMessage, 'negative')
      setYIsLoading(false);
    })
  }
  const handleyearMonthOptionChange = (e) => {
    setInitialyearMonthValue({ label: e?.label, value: e?.value });
    setYearMonthValue(e?.value);
  }

  const getBillingReport = () => {
    if (reportType && yearMonthValue) {
      setRIsLoading(true);
      axios.get(`/reports-manager/billing-report-link/${reportType}/${yearMonthValue}`).then(res => {
        let valueTemp = res.data.report.split(";");
        let modifiedResult = valueTemp.map((obj) => {
          if (obj.includes(".pdf")) {
            let filename = obj.substring(obj.lastIndexOf('/') + 1);
            if (filename.split('_').length > 2) {
              let superGroupStartPosition = filename.split('_', 2).join('_').length + 1;
              return { label: "Adobe PDF Invoice for: " + filename.substring(superGroupStartPosition).replace('.pdf', ''), urlLink: obj, isOpen: true }
            }
            else {
              return { label: "Adobe PDF Invoice", urlLink: obj, isOpen: true }
            }
          }
          else if (obj.includes(".xls")) {
            return { label: "MS Excel eBill", urlLink: obj }
          }
          else if (obj.includes(".csv")) {
            return { label: "State Sales Tax Details", urlLink: obj }
          }
        });
        setReportList(modifiedResult);
        setRIsLoading(false);
      }).catch(err => {
        notify(err?.displayMessage, 'negative')
        setRIsLoading(false);
      })
    }

  }

  return (
    <>
      <ListPaneHeader
        title={`Billing Reports(Standard Bills, State Sales Tax, and 1099 Bills)`}
        layoutControls={[expandCollapseListPaneButtonConfig]}
      />
      {
        isError ? (
          // replace with a better error component
          <ErrorPage
            // this prop isn't real but is an example
            error={error}
          />
        ) :
          isLoading ? <LoadingPage height='40px'></LoadingPage> :
            bAIsLoading ? (<LoadingPage height='40px'></LoadingPage>) : (<div>
              {isUserHasAcces.loading ? <LoadingPage height='40px'></LoadingPage> : !isUserHasAcces.access ? (<div className="noAccessReportColor">{noAccessText}
                <br></br>
                {noAccesAdminText}</div>) : (<div className="billingReportCietrria">

                  <table id="billingReportSearch">
                    <tr>
                      <td className='userreport-labels'><label>Select Report Type:</label></td>
                      <td className='userReportcriteriaWidth'>
                        <SelectDropdown
                          isClearable={false}
                          domID="reportType1"
                          options={SELECT_REPORT_OPTIONS}
                          size="small"
                          initialValue={initialREPORT_OPTIONValue}
                          onChange={(e) => handleReportOptionChange(e)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='userreport-labels'><label>Select year and Month:</label></td>
                      <td className='userReportcriteriaWidth'>
                        {
                          yIsLoading ? (
                            <LoadingPage height='40px'></LoadingPage>
                          ) : (
                            <SelectDropdown
                              isClearable={false}
                              domID="reportDatasourse1"
                              options={yearMonthList}
                              size="small"
                              initialValue={initialyearMonthValue}
                              onChange={(e) => handleyearMonthOptionChange(e)}
                            />)
                        }
                      </td>
                    </tr>

                    <tr><td><Button
                      buttonType="standard"
                      size="small"
                      name="Submit"
                      onClick={() => { getBillingReport() }}
                    /></td><td></td></tr>
                  </table>

                  <div>
                    {
                      rIsLoading ? (<LoadingPage height='40px'></LoadingPage>) :

                        (
                          <ul>
                            {
                              reportList?.map((i, index) => {
                                return (
                                  <>
                                    {i != undefined && i.urlLink != undefined &&
                                      <li key={index}>
                                        <a href={i.urlLink} onClick={
                                          i.isOpen ? (e) => { downloadPdf(e) } : (e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            downloadReport(i.urlLink)
                                          }
                                        }> {i.label} </a>
                                      </li>
                                    }</>
                                )
                              })
                            }
                          </ul>
                        )
                    }
                  </div>
                </div>)

              }</div>)
      }
    </>
  )
}

export default withAxiosCancellation(BillingReport);