import Api from '..';
import { API_BASE } from '../constants';

// -------------- Edit Rules For Rule Types ----------------
export const putRulesForRuleTypes = async (
  { clientLayoutId, userSecurityTokenKey, username, roleId },
  { id = '', name = '', desc = '', ruleType },
) => {
  try {
    const ruleTypeId = ruleType || window.location.href.substring(
      window.location.href.lastIndexOf('=') + 1,
    );
    const baseUrl = `${API_BASE}/rules/${clientLayoutId}/${ruleTypeId}/${id}`;
    const api = new Api(userSecurityTokenKey, username, null, roleId);
    const updateRulesForRuleTypes = JSON.stringify({
      name,
      description: desc,
    });
    const putRulesForRuleTypesResponse = await api.put(baseUrl, updateRulesForRuleTypes);

    if (putRulesForRuleTypesResponse.error) {
      throw putRulesForRuleTypesResponse.error;
    } else {
      return putRulesForRuleTypesResponse;
    }
  } catch (error) {
    throw error;
  }
};

// -------------- Post Rules For Rule Types ----------------
export const postRulesForRuleTypes = async (
  { clientLayoutId, userSecurityTokenKey, username, roleId },
  {
    name = '',
    desc = '',
    isCloningRule,
    ruleIdFrom,
    ruleVersionFrom,
    ruleType,
  },
) => {
  try {
    const ruleTypeId = ruleType || window.location.href.substring(window.location.href.lastIndexOf('=') + 1);
    const body = isCloningRule ? {
      name: name.trim(),
      description: desc.trim(),
      ruleIdFrom,
      ruleVersionFrom,
    } : {
      name: name.trim(),
      description: desc.trim(),
    };
    const baseUrl = `${API_BASE}/rules/${clientLayoutId}/${ruleTypeId}`;
    const api = new Api(userSecurityTokenKey, username, null, roleId);
    const postRulesForRuleTypesResponse = await api.post(baseUrl, JSON.stringify(body));
  
    if (postRulesForRuleTypesResponse.error) {
      throw postRulesForRuleTypesResponse.error;
    } else {
      return postRulesForRuleTypesResponse;
    }
  } catch(error) {
    throw error;
  }
};

// ------- Put request to commit rule -------------

export const putRequestToCommitRule = async (
  { clientLayoutId, userSecurityTokenKey, username, roleId },
  { selectedRuleRecord, selectedRuleTypeId },
) => {
  try {
    const { id } = selectedRuleRecord;
    const ruleType = selectedRuleTypeId || window.location.href.substring(
      window.location.href.lastIndexOf('=') + 1,
    );
    const ruleToCommit = JSON.stringify({
      ruleIdArray: [id],
    });
    const baseUrl = `${API_BASE}/rules/${clientLayoutId}/${ruleType}:commit`;
    const api = new Api(userSecurityTokenKey, username, null, roleId);
    const commitRulePutResponse = await api.put(baseUrl, ruleToCommit);

    if (commitRulePutResponse.error) {
      throw commitRulePutResponse.error;
    } else {
      const response = {
        ...commitRulePutResponse,
        id
      };
      return response;
    }
  } catch (error) {
    throw error;
  }
};

export const getRevertRuleVersionsList = async (
  { userSecurityTokenKey, username, clientLayoutId, userRoleId, dataSourceId },
  { selectedRuleTypeId, ruleId },
) => {
  try {
    const role = userRoleId;
    const dataSource = dataSourceId; // dataSourceId is set as dataSource only for revert rule calls @MCDS-19353
    const baseUrl = `${API_BASE}/rules/${clientLayoutId}/${selectedRuleTypeId}/${ruleId}/versions`;
    const api = new Api(userSecurityTokenKey, username, dataSource, role);
    const revertRuleVersionsList = await api.get(baseUrl);

    if (revertRuleVersionsList.error) {
      throw revertRuleVersionsList.error;
    } else {
      return revertRuleVersionsList;
    }
  } catch (error) {
    throw error;
  }
};

export const putRevertRuleVersion = async (
  { clientLayoutId, userSecurityTokenKey, username, userRoleId, dataSourceId },
  { selectedRuleTypeId, ruleId, versionFrom },
) => {
  try {
    const role = userRoleId;
    const dataSource = dataSourceId; // dataSourceId is set as dataSource only for revert rule calls @MCDS-19353
    const baseUrl = `${API_BASE}/rules/${clientLayoutId}/${selectedRuleTypeId}/${ruleId}:revert`;
    const api = new Api(userSecurityTokenKey, username, dataSource, role);
    const bodyData = JSON.stringify({
      versionFrom
    });
    const response = await api.put(baseUrl, bodyData);
    if (response.error) {
      throw response.error;
    } else {
      return response;
    }
  } catch (error) {
    throw error;
  }
};

export default {
  putRulesForRuleTypes,
  postRulesForRuleTypes,
  putRequestToCommitRule,
  getRevertRuleVersionsList,
  putRevertRuleVersion
};
