import {
  GET_LIVEASSOC_RULETYPES_REQUEST_TRIGGER,
  GET_LIVEASSOC_RULETYPES_REQUEST_SUCCESS,
  GET_LIVEASSOC_RULETYPES_REQUEST_ERROR,
  RESET_STATE
} from './constants';

// This is the action that the UI will dispatch to trigger our request loop
export const triggerGetLiveAssocRuleTypesRequest = (id = null) => ({
  type: GET_LIVEASSOC_RULETYPES_REQUEST_TRIGGER,
  payload: id,
});

export const getLiveAssocRuleTypesListReceived = (payload = [], id = null) => ({
  type: GET_LIVEASSOC_RULETYPES_REQUEST_SUCCESS,
  payload,
  id,
  error: null,
});

// This action is used to signal a request error
export const getLiveAssocRuleTypesListRequestError = (error, id = null) => ({
  type: GET_LIVEASSOC_RULETYPES_REQUEST_ERROR,
  error,
  id,
});

export const resetLiveAssocState = (payload = {}) => ({
  type: RESET_STATE,
  payload,
});
