import React from 'react';
import { Page } from '../../components';
import RouteWrapper from '../../components/RouteWrapper';
import FileReceiptManagerDetail from './FileReceiptManagerDetail';
import FileReceiptManagerMain from './FileReceiptManagerMain';

const FileReceiptManager = () => (
  <RouteWrapper>
  <Page
    ListPaneContent={FileReceiptManagerMain}
    DetailPaneContent={FileReceiptManagerDetail}
  />
  </RouteWrapper>
);

export default FileReceiptManager;
