import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import get from 'lodash/get';
import { Button, LoadingIndicator, SelectDropdown, Input } from 'ui-core-ssgr';
import {
  Title,
  ContainerBox,
  DropDownOne,
  DropDownTwo,
  DropDownThree,
  DropSmallMargin,
  DropLargeMargin,
  LoadingWrapper,
  DocumentMessage,
} from './styles';
import { detailKeys } from './constants';
import {
  ButtonGroupBottom,
  ModalPaddingWrapper,
  ModalButton,
  ReadOnlyTextTitle,
  ReadOnlyTextArea
} from '../../../shared/styles/styledComponents';

class ProductionArrangementModal extends React.Component {
  state = {
    copyNumberError: false,
    customCoverSheetError: false,
    eobFormError: false,
    isButtonDisabled: true,
    ruleDetail: {
      claimType: 'default',
      recipientType: 'default',
      documentType: '01',
      copyNumber: '01',
      deliveryMethod: 'default',
      deliveryAddress: 'default',
      checkForm: 'default',
      eobForm: 'default',
      customCoverSheetForm: 'default',
      customCoverSheetLevel: 'default',
    },
    ruleDetailCodes: {
      claimType: 'default',
      recipientType: 'default',
      documentType: '01',
      copyNumber: '01',
      deliveryMethod: 'default',
      deliveryAddress: 'default',
      checkForm: 'default',
      eobForm: 'default',
      customCoverSheetForm: 'default',
      customCoverSheetLevel: 'default',
    },
    documentTypeChanged: false,
    copyNumberValue: '',
    firstItemIndex: null,
  };

  componentDidMount() {
    const { isPostingRuleDetail, detailElements } = this.props;
    const docTypeFirstIndex = get(detailElements, 'documentTypesList[0].code', '01');

    if (isPostingRuleDetail) {
      this.setState(prevState => ({
        ...prevState,
        isButtonDisabled: false,
        eobFormError: true,
        firstItemIndex: docTypeFirstIndex,
        ruleDetail: {
          ...prevState.ruleDetail,
          documentType: docTypeFirstIndex,
        },
        ruleDetailCodes: {
          ...prevState.ruleDetail,
          documentType: docTypeFirstIndex,
        },
      }));
    } else {
      this.getInitialDropdownValues();
    }
  }

  componentDidUpdate(prevProps) {
    const { detailElements, isPostingRuleDetail } = this.props;
    if (prevProps.detailElements !== detailElements && !isPostingRuleDetail) {
      this.getInitialDropdownValues();
    }
  }

  getInitialDropdownValues = () => {
    const { isPostingRuleDetail, selectedRuleDetail, actionType } = this.props;
    const { ruleDetail } = this.state;
    if (!isPostingRuleDetail) {
      Object.keys(ruleDetail).forEach(key => {
        if (key === 'eobForm' || key === 'copyNumber') {
          const value = this.populateDataDropDown(
            'eobFormList',
            'eobForm',
          ).filter(d => d.label.includes(selectedRuleDetail[key]));
          const { copyNumber } = selectedRuleDetail;
          let cValue;
          if (actionType === 'Read only') {
            cValue = value.length ? value[0].label : '-';
          } else {
            cValue = value.length ? value[0].value : '[None]';
          }
          this.setState(prevState => ({
            ruleDetail: {
              ...prevState.ruleDetail,
              copyNumber,
              eobForm: cValue || 'default',
            },
            ruleDetailCodes: {
              ...prevState.ruleDetailCodes,
              copyNumber,
              eobForm: cValue,
            },
            eobFormError: cValue === '[None]',
          }));
        } else {
          detailKeys.forEach(k => {
            let value = this.populateDataDropDown(
              k,
              selectedRuleDetail[key],
            ).filter(d => d.label.includes(selectedRuleDetail[key]));
            if (value && value.length && key === 'claimType') {
              value = value.filter(d => d.label === selectedRuleDetail[key]);
            }
            if (key === 'documentType') {
              value = this.populateDataDropDown(
                'documentTypesList',
                'documentType',
              ).filter(d => d.label.includes(selectedRuleDetail[key]) && d);
              this.setState({ firstItemIndex: null });
            }
            if (key === 'recipientType') {
              value = this.populateDataDropDown(
                'recipientTypesList',
                'recipientType',
              ).filter(d => d.label.includes(selectedRuleDetail[key]) && d);
            }
            if (key === 'customCoverSheetLevel') {
              value = this.populateDataDropDown(
                'customCoverSheetLevelList',
                'customCoverSheetLevel',
              ).filter(d => d.label.includes(selectedRuleDetail[key]) && d);
            }
            if (actionType === 'Read only') {
              value = value.length && value[0].label;
            } else {
              value = value.length && value[0].value;
            }
            if (value) {
              this.setState(prevState => ({
                ruleDetail: {
                  ...prevState.ruleDetail,
                  [key]: value || 'default',
                },
                ruleDetailCodes: {
                  ...prevState.ruleDetailCodes,
                  [key]: value,
                },
              }));
            }
          });
        }
      });
    }
  };

  getIsButtonDisabledState = () => {
    const {
      copyNumberError,
      customCoverSheetError,
      eobFormError,
      ruleDetail,
      ruleDetailCodes,
    } = this.state;
    const { isCloningRuleDetail } = this.props;
    const {
      claimType,
      recipientType,
      documentType,
      copyNumber,
    } = ruleDetailCodes;
    const isButtonDisabled =
      copyNumberError ||
      customCoverSheetError ||
      eobFormError ||
      _.isEqual(ruleDetailCodes, ruleDetail) ||
      (isCloningRuleDetail &&
        _.isEqual(
          { claimType, recipientType, documentType, copyNumber },
          (({ claimType, recipientType, documentType, copyNumber }) => ({
            claimType,
            recipientType,
            documentType,
            copyNumber,
          }))(ruleDetail),
        ));
    this.setState({ isButtonDisabled });
  };

  getRuleVersion = record => {
    try {
      const { status, version } = record;
      const testVersion = version[status.indexOf('T')];
      if (testVersion) return testVersion;
      return version[status.indexOf('L')];
    } catch (e) {
      console.log(e);
    }
    return null;
  };

  onCancel = () => {
    const {
      setIsShowingProductionArrangementModal,
      setIsCloningRuleDetail,
      setIsPostingRuleDetail,
      setIsReadOnlyRuleDetail
    } = this.props;
    setIsCloningRuleDetail(false);
    setIsPostingRuleDetail(false);
    setIsShowingProductionArrangementModal(false);
    setIsReadOnlyRuleDetail(false);
    this.setState({...this.state});
  };

  onInputChange = (e, value, input) => {
    let inputValue = e.target.value;
    if (inputValue === '[None]') inputValue = '';
    if (input === 'customCoverSheetLevel' && inputValue !== 'default') {
      this.setState({
        documentTypeChanged: true,
      });
    }
    if (input === 'documentType') this.setState({ documentTypeChanged: true });

    if (input === 'copyNumber' && inputValue.length === 1) {
      inputValue = `0${inputValue}`;
    }
    this.setState(
      prevState => ({
        ...prevState,
        ruleDetail: {
          ...prevState.ruleDetail,
          [input]: inputValue,
        },
      }),
      () => this.validateFormInputs(inputValue, input),
    );
  };

  onSave = () => {
    const {
      isCloningRuleDetail,
      isPostingRuleDetail,
      postRuleDetails,
      putRuleDetails,
      selectedRecord,
      selectedRuleDetail,
      selectedRuleRecord,
      selectedRuleTypeId,
    } = this.props;
    let { ruleDetail } = this.state;
    const { documentTypeChanged, firstItemIndex } = this.state;
    const record = selectedRecord || selectedRuleRecord;
    const ruleDetailId = selectedRuleDetail.id;
    const ruleId = record.id;
    const ruleVersion = this.getRuleVersion(record);
    const ruleType = selectedRuleTypeId;
    ruleDetail = isCloningRuleDetail
      ? { ...ruleDetail, ...{ ruleDetailIdFrom: ruleDetailId } }
      : ruleDetail;
    if (ruleType === 'PAR') {
      if (isPostingRuleDetail || isCloningRuleDetail) {
        // check if document type selection in case that first element on the list is not 01 code value will assign the code of the first element by default
        ruleDetail =
          documentTypeChanged === false && firstItemIndex
            ? { ...ruleDetail, ...{ documentType: firstItemIndex } }
            : ruleDetail;
        postRuleDetails({ ruleDetail, ruleType, ruleId, ruleVersion });
      } else {
        putRuleDetails({
          ruleDetail,
          ruleType,
          ruleId,
          ruleVersion,
          ruleDetailId,
        });
      }
    }
  };

  populateDataDropDown = (listName, name) => {
    const {
      detailElements,
      selectedRuleDetail,
      selectedRuleTypeId,
    } = this.props;
    if (!_.isEmpty(detailElements) && selectedRuleTypeId === 'PAR') {
      const matchingRule = detailElements[listName].find(
        x => x.value === selectedRuleDetail[name],
      );
      let arrData = detailElements[listName].sort(function (a, b) {
        if(a.code == b.code) return 0;
        if (a.code == 'default') return -1;
        if (b.code == 'default') return 1;

        if (a.code < b.code)
            return -1;
        if (a.code > b.code)
            return 1;
        return 0;
      });
      const arr =
        selectedRuleDetail[name] && matchingRule
          ? [...new Set([matchingRule, ...arrData])]
          : arrData;
      return _.uniqBy(arr, 'value')
        .map(r => ({
          label: r.value,
          value: r.code,
        }))
        .filter(d => d.value !== '');
    }
    return [];
  };

  validateFormInputs = (value, input) => {
    let { copyNumberError, eobFormError, customCoverSheetError } = this.state;
    const { ruleDetail } = this.state;
    const regExp = /^(0?[1-9]|[1-8][0-9]|9[0-8])$/;
    eobFormError = input === 'eobForm' ? value === 'default' : eobFormError;
    copyNumberError =
      input === 'copyNumber' ? !regExp.test(value) : copyNumberError;
    const form = ruleDetail.customCoverSheetForm;
    const level = ruleDetail.customCoverSheetLevel;
    customCoverSheetError =
      (form === 'default' && level !== 'default') ||
      (form !== 'default' && level === 'default');

    this.setState(
      {
        eobFormError,
        copyNumberError,
        customCoverSheetError,
      },
      () => this.getIsButtonDisabledState(),
    );
  };

  handleEnterPressed = event => {
    const { handleChangeFormat } = this;
    if (event.key === 'Enter') {
      handleChangeFormat(event);
    }
  };

  handleChangeFormat = input => {
    const inputValue = input.target.value;
    const formatValue = inputValue.replace(/^(\d)$/, '0$1');
    this.setState({
      copyNumberValue: formatValue,
    });
  };

  render() {
    const {
      actionType,
      loading,
      selectedRecord,
      selectedRuleRecord
    } = this.props;
    const {
      copyNumberError,
      eobFormError,
      isButtonDisabled,
      ruleDetail,
      documentTypeChanged,
      copyNumberValue,
      firstItemIndex,
    } = this.state;
    let { customCoverSheetError, infoHeaderStyle } = this.state;
    const {
      claimType,
      checkForm,
      eobForm,
      copyNumber,
      customCoverSheetForm,
      customCoverSheetLevel,
      deliveryAddress,
      deliveryMethod,
      documentType,
      recipientType,
    } = ruleDetail;
    const recordId = get(selectedRuleRecord, 'id', '') || get(selectedRecord, 'id', '');
    const customCoverSheetLevelInitialValue = customCoverSheetLevel.length
      ? customCoverSheetLevel
      : '1';
    let customCoverSheetLevelMessage = '';
    if (customCoverSheetLevel === 'DOC' && documentTypeChanged) {
      customCoverSheetLevelMessage =
        'Each document will receive the cover sheet';
    } else if (customCoverSheetLevel === 'ENV' && documentTypeChanged) {
      customCoverSheetLevelMessage = '1 coversheet per envelope';
    }
    customCoverSheetError =
      (customCoverSheetForm === 'default' &&
        customCoverSheetLevel !== 'default') ||
      (customCoverSheetForm !== 'default' &&
        customCoverSheetLevel === 'default');
    if (customCoverSheetError) {
      infoHeaderStyle = { color: '#bf1616', fontWeight: '600' };
    } else {
      infoHeaderStyle = { color: '#6d767e', fontWeight: '100' };
    }

    // FOR READ ONLY CONDITIONAL RENDERING (Live Rule)
    let claimTypeReadOnly;
    let recipientTypeReadOnly;
    let documentTypeReadOnly;
    let copyNumberReadOnly;
    let deliveryMethodReadOnly;
    let deliveryAddressReadOnly;
    let checkFormReadOnly;
    let eobFormReadOnly;
    let customCoverSheetFormReadOnly;
    let customCoverSheetLevelReadOnly;
    if (actionType === 'Read only') {
      claimTypeReadOnly = (claimType !== 'default') ? claimType : 'Any';
      recipientTypeReadOnly = (recipientType !== 'default') ? recipientType : 'Any';
      documentTypeReadOnly = (documentType !== '01') ? documentType : '001_Electronic Check not used';
      copyNumberReadOnly = (copyNumber !== '') ? copyNumber : '-';
      deliveryMethodReadOnly = (deliveryMethod !== 'default') ? deliveryMethod : 'Originally Specified Delivery Type';
      deliveryAddressReadOnly = (deliveryAddress !== 'default') ? deliveryAddress : '-';
      checkFormReadOnly = (checkForm !== 'default') ? checkForm : '-';
      eobFormReadOnly = (eobForm !== 'default') ? eobForm : '-';
      customCoverSheetFormReadOnly = (customCoverSheetForm !== 'default') ? customCoverSheetForm : '-';
      customCoverSheetLevelReadOnly = (customCoverSheetLevel !== 'default') ? customCoverSheetLevel : '-';
    }

    return (
      <div>
        {actionType !== 'Read only' ? (
          <Title>
            <span className="infoHeader" style={{ ...infoHeaderStyle }}>
              * Select values for Custom Coversheet Form and Custom Coversheet
              Level, or select None for both fields.
            </span>
          </Title>
        ) : null }
        <ContainerBox>
          <DropDownOne>
            <DropSmallMargin>
              {actionType !== 'Read only' ? (
                <SelectDropdown
                  domID="test-id"
                  label="claim type"
                  options={this.populateDataDropDown(
                    'claimTypesList',
                    'claimType',
                  )}
                  initialValue={claimType.length ? claimType : '1'}
                  onChange={(e, value) =>
                    this.onInputChange(e, value, 'claimType')
                  }
                  startWithBlankValue={false}
                />
              ) : <ReadOnlyTextTitle>Claim Type<ReadOnlyTextArea>{claimTypeReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
            </DropSmallMargin>
            <DropSmallMargin>
              {actionType !== 'Read only' ? (
                <Input
                  domID="test_id"
                  className="copy"
                  label="copy number "
                  placeholder={copyNumber.length ? copyNumber : '01'}
                  hasError={copyNumberError}
                  errorMessage="Must be a number between 1 and 98."
                  maxLength={50}
                  onChange={(e, value) =>
                    this.onInputChange(e, value, 'copyNumber')
                  }
                  disabled={false}
                  readOnly={false}
                  onEnterPress={this.handleEnterPressed}
                  onBlur={this.handleChangeFormat}
                  initialValue={copyNumberValue}
                />
              ) : <ReadOnlyTextTitle>Copy Number<ReadOnlyTextArea>{copyNumberReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
            </DropSmallMargin>
            <DropSmallMargin>
              {actionType !== 'Read only' ? (
                <SelectDropdown
                  domID="test-id"
                  label="check form"
                  options={this.populateDataDropDown(
                    'checkFormList',
                    'checkForm',
                  )}
                  initialValue={checkForm.length ? checkForm : '1'}
                  onChange={(e, value) =>
                    this.onInputChange(e, value, 'checkForm')
                  }
                  startWithBlankValue={false}
                />
              ) : <ReadOnlyTextTitle>Check Form<ReadOnlyTextArea>{checkFormReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
            </DropSmallMargin>
            <DropLargeMargin>
              {actionType !== 'Read only' ? (
                <SelectDropdown
                  domID="test-id"
                  label="custom coversheet form"
                  options={this.populateDataDropDown(
                    'customCoverSheetFormList',
                    'customCoverSheetForm',
                  )}
                  initialValue={
                    customCoverSheetForm.length ? customCoverSheetForm : '1'
                  }
                  onChange={(e, value) =>
                    this.onInputChange(e, value, 'customCoverSheetForm')
                  }
                  hasError={customCoverSheetError}
                  startWithBlankValue={false}
                />
              ) : <ReadOnlyTextTitle>Custom Coversheet Form<ReadOnlyTextArea>{customCoverSheetFormReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
            </DropLargeMargin>
          </DropDownOne>
          <DropDownTwo>
            <DropSmallMargin>
              {actionType !== 'Read only' ? (
                <SelectDropdown
                  domID="test-id"
                  label="recipient"
                  options={this.populateDataDropDown(
                    'recipientTypesList',
                    'recipientType',
                  )}
                  initialValue={recipientType.length ? recipientType : '1'}
                  onChange={(e, value) =>
                    this.onInputChange(e, value, 'recipientType')
                  }
                  startWithBlankValue={false}
                  s
                />
              ) : <ReadOnlyTextTitle>Recipient<ReadOnlyTextArea>{recipientTypeReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
            </DropSmallMargin>
            <DropSmallMargin>
              {actionType !== 'Read only' ? (
                <SelectDropdown
                  domID="test-id"
                  label="delivery address"
                  options={this.populateDataDropDown(
                    'deliveryAddressList',
                    'deliveryAddress',
                  )}
                  initialValue={deliveryAddress.length ? deliveryAddress : '1'}
                  onChange={(e, value) =>
                    this.onInputChange(e, value, 'deliveryAddress')
                  }
                  startWithBlankValue={false}
                />
              ) : <ReadOnlyTextTitle>Delivery Address<ReadOnlyTextArea>{deliveryAddressReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
            </DropSmallMargin>
            <DropSmallMargin>
              {actionType !== 'Read only' ? (
                <SelectDropdown
                  domID="test-id"
                  label="eob form"
                  options={this.populateDataDropDown('eobFormList', 'eobForm')}
                  initialValue={
                    ruleDetail.eobForm !== '' ? ruleDetail.eobForm : '1'
                  }
                  hasError={eobFormError}
                  errorMessage="EOB Form is required."
                  onChange={(e, value) => this.onInputChange(e, value, 'eobForm')}
                  startWithBlankValue={false}
                />
              ) : <ReadOnlyTextTitle>Eob Form<ReadOnlyTextArea>{eobFormReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
            </DropSmallMargin>
            <DropSmallMargin>
              {actionType !== 'Read only' ? (
                <SelectDropdown
                  domID="test-id"
                  label="custom coversheet level"
                  options={this.populateDataDropDown(
                    'customCoverSheetLevelList',
                    'customCoverSheetLevel',
                  )}
                  initialValue={customCoverSheetLevelInitialValue}
                  onChange={(e, value) =>
                    this.onInputChange(e, value, 'customCoverSheetLevel')
                  }
                  hasError={customCoverSheetError}
                  startWithBlankValue={false}
                />
              ) : <ReadOnlyTextTitle>Custom Coversheet Level<ReadOnlyTextArea>{customCoverSheetLevelReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
              <DocumentMessage>
                <span>{customCoverSheetLevelMessage}</span>
              </DocumentMessage>
            </DropSmallMargin>
          </DropDownTwo>
          <DropDownThree>
            <DropSmallMargin>
              {actionType !== 'Read only' ? (
                <SelectDropdown
                  domID="test-id"
                  label="document type"
                  options={this.populateDataDropDown(
                    'documentTypesList',
                    'documentType',
                  )}
                  initialValue={firstItemIndex || documentType}
                  onChange={(e, value) =>
                    this.onInputChange(e, value, 'documentType')
                  }
                  startWithBlankValue={false}
                />
              ) : <ReadOnlyTextTitle>Document Type<ReadOnlyTextArea>{documentTypeReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
            </DropSmallMargin>
            <DropSmallMargin>
              {actionType !== 'Read only' ? (
                <SelectDropdown
                  domID="test-id"
                  label="delivery method"
                  options={this.populateDataDropDown(
                    'deliveryMethodList',
                    'deliveryMethod',
                  )}
                  initialValue={deliveryMethod.length ? deliveryMethod : '1'}
                  onChange={(e, value) =>
                    this.onInputChange(e, value, 'deliveryMethod')
                  }
                  startWithBlankValue={false}
                />
              ) : <ReadOnlyTextTitle>Delivery Method<ReadOnlyTextArea>{deliveryMethodReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
            </DropSmallMargin>
          </DropDownThree>
        </ContainerBox>
        <ModalPaddingWrapper>
        </ModalPaddingWrapper>
        <ButtonGroupBottom>
          <ModalButton>
            {loading.has(recordId) && (
              <LoadingWrapper>
                <LoadingIndicator
                  domID="basic-details-form-loading-indicator"
                  length="30px"
                />
              </LoadingWrapper>
            )}
            {actionType !== 'Read only' ? (
              <Button
                domID="automation-id"
                name="Save"
                buttonType="emphasized"
                size="medium"
                disabled={
                  eobFormError ||
                  copyNumberError ||
                  isButtonDisabled ||
                  loading.has(recordId)
                }
                onClick={() => this.onSave()}
                isDropdown={false}
                type="button"
              />
            ) : null }
            <Button
              domID="automation-id"
              name={actionType !== 'Read only' ? 'Cancel' : 'Close'}
              buttonType="diminished"
              size="medium"
              disabled={selectedRuleRecord && loading.has(recordId)}
              onClick={() => this.onCancel()}
              isDropdown={false}
              type="button"
            />
          </ModalButton>
        </ButtonGroupBottom>
      </div>
    );
  }
}
ProductionArrangementModal.propTypes = {
  selectedRuleDetail: PropTypes.object,
  detailElements: PropTypes.object,
  isPostingRuleDetail: PropTypes.bool,
  isCloningRuleDetail: PropTypes.bool,
  setIsShowingProductionArrangementModal: PropTypes.func,
  postRuleDetails: PropTypes.func,
  putRuleDetails: PropTypes.func,
  selectedRecord: PropTypes.object,
  selectedRuleRecord: PropTypes.object,
  selectedRuleTypeId: PropTypes.string,
  loading: PropTypes.object,
  setIsCloningRuleDetail: PropTypes.func,
  setIsPostingRuleDetail: PropTypes.func,
};
export default ProductionArrangementModal;
