import styled from 'styled-components';

export const ScrollableGridWrapper = styled.div`
  max-height: 26em;
  overflow: auto;
`;

export const RequiredDiv = styled.div`
  text-align: right;
  font-size: 14px;
`;