import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LiveForm from './LiveForm';
import LiveGridWrapper from './LiveGridWrapper';

class LiveRuleTab extends Component {
  // want live (Live Rule Tab)
  getRuleVersion = record => {
    try {
      const { status, version } = record;
      const testVersion = version[status.indexOf('T')];
      const liveVersion = version[status.indexOf('L')];
      if (liveVersion) {
        return liveVersion;
      }
      if (testVersion) {
        return testVersion;
      }
    } catch (e) {}
    // if no test/live version or any error return null
    return null;
  };

  componentDidMount() {
    const {
      triggerRuleDetailsRequest,
      selectedRuleTypeId,
      selectedRuleRecord,
      deleteAllNotifications,
      captureCurrentPage,
      rulesForRuleTypes
    } = this.props;
    deleteAllNotifications();
    if (
      selectedRuleRecord.status.length >= 1 &&
      selectedRuleRecord.status.includes('L')
    ) {
      const ruleVersion = this.getRuleVersion(selectedRuleRecord);
      triggerRuleDetailsRequest({
        ruleType: selectedRuleTypeId,
        ruleId: selectedRuleRecord.id,
        ruleVersion,
      });
    }
    const pageTrackerPrefix = 'GFLA-';
    // following action is called to capture new relic data
    captureCurrentPage({pageId: `${pageTrackerPrefix}${selectedRuleTypeId}`});

    rulesForRuleTypes.ruleVersionRequest = null; // made this null so that it wont affect versions after cloning any rule, as it should be set in case of revert rule only
    rulesForRuleTypes.ruleVersion = null; // // made this null so that it wont affect versions after cloning any rule, as it should be set in case of revert rule only
  }
  
  render() {
    const {
      isExpanded,
      notifications,
      deleteNotification,
      toggleDetailsView,
      selectedRuleRecord,
      currentTab,
      overwriteTestRuleRequestTrigger,
      setIsShowingOverwriteModal,
      isShowingOverwriteModal,
      loading,
      gridConfig,
      selectedRuleTypeId,
      records,
      setSelectedRuleDetail,
      isReadOnlyRuleDetail,
      setIsReadOnlyRuleDetail,
      setIsShowingCustomerServiceMessageModal,
      setIsShowingCheckTextRuleModal,
      setIsShowingErisaMessageModal,
      setIsShowingSignatureRuleModal,
      setIsShowingImageRuleModal,
      setIsShowingReturnAddressModal,
      setIsShowingProductionArrangementModal,
      setIsShowingTaxIdArrangementModal,
      setShowingHighDollarReviewModal,
      setIsShowingGeneralMessageModal,
      setIsShowingMemberIdModal,
      setIsShowingProviderInsuredOptionMessageModal,
      isShowingProviderInsuredOptionMessageModal,
      setIsShowingAutoHoldModal,
      setIsShowingDuplexRuleModal,
      setIsShowingPDFTaggingModal
    } = this.props;
    return (
      <>
        <LiveForm
          deleteNotification={deleteNotification}
          isExpanded={isExpanded}
          notifications={notifications}
          selectedRuleRecord={selectedRuleRecord}
          toggleDetailsView={toggleDetailsView}
          loading={loading}
          overwriteTestRuleRequestTrigger={overwriteTestRuleRequestTrigger}
          setIsShowingOverwriteModal={setIsShowingOverwriteModal}
          isShowingOverwriteModal={isShowingOverwriteModal}
        />
        <LiveGridWrapper
          key={selectedRuleRecord.id}
          currentTab={currentTab}
          toggleDetailsView={toggleDetailsView}
          selectedRuleRecord={selectedRuleRecord}
          loading={loading}
          gridConfig={gridConfig}
          records={records}
          selectedRuleTypeId={selectedRuleTypeId}
          overwriteTestRuleRequestTrigger={overwriteTestRuleRequestTrigger}
          setIsShowingOverwriteModal={setIsShowingOverwriteModal}
          isShowingOverwriteModal={isShowingOverwriteModal}
          setSelectedRuleDetail={setSelectedRuleDetail}
          isReadOnlyRuleDetail={isReadOnlyRuleDetail}
          setIsShowingCustomerServiceMessageModal={setIsShowingCustomerServiceMessageModal}
          setIsShowingCheckTextRuleModal={setIsShowingCheckTextRuleModal}
          setIsShowingErisaMessageModal={setIsShowingErisaMessageModal}
          setIsReadOnlyRuleDetail={setIsReadOnlyRuleDetail}
          setIsShowingSignatureRuleModal={setIsShowingSignatureRuleModal}
          setIsShowingImageRuleModal={setIsShowingImageRuleModal}
          setIsShowingReturnAddressModal={setIsShowingReturnAddressModal}
          setIsShowingProductionArrangementModal={setIsShowingProductionArrangementModal}
          setIsShowingTaxIdArrangementModal={setIsShowingTaxIdArrangementModal}
          setShowingHighDollarReviewModal={setShowingHighDollarReviewModal}
          setIsShowingGeneralMessageModal={setIsShowingGeneralMessageModal}
          setIsShowingMemberIdModal={setIsShowingMemberIdModal}
          setIsShowingProviderInsuredOptionMessageModal={setIsShowingProviderInsuredOptionMessageModal}
          isShowingProviderInsuredOptionMessageModal={isShowingProviderInsuredOptionMessageModal}
          setIsShowingAutoHoldModal={setIsShowingAutoHoldModal}
          setIsShowingDuplexRuleModal={setIsShowingDuplexRuleModal}
          setIsShowingPDFTaggingModal={setIsShowingPDFTaggingModal}
        />
      </>
    );
  }
}

LiveRuleTab.propTypes = {
  deleteNotification: PropTypes.func,
  selectedRuleRecord: PropTypes.object,
  notifications: PropTypes.object,
  currentTab: PropTypes.number,
  toggleDetailsView: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool,
  selectedRecord: PropTypes.shape({
    id: PropTypes.string,
  }),
  captureCurrentPage: PropTypes.func,
  rulesForRuleTypes: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
};

export default LiveRuleTab;
