import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
} from 'ui-core';

import Transition from 'react-transition-group/Transition';
import PopoverComponent from './components/popover';
import Arrow from './components/arrow';
import ClickAwayWrapper from '../../commonResources/helpers/clickAwayWrapper';

const INITIAL_STATE = {
  isDisplayingPopover: false
};

const HOVER_DELAY = 300;

class PopoverBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  onClick=(event, state)=> {
    const {
      trigger = '',
      onClick = () => false,
    } = this.props;

    const {
      isDisplayingPopover
    } = this.state;

    if (trigger === 'click') {
      this.setState({
        isDisplayingPopover: !isDisplayingPopover
      });
    }
    onClick(event, state);
  }

  onClickOut=()=> {
    const { trigger = '' } = this.props;
    if (trigger === 'click') {
      this.setState({
        isDisplayingPopover: false
      });
    }
  }

  onHover=(event, state)=> {
    const {
      trigger = '',
      onHover = () => false,
    } = this.props;

    const {
      isDisplayingPopover
    } = this.state;

    if (trigger === 'hover') {
      this.setState({
        isDisplayingPopover: !isDisplayingPopover
      });
    }
    onHover(event, state);
  }

  generatePopoverComponent() {
    const {
      orientation = 'top',
      width = '',
      children = null,
    } = this.props;

    const duration = 200;

    const marginMap = {
      bottom: '0.75em 0 0 0',
      left: '0 0.5em 0 0',
      top: '0 0 0.5em 0',
      right: '0 0 0 0.5em'
    };

    const defaultStyle = {
      transition: `opacity ${duration}ms, margin ${duration}ms`,
      margin: '0 0 0 0',
      opacity: 0,
      zIndex: -1,
    };

    const transitionStyles = {
      // NOTE: this is nested so that we can use square bracket notation with a
      // variable to access it
      entered: { opacity: 1, margin: `${marginMap[orientation]}`, zIndex: 200 },
    };

    return state => (
      <PopoverComponent
        className={`popover ${orientation}`}
        style={{ ...defaultStyle, ...transitionStyles[state], width: `${width}` }}
        {...this.props}
      >
        {children || null}
        <Arrow className={`arrow ${orientation}`} />
      </PopoverComponent>
    );
  }

  render() {
    const {
      domID = null,
      trigger = '',
      buttonName = undefined,
      buttonClass = undefined,
      buttonType = "emphasized"
    } = this.props;

    const {
      isDisplayingPopover
    } = this.state;

    return (
      <ClickAwayWrapper onClickOut={() => { this.onClickOut(); }} id={domID}>
        <span style={{ position: 'relative' }}>
          <Transition
            in={isDisplayingPopover}
            timeout={trigger === 'hover' ? HOVER_DELAY : 0}
          >
            {this.generatePopoverComponent()}
          </Transition>
          <Button
            name={buttonName}
            buttonType={buttonType}
            className={buttonClass}
            onClick={(event, state) => { this.onClick(event, state); }}
            onMouseOver={(event, state) => { this.onHover(event, state); }}
            onFocus={(event, state) => { this.onHover(event, state); }}
            onMouseOut={(event, state) => { this.onHover(event, state); }}
            onBlur={(event, state) => { this.onHover(event, state); }}
          />
        </span>

      </ClickAwayWrapper>
    );
  }
}

PopoverBox.propTypes = {
  trigger: PropTypes.string.isRequired,
  children: PropTypes.node,
  buttonName: PropTypes.string,
  buttonType: PropTypes.string,
  buttonClass: PropTypes.string,
  width: PropTypes.string,
  orientation: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  onClick: PropTypes.func,
  onHover: PropTypes.func,
  domID: PropTypes.string,
};

export default PopoverBox;
