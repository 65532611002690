import React from 'react';
import { Page } from '../../components';
import ErrorManagerTranslationMain from './ErrorManagerTranslationMain';
import RouteWrapper from '../../components/RouteWrapper';

const ErrorManagerTranslation = () => (
  <RouteWrapper>
    <Page
      ListPaneContent={ErrorManagerTranslationMain}
    />
  </RouteWrapper>
);

export default ErrorManagerTranslation;
