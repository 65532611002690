// Import the action types we are going to respond to with this reducer
import {
  SET_RULE_TYPES_SEARCH_QUERY,
  SET_RULE_TYPES_SEARCH_RESULTS,
  RULE_TYPES_REQUEST_SUCCESS,
  RULE_TYPES_REQUEST_ERROR,
  SET_IS_VIEWING_RULE_DETAILS,
  RULE_DETAILS_REQUEST_SUCCESS,
  RULE_DETAILS_REQUEST_ERROR,
  SET_RULE_TYPE_MODAL_OPEN,
  SET_RULE_TYPE_REQUEST_SUCCESS,
  SET_RULE_TYPE_REQUEST_ERROR,
  SET_SELECTED_DETAILS_RECORD_ID,
  SET_IS_SHOWING_DELETE_RULE_DETAIL_MODAL,
  DELETE_RULE_DETAIL_SUCCESS,
  DELETE_RULE_DETAIL_ERROR,
  SET_IS_SHOWING_DETAIL_CLONE_RULE_MODAL,
  OVERWRITE_TEST_RULE_SUCCESS,
  OVERWRITE_TEST_RULE_ERROR,
  SET_SELECTED_RULE_TYPE_ID,
  SET_IS_SHOWING_OVERWRITE_MODAL,
  RESET_RULE_TYPES,
  IMAGES_TEST_COUNT_REQUEST_SUCCESS,
  IMAGES_TEST_COUNT_REQUEST_ERROR,
  RESET_STATE
} from './constants';

import {
  ALL_RULES_FOR_RULETYPES_ASSOCIATIONS_REQUEST_SUCCESS,
  ALL_RULES_FOR_RULETYPES_ASSOCIATIONS_REQUEST_ERROR,
} from '../rulesForRuleTypesList/constants';

import gridStub from '../../../stubs/rulesArray';

// Initial state expresses the data type of this key in the store (in this case
// store.groupsList will be an Array). This object is exported separately to
// make unit testing easier
export const INITIAL_STATE = {
  selectedDetailsRecordId: null,
  isAdvancedViewShown: false,
  selectedRuleRecord: null,
  selectedRuleTypeId: null,
  ruleDetailId: null,
  records: [],
  testImageCount: null,
  ruleTypeModalIsOpen: false,
  ruleTypesSearchQuery: '',
  ruleTypesSearchResults: [],
  setRuleTypesListRecords: [],
  ruleTypesAccumRecords: [],
  isShowingDeleteRuleDetailModal: false,
  showDetailCloneRuleModal: false,
  overwriteTestRuleSuccess: false,
  overwriteTestRuleError: false,
  isShowingOverwriteModal: false,
};

// This is the actual "reducer". It is a function that will run every time an
// action is dispatched by the store, but only return updated state values if
// it matches the action's type in the switch statement.
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_RULE_TYPES_SEARCH_QUERY:
      return {
        ...state,
        ruleTypesSearchQuery: action.payload,
      };
    case SET_RULE_TYPES_SEARCH_RESULTS:
      return {
        ...state,
        ruleTypesSearchResults: action.payload,
      };
    case RULE_TYPES_REQUEST_SUCCESS:
      // In our success case our action's payload will be the new array to cach
      // and use in the application
      return {
        ...state,
        selectedDetailsRecordId: action.id,
        ruleTypesAccumRecords: action.payload
      };
    case RESET_RULE_TYPES:
      return {
        ...state,
        ruleTypesSearchResults: [],
        ruleTypesAccumRecords: [],
      };
    case IMAGES_TEST_COUNT_REQUEST_SUCCESS:
      return {
        ...state,
        multipleTestImageCountRecords: action.payload.multipleTestImageCountRecords
      };
    case IMAGES_TEST_COUNT_REQUEST_ERROR:
      return { ...state };  
    // 2nd API call must be merged into the firs call's result
    case ALL_RULES_FOR_RULETYPES_ASSOCIATIONS_REQUEST_SUCCESS: {
      return !action.payload.groupFlow ? state : { ...state, records: [...action.payload] };
    }
    case ALL_RULES_FOR_RULETYPES_ASSOCIATIONS_REQUEST_ERROR:
      if (window.location.origin === 'http://localhost:3000') {
        return { ...state, records: [...gridStub] };
      }
      return { ...state };
    case RULE_TYPES_REQUEST_ERROR:
      // If an error occured you can set a fallback state here
      return { ...state, selectedDetailsRecordId: action.payload };
    case SET_IS_VIEWING_RULE_DETAILS:
      return {
        ...state,
        selectedRuleRecord: action.payload.selectedRuleRecord,
        selectedRuleTypeId: action.payload.selectedRuleTypeId,
      };
    case SET_SELECTED_RULE_TYPE_ID:
      return {
        ...state,
        selectedRuleTypeId: action.payload,
      };
    case RULE_DETAILS_REQUEST_SUCCESS:
      return {
        ...state,
        records: [...action.payload.ruleDetails],
        testImageCount: action.payload.testImageCount
      };
    case RULE_DETAILS_REQUEST_ERROR:
      return {
        ...state,
        records: [],
      };
    case SET_RULE_TYPE_REQUEST_SUCCESS:
      return {
        ...state,
        setRuleTypesListRecords: action.payload,
      };
    case SET_RULE_TYPE_REQUEST_ERROR:
      return {
        ...state,
        setRuleTypesListRecords: [],
      };
    case SET_RULE_TYPE_MODAL_OPEN:
      return { ...state, ruleTypeModalIsOpen: action.payload };
    case SET_SELECTED_DETAILS_RECORD_ID:
      return { ...state, selectedDetailsRecordId: action.payload.id };
    case SET_IS_SHOWING_DELETE_RULE_DETAIL_MODAL:
      return {
        ...state,
        isShowingDeleteRuleDetailModal: action.payload,
      };
    case DELETE_RULE_DETAIL_SUCCESS:
      return {
        ...state,
      };
    case DELETE_RULE_DETAIL_ERROR:
      return {
        ...state,
      };
    case OVERWRITE_TEST_RULE_SUCCESS:
      return {
        ...state,
        overwriteTestRuleSuccess: true,
        overwriteTestRuleError: false,
      };
    case OVERWRITE_TEST_RULE_ERROR:
      return {
        ...state,
        overwriteTestRuleError: true,
        overwriteTestRuleSuccess: false,
      };
    case SET_IS_SHOWING_DETAIL_CLONE_RULE_MODAL:
      return {
        ...state,
        showDetailCloneRuleModal: action.payload,
      };
    case SET_IS_SHOWING_OVERWRITE_MODAL:
      return {
        ...state,
        isShowingOverwriteModal: action.payload,
      };
    case RESET_STATE:
      return {
        selectedDetailsRecordId: null,
        isAdvancedViewShown: false,
        selectedRuleRecord: null,
        selectedRuleTypeId: null,
        ruleDetailId: null,
        records: [],
        testImageCount: null,
        ruleTypeModalIsOpen: false,
        ruleTypesSearchQuery: '',
        ruleTypesSearchResults: [],
        setRuleTypesListRecords: [],
        ruleTypesAccumRecords: [],
        isShowingDeleteRuleDetailModal: false,
        showDetailCloneRuleModal: false,
        overwriteTestRuleSuccess: false,
        overwriteTestRuleError: false,
        isShowingOverwriteModal: false,
      };
    default:
      // Most of the actions in our app will be ignored by this reducer, so the
      // default case is to return the existing state
      return state;
  }
};
