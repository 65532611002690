import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NotificationBanner } from 'ui-core-ssgr';
import { Portal } from 'react-portal';
import {
  FormWrapper,
  RuleNameInputWrapper,
  RuleDescriptionInputWrapper,
  RuleNameInput,
  RuleDescriptionInput,
  InputLabelWrapper,
  CommitRuleButton,
} from './styles';
import { gridActiveGroupConfig } from '../AdvancedGridWrapper/constants';
import CommitRuleModal from '../../../../../RulesTabView/components/CommitRuleModal';
import ActiveGroupsListingModal from '../../../../../RulesGridView/components/ActiveGroupsListingModal';
import {userPermissionConstants} from '../../../../../../shared/modules/constants';

const AdvancedForm = ({
  selectedRuleRecord,
  onInputChange,
  error,
  triggerCommitRuleForRuleType,
  records,
  selectedRecord,
  name,
  desc,
  loading,
  isCloningRule,
  selectedRuleTypeId,
  setIsShowingActiveGroupsListingModal,
  showActiveGroupsListingModal,
  activeGroupsModalLocation,
  selectedActiveRecordsList,
  selectedRecordName,
  testImageCount,
  userRole,
  userPermissions
}) => {
  const [isShowingCommitRuleModal, setIsShowingCommitRuleModal] = useState(false);

  const ruleCommit = () => setIsShowingCommitRuleModal(true);

  const handleCellClick = (event, record) => {
    event.preventDefault();
    event.stopPropagation();
    setIsShowingActiveGroupsListingModal({
      showActiveGroupsListingModal: true,
      activeGroupsModalLocation: 'GroupDetailsView',
      selectedActiveRecordsList: record,
      selectedRecordName: record.name,
    });
  };

  const { status } = selectedRuleRecord;
  const [firstIndex] = status;

  const disabledCommitButton =
  (status &&
    status.length !== 2 &&
    firstIndex === 'L') ||
  !records.length || isCloningRule;
  const {userRoleEdit, CAN_PERFORM_GROUPRULES_RULETYPE_KEY_COMMIT_RULES} = userPermissionConstants;
  const CAN_PERFORM_GROUPRULES_RULETYPE_COMMIT_RULES = CAN_PERFORM_GROUPRULES_RULETYPE_KEY_COMMIT_RULES.replace('RULETYPE_KEY', selectedRuleTypeId);
  
  return (
    <FormWrapper>
      {firstIndex === 'L' && status.length === 1 && (
        <Portal>
          <NotificationBanner
            icon="Info"
            domID="test-id"
            dataTestId="test-id"
            type="neutral"
            text="This is a mirror of the live rule. Feel free to edit this test rule."
            autoDismiss
            timer={1800000}
          />
        </Portal>
      )}
      {userRole !== userRoleEdit && userPermissions.includes(CAN_PERFORM_GROUPRULES_RULETYPE_COMMIT_RULES) ?
        <CommitRuleButton
          name="Commit Rule"
          buttonType="emphasized"
          size="medium"
          onClick={ruleCommit}
          disabled={disabledCommitButton}
        />
        : null}
      {isShowingCommitRuleModal && (
        <CommitRuleModal
          loading={loading}
          selectedRuleRecord={selectedRuleRecord}
          selectedRecord={selectedRecord}
          testImageCount={testImageCount}
          setIsShowingCommitRuleModal={setIsShowingCommitRuleModal}
          isShowingCommitRuleModal={isShowingCommitRuleModal}
          triggerCommitRuleForRuleType={triggerCommitRuleForRuleType}
          selectedRuleTypeId = {selectedRuleTypeId}
          handleCellClick={handleCellClick}
        />
      )}
      {showActiveGroupsListingModal && activeGroupsModalLocation === 'GroupDetailsView' && (
        <ActiveGroupsListingModal
          setIsShowingActiveGroupsListingModal={
            setIsShowingActiveGroupsListingModal
          }
          showActiveGroupsListingModal={showActiveGroupsListingModal}
          gridActiveGroupConfig={gridActiveGroupConfig}
          selectedActiveRecordsList={selectedActiveRecordsList}
          selectedRecordName={selectedRecordName}
        />
      )}
      <RuleNameInputWrapper>
        <InputLabelWrapper>RULE. *</InputLabelWrapper>
        <RuleNameInput
          className="rule-name-input"
          disabled={false}
          isHovered={false}
          errorMessage={error.has('name') ? error.get('name') : ''}
          hasError={error.has('name')}
          initialValue={name}
          onChange={e => onInputChange(e, 'name', selectedRuleRecord)}
        />
      </RuleNameInputWrapper>
      <RuleDescriptionInputWrapper>
        <InputLabelWrapper>RULE DESCRIPTION</InputLabelWrapper>
        <RuleDescriptionInput
          className="rule-description-input"
          disabled={false}
          isHovered={false}
          errorMessage={error.has('desc') ? error.get('desc') : ''}
          hasError={error.has('desc')}
          initialValue={desc}
          onChange={e => onInputChange(e, 'desc', selectedRuleRecord)}
        />
      </RuleDescriptionInputWrapper>
    </FormWrapper>
  );
};

AdvancedForm.propTypes = {
  selectedRuleRecord: PropTypes.shape({ id: PropTypes.string }),
  onInputChange: PropTypes.func,
  error: PropTypes.object,
  triggerCommitRuleForRuleType: PropTypes.func,
  records: PropTypes.array,
  selectedRecord: PropTypes.object,
  name: PropTypes.string,
  desc: PropTypes.string,
  loading: PropTypes.object,
  isCloningRule: PropTypes.bool,
  selectedRuleTypeId: PropTypes.string,
  setIsShowingActiveGroupsListingModal: PropTypes.func,
  showActiveGroupsListingModal: PropTypes.bool,
  activeGroupsModalLocation: PropTypes.string,
  selectedActiveRecordsList: PropTypes.object,
  selectedRecordName: PropTypes.string,
  testImageCount: PropTypes.number,
  userRole: PropTypes.string,
  userPermissions: PropTypes.string
};

export default AdvancedForm;
