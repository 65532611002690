import React from 'react'
import { Page } from '../../components'
import RouteWrapper from '../../components/RouteWrapper';
import ListPaneContent from './RoleManagerListPane'
import RoleManagerDetailPane from './RoleManagerDetailPane'


const RoleManager = () => (
<RouteWrapper>
  <Page 
    ListPaneContent={ListPaneContent}
    DetailPaneContent={RoleManagerDetailPane}
  />
</RouteWrapper>
)
export default RoleManager