import { select, call, put, takeLatest } from 'redux-saga/effects';
import getLiveAssocRuleTypesList from './network';
import {
  getLiveAssocRuleTypesListReceived,
  getLiveAssocRuleTypesListRequestError,
} from './actions';
import { setLoadingState } from '../../app/loadingState/actions';
import { addNotification } from '../../app/notificationState/actions';
import { GET_LIVEASSOC_RULETYPES_REQUEST_TRIGGER } from './constants';

function* getLiveAssocRuleTypesListGenerator(data) {
  yield put(
    setLoadingState({
      id: 'liveAssocRuleTypesGridLoading',
      isLoading: true,
    }),
  );

  const { groupId } = data.payload;

  try {
    const { clientLayoutId, userSecurityTokenKey, username, roleId } = yield select(
      ({ serverConfig }) => serverConfig,
    );

    const liveAssocRuleTypesArray = yield call(
      getLiveAssocRuleTypesList,
      clientLayoutId,
      userSecurityTokenKey,
      username,
      groupId,
      roleId
    );

    // If no error was thrown we can assume we got our list of rule types successfully,
    // but we can also do additional validation here
    if (Array.isArray(liveAssocRuleTypesArray)) {
      yield put(
        setLoadingState({
          id: 'liveAssocRuleTypesGridLoading',
          isLoading: false,
        }),
      );
      // If we got what we expect, dispatch our success action
      yield put(
        getLiveAssocRuleTypesListReceived(liveAssocRuleTypesArray, groupId),
      );
    } else {
      yield put(
        setLoadingState({
          id: 'liveAssocRuleTypesGridLoading',
          isLoading: false,
        }),
      );
      // We got a 200 response that was valid JSON, but the expected data type
      // was not returned from the server so we pass a custom error out with our
      // failure action
      yield put(
        getLiveAssocRuleTypesListRequestError(
          'Error with LiveAssocRuleTypesListRequest',
          groupId,
        ),
      );
    }
  } catch (thrownError) {
    yield put(
      setLoadingState({
        id: 'liveAssocRuleTypesGridLoading',
        isLoading: false,
      }),
    );
    yield put(
      addNotification({
        id: thrownError.errorId,
        type: 'negative',
        msg: thrownError.message,
      }),
    );
    yield put(getLiveAssocRuleTypesListRequestError(thrownError, groupId));
  }

  // After we have sent our new data off to the reducer we can still dispatch
  // additional actions to update app state, for example to resolve the loading
  // state set in the example above
  // yield put(AppActions.stopWaiting());
}

function* getLiveAssocRuleTypesListSaga() {
  yield takeLatest(
    GET_LIVEASSOC_RULETYPES_REQUEST_TRIGGER,
    getLiveAssocRuleTypesListGenerator,
  );
}

export default getLiveAssocRuleTypesListSaga;
