import { select, call, put, takeLatest } from 'redux-saga/effects';
import {
  getGroupsListByStatus,
  getRecordCountFromDB,
  getGroupsListByStatusAndKeywordCountFromDB,
} from './network';
import {
  allGroupsListReceived,
  allGroupsRequestError,
  recordCountFromDBRecieved,
  recordCountFromDBError,
  recordCountByStatusAndKeywordRecievedDB,
  recordCountByStatusAndKeywordErrorDB,
} from './actions';
import { addNotification } from '../../app/notificationState/actions';
import {
  ALL_GROUPS_REQUEST_TRIGGER,
  RECORD_COUNT_FROM_DB_TRIGGER,
  RECORD_COUNT_WITH_STATUS_KEYWORD_FROM_DB_TRIGGER,
} from './constants';
import { setLoadingState } from '../../app/loadingState/actions';

// This generator is the control flow manager that will step through the request
// lifecycle and dispatch actions to update the application at the end

function* recordCountGenerator(action) {
  try {
    const userConfig = yield select(({ serverConfig }) => serverConfig);

    const response = yield call(
      getRecordCountFromDB,
      userConfig,
      action.payload,
    );

    const count = response.groupsCount || 0;

    yield put(recordCountFromDBRecieved({ count }));
  } catch (thrownError) {
    yield put(
      addNotification({
        id: thrownError.errorId,
        type: 'negative',
        msg: thrownError.message,
      }),
    );
    yield put(recordCountFromDBError(thrownError));
  }
}

function* groupsListGenerator(action) {
  yield put(
    setLoadingState({
      id: 'groupsListGridLoading',
      isLoading: true,
    }),
  );
  // Before we make our network request we can dispatch actions to modify app
  // state, for example to show a spinner:
  // yield put(AppActions.startWaiting());

  try {
    // get values sent via server-side config to use with this call
    const userConfig = yield select(({ serverConfig }) => serverConfig);

    const { status, queryValue } = action.payload;

    // These will show the groups list of the status that was selected by the user.

    const response = yield call(
      getGroupsListByStatus,
      userConfig,
      action.payload,
    );
    const newGroupsList = response.groups;

    if (Array.isArray(newGroupsList)) {
      // If we got what we expect, dispatch our success action
      if (action.payload && action.payload.offset > 0) {
        yield put(
          setLoadingState({
            id: 'groupsListGridLoading',
            isLoading: false,
          }),
        );
        yield put(
          allGroupsListReceived({
            groupsList: newGroupsList,
            loadMore: response.loadMore,
            offset: action.payload.offset,
            queryValue,
            status,
            append: true,
          }),
        );
      } else {
        yield put(
          setLoadingState({
            id: 'groupsListGridLoading',
            isLoading: false,
          }),
        );
        yield put(
          allGroupsListReceived({
            groupsList: newGroupsList,
            loadMore: response.loadMore,
            offset: 0,
            status,
            queryValue,
          }),
        );
      }
    } else {
      yield put(
        setLoadingState({
          id: 'groupsListGridLoading',
          isLoading: false,
        }),
      );
      // We got a 200 response that was valid JSON, but the expected data type
      // was not returned from the server so we pass a custom error out with our
      // failure action
      yield put(
        allGroupsRequestError(
          new Error(
            '[ groupsListGenerator ] returned groups list was not an Array',
          ),
        ),
      );
    }
  } catch (thrownError) {
    yield put(
      setLoadingState({
        id: 'groupsListGridLoading',
        isLoading: false,
      }),
    );
    yield put(
      addNotification({
        id: thrownError.errorId,
        type: 'negative',
        msg: thrownError.message,
      }),
    );
    yield put(allGroupsRequestError(thrownError));
  }
}

// ----------- Count Update when Search Box is used --------------

function* groupsListQueryUpdatewithCountGenerator(action) {
  try {
    const userConfig = yield select(({ serverConfig }) => serverConfig);

    const response = yield call(
      getGroupsListByStatusAndKeywordCountFromDB,
      userConfig,
      action.payload,
    );
    const count = response.groupsCount || 0;
    yield put(recordCountByStatusAndKeywordRecievedDB({ count }));
  } catch (thrownError) {
    yield put(recordCountByStatusAndKeywordErrorDB(thrownError));
  }
}

// This saga gets attached to the redux store, and listens for action types
// similar to the reducers. When it matches an action type it will run the
// generator indicated and pass it the action as an argument.
function* groupsListSaga() {
  yield takeLatest(ALL_GROUPS_REQUEST_TRIGGER, groupsListGenerator);
  yield takeLatest(RECORD_COUNT_FROM_DB_TRIGGER, recordCountGenerator);
  yield takeLatest(
    RECORD_COUNT_WITH_STATUS_KEYWORD_FROM_DB_TRIGGER,
    groupsListQueryUpdatewithCountGenerator,
  );
}

export default groupsListSaga;
