export const DEMO_USER = {
  sub: 'test1.dffkj@org1.com',
  aud: 'https://sdfkjldfskjl.chhc.io',
  jti: 'RgGcjg6ZJonYXnR24wjoIa',
  iss: 'https://sso-test.changehealthcare.com',
  iat: 1534264941,
  exp: 1534266741,
  phone_numbers: {
    number: '',
    type: 'work',
  },
  roles: 'User',
  photo: 'https://media.forgecdn.net/avatars/124/768/636424778749237239.jpeg',
  company: {
    name: 'Florida Blue',
    id: 'be5bf4cb-ddd6-4532-8e4f-a3f2df847650',
  },
  given_name: 'Test1',
  uuid: '716a2271-12d9-4007-8054-6bcabb4c7052',
  family_name: 'User1',
  email: 'test1.dffkj@org1.com',
  apps: [
    {
      name: 'Boilerplate',
      id: '7c7-4ff9-b4a8-8e0a00354d8a',
      uri: 'https://sdfkjldfskjl.chhc.io',
    },
    {
      name: 'Other App',
      id: '7c7-4ff9-b4a8-8e0a0',
      uri: 'https://otherapp9394.chhc.io',
    },
    {
      name: 'Third App',
      id: '7c7-4ff9-b4a8-8e0a0',
      uri: 'https://thirdapp34984.chhc.io',
    },
  ],
};

export const SUPPORT_MENU_ITEMS = {
  shouldShow: true,
  showBottomAction: false,
  linkData: {
    staticContent: [
      {
        label: 'Change Context',
      },
      {
        label: 'FAQ',
      },
    ]
  }
};

export const AVATAR_MENU_ITEMS = [
  [
    {
      id: 2,
      label: 'Log Out',
      path: '/logout',
    },
  ],
];

export const ON_AVATAR_MENU_SELECT =
  'app/containers/PageWrapper/ON_AVATAR_MENU_SELECT';

export const USER_SET = 'app/containers/PageWrapper/USER_SET';

export const TOGGLE_AUTH_MODAL_OPEN =
  'app/containers/PageWrapper/TOGGLE_AUTH_MODAL_OPEN';

export const APP_ID = 'TEST';

export const SET_SUPPORT_ITEMS = 'SET_SUPPORT_ITEMS'