import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import AccordionPanel from '../AccordionPanel';
import { OuterPanel } from './styles';
import '../../rc-collapse.css';
import { NEW_RULE_ID_LIST } from './constants';

const AccordionPanels = props => {
  const {
    ruleTypesSearchResults,
    setSelectedRuleTypeId,
    setRuleTypesSearchQuery,
    isExpanded,
    ruleTypesAccumRecords,
    captureCurrentPage
  } = props;

  const sortByName = (a, b) => {
    const nameA = a.name;
    const nameB = b.name;
    let comparison = 0;

    if (nameA > nameB) {
      comparison = 1;
    } else if (nameA < nameB) {
      comparison = -1;
    }
    return comparison;
  };

  const ruleTypes = ruleTypesAccumRecords || [];
  const sortedRuleTypes = ruleTypes.sort(sortByName);

  const setRuleTypeId = ruleType => {
    const pageTrackerPrefix = 'GDTA-';
    // following action is called to capture new relic data
    if(ruleType !== undefined) captureCurrentPage({pageId: `${pageTrackerPrefix}${ruleType}`});
    setSelectedRuleTypeId(ruleType);
    setRuleTypesSearchQuery('');
  };

  const getRuleTypes = () => {
    const ruleIndex = ruleTypesSearchResults.length
      ? sortedRuleTypes.findIndex(r => r.id === ruleTypesSearchResults[0].id)
      : 0;
    const insert = (arr, index, newItem) => [
      ...arr.slice(0, index),
      newItem,
      ...arr.slice(index),
    ];
    return ruleTypesSearchResults.length
      ? insert(
        sortedRuleTypes.filter(r => r.id !== ruleTypesSearchResults[0].id),
        ruleIndex,
        ruleTypesSearchResults[0],
      )
      : sortedRuleTypes;
  };

  const rcCollapsePanelProps = getRuleTypes().reduce((acc, { id }) => {
    const newConfig = {
      [id]: NEW_RULE_ID_LIST.includes(id)
        ? {}
        : { disabled: true, showArrow: false },
    };
    return { ...acc, ...newConfig };
  }, {});

  return (
    <OuterPanel accordion onChange={setRuleTypeId}>
      {sortedRuleTypes.length && isExpanded &&
        map(getRuleTypes(), ruleType => (
          <AccordionPanel
            {...props}
            key={ruleType.id}
            ruleType={ruleType}
            sortedRuleTypes={sortedRuleTypes}
            rcCollapsePanelProps={rcCollapsePanelProps[ruleType.id]}
            isExpanded={isExpanded}
          />
        ))}
    </OuterPanel>
  );
};

AccordionPanels.propTypes = {
  ruleTypesSearchResults: PropTypes.array,
  isExpanded: PropTypes.bool,
  ruleTypesAccumRecords: PropTypes.array,
  setSelectedRuleTypeId: PropTypes.func,
  setRuleTypesSearchQuery: PropTypes.func,
  captureCurrentPage: PropTypes
};
export default AccordionPanels;