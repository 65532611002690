export const DIRECT_PRINT_JOB_TRACKER_GRID={
    columns: new Set([
      {
        dataName: 'JOBNUMBER',
        text: "JOB NUMBER",
        sortable: true,
        isSorted: 0,
        cellType: 'text',
      },
      {
        dataName: 'CLIENTID',
        text: "CLIENT ID",
        sortable: true,
        isSorted: 0,
        cellType: 'text',
      },
      {
        dataName: 'CLIENTNAME',
        text: "CLIENT NAME",
        sortable: true,
        isSorted: 0,
        cellType: 'text',
      },
      {
        dataName: 'IMPORTDATETIME',
        text: "IMPORT DATE / TIME",
        sortable: true,
        isSorted: 0,
        cellType: 'text',
      },
      {
        dataName: 'JOBSTATUS_DISPLAY',
        text: "JOB STATUS",
        sortable: true,
        isSorted: 0,
        cellType: 'text',
      },
      {
        dataName: 'JOBSTATUSDATETIME',
        text: "JOB STATUS DATE / TIME",
        sortable: true,
        isSorted: 0,
        cellType: 'text',
      },
      {
        dataName: 'FILECOUNT',
        text: "FILE COUNT",
        sortable: true,
        isSorted: 0,
        cellType: 'text',
      },
      {
        dataName: 'RESPONSESTATUS',
        text: "RESPONSE STATUS",
        sortable: true,
        isSorted: 0,
        cellType: 'text',
      },
      {
        dataName: 'RESPONSE',
        text: "RESPONSE",
        sortable: true,
        isSorted: 0,
        cellType: 'text',
      }
    ]),
    initialSelectedItemIds: new Set([]),
    bookmarkedItemIds: new Set([]),
    domID: 'directPrintJobTrackerGrid',
    dataTestId: 'directPrintJobTrackerGrid',
    isConfigurable: true,
    supportSelection: false
  };

  export const DIRECT_PRINT_FILE_TRACKER_GRID={
    columns: new Set([
      {
        dataName: 'FILEID',
        text: "FILE ID",
        sortable: true,
        isSorted: 0,
        cellType: 'text',
      },
      {
        dataName: 'FILENAME',
        text: "FILE NAME",
        sortable: true,
        isSorted: 0,
        cellType: 'text',
      },
      {
        dataName: 'FILESTATUSDATETIME',
        text: "IMPORT DATE / TIME",
        sortable: true,
        isSorted: 0,
        cellType: 'text',
      },
      {
        dataName: 'FILESTATUS_DISPLAY',
        text: "STATUS",
        sortable: true,
        isSorted: 0,
        cellType: 'text',
      },
    ]),
    initialSelectedItemIds: new Set([]),
    bookmarkedItemIds: new Set([]),
    domID: 'directPrintFileTrackerGrid',
    dataTestId: 'directPrintFileTrackerGrid',
    isConfigurable: true,
    supportSelection: false
  };