/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import { useEffect, useRef, useState, useCallback } from 'react'
import useNotificationBannerHook from '../useNotificationBannerHook';
import axios from '../axios';
import envConfig from '../config/env-config';

const { APP_ENV = 'development' } = window;
const API_ROOT_SERVER = envConfig[APP_ENV].API_ROOT_SERVER;
const { REACT_APP_API_ROOT_SERVER } = process.env

const useAxiosPostLocal = ({
    url,
    method = 'POST',
    config = {},
}) => {
    const configRef = useRef()
    configRef.current = config
    const [data, setData] = useState(null)
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    let activeUserId = null;

    if (localStorage['persist:root:reducer'] && JSON.parse(localStorage['persist:root:reducer']).session
    && JSON.parse(JSON.parse(localStorage['persist:root:reducer']).session).userId) {
      activeUserId=JSON.parse(JSON.parse(localStorage['persist:root:reducer']).session).userId
    }

    const getUrl = (url) => {
        return `${REACT_APP_API_ROOT_SERVER ? REACT_APP_API_ROOT_SERVER : API_ROOT_SERVER}${url}`        
    }
    const postData = async (payload) => {
        try {
            setIsLoading(true)
            let config = {
                url: getUrl(url),
                method,
                headers: {
                    username: activeUserId,
                    ...configRef.current
                },
                responseType: 'json',
            }
            if (method === "POST") {
                config = { ...config, data: JSON.stringify(payload), }
            }
            const { data: info, ...rest } = await axios({ ...config });
            setIsError(false)
            setData(info)

        } catch (err) {
            setIsError(true)
            setError(err)

        } finally {
            setIsLoading(false)
        }
    }
    return { data, error, isError, isLoading, postData, }
}

const useInsertErrorDetails = (props = {}) => {
    const [errorIdResp, seterrorIdResp] = useState('')
    const { data, error, postData, isError, isLoading, ...rest } = useAxiosPostLocal({
        ...props,
        url: 'insertErrorDetails',
        method: 'POST',
        payload: {}
    })
    const { notify } = useNotificationBannerHook()

    useEffect(() => {
        if (data) {
            const { errorId } = data;
            if (errorId) {
                seterrorIdResp(errorId)
            }
        }
        if (error) {
            notify(`There were errors processing your request: ${error?.fs}`, 'negative')
        }
    }, [data, error])
    return { errorIdResp, isError, error, insertErrorDetails: postData, isLoading, ...rest };
}

export default useInsertErrorDetails;