/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import Datetime from 'react-datetime';
import {
  Grid,
  Input,
  useLayout,
  ListPaneHeader,
  Button,
  ButtonGroup,
  Modal,
  ModalHeader,
  ModalBody
} from 'ui-core/dist'
import withAxiosCancellation from '../../components/withAxiosCancellation'
import { SelectDropdown } from '../../components'
import ChildListPane from './ChildListPane'
import axios from '../../axios'
import moment from 'moment';
import styled from 'styled-components/dist/styled-components';
import { Clock } from 'ui-core/dist/icons'
import useFileTrackerFiles from '../../api/useFileTrackerFiles'
import useFileTrackerStatus from '../../api/useFileTrackerStatus'
import useFileTrackerLabels from '../../api/useFileTrackerLabels'
import ListPaneHeaderWrapper from '../../components/ErrorDisplaySection/ListPaneHeaderWrapper';
import FileReceiptManagerDetailComponent from '../FileReceiptManager/FileReceiptManagerDetailComponent';
import { AnchorInput, GridPaginationWrapper, Loading } from '../../components'
import TimeoutNotification from '../../components/TimeoutNotification';
import LoadingComponent from '../../components/LoadingComponent';
import ErrorPage from '../ErrorPage/components/ErrorPage';
import useNotificationBannerHook from '../../useNotificationBannerHook';
import './modules/styles.scss'
import { ReturnAddressLogo } from '../RuleDetailModals/ReturnAddressModal/styles';

// const StyledGridWrapper = styled.div`
// &.GridWrapper #grid-wrapper-FileTrackerGrid {
//   & table#FileTrackerGrid tbody tr td.fileNameProp > div.cell-component-wrapper {
//     width: 175px !important;
//     overflow: hidden !important;
//   }
//   & table#FileTrackerGrid thead tr th:first-child {
//     width: 40px !important;
//   }
//   & table#FileTrackerGrid thead tr th[data-testid="header-fileKeyProp"] > div:first-child  {
//     width: 70px !important;
//     max-width: 250px !important;
//     overflow: hidden !important;
//   }
//   & table#FileTrackerGrid thead tr th[data-testid="header-fileNameProp"] > div:first-child {
//     width: 80px !important;
//     overflow: hidden !important;
//   }
//   & table#FileTrackerGrid thead tr th[data-testid="header-importDt"] > button:first-child {
//     width: 120px !important;
//     max-width: 250px !important;
//     overflow: hidden !important;
//   }
//   & table#FileTrackerGrid thead tr th[data-testid="header-mSuppressed"] > div:first-child {
//     width: 132px !important;
//     max-width: 250px !important;
//     overflow: hidden !important;
//   }
//   & table#FileTrackerGrid thead tr th[data-testid="header-aSuppressed"] > div:first-child {
//     width: 120px !important;
//     max-width: 250px !important;
//     overflow: hidden !important;
//   }
//   & table#FileTrackerGrid thead tr th[data-testid="header-readyPrnt"] > div:first-child {
//     width: 120px !important;
//     max-width: 250px !important;
//     overflow: hidden !important;
//   }
//   & table#FileTrackerGrid thead tr th[data-testid="header-mailed"] > div:first-child {
//     width: 150px !important;
//     max-width: 250px !important;
//     overflow: hidden !important;
//   }
//   & table#FileTrackerGrid thead tr th[data-testid="header-percentMailed"] > div:first-child {
//     width: 100px !important;
//     max-width: 250px !important;
//     overflow: hidden !important;
//   }
//   & table#FileTrackerGrid thead tr th[data-testid="header-readyPrntDt"] > div:first-child {
//     width: 150px !important;
//     max-width: 250px !important;
//     overflow: hidden !important;
//   }
//   & table#FileTrackerGrid thead tr th[data-testid="header-mailDt"] > div:first-child {
//     width: 170px !important;
//     max-width: 250px !important;
//     overflow: hidden !important;
//   }
//   & table#FileTrackerGrid thead tr th[data-testid="header-fileStatus"] > div:first-child {
//     width: 90px !important;
//     max-width: 250px !important;
//     overflow: hidden !important;
//   }
//   & table#FileTrackerGrid thead tr th[data-testid="header-enotes"] > div:first-child {
//     width: 70px !important;
//     max-width: 250px !important;
//     overflow: hidden !important;
//   }
//   & table#FileTrackerGrid thead tr th[data-testid="header-enoteDt"] > div:first-child {
//     width: 150px !important;
//     max-width: 250px !important;
//     overflow: hidden !important;
//   }
//   & table#FileTrackerGrid thead tr th[data-testid="header-web"] > div:first-child {
//     width: 80px !important;
//     max-width: 250px !important;
//     overflow: hidden !important;
//   }
//  }
// `

const StyledModalWrapper = styled.div`
  height: 80vh;
`
const StyledModalHeaderWrapper = styled.div`
  & div {
    padding:1px 9px !important;
    padding-bottom: 1px !important;
  }
`

const FileTrackerListPane = ({getCancelSourceWithName}) => {
  const { notify } = useNotificationBannerHook()
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [pageIndex, setPageIndex] = useState(1);
  const [claimNumber, setClaimNumber] = useState("")
  const [fileKey, setFileKey] = useState("")
  const [fileName, setFileName] = useState("")
  const [fileStatus, setFileStatus] = useState("")
  const [importStartDate, setImportStartDate] = useState(moment().subtract(14, "days").format("MM/DD/YYYY"))
  const [importEndDate, setImportEndDate] = useState(moment().format("MM/DD/YYYY"))
  const [isOpenFileReceiptModal, setIsOpenFileReceiptModal] = useState(false)
  const [isDocView, setIsDocView] = useState(false)
  const [idx, setIdx] = useState(0)
  const [sortBy, setSortBy] = useState('importDt')
  const [sortOrder, setSortOrder] = useState('DESC')
  const [selectedFileKey, setSelectedFileKey] = useState()
  const [datesError, setDatesError] = useState(false)
  const [errMsg, setErrMsg] = useState(false)
  const [selectedRecord, setSelectedRecord] = useState()
  const [isReportLoading, setIsReportLoading] = useState(false)
  const [payload, setPayload] = useState({
    maxRecords: rowsPerPage,
    rowPosition: pageIndex,
    claimNum: claimNumber,
    fileKey,
    fileName,
    fileStatus,
    importStartDate: importStartDate,
    importEndDate: importEndDate,
    cacheName,
  })
  const { expandCollapseListPaneButtonConfig } = useLayout()
  const { data, isLoading, isError, error, total, cacheName, refetch, lastUpdated } = useFileTrackerFiles({ payload,getCancelSource:getCancelSourceWithName('fileTrackerFiles') })
  const { data: statuses, isLoading: statusesLoading } = useFileTrackerStatus({getCancelSource:getCancelSourceWithName('fileTrackerStatus')})
  const { labels, isLoading: labelsLoading, isError: isErrorLabels, error: errorLabels } = useFileTrackerLabels({getCancelSource:getCancelSourceWithName('fileTrackerLables')})

  const handleShowButton = ({ pi }) => {
    setPageIndex(pi)
    setPayload({
      maxRecords: rowsPerPage,
      rowPosition: pi,
      claimNum: claimNumber,
      fileKey,
      fileName,
      fileStatus: fileStatus ? fileStatus.value : '',
      importStartDate: importStartDate,
      importEndDate: importEndDate,
      sortBy,
      sortOrder,
      cacheName
    })
  }
  const onJobNumberChange = (e, state) => setFileKey(state.value)
  const onFileNameChange = (e, state) => setFileName(state.value)
  const onFileStatusChange = (state) => setFileStatus(state)
  const onCaseNumberChange = (e, state) => setClaimNumber(state.value)
  const onFileKeyAnchorClick = (fileKey) => {
    setSelectedFileKey(fileKey)
    setIsOpenFileReceiptModal(true)
  }
  const onFileNameAnchorClick = (record) => {
    setSelectedFileKey(record.fileKey)
    setSelectedRecord(record)
    setIsDocView(true)
  }
  const searchClick = () => {
    setIsReportLoading(false);
    setRowsPerPage(50)
    setIdx(idx + 1);
    setPageIndex(1)
    setPayload({
      idx,
      maxRecords: 50,
      rowPosition: 1,
      claimNum: claimNumber,
      fileKey,
      fileName,
      fileStatus: fileStatus ? fileStatus.value : '',
      importStartDate: importStartDate,
      importEndDate: importEndDate,
      sortOrder: 'DESC',
      sortBy: 'importDt'
    })
  }

  const onSortGridColumn = (e, { sortingKey }) => {
    const splitKey = sortingKey.split('|')
    setSortBy(splitKey[0])
    if (splitKey[1] === 'SORT_DESCENDING') setSortOrder('DESC')
    else setSortOrder('ASC')
    setPayload({
      ...payload,
      sortBy: splitKey[0],
      sortOrder: splitKey[1] === 'SORT_DESCENDING' ? 'DESC' : 'ASC',
      cacheName,
    })
  }

  const clearClick = () => {
    setClaimNumber("")
    setFileKey("")
    setFileName("")
    setFileStatus({})
    setImportStartDate(moment().subtract(14, "days").format("MM/DD/YYYY"))
    setImportEndDate(moment().format("MM/DD/YYYY"))
    setDatesError(false)
  }
  const exportClick = async () => {
    try {
      setIsReportLoading(true);
      const res = await axios.get(`/fileTracker/exportFileUrl?cacheName=${cacheName}`, { responseType: 'blob' })
      if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
        window.navigator.msSaveOrOpenBlob(res.data, 'FileTrackerReport.csv');
      } else {
        const url = URL.createObjectURL(res.data);
        const element = document.createElement('a');
        element.setAttribute('href', url);
        element.setAttribute('download', `FileTrackerReport.csv`);
        element.click();
      }
      setIsReportLoading(false);
    } catch (err) {
      setIsReportLoading(false);
      notify('Failed to export document at the moment. Please try again', "negative")
    }
  }

  const onCloseFileReceiptModal = () => {
    setIsOpenFileReceiptModal(false)
    setSelectedFileKey(undefined)
  }

  const sortingComparator = (sortDataName, sortDirectionString, recordsToSort) => recordsToSort

  const startDateValidation = (currentDate) => {
    const momentStartDuration = moment().subtract(10, 'years');
    let err = false;
    if (moment(currentDate).isBefore(momentStartDuration)) {
      err = true;
    } else if (moment(currentDate).isAfter(moment(importEndDate).startOf('day'), 'day')) {
      err = true;
    }

    return !err;
  };

  const validation = (currentDate) => {
    const momentStartDuration = moment().subtract(10, 'years');
    let err = false;
    if (moment(currentDate).isBefore(momentStartDuration)) {
      err = true;
    } else if (moment(currentDate).isAfter(moment().startOf('day'), 'day')) {
      err = true;
    } else if (moment(currentDate).isBefore(moment(importStartDate).startOf('day'), 'day')) {
      err = true;
    }

    return !err;
  };

  const onStartDateChange = (val) => {
    let err = false;
    let errMessage = '';

    const momentStartDuration = moment().subtract(10, 'years');

    if (!moment(val, 'MM/DD/YYYY', true).isValid() || val === '' || moment(val).isBefore(momentStartDuration)) {
      errMessage = 'Import start date is invalid(mm/dd/yyyy, within 10 years).';
      err = true;
    } else if (moment(val).isAfter(moment(importEndDate))) {
      errMessage = 'Import start date should be earlier than the end date.';
      err = true;
    } else if (moment(val).isAfter(moment().startOf('day'), 'day')) {
      errMessage = 'Import start date is invalid(mm/dd/yyyy, max date is today).';
      err = true;
    }

    if (err) {
      setDatesError(true);
      setErrMsg(errMessage);
      setImportStartDate(val);
    } else {
      setDatesError(false);
      setImportStartDate(val.format("MM/DD/YYYY"));
    }
  }

  const onEndDateChange = (val) => {
    let err = false;
    let errMessage = '';

    const momentStartDuration = moment().subtract(10, 'years');

    if (!moment(val, 'MM/DD/YYYY', true).isValid() || val === '' || moment(val).isBefore(momentStartDuration)) {
      err = true;
      errMessage = 'Import end date is invalid(mm/dd/yyyy, within 10 years).'
    } else if (moment(val).isBefore(moment(importStartDate))) {
      errMessage = 'Import end date should be greater than the start date.';
      err = true;
    } else if (moment(val).isAfter(moment().startOf('day'), 'day')) {
      errMessage = 'Import end date is invalid(mm/dd/yyyy, max date is today).';
      err = true;
    }

    if (err) {
      setDatesError(true);
      setErrMsg(errMessage);
      setImportEndDate(val);
    } else {
      setDatesError(false);
      setImportEndDate(val.format("MM/DD/YYYY"));
    }
  }

  const onDateClose = () => {
    setIdx(idx + 1);
  }

  return (
    <>
      {!isDocView ?
        <>
          <TimeoutNotification />
          <ListPaneHeaderWrapper
            title="File Tracker"
            layoutControls={[expandCollapseListPaneButtonConfig]}
          />
          {labelsLoading || isLoading ? (
            // replace with a better loading component            
            <div style={{ marginLeft: '50%' }}><LoadingComponent /></div>
          ) : isError || isErrorLabels ? (
            // replace with a better error component
            <ErrorPage
              // this prop isn't real but is an example
              error={error || errorLabels}
            />
          ) : (
                <div className="file-tracker" style={{marginTop: "-9.5px"}}>
                  {lastUpdated && <div style={{ fontSize: '13px', padding: "0 0 10px 10px", display: 'flex', alignItems: 'center' }}>
                    <Clock size="small" />
                    <span style={{ marginLeft: '6px' }}>This data was last updated at {lastUpdated}. Please refresh the page for the most recent data.</span>
                  </div>}
                  {datesError && <p style={{ fontSize: '13px', color: 'red', marginLeft: '30px' }}>{errMsg}</p>}
                  <div className="fileTracker-filters" style={{ display: 'flex', marginLeft: '30px'}}>
                    <ButtonGroup style={{ display: 'flex', alignItems: 'flex-end' }}>
                      <div className="msgDatePicker">
                        <label style={{ color: '#626D8A', fontSize: '11px' }}>IMPORT START DATE</label>
                        <Datetime
                          onChange={onStartDateChange}
                          // inputProps={{ readOnly: true }}
                          closeOnSelect={true}
                          isValidDate={startDateValidation}
                          dateFormat="MM/DD/YYYY"
                          value={importStartDate}
                          initialValue={importStartDate}
                          initialViewDate={importStartDate}
                          timeFormat={false}
                          onClose={onDateClose}
                        />
                      </div>
                      <div className="msgDatePicker">
                        <label style={{ color: '#626D8A', fontSize: '11px' }}>IMPORT END DATE</label>
                        <Datetime
                          onChange={onEndDateChange}
                          // inputProps={{ readOnly: true }}
                          closeOnSelect={true}
                          isValidDate={validation}
                          dateFormat="MM/DD/YYYY"
                          value={importEndDate}
                          initialValue={importEndDate}
                          initialViewDate={importEndDate}
                          timeFormat={false}
                          onClose={onDateClose}
                        />
                      </div>
                      <Input
                        domID="FileTrackerJobNumberInput"
                        onChange={onJobNumberChange}
                        size="small"
                        label={labelsLoading ? '' : (labels?.fileKey || `File Key`).toUpperCase()}
                        initialValue={fileKey}
                      />
                      <Input
                        domID="FileTrackerFileNameInputName"
                        onChange={onFileNameChange}
                        size="small"
                        label="FILE NAME"
                        initialValue={fileName}
                      />
                      <SelectDropdown
                        width="180px"
                        domID="FileTrackerFileStatusDropdown"
                        onChange={onFileStatusChange}
                        size="small"
                        label="FILE STATUS"
                        options={statuses || []}
                        initialValue={fileStatus}
                        isClearable={fileStatus?.label ? true : false}
                      />
                      <Input
                        domID="FileTrackerCaseNumberInput"
                        onChange={onCaseNumberChange}
                        size="small"
                        label={labelsLoading ? '' : (labels?.claimNum || `Claim Number`).toUpperCase()}
                        initialValue={claimNumber}
                      />
                      <Button
                        domID="searchButton"
                        name="Search"
                        onClick={searchClick}
                        size="small"
                        buttonType="standard"
                        className="file-tracker__search-button"
                        disabled={datesError}
                      />
                      <Button
                        domID="clearButton"
                        name="Clear"
                        onClick={clearClick}
                        size="small"
                        buttonType="diminished"
                        className="file-tracker__search-button"
                      />
                    </ButtonGroup>
                  </div>

                  <div className="file-tracker__pagination-row" style={{ display: 'flex', alignItems: 'baseline' }}>
                    <Button
                      domID="exportButton"
                      name={isReportLoading ? "Exporting data..." : "Export"}
                      onClick={exportClick}
                      size="small"
                      buttonType="standard"
                      disabled={!data?.length > 0 || isReportLoading}
                    />

                    {
                      isReportLoading && <Loading length="30px" width="auto" />
                    }

                    <GridPaginationWrapper
                      rowsPerPage={rowsPerPage}
                      onChangeRowsPerPage={setRowsPerPage}
                      pageIndex={pageIndex}
                      onChangePageIndex={setPageIndex}
                      onShowButtonCLick={handleShowButton}
                      totalRecords={total}
                      defaultRowsPerPage={50}
                      specialIdx={true}
                      disableShowButton={datesError}
                    />
                  </div>
                  {/* <div className="file-tracker__grid-wrapper">
              
            </div> */}
                  <div className="GridWrapper">
                    <Grid
                      isConfigurable
                      isFixedHeader
                      maxHeight="470px"
                      columns={new Set([
                        {
                          dataName: 'fileKeyProp',
                          text: (labels?.fileKey || 'File Key').toUpperCase(),
                          cellType: 'custom',
                        },
                        {
                          dataName: 'fileNameProp',
                          text: 'FILE NAME',
                          cellType: 'custom',
                        },
                        {
                          dataName: 'importDt',
                          text: 'IMPORT DATE',
                          cellType: 'text',
                          sortable: true,
                          isSorted: 0,
                        },
                        {
                          dataName: 'imported',
                          text: (labels?.imported || 'IMPORTED').toUpperCase(),
                          cellType: 'text',
                        },
                        {
                          dataName: 'held',
                          text: 'HELD',
                          cellType: 'text',
                        },
                        {
                          dataName: 'error',
                          text: 'ERROR',
                          cellType: 'text',
                        },
                        {
                          dataName: 'mSuppressed',
                          text: 'MANUAL SUPPRESSED',
                          cellType: 'text',
                        },
                        {
                          dataName: 'aSuppressed',
                          text: 'AUTO SUPPRESSED',
                          cellType: 'text',
                        },
                        {
                          dataName: 'readyPrnt',
                          text: (labels?.readyPrnt || 'Ready for Print').toUpperCase(),
                          cellType: 'text',
                        },
                        {
                          dataName: 'released',
                          text: (labels?.released || 'Released').toUpperCase(),
                          cellType: 'text',
                        },
                        {
                          dataName: 'mailed',
                          text: 'COMPLETED (COUNT)',
                          cellType: 'text',
                        },
                        {
                          dataName: 'percentMailed',
                          text: 'COMPLETED%',
                          cellType: 'text',
                        },
                        {
                          dataName: 'readyPrntDt',
                          text: (labels?.readyPrntDt || 'Ready For Print Date').toUpperCase(),
                          cellType: 'text',
                        },
                        {
                          dataName: 'mailDt',
                          text: 'MAILED / COMPLETED DATE',
                          cellType: 'text',
                        },
                        {
                          dataName: 'fileStatus',
                          text: 'FILE STATUS',
                          cellType: 'text',
                        },
                        {
                          dataName: 'enotes',
                          text: (labels?.enotes || 'ENOTES').toUpperCase(),
                          cellType: 'text',
                        },
                        {
                          dataName: 'enoteDt',
                          text: 'ENOTE DELIVERY DATE',
                          cellType: 'text',
                        },
                        {
                          dataName: 'web',
                          text: (labels?.web || 'WEB').toUpperCase(),
                          cellType: 'text',
                        },
                      ])}
                      records={data?.map(record => ({
                        ...record,
                        imported: (
                          <span className="fileTracker-list-count">
                            {record.imported}
                          </span>
                        ),
                        held: (
                          <span className="fileTracker-list-count">
                            {record.held}
                          </span>
                        ),
                        error: (
                          <span className="fileTracker-list-count">
                            {record.error}
                          </span>
                        ),
                        mSuppressed: (
                          <span className="fileTracker-list-count">
                            {record.mSuppressed}
                          </span>
                        ),
                        aSuppressed: (
                          <span className="fileTracker-list-count">
                            {record.aSuppressed}
                          </span>
                        ),
                        readyPrnt: (
                          <span className="fileTracker-list-count">
                            {record.readyPrnt}
                          </span>
                        ),
                        released: (
                          <span className="fileTracker-list-count">
                            {record.released}
                          </span>
                        ),
                        mailed: (
                          <span className="fileTracker-list-count">
                            {record.mailed}
                          </span>
                        ),
                        percentMailed: (
                          <span className="fileTracker-list-count">
                            {record.percentMailed}
                          </span>
                        ),
                        enotes: (
                          <span className="fileTracker-list-count">
                            {record.enotes}
                          </span>
                        ),
                        web: (
                          <span className="fileTracker-list-count">
                            {record.web}
                          </span>
                        ),
                        fileKeyProp: (
                          <AnchorInput
                            id={`fileKey-${record.fileKey}`}
                            type='button'
                            className='fileKeyLink'
                            onClick={() => onFileKeyAnchorClick(record.fileKey)}
                          >
                            {record.fileKey}
                          </AnchorInput>
                        ),
                        fileNameProp: (
                          <AnchorInput
                            id={`fileName-${record.fileName}`}
                            type='button'
                            className='fileNameLink'
                            onClick={() => onFileNameAnchorClick(record)}
                          >
                            {record.fileName}
                          </AnchorInput>
                        ),
                      })) || []}
                      dataTestId="test-FileTrackerGrid"
                      domID="FileTrackerGrid"
                      initialSortingKey={`${sortBy}|${sortOrder === 'DESC' ? 'SORT_DESCENDING' : 'SORT_ASCENDING'}`}
                      sortingComparator={sortingComparator}
                      onSortGridColumn={onSortGridColumn}
                    />
                  </div>
                </div>
              )}
          <Modal isOpen={isOpenFileReceiptModal}>
            <StyledModalWrapper>
              <StyledModalHeaderWrapper>
                <ModalHeader
                  title="File Receipt"
                  onClose={onCloseFileReceiptModal}
                />
              </StyledModalHeaderWrapper>
              <ModalBody>
                {isOpenFileReceiptModal ? (
                  <FileReceiptManagerDetailComponent
                    filekey={selectedFileKey}
                    noActions={true}
                    height='58vh'
                    overflow='auto'
                  />
                ) : null}
              </ModalBody>
            </StyledModalWrapper>
          </Modal>
        </> : <ChildListPane
          claimNumber={claimNumber}
          record={selectedRecord}
          labels={labels}
          onClose={() => setIsDocView(false)}
        />}
    </>
  )
}

export default withAxiosCancellation(FileTrackerListPane)