export const DOCUMENT_SEARCH_RESULTS_SHOW_MORE_NESTED_GRID_COLUMN={
  disabledColumns:new Set([]),
  columns: new Set([
    {
      dataName: 'type',
      text: 'Type',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'facilityType',
      text: 'Facility/Type',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'id_display',
      text: 'ID',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'description',
      text: 'Description',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },{
      dataName: 'dateTime',
      text: 'Date/Time',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    }])
}

export const DOCUMENT_SEARCH_RESULTS_SHOW_MORE_GRID_COLUMN={
  disabledColumns:new Set([]),
  columns: new Set([
    {
      dataName: 'copy',
      text: 'Copy',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'stack',
      text: 'Stack',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'job',
      text: 'Job',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'envelope',
      text: 'Envelope',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },{
      dataName: 'startPage',
      text: 'Start Page',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },{
      dataName: 'facility',
      text: 'Facility',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },{
      dataName: 'mailDate',
      text: 'Mail Date',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },{
      dataName: 'deliveryType',
      text: 'Delivery Type',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },{
      dataName: 'trackingInformation',
      text: 'Tracking Information',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
      hasSubRecords:true
    },{
      dataName: 'email',
      text: 'Email',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    }])
}

export const DOCUMENT_SEARCH_RESULTS_PAGE_GRID_COLUMN={
  columns: new Set([
    {
      dataName: 'documentKey',
      text: 'DOCUMENT KEY',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'importDate',
      text: 'IMPORT DATE',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'paymentIdentifier',
      text: 'PAYMENT IDENTIFIER',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'checkNumber',
      text: 'CHECK NUMBER',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'checkAmount',
      text: 'CHECK AMOUNT',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'claimNumberDisplay',
      text: 'CLAIM NUMBER',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'claimType',
      text: 'CLAIM TYPE',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'documentType',
      text: 'DOCUMENT TYPE',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'recipientType',
      text: 'RECIPIENT TYPE',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'copyNumbersDisplay',
      text: 'COPY NUMBER(S)',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'wheresMyPackage',
      text: `WHERE'S MY PACKAGE`,
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'status',
      text: 'STATUS',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    }
  ]),
  selectedItemIds: new Set([]),
  bookmarkedItemIds: new Set([]),
  domID: 'DocumentManagerSearchGrid',
  isConfigurable: true,
  supportSelection: false
};
export const DOCUMENT_SEARCH_RESULTS_PAGE_QUICK_GRID_COLUMN={
  columns: new Set([
    {
      dataName: 'documentKeyDisplay',
      text: 'DOCUMENT KEY',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'checkNumber',
      text: 'CHECK NUMBER',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'checkAmount',
      text: 'CHECK AMOUNT',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'claimNumberDisplay',
      text: 'CLAIM NUMBER',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'claimType',
      text: 'CLAIM TYPE',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'documentType',
      text: 'DOCUMENT TYPE',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'recipientType',
      text: 'RECIPIENT TYPE',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'pdfLinkDisplay',
      text: 'PDF LINK',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'status',
      text: 'STATUS',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    }
  ]),
  selectedItemIds: new Set([]),
  bookmarkedItemIds: new Set([]),
  domID: 'DocumentManagerSearchQuickGrid',
  isConfigurable: true,
  supportSelection: false
};

export const HOLD_DOCUMENT_SEARCH_RESULT_COLUMN = {
  disabledColumns:new Set([]),
  columns: new Set([
    {
      dataName: 'claimNumber',
      text: 'CLAIM NUMBER',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'checkNumber',
      text: 'CHECK #',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'checkAmount',
      text: 'CHECK AMOUNT',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'claimTypeDescription',
      text: 'CLAIM TYPE',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },{
      dataName: 'recipientTypeDescription',
      text: 'RECIPIENT TYPE',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },{
      dataName: 'relationCodeDescription',
      text: 'DOCUMENT TYPE',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },{
      dataName: 'statusComp',
      text: 'STATUS',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    }])
}

export const SELECTED_HOLD_PURGE_ROUTE_DOCUMENT_SEARCH_RESULT_COLUMN = {
  disabledColumns:new Set([]),
  columns: new Set([
    {
      dataName: 'documentKey',
      text: 'DOCUMENT KEY',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'claimNumber',
      text: 'CLAIM NUMBER',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'checkNumber',
      text: 'CHECK #',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'checkAmount',
      text: 'CHECK AMOUNT',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'claimTypeDescription',
      text: 'CLAIM TYPE',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },{
      dataName: 'recipientTypeDescription',
      text: 'RECIPIENT TYPE',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },{
      dataName: 'relationCodeDescription',
      text: 'DOCUMENT TYPE',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },{
      dataName: 'statusComp',
      text: 'STATUS',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    }])
}

export const SELECT_EMPLOYER_GROUP ={
  columns: new Set([
    {
      dataName: 'label',
      text: 'EMPLOYER GROUP',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    }
  ])
};

export const FILE_SEARCH_RESULT_COLUMN ={
  columns: new Set([
    {
      dataName: 'filekey',
      text: 'File Key',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'originalFileName',
      text: 'Original Name',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'importedDate',
      text: 'Import Date',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'status',
      text: 'STATUS',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'errorPercent',
      text: '',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    }

  ]),
  bookmarkedItemIds: new Set([]),
  domID: 'searchFile',
  selectAllCheckboxDomID: 'select-all-test-id',
  isConfigurable: false,
  onSortGridColumn: () => false,
};
export const RELEASE_GROUP_SEARCH_RESULT_COLUMN ={
  columns: new Set([
    {
      dataName: 'filekey',
      text: 'File Key',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'originalFileName',
      text: 'Original Name',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'description',
      text: 'Description',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'group',
      text: 'Group',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'docType',
      text: 'Doc. Type',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'claimType',
      text: 'Claim Type',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'recipType',
      text: 'Recip. Type',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    }
  ]),
  bookmarkedItemIds: new Set([]),
  domID: 'searchFile',
  selectAllCheckboxDomID: 'select-all-test-id',
  isConfigurable: false,
  onSortGridColumn: () => false,
};
export const ROUTE_FILE_SEARCH_RESULT_COLUMN ={
  columns: new Set([
    {
      dataName: 'filekey',
      text: 'File Key',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'originalFileName',
      text: 'Original Name',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'importedDate',
      text: 'Import Date',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'status',
      text: 'STATUS',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'errors',
      text: '',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
  ]),
  bookmarkedItemIds: new Set([]),
  domID: 'searchFile',
  selectAllCheckboxDomID: 'select-all-test-id',
  isConfigurable: false,
  onSortGridColumn: () => false,
};
export const DOCUMENT_SEARCH_RESULTS_PAGE_ACH_FILE_DETAILS={
  columns: new Set([
    {
      dataName: 'bankname',
      text: 'BANK NAME',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'createddate',
      text: 'CREATED',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'debit',
      text: 'TOTAL DEBIT',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'credit',
      text: 'TOTAL CREDIT',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'name',
      text: 'FILE NAME',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'status',
      text: 'STATUS',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    }
  ]),
  selectedItemIds: new Set([]),
  bookmarkedItemIds: new Set([]),
  domID: 'GridACHFileDetails',
  isConfigurable: true,
  supportSelection: false
};

export const DOCUMENT_SEARCH_RESULTS_PAGE_ACH_BATCH_DETAILS={
  columns: new Set([
    {
      dataName: 'companyname',
      text: 'COMPANY NAME',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'acct',
      text: 'ACCOUNT',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'discdata',
      text: 'DISCRETIONARY DATA',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'settlementdate',
      text: 'SETTLEMENT',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'debit',
      text: 'TOTAL DEBIT',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'credit',
      text: 'TOTAL CREDIT',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'itemcount',
      text: 'TOTAL ITEMS',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'status',
      text: 'STATUS',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    }
  ]),
  selectedItemIds: new Set([]),
  bookmarkedItemIds: new Set([]),
  domID: 'ACHBatchDetails',
  isConfigurable: true,
  supportSelection: false
};
export const DOCUMENT_SEARCH_RESULTS_PAGE_ACH_TRANSACTION_DETAILS={
  columns: new Set([
    {
      dataName: 'key',
      text: 'DOCUMENT KEY',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'tracenum',
      text: 'TRACE NUMBER',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'recipientname',
      text: 'RECIPIENT ENTITY',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'supRoute',
      text: 'R/T NUMBER (LAST 4)',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'supAcct',
      text: 'ACCOUNT NUMBER (LAST 4)',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'accttype',
      text: 'C/S',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'debit',
      text: 'DEBIT',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'credit',
      text: 'CREDIT',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
    {
      dataName: 'status',
      text: 'STATUS',
      sortable: false,
      isSorted: 0,
      cellType: 'text',
    }
  ]),
  selectedItemIds: new Set([]),
  bookmarkedItemIds: new Set([]),
  domID: 'AchTransactionDetails',
  isConfigurable: true,
  supportSelection: false
};
export const SELECT_SORT_FIELD_OPTIONS = [
  { label: 'Document Key', value: "documentKey" },
  { label: 'Status', value: "status" },
  { label: 'Claim Number', value: "claimnumber" }
];
export const SELECT_SORT_FIELD_RELEASE_DOCUMENT = [
  { label: 'Trans Id', value: "transid" },
  { label: 'Claim Number', value: "claimnumber" }
];
export const SELECT_SORT_FIELD_RELEASE_GROUP = [
  { label: 'File Key', value: "filekey" },
  { label: 'Original File Name', value: "originalFileName" },
  { label: 'Import Date', value: "importDate" }
];

export const SELECT_RECORD_OPTIONS = [
  {label:'1', value:'1'},
  {label:'3', value:'3'},
  {label:'5', value:'5'},
  {label:'10', value:'10'},
  {label:'25', value:'25'},
  {label:'50', value:'50'},
];


export const SORT_ORDER = [
  { label: 'Descending', value: "true" },
  { label: 'Ascending', value: "false" }
];

export const RELEASE_TYPE = [
  { label: 'Scheduled Release Rule', value: "S" },
  { label: 'First Available', value: "I" }
];
export const COLLAPSE_CLAIM_NUMBER = [{label:"No",value:"No"},{label:"Yes",value:"Yes"}]
export const SELECT_ACTION_OPTION=[
  { value: '', label:"None"},
  { value: 'Hold', label:"Hold"},
  { value: 'Purge', label:"Purge"},
  { value: 'Release', label:"Release"},
  { value: 'Route', label:"Route"}
];
export const SELECT_LEVEL_OPTION=[
  { value: '', label:"None"},
  { value: 'Document', label:"Document"},
  { value: 'Group', label:"Group"},
  { value: 'File', label:"File"},
  { value: 'Auto', label:"Auto"}
];

export const DOCUMENT_HISTORY_GRID_COLUMN= 
new Set([
  {
    dataName: 'groupname_display',
    groupingColumnName:'id',
    text: 'Group Name',
    sortable: true,
    isSorted: 0,
    cellType: 'text',
  },
  // {
  //   dataName: 'id',
  //   text: 'TRANSACTION ID',
  //   sortable: false,
  //   isSorted: 0,
  //   cellType: 'text',
  // },
  {
    dataName: 'documentKey',
    text: 'DOCUMENT KEY',
    sortable: false,
    isSorted: 0,
    cellType: 'text',
  },
  {
    dataName: 'claimNumbers',
    text: 'CLAIM #',
    sortable: false,
    isSorted: 0,
    cellType: 'text',
  },
  {
    dataName: 'recipientTypeDescription',
    text: 'DOCUMENT TYPE',
    sortable: false,
    isSorted: 0,
    cellType: 'text',
  },
  {
    dataName: 'checkNumber',
    text: 'CHECK #',
    sortable: false,
    isSorted: 0,
    cellType: 'text',
  },
  {
    dataName: 'checkAmount',
    text: 'CHECK AMT.',
    sortable: false,
    isSorted: 0,
    cellType: 'text',
  },
  {
    dataName: 'status',
    text: 'TRANSACTION STATUS',
    sortable: false,
    isSorted: 0,
    cellType: 'text',
  },
  // {
  //   dataName: 'user',
  //   text: 'TRANSACTION BY',
  //   sortable: false,
  //   isSorted: 0,
  //   cellType: 'text',
  // },
  // {
  //   dataName: 'dateTime',
  //   text: 'TRANSACTION DATE',
  //   sortable: false,
  //   isSorted: 0,
  //   cellType: 'text',
  // }, 
  {
    dataName: 'details',
    text: '',
    sortable: false,
    isSorted: 0,
    cellType: 'text',
  },  
]);
export const FILE_GROUP_HISTORY_GRID_COLUMN= 
new Set([
  {
    dataName: 'groupname_display',
    groupingColumnName:'id',
    text: 'Group Name',
    sortable: true,
    isSorted: 0,
    cellType: 'text',
  },
  // {
  //   dataName: 'id',
  //   text: 'TRANSACTION ID',
  //   sortable: false,
  //   isSorted: 0,
  //   cellType: 'text',
  // },
  {
    dataName: 'filekey',
    text: 'FILE KEY',
    sortable: false,
    isSorted: 0,
    cellType: 'text',
  },
  {
    dataName: 'originalFileName',
    text: 'ORIG. NAME',
    sortable: false,
    isSorted: 0,
    cellType: 'text',
  },
  {
    dataName: 'receivedDate',
    text: 'FILE RECEIVED DATE',
    sortable: false,
    isSorted: 0,
    cellType: 'text',
  },
  {
    dataName: 'status',
    text: 'TRANSACTION STATUS',
    sortable: false,
    isSorted: 0,
    cellType: 'text',
  },
  {
    dataName: 'details',
    text: '',
    sortable: false,
    isSorted: 0,
    cellType: 'text',
  },
]);
export const AUTO_HISTORY_GRID_COLUMN= 
new Set([
  {
    dataName: 'groupname_display',
    groupingColumnName:'id',
    text: 'Group Name',
    sortable: true,
    isSorted: 0,
    cellType: 'text',
  },
  // {
  //   dataName: 'id',
  //   text: 'TRANSACTION ID',
  //   sortable: false,
  //   isSorted: 0,
  //   cellType: 'text',
  // },
  {
    dataName: 'filekey',
    text: 'FILE KEY',
    sortable: false,
    isSorted: 0,
    cellType: 'text',
  },
  {
    dataName: 'claimtype',
    text: 'CLAIM TYPE',
    sortable: false,
    isSorted: 0,
    cellType: 'text',
  },
  {
    dataName: 'recip',
    text: 'RECIPIENT',
    sortable: false,
    isSorted: 0,
    cellType: 'text',
  },
  {
    dataName: 'doctype',
    text: 'DOC. TYPE',
    sortable: false,
    isSorted: 0,
    cellType: 'text',
  },
  {
    dataName: 'status',
    text: 'STATUS',
    sortable: false,
    isSorted: 0,
    cellType: 'text',
  },
  {
    dataName: 'details',
    text: '',
    sortable: false,
    isSorted: 0,
    cellType: 'text',
  }
]);

export const GENERIC_TEXT1 = "All Document Management must be completed by the start of the release to be included"

export const GENERIC_TEXT2 = "Type a complete or a partial claim number, check number, TIN or SSN to search for a specific documents or a range of documents. You can also select a group or file to display all documents within the group or file. Click Next to display the search results page where you can select the documents you want to release."

export const GENERIC_TEXT_HOLD = "If a \"Hold\" request is logged after 10:00 AM Central Time, the request will apply to the next business day's release."

export const GENERIC_TEXT_RELEASE = "If a \"Release\" request is logged after 10:00 AM Central Time, the request will apply to the next business day's release."

export const GENERIC_TEXT_PURGE = "If a \"Purge\" request is logged after 10:00 AM Central Time, the request will apply to the next business day's release."

export const GENERIC_TEXT_ROUTE = "If a \"Route\" request is logged after 10:00 AM Central Time, the request will apply to the next business day's release."

export const GENERIC_TEXT_SEARCH_HOLD = "Select the documents that you want to place on hold, paging through the search results list, if necessary, to find the documents. When you are finished making your selections, click Confirm Selected Items to continue."

export const GENERIC_TEXT_SEARCH_PURGE = "Select the groups that you want to purge, paging through the search results list, if necessary, to find all groups. When you are finished making your selections, click Confirm Selected Items to continue."

export const GENERIC_TEXT_SEARCH_RELEASE = "Select the automatic holds that you want to release, paging through the search results list, if necessary, to find all items. A checkbox is displayed only if a hold is available for release. When you are finished making your selections, click Confirm Selected Items to continue."

export const GENERIC_TEXT_SEARCH_ROUTE = "Update the routing information by choosing a different delivery address and/or delivery type. Select the groups to be delivered using the updated routing information, paging through the search results list, if necessary, to find the groups. Click Confirm Selected Items to continue."

export const GENERIC_WARNING_TEXT_PURGE = "Warning purging documents can not be undone!"

export const GENERIC_WARNING_TEXT_ROUTE = "WARNING: Please be aware that any routing performed will override any electronic delivery and will print and mail the selected document(s) below."

export const GENERIC_TEXT_FILE_SEARCH_HOLD = "Select the files that you want to place on hold, paging through the search results list, if necessary, to find the files. When you are finished making your selections, click Confirm Selected Items to continue."

export const GENERIC_TEXT_FILE_SEARCH_RELEASE = "Select the files that you want to release, paging through the search results list, if necessary, to find all items. A checkbox is displayed only if a file is available for release. When you are finished making your selections, click Confirm Selected Items to continue."

export const GENERIC_TEXT_FILE_SEARCH_PURGE = "Select the files that you want to purge, paging through the search results list, if necessary, to find all files. When you are finished making your selections, click Confirm Selected Items to continue."

export const GENERIC_TEXT_FILE_SEARCH_ROUTE = "Update the routing information by choosing a different delivery address and/or delivery type. Select the files to be delivered using the updated routing information, paging through the search results list, if necessary, to find the files. Click Confirm Selected Items to continue."

export const GENERIC_TEXT_DOCUMENT_SEARCH_RELEASE = "Select the documents that you want to release, paging through the search results list, if necessary, to find all items. A checkbox is displayed only if a document is available for release. When you are finished making your selections, click Confirm Selected Items to continue."

export const GENERIC_TEXT_DOCUMENT_SEARCH_PURGE = "Select the documents that you want to purge, paging through the search results list, if necessary, to find all documents. When you are finished making your selections, click Confirm Selected Items to continue."

export const GENERIC_TEXT_DOCUMENT_SEARCH_ROUTE1 = "Update the routing information by choosing a different delivery address and/or delivery type. Select the documents that will be delivered using the updated routing information, paging through the search results list, if necessary, to find the documents. Click Confirm Selected Items to continue."

export const GENERIC_TEXT_DOCUMENT_SEARCH_ROUTE2 = "If the value in the claim number column shows up as a link, the document is ready for pre-release preview. Any updates to group rules, forms or images can affect the final output."

export const RELEASE_TYPE_ERROR_MSG = "This item is already scheduled to print. Please select release type First Available in order to override Scheduled Date"

export const FILEKEY_LIST_NOT_AVAILABLE = 'Filekey list not available'
export const RECIPIENT_TYPE_LIST_NOT_AVAILABLE = 'Recipient Type list not available'
export const EMPLOYER_GROUP_LIST_NOT_AVAILABLE = 'Employer Group list not available'
export const DOCUMENT_TYPE_LIST_NOT_AVAILABLE = 'Document Type list not available'
export const CLAIM_TYPE_NOT_AVAILABLE = 'Claim Type list not available'
export const FORM_NUMBER_LIST_NOT_AVAILABLE = 'Form number list not available'
export const CLAIM_TYPE = 'ClaimType'
export const RECIPIENT_TYPE = 'RecipientType'
export const DOCUMENT_TYPE = 'DocumentType'
export const DOC_MANAGER_SPECIFY_TEMP_DELIVERY_ADDRESS='ROUTE_SPECIFY_TEMP_DELIVERY_ADDRESS'
export const DOC_MANAGER_ROUTE_ORIGINAL_ADDRESS='ROUTE_ORIGINAL_ADDRESS'
export const DOC_MANAGER_ROUTE_ORIGINAL_ADDRESS_LABEL='-- Originally Specified Address --'
export const DOC_MANAGER_SPECIFY_TEMP_DELIVERY_ADDRESS_LABEL='-- Specify A One-Time Address --'
export const DOC_MANAGER_ROUTE_DELIVERY_ADDRESS_EMPTY_VALIDATION_MSG='You must specify a delivery address.'
export const ABF_DELIVERY_ADDRESS_TYPE='D'
export const DOC_MANAGER_ROUTE_DELIVERY_ADDRESS_INT_NORECORDS_SELECTED='*ERROR - You must select a single record from the documents.'
export const DOC_MANAGER_ROUTE_DELIVERY_TYPE_NO_CHANGE_LABEL = '-- No Change --';
export const DOC_MANAGER_ROUTE_DELIVERY_TYPE_NO_CHANGE_VALUE = 'no_change';
export const DOC_MANAGER_ROUTE_DELIVERY_TYPE_CANACCEPTPOBOX_VALIDATION_MSG='*ERROR -You cannot choose Po-Box for this selected Delivery Type'
export const DOC_MANAGER_ROUTE_PO_BOX_WARNING='WARNING - If the Specified Address of the document(s) is a PO Box, but the original delivery type cannot support the delivery to a PO Box, then the document(s) can NOT be delivered'
export const DOC_MANAGER_ROUTE_PO_BOX_WARNING_SHORT='WARNING - If the Specified Address of the document(s) is a PO Box, then the document(s) can NOT be delivered.'
export const DOC_MANAGER_ROUTE_INTENDED_ADDRESS_WARNING='*WARNING - You have not re-routed the document you are wanting to send! Make sure this is the intended address.'