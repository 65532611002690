import React, { useState } from 'react'
import {
  Grid,
  useLayout,
  ListPaneHeader,
  Button,
} from 'ui-core/dist'
import axios from '../../axios';
import exportFromJSON from 'export-from-json'
import ErrorPage from '../ErrorPage'
import useNotificationBannerHook from '../../useNotificationBannerHook';
import { useDatasourceRequest } from '../../api/useReportManager';
import { LoadingPage, SelectDropdown } from '../../components';
import withAxiosCancellation from '../../components/withAxiosCancellation';

import './modules/style.scss'

const UserReport = ({getCancelSourceWithName}) => {
  const {
    expandCollapseListPaneButtonConfig
  } = useLayout()

  const { notify } = useNotificationBannerHook()

  const { dataSourceList: dataSourceList, isLoading: pIsLoading, isError, error } = useDatasourceRequest({getCancelSource:getCancelSourceWithName('dataSourceRequest')});
  const [isReportLoading, setIsReportLoading] = useState(false);
  const [userReportList, setUserReportList] = useState();
  const [isReportbtnClicked, setIsReportbtnClicked] = useState(false);
  const [initialDataSourceValue, setInitialDataSourceValue] = useState({ label: "ALL", value: "ALL" });
  const [dataSourceValue, setDataSourceValue] = useState("ALL");
  const handleDatasourceOptionChange = (e) => {
    setInitialDataSourceValue({ label: e?.label, value: e?.value });
    setDataSourceValue(e?.value);
  }

  const SELECT_REPORT_OPTIONS = [{
    label: "View On Sceen",
    value: "VON"
  },
  {
    label: "Download Microsoft Excel",
    value: "DME"
  }
  ];
  const [initialREPORT_OPTIONValue, setInitialREPORT_OPTIONValue] = useState({ label: "View On Sceen", value: "VON" });
  const [reportType, setReportType] = useState("VON");
  const handleReportOptionChange = (e) => {
    setInitialREPORT_OPTIONValue({ label: e?.label, value: e?.value });
    setReportType(e?.value);
  }

  const SELECT_REPORT_SORTOrder_OPTIONS = [{
    label: "Ascending",
    value: "ASC"
  },
  {
    label: "Descending",
    value: "DESC"
  }
  ];
  const [initialReportSortOrderValue, setInitialReportSortOrderValue] = useState({ label: "Ascending", value: "ASC" });
  const [reportSortOrder, setReportSortOrder] = useState("ASC");
  const handleReportSortOrderOptionChange = (e) => {
    setInitialReportSortOrderValue({ label: e?.label, value: e?.value });
    setReportSortOrder(e?.value);
  }

  const SELECT_REPORT_SORTBy_OPTIONS = [{
    label: "User ID",
    value: "USERID"
  },
  {
    label: "User Name",
    value: "NAME"
  },
  {
    label: "Client Layout/Web Group",
    value: "DATASOURCE"
  }
    ,
  {
    label: "Active Status",
    value: "STATUS"
  },
  {
    label: "Last Logged In",
    value: "LASTLOG"
  }
  ];
  const [initialReportSortByValue, setTnitialReportSortByValue] = useState({ label: "User ID", value: "USERID" });
  const [reportSortBy, setReportSortBy] = useState("USERID");
  const handleReportSortByOptionChange = (e) => {
    setTnitialReportSortByValue({ label: e?.label, value: e?.value });
    setReportSortBy(e?.value);
  }

  const fileName = 'UserReport';
  const exportType = 'xls';
  const getReport = () => {
    if (reportType == "VON") {
      setIsReportbtnClicked(true)
    }
    else {
      setIsReportbtnClicked(false)
    }
    setIsReportLoading(true);
    axios.get(`/reports-manager/user-report/${dataSourceValue}?sortBy=${reportSortBy}&sortOrder=${reportSortOrder}`).then(res => {
      setUserReportList(res.data.data)
      setIsReportLoading(false);
      if (reportType !== "VON") {
        const data = res.data.data;
        exportFromJSON({ data, fileName, exportType })
      }
    }).catch(err => {
      notify(err.displayMessage, "negative")
      setIsReportLoading(false);
    })
  }

  const userReportColumns = new Set([
    {
      dataName: 'dataSourceDesc',
      text: 'CLIENT_LAYOUT(S) AND OR WEB GROUP(S):',
      cellType: 'text',
    },
    {
      dataName: 'UserAccess',
      text: 'WEB ACCESS ROLE (MULTIPLE ACCESS IS SEPARATED WITH **):',
      cellType: 'text',
    },
    {
      dataName: 'UserAccessDesc',
      text: 'TYPE OF ACCESS (MULTIPLE ACCESS IS SEPARATED WITH **):',
      cellType: 'text',
    },
    {
      dataName: 'UserId',
      text: 'USER ID',
      cellType: 'text',
    },
    {
      dataName: 'Name',
      text: 'USER NAME',
      cellType: 'text',
    },
    {
      dataName: 'Email',
      text: 'EMAIL ADDRESS',
      cellType: 'text',
    },
    {
      dataName: 'Status',
      text: 'ACTIVE STATUS',
      cellType: 'text',
    },
    {
      dataName: 'LastLogin',
      text: 'DATE OF LAST LOGIN',
      cellType: 'text',
    },
    {
      dataName: 'Expire',
      text: 'PASSWORD EXPIRATION DATE',
      cellType: 'text',
    }
  ]);

  return (
    <>
      <ListPaneHeader
        title={`User Report`}
        layoutControls={[expandCollapseListPaneButtonConfig]}
      />
      {
        isError ? (
          // replace with a better error component
          <ErrorPage
            // this prop isn't real but is an example
            error={error}
          />
    )
      : 
      <div className="marginLeftUserReport">
        <table id="userReportSearch">
          <tr>
            <td className='userreport-labels'><label>Select Report Type:</label></td>
            <td className='userReportcriteriaWidth'>
              <SelectDropdown
                width="250px"
                isClearable={false}
                domID="reportType"
                options={SELECT_REPORT_OPTIONS}
                size="small"
                initialValue={initialREPORT_OPTIONValue}
                onChange={(e) => handleReportOptionChange(e)}
              />
            </td>
          </tr>
          <tr>
            <td className='userreport-labels'><label>Select Client Layout &amp;/or Web Group:</label></td>
            <td className='userReportcriteriaWidth'>
              {
                pIsLoading ? (
                  <LoadingPage height='40px'></LoadingPage>
                ) : (<SelectDropdown
                  width="250px"
                  isClearable={false}
                  domID="reportDatasourse"
                  options={dataSourceList}
                  size="small"
                  initialValue={initialDataSourceValue}
                  onChange={(e) => handleDatasourceOptionChange(e)}
                />)
              }
            </td>
          </tr>
          <tr>
            <td className='userreport-labels'><label>Sort By:</label></td>
            <td className='userReportcriteriaWidth'>
              <SelectDropdown
                isClearable={false}
                domID="reportSortBy"
                options={SELECT_REPORT_SORTBy_OPTIONS}
                size="small"
                width="250px"
                initialValue={initialReportSortByValue}
                onChange={(e) => handleReportSortByOptionChange(e)}

              /></td>
          </tr>
          <tr>
            <td className='userreport-labels'><label>Sort Order:</label></td>
            <td className='userReportcriteriaWidth'>
              <SelectDropdown
                width="250px"
                isClearable={false}
                domID="reportSortOrder"
                options={SELECT_REPORT_SORTOrder_OPTIONS}
                size="small"
                initialValue={initialReportSortOrderValue}
                onChange={(e) => handleReportSortOrderOptionChange(e)}

              /></td>
          </tr>
          <tr><td><Button
            buttonType="standard"
            size="small"
            name="Submit"
            onClick={() => { getReport() }}
          /></td><td></td></tr>
        </table>
        <div style={{paddingTop: '10px'}}>
          {
            isReportbtnClicked ? (isReportLoading ? (<LoadingPage></LoadingPage>) : (<div><Grid
              domID="UserReportGrid"
              columns={userReportColumns}
              dataTestId="test-userReport"
              records={userReportList || []}
            /></div>)) : (null)
          }
        </div>
      </div>
      }
    </>
  )
}

export default withAxiosCancellation(UserReport);