import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { LoadingIndicator, Button } from 'ui-core-ssgr';
import { 
  ButtonGroupBottom, 
  ModalButton,
  ReadOnlyTextArea,
  ReadOnlyTextTitle 
} from '../../../shared/styles/styledComponents';
import { ContainerBox, CopyNumberCaption } from './styles';
import { LoadingWrapper } from '../../RulesTabView/components/RuleDetails/RuleDetailsGridWrapper/styles';
import DropDownControl from '../ModalControls/DropDownControl/index';
import InputControl from '../ModalControls/InputControl';
class MemberIdModal extends React.Component {
  state = {
    claimType: 'default',
    recipientType: 'default',
    documentType: 'default',
    copyNumber: '',
    SSN: '',
    deliveryMethod: 'default',
    deliveryAddress: 'default',
    checkForm: 'default',
    eobForm: 'default',
    isButtonDisabled: true,
    copyNumberError: false,
    copyNumberErrorMessage: '',
    deliveryAddressError: false,
    deliveryAddressErrorMessage: '',
    SSNError: false,
    SSNErrorMessage: '',
  };

  componentDidMount() {
    this.getInitialValues();
  }

  componentDidUpdate(prevProps) {
    const { detailElements, isShowingMemberIdModal } = this.props;
    if (
      prevProps.detailElements !== detailElements ||
      prevProps.isShowingMemberIdModal !== isShowingMemberIdModal
    ) {
      this.getInitialValues();
    }
  }

  populateCodeFromValue = name => {
    const { detailElements, selectedRuleDetail } = this.props;
    if (!_.isEmpty(detailElements)) {
      if (name && detailElements.claimTypesList) {
        let selectedCode = '';
        let selectedList = '';
        let firstValue = '';
        let selectedName = name.replace('List', '');
        if (selectedName.indexOf('Types')) {
          selectedName = selectedName.replace('Types', 'Type');
        }
        const selectedValue = selectedRuleDetail[selectedName];
        if (name === 'claimTypesList') selectedList = 'claimTypesList';
        if (name === 'recipientTypesList') selectedList = 'recipientTypesList';
        if (name === 'documentTypesList') selectedList = 'documentTypesList';
        if (name === 'deliveryMethodList') selectedList = 'deliveryMethodList';
        if (name === 'deliveryAddressList')
          selectedList = 'deliveryAddressList';
        if (name === 'checkFormList') selectedList = 'checkFormList';
        if (name === 'eobFormList') selectedList = 'eobFormList';

        if (name === 'checkFormList' || name === 'eobFormList') {
          firstValue = detailElements[selectedList].find(x =>
            x.value.includes(selectedValue),
          );
        } else {
          firstValue = detailElements[selectedList].find(x =>
            x.value.includes(selectedValue),
          );
        }

        const arr = firstValue
          ? [...new Set([firstValue, ...detailElements[selectedList]])]
          : detailElements[selectedList];
        arr.map(r => {
          if (name === 'checkFormList' || name === 'eobFormList') {
            if (r.value.includes(selectedValue)) {
              selectedCode = r.code;
            }
          } else if (r.value === selectedValue) {
            selectedCode = r.code;
          }
          return {
            label: r.value,
            value: r.code,
          };
        });
        return selectedCode;
      }
    }
  };

  getInitialValues = () => {
    const { detailElements, selectedRuleDetail, actionType } = this.props;
    let claimType = 'default';
    let recipientType = 'default';
    let documentType = 'default';
    let copyNumber = '';
    let SSN = '';
    let deliveryMethod = 'default';
    let deliveryAddress = 'default';
    let checkForm = 'default';
    let eobForm = 'default';

    if (
      !_.isEmpty(detailElements) &&
      !_.isEmpty(selectedRuleDetail) &&
      (actionType !== 'Add a')
    ) {
      if (actionType === 'Edit a' || actionType === 'Clone a')
        if (detailElements.claimTypesList && selectedRuleDetail.claimType) {
          claimType = detailElements.claimTypesList.find(
            o => o.value === selectedRuleDetail.claimType,
          ).code;
        }
      if (
        detailElements.recipientTypesList &&
        selectedRuleDetail.recipientType
      ) {
        recipientType = detailElements.recipientTypesList.find(
          o => o.value === selectedRuleDetail.recipientType,
        ).code;
      }
      if (
        detailElements.documentTypesList &&
        selectedRuleDetail.documentType !== 'Any'
      ) {
        documentType = detailElements.documentTypesList.find(
          o => o.value === selectedRuleDetail.documentType,
        ).code;
      } else {
        documentType = 'default';
      }
      if (
        detailElements.deliveryMethodList &&
        selectedRuleDetail.deliveryMethod
      ) {
        deliveryMethod = detailElements.deliveryMethodList.find(o =>
          o.value.includes(selectedRuleDetail.deliveryMethod),
        ).code;
      }
      if (
        detailElements.deliveryAddressList &&
        selectedRuleDetail.deliveryAddress
      ) {
        const isDeliveryAddressExists = detailElements.deliveryAddressList.find(
          o => o.value.includes(selectedRuleDetail.deliveryAddress),
        );
        deliveryAddress = !_.isEmpty(isDeliveryAddressExists) ? isDeliveryAddressExists.code : 'default';
      }
      if (detailElements.checkFormList && selectedRuleDetail.checkForm) {
        const isCheckFormExists = detailElements.checkFormList.find(
          o => o.value.includes(selectedRuleDetail.checkForm),
        );
        checkForm = !_.isEmpty(isCheckFormExists) ? isCheckFormExists.code : 'default';
      }
      if (detailElements.eobFormList && selectedRuleDetail.eobForm) {
        const isEobFormExists = detailElements.eobFormList.find(
          o => o.value.includes(selectedRuleDetail.eobForm),
        );
        eobForm = !_.isEmpty(isEobFormExists) ? isEobFormExists.code : 'default';
      }
      if (selectedRuleDetail.SSN) {
        const { SSN: ruleDetailSSN } = selectedRuleDetail;
        SSN = ruleDetailSSN;
      }
      if (selectedRuleDetail.copyNumber) {
        const { copyNumber: ruleDetailCopyNumber } = selectedRuleDetail;
        copyNumber = ruleDetailCopyNumber;
      }
      if (actionType === 'Read only') {
        if (detailElements.claimTypesList && selectedRuleDetail.claimType) {
          claimType = detailElements.claimTypesList.find(
            o => o.value === selectedRuleDetail.claimType,
          ).value;
        }
        if (
          detailElements.recipientTypesList &&
          selectedRuleDetail.recipientType
        ) {
          recipientType = detailElements.recipientTypesList.find(
            o => o.value === selectedRuleDetail.recipientType,
          ).value;
        }
        if (
          detailElements.documentTypesList &&
          selectedRuleDetail.documentType !== 'Any'
        ) {
          documentType = detailElements.documentTypesList.find(
            o => o.value === selectedRuleDetail.documentType,
          ).value;
        } else {
          documentType = 'default';
        }
        if (
          detailElements.deliveryMethodList &&
          selectedRuleDetail.deliveryMethod
        ) {
          const isDeliveryMethodExists = detailElements.deliveryMethodList.find(
            o => o.value.includes(selectedRuleDetail.deliveryMethod),
          );
          deliveryMethod = !_.isEmpty(isDeliveryMethodExists) ? isDeliveryMethodExists.value : 'default';
        }
        if (
          detailElements.deliveryAddressList &&
          selectedRuleDetail.deliveryAddress
        ) {
          const isDeliveryAddressExists = detailElements.deliveryAddressList.find(
            o => o.value.includes(selectedRuleDetail.deliveryAddress),
          );
          deliveryAddress = !_.isEmpty(isDeliveryAddressExists) ? isDeliveryAddressExists.value : 'default';
        }
        if (detailElements.checkFormList && selectedRuleDetail.checkForm) {
          const isCheckFormExists = detailElements.checkFormList.find(
            o => o.value.includes(selectedRuleDetail.checkForm),
          );
          checkForm = !_.isEmpty(isCheckFormExists) ? isCheckFormExists.value : 'default';
        }
        if (detailElements.eobFormList && selectedRuleDetail.eobForm) {
          const isEobFormExists = detailElements.eobFormList.find(
            o => o.value.includes(selectedRuleDetail.eobForm),
          );
          eobForm = !_.isEmpty(isEobFormExists) ? isEobFormExists.value : 'default';
        }
        if (selectedRuleDetail.SSN) {
          const { SSN: ruleDetailSSN } = selectedRuleDetail;
          SSN = ruleDetailSSN;
        }
        if (selectedRuleDetail.copyNumber) {
          const { copyNumber: ruleDetailCopyNumber } = selectedRuleDetail;
          copyNumber = ruleDetailCopyNumber;
        }
      }
      this.setState(
        {
          claimType,
          recipientType,
          documentType,
          copyNumber,
          SSN,
          deliveryMethod,
          deliveryAddress,
          checkForm,
          eobForm,
          isButtonDisabled: true,
        },
        () => {
          this.validateFormInputs('SSN');
          this.validateFormInputs('copyNumber');
          this.validateFormInputs('deliveryAddress');
        },
      );
    } else {
      this.setState(
        {
          claimType,
          recipientType,
          documentType,
          copyNumber,
          SSN,
          deliveryMethod,
          deliveryAddress,
          checkForm,
          eobForm,
          isButtonDisabled: true,
        },
        () => {
          this.validateFormInputs('SSN');
          this.validateFormInputs('copyNumber');
          this.validateFormInputs('deliveryAddress');
        },
      );
    }
  };

  getRuleVersion = record => {
    try {
      const { status, version } = record;
      const testVersion = version[status.indexOf('T')];
      if (testVersion) return testVersion;
      const liveVersion = version[status.indexOf('L')];
      return liveVersion;
    } catch (e) {
      console.log(e);
    }
    return null;
  };

  handleChangeFormat = input => {
    const inputValue = input.target.value;
    let formatValue = inputValue.replace(/^(\d)$/, '0$1');
    const regExpAlphaNumeric = /^[a-z0-9]+$/i;
    if (regExpAlphaNumeric.test(formatValue))
      formatValue = formatValue.replace(/(\b[a-z](?!\s))/g, (
        formatValue,
      ) => formatValue.toUpperCase());
    this.setState({
      copyNumber: formatValue,
    });
  };

  handleEnterPressed = event => {
    const { handleChangeFormat } = this;
    if (event.key === 'Enter') {
      handleChangeFormat(event);
    }
  };

  populateDataDropDown = name => {
    const { detailElements, selectedRuleDetail } = this.props;
    if (!_.isEmpty(detailElements)) {
      if (detailElements) {
        let options = [];
        let selectedList = '';
        const selectedValue = selectedRuleDetail[name];

        if (name === 'claimTypesList') selectedList = 'claimTypesList';
        if (name === 'recipientTypesList') selectedList = 'recipientTypesList';
        if (name === 'documentTypesList') selectedList = 'documentTypesList';
        if (name === 'deliveryMethodList') selectedList = 'deliveryMethodList';
        if (name === 'deliveryAddressList') selectedList = 'deliveryAddressList';
        if (name === 'checkFormList') selectedList = 'checkFormList';
        if (name === 'eobFormList') selectedList = 'eobFormList';

        const firstValue = detailElements[selectedList].find(x =>
          x.value.includes(selectedValue),
        );
        const arr = firstValue
          ? [...new Set([firstValue, ...detailElements[selectedList]])]
          : detailElements[selectedList];
        options = arr
          .map(r => ({
            label: r.value,
            value: r.code,
          }))
          .filter(v => v.value !== '');
        return options;
      }
    }
  };

  onCancel = () => {
    const {
      setIsCloningRuleDetail,
      setIsPostingRuleDetail,
      setIsShowingMemberIdModal,
      setIsReadOnlyRuleDetail
    } = this.props;
    setIsCloningRuleDetail(false);
    setIsPostingRuleDetail(false);
    setIsShowingMemberIdModal(false);
    setIsReadOnlyRuleDetail(false);
  };

  onSave = () => {
    const {
      isCloningRuleDetail,
      isPostingRuleDetail,
      postRuleDetails,
      putRuleDetails,
      selectedRuleDetail,
      selectedRuleRecord,
      selectedRuleTypeId,
      setIsCloningRuleDetail,
      setIsPostingRuleDetail,
      selectedRecord,
    } = this.props;
    const {
      claimType,
      recipientType,
      documentType,
      deliveryMethod,
      deliveryAddress,
      checkForm,
      eobForm,
      copyNumber,
      SSN,
    } = this.state;

    const record = selectedRuleRecord || selectedRecord;
    const ruleDetailId = selectedRuleDetail.id;
    const ruleId = record.id;
    const ruleVersion = this.getRuleVersion(record);
    const ruleType = selectedRuleTypeId;
    let ruleDetail = {
      claimType,
      recipientType,
      documentType,
      deliveryMethod,
      deliveryAddress,
      checkForm,
      eobForm,
      copyNumber,
      SSN,
    };
    ruleDetail = isCloningRuleDetail
      ? { ...ruleDetail, ...{ ruleDetailIdFrom: ruleDetailId } }
      : ruleDetail;
    if (isPostingRuleDetail || isCloningRuleDetail) {
      postRuleDetails({ ruleDetail, ruleType, ruleId, ruleVersion });
      setIsCloningRuleDetail(false);
      setIsPostingRuleDetail(false);
    } else {
      putRuleDetails({
        ruleDetail,
        ruleType,
        ruleId,
        ruleVersion,
        ruleDetailId,
      });
    }
  };

  validateFormInputs = inputField => {
    const { SSN, copyNumber, deliveryAddress } = this.state;
    if (inputField === 'copyNumber') {
      let copyNumberError = false;
      let copyNumberErrorMessage = '';
      const regExpDecimal = /^(\d?[1-9]|[1-9]0)$/gi;
      copyNumberError =
        copyNumber !== undefined
          ? !regExpDecimal.test(copyNumber)
          : copyNumberError;
      copyNumberErrorMessage =  copyNumberError ?   
        'Copy Number required. Must be 2 digit numeric value from 01 – 99.' : '';
      this.setState({
        copyNumberError,
        copyNumberErrorMessage,
      });
    }
    if (inputField === 'SSN') {
      let SSNError = false;
      let SSNErrorMessage = '';
      const regExpSSN = /^(?! |.* $)[A-Za-z0-9-_ ]{1,30}$/;
      SSNError = SSN !== undefined ? !regExpSSN.test(SSN) : SSNError;
      SSNErrorMessage = SSNError ? 'SSN required. Alphanumerics, _ and - accepted. Max 30 characters.' : '';
      this.setState({
        SSNError,
        SSNErrorMessage,
      });
    }
    if (inputField === 'deliveryAddress') {
      let deliveryAddressError = false;
      let deliveryAddressErrorMessage = '';
      if (deliveryAddress === 'default' || deliveryAddress === undefined) {
        deliveryAddressError = true;
      }
      deliveryAddressErrorMessage = deliveryAddressError ? 'You must select a delivery address.' : '';
      this.setState({
        deliveryAddressError,
        deliveryAddressErrorMessage,
      });
    }
  };

  setButtonDisableOnDefault(dbCodes) {
    const { actionType } = this.props;
    const {
      claimType,
      recipientType,
      documentType,
      deliveryMethod,
      deliveryAddress,
      checkForm,
      eobForm,
      copyNumber,
      SSN,
    } = this.state;
    let newState = false;
    if (
      (actionType === 'Edit a' &&
        (claimType === dbCodes.claimTypeDbCode &&
          recipientType === dbCodes.recipientTypeDbCode &&
          documentType === dbCodes.documentTypeDbCode &&
          deliveryMethod === dbCodes.deliveryMethodDbCode &&
          deliveryAddress === dbCodes.deliveryAddressDbCode &&
          checkForm === dbCodes.checkFormDbCode &&
          eobForm === dbCodes.eobFormDbCode &&
          copyNumber === dbCodes.copyNumberDbCode &&
          SSN === dbCodes.SSNDbCode)) ||
      (actionType === 'Clone a' &&
        (claimType === dbCodes.claimTypeDbCode &&
          recipientType === dbCodes.recipientTypeDbCode &&
          documentType === dbCodes.documentTypeDbCode &&
          copyNumber === dbCodes.copyNumberDbCode &&
          SSN === dbCodes.SSNDbCode))
    ) {
      newState = true;
    }
    this.setState({ isButtonDisabled: newState });
  }

  checkUnsavedChanges = event => {
    const { selectedRuleDetail } = this.props;
    const { populateCodeFromValue, validateFormInputs } = this;
    const claimTypeDbCode = populateCodeFromValue('claimTypesList');
    const recipientTypeDbCode = populateCodeFromValue('recipientTypesList');
    const documentTypeDbCode = populateCodeFromValue('documentTypesList');
    const deliveryMethodDbCode = populateCodeFromValue('deliveryMethodList');
    const deliveryAddressDbCode = populateCodeFromValue('deliveryAddressList');
    const checkFormDbCode = populateCodeFromValue('checkFormList');
    const eobFormDbCode = populateCodeFromValue('eobFormList');
    const SSNDbCode = selectedRuleDetail.SSN;
    const copyNumberDbCode = selectedRuleDetail.copyNumber;
    const dbCodes = {
      claimTypeDbCode,
      recipientTypeDbCode,
      documentTypeDbCode,
      deliveryMethodDbCode,
      deliveryAddressDbCode,
      checkFormDbCode,
      eobFormDbCode,
      SSNDbCode,
      copyNumberDbCode,
    };

    dbCodes.deliveryMethodDbCode = dbCodes.deliveryMethodDbCode === '' ? 'default' : dbCodes.deliveryMethodDbCode;
    dbCodes.checkFormDbCode = dbCodes.checkFormDbCode === '' ? 'default' : dbCodes.checkFormDbCode;
    dbCodes.eobFormDbCode = dbCodes.eobFormDbCode === '' ? 'default' : dbCodes.eobFormDbCode;

    if (event.target) {
      const { name, value } = event.target;
      this.setState(
        {
          [name]: value,
        },
        () => {
          validateFormInputs(name);
          this.setButtonDisableOnDefault(dbCodes);
        },
      );
    }
  };

  render() {
    const {
      checkUnsavedChanges,
      handleChangeFormat,
      handleEnterPressed,
    } = this;
    const {
      claimType,
      recipientType,
      documentType,
      copyNumber,
      SSN,
      deliveryMethod,
      deliveryAddress,
      checkForm,
      eobForm,
      isButtonDisabled,
      copyNumberError,
      SSNError,
      copyNumberErrorMessage,
      SSNErrorMessage,
      deliveryAddressError,
      deliveryAddressErrorMessage,
    } = this.state;
    const { loading, selectedRuleRecord, selectedRecord, actionType } = this.props;
    const recordId = selectedRuleRecord
      ? selectedRuleRecord.id
      : selectedRecord.id;
    const claimTypeOptions = this.populateDataDropDown('claimTypesList');
    const recipientTypeOptions = this.populateDataDropDown(
      'recipientTypesList',
    );
    const documentTypeOptions = this.populateDataDropDown('documentTypesList');
    const deliveryMethodListOptions = this.populateDataDropDown(
      'deliveryMethodList',
    );
    const deliveryAddressListOptions = this.populateDataDropDown(
      'deliveryAddressList',
    );
    const checkFormListOptions = this.populateDataDropDown('checkFormList');
    const eobFormListOptions = this.populateDataDropDown('eobFormList');

    if (deliveryAddressListOptions !== undefined) {
      const selectOption = { label: 'Select', value: 'default' };
      deliveryAddressListOptions.unshift(selectOption);
    }

    // FOR READ ONLY CONDITIONAL RENDERING (Live Rule)
    let claimTypeReadOnly;
    let recipientTypeReadOnly;
    let documentTypeReadOnly;
    let copyNumberReadOnly;
    let SSNReadOnly;
    let deliveryMethodReadOnly;
    let deliveryAddressReadOnly;
    let checkFormReadOnly;
    let eobFormReadOnly;
    if (actionType === 'Read only') {
      claimTypeReadOnly = (claimType !== 'default') ? claimType : 'Any';
      recipientTypeReadOnly = (recipientType !== 'default') ? recipientType : 'Any';
      documentTypeReadOnly = (documentType !== 'default') ? documentType : 'Any';
      copyNumberReadOnly = (copyNumber !== '') ? copyNumber : '-';
      SSNReadOnly = (SSN !== '') ? SSN : '-';
      deliveryMethodReadOnly = (deliveryMethod !== 'default') ? deliveryMethod : 'Originally Specified Delivery Type';
      deliveryAddressReadOnly = (deliveryAddress !== 'default') ? deliveryAddress : '-';
      checkFormReadOnly = (checkForm !== 'default') ? checkForm : '-';
      eobFormReadOnly = (eobForm !== 'default') ? eobForm : '-';
    }


    return (
      <>
        <ContainerBox>
          {actionType !== 'Read only' ? (
            <DropDownControl
              dropDownDomID="member-id-claim-type"
              dropDownLabel="claim type"
              dropDownName="claimType"
              dropDownOptions={claimTypeOptions}
              dropDownInitialValue={claimType}
              dropDownOnChangeHandle={checkUnsavedChanges}
            />
          ) : <ReadOnlyTextTitle>Claim Type<ReadOnlyTextArea>{claimTypeReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
          {actionType !== 'Read only' ? (
            <DropDownControl
              dropDownDomID="member-id-recipient-type"
              dropDownLabel="recipient"
              dropDownName="recipientType"
              dropDownOptions={recipientTypeOptions}
              dropDownInitialValue={recipientType}
              dropDownOnChangeHandle={checkUnsavedChanges}
            />
          ) : <ReadOnlyTextTitle>Recipient<ReadOnlyTextArea>{recipientTypeReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
          {actionType !== 'Read only' ? (
            <DropDownControl
              dropDownDomID="member-id-document-type"
              dropDownLabel="document type"
              dropDownName="documentType"
              dropDownOptions={documentTypeOptions}
              dropDownInitialValue={documentType}
              dropDownOnChangeHandle={checkUnsavedChanges}
            />
          ) : <ReadOnlyTextTitle>Document Type<ReadOnlyTextArea>{documentTypeReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
        </ContainerBox>
        <ContainerBox className="member-id-copy-number-wrap">
          {actionType !== 'Read only' ? (
            <InputControl
              inputDomID="member-id-copy-number"
              inputLabel="copy number"
              inputName="copyNumber"
              inputInitialValue={copyNumber}
              inputStartWithBlankValue={false}
              inputOnChangeHandle={checkUnsavedChanges}
              inputDisabled={false}
              inputIsHovered={false}
              inputErrorMessage={copyNumberErrorMessage}
              inputHasError={copyNumberError}
              inputStyles={{ width: '100px', marginBottom: '12px' }}
              inputOnBlurHandle={handleChangeFormat}
              inputOnKeyDown={handleEnterPressed}
            />
          ) : <ReadOnlyTextTitle>Copy Number<ReadOnlyTextArea>{copyNumberReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
          {actionType !== 'Read only' ? (
            <CopyNumberCaption>(99 all copies)</CopyNumberCaption>
          ) : null }
        </ContainerBox>
        <ContainerBox className="member-id-ssn-number-wrap">
          {actionType !== 'Read only' ? (
            <InputControl
              inputDomID="member-id-ssn-number"
              inputLabel="social security number"
              inputName="SSN"
              inputInitialValue={SSN}
              inputStartWithBlankValue={false}
              inputOnChangeHandle={checkUnsavedChanges}
              inputDisabled={false}
              inputIsHovered={false}
              inputErrorMessage={SSNErrorMessage}
              inputHasError={SSNError}
              inputStyles={{ marginBottom: '12px' }}
              inputOnBlurHandle={() => null}
              inputOnKeyDown={() => null}
            />
          ) : <ReadOnlyTextTitle>Social Security Number<ReadOnlyTextArea>{SSNReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
        </ContainerBox>
        <ContainerBox>
          {actionType !== 'Read only' ? (
            <DropDownControl
              dropDownDomID="member-id-delivery-method"
              dropDownLabel="delivery method"
              dropDownName="deliveryMethod"
              dropDownOptions={deliveryMethodListOptions}
              dropDownInitialValue={deliveryMethod}
              dropDownOnChangeHandle={checkUnsavedChanges}
              dropDownStyles={{ width: '400px' }}
            />
          ) : <ReadOnlyTextTitle>Delivery Method<ReadOnlyTextArea>{deliveryMethodReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
        </ContainerBox>
        <ContainerBox>
          {actionType !== 'Read only' ? (
            <DropDownControl
              className="member-id-delivery"
              dropDownDomID="member-id-delivery-address"
              dropDownLabel="delivery address"
              dropDownName="deliveryAddress"
              dropDownOptions={deliveryAddressListOptions}
              dropDownInitialValue={deliveryAddress}
              dropDownOnChangeHandle={checkUnsavedChanges}
              dropDownStyles={{ width: '400px' }}
              dropDownHasError={deliveryAddressError}
              dropDownErrorMessage={deliveryAddressErrorMessage}
              inputStyles={{ marginBottom: '12px' }}
            />
          ) : <ReadOnlyTextTitle>Delivery Address<ReadOnlyTextArea>{deliveryAddressReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
        </ContainerBox>
        <ContainerBox>
          {actionType !== 'Read only' ? (
            <DropDownControl
              dropDownDomID="member-id-alternative-check-form"
              dropDownLabel="alternative check form"
              dropDownName="checkForm"
              dropDownOptions={checkFormListOptions}
              dropDownInitialValue={checkForm}
              dropDownOnChangeHandle={checkUnsavedChanges}
              dropDownStyles={{ width: '400px' }}
            />
          ) : <ReadOnlyTextTitle>Alternative Check Form<ReadOnlyTextArea>{checkFormReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
        </ContainerBox>
        <ContainerBox>
          {actionType !== 'Read only' ? (
            <DropDownControl
              dropDownDomID="member-id-alternative-eob-form"
              dropDownLabel="alternative eob form"
              dropDownName="eobForm"
              dropDownOptions={eobFormListOptions}
              dropDownInitialValue={eobForm}
              dropDownOnChangeHandle={checkUnsavedChanges}
              dropDownStyles={{ width: '400px' }}
            />
          ) : <ReadOnlyTextTitle>Alternative EOB Form<ReadOnlyTextArea>{eobFormReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
        </ContainerBox>
        <ButtonGroupBottom>
          <ModalButton>
            {loading.has(recordId) && (
              <LoadingWrapper>
                <LoadingIndicator
                  domID="basic-details-form-loading-indicator"
                  length="30px"
                />
              </LoadingWrapper>
            )}
            {actionType !== 'Read only' ? (
              <Button
                onClick={() => this.onSave()}
                domID="automation-id"
                name="Save"
                buttonType="emphasized"
                size="medium"
                isDropdown={false}
                type="button"
                disabled={
                  isButtonDisabled ||
                  loading.has(recordId) ||
                  SSNError ||
                  copyNumberError ||
                  deliveryAddressError
                }
              />
            ) : null }  
            <Button
              domID="automation-id"
              name={actionType !== 'Read only' ? 'Cancel' : 'Close'}
              buttonType="diminished"
              disabled={loading.has(recordId)}
              size="medium"
              onClick={() => this.onCancel()}
              isDropdown={false}
              type="button"
            />
          </ModalButton>
        </ButtonGroupBottom>
      </>
    );
  }
}

MemberIdModal.propTypes = {
  detailElements: PropTypes.object,
  loading: PropTypes.object,
  selectedRuleRecord: PropTypes.object,
  selectedRuleDetail: PropTypes.object,
  setIsCloningRuleDetail: PropTypes.func,
  setIsPostingRuleDetail: PropTypes.func,
  setIsShowingMemberIdModal: PropTypes.func,
  isShowingMemberIdModal: PropTypes.bool,
  isCloningRuleDetail: PropTypes.bool,
  isPostingRuleDetail: PropTypes.bool,
  postRuleDetails: PropTypes.func,
  putRuleDetails: PropTypes.func,
  actionType: PropTypes.string,
  selectedRecord: PropTypes.object,
  selectedRuleTypeId: PropTypes.string,
};
export default MemberIdModal;
