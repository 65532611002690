// Header props
export const headerProps = {
  amountKey: 'amount',
  domID: 'header',
};

const CLONE = 'clone';
const COMMIT = 'commit';

export const CLONE_BUTTON = [CLONE];
export const COMMIT_BUTTON_SET = [COMMIT];
export const CLONE_COMMIT_BUTTON_SET = [CLONE, COMMIT];
