// Import the action types we are going to respond to with this reducer
import {
  RULES_FOR_RULETYPES_PUT_ERROR,
  RULES_FOR_RULETYPES_PUT_SUCCESS,
  RULES_FOR_RULETYPES_PUT_REQUEST_TRIGGER,
  SET_IS_POSTING_RULES_FOR_RULETYPES,
  RULES_FOR_RULETYPES_POST_REQUEST_TRIGGER,
  RULES_FOR_RULETYPES_POST_SUCCESS,
  RULES_FOR_RULETYPES_POST_ERROR,
  SET_IS_CLONING_RULE_STATE,
  SET_IS_SHOWING_CLONE_RULE_MODAL,
  SET_RECORD_FOR_DETAIL_PANE,
  SET_RULE_TYPE_COMMIT_MODAL_OPEN,
  COMMIT_RULE_FOR_RULE_TYPE_SUCCESS,
  COMMIT_RULE_FOR_RULETYPE_ERROR,
  SET_IS_COMMITTING_RULE,
  SET_IS_SHOWING_ACTIVE_GROUPS_LISTING_MODAL,
  SET_IS_SHOWING_REVERT_RULE_MODAL,
  GET_REVERT_RULE_VERSION_TRIGGER,
  GET_REVERT_RULE_VERSION_SUCCESS,
  GET_REVERT_RULE_VERSION_ERROR,
  POST_REVERT_RULE_VERSION_TRIGGER,
  POST_REVERT_RULE_VERSION_SUCCESS,
  POST_REVERT_RULE_VERSION_ERROR,
  RESET_STATE,
} from './constants';

// Initial state expresses the data type of this key in the store (in this case
// store.rulesForRuleTypesList will be an Array). This object is exported separately to
// make unit testing easier
export const INITIAL_STATE = {
  isCommittingRule: false,
  isPostingRulesForRuleTypes: false,
  showCloneRuleModal: false,
  isCloningRule: false,
  recordForDetailPane: null,
  ruleTypeCommitRuleModalOpen: false,
  ruleIdArray: [],
  showActiveGroupsListingModal: false,
  selectedActiveRecordsList: {},
  selectedRecordName: '',
  activeGroupsModalLocation: '',
  revertRuleVersionsList: null,
  isShowingRevertRuleModal: false,
  ruleVersionRequest: null
};

// This is the actual "reducer". It is a function that will run every time an
// action is dispatched by the store, but only return updated state values if
// it matches the action's type in the switch statement.
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RULES_FOR_RULETYPES_PUT_REQUEST_TRIGGER:
      return {
        ...state,
      };

    case RULES_FOR_RULETYPES_PUT_SUCCESS:
      return {
        ...state,
      };

    case RULES_FOR_RULETYPES_PUT_ERROR:
      return {
        ...state,
      };

    case RULES_FOR_RULETYPES_POST_REQUEST_TRIGGER:
      return {
        ...state,
      };

    case RULES_FOR_RULETYPES_POST_SUCCESS:
      return {
        ...state,
      };

    case RULES_FOR_RULETYPES_POST_ERROR:
      return {
        ...state,
      };

    case SET_IS_POSTING_RULES_FOR_RULETYPES:
      return {
        ...state,
        isPostingRulesForRuleTypes: action.payload,
      };

    case SET_IS_CLONING_RULE_STATE:
      return {
        ...state,
        isCloningRule: action.payload,
        ruleVersionRequest: null // made this null so that it wont affect versions after cloning any rule, as it should be set in case of revert rule only
      };

    case SET_IS_SHOWING_CLONE_RULE_MODAL:
      return {
        ...state,
        showCloneRuleModal: action.payload,
      };

    case SET_RECORD_FOR_DETAIL_PANE:
      return {
        ...state,
        recordForDetailPane: action.payload,
      };

    case SET_RULE_TYPE_COMMIT_MODAL_OPEN:
      return {
        ...state,
        ruleTypeCommitRuleModalOpen: action.payload,
      };

    case COMMIT_RULE_FOR_RULE_TYPE_SUCCESS:
      return {
        ...state,
      };

    case COMMIT_RULE_FOR_RULETYPE_ERROR:
      return {
        ...state,
      };

    case SET_IS_COMMITTING_RULE:
      return {
        ...state,
        isCommittingRule: action.payload,
      };

    case SET_IS_SHOWING_ACTIVE_GROUPS_LISTING_MODAL:
      return {
        ...state,
        showActiveGroupsListingModal: action.payload.showActiveGroupsListingModal,
        selectedActiveRecordsList: action.payload.selectedActiveRecordsList,
        selectedRecordName: action.payload.selectedRecordName,
        activeGroupsModalLocation: action.payload.activeGroupsModalLocation
      };
    case SET_IS_SHOWING_REVERT_RULE_MODAL:
      return {
        ...state,
        isShowingRevertRuleModal: action.payload
      };

    case GET_REVERT_RULE_VERSION_TRIGGER:
      return {
        ...state,
      };
  
    case GET_REVERT_RULE_VERSION_SUCCESS:
      return {
        ...state,
        revertRuleVersionsList: action.payload
      };
  
    case GET_REVERT_RULE_VERSION_ERROR:
      return {
        ...state
      };
    case POST_REVERT_RULE_VERSION_TRIGGER: 
      return {
        ...state,
        ruleVersionRequest: 'progress',
      };

    case POST_REVERT_RULE_VERSION_SUCCESS: 
      return {
        ...state,
        ruleVersionRequest: 'success',
        ruleVersion: action.payload.ruleVersion
      };
    
    case POST_REVERT_RULE_VERSION_ERROR: 
      return {
        ...state,
        ruleVersionRequest: 'failed',
      }
    case RESET_STATE:
      return {
        isCommittingRule: false,
        isPostingRulesForRuleTypes: false,
        showCloneRuleModal: false,
        isCloningRule: false,
        revertRuleVersionsList: null,
        isShowingRevertRuleModal: false,
        ruleVersionRequest: null,
        recordForDetailPane: null,
        ruleTypeCommitRuleModalOpen: false,
        ruleIdArray: [],
        showActiveGroupsListingModal: false,
        selectedActiveRecordsList: {},
        selectedRecordName: '',
        activeGroupsModalLocation: '',
      };
    default:
      // Most of the actions in our app will be ignored by this reducer, so the
      // default case is to return the existing state
      return state;
  }
};
