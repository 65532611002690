import {  useCallback, useEffect, useRef, useState } from 'react';


function useStateCallback(initialState) {
  const [state, _setState] = useState(initialState);

  const callbackRef = useRef();
  const isFirstCallbackCall = useRef(true);

  const setStateFunction = useCallback((setStateAction, callback) => {
    callbackRef.current = callback;
    _setState(setStateAction);
  }, []);

  useEffect(() => {
    if (isFirstCallbackCall.current) {
      isFirstCallbackCall.current = false;
      return;
    }
    if (callbackRef && callbackRef.current) {
      callbackRef.current(state);    
    }
    callbackRef.current=undefined;
  }, [state]);

  return [state, setStateFunction];
}

export default useStateCallback;