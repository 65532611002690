import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  useLayout,
  ListPaneHeader,
  Button, Input, DatePickerV2, Modal, ModalHeader, ButtonGroup, ModalBody
} from 'ui-core/dist'
import axios from '../../axios';
import useNotificationBannerHook from '../../useNotificationBannerHook';

import { LoadingPage } from '../../components';

import './modules/style.scss'

const OffHoursActivityReport = () => {
  const {
    expandCollapseListPaneButtonConfig
  } = useLayout()
  const { notify } = useNotificationBannerHook();
  const [isLoading, setIsLoading] = useState(false);
  const [resolutionModal, setResolutionModal] = useState(false);
  const { dataSourceDesc } = useSelector(({ context }) => context?.context || {});

  const cLInfo = dataSourceDesc.split('-')[0].trim();
  const clientId = cLInfo.split('_')[0].trim();
  const LayoutId = cLInfo.split('_')[1].trim();
  const [acsReportCriteria, setacsReportCriteria] = useState({
    emailAddress: "", reportFormat: "",
    clientId: clientId, LayoutId: LayoutId, fromDate: "", toDate: ""
  });

  const [count, setCount] = useState(0);
  const [email, setEmail] = useState();
  const [reportFormat, setReportFormat] = useState();
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  const [offHoursFrom, setOffHoursFrom] = useState('07:30 PM');
  const [offHoursTo, setOffHoursTo] = useState('06:00 AM');

  const showModal = async () => {
    if (!ValidatestartAndDate()) {
      notify(`From Date should be less than To Date.`, 'caution')
    } else {
      setResolutionModal(true)
    }
  }
  const submitReport = async () => {
    setIsLoading(true);
    const objTosave = {
      "startDate": fromDate,
      "endDate": toDate,
      "emailId": email,
      "startTime": offHoursFrom,
      "endTime": offHoursTo,
    };
    try {
      const res = await axios.post(
        `reports-manager/off-hours-activity-report`,
        objTosave
      )
      setIsLoading(false);
      setResolutionModal(false);
      setOffHoursFrom('07:30 PM');
      setOffHoursTo('06:00 AM');
      setEmail('')
      setFromDate('')
      setToDate('')
      setCount(count + 1)
      notify(`Off Hours Activity Report Web Transaction ${res?.data?.reportId} has been submitted. If you have not received this report within six(6)hours, please contact client service  via customer care hub (https://customercare.changehealthcare.com/public/home.html). If you don't have access to customer care hub, please contact your internal administrator.`, 'positive')
    } catch (err) {
      setResolutionModal(false);
      notify(`${err.displayMessage}`, 'negative');
      setIsLoading(false);
    }
  }

  const ValidateEmail = () => {
    return (/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(email) ? false : true);
  }
  const resetActivityForm = () => {
    setOffHoursFrom('07:30 PM');
    setOffHoursTo('06:00 AM');
    setEmail('')
    setFromDate('')
    setToDate('')
    setCount(count + 1)
  }
  const ValidatestartAndDate = () => {
    let startDate = new Date(fromDate);
    let endDate = new Date(toDate);
    return startDate > endDate ? false : true;
  }

  const validateStartTime = () => {
    return (/(^(?:0?[1-9]:[0-5]|1(?=[012])\d:[0-5])\d( )?(AM|am|aM|Am|PM|pm|pM|Pm))$/.test(offHoursFrom) ? false : true);
  }

  const validateEndTime = () => {
    return (/(^(?:0?[1-9]:[0-5]|1(?=[012])\d:[0-5])\d( )?(AM|am|aM|Am|PM|pm|pM|Pm))$/.test(offHoursTo) ? false : true);
  }

  const rendeOffHoursStartTime = useMemo(() => (
    <Input
      label="Off Hours Start Time - Format as HH:MM (CDT):"
      domID="tpf1"
      errorMessage="Please enter a valid off hours Starting Time."
      initialValue={offHoursFrom}
      hasError={validateStartTime()}
      onChange={(e, { value }) => {
        setOffHoursFrom(value);
      }}
      size="small"
    />
  ), [offHoursFrom]);

  const rendeOffHoursToTime = useMemo(() => (
    <Input
      label="Off Hours End Time - Format as HH:MM (CDT):"
      domID="tpf2"
      errorMessage="Please enter a valid off hours Ending Time."
      hasError={validateEndTime()}
      initialValue={offHoursTo}
      onChange={(e, { value }) => {
        setOffHoursTo(value);
      }}
      size="small"
    />
  ), [offHoursTo]);

  const renderEmailAdres = useMemo(() => (
    <Input
      label="Email Address for Delivery of Report:"
      aria-label="ARIA Label"
      autoComplete="off"
      dataTestId="test-inputBasic"
      domID="basic-test-id"
      errorMessage="Please enter a valid email address"
      hasError={ValidateEmail()}
      name="emailAddress"
      maxLength={50}
      initialValue={email}
      onChange={(e) => {
        setEmail(e.target.value || "");
      }}
      size="small"
    />
  ), [email]);
  const rendeStartDate = useMemo(() => (
    <DatePickerV2
      key={count}
      label="From Date:"
      domID="renderstartDatePicker"
      errorMessage="Please enter a valid From Date"
      hasError={fromDate ? false : true}
      initialValue={fromDate}
      onDateChange={(e) => {
        setFromDate(e ? e.format('MM/DD/YYYY') : '')
      }}
      size="small"
    />
  ), [fromDate, count]);
  const rendeEndDate = useMemo(() => (
    <DatePickerV2
      key={count}
      label="To Date:"
      domID="renderEndDatePicker"
      errorMessage="Please enter a valid To Date"
      hasError={toDate ? false : true}
      initialValue={toDate}
      onDateChange={(e) => {
        setToDate(e ? e.format('MM/DD/YYYY') : '')
      }}
      size="small"
    />
  ), [toDate, count]);

  const validateForm = useMemo(() => {
    if (!email || ValidateEmail() || !fromDate || !toDate) {
      return false;
    }
    return true;
  }, [email, fromDate, toDate]);



  return (
    <>
      <ListPaneHeader
        title={`Off Hours Activity Report Request`}
        layoutControls={[expandCollapseListPaneButtonConfig]}
      />
      <div className="marginLeftUserReport">
        <table id="userReportSearch">
          <tr>
            <td className='userReportcriteriaWidth'>
              <div style={{ display: "flex" }}>
                {rendeOffHoursStartTime}
              </div>
            </td>
            <td className='userReportcriteriaWidth'>
              <div style={{ display: "flex" }}>
                {rendeOffHoursToTime}
              </div>
            </td>
          </tr>
          <tr>
            <td className='userReportcriteriaWidth'>
              {rendeStartDate}
            </td>
            <td className='userReportcriteriaWidth'>
              {rendeEndDate}
            </td>
          </tr>
          <tr>
            <td className='userReportcriteriaWidth'>
              {renderEmailAdres}
            </td>
          </tr>
          <tr><td>

            <ButtonGroup>
              <Button
                buttonType="standard"
                size="small"
                name="Submit"
                disabled={!validateForm}
                onClick={() => { showModal() }}
              />
              <Button
                domID="endUserSessionresetButton"
                name="Reset"
                size="small"
                buttonType="standard"
                onClick={() => { resetActivityForm() }}
              />
            </ButtonGroup>
          </td><td></td></tr>
        </table>

        <Modal isOpen={resolutionModal}>
          <ModalHeader
            title="Do you want to submit?"
            onClose={() => setResolutionModal(false)}
          />
          <ModalBody>
            <div className="end-session-modal">
              {
                isLoading ? (
                  // replace with a better loading component
                  <LoadingPage />
                ) :
                  (<ButtonGroup>
                    <Button
                      domID="endUserSessionCancelButton"
                      name="Cancel"
                      size="small"
                      buttonType="standard"
                      onClick={() => { setResolutionModal(false) }}
                    />
                    <Button
                      domID="endUserSessionConfirmButton"
                      name="Confirm"
                      size="small"
                      buttonType="standard"
                      onClick={() => { submitReport() }}
                    />
                  </ButtonGroup>)
              }
            </div>
          </ModalBody>
        </Modal>
      </div>
    </>
  )
}

export default OffHoursActivityReport;