export const setEditPayerEftAccount=(payload)=>({
  type:'SET_EDIT_PAYER_EFT_ACCOUNT',
  payload
})
  
export const resetEditPayerEftAccount=()=>({
  type:'RESET_EDIT_PAYER_EFT_ACCOUNT'
})

export const setOriginalPayerEftAccount=(payload)=>({
  type:'SET_ORIGINAL_PAYER_EFT_ACCOUNT',
  payload
})

export const resetOriginalPayerEftAccount=()=>({
  type: 'RESET_ORIGINAL_PAYER_EFT_ACCOUNT'
})

export const setModePayerEftAccount=(payload)=>({
  type: 'SET_MODE_PAYER_EFT_ACCOUNT',
  payload
})
