import React from 'react';
import PropTypes from 'prop-types';
import RulesTabHeader from '../RulesTabHeader';
import EditTestTab from '../EditTestTab';
import LiveRuleTab from '../LiveRuleTab';
import { AssociateTab } from '../AssociateTab';
import DetailCloneRuleModal from '../DetailCloneRuleModal';
import RevertRuleModal from '../../../RuleDetailModals/RevertRuleModal';

const DefaultView = ({
  associatedGroupRecordsNoTermedRecords,
  currentTab,
  detailElements,
  gridConfig,
  addNotification,
  updateCurrentTab,
  deleteNotification,
  getRuleDetails,
  getRuleDetailElements,
  goBackFromAdvancedToDefaultView,
  groupAssociationsForRuleId,
  groupAssociationsForRuleIdTrigger,
  isCloningRuleDetail,
  isEditingRuleDetail,
  isExpanded,
  isPostingRuleDetail,
  isPostingRulesForRuleTypes,
  isShowingProductionArrangementModal,
  isShowingCustomerServiceMessageModal,
  isShowingReturnAddressModal,
  isShowingDuplexRuleModal,
  isShowingSignatureRuleModal,
  isShowingCheckTextRuleModal,
  isShowingImageRuleModal,
  isShowingAutoHoldModal,
  isShowingMemberIdModal,
  isShowingTaxIdArrangementRuleModal,
  isShowingErisaMessageModal,
  isShowingHighDollarReviewModal,
  isShowingProviderInsuredOptionMessageModal,
  isShowingGeneralMessageModal,
  isShowingPDFTaggingModal,
  loading,
  notifications,
  postRuleDetails,
  putRuleDetails,
  selectedRecord,
  selectedRecordStatus,
  selectedRuleDetail,
  selectedRuleRecord,
  selectedRuleTypeId,
  setIsCloningRuleDetail,
  setIsEditingRuleDetail,
  setIsReadOnlyRuleDetail,
  setIsPostingRuleDetail,
  setIsShowingProductionArrangementModal,
  setIsShowingCustomerServiceMessageModal,
  setIsShowingReturnAddressModal,
  setIsShowingDuplexRuleModal,
  setIsShowingSignatureRuleModal,
  setIsShowingCheckTextRuleModal,
  setIsShowingImageRuleModal,
  setIsShowingAutoHoldModal,
  setIsShowingMemberIdModal,
  setIsShowingTaxIdArrangementRuleModal,
  setIsShowingErisaMessageModal,
  setShowingHighDollarReviewModal,
  setIsShowingProviderInsuredOptionMessageModal,
  setIsShowingGeneralMessageModal,
  setIsShowingPDFTaggingModal,
  setIsViewingRuleDetails,
  setSelectedRuleDetail,
  toggleDetailsView,
  triggerAllRulesForRuleTypesRequest,
  triggerRulesForRuleTypesPutRequest,
  triggerRulesForRuleTypesPostRequest,
  setIsPostingRulesForRuleTypes,
  records,
  testImageCount,
  resetDetailsPane,
  rulesForRuleTypesList,
  isShowingDeleteRuleDetailModal,
  setIsShowingDeleteRuleDetailModal,
  deleteRuleDetailTrigger,
  setIsCloningRuleState,
  setIsShowingDetailCloneRuleModal,
  isCloningRule,
  showDetailCloneRuleModal,
  setRuleTypeCommitModalOpen,
  ruleTypeCommitRuleModalOpen,
  triggerCommitRuleForRuleType,
  overwriteTestRuleRequestTrigger,
  setIsShowingAddNewAddressModal,
  isShowingAddNewAddressModal,
  getAddNewAddressElements,
  returnAddressElements,
  postAddNewAddressElements,
  setIsShowingOverwriteModal,
  isShowingOverwriteModal,
  errorNotifications,
  setIsShowingImageLibraryModal,
  isShowingImageLibraryModal,
  setIsShowingImageUploadModal,
  isShowingImageUploadModal,
  postImageUpload,
  setIsShowingActiveGroupsListingModal,
  showActiveGroupsListingModal,
  selectedActiveRecordsList,
  selectedRecordName,
  activeGroupsModalLocation,
  getRuleImages,
  ruleImages,
  getRuleImagesCount,
  ruleImagesCount,
  resetRuleImagesData,
  convertImageUpload,
  convertedImageUploadData,
  convertedImageUploadError,
  postImageUploadSuccessId,
  postCommitImage,
  setIsShowingCommitImageModal,
  isShowingCommitImageModal,
  postImageCommitSuccessId,
  postCommitImageTriggerCancel,
  captureCurrentPage,
  userRole,
  userPermissions,
  ruleDetails,
  getRevertRuleVersions,
  revertRuleVersionsList,
  setIsShowingRevertRuleModal,
  isShowingRevertRuleModal,
  postRevertRuleVersion,
  userType,
  rulesForRuleTypes,
  userRoleId
}) => selectedRecord && (
  <div>
    <RulesTabHeader
      setRuleTypeCommitModalOpen={setRuleTypeCommitModalOpen}
      addNotification={addNotification}
      deleteNotification={deleteNotification}
      notifications={notifications}
      selectedRuleRecord={selectedRuleRecord}
      selectedRuleRecordStatus={selectedRecordStatus}
      toggleDetailsView={toggleDetailsView}
      currentTab={currentTab}
      isExpanded={isExpanded}
      isShowingProductionArrangementModal={
        isShowingProductionArrangementModal
      }
      isShowingCustomerServiceMessageModal={
        isShowingCustomerServiceMessageModal
      }
      updateCurrentTab={updateCurrentTab}
      setIsViewingRuleDetails={setIsViewingRuleDetails}
      setIsShowingProductionArrangementModal={
        setIsShowingProductionArrangementModal
      }
      setIsShowingCustomerServiceMessageModal={
        setIsShowingCustomerServiceMessageModal
      }
      setIsPostingRulesForRuleTypes={setIsPostingRulesForRuleTypes}
      isPostingRulesForRuleTypes={isPostingRulesForRuleTypes}
      setIsCloningRuleState={setIsCloningRuleState}
      setIsShowingDetailCloneRuleModal={setIsShowingDetailCloneRuleModal}
      isCloningRule={isCloningRule}
      showDetailCloneRuleModal={showDetailCloneRuleModal}
      resetDetailsPane={resetDetailsPane}
      getRevertRuleVersions={getRevertRuleVersions}
      setIsShowingRevertRuleModal={setIsShowingRevertRuleModal}
      selectedRuleTypeId={selectedRuleTypeId}
      userType={userType}
      userRoleId={userRoleId}
    />

    <div>
      {showDetailCloneRuleModal && (
        <DetailCloneRuleModal
          addNotification={addNotification}
          selectedRecord={selectedRecord}
          setIsShowingDetailCloneRuleModal={
            setIsShowingDetailCloneRuleModal
          }
          setIsCloningRuleState={setIsCloningRuleState}
          showDetailCloneRuleModal={showDetailCloneRuleModal}
          isCloningRule={isCloningRule}
          toggleDetailsView={toggleDetailsView}
          resetDetailsPane={resetDetailsPane}
          isExpanded={isExpanded}
          updateCurrentTab={updateCurrentTab}
          triggerRuleDetailsRequest={getRuleDetails}
          selectedRuleTypeId={selectedRuleTypeId}
        />
      )}
      {isShowingRevertRuleModal && (
        <RevertRuleModal
          loading={loading}
          selectedRuleRecord={selectedRuleRecord}
          setIsShowingRevertRuleModal={setIsShowingRevertRuleModal}
          isShowingRevertRuleModal={isShowingRevertRuleModal}
          revertRuleVersionsList={revertRuleVersionsList}
          postRevertRuleVersion={postRevertRuleVersion}
          selectedRuleTypeId={selectedRuleTypeId}
        />
      )}
      {
        [
          <EditTestTab
            triggerCommitRuleForRuleType={triggerCommitRuleForRuleType}
            setRuleTypeCommitModalOpen={setRuleTypeCommitModalOpen}
            ruleTypeCommitRuleModalOpen={ruleTypeCommitRuleModalOpen}
            addNotification={addNotification}
            deleteNotification={deleteNotification}
            detailElements={detailElements}
            getRuleDetails={getRuleDetails}
            getRuleDetailElements={getRuleDetailElements}
            gridConfig={gridConfig}
            isCloningRuleDetail={isCloningRuleDetail}
            isEditingRuleDetail={isEditingRuleDetail}
            isPostingRuleDetail={isPostingRuleDetail}
            isShowingReturnAddressModal={isShowingReturnAddressModal}
            isShowingProductionArrangementModal={
              isShowingProductionArrangementModal
            }
            isShowingCustomerServiceMessageModal={
              isShowingCustomerServiceMessageModal
            }
            isShowingDuplexRuleModal={isShowingDuplexRuleModal}
            isShowingSignatureRuleModal={isShowingSignatureRuleModal}
            isShowingCheckTextRuleModal={isShowingCheckTextRuleModal}
            isShowingImageRuleModal={isShowingImageRuleModal}
            isShowingAutoHoldModal={isShowingAutoHoldModal}
            isShowingMemberIdModal={isShowingMemberIdModal}
            isShowingTaxIdArrangementRuleModal={
              isShowingTaxIdArrangementRuleModal
            }
            isShowingErisaMessageModal={isShowingErisaMessageModal}
            isShowingHighDollarReviewModal={isShowingHighDollarReviewModal}
            isShowingProviderInsuredOptionMessageModal={
              isShowingProviderInsuredOptionMessageModal
            }
            isShowingGeneralMessageModal={isShowingGeneralMessageModal}
            isShowingPDFTaggingModal={isShowingPDFTaggingModal}
            postRuleDetails={postRuleDetails}
            putRuleDetails={putRuleDetails}
            selectedRecord={selectedRecord}
            selectedRecordStatus={selectedRecordStatus}
            selectedRuleRecord={selectedRuleRecord}
            selectedRuleTypeId={selectedRuleTypeId}
            setIsCloningRuleDetail={setIsCloningRuleDetail}
            setIsEditingRuleDetail={setIsEditingRuleDetail}
            setIsReadOnlyRuleDetail={setIsReadOnlyRuleDetail}
            setIsPostingRuleDetail={setIsPostingRuleDetail}
            setIsShowingProductionArrangementModal={
              setIsShowingProductionArrangementModal
            }
            setIsShowingCustomerServiceMessageModal={
              setIsShowingCustomerServiceMessageModal
            }
            setIsShowingReturnAddressModal={setIsShowingReturnAddressModal}
            setIsShowingDuplexRuleModal={setIsShowingDuplexRuleModal}
            setIsShowingSignatureRuleModal={setIsShowingSignatureRuleModal}
            setIsShowingCheckTextRuleModal={setIsShowingCheckTextRuleModal}
            setIsShowingImageRuleModal={setIsShowingImageRuleModal}
            setIsShowingAutoHoldModal={setIsShowingAutoHoldModal}
            setIsShowingMemberIdModal={setIsShowingMemberIdModal}
            setIsShowingTaxIdArrangementRuleModal={
              setIsShowingTaxIdArrangementRuleModal
            }
            setIsShowingErisaMessageModal={setIsShowingErisaMessageModal}
            setShowingHighDollarReviewModal={
              setShowingHighDollarReviewModal
            }
            setIsShowingProviderInsuredOptionMessageModal={
              setIsShowingProviderInsuredOptionMessageModal
            }
            setIsShowingGeneralMessageModal={
              setIsShowingGeneralMessageModal
            }
            setIsShowingPDFTaggingModal={setIsShowingPDFTaggingModal}
            setSelectedRuleDetail={setSelectedRuleDetail}
            setIsPostingRulesForRuleTypes={setIsPostingRulesForRuleTypes}
            toggleDetailsView={toggleDetailsView}
            isExpanded={isExpanded}
            loading={loading}
            notifications={notifications}
            selectedRuleDetail={selectedRuleDetail}
            triggerAllRulesForRuleTypesRequest={
              triggerAllRulesForRuleTypesRequest
            }
            triggerRulesForRuleTypesPutRequest={
              triggerRulesForRuleTypesPutRequest
            }
            triggerRulesForRuleTypesPostRequest={
              triggerRulesForRuleTypesPostRequest
            }
            rulesForRuleTypesList={rulesForRuleTypesList}
            records={records}
            testImageCount = {testImageCount}
            isPostingRulesForRuleTypes={isPostingRulesForRuleTypes}
            goBackFromAdvancedToDefaultView={
              goBackFromAdvancedToDefaultView
            }
            isShowingDeleteRuleDetailModal={isShowingDeleteRuleDetailModal}
            setIsShowingDeleteRuleDetailModal={
              setIsShowingDeleteRuleDetailModal
            }
            deleteRuleDetailTrigger={deleteRuleDetailTrigger}
            setIsShowingDetailCloneRuleModal={
              setIsShowingDetailCloneRuleModal
            }
            setIsCloningRuleState={setIsCloningRuleState}
            isCloningRule={isCloningRule}
            showDetailCloneRuleModal={showDetailCloneRuleModal}
            setIsShowingAddNewAddressModal={setIsShowingAddNewAddressModal}
            isShowingAddNewAddressModal={isShowingAddNewAddressModal}
            getAddNewAddressElements={getAddNewAddressElements}
            returnAddressElements={returnAddressElements}
            postAddNewAddressElements={postAddNewAddressElements}
            errorNotifications={errorNotifications}
            setIsShowingImageLibraryModal={setIsShowingImageLibraryModal}
            isShowingImageLibraryModal={isShowingImageLibraryModal}
            setIsShowingImageUploadModal={setIsShowingImageUploadModal}
            isShowingImageUploadModal={isShowingImageUploadModal}
            postImageUpload={postImageUpload}
            setIsShowingActiveGroupsListingModal={setIsShowingActiveGroupsListingModal}
            showActiveGroupsListingModal={showActiveGroupsListingModal}
            selectedActiveRecordsList={selectedActiveRecordsList}
            selectedRecordName={selectedRecordName}
            activeGroupsModalLocation={activeGroupsModalLocation}
            getRuleImages={getRuleImages}
            ruleImages={ruleImages}
            getRuleImagesCount={getRuleImagesCount}
            ruleImagesCount={ruleImagesCount}
            resetRuleImagesData={resetRuleImagesData}
            convertImageUpload={convertImageUpload}
            convertedImageUploadData={convertedImageUploadData}
            convertedImageUploadError={convertedImageUploadError}
            postImageUploadSuccessId={postImageUploadSuccessId}
            postCommitImage={postCommitImage}
            setIsShowingCommitImageModal={setIsShowingCommitImageModal}
            isShowingCommitImageModal={isShowingCommitImageModal}
            postImageCommitSuccessId={postImageCommitSuccessId}
            postCommitImageTriggerCancel={postCommitImageTriggerCancel}
            captureCurrentPage={captureCurrentPage}
            userRole={userRole}
            userPermissions={userPermissions}
            ruleDetails={ruleDetails}
            rulesForRuleTypes={rulesForRuleTypes}
          />,
          <LiveRuleTab
            addNotification={addNotification}
            deleteNotification={deleteNotification}
            selectedRecord={selectedRecord}
            selectedRecordStatus={selectedRecordStatus}
            toggleDetailsView={toggleDetailsView}
            isExpanded={isExpanded}
            isPostingRulesForRuleTypes={isPostingRulesForRuleTypes}
            isCloningRule={isCloningRule}
            loading={loading}
            notifications={notifications}
            gridConfig={gridConfig}
            triggerRulesForRuleTypesPutRequest={
              triggerRulesForRuleTypesPutRequest
            }
            overwriteTestRuleRequestTrigger={
              overwriteTestRuleRequestTrigger
            }
            currentTab={currentTab}
            setSelectedRuleDetail={setSelectedRuleDetail}
            setIsShowingOverwriteModal={setIsShowingOverwriteModal}
            isShowingOverwriteModal={isShowingOverwriteModal}
            selectedRuleRecord={selectedRuleRecord}
            selectedRuleTypeId={selectedRuleTypeId}
            getRuleDetails={getRuleDetails}
            records={records}
            getRuleDetailElements={getRuleDetailElements}
            setIsShowingCustomerServiceMessageModal={setIsShowingCustomerServiceMessageModal}
            setIsShowingCheckTextRuleModal={setIsShowingCheckTextRuleModal}
            isShowingCustomerServiceMessageModal={isShowingCustomerServiceMessageModal}
            isShowingErisaMessageModal={isShowingErisaMessageModal}
            setIsShowingErisaMessageModal={setIsShowingErisaMessageModal}
            setIsShowingSignatureRuleModal={setIsShowingSignatureRuleModal}
            isShowingCheckTextRuleModal={isShowingCheckTextRuleModal}
            setIsShowingProductionArrangementModal={setIsShowingProductionArrangementModal}
            isShowingProductionArrangementModal={isShowingProductionArrangementModal}
            setShowingHighDollarReviewModal={setShowingHighDollarReviewModal}
            isShowingHighDollarReviewModal={isShowingHighDollarReviewModal}
            setIsShowingMemberIdModal={setIsShowingMemberIdModal}
            isShowingMemberIdModal={isShowingMemberIdModal}
            isShowingSignatureRuleModal={isShowingSignatureRuleModal}
            isShowingPDFTaggingModal={isShowingPDFTaggingModal}
            setIsShowingPDFTaggingModal={setIsShowingPDFTaggingModal}
            setIsEditingRuleDetail={setIsEditingRuleDetail}
            setIsReadOnlyRuleDetail={setIsReadOnlyRuleDetail}
            setIsCloningRuleDetail={setIsCloningRuleDetail}
            setIsPostingRuleDetail={setIsPostingRuleDetail}
            selectedRuleDetail={selectedRuleDetail}
            detailElements={detailElements}
            isEditingRuleDetail={isEditingRuleDetail}
            captureCurrentPage={captureCurrentPage}
            setIsShowingImageRuleModal={setIsShowingImageRuleModal}
            setIsShowingReturnAddressModal={setIsShowingReturnAddressModal}
            isShowingImageRuleModal={isShowingImageRuleModal}
            isShowingReturnAddressModal={isShowingReturnAddressModal}
            isShowingTaxIdArrangementRuleModal={isShowingTaxIdArrangementRuleModal}
            setIsShowingTaxIdArrangementRuleModal={setIsShowingTaxIdArrangementRuleModal}
            ruleDetails={ruleDetails}
            setIsShowingGeneralMessageModal={setIsShowingGeneralMessageModal}
            isShowingGeneralMessageModal={isShowingGeneralMessageModal}
            setIsShowingProviderInsuredOptionMessageModal={setIsShowingProviderInsuredOptionMessageModal}
            isShowingProviderInsuredOptionMessageModal={isShowingProviderInsuredOptionMessageModal}
            setIsShowingAutoHoldModal={setIsShowingAutoHoldModal}
            isShowingAutoHoldModal={isShowingAutoHoldModal}
            setIsShowingDuplexRuleModal={setIsShowingDuplexRuleModal}
            isShowingDuplexRuleModal={isShowingDuplexRuleModal}
            rulesForRuleTypes={rulesForRuleTypes}
          />,
          <AssociateTab
            groupAssociationsForRuleIdTrigger={
              groupAssociationsForRuleIdTrigger
            }
            isPostingRulesForRuleTypes={isPostingRulesForRuleTypes}
            isCloningRule={isCloningRule}
            selectedRecord={selectedRecord}
            loading={loading}
            deleteNotification={deleteNotification}
            notifications={notifications}
            groupAssociationsForRuleId={groupAssociationsForRuleId}
            associatedGroupRecordsNoTermedRecords={
              associatedGroupRecordsNoTermedRecords
            }
            selectedRuleTypeId={selectedRuleTypeId}
            captureCurrentPage={captureCurrentPage}
          />,
        ][currentTab]
      }
    </div>
  </div>
);

DefaultView.defaultProps = {
  selectedRecord: null,
};

DefaultView.propTypes = {
  deleteNotification: PropTypes.func,
  isPostingRulesForRuleTypes: PropTypes.bool,
  loading: PropTypes.object,
  notifications: PropTypes.object,
  setIsPostingRulesForRuleTypes: PropTypes.func,
  goBackFromAdvancedToDefaultView: PropTypes.func,
  rulesForRuleTypesList: PropTypes.object,
  triggerAllRulesForRuleTypesRequest: PropTypes.func,
  triggerRulesForRuleTypesPostRequest: PropTypes.func,
  records: PropTypes.array,
  groupAssociationsForRuleIdTrigger: PropTypes.func,
  groupAssociationsForRuleId: PropTypes.array,
  associatedGroupRecordsNoTermedRecords: PropTypes.array,
  toggleDetailsView: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool,
  selectedRecord: PropTypes.shape({
    id: PropTypes.string,
  }),
  selectedRecordStatus: PropTypes.array,
  setIsViewingRuleDetails: PropTypes.func.isRequired,
  triggerRulesForRuleTypesPutRequest: PropTypes.func.isRequired,
  currentTab: PropTypes.number,
  updateCurrentTab: PropTypes.func.isRequired,
  isShowingDeleteRuleDetailModal: PropTypes.bool,
  setIsShowingDeleteRuleDetailModal: PropTypes.func,
  convertedImageUploadData: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  convertedImageUploadError: PropTypes.string,
  postImageUploadSuccessId:PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  postCommitImage: PropTypes.func,
  setIsShowingCommitImageModal: PropTypes.func,
  isShowingCommitImageModal: PropTypes.object,
  postImageCommitSuccessId: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  postCommitImageTriggerCancel: PropTypes.func,
};

export default DefaultView;