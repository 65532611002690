import React, {useMemo, useEffect} from 'react';
import { 
  ButtonGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody
} from 'ui-core/dist'
// import { useNotificationCenter } from 'react-toastify/addons/use-notification-center';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import useInactivityTracker from '../../hooks/useInactivityTracker'
import useLogout from '../../hooks/useLogout'
import axios from '../../axios';
import {DEFAULT_USER_SESSION_TIME} from '../../containers/InactivityTracker/modules/constants'

const InactivityTracker = () => {
  const [showtoast, setTime, setLastAccess]=useInactivityTracker()
  const logout = useLogout()
  // const {clear}=useNotificationCenter()
  
  const onContinueSession=async()=>{
    await axios.post('/users/continueSession')
    setLastAccess(new Date())
    setTime(DEFAULT_USER_SESSION_TIME)
  }
  
  return (
    <>
      {/* {showtoast ?<ToastContainer hideProgressBar={true} autoClose={false} />:null} */}
      {showtoast?(
        <Modal isOpen={showtoast}>
        <ModalHeader 
          title="Session Timeout"
          
        />
        <ModalBody>
          <div className="end-session-modal">
            <p><span className="--bold">You are about to be logged out in 30sec.</span></p>
            <p>Do you want to continue ?</p>
            <ButtonGroup>
              <Button 
                domID="endUserSessionCancelButton"
                name="Yes"
                onClick={onContinueSession}
                size="small"
                buttonType="standard"
              />
              <Button 
                domID="endUserSessionConfirmButton"
                name="No"
                onClick={()=>logout()}
                size="small"
                buttonType="standard"
              />
            </ButtonGroup>
          </div>
        </ModalBody>
      </Modal>
      ): null}
    </>
  )
}

export default InactivityTracker