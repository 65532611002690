import { useAxios } from '../hooks'

const useFileTrackerLabels = (props={}) => {
  const { data, ...rest } = useAxios({
    ...props,
    url: '/fileTracker/fields',
    method: 'GET',
  })

  return { labels: data, ...rest }
}

export default useFileTrackerLabels