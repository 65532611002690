import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Datetime from 'react-datetime';
import moment from 'moment';
import {
  Grid,
  Input,
  useLayout,
  ListPaneHeader,
  Button,
  ButtonGroup
} from 'ui-core/dist'
import axios from '../../axios'
import useReportBuilder from '../../api/useReportBuilder'
import useReportBuilderFields from '../../api/useReportBuilderFields'
import useFileTrackerLabels from '../../api/useFileTrackerLabels'
import ListPaneHeaderWrapper from '../../components/ErrorDisplaySection/ListPaneHeaderWrapper';
import { AnchorInput, SelectDropdown, GridPaginationWrapper, Loading, LoadingPage } from '../../components'
import ErrorPage from '../ErrorPage';
import useNotificationBannerHook from '../../useNotificationBannerHook';
import withAxiosCancellation from '../../components/withAxiosCancellation'
import './modules/styles.scss'

const ReportBuilderListPane = ({getCancelSourceWithName}) => {
  const { notify } = useNotificationBannerHook()
  const canAccess = useSelector(({ context }) =>
    context?.permissions?.filter(p => p.name === 'CAN_ACCESS_AUDIT_REPORT').length > 0)
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [pageIndex, setPageIndex] = useState(1);
  const [idx, setIdx] = useState(0)
  const [caseNumber, setCaseNumber] = useState("")
  const [docStatus, setDocStatus] = useState("")
  const [fileKey, setFileKey] = useState("")
  const [fileName, setFileName] = useState("")
  const [billName, setBillName] = useState("")
  const [recipientType, setRecipientType] = useState("")
  const [deliveryType, setDeliveryType] = useState("")
  const [issuedState, setIssuedState] = useState("")
  const [carrier, setCarrier] = useState("")
  const [owningCarrier, setOwningCarrier] = useState("")
  const [language, setLanguage] = useState("")
  const [productType, setProductType] = useState("")
  const [ncoa, setNcoa] = useState("")
  const [reportType, setReportType] = useState({
    label: "Standard Report",
    value: "RBStandardReport",
  })
  const [sortBy, setSortBy] = useState('claimNum')
  const [sortOrder, setSortOrder] = useState('ASC')
  const [hierarchy2, setHierarchy2] = useState("")
  const [importStartDate, setImportStartDate] = useState(moment().subtract(30, "days").format("MM/DD/YYYY"))
  const [importEndDate, setImportEndDate] = useState(moment().format("MM/DD/YYYY"))
  const [readyStartDate, setReadyStartDate] = useState('')
  const [readyEndDate, setReadyEndDate] = useState('')
  const [mailedStartDate, setMailedStartDate] = useState('')
  const [mailedEndDate, setMailedEndDate] = useState('')
  const [enoteStartDate, setEnoteStartDate] = useState('')
  const [enoteEndDate, setEnoteEndDate] = useState('')
  const [dropdowns, setDropdowns] = useState('');

  const [datesError, setDatesError] = useState(false)
  const [readyError, setReadyError] = useState(false)
  const [mailError, setMailError] = useState(false)
  const [enoteError, setEnoteError] = useState(false)
  const [errMsg, setErrMsg] = useState('')
  const [mailErrMsg, setMailErrMsg] = useState('')
  const [readyErrMsg, setReadyErrMsg] = useState('')
  const [enoteErrMsg, setEnoteErrMsg] = useState('')

  const [datesEndError, setDatesEndError] = useState(false)
  const [readyEndError, setReadyEndError] = useState(false)
  const [mailEndError, setMailEndError] = useState(false)
  const [enoteEndError, setEnoteEndError] = useState(false)
  const [errEndMsg, setErrEndMsg] = useState('')
  const [mailErrEndMsg, setMailErrEndMsg] = useState('')
  const [readyErrEndMsg, setReadyErrEndMsg] = useState('')
  const [enoteErrEndMsg, setEnoteErrEndMsg] = useState('')

  const [isReportLoading, setIsReportLoading] = useState(false)
  const [isDocLoading, setIsDocLoading] = useState(false)

  const [payload, setPayload] = useState({
    maxRecords: rowsPerPage,
    rowPosition: pageIndex,
    claimNum: caseNumber,
    docStatus: docStatus,
    claimType: billName ? billName.value : '',
    recptType: recipientType,
    delvType: deliveryType,
    recptState: issuedState,
    hierarchy3: language ? language.value : '',
    hierarchy4: carrier ? carrier.value : '',
    hierarchy5: owningCarrier ? owningCarrier.value : '',
    procesrId: productType,
    fileKey,
    fileName,
    hierarchy2: hierarchy2 ? hierarchy2.value : '',
    impFrmDt: importStartDate,
    impToDt: importEndDate,
    readyPrntFrmDt: readyStartDate,
    readyPrntToDt: readyEndDate,
    mailFrmDt: mailedStartDate,
    mailToDt: mailedEndDate,
    entFrmDt: enoteStartDate,
    entToDt: enoteEndDate,
    ncoa,
    reportType: reportType ? reportType.value : '',
    cacheName,
    sortBy,
    sortOrder
  })
  const { expandCollapseListPaneButtonConfig } = useLayout()
  const { data, isLoading, total, cacheName, showMsg, isError, error } = useReportBuilder({ payload, getCancelSource:getCancelSourceWithName('reportBuilder') })
  const { fields, isLoading: fieldsLoading } = useReportBuilderFields({getCancelSource:getCancelSourceWithName('reportBuilderFields')})
  const { labels, isLoading: labelsLoading } = useFileTrackerLabels({getCancelSource:getCancelSourceWithName('fileTrackerLabels')})

  const onDocStatusChange = (state) => setDocStatus(state)
  const onBillNameChange = (state) => setBillName(state)
  const onRecipientTypeChange = (state) => setRecipientType(state)
  const onDeliveryTypeChange = (state) => setDeliveryType(state)
  const onIssuedStateChange = (state) => setIssuedState(state)
  const onCarrierChange = (state) => setCarrier(state)
  const onOwningCarrierChange = (state) => setOwningCarrier(state)
  const onLanguageChange = (state) => setLanguage(state)
  const onHierarchy2Change = (state) => setHierarchy2(state)
  const onNcoaChange = (state) => setNcoa(state)
  const onReportTypeChange = (state) => setReportType(state)
  const onCaseNumberChange = (e, state) => setCaseNumber(state.value)
  const onFileKeyChange = (e, state) => setFileKey(state.value)
  const onFileNameChange = (e, state) => setFileName(state.value)
  const onProductTypeChange = (e, state) => setProductType(state.value)

  useEffect(() => {
    if (!dropdowns) {
      setDropdowns(fields);
    }
  }, [fields]);

  const searchClick = () => {
    setIsReportLoading(false);
    setIsDocLoading(false);
    setRowsPerPage(50)
    setPageIndex(1)
    setIdx(idx + 1);
    setPayload({
      maxRecords: 50,
      rowPosition: 1,
      claimNum: caseNumber,
      docStatus: docStatus ? docStatus.value : '',
      claimType: billName ? billName.value : '',
      recptType: recipientType ? recipientType.value : '',
      delvType: deliveryType ? deliveryType.value : '',
      recptState: issuedState ? issuedState.value : '',
      hierarchy3: language ? language.value : '',
      hierarchy4: carrier ? carrier.value : '',
      hierarchy5: owningCarrier ? owningCarrier.value : '',
      procesrId: productType,
      fileKey,
      fileName,
      hierarchy2: hierarchy2 ? hierarchy2.value : '',
      impFrmDt: importStartDate,
      impToDt: importEndDate,
      readyPrntFrmDt: readyStartDate,
      readyPrntToDt: readyEndDate,
      mailFrmDt: mailedStartDate,
      mailToDt: mailedEndDate,
      entFrmDt: enoteStartDate,
      entToDt: enoteEndDate,
      ncoa: ncoa ? ncoa.value : '',
      reportType: reportType ? reportType.value : '',
      idx,
      sortOrder: 'ASC',
      sortBy: 'claimNum'
    })
  }
  const clearClick = () => {
    setIdx(idx + 60);
    setCaseNumber("")
    setDocStatus({})
    setFileKey("")
    setFileName("")
    setBillName({})
    setRecipientType({})
    setDeliveryType({})
    setIssuedState({})
    setCarrier({})
    setOwningCarrier({})
    setLanguage({})
    setHierarchy2({})
    setProductType("")
    setNcoa({})
    setImportStartDate(moment().subtract(30, "days").format("MM/DD/YYYY"))
    setImportEndDate(moment().format("MM/DD/YYYY"))
    setReadyStartDate()
    setReadyEndDate()
    setMailedStartDate()
    setMailedEndDate()
    setEnoteStartDate()
    setEnoteEndDate()
    setReportType({
      label: "Standard Report",
      value: "RBStandardReport",
    });

    setDatesError(false);
    setErrMsg('')
    setMailError(false);
    setMailErrMsg('');
    setReadyError(false);
    setReadyErrMsg('');
    setEnoteError(false);
    setEnoteErrMsg('');

    setDatesEndError(false);
    setErrEndMsg('')
    setMailEndError(false);
    setMailErrEndMsg('');
    setReadyEndError(false);
    setReadyErrEndMsg('');
    setEnoteEndError(false);
    setEnoteErrEndMsg('');
  }

  const onSortGridColumn = (e, { sortingKey }) => {
    const splitKey = sortingKey.split('|')
    setSortBy(splitKey[0])
    if (splitKey[1] === 'SORT_DESCENDING') setSortOrder('DESC')
    else setSortOrder('ASC')
    setPayload({
      ...payload,
      sortBy: splitKey[0],
      sortOrder: splitKey[1] === 'SORT_DESCENDING' ? 'DESC' : 'ASC',
      cacheName,
    })
  }

  const handleShowButton = ({ pi }) => {
    setPageIndex(pi)
    setPayload({
      maxRecords: rowsPerPage,
      rowPosition: pi,
      claimNum: caseNumber,
      docStatus: docStatus ? docStatus.value : '',
      claimType: billName ? billName.value : '',
      recptType: recipientType ? recipientType.value : '',
      delvType: deliveryType ? deliveryType.value : '',
      recptState: issuedState ? issuedState.value : '',
      hierarchy3: language ? language.value : '',
      hierarchy4: carrier ? carrier.value : '',
      hierarchy5: owningCarrier ? owningCarrier.value : '',
      procesrId: productType,
      fileKey,
      fileName,
      hierarchy2: hierarchy2 ? hierarchy2.value : '',
      impFrmDt: importStartDate,
      impToDt: importEndDate,
      readyPrntFrmDt: readyStartDate,
      readyPrntToDt: readyEndDate,
      mailFrmDt: mailedStartDate,
      mailToDt: mailedEndDate,
      entFrmDt: enoteStartDate,
      entToDt: enoteEndDate,
      ncoa,
      reportType: reportType ? reportType.value : '',
      cacheName,
      sortBy,
      sortOrder
    })
  }
  const downloadReportClick = async (docKey) => {
    try {
      setIsReportLoading(true);
      const res = await axios.get(`/reportBuilder/exportDocUrl?cacheName=${cacheName}&reportType=${reportType && reportType.value}`, { responseType: 'blob' })
      if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
        window.navigator.msSaveOrOpenBlob(res.data, `${reportType.label}.zip`);
      } else {
        const url = URL.createObjectURL(res.data);
        const element = document.createElement('a');
        element.setAttribute('href', url);
        element.setAttribute('download', `${reportType.label}.zip`);
        element.click();
      }
      setIsReportLoading(false);
    } catch (err) {
      setIsReportLoading(false);
      notify('Failed to export document at the moment. Please try again', "negative")
    }
  }
  const onDocKeyAnchorClick = async (docKey) => {
    try {
      setIsDocLoading(true);
      const res = await axios.get(`/fileTracker/pdfUrl?docKey=${docKey}`, { responseType: 'blob' })
      if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
        window.navigator.msSaveOrOpenBlob(res.data, 'ReportBuilderDocument.pdf');
      } else {
        const url = URL.createObjectURL(res.data);
        const tab = window.open();
        if(tab)
          tab.location.href = url;
        else
          notify('Please enable allow window pop-up in your browser.','caution')
      }
      setIsDocLoading(false);
    } catch (err) {
      setIsDocLoading(false);
      notify('Failed to export PDF at the moment. Please try again', "negative")
    }
  }

  const sortingComparator = (sortDataName, sortDirectionString, recordsToSort) => recordsToSort

  const importStartDateValidation = (currentDate) => {
    const momentStartDuration = moment().subtract(10, 'years');

    let err = false;
    if (moment(currentDate).isBefore(momentStartDuration)) {
      err = true;
    } else if (moment(currentDate).isAfter(moment(importEndDate).startOf('day'), 'day')) {
      err = true;
    } else if (moment(currentDate).isAfter(moment().startOf('day'), 'day')) {
      err = true;
    }

    return !err;
  };

  const importEndDateValidation = (currentDate) => {
    const momentStartDuration = moment().subtract(10, 'years');

    let err = false;
    if (moment(currentDate).isBefore(momentStartDuration)) {
      err = true;
    } else if (moment(currentDate).isAfter(moment().startOf('day'), 'day')) {
      err = true;
    } else if (moment(currentDate).isBefore(moment(importStartDate).startOf('day'), 'day')) {
      err = true;
    }

    return !err;
  };

  const mailedStartDateValidation = (currentDate) => {
    const momentStartDuration = moment().subtract(10, 'years');

    let err = false;
    if (moment(currentDate).isBefore(momentStartDuration)) {
      err = true;
    } else if (moment(currentDate).isAfter(moment(mailedEndDate).startOf('day'), 'day')) {
      err = true;
    } else if (moment(currentDate).isAfter(moment().startOf('day'), 'day')) {
      err = true;
    }

    return !err;
  };

  const mailedEndDateValidation = (currentDate) => {
    const momentStartDuration = moment().subtract(10, 'years');

    let err = false;
    if (moment(currentDate).isBefore(momentStartDuration)) {
      err = true;
    } else if (moment(currentDate).isAfter(moment().startOf('day'), 'day')) {
      err = true;
    } else if (moment(currentDate).isBefore(moment(mailedStartDate).startOf('day'), 'day')) {
      err = true;
    }

    return !err;
  };

  const readyStartDateValidation = (currentDate) => {
    const momentStartDuration = moment().subtract(10, 'years');

    let err = false;
    if (moment(currentDate).isBefore(momentStartDuration)) {
      err = true;
    } else if (moment(currentDate).isAfter(moment(readyEndDate).startOf('day'), 'day')) {
      err = true;
    } else if (moment(currentDate).isAfter(moment().startOf('day'), 'day')) {
      err = true;
    }

    return !err;
  };

  const readyEndDateValidation = (currentDate) => {
    const momentStartDuration = moment().subtract(10, 'years');

    let err = false;
    if (moment(currentDate).isBefore(momentStartDuration)) {
      err = true;
    } else if (moment(currentDate).isAfter(moment().startOf('day'), 'day')) {
      err = true;
    } else if (moment(currentDate).isBefore(moment(readyStartDate).startOf('day'), 'day')) {
      err = true;
    }

    return !err;
  };

  const emailStartDateValidation = (currentDate) => {
    const momentStartDuration = moment().subtract(10, 'years');

    let err = false;
    if (moment(currentDate).isBefore(momentStartDuration)) {
      err = true;
    } else if (moment(currentDate).isAfter(moment(enoteEndDate).startOf('day'), 'day')) {
      err = true;
    } else if (moment(currentDate).isAfter(moment().startOf('day'), 'day')) {
      err = true;
    }

    return !err;
  };

  const emailEndDateValidation = (currentDate) => {
    const momentStartDuration = moment().subtract(10, 'years');

    let err = false;
    if (moment(currentDate).isBefore(momentStartDuration)) {
      err = true;
    } else if (moment(currentDate).isAfter(moment().startOf('day'), 'day')) {
      err = true;
    } else if (moment(currentDate).isBefore(moment(enoteStartDate).startOf('day'), 'day')) {
      err = true;
    }

    return !err;
  };

  const onImportStartDateChange = (val) => {
    let err = false;
    let errMessage = '';
    const momentStartDuration = moment().subtract(10, 'years');

    if (val === '') {
      setDatesError(false);
      setImportStartDate('');
      return;
    }

    if (!moment(val, 'MM/DD/YYYY', true).isValid() || moment(val).isBefore(momentStartDuration)) {
      errMessage = 'Import start date is invalid(mm/dd/yyyy, within 10 years).';
      err = true;
    } else if (moment(importEndDate, 'MM/DD/YYYY', true).isValid() && moment(val).isAfter(moment(importEndDate))) {
      errMessage = 'Import start date should be earlier than the end date.';
      err = true;
    } else if (moment(val).isAfter(moment().startOf('day'), 'day')) {
      errMessage = 'Import start date is invalid(mm/dd/yyyy, max date is today).';
      err = true;
    }

    if (err) {
      setDatesError(true);
      setErrMsg(errMessage);
      setImportStartDate(val);
    } else {
      setDatesError(false);
      setImportStartDate(val.format("MM/DD/YYYY"));
    }
  }

  const onImportEndDateChange = (val) => {
    let err = false;
    let errMessage = '';
    const momentStartDuration = moment().subtract(10, 'years');

    if (val === '') {
      setDatesEndError(false);
      setImportEndDate('');
      return;
    }

    if (!moment(val, 'MM/DD/YYYY', true).isValid() || moment(val).isBefore(momentStartDuration)) {
      err = true;
      errMessage = 'Import end date is invalid(mm/dd/yyyy, within 10 years).'
    } else if (moment(importStartDate, 'MM/DD/YYYY', true).isValid() && moment(val).isBefore(moment(importStartDate))) {
      errMessage = 'Import end date should be greater than the start date.';
      err = true;
    } else if (moment(val).isAfter(moment().startOf('day'), 'day')) {
      errMessage = 'Import end date is invalid(mm/dd/yyyy, max date is today).';
      err = true;
    }

    if (err) {
      setDatesEndError(true);
      setErrEndMsg(errMessage);
      setImportEndDate(val);
    } else {
      setDatesEndError(false);
      setImportEndDate(val.format("MM/DD/YYYY"));
    }
  }

  const onMailedStartDateChange = (val) => {
    let err = false;
    let errMessage = '';
    const momentStartDuration = moment().subtract(10, 'years');

    if (val === '') {
      setMailError(false);
      setMailedStartDate('');
      return;
    }

    if (!moment(val, 'MM/DD/YYYY', true).isValid() || moment(val).isBefore(momentStartDuration)) {
      errMessage = 'Mailed start date is invalid(mm/dd/yyyy, within 10 years).';
      err = true;
    } else if (moment(mailedEndDate, 'MM/DD/YYYY', true).isValid() && moment(val).isAfter(moment(mailedEndDate))) {
      errMessage = 'Mailed start date should be earlier than the end date.';
      err = true;
    } else if (moment(val).isAfter(moment().startOf('day'), 'day')) {
      errMessage = 'Mailed start date is invalid(mm/dd/yyyy, max date is today).';
      err = true;
    }

    if (err) {
      setMailError(true);
      setMailErrMsg(errMessage);
      setMailedStartDate(val);
    } else {
      setMailError(false);
      setMailedStartDate(val.format("MM/DD/YYYY"));
    }
  }

  const onMailedEndDateChange = (val) => {
    let err = false;
    let errMessage = '';
    const momentStartDuration = moment().subtract(10, 'years');

    if (val === '') {
      setMailEndError(false);
      setMailedEndDate('');
      return;
    }

    if (!moment(val, 'MM/DD/YYYY', true).isValid() || moment(val).isBefore(momentStartDuration)) {
      err = true;
      errMessage = 'Mailed end date is invalid(mm/dd/yyyy, within 10 years).'
    } else if (moment(mailedStartDate, 'MM/DD/YYYY', true).isValid() && moment(val).isBefore(moment(mailedStartDate))) {
      errMessage = 'Mailed end date should be greater than the start date.';
      err = true;
    } else if (moment(val).isAfter(moment().startOf('day'), 'day')) {
      errMessage = 'Mailed end date is invalid(mm/dd/yyyy, max date is today).';
      err = true;
    }


    if (err) {
      setMailEndError(true);
      setMailErrEndMsg(errMessage);
      setMailedEndDate(val);
    } else {
      setMailEndError(false);
      setMailedEndDate(val.format("MM/DD/YYYY"));
    }
  }

  const onReadyStartDateChange = (val) => {
    let err = false;
    let errMessage = '';
    const momentStartDuration = moment().subtract(10, 'years');

    if (val === '') {
      setReadyError(false);
      setReadyStartDate('');
      return;
    }

    if (!moment(val, 'MM/DD/YYYY', true).isValid() || moment(val).isBefore(momentStartDuration)) {
      errMessage = 'Ready For Print start date is invalid(mm/dd/yyyy, within 10 years).';
      err = true;
    } else if (moment(readyEndDate, 'MM/DD/YYYY', true).isValid() && moment(val).isAfter(moment(readyEndDate))) {
      errMessage = 'Ready For Print start date should be earlier than the end date.';
      err = true;
    } else if (moment(val).isAfter(moment().startOf('day'), 'day')) {
      errMessage = 'Ready For Print start date is invalid(mm/dd/yyyy, max date is today).';
      err = true;
    }

    if (err) {
      setReadyError(true);
      setReadyErrMsg(errMessage);
      setReadyStartDate(val);
    } else {
      setReadyError(false);
      setReadyStartDate(val.format("MM/DD/YYYY"));
    }
  }

  const onReadyEndDateChange = (val) => {
    let err = false;
    let errMessage = '';
    const momentStartDuration = moment().subtract(10, 'years');

    if (val === '') {
      setReadyEndError(false);
      setReadyEndDate('');
      return;
    }

    if (!moment(val, 'MM/DD/YYYY', true).isValid() || moment(val).isBefore(momentStartDuration)) {
      err = true;
      errMessage = 'Ready For Print end date is invalid(mm/dd/yyyy, within 10 years).'
    } else if (moment(readyStartDate, 'MM/DD/YYYY', true).isValid() && moment(val).isBefore(moment(readyStartDate))) {
      errMessage = 'Ready For Print end date should be greater than the start date.';
      err = true;
    } else if (moment(val).isAfter(moment().startOf('day'), 'day')) {
      errMessage = 'Ready For Print end date is invalid(mm/dd/yyyy, max date is today).';
      err = true;
    }

    if (err) {
      setReadyEndError(true);
      setReadyErrEndMsg(errMessage);
      setReadyEndDate(val);
    } else {
      setReadyEndError(false);
      setReadyEndDate(val.format("MM/DD/YYYY"));
    }
  }

  const onEmailStartDateChange = (val) => {
    let err = false;
    let errMessage = '';
    const momentStartDuration = moment().subtract(10, 'years');

    if (val === '') {
      setEnoteError(false);
      setEnoteStartDate('');
      return;
    }

    if (!moment(val, 'MM/DD/YYYY', true).isValid() || moment(val).isBefore(momentStartDuration)) {
      errMessage = 'Enote Delivery start date is invalid(mm/dd/yyyy, within 10 years).';
      err = true;
    } else if (moment(enoteEndDate, 'MM/DD/YYYY', true).isValid() && moment(val).isAfter(moment(enoteEndDate))) {
      errMessage = 'Enote Delivery start date should be earlier than the end date.';
      err = true;
    } else if (moment(val).isAfter(moment().startOf('day'), 'day')) {
      errMessage = 'Enote Delivery start date is invalid(mm/dd/yyyy, max date is today).';
      err = true;
    }

    if (err) {
      setEnoteError(true);
      setEnoteErrMsg(errMessage);
      setEnoteStartDate(val);
    } else {
      setEnoteError(false);
      setEnoteStartDate(val.format("MM/DD/YYYY"));
    }
  }

  const onEmailEndDateChange = (val) => {
    let err = false;
    let errMessage = '';
    const momentStartDuration = moment().subtract(10, 'years');

    if (val === '') {
      setEnoteEndError(false);
      setEnoteEndDate('');
      return;
    }

    if (!moment(val, 'MM/DD/YYYY', true).isValid() || moment(val).isBefore(momentStartDuration)) {
      err = true;
      errMessage = 'Enote Delivery end date is invalid(mm/dd/yyyy, within 10 years).'
    } else if (moment(enoteStartDate, 'MM/DD/YYYY', true).isValid() && moment(val).isBefore(moment(enoteStartDate))) {
      errMessage = 'Enote Delivery end date should be greater than the start date.';
      err = true;
    } else if (moment(val).isAfter(moment().startOf('day'), 'day')) {
      errMessage = 'Enote Delivery end date is invalid(mm/dd/yyyy, max date is today).';
      err = true;
    }

    if (err) {
      setEnoteEndError(true);
      setEnoteErrEndMsg(errMessage);
      setEnoteEndDate(val);
    } else {
      setEnoteEndError(false);
      setEnoteEndDate(val.format("MM/DD/YYYY"));
    }
  }

  const onDateClose = () => {
    setIdx(idx + 1);
  }

  return (
    <div style={{ marginTop: '-12px' }}>
      <ListPaneHeaderWrapper
        title={`Report Builder`}
        layoutControls={[expandCollapseListPaneButtonConfig]}
      />
      <div className="report-builder" style={{ marginTop: '-12px' }}>
        {datesError && <p style={{ fontSize: '13px', color: 'red', margin: '0 0 0 30px' }}>{errMsg}</p>}
        {datesEndError && <p style={{ fontSize: '13px', color: 'red', margin: '0 0 0 30px' }}>{errEndMsg}</p>}
        {readyError && <p style={{ fontSize: '13px', color: 'red', margin: '0 0 0 30px' }}>{readyErrMsg}</p>}
        {readyEndError && <p style={{ fontSize: '13px', color: 'red', margin: '0 0 0 30px' }}>{readyErrEndMsg}</p>}
        {mailError && <p style={{ fontSize: '13px', color: 'red', margin: '0 0 0 30px' }}>{mailErrMsg}</p>}
        {mailEndError && <p style={{ fontSize: '13px', color: 'red', margin: '0 0 0 30px' }}>{mailErrEndMsg}</p>}
        {enoteError && <p style={{ fontSize: '13px', color: 'red', margin: '0 0 0 30px' }}>{enoteErrMsg}</p>}
        {enoteEndError && <p style={{ fontSize: '13px', color: 'red', margin: '0 0 0 30px' }}>{enoteErrEndMsg}</p>}
        <div className="report-builder__search-row">
          <SelectDropdown
            width="220px"
            domID="ReportBuilderReportsDropdown"
            onChange={onReportTypeChange}
            size="small"
            label="REPORTS"
            options={canAccess ? [
              {
                label: "Audit Report",
                value: "RBAuditReport",
              }, {
                label: "Standard Report",
                value: "RBStandardReport",
              }
            ] : [
                {
                  label: "Standard Report",
                  value: "RBStandardReport",
                },
              ]}
            initialValue={reportType}
            isClearable={false}
          />
        </div>
        <div className="report-builder__search-row">
          <Input
            domID="ReportBuilderCaseNumberInput"
            onChange={onCaseNumberChange}
            size="small"
            label={labelsLoading ? '' : (labels?.claimNum || "CLAIM NUMBER").toUpperCase()}
            initialValue={caseNumber}
          />
          <SelectDropdown
            width="220px"
            domID="ReportBuilderBillNameDropdown"
            onChange={onBillNameChange}
            label={labelsLoading ? '' : (labels?.claimType || "CLAIM TYPE").toUpperCase()}
            options={dropdowns?.claimTypes}
            initialValue={billName}
          />
          <SelectDropdown
            width="220px"
            domID="ReportBuilderRecipientTypeDropdown"
            onChange={onRecipientTypeChange}
            size="small"
            label="RECIPIENT TYPE"
            options={dropdowns?.recipientTypes}
            initialValue={recipientType}
            isClearable={recipientType?.label ? true : false}
          />
          <SelectDropdown
            width="220px"
            domID="ReportBuilderDeliveryTypeDropdown"
            onChange={onDeliveryTypeChange}
            size="small"
            label="DELIVERY TYPE"
            options={dropdowns?.deliveryTypes}
            initialValue={deliveryType}
            isClearable={deliveryType?.label ? true : false}
          />
        </div>
        <div className="report-builder__search-row">
          <SelectDropdown
            width="220px"
            domID="ReportBuilderhierarchy2Dropdown"
            onChange={onHierarchy2Change}
            label={labelsLoading ? '' : (labels?.hierarchy2 || "HIERARCHY 2").toUpperCase()}
            options={dropdowns?.hierarchy2}
            initialValue={hierarchy2}
          />
          <SelectDropdown
            width="220px"
            domID="ReportBuilderOwningCarrierDropdown"
            onChange={onOwningCarrierChange}
            label={labelsLoading ? '' : (labels?.hierarchy5 || "HIERARCHY 5").toUpperCase()}
            options={dropdowns?.hierarchy5}
            initialValue={owningCarrier}
          />
          <SelectDropdown
            width="220px"
            domID="ReportBuilderCarrierDropdown"
            onChange={onCarrierChange}
            label={labelsLoading ? '' : (labels?.hierarchy4 || "HIERARCHY 4").toUpperCase()}
            options={dropdowns?.hierarchy4}
            initialValue={carrier}
          />
          <SelectDropdown
            width="220px"
            domID="ReportBuilderIssuedStateDropdown"
            onChange={onIssuedStateChange}
            size="small"
            label={labelsLoading ? '' : (labels?.recptState || "RECIPIENT STATE").toUpperCase()}
            options={dropdowns?.recipientStates}
            initialValue={issuedState}
            isClearable={issuedState?.label ? true : false}
          />
        </div>
        <div className="report-builder__search-row">
          <Input
            domID="ReportBuilderFileKeyInput"
            onChange={onFileKeyChange}
            size="small"
            label={labelsLoading ? '' : (labels?.fileKey || "FILE KEY").toUpperCase()}
            initialValue={fileKey}
          />
          <Input
            domID="ReportBuilderFileNameInput"
            onChange={onFileNameChange}
            size="small"
            label={labelsLoading ? '' : (labels?.fileName || "FILE NAME").toUpperCase()}
            initialValue={fileName}
          />
          <SelectDropdown
            width="220px"
            domID="ReportBuilderDocStatusDropdown"
            onChange={onDocStatusChange}
            size="small"
            label={labelsLoading ? '' : (labels?.docStatus || "DOCUMENT STATUS").toUpperCase()}
            options={dropdowns?.documentStatus}
            initialValue={docStatus}
            isClearable={docStatus?.label ? true : false}
          />
          <SelectDropdown
            width="220px"
            domID="ReportBuilderLanguageDropdown"
            onChange={onLanguageChange}
            label={labelsLoading ? '' : (labels?.hierarchy3 || "HIERARCHY 3").toUpperCase()}
            options={dropdowns?.hierarchy3}
            initialValue={language}
          />
        </div>
        <div className="report-builder__search-row">
          <div className="msgDatePicker">
            <label style={{ color: '#626D8A', fontSize: '11px' }}>IMPORT DATE RANGE</label>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Datetime
                onChange={onImportStartDateChange}
                // inputProps={{ readOnly: true }}
                closeOnSelect={true}
                isValidDate={importStartDateValidation}
                dateFormat="MM/DD/YYYY"
                value={importStartDate}
                initialValue={importStartDate}
                initialViewDate={importStartDate}
                timeFormat={false}
              /> <span style={{ padding: '0 3px' }}>-</span>
              <Datetime
                onChange={onImportEndDateChange}
                // inputProps={{ readOnly: true }}
                closeOnSelect={true}
                isValidDate={importEndDateValidation}
                dateFormat="MM/DD/YYYY"
                value={importEndDate}
                initialValue={importEndDate}
                initialViewDate={importEndDate}
                timeFormat={false}
              />
            </div>
          </div>
          <div className="msgDatePicker">
            <label style={{ color: '#626D8A', fontSize: '11px' }}>{labelsLoading ? '' : (labels?.readyPrntDtRng || "READY FOR PRINT DATE RANGE").toUpperCase()}</label>
            <div style={{ display: 'flex', alignItems: 'center' }} key={idx}>
              <Datetime
                onChange={onReadyStartDateChange}
                // inputProps={{ readOnly: true }}
                closeOnSelect={true}
                isValidDate={readyStartDateValidation}
                dateFormat="MM/DD/YYYY"
                value={readyStartDate}
                initialValue={readyStartDate}
                initialViewDate={readyStartDate}
                timeFormat={false}
              /> <span style={{ padding: '0 3px' }}>-</span>
              <Datetime
                onChange={onReadyEndDateChange}
                // inputProps={{ readOnly: true }}
                closeOnSelect={true}
                isValidDate={readyEndDateValidation}
                dateFormat="MM/DD/YYYY"
                value={readyEndDate}
                initialValue={readyEndDate}
                initialViewDate={readyEndDate}
                timeFormat={false}
              />
            </div>
          </div>
          <div className="msgDatePicker">
            <label style={{ color: '#626D8A', fontSize: '11px' }}>MAILED/COMPLETED DATE RANGE</label>
            <div style={{ display: 'flex', alignItems: 'center' }} key={idx}>
              <Datetime
                onChange={onMailedStartDateChange}
                // inputProps={{ readOnly: true }}
                closeOnSelect={true}
                isValidDate={mailedStartDateValidation}
                dateFormat="MM/DD/YYYY"
                value={mailedStartDate}
                initialValue={mailedStartDate}
                initialViewDate={mailedStartDate}
                timeFormat={false}
              /> <span style={{ padding: '0 3px' }}>-</span>
              <Datetime
                onChange={onMailedEndDateChange}
                // inputProps={{ readOnly: true }}
                closeOnSelect={true}
                isValidDate={mailedEndDateValidation}
                dateFormat="MM/DD/YYYY"
                value={mailedEndDate}
                initialValue={mailedEndDate}
                initialViewDate={mailedEndDate}
                timeFormat={false}
              />
            </div>
          </div>
          <div className="msgDatePicker">
            <label style={{ color: '#626D8A', fontSize: '11px' }}>{labelsLoading ? '' : (labels?.enoteDtRng || "EMAIL DELIVERY DATE RANGE").toUpperCase()}</label>
            <div style={{ display: 'flex', alignItems: 'center' }} key={idx}>
              <Datetime
                onChange={onEmailStartDateChange}
                // inputProps={{ readOnly: true }}
                closeOnSelect={true}
                isValidDate={emailStartDateValidation}
                dateFormat="MM/DD/YYYY"
                value={enoteStartDate}
                initialValue={enoteStartDate}
                initialViewDate={enoteStartDate}
                timeFormat={false}
              /> <span style={{ padding: '0 3px' }}>-</span>
              <Datetime
                onChange={onEmailEndDateChange}
                // inputProps={{ readOnly: true }}
                closeOnSelect={true}
                isValidDate={emailEndDateValidation}
                dateFormat="MM/DD/YYYY"
                value={enoteEndDate}
                initialValue={enoteEndDate}
                initialViewDate={enoteEndDate}
                timeFormat={false}
              />
            </div>
          </div>
        </div>
        <div className="report-builder__search-row">
          <Input
            domID="FileTrackerProductTypeInput"
            onChange={onProductTypeChange}
            size="small"
            label={labelsLoading ? '' : (labels?.procesrId || "PROCESSOR ID").toUpperCase()}
            initialValue={productType}
          />
          <SelectDropdown
            width="220px"
            domID="ReportBuilderNCOADropdown"
            onChange={onNcoaChange}
            size="small"
            label={labelsLoading ? '' : (labels?.NCOA || "NCOA").toUpperCase()}
            initialValue={ncoa}
            isClearable={ncoa?.label ? true : false}
            options={[
              {
                label: 'No',
                value: 'No',
              },
              {
                label: 'Yes',
                value: 'Yes',
              },
            ]}
          />
          <Button
            domID="searchButton"
            name={isLoading ? "Searching..." : "Search"}
            onClick={searchClick}
            size="small"
            buttonType="standard"
            className="report-builder__search-button"
            disabled={datesError || readyError || mailError || enoteError || datesEndError || readyEndError || mailEndError || enoteEndError || isLoading}
          />
          <Button
            domID="clearButton"
            name="Clear"
            onClick={clearClick}
            size="small"
            buttonType="diminished"
            className="report-builder__search-button"
          />
        </div>
        {showMsg ? <p style={{ fontSize: '13px', margin: '10px 10px 0 10px' }}>The search result exceeds 1000 rows of records. Please download the file for the complete result.</p> : ''}
        <div className="report-builder__pagination-row" style={{ display: 'flex', alignItems: 'baseline' }}>
          <ButtonGroup>
            {/* <Button
              domID="viewSelectedButton"
              name="View Selected"
              onClick={viewSelectedClick}
              size="small"
              buttonType="standard"
            /> */}
            <Button
              domID="downloadReportButton"
              name={isReportLoading ? "Loading Report..." : "Download Report"}
              onClick={downloadReportClick}
              size="small"
              buttonType="standard"
              disabled={!cacheName || isReportLoading}
            />
          </ButtonGroup>
          {
            isReportLoading && <Loading length="30px" width="auto" />
          }
          <GridPaginationWrapper
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={setRowsPerPage}
            pageIndex={pageIndex}
            onChangePageIndex={setPageIndex}
            onShowButtonCLick={handleShowButton}
            totalRecords={total}
            defaultRowsPerPage={50}
            specialIdx={true}
            disableShowButton={datesError || readyError || mailError || enoteError || datesEndError || readyEndError || mailEndError || enoteEndError}
          />
        </div>
        {
          isDocLoading && <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <p style={{ fontSize: '13px', color: '#0F0F59', margin: '0 0 20px 40px' }}>Loading PDF. Please wait...</p>
            <Loading length="30px" width="auto" />
          </div>
        }
        <div className="report-builder__grid-wrapper">
          {isLoading ? <Loading />
            :
            isError ? (
              // replace with a better error component
              <ErrorPage
                // this prop isn't real but is an example
                error={error}
              />
            ) : (
                <Grid
                  columns={payload.reportType === "RBStandardReport" ? new Set([
                    {
                      dataName: 'docKeyProp',
                      text: 'DOCUMENT KEY',
                      cellType: 'custom',
                    },
                    {
                      dataName: 'claimNum',
                      text: (labels?.claimNum || 'CLAIM NUMBER').toUpperCase(),
                      cellType: 'text',
                      sortable: true,
                      isSorted: 0,
                    },
                    {
                      dataName: 'claimType',
                      text: (labels?.claimType || "CLAIM TYPE").toUpperCase(),
                      cellType: 'text',
                    },
                    {
                      dataName: 'recptType',
                      text: 'RECIPIENT TYPE',
                      cellType: 'text',
                    },
                    {
                      dataName: 'delvType',
                      text: 'DELIVERY TYPE',
                      cellType: 'text',
                    },
                    {
                      dataName: 'hierarchy2',
                      text: (labels?.hierarchy2 || "HIERARCHY 2").toUpperCase(),
                      cellType: 'text',
                    },
                    {
                      dataName: 'hierarchy5',
                      text: (labels?.hierarchy5 || "HIERARCHY 5").toUpperCase(),
                      cellType: 'text',
                    },
                    {
                      dataName: 'hierarchy4',
                      text: (labels?.hierarchy4 || "HIERARCHY 4").toUpperCase(),
                      cellType: 'text',
                    },
                    {
                      dataName: 'recptState',
                      text: (labels?.recptState || "RECIPIENT STATE").toUpperCase(),
                      cellType: 'text',
                    },
                    {
                      dataName: 'hierarchy3',
                      text: (labels?.hierarchy3 || "HIERARCHY 3").toUpperCase(),
                      cellType: 'text',
                    },
                    {
                      dataName: 'fileName',
                      text: 'FILE NAME',
                      cellType: 'text',
                    },
                    {
                      dataName: 'docStatus',
                      text: (labels?.docStatus || "DOCUMENT STATUS").toUpperCase(),
                      cellType: 'text',
                    },
                    {
                      dataName: 'fileKey',
                      text: (labels?.fileKey || 'FILE KEY').toUpperCase(),
                      cellType: 'text',
                    },
                    {
                      dataName: 'procesrId',
                      text: (labels?.procesrId || "PROCESSOR ID").toUpperCase(),
                      cellType: 'text',
                    },
                    {
                      dataName: 'enoteStatus',
                      text: (labels?.enoteStatus || "EMAIL STATUS").toUpperCase(),
                      cellType: 'text',
                    },
                    {
                      dataName: 'enoteDt',
                      text: (labels?.enoteDt || "EMAIL DELIVERY DATE").toUpperCase(),
                      cellType: 'text',
                    },
                    {
                      dataName: 'importDt',
                      text: 'IMPORT DATE',
                      cellType: 'text',
                    },
                    {
                      dataName: 'readyPrntDt',
                      text: (labels?.readyPrntDt || 'READY FOR PRINT DATE').toUpperCase(),
                      cellType: 'text',
                    },
                    {
                      dataName: 'mailDt',
                      text: 'MAILED / COMPLETED DATE',
                      cellType: 'text',
                    },
                    {
                      dataName: 'ncoa',
                      text: 'NCOA',
                      cellType: 'text',
                    },
                    {
                      dataName: 'lastScanDt',
                      text: 'LAST SCAN DELIVERY DATE',
                      cellType: 'text',
                    },
                  ]) : new Set([
                    {
                      dataName: 'docKeyProp',
                      text: 'DOCUMENT KEY',
                      cellType: 'custom',
                    },
                    {
                      dataName: 'claimNum',
                      text: (labels?.claimNum || 'CLAIM NUMBER').toUpperCase(),
                      cellType: 'text',
                      sortable: true,
                      isSorted: 0,
                    },
                    {
                      dataName: 'fileKey',
                      text: (labels?.fileKey || 'FILE KEY').toUpperCase(),
                      cellType: 'text',
                    },
                    {
                      dataName: 'claimType',
                      text: (labels?.claimType || "CLAIM TYPE").toUpperCase(),
                      cellType: 'text',
                    },

                    {
                      dataName: 'hierarchy3',
                      text: (labels?.hierarchy3 || "HIERARCHY 3").toUpperCase(),
                      cellType: 'text',
                    },
                    {
                      dataName: 'hierarchy2',
                      text: (labels?.hierarchy2 || "HIERARCHY 2").toUpperCase(),
                      cellType: 'text',
                    },
                    {
                      dataName: 'hierarchy4',
                      text: (labels?.hierarchy4 || "HIERARCHY 4").toUpperCase(),
                      cellType: 'text',
                    },
                    {
                      dataName: 'carrierName',
                      text: "CARRIER NAME",
                      cellType: 'text',
                    },
                    {
                      dataName: 'recptState',
                      text: (labels?.recptState || "RECIPIENT STATE").toUpperCase(),
                      cellType: 'text',
                    },
                    {
                      dataName: 'importDt',
                      text: 'IMPORT DATE',
                      cellType: 'text',
                    },
                    {
                      dataName: 'exchange',
                      text: 'EXCHANGE',
                      cellType: 'text',
                    },
                    {
                      dataName: 'assignedTo',
                      text: 'ASSIGNED TO',
                      cellType: 'text',
                    },
                    {
                      dataName: 'vendor',
                      text: "VENDOR",
                      cellType: 'text',
                    },
                    {
                      dataName: 'fileName',
                      text: 'FILE NAME',
                      cellType: 'text',
                    },
                    {
                      dataName: 'procesrId',
                      text: (labels?.procesrId || "PROCESSOR ID").toUpperCase(),
                      cellType: 'text',
                    },
                    {
                      dataName: 'ncoa',
                      text: 'NCOA',
                      cellType: 'text',
                    },
                  ])}
                  records={data?.map((record, i) => ({
                    ...record,
                    docKeyProp: (((reportType && reportType.value === 'RBStandardReport') && (record.docStatus === "Error" || record.docStatus === "Imported")) ||
                      ((reportType && reportType.value === 'RBAuditReport') && (record.preview === '0'))) ? (
                        <AnchorInput
                          id={`docKey-${record.docKey}`}
                          type='button'
                          disabled
                          style={{ textDecoration: 'none', cursor: 'default' }}
                          className='docKeyLink'
                        >
                          {record.docKey}
                        </AnchorInput>
                      ) : (
                        <AnchorInput
                          id={`docKey-${record.docKey}`}
                          type='button'
                          className='docKeyLink'
                          onClick={() => onDocKeyAnchorClick(record.docKey)}
                        >
                          {record.docKey}
                        </AnchorInput>
                      ),
                  })) || []}
                  dataTestId="test-ReportBuilderGrid"
                  domID="ReportBuilderGrid"
                  initialSortingKey={`${sortBy}|${sortOrder === 'DESC' ? 'SORT_DESCENDING' : 'SORT_ASCENDING'}`}
                  sortingComparator={sortingComparator}
                  onSortGridColumn={onSortGridColumn}
                  isConfigurable
                  isFixedHeader
                  maxHeight="500px"
                />
              )}
        </div>
      </div>
    </div>
  )
}

export default withAxiosCancellation(ReportBuilderListPane)
