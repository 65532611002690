/* eslint-disable no-plusplus */
/* eslint-disable no-loop-func */
/* eslint-disable radix */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-useless-escape */
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import styled from 'styled-components/dist/styled-components'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import {
  Grid,
  Modal,
  ModalHeader,
  useLayout,
  ListPaneHeader,
  Input, Button, LoadingIndicator, Breadcrumb, ModalBody
} from 'ui-core/dist'
import { useGetDrawDownTypes, useGetConsolidationTypes, useGetConsTypeDesc, useGetDrawDownDesc,
  useEditEftAccount,  useAddEftAccount, useGetOdfiList} from '../../api/usePayerEftAccount';
import { SelectDropdown, AnchorInput } from '../../components'
import LoadingComponent from '../../components/LoadingComponent';
import ListPaneHeaderWrapper from '../../components/ErrorDisplaySection/ListPaneHeaderWrapper';
import ErrorPage from '../ErrorPage/components/ErrorPage';
import useNotificationBannerHook from '../../useNotificationBannerHook';
import GridPaginationWrapper from '../../components/GridPaginationWrapper';
import * as constants from './Modules/constants';
import useSortableGrid from '../../hooks/useSortableGrid';
import {resetEditPayerEftAccount} from './Modules/action';
import ModalWrapper from '../../components/Modal/components/ModalWrapper';
import './Modules/styles.scss'

const StyledGridWrapper = styled.div`
&.GridWrapper #grid-wrapper-payerEftChooseOdfiGrid{
  & table tbody tr td.odfiId_display {
      width: 250px !important;
      overflow: hidden !important;
  }
 }
`
const StyledLoadingContainer=styled.div`
& > div {
  width: 35px !important;
  height: 35px !important;
  & > svg {
    width: 35px !important;
    height: 35px !important;
  }
}
`
const StyledModalWrapperWidth = styled(ModalWrapper)`
& .active-content {
  width: 70% !important;
  max-height: 80vh !important;
}
`
const StyledContainer=styled.table`
width: 60%;
& tr td:first-child {
    width: 300px !important;
}
& tr td input {
    width: 370px !important;
}
`
const PayerEftAccountEdit = () => {
  const {
    expandCollapseListPaneButtonConfig
  } = useLayout()
  const { notify } = useNotificationBannerHook();
  const history = useHistory();
  const dispatch=useDispatch()
  
  const { editPayerEftAccount, mode } 
  = useSelector(({ payerEftAccount }) => payerEftAccount);

  const { isLoading: isLoadinggetDrawDownTypes, isError: isErrorgetDrawDownTypes, error: errorgetDrawDownTypes, 
    postData: getDrawDownTypes, data: availableDrawDownTypes } = useGetDrawDownTypes()

  const { isLoading: isLoadinggetConsolidationTypes, isError: isErrorgetConsolidationTypes, error: errorgetConsolidationTypes, 
    postData: getConsolidationTypes, data: availableConsolidationTypes } = useGetConsolidationTypes()

  const { isLoading: isLoadinggetConsTypeDesc, isError: isErrorgetConsTypeDesc, error: errorgetConsTypeDesc, 
    postData: getConsTypeDesc, data: ConsTypeDesc } = useGetConsTypeDesc()

  const { isLoading: isLoadinggetDrawDownDesc, isError: isErrorgetDrawDownDesc, error: errorgetDrawDownDesc, 
    postData: getDrawDownDesc, data: DrawDownDesc } = useGetDrawDownDesc()
    
  const { isLoading: isLoadingeditEftAccount, isError: isErroreditEftAccount, error: erroreditEftAccount, 
    postData: editEftAccount, data: editEftAccountResp, metaData: editEftAccountMetadata } = useEditEftAccount()

  const { isLoading: isLoadingaddEftAccount, isError: isErroraddEftAccount, error: erroraddEftAccount, 
    postData: addEftAccount, data: addEftAccountResp, metaData: addEftAccountMetadata } = useAddEftAccount()

  const { isLoading: isLoadinggetOdfiList, isError: isErrorgetOdfiList, error: errorgetOdfiList, 
    postData: getOdfiList, data: getOdfiListResp, metaData: getOdfiListMetadata } = useGetOdfiList()

  const [consolidationTypeId, setConsolidationTypeId]=useState(editPayerEftAccount?.consolidationTypeId||'')
  const [consolidationTypeDesc, setConsolidationTypeDesc]=useState(editPayerEftAccount?.consolidationTypeDesc||'')
  const [drawDownTypeId, setDrawDownTypeId]=useState(editPayerEftAccount?.drawDownTypeId||'')
  const [drawDownTypeDesc, setDrawDownTypeDesc]=useState(editPayerEftAccount?.drawDownTypeDesc||'')
  const [eftAcctId, setEftAcctId]=useState(editPayerEftAccount?.eftAcctId||'')
  const [accountId, setAccountId]=useState(editPayerEftAccount?.accountId||'')
  const [routingCode, setRoutingCode]=useState(editPayerEftAccount?.routingCode||'')
  const [companyName, setCompanyName]=useState(editPayerEftAccount?.companyName||'')
  const [descretionaryData, setDescretionaryData]=useState(editPayerEftAccount?.descretionaryData||'')
  const [odfiId, setOdfiId]=useState(editPayerEftAccount?.odfiId||'')
  const [effDateDiff, setEffDateDiff]=useState(editPayerEftAccount?.effDateDiff||'')
  const [companyId, setCompanyId]=useState(editPayerEftAccount?.companyId||'')
  const [companyEntryDesc, setCompanyEntryDesc]=useState(editPayerEftAccount?.companyEntryDesc||'')
  const [origFinInst, setOrigFinInst]=useState(editPayerEftAccount?.origFinInst||'')
  const [accountVerified, setAccountVerified]=useState(editPayerEftAccount?.accountVerified||'')
  const [bankingModel, setBankingModel]=useState(editPayerEftAccount?.bankingModel||'')
  const [versionNumber, setVersionNumber]=useState(editPayerEftAccount?.versionNumber||'')
  const [statusCode, setStatusCode]=useState(editPayerEftAccount?.statusCode||'')
  const [tUpdated, setTUpdated]=useState(editPayerEftAccount?.tUpdated||'')
  const [acctType, setAcctType]=useState(editPayerEftAccount?.acctType||'')
  const [holdDays, setHoldDays]=useState(editPayerEftAccount?.holdDays||'')
  const [count, setcount] = useState(0);
  const [availableDrawDownTypesSource, setavailableDrawDownTypesSource]=useState(null)
  const [availableConsolidationTypesSource, setavailableConsolidationTypesSource]=useState(null)
  const [validationMessage, setValidationMessage]=useState('')
  const [isOpenConfirmationModal, setisOpenConfirmationModal]=useState(false)
  const [isOpenChooseOdfiViewModal, setisOpenChooseOdfiViewModal]=useState(false)

  const BANKING_MODEL_OPTIONS=[{
    label: 'Emdeon Model', value: '0',
  }, {
    label: 'Payer Bank Model', value:'1'
  }]
  const ACCOUNT_TYPE_OPTIONS=[{
    label: 'Checking', value:'C'
  }, {
    label: 'Savings', value:'S'
  }]
  const ACCOUNT_VERIFIED_OPTIONS=[{
    label: 'NO', value:'0'
  }, {
    label: 'YES', value:'1'
  }]
  const onCloseConfirmationModal=()=>{
    setisOpenConfirmationModal(false)
  }
  useEffect(()=>{
    getDrawDownTypes({count})
    getConsolidationTypes({count:count+1})
    if(mode==="add") setBankingModel("0")
    setcount(count+2);
    return ()=>{
      dispatch(resetEditPayerEftAccount())
    }
  },[])
  useEffect(() => {
    if (getOdfiListMetadata) {
      const { status, statusText } = getOdfiListMetadata;
      if (status === 200 || statusText === "OK") {
        if (getOdfiListResp && getOdfiListResp.fs) {
          const { fs } = getOdfiListResp;
          if (fs) {
            notify(fs, 'negative')
          }
        }
      } else {
        notify('Error occurred while fetching Odfi List', 'negative')
      }
    }
  }, [getOdfiListMetadata, getOdfiListResp])
  useEffect(() => {
    if (addEftAccountMetadata) {
      const { status, statusText } = addEftAccountMetadata;
      if (status === 200 || statusText === "OK") {
        if (addEftAccountResp && addEftAccountResp.fs) {
          const { fs } = addEftAccountResp;
          if (fs) {
            notify(fs, 'negative')
            return;
          }
        }
        const message={msg: constants.status_client_eft_insert_successful.replace('{0}', addEftAccountResp?.resp), type: 'positive'}
        onCloseConfirmationModal()
        history.push({pathname: '/ClientRulesManager/PayerEftAccount/index', state: JSON.parse(JSON.stringify(message))})
      } else {
        notify(constants.error_client_eft_add_failure.replace('{0}', eftAcctId), 'negative')
      }
    }
  }, [addEftAccountMetadata, addEftAccountResp])

  useEffect(() => {
    if (editEftAccountMetadata) {
      const { status, statusText } = editEftAccountMetadata;
      if (status === 200 || statusText === "OK") {
        if (editEftAccountResp && editEftAccountResp.fs) {
          const { fs } = editEftAccountResp;
          if (fs) {
            notify(fs, 'negative')
            return;
          }
        }
        const message={msg: constants.status_client_eft_update_successful.replace('{0}', eftAcctId), type: 'positive'}
        onCloseConfirmationModal()
        history.push({pathname: '/ClientRulesManager/PayerEftAccount/index', state: JSON.parse(JSON.stringify(message))})
      } else{
        notify(constants.error_client_eft_modify_failure.replace('{0}', eftAcctId), 'negative')
      }
    }
  }, [editEftAccountMetadata, editEftAccountResp])

  useEffect(()=>{
    if (availableDrawDownTypes && (availableDrawDownTypes?.resp||'').indexOf(',')) {
      const s=availableDrawDownTypes?.resp.split(',')
      if (s.length>0) {
        const source=[];
        let cur;
        for (let index = 0; index < s.length; index++) {
          const element = s[index];
          if (index===0) {
            cur=element;
            source.push({value:element})
            continue;
          }
          if (index===1) {
            const f=source.find(x=>x.value===cur);
            if (f) {
              f.label=element;
              continue;
            }
          }
          const r=index%2;
          if (r===0) {
            cur=element;
            source.push({value:element});
            continue;
          } else{
            const f=source.find(x=>x.value===cur);
            if (f) {
              f.label=element;
              continue;
            }
          }
        }
        setavailableDrawDownTypesSource(JSON.parse(JSON.stringify(source)))
        return;
      }
    }
    setavailableDrawDownTypesSource([])
  },[availableDrawDownTypes])
  useEffect(()=>{
    if (availableConsolidationTypes && (availableConsolidationTypes?.resp||'').indexOf(',')) {
      const s=availableConsolidationTypes?.resp.split(',')
      if (s.length>0) {
        const source=[];
        let cur;
        for (let index = 0; index < s.length; index++) {
          const element = s[index];
          if (index===0) {
            cur=element;
            source.push({value:element})
            continue;
          }
          if (index===1) {
            const f=source.find(x=>x.value===cur);
            if (f) {
              f.label=element;
              continue;
            }
          }
          const r=index%2;
          if (r===0) {
            cur=element;
            source.push({value:element});
            continue;
          } else{
            const f=source.find(x=>x.value===cur);
            if (f) {
              f.label=element;
              continue;
            }
          }
        }
        setavailableConsolidationTypesSource(JSON.parse(JSON.stringify(source)))
        return;
      }
    }
    setavailableConsolidationTypesSource([])
  },[availableConsolidationTypes])
  useEffect(()=>{
    if (ConsTypeDesc && ConsTypeDesc.resp) {
      setConsolidationTypeDesc(ConsTypeDesc.resp)
    }
  },[ConsTypeDesc])
  useEffect(()=>{
    if (DrawDownDesc && DrawDownDesc.resp) {
      setDrawDownTypeDesc(DrawDownDesc.resp)
    }
  },[DrawDownDesc])
  const onSubmitConfirmationClick=()=>{
    const abfPeo=populateEftObj();
    if (abfPeo?.payerBank.toString().trim()==="1") {
      abfPeo.holdDays='';
      abfPeo.consType='';
      abfPeo.consTypeDesc='';
      abfPeo.drawDown='';
      abfPeo.drawDownDesc='';
      abfPeo.acctType='';
    } else{
      abfPeo.odfiId='';
      abfPeo.compData='';
      abfPeo.compId='';
      abfPeo.compEntDesc='';
      abfPeo.originId='';
    }
    if (mode==="modify") editEftAccount({...abfPeo, count})
    else if (mode==="add") addEftAccount({...abfPeo, count})
    setcount(count+1)
  }
  const onSubmitClick=()=>{
    setValidationMessage();
    const abfPeo=populateEftObj();
    if (reqFieldList(abfPeo).trim()!=="") {
      let msg=constants.status_client_eft_validate_fieldList;
      msg=msg.replace("{0}", reqFieldList(abfPeo));
      setValidationMessage(msg)
      return false;
    }
    if (!validateLong(abfPeo.holdDays)) {
      let msg=constants.status_client_eft_validate_holddays_failed;
      msg=msg.replace("{0}", abfPeo.holdDays)
      setValidationMessage(msg)
      return false;
    }
    if (!validateLong(abfPeo.originId)) {
      let msg=constants.status_client_eft_validate_oridfinid_failed
      msg=msg.replace("{0}", abfPeo.originId)
      setValidationMessage(msg);
      return false;
    }
    if (!validateLong(abfPeo.compId)) {
      let msg=constants.status_client_eft_validate_companyid_failed
      msg=msg.replace("{0}", abfPeo.compId)
      setValidationMessage(msg)
      return false;
    }
    if (!validateLong(abfPeo.dateDiff)) {
      let msg=constants.status_client_eft_validate_effdtdiff_failed
      msg=msg.replace("{0}", abfPeo.dateDiff)
      setValidationMessage(msg)
      return false;
    }
    if (!validateLong(abfPeo.routeId)) {
      let msg=constants.status_client_eft_validate_routeid_failed
      msg=msg.replace("{0}", abfPeo.routeId)
      setValidationMessage(msg)
      return false;
    }
    if (!validateChecksum(abfPeo.routeId)) {
      let msg=constants.status_client_eft_validate_routeid_checksum_failed
      msg=msg.replace("{0}", abfPeo.routeId)
      setValidationMessage(msg)
      return false;
    }
    if (mode ==="modify" && !testModify()) {
      let msg=constants.status_client_eft_edit_nochange
      msg=msg.replace("{0}", abfPeo.acctId)
      setValidationMessage(msg)
      return false;
    }
    // getConsTypeDesc && getDrawDownDesc && then show confirmation modal
    if (consolidationTypeId) getConsTypeDesc({consType: consolidationTypeId})
    if (drawDownTypeId) getDrawDownDesc({drawDownType: drawDownTypeId});
    setisOpenConfirmationModal(true)
    return true;
  }
  const populateEftObj=()=> {
    const abfPeo={};
    abfPeo.consType = consolidationTypeId||'';
    abfPeo.consTypeDesc = consolidationTypeDesc||'';
    abfPeo.drawDown = drawDownTypeId||'';
    abfPeo.drawDownDesc = drawDownTypeDesc||'';
    abfPeo.acctId = eftAcctId||'';
    abfPeo.onus = accountId||'';
    abfPeo.routeId = routingCode||'';
    abfPeo.compName = companyName||'';
    abfPeo.compData = descretionaryData||'';
    abfPeo.odfiId = odfiId||'';
    abfPeo.dateDiff = effDateDiff||'';
    abfPeo.compId = companyId||'';
    abfPeo.compEntDesc = companyEntryDesc||'';
    abfPeo.originId = origFinInst||'';
    abfPeo.verified = accountVerified||'';
    abfPeo.payerBank = bankingModel||'';
    abfPeo.version = versionNumber||'';
    abfPeo.status = statusCode||'';
    abfPeo.updated = tUpdated||'';
    abfPeo.acctType = acctType||'';
    abfPeo.holdDays = holdDays||'';
    return abfPeo;
  }
  const validateLong=(checkLong)=> {
    if (checkLong && checkLong.trim().length > 0) {
      try {
        // eslint-disable-next-line no-undef
        if(checkLong.trim()==="0") return true;
        const b=BigInt(checkLong);
        return b?true:false;
      } catch (e) {
        return false;
      }
    }
    return true;
  }
  const testModify=()=> {
    if (consolidationTypeId!==(editPayerEftAccount?.consolidationTypeId||'').trim()) {
      return true;
    } if (consolidationTypeDesc!==(editPayerEftAccount?.consolidationTypeDesc||'').trim()) {
      return true;
    } if (drawDownTypeId!==(editPayerEftAccount?.drawDownTypeId||'').trim()) {
      return true;
    } if (drawDownTypeDesc!==(editPayerEftAccount?.drawDownTypeDesc||'').trim()) {
      return true;
    } if (eftAcctId!==(editPayerEftAccount?.eftAcctId||'').trim()) {
      return true;
    } if (accountId!==(editPayerEftAccount?.accountId||'').trim()) {
      return true;
    } if (routingCode!==(editPayerEftAccount?.routingCode||'').trim()) {
      return true;
    } if (companyName!==(editPayerEftAccount?.companyName||'').trim()) {
      return true;
    } if (descretionaryData!==(editPayerEftAccount?.descretionaryData||'').trim()) {
      return true;
    } if (odfiId!==(editPayerEftAccount?.odfiId||'').trim()) {
      return true;
    } if (effDateDiff!==(editPayerEftAccount?.effDateDiff||'').trim()) {
      return true;
    } if (companyId!==(editPayerEftAccount?.companyId||'').trim()) {
      return true;
    } if (companyEntryDesc!==(editPayerEftAccount?.companyEntryDesc||'').trim()) {
      return true;
    } if (origFinInst!==(editPayerEftAccount?.origFinInst||'').trim()) {
      return true;
    } if (accountVerified!==(editPayerEftAccount?.accountVerified||'').trim()) {
      return true;
    } if (bankingModel!==(editPayerEftAccount?.bankingModel||'').trim()) {
      return true;
    } if (versionNumber!==(editPayerEftAccount?.versionNumber||'').trim()) {
      return true;
    } if (statusCode!==(editPayerEftAccount?.statusCode||'').trim()) {
      return true;
    } if (tUpdated!==(editPayerEftAccount?.tUpdated||'').trim()) {
      return true;
    } if (acctType!==(editPayerEftAccount?.acctType||'').trim()) {
      return true;
    } if (holdDays!==(editPayerEftAccount?.holdDays||'').trim()) {
      return true;
    } 
    return false;
  }
  const validateChecksum=(rteCode)=> {
    let i = 0;
    let addValues = 0;
    if (rteCode.trim().length !== 9) {
      return false;
    }
    while (i < 9) {
      if (i == 0) {
          addValues = addValues + (3 * parseInt(rteCode.trim().substring(i, i + 1)));
      } else if (i == 1) {
          addValues = addValues + (7 * parseInt(rteCode.trim().substring(i, i + 1)));
      } else if (i == 2) {
          addValues = addValues + parseInt(rteCode.trim().substring(i, i + 1));
      } else if (i == 3) {
          addValues = addValues + (3 * parseInt(rteCode.trim().substring(i, i + 1)));
      } else if (i == 4) {
          addValues = addValues + (7 * parseInt(rteCode.trim().substring(i, i + 1)));
      } else if (i == 5) {
          addValues = addValues + parseInt(rteCode.trim().substring(i, i + 1));
      } else if (i == 6) {
          addValues = addValues + (3 * parseInt(rteCode.trim().substring(i, i + 1)));
      } else if (i == 7) {
          addValues = addValues + (7 * parseInt(rteCode.trim().substring(i, i + 1)));
      } else {
          addValues = addValues + parseInt(rteCode.trim().substring(i, i + 1));
      }
      i = i + 1;
    }
    if (addValues % 10 != 0) {
      return false;
    }
    return true;
  }
  const reqFieldList=(abfPeo)=> {
    let fieldList = "";
    if (abfPeo.payerBank.trim()==="1") {
      if (abfPeo.onus.trim()==="")
        fieldList += "Payer Eft Account Number: ";
      if (abfPeo.routeId.trim()==="")
        fieldList += "Payer EFT Routing Code: ";
      if (abfPeo.compName.trim()==="")
        fieldList += "Company Name: ";
      if (abfPeo.compData.trim()==="")
        fieldList += "Discretionary Data: ";
      if (abfPeo.odfiId.trim()==="")
        fieldList += "ODFI ID: ";
      if (abfPeo.dateDiff.trim()==="")
        fieldList += "Effective Date Difference: ";
      if (abfPeo.compId.trim()==="")
        fieldList += "Company Identification (Assigned by ODFI): ";
      if (abfPeo.compEntDesc.trim()==="")
        fieldList += "Company Entry Description: ";
      if (abfPeo.originId.trim()==="")
        fieldList += "Originating Financial Institution: ";
    } else {
      if (abfPeo.onus.trim()==="")
        fieldList += "Payer Eft Account Number: ";
      if (abfPeo.routeId.trim()==="")
        fieldList += "Payer EFT Routing Code: ";
      if (abfPeo.compName.trim()==="")
        fieldList += "Company Name: ";
      if (abfPeo.dateDiff.trim()==="")
        fieldList += "Effective Date Difference: ";
      if (abfPeo.holdDays.trim()==="")
        fieldList += "Hold Days: ";
    }
    return fieldList;
  }
  useEffect(()=>{
    if (isOpenChooseOdfiViewModal) {
      getOdfiList({Dummy:"", count})
      setcount(count+1)
    }
  }, [isOpenChooseOdfiViewModal])
  const onChooseOdfiViewClick=()=>{
    setisOpenChooseOdfiViewModal(true);
  }
  const onCloseChooseOdfiViewModal=()=>{
    setisOpenChooseOdfiViewModal(false);
  }
  const renderConfirmationModal=useMemo(()=>{
    if (isOpenConfirmationModal) {
      return (
        <Modal isOpen={isOpenConfirmationModal}>
          <ModalHeader
            title={`Confirm Client Payer EFT Account ${mode==="modify"?'Modification':''}`}
            onClose={() => onCloseConfirmationModal()}
          />
          <ModalBody>
            {isOpenConfirmationModal ? (
              <div style={{ marginLeft: '34px', marginBottom: '20px', maxHeight:'320px', overflow:'auto' }} className="marginLeft">
                <div className="displayFlex">
                  <div className="marginLeft">
                    <table>
                      <tr>
                        <td><label><span className="required-glyph">Confirm any changes you made, and click Submit to {mode==="modify"?'update':'add'} the EFT Account.</span></label></td>
                      </tr>
                      <tr><p></p></tr>
                      <tr>
                        <table border="0">
                          {bankingModel==="1"?(
                            <><tr>
                              <td className="name"><label>Banking Model:</label></td>
                              <td className="value"><label>Payer Bank</label></td>
                            </tr><tr><p></p></tr></>
                          ):null}
                          {bankingModel==="0"?(
                            <><tr>
                              <td className="name"><label>Banking Model:</label></td>
                              <td className="value"><label>Emdeon</label></td>
                            </tr><tr><p></p></tr></>
                          ):null}
                          <tr>
                            <td className="name"><label>Payer Eft Account Number:</label></td>
                            <td className="value"><label>{accountId}</label></td>
                          </tr>
                          <tr><p></p></tr>
                          <tr>
                            <td className="name"><label>Payer EFT Routing Code:</label></td>
                            <td className="value"><label>{routingCode}</label></td>
                          </tr>
                          <tr><p></p></tr>
                          <tr>
                            <td className="name"><label>Company Name:</label></td>
                            <td className="value"><label>{companyName}</label></td>
                          </tr>
                          <tr><p></p></tr>
                          {bankingModel==="1"?(
                            <><tr>
                              <td className="name"><label>ODFI ID:</label></td>
                              <td className="value"><label>{odfiId}</label></td>
                            </tr><tr><p></p></tr></>
                          ):null}
                          <tr>
                            <td className="name"><label>Effective Date Difference:</label></td>
                            <td className="value"><label>{effDateDiff}</label></td>
                          </tr>
                          <tr><p></p></tr>
                          {bankingModel==="0"?(
                            <><tr>
                              <td className="name"><label>Hold Days:</label></td>
                              <td className="value"><label>{holdDays}</label></td>
                            </tr><tr><p></p></tr></>
                          ):null}
                          {bankingModel==="1"?(
                            <><tr>
                              <td className="name"><label>Discretionary Data:</label></td>
                              <td className="value"><label>{descretionaryData}</label></td>
                            </tr><tr><p></p></tr></>
                          ):null}
                          {bankingModel==="1"?(
                            <><tr>
                              <td className="name"><label>Company Identification (Assigned by ODFI):</label></td>
                              <td className="value"><label>{companyId}</label></td>
                            </tr><tr><p></p></tr></>
                          ):null}
                          {bankingModel==="1"?(
                            <><tr>
                              <td className="name"><label>Company Entry Description:</label></td>
                              <td className="value"><label>{companyEntryDesc}</label></td>
                            </tr><tr><p></p></tr></>
                          ):null}
                          {bankingModel==="1"?(
                            <><tr>
                              <td className="name"><label>Originating Financial Institution:</label></td>
                              <td className="value"><label>{origFinInst}</label></td>
                            </tr><tr><p></p></tr></>
                          ):null}
                          {bankingModel==="0"?(
                            <><tr>
                              <td className="name"><label>Consolidation Type:</label></td>
                              <td className="value"><label>{consolidationTypeDesc}</label></td>
                            </tr><tr><p></p></tr></>
                          ):null}
                          {bankingModel==="0"?(
                            <><tr>
                              <td className="name"><label>Draw Down Type:</label></td>
                              <td className="value"><label>{drawDownTypeDesc}</label></td>
                            </tr><tr><p></p></tr></>
                          ):null}
                          {bankingModel==="0"?(
                            <><tr>
                              <td className="name"><label>Account Type:</label></td>
                              {acctType === "C" ? (
                                <td className="value"><label>Checkings</label></td>
                              ) : null}
                              {acctType === "S" ? (
                                <td className="value"><label>Savings</label></td>
                              ) : null}
                            </tr><tr><p></p></tr></>
                          ):null}
                          <tr>
                            <td className="name"><label>Account Verified:</label></td>
                            {accountVerified==="0"?(
                              <td className="value"><label>NO</label></td>  
                            ):null}
                            {accountVerified==="1"?(
                              <td className="value"><label>YES</label></td>
                            ):null}
                          </tr>
                          <tr><p></p></tr>
                          <tr>
                            <td className="value" colspan="2">
                              <Button
                                id='ContactEditorAdd'
                                size="small"
                                name="Submit"
                                onClick={()=>onSubmitConfirmationClick()}
                              />
                              {/* <input type="submit" name="submit" value="Submit" onClick={()=>onSubmitConfirmationClick()} /> */}
                            </td>
                          </tr>
                        </table>   
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            ) : null}
          </ModalBody>
        </Modal>
      )
    }
    return null;
  },[isOpenConfirmationModal,consolidationTypeId,drawDownTypeId,
    consolidationTypeDesc,drawDownTypeDesc,eftAcctId,accountId,
    routingCode,companyName,descretionaryData,odfiId,effDateDiff,companyId,
    companyEntryDesc,origFinInst,accountVerified,bankingModel,acctType,holdDays,
    consolidationTypeId,drawDownTypeId,versionNumber,statusCode,tUpdated
  ])
  const renderChooseOdfiViewModal=useMemo(()=>{
    let odfiAccounts=[];
    if (getOdfiListResp && getOdfiListResp.collection
      && getOdfiListResp.collection.odfiAccounts) {
      if (getOdfiListResp.collection.odfiAccounts.constructor.name==='Array') {
        odfiAccounts=getOdfiListResp.collection.odfiAccounts;
      } else if (getOdfiListResp.collection.odfiAccounts.constructor.name==='Object') {
        odfiAccounts=[getOdfiListResp.collection.odfiAccounts];
      }
    }
    if (isOpenChooseOdfiViewModal && odfiAccounts 
      && odfiAccounts.length>0) {

      const data=JSON.parse(JSON.stringify(odfiAccounts));
      const temp=data.map((odfi, index)=>({
        ...odfi,
        odfiId_display: <AnchorInput
          domID={`chooseOdfi_${odfi?.odfiId}_${index}`}
          color="blue"
          type='button'
          className='fileKeyLink'
          onClick={()=>{setOdfiId(odfi?.odfiId||''); onCloseChooseOdfiViewModal()}}
        >{odfi?.odfiId||''}</AnchorInput>
      }))
      return (
        <Modal isOpen={isOpenChooseOdfiViewModal}>
          <ModalHeader
            title="Click an ODFI ID to select it and close this window."
            onClose={() => onCloseChooseOdfiViewModal()}
          />
          <ModalBody>
            {isOpenChooseOdfiViewModal && odfiAccounts 
          && odfiAccounts.length>0 ? (
                <div style={{ marginLeft: '34px', marginBottom: '20px', overflow:'auto', maxHeight: '350px' }} 
                  className="marginLeft">
                  <div className="displayFlex">
                    <div className="marginLeft">
                    <StyledGridWrapper className="GridWrapper">
                      <Grid
                        {...constants.EFT_CHOOSE_ODFI_GRID}
                        records={temp || []}
                        onClickThrough={(e, rec) => {
                          e.preventDefault();
                          e.stopPropagation();
                          return false;
                        }}
                      />
                      </StyledGridWrapper>
                    </div>
                  </div>
                </div>
              ) : null}
          </ModalBody>
        </Modal>
      )
    }
    return null;
  }, [isOpenChooseOdfiViewModal, getOdfiListResp, consolidationTypeId,drawDownTypeId,
    consolidationTypeDesc,drawDownTypeDesc,eftAcctId,accountId,
    routingCode,companyName,descretionaryData,odfiId,effDateDiff,companyId,
    companyEntryDesc,origFinInst,accountVerified,bankingModel,acctType,holdDays,
    consolidationTypeId,drawDownTypeId,versionNumber,statusCode,tUpdated])
  return (
    <>
      <ListPaneHeaderWrapper
        title={mode==="add"? 'Add A new Client Payer Eft Account': 'Modify A Client Payer Eft Account'}
        layoutControls={[expandCollapseListPaneButtonConfig]}
      />
      <>
        {isLoadinggetConsolidationTypes || isLoadinggetDrawDownTypes || isLoadinggetConsTypeDesc
        || isLoadinggetDrawDownDesc || isLoadingaddEftAccount || isLoadingeditEftAccount ? (
        // replace with a better loading component            
            <div style={{ marginLeft: '50%' }}><LoadingComponent /></div>
          ) : isErrorgetConsolidationTypes || isErrorgetDrawDownTypes || isErrorgetConsTypeDesc
        || isErrorgetDrawDownDesc || isErroraddEftAccount || isErroreditEftAccount
        || isErrorgetOdfiList ? (
          // replace with a better error component
              <ErrorPage
                // this prop isn't real but is an example
                error={errorgetConsolidationTypes || errorgetDrawDownTypes || errorgetConsTypeDesc
            || errorgetDrawDownDesc || erroraddEftAccount || erroreditEftAccount
            || errorgetOdfiList}
              />
            ) : (
              <div className="marginLeft">
                <table>
                  <tr>
                    <td>
                      <Breadcrumb
                        breadcrumbItems={[{ title: "Client Payer Eft Manager" }, 
                          { title: mode==="Add"? "Add Client Payer Eft Manager": "Modify Client Payer Eft Manager" }]}
                        onBreadcrumbItemClick={()=>history.goBack()}
                        domID="breadcrumb"
                      />
                      <br/>
                    </td>
                  </tr>
                  <tr>
                    <td><span className="required-glyph">
                      <label>Modify the information as desired, and then click Submit to update the EFT Account.</label></span></td>
                  </tr>
                  <tr>
                    <td><span className="required-glyph"><label>* Indicates a required field.</label></span></td>
                  </tr>
                  <tr>
                    <td><span className="required-glyph"><label>Any disabled fields that contain data will be cleared upon submission.</label></span></td>
                  </tr>
                  <tr><p></p></tr>
                  {validationMessage?(
                    <><tr>
                      <td>
                        <span className="required-glyph"><label style={{color:'red'}}>{validationMessage}</label></span>
                      </td>
                    </tr><tr><p></p></tr></>
                  ):null}
                  <tr>
                    <StyledContainer border="0">
                      <tr>
                        <td className="name"><label>Client Rule Id:</label></td>
                        <td className="value"><label>{eftAcctId}</label></td>
                      </tr>
                      <tr><p></p></tr>
                      <tr>
                        <td className="name"><label>Banking Model:</label></td>
                        <td className="value">
                          <SelectDropdown
                            width="300px"
                            domID="defer_state_input"
                            isClearable={false}
                            label=""
                            options={BANKING_MODEL_OPTIONS}
                            size="small"
                            initialValue={{ label: BANKING_MODEL_OPTIONS.find(x=>x.value===bankingModel)?.label, value: bankingModel }}
                            onChange={(e) => {
                              if (e) {
                                if (setBankingModel) setBankingModel(e.value)
                              } else if (setBankingModel) setBankingModel(null)
                              // reSubForm()
                            }}
                          />
                        </td>
                      </tr>
                      <tr><p></p></tr>
                      <tr>
                        <td className="name"><label>Payer Eft Account Number:&#160;<span className="required-glyph">*</span></label></td>
                        <td className="value">
                          <Input
                            domID="accountId"
                            label=""
                            maxLength="20"
                            size="small"
                            initialValue={accountId}
                            onChange={(e) => {
                              if (setAccountId) setAccountId(e.target.value)
                            }}
                          />
                        </td>
                      </tr>
                      <tr><p></p></tr>
                      <tr>
                        <td className="name"><label>Payer EFT Routing Code:&#160;<span className="required-glyph">*</span></label></td>
                        <td className="value">
                          <Input
                            domID="routingCode"
                            label=""
                            maxLength="9"
                            size="small"
                            initialValue={routingCode}
                            onChange={(e) => {
                              if (setRoutingCode) setRoutingCode(e.target.value)
                            }}
                          />
                        </td>
                      </tr>
                      <tr><p></p></tr>
                      <tr>
                        <td className="name"><label>Company Name:&#160;<span className="required-glyph">*</span></label></td>
                        <td className="value">
                          <Input
                            domID="companyName"
                            label=""
                            maxLength="22"
                            size="small"
                            initialValue={companyName}
                            onChange={(e) => {
                              if (setCompanyName) setCompanyName((e.target.value||'').toUpperCase())
                            }}
                          />
                        </td>
                      </tr>
                      <tr><p></p></tr>
                      {bankingModel==="0"?(
                        <tr>
                          <td className="name" disabled="true"><label>ODFI ID:</label></td>
                          <td className="value" disabled="true">
                            <Input
                              domID="odfiId"
                              label=""
                              maxLength="50"
                              size="small"
                              initialValue={odfiId}
                              disabled
                              onChange={(e) => {
                                if (setOdfiId) setOdfiId(e.target.value)
                              }}
                            />
                            <label>[Select Payer ODFI (Bank)]</label></td>
                        </tr>
                      ):(
                        <tr>
                          <td className="name"><label>ODFI ID:&#160;<span className="required-glyph">*</span></label></td>
                          <td className="value">
                            <Input
                              domID="odfiId"
                              label=""
                              maxLength="50"
                              size="small"
                              initialValue={odfiId}
                              onChange={(e) => {
                                if (setOdfiId) setOdfiId(e.target.value)
                              }}
                            />
                            <StyledLoadingContainer>
                              <>
                                {isLoadinggetOdfiList ? <LoadingIndicator /> :
                                  <AnchorInput
                                    domID="chooseOdfiView"
                                    color="blue"
                                    type='button'
                                    className='fileKeyLink'
                                    onClick={()=>{onChooseOdfiViewClick();setcount(count+1)}}
                                  >Select Payer ODFI (Bank)</AnchorInput>
                                }
                              </>
                            </StyledLoadingContainer>
                          </td>
                        </tr>
                      )}
                      <tr><p></p></tr>
                      <tr>
                        <td className="name"><label>Effective Date Difference:&#160;<span className="required-glyph">*</span></label></td>
                        <td className="value">
                          <Input
                            domID="effDateDiff"
                            label=""
                            maxLength="2"
                            size="small"
                            initialValue={effDateDiff}
                            onChange={(e) => {
                              if (setEffDateDiff) setEffDateDiff(e.target.value)
                            }}
                          />
                        </td>
                      </tr>
                      <tr><p></p></tr>
                      {bankingModel==="1"?(
                        <tr>
                          <td className="name" disabled="true"><label>Hold Days:</label></td>
                          <td className="value">
                            <Input
                              domID="holdDays"
                              label=""
                              maxLength="2"
                              size="small"
                              initialValue={holdDays}
                              disabled
                              onChange={(e) => {
                                if (setHoldDays) setHoldDays(e.target.value)
                              }}
                            />
                          </td>
                        </tr>
                      ):(
                        <tr>
                          <td className="name"><label>Hold Days:&#160;<span className="required-glyph">*</span></label></td>
                          <td className="value">
                            <Input
                              domID="holdDays"
                              label=""
                              maxLength="2"
                              size="small"
                              initialValue={holdDays}
                              onChange={(e) => {
                                if (setHoldDays) setHoldDays(e.target.value)
                              }}
                            />
                          </td>
                        </tr>
                      )}
                      <tr><p></p></tr>
                      {bankingModel==="0"?(
                        <tr>
                          <td className="name" disabled="true"><label>Discretionary Data:</label></td>
                          <td className="value">
                            <Input
                              domID="descretionaryData"
                              label=""
                              maxLength="20"
                              size="small"
                              initialValue={descretionaryData}
                              disabled
                              onChange={(e) => {
                                if (setDescretionaryData) setDescretionaryData(e.target.value)
                              }}
                            />
                          </td>
                        </tr>
                      ):(
                        <tr>
                          <td className="name"><label>Discretionary Data:&#160;<span className="required-glyph">*</span></label></td>
                          <td className="value">
                            <Input
                              domID="descretionaryData"
                              label=""
                              maxLength="20"
                              size="small"
                              initialValue={descretionaryData}
                              onChange={(e) => {
                                if (setDescretionaryData) setDescretionaryData(e.target.value)
                              }}
                            />
                          </td>
                        </tr>
                      )}
                      <tr><p></p></tr>
                      {bankingModel==="0"?(
                        <tr>
                          <td className="name" disabled="true"><label>Company Identification (Assigned by ODFI):</label></td>
                          <td className="value">
                            <Input
                              domID="companyId"
                              label=""
                              maxLength="10"
                              size="small"
                              initialValue={companyId}
                              disabled
                              onChange={(e) => {
                                if (setCompanyId) setCompanyId(e.target.value)
                              }}
                            />
                          </td>
                        </tr>
                      ):(
                        <tr>
                          <td className="name"><label>Company Identification (Assigned by ODFI):&#160;<span className="required-glyph">*</span></label></td>
                          <td className="value">
                            <Input
                              domID="companyId"
                              label=""
                              maxLength="10"
                              size="small"
                              initialValue={companyId}
                              onChange={(e) => {
                                if (setCompanyId) setCompanyId(e.target.value)
                              }}
                            />
                          </td>
                        </tr>
                      )}
                      <tr><p></p></tr>
                      {bankingModel==="0"?(
                        <tr>
                          <td className="name" disabled="true"><label>Company Entry Description:</label></td>
                          <td className="value">
                            <Input
                              domID="companyEntryDesc"
                              label=""
                              maxLength="10"
                              size="small"
                              initialValue={companyEntryDesc}
                              disabled
                              onChange={(e) => {
                                if (setCompanyEntryDesc) setCompanyEntryDesc(e.target.value)
                              }}
                            />
                          </td>
                        </tr>
                      ):(
                        <tr>
                          <td className="name"><label>Company Entry Description:&#160;<span className="required-glyph">*</span></label></td>
                          <td className="value">
                            <Input
                              domID="companyEntryDesc"
                              label=""
                              maxLength="10"
                              size="small"
                              initialValue={companyEntryDesc}
                              onChange={(e) => {
                                if (setCompanyEntryDesc) setCompanyEntryDesc((e.target.value||'').toUpperCase())
                              }}
                            />
                          </td>
                        </tr>
                      )}
                      <tr><p></p></tr>
                      {bankingModel==="0"?(
                        <tr>
                          <td className="name" disabled="true"><label>Originating Financial Institution:</label></td>
                          <td className="value">
                            <Input
                              domID="origFinInst"
                              label=""
                              maxLength="8"
                              size="small"
                              initialValue={origFinInst}
                              disabled
                              onChange={(e) => {
                                if (setOrigFinInst) setOrigFinInst(e.target.value)
                              }}
                            />
                          </td>
                        </tr>
                      ):(
                        <tr>
                          <td className="name"><label>Originating Financial Institution:&#160;<span className="required-glyph">*</span></label></td>
                          <td className="value">
                            <Input
                              domID="origFinInst"
                              label=""
                              maxLength="8"
                              size="small"
                              initialValue={origFinInst}
                              onChange={(e) => {
                                if (setOrigFinInst) setOrigFinInst(e.target.value)
                              }}
                            />
                          </td>
                        </tr>
                      )}
                      <tr><p></p></tr>
                      {bankingModel==="1"?(
                        <tr>
                          <td className="name" disabled="true"><label>Consolidation Type:</label></td>
                          <td className="value">
                            {availableConsolidationTypesSource && availableConsolidationTypesSource.length>0?(
                              <SelectDropdown
                                width="300px"
                                domID="defer_state_input"
                                isClearable={false}
                                label=""
                                options={availableConsolidationTypesSource}
                                size="small"
                                disabled
                                initialValue={{ label: availableConsolidationTypesSource.find(x=>x.value===consolidationTypeId)?.label, value: consolidationTypeId }}
                                onChange={(e) => {
                                  if (e) {
                                    if (setConsolidationTypeId) setConsolidationTypeId(e.value)
                                  } else if (setConsolidationTypeId) setConsolidationTypeId(null)
                                  // reSubForm()
                                }}
                              />
                            ):null}
                       
                          </td>
                        </tr>
                      ):(
                        <tr>
                          <td className="name"><label>Consolidation Type:</label></td>
                          <td className="value">
                            {availableConsolidationTypesSource && availableConsolidationTypesSource.length>0?(
                              <SelectDropdown
                                width="300px"
                                domID="defer_state_input"
                                isClearable={false}
                                label=""
                                options={availableConsolidationTypesSource}
                                size="small"
                                initialValue={{ label: availableConsolidationTypesSource.find(x=>x.value===consolidationTypeId)?.label, value: consolidationTypeId }}
                                onChange={(e) => {
                                  if (e) {
                                    if (setConsolidationTypeId) setConsolidationTypeId(e.value)
                                  } else if (setConsolidationTypeId) setConsolidationTypeId(null)
                                  // reSubForm()
                                }}
                              />
                            ):null}
                        
                          </td>
                        </tr>
                      )}
                      <tr><p></p></tr>
                      {bankingModel==="1"?(
                        <tr>
                          <td className="name" disabled="true"><label>Draw Down Type:</label></td>
                          <td className="value">
                            {availableDrawDownTypesSource && availableDrawDownTypesSource.length>0?(
                              <SelectDropdown
                                width="300px"
                                domID="defer_state_input"
                                isClearable={false}
                                label=""
                                options={availableDrawDownTypesSource}
                                size="small"
                                disabled
                                initialValue={{ label: availableDrawDownTypesSource.find(x=>x.value===drawDownTypeId)?.label, value: drawDownTypeId }}
                                onChange={(e) => {
                                  if (e) {
                                    if (setDrawDownTypeId) setDrawDownTypeId(e.value)
                                  } else if (setDrawDownTypeId) setDrawDownTypeId(null)
                                  // reSubForm()
                                }}
                              />
                            ):null}
                       
                          </td>
                        </tr>
                      ):(
                        <tr>
                          <td className="name"><label>Draw Down Type:</label></td>
                          <td className="value">
                            {availableDrawDownTypesSource && availableDrawDownTypesSource.length>0?(
                              <SelectDropdown
                                width="300px"
                                domID="defer_state_input"
                                isClearable={false}
                                label=""
                                options={availableDrawDownTypesSource}
                                size="small"
                                initialValue={{ label: availableDrawDownTypesSource.find(x=>x.value===drawDownTypeId)?.label, value: drawDownTypeId }}
                                onChange={(e) => {
                                  if (e) {
                                    if (setDrawDownTypeId) setDrawDownTypeId(e.value)
                                  } else if (setDrawDownTypeId) setDrawDownTypeId(null)
                                  // reSubForm()
                                }}
                              />
                            ):null}
                       
                          </td>
                        </tr>
                      )}
                      <tr><p></p></tr>
                      {bankingModel==="1"?(
                        <tr>
                          <td className="name" disabled="true"><label>Account Type:</label></td>
                          <td className="value">
                            <SelectDropdown
                              width="300px"
                              domID="defer_state_input"
                              isClearable={false}
                              label=""
                              options={ACCOUNT_TYPE_OPTIONS}
                              size="small"
                              disabled
                              initialValue={{ label: ACCOUNT_TYPE_OPTIONS.find(x=>x.value===acctType)?.label, value: acctType }}
                              onChange={(e) => {
                                if (e) {
                                  if (setAcctType) setAcctType(e.value)
                                } else if (setAcctType) setAcctType(null)
                                // reSubForm()
                              }}
                            />
                          </td>
                        </tr>
                      ):(
                        <tr>
                          <td className="name"><label>Account Type:</label></td>
                          <td className="value">
                            <SelectDropdown
                              width="300px"
                              domID="defer_state_input"
                              isClearable={false}
                              label=""
                              options={ACCOUNT_TYPE_OPTIONS}
                              size="small"
                              initialValue={{ label: ACCOUNT_TYPE_OPTIONS.find(x=>x.value===acctType)?.label, value: acctType }}
                              onChange={(e) => {
                                if (e) {
                                  if (setAcctType) setAcctType(e.value)
                                } else if (setAcctType) setAcctType(null)
                                // reSubForm()
                              }}
                            />
                          </td>
                        </tr>
                      )}
                      <tr><p></p></tr>
                      <tr>
                        <td className="name"><label>Account Verified:</label></td>
                        <td className="value">
                          <SelectDropdown
                            width="300px"
                            domID="defer_state_input"
                            isClearable={false}
                            label=""
                            options={ACCOUNT_VERIFIED_OPTIONS}
                            size="small"
                            initialValue={{ label: ACCOUNT_VERIFIED_OPTIONS.find(x=>x.value===accountVerified)?.label, value: accountVerified }}
                            onChange={(e) => {
                              if (e) {
                                if (setAccountVerified) setAccountVerified(e.value)
                              } else if (setAccountVerified) setAccountVerified(null)
                              // reSubForm()
                            }}
                          />
                        </td>
                      </tr>
                    </StyledContainer>
                    <table>
                      <tr><p></p></tr>
                      <tr>
                        <td className="value" colspan="2">
                          <div className='displayFlex'>
                            <Button
                              id='ContactEditorAdd'
                              size="small"
                              name="Cancel"
                              onClick={()=>history.goBack()}
                            />
                            <div className='marginLeft'>
                              <Button
                                id='ContactEditorAdd'
                                size="small"
                                name="Submit"
                                onClick={()=>onSubmitClick()}
                              />
                            </div>
                          </div>
                          {/* <input type="button" value="Back" onClick={()=>history.goBack()} /> */}
                          &nbsp;</td>
                      </tr>
                    </table>
                  </tr>
                </table>
                {renderConfirmationModal}
                {renderChooseOdfiViewModal}
              </div>
            )
        }
      </>
    </>
  )
}

export default PayerEftAccountEdit;