/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import styled from 'styled-components/dist/styled-components'
import { useLayout } from 'ui-core/dist/useLayout';
import DetailPaneHeader from 'ui-core/dist/DetailPaneHeader';
import { useSelector, useDispatch } from 'react-redux';
import {
  Modal, Button, ModalHeader, Input, ModalBody
} from 'ui-core/dist';
import { detailPaneAnimStates } from '../../../commonResources/constants/animStates';
import { SelectDropdown } from '../../../components'
import ErrorPage from '../../ErrorPage';
import LoadingComponent from '../../../components/LoadingComponent';
import useNotificationBannerHook from '../../../useNotificationBannerHook';
import {
  useAddClientContact,
  useUpdateClientContact,
  useDeleteClientContact
} from '../../../api/useClientInformationContactEditor'

const BLOCKING_ERROR = 'cannot insert NULL into ("ABFRAICADMIN"."SYS_CLIENT_EMAIL_RECIPIENTS"."C_EMAIL_CODE"'
const ContactEditorDetail = () => {
  const {
    expandCollapseDetailPaneButtonConfig,
    closeDetailPaneButtonConfig,
    detailAnimationState,
    closeDetailPane
  } = useLayout()

  const { notify } = useNotificationBannerHook();
  const [cfirstnm, setcfirstnm] = useState('')
  const [clastnm, setclastnm] = useState('')
  const [cemailaddr, setcemailaddr] = useState('')
  const [cccemail, setcccemail] = useState('')
  const [ccontactid, setccontactid] = useState('')
  const [isOpenConfirmationModal, setisOpenConfirmationModal] = useState(false)
  const [confirmationModalMode, setconfirmationModalMode] = useState('')

  const [count, setcount] = useState(0)

  const { clientContactEditorRecord, clientContactEditorMode, refetchClientContactEditorRecord }
    = useSelector(({ clientAdministration }) => clientAdministration);

  useEffect(() => {
    if (clientContactEditorRecord) {
      if (clientContactEditorRecord.ccontactid) {
        onResetCurrentRecordValues()
      } else {
        onClearCurrentRecordValues()
      }
    }
  }, [clientContactEditorRecord]);

  const { data: infoAddClientContact, postData: addClientContact, metaData: addClaimTypeTranslationResponse, isLoading: isLoadingaddClientContact,
    isError: isErroraddClientContact, error: erroraddClientContact } = useAddClientContact()

  const { data: infoUpdateRecipType, postData: updateClientContact, metaData: updateClaimTypeTranslationResponse, isLoading: isLoadingupdateClientContact,
    isError: isErrorupdateClientContact, error: errorupdateClientContact } = useUpdateClientContact()

  const { data: infoDeleteRecipType, postData: deleteClientContact, metaData: deleteClaimTypeTranslationResponse, isLoading: isLoadingdeleteClientContact,
    isError: isErrordeleteClientContact, error: errordeleteClientContact } = useDeleteClientContact()

  const onResetCurrentRecordValues = () => {
    if (clientContactEditorRecord && clientContactEditorRecord.ccontactid) {
      const { cfirstnm: cfirstnmp, clastnm: clastnmp, cemailaddr: cemailaddrp, cccemail: cccemailp, ccontactid: ccontactidp } = clientContactEditorRecord
      setcfirstnm(cfirstnmp)
      setclastnm(clastnmp)
      setcemailaddr(cemailaddrp)
      setcccemail(cccemailp)
      setccontactid(ccontactidp)
    }
  }

  useEffect(() => {
    if (addClaimTypeTranslationResponse) {
      const { status, statusText } = addClaimTypeTranslationResponse;
      if (status === 200 || statusText === "OK") {
        if (infoAddClientContact && infoAddClientContact.fs) {
          const { fs } = infoAddClientContact;
          if (!fs || (fs || "").indexOf(BLOCKING_ERROR) === -1) {
            notify('Client Contact Added successfully', 'positive')
            closeDetailPane()
            if (refetchClientContactEditorRecord) refetchClientContactEditorRecord()
            return
          }
          notify(fs, 'negative')
          return;
        }
        notify('Client Contact Added successfully', 'positive')
        closeDetailPane()
        if (refetchClientContactEditorRecord) refetchClientContactEditorRecord()
      }
    }
  }, [addClaimTypeTranslationResponse])

  useEffect(() => {
    if (updateClaimTypeTranslationResponse) {
      const { status, statusText } = updateClaimTypeTranslationResponse;
      if (status === 200 || statusText === "OK") {
        if (infoUpdateRecipType && infoUpdateRecipType.fs) {
          const { fs } = infoUpdateRecipType;
          if (!fs || (fs || "").indexOf(BLOCKING_ERROR) === -1) {
            notify('Client Contact Updated successfully', 'positive')
            closeDetailPane()
            if (refetchClientContactEditorRecord) refetchClientContactEditorRecord()
            return
          }
          notify(fs, 'negative')
          return;
        }
        notify('Client Contact Updated successfully', 'positive')
        closeDetailPane()
        if (refetchClientContactEditorRecord) refetchClientContactEditorRecord()
      }
    }
  }, [updateClaimTypeTranslationResponse])


  useEffect(() => {
    if (deleteClaimTypeTranslationResponse) {
      const { status, statusText } = deleteClaimTypeTranslationResponse;
      if (status === 200 || statusText === "OK") {
        if (infoDeleteRecipType && infoDeleteRecipType.fs) {
          const { fs } = infoDeleteRecipType;
          if (!fs || (fs || "").indexOf(BLOCKING_ERROR) === -1) {
            notify('Client Contact Deleted successfully', 'positive')
            closeDetailPane()
            if (refetchClientContactEditorRecord) refetchClientContactEditorRecord()
            return
          }
          notify(fs, 'negative')
          return;
        }
        notify('Client Contact Deleted successfully', 'positive')
        closeDetailPane()
        if (refetchClientContactEditorRecord) refetchClientContactEditorRecord()
      }
    }
  }, [deleteClaimTypeTranslationResponse])


  const onDeleteClick = () => {
    if (clientContactEditorMode === "Edit") {
      setconfirmationModalMode("Delete")
      setisOpenConfirmationModal(true)
    }
  }
  const onSubmitClick = () => {
    if (clientContactEditorMode === "Add") {
      setconfirmationModalMode("Add")
    } else if (clientContactEditorMode === "Edit") {
      setconfirmationModalMode("Modify")
    }
    setisOpenConfirmationModal(true)
  }
  const onConfirmationYesClick = () => {
    if (confirmationModalMode === "Delete") {
      deleteClientContact({ ccontactid: clientContactEditorRecord?.ccontactid, count })
      setcount(count + 1)

    } else if (confirmationModalMode === "Add") {
      addClientContact({ cfirstnm, clastnm, cemailaddr, cccemail, ccontactid, count })
      setcount(count + 1)

    } else if (confirmationModalMode === "Modify") {
      updateClientContact({ cfirstnm, clastnm, cemailaddr, cccemail, ccontactid, count })
      setcount(count + 1)
    }
    setconfirmationModalMode('')
    setisOpenConfirmationModal(false)
  }

  const onClearCurrentRecordValues = () => {
    setcfirstnm('')
    setclastnm('')
    setcccemail('')
    setcemailaddr('')
    setccontactid('')
  }
  const renderCContactid = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_webRecipientTypeDesc"
      placeholder=""
      size="small"
      maxLength={30}
      disabled
      initialValue={ccontactid}
      onChange={(e) => {
        setccontactid(e.target.value || "")
      }}
    />
  ), [ccontactid])

  const renderCFirstName = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_clientRecipientType"
      placeholder=""
      size="small"
      maxLength={25}
      initialValue={cfirstnm}
      errorMessage="First name is required"
      hasError={cfirstnm?false:true}
      onChange={(e) => {
        setcfirstnm(e.target.value || "")
      }}
    />
  ), [cfirstnm])

  const renderCLastName = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_webRecipientTypeDesc"
      placeholder=""
      size="small"
      maxLength={30}
      initialValue={clastnm}
      errorMessage="Last name is required"
      hasError={clastnm?false:true}
      onChange={(e) => {
        setclastnm(e.target.value || "")
      }}
    />
  ), [clastnm])

  const renderCEmailAddress = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_webRecipientTypeDesc"
      placeholder=""
      size="small"
      maxLength={128}
      initialValue={cemailaddr}
      errorMessage="Enter valid Email Address"
      hasError={cemailaddr ? !/\S+@\S+\.\S+/.test(cemailaddr) : true}
      onChange={(e) => {
        setcemailaddr(e.target.value || "")
      }}
    />
  ), [cemailaddr])

  const renderCCEmailAddress = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_webRecipientTypeDesc"
      placeholder=""
      size="small"
      maxLength={128}
      initialValue={cccemail}
      errorMessage="Incorrect data in cemail addr"
      hasError={cccemail ? !/\S+@\S+\.\S+/.test(cccemail) : false}
      onChange={(e) => {
        setcccemail(e.target.value || "")
      }}
    />
  ), [cccemail])

  const validateForm = useMemo(() => {
    if (!cfirstnm || !clastnm || !cemailaddr) {
      return false;
    }
    if (cemailaddr && !/\S+@\S+\.\S+/.test(cemailaddr)) {
      return false;
    }
    if (cccemail && !/\S+@\S+\.\S+/.test(cccemail)) {
      return false;
    }
    return true;
  }, [cfirstnm, clastnm, cemailaddr, cccemail])

  const disabledDeleteButton = useMemo(() => {
    if (clientContactEditorMode === "Edit") {
      if (clientContactEditorRecord?.hasReportMapping) {
        return true;
      }
      return false;
    }
    return true;
  }, [clientContactEditorMode, clientContactEditorRecord])
  const renderClientContactEditorForm = useMemo(() => {
    if (detailAnimationState > detailPaneAnimStates.COLLAPSED) {
      return (
        <div style={{ marginLeft: '40px' }} className="marginLeft">
          <div>
            <p>
              <div className="displayFlex">Complete the information below, and then click Submit to continue.</div>
            </p>
            <p>
              <div className="displayFlex"><span style={{ color: 'red' }}>* indicates required information</span></div>
            </p>
          </div>
          <br />
          <div className="displayFlex">
            <table className="common-table">
              <tr>
                <td>
                  <label> Contact ID : </label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderCContactid}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <label> First Name: <span style={{ color: 'red' }}>*</span></label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderCFirstName}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <label> Last Name: <span style={{ color: 'red' }}>*</span></label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderCLastName}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <label> Email Address: <span style={{ color: 'red' }}>*</span></label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderCEmailAddress}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <label> CC Email Address: </label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderCCEmailAddress}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td colSpan="2">
                  <div className="displayFlex">
                    <div>
                      <Button
                        domID="btn_reset"
                        buttonType="standard"
                        size="small"
                        name="RESET"
                        disabled={clientContactEditorMode !== "Edit"}
                        onClick={() => onResetCurrentRecordValues()}
                      />
                    </div>
                    <div className="marginLeft">
                      <Button
                        domID="btn_cancel"
                        buttonType="standard"
                        size="small"
                        name="CLEAR"
                        onClick={() => onClearCurrentRecordValues()}
                      />
                    </div>
                    <div className="marginLeft">
                      <Button
                        domID="btn_delete"
                        buttonType="standard"
                        size="small"
                        name="DELETE"
                        disabled={disabledDeleteButton}
                        onClick={() => onDeleteClick()}
                      />
                    </div>
                    <div className="marginLeft">
                      <Button
                        domID="btn_search"
                        buttonType="standard"
                        size="small"
                        name="SUBMIT"
                        disabled={!validateForm}
                        onClick={() => onSubmitClick()}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
            </table>
          </div>

          <Modal isOpen={isOpenConfirmationModal}>
            <ModalHeader
              title={`Do you want to ${confirmationModalMode} ?`}
              onClose={() => setisOpenConfirmationModal(false)}
            />
            <ModalBody>
              {isOpenConfirmationModal ? (
                <div style={{ marginLeft: '34px', marginBottom: '20px' }} className="marginLeft">
                  <div className="displayFlex">
                    <div className="marginLeft">
                      <Button
                        domID="btn_search"
                        buttonType="standard"
                        size="small"
                        name="Yes"
                        onClick={() => onConfirmationYesClick()}
                      />
                    </div>
                    <div className="marginLeft">
                      <Button
                        domID="btn_search"
                        buttonType="standard"
                        size="small"
                        name="No"
                        onClick={() => setisOpenConfirmationModal(false)}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
            </ModalBody>
          </Modal>
        </div>
      )
    }
    return null;
  }, [detailAnimationState,
    cfirstnm, clastnm, cemailaddr, cccemail, ccontactid, validateForm, clientContactEditorRecord, clientContactEditorMode, isOpenConfirmationModal, confirmationModalMode])

  return (
    <>
      <DetailPaneHeader
        title={clientContactEditorMode === "Edit" ? "Modify Client Contact" : "Add Client Contact"}
        layoutControls={[
          expandCollapseDetailPaneButtonConfig,
          closeDetailPaneButtonConfig
        ]}
      />
      <>
        {isLoadingaddClientContact
          || isLoadingdeleteClientContact || isLoadingupdateClientContact ? (
            // replace with a better loading component
            <div style={{ marginLeft: '50%' }}><LoadingComponent /></div>
          ) : isErroraddClientContact
            || isErrordeleteClientContact || isErrorupdateClientContact ? (
              // replace with a better error component
              <ErrorPage
                // this prop isn't real but is an example
                error={erroraddClientContact
                  || errordeleteClientContact || errorupdateClientContact}
              />
            ) : (
              <>
                {renderClientContactEditorForm}
              </>
            )
        }
      </>
    </>
  )
};

export default ContactEditorDetail