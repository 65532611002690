import React from 'react'
import { useSelector } from 'react-redux'
import { Page } from '../../components'
import ListPaneContent from './TestFileManagerListPane'
import FileDetails from './TestFileManagerFileDetails'
import Upload from './TestFileManagerUpload'
import RouteWrapper from '../../components/RouteWrapper'


const TestFileManager = () => {
  const isUpload = useSelector(store => store.testFileManager.isUpload)

  return (
    <RouteWrapper>
      <Page 
        ListPaneContent={ListPaneContent}
        DetailPaneContent={isUpload ? Upload : FileDetails}
      />
    </RouteWrapper>
  )
}
export default TestFileManager