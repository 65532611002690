import styled from 'styled-components';
import { LoadingContainer } from '../../../../shared/styles/styledComponents';

export const DetailsPane = styled.div`
  position: absolute;
  top: 0;
  right: ${props => (props.isExpanded ? '0' : '-65%')};
  width: 65%;
  height: 100%;
  background-color: #fff;
  border-left: 1px solid #afb6bd;
  box-shadow: -1px 0 3px 0 rgba(0, 0, 0, 0.1);
  transition: right 500ms ease-in-out 0s;
  overflow-x: hidden;
`;

export const CenteredLoadingContainer = styled(LoadingContainer)`
  margin-top: 4em;
`;
