import React, { useMemo, useState, useEffect, useRef, useContext } from 'react';
import { useSelector } from 'react-redux';
import { NotificationBanner } from 'ui-core/dist';
import NotificationContext, { notificationDefaultValue } from './notificationContext'
import ApplicationRoutes from './applicationRoutes';
import ErrorContext from './ErrorContext';
import { AuthProvider } from "react-oidc-context";
import {OICoreConfig} from "./config/oicore-config.js";

const BootstrapComponent = () => {
  
  const { clearNotification } = useSelector(({ session }) => session)
  const historyRef = useRef(null)
  const errorContext = useContext(ErrorContext);
  const getHistoryRef = (ref) => {
    if (ref) historyRef.current = ref;
    if (ref && errorContext.getHistoryRef) errorContext.getHistoryRef(ref)
  }
  useEffect(() => {
    return historyRef?.current?.listen(location => {
      if (location.pathname && (historyRef.current.action === "POP" || historyRef.current.action === "PUSH") || clearNotification) {
        toggleShowNotification()
      }
    })
  }, [clearNotification])

  const defaultNotificationConfig = {
    showNotificationBanner: false,
    notificationText: '',
    notificationType: 'neutral',
    notificationDOMId: '',
    notificationDataTestId: '',
    notificationAutoDismiss: true,
    timer: 30000000,
  }
  const [notificationConfig, setnotificationConfig] = useState(defaultNotificationConfig)
  const getRandomInt = (max) => Math.floor(Math.random() * Math.floor(max));
  const notify = (notificationText, notificationType, notificationAutoDismiss,
    timer) => {
    const uniqueId = getRandomInt(99999999999).toString();
    if (notificationText && notificationText.indexOf('connect ECONNREFUSED') !== -1 ||
      notificationText && notificationText.indexOf('ENOTFOUND') !== -1) {
      notificationText = `You may be experiencing intermittent access to the Client Access System. The website should be fully accessible shortly.
               If it is before 10:00 am (cst) and you are attempting to manage documents, please contact a Client Analyst at (800) 804-7430 and they can assist
               with this transaction(s).`;
    } else if (notificationText && notificationText.indexOf('connect ETIMEDOUT') !== -1) {
      notificationText = `You may be experiencing intermittent access to the Client Access System. The website should be fully accessible shortly.`;
    }
    let notificationTextTemp = "";
    if (notificationType === 'negative') {
      if (notificationText?.includes("not able to authenticate you at this time")) {
        notificationTextTemp = notificationText;
      }
      else {
        notificationTextTemp = `There were errors processing your request: ${notificationText}`;
      }
    }

    setnotificationConfig(state => JSON.parse(JSON.stringify({
      ...state,
      showNotificationBanner: true,
      notificationText: notificationType === 'negative' ? notificationTextTemp : notificationText,
      notificationType: notificationType || defaultNotificationConfig?.notificationType,
      notificationDOMId: `${uniqueId}_notificationId`,
      notificationDataTestId: `${uniqueId}_notificationTestId`,
      notificationAutoDismiss: notificationAutoDismiss || defaultNotificationConfig?.notificationAutoDismiss,
      timer: timer || defaultNotificationConfig?.timer,
    })))
  }
  const toggleShowNotification = () => {
    setnotificationConfig(state => JSON.parse(JSON.stringify({
      ...state,
      showNotificationBanner: false
    })))
  }
  const notificationValue = { ...notificationDefaultValue, toggleShowNotification, notify };

  const renderNotificationBanner = useMemo(() => (
    <div hidden={!notificationConfig?.showNotificationBanner}>
      <NotificationBanner
        key={notificationConfig.notificationDOMId}
        dataTestId={notificationConfig?.notificationDataTestId}
        domID={notificationConfig?.notificationDOMId}
        onDismiss={toggleShowNotification}
        text={notificationConfig?.notificationText}
        type={notificationConfig?.notificationType}
        autoDismiss={notificationConfig?.notificationAutoDismiss}
        timer={notificationConfig?.timer}
      />
    </div>
  ), [notificationConfig])

  return (
    <NotificationContext.Provider value={notificationValue}>
      {/* <ApplicationRoutes /> */}
      <>
        <AuthProvider {...OICoreConfig.sentinel}>
          <ApplicationRoutes getHistoryRef={getHistoryRef} />
          {renderNotificationBanner}
        </AuthProvider>
      </>
    </NotificationContext.Provider>
  )
}

export default BootstrapComponent;