const initialState = {
  faultcode:'',
  faultstring:'',
  faultactor:'',
  returnCode:'',
  errors:[]
}
    
export default (state = initialState, { type, payload }) => {
  switch(type) {
    case "SET_ERRORS":
      return {
        ...state,
        faultcode:payload?payload.fc||"":"",
        faultstring:payload?payload.fs||"":"",
        faultactor:payload?payload.fa||"":"",
        returnCode:payload?payload.rc||"":"",
        errors:payload?JSON.parse(JSON.stringify(payload.errors||[])):null
      }
    case "RESET_ERRORS":
      return {
        ...state,
        faultcode:'',
        faultstring:'',
        faultactor:'',
        returnCode:''
      }
    default:
      return state;
  }
}