import React from 'react';
import { Page } from '../../components';
import ChangePassword from './ChangePassword';

const ErrorManager = () => (
  <Page
    ListPaneContent={ChangePassword}
    // DetailPaneContent={DashboardDetail}
  />
);

export default ErrorManager;
