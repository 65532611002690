import React from 'react';
import ErrorContext from '../../ErrorContext'

export default function withErrorBoundary(Component) {

    class ErrorBoundary extends React.Component {
        static contextType = ErrorContext;
        constructor(props) {
            super(props);
            this.state = { hasError: false };
        }

        componentDidCatch(error, info) {
            this.setState({ hasError: true });
            if (this.context && this.context?.handleCustomError) {
                this.context?.handleCustomError(error, info, Component.componentDisplayName || Component.displayName || Component.name || 'Component');
            }
        }

        render() {
            if (this.state.hasError) {
                // You can render any custom fallback UI
                return <></>;
            }
            return this.props.children;
        }
    }

    class ErrorWrapper extends React.Component {
        render() {
            return (
                <ErrorBoundary>
                    <Component {...this.props} />
                </ErrorBoundary>
            )
        }
    }
    return ErrorWrapper;
}