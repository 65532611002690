import { connect } from 'react-redux';
import GridWrapper from './components/GridWrapper';
import {
  triggerAllGroupsRequest,
  recordCountFromDBTrigger,
  recordCountByStatusAndKeywordTriggerDB,
  resetGroupsRecords,
} from '../../redux/api/groupsList/actions';
import {
  setIsCloningGroupState,
  setIsShowingActivateGroupModal,
  setIsShowingDeactivateGroupModal,
  setIsShowingCloneGroupModal,
  triggerActivateGroupRequest,
  triggerDeactivateGroupRequest
} from '../../redux/api/groups/actions';
import {
  triggerRuleTypesRequest,
  setSelectedDetailsRecordId,
  resetRuleTypesListReceived,
} from '../../redux/api/ruleTypesList/actions';
import { resetDetailsPane, setDefaultGroupDetailsTab } from '../../redux/app/detailsPaneState/actions';
import {
  addNotification,
  deleteNotification,
} from '../../redux/app/notificationState/actions';
import { selectCurrentDetailsRecord } from '../GroupDetailView/selectors';
import {selectUserRole,selectUserPermissions, selectUserRoleId} from '../RulesTabView/selectors';
import {
  selectGroupCloningState,
  selectNotificationState,
} from '../BasicTabDetailsView/selectors';
import { resetRuleDetailElements } from '../../redux/api/ruleDetails/actions';
import {
  captureCurrentPage,
} from '../../redux/api/pageTracker/actions';

const mapStateToProps = state => ({
  ...state,
  ...state.groupsList,
  loading: state.loadingState.loading,
  isCloningGroup: selectGroupCloningState(state),
  isShowingActivateGroupModal: state.groups.isShowingActivateGroupModal,
  isShowingDeactivateGroupModal: state.groups.isShowingDeactivateGroupModal,
  isMulti: state.groups.isMulti,
  isActivatingGroup: state.groups.isActivatingGroup,
  isDeactivatingGroup: state.groups.isDeactivatingGroup,
  notifications: selectNotificationState(state),
  selectedRecord: selectCurrentDetailsRecord(state),
  showCloneGroupModal: state.groups.showCloneGroupModal,
  groupGridQuery: state.groupsList.groupGridQuery,
  userRole: selectUserRole(state),
  userPermissions: selectUserPermissions(state),
  userRoleId: selectUserRoleId(state)
});

const mapDispatchToProps = {
  addNotification,
  deleteNotification,
  setIsCloningGroupState,
  setIsShowingCloneGroupModal,
  setIsShowingActivateGroupModal,
  setIsShowingDeactivateGroupModal,
  setSelectedDetailsRecordId,
  setDefaultGroupDetailsTab,
  triggerActivateGroupRequest,
  triggerDeactivateGroupRequest,
  triggerAllGroupsRequest,
  triggerRuleTypesRequest,
  resetRuleDetailElements,
  resetDetailsPane,
  recordCountFromDBTrigger,
  recordCountByStatusAndKeywordTriggerDB,
  resetRuleTypesListReceived,
  resetGroupsRecords,
  captureCurrentPage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GridWrapper);
