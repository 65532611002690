export const EEOB_FORM_MANAGER_GRID={
  columns: new Set([
    {
      dataName: 'description',
      text: 'Description',
      sortable: true,
      isSorted: 0,
      cellType: 'text',
    },
  ]),
  selectedItemIds: new Set([]),
  initialSelectedItemIds: new Set([]),
  bookmarkedItemIds: new Set([]),
  domID: 'eeobFormManagerGrid',
  dataTestId: 'eeobFormManagerGrid',
  isConfigurable: true,
  supportSelection: false
};