/* eslint-disable react/prop-types */
import React, {
  Children,
  isValidElement,
  cloneElement
} from 'react';
import { isFragment } from "react-is";
import withErrorBoundary from '../../hooks/withErrorBoundary';


/*************** 1. ***************/
function flattenChildren(
  // only needed argument
  children,
  // only used for debugging
  depth,
  // is not required, start with default = []
  keys = []
) {
  /*************** 2. ***************/
  return Children.toArray(children).reduce(
    (acc, node, nodeIndex) => {
      if (isFragment(node)) {
        /*************** 5. ***************/
        acc.push.apply(
          acc,
          flattenChildren(
            node.props.children,
            depth + 1,
            /*************** 6. ***************/
            keys.concat(node.key || nodeIndex)
          )
        );
      } else {
        /*************** 4. ***************/
        if (isValidElement(node)) {
          acc.push(
            cloneElement(node, {
              /*************** 6. ***************/
              key: keys.concat(String(node.key)).join('.')
            })
          );
          if(node.props.children && Children.toArray(node.props.children).length>0){
            acc.push.apply(
              acc,
              flattenChildren(
                node.props.children,
                depth + 1,
                /*************** 6. ***************/
                keys.concat(node.key || nodeIndex)
              )
            );
          }
        } else if (
          /*************** 3. ***************/
          typeof node === "string"
          || typeof node === "number"
        ) {
          acc.push(node);
        }
      }
      return acc;
    },
    /*************** Acculumator Array ***************/
    []
  );
}
function flattenChildren1(
  // only needed argument
  children,
  // only used for debugging
  depth,
  // is not required, start with default = []
  keys = []
) {
  //debugger
  /*************** 2. ***************/
  return Children.toArray(children).map(
    (node, nodeIndex) => {
      //debugger
      if (isFragment(node)) {
        /*************** 5. ***************/
        return cloneElement(node, 
          {...node.props}, 
          flattenChildren1(
            node.props.children,
            depth + 1,
            /*************** 6. ***************/
            keys.concat(node.key || nodeIndex)
          ))
      } else {
        /*************** 4. ***************/
        if (isValidElement(node)) {
          if(node.type.name==="Page" && node.props.ListPaneContent){
            return cloneElement(node, 
              {...node.props, 
                ListPaneContent: node.props.ListPaneContent ? withErrorBoundary(node.props.ListPaneContent): null,
                DetailPaneContent: node.props.DetailPaneContent ? withErrorBoundary(node.props.DetailPaneContent): null
              }, 
              flattenChildren1(
                node.props.children,
                depth + 1,
                /*************** 6. ***************/
                keys.concat(node.key || nodeIndex)
              ))
          }
          return cloneElement(node, 
            {...node.props}, 
            flattenChildren1(
              node.props.children,
              depth + 1,
              /*************** 6. ***************/
              keys.concat(node.key || nodeIndex)
            ))
        } else if (
          /*************** 3. ***************/
          typeof node === "string"
          || typeof node === "number"
        ) {
          return cloneElement(node, {...node.props})
        }
      }
    }
  );
}
const RouteWrapper = ({ children }) => {
//   const s = flattenChildren(
//     children
//   ).map((child, index) => {
//     return child.key
//   })
//   console.log(s.join('\n'))
  return (
    <>
      {flattenChildren1(
        children
      )}
    </>
  )
}

export default RouteWrapper;