/* eslint-disable no-useless-escape */
/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useLayout } from 'ui-core/dist/useLayout';
import DetailPaneHeader from 'ui-core/dist/DetailPaneHeader';
import { useSelector } from 'react-redux';
import {
  Modal, Button, TextArea, ModalHeader, Input, ModalBody
} from 'ui-core/dist';
import { detailPaneAnimStates } from '../../commonResources/constants/animStates';
import ErrorPage from '../ErrorPage';
import LoadingComponent from '../../components/LoadingComponent';
import {
  useAddEEOBForm, useGetEEOBFormByName, useGetNextEEOBFormId, useUpdateEEOBForm
} from '../../api/useEEOBFormManager'
import useNotificationBannerHook from '../../useNotificationBannerHook';

// const StyledTextArea=styled(TextArea)`
//   & textarea#txt_body {
//       width: ${props=>props.width};
//       height: ${props=>props.heigth};
//   }
//   `
const isHTMLExist = (str)=>{
  var a = document.createElement('div');
  a.innerHTML = str;
  for(var c = a.childNodes, i=c.length; i--;){
    if(c[i].nodeType == 1) return true;
  }
  return false;
}
const EEOBFormManagerDetail = () => {
  const {
    expandCollapseDetailPaneButtonConfig,
    closeDetailPaneButtonConfig,
    detailAnimationState,
    closeDetailPane
  } = useLayout()

  const { notify } = useNotificationBannerHook();
  const [body, setbody] = useState('')
  const [description, setdescription] = useState('')
  const [formId, setformId]=useState('')
  const [fromAddress, setfromAddress] = useState('')
  const [fromName, setfromName] = useState('')
  const [subject, setsubject] = useState('')
  const [confirmationModalMode, setconfirmationModalMode] = useState('')
  const [isOpenConfirmationModal, setisOpenConfirmationModal] = useState(false)

  const [fromNameValidState, setfromNameValidState]=useState(true)
  const [fromNameValidationMessage, setfromNameValidationMessage]=useState('')
  const [fromAddressValidState, setfromAddressValidState]=useState(true)
  const [fromAddressValidationMessage, setfromAddressValidationMessage]=useState('')
  const [bodyValidState, setbodyValidState]=useState(true)
  const [bodyValidationMessage, setbodyValidationMessage]=useState('')

  const [count, setcount] = useState(0)

  const { eeobFormManagerRecord, eeobFormManagerMode, refetchEEOBFormManager }
    = useSelector(({ eeobFormManager }) => eeobFormManager);

  const { nextEEOBFormId, postData: getNextEEOBFormId, isLoading: isLoadingnextEEOBFormId,
    isError: isErrornextEEOBFormId, error: errornextEEOBFormId } = useGetNextEEOBFormId()

  const { showMoreInfo, postData: getEEOBFormByName, isLoading: isLoadingShowMoreInfo,
    isError: isErrorShowMoreInfo, error: errorShowMoreINfo } = useGetEEOBFormByName()

  const { data: infoAddEEOBForm, addEEOBForm, metaData: addEEOBFormResponse, isLoading: isLoadingaddEEOBForm,
    isError: isErroraddEEOBForm, error: erroraddEEOBForm } = useAddEEOBForm()

  const { data: infoUpdateEEOBForm, updateEEOBForm, metaData: updateEEOBFormResponse, isLoading: isLoadingupdateEEOBForm,
    isError: isErrorupdateEEOBForm, error: errorupdateEEOBForm } = useUpdateEEOBForm()

  useEffect(() => {
    if (eeobFormManagerRecord && detailAnimationState > detailPaneAnimStates.COLLAPSED) {
      if (eeobFormManagerMode==="Edit") {
        getShowMoreData(eeobFormManagerRecord.id);
      } else if(eeobFormManagerMode==="Add") {
        onClearCurrentRecordValues()
        getNextEEOBFormId({count})
        setcount(count+1)
      }
    }
  }, [eeobFormManagerRecord, eeobFormManagerMode]);

  const getShowMoreData = (id) => {
    getEEOBFormByName({ strFormName: id, count })
    setcount(count + 1)
  }
  useEffect(()=>{
    if (nextEEOBFormId && nextEEOBFormId.resp) {
      setformId(nextEEOBFormId.resp)
    }
  },[nextEEOBFormId])
  useEffect(() => {
    onResetCurrentRecordValues()
  }, [showMoreInfo])

  const onClearCurrentRecordValues = () => {
    setbody('')
    setdescription('')
    setfromAddress('')
    setfromName('')
    setsubject('')
  }

  const onResetCurrentRecordValues = () => {
    if (showMoreInfo) {
      const {cBody, cDesc, cForm, cFromAddress, cFromName, cSubject}=showMoreInfo;
      setbody(cBody)
      setdescription(cDesc)
      setformId(cForm)
      setfromAddress(cFromAddress)
      setfromName(cFromName)
      setsubject(cSubject)
    }
  }

  useEffect(() => {
    if (addEEOBFormResponse) {
      const { status, statusText } = addEEOBFormResponse;
      if (status === 200 || statusText === "OK") {
        if (infoAddEEOBForm && infoAddEEOBForm.fs) {
          const { fs } = infoAddEEOBForm;
          if (fs) {
            notify(fs, 'negative')
            return;
          }
        }
        notify('EEOB Form Added successfully', 'positive')
        closeDetailPane()
        if (refetchEEOBFormManager) refetchEEOBFormManager()
      }
    }
  }, [addEEOBFormResponse])

  useEffect(() => {
    if (updateEEOBFormResponse) {
      const { status, statusText } = updateEEOBFormResponse;
      if (status === 200 || statusText === "OK") {
        if (infoUpdateEEOBForm && infoUpdateEEOBForm.fs) {
          const { fs } = infoUpdateEEOBForm;
          if (fs) {
            notify(fs, 'negative')
            return;
          }
        }
        notify('EEOB Form Updated successfully', 'positive')
        closeDetailPane()
        if (refetchEEOBFormManager) refetchEEOBFormManager()
      }
    }
  }, [updateEEOBFormResponse])

  const onSubmitClick = () => {
    if (eeobFormManagerMode === "Add") {
      setconfirmationModalMode("Add")
    } else if (eeobFormManagerMode === "Edit") {
      setconfirmationModalMode("Modify")
    }
    setisOpenConfirmationModal(true)
  }
  const onConfirmationYesClick = () => {
    if (confirmationModalMode === "Add") {
      addEEOBForm({
        count,
        body,
        description,
        formId,
        fromAddress,
        fromName,
        subject,
      })
      setcount(count + 1)

    } else if (confirmationModalMode === "Modify") {
      updateEEOBForm({
        count,
        body,
        description,
        formId,
        fromAddress,
        fromName,
        subject,
      })
      setcount(count + 1)
    }
    setconfirmationModalMode('')
    setisOpenConfirmationModal(false)
  }
  const renderBody = useMemo(() => (
    <TextArea
      className="test-classname"
      domID="txt_body"
      placeholder=""
      resize="both"
      width="400px"
      heigth="200px"
      autoFocus
      initialValue={body}
      errorMessage={bodyValidationMessage}
      hasError={!bodyValidState}
      onChange={(e)=>{
        setbody(e.target.value || "")
      }}
    />
  ), [body, bodyValidState, bodyValidationMessage])
  const renderDescription = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_description"
      placeholder=""
      size="small"
      maxLength={500}
      errorMessage="Please enter Form Description"
      hasError={description?false:true}
      initialValue={description}
      onChange={(e) => {
        setdescription(e.target.value || "")
      }}
    />
  ), [description])
  const renderFormId = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_formId"
      placeholder=""
      size="small"
      disabled
      maxLength={11}
      errorMessage="Form name not found"
      hasError={formId?false:true}
      initialValue={formId}
      onChange={(e) => {
        setformId(e.target.value || "")
      }}
    />
  ), [formId])
  const renderFromAddress = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_fromAddress"
      placeholder=""
      size="small"
      maxLength={500}
      errorMessage={fromAddressValidationMessage}
      hasError={!fromAddressValidState}
      initialValue={fromAddress}
      onChange={(e) => {
        setfromAddress(e.target.value || "")
      }}
    />
  ), [fromAddress, fromAddressValidState, fromAddressValidationMessage])
  const renderFromName = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_fromName"
      placeholder=""
      size="small"
      maxLength={500}
      errorMessage={fromNameValidationMessage}
      hasError={!fromNameValidState}
      initialValue={fromName}
      onChange={(e) => {
        setfromName(e.target.value || "")
      }}
    />
  ), [fromName, fromNameValidState, fromNameValidationMessage])
  const renderSubject = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_subject"
      placeholder=""
      size="small"
      maxLength={500}
      errorMessage="Please enter Subject"
      hasError={subject?false:true}
      initialValue={subject}
      onChange={(e) => {
        setsubject(e.target.value || "")
      }}
    />
  ), [subject])
  const validateForm = useMemo(() => {
    let valid=true;
    if (!body || !description || !formId || !subject) {
      valid=false;
    } else{
      valid=true;
    }
    if (!fromName) {
      setfromNameValidState(false)
      setfromNameValidationMessage('Please enter From Name')
      valid=false;
    } else if (fromName.indexOf('@') >= 0) {
      setfromNameValidState(false)
      setfromNameValidationMessage("The From name needs to be input in the form of a name and cannot be an e-mail address")
      valid=false;
    } else{
      setfromNameValidState(true)
      setfromNameValidationMessage('')
    }
    if(!fromAddress){
      setfromAddressValidState(false)
      setfromAddressValidationMessage('Please enter From Email Address')
      valid=false;
    } else if (fromAddress!=="" && !(/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(fromAddress))) {
      setfromAddressValidState(false)
      setfromAddressValidationMessage("Please enter a valid email address");
      valid=false;
    } else{
      setfromAddressValidState(true)
      setfromAddressValidationMessage('')
    }
    if (!body) {
      setbodyValidState(false)
      setbodyValidationMessage('Please enter Email Body')
      valid=false;
    } 
    // else if (body!=="" && !(/([\<])([^\>]{1,})*([\>])/i.test(body))) {
      else if (body!=="" && !isHTMLExist(body)) {
      setbodyValidState(false)
      setbodyValidationMessage("HTML in Email Body is not valid.");
      valid=false;
    } else{
      setbodyValidState(true)
      setbodyValidationMessage('')
    }
    return valid;
  }, [body, description, formId, fromAddress, fromName, subject])

  
  const renderEEOBFormManagerForm = useMemo(() => {
    if (detailAnimationState > detailPaneAnimStates.COLLAPSED) {
      return (
        <div style={{ marginLeft: '40px' }} className="marginLeft">
          <div>
            <p>
              <div className="displayFlex">Complete the information below, and then click Submit to continue.</div>
            </p>
            <p>
              <div className="displayFlex"><span style={{ color: 'red' }}>* indicates required information</span></div>
            </p>
          </div>
          <br />
          <div className="displayFlex">
            <table className="common-table">
              <tr>
                <td>
                  <label> eEOB Form Description: <span style={{ color: 'red' }}>*</span></label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderDescription}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <label> eEOB Form Name : <span style={{ color: 'red' }}>*</span></label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderFormId}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <label> From Email Address: <span style={{ color: 'red' }}>*</span></label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderFromAddress}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <label> From Name: <span style={{ color: 'red' }}>*</span></label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderFromName}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <label> Subject: <span style={{ color: 'red' }}>*</span></label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderSubject}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <label> Email Body: <span style={{ color: 'red' }}>*</span></label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderBody}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td colSpan="2">
                  <div className="displayFlex">
                    <div>
                      <Button
                        domID="btn_exclReset"
                        buttonType="standard"
                        size="small"
                        name="RESET"
                        disabled={eeobFormManagerMode !== "Edit"}
                        onClick={() => onResetCurrentRecordValues()}
                      />
                    </div>
                    <div className="marginLeft">
                      <Button
                        domID="btn_exclCancel"
                        buttonType="standard"
                        size="small"
                        name="CLEAR"
                        onClick={() => onClearCurrentRecordValues()}
                      />
                    </div>
                    <div className="marginLeft">
                      <Button
                        domID="btn_exclSearch"
                        buttonType="standard"
                        size="small"
                        name="SUBMIT"
                        disabled={!validateForm}
                        onClick={() => onSubmitClick()}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
            </table>
          </div>
          <Modal isOpen={isOpenConfirmationModal}>
            <ModalHeader
              title={`Do you want to ${confirmationModalMode} ?`}
              onClose={() => setisOpenConfirmationModal(false)}
            />
            <ModalBody>
              {isOpenConfirmationModal ? (
                <div style={{ marginLeft: '34px', marginBottom: '20px' }} className="marginLeft">
                  <div className="displayFlex">
                    <div className="marginLeft">
                      <Button
                        domID="btn_exclYes"
                        buttonType="standard"
                        size="small"
                        name="Yes"
                        onClick={() => onConfirmationYesClick()}
                      />
                    </div>
                    <div className="marginLeft">
                      <Button
                        domID="btn_exclNo"
                        buttonType="standard"
                        size="small"
                        name="No"
                        onClick={() => setisOpenConfirmationModal(false)}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
            </ModalBody>
          </Modal>
        </div>
      )
    }
    return null;
  }, [
    eeobFormManagerRecord, showMoreInfo, detailAnimationState,
    body, description, formId, fromAddress, fromName, subject, validateForm,
    eeobFormManagerMode, confirmationModalMode, isOpenConfirmationModal,
    fromNameValidState, fromNameValidationMessage,
    fromAddressValidState, fromAddressValidationMessage,
    bodyValidState, bodyValidationMessage
  ])

  return (
    <>
      <DetailPaneHeader
        title={eeobFormManagerMode === "Edit" ? "Modify EEOB Form" : "Add EEOB Form"}
        layoutControls={[
          expandCollapseDetailPaneButtonConfig,
          closeDetailPaneButtonConfig
        ]}
      />
      <>
        {isLoadingShowMoreInfo || isLoadingaddEEOBForm
          || isLoadingupdateEEOBForm || isLoadingnextEEOBFormId ? (
            // replace with a better loading component
            <div style={{ marginLeft: '50%' }}><LoadingComponent /></div>
          ) : isErrorShowMoreInfo || isErroraddEEOBForm
            || isErrorupdateEEOBForm ? (
              // replace with a better error component
              <ErrorPage
                // this prop isn't real but is an example
                error={errorShowMoreINfo || erroraddEEOBForm
                  || errorupdateEEOBForm}
              />
            ) : (
              <>
                {renderEEOBFormManagerForm}
              </>
            )
        }
      </>
    </>
  )
}

export default EEOBFormManagerDetail
