export const ALL_GROUPS_REQUEST_TRIGGER = 'ALL_GROUPS_REQUEST_TRIGGER';
export const ALL_GROUPS_REQUEST_SUCCESS = 'ALL_GROUPS_REQUEST_SUCCESS';
export const ALL_GROUPS_REQUEST_ERROR = 'ALL_GROUPS_REQUEST_ERROR';
export const UPDATE_RULETYPES_REQUEST_TRIGGER =
  'UPDATE_RULETYPES_REQUEST_TRIGGER';

export const RECORD_COUNT_FROM_DB_SUCCESS = 'RECORD_COUNT_FROM_DB_SUCCESS';
export const RECORD_COUNT_FROM_DB_ERROR = 'RECORD_COUNT_FROM_DB_ERROR';
export const RECORD_COUNT_FROM_DB_TRIGGER = 'RECORD_COUNT_FROM_DB_TRIGGER';

export const RECORD_COUNT_WITH_STATUS_KEYWORD_FROM_DB_ERROR =
  'RECORD_COUNT_WITH_STATUS_KEYWORD_FROM_DB_ERROR';
export const RECORD_COUNT_WITH_STATUS_KEYWORD_FROM_DB_SUCCESS =
  'RECORD_COUNT_WITH_STATUS_KEYWORD_FROM_DB_SUCCESS';
export const RECORD_COUNT_WITH_STATUS_KEYWORD_FROM_DB_TRIGGER =
  'RECORD_COUNT_WITH_STATUS_KEYWORD_FROM_DB_TRIGGER';
export const RESET_GROUPS_RECORDS = 'RESET_GROUPS_RECORDS';
export const RESET_GROUPS_LIST = 'RESET_GROUPS_LIST';
