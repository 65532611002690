/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useLayout, Button, Modal, ModalHeader, ButtonGroup, ModalFooter, ModalBody } from 'ui-core/dist';
import moment from 'moment';
import styled from 'styled-components/dist/styled-components';
import { LoadingPage, CountDownTimer } from '../../components';
import ErrorPage from '../ErrorPage';
import ListPaneHeaderWrapper from '../../components/ErrorDisplaySection/ListPaneHeaderWrapper';
import { useCurrentTime, useReleaseTime, useOverrideFileCutoff, useGetEligibleFileKeysFileCutoff } from '../../api/useDocumentManager';

import { FILEKEY_LIST_NOT_AVAILABLE } from './Modules/constants';
import MultipleSelectDropdown from '../../components/MultipleSelectDropdown';

import './Modules/styles.scss';

const StyledList = styled.ul`
list-style-type:none;
display:block;
font-size:10px;
margin-top:-9px;
`
const StyledListItem = styled.li`
display:inline-block;
border:1px solid black;
margin-right:10px;
margin-top:5px;
padding-left:10px;
padding-right:10px;
text-align:center;
width:${props => props.width || 'auto'}
`

const OverrideFileCutoff = () => {
  const {
    expandCollapseListPaneButtonConfig
  } = useLayout()

  const { result, setOverride } = useOverrideFileCutoff();
  const { data, isError, error, isLoading, getEligibleFileKeys } = useGetEligibleFileKeysFileCutoff();
  const [selectedFileKeys, setselectedFileKeys] = useState([]);
  const [isOpenConfirmedModal, setConfirmedModal] = useState(false)
  const [displayError, setDisplayError] = useState(false);
  const { currentTime } = useCurrentTime();
  const { releaseTime } = useReleaseTime();
  const [count, setCount] = useState(0);
  // const isLoading = false;
  // const isError = false;
  // const error = '';

  useEffect(() => {
    getEligibleFileKeys({ count });
    setCount(count + 1);
  }, [])

  const onConfirm = () => {
    setOverride({ sFileKey: selectedFileKeys.join(','), count: count + 1 })
    setConfirmedModal(false)
    setselectedFileKeys([])
    getEligibleFileKeys({ count: count + 1 });
    setCount(count + 1);
  }

  // useEffect(() => {
  //   if (result) {
  //     getEligibleFileKeys({ count });
  //     // setCount(count+1);
  //     setConfirmedModal(false)
  //   }
  // }, [result])

  // useEffect(() => {
  //   // if(result){
  //     setCount(count+1);
  //     setConfirmedModal(false)
  //   // }
  // },[result])

  const renderFileKeysList = (claimNumbers) => {
    const renderSection = (nos) => {
      if (nos && nos.length > 0) {
        const [one, two, three, four, ...rest] = nos;
        let width = '80px';
        if (one.length > 6 && one.length < 8) {
          width = '100px'
        }
        if (one.length > 8 && one.length < 10) {
          width = '120px'
        }
        if (one.length > 10) {
          width = '163px'
        }
        return (
          <>
            <StyledList>
              {one ? (<StyledListItem width={width}>{one}</StyledListItem>) : null}
              {two ? (<StyledListItem width={width}>{two}</StyledListItem>) : null}
              {three ? (<StyledListItem width={width}>{three}</StyledListItem>) : null}
              {four ? (<StyledListItem width={width}>{four}</StyledListItem>) : null}
            </StyledList>
            {rest && rest.length > 0 ? (
              <>
                {renderSection(rest)}
              </>
            ) : null}
          </>
        )
      }
      return null;
    }
    return renderSection(claimNumbers);
  }

  useEffect(() => {
    if (currentTime && releaseTime) {
      const t1 = moment(currentTime).format("H");
      const t2 = moment(releaseTime).format("H");
      if (parseInt(t1, 10) >= parseInt(t2, 10)) {
        setDisplayError(true);
      } else {
        setDisplayError(false);
      }
    }
  }, [currentTime, releaseTime])

  return (
    <>
      <ListPaneHeaderWrapper
        title="Document Manager: Override File Cutoff Time"
        layoutControls={[expandCollapseListPaneButtonConfig]}
      />
      {isLoading ? (
        <LoadingPage />
      ) : isError ? (
        <ErrorPage
          error={error}
        />
      ) : (
        <>
          <div className="mainWrapper">
            {releaseTime && currentTime ?
              <CountDownTimer
                startDate={releaseTime}
                endDate={currentTime}
              /> : ''}
            <div style={{ fontSize: "small", padding: "12px" }}>
              <i>All document management must be completed by the start of the release to be included.</i>
              <p>Please select the file key(s) for which the cutoff time should be overridden.</p>
            </div>
            <div>
              <MultipleSelectDropdown
                id='fileKey'
                label='File Key(s):'
                pRecords={data || []}
                errorMessage={FILEKEY_LIST_NOT_AVAILABLE}
                initialValue={selectedFileKeys}
                effect={(value) => {
                  setselectedFileKeys(value)
                }}
                displayField='label'
                valueField='value'
                displayDefault={false}
                defaultText={false}
                selectInputLabelWidth='40%'
              />
            </div>
            <div className="displayFlex marginLeft">
              <Button
                buttonType="standard"
                size="small"
                name="RESET"
                disabled={!selectedFileKeys.length}
                onClick={() => {
                  setselectedFileKeys([]);
                }}
              />
              <Button
                buttonType="standard"
                size="small"
                style={{ marginLeft: '10px' }}
                name="NEXT->"
                disabled={!selectedFileKeys.length}
                onClick={() => { setConfirmedModal(true) }}
              />
            </div>
          </div>
        </>
      )}<Modal isOpen={isOpenConfirmedModal}>
        <ModalHeader
          title="Selected File Key(s)"
          onClose={() => setConfirmedModal(false)}
        />
        <ModalBody>
          {displayError ? (
            <div className="displayFlex" style={{ paddingLeft: '45px', paddingBottom: '20px', color: 'red' }}>
              <span>You have attempted to override a file after the 10:00 am cst cut off time.<br />
            This request will not be processed and the file will be processed at its regular scheduled release. <br />
            If this is an emergency, please contact your Account Manager.</span>
            </div>) : (
            <div>
              <div className="displayFlex" style={{ paddingLeft: '30px' }}>
                <span>Please confirm the selection of the file key(s) for which the cutoff time should be overridden.</span>
              </div>
              {isOpenConfirmedModal ? (
                <div>
                  <div style={{ wordBreak: 'break-word' }}>
                    <div style={{
                      paddingTop: '10px',
                      marginTop: '20px',
                      marginBottom: '-10px',
                      maxHeight: '44vh',
                      overflow: 'auto'
                    }}>
                      {selectedFileKeys?.length ? renderFileKeysList(selectedFileKeys) : ''}
                    </div>
                    
                  </div>
                  
                  
                </div>
              ) : null}</div>)}
        </ModalBody>
        <ModalFooter>
            <ButtonGroup>
            <Button
              buttonType="standard"
              domID="close"
              dataTestId="test-close"
              name="CLOSE"
              onClick={() => setConfirmedModal(false)}
              size="medium"
            />
            <Button
              buttonType="standard"
              domID="confirm"
              dataTestId="test-confirm"
              name="CONFIRM"
              onClick={() => onConfirm()}
              size="medium"
            />
          </ButtonGroup>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default OverrideFileCutoff
