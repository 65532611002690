import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Portal } from 'react-portal';
import { Button, LoadingIndicator, NotificationBanner } from 'ui-core-ssgr';
import { PaddingWrapper } from '../../../../shared/styles/spacing';
import {
  LoadingContainer,
  GridLoadingWrapper,
} from '../../../../shared/styles/styledComponents';
import { SubDetailHeader, ButtonStatus } from './styles';
import CommitDetailsGridWrapper from '../CommitDetailsGridWrapper';

const CommitDetailsForm = ({
  selectedRecord,
  triggerGetCommitRuleTypesRequest,
  deleteAllNotifications,
  records,
  isShowingCommitMultipleTestAssocRuleModalOpen,
  triggerCommitforMultipleTestAssocRules,
  commitMultipleTestAssocRuleModalOpen,
  loading,
  setDefaultGroupDetailsTab,
  showActiveGroupsListingModal,
  setIsShowingActiveGroupsListingModal,
  notifications,
  deleteNotification,
  selectedActiveRecordsList,
  selectedRecordName,
  activeGroupsModalLocation,
  isEditRole
}) => {
  const [buttonDisabled, setButtonDisabled] = useState(true);

  useEffect(() => {
    const groupId = get(selectedRecord, 'id', '');
    deleteAllNotifications();
    triggerGetCommitRuleTypesRequest({ groupId });
  }, []);

  const shouldDisableButton = isButtonDisabled => {
    setButtonDisabled(isButtonDisabled)
  }

  const onCommitRulesAndAssocButtonClick = () => {
    isShowingCommitMultipleTestAssocRuleModalOpen(true);
  };

  return (
    <>
      {notifications.has('getCommitRuleTypesList') && (
        <NotificationBanner
          domID="api-error-notification"
          type={notifications.get('getCommitRuleTypesList').type}
          text={notifications.get('getCommitRuleTypesList').msg}
          onDismiss={() => deleteNotification('getCommitRuleTypesList')}
          autoDismiss
          timer={1800000}
        />
      )}
       {isEditRole && (
         <Portal>
           <NotificationBanner
             domID="COMMIT-CART-notification"
             type='neutral'
             text='Please contact your super edit user to commit the rules and associations.'
             icon='Info'
             onDismiss={() => deleteNotification('api-info-notification')}
             autoDismiss
             timer={1800000}
           />
         </Portal>
       )}

      <PaddingWrapper>
        <SubDetailHeader
          domID="rule-type-header"
          title="Commit Test Rules & Associations"
        />
        { isEditRole === false &&(
          <ButtonStatus>
            <Button
              name="Commit Rules & Associations"
              type="button"
              buttonType="emphasized"
              size="medium"
              onClick={onCommitRulesAndAssocButtonClick}
              disabled={buttonDisabled || selectedRecord.status.toLowerCase() === 'termed'}
            />
          </ButtonStatus>)
        }
        <PaddingWrapper />
        <PaddingWrapper />
        {loading.has('getCommitRuleTypesListLoading') ? (
          <LoadingContainer>
            <GridLoadingWrapper>
              <LoadingIndicator
                domID="group-grid-loading-indicator"
                length="45px"
              />
            </GridLoadingWrapper>
          </LoadingContainer>
        ) : (
          <CommitDetailsGridWrapper
            loading={loading}
            selectedRecord={selectedRecord}
            commitMultipleTestAssocRuleModalOpen={commitMultipleTestAssocRuleModalOpen}
            isShowingCommitMultipleTestAssocRuleModalOpen={isShowingCommitMultipleTestAssocRuleModalOpen}
            triggerCommitforMultipleTestAssocRules={triggerCommitforMultipleTestAssocRules}
            records={records}
            shouldDisableButton={shouldDisableButton}
            setDefaultGroupDetailsTab={setDefaultGroupDetailsTab}
            showActiveGroupsListingModal={showActiveGroupsListingModal}
            setIsShowingActiveGroupsListingModal={setIsShowingActiveGroupsListingModal}
            selectedActiveRecordsList={selectedActiveRecordsList}
            selectedRecordName={selectedRecordName}
            activeGroupsModalLocation={activeGroupsModalLocation}
            isEditRole={isEditRole}
          />
        )}
      </PaddingWrapper>
    </>
  );
};

CommitDetailsForm.propTypes = {
  selectedRecord: PropTypes.object,
  triggerGetCommitRuleTypesRequest: PropTypes.func.isRequired,
  records: PropTypes.array,
  triggerCommitforMultipleTestAssocRules: PropTypes.func,
  commitMultipleTestAssocRuleModalOpen: PropTypes.bool,
  deleteAllNotifications: PropTypes.func,
  setDefaultGroupDetailsTab: PropTypes.func,
  showActiveGroupsListingModal: PropTypes.bool,
  setIsShowingActiveGroupsListingModal: PropTypes.func,
  notifications: PropTypes.object,
  deleteNotification: PropTypes.func,
  isShowingCommitMultipleTestAssocRuleModalOpen: PropTypes.func,
  loading: PropTypes.object,
  selectedActiveRecordsList: PropTypes.object,
  selectedRecordName: PropTypes.string,
  activeGroupsModalLocation: PropTypes.string,
};

export default CommitDetailsForm;
