import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Checkbox, Tooltip } from 'ui-core-ssgr';

import { isA11yClick } from '../../../../shared/helpers/domEvents';
import { mapIterable } from '../../../../shared/helpers/iterables';
import {
  TruncatedColumn,
  WordTagWidthSmall,
  GrayedOutWordTagSmall,
} from '../../../../shared/styles/styledComponents';
import constants from './constants';

const onKeyPress = (e, callback) => {
  if (isA11yClick(e)) {
    e.preventDefault();
    callback(e);
  }
};

export const ClickableRow = ({
  selectedItemIds,
  onClickThrough = () => false,
  onRowSelect = () => false,
  supportSelection = false,
  isSelected = false,
  isFocused = false,
  selectionKey = '',
  disabledColumns = new Set([]),
  columns = new Set([]),
  record,
}) => {
  const classes = [];
  if (isFocused) {
    classes.push('focused');
  }
  const createColumnOfRecord = columnOfRecord => {
    if (disabledColumns.has(columnOfRecord.dataName)) {
      return null;
    }

    switch (columnOfRecord.dataName) {
      case 'name':
        return (
          <TruncatedColumn
            key={columnOfRecord.dataName}
            className={columnOfRecord.dataName}
          >
            {record.name ? (
              <Tooltip
                tooltipWidth={120}
                tooltipContent={`Rule ID: ${record.id}`}
              >
                {record.name}
              </Tooltip>
            ) : (
              <br />
            )}
          </TruncatedColumn>
        );
      case 'description':
        return (
          <TruncatedColumn
            key={columnOfRecord.dataName}
            className={columnOfRecord.dataName}
          >
            {record.description ? (
              <Tooltip tooltipContent={record.description}>
                {record.description}
              </Tooltip>
            ) : (
              <br />
            )}
          </TruncatedColumn>
        );
      case 'active':
        return (
          <td key={columnOfRecord.dataName} className={columnOfRecord.dataName}>
            <WordTagWidthSmall
              type={record.active ? 'positive' : 'neutral'}
              label={record.active ? 'Live' : 'Test'}
            />
          </td>
        );
      case 'status':
        switch (constants.getStatus(record.status)) {
          case constants.Statuses.TEST:
            return (
              <td
                key={columnOfRecord.dataName}
                className={columnOfRecord.dataName}
              >
                <WordTagWidthSmall type="neutral" label="test" />
              </td>
            );
          case constants.Statuses.EDITED_TEST_LIVE:
            return (
              <td
                key={columnOfRecord.dataName}
                className={columnOfRecord.dataName}
              >
                <WordTagWidthSmall type="neutral" label="test" />
                <WordTagWidthSmall type="positive" label="live" />
              </td>
            );
          case constants.Statuses.TEST_LIVE:
            return (
              <td
                key={columnOfRecord.dataName}
                className={columnOfRecord.dataName}
              >
                <GrayedOutWordTagSmall label="test" />
                <WordTagWidthSmall type="positive" label="live" />
              </td>
            );
          default:
            return (
              <td
                key={columnOfRecord.dataName}
                className={columnOfRecord.dataName}
              />
            );
        }
      case 'associated':
        return (
          <TruncatedColumn
            key={columnOfRecord.dataName}
            className={columnOfRecord.dataName}
          >
            {record.associated}
          </TruncatedColumn>
        );
      case 'userId':
        return (
          <td key={columnOfRecord.dataName} className={columnOfRecord.dataName}>
            {record[columnOfRecord.dataName]}
          </td>
        );
      case 'dateModified':
        return (
          record.id !== 'None' && (
            <td
              key={columnOfRecord.dataName}
              className={columnOfRecord.dataName}
            >
              {moment(record.dateModified)
                .format('M/DD/YYYY h:mma')
                .toUpperCase()}
            </td>
          )
        );
      default:
        return (
          <td key={columnOfRecord.dataName} className={columnOfRecord.dataName}>
            {record[columnOfRecord.dataName]}
          </td>
        );
    }
  };
  return (
    <tr
      onClick={e => {
        onClickThrough(e, record);
      }}
      onKeyPress={e => onKeyPress(e, onClickThrough)}
      role="button"
      tabIndex="0"
      className={classes.join(' ')}
    >
      {supportSelection ? (
        <td className="select-checkbox" key="select column">
          <Checkbox
            name={`${record[selectionKey].replace(
              / /g,
              '-',
            )}-row-select-checkbox`}
            className="table-row-checkbox"
            onChange={() => onRowSelect(record, isSelected)}
            value={selectedItemIds.has(record.id)}
            stopClickPropagation
          />
        </td>
      ) : null}
      {mapIterable(columns.values(), createColumnOfRecord)}
      <td />
    </tr>
  );
};

ClickableRow.propTypes = {
  columns: PropTypes.shape({
    dataName: PropTypes.string,
    values: PropTypes.func,
  }).isRequired,
  disabledColumns: PropTypes.shape({
    dataName: PropTypes.string,
    has: PropTypes.func,
  }).isRequired,
  record: PropTypes.any.isRequired,
  onClickThrough: PropTypes.func,
  onRowSelect: PropTypes.func,
  supportSelection: PropTypes.bool,
  isSelected: PropTypes.bool,
  selectionKey: PropTypes.string,
  isFocused: PropTypes.bool,
  selectedItemIds: PropTypes.object
};

export default ClickableRow;
