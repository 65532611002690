import React from 'react'
import LoadingIndicator from 'ui-core/dist/LoadingIndicator';

const LoadingComponent = () => (
  <div>
    <LoadingIndicator />
  </div>
)

export default LoadingComponent;
