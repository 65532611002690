/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import styled from 'styled-components/dist/styled-components';
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import moment from 'moment'
import DetailPaneHeader from 'ui-core/dist/DetailPaneHeader';
import {
  Grid,
  useLayout,
  Input,
  Button,
  Breadcrumb
} from 'ui-core/dist'
import Datetime from 'react-datetime';
import { detailPaneAnimStates } from '../../commonResources/constants/animStates';
import { LoadingPage, SelectDropdown } from '../../components';
import ErrorPage from '../ErrorPage';
import ListPaneHeaderWrapper from '../../components/ErrorDisplaySection/ListPaneHeaderWrapper';
import GridPaginationWrapper from '../../components/GridPaginationWrapper';
import useSortableGrid from '../../hooks/useSortableGrid';
import useSelectableGrid from '../../hooks/useSelectableGrid'
import { useGetFilesForJob } from '../../api/useDirectPrintJobTracker';
import useNotificationBannerHook from '../../useNotificationBannerHook';
import { DIRECT_PRINT_FILE_TRACKER_GRID } from './Module/constants'
import { resetDirectPrintJobNumber } from './Module/action'
import './Module/index.scss'

const StyledGridWrapper = styled.div`
&.GridWrapper #grid-wrapper-directPrintFileTrackerGrid{
  & table tbody tr td:nth-child(5) > div.cell-component-wrapper {
      max-width: 250px !important;
      overflow: hidden !important;
  }
 }
`
const DirectPrintFilesForJob = () => {
  const {
    // expandCollapseListPaneButtonConfig,
    // openDetailPane,
    closeDetailPane,
    expandCollapseDetailPaneButtonConfig,
    closeDetailPaneButtonConfig,
    detailAnimationState
  } = useLayout()
  const history = useHistory();
  const { notify } = useNotificationBannerHook();
  const clientLayout = useSelector(({ context }) => context?.context?.client + context?.context?.layout)
  const { jobNumber } = useSelector(({ directPrint }) => directPrint?.jobNumber||{})
  const dispatch = useDispatch()
  const DEFAULT_ROWS_PER_PAGE = 25;

  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE)
  const [pageIndex, setPageIndex] = useState(0);
  const [count, setcount] = useState(0)
  const [validationMessage2, setvalidationMessage2] = useState('')
  const [importStartDate, setImportStartDate] = useState(moment().subtract(30, "days").format("MM/DD/YYYY"))
  const [importEndDate, setImportEndDate] = useState(moment().format("MM/DD/YYYY"))
  const [filename, setfilename] = useState('')
  const [fileStatus, setfileStatus] = useState('')
  const [datesError, setDatesError] = useState(false)
  const [errMsg, setErrMsg] = useState(false)

  const FILESTATUS_OPTIONS = [{
    label: 'Print Received',
    value: 400
  }, {
    label: 'Print Accepted',
    value: 401
  }, {
    label: 'Rejected',
    value: 402
  }, {
    label: 'Print In Process',
    value: 410
  }, {
    label: 'Print In Late Process',
    value: 411
  }, {
    label: 'Print Hold',
    value: 420
  }, {
    label: 'Print Hold in Late Process',
    value: 421
  }, {
    label: 'Print Cancelled',
    value: 440
  }, {
    label: 'Print Void',
    value: 441
  }, {
    label: 'Print Complete',
    value: 450
  }, {
    label: 'Partial Hold',
    value: 419
  }, {
    label: 'Print Undergoing Manual Changes',
    value: 430
  }, {
    label: 'Partial Complete',
    value: 449
  }, {
    label: 'Print Job Does Not Exist',
    value: 480
  }, {
    label: 'Print status response contains error(s).',
    value: 490
  }]
  const getInitialValuefileStatus = () => {
    if (fileStatus) {
      return { label: FILESTATUS_OPTIONS.find(x => x.value === fileStatus)?.label, value: fileStatus }
    }
    return null;
  }
  const onClearClick = () => {
    setImportStartDate(moment().subtract(30, "days").format("MM/DD/YYYY"))
    setImportEndDate(moment().format("MM/DD/YYYY"))
    setfilename('')
    setfileStatus('')
    setDatesError(false);
    setRowsPerPage(25)
    setPageIndex(0);
    let config = {
      clientLayout,
    "rowCount": 25,
    "offset": 0,
    "sortColumn": sortConfig.sortDataName,
    "sortOrder": sortConfig.sortDescending,
    withparams: false,
    jobNumber,
    count
    }
    getFilesForJob(JSON.parse(JSON.stringify(config)))
  }
  useEffect(() => {
    setvalidationMessage2('')
    if (jobNumber) {
      if (!/^[a-zA-Z0-9._-]+$/i.test(jobNumber)) {
        setvalidationMessage2('Job Number can only be alphanumeric')
      }
    }
  }, [jobNumber])
  useEffect(() => {
    setvalidationMessage2('')
    if (filename) {
      if (!/^[a-zA-Z0-9._-]+$/i.test(filename)) {
        setvalidationMessage2('File name can only be alphanumeric')
      }
    }
  }, [filename])
  const onSearchClick = useCallback(() => {

    setRowsPerPage(DEFAULT_ROWS_PER_PAGE)
    setPageIndex(0)
    // setfilename('')
    let config = {
      clientLayout,
      "rowCount": DEFAULT_ROWS_PER_PAGE,
      "offset": 0,
      "sortColumn": sortConfig.sortDataName,
      "sortOrder": sortConfig.sortDescending,
      withparams: false,
      jobNumber,
      count
    }
    if (filename) {
      config = {
        ...config, withparams: true,
        filename,
        count
      }
    }
    getFilesForJob(JSON.parse(JSON.stringify(config)))
    setcount(count + 1)
  }, [filename, sortConfig, jobNumber])

  useEffect(() => {
    if (jobNumber && getFilesForJob) {
      setfilename('')
      setRowsPerPage(25)
      setPageIndex(0)
      // closeDetailPane();
      //   dispatch(resetDirectPrintJobRecord());
      let config = {
        clientLayout,
      "rowCount": DEFAULT_ROWS_PER_PAGE,
      "offset": 0,
      "sortColumn": sortConfig.sortDataName,
      "sortOrder": sortConfig.sortDescending,
      withparams: false,
      jobNumber,
      count
      }
      getFilesForJob(JSON.parse(JSON.stringify(config)))
    }
  }, [jobNumber])

  const fetchData = useCallback((args) => {
    let sortcolumn; let sd; let pi; let type;
    if (args) {
      ({ sortcolumn, sd, pi, type } = args);
    }
    let config = {
      clientLayout,
      "rowCount": rowsPerPage,
      "offset": pi === undefined || pi === null ? pageIndex : pi,
      "sortColumn": sortcolumn === undefined || sortcolumn === null ? sortConfig.sortDataName : sortcolumn,
      "sortOrder": sd === undefined || sd === null ? sortConfig.sortDescending : sd,
      withparams: false,
      jobNumber,
      count: count+1
    }
    if (filename) {
      config = {
        ...config, withparams: true,
        filename,
        count
      }
    }
    getFilesForJob(JSON.parse(JSON.stringify(config)))
    setcount(count + 1)

  }, [rowsPerPage, pageIndex, sortConfig, clientLayout, importStartDate, importEndDate, jobNumber, fileStatus, filename, count]);

  const { isLoading, isError, error, getFilesForJob, data: directPrintFiles } = useGetFilesForJob()
  const [sortConfig, getArgumentsAndSortGrid] = useSortableGrid(fetchData, "FILESTATUSDATETIME", "true");


  const onShowMoreClick = useCallback((record) => {
    // dispatch(setManifestEntryRecord({ manifestEntrySelectedRecord: JSON.parse(JSON.stringify(record)), onSearchClick }));
    // openDetailPane();
  }, [directPrintFiles]);

  const renderfileStatus = (FILESTATUS, index) => {
    if (FILESTATUS) {
      if (FILESTATUS.length >= 50) {
        return (
          <>
            <span data-tip data-for={`statustooltip_${index}`}>${FILESTATUS.slice(0, 50)}...</span>
            <ReactTooltip id={`statustooltip_${index}`} type='dark' effect='solid'>
              <span>{FILESTATUS}</span>
            </ReactTooltip>
          </>
        )
      }
      return FILESTATUS;
    }
    return '';
  }
  const renderMainGrid = useMemo(() => {

    if (directPrintFiles) {
      const { files, totalRecords } = directPrintFiles;
      const data = JSON.parse(JSON.stringify(files)).map((d, index) => ({
        ...d,
        FILESTATUS_DISPLAY: renderfileStatus(d.FILESTATUS, index) // renderfileStatus(d.fileStatus, index)
      }));

      return (
        <>
          <div style={{ marginLeft: '30px' }}>
            <GridPaginationWrapper
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={setRowsPerPage}
              pageIndex={pageIndex}
              onChangePageIndex={setPageIndex}
              onShowButtonCLick={fetchData}
              totalRecords={totalRecords || 0}
              defaultRowsPerPage={DEFAULT_ROWS_PER_PAGE}
              disableShowButton={datesError}
            />
          </div>
          <StyledGridWrapper>
            <Grid
              {...DIRECT_PRINT_FILE_TRACKER_GRID}
              isFixedHeader
              maxHeight="600px"
              records={data || []}
              onClickThrough={(e, rec) => {
                e.preventDefault();
                e.stopPropagation();
                return false;
              }}
              initialSortingKey={`${sortConfig.sortDataName}|${sortConfig.sortDescending === "true" ? 'SORT_DESCENDING' : 'SORT_ASCENDING'}`}
              onSortGridColumn={(e, state) => getArgumentsAndSortGrid(state)}
              sortingComparator={(sortDataName, sortDirectionString, recordsToSort) => recordsToSort}
            />
          </StyledGridWrapper>
        </>
      )
    }
    return (
      <div className="marginLeft">
        <p><span className="required-glyph"><label>No files exist for the selected job</label></span></p>
      </div>
    );
  }, [directPrintFiles, rowsPerPage, pageIndex, sortConfig, datesError]);

  const startDateValidation = (currentDate) => {
    const momentStartDuration = moment().subtract(10, 'years');
    let err = false;
    if (moment(currentDate).isBefore(momentStartDuration)) {
      err = true;
    } else if (moment(currentDate).isAfter(moment(importEndDate).startOf('day'), 'day')) {
      err = true;
    }

    return !err;
  };

  const validation = (currentDate) => {
    const momentStartDuration = moment().subtract(10, 'years');
    let err = false;
    if (moment(currentDate).isBefore(momentStartDuration)) {
      err = true;
    } else if (moment(currentDate).isAfter(moment().startOf('day'), 'day')) {
      err = true;
    } else if (moment(currentDate).isBefore(moment(importStartDate).startOf('day'), 'day')) {
      err = true;
    }

    return !err;
  };

  const onStartDateChange = (val) => {
    let err = false;
    let errMessage = '';

    const momentStartDuration = moment().subtract(10, 'years');

    if (!moment(val, 'MM/DD/YYYY', true).isValid() || val === '' || moment(val).isBefore(momentStartDuration)) {
      errMessage = 'Import start date is invalid(mm/dd/yyyy, within 10 years).';
      err = true;
    } else if (moment(val).isAfter(moment(importEndDate))) {
      errMessage = 'Import start date should be earlier than the end date.';
      err = true;
    } else if (moment(val).isAfter(moment().startOf('day'), 'day')) {
      errMessage = 'Import start date is invalid(mm/dd/yyyy, max date is today).';
      err = true;
    }

    if (err) {
      setDatesError(true);
      setErrMsg(errMessage);
      setImportStartDate(val);
    } else {
      setDatesError(false);
      setImportStartDate(val.format("MM/DD/YYYY"));
    }
  }

  const onEndDateChange = (val) => {
    let err = false;
    let errMessage = '';

    const momentStartDuration = moment().subtract(10, 'years');

    if (!moment(val, 'MM/DD/YYYY', true).isValid() || val === '' || moment(val).isBefore(momentStartDuration)) {
      err = true;
      errMessage = 'Import end date is invalid(mm/dd/yyyy, within 10 years).'
    } else if (moment(val).isBefore(moment(importStartDate))) {
      errMessage = 'Import end date should be greater than the start date.';
      err = true;
    } else if (moment(val).isAfter(moment().startOf('day'), 'day')) {
      errMessage = 'Import end date is invalid(mm/dd/yyyy, max date is today).';
      err = true;
    }

    if (err) {
      setDatesError(true);
      setErrMsg(errMessage);
      setImportEndDate(val);
    } else {
      setDatesError(false);
      setImportEndDate(val.format("MM/DD/YYYY"));
    }
  }

  const renderFileName = useMemo(() => (
    <Input
    style={{ width: '300px' }}
    className="test-classname"
    domID="filename"
    placeholder="Enter File name"
    size="small"
    maxLength={200}
    initialValue={filename}
    onChange={(e) => {
      setfilename(e.target.value || "")
    }}
  />
  ), [filename])

  return (
    <>
      <DetailPaneHeader
        title="Direct Print File Tracker"
        layoutControls={[
          expandCollapseDetailPaneButtonConfig,
          closeDetailPaneButtonConfig
        ]}
      />
      {/* <ListPaneHeaderWrapper
        title="Direct Print Job Tracker"
        layoutControls={[expandCollapseListPaneButtonConfig]}
      /> */}
      {detailAnimationState > detailPaneAnimStates.COLLAPSED ? (
        <>
          <div style={{ marginLeft: '20px' }} className="marginLeft">
            {datesError && <p style={{ fontSize: '13px', color: 'red', marginLeft: '10px' }}>{errMsg}</p>}
            {validationMessage2 ? (
              <><div className='marginLeft' style={{ marginLeft: '29px' }}><table><tr>
                <td>
                  <span className="required-glyph"><label style={{ color: 'red' }}>{validationMessage2}</label></span>
                </td>
              </tr><tr><p></p></tr></table></div></>
            ) : null}
            <Breadcrumb
              breadcrumbItems={[{ title: "Direct Print Job Tracker" },
              { title: `Job Number: ${jobNumber}` }]}
              onBreadcrumbItemClick={() => {
                // history.goBack()
                if (detailAnimationState > detailPaneAnimStates.COLLAPSED) {
                  if (resetDirectPrintJobNumber) dispatch(resetDirectPrintJobNumber())
                  closeDetailPane()
                }
              }}
              domID="breadcrumb"
            />
            <div className="displayFlex" style={{ marginTop: '15px', alignItems: 'flex-end' }}>
              <div className="marginLeft" style={{ display: 'flex', alignItems: 'flex-end', fontWeight: 'bold' }}>JOB NUMBER: {jobNumber}</div></div>
            <div className="displayFlex" style={{ marginTop: '15px', alignItems: 'flex-end' }}>
              {/* <div className="marginLeft" style={{ display: 'flex', alignItems: 'flex-end' }}>
                <div className="msgDatePicker">
                  <label style={{ color: '#626D8A', fontSize: '11px' }}>IMPORT START DATE</label>
                  <Datetime
                    onChange={onStartDateChange}
                    // inputProps={{ readOnly: true }}
                    closeOnSelect={true}
                    isValidDate={startDateValidation}
                    dateFormat="MM/DD/YYYY"
                    value={importStartDate}
                    initialValue={importStartDate}
                    initialViewDate={importStartDate}
                    timeFormat={false}
                  />
                </div>
                <div className="msgDatePicker" style={{ marginLeft: '16px' }}>
                  <label style={{ color: '#626D8A', fontSize: '11px' }}>IMPORT END DATE</label>
                  <Datetime
                    onChange={onEndDateChange}
                    // inputProps={{ readOnly: true }}
                    closeOnSelect={true}
                    isValidDate={validation}
                    dateFormat="MM/DD/YYYY"
                    value={importEndDate}
                    initialValue={importEndDate}
                    initialViewDate={importEndDate}
                    timeFormat={false}
                  />
                </div>
              </div> */}

              <div className="marginLeft" style={{ marginLeft: '10px' }}>
                <div>
                  <label> FILE NAME </label>
                  {renderFileName}
                </div>
              </div>
              {/* <div className="marginLeft" style={{ marginLeft: '16px' }}>
                <div>
                  <label> FILE STATUS </label>
                  <SelectDropdown
                    placeholder="Select Here..."
                    width='300px'
                    domID="fileStatusDropdown"
                    label=""
                    options={FILESTATUS_OPTIONS}
                    size="small"
                    initialValue={getInitialValuefileStatus()}
                    isClearable={false}
                    onChange={(e) => {
                      setfileStatus(e?.value || '')
                    }}
                  />
                </div>
              </div> */}
            </div>
            <div className="displayFlex" style={{ marginTop: '15px', alignItems: 'flex-end' }}>
              <div className="marginLeft">
                <div>
                  <p></p>
                  <Button
                    id='search'
                    size="small"
                    name="SEARCH"
                  disabled={validationMessage2 || datesError || !filename}
                    onClick={() => onSearchClick()}
                  />
                </div>
              </div>
              <div className="marginLeft">
                <div>
                  <p></p>
                  <Button
                    buttonType="deEmphasized"
                    id='clear'
                    size="small"
                    name="CLEAR"
                    disabled={!filename}
                    onClick={() => onClearClick()}
                  />
                </div>
              </div>
            </div>
          </div>
          {isLoading ? (
            // replace with a better loading component
            <LoadingPage />
          ) : isError ? (
            // replace with a better error component
            <ErrorPage
              // this prop isn't real but is an example
              error={error}
            />
          ) : (
            <>
              {renderMainGrid}
            </>
          )}
        </>
      ) : null}
    </>
  )
}

export default DirectPrintFilesForJob
