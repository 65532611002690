import React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import MessageManager from '../containers/SystemAdmin/MessageManager';
import MassUpdate from '../containers/SystemAdmin/MassUpdate';
import ServerProcessDetail from '../containers/SystemAdmin/ServerProcessDetail';

const SysAdminRoutes = () => (
  <Switch>
    <Route path="/SystemAdministration/MessageManager" component={MessageManager} />
    <Route path="/SystemAdministration/MassUpdate" component={MassUpdate} />
    <Route path="/SystemAdministration/serverDetails" component={ServerProcessDetail} />
    <Route exact path="/SystemAdministration">
      <Redirect to="/SystemAdministration/MessageManager" />
    </Route>
  </Switch>
)

export default SysAdminRoutes;