import React, { useState } from 'react'
import './modules/styles.scss'

const CustomGrid = ({ gridData, columns }) => {
    const [data, setData] = useState(gridData);
    const [sortAsc, setSortAsc] = useState(true);

    const sortColumn = (key, sortAsc) => {
        const newData = data?.sort(function (a, b) {
            var nameA = a[key].toString().toUpperCase(); // ignore upper and lowercase
            var nameB = b[key].toString().toUpperCase(); // ignore upper and lowercase
            if (sortAsc) {
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                // names must be equal
                return 0;
            } else {
                if (nameA > nameB) {
                    return -1;
                }
                if (nameA < nameB) {
                    return 1;
                }
                // names must be equal
                return 0;
            }
        });
        setSortAsc(!sortAsc);
        setData(newData);
    }

    return (
        <div id="IDKitGrid" className="IDKitGrid">
            <table className="IDKitGrid__table">
                <thead>
                    <tr className="IDKitGrid__trTop IDKitGrid__tr_head">
                        <th colspan="5" style={{ borderRight: '1px solid #c3c5cd', borderBottom: '1px solid #c3c5cd' }}>File to Record Transformation &nbsp; ———&gt;</th>
                        <th colspan="5" style={{ borderRight: '1px solid #c3c5cd', borderBottom: '1px solid #c3c5cd' }}>Document Composition and Print &nbsp; ———&gt;</th>
                        <th colspan="2" style={{ borderBottom: '1px solid #c3c5cd' }}>Overall Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="IDKitGrid__tr_head">
                        <th style={{ borderBottom: '1px solid #c3c5cd' }} colspan="2"></th>
                        <th colspan="2" style={{ borderLeft: '1px solid #c3c5cd', borderRight: '1px solid #c3c5cd', borderBottom: '1px solid #c3c5cd' }}>Record Imported</th>
                        <th style={{ borderBottom: '1px solid #c3c5cd' }} colspan="1"></th>
                        <th colspan="2" style={{ borderLeft: '1px solid #c3c5cd', borderRight: '1px solid #c3c5cd', borderBottom: '1px solid #c3c5cd' }}>Record Verified</th>
                        <th style={{ borderBottom: '1px solid #c3c5cd' }} colspan="1"></th>
                        <th colspan="2" style={{ borderLeft: '1px solid #c3c5cd', borderRight: '1px solid #c3c5cd', borderBottom: '1px solid #c3c5cd' }}>Printed &amp; Mailed</th>
                        <th style={{ borderBottom: '1px solid #c3c5cd' }} colspan="2"></th>
                    </tr>
                    <tr className="IDKitGrid__tr_head" style={{ background: 'none !important' }}>
                        <th style={{ borderBottom: '1px solid #c3c5cd', textAlign: 'left', cursor: 'pointer' }} onClick={() => sortColumn('filename', sortAsc)}>File Name</th>
                        <th style={{ borderBottom: '1px solid #c3c5cd', borderRight: '1px solid #c3c5cd', textAlign: 'left', cursor: 'pointer' }} onClick={() => sortColumn('fileReceived', sortAsc)}>File Received</th>
                        <th style={{ borderBottom: '1px solid #c3c5cd', textAlign: 'left' }}>Success</th>
                        <th style={{ borderBottom: '1px solid #c3c5cd', borderRight: '1px solid #c3c5cd', textAlign: 'left' }}>Reject</th>
                        <th style={{ borderBottom: '1px solid #c3c5cd', borderRight: '1px solid #c3c5cd', textAlign: 'left', cursor: 'pointer' }} onClick={() => sortColumn('recordsSent', sortAsc)}>Records Sent</th>
                        <th style={{ borderBottom: '1px solid #c3c5cd', textAlign: 'left' }}>Success</th>
                        <th style={{ borderBottom: '1px solid #c3c5cd', borderRight: '1px solid #c3c5cd', textAlign: 'left' }}>Reject</th>
                        <th style={{ borderBottom: '1px solid #c3c5cd', borderRight: '1px solid #c3c5cd', textAlign: 'left' }}>PDFs</th>
                        <th style={{ borderBottom: '1px solid #c3c5cd', textAlign: 'left' }}>Success</th>
                        <th style={{ borderBottom: '1px solid #c3c5cd', borderRight: '1px solid #c3c5cd', textAlign: 'left' }}>Pending</th>
                        <th style={{ borderBottom: '1px solid #c3c5cd', textAlign: 'left', cursor: 'pointer' }} onClick={() => sortColumn('lastUpdated', sortAsc)}>Mailed Date</th>
                        <th style={{ borderBottom: '1px solid #c3c5cd', textAlign: 'left', cursor: 'pointer' }} onClick={() => sortColumn('overallStatus', sortAsc)}>Status</th>
                    </tr>
                    {
                        data?.map(record => {
                            return (
                                <tr>
                                    {columns.map(col => {
                                        const colName = col.dataName;
                                        if (colName === 'recordsSent' || colName === 'fileReceived' || colName === 'lastUpdated') {
                                            const val = record[colName] ? record[colName] : '-'
                                            return <td style={colName === 'recordsSent' || colName === 'fileReceived' ? { borderRight: '1px solid #c3c5cd', textAlign: 'left' } : { textAlign: 'left' }}>
                                                {val}
                                            </td>
                                        }
                                        return <td style={colName === 'recRejectedCount' || colName === 'validatedRejected' ||
                                            colName === 'pdfsReceived' || colName === 'printedMailedPending' ? { borderRight: '1px solid #c3c5cd', textAlign: 'left' } : { textAlign: 'left' }}>
                                            {record[colName]}
                                        </td>
                                    })}
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}

export default CustomGrid