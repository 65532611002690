/* eslint-disable no-nested-ternary */
import React, { useMemo, useEffect, useState, useCallback } from 'react';
import styled from 'styled-components/dist/styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom'
import {
  Grid,
  useLayout,
  Modal, ModalHeader, ButtonGroup, ModalBody,
  Button, ModalFooter
} from 'ui-core/dist'
import useNotificationBannerHook from '../../../useNotificationBannerHook';
import { LoadingPage, AnchorInput } from '../../../components';

import ErrorPage from '../../ErrorPage';
import {
  GENERIC_TEXT_FILE_SEARCH_ROUTE,
  GENERIC_TEXT_ROUTE,
  GENERIC_WARNING_TEXT_ROUTE,
  ROUTE_FILE_SEARCH_RESULT_COLUMN,
  DOC_MANAGER_ROUTE_DELIVERY_TYPE_NO_CHANGE_VALUE,
  DOC_MANAGER_ROUTE_DELIVERY_TYPE_NO_CHANGE_LABEL
} from "../Modules/constants";
import withAxiosCancellation from '../../../components/withAxiosCancellation'
import { useSearchFiles, useSubmitRouteDocument, useGetAddress } from '../../../api/useDocumentManager';
import ListPaneHeaderWrapper from '../../../components/ErrorDisplaySection/ListPaneHeaderWrapper';
import GridPaginationWrapper from '../../../components/GridPaginationWrapper';
import useSortableGrid from '../../../hooks/useSortableGrid';
import { setFileSearchParam, setFileSearchParamNull } from "../Modules/action";
import FileReceiptManagerDetailComponent from '../../FileReceiptManager/FileReceiptManagerDetailComponent';
import useDocumentRouteDeliveryFilter from '../DocumentRouteDeliveryFilter/useDocumentRouteDeliveryFilter';
import DocumentRouteDeliveryFilterComp from '../DocumentRouteDeliveryFilter/DocumentRouteDeliveryFilterComp';
import ModalWrapper from '../../../components/Modal/components/ModalWrapper';
import PopoverBox from "../../../components/PopoverBox";

import '../Modules/styles.scss';
import useSelectableGrid from '../../../hooks/useSelectableGrid';

const StyledModalWrapper = styled.div`
height: 80vh;
`
const StyledModalHeaderWrapper = styled.div`
& div {
  padding:1px 9px !important;
  padding-bottom: 0px !important;
}
`
const StyledConfirmPanelContainer = styled.div`
margin-left: 20px;
& table {
  width: 50% !important;
  & tr {
    border-spacing: 5em;
    & > td {
      padding-bottom: 1em;
    }
  }
}
`
const StyledModalWrapperWidth = styled(ModalWrapper)`
& .active-content {
  
  max-height: 600px !important;
 
  & span {
    font-size: 11px !important;
  }
}
`

const StyleDivParent = styled.div`
  &.styled-parent-div {
    margin-top: 45px;
    border: 1px solid #cacccd;
    /* border-width: 1px; */
    /* border-color: #dbdbdb; */
    overflow-y: auto;
    height: 470px;
    border-radius: 5px;
    margin-left: 30px;
    text-align: left;
    /* -webkit-box-shadow: -1px 9px 18px 0px rgb(0 0 0 / 75%); */
    /* -moz-box-shadow: -1px 9px 18px 0px rgba(0,0,0,0.75); */
    /* box-shadow: 0px 0px 6px -1px rgb(0 0 0 / 75%); */
  }
  &.popBoxDiv {
    display: inline-block;

    & span {
      position:relative !important;

      & div.popover.bottom {
        border-radius: 5px;
        margin-top: 42px !important;
        margin-left: 27px !important;
      }
    }
  }
`;
const FSearchResultRouteMain = ({getCancelSourceWithName}) => {
  const {
    expandCollapseListPaneButtonConfig
  } = useLayout()
  const dispatch = useDispatch()
  const history = useHistory();
  const { notify } = useNotificationBannerHook()
  const { allData, totalRecordsResp, searchFiles, isLoading, isError, error, refetch } = useSearchFiles({getCancelSource:getCancelSourceWithName('searchRouteFileResult')});
  const { fileSearchParam } = useSelector(({ documentSearch }) => documentSearch);
  const [changeItemPerPage, setchangeItemsPerPage] = useState(fileSearchParam.maxRecords)
  const [pageIndex, setPageIndex] = useState(0)
  const [isOpenFileReceiptModal, setisOpenFileReceiptModal] = useState(false)
  const [currentFileKey, setCurrentFileKey] = useState('')
  const [disabledItemIds, setDisableItemIds] = useState([])
  const [isOpenConfirmedModal, setConfirmedModal] = useState(false)
  const { onRowSelect, onClearAllSelection, selectedItemIds, selectedRecords, selectedItemIdsCurrentPage } = useSelectableGrid({ uniqueKey: 'filekeyUnique', list: allData, isUicl: true })
  const form = useDocumentRouteDeliveryFilter(selectedRecords, notify, {
    deliveryTypeConfig: {
      values: [{
        label: DOC_MANAGER_ROUTE_DELIVERY_TYPE_NO_CHANGE_LABEL,
        value: DOC_MANAGER_ROUTE_DELIVERY_TYPE_NO_CHANGE_VALUE
      }],
      defaultSelectedValue: DOC_MANAGER_ROUTE_DELIVERY_TYPE_NO_CHANGE_VALUE
    }, deliveryAddressConfig: {}
  });
  const { transId, submitDocuments, isLoading: isTxnLoading } = useSubmitRouteDocument(form, 'files');
  const [count, setCount] = useState(0);

  useEffect(() => {
    searchFiles(fileSearchParam)
  }, [])

  useEffect(() => {
    if (isOpenConfirmedModal) {
      if (form?.renderConfirmationModalUpperPanel) form.renderConfirmationModalUpperPanel();
    }
  }, [isOpenConfirmedModal])

  useEffect(() => {
    if (allData) {
      const disableItems = allData.filter(x => (x.filekey.$.canManage === 'false')).map(y => y.filekey._)
      setDisableItemIds(disableItems)
    }
  }, [allData])

  useEffect(() => {
    if (transId) {
      onClearAllSelection()
      // refetch()
      if (form && form.resetFields) form.resetFields()
      history.push({ pathname: '/DocumentManager/Route/File', state: `You have successfully routed the files.  The transaction ID is ${transId}` })
    }
  }, [transId])

  const fetchSortingData = useCallback((args) => {
    let sortcolumn; let sd; let pi;
    if (args) {
      ({ sortcolumn, sd, pi } = args);
    }
    const payload = JSON.parse(JSON.stringify({
      "maxRecords": changeItemPerPage,
      "rowPosition": pi === undefined || pi === null ? pageIndex : pi,
      "sortField": sortcolumn === undefined || sortcolumn === null ? sortConfig.sortDataName : sortcolumn,
      "sortDescending": sd === undefined || sd === null ? sortConfig.sortDescending : sd,
      "checkNumber": fileSearchParam ? fileSearchParam.checkNumber : '',
      "claimNumber": fileSearchParam ? fileSearchParam.claimNumber : '',
      "groupId": fileSearchParam ? fileSearchParam.groupId : '',
      "idNumber": fileSearchParam ? fileSearchParam.idNumber : '',
      "filekey": fileSearchParam ? fileSearchParam.filekey : '',
      "showDuplicateChecks": true,
      "showProcessedDocuments": false
    }))
    dispatch(setFileSearchParamNull(payload))
    dispatch(setFileSearchParam(payload))
    searchFiles(payload)

  }, [changeItemPerPage, pageIndex, sortConfig]);

  const [sortConfig, getArgumentsAndSortGrid] = useSortableGrid(fetchSortingData, "filekey", "true");

  const onCloseFileReceiptModal = () => {
    setisOpenFileReceiptModal(false)
    setCurrentFileKey('')
    // dispatch(resetFileKey())
    return true;
  }
  const onCloseConfirmedModal = () => {
    setConfirmedModal(false)
    setCurrentFileKey('')
    // dispatch(resetFileKey())
    return true;
  }
  const nextConfirmed = () => {
    submitDocuments(selectedItemIds, count);
    setConfirmedModal(false)
    setCount(count + 1);
  }
  const renderStatus = (status) => {
    let className;
    switch (status) {
      case 'Imported':
        className = 'status-ok';
        break;
      case 'Released':
        className = 'status-ok';
        break;
      case 'Ready to print':
        className = 'status-ok';
        break;
      case 'Held':
        className = 'status-warn';
        break;
      case 'Held and Errored':
        className = 'status-error';
        break;
      case 'Errored':
        className = 'status-error';
        break;
      case 'Purged':
        className = 'status-error';
        break;
      default:
        break;
    }

    return (
      <div className={className}>
        <span>{status}</span>
      </div>
    )
  }
  const renderCheckedContent = useMemo(() => {
    const selectedRecordsArray = Array.from(selectedRecords);
    if (selectedRecordsArray.length) {
      // const temp=JSON.parse(JSON.stringify(errorResp));
      const data = selectedRecordsArray.map((fileSearchResults) => ({
        filekeyUnique: fileSearchResults.filekey._,
        originalFileName: fileSearchResults.originalFileName,
        filekey: fileSearchResults.filekey._,
        importedDate: fileSearchResults.importedDate,
        status: renderStatus(fileSearchResults.status._),
        statusText: fileSearchResults.status._,
      }))


      return (
        <Grid
          {...ROUTE_FILE_SEARCH_RESULT_COLUMN}
          domID="DocumentSearchHoldConfirm"
          dataTestId="test-gridDefault"
          maxHeight="350px"
          records={data || []}
        // onSortGridColumn={(e,state)=>getArgumentsAndSortGrid(state)}
        />
      );
    }
    return '';
  }, [selectedRecords, isOpenConfirmedModal])

  const renderGridContent = useMemo(() => {
    if (allData.length) {
      const data = allData.map((fileSearchResults) => ({
        ...fileSearchResults,
        originalFileName: fileSearchResults?.originalFileName,
        filekey: (
          <AnchorInput
            id='fileKey'
            type='button'
            className='fileKeyLink'
            onClick={() => {
              setisOpenFileReceiptModal(true)
              setCurrentFileKey(fileSearchResults?.filekey?._)
            }}
          >{fileSearchResults?.filekey?._}</AnchorInput>
        ),
        importedDate: fileSearchResults.importedDate,
        status: renderStatus(fileSearchResults.status._),
        statusText: fileSearchResults.status._,
        errors: fileSearchResults?.status?.$?.percent !== "100" ?
          `${fileSearchResults?.status?.$?.percent}% ${fileSearchResults?.status?.$?.errors} errors` : ''
      }))


      return (
        <Grid
          {...ROUTE_FILE_SEARCH_RESULT_COLUMN}
          domID="DocumentSearchHoldConfirm"
          dataTestId="test-gridDefault"
          records={data || []}
          selectionKey="filekeyUnique"
          initialSelectedItemIds={new Set(selectedItemIdsCurrentPage)}
          initialDisabledItemIds={new Set(disabledItemIds)}
          onRowSelect={onRowSelect}
          onSelectAll={onRowSelect}
          onSortGridColumn={(e, state) => getArgumentsAndSortGrid(state)}
          supportSelection={!(disabledItemIds.length >= changeItemPerPage)}
          initialSortingKey={`${sortConfig.sortDataName}|${sortConfig.sortDescending === "true" ? 'SORT_DESCENDING' : 'SORT_ASCENDING'}`}
        />
      );
    } else {
      return (
        <div className="displayFlex">
          <label style={{ color: 'red' }}>No records found. Change search criteria.</label>
        </div>
      )
    }
  }, [allData, disabledItemIds, selectedItemIds, selectedItemIdsCurrentPage, transId, selectedRecords]);

  const renderConfirmButton = useMemo(() => {
    let disabled = form && ((form?.validationMessage && form.validationMessage.toString() && !form.validationMessage.toString().toLowerCase().includes('warning')));

    if (selectedItemIds.size === 0) {
      disabled = true;
    }
    return (
      <Button
        buttonType="standard"
        size="small"
        name="CONFIRM SELECTED ITEMS"
        disabled={disabled}
        onClick={() => { setConfirmedModal(true) }}
      />
    );
  }, [selectedItemIds, form?.validationMessage])

  return (
    <>
      <ListPaneHeaderWrapper
        title="Document Manager: Route Files"
        layoutControls={[expandCollapseListPaneButtonConfig]}

      />
      {isLoading ? (
        // replace with a better loading component
        <LoadingPage />
      ) : isError ? (
        // replace with a better error component
        <ErrorPage
          // this prop isn't real but is an example
          error={error}
        />
      ) : (
            <div className="mainWrapper">
              <div style={{ fontSize: "small", padding: "12px" }}>
                <p>{GENERIC_TEXT_FILE_SEARCH_ROUTE}</p>
                <div style={{ color: "#bf1616" }}>
                  <p>
                    {GENERIC_TEXT_ROUTE}
                  </p>
                  <p>
                    {GENERIC_WARNING_TEXT_ROUTE}
                  </p>
                </div>
              </div>
              <div className="">
                <StyleDivParent className="popBoxDiv">
                  <PopoverBox
                    trigger="hover"
                    orientation="bottom"
                    buttonName="File Cutoff Time Applies"
                    buttonType="diminished"
                    domID="format_popup"
                    width="350px"

                  >
                    <div style={{
                      padding: "14px",
                      color: "#202083",
                      fontSize: "13px",
                      fontWeight: "bold",
                    }}>
                      File cutoff time is defined as the time in which a file must be fully imported, in order to be included in the 10:00AM release.

                </div>
                  </PopoverBox>
                </StyleDivParent>
              </div>
              <div>
                {/* Delivery Address Filter Component */}
                <DocumentRouteDeliveryFilterComp form={form} />
                <div className="marginLeft">
                  <ButtonGroup>
                    <Button
                      buttonType="standard"
                      size="small"
                      name="New Search"
                      domID="holdBack"
                      onClick={() => { history.push(`/DocumentManager/Route/File`) }}
                    />
                    <Button
                      disabled={!selectedItemIds.size}
                      buttonType="standard"
                      size="small"
                      name="Clear all"
                      onClick={() => {
                        onClearAllSelection();
                      }}
                    />
                    {renderConfirmButton}
                  </ButtonGroup>
                </div>

                <GridPaginationWrapper
                  rowsPerPage={changeItemPerPage}
                  onChangeRowsPerPage={(v) => setchangeItemsPerPage(v)}
                  pageIndex={pageIndex}
                  onChangePageIndex={(v) => setPageIndex(v)}
                  onShowButtonCLick={(args) => fetchSortingData(args)}
                  totalRecords={totalRecordsResp}
                  defaultRowsPerPage={changeItemPerPage}
                  enableTextbox={false}
                />
                <div className='GridWrapper'>
                  {renderGridContent}
                </div>
              </div>
            </div>
          )}
      <Modal isOpen={isOpenFileReceiptModal}>
        <StyledModalWrapper>
          <StyledModalHeaderWrapper>
            <ModalHeader
              title="File Receipt"
              onClose={() => onCloseFileReceiptModal()}
            />
          </StyledModalHeaderWrapper>
          <ModalBody>
            {isOpenFileReceiptModal ? (
              <FileReceiptManagerDetailComponent
                filekey={currentFileKey}
                height='58vh'
                overflow='auto'
              />
            ) : null}
          </ModalBody>
        </StyledModalWrapper>
      </Modal>

      <Modal isOpen={isOpenConfirmedModal} wrapperComponent={StyledModalWrapperWidth}>
        <ModalHeader
          title="Selected Files"
          onClose={() => onCloseConfirmedModal()}
        />
        <ModalBody>
          <div style={{ paddingLeft: '30px', fontSize: '11px' }}>
            <span>Confirm the routing information for the selected files.  If you are releasing files that are currently on hold, the documents contained in the files will be released for printing immediately and then delivered using the updated routing information.  Click Next to continue.  </span>
            <br />
            <span style={{ color: 'red' }}>*WARNING - You are performing a file level route. Please be aware that any routing performed will override any electronic delivery and will print and mail the selected document(s) below.</span>
          </div>
          {isOpenConfirmedModal ? (
            <div>
              {form?.confirmationModalMarkup}
              <div className='GridWrapper'>
                <div className="modalOuterDiv" style={{
                  paddingLeft: '25px',
                  paddingRight: '25px',
                  // marginTop: '20px',
                  marginBottom: '-10px',
                  overflow: 'scroll',
                  maxWidth: '800px',
                  maxHeight: '40vh'
                }}>
                  {renderCheckedContent}
                </div>
              </div>

            </div>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button
              buttonType="standard"
              size="small"
              name="CLOSE"
              onClick={() => {
                setConfirmedModal(false);
              }}
              style={{ marginTop: '10px' }}
            />
            <Button
              buttonType="standard"
              size="small"
              name="CONFIRM"
              onClick={() => {
                nextConfirmed();
              }}
              style={{ marginTop: '10px' }}
              disabled={isTxnLoading}
            />
            {
              isTxnLoading && <LoadingPage height="1vh" />
            }
          </ButtonGroup>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default withAxiosCancellation(FSearchResultRouteMain)
