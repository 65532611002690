import styled from 'styled-components';
export const ContainerBox = styled.div`
  display: flex;
  margin-top: 40px;
  margin-bottom: 40px;
  position: relative;
  & > div > div > div > span {
    width: 550px;
  }
`;
export const ReadOnlyContainerBox = styled(ContainerBox)`
  margin-top: 20px;
  margin-bottom: 20px;
`;
export const ButtonPaddingTop = styled.div`
  padding-top: 10px;
`;
export const CopyNumberCaption = styled.div`
  display: flex;
  margin-top: 28px;
  position: relative;
  margin-left: -30px;
  font-size: 11px;
`;
export const DollarLabel = styled.div`
  position: absolute;
  top: 25px;
  left: -11px;
`;
export const DollarLabelReadOnly = styled.div`
  top: 25px;
  left: -11px;
`;
export const BlankSignatureContainer = styled(ContainerBox)`
  margin-top: 20px;
  margin-bottom: 60px;
`;
export const BlankSignature = styled.div`
  display: inline-block;
  margin-right: 25px;
`;
export const BlankSignatureTitle = styled.div`
  display: block;
  margin-bottom: 0.3rem;
  color: #37474f;
  font-size: 11px;
  line-height: 1.4em;
  font-weight: 600;
  letter-spacing: 0.05em;
  text-transform: uppercase;
`;
export const BlankSignatureWrap = styled.div`
  position: absolute;
  top: 25px;
`;
