import { useAxios } from '../hooks'

const useReportBuilder = ({ payload, getCancelSource }) => {
  const { data, ...rest } = useAxios({
    getCancelSource,
    url: '/reportBuilder/documents',
    method: 'POST',
    payload,
    negativeMessage: false
  })

  return { 
    data: data?.data, 
    cacheName: data?.cacheName,
    lastUpdated: data?.lastUpdated,
    total: data?.totalRecords || undefined, ...rest,
    showMsg: data?.showCapMsg
  }
}

export default useReportBuilder