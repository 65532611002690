import React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import { Page } from '../components';
import EMDFinanceManagerMain from '../containers/EMDFinanceManager/index'
import FinanceManagerTierPriceDetail from '../containers/EMDFinanceManager/FinanceManagerTierPriceDetail'
// import EEOBFormManagerDetail from '../containers/EEOBFormManager/EEOBFormManagerDetail'

const EMDFinanceManagerPage = () => (
  <Page
    ListPaneContent={EMDFinanceManagerMain}
    DetailPaneContent={FinanceManagerTierPriceDetail}
  />
);

const EMDFinanceManagerRoutes = () => (
  <Switch>
    <Route path="/EMDFinanceManager/index" component={EMDFinanceManagerPage} />
    <Route exact path="/EMDFinanceManager">
      <Redirect to="/EMDFinanceManager/index" />
    </Route>
  </Switch>
)

export default EMDFinanceManagerRoutes;