// Import the action types we are going to respond to with this reducer
import {
  GET_COMMIT_RULETYPES_REQUEST_SUCCESS,
  GET_COMMIT_RULETYPES_REQUEST_ERROR,
  SET_IS_SHOWING_MULTIPLE_RULES_COMMIT_MODAL,
  MULTIPLE_RULES_COMMIT_PUT_REQUEST_TRIGGER,
  MULTIPLE_RULES_COMMIT_PUT_REQUEST_SUCCESS,
  MULTIPLE_RULES_COMMIT_PUT_REQUEST_ERROR,
  SET_IS_MULTIPLE_RULES_COMMIT_STATE,
  TRIGGER_COMMIT_FOR_MULTIPLE_TEST_ASSOCIATION_RULES,
  COMMIT_MULTIPLE_TEST_ASSOCIATION_RULES_SUCCESS,
  COMMIT_MULTIPLE_TEST_ASSOCIATION_RULES_ERROR,
  COMMIT_MULTIPLE_TEST_ASSOCIATION_RULE_MODAL_OPEN,
  RESET_COMMIT_STATE,
} from './constants';

// Initial state expresses the data type of this key in the store (in this case
// store.groupsList will be an Array). This object is exported separately to
// make unit testing easier
export const INITIAL_STATE = {
  isMultipleCommiting: false,
  showMultipleCommitRulesModal: false,
  resultCommit: false,
  errorCommit: false,
  commitMultipleTestAssocRuleModalOpen: false,
  showErrorBanner: false,
  showSuccessBanner: false,
};

// This is the actual "reducer". It is a function that will run every time an
// action is dispatched by the store, but only return updated state values if
// it matches the action's type in the switch statement.
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_COMMIT_RULETYPES_REQUEST_SUCCESS:
      // In our success case our action's payload will be the new array to cach
      // and use in the application
      return {
        records: [...action.payload.commitRuleTypesArray],
        selectedDetailsRecordId: action.payload.groupId,
      };
    case GET_COMMIT_RULETYPES_REQUEST_ERROR:
      // If an error occured you can set a fallback state here
      return { error: [action.error], selectedDetailsRecordId: action.id };
    case MULTIPLE_RULES_COMMIT_PUT_REQUEST_TRIGGER:
      return {
        ...state,
      };
    case MULTIPLE_RULES_COMMIT_PUT_REQUEST_SUCCESS:
      return {
        ...state,
        resultCommit: action.payload,
      };
    case MULTIPLE_RULES_COMMIT_PUT_REQUEST_ERROR:
      // If an error occurred you can set a fallback state here

      return {
        ...state,
        errorCommit: action.payload,
      };

    case SET_IS_MULTIPLE_RULES_COMMIT_STATE:
      return {
        ...state,
        isMultipleCommiting: action.payload,
      };
    case SET_IS_SHOWING_MULTIPLE_RULES_COMMIT_MODAL:
      return {
        ...state,
        showMultipleCommitRulesModal: action.payload,
      };
      // ---Commit multiple test asssoc rules for groups------

    case COMMIT_MULTIPLE_TEST_ASSOCIATION_RULE_MODAL_OPEN:
      return {
        ...state,
        commitMultipleTestAssocRuleModalOpen: action.payload,
      };
    case TRIGGER_COMMIT_FOR_MULTIPLE_TEST_ASSOCIATION_RULES:
      return {
        ...state,
      };
    case COMMIT_MULTIPLE_TEST_ASSOCIATION_RULES_SUCCESS:
      return {
        ...state,
      };
    case COMMIT_MULTIPLE_TEST_ASSOCIATION_RULES_ERROR:
      return {
        ...state,
        errorCommit: action.payload,
      };
    case RESET_COMMIT_STATE:
      return {
        isMultipleCommiting: false,
        showMultipleCommitRulesModal: false,
        resultCommit: false,
        errorCommit: false,
        commitMultipleTestAssocRuleModalOpen: false,
        showErrorBanner: false,
        showSuccessBanner: false,
      };
    default:
      // default case is to return the existing state
      return state;
  }
};
