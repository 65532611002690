import styled from 'styled-components';

export const ContainerBox = styled.div`
  display: flex;
  padding-bottom: 36px;
  position: relative;
  & > div > div > span {
    width: 400px;
  }
`;

export const CopyNumberCaption = styled.div`
  display: flex;
  margin-top: 28px;
  position:relative;
  margin-left: -30px;
  font-size: 11px;
}`;
