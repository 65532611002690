
const initialState = {
  originalRecord: null,
  editPayerEftAccount:null,
  mode: null,
  refetchEftAccounts:null
}
    
export default (state = initialState, { type, payload }) => {
  switch(type) {
    case 'SET_ORIGINAL_PAYER_EFT_ACCOUNT':
      return {
        ...state,
        originalRecord: JSON.parse(JSON.stringify({
          originalEftAcctId: payload.originalEftAcctId,
          originalVersionNumber: payload.originalVersionNumber,
          originalStatusCode: payload.originalStatusCode,
        })),
        refetchEftAccounts: payload.refetchEftAccounts,
      }
    case 'RESET_ORIGINAL_PAYER_EFT_ACCOUNT':
      return {
        ...state,
        originalEftAcctId:null,
        originalVersionNumber: null,
        originalStatusCode: null,
        refetchEftAccounts:null
      }
    case 'SET_MODE_PAYER_EFT_ACCOUNT':
      return {
        ...state,
        mode: payload.mode
      }
    case 'SET_EDIT_PAYER_EFT_ACCOUNT':
      return {
        ...state,
        editPayerEftAccount:JSON.parse(JSON.stringify(payload.editPayerEftAccount)),
        mode: payload.mode
      };
    case 'RESET_EDIT_PAYER_EFT_ACCOUNT':
      return {
        ...state,
        editPayerEftAccount:null,
        mode: null,
      };
    default:
      return state;
  }
}