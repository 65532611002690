/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import styled from 'styled-components/dist/styled-components';
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import {
  useLayout,
  Modal,
  ModalHeader,
  ModalFooter,
  Button,
  ButtonGroup,
  ModalBody
} from 'ui-core/dist'
import { LoadingPage, AnchorInput, SelectDropdown } from '../../../components';
import useNotificationBannerHook from '../../../useNotificationBannerHook';
import { setDetailsId } from '../../ErrorManager/Modules/Action';
import ErrorPage from '../../ErrorPage';
import {
  GENERIC_TEXT1,
  GENERIC_TEXT_HOLD,
  GENERIC_TEXT_SEARCH_HOLD
} from "../Modules/constants";
import withAxiosCancellation from '../../../components/withAxiosCancellation'
import ListPaneHeaderWrapper from '../../../components/ErrorDisplaySection/ListPaneHeaderWrapper';
import ShowMoreNestedGridComponent from '../../../components/ShowMoreNestedGridComponent';
import { useSearchReleaseDocument, useSubmitReleaseDocument, usePreviewFile } from '../../../api/useDocumentManager';
import GridPaginationWrapper from '../../../components/GridPaginationWrapper';
import {
  resetRecord,
  setRecord,
  setFileSearchParam, setFileSearchParamNull
} from "../Modules/action";
import PopoverBox from "../../../components/PopoverBox";
import useSelectableGrid from '../../../hooks/useSelectableGrid';
import '../Modules/styles.scss';

const StyleDivParent = styled.div`
  &.styled-parent-div {
    margin-top: 45px;
    border: 1px solid #cacccd;
    /* border-width: 1px; */
    /* border-color: #dbdbdb; */
    overflow-y: auto;
    height: 470px;
    border-radius: 5px;
    margin-left: 30px;
    text-align: left;
    /* -webkit-box-shadow: -1px 9px 18px 0px rgb(0 0 0 / 75%); */
    /* -moz-box-shadow: -1px 9px 18px 0px rgba(0,0,0,0.75); */
    /* box-shadow: 0px 0px 6px -1px rgb(0 0 0 / 75%); */
  }
  &.popBoxDiv {
    display: inline-block;

    & span {
      position:relative !important;

      & div.popover.bottom {
        border-radius: 5px;
        margin-top: 42px !important;
        margin-left: 27px !important;
      }
    }
  }
`;

const DSearchResultReleaseMain = ({getCancelSourceWithName}) => {
  const {
    expandCollapseListPaneButtonConfig,
    openDetailPane,
    closeDetailPane
  } = useLayout()

  const DEFAULT_DOWNLOAD_ERR_MESSAGE = "Could not download PDF at the moment. There is no PDF File for your request";
  const dispatch = useDispatch()
  const history = useHistory();
  const { notify } = useNotificationBannerHook();
  const { allData, totalRecordsResp, searchDocument, isLoading, isError = false, error } = useSearchReleaseDocument({getCancelSource:getCancelSourceWithName('searchReleaseDocumentResult')});
  const { transId, submitDocument, isLoading: isTxnLoading } = useSubmitReleaseDocument();
  const [claimNumberDownloadTrack, setclaimNumberDownloadTrack] = useState({
    documentKey: null,
    filename: null
  })
  const { file, previewFile, refetchPreviewFile, isError: isErrorPreviewFile, error: errorPreviewFile, isLoading: isLoadingPreviewFile } = usePreviewFile(setclaimNumberDownloadTrack);
  const { fileSearchParam } = useSelector(({ documentSearch }) => documentSearch);
  const [count, setCount] = useState(0);
  const [changeItemPerPage, setchangeItemsPerPage] = useState(fileSearchParam.maxRecords)
  const [pageIndex, setPageIndex] = useState(0)
  const [list, setlist] = useState([]);
  const [isOpenConfirmModal, setisOpenConfirmModal] = useState(false)
  const [releaseType, setReleaseType] = useState({ value: 'S', label: 'Scheduled Release Rule' })
  const { onRowSelect, onClearAllSelection, onClearSelectionCurrentPage, selectedRecords } = useSelectableGrid({ uniqueKey: 'uniqueId', selectionKey: 'isSelected', list, setlist })
  const canView = useSelector(({ context }) =>
    context?.permissions?.filter(p => p.name === 'CAN_PERFORM_DOC_PDF_VIEW').length > 0)
  useEffect(() => {
    searchDocument(fileSearchParam)
  }, [])

  useEffect(() => {
    if (allData) {
      setlist(JSON.parse(JSON.stringify(allData)))
    }
  }, [allData, totalRecordsResp])



  useEffect(() => {
    if (transId) {
      setisOpenConfirmModal(false);
      // searchDocument({ ...fileSearchParam, count })
      // setCount(count + 1);
      onClearAllSelection()
      history.push({ pathname: '/DocumentManager/Release/Document', state: `You have successfully released the documents.  The transaction ID is ${transId}` })
    }
  }, [transId]);

  useEffect(() => {
    if (isErrorPreviewFile) {
      notify(errorPreviewFile || DEFAULT_DOWNLOAD_ERR_MESSAGE, 'negative');
    }
  }, [isErrorPreviewFile])

  useEffect(() => {
    if (file && claimNumberDownloadTrack.filename) {
      downloadFile(file)
    }
  }, [file])

  const downloadFile = (f) => {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
      window.navigator.msSaveOrOpenBlob(f, claimNumberDownloadTrack.filename);
    } else {
      const FILE = window.URL.createObjectURL(f);
      const docUrl = document.createElement('a');
      docUrl.href = FILE;
      docUrl.setAttribute('download', claimNumberDownloadTrack.filename);
      document.body.appendChild(docUrl);
      docUrl.click();
      document.body.removeChild(docUrl);
    }
  }

  const onCloseConfirmModal = () => {
    setisOpenConfirmModal(false)
    return true;
  }

  const onShowMoreClick = useCallback((record, index, currentShowMoreTarget) => {
    dispatch(setRecord({ record, index, currentShowMoreTarget }));
    openDetailPane();
  }, [allData]);

  const onConfirm = () => {
    // const fileIds = [...new Map(list.filter(y => y.isSelected).map(item => [item.groupid, item])).values()].map(x=>x.groupid);
    const selectedItemids = [releaseType.value]
    if(selectedRecords && selectedRecords.size){
      Array.from(selectedRecords).forEach(l => {
        selectedItemids.push(l.documentKey);
      });
      submitDocument({ pstrDocumentKeys: selectedItemids, count });
      setCount(count + 1);
    }
  }

  const renderClaimNumberDisplay = (bPreviewLink, bRelatedKeys, claimNumber, documentKey, relKeys) => {
    if (claimNumber) {
      if (claimNumber.constructor.name === "Array" && claimNumber.length > 1) {
        if (bPreviewLink === 'True') {
          if (bRelatedKeys === 'True') {
            return (
              <AnchorInput
                id='fileKey'
                type='button'
                className='fileKeyLink'
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if(canView) {
                  if (documentKey !== claimNumberDownloadTrack.documentKey) {
                    previewFile({
                      payload: { documentKey, relKeys }, config: JSON.parse(JSON.stringify({
                        documentKey,
                        filename: 'printPreview.pdf'
                      }))
                    });
                  } else {
                    refetchPreviewFile()
                  }} else {
                    notify('You do not have required permission to perform this function','negative')
                  }
                }}
              >multiple</AnchorInput>
            )
          }
          return (
            <AnchorInput
              id='fileKey'
              type='button'
              className='fileKeyLink'
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if(canView) {
                if (documentKey !== claimNumberDownloadTrack.documentKey) {
                  previewFile({
                    payload: { documentKey, relKeys: "none" }, config: JSON.parse(JSON.stringify({
                      documentKey,
                      filename: 'printPreview.pdf'
                    }))
                  });
                } else {
                  refetchPreviewFile()
                }} else {
                  notify('You do not have required permission to perform this function','negative')
                }
              }}
            >multiple</AnchorInput>
          )
        }
        return <span>multiple</span>
      }
      if (bPreviewLink === 'True') {
        if (bRelatedKeys === 'True') {
          return (
            <AnchorInput
              id='fileKey'
              type='button'
              className='fileKeyLink'
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if(canView) {
                if (documentKey !== claimNumberDownloadTrack.documentKey) {
                  previewFile({
                    payload: { documentKey, relKeys }, config: JSON.parse(JSON.stringify({
                      documentKey,
                      filename: 'printPreview.pdf'
                    }))
                  });
                } else {
                  refetchPreviewFile()
                }} else {
                  notify('You do not have required permission to perform this function','negative')
                }
              }}
            >{claimNumber.claimNumber}</AnchorInput>
          )
        }
        return (
          <AnchorInput
            id='fileKey'
            type='button'
            className='fileKeyLink'
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (canView) {
              if (documentKey !== claimNumberDownloadTrack.documentKey) {
                previewFile({
                  payload: { documentKey, relKeys: "none" }, config: JSON.parse(JSON.stringify({
                    documentKey,
                    filename: 'printPreview.pdf'
                  }))
                });
              } else {
                refetchPreviewFile()
              }} else {
                notify('You do not have required permission to perform this function','negative')
              }
            }}
          >{claimNumber.claimNumber}</AnchorInput>
        )
      }
      return <span>{claimNumber.claimNumber}</span>
    }
    return null;
  }

  const transformGroupedData = (data, isGrouped, isModal) => {
    const temp = JSON.parse(JSON.stringify(data))
    let t;
    if (isGrouped) {
      t = temp.map((d) => ({
        ...d,
        subRecords: d.subRecords.map((s) => {
          let statusComp = '';
          let className;
          switch (s.status) {
            case 'Imported':
              className = 'status-ok';
              break;
            case 'Released':
              className = 'status-ok';
              break;
            case 'Ready to print':
              className = 'status-ok';
              break;
            case 'Held':
              className = 'status-warn';
              break;
            case 'Held and Errored':
              className = 'status-error';
              break;
            case 'Errored':
              className = 'status-error';
              break;
            case 'Purged':
              className = 'status-error';
              break;
            default:
              break;
          }
          if (s.status === 'Errored' && !isModal) {
            statusComp = <AnchorInput
              id='fileKey'
              type='button'
              style={{ color: 'red' }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (onShowMoreClick) {
                  const record = allData.find(x => x.documentKey === s.documentKey);
                  const i = allData.map(x => x.documentKey).indexOf(s.documentKey);
                  dispatch(resetRecord())
                  dispatch(setDetailsId(s.documentKey))
                  return onShowMoreClick({ ...record, source: 'decision' }, i, 'errorDetails');
                }
              }}
            >{s.status}</AnchorInput>
          } else {
            statusComp = (
              <div className={className}>
                <span>{s.status}</span>
              </div>
            )
          }
          return {
            ...s,
            claimNumber_display: renderClaimNumberDisplay(s.$.bPreviewLink,
              s.$.bRelatedKeys,
              s.claimNumbers.claimNumber.constructor.name === 'Array' ? s.claimNumbers.claimNumber : s.claimNumbers,
              s.documentKey, s.$relKeys),
            claimType: s.claimType.description,
            documentType: s.documentType.description,
            recipientType: s.recipientType.description,
            'display_id': s.heldAtLevel === 'None' ? `Scheduled for Release at ${s.dateTime}`
              : `Held at ${s.dateTime} by user ${s.user} in transaction ${s.id}`,
            status_display: statusComp
          }
        })
      }))
    }
    return t;
  }

  const onSelectAll = () => {
    const data = list.filter(l => l.$.canManage === 'true');
    onRowSelect(true, data);
  }

  const fetchData = (args) => {
    let pi;
    if (args) {
      ({ pi } = args);
    }
    closeDetailPane();
    const payload = {
      ...fileSearchParam,
      "maxRecords": changeItemPerPage,
      "rowPosition": pi === undefined || pi === null ? pageIndex : pi
    }
    dispatch(setFileSearchParamNull(payload))
    dispatch(setFileSearchParam(payload))
    searchDocument(payload)
  }
  const renderGrid = useCallback((fromModal) => {
    if (fromModal)
      return (
        <>
          <ShowMoreNestedGridComponent
            mainGridConfig={{
              transformationFunction: (data, isGrouped) => transformGroupedData(data, isGrouped, true),
              disabledColumns: new Set([]),
              columns: new Set([
                {
                  dataName: 'display_id',
                  groupingColumnName: 'id',
                  text: 'Group Name',
                  sortable: true,
                  isSorted: 0,
                  cellType: 'text',
                },
                {
                  dataName: 'documentKey',
                  text: 'Document Key',
                  sortable: false,
                  isSorted: 0,
                  cellType: 'text',
                },
                {
                  dataName: 'claimNumber_display',
                  text: 'Claim #',
                  sortable: true,
                  isSorted: 0,
                  cellType: 'text',
                },
                {
                  dataName: 'checkNumber',
                  text: 'Check #',
                  sortable: true,
                  isSorted: 0,
                  cellType: 'text',
                }, {
                  dataName: 'checkAmount',
                  text: 'Check Amt.',
                  sortable: true,
                  isSorted: 0,
                  cellType: 'text',
                }, {
                  dataName: 'claimType',
                  text: 'Claim Type',
                  sortable: true,
                  isSorted: 0,
                  cellType: 'text',
                }, {
                  dataName: 'recipientType',
                  text: 'Recipient Type',
                  sortable: true,
                  isSorted: 0,
                  cellType: 'text',
                }, {
                  dataName: 'documentType',
                  text: 'Document Type',
                  sortable: true,
                  isSorted: 0,
                  cellType: 'text',
                }, {
                  dataName: 'status_display',
                  text: 'Status',
                  sortable: true,
                  isSorted: 0,
                  cellType: 'custom',
                }]),
              isGrouped: true,
              defaultGroupBy: 'id',
              defaultGroupingType: 'table',
              wantGroupingControls: false,
              width: '100%',
            }}
            data={Array.from(selectedRecords)}
          />
        </>
      )
    if (list && list.length > 0) {
      return (
        <>
          <ShowMoreNestedGridComponent
            mainGridConfig={{
              transformationFunction: transformGroupedData,
              disabledColumns: new Set([]),
              columns: new Set([
                {
                  dataName: 'display_id',
                  groupingColumnName: 'id',
                  text: 'Group Name',
                  sortable: true,
                  isSorted: 0,
                  cellType: 'text',
                },
                {
                  dataName: 'claimNumber_display',
                  text: 'Claim #',
                  sortable: true,
                  isSorted: 0,
                  cellType: 'text',
                },
                {
                  dataName: 'checkNumber',
                  text: 'Check #',
                  sortable: true,
                  isSorted: 0,
                  cellType: 'text',
                }, {
                  dataName: 'checkAmount',
                  text: 'Check Amt.',
                  sortable: true,
                  isSorted: 0,
                  cellType: 'text',
                }, {
                  dataName: 'claimType',
                  text: 'Claim Type',
                  sortable: true,
                  isSorted: 0,
                  cellType: 'text',
                }, {
                  dataName: 'recipientType',
                  text: 'Recipient Type',
                  sortable: true,
                  isSorted: 0,
                  cellType: 'text',
                }, {
                  dataName: 'documentType',
                  text: 'Document Type',
                  sortable: true,
                  isSorted: 0,
                  cellType: 'text',
                }, {
                  dataName: 'status_display',
                  text: 'Status',
                  sortable: true,
                  isSorted: 0,
                  cellType: 'custom',
                }]),
              isGrouped: true,
              defaultGroupBy: 'id',
              defaultGroupingType: 'table',
              wantGroupingControls: false,
              width: '85%',
              supportSelection: true,
              selectionKey: 'isSelected',
              selectionCondition: (x) => x.$.canManage === 'true',
              disabledCondition: (x) => x.user === 'Scheduled' && releaseType.value === 'I',
              onRowClick: (e, rec) => {
                e.preventDefault();
                e.stopPropagation();
                if (rec) {
                  const record = allData.find(x => x.documentKey === rec.documentKey);
                  const i = allData.map(x => x.documentKey).indexOf(rec.documentKey);
                  if (onShowMoreClick) {
                    dispatch(resetRecord())
                    dispatch(setDetailsId(rec.documentKey))
                    return onShowMoreClick({ ...record, source: 'decision' }, i, 'showMore');
                  }
                  return false;
                }
                return false;
              },
              onRowSelect
            }}
            data={list}
          />
        </>
      )
    }
    return (
      <>
        <div className="displayFlex">
          <label style={{ color: 'red' }}>No records found. Change search criteria.</label>
        </div>
      </>
    )
  }, [list, releaseType, claimNumberDownloadTrack])
  const renderConfirmSelectedItems = useMemo(() => (
    <Button
      buttonType="standard"
      size="small"
      name="CONFIRM SELECTED ITEMS"
      disabled={!selectedRecords.size}
      onClick={() => { setisOpenConfirmModal(true) }}
    />
  ), [selectedRecords])
  return (
    <>
      <ListPaneHeaderWrapper
        title="Document Manager: Release Documents"
        layoutControls={[expandCollapseListPaneButtonConfig]}

      />
      {isLoading || isLoadingPreviewFile ? (
        <LoadingPage />
      ) : isError ? (
        <ErrorPage
          error={error}
        />
      ) : (
            <div className="mainWrapper">
              <div style={{ fontSize: "small", padding: "12px" }}>
                <i>{GENERIC_TEXT1}</i>
                <p>{GENERIC_TEXT_SEARCH_HOLD}</p>
                <p style={{ color: "#bf1616" }}>
                  {GENERIC_TEXT_HOLD}
                </p>
              </div>
              <div>

                <div className="">
                  <StyleDivParent className="popBoxDiv">
                    <PopoverBox
                      trigger="hover"
                      orientation="bottom"
                      buttonName="File Cutoff Time Applies"
                      buttonType="diminished"
                      domID="format_popup"
                      width="350px"

                    >
                      <div style={{
                        padding: "14px",
                        color: "#202083",
                        fontSize: "13px",
                        fontWeight: "bold",
                      }}>
                        File cutoff time is defined as the time in which a file must be fully imported, in order to be included in the 10:00AM release.

                  </div>
                    </PopoverBox>
                  </StyleDivParent>
                </div>

                <GridPaginationWrapper
                  rowsPerPage={changeItemPerPage}
                  onChangeRowsPerPage={(v) => setchangeItemsPerPage(v)}
                  pageIndex={pageIndex}
                  onChangePageIndex={(v) => setPageIndex(v)}
                  onShowButtonCLick={(args) => fetchData(args)}
                  totalRecords={totalRecordsResp}
                  defaultRowsPerPage={changeItemPerPage}
                  enableTextbox={false}
                />
                <div style={{ marginBottom: "10px" }} className="displayFlex">
                  <span>Release Type:</span>
                  <SelectDropdown
                    domID="relType"
                    onChange={(e) => {
                      setReleaseType(e)
                      setlist(JSON.parse(JSON.stringify(list)))
                    }}
                    initialValue={releaseType}
                    options={[
                      { value: 'S', label: 'Scheduled Release Rule' },
                      { value: 'I', label: 'First Available' }
                    ]}
                    size="small"
                    width="240px"
                  />
                </div>
                <div className='displayFlex'>
                  <Button
                    buttonType="standard"
                    size="small"
                    name="Select all on this page"
                    disabled={list && list.length === 0}
                    onClick={() => { onSelectAll() }}
                  />&nbsp;
              <Button
                    buttonType="standard"
                    size="small"
                    name="Clear all on this page"
                    disabled={list.filter(x => x.isSelected === true).length === 0}
                    onClick={() => { onClearSelectionCurrentPage() }}
                  />&nbsp;
              <Button
                    buttonType="standard"
                    size="small"
                    name="Clear all"
                    disabled={!selectedRecords.size}
                    onClick={() => { onClearAllSelection() }}
                  />&nbsp;
              <Button
                    buttonType="standard"
                    size="small"
                    name="New Search"
                    onClick={() => { history.push(`/DocumentManager/Release/Document`) }}
                  />&nbsp;
              {renderConfirmSelectedItems}
                </div>
                <div className="displayFlex"></div>
                {renderGrid(false)}

                <div className="displayFlex"></div>

                <Modal isOpen={isOpenConfirmModal}>
                  <ModalHeader
                    title="Selected Documents"
                    onClose={() => onCloseConfirmModal()}
                  />
                  <ModalBody>
                    <div className="displayFlex" style={{ paddingLeft: '30px' }}>
                      <span>Confirm your selections, and then click Confirm to remove the hold and release the documents to printing.</span>
                    </div>
                    <div style={{ marginBottom: "10px", marginLeft: "30px" }} className="displayFlex">
                  <span>Release Type:</span>
                  <SelectDropdown
                    domID="relType"
                    disabled="true"
                    initialValue={releaseType}
                    options={[
                      { value: 'S', label: 'Scheduled Release Rule' },
                      { value: 'I', label: 'First Available' }
                    ]}
                    size="small"
                    width="240px"
                  />
                </div>
                    <div>
                      {isOpenConfirmModal ? (
                        <div className="modalOuterDiv" style={{
                          paddingLeft: '25px',
                          paddingRight: '25px',
                          marginTop: '20px',
                          marginBottom: '-10px',
                          maxHeight: '40vh',
                          overflow: 'scroll',
                          maxWidth: '800px'
                        }}>
                          {renderGrid(true)}
                        </div>
                      ) : null}

                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <ButtonGroup>
                      <Button
                        buttonType="standard"
                        domID="close"
                        dataTestId="test-close"
                        name="CLOSE"
                        onClick={() => setisOpenConfirmModal(false)}
                        size="medium"
                      />
                      <Button
                        buttonType="standard"
                        domID="confirm"
                        dataTestId="test-confirm"
                        name="CONFIRM"
                        onClick={onConfirm}
                        size="medium"
                        disabled={!selectedRecords.size || isTxnLoading}
                      />
                      {
                        isTxnLoading && <LoadingPage height="1vh" />
                      }
                    </ButtonGroup>
                  </ModalFooter>
                </Modal>
              </div>
            </div>
          )}
    </>
  )
}

export default withAxiosCancellation(DSearchResultReleaseMain)
