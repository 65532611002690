import styled from 'styled-components';

import { Colors }     from '../../../../../../shared/styles/colors';
import { Typography } from '../../../../../../shared/styles/typography';

export const PanelTitle = styled.span`
  color: ${Colors.default};
  ${Typography.mediumLarge};
  ${Typography.mediumLineHeight};
  ${Typography.defaultFontWeight};
`;

export const RuleTypeButtons = styled.div `
  margin-left: auto;
  button {
    margin-left: .5rem;
  }
`;