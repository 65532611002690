import styled from 'styled-components';

export const RulesGridWrapperStyle = styled.div`
  & td.status {
    display: inline-flex;
  }
  & button:not(.settings-trigger) {
    text-align: left;
  }
  & svg {
    right: auto !important;
    display: none;
  }
`;
export const GridCellWrap = styled.div`
  text-decoration: underline;
`;
