/* eslint-disable no-nested-ternary */
import React, { useMemo, useEffect, useState, useCallback } from 'react';
import styled from 'styled-components/dist/styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom'
import {
  Grid,
  useLayout,
  Modal, ModalHeader, ButtonGroup,
  Button, ModalFooter, ModalBody
} from 'ui-core/dist'
import { LoadingPage, AnchorInput } from '../../../components';

import ErrorPage from '../../ErrorPage';
import {
  FILE_SEARCH_RESULT_COLUMN,
  GENERIC_TEXT_HOLD,
  GENERIC_TEXT_FILE_SEARCH_HOLD,
} from "../Modules/constants";
import withAxiosCancellation from '../../../components/withAxiosCancellation'
import { useSearchFiles, useHoldFiles } from '../../../api/useDocumentManager';
import ListPaneHeaderWrapper from '../../../components/ErrorDisplaySection/ListPaneHeaderWrapper';
import GridPaginationWrapper from '../../../components/GridPaginationWrapper';
import useSortableGrid from '../../../hooks/useSortableGrid';
import { setFileSearchParam, setFileSearchParamNull } from "../Modules/action";
import FileReceiptManagerDetailComponent from '../../FileReceiptManager/FileReceiptManagerDetailComponent';
import useNotificationBannerHook from '../../../useNotificationBannerHook';
import '../Modules/styles.scss';
import useSelectableGrid from '../../../hooks/useSelectableGrid';

const StyledModalWrapper = styled.div`
height: 80vh;
`
const StyledModalHeaderWrapper = styled.div`
& div {
  padding:1px 9px !important;
  padding-bottom: 0px !important;
}
`

const FSearchResultHoldMain = ({getCancelSourceWithName}) => {
  const {
    expandCollapseListPaneButtonConfig
  } = useLayout()
  const dispatch = useDispatch()
  const history = useHistory();
  const { notify } = useNotificationBannerHook()
  const { allData, totalRecordsResp, searchFiles, isLoading, isError, error, refetch } = useSearchFiles({getCancelSource:getCancelSourceWithName('fileSearchResultPageHold')});

  const { fileSearchParam } = useSelector(({ documentSearch }) => documentSearch);
  const [changeItemPerPage, setchangeItemsPerPage] = useState(fileSearchParam.maxRecords)
  const [pageIndex, setPageIndex] = useState(0)
  const [isOpenFileReceiptModal, setisOpenFileReceiptModal] = useState(false)
  const [currentFileKey, setCurrentFileKey] = useState('')
  const [disabledItemIds, setDisableItemIds] = useState([])
  const [isOpenConfirmedModal, setConfirmedModal] = useState(false)
  const { transactionId, nextClick, isLoading: isTxnLoading } = useHoldFiles()
  useEffect(() => {
    searchFiles(fileSearchParam)
  }, [])

  const { onRowSelect, onClearAllSelection, selectedItemIds, selectedRecords, selectedItemIdsCurrentPage } = useSelectableGrid({ uniqueKey: 'filekeyUnique', list: allData, isUicl: true })

  useEffect(() => {
    if (allData) {
      const disableItems = allData.filter(x => (x.status._.indexOf("Held") !== -1 || x.filekey.$.canManage === 'false')).map(y => y.filekey._)
      setDisableItemIds(disableItems)
    }
  }, [allData])

  useEffect(() => {
    if (transactionId.resp) {
      onClearAllSelection()
      // notify(`You have successfully held the files.  The transaction ID is ${transactionId.resp}`, 'positive')      
      // refetch()
      history.push({ pathname: '/DocumentManager/Hold/File', state: `You have successfully held the files.  The transaction ID is ${transactionId.resp}` })
    }
  }, [transactionId])

  const errorPercentage = (fileSearchResults) => {
    let str = ''
    if (fileSearchResults.status.$) {
      if (fileSearchResults.status.$.errors) {
        str = `${fileSearchResults.status.$.percent}% ${fileSearchResults.status.$.errors} errors`;
      } else {
        str = `${fileSearchResults.status.$.percent}%`;
      }
    }
    return str;
  }


  const fetchSortingData = useCallback((args) => {
    let sortcolumn; let sd; let pi;
    if (args) {
      ({ sortcolumn, sd, pi } = args);
    }
    const payload = JSON.parse(JSON.stringify({
      "maxRecords": changeItemPerPage,
      "rowPosition": pi === undefined || pi === null ? pageIndex : pi,
      "sortField": sortcolumn === undefined || sortcolumn === null ? sortConfig.sortDataName : sortcolumn,
      "sortDescending": sd === undefined || sd === null ? sortConfig.sortDescending : sd,
      "checkNumber": fileSearchParam ? fileSearchParam.checkNumber : '',
      "claimNumber": fileSearchParam ? fileSearchParam.claimNumber : '',
      "groupId": fileSearchParam ? fileSearchParam.groupId : '',
      "idNumber": fileSearchParam ? fileSearchParam.idNumber : '',
      "filekey": fileSearchParam ? fileSearchParam.filekey : '',
      "showDuplicateChecks": true,
      "showProcessedDocuments": false
    }))
    dispatch(setFileSearchParamNull(payload))
    dispatch(setFileSearchParam(payload))
    searchFiles(payload)

  }, [changeItemPerPage, pageIndex, sortConfig]);

  const [sortConfig, getArgumentsAndSortGrid] = useSortableGrid(fetchSortingData, "filekey", "true");

  const onCloseFileReceiptModal = () => {
    setisOpenFileReceiptModal(false)
    setCurrentFileKey('')
    // dispatch(resetFileKey())
    return true;
  }
  const onCloseConfirmedModal = () => {
    setConfirmedModal(false)
    setCurrentFileKey('')
    // dispatch(resetFileKey())
    return true;
  }
  const nextConfirmed = () => {
    const pstrFileKeys = Array.from(selectedItemIds);
    nextClick({ pstrFileKeys })
    setConfirmedModal(false)
  }
  const renderStatus = (status) => {
    let className;
    switch (status) {
      case 'Imported':
        className = 'status-ok';
        break;
      case 'Released':
        className = 'status-ok';
        break;
      case 'Ready to print':
        className = 'status-ok';
        break;
      case 'Held':
        className = 'status-warn';
        break;
      case 'Held and Errored':
        className = 'status-error';
        break;
      case 'Errored':
        className = 'status-error';
        break;
      case 'Purged':
        className = 'status-error';
        break;
      default:
        break;
    }

    return (
      <div className={className}>
        <span>{status}</span>
      </div>
    )
  }
  const renderCheckedContent = useMemo(() => {
    const selectedRecordsArray = Array.from(selectedRecords);
    if (selectedRecordsArray.length) {
      // const temp=JSON.parse(JSON.stringify(errorResp));
      const data = selectedRecordsArray.map((fileSearchResults) => ({
        filekeyUnique: fileSearchResults.filekey._,
        originalFileName: fileSearchResults.originalFileName,
        filekey: fileSearchResults.filekey._,
        importedDate: fileSearchResults.importedDate,
        status: renderStatus(fileSearchResults.status._),
        statusText: fileSearchResults.status._,
        errorPercent: errorPercentage(fileSearchResults)
      }))


      return (
        <Grid
          {...FILE_SEARCH_RESULT_COLUMN}
          domID="DocumentSearchHoldConfirm"
          dataTestId="test-gridDefault"
          maxHeight="350px"
          records={data || []}
        // onSortGridColumn={(e,state)=>getArgumentsAndSortGrid(state)}
        />
      );
    }
    return '';
  }, [selectedRecords, isOpenConfirmedModal])

  const renderGridContent = useMemo(() => {
    if (allData.length) {
      const data = allData.map((fileSearchResults) => ({
        ...fileSearchResults,
        originalFileName: fileSearchResults.originalFileName,
        filekey: (
          // <a onClick={(e)=>{history.push(`/ErrorManager/details/${record.id}`)}}>{record.id}</a>
          <AnchorInput
            id='fileKey'
            type='button'
            className='fileKeyLink'
            // onClick={()=>{history.push(`/FileReceiptManager/details/${fileSearchResults.filekey._}`)}}
            onClick={() => {
              setisOpenFileReceiptModal(true)
              setCurrentFileKey(fileSearchResults.filekey._)
            }}
          >{fileSearchResults.filekey._}</AnchorInput>
        ),
        importedDate: fileSearchResults.importedDate,
        status: renderStatus(fileSearchResults.status._),
        statusText: fileSearchResults.status._,
        errorPercent: errorPercentage(fileSearchResults)
      }))


      return (
        <Grid
          {...FILE_SEARCH_RESULT_COLUMN}
          domID="DocumentSearchHold"
          dataTestId="test-gridDefault"
          records={data || []}
          selectionKey="filekeyUnique"
          initialSelectedItemIds={new Set(selectedItemIdsCurrentPage)}
          initialDisabledItemIds={new Set(disabledItemIds)}
          onRowSelect={onRowSelect}
          onSelectAll={onRowSelect}
          onSortGridColumn={(e, state) => getArgumentsAndSortGrid(state)}
          supportSelection={!(disabledItemIds.length >= changeItemPerPage)}
          initialSortingKey={`${sortConfig.sortDataName}|${sortConfig.sortDescending === "true" ? 'SORT_DESCENDING' : 'SORT_ASCENDING'}`}
        />
      );
    } else {
      return (
        <div className="displayFlex">
          <label style={{ color: 'red' }}>No records found. Change search criteria.</label>
        </div>
      )
    }
  }, [allData, disabledItemIds, selectedItemIds, selectedItemIdsCurrentPage, transactionId, selectedRecords]);

  const renderButtonContent = useMemo(() => (
    <>
      <Button
        disabled={!selectedItemIds.size}
        buttonType="standard"
        size="small"
        name="Clear all"
        onClick={() => {
          onClearAllSelection();
        }}
      />
      <Button
        disabled={!selectedItemIds.size}
        buttonType="standard"
        size="small"
        name="CONFIRM SELECTED ITEMS"
        onClick={() => {
          setConfirmedModal(true);
        }}
      />
    </>
  ), [selectedItemIds])
  return (
    <>
      <ListPaneHeaderWrapper
        title="Document Manager: Hold Files"
        layoutControls={[expandCollapseListPaneButtonConfig]}

      />
      {isLoading ? (
        // replace with a better loading component
        <LoadingPage />
      ) : isError ? (
        // replace with a better error component
        <ErrorPage
          // this prop isn't real but is an example
          error={error}
        />
      ) : (
            <div className="mainWrapper">
              <div style={{ fontSize: "small", padding: "12px" }}>
                <p>
                  {GENERIC_TEXT_FILE_SEARCH_HOLD}
                </p>
                <p style={{ color: "#bf1616" }}>
                  {GENERIC_TEXT_HOLD}
                </p>
              </div>
              <div>

                <div className="marginLeft">
                  <ButtonGroup>
                    <Button
                      buttonType="standard"
                      size="small"
                      name="New Search"
                      domID="holdBack"
                      onClick={() => { history.push(`/DocumentManager/Hold/File`) }}
                    />
                    {renderButtonContent}
                  </ButtonGroup>
                </div>

                <GridPaginationWrapper
                  rowsPerPage={changeItemPerPage}
                  onChangeRowsPerPage={(v) => setchangeItemsPerPage(v)}
                  pageIndex={pageIndex}
                  onChangePageIndex={(v) => setPageIndex(v)}
                  onShowButtonCLick={(args) => fetchSortingData(args)}
                  totalRecords={totalRecordsResp}
                  defaultRowsPerPage={changeItemPerPage}
                  enableTextbox={false}
                />
                <div className='GridWrapper'>
                  {renderGridContent}
                </div>
              </div>
            </div>
          )}
      <Modal isOpen={isOpenFileReceiptModal}>
        <StyledModalWrapper>
          <StyledModalHeaderWrapper>
            <ModalHeader
              title="File Receipt"
              onClose={() => onCloseFileReceiptModal()}
            />
          </StyledModalHeaderWrapper>
          <ModalBody>
            {isOpenFileReceiptModal ? (
              <FileReceiptManagerDetailComponent
                filekey={currentFileKey}
                height='58vh'
                overflow='auto'
              />
            ) : null}
          </ModalBody>
        </StyledModalWrapper>
      </Modal>

      <Modal isOpen={isOpenConfirmedModal}>
        <ModalHeader
          title="Selected Files"
          onClose={() => onCloseConfirmedModal()}
        />
        <ModalBody>
          <div className="displayFlex" style={{ paddingLeft: '30px' }}>
            <span>Confirm your selections, and then click Confirm to place the files on hold.</span>
          </div>
          {isOpenConfirmedModal ? (
            <div>
              <div className='GridWrapper'>
                <div className="modalOuterDiv" style={{
                  paddingLeft: '25px',
                  paddingRight: '25px',
                  marginTop: '20px',
                  marginBottom: '-10px',
                  maxHeight: '40vh',
                  overflow: 'scroll',
                  maxWidth: '800px'
                }}>
                  {renderCheckedContent}
                </div>
              </div>

            </div>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button
              buttonType="standard"
              size="small"
              name="CLOSE"
              onClick={() => {
                setConfirmedModal(false);
              }}
              style={{ marginTop: '10px' }}
            />
            <Button
              buttonType="standard"
              size="small"
              name="CONFIRM"
              onClick={() => {
                nextConfirmed();
              }}
              style={{ marginTop: '10px' }}
              disabled={isTxnLoading}
            />
            {
              isTxnLoading && <LoadingPage height="1vh" />
            }
          </ButtonGroup>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default withAxiosCancellation(FSearchResultHoldMain)
