import React, { useState } from 'react';
import GroupGridView from '../../containers/GroupGridView';
import GroupDetailView from '../../containers/GroupDetailView';

const GroupWrapper = () => {
    const [detailExpanded, setDetailExpanded] = useState(false);

    const toggleDetailsView = (forceState) => {
        const newState =
            forceState !== undefined ? forceState : !detailExpanded;

        setDetailExpanded(newState);
    }

    return (
        <>
            <GroupGridView
                isExpanded={detailExpanded}
                toggleDetailsView={toggleDetailsView}
            />
            <GroupDetailView
                isExpanded={detailExpanded}
                toggleDetailsView={toggleDetailsView}
            />
        </>
    )
};

export default GroupWrapper;