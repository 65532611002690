import { connect } from 'react-redux';

import App from './index';

import {
  selectIsViewingRuleDetails,
} from '../TestAssocTabDetailsView/selectors';

const mapStateToProps = state => ({
  isAdvancedViewShown: selectIsViewingRuleDetails(state),
});

export default connect(
  mapStateToProps,
  null,
)(App);
