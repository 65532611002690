import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Warning, LoadingIndicator } from 'ui-core-ssgr';
import ButtonGroup from '../../../shared/components/ButtonGroup';
import Colors from '../../../shared/styles/colors';
import { 
  ModalPaddingWrapper, 
  BoldTextAfterWarningIcon, 
  IconWrapper, 
  ModalTitlePaddingWrapper, 
  StyledHeader,
} from '../../../shared/styles/styledComponents';
import { LoadingWrapper } from './styles';
import { DivWithPaddingTop } from '../../../shared/styles/spacing';

class DeleteRuleDetailModal extends React.Component {
  onButtonClick = name => {
    const {
      setIsShowingDeleteRuleDetailModal,
      deleteRuleDetailTrigger,
      selectedRecord,
      records,
      selectedItemIds,
      selectedRuleTypeId,
    } = this.props;

    const selectedRuleDetails = Array.from(selectedItemIds);

    const filteredArray = records.filter(r =>
      selectedRuleDetails.includes(r.id),
    );

    if (name === 'cancel') setIsShowingDeleteRuleDetailModal(false);
    const ruleDetailIdArray = [];
    if (name === 'confirm') {
      filteredArray.forEach(f => {
        ruleDetailIdArray.push(f.id);
      });
      deleteRuleDetailTrigger({
        ruleType: selectedRuleTypeId,
        ruleId: selectedRecord.id,
        ruleVersion: selectedRecord.version[0],
        ruleDetailIdArray,
      });
    }
  };

  onModalToggle = () => {
    const { setIsShowingDeleteRuleDetailModal } = this.props;
    setIsShowingDeleteRuleDetailModal(false);
  };

  render() {
    const { onButtonClick } = this;
    const { selectedRecord, loading } = this.props;

    return (
      <Modal
        domID="modal-id"
        onModalToggle={() => this.onModalToggle()}
        isOpen
        showDefaultClose
        deferBodyControl={false}
      >
        <ModalPaddingWrapper>
          <ModalTitlePaddingWrapper>
            <StyledHeader>{`Deleting rule detail record: ${selectedRecord &&
              selectedRecord.name}`}</StyledHeader>
          </ModalTitlePaddingWrapper>
          <IconWrapper>
            <Warning fillColor={Colors.negativeDark} />
          </IconWrapper>
          <BoldTextAfterWarningIcon>
            {`This  will remove the rule detail record from ${selectedRecord &&
              selectedRecord.name}.`}
          </BoldTextAfterWarningIcon>
          <DivWithPaddingTop>
            <ButtonGroup>
              <LoadingWrapper>
                {loading ? (
                  <LoadingIndicator
                    domID="delete-rule-detail-modal-loading-indicator"
                    length="30px"
                  />
                ) : null}
              </LoadingWrapper>
              <Button
                name="confirm"
                buttonType="emphasized"
                onClick={() => onButtonClick('confirm')}
                size="medium"
                type="button"
                disabled={loading}
              />
              <Button
                name="cancel"
                buttonType="diminished"
                onClick={() => onButtonClick('cancel')}
                size="medium"
                type="button"
                disabled={loading}
              />
            </ButtonGroup>
          </DivWithPaddingTop>
        </ModalPaddingWrapper>
      </Modal>
    );
  }
}

DeleteRuleDetailModal.propTypes = {
  deleteRuleDetailTrigger: PropTypes.func,
  setIsShowingDeleteRuleDetailModal: PropTypes.func,
  selectedRecord: PropTypes.object,
  records: PropTypes.array,
  selectedItemIds: PropTypes.object,
  selectedRuleTypeId: PropTypes.string,
};

export default DeleteRuleDetailModal;
