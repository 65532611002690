import React from 'react';
import { DetailPaneHeader } from 'ui-core/dist';
import ErrorDisplaySection from './ErrorDisplaySection';

const DetailPaneHeaderWrapper=({title, layoutControls})=>(
  <>
    <DetailPaneHeader
      title={title}
      layoutControls={layoutControls}
    />
    <ErrorDisplaySection/>
  </>
)

export default DetailPaneHeaderWrapper