/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
import React,{useState,useMemo, useCallback, useEffect} from 'react';
import styled from 'styled-components/dist/styled-components';
import {useDispatch} from 'react-redux';
// import moment, { calendarFormat } from 'moment';
import { Grid, 
  Chart,
  useLayout, 
  Button
} from 'ui-core/dist'
import ReactTooltip from 'react-tooltip';
import { LoadingPage } from '../../../components';
import ErrorPage from '../../ErrorPage';
import ListPaneHeaderWrapper from '../../../components/ErrorDisplaySection/ListPaneHeaderWrapper';
// import GridPaginationWrapper from '../../../components/GridPaginationWrapper';
import { CLIENT_NCOA_BROKERS_EDITOR_GRID } from '../Modules/constants';
// import useSortableGrid from '../../../hooks/useSortableGrid';
import { resetClientContactEditorRecord, setClientContactEditorRecord } from '../Modules/action';
import { useGetNCOAPAFBrokersEditorTable } from '../../../api/useClientNCOPAFBrokers';

const StyledGridWrapper=styled.div`
&.GridWrapper #grid-wrapper-test-id{
  overflow:initial !important;
}
`
const StyleButton=styled(Button)`
height: 18px !important;
width: 53px !important;
background: transparent;
box-shadow: none;
&:hover {
  background: transparent;
box-shadow: none;
}
`

const BrokerEditorMain = () => {
  const {
    expandCollapseListPaneButtonConfig,
    openDetailPane,
    closeDetailPane
  } = useLayout()
  const dispatch=useDispatch()
  const [count, setcount]=useState(0);
  const [selectedRecord,setSelectedRecord]=useState('')
  const refetchClientContactEditorRecord=()=>{
    postData(JSON.parse(JSON.stringify({
      count
    })))
    setcount(count+1)
  }
  const {NCOA_BROKER, NCOA_BROKERCount, NCOA_PAF, NCOA_PAFCount, NCOA_PAF_BROKER, postData, isLoading,isError,error }=useGetNCOAPAFBrokersEditorTable() 

  useEffect(()=>{
    if (postData) {
      closeDetailPane();
      dispatch(resetClientContactEditorRecord());
      refetchClientContactEditorRecord()
    }
  },[])
        
  const onShowMoreClick=useCallback((record)=>{
    dispatch(setClientContactEditorRecord({clientContactEditorRecord:JSON.parse(JSON.stringify(record)), mode:'Edit', refetchClientContactEditorRecord}));
    setSelectedRecord(JSON.parse(JSON.stringify(record)))
    openDetailPane();
  },[NCOA_PAF_BROKER]);
    
  const onAddRecipientTypeTranslation=()=>{
    setSelectedRecord({})
    dispatch(setClientContactEditorRecord({clientContactEditorRecord:JSON.parse(JSON.stringify({})), mode:'Add', refetchClientContactEditorRecord}));
    openDetailPane();
  }
  const renderMainGrid=useMemo(()=>{
        
    if (NCOA_PAF_BROKER) {
      const temp=JSON.parse(JSON.stringify(NCOA_PAF_BROKER))  
      const data=temp.map((t, index)=>{
        const cBrokerID=t.$.cBrokerID;  
        const {cBrokerPhone}=t;
        
        return {
          ...t,
          cBrokerID: t.$.cBrokerID,
          bCanEditDisplay: t.$.bCanEdit === "0" ?(
            <>
              <StyleButton data-tip data-for={`brokertooltip_${index}_${cBrokerID}`}
                onClick={()=>false}>
                <Chart
                  className="settings"
                  fillColor="#37474F"
                  title="Settings"
                />
              </StyleButton>
              <ReactTooltip id={`brokertooltip_${index}_${(cBrokerID)}`} type='dark' effect='solid'>
                <span>This broker has been accessed and can no longer be deleted.</span>
              </ReactTooltip>
            </>
          ):null,
          cListOwnerPhoneDisplay: (<span>({cBrokerPhone.substr(0,3)}) {cBrokerPhone.substr(3,3)}-{cBrokerPhone.substr(6,4)}</span>),
        }
      })
      return (
        <>
          <StyledGridWrapper className='GridWrapper'>
            <Grid
              {...CLIENT_NCOA_BROKERS_EDITOR_GRID}
              records={data || []}
              selectionKey="cBrokerID"
              onClickThrough={(e,rec)=>{
                e.preventDefault();
                e.stopPropagation();
                if (rec) {
                  const t=temp.find(x=>x.$.cBrokerID===rec.cBrokerID);
                  if (t) {
                    onShowMoreClick(t);
                  }
                }
                return false;
              }}
              focusedItem={selectedRecord}
            />
          </StyledGridWrapper>
        </>
      )
    }
    return null;
  },[NCOA_PAF_BROKER, selectedRecord]);
    
  return (
    <>
      <ListPaneHeaderWrapper
        title="Client NCOA PAF Manager - Broker Editor"
        layoutControls={[expandCollapseListPaneButtonConfig]}
      />
      {isLoading ? (
      // replace with a better loading component
        <LoadingPage />
      ) : isError ? (
      // replace with a better error component
        <ErrorPage
          // this prop isn't real but is an example
          error={error}
        />
      ) : (
        <>
          <div style={{marginLeft: '20px'}} className="marginLeft">
            <div style={{marginTop: '20px'}} className="displayFlex">
              <Button
                id='ClaimTypeAdd'
                size="small"
                name="Add Broker"
                onClick={(e)=>{
                  e.preventDefault();
                  e.stopPropagation();
                  onAddRecipientTypeTranslation()
                }}
              />
            </div>
          </div>
          {renderMainGrid}
        </>
      )}
    </>
  )
};

export default BrokerEditorMain