import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tabs, BreadcrumbItem, DropdownButton, Button, Tooltip } from 'ui-core-ssgr';
import { PaddingWrapper, TooltipWrapper } from '../../../../shared/styles/spacing';
import {
  StyledBreadCrumb,
  WordTagWidthSmall,
  GrayedOutWordTagSmall,
  ButtonStatus,
  AdvancedDetailHeader
} from './styles';
import { TabsWrap } from '../../../../shared/styles/styledComponents';
import {IS_ALLOWED_TO_REVERT} from '../../constants';

export class AdvancedDetailsHeader extends Component {
  componentDidMount() {
    this.updateNotifications();
  }

  componentDidUpdate(prevProps) {
    const {
      currentTab,
      deleteNotification,
      notifications,
      selectedRuleRecord,
    } = this.props;
    const { id } = selectedRuleRecord;
    if (prevProps.currentTab !== currentTab && notifications.has(id)) {
      deleteNotification(id);
    }
    if (
      !prevProps.notifications.has(id) &&
      (prevProps.currentTab !== currentTab ||
        prevProps.selectedRuleRecord.id !== id)
    ) {
      this.updateNotifications();
    }
  }

  updateNotifications = () => {
    const {
      addNotification,
      currentTab,
      selectedRuleRecord,
      isExpanded,
    } = this.props;
    const { status } = selectedRuleRecord;
    if (status && status.length === 1) {
      let msg = '';
      if (
        status[0] === 'L' &&
        currentTab === 0 &&
        isExpanded
      ) {
        msg =
          'This is a mirror of the live rule. Feel free to edit this test rule.';
      }
      if (
        status[0] === 'T' &&
        currentTab === 1
      ) {
        msg =
          'There is no live rule at this time. Committing test rule will create a live rule';
      }
      if (msg.length) {
        addNotification({
          id: selectedRuleRecord.id,
          icon: 'Info',
          type: 'neutral',
          msg,
        });
      }
    }
  };

  onBreadcrumbClick = () => {
    const {
      resetRuleDetailElements,
      selectedRuleTypeId,
      setIsViewingRuleDetails,
      setIsShowingDetailCloneRuleModal,
      setIsCloningRuleState,
      deleteNotification,
      captureCurrentPage
    } = this.props;
    resetRuleDetailElements();
    setIsViewingRuleDetails({
      isAdvancedViewShown: false,
      selectedRuleRecord: {},
      selectedRuleTypeId,
    });
    // code to remove notification 'Fill out a unique rule name', cancel state for cloning rule
    deleteNotification('cloneRule');
    setIsCloningRuleState(false);
    setIsShowingDetailCloneRuleModal(false);
    const pageTrackerPrefix = 'test associations';
    // following action is called to capture new relic data
    captureCurrentPage({pageId: `${pageTrackerPrefix}`});
  };

  getWordTags = status => {
    if (status.includes('L') && status.includes('T')) {
      return (
        <>
          <WordTagWidthSmall type="neutral" label="test" />
          <WordTagWidthSmall type="positive" label="live" />
        </>
      );
    }
    if (status[0] === 'T') {
      return (
        <>
          <WordTagWidthSmall type="neutral" label="test" />
        </>
      );
    }
    if (status[0] === 'L') {
      return (
        <>
          <GrayedOutWordTagSmall label="test" />
          <WordTagWidthSmall type="positive" label="live" />
        </>
      );
    }
    return null;
  };

  dismissDetailPane = () => {
    const { setIsViewingRuleDetails } = this.props;
    setIsViewingRuleDetails({
      isAdvancedViewShown: false,
      selectedRuleRecord: {},
    });
  };

  cloneRule = () => {
    const {
      setIsShowingDetailCloneRuleModal,
      setIsViewingRuleDetails,
      selectedRuleRecord,
      selectedRuleTypeId
    } = this.props;
    setIsViewingRuleDetails({
      isAdvancedViewShown: true,
      selectedRuleRecord,
      selectedRuleRecordId: selectedRuleRecord.id,
      selectedRuleTypeId,
    });
    setIsShowingDetailCloneRuleModal(true);
  };

  onMenuClick = e => {
    if (e.target.innerHTML === 'Clone') {
      this.cloneRule();
    }
  };

  onClickTrialHandle = () => {
    window.open(
      '/TestFileManager',
      '_blank'
    );
  };

  onClickRevertHandle = () => {
    const {
      setIsShowingRevertRuleModal,
      getRevertRuleVersions,
      selectedRuleRecord,
      selectedRuleTypeId,
      userRoleId,
      addNotification
    } = this.props;
    if(IS_ALLOWED_TO_REVERT(userRoleId).status){
      const {id} = selectedRuleRecord;
      getRevertRuleVersions({selectedRuleTypeId, ruleId: id});
      setIsShowingRevertRuleModal(true);
    }else{
      addNotification({
        id: selectedRuleTypeId,
        type: 'negative',
        msg: IS_ALLOWED_TO_REVERT(userRoleId).error,
      });
    }
  }
  
  title = () => {
    const {
      selectedRuleRecord,
      isCloningRule,
    } = this.props;
    let title = selectedRuleRecord.name;
    if (isCloningRule) {
      title = `Cloning Rule ${selectedRuleRecord.name}`;
    }
    return title;
  };

  render() {
    const {onClickTrialHandle, onClickRevertHandle} = this;
    const {
      selectedRuleRecord,
      toggleDetailsView,
      currentTab,
      updateCurrentTab,
      selectedRecord,
      setIsShowingDetailCloneRuleModal,
      setIsCloningRuleState,
      showDetailCloneRuleModal,
      isCloningRule,
      setDetailsPaneToInitialStateForClone,
      userRoleId
    } = this.props;
    return (
      <>
        <PaddingWrapper className="rule-detail-pane-header group-flow">
          <StyledBreadCrumb style={{display: 'flex'}}>
            <BreadcrumbItem
              title={`${selectedRecord.name}`}
              onBreadcrumbItemClick={this.onBreadcrumbClick}
              description=""
            />
            <BreadcrumbItem
              title={`${selectedRuleRecord.name}`}
              description=""
              disableLink
            />
          </StyledBreadCrumb>
          <AdvancedDetailHeader
            title={this.title()}
            inlineLabel={
              !isCloningRule
                ? this.getWordTags(selectedRuleRecord.status)
                : this.getWordTags(['T'])
            }
            layoutControls={[
              {
                buttonType: 'close',
                onButtonClick: () =>
                  setTimeout(() => this.dismissDetailPane(), 500) &&
                  toggleDetailsView(false),
                buttonTooltipPosition: 'top-center',
              },
            ]}
          />
          {!isCloningRule && (
            <ButtonStatus>
              {IS_ALLOWED_TO_REVERT(userRoleId).status && 
              <Button
                name="Revert"
                buttonType="deEmphasized"
                size="small"
                type="button"
                onClick={() => onClickRevertHandle()}
              /> }
              <TooltipWrapper> 
                <Tooltip
                  domID="tooltip-trial"
                  tooltipContent="Run a test file to preview your changes in a new tab. Come back to this tab when you finish the test."
                  tooltipPosition="top-left"
                  tooltipWidth={310}> 
                  <Button
                    name="Trial"
                    buttonType="deEmphasized"
                    size="small"
                    type="button"
                    onClick={onClickTrialHandle}
                  />
                </Tooltip> 
              </TooltipWrapper>
              <DropdownButton
                buttonID="Dropdown_Button"
                buttonClass="dropdown-button"
                buttonType="deEmphasized"
                size="small"
                name="more"
                onClick={() => false}
                disabled={false}
                listID="Dropdown_List"
                listClass="dropdown-list"
                menuItems={[
                  { label: 'Clone', id: 1 },
                // Commenting Trial and Term out for now. Will implement these post-beta
                // { label: 'Trial', id: 2 },
                // { label: 'Term', id: 3 },
                ]}
                onMenuClick={this.onMenuClick}
                setIsShowingDetailCloneRuleModal={setIsShowingDetailCloneRuleModal}
                setIsCloningRuleState={setIsCloningRuleState}
                showDetailCloneRuleModal={showDetailCloneRuleModal}
                isCloningRule={isCloningRule}
                toggleDetailsView={() => false}
                resetDetailsPane={setDetailsPaneToInitialStateForClone}
              />
            </ButtonStatus>
          )}
        </PaddingWrapper>
        <TabsWrap>
          <Tabs
            domID="tabsWrap"
            tabs={[
              { label: 'edit test', domID: 'edit-test-tab' },
              { label: 'live rule', domID: 'live-rule-tab' },
              {
                label: 'associations',
                domID: 'associate-tab',
              },
            ]}
            initialTab={currentTab}
            key={currentTab}
            onTabSelect={(e, { selectedTabIndex }) => {
              updateCurrentTab(selectedTabIndex);
            }}
          />
        </TabsWrap>
      </>
    );
  }
}

AdvancedDetailsHeader.propTypes = {
  resetRuleDetailElements: PropTypes.func,
  selectedRuleRecord: PropTypes.object,
  selectedRecord: PropTypes.object,
  selectedRuleTypeId: PropTypes.string,
  setIsViewingRuleDetails: PropTypes.func,
  currentTab: PropTypes.number,
  updateCurrentTab: PropTypes.func,
  toggleDetailsView: PropTypes.func,
  deleteNotification: PropTypes.func,
  notifications: PropTypes.object,
  setIsShowingDetailCloneRuleModal: PropTypes.func,
  setIsCloningRuleState: PropTypes.func,
  showDetailCloneRuleModal: PropTypes.bool,
  isCloningRule: PropTypes.bool,
  setDetailsPaneToInitialStateForClone: PropTypes.func,
  captureCurrentPage: PropTypes.func,
  userRoleId: PropTypes.string,
  addNotification: PropTypes.func.isRequired,
  setIsShowingRevertRuleModal: PropTypes.func,
  getRevertRuleVersions: PropTypes.func,
};

export default AdvancedDetailsHeader;
