export const gridConfig = {
  columns: new Set([
    {
      dataName: 'name',
      text: 'Rule Types',
      sortable: true,
    },
    {
      dataName: 'description',
      text: 'Description',
      sortable: true,
    },
  ]),
  selectionKey: 'id',
  domID: 'select-rule-type-grid',
  onSortTableColumn: () => {},
  supportSelection: true,
  initialSortingKey: 'name|SORT_ASCENDING',
  isFixedHeader: true,
};
