import React, { useState } from 'react'
import {
  NestedGrid,
  Section,
  useLayout,
  ListPaneHeader,
  Button,
  Toggle,
} from 'ui-core/dist'
import axios from '../../axios';
import useNotificationBannerHook from '../../useNotificationBannerHook';
import ErrorPage from '../ErrorPage';
import { useLayoutsRequest } from '../../api/useReportManager';
import { LoadingPage, SelectDropdown } from '../../components';
import withAxiosCancellation from '../../components/withAxiosCancellation';
import './modules/style.scss'

const VendorLayoutReport = ({getCancelSourceWithName}) => {
  const {
    expandCollapseListPaneButtonConfig
  } = useLayout()
  const { notify } = useNotificationBannerHook()


  const gridColumns = new Set([
    {
      dataName: 'Sequence',
      text: 'Sequence',
      cellType: 'text',
    },
    {
      dataName: 'DataType',
      text: 'Data Type',
      cellType: 'text',
    },
    {
      dataName: 'FieldName',
      text: 'Field Name',
      cellType: 'text',
    },
    {
      dataName: 'StartPosition',
      text: 'Start Pos',
      cellType: 'text',
    },
    {
      dataName: 'Length',
      text: 'Length',
      cellType: 'text',
    },
    {
      dataName: 'Description',
      text: 'Description',
      cellType: 'text',
    }
  ]);

  const { layoutsList: layoutsList, isLoading: pIsLoading, error, isError } = useLayoutsRequest({getCancelSource:getCancelSourceWithName('vendorLayoutRequest')});
  const [vendorReportList, setVendorReportList] = useState();
  const [isReportLoading, setIsReportLoading] = useState(false);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [initialLayout, setInitialLayout] = useState({ label: '003', value: '003' });
  const [allVersions, setAllVersions] = useState(false);
  const [layoutsValue, setLayoutsValue] = useState('003');
  const handleLayoutOptionChange = (e) => {
    setInitialLayout({ label: e?.label, value: e?.value });
    setLayoutsValue(e?.value);
  }
  const handleAllVersionsChange = (e) => {
    setAllVersions(true);
  }

  const getReport = () => {
    setIsSubmitClicked(true);
    setIsReportLoading(true);
    axios.get(`reports-manager/vendor-layout-report/${layoutsValue}/${allVersions}`)
      .then(res => {
        setVendorReportList(res.data.report.TableName);
        setIsReportLoading(false);
      }).catch(err => {
        notify(err.displayMessage, "negative")
        setIsReportLoading(false);
      })
  }

  return (
    <>
      <ListPaneHeader
        title={`Data Dictionary Reports`}
        layoutControls={[expandCollapseListPaneButtonConfig]}
      />
      { isError ? (
                  // replace with a better error component
                  <ErrorPage
                    // this prop isn't real but is an example
                    error={error}
                  />
            ):
            pIsLoading ? (
              <LoadingPage height='20px'></LoadingPage>
            ) :
      (
      <div style={{ paddingLeft: '50px', fontSize: '13px' }}>
        <h4>Vendor Layout Report</h4>
        <table id="vendorLayoutReport">
          <tr>
            {/* <td><div className='vendorlayoutreport__labels'><label>Select Layout:</label></div></td> */}
            <td className='layoutsWidth'>
              
                
                  <SelectDropdown
                    isClearable={false}
                    size="small"
                    placeholderText="Select..."
                    initialValue={initialLayout}
                    options={layoutsList}
                    onChange={(e) => handleLayoutOptionChange(e)}
                    label="Client Layout"
                  />
              
            </td>
            <td id="VersionSwitch">
              <Toggle
                altTextForCenterToggle="View All Verisons"
                dataTestId="VerisonSwitch"
                domID="VersionSwitch"
                label="All Versions"
                labelOnly
                onToggle={(e, state) => {
                  if (state.isToggled) setAllVersions(true);
                  else setAllVersions(false);
                }}
              />
            </td>
          </tr>
          <tr style={{ marginTop: '20px' }}><td><Button
            buttonType="standard"
            size="small"
            name="Submit"
            onClick={() => { getReport() }}
          /></td><td></td></tr>
        </table>
        <div style={{ paddingTop: '10px' }}>
          {
            isSubmitClicked ? (isReportLoading ? (<LoadingPage></LoadingPage>)
              : (vendorReportList && vendorReportList.map((table, index) =>
                <Section title={'Vendor Layout Report for Layout: ' + table.Layout}
                  description={'Table Name:   ' + table.TableNme}
                  count={table.Details.length}
                  isAccordion={true}
                  dataTestId={'test-section-vendorReport-' + index}
                  key={'section-vendorReport-' + index}
                  headerChildren={<div className='vendorlayoutreport__labels'>
                    <td><tr>
                      <label style={{ paddingLeft: '8px' }}>Record Type:</label>
                      <label className='vendorlayout-header__labels'>{table.RecordType}</label>
                      <label style={{ paddingLeft: '8px' }}>Version:</label>
                      <label className='vendorlayout-header__labels'>{table.Version}</label>
                      <label style={{ paddingLeft: '8px' }}>Version Code:</label>
                      <label className='vendorlayout-header__labels'>{table.VersionCode}</label>
                      <label style={{ paddingLeft: '8px' }}>Layout:</label>
                      <label className='vendorlayout-header__labels'>{table.Layout}</label>
                    </tr><tr>
                        <label style={{ paddingLeft: '200px' }}>Total Length:</label>
                        <label className='vendorlayout-header__labels'>{table.TotalLength}</label>
                      </tr></td>
                  </div>}
                ><NestedGrid
                    className='vendorLayoutGrid'
                    key={'vendorReport-' + index}
                    gridProps={{
                      selectionKey: 'Sequence',
                      dataTestId: 'test-vendorReport-' + index,
                      columns: gridColumns,
                      records: table.Details,
                      className: 'vendorLayoutGrid'
                    }}
                  /></Section>)))
              : (null)
          }
        </div>
      </div>
    )}
    </>
  )
}

export default withAxiosCancellation(VendorLayoutReport);