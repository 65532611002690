import { useAxios } from '../hooks'

const useFormDetail = (id, param) => {
  const { data, ...rest } = useAxios({
    url: `/forms/${id}?param=${param}&formItem=${id}`,
  })

  return { 
    data,
    ...rest
  }
}

export default useFormDetail