import React from 'react'
import { Grid, ListPaneHeader, useLayout, StickyLayout } from 'ui-core/dist'
import useRoleInfo from '../../api/useRoleInfo'
import { Loading } from '../../components'
import withAxiosCancellation from '../../components/withAxiosCancellation'
import "./modules/styles.scss"

const RoleManagerDetailPane = ({getCancelSourceWithName}) => {
  const {
    expandCollapseDetailPaneButtonConfig, 
    closeDetailPaneButtonConfig,
    detailProps
  } = useLayout()

  const { data, isLoading } = useRoleInfo({ roleId: detailProps?.id, getCancelSource:getCancelSourceWithName('userRoleInfo') })

  return (
    <StickyLayout
      headerContent={<ListPaneHeader 
        sticky
        title="Role Details"
        description={`Details for role ${detailProps?.id}`}
        layoutControls={[expandCollapseDetailPaneButtonConfig, closeDetailPaneButtonConfig]}
      />}
      height="calc(100vh - 80px)"
    >
      {isLoading ? <Loading /> : (
        <div className="role-details">
          <div className="role-details__row">
            <span className="role-details__label">Name:</span>
            <span className="role-details__info">{data?.name}</span>
          </div>
          <div className="role-details__row">
            <span className="role-details__label">Permission Description:</span>
            <span className="role-details__info">{data?.description}</span>
          </div>
          <div className="role-details__row">
            <span className="role-details__label">Is Active:</span>
            <span className="role-details__info">{data?.isActive === "true" || data?.isActive === "1" ? 'Active' : 'Inactive'}</span>
          </div>
          <div className="role-details__row">
            <span className="role-details__label">Permissions for this Role:</span>
          </div>
          <Grid 
            columns={new Set([
              {
                dataName: 'permName',
                text: 'Permission Name',
                cellType: 'text',
              },
              {
                dataName: 'description',
                text: 'Permission Description',
                cellType: 'text',
              },
            ])}
            records={data?.permissions || []}
            dataTestId="test-RoleModalGrid"
            domID="RoleModalGrid"
          />
        </div>
      )}
    </StickyLayout>
  )
}

export default withAxiosCancellation(RoleManagerDetailPane)