import { SORTING_COMPARATOR } from '../../../../shared/helpers/sorting';
export const gridConfig = {
  columns: new Set([
    {
      dataName: 'name',
      text: 'Rule',
      sortable: true,
    },
    {
      dataName: 'status',
      text: 'Status',
      sortable: true,
    },
    {
      dataName: 'activeGroupLabel',
      text: 'Active Group Live Association',
      sortable: true,
    },
    {
      dataName: 'description',
      text: 'Description',
      sortable: true,
    },
    {
      dataName: 'userId',
      text: 'User Id',
      sortable: true,
    },
    {
      dataName: 'dateModified',
      text: 'Date Modified',
      sortable: true,
    },
  ]),
  selectionKey: 'id',
  domID: 'rule-type-grid',
  // onSortTableColumn: () => {},
  supportSelection: true,
  initialSortingKey: 'dateModified|SORT_ASCENDING',
  sortingComparator: (sortDataName, sortDirectionString, records) => {
    if(sortDataName !== 'activeGroupLabel') {
      return records;
    }
    return SORTING_COMPARATOR(sortDataName, sortDirectionString, records);
  },
  pageTrackerPrefix: 'RD-', // rule detail
};

export const gridActiveGroupConfig = {
  columns: new Set([
    {
      dataName: 'name',
      text: 'Active Group',
      sortable: true,
    },
    {
      dataName: 'description',
      text: 'Description',
      sortable: true,
    },
  ]),
  selectionKey: 'id',
  domID: 'active-rule-grid',
  onSortTableColumn: () => {},
  supportSelection: false,
  initialSortingKey: 'dateModified|SORT_DESCENDING',
  isFixedHeader: true,
};
