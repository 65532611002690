import React from 'react'
import { Page } from '../../components'
import RouteWrapper from '../../components/RouteWrapper';
import ListPaneContent from './ActiveUserListPane'


const UserManager = () => (
  <RouteWrapper>
    <Page 
      ListPaneContent={ListPaneContent}
      // DetailPaneContent={}
    />
  </RouteWrapper>
)
export default UserManager