import React, {useState} from 'react';
import { Button, LoadingIndicator} from 'ui-core-ssgr';
import PropTypes from 'prop-types';
import {
  ModalButton,
  LoadingWrapperSmall,
  LoadingWrapperImageControl,
  ImageNameWrap,
  FileZoneWrapper
} from '../../../containers/RulesTabView/components/RuleDetails/RuleDetailsGridWrapper/styles';
import { ButtonGroup } from '../ButtonGroup';
import Filezone from './Filezone/index';
import InputControl from '../../../containers/RuleDetailModals/ModalControls/InputControl';

const FileUpload = ({
  acceptedFileTypes,
  fileSizeAllowed,
  postImageUpload, 
  selectedRuleTypeId,
  setIsShowingImageUploadModal, 
  selectedRecordIdImageLibrary, 
  fileUploadMessage, 
  loading,
  allowMultiple,
  convertImageUpload,
  convertedImageUploadData,
  convertedImageUploadError,
  handleAcceptImageUpload
}) => {

  const [isFileError, setIsFileError] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [fileUploadValidationError, setFileUploadValidationError] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isHiddenInputName, setIsHiddenInputName] = useState(true);
  const [imageNameErrorMessage, setImageNameErrorMessage] = useState(null);
  const [imageName, setImageName] = useState('');
  
  const validateFileSize = (size) => {
    const actualSize = fileSizeAllowed;
    if(size === 0){
      return false;
    }
    if (size && size > actualSize) {
      return false;
    }
    if (size > actualSize) {
      return false;
    }
    return true;
  }

  const validateFileExtension = extension => acceptedFileTypes.includes(extension.toLowerCase())

  const validateFiles = (size, extension) => {
    if (!validateFileExtension(extension)) {
      return {
        message: `Image types supported are: ${acceptedFileTypes}`,
        isValid: false
      };
    }
    if (!validateFileSize(size)) {
      return {
        message: 'Image size must be greater than 0 byte and less than 2MB',
        isValid: false
      };
    }
    return {message: null, isValid: true };
  }

  const getBase64 = (file,callback) => {
    let results = null; 
    const reader = new FileReader();
    reader.onload = function( theFile ) {
      const image = new Image();
      image.src = theFile.target.result;
      image.onload = function() {
        results = {imageBase64: reader.result, width: this.width, height: this.height, error: false};
        callback( results );
      };
      image.onerror = function(err) {
        results = {imageBase64: reader.result, width: this.width, height: this.height, error: false};
        callback( results );
      };
    };
    reader.readAsDataURL(file);
  }
  
  const uploadFile = async (e) => {
    if (!(e && e.length && e.length > 0)) return;
    let files;
    let size = 0;
    const data = new FormData();
    for (let x = 0; x < 1; x += 1) {
      files = e[x];
      size = e[x].size;
      data.append('files[]', e[x]);
    }
    
    if (size !== null && files !== null) {
      const canProceed = await validateFiles(size, files.name.split('.').pop());
      // all validations passed
      if (canProceed.isValid) {
        getBase64(files, (results) => {
          const {imageBase64} = results;
          const imageWidth = results.width;
          const imageHeight = results.height;
          const imageError = results.error;
          const acceptedFileWidth = 1280; 
          const acceptedFileHeight = 720;

          if(!imageError){
            if(imageWidth > acceptedFileWidth || imageHeight > acceptedFileHeight) {
              setIsFileUploaded(false);
              setIsFileError(true);
              setFileUploadValidationError(`Image dimension must be within ${acceptedFileWidth}x${acceptedFileHeight}`);
              setIsButtonDisabled(true);
              setIsHiddenInputName(true);
            }else{
              setIsFileUploaded(true);
              setIsFileError(false);
              setFileUploadValidationError(canProceed.message);
              setIsButtonDisabled(false);
              setIsHiddenInputName(false);
              convertImageUpload({selectedRuleTypeId, selectedRecordId: selectedRecordIdImageLibrary, imageBase64});
              //setImageName( files.name.substring(0, 50) ); // limit text to max 50 char
              setImageNameErrorMessage(null);
              setImageNameError('',0,false);
            }
          }else{
            setIsFileUploaded(false);
            setIsFileError(true);
            setFileUploadValidationError(`Error occurred while loading image, please try different image`);
            setIsButtonDisabled(true);
            setIsHiddenInputName(true);
          }
        });
      } else {
        // validations failed
        setIsFileUploaded(false);
        setIsFileError(true);
        setFileUploadValidationError(canProceed.message);
        setIsButtonDisabled(true);
        setIsHiddenInputName(true);
      }
    }
  }

  const onAcceptImageUpload = (e) => {
    const selectedRecordId = selectedRecordIdImageLibrary;
    const imageData = convertedImageUploadData;
    postImageUpload({
      selectedRuleTypeId,
      selectedRecordId,
      imageData,
      imageName
    });
    handleAcceptImageUpload(e);
  }

  const onCancelImageUpload = () => {
    // close modal and reset all states
    setIsShowingImageUploadModal(selectedRuleTypeId, selectedRecordIdImageLibrary, false);
    setIsFileUploaded(false);
    setIsFileError(false);
    setFileUploadValidationError(null);
    setIsButtonDisabled(true);
    setIsHiddenInputName(true);
    setImageNameErrorMessage(null);
    setImageName('');
  }

  const fileOnError = async () => {
    setIsFileUploaded(false);
    setIsFileError(true);
    setFileUploadValidationError(`Something went wrong with image, please try different image`);
    setIsButtonDisabled(true);
    setIsHiddenInputName(true);
  }

  const setImageNameError = (value,charCount,eventTargetReplace) => {

    const maxChar = 50;
    const minChar = 3;
    let errorMessage = '';
    if(charCount > maxChar || charCount === 0 || eventTargetReplace){
      errorMessage = `Image Name is required. Max allowed characters is ${maxChar}`;
    }else if(charCount < minChar){
      errorMessage = `User must enter Min of ${minChar} characters`;
    }else{
      errorMessage = '';
    }
    setImageName(value);
    if(errorMessage){
      setIsHiddenInputName(true); 
      setImageNameErrorMessage(errorMessage);
    }else{
      setIsHiddenInputName(false);
      setImageNameErrorMessage(null);
    }
  }

  const checkUnsavedChanges = event => {
    if (event.target) {
      const {value} = event.target;
      const charCount = event.target.value.length;
      const eventTargetReplace = !event.target.value.replace(/\s/g, '').length;
      setImageNameError(value,charCount, eventTargetReplace);
    }
  }

  const recordId = selectedRecordIdImageLibrary;
  const inputHasError = imageNameErrorMessage !== null; 
  let fileUploadMessageFinal = '';
  let isFileErrorFinal = null;
  let isFileUploadedFinal = null;
  let isButtonDisabledFinal = null;
  let isHiddenInputNameFinal = null;
  // case when convertedImageUploadError exists
  if(fileUploadValidationError === null && convertedImageUploadError !== null){
    fileUploadMessageFinal = convertedImageUploadError;
    isFileErrorFinal = true;
    isFileUploadedFinal = false;
    isButtonDisabledFinal = true;
    isHiddenInputNameFinal = true;
  }
  // case when fileUploadValidationError exists
  if(fileUploadValidationError !== null){
    fileUploadMessageFinal = fileUploadValidationError;
    isFileErrorFinal = isFileError;
    isFileUploadedFinal = isFileUploaded;
    isButtonDisabledFinal = isButtonDisabled;
    isHiddenInputNameFinal = isHiddenInputName;
  }
  // case when fileUploadValidationError && convertedImageUploadError does not exists
  if(fileUploadValidationError === null && convertedImageUploadError === null){
    fileUploadMessageFinal = fileUploadMessage;
    isFileErrorFinal = isFileError;
    isFileUploadedFinal = isFileUploaded;
    isButtonDisabledFinal = isButtonDisabled;
    isHiddenInputNameFinal = isHiddenInputName;
  }

  return (
    <>
      {(selectedRuleTypeId && loading.has(selectedRuleTypeId)) && (
        <LoadingWrapperImageControl>
          <LoadingIndicator
            domID="basic-details-form-loading-indicator"
            length="50px"
          />
        </LoadingWrapperImageControl>
      )}
      <FileZoneWrapper style={loading.has(selectedRuleTypeId) ? {'display':'none'} : {'display':'block'}}>
        <Filezone
          fileOnLoad={() => null}
          fileUploadMessage={fileUploadMessageFinal}
          acceptedFiles={acceptedFileTypes}
          isFileUploaded={isFileUploadedFinal}
          isFileError={isFileErrorFinal}
          fileOnError={e => fileOnError(e)}
          allowMultiple={allowMultiple}
          convertedImageUploadData={convertedImageUploadData}
	        uploadFile={uploadFile}
        />
      </FileZoneWrapper>
      <ButtonGroup>
        <ImageNameWrap style={isButtonDisabledFinal || loading.has(selectedRuleTypeId) ? {'display':'none'} : {'display':'block'}}>
          <InputControl
            inputDomID="upload-image-name"
            inputLabel="Image Name"
            inputName="imageName"
            inputInitialValue={imageName}
            inputOnChangeHandle={(e) => checkUnsavedChanges(e)}
            inputErrorMessage={imageNameErrorMessage || ''}
            inputHasError={inputHasError}
            inputStyles={{width: '450px',marginBottom: '12px',marginTop: '12px'}}
            inputOnBlurHandle={() => null}
            inputOnKeyDown={() => null}
            inputMaxLength={51}
            inputDisabled={isButtonDisabledFinal || loading.has(selectedRuleTypeId)} // disable when image has errors
	          />
        </ImageNameWrap>
        <ModalButton className="button-wrap-image-library">
          {(recordId && loading.has(recordId)) && (
            <LoadingWrapperSmall>
              <LoadingIndicator
                domID="basic-details-form-loading-indicator"
                length="30px"
              />
            </LoadingWrapperSmall>
          )}
          <Button
            onClick={(e) => onAcceptImageUpload(e)}
            domID="accept-ImageUpload"
            name="Accept"
            buttonType="emphasized"
            size="medium"
            isDropdown={false}
            type="button"
            disabled={isHiddenInputNameFinal || isButtonDisabledFinal || (selectedRuleTypeId && loading.has(selectedRuleTypeId)) || (recordId && loading.has(recordId))}
          />
          <Button
            domID="cancel-ImageUpload"
            name="Cancel"
            buttonType="diminished"
            size="medium"
            onClick={() => onCancelImageUpload()}
            isDropdown={false}
            type="button"
          />
        </ModalButton>
      </ButtonGroup>
    </>
  );
}

FileUpload.propTypes = {
  fileSizeAllowed: PropTypes.number,
  acceptedFileTypes: PropTypes.string,
  fileUploadMessage: PropTypes.string,
  setIsShowingImageUploadModal: PropTypes.func,
  selectedRecordIdImageLibrary: PropTypes.string,
  selectedRuleTypeId: PropTypes.string,
  postImageUpload: PropTypes.func,
  loading: PropTypes.object,
  convertImageUpload: PropTypes.func,
  convertedImageUploadData: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  allowMultiple: PropTypes.bool,
  convertedImageUploadError: PropTypes.string,
  handleAcceptImageUpload: PropTypes.func,
};

export default FileUpload;