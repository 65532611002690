import { useAxios } from '../hooks'

const useDataSources = ({ payload, getCancelSource }) => {
  const { data, ...rest } = useAxios({
    getCancelSource,
    url: `/dataSources`,
    method: 'POST',
    negativeMessage: false,
    payload
  })

  return { data: data?.data, total: data?.totalRecords || undefined, ...rest }
}
export default useDataSources