import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import us_map_icon from '../../assets/icons/us_map_icon.png';
import { setSelected as navSelected } from '../SideNav/modules/actions';
import useNotificationBannerHook from '../../useNotificationBannerHook';

const USDeliveryTime = () => {
  const dispatch = useDispatch()
  const { notify } = useNotificationBannerHook()
  const access = useSelector(store => store.context?.permissions?.filter(p => p.name === 'CAN_PERFORM_US_DELIVERY_REPORT').length > 0)
  return (
    <>
      <div>
        <h3 className="usDeliveryTimeHeader">USPS Delivery Time</h3>
        <div className="usDeliveryTimeBody">
          <span>We have a full interactive <a onClick={(e) => {
            // dispatch(navSelected(['0|14', 'US Delivery Report']))
            e.preventDefault()
            e.stopPropagation()
            if(access) {
              dispatch(push('/ReportManager/USDelivery'))  
            } else{
              notify('You do not have permission to access this page', 'caution')
              return;
            }
          }}
            href="#">USA MAP </a>
            <img alt="not avaliable" src={us_map_icon} />
          </span>
        </div>
      </div>
    </>
  )
}

export default USDeliveryTime;