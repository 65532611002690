/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
    Input,
    Button,
    ButtonGroup,
    ListPaneHeader,
    useLayout,
    Modal,
    ModalHeader
} from 'ui-core/dist'
import { SelectDropdown, Loading } from '../../components'
import ErrorPage from '../ErrorPage';
import MultiSelectDropdown from '../../components/MultipleSelectDropdownStatic'
import axios from '../../axios'
import useClients from '../../api/useSysClientLayouts'
import useNotificationBannerHook from '../../useNotificationBannerHook';
import './modules/index.scss';
import "react-datetime/css/react-datetime.css";

const UpdateDocStatus = () => {
    const {
        expandCollapseDetailPaneButtonConfig,
        closeDetailPaneButtonConfig,
        closeDetailPane,
        detailProps,
        expandCollapseListPaneButtonConfig
    } = useLayout();

    const { notify } = useNotificationBannerHook()

    const [loading, setLoading] = useState(false);
    const [firstLoading, setFirstLoading] = useState(false);
    const [lastLoading, setLastLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);

    const [clients, setClients] = useState([]);
    const [claimTypes, setClaimTypes] = useState([]);
    const [recipTypes, setRecipTypes] = useState([]);
    const [docTypes, setDocTypes] = useState([]);
    const [groups, setGroups] = useState([]);
    const [fromStatus, setFromStatus] = useState([]);
    const [toStatus, setToStatus] = useState([]);

    const [selectedClaimType, setSelectedClaimType] = useState('');
    const [selectedRecipType, setSelectedRecipType] = useState('');
    const [selectedDocType, setSelectedDocType] = useState('');
    const [selectedGroup, setSelectedGroup] = useState('');
    const [selectedFromStatus, setSelectedFromStatus] = useState('');
    const [selectedToStatus, setSelectedToStatus] = useState('');

    const [idx, setIdx] = useState(0);
    const [selectedClient, setSelectedClient] = useState('');

    const [selectedDataSet, setSelectedDataSet] = useState('');
    const [dataSet, setDataSet] = useState([]);

    const [fileKey, setFileKey] = useState('');
    const [docKey, setDocKey] = useState('');

    const [userType, setUserType] = useState('ALL');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [msgDesc, setMsgDesc] = useState('');
    const [msg, setMsg] = useState('');
    const [priority, setPriority] = useState('');

    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)

    const clearState = () => {
        setIdx(idx + 1);
        setSelectedClient('');
        setUserType('ALL');
        setStartDate('');
        setEndDate('');
        setMsgDesc('');
        setMsg('');
        setPriority('');
    }

    const { data: clientsList, isLoading: clientsLoading, isError, error } = useClients()

    useEffect(() => {
        if (clientsList && clientsList.length)
            setClients(clientsList)
    }, [clientsList])

    const getSelectionValue = (id, data) => {
        return data && data.filter(ele => {
            return ele.value === id;
        })[0];
    }

    const getFields = async ({
        queryType = '',
        clientLayout = '',
        decDataSet = '',
        fileKey = '',
        docKey = '',
        fromStatus = ''
    }) => {
        try {
            if (queryType === 'CL') setFirstLoading(true)
            if ((queryType === 'DK' && docKey === '') || (queryType === 'FK' && fileKey === '')) queryType = 'CL';
            if (queryType !== 'FS') setLoading(true);
            else setLastLoading(true)
            const res = await axios.post(`/sysAdmin/fields`, {
                queryType,
                clientLayout,
                decDataSet,
                fileKey,
                docKey,
                fromStatus,
            });
            const resData = res?.data;
            if (queryType === 'CL') {
                setDataSet(resData.retDecDataSets);
            }
            if (queryType === 'DS' || queryType === 'FK' || queryType === 'DK' || queryType === 'CL') {
                setClaimTypes(resData.retClaimTypes)
                setRecipTypes(resData.retRecipTypes)
                setDocTypes(resData.retDocTypes)
                setFromStatus(resData.retFromStatuses)
                setGroups(resData.retGroupIds)
                setToStatus(resData.retToStatuses)
            }
            if (queryType === 'FS') {
                setToStatus(resData.retToStatuses)
            }
            setLoading(false);
            setFirstLoading(false)
            setLastLoading(false)
            // setToStatus(resData.retToStatuses)
        } catch (err) {
            setLoading(false);
            setFirstLoading(false)
            setLastLoading(false)
            notify(err.displayMessage, "negative")
        }
    }

    const onClientChange = (state) => {
        setSelectedClient(state.value);
        setSelectedDataSet('');
        setFileKey('');
        setDocKey('');
        setSelectedClaimType('')
        setSelectedRecipType('')
        setSelectedDocType('')
        setSelectedFromStatus('')
        setSelectedGroup('')
        setSelectedToStatus('')
        getFields({
            clientLayout: state.value,
            queryType: 'CL'
        })
    }

    const onDataSetChange = (state) => {
        setSelectedDataSet(state.value);
        setFileKey('');
        setDocKey('');
        setSelectedClaimType('')
        setSelectedRecipType('')
        setSelectedDocType('')
        setSelectedFromStatus('')
        setSelectedGroup('')
        setSelectedToStatus('')
        getFields({
            clientLayout: selectedClient,
            queryType: 'DS',
            decDataSet: state.value
        })
    }

    const onFromStatusChange = (state) => {
        setSelectedFromStatus(state.value);
        setSelectedToStatus('')
        getFields({
            clientLayout: selectedClient,
            queryType: 'FS',
            fromStatus: state.value,
            decDataSet: selectedDataSet,
            docKey,
            fileKey
        })
    }

    const onToStatusChange = (state) => {
        setSelectedToStatus(state.value);
        // getFields({
        //     clientLayout: selectedClient,
        //     queryType: 'FS',
        //     toStatus: state.value
        // })
    }

    const onFileKeyChange = (value) => {
        setFileKey(value);
        setSelectedDataSet('');
        setDocKey('')
    }

    const onFileKeyEnter = () => {
        setSelectedClaimType('')
        setSelectedRecipType('')
        setSelectedDocType('')
        setSelectedFromStatus('')
        setSelectedGroup('')
        setSelectedToStatus('')
        getFields({
            clientLayout: selectedClient,
            queryType: 'FK',
            fileKey
        })
    }

    const onDocKeyChange = (value) => {
        setDocKey(value);
        setSelectedDataSet('');
        setFileKey('')
    }

    const onDocKeyEnter = () => {
        setSelectedClaimType('')
        setSelectedRecipType('')
        setSelectedDocType('')
        setSelectedFromStatus('')
        setSelectedGroup('')
        setSelectedToStatus('')
        getFields({
            clientLayout: selectedClient,
            queryType: 'DK',
            docKey
        })
    }

    const getDOMElements = () => {
        return <>
            <fieldset>
                <legend style={{ fontSize: '13px', color: '#0F0F59' }}>You may now select a Data Set, enter a File Key or Document Key or skip to the next section:</legend>
                {clientsLoading ? <Loading /> :
                    <>
                        <SelectDropdown
                            width="600px"
                            dataTestId="test-layout"
                            domID="ClientsDropdown"
                            label='Client / Layout'
                            onChange={onClientChange}
                            initialValue={getSelectionValue(selectedClient, clients)}
                            isClearable={false}
                            options={clients}
                            size="small"
                        />
                        {firstLoading ? <Loading /> :
                            <div className="displayRow" style={{ marginTop: '12px' }}>
                                <SelectDropdown
                                    width="200px"
                                    dataTestId="test-dataSet"
                                    domID="DataSetDropdown"
                                    label='Decision Data Set'
                                    onChange={onDataSetChange}
                                    initialValue={getSelectionValue(selectedDataSet, dataSet)}
                                    isClearable={false}
                                    options={dataSet}
                                    size="small"
                                />
                                <div style={{ width: '200px' }}>
                                    <Input
                                        domID="FileKey"
                                        onChange={(e, { value }) => onFileKeyChange(value)}
                                        size="small"
                                        initialValue={fileKey}
                                        label="File Key"
                                        maxLength="17"
                                        errorMessage="The file key must be 17 characters in length"
                                        hasError={fileKey !== '' && fileKey.length !== 17}
                                        onEnterPress={(fileKey !== '' && fileKey.length !== 17) ? () => { } : onFileKeyEnter}
                                        onBlur={(fileKey !== '' && fileKey.length !== 17) ? () => { } : onFileKeyEnter}
                                    />
                                </div>
                                <div style={{ width: '200px' }}>
                                    <Input
                                        domID="DocKey"
                                        onChange={(e, { value }) => onDocKeyChange(value)}
                                        size="small"
                                        initialValue={docKey}
                                        label="Document Key"
                                        maxLength="23"
                                        errorMessage="The document key must be 23 characters in length"
                                        hasError={docKey !== '' && docKey.length !== 23}
                                        onEnterPress={(docKey !== '' && docKey.length !== 23) ? () => { } : onDocKeyEnter}
                                        onBlur={(docKey !== '' && docKey.length !== 23) ? () => { } : onDocKeyEnter}
                                    />
                                </div>
                            </div>
                        }
                    </>
                }
            </fieldset>

            <fieldset style={{ marginTop: '16px' }}>
                <legend style={{ fontSize: '13px', color: '#0F0F59' }}>Next, select 1 or more of the following criteria. List boxes are populated based on the above selection:</legend>
                {loading ? <Loading /> :
                    <>
                        <div className="displayRow">
                            <MultiSelectDropdown
                                records={claimTypes}
                                id='ClaimType'
                                label='Claim Type'
                                disabled={docKey && docKey.length === 23 ? true : false}
                                onChange={(val) => setSelectedClaimType(val)}
                            />
                            <MultiSelectDropdown
                                records={recipTypes}
                                id='RecipientType'
                                label='Recipient Type'
                                disabled={docKey && docKey.length === 23 ? true : false}
                                onChange={(val) => setSelectedRecipType(val)}
                            />
                            <MultiSelectDropdown
                                records={docTypes}
                                id='DocumentType'
                                label='Document Type'
                                disabled={docKey && docKey.length === 23 ? true : false}
                                onChange={(val) => setSelectedDocType(val)}
                            />
                            <SelectDropdown
                                width="120px"
                                dataTestId="test-fromStatus"
                                domID="FromStatusDropdown"
                                label='Reprocess From'
                                onChange={onFromStatusChange}
                                initialValue={getSelectionValue(selectedFromStatus, fromStatus)}
                                isClearable={false}
                                options={fromStatus}
                                size="small"
                            />
                            <MultiSelectDropdown
                                records={groups}
                                id='GroupID'
                                label='Group ID'
                                disabled={docKey && docKey.length === 23 ? true : false}
                                onChange={(val) => setSelectedGroup(val)}
                            />
                        </div>
                    </>
                }
            </fieldset>
            <fieldset style={{ marginTop: '16px' }}>
                <legend style={{ fontSize: '13px', color: '#0F0F59' }}>Documents that meet the selected criteria will be reprocessed starting at the status selected below:</legend>
                {lastLoading || loading ? <Loading /> :
                    <>
                        <SelectDropdown
                            width="120px"
                            dataTestId="test-toStatus"
                            domID="ToStatusDropdown"
                            label='Reprocess To'
                            onChange={onToStatusChange}
                            initialValue={getSelectionValue(selectedToStatus, toStatus)}
                            isClearable={false}
                            options={toStatus}
                            size="small"
                        />
                    </>
                }
            </fieldset>
        </>
    };

    const saveForm = async () => {
        setSubmitLoading(true);
        try {
            const res = await axios.put(`/sysAdmin/updateStatus`, {
                clientLayout: selectedClient,
                decDataSet: '',
                docKey,
                fileKey,
                queryType: '',
                fromStatus: '',
                toStatus: '',
                retDecDataSets: selectedDataSet,
                retDocKeys: docKey,
                retFileKeys: fileKey,
                retGroupIds: selectedGroup ? selectedGroup.join(',') : '',
                retFromStatuses: selectedFromStatus,
                retToStatuses: selectedToStatus,
                retDocTypes: selectedDocType ? selectedDocType.join(',') : '',
                retClaimTypes: selectedClaimType ? selectedClaimType.join(',') : '',
                retRecipTypes: selectedRecipType ? selectedRecipType.join(',') : '',
            });
            setSubmitLoading(false)

            let msg = '';

            msg += 'You have scheduled the selected criteria for Reprocessing.'

            if (selectedClient) msg += ` For Client Layout: ${selectedClient}`
            if (selectedDataSet) msg += ` For Data Set: ${selectedDataSet}`
            if (fileKey) msg += ` For File Key: ${fileKey}`
            if (docKey) msg += ` For Document Key: ${docKey}`
            setSelectedClaimType('')
            setSelectedRecipType('')
            setSelectedDocType('')
            setSelectedFromStatus('')
            setSelectedGroup('')
            setSelectedToStatus('')
            setSelectedDataSet('');
            setFileKey('');
            setDocKey('');
            setSelectedClient('')
            setDataSet([])
            setClaimTypes([])
            setRecipTypes([])
            setDocTypes([])
            setFromStatus([])
            setGroups([])
            setToStatus([])
            notify(msg, 'positive')
            // getFields({
            //     clientLayout: selectedClient,
            //     queryType: 'CL'
            // })
        } catch (err) {
            setSubmitLoading(false)
            notify(err.displayMessage, "negative")
        }
    }

    return (
        <>
            <ListPaneHeader
                title="Update Document Status"
                layoutControls={[expandCollapseListPaneButtonConfig]}
            />
            {submitLoading ? <Loading /> : isError  ? (
        // replace with a better error component
          <ErrorPage
            // this prop isn't real but is an example
            error={error }
          />
            ) : <div className='updateStatusDetail'>
                {getDOMElements()}
                <div style={{ marginTop: '20px' }}>
                    <Button
                        buttonType="standard"
                        domID="ConfirmAction"
                        size="small"
                        name="Submit"
                        disabled={selectedFromStatus === '' || selectedToStatus === '' || (fileKey && fileKey.length !== 17) || (docKey && docKey.length !== 23)}
                        onClick={saveForm}
                    ></Button>
                </div>
            </div>}
        </>
    )
}

export default UpdateDocStatus