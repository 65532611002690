import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  LoadingIndicator,
  Button,
  Modal, 
  Warning
} from 'ui-core-ssgr';
import ButtonGroup from '../../../shared/components/ButtonGroup';
import Colors from '../../../shared/styles/colors';
import { DivWithPaddingTop } from '../../../shared/styles/spacing';
import { 
  ModalPaddingWrapper, 
  IconWrapper,
  BoldTextAfterWarningIcon, 
  ModalTitlePaddingWrapper, 
  StyledHeader,
} from '../../../shared/styles/styledComponents';
import {
  CommitImageModalContentPaddingWrapper,
  LoadingWrapper
} from './styles';

const CommitImageModal = (props) => {
  const {
    workflow,
    loading,
    selectedRuleTypeId,
    selectedDataToCommit,
    postImageUploadSuccessId,
    ruleId,
    ruleVersion,
    ruleDetailId
  } = props;
  
  let imageId = null;
  let imageName = null;

  if(workflow === 'upload' &&  !_.isEmpty(postImageUploadSuccessId)){
    ({ imageId } = postImageUploadSuccessId.imageId);
    ({ imageName } = postImageUploadSuccessId);
  }
  if(workflow === 'ruledetail' && !_.isEmpty(selectedDataToCommit)){
    ({ imageId, imageName } = selectedDataToCommit);
  }

  const onModalToggle = () => {
    const status = false;
    const {postCommitImageTriggerCancel, setIsShowingCommitImageModal} = props;
    postCommitImageTriggerCancel({imageId, selectedRuleTypeId, imageName, ruleId, ruleVersion, ruleDetailId})
    setIsShowingCommitImageModal({status, workflow});
  }

  const onButtonClick = name => {
    const {
      setIsShowingCommitImageModal,
      postCommitImage,
      postCommitImageTriggerCancel
    } = props;
    if (name === 'confirm') {
      postCommitImage({imageId, selectedRuleTypeId, imageName, ruleId, ruleVersion, ruleDetailId});
    }else{
      postCommitImageTriggerCancel({imageId, selectedRuleTypeId, imageName, ruleId, ruleVersion, ruleDetailId})
      const status = false;
      setIsShowingCommitImageModal({status, workflow});
    }
  }

  const commitWarningMessage = workflow === 'upload' ? `Please select "Back to Rule Detail" to save the rule detail.`: `We recommend testing new images before they are committed. Please do so if you have not already.`;
  const buttonText = workflow === 'upload' ? 'BACK TO RULE DETAIL' : 'CONFIRM';
  return(
    <Modal
      domID="modal-id"
      onModalToggle={() => onModalToggle()}
      isOpen
      showDefaultClose
      deferBodyControl={false}
    >
      <ModalPaddingWrapper>
        {workflow && workflow === 'upload' ? 
        <>
            <ModalTitlePaddingWrapper>
              <StyledHeader>
                {`Test & Commit Image`}
              </StyledHeader>
            </ModalTitlePaddingWrapper>
            <CommitImageModalContentPaddingWrapper>
              {`Your image`} <span>{imageName}</span> {`has been saved to the library successfully in test.`}
            </CommitImageModalContentPaddingWrapper>
            <IconWrapper>
              <Warning fillColor={Colors.negativeDark} />
            </IconWrapper>
            <BoldTextAfterWarningIcon>
              {`We recommend testing new images before they are committed.`}
            </BoldTextAfterWarningIcon>
            <CommitImageModalContentPaddingWrapper>
              {commitWarningMessage}
            </CommitImageModalContentPaddingWrapper>
        </> : 
        <>
            <ModalTitlePaddingWrapper>
              <StyledHeader>
                {`Committing Image`}
              </StyledHeader>
            </ModalTitlePaddingWrapper>
            <CommitImageModalContentPaddingWrapper>
              {`Your are committing`} <span>{imageName}</span> {`from test to live.`}
            </CommitImageModalContentPaddingWrapper>
            <IconWrapper>
              <Warning fillColor={Colors.negativeDark} />
            </IconWrapper>
            <BoldTextAfterWarningIcon>
              {commitWarningMessage}
            </BoldTextAfterWarningIcon>
            <CommitImageModalContentPaddingWrapper>
              {`If you select "Confirm", it will commit the image to live.`}
            </CommitImageModalContentPaddingWrapper>
        </>}
        <DivWithPaddingTop>
          <ButtonGroup>
            {loading.has(selectedRuleTypeId) && (
              <LoadingWrapper>
                <LoadingIndicator
                  domID="basic-details-form-loading-indicator"
                  length="30px"
                />
              </LoadingWrapper>
            )}
            <Button
              name={buttonText}
              buttonType="emphasized"
              onClick={() => workflow === 'upload' ? onButtonClick('cancel') : onButtonClick('confirm')}
              size="medium"
              type="button"
              disabled={loading.has(selectedRuleTypeId)}
            />
            <Button
              name="cancel"
              buttonType="diminished"
              onClick={() => onButtonClick('cancel')}
              size="medium"
              type="button"
            />
          </ButtonGroup>
        </DivWithPaddingTop>
      </ModalPaddingWrapper>
    </Modal>
  );
}

CommitImageModal.propTypes = {
  workflow: PropTypes.string,
  setIsShowingCommitImageModal: PropTypes.func,
  postImageUploadSuccessId: PropTypes.object,
  postCommitImage: PropTypes.func,
  selectedRuleTypeId: PropTypes.string,
  loading: PropTypes.object,
  selectedDataToCommit: PropTypes.object,
  ruleId: PropTypes.string,
  ruleVersion: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  postCommitImageTriggerCancel: PropTypes.func, 
  ruleDetailId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
}
export default CommitImageModal;