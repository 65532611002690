import { useEffect, useState } from 'react';
import { useAxiosPost } from '../hooks';

const useAddAddress = (callback) => {
  const { data, status, error, isError, isLoading, isSuccess, refetch, postData, metaData } = useAxiosPost({
    url: 'addressManager/addAddress',
    method: 'POST',
    dispatchError: false
  });

  useEffect(() => {
    if (data)
      if (callback) callback(false);
  }, [data]);

  useEffect(() => {
    if (isError) {
      if (callback) callback(error);
    }
  }, [isError]);
  return { data, status, error, isError, isLoading, isSuccess, refetch, metaData, postData };
};

const useDeactivateAddress = (callback) => {
  const { data, status, error, isError, isLoading, isSuccess, refetch, postData, metaData } = useAxiosPost({
    url: 'addressManager/deactivateAddress',
    method: 'POST',
    dispatchError: false
  });

  useEffect(() => {
    if (data)
      if (callback) callback(false);
  }, [data]);

  useEffect(() => {
    if (isError) {
      if (callback) callback(error);
    }
  }, [isError]);
  return { data, status, error, isError, isLoading, isSuccess, refetch, metaData, postData };
};

const useGetAddress = (callback) => {
  const [result, setresult] = useState(null);
  const { data, status, error, isError, isLoading, isSuccess, refetch, postData, metaData } = useAxiosPost({
    url: 'addressManager/getAddress',
    method: 'POST',
    dispatchError: false
  });

  useEffect(() => {
    if (data) {
      if (data?.resp === '' || data?.dnf) {
        seterrorResp(JSON.parse(JSON.stringify([])));
        settotalRecordsResp(0);
        return;
      }
      const { resp, fa, fs, fc, rc, dnf } = data;
      if (resp) {
        setresult(JSON.parse(JSON.stringify({
          resp: {
            ...data.resp,
          }
        })));
      } else if (fa && fs && fc && rc) {
        seterrorResp(JSON.parse(JSON.stringify([])));
        settotalRecordsResp(0);
      }
      
      if (callback) callback(false);
    }
  }, [data]);

  useEffect(() => {
    if (isError) {
      if (callback) callback(error);
    }
  }, [isError]);
  return { data: result, status, error, isError, isLoading, isSuccess, refetch, metaData, postData };
};

const useGetAddresses = (callback) => {
  const { data, status, error, isError, isLoading, isSuccess, refetch, postData, metaData } = useAxiosPost({
    url: 'addressManager/getAddresses',
    method: 'POST',
    dispatchError: false
  });

  useEffect(() => {
    if (data)
      if (callback) callback(false);
  }, [data]);

  useEffect(() => {
    if (isError) {
      if (callback) callback(error);
    }
  }, [isError]);
  return { data, status, error, isError, isLoading, isSuccess, refetch, metaData, postData };
};

const useGetAddressNames = (callback) => {
  const { data, status, error, isError, isLoading, isSuccess, refetch, postData, metaData } = useAxiosPost({
    url: 'addressManager/getAddressNames',
    method: 'POST',
    dispatchError: false
  });

  useEffect(() => {
    if (data)
      if (callback) callback(false);
  }, [data]);

  useEffect(() => {
    if (isError) {
      if (callback) callback(error);
    }
  }, [isError]);
  return { data, status, error, isError, isLoading, isSuccess, refetch, metaData, postData };
};

const useGetAlternateAddresses = (callback) => {
  const { data, status, error, isError, isLoading, isSuccess, refetch, postData, metaData } = useAxiosPost({
    url: 'addressManager/getAlternateAddresses',
    method: 'POST',
    dispatchError: false
  });

  useEffect(() => {
    if (data)
      if (callback) callback(false);
  }, [data]);

  useEffect(() => {
    if (isError) {
      if (callback) callback(error);
    }
  }, [isError]);
  return { data, status, error, isError, isLoading, isSuccess, refetch, metaData, postData };
};

const useGetDeliveryTypes = (callback) => {
  const { data, status, error, isError, isLoading, isSuccess, refetch, postData, metaData } = useAxiosPost({
    url: 'addressManager/getDeliveryTypes',
    method: 'POST',
    dispatchError: false
  });

  useEffect(() => {
    if (data)
      if (callback) callback(false);
  }, [data]);

  useEffect(() => {
    if (isError) {
      if (callback) callback(error);
    }
  }, [isError]);
  return { data, status, error, isError, isLoading, isSuccess, refetch, metaData, postData };
};

const useGetReturnLogos = (callback) => {
  const { data, status, error, isError, isLoading, isSuccess, refetch, postData, metaData } = useAxiosPost({
    url: 'addressManager/getReturnLogos',
    method: 'POST',
    dispatchError: false
  });

  useEffect(() => {
    if (data)
      if (callback) callback(false);
  }, [data]);

  useEffect(() => {
    if (isError) {
      if (callback) callback(error);
    }
  }, [isError]);
  return { data, status, error, isError, isLoading, isSuccess, refetch, metaData, postData };
};

const useGetReturnStyles = (callback) => {
  const { data, status, error, isError, isLoading, isSuccess, refetch, postData, metaData } = useAxiosPost({
    url: 'addressManager/getReturnStyles',
    method: 'POST',
    dispatchError: false
  });

  useEffect(() => {
    if (data)
      if (callback) callback(false);
  }, [data]);

  useEffect(() => {
    if (isError) {
      if (callback) callback(error);
    }
  }, [isError]);
  return { data, status, error, isError, isLoading, isSuccess, refetch, metaData, postData };
};

const useGetSearchParams = (callback) => {
  const { data, status, error, isError, isLoading, isSuccess, refetch, postData, metaData } = useAxiosPost({
    url: 'addressManager/getSearchParams',
    method: 'POST',
    dispatchError: false
  });

  useEffect(() => {
    if (data)
      if (callback) callback(false);
  }, [data]);

  useEffect(() => {
    if (isError) {
      if (callback) callback(error);
    }
  }, [isError]);
  return { data, status, error, isError, isLoading, isSuccess, refetch, metaData, postData };
};

const useGetSortOrdersDirect = () => {
  const { directPostCall } = useAxiosPost({
    url: 'addressManager/getSortOrders',
    method: 'POST',
    dispatchError: false
  });
  return { directPostCall };
};
const useGetSortOrders = () => {
  const { data, status, error, isError, isLoading, isSuccess, refetch, postData, metaData } = useAxiosPost({
    url: 'addressManager/getSortOrders',
    method: 'POST',
    dispatchError: false
  });
  return { data, status, error, isError, isLoading, isSuccess, refetch, metaData, postData };
};

const useReactivateAddress = (callback) => {
  const { data, status, error, isError, isLoading, isSuccess, refetch, postData, metaData } = useAxiosPost({
    url: 'addressManager/reactivateAddress',
    method: 'POST',
    dispatchError: false
  });

  useEffect(() => {
    if (data)
      if (callback) callback(false);
  }, [data]);

  useEffect(() => {
    if (isError) {
      if (callback) callback(error);
    }
  }, [isError]);
  return { data, status, error, isError, isLoading, isSuccess, refetch, metaData, postData };
};

const useUpdateAddress = (callback) => {
  const { data, status, error, isError, isLoading, isSuccess, refetch, postData, metaData } = useAxiosPost({
    url: 'addressManager/updateAddress',
    method: 'POST',
    dispatchError: false
  });

  useEffect(() => {
    if (data)
      if (callback) callback(false);
  }, [data]);

  useEffect(() => {
    if (isError) {
      if (callback) callback(error);
    }
  }, [isError]);
  return { data, status, error, isError, isLoading, isSuccess, refetch, metaData, postData };
};

export {
  useAddAddress, useDeactivateAddress, useGetAddress, useGetAddresses, useGetAddressNames, useGetAlternateAddresses, useGetDeliveryTypes,
  useGetReturnLogos, useGetReturnStyles, useGetSearchParams, useGetSortOrdersDirect, useGetSortOrders, useReactivateAddress, useUpdateAddress
};