import React from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';

const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 10px;
  font-size: 13px;
`;

const ErrorDisplaySection=()=>{
  const { faultcode, faultstring, faultactor, returnCode,errors } = useSelector(({ errormessages }) => errormessages);

  return(
    <>
      {(faultcode && faultstring && faultactor && returnCode)?(
        <div className="marginLeft" style={{color:'red'}}>
          <ErrorWrapper>
            <label> Fault Code : </label>
            {faultcode}
          </ErrorWrapper>
          <ErrorWrapper>
            <label> Fault String : </label>
            {faultstring}
          </ErrorWrapper>
          <ErrorWrapper>
            <label> Fault Actor : </label>
            {faultactor}
          </ErrorWrapper>
          <ErrorWrapper>
            <label> Error Code : </label>
            {returnCode}
          </ErrorWrapper>
        </div>
      ):null}
      <br/>
      {errors && errors.length>0?(
        <div className="marginLeft" style={{color:'red', fontSize: '13px'}}>
          <h3>There were errors processing your request:</h3>
          <ul>
            {errors.map((error)=>(
              <li>{error}</li>
            ))}
          </ul>
        </div>
      ):null}
    </>
  )
}

export default ErrorDisplaySection;
