export default [
  {
    id: '0004L',
    name: 'PAR_0004L Non MEOB Rule',
    status: ['L', 'T'],
    activeGroup: '',
    description: 'PAR_0004L Non MEOB Rule',
    userId: 'issue 211146',
    dateModified: Date.now() - 100000000000,
  },
  {
    id: '00686',
    name: 'PAR_95818',
    status: ['T'],
    activeGroup: '',
    description: 'PAR_95818',
    userId: '',
    dateModified: Date.now() - 100000,
  },
  {
    id: '3',
    name: 'PAR_3',
    status: ['L'],
    activeGroup: [
      'DADA',
      'HFCE',
      'IME',
      'GME',
      'NMo',
      'PPP',
      'PPK',
      'PPQ',
      'NKNK',
      'ttt',
      'RRR',
      'PPQ',
      'NKNK',
      'ttt',
      'RRR',
      'PPPQ',
      'NKNK',
      'ttt',
      'RRR',
      'PPQ',
      'NKNK',
      'ttt',
      'RRR',
    ],
    description:
      'Ibsum factum lorem Unumus Ibsum factum lorem Unumus Ibsum factum lorem Unumus Ibsum factum lorem Unumus Ibsum factum lorem Unumus',
    userId: 'Ewing',
    dateModified: Date.now() - 1000000000,
  },
  {
    id: '4',
    name: 'PAR_4',
    status: ['L', 'T'],
    activeGroup: '',
    description: 'Ibsum factum lorem Unumus',
    userId: 'Frauen',
    dateModified: Date.now() - 10000000000,
  },
];
