import React from 'react';
import { Portal } from 'react-portal';
import { NotificationBanner } from 'ui-core-ssgr';
import PropTypes from 'prop-types';
import AdvancedHeader from '../AdvancedHeader';
import { AdvancedPane } from './styles';
import EditTestTab from '../AdvancedTabs/EditTestTab/index';
import AssociateTab from '../AdvancedTabs/AssociateTab/index';
import LiveRuleTab from '../AdvancedTabs/LiveRuleTab/index';
import DetailCloneRuleModal from '../../../RulesTabView/components/DetailCloneRuleModal';
import RevertRuleModal from '../../../RuleDetailModals/RevertRuleModal';

const AdvancedView = props => {
  const {
    isEditingRuleDetail,
    isReadOnlyRuleDetail,
    resetRuleDetailElements,
    ruleTypesList,
    toggleDetailsView,
    selectedRuleRecord,
    selectedRecord,
    gridConfig,
    records,
    selectedRuleTypeId,
    setIsViewingRuleDetails,
    selectedRuleRecordStatus,
    advancedGroupsDetailsTabIndex,
    updateCurrentTab,
    setSelectedRuleDetail,
    triggerRulesForRuleTypesPutRequest,
    notifications,
    deleteNotification,
    deleteAllNotifications,
    rulesForRuleTypesList,
    overwriteTestRuleRequestTrigger,
    setIsShowingOverwriteModal,
    isShowingOverwriteModal,
    loading,
    setRuleTypeCommitModalOpen,
    ruleTypeCommitRuleModalOpen,
    triggerCommitRuleForRuleType,
    setIsShowingDetailCloneRuleModal,
    setIsCloningRuleState,
    showDetailCloneRuleModal,
    isCloningRule,
    resetDetailsPane,
    addNotification,
    isExpanded,
    setAdvancedGroupDetailsTab,
    setDetailsPaneToInitialStateForClone,
    triggerRuleDetailsRequest,
    activeGroupsModalLocation,
    selectedActiveRecordsList,
    selectedRecordName,
    setIsShowingActiveGroupsListingModal,
    setIsReadOnlyRuleDetail,
    setIsShowingCustomerServiceMessageModal,
    setIsShowingCheckTextRuleModal,
    setIsShowingErisaMessageModal,
    setIsShowingSignatureRuleModal,
    setIsShowingImageRuleModal,
    setIsShowingReturnAddressModal,
    setIsShowingProductionArrangementModal,
    setIsShowingTaxIdArrangementModal,
    setShowingHighDollarReviewModal,
    setIsShowingMemberIdModal,
    setIsShowingPDFTaggingModal,
    captureCurrentPage,
    getRevertRuleVersions,
    revertRuleVersionsList,
    setIsShowingRevertRuleModal,
    isShowingRevertRuleModal,
    postRevertRuleVersion,
    setIsShowingGeneralMessageModal,
    setIsShowingProviderInsuredOptionMessageModal,
    setIsShowingAutoHoldModal,
    setIsShowingDuplexRuleModal,
    userType,
    rulesForRuleTypes,
    userRoleId
  } = props;

  const onNotificationDismiss = id => {
    deleteNotification(id);
  };
  const { status } = selectedRuleRecord;
  const statusDisabled = !!(status.length === 1 && status[0] === 'L');

  return (
    <AdvancedPane>
      {selectedRuleRecord && (
        <div>
          <AdvancedHeader
            isEditingRuleDetail={isEditingRuleDetail}
            selectedRuleRecord={selectedRuleRecord}
            selectedRuleRecordStatus={selectedRuleRecordStatus}
            resetRuleDetailElements={resetRuleDetailElements}
            ruleTypesList={ruleTypesList}
            rulesForRuleTypesList={rulesForRuleTypesList}
            toggleDetailsView={toggleDetailsView}
            currentTab={advancedGroupsDetailsTabIndex}
            updateCurrentTab={updateCurrentTab}
            selectedRecord={selectedRecord}
            selectedRuleTypeId={selectedRuleTypeId}
            setIsViewingRuleDetails={setIsViewingRuleDetails}
            setIsShowingDetailCloneRuleModal={setIsShowingDetailCloneRuleModal}
            setIsCloningRuleState={setIsCloningRuleState}
            showDetailCloneRuleModal={showDetailCloneRuleModal}
            isCloningRule={isCloningRule}
            resetDetailsPane={resetDetailsPane}
            setAdvancedGroupDetailsTab={setAdvancedGroupDetailsTab}
            notifications={notifications}
            addNotification={addNotification}
            isExpanded={isExpanded}
            deleteNotification={deleteNotification}
            setDetailsPaneToInitialStateForClone={
              setDetailsPaneToInitialStateForClone
            }
            captureCurrentPage={captureCurrentPage}
            getRevertRuleVersions={getRevertRuleVersions}
            setIsShowingRevertRuleModal={setIsShowingRevertRuleModal}
            userType={userType}
            userRoleId={userRoleId}
          />
          <div>
            {showDetailCloneRuleModal && (
              <DetailCloneRuleModal
                addNotification={addNotification}
                selectedRecord={selectedRuleRecord}
                setIsShowingDetailCloneRuleModal={
                  setIsShowingDetailCloneRuleModal
                }
                setIsCloningRuleState={setIsCloningRuleState}
                showDetailCloneRuleModal={showDetailCloneRuleModal}
                isCloningRule={isCloningRule}
                toggleDetailsView={toggleDetailsView}
                resetDetailsPane={setDetailsPaneToInitialStateForClone}
                isExpanded={isExpanded}
                updateCurrentTab={updateCurrentTab}
                triggerRuleDetailsRequest={triggerRuleDetailsRequest}
                selectedRuleTypeId={selectedRuleTypeId}
              />
            )}
            {isShowingRevertRuleModal && (
              <RevertRuleModal
                loading={loading}
                selectedRuleRecord={selectedRuleRecord}
                setIsShowingRevertRuleModal={setIsShowingRevertRuleModal}
                isShowingRevertRuleModal={isShowingRevertRuleModal}
                revertRuleVersionsList={revertRuleVersionsList}
                postRevertRuleVersion={postRevertRuleVersion}
                selectedRuleTypeId={selectedRuleTypeId}
              />
            )}
            {notifications.size > 0 && (
              <Portal key="advanced-view">
                {notifications
                  ? [...notifications].map(([key, value]) => (
                    <NotificationBanner
                      key={[key]}
                      domID={`${key}-notification`}
                      type={value.type}
                      text={value.msg}
                      onDismiss={() => onNotificationDismiss(key)}
                      autoDismiss
                      timer={1800000}
                      icon={value.type === 'neutral' ? 'Info' : null}
                    />
                  ))
                  : null}
              </Portal>
            )}
            {
              [
                <EditTestTab
                  {...props}
                  selectedRuleRecord={selectedRuleRecord}
                  selectedRuleRecordStatus={selectedRuleRecordStatus}
                  setSelectedRuleDetail={setSelectedRuleDetail}
                  currentTab={advancedGroupsDetailsTabIndex}
                  updateCurrentTab={updateCurrentTab}
                  notifications={notifications}
                  loading={loading}
                  deleteNotification={deleteNotification}
                  deleteAllNotifications={deleteAllNotifications}
                  setRuleTypeCommitModalOpen={setRuleTypeCommitModalOpen}
                  ruleTypeCommitRuleModalOpen={ruleTypeCommitRuleModalOpen}
                  triggerCommitRuleForRuleType={triggerCommitRuleForRuleType}
                  selectedRecord={selectedRecord}
                  isCloningRule={isCloningRule}
                  setIsCloningRuleState={setIsCloningRuleState}
                  setIsShowingDetailCloneRuleModal={
                    setIsShowingDetailCloneRuleModal
                  }
                  selectedRuleTypeId={selectedRuleTypeId}
                  activeGroupsModalLocation={activeGroupsModalLocation}
                  selectedActiveRecordsList={selectedActiveRecordsList}
                  selectedRecordName={selectedRecordName}
                  setIsShowingActiveGroupsListingModal={setIsShowingActiveGroupsListingModal}
                  setIsReadOnlyRuleDetail={setIsReadOnlyRuleDetail}
                  captureCurrentPage={captureCurrentPage}
                />,
                <LiveRuleTab
                  key={selectedRecord.id}
                  triggerRuleDetailsRequest={triggerRuleDetailsRequest}
                  selectedRuleTypeId={selectedRuleTypeId}
                  gridConfig={gridConfig}
                  records={records}
                  selectedRuleRecord={selectedRuleRecord}
                  selectedRuleRecordStatus={selectedRuleRecordStatus}
                  setSelectedRuleDetail={setSelectedRuleDetail}
                  toggleDetailsView={toggleDetailsView}
                  triggerRulesForRuleTypesPutRequest={
                    triggerRulesForRuleTypesPutRequest
                  }
                  currentTab={advancedGroupsDetailsTabIndex}
                  updateCurrentTab={updateCurrentTab}
                  notifications={notifications}
                  deleteNotification={deleteNotification}
                  deleteAllNotifications={deleteAllNotifications}
                  overwriteTestRuleRequestTrigger={
                    overwriteTestRuleRequestTrigger
                  }
                  setIsShowingOverwriteModal={setIsShowingOverwriteModal}
                  isShowingOverwriteModal={isShowingOverwriteModal}
                  loading={loading}
                  statusDisabled={statusDisabled}
                  isReadOnlyRuleDetail={isReadOnlyRuleDetail}
                  setIsReadOnlyRuleDetail={setIsReadOnlyRuleDetail}
                  setIsShowingCustomerServiceMessageModal={setIsShowingCustomerServiceMessageModal}
                  setIsShowingCheckTextRuleModal={setIsShowingCheckTextRuleModal}
                  setIsShowingErisaMessageModal={setIsShowingErisaMessageModal}
                  setIsShowingSignatureRuleModal={setIsShowingSignatureRuleModal}
                  setIsShowingImageRuleModal={setIsShowingImageRuleModal}
                  setIsShowingReturnAddressModal={setIsShowingReturnAddressModal}
                  setIsShowingProductionArrangementModal={setIsShowingProductionArrangementModal}
                  setIsShowingTaxIdArrangementModal={setIsShowingTaxIdArrangementModal}
                  setShowingHighDollarReviewModal={setShowingHighDollarReviewModal}
                  setIsShowingMemberIdModal={setIsShowingMemberIdModal}
                  setIsShowingPDFTaggingModal={setIsShowingPDFTaggingModal}
                  captureCurrentPage={captureCurrentPage}
                  setIsShowingGeneralMessageModal={setIsShowingGeneralMessageModal}
                  setIsShowingProviderInsuredOptionMessageModal={setIsShowingProviderInsuredOptionMessageModal}
                  setIsShowingAutoHoldModal={setIsShowingAutoHoldModal}
                  setIsShowingDuplexRuleModal={setIsShowingDuplexRuleModal}
                  rulesForRuleTypes={rulesForRuleTypes}
                />,
                <AssociateTab
                  notifications={notifications}
                  deleteNotification={deleteNotification}
                  deleteAllNotifications={deleteAllNotifications}
                  loading={loading}
                  selectedRecord={selectedRuleRecord}
                  selectedRuleTypeId={selectedRuleTypeId}
                  captureCurrentPage={captureCurrentPage}
                />,
              ][advancedGroupsDetailsTabIndex]
            }
          </div>
        </div>
      )}
    </AdvancedPane>
  );
};

AdvancedView.defaultProps = {
  selectedRuleRecord: null,
  selectedRecord: null,
  advancedGroupsDetailsTabIndex: 0,
};

AdvancedView.propTypes = {
  notifications: PropTypes.object,
  setSelectedRuleDetail: PropTypes.func,
  triggerRulesForRuleTypesPutRequest: PropTypes.func,
  resetRuleDetailElements: PropTypes.func,
  toggleDetailsView: PropTypes.func,
  setIsViewingRuleDetails: PropTypes.func.isRequired,
  updateCurrentTab: PropTypes.func.isRequired,
  selectedRuleRecord: PropTypes.shape({
    id: PropTypes.string,
  }),
  selectedRecord: PropTypes.shape({
    id: PropTypes.string,
  }),
  advancedGroupsDetailsTabIndex: PropTypes.number,
  selectedRuleRecordStatus: PropTypes.array,
  deleteNotification: PropTypes.func,
  deleteAllNotifications: PropTypes.func,
  rulesForRuleTypesList: PropTypes.array,
  overwriteTestRuleRequestTrigger: PropTypes.func,
  setIsShowingOverwriteModal: PropTypes.func,
  isShowingOverwriteModal: PropTypes.bool,
  loading: PropTypes.object,
  setRuleTypeCommitModalOpen: PropTypes.func,
  ruleTypeCommitRuleModalOpen: PropTypes.bool,
  triggerCommitRuleForRuleType: PropTypes.func,
  setIsShowingDetailCloneRuleModal: PropTypes.func,
  setIsCloningRuleState: PropTypes.func,
  showDetailCloneRuleModal: PropTypes.bool,
  isCloningRule: PropTypes.bool,
  resetDetailsPane: PropTypes.func,
  addNotification: PropTypes.func,
  isExpanded: PropTypes.bool,
  setAdvancedGroupDetailsTab: PropTypes.func,
  setDetailsPaneToInitialStateForClone: PropTypes.func,
  triggerRuleDetailsRequest: PropTypes.func,
  isEditingRuleDetail: PropTypes.bool,
  ruleTypesList: PropTypes.array,
  gridConfig: PropTypes.object,
  records: PropTypes.array,
  selectedRuleTypeId: PropTypes.string,
  activeGroupsModalLocation: PropTypes.string,
  selectedActiveRecordsList: PropTypes.object,
  selectedRecordName: PropTypes.string,
  setIsShowingActiveGroupsListingModal: PropTypes.func,
  captureCurrentPage: PropTypes.func,
  userType: PropTypes.string
};

export default AdvancedView;
