const initialState = {
  selectedRecords:[],
  addNoteText:'',
  notesModal: false,
  resolutionModal: false,
  detailsInfoModal: false,
  id: undefined,
  detailcuid: undefined,
  isAll: false,
  errorListRefetch:null
}
  
export default (state = initialState, { type, payload }) => {
  switch(type) {
    case 'SET_ERROR_LIST_REFETCH':
      return {
        ...state,
        errorListRefetch:payload.fetchFunc
      };
    case "SET_ERROR_IDS":
      return {
        ...state,
        selectedRecords: [...state.selectedRecords, ...payload]
      }
    case "RESET_ERROR_IDS":
      return {
        ...state,
        selectedRecords: []
      }
    case 'SET_DETAILS_ID': {
      return {
        ...state,
        id: payload
      }
    }
    case 'SET_DETAILS_CUID': {
      return {
        ...state,
        detailcuid: payload
      }
    }
    case 'SET_NOTES_MODAL': {
      return {
        ...state,
        notesModal: payload
      }
    }
    case 'SET_RESOLUTION_MODAL': {
      return {
        ...state,
        resolutionModal: payload
      }
    }
    case 'SET_DETAILS_MODAL': {
      return {
        ...state,
        detailsInfoModal: payload
      }
    }
    case 'SET_IS_ALL': {
      return {
        ...state,
        isAll: payload
      }
    }
    
    default:
      return state;
  }
}