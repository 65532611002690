import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import { Grid, Modal, LoadingIndicator } from 'ui-core-ssgr';
import { ScrollableGridWrapper } from './styles';
import {
  LoadingContainer,
  GridLoadingWrapper,
  StyledDetailHeader
} from '../../../../../shared/styles/styledComponents';
import RuleDetailsGridHeader from '../RuleDetailsGridHeader';
import ClickableRow from '../ClickableRow';
import RuleDetailsModal from '../../../../RuleDetailModals';

class RuleDetailsGridWrapper extends Component {

  state = {
    selectedItemIds: new Set([]),
    ruleVersion: '',
  };

  componentDidMount = () =>{
    const {
      getRuleDetails,
      isPostingRulesForRuleTypes,
      isCloningRule,
      selectedRuleTypeId,
      selectedRecord,
      selectedRuleRecord,
      rulesForRuleTypes
    } = this.props;
    const record = selectedRecord || selectedRuleRecord;
    if (!isPostingRulesForRuleTypes && !isCloningRule) {
      let ruleVersion = this.getRuleVersion(record);
      
      // workes only in case of revert rule put request
      if (
        !isEmpty(rulesForRuleTypes.ruleVersionRequest) &&
      rulesForRuleTypes.ruleVersionRequest === 'success' &&
      rulesForRuleTypes.ruleVersion !== ruleVersion && 
      rulesForRuleTypes.ruleVersion !== undefined
      ) {
        ruleVersion = rulesForRuleTypes.ruleVersion;
      }

      getRuleDetails({
        ruleType: selectedRuleTypeId,
        ruleId: record.id,
        ruleVersion,
      });
    }
  }

  componentDidUpdate = prevProps => {
    const {
      getRuleDetails,
      selectedRecord,
      selectedRuleTypeId,
      selectedRuleRecord,
      isCloningRule,
      records,
      rulesForRuleTypes
    } = this.props;
    const record = selectedRecord || selectedRuleRecord;
    const ruleVersion = this.getRuleVersion(record);

    // workes only in case of revert rule put request
    if(
      !isEmpty(prevProps.rulesForRuleTypes.ruleVersionRequest) && 
      !isEmpty(rulesForRuleTypes.ruleVersionRequest) && 
      prevProps.rulesForRuleTypes.ruleVersionRequest !== rulesForRuleTypes.ruleVersionRequest &&
      rulesForRuleTypes.ruleVersion !== undefined
    ){
      getRuleDetails({
        ruleType: selectedRuleTypeId,
        ruleId: record.id,
        ruleVersion: rulesForRuleTypes.ruleVersion
      });
    }

    if (!prevProps.isCloningRule && isCloningRule) {
      getRuleDetails({
        ruleType: selectedRuleTypeId,
        ruleId: record.id,
        ruleVersion,
      });
    }
    if (prevProps.records.length !== records.length) {
      this.setState({ selectedItemIds: new Set([]) });
    }
  }

  getHeaderName = () => {
    const {
      isCloningRuleDetail,
      isEditingRuleDetail,
      isPostingRuleDetail,
    } = this.props;
    let heading = '';
    if (isEditingRuleDetail) heading = 'Edit a';
    if (isPostingRuleDetail) heading = 'Add a';
    if (isCloningRuleDetail) heading = 'Clone a';
    return heading;
  };

  // want test unless cloning (Edit Test Tab)
  getRuleVersion = record => {
    const { isCloningRule } = this.props;
    try {
      const { status, version } = record;
      const testVersion = version[status.indexOf('T')];
      const liveVersion = version[status.indexOf('L')];
      if (!isCloningRule && testVersion) {
        return testVersion;
      }
      if (isCloningRule && liveVersion) {
        return liveVersion;
      }
      if (!isCloningRule && liveVersion) {
        return liveVersion;
      }
      if (isCloningRule && testVersion) {
        return testVersion;
      }
    } catch (e) {
      return '';
    }
    // if no test/live version or any error return null
    return null;
  };

  onCancel = () => {
    const {
      setIsShowingProductionArrangementModal,
      setIsShowingCustomerServiceMessageModal,
      setIsShowingReturnAddressModal,
      setIsCloningRuleDetail,
      setIsPostingRuleDetail,
      setIsEditingRuleDetail,
      setIsReadOnlyRuleDetail,
      setIsShowingDuplexRuleModal,
      setIsShowingSignatureRuleModal,
      setIsShowingCheckTextRuleModal,
      setIsShowingImageRuleModal,
      setIsShowingAutoHoldModal,
      setIsShowingMemberIdModal,
      setIsShowingTaxIdArrangementRuleModal,
      setIsShowingErisaMessageModal,
      setShowingHighDollarReviewModal,
      setIsShowingProviderInsuredOptionMessageModal,
      setIsShowingGeneralMessageModal,
	    setIsShowingPDFTaggingModal
    } = this.props;

    setIsShowingProductionArrangementModal(false);
    setIsShowingCustomerServiceMessageModal(false);
    setIsShowingReturnAddressModal(false);
    setIsShowingDuplexRuleModal(false);
    setIsShowingSignatureRuleModal(false);
    setIsShowingCheckTextRuleModal(false);
    setIsShowingImageRuleModal(false);
    setIsShowingAutoHoldModal(false);
    setIsShowingMemberIdModal(false);
    setIsShowingTaxIdArrangementRuleModal(false);
    setIsShowingErisaMessageModal(false);
    setShowingHighDollarReviewModal(false);
    setIsShowingProviderInsuredOptionMessageModal(false);
    setIsShowingGeneralMessageModal(false);
    setIsShowingPDFTaggingModal(false);
    setIsCloningRuleDetail(false);
    setIsPostingRuleDetail(false);
    setIsEditingRuleDetail(false);
    setIsReadOnlyRuleDetail(false);
  };

  onGridSelectAll = (_, selectedItems) => {
    const { selectedItemIds } = this.state;
    const { setSelectedRuleDetail, records } = this.props;
    const onlyOneRule = selectedItems.selectedItemIds.size === 1;
    const alreadyFull = selectedItemIds.size === records.length;
    const ids = alreadyFull ? [] : records.map(({ id }) => id);

    if (alreadyFull) {
      setSelectedRuleDetail({});
    } else if (onlyOneRule) {
      const selectedItemValue = selectedItems.selectedItemIds.values().next().value;
      const selectedRecord = records.find(record => record.id === selectedItemValue);
      setSelectedRuleDetail(selectedRecord);
    }

    this.setState({ selectedItemIds: new Set(ids) });
  };

  onRowClickThrough = (_, record) => {
    const {
      isCloningRuleDetail,
      isPostingRuleDetail,
      selectedRuleTypeId,
      setIsCloningRuleDetail,
      setIsEditingRuleDetail,
      setIsPostingRuleDetail,
      setIsShowingProductionArrangementModal,
      setIsShowingCustomerServiceMessageModal,
      setIsShowingReturnAddressModal,
      setIsShowingDuplexRuleModal,
      setIsShowingSignatureRuleModal,
      setIsShowingCheckTextRuleModal,
      setIsShowingImageRuleModal,
      setIsShowingAutoHoldModal,
      setIsShowingMemberIdModal,
      setIsShowingTaxIdArrangementRuleModal,
      setIsShowingErisaMessageModal,
      setShowingHighDollarReviewModal,
      setIsShowingProviderInsuredOptionMessageModal,
      setIsShowingGeneralMessageModal,
      setIsShowingPDFTaggingModal,
      setSelectedRuleDetail,
      detailElements,
      addNotification,
      errorNotifications,
      getAddNewAddressElements, 
      isCloningRule
    } = this.props;
    if(!isCloningRule){
      if(selectedRuleTypeId === 'RAD') {
        getAddNewAddressElements({selectedRuleTypeId});
      }
      setSelectedRuleDetail(record);
      if (isCloningRuleDetail) setIsCloningRuleDetail(false);
      if (isPostingRuleDetail) setIsPostingRuleDetail(false);
      // adde following condition added to nullify selectedItemIds for issue fix: MCDS-14519
      if (!isPostingRuleDetail && !isCloningRuleDetail) {
        this.setState({ selectedItemIds: new Set([]) });
      }
      if (Object.entries(detailElements).length > 0) {
        setIsEditingRuleDetail(true);
        if (selectedRuleTypeId === 'PAR') {
          setIsShowingProductionArrangementModal(true);
        }
        if (selectedRuleTypeId === 'CSM') {
          setIsShowingCustomerServiceMessageModal(true);
        }
        if (selectedRuleTypeId === 'RAD') {
          setIsShowingReturnAddressModal(true);
        }
        if (selectedRuleTypeId === 'DPX') {
          setIsShowingDuplexRuleModal(true);
        }
        if (selectedRuleTypeId === 'SIG') {
          setIsShowingSignatureRuleModal(true);
        }
        if (selectedRuleTypeId === 'CHT') {
          setIsShowingCheckTextRuleModal(true);
        }
        if (selectedRuleTypeId === 'IMG') {
          setIsShowingImageRuleModal(true);
        }
        if (selectedRuleTypeId === 'HLD') {
          setIsShowingAutoHoldModal(true);
        }
        if (selectedRuleTypeId === 'SSN') {
          setIsShowingMemberIdModal(true);
        }
        if (selectedRuleTypeId === 'TAX') {
          setIsShowingTaxIdArrangementRuleModal(true);
        }
        if (selectedRuleTypeId === 'ERI') {
          setIsShowingErisaMessageModal(true);
        }
        if (selectedRuleTypeId === 'HDR') {
          setShowingHighDollarReviewModal(true);
        }
        if (selectedRuleTypeId === 'PIO') {
          setIsShowingProviderInsuredOptionMessageModal(true);
        }
        if (selectedRuleTypeId === 'GEN') {
          setIsShowingGeneralMessageModal(true);
        }
        if (selectedRuleTypeId === 'PDT') {
          setIsShowingPDFTaggingModal(true);
        }
      } else if (errorNotifications) {
        errorNotifications.forEach(element => {
          if (element.type === 'ruleDetailElements') {
            addNotification({
              id: selectedRuleTypeId,
              type: 'negative',
              msg: element.msg,
            });
          }
        });
      }
    }
  };

  onRowSelect = record => {
    const { selectedItemIds } = this.state;
    const { setSelectedRuleDetail } = this.props;
    const isBeingSelected = !selectedItemIds.has(record.id);
    const method = isBeingSelected ? 'add' : 'delete';
    selectedItemIds[method](record.id);
    if (isBeingSelected) setSelectedRuleDetail(record);
    this.setState({
      selectedItemIds,
    });
  };

  toFixedTrunc = (x, n) => {
    const v = (typeof x === 'string' ? x : x.toString()).split('.');
    if (n <= 0) return v[0];
    let f = v[1] || '';
    if (f.length > n) return `${v[0]}.${f.substr(0, n)}`;
    while (f.length < n) f += '0';
    let inputValue = `${v[0]}.${f}`;
    inputValue = inputValue
      .replace(/,/g, '')
      .split('')
      .reverse()
      .join('')
      .replace(/(\d{3})/g, '$1,')
      .replace(/,$/, '')
      .split('')
      .reverse()
      .join('');
    return inputValue;
  }

  constructPageList = record => {
    const { selectedRuleTypeId } = this.props;
    for (let j = 0; j < record.length; j += 1) {
      const column = record[j];
      let selectedColumnLabel = '';
      let toNumber = null;
      if (
        column.signatureThreshold !== null &&
        column.signatureThreshold !== undefined &&
        selectedRuleTypeId === 'SIG'
      ) {
        selectedColumnLabel = 'signatureThresholdLabel';
        toNumber =
          typeof column.signatureThreshold === 'string'
            ? column.signatureThreshold
            : String(column.signatureThreshold);
        toNumber = this.toFixedTrunc(toNumber, 2);
      }
      if (
        column.highDollarThreshold !== null &&
        column.highDollarThreshold !== undefined &&
        selectedRuleTypeId === 'HDR'
      ) {
        selectedColumnLabel = 'highDollarThresholdLabel';
        toNumber = this.toFixedTrunc(String(column.highDollarThreshold), 2);
      }
      column[selectedColumnLabel] = `$${toNumber}`;
    }

    return map(record, r =>
      r.message
        ? {
          ...r,
          message: r.message.replace(/\r?\\n|\r/g, '\n'),
        }
        : r,
    );
  };

  renderModalContent = () => {
    const {
      isShowingProductionArrangementModal,
      isShowingCustomerServiceMessageModal,
      isShowingReturnAddressModal,
      isShowingDuplexRuleModal,
      isShowingSignatureRuleModal,
      isShowingCheckTextRuleModal,
      isShowingImageRuleModal,
      isShowingAutoHoldModal,
      isShowingMemberIdModal,
      isShowingTaxIdArrangementRuleModal,
      isShowingErisaMessageModal,
      isShowingHighDollarReviewModal,
      isShowingProviderInsuredOptionMessageModal,
      isShowingGeneralMessageModal,
      isShowingPDFTaggingModal,
      isShowingAddNewAddressModal,
      isShowingImageLibraryModal,
      isShowingImageUploadModal,
    } = this.props;

    return isShowingProductionArrangementModal ||
    isShowingCustomerServiceMessageModal ||
    isShowingReturnAddressModal ||
    isShowingDuplexRuleModal ||
    isShowingSignatureRuleModal ||
    isShowingCheckTextRuleModal ||
    isShowingImageRuleModal ||
    isShowingAutoHoldModal ||
    isShowingMemberIdModal ||
    isShowingTaxIdArrangementRuleModal ||
    isShowingErisaMessageModal ||
    isShowingHighDollarReviewModal ||
    isShowingProviderInsuredOptionMessageModal ||
    isShowingGeneralMessageModal ||
    isShowingPDFTaggingModal ||
    isShowingAddNewAddressModal ||
    isShowingImageLibraryModal ||
    isShowingImageUploadModal
  };

  render() {
    const {
      records,
      gridConfig,
      isPostingRulesForRuleTypes,
      setIsShowingDeleteRuleDetailModal,
      isShowingDeleteRuleDetailModal,
      setSelectedDetailsRecordId,
      deleteRuleDetailTrigger,
      selectedRecord,
      loading,
      detailElements,
      isPostingRuleDetail,
      isShowingProductionArrangementModal,
      isShowingCustomerServiceMessageModal,
      selectedRuleRecord,
      selectedRuleTypeId,
      setIsCloningRuleDetail,
      setIsShowingProductionArrangementModal,
      setIsShowingCustomerServiceMessageModal,
      setIsShowingReturnAddressModal,
      setIsShowingDuplexRuleModal,
      setIsShowingSignatureRuleModal,
      setIsShowingCheckTextRuleModal,
      setIsShowingImageRuleModal,
      setIsShowingAutoHoldModal,
      setIsShowingMemberIdModal,
      setIsShowingTaxIdArrangementRuleModal,
      setIsShowingErisaMessageModal,
      setShowingHighDollarReviewModal,
      setIsShowingProviderInsuredOptionMessageModal,
      setIsShowingGeneralMessageModal,
      setIsShowingPDFTaggingModal,
      setIsPostingRuleDetail,
      setIsReadOnlyRuleDetail,
      setSelectedRuleDetail,
      addNotification,
      errorNotifications,
      getAddNewAddressElements,
      isCloningRule
    } = this.props;
    const { selectedItemIds } = this.state;
    const {
      onGridSelectAll,
      onRowClickThrough,
      onRowSelect,
      renderModalContent,
    } = this;
    const actionTypeText = this.getHeaderName();

    return (
      <>
        <RuleDetailsGridHeader
          headerName="Details"
          detailElements={detailElements}
          setSelectedRuleDetail={setSelectedRuleDetail}
          recordCount={records.length}
          selectedItemIds={selectedItemIds}
          isPostingRulesForRuleTypes={isPostingRulesForRuleTypes}
          isPostingRuleDetail={isPostingRuleDetail}
          isShowingProductionArrangementModal={
            isShowingProductionArrangementModal
          }
          isShowingCustomerServiceMessageModal={
            isShowingCustomerServiceMessageModal
          }
          selectedRuleTypeId={selectedRuleTypeId}
          selectedRuleRecord={selectedRuleRecord}
          setIsCloningRuleDetail={setIsCloningRuleDetail}
          setIsPostingRuleDetail={setIsPostingRuleDetail}
          setIsReadOnlyRuleDetail={setIsReadOnlyRuleDetail}
          setIsShowingDeleteRuleDetailModal={setIsShowingDeleteRuleDetailModal}
          setIsShowingProductionArrangementModal={
            setIsShowingProductionArrangementModal
          }
          setIsShowingCustomerServiceMessageModal={
            setIsShowingCustomerServiceMessageModal
          }
          setIsShowingReturnAddressModal={setIsShowingReturnAddressModal}
          setIsShowingDuplexRuleModal={setIsShowingDuplexRuleModal}
          setIsShowingSignatureRuleModal={setIsShowingSignatureRuleModal}
          setIsShowingCheckTextRuleModal={setIsShowingCheckTextRuleModal}
          setIsShowingImageRuleModal={setIsShowingImageRuleModal}
          setIsShowingAutoHoldModal={setIsShowingAutoHoldModal}
          setIsShowingMemberIdModal={setIsShowingMemberIdModal}
          setIsShowingTaxIdArrangementRuleModal={
            setIsShowingTaxIdArrangementRuleModal
          }
          setIsShowingErisaMessageModal={setIsShowingErisaMessageModal}
          setShowingHighDollarReviewModal={setShowingHighDollarReviewModal}
          setIsShowingProviderInsuredOptionMessageModal={
            setIsShowingProviderInsuredOptionMessageModal
          }
          setIsShowingGeneralMessageModal={setIsShowingGeneralMessageModal}
          setIsShowingPDFTaggingModal={setIsShowingPDFTaggingModal}
          setSelectedDetailsRecordId={setSelectedDetailsRecordId}
          selectedRecord={selectedRecord}
          isShowingDeleteRuleDetailModal={isShowingDeleteRuleDetailModal}
          records={records}
          deleteRuleDetailTrigger={deleteRuleDetailTrigger}
          loading={loading}
          addNotification={addNotification}
          errorNotifications={errorNotifications}
          getAddNewAddressElements={getAddNewAddressElements}
          isCloningRule={isCloningRule}
        />
        <ScrollableGridWrapper>
          {loading.has('ruleDetailsGridLoading') ? (
            <LoadingContainer>
              <GridLoadingWrapper>
                <LoadingIndicator
                  domID="edit-test-grid-loading-indicator"
                  length="45px"
                />
              </GridLoadingWrapper>
            </LoadingContainer>
          ) : (
            <Grid
              {...gridConfig}
              records={
                !isPostingRulesForRuleTypes
                  ? this.constructPageList(records)
                  : []
              }
              supportSelection={!isCloningRule || false}
              selectedItemIds={selectedItemIds}
              initialSelectedItemIds={selectedItemIds}
              rowComponent={ClickableRow}
              onClickThrough={(e, record) => onRowClickThrough(e, record)}
              onRowSelect={onRowSelect}
              onSelectAll={onGridSelectAll}
              isPostingRulesForRuleTypes={isPostingRulesForRuleTypes}
            />
          )}
        </ScrollableGridWrapper>
        {renderModalContent() && (
          <Modal
            isOpen={renderModalContent()}
            onModalToggle={this.onCancel}
          >
            <StyledDetailHeader
              domID="test-id"
              title={`${this.getHeaderName()} Detail for Rule: ${
                selectedRuleRecord
                  ? selectedRuleRecord.name
                  : selectedRecord.name
              }`}
              marginBottom={0}
            />
            <RuleDetailsModal
              {...this.props}
              {...this.state}
              actionType={actionTypeText}
            />
          </Modal>
        )}
      </>
    );
  }
}

RuleDetailsGridWrapper.propTypes = {
  isPostingRulesForRuleTypes: PropTypes.bool,
  records: PropTypes.array.isRequired,
  gridConfig: PropTypes.shape({ columns: PropTypes.shape({}) }).isRequired,
  setIsViewingRuleDetails: PropTypes.func,
  selectedRuleDetail: PropTypes.object,
  selectedRuleRecord: PropTypes.object,
  selectedRuleTypeId: PropTypes.string,
  columns: PropTypes.object,
  headerText: PropTypes.string,
  headerCount: PropTypes.number,
  goBackFromAdvancedToDefaultView: PropTypes.func.isRequired,
  toggleDetailsView: PropTypes.func.isRequired,
  ruleDetails: PropTypes.object,
  selectedRecord: PropTypes.object,
  getRuleDetailElements: PropTypes.func,
  getRuleDetails: PropTypes.func,
  getAddNewAddressElements: PropTypes.func,
  isCloningRule: PropTypes.bool,
  isCloningRuleDetail: PropTypes.bool,
  isEditingRuleDetail: PropTypes.bool,
  isPostingRuleDetail: PropTypes.bool,
  setIsShowingProductionArrangementModal: PropTypes.func,
  setIsShowingCustomerServiceMessageModal: PropTypes.func,
  setIsShowingReturnAddressModal: PropTypes.func,
  setIsCloningRuleDetail: PropTypes.func,
  setIsPostingRuleDetail: PropTypes.func,
  setIsEditingRuleDetail: PropTypes.func,
  setIsShowingDuplexRuleModal: PropTypes.func,
  setIsShowingSignatureRuleModal: PropTypes.func,
  setIsShowingCheckTextRuleModal: PropTypes.func,
  setIsShowingImageRuleModal: PropTypes.func,
  setIsShowingAutoHoldModal: PropTypes.func,
  setIsShowingMemberIdModal: PropTypes.func,
  setIsShowingTaxIdArrangementRuleModal: PropTypes.func,
  setIsShowingErisaMessageModal: PropTypes.func,
  setShowingHighDollarReviewModal: PropTypes.func,
  setIsShowingProviderInsuredOptionMessageModal: PropTypes.func,
  setIsShowingGeneralMessageModal: PropTypes.func,
  setIsShowingPDFTaggingModal: PropTypes.func,
  isShowingProductionArrangementModal: PropTypes.bool,
  isShowingCustomerServiceMessageModal: PropTypes.bool,
  isShowingReturnAddressModal: PropTypes.bool,
  isShowingDuplexRuleModal: PropTypes.bool,
  isShowingSignatureRuleModal: PropTypes.bool,
  isShowingCheckTextRuleModal: PropTypes.bool,
  isShowingImageRuleModal: PropTypes.bool,
  isShowingAutoHoldModal: PropTypes.bool,
  isShowingMemberIdModal: PropTypes.bool,
  isShowingTaxIdArrangementRuleModal: PropTypes.bool,
  isShowingErisaMessageModal: PropTypes.bool,
  isShowingHighDollarReviewModal: PropTypes.bool,
  isShowingProviderInsuredOptionMessageModal: PropTypes.bool,
  isShowingGeneralMessageModal: PropTypes.bool,
  isShowingPDFTaggingModal: PropTypes.bool,
  setSelectedRuleDetail: PropTypes.func,
  detailElements: PropTypes.object,
  addNotification: PropTypes.func,
  errorNotifications: PropTypes.object,
  setIsShowingDeleteRuleDetailModal: PropTypes.func,
  isShowingDeleteRuleDetailModal: PropTypes.bool,
  setSelectedDetailsRecordId: PropTypes.func,
  deleteRuleDetailTrigger: PropTypes.func,
  loading: PropTypes.object,
  postRuleDetails: PropTypes.func,
  putRuleDetails: PropTypes.func,
  setIsShowingAddNewAddressModal: PropTypes.func,
  isShowingAddNewAddressModal: PropTypes.bool,
  returnAddressElements: PropTypes.object,
  postAddNewAddressElements: PropTypes.func,
  setIsShowingImageLibraryModal: PropTypes.func,
  isShowingImageLibraryModal: PropTypes.bool,
  setIsShowingImageUploadModal: PropTypes.func,
  isShowingImageUploadModal: PropTypes.bool,
  postImageUpload: PropTypes.func,
  getRuleImages: PropTypes.func,
  ruleImages: PropTypes.object,
  getRuleImagesCount: PropTypes.func,
  ruleImagesCount: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  resetRuleImagesData: PropTypes.func,
  convertImageUpload: PropTypes.func,
  convertedImageUploadData: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  convertedImageUploadError: PropTypes.string,
  postImageUploadSuccessId: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  postCommitImage: PropTypes.func,
  setIsShowingCommitImageModal: PropTypes.func,
  isShowingCommitImageModal: PropTypes.object,
  postImageCommitSuccessId: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  postCommitImageTriggerCancel: PropTypes.func,
  setIsReadOnlyRuleDetail: PropTypes.func,
  rulesForRuleTypes: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
};

export default RuleDetailsGridWrapper;
