import React from 'react'

const errorContextDefaultValue = {
  getHistoryRef:()=>false,
  handleCustomError: ()=>false
};
    
const ErrorContext = React.createContext(
  errorContextDefaultValue // default value
);
export default ErrorContext