
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import { useEffect, useRef, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux';
import { useAxios, useAxiosPost } from '../hooks'

const useBankList = (props = {}) => {
    const [totalRecordsResp, settotalRecordsResp]=useState(0)
    const [allData, setAllData]=useState([])
    const { data, error, status, postData,isError, isLoading,...rest } = useAxiosPost({
      ...props,
      url: 'achManager/getBankList',
      method: 'POST',
      payload:{}
    })
  
    useEffect(()=>{
      if (data) {
          if(data.banklist && data.banklist.bank){
            if(data.banklist.bank.constructor.name === 'Array')
              setAllData(JSON.parse(JSON.stringify(data.banklist.bank)))
            else 
              setAllData(JSON.parse(JSON.stringify([
                {
                  ...data.banklist.bank
                }])));
          }
        }
    },[data,totalRecordsResp])
    return { allData,totalRecordsResp,isError, error, status, postData,isLoading, ...rest }
  }
  const useFileList = (props = {}) => {
    const [totalRecordsResp, settotalRecordsResp]=useState(0)
    const [allData, setAllData]=useState([])
    const { data, error, status, postData,isError, isLoading,...rest } = useAxiosPost({
      ...props,
      url: 'achManager/getFileList',
      method: 'POST',
      payload:{}
    })
  
    useEffect(()=>{
      if (data && data.response) {

        if (data.response.transmissionlist && data.response.transmissionlist.file) {
          //settotalRecordsResp(data.transmissionlist.file)  
          const sr=JSON.parse(JSON.stringify(data.response.transmissionlist.file))  
          let searchResult=[]
          if (sr.constructor.name==="Array") {
            searchResult=[...sr]
          } else if(sr.constructor.name==="Object"){
            searchResult.push({...sr})
          }
          if (searchResult && searchResult.length>0) {
            setAllData(JSON.parse(JSON.stringify(searchResult)))  
            settotalRecordsResp(data.totalRecords);
          } else{
            setAllData(JSON.parse(JSON.stringify([])))  
          }
        } else{
          settotalRecordsResp(0);
          setAllData(JSON.parse(JSON.stringify([])))
        }
        
        }
    },[data,totalRecordsResp])
    return { allData,totalRecordsResp,isError, error, status, postData,isLoading, ...rest }
  }

  const useFileBatchDetailsByFileID = (setConfig) => {
    const [totalRecordsResp, settotalRecordsResp]=useState(0)
    const configRef=useRef(null);
    const getACHFileDetailsByFileID=({payload, config})=>{
      configRef.current=config;
      postData(payload);
      if (configRef && configRef.current && setConfig) {
        setConfig(JSON.parse(JSON.stringify(configRef.current)))
      }
    }
    const { data, status, error, isError, isLoading, isSuccess, refetch, postData, metaData } = useAxiosPost({
      url: `achManager/getACHFileBatchDetailsByFileID`,
      method: 'POST',
      dispatchError: false
    });
  
    useEffect(()=>{
      if (data) {
        if (configRef && configRef.current && setConfig) {
          setConfig(JSON.parse(JSON.stringify(configRef.current)))
        }
        settotalRecordsResp(data.totalRecords)
      }
    },[data])
  
    return { data, totalRecordsResp, status, error, isError, isLoading, isSuccess, refetchACHFileDetailsByFileID: refetch, getACHFileDetailsByFileID, metaData }
  }


  export {useBankList,useFileList,useFileBatchDetailsByFileID}