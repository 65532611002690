import { select, call, put, takeLatest } from 'redux-saga/effects';
import get from 'lodash/get';
import {
  setIsPostingRulesForRuleTypes,
  rulesForRuleTypesPutSuccess,
  rulesForRuleTypesPutError,
  rulesForRuleTypesPostSuccess,
  rulesForRuleTypesPostError,
  setIsCloningRuleState,
  commitRuleForRuleTypeSuccess,
  commitRuleForRuleTypeError,
  setRuleTypeCommitModalOpen,
  setIsShowingRevertRuleModal,
  getRevertRuleVersionsSuccess,
  getRevertRuleVersionsError,
  postRevertRuleVersionSuccess,
  postRevertRuleVersionError
} from './actions';
import {
  setIsViewingRuleDetails,
  triggerRuleDetailsRequest,
  triggerRuleTypesRequest,
  ruleTypesListReceived
} from '../ruleTypesList/actions';
import {getRuleTypesList} from '../ruleTypesList/network';
import {
  setSelectedRuleRecord,
  triggerAllRulesForRuleTypesRequest,
  updateCurrentTab,
  rulesCountFromDBTrigger,
} from '../rulesForRuleTypesList/actions';
import {
  RULES_FOR_RULETYPES_PUT_REQUEST_TRIGGER,
  RULES_FOR_RULETYPES_POST_REQUEST_TRIGGER,
  TRIGGER_COMMIT_RULE_FOR_RULE_TYPE,
  GET_REVERT_RULE_VERSION_TRIGGER,
  POST_REVERT_RULE_VERSION_TRIGGER
} from './constants';
import { 
  putRulesForRuleTypes,
  postRulesForRuleTypes,
  putRequestToCommitRule,
  getRevertRuleVersionsList,
  putRevertRuleVersion
} from './network';
import { setLoadingState } from '../../app/loadingState/actions';
import { addNotification } from '../../app/notificationState/actions';
import {
  selectCurrentDetailsRecord
} from '../../../containers/GroupDetailView/selectors';
import { getRulesForRuleTypesList } from '../rulesForRuleTypesList/network';
import { setAdvancedGroupDetailsTab } from '../../app/detailsPaneState/actions';

function* commitRuleforRuleTypePutGenerator(action) {
  yield put(
    setLoadingState({
      id: action.payload.id,
      isLoading: true,
    }),
  );
  try {
    const userConfig = yield select(({ serverConfig }) => serverConfig);
    const response = yield call(
      putRequestToCommitRule,
      userConfig,
      action.payload,
    );
    if (action.payload) {
      yield put(
        commitRuleForRuleTypeSuccess({
          response,
        }),
      );
      yield put(updateCurrentTab({ currentTab: 1 }));
      yield put(setRuleTypeCommitModalOpen(false));
      const ruleType = action.payload.selectedRuleTypeId || (action.payload.ruleType
        ? action.payload.ruleType
        : window.location.href.substring(
          window.location.href.lastIndexOf('=') + 1,
        ));
      const ruleId = action.payload.selectedRuleRecord.id;
      yield put(setSelectedRuleRecord({ id: ruleId }));
      if (action.payload.selectedRecord) {
        yield put(
          triggerRuleTypesRequest({
            groupId: action.payload.selectedRecord.id,
          }),
        );
      }
      yield put(setAdvancedGroupDetailsTab(1));
      yield put(
        triggerAllRulesForRuleTypesRequest({
          offset: 0,
          queryValue: '',
          sortOrder: 'desc',
          name: 'dateModified',
          ruleTypeId: ruleType,
        }),
      );
      yield put(
        rulesCountFromDBTrigger({
          offset: 0,
          queryValue: '',
          name: 'dateModified',
          sortOrder: 'desc',
          ruleTypeId: ruleType,
        }),
      );
      const { rules } = yield call(
        getRulesForRuleTypesList,
        userConfig,
        {
          sortOrder: 'desc',
          sortColumn: 'dateModified',
          offset: 0,
          ruleTypeId: ruleType,
          queryValue: '',
        },
        ruleType,
      );
      const isAdvancedViewShown = !window.location.href.includes('ruletype');
      const rule = rules.find(r => r.id === ruleId);
      yield put(
        setIsViewingRuleDetails({
          isAdvancedViewShown,
          selectedRuleRecord: rule,
          selectedRuleTypeId: ruleType,
        }),
      );
      yield put(
        triggerRuleDetailsRequest({
          ruleType,
          ruleId,
          ruleVersion: rule.version,
        }),
      );
      yield put(
        setLoadingState({
          id: action.payload.id,
          isLoading: false,
        }),
      );
      yield put(
        addNotification({
          id: action.payload.id,
          type: 'positive',
          msg: response.success,
        }),
      );
    }
  } catch (thrownError) {
    yield put(setRuleTypeCommitModalOpen(false));
    yield put(
      setLoadingState({
        id: action.payload.id,
        isLoading: false,
      }),
    );
    yield put(
      addNotification({
        id: action.payload.id,
        type: 'negative',
        msg: thrownError.message,
      }),
    );
    yield put(commitRuleForRuleTypeError(thrownError));
  }
}

function* rulesForRuleTypesPutGenerator(action) {
  yield put(
    setLoadingState({
      id: action.payload.id,
      isLoading: true,
    }),
  );
  try {
    const userConfig = yield select(({ serverConfig }) => serverConfig);
    const ruleType = action.payload.ruleType ||
      window.location.href.substring(
        window.location.href.lastIndexOf('=') + 1,
      );
    const response = yield call(
      putRulesForRuleTypes,
      userConfig,
      action.payload,
    );
    const { id } = response;
    if (action.payload) {
      yield put(
        rulesForRuleTypesPutSuccess({
          rule: response,
        }),
      );
      yield put(
        setLoadingState({
          id: action.payload.id,
          isLoading: false,
        }),
      );
      yield put(
        addNotification({
          id: action.payload.id,
          type: 'positive',
          msg: response.success,
        }),
      );
      yield put(setSelectedRuleRecord({ id }));
      yield put(
        triggerAllRulesForRuleTypesRequest({
          offset: 0,
          queryValue: '',
          sortOrder: 'desc',
          name: 'dateModified',
          ruleTypeId: ruleType,
        }),
      );
      const { rules } = yield call(
        getRulesForRuleTypesList,
        userConfig,
        {
          sortOrder: 'desc',
          sortColumn: 'dateModified',
          offset: 0,
          ruleTypeId: ruleType,
          queryValue: '',
        },
        ruleType,
      );
      const rule = rules.find(r => r.id === id);
      yield put(
        setIsViewingRuleDetails({
          isAdvancedViewShown: true,
          selectedRuleRecord: rule,
          selectedRuleTypeId: ruleType,
        }),
      );
      if (!window.location.href.includes('ruletype')) {
        const group = yield select(selectCurrentDetailsRecord);
        yield put(triggerRuleTypesRequest({ groupId: group.id }));
      }
      yield put(
        rulesCountFromDBTrigger({
          offset: 0,
          queryValue: '',
          name: 'dateModified',
          sortOrder: 'desc',
          ruleTypeId: ruleType,
        }),
      );
    }
  } catch (thrownError) {
    yield put(
      addNotification({
        id: thrownError.errorId,
        type: 'negative',
        msg: thrownError.message,
      }),
    );
    yield put(
      rulesForRuleTypesPutError({
        msg: new Error(thrownError),
      }),
    );
    yield put(
      setLoadingState({
        id: action.payload.id,
        isLoading: false,
      }),
    );
    yield put(
      addNotification({
        id: thrownError.errorId,
        type: 'negative',
        msg: thrownError.message,
      }),
    );
    yield put(
      rulesForRuleTypesPutError({
        msg: new Error(thrownError),
      }),
    );
  }
}

// POST generator for creating or cloning a rule
function* rulesForRuleTypesPostGenerator(action) {
  yield put(
    setLoadingState({
      id: action.payload.id,
      isLoading: true,
    }),
  );

  try {
    const userConfig = yield select(({ serverConfig }) => serverConfig);
    const response = yield call(
      postRulesForRuleTypes,
      userConfig,
      action.payload,
    );
    const { clientLayoutId, userSecurityTokenKey, username, roleId, dataSourceId } = yield select(({ serverConfig }) => serverConfig,);
    const ruleType = action.payload.ruleType
      ? action.payload.ruleType
      : window.location.href.substring(
        window.location.href.lastIndexOf('=') + 1,
      );
    if (response && !response.error) {
      if (action.payload) {
        yield put(
          rulesForRuleTypesPostSuccess({
            rule: response,
          }),
        );
        const isAdvancedViewShown = !window.location.href.includes('ruletype');
        yield put(setSelectedRuleRecord({ id: response.id }));
        const { rules } = yield call(
          getRulesForRuleTypesList,
          userConfig,
          {
            sortOrder: 'desc',
            sortColumn: 'dateModified',
            offset: 0,
            ruleTypeId: ruleType,
            queryValue: '',
          },
        );
        const rule = rules.find(r => r.id === response.id);
        yield put(
          setIsViewingRuleDetails({
            isAdvancedViewShown,
            selectedRuleRecord: rule,
            selectedRuleTypeId: ruleType,
          }),
        );
        // start : new code to pull latest record for a rule : helps in new rule case : MCDS-16376
        const ruleVersion = rule.version;
        const ruleId = rule.id;
        yield put(
          triggerRuleDetailsRequest({
            ruleType,
            ruleId,
            ruleVersion,
          }),
        );
        // end
        yield put(setIsPostingRulesForRuleTypes(false));
        yield put(setIsCloningRuleState(false));
        yield put(
          rulesCountFromDBTrigger({
            queryValue: '',
            sortOrder: 'desc',
            name: 'dateModified',
            ruleTypeId: ruleType,
          }),
        );
      }
      const group = yield select(selectCurrentDetailsRecord);
      const groupId = get(group, 'id', '');
      if (groupId) {
        const queryValue = '';
        const ruleTypesArray = yield call(
          getRuleTypesList,
          clientLayoutId,
          userSecurityTokenKey,
          username,
          groupId,
          ruleType,
          queryValue,
          dataSourceId,
          roleId
        );
        yield put(triggerRuleTypesRequest({ groupId }));
        yield put(ruleTypesListReceived(ruleTypesArray, groupId));
      }
      yield put(
        triggerAllRulesForRuleTypesRequest({
          offset: 0,
          queryValue: '',
          sortOrder: 'desc',
          name: 'dateModified',
          ruleTypeId: ruleType,
        }),
      );
      yield put(
        setLoadingState({
          id: action.payload.id,
          isLoading: false,
        }),
      );
      yield put(
        addNotification({
          id: action.payload.id,
          type: 'positive',
          msg: response.success,
        }),
      );
    } else {
      yield put(
        setLoadingState({
          id: action.payload.id,
          isLoading: false,
        }),
      );
      yield put(
        addNotification({
          id: action.payload.id,
          type: 'negative',
          msg: response.error.message,
        }),
      );
      yield put(
        rulesForRuleTypesPostError({
          msg: new Error(response),
        }),
      );
    }
  } catch (thrownError) {
    yield put(rulesForRuleTypesPostError(thrownError));
    yield put(
      setLoadingState({
        id: action.payload.id,
        isLoading: false,
      }),
    );
    yield put(
      addNotification({
        id: action.payload.id,
        type: 'negative',
        msg: thrownError.message,
      }),
    );
    yield put(
      rulesForRuleTypesPostError({
        msg: new Error(thrownError),
      }),
    );
  }
  
}

function* getRevertRuleVersionGenerator(action) {
  yield put(
    setLoadingState({
      id: action.payload.selectedRuleTypeId,
      isLoading: true,
    }),
  );
  try {
    const userConfig = yield select(({ serverConfig }) => serverConfig);
    const response = yield call(
      getRevertRuleVersionsList,
      userConfig,
      action.payload,
    );
    const ruleVersions = response;
    if (response) {
      yield put(
        getRevertRuleVersionsSuccess({
          ruleVersions,
        }),
      );
    } else {
      // show error banner notification when request is failed to pull data
      yield put(
        addNotification({
          id: action.payload.selectedRuleTypeId,
          type: 'negative',
          msg: response.error.message,
        }),
      );
      yield put(
        getRevertRuleVersionsError(
          new Error(
            '[ getRevertRuleVersionGenerator ] returned versions was not an object',
          ),
        ),
      );
      yield put(setIsShowingRevertRuleModal(false));
    }
    // disable loader when request is processed, either failed or successs
    yield put(
      setLoadingState({
        id: action.payload.selectedRuleTypeId,
        isLoading: false,
      }),
    );
  } catch (thrownError) {
    yield put(setIsShowingRevertRuleModal(false));
    yield put(getRevertRuleVersionsError(thrownError));
    yield put(
      addNotification({
        id: thrownError.errorId,
        type: 'negative',
        msg: thrownError.message,
      }),
    );
    yield put(
      setLoadingState({
        id: action.payload.selectedRuleTypeId,
        isLoading: false,
      }),
    );
  }
}

function* postRevertRuleVersionGenerator(action) {
  yield put(
    setLoadingState({
      id: action.payload.ruleId,
      isLoading: true,
    }),
  );
  try {
    const userConfig = yield select(({ serverConfig }) => serverConfig);
    const response = yield call(
      putRevertRuleVersion,
      userConfig,
      action.payload,
    );
    if (response && response.success && action.payload) {
      const ruleType = action.payload.selectedRuleTypeId
      const {selectedRuleTypeId, ruleId} = action.payload;
      const responseSuccess = response.success;
      
      let versionFromApi = responseSuccess.split('version')[1];
      versionFromApi = versionFromApi.replace(' has been created from','');
      const ruleVersion = versionFromApi.replace(' ','');
      // new rule version from revert rule put call is sent to react view to call getRuleDetails in order to pull latest rule details
      yield put(postRevertRuleVersionSuccess({response, ruleVersion}));
      // called ruleSummary in order to pull latest rules for left main grid

      yield put(updateCurrentTab({ currentTab: 0 }));  // for rule flow
      yield put(setAdvancedGroupDetailsTab(0));
      
      if (!window.location.href.includes('ruletype')) {
        const group = yield select(selectCurrentDetailsRecord);
        yield put(triggerRuleTypesRequest({ groupId: group.id }));
      }
      yield put(
        rulesCountFromDBTrigger({
          offset: 0,
          queryValue: '',
          name: 'dateModified',
          sortOrder: 'desc',
          ruleTypeId: ruleType,
        }),
      );

      const { rules } = yield call(
        getRulesForRuleTypesList,
        userConfig,
        {
          sortOrder: 'desc',
          sortColumn: 'dateModified',
          offset: 0,
          ruleTypeId: ruleType,
          queryValue: '',
        },
        ruleType,
      );
      const isAdvancedViewShown = !window.location.href.includes('ruletype');
      const rule = rules.find(r => r.id === ruleId);
      yield put(
        setIsViewingRuleDetails({
          isAdvancedViewShown,
          selectedRuleRecord: rule,
          selectedRuleTypeId
        }),
      );

      
      yield put(
        triggerAllRulesForRuleTypesRequest({
          offset: 0,
          queryValue: '',
          sortOrder: 'desc',
          name: 'dateModified',
          ruleTypeId: selectedRuleTypeId,
        }),
      );
      yield put(
        addNotification({
          id: ruleId,
          type: 'positive',
          msg: response.success,
        }),
      );
      yield put(
        setLoadingState({
          id: ruleId,
          isLoading: false,
        }),
      );
      yield put(setIsShowingRevertRuleModal(false));
    }
  } catch (thrownError) {
    yield put(
      addNotification({
        id: action.payload.ruleId,
        type: 'negative',
        msg: thrownError.message,
      }),
    );
    yield put(
      setLoadingState({
        id: action.payload.ruleId,
        isLoading: false,
      }),
    );
    yield put(setIsShowingRevertRuleModal(false));
    yield put(postRevertRuleVersionError(thrownError));
  }
}

// This saga gets attached to the redux store, and listens for action types
// similar to the reducers. When it matches an action type it will run the
// generator indicated and pass it the action as an argument.
function* rulesForRuleTypesSaga() {
  yield takeLatest(
    RULES_FOR_RULETYPES_PUT_REQUEST_TRIGGER,
    rulesForRuleTypesPutGenerator,
  );
  yield takeLatest(
    RULES_FOR_RULETYPES_POST_REQUEST_TRIGGER,
    rulesForRuleTypesPostGenerator,
  );
  yield takeLatest(
    TRIGGER_COMMIT_RULE_FOR_RULE_TYPE,
    commitRuleforRuleTypePutGenerator,
  );
  yield takeLatest(
    GET_REVERT_RULE_VERSION_TRIGGER,
    getRevertRuleVersionGenerator,
  );
  yield takeLatest(
    POST_REVERT_RULE_VERSION_TRIGGER,
    postRevertRuleVersionGenerator,
  );
}

export default rulesForRuleTypesSaga;
