import { select, call, put, takeLatest, all } from 'redux-saga/effects';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import _ from 'lodash';
import {
  getRuleTypesList,
  getRuleDetails,
  getAddRuleTypesList,
  deleteRuleDetail,
  overwriteTestRule,
} from './network';
import {
  ruleTypesListReceived,
  ruleTypesListRequestError,
  triggerUpdateRuleTypes,
  ruleDetailsListReceived,
  ruleDetailsRequestError,
  setRuleTypesListReceived,
  setRuleTypesListRequestError,
  setRuleTypeModalOpen,
  setIsShowingDeleteRuleDetailModal,
  setIsShowingOverwriteModal,
  deleteRuleDetailSuccess,
  deleteRuleDetailError,
  triggerRuleDetailsRequest,
  setIsViewingRuleDetails,
  triggerRuleTypesRequest,
  overwriteTestRuleReceived,
  overwriteTestRuleError,
  setRuleTypesSearchResults,
  imagesTestCountRecieved,
  imagesTestCountError
} from './actions';
import {
  RULE_TYPES_REQUEST_TRIGGER,
  RULE_DETAILS_REQUEST_TRIGGER,
  SET_RULE_TYPE_REQUEST_TRIGGER,
  DELETE_RULE_DETAIL_TRIGGER,
  OVERWRITE_TEST_RULE_REQUEST_TRIGGER,
  IMAGES_TEST_COUNT_REQUEST_TRIGGER
} from './constants';
import { setLoadingState } from '../../app/loadingState/actions';
import { addNotification } from '../../app/notificationState/actions';
import { triggerAllRulesForRuleTypesRequest, rulesCountFromDBTrigger } from '../rulesForRuleTypesList/actions';
import { getRulesForRuleTypesList } from '../rulesForRuleTypesList/network';
import { selectCurrentDetailsRecord } from '../../../containers/GroupDetailView/selectors';
import {
  selectRuleTypesSearchQuery,
  selectSelectedRuleTypeId,
} from '../../../containers/TestAssocTabDetailsView/selectors';

function* ruleTypesListGenerator(data) {
  yield put(
    setLoadingState({
      id: 'ruleTypesGridLoading',
      isLoading: true,
    }),
  );
  const { groupId, ruleType, queryValue } = data.payload;
  try {
    // get values sent via server-side config to use with this call
    const { clientLayoutId, userSecurityTokenKey, username, roleId, dataSourceId } = yield select(
      ({ serverConfig }) => serverConfig,
    );

    const ruleTypesArray = yield call(
      getRuleTypesList,
      clientLayoutId,
      userSecurityTokenKey,
      username,
      groupId,
      ruleType,
      queryValue,
      dataSourceId,
      roleId
    );

    if (Array.isArray(ruleTypesArray)) {
      const ruleTypesQueryValue = yield select(selectRuleTypesSearchQuery);
      const ruleTypesRuleType = yield select(selectSelectedRuleTypeId);
      if (ruleType && queryValue && queryValue.length) {
        const ruleTypesSearchResults = yield call(
          getRuleTypesList,
          clientLayoutId,
          userSecurityTokenKey,
          username,
          groupId,
          ruleTypesRuleType,
          ruleTypesQueryValue,
          dataSourceId,
          roleId
        );
        yield put(
          setLoadingState({
            id: 'ruleTypesGridLoading',
            isLoading: false,
          }),
        );
        yield put(setRuleTypesSearchResults(ruleTypesSearchResults));
      } else {
        yield put(
          setLoadingState({
            id: 'ruleTypesGridLoading',
            isLoading: false,
          }),
        );
        yield put(setRuleTypesSearchResults([]));

        // Getting all of the prev state that is set in the store
        const prevSelectedRecord = yield select();
        // Getting the previous accum records from the state
        const { ruleTypesAccumRecords } = prevSelectedRecord.ruleTypesList;
        /**
         * Generating the new rule types array depending on whether there is a rule type or not
         * If there is a rule type in the payload that means that the user is adding a rule type/s from the modal
         * If there is not one that means we have a new call for the rule types and we need to just use the response from the call
         */
        const generatedRuleTypesArray = ruleType ? [
          ...ruleTypesArray,
          ...ruleTypesAccumRecords
        ] : ruleTypesArray;

        yield put(ruleTypesListReceived(generatedRuleTypesArray, groupId));
        yield put(triggerUpdateRuleTypes(generatedRuleTypesArray, groupId));
      }
    } else {
      yield put(
        setLoadingState({
          id: 'ruleTypesGridLoading',
          isLoading: false,
        }),
      );
      yield put(setRuleTypesSearchResults([]));

      const prevSelectedRecord = yield select();

      if (prevSelectedRecord.ruleTypesList.ruleTypesAccumRecords.length) {
        ruleTypesArray.forEach(element => {
          if (
            !prevSelectedRecord.ruleTypesList.ruleTypesAccumRecords.find(
              item => item.id === element.id,
            )
          ) {
            prevSelectedRecord.ruleTypesList.ruleTypesAccumRecords.push(
              element,
            );
          }
        });
        yield put(
          triggerUpdateRuleTypes(
            prevSelectedRecord.ruleTypesList.ruleTypesAccumRecords,
            groupId,
          ),
        );
        yield put(
          ruleTypesListReceived(
            prevSelectedRecord.ruleTypesList.ruleTypesAccumRecords,
            groupId,
          ),
        );
      } else {
        yield put(ruleTypesListReceived(ruleTypesArray, groupId));
        yield put(triggerUpdateRuleTypes(ruleTypesArray, groupId));
      }
    }
  } catch (thrownError) {
    yield put(
      addNotification({
        id: thrownError.errorId,
        type: 'negative',
        msg: thrownError.message,
      }),
    );
    yield put(
      setLoadingState({
        id: 'ruleTypesGridLoading',
        isLoading: false,
      }),
    );
    yield put(
      addNotification({
        id: thrownError.errorId,
        type: 'negative',
        msg: thrownError.message,
      }),
    );
    yield put(ruleTypesListRequestError(thrownError, groupId));
  }
}

function* ruleDetailsListGenerator(action) {
  yield put(
    setLoadingState({
      id: 'ruleDetailsGridLoading',
      isLoading: true,
    }),
  );
  try {
    const userConfig = yield select(({ serverConfig }) => serverConfig);
    const { clientLayoutId, userSecurityTokenKey, username, roleId } = userConfig;
    const { ruleType, ruleId, ruleVersion } = action.payload;
    const response = yield call(getRuleDetails, {
      clientLayoutId,
      userSecurityTokenKey,
      username,
      ruleType,
      ruleId,
      ruleVersion,
      roleId
    });
    const ruleDetailsResults = response.ruleDetails;
    const {testImageCount} = response;

    // There are rule details and we are getting a response
    if (Array.isArray(ruleDetailsResults) && !isEmpty(ruleDetailsResults)) {
      yield put(
        setLoadingState({
          id: 'ruleDetailsGridLoading',
          isLoading: false,
        }),
      );
      // If we got what we expect, dispatch our success action
      yield put(
        ruleDetailsListReceived({
          ruleDetails: ruleDetailsResults,
          testImageCount
        }),
      );
    } else {
      // We got a 200 response that was valid JSON, but the expected data type
      // was not returned from the server so we pass a custom error out with our
      // failure action
      yield put(
        setLoadingState({
          id: 'ruleDetailsGridLoading',
          isLoading: false,
        }),
      );
      yield put(
        ruleDetailsRequestError(
          new Error(
            '[ ruleTypesListGenerator ] returned rule type list was not an Array',
          ),
        ),
      );
    }
  } catch (thrownError) {
    yield put(
      setLoadingState({
        id: 'ruleDetailsGridLoading',
        isLoading: false,
      }),
    );
    yield put(
      addNotification({
        id: thrownError.errorId,
        type: 'negative',
        msg: thrownError.code ? `${thrownError.message}. Error code: ${thrownError.code}` : thrownError.message,
      }),
    );
    yield put(ruleDetailsRequestError(thrownError));
  }
  // After we have sent our new data off to the reducer we can still dispatch
  // additional actions to update app state, for example to resolve the loading
  // state set in the example above
  // yield put(AppActions.stopWaiting());
}


function* imagesTestCountListGenerator(action) {
  yield put(
    setLoadingState({
      id: 'multipleCommitDialogGridLoading',
      isLoading: true,
    }),
  );
  try {
    const userConfig = yield select(({ serverConfig }) => serverConfig);
    const { clientLayoutId, userSecurityTokenKey, username } = userConfig;
    const records = action.payload;


    const results = yield all(records.map(record => {

      const { ruleType, ruleId, ruleVersion } = record;

      
      return call(getRuleDetails, {
        clientLayoutId,
        userSecurityTokenKey,
        username,
        ruleType,
        ruleId,
        ruleVersion,
      });


    }
    ));

    const testImagesCountResponse = _.filter(results.map (result =>  {
      if (result && result.ruleId) {
        return {
          ruleId: result.ruleId,
          testImageCount: result.testImageCount
        };
      }
      return {}
    }),_.size);


    yield put(
      imagesTestCountRecieved({
        multipleTestImageCountRecords: testImagesCountResponse,
      }),
    );

    yield put(
      setLoadingState({
        id: 'multipleCommitDialogGridLoading',
        isLoading: false,
      }));
    
  } catch (thrownError) {
    yield put(
      imagesTestCountError({
        id: thrownError.errorId,
        type: 'negative',
        msg: thrownError.message,
      }),
    );
    yield put(
      setLoadingState({
        id: 'multipleCommitDialogGridLoading',
        isLoading: false,
      }),
    );
  }
}


function* overwriteTestRuleGenerator(action) {
  yield put(
    setLoadingState({
      id: 'overwriteTestRuleLoading',
      isLoading: true,
    }),
  );
  try {
    const userConfig = yield select(({ serverConfig }) => serverConfig);
    const { clientLayoutId, userSecurityTokenKey, username, roleId } = userConfig;
    const { ruleName, ruleType, ruleId } = action.payload;

    const response = yield call(overwriteTestRule, {
      clientLayoutId,
      userSecurityTokenKey,
      username,
      ruleType,
      ruleId,
      roleId
    });

    yield put(
      overwriteTestRuleReceived({
        overwriteTestRule: response,
      }),
    );
    yield put(
      triggerAllRulesForRuleTypesRequest({
        offset: 0,
        queryValue: '',
        sortOrder: 'desc',
        name: 'dateModified',
        ruleTypeId: ruleType,
      }),
    );
    const { rules } = yield call(
      getRulesForRuleTypesList,
      userConfig,
      {
        sortOrder: 'desc',
        sortColumn: 'dateModified',
        offset: 0,
        ruleTypeId: ruleType,
        queryValue: '',
      },
      ruleType,
    );
    const rule = rules.find(r => r.id === ruleId);
    const isAdvancedViewShown = !window.location.href.includes('ruletype');
    yield put(
      setIsViewingRuleDetails({
        isAdvancedViewShown,
        selectedRuleRecord: rule,
        selectedRuleTypeId: ruleType,
      }),
    );

    if (!window.location.href.includes('ruletype')) {
      const group = yield select(selectCurrentDetailsRecord);
      yield put(triggerRuleTypesRequest({ groupId: group.id }));
    }

    yield put(
      setLoadingState({
        id: 'overwriteTestRuleLoading',
        isLoading: false,
      }),
    );

    yield put(setIsShowingOverwriteModal(false));

    yield put(
      addNotification({
        id: ruleId,
        type: 'positive',
        msg: `The test version has been overwritten live version for rule ${ruleName}.`,
        icon: 'Info',
      }),
    );

    const { ruleVersion } = response;
    yield put(
      triggerRuleDetailsRequest({
        userConfig,
        ruleType,
        ruleId,
        ruleVersion,
      }),
    );
  } catch (thrownError) {
    yield put(
      addNotification({
        id: thrownError.errorId,
        type: 'negative',
        msg: thrownError.message,
      }),
    );
    yield put(
      setLoadingState({
        id: 'overwriteTestRuleLoading',
        isLoading: false,
      }),
    );
    yield put(
      addNotification({
        id: thrownError.errorId,
        type: 'negative',
        msg: thrownError.message,
      }),
    );
    yield put(setIsShowingOverwriteModal(false));
    yield put(overwriteTestRuleError(thrownError));
  }
}
// This generator is the control flow manager that will step through the request
// lifecycle and dispatch actions to update the application at the end
function* getAddRuleTypesListGenerator() {
  // Before we make our network request we can dispatch actions to modify app
  // state, for example to show a spinner:
  // yield put(AppActions.startWaiting());
  try {
    // get values sent via server-side config to use with this call
    const { clientLayoutId, userSecurityTokenKey, username, roleId } = yield select(
      ({ serverConfig }) => serverConfig,
    );
    // We get the rule types list in scope by yielding the result of calling the API
    const ruleTypesArray = yield call(
      getAddRuleTypesList,
      clientLayoutId,
      userSecurityTokenKey,
      username,
      roleId
    );
    // If no error was thrown we can assume we got our list of rule types successfully,
    // but we can also do additional validation here
    if (Array.isArray(ruleTypesArray)) {
      // If we got what we expect, dispatch our success action
      yield put(setRuleTypesListReceived(ruleTypesArray));
    } else {
      // We got a 200 response that was valid JSON, but the expected data type
      // was not returned from the server so we pass a custom error out with our
      // failure action
      yield put(setRuleTypeModalOpen(false));
      yield put(
        setRuleTypesListRequestError(
          new Error(
            '[ getAddRuleTypesListGenerator ] returned rule type list was not an Array',
          ),
        ),
      );
      yield put(
        ruleTypesListRequestError(
          new Error(
            '[ getAddRuleTypesListGenerator ] returned rule type list was not an Array',
          ),
        ),
      );
    }
  } catch (thrownError) {
    yield put(setRuleTypeModalOpen(false));
    yield put(
      addNotification({
        id: thrownError.errorId,
        type: 'negative',
        msg: thrownError.message,
      }),
    );
    yield put(setRuleTypesListRequestError(thrownError));
  }
}
function* deleteRuleDetailGenerator(action) {
  // Before we make our network request we can dispatch actions to modify app
  // state, for example to show a spinner:
  // yield put(AppActions.startWaiting());
  yield put(
    setLoadingState({
      id: action.payload.ruleId,
      isLoading: true,
    }),
  );
  try {
    const userConfig = yield select(({ serverConfig }) => serverConfig);
    const response = yield call(deleteRuleDetail, userConfig, action.payload);
    const ruleType = action.payload.ruleType
      ? action.payload.ruleType
      : window.location.href.substring(
        window.location.href.lastIndexOf('=') + 1,
      );
    if (response && !response.error) {
      if (action.payload) {
        yield put(
          deleteRuleDetailSuccess(),
        );
        const { ruleId } = action.payload;
        const { ruleVersion } = response;
        yield put( 
          triggerRuleDetailsRequest({
            userConfig,
            ruleType,
            ruleId,
            ruleVersion,
          }),
        );
        yield put(
          setLoadingState({
            id: action.payload.ruleId,
            isLoading: false,
          }),
        );
        if (!window.location.href.includes('ruletype')) {
          const group = yield select(selectCurrentDetailsRecord);
          yield put(triggerRuleTypesRequest({ groupId: group.id }));
        }
        yield put(setIsShowingDeleteRuleDetailModal(false));
        const { rules } = yield call(
          getRulesForRuleTypesList,
          userConfig,
          {
            sortOrder: 'desc',
            sortColumn: 'dateModified',
            offset: 0,
            ruleTypeId: ruleType,
            queryValue: '',
          },
          ruleType,
        );
        // Grabbing the state from the store
        const getState = yield select();
        // Getting the Current Selected Record Active Groups since it does not come back from API
        const currentSelectedRuleRecordActiveGroups = get(getState, 'ruleTypesList.selectedRuleRecord.activeGroup', []);
        // Finding the current rule from the list of updated rules after saving the detail
        const findCurrentRule = rules.find(r => r.id === ruleId);
        const isAdvancedViewShown = !window.location.href.includes('ruletype');
        // Setting the rule with the updates details and also the active groups associated with it
        const rule = {
          ...findCurrentRule,
          activeGroup: currentSelectedRuleRecordActiveGroups
        };
        yield put(
          setIsViewingRuleDetails({
            isAdvancedViewShown,
            selectedRuleRecord: rule,
            selectedRuleTypeId: ruleType,
          }),
        );
        yield put(
          triggerAllRulesForRuleTypesRequest({
            sortOrder: 'desc',
            sortColumn: 'dateModified',
            offset: 0,
            ruleTypeId: ruleType,
            queryValue: '',
          }),
        );
        yield put(
          rulesCountFromDBTrigger({
            sortOrder: 'desc',
            sortColumn: 'dateModified',
            offset: 0,
            ruleTypeId: ruleType,
            queryValue: '',
          }),
        );
        yield put(
          addNotification({
            id: action.payload.ruleId,
            type: 'positive',
            msg: response.success,
          }),
        );
      }
    } else {
      yield put(setIsShowingDeleteRuleDetailModal(false));
      yield put(
        addNotification({
          id: action.payload.ruleId,
          type: 'negative',
          msg: response.error.message,
        }),
      );
      yield put(
        setLoadingState({
          id: action.payload.ruleId,
          isLoading: false,
        }),
      );
      yield put(
        deleteRuleDetailError({
          msg: new Error(response),
        }),
      );
    }
  } catch (thrownError) {
    yield put(setIsShowingDeleteRuleDetailModal(false));
    yield put(
      setLoadingState({
        id: action.payload.ruleId,
        isLoading: false,
      }),
    );
    yield put(
      addNotification({
        id: thrownError.errorId,
        type: 'negative',
        msg: thrownError.message,
      }),
    );
    yield put(deleteRuleDetailError(thrownError));
  }
}
// This saga gets attached to the redux store, and listens for action types
// similar to the reducers. When it matches an action type it will run the
// generator indicated and pass it the action as an argument.
export function* ruleTypesListSaga() {
  yield takeLatest(RULE_TYPES_REQUEST_TRIGGER, ruleTypesListGenerator);
  yield takeLatest(SET_RULE_TYPE_REQUEST_TRIGGER, getAddRuleTypesListGenerator);
  yield takeLatest(
    OVERWRITE_TEST_RULE_REQUEST_TRIGGER,
    overwriteTestRuleGenerator,
  );
}
export function* ruleDetailsSaga() {
  yield takeLatest(DELETE_RULE_DETAIL_TRIGGER, deleteRuleDetailGenerator);
  yield takeLatest(RULE_DETAILS_REQUEST_TRIGGER, ruleDetailsListGenerator);
  yield takeLatest(IMAGES_TEST_COUNT_REQUEST_TRIGGER, imagesTestCountListGenerator);
}
