export const RULE_DETAIL_ELEMENTS_TRIGGER = 'RULE_DETAIL_ELEMENTS_TRIGGER';
export const RULE_DETAIL_ELEMENTS_SUCCESS = 'RULE_DETAIL_ELEMENTS_SUCCESS';
export const RULES_DETAIL_ELEMENT_ERROR = 'RULES_DETAIL_ELEMENT_ERROR';
export const RULE_DETAIL_POST_REQUEST_TRIGGER =
  'RULE_DETAIL_POST_REQUEST_TRIGGER';
export const RULE_DETAIL_POST_REQUEST_SUCCESS =
  'RULE_DETAIL_POST_REQUEST_SUCCESS';
export const RULE_DETAIL_POST_REQUEST_ERROR = 'RULE_DETAIL_POST_REQUEST_ERROR';
export const RULE_DETAIL_PUT_REQUEST_TRIGGER =
  'RULE_DETAIL_PUT_REQUEST_TRIGGER';
export const RULE_DETAIL_PUT_REQUEST_SUCCESS =
  'RULE_DETAIL_PUT_REQUEST_SUCCESS';
export const RULE_DETAIL_PUT_REQUEST_ERROR = 'RULE_DETAIL_PUT_REQUEST_ERROR';
export const SET_SELECTED_RULE_DETAIL = 'SET_SELECTED_RULE_DETAIL';
export const IS_CLONING_RULE_DETAIL = 'IS_CLONING_RULE_DETAIL';
export const IS_READ_ONLY_RULE_DETAIL = 'IS_READ_ONLY_RULE_DETAIL';
export const IS_EDITING_RULE_DETAIL = 'IS_EDITING_RULE_DETAIL';
export const IS_POSTING_RULE_DETAIL = 'IS_POSTING_RULE_DETAIL';
export const IS_SHOWING_RETURN_ADDRESS_MODAL =
  'IS_SHOWING_RETURN_ADDRESS_MODAL';
export const IS_SHOWING_PRODUCTION_ARRANGEMENT_MODAL =
  'IS_SHOWING_PRODUCTION_ARRANGEMENT_MODAL';
export const IS_SHOWING_CUSTOMER_SERVICE_MESSAGE_MODAL =
  'IS_SHOWING_CUSTOMER_SERVICE_MESSAGE_MODAL';
export const IS_SHOWING_DUPLEX_RULE_MODAL = 'IS_SHOWING_DUPLEX_RULE_MODAL';
export const IS_SHOWING_SIGNATURE_RULE_MODAL =
  'IS_SHOWING_SIGNATURE_RULE_MODAL';
export const IS_SHOWING_CHECK_TEXT_RULE_MODAL =
  'IS_SHOWING_CHECK_TEXT_RULE_MODAL';
export const IS_SHOWING_IMAGE_RULE_MODAL = 'IS_SHOWING_IMAGE_RULE_MODAL';
export const IS_SHOWING_AUTO_HOLD_MODAL = 'IS_SHOWING_AUTO_HOLD_MODAL';
export const IS_SHOWING_MEMBER_ID_MODAL = 'IS_SHOWING_MEMBER_ID_MODAL';
export const IS_SHOWING_TAX_ID_ARRANGEMENT_MODAL =
  'IS_SHOWING_TAX_ID_ARRANGEMENT_MODAL';
export const IS_SHOWING_ERISA_MESSAGE_MODAL = 'IS_SHOWING_ERISA_MESSAGE_MODAL';
export const IS_SHOWING_HIGH_DOLLAR_REVIEW_MODAL =
  'IS_SHOWING_HIGH_DOLLAR_REVIEW_MODAL';
export const IS_SHOWING_PROVIDER_INSURED_OPTION_MESSAGE_MODAL =
  'IS_SHOWING_PROVIDER_INSURED_OPTION_MESSAGE_MODAL';
export const IS_SHOWING_GENERAL_MESSAGE_MODAL =
  'IS_SHOWING_GENERAL_MESSAGE_MODAL';
export const IS_SHOWING_PDF_TAGGING_MODAL = 'IS_SHOWING_PDF_TAGGING_MODAL';
export const RESET_RULE_DETAIL_ELEMENTS = 'RESET_RULE_DETAIL_ELEMENTS';
export const IS_SHOWING_ADD_NEW_ADDRESS_MODAL =
  'IS_SHOWING_ADD_NEW_ADDRESS_MODAL';

export const ADD_NEW_ADDRESS_GET_ELEMENTS_TRIGGER =
  'ADD_NEW_ADDRESS_GET_ELEMENTS_TRIGGER';
export const ADD_NEW_ADDRESS_GET_ELEMENTS_TRIGGER_SUCCESS =
  'ADD_NEW_ADDRESS_GET_ELEMENTS_TRIGGER_SUCCESS';
export const ADD_NEW_ADDRESS_GET_ELEMENTS_TRIGGER_ERROR =
  'ADD_NEW_ADDRESS_GET_ELEMENTS_TRIGGER_ERROR';

export const ADD_NEW_ADDRESS_ELEMENTS_POST_REQUEST_TRIGGER =
  'ADD_NEW_ADDRESS_ELEMENTS_POST_REQUEST_TRIGGER';
export const ADD_NEW_ADDRESS_ELEMENTS_POST_REQUEST_TRIGGER_SUCCESS =
  'ADD_NEW_ADDRESS_ELEMENTS_POST_REQUEST_TRIGGER_SUCCESS';
export const ADD_NEW_ADDRESS_ELEMENTS_POST_REQUEST_TRIGGER_ERROR =
  'ADD_NEW_ADDRESS_ELEMENTS_POST_REQUEST_TRIGGER_ERROR';
export const IS_SHOWING_IMAGE_LIBRARY_MODAL =
  'IS_SHOWING_IMAGE_LIBRARY_MODAL';
export const IS_SHOWING_IMAGE_UPLOAD_MODAL =
  'IS_SHOWING_IMAGE_UPLOAD_MODAL';

export const POST_IMAGE_UPLOAD_TRIGGER =
  'POST_IMAGE_UPLOAD_TRIGGER';
export const POST_IMAGE_UPLOAD_SUCCESS =
  'POST_IMAGE_UPLOAD_SUCCESS';
export const POST_IMAGE_UPLOAD_ERROR =
  'POST_IMAGE_UPLOAD_ERROR';

export const GET_RULE_IMAGE_TRIGGER =
  'GET_RULE_IMAGE_TRIGGER';
export const GET_RULE_IMAGE_TRIGGER_SUCCESS =
  'GET_RULE_IMAGE_TRIGGER_SUCCESS';
export const GET_RULE_IMAGE_TRIGGER_ERROR =
  'GET_RULE_IMAGE_TRIGGER_ERROR';

export const GET_RULE_IMAGE_COUNT_TRIGGER =
  'GET_RULE_IMAGE_COUNT_TRIGGER';
export const GET_RULE_IMAGE_COUNT_TRIGGER_SUCCESS =
  'GET_RULE_IMAGE_COUNT_TRIGGER_SUCCESS';
export const GET_RULE_IMAGE_COUNT_TRIGGER_ERROR =
  'GET_RULE_IMAGE_COUNT_TRIGGER_ERROR';
export const RESET_RULE_IMAGE_DATA_TRIGGER =
  'RESET_RULE_IMAGE_DATA_TRIGGER';

export const CONVERT_IMAGE_UPLOAD_TRIGGER =
  'CONVERT_IMAGE_UPLOAD_TRIGGER';
export const CONVERT_IMAGE_UPLOAD_TRIGGER_SUCCESS =
  'CONVERT_IMAGE_UPLOAD_TRIGGER_SUCCESS';
export const CONVERT_IMAGE_UPLOAD_TRIGGER_ERROR =
  'CONVERT_IMAGE_UPLOAD_TRIGGER_ERROR';

export const IS_SHOWING_COMMIT_IMAGE_MODAL =
  'IS_SHOWING_COMMIT_IMAGE_MODAL';
export const POST_COMMIT_IMAGE_TRIGGER = 
  'POST_COMMIT_IMAGE_MODAL_TRIGGER';
export const POST_COMMIT_IMAGE_TRIGGER_SUCCESS = 
  'POST_COMMIT_IMAGE_MODAL_TRIGGER_SUCCESS';
export const POST_COMMIT_IMAGE_TRIGGER_ERROR = 
  'POST_COMMIT_IMAGE_MODAL_TRIGGER_ERROR';
export const POST_COMMIT_IMAGE_TRIGGER_CANCEL = 
  'POST_COMMIT_IMAGE_TRIGGER_CANCEL';
export const RESET_STATE = 
  'RESET_STATE';