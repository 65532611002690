import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Input,
  ButtonGroup,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ListPaneHeader,
  useLayout,
  LoadingIndicator
} from 'ui-core/dist'
import { SelectDropdown, Loading } from '../../components'
import './modules/styles.scss'
import axios from '../../axios'
import useAssignableRolesAndSources from '../../api/useAssignableRolesAndSources'
import useUserManagerUser from '../../api/useUserManagerUser'
import { setCopyId } from './modules/actions'
import useNotificationBannerHook from '../../useNotificationBannerHook';

const AddAUser = () => {
  const { notify } = useNotificationBannerHook()
  const {
    expandCollapseDetailPaneButtonConfig,
    closeDetailPaneButtonConfig,
    closeDetailPane,
    detailProps
  } = useLayout()
  const dispatch = useDispatch()

  const [isLoadingConfirm, setisLoadingConfirm]=useState(false)
  const [userId, setUserId] = useState('')
  const [userName, setUserName] = useState('')
  const [company, setCompany] = useState('')
  const [jobTitle, setJobTitle] = useState('')
  const [email, setEmail] = useState('')
  const [birthday, setBirthday] = useState('')
  const [workPh, setWorkPh] = useState('')
  const [workExtension, setWorkExtension] = useState('')
  const [faxNum, setFaxNum] = useState('')
  const [allowedIps, setAllowedIps] = useState('')
  const [status, setStatus] = useState({
    label: 'Active', value: true
  })
  const [userType, setUserType] = useState()
  const [alsoCopy, setAlsoCopy] = useState({ label: 'No', value: false })
  const [role, setRole] = useState()
  const [dataSource, setDataSource] = useState()
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)

  const copyId = useSelector(store => store.userManager.copyId)
  const { dataSourceDefinition, roleName } = useSelector(({ context }) => context?.context || {});

  const { roles, dataSources, isLoading } = useAssignableRolesAndSources()
  const { data, isLoading: isUserLoading } = useUserManagerUser({ userId: copyId })

  // if (detailProps && detailProps.isAddUser) {
  //   setUserId('')
  //   setUserName('')
  //   setCompany('')
  //   setJobTitle('')
  //   setWorkPh('')
  //   setWorkExtension('')
  //   setFaxNum('')
  //   setAllowedIps('')
  //   setStatus({
  //     label: 'Active', value: true
  //   })
  //   setUserType({ label: "External", value: "E" })
  // }

  useEffect(() => {
    setRole({ label: roles && roles[0] && roles[0].name, value: roles && roles[0] && roles[0].id })
  }, [roles, detailProps && detailProps.isAddUser])

  useEffect(() => {
    setDataSource({ label: dataSources && dataSources[0] && dataSources[0].description, value: dataSources && dataSources[0] && dataSources[0].id })
  }, [dataSources, detailProps && detailProps.isAddUser])

  useEffect(() => {
    setUserId('')
    setEmail('')
    setUserName(copyId ? data?.fullName : '')
    setCompany(copyId ? data?.company : '')
    setJobTitle(copyId ? data?.jobTitle : '')
    setWorkPh(copyId ? data?.workPhoneNumber : '')
    setWorkExtension(copyId ? data?.workPhoneNumberExt : '')
    setFaxNum(copyId ? data?.faxNumber : '')
    setAllowedIps(copyId ? data?.userAllowedIp : '')
    setStatus(copyId ? data?.isActive === "true" ? {
      label: 'Active', value: true
    } : { label: 'Inactive', value: false } : {
        label: 'Active', value: true
      })
    setUserType((dataSourceDefinition === '/*' && roleName === 'Internal Manager') ? {
      label: 'Internal',
      value: 'I'
    } : {
        label: 'External',
        value: 'E'
      })
  }, [data, detailProps && detailProps.isAddUser])

  const onUserIdChange = (e, { value }) => setUserId(value)
  const onUserChange = (e, { value }) => setUserName(value)
  const onCompanyChange = (e, { value }) => setCompany(value)
  const onJobChange = (e, { value }) => setJobTitle(value)
  const onEmailChange = (e, { value }) => setEmail(value)
  const onBirthdayChange = (e, { value }) => setBirthday(value)
  const onWorkChange = (e, { value }) => setWorkPh(value)
  const onWorkExtensionChange = (e, { value }) => setWorkExtension(value)
  const onFaxChange = (e, { value }) => setFaxNum(value)
  const onAllowedChange = (e, { value }) => setAllowedIps(value)
  const onStatusChange = (state) => setStatus(state)
  const onUserTypeChange = (state) => setUserType(state)
  const onAlsoCopyChange = (state) => setAlsoCopy(state)
  const onRoleDropdownChange = (state) => setRole(state)
  const onDataSourceDropdownChange = (state) => setDataSource(state)

  const onReset = () => {
    setUserId('')
    setUserName(copyId ? data?.fullName : '')
    setCompany(copyId ? data?.company : '')
    setJobTitle(copyId ? data?.jobTitle : '')
    setEmail('')
    setBirthday('')
    setWorkPh(copyId ? data?.workPhoneNumber : '')
    setWorkExtension(copyId ? data?.workPhoneNumberExt : '')
    setFaxNum(copyId ? data?.faxNumber : '')
    setAllowedIps(copyId ? data?.userAllowedIp : '')
    setStatus(copyId ? data?.isActive ? {
      label: 'Active', value: true
    } : { label: 'Inactive', value: false } : {
        label: 'Active', value: true
      })
    setUserType(data?.userType === "E" ? {
      label: "External", value: "E"
    } : { label: "Internal", value: "I" })
    setAlsoCopy({ label: 'No', value: false })
    setRole({ label: roles && roles[0] && roles[0].name, value: roles && roles[0] && roles[0].id })
    setDataSource({ label: dataSources && dataSources[0] && dataSources[0].description, value: dataSources && dataSources[0] && dataSources[0].id })
  }

  const onSubmit = () => {
    if(workPh) {
      if(!(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(workPh))) {
        notify('The Work Phone Number is improperly formatted.  It should follow the format ###-###-####','negative')
        return false;
      } 
    }
    if(faxNum) {
      if(!(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(faxNum))) {
        notify('The Work Fax Number is improperly formatted.  It should follow the format ###-###-####','negative')
        return false;
      } 
    }
    setIsConfirmationOpen(true)
  }

  const closeConfirmationModal = () => setIsConfirmationOpen(false)

  const onConfirmUpdate = async () => {
    const copyObj = {}
    if (alsoCopy.value === true) {
      copyObj.copyAccess = true
      copyObj.copyFromId = copyId
    } else if (alsoCopy.value === false) {
      copyObj.roleId = role?.value || undefined
      copyObj.dataSourceId = dataSource?.value || undefined
    }
    if (/^[a-z0-9_-]{3,16}$/.test(userId)) 
    {
      try {
        setisLoadingConfirm(true)
        await axios.post(`/members/add`, {
          userId,
          fullName: userName,
          birthDay: birthday,
          company,
          emailAddr: email,
          faxNumber: faxNum,
          jobTitle,
          isActive: status.value,
          workPhoneNumber: workPh,
          workPhoneNumberExt: workExtension,
          userType: userType.value,
          userAllowedIp: allowedIps,
          ...copyObj
        })
        notify(`You have successfully added the user ${userId}. Authentication instructions have been sent to the registered email address for the user.`, 'positive')
        detailProps.refreshMe()
        dispatch(setCopyId(''))
        onReset();
      } catch (err) {
        notify(err.displayMessage, "negative")
      } finally {
        setisLoadingConfirm(false)
        setIsConfirmationOpen(false)
        closeDetailPane()
      }
    } else {
      notify("The User ID may contain only 3-16 lowercase letters and numbers", "negative")
      setIsConfirmationOpen(false)
    }
  }

  return (
    <>
      <ListPaneHeader
        title="Add A User"
        layoutControls={[expandCollapseDetailPaneButtonConfig, closeDetailPaneButtonConfig]}
      />
      {(isLoading || isUserLoading) ? <Loading /> : (detailProps && detailProps.detailPaneOpen) || copyId ? (
        <div className="add-a-user">
          <p className="add-a-user__details">Complete the information below, then click Submit to continue. The E-Mail address field is not required but specifying the user&rsquo;s E-Mail adress will make the user&rsquo;s life a lot easier.</p>
          <span className="add-a-user__details --red">* indicates required information</span>
          <div className="add-a-user__row">
            <div className="add-a-user__set">
              <span className="add-a-user__label">User ID:<span className="--red">*</span></span>
              <Input
                domID="AddAUserUserIdInput"
                onChange={onUserIdChange}
                size="small"
                initialValue={userId}
                maxLength= {16}
              />
            </div>
            <div className="add-a-user__set">
              <span className="add-a-user__label">User Name:<span className="--red">*</span></span>
              <Input
                domID="AddAUserUserInput"
                onChange={onUserChange}
                size="small"
                initialValue={userName}
              />
            </div>
          </div>
          <div className="add-a-user__row">
            <div className="add-a-user__set">
              <span className="add-a-user__label">Company:</span>
              <Input
                domID="AddAUserCompanyInput"
                onChange={onCompanyChange}
                size="small"
                initialValue={company}
              />
            </div>
            <div className="add-a-user__set">
              <span className="add-a-user__label">Job Title:</span>
              <Input
                domID="AddAUserJobInput"
                onChange={onJobChange}
                size="small"
                initialValue={jobTitle}
              />
            </div>
          </div>
          <div className="add-a-user__set">
            <span className="add-a-user__label">E-mail Address:<span className="--red">*</span></span>
            <Input
              domID="AddAUserEmailInput"
              onChange={onEmailChange}
              size="small"
              initialValue={email}
            />
          </div>
          {/* <div className="add-a-user__set">
            <span className="add-a-user__label">Birthday</span>
            <Input 
              domID="AddAUserBirthdayInput"
              onChange={onBirthdayChange}
              size="small"
              // initialValue={data?.birthDay}
            />
            <span className="add-a-user__info">format 10/31</span>
          </div> */}
          <div className="add-a-user__set">
            <span className="add-a-user__label">Work Phone:</span>
            <Input
              domID="AddAUserWorkInput"
              onChange={onWorkChange}
              size="small"
              maxLength={12}
              initialValue={workPh}
            />
            <span className="add-a-user__info">format 314-555-1212 Ext:</span>
            <div style={{ width: '75px' }}>
              <Input
                domID="AddAUserWorkExtenstionInput"
                onChange={onWorkExtensionChange}
                size="small"
                initialValue={workExtension}
              />
            </div>
          </div>
          <div className="add-a-user__set">
            <span className="add-a-user__label">Fax Number:</span>
            <Input
              domID="AddAUserFaxInput"
              onChange={onFaxChange}
              size="small"
              maxLength={12}
              initialValue={faxNum}
            />
            <span className="add-a-user__info">format 314-555-1212</span>
          </div>
          <div className="add-a-user__set">
            <span className="add-a-user__label">Allowed IPs:</span>
            <Input
              domID="AddAUserAllowedInput"
              onChange={onAllowedChange}
              size="small"
              initialValue={allowedIps}
            />
          </div>
          <div className="add-a-user__set">
            <span className="add-a-user__label">Status:</span>
            <SelectDropdown
              width="400px"
              dataTestId="test-selectUserType"
              domID="UserTypeDropdown"
              onChange={onStatusChange}
              initialValue={status}
              isClearable={false}
              options={[
                {
                  label: 'Active',
                  value: true
                },
                {
                  label: 'Inactive',
                  value: false
                },
              ]}
              size="small"
            />
          </div>
          <div className="add-a-user__set">
            <span className="add-a-user__label">User Type:</span>
            <SelectDropdown
              width="400px"
              dataTestId="test-selectUserType"
              domID="UserTypeDropdown"
              onChange={onUserTypeChange}
              initialValue={userType}
              options={(dataSourceDefinition === '/*' && roleName === 'Internal Manager') ? [{
                label: 'Internal',
                value: 'I'
              }] : [{
                label: 'External',
                value: 'E'
              }]}
              size="small"
            />
          </div>
          {copyId && (
            <>
              <div className="add-a-user__set">
                <span className="add-a-user__label">Also Copy Access List Entries:</span>
                <SelectDropdown
                  width="400px"
                  dataTestId="test-alsoCopy"
                  domID="AlsoCopyDropdown"
                  onChange={onAlsoCopyChange}
                  initialValue={alsoCopy}
                  options={[
                    {
                      label: 'Yes',
                      value: true,
                    },
                    {
                      label: 'No',
                      value: false,
                    }
                  ]}
                  size="small"
                />
              </div>
            </>
          )}
          <div className="add-a-user__set">
            <span className="add-a-user__label">Role:</span>
            <SelectDropdown
              width="400px"
              domID="AccessRoleDropown"
              size="medium"
              placeholder="Select..."
              onChange={onRoleDropdownChange}
              options={roles?.map(r => ({ label: r.name, value: r.id }))}
              initialValue={role}
              disabled={alsoCopy.value === true}
              isClearable={false}
            />
          </div>
          <div className="add-a-user__set">
            <span className="add-a-user__label">Data Source:</span>
            <SelectDropdown
              width="400px"
              domID="AccessDataSourceDropown"
              onChange={onDataSourceDropdownChange}
              isDisabled={alsoCopy.value === true}
              initialValue={dataSource}
              options={dataSources?.map(ds => ({ label: ds.description, value: ds.id }))}
            />
          </div>
          <ButtonGroup className="add-a-user__button-group--right">
            <Button
              domID="Button"
              name="Reset"
              onClick={onReset}
              buttonType="standard"
              size="medium"
            />
            <Button
              domID="Button"
              name="Submit"
              onClick={onSubmit}
              buttonType="standard"
              size="medium"
              disabled={!email || !userId || !userName}
            />
          </ButtonGroup>
        </div>
      ) : <></>}
      { (((detailProps && detailProps.detailPaneOpen) || copyId) && isConfirmationOpen) ? 
      <Modal isOpen={isConfirmationOpen} className="modify-user-modal">
        <ModalHeader
          title="Are You Sure You Want to Add this User?"
          onClose={closeConfirmationModal}
        />
        <ModalFooter>
          <div className='displayFlex'>
            <ButtonGroup className="modify-user-modal__button-group">
              {isLoadingConfirm ? <LoadingIndicator /> :null}
              <Button
                domID="confirmUpdateButton"
                disabled={isLoadingConfirm}
                onClick={onConfirmUpdate}
                buttonType="standard"
                size="medium"
                name="Confirm"
              />
            </ButtonGroup>
          </div>
        </ModalFooter>
      </Modal> : <></>}
    </>
  )
}

export default AddAUser