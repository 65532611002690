/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useMemo } from 'react';
import { Button, Modal, ModalHeader, Grid, ModalBody, LoadingIndicator } from 'ui-core/dist';
import ErrorPage from '../ErrorPage';
import {
  useDownloadFile,
  useGetFileDetails,
  useGetOriginalFile,
  useGetCheckRegister,
  useCheckIfFileNameAFF,
  useGetAFFFiles
} from '../../api/useFileReceiptManager';
import { DOWNLOAD_ORIGINAL_FILE_GRID_COLUMN } from './Modules/constants';
import FileReceiptManagerResend from './FileReceiptManagerResend';
import StyledBoxBorder from './Modules/StyledBoxBorder';
import { LoadingPage, AnchorInput } from '../../components';
import useNotificationBannerHook from '../../useNotificationBannerHook';
import Authorizer from '../../containers/Authorizer';
import withAxiosCancellation from '../../components/withAxiosCancellation'

const FileReceiptManagerDetailComponent = ({ filekey, height, overflow, noActions = false, getCancelSourceWithName }) => {

  const { notify } = useNotificationBannerHook();
  const [tmpFileName, setTmpFileName] = useState('');
  const [fileData, setFileData] = useState('');

  const { details, isLoading, isError, error, getFileDetails } = useGetFileDetails({getCancelSource:getCancelSourceWithName('getFileDetails')});
  const { ABFFile, getOriginalFile } = useGetOriginalFile({getCancelSource:getCancelSourceWithName('getOriginalFile')});
  const { checkRegister, getCheckRegister } = useGetCheckRegister({getCancelSource:getCancelSourceWithName('getCheckRegister')});
  const { AffFileName, getCheckIfFileNameAFF } = useCheckIfFileNameAFF({getCancelSource:getCancelSourceWithName('checkIfFileNameAFF')});
  const { originalFile, isLoading: isLoadingGetOriginalFile,isError: isErrorGet, error: errorGet, getAFFFiles } = useGetAFFFiles({getCancelSource:getCancelSourceWithName('getAffFiles')});
  const { file, getDownloadFile } = useDownloadFile();
  const [isOpenResendModal, setisOpenResendModal] = useState(false);
  const [isOpenDwnldOrginalFileModal, setisOpenDwnldOrginalFileModal] = useState(false)
  const [called, setCalled] = useState(1);

  useEffect(() => {
    if (filekey) {
      setFileData('');
      setTmpFileName('');
      getFileDetails({ pstrFileKey: filekey });
      getOriginalFile({ pstrFileKey: filekey });
      getCheckRegister({ pstrFileKey: filekey });
      getCheckIfFileNameAFF({ strFileKey: filekey });
    }
  }, [filekey]);

  useEffect(() => {
    if (AffFileName) {
      getAFFFiles({ strFileName: AffFileName });
    }
    if (isErrorGet) {
      notify(`Error Ocurred to get Original File`, 'negative')
    }
  }, [AffFileName, isErrorGet, errorGet]);

  const downloadFile = ({ File }) => {
    setFileData('');
    setTmpFileName('')
    getDownloadFile({ url: File.redirectUri, called });
    setCalled(called + 1);
    setTmpFileName(File.fileName);
  }

  useEffect(() => {
    if (file) {
      setFileData(file);
    }
  }, [file])

  useEffect(() => {
    if (fileData && tmpFileName) {
      if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
        window.navigator.msSaveOrOpenBlob(file, tmpFileName);
      } else {
        const url = URL.createObjectURL(file);
        const element = document.createElement('a');
        element.setAttribute('href', url);
        element.setAttribute('download', tmpFileName);
        element.click();
      }
    }
  }, [fileData, tmpFileName])

  const originalFileDownload = () => {
    if (originalFile.length > 1) {
      setisOpenDwnldOrginalFileModal(true)

    }
    else if (originalFile.constructor.name === 'Array') {
      downloadFile({ File: { ...originalFile[0], redirectUri: originalFile[0].fileURL }, button: 'originalFile' });
    }
    else if (originalFile.constructor.name === 'Object') {
      downloadFile({ File: { ...originalFile, redirectUri: originalFile.fileURL }, button: 'originalFile' });
    }
  }
  const renderDwnldOriginalFileDetails = useMemo(() => {
    const originalFileArray = Array.from(originalFile);
    if (originalFileArray.length) {
      const data = originalFileArray.map((fileDetails) => ({
        originalFile: (
          <AnchorInput
            id='originalFileId'
            type='button'
            className='fileKeyLink'
            onClick={() => {
              downloadFile({ File: { ...fileDetails, redirectUri: fileDetails.fileURL }, button: 'originalFile' });
            }}
          >
            {fileDetails.fileName}
          </AnchorInput>
        ),
        fileSize: fileDetails.fileSize,
        fileType: fileDetails.fileType,
        dateModified: fileDetails.fileModifiedDate,
      }))


      return (
        <Grid
          {...DOWNLOAD_ORIGINAL_FILE_GRID_COLUMN}
          // isFixedHeader
          maxHeight="350px"
          dataTestId="test-gridDefault"
          records={data || []}
        // onSortGridColumn={(e,state)=>getArgumentsAndSortGrid(state)}
        />
      );
    }
    return '';
  }, [originalFile, isOpenDwnldOrginalFileModal])

  return (
    <>
      {isLoading ? (
        // replace with a better loading component        
        <LoadingPage />
      ) : isError ? (
        // replace with a better error component
        <ErrorPage
          // this prop isn't real but is an example
          error={error}
        />
      ) : (
            <>
              <div className='marginLeft42px'>
                {!noActions && <div className='displayFlex'>
                <Authorizer permission="CAN_PERFORM_FILERECEIPT_RESEND">
                  <Button
                    buttonType="standard"
                    // disabled
                    domID="ResendFileReceipt"
                    name="Resend File Receipt"
                    size="small"
                    type="button"
                    style={{ marginRight: '5px' }}
                    onClick={() => setisOpenResendModal(true)}
                  />
                  </Authorizer>
                  <Authorizer permission="CAN_PERFORM_ORIGINAL_FILE_DOWNLOAD">
                  <Button
                    buttonType="standard"
                    disabled={ABFFile.redirectUri ? 0 : 1}
                    domID="DownloadAbfFile"
                    name="Download ABF Formatted File"
                    size="small"
                    type="button"
                    style={{ marginRight: '5px' }}
                    onClick={() => {
                      downloadFile({ File: ABFFile, button: 'abf' });
                    }}
                  />
                  </Authorizer>
                  <Authorizer permission="CAN_PERFORM_CHECK_REGISTER_DOWNLOAD">
                  <Button
                    buttonType="standard"
                    disabled={checkRegister.redirectUri ? 0 : 1}
                    domID="checkRegister"
                    name="Download Check Register"
                    size="small"
                    type="button"
                    style={{ marginRight: '5px' }}
                    onClick={() => {
                      downloadFile({ File: checkRegister, button: 'checkRegister' });
                    }}
                  />
                  </Authorizer>
                  <Authorizer permission="CAN_PERFORM_ORIGINAL_FILE_DOWNLOAD">
                  {AffFileName ? <div className="downloadBtnLoading">
                    {isLoadingGetOriginalFile ? <LoadingIndicator /> : ''}
                    <Button
                      buttonType="standard"
                      disabled={originalFile.length ? 0 : 1}
                      domID="originalFile"
                      name="Download Original File"
                      size="small"
                      type="button"
                      style={{ marginRight: '5px' }}
                      onClick={() => {
                        originalFileDownload()
                      }}
                    />
                  </div> : ''}
                  </Authorizer>
                </div>}
                <StyledBoxBorder className='fontSize14px' width="95%" height={height} overflow={overflow}>
                  <div className='boxHeader padding4px'>
                    Details for File
                {' '}
                    {filekey}
                ...
              </div>
                  <pre className='padding4px'>
                    {details}
                  </pre>
                </StyledBoxBorder>
              </div>
              <FileReceiptManagerResend
                filekey={filekey}
                isOpen={isOpenResendModal}
                closeModal={() => setisOpenResendModal(false)}
                sendResponseMessage={(data) => {
                  if (data !== true)
                    notify(data, 'positive')
                  else
                    notify('Error in sending the File Receipt. There is no Email associated', 'negative')
                }}
              />

              <Modal
                isOpen={isOpenDwnldOrginalFileModal}
                height="74vh"
              >
                <ModalHeader
                  title="Original File"
                  onClose={() => setisOpenDwnldOrginalFileModal(false)}
                />
                <ModalBody>
                  {isOpenDwnldOrginalFileModal ? (
                    <div
                      className='GridWrapper'
                      style={{
                        overflow: "auto",
                        paddingBottom: "49px",
                        paddingLeft: "10px"
                      }}
                    >
                      <div style={{
                        paddingLeft: '25px',
                        paddingRight: '25px',
                        marginTop: '20px',
                        marginBottom: '-10px',
                        maxHeight: '44vh',
                        overflow: 'auto'
                      }}
                      >
                        {renderDwnldOriginalFileDetails}
                      </div>
                    </div>
                  ) : null}
                </ModalBody>
              </Modal>

            </>
          )}
    </>
  );
};

FileReceiptManagerDetailComponent.propTypes = {

};

export default withAxiosCancellation(FileReceiptManagerDetailComponent);
