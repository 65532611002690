import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import { LoadingIndicator, Button } from 'ui-core-ssgr';
import DropDownControl from '../ModalControls/DropDownControl/index';
import {
  ContainerBox,
  ProviderInsuredOptionMessageTextArea,
} from './styles';
import { LoadingWrapper } from '../../RulesTabView/components/RuleDetails/RuleDetailsGridWrapper/styles';
import { getRuleVersion, getInitialMessageModalValues } from '../utils';
import {
  ReadOnlyTextTitle,
  ReadOnlyTextArea,
  ButtonGroupBottom,
  ModalButton
} from '../../../shared/styles/styledComponents';

const ProviderInsuredOptionMessageModal = ({
  setIsCloningRuleDetail,
  setIsPostingRuleDetail,
  isShowingProviderInsuredOptionMessageModal,
  isCloningRuleDetail,
  isPostingRuleDetail,
  postRuleDetails,
  putRuleDetails,
  selectedRuleDetail,
  selectedRuleRecord,
  selectedRuleTypeId,
  selectedRecord,
  loading,
  detailElements,
  setIsShowingProviderInsuredOptionMessageModal,
  actionType,
  setIsReadOnlyRuleDetail
}) => {
  const [claimType, setClaimType] = useState('default');
  const [recipientType, setRecipientType] = useState('default');
  const [documentType, setDocumentType] = useState('default');
  const [message, setMessage] = useState('');
  const [providerInsuredOptionMessageError, setProviderInsuredOptionsMessageError] = useState(false);
  const [providerInsuredOptionMessageErrorMessage, setProviderInsuredOptionMessageErrorMessage] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const validateMessage = userMessage => {
    let providerInsuredOptionMessageErrorValue = false;
    let providerInsuredOptionMessageErrorMessageValue = '';
    if (!userMessage || /^\s*$/.test(userMessage)) {
      providerInsuredOptionMessageErrorValue = true;
      providerInsuredOptionMessageErrorMessageValue = 'Message required with minimum of 1 character';
    } else if (userMessage.length > 4000) {
      providerInsuredOptionMessageErrorValue = true;
      providerInsuredOptionMessageErrorMessageValue = 'Max 4000 characters';
    }
    setProviderInsuredOptionsMessageError(providerInsuredOptionMessageErrorValue);
    setProviderInsuredOptionMessageErrorMessage(providerInsuredOptionMessageErrorMessageValue);
  };

  // This use effect is for the componentDidMount functionality
  useEffect(() => {
    if (isPostingRuleDetail) {
      setProviderInsuredOptionsMessageError(true);
      setProviderInsuredOptionMessageErrorMessage('Message required with minimum of 1 character');
    }
    const {
      claimTypeValue,
      recipientTypeValue,
      documentTypeValue,
      messageValue,
      buttonDisabled
    } = getInitialMessageModalValues({ isPostingRuleDetail, selectedRuleDetail, detailElements });

    setClaimType(claimTypeValue);
    setRecipientType(recipientTypeValue);
    setDocumentType(documentTypeValue);
    setMessage(messageValue);
    setIsButtonDisabled(buttonDisabled);
  }, []);

  // This use effect is for the componentDidUpdate functionality
  useEffect(() => {
    const {
      claimTypeValue,
      recipientTypeValue,
      documentTypeValue,
      messageValue,
      buttonDisabled
    } = getInitialMessageModalValues({ isPostingRuleDetail, selectedRuleDetail, detailElements });

    setClaimType(claimTypeValue);
    setRecipientType(recipientTypeValue);
    setDocumentType(documentTypeValue);
    setMessage(messageValue);
    setIsButtonDisabled(buttonDisabled);
  }, [detailElements, isShowingProviderInsuredOptionMessageModal]);

  useEffect(() => {
    const claimTypeDbCode = populateCodeFromValue('claimTypesList');
    const recipientTypeDbCode = populateCodeFromValue('recipientTypesList');
    const documentTypeDbCode = populateCodeFromValue('documentTypesList');
    const messageDbCode = selectedRuleDetail.message;
    const getDbCodes = {
      claimTypeDbCode,
      recipientTypeDbCode,
      documentTypeDbCode,
      messageDbCode,
    };
    setButtonDisableOnDefault(getDbCodes); 
  }, [claimType, documentType, recipientType, message]);

  const populateCodeFromValue = name => {
    let selectedCode = '';

    if (!isEmpty(detailElements)) {
      if (name) {
        const selectedName = name.replace('List', '');
        const replaceSelectedName = selectedName.indexOf('Types') ? selectedName.replace('Types', 'Type') : selectedName;
        const selectedValue = selectedRuleDetail[replaceSelectedName];
        let selectedList = '';

        if (name === 'claimTypesList') selectedList = 'claimTypesList';
        if (name === 'recipientTypesList') selectedList = 'recipientTypesList';
        if (name === 'documentTypesList') selectedList = 'documentTypesList';

        const detailListItem = detailElements[selectedList];
        const firstValue = find(detailListItem, x =>
          x.value.includes(selectedValue),
        ) || {};
        const arr = firstValue
          ? [...new Set([firstValue, ...detailListItem])]
          : detailListItem;
        arr.map(r => {
          if (r.value === selectedValue) {
            selectedCode = r.code;
          }
          return {
            label: r.value,
            value: r.code,
          };
        });
      }
    }
    return selectedCode;
  };

  const populateDataDropDown = name => {
    let options = [];

    if (!isEmpty(detailElements)) {
      let selectedList = '';
      const selectedValue = selectedRuleDetail[name];

      if (name === 'claimTypesList') selectedList = 'claimTypesList';
      if (name === 'recipientTypesList') selectedList = 'recipientTypesList';
      if (name === 'documentTypesList') selectedList = 'documentTypesList';
      const firstValue = detailElements[selectedList].find(x =>
        x.value.includes(selectedValue),
      );
      const arr = firstValue
        ? [...new Set([firstValue, ...detailElements[selectedList]])]
        : detailElements[selectedList];
      options = arr
        .map(r => ({
          label: r.value,
          value: r.code,
        }))
        .filter(v => v.value);
    }
    return options
  };

  const onCancel = () => {
    setIsShowingProviderInsuredOptionMessageModal(false);
    setIsCloningRuleDetail(false);
    setIsPostingRuleDetail(false);
    setIsReadOnlyRuleDetail(false);
  };

  const onSave = () => {
    const record = selectedRuleRecord || selectedRecord;
    const ruleDetailId = get(selectedRuleDetail, 'id', '');
    const ruleId = get(record, 'id', '');
    const ruleVersion = getRuleVersion(record);
    const ruleType = selectedRuleTypeId;
    let ruleDetail = {
      claimType,
      recipientType,
      documentType,
      message,
    };
    ruleDetail = isCloningRuleDetail
      ? { ...ruleDetail, ...{ ruleDetailIdFrom: ruleDetailId } }
      : ruleDetail;
    if (isPostingRuleDetail || isCloningRuleDetail) {
      postRuleDetails({ ruleDetail, ruleType, ruleId, ruleVersion });
      setIsCloningRuleDetail(false);
      setIsPostingRuleDetail(false);
    } else {
      putRuleDetails({
        ruleDetail,
        ruleType,
        ruleId,
        ruleVersion,
        ruleDetailId,
      });
    }
  };

  const setButtonDisableOnDefault = getDbCodes => {
    let buttonDisabled = false;
    if (
      (actionType === 'Edit a' &&
        (claimType === getDbCodes.claimTypeDbCode &&
          recipientType === getDbCodes.recipientTypeDbCode &&
          documentType === getDbCodes.documentTypeDbCode &&
          message === getDbCodes.messageDbCode)) ||
      (actionType === 'Clone a' &&
        (claimType === getDbCodes.claimTypeDbCode &&
          recipientType === getDbCodes.recipientTypeDbCode &&
          documentType === getDbCodes.documentTypeDbCode))
    ) {
      buttonDisabled = true;
    }
    setIsButtonDisabled(buttonDisabled);
  }

  const checkUnsavedChanges = event => {
    if (event.target) {
      const { name, value } = event.target;
      switch(name) {
        case 'message':
          validateMessage(value);
          setMessage(value);
          break;
        case 'claimType': 
          setClaimType(value);
          break;
        case 'recipientType':
          setRecipientType(value);
          break;
        case 'documentType':
          setDocumentType(value);
          break;
        default:
          break;
      }     
    }
  };

  // used for read only purpose
  const pullValuefromCode = (code, selectedList) => {
    let selectedCode = '';
    if (!isEmpty(code)) {
      const selectedName = selectedList.replace('List', '');
      const replaceSelectedName = selectedName.indexOf('Types') ? selectedName.replace('Types', 'Type') : selectedName;
      const selectedValue = selectedRuleDetail[replaceSelectedName];
      const detailListItem = detailElements[selectedList];
      const firstValue = find(detailListItem, x =>
        x.value.includes(selectedValue),
      ) || {};
      const arr = firstValue
        ? [...new Set([firstValue, ...detailListItem])]
        : detailListItem;
      arr.map(r => {
        if (r.value === selectedValue) {
          selectedCode = r.value;
        }
        return {
          label: r.value,
          value: r.code,
        };
      });
    }
    return selectedCode;
  }

  const recordId = get(selectedRuleRecord, 'id', '')|| get(selectedRecord, 'id', '');

  // FOR READ ONLY CONDITIONAL RENDERING (Live Rule)
  let claimTypeReadOnly = '';
  let recipientTypeReadOnly = '';
  let documentTypeReadOnly = '';
  let messageReadOnly = '';

  if(actionType === 'Read only'){
    claimTypeReadOnly = (claimType !== 'default') ? pullValuefromCode(claimType, 'claimTypesList') : 'Any';
    recipientTypeReadOnly = (recipientType !== 'default') ? pullValuefromCode(recipientType,'recipientTypesList') : 'Any';
    documentTypeReadOnly = (documentType !== 'default') ? pullValuefromCode(documentType,'documentTypesList') : 'Any';
    messageReadOnly = (message !== 'default') ? message : '-';
  }

  return (
    <>
      <ContainerBox>
        {actionType !== 'Read only' ? (
          <DropDownControl
            dropDownDomID="provider-insured-option-message-claim-type"
            dropDownLabel="claim type"
            dropDownName="claimType"
            dropDownOptions={populateDataDropDown('claimTypesList')}
            dropDownInitialValue={claimType}
            dropDownOnChangeHandle={checkUnsavedChanges}
          />
        ) : <ReadOnlyTextTitle>Claim Type<ReadOnlyTextArea>{claimTypeReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
        {actionType !== 'Read only' ? (
          <DropDownControl
            dropDownDomID="provider-insured-option-message-recipient-type"
            dropDownLabel="recipient"
            dropDownName="recipientType"
            dropDownOptions={populateDataDropDown('recipientTypesList')}
            dropDownInitialValue={recipientType}
            dropDownOnChangeHandle={checkUnsavedChanges}
          />
        ) :  <ReadOnlyTextTitle>Recipient Type<ReadOnlyTextArea>{recipientTypeReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
        {actionType !== 'Read only' ? (
          <DropDownControl
            dropDownDomID="provider-insured-option-message-document-type"
            dropDownLabel="document type"
            dropDownName="documentType"
            dropDownOptions={populateDataDropDown('documentTypesList')}
            dropDownInitialValue={documentType}
            dropDownOnChangeHandle={checkUnsavedChanges}
          />
        ) : <ReadOnlyTextTitle>Document Type<ReadOnlyTextArea>{documentTypeReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
      </ContainerBox>
      <ContainerBox>
        {actionType !== 'Read only' ? (
          <ProviderInsuredOptionMessageTextArea
            domID="provider-insured-option-message-message"
            label="message"
            name="message"
            placeholder="Fill out your message here"
            initialValue={message}
            onChange={checkUnsavedChanges}
            errorMessage={providerInsuredOptionMessageErrorMessage}
            hasError={providerInsuredOptionMessageError}
            hasCounter
            maxLength={4001}
          />
        ) : <ReadOnlyTextTitle>Message<ReadOnlyTextArea>{messageReadOnly}</ReadOnlyTextArea></ReadOnlyTextTitle>}
      </ContainerBox>
      <ButtonGroupBottom>
        <ModalButton>
          {loading.has(recordId) && (
            <LoadingWrapper>
              <LoadingIndicator
                domID="basic-details-form-loading-indicator"
                length="30px"
              />
            </LoadingWrapper>
          )}
          {actionType !== 'Read only' ? (
            <Button
              onClick={onSave}
              domID="automation-id"
              name="Save"
              buttonType="emphasized"
              size="medium"
              type="button"
              disabled={
                isButtonDisabled ||
              loading.has(recordId) ||
              providerInsuredOptionMessageError ||
              message === '' ||
              message === undefined
              }
            />) : null }
          <Button
            domID="automation-id"
            name={actionType !== 'Read only' ? 'Cancel' : 'Close'}
            buttonType="diminished"
            disabled={loading.has(recordId)}
            size="medium"
            onClick={onCancel}
            type="button"
          />
        </ModalButton>
      </ButtonGroupBottom>
    </>
  );
}

ProviderInsuredOptionMessageModal.propTypes = {
  detailElements: PropTypes.object,
  loading: PropTypes.object,
  selectedRuleRecord: PropTypes.object,
  selectedRuleDetail: PropTypes.object,
  selectedRecord: PropTypes.object,
  setIsCloningRuleDetail: PropTypes.func,
  setIsPostingRuleDetail: PropTypes.func,
  setIsShowingProviderInsuredOptionMessageModal: PropTypes.func,
  isCloningRuleDetail: PropTypes.bool,
  isShowingProviderInsuredOptionMessageModal: PropTypes.bool,
  isPostingRuleDetail: PropTypes.bool,
  postRuleDetails: PropTypes.func,
  putRuleDetails: PropTypes.func,
  actionType: PropTypes.string,
  selectedRuleTypeId: PropTypes.string,
};

export default ProviderInsuredOptionMessageModal;
