  const initialState = {
    manifestEntrySelectedRecord:null,
    refetchManifestEntryList:()=>false,
  }
  
  export default (state = initialState, { type, payload }) => {
    switch(type) {
      case 'SET_MANIFEST_ENTRY_RECORD':
        return {
          ...state,
          manifestEntrySelectedRecord:JSON.parse(JSON.stringify(payload.manifestEntrySelectedRecord)),
          refetchManifestEntryList:payload.refetchManifestEntryList
        };
      case 'RESET_MANIFEST_ENTRY_RECORD':
        return {
          ...state,
          manifestEntrySelectedRecord:null,
          refetchManifestEntryList:()=>false
        };
      default:
        return state;
    }
  }