import React from 'react';
import PropTypes from 'prop-types';

import LayoutButton from '../styles/LayoutButton';
import Close from '../../../../assets/icons/svgComponents/Close';

const svgMap = {
  close: Close,
};

const ButtonWrapper = (props) => {
  const SvgComponent = svgMap[props.buttonType];
  return (
    <LayoutButton onClick={e => props.onClick(e)}>
      <SvgComponent length="36px" />
    </LayoutButton>
  );
};

ButtonWrapper.defaultProps = {
  buttonType: 'popout',
  onClick: () => false,
};

ButtonWrapper.propTypes = {
  buttonType: PropTypes.oneOf(['close']),
  onClick: PropTypes.func,
};

export default ButtonWrapper;
