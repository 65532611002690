import { useEffect, useRef, useState, useCallback } from 'react';
import { useAxios, useAxiosPost } from '../hooks';

const useClientAdministrationDocumentTypeData=()=>{
  const [totalRecords,setTotalRecords]=useState(0)
  const [records, setRecords]=useState([]);
  const {postData, isLoading,isError,error, data }=useAxiosPost({
    url:'clientAdministration/getDocumentTypeTranslations',
    method:'POST',
    onError: (e) => 
    // what happens on error? if you need something extra
    // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  
  useEffect(()=>{
    if (data) {
      const {collection}=data;
      if (collection && collection.$) {
        const {totalRecords: totalRecordsp, type}=collection.$
        if (type && collection[type]) {
          setTotalRecords(totalRecordsp)  
          const sr=JSON.parse(JSON.stringify(collection[type]))  
          let searchResult=[]
          if (sr.constructor.name==="Array") {
            searchResult=[...sr]
          } else if(sr.constructor.name==="Object"){
            searchResult.push({...sr})
          }
          if (searchResult && searchResult.length>0) {
            setRecords(JSON.parse(JSON.stringify(searchResult)))  
          } else{
            setTotalRecords(0);
            setRecords(JSON.parse(JSON.stringify([])))  
          }
        } else{
          setTotalRecords(0);
          setRecords(JSON.parse(JSON.stringify([])))
        }
      }
    }
  },[data])
  return {totalRecords,records,postData, isLoading, isError, error};
}

const useDocumentTypeTranslationDetails=()=>{
  const [showMoreInfo, setshowMoreInfo]=useState(null)
  const {postData, isLoading,isError,error, data }=useAxiosPost({
    url:'clientAdministration/getDocumentTypeTranslation',
    method:'POST',
    onError: (e) => 
    // what happens on error? if you need something extra
    // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  
  useEffect(()=>{
    if (data && data['document-type-translation'] && data['document-type-translation']['document-type-translation']) {
        
      setshowMoreInfo(JSON.parse(JSON.stringify(data['document-type-translation']['document-type-translation'])))
    } else{
      setshowMoreInfo(null);
    }
  },[data])
  
  return {showMoreInfo, isLoading, isError, error, postData};
}

const useGetSysDocumentTypes=()=>{
  const {directPostCall, isLoading,isError,error }=useAxiosPost({
    url:'clientAdministration/getSysDocumentTypes',
    method:'POST',
    onError: (e) => 
    // what happens on error? if you need something extra
    // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  
  
  return { isLoading, isError, error, directPostCall};
}

const useAddDocumentTypeTranslation=()=>{
  const {postData, isLoading,isError,error, metaData, data }=useAxiosPost({
    url:'clientAdministration/addDocumentTypeTranslation',
    method:'POST',
    onError: (e) => 
    // what happens on error? if you need something extra
    // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  
  
  return { isLoading, isError, error, postData, metaData, data};
}

const useUpdateDocumentTypeTranslation=()=>{
  const {postData, isLoading,isError,error,metaData, data }=useAxiosPost({
    url:'clientAdministration/updateDocumentTypeTranslation',
    method:'POST',
    onError: (e) => 
    // what happens on error? if you need something extra
    // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  
  
  return { isLoading, isError, error, postData, metaData, data};
}

const useDeleteDocumentTypeTranslation=()=>{
  const {postData, metaData, isLoading,isError,error, data }=useAxiosPost({
    url:'clientAdministration/deleteDocumentTypeTranslation',
    method:'POST',
    onError: (e) => 
    // what happens on error? if you need something extra
    // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  
  
  return { isLoading, isError, error, postData, metaData, data};
}

export {
  useClientAdministrationDocumentTypeData,
  useDocumentTypeTranslationDetails,
  useGetSysDocumentTypes,
  useAddDocumentTypeTranslation,
  useUpdateDocumentTypeTranslation,
  useDeleteDocumentTypeTranslation
}