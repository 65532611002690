import styled from 'styled-components';

import { Input, Button } from 'ui-core-ssgr';
import ButtonGroup from '../../../../shared/components/ButtonGroup';

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 1em 1em 0 1em;
`;

export const RuleNameInputWrapper = styled.div`
  flex: 1 0 0;
  padding: 2.5em 2em 1.5em 1em;
`;

export const RuleDescriptionInputWrapper = styled.div`
  flex: 2 0 0;
  padding: 2.5em 1em 1.5em 1em;
`;

export const RuleNameInput = styled(Input)`
  width: 100%;
  & span[class^='ErrorMessage'] {
    position: absolute;
  }
`;

export const RuleDescriptionInput = styled(Input)`
  width: 100%;
  & span[class^='ErrorMessage'] {
    position: absolute;
  }
`;

export const InputLabelWrapper = styled.div`
  text-transform: uppercase;
  font-size: 11px;
  margin-bottom: 0.3rem;
  line-height: 1.4em;
  font-weight: 700;
  letter-spacing: 0.5px;
`;

export const LoadingWrapper = styled.div`
  float: left;
  margin-left: -28px;
  margin-top: 3px;
`;

export const CommitRuleButton = styled(Button)`
  position: absolute;
  right: 2.5em;
`;

export const RuleTabFormButtonGroup = styled(ButtonGroup)`
  padding: 0.5rem 2rem;
  position: fixed;
  margin-left: -13px;
  bottom: -6px;
  width: 100%;
  border-top: 1px solid #ccc;
  background: #fff;
  z-index: 1;
  @media all and (-ms-high-contrast: none) {
    margin-left: -419px;
  }
`;
