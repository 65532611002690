import Api from '..';
import get from 'lodash/get';
import { API_BASE } from '../constants';

// ------------- Get Rules count and queryValue count
export const getRulesCountFromDB = async (
  { clientLayoutId, userSecurityTokenKey, username, roleId },
  {
    sortOrder = 'desc',
    name = 'dateModified',
    offset = 0,
    ruleTypeId = '',
    queryValue = 0,
  },
) => {
  try {
    let baseUrl = `${API_BASE}/ruleSummary/${clientLayoutId}/${ruleTypeId}/count?offset=${offset}`;
    const safeSearchQuery = queryValue ? `${queryValue}` : '';

    baseUrl = safeSearchQuery ? `${baseUrl}&keyword=${safeSearchQuery}` : `${baseUrl}&sortColumn=${name}&sortOrder=${sortOrder}`;

    const api = new Api(userSecurityTokenKey, username, null, roleId);
    const getRulesCountFromDBResponse = await api.get(baseUrl);

    if (getRulesCountFromDBResponse.error) {
      throw getRulesCountFromDBResponse.error;
    }
    return getRulesCountFromDBResponse;
  } catch (error) {
    throw error;
  }
};

// -------- Request for all rules & for search query ------------------ //
export const getRulesForRuleTypesList = async (
  { clientLayoutId, userSecurityTokenKey, username, roleId },
  {
    sortOrder = 'desc',
    name = 'dateModified',
    offset = 0,
    ruleTypeId = '',
    queryValue = '',
  },
  selectedRuleTypeId,
) => {
  try {
    let baseUrl = '';
    if (ruleTypeId.includes('http')) {
      baseUrl = `${API_BASE}/ruleSummary/${clientLayoutId}/${selectedRuleTypeId}`;
    } else {
      baseUrl = `${API_BASE}/ruleSummary/${clientLayoutId}/${ruleTypeId}`;
    }

    if (queryValue) {
      baseUrl = `${baseUrl}?offset=${offset}`
    } else {
      baseUrl = `${baseUrl}?sortColumn=${name}&sortOrder=${sortOrder}&offset=${offset}`;
    }

    const safeSearchQuery = queryValue ? `${queryValue}` : '';
    if (safeSearchQuery) baseUrl = `${baseUrl}&keyword=${safeSearchQuery}`;

    const api = new Api(userSecurityTokenKey, username, null, roleId);
    const getRulesForRuleTypesListResponse = await api.get(baseUrl);

    if (getRulesForRuleTypesListResponse.error) {
      throw getRulesForRuleTypesListResponse.error;
    }
    return getRulesForRuleTypesListResponse;
  } catch (error) {
    throw error;
  }
};

export const getGroupAssociationsForRuleIds = async (
  ruleIds,
  clientLayoutId,
  userSecurityTokenKey,
  username,
  ruleType,
  roleId,
) => {
  try {
    const baseUrl = `${API_BASE}/associations/${clientLayoutId}/${ruleType}`;
    const ids = JSON.stringify(ruleIds);
    const api = new Api(userSecurityTokenKey, username, null, roleId);
    const getGroupAssociationsForRuleIdsResponse = await api.post(baseUrl, ids);

    if (getGroupAssociationsForRuleIdsResponse.error) {
      throw getGroupAssociationsForRuleIdsResponse.error;
    }
    return get(getGroupAssociationsForRuleIdsResponse, 'res', []);
  } catch (error) {
    throw error;
  }
}

export const getGroupAssociationsForRule = async (
  clientLayoutId,
  userSecurityTokenKey,
  username,
  ruleType,
  roleId,
  { ruleId },
) => {
  try {
    const baseUrl = `${API_BASE}/rules/${clientLayoutId}/${ruleType}/${ruleId}/associations?sortOrder=asc`;
    const api = new Api(userSecurityTokenKey, username, null, roleId);
    const getGroupAssociationsForRuleResponse = await api.get(baseUrl);

    if (getGroupAssociationsForRuleResponse.error) {
      throw getGroupAssociationsForRuleResponse.error;
    }
    return getGroupAssociationsForRuleResponse;
  } catch (error) {
    throw error;
  }
};

export const postGroupAssociationsForRuleTypeTrigger = async (
  { userSecurityTokenKey, username, clientLayoutId, roleId },
  { groupId, selectedRules },
) => {
  try {
    const requestUrl = `${API_BASE}/associations/${clientLayoutId}/${groupId}`;
    const requestBody = JSON.stringify({
      rulesArray: selectedRules
    });
    const api = new Api(userSecurityTokenKey, username, null, roleId);
    const postGroupAssociationsForRuleTypeResponse = await api.post(requestUrl, requestBody);
  
    if (postGroupAssociationsForRuleTypeResponse.error) {
      throw postGroupAssociationsForRuleTypeResponse.error;
    }
    return postGroupAssociationsForRuleTypeResponse;
  } catch(error) {
    throw error;
  }
};

export default {
  getRulesForRuleTypesList,
  getGroupAssociationsForRuleIds,
  getRulesCountFromDB,
  getGroupAssociationsForRule,
  postGroupAssociationsForRuleTypeTrigger,
};
