export const detailKeys = [
  'claimTypesList',
  'checkFormList',
  'customCoverSheetFormList',
  'recipientTypesList',
  'deliveryAddressList',
  'eobFormList',
  'customCoverSheetLevelList',
  'documentTypesList',
  'deliveryMethodList',
];
