import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Warning, LoadingIndicator } from 'ui-core-ssgr';
import { connect } from 'react-redux';
import ButtonGroup from '../../../../shared/components/ButtonGroup';
import Colors from '../../../../shared/styles/colors';
import { 
  ModalPaddingWrapper, 
  BoldTextAfterWarningIcon, 
  IconWrapper, 
  ModalTitlePaddingWrapper, 
  StyledHeader,
} from '../../../../shared/styles/styledComponents';
import { LoadingWrapper } from './styles';
import { selectLoadingState, selectCurrentSelectedGroupNames  } from './selectors';
import { DivWithPaddingTop } from '../../../../shared/styles/spacing';

const DeactivateGroupModal = ({
  selectedRecord,
  isShowingDeactivateGroupModal,
  loading,
  groupNames,
  isMulti,
  setIsShowingDeactivateGroupModal,
  triggerDeactivateGroupRequest,
  selectedItemIds,
}) => {
  const onButtonClick = name => () => {
    const groupId = isMulti ? [...selectedItemIds] : [selectedRecord.id];
    if (name === 'cancel') setIsShowingDeactivateGroupModal(false);
    if (name === 'confirm') {
      triggerDeactivateGroupRequest({
        groupId,
        isMulti,
        isActive: 'false',
        groupName: isMulti ? groupNames : selectedRecord.name,
      });
    }
  };

  return (
    <Modal
      domID="modal-id"
      onModalToggle={() => setIsShowingDeactivateGroupModal(false)}
      isOpen={isShowingDeactivateGroupModal}
      showDefaultClose
      deferBodyControl={false}
    >
      <ModalPaddingWrapper>
        <ModalTitlePaddingWrapper>
          <StyledHeader>{isMulti ? `Deactivating groups: ${groupNames}` :
            `Deactivating a group: ${(selectedRecord && selectedRecord.name)}`}
          </StyledHeader> 
        </ModalTitlePaddingWrapper>
        <IconWrapper>
          <Warning fillColor={Colors.negativeDark} />
        </IconWrapper>
        <BoldTextAfterWarningIcon>
          {`This  will deactivate the selected group(s). Once deactivated, documents will no longer be able to process
              under the selected group(s) until they have been reactivated.`}
        </BoldTextAfterWarningIcon>
        <DivWithPaddingTop>
          <ButtonGroup>
            <LoadingWrapper>
              {loading > 0 && (
                <LoadingIndicator
                  domID="deactivate-groups-modal-loading-indicator"
                  length="30px"
                />
              )}
            </LoadingWrapper>
            <Button
              name="confirm"
              buttonType="emphasized"
              onClick={onButtonClick('confirm')}
              size="medium"
              type="button"
              disabled={loading > 0}
            />
            <Button
              name="cancel"
              buttonType="diminished"
              onClick={onButtonClick('cancel')}
              size="medium"
              type="button"
              disabled={loading > 0}
            />
          </ButtonGroup>
        </DivWithPaddingTop>
      </ModalPaddingWrapper>
    </Modal>
  );
}

DeactivateGroupModal.propTypes = {
  selectedRecord: PropTypes.object,
  setIsShowingDeactivateGroupModal: PropTypes.func,
  loading: PropTypes.number,
  triggerDeactivateGroupRequest: PropTypes.func,
  isShowingDeactivateGroupModal: PropTypes.bool,
  selectedItemIds: PropTypes.object,
  isMulti: PropTypes.bool,
  groupNames: PropTypes.array

};

const mapStateToProps = state => ({
  loading: selectLoadingState(state),
  groupNames:  selectCurrentSelectedGroupNames(state)
});

export default connect(mapStateToProps)(DeactivateGroupModal);
