import Api from '..';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { API_BASE } from '../constants';

export const getRuleDetailElements = async (
  { userSecurityTokenKey, username, clientLayoutId, roleId },
  { selectedRuleTypeId },
) => {
  try {
    const baseUrl = `${API_BASE}/ruleDetailElements/${clientLayoutId}/${selectedRuleTypeId}`;
    const api = new Api(userSecurityTokenKey, username, null, roleId);
    const getRuleDetailElementsResponse = await api.get(baseUrl);

    if (getRuleDetailElementsResponse.error) {
      throw getRuleDetailElementsResponse.error;
    } else {
      return getRuleDetailElementsResponse;
    }
  } catch (error) {
    throw error;
  }
};

// POST rule detail (add or clone)
export const postRuleDetails = async (
  { userSecurityTokenKey, username, clientLayoutId, roleId },
  { ruleDetail, ruleType, ruleId, ruleVersion },
) => {
  try {
    // Checking to see if the details consists of a message or not
    const detailMessagePresent = get(ruleDetail, 'message', '');
    // If the message is present, we will then encode before sending to the backend
    // If the message is not present then it will just be sent as is
    const detailToPost = detailMessagePresent
      ? {
        ...ruleDetail,
        message: detailMessagePresent,
      }
      : ruleDetail;
    const baseUrl = `${API_BASE}/ruleDetails/${clientLayoutId}/${ruleType}/${ruleId}/${ruleVersion}`;
    const api = new Api(userSecurityTokenKey, username, null, roleId);
    const postRuleDetailsResponse = await api.post(baseUrl, JSON.stringify(detailToPost));

    if (postRuleDetailsResponse.error) {
      throw postRuleDetailsResponse.error;
    } else {
      return postRuleDetailsResponse;
    }
  } catch (error) {
    throw error;
  }
};

// PUT rule detail (edit)
export const putRuleDetails = async (
  { userSecurityTokenKey, username, clientLayoutId, roleId },
  { ruleDetail, ruleType, ruleId, ruleVersion, ruleDetailId },
) => {
  try {
    // Checking to see if the details consists of a message or not
    const detailMessagePresent = get(ruleDetail, 'message', '');
    // If the message is present, we will then encode before sending to the backend
    // If the message is not present then it will just be sent as is
    const detailToPut = detailMessagePresent
      ? {
        ...ruleDetail,
        message: detailMessagePresent,
      }
      : ruleDetail;
    const baseUrl = `${API_BASE}/ruleDetails/${clientLayoutId}/${ruleType}/${ruleId}/${ruleVersion}/${ruleDetailId}`;
    const api = new Api(userSecurityTokenKey, username, null, roleId);
    const putRuleDetailsResponse = await api.put(baseUrl, JSON.stringify(detailToPut));

    if (putRuleDetailsResponse.error) {
      throw putRuleDetailsResponse.error;
    } else {
      return putRuleDetailsResponse;
    }
  } catch (error) {
    throw error;
  }
};

// --------------- GET :: Addd new address (return address rule type) ------------ //
export const getAddNewAddressElements = async ({
  userSecurityTokenKey,
  username,
  clientLayoutId,
  dataSourceDefinition,
  roleId,
}) => {
  try {
    const apiUrlClientTables = getClientTablesUrl();
    const baseUrl = `${apiUrlClientTables}/returnAddressElements/${clientLayoutId}`;
    const api = new Api(userSecurityTokenKey, username, dataSourceDefinition, roleId);
    const getAddNewAddressElementsResponse = await api.get(baseUrl);

    if (getAddNewAddressElementsResponse.error) {
      throw getAddNewAddressElementsResponse.error;
    } else {
      return getAddNewAddressElementsResponse;
    }
  } catch (error) {
    throw error;
  }
};

// --------------- POST :: Addd new address (return address rule type) ------------ //
export const postAddNewAddressElements = async (
  { userSecurityTokenKey, username, clientLayoutId, dataSourceDefinition, roleId },
  { ruleDetail },
) => {
  try {
    const apiUrlClientTables = getClientTablesUrl(); 
    const baseUrl = `${apiUrlClientTables}/returnAddresses/${clientLayoutId}`;
    const api = new Api(userSecurityTokenKey, username, dataSourceDefinition, roleId);
    const postAddNewAddressElementsResponse = await api.post(baseUrl, JSON.stringify(ruleDetail));
  
    if (postAddNewAddressElementsResponse.error) {
      throw postAddNewAddressElementsResponse.error;
    } else {
      return postAddNewAddressElementsResponse;
    }
  } catch (error) {
    throw error;
  }
};

// --------------- POST :: Upload new image to image library ------------ //
export const postImageUpload = async (
  { userSecurityTokenKey, username, clientLayoutId, roleId },
  {imageData, imageName}) => {
  try {
    let {imageId} = imageData;
    imageId = String(imageId);
    const url = `${API_BASE}/ruleImages/${clientLayoutId}`;
    const api = new Api(userSecurityTokenKey, username, null, roleId);
    const postImageUploadResponse = await api.post(url, JSON.stringify({imageId, imageName}));
    if (postImageUploadResponse.error) {
      throw postImageUploadResponse.error;
    } else {
      return postImageUploadResponse;
    }
  }
  catch (error) {
    throw error;
  }
};

// --------------- GET :: Get Rule Images to show in Image Library Modal ------------ //
export const getRuleImages = async (
  { userSecurityTokenKey, username, clientLayoutId, roleId },
  { selectedRuleTypeId, offset, keyword },
) => {
  try {
    let baseUrl = `${API_BASE}/ruleImages/${clientLayoutId}/${selectedRuleTypeId}`;
    if (!isEmpty(keyword)) {
      baseUrl = `${baseUrl}?keyword=${keyword}`;
      if (offset && offset > 0) {
        baseUrl = `${baseUrl}&offset=${offset}`;
      }
    } else if (offset && offset > 0) {
      baseUrl = `${baseUrl}?offset=${offset}`;
    }
    const api = new Api(userSecurityTokenKey, username, null, roleId);
    const getRuleImagesResponse = await api.get(baseUrl);  
    if (getRuleImagesResponse.error) {
      throw getRuleImagesResponse.error;
    } else {
      return getRuleImagesResponse;
    }
  } catch (error) {
    throw error;
  }
};

// --------------- GET :: Get count of Rule Images to show in Image Library Modal for pagination ------------ //
export const getRuleImagesCount = async (
  { userSecurityTokenKey, username, clientLayoutId, roleId },
  { selectedRuleTypeId, keyword },
) => {
  try {
    let baseUrl =  `${API_BASE}/ruleImages/${clientLayoutId}/${selectedRuleTypeId}:count`;
    if (!isEmpty(keyword)) {
      baseUrl = `${baseUrl}?keyword=${keyword}`;
    }
    const api = new Api(userSecurityTokenKey, username, null, roleId);
    const getRuleImagesCountResponse = await api.get(baseUrl);  
    if (getRuleImagesCountResponse.error) {
      throw getRuleImagesCountResponse.error;
    } else {
      return getRuleImagesCountResponse;
    }
  } catch (error) {
    throw error;
  }
};

// --------------- POST :: Upload new image to image library ------------ //
export const convertImageUpload = async (
  { userSecurityTokenKey, username, clientLayoutId, roleId },
  { selectedRuleTypeId, imageBase64}) => {
  try {
    const url = `${API_BASE}/ruleImages/${clientLayoutId}/${selectedRuleTypeId}:convert`;
    const api = new Api(userSecurityTokenKey, username, null, roleId);
    const convertImageUploadResponse = await api.post(url, JSON.stringify({image: imageBase64}));
    if (convertImageUploadResponse.error) {
      throw convertImageUploadResponse.error;
    } else {
      return convertImageUploadResponse;
    }
  } catch (error) {
    throw error;
  }  
};

// --------------- PUT :: Upload new image to image library ------------ //
export const putCommitImage = async (
  { userSecurityTokenKey, username, clientLayoutId, roleId },
  { imageId, selectedRuleTypeId}) => {
  try {
    const url = `${API_BASE}/ruleImages/${clientLayoutId}`;
    const isReturnAddressLogo = selectedRuleTypeId.toUpperCase() === 'RAD' || false;
    const api = new Api(userSecurityTokenKey, username, null, roleId);
    const putCommitImageResponse = await api.put(url, JSON.stringify({imageId, isReturnAddressLogo}));
    if (putCommitImageResponse.error) {
      throw putCommitImageResponse.error;
    } else {
      return putCommitImageResponse;
    }
  } catch (error) {
    throw error;
  } 
};

const getClientTablesUrl = () => API_BASE.replace('groupRules/v1', 'clientTables/v1').replace(
  'groupRules/v2',
  'clientTables/v1',
);
