import { SORTING_COMPARATOR } from '../../../../../shared/helpers/sorting';

export const RULE_TYPES = [
  'HLD',
  'CHT',
  'CSM',
  'DPX',
  'ERI',
  'GEN',
  'HDR',
  'IMG',
  'SSN',
  'PAR',
  'PIO',
  'RAD',
  'SIG',
  'TAX',
  'PDT',
];

export const DEFAULT_VALUES = {
  claimType: 'Any',
  recipientType: 'Any',
  documentType: 'Any',
};

export const DEFAULT_COLUMNS = [
  {
    dataName: 'claimType',
    text: 'claim type',
    sortable: true,
  },
  {
    dataName: 'recipientType',
    text: 'recipient',
    sortable: true,
  },
  {
    dataName: 'documentType',
    text: 'document type',
    sortable: true,
  },
];

export const AUTO_HOLD_DEFAULT_VALUES = {
  ...DEFAULT_VALUES,
};
export const RETURN_ADDRESS_DEFAULT_VALUES = {
  ...DEFAULT_VALUES,
  returnAddress: undefined,
};
export const CHECK_TEXT_DEFAULT_VALUES = {
  ...DEFAULT_VALUES,
  checkLeftText: '',
  fractionalCodeLeft: '',
  fractionalCodeTop: '',
  fractionalCodeBottom: '',
};
export const CUSTOMER_SERVICE_DEFAULT_VALUES = {
  ...DEFAULT_VALUES,
  message: '',
};
export const DUPLEX_RULE_DEFAULT_VALUES = {
  ...DEFAULT_VALUES,
};
export const ERISA_MESSAGE_RULE_TYPE_DEFAULT_VALUES = {
  ...DEFAULT_VALUES,
  state: 'Any',
  message: undefined,
};
export const GENERAL_MESSAGE_RULE_TYPE_DEFAULT_VALUES = {
  ...DEFAULT_VALUES,
  message: undefined,
};
export const HIGH_DOLLAR_REVIEW_TYPE_DEFAULT_VALUES = {
  ...DEFAULT_VALUES,
  highDollarThreshold: 0,
  blankSignature: false,
  deliveryMethod: undefined,
  deliveryAddress: undefined,
};
export const IMAGE_DEFINITION_RULE_TYPE_DEFAULT_VALUES = {
  ...DEFAULT_VALUES,
  image1: undefined,
};
export const MEMBER_ID_DEFAULT_VALUES = {
  ...DEFAULT_VALUES,
  copyNumber: undefined,
  SSN: undefined,
  deliveryAddress: undefined,
};
export const PRODUCTION_ARRANGEMENT_DEFAULT_VALUES = {
  ...DEFAULT_VALUES,
  copyNumber: undefined,
  deliveryMethod: undefined,
  deliveryAddress: undefined,
  checkForm: undefined,
  eobForm: undefined,
  customCoverSheetForm: undefined,
  customCoverSheetLevel: undefined,
};
export const PROVIDER_INSURED_OPTION_MESSAGE_DEFAULT_VALUES = {
  ...DEFAULT_VALUES,
  message: undefined,
};
export const SIGNATURE_RULE_DEFAULT_VALUES = {
  ...DEFAULT_VALUES,
  signatureThreshold: '0',
};
export const TAX_ID_ARRANGEMENT_DEFAULT_VALUES = {
  ...DEFAULT_VALUES,
  copyNumber: undefined,
  taxIdNumber: undefined,
  deliveryAddress: undefined,
};
export const PDF_TAGGING_RULE_TYPE_DEFAULT_VALUES = {
  ...DEFAULT_VALUES,
  state: 'Any',
};

export const DEFAULT_RECORD_VALUES_BY_RECORD_TYPE = {
  [RULE_TYPES[0]]: AUTO_HOLD_DEFAULT_VALUES,
  [RULE_TYPES[1]]: CHECK_TEXT_DEFAULT_VALUES,
  [RULE_TYPES[2]]: CUSTOMER_SERVICE_DEFAULT_VALUES,
  [RULE_TYPES[3]]: DUPLEX_RULE_DEFAULT_VALUES,
  [RULE_TYPES[4]]: ERISA_MESSAGE_RULE_TYPE_DEFAULT_VALUES,
  [RULE_TYPES[5]]: GENERAL_MESSAGE_RULE_TYPE_DEFAULT_VALUES,
  [RULE_TYPES[6]]: HIGH_DOLLAR_REVIEW_TYPE_DEFAULT_VALUES,
  [RULE_TYPES[7]]: IMAGE_DEFINITION_RULE_TYPE_DEFAULT_VALUES,
  [RULE_TYPES[8]]: MEMBER_ID_DEFAULT_VALUES,
  [RULE_TYPES[9]]: PRODUCTION_ARRANGEMENT_DEFAULT_VALUES,
  [RULE_TYPES[10]]: PROVIDER_INSURED_OPTION_MESSAGE_DEFAULT_VALUES,
  [RULE_TYPES[11]]: RETURN_ADDRESS_DEFAULT_VALUES,
  [RULE_TYPES[12]]: SIGNATURE_RULE_DEFAULT_VALUES,
  [RULE_TYPES[13]]: TAX_ID_ARRANGEMENT_DEFAULT_VALUES,
  [RULE_TYPES[14]]: PDF_TAGGING_RULE_TYPE_DEFAULT_VALUES,
};

export const autoHoldGridConfig = {
  columns: new Set(DEFAULT_COLUMNS),
  selectionKey: 'id',
  domID: 'auto-hold-edit-test-grid',
  onSortTableColumn: () => {},
  supportSelection: true,
  initialSortingKey: 'claimType|SORT_ASCENDING',
  sortingComparator: SORTING_COMPARATOR,
  pageTrackerPrefix: 'RDET-', // rule detail edit test
};

export const checkTextRuleGridConfig = {
  columns: new Set([
    ...DEFAULT_COLUMNS,
    {
      dataName: 'checkLeftText',
      text: 'check text',
      sortable: true,
    },
    {
      dataName: 'fractionalCodeLeft',
      text: 'frc left',
      sortable: true,
    },
    {
      dataName: 'fractionalCodeTop',
      text: 'frc top',
      sortable: true,
    },
    {
      dataName: 'fractionalCodeBottom',
      text: 'frc bottom',
      sortable: true,
    },
  ]),
  selectionKey: 'id',
  domID: 'check-text-edit-test-grid',
  onSortTableColumn: () => {},
  supportSelection: true,
  initialSortingKey: 'claimType|SORT_ASCENDING',
  sortingComparator: SORTING_COMPARATOR,
  pageTrackerPrefix: 'RDET-', // rule detail edit test
};

export const customerServiceMessageGridConfig = {
  columns: new Set([
    ...DEFAULT_COLUMNS,
    {
      dataName: 'message',
      text: 'message',
      sortable: true,
    },
  ]),
  selectionKey: 'id',
  domID: 'customer-service-message-edit-test-grid',
  onSortTableColumn: () => {},
  supportSelection: true,
  initialSortingKey: 'claimType|SORT_ASCENDING',
  sortingComparator: SORTING_COMPARATOR,
  pageTrackerPrefix: 'RDET-', // rule detail edit test
};

export const duplexRuleGridConfig = {
  columns: new Set(DEFAULT_COLUMNS),
  selectionKey: 'id',
  domID: 'duplex-edit-test-grid',
  onSortTableColumn: () => {},
  supportSelection: true,
  initialSortingKey: 'claimType|SORT_ASCENDING',
  sortingComparator: SORTING_COMPARATOR,
  pageTrackerPrefix: 'RDET-',
};

export const erisaMessageGridConfig = {
  columns: new Set([
    ...DEFAULT_COLUMNS,
    {
      dataName: 'state',
      text: 'state',
      sortable: true,
    },
    {
      dataName: 'message',
      text: 'message',
      sortable: true,
    },
  ]),
  selectionKey: 'id',
  domID: 'erisa-message-edit-test-grid',
  onSortTableColumn: () => {},
  supportSelection: true,
  initialSortingKey: 'claimType|SORT_ASCENDING',
  sortingComparator: SORTING_COMPARATOR,
  pageTrackerPrefix: 'RDET-', // rule detail edit test
};

export const generalMessageGridConfig = {
  columns: new Set([
    ...DEFAULT_COLUMNS,
    {
      dataName: 'message',
      text: 'message',
      sortable: true,
    },
  ]),
  selectionKey: 'id',
  domID: 'general-message-edit-test-grid',
  onSortTableColumn: () => {},
  supportSelection: true,
  initialSortingKey: 'claimType|SORT_ASCENDING',
  sortingComparator: SORTING_COMPARATOR,
  pageTrackerPrefix: 'RDET-', // rule detail edit test
};

export const highDollarReviewGridConfig = {
  columns: new Set([
    ...DEFAULT_COLUMNS,
    {
      dataName: 'highDollarThresholdLabel',
      text: 'high dollar threshold',
      sortable: true,
    },
  ]),
  selectionKey: 'id',
  domID: 'high-dollar-review-edit-test-grid',
  onSortTableColumn: () => {},
  supportSelection: true,
  initialSortingKey: 'claimType|SORT_ASCENDING',
  sortingComparator: SORTING_COMPARATOR,
  pageTrackerPrefix: 'RDET-', // rule detail edit test
};

export const imageDefinitionGridConfig = {
  columns: new Set([
    ...DEFAULT_COLUMNS,
    {
      dataName: 'image1',
      text: 'image1',
      sortable: true,
    },
  ]),
  selectionKey: 'id',
  domID: 'image-definition-edit-test-grid',
  onSortTableColumn: () => {},
  supportSelection: true,
  initialSortingKey: 'claimType|SORT_ASCENDING',
  sortingComparator: SORTING_COMPARATOR,
  pageTrackerPrefix: 'RDET-', // rule detail edit test
};

export const memberIdRuleGridConfig = {
  columns: new Set([
    ...DEFAULT_COLUMNS,
    {
      dataName: 'copyNumber',
      text: 'copy number',
      sortable: true,
    },
    {
      dataName: 'SSN',
      text: 'ssn',
      sortable: true,
    },
    {
      dataName: 'deliveryAddress',
      text: 'delivery address',
      sortable: true,
    },
  ]),
  selectionKey: 'id',
  domID: 'member-id-edit-test-grid',
  onSortTableColumn: () => {},
  supportSelection: true,
  initialSortingKey: 'claimType|SORT_ASCENDING',
  sortingComparator: SORTING_COMPARATOR,
  pageTrackerPrefix: 'RDET-', // rule detail edit test
};

export const productionArrangementsGridConfig = {
  columns: new Set([
    ...DEFAULT_COLUMNS,
    {
      dataName: 'copyNumber',
      text: 'copy number',
      sortable: true,
    },
    {
      dataName: 'deliveryMethod',
      text: 'delivery method',
      sortable: true,
    },
    {
      dataName: 'deliveryAddress',
      text: 'delivery address',
      sortable: true,
    },
    {
      dataName: 'checkForm',
      text: 'check form',
      sortable: true,
    },
    {
      dataName: 'eobForm',
      text: 'eob form',
      sortable: true,
    },
    {
      dataName: 'customCoverSheetForm',
      text: 'custom coversheet form',
      sortable: true,
    },
    {
      dataName: 'customCoverSheetLevel',
      text: 'custom coversheet level',
      sortable: true,
    },
  ]),
  selectionKey: 'id',
  domID: 'production-arrangements-edit-test-grid',
  onSortTableColumn: () => {},
  supportSelection: true,
  initialSortingKey: 'claimType|SORT_ASCENDING',
  sortingComparator: SORTING_COMPARATOR,
  pageTrackerPrefix: 'RDET-', // rule detail edit test
};

export const providerInsuredOptionMessageGridConfig = {
  columns: new Set([
    ...DEFAULT_COLUMNS,
    {
      dataName: 'message',
      text: 'message',
      sortable: true,
    },
  ]),
  selectionKey: 'id',
  domID: 'provider-insured-option-message-edit-test-grid',
  onSortTableColumn: () => {},
  supportSelection: true,
  initialSortingKey: 'claimType|SORT_ASCENDING',
  sortingComparator: SORTING_COMPARATOR,
  pageTrackerPrefix: 'RDET-', // rule detail edit test
};

export const returnAddressGridConfig = {
  columns: new Set([
    ...DEFAULT_COLUMNS,
    {
      dataName: 'returnAddress',
      text: 'return address',
      sortable: true,
    },
  ]),
  selectionKey: 'id',
  domID: 'return-address-edit-test-grid',
  onSortTableColumn: () => {},
  supportSelection: true,
  initialSortingKey: 'claimType|SORT_ASCENDING',
  sortingComparator: SORTING_COMPARATOR,
  pageTrackerPrefix: 'RDET-', // rule detail edit test
};

export const signatureRuleGridConfig = {
  columns: new Set([
    ...DEFAULT_COLUMNS,
    {
      dataName: 'signatureThresholdLabel',
      text: 'signature threshold',
      sortable: true,
    },
    {
      dataName: 'signature1',
      text: 'signature1',
      sortable: true,
    },
  ]),
  selectionKey: 'id',
  domID: 'signature-edit-test-grid',
  onSortTableColumn: () => {},
  supportSelection: true,
  initialSortingKey: 'claimType|SORT_ASCENDING',
  sortingComparator: SORTING_COMPARATOR,
  pageTrackerPrefix: 'RDET-', // rule detail edit test
};

export const taxIdArrangementRuleGridConfig = {
  columns: new Set([
    ...DEFAULT_COLUMNS,
    {
      dataName: 'copyNumber',
      text: 'copy number',
      sortable: true,
    },
    {
      dataName: 'taxIdNumber',
      text: 'tax id number',
      sortable: true,
    },
    {
      dataName: 'deliveryAddress',
      text: 'delivery address',
      sortable: true,
    },
  ]),
  selectionKey: 'id',
  domID: 'tax-id-arr-edit-test-grid',
  onSortTableColumn: () => {},
  supportSelection: true,
  initialSortingKey: 'claimType|SORT_ASCENDING',
  sortingComparator: SORTING_COMPARATOR,
  pageTrackerPrefix: 'RDET-', // rule detail edit test
};

export const pdfTaggingGridConfig = {
  columns: new Set([
    ...DEFAULT_COLUMNS,
    {
      dataName: 'templateId',
      text: 'template Id',
      sortable: true,
    },
  ]),
  selectionKey: 'id',
  domID: 'pdf-tagging-edit-test-grid',
  onSortTableColumn: () => {},
  supportSelection: true,
  initialSortingKey: 'claimType|SORT_ASCENDING',
  sortingComparator: SORTING_COMPARATOR,
  pageTrackerPrefix: 'RDET-', // rule detail edit test
};

export const GRID_CONFIGS_BY_RECORD_TYPE = {
  [RULE_TYPES[0]]: autoHoldGridConfig,
  [RULE_TYPES[1]]: checkTextRuleGridConfig,
  [RULE_TYPES[2]]: customerServiceMessageGridConfig,
  [RULE_TYPES[3]]: duplexRuleGridConfig,
  [RULE_TYPES[4]]: erisaMessageGridConfig,
  [RULE_TYPES[5]]: generalMessageGridConfig,
  [RULE_TYPES[6]]: highDollarReviewGridConfig,
  [RULE_TYPES[7]]: imageDefinitionGridConfig,
  [RULE_TYPES[8]]: memberIdRuleGridConfig,
  [RULE_TYPES[9]]: productionArrangementsGridConfig,
  [RULE_TYPES[10]]: providerInsuredOptionMessageGridConfig,
  [RULE_TYPES[11]]: returnAddressGridConfig,
  [RULE_TYPES[12]]: signatureRuleGridConfig,
  [RULE_TYPES[13]]: taxIdArrangementRuleGridConfig,
  [RULE_TYPES[14]]: pdfTaggingGridConfig,
};
