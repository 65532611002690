/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import styled from 'styled-components/dist/styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  Button,
  useLayout,
  Checkbox,
  Input,
} from 'ui-core/dist';
import { LoadingPage } from '../../components';
import GridPaginationWrapper from '../../components/GridPaginationWrapper';
import ErrorPage from '../ErrorPage';
import ListPaneHeaderWrapper from '../../components/ErrorDisplaySection/ListPaneHeaderWrapper';
import { INELIGIBLE_CODE_INFO_GRID } from './Modules/constants';
import { resetCheckAccountInfo, setCheckAccountInfo } from './Modules/action';
import { useGetIneligibleCodes } from "../../api/useClientRuleManager";
import useSortableGrid from '../../hooks/useSortableGrid';

const StyledGridWrapper = styled.div`
&.GridWrapper #grid-wrapper-test-id{
  overflow:initial !important;
}
`
const IneligibleCodes = () => {
  const {
    expandCollapseListPaneButtonConfig,
    openDetailPane,
    closeDetailPane
  } = useLayout()
  const dispatch = useDispatch()
  const [count, setcount] = useState(0)
  const [selectedRecord,setSelectedRecord]=useState('')
  const [totalRecords, setTotalRecords] = useState(0);
  const [records, setRecords] =useState([])
  const [rowsPerPage,setRowsPerPage]=useState(10);
  const [pageIndex,setPageIndex]=useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [exactMatch, setExactMatch] = useState(false);

  const {data: ineligibleCodes, postData: getIneligibleCodes, isError, isLoading, error}
    =useGetIneligibleCodes()    
  const { dataSourceDefinition } = useSelector(({ context }) => context?.context || {});

  const getParameters = useMemo(() =>(
    `<![CDATA[<params>
      <codeToSearch>${exactMatch ? searchValue?.trim() : searchValue}</codeToSearch>
      <findExactMatch>${exactMatch}</findExactMatch></params>]]>`
  ),[searchValue, exactMatch])

  useEffect(() => {
    getIneligibleCodes({
      maxRecords: 10,
      rowPosition: 0,
      sortField: 'inelgible-code',
      sortDescending: false,
      viewInactive: false,
      parameters: getParameters,
      count
    })
    setcount(count + 1)
    closeDetailPane();
  },[]);

  const fetchData = useCallback((args) => {
    let sortcolumn; let sd; let pi;
    if (args) {
      ({sortcolumn,sd,pi}=args);
    }
    getIneligibleCodes({
      maxRecords: rowsPerPage,
      rowPosition: pi===undefined || pi===null?pageIndex: pi,
      sortField:sortcolumn===undefined || sortcolumn===null?sortConfig.sortDataName: sortcolumn,
      sortDescending: sd===undefined || sd===null?sortConfig.sortDescending: sd,
      viewInactive: false,
      parameters: getParameters,
      count
    })
    setcount(count + 1)
    closeDetailPane();
  }, [rowsPerPage, pageIndex, searchValue, exactMatch, sortConfig, count])

  const [sortConfig, getArgumentsAndSortGrid]=useSortableGrid(fetchData,"inelgible-code","false");

  useEffect(() => {
   
    if(ineligibleCodes){
      const {collection}=ineligibleCodes;
      if (collection) {
        setTotalRecords(collection.$?.totalRecords)
        if (collection['inelgible-codes']) {
          if(collection['inelgible-codes'].constructor.name === 'Array'){
            const recs = collection['inelgible-codes']
            setRecords(recs)
          } else {
            setRecords([collection['inelgible-codes']])
          }
        } else {
          setTotalRecords(0)
          setRecords([])
        }
      } else {
        setTotalRecords(0)
        setRecords([])
      }
    } else {
      setTotalRecords(0)
      setRecords([])
    }
  }, [ineligibleCodes, ])

  const refetchIneligibleCodes = () => {
    fetchData()
    setcount(count + 1)
    closeDetailPane();
  }

  const onShowMoreClick = useCallback((record) => {
    dispatch(setCheckAccountInfo({ selectedRecord: JSON.parse(JSON.stringify(record))
      , mode: 'Edit', refetchRecord: refetchIneligibleCodes}));
    setSelectedRecord(JSON.parse(JSON.stringify(record)))
    openDetailPane({
      isAdd: false
    });
  },[count]);

  const onAddDeliveryAddress = () => {
    setSelectedRecord({})
    dispatch(setCheckAccountInfo({ selectedRecord: JSON.parse(JSON.stringify({}))
      , mode: 'Add', refetchRecord: refetchIneligibleCodes}));
    openDetailPane({
      isAdd: true
    });
  }
  
  const renderGrid = useMemo(() => {
    if (records) {
      let data = []
      if(records.constructor.name === 'Array')
        data = JSON.parse(JSON.stringify(records))
      else
        data = JSON.parse(JSON.stringify([records]))
      return (
        <>
          <StyledGridWrapper className='GridWrapper'>
            <div className="displayFlex" style={{paddingLeft: '23px'}}>

              <span>Ineligible Code Search: </span>
              <Input
                style={{ width: '200px',marginLeft:'10px'}}
                className="test-classname"
                domID="txt_renderaddressNameSearch"
                size="small"
                maxLength={50}
                initialValue={searchValue}
                onChange={(e) => {
                  if(e)
                    setSearchValue(e.target.value)
                }}
                onEnterPress={fetchData}
              />

              <span style={{ marginLeft:'10px',marginRight:"10px"}}>Exact Match: </span>
              <Checkbox
                domID="exactMatch"
                size="large"
                checked={exactMatch}
                onChange={() => {
                  setExactMatch(!exactMatch)
                }}
              />

            </div>

            <GridPaginationWrapper
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={setRowsPerPage}
              pageIndex={pageIndex}
              onChangePageIndex={setPageIndex}
              onShowButtonCLick={fetchData}
              totalRecords={totalRecords}
              defaultRowsPerPage={10}
            />
            <Grid
              {...INELIGIBLE_CODE_INFO_GRID}
              records={data || []}
              selectionKey= "inelgible-code"
              onClickThrough={(e, rec) => {
                e.preventDefault();
                e.stopPropagation();
                if (rec) {
                  const record = (data || []).find(x => x['inelgible-code'] === rec['inelgible-code'])
                  if (onShowMoreClick) {
                    dispatch(resetCheckAccountInfo())
                    return onShowMoreClick({...record, data});
                  }
                  return false;
                }
                return false;
              }}
              focusedItem={selectedRecord}
              initialSortingKey={`${sortConfig.sortDataName}|${sortConfig.sortDescending==="true"?'SORT_DESCENDING':'SORT_ASCENDING'}`}
              onSortGridColumn={(e,state)=>getArgumentsAndSortGrid(state)}
            />
          </StyledGridWrapper>
        </>
      )
    }
    return null;
  }, [
    records,
    selectedRecord,
    sortConfig,
    searchValue,
    exactMatch,
    rowsPerPage,
    pageIndex,
    totalRecords
  ])

  return (
    <>
      <ListPaneHeaderWrapper
        title="Ineligible Codes Manager"
        layoutControls={[expandCollapseListPaneButtonConfig]}
      />
      {isLoading ? (
        <LoadingPage />
      ) : isError ? (
        <ErrorPage
          error={error}
        />
      ) : (
        <>
          <div className="marginLeft">
            {dataSourceDefinition.split('/')[2] === '005' ?
              <table border="0" cellSpacing="1" cellPadding="3" width="100%">
                <tr>
                  <td>
                    <div className="displayFlex" style={{ paddingLeft: '23px'}}>
                      <Button
                        id='ContactEditorAdd'
                        size="small"
                        name="Add Ineligible Code"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          onAddDeliveryAddress()
                        }}
                      />
                    </div>
                  </td>
                </tr>
                <tr><p></p></tr>
                <tr>
                  <td>
                    {renderGrid}
                  </td>
                </tr>
              </table> : 
              <div style={{ color: 'red', marginLeft: '40px' }}>
                <div style={{ fontSize: '13px'}}>There were errors processing your request:</div>
                <li style={{ fontSize: '13px', marginLeft: '40px' }}>
                  This layout does not allow for the editing of Ineligible Codes.
                </li>
              </div>
            }
          </div>
        </>
      )}
    </>
  )
};

export default IneligibleCodes
