import {
  GET_COMMIT_RULETYPES_REQUEST_TRIGGER,
  GET_COMMIT_RULETYPES_REQUEST_SUCCESS,
  GET_COMMIT_RULETYPES_REQUEST_ERROR,
  SET_IS_SHOWING_MULTIPLE_RULES_COMMIT_MODAL,
  MULTIPLE_RULES_COMMIT_PUT_REQUEST_TRIGGER,
  MULTIPLE_RULES_COMMIT_PUT_REQUEST_SUCCESS,
  MULTIPLE_RULES_COMMIT_PUT_REQUEST_ERROR,
  SET_IS_MULTIPLE_RULES_COMMIT_STATE,
  COMMIT_MULTIPLE_TEST_ASSOCIATION_RULE_MODAL_OPEN,
  TRIGGER_COMMIT_FOR_MULTIPLE_TEST_ASSOCIATION_RULES,
  COMMIT_MULTIPLE_TEST_ASSOCIATION_RULES_SUCCESS,
  COMMIT_MULTIPLE_TEST_ASSOCIATION_RULES_ERROR,
  RESET_COMMIT_STATE
} from './constants';

// This is the action that the UI will dispatch to trigger our request loop
export const triggerGetCommitRuleTypesRequest = (id = null) => ({
  type: GET_COMMIT_RULETYPES_REQUEST_TRIGGER,
  payload: id,
});

export const getCommitRuleTypesListReceived = payload => ({
  type: GET_COMMIT_RULETYPES_REQUEST_SUCCESS,
  payload,
  error: null,
});

// This action is used to signal a request error
export const getCommitRuleTypesListRequestError = (error, id = null) => ({
  type: GET_COMMIT_RULETYPES_REQUEST_ERROR,
  error,
  id,
});

// ----------  MULTIPLE RULES COMMIT --------------------------------
// This action sets if the multiple rules commit  modal is shown or not
export const setIsShowingMultipleCommitRulesModal = payload => ({
  type: SET_IS_SHOWING_MULTIPLE_RULES_COMMIT_MODAL,
  payload,
});

// This is the action that the UI will dispatch to trigger our request loop
export const triggerMultipleCommitPutRequest = (payload = {}) => ({
  type: MULTIPLE_RULES_COMMIT_PUT_REQUEST_TRIGGER,
  payload,
});

// This action is dispatched from the saga if our request succeeds. It is the
// action the reducer will respond in order to cache the response data
export const multipleCommitPutSuccess = (payload = {}) => ({
  type: MULTIPLE_RULES_COMMIT_PUT_REQUEST_SUCCESS,
  payload,
  error: null,
});

// This action is used to signal a request error
export const multipleCommitPutError = (error = {}) => ({
  type: MULTIPLE_RULES_COMMIT_PUT_REQUEST_ERROR,
  error,
});

export const setIsMultipleRulesCommitingState = payload => ({
  type: SET_IS_MULTIPLE_RULES_COMMIT_STATE,
  payload,
});

// ------------------------------------------------------------------

export const isShowingCommitMultipleTestAssocRuleModalOpen = payload => ({
  type: COMMIT_MULTIPLE_TEST_ASSOCIATION_RULE_MODAL_OPEN,
  payload,
});

export const triggerCommitforMultipleTestAssocRules = (payload = {}) => ({
  type: TRIGGER_COMMIT_FOR_MULTIPLE_TEST_ASSOCIATION_RULES,
  payload,
});
export const commitMultipleTestAssocRulesSuccess = (payload = []) => ({
  type: COMMIT_MULTIPLE_TEST_ASSOCIATION_RULES_SUCCESS,
  payload,
  error: null,
});
export const commitMultipleTestAssocRulesError = (error = {}) => ({
  type: COMMIT_MULTIPLE_TEST_ASSOCIATION_RULES_ERROR,
  payload: null,
  error,
});

export const resetCommitState = (payload = {}) => ({
  type: RESET_COMMIT_STATE,
  payload,
});
