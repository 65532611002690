import {useState, useEffect} from 'react';
import moment from 'moment'
import { useAxiosPost } from '../hooks'

const useGetJobs=()=>{
    const [url, seturl]=useState('');
    const [count, setcount]=useState(0)
    const getJobs=({
        clientLayout, 
        sortColumn, 
        sortOrder, 
        offset, 
        rowCount,
        withparams,
        importStartDate,
        importEndDate,
        jobNumber,
        jobStatus,
        responseStatus,
        count: countP})=>{
            let s=`/jobs/${clientLayout}/${sortColumn}/${(sortOrder||"")==="true"?'desc':'asc'}/${offset}/${rowCount}`
            let queryparams=`?withparams=${withparams}`;
            if (importStartDate) {
              queryparams=queryparams+(queryparams.length==0?'?':'&')+`importStartDate=${moment(importStartDate).format('DD/MM/YYYY')}`
            }
            if (importEndDate) {
              queryparams=queryparams+(queryparams.length==0?'?':'&')+`importEndDate=${moment(importEndDate).format('DD/MM/YYYY')}`
            }
            if (jobNumber) {
              queryparams=queryparams+(queryparams.length==0?'?':'&')+`jobNumber=${jobNumber}`
            }
            if (jobStatus) {
              queryparams=queryparams+(queryparams.length==0?'?':'&')+`jobStatus=${jobStatus}`
            }
            if (responseStatus) {
              queryparams=queryparams+(queryparams.length==0?'?':'&')+`responseStatus=${responseStatus}`
            }
            s=s+queryparams;
        seturl(s)
        setcount(countP)
    }
    useEffect(()=>{
        if (url && postData) {
            postData({count})
        }
    },[url, count])
    const {postData, isLoading,isError,error, metaData, data }=useAxiosPost({
      url,
      method:'GET',
      useDirectPrint:true,
      onError: (e) => 
      // what happens on error? if you need something extra
      // check api and see that the notifications are already handled there
        e
      ,
      // this will override the onSuccess and onError so just pick to use this
      // or to use the other 2
      onSettled: (d, e) => {
        if(e) {
          // do a thing
          return e
        }
        // some logic here
        return d
      },
    })  
    return { isLoading, isError, error, getJobs, metaData, data};
  }
  const useGetFilesForJob=()=>{
    const [config, setconfig]=useState(null)
    const [url, seturl]=useState('')
    const getFilesForJob=({
        clientLayout, 
        sortColumn, 
        sortOrder, 
        offset, 
        rowCount,
        withparams,
        jobNumber,
        filename,
        count: countP})=>{
            let s=`/jobs/${clientLayout}/files/${sortColumn}/${(sortOrder||"")==="true"?'desc':'asc'}/${offset}/${rowCount}`
            setconfig(JSON.parse(JSON.stringify({ withparams, filename, jobNumber, countP})))
            seturl(s)
    }
    useEffect(()=>{
        if (config && postData) {
            postData({...config})
        }
    },[config])
    const {postData, isLoading,isError,error, metaData, data }=useAxiosPost({
      url,
      method:'POST',
      useDirectPrint:true,
      onError: (e) => 
      // what happens on error? if you need something extra
      // check api and see that the notifications are already handled there
        e
      ,
      // this will override the onSuccess and onError so just pick to use this
      // or to use the other 2
      onSettled: (d, e) => {
        if(e) {
          // do a thing
          return e
        }
        // some logic here
        return d
      },
    })  
    return { isLoading, isError, error, getFilesForJob, metaData, data};
  }
  const useGetJobNumbers=()=>{
    const [url, seturl]=useState('');
    const getJobNumbers=(clientLayout, jobNumber)=>{
        seturl(`/jobs/${clientLayout}/${jobNumber}`)
    }
    useEffect(()=>{
        if (url && postData) {
            postData()
        }
    },[url])
    const {postData, isLoading,isError,error, metaData, data }=useAxiosPost({
      url,
      method:'GET',
      useDirectPrint:true,
      onError: (e) => 
      // what happens on error? if you need something extra
      // check api and see that the notifications are already handled there
        e
      ,
      // this will override the onSuccess and onError so just pick to use this
      // or to use the other 2
      onSettled: (d, e) => {
        if(e) {
          // do a thing
          return e
        }
        // some logic here
        return d
      },
    })  
    return { isLoading, isError, error, getJobNumbers, metaData, data};
    }

    export {
        useGetJobs,
        useGetJobNumbers,
        useGetFilesForJob
    }