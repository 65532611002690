import {
  SET_LOGON,
  LOGOUT_ERROR,
  CHANGE_PASSWORD,
  CLEAR_NOTIFICATION_BANNER
} from './constants';

const initialState = {
  logoutError: {
    displayMessage: undefined
  },
  clearNotification: false
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LOGON:
      return {
        ...state,
        ...payload
      }
    case LOGOUT_ERROR:
      return {
        ...state,
        logoutError: {
          ...payload
        }
      }
    case CLEAR_NOTIFICATION_BANNER:
      return {
        ...state,
        clearNotification: true
      }
      case 'RESET_CLEAR_NOTIFICATION_BANNER':
        return {
          ...state,
          clearNotification: false
        }
    case CHANGE_PASSWORD:
      return {
        ...state,
        passwordExpired: false
      }
    default: 
      return state
  }
}