/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components/dist/styled-components';
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import {
  useLayout,
  Modal,
  ModalHeader,
  Button,
  ModalFooter,
  ButtonGroup,
  ModalBody
} from 'ui-core/dist'
import { LoadingPage, AnchorInput } from '../../../components';
import withAxiosCancellation from '../../../components/withAxiosCancellation'
import useNotificationBannerHook from '../../../useNotificationBannerHook';
import ErrorPage from '../../ErrorPage';
import {
  GENERIC_TEXT_SEARCH_ROUTE,
  GENERIC_TEXT_ROUTE,
  GENERIC_WARNING_TEXT_ROUTE,
  DOC_MANAGER_ROUTE_DELIVERY_TYPE_NO_CHANGE_VALUE,
  DOC_MANAGER_ROUTE_DELIVERY_TYPE_NO_CHANGE_LABEL
} from "../Modules/constants";
import ListPaneHeaderWrapper from '../../../components/ErrorDisplaySection/ListPaneHeaderWrapper';
import FileReceiptManagerDetailComponent from '../../FileReceiptManager/FileReceiptManagerDetailComponent';
import ShowMoreNestedGridComponent from '../../../components/ShowMoreNestedGridComponent';
import { useSearchHoldGroups, useSubmitRouteDocument, useGetAddress } from '../../../api/useDocumentManager';
import { resetFileKey, setFileKey } from '../../FileReceiptManager/Modules/Action';
import GridPaginationWrapper from '../../../components/GridPaginationWrapper';
import { setFileSearchParam, setFileSearchParamNull } from "../Modules/action";
import useSelectableGrid from '../../../hooks/useSelectableGrid';
import '../Modules/styles.scss';
import useDocumentRouteDeliveryFilter from '../DocumentRouteDeliveryFilter/useDocumentRouteDeliveryFilter';
import DocumentRouteDeliveryFilterComp from '../DocumentRouteDeliveryFilter/DocumentRouteDeliveryFilterComp';
import ModalWrapper from '../../../components/Modal/components/ModalWrapper';
import PopoverBox from "../../../components/PopoverBox";

const StyledModalWrapper = styled.div`
height: 80vh;
`
const StyledModalHeaderWrapper = styled.div`
& div {
  padding:1px 9px !important;
  padding-bottom: 1px !important;
}
`
const StyledModalWrapperWidth = styled(ModalWrapper)`
& .active-content {
  width: 65% !important;
  max-height: 600px !important;
  overflow: auto;
  & span {
    font-size: 11px !important;
  }
}
`

const StyleDivParent = styled.div`
  &.styled-parent-div {
    margin-top: 45px;
    border: 1px solid #cacccd;
    /* border-width: 1px; */
    /* border-color: #dbdbdb; */
    overflow-y: auto;
    height: 470px;
    border-radius: 5px;
    margin-left: 30px;
    text-align: left;
    /* -webkit-box-shadow: -1px 9px 18px 0px rgb(0 0 0 / 75%); */
    /* -moz-box-shadow: -1px 9px 18px 0px rgba(0,0,0,0.75); */
    /* box-shadow: 0px 0px 6px -1px rgb(0 0 0 / 75%); */
  }
  &.popBoxDiv {
    display: inline-block;

    & span {
      position:relative !important;

      & div.popover.bottom {
        border-radius: 5px;
        margin-top: 42px !important;
        margin-left: 27px !important;
      }
    }
  }
`;
const GSearchResultRouteMain = ({getCancelSourceWithName}) => {
  const {
    expandCollapseListPaneButtonConfig
  } = useLayout()

  const dispatch = useDispatch()
  const history = useHistory();
  const { notify } = useNotificationBannerHook();
  const { allData, totalRecordsResp, searchGroups, isLoading, isError, error } = useSearchHoldGroups({getCancelSource:getCancelSourceWithName('searchRouteGroupResult')});
  const { fileSearchParam } = useSelector(({ documentSearch }) => documentSearch);
  const [count, setCount] = useState(0);
  const [changeItemPerPage, setchangeItemsPerPage] = useState(fileSearchParam.maxRecords)
  const [pageIndex, setPageIndex] = useState(0)
  const [list, setlist] = useState([]);

  const [isOpenFileReceiptModal, setisOpenFileReceiptModal] = useState(false)
  const [isOpenConfirmModal, setisOpenConfirmModal] = useState(false)
  const { onRowSelect, onClearAllSelection,
    onClearSelectionCurrentPage, selectedRecords, selectedItemIds } = useSelectableGrid({ uniqueKey: 'uniqueId', selectionKey: 'isSelected', list, setlist })

  const form = useDocumentRouteDeliveryFilter(selectedRecords, notify, {
    deliveryTypeConfig: {
      values: [{
        label: DOC_MANAGER_ROUTE_DELIVERY_TYPE_NO_CHANGE_LABEL,
        value: DOC_MANAGER_ROUTE_DELIVERY_TYPE_NO_CHANGE_VALUE
      }], defaultSelectedValue: DOC_MANAGER_ROUTE_DELIVERY_TYPE_NO_CHANGE_VALUE
    }, deliveryAddressConfig: {}
  });
  const { filekey }
    = useSelector(({ fileReceipt }) => fileReceipt);
  const { transId, submitDocuments, isLoading: isTxnLoading } = useSubmitRouteDocument(form, 'groups');
  useEffect(() => {
    searchGroups(fileSearchParam)
  }, [])

  useEffect(() => {
    if (isOpenConfirmModal) {
      if (form?.renderConfirmationModalUpperPanel) form.renderConfirmationModalUpperPanel();
    }
  }, [isOpenConfirmModal])

  useEffect(() => {
    if (allData) {
      setlist(JSON.parse(JSON.stringify(allData)))
    }
  }, [allData, totalRecordsResp])

  useEffect(() => {
    if (transId) {
      setisOpenConfirmModal(false);
      // searchGroups({ ...fileSearchParam, count })
      // setCount(count + 1);
      onClearAllSelection()
      if (form && form.resetFields) form.resetFields()
      history.push({ pathname: '/DocumentManager/Route/Group', state: `You have successfully routed the groups.  The transaction ID is ${transId}` })
    }
  }, [transId]);

  const onCloseFileReceiptModal = () => {
    setisOpenFileReceiptModal(false)
    dispatch(resetFileKey())
    return true;
  }


  const onCloseConfirmModal = () => {
    setisOpenConfirmModal(false)
    return true;
  }

  const onConfirm = () => {
    if(selectedRecords && selectedRecords.size){
      const groupIds = [...new Map(Array.from(selectedRecords).filter(y => y.isSelected).map(item => [item.groupid, item])).values()].map(x => x.groupid);
      let payLoad = `<GroupManagement>`
      groupIds.forEach(g => {
        payLoad += `<group>`
        payLoad += `<id>${g}</id>`
        payLoad += `<filekeys>`
        Array.from(selectedRecords).forEach(l => {
          if (g === l.groupid)
            payLoad += `<filekey>${l.filekey}</filekey>`;
        });
        payLoad += `</filekeys>`;
        payLoad += `</group>`
      });
      payLoad += `</GroupManagement>`
      submitDocuments(payLoad, count);
      setCount(count + 1);
    }
  }

  const transformGroupedData = (data, isGrouped) => {
    const temp = JSON.parse(JSON.stringify(data))
    let t;
    if (isGrouped) {
      t = temp.map((d) => ({
        ...d,
        subRecords: d.subRecords.map((s) => {
          let statusComp = '';
          let className;
          switch (s.status) {
            case 'Imported':
              className = 'status-ok';
              break;
            case 'Released':
              className = 'status-ok';
              break;
            case 'Ready to print':
              className = 'status-ok';
              break;
            case 'Held':
              className = 'status-warn';
              break;
            case 'Held and Errored':
              className = 'status-error';
              break;
            case 'Errored':
              className = 'status-error';
              break;
            case 'Purged':
              className = 'status-error';
              break;
            default:
              break;
          }
          statusComp = (
            <div className={className}>
              <span>{s.status}</span>
            </div>
          )
          return {
            ...s,
            'filekey_display': <AnchorInput
              id='fileKey'
              type='button'
              className='fileKeyLink'
              onClick={() => {
                dispatch(resetFileKey())
                dispatch(setFileKey(s.filekey))
                setisOpenFileReceiptModal(true)
              }}
            >{s.filekey}</AnchorInput>,
            'groupname_display': <AnchorInput
              id='documentSearchNavigation'
              type='button'
              className='fileKeyLink'
              onClick={() => { alert(s.groupname) }}
            >{s.groupname}</AnchorInput>,
            status_display: statusComp
          }
        })
      }))
    }
    return t;
  }

  const onSelectAll = () => {
    const data = list.filter(x => x.canManage === 'true')
    onRowSelect(true, data)
  }

  const fetchData = (args) => {
    let pi;
    if (args) {
      ({ pi } = args);
    }
    const payload = JSON.parse(JSON.stringify({
      "maxRecords": changeItemPerPage,
      "rowPosition": pi === undefined || pi === null ? pageIndex : pi,
      "sortField": fileSearchParam.sortField,
      "sortDescending": fileSearchParam.sortDescending,
      "checkNumber": fileSearchParam ? fileSearchParam.checkNumber : '',
      "claimNumber": fileSearchParam ? fileSearchParam.claimNumber : '',
      "groupId": fileSearchParam ? fileSearchParam.groupId : '',
      "idNumber": fileSearchParam ? fileSearchParam.idNumber : '',
      "filekey": fileSearchParam ? fileSearchParam.filekey : '',
      "showDuplicateChecks": true,
      "showProcessedDocuments": false,
    }))
    dispatch(setFileSearchParamNull(payload))
    dispatch(setFileSearchParam(payload))
    searchGroups(payload)
  }

  const renderGrid = (fromModal) => {
    let grid = <></>;
    if (fromModal)
      grid = <ShowMoreNestedGridComponent
        mainGridConfig={{
          transformationFunction: transformGroupedData,
          disabledColumns: new Set([]),
          columns: new Set([
            {
              dataName: 'groupname_display',
              groupingColumnName: 'groupname',
              text: 'Group Name',
              sortable: true,
              isSorted: 0,
              cellType: 'text',
            },
            {
              dataName: 'filekey',
              text: 'File Key',
              sortable: true,
              isSorted: 0,
              cellType: 'text',
            },
            {
              dataName: 'originalFileName',
              text: 'Orig. Name',
              sortable: true,
              isSorted: 0,
              cellType: 'text',
            },
            {
              dataName: 'importedDate',
              text: 'Imported Date',
              sortable: true,
              isSorted: 0,
              cellType: 'text',
            }, {
              dataName: 'status_display',
              text: 'Status',
              sortable: true,
              isSorted: 0,
              cellType: 'custom',
            }, {
              dataName: 'errors',
              text: '',
              sortable: true,
              isSorted: 0,
              cellType: 'text',
            },]),
          isGrouped: true,
          defaultGroupBy: 'groupname',
          defaultGroupingType: 'table',
          wantGroupingControls: false,
          width: '100%',
        }}
        data={Array.from(selectedRecords)}
      />
    else {
      if (list && list.length > 0) {
        grid = <ShowMoreNestedGridComponent
          mainGridConfig={{
            transformationFunction: transformGroupedData,
            disabledColumns: new Set([]),
            columns: new Set([
              {
                dataName: 'groupname_display',
                groupingColumnName: 'groupname',
                text: 'Group Name',
                sortable: true,
                isSorted: 0,
                cellType: 'text',
              },
              {
                dataName: 'filekey_display',
                text: 'File Key',
                sortable: true,
                isSorted: 0,
                cellType: 'text',
              },
              {
                dataName: 'originalFileName',
                text: 'Orig. Name',
                sortable: true,
                isSorted: 0,
                cellType: 'text',
              },
              {
                dataName: 'importedDate',
                text: 'Imported Date',
                sortable: true,
                isSorted: 0,
                cellType: 'text',
              }, {
                dataName: 'status_display',
                text: 'Status',
                sortable: true,
                isSorted: 0,
                cellType: 'custom',
              }, {
                dataName: 'errors',
                text: '',
                sortable: true,
                isSorted: 0,
                cellType: 'text',
              },]),
            isGrouped: true,
            defaultGroupBy: 'groupname',
            defaultGroupingType: 'table',
            wantGroupingControls: false,
            width: '85%',
            supportSelection: true,
            selectionKey: 'isSelected',
            selectionCondition: (x) => x.canManage === 'true',
            onRowSelect
          }}
          data={list}
        />
      } else {
        grid = (
          <div className="displayFlex">
            <label style={{ color: 'red' }}>No records found. Change search criteria.</label>
          </div>
        )
      }
    }
    return grid;
  }

  const renderConfirmButton = useMemo(() => {
    let disabled = form && ((form?.validationMessage && form.validationMessage.toString() && !form.validationMessage.toString().toLowerCase().includes('warning')));

    if (selectedItemIds.size === 0) {
      disabled = true;
    }
    return (
      <Button
        buttonType="standard"
        size="small"
        name="CONFIRM SELECTED ITEMS"
        // disabled={!selectedRecords.size}
        disabled={disabled}
        onClick={() => { setisOpenConfirmModal(true) }}
      />
    );
  }, [selectedItemIds, form?.validationMessage])

  return (
    <>
      <ListPaneHeaderWrapper
        title="Document Manager: Route Groups"
        layoutControls={[expandCollapseListPaneButtonConfig]}

      />
      {isLoading ? (
        // replace with a better loading component
        <LoadingPage />
      ) : isError ? (
        // replace with a better error component
        <ErrorPage
          // this prop isn't real but is an example
          error={error}
        />
      ) : (
            <div className="mainWrapper">
              <div style={{ fontSize: "small", padding: "12px" }}>
                <p>{GENERIC_TEXT_SEARCH_ROUTE}</p>
                <div style={{ color: "#bf1616" }}>
                  <p>
                    {GENERIC_TEXT_ROUTE}
                  </p>
                  <p>
                    {GENERIC_WARNING_TEXT_ROUTE}
                  </p>
                </div>
              </div>
              <div className="">
                <StyleDivParent className="popBoxDiv">
                  <PopoverBox
                    trigger="hover"
                    orientation="bottom"
                    buttonName="File Cutoff Time Applies"
                    buttonType="diminished"
                    domID="format_popup"
                    width="350px"

                  >
                    <div style={{
                      padding: "14px",
                      color: "#202083",
                      fontSize: "13px",
                      fontWeight: "bold",
                    }}>
                      File cutoff time is defined as the time in which a file must be fully imported, in order to be included in the 10:00AM release.

                </div>
                  </PopoverBox>
                </StyleDivParent>
              </div>
              {/* Delivery Address Filter Component */}
              <DocumentRouteDeliveryFilterComp form={form} />
              <div>
                <GridPaginationWrapper
                  rowsPerPage={changeItemPerPage}
                  onChangeRowsPerPage={(v) => setchangeItemsPerPage(v)}
                  pageIndex={pageIndex}
                  onChangePageIndex={(v) => setPageIndex(v)}
                  onShowButtonCLick={(args) => fetchData(args)}
                  totalRecords={totalRecordsResp}
                  defaultRowsPerPage={changeItemPerPage}
                  enableTextbox={false}
                />
                <div className='displayFlex'>
                  <Button
                    buttonType="standard"
                    size="small"
                    name="Select all on this page"
                    disabled={list && list.length === 0}
                    onClick={() => { onSelectAll() }}
                  />&nbsp;
              <Button
                    buttonType="standard"
                    disabled={list.filter(x => x.isSelected === true).length === 0}
                    size="small"
                    name="Clear all on this page"
                    onClick={() => { onClearSelectionCurrentPage() }}
                  />&nbsp;
              <Button
                    buttonType="standard"
                    disabled={!selectedRecords.size}
                    size="small"
                    name="Clear all"
                    onClick={() => { onClearAllSelection() }}
                  />&nbsp;
              <Button
                    buttonType="standard"
                    size="small"
                    name="New Search"
                    onClick={() => { history.push(`/DocumentManager/Route/Group`) }}
                  />&nbsp;
              {renderConfirmButton}

                </div>
                <div className="displayFlex"></div>
                {renderGrid(false)}

                <div className="displayFlex"></div>

                <Modal isOpen={isOpenConfirmModal}>
                  <ModalHeader
                    title="Selected Groups"
                    onClose={() => onCloseConfirmModal()}
                  />
                  <ModalBody>
                    <div style={{ paddingLeft: '30px', fontSize: '11px' }}>
                      <span>Confirm the routing information for the selected groups.  If you are releasing groups that are currently on hold, the documents within in the groups will be immediately released for printing and delivered using the updated routing information.  Click Confirm to continue.</span>
                      <br />
                      <span style={{ color: 'red' }}>*WARNING - You are performing a group level route. Please be aware that any routing performed will override any electronic delivery and will print and mail the selected document(s) below.</span>
                    </div>
                    <div>

                      {isOpenConfirmModal ? (
                        <div className="modalOuterDiv" style={{
                          paddingLeft: '25px',
                          paddingRight: '25px',
                          marginTop: '20px',
                          marginBottom: '-10px',
                          overflow: 'scroll',
                          maxWidth: '800px',
                          maxHeight: '40vh'
                        }}>
                          {form?.confirmationModalMarkup}
                          {renderGrid(true)}
                        </div>
                      ) : null}

                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <ButtonGroup>
                      <Button
                        buttonType="standard"
                        domID="close"
                        dataTestId="test-close"
                        name="CLOSE"
                        onClick={() => setisOpenConfirmModal(false)}
                        size="medium"
                      />
                      <Button
                        buttonType="standard"
                        domID="confirm"
                        dataTestId="test-confirm"
                        name="CONFIRM"
                        onClick={() => onConfirm()}
                        size="medium"
                        disabled={!selectedRecords.size||isTxnLoading}
                      />
                      {
                        isTxnLoading && <LoadingPage height="1vh" />
                      }
                    </ButtonGroup>
                  </ModalFooter>
                </Modal>

                <Modal isOpen={isOpenFileReceiptModal}>
                  <StyledModalWrapper>
                    <StyledModalHeaderWrapper>
                      <ModalHeader
                        title="File Receipt"
                        onClose={() => onCloseFileReceiptModal()}
                      />
                    </StyledModalHeaderWrapper>
                    <ModalBody>
                      {isOpenFileReceiptModal ? (
                        <FileReceiptManagerDetailComponent
                          filekey={filekey}
                          height='58vh'
                          overflow='auto'
                        />
                      ) : null}
                    </ModalBody>
                  </StyledModalWrapper>
                </Modal>
              </div>
            </div>
          )}
    </>
  )
}

export default withAxiosCancellation(GSearchResultRouteMain)
