/* eslint-disable no-nested-ternary */
import React from 'react';
import { SelectInput, SelectInputWrapper } from './SelectInput';

const MultipleSelectDropdownStatic = ({
  label, records,
  onChange,
  selectInputWidth, id,
  disabled,
}) => {

  const getSelectedOptions = (sel) => {
    const opts = []; let opt;
    const selOptions = Array.from(sel.target.options);
    const len = selOptions.length;
    for (let i = 0; i < len; i += 1) {
      opt = selOptions[i];
      if (opt.selected && opt.id.length) {
        opts.push(opt.value);
      }
    }
    onChange(opts);
  }

  const renderSelect = (data) => (
    <div>
      <SelectInput id={id} selectInputWidth={selectInputWidth} disabled={disabled} onChange={(value) => getSelectedOptions(value)} multiple>
        {data}
      </SelectInput>
    </div>

  );

  const renderSelectContent = () => {
    const rec = records;
    const data = rec.map((record) => (
      <option id={record.value} value={record.value}>{record.label}</option>
    ));

    return (
      <div>
        <SelectInputWrapper width="100%">
          <div style={{ display: 'flex', flexDirection: 'column', fontSize: '13px' }}>
            <div style={{ color: '#626D8A', fontSize: '11px' }}>{label}</div>
            {renderSelect(data)}
          </div>
        </SelectInputWrapper>
      </div>
    )
  }

  return (
    <>
      {renderSelectContent()}
    </>
  );
}

export default MultipleSelectDropdownStatic
