import styled from 'styled-components';

export const AdvancedPane = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  transition: right 500ms ease-in-out 0s;
  overflow-x: hidden;
`;

export const LoadingWrapper = styled.div`
  float: left;
  margin-left: -30px;
  margin-top: 3px;
  margin-right: 4px;
`;
