import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import { Portal } from 'react-portal';
import { Grid, Button, Modal, LoadingIndicator, NotificationBanner } from 'ui-core-ssgr';
import AutoHoldModal from '../../../../../RuleDetailModals/AutoHoldModal/index';
import DuplexRuleModal from '../../../../../RuleDetailModals/DuplexRuleModal/index';
import ReturnAddressModal from '../../../../../RuleDetailModals/ReturnAddressModal';
import ProductionArrangementModal from '../../../../../RuleDetailModals/ProductionArrangementModal';
import CheckTextRuleModal from '../../../../../RuleDetailModals/CheckTextRuleModal';
import CustomerServiceMessageModal from '../../../../../RuleDetailModals/CustomerServiceMessage';
import SignatureRuleModal from '../../../../../RuleDetailModals/SignatureRuleModal/index';
import MemberIdModal from '../../../../../RuleDetailModals/MemberIdModal/index';
import TaxIdArrangementRuleModal from '../../../../../RuleDetailModals/TaxIdArrangementRuleModal/index';
import ImageRuleModal from '../../../../../RuleDetailModals/ImageRuleModal';
import ErisaMessageModal from '../../../../../RuleDetailModals/ErisaMessageModal/index';
import HighDollarReviewModal from '../../../../../RuleDetailModals/HighDollarReviewModal/index';
import ProviderInsuredOptionMessageModal from '../../../../../RuleDetailModals/ProviderInsuredOptionMessageModal/index';
import GeneralMessageModal from '../../../../../RuleDetailModals/GeneralMessageModal/index';
import PDFTaggingModal from '../../../../../RuleDetailModals/PDFTaggingModal/index';
import { ButtonGroupBottom, LoadingContainer, GridLoadingWrapper, StyledDetailHeader } from '../../../../../../shared/styles/styledComponents';
import { PaddingWrapperEditTab } from '../../../../../../shared/styles/spacing';
import { ScrollableGridWrapper } from './styles';
import AdvancedGridHeader from '../AdvancedGridHeader';
import AdvancedClickableRow from '../AdvancedClickableRow';
import {
  selectDetailElements,
  selectSelectedRuleDetail,
  selectRenderSafeRecords,
  selectGridConfig,
} from './selectors';
import {
  triggerRuleDetailsRequest,
  setIsViewingRuleDetails,
  setIsShowingDeleteRuleDetailModal,
  deleteRuleDetailTrigger,
} from '../../../../../../redux/api/ruleTypesList/actions';
import {
  ruleDetailElementsTrigger,
  ruleDetailPostRequestTrigger,
  ruleDetailPutRequestTrigger,
  setIsCloningRuleDetail,
  setIsEditingRuleDetail,
  setIsPostingRuleDetail,
  setIsReadOnlyRuleDetail,
  setIsShowingProductionArrangementModal,
  setIsShowingCustomerServiceMessageModal,
  setIsShowingReturnAddressModal,
  setIsShowingDuplexRuleModal,
  setIsShowingSignatureRuleModal,
  setIsShowingCheckTextRuleModal,
  setIsShowingImageRuleModal,
  setIsShowingAutoHoldModal,
  setIsShowingMemberIdModal,
  setIsShowingTaxIdArrangementRuleModal,
  setIsShowingErisaMessageModal,
  setShowingHighDollarReviewModal,
  setIsShowingProviderInsuredOptionMessageModal,
  setIsShowingGeneralMessageModal,
  setIsShowingPDFTaggingModal,
  getAddNewAddressElementsTrigger,
  postAddNewAddressElementsTrigger,
  setIsShowingAddNewAddressModal,
  setIsShowingImageLibraryModal,
  setIsShowingImageUploadModal,
  postImageUploadTrigger,
  getRuleImagesTrigger,
  getRuleImagesCountTrigger,
  resetRuleImagesDataTrigger,
  convertImageUploadTrigger,
  postCommitImageTrigger,
  setIsShowingCommitImageModal,
  postCommitImageTriggerCancel
} from '../../../../../../redux/api/ruleDetails/actions';
import {
  selectIsEditingRuleDetail,
  selectLoadingState,
  selectProductionArrangementModalState,
  selectCustomerServiceMessageModalState,
  selectReturnAddressModalState,
  selectDuplexRuleModalState,
  selectSignatureRuleModalState,
  selectCheckTextRuleModalState,
  selectImageRuleModalState,
  selectAutoHoldModalState,
  selectMemberIdModalState,
  selectTaxIdArrangementState,
  selectErisaMessageModalState,
  selectHighDollarReviewModalState,
  selectProviderInsuredOptionMessageModalState,
  selectGeneralMessageModalState,
  selectPDFTaggingModalState,
  selectReturnAddressElements,
  selectRuleImages,
  selectRuleImagesCount,
  selectConvertedImageUploadData,
  selectConvertedImageUploadError,
  selectPostImageUploadSuccessId,
  selectIsShowingCommitImageModal,
  selectPostImageCommitSuccessId
} from '../../../../../RulesTabView/selectors';

import { LoadingWrapper } from '../../../AdvancedView/styles';

class AdvancedGridWrapper extends React.Component {
  state = {
    selectedItemIds: new Set([]),
    ruleVersion: '',
  };

  componentDidMount() {
    const {
      getRuleDetailElements,
      getRuleDetails,
      selectedRuleTypeId,
      selectedRuleRecord,
      ruleDetails,
      rulesForRuleTypes
    } = this.props;
    if (isEmpty(ruleDetails.detailElements)) {
      getRuleDetailElements({ selectedRuleTypeId });
    }
    let ruleVersion = this.getRuleVersion(selectedRuleRecord);

    // workes only in case of revert rule put request
    if (
      !isEmpty(rulesForRuleTypes.ruleVersionRequest) &&
      rulesForRuleTypes.ruleVersionRequest === 'success' &&
      rulesForRuleTypes.ruleVersion !== ruleVersion &&
      rulesForRuleTypes.ruleVersion !== undefined
    ) {
      ruleVersion = rulesForRuleTypes.ruleVersion;
    }
    getRuleDetails({
      ruleType: selectedRuleTypeId,
      ruleId: selectedRuleRecord.id,
      ruleVersion,
    });
  }

  componentDidUpdate(prevProps) {
    const {records, rulesForRuleTypes, getRuleDetails, selectedRuleTypeId, selectedRuleRecord} = this.props;
    if (prevProps.records.length !== records.length) {
      this.setState({ selectedItemIds: new Set([]) });
    }

    // workes only in case of revert rule put request
    if(
      !isEmpty(prevProps.rulesForRuleTypes.ruleVersionRequest) && 
      !isEmpty(rulesForRuleTypes.ruleVersionRequest) && 
      prevProps.rulesForRuleTypes.ruleVersionRequest !== rulesForRuleTypes.ruleVersionRequest &&
      rulesForRuleTypes.ruleVersion !== undefined
    ){
      getRuleDetails({
        ruleType: selectedRuleTypeId,
        ruleId: selectedRuleRecord.id,
        ruleVersion: rulesForRuleTypes.ruleVersion
      });
    }
  }

  // want test unless cloning (Edit Test Tab)
  getRuleVersion = record => {
    const { isCloningRule } = this.props;
    try {
      const { status, version } = record;
      const testVersion = version[status.indexOf('T')];
      const liveVersion = version[status.indexOf('L')];
      if (!isCloningRule && testVersion) {
        return testVersion;
      }
      if (isCloningRule && liveVersion) {
        return liveVersion;
      }
      if (!isCloningRule && liveVersion) {
        return liveVersion;
      }
      if (isCloningRule && testVersion) {
        return testVersion;
      }
    } catch (e) { return ''; }
    // if no test/live version or any error return null
    return null;
  };

  onGridSelectAll = (_, selectedItems) => {
    const { selectedItemIds } = this.state;
    const { setSelectedRuleDetail, records } = this.props;
    const onlyOneRule = selectedItems.selectedItemIds.size === 1;
    const alreadyFull = selectedItemIds.size === records.length;
    const ids = alreadyFull ? [] : records.map(({ id }) => id);

    if (alreadyFull) {
      setSelectedRuleDetail({});
    } else if (onlyOneRule) {
      const selectedItemValue = selectedItems.selectedItemIds.values().next().value;
      const selectedRecord = records.find(record => record.id === selectedItemValue);
      setSelectedRuleDetail(selectedRecord);
    }

    this.setState({ selectedItemIds: new Set(ids) });
  };

  onRowClickThrough = (event, record) => {
    const {
      isCloningRuleDetail,
      isPostingRuleDetail,
      selectedRuleTypeId,
      setSelectedRuleDetail,
      setIsCloningRuleDetail,
      setIsEditingRuleDetail,
      setIsPostingRuleDetail,
      setIsShowingProductionArrangementModal,
      setIsShowingCustomerServiceMessageModal,
      setIsShowingReturnAddressModal,
      setIsShowingDuplexRuleModal,
      setIsShowingSignatureRuleModal,
      setIsShowingCheckTextRuleModal,
      setIsShowingImageRuleModal,
      setIsShowingAutoHoldModal,
      setIsShowingMemberIdModal,
      setIsShowingTaxIdArrangementRuleModal,
      setIsShowingErisaMessageModal,
      setShowingHighDollarReviewModal,
      setIsShowingProviderInsuredOptionMessageModal,
      setIsShowingGeneralMessageModal,
      setIsShowingPDFTaggingModal,
      detailElements,
      addNotification,
      errorNotifications,
      getAddNewAddressElements,
      isCloningRule
    } = this.props;
    if(!isCloningRule){
      if(selectedRuleTypeId === 'RAD') {
        getAddNewAddressElements({selectedRuleTypeId});
      }
      setSelectedRuleDetail(record);
      if (isCloningRuleDetail) setIsCloningRuleDetail(false);
      if (isPostingRuleDetail) setIsPostingRuleDetail(false);
      // added following condition added to nullify selectedItemIds for issue fix: MCDS-14519
      if (!isPostingRuleDetail && !isCloningRuleDetail) { this.setState({ selectedItemIds: new Set([]) }) }
      if (Object.entries(detailElements).length > 0) {
        setIsEditingRuleDetail(true);

        switch (selectedRuleTypeId) {
          case 'PAR':
            setIsShowingProductionArrangementModal(true);
            break;
          case 'CSM':
            setIsShowingCustomerServiceMessageModal(true);
            break;
          case 'RAD':
            setIsShowingReturnAddressModal(true);
            break;
          case 'DPX':
            setIsShowingDuplexRuleModal(true);
            break;
          case 'SIG':
            setIsShowingSignatureRuleModal(true);
            break;
          case 'CHT':
            setIsShowingCheckTextRuleModal(true);
            break;
          case 'HLD':
            setIsShowingAutoHoldModal(true);
            break;
          case 'SSN':
            setIsShowingMemberIdModal(true);
            break;
          case 'TAX':
            setIsShowingTaxIdArrangementRuleModal(true);
            break;
          case 'ERI':
            setIsShowingErisaMessageModal(true);
            break;
          case 'HDR':
            setShowingHighDollarReviewModal(true);
            break;
          case 'PIO':
            setIsShowingProviderInsuredOptionMessageModal(true);
            break;
          case 'GEN':
            setIsShowingGeneralMessageModal(true);
            break;
          case 'PDT':
            setIsShowingPDFTaggingModal(true);
            break;
          case 'IMG':
            setIsShowingImageRuleModal(true);
            break;
          default:
            break;
        }
      } else if (errorNotifications) {
        errorNotifications.forEach(element => {
          if (element.type === 'ruleDetailElements') {
            addNotification({
              id: selectedRuleTypeId,
              type: 'negative',
              msg: element.msg
            });
          }
        });
      }
    }
  };

  onRowSelect = record => {
    const { selectedItemIds } = this.state;
    const {setSelectedRuleDetail} = this.props;
    const isBeingSelected = !selectedItemIds.has(record.id);
    const method = isBeingSelected ? 'add' : 'delete';
    selectedItemIds[method](record.id);
    if (isBeingSelected) setSelectedRuleDetail(record);
    this.setState({ selectedItemIds });
  };

  cancelPressed = () => {
    const {
      setIsShowingProductionArrangementModal,
      setIsShowingCustomerServiceMessageModal,
      setIsShowingReturnAddressModal,
      setIsShowingDuplexRuleModal,
      setIsShowingSignatureRuleModal,
      setIsShowingCheckTextRuleModal,
      setIsShowingImageRuleModal,
      setIsShowingAutoHoldModal,
      setIsShowingMemberIdModal,
      setIsCloningRuleDetail,
      setIsPostingRuleDetail,
      setIsEditingRuleDetail,
      setIsReadOnlyRuleDetail,
      setIsShowingTaxIdArrangementRuleModal,
      setIsShowingErisaMessageModal,
      setShowingHighDollarReviewModal,
      setIsShowingProviderInsuredOptionMessageModal,
      setIsShowingGeneralMessageModal,
      setIsShowingPDFTaggingModal
    } = this.props;
    setIsShowingProductionArrangementModal(false);
    setIsShowingCustomerServiceMessageModal(false);
    setIsShowingReturnAddressModal(false);
    setIsShowingDuplexRuleModal(false);
    setIsShowingSignatureRuleModal(false);
    setIsShowingCheckTextRuleModal(false);
    setIsShowingImageRuleModal(false);
    setIsShowingAutoHoldModal(false);
    setIsShowingMemberIdModal(false);
    setIsShowingTaxIdArrangementRuleModal(false);
    setIsShowingErisaMessageModal(false);
    setShowingHighDollarReviewModal(false);
    setIsShowingProviderInsuredOptionMessageModal(false);
    setIsShowingGeneralMessageModal(false);
    setIsShowingPDFTaggingModal(false);
    setIsCloningRuleDetail(false);
    setIsPostingRuleDetail(false);
    setIsEditingRuleDetail(false);
    setIsReadOnlyRuleDetail(false);
  };

  getHeaderName = () => {
    const {
      isCloningRuleDetail,
      isEditingRuleDetail,
      isPostingRuleDetail,
    } = this.props;
    let heading = '';
    if (isEditingRuleDetail) heading = 'Edit a';
    if (isPostingRuleDetail) heading = 'Add a';
    if (isCloningRuleDetail) heading = 'Clone a';
    return heading;
  };

  toFixedTrunc(x, n) {
    const v = (typeof x === 'string' ? x : x.toString()).split('.');
    if (n <= 0) return v[0];
    let f = v[1] || '';
    if (f.length > n) return `${v[0]}.${f.substr(0, n)}`;
    while (f.length < n) f += '0';
    let inputValue = `${v[0]}.${f}`;
    inputValue = inputValue
      .replace(/,/g, '')
      .split('')
      .reverse()
      .join('')
      .replace(/(\d{3})/g, '$1,')
      .replace(/,$/, '')
      .split('')
      .reverse()
      .join('');
    return inputValue;
  }

  constructPageList = record => {
    const { selectedRuleTypeId } = this.props;
    for (let j = 0; j < record.length; j += 1) {
      const column = record[j];
      let selectedColumnLabel = '';
      let toNumber = null;
      if (column.signatureThreshold !== null && column.signatureThreshold !== undefined && selectedRuleTypeId === 'SIG') {
        selectedColumnLabel = 'signatureThresholdLabel';
        toNumber = typeof column.signatureThreshold === 'string' ? column.signatureThreshold : String(column.signatureThreshold);
        toNumber = this.toFixedTrunc(toNumber, 2);
      }
      if (column.highDollarThreshold !== null && column.highDollarThreshold !== undefined && selectedRuleTypeId === 'HDR') {
        selectedColumnLabel = 'highDollarThresholdLabel';
        toNumber = this.toFixedTrunc(String(column.highDollarThreshold), 2);
      }
      column[selectedColumnLabel] = `$${toNumber}`;
    }

    return map(record, r =>
      r.message
        ? {
          ...r,
          message: r.message.replace(/\r?\\n|\r/g, '\n'),
        }
        : r,
    );
  };

  onSave = () => {
    const {
      name,
      desc,
      isPostingRulesForRuleTypes,
      isCloningRule,
      selectedRuleRecord,
      selectedRuleTypeId,
      triggerRulesForRuleTypesPutRequest,
      triggerRulesForRuleTypesPostRequest,
      deleteNotification,
      getRuleDetailElements
    } = this.props;
    const {id} = selectedRuleRecord;
    const ruleVersionFrom = this.getRuleVersion(selectedRuleRecord);

    if (isCloningRule) {
      triggerRulesForRuleTypesPostRequest({
        name,
        desc,
        isCloningRule,
        ruleIdFrom: id,
        ruleVersionFrom,
        id: 'postRule',
        ruleType: selectedRuleTypeId,
      });
    } else if (isPostingRulesForRuleTypes) {
      triggerRulesForRuleTypesPostRequest({
        name,
        desc,
        id: 'postRule',
        ruleType: selectedRuleTypeId,
      });
      getRuleDetailElements({ selectedRuleTypeId });
    } else {
      triggerRulesForRuleTypesPutRequest({
        id,
        name,
        desc,
        selectedRuleRecord,
        ruleType: selectedRuleTypeId,
      });
    }
    deleteNotification('cloneRule'); // once user hit submit, delete notification : Fill out a unique rule name 
  };

  checkButtonDisableStatus(selectedRuleRecord) {
    const {name, desc} = this.props;
    if (
      selectedRuleRecord.name === name &&
      selectedRuleRecord.description === desc
    ) {
      return true;
    }
    return false;
  }

  onNotificationDismiss = id => {
    const { deleteNotification } = this.props;
    deleteNotification(id);
  };

  handleCancelClick = () => {
    const {
      goBackFromAdvancedToDefaultView,
      deleteNotification,
      setIsCloningRuleState,
      setIsShowingDetailCloneRuleModal,
    } = this.props;
    goBackFromAdvancedToDefaultView();
    // code to remove notification 'Fill out a unique rule name', cancel state for cloning rule
    deleteNotification('cloneRule');
    setIsCloningRuleState(false);
    setIsShowingDetailCloneRuleModal(false);
  }

  render() {
    const {
      detailElements,
      records,
      gridConfig,
      isShowingProductionArrangementModal,
      isShowingCustomerServiceMessageModal,
      deleteNotification,
      isShowingReturnAddressModal,
      isShowingDuplexRuleModal,
      isShowingSignatureRuleModal,
      isShowingCheckTextRuleModal,
      isShowingImageRuleModal,
      isShowingAutoHoldModal,
      isShowingMemberIdModal,
      isShowingTaxIdArrangementRuleModal,
      isShowingErisaMessageModal,
      isShowingHighDollarReviewModal,
      isShowingProviderInsuredOptionMessageModal,
      isShowingGeneralMessageModal,
      isShowingPDFTaggingModal,
      loading,
      putRuleDetails,
      postRuleDetails,
      setIsCloningRuleDetail,
      setIsPostingRuleDetail,
      setIsShowingReturnAddressModal,
      setIsShowingProductionArrangementModal,
      setIsShowingCustomerServiceMessageModal,
      setIsShowingDuplexRuleModal,
      setIsShowingSignatureRuleModal,
      setIsShowingCheckTextRuleModal,
      setIsShowingImageRuleModal,
      setIsShowingAutoHoldModal,
      setIsShowingMemberIdModal,
      setIsShowingTaxIdArrangementRuleModal,
      setIsShowingErisaMessageModal,
      setIsShowingProviderInsuredOptionMessageModal,
      setIsShowingGeneralMessageModal,
      setIsShowingPDFTaggingModal,
      setShowingHighDollarReviewModal,
      selectedRuleDetail,
      selectedRuleRecord,
      selectedRuleTypeId,
      selectedRecord,
      isCloningRuleDetail,
      isEditingRuleDetail,
      isPostingRuleDetail,
      setIsShowingDeleteRuleDetailModal,
      isShowingDeleteRuleDetailModal,
      deleteRuleDetailTrigger,
      isButtonDisabled,
      setIsShowingAddNewAddressModal,
      isShowingAddNewAddressModal,
      returnAddressElements,
      postAddNewAddressElements,
      getAddNewAddressElements,
      notifications,
      addNotification,
      errorNotifications,
      setIsShowingImageLibraryModal,
      isShowingImageLibraryModal,
      setIsShowingImageUploadModal,
      isShowingImageUploadModal,
      postImageUpload,
      isCloningRule,
      getRuleImages,
      ruleImages,
      getRuleImagesCount,
      ruleImagesCount,
      resetRuleImagesData,
      convertImageUpload,
      convertedImageUploadData,
      convertedImageUploadError,
      postImageUploadSuccessId,
      postCommitImage,
      setIsShowingCommitImageModal,
      isShowingCommitImageModal,
      postImageCommitSuccessId,
      postCommitImageTriggerCancel,
      setIsReadOnlyRuleDetail
    } = this.props;
    let isButtonDisabledView = isButtonDisabled;
    if (!isButtonDisabledView) {
      isButtonDisabledView = this.checkButtonDisableStatus(selectedRuleRecord);
    }
    const { selectedItemIds } = this.state;
    const {
      onGridSelectAll,
      onRowClickThrough,
      onRowSelect,
      cancelPressed,
      handleCancelClick
    } = this;
    const actionTypeText = this.getHeaderName();
    return (
      <>
        {notifications.size > 0 && (
          <Portal key="advanced-grid-wrapper">
            {notifications
              ? [...notifications].map(([key, value]) => (
                <NotificationBanner
                  key={[key]}
                  domID={`${key}-notification`}
                  type={value.type}
                  text={value.msg}
                  onDismiss={() => this.onNotificationDismiss(key)}
                  autoDismiss
                  timer={1800000}
                  icon={value.type === 'neutral' ? 'Info' : null}
                />
              ))
              : null}
          </Portal>
        )}
        <AdvancedGridHeader
          headerName="Details"
          recordCount={records.length}
          selectedItemIds={selectedItemIds}
          postRuleDetails={postRuleDetails}
          putRuleDetails={putRuleDetails}
          detailElements={detailElements}
          setIsCloningRuleDetail={setIsCloningRuleDetail}
          setIsPostingRuleDetail={setIsPostingRuleDetail}
          setIsShowingDuplexRuleModal={setIsShowingDuplexRuleModal}
          setIsShowingSignatureRuleModal={setIsShowingSignatureRuleModal}
          setIsShowingCheckTextRuleModal={setIsShowingCheckTextRuleModal}
          setIsShowingImageRuleModal={setIsShowingImageRuleModal}
          setIsShowingTaxIdArrangementRuleModal={
            setIsShowingTaxIdArrangementRuleModal
          }
          setIsShowingErisaMessageModal={setIsShowingErisaMessageModal}
          isShowingErisaMessageModal={isShowingErisaMessageModal}
          setIsShowingProviderInsuredOptionMessageModal={
            setIsShowingProviderInsuredOptionMessageModal
          }
          isShowingProviderInsuredOptionMessageModal={
            isShowingProviderInsuredOptionMessageModal
          }
          setIsShowingGeneralMessageModal={setIsShowingGeneralMessageModal}
          isShowingGeneralMessageModal={isShowingGeneralMessageModal}
          setIsShowingPDFTaggingModal={setIsShowingPDFTaggingModal}
          isShowingPDFTaggingModal={isShowingPDFTaggingModal}
          setShowingHighDollarReviewModal={setShowingHighDollarReviewModal}
          isShowingDuplexRuleModal={isShowingDuplexRuleModal}
          setIsShowingAutoHoldModal={setIsShowingAutoHoldModal}
          isShowingAutoHoldModal={isShowingAutoHoldModal}
          setIsShowingMemberIdModal={setIsShowingMemberIdModal}
          isShowingMemberIdModal={isShowingMemberIdModal}
          selectedRuleRecord={selectedRuleRecord}
          selectedRuleDetail={selectedRuleDetail}
          setIsShowingProductionArrangementModal={
            setIsShowingProductionArrangementModal
          }
          setIsShowingCustomerServiceMessageModal={
            setIsShowingCustomerServiceMessageModal
          }
          setIsShowingReturnAddressModal={setIsShowingReturnAddressModal}
          selectedRuleTypeId={selectedRuleTypeId}
          setIsShowingDeleteRuleDetailModal={setIsShowingDeleteRuleDetailModal}
          isShowingDeleteRuleDetailModal={isShowingDeleteRuleDetailModal}
          loading={loading}
          selectedRecord={selectedRuleRecord}
          deleteRuleDetailTrigger={deleteRuleDetailTrigger}
          records={records}
          getAddNewAddressElements={getAddNewAddressElements}
          addNotification={addNotification}
          errorNotifications={errorNotifications}
          isCloningRule={isCloningRule}
        />
        <ScrollableGridWrapper>
          {notifications.has('ruleDetailsError')
            && (
              <NotificationBanner
                domID="api-error-notification"
                type={notifications.get('ruleDetailsError').type}
                text={notifications.get('ruleDetailsError').msg}
                onDismiss={() => deleteNotification('ruleDetailsError')}
                autoDismiss
                timer={1800000}
              />
            )}
          {loading.has('ruleDetailsGridLoading') ? (
            <LoadingContainer>
              <GridLoadingWrapper>
                <LoadingIndicator
                  domID="group-grid-loading-indicator"
                  length="45px"
                />
              </GridLoadingWrapper>
            </LoadingContainer>
          ) : (
            <Grid
              {...gridConfig}
              records={this.constructPageList(records)}
              selectedItemIds={selectedItemIds}
              initialSelectedItemIds={selectedItemIds}
              rowComponent={AdvancedClickableRow}
              onClickThrough={(e, record) => onRowClickThrough(e, record)}
              onRowSelect={onRowSelect}
              onSelectAll={onGridSelectAll}
              supportSelection={!isCloningRule || false}
            />
          )}
        </ScrollableGridWrapper>
        <PaddingWrapperEditTab className="group-flow-edit-test-tab">
          <LoadingWrapper>
            {(loading.has(selectedRuleRecord.id) ||
              loading.has('postRule')) && (
              <LoadingIndicator
                domID="basic-details-form-loading-indicator"
                length="30px"
              />
            )}
          </LoadingWrapper>
          <ButtonGroupBottom>
            <Button
              onClick={() => this.onSave()}
              type="button"
              size="large"
              buttonType="emphasized"
              disabled={
                isButtonDisabledView ||
                (loading.has(selectedRuleRecord.id) || loading.has('postRule'))
              }
              name="Save"
            />
            <Button
              onClick={() => handleCancelClick()}
              type="button"
              size="large"
              buttonType="diminished"
              disabled={false}
              name="Cancel"
            />
          </ButtonGroupBottom>

          <Modal
            isOpen={
              isShowingReturnAddressModal ||
              isShowingProductionArrangementModal ||
              isShowingCustomerServiceMessageModal ||
              isShowingDuplexRuleModal ||
              isShowingSignatureRuleModal ||
              isShowingCheckTextRuleModal ||
              isShowingImageRuleModal ||
              isShowingAutoHoldModal ||
              isShowingMemberIdModal ||
              isShowingTaxIdArrangementRuleModal ||
              isShowingErisaMessageModal ||
              isShowingHighDollarReviewModal ||
              isShowingProviderInsuredOptionMessageModal ||
              isShowingPDFTaggingModal ||
              isShowingGeneralMessageModal
            }
            onModalToggle={() => cancelPressed()}
          >
            <StyledDetailHeader
              domID="test-id"
              title={`${actionTypeText} Detail for Rule: ${
                selectedRuleRecord
                  ? selectedRuleRecord.name
                  : selectedRecord.name
              }`}
              marginBottom={0}
            />
            {selectedRuleTypeId === 'PAR' &&
              isShowingProductionArrangementModal ? (
                <ProductionArrangementModal
                  cancelPressed={() => cancelPressed()}
                  detailElements={detailElements}
                  loading={loading}
                  isCloningRuleDetail={isCloningRuleDetail}
                  isPostingRuleDetail={isPostingRuleDetail}
                  setIsCloningRuleDetail={setIsCloningRuleDetail}
                  setIsPostingRuleDetail={setIsPostingRuleDetail}
                  putRuleDetails={putRuleDetails}
                  postRuleDetails={postRuleDetails}
                  selectedRuleRecord={selectedRuleRecord}
                  selectedRuleDetail={selectedRuleDetail}
                  selectedRuleTypeId={selectedRuleTypeId}
                  setIsShowingProductionArrangementModal={
                    setIsShowingProductionArrangementModal
                  }
                  setIsReadOnlyRuleDetail={setIsReadOnlyRuleDetail}
                  actionType={actionTypeText}
                />
              ) : null}
            {selectedRuleTypeId === 'CSM' &&
              isShowingCustomerServiceMessageModal ? (
                <CustomerServiceMessageModal
                  cancelPressed={() => cancelPressed()}
                  detailElements={detailElements}
                  actionType={actionTypeText}
                  loading={loading}
                  isCloningRuleDetail={isCloningRuleDetail}
                  isPostingRuleDetail={isPostingRuleDetail}
                  putRuleDetails={putRuleDetails}
                  postRuleDetails={postRuleDetails}
                  isShowingCustomerServiceMessageModal={
                    isShowingCustomerServiceMessageModal
                  }
                  selectedRuleRecord={selectedRuleRecord}
                  selectedRuleDetail={selectedRuleDetail}
                  selectedRuleTypeId={selectedRuleTypeId}
                  setIsShowingCustomerServiceMessageModal={
                    setIsShowingCustomerServiceMessageModal
                  }
                  setIsCloningRuleDetail={setIsCloningRuleDetail}
                  setIsPostingRuleDetail={setIsPostingRuleDetail}
                  setIsReadOnlyRuleDetail={setIsReadOnlyRuleDetail}
                />
              ) : null}
            {selectedRuleTypeId === 'RAD' &&
              isShowingReturnAddressModal &&
              detailElements &&
              detailElements.returnAddressList ? (
                <ReturnAddressModal
                  cancelPressed={() => cancelPressed()}
                  loading={loading}
                  postRuleDetails={postRuleDetails}
                  putRuleDetails={putRuleDetails}
                  isCloningRuleDetail={isCloningRuleDetail}
                  isPostingRuleDetail={isPostingRuleDetail}
                  detailElements={detailElements}
                  setIsCloningRuleDetail={setIsCloningRuleDetail}
                  setIsPostingRuleDetail={setIsPostingRuleDetail}
                  setIsShowingReturnAddressModal={setIsShowingReturnAddressModal}
                  isShowingReturnAddressModal={isShowingReturnAddressModal}
                  selectedRuleRecord={selectedRuleRecord}
                  selectedRuleDetail={selectedRuleDetail}
                  selectedRuleTypeId={selectedRuleTypeId}
                  setIsShowingAddNewAddressModal={setIsShowingAddNewAddressModal}
                  isShowingAddNewAddressModal={isShowingAddNewAddressModal}
                  returnAddressElements={returnAddressElements}
                  postAddNewAddressElements={postAddNewAddressElements}
                  setIsShowingImageLibraryModal={setIsShowingImageLibraryModal}
                  isShowingImageLibraryModal={isShowingImageLibraryModal}
                  setIsShowingImageUploadModal={setIsShowingImageUploadModal}
                  isShowingImageUploadModal={isShowingImageUploadModal}
                  postImageUpload={postImageUpload}
                  getRuleImages={getRuleImages}
                  ruleImages={ruleImages}
                  getRuleImagesCount={getRuleImagesCount}
                  ruleImagesCount={ruleImagesCount}
                  resetRuleImagesData={resetRuleImagesData}
                  convertImageUpload={convertImageUpload}
                  convertedImageUploadData={convertedImageUploadData}
                  convertedImageUploadError={convertedImageUploadError}
                  postImageUploadSuccessId={postImageUploadSuccessId}
                  postCommitImage={postCommitImage}
                  setIsShowingCommitImageModal={setIsShowingCommitImageModal}
                  isShowingCommitImageModal={isShowingCommitImageModal}
                  postImageCommitSuccessId={postImageCommitSuccessId}
                  postCommitImageTriggerCancel={postCommitImageTriggerCancel}
                  setIsReadOnlyRuleDetail={setIsReadOnlyRuleDetail}
                  actionType={actionTypeText}
                />
              ) : null}

            {selectedRuleTypeId === 'DPX' &&
              isShowingDuplexRuleModal &&
              detailElements &&
              detailElements.claimTypesList ? (
                <DuplexRuleModal
                  cancelPressed={() => cancelPressed()}
                  postRuleDetails={postRuleDetails}
                  putRuleDetails={putRuleDetails}
                  detailElements={detailElements}
                  isCloningRuleDetail={isCloningRuleDetail}
                  isEditingRuleDetail={isEditingRuleDetail}
                  isPostingRuleDetail={isPostingRuleDetail}
                  loading={loading}
                  setIsCloningRuleDetail={setIsCloningRuleDetail}
                  setIsPostingRuleDetail={setIsPostingRuleDetail}
                  setIsShowingDuplexRuleModal={setIsShowingDuplexRuleModal}
                  isShowingDuplexRuleModal={isShowingDuplexRuleModal}
                  selectedRecord={selectedRecord}
                  selectedRuleRecord={selectedRuleRecord}
                  selectedRuleDetail={selectedRuleDetail}
                  actionType={actionTypeText}
                  selectedRuleTypeId={selectedRuleTypeId}
                  setIsReadOnlyRuleDetail={setIsReadOnlyRuleDetail}
                />
              ) : null}

            {selectedRuleTypeId === 'SIG' &&
              isShowingSignatureRuleModal &&
              detailElements &&
              detailElements.claimTypesList ? (
                <SignatureRuleModal
                  cancelPressed={() => cancelPressed()}
                  postRuleDetails={postRuleDetails}
                  putRuleDetails={putRuleDetails}
                  detailElements={detailElements}
                  isCloningRuleDetail={isCloningRuleDetail}
                  isEditingRuleDetail={isEditingRuleDetail}
                  isPostingRuleDetail={isPostingRuleDetail}
                  loading={loading}
                  setIsCloningRuleDetail={setIsCloningRuleDetail}
                  setIsPostingRuleDetail={setIsPostingRuleDetail}
                  setIsShowingSignatureRuleModal={setIsShowingSignatureRuleModal}
                  isShowingSignatureRuleModal={isShowingSignatureRuleModal}
                  selectedRecord={selectedRecord}
                  selectedRuleRecord={selectedRuleRecord}
                  selectedRuleDetail={selectedRuleDetail}
                  actionType={actionTypeText}
                  selectedRuleTypeId={selectedRuleTypeId}
                  setIsShowingImageLibraryModal={setIsShowingImageLibraryModal}
                  isShowingImageLibraryModal={isShowingImageLibraryModal}
                  setIsShowingImageUploadModal={setIsShowingImageUploadModal}
                  isShowingImageUploadModal={isShowingImageUploadModal}
                  postImageUpload={postImageUpload}
                  getRuleImages={getRuleImages}
                  ruleImages={ruleImages}
                  getRuleImagesCount={getRuleImagesCount}
                  ruleImagesCount={ruleImagesCount}
                  resetRuleImagesData={resetRuleImagesData}
                  convertImageUpload={convertImageUpload}
                  convertedImageUploadData={convertedImageUploadData}
                  convertedImageUploadError={convertedImageUploadError}
                  postImageUploadSuccessId={postImageUploadSuccessId}
                  postCommitImage={postCommitImage}
                  setIsShowingCommitImageModal={setIsShowingCommitImageModal}
                  isShowingCommitImageModal={isShowingCommitImageModal}
                  postImageCommitSuccessId={postImageCommitSuccessId}
                  postCommitImageTriggerCancel={postCommitImageTriggerCancel}
                  setIsReadOnlyRuleDetail={setIsReadOnlyRuleDetail}
                />
              ) : null}

            {selectedRuleTypeId === 'IMG' &&
              isShowingImageRuleModal &&
              detailElements &&
              detailElements.claimTypesList ? (
                <ImageRuleModal
                  cancelPressed={() => cancelPressed()}
                  postRuleDetails={postRuleDetails}
                  putRuleDetails={putRuleDetails}
                  detailElements={detailElements}
                  isCloningRuleDetail={isCloningRuleDetail}
                  isEditingRuleDetail={isEditingRuleDetail}
                  isPostingRuleDetail={isPostingRuleDetail}
                  loading={loading}
                  setIsCloningRuleDetail={setIsCloningRuleDetail}
                  setIsPostingRuleDetail={setIsPostingRuleDetail}
                  setIsShowingImageRuleModal={setIsShowingImageRuleModal}
                  isShowingImageRuleModal={isShowingImageRuleModal}
                  selectedRecord={selectedRecord}
                  selectedRuleRecord={selectedRuleRecord}
                  selectedRuleDetail={selectedRuleDetail}
                  actionType={actionTypeText}
                  selectedRuleTypeId={selectedRuleTypeId}
                  setIsShowingImageLibraryModal={setIsShowingImageLibraryModal}
                  isShowingImageLibraryModal={isShowingImageLibraryModal}
                  setIsShowingImageUploadModal={setIsShowingImageUploadModal}
                  isShowingImageUploadModal={isShowingImageUploadModal}
                  postImageUpload={postImageUpload}
                  getRuleImages={getRuleImages}
                  ruleImages={ruleImages}
                  getRuleImagesCount={getRuleImagesCount}
                  ruleImagesCount={ruleImagesCount}
                  resetRuleImagesData={resetRuleImagesData}
                  convertImageUpload={convertImageUpload}
                  convertedImageUploadData={convertedImageUploadData}
                  convertedImageUploadError={convertedImageUploadError}
                  postImageUploadSuccessId={postImageUploadSuccessId}
                  postCommitImage={postCommitImage}
                  setIsShowingCommitImageModal={setIsShowingCommitImageModal}
                  isShowingCommitImageModal={isShowingCommitImageModal}
                  postImageCommitSuccessId={postImageCommitSuccessId}
                  postCommitImageTriggerCancel={postCommitImageTriggerCancel}
                  setIsReadOnlyRuleDetail={setIsReadOnlyRuleDetail}
                />
              ) : null}

            {selectedRuleTypeId === 'CHT' &&
              isShowingCheckTextRuleModal &&
              detailElements &&
              detailElements.claimTypesList ? (
                <CheckTextRuleModal
                  cancelPressed={() => cancelPressed()}
                  postRuleDetails={postRuleDetails}
                  putRuleDetails={putRuleDetails}
                  detailElements={detailElements}
                  isCloningRuleDetail={isCloningRuleDetail}
                  isEditingRuleDetail={isEditingRuleDetail}
                  isPostingRuleDetail={isPostingRuleDetail}
                  loading={loading}
                  setIsCloningRuleDetail={setIsCloningRuleDetail}
                  setIsPostingRuleDetail={setIsPostingRuleDetail}
                  setIsShowingCheckTextRuleModal={setIsShowingCheckTextRuleModal}
                  isShowingCheckTextRuleModal={isShowingCheckTextRuleModal}
                  selectedRecord={selectedRecord}
                  selectedRuleRecord={selectedRuleRecord}
                  selectedRuleDetail={selectedRuleDetail}
                  actionType={actionTypeText}
                  selectedRuleTypeId={selectedRuleTypeId}
                  setIsShowingImageLibraryModal={setIsShowingImageLibraryModal}
                  isShowingImageLibraryModal={isShowingImageLibraryModal}
                  setIsShowingImageUploadModal={setIsShowingImageUploadModal}
                  isShowingImageUploadModal={isShowingImageUploadModal}
                  postImageUpload={postImageUpload}
                  getRuleImages={getRuleImages}
                  ruleImages={ruleImages}
                  getRuleImagesCount={getRuleImagesCount}
                  ruleImagesCount={ruleImagesCount}
                  resetRuleImagesData={resetRuleImagesData}
                  convertImageUpload={convertImageUpload}
                  convertedImageUploadData={convertedImageUploadData}
                  convertedImageUploadError={convertedImageUploadError}
                  postImageUploadSuccessId={postImageUploadSuccessId}
                  postCommitImage={postCommitImage}
                  setIsShowingCommitImageModal={setIsShowingCommitImageModal}
                  isShowingCommitImageModal={isShowingCommitImageModal}
                  postImageCommitSuccessId={postImageCommitSuccessId}
                  postCommitImageTriggerCancel={postCommitImageTriggerCancel}
                  setIsReadOnlyRuleDetail={setIsReadOnlyRuleDetail}
                />
              ) : null}

            {selectedRuleTypeId === 'HLD' &&
              isShowingAutoHoldModal &&
              detailElements &&
              detailElements.claimTypesList ? (
                <AutoHoldModal
                  cancelPressed={() => cancelPressed()}
                  postRuleDetails={postRuleDetails}
                  putRuleDetails={putRuleDetails}
                  detailElements={detailElements}
                  isCloningRuleDetail={isCloningRuleDetail}
                  isEditingRuleDetail={isEditingRuleDetail}
                  isPostingRuleDetail={isPostingRuleDetail}
                  loading={loading}
                  setIsCloningRuleDetail={setIsCloningRuleDetail}
                  setIsPostingRuleDetail={setIsPostingRuleDetail}
                  setIsShowingAutoHoldModal={setIsShowingAutoHoldModal}
                  isShowingAutoHoldModal={isShowingAutoHoldModal}
                  selectedRecord={selectedRecord}
                  selectedRuleRecord={selectedRuleRecord}
                  selectedRuleDetail={selectedRuleDetail}
                  actionType={actionTypeText}
                  selectedRuleTypeId={selectedRuleTypeId}
                  setIsReadOnlyRuleDetail={setIsReadOnlyRuleDetail}
                />
              ) : null}
            {selectedRuleTypeId === 'SSN' &&
              isShowingMemberIdModal &&
              detailElements &&
              detailElements.claimTypesList ? (
                <MemberIdModal
                  cancelPressed={() => cancelPressed()}
                  postRuleDetails={postRuleDetails}
                  putRuleDetails={putRuleDetails}
                  detailElements={detailElements}
                  isCloningRuleDetail={isCloningRuleDetail}
                  isEditingRuleDetail={isEditingRuleDetail}
                  isPostingRuleDetail={isPostingRuleDetail}
                  loading={loading}
                  setIsCloningRuleDetail={setIsCloningRuleDetail}
                  setIsPostingRuleDetail={setIsPostingRuleDetail}
                  setIsShowingMemberIdModal={setIsShowingMemberIdModal}
                  isShowingMemberIdModal={isShowingMemberIdModal}
                  selectedRecord={selectedRecord}
                  selectedRuleRecord={selectedRuleRecord}
                  selectedRuleDetail={selectedRuleDetail}
                  actionType={actionTypeText}
                  selectedRuleTypeId={selectedRuleTypeId}
                  setIsReadOnlyRuleDetail={setIsReadOnlyRuleDetail}
                />
              ) : null}
            {selectedRuleTypeId === 'TAX' &&
              isShowingTaxIdArrangementRuleModal &&
              detailElements &&
              detailElements.claimTypesList ? (
                <TaxIdArrangementRuleModal
                  cancelPressed={() => cancelPressed()}
                  postRuleDetails={postRuleDetails}
                  putRuleDetails={putRuleDetails}
                  detailElements={detailElements}
                  isCloningRuleDetail={isCloningRuleDetail}
                  isEditingRuleDetail={isEditingRuleDetail}
                  isPostingRuleDetail={isPostingRuleDetail}
                  loading={loading}
                  setIsCloningRuleDetail={setIsCloningRuleDetail}
                  setIsPostingRuleDetail={setIsPostingRuleDetail}
                  setIsShowingTaxIdArrangementRuleModal={
                    setIsShowingTaxIdArrangementRuleModal
                  }
                  isShowingTaxIdArrangementRuleModal={
                    isShowingTaxIdArrangementRuleModal
                  }
                  selectedRecord={selectedRecord}
                  selectedRuleRecord={selectedRuleRecord}
                  selectedRuleDetail={selectedRuleDetail}
                  actionType={actionTypeText}
                  selectedRuleTypeId={selectedRuleTypeId}
                  setIsReadOnlyRuleDetail={setIsReadOnlyRuleDetail}
                />
              ) : null}
            {selectedRuleTypeId === 'ERI' &&
              isShowingErisaMessageModal &&
              detailElements &&
              detailElements.claimTypesList ? (
                <ErisaMessageModal
                  cancelPressed={() => cancelPressed()}
                  postRuleDetails={postRuleDetails}
                  putRuleDetails={putRuleDetails}
                  detailElements={detailElements}
                  isCloningRuleDetail={isCloningRuleDetail}
                  isEditingRuleDetail={isEditingRuleDetail}
                  isPostingRuleDetail={isPostingRuleDetail}
                  loading={loading}
                  setIsCloningRuleDetail={setIsCloningRuleDetail}
                  setIsPostingRuleDetail={setIsPostingRuleDetail}
                  setIsShowingErisaMessageModal={setIsShowingErisaMessageModal}
                  isShowingErisaMessageModal={isShowingErisaMessageModal}
                  selectedRecord={selectedRecord}
                  selectedRuleRecord={selectedRuleRecord}
                  selectedRuleDetail={selectedRuleDetail}
                  actionType={actionTypeText}
                  selectedRuleTypeId={selectedRuleTypeId}
                  setIsReadOnlyRuleDetail={setIsReadOnlyRuleDetail}
                />
              ) : null}
            {selectedRuleTypeId === 'HDR' &&
              isShowingHighDollarReviewModal &&
              detailElements &&
              detailElements.claimTypesList ? (
                <HighDollarReviewModal
                  cancelPressed={() => cancelPressed()}
                  postRuleDetails={postRuleDetails}
                  putRuleDetails={putRuleDetails}
                  detailElements={detailElements}
                  isCloningRuleDetail={isCloningRuleDetail}
                  isEditingRuleDetail={isEditingRuleDetail}
                  isPostingRuleDetail={isPostingRuleDetail}
                  loading={loading}
                  setIsCloningRuleDetail={setIsCloningRuleDetail}
                  setIsPostingRuleDetail={setIsPostingRuleDetail}
                  setShowingHighDollarReviewModal={
                    setShowingHighDollarReviewModal
                  }
                  isShowingHighDollarReviewModal={isShowingHighDollarReviewModal}
                  selectedRecord={selectedRecord}
                  selectedRuleRecord={selectedRuleRecord}
                  selectedRuleDetail={selectedRuleDetail}
                  actionType={actionTypeText}
                  selectedRuleTypeId={selectedRuleTypeId}
                  setIsReadOnlyRuleDetail={setIsReadOnlyRuleDetail}
                />
              ) : null}
            {selectedRuleTypeId === 'PIO' &&
              isShowingProviderInsuredOptionMessageModal &&
              detailElements &&
              detailElements.claimTypesList ? (
                <ProviderInsuredOptionMessageModal
                  cancelPressed={() => cancelPressed()}
                  postRuleDetails={postRuleDetails}
                  putRuleDetails={putRuleDetails}
                  detailElements={detailElements}
                  isCloningRuleDetail={isCloningRuleDetail}
                  isEditingRuleDetail={isEditingRuleDetail}
                  isPostingRuleDetail={isPostingRuleDetail}
                  loading={loading}
                  setIsCloningRuleDetail={setIsCloningRuleDetail}
                  setIsPostingRuleDetail={setIsPostingRuleDetail}
                  setIsShowingProviderInsuredOptionMessageModal={
                    setIsShowingProviderInsuredOptionMessageModal
                  }
                  isShowingProviderInsuredOptionMessageModal={
                    isShowingProviderInsuredOptionMessageModal
                  }
                  selectedRecord={selectedRecord}
                  selectedRuleRecord={selectedRuleRecord}
                  selectedRuleDetail={selectedRuleDetail}
                  actionType={actionTypeText}
                  selectedRuleTypeId={selectedRuleTypeId}
                  setIsReadOnlyRuleDetail={setIsReadOnlyRuleDetail}
                />
              ) : null}
            {selectedRuleTypeId === 'GEN' &&
              isShowingGeneralMessageModal &&
              detailElements &&
              detailElements.claimTypesList ? (
                <GeneralMessageModal
                  cancelPressed={() => cancelPressed()}
                  postRuleDetails={postRuleDetails}
                  putRuleDetails={putRuleDetails}
                  detailElements={detailElements}
                  isCloningRuleDetail={isCloningRuleDetail}
                  isEditingRuleDetail={isEditingRuleDetail}
                  isPostingRuleDetail={isPostingRuleDetail}
                  loading={loading}
                  setIsCloningRuleDetail={setIsCloningRuleDetail}
                  setIsPostingRuleDetail={setIsPostingRuleDetail}
                  setIsShowingGeneralMessageModal={
                    setIsShowingGeneralMessageModal
                  }
                  isShowingGeneralMessageModal={isShowingGeneralMessageModal}
                  selectedRecord={selectedRecord}
                  selectedRuleRecord={selectedRuleRecord}
                  selectedRuleDetail={selectedRuleDetail}
                  actionType={actionTypeText}
                  selectedRuleTypeId={selectedRuleTypeId}
                  setIsReadOnlyRuleDetail={setIsReadOnlyRuleDetail}
                />
              ) : null}
            {selectedRuleTypeId === 'PDT' &&
              isShowingPDFTaggingModal &&
              detailElements &&
              detailElements.claimTypesList ? (
                <PDFTaggingModal
                  cancelPressed={() => cancelPressed()}
                  postRuleDetails={postRuleDetails}
                  putRuleDetails={putRuleDetails}
                  detailElements={detailElements}
                  isCloningRuleDetail={isCloningRuleDetail}
                  isEditingRuleDetail={isEditingRuleDetail}
                  isPostingRuleDetail={isPostingRuleDetail}
                  loading={loading}
                  setIsCloningRuleDetail={setIsCloningRuleDetail}
                  setIsPostingRuleDetail={setIsPostingRuleDetail}
                  setIsShowingPDFTaggingModal={setIsShowingPDFTaggingModal}
                  isShowingPDFTaggingModal={isShowingPDFTaggingModal}
                  selectedRecord={selectedRecord}
                  selectedRuleRecord={selectedRuleRecord}
                  selectedRuleDetail={selectedRuleDetail}
                  actionType={actionTypeText}
                  selectedRuleTypeId={selectedRuleTypeId}
                  setIsReadOnlyRuleDetail={setIsReadOnlyRuleDetail}
                />
              ) : null}
          </Modal>
        </PaddingWrapperEditTab>
      </>
    );
  }
}

AdvancedGridWrapper.propTypes = {
  records: PropTypes.array.isRequired,
  gridConfig: PropTypes.shape({ columns: PropTypes.shape({}) }).isRequired,
  getRuleDetails: PropTypes.func.isRequired,
  setIsViewingRuleDetails: PropTypes.func,
  selectedRuleRecord: PropTypes.object,
  selectedRuleTypeId: PropTypes.string,
  columns: PropTypes.object,
  headerText: PropTypes.string,
  headerCount: PropTypes.number,
  isCloningRuleDetail: PropTypes.bool,
  onRowClickThrough: PropTypes.func,
  goBackFromAdvancedToDefaultView: PropTypes.func,
  cancelPressed: PropTypes.func,
  detailElements: PropTypes.object,
  setSelectedRuleDetail: PropTypes.func,
  getRuleDetailElements: PropTypes.func,
  setIsShowingProductionArrangementModal: PropTypes.func,
  setIsShowingCustomerServiceMessageModal: PropTypes.func,
  setIsShowingReturnAddressModal: PropTypes.func,
  setIsShowingDuplexRuleModal: PropTypes.func,
  setIsShowingSignatureRuleModal: PropTypes.func,
  setIsShowingCheckTextRuleModal: PropTypes.func,
  setIsShowingImageRuleModal: PropTypes.func,
  setIsShowingAutoHoldModal: PropTypes.func,
  setIsShowingMemberIdModal: PropTypes.func,
  setIsShowingTaxIdArrangementRuleModal: PropTypes.func,
  setIsShowingErisaMessageModal: PropTypes.func,
  setShowingHighDollarReviewModal: PropTypes.func,
  setIsShowingProviderInsuredOptionMessageModal: PropTypes.func,
  setIsShowingGeneralMessageModal: PropTypes.func,
  setIsShowingPDFTaggingModal: PropTypes.func,
  isCloningRule: PropTypes.bool,
  getAddNewAddressElements: PropTypes.func,
  setIsShowingImageLibraryModal: PropTypes.func,
  isShowingImageLibraryModal: PropTypes.bool,
  setIsShowingImageUploadModal: PropTypes.func,
  isShowingImageUploadModal: PropTypes.bool,
  postImageUpload: PropTypes.func,
  getRuleImages: PropTypes.func,
  ruleImages: PropTypes.object,
  getRuleImagesCount: PropTypes.func,
  ruleImagesCount: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  resetRuleImagesData: PropTypes.func,
  convertImageUpload: PropTypes.func,
  convertedImageUploadData: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  convertedImageUploadError: PropTypes.string,
  postImageUploadSuccessId: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  postImageCommitSuccessId: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  ruleDetails: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  rulesForRuleTypes: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
};

const mapStateToProps = state => ({
  ...state,
  ...state.ruleDetails,
  isShowingDeleteRuleDetailModal:
    state.ruleTypesList.isShowingDeleteRuleDetailModal,
  detailElements: selectDetailElements(state),
  loading: selectLoadingState(state),
  selectedRuleDetail: selectSelectedRuleDetail(state),
  records: selectRenderSafeRecords(state),
  gridConfig: selectGridConfig(state),
  isCloningRuleDetail: state.ruleDetails.isCloningRuleDetail,
  isEditingRuleDetail: selectIsEditingRuleDetail(state),
  isPostingRuleDetail: state.ruleDetails.isPostingRuleDetail,
  isShowingReturnAddressModal: selectReturnAddressModalState(state),
  isShowingDuplexRuleModal: selectDuplexRuleModalState(state),
  isShowingSignatureRuleModal: selectSignatureRuleModalState(state),
  isShowingCheckTextRuleModal: selectCheckTextRuleModalState(state),
  isShowingImageRuleModal: selectImageRuleModalState(state),
  isShowingAutoHoldModal: selectAutoHoldModalState(state),
  isShowingMemberIdModal: selectMemberIdModalState(state),
  isShowingProductionArrangementModal: selectProductionArrangementModalState(
    state,
  ),
  isShowingCustomerServiceMessageModal: selectCustomerServiceMessageModalState(
    state,
  ),
  isShowingTaxIdArrangementRuleModal: selectTaxIdArrangementState(state),
  isShowingErisaMessageModal: selectErisaMessageModalState(state),
  isShowingHighDollarReviewModal: selectHighDollarReviewModalState(state),
  isShowingProviderInsuredOptionMessageModal: selectProviderInsuredOptionMessageModalState(
    state,
  ),
  isShowingGeneralMessageModal: selectGeneralMessageModalState(state),
  isShowingPDFTaggingModal: selectPDFTaggingModalState(state),
  selectedRuleRecord: state.ruleTypesList.selectedRuleRecord,
  selectedRuleTypeId: state.ruleTypesList.selectedRuleTypeId,
  returnAddressElements: selectReturnAddressElements(state),
  ruleImages: selectRuleImages(state),
  ruleImagesCount: selectRuleImagesCount(state),
  convertedImageUploadData: selectConvertedImageUploadData(state),
  convertedImageUploadError: selectConvertedImageUploadError(state),
  postImageUploadSuccessId: selectPostImageUploadSuccessId(state),
  isShowingCommitImageModal: selectIsShowingCommitImageModal(state),
  postImageCommitSuccessId: selectPostImageCommitSuccessId(state),
});

const mapDispatchToProps = dispatch => ({
  getRuleDetailElements: ({ selectedRuleTypeId }) =>
    dispatch(ruleDetailElementsTrigger({ selectedRuleTypeId })),
  getRuleDetails: (...args) => dispatch(triggerRuleDetailsRequest(...args)),
  goBackFromAdvancedToDefaultView: () =>
    dispatch(
      setIsViewingRuleDetails({
        isAdvancedViewShown: false,
        selectedRuleRecord: {},
      }),
    ),
  postRuleDetails: (...args) => dispatch(ruleDetailPostRequestTrigger(...args)),
  putRuleDetails: (...args) => dispatch(ruleDetailPutRequestTrigger(...args)),
  setIsCloningRuleDetail: bool => dispatch(setIsCloningRuleDetail(bool)),
  setIsEditingRuleDetail: bool => dispatch(setIsEditingRuleDetail(bool)),
  setIsPostingRuleDetail: bool => dispatch(setIsPostingRuleDetail(bool)),
  setIsReadOnlyRuleDetail: bool => dispatch(setIsReadOnlyRuleDetail(bool)),
  setIsShowingReturnAddressModal: bool =>
    dispatch(setIsShowingReturnAddressModal(bool)),
  setIsShowingDuplexRuleModal: bool =>
    dispatch(setIsShowingDuplexRuleModal(bool)),
  setIsShowingAutoHoldModal: bool => dispatch(setIsShowingAutoHoldModal(bool)),
  setIsShowingMemberIdModal: bool => dispatch(setIsShowingMemberIdModal(bool)),
  setIsShowingProductionArrangementModal: bool =>
    dispatch(setIsShowingProductionArrangementModal(bool)),
  setIsShowingCustomerServiceMessageModal: bool =>
    dispatch(setIsShowingCustomerServiceMessageModal(bool)),
  setIsShowingSignatureRuleModal: bool =>
    dispatch(setIsShowingSignatureRuleModal(bool)),
  setIsShowingCheckTextRuleModal: bool =>
    dispatch(setIsShowingCheckTextRuleModal(bool)),
  setIsShowingImageRuleModal: bool =>
    dispatch(setIsShowingImageRuleModal(bool)),
  setIsShowingTaxIdArrangementRuleModal: bool =>
    dispatch(setIsShowingTaxIdArrangementRuleModal(bool)),
  setIsShowingErisaMessageModal: bool =>
    dispatch(setIsShowingErisaMessageModal(bool)),
  setShowingHighDollarReviewModal: bool =>
    dispatch(setShowingHighDollarReviewModal(bool)),
  setIsShowingProviderInsuredOptionMessageModal: bool =>
    dispatch(setIsShowingProviderInsuredOptionMessageModal(bool)),
  setIsShowingGeneralMessageModal: bool =>
    dispatch(setIsShowingGeneralMessageModal(bool)),
  setIsShowingPDFTaggingModal: bool =>
    dispatch(setIsShowingPDFTaggingModal(bool)),
  setIsShowingDeleteRuleDetailModal: payload =>
    dispatch(setIsShowingDeleteRuleDetailModal(payload)),
  deleteRuleDetailTrigger: (...args) =>
    dispatch(deleteRuleDetailTrigger(...args)),
  getAddNewAddressElements: payload =>
    dispatch(getAddNewAddressElementsTrigger(payload)),
  postAddNewAddressElements: payload =>
    dispatch(postAddNewAddressElementsTrigger(payload)),
  setIsShowingAddNewAddressModal: bool =>
    dispatch(setIsShowingAddNewAddressModal(bool)),
  setIsShowingImageLibraryModal: (ruleType, selectedRecordId, bool) =>
    dispatch(setIsShowingImageLibraryModal(ruleType, selectedRecordId, bool)),
  setIsShowingImageUploadModal: (ruleType, selectedRecordId, bool) =>
    dispatch(setIsShowingImageUploadModal(ruleType, selectedRecordId, bool)),
  postImageUpload: payload =>
    dispatch(postImageUploadTrigger(payload)),
  getRuleImages: payload =>
    dispatch(getRuleImagesTrigger(payload)),
  getRuleImagesCount: payload =>
    dispatch(getRuleImagesCountTrigger(payload)),
  resetRuleImagesData: payload =>
    dispatch(resetRuleImagesDataTrigger(payload)),
  convertImageUpload: payload =>
    dispatch(convertImageUploadTrigger(payload)),
  postCommitImage: payload =>
    dispatch(postCommitImageTrigger(payload)),
  setIsShowingCommitImageModal: payload =>
    dispatch(setIsShowingCommitImageModal(payload)),
  postCommitImageTriggerCancel: payload =>
    dispatch(postCommitImageTriggerCancel(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdvancedGridWrapper);
