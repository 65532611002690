
const initialState = {
  ManageAddressRecord:null,
  ManageAddressMode:'',
  sortOrders: [], 
  alternateAddress:[],
  returnLogos:[],
  returnStyles:[],
  refetchManageAddressRecord:()=>false,
  resetControls: () => {},
}

export default (state = initialState, { type, payload }) => {
  switch(type) {
   
    case 'SET_MANAGE_ADDRESS_RECORD':{
      return {
        ...state,
        ManageAddressRecord: JSON.parse(JSON.stringify(payload.ManageAddressRecord)),
        sortOrders: JSON.parse(JSON.stringify(payload.sortOrders||[])),
        ManageAddressMode: payload.mode,
        refetchManageAddressRecord: payload.refetchManageAddressRecord,
        alternateAddress: JSON.parse(JSON.stringify(payload.alternateAddress||[])),
        returnLogos: JSON.parse(JSON.stringify(payload.returnLogo||[])),
        returnStyles: JSON.parse(JSON.stringify(payload.returnStyle||[])),
        resetControls: payload.resetControls
      }
    }
    case 'RESET_MANAGE_ADDRESS_RECORD':{
      return {
        ...state,
        ManageAddressRecord:null,
        sortOrders: [],
        ManageAddressMode:'',
        refetchManageAddressRecord:()=>false,
        resetControls: () => {}
      }
    }

    default:
      return state;
  }
}