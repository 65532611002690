import React, { useState, useEffect } from 'react'
import {
  useLayout,
  ListPaneHeader,
} from 'ui-core/dist'
import axios from '../../../axios'
import useDeliveryReport from '../../../api/useDeliveryReport'
import DeliveryReportModal from './DeliveryReportModal';
import { Loading } from '../../../components'
import us_map from '../../../assets/icons/USAGold.jpg';
import graphic from '../../../assets/icons/TTGraphic.jpeg';
import withAxiosCancellation from '../../../components/withAxiosCancellation';
import './index.scss'

const statesMap = {
  "AL": "Alabama",
  "AK": "Alaska",
  "AS": "American Samoa",
  "AZ": "Arizona",
  "AR": "Arkansas",
  "CA": "California",
  "CO": "Colorado",
  "CT": "Connecticut",
  "DE": "Delaware",
  "DC": "District Of Columbia",
  "FM": "Federated States Of Micronesia",
  "FL": "Florida",
  "GA": "Georgia",
  "GU": "Guam",
  "HI": "Hawaii",
  "ID": "Idaho",
  "IL": "Illinois",
  "IN": "Indiana",
  "IA": "Iowa",
  "KS": "Kansas",
  "KY": "Kentucky",
  "LA": "Louisiana",
  "ME": "Maine",
  "MH": "Marshall Islands",
  "MD": "Maryland",
  "MA": "Massachusetts",
  "MI": "Michigan",
  "MN": "Minnesota",
  "MS": "Mississippi",
  "MO": "Missouri",
  "MT": "Montana",
  "NE": "Nebraska",
  "NV": "Nevada",
  "NH": "New Hampshire",
  "NJ": "New Jersey",
  "NM": "New Mexico",
  "NY": "New York",
  "NC": "North Carolina",
  "ND": "North Dakota",
  "MP": "Northern Mariana Islands",
  "OH": "Ohio",
  "OK": "Oklahoma",
  "OR": "Oregon",
  "PW": "Palau",
  "PA": "Pennsylvania",
  "PR": "Puerto Rico",
  "RI": "Rhode Island",
  "SC": "South Carolina",
  "SD": "South Dakota",
  "TN": "Tennessee",
  "TX": "Texas",
  "UT": "Utah",
  "VT": "Vermont",
  "VI": "Virgin Islands",
  "VA": "Virginia",
  "WA": "Washington",
  "WV": "West Virginia",
  "WI": "Wisconsin",
  "WY": "Wyoming"
};

const DeliveryReportContent = ({getCancelSourceWithName}) => {
  const [response, setResponse] = useState({});
  const [currentState, setCurrentState] = useState('');
  const [modalContent, setModalContent] = useState({
    isAccessOpen: false,
    data: '',
  })

  const { expandCollapseListPaneButtonConfig } = useLayout()
  const { data, isLoading } = useDeliveryReport({getCancelSource:getCancelSourceWithName('availableGenericReports')})

  useEffect(() => {
    if (data) {
      const dataArr = data.split('~')
      const deliveryDates = dataArr.slice(0, 5)
      const parsedObj = {}
      parsedObj.startDate = deliveryDates[1]
      parsedObj.endDate = deliveryDates[2]
      dataArr.splice(0, 5)
      let state;
      let otherStates = ['GU', 'PR', 'VI'];
      dataArr.forEach((ele, idx) => {
        if (idx % 5 === 0) {
          if (otherStates.indexOf(ele) !== -1) state = 'others';
          else state = ele;
          parsedObj[state] = parsedObj[state] ? parsedObj[state] : {}
        }
        if (idx % 5 === 1) parsedObj[state].packageCount = ele;
        if (idx % 5 === 2) parsedObj[state].avgDays = ele;
        if (idx % 5 === 3) parsedObj[state].nickName = ele;
        if (idx % 5 === 4) {
          const statesData = ele.split('|');
          const dataArr = [];
          parsedObj[state].data = parsedObj[state].data ? parsedObj[state].data : { totalRecords: data[0], records: dataArr }
          statesData.splice(0, 1);
          // let inc = -1;
          let len;
          statesData.forEach((dataEle, dataIdx) => {
            // const incIdx = dataIdx / 4;
            // if (Number.isInteger(incIdx)) inc++;
            if (dataIdx % 4 === 0) {
              len = parsedObj[state].data.records.length ? parsedObj[state].data.records.length : 0;
              parsedObj[state].data.records.push({});
              parsedObj[state].data.records[len].zipCode = dataEle;
            }
            if (dataIdx % 4 === 1) parsedObj[state].data.records[len].center = dataEle;
            if (dataIdx % 4 === 2) parsedObj[state].data.records[len].packageCount = dataEle;
            if (dataIdx % 4 === 3) parsedObj[state].data.records[len].avgDays = dataEle;
          })
        }
      })
      setResponse(parsedObj);
    }
  }, [data, isLoading])

  const getAvgDays = (days, flag, len, state) => {
    if (state === 'others') return 'Click for Details'
    if (!len && !flag) return 'No Data Available';
    if (!days) return flag ? "0.00 Days" : "Avg. Delivery " + '0.00' + " Days"
    let stringData = '';
    const HA100 = Math.round(days * 100)
    if (HA100 == 100 * Math.round(HA100 / 100)) {
      stringData = HA100 / 100 + ".00 Days"
    }
    else {
      if (HA100 / 10 == Math.round(HA100 / 10)) {
        stringData = HA100 / 100 + "0 Days"
      }
      else {
        stringData = HA100 / 100 + " Days"
      }
    }

    return flag ? stringData : "Avg. Delivery " + stringData;
  }

  const ietruebody = () => {
    return (document.compatMode && document.compatMode != "BackCompat") ? document.documentElement : document.body
  }

  const addCommas = (str) => {
    const nStr = str + '';
    const x = nStr.split('.');
    let x1 = x[0];
    const x2 = x.length > 1 ? '.' + x[1] : '';
    const rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }

    return x1 + x2;
  }

  const positionToolTip = (event) => {
    const tipobj = document.getElementById('xtooltip');
    const tipobj2 = document.getElementById('xtooltip2');
    const offsetxpoint = -60   // x-offset of tooltip
    const offsetypoint = 20    // y-offset of tooltip
    const ie = document.all
    const ns6 = document.getElementById && !document.all
    const curX = (ns6) ? event.pageX : event.clientX + ietruebody().scrollLeft;
    const curY = (ns6) ? event.pageY : event.clientY + ietruebody().scrollTop;
    const rightedge = ie && !window.opera ? ietruebody().clientWidth - event.clientX - offsetxpoint : window.innerWidth - event.clientX - offsetxpoint - 20
    const bottomedge = ie && !window.opera ? ietruebody().clientHeight - event.clientY - offsetypoint : window.innerHeight - event.clientY - offsetypoint - 20
    const leftedge = (offsetxpoint < 0) ? offsetxpoint * (-1) : -1000
    if (rightedge < tipobj.offsetWidth)
      tipobj.style.left = ie ? ietruebody().scrollLeft + event.clientX - tipobj.offsetWidth + "px" : window.pageXOffset + event.clientX - tipobj.offsetWidth + "px"
    else if (curX < leftedge)
      tipobj.style.left = "5px"
    else
      tipobj.style.left = curX + offsetxpoint + "px"
    if (bottomedge < tipobj.offsetHeight)
      tipobj.style.top = ie ? ietruebody().scrollTop + event.clientY - tipobj.offsetHeight - offsetypoint + "px" : window.pageYOffset + event.clientY - tipobj.offsetHeight - offsetypoint + "px"
    else
      tipobj.style.top = curY + offsetypoint + "px"
    tipobj.style.visibility = "visible"
    if (rightedge < tipobj2.offsetWidth)
      tipobj2.style.left = ie ? ietruebody().scrollLeft + event.clientX - tipobj2.offsetWidth + "px" : window.pageXOffset + event.clientX - tipobj2.offsetWidth + "px"
    else if (curX < leftedge)
      tipobj2.style.left = "5px"
    else
      tipobj2.style.left = curX + offsetxpoint + "px"
    if (bottomedge < tipobj.offsetHeight)
      tipobj2.style.top = ie ? ietruebody().scrollTop + event.clientY - tipobj2.offsetHeight - offsetypoint + "px" : window.pageYOffset + event.clientY - tipobj2.offsetHeight - offsetypoint + "px"
    else
      tipobj2.style.top = curY + offsetypoint + "px"
    tipobj2.style.visibility = "visible"
  }

  const showToolTip = (evt, state) => {
    const tipobj = document.getElementById('xtooltip');
    const HoverInfo = `<tr><td><center><font color='#000000'><small><b>${response[state]?.nickName || 'NA'}</b></small></font></center></td></tr>`
    tipobj.style.width = "200px"
    tipobj.style.backgroundColor = "tan"
    tipobj.innerHTML = `<table id='ToolTipTable' width='100%' border=0 background=${graphic}><tr><td><center><font color='#000000'><small><b>${statesMap[state] || 'Other Territories'}</b></small></font></center></td></tr>${state === 'others' ? '' : HoverInfo}<tr><td><center><font color='#000000'><small><b>${getAvgDays(response[state]?.avgDays, false, response[state]?.data?.records?.length, state)}</b></small></font></center></td></tr></table>`
    positionToolTip(evt)
  }

  const hideToolTip = () => {
    const tipobj = document.getElementById('xtooltip');
    tipobj.style.visibility = "hidden"
    tipobj.style.left = "-1000px"
    tipobj.style.backgroundColor = ''
    tipobj.style.width = ''
  }

  const showDetails = (state) => { setCurrentState(state); }

  const showModal = async (zip) => {
    try {
      const res = await axios.get(`/reports-manager/delivery-times/${zip}`);
      setModalContent({
        ...modalContent,
        isAccessOpen: true,
        data: res && res.data && res.data.report
      })
    } catch (err) {
      // Catch error
    }
  }

  const onAccessClose = () => {
    setModalContent({
      isAccessOpen: false,
      data: '',
    })
  }

  return (
    <>
      <ListPaneHeader
        title='USPS Delivery Times'
        layoutControls={[expandCollapseListPaneButtonConfig]}
      />
      <div className="deliveryReport-home" style={{ marginLeft: '40px' }}>
        {isLoading ? <Loading /> : (
          <div>
            <img alt="US Map" src={us_map} useMap='#usamap' className="no-border" name='map' />
            <map name='usamap'>
              <area shape='POLY'
                coords='224,212,258,213,258,232,256,234,256,240,261,245,265,243,267,247,271,247,273,249,280,249,281,251,286,251,287,253,291,251,295,253,305,253,306,251,314,251,319,255,325,255,326,278,330,282,330,288,332,291,330,299,330,308,328,312,318,313,317,311,316,318,311,323,305,325,304,327,297,327,296,331,293,331,290,334,290,338,286,340,286,345,284,346,284,356,286,358,286,365,282,365,281,363,273,363,269,359,265,359,262,356,260,348,258,346,258,342,254,339,254,336,248,330,248,326,246,325,244,318,235,309,228,309,227,307,225,307,216,319,209,317,198,309,196,306,196,296,191,291,189,291,188,288,178,279,177,275,177,273,198,273,199,275,218,274,218,271,220,270,220,246,222,244,222,221,224,220'
                onClick={() => { showDetails('TX') }} onMouseOver={(e) => { showToolTip(e, 'TX') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='POLY'
                coords='225,207,317,208,319,224,319,254,315,254,312,252,302,252,301,254,297,254,296,252,281,252,278,248,276,248,275,250,273,250,272,248,268,248,264,244,259,244,257,242,257,217,259,215,258,214,240,214,238,212,224,212'
                onClick={() => { showDetails('OK') }} onMouseOver={(e) => { showToolTip(e, 'OK') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='POLY'
                coords='374,59,378,59,378,63,376,65,377,66,383,66,387,70,393,70,395,68,399,68,400,66,408,66,409,68,416,68,419,72,419,74,417,74,416,76,403,76,398,80,391,80,390,84,388,85,388,88,382,87,382,83,379,80,375,80,373,78,370,78,369,76,360,76,359,74,359,70,373,62'
                onClick={() => { showDetails('MI') }} onMouseOver={(e) => { showToolTip(e, 'MI') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='POLY'
                coords='411,79,427,83,427,89,429,90,429,98,423,105,426,106,431,100,436,100,437,105,439,106,440,116,440,120,437,121,437,124,435,125,435,130,432,134,416,134,409,136,399,136,401,128,403,127,403,121,401,120,401,117,399,115,398,108,399,94,403,90,405,90,406,92,407,91,407,85,409,83,409,80'
                onClick={() => { showDetails('MI') }} onMouseOver={(e) => { showToolTip(e, 'MI') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='POLY'
                coords='443,227,447,227,448,232,454,234,458,240,465,244,466,248,471,250,473,255,477,258,477,262,479,264,476,284,473,284,472,282,471,287,462,286,447,288,435,288,435,285,433,284,433,279,431,277,433,265,431,264,429,261,429,257,427,256,427,251,425,249,425,243,423,242,422,234,422,230,426,230,427,228'
                onClick={() => { showDetails('GA') }} onMouseOver={(e) => { showToolTip(e, 'GA') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='POLY'
                coords='355,262,359,262,359,264,361,265,361,276,357,280,355,290,360,291,361,289,377,289,379,299,374,299,371,302,374,303,375,301,378,301,380,303,382,301,385,301,385,305,381,310,383,311,383,315,380,315,376,311,375,316,363,316,352,307,349,311,329,309,329,304,331,302,331,288,329,286,329,282,327,280,326,275,326,263'
                onClick={() => { showDetails('LA') }} onMouseOver={(e) => { showToolTip(e, 'LA') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='POLY'
                coords='544,122,547,122,546,127,543,127,538,131,533,131,533,127'
                onClick={() => { showDetails('NY') }} onMouseOver={(e) => { showToolTip(e, 'NY') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='POLY'
                coords='520,70,526,70,528,74,528,84,532,89,532,96,534,97,534,112,536,119,535,125,523,123,515,115,499,117,497,119,489,119,487,121,479,121,477,123,469,123,470,117,474,114,476,110,474,109,474,103,477,103,478,101,493,101,500,94,500,90,498,89,498,87,502,84,502,80,506,75,508,73,511,73,512,71'
                onClick={() => { showDetails('NY') }} onMouseOver={(e) => { showToolTip(e, 'NY') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='POLY'
                coords='543,61,547,61,547,65,549,67,549,71,553,79,553,85,557,88,557,92,553,96,543,98,540,97,540,76,543,73'
                onClick={() => { showDetails('NH') }} onMouseOver={(e) => { showToolTip(e, 'NH') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='POLY'
                coords='498,157,506,161,506,167,504,169,505,170,511,170,513,172,518,173,518,185,524,186,524,192,519,192,518,194,509,194,507,196,487,198,485,200,473,200,472,202,457,202,448,204,445,204,448,197,455,190,457,190,461,194,462,192,467,192,468,190,473,188,474,182,478,177,478,172,484,171,484,167,490,163,490,158,496,158,497,160'
                onClick={() => { showDetails('VA') }} onMouseOver={(e) => { showToolTip(e, 'VA') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='POLY'
                coords='563,25,571,27,575,43,582,50,585,50,586,54,586,58,584,58,583,61,579,64,576,64,569,71,569,74,561,79,559,87,553,86,553,81,551,79,551,75,549,73,549,69,547,67,546,62,546,58,549,57,549,53,551,51,551,33,553,31,553,26,557,26,558,28,559,26'
                onClick={() => { showDetails('ME') }} onMouseOver={(e) => { showToolTip(e, 'ME') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='POLY'
                coords='539,65,542,65,543,68,543,74,539,77,541,78,541,82,539,83,539,91,541,93,541,98,537,99,533,99,531,89,529,88,529,85,527,83,526,72,526,68'
                onClick={() => { showDetails('VT') }} onMouseOver={(e) => { showToolTip(e, 'VT') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='POLY'
                coords='554,93,558,93,558,99,561,100,564,104,564,108,562,109,558,109,555,106,535,109,535,100,537,98,545,98,546,96,551,96'
                onClick={() => { showDetails('MA') }} onMouseOver={(e) => { showToolTip(e, 'MA') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='POLY'
                coords='544,107,551,107,552,110,552,116,549,118,545,118,540,122,536,122,535,114,536,108'
                onClick={() => { showDetails('CT') }} onMouseOver={(e) => { showToolTip(e, 'CT') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='POLY'
                coords='522,123,532,124,534,128,530,133,534,134,535,136,535,146,532,153,522,152,521,150,521,144,526,139,521,134'
                onClick={() => { showDetails('NJ') }} onMouseOver={(e) => { showToolTip(e, 'NJ') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='POLY'
                coords='512,115,516,115,518,119,522,122,520,133,526,138,524,144,517,148,509,148,508,150,499,150,498,152,489,152,488,154,479,154,472,156,466,156,466,147,464,145,463,132,463,124,465,122,469,122,471,124,473,122,481,122,483,120,491,120,493,118,501,118,503,116'
                onClick={() => { showDetails('PA') }} onMouseOver={(e) => { showToolTip(e, 'PA') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='CIRCLE'
                coords='556,211,10'
                onClick={() => { showDetails('MD') }} onMouseOver={(e) => { showToolTip(e, 'MD') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='POLY'
                coords='479,155,480,161,489,155,495,154,503,160,507,162,506,169,514,169,525,171,532,165,528,165,524,165,519,162,515,148,478,155,478,157'
                onClick={() => { showDetails('MD') }} onMouseOver={(e) => { showToolTip(e, 'MD') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='CIRCLE' coords='555,168,9'
                onClick={() => { showDetails('DE') }} onMouseOver={(e) => { showToolTip(e, 'DE') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='POLY'
                coords='520,146,523,154,532,162,523,164,518,149,519,147,521,146'
                onClick={() => { showDetails('DE') }} onMouseOver={(e) => { showToolTip(e, 'DE') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='POLY'
                coords='464,154,468,155,469,157,470,155,478,155,479,159,481,159,485,155,490,155,490,163,484,170,484,173,478,174,478,179,474,184,474,188,470,191,465,191,462,193,456,192,456,190,452,189,449,185,449,177,452,174,452,169,456,168,456,164,464,158'
                onClick={() => { showDetails('WV') }} onMouseOver={(e) => { showToolTip(e, 'WV') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='POLY'
                coords='4,315,13,296,34,282,65,291,77,338,93,337,119,354,119,355,113,362,113,360,60,342,25,371,6,376,6,375,25,356,5,338,13,323,13,322,5,315,4,314'
                onClick={() => { showDetails('AK') }} onMouseOver={(e) => { showToolTip(e, 'AK') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='POLY'
                coords='55,6,66,7,68,9,73,9,74,11,80,11,82,13,88,13,90,15,96,15,98,17,104,17,106,19,105,30,103,32,103,39,101,40,101,48,99,50,99,59,83,57,82,55,56,55,54,53,44,53,43,46,34,39,35,9,39,9,46,15,50,15,53,17,54,23,57,20,57,18,55,17,55,12,57,10,55,9'
                onClick={() => { showDetails('WA') }} onMouseOver={(e) => { showToolTip(e, 'WA') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='POLY'
                coords='55,107,85,114,116,120,102,190,101,197,99,203,98,203,97,202,97,202,96,201,94,200,92,201,92,201,92,204,91,211,92,212,91,215,91,216,91,216,45,149'
                onClick={() => { showDetails('NV') }} onMouseOver={(e) => { showToolTip(e, 'NV') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='POLY'
                coords='13,97,25,98,26,100,33,100,34,102,40,102,42,104,55,106,55,113,53,115,53,121,51,123,51,129,49,131,49,137,47,139,47,145,45,149,47,150,49,155,51,156,53,161,65,177,65,179,69,182,71,187,73,188,75,193,87,209,87,211,91,214,91,221,94,226,94,228,89,233,89,236,85,239,87,247,60,246,58,244,55,244,55,235,53,234,53,231,45,224,45,221,37,218,37,215,34,212,30,212,23,208,23,195,21,194,21,191,19,190,19,187,15,182,15,175,17,171,13,169,13,161,16,160,17,157,11,155,11,147,7,144,6,140,6,114,11,109'
                onClick={() => { showDetails('CA') }} onMouseOver={(e) => { showToolTip(e, 'CA') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='POLY'
                coords='32,40,40,41,42,43,42,50,45,53,56,53,57,55,83,55,85,57,97,59,100,63,98,72,90,82,92,83,92,89,90,90,90,95,88,96,88,104,86,106,86,112,84,113,66,111,65,109,57,109,55,107,50,107,49,105,42,105,41,103,35,103,33,101,28,101,27,99,13,97,14,81,16,80,16,77,18,76,24,64,24,60,28,54,28,50,30,48'
                onClick={() => { showDetails('OR') }} onMouseOver={(e) => { showToolTip(e, 'OR') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='POLY'
                coords='224,79,297,82,297,80,297,78,295,73,295,72,295,70,295,68,294,61,294,57,292,56,292,51,292,47,292,45,292,44,291,41,291,40,259,39,246,38,227,37'
                onClick={() => { showDetails('ND') }} onMouseOver={(e) => { showToolTip(e, 'ND') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='POLY'
                coords='387,233,393,233,393,269,391,273,393,275,394,290,394,298,386,298,383,301,379,301,376,290,356,290,357,279,361,273,361,267,359,266,359,259,357,257,357,254,359,253,361,244,365,241,365,236,367,234'
                onClick={() => { showDetails('MS') }} onMouseOver={(e) => { showToolTip(e, 'MS') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='POLY'
                coords='339,214,369,214,369,218,373,219,373,223,371,225,371,233,365,238,365,243,361,246,361,250,359,252,359,262,325,262,325,256,319,255,318,217,318,215'
                onClick={() => { showDetails('AR') }} onMouseOver={(e) => { showToolTip(e, 'AR') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='POLY'
                coords='328,157,350,158,350,165,354,169,354,171,357,172,361,178,364,178,366,180,366,185,364,189,374,196,374,201,376,205,379,206,379,210,374,215,374,219,368,219,368,216,370,215,369,214,321,214,320,216,318,216,318,179,315,178,312,174,312,169,310,168,310,165,307,162,307,158'
                onClick={() => { showDetails('MO') }} onMouseOver={(e) => { showToolTip(e, 'MO') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='POLY'
                coords='376,128,386,128,386,130,390,134,390,146,392,147,392,175,394,179,394,183,390,188,390,195,386,200,386,204,379,203,378,205,374,204,374,198,369,193,364,191,364,185,366,184,366,182,363,179,360,179,351,167,352,155,356,150,354,149,354,145,362,140,362,134,360,133,360,129'
                onClick={() => { showDetails('IL') }} onMouseOver={(e) => { showToolTip(e, 'IL') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='POLY'
                coords='332,116,352,117,352,124,355,127,357,127,363,135,362,142,354,146,356,152,352,156,352,158,304,158,304,152,302,150,302,146,300,144,297,131,297,117'
                onClick={() => { showDetails('IA') }} onMouseOver={(e) => { showToolTip(e, 'IA') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='POLY'
                coords='237,165,310,166,313,169,313,175,316,178,317,188,317,208,236,207'
                onClick={() => { showDetails('KS') }} onMouseOver={(e) => { showToolTip(e, 'KS') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='POLY'
                coords='219,121,264,122,266,124,278,124,280,126,283,126,284,128,290,126,297,130,297,133,299,134,299,139,301,140,301,143,303,144,303,149,305,150,305,152,303,153,305,154,305,157,308,162,308,165,237,164,237,160,239,159,238,150,219,150,218,148'
                onClick={() => { showDetails('NE') }} onMouseOver={(e) => { showToolTip(e, 'NE') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='POLY'
                coords='348,68,352,68,353,71,357,71,361,75,366,75,367,77,372,77,373,79,379,79,382,81,382,86,388,93,386,114,384,116,384,118,386,119,386,127,364,129,358,129,352,126,352,118,350,117,350,112,341,103,334,100,333,87,334,81,338,78,338,71,343,71,344,69'
                onClick={() => { showDetails('WI') }} onMouseOver={(e) => { showToolTip(e, 'WI') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='POLY'
                coords='309,35,313,35,313,39,315,40,316,44,322,44,324,46,326,44,331,44,332,46,339,48,344,52,346,50,357,50,359,52,359,54,355,58,352,58,337,73,337,82,333,85,333,87,335,88,333,97,349,110,349,116,307,117,297,117,297,93,293,89,293,87,297,83,297,77,295,76,295,61,293,60,292,54,292,40,308,40'
                onClick={() => { showDetails('MN') }} onMouseOver={(e) => { showToolTip(e, 'MN') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='POLY'
                coords='223,80,289,81,290,83,296,83,295,89,297,91,296,129,290,127,283,127,282,125,277,125,276,123,256,123,254,121,220,121,221,95,223,94'
                onClick={() => { showDetails('SD') }} onMouseOver={(e) => { showToolTip(e, 'SD') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='POLY'
                coords='521,192,527,193,527,195,523,198,524,199,530,199,529,207,521,208,525,213,525,217,522,217,511,228,511,232,507,233,498,231,488,223,484,223,483,225,476,225,472,221,462,221,450,227,438,227,437,229,432,229,432,225,435,221,437,219,444,217,446,213,452,211,459,204,459,201,474,201,476,199,488,199,489,197,500,197,501,195,510,195,511,193'
                onClick={() => { showDetails('NC') }} onMouseOver={(e) => { showToolTip(e, 'NC') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='POLY'
                coords='459,127,463,127,464,136,464,160,460,162,456,168,453,169,451,177,447,177,444,174,443,176,430,176,428,172,423,172,420,146,420,134,439,134,440,136,444,136,445,134,450,134,453,130'
                onClick={() => { showDetails('OH') }} onMouseOver={(e) => { showToolTip(e, 'OH') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='POLY'
                coords='412,135,420,136,420,151,422,153,423,170,422,178,418,179,414,187,407,188,406,191,403,190,402,192,391,191,391,186,394,181,394,169,392,167,391,138'
                onClick={() => { showDetails('IN') }} onMouseOver={(e) => { showToolTip(e, 'IN') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='POLY'
                coords='422,172,428,172,431,175,446,175,448,177,448,182,455,189,455,193,451,195,448,201,443,203,442,205,435,205,433,207,413,207,411,209,393,209,392,211,386,212,379,212,379,203,385,203,386,199,390,196,390,193,392,191,401,191,402,189,404,189,405,191,407,187,411,189,418,177,422,176'
                onClick={() => { showDetails('KY') }} onMouseOver={(e) => { showToolTip(e, 'KY') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='POLY'
                coords='453,203,458,203,458,206,455,210,450,212,441,220,438,220,430,230,412,230,410,232,388,232,375,234,370,234,371,223,373,221,373,214,375,212,390,212,391,210,396,210,397,208,418,208,419,206,438,206,440,204'
                onClick={() => { showDetails('TN') }} onMouseOver={(e) => { showToolTip(e, 'TN') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='POLY'
                coords='466,221,476,222,477,224,492,224,493,226,495,226,503,232,503,236,500,239,500,242,498,243,498,247,490,256,483,260,482,263,478,263,472,255,472,253,469,250,467,250,463,244,456,240,453,234,447,232,447,228,450,224,453,224,455,222'
                onClick={() => { showDetails('SC') }} onMouseOver={(e) => { showToolTip(e, 'SC') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='POLY'
                coords='469,282,477,283,483,300,493,310,493,318,495,319,499,328,501,329,503,343,503,347,501,350,502,354,499,357,493,357,486,349,483,349,481,344,477,341,477,338,471,335,471,332,469,331,469,325,471,324,465,322,465,318,467,316,465,310,455,303,450,297,447,297,446,295,440,301,433,303,429,300,428,297,423,297,418,293,417,295,405,295,404,291,404,287,416,287,418,285,435,285,438,289,442,287,469,287,470,289,471,286,469,285'
                onClick={() => { showDetails('FL') }} onMouseOver={(e) => { showToolTip(e, 'FL') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='POLY'
                coords='409,231,421,231,423,233,425,247,427,248,427,253,432,264,431,275,433,282,433,284,430,286,414,286,412,288,404,288,403,289,405,291,405,298,401,298,400,296,395,297,395,295,393,294,392,278,393,232'
                onClick={() => { showDetails('AL') }} onMouseOver={(e) => { showToolTip(e, 'AL') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='POLY'
                coords='158,199,224,206,235,207,237,164,238,150,217,149,165,143'
                onClick={() => { showDetails('CO') }} onMouseOver={(e) => { showToolTip(e, 'CO') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='POLY'
                coords='158,200,186,201,187,203,203,203,205,205,220,205,221,207,224,207,222,221,222,244,220,246,220,268,218,270,218,275,195,275,193,273,177,273,176,275,166,275,165,273,159,273,158,277,156,278,147,278,148,257,150,256,150,243,152,242,152,229,154,228,154,216,156,214,156,202'
                onClick={() => { showDetails('NM') }} onMouseOver={(e) => { showToolTip(e, 'NM') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='POLY'
                coords='152,85,176,86,177,88,193,88,195,90,209,90,211,92,222,92,220,110,220,131,218,133,218,148,181,146,179,144,163,144,161,142,150,142,149,140,145,140,146,126,148,125,148,112,150,111,150,98,152,97'
                onClick={() => { showDetails('WY') }} onMouseOver={(e) => { showToolTip(e, 'WY') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='POLY'
                coords='114,22,135,23,137,25,147,25,149,27,158,27,159,29,170,29,171,31,181,31,183,33,200,33,201,35,220,35,221,37,226,37,224,84,222,86,222,92,200,91,199,89,184,89,183,87,168,87,167,85,153,85,152,89,132,89,132,84,128,82,128,72,120,71,120,67,122,66,124,56,120,54,118,48,114,44,113,35'
                onClick={() => { showDetails('MT') }} onMouseOver={(e) => { showToolTip(e, 'MT') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='POLY'
                coords='158,199,102,190,116,121,147,126,144,140,165,143'
                onClick={() => { showDetails('UT') }} onMouseOver={(e) => { showToolTip(e, 'UT') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='POLY'
                coords='101,191,120,192,122,194,133,194,134,196,147,196,148,198,157,198,155,225,153,227,153,239,151,241,151,252,149,253,149,266,147,267,147,277,127,276,126,274,120,274,116,270,113,270,112,268,106,266,102,262,99,262,98,260,92,258,88,254,82,252,82,248,84,248,87,245,85,244,85,238,87,237,89,230,95,227,91,223,91,201,96,200,98,202,101,199'
                onClick={() => { showDetails('AZ') }} onMouseOver={(e) => { showToolTip(e, 'AZ') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='POLY'
                coords='105,20,115,21,115,27,113,28,115,44,119,47,121,54,125,56,121,70,127,71,127,76,129,77,131,88,150,89,151,91,147,126,132,125,130,123,122,123,120,121,112,121,110,119,103,119,102,117,93,117,92,115,86,115,89,93,91,92,91,88,93,86,91,84,91,79,101,66,101,64,99,63,99,47,101,46,101,38,103,36,103,29,105,28'
                onClick={() => { showDetails('ID') }} onMouseOver={(e) => { showToolTip(e, 'ID') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='RECT'
                coords='53,380,109,414'
                onClick={() => { showDetails('HI') }} onMouseOver={(e) => { showToolTip(e, 'HI') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='CIRCLE'
                coords='577,142,9'
                onClick={() => { showDetails('RI') }} onMouseOver={(e) => { showToolTip(e, 'RI') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='POLY'
                coords='553,117,550,107,554,106,558,109,559,111,560,112,552,116'
                onClick={() => { showDetails('RI') }} onMouseOver={(e) => { showToolTip(e, 'RI') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='RECT'
                coords='106,391,137,413'
                onClick={() => { showDetails('HI') }} onMouseOver={(e) => { showToolTip(e, 'HI') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='RECT'
                coords='614,62,645,84'
                onClick={() => { showDetails('VT') }} onMouseOver={(e) => { showToolTip(e, 'VT') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='RECT'
                coords='614,89,645,111'
                onClick={() => { showDetails('NH') }} onMouseOver={(e) => { showToolTip(e, 'NH') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='RECT'
                coords='614,116,645,138'
                onClick={() => { showDetails('MA') }} onMouseOver={(e) => { showToolTip(e, 'MA') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='RECT'
                coords='614,143,645,165'
                onClick={() => { showDetails('CT') }} onMouseOver={(e) => { showToolTip(e, 'CT') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='RECT'
                coords='614,170,645,192'
                onClick={() => { showDetails('RI') }} onMouseOver={(e) => { showToolTip(e, 'RI') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='RECT'
                coords='614,197,645,219'
                onClick={() => { showDetails('NJ') }} onMouseOver={(e) => { showToolTip(e, 'NJ') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='RECT'
                coords='614,224,645,246'
                onClick={() => { showDetails('DE') }} onMouseOver={(e) => { showToolTip(e, 'DE') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='RECT'
                coords='614,251,645,273'
                onClick={() => { showDetails('MD') }} onMouseOver={(e) => { showToolTip(e, 'MD') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='RECT'
                coords='614,278,645,300'
                onClick={() => { showDetails('DC') }} onMouseOver={(e) => { showToolTip(e, 'DC') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='RECT'
                coords='531,391,645,413'
                onClick={() => { showDetails('others') }} onMouseOver={(e) => { showToolTip(e, 'others') }} onMouseOut={() => { hideToolTip() }} />
              <area shape='RECT'
                coords='0,0,0,0' />
            </map>
            <div id='xtooltip' background='' style={{ visibility: 'hidden', position: 'absolute' }} />
            <div id="xtooltip2" style={{ color: '#0F0F59', width: '650px', backgroundColor: 'rgb(238, 238, 238)', left: '184px', top: '516px', visibility: 'visible', fontSize: '13px', margin: '7px 0' }}>
              <table width="100%" border="1">
                <tbody>
                  <tr>
                    <td>
                      <table width="100%" border="0" style={{ border: '1px solid grey' }}>
                        <tbody>
                          <tr>
                            <td colSpan="2">
                              <center>
                                {currentState ? (
                                  <div className='DeliveryTimeTop1'>
                                    <div>{currentState === 'others' ? 'Other Territories Delivery Times by Zip Code' : statesMap[currentState] + ' Average Delivery Times'}</div>
                                    <div>Between {response && response.startDate} and {response && response.endDate}</div>
                                    <div style={{ padding: '5px 0' }} />
                                    {response && response[currentState] && currentState !== 'others' ? (
                                      <div className='DeliveryTimeTop1Details'>
                                        <div className="DeliveryTimeTop1DetailsRow">
                                          <div>Average Delivery Time:</div>
                                          <div>{response && response[currentState] && getAvgDays(response[currentState].avgDays, true)}</div>
                                        </div>
                                        <div className="DeliveryTimeTop1DetailsRow">
                                          <div>Package Count:</div>
                                          <div>{response && response[currentState] && response[currentState].packageCount && addCommas(response[currentState].packageCount)} Packages</div>
                                        </div>
                                      </div>) : response && response[currentState] ? '' : <p>No Data Available</p>}
                                  </div>
                                ) : <b>Click a State for Average Delivery Times</b>}
                              </center>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  {currentState && response && response[currentState] ? (
                    <tr>
                      <td>
                        <center>
                          <br />
                          <br />
                          <table border="1" cellSpacing="0">
                            <tbody>
                              {currentState !== 'others' ? <tr>
                                <td colSpan="5">
                                  <center>
                                    <b>{statesMap[currentState] || 'Other States '} Delivery Times by Zip Code</b>
                                  </center>
                                </td>
                              </tr> : <></>}
                              <tr>
                                <td width="90">
                                  <center>
                                    <b>Zip<br />Code</b>
                                  </center>
                                </td>
                                <td width="220">
                                  <center>
                                    <b>USPS<br />Center</b>
                                  </center>
                                </td>
                                <td width="90">
                                  <center>
                                    <b>Average Days</b>
                                  </center>
                                </td>
                                <td width="90">
                                  <center>
                                    <b>Package Count</b>
                                  </center>
                                </td>
                                <td width="90">
                                  <center>
                                    <b>5 Digit Zip Codes</b>
                                  </center>
                                </td>
                              </tr>
                              {response && response[currentState] && response[currentState].data && response[currentState].data.records?.map((stateRecord, idx) => {
                                return <tr key={idx}>
                                  <td width="90">
                                    <center>
                                      {stateRecord.zipCode}
                                    </center>
                                  </td>
                                  <td width="220">
                                    {stateRecord.center}
                                  </td>
                                  <td width="90">
                                    <center>
                                      {stateRecord.avgDays}
                                    </center>
                                  </td>
                                  <td width="90">
                                    {addCommas(stateRecord.packageCount)}
                                  </td>
                                  <td width="90">
                                    <center>
                                      <small>
                                        <a id={`LINK${stateRecord.zipCode}`} onClick={() => { showModal(stateRecord.zipCode) }} style={{ textDecoration: 'underline', cursor: 'pointer' }} >Show</a>
                                      </small>
                                    </center>
                                  </td>
                                </tr>
                              })}
                            </tbody>
                          </table>
                        </center>
                      </td>
                    </tr>) : <></>}
                </tbody>
              </table>
            </div>
            {}
          </div>
        )}
        {modalContent.isAccessOpen && <DeliveryReportModal
          isAccessOpen={modalContent.isAccessOpen}
          onAccessClose={onAccessClose}
          data={modalContent.data}
        />}
      </div>
    </>
  )
}

export default withAxiosCancellation(DeliveryReportContent)