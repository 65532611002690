/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import styled from 'styled-components/dist/styled-components'
import { useLayout } from 'ui-core/dist/useLayout';
import DetailPaneHeader from 'ui-core/dist/DetailPaneHeader';
import { useSelector, useDispatch } from 'react-redux';
import {
  Modal, Button, ModalHeader, Input, ModalBody
} from 'ui-core/dist';
import { detailPaneAnimStates } from '../../../commonResources/constants/animStates';
import ErrorPage from '../../ErrorPage';
import { SelectDropdown } from '../../../components'
import LoadingComponent from '../../../components/LoadingComponent';
import ModalWrapper from '../../../components/Modal/components/ModalWrapper';
import useNotificationBannerHook from '../../../useNotificationBannerHook';
import {
  useInsertBroker,
  useUpdateBroker,
  useDeleteBroker,
} from '../../../api/useClientNCOPAFBrokers'

const StyledModalWrapperWidth=styled(ModalWrapper)`
& .active-content {
  width: 30% !important;
}
`

const BrokerEditorDetail = () => {
  const {
    expandCollapseDetailPaneButtonConfig,
    closeDetailPaneButtonConfig,
    detailAnimationState,
    closeDetailPane
  } = useLayout()

  const { notify } = useNotificationBannerHook();
  const [cBrokerNAICS, setcBrokerNAICS] = useState('')
  const [cBrokerName, setcBrokerName] = useState('')
  const [cBrokerPhone, setcBrokerPhone] = useState('')
  const [cBrokerAddress, setcBrokerAddress] = useState('')
  const [cBrokerCity, setcBrokerCity] = useState('')
  const [cBrokerState, setcBrokerState] = useState('')
  const [cBrokerZip, setcBrokerZip] = useState('')
  const [cBrokerZip4, setcBrokerZip4] = useState('')
  const [cBrokerID, setcBrokerID] = useState('')
  const [ editorMode, setEditorMode ] = useState('Add')
  const [ bCanEdit, setbCanEdit ] = useState(true)
  const [isOpenConfirmationModal, setisOpenConfirmationModal] = useState(false)
  const [confirmationModalMode, setconfirmationModalMode] = useState('')

  const [count, setcount] = useState(0)

  const { clientContactEditorRecord, clientContactEditorMode: editorModep, refetchClientContactEditorRecord }
    = useSelector(({ clientAdministration }) => clientAdministration);

  useEffect(() => {
    if (clientContactEditorRecord) {
      if (clientContactEditorRecord.cBrokerID) {
        onResetCurrentRecordValues()
      } else {
        onClearCurrentRecordValues()
      }
    }
  }, [clientContactEditorRecord]);

  const { data: infoInsertBroker, postData: insertBroker, metaData: insertBrokerResponse, isLoading: isLoadinginsertBroker,
    isError: isErrorinsertBroker, error: errorinsertBroker } = useInsertBroker()

  const { data: infoUpdateBroker, postData: updateBroker, metaData: updateBrokerResponse, isLoading: isLoadingupdateBroker,
    isError: isErrorupdateBroker, error: errorupdateBroker } = useUpdateBroker()

  const { data: infoDeleteBroker, postData: deleteBroker, metaData: deleteBrokerResponse, isLoading: isLoadingdeleteBroker,
    isError: isErrordeleteBroker, error: errordeleteBroker } = useDeleteBroker()

  const onResetCurrentRecordValues = () => {
    if (clientContactEditorRecord && clientContactEditorRecord.cBrokerID) {
      const { cBrokerNAICS: cBrokerNAICSp, cBrokerName: cBrokerNamep, cBrokerPhone: cBrokerPhonep, 
        cBrokerAddress: cBrokerAddressp, cBrokerID: cBrokerIDp, cBrokerCity: cBrokerCityp, 
        cBrokerState: cBrokerStatep, cBrokerZip: cBrokerZipp, cBrokerZip4: cBrokerZip4p, bCanEdit: bCanEditp} = clientContactEditorRecord
      setcBrokerNAICS(cBrokerNAICSp)
      setcBrokerName(cBrokerNamep)
      setcBrokerPhone(`(${cBrokerPhonep?.substr(0,3)}) ${cBrokerPhonep?.substr(3,3)}-${cBrokerPhonep?.substr(6,4)}`)
      setcBrokerAddress(cBrokerAddressp)
      setcBrokerID(cBrokerIDp)
      setcBrokerCity(cBrokerCityp)
      setcBrokerState(cBrokerStatep)
      setcBrokerZip(cBrokerZipp)
      setcBrokerZip4(cBrokerZip4p)
      setEditorMode(editorModep)
      setbCanEdit(bCanEditp === "1")
    }
  }

  useEffect(() => {
    if (insertBrokerResponse) {
      const { status, statusText } = insertBrokerResponse;
      if (status === 200 || statusText === "OK") {
        if (infoInsertBroker && infoInsertBroker.XMLResponse) {
          const { XMLResponse } = infoInsertBroker;
          if (XMLResponse) {
            notify(`Broker ${XMLResponse} has been successfully added.`, 'positive')
            closeDetailPane()
            if (refetchClientContactEditorRecord) refetchClientContactEditorRecord()
            return
          }
          return;
        }
        // notify(`Broker ${XMLResponse} has been successfully added.`)
        closeDetailPane()
        if (refetchClientContactEditorRecord) refetchClientContactEditorRecord()
      }
    }
  }, [insertBrokerResponse])

  useEffect(() => {
    if (updateBrokerResponse) {
      const { status, statusText } = updateBrokerResponse;
      if (status === 200 || statusText === "OK") {
        if (infoUpdateBroker && infoUpdateBroker.XMLResponse) {
          const { XMLResponse } = infoUpdateBroker;
          if (XMLResponse) {
            notify(`Broker ${XMLResponse} has been successfully updated.`, 'positive')
            closeDetailPane()
            if (refetchClientContactEditorRecord) refetchClientContactEditorRecord()
            return
          }
          return;
        }
        // notify(`Broker ${XMLResponse} has been successfully added.`)
        closeDetailPane()
        if (refetchClientContactEditorRecord) refetchClientContactEditorRecord()
      }
    }
  }, [updateBrokerResponse])


  useEffect(() => {
    if (deleteBrokerResponse) {
      const { status, statusText } = deleteBrokerResponse;
      if (status === 200 || statusText === "OK") {
        if (infoDeleteBroker && infoDeleteBroker.XMLResponse) {
          const { XMLResponse } = infoDeleteBroker;
          if (XMLResponse) {
            notify(`Broker ${XMLResponse} has been successfully deleted.`, 'positive')
            closeDetailPane()
            if (refetchClientContactEditorRecord) refetchClientContactEditorRecord()
            return
          }
          return;
        }
        // notify(`Broker ${XMLResponse} has been successfully added.`)
        closeDetailPane()
        if (refetchClientContactEditorRecord) refetchClientContactEditorRecord()
      }
    }
  }, [deleteBrokerResponse])


  const onDeleteClick = () => {
    if (editorMode === "Edit") {
      setconfirmationModalMode("Delete")
      setisOpenConfirmationModal(true)
    }
  }
  const onSubmitClick = () => {
    if (editorMode === "Add") {
      setconfirmationModalMode("Add")
    } else if (editorMode === "Edit") {
      setconfirmationModalMode("Modify")
    }
    setisOpenConfirmationModal(true)
  }
  const onConfirmationYesClick = () => {
    if (confirmationModalMode === "Delete") {
      deleteBroker({ cBrokerID: clientContactEditorRecord?.cBrokerID, count })
      setcount(count + 1)

    } else if (confirmationModalMode === "Add") {
      insertBroker({ cBrokerNAICS, cBrokerName, cBrokerPhone, cBrokerAddress, cBrokerID, cBrokerCity, cBrokerState, cBrokerZip, cBrokerZip4, count })
      setcount(count + 1)

    } else if (confirmationModalMode === "Modify") {
      updateBroker({ cBrokerNAICS, cBrokerName, cBrokerPhone, cBrokerAddress, cBrokerID, cBrokerCity, cBrokerState, cBrokerZip, cBrokerZip4, count })
      setcount(count + 1)
    }
    setconfirmationModalMode('')
    setisOpenConfirmationModal(false)
  }

  const onClearCurrentRecordValues = () => {
    setcBrokerNAICS('')
    setcBrokerName('')
    setcBrokerAddress('')
    setcBrokerPhone('')
    setcBrokerID('')
    setcBrokerCity('')
    setcBrokerState('')
    setcBrokerZip('')
    setcBrokerZip4('')
    setEditorMode('Add')
    setbCanEdit(true)
  }

  const onCopyCurrentRecordValues = () => {
    setcBrokerID('')
    setbCanEdit(true)
  }

  const renderCBrokerid = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_brokerIDDesc"
      placeholder=""
      size="small"
      maxLength={30}
      disabled
      initialValue={cBrokerID}
      onChange={(e) => {
        setcBrokerID(e.target.value || "")
      }}
    />
  ), [cBrokerID])

  const renderCBrokerNAICS = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_BrokerNAICS"
      placeholder=""
      size="small"
      maxLength={6}
      disabled={editorMode === "Edit"}
      initialValue={cBrokerNAICS}
      errorMessage="Only numeric values are allowed and must be 6 digits"
      hasError={cBrokerNAICS ? !/^\d{6}$/.test(cBrokerNAICS) : false}
      onChange={(e) => {
        setcBrokerNAICS(e.target.value || "")
      }}
    />
  ), [cBrokerNAICS, editorMode])

  const renderCBrokerName = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_CBrokerName"
      placeholder=""
      size="small"
      maxLength={50}
      initialValue={cBrokerName}
      errorMessage="Incorrect data do not use '&', '|' or '>' characters"
      hasError={/[\\&>\\|]/.test(cBrokerName)}
      onChange={(e) => {
        setcBrokerName(e.target.value || "")
      }}
    />
  ), [cBrokerName])

  const renderCBrokerPhone = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_CBrokerPhone"
      placeholder=""
      size="small"
      maxLength={14}
      initialValue={cBrokerPhone}
      errorMessage="Must be of proper format '(111) 111-1111'"
      hasError={cBrokerPhone ? !/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(cBrokerPhone) : false}
      onChange={(e) => {
        let x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        x = !x[2] ? x[1] : `(${  x[1]  }) ${  x[2]  }${x[3] ? `-${  x[3]}` : ''}`
        setcBrokerPhone(x || "")
      }}
    />
  ), [cBrokerPhone])

  const renderCBrokerAddress = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_CBrokerAddress"
      placeholder=""
      size="small"
      maxLength={100}
      initialValue={cBrokerAddress}
      hasError={/[\\&>\\|]/.test(cBrokerAddress)}
      errorMessage="Incorrect data do not use '&', '|' or '>' characters"
      onChange={(e) => {
        setcBrokerAddress(e.target.value || "")
      }}
    />
  ), [cBrokerAddress])

  const renderCBrokerCity = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_CBrokerCity"
      placeholder=""
      size="small"
      maxLength={50}
      initialValue={cBrokerCity}
      errorMessage="Incorrect data do not use '&', '|' or '>' characters"
      hasError={/[\\&>\\|]/.test(cBrokerCity)}
      onChange={(e) => {
        setcBrokerCity(e.target.value || "")
      }}
    />
  ), [cBrokerCity])

  const renderCBrokerState = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_CBrokerState"
      placeholder=""
      size="small"
      maxLength={2}
      initialValue={cBrokerState}
      errorMessage="Incorrect data do not use '&', '|' or '>' characters"
      hasError={/[\\&>\\|]/.test(cBrokerState)}
      onChange={(e) => {
        setcBrokerState(e.target.value || "")
      }}
    />
  ), [cBrokerState])

  const renderCBrokerZip = useMemo(() => (
    <div className="displayFlex">
      <Input
        style={{ width: '70px' }}
        className="test-classname"
        domID="txt_cBrokerZip"
        placeholder=""
        size="small"
        maxLength={5}
        initialValue={cBrokerZip}
        errorMessage="Only numeric values are allowed and must be 5 digit"
        hasError={cBrokerZip ? !/^\d{5}$/.test(cBrokerZip) : false}
        onChange={(e) => {
          setcBrokerZip(e.target.value || "")
        }}
      />
      <span style={{marginLeft: "5px", marginRight: "5px"}}>-</span>
      <Input
        style={{ width: '70px' }}
        className="test-classname"
        domID="txt_cBrokerZip4"
        placeholder=""
        size="small"
        maxLength={4}
        initialValue={cBrokerZip4}
        errorMessage="Only numeric values are allowed and must be 4 digit"
        hasError={cBrokerZip4 ? !/^\d{4}$/.test(cBrokerZip4) : false}
        onChange={(e) => {
          setcBrokerZip4(e.target.value || "")
        }}
      />
    </div>
  ), [cBrokerZip, cBrokerZip4])

  const validateForm = useMemo(() => {
    if (!(/^\d{6}$/.test(cBrokerNAICS)) 
      || (cBrokerName ? /[\\&>\\|]/.test(cBrokerName) : true)
      || !/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(cBrokerPhone)
      || (cBrokerAddress ? /[\\&>\\|]/.test(cBrokerAddress) : true)
      || (cBrokerCity ? /[\\&>\\|]/.test(cBrokerCity) : true)
      || (cBrokerState ? /[\\&>\\|]/.test(cBrokerState) : true) 
      || !/^\d{5}$/.test(cBrokerZip)
      || !/^\d{4}$/.test(cBrokerZip4)) {
      return false;
    }
    return true;
  }, [cBrokerNAICS, cBrokerName, cBrokerPhone, cBrokerAddress, cBrokerCity, cBrokerState, cBrokerZip, cBrokerZip4])

  const disabledDeleteButton = useMemo(() => {
    if (editorMode === "Edit") {
      if (clientContactEditorRecord?.$?.bCanEdit === "0") {
        return true;
      }
      return false;
    }
    return true;
  }, [editorMode, clientContactEditorRecord])
  const renderBrokerEditorForm = useMemo(() => {
    if (detailAnimationState > detailPaneAnimStates.COLLAPSED) {
      return (
        <div style={{ marginLeft: '40px' }} className="marginLeft">
          <div>
            <p>
              <div className="displayFlex">Complete the information below, and then click Submit to continue.</div>
            </p>
            <p>
              <div className="displayFlex"><span style={{ color: 'red' }}>* indicates required information</span></div>
            </p>
          </div>
          <br />
          <div className="displayFlex">
            <table className="common-table">
              <tr>
                <td>
                  <label> Broker ID: </label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderCBrokerid}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <label> Broker NAICS: <span style={{ color: 'red' }}>*</span></label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderCBrokerNAICS}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <label> Broker Name: <span style={{ color: 'red' }}>*</span></label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderCBrokerName}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <label> Broker Phone #: <span style={{ color: 'red' }}>*</span></label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderCBrokerPhone}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <label> Broker Address: <span style={{ color: 'red' }}>*</span></label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderCBrokerAddress}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <label> Broker City: <span style={{ color: 'red' }}>*</span></label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderCBrokerCity}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <label> Broker State: <span style={{ color: 'red' }}>*</span></label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderCBrokerState}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <label> Broker Zip+4: <span style={{ color: 'red' }}>*</span></label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderCBrokerZip}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td colSpan="2">
                  <div className="displayFlex">
                    {/* <div>
                      <Button
                        domID="btn_reset"
                        buttonType="standard"
                        size="small"
                        name="RESET"
                        disabled={editorMode !== "Edit"}
                        onClick={() => onResetCurrentRecordValues()}
                      />
                    </div> */}
                    <div className="marginLeft">
                      <Button
                        domID="btn_cancel"
                        buttonType="standard"
                        size="small"
                        name="CLEAR"
                        onClick={() => {onClearCurrentRecordValues()
                          setEditorMode('Add')
                        }}
                      />
                    </div>
                    <div className="marginLeft">
                      <Button
                        domID="btn_copy"
                        buttonType="standard"
                        size="small"
                        name="COPY"
                        disabled={editorMode === "Add"}
                        onClick={() => {
                          onCopyCurrentRecordValues()
                          setEditorMode('Add')
                        }}
                      />
                    </div>
                    <div className="marginLeft">
                      <Button
                        domID="btn_delete"
                        buttonType="standard"
                        size="small"
                        name="DELETE"
                        disabled={disabledDeleteButton}
                        onClick={() => onDeleteClick()}
                      />
                    </div>
                    <div className="marginLeft">
                      <Button
                        domID="btn_search"
                        buttonType="standard"
                        size="small"
                        name="SUBMIT"
                        disabled={!validateForm}
                        onClick={() => onSubmitClick()}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
            </table>
          </div>

          <Modal isOpen={isOpenConfirmationModal} wrapperComponent={StyledModalWrapperWidth}>
            <ModalHeader
              title={`Do you want to ${confirmationModalMode} ?`}
              onClose={() => setisOpenConfirmationModal(false)}
            />
            <ModalBody>
              {isOpenConfirmationModal ? (
                <div style={{ marginLeft: '34px', marginBottom: '20px' }} className="marginLeft">
                  <div className="displayFlex">
                    <div className="marginLeft">
                      <Button
                        domID="btn_Yes_Broker"
                        buttonType="standard"
                        size="small"
                        name="Yes"
                        onClick={() => onConfirmationYesClick()}
                      />
                    </div>
                    <div className="marginLeft">
                      <Button
                        domID="btn_No_Broker"
                        buttonType="standard"
                        size="small"
                        name="No"
                        onClick={() => setisOpenConfirmationModal(false)}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
            </ModalBody>
          </Modal>
        </div>
      )
    }
    return null;
  }, [detailAnimationState,
    cBrokerNAICS, cBrokerName, cBrokerPhone, cBrokerAddress, cBrokerID, validateForm, clientContactEditorRecord, editorMode, isOpenConfirmationModal, confirmationModalMode, cBrokerCity, cBrokerState, cBrokerZip, cBrokerZip4])

  return (
    <>
      <DetailPaneHeader
        title={editorMode === "Edit" ? "Modify Broker" : "Add Broker"}
        layoutControls={[
          expandCollapseDetailPaneButtonConfig,
          closeDetailPaneButtonConfig
        ]}
      />
      <>
        {isLoadinginsertBroker
          || isLoadingdeleteBroker || isLoadingupdateBroker ? (
            // replace with a better loading component
            <div style={{ marginLeft: '50%' }}><LoadingComponent /></div>
          ) : isErrorinsertBroker
            || isErrordeleteBroker || isErrorupdateBroker ? (
              // replace with a better error component
              <ErrorPage
                // this prop isn't real but is an example
                error={errorinsertBroker
                  || errordeleteBroker || errorupdateBroker}
              />
            ) : (
              <>
                {renderBrokerEditorForm}
              </>
            )
        }
      </>
    </>
  )
};

export default BrokerEditorDetail