import React from 'react'
import PropTypes from 'prop-types'
import './style.scss'

const ColorCell = ({ status }) => (
  <span className={`color-cell__${status.replace(/\s+/g, '')}`}>{status}</span>
)

ColorCell.propTypes = { status: PropTypes.string.isRequired }

export default ColorCell