import {
  ALL_RULES_FOR_RULETYPES_REQUEST_TRIGGER,
  ALL_RULES_FOR_RULETYPES_REQUEST_SUCCESS,
  ALL_RULES_FOR_RULETYPES_REQUEST_ERROR,
  ALL_RULES_FOR_RULETYPES_ASSOCIATIONS_REQUEST_TRIGGER,
  ALL_RULES_FOR_RULETYPES_ASSOCIATIONS_REQUEST_SUCCESS,
  ALL_RULES_FOR_RULETYPES_ASSOCIATIONS_REQUEST_ERROR,
  RULES_COUNT_FROM_DB_TRIGGER,
  RULES_COUNT_FROM_DB_SUCCESS,
  RULES_COUNT_FROM_DB_ERROR,
  RESET_RULE_DETAILS_PANE,
  SET_IS_VIEWING_RULE_DETAILS,
  UPDATE_CURRENT_TAB,
  GROUP_ASSOCIATIONS_FOR_RULE_ID_TRIGGER,
  GROUP_ASSOCIATIONS_FOR_RULE_ID_SUCCESS,
  GROUP_ASSOCIATIONS_FOR_RULE_ID_ERROR,
  POST_GROUP_ASSOCIATIONS_FOR_RULE_TYPE_TRIGGER,
  GROUP_ASSOCIATIONS_FOR_RULE_TYPE_SUCCESS,
  GROUP_ASSOCIATIONS_FOR_RULE_TYPE_ERROR,
  SET_IS_VIEWING_GROUP_ASSOCIATIONS_TYPE_MODAL,
  SET_SELECTED_RULE_TYPE,
  RESET_RULE_TYPE_RECORDS,
  RESET_STATE
} from './constants';
import { SET_SELECTED_RULE_RECORD } from '../ruleTypesList/constants';

// --------- RULES COUNT FROM DB -----------------------
export const rulesCountFromDBTrigger = (payload = {}) => ({
  type: RULES_COUNT_FROM_DB_TRIGGER,
  payload,
});

export const rulesCountFromDBRecieved = payload => ({
  type: RULES_COUNT_FROM_DB_SUCCESS,
  payload,
  error: null,
});

export const rulesCountFromDBError = error => ({
  type: RULES_COUNT_FROM_DB_ERROR,
  payload: null,
  error,
});
// ----------------------------------

// This is the action that the UI will dispatch to trigger our request loop for the first API call
export const triggerAllRulesForRuleTypesRequest = payload => ({
  type: ALL_RULES_FOR_RULETYPES_REQUEST_TRIGGER,
  payload,
});

// This action is dispatched from the saga if our request succeeds. It is the
// action the reducer will respond in order to cache the response data
export const allRulesForRuleTypesListReceived = (payload = []) => ({
  type: ALL_RULES_FOR_RULETYPES_REQUEST_SUCCESS,
  payload,
  error: null,
});

// This action is used to signal a request error
export const allRulesForRuleTypesRequestError = error => ({
  type: ALL_RULES_FOR_RULETYPES_REQUEST_ERROR,
  payload: null,
  error,
});

// Second API call (to get active groups live associations)
export const triggerAllRulesForRuleTypesAssociationsRequest = ({ ruleType = null, groupFlow = false }) => ({
  type: ALL_RULES_FOR_RULETYPES_ASSOCIATIONS_REQUEST_TRIGGER,
  payload: {
    ruleType,
    groupFlow
  },
});

export const allRulesForRuleTypesAssociationsListReceived = (payload = []) => ({
  type: ALL_RULES_FOR_RULETYPES_ASSOCIATIONS_REQUEST_SUCCESS,
  payload,
  error: null,
});

export const allRulesForRuleTypesAssociationsRequestError = error => ({
  type: ALL_RULES_FOR_RULETYPES_ASSOCIATIONS_REQUEST_ERROR,
  payload: null,
  error,
});

export const resetRuleDetailsPane = payload => ({
  type: RESET_RULE_DETAILS_PANE,
  payload,
});

export const setSelectedRuleRecord = payload => ({
  type: SET_SELECTED_RULE_RECORD,
  payload,
});

export const setIsViewingRuleDetails = (payload = []) => ({
  type: SET_IS_VIEWING_RULE_DETAILS,
  payload,
});

export const updateCurrentTab = payload => ({
  type: UPDATE_CURRENT_TAB,
  payload,
});

// --------- GROUP ASSOCIATIONS FOR RULE ID -----------------------
export const groupAssociationsForRuleIdTrigger = payload => ({
  type: GROUP_ASSOCIATIONS_FOR_RULE_ID_TRIGGER,
  payload,
});

export const groupAssociationsForRuleIdSuccess = (payload = []) => ({
  type: GROUP_ASSOCIATIONS_FOR_RULE_ID_SUCCESS,
  payload,
  error: null,
});

export const groupAssociationsForRuleIdError = error => ({
  type: GROUP_ASSOCIATIONS_FOR_RULE_ID_ERROR,
  payload: null,
  error,
});
// ----------------------------------

// --------- GROUP ASSOCIATIONS FOR RULE TYPE -----------------------
export const postGroupAssociationsForRuleTypeTrigger = (payload = {}) => ({
  type: POST_GROUP_ASSOCIATIONS_FOR_RULE_TYPE_TRIGGER,
  payload,
});

export const groupAssociationsForRuleTypeSuccess = (payload = []) => ({
  type: GROUP_ASSOCIATIONS_FOR_RULE_TYPE_SUCCESS,
  payload,
  error: null,
});

export const groupAssociationsForRuleTypeError = error => ({
  type: GROUP_ASSOCIATIONS_FOR_RULE_TYPE_ERROR,
  payload: null,
  error,
});

export const setIsViewingGroupAssociationsModal = payload => ({
  type: SET_IS_VIEWING_GROUP_ASSOCIATIONS_TYPE_MODAL,
  payload,
});

export const setSelectedRuleType = payload => ({
  type: SET_SELECTED_RULE_TYPE,
  payload,
});

// ----------------------------------

// reset rule records (for search)
export const resetRuleTypeRecords = () => ({
  type: RESET_RULE_TYPE_RECORDS,
  payload: null,
})

export const resetRuleTypeRecordsState = () => ({
  type: RESET_STATE,
  payload: null,
})