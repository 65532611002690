/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import { 
  Button, 
  ButtonGroup, 
  ListPaneHeader, 
  Modal,
  ModalHeader,
  ModalFooter, 
  TabContainer, 
  useLayout, 
} from 'ui-core/dist'
import { useDispatch, useSelector } from 'react-redux'
import BasicTab from './BasicTab'
import { isAddUser, setCopyId } from './modules/actions'
import AccessTab from './AccessTab'
import useUserManagerUser from '../../api/useUserManagerUser'
import { Loading } from '../../components'
import axios from '../../axios'
import Authorizer from '../Authorizer';
import useNotificationBannerHook from '../../useNotificationBannerHook';
import withAxiosCancellation from '../../components/withAxiosCancellation'

const UserManagerDetailPane = ({getCancelSourceWithName}) => {
  const {
    expandCollapseDetailPaneButtonConfig, 
    closeDetailPaneButtonConfig,
    detailProps
  } = useLayout()
  const dispatch = useDispatch()
  const [ isOpen, setIsOpen ] = useState(false)
  const [ inFlight, setInFlight ] = useState(false)
  const { notify } = useNotificationBannerHook();
  const isHideResetPassword= JSON.parse(localStorage.getItem('isHideResetPassword'));

  const { data, isLoading, refetch } = useUserManagerUser({ userId: detailProps?.userId, getCancelSource:getCancelSourceWithName('usermanagerUser') })

  const onCopy = () => {
    dispatch(isAddUser(true))
    dispatch(setCopyId(detailProps?.userId))
  }
  const onResetPassword = () => setIsOpen('reset')
  const onActivate = () => setIsOpen('activate')
  const onDeactivate = () => setIsOpen('deactivate')

  const onSubmitReset = async () => {
    setInFlight(true)
    try {
      await axios.post(`/members/${detailProps?.userId}/resetPassword`)
      setIsOpen(false)
      notify(`You have successfully reset the password for user ${detailProps?.userId}. A new password was sent to the registered email address for the user.`, 'positive')
    } catch(err) {
      notify(err.displayMessage, 'negative')
    } finally {
      setInFlight(false)
    }
  }

  const onSubmitActivate = async () => {
    setInFlight(true)
    try {
      await axios.put(`/members/${detailProps?.userId}/reactivate`)
      setIsOpen(false)
      notify(`You have successfully activated user ${detailProps?.userId}`, 'positive')
      detailProps.refreshMe()
      refetch()
    } catch(err) {
      notify(err.displayMessage, 'negative')
    } finally {
      setInFlight(false)
    }
  }
  const onSubmitDeactivate = async () => {
    setInFlight(true)
    try {
      await axios.put(`/members/${detailProps?.userId}/deactivate`)
      setIsOpen(false)
      notify(`You have successfully deactivated user ${detailProps?.userId}`, 'positive')
      detailProps.refreshMe()
      refetch()
    } catch(err) {
      notify(err.displayMessage, 'negative')
    } finally {
      setInFlight(false)
    }
  }

  const onClose = () => setIsOpen(false)
  const onCancel = () => setIsOpen(false)
  const onConfirm = () => {
    switch (isOpen) {
      case 'reset':
        onSubmitReset()
        break;
      case 'activate':
        onSubmitActivate()
        break;
      case 'deactivate':
        onSubmitDeactivate()
        break;
      default:
        break;
    }
  }
  const activeUserId = useSelector(({ session }) => session.userId)
 
  return (
    <>
      <ListPaneHeader 
        title="User Manager Details"
        layoutControls={[expandCollapseDetailPaneButtonConfig, closeDetailPaneButtonConfig]}
      />
      { !isLoading && !data ? <p style={{color: 'red', padding: '0 40px'}}>Access Denied: You do not have the access level required to perform this function</p> :
        isLoading ? <Loading /> : (
          <>
            <ButtonGroup className="user-manager-details__button-group">
            <Authorizer permission="CAN_PERFORM_SECURITY_USER_PASS_RESET">
              <>
              {
                !isHideResetPassword &&  <Button 
                domID="UserDetailsResetButton1"
                onClick={onResetPassword}
                name="Reset Password"
                buttonType="standard"
                size="small"
              />
              }
              </>
             
              </Authorizer>
              <Authorizer permission="CAN_PERFORM_SECURITY_USER_COPY">
              <Button 
                domID="UserDetailsCopyButton2"
                onClick={onCopy}
                name="Copy"
                buttonType="standard"
                size="small"
              />
              </Authorizer>
              {data?.isActive === "false" ? (
                <Authorizer permission="CAN_PERFORM_SECURITY_USER_REACTIVATE">
                  <Button 
                    domID="UserDetailsActivateButton"
                    onClick={onActivate}
                    name="Activate"
                    buttonType="standard"
                    size="small"
                  />
                </Authorizer>
              ) :(
                <Authorizer permission="CAN_PERFORM_SECURITY_USER_DEACTIVATE">
                  <Button 
                    domID="UserDetailsDeactivateButton"
                    onClick={onDeactivate}
                    name="Deactivate"
                    buttonType="standard"
                    size="small"
                  />
                </Authorizer>
              )}
            </ButtonGroup>
            <TabContainer
              className="user-manager-deatils__tab-container"
              contentClass="user-manager-deatils__content"
              dataTestId="test-tabBasic"
              domID="tab-container-basic"
              tabs={[
                {
                  domID: 'basicTab',
                  label: 'Basic',
                  tabContent: <BasicTab 
                    data={data} 
                    isLoading={isLoading} 
                    refetch={() => {
                      detailProps.refreshMe()
                      refetch()
                    }} 
                  />
                },
                {
                  domID: 'accessTab',
                  label: 'Access',
                  isDisabled: (activeUserId === detailProps?.userId),
                  tabContent: <AccessTab userId={detailProps?.userId} />
                },
              ]}
            />
          </>
        )
      }
      {isOpen?(
        <Modal isOpen={isOpen}>
          <ModalHeader 
            title={
              isOpen === 'activate' ? "Confirm User Activation" 
                : isOpen === 'deactivate' ? "Confirm User Deactivation" 
                  : isOpen === 'reset' ? "Reset a User's Password"
                    : ''
            }
            description={
              isOpen === 'activate' ? `Activate user ${detailProps?.userId}` 
                : isOpen === 'deactivate' ? `Deactivate user ${detailProps?.userId}` 
                  : isOpen === 'reset' ? `Reset the password for ${detailProps?.userId}`
                    : ''
            }
            onClose={onClose}
          />
          <ModalFooter>
            <ButtonGroup>
              <Button 
                domID="UserManagerDetailsModalCancelButton"
                size="small"
                buttonType="standard"
                name="Cancel"
                onClick={onCancel}
              />
              <Button 
                domID="UserManagerDetailsModalConfirmButton"
                size="small"
                buttonType="standard"
                name="Confirm"
                onClick={onConfirm}
                disabled={inFlight}
              />
            </ButtonGroup>
          </ModalFooter>
        </Modal>
      ):null}
    </>
  )
}

export default withAxiosCancellation(UserManagerDetailPane)