import { useAxiosPost } from '../hooks'

const useGetEftAccounts=()=>{
  const {postData, isLoading,isError,error, metaData, data }=useAxiosPost({
    url:'payerEftManager/getEftAccounts',
    method:'POST',
    onError: (e) => 
      // what happens on error? if you need something extra
      // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  
  return { isLoading, isError, error, postData, metaData, data};
}
const useGetEftAccount=()=>{
  const {postData, isLoading,isError,error, metaData, data }=useAxiosPost({
    url:'payerEftManager/getEftAccount',
    method:'POST',
    onError: (e) => 
    // what happens on error? if you need something extra
    // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  
  return { isLoading, isError, error, postData, metaData, data};
}
const useGetDrawDownTypes=()=>{
  const {postData, isLoading,isError,error, metaData, data }=useAxiosPost({
    url:'payerEftManager/getDrawDownTypes',
    method:'POST',
    onError: (e) => 
    // what happens on error? if you need something extra
    // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  
  return { isLoading, isError, error, postData, metaData, data};
}
const useGetConsolidationTypes=()=>{
  const {postData, isLoading,isError,error, metaData, data }=useAxiosPost({
    url:'payerEftManager/getConsolidationTypes',
    method:'POST',
    onError: (e) => 
    // what happens on error? if you need something extra
    // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  
  return { isLoading, isError, error, postData, metaData, data};
}
const useGetConsTypeDesc=()=>{
  const {postData, isLoading,isError,error, metaData, data }=useAxiosPost({
    url:'payerEftManager/getConsTypeDesc',
    method:'POST',
    onError: (e) => 
    // what happens on error? if you need something extra
    // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  
  return { isLoading, isError, error, postData, metaData, data};
}
const useGetDrawDownDesc=()=>{
  const {postData, isLoading,isError,error, metaData, data }=useAxiosPost({
    url:'payerEftManager/getDrawDownDesc',
    method:'POST',
    onError: (e) => 
    // what happens on error? if you need something extra
    // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  
  return { isLoading, isError, error, postData, metaData, data};
}
const useEditEftAccount=()=>{
  const {postData, isLoading,isError,error, metaData, data }=useAxiosPost({
    url:'payerEftManager/editEftAccount',
    method:'POST',
    onError: (e) => 
    // what happens on error? if you need something extra
    // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  
  return { isLoading, isError, error, postData, metaData, data};
}
const useAddEftAccount=()=>{
  const {postData, isLoading,isError,error, metaData, data }=useAxiosPost({
    url:'payerEftManager/addEftAccount',
    method:'POST',
    onError: (e) => 
    // what happens on error? if you need something extra
    // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  
  return { isLoading, isError, error, postData, metaData, data};
}
const useCopyEftAccount=()=>{
  const {postData, isLoading,isError,error, metaData, data }=useAxiosPost({
    url:'payerEftManager/copyEftAccount',
    method:'POST',
    onError: (e) => 
    // what happens on error? if you need something extra
    // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  
  return { isLoading, isError, error, postData, metaData, data};
}
const useOverWriteEftAccount=()=>{
  const {postData, isLoading,isError,error, metaData, data }=useAxiosPost({
    url:'payerEftManager/overWriteEftAccount',
    method:'POST',
    onError: (e) => 
    // what happens on error? if you need something extra
    // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  
  return { isLoading, isError, error, postData, metaData, data};
}
const useDeactivateEftAccount=()=>{
  const {postData, isLoading,isError,error, metaData, data }=useAxiosPost({
    url:'payerEftManager/deactivateEftAccount',
    method:'POST',
    onError: (e) => 
    // what happens on error? if you need something extra
    // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  
  return { isLoading, isError, error, postData, metaData, data};
}
const useCheckGroupRule=()=>{
  const {postData, isLoading,isError,error, metaData, data }=useAxiosPost({
    url:'payerEftManager/checkGroupRule',
    method:'POST',
    onError: (e) => 
    // what happens on error? if you need something extra
    // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  
  return { isLoading, isError, error, postData, metaData, data};
}
const useGetOdfiList=()=>{
  const {postData, isLoading,isError,error, metaData, data }=useAxiosPost({
    url:'payerEftManager/getOdfiList',
    method:'POST',
    onError: (e) => 
    // what happens on error? if you need something extra
    // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  
  return { isLoading, isError, error, postData, metaData, data};
}
const useGetEftCommitList=()=>{
  const {postData, isLoading,isError,error, metaData, data }=useAxiosPost({
    url:'payerEftManager/getEftCommitList',
    method:'POST',
    onError: (e) => 
    // what happens on error? if you need something extra
    // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  
  return { isLoading, isError, error, postData, metaData, data};
}
const useCommitEftAccount=()=>{
  const {postData, isLoading,isError,error, metaData, data }=useAxiosPost({
    url:'payerEftManager/commitEftAccount',
    method:'POST',
    onError: (e) => 
    // what happens on error? if you need something extra
    // check api and see that the notifications are already handled there
      e
    ,
    // this will override the onSuccess and onError so just pick to use this
    // or to use the other 2
    onSettled: (d, e) => {
      if(e) {
        // do a thing
        return e
      }
      // some logic here
      return d
    },
  })  
  return { isLoading, isError, error, postData, metaData, data};
}

export {
  useGetEftAccounts,
  useGetEftAccount,
  useGetDrawDownTypes, 
  useGetConsolidationTypes,
  useGetConsTypeDesc,
  useGetDrawDownDesc,
  useAddEftAccount,
  useEditEftAccount,
  useCopyEftAccount,
  useOverWriteEftAccount,
  useDeactivateEftAccount,
  useCheckGroupRule,
  useGetOdfiList,
  useGetEftCommitList,
  useCommitEftAccount
}

