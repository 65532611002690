import React from 'react';
import { useLayout } from 'ui-core/dist/useLayout';
import DetailPaneHeader from 'ui-core/dist/DetailPaneHeader';

// https://prod.storybooks.awsprod.healthcareit.net/?path=/story/layouts-page-layout--getting-started
const DashboardDetail = () => {
  const {
    expandCollapseDetailPaneButtonConfig,
    closeDetailPaneButtonConfig
  } = useLayout()

  return (
    <>
      <DetailPaneHeader
        title="Example Detail Pane"
        layoutControls={[
          expandCollapseDetailPaneButtonConfig,
          closeDetailPaneButtonConfig
        ]}
      />
    </>
  )
}

export default DashboardDetail
