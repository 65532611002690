import { useEffect } from 'react'
import {useState} from 'react'
import { useAxios, useAxiosPost } from '../hooks'

const useTestFile = (id ,getCancelSource) =>{
  const [datas, setData] = useState({})
  const { data, isLoading, postData } = useAxiosPost({
  getCancelSource,
  url: `testFiles/${id}`,
  method: 'GET',
})
// if(data){
//   setData(data)
// }
return {  data, isLoading, postData }
}

const useGetMaxFileSize = (userType) => {
  const [maxSize, setmaxSize]=useState('')
  const { data, ...rest } = useAxios({
    url: `/testFiles/getMaxFileSize`,
    method: 'POST',
    payload: {userType}
  })

  useEffect(()=>{
    if (data && data?.maxSize) {
      setmaxSize(data?.maxSize)
    }
  },[data])

  return { 
    maxSize,
    ...rest
  }
}

// const useUploadFile = (uploadedFile) =>{
//   const [testFileId, setData] = useState({})
//   const { data, isLoading, postData } = useAxiosPost({
//     url: `testFiles/upload`,
//     method: 'POST',
//     headers: {
//       'Content-Type': uploadedFile ? 'multipart/form-data' : 'application/json'
//     },
//     onSuccess: (d) => {
//       if(d){
//         setData(d?.data?.testFileId)
//       }
//     },
//     onError: (e) =>
//       // what happens on error? if you need something extra
//       // check api and see that the notifications are already handled there
//       e
//     ,
//     // this will override the onSuccess and onError so just pick to use this
//     // or to use the other 2
//     onSettled: (d, e) => {
//       if (e) {
//         // do a thing
//         return e
//       }
//       // some logic here
//       return d
//     },
//   })
  
//   return {  testFileId, isLoading, uploadTestFile: postData }
//}
export {useTestFile, useGetMaxFileSize}