import {
  IS_READ_ONLY_RULE_DETAIL,
  IS_EDITING_RULE_DETAIL,
  IS_POSTING_RULE_DETAIL,
  IS_SHOWING_PRODUCTION_ARRANGEMENT_MODAL,
  IS_SHOWING_CUSTOMER_SERVICE_MESSAGE_MODAL,
  IS_SHOWING_RETURN_ADDRESS_MODAL,
  RESET_RULE_DETAIL_ELEMENTS,
  RULE_DETAIL_POST_REQUEST_TRIGGER,
  RULE_DETAIL_POST_REQUEST_SUCCESS,
  RULE_DETAIL_POST_REQUEST_ERROR,
  RULE_DETAIL_ELEMENTS_TRIGGER,
  RULE_DETAIL_ELEMENTS_SUCCESS,
  RULES_DETAIL_ELEMENT_ERROR,
  SET_SELECTED_RULE_DETAIL,
  RULE_DETAIL_PUT_REQUEST_TRIGGER,
  RULE_DETAIL_PUT_REQUEST_SUCCESS,
  RULE_DETAIL_PUT_REQUEST_ERROR,
  IS_CLONING_RULE_DETAIL,
  IS_SHOWING_DUPLEX_RULE_MODAL,
  IS_SHOWING_SIGNATURE_RULE_MODAL,
  IS_SHOWING_CHECK_TEXT_RULE_MODAL,
  IS_SHOWING_IMAGE_RULE_MODAL,
  IS_SHOWING_AUTO_HOLD_MODAL,
  IS_SHOWING_MEMBER_ID_MODAL,
  IS_SHOWING_TAX_ID_ARRANGEMENT_MODAL,
  IS_SHOWING_ERISA_MESSAGE_MODAL,
  IS_SHOWING_HIGH_DOLLAR_REVIEW_MODAL,
  IS_SHOWING_ADD_NEW_ADDRESS_MODAL,
  ADD_NEW_ADDRESS_GET_ELEMENTS_TRIGGER,
  ADD_NEW_ADDRESS_GET_ELEMENTS_TRIGGER_SUCCESS,
  ADD_NEW_ADDRESS_GET_ELEMENTS_TRIGGER_ERROR,
  ADD_NEW_ADDRESS_ELEMENTS_POST_REQUEST_TRIGGER,
  ADD_NEW_ADDRESS_ELEMENTS_POST_REQUEST_TRIGGER_SUCCESS,
  ADD_NEW_ADDRESS_ELEMENTS_POST_REQUEST_TRIGGER_ERROR,
  IS_SHOWING_PROVIDER_INSURED_OPTION_MESSAGE_MODAL,
  IS_SHOWING_GENERAL_MESSAGE_MODAL,
  IS_SHOWING_PDF_TAGGING_MODAL,
  IS_SHOWING_IMAGE_LIBRARY_MODAL,
  IS_SHOWING_IMAGE_UPLOAD_MODAL,
  POST_IMAGE_UPLOAD_TRIGGER,
  POST_IMAGE_UPLOAD_SUCCESS,
  POST_IMAGE_UPLOAD_ERROR,
  GET_RULE_IMAGE_TRIGGER,
  GET_RULE_IMAGE_TRIGGER_SUCCESS,
  GET_RULE_IMAGE_TRIGGER_ERROR,
  GET_RULE_IMAGE_COUNT_TRIGGER,
  GET_RULE_IMAGE_COUNT_TRIGGER_SUCCESS,
  GET_RULE_IMAGE_COUNT_TRIGGER_ERROR,
  RESET_RULE_IMAGE_DATA_TRIGGER,
  CONVERT_IMAGE_UPLOAD_TRIGGER,
  CONVERT_IMAGE_UPLOAD_TRIGGER_SUCCESS,
  CONVERT_IMAGE_UPLOAD_TRIGGER_ERROR,
  IS_SHOWING_COMMIT_IMAGE_MODAL,
  POST_COMMIT_IMAGE_TRIGGER,
  POST_COMMIT_IMAGE_TRIGGER_SUCCESS,
  POST_COMMIT_IMAGE_TRIGGER_ERROR,
  POST_COMMIT_IMAGE_TRIGGER_CANCEL,
  RESET_STATE
} from './constants';

export const resetRuleDetailElements = () => ({
  type: RESET_RULE_DETAIL_ELEMENTS,
});

export const ruleDetailElementsTrigger = (payload = {}) => ({
  type: RULE_DETAIL_ELEMENTS_TRIGGER,
  payload,
});

export const ruleDetailElementsRecieved = (payload = {}) => ({
  type: RULE_DETAIL_ELEMENTS_SUCCESS,
  payload,
  error: null,
});

export const ruleDetailElementsError = (payload = {}, error) => ({
  type: RULES_DETAIL_ELEMENT_ERROR,
  payload,
  error,
});

export const setSelectedRuleDetail = (payload = {}) => ({
  type: SET_SELECTED_RULE_DETAIL,
  payload,
});

export const setIsReadOnlyRuleDetail = payload => ({
  type: IS_READ_ONLY_RULE_DETAIL,
  payload,
});

export const setIsEditingRuleDetail = payload => ({
  type: IS_EDITING_RULE_DETAIL,
  payload,
});

export const setIsPostingRuleDetail = payload => ({
  type: IS_POSTING_RULE_DETAIL,
  payload,
});

export const setIsCloningRuleDetail = payload => ({
  type: IS_CLONING_RULE_DETAIL,
  payload,
});

export const setIsShowingProductionArrangementModal = payload => ({
  type: IS_SHOWING_PRODUCTION_ARRANGEMENT_MODAL,
  payload,
});

export const setIsShowingCustomerServiceMessageModal = payload => ({
  type: IS_SHOWING_CUSTOMER_SERVICE_MESSAGE_MODAL,
  payload,
});

export const setIsShowingReturnAddressModal = payload => ({
  type: IS_SHOWING_RETURN_ADDRESS_MODAL,
  payload,
});

export const setIsShowingDuplexRuleModal = payload => ({
  type: IS_SHOWING_DUPLEX_RULE_MODAL,
  payload,
});

export const setIsShowingSignatureRuleModal = payload => ({
  type: IS_SHOWING_SIGNATURE_RULE_MODAL,
  payload,
});

export const setIsShowingCheckTextRuleModal = payload => ({
  type: IS_SHOWING_CHECK_TEXT_RULE_MODAL,
  payload,
});
export const setIsShowingImageRuleModal = payload => ({
  type: IS_SHOWING_IMAGE_RULE_MODAL,
  payload,
});

export const setIsShowingAutoHoldModal = payload => ({
  type: IS_SHOWING_AUTO_HOLD_MODAL,
  payload,
});

export const setIsShowingMemberIdModal = payload => ({
  type: IS_SHOWING_MEMBER_ID_MODAL,
  payload,
});

export const setIsShowingTaxIdArrangementRuleModal = payload => ({
  type: IS_SHOWING_TAX_ID_ARRANGEMENT_MODAL,
  payload,
});

export const setIsShowingErisaMessageModal = payload => ({
  type: IS_SHOWING_ERISA_MESSAGE_MODAL,
  payload,
});

export const setShowingHighDollarReviewModal = payload => ({
  type: IS_SHOWING_HIGH_DOLLAR_REVIEW_MODAL,
  payload,
});

export const setIsShowingProviderInsuredOptionMessageModal = payload => ({
  type: IS_SHOWING_PROVIDER_INSURED_OPTION_MESSAGE_MODAL,
  payload,
});

export const setIsShowingGeneralMessageModal = payload => ({
  type: IS_SHOWING_GENERAL_MESSAGE_MODAL,
  payload,
});

export const setIsShowingPDFTaggingModal = payload => ({
  type: IS_SHOWING_PDF_TAGGING_MODAL,
  payload,
});

export const ruleDetailPostRequestTrigger = (payload = {}) => ({
  type: RULE_DETAIL_POST_REQUEST_TRIGGER,
  payload,
});

export const ruleDetailPostRequestSuccess = (payload = {}) => ({
  type: RULE_DETAIL_POST_REQUEST_SUCCESS,
  payload,
});

export const ruleDetailPostRequestError = error => ({
  type: RULE_DETAIL_POST_REQUEST_ERROR,
  error,
});

export const ruleDetailPutRequestTrigger = (payload = {}) => ({
  type: RULE_DETAIL_PUT_REQUEST_TRIGGER,
  payload,
});

export const ruleDetailPutRequestSuccess = (payload = {}) => ({
  type: RULE_DETAIL_PUT_REQUEST_SUCCESS,
  payload,
});

export const ruleDetailPutRequestError = error => ({
  type: RULE_DETAIL_PUT_REQUEST_ERROR,
  error,
});

export const setIsShowingAddNewAddressModal = payload => ({
  type: IS_SHOWING_ADD_NEW_ADDRESS_MODAL,
  payload,
});

// --------------- Addd new address (return address rule type) ------------ //
export const getAddNewAddressElementsTrigger = (payload = {}) => ({
  type: ADD_NEW_ADDRESS_GET_ELEMENTS_TRIGGER,
  payload,
});

export const getAddNewAddressElementsRecieved = (payload = {}) => ({
  type: ADD_NEW_ADDRESS_GET_ELEMENTS_TRIGGER_SUCCESS,
  payload,
  error: null,
});

export const getAddNewAddressElementsError = error => ({
  type: ADD_NEW_ADDRESS_GET_ELEMENTS_TRIGGER_ERROR,
  payload: null,
  error,
});

export const postAddNewAddressElementsTrigger = (payload = {}) => ({
  type: ADD_NEW_ADDRESS_ELEMENTS_POST_REQUEST_TRIGGER,
  payload,
});

export const postAddNewAddressElementsRecieved = (payload = {}) => ({
  type: ADD_NEW_ADDRESS_ELEMENTS_POST_REQUEST_TRIGGER_SUCCESS,
  payload,
  error: null,
});

export const postAddNewAddressElementsError = error => ({
  type: ADD_NEW_ADDRESS_ELEMENTS_POST_REQUEST_TRIGGER_ERROR,
  payload: null,
  error,
});

export const setIsShowingImageLibraryModal = (ruleType, selectedRecordId, bool) => ({
  type: IS_SHOWING_IMAGE_LIBRARY_MODAL,
  ruleType,
  selectedRecordId,
  payload : bool,
});

export const setIsShowingImageUploadModal = (ruleType, selectedRecordId, bool) => ({
  type: IS_SHOWING_IMAGE_UPLOAD_MODAL,
  ruleType,
  selectedRecordId,
  payload: bool,
});

export const postImageUploadTrigger = (payload = {}) => ({
  type: POST_IMAGE_UPLOAD_TRIGGER,
  payload
});

export const postImageUploadRecieved = (payload = {}) => ({
  type: POST_IMAGE_UPLOAD_SUCCESS,
  payload,
  error: null,
});

export const postImageUploadError = error => ({
  type: POST_IMAGE_UPLOAD_ERROR,
  payload: null,
  error,
});

// --------------- GET :: Get Rule Images to show in Image Library Modal ------------ //
export const getRuleImagesTrigger = (payload = {}) => ({
  type: GET_RULE_IMAGE_TRIGGER,
  payload,
});

export const getRuleImagesRecieved = (payload = {}) => ({
  type: GET_RULE_IMAGE_TRIGGER_SUCCESS,
  payload,
  error: null,
});

export const getRuleImagesError = error => ({
  type: GET_RULE_IMAGE_TRIGGER_ERROR,
  payload: null,
  error,
});

// --------------- GET :: Get count of Rule Images to show in Image Library Modal for pagination ------------ //
export const getRuleImagesCountTrigger = (payload = {}) => ({
  type: GET_RULE_IMAGE_COUNT_TRIGGER,
  payload,
});

export const getRuleImagesCountRecieved = (payload = {}) => ({
  type: GET_RULE_IMAGE_COUNT_TRIGGER_SUCCESS,
  payload,
  error: null,
});

export const getRuleImagesCountError = error => ({
  type: GET_RULE_IMAGE_COUNT_TRIGGER_ERROR,
  payload: null,
  error,
});

export const resetRuleImagesDataTrigger = (payload = {}) => ({
  type: RESET_RULE_IMAGE_DATA_TRIGGER,
  payload
});

// --------------- POST :: convert image from api and get converted image in response ------------ //
export const convertImageUploadTrigger = (payload = {}) => ({
  type: CONVERT_IMAGE_UPLOAD_TRIGGER,
  payload,
});

export const convertImageUploadRecieved = (payload = {}) => ({
  type: CONVERT_IMAGE_UPLOAD_TRIGGER_SUCCESS,
  payload,
  error: null,
});

export const convertImageUploadError = error => ({
  type: CONVERT_IMAGE_UPLOAD_TRIGGER_ERROR,
  payload: null,
  error,
});

// --------------- action to show/hide commit image modal after image upload accept button submit ------------ //
export const setIsShowingCommitImageModal = payload => ({
  type: IS_SHOWING_COMMIT_IMAGE_MODAL,
  payload
});

// --------------- POST :: action to commit image from commit image modal after image upload ------------ //
export const postCommitImageTrigger = (payload = {}) => ({
  type: POST_COMMIT_IMAGE_TRIGGER,
  payload,
});

export const postCommitImageTriggerCancel = (payload = {}) => ({
  type: POST_COMMIT_IMAGE_TRIGGER_CANCEL,
  payload,
});

export const postCommitImageRecieved = (payload = {}) => ({
  type: POST_COMMIT_IMAGE_TRIGGER_SUCCESS,
  payload,
  error: null,
});

export const postCommitImageError = error => ({
  type: POST_COMMIT_IMAGE_TRIGGER_ERROR,
  payload: null,
  error,
});

export const resetRuleDetails = (payload = {}) => ({
  type: RESET_STATE,
  payload,
});