import React from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalHeader, ModalBody } from 'ui-core/dist'
import useDataSource from '../../api/useDataSource'
import { Loading } from '../../components'

const DataSourceModal = ({ isDataSourceOpen, onDataSourceClose, dataSourceId }) => {
  const { data, isLoading } = useDataSource({ dataSourceId })
  return (
    <Modal isOpen={isDataSourceOpen} className="data-source-modal">
      <ModalHeader 
        title="Data Source Details"
        description={`Details for data source ${dataSourceId}`}
        onClose={onDataSourceClose}
      />
      {isLoading ? <Loading /> : (
        <ModalBody className="data-source-modal__contents">
          <div className="data-source-modal__row">
            <span className="data-source-modal__label">Data Source Definition:</span>
            <span className="data-source-modal__info">{data?.definition}</span>
          </div>
          <div className="data-source-modal__row">
            <span className="data-source-modal__label">Data Source Description:</span>
            <span className="data-source-modal__info">{data?.description}</span>
          </div>
          <div className="data-source-modal__row">
            <span className="data-source-modal__label">Is Active:</span>
            <span className="data-source-modal__info">{data?.isActive === 'true' ? 'Active' : 'Inactive'}</span>
          </div>
        </ModalBody>
      )}
    </Modal>
  )
}

DataSourceModal.propTypes = {
  isDataSourceOpen: PropTypes.bool.isRequired, 
  onDataSourceClose: PropTypes.func.isRequired, 
  dataSourceId: PropTypes.string.isRequired
}

export default DataSourceModal