/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import styled from 'styled-components/dist/styled-components'
import { useLayout } from 'ui-core/dist/useLayout';
import DetailPaneHeader from 'ui-core/dist/DetailPaneHeader';
import { useSelector, useDispatch } from 'react-redux';
import {
  Modal, Button, ModalHeader, Input, TextArea, ModalBody, Checkbox
} from 'ui-core/dist';
import { detailPaneAnimStates } from '../../commonResources/constants/animStates';
import ErrorPage from '../ErrorPage';
import LoadingComponent from '../../components/LoadingComponent';
import useNotificationBannerHook from '../../useNotificationBannerHook';
import { SelectDropdown } from '../../components'

import {useUpdateTierPrices} from '../../api/useEmdFinanceManagerTierPrice'

const FinanceManagerTierPriceDetail = () => {
  const {
    expandCollapseDetailPaneButtonConfig,
    closeDetailPaneButtonConfig,
    detailAnimationState,
    closeDetailPane
  } = useLayout()
  const dispatch = useDispatch();
  const { notify } = useNotificationBannerHook();
  // const [draftCode, setDraftCode] = useState('')
  // const [message, setMessage] = useState('')
  // const [logo, setLogo] = useState('')
  const [isActive, setIsActive] = useState(false)
  const [billedClientID, setBilledClientID] = useState('')
  const [productItemDesc, setProductItemDesc] = useState('')
  const [tier1Price, setTier1Price] = useState('')
  const [tier1MinimumCounts,setTier1MinimumCounts] = useState('')
  const [tier1BackToZero, setTier1BackToZero] = useState('')
  const [isIncludeBSCounts, setIsIncludeBSCounts] = useState(false)
  const [tier2MinCounts, setTier2MinCounts] = useState('')
  const [tier2Price, setTier2Price] = useState('')
  const [isTier2BackToZero, setIsTier2BackToZero] = useState(false)
  const [tier3MinCounts, setTier3MinCounts] = useState('')
  const [tier3Price, setTier3Price] = useState('')
  const [isTier3BackToZero, setIsTier3BackToZero] = useState(false)
  const [tier4MinCounts, setTier4MinCounts] = useState('')
  const [tier4Price, setTier4Price] = useState('')
  const [isTier4BackToZero, setIsTier4BackToZero] = useState(false)
  const [tier5MinCounts, setTier5MinCounts] = useState('')
  const [tier5Price, setTier5Price] = useState('')
  const [isTier5BackToZero, setIsTier5BackToZero] = useState(false)
  const activeUserId = useSelector(({ session }) => session.userId)
  const [tierDescId, setTierDescId] = useState('')
  const [tier2Disabled, setTier2Disabled] = useState(true)
  const [tier3Disabled, setTier3Disabled] = useState(true)
  const [tier4Disabled, setTier4Disabled] = useState(true)
  const [tier5Disabled, setTier5Disabled] = useState(true)
  const [tierSaveDisabled, setTierSaveDisabled] = useState(true)
  const [tierSSPBiiling, setTierSSPBilling] = useState('')
  const [SSPBillings, setSSPBilling] = useState('Legacy')

  const [imageTranp, setImageTran] = useState('')
  const [imageCode, setImageCode] = useState('')
  const [imageName, setImageName] = useState('')
  const [imageListData, setImageListData] = useState()
  const [confirmationModalMode, setconfirmationModalMode] = useState('')
  const [isOpenConfirmationModal, setisOpenConfirmationModal] = useState(false)
  const [editorMode, setEditorMode] = useState('Edit')
  const [invalidNumber,setInvalidNumber] = useState(false)

  const [count, setcount] = useState(0)

  const availableSSPBilling = [{
    label: 'Legacy', value: 'Legacy'
  }, {
    label: 'Modern', value: 'Modern'
  }, {
    label: 'Both', value: 'Both'
  }]

  const { financeManagerTierData, refetchTierData, ncoaDropdownData }
    = useSelector(({ financeManager }) => financeManager);
  useEffect(()=>{
    if(tier1Price){
      setTier2Disabled(false)
    }else{
      setTier2Disabled(true)
    }
  },[tier1Price])
  useEffect(()=>{
    if(tier2Price && tier2MinCounts){
      setTier3Disabled(false)
    }else{
      setTier3Disabled(true)
    }
  },[tier2Price,tier2MinCounts])
  useEffect(()=>{
    if(tier3Price && tier3MinCounts){
      setTier4Disabled(false)
    }else{
      setTier4Disabled(true)
    }
  },[tier3Price,tier3MinCounts])
  useEffect(()=>{
    if(tier4Price && tier4MinCounts){
      setTier5Disabled(false)
    }else{
      setTier5Disabled(true)
    }
  },[tier4Price,tier4MinCounts])
  useEffect(()=>{

    let e = 0
    if(tier2Price && !tier2MinCounts || !tier2Price && tier2MinCounts || !tier2Price && !tier2MinCounts && (tier3Price||tier3MinCounts||tier4Price||tier4MinCounts||tier5Price||tier5MinCounts)){
      e++
    }
    if(tier3Price && !tier3MinCounts || !tier3Price && tier3MinCounts || !tier3Price && !tier3MinCounts && (tier4Price||tier4MinCounts||tier5Price||tier5MinCounts)){
      e++
    }
    if(tier4Price && !tier4MinCounts || !tier4Price && tier4MinCounts || !tier4Price && !tier4MinCounts && (tier5Price||tier5MinCounts)){
      e++
    }
    if(tier5Price && !tier5MinCounts || !tier5Price && tier5MinCounts){
      e++
    }
    if(e===0){
      setTierSaveDisabled(false)
    }else{
      setTierSaveDisabled(true)
    }
  },[tier1Price,tier2MinCounts,tier2Price,tier3Price,tier3MinCounts,tier4Price,tier4MinCounts,tier5Price,tier5MinCounts])
  useEffect(() => {
    if (financeManagerTierData) {
      onResetCurrentRecordValues()
      setTierDescId(financeManagerTierData?.$?.id)
      setIsActive(financeManagerTierData.isActive==='1')
      setBilledClientID(financeManagerTierData.billedClientNumber)
      setProductItemDesc(financeManagerTierData.productItemDesc)
      setIsIncludeBSCounts(financeManagerTierData.includeBacksideCounts==='1')
      setSSPBilling(financeManagerTierData.SSPBillings)
      if(financeManagerTierData.tierPrices && financeManagerTierData.tierPrices!=='' && financeManagerTierData.tierPrices.tierPrice){
        const totalTierDataAll = financeManagerTierData.tierPrices.tierPrice

        if(totalTierDataAll.constructor.name==='Object'){
          setTier1Price(totalTierDataAll.price)
          setTier1MinimumCounts(totalTierDataAll.minimumCounts)
          setTier1BackToZero(totalTierDataAll.backToZero)
        }else{
          const totalTierData = totalTierDataAll.sort((a, b) => Number(a.minimumCounts) > Number(b.minimumCounts) ? 1 : -1)
          setTier1Price(totalTierData[0]?totalTierData[0].price:'')
          setTier1MinimumCounts(totalTierData[0]?totalTierData[0].minimumCounts:'')
          setTier1BackToZero(totalTierData[0]?totalTierData[0].backToZero:'')
          setTier2Price(totalTierData[1]?totalTierData[1].price:'')
          setTier2MinCounts(totalTierData[1]?totalTierData[1].minimumCounts:'')
          setIsTier2BackToZero(totalTierData[1]?totalTierData[1].backToZero:'')
          setTier3Price(totalTierData[2]?totalTierData[2].price:'')
          setTier3MinCounts(totalTierData[2]?totalTierData[2].minimumCounts:'')
          setIsTier3BackToZero(totalTierData[2]?totalTierData[2].backToZero:'')
          setTier4Price(totalTierData[3]?totalTierData[3].price:'')
          setTier4MinCounts(totalTierData[3]?totalTierData[3].minimumCounts:'')
          setIsTier4BackToZero(totalTierData[3]?totalTierData[3].backToZero:'')
          setTier5Price(totalTierData[4]?totalTierData[4].price:'')
          setTier5MinCounts(totalTierData[4]?totalTierData[4].minimumCounts:'')
          setIsTier5BackToZero(totalTierData[4]?totalTierData[4].backToZero:'')
        }

      }

    } else
    {
      // onClearCurrentRecordValues()
    }


  }, [financeManagerTierData]);






  const { data: infoModifyTierPricesResponse, postData: modifyTierPrices, metaData: modifyTierPricesResponse, isLoading: isLoadingModifyTierPrices,
    isError: isErrorModifyTierPrices, error: errorModifyTierPrices } = useUpdateTierPrices()

  const onResetCurrentRecordValues = () => {
    setTierDescId('')
    setTier1Price('')
    setTier1BackToZero('')
    setTier1MinimumCounts('')
    setTier2Price('')
    setTier2MinCounts('')
    setIsTier2BackToZero('')
    setTier3Price('')
    setTier3MinCounts('')
    setIsTier3BackToZero('')
    setTier4Price('')
    setTier4MinCounts('')
    setIsTier4BackToZero('')
    setTier5Price('')
    setTier5MinCounts('')
    setIsTier5BackToZero('')
    setTierSSPBilling('')
    setSSPBilling('Legacy')
    // if (showMoreInfo) {
    //   setImageTran(showMoreInfo?.imageTran)
    //   setImageCode(showMoreInfo?.clientImageName)
    //   setImageName(showMoreInfo?.abfImageName)
    //   setImageListData(showMoreInfo?.abfImageName)
    //   setEditorMode(editorModep)
    // }
  }


  useEffect(() => {
    if (infoModifyTierPricesResponse) {


        notify(`${productItemDesc} is successfully modified.`, 'positive')
        closeDetailPane()
        if (refetchTierData) refetchTierData()

    }
  }, [infoModifyTierPricesResponse])


  const onSubmitClick = () => {
    if (editorMode === "Add") {
      // onConfirmationYesClick()
      setconfirmationModalMode("Add")
    } else if (editorMode === "Edit") {
      setconfirmationModalMode("Modify")
    }
    setisOpenConfirmationModal(true)
  }
  const constructXML = (price, id, minimumCounts, backToZero) => {

    let dataXML = ""
      if (price && minimumCounts) {
          dataXML += "<tierPrice><id>" + (!id ? "" : id)
                  + "</id>" + "<isDeleted>0</isDeleted>" + "<minimumCounts>" + minimumCounts
                  + "</minimumCounts>" + "<price>" + price + "</price>" + "<backToZero>"
                  + (backToZero === "1"||backToZero===true ? "1" : "0") + "</backToZero></tierPrice>";
      } else if (id) {
          dataXML += "<tierPrice><id>" + id + "</id>" + "<isDeleted>1</isDeleted></tierPrice>";
      }
      return dataXML

  }
  const onConfirmationYesClick = useCallback(() => {
    let tierPriceFinalVar
    if(financeManagerTierData && financeManagerTierData.tierPrices && financeManagerTierData.tierPrices==='' ){
      tierPriceFinalVar = ''
    }else{
      tierPriceFinalVar = financeManagerTierData.tierPrices
    }
    let dataXML=`<![CDATA[<data><collection><productItemPrices><productItemPrice>
    <id>${tierDescId||""}</id>
    <isActive>${isActive? 1: 0}</isActive>
    <billedClientNumber>${billedClientID}</billedClientNumber>
    <monthBilled>${financeManagerTierData?.monthBilled}</monthBilled>
    <includeBacksideCounts>${isIncludeBSCounts? "1": "0"}</includeBacksideCounts><tierPrices>
    ${financeManagerTierData?.tierPrices !== '' ?
    constructXML(
      tier1Price,
      financeManagerTierData?.tierPrices?.tierPrice.constructor.name ==="Array"?
      financeManagerTierData.tierPrices.tierPrice[0].id: financeManagerTierData.tierPrices.tierPrice.id,
      financeManagerTierData?.tierPrices?.tierPrice.constructor.name ==="Array"?
      financeManagerTierData.tierPrices.tierPrice[0].minimumCounts: financeManagerTierData.tierPrices.tierPrice.minimumCounts,
      financeManagerTierData?.tierPrices?.tierPrice.constructor.name ==="Array"?
      financeManagerTierData.tierPrices.tierPrice[0].backToZero: financeManagerTierData.tierPrices.tierPrice.backToZero,
      ):
      constructXML(
        tier1Price,
        '',
        '0',
        '0'
      )
    }
    ${financeManagerTierData?.tierPrices !== '' && financeManagerTierData?.tierPrices?.tierPrice[1]?
    constructXML(
      tier2Price,
      financeManagerTierData?.tierPrices?.tierPrice.constructor.name ==="Array"?
      financeManagerTierData.tierPrices.tierPrice[1]?.id: financeManagerTierData.tierPrices.tierPrice.id,
      tier2MinCounts,
      isTier2BackToZero,
      ):(
      (tier2Price || tier2MinCounts || isTier2BackToZero) ?
      constructXML(
        tier2Price,
        '',
        tier2MinCounts,
        isTier2BackToZero
      ):'')
    }
    ${financeManagerTierData?.tierPrices !== '' && financeManagerTierData?.tierPrices?.tierPrice[2]?
    constructXML(
      tier3Price,
      financeManagerTierData?.tierPrices?.tierPrice.constructor.name ==="Array"?
      financeManagerTierData.tierPrices.tierPrice[2]?.id: financeManagerTierData.tierPrices.tierPrice.id,
      tier3MinCounts,
      isTier3BackToZero,
      ):(
      (tier3Price || tier3MinCounts || isTier3BackToZero) ?
      constructXML(
        tier3Price,
        '',
        tier3MinCounts,
        isTier3BackToZero
      ):'')
    }
    ${financeManagerTierData?.tierPrices !== '' && financeManagerTierData?.tierPrices?.tierPrice[3]?
    constructXML(
      tier4Price,
      financeManagerTierData?.tierPrices?.tierPrice.constructor.name ==="Array"?
      financeManagerTierData.tierPrices.tierPrice[3]?.id: financeManagerTierData.tierPrices.tierPrice.id,
      tier4MinCounts,
      isTier4BackToZero,
      ):(
      (tier4Price || tier4MinCounts || isTier4BackToZero) ?
      constructXML(
        tier4Price,
        '',
        tier4MinCounts,
        isTier4BackToZero
      ):'')
    }
    ${financeManagerTierData?.tierPrices !== '' && financeManagerTierData?.tierPrices?.tierPrice[4]?
    constructXML(
      tier5Price,
      financeManagerTierData?.tierPrices?.tierPrice.constructor.name ==="Array"?
      financeManagerTierData.tierPrices.tierPrice[4]?.id: financeManagerTierData.tierPrices.tierPrice.id,
      tier5MinCounts,
      isTier5BackToZero,
      ):(
      (tier5Price || tier5MinCounts || isTier5BackToZero) ?
      constructXML(
        tier5Price,
        '',
        tier5MinCounts,
        isTier5BackToZero
      ):'')
    }
    </tierPrices>
    <NCOAid>${tierDescId}</NCOAid></productItemPrice></productItemPrices>
    <userId>${activeUserId}</userId>
    <SSPBilling>${SSPBillings}</SSPBilling></collection></data>]]>
    `
      modifyTierPrices({
        dataXML,
        count,
      })
      setcount(count + 1)
    setconfirmationModalMode('')
    setisOpenConfirmationModal(false)
  },[isActive,tierDescId, activeUserId,billedClientID, tier1Price,tier2MinCounts,tier2Price,tier3Price,tier2MinCounts,isTier2BackToZero, tierDescId,
  tier3Price,isTier3BackToZero,tier3MinCounts,tier4Price,tier4MinCounts,isTier4BackToZero,tier5MinCounts,tier5Price,isTier5BackToZero,
  isIncludeBSCounts,SSPBillings])
  // useEffect(() => {
  //   onResetCurrentRecordValues()
  // }, [financeManagerTierData])

  // const onClearCurrentRecordValues = () => {

  //   setImageTran('')
  //   setImageCode('')
  //   setImageListData('')
  //   setEditorMode('Add')
  // }
  const validateNumber = useCallback((number) => {
    if(number)
    {
      if(/^[-+]?[0-9]+\.[0-9]+$/.test(number) || /^\d+$/.test(number)){
        setInvalidNumber(false)
        return false
      }else{
        setInvalidNumber(true)
        return true
      }
    }
    else{
      setInvalidNumber(false)
      return false
    }

  });

  const renderIsActive = useMemo(() => (
    <Checkbox
      domID='chk_active'
      labelTooltipWidth={100}
      name="DropdownListItem"
      checked={isActive}
      onChange={(e,state)=>{
        setIsActive(state)
      }}
    />
  ), [isActive])
  const renderBilledClientId = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_BilledClientId"
      placeholder=""
      size="small"
      maxLength={21}
      disabled
      initialValue={billedClientID}
      // errorMessage="Incorrect data do not use '&', '|' or '>' characters"
      // hasError={/[\\&>\\|]/.test(address)}
      onChange={(e) => {
        setBilledClientID(e.target.value || "")
      }}
    />
  ), [billedClientID])
  const renderProductItemDesc = useMemo(() => {
    // if(ncoaDropdownData && ncoaDropdownData.length > 1 && (productItemDesc==='NCOA Hit' || productItemDesc==='NCOA Envelope')){
    //   let optionsArr = []
    //   ncoaDropdownData.forEach(v=>{
    //     const a = {label:v.productItemDesc,value:v.$.id}
    //     optionsArr.push(a)
    //   })
    //   return(
    //     <SelectDropdown
    //   width="230px"
    //   domID="defer_state_input"
    //   // onChange={(e, state) => this.props.handleDateRangeSelect(e, state)}
    //   options={optionsArr}
    //   size="small"
    //   initialValue={{label:financeManagerTierData.productItemDesc,value:financeManagerTierData.$.id}}
    //   onChange={(e)=>{
    //     if (e) {
    //       setTierDescId(e.value)
    //     }
    //   }}
    // />
    //   )
    // }else{
       return(
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_ProductItemDesc"
      placeholder=""
      size="small"
      maxLength={21}
      disabled
      initialValue={productItemDesc}
      // errorMessage="Incorrect data do not use '&', '|' or '>' characters"
      // hasError={/[\\&>\\|]/.test(address)}
      onChange={(e) => {
        setProductItemDesc(e.target.value || "")
      }}
    />
     )
    // }
  }, [productItemDesc,ncoaDropdownData,financeManagerTierData, tierDescId])
  const renderTier1Price = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_Tier1Price"
      placeholder=""
      size="small"
      maxLength={21}
      initialValue={tier1Price}
      errorMessage="Please enter a valid number only!"
      hasError={validateNumber(tier1Price)}
      onChange={(e) => {
        setTier1Price(e.target.value || "")
        if(e.target.value){
          setTier2Disabled(false)
        }else{
          setTier2Disabled(true)
        }
      }}
    />
  ), [tier1Price])

  const renderIncludeBacksidecounts = useMemo(() => (
    <Checkbox
      domID='chk_active1'
      labelTooltipWidth={100}
      name="DropdownListItem"
      checked={isIncludeBSCounts}
      onChange={(e,state)=>{
        setIsIncludeBSCounts(state)
      }}
    />
  ), [isIncludeBSCounts])
  const renderTier2MinimumCounts = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_Tier1Price"
      placeholder=""
      size="small"
      maxLength={10}
      initialValue={tier2MinCounts}
      errorMessage="Please enter a valid number only!"
      hasError={validateNumber(tier2MinCounts)}
      disabled={tier2Disabled}
      onChange={(e) => {
        setTier2MinCounts(e.target.value || "")
      }}
    />
  ), [tier2MinCounts,tier2Disabled])
  const renderTier2Price = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_Tier1Price"
      placeholder=""
      size="small"
      maxLength={21}
      initialValue={tier2Price}
      disabled={tier2Disabled}
      onChange={(e) => {
        setTier2Price(e.target.value || "")
      }}
      errorMessage="Please enter a valid number only!"
      hasError={validateNumber(tier2Price)}
    />
  ), [tier2Price,tier2Disabled])
  const renderTier2BackToZero = useMemo(() => (
    <Checkbox
      domID='chk_active2'
      labelTooltipWidth={100}
      name="DropdownListItem"
      checked={isTier2BackToZero==='1'}
      onChange={(e,state)=>{
        setIsTier2BackToZero(state)
      }}
      disabled={tier2Disabled}
    />
  ), [isTier2BackToZero,tier2Disabled])
  const renderTier3MinimumCounts = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_Tier3Price"
      placeholder=""
      size="small"
      maxLength={10}
      initialValue={tier3MinCounts}
      onChange={(e) => {
        setTier3MinCounts(e.target.value || "")
      }}
      disabled={tier3Disabled}
      errorMessage="Please enter a valid number only!"
      hasError={validateNumber(tier3MinCounts)}
    />
  ), [tier3MinCounts,tier3Disabled])
  const renderTier3Price = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_Tier1Price"
      placeholder=""
      size="small"
      maxLength={21}
      initialValue={tier3Price}
      onChange={(e) => {
        setTier3Price(e.target.value || "")
      }}
      disabled={tier3Disabled}
      errorMessage="Please enter a valid number only!"
      hasError={validateNumber(tier3Price)}
    />
  ), [tier3Price,tier3Disabled])
  const renderTier3BackToZero = useMemo(() => (
    <Checkbox
      domID='chk_active3'
      labelTooltipWidth={100}
      name="DropdownListItem"
      checked={isTier3BackToZero==='1'}
      onChange={(e,state)=>{
        setIsTier3BackToZero(state)
      }}
      disabled={tier3Disabled}
    />
  ), [isTier3BackToZero,tier3Disabled])
  const renderTier4MinimumCounts = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_Tier4Price"
      placeholder=""
      size="small"
      maxLength={10}
      initialValue={tier4MinCounts}
      onChange={(e) => {
        setTier4MinCounts(e.target.value || "")
      }}
      disabled={tier4Disabled}
      errorMessage="Please enter a valid number only!"
      hasError={validateNumber(tier4MinCounts)}
    />
  ), [tier4MinCounts,tier4Disabled])
  const renderTier4Price = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_Tier4Price"
      placeholder=""
      size="small"
      maxLength={21}
      initialValue={tier4Price}
      onChange={(e) => {
        setTier4Price(e.target.value || "")
      }}
      disabled={tier4Disabled}
      errorMessage="Please enter a valid number only!"
      hasError={validateNumber(tier4Price)}
    />
  ), [tier4Price,tier4Disabled])
  const renderTier4BackToZero = useMemo(() => (
    <Checkbox
      domID='chk_active4'
      labelTooltipWidth={100}
      name="DropdownListItem"
      checked={isTier4BackToZero==='1'}
      onChange={(e,state)=>{
        setIsTier4BackToZero(state)
      }}
      disabled={tier4Disabled}
    />
  ), [isTier4BackToZero,tier4Disabled])
  const renderTier5MinimumCounts = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_Tier5Price"
      placeholder=""
      size="small"
      maxLength={10}
      initialValue={tier5MinCounts}
      onChange={(e) => {
        setTier5MinCounts(e.target.value || "")
      }}
      disabled={tier5Disabled}
      errorMessage="Please enter a valid number only!"
      hasError={validateNumber(tier5MinCounts)}
    />
  ), [tier5MinCounts,tier5Disabled])
  const renderTier5Price = useMemo(() => (
    <Input
      style={{ width: '200px' }}
      className="test-classname"
      domID="txt_Tier5Price"
      placeholder=""
      size="small"
      maxLength={21}
      initialValue={tier5Price}
      onChange={(e) => {
        setTier5Price(e.target.value || "")
      }}
      disabled={tier5Disabled}
      errorMessage="Please enter a valid number only!"
      hasError={validateNumber(tier5Price)}
    />
  ), [tier5Price,tier5Disabled])
  const renderTier5BackToZero = useMemo(() => (
    <Checkbox
      domID='chk_active5'
      labelTooltipWidth={100}
      name="DropdownListItem"
      checked={isTier5BackToZero==='1'}
      onChange={(e,state)=>{
        setIsTier5BackToZero(state)
      }}
      disabled={tier5Disabled}
    />
  ), [isTier5BackToZero,tier5Disabled])
  const renderSSPBilling = useMemo(() => (
    <SelectDropdown
      width="250px"
      domID="defer_state_input"
      isClearable={false}
      label=""
      options={availableSSPBilling}
      size="small"
      initialValue={{ label: availableSSPBilling.find(x => x.value === SSPBillings)?.label, value: SSPBillings }}
      onChange={(e) => {
            setSSPBilling(e.value)
      }}
    />
  ), [SSPBillings])
  const validateForm = useMemo(() => {
    if(!imageCode || !imageListData){
      return true
    }
    return false

  }, [imageCode, imageListData])

  const renderAddressEntryForm = useMemo(() => {
    if (detailAnimationState > detailPaneAnimStates.COLLAPSED) {
      return (
        <div style={{ marginLeft: '40px' }} className="marginLeft">
          <div>
            <p>
              <div className="displayFlex">Complete the information below, and then click Submit to continue.</div>
            </p>
            <p>
              <div className="displayFlex"><span style={{ color: 'red' }}>* Please enter Tier1 then Tier2 then Tier3...</span></div>
            </p>
            <p>
              <div className="displayFlex"><span style={{ color: 'red' }}>* Please enter minimum count and price to update the Tier</span></div>
            </p>
          </div>
          <br />
          <div className="displayFlex">
            <table className="common-table">
              <tr>
                <td>
                  <label> IsActive : </label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderIsActive}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td>
                  <label> Billed Client Id : </label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderBilledClientId}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td style={{width: "204px"}}>
                  <label>Product Item Description : </label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderProductItemDesc}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td style={{width: "204px"}}>
                  <label>Tier 1 Include Backside counts : </label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderIncludeBacksidecounts}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td style={{width: "204px"}}>
                  <label>Tier 1 Price : </label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderTier1Price}
                  </div>
                </td>
              </tr>
              {/* <tr><p></p></tr>
              <tr>
                <td style={{width: "204px"}}>
                  <label>Tier 1 Minimum Counts : </label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderTier1MinimumCounts}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td style={{width: "204px"}}>
                  <label>Tier 1 Back To Zero : </label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderTier1BackToZero}
                  </div>
                </td>
              </tr> */}

              <tr><p></p></tr>
              <tr>
                <td style={{width: "204px"}}>
                  <label>Tier 2 Minimum Counts : </label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderTier2MinimumCounts}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td style={{width: "204px"}}>
                  <label>Tier 2 Price : </label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderTier2Price}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td style={{width: "204px"}}>
                  <label>Tier 2 Back To Zero : </label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderTier2BackToZero}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td style={{width: "204px"}}>
                  <label>Tier 3 Minimum Counts : </label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderTier3MinimumCounts}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td style={{width: "204px"}}>
                  <label>Tier 3 Price : </label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderTier3Price}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td style={{width: "204px"}}>
                  <label>Tier 3 Back To Zero : </label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderTier3BackToZero}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td style={{width: "204px"}}>
                  <label>Tier 4 Minimum Counts : </label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderTier4MinimumCounts}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td style={{width: "204px"}}>
                  <label>Tier 4 Price : </label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderTier4Price}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td style={{width: "204px"}}>
                  <label>Tier 4 Back To Zero : </label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderTier4BackToZero}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td style={{width: "204px"}}>
                  <label>Tier 5 Minimum Counts : </label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderTier5MinimumCounts}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td style={{width: "204px"}}>
                  <label>Tier 5 Price : </label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderTier5Price}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td style={{width: "204px"}}>
                  <label>Tier 5 Back To Zero : </label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderTier5BackToZero}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td style={{width: "204px"}}>
                  <label>Legacy/SPP Billing : </label>
                </td>
                <td>
                  <div className="marginLeft" style={{ width: '250px' }}>
                    {renderSSPBilling}
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
              <tr>
                <td colSpan="2">
                  <div className="displayFlex">
                    {/* <div>
                      <Button
                        domID="btn_reset"
                        buttonType="standard"
                        size="small"
                        name="RESET"
                        disabled={editorMode !== "Edit"}
                        onClick={() => onResetCurrentRecordValues()}
                      />
                    </div>
                    <div className="marginLeft">
                      <Button
                        domID="btn_copy"
                        buttonType="standard"
                        size="small"
                        name="COPY"
                        disabled={editorMode === "Add"}
                        onClick={() => {
                          onCopyCurrentRecordValues()
                          setEditorMode('Add')
                        }}
                      />
                    </div>
                    <div className="marginLeft">
                      <Button
                        domID="btn_delete"
                        buttonType="standard"
                        size="small"
                        name="DELETE"
                        disabled={editorMode !== "Edit"}
                        onClick={() => {setconfirmationModalMode("Delete")
                          setisOpenConfirmationModal(true)}}
                      />
                    </div> */}
                    <div className="marginLeft">
                      <Button
                        domID="btn_search"
                        buttonType="standard"
                        size="small"
                        name="SUBMIT"
                        disabled={tierSaveDisabled||invalidNumber}
                        onClick={() => onSubmitClick()}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr><p></p></tr>
            </table>
          </div>
          <Modal isOpen={isOpenConfirmationModal}>
            <ModalHeader
              title={`Do you want to modify ${productItemDesc} ?`}
              onClose={() => setisOpenConfirmationModal(false)}
            />
            <ModalBody>
              {isOpenConfirmationModal ? (
                <div style={{ marginLeft: '34px', marginBottom: '20px' }} className="marginLeft">
                  <div className="displayFlex">
                    <div className="marginLeft">
                      <Button
                        domID="btn_search"
                        buttonType="standard"
                        size="small"
                        name="Yes"
                        onClick={() => onConfirmationYesClick()}
                      />
                    </div>
                    <div className="marginLeft">
                      <Button
                        domID="btn_search"
                        buttonType="standard"
                        size="small"
                        name="No"
                        onClick={() => setisOpenConfirmationModal(false)}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
            </ModalBody>
          </Modal>
        </div>
      )
    }
    return null;
  }, [financeManagerTierData, detailAnimationState, editorMode, confirmationModalMode, isOpenConfirmationModal, isActive, billedClientID,
    productItemDesc, tier1Price, isIncludeBSCounts, tier2MinCounts, tier2Price, isTier2BackToZero,
    tier3MinCounts, tier3Price, isTier3BackToZero, tier4MinCounts, tier4Price, isTier4BackToZero,invalidNumber,
    tier5MinCounts, tier5Price, isTier5BackToZero,tier3Disabled,tier2Disabled,tier4Disabled,tier5Disabled,tierSaveDisabled,SSPBillings])

  return (
    <>
      <DetailPaneHeader
        title= {`Modify: ${productItemDesc}`}
        layoutControls={[
          expandCollapseDetailPaneButtonConfig,
          closeDetailPaneButtonConfig
        ]}
      />
      <>
        {
          isLoadingModifyTierPrices? (
            // replace with a better loading component
            <div style={{ marginLeft: '50%' }}><LoadingComponent /></div>
          ) : isErrorModifyTierPrices  ? (
              // replace with a better error component
              <ErrorPage
                // this prop isn't real but is an example
                error={errorModifyTierPrices}
              />
            ) : (
              <>
                {renderAddressEntryForm}
              </>
            )
        }
      </>
    </>
  )
}

export default FinanceManagerTierPriceDetail
