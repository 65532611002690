export const fonts = `@font-face {
  font-family: 'Core Sans C';
  src: url('/static/media/core-sans-c-400.woff2') format('woff2'),
    url('/static/media/core-sans-c-400.eot') format('embedded-opentype'),
    url('/static/media/core-sans-c-400.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Core Sans C';
  src: url('/static/media/core-sans-c-400-italic.woff2') format('woff2'),
    url('/static/media/core-sans-c-400-italic.eot') format('embedded-opentype'),
    url('/static/media/core-sans-c-400-italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Core Sans C';
  src: url('/static/media/core-sans-c-600.woff2') format('woff2'),
    url('/static/media/core-sans-c-600.eot') format('embedded-opentype'),
    url('/static/media/core-sans-c-600.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Core Sans C';
  src: url('/static/media/core-sans-c-600-italic.woff2') format('woff2'),
    url('/static/media/core-sans-c-600-italic.eot') format('embedded-opentype'),
    url('/static/media/core-sans-c-600-italic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}
* {
  font-family: 'Core Sans C';
}`;
