
const initialState = {
  quickSearchParams: null,
  searchParams:null,
  fileSearchParam:null,
  record:null,
  documentSearchMetaData:null,
  index:null,
  currentShowMoreTarget:null,
  trackingInfoAvailable:false,
  trackingInfoText:null,
  selectedAchRecord:null
}
    
export default (state = initialState, { type, payload }) => {
  switch(type) {
    case 'SET_TRACKING_INFO_AVAILABLE':
      return {
        ...state,
        trackingInfoAvailable:payload.trackingInfoAvailable,
        trackingInfoText:payload.trackingInfoText
      }
    case 'SET_CURRENT_SHOW_MORE_TARGET':
      return {
        currentShowMoreTarget:payload
      }
    case 'RESET_DOCUMENT_SEARCH_METADATA':
      return {
        ...state,
        documentSearchMetaData:null
      }
    case 'SET_DOCUMENT_SEARCH_METADATA':
      return {
        ...state,
        documentSearchMetaData:payload
      }
    case 'RESET_RECORD':
      return {
        ...state,
        record:null,
        index:null,
        currentShowMoreTarget:null,
        trackingInfoAvailable:false,
        trackingInfoText:null
      }
    case 'SET_RECORD':
      return {
        ...state,
        index:payload.index,
        currentShowMoreTarget:payload.currentShowMoreTarget,
        record:payload.record
      }
    case 'SET_QUICK_SEARCH_PARAMS':{
      return {
        ...state,
        quickSearchParams: JSON.parse(JSON.stringify(payload))
      }
    }
    case "SET_DOCUMENT_SEARCH_PARAMS":
      return {
        ...state,
        searchParams:JSON.parse(JSON.stringify(payload))
      }
    case "RESET_DOCUMENT_SEARCH_PARAMS":
      return {
        ...state,
        searchParams:null
      }
    case "FILE_SEARCH_PARAM":
      return {
        ...state,
        fileSearchParam: JSON.parse(JSON.stringify(payload))
      }
    case "FILE_SEARCH_PARAM_NULL":
      return {
        ...state,
        fileSearchParam: null
      }
    case "SET_ACH_FILE_ID_DETAILS":
      return {
        ...state,
        selectedAchRecord: JSON.parse(JSON.stringify(payload.selectedAchRecord))
      }
    default:
      return state;
  }
}