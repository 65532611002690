import React from 'react';
import AccordionPanels from './components/AccordionPanels';

import {
  SingleSelectGroupNoBorder,
  SingleSelectGroupInnerWrapper,
  SingleSelectGroupWrapper,
} from './styles';

const TestAssocDetailsGrid = props => (
  <div>
    <SingleSelectGroupWrapper>
      <SingleSelectGroupInnerWrapper>
        <SingleSelectGroupNoBorder>
          <AccordionPanels {...props} />
        </SingleSelectGroupNoBorder>
      </SingleSelectGroupInnerWrapper>
    </SingleSelectGroupWrapper>
  </div>
);

export default TestAssocDetailsGrid;
