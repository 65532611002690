import React from 'react';
import PropTypes from 'prop-types';
import { DetailHeader } from 'ui-core-ssgr';
import { PaddingWrapper } from '../../../../../shared/styles/spacing';

import { headerProps } from './constants';

class LiveGridHeader extends React.Component {
  onButtonClick = name => {};

  render() {
    const { recordCount, headerName, selectedRuleRecord } = this.props;

    return (
      <PaddingWrapper>
        <DetailHeader
          {...headerProps}
          title={headerName}
          amountKey="amount"
          domID="header"
          count={
            selectedRuleRecord &&
            selectedRuleRecord.status.length === 1 &&
            selectedRuleRecord.status[0] === 'T'
              ? null
              : recordCount
          }
          marginBottom={12}
        />
      </PaddingWrapper>
    );
  }
}

LiveGridHeader.defaultProps = {
  recordCount: 0,
};

LiveGridHeader.propTypes = {
  selectedRuleRecord: PropTypes.object,
  recordCount: PropTypes.number,
  headerName: PropTypes.string,
};

export default LiveGridHeader;
